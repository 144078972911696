import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { BaseResponse } from '../../shared/business/shared.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';

@Component({
  selector: 'app-machine-name',
  templateUrl: './machine-name.component.html',
  styleUrls: ['./machine-name.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailMachineNameComponent implements OnInit {
  FormGrp: UntypedFormGroup;
  machineNames: any;
  isViewOnly = false;
  tableoptions: any[];
  captions: any;
  currIndex: any;
  maxListOrder: number;
  type: any;
  onEditDisableFunction: boolean = true;
  updatedData: any;
  deleteMachineId: number;
  constructor(private Form: UntypedFormBuilder, private http: HttpServiceCall, public localization: RetailLocalization, private utils: RetailUtilities,
    private retailService: RetailSetupService) {
  }

  ngOnInit() {
    this.FormGrp = this.Form.group({
      TerminalID: []
    });
    this.captions = this.localization.captions.retailsetup;
    this.InvokeServiceCall("GetMachineNames", myGlobals.Host.authentication, HttpMethod.Get);
    this.isViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.MachineNames).view;
  }

  checkForMinValidation(machineName): boolean {
    let valid: boolean = true;
    if ((machineName.value.controls.setupCodeName.value.trim().length < 3)) {
      let errMsg: string = this.localization.getError(100713);
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
      valid = false;
    }
    return valid;
  }

  addMachineName(data: any) {
    let regex = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/);
    var guiddata =data.value.controls.GuidID.value?.trim();
    if (regex.test(guiddata) == false) {
      guiddata="";
  }  
    if (data.type.toLowerCase() == this.localization.captions.setting.Add.toLowerCase() && this.checkForMinValidation(data)) {
      const machineNameObj = {
        IsActive: true,
        Name: data.value.controls.setupCodeName.value.trim(),
        MachineGUID :guiddata,
        propertyID: Number(this.utils.GetPropertyInfo('PropertyId')),
        ListOrder: Number(this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1] ? this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1].listOrder : 0) + 1,
      };
      this.utils.ToggleLoader(true,this.captions.lbl_processing);
      this.InvokeServiceCall("CreateMachineName", myGlobals.Host.authentication, HttpMethod.Post, '', machineNameObj);
    }
    else if (data.type.toLowerCase() == this.localization.captions.setting.update.toLowerCase() && this.checkForMinValidation(data)) {
      const machineNameObj = {
        id: this.tableoptions[0].TablebodyData[this.currIndex].id,
        IsActive: data.value.controls.activetoggle.value,
        Name: data.value.controls.setupCodeName.value.trim(),
        MachineGUID :guiddata,
        propertyID: Number(this.utils.GetPropertyInfo('PropertyId')),
        ListOrder: this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
      };
      this.utils.ToggleLoader(true,this.captions.lbl_processing);
      this.InvokeServiceCall("UpdateMachineName", myGlobals.Host.authentication, HttpMethod.Put, { id: machineNameObj.id }, machineNameObj);
    }
    return true;
  }

  Done(event: any) {
    const machineNameObj = {
      id: event.id,
      IsActive: event.active,
      Name: event.machineName,
      MachineGUID:event.machineGUID,
      ListOrder: event.listOrder,
    };
    this.updatedData = event;
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.InvokeServiceCall("UpdateMachineName", myGlobals.Host.authentication, HttpMethod.Put, { id: machineNameObj.id }, machineNameObj);
  }

  EditRecords(data?: any, type?: any) {
    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == data[0].id);
  }

  DeleteRecords(event: any) {
    this.deleteMachineId = event[0].id;
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.InvokeServiceCall("MachineAssociatedWithTransaction", myGlobals.Host.retailPOS, HttpMethod.Get, { machineNameId: this.deleteMachineId });
  }

  dragDrop(e: any) {
    // let params = { 'fromorder': e[0], 'toorder': e[1] }
    // this.InvokeServiceCall("MachineNameDragDrop", myGlobals.Host.authentication, HttpMethod.Put, params);
    const machineNameObj = {
      id: e[4].id,
      IsActive: e[4].active,
      Name: e[4].machineName,
      MachineGUID:e[4].machineGUID,
      ListOrder: e[5].listOrder,
    };
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.InvokeServiceCall("UpdateMachineName", myGlobals.Host.authentication, HttpMethod.Put, { id: machineNameObj.id }, machineNameObj);
  }

  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if(callDesc === "MachineAssociatedWithTransaction") {
      let response = <any>result.result;
      if(!response) {
        this.InvokeServiceCall("DeleteMachineName", myGlobals.Host.authentication, HttpMethod.Delete, { id: this.deleteMachineId });
      }
    } else {
      let response = <any>result.result;
      let currentPropertyId = Number(this.utils.GetPropertyInfo('PropertyId'));
      this.machineNames = [];
      response.forEach(element => {
        if (element.propertyId == currentPropertyId) {
          let machineName: any = {
            id: element.id,
            machineName: element.name,
            machineGUID :element.machineGUID,
            active: element.isActive,
            listOrder: element.listOrder
          }
          this.machineNames.push(machineName);
        }
      });
      this.BindToGrid();
    } 
    this.utils.ToggleLoader(false);
  }

  private BindToGrid() {
    this.tableoptions = [
      {
        TableHdrData: [{ "title": this.captions.machineName, "jsonkey": "machineName", "sortable": true },{ "title": this.captions.MachineGuid, "jsonkey": "machineGUID", "alignType": "left" ,"sortable": true },
        { "title": this.captions.Active, "jsonkey": "active", "sortable": true },
        { "title": this.captions.ListOrder, "jsonkey": "listOrder", "alignType": "right", "sortable": true, "searchable": false }],
        TablebodyData: this.machineNames,
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.Search,
        EnableActions: true,
        SelectRows: true,
        IsCommission: true,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.machineNames,
        Sortable: 'listOrder',
        TableId: myGlobals.GridType.machineNames,
        disableDelete: false,
        customHeader: true,
        pageTitle: 'machinename',
        IsViewOnly: this.isViewOnly,
        ServiceId: 'machinename',
        TableDraggable: true,
        automationId : "machineNames"
      }
    ];
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (error.errorCode == 10506) {
      if (this.updatedData.active != undefined) {
        this.updatedData.active = true;
      }
    }
  }

  sliderChange(event: any) {
    let currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == event.data.id);
    this.tableoptions[0].TablebodyData[currIndex].active = !event.value;
  }
}
