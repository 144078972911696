import { Injectable } from '@angular/core';
import { CommonCommunication } from '../../communication/services/common-communication-services';
import { CommonControllersRoutes } from '../../communication/common-route';
import { NotificationTemplate, NotificationKeyword, TemplateDomainData, NotificationAPITemplate } from '../../templates/template-email/crud-email-template/crud-email-template.model';


@Injectable({
    providedIn: 'root'
})
export class TemplateDataServices {

    constructor(private _commonCommunication: CommonCommunication) {
    }

    public GetAllTemplates(typeId: number, showInActive: boolean): Promise<NotificationTemplate[]> {
        return this._commonCommunication.getPromise<NotificationTemplate[]>(
            {
                route: CommonControllersRoutes.GetAllTemplates,
                uriParams: { typeId: typeId, showInActive: showInActive }
            }
        )
    }

    public GetMaxListOrder(typeId: number): Promise<number> {
        return this._commonCommunication.getPromise<number>(
            {
                route: CommonControllersRoutes.GetMaxListOrder,
                uriParams: { typeId: typeId }
            }
        )
    }


    public GetTemplateKeywords(eventId: number[], productId: number): Promise<NotificationKeyword[]> {
        return this._commonCommunication.putPromise<NotificationKeyword[]>(
            {
                route: CommonControllersRoutes.GetTemplateKeywords,
                uriParams: { productId: productId },
                body: eventId
            }
        )
    }

    public GetTemplatByNotificationTemplateId(Id: number,languageid :number=1): Promise<TemplateDomainData> {
        return this._commonCommunication.getPromise<TemplateDomainData>(
            {
                route: CommonControllersRoutes.GetTemplatByNotificationTemplateId,
                uriParams: { notificationTemplateId: Id , languageId:languageid}
            },true,true
        )
    }

    public CreateorUpdateTemplate(templateData: TemplateDomainData): Promise<number> {
        return this._commonCommunication.postPromise<number>(
            {
                route: CommonControllersRoutes.CreateorUpdateTemplate,
                body: templateData
            }
        )
    }

    public DeleteTemplate(notificationTemplateId: number): Promise<NotificationTemplate[]> {
        return this._commonCommunication.deletePromise<NotificationTemplate[]>(
            {
                route: CommonControllersRoutes.DeleteTemplate,
                uriParams: { id: notificationTemplateId }
            }
        )
    }
    public DeleteNotificationTemplateContent(id: number,languageId:number): Promise<NotificationTemplate> {
        return this._commonCommunication.deletePromise<NotificationTemplate>(
            {
                route: CommonControllersRoutes.DeleteNotificationTemplateContent,
                uriParams: { notificationTemplateId :id, languageId:languageId, }
            }
        )
    }
    public UpdateTemplate(id: number, notificationtemplate: NotificationAPITemplate): Promise<boolean> {
        return this._commonCommunication.putPromise<boolean>(
            {
                route: CommonControllersRoutes.UpdateTemplate,
                body: notificationtemplate,
                uriParams: { id: id }
            }
        )
    }

    public UpdateListOrder(fromorder: number, toOrder: number, typeId: number): Promise<boolean> {
        return this._commonCommunication.patchPromise<boolean>(
            {
                route: CommonControllersRoutes.UpdateListOrder,
                uriParams: { fromorder: fromorder, toOrder: toOrder, typeId: typeId }
            }
        )
    }

    public DefaultTemplateExists(eventId: number[], id: number, typeId: number): Promise<boolean> {
        return this._commonCommunication.putPromise<boolean>(
            {
                route: CommonControllersRoutes.DefaultTemplateExists,
                uriParams: { id: id, typeId: typeId },
                body: eventId
            }
        )
    }
}
