import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatDialogRef } from "@angular/material/dialog";
import { RetailPopupComponent } from '../retail-popup/retail-popup.component';
import { popupConfig, BaseResponse, CommissionTemplate, CommissionTemplateSplit } from "../retail.modals";
import { Host, ButtonType, RetailBreakPoint, Maxlength } from '../shared/globalsContant';
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { RetailLocalization } from '../common/localization/retail-localization';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { EmptyValueValidator } from '../shared/Validators/EmptyValueValidator';
import { CommonVariablesService } from '../shared/service/common-variables.service';

@Component({
  selector: 'app-retail-add-commission-popup',
  templateUrl: './retail-add-commission-popup.component.html',
  styleUrls: ['./retail-add-commission-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailAddCommissionPopupComponent implements OnInit {

  @Input() dataInput;
  @Input() popupConfigs: popupConfig;
  @Input() automationId: string = '';
  captions: any;
  FormGrp: UntypedFormGroup;

  splitLevels: UntypedFormArray;
  commissionClasses: any = [];

  SelectedType: any;
  selectedCC: any = '';
  isEdit: boolean = false;
  validateSaveVar: boolean = false;
  templateId: number = 0;
  IsViewOnly: boolean;
  addCommissionChanges: any;
  percentageMaxLength = Maxlength.PERCENTAGE;
  floatLabel: string;
  constructor(private Form: UntypedFormBuilder, private dialogRef: MatDialogRef<RetailPopupComponent>, private _shopService: CommonVariablesService,
    private http: HttpServiceCall, public localization: RetailLocalization, private Utilities: RetailUtilities, private retailService: RetailSetupService) {
      this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.captions = this.localization.captions.retailsetup;
    this.FormGrp = this.Form.group({
      id: '',
      tempCode: ['', Validators.required],
      tempName: ['', [Validators.required, EmptyValueValidator]],
      on: ['', Validators.required],
      selectedCC: '',
      splitLevels: this.Form.array([this.createItem()])
    });

    this.commissionClasses = [
      { id: '1', value: 'CC', viewValue: this.captions.CommissionClass },
      { id: '2', value: 'EMP', viewValue: this.captions.EmployeeClass }
    ];

    var breakPoint = this.retailService.retailSetupBreakPoints.find(bp => bp.breakPointNumber == RetailBreakPoint.SplitCommissionTemplateSetup);
    this.IsViewOnly = breakPoint ? breakPoint.view : false;
    if (this.popupConfigs.operation == 'edit') {
      this.templateId = this.dataInput.id;
      let commissionClassNumber: number = this.dataInput.commissionClassId;
      for (let i = 0; i < this.dataInput.splitDetail.length; i++) {
        this.addItem();

        this.splitLevels.at(i).setValue(
          {
            id: this.dataInput.splitDetail[i].id,
            level: this.dataInput.splitDetail[i].levelDescription,
            split: this.localization.localizeCurrency(this.dataInput.splitDetail[i].percentage, false),
          });
      }
      this.removeItem(this.dataInput.splitDetail.length, true); // TO DO
      if (commissionClassNumber > 0) {
        this.SelectedType = 'CC'
        this.FormGrp.controls["selectedCC"].setValue(commissionClassNumber);
      }
      else {
        this.SelectedType = 'EMP';
      }
      this.FormGrp.controls["tempCode"].setValue(this.dataInput.templateCode);
      this.FormGrp.controls["tempName"].setValue(this.dataInput.templateName);
      this.isEdit = true;
    }
    if (this.IsViewOnly) {
      this.Utilities.disableControls(this.FormGrp)
    }

  }

  ngAfterViewInit() {
    this.formValueChanges();
  }

  createItem(): UntypedFormGroup {
    return this.Form.group({
      id: 0,
      level: ['', [Validators.required, EmptyValueValidator]],
      split: ['', Validators.required]
    });
  }

  addItem(): void {
    this.splitLevels = this.FormGrp.get('splitLevels') as UntypedFormArray;
    this.splitLevels.push(this.createItem());
  }

  removeItem(i?: any, isInternal: any = false) {
    if (!isInternal) {
      this.FormGrp.markAsDirty();
    }
    this.splitLevels.removeAt(i);
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  save() {
    let template: CommissionTemplate = this.GetTemplateData();
    this.ValidateTotalPercentage(template);
  }

  ValidateTotalPercentage(formData: CommissionTemplate) {
    if (formData && formData.splitDetail && formData.splitDetail.length > 0) {
      let totalPercent: number = 0;
      for (let split of formData.splitDetail) {
        totalPercent += split.percentage;
      }
      let extraParams: any = { body: formData };
      if (totalPercent == 100) {
        if (this.isEdit) {
          let param = { 'id': this.templateId };
          this.InvokeServiceCall('CommissionTemplateById', HttpMethod.Put, param, formData);
        }
        else {
          this.InvokeServiceCall("CommissionTemplate", HttpMethod.Post, '', formData);
        }
      }
      else if (totalPercent > 100) {
        this.Utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.getError(10304), ButtonType.YesNo, this.PopupCallback.bind(this), extraParams);
      }
      else if (totalPercent < 100) {
        this.Utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.getError(10305), ButtonType.YesNo, this.PopupCallback.bind(this), extraParams);
      }
    }
  }

  PopupCallback(result, extraPrams) {
    let body = extraPrams['body'];
    if (result.toLowerCase() == 'yes') {
      if (this.isEdit) {
        let param = { 'id': this.templateId };
        this.InvokeServiceCall('CommissionTemplateById', HttpMethod.Put, param, body);
      }
      else {
        this.InvokeServiceCall("CommissionTemplate", HttpMethod.Post, '', body);
      }
    }
  }

  GetTemplateData(): CommissionTemplate {

    let splitLevels: any[] = this.FormGrp.controls["splitLevels"].value;
    let splitInfo: CommissionTemplateSplit[] = [];
    for (let split of splitLevels) {
      let info: CommissionTemplateSplit = { id: split.id, commissionTemplateId: this.templateId, levelDescription: split.level, percentage: this.localization.currencyToSQLFormat(split.split) };
      splitInfo.push(info);
    }

    let classId: number = 0;
    if (this.SelectedType == 'CC') {
      classId = Number(this.FormGrp.controls["selectedCC"].value);
    }

    return {
      id: this.templateId,
      templateCode: this.FormGrp.controls["tempCode"].value,
      templateName: this.FormGrp.controls["tempName"].value,
      commissionClassId: classId,
      splitDetail: splitInfo,
      productId: Number(this.Utilities.GetPropertyInfo('ProductId'))
    }
  }

  InvokeServiceCall(route: string, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.commission,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string): void {
    if (callDesc == 'CommissionTemplate' || callDesc == 'CommissionTemplateById') {
      let response: CommissionTemplate[] = <any>result.result;
      this._shopService.commissionTemplate = response;
      this.onCancelClick();
    }
  }
  errorCallback(): void {
    console.error('error');
  }

  formValueChanges() {
    this.addCommissionChanges = this.FormGrp.valueChanges.subscribe(() => {
      let isValidType: boolean = true;
      if (this.SelectedType == 'CC' && this.FormGrp.controls["selectedCC"].value == '') {
        isValidType = false;
      }
      this.validateSaveVar = this.FormGrp.valid && this.FormGrp.dirty && isValidType;
    }
    )
  }

  ngDestroy() {
    if (this.addCommissionChanges) {
      this.addCommissionChanges.unsubscribe();
    }
  }
}
