import { Injectable } from "@angular/core";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { Outlet, SubPropertyModel, CCTerminal, OutelTerminalHeaderDropDown, Translog } from "../../retail.modals";
import { RetailLocalization } from "../../common/localization/retail-localization";

@Injectable()

export class RetailOutletsBusinessService {

    constructor(private utils: RetailUtilities, private localization: RetailLocalization) { }

    public checkForMinValidation(newOutlet: SubPropertyModel): boolean {
        let valid: boolean = true;
        if ((newOutlet.subPropertyName.trim().length < 2)) {
            let errMsg:string = this.localization.getError(10712);
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
            valid = false;
        }
        return valid;
    }

    public ValidateOutlet(outlets: Outlet[], newOutlet: SubPropertyModel): boolean {
        let goodToProceed: boolean = this.ValidateOutletName(newOutlet.subPropertyName);
        if (goodToProceed) {
            goodToProceed = !this.isDuplicate(outlets, newOutlet);
        }
        return goodToProceed;
    }

    public getTerminals(cCTerminals: CCTerminal[], isActiveOnly: boolean = true): OutelTerminalHeaderDropDown[] {
        return cCTerminals.filter(allterminals => allterminals.isActive == isActiveOnly).map(t => {
            return { id: t.terminalId, viewValue: t.terminalId }
        });
    }

    private isDuplicate(outlets: Outlet[], newOutlet: SubPropertyModel): boolean {
        let isExist: boolean = outlets.some(t => (t.outletName.trim().toLowerCase() == newOutlet.subPropertyName.trim().toLowerCase()
            && t.id != newOutlet.subPropertyID));
        if (isExist) {
            let errMsg: string = this.localization.getError(10710);
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
        }
        return isExist;
    }

    private ValidateOutletName(outletName: string): boolean {
        let Isvalid: boolean = true;
        if (outletName.trim().length == 0) {
            Isvalid = false;
            let errMsg: string = this.localization.getError(10711);
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
        }
        return Isvalid;
    }

    public GetTranslogBody(LogType: string, logDescription: string, outlet: SubPropertyModel = null, existingOutlet: Outlet = null): Translog {
        let outletOldData: any;
        let outletNewData: any;
        if (existingOutlet) {
            outletOldData = {
                CCTerminalId: existingOutlet.CCTerminalId,
                isActive: existingOutlet.isActive,
                subPropertyId: existingOutlet.id,
                subPropertyName: existingOutlet.outletName,
                terminalLinkId: existingOutlet.terminalId
            };
        }
        if (outlet) {
            outletNewData = {
                CCTerminalId: outlet.CCTerminalId,
                isActive: outlet.isActive,
                subPropertyId: outlet.subPropertyID,
                subPropertyName: outlet.subPropertyName,
                terminalLinkId: outlet.terminalLinkId
            };
        }
        return {
            TypeId: outlet ? outlet.subPropertyID : existingOutlet.id,
            LogType: LogType,
            Description: logDescription,
            OldValue: outletOldData ? JSON.stringify(outletOldData) : null,
            NewValue: outletNewData ? JSON.stringify(outletNewData) : null
        } as Translog; 
    }
}
