<div class="package-activities">
    <div class="package-activities__header">
        <div class="package-activities__calendar">
            <!-- <div class="package-activities__date-section">
                <form [formGroup]="dateRangeForm">
                    <mat-form-field>
                        <input matInput placeholder="Choose a date" [satDatepicker]="package_filter"
                            formControlName="filterDate">
                        <sat-datepicker #package_filter [rangeMode]="true" [selectFirstDateOnClose]="true">
                        </sat-datepicker>
                        <sat-datepicker-toggle matSuffix [for]="package_filter"></sat-datepicker-toggle>
                    </mat-form-field>
                </form>
            </div> -->
            <div class="package-activities__today-button" *ngIf="showTodayButton">
                <activities-app-button [buttontype]="buttonToday" (valueChange)="navigateToToday()"></activities-app-button>
            </div>
        </div>
    </div>
    <div class="package-list">
        
        <div class="package-bookings">
            <div class="package-activities__searchbar">
                <dynamic-form [config]="searchconfig" #form="dynamicForm">
                </dynamic-form>
            </div>
            <div class="packages__table pannel">
                <table mat-table [dataSource]="tableDataSource" matSort class="mat-elevation-z8"
                    (matSortChange)="onSortData($event)" matSortDisableClear>
                    <ng-container [matColumnDef]="column" *ngFor="let column of displayColumns">
                        <ng-container>
                            <th [id]="column" class="seat-grid-header" mat-header-cell mat-sort-header arrowPosition="after"
                                *matHeaderCellDef> {{column | translate}} </th>
                        </ng-container>
                        <!-- <ng-container *ngIf="column == 'Actions'">
                            <th [id]="column" class="seat-grid-header" mat-header-cell arrowPosition="after"
                                *matHeaderCellDef>
                                {{column | translate}} </th>
                        </ng-container> -->
                            <td class="seat-grid-content" mat-cell *matCellDef="let packageItem;let i=index">
                                <span class="line-items"
                                    >{{packageItem[column]}}</span>
                                <!-- <span *ngIf="column=='Actions'" class="packages__actions">
                                    <span (click)="cancelPackage(packageItem)">{{'cancel' | translate}}</span>
                                </span> -->
                            </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;" (click)="viewActivities(row)" class="package-row" [ngClass]="{'active' : selectedPackags?.PackageConfirmationId == row.ConfirmationCode}"></tr>
                </table>
                <div class="no-data"
                    *ngIf="(packages && packages.length == 0) || (tableDataSource.filteredData && tableDataSource.filteredData.length == 0)">
                    {{'noDataFound' | translate}}</div>
            </div>
        </div>
        <div class="package-details"  *ngIf="showBookedActivities">
            <div class="activities-pannel"
           >
            <div class="detail-view">
                <span class="icon-close" (click)="showBookedActivities = false"></span>
                <div class="title">{{'PackageSummary' | translate}}</div>
                <table class="table table-primary-detail">
                    <tr>
                        <td>{{'guestName' | translate}}</td>
                        <td>{{bookingDetails?.GuestName}}</td>
                    </tr>
                    <tr>
                        <td>{{'packageName' | translate}} </td>
                        <td>{{bookingDetails?.PackageName}}</td>
                    </tr>
                    <tr>
                        <td>{{'ConfirmationCode' | translate}}</td>
                        <td>{{bookingDetails?.ConfirmationCode}}</td>
                    </tr>
                    <tr>
                        <td>{{'BookingDate' | translate}}</td>
                        <td>{{bookingDetails?.Date | formatSettingsDate: cs.settings.value.General.DateFormat}}</td>
                    </tr>
                </table>
                <div class="sub-title">{{'activities' | translate}}</div>
                <table class="table table-secondary-detail">
                    <th>{{'userActivityText' | translate}}</th>
                    <th>{{'sizeText' | translate}}</th>
                    <th>{{'price' | translate}}</th>
                    <tr *ngFor="let book of bookingDetails?.bookings">
                        <td>{{book?.activityName}}</td>
                        <td>{{book?.bookingSize}}</td>
                        <td>{{_settings?.General?.OperationCurrency }} {{book?.PaidAmount | currencyDecimalFormatter}}</td>
                    </tr>
                </table>
                <div class="totalPrice">
                    <div class="price-amount">{{'TotalPrice' | translate}}</div>
                    <div class="price-amount">{{_settings?.General?.OperationCurrency }} {{bookingDetails?.PackagePrice | currencyDecimalFormatter}}</div>
                </div>
                <!-- <div class="action">
                    <activities-app-button [buttontype]="cancelBtn" (click)='cancelPackage(bookingDetails)'></activities-app-button>
                </div> -->
            </div>
        </div>
        </div>
    </div>