import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TimeLineComponent } from './timeline.component';

const TimeEntryRoutes: Routes = [
  {
    path: '',
    component: TimeLineComponent,
    children: [
      {
        path: '',
        component: TimeLineComponent

      },
    ]
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(TimeEntryRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class TimeLineRoutingModule {

}










