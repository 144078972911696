import { UntypedFormGroup } from '@angular/forms';
import { Time } from '@angular/common';
import { Observable } from 'rxjs';
import { aligment, HdrActionTypeEnum, ActionTypeEnum } from '../components/cdkvirtual/cdkvirtual.model';
import { TableActions } from '../enums/shared-enums';
import { InputTypeText, InputTypeNumbers } from '../shared/shared/utilities/common-utilities';
import { SnackBarType } from '../shared/shared/enums/enums';

export interface AgDurationConfig {
    class?: string;
    controlName?: string;
    disabled?: boolean;
    formGroup?: UntypedFormGroup;
    invalidErrorMessage?: string;
    label?: string;
    maxValueErrorMessage?: string;
    minValueErrorMessage?: string;
    required?: boolean;
}

export interface AgTimeConfig {
    className?: string;
    errorMessage?: string;
    errorMessageId?: string;
    form?: UntypedFormGroup;
    formControlName?: string;
    floatLabel?: string;
    hidden?: boolean;
    maxTime?: string;
    minTime?: string;
    placeHolder?: string;
    placeHolderId?: string;
    value?: string;
    defaultTime?: string;
    customErrorMessage?: string;
    customErrorMessageId?: string;
    isTimeRequired?: boolean;
    timeFormat?:number;
    automationId?: string;
}

export interface AgTimeModalConfig{
    className?: string;
    isTimeRequired: boolean;
    automationId: string;
    errorMessage?: string;
    placeHolder? : string;
    floatLabel? : string;
}

export interface AgFieldConfig {
    id?: string;
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formControlName?: string;
    placeHolder?: string;
    placeHolderId?: string;
    value?: string | number;
    maxlength?: string | number;
    minlength?: string | number;
    disabled?: boolean;
    isRequired?: boolean;
    maxValue?: number;
    showLabel?: boolean;
    labelText?: string;
    minValue?: number;
    incrementBy?: number;
    customErrorMessage?: string;
    maximumValueErrorMsg?: string;
    minimumValueErrorMsg?: string;
    allowNegative?: boolean; // used for currency
    customMaxErrorMsg?: string;
    customMinErrorMsg?: string;
    floatLabel?: string;
    inputType?: string;
    maximumValueErrorMsgId?: string;
    customErrorMessageId?: string;//To fill custom error message value's caption key from json & value will be in customerrorMessage
    errorMessageId?: string;//To fill custom error message value's caption key from json & value will be in errorMessage
    readOnly?: boolean;
    automationId?: string;
    predecimalmaxlength?: number;
    postdecimalmaxlength?:number;
    isfieldUpdate?:number;
}

// Aside Filter Panel data model
export interface FilterGroup {
    id: number;
    name: string;
    title: string;
    filters: Filter[];
    filtered?: Filter[];
    value?: Filter; // Value to be selected for single filter by default
    isSingleSelect?: boolean;
    isDisabled?: boolean;
    expand?: boolean;
    isSectionReset?: boolean;
    isHidden?: boolean;
    className?: string;
}

export interface DateFilter {
    name: string;
    minDate?: Date;
    placeholder: string;
    defaultValue?: Date;
    value?: Date;
    maxDate?: Date;
    disable?: boolean;
}

export interface Filter {
    id: number;
    name: string;
    class?: string;
    isSelected?: boolean;
    extraParam?: any;
    title?: string;
    isDisabled?: boolean;
    refBuildingId?: any;
}

export interface AsideFilterConfig {
    filterText: string;
    resetText?: string;
    displayCount: number;
    alphabets?: string[];
    isMultiSelect: boolean;
    panelClass?: string;
    viewMoreConfig?: ViewMoreConfig;
    isCloseRequired?:boolean;
}


export interface AsideDateFilter {
    name: string;
    isDateRange?: boolean;
    filters: DateFilter[];
}

export interface ViewMoreConfig {
    apply: string;
    cancel: string;
    searchByPlaceHolder: string;
    alphabets?: string[];
}


export interface ButtonValue {
    customclass?: string;
    disabledproperty?: boolean;
    label: string;
    type: string;
}

export interface ReturnButtonValue {
    buttontype?: string;
    event: object;
    value?: number;
}

export interface SplitMoreAction {
    action: string;
    class?: string;
    disabled?: boolean;
    icon?: string;
    label: string;
}

export interface TableSearchHeader {
    advancedSearch?: string;
    hideAdvanceSearch?: boolean;
    buttonType?: string;
    createBtnDisabled?: boolean;
    createBtnLabel?: string;
    hasHeaderAction?: boolean;
    showFilter?:boolean;
    showTextCount?:boolean;
    textLabel?:any;
    headerActionDisabled?: boolean;
    links?: string;
    maxLength?: number;
    searchBarhide?: boolean;
    searchDisabled?: boolean;
    searchPlaceHolder: string;
    showInactive?: boolean;
    toggleDisabled?: boolean;
    toggleLabel?: string;
    toggleLabelID?: string;//Give toggleLabel key of toggleLable value here from localization json's
    toggleNo?: string;
    toggleYes?: string;
    showPrint?: boolean;
    hideActionBtn?: boolean;
    disablePrint?: boolean;
    showBeforeSearchButton?: boolean;
    beforeBtnLabel?: string;
    beforeButtonDisabled?: boolean;
    automationId?: string;
}

export interface radioOptInterfce{
    radioOptionsArr:radioselectionInterface[],
    minchar?:number
}

export interface radioselectionInterface{ id: number; viewValue: string; selected: boolean; }

export interface AgToggleConfig<T = any> {
    className?: string;
    data?: T;
    disabled?: boolean;
    formControlName?: string;
    group: UntypedFormGroup;
    horizontal?: boolean;
    label?: string;
    labelID?: string;
    name?: string;
    value?: any;
    automationId?: string;
}

export interface AgDateConfig {
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formControlName?: string;
    hidden?: boolean;
    isDateRequired?: boolean;
    isDisabled?: boolean;
    maxDate?: Date | string;
    minDate?: Date | string;
    placeHolder?: string;
    placeHolderId?: string;
    value?: Date | string;
    minDateErrorMessage?: string;
    errorMessageId?: string;
    automationId?: string;
}

export interface HeaderInput {
    code: string | number;
    isActive: boolean;
    name: string;
    toggleShow: boolean;
}

export interface InputActionPlaceHolder {
    code: string;
    name: string;
    errorMessageCode: string;
    errorMessageName: string;
}

/** CDK Table Model Starts */
export class TableHeaderOptions {
    alignment?: aligment;
    subheaderName?:string;
    displayName: string;
    hdrtemplateName?: HdrActionTypeEnum;
    inputType?: ActionTypeEnum;
    isInlineEditable?: boolean;
    key: string;
    searchable?: boolean;
    sorting?: boolean;
    sortingKey?: string;
    templateName?: ActionTypeEnum;
    inputs?: any;
    maxValue?: string | number;
    isCheckboxDisabled?: boolean;
    headerData?: any;
    inputTypeDirective?: string;
    blurEventdisable?: boolean;
    duration?: AgDurationConfig;
    radioKey?: string;
    allowNegative?: boolean; // as of now used for currency
    displayNameId?: string;
    headerInfo?: string; // Detailed description of the header , it will be shown in tooltip
    minValue?: string | number; // In case of Date picker pass the property name in the row obejct
    isHidden?: boolean;    
    isDisabled?: boolean;
    displaySubName?:string;
    disabeRadiokeyName?:string;
    sortInputType?:string;
}

export interface TableOptions {
    actions?: TableAction[];
    checkboxKey?: string;
    columnFreeze?: {
        firstColumn: boolean;
        lastColumn: boolean;
    };
    isSubheader?:boolean;
    isFilterByAPI?: boolean;
    commentKey?: string;
    defaultsortingColoumnKey: string;
    defaultSortOrder: string;
    deleteMsgKey?: string;
    ignoreSort?: boolean;
    inlineActions?: TableAction[];
    isDragDisabled?: boolean;
    isHeaderCheckboxAllowed?: boolean;
    isInternalEdit?: boolean;
    isReadOnly?: boolean;
    maxRecordCount?: number;
    noDataLabel?: string;
    readOnlyKey?: string;
    showDeleteMsg?: boolean;
    showTotalRecords?: boolean;
    CDK_showConfigure?: boolean
    isRowClick?: boolean;
    defaultClickedRowIndex?: number;
    uniqueKey?: string;
    enablePagination?: boolean;
    enableServerPagination?: boolean;
    enableExportExcel?:boolean;
    defaultPageSize?:boolean;
    imageCaption? : string;
}

export interface AgDurationConfig {
    class?: string;
    controlName?: string;
    disabled?: boolean;
    formGroup?: UntypedFormGroup;
    invalidErrorMessage?: string;
    label?: string;
    maxValueErrorMessage?: string;
    minValueErrorMessage?: string;
    required?: boolean;
}

export interface TableAction {
    type: TableActions;
    tooltip?: string;
    disabled: boolean;
    customClass?: string;
}
export interface DropdownOptions {
    checked?: boolean;
    id?: number | string;
    value?: string | any;
    viewValue: string;
    disabled?: boolean;
    otherData?: any;
    showInDropDown?: boolean;
    propertyValue?: string;
    pmsIntegrationId?: number;
}


export interface AgDropdownConfig<T = any> {
    data?: T;
    className?: string;
    errorMessage?: string;
    form: UntypedFormGroup;
    formControlName: string;
    placeHolder?: string;
    placeHolderId?: string;
    selectOptions: Observable<DropdownOptions[]> | Promise<DropdownOptions[]>;
    isFirstEmpty?: boolean;
    isSelect?: boolean;
    showRequired?: boolean;
    disabled?: boolean;
    isMultiSelect?: boolean;
    customErrorMessage?: string;
    defaultSelectededOptionValue?: DropdownOptions;
    customErrorMessageId?: string;//To fill custom error message value's caption key from json & value will be in customerrorMessage
    errorMessageId?: string;//To fill custom error message value's caption key from json & value will be in errorMessage
    isAllSelected?: boolean;
    dropdownWithSearch?: boolean;
    isFormLogicApplied?:boolean;
    automationId?: string;
    isReportDropdown?: boolean;
}

/** CDK Table Model Ends */

export interface AgAddressFieldConfig {
    className?: string;
    errorMessage?: string;
    errorMessageId?: string;
    form?: UntypedFormGroup;
    formArrayName?: string;
    formControlName?: string;
    placeHolder?: string;
    placeHolderId?: string;
    value?: string;
    disabled?: boolean;
    automationId?: string;
}

export interface AgAddressFieldConfig {
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formArrayName?: string;
    formControlName?: string;
    placeHolder?: string;
    placeHolderId?: string;
    value?: string;
    disabled?: boolean;
    isAddressLine1Required?: boolean;
    automationId?: string;
}
export interface AgAddressFieldConfig {
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formArrayName?: string;
    formControlName?: string;
    placeHolder?: string;
    placeHolderId?: string;
    value?: string;
    disabled?: boolean;
    automationId?: string;
}

export interface AgContactConfig {
    typeControlName?: string;
    typeClass?: string;
    className?: string;
    extensionClass?: string;
    extnPlaceHolder?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formArrayName?: string;
    formControlName?: string;
    placeHolder?: string;
    placeHolderId?: string;
    value?: string | number;
    options?: any[];
    showSwitches?: boolean;
    isPrimaryName?: string;
    isPrivateName?: string;
    primarySwitchLabel?: string;
    privateSwitchLabel?: string;
    emailPlaceHolder?: string;
    isPhoneNumberRequired?: boolean;
    isEmailRequired?: boolean;
    requiredErrorMessage?: string;
    disabled?: boolean;
    allowOnlyPrimaryOrPrivate?: boolean;
    errorMessageId?: string;//To fill custom error message value's caption key from json & value will be in errorMessage
    automationId?: string;
}
export interface AgDropdownConfig<T = any> {
    data?: T;
    className?: string;
    errorMessage?: string;
    errorMessageId?: string;
    form: UntypedFormGroup;
    formControlName: string;
    placeHolder?: string;
    placeHolderId?: string;
    selectOptions: Observable<DropdownOptions[]> | Promise<DropdownOptions[]>;
    isFirstEmpty?: boolean;
    isSelect?: boolean;
    showRequired?: boolean;
    disabled?: boolean;
    isMultiSelect?: boolean;
    customErrorMessage?: string;
    customErrorMessageId?: string;
    defaultSelectededOptionValue?: DropdownOptions;
    isAll?: boolean;
    isReportDropdown?: boolean;
}

export interface GoogleAddressOutput {
    fullAddress?: Addresscomponent;
    city: string;
    state: string;
    country: string;
    zipCode: string;
}

export interface DropdownOptions {
    id?: number | string;
    value?: string | any;
    viewValue: string;
    disabled?: boolean;
    otherData?: any;
}

export interface Addresscomponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface AgPhoneDetail {
    id: number;
    phoneType: number;
    phoneNumber: string;
    extension: string;
    isPrimary: boolean;
    isPrivate: boolean;
    disabled?: boolean;
}

export interface AgAddressConfig {
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formArrayName?: string;
    formControlName?: string;
    placeHolder?: string;
    placeHolderId?: string;
    value?: string;
    disabled?: boolean;
    isAddressLine1Required?: boolean;
    errorMessageId: string;
    automationId?: string;
}

export interface AgAddressField {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface DataType {
    id: number;
    name: string;
}

export interface SaveButtonObj {
    isEdit: boolean;
    disabled: boolean;
    customSaveText?: string;
    customCancelText?: string;
    automationId?: string;
}

export interface ImageData {
    id: number;
    referenceType: string;
    referenceId: number;
    sequenceNo: number;
    contentType: string;
    data: any[];
    thumbnailData: any[];
}

export interface AgInputFieldConfig {
    id?: string;
    className?: string;
    errorMessageId?: string;//To fill custom error message value's caption key from json & value will be in errorMessage
    errorMessage?: string;
    form?: UntypedFormGroup;
    formControlName?: string;
    placeHolder?: string;
    placeHolderId?: string;
    value?: string | number;
    disabled?: boolean;
    customErrorMessageId?: string;//To fill custom error message value's caption key from json & value will be in customerrorMessage
    customErrorMessage?: string;
    maximumValueErrorMsg?: string;
    inputType?: string;
    autocomplete?: boolean;
    showRequired?: boolean;
    minlength?: number;
    maxlength?: number;
    changeLabelOnEdit?: boolean;
    isEditMode?: boolean;
    readonly?: boolean;
    maxValue?: number;
    minValue?: number;
    floatLabel?: string;
    focus?:boolean;
    type?: string;
    isRequired?: boolean;
    automationId?: string;

}

export interface RadioOptions {
    id: number;
    value: string | number;
    viewValue: string;
    disabled?: boolean;
    icon?:string
}

export interface FloatingNavigation {
    displayName: string;
    idName: string;
}

export interface AsideFilterConfig {
    filterText: string;
    resetText?: string;
    displayCount: number;
    alphabets?: string[];
    isMultiSelect: boolean;
    viewMoreConfig?: ViewMoreConfig;
}

export interface AsideDateFilter {
    name: string;
    isDateRange?: boolean;
    filters: DateFilter[];
}

export interface ViewMoreConfig {
    apply: string;
    cancel: string;
    searchByPlaceHolder: string;
    alphabets?: string[];
}

export interface DateFilter {
    name: string;
    minDate?: Date;
    placeholder: string;
    defaultValue?: Date;
    value?: Date;
    maxDate?: Date;
    disable?: boolean;
}

export interface FilterGroup {
    id: number;
    name: string;
    title: string;
    filters: Filter[];
    filtered?: Filter[];
    value?: Filter; // Value to be selected for single filter by default
    isSingleSelect?: boolean;
    expanded?: boolean;
}

export interface Filter {
    id: number;
    name: string;
    class?: string;
    isSelected?: boolean;
    extraParam?: any;
    title?: string;
}

export interface MultiImageUpload {
    image: string;
    imageSize: number;
    thumbnail: string;
    thumbnailSize: number;
    filename: string;
}

export enum AgTextBoxType {
    FreeText = 'FREETEXT',
    Currency = 'CURRENCY',
    Number = 'NUMBER',
    Code = 'CODE',
    Percent = 'PERCENTAGE',
    NoWhiteSpace='NOWHITESPACE',
    ExchangeConversion = 'EXCHANGECONVERSION'
}

export enum AgDropDownType {
    Default = 'DEFAULT',
    Mulitple = 'MULTIPLE'
}

export enum AgDateType {
    DEFAULT = 'DEFAULT'
}

export interface AgTextBoxAttributes {
    type: AgTextBoxType;
    maxLength: number;
    minLength?: number;
    directives: InputTypeText[] | InputTypeNumbers[];
    toDomMapper: (text: string | number) => string | number;
    toFormMapper: (text: string | number) => string | number;
    customClass: string;
    defaultValue: string | number;
}

export interface AgDateAttributes {
    type: AgDateType;
    minDate: Date;
    maxDate: Date;
    toDomMapper: (value: string | Date) => Date; // Accepts Date Obj or ISO Date String
    toFormMapper: (value: string | Date) => string;  // Retuns "1996-10-15T00:00:00"
    customClass: string;
}


export class AgysControlOnDateChange {
    date: Date;
    dateISOString: string;
    dateInMilliSeconds: number;
    dayCode: string;
    localizedDisplayDate: string;
    localizedDay: string;
    localizedDayLong: string;
    localizedMonthShort: string;
    localizedMonthLong: string;
    day: number;
    year: number;
    month: number;
    hour: number;
    minute: number;
    second: number;
}

export interface Option {
    id: number;
    code?: string;
    description: string;
    isActive?: boolean;
}

export interface SnackBarInputs {
    message: string;
    type: SnackBarType;
    redirectURL?:string;
    icon?:string;
    minWidth?: number;
}


export interface ImageDatas {
    id: number;
    referenceType: string;
    referenceId: number;
    sequenceNo: number;
    contentType: string;
    data: string;
    thumbnailData: string;
    imageReferenceId: string;
    name: string;
}

export interface Email {
    id:number;
    arCustomerDetailId:number;
    emailType: number;
    emailId: string;
    primaryEmail?: boolean;
    privateInformtionEmail?: boolean;
  }
  
export interface ServerPaginationConfiguration{
    initPageSize: number;
    initFrom: number;
    initTo: number;
    initPageIndex: number;
}

  export interface MultiEmail{
    name: string;
    mail: string;
    checked: boolean;
    isDrivenData: boolean;
}

export interface AdditionalGuestInfo {
    firstName: string;
    lastName: string;
    emailId: string;
}

export enum PropTimeFormat{
    Hours12 = 1,
    Hours24 = 2
}