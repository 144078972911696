import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, QueryList, ViewChildren } from '@angular/core';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { TableBlockingRuleFrequencyType } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { AllocationTypeDTO, SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { PartyService } from '@app/shared/services/party.service';
import _ from 'lodash';
import { ISubscription, Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-advance-block',
  templateUrl: './advance-block.component.html',
  styleUrls: ['./advance-block.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdvanceBlockComponent implements OnInit, AfterViewInit {
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  selectedValue: any[] = [];
  selectedShitIds: number[] = [];
  leftArrowDisabled = true;
  rightArrowDisabled = false;
  availableBtnSpace: any;
  noShowIcon: boolean;
  btnWidth: number;
  starttime: FieldConfig[];
  endtime: FieldConfig[];
  showNavigation = false;
  isInitial = true;
  shifts: any;
  isFormValid = false;
  isShiftisAvilable = true;
  selectedIndex = 0;
  selectedShiftType = 1;
  isfrequencyChanged = false;
  isDateChanged = true;
  cachedShifts = [];
  shiftAvailabilitySubscription: ISubscription;
  settingSubscription: ISubscription;
  @ViewChild('areaContainer', { static: true }) el: ElementRef;
  selectedFrequency;
  @Input() title: string;
  tabSubscription: ISubscription;
  pageInitialize = true;
  subscriptions: Subscription = new Subscription();
  allocationCodeConfig: FieldConfig[];
  allocationNoteconfig: FieldConfig[];
  fromTime: any;
  toTime: any;
  _settings: SettingsDTO = {} as SettingsDTO;
  allocationType: AllocationTypeDTO[];
  allocationTypeData : any;

  constructor(public _as: AppService, private partyService: PartyService, 
    private cs: CacheService, @Inject(COMPONENTINPUT) private data) {
    this.subscriptions.add(cs.settings.subscribe(settings => {
      this._settings = settings;
      setTimeout(() => {
        this.loadAllocations();
      })
    }));
  }


  ngOnInit() {
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === AdvanceBlockComponent);
    console.log(this.selectedIndex, 'selectedIndex')
    this.allocationCodeConfig = [{
      type: 'select',
      label: 'Select Allocation Code',
      name: 'allocationcode',
      placeholder: 'Allocation Code',
      options: this.allocationType, //[{ id: 1, value: 'By Shift' }, { id: 2, value: 'By Time Range' }, { id: 3, value: 'By Day' }],
      class: 'shift-selection shifts-area__advance-block pb-2',
      showErrorText: true,
      appearance: true,
      value: 1,
      cellClick: this.setallocationCodeConfig.bind(this)
    },
    {
      type: 'textarea',
      name: 'blockingReason',
      placeholder: 'Reason for blocking',
      label: 'Reason for blocking',
      rows: 1,
      inputType: 'text',
      showHint: true,
      charLength: 200,
      class: 'dayNoteText shifts-area__advance-block pt-5 pl-2',
    }
  ];
  if (this.data) {
    this.allocationCodeConfig[0].value = this.data.AllocationTypeId;
    this.allocationCodeConfig[1].value = this.data.BlockingReason;
    this.partyService.reservationFormGroup.get('blockingReason').setValue(this.data.BlockingReason);
  }
  this.loadAllocations();
  }
  loadAllocations() {
    this.allocationType = _.cloneDeep(this._settings.AllocationType);
    this.allocationType.forEach((type) => { if (type.Id === null) { type.Id = -1; } return type; });
    this.allocationType.unshift(this.allocationType.splice(this.allocationType.findIndex(item => item.Id === -1), 1)[0]);
    if(!this._as.engageIntegration && this.allocationType && this._settings.PropertySetting[0].IsAllocationEnabled){
      this.allocationType = this.allocationType.filter(item => item ? item.BlockType == 0 : false )
    }
    this.allocationCodeConfig[0].options = this.allocationType.map(x => ({ id: x.Id, value: x.AllocationName }));
    this.allocationTypeData = this.allocationType.map(x => ({ id: x.Id, value: x.AllocationName }));
    if (this._settings.AllocationType && this._settings.AllocationType.length > 0 && (!this.data || !this.data.AllocationTypeId)) {
      var _defaultType = this.allocationType.find(p => p.BlockType == 0);
      if (_defaultType) {
        this.allocationCodeConfig[0].value = _defaultType.Id;
        this.partyService.reservationFormGroup.get('selectedBlockBy').setValue(_defaultType.Id);
        // if (!this.data || !this.data.BlockingReason) {
        //   this.allocationCodeConfig[1].value = _defaultType.AllocationDescription;
        //   this.partyService.reservationFormGroup.get('blockingReason').setValue(_defaultType.AllocationDescription);
        // }
      }
    }
  }
  ngAfterViewInit() {
    this.dynamicForm.form.valueChanges.subscribe((data) => {
      console.log(data, 'datattaa');
      this.partyService.reservationFormGroup.get('selectedBlockBy').setValue(data.allocationcode);
      this.partyService.reservationFormGroup.get('blockingReason').setValue(data.blockingReason);
    });
  }

  // loadNavigation() {
  //   if (this.el.nativeElement.scrollWidth > this.el.nativeElement.offsetWidth) {
  //     this.showNavigation = true;
  //   } else {
  //     this.showNavigation = false;
  //   }
  // }
  // navigateLeft() {
  //   const element = this.el.nativeElement;
  //   if (element.offsetWidth < element.scrollWidth) {
  //     element.scrollLeft = element.scrollLeft - 186;
  //   }
  // }

  // navigateRight() {
  //   const element = this.el.nativeElement;
  //   if (element.offsetWidth < element.scrollWidth) {
  //     element.scrollLeft = element.scrollLeft + 186;
  //   }
  // }
  setallocationCodeConfig(event) {
    this.partyService.reservationFormGroup.get('selectedBlockBy').setValue(event.value);
  }


}
