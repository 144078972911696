import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-package-information',
  templateUrl: './package-information.component.html',
  styleUrls: ['./package-information.component.scss']
})
export class PackageInformationComponent implements OnInit {
  
  public currentPackage : []
  constructor() { }
  @Input() packageInfo;
  ngOnInit(): void {
    this.loadPackage(this.packageInfo)
  }

  /**
     * Converts a json string Object Notation (JSON) string into an Package array.
     * CommonPackageInfo length greater than 5 then only we are convert into object
     */
  loadPackage(packageInfo : string){
    if(packageInfo && packageInfo.length > 5){
      this.currentPackage = JSON.parse(packageInfo)
    }else{
      this.currentPackage = null;
    }
  }
  
}
