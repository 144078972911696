import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';

@Component({
  selector: 'app-form-add-button',
  templateUrl: './form-add-button.component.html',
  styleUrls: ['./form-add-button.component.scss']
})
export class FormAddButtonComponent implements OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;
  @Input() addBtnConfig: FieldConfig;
  constructor() { }

  ngOnInit() {
  }

  addData(event) {
    if (this.addBtnConfig) {
      this.addBtnConfig.cellClick(event);
    }
    else {
      this.config.cellClick(event);
    }

  }
}
