import { Directive, ElementRef, HostListener,  AfterViewInit,  Output, EventEmitter} from '@angular/core';
@Directive({
  selector: '[retailViewMore]'
})
export class RetailViewmoreDirective implements AfterViewInit  {
    public intialBool:boolean = true;
    @Output() NoOfElements: EventEmitter<any> = new EventEmitter();
    constructor(public el: ElementRef) {
    }
    ngAfterViewInit() {
      setTimeout(x => this.calculateNoOfElements(), 1);

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateNoOfElements()
  }

  private calculateNoOfElements(){
    let elementWidth = this.getWidth(this.el.nativeElement, false);
    let labelwidth = 200;
    let parentelementWidth =  this.getWidth(this.el.nativeElement.parentElement, true) - labelwidth;
    let calWidth = Math.round(parentelementWidth) / Math.round(elementWidth);
    let ResultView = Math.floor(calWidth);
    this.NoOfElements.emit(ResultView);
  }

  private getWidth(element, isParent){
    let style = element ? getComputedStyle(element) : null;
    let width = 0;
    if (style) {
    let margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    let padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
    width = element.offsetWidth + margin;
    if(isParent){
      width -= padding;
    }
  }
    return width;
  }
}
