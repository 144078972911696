
import { Injectable } from '@angular/core';
import { API } from 'src/app/common/data-magine/document-codes/document-codes.model';
import { InternalIntegrationCommunication } from '../communication/services/internal-intergration-service';
import { CommonApiRoutes } from "../common-route";

@Injectable({
    providedIn: 'root'
})
export class DmCodesDataService {

    constructor(private v1InternalIntegration: InternalIntegrationCommunication) {
    }

    public getAllDmCodes(IncludeInActive: boolean = false): Promise<API.DmCodes[]> {
        const result = this.v1InternalIntegration.getPromise<API.DmCodes[]>(
            { route: CommonApiRoutes.GetAllDmCodes, uriParams: { IncludeInActive } }, false);
        return result;
    }
    public getDmCodes(id: number): Promise<API.DmCodes> {
        const result = this.v1InternalIntegration.getPromise<API.DmCodes>(
            { route: CommonApiRoutes.GetDmCodes, uriParams: { id } }, false);
        return result;
    }
    public createDmCodes(body: API.DmCodes): Promise<API.DmCodes[]> {
        const result = this.v1InternalIntegration.postPromise<API.DmCodes[]>(
            { route: CommonApiRoutes.CreateDmCodes, body }, true);
        return result;
    }
    public updateDmCodes(body: API.DmCodes): Promise<API.DmCodes[]> {
        const result = this.v1InternalIntegration.putPromise<API.DmCodes[]>(
            { route: CommonApiRoutes.UpdateDmCodes, body }, false);
        return result;
    }
    public deleteDmCodes(id: number): Promise<boolean> {
        const result = this.v1InternalIntegration.deletePromise<boolean>(
            { route: CommonApiRoutes.DeleteDmCodes, uriParams: { id } }, false);
        return result;
    }

    public async getNextListOrder(): Promise<number> {
        const result: number = await this.v1InternalIntegration.getPromise<number>(
            { route: CommonApiRoutes.GetNextListOrder_DmCodes });
        return result;
    }

    public getDmDocTypesMapByDmCodeId(id: number): Promise<API.DmDocTypesMap[]> {
        const result = this.v1InternalIntegration.getPromise<API.DmDocTypesMap[]>(
            { route: CommonApiRoutes.GetDmDocTypesMapByDmCodeId, uriParams: { id } }, false);
        return result;
    }

    public updateDmDocTypesMap(body: API.DmDocTypesMap[]): Promise<API.DmDocTypesMap[]> {
        const result = this.v1InternalIntegration.putPromise<API.DmDocTypesMap[]>(
            { route: CommonApiRoutes.UpdateDmDocTypesMap, body }, false);
        return result;
    }
}

