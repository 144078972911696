import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { CardEntryModeDialogAction, CardEntryModeDialogResult, CardInputMethod } from '../service/payment/payment-business.model';

@Component({
  selector: 'app-card-entry-mode',
  templateUrl: './card-entry-mode.component.html',
  styleUrls: ['./card-entry-mode.component.scss']
})
export class CardEntryModeComponent implements OnInit {
  captions: any;
  result: CardEntryModeDialogResult;
  cardEntryModeFormGroup: UntypedFormGroup;
  cardInputMethods: { id: number; value: CardInputMethod; viewValue: string; }[];

  constructor(private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<CardEntryModeComponent>,
    private localization: RetailLocalization) {
  }

  ngOnInit() {
    this.captions = this.localization.captions.setting;
    this.cardInputMethods = [
      {
        id: 2,
        value: CardInputMethod.KeyIn,
        viewValue: this.captions.keyIn
      },
      {
        id: 3,
        value: CardInputMethod.SwipeInsertTap,
        viewValue: this.captions.swipeInsertTap
      }];
    this.cardEntryModeFormGroup = this.fb.group({
      cardInputMethod: CardInputMethod.KeyIn
    });
  }
  onSave() {
    this.result = {
      action: CardEntryModeDialogAction.Proceed,
      cardInputMethod: this.cardEntryModeFormGroup.get('cardInputMethod').value
    };
    this.dialog.close(this.result);
  }
  onCancel() {
    this.result = { action: CardEntryModeDialogAction.Cancel };
    this.dialog.close(this.result);
  }
}
