import { Injectable } from '@angular/core';
import { ReservationCommunication } from '../communication/services/reservationcommunication.service';
import { CommonApiRoutes } from "../common-route";
import { EformEmailRequest, EformEmailTemplate, GuestContactInformation } from '../data-magine/data-magine-integration/data-magine-models';

@Injectable({
    providedIn: 'root'
})

export class DmEformsDataService {
    constructor(private reservationCommunication: ReservationCommunication
    ) {
    }

    public async getEformsTemplate(eventid: number,preferredLanguageId) {
        const result = this.reservationCommunication.getPromise<EformEmailTemplate[]>(
            { route: CommonApiRoutes.GetEformsTemplate, uriParams: { eventid,preferredLanguageId }}, false);
        return result;
    }

    public SendEmail(body: EformEmailRequest): Promise<any> {
        const result = this.reservationCommunication.postPromise<any>(
            { route: CommonApiRoutes.SendEformsEmail, body }, true);
        return result;
    }

    public UpdateGuestContactDetails(body: GuestContactInformation[]): Promise<boolean> {
        const result = this.reservationCommunication.putPromise<boolean>(
            { route: CommonApiRoutes.UpdateGuestContactDetails, body }, true);
        return result;
    }

}