import { Injectable } from '@angular/core';
import { DmConfig, Product } from 'src/app/common/Models/common.models';
import { InternalIntegrationCommunication } from '../communication/services/internal-intergration-service';
import { CommonApiRoutes } from "../common-route";
import { DmFormRequest, API } from '../Models/data-magine.model';

@Injectable({
    providedIn: 'root'
})

export class DmFormDetailsDataService {
    constructor(private v1InternalIntegration: InternalIntegrationCommunication
    ) {
    }

    public async getDmFormDetails(body: DmFormRequest) {
        const result = this.v1InternalIntegration.putPromise<any>(
            { route: CommonApiRoutes.GetDmFormDetails, body}, false);
        return result;
    }

    public async getDocInformation(body : API.DmDocIndexedDetailRequest) {
        const result = this.v1InternalIntegration.putPromise<API.DmDocIndexedDetails[]>(
            { route: CommonApiRoutes.GetDocInformation, body}, false);
        return result;
    }

    public async createDocInformation(body: API.DmDocIndexedDetails[]): Promise<API.DmDocIndexedDetails[]> {
        const result = this.v1InternalIntegration.postPromise<API.DmDocIndexedDetails[]>(
            { route: CommonApiRoutes.CreateDocInformation, body }, true);
        return result;
    }

    public async deleteDocInformation(_id: number[]): Promise<boolean> {
        const result = this.v1InternalIntegration.deletePromise<boolean>(
            { route: CommonApiRoutes.DeleteDocInformation, body: _id } , false);
        return result;
    }
    

}