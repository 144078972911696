<div class="policies">
    <!-- <ng-scrollbar> -->
        <p *ngIf="generalSettings.CustomPolicyText!='' && generalSettings.CustomPolicyText!=null ">
            {{generalSettings.CustomPolicyText}}</p>
        <p *ngIf="generalSettings.CustomPolicyText==''|| generalSettings.CustomPolicyText==null ">
            {{'serverBetter' | translate}}
        </p>

        <p *ngIf="dataRetentionPolicy.nameRetentionPeriod && dataRetentionPolicy.nameRetentionPeriod!=0">
            {{'weWillKeep' | translate}} <strong>{{'guestNameText' | translate}} </strong> {{'maxDays' | translate}} <strong>
                {{dataRetentionPolicy.nameRetentionPeriod}}</strong> {{'days' | translate}}
        </p>
        <p *ngIf="dataRetentionPolicy.emailRetentionPeriod && dataRetentionPolicy.emailRetentionPeriod!=0">
            {{'weWillKeep' | translate}} <strong class="capitalize">{{'emailAddress' | translate}} </strong> {{'maxDays' | translate}}
            <strong>{{dataRetentionPolicy.emailRetentionPeriod}}</strong> {{'days' | translate}}
        </p>
        <p *ngIf="dataRetentionPolicy.phoneRetentionPeriod && dataRetentionPolicy.phoneRetentionPeriod!=0">
            {{'weWillKeep' | translate}} <strong>{{'phoneNumber' | translate}} </strong> {{'maxDays' | translate}}
            <strong>{{dataRetentionPolicy.phoneRetentionPeriod}} </strong> {{'days' | translate}}
        </p>
        <p *ngIf=" dataRetentionPolicy.addressRetentionPeriod && dataRetentionPolicy.addressRetentionPeriod!=0">
            {{'weWillKeep' | translate}} <strong>{{'guestBookDetailsAddress' | translate}} </strong> {{'maxDays' | translate}}
            <strong>{{dataRetentionPolicy.addressRetentionPeriod}} </strong> {{'days' | translate}}
        </p>
        <p *ngIf="dataRetentionPolicy.guestImageRetentionPeriod && dataRetentionPolicy.guestImageRetentionPeriod!=0">
            {{'weWillKeep' | translate}} <strong>{{'guestImage' | translate}} </strong> {{'maxDays' | translate}}
            <strong>{{dataRetentionPolicy.guestImageRetentionPeriod}} </strong> {{'days' | translate}}
        </p>
        <p
            *ngIf="dataRetentionPolicy.birthdayAndAnniversaryRetentionPeriod && dataRetentionPolicy.birthdayAndAnniversaryRetentionPeriod!=0">
            {{'weWillKeep' | translate}} <strong>{{'birthday&Anniversary' | translate}} </strong> {{'maxDays' | translate}}
            <strong>{{dataRetentionPolicy.birthdayAndAnniversaryRetentionPeriod}} </strong> {{'days' | translate}}
        </p>
        <p *ngIf="dataRetentionPolicy.customFieldRetentionPeriod && dataRetentionPolicy.customFieldRetentionPeriod!=0">
            {{'weWillKeep' | translate}} <strong>{{'customDetails' | translate}}</strong> {{'maxDays' | translate}}
            <strong>{{dataRetentionPolicy.customFieldRetentionPeriod}} </strong> {{'days' | translate}}
        </p>
    <!-- </ng-scrollbar> -->
</div>