import { AbstractControl, ValidatorFn } from '@angular/forms';

export function NegativeValueValidator(ParentScope: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        let localizedVal = Number(ParentScope.localization.currencyToSQLFormat(control.value));
        const controlVal = (localizedVal < 0 ? localizedVal * -1 : localizedVal);
        const maxVal = Number(ParentScope.remainingAmount < 0 ? ParentScope.remainingAmount * -1 : ParentScope.remainingAmount);
        if (control.value !== undefined && (isNaN(localizedVal) || controlVal > maxVal)) {
            return { 'max': true };
        }
        return null;
    };
}