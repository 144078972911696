import { RoleDTO } from "./RestaurantDTO";
import { UserRoleDTO } from "./UserRoleDTO";

export enum LoginResultType {
  Successful,
  TemporarilyLocked,
  WrongCredentials,
  ConcurrentUsersLimitationExceeded,
  LockedDueToBillingIssues,
  InActive
}

export enum RoleType {
  Host = 1,
  Reservationist = 2,
  Manager = 3,
  JuniorInstructor=4,
  SeniorInstructor=5
}

export enum LoginOperationTarget {
  Restaurant,
  Settings,
  Reports
}

export class RestaurantAvailableForLoginDTO {
  public Id: number;
  public Name: string;
  public SupportTime: string;
  public SupportContactNumber: string;
  public ApiKey: string;
  public HostRoleType: HostType;
  public PropertyType: string;
  public IsLockedDueToBillingIssues: boolean;
}

export class HostType {
  public Id: number;
  public Name: string;
  public PropertyTypeId: number;
  public RoleCode: string;
  public StatusCode: number;
}
export class RetailDetailsDTO {
  public RetailRoleId: number;
  public RetailUserId : number;
  public RetailUserName : string;
  public RetailTenantId : string;
  public RetailSiteId : string;
  public RetailPassword : string;
  public RestaurantId: number;
}
export class LoginResultDTO {
  public Result: LoginResultType;
  public LockedForMinutes: number;
  public HostId: number;
  public HostRoleType: RoleType;
  public HostPinCode: string;
  public DeviceId: number;
  public AppSectionsAvailableForLogin: LoginOperationTarget[];
  public RestaurantsAvailableForLogin: RestaurantAvailableForLoginDTO[];
  public IsFirstTimeLogin: Boolean;
  public AllowResetPin: Boolean;
  public RetailDetailDTO: RetailDetailsDTO[];
  public LoggedInRestaurantId: number;
}

export class LoginDTO{
  public EmailAddress : string;
  public Password : string;
  public Pincode : string;
  public Token ?: string;
  public MerchantId ?: string;
}
