<login-container>
  <div seat-login-text>
    <div class="seat-login__login-text">
      <label class="seat-login__heading">{{loginHeader | translate}}</label>
      <div class="seat-login__message seat-secondary-text">{{ 'welcomeToSeat' | translate  }}</div>
    </div>
  </div>
  <div seat-login__language class="seat-login__language" *ngIf="!showLogin && enableMultiLanguage">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <i class="icon-language-filled seat-login__language-icon"></i>
      <span class="seat-header-four language-chosen mx-1">{{languageChosen.toUpperCase()}}</span>
      <span class="icon-Dropdown-2"></span>
    </button>
  </div>
  <div forms class="seat-login__forms">
    <div [hidden]="showSsoLink" class="seat-login__register-form">
      <dynamic-form [config]="customerConfig" #customerDetails>
      </dynamic-form>
      <activities-app-button [buttontype]="verifyButton" [hidden]="gotMerchant" (valueChange)='getMerchant()'></activities-app-button>
      <activities-app-button [buttontype]="unlockWithPasswordButton" [hidden]="gotMerchant" (valueChange)='unlockWithPassword()'></activities-app-button>
    </div>
    <!-- Unlock page form -->
    <div [hidden]="!showLogin || gotMerchant" class="seat-login__register-form">
      <dynamic-form [config]="unlockconfig" #unlockForm>
      </dynamic-form>
      <activities-app-button [buttontype]="unlockButton" (valueChange)='unlock()'></activities-app-button>
      <activities-app-button [buttontype]="ssoButton" (valueChange)='showSSO()'></activities-app-button>
    </div>
    <!-- Register page form -->
    <div [hidden]="showLogin || isMachineDetailsAvailable || !gotMerchant" class="seat-login__register-form">
      <dynamic-form [config]="loginConfig" #pinLoginForm>
      </dynamic-form>
      <activities-app-button [buttontype]="loginButton" (valueChange)='login()'></activities-app-button>
    </div>
    <!-- Machine selection -->
    <div [hidden]="showLogin || !isMachineDetailsAvailable || !gotMerchant" class="seat-login__register-form machine-form rs-mt-15">
      <dynamic-form [config]="mahcineConfig" #machineSelectionForm>
      </dynamic-form>
      <activities-app-button [buttontype]="machineButton" (valueChange)='selectedMachine()'></activities-app-button>
    </div>
  </div>
</login-container>
<mat-menu #menu="matMenu" yPosition="below">
  <button mat-menu-item *ngFor="let language of availableLanguages" (click)="setDefaultLanguage(language,true)">
    <mat-icon [ngStyle]="{'color': language.Code == languageChosen ? '#1da664' : 'transparent' }">done</mat-icon>
  {{language.DisplayName}}
  </button>
</mat-menu>