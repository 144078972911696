<div class="filter-slots">
    <div class="filter-slots__popup-menu m-1">
        <a [ngClass]="{'filter-popup__active': isFilterEnabled}" class="filter-btn" #filterMenuPopover="ngbPopover" [ngbPopover]="filterMenu" [autoClose]="false" popoverClass="filter-popover"
            triggers="manual" placement="auto" container="body" (click)="filterMenuPopover.toggle()"> {{_MESSAGES.CONFIG.filterText |
            translate }} : {{(isFilterEnabled ? _MESSAGES.CONFIG.filterOnText : _MESSAGES.CONFIG.filterOffText) | translate }}
            <span class="icon-down-arrow pl-2 dropdown-arrow"></span></a>
        <div class="filter-slots__options pr-3">
            <span class="icon-refresh pr-3" (click)="refreshSlotsData()"></span>
            <activities-app-button *ngIf="!slotService.isEditEnabled; else viewCancel" [buttontype]="editBtn"
                (valueChange)="enableDisableSlot()"></activities-app-button>
            <ng-template #viewCancel>
                <activities-app-button [buttontype]="cancelBtn" (valueChange)="enableDisableSlot()"></activities-app-button></ng-template>
        </div>
    </div>
</div>
<div class="slots">
    <ng-scrollbar>
        <div *ngFor="let slotTime of slotService.filteredSlots$ | async; last as lastSlot" [ngClass]="{'slots__space-content': lastSlot}">
            <div class="slots__time mx-1">{{slotTime.DateTime | slotTimeFormatter}} <span *ngIf="slotTime.DateTime | slotDateFormat">({{slotTime.DateTime | slotDateFormat }})</span></div>
            <div class="slots__grid">
            <div class="m-2 slot_width" *ngFor="let party of slotTime.PartyList">
                    <app-slots-section [data]="party" [partyDetails]="slotService.reservationDetails[party.PartyId]" [dateTime]="slotTime.DateTime" [isEdit]="slotService.isEditEnabled"></app-slots-section>
                </div>
                <div class="m-2 slot_width" *ngFor="let section of slotTime.Sections">
                    <app-slots-section [data]="section" [dateTime]="slotTime.DateTime" [isEdit]="slotService.isEditEnabled"></app-slots-section>
                </div>
                <div *ngIf="!(slotTime.DateTime | expiredSlot: settings);else standByView" class="slots__create seat-tertiary-text py-2" [ngClass]="{'slots__expired': !(slotTime.DateTime | expiredSlot: settings)}">
                    {{'noSlotsAvailable' | translate}}
                </div>
                <ng-template #standByView>
                    <div class="slots__create seat-tertiary-text py-2" [ngClass]="{'slots__add': settings.General.SlottingMode == SlottingMode.Manual}">
                        <div (click)="slotService.addToStandBy(slotTime.DateTime)"><label class="pointer">{{_MESSAGES.CONFIG.addToStandby | translate}}</label></div>
                        <div #addSlotContainer *ngIf="settings.General.SlottingMode == SlottingMode.Manual" [autoClose]="false" triggers="manual" #addSlotPopup="ngbPopover"  popoverClass="add-popover{{slotTime.DateTime}} addslots-popover"
                        [ngbPopover]="addSlot" [placement]="'left-bottom'" container="body" (click)="openSlotPopup(slotTime.DateTime)"><label class="pointer">{{_MESSAGES.CONFIG.addSlots | translate}}</label></div>
                    </div>
                </ng-template>
            </div>
        </div>
    </ng-scrollbar>
    <ng-template #addSlot>
        <div class="slots__add-slot">
            <app-new-slot [isAdd]="true" [isSlots]="true" (closeSlot)="closeSlotPopover()" [slotTime]="slotDateTime"></app-new-slot>
        </div>
    </ng-template>
</div>

<ng-template class="arrow-up" #filterMenu>
    <div class="filter-popup">
        <i class="close-popup icon-Group-591" (click)="closeFilter()"></i>
        <div class="filter-popup__actions">
            <dynamic-form [config]="partySizeConfig" #form="dynamicForm">
            </dynamic-form>
        </div>
        <div class="filter-popup__actions">
            <div *ngFor="let hideFilter of hideFilters;let i = index;" class="filter-popup__hide-filter" [ngClass]="{'filter-popup__active': hideFilter.isActive}" (click)="changeEvent(i)">
                {{hideFilter.name | translate}}
            </div>
        </div>
        <div class="filter-popup__actions">
            <div class="filter-popup__reset-filter" (click)="resetFilter()">
                {{_MESSAGES.CONFIG.resetFilter | translate}}
            </div>
        </div>
    </div>
</ng-template>