import { Component, Input, OnChanges } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { PartyState, PaymentMethod } from '@app/shared/constants/commonenums';
import { PartyChargeState, PartyPrepaymentState } from '@app/shared/models/InputContact';
import { BookingChargeType } from '@app/shared/models/RestaurantDTO';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import { response } from 'express';
import { round } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-financial-status-popup',
  templateUrl: './financial-status-popup.component.html',
  styleUrls: ['./financial-status-popup.component.scss']
})
export class FinancialStatusPopupComponent implements OnChanges {
  @Input() partyData;
  financialDetail: any;
  paymentResponse: any;
  subscriptions: Subscription = new Subscription();

  constructor(private partyService: PartyService, private translateService: TranslateService,private cs: CacheService) { }

  ngOnChanges(): void {
    this.financialDetails(this.partyData)
  }

  financialDetails(attendee) {
    this.subscriptions.add(this.partyService.getPartyPaymentStaus(attendee.Id).subscribe(responseData => {
      let response = responseData.Payload;
      this.paymentResponse = response;
    this.financialDetail = {};
    this.financialDetail.header = this.translateService.instant("financialdetails");

   // let partyAmount = attendee['PaidAmount'];
    let sessionTotalAmount = 0;
    let sessionRatePlanAmount = 0;
    let sessionAddonAmount = 0;
    if (attendee.BookedSessionId) {
      let session = attendee.BookedSessions.filter(x => x.Id == attendee.BookedSessionId)
      if (session && session.length) {
        sessionRatePlanAmount += ((session[0].PackageDiscountedAmount || 0) + (session[0].RatePlanAmount || 0) + (session[0].TaxAmount || 0))
      }
      sessionTotalAmount += sessionRatePlanAmount

      if (attendee.AddonItemAmount && attendee.BookingContactAddonItems && attendee.BookingContactAddonItems.length > 0) {
        var addonbysession = attendee.BookingContactAddonItems.filter(x => x.BookedSessionId == attendee.BookedSessionId);
        addonbysession.forEach(x => {
          sessionAddonAmount += (x.Amount || 0) + (x.TaxAmount || 0)
        });
      }
      sessionTotalAmount += sessionAddonAmount;
      if (attendee.BookingAmounts && attendee.BookingAmounts.length > 0) {
        var bookingAmountSession = attendee.BookingAmounts.filter(x => x.BookedSessionId == attendee.BookedSessionId && attendee.BookingChargeType != BookingChargeType.Tax);
        bookingAmountSession.forEach(x => {
          if (x.BookingChargeType == BookingChargeType.ServiceCharge) {
            sessionTotalAmount += (x.Amount || 0);
          }
          else if (x.BookingChargeType == BookingChargeType.Negotiation) {
            sessionTotalAmount += (x.NegotiatedAmount || 0);
          }
        });
      }
    }



    if ((!attendee.CreditCardId && attendee.PrepaymentState == PartyPrepaymentState.DeferredPayment && attendee.ChargeState == PartyChargeState.Pending)) {
      this.financialDetail.message = this.translateService.instant('nocreditcardcollected');
    }
    else if (attendee.PrepaymentState == PartyPrepaymentState.DepositCollected || attendee.PrepaymentState == PartyPrepaymentState.PaymentDuePending) {
     // this.subscriptions.add(this.partyService.getPartyPaymentStaus(attendee.Id).subscribe(response => {
        if (response) {
         // let data = response.Payload
          if (response.PartyId != attendee.Id) {
            return;
          }
          this.financialDetail.message = this.translateService.instant('guestPaidText', {paymentAmount: this.cs.settings.value.General.OperationCurrency + round(Number(response.PaidAmount), 2)});
          this.financialDetail.paymentduemessage = this.translateService.instant('paymentDue', {paymentAmount: this.cs.settings.value.General.OperationCurrency + Math.abs(round(Number(response.PendingAmount), 2))});
        }
        else {
          this.financialDetail.message = this.translateService.instant("guestPaymentDueInfoText");
        }
     // })
      //);

    }
    else if (attendee.SessionGroupId > 0 && (attendee.PrepaymentState == PartyPrepaymentState.Prepaid || (attendee.PrepaymentState == PartyPrepaymentState.DeferredPayment && attendee.ChargeState == PartyChargeState.Charged)) && attendee.CreditCardId) {
      this.financialDetail.message = this.translateService.instant('guestPaidForClassText', {paymentAmount: this.cs.settings.value.General.OperationCurrency + round(Number(response.PaidAmount), 2)});
    }
    else if (attendee.SessionGroupId > 0 && attendee.PrepaymentState == PartyPrepaymentState.DeferredPayment && attendee.ChargeState == PartyChargeState.Pending && attendee.CreditCardId) {
      this.financialDetail.message = this.translateService.instant('guestCardAuthorizedForClassText', {paymentAmount: this.cs.settings.value.General.OperationCurrency + round(Number(response.PendingAmount), 2)});
    }
    else if (attendee.PrepaymentState == PartyPrepaymentState.Prepaid || (attendee.PrepaymentState == PartyPrepaymentState.DeferredPayment && attendee.ChargeState == PartyChargeState.Charged) && attendee.CreditCardId) {

      this.financialDetail.message = this.translateService.instant('guestPaidForSessionText', {paymentAmount: this.cs.settings.value.General.OperationCurrency + round(Number(response.PaidAmount), 2)}); //for now displaying full party amount against each session
    }
    else if (attendee.PrepaymentState == PartyPrepaymentState.DeferredPayment && attendee.ChargeState == PartyChargeState.Pending && attendee.CreditCardId) {
      let addonAmounttobeAdded = 0;

      this.financialDetail.message = this.translateService.instant('guestCardAuthorizedForSessionText', {paymentAmount: this.cs.settings.value.General.OperationCurrency + round(Number(response.PendingAmount), 2)});
    }
    else if (attendee.CreditCardId && attendee.NoShowFeeAmount > 0) {
      this.financialDetail.message = this.translateService.instant('guestAuthorized', {paymentAmount: this.cs.settings.value.General.OperationCurrency + round(Number(attendee.NoShowFeeAmount), 2)});
    }
    else if (attendee.PrepaymentState == PartyPrepaymentState.PrepaymentRequired) {
      this.financialDetail.message = attendee?.PaymentMethod == PaymentMethod.Authorize ? this.translateService.instant('CardAuthorized') : this.translateService.instant('NoPaymentCollected');
      this.financialDetail.paymentduemessage = this.translateService.instant('paymentDue', {paymentAmount: this.cs.settings.value.General.OperationCurrency + round(Number(response.PendingAmount), 2)});
    }
    else if (attendee.BookedSessionId && ((sessionRatePlanAmount > 0 || sessionAddonAmount > 0) && !response.RefundedAmount && response.PaidAmount == 0)) {
      this.financialDetail.message = this.translateService.instant("CompleteWaiveoffText");
    }
    else if (((attendee.RatePlanAmount > 0 || attendee.AddonItemAmount > 0) && !response.RefundedAmount && response.PaidAmount == 0 )) {
      this.financialDetail.message = this.translateService.instant("CompleteWaiveoffText");
    }
    else if (attendee.PrepaymentState == PartyPrepaymentState.RefundDuePending) {
    //  this.subscriptions.add(this.partyService.getPartyPaymentStaus(attendee.Id).subscribe(response => {
        if (response) {
          if (response.PartyId != attendee.Id) {
            return;
          }

          if(response.PaidAmount){
            this.financialDetail.message = this.translateService.instant('guestPaidText', {paymentAmount: this.cs.settings.value.General.OperationCurrency + round(Number(response.PaidAmount), 2)});
          }

          this.financialDetail.subMessage = this.translateService.instant('RefundPendingAmountText', {paymentAmount: this.cs.settings.value.General.OperationCurrency + Math.abs(round(Number(response.PendingAmount), 2))});
        }
        else {
          this.financialDetail.message = this.translateService.instant('GuestPaymentRefundPending');
        }
   //   })
    //  )


    }
    else if (attendee.State == PartyState.Cancelled && attendee.PrepaymentState !== PartyPrepaymentState.None) {
      if (response) {
       // let data = response.Payload
        if (response.PartyId != attendee.Id) {
          return;
        }
        if (response.PrepaidAmount) {
          this.financialDetail.paymentMessage = this.translateService.instant('guestPaidForBookingText', {paymentAmount: `${this.cs.settings.value.General.OperationCurrency}${round(Number(response.PrepaidAmount), 2)}`});
        }
        if (response.RefundedAmount) {
          this.financialDetail.subMessage = this.translateService.instant('guestRefundedForBookingText', {paymentAmount: `${this.cs.settings.value.General.OperationCurrency}${Math.abs(round(Number(response.RefundedAmount), 2))}`});
        }
      }
      if (attendee.PrepaymentState == PartyPrepaymentState.CancellationFeeApplied || attendee.CancellationFeeAmount || attendee.CancellationFee )
      this.financialDetail.message = this.translateService.instant('guestChanrgeForCancellationText', {paymentAmount: `${this.cs.settings.value.General.OperationCurrency}${round(Number(attendee.CancellationFeeAmount || attendee.CancellationFee || 0), 2)}`});

    }
    else {
      this.financialDetail.message = this.translateService.instant("NoPaymentInvolvedText");
      if (response) {
         if (response.PartyId != attendee.Id) {
           return;
         }
         if (response.PrepaidAmount) {
           this.financialDetail.paymentMessage = this.translateService.instant('guestPaidForBookingText', {paymentAmount: `${this.cs.settings.value.General.OperationCurrency}${round(Number(response.PrepaidAmount), 2)}`});
         }
         if (response.RefundedAmount) {
           this.financialDetail.subMessage = this.translateService.instant('guestRefundedForBookingText', {paymentAmount: `${this.cs.settings.value.General.OperationCurrency}${Math.abs(round(Number(response.RefundedAmount), 2))}`});
         }
       }
    }
  }));


  }

  ngOnDestroy() {
    if(this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
