<div class="session-slots" *ngIf="intialized">
    <ng-container *ngFor="let slot of SessionSlots; index as i">
      <div class="session-slot">
        <input [disabled]="(slot.disabled || slot.disabledOverlaped)" class="session-slot__checkbox" [id]="'session_'+i+'_'+slot.Start"
          type="checkbox" [(ngModel)]="SessionSlots[i].checked" (click)="onSlotClick($event, SessionSlots[i])" (change)="onSlotSelectSuccess(SessionSlots[i])"/>
        <label [attr.for]="'session_'+i+'_'+slot.Start" [ngClass]="slot.stateClass + ' session-slot__btn'"
          [ngStyle]="{'border-color':slot.color, 'color': slot.color}"
          [ngbPopover]="!slot.checked && ((AllowStandBy && slot?.Availability?.AvailableStandBySlots)  || AllowOverbook) && (slot.Availability?.AvailabilityType === _AvailabilityType.SoldOut || !slot.IsAddonAvailable) ? disabledSlotActionPopup : ''">
          <div *ngIf="!slot.SessionGroupId" class="session-slot__session-name"
            title="{{'Session' | translate}} {{i+1}}">{{'Session' | translate}} {{i+1}}</div>
          <div *ngIf="slot.SessionGroupId" class="session-slot__session-name" [title]="slot.SessionGroupName">
            {{slot.SessionGroupName}}</div>
          <span *ngIf="!slot.SessionGroupId">{{slot.Start | localizedDate:'LT'}} - {{slot.End | localizedDate:'LT'}}</span>
        </label>

      </div>
      
      <ng-template #disabledSlotActionPopup>
        <activities-app-button *ngIf="AllowOverbook" [buttontype]="overbookButton" (click)="soldOutSessionSelection(slot, 'overbook')"></activities-app-button>
        <activities-app-button *ngIf="AllowStandBy && slot?.Availability?.AvailableStandBySlots" [buttontype]="addToStandByButton" (click)="soldOutSessionSelection(slot, 'standby')"></activities-app-button>
      </ng-template>
    </ng-container>

</div>


<div class="activity-booking__slot-container" *ngIf="!SessionSlots || !SessionSlots.length">
  <div class="activity-booking__no-slots">{{"NoSlotsMessage" | translate}}</div>
</div>
