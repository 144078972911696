import { Injectable,SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CacheService } from '@app/core/services/cache.service';

@Injectable({
  providedIn: 'root'
})

export class DynamicFormService {
  selectedCountryCode: string;
  selectedCountryId: number;
  requiredPhoneNumberLength$: number;
  constructor(private domSanitizer: DomSanitizer, private cs: CacheService) { }
  HTMLPattern = "^[^<>]+$"
  
  onInputChange(event, backspace) {
    let newVal = event ? event.replace(/\D/g, '') : '';
    //let newVal =  backspace? event:event.replace(/\D/g, '');

    let stringCount;
    if (newVal) {
      if(backspace){
        newVal = newVal.substring(0, newVal.length);
      }
      else{
        stringCount = this.cs.settings.value.Countries.find(country => country.Id == (this.selectedCountryId))?.PhoneNumberLength;
        let phoneNumberFormatArray = (this.cs.settings.value.Countries.find(country => country.Id == (this.selectedCountryId))?.PhoneNumberPattern.split(', '))
        if(phoneNumberFormatArray && phoneNumberFormatArray.length){
        newVal = newVal.replace(this.getFormat(phoneNumberFormatArray[0]), this.getFormat(phoneNumberFormatArray[1]));
        }
   //     eval(this.cs.settings.value.Countries.find(country => country.Id == (this.selectedCountryId))?.PhoneNumberPattern.split(', ')[0]), eval(this.cs.settings.value.Countries.find(country => country.Id == this.selectedCountryId)?.PhoneNumberPattern.split(', ')[1])
       }
        
      // switch (this.selectedCountryCode) {
      //   case '+61'://Australia
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 11;
      //       newVal = newVal.replace(/^(\d{0,1})(\d{0,4})(\d{0,4})/, '$1 $2 $3');
      //     }
      //     break;
      //   case '+43': // Austria
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 10;
      //       newVal = newVal.replace(/^(\d{0,3})(\d{0,6})/, '$1 $2');
      //     }
      //     break;
      //   case '+32'://Belgium
      //     if (backspace) {
      //       newVal = newVal.slice(0, newVal.length);
      //     } else {
      //       stringCount = 10;
      //       newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1 $2 $3');
      //     }

      //     break;
      //   case '+56'://Chile
      //   case '+353'://Ireland
      //   case '+31'://Netherlands
      //   case '+63'://Philphines
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 11;
      //       newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})/, '$1 $2 $3');
      //     }
      //     break;

      //   case '+385'://Croatia
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 12;
      //       newVal = newVal.replace(/^(\d{0,4})(\d{0,3})(\d{0,3})/, '$1 $2 $3');
      //     }

      //     break;
      //   case '+420'://Czech Republic
      //   case '+351'://Portugal
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 11;
      //       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1 $2 $3');
      //     }
      //     break;
      //   case '+372'://Estonia
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 7;
      //       newVal = newVal.replace(/^(\d{0,7})/, '$1');
      //     }
      //     break;
      //   case '+33'://France
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 12;
      //       newVal = newVal.replace(/^(\d{0,1})(\d{0,2})(\d{0,2})(\d{0,4})/, '$1 $2 $3 $4');
      //     }
      //     break;
      //   case '+49'://Germany
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 10;
      //       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,2})/, '$1-$2 $3');
      //     }
      //     break;
      //   case '+852'://Hong Kong
      //   case '+502'://Gautemala
      //   case '+504'://Honduras
      //   case '+65'://Singapore
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 9;
      //       newVal = newVal.replace(/^(\d{0,4})(\d{0,4})/, '$1 $2');
      //     }
      //     break;
      //   case '+36'://Hungary
      //   case '+371': //Latvia
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 10;
      //       newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})/, '$1 $2 $3');
      //     }

      //     break;
      //   case '+62'://Indonesia
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 13;
      //       newVal = newVal.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})/, '$1 $2 $3');
      //     }

      //     break;
      //   case '+972'://Isarel
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 11;
      //       newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
      //     }

      //     break;
      //   case '+39'://Italy
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 11;
      //       newVal = newVal.replace(/^(\d{0,3})(\d{0,7})/, '$1 $2');
      //     }
      //     break;
      //   case '+370'://Lithuania
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 10;
      //       newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,4})/, '$1 $2 $3');
      //     }

      //     break;
      //   case '+60'://Malaysia
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 12;
      //       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2 $3');
      //     }

      //     break;
      //   case '+47'://Norway
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 11;
      //       newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4');
      //     }

      //     break;
      //   case '+48'://poland
      //   case '+46'://Swedan
      //   case '+41'://Switzerland
      //     if (backspace) {
      //       newVal = newVal.substring(0, newVal.length);
      //     } else {
      //       stringCount = 12;
      //       newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4');
      //     }
      //     break;
      //   case '+34'://Spain
      //     if (backspace) {
      //       newVal = newVal.slice(0, newVal.length);
      //     }
      //     else {
      //       stringCount = 12;
      //       newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4');
      //     }
      //     break;
      //   case '+44': //UK
      //     if (backspace) {
      //       newVal = newVal.slice(0, newVal.length);
      //     }
      //     else {
      //       stringCount = 12;
      //       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1 $2 $3');
      //     }
      //     break;

      //   case '+66'://Thailand
      //     if (backspace) {
      //       newVal = newVal.slice(0, newVal.length);
      //     }
      //     else {
      //       stringCount = 10;
      //       newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,4})/, '$1 $2 $3');
      //     }
      //     break;

      //   case '+1'://Canada   //USA //Puerto Rico

      //     if (backspace) {
      //       newVal = newVal.slice(0, newVal.length);
      //     } else {
      //       stringCount = 14;
      //       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
      //     }

      //     break;
      //   case '+86': // China
      //     if (backspace) {
      //       newVal = newVal.slice(0, newVal.length);
      //     } else {
      //       stringCount = 13;
      //       newVal = newVal.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})/, '$1 $2 $3');
      //     }
      //     break;
      //   default:
      //     if (backspace) {
      //       newVal = newVal.slice(0, newVal.length);
      //     }
      //     break;
      // }
    }

    if (this.cs.settings.value.General.ValidateGuestPhoneNumber) {
      this.requiredPhoneNumberLength$ = stringCount;
    } else {
      this.requiredPhoneNumberLength$ = null;
    }
    return newVal;
  }
  getFormat(format){
    return new Function( 'return (' + format + ')' )()
  }
  inputvalidation(value){
    let secureText = this.domSanitizer.sanitize(SecurityContext.HTML, value);
    if(secureText == value || value.includes('\n')) { //Allow enter key values 
      return false;
    }else{
      return true;
    }
  }
}
