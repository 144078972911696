import { EventEmitter, Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class routingMenuService {
    selectedRoutingMenu:any;
    moreselectedText:string;
    triggerADB2CLogOut=new EventEmitter<boolean>();
    selectedRoutingPath: string;

    constructor()
    {
        
        this.selectedRoutingPath=sessionStorage.getItem('selectedRoutingPath');
    }
   
    setselectedRoutingMenu(obj,text:string=''){
        this.selectedRoutingMenu = obj;
        if(obj?.routePath)
        {
            this.selectedRoutingPath=obj?.routePath;
            sessionStorage.setItem('selectedRoutingPath',obj?.routePath);
        }
        if(text) this.moreselectedText = obj;
    
    }

}
