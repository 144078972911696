import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RestaurantAvailableForLoginDTO } from '@app/shared/models/AuthenticationResultDTO';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppService } from '@app/app.service';
import { LoginService } from '../services/login.service';


@Injectable()

export class LoginBusiness {
    public loginFormGroup: UntypedFormGroup;
    public  filteredOptions: any;
    constructor(private fb: UntypedFormBuilder, private as: AppService, private ls:LoginService) {
    
        this.intializeFormGroup();
      //  this.loadData();
    }

    /***
     * @function intializeFormGroup
     * @input Params nil
     * To intialize the form group with required form controls
   */
    async intializeFormGroup() {
        this.loginFormGroup = this.fb.group({
            language: ['', Validators.required],
            restaurant: ['', Validators.required],
            lockPin: ['', Validators.required]
        });       
    }

    /***
     * @function intializeFormGroup
     * @input Params nil
     * To intialize the form group with required form controls
    */
      async loadData() {
      //await this.ls.loadRestaurantsList().subscribe(x => this.filteredOptions = x);     
    
      //this.filteredOptions.
        // this.filteredOptions = this.loginFormGroup.controls.restaurant.valueChanges
        // .pipe(
        //   startWith(''),
        //   map(value => this._filter(value))
        // );
    }


    private _filter(value: string): RestaurantAvailableForLoginDTO[] {
        const filterValue = value.toLowerCase();
      if (!this.as.unlockRestaurantData || !this.as.unlockRestaurantData.RestaurantsAvailableForLogin)
          return;
      return this.as.unlockRestaurantData.RestaurantsAvailableForLogin.filter(option => option.Name.toLowerCase().includes(filterValue));
      }
}
