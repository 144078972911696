import { HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes, ContentView, LessonType, Menu, ReservationEmailNotificationType, ResponseType, Status, ValidationMessageType } from '@constants/commonenums';
import { ActivitiesVenue, AnyMembers, AnyVenue, chineseLanguageCodes, controlSettings, DEFAULT_LANGUAGE_CODE, DEFAULT_LANGUAGE_ID, DineInVenue, popupDialogDimension, propertyOfTypeTheatre } from '@constants/globalConstants';
import sideMenu from '@data/sidemenu.json';
import sidemenuforhost from '@data/sidemenuforhost.json';
import sidemenuforTennisProperty from '@data/sidemenuforTennisProperty.json';
import sidemenuforTheatreProperty from '@data/sidemenuforTheatreProperty.json';
import { RestaurantAvailableForLoginDTO } from '@models/AuthenticationResultDTO';
import { ContactNotes, PartyNotes } from '@models/global.interface';
import { LoginResultDTO, RoleType } from '@models/LoginResultDTO';
import { ActivitySessionsDTO, AddonActivitiesMappingsDTO, AddonDTO, BookingChargeType, CalculationType, Category, ContactNoteDTO, CountryDTO, FloorPlanDTO, OpenHoursDTO, PageMethod, PartyDTO, PredefinedContactNoteDTO, PredefinedPartyNoteDTO, RetailIntegrationDTO, SelectionType, SettingsDTO, ShiftDTO, SpecialMealDTO, StandaloneTableDTO, StateDTO, TaxesDTO } from '@models/RestaurantDTO';
import { TimeRangeDTO } from '@models/TimeRangeDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import * as CryptoJS from 'crypto-js';
import _ from 'lodash';
import moment from 'moment';
import { RestaurantPoliciesComponent } from '../components/create-update-party-tab-layout/selections/restaurant-policies/restaurant-policies.component';
import { AddOnAvailability } from '../models/InputReservation';
import { EffectiveRangePartiesPipe } from '../pipes/effective-range-parties.pipe';
import { RSAHelper } from './RSA/rsa-helper';
import { PageMethods } from '../models/InputContact';
import { AutoFocusTarget } from '@angular/cdk/dialog';
import { headerWithRestaurantIds, urlConfig } from '@constants/url-config';

export abstract class Utilities {
  constructor(protected dialog: MatDialog, protected translateService?: TranslateService) {
    Utilities._restaurantId = Number(sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`));
  }
  public static _restaurantId: number;
  public static _managerPin: string;
  public static key = "MIIBOQIBAAJBAMrM";
  public static IV = "LINAN7snxGRArw1d";

  public static Date(date?: any) {
    return date ? moment(date) : moment();
  }

  public static tryParse(str: string): boolean {
    try {
      const json = JSON.parse(str);
      return (typeof json === 'object');
    } catch (e) {
      return false;
    }
  }

  public static tryParseObject<T>(str: string): T {
    try {
      const obj = JSON.parse(str);
      if (obj && typeof obj === 'object') {
        return obj;
      }
    } catch (e) {
      return undefined;
    }
  }

  static RestaurantId() {
    //return this._restaurantId ? this._restaurantId : Number(sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`));
    return Number(sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`));
  }

  static setRestaurantId(restaurantid: number) {
    sessionStorage.setItem(`restaurantId${Utilities.getSessionStorageType()}`, restaurantid.toString());
    //this._restaurantId = restaurantid;
  }

  static setLatestRevision(updatedRevision, restaurantId = Utilities.RestaurantId()) {
    localStorage.setItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${restaurantId}_revision`, JSON.stringify(updatedRevision));
  }

  static getLatestRevision(restaurantId = Utilities.RestaurantId()) {
    return Number(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${restaurantId}_revision`));
  }

  static getLanguageId(){
    return Number( sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`));
  }

  static getHubConnectionId() {
    return localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_hubConnectionId`);
  }

  public static getMenuNameById(id: number) {

    const hostRoleType = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostRoleType;
    if (ActivitiesVenue.indexOf(this.getPropertyType()) > -1) {

      if (hostRoleType && (hostRoleType.Name === RoleType[RoleType.Manager] || hostRoleType.Name === RoleType[RoleType.SeniorInstructor])) {
        if (id === 0)
          id = Menu.ActivitiesTimeline;
        return sidemenuforTennisProperty.menus.find(menu => menu.id == id).header_text;
      }
      else {
        let menuInfo = sidemenuforTennisProperty.menus.find(menu => menu.id == id);
        if (menuInfo) {
          return menuInfo.name;
        } else {
          return sidemenuforTennisProperty.menus[0].name
        }
      }
    }
    else {
      if (propertyOfTypeTheatre.indexOf(this.getPropertyType()) > -1) {
        if (hostRoleType && hostRoleType.Name === RoleType[RoleType.Manager]) {
          return sidemenuforTheatreProperty.menus.find(menu => menu.id == id)?.header_text;
        } else {
          return sidemenuforhost.menus.find(menu => menu.id == id)?.name;
        }
      } else {
        if (hostRoleType && hostRoleType.Name === RoleType[RoleType.Manager]) {
          return sideMenu.menus.find(menu => menu.id == id)?.header_text;
        } else {
          return sidemenuforhost.menus.find(menu => menu.id == id)?.name;
        }
      }
    }
  }

  public static setImpersonateHost(mgrPin: string) {
    if (mgrPin) {
      this._managerPin = this.encryptRSA(mgrPin);
    } else {
      this._managerPin = mgrPin;
    }
  }

  public static getHeaders(restaurantID?: number, isRetailHeaderRequired:boolean = false , request?: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    const sessionType = Utilities.getSessionStorageType()

    let loginResult: LoginResultDTO = {} as LoginResultDTO;
    if (Utilities.tryParse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`))) {
      loginResult = Utilities.tryParseObject<LoginResultDTO>(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    }

    if (sessionStorage.getItem(`ClientUid${sessionType}`)) {
      httpOptions.headers = httpOptions.headers.set('x-ts-client-uid', sessionStorage.getItem(`ClientUid${sessionType}`));
    }
    if (loginResult && loginResult.HostId) {
      httpOptions.headers = httpOptions.headers.set('x-ts-host-id', loginResult.HostId.toString());
    }
    let customHeaderKeys = Object.keys(headerWithRestaurantIds).filter(k => isNaN(Number(k)));
    if(customHeaderKeys.filter(k => request?.url.includes(urlConfig[k]))?.length && request?.url){
      let cartItems = request.body?.map(item => {return item.PropertyId});
      let selectedProperty =  loginResult.RestaurantsAvailableForLogin.filter(property => cartItems.includes(property.Id));
      httpOptions.headers = httpOptions.headers.set('Restaurant-Ids' , selectedProperty.map(r => { return r.Id}).toString());
      selectedProperty.forEach(r => {
        httpOptions.headers = httpOptions.headers.set('x-ts-restaurant-api-key_'+ r.Id, r.ApiKey)
      })
    }else if(restaurantID && loginResult && loginResult.RestaurantsAvailableForLogin &&
      loginResult.RestaurantsAvailableForLogin.find(r => r.Id === restaurantID)){
        httpOptions.headers = httpOptions.headers.set('x-ts-restaurant-api-key',
        loginResult.RestaurantsAvailableForLogin.find(r => r.Id === restaurantID).ApiKey);
    }


    if (sessionStorage.getItem(`MerchantKey${Utilities.getSessionStorageType()}`)) {
      httpOptions.headers = httpOptions.headers.set('x-ts-merchant-auth-key',
        sessionStorage.getItem(`MerchantKey${Utilities.getSessionStorageType()}`));
    }

    if (isRetailHeaderRequired) {
      if (sessionStorage.getItem('_jwt')) {
        httpOptions.headers = httpOptions.headers.set('retail-token',
          sessionStorage.getItem('_jwt'));
      }
      if (sessionStorage.getItem('userSession')) {
        httpOptions.headers = httpOptions.headers.set('retail-session',
          sessionStorage.getItem('userSession'));
      }
    }

    if (this._managerPin) {
      httpOptions.headers = httpOptions.headers.set('x-ts-impersonate-host-pin-code',
        this._managerPin);
    }
    httpOptions.headers = httpOptions.headers.set('Language-Id', ( sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`) || DEFAULT_LANGUAGE_ID.toString()));
    if (localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_hubConnectionId`)) {
      httpOptions.headers = httpOptions.headers.set('signalr-connectionid', localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_hubConnectionId`));
    }
    httpOptions.headers = httpOptions.headers.set('Access-Control-Allow-Origin', '*.rguest.com');

    return httpOptions;
  }

  public static getRandomDecimal(): number {
    const crypto = window.crypto;
    return crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
  }

  public static getHostId() {
    let loginResult: LoginResultDTO = {} as LoginResultDTO;
    if (Utilities.tryParse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`))) {
      loginResult = Utilities.tryParseObject<LoginResultDTO>(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    }
    return loginResult.HostId;
  }

  public static GetRestaurantApiKey(restaurantid: any) {
    let restaurantkey: any;
    if (Utilities.tryParse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`))) {
      let loginResult: LoginResultDTO = {} as LoginResultDTO;
      loginResult = Utilities.tryParseObject<LoginResultDTO>(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
      restaurantkey = loginResult.RestaurantsAvailableForLogin.find(r => r.Id === restaurantid).ApiKey;
    }

    return restaurantkey;
  }

  public static encryptRSA(value): string {
    return RSAHelper.encryptWithPublicKey(value);
  }

  public static encrypt(value): string {
    var key = CryptoJS.enc.Utf8.parse(this.key);
    var iv = CryptoJS.enc.Utf8.parse(this.IV);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  public static decrypt(encrypted: string): string {
    var key = CryptoJS.enc.Utf8.parse(this.key);
    var iv = CryptoJS.enc.Utf8.parse(this.IV);
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  public static between(start: Date, end: Date, date: Date): boolean {
    return (date >= start && date <= end);
  }

  public static datetimeBetween(start: Date, end: Date, date: Date): boolean {
    return (new Date(date) >= new Date(start) && new Date(date) <= new Date(end));
  }

  public static datetimeInBetween(start: Date, end: Date, date: Date): boolean {
    return (new Date(date) >= new Date(start) && new Date(date) < new Date(end));
  }

  public static diffBetweenDates(start, end): number {
    return this.Date(start).startOf('d').diff(this.Date(end).startOf('d'), 'd');
  }
  public static diffBetweenDatesInMinutes(start, end): number {
    return this.Date(start).startOf('m').diff(this.Date(end).startOf('m'), 'm');
  }

  public static diffBetweenDateTime(start: Date, end: Date) {
    return this.Date(start).diff(end);
  }
public static dateRangeOverlaps(startdate, enddate, startD, endD) {
  let nstartdate=new Date(startdate);
  let nenddate=new Date(enddate.setTime(new Date(enddate).getTime()-(.5 * 60000)));
  let nstartD=new Date(startD);
  let newEndDate=new Date(endD);
  let nendD=new Date(newEndDate.setTime(new Date(newEndDate).getTime()- (.5 * 60000)));
  return  nstartdate <= nendD && nstartD <= nenddate;
}
  public static getConfirmationMessage(data: any, message: string = '', forConfirmationEmail: ReservationEmailNotificationType = null, confirmedSessionId: any = null) {
    let confirmation: any = [];
    if (data.ValidationMessages != null && (data.ValidationMessages.length == 0 || data.ValidationMessages.every(x => x.Type == ValidationMessageType.Warning)) && data.ExceptionMessage == null ||
      data.ResponseType === ResponseType.Success) {
      confirmation.push({ confirmationMessage: message, dialogTitle: '' });
      if (forConfirmationEmail != null) {
        //let contactData = data.Change.ChangeSet.AdditionalData;
        confirmation = [];
        confirmation.push({
          sendConfirmationEmail: true,
          reservationEmailNotificationType: forConfirmationEmail,
          confirmationCode: data.Payload ? data.Payload.ConfirmationCode : null,
          partyId: data.Payload ? data.Payload.PartyId : null,
          email: data.Payload ? data.Payload.EmailAddress : null,
          firstName: data.Payload ? data.Payload.FirstName : null,
          lastName: data.Payload ? data.Payload.LastName : null,
          confirmationMessage: message,
          cancelledIds: data.Payload ? data.Payload.CancelledIds : null,
          bookedSessionId: data.Payload ? data.Payload.BookedSessionId : null,
          classOrSessionBooking: data.Payload ? (data.Payload.ClassOrSessionBooking ? data.Payload.ClassOrSessionBooking : false) : false,
          confirmedBookedSessionId: confirmedSessionId,
          dialogTitle: 'Reservation Confirmation?'
        });
      }
    } else {
      if (data.ValidationMessages && data.ValidationMessages.length > 0) {
        confirmation.push({ confirmationMessage: data.ValidationMessages[0].Message, dialogTitle: this.getDialogTitle(data) });
      } else if (data.ExceptionMessage != null) {
        confirmation.push({ confirmationMessage: data.ExceptionMessage, dialogTitle: this.getDialogTitle(data) });
      }
    }
    return confirmation;
  }

  public static getConfirmationDialogTitle(notificationType: ReservationEmailNotificationType) {
    switch (notificationType) {
      case ReservationEmailNotificationType.Cancelled:
        return 'cancellationPromptEmailTitle';
      case ReservationEmailNotificationType.Updated:
        return 'updatePromptEmailTitle';
      case ReservationEmailNotificationType.Created:
        return 'confirmationPromptEmailTitle';
      default:
        return 'confirmationPromptEmailTitle';
    }
  }

  public static getDialogTitle(data: any) {
    if (data.ValidationMessages.length === 0 && data.ExceptionMessage == null) {
      return '';
    } else {
      if (data.ValidationMessages.length > 0) {
        if (data.ValidationMessages[0].Type === ValidationMessageType.Error) {
          return 'Error';
        } else {
          return 'Completed with warning';
        }
      } else if (data.ExceptionMessage != null) {
        return 'Error';
      }
    }
  }

  public static getPropertyType(restaurantId = Utilities.RestaurantId()): any {
    let restaurant = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${restaurantId}_restaurantSettings`));
    return (restaurant) ? restaurant.PropertyType : "";
  }

  public static getCountryCodes(settings: SettingsDTO) {
    return (settings.Countries).map((country: CountryDTO) => {
      return {
        id: country.Name,
        value: `${country.Label} (${country.CountryPhoneCode})`,
        countryCode: country.CountryPhoneCode,
        countryId: country.Id
      };
    });
  }

  public static getMobileNumMaxLength(countryCode) {
    let stringCount: number;
    switch (countryCode) {
      case '+372':
        stringCount = 7;
        break;
      case '+852':
      case '+502':
      case '+65':
        stringCount = 9;
        break;
      case '+371':
      case '+43':
      case '+32':
      case '+36':
      case '+370':
      case '+66':
        stringCount = 10;
        break;
      case '+31':
      case '+420':
      case '+351':
      case '+61':
      case '+56':
      case '+353':
      case '+63':
      case '+972':
      case '+39':
      case '+47':
        stringCount = 11;
        break;
      case '+62':
      case '+86':
        stringCount = 13;
        break;
      case '+385':
      case '+34':
      case '+44':
      case '+46':
      case '+48':
      case '+60':
      case '+33':
      case '+41':
        stringCount = 12;
        break;
      case '+49':
        stringCount = 10;
        break;
      case '+1':
        stringCount = 14;
        break;
    }
    return stringCount;
  }

  public static addSeatedPartyToStorage(restaurantId: number, party: PartyDTO, state: StateDTO) {
    if (restaurantId && state) {
      if (state.SeatingParties.find(p => p.Id == party.Id)) {
        state.SeatingParties[party.Id] = party;
      } else {
        state.SeatingParties = state.SeatingParties.concat(party);
      }
      localStorage.setItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${restaurantId}_restaurantState`, JSON.stringify(state));
    }
  }

  public static getRestaurantTables(allAvailableFloors: FloorPlanDTO[]): StandaloneTableDTO[] {
    const allAvailableTables: StandaloneTableDTO[] = [];
    if (allAvailableFloors?.length > 0) {
      allAvailableFloors.forEach((floors) => {
        if (floors.StandaloneTables.length > 0) {
          floors.StandaloneTables.forEach((table) => {
            allAvailableTables.push(table);
          });
        }
      });
    }
    return allAvailableTables;
  }


  public static getLayoutId(floorplans: FloorPlanDTO[]): number {
    const layoutId = floorplans.filter((floor) => floor.IsDefault)[0].Id;
    return layoutId;
  }

  public static getTimeZone(settings: SettingsDTO) {
    const timezoneoffset = settings.General.TimeZoneIANAFormat.split(':', 2);
    const formattedTimeZone = `${timezoneoffset[0]}${timezoneoffset[1]}`;
    return formattedTimeZone;
  }

  public static parseDateString(date: Date | string) {
    return new Date(moment(date).valueOf());
  }

  public static getDefaultCountryCode(settings: SettingsDTO) {
    let restaurantCountry = settings.General.Country;
    const countryCodes = settings.Countries as CountryDTO[];
    // Manually changed the country name for United Kingdom countries (Scotland, Wales and England) for EMEA prod issue
    if (restaurantCountry.toLowerCase() === 'england' ||
      restaurantCountry.toLowerCase() === 'scotland' || restaurantCountry.toLowerCase() === 'wales') {
      restaurantCountry = 'United kingdom';
    }
    const matchedCountry = countryCodes.filter((country) => country.Name.toLowerCase() === restaurantCountry.toLowerCase());
    let defaultRestaurantCountryCode = '';
    if (matchedCountry.length > 0) {
      defaultRestaurantCountryCode = matchedCountry[0].CountryPhoneCode;
    } else {
      defaultRestaurantCountryCode = countryCodes.filter((country) => country.Name === 'USA')[0].CountryPhoneCode;
    }
    return defaultRestaurantCountryCode;
  }

  
  public static getDefaultCountryId(settings: SettingsDTO) {
    let restaurantCountry = settings.General.Country;
    const countryCodes = settings.Countries as CountryDTO[];
    // Manually changed the country name for United Kingdom countries (Scotland, Wales and England) for EMEA prod issue
    if (restaurantCountry.toLowerCase() === 'england' ||
      restaurantCountry.toLowerCase() === 'scotland' || restaurantCountry.toLowerCase() === 'wales') {
      restaurantCountry = 'United kingdom';
    }
    const matchedCountry = countryCodes.filter((country) => country.Name.toLowerCase() === restaurantCountry.toLowerCase());
    let defaultRestaurantCountryId = 0;
    if (matchedCountry.length > 0) {
      defaultRestaurantCountryId = matchedCountry[0].Id;
    } else {
      defaultRestaurantCountryId = countryCodes.filter((country) => country.Name === 'USA')[0].Id;
    }
    return defaultRestaurantCountryId;
  }

  public static getDefaultCountry(countryCodes: any[], settings: SettingsDTO) {
    const restaurantCountry = settings.General.Country;
    let defaultCountry: any;
    const matchedCountry = countryCodes.filter((country) => {
      return country.id.toLowerCase() === restaurantCountry.toLowerCase();
    });
    if (matchedCountry.length > 0) {
      defaultCountry = matchedCountry[0];
    } else {
      defaultCountry = countryCodes.filter((country) => country.id === 'USA')[0];
    }
    return defaultCountry;
  }

  public static getCommunalTables(tableIds, floorplans: FloorPlanDTO[]) {
    const allCommunalTables = [];
    const StandaloneTables: StandaloneTableDTO[] = this.getRestaurantTables(floorplans);
    StandaloneTables.forEach((table: StandaloneTableDTO) => {
      const selectedTable = tableIds.filter((partyTable) => partyTable === table.Id);
      if (selectedTable.length > 0 && table.IsCommunalTable) {
        allCommunalTables.push(selectedTable);
      }
    });
    return allCommunalTables;
  }
  public static setComponentDetails(componentName: any, dimensionType: string, popupType: string, popupInput?: any,
    popupTitle?: any): ComponentDetails {
    const componentDetails: ComponentDetails = {
      componentName,
      dimensionType,
      popupType,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput,
      popupTitle: popupTitle ? popupTitle : ''
    };
    return componentDetails;
  }

  public static getTableNamesFromStandaloneTables(StandaloneTableIds, floorplans: FloorPlanDTO[]) {
    const tableNames = [];
    const StandaloneTables = this.getRestaurantTables(floorplans);
    if (!StandaloneTableIds.length) {
      const tempTableDetails = StandaloneTables.filter(table => table.Id === StandaloneTableIds.Id);
      if (tempTableDetails.length > 0) {
        tableNames.push(tempTableDetails[0].Name);
      }
    }
    else {
      StandaloneTableIds.forEach(tableId => {
        const tempTable = StandaloneTables.filter(table => table.Id === tableId);
        if (tempTable.length > 0) {
          tableNames.push(tempTable[0].Name);
        }
      });
    }
    return tableNames;
  }

  public static getSeatingTypeFromStandaloneTables(StandaloneTableId, floorplans: FloorPlanDTO[]) {
    var seatingTypeId = null;
    const StandaloneTables = this.getRestaurantTables(floorplans);
    const tempTable = StandaloneTables.filter(table => table.Id === StandaloneTableId);
    if (tempTable.length > 0) {
      seatingTypeId = tempTable[0].SeatingTypeId;
    }
    return seatingTypeId;
  }

  public static getRestaurantDateTime(daylightDelta: string) {
    const offset = Number(daylightDelta);
    const d = new Date();
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    const nd = new Date(utc + (3600000 * offset));
    return nd;
  }

  public static GetRetainSignalR(): boolean {
    if (localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_retainSignalR`)) {
      return localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_retainSignalR`).toLowerCase() == 'true';
    }
    return false;
  }

  public static SetRetainSignalR(value: string) {
    localStorage.setItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_retainSignalR`, value);
  }

  public static getPartiesForSelectedShift(effectiveRangeFilter: EffectiveRangePartiesPipe, selectedShiftId: number, shifts: ShiftDTO[], partiesList: any[]): any[] {
    let partiesForTheShift: any[] = [];
    if (partiesList && partiesList.length) {
      let selectedShift = shifts.filter(s => s.Id == selectedShiftId)[0];
      if (selectedShift) {
        partiesForTheShift = effectiveRangeFilter.transform(partiesList, selectedShift.EffectiveRange, ContentView.list);
      }
    }
    return partiesForTheShift;
  }

  public static getRestaurantShiftForDay(Shifts: ShiftDTO[], date: Date, includeOnlyWeek?: boolean, canFormatDisplayedRange?) {
    const dayofweek = moment.isMoment(date) ? date?.day() : date?.getDay();
    const dates = new Date(date).setHours(0, 0, 0, 0);
    let dateshifts = Shifts.filter(val => {
      return val.Date != null && new Date(val.Date).setHours(0, 0, 0, 0) == dates;
    });
    if ((!(dateshifts && dateshifts.length > 0)) || includeOnlyWeek) {
      dateshifts = Shifts.filter(val => {
        return val.Date == null && val.DayOfWeek == dayofweek;
      });
    }
    var dateshiftsByDate = _.cloneDeep(dateshifts);
    dateshiftsByDate = dateshiftsByDate.sort(function (a, b) {
      return a.EffectiveRange.Start.toString().split('Z')[0].localeCompare(b.EffectiveRange.Start.toString().split('Z')[0]);
    });
    Utilities.assignDates(dateshiftsByDate, date, canFormatDisplayedRange);
    return dateshiftsByDate;
  }


  public static getRestaurantCustomShiftForDay(Shifts: ShiftDTO[], date: Date, includeOnlyWeek?: boolean, canFormatDisplayedRange?) {
    const dayofweek = moment.isMoment(date) ? date?.day() : date?.getDay();
    const dates = new Date(date).setHours(0, 0, 0, 0);
    let dateshifts = Shifts.filter(val => {
      return val.Date != null && new Date(val.Date).setHours(0, 0, 0, 0) == dates;
    });

    var dateshiftsByDate = _.cloneDeep(dateshifts);
    //below sort was added in getRestaurantShiftForDay method in swire , so copied the same here during merge.
    dateshiftsByDate = dateshiftsByDate.sort(function (a, b) {
      return a.EffectiveRange.Start.toString().split('Z')[0].localeCompare(b.EffectiveRange.Start.toString().split('Z')[0]);
    });
    Utilities.assignDates(dateshiftsByDate, date, canFormatDisplayedRange);
    return dateshiftsByDate;
  }


  public static getRestaurantOpenHoursForDay(OpenHours: OpenHoursDTO[], date: Date, includeOnlyWeek?: boolean) {
    const dayofweek = moment.isMoment(date) ? date?.day() : date?.getDay();
    const dates = new Date(date).setHours(0, 0, 0, 0);
    let openHrs = OpenHours.filter(val => {
      return val.Date != null && new Date(val.Date).setHours(0, 0, 0, 0) == dates;
    });
    if ((!(openHrs && openHrs.length > 0)) || includeOnlyWeek) {
      openHrs = OpenHours.filter(val => {
        return val.Date == null && val.DayOfWeek == dayofweek;
      });
    }
    var openHoursByDate = _.cloneDeep(openHrs);
    Utilities.assignDates(openHoursByDate, date);
    return openHoursByDate;
  }

  public static GetWideRange(dates: any[]) {
    var timerange = [];
    var startRange = [];
    var endRange = [];
    dates.forEach(date => {
      startRange.push(new Date(date.EffectiveRange.Start));
      endRange.push(new Date(date.EffectiveRange.End));
    });

    timerange[0] = moment(new Date(Math.min.apply(null, startRange))).format("YYYY-MM-DDTHH:mm:ss");
    timerange[1] = moment(new Date(Math.max.apply(null, endRange))).format("YYYY-MM-DDTHH:mm:ss");
    return timerange;
  }
  public static assignDates(dateShifts: any[], date: Date, canFormatDisplayedRange = false) {
    let selectedDate = moment.isMoment(date) ? _.cloneDeep(new Date(date)) : _.cloneDeep(date);
    if(selectedDate){
    selectedDate = new Date(selectedDate.setHours(0, 0, 0, 0));
    dateShifts.forEach(shift => {
      let startHours = Utilities.formateDateString(shift.EffectiveRange.Start).getHours();
      let startMins = Utilities.formateDateString(shift.EffectiveRange.Start).getMinutes();
      let endHours = Utilities.formateDateString(shift.EffectiveRange.End).getHours();
      let endMins = Utilities.formateDateString(shift.EffectiveRange.End).getMinutes();
      if (Utilities.Date(shift.EffectiveRange.End).startOf('day').diff(Utilities.Date(new Date("0001-01-01T00:00:00")).startOf('day'), 'd') == 0) {
        shift.EffectiveRange.Start = moment(selectedDate).clone().add(startHours, 'hours').add(startMins, 'minutes').format("YYYY-MM-DDTHH:mm:ss");
        shift.EffectiveRange.End = moment(selectedDate).clone().add(endHours, 'hours').add(endMins, 'minutes').format("YYYY-MM-DDTHH:mm:ss");
      }
      else if (Utilities.Date(shift.EffectiveRange.End).startOf('day').diff(Utilities.Date(new Date("0001-01-01T00:00:00")).startOf('day'), 'd') == 1) {
        shift.EffectiveRange.Start = moment(selectedDate).clone().add(startHours, 'hours').add(startMins, 'minutes').format("YYYY-MM-DDTHH:mm:ss");
        shift.EffectiveRange.End = moment(selectedDate).clone().add(1, 'day').add(endHours, 'hours').add(endMins, 'minutes').format("YYYY-MM-DDTHH:mm:ss");
      }
      if (canFormatDisplayedRange) {
        let startHours = Utilities.formateDateString(shift.DisplayedRange.Start).getHours();
        let startMins = Utilities.formateDateString(shift.DisplayedRange.Start).getMinutes();
        let endHours = Utilities.formateDateString(shift.DisplayedRange.End).getHours();
        let endMins = Utilities.formateDateString(shift.DisplayedRange.End).getMinutes();
        if (Utilities.Date(shift.DisplayedRange.End).startOf('day').diff(Utilities.Date(new Date("0001-01-01T00:00:00")).startOf('day'), 'd') == 0) {
          shift.DisplayedRange.Start = moment(selectedDate).clone().add(startHours, 'hours').add(startMins, 'minutes').format("YYYY-MM-DDTHH:mm:ss");
          shift.DisplayedRange.End = moment(selectedDate).clone().add(endHours, 'hours').add(endMins, 'minutes').format("YYYY-MM-DDTHH:mm:ss");
        }
        else if (Utilities.Date(shift.DisplayedRange.End).startOf('day').diff(Utilities.Date(new Date("0001-01-01T00:00:00")).startOf('day'), 'd') == 1) {
          shift.DisplayedRange.Start = moment(selectedDate).clone().add(startHours, 'hours').add(startMins, 'minutes').format("YYYY-MM-DDTHH:mm:ss");
          shift.DisplayedRange.End = moment(selectedDate).clone().add(1, 'day').add(endHours, 'hours').add(endMins, 'minutes').format("YYYY-MM-DDTHH:mm:ss");
        }
      }
    });
  }
  }

  public static AppendDateWithTime(date: any, time: any): Date {
    time = time.split(':');
    return new Date(moment(date).clone().set('hours', time[0]).set('minutes', time[1]).set('seconds', time[2]).format("YYYY-MM-DDTHH:mm:ss"));
  }

  static existValueInEnum(type: any, value: any): boolean {
    return Object.keys(type).filter(k => isNaN(Number(k))).filter(k => type[k] === value).length > 0;
  }

  public static GetLayoutForDate(floorplans: FloorPlanDTO[], date, selectedShiftId?) {
    // const parsedJson = JSON.parse(sessionStorage.getItem(this.appService.restaurantId + '_restaurantSettings'));
    const allLayout = floorplans;
    let Layout = null;
    const selectedDate = new Date(date).setHours(0, 0, 0, 0);
    if (selectedShiftId) {
      Layout = allLayout.filter((Floor) => {
        if (Floor.Dates != null) {
          if (Floor.Dates.filter((dates) => {
            const dateval = new Date(dates.Date).setHours(0, 0, 0, 0);
            if (dateval == selectedDate && (dates.ShiftId == null || dates.ShiftId == selectedShiftId)) {
              return dates;
            }
          }).length > 0) {
            return Floor;
          }
        }
      });
    } else {
      Layout = allLayout.filter((Floor) => {
        if (Floor.Dates != null) {
          if (Floor.Dates.filter((dates) => {
            const dateval = new Date(dates.Date).setHours(0, 0, 0, 0);
            if (dateval == selectedDate) {
              return dates;
            }
          }).length > 0) {
            return Floor;
          }
        }
      });

    }

    if (!Layout || Layout.length == 0) {
      Layout = allLayout.filter(x => x.IsDefault == true);
    }

    return Layout;

  }

  public static formateDateString(oldDate) {
    if (!oldDate) {
      return null;
    }
    let val = oldDate.toString();
    val = val.replace(/-/g, '/');
    val = val.replace(/T/g, ' ');
    return new Date(val);
  }

  public showCommunalTableAlert() {
    const popUpMessage = [{
      confirmationMessage: `${this.translateService.instant('communalTableError')}`,
      dialogTitle: this.translateService.instant('tableSelection'),
      showAlert: true
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', popUpMessage,
      popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.reservation, '450px', 'auto', true, '', 'Ok',
      'Cancel', true);
  }

  public openCustomPopup(componentDetails: ComponentDetails, fromComponent: ComponentTypes, width?: string, height?: string,
    back?: boolean, title?: string, update?: string, cancel?: string, showAction?: boolean, maxWidth?: string,
    panelClass?: string, selectionView?: boolean, autoFocus?: boolean | AutoFocusTarget) {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width,
      height,
      maxWidth,
      panelClass,
      minHeight: popupDialogDimension.actionDialogMinHeight,
      autoFocus,
      data: {
        title,
        update,
        cancel,
        componentDetails,
        from: fromComponent,
        back,
        standalone: true,
        showAction,
        selectionView
      }
    });
    return dialogRef;
  }

  public showAppPopup(componentDetails: ComponentDetails, fromComponent: ComponentTypes, width?: string, height?: string,
    back?: boolean, title?: string, update?: string, cancel?: string, showAction?: boolean, maxWidth?: string,
    panelClass?: string, selectionView?: boolean, autoFocus?: boolean | AutoFocusTarget) {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width,
      height,
      maxWidth,
      panelClass,
      minHeight: popupDialogDimension.actionDialogMinHeight,
      autoFocus,
      data: {
        title,
        update,
        cancel,
        componentDetails,
        from: fromComponent,
        back,
        standalone: true,
        showAction,
        selectionView
      }
    });
    return dialogRef;
  }

  public static getFloorPlan(floorPlanViews, tranlateService?: TranslateService) {
    let floorPlanOptions = floorPlanViews.map(x => ({ id: x.Id, value: x.Name }));
    let defaultValue = (Utilities.IsActivitiesVenues(this.getPropertyType())) ? tranlateService.instant('View All Locations') : tranlateService.instant('View All Floors');
    floorPlanOptions = [{ id: -1, value: defaultValue }].concat(floorPlanOptions);
    return floorPlanOptions
  }
  public static getServerFromStandaloneTables(StandaloneTableIds, floorplans: FloorPlanDTO[]) {
    const ServerIds = [];
    const StandaloneTables = this.getRestaurantTables(floorplans);
    StandaloneTableIds.forEach(tableId => {
      const tempTable = StandaloneTables.filter(table => table.Id === tableId);
      if (tempTable.length > 0) {
        ServerIds.push(tempTable[0].ServerId);
      }
    });
    return ServerIds;
  }
  public static getIGServerForStandaloneTables(StandaloneTableIds, floorplans: FloorPlanDTO[], settings: SettingsDTO) {
    const ServerIds = [];
    const IGServerIds = [];
    const StandaloneTables = this.getRestaurantTables(floorplans);
    StandaloneTableIds.forEach(tableId => {
      const tempTable = StandaloneTables.filter(table => table.Id === tableId);
      if (tempTable.length > 0) {
        ServerIds.push(tempTable[0].ServerId);
      }
    });
    if (settings && settings.Servers != null && settings.Servers.length > 0) {
      if (ServerIds.length > 0) {
        const _igServer = settings.Servers.filter(s => s.Id == ServerIds[0]);
        if (_igServer && _igServer.length > 0)
          IGServerIds.push(_igServer[0].IgServerId);
      }
    }
    return IGServerIds;
  }

  public static AddDates(selectedDate: Date, Days?: number, months?: number, year?: number) {
    var dd = selectedDate.getDate() + Days;
    var mm = selectedDate.getMonth() + 1 + months;
    var y = selectedDate.getFullYear() + year;
    return new Date(y + '/' + mm + '/' + dd);
  }

  public static getHostPinCode() {
    let pinCode: string = "";
    if (Utilities.tryParse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`))) {
      let loginResult: LoginResultDTO = {} as LoginResultDTO;
      loginResult = Utilities.tryParseObject<LoginResultDTO>(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
      pinCode = loginResult.HostPinCode;
      if (pinCode.length > 4) {
        pinCode = this.decrypt(pinCode);
      }
    }
    return pinCode;
  }

  public static GetAvailableRestaurantsForLogin(): RestaurantAvailableForLoginDTO[] {
    let availableRestaurants: RestaurantAvailableForLoginDTO[] = [];
    if (Utilities.tryParse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`))) {
      let loginResult: LoginResultDTO = {} as LoginResultDTO;
      loginResult = Utilities.tryParseObject<LoginResultDTO>(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
      availableRestaurants = loginResult.RestaurantsAvailableForLogin;
    }
    return availableRestaurants;
  }

  public static GetRestaurantById(restaurantid: number) {
    let restaurant: RestaurantAvailableForLoginDTO[] = [];
    if (Utilities.tryParse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`))) {
      let loginResult: LoginResultDTO = {} as LoginResultDTO;
      loginResult = Utilities.tryParseObject<LoginResultDTO>(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
      restaurant = loginResult.RestaurantsAvailableForLogin.filter(r => r.Id == restaurantid);
    }
    return restaurant;
  }


  public static EnumerateHoursBetweenRange(timeRange: TimeRangeDTO) {
    let hoursArray: any[] = [];
    if (!timeRange) return
    let dateTimeStart = new Date(timeRange.Start);
    let dateTimeEnd = new Date(timeRange.End);
    dateTimeStart = new Date(dateTimeStart.getFullYear(), dateTimeStart.getMonth(), dateTimeStart.getDate(), dateTimeStart.getHours(), 0, 0);
    while (this.diffBetweenDateTime(dateTimeStart, dateTimeEnd) < 0) {
      let updatedDate = _.cloneDeep(dateTimeStart);
      hoursArray.push(updatedDate)
      dateTimeStart.setHours(dateTimeStart.getHours() + 1);
    }
    return hoursArray;
  }

  public static getMealShiftDate(_settings: SettingsDTO, startDate: Date, endDate: Date, mealShiftId?) {
    var timerange = [];
    var startRange = [];
    var endRange = [];
    var shifts = [];
    var startshifts = Utilities.getRestaurantShiftForDay(_settings.Shifts, new Date(startDate));
    if (!(startshifts && startshifts.length > 0)) {
      startRange.push(new Date(startDate));
    }
    else {
      shifts.push(...startshifts);
    }
    var endShifts = Utilities.getRestaurantShiftForDay(_settings.Shifts, new Date(endDate));
    if (!(endShifts && endShifts.length > 0)) {
      endRange.push(new Date(endDate));
    }
    else {
      shifts.push(...endShifts);
    }

    if (shifts && shifts.length > 0) {
      if (mealShiftId) {
        var shift = shifts.filter(x => x.Id == mealShiftId);
        if (shift && shift.length > 0) {
          startRange.push(new Date(shift[0].EffectiveRange.Start));
          endRange.push(new Date(shift[0].EffectiveRange.End));
        }
      }
      else {
        shifts.forEach(shift => {
          startRange.push(new Date(shift.EffectiveRange.Start));
          endRange.push(new Date(shift.EffectiveRange.End));
        });
      }
    }
    timerange[0] = new Date(Math.min.apply(null, startRange));
    timerange[1] = new Date(Math.max.apply(null, endRange));
    return timerange;
  }

  public static getMealwithoutShift(ActivitySession: ActivitySessionsDTO[], Slottime: Date) {
    let currentDayOfWeek = Slottime?.getDay();

    return ActivitySession.filter(sp => {
      let sTime = sp.StartTime.split(":");
      let eTime = sp.EndTime.split(":");
      let StartSlottime = new Date(Slottime).getHours() + (new Date(Slottime).getMinutes() / 60);
      let getStartval = Number(sTime[0]) + (Number(sTime[1]) / 60);
      let getEndval = Number(eTime[0]) + (Number(eTime[1]) / 60);
      if (sp.Dayofweek == currentDayOfWeek && getStartval <= StartSlottime && getEndval >= StartSlottime) {
        return true;
      }
    }
    );
  }

  public static getMealwithoutShiftForExtendedDays(meal: any, Slottime: Date) {
    let currentDayOfWeek = Slottime?.getDay();

    let StartDate = meal.StartDate.split("T")[0];
    let EndDate = meal.EndDate.split("T")[0];
    if (meal.ActivitySessions[0].StartTime > meal.ActivitySessions[0].EndTime) {
      if (this.diffBetweenDateTime(new Date(meal.StartDate), new Date(meal.EndDate)) < meal.ActivitySessions.length) {
        var _endDate = new Date(meal.EndDate);
        _endDate.setDate(_endDate.getDate() + 1);
        EndDate = (moment(_endDate).format('YYYY-MM-DDTHH:mm:ss')).toString().split("T")[0];
      }
    }
    return meal.ActivitySessions.filter(sp => {
      let _startDatetime = StartDate + "T" + sp.StartTime;
      let _endDatetime = EndDate + "T" + sp.EndTime;
      let _start = new Date(_startDatetime);
      let _end = new Date(_endDatetime);
      if (_start <= Slottime && _end >= Slottime) {
        return true;
      }
    }
    );
  }

  static mapNotesByCategory(selectedGuestNotes: ContactNotes[], guestNotes: ContactNoteDTO[]) {
    let categoryNotes = [];
    selectedGuestNotes.forEach((notes) => {
      let selectedNoteValues = [];
      guestNotes.forEach((guestNote) => {
        let selectedNote = notes.contactNotes.filter(contactNote => contactNote.Id == guestNote.RelatedId);
        if (selectedNote && selectedNote.length > 0) {
          selectedNoteValues.push(guestNote)
        }
      })
      categoryNotes.push({ category: notes.CategoryName, notes: selectedNoteValues })
    });
    return categoryNotes;
  }

  static getRestaurantPredefinedContactNotes(categories: Category[], othersId: number[] = null) {
    let guestNotes: ContactNotes[] = [];
    categories.forEach(category => {
      if (category.IsVisible) {
        const { SubCategories = [] } = category;
        let contactNotes: PredefinedContactNoteDTO[] = [];
        SubCategories.forEach(SubCategory => {
          const { PredefinedContactNote = [] } = SubCategory;
          contactNotes.unshift(...PredefinedContactNote);
        });
        let othersIndex = contactNotes.findIndex(note => note.Text.toLowerCase() == 'others');
        if (othersIndex !== contactNotes.length) {
          let otherNotes = contactNotes.splice(othersIndex, 1);
          contactNotes.push(...otherNotes);
        }
        let CategoryName = category.Text;
        let CategoryId = category.Id;
        let Color = `rgba(${category.Color.R}, ${category.Color.G}, ${category.Color.B}, ${category.Color.A ? category.Color.A : 1})`;
        if (contactNotes.length == 1) {
          const isOtherSubCategoryOnly = contactNotes.filter(note => note.Text.toLowerCase() !== 'others').length;
          if (isOtherSubCategoryOnly)
            guestNotes.unshift({ CategoryName, CategoryId, contactNotes, Color })
        } else if (contactNotes.length) {
          guestNotes.unshift({ CategoryName, CategoryId, contactNotes, Color })
        }
        else {
          if (othersId && contactNotes.some(r => othersId.indexOf(r.Id) >= 0)) {
            if (contactNotes.length > 0) {
              guestNotes.unshift({ CategoryName, CategoryId, contactNotes, Color });
            }
          }
        }
      }
    });
    return guestNotes;
  }

  static getRestaurantPredefinedPartyNotes(categories: Category[], includeOthers: boolean, othersId: number[]) {
    let partyNotes: PartyNotes[] = [];
    categories.forEach(category => {
      if (category.IsVisible) {
        const { SubCategories = [] } = category;
        let predefinedPartyNotes: PredefinedPartyNoteDTO[] = [];
        SubCategories.forEach(SubCategory => {
          const { PredefinedPartyNote = [] } = SubCategory;
          predefinedPartyNotes.unshift(...PredefinedPartyNote);
        });
        let othersIndex = predefinedPartyNotes.findIndex(note => note.Text.toLowerCase() == 'others');
        if (othersIndex !== predefinedPartyNotes.length) {
          let otherNotes = predefinedPartyNotes.splice(othersIndex, 1);
          predefinedPartyNotes.push(...otherNotes);
        }
        let CategoryName = category.Text;
        let CategoryId = category.Id;
        let Color = `rgba(${category.Color.R}, ${category.Color.G}, ${category.Color.B}, ${category.Color.A ? category.Color.A : 1})`;
        if (includeOthers == true || predefinedPartyNotes.some(r => othersId.indexOf(r.Id) >= 0)) {
          if (predefinedPartyNotes.length > 0) {
            partyNotes.unshift({ CategoryName, CategoryId, partyNotes: predefinedPartyNotes, Color })
          }
        }
        else {
          if (predefinedPartyNotes.length == 1) {
            const isOtherSubCategoryOnly = predefinedPartyNotes.filter(note => note.Text.toLowerCase() !== 'others').length;
            if (isOtherSubCategoryOnly)
              partyNotes.unshift({ CategoryName, CategoryId, partyNotes: predefinedPartyNotes, Color })
          } else if (predefinedPartyNotes.length) {
            partyNotes.unshift({ CategoryName, CategoryId, partyNotes: predefinedPartyNotes, Color })
          }
        }

      }
    });
    return partyNotes;
  }
  public static number_suffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return "st";
    }
    if (j == 2 && k != 12) {
      return "nd";
    }
    if (j == 3 && k != 13) {
      return "rd";
    }
    return "th";
  }

  public static uniqueBy(arr, ...uniqueByArgs) {
    let modifiedArray = arr.map(item => {
      let res = "";
      uniqueByArgs.forEach((prop, index) => {
        if (index) {
          res += "||";
        }
        res += item[prop];
      })
      return res;
    });
    var uniqueArray = [...new Set(modifiedArray)];
    return uniqueArray.map((item: string) => {
      var values = item.split("||");
      var output = {};
      uniqueByArgs.forEach((prop, index) => {
        output[prop] = values[index]
      })
      return output;
    })
  }

  public static isRetailEnabledProperty(retailDTO: RetailIntegrationDTO) {
    let loginResult: any = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    return loginResult && retailDTO && retailDTO.IsEnabled;
  }

  public static isRetailEnabledPropertyWithToken(retailDTO: RetailIntegrationDTO) {
    let loginResult: any = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    return loginResult && loginResult.RetailDetailDTO && retailDTO && retailDTO.IsEnabled && sessionStorage.getItem('_jwt');
  }

  public static isRetailEnabledPropertyWithSession(retailDTO: RetailIntegrationDTO) {
    let loginResult: any = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    return loginResult && loginResult.RetailDetailDTO && retailDTO && retailDTO.IsEnabled && sessionStorage.getItem('userSession');
  }

  public static isUserHasAccessToRetail(restaurantId) {
    let loginResult: any = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    return loginResult && loginResult.RetailDetailDTO && loginResult.RetailDetailDTO.length && loginResult.RetailDetailDTO.find((property) => property.RestaurantId === restaurantId);
  }

  public static IsMember(slot: any): boolean {
    if (!slot) return false;
    let restaurant = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${Utilities.RestaurantId()}_restaurantSettings`));
    let isAnyMember = restaurant.AllocationType.find(type => type.Id === slot.AllocationTypeId).AllocationName == 'AnyMembers';
    return isAnyMember;
  }
  public static IsActivitiesVenues(type) {
    return ActivitiesVenue.find(x => x == type) ? true : false;
  }
  public static AnyVenue(type) {
    return AnyVenue.find(x => x == type) ? true : false;
  }
  public static IsDineInVenue(type) {
    return DineInVenue.find(x => x == type) ? true : false;
  }
  public static IspropertyOfTypeTheatre(type) {
    return propertyOfTypeTheatre.find(x => x == type) ? true : false;
  }
  public static controlValidate(controls, propertyType) {
    return controls.find(x => x == propertyType) ? true : false;
  }
  public static showSettingsChangedInfo(msg, title = "alert", dialog) {
    const showAlert = true;
    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: title, showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    return dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: popupDialogDimension.actionDialogHeight,
      width: popupDialogDimension.actionDialogWidth,
      data: {
        title,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: true
      }
    })
  }

  ShowRestPolicyDetails(restaurantPolicy) {
    const componentDetails: ComponentDetails = {
      componentName: RestaurantPoliciesComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupTitle: restaurantPolicy.PreReservationMessageTitle
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '60%',
      width: '40%',
      data: {
        title: restaurantPolicy.PreReservationMessageTitle,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.restaurantPolicies,
        back: false,
        standalone: true,
        showAction: true
      },
    });

  }

  public static isLessonBooking(party, propertyType, SpecialMeals) {
    if (Utilities.controlValidate(controlSettings.propertyType_LessonBooking, propertyType)) {
      if (party.SpecialMealId) {
        let lesson = SpecialMeals.filter(s => s.Id == party.SpecialMealId)[0];
        return lesson && lesson.LessonType == LessonType.GroupLesson;
      }
    }
    return false;
  }

  public static getTax(taxes: any, restaurantId, specialMealAmount, ratePlanAmount, specialmeal, daylightDelta): number {
    let taxAmount = 0;
    let restaurantTime = this.getRestaurantDateTime(daylightDelta);
  

    var applicableCharges = this.getApplicableCharges(taxes, specialmeal,daylightDelta, restaurantId );
    if(applicableCharges && applicableCharges.length){
        return this.getChargeAmount(applicableCharges, (specialMealAmount || 0) + (ratePlanAmount || 0));
    }
    return taxAmount;
  }

  static getDateApplicableCharges(charges: any, daylightDelta):any[]{
    if (charges && charges.length) {
      let restaurantTime = this.getRestaurantDateTime(daylightDelta);
      return charges.filter(t => t.StatusCode == Status.Approved && (
        (!t.StartDate && !t.EndDate)
        || (!t.EndDate &&  t.StartDate && new Date(t.StartDate) <= restaurantTime)
        || (!t.StartDate && t.EndDate && new Date(t.EndDate) >= restaurantTime)
        || (t.StartDate && t.EndDate && (new Date(t.StartDate) <= restaurantTime) && new Date(t.EndDate) >= restaurantTime))
      );
    }
    return [];
  }

  static getActivityApplicableCharges(charges: any, specialmeal, restaurantId):any[]{
    if (charges && charges.length) {
      if (specialmeal && specialmeal.IsPrepaymentRequired) {
        return charges.filter(t => t.BookingChargeActivityMappings && t.BookingChargeActivityMappings.length
          && t.BookingChargeActivityMappings.find(map => map.ActivityId == specialmeal.Id));
      }
      else {
        return charges.filter(t => t.BookingChargePropertyMappings && t.BookingChargePropertyMappings.length
          && t.BookingChargePropertyMappings.find(map => map.PropertyId == restaurantId && map.IsDefault));
      }
    }

    return [];
  }

  static getApplicableCharges(charges, specialmeal, daylightDelta, restaurantId): any[]{
    if (charges && charges.length) {

      var dateApplicableCharges = this.getDateApplicableCharges(charges, daylightDelta);
      if(dateApplicableCharges?.length){
        return this.getActivityApplicableCharges(dateApplicableCharges, specialmeal, restaurantId);
      }
     

    }
    return [];
  }

  static getChargeAmount(applicableTaxes: any, totalAmount): number {
    let taxAmount = 0;
    if (applicableTaxes && applicableTaxes.length) {
      applicableTaxes.forEach(defaultTax => {
        if (defaultTax.CalculationType == CalculationType.Percentage) {
          taxAmount = taxAmount + (totalAmount * defaultTax.BookingChargeValue * 0.01);
        }
        else {
          taxAmount = taxAmount + defaultTax.BookingChargeValue;
        }
      })
    }
    return taxAmount;
  }

  static getServiceChargeWithServiceChargeTaxAmount(bookingCharges: any, totalAmount, restaurantId, specialmeal, daylightDelta) {
    let serviceCharge = 0;
    let serviceChargeTaxAmount = 0;
    if(bookingCharges && bookingCharges.length){
      var servicerCharges = bookingCharges.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge);
      let applicableServiceCharges = this.getApplicableCharges(servicerCharges, specialmeal, daylightDelta, restaurantId);
      if(applicableServiceCharges?.length>0){
        applicableServiceCharges.forEach(charge => {
            var thisServiceChargeAmount = this.getChargeAmount([charge],totalAmount);
            let taxIds = charge.BookingChargeTaxMappings?.map(tax => { return tax.TaxId });
            let taxes = bookingCharges.filter(charge => taxIds?.includes(charge.Id));
            let applicableTaxes = this.getDateApplicableCharges(taxes, daylightDelta);
            var thisServiceChargeTaxAmount = this.getChargeAmount(applicableTaxes, thisServiceChargeAmount);
            serviceCharge = serviceCharge + thisServiceChargeAmount;
            serviceChargeTaxAmount = serviceChargeTaxAmount + thisServiceChargeTaxAmount;
        });
      }
    }

    return {ServiceChargeAmount : serviceCharge, ServiceChargeTaxAmount: serviceChargeTaxAmount};
  }


  public static getWeekDateRange(date) {
    let endDate = new Date(date);
    endDate.setDate(date.getDate() + 6);
    return [date, endDate]
  }

  static getMandatoryAddons(AddonsList: AddonDTO[], SpecialMeal?) {
    let AddonActivitiesMappings: AddonActivitiesMappingsDTO[] = [];
    // let Addons = AddonsList.filter(x => x.AddonActivitiesMappings.some(g => g.ActivityId == SpecialMeal && g.IsMandatory == true));
    if (!AddonsList) {
      return null
    }
    AddonsList.forEach(y => {
      y.AddonActivitiesMappings.forEach(x => {
        if (x.ActivityId == SpecialMeal && (x.IsMandatory || x.IsCategoryLevelMandatory))
          AddonActivitiesMappings.push(x);
      })
    });
    return AddonActivitiesMappings;
  }
  static getAddons(AddonsList: AddonDTO[], SpecialMeal?, Date?) {
    let AddonActivitiesMappings: AddonActivitiesMappingsDTO[] = [];
    if (!AddonsList) {
      return null
    }
    // let Addons = AddonsList.filter(x => x.AddonActivitiesMappings.some(g => g.ActivityId == SpecialMeal));
    AddonsList.forEach(y => {
      if (Date) {
        let startDate = y.AddOnDetails[0]?.StartDate?.toString().split('T') || null;
        let endDate = y.AddOnDetails[0]?.EndDate?.toString().split('T') || null;
        let slotDate = Date?.split('T');
        if ((!startDate && !endDate) || (startDate !== null && endDate !== null && ( moment(slotDate[0]).isBetween(startDate[0], endDate[0]) || slotDate[0] == startDate[0] ||  slotDate[0] == endDate[0]))) {
          const startTime = y.AddOnDetails[0]?.StartTime ? moment(y.AddOnDetails[0]?.StartTime?.toString().split('T')[1].replace('Z',''), 'hh:mm:ss') : null;
          const endTime = y.AddOnDetails[0]?.EndTime ? moment(y.AddOnDetails[0]?.EndTime?.toString().split('T')[1].replace('Z',''), 'hh:mm:ss') : null;
          const time = moment(slotDate[1], 'hh:mm:ss')
          if ((!startTime && !endTime) || (startTime !== null && endTime !== null && (time.isBetween(startTime, endTime) || time == startTime || time == endTime))) {
            y.AddonActivitiesMappings.forEach(x => {
              if (x.ActivityId == SpecialMeal)
                AddonActivitiesMappings.push(x);
            })
          }
        }
      } else {
        y.AddonActivitiesMappings.forEach(x => {
          if (x.ActivityId == SpecialMeal)
            AddonActivitiesMappings.push(x);
        })
      }
    });
    return AddonActivitiesMappings;
  }

  static getAddonActivityType(AddonsList: AddonDTO[], SpecialMeal?) {
    let AddonActivitiesMappings: AddonActivitiesMappingsDTO[] = [];
    if (!AddonsList) {
      return null
    }
    // let Addons = AddonsList.filter(x => x.AddonActivitiesMappings.some(g => g.ActivityId == SpecialMeal));
    AddonsList.forEach(y => {
      if(y.SelectionType == SelectionType.PerLesson)
      {
      y.AddonActivitiesMappings.forEach(x => {
        if (x.ActivityId == SpecialMeal)
          AddonActivitiesMappings.push(x);
      })
    }
    });
    return AddonActivitiesMappings;
  }

  static filterSlotsbyAddon(AddonsList: AddonDTO[], OverAllAddon: AddonActivitiesMappingsDTO[], Addons: AddonActivitiesMappingsDTO[], AvailableAddon: AddOnAvailability[], selectedPartySize,SpecialMealId) {
    if (!OverAllAddon
      || (OverAllAddon && OverAllAddon.length == 0)//When there is no addon-activity mapping, we shouldn't disable slots
      || !AvailableAddon || Addons.length == 0
      ) {
      return true;
    } 
    /*  if (OverAllAddon && AvailableAddon && OverAllAddon.length != AvailableAddon.length) {
       return false;
     } */
    let addonvalue = false;
    let alreadycheckval = [];
    let categoryleveladdons = Addons.filter(x => x.IsCategoryLevelMandatory);
    for (const addon of Addons) {
      if (alreadycheckval.find(x => x.AddonId == addon.AddonId))
        continue;
      let byPartyType = false;
      let byPerBooking = false;
      let byLessonType = false;
      let anyCategoryforBooking = false;
      let addons = AddonsList.filter(x => x.AddonId == addon.AddonId)[0];
      const val = AvailableAddon?.filter(z => z.AddonId == addon.AddonId)[0];
      if (addons && val && addon.IsMandatory && addons.SelectionType == SelectionType.PerGuest) {
        byPartyType = val.AvailableQuantity >= selectedPartySize && (addon.MinQuantity <= selectedPartySize && addon.MaxQuantity >= selectedPartySize);
      }
      if (addons && val && addon.IsMandatory && addons.SelectionType == SelectionType.PerBooking) {
        byPerBooking = val.AvailableQuantity >= addon.MinQuantity;
      } 
      if (addons && val && addon.IsMandatory && addons.SelectionType == SelectionType.PerLesson) {
        byLessonType = val.AvailableQuantity <= val.OverAllQuantity && val.AvailableQuantity > 0;
      }
      if (addons && val && addon.IsCategoryLevelMandatory) {
        let Categoryaddons = AddonsList.filter(x => x.CategoryId == addons.CategoryId && categoryleveladdons.filter(y => y.AddonId == x.AddonId));
        let tempval = false;
        for (let index = 0; index < Categoryaddons.length; index++) {
          let cat = Categoryaddons[index];
          const catval = AvailableAddon?.filter(z => z.AddonId == cat.AddonId)[0];

          if (cat && catval && cat.SelectionType == SelectionType.PerGuest && !tempval && catval.AvailableQuantity >= selectedPartySize) {
            tempval = true;
            alreadycheckval.push(Categoryaddons);
            break;
          }
          if (cat && catval && cat.SelectionType == SelectionType.PerBooking && !tempval && catval.AvailableQuantity >= addon.MinQuantity) {
            tempval = true;
            alreadycheckval.push(Categoryaddons);
            break;
          }
          if (cat && catval && cat.SelectionType == SelectionType.PerLesson && !tempval && catval.AvailableQuantity <= catval.OverAllQuantity && catval.AvailableQuantity > 0) {
            tempval = true;
            alreadycheckval.push(Categoryaddons);
            break;
          }
        }
        anyCategoryforBooking = tempval;
      }
      if (val && (val.IsUnlimited || byPartyType || byPerBooking || byLessonType || anyCategoryforBooking)) {
        addonvalue = true;
      } else {
        addonvalue = false;
        break;
      }
    }
    return addonvalue;
  }

  public static mapDefaultPageMethodForProperty(defaultPageMethod){
    switch(defaultPageMethod){
      case PageMethods.Manual:
        return PageMethod.Manual;
      case PageMethods.Sms:
        return PageMethod.Sms;
      case PageMethods.VoiceCall:
        return PageMethod.VoiceCall;
      case PageMethods.Email:
        return PageMethod.Email;
    }
  }
  public static openPurchaseForm(formUrl){
      let url = formUrl;
      if(url.includes('?')){
         url = url.concat('&ngsw-bypass=true')
      }else{
        url = url.concat('?ngsw-bypass=true')
      }
      window.open(url, 'Payment Form', 'height=750,width=500');
  }

  public static getSessionStorageType(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const activityType = urlParams.get('activityType');
    return activityType ?  ('_'+ activityType) : ''
  }

  public static getRandomString(): string{
    return ' #' + Math.floor(1 + Math.random() * 9) + (Math.random() + 1).toString(36).substring(2,3) + Math.floor(10 + Math.random() * 90) + (Math.random() + 1).toString(36).substring(2,3);
  }

  public static findLocale(availableLanguages){
    if(availableLanguages){
      let locale  = availableLanguages.find(lang => lang.Id ==  sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`))?.Code || DEFAULT_LANGUAGE_CODE;
      if(chineseLanguageCodes.includes(locale)){
          return 'zh-cn';
      }
      return locale;
    }
    return DEFAULT_LANGUAGE_CODE;
  }

  public static getDateFromTime(time: string, dateToAppend){ //time format expected 'hh:mm:ss'
      let [hr, min, sec] = time.split(":");
      let date = new Date(dateToAppend.getTime());
      date.setHours(+hr);
      date.setMinutes(+min);
      date.setSeconds(+sec);
      return date;
  }

 public static getLanguageCodeForRetail(){
  let defaultLangugaeCode = 'en-US';
  if((sessionStorage.getItem('languageId')) && (sessionStorage.getItem('languages'))) {
    let locale = JSON.parse(sessionStorage.getItem('languages')).find(lang => lang.Id == sessionStorage.getItem('languageId'))?.Code || DEFAULT_LANGUAGE_CODE;
    if(locale == 'ko')
        return 'ko-KR';
      else
        return defaultLangugaeCode;
  }
  else {
    return defaultLangugaeCode;
  }
 }

 public static getRoundedNumber(value): number{
  let decimalPlaces = sessionStorage.getItem('noOfDecimalDigits');
  return Number(value?.toFixed(decimalPlaces || 2) || 0);
}

  public static validMemberTypesSelected(masterAllocationTypes, alocationTypesSelected): boolean{
    let allAllocationTypes = masterAllocationTypes.filter(type => alocationTypesSelected.includes(type.Id)).map(allocationtype => allocationtype['AllocationPropertyMappings'][0]?.AllocationTypeName);
    if(allAllocationTypes.length){
        let uniqTypes = _.uniq(allAllocationTypes).filter(uType => uType != AnyMembers);
        return uniqTypes.length > 1 ? false : true;
    }
    return true;
  }

 public static getLocalStorage(key: string){
  return localStorage.getItem(key)
 }



}
