<app-dialog-header [title]="title" (closeDialog)="close()"></app-dialog-header>
<div class="ag_w--50 ag_p--5">
  <app-simple-search class='ag_w--100' [placeholder]="captions.lbl_searchByFNameLNameUser"
  (searchChange)="searchValueChange($event)">
  </app-simple-search>
</div>

<div class="cart-transfer-wrapper ag_mt--3 ag_container--padding-md">
   <div class="table-wrpper">
    <table>
        <tr>
          <th>{{captions.lbl_username}}</th>
          <th>{{captions.lbl_firstName}}</th>
          <th>{{captions.lbl_lastName}}</th>
        </tr>
        <tr class="ag_cursor--pointer" *ngFor="let x of tableData" [ngClass]="[x.selected?'selected':'']" (click)="onSelect(x)">
          <td><span>{{x.userName}}</span></td>
          <td><span>{{x.firstname}}</span></td>
          <td><span>{{x.lastname}}</span></td>
        </tr>
      </table>
   </div> 

</div>

<app-dialog-footer [buttonObj]="buttonObj" (cancel)="close()" (save)="save($event)"></app-dialog-footer>