<div class="action-bar">
  <div class="action-bar__left" [class.weekView]="viewPeriod=='week'">
    <div class="btn-controls">
      <!-- <activities-app-button *ngFor="let control of buttonControls" [buttontype]="control" (valueChange)="changeEvent($event)">
      </activities-app-button> -->
      <activities-app-button [buttontype]="buttonControls[0]" (click)="changeEvent(buttonControls[0].name)"></activities-app-button>
      <div class="action-bar__zoom-view">
        <activities-app-button [buttontype]="buttonControls[1]" (click)="changeEvent(buttonControls[1].name)"></activities-app-button>
        <activities-app-button [buttontype]="buttonControls[3]" (click)="changeEvent(buttonControls[3].name)"></activities-app-button>
        <activities-app-button [buttontype]="buttonControls[2]" (click)="changeEvent(buttonControls[2].name)"></activities-app-button>
      </div>
    </div>
    <div class="view-by">
      <label for="sort-by" class="view-by__label seat-labels">{{'timelineViewByText' | translate}}</label>
      <dynamic-form [config]="viewByConfig" #viewByForm>
      </dynamic-form>
    </div>
    <div class="search">
      <dynamic-form [config]="searchconfig" #searchConfigForm>
      </dynamic-form>
    </div>
    <div class="legends-icon-holder" style="position: relative;z-index: 1">
      <a [ngbPopover]="legendsPopover" placement="bottom" [autoClose]="'outside'" class="icon-legends">
      </a>
      <ng-template #legendsPopover>
        <app-display-legend style="min-width: 150px;display: inline-block;" [legends]="legendsConfig" [isColumn]="true">
        </app-display-legend>
      </ng-template>
    </div>
  </div>
  <div class="action-bar__secondary-actions">   
    <!-- <activities-app-button [buttontype]="addClassButton" (valueChange)='addNewClass()'></activities-app-button> -->
    <activities-app-button *ngIf="selectedViewId == viewByOptions.Instructor && viewPeriod=='day'" [buttontype]="staffBlockHours" (valueChange)='ats.createStaffBlockHr(CurrentDateSelected,staffScheduleShitsBreakHours)'></activities-app-button>
    <div class="filter-icon-holder">
      <a [ngbPopover]="filterPopover" placement="bottom" [autoClose]="'outside'" class="icon-Filter">
      </a>
      <ng-template #filterPopover>       
        <ng-container *ngTemplateOutlet="GuestTypeFilters"></ng-container>
        <ng-container *ngTemplateOutlet="PaymentTypeFilters"></ng-container>
        <ng-container *ngTemplateOutlet="ActivityFilters"></ng-container>
        
        <ng-container *ngIf="selectedViewId===1">
          <h3 class="filter-name">
            {{'Categories' | translate}}
          </h3>
          <ul class="category-list">
            <li >
              <mat-checkbox class="parent-label" [(ngModel)]="allCategorySelected" (change)="allCategorySelectedHandler()">
                {{'All' | translate}}
              </mat-checkbox>
            </li>
            <li *ngFor="let category of categoriesList">
              <ng-container *ngIf="(category.id | activityColor) as categoryStyle">
                <mat-checkbox class="parent-label" [class.collapsed]="category.collapsed"
                [ngStyle]="{'backgroundColor': categoryStyle.backgroundColor, 'borderColor': categoryStyle.color }"
                [(ngModel)]="category.selected" (change)="categorySelectHandler(category)">
                  {{category.name}}
                  <span class="icon icon-next" (click)="expansionToggle($event, category)"></span> 
                </mat-checkbox>
                <ul class="activity-list" >
                  <li *ngFor="let activity of category.activities">
                    <mat-checkbox [(ngModel)]="activity.selected" (change)="activitySelectHandler(category)">
                      {{activity.name}}
                    </mat-checkbox>
                  </li>
                </ul>
              </ng-container>
            </li>
          </ul>
        </ng-container>        
      </ng-template>
    </div>
    <dynamic-form [config]="viewPeriodConfig" #viewByPeriodForm>
    </dynamic-form>

    <ng-container>
      <div class="button-align d-flex">
      <button mat-button class=" button-group d-flex" (click)="changeEvent(timelineActionButtonControls[0].name)">
        <span> {{timelineActionButtonControls[0]?.label | translate}}</span>
       </button>
      <button [matMenuTriggerFor]="menuForTimelineAction" class=" button-group button-arrow d-flex justify-content-center align-items-center" >          
        <span class="icon-down-arrow" style="font-size:11px"></span></button>
      </div>

      <mat-menu #menuForTimelineAction="matMenu">
        <ng-container *ngFor="let control of timelineActionButtonControls">
          <button mat-menu-item (click)="changeEvent(control.name)">{{control.label | translate}}</button>
        </ng-container>
      </mat-menu>
    </ng-container>
    <!-- <activities-app-button [buttontype]="createActivityButton" (valueChange)='createActivity()'
      *ngIf="(rolesAndPermissionsType?.CreateActivity | rolesAndPermission) && !cs.isIframeEnabled"></activities-app-button> -->
    <!-- <activities-app-button [buttontype]="packagesListButton" *ngIf="!cs.isIframeEnabled" (valueChange)='bookedPackages()'></activities-app-button>
    <activities-app-button [buttontype]="standByListButton" (valueChange)='standByReservations()'></activities-app-button> -->
  </div>
</div>
<ng-container *ngIf="timeArrayGenerated && timeArrayGenerated.length; then table; else noOpenHours">

</ng-container>
<ng-template #table>

  <div class="table-timeline" [ngClass]="{'timeline-scroll': isSwitchAxis}" >
    <div *ngIf="viewPeriod=='day'; else weeklyTable" class="timeline">
      <!-- <ng-scrollbar track="all"> -->
        <ng-container *ngIf="!isSwitchAxis; else switchAxis">
      <cdk-virtual-scroll-viewport class="list-container list-group" [ngClass]="{'vcart-container' : cs?.isIframeEnabled}" #scrollViewport [itemSize]="100" minBufferPx="900" maxBufferPx="1000"
        (scroll)="onScroll($event)">
          <div id="currentTimeIndicator" class="current-timeline" *ngIf="(timeLineData && timeLineData.length > 0 ) || selectedViewId !== viewByOptions?.Lessons"
            [style.left]="(currentTimeStart-80)+'px'">
            <span class="current-timeline__label">{{restaurantDateTime | localizedDate:'LT'}}</span>
          </div>
          <div class="table-timeline__header" [style.top]="inverseOfTranslation">
            <div class="table-timeline__row table-timeline__row--header" *ngIf="dataList?.ByClass?.length || selectedViewId !== viewByOptions?.Lessons">
              <div class="table-timeline__column table-timeline__column--header sticky-left">{{ this.timelineViewByName }}
                <div class="switchAxis">
                  <a class="icon-swap" (click)="changeAxis()">
                  </a>
                </div>
              </div>
              <ng-container *ngFor="let column of (timeArrayGenerated | timeArrayRefactor: timeFilter);let index=index;">
                <div class="table-timeline__column table-timeline__column--header" dynamicSlotWidth
                  [slotWidth]="columnWidth">
                  {{column | localizedDate : 'LT'}}</div>
              </ng-container>
            </div>
          </div>
  
          <div class="table-timeline__body" *ngIf="timeArrayGenerated && timeArrayGenerated.length">
            <!-- <app-timeline-row [timeArrayGenerated]="timeArrayGenerated" [dataList]="instructors"
              [columnWidth]="columnWidth" (addBookingToSlot)="addBookingToSlot($event)"
              (activitySelected)="activitySelected($event)" [dataDate]="CurrentDateSelected">
            </app-timeline-row> -->
  
            <!-- <div class="table-timeline-main">
              <div class="table-timeline__body" *ngIf="timeArrayGenerated && timeArrayGenerated.length && timeLineData ">
                <ng-container *ngIf="((timeLineData | timelineFilter : filterData[viewBy] : searchText)  | sortData: sortBy)  as filteredTimeLineData">
                  <div *cdkVirtualFor="let rowData of filteredTimeLineData">
                    <app-timeline-row [timeArrayGenerated]="timeArrayGenerated" [rowData]="rowData" [lastIndex]="lastIndex" [columnWidth]="columnWidth" 
                    [selectedViewId]="viewBy" [CurrentDateSelected]="CurrentDateSelected" [OpenHours]="_settings.OpenHours" 
                    [TimeSlotUnitInMinutes]="_settings.General.TimeSlotUnitInMinutes" [noOfBookings]="rowData.OpenPrivateReservations?.length"></app-timeline-row>
                  </div>
                  
                  <div class="noDataFound" style="padding: 10px 0;text-align: center;" *ngIf="!filteredTimeLineData || (filteredTimeLineData && !filteredTimeLineData.length)">
                    {{'noDataFound' | translate}}
                  </div>
                </ng-container>
                
              </div>
            </div> -->
            <app-timeline-main [columnWidth]="columnWidth" [searchText]="searchText" [viewPort]="scrollViewport"
              (addBookingToSlot)="addBookingToSlot($event)" [timeArrayGenerated]="timeArrayGenerated" [isSwitchAxis]="isSwitchAxis"
              (activitySelected)="activitySelected($event)" [timeLineData]="timeLineData" [viewBy]="selectedViewId"
              [lastIndex]="lastIndex" [filterData]="filterData[selectedViewId]" [CurrentDateSelected]="CurrentDateSelected" [OpenHours]="_settings.OpenHours" [TimeSlotUnitInMinutes]="_settings.General.TimeSlotUnitInMinutes" [staffSchedule]="staffScheduleShitsBreakHours"></app-timeline-main>
          </div>
      </cdk-virtual-scroll-viewport>
    </ng-container>
    <ng-template #switchAxis>
          <cdk-virtual-scroll-viewport class="list-container list-group timeline-scroll__switch" [ngClass]="{'vcart-container' : cs?.isIframeEnabled}" #scrollViewportSwitchAxis orientation="horizontal" [itemSize]="50" minBufferPx="200" maxBufferPx="250" (scroll)="onScroll($event)">
            <div class="table-timeline__header-switch sticky-left">
              <div class="table-timeline__row-switch table-timeline__row--header-switch" *ngIf="dataList?.ByClass?.length || selectedViewId !== viewByOptions?.Lessons">
                <div class="table-timeline__switch--column table-timeline__switch--column--header sticky-left sticky-top">{{ this.timelineViewByName }}
                  <div class="switchAxis">
                    <a class="icon-swap" (click)="changeAxis()">
                    </a>
                  </div>
                </div>
                <ng-container *ngFor="let column of (timeArrayGenerated | timeArrayRefactor: timeFilter);let index=index;">
                  <div class="table-timeline__switch--column table-timeline__switch--column--header header-size">
                    {{column | localizedDate : 'LT'}}</div>
                </ng-container>
              </div>
            </div>
            <div class="table-timeline__body-switch" *ngIf="timeArrayGenerated && timeArrayGenerated.length">
            <div class="time-indicator" [style.top]="(currentTimeStart-155)+'px'">
              <span class="time-indicator__label">{{restaurantDateTime | localizedDate:'LT'}}</span>
              <mat-divider class="time-indicator__divider"></mat-divider>
            </div>
              <app-timeline-main [columnWidth]="columnWidth" [searchText]="searchText" [viewPort]="scrollViewportSwitchAxis"
                (addBookingToSlot)="addBookingToSlot($event)" [timeArrayGenerated]="timeArrayGenerated" [isSwitchAxis]="isSwitchAxis"
                (activitySelected)="activitySelected($event)" [timeLineData]="timeLineData" [viewBy]="selectedViewId"
                [lastIndex]="lastIndex" [filterData]="filterData[selectedViewId]" [CurrentDateSelected]="CurrentDateSelected" [OpenHours]="_settings.OpenHours" [TimeSlotUnitInMinutes]="_settings.General.TimeSlotUnitInMinutes" [staffSchedule]="staffScheduleShitsBreakHours"></app-timeline-main>
            </div>
        </cdk-virtual-scroll-viewport>
    </ng-template>
     



      <!-- </ng-scrollbar> -->
    </div>
    <ng-container  *ngIf="dragHandlerService.dragStarted">
      <div class="horizontal-scroll-handler horizontal-scroll-handler--left" (mouseenter)="dragHandlerService.autoScrollHandler(1, viewPort, 'left')" (mouseleave)="dragHandlerService.mouseLeave()" [attr.direction]="1" [attr.axis]="'left'"><i class="icon-Sort-Up"></i></div>
      <div class="horizontal-scroll-handler horizontal-scroll-handler--right" (mouseenter)="dragHandlerService.autoScrollHandler(-1, viewPort, 'left')" (mouseleave)="dragHandlerService.mouseLeave()" [attr.direction]="-1" [attr.axis]="'left'"><i class="icon-Sort-Up"></i></div>
      <div class="vertical-scroll-handler vertical-scroll-handler--up" (mouseenter)="dragHandlerService.autoScrollHandler(1, viewPort, 'top')" (mouseleave)="dragHandlerService.mouseLeave()" [attr.direction]="1" [attr.axis]="'top'"><i class="icon-Sort-Up"></i></div>
      <div class="vertical-scroll-handler vertical-scroll-handler--down" (mouseenter)="dragHandlerService.autoScrollHandler(-1, viewPort, 'top')" (mouseleave)="dragHandlerService.mouseLeave()" [attr.direction]="-1" [attr.axis]="'top'"><i class="icon-Sort-Down"></i></div>
    </ng-container>
    <ng-template #weeklyTable>
      <cdk-virtual-scroll-viewport class="list-container list-group" [ngClass]="{'vcart-container' : cs?.isIframeEnabled}" #scrollViewportMonthly itemSize="200"  [appendOnly]="dragHandlerService.dragStarted">
        
        <div class="table-timeline__header" [style.top]="inverseOfTranslation" style="width: 100%;">
          <div class="table-timeline__row table-timeline__row--header" [ngClass]="{ 'dayname-header' : showDayName }">
            <div class="table-timeline__column table-timeline__column--header sticky-left" [ngClass]="{ 'dayname-column-header' : showDayName }">{{timelineViewByName}}</div>
            <div class="table-timeline__row--header-week" gridColumnsGenerator="7" [columnWidth]="columnWidth*4">
              <ng-container
                *ngFor="let column of (CurrentDateSelected | weekDateGenerator: restaurantDateTime);let index=index;">
                <div class="table-timeline__column table-timeline__column--header" >
                  <label [class.hightlight]="column[1]">{{column[0] | configDateFormatter}}</label>
                  <p *ngIf="showDayName">{{column[0] | weekDayPipe }}</p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <app-weekly-view [dataList]="allBookings" (activitySelected)="activitySelected($event)"
          (addBookingToSlot)="addBookingToSlot($event)" [viewBy]="selectedViewId" [viewPort]="scrollViewportMonthly" [columnWidth]="columnWidth" [filterDataBckup]="filterData[selectedViewId]"
          [settings]="_settings" [searchText]="searchText" [filterData]="filterData[selectedViewId]" [timelineCommonFilters]="partyService.timelineCommonFilters">

        </app-weekly-view>
      </cdk-virtual-scroll-viewport>

    </ng-template>

    <app-dashboard [type]="ReservationType.Timeline" [dashboardData]="dashboardData" (actions)="actions($event)"
      (partyUnselectedEvent)="partyUnselectedEventHander()" (createReservation)="create($event)"></app-dashboard>
    <!--  <div class="class-details" [class.active]="selectedClass || selectedOpenBooking || selectedPrivateLessonBooking">

      <open-booking-detail *ngIf="selectedOpenBooking" [bookingDetail]="selectedOpenBooking"
        (cancelParty)="cancelParty($event)" (close)="closeActivityDetail()"></open-booking-detail>
      <privatelesson-booking-detail *ngIf="selectedPrivateLessonBooking" [classDetail]="selectedPrivateLessonBooking"
        (cancelParty)="cancelParty($event)" (close)="closeActivityDetail()">
      </privatelesson-booking-detail> -->
      <!-- <class-details *ngIf="selectedClass" [classDetail]="selectedClass" [selectedViewId]="selectedViewId"
        (close)="closeActivityDetail()"></class-details> 
    </div> -->
  </div>
</ng-template>

<ng-template #noOpenHours>
  <div class="noOpenHoursConfigured">
    {{'noOpenHoursConfigured' | translate}}
  </div>
</ng-template>

<ng-template #GuestTypeFilters>
  <li class="guest-payment-type-filter">
      <h3 class="filter-name ag_cursor--pointer" [class.collapsed]="partyService.timelineCommonFilters.GuestTypeFilterCollapsed"
      (click)="partyService.timelineCommonFilters.GuestTypeFilterCollapsed = !partyService.timelineCommonFilters.GuestTypeFilterCollapsed">
        {{'reservationStatus' | translate}}
        <span class="icon icon-next"></span> 
      </h3>
      <ul class="activity-list">
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.GuestTypeFilterState.allSelected"
            (change)="timelineFilterSelectAllHandler(true,partyService.timelineCommonFilters.GuestTypeFilterState.allSelected, 'GuestTypeFilterState')">
            {{'All' | translate}}
          </mat-checkbox>
        </li>
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.GuestTypeFilterState.showBookedSessions"
            (change)="timelineFilterOnChangeHandler(true, 'GuestTypeFilterState')">
            {{'Booked' | translate}}
          </mat-checkbox>
        </li>
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.GuestTypeFilterState.showBookingConfirmedSessions"
            (change)="timelineFilterOnChangeHandler(true, 'GuestTypeFilterState')">
            {{'confirmed' | translate}}
          </mat-checkbox>
        </li>
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.GuestTypeFilterState.showStandbyBookedSessions"
            (change)="timelineFilterOnChangeHandler(true, 'GuestTypeFilterState')">
            {{'Standby' | translate}}
          </mat-checkbox>
        </li>
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.GuestTypeFilterState.showOverbookedSessions"
            (change)="timelineFilterOnChangeHandler(true, 'GuestTypeFilterState')">
            {{'OverBooked' | translate}}
          </mat-checkbox>
        </li>
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.GuestTypeFilterState.showCancelledReservations"
            (change)="timelineFilterOnChangeHandler(true, 'GuestTypeFilterState')">
            {{'Cancelled' | translate}}
          </mat-checkbox>
        </li>
      </ul>
  </li>
</ng-template>

<ng-template #PaymentTypeFilters>
  <li class="guest-payment-type-filter">
      <h3 class="filter-name ag_cursor--pointer" [class.collapsed]="partyService.timelineCommonFilters.PaymentTypeFilterCollapsed"
      (click)="partyService.timelineCommonFilters.PaymentTypeFilterCollapsed = !partyService.timelineCommonFilters.PaymentTypeFilterCollapsed">
        {{'PaymentStatus' | translate}}
        <span class="icon icon-next"></span> 
      </h3>
      <ul class="activity-list" >
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.PaymentTypeFilterState.allSelected"
            (change)="timelineFilterSelectAllHandler(true, partyService.timelineCommonFilters.PaymentTypeFilterState.allSelected, 'PaymentTypeFilterState')">
            {{'All' | translate}}
          </mat-checkbox>
        </li>
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.PaymentTypeFilterState.showPaidBookings"
            (change)="timelineFilterOnChangeHandler(true, 'PaymentTypeFilterState')">
            {{'Paid' | translate}}
          </mat-checkbox>
        </li>
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.PaymentTypeFilterState.showPartialPaidBookings"
            (change)="timelineFilterOnChangeHandler(true, 'PaymentTypeFilterState')">
            {{'PartiallyPaidText' | translate}}
          </mat-checkbox>
        </li>
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.PaymentTypeFilterState.showUnpaidBookings"
            (change)="timelineFilterOnChangeHandler(true, 'PaymentTypeFilterState')">
            {{'NotPaidText' | translate}}
          </mat-checkbox>
        </li>
      </ul>
  </li>
</ng-template>

<ng-template #ActivityFilters>
  <li class="guest-payment-type-filter">
      <h3 class="filter-name ag_cursor--pointer" [class.collapsed]="partyService.timelineCommonFilters.ActivityFilterCollapsed"
      (click)="partyService.timelineCommonFilters.ActivityFilterCollapsed = !partyService.timelineCommonFilters.ActivityFilterCollapsed">
        {{'lessons' | translate}} {{'filterText' | translate}}
        <span class="icon icon-next"></span> 
      </h3>
      <ul class="activity-list" >
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.ActivityFilterState.allSelected"
            (change)="timelineFilterSelectAllHandler(true, partyService.timelineCommonFilters.ActivityFilterState.allSelected, 'ActivityFilterState')">
            {{'All' | translate}}
          </mat-checkbox>
        </li>
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.ActivityFilterState.showCancelledSessions"
            (change)="timelineFilterOnChangeHandler(true, 'ActivityFilterState')">
            {{'Cancelled' | translate}}
          </mat-checkbox>
        </li>
        <li class="booked-sessions-filter">
          <mat-checkbox [(ngModel)]="partyService.timelineCommonFilters.ActivityFilterState.showBlockedSessions"
            (change)="timelineFilterOnChangeHandler(true, 'ActivityFilterState')">
            {{'blockedText' | translate}}
          </mat-checkbox>
        </li>
      </ul>
  </li>
</ng-template>