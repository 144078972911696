<div class="standby-activities">
  <div class="standby-activities__header">
    <div class="standby-activities__calendar">

      <div class="standby-activities__date-section">
        <dynamic-form [config]="datePickerconfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="standby-activities__today-button" *ngIf="showTodayButton">
        <activities-app-button [buttontype]="buttonToday" (valueChange)="navigateToToday()"></activities-app-button>
      </div>
    </div>
    <div class="standby-activities__searchbar">
      <dynamic-form [config]="searchconfig" #form="dynamicForm">
      </dynamic-form>
    </div>
  </div>
  <hr />
  <div class="attendees">
    <ng-scrollbar>
      <div class="user-card__container">
        <div class="user-card" *ngFor="let attendee of StandBYList  | attendeesFilter : searchText">
          <span class="icon-edit" *ngIf="attendee?.isOpenBooking && !cs.isIframeEnabled" (click)="editStandbyOpenBooking(attendee)"></span>
          <div class="user-card__body">
            <div class="user-card__name-image-view">
              <div class="intial-image-view" [ngStyle]="{'background-color': '' | randomColor}">
                {{attendee.Contact | guestNameFormatter}}</div>
            </div>
            <div class="user-card__content-view">
              <h3 class="user-card__name text-ellipsis" *ngIf="attendee.Contact">
                {{attendee?.Contact?.FirstName}} {{attendee?.Contact?.LastName || ''}}
              </h3>
              <p class="user-card__mobile text-ellipsis">{{attendee?.SpecialMealName}}</p>
              <p class="user-card__mobile text-ellipsis">
                <span *ngIf="attendee.EndTime">{{attendee.StartTime | localizedDate : 'LT' }} - {{attendee.EndTime | localizedDate : 'LT' }}</span>
                <span *ngIf="!attendee.EndTime">{{attendee.StartDate | localizedDate : 'MMM DD' }} - {{attendee.EndDate | localizedDate : 'MMM DD' }}</span>
                &nbsp;-&nbsp;
                <span>{{attendee.SessionType}}</span>
                &nbsp;-&nbsp;
                <span>{{attendee.Size}}</span>{{' '}}<span class="icon icon-add-contact"></span>
              </p>
              <!-- <p class="user-card__mobile user-card__location text-ellipsis"><span
                  class="icon icon-placeholder"></span>&nbsp;{{attendee?.LocationName}}</p> -->
              <p *ngIf="attendee && attendee.Instructors && attendee.Instructors.length" class="user-card__mobile user-card__instructors text-ellipsis"  >
                <span>{{attendee?.Instructors[0]}}</span>
                <span class="user-card__more-instructors" [matTooltip]="attendee?.Instructors | arrayJoin" *ngIf="attendee?.Instructors.length>1">&nbsp; +{{attendee?.Instructors.length - 1 }}</span>
              </p>
            </div>


          </div>
          <div class="user-card__btn-view">
            <div class="user-card__btn-view-btn">

              <activities-app-button class="user-card__btn-margin" [buttontype]="RejectBtn" (valueChange)="RejectClick(attendee)">
              </activities-app-button>
              <activities-app-button class="user-card__btn-margin"  [buttontype]="(attendee.isPast) ?  AcceptDisabledBtn : AcceptBtn" (valueChange)="ConfirmClick(attendee)">
              </activities-app-button>
            </div>


          </div>
        </div>

        <div class="no-results" *ngIf="!StandBYList || !StandBYList.length">{{'noStandbyReservationsFound' | translate}}</div>
      </div>
    </ng-scrollbar>
  </div>
</div>
