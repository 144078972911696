  <div class="commissiongratuityservicechargeworks" #reportControl [ngClass]="{'full-view': status}">
    <div class="h-100">
      <div class="left-asidereports" id="time-overlay">
        <ng-scrollbar >
          <form [formGroup]="reportsinput" autocomplete="off">
            <div>
              <mat-form-field [floatLabel]="floatLabel">
                <mat-select placeholder="{{captions.ReportName}}" name="reportNameSelector" formControlName="reportName"
                  (selectionChange)="updateReportControls($event);" [disabled]="disableReportName">
                  <ng-container *ngFor="let report of reportSelector">
                    <mat-option [disabled]="!report.IsAuthorized" (click)="accessCheckAndAlert(report)"
                      *ngIf="report.group == reportGroup" [value]="report.code">
                      {{report.value}}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="reportGroup == 'CLIENT'">
              <mat-form-field class="w-100 h-25" [floatLabel]="floatLabel">
                <input placeholder="Search By Client Name" aria-label="Number" matInput
                  [formControl]="reportsinput.controls['clientTobeSelected']" [matAutocomplete]="auto"
                  (change)="searchClient()">
                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn">
                  <mat-option *ngFor="let client of Clients" [value]="client" (click)="clientSelected(client.name)">
                    {{client.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div>
                <mat-error *ngIf="showMissingClientError">
                  Missing Client
                </mat-error>
              </div>
            </div>



            <div>
            <ng-container>
              <div *ngIf="showTime">
                <!-- <mat-form-field class="form-group date-picker-width" [floatLabel]="floatLabel">
                  <input matInput type="text" [format]='this.localization.getTimeFormat()' [ngxTimepicker]="toggleTimepicker"
               placeholder="{{this.localization.captions.common.StartTime}}" formControlName="startTime" name="starttime"
                autocomplete="off" (focus)="openPicker($event)" readonly>
                <ngx-material-timepicker-toggle matSuffix [for]="toggleTimepicker" class="">
                  <i  aria-hidden="true" class="icon-scheduledclock cursor" (click)="openPicker($event)" ngxMaterialTimepickerToggleIcon></i>
                </ngx-material-timepicker-toggle>
            </mat-form-field>
            <ngx-material-timepicker #toggleTimepicker enableKeyboardInput="true" (closed)="isClosed($event)" [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn"></ngx-material-timepicker>
            <ng-template #cancelBtn ><button class="timepickerCustBtn timepicker-button picker-cancel">{{this.localization.captions.common.Cancel}}</button></ng-template>
            <ng-template #confirmBtn ><button class="timepickerCustBtn timepicker-button picker-ok">{{this.localization.captions.common.OK}}</button></ng-template> -->

            <app-ag-time-picker [inputs]="startTimeInput" (timeChangeEvent)="startTimeChange($event)"></app-ag-time-picker>
              </div>

              <div *ngIf="showTime">
                <!-- <mat-form-field class="form-group date-picker-width" [floatLabel]="floatLabel">
                  <input matInput type="text" [format]='this.localization.getTimeFormat()' [ngxTimepicker]="toggleTimepicker1"
               placeholder="{{this.localization.captions.common.EndTime}}" formControlName="endTime" name="endtime" [min]="reportsinput.controls.startTime.value"
                autocomplete="off" (focus)="openPicker($event)" readonly>
                <ngx-material-timepicker-toggle matSuffix [for]="toggleTimepicker1" class="">
                  <i  aria-hidden="true" class="icon-scheduledclock cursor" (click)="openPicker($event)" ngxMaterialTimepickerToggleIcon></i>
                </ngx-material-timepicker-toggle>
            </mat-form-field>
            <ngx-material-timepicker #toggleTimepicker1 enableKeyboardInput="true" (closed)="isClosed($event)" [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn"></ngx-material-timepicker>
            <ng-template #cancelBtn ><button class="timepickerCustBtn timepicker-button picker-cancel">{{this.localization.captions.common.Cancel}}</button></ng-template>
            <ng-template #confirmBtn ><button class="timepickerCustBtn timepicker-button picker-ok">{{this.localization.captions.common.OK}}</button></ng-template> -->

            <app-ag-time-picker [inputs]="endTimeInput"></app-ag-time-picker>

              </div>

            </ng-container>

            <div *ngIf="showDate">
              <!-- <mat-form-field class="form-group" *ngIf="showDate" appearance="legacy" >
                <mat-label>{{commonCaptions.StartDate}}</mat-label>
                <input matInput [matDatepicker]="picker1" dateRestricter placeholder="{{placeholderFormat}}" class="picker1"
                  formControlName="startDate" (dateChange)="OnFromDateValueChange($event)"  [min]="defaultMinDate" [max]="maxDate">
                <mat-datepicker #picker1></mat-datepicker>
                <i  aria-hidden="true" matSuffix class="icon-Calender cursor" (click)="picker1.open()"></i>
                <mat-error *ngIf="reportsinput.controls['startDate'].hasError('matDatepickerParse')">{{commonCaptions.dateFormat}}</mat-error>   
                <mat-error *ngIf="reportsinput.controls['startDate'].hasError('required') && !reportsinput.controls['startDate'].hasError('matDatepickerParse')">{{commonCaptions.Missing}} {{commonCaptions.StartDate}}</mat-error>   
                <mat-error *ngIf="reportsinput.controls['startDate'].hasError('matDatepickerMin')">{{commonCaptions.minimum}} {{commonCaptions.StartDate}}</mat-error>
                <mat-error *ngIf="reportsinput.controls['startDate'].hasError('matDatepickerMax')">{{commonCaptions.maximum}} {{commonCaptions.StartDate}}</mat-error>
              </mat-form-field> -->
              <div *ngIf="isFromJobScheduler">
                <div class="ag_display--flex">
                  <mat-form-field class="full-width" [floatLabel]="floatLabel" >
                    <mat-select [id]="'ag-automation-selection-timeline'" [formControlName]="'timelineFrom'" 
                      [attr.LiteralID]="'timelineFrom'" [placeholder]="captions.lbl_startDate">
                      <mat-option *ngFor="let item of timelineOptions" [value]="item.id" [matTooltip]="item.viewValue">
                        {{item.viewValue}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                    <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--33 pr-3"class="ag-ml-1">
                    <input matInput [formControlName]="'timelineCountFrom'" autocomplete="off" [type]="'number'"
                    min="-365" max="365" maxLength="3" numMaxlength>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="!isFromJobScheduler">
              <app-ag-date-picker [inputs]="startDateInputs" (datePickerChange)="OnFromDateValueChange($event)">
              </app-ag-date-picker>
              </div>
            </div>
            <div *ngIf="showEndDate">
              <div *ngIf="!isFromJobScheduler">
                <app-ag-date-picker [inputs]="endDateInputs" (datePickerChange)="onEndDateOpen($event)">
                </app-ag-date-picker>
              </div>
              <div *ngIf="isFromJobScheduler">
                <div class="display-center">
                  <mat-form-field class="full-width" [floatLabel]="floatLabel">
                    <mat-select [id]="'ag-automation-selection-timeline'" [formControlName]="'timelineTo'"
                      [attr.LiteralID]="'timelineTo'" [placeholder]="captions.lbl_endDate">
                      <mat-option *ngFor="let item of timelineOptions" [value]="item.id" [matTooltip]="item.viewValue">
                        {{item.viewValue}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--33 pr-3" class="ag-ml-1">
                    <input matInput [formControlName]="'timelineCountTo'" autocomplete="off" [type]="'number'"
                    min="-365" max="365" maxLength="3" numMaxlength>
                  </mat-form-field>
                </div>
              </div>
              <!-- <mat-form-field class="form-group" *ngIf="showDate">
                <mat-label>{{commonCaptions.EndDate}}</mat-label>
                <input matInput [matDatepicker]="picker2" dateRestricter placeholder="{{placeholderFormat}}" class="picker2" 
                  formControlName="endDate" (focus)="onEndDateOpen($event);" (dateChange)="onEndDateOpen($event);" [min]="minEndDate" [max]="maxDate">
                <mat-datepicker #picker2></mat-datepicker>
                <i  aria-hidden="true" matSuffix class="icon-Calender cursor" (click)="onEndDateOpen($event);picker2.open();"></i>
                <mat-error *ngIf="reportsinput.controls['endDate'].hasError('matDatepickerParse')">{{commonCaptions.dateFormat}}</mat-error>   
                <mat-error *ngIf="reportsinput.controls['endDate'].hasError('required') && !reportsinput.controls['endDate'].hasError('matDatepickerParse')">{{commonCaptions.Missing}} {{commonCaptions.EndDate}}</mat-error>   
                <mat-error *ngIf="reportsinput.controls['endDate'].hasError('matDatepickerMin')">{{commonCaptions.minimum}} {{commonCaptions.EndDate}}</mat-error>
                <mat-error *ngIf="reportsinput.controls['endDate'].hasError('matDatepickerMax')">{{commonCaptions.maximum}} {{commonCaptions.EndDate}}</mat-error>
              </mat-form-field> -->
            </div>


            <div *ngIf="inActive" class="mb-3">
              <label class="LW14">{{inActiveText}}</label>
              <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleIncludeInactive($event[0])"
                [formControl]="reportsinput.controls['includeInactiveusers']"></app-retail-toggle-switch>

            </div>           
            <div>

              <app-retail-multi-select-dropdown *ngIf="showFilterDropDown" [dropDownFilterData]="dropDownFilterData" [activeToggle]="activeToggle"
                [dropDownControlname]="'reportNameChild'" [dropDownName]="drowDownFilterName" [reportName]="reportsinput.value.reportName"
                (dropDownFrmControl)="getFormControlValue($event)" (IsAnySelected)="IsSelectionValid($event)"
                [selectedData]="selectedData">
              </app-retail-multi-select-dropdown>
            </div>
         
            <div *ngIf="showHelperText">
              <label class="LW14">{{commonCaptions.UserHelpertext}}{{"-"}}{{commonCaptions.UserHelper}} {{commonCaptions.TherapistHelpertext}}{{commonCaptions.TherapistHelper}}</label>
            </div>
          


            <!-- Custom Report Filter Block  BEGIN-->
            <div *ngIf="customReport" class="mb-3" [ngSwitch]="customReport">
              <ng-template [ngSwitchCase]="'MultiPackItems'">
                <app-retail-multipack-item (formReady)="formInitialized($event)"></app-retail-multipack-item>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventorySummary'">
                <app-retail-inventory-summary (formReady)="formInitialized($event)" (IsValidSelection)="customFilterBlockValidation($event)"></app-retail-inventory-summary>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventoryDetails'">
                <app-retail-inventory-details (formReady)="formInitialized($event)" (IsValidSelection)="customFilterBlockValidation($event)"></app-retail-inventory-details>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventoryReport'">
                <app-retail-inventory-report (formReady)="formInitialized($event)" (IsValidSelection)="customFilterBlockValidation($event)"></app-retail-inventory-report>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventoryTransfer'">
                <app-retail-inventory-transfer (formReady)="formInitialized($event)"></app-retail-inventory-transfer>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventoryWash'">
                <app-retail-inventory-wash (formReady)="formInitialized($event)"></app-retail-inventory-wash>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventoryAudit'">
                <app-retail-inventory-audit (formReady)="formInitialized($event)"[ShowOutletSelection]="ShowOutletSelectionFieldInInventoryAuditTrailReport" 
                 (IsValidSelection)="customFilterBlockValidation($event)"></app-retail-inventory-audit>
              </ng-template>
              <ng-template [ngSwitchCase]="'SalesByDiscountTypes'">
                <app-retail-sales-by-discountype (formReady)="formInitialized($event)"></app-retail-sales-by-discountype>
              </ng-template>
              <ng-template [ngSwitchCase]="'SalesBySubCategory'">
                <app-retail-sales-by-subcategory (formReady)="formInitialized($event)"></app-retail-sales-by-subcategory>
              </ng-template>
              <ng-template [ngSwitchCase]="'SalesByCategory'">
                <app-retail-sales-by-category (formReady)="formInitialized($event)" (validateUser)="validateForm($event)"></app-retail-sales-by-category>
              </ng-template>
              <ng-template [ngSwitchCase]="'SaleByItem'">
                <app-retail-sale-by-item (formReady)="formInitialized($event)" (IsValidTextInput)="TextInputFilterBlockValidation($event)" (validateUser)="validateForm($event)"></app-retail-sale-by-item>
              </ng-template>
              <ng-template [ngSwitchCase]="'SalesByVendor'">
                <app-sales-by-vendor (formReady)="formInitialized($event)" (IsValidTextInput)="TextInputFilterBlockValidation($event)"></app-sales-by-vendor>
              </ng-template>
              <ng-template [ngSwitchCase]="'CorrectVoid'">
                <app-retail-correct-void (formReady)="formInitialized($event)"></app-retail-correct-void>
              </ng-template>
              <ng-template [ngSwitchCase]="'Shift'">
                <app-retail-shift (formReady)="formInitialized($event)" (validateUser)="validateForm($event)"></app-retail-shift>
              </ng-template>
              <ng-template [ngSwitchCase]="'RevenueSummary'">
                <app-retail-revenue-summary (formReady)="formInitialized($event)"></app-retail-revenue-summary>
              </ng-template>
              <ng-template [ngSwitchCase]="'TopSpenders'">
                <app-retail-top-spenders (formReady)="formInitialized($event)" [ShowOutletSelection]="ShowOutletSelectionFieldInTopSpendersReport" 
                (validateUser)="validateForm($event)"></app-retail-top-spenders>
              </ng-template>
              <ng-template [ngSwitchCase]="'Transaction'">
                <app-retail-transaction (formReady)="formInitialized($event)" (validateUser)="validateForm($event)"></app-retail-transaction>
              </ng-template>
              <ng-template [ngSwitchCase]="'GiftCardsRedeem'">
                <app-gift-card-redeem (formReady)="formInitialized($event)" (validateUser)="validateForm($event)"></app-gift-card-redeem>
              </ng-template>
              <ng-template [ngSwitchCase]="'GiftCardsIssued'">
                <app-gift-card-issued (formReady)="formInitialized($event)" (validateUser)="validateForm($event)"></app-gift-card-issued>
              </ng-template>
              <ng-template [ngSwitchCase]="'TransactionDetail'">
                <app-retail-transaction-detail (formReady)="formInitialized($event)"></app-retail-transaction-detail>
              </ng-template>
              <ng-template [ngSwitchCase]="'TaxExemptSales'">
                <app-retail-taxexempt-sales (formReady)="formInitialized($event)"></app-retail-taxexempt-sales>
              </ng-template>
              <ng-template [ngSwitchCase]="'StaffMemberSummary'">
                <app-staff-member-summary (formReady)="formInitialized($event)"></app-staff-member-summary>
              </ng-template>
              <ng-template [ngSwitchCase]="'Surcharges'">
                <app-surcharges (formReady)="formInitialized($event)"></app-surcharges>
              </ng-template>
              <ng-template [ngSwitchCase]="'Accrual'" *ngIf="retailFeature.AllowAccrualPoints">
                <app-accrual (formReady)="formInitialized($event)"></app-accrual>
              </ng-template>
              <ng-template [ngSwitchCase]="'SalesMix'">
                <app-retail-sales-mix (formReady)="formInitialized($event)" (validateUser)="validateForm($event)"></app-retail-sales-mix>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventoryPickupDelivery'">
                <app-inventory-pickup-delivery-report (formReady)="formInitialized($event)" (IsValidSelection)="customFilterBlockValidation($event)"></app-inventory-pickup-delivery-report>
              </ng-template>
              <ng-template [ngSwitchCase]="'ItemsExtract'">
                <app-retail-itemsextract (formReady)="formInitialized($event)" (IsValidSelection)="CustomFilterInputValidation($event)"></app-retail-itemsextract>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventoryDetailVendor'">
                <app-retail-vendor-inventory-detail (formReady)="formInitialized($event)" (IsValidSelection)="customFilterBlockValidation($event)"></app-retail-vendor-inventory-detail>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventorySummaryVendor'">
                <app-retail-inventory-summary-by-vendor (formReady)="formInitialized($event)" (IsValidSelection)="CustomFilterInputValidation($event)"></app-retail-inventory-summary-by-vendor>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventoryOnHand'">
                <app-inventory-on-hand (formReady)="formInitialized($event)" (IsValidSelection)="customFilterBlockValidation($event)"></app-inventory-on-hand>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventoryVariance'">
                <app-inventory-variance (formReady)="formInitialized($event)" (validateUser)="validateForm($event)"></app-inventory-variance>
              </ng-template>
              <ng-template [ngSwitchCase]="'Receiving'">
                <app-inventory-receiving (formReady)="formInitialized($event)" (validateUser)="validateForm($event)"></app-inventory-receiving>
              </ng-template>
              <ng-template [ngSwitchCase]="'PurchaseOrders'">
                <app-inventory-purchase-orders (formReady)="formInitialized($event)" (validateUser)="validateForm($event)"></app-inventory-purchase-orders>
              </ng-template>
              <ng-template [ngSwitchCase]="'TransactionExtract'">
                <app-transaction-extract (formReady)="formInitialized($event)" (IsValidSelection)="CustomFilterInputValidation($event)"></app-transaction-extract>
              </ng-template>
              <ng-template [ngSwitchCase]="'InventoryValueAndActivity'">
                <app-inventory-value-and-activity (formReady)="formInitialized($event)" (validateUser)="validateForm($event)"></app-inventory-value-and-activity>
              </ng-template>
              <p *ngSwitchDefault></p>
            </div>
            <!-- END-->

            <div *ngIf="showHourlyRate" class="mb-3">
              <label class="LW14">{{captions.IncludeHourlyRate}}</label>
              <app-retail-toggle-switch class="d-block" [formControl]="reportsinput.controls['includeHourlyRate']">
              </app-retail-toggle-switch>
            </div>

            <div *ngIf="hideCorrectedValues" class="mb-3">
              <label class="LW14">{{captions.hideCorrectedValues}}</label>
              <app-retail-toggle-switch class="d-block" [formControl]="reportsinput.controls['hideCorrectedValuesToggle']">
              </app-retail-toggle-switch>
            </div>

            <div *ngIf="includeInhouseGratuitySC" class="mb-3">
              <label class="LW14">{{captions.includeInhouseGratuitySC}}</label>
              <app-retail-toggle-switch class="d-block" [formControl]="reportsinput.controls['includeInhouseGratuitySCToggle']">
              </app-retail-toggle-switch>
            </div>
            <div *ngIf="includeInhouseGratuity" class="mb-3">
              <label class="LW14">{{captions.includeInhouseGratuity}}</label>
              <app-retail-toggle-switch class="d-block" [formControl]="reportsinput.controls['includeInhouseGratuityToggle']">
              </app-retail-toggle-switch>

            </div>
            <div *ngIf="includeInhouseSC" class="mb-3">
              <label class="LW14">{{captions.includeInhouseSC}}</label>
              <app-retail-toggle-switch class="d-block" [formControl]="reportsinput.controls['includeInhouseSCToggle']">
              </app-retail-toggle-switch>

            </div>

            <div *ngIf="includeInhouseSC && reportsinput.controls['includeInhouseSCToggle'].value" class="pl-2 mb-3">
              <label class="LW14">{{captions.ReportType}}</label>
              <mat-radio-group class="report-radio-group d-block LW14" [formControl]="reportsinput.controls['reportTypeSCSelection']">
                  <mat-radio-button *ngFor="let data of radioSelectionSCList" class="report-radio-button d-block" [value]="data.id">
                      {{data.name}}
                  </mat-radio-button>
                  </mat-radio-group>
            </div>

            <div *ngIf="includeInhouseGratuity && reportsinput.controls['includeInhouseGratuityToggle'].value" class="pl-2 mb-3">
              <label class="LW14">{{captions.ReportType}}</label>
              <mat-radio-group class="report-radio-group d-block LW14" [formControl]="reportsinput.controls['reportTypeGRSelection']">
                  <mat-radio-button *ngFor="let data of radioSelectionGRList" class="report-radio-button d-block" [value]="data.id">
                      {{data.name}}
                  </mat-radio-button>
                  </mat-radio-group>
            </div>

            <div *ngIf="includeInhouseGratuitySC && reportsinput.controls['includeInhouseGratuitySCToggle'].value" class="pl-2 mb-3">
              <label class="LW14">{{captions.ReportType}}</label>
              <mat-radio-group class="report-radio-group d-block LW14" [formControl]="reportsinput.controls['reportTypeGSCSelection']">
                  <mat-radio-button *ngFor="let data of radioSelectionGSCList" class="report-radio-button d-block" [value]="data.id">
                      {{data.name}}
                  </mat-radio-button>
                  </mat-radio-group>
            </div>

            <div *ngIf="pageBreak" class="mb-3">
              <label class="LW14">{{captions.PageBreak}}</label>
              <app-retail-toggle-switch class="d-block" [formControl]="reportsinput.controls['pageBreakToggle']">
              </app-retail-toggle-switch>

            </div>
            
            <div *ngIf="!isFromJobScheduler">
              <button mat-button class="body-bgcolor whitecolor actionButton w-100"
                [ngClass]="(disableGenerateBtn || !this.reportsinput.valid ||!validSelection || !textInputFilterValid) ? 'button_invalid' : 'button_valid'"
                (click)="generateReport($event)">{{captions.Generate}}</button>
            </div>
            <div *ngIf="isFromJobScheduler && AllowButton && this.reportsinput.controls.reportName.value">
                <button mat-button class="body-bgcolor whitecolor actionButton w-100" type="button" mat-flat-button color="primary"
                (click)="saveReportConfig()">{{captions.lbl_saveReportConfig}}</button>
            </div>
            <a  *ngIf="AllowButton" mat-button class="text-color w-100 text-uppercase LW14" (click)="handleclick($event, reportsinput.value.reportName)">{{captions.reset}}</a>
          </div>
            <!-- <div *ngIf="AllowJasperReport">
              <button type="button" style="position: absolute;bottom: 20px;width: 75%;margin-left: 10px;" mat-button class="body-bgcolor whitecolor actionButton" color="primary" (click)="loadReports()">Load Report</button>
            </div>  -->
            <div>
              <mat-error class="LW12" *ngIf="!this.reportsinput.controls['startDate'].valid && this.reportsinput.controls['startDate'].touched && !this.invalidStartDate"
                style="padding-top: 21px;padding-left: 3px;">
                {{captions.StartDateShouldNotBeGreaterThanEndDate}}
              </mat-error>
            <mat-error class="LW12" *ngIf="!this.reportsinput.controls['endDate'].valid && this.reportsinput.controls['endDate'].touched && !this.invalidEndDate"
              style="padding-top: 21px;padding-left: 3px;">
              {{captions.EndDatecannotbepriortoStartDate}}
            </mat-error>
              <mat-error class="LW12" *ngIf="!validSelection || !textInputFilterValid"
                style="padding-top: 21px;padding-left: 3px;">
                {{validSelectionErrMsg}}
              </mat-error>
            </div>
          </form>
        </ng-scrollbar>
        <button class="aside-arrow body-bgcolor whitecolor" (click)="asideArrow($event)"><i
            aria-hidden="true"  class="pointercursor icon-left-arrow"></i></button>
      </div>
      <div class="right-contentreports">  
        <ng-scrollbar >
          <div class="report-section" *ngIf="reportParams; else emptyReport">
            <app-retail-report (disableGenerateEvtEmitter)="disableGenerateButtonEvent($event)" [options]="reportParams">
            </app-retail-report>
          </div>
        </ng-scrollbar>
      </div>
      <!-- <div *ngIf="AllowJasperReport" class="right-contentreports">
        <app-jasper-report [reportDetails]="jasperReportDetails"></app-jasper-report>
      </div> -->
    </div>
  </div>
  <ng-template #emptyReport>
    <div class="report--empty no-data-img">
      <img src="./assets/images/SearchIllustration.png" alt="No Data">
      <!-- <span>{{captions.NoRecordsToDisplay}}</span> -->
    </div>
  </ng-template>
