import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'message-status',
  templateUrl: './message-status.component.html',
  styleUrls: ['./message-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MessageStatusComponent implements OnInit {


  ngOnInit() {

  }

}
