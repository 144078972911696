<section class='ar-customer-search-wrapper newVersionWrapper dialog-container ag_height--100'>
    <h1 class='ag_p--4 ag_m--0 ag_display--flex align-items-center' mat-dialog-title>{{captions.pg_title_arCustomerSearch}}
        <i class='icon-close ag_ml--auto ag_cursor--pointer' aria-hidden="true"
            (click)="closePopUp(DialogCloseEnum.Close)">&nbsp;</i>
    </h1>
    <div class='dialog-content ag_p--0 ag_m--0 ag_container--padding-lg' mat-dialog-content>
        <app-aside-filter [filterGroups]="filtergroups" class="ga-filter-aside" [config]="filterConfig" filterLimit="10"
            (filterEvent)="filterHandler($event)" (resetEvent)="resetfilter()">
        </app-aside-filter>
        <div class='customer-list-search ag_height--100 ag_pl--8'>
            <div class='ag_display--flex align-items-baseline'>
                <h4 class='ag_group--headers'>{{captions.grp_title_arCustomersList}}</h4>
                <!-- <app-simple-search class='ag_ml--auto'
                    [placeholder]="captions.lbl_searchByArAccountNumberAccountNameStatusReference"
                    (searchChange)="searchCustomer($event)"></app-simple-search> -->

                <app-ag-dropdown-search class='ag_ml--auto' [searchPlaceholder]='captions.lbl_searchPlaceholder'
                    (onDropdownChange)='searchByChange($event)' (onSearch)="searchCustomer($event)"
                    [dropdownOptions]='dropdownSearchOptions'>
                </app-ag-dropdown-search>
            </div>
            <div class="table-container">
                <app-cdkvirtual [headerOptions]="headerOptions" [tableContent]="tableContent | async"
                    [options]="tableOptions"  (rowClickEmit)='onRowClick($event)'
                    (EmittedData)='onTableAction($event)'
                    [childTemplate]="childTemplate">
                </app-cdkvirtual>
            </div>
            <ng-template #childTemplate let-element="element" let-key="key" let-index="idx">
                <ng-container [ngSwitch]="key">
                    <ng-container *ngSwitchCase="'status'">
                        <span class='ag_display--flex align-items-center'>
                            <i class='icon-Circle' [ngClass]="'status-'+element.statusId" aria-hidden="true">&nbsp;</i>
                            {{element.status}}
                        </span>
                    </ng-container>
                </ng-container>
            </ng-template>
        </div>
    </div>
    <div mat-dialog-actions class='ag_footer--actions ag_display--flex ag_pl--4'>
        <app-button [buttontype]='proceedButton' (valueChange)='onAction()'></app-button>
        <app-button [buttontype]='cancelButton' (valueChange)='onCancel()'></app-button>
    </div>
</section>