import { CommonModule } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CounterComponent } from '@app/pos/util-components/counter/counter.component';
import { DialogContainerComponent } from '@app/pos/util-components/dialog-container/dialog-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { GuestSearchGlobalComponent } from '../guest-search-global/guest-search-global.component';
import { environment } from '@environments/environment';
import { LoaderService } from '@app/core/services/loader.service';
import { UtilService } from '@app/pos/services/util.service';

@Component({
  selector: 'respos-wristband-mapping-container',
  standalone: true,
  imports: [CommonModule, FormsModule, CounterComponent, TranslateModule, GuestSearchGlobalComponent, CommonModule, DialogContainerComponent],
  templateUrl: './wristband-mapping-container.component.html',
  styleUrls: ['./wristband-mapping-container.component.scss']
})
export class WristbandMappingContainerComponent implements OnDestroy {

  dialogRef = inject(MatDialogRef);
  loaderService = inject(LoaderService);
  utilService = inject(UtilService);
  wristbandmappingurl: string = environment.BaseUrl;
  messageListner;
  static token: string = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjE1QTY0MjkzRDYzODE5QjlFQTA5MDVFQjc1QjdEOUI5NDk0NDdDM0NSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IkZhWkNrOVk0R2JucUNRWHJkYmZadVVsRWZEdyJ9.eyJuYmYiOjE3MTc2NzE0NDcsImV4cCI6MTcxNzY3NTA0NywiaXNzIjoiaHR0cHM6Ly92MS1hdXRoLXFhLnJndWVzdC5jb20vdjEvYXV0aHNlcnZpY2UiLCJhdWQiOlsiYWN0aXZpdHkiLCJndWVzdFByb2ZpbGUiLCJQSUkiLCJSZXNlcnZhdGlvbnMiLCJzZWF0IiwidHJhbnNtaXNzaW9uIl0sImNsaWVudF9pZCI6InZlcnNhcWFjbG50IiwiY2xpZW50X1NlYXRNZXJjaGFudElkIjoiMTYxMSIsImlhdCI6MTcxNzY3MTQ0Nywic2NvcGUiOlsiYWN0aXZpdHkuZnVsbCIsImd1ZXN0UHJvZmlsZS5mdWxsIiwiUElJLmZ1bGwiLCJyZXNlcnZhdGlvbnNBcGkuZnVsbCIsInNlYXQuZnVsbCIsInRyYW5zbWlzc2lvbi5mdWxsIl19.skfHuWqT6hoFApFVYdbk-8OM9LOfJRSECmZNOf2Di1YIVp1KLOPKy_-nk_Mn91Db5uF77MFmGDTK_g4dMvGsxU5q_oFpWpjrDBXjG2dc-o1AEr9JDjQaOOPkMaytTocggoBOk5L9Ssr-ZdOtcqVxZmrNe5qLE6U6UQOT98kxdkiJweqZQDEJx599-2pRP8POoV3HuMVpXXnTxkt33aSo1THO6JzQatleMZiT7AgdOKSshk_Ga86mX1F-xgwP2fPJDe7MfD3HgYXHD4ydXaAqDyxSHoN7iDjyX2g8ixYLBvEid1D0P0d9KkQfLYqR8ua4VL5xVNPVvS8MgK7ptAoLIw';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.wristbandmappingurl = data.wristbandURL;

    this.messageListner = window.addEventListener('message', (event) => {
      let _eventName = event.data?.data?.event_id;
      if(_eventName === 'iframe_loading'){
        this.loaderService.isLoading.next(true);
      }else if(_eventName === 'iframe_loaded'){
        this.loaderService.isLoading.next(false);
      }else if(_eventName === 'register_failed'){
        this.loaderService.isLoading.next(false);
        this.utilService.showError(['Wirstband register failed']);
      }else if(['register_sucess', 'register_cancelled'].includes(_eventName)){
        this.loaderService.isLoading.next(false);
        this.dialogRef.close();
      }
    })
    // this.wristbandmappingurl = `http://localhost:5001?reservationId=${data.cartBookingId}&token=${WristbandMappingContainerComponent.token}`
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.messageListner);
  }
}
