<!-- <mat-form-field [floatLabel]="floatLabel" [ngClass]="className" class="ag_time-picker date-picker-width" [formGroup]="form" [attr.automationId]="automationId">
  <input class='ag_text--uppercase' [ngxTimepicker]="timepicker" matInput [placeholder]="placeHolder"
    [required]="isTimeRequired" [formControlName]="formControlName" [readonly]="true" autocomplete="off" [min]="minTime"
    [max]="maxTime" [format]="timeformat">
  <ngx-material-timepicker-toggle matSuffix class="time-picker" [for]="timepicker">
    <i  aria-hidden="true" class="icon-clock cursor" ngxMaterialTimepickerToggleIcon></i>

  </ngx-material-timepicker-toggle>
  <ngx-material-timepicker #timepicker enableKeyboardInput="true" [cancelBtnTmpl]="cancelBtn"  [confirmBtnTmpl]="confirmBtn"
    [defaultTime]="form.get(formControlName).value" (closed)="timeChanged()"
    (opened)="timepickerOpened($event)">
  </ngx-material-timepicker>
  <ng-template #cancelBtn ><button class="ag_button--secondary" type="button" [attr.LiteralID]="'btn_cancel'">{{captions.btn_cancel}}</button>
  </ng-template>
  <ng-template #confirmBtn ><button class="ag_button--primary" type="button" [attr.LiteralID]="'btn_ok'">{{captions.btn_ok}}</button>
  </ng-template>
  <mat-error *ngIf="form.controls[formControlName].hasError('required')">{{errorMessage}}</mat-error>
  <mat-error *ngIf="form.controls[formControlName].hasError('invalid')" [attr.LiteralID]="'err_invalid'">{{captions.err_invalid}} {{placeHolder}}
  </mat-error>
  <mat-error *ngIf="form.controls[formControlName].hasError('customError')">{{customErrorMessage}}</mat-error>
</mat-form-field> -->
<mat-form-field [floatLabel]="floatLabel" [ngClass]="className" class="ag_time-picker date-picker-width"  [formGroup]="form" [attr.automationId]="automationId">
  <input matInput class="ag_text--uppercase" [disabled]="disabled" [pattern]="timeRegex" [placeholder]="placeHolder" [required]="isTimeRequired" autocomplete="off" [formControlName]="formControlName" 
    (blur)="selectedTimeFreeInputChanged()"/>
    <mat-error *ngIf="form.get(formControlName).hasError('pattern')">{{patternFormat}}</mat-error>
    <mat-error *ngIf="form.get(formControlName).hasError('required')">{{errorMessage}}</mat-error>
    <mat-error *ngIf="form.get(formControlName).hasError('invalid')" [attr.LiteralID]="'err_invalid'">{{captions.err_invalid}} {{placeHolder}}
    </mat-error>
    <mat-error *ngIf="form.get(formControlName).hasError('customError')">{{customErrorMessage}}</mat-error>
    <div class="time-picker-dial">
      <input class='ag_text--uppercase ag_display--none' [ngxTimepicker]="timepicker" matInput [placeholder]="placeHolder" [required]="isTimeRequired"
       [formControlName]="selectedTimeFreeInput" [readonly]="true" autocomplete="off" [min]="minTime" [max]="maxTime" [format]="timeformat">
      <ngx-material-timepicker-toggle matSuffix class="time-picker" [for]="timepicker" [ngClass]="[form.get(formControlName).disabled ? 'ag_section--disable' : '']">
        <i  aria-hidden="true" class="icon-clock cursor" ngxMaterialTimepickerToggleIcon></i>    
      </ngx-material-timepicker-toggle>
      <ngx-material-timepicker #timepicker enableKeyboardInput="true" [cancelBtnTmpl]="cancelBtn"  [confirmBtnTmpl]="confirmBtn" [defaultTime]="form.get(formControlName).value"
        (closed)="timeChanged()" (opened)="timepickerOpened($event)" (timeSet)="updateTime($event)">
      </ngx-material-timepicker>  
      <ng-template #cancelBtn ><button class="ag_button--secondary" type="button" [attr.LiteralID]="'btn_cancel'">{{captions.btn_cancel}}</button>
      </ng-template>
      <ng-template #confirmBtn ><button class="ag_button--primary" type="button" [attr.LiteralID]="'btn_ok'">{{captions.btn_ok}}</button>
      </ng-template>
    </div>
</mat-form-field>