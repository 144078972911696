<div class="reservation-charge">
  <div class="reservation-charge__special-meals">
    <div *ngIf="specialMealEnabled">
      <mat-form-field class="mt-2 width230 reservation-charge__meal-autocomplete">
        <input type="text" placeholder="{{'specialMealType' | translate}}" aria-label="Number" matInput
          [matAutocomplete]="auto" [readonly]="isReadOnly" [formControl]="specialMealControl">
        <mat-icon matSuffix class="search-icon icon-Group-591" *ngIf="!isEditReservation" (click)="clearFunction()">
        </mat-icon>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.Name"
            (click)="overrideValues(option,$)" title="{{option.Name}}">
            {{option.Name}}
            <span *ngIf="option.IsPrepaymentRequired" class="icon-res-payment-Paid reservation-charge__paymentIcon"></span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="reservation-charge__coverRow">
        <span class="reservation-charge__select-text" *ngIf="coverTypes?.length > 0">{{'CoverTypeText'|translate}}</span>
        <div class="coverTypeDiv" *ngFor="let coverType of coverTypes" >
          <span class="reservation-charge__name nameWidth">{{ coverType.CoverTypeName }} ({{ formatCurrency(coverType.Price) }})</span>
          <div class="reservation-charge__iconArea">
            <a class="round-icon icon-green-color decrementIcon" (click)="decreaseCoverType(coverType)"> &#8722; </a>
            <span class="reservation-charge__name">{{ coverType.Covers }}</span>
            <a class="round-icon icon-green-color incrementIcon" (click)="increaseCoverType(coverType)"> + </a>
          </div>
        </div>

        <div class="totalCountDiv" *ngIf="coverTypes?.length > 0">
          <span class="reservation-charge__name rs-pt-10 totalCountText">{{'Total Value' | translate}}:</span>
          <span class="reservation-charge__name rs-pt-10 totalCountValue">{{ formatCurrency(TotalAmount) }}</span>
        </div>
      </div>
    </div>
  </div>

</div>