import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SettingDialogPopupComponent } from '../shared/setting-dialog-popup/setting-dialog-popup.component';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import {  AlertType } from '../shared/shared.modal';
import { RetailLocalization } from '../common/localization/retail-localization';
import { GridType, ButtonType, RetailBreakPoint } from '../shared/globalsContant';
import { ItemAssignedComponent } from './item-assigned/item-assigned.component';
import { VendorSetupService } from './retail-vendor-setup.service';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import _ from 'lodash';
import { VendorType } from './retail-vendor-setup.modals';


@Component({
  selector: 'app-retail-vendor-setup',
  templateUrl: './retail-vendor-setup.component.html',
  styleUrls: ['./retail-vendor-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailVendorSetupComponent implements OnInit {
  IsViewOnly: boolean = false;
  searchText: string;
  vendorSearch: UntypedFormGroup;
  settingDialogSubscription: Subscription;
  captions: any;
  tableoptions: any;
  tabledata: any[] = [];
  originalTableData: any[] = [];
  onEditDisableFunction: boolean = true;
  isEditFlag: boolean = false;
  maxListOrder: number;
  deletedIndex: any;
  vendorTypecategories: any[] = [];
  vendorArgs: any;
  vendorTypes: VendorType[]=[];
  
  constructor(private dialog: MatDialog, private _rs: RetailSetupService, private _localization: RetailLocalization, private retailService: RetailSetupService,
    private utils: RetailUtilities, private _vendorSetupService: VendorSetupService) { }

  ngOnInit() {
    this._rs.tabLoaderEnable.next(false);
    this.captions = this._localization.captions.vendors;
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == RetailBreakPoint.VendorSetup).view;
    this.getAllvendors();
    this._vendorSetupService.getAllVendorTypes().then(x =>{
      this.vendorTypes = x.sort(t=>t.listOrder);
      this.vendorTypecategories = [{
        id: 1,
        'name': 'VendorType',
        'title': this.captions.lbl_vendorType,
        'filters': this.vendorTypes.map(c =>{
          return {
           id: c.id,
           name: c.description
          }
        }),
        'filtered': []
      }];
    });
  }

  async getAllvendors() {
    this.tabledata = await this._vendorSetupService.getAllVendors();
    this.originalTableData = _.cloneDeep(this.tabledata);
    if(this.vendorArgs){
      this.CategoryFilter(this.vendorArgs);
    }
    else{
      this.BindTableData(this.tabledata);
    }
  }

  ngOnDestroy() {
    if (this.settingDialogSubscription) {
      this.settingDialogSubscription.unsubscribe();
    }
  }

  resetValue() {
    this.searchText = "";
  }

  searchInputChange(e) {
    console.log('Search Text', this.searchText);
  }

  addVendor(e) {
    this.openDialog('Create');
  }
  editVendor(event) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.openDialog('Edit', event[0]);
    this.utils.ToggleLoader(false);
  }

  async DeleteRecords(event) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    let errorMsg = this._localization.getError(75008);
    this.deletedIndex = event[0];
    this.utils.ToggleLoader(false);
    errorMsg = this._localization.replacePlaceholders(errorMsg, ["VendorName"], [this.deletedIndex.vendorName])
    this.utils.showAlert(errorMsg, AlertType.Warning, ButtonType.YesNo, this.PopupCallback.bind(this), event[0]);
  }

  async PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == "yes") {
      await this._vendorSetupService.deleteVendor(this.deletedIndex.id);
    }
    this.getAllvendors();
  }

  openDialog(title: any, data?: any): any {
    let dialogRef: any;
    let popupConfiguration: any;
    if (title == "Edit") {
      popupConfiguration = {
        data: data,
        listOrder: data.listOrder,
        isViewOnly: this.IsViewOnly,
        vendorTypes : this.vendorTypes.filter(y=>y.isActive),
        caption: this.captions.lbl_editVendor,
        operation: "edit"
      };
    }
    else {
      let listOrderArray = this.tableoptions && this.tableoptions[0].TablebodyData && this.tableoptions[0].TablebodyData.map(e => e.listOrder);
      this.maxListOrder = listOrderArray && listOrderArray.length > 0 ? Math.max(...listOrderArray) : 0;
      popupConfiguration = {
        data: data,
        listOrder: this.maxListOrder + 1,
        vendorTypes : this.vendorTypes.filter(y=>y.isActive),
        isViewOnly: this.IsViewOnly,
        caption: this.captions.lbl_addVendor,
        operation: "Create"
      };
    }
    let afterClosed = true;
    dialogRef = this.triggerPopup(popupConfiguration, afterClosed);
    return dialogRef;
  }

  triggerPopup(popupConfiguration: any, afterClosed = true): any {
    const dialogRef = this.dialog.open(SettingDialogPopupComponent, {
      width: '1100px',
      maxWidth: "1000px;",
      height: "675px",
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: { headername: popupConfiguration.caption, closebool: true, templatename: 'VENDOR', popupConfig: popupConfiguration },
      disableClose: true
    });

    if (afterClosed) {
      this.settingDialogSubscription = dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.getAllvendors();
        }
        else{
          if(this.vendorArgs){
            this.CategoryFilter(this.vendorArgs);
          }
          else {
          this.BindTableData(this.tabledata);
          }
        }
        if (document.getElementsByClassName("rowDisabled") && document.getElementsByClassName("rowDisabled").length > 0) {
          document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
          document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
        }
        this.isEditFlag = false;
      });
    }
    return dialogRef;
  }

  BindTableData(tableData) {
    this.tableoptions = [{
      TableHdrData: this.GetHeader(),
      TablebodyData: tableData,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext: this.captions.lbl_vendorPlaceholder,
      EnableActions: true,
      SelectRows: false,
      SelectedSettingId: GridType.vendor,
      Searchable: true,
      EditMoreOption: true,
      Sortable: "listOrder",
      TableId: GridType.vendor,
      disableDelete: false,
      showToggle: false,
      IsViewOnly: this.IsViewOnly,
      disableEditButton: false,
      TableDraggable: true,
      DoneCancel: true,
      automationId: 'retailVendorSetup'
    }];
  }

  private GetHeader() {
    return [
      { "title": this.captions.lbl_vendorCode, "jsonkey": "vendorCode", "sortcolumndatatype": "string", "searchable": true, 
        "sortable": true ,"type": "clickable"},
      { "title": this.captions.lbl_vendorName, "jsonkey": "vendorName", "searchable": true, "sortable": true },
      { "title": this.captions.lbl_vendorRepresentative, "jsonkey": "vendorRepresentative", "searchable": true, "sortable": true },
      { "title": this.captions.lbl_phone, "jsonkey": "primaryphone", "searchable": true, "sortable": false },
      { "title": this.captions.lbl_noOfItemsAssgined, "jsonkey": "noOfItemsAssgined", "searchable": false, "sortable": true, 'alignType': 'right' },
      { "title": this.captions.lbl_listOrder, "jsonkey": "listOrder", "searchable": false, "sortable": true, 'alignType': 'right' },
      { "title": this.captions.lbl_active, "jsonkey": "active", "searchable": false, "sortable": false }
    ]; 
  }

  async Done(body) {
    if(this.checkForVendorType(body.vendorType)){
      await this._vendorSetupService.updateVendor(body, body.listOrder);
    }
    this.getAllvendors();
  }

  checkForVendorType(vendorType): boolean {
    let vendorTypeAvail: boolean = true;
    var vendorTypes = this.vendorTypes.filter(x => x.isActive);
    if ((vendorType && vendorTypes && vendorTypes.findIndex(x => x.id == vendorType) === -1)) {
        let errMsg:string = this.captions.err_missing_vendorType;
        this.utils.ShowErrorMessage(this._localization.captions.common.Error, errMsg);
        vendorTypeAvail = false;
    }
    return vendorTypeAvail;
  }

  Cancel(event) {
    console.log('Row Edit cancelled', event);
  }

  async dragDrop(event) {
    var currIndex = this.tableoptions[0].TablebodyData[event[0] - 1];
    var droppedIndex = this.tableoptions[0].TablebodyData[event[1] - 1].listOrder;
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    await this._vendorSetupService.updateVendor(currIndex, droppedIndex);
    this.utils.ToggleLoader(false);
    this.getAllvendors();
  }

  onLabelClick(e) {
    if (e && e.rowData && e.hdrCnt && e.hdrCnt === 'vendorCode') {
      this.itemAssignedPopup(e.rowData);
    }
  }

  itemAssignedPopup(clickedItem) {
    let popupConfiguration: any;
    const vendorItemsAssigned = clickedItem.vendorsAssignedItem ? clickedItem.vendorsAssignedItem : [];
    popupConfiguration = {
      data: vendorItemsAssigned,
      caption: this.captions.item_assigned + ' - ' + clickedItem.vendorName
    };
    return this.triggerItemAssignedPopup(popupConfiguration);
  }

  triggerItemAssignedPopup(popupConfiguration: any): any {
    const dialogRef = this.dialog.open(ItemAssignedComponent, {
      width: '992px',
      maxWidth: '1000px;',
      height: '675px',
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: { headername: popupConfiguration.caption, closebool: true, popupConfig: popupConfiguration },
      disableClose: true
    });
    return dialogRef;
  }

  CategoryFilter(args) {
    let newtableData = _.cloneDeep(this.originalTableData);
    this.vendorArgs = args;
    for (let res of args) {
      if (res.name == 'VendorType' && res.filtered.length > 0) {
        newtableData = newtableData.filter(r => res.filtered.some(a => a == r.vendorType));
      }
    }
    this.tableoptions[0].TablebodyData = newtableData;
    this.tableoptions = [...this.tableoptions];
  }


}
