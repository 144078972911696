import { Utilities } from '@app/shared/utilities/utilities';
import { ChangeAction } from '@constants/commonenums';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { ReservationDTO } from '@models/InputContact';
import { BookingContactAddonItemDTO, PartyMessageDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import _, { uniqBy } from 'lodash';
import { CacheService } from '../services/cache.service';
import { Processor } from './processor';

export class PartyContactAddonChangeProcessor implements Processor {

  private static instance: PartyContactAddonChangeProcessor;
  private constructor(public partyService: PartyService, public cs: CacheService) { }

  public static Instance(partyService: any = PartyService, cs: CacheService): PartyContactAddonChangeProcessor {
    if (!PartyContactAddonChangeProcessor.instance) {
        PartyContactAddonChangeProcessor.instance = new PartyContactAddonChangeProcessor(partyService, cs);
    }

    return PartyContactAddonChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: object, propertyId: number): void {
    let partiesList =[];
    let standBy = false;
    if (objectChange) {
      const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
      if(this.partyService.StandbyParties$.value.filter(party =>party.Id == partyId).length > 0){
        partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.StandbyParties$.value : this.cs.propertySettings.value[propertyId].StandbyParties;
        standBy = true;       
      }
      else{
        partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.Parties$.value : this.cs.propertySettings.value[propertyId].reservations;
        standBy= false;
      }
      if (objectChange.Action === ChangeAction.Created) {
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
        const party = partiesList.filter(party => party.Id == partyId);
        
        if (party && party.length) {
            if(!party[0].BookingContactAddonItems){
                party[0].BookingContactAddonItems = [];
            }
          const partyContactAddon: BookingContactAddonItemDTO = new BookingContactAddonItemDTO();
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyContactAddon).includes(property.PropertyName.replace('Internal', ''))) {
                partyContactAddon[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
          party[0].BookingContactAddonItems.push(partyContactAddon)
          party[0].BookingContactAddonItems = uniqBy(party[0].BookingContactAddonItems, 'Id');
        }
      }
   
      if (objectChange.Action === ChangeAction.Updated) {
        const updatedPartyContactAddonId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party: ReservationDTO = partiesList.find(party => party.Id == partyId);
        if (party) {
            const partyContactAddon: BookingContactAddonItemDTO = party.BookingContactAddonItems.find(addon => addon.Id == updatedPartyContactAddonId);
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyContactAddon).includes(property.PropertyName.replace('Internal', ''))) {
                partyContactAddon[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
          party.BookingContactAddonItems[party.BookingContactAddonItems.indexOf(partyContactAddon)] = partyContactAddon;
          partiesList[partiesList.indexOf(party)] = party;
          if (propertyId == Utilities.RestaurantId()) {
            if (this.partyService.selectedParty$.value && this.partyService.selectedParty$.value.Id == partyId) {
              this.partyService.selectedParty$.next(party);
            }
          }
        }
      }
      if (objectChange.Action === ChangeAction.Removed) {
        const removedPartyContactAddonId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party: ReservationDTO = partiesList.find(party => party.Id == partyId);
        // Cancelled reservation has to be removed from the list and from the screen
        if (party) {
            const partyContactAddon: BookingContactAddonItemDTO = party.BookingContactAddonItems.find(addon => addon.Id == removedPartyContactAddonId);
          if (partyContactAddon) {
            party.BookingContactAddonItems = party.BookingContactAddonItems.filter(addon => addon.Id != removedPartyContactAddonId);
          }
          partiesList[partiesList.indexOf(party)] = party;
        }
      }
    }
    partiesList = _.uniqBy(partiesList, 'Id');
    if (propertyId == Utilities.RestaurantId()) {
      if(standBy) {
        this.partyService.StandbyParties$.next(partiesList);
        this.partyService.standbyPartiesList = partiesList;
        this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      } else {
        this.partyService.Parties$.next(partiesList);
        this.partyService.partiesList = partiesList;
        this.cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    } else {
      if (standBy) {
        this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      } else {
         this.cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    }
  }
}
