<div class="specialmeal-booking-summary">
  <div class="specialmeal-booking-summary__booking-details">
    <ng-scrollbar>
      <div class="specialmeal-booking-summary__container">
        <div *ngIf="restaurantPolicyAvailable" class="specialmeal-booking-summary__policy-table">
          <label class="specialmeal-booking-summary__selected-tables seat-secondary-text"> {{
            restaurantPolicy.PreReservationMessageTitle }} </label>
          <span class="icon-exclamation" (click)="ShowRestPolicyDetails()">
            <span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
        </div>
        <div class="specialmeal-booking-summary__summary-accordion">
          <mat-accordion>
            <mat-expansion-panel [expanded]="false" class="specialmeal-booking-summary__summary-accordian--panel">
              <mat-expansion-panel-header>
                <mat-panel-title
                  class="specialmeal-booking-summary__section-title specialmeal-booking-summary__section-title--accordian">
                  {{'bookingSummary' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="specialmeal-booking-summary__content" *ngIf="reservationData">

                <div class="specialmeal-booking-summary__details">
                  <div class="item">
                    <div class="label">{{'ReservationDate' | translate}}</div>
                    <div class="value">{{reservationData.reservationDate | formatSettingsDate:
                      settings.General.DateFormat}}
                    </div>
                  </div>
                  <div class="item ">
                    <div class="label">{{'reservationPopUpTime' | translate}}</div>
                    <div class="value">{{reservationData.reservationTime | date:'shortTime'}}</div>
                  </div>
                  <div class="item">
                    <div class="label">{{'partySize' | translate}}</div>
                    <div class="value">{{reservationData.partySize}}</div>
                  </div>
                  <div class="item" *ngIf="reservationData?.specialMealName">
                    <div class="label">{{'SpecialMeal' | translate}}</div>
                    <div class="value">{{reservationData.specialMealName}}</div>
                  </div>
                  <div class="item">
                    <div class="label">{{'seatingArea' | translate}}</div>
                    <div class="value">{{reservationData.seatingArea}}</div>
                  </div>
                  <div class="item">
                    <div class="label">{{'seatingType' | translate}}</div>
                    <div class="value">{{reservationData.seatingType}}</div>
                  </div>

                  <div class="item">
                    <div class="label">{{'guestBookTableText' | translate}}</div>
                    <div class="value">{{reservationData.tables}}</div>
                  </div>
                  <div class="item">
                    <div class="label">{{'partyStatus' | translate}}</div>
                    <div class="value">{{reservationData.partyStatus}}</div>
                  </div>
                  <div class="item">
                    <div class="label">{{'hostText' | translate}}</div>
                    <div class="value">{{reservationData.host}}</div>
                  </div>
                  <div class="item">
                    <div class="label">{{'pager' | translate}}</div>
                    <div class="value">{{reservationData.pager}}</div>
                  </div>

                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="specialmeal-booking-summary__summary-accordion"
          *ngIf="ratePlanDetails && ratePlanDetails.total > 0">
          <mat-accordion class="p-10">
            <mat-expansion-panel [expanded]="true" class="specialmeal-booking-summary__summary-accordian--panel">
              <mat-expansion-panel-header>
                <mat-panel-title
                  class="specialmeal-booking-summary__section-title specialmeal-booking-summary__section-title--accordian">
                  {{'reservationSummary' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="rate-summary">
                <div class="summary-data-header">
                  <div class="data-labels">{{'Description' | translate}}</div>
                  <div class="data-labels">{{'Quantity' | translate}}</div>
                  <div class="data-labels">{{'price' | translate}}</div>
                  <div class="data-labels">{{'Total'}}</div>
                </div>
                <div class="summary-price">
                  <div class="price-item" *ngIf="ratePlanDetails.showSpecialMeal && ratePlanDetails.SpecialMealAmount > 0 ">
                    <div class="item-container">
                      <div class="data-labels">{{ratePlanDetails.specialMealName}}</div>
                      <div class="data-labels">{{ratePlanDetails.size}}</div>
                      <div class="data-labels">{{operationCurrency}} {{(ratePlanDetails.SpecialMealAmount /
                        ratePlanDetails.size) | currencyDecimalFormatter}}
                      </div>
                      <div class="data-labels">{{operationCurrency}} {{ratePlanDetails.SpecialMealAmount |
                        currencyDecimalFormatter}}
                      </div>
                    </div>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="ratePlanVariantRef ; context:{variant:ratePlanDetails.tableVariant , keyName:'tablesMenuText'} "></ng-container>

                  <ng-container
                    *ngTemplateOutlet="ratePlanVariantRef ; context:{variant:ratePlanDetails.guestTypeVariant , keyName:'CoverTypes_specialmeal'} "></ng-container>
                  <ng-container
                    *ngTemplateOutlet="ratePlanVariantRef ; context:{variant:ratePlanDetails.addonVariant , keyName:'addons'} "></ng-container>

                </div>
                <div class="summary-total">
                  <div class="tax-section">
                    <label>{{'serviceChargesWithPlural' | translate }}</label><label>{{operationCurrency}}
                      {{ratePlanDetails.serviceCharge | currencyDecimalFormatter}}</label>
                  </div>
                  <div class="tax-section">
                    <label>{{'serviceChargesTaxWithPlural' | translate }}</label><label>{{operationCurrency}}
                      {{ratePlanDetails.taxOnServiceCharge | currencyDecimalFormatter}}</label>
                  </div>
                  <div class="tax-section">
                    <label>{{'tax' | translate }}</label><label>{{operationCurrency}} {{ratePlanDetails.tax |
                      currencyDecimalFormatter}}</label>
                  </div>
                  <div class="tax-section grant-total" *ngIf="ratePlanDetails.total">
                    <label>{{'findTotal' | translate | uppercase}}</label><label>{{operationCurrency}}
                      {{ratePlanDetails.total
                      | currencyDecimalFormatter}}</label>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div *ngIf="ratePlanRequest" class="mt-3 specialmeal-booking-summary__rate-plan-summary">
        <app-rate-plan [bookingData]="ratePlanRequest" [invokeRatePlan]="invokeRatePlan.asObservable()" [enableEdit]="false"></app-rate-plan>
        <div class="apply-promocode mt-4" *ngIf="partyService.ratePlanObject && this.partyService.ratePlanObject.RatePlan?.Total > 0">
          <app-apply-promo-code [promoCodeEligibleProperty]="promoCodeEligibleProperty" [isDining]="true" (promoApplied)="promoCodeApplied($event)"></app-apply-promo-code>
        </div>
      </div>
        <div class="specialmeal-booking-summary__party-notes mt-3">
          <dynamic-form [config]="config" #form="dynamicForm"> </dynamic-form>
        </div>
      </div>
    </ng-scrollbar>
  </div>
  <div class="specialmeal-booking-summary__guest-details">
    <app-guest-selection [selectedIndex]="1"></app-guest-selection>
  </div>
</div>


<ng-template #ratePlanVariantRef let-variant="variant" let-keyName="keyName">
  <div class="price-item" *ngIf="variant?.length">
    <div class="item-title">
      {{keyName | translate}}
    </div>
    <div class="item-container" *ngFor="let item of variant">
      <div class="data-labels">{{item.Name}}</div>
      <div class="data-labels">{{item.Count}}</div>
      <div class="data-labels"> {{operationCurrency}} {{item.OriginalVariant | currencyDecimalFormatter}}
      </div>
      <div class="data-labels">{{operationCurrency}} {{item.TotalVariant | currencyDecimalFormatter}}
      </div>
    </div>
  </div>
</ng-template>