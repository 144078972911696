<div class="align-middle text-align-center room-type-wrapper">
    <div *ngFor="let roomType of roomTypes;let i=index" class="room-type">
        <div class="roomType-inner cursor-pointer" (click)="selectroomTypes($event,i)"
            [ngClass]="roomType.selected ? 'room-type-selected':''">
            <div class="code" [matTooltip]="roomType.code">
                {{roomType.code}}
            </div>
            <div class="ag_pt--2 desc" matTooltip="{{roomType.description}}">
                {{roomType.description}}
            </div>
        </div>
        <div class="selected-icon">
            <i  [attr.automationId]="'Lbl_roomType_roomTypes{{i}}'" aria-hidden="true" [ngClass]="roomType.selected ? 'selected' : ''" class="icon-confirmed"
                (click)="selectroomTypes($event,i)"></i>
        </div>
    </div>
</div>