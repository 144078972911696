import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateRange, MatRangeDateSelectionModel, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { LocaleService } from '@app/shared/services/locale.service';

@Component({
  selector: 'app-date-selection-with-range',
  templateUrl: './date-selection-with-range.component.html',
  styleUrls: ['./date-selection-with-range.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel,
  ],
})
export class DateSelectionWithRangeComponent implements OnInit {
  @Output() dateRangesChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() selected;
 ngOnInit(): void {
  this.dateAdapter.setLocale(this.ls.defaultLocale())
  }

  dateRange: DateRange<Date>;

  constructor(
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,private dateAdapter:DateAdapter<any>, private ls:LocaleService,
  ) {
    
      }


  ngOnChanges(){
    if(this.selected)
    this.dateRange = new DateRange(new Date(this.selected.begin), new Date(this.selected.end));

  }

  rangeChanged(selectedDate: Date) {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        selectedDate,
        selection
      );

    this.selectionModel.updateSelection(newSelection, this);
    this.dateRange = new DateRange<Date>(newSelection.start, newSelection.end);
      this.dateRangesChange.emit({ 'begin': newSelection.start, 'end': newSelection.end })
    // if (this.selectionModel.isComplete()) {
    //   console.log("new selection", newSelection);
    // }
  }

}
