<section class='create-settings-letter-wrapper ag_height--100'>

  <div class='form-content'>
    <app-warning-bar [message]="message" *ngIf="isCopy" (closeWarning)="hideWarning()" [showClose]="true">
    </app-warning-bar>
    <form [formGroup]='letterForm' autocomplete="off" DetectFormValueChange [changeInputValues]="changeInputValues"
      (formStatusChange)="formStatusChangeEvent($event)">
      <div class='ag_form_wrapper--md'>
        <div class="ag_display--flex">
          <ag-textbox class="ag_form-control--xs" [config]='codeInputs'></ag-textbox>
          <ag-textbox class="ag_form-control--xs" [config]='nameInputs'></ag-textbox>
        </div>
        <app-ag-incremental [inputs]='listOrderInputs'></app-ag-incremental>
        <div class="ag_display--flex">          
          <div class="ag_display--block ag_form-control--xs">
            <label class="LW14 NC-w40" [attr.LiteralID]="'active'" >{{captions.chk_txt_active}}</label>
            <ui-switch class="LW14 switch-toggle ag_display--block" [disabled]="disableToggle"  size="small"
             checkedLabel={{captions.Yes}} uncheckedLabel={{captions.No}}  formControlName="active"
             ></ui-switch>
          </div>
          <div class="ag_display--block ag_form-control--xs">
            <label class="LW14 NC-w40" [attr.LiteralID]="'default'" >{{captions.chk_txt_setDefaults}}</label>
            <ui-switch class="LW14 switch-toggle ag_display--block" [disabled]="disableToggle"  size="small"
             checkedLabel={{captions.Yes}} uncheckedLabel={{captions.No}}  formControlName="default"
             ></ui-switch>
          </div>
        </div>      
      </div>
      <div class='editor-container'>
        <label class='ag_form--label'>{{createInputs.editorLabel}}</label>
        <app-editor class="editor-wrapper" [input]="editorInput" [templateTags]='templateTags'>
        </app-editor>
      </div>
    </form>
  </div>
  <div class="ag_footer--actions footer">
    <app-button [buttontype]="actionButton" (valueChange)='onAction($event)'></app-button>
    <app-button [buttontype]="cancelButton" class="ag_ml--2" (valueChange)='onCancel($event)'></app-button>
  </div>
</section>