<form id="packaged-items" autocomplete="off" name="PackagedItems" class="h-100 w-100">
	<div class="packagedItem-content d-flex w-100">
		<div
			[ngClass]="this.SelectedData.length == 0 && this.selectedPackageDetails.length == 0  ? 'w-100':'PI_groupItemGrid' ">
				<div [ngClass]="isEdit && IsViewOnly ? 'disableItemSetup' : ''" (click)="stopdefaultClick($event)" (keydown)="stopdefaultClick($event)" class="w-100 h-100">
				<!-- Left pane content -->
				<div class="w-100 h-100">
					<div class="padding20" *ngIf="selectedOutletMgrArr.length > 0">
						<div class="intro-label textellipsis">
							<span>
								<i  aria-hidden="true" class="icon-information"></i>
								<label class="LW16 ml-2 mr-2">{{captions.SelectedOutlets}}</label>
								<label [popover]="myPopover" popoverPlacement="bottom" [popoverOnHover]="false"
									[popoverCloseOnMouseOutside]="true" [matTooltip]="popTemplate"
									[ngClass]="{'outlet': selectedOutletMgrArr.length > 1}" class="LW16"
									*ngFor="let outlet of selectedOutletMgrArr">{{outlet | getoutletname}}
								</label>
							</span>
						</div>
					</div>
					<div>
						<form class="h-100" autocomplete="off" name="General" [formGroup]="packagedItemsFormGrp">
							<div class="padding20 inputPadding">
								<mat-form-field class="pt-4" [floatLabel]="floatLabel">
									<mat-chip-list #chipList>
										<mat-chip class="body-bgcolor whitecolor" *ngFor="let package of packageItems"
											[selectable]="selectable" [removable]="removable"
											(removed)="remove(package)">
											{{package}}
											<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
										</mat-chip>
										<input [attr.automationId] = "'Srch_packagedItems_SearchbyItemNumberDescriptionBarcodeScannerCodeSupplier'" #packageInput
											[placeholder]="captions.SearchbyItemNumberDescriptionBarcodeScannerCodeSupplier"
											class="searchbox-width" [matChipInputFor]="chipList"
											[matAutocomplete]="auto"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
											[matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)"
											[(ngModel)]="searchText" (ngModelChange)="searchPackages()"
											[ngModelOptions]="{standalone: true}" (mousedown)="clearInput()">
										<i [attr.automationId]="'Icn_packagedItems_iconCancel'" aria-hidden="true"  class="icon-Cancel cursor" *ngIf="searchText.length > 0"
											(click)="deleteText()"></i>
									</mat-chip-list>
									<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
										<mat-option class="auto-complete client-search multi-select"
											*ngFor="let item of packageSearchArray" [value]="item">
											{{item}}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
								<button [attr.automationId]="'Btn_packagedItems_searchPI'" mat-raised-button class="body-bordercolor PI_searchbutton"
									(click)="getSearchResult()" *ngIf="!AdvanceSearchEnabled">
									<i  aria-hidden="true" class='icon-Search' matTooltip="{{this.captions.Search}}"></i>
								</button>
								<button [attr.automationId]="'Btn_packagedItems_advancedSearch'" mat-raised-button class="body-bordercolor PI_searchbutton"
									(click)="OpenAdvaceSearch()" *ngIf="!AdvanceSearchEnabled">
									<i  aria-hidden="true" class='icon-advance-search text-color'
										matTooltip="{{this.captions.AdvancedSearch}}"></i>
								</button>
							</div>

							<div *ngIf="AdvanceSearchEnabled" class="PI_advanceSearchsec padding20"
								[ngClass]="this.SelectedData.length == 0 && this.selectedPackageDetails.length == 0  ? 'w-100':'advSearch'">
								<label class="d-block">{{captions.AdvancedSearch}}</label>
								<i  [attr.automationId]="'Icn_packagedItems_closeAdvanceSearch'" aria-hidden="true" id="close" class="material-icons float-right position-relative cursor close-button"
									(click)="closeAdvaceSearch()">close</i>
								<mat-form-field class="LW14 width_90 pr-3" [floatLabel]="floatLabel">
									<mat-select [attr.automationId]="'Dd_packagedItems_category'" [placeholder]="captions.Category" formControlName="PI_Category1"
										name="sub1" (selectionChange)="OnCategoryChange($event)">
										<mat-option [value]="cat1" *ngFor="let cat1 of categories">{{cat1.name}}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field class="LWL14 width_90 pr-3" [floatLabel]="floatLabel">
									<mat-select [attr.automationId]="'Dd_packagedItems_subCategory1'" [placeholder]="captions.Subcategory1" name="sub2"
										formControlName="PI_Category2" (selectionChange)="OnSubCategory1Change($event)">
										<mat-option [value]="cat1" *ngFor="let cat1 of subCategoryOne">{{cat1.name}}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field class="LWL14 width_90 pr-3" [floatLabel]="floatLabel">
									<mat-select [attr.automationId]="'Dd_packagedItems_subCategory2'" [placeholder]="captions.Subcategory2" name="sub3"
										formControlName="PI_Category3" (selectionChange)="OnSubCategory2Change($event)">
										<mat-option [value]="cat1" *ngFor="let cat1 of subCategoryTwo">{{cat1.name}}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field class="LWL14 width_90 pr-3" [floatLabel]="floatLabel">
									<mat-select [attr.automationId]="'Dd_packagedItems_subCategory3'" [placeholder]="captions.Subcategory3" name="sub4"
										formControlName="PI_Category4" (selectionChange)="OnSubCategory3Change($event)">
										<mat-option [value]="cat1" *ngFor="let cat1 of subCategoryThree">{{cat1.name}}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field class="LWL14 width_90 pr-3" [floatLabel]="floatLabel">
									<mat-select [attr.automationId]="'Dd_packagedItems_subCategory4'" [placeholder]="captions.Subcategory4" name="sub5"
										formControlName="PI_Category5" (selectionChange)="OnSubCategory4Change($event)">
										<mat-option [value]="cat1" *ngFor="let cat1 of subCategoryFour">{{cat1.name}}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field class="LWL14 width_90 pr-3" [floatLabel]="floatLabel">
									<mat-select [attr.automationId]="'Dd_packagedItems_subCategory5'" [placeholder]="captions.Subcategory5" name="sub6"
										formControlName="PI_Category6">
										<mat-option [value]="cat1" *ngFor="let cat1 of subCategoryFive">{{cat1.name}}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<button mat-raised-button class="body-bordercolor PI_searchbutton">
									<i [attr.automationId]="'Icn_packagedItems_itemSearch'"aria-hidden="true"  [ngClass]="'icon-Search'" (click)="getSearchResult()"></i>
								</button>
							</div>
						</form>
					</div>
					<div class="PI_GridHeight padding20 w-100">
						<div class="h-100 tableBorder grid-table">
							<virtual-scroller #scroll [items]="filteredData"
								style="height: calc(100% - 5px)" (vsEnd)="scrollEndHandler($event)">
								<table aria-describedby="" class="table tbl-cellspacing">
									<thead #header>
										<th scope="col" class=" table-checkbox">
											<div class="th-inner">
												<mat-checkbox [attr.automationId]="'Chk_packagedItems_checkAll'" (change)="RowSelect($event, filteredData, 'All')" 
													[(ngModel)]="IsCheckAll" name="allCheckbox" [disabled]="true || IsViewOnly">
												</mat-checkbox>
											</div>
										</th>
										<th scope="col" *ngFor="let HDR of tableHDRdata" class="textellipsis">{{HDR}}</th>
									</thead>
									<tbody  #container>
										<tr *ngFor="let details of scroll.viewPortItems;let i=index">
											<td class=" table-checkbox">
												<mat-checkbox [attr.automationId]="'Chk_packagedItems_details'" (change)="RowSelect($event, details, 'Single')" class="suppress-z position-absolute"
													[checked]="details.checked" name="singleCheckbox"
													[disabled]="IsViewOnly"></mat-checkbox>
											</td>
											<td>{{details.itemNumber}}</td>
											<td>{{details.itemDescription}}</td>
											<td>{{details.salesPrice | Currency : false}}</td>


											<td>{{details.categoryName}}</td>
											<td>{{details.subCategory1Name}}</td>
											<td>{{details.subCategory2Name}}</td>
											<td>{{details.subCategory3Name}}</td>
											<td>{{details.subCategory4Name}}</td>
											<td>{{details.subCategory5Name}}</td>
										</tr>
									</tbody>
								</table>
								</virtual-scroller> 
						</div>
						<div class="res-progress-bar" *ngIf="isLoading">
							<mat-progress-bar  mode="indeterminate"></mat-progress-bar>
						  </div>
					</div>
				</div>
				</div> 
		</div>
		<div
			[ngClass]="this.SelectedData.length == 0 && this.selectedPackageDetails.length == 0 ? 'd-none':'PI_groupItem'">
			<!-- Right pane Content -->
			<div class="h-100">
				<div class="d-flex">
					<div class="d-flex selectedPackages">
						<label class="text_capitalize LW14">{{captions.GroupedItems}}</label>
						<div class="packageCount body-bgcolor whitecolor d-flex text-center">
							<span
								class="m-auto">{{this.SelectedData.length + this.selectedPackageDetails.length}}</span>
						</div>
					</div>
					<div class="itemNavigation" *ngIf="this.SelectedData.length > 0">
						<span
							class="selected-data-count">({{ (displayPIcount < this.SelectedData.length) ? displayPIcount : this.SelectedData.length  }}/{{this.SelectedData.length}})</span>
						<button mat-button [disabled]="displayPIcount == 1"
							[ngClass]="displayPIcount == 1 ? 'button_invalid': 'button_valid'">
							<i [attr.automationId]="'Icn_packagedItems_PICountPrevData'" class="icon-left-arrow cursor"
								(click)="scrollRef.scrollYTo(392*((this.displayPIcount-1)-1));prevData()"></i>
						</button>
						<button mat-button [disabled]="displayPIcount == this.SelectedData.length"
							[ngClass]="displayPIcount == this.SelectedData.length ? 'button_invalid' : 'button_valid'">
							<i [attr.automationId]="'Icn_packagedItems_PICountNextData'" class="icon-right-arrow cursor"
								(click)="scrollRef.scrollYTo(392*((this.displayPIcount-1)+1));NextData()"></i>
						</button>
					</div>
				</div>
				<div class="packageList">
					<ng-scrollbar   #scrollRef>
						<div [ngClass]="isEdit && IsViewOnly ? 'disableItemSetup' : ''" (click)="stopdefaultClick($event)" (keydown)="stopdefaultClick($event)">
						<div class="editPackagedItems" *ngFor="let packageDetails of this.SelectedData;let i=index;"
							id="scroll_{{i + 1}}">
							<div class="titleColor textellipsis" [title]="packageDetails.itemDescription">
								{{packageDetails.itemNumber}} - {{packageDetails.itemDescription}}</div>
							<div class="packageContent">
								<div class="pt-3 datePicker">
									<form class="pl-2">
										<div class="LW14 d-inline-block">
											<mat-form-field class="LW14 d-inline-block date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
												<mat-label>{{captions.StartDate}}</mat-label>
												<input [attr.automationId]="'Txt_packagedItems_startDatePicker'" #startDatePicker="ngModel" matInput dateRestricter [matDatepicker]="picker"
													[placeholder]="placeholderFormat" [min]="minFromDate"
													 (ngModelChange)="fromDateChange($event, packageDetails)"
													name="startDate" [(ngModel)]="packageDetails.startDate">
                        <mat-datepicker #picker></mat-datepicker>
                        <i [attr.automationId]="'Icn_packagedItems_startDatePicker'" matSuffix class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar"
												(click)="picker.open()"></i>
												<mat-error *ngIf="startDatePicker.hasError('matDatepickerParse')">{{captionsCommon.dateFormat}}</mat-error>   
												<mat-error *ngIf="startDatePicker.hasError('required') && !startDatePicker.hasError('matDatepickerParse')">{{captionsCommon.Missing}} {{captions.StartDate}}</mat-error>   
												<mat-error *ngIf="startDatePicker.hasError('matDatepickerMin')">{{captionsCommon.minimum}} {{captions.StartDate}}</mat-error>
												<mat-error *ngIf="startDatePicker.hasError('matDatepickerMax')">{{captionsCommon.maximum}} {{captions.StartDate}}</mat-error>
											</mat-form-field>

										</div>
										<div class="LW14 d-inline-block">
											<mat-form-field class="LW14 d-inline-block date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
												<mat-label>{{captions.EndDate}}</mat-label>
												<input [attr.automationId]="'Txt_packagedItems_EndDatePicker'" #endDatePicker="ngModel" matInput dateRestricter [matDatepicker]="picker1"
													[placeholder]="placeholderFormat"
													[min]="returnCorrectDate(packageDetails.startDate,minFromDate)"
													 name="EndDate"
													[(ngModel)]="packageDetails.endDate">
                        <mat-datepicker #picker1></mat-datepicker>
                        <i [attr.automationId]="'Icn_packagedItems_endDatePicker'" matSuffix class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar"
												(click)="picker1.open()"></i>
												<mat-error *ngIf="endDatePicker.hasError('matDatepickerParse')">{{captionsCommon.dateFormat}}</mat-error>   
												<mat-error *ngIf="endDatePicker.hasError('required') && !endDatePicker.hasError('matDatepickerParse')">{{captionsCommon.Missing}} {{captions.EndDate}}</mat-error>   
												<mat-error *ngIf="endDatePicker.hasError('matDatepickerMin')">{{captionsCommon.minimum}} {{captions.EndDate}}</mat-error>
												<mat-error *ngIf="endDatePicker.hasError('matDatepickerMax')">{{captionsCommon.maximum}} {{captions.EndDate}}</mat-error>
											</mat-form-field>

										</div>
										<div>
											<mat-form-field [floatLabel]="floatLabel">
												<input [attr.automationId]="'Txt_packagedItems_salesPrice'" type="text" matInput RetailCurrencyFormatter
													[placeholder]="captions.Price +' (' +localization.currencySymbol+')'"
													name="price"
													[(ngModel)]="packageDetails['salesPrice']">
											</mat-form-field>
										</div>
										<div>
											<mat-form-field [floatLabel]="floatLabel">
												<input [attr.automationId]="'Txt_packagedItems_memberPrice'" type="text" matInput RetailCurrencyFormatter
													[placeholder]="captions.MemberPrice +' (' +localization.currencySymbol+')'"
													name="memPrice"
													[(ngModel)]="packageDetails['memberPrice']">
											</mat-form-field>
										</div>
									</form>
								</div>
							</div>
							<div class="packageActions">
								<div class="float-right d-flex">
									<div class="cancel">
										<span class="cursor LW14" (click)="cancelPackage(packageDetails)">
											<i [attr.automationId]="'Icn_packagedItems_cancel'"
												class="icon-close"></i>{{this.localization.captions.common.Cancel}}</span>
									</div>
									<div class="done">
										<span class="cursor LW14 text-color" (click)="updatePackge(packageDetails)">
											<i [attr.automationId]="'Icn_packagedItems_done'"
												class="icon-done text-color"></i>{{this.localization.captions.common.Done}}</span>
									</div>
								</div>
							</div>
						</div>
						<div *ngFor="let packDetails of selectedPackageDetails; let index = index" class="memberDetails"
							[ngClass]="IsViewOnly ? 'button_invalid' : ''">
							<div *ngIf="packDetails.isDone == true" class="w-100">
								<div class="w-100 d-flex packageItem">
									<div class="LW12 packWidth">
										<span class="item">
											#{{packDetails.itemNumber}}
										</span>
									</div>
									<div class="options text-align-right">
										<i [attr.automationId]="'Icn_packagedItems_edit'" class="icon-Edit cursor" (click)="editPackage(packDetails, index)"></i>
										<i [attr.automationId]="'Icn_packagedItems_delete'" class="icon-Delete cursor" (click)="deletePackage(packDetails, index)"></i>
									</div>
								</div>
								<div class="w-100 d-flex packageItem">
									<div class="LW12 textellipsis packWidth" [title]="packDetails.itemDescription">
										{{packDetails.itemDescription}}</div>
									<div class="LWB12 priceDetails float-right blckClr">
										<div class=" float-right">
											{{packDetails.salesPrice ? packDetails.salesPrice : 0}}</div>
									</div>
								</div>
								<div class="w-100 d-flex packageItem">
									<div class="LW12 packWidth">{{packDetails.startDate | localizeDate}} -
										{{packDetails.endDate | localizeDate}}</div>
									<div class="memberPrice">
										<div class="LW12 float-right">
											<div class=" float-right"><i
													class="icon-client pr-1"></i>{{packDetails.memberPrice ? packDetails.memberPrice : 0}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						</div>
					</ng-scrollbar>
				</div>
			</div>
		</div>
	</div>
	<mat-dialog-actions class="float-right actionButtons pr-4">
		<button [attr.automationId]="'Btn_packagedItems_cancel'" class="" mat-button type="button"
			(click)="close()">{{this.localization.captions.common.CANCEL}}</button>
		<button [attr.automationId]="'Btn_packagedItems_previous'" mat-button matStepperPrevious type="button"
			class="LW14 text_capitalize body-bordercolor">{{this.localization.captions.common.PREVIOUS}}</button>
		<button [attr.automationId]="'Btn_packagedItems_next'" mat-button matStepperNext type="button" color="primary"
			[ngClass]="true ? 'button_valid' : ' button_invalid'" *ngIf="_retailService.GeneralFormGrp.controls['commissionableitem'].value || (functionalities['ShowRetailSetupRoomTypesAssignmentTab'] || false)"
			class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.NEXT}}</button>

			<button [attr.automationId]="'Btn_packagedItems_save'" mat-button type="button" *ngIf="!isEdit && !(_retailService.GeneralFormGrp.controls['commissionableitem'].value || (functionalities['ShowRetailSetupRoomTypesAssignmentTab'] || false))" (click)="save()" color="primary"
            [ngClass]="packagedItemsFormGrp.valid ? 'button_valid' : ' button_invalid'"
            class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.SAVE}}</button>
        <button [attr.automationId]="'Btn_packagedItems_update'" mat-button type="button" *ngIf="isEdit && !(_retailService.GeneralFormGrp.controls['commissionableitem'].value || (functionalities['ShowRetailSetupRoomTypesAssignmentTab'] || false))" (click)="save()" color="primary"
            [ngClass]="(packagedItemsFormGrp.valid && (parentForm.get('generalFormGroup').dirty || parentForm.get('otherInfoFormGroup').dirty || parentForm.get('inventoryFormGroup')?.dirty
			|| parentForm.get('multipackFormGroup')?.dirty || parentForm.get('packagedItemFormGroup')?.dirty)) ? 'button_valid' : ' button_invalid'"
            class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.Update}}</button>
	</mat-dialog-actions>
</form>
