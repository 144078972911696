<div class="dashboard-accordian-section"
  *ngIf="(this._as.selectedMenuId != Menu.Servers && this._as.selectedMenuId != Menu.Tables) ">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header class="seat-border-color">
        <mat-panel-title>
          <span class="seat-section-header">{{header | translate}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- <ng-scrollbar> -->
        <div *ngIf="dashboardStatus" class="dashboard-accordian-section__status-container">
          <div *ngFor="let status of dashboardStatus;let i=index">
            <div class="dashboard-accordian-section__status-row">
              <div *ngIf="status.Date"
                class="dashboard-accordian-section__status-box seat-border-color seat-secondary-bg-color">
                <div class="dashboard-accordian-section__day-view seat-secondary-text">{{status.Date.day}}</div>
                <div *ngIf="status.Date.day != '-'" class="dashboard-accordian-section__date-view seat-secondary-text seat-border-color seat-secondary-bg-color">{{status.Date.year}}</div>
              </div>
              <div *ngIf="!status.Date"
                class="dashboard-accordian-section__status-box seat-border-color seat-secondary-bg-color">
                <span class="dashboard-accordian-section__count seat-secondary-text">{{status.Count}}</span>
              </div>
              <label class="seat-tertiary-text">{{status.Name | translate}}</label>
            </div>
          </div>
        </div>
        <div *ngIf="dashboardStatus?.length==0" class="dashboard-accordian-section__no-status seat-no-content-found">
          {{'noStatusFound' | translate}}
        </div>
      <!-- </ng-scrollbar> -->
    </mat-expansion-panel>
  </mat-accordion>
</div>