import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { BaseResponse } from '../../shared/business/shared.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { ConfigureDateTimeComponent } from '../configure-date-time/configure-date-time.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-retail-discount-types',
  templateUrl: './retail-discount-types.component.html',
  styleUrls: ['./retail-discount-types.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailDiscountTypesComponent implements OnInit {
  FormGrp: UntypedFormGroup;
  discountTypes: any;
  tableoptions: any[];
  captions: any;
  currIndex: any;
  IsViewOnly: boolean;
  maxListOrder: number;
  type: any;
  onEditDisableFunction: boolean = true;
  updatedData : any;
  constructor(private dialog: MatDialog,private Form: UntypedFormBuilder, private http: HttpServiceCall, public localization: RetailLocalization, private utils: RetailUtilities,
    private retailService: RetailSetupService) {
  }

  ngOnInit() {
    this.FormGrp = this.Form.group({
      TerminalID: []
    });
    this.captions = this.localization.captions.retailsetup;
    this.InvokeServiceCall("GetDiscountTypes", myGlobals.Host.retailManagement, HttpMethod.Get);
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.DiscountType).view;
  }

  checkForMinValidation(discountType): boolean {
    let valid: boolean = true;
    if ((discountType.value.controls.setupCodeName.value.trim().length < 3)) {
        let errMsg:string = this.localization.getError(100713);
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
        valid = false;
    }
    return valid;
}

  addDiscountType(data: any) {
    if (data.type.toLowerCase() == this.localization.captions.setting.Add.toLowerCase() && this.checkForMinValidation(data)) {
      const discountTypeObj = {
        IsActive: true,
        Type: data.value.controls.setupCodeName.value.trim(),
        ListOrder: Number(this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1] ? this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1].listOrder : 0) + 1,
      };
      this.utils.ToggleLoader(true,this.captions.lbl_processing);
      this.InvokeServiceCall("CreateDiscountType", myGlobals.Host.retailManagement, HttpMethod.Post, '', discountTypeObj);
    }
    else if (data.type.toLowerCase() == this.localization.captions.setting.update.toLowerCase() && this.checkForMinValidation(data)) {
      const discountTypeObj = {
        id: this.tableoptions[0].TablebodyData[this.currIndex].id,
        IsActive: data.value.controls.activetoggle.value,
        Type: data.value.controls.setupCodeName.value.trim(),
        ListOrder: this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
      };
      this.utils.ToggleLoader(true,this.captions.lbl_processing);
      this.InvokeServiceCall("UpdateDiscountType", myGlobals.Host.retailManagement, HttpMethod.Put, { id: discountTypeObj.id }, discountTypeObj);
    }
    return true;
  }

  Done(event: any) {
    const discountTypeObj = {
      id: event.id,
      IsActive: event.active,
      Type: event.discountType,
      ListOrder: event.listOrder,
    };

    this.updatedData = event;
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.InvokeServiceCall("UpdateDiscountType", myGlobals.Host.retailManagement, HttpMethod.Put, { id: discountTypeObj.id }, discountTypeObj);
  }

  EditRecords(discountData) {
    // this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == data[0].id);
    this.dialog.open(ConfigureDateTimeComponent, {
      width: '100%',
      height: '100%',
      maxWidth :'100%',
      hasBackdrop: true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.localization.captions.hdr_EditDiscountType,
        data: [discountData[0]],
        mode: 'edit',
      }
    }).afterClosed().subscribe(result => {
      this.InvokeServiceCall("GetDiscountTypes", myGlobals.Host.retailManagement, HttpMethod.Get);
    });
  }

  DeleteRecords(event: any) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.InvokeServiceCall("DeleteDiscountType", myGlobals.Host.retailManagement, HttpMethod.Delete, { id: event[0].id });
  }

  dragDrop(e: any) {
    let params = { 'fromorder': e[0], 'toorder': e[1] }
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.InvokeServiceCall("DiscountTypeDragDrop", myGlobals.Host.retailManagement, HttpMethod.Put, params);
 
  }

  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateDiscountType" || "GetDiscountTypes" || "DeleteDiscountType" || "UpdateDiscountType" || "DiscountTypeDragDrop") {
      let response = <any>result.result;
      this.discountTypes = [];
      response.forEach(element => {
        let discountType: any = {
          id: element.id,
          discountType: element.type,
          active: element.isActive,
          listOrder: element.listOrder,
          enableDiscountRules: element.enableDiscountRules
        }
        this.discountTypes.push(discountType);
      });

      this.BindToGrid();
      this.utils.ToggleLoader(false);
    }
  }

  private BindToGrid() {
    this.tableoptions = [
      {
        TableHdrData: [{ "title": this.captions.DiscountType, "jsonkey": "discountType", "sortable": true },
        { "title": this.captions.Active, "jsonkey": "active", "sortable": true },
        { "title": this.captions.ListOrder, "jsonkey": "listOrder", "alignType": "right", "sortable": true, "searchable": false }],
        TablebodyData: this.discountTypes,
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.srch_by_discountType,
        EnableActions: true,
        SelectRows: true,
        IsCommission: true,
        Searchable: true,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.discountTypes,
        Sortable: 'listOrder',
        TableId: myGlobals.GridType.discountTypes,
        disableDelete: false,
        // customHeader: true,
        pageTitle: 'discounttype',
        ServiceId: 'discounttype',
        IsViewOnly: this.IsViewOnly,
        TableDraggable: true,
        automationId: 'retailDiscountTypes'
      }
    ];
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void 
  {
    if(error.errorCode == 10506)
    {
      if(this.updatedData.active != undefined)
      {
        this.updatedData.active = true;
      }
    }
  }



  sliderChange(event: any) {
    let currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == event.data.id);
    this.tableoptions[0].TablebodyData[currIndex].active = !event.value;
  }
  openDialog(){
    this.dialog.open(ConfigureDateTimeComponent, {  
      width: '100%',
      height: '100%',
      maxWidth :'100%',
      hasBackdrop: true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.localization.captions.hdr_CreateDiscountType,
        data: Number(this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1] ? this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1].listOrder : 0) + 1,
        mode: 'create',
      }
    }).afterClosed().subscribe(result => {
      this.InvokeServiceCall("GetDiscountTypes", myGlobals.Host.retailManagement, HttpMethod.Get);
    });

  }
}
