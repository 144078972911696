import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigureDateTimeBusiness } from './configure-date-time.business';
import { AlertAction } from 'src/app/common/Models/common.models';
import { Localization } from 'src/app/common/localization/localization';
import { API, DaysOfWeek, ConfigureDateTimeRulesBy, PeriodType } from './configure-date-time.model';
import _, { cloneDeep, result } from 'lodash';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { AlertType } from '../../shared/shared.modal';
import { ButtonType } from '../../shared/globalsContant';

@Component({
  selector: 'app-configure-date-time',
  templateUrl: './configure-date-time.component.html',
  styleUrls: ['./configure-date-time.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigureDateTimeComponent implements OnInit {

  header: string;
  captions: any;
  discountSetupForm: UntypedFormGroup;
  @Output() nextEmit = new EventEmitter();
  ruleArray: UntypedFormArray;
  seasonDays = [];
  floatLabelNever: string;
  floatLabel: string;
  timeformat: number;
  priceRounding: any;
  periodTypes = [];
  weekDays: any;
  selectedWeekDays: any;
  conditionValues: any[];
  isClicked: boolean;
  seasonList;
  nextButton: ButtonValue;
  cancelButton: ButtonValue;
  saveButton: ButtonValue;
  prevButton: ButtonValue;
  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date;
  discountTypeConfig: any;
  discountTypeData: any;
  deletedArrayIds: any = [];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  discountTypeRules = ConfigureDateTimeRulesBy
  todayPropertyDate:Date; 
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
    private localization: Localization,
    private fb: UntypedFormBuilder,
    private business: ConfigureDateTimeBusiness,
    private _utilities: RetailUtilities,
    private dialogRef: MatDialogRef<ConfigureDateTimeComponent>,
    private propertyInformation: RetailPropertyInformation
  ) {
    this.header = this.dialogData.header;
    this.captions = this.localization.captions;
    this.floatLabelNever = this.localization.setFloatLabelNever;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.todayPropertyDate = this.propertyInformation.CurrentDate;
    this.formGenerator();
    this.minStartDate = this.propertyInformation.CurrentDate;
    this.minEndDate = this.propertyInformation.CurrentDate;
    this.initialize();  
    this.discountSetupForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.checkMandatoryFields();
      this.enableNextBtn();
    });
  }
  checkMandatoryFields() {
    // if (this.setupForm.controls.courses.value.length > 0 && this.setupForm.controls.season.value && 
    //  this.setupForm.controls.seasonName.value && this.setupForm.controls.strategyName.value.trim() &&
    //  this.setupForm.controls.rateType.value.length > 0 && this.setupForm.controls.priceRounding.value) {
    //    this.isShow = true;
    //  } else {
    //    this.isShow = false;
    //  }
   }

  async initialize(){
    this.timeformat = this.localization.getTimeFormat();
    this.periodTypes = this.business.getperiodTypes();
    this.weekDays = this.business.getWeekList();
    if(this.todayPropertyDate){
      this.startDateChanged(0, 'startDate', 'endDate');
    }
    if(this.dialogData.mode === 'edit')
    {
      this.getDiscountTypeConfiguration(this.dialogData?.data[0]?.id).then(x => {
        this.bindFormData(this.business.discountTypeConfigView);
      });
    }
    this.discountTypeConfig = this.business.discountTypeConfiguration;
    this.selectedWeekDays = [];
  } 

  formGenerator() {
    this.discountSetupForm = this.fb.group({
      discountName: ['', [Validators.required, Validators.minLength(3)]],
      listOrder:[this.dialogData.data],
      activetoggle: [true],
      enableRules:[false],
      rule: this.fb.array([
        this.fb.group({
          startDate: [this.propertyInformation.CurrentDate, Validators.required],
          endDate: [this.propertyInformation.CurrentDate, Validators.required],
          minstartdate: this.propertyInformation.CurrentDate,
          maxstartdate: '',
          minenddate: this.propertyInformation.CurrentDate,
          maxenddate: '',
          rangeDiff: '',
          rulesByRange: this.fb.array([
            this.fb.group({
              rule_id: 0,
              rule_periodTypes: [this.business.getperiodTypes()],
              rule_period: ['', Validators.required],
              rule_startTime: [''],
              rule_endTime: [''],
              rule_applyForAllDays: false,
              rule_customizeAllDays: [this.business.getWeekList()],
              rule_minEndTime: this.localization.getDefaultTime(),
              rule_maxStartTime: null,
              rule_minStartTime: this.localization.getDefaultTime(),
              rule_maxEndTime: null,
              rule_isAddDisable: false,
              rule_overrideFlatAmt: ''
            })
          ])
        })
  ]),
  }) 
  this.nextButton = {
    type: "primary",
    label: this.captions.btn_next,
    disabledproperty: true,
  };
  this.saveButton = {
    type: "primary",
    label: this.dialogData.mode == 'edit' ? this.captions.lbl_updateDiscount : this.captions.lbl_saveDiscount,
    disabledproperty: true,
  };
  this.prevButton = {
    type: "tertiary",
    label: this.captions.btn_previous,
  };
  this.cancelButton = {
    type: "tertiary",
    label: this.captions.btn_cancel,
  };
    this.ruleArray = this.discountSetupForm.get('rule') as UntypedFormArray
    this.discountSetupForm.valueChanges.subscribe(x => {
      this.enableNextBtn()
    })
  }
  bindFormData(inputData: API.DiscountTypeConfigurationView[]) {
    if (this.dialogData.mode === 'edit') {
      this.discountSetupForm.controls.discountName.setValue(this.dialogData?.data[0]?.discountType);
      this.discountSetupForm.controls.listOrder.setValue(this.dialogData?.data[0]?.listOrder);
      this.discountSetupForm.controls.activetoggle.setValue(this.dialogData?.data[0]?.active);
      this.discountSetupForm.controls.enableRules.setValue(this.dialogData?.data[0]?.enableDiscountRules);
      let arrayform = this.discountSetupForm.get('rule') as UntypedFormArray;
      arrayform.removeAt(0);
      inputData.forEach((x,i)  => {
        const controls = this.fb.group({
          startDate: new Date(x.startDate),
          endDate: new Date(x.endDate),
          minstartdate: this.propertyInformation.CurrentDate,
          maxstartdate: '',
          minenddate: this.propertyInformation.CurrentDate,
          maxenddate: '',
          rangeDiff: '',
          rulesByRange: this.mapperForRules(x.rules,new Date(x.startDate),new Date(x.endDate),i)
        });
        arrayform.push(controls);
        controls.controls.rulesByRange['controls'].forEach((x,ind)=>{
          this.validation(ind,'rule','rule_startTime','rule_endTime','rule_customizeAllDays','rule_period','rule_applyForAllDays',i)
          this.checkAddDisable('rule','rule_period','rule_customizeAllDays','rule_isAddDisable','rule_periodTypes',i);
        })
      })
      this.discountSetupForm.markAsPristine();
      this.enableNextBtn();
    }
  }
  mapperForRules(data: API.DiscountTypeConfiguration[],startDate,endDate,i) {
    let response = this.fb.array([]);
    this.seasonDays=[];
      let dateRange = this.localization.getDatesForGivenRange(
        startDate,
        endDate
      );
      if(dateRange.length > 0){
        dateRange.forEach(x => {
          this.seasonDays.push(
            this.localization.getDate(x.dateObj).toLocaleDateString('en-us', {weekday : 'short'})
          );
        });
        this.seasonDays=_.uniqBy(this.seasonDays,x=>x);
    }
    // this.checkSeasonDates(i,startDate, endDate);
    data.forEach((element,ind) => {
      let ruleRow = this.fb.group({
        rule_id: element.id,
        rule_periodTypes: [this.business.getperiodTypes()],
        rule_period: element.rules,
        rule_startTime: this.localization.getTime(this.localization.ConvertStringDateTimeToDate(element.startTime, this.localization.LocalizeTime(element.startTime)), this.timeformat),
        rule_endTime: this.localization.getTime(this.localization.ConvertStringDateTimeToDate(element.endTime, this.localization.LocalizeTime(element.endTime)), this.timeformat),
        rule_customizeAllDays: [this.getWeekListforUI(element.daysOfWeek)],
        rule_applyForAllDays: this.applyForAllDays(element.daysOfWeek),
        rule_minEndTime: this.localization.getDefaultTime(),
        rule_maxStartTime: null,
        rule_minStartTime: this.localization.getDefaultTime(),
        rule_maxEndTime: null,
        rule_isAddDisable: false,
        rule_overrideFlatAmt: ''
      })
      response.push(ruleRow);
      // this.validation(ind,'rule','rule_startTime','rule_endTime','rule_customizeAllDays','rule_period','rule_applyForAllDays',i)
      // this.checkAddDisable('rule','rule_period','rule_customizeAllDays','rule_isAddDisable','rule_periodTypes',i);
    });
    return response;
  }
  getWeekListforUI(days: DaysOfWeek) {
    this.weekDays = this.setSeasonDaysForgrid()
    this.weekDays.forEach(wd=>wd.isClicked = ((days & wd.id) == wd.id));
    console.log(this.weekDays);
    return this.weekDays;
  }
  isAppliedForAllDays(days: DaysOfWeek) {
    let result = true;
    let weekDays = this.getWeekListforUI(days);
    if(weekDays.filter(x=>x.isClicked).length == weekDays.filter(x=>x.isSeasonDay).length)
      return result;
    else 
      return false;
  }
  deleteDayRange(index) {
    const control = this.discountSetupForm.get('rule') as UntypedFormArray;
    control.value[index].rulesByRange.map(x => x.rule_id).forEach(element => {
      console.log(element);
      if (element != undefined && element > 0)
        this.deletedArrayIds.push(element);
    });
    control.removeAt(index);
    this.deleteDayRangeValidation();
    this.discountSetupForm.markAsDirty();
    this.enableNextBtn();
  }
  deleteDayRangeValidation() {
    let overallArr = this.discountSetupForm.get('rule') as UntypedFormArray;
    let range = ['startDate', 'endDate'];
    overallArr.controls.forEach((ele, gridIndex) => {
      ele['controls']['endDate'].setErrors(null);
      ele['controls']['startDate'].setErrors(null);
      range.forEach(rangeData => {
        this.checkRangeValid(gridIndex, rangeData);
      });
    });
  }

  startDateChanged(i, fromRange, toRange) {
    let startDate = this.discountSetupForm.get('rule')['controls'][i].controls[fromRange].value;    
    let endDate = this.discountSetupForm.get('rule')['controls'][i].controls[toRange].value;
    this.checkBetweenRangeValidation(i, startDate, endDate, this.discountSetupForm.get('rule')['controls'][i].controls['rangeDiff'].value);
    if (startDate && endDate) {
      if (this.isStartDateExceedsEndDate(i, fromRange, toRange)) {
        this.discountSetupForm.get('rule')['controls'][i].controls['startDate'].setErrors({ greater: true });
      } else {
        this.discountSetupForm.get('rule')['controls'][i].controls['startDate'].setErrors(null);
      }
      let daysDiff = this.localization.getDaysDifference(startDate, endDate);
      this.discountSetupForm.get('rule')['controls'][i].controls['rangeDiff'].setValue(daysDiff);
    }
    // this.setupForm.get('rule')['controls'][i].controls['minenddate'].value = startDate;
    this.checkOverallDayRangeValidation(i,startDate,endDate,'startDate');
    this.checkSeasonDates(i,startDate, endDate);
    this.checkValidDateOnCreate(i);
  }
  checkValidDateOnCreate(i)
  {
    // For Create Only 
    if(this.dialogData.mode != 'edit')
    {
      let controlList = this.discountSetupForm.get('rule') as UntypedFormArray;
      let startDate = this.discountSetupForm.get('rule')['controls'][i].controls['startDate'].value;    
      let endDate = this.discountSetupForm.get('rule')['controls'][i].controls['endDate'].value;
      let isValiStartdateDate: boolean = startDate < this.propertyInformation.CurrentDate;
      let isValiEnddateDate: boolean = endDate < this.propertyInformation.CurrentDate;
      if(isValiStartdateDate)
      {
        this.discountSetupForm.get('rule')['controls'][i].controls['startDate'].setErrors({ isinvalid: true });
        controlList.controls[i]['controls']['startDate'].setErrors({ isinvalid: true });
      } 
      if(isValiEnddateDate)
      {
        this.discountSetupForm.get('rule')['controls'][i].controls['endDate'].setErrors({ isinvalid: true });
        controlList.controls[i]['controls']['endDate'].setErrors({ isinvalid: true });
      } 
    }    
  }
  checkSeasonDates(i,stdate,enDate){
    this.seasonDays=[];
      let dateRange = this.localization.getDatesForGivenRange(
        stdate,
        enDate
      );
      if(dateRange.length > 0){
        dateRange.forEach(x => {
          this.seasonDays.push(
            this.localization.getDate(x.dateObj).toLocaleDateString('en-us', {weekday : 'short'})
          );
        });
        this.seasonDays=_.uniqBy(this.seasonDays,x=>x);
    }
    let arr=this.discountSetupForm.get('rule')['controls'][i].controls.rulesByRange.controls;
    arr.forEach(x=>{
      x.controls.rule_customizeAllDays.setValue(this.setSeasonDaysForgrid());
      x.controls.rule_applyForAllDays.setValue(false);
    })
    // this.weekDays = this.setSeasonDaysForgrid()
  }
  endDateChanged(i, fromRange, toRange) {
    let startDate = this.discountSetupForm.get('rule')['controls'][i].controls[fromRange].value;
    let endDate = this.discountSetupForm.get('rule')['controls'][i].controls[toRange].value;
    this.checkBetweenRangeValidation(i, startDate, endDate, this.discountSetupForm.get('rule')['controls'][i].controls['rangeDiff'].value);
    if (startDate && endDate) {
      if (this.isStartDateExceedsEndDate(i,fromRange, toRange)) {
        this.discountSetupForm.get('rule')['controls'][i].controls['startDate'].setErrors({ greater: true });
      } else {
        this.discountSetupForm.get('rule')['controls'][i].controls['startDate'].setErrors(null);
      }
      let daysDiff = this.localization.getDaysDifference(startDate, endDate);
      this.discountSetupForm.get('rule')['controls'][i].controls['rangeDiff'].setValue(daysDiff);
    }
    // this.setupForm.get('rule')['controls'][i].controls['minenddate'].value = startDate;
    this.checkOverallDayRangeValidation(i,startDate,endDate,'endDate');
    this.checkSeasonDates(i,startDate, endDate);
    this.checkValidDateOnCreate(i);
  }
  checkBetweenRangeValidation(i, startDate, endDate, daysDiff) {
    let currentDaysDiff = this.localization.getDaysDifference(startDate, endDate);
    if (currentDaysDiff > daysDiff) {
      let arr=this.discountSetupForm.get('rule')['controls'][i].controls.rulesByRange.controls;
      arr.forEach((x,i)=>{
        x.controls.rule_customizeAllDays.value.forEach(y=>{
          y.isClicked = false;
        });
        x.controls.rule_applyForAllDays.setValue(false);
      })
    }
  }
  checkOverallDayRangeValidation(index, startDate, endDate, type) {
    let overallArr = this.discountSetupForm.get('rule') as UntypedFormArray;
    if (type == 'startDate') {
      for (let i = 0; i < overallArr.length; i++) {
        this.discountSetupForm.get('rule')['controls'][index].controls['startDate'].setErrors(null);
        overallArr.controls[i]['controls']['endDate'].setErrors(null);
        if (i != index) {
          let comparisonStartDate = overallArr.controls[i]['controls']['startDate'].value;
          let comparisonEndDate = overallArr.controls[i]['controls']['endDate'].value;
          if ((comparisonStartDate <= startDate && comparisonEndDate >= startDate)
            || (startDate <= comparisonStartDate && startDate >= comparisonEndDate)) {
            this.discountSetupForm.get('rule')['controls'][index].controls['startDate'].setErrors({ overlap: true });
            overallArr.controls[i]['controls']['endDate'].setErrors({ overlap: true });
            break;
          }
        }
      }
    } else {
      for (let i = 0; i < overallArr.length; i++) {
        this.discountSetupForm.get('rule')['controls'][index].controls['endDate'].setErrors(null);
        overallArr.controls[i]['controls']['startDate'].setErrors(null);
        if (i != index) {
          let comparisonStartDate = overallArr.controls[i]['controls']['startDate'].value;
          let comparisonEndDate = overallArr.controls[i]['controls']['endDate'].value;
          if ((comparisonEndDate >= endDate && comparisonStartDate <= endDate)
            || (comparisonEndDate >= endDate && endDate >= comparisonStartDate)) {
            this.discountSetupForm.get('rule')['controls'][index].controls['endDate'].setErrors({ overlap: true });
            overallArr.controls[i]['controls']['startDate'].setErrors({ overlap: true });
            break;
          }
        }
      }
    }
  }
  isStartDateExceedsEndDate(i, fromRange, toRange): boolean {
    const startDate = this.discountSetupForm.get('rule')['controls'][i].controls[fromRange].value;
    const endDate = this.discountSetupForm.get('rule')['controls'][i].controls[toRange].value
    return this.resetTime(startDate) > this.resetTime(endDate);
  }
  resetTime(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }
  checkRangeValid(index, range) {
    let value = this.discountSetupForm.get('rule')['controls'][index].controls[range].value;
    if (value == NaN || value == null) {
      this.discountSetupForm.get('rule')['controls'][index].controls[range].setErrors({ required: true });
    }
    else {
      this.discountSetupForm.get('rule')['controls'][index].controls[range].setErrors(null);
      this.checkValid(index, range);
    }
  }
  checkValid(index, controlName) {
    let overallArr = this.discountSetupForm.get('rule') as UntypedFormArray;
    let totalArr = overallArr.controls.filter((x, ind, array) => array.indexOf(x) != index)
    let currentfromRange = this.discountSetupForm.get('rule')['controls'][index].controls.startDate?.value;
    let currentToRange = this.discountSetupForm.get('rule')['controls'][index].controls.endDate?.value;

    //Check InValid Status
    let isAllFilled = false;
    if ((currentfromRange != null && currentfromRange !== NaN && (currentfromRange >= 0))
      && (currentToRange != null && currentToRange !== NaN && (currentToRange >= 0))) {
      isAllFilled = true;
      overallArr.controls[index]['controls'][controlName].setErrors(null);
    }
    //check overlap
    if ((currentfromRange > currentToRange) && isAllFilled) {
      this.discountSetupForm.get('rule')['controls'][index].controls[controlName].setErrors({ invalid: true });
      // this.setupForm.get('rule')['controls'][index].controls['endDate'].setErrors({ invalid: true });
    } else {

      if (this.discountSetupForm.get('rule')['controls'][index].controls['endDate'].hasError('invalid')) {
        this.discountSetupForm.get('rule')['controls'][index].controls['endDate'].setErrors(null);
      }
      if (this.discountSetupForm.get('rule')['controls'][index].controls['startDate'].hasError('invalid')) {
        this.discountSetupForm.get('rule')['controls'][index].controls['startDate'].setErrors(null);
      }

      let currentRange = this.discountSetupForm.get('rule')['controls'][index].controls[controlName]?.value;
      totalArr.forEach(arr => {
        let valid = true;
        //checking all grid's from and to with current grid
        let from = (arr['controls'].startDate.value) ? arr['controls'].startDate.value : NaN;
        let to = (arr['controls'].endDate.value) ? arr['controls'].endDate.value : NaN;
        if ((from != NaN && to != NaN) && from <= currentRange && to >= currentRange) {
          valid = false
        }
        if (!valid) {
          this.discountSetupForm.get('rule')['controls'][index].controls[controlName].setErrors({ incorrect: true });
        }
      })
    }
    this.enableNextBtn();
  }
  onDayClick(index, array, formcontrol, applyAllDay, period, isAddDisable, arrIdx, formArray, st, et, periodTypes, dayRangeIndex?) {
    let arr = array.value[formcontrol]
    arr[index].isClicked = !arr[index].isClicked
    let filteredDays = arr.filter(x => x.isClicked == true)
    let availableDays = arr.filter(x => x.isDisabled == false)
    if (filteredDays.length == availableDays.length) {
      array.controls[applyAllDay].setValue(true);
    }
    else {
      array.controls[applyAllDay].setValue(false)
    }
    let overallArr;
    overallArr = this.discountSetupForm.get('rule')['controls'][dayRangeIndex].controls.rulesByRange
    this.validation(arrIdx, formArray, st, et, formcontrol, period, applyAllDay, dayRangeIndex);
    this.checkAddDisable(formArray, period, formcontrol, isAddDisable, periodTypes, dayRangeIndex);
    this.disablePeriodWeek(overallArr['controls'], periodTypes, formcontrol, overallArr['controls'].length);
    this.enableNextBtn();
  }
  //adding an empty row
  addDualRules(arrayIndex, index) {
    const controls = this.fb.group({
      rule_id:0,
      rule_periodTypes: [this.business.getperiodTypes()],
      rule_period: ['', Validators.required],
      rule_startTime: ['', Validators.required],
      rule_endTime: ['', Validators.required],
      rule_applyForAllDays: false,
      rule_customizeAllDays: [this.setSeasonDaysForgrid()],
      rule_minEndTime: this.localization.getDefaultTime(),
      rule_maxStartTime: null,
      rule_minStartTime: this.localization.getDefaultTime(),
      rule_maxEndTime: null,
      rule_isAddDisable: false
    })
    this.ruleArray = (controls == null ? this.discountSetupForm.get('rule') as UntypedFormArray : this.discountSetupForm.get('rule')['controls'][arrayIndex].controls.rulesByRange as UntypedFormArray);
    this.ruleArray.push(controls);
    this.disablePeriodWeek(this.discountSetupForm.get('rule')['controls'][arrayIndex].controls.rulesByRange['controls'], 'rule_periodTypes', 'rule_customizeAllDays', this.discountSetupForm.get('rule')['controls'][arrayIndex].controls.rulesByRange['controls'].length)
  }
  validation(index, formArray, startTime, endTime, customizeAllDays, period, applyAllDay, dayRangeIndex?) {
    this.gridValidation(index, formArray, startTime, endTime, customizeAllDays, period, applyAllDay, dayRangeIndex);
    this.allGridValidation(formArray, startTime, endTime, customizeAllDays, period, applyAllDay, dayRangeIndex);
  }
  onToggleChange(event, array, customizeAllDays, period, isAddDisable, formArray, startTime, endTime, index, periodTypes, applyAllDay, dayRangeIndex?) {
    let arr = array.value[customizeAllDays]
    arr.forEach(ele => {
      if (!ele.isDisabled) {
        ele.isClicked = event
      }
    });
    this.validation(index, formArray, startTime, endTime, customizeAllDays, period, applyAllDay, dayRangeIndex)
    this.checkAddDisable(formArray, period, customizeAllDays, isAddDisable, periodTypes, dayRangeIndex)
    this.enableNextBtn();
  }
  ok(eve) {
    this.isClicked = true;
  }
  daysMapper(daysOfWeek: DaysOfWeek) {
    this.weekDays = this.setSeasonDaysForgrid()
    this.weekDays.forEach(wd => wd.isClicked = ((daysOfWeek & wd.id) == wd.id));
    console.log(this.weekDays);
    return this.weekDays;
  }
  applyForAllDays(daysOfWeek) {
    this.weekDays = this.setSeasonDaysForgrid()
    let arr = this.weekDays.filter(x => x.isClicked);
    return arr.length >= this.seasonDays.length ? true : false;
  }
  createDualUtilValues(_index: number, _data: API.DiscountTypeConfiguration[]) {
    let rulesArr = this.fb.array([]);
    _data.forEach((res, ind) => {
      let arr = this.fb.group({
        rule_periodTypes: [this.business.getperiodTypes()],
        rule_period: res.rules,
        rule_startTime: this.localization.getTime(this.localization.ConvertStringDateTimeToDate(res.startTime, this.localization.LocalizeTime(res.startTime)), this.timeformat),
        rule_endTime: this.localization.getTime(this.localization.ConvertStringDateTimeToDate(res.endTime, this.localization.LocalizeTime(res.endTime)), this.timeformat),
        rule_customizeAllDays: [this.daysMapper(res.daysOfWeek)],
        rule_applyForAllDays: this.applyForAllDays(res.daysOfWeek),
        rule_isAddDisable: false,
        rule_maxEndTime: null,
        rule_maxStartTime: null,
        rule_minEndTime: this.localization.getDefaultTime(),
        rule_minStartTime: this.localization.getDefaultTime(),
        rule_validated: ''
      });
      rulesArr.push(arr);
      this.dualPatch(arr);
      this.rule_periodChanged(arr.controls.rule_period.value, ind, _index, true, arr);
      arr.controls.rule_period.setValidators(Validators.required);
      arr.controls.rule_period.updateValueAndValidity();
    });
    return rulesArr
  }
  dualPatch(arr) {
    arr.controls.rule_minStartTime.setValue("00:00")
    arr.controls.rule_minStartTime.updateValueAndValidity();
    arr.controls.rule_minEndTime.setValue(arr.controls.rule_startTime.value);
    arr.controls.rule_minEndTime.updateValueAndValidity();
    arr.controls.rule_maxStartTime.setValue("23:59");
    arr.controls.rule_maxStartTime.updateValueAndValidity();
    arr.controls.rule_maxEndTime.setValue("23:59");
    arr.controls.rule_maxEndTime.updateValueAndValidity();
  }

  rule_periodChanged(e, index, dayRangeIndex, fromEdit, ruleControls) {
    // let ruleControls=this.setupForm.get('rule')['controls'][dayRangeIndex].controls.rulesByRange['controls'][index].controls 
    if (!fromEdit) {
      ruleControls.controls.rule_period.setValue(e);
      ruleControls.controls.rule_applyForAllDays.setValue(false);
      ruleControls.controls.rule_customizeAllDays.setValue(this.setSeasonDaysForgrid());
      ruleControls.controls.rule_startTime.setValue('');
      ruleControls.controls.rule_endTime.setValue('');
    }
    if (e == PeriodType.week) {
      ruleControls.controls.rule_startTime.clearValidators();
      ruleControls.controls.rule_startTime.updateValueAndValidity();
      ruleControls.controls.rule_endTime.clearValidators();
      ruleControls.controls.rule_endTime.updateValueAndValidity();
      ruleControls.controls.rule_applyForAllDays.setValidators(Validators.required);
    }
    else {
      ruleControls.controls.rule_startTime.setValidators(Validators.required)
      ruleControls.controls.rule_startTime.updateValueAndValidity();
      ruleControls.controls.rule_endTime.setValidators(Validators.required)
      ruleControls.controls.rule_endTime.updateValueAndValidity();
      ruleControls.controls.rule_applyForAllDays.setValidators(Validators.required)
      ruleControls.controls.rule_applyForAllDays.updateValueAndValidity();
    }
    if (!fromEdit) {
      this.deleteOverallConditionValue(index, 'rule', 'rule_conditionArray', 'rule_condition', 'rule_conditionValue', dayRangeIndex)
      this.validation(index, 'rule', 'rule_startTime', 'rule_endTime', 'rule_customizeAllDays', 'rule_period', 'rule_applyForAllDays', dayRangeIndex)
    }
    this.enableNextBtn();
  }
  checkOverallConditionValue(condInd, ruleIndex, formArray, conditionArray, condition, conditionValue, dayRangeIndex?) {
    let overallArr = this.discountSetupForm.get(formArray) as UntypedFormArray
    let conditionArr;
    conditionArr = overallArr['controls'][dayRangeIndex]['controls'].rulesByRange.controls[ruleIndex]['controls'][conditionArray]
    conditionArr.controls.forEach((ele, currCondindex) => {
      if (condInd != currCondindex) {
        ele.controls[conditionValue].setErrors(null);
        this.checkConditionValue(currCondindex, ruleIndex, formArray, conditionArray, condition, conditionValue, dayRangeIndex)
      }

    })
  }

  timeChanged(e, idx, from, formArray, dayRangeIndex?) {
    if (this.isClicked) {
      let array = this.discountSetupForm.get(formArray)['controls'][dayRangeIndex].controls.rulesByRange
          if (from === 'fromTime') {
            if (array.controls[idx]['controls']['rule_startTime'].value != '') {
              array.controls[idx]['controls'].rule_minEndTime.setValue(array.controls[idx]['controls']['rule_startTime'].value)
            }
          } else if (from === 'toTime') {
            if (array.controls[idx]['controls']['rule_endTime'].value != '') {

            }
          }
          this.checkTime(array, idx, 'rule_startTime', 'rule_endTime');
          this.validation(idx, formArray, 'rule_startTime', 'rule_endTime', 'rule_customizeAllDays', 'rule_period', 'rule_applyForAllDays', dayRangeIndex);
    }
    this.enableNextBtn();
  }
  checkTime(arr, idx, startTime, endTime) {
    const st = (this.localization.TimeToDate(arr.controls[idx]['controls'][startTime].value)).getTime();
    const et = (this.localization.TimeToDate(arr.controls[idx]['controls'][endTime].value)).getTime();
    console.log(st);
    console.log(et);
    if (st > et || st == et) {
      arr.controls[idx]['controls'][endTime].setValue('');
    }
  }
  deleteOverallConditionValue(ruleIndex,formArray,conditionArray,condition,conditionValue,dayRangeIndex?){
    // let overallArr= this.discountSetupForm.get(formArray) as UntypedFormArray
    // let conditionArr;
    // //  conditionArr=overallArr['controls'][dayRangeIndex]['controls'].rulesByRange.controls[ruleIndex]['controls'][conditionArray]
    // conditionArr.controls.forEach((ele,currCondindex) => {
    //         ele.controls[conditionValue].setErrors(null);
    //         this.checkConditionValue(currCondindex,ruleIndex,formArray,conditionArray,condition,conditionValue,dayRangeIndex)
    // })
  }
  checkConditionValue(condInd, ruleIndex, formArray, conditionArray, condition, conditionValue, dayRangeIndex?) {
    let overallArr = this.discountSetupForm.get(formArray) as UntypedFormArray
    let conditionArr;
    conditionArr = overallArr['controls'][dayRangeIndex]['controls'].rulesByRange.controls[ruleIndex]['controls'][conditionArray]
    let currIndex = conditionArr.controls[condInd].controls;
    let totalArr = conditionArr.controls.filter((x, ind, array) => array.indexOf(x) != condInd)
    if (currIndex[conditionValue].value == '' || currIndex[conditionValue].value == null) {
      currIndex[conditionValue].setErrors({ required: true });
    }
    else if (currIndex[conditionValue].value > 100 && formArray != "daysInAdvance") {
      currIndex[conditionValue].setErrors({ max: true });
    }
    else {
      currIndex[conditionValue].setErrors(null);
      if (totalArr.length > 0) {
        totalArr.forEach(ele => {
          if ((ele.controls[condition].value == currIndex[condition].value) && (ele.controls[conditionValue].value == Number(currIndex[conditionValue].value).customToFixed())) {
            currIndex[conditionValue].setErrors({ invalid: true });
          }
        })
      }
    }

    this.enableNextBtn();

  }
  enableNextBtn() {
    this.CheckCustomization();
    if (this.discountSetupForm.dirty && this.discountSetupForm.valid) {
      this.saveButtonEnable(false)
    }
    else if (this.discountSetupForm.dirty && !this.discountSetupForm.controls.enableRules.getRawValue() && this.discountSetupForm.controls.discountName.valid) {
      this.saveButtonEnable(false)
    }
    else {
      this.saveButtonEnable(true)
    }
  }
  clearAllRules() {
    this._utilities.showAlert(this.captions.lbl_showclearMsg, AlertType.Warning, ButtonType.YesNo, async (res) => {
      if (res == AlertAction.YES) {
        if (this.discountSetupForm.controls.rule?.['controls'].length > 0) {
          const controls = this.discountSetupForm.get('rule') as UntypedFormArray;
          let controlsLength: number = controls['controls'].length;
          for (let i = 0; i < controlsLength; i++) {
            controls.value[0].rulesByRange.map(c => {
              if (c.rule_id != undefined && c.rule_id > 0)
                this.deletedArrayIds.push(c.rule_id)
            });
            controls.removeAt(0);
          }
          this.ruleArray = this.discountSetupForm.get('rule') as UntypedFormArray;
          this.ruleArray.push(this.createDualFormArray());
          this.enableNextBtn();
        }
      }
    });
    this.discountSetupForm.markAsDirty();
    this.enableNextBtn();
  }
  removeRules(_index, arrayIndex) {
    this.ruleArray = (arrayIndex == null ? this.discountSetupForm.get('rule') as UntypedFormArray : this.discountSetupForm.get('rule')['controls'][arrayIndex].controls.rulesByRange as UntypedFormArray);
    if(this.ruleArray.value[_index].rule_id>0)
    {
      this.deletedArrayIds.push(this.ruleArray.value[_index].rule_id);
    }
    this.ruleArray.removeAt(_index);
    this.allGridValidation('rule', 'rule_startTime', 'rule_endTime', 'rule_customizeAllDays', 'rule_period', 'rule_applyForAllDays', arrayIndex);
    this.checkAddDisable('rule', 'rule_period', 'rule_customizeAllDays', 'rule_isAddDisable', 'rule_periodTypes', arrayIndex)
    this.disablePeriodWeek(this.discountSetupForm.get('rule')['controls'][arrayIndex].controls.rulesByRange['controls'], 'rule_periodTypes', 'rule_customizeAllDays', this.discountSetupForm.get('rule')['controls'][arrayIndex].controls.rulesByRange['controls'].length);
    this.discountSetupForm.markAsDirty();
    this.saveButtonEnable(false);
  }
  deleteDiscountTypeconfig(ids)
  {
    this.business.DeleteDiscountTypeConfigurationById(ids);
  }
  copyRules(_index, arrayIndex)
  {
    this.ruleArray = (arrayIndex == null ? this.discountSetupForm.get('rule') as UntypedFormArray : this.discountSetupForm.get('rule')['controls'][arrayIndex].controls.rulesByRange as UntypedFormArray);
    console.log(this.ruleArray.value[_index]);
    const value = this.ruleArray.value[_index];

    let arr = this.fb.group({
      rule_id: 0,
      rule_periodTypes: [this.business.getperiodTypes()],
      rule_period: value.rule_period,
      rule_startTime: ['', Validators.required],
      rule_endTime: ['', Validators.required],
      rule_applyForAllDays: _.cloneDeep(value.rule_applyForAllDays),
      rule_customizeAllDays: [_.cloneDeep(value.rule_customizeAllDays)],
      rule_minEndTime: this.localization.getDefaultTime(),
      rule_maxStartTime: null,
      rule_minStartTime: this.localization.getDefaultTime(),
      rule_maxEndTime: null,
      rule_isAddDisable: false
    });
    this.ruleArray.push(arr);
    this.disablePeriodWeek(this.discountSetupForm.get('rule')['controls'][arrayIndex].controls.rulesByRange['controls'], 'rule_periodTypes', 'rule_customizeAllDays', this.discountSetupForm.get('rule')['controls'][arrayIndex].controls.rulesByRange['controls'].length)
  }
  disablePeriodWeek(overAllcontrols, periodTypes, customizeDays, totalGridLength, dayRangeIndex?) {
    let selectedDays = [];
    let dplicate = [];

    overAllcontrols.forEach((arr, index?) => {
      if (index < totalGridLength - 1) {
        dplicate = [...dplicate, ...arr['controls'][customizeDays].value.filter(x => x.isClicked == true)]
      }
    });
    selectedDays = _.uniqBy(dplicate, x => x.id);
    overAllcontrols[totalGridLength - 1]['controls'][periodTypes].value.filter((x) => {
      if (x.id == PeriodType.week && selectedDays.length == this.seasonDays.length) {
        x.isDisabled = true
      }
      else {
        x.isDisabled = false
      }
    })

  }
  allGridValidation(formArray, startTime, endTime, customizeAllDays, period, applyAllDay, dayRangeIndex?) {
    let overallArr = this.discountSetupForm.get(formArray) as UntypedFormArray;
    this.discountSetupForm.get('rule')['controls'][dayRangeIndex].controls.rulesByRange['controls'].forEach((element, index) => {
      this.gridValidation(index, formArray, startTime, endTime, customizeAllDays, period, applyAllDay, dayRangeIndex);
    });
  }
  gridValidation(index, formArray, startTime, endTime, customizeAllDays, period, applyAllDay, dayRangeIndex?) {
    let overallArr;
    let currentArr;
    let totalArr;
    overallArr = this.discountSetupForm.get('rule')['controls'][dayRangeIndex].controls.rulesByRange as UntypedFormArray;
    currentArr = overallArr.controls[index];
    totalArr = overallArr.controls.filter((x, ind, array) => array.indexOf(x) != index)

    let selectedDays = [];
    let isAllGridValid = true;
    overallArr.controls.forEach(x => {
      if (x['controls'][startTime].value != '') {
        x['controls'][startTime].setErrors(null);
      }
      if (x['controls'][endTime].value != '') {
        x['controls'][endTime].setErrors(null);
      }
    })
    //HOUR
    if (currentArr['controls'][period].value == PeriodType.hour) {
      totalArr.forEach(arr => {
        if (arr['controls'][period].value == PeriodType.week) {
          selectedDays = [...selectedDays, ...arr['controls'][customizeAllDays].value.filter(x => x.isClicked == true)]
        }
      })
      const currentStartTime = (this.localization.TimeToDate(currentArr['controls'][startTime].value)).getTime();
      const currentEndTime = (this.localization.TimeToDate(currentArr['controls'][endTime].value)).getTime();

      totalArr.forEach(arr => {
        let isGridValid = true;
        let startValid = true;
        let endValid = true;
        //checking grid's st and et 
        let st = (this.localization.TimeToDate(arr['controls'][startTime].value)).getTime();
        let et = (this.localization.TimeToDate(arr['controls'][endTime].value)).getTime();

        if (st <= currentStartTime && et >= currentStartTime) {
          isGridValid = false
          startValid = false
        }
        else if (st <= currentEndTime && et >= currentEndTime) {
          isGridValid = false
          endValid = false
        }
        else if (st == currentStartTime && et == currentEndTime) {
          isGridValid = false
        }
        else if (st >= currentStartTime && st <= currentEndTime) {
          isGridValid = false
          startValid = false
        }
        else if (et >= currentStartTime && et <= currentEndTime) {
          isGridValid = false
          endValid = false
        }
        else if ((st <= currentStartTime && et >= currentEndTime) || !startValid || !endValid) {
          isGridValid = false;
        }
        isAllGridValid = isGridValid
        if (!isAllGridValid) {
          selectedDays = [...selectedDays, ...arr['controls'][customizeAllDays].value.filter(x => x.isClicked == true)]
        }
        else {

        }
        if (selectedDays.length == this.seasonDays.length && !startValid) {
          currentArr['controls'][startTime].setErrors({ invalid: true });
        }
        else if (selectedDays.length == this.seasonDays.length && !endValid) {
          currentArr['controls'][endTime].setErrors({ invalid: true });
        }
      })


    }
    //Week
    else {
      totalArr.forEach(arr => {
        selectedDays = [...selectedDays, ...arr['controls'][customizeAllDays].value.filter(x => x.isClicked == true)]
      });
    }
    currentArr['controls'][customizeAllDays].value.forEach(x => {
      let days = selectedDays.filter(day => day.id == x.id)

      if (days.length > 0) {
        x.isDisabled = true
        x.isClicked = false
      }
      else {
        x.isDisabled = false
      }

      if (!x.isSeasonDay)
        x.isDisabled = true;
    })
    let allDays = currentArr['controls'][customizeAllDays].value.every(x => x.isDisabled == true)
    if (allDays) {
      currentArr['controls'][applyAllDay].disable();
    }
    else {
      currentArr['controls'][applyAllDay].enable();
    }
    let filteredDays = currentArr['controls'][customizeAllDays].value.filter(x => x.isClicked == true)
    let availableDays = currentArr['controls'][customizeAllDays].value.filter(x => x.isDisabled == false)
    if (filteredDays.length == availableDays.length) {
      currentArr['controls'][applyAllDay].setValue(true);
    }
    else {
      currentArr['controls'][applyAllDay].setValue(false)
    }
    this.selectedWeekDays = selectedDays
  }
  checkAddDisable(formArray, period, customizeAllDays, isAddDisable, periodTypes, dayRangeIndex?) {
    let selectedDays = [];
    let overallArr;
    overallArr = this.discountSetupForm.get('rule')['controls'][dayRangeIndex].controls.rulesByRange
    let overAllcontrols = overallArr.controls;
    let totalGridLength = overAllcontrols.length;
    let weeklyGirdLength =overAllcontrols.filter(arr => arr['controls'][period].value==PeriodType.week).length;
    if (totalGridLength == weeklyGirdLength) {
      overAllcontrols.forEach(arr => {
        selectedDays = [...selectedDays, ...arr['controls'][customizeAllDays].value.filter(x => x.isClicked == true)]
      });
    }
    if (selectedDays.length == this.seasonDays.length) {
      overAllcontrols.forEach(e => e['controls'][isAddDisable].setValue(true));
    }
    else {
      overAllcontrols.forEach(e => e['controls'][isAddDisable].setValue(false));
    }
    this.disablePeriodWeek(overAllcontrols, periodTypes, customizeAllDays, totalGridLength);
  }

  CheckCustomization() {
    // if(this.isDualFlagEnabled){
    return this.checkCustomizeDays('rule')
    // }else{
    //     return this.checkCustomizeDays('')
    // }
  }
  addDayRange() {
    this.ruleArray = this.discountSetupForm.get('rule') as UntypedFormArray;
    this.ruleArray.push(this.createDualFormArray());
  }
  setSeasonDaysForgrid(){
    let days=this.business.getWeekList();
    days.forEach(ele =>ele.isDisabled=true);
    days.forEach(element => {
      if(this.seasonDays?.filter(ele => ele == element.viewVal).length > 0){
        element.isDisabled = false
        element.isSeasonDay = true
      }
     else{
      element.isDisabled = true ;
      element.isSeasonDay = false 
     }
    });
    return days;
  }
  createDualFormArray() {
    return this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      minstartdate: this.propertyInformation.CurrentDate,
      maxstartdate: '',
      minenddate: this.propertyInformation.CurrentDate,
      maxenddate: '',
      rangeDiff: '',
      rulesByRange: this.fb.array([
        this.fb.group({
          rule_periodTypes: [this.business.getperiodTypes()],
          rule_period: ['', Validators.required],
          rule_startTime: ['', Validators.required],
          rule_endTime: ['', Validators.required],
          rule_applyForAllDays: false,
          rule_customizeAllDays: [this.setSeasonDaysForgrid()],
          rule_minEndTime: this.localization.getDefaultTime(),
          rule_maxStartTime: null,
          rule_minStartTime: this.localization.getDefaultTime(),
          rule_maxEndTime: null,
          rule_isAddDisable: false
        })
      ])
    })
  }
  checkCustomizeDays(formArray) {
    let isAllGridValid = true;
    //  if(this.isDualFlagEnabled){
    let overallArr = this.discountSetupForm.get(formArray) as UntypedFormArray;
    overallArr.controls.forEach(element => {
      element['controls'].rulesByRange.controls.forEach(rules => {
        let isValid = rules['controls']['rule_customizeAllDays'].value.some(x => x.isClicked == true);
        if (!isValid) {
          isAllGridValid = false;
          rules.setErrors({ 'invalid': true })
        }
        else {
          rules.setErrors(null);
        }
      })
    })
    // }

    if (!isAllGridValid) {
      this.discountSetupForm.setErrors({ 'invalid': true })
    }
    else {
      this.discountSetupForm.setErrors(null);
    }
    return isAllGridValid
  }
  cancel(eve) {
    if (this.discountSetupForm.dirty) {
      this._utilities.showAlert(this.captions.lbl_Cancel_Msg, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res == AlertAction.YES) {
          this.dialogRef.close();
        }
      })
    }
    else {
      this.dialogRef.close();
    }
  }
  cancelTimePopUp(eve) {
    this.isClicked = false;
  }
  updateDiscountType()
  {
    const discountTypeObj = {
      id: this.dialogData?.data[0]?.id,
      IsActive: this.discountSetupForm.controls.activetoggle.getRawValue(),
      Type: this.discountSetupForm.controls.discountName.getRawValue().trim(),
      ListOrder: this.discountSetupForm.controls.listOrder.getRawValue()?this.discountSetupForm.controls.listOrder.getRawValue():0,
      EnableDiscountRules : this.discountSetupForm.controls.enableRules.getRawValue()
    };
    return this.business.updateDiscountType(discountTypeObj);
  }
  async save(eve) {
    let popupMessage;
    this._utilities.ToggleLoader(true);
    let formData = this.discountSetupForm.getRawValue();
    let saveObj: API.DiscountTypeConfiguration[] = [];
    if (this.dialogData.mode == 'edit') {
      await this.updateDiscountType().then(async result => {
        formData.rule.forEach(x => {
          if (formData.enableRules) {
            x.rulesByRange.forEach(y => {
              let res: API.DiscountTypeConfiguration = {
                id: y.rule_id,
                discountTypeId: this.dialogData?.data[0]?.id,
                startTime: this.timeDateMapper(this.localization.convertDateObjToAPIdate(this.localization.delocalizeDisplayDate(x.startDate)), y.rule_startTime ? y.rule_startTime : "00:00"),
                endTime: this.timeDateMapper(this.localization.convertDateObjToAPIdate(this.localization.delocalizeDisplayDate(x.endDate)), y.rule_endTime ? y.rule_endTime : "23:59"),
                isDeleted: false,
                rules: y.rule_period,
                daysOfWeek: this.weekCalculation(y.rule_customizeAllDays)
              }
              saveObj.push(res);
            })
          }
        })
        if(saveObj.length>0)
        {
          await this.business.update(saveObj).then(res => {
            if (this.deletedArrayIds.length > 0) {
              this.deleteDiscountTypeconfig(this.deletedArrayIds);
            }
          })
        }
        popupMessage = this.localization.replacePlaceholders(this.captions.lbl_discountUpdate, ['discountName'], [this.discountSetupForm.controls.discountName.getRawValue()])
        this._utilities.ToggleLoader(false);
        this._utilities.showAlert(popupMessage, AlertType.Success, ButtonType.Ok, async (res) => {
          if (res == AlertAction.CONTINUE) {
            this.dialogRef.close();
          }
        })
      });
    } else if (this.dialogData.mode == 'create' || this.dialogData.mode == 'copy') {
      await this.AddDiscountType().then(async result =>{
        let discountTypeId = result;
        formData.rule.forEach(x => {
          if (formData.enableRules) {
            x.rulesByRange.forEach(y => {
              let res: API.DiscountTypeConfiguration = {
                id: 0,
                discountTypeId: discountTypeId,
                startTime: this.timeDateMapper(this.localization.convertDateObjToAPIdate(this.localization.delocalizeDisplayDate(x.startDate)), y.rule_startTime ? y.rule_startTime : "00:00"),
                endTime: this.timeDateMapper(this.localization.convertDateObjToAPIdate(this.localization.delocalizeDisplayDate(x.endDate)), y.rule_endTime ? y.rule_endTime : "23:59"),
                isDeleted: false,
                rules: y.rule_period,
                daysOfWeek: this.weekCalculation(y.rule_customizeAllDays)
  
              }
              saveObj.push(res);
            })
          }
        })
        if (saveObj.length > 0) {
          await this.business.save(saveObj);
        }
        popupMessage = this.localization.replacePlaceholders(this.captions.lbl_discountSave, ['discountName'], [this.discountSetupForm.controls.discountName.getRawValue()]);
        this._utilities.ToggleLoader(false);
        this._utilities.showAlert(popupMessage, AlertType.Success, ButtonType.Ok, async (res) => {
          if (res == AlertAction.CONTINUE) {
            this.dialogRef.close();
          }
        })
      })
    }
    console.log(saveObj);
  }

  async AddDiscountType()
  {
    const discountTypeObj = {
      IsActive: this.discountSetupForm.controls.activetoggle.getRawValue(),
      Type: this.discountSetupForm.controls.discountName.getRawValue(),
      ListOrder: this.discountSetupForm.controls.listOrder.getRawValue()?this.discountSetupForm.controls.listOrder.getRawValue():0,
      EnableDiscountRules: this.discountSetupForm.controls.enableRules.getRawValue()
    };
    return await this.business.AddDiscountType(discountTypeObj);
  }

  bindId(course, rateType, isGroupId) {
    let id;
    let dd = this.business.editData;
    console.log(dd);
    // let res = dd.filter(x => x.courseId == course && x.rateTypeId == rateType);
    // if (res.length > 0) {
    //   if (isGroupId) {
    //     id = res[0]['groupId'] ? res[0]['groupId'] : DefaultGUID;
    //   } else {
    //     id = res[0]['id'] ? res[0]['id'] : 0;
    //   }
    // } else {
    //   if (!isGroupId) {
    //     id = 0;
    //   }
    // }
    return id;
  }
  timeDateMapper(date, time) {
    let time_calc = this.localization.TimeToDate(time);
    let res = this.localization.AddTimeToDate(date, time_calc);
    return this.localization.convertDateTimeToAPIDateTime(res);
  }
  GetErrorMessage(error) {
    if (!error.result) {
      let errorMsg = this.localization.getError(error?.errorCode);
      this._utilities.showAlert(errorMsg, AlertType.Error);
    } else {
      this.dialogRef.close();
    }
  }
  weekCalculation(days) {
    let checked = days.filter(wd => wd.isClicked);
    let daysOfWeek: DaysOfWeek;
    checked.forEach(r => {
      daysOfWeek = r.id | daysOfWeek
    })
    return daysOfWeek;
  }

  async getDiscountTypeConfiguration(discountTypeId) {
    await this.business.getDiscountTypeConfigView(discountTypeId);
  }
  saveButtonEnable(eve) {
    this.saveButton.disabledproperty = eve;
    this.saveButton = { ...this.saveButton };
  }
  //Method to get Minimum start date 
  getMinDate(startDate,minstartdate){
    return startDate && startDate < this.minStartDate  ? startDate : minstartdate;
  }
}
