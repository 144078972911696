import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "../../shared/service/http-call.service";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { TaxConfiguration, Outlet, LinkedTax } from "../../retail.modals";
import { BaseResponse } from "../../shared/business/shared.modals";
import { Host } from "../../shared/globalsContant";

@Injectable()
export class RetailTaxesDataService {

    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private localization: RetailLocalization) { }

    public async getAllTaxConfiguration(): Promise<TaxConfiguration[]> {
        let result: TaxConfiguration[] = await this.InvokeTaxConfigurationCalls<TaxConfiguration[]>(Host.retailManagement, "GetTaxes", HttpMethod.Get);
        let taxConfigs: TaxConfiguration[] = []
        let propertyConfigDecimal =  this.utils.GetPropertyInfo("MaxDecimalPlaces");
        let maxDecimalPlace : number = 2;
        if(Number(propertyConfigDecimal) >=0){
            maxDecimalPlace =  Number(propertyConfigDecimal);
        }
        result = result || [];

        result.forEach(element => {
            let taxConfig: TaxConfiguration = {
                id: element.id,
                outletId: element.outletId,
                outletName: element.outletName,
                taxName: element.taxName,
                isAmount: element.isAmount,
                taxAmount: element.taxAmount,
                taxAmountFormatted: this.localization.localizeCurrency(element.taxAmount, element.isAmount, element.isAmount ? 2 : maxDecimalPlace,!element.isAmount,!element.isAmount) + (!element.isAmount ? '%' : ''),
                startDate: this.localization.localizeDisplayDate(this.localization.getDate(element.startDate)),
                endDate: this.localization.localizeDisplayDate(this.localization.getDate(element.endDate)),
                allowTaxOnGratuity: element.allowTaxOnGratuity,
                allowTaxOnServiceCharge: element.allowTaxOnServiceCharge,
                isDefaultTax: element.isDefaultTax,
                hasTaxOnTax: element.hasTaxOnTax,
                linkedTaxes:element.hasTaxOnTax ?  element.linkedTaxes : [],
                isTaxRevenueToProperty: element.isTaxRevenueToProperty,
                taxUniqueId: element.taxUniqueId,
                allowRemovalForPaymentMethod: element.allowRemovalForPaymentMethod             
            }

            taxConfigs.push(taxConfig);
        });

        return taxConfigs;
    }

    public async getOutlets(id: number): Promise<Outlet[]> {
        let result: any = await this.InvokeTaxConfigurationCalls<any>(Host.retailManagement, "GetOutletsByProperty", HttpMethod.Get, undefined, { PropertyId: id }) || [];
        let outlets: any = []

        result.filter(r => r.isActive).forEach(element => {
            let OutletData: any = {
                id: element.subPropertyID,
                name: element.subPropertyName
            }
            outlets.push(OutletData);
        });

        return outlets;
    }

    public async createTaxConfiguration(body: TaxConfiguration): Promise<boolean> {
        let result = await this.InvokeTaxConfigurationCalls<number>(Host.retailManagement, "CreateTax", HttpMethod.Post, body);
        return result > 0;
    }

    public async updateTaxConfiguration(body: TaxConfiguration): Promise<boolean> {
        let result = await this.InvokeTaxConfigurationCalls<boolean>(Host.retailManagement, "UpdateTax", HttpMethod.Put, body, { id: body.id });
        return result || false;
    }

    public async deleteTaxConfiguration(id: number): Promise<TaxConfiguration[]> {
        try {
            return await this.InvokeTaxConfigurationCalls<TaxConfiguration[]>(Host.retailManagement, "DeleteTax", HttpMethod.Delete, undefined, { id: id });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }
    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage("Error", errMsg);
    }


    private async InvokeTaxConfigurationCalls<T>(host: Host, callDesc: string, callType: HttpMethod, body?: TaxConfiguration, uRIParams?: any): Promise<T> {

        try {
            let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
                callDesc: callDesc,
                host: host,
                method: callType,
                body: body,
                uriParams: uRIParams
            });

            return response.result;
        }
        catch (ex) {
            return null;
        }
    }


}
