import { PartyType, PartyState, PaymentMethod } from '../constants/commonenums';
import { PageMethod } from './RestaurantDTO';
import { PartyPrepaymentState, PartyChargeState, CoverTypeQuantityDTO } from './InputContact';

export class Party {
  Id: number = null;
  RestaurantId: number = null;
  ContactId: number = null;
  DateTime: Date = null;
  Type: PartyType = null;
  State: PartyState = null;

  Size: number = null;
  SeatingAreaId?: number = null;
  StaffIds: number[] = [];
  TableIds: number[] = [];
  TableNames: string[] = [];
  SeatingTime?: Date = null;
  DepartureTime?: Date = null;

  CreatedAt: Date = null;
  HostId?: number = null;
  StatusId?: number = null;
  PartySourceId?: number = null;
  ConfirmationCode: string = null;
  IsConfirmed: boolean = null;
  HotelId?: number = null;
  ConciergeId?: number = null;

  PagerNumber: string = null;
  PageMethod: PageMethod = null;
  LastPagedAt?: Date = null;

  // Walk-Ins
  QuotedSeatingTime?: Date = null;
  IsSeatingTimeOverriden: boolean = null;
  Position: number = null;
  NoShowFeeAmount: number = null;
  // Reservation
  SlotId?: number = null;
  SpecialMealId?: number = null;
  IsCCAssociated: boolean = null;
  CreditCardId?: number = null;
  SpecialMealAmount: number = null;
  PrepaymentState: PartyPrepaymentState = null;
  ChargeState: PartyChargeState = null;
  ChargedAt?: Date = null;
  RefundedAt?: Date = null;
  CoverTypeQuantityString: string = null;
  Covers: CoverTypeQuantityDTO[] = [];
  IsPartySelected: boolean = null;
  SeatingTypeId?: number = null;
  RatePlanAmount: number = null;
  RoomNumber?: string = null;
  BookingId?: string = null;
  DepositPaid?: number = null;
  StartDate: Date = null;
  EndDate: Date = null;
  SessionGroupId?: number = null;
  BookingTypeQuantities: any = [];
  BookedSessions: any = [];
  NegotiatedAmount: number = null;
  ServerId?: number = null;
  UniqueId: string = null;
  Charges: string = null;
  TaxAmount: number = null;
  PackageId: number = null;
  AddonItemAmount: number = null;
  AddonItemTaxAmount: number = null;
  WaiverFormURL: string = null;
  PackageDiscountAmount: number = null;
  ReferenceNumber: string = null;
  PaymentMethod: PaymentMethod = PaymentMethod.Prepayment;
  Comments: string = null;
  SalesContactIds: number[] = [];
  OverBooked: number = 0;
  CancellationFeeAmount?:number = null;
  CommonPackageInfo?: string = null;
  CoverTypeQuantities?: CoverTypeQuantityDTO[] = [];
  IsExclusiveBooking?:boolean = false;
}
