import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { LocalizedDatePipe } from '@app/shared/pipes/localize-date.pipe';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { SettingsService } from '@services/settings.service';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';
import { ISubscription } from 'rxjs/Subscription';
@Component({
  selector: 'app-date-range-selection',
  templateUrl: './date-range-selection.component.html',
  styleUrls: ['./date-range-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class DateRangeSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;

  inlineRange;
  selectedDate;
  startdate: Date;
  enddate: Date;
  maxdate: Date;
  mindate: Date;
  config: FieldConfig[];
  RecurrenceList: any = [];
  selectedIndex = 0;

  tabSubscription: ISubscription;
  FormSubscription: ISubscription;
  dateiserror = false;

  constructor(public partyService: PartyService, public dialog: MatDialog, private ps: PopupService, private appService: AppService,private lsPipe: LocalizedDatePipe,
    @Inject(COMPONENTINPUT) private data, private ss: SettingsService, private datePipe: DatePipe, private cs: CacheService) {
    this.datePipe = new DatePipe(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_userLocale`));
  }

  ngOnInit() {
    this.startdate = this.data ? new Date(this.data.StartDate) : this.partyService.reservationFormGroup.value.selectedDate;
    this.enddate = this.data ? new Date(this.data.EndDate) : this.partyService.reservationFormGroup.value.selectedDate;
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === DateRangeSelectionComponent);
    this.mindate = this.startdate;
    if (this.data) {
      this.inlineRangeChange({ 'begin': this.data.StartDate, 'end': this.data.EndDate })

    }

  }
  ngAfterViewInit() {

    this.tabSubscription = this.partyService.tabChange$.subscribe((index) => {
      if (index === this.selectedIndex) {
        this.reservationFormChange();
        this.partyService.reservationFormGroup.markAsDirty();
        this.partyService.reservationFormGroup.updateValueAndValidity();
      }
    });


  }
  getDateVal(waitDate) {

    let datec = Utilities.number_suffix(new Date(waitDate).getDate());
    return datec
  }

  ngOnDestroy() {
    if (this.tabSubscription)
      this.tabSubscription.unsubscribe();
    if (this.FormSubscription)
      this.FormSubscription.unsubscribe();


  }
  inlineRangeChange($event) {
    this.inlineRange = $event;
    this.selectedDate = this.inlineRange.begin;
    this.reservationFormChange();
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
  }



  checkdateispast(selecteddate?) {
    if (selecteddate) {
      let selectedtdate = new Date(selecteddate).setHours(0, 0, 0, 0);
      let currentdate = new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)).setHours(0, 0, 0, 0);
      if (!(selectedtdate < currentdate)) {
        this.dateiserror = true;
        return true;
      }

      this.dateiserror = false;
    }
    else {
      this.dateiserror = false;
    }
    return false;
  }

  reservationFormChange() {
    this.partyService.reservationFormGroup.get('selectedStartDate').setValue(this.inlineRange ? this.inlineRange.begin : null);
    this.partyService.reservationFormGroup.get('selectedEndDate').setValue(this.inlineRange ? this.inlineRange.end : null);
    this.checkdateispast(this.inlineRange ? this.inlineRange.end : null);
    if (this.selectedIndex >= 0) {
      let begindate = this.inlineRange ? this.inlineRange.begin : new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
      let endDate = this.inlineRange ? this.inlineRange.end : new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
      let range = this.lsPipe.transform(begindate, 'MMM DD') + '-' + this.lsPipe.transform(endDate, 'MMM DD');
      this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = range;
    }
    this.ps.formValid$.next(
      {
        isFormValid: this.getNextPageIsEnable(),
        currentTab: this.selectedIndex
      } as IFormValidDetails);
  }

  getNextPageIsEnable() {
    if (this.partyService.reservationFormGroup.value.selectedFrequency != null && this.partyService.reservationFormGroup.value.selectedFrequency != undefined && this.partyService.reservationFormGroup.value.selectedStartDate && this.partyService.reservationFormGroup.value.selectedEndDate && this.checkdateispast(this.partyService.reservationFormGroup.value.selectedEndDate)) {
      return true;
    }
    return false
  }



}

