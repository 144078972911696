<div class="retail-CustomDataTable retail-CustomDataTable-theme" id="table_{{SelectedSettingId}}"
  (window:resize)="viewCheckedFlag = false;calcShowMore();">
  <form class="w-100 h-100" [formGroup]="table">
    <div class="page-header" id="pageHeader" (window:resize)="calculateWidth()">
      <mat-form-field *ngIf="options ? options[0].Searchable : false" class="spa-searchbox searchpt custSearchbox"
        [floatLabel]="floatLabelNever" id="searchInput">
        <input #tableInput matInput autocomplete="off" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
          type="text" placeholder="{{captions.common.SearchBy}} {{PlaceHoldertext}}" value="" (keypress)="currentPage=1"
          (ngModelChange)="viewCheckedFlag=false">
        <i  aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor"
          (click)="searchText='';calwidthwithtimeout();viewCheckedFlag = false"></i>
      </mat-form-field>
      <!--Added the following for Retail Pages-->
      <div *ngIf="customHeader" class="custom-retail-inputs">
        <app-retail-table-header [headerName]="pageTitle" [setupFormGroup]="formGroupName" [isViewOnly]="IsViewOnly"
          [maxInputLength]="retailHeaderMaxLength" [minInputLength]="retailHeaderMinLength" [setupName]="setupName"
          [errorText]="errorMessage" [enableToggleButton]="enableToggleButton" [active]="captions.setting.Active"
          [isValidRoleName]="isValidRoleName" (buttonClick)="onButtonClick()" [headerButtonName]="customHeaderButton"
          [isCancelButtonAvailable]="isCancelAvailable" (inputChange)="checkValid($event)"
          (validToggleState)="isValidRoleName = true;" (buttonCancelClick)="onButtonCancelClick($event)"
          [searchText]="searchText" [cancelButtonName]="captions.retailsetup.CANCEL"
          [searchPlaceholderValue]="PlaceHoldertext" (searchInputChange)="searchInputValue($event)"
          [isEditFlag]="isEditModeSet" [inlineEditFlag]="inlineEditOn" (validSelectState)="isValidRoleName = true;"
          (searchKeyPress)="currentPage=1" (resetSearchValue)="clearSearchText()" [enableTerminal]="enableTerminal"
          [headerOptions]="headerOptions" [terminalID]="captions.setting.TerminalID"
          [terminalMissing]="captions.setting.MissingTerminalID" [skipValidation]="skipValidationHeaderComp"
          [showValidationErrPopup]="showErrForValidationHeaderComp">
        </app-retail-table-header>
      </div>
      <!--- end -->
    </div>
    <div class="fixed-table-container" id="fixed-table-container"
      [ngClass]="{'noSort': bodyArray.length < 1, 'custom-table-height': customHeader, 'table-height': !customHeader}">
    
        <label *ngIf="functionalities[ShowNoOfRecordInGrid]" class="ag_w--100 total-count-label text mr-2">
          {{totalMsg}} </label>
    
      <div class="w-100 grid-table h-100" id="SPACustomTable" [ngClass]="isScrollAvailable ? '': 'dynamic-scrollbar'"> 
          <virtual-scroller #scroll [items]="(bodyArray | filterPipe: searchText : hdrkeyArray)"
           style="height: calc(100% - 5px)" (vsEnd)="scrollEndHandler($event)"
           [ngClass]="{'hideScroll':hideScroll, 'scrollReset':freezeScroll}">
          <table aria-describedby="" class="table mb-0 inventory-setup-table">
            <thead #header>
              <tr id="SPAHeaderRow">
                <th scope='col'  scope='col'  *ngIf="EditMoreOption && SelectRow" class="pr-3 table-checkbox noellipsis">
                  <div class="th-inner">
                    <mat-checkbox attr.automationId='Chk_{{automationId}}_IsCheckAll'
                      [disabled]="isReadOnly || isEditModeSet || ((bodyArray | filterPipe: searchText : hdrkeyArray).length <= 0)"
                      (change)="RowSelect($event,bodyArray,'All')" formControlName="IsCheckAll"></mat-checkbox>
                  </div>
                </th>
                <th scope='col'  *ngFor="let hdrCnt of hdrArray;let hi=index" id="header{{hi}}"
                  [ngClass]="[hdrCnt.alignType=='right' ? 'text-right' : hdrCnt.alignType=='center' ? 'text-center':'',hdrCnt.sortable != undefined ? (hdrCnt.sortable ? '': 'pointerevents-none') : '']"
                  [class.cursordefault]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? false: true) : false"
                  [class.cursor]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? true: false) : true"
                  (click)="sortingFunc(hdrCnt.jsonkey,hi,'Frmtable', hdrCnt.sortcolumn, hdrCnt.sortcolumndatatype,hdrCnt.groupSort,hdrCnt.groupSortType,hdrCnt.groupSortJsonKey)">
                  <div class="th-inner"
                    [ngClass]="((bodyArray | filterPipe: searchText : hdrkeyArray).length != 0 && (selectedDefaultHeader == hdrCnt.jsonkey) && (hdrCnt.sortable != undefined ? hdrCnt.sortable : (selectedDefaultHeader == hdrCnt.jsonkey && bodyArray.length > 0)) ) ? 'font-weight':''">
                    {{hdrCnt.title}}
                    <span class="pl-1"
                      *ngIf="(hdrCnt.jsonkey != '' && hdrCnt.sortable == true) && ((bodyArray | filterPipe: searchText : hdrkeyArray).length != 0)">
                      <i aria-hidden="true"  id="sortArrow{{hi}}"
                        *ngIf="hdrCnt.jsonkey != '' && hdrCnt.sortable != undefined ? hdrCnt.sortable : true && (bodyArray | filterPipe: searchText : hdrkeyArray).length != 0"
                        [ngStyle]="{'opacity':selectedDefaultHeader == hdrCnt.jsonkey && !isEditModeSet ? '1' : '0.3' }"
                        [ngClass]="selectedDefaultHeader == hdrCnt.jsonkey && orderType == 'asc' ? 'icon-filled_up_arrow position-absolute deg0 IC6': selectedDefaultHeader == hdrCnt.jsonkey && orderType =='desc' ?
                        'icon-filled_up_arrow deg180 position-absolute IC6': 'icon-sortArrow sort-icon'">
                      </i>
                    </span>
                  </div>
                </th>
                <th  scope='col'  style="width: 25px;" *ngIf="SelectedSettingId != GridType.retail"></th>
                <th scope='col'  *ngIf="!isRoleSetUpReadOnly && EnableActions"
                  [ngClass]="{'column-sticky': sticky, 'minwidth177':DoneCancel, 'minwidth100':!DoneCancel}">
                  <div class="th-inner  ">{{captions.setting.Actions}}</div>
                </th>
              </tr>
            </thead>
            <tbody formArrayName="tablebody" #container>
              <tr draggable [dragEnabled]="blnDraggable" [dragData]="bodyCnt" [dragHandle]="'.draggable'" class="cust-row"
                [dropEnabled]="blnDraggable" droppable (OnDragOver)="onDragOver($event,bodyCnt,bodyArray)"
                (onDrop)="onItemDrop($event,bodyCnt,bodyArray)"
                [ngClass]="{'highlight' :((NewerData[0] === bodyCnt) || (SelectedData === bodyCnt) || bodyCnt['isEdit']), 'onEdit':isEdit, 'editRow':editableRow == bodyCnt.id, 'rowDisabled': (enableRowCheck | enablerow : bi : options : editEvent) }"
                id="{{bodyCnt.id}}"
                *ngFor="let bodyCnt of scroll.viewPortItems;let bi=index;"
                [formGroupName]="bi"> 

                <td class="pr-3 table-checkbox noellipsis">
                  <mat-checkbox attr.automationId='Chk_{{automationId}}_Check{{bi}}' (change)="RowSelect($event,bodyCnt,'Single')" class="suppress-z" [disabled]="isEditModeSet"
                    [checked]="SelectedData.indexOf(bodyCnt) != -1" [disabled]="isReadOnly || bodyCnt.checkbox">
                  </mat-checkbox>
                </td>
                <td class="text-right" [ngClass]="IsViewOnly || isEditModeSet ? 'button_invalid' : ''">{{bodyCnt.itemNumber}} <span class="w-20 ag_display--inflex pr-2"><i *ngIf="bodyCnt.isExternal" [ngClass]="{'icon-inventory_item_eatec' : bodyCnt.isExternal}"></i></span></td>
                <td [ngClass]="IsViewOnly || isEditModeSet ? 'button_invalid' : ''">{{bodyCnt.itemName}}</td>
                <td [ngClass]="IsViewOnly || isEditModeSet ? 'button_invalid' : ''">{{bodyCnt.categoryName}}</td>
                <td [ngClass]="IsViewOnly || isEditModeSet ? 'button_invalid' : ''" class="text-right">{{bodyCnt.salesPrice | Currency}}</td>
                <td *ngIf="!isEatec" [ngClass]="IsViewOnly || isEditModeSet ? 'button_invalid' : ''" class="text-right">{{bodyCnt.quantityOnHand}}</td>
                <td [ngClass]="IsViewOnly || isEditModeSet ? 'button_invalid' : ''" class="text-right">{{bodyCnt.itemPar}}</td>
                <td>
                  <div [ngClass]="IsViewOnly || isRoleSetUpReadOnly || bodyCnt.isDefault ? 'button_invalid' : ''">
                   
                    <ui-switch class="LW14 switch-toggle suppress-z" [(ngModel)]="bodyCnt.isInActive" [checked]="bodyCnt.isInActive"
                      [ngModelOptions]="{standalone: true}" [disabled]="isEditModeSet && edittedIndex != bi || bodyCnt.isExternal"
                      size="small" checkedLabel={{localization.captions.common.Yes}}
                      uncheckedLabel={{localization.captions.common.No}}
                      (change)="showInactiveRoles('isInActive', bodyCnt, bi)" [name]="toggleDisplayText"></ui-switch>
                  </div>
                </td>
                <td  *ngIf="!isEatec"  class="text-right">{{bodyCnt.costPrice | Currency}}</td>
                <td  *ngIf="isEatec" class="text-right">{{bodyCnt.weightedAverageCost | Currency}}</td>
                <td>
                  <span>
                    <input attr.automationId='Txt_{{automationId}}_PrintQuantity{{bi}}' [(ngModel)]="bodyCnt['printqty']" [ngModelOptions]="{standalone: true}" type="text"
                      id="printqty{{bi}}" inputtype="nonnegative" [disabled]="isEditModeSet"
                      (input)="fieldEdit($event,bodyCnt,'printqty',bi)" name="printqty{{bi}}" [maxlength]="4">

                  </span>
                </td>
                <td *ngIf="!isRoleSetUpReadOnly && EnableActions && !(SelectedSettingId == GridType.password)"
                  [ngClass]="{'column-sticky': sticky,'minwidth177':DoneCancel,'minwidth100':!DoneCancel}"
                  class="noellipsis">

                  <button attr.automationId='Btn_{{automationId}}_Print{{bi}}' class="icon-print cursor pr-2"
                    [ngClass]="(IsViewOnly && IsRetailCodeSetup)||disableEditButton || bodyCnt.isDefault || isEditModeSet ? 'button_invalid' : ''"
                    *ngIf="editable && !table.value.tablebody[bi].donecancel" title="{{captions.common.Print}}"
                    (click)="editRow($event,bodyCnt,'print',options[0].ServiceId)"></button>

                  <!-- Icon Edit -->
                  <button attr.automationId='Btn_{{automationId}}_Edit{{bi}}' class="icon-Edit cursor pr-2"
                    [ngClass]="(IsViewOnly && IsRetailCodeSetup)||disableEditButton || bodyCnt.isDefault || isEditModeSet ? 'button_invalid' : ''"
                    *ngIf="editable && !table.value.tablebody[bi].donecancel" title="{{captions.common.Edit}}"
                    (click)="editRow($event,bodyCnt,'edit',options[0].ServiceId)"></button>

                  <!-- Icon Delete -->
                  <button attr.automationId='Btn_{{automationId}}_Delete{{bi}}' *ngIf="!disableDelete && !table.value.tablebody[bi].donecancel" class="icon-Delete cursor pr-2"
                    [ngClass]="IsViewOnly || bodyCnt.isDefault ? 'button_invalid' : ''"
                    title="{{captions.common.Delete}}" [disabled]="isEditModeSet"
                    (click)="DeleteRecords(bodyCnt)"></button>
                  <button attr.automationId='Btn_{{automationId}}_Drag{{bi}}' class="icon-DragAndDrop movecursor draggable"
                    [disabled]="bodyArray.length == 1 || isEditModeSet" [ngClass]="{'button_invalid': IsViewOnly}"
                    *ngIf="blnDraggable && !table.value.tablebody[bi].donecancel"
                    title="{{captions.common.Drag}}"></button>
                  <span *ngIf="table.value.tablebody[bi].donecancel" class="done" (click)="Done(bodyCnt, bi)">
                    <i attr.automationId='Lbl_{{automationId}}_Done{{bi}}' aria-hidden="true" class="icon-done pr-1"></i>{{captions.common.cDone}}</span>
                  <span *ngIf="table.value.tablebody[bi].donecancel" class="cancel" (click)="Cancel(bodyCnt, bi)">
                    <i  attr.automationId='Lbl_{{automationId}}_Cancel{{bi}}' aria-hidden="true" class="icon-Cancel pr-1"></i>{{captions.common.cCancel}}</span>
                </td>


              </tr>
              <tr *ngIf="(bodyArray | filterPipe: searchText : hdrkeyArray).length == 0 && !isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1"
                  class="text-center">
                  {{captions.common.NoDataFound}} </td>
              </tr>
              <tr *ngIf="isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1"
                  class="text-center">{{captions.common.loadingData}}</td>
              </tr>
            </tbody>
          </table>
        </virtual-scroller> 
      </div>
    </div> 
  </form>
</div>
