import { Pipe, PipeTransform } from '@angular/core';
import { PartyState, PartyType } from '@constants/commonenums';

@Pipe({
  name: 'reservationStatus'
})
export class ReservationStatusPipe implements PipeTransform {
  color: any;
  transform(state: any, reservationDetails: string): any {
    if (reservationDetails === 'status') {
      switch (state.toString().toLowerCase()) {
        case '0':
          status = 'reservedState';
          break;
        case 'arrived':
          status = 'arrivedState';
          break;
        case '1':
          status = 'seatedState';
          break;
        case '2':
          status = 'leftState';
          break;
        case '4':
          status = 'cancelledState';
          break;
        case 'non operating hours':
          status = 'non-operating';
          break;
        case '3':
          status = 'noShowState';
          break;
        case PartyState.Open.toString():
          status = 'openState';
          break;
        case PartyState.Blocked.toString():
          status = 'blockedTable';
          break;
      }
      return status;
    } else if (reservationDetails === 'color') {
      switch (state.toString().toLowerCase()) {
        case '0':
          this.color = '#5093E1';
          break;
        case 'arrived':
          this.color = '#ED1446';
          break;
        case '1':
          this.color = '#9CC62B';
          break;
        case '4':
          this.color = '#A1A6B4';
          break;
        case 'non operating hours':
          this.color = '';
          break;
        case '3':
          this.color = '';
          break;
        case PartyState.Open.toString():
          this.color = '#A1A6B4';
          break;
        case PartyState.Blocked.toString():
          this.color = '#C1832E';
          break;
      }
      return this.color;
    }
    else if (reservationDetails === 'historyStatus') {
      switch (state.toString().toLowerCase()) {
        case '0':
          status = 'reservedState';
          break;
        case '1':
          status = 'seatedState';
          break;
        case '2':
          status = 'leftState';
          break;
        case '4':
          status = 'cancelledState';
          break;
        case '3':
          status = 'noShowState';
          break;
        default:
          status = 'reservedState';
          break;
      }
      return status;
    }
    // if (!value) return  items;
    // if (value == '' || value == null) return [];
    // return items.filter(e => e.toLowerCase().indexOf(value) > -1 );
  }

}

@Pipe({
  name: 'reservationBookingStatus'
})
export class ReservationBookingStatusPipe implements PipeTransform {
  transform(reservation: any): any {
    let state;

    if (reservation.Type === PartyType.StandBy) {
      state = 'confirmed'
    } else if (reservation.Type === PartyType.StandBy) {
      state = 'standby'
    } else if (reservation.Type === PartyType.WalkIn) {
      state = 'standby'
    }

    return state;
  }
}
