import { Inject } from '@angular/core';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { COMPONENTINPUT } from '@app/popup-module/popup.service';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { FeedbackDTO } from '@app/shared/models/mappers/FeedbackDTO';
import { GuestBookService } from '@app/shared/services/guestbook.service';
import { PartyService } from '@app/shared/services/party.service';
import { buttonTypes, PartyType, SlotType, TableBlockingRuleFrequencyType } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { AuditableActionType, AuditableEntityType, AuditLogItemDetailType, SimpleAuditLogItemDTO } from '@models/SimpleAuditLogItemDTO';
import { FacadeService } from '@services/facade.service';
import { Utilities } from '@utilities/utilities';
import { Subscription } from 'rxjs';
import { ISubscription } from 'rxjs-compat/Subscription';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicFormService } from '@app/shared/dynamicform/service/dynamic-form.service';
import { MyErrorStateMatcher } from '@dynamicform/models/error-matcher';

@Component({
  selector: 'app-feedbackdetails',
  templateUrl: './feedbackdetails.component.html',
  styleUrls: ['./feedbackdetails.component.scss']
})
export class FeedbackDetailsComponent implements OnInit, OnDestroy {
  stars = [1, 2, 3, 4, 5];
  feedbacks: any;
  pastResv: any;
  restaurantSettings: any;
  reviews: any;
  managerconfig: FieldConfig[];
  managerComment: any;
  actionBtn: ButtonValue;
  pageSubscription: ISubscription;
  cmdId: number;
  feedbackval: FeedbackDTO;
  btnDisable = true;
  btnTextboxdisabled=true;
  tagValidation:boolean = false;

  constructor(public fs: FacadeService, public _gbs: GuestBookService, public partyService: PartyService, private appService: AppService, private cs: CacheService,private dynamicFormService:
    DynamicFormService) { }
    matcher = new MyErrorStateMatcher();
    
  ngOnDestroy(): void {
    if (this.pageSubscription)
      this.pageSubscription.unsubscribe();
  }

  ngOnInit() {
    this.feedbacks = this.partyService.feedbackDetails;
    this.pastResv = this.partyService.pastReservationsData;
    this.pageSubscription = this.cs.settings.subscribe(sett => {
      this.restaurantSettings = sett
    });
    this.restaurantSettings = this.cs.settings.value;
    this.btnDisable = true;
    this.btnTextboxdisabled=true;
    // console.log(this.feedbackData);
    this.reviews = [];
    for (let i = 0; i < this.restaurantSettings.FeedbackQuestionaries.length; i++) {
      for (let j = 0; j < this.pastResv.Feedbacks.length; j++) {
        if (this.restaurantSettings.FeedbackQuestionaries[i].Id == this.pastResv.Feedbacks[j].FeedbackQuestionId) {
        let newRating=  this.restaurantSettings.FeedbackQuestionaries[i];
        newRating['Rating']=this.pastResv.Feedbacks[j].Rating;
        newRating['type']='rating';
          this.reviews.push(newRating);
  
        }
      }
    }
    let comments = this.pastResv.Feedbacks.filter(x => x.FeedbackQuestionId == null);
    let cmtVal = "None";
    let mgrcmt = "";
    if (comments.length > 0) {
      if (comments[0].GuestComments) {
        cmtVal = comments[0].GuestComments;
      }
      this.reviews.push({ type: 'question', value: cmtVal, label: 'Any Suggestion' });
      this.cmdId = comments[0].Id;
      mgrcmt = comments[0].ReviewerComments;
      if (comments[0].StatusCode == 3) {
        this.btnDisable = true;
        this.btnTextboxdisabled=true;
        this._gbs.feedbackActionTacken = true;
      }else{
        this.btnDisable = true;
        this.btnTextboxdisabled=false;
        
      }
    }

    this.managerComment = mgrcmt;
    this.managerconfig = [
      {
        type: 'textarea',
        name: 'manager comment',
        placeholder: '',
        label: 'Manager\'s comment',
        rows: 3,
        inputType: 'text',
        showHint: false,
        charLength: 200,
        value: mgrcmt,
        class: 'dayNoteText pt-5 pl-2',
      }
    ];
    this.actionBtn = {
      type: buttonTypes.actionPrimarySmall,
      label: 'Action Taken',
      disbaledproperity: false,
      customclass: 'action-taken-btn',
    };
  }
  textChanged() {
    if(this.managerComment && !this.dynamicFormService.inputvalidation(this.managerComment))
    {
      this.btnDisable = false;
    }else{
      this.btnDisable = true;
    }

  }

  submit() {
    const requestedPayload = {} as FeedbackDTO;
    requestedPayload.Id = this.cmdId;
    requestedPayload.ReviewerComments = this.managerComment;
    requestedPayload.StatusCode = 3;
    this.partyService.postFeedBackMessage(requestedPayload).subscribe(obj => {
      this._gbs.Feedbackaction.next(false);
      this.pastResv.Feedbacks.forEach(element => {
        if (element.Rating == 0 && element.FeedbackQuestionId == null) {
          element.StatusCode = 3;
          element.ReviewerComments = this.managerComment;
        }
      });

      this._gbs.feedbackActionTacken = true;
    });
  }

}
