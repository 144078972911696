<div class="date-range-selection d-flex">
    <div  style="height: 80%;width: 20%;">
        <span
        class="date-range-selection__select-text reservation-select-label seat-panel-header seat-header-bold">{{'selectedDate'| translate}}</span>
        
       <!--  <span class="date-range-selection__select-day">{{inlineRange.begin | localizedDate: 'dddd'}}</span> -->
        <span class="seat-section-header d--bock">{{selectedDateRange.begin | localizedDate: 'MMM DD'}}{{getDateVal(selectedDateRange.begin) | lowercase }} {{selectedDateRange.begin | localizedDate : "YYYY"}}</span>
        <span class="seat-section-header d-block" >{{'toText' | translate}}</span>
        <!-- <span class="date-range-selection__select-day">{{inlineRange.end | localizedDate: 'dddd'}}</span> -->
        <span class=" seat-section-header d-block">{{selectedDateRange.end | localizedDate: 'MMM DD'}}{{getDateVal(selectedDateRange.end) | lowercase }} {{selectedDateRange.end | localizedDate:"YYYY"}} </span>
      
      </div>
    <section class="text-center" style="width: 60%;">

    <!-- <label class="pb-2 seat-header-bold text-center">{{'DateRange' | translate}}</label> -->
    <div class="date-view">
        <app-date-selection-with-range [selected]="selectedDateRange"
            (dateRangesChange)="inlineRangeChange($event)"></app-date-selection-with-range>
    </div>
</section>
    <div class="" style="width: 20%;">
        <label class="pb-2 seat-header-bold">{{'Application' | translate}}</label>
        <dynamic-form [config]="applicationSelectConfig" #applicationSelectForm></dynamic-form>
        <label class="pb-2 seat-header-bold mt-2">{{'Reason' | translate}}</label>
        <dynamic-form [config]="reasonForBlockConfig" #reasonForm></dynamic-form>
    </div>
</div>