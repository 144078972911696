import { Pipe, PipeTransform } from '@angular/core';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { RolesPermissionDTO } from '@models/RestaurantDTO';

@Pipe({
  name: 'switchInputFormater'
})
export class SwitchInputFormater implements PipeTransform {
  switchFormat: FieldConfig[];
  transform(config: RolesPermissionDTO, _name: string, isChecked: boolean): any {
    this.switchFormat = [{
      name: _name,
      type: 'switch',
      checked: isChecked,
      value: isChecked
    }];
    return this.switchFormat;
  }
}
