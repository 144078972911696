
export class RatePlanSummaryDTO {
    Sessions: SessionPaymentDTO[]
}


export class PaymentBase {
    Price: number;
    Discount: number
    Tax: number
    Taxes: BookingChargeItem[];
    ServiceCharge: number
    ServiceCharges: BookingChargeItem[];
}

export class SessionPaymentDTO {
    TimeRange: TimeRangeDTO;
    SessionDateMap: SessionDateMap;
    GuestPrices: GuestPrice[];
    ReservationPrice: number;
    AddonPrice: number;
}

export class GuestPrice {
    GuestId: number;
    GuestName: string;
    RateType: string;
    Items: PaymentItemDTO[];
}

export class PaymentItemDTO extends PaymentBase {

    Id: number;
    Index: number;
    Type: PaymentItemType;
    ItemName: string;
    Count: number;
    UnitPrice: number;
    PastUnitPrice: number;
    PayableUnitPrice: number;

}


export class SessionDateMap {

    Date: Date;
    SessionId: number;
}


export enum PaymentItemType {
    Tax,
    ServiceCharge,
    Negotiation,
    MinRateCoverTypeAdjustment,
    BasePrice,
    Location,
    Staff,
    /// <summary>
    /// Guest type
    /// </summary>
    CoverType,
    BookingType,
    Addon,
    SpecialMeal,
    SpecialMealCovers,
    MembershipDiscount,
    AdditionalRate,
    CancellationFee,
    NoShowFee,
    MembershipRound,
    PackageDiscount,
    PromoCodeDiscount

}

export class BookingChargeItem
    {
        ChargeId: number;
        IsPercentage: boolean;
        ChargeValue: number;
        ChargeName: string;
        ApplyBeforeTax: boolean;
        Amount: number;
        TaxOnBookingChargeAmount: number;
        Taxes: BookingChargeItem[];
    }

    export class TimeRangeDTO{
        Start: string;
        End: string;
        Length: string;
    }