import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppService } from '@app/app.service';
import { Menu, PartyState, PartyType, TableBlockingRuleFrequencyType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { PartyService } from '@services/party.service';
import { ServerService } from '@services/server.service';
import { TablesService } from '@services/tables.service';
import { Utilities } from '@shared/utilities/utilities';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';


@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DashboardHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('dataContainer') el: ElementRef;
  dashboardHeaderCounts$ = new BehaviorSubject<any[]>(null);
  name: string;
  menuName: string;
  showDashboardHeader = false;
  showNavigation = false;
  waitlistBgColor = '#D09F33';
  waitlistBorderColor = '#C1832E';
  reservationDefaultColor = '#C8C9CA';
  reservationBgColors = ['#32A6D5', '#F27935', '#4D4698', '#DCEA06', '#8206EA', '#E306EA', '#EA9B06'];
  reservationBorderColors = ['#2D97C3', '#DB6929', '#423C81', '#DCEA06', '#8206EA', '#E306EA', '#EA9B06'];
  serverBgColor: string = '#D09F33';
  serverBorderColor: string = '#C1832E';
  @Input() isTableCountView: boolean;
  subscriptions: Subscription = new Subscription();
  constructor(public _as: AppService, private _ps: PartyService, public ts: TranslateService, public ss: ServerService, public cs: CacheService,
    private tableService: TablesService) {
    this.menuName = Utilities.getMenuNameById(this._as.selectedMenuId);
  }

  ngOnInit() {
    switch (this._as.selectedMenuId) {
      case Menu.Reservation:
        this.setReservationDashboardHeaderCount();
        break;
      case Menu.WaitList:
        this.setWaitlistDashboardHeaderCount();
        break;
      case Menu.Servers:
        this.setServersDashboardHeaderCount();
        break;
      case Menu.Tables:
        this.setTablesDashboardHeaderCount();
        break;
      default:
    }
  }

  setReservationDashboardHeaderCount() {
    this.subscriptions.add(this._ps.Parties$.subscribe(parties => {
      let countInfo: any[] = [];
      this.subscriptions.add(this._ps.shifts$.subscribe(shifts => {
        if (!shifts) {
          return;
        }
        countInfo = [];
        shifts.forEach((shift, index) => {
          //ToDo: for current date only count should be updated
          let count: number = parties ? parties.filter(party => party.State !== PartyState.Cancelled && party.ReservedFor >= shift.EffectiveRange.Start && party.ReservedFor <= shift.EffectiveRange.End).length : 0;
          const bgColor = index < this.reservationBgColors.length ? this.reservationBgColors[index] : this.reservationDefaultColor;
          const borderColor = index < this.reservationBorderColors.length ? this.reservationBorderColors[index] : this.reservationDefaultColor;
          countInfo.push({ Name: shift.Name, Count: count, bgColor: bgColor, borderColor: borderColor });
        });
      }));
      this.setDashboardHeaderCount(countInfo);
    }));
  }

  setWaitlistDashboardHeaderCount() {
    this.subscriptions.add(this._ps.Parties$.subscribe(parties => {
      let countInfo: any[] = [];
      if(parties)
      {
        parties = parties.filter(party => party.Type === PartyType.WalkIn && (party.Contact?.FirstName || party.Contact?.LastName) && (party.State === PartyState.Pending || party.State === PartyState.Seated));
      }
      let partiesLessThan2: number = parties ? parties.filter(party => party.Size <= 2).length : 0;
      let parties3to6: number = parties ? parties.filter(party => party.Size > 2 && party.Size <= 6).length : 0;
      let partiesAbove6: number = parties ? parties.filter(party => party.Size > 6).length : 0;
      countInfo.push({ Name: '1-2', Count: partiesLessThan2, bgColor: this.waitlistBgColor, borderColor: this.waitlistBorderColor });
      countInfo.push({ Name: '3-6', Count: parties3to6, bgColor: this.waitlistBgColor, borderColor: this.waitlistBorderColor });
      countInfo.push({ Name: (this.ts.instant('above')+ ' ' + '6'), Count: partiesAbove6, bgColor: this.waitlistBgColor, borderColor: this.waitlistBorderColor });
      this.setDashboardHeaderCount(countInfo);
    }));
  }

  setServersDashboardHeaderCount() {
    let countInfo: any;
    if (!this.isTableCountView) {
      this.subscriptions.add(this.ss.serverDetails.subscribe(details => {
        const totalAssignedServers = details.filter((server) => (server.StandaloneTables != null && server.StandaloneTables.length > 0) && !server.IsTemplate).length;
        const totalFreeServers = details.filter((server) => (server.StandaloneTables == null || server.StandaloneTables.length === 0) && !server.IsTemplate).length;
        const availableServers = details.filter((server) => !server.IsTemplate).length;
        countInfo = [
          { Name: this.ts.instant('totalServers'), bgColor: this.serverBgColor, borderColor: this.serverBorderColor, Count: availableServers },
          { Name: this.ts.instant('servercirclecomponentlabel2'), bgColor: this.serverBgColor, borderColor: this.serverBorderColor, Count: totalAssignedServers },
          { Name: this.ts.instant('freeText'), bgColor: this.serverBgColor, borderColor: this.serverBorderColor, Count: totalFreeServers }];
        this.setDashboardHeaderCount(countInfo);
      }));
    } else {
      this.subscriptions.add(this.cs.layout.subscribe(_lyt => {
        let totalTables = 0;
        let assignedTables = 0;
        let unAssignedTables = 0;
        let CurrentFloorPlan = _lyt.FloorPlans.filter(x => x.Id == this.ss.currentLayoutId);
        CurrentFloorPlan.forEach(fp => {
          totalTables = totalTables + fp.StandaloneTables.length;
          assignedTables = assignedTables + fp.StandaloneTables.filter(table => table.ServerId != null).length;
          unAssignedTables = unAssignedTables + fp.StandaloneTables.filter(table => table.ServerId == null).length;
        });
        countInfo = [
          { Name: this.ts.instant('totalTables'), bgColor: this.serverBgColor, borderColor: this.serverBorderColor, Count: totalTables },
          { Name: this.ts.instant('assignedTables'), bgColor: this.serverBgColor, borderColor: this.serverBorderColor, Count: assignedTables },
          { Name: this.ts.instant('unassigned'), bgColor: this.serverBgColor, borderColor: this.serverBorderColor, Count: unAssignedTables }];
        this.setDashboardHeaderCount(countInfo);
      }));
    }
  }

  setDashboardHeaderCount(countinfo: any) {
    this.dashboardHeaderCounts$.next(countinfo);
    this.showDashboardHeader = true;
  }

  setTablesDashboardHeaderCount() {
    this.subscriptions.add(this._ps.Parties$.subscribe(parties => {
      this.setTablesHeaderCount();
    }));
    this.subscriptions.add(this.tableService.tableBlockingRules$.subscribe(tables => {
      this.setTablesHeaderCount();
    }));
    this.subscriptions.add(this.tableService.ChangeLayout.subscribe(shiftId => {
      this.setTablesHeaderCount();
    }));
  }

  setTablesHeaderCount() {
    let parties = this._ps.Parties$.value;
    let tablesCount = _.spread(_.union)(_.map(parties.filter(x => x.State == PartyState.Seated), 'TableIds'));
    let uniqTables = _.uniq(tablesCount);
    let blockedTables = this.tableService.tableBlockingRules$.value;
    if (this.tableService.selectedShift) {
      blockedTables = blockedTables?.filter(val => {
        if(val.Shifts){
        if (val.Shifts.filter(shift => {
          if (shift.Id == this.tableService.selectedShift) {
            return shift;
          }
        }).length > 0) {
          return val;
        }
      }
      });
      if (this.tableService.isCustomFloorSelected) {
        blockedTables = blockedTables?.filter(val => {
          if (val.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay) {
            return true;
          }
        });
      }
    }
    let blockedtablesCount = _.spread(_.union)(_.map(blockedTables, 'TableIds'));
    let seatedBlockedTables = _.intersection(blockedtablesCount, uniqTables);
    let tableBlockingCopy = _.cloneDeep(blockedTables);
    seatedBlockedTables.forEach(element => {
      tableBlockingCopy.forEach((value, index) => {
        let objIndex = value.TableIds.findIndex((obj => obj == element));
        tableBlockingCopy[index].TableIds.slice(objIndex);
      });
    });
    let countInfo = [
      { Name: 'freeText', Count: this.tableService.standAloneTables.length - uniqTables.length, bgColor: this.waitlistBgColor, borderColor: this.waitlistBorderColor },
      { Name: 'occupiedText', Count: uniqTables.length, bgColor: this.waitlistBgColor, borderColor: this.waitlistBorderColor },
      { Name: 'blockedText', Count: _.uniq(_.spread(_.union)(_.map(tableBlockingCopy, 'TableIds'))).length, bgColor: this.waitlistBgColor, borderColor: this.waitlistBorderColor }];
    this.dashboardHeaderCounts$.next(countInfo);
    this.showDashboardHeader = true;
  }

  ngAfterViewInit() {
    if (this.el && this.el.nativeElement && this.el.nativeElement.scrollWidth > this.el.nativeElement.offsetWidth) {
      this.showNavigation = true;
    }
    else {
      this.showNavigation = false;
    }
  }

  ngOnDestroy(): void {
    this.showDashboardHeader = false;
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  setColors(data: any, bgColor: string, borderColor: string) {
    data.bgColor = bgColor;
    data.borderColor = borderColor;
  }

  navigateLeft() {
    let element = this.el.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      element.scrollLeft = element.scrollLeft - 106;
    }
  }

  navigateRight() {
    let element = this.el.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      element.scrollLeft = element.scrollLeft + 106;
    }
  }
}
