import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'headerSeatingAreaFilter'
})
export class HeaderSeatingAreaFilterPipe implements PipeTransform {

  transform(allParties: any, seatingAreaId: number): any {
    if (seatingAreaId > 0) {
      return allParties.filter(party => party.SeatingAreaId == seatingAreaId);
    }
    else {
      return allParties;
    }
  }
}
