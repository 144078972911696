import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlMatchResult, UrlSegment } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from '../layout/layout.component';
//import { AuthGuardService } from '@core/services/auth-guard.service';
//import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { PageNotFoundComponent } from './pagenotfound/page-not-found.componenet';
import { CacheService } from './services/cache.service';
import { SettingsModule } from '@app/settings/settings.module';
import { AuthGuardService } from './services/auth-guard.service';
import { WidgetComponent } from './widget/widget.component';
import { HelpComponent } from '@app/layout/help/help.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    //canActivate: [AuthGuardService]
  },
  {
    path: 'sso',
    loadChildren: () => import('./sso/sso.module').then(m => m.SsoModule),
    // component: SsoComponent,
  },
  {
    path: 'help',
    component: HelpComponent,
    title: 'Agilysys Reserve Help'
    // component: SsoComponent,
  },
  //{
  //  path: 'auth-callback',
  //  component: AuthCallbackComponent
  //},
  {
    path: '',
    component: LayoutComponent,
    title: 'Agilysys Reserve',
    children: [
      {
        path: 'pos',
        loadChildren: () => import('../pos/pos.module').then(m => m.POSModule),
        canLoad: [CacheService],
        title: 'Agilysys Reserve - POS'
      },
      {
        path: 'reservation',
        loadChildren: () => import('../reservation/reservation.module').then(m => m.ReservationModule),
        data: { state: 'reservation' },
        canLoad: [CacheService]
      },
      {
        path: 'waitlist',
        loadChildren: () => import('../waitlist/waitlist.module').then(m => m.WaitlistModule),
        data: { state: 'waitlist' },
        canLoad: [CacheService]
      },
      {
        path: 'guestbook',
        loadChildren: () => import('../guestbook/guestbook.module').then(m => m.GuestbookModule),
        data: { state: 'guestbook' },
        canLoad: [CacheService]
      },
      {
        path: 'reports',
        loadChildren: () => import('../reports/reports.module').then(m => m.ReserveReportsModule),
        data: { state: 'reports' },
        canLoad: [CacheService],
        // canActivate: [AuthGuardService]
      },
      {
        path: 'settings',
        loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule),
        data: { state: 'settings' },
        canLoad: [CacheService],
        //canActivate: [AuthGuardService]
      },
      {
        path: 'tables',
        loadChildren: () => import('../tables/tables.module').then(m => m.TablesModule),
        data: { state: 'tables' },
        canLoad: [CacheService]
      },
      {
        path: 'timeline',
        loadChildren: () => import('../timeline/timeline.module').then(m => m.TimelineModule),
        canLoad: [CacheService]
      },
      {
        path: 'floorplan',
        loadChildren: () => import('../floorplan/floorplan.module').then(m => m.FloorplanModule),
        canLoad: [CacheService],
        canActivate: [AuthGuardService]
      },
      {
        path: 'notifications',
        loadChildren: () => import('../notifications/notifications.module').then(m => m.NotificationsModule),
        canLoad: [CacheService]
      },
      {
        path: 'servers',
        loadChildren: () => import('../servers/servers.module').then(m => m.ServersModule),
        canLoad: [CacheService],
        // canActivate: [AuthGuardService]
      },
      {
        path: 'activities-timeline',
        loadChildren: () => import('../activities-timeline/activities-timeline.module').then(m => m.ActivitiesTimelineModule),
        canLoad: [CacheService],
        canActivate: [AuthGuardService],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'crs',
        loadChildren: () => import('../crs/crs.module').then(m => m.CRSModule),
        canLoad: [CacheService],
      },
      {
        path: 'switch',
        loadChildren: () => import('../switch-property/switch-property.module').then(m => m.SwitchPropertyModule),
        canLoad: [CacheService],
        // canActivate: [AuthGuardService]
      },
      {
        path: 'shop',
        loadChildren: () => import('../retail/shop/shop.module').then(m => m.ShopModule),
        canLoad: [CacheService],
        canActivate: [AuthGuardService]
      },
      {
        path: 'audit/dayend',
        redirectTo: "/settings/audit/dayend",
      },
      {
        path: 'audit/nightaudit',
        redirectTo: "/settings/audit/nightaudit",
      },
    ],
  },
  {

    matcher: iframeMatch,
    component: WidgetComponent,
    canLoad: [CacheService]

  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
export function iframeMatch(url: UrlSegment[]): UrlMatchResult {
  if (url.find(x => x.path == "activitiesdivscreen")) {
    return {
      consumed: url
    };
  }
  return null;
}