<section class="retail-code-setup newVersionWrapper retail-payment-method-wrapper h-100 " id="paymentMethod">
  <app-table-search-header [searchHeaderOption]="searchHeaderOption" (searchChange)="searchChange($event)"
 (createType)="createEvent($event)">
</app-table-search-header>
  <div class="retailGrid" [attr.automationId]="'Tbl_paymentMethods_paymentTable'">
      <app-retail-table [searchvalue]="searchText" [options]="tableoptions"  (dragDropEvt)="dragDrop($event)"
          (deleteEvt)="DeleteRecords($event)" (addClick)="addPayment($event,type)" (editEvt)="EditRecords($event)"  (cancelEmitter)="Cancel($event)"
          (toggleEvtEmitter)="sliderChange($event)" (doneEvtEmitter)="Done($event)" [isOnEditDisableFunctionAvailable]="onEditDisableFunction" [isEditModeSetFromParent]="isEditFlag"></app-retail-table>
  </div>
</section>
