import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

export interface trainingObject {
  title: string;
  image: string;
  sections: sectionDivsionObject[]
}

export interface sectionDivsionObject {
  id: number;
  key: string,
  contentType: string,
  description: string,
  duration: string,
  link: string,
  poster: string
}

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  trainingSections: trainingObject[] = [];
  canViewVideo = false;
  videoDescription = '';
  videoLink = '';
  thumbnail = '';

  constructor() { }

  ngOnInit(): void {
    this.trainingSections = [
      {
        title: 'Quick Start: Just the basics',
        image: '',
        sections: [
          {
            id: 1,
            key: 'createReservation',
            contentType: 'video',
            description: 'Create a Reservation',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/1.PNG'
          },
          {
            id: 2,
            key: 'createWaitlist',
            contentType: 'video',
            description: 'Create a Waitlist',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/2.PNG'
          },
          {
            id: 3,
            key: 'createContact',
            contentType: 'video',
            description: 'Create a Contact',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/3.PNG'
          },
          {
            id: 4,
            key: 'cancelReservation',
            contentType: 'video',
            description: 'Cancel a Reservation',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/4.PNG'
          },
          {
            id: 5,
            key: 'switchRes',
            contentType: 'video',
            description: 'Switch restaurant and view availability in other restaurants',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/5.PNG'
          }
        ]
      },
      {
        title: 'Reservation Management',
        image: '',
        sections: [
          {
            id: 1,
            key: 'printChit',
            contentType: 'video',
            description: 'Print chit for reservations and waitlist',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/6.PNG'
          },
          {
            id: 2,
            key: 'pageParty',
            contentType: 'video',
            description: 'Send a message/email/Manual page to a party',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/7.PNG'
          },
          // {
          //   id: 3,
          //   key: 'pageMultipleParties',
          //   contentType: 'video',
          //   description: 'Send a message/email to multiple parties',
          //   duration: '',
          //   link: '',
          //   poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          {
            id: 4,
            key: 'viewAuditLog',
            contentType: 'video',
            description: 'View Audit Log of a transaction',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/8.PNG'
          },
          // {
          //   id: 5,
          //   key: 'assignTable',
          //   contentType: 'video',
          //   description: 'Assign a table to a party',
          //   duration: '',
          //   link: '',
          //    poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          // {
          //   id: 6,
          //   key: 'viewStatistics',
          //   contentType: 'video',
          //   description: 'View the reservation and waitlist statistics',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          {
            id: 7,
            key: 'addStandby',
            contentType: 'video',
            description: 'Add standby',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/9.PNG'
          },
          {
            id: 8,
            key: 'convertStandby',
            contentType: 'video',
            description: 'Convert standby to Reservation',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/10.PNG'
          },
          // {
          //   id: 9,
          //   key: 'viewStandby',
          //   contentType: 'video',
          //   description: 'View Standby List',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          {
            id: 10,
            key: 'noShow',
            contentType: 'video',
            description: 'No Show a Reservation',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/11.PNG'
          },
          // {
          //   id: 11,
          //   key: 'addPartyNotes',
          //   contentType: 'video',
          //   description: 'Add party Notes',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          // {
          //   id: 12,
          //   key: 'bookSpecialMeal',
          //   contentType: 'video',
          //   description: 'Book a Special Meal',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // }
        ]
      },
      {
        title: 'Table Management',
        image: '',
        sections: [
          {
            id: 1,
            key: 'quickSeat',
            contentType: 'video',
            description: 'Quick Seat a Party',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/12.PNG'
          },
          {
            id: 2,
            key: 'overrideSeatTime',
            contentType: 'video',
            description: 'Override Seating time',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/13.PNG'
          },
          {
            id: 3,
            key: 'seatParty',
            contentType: 'video',
            description: 'Seat a Party',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/14.PNG'
          },
          // {
          //   id: 4,
          //   key: 'unseatParty',
          //   contentType: 'video',
          //   description: 'Unseat a Party',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          {
            id: 5,
            key: 'clearTable',
            contentType: 'video',
            description: 'Clear & Release Table',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/15.PNG'
          },
          {
            id: 6,
            key: 'setTableStatus',
            contentType: 'video',
            description: 'Set table status',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/16.PNG'
          },
          // {
          //   id: 7,
          //   key: 'moveParty',
          //   contentType: 'video',
          //   description: 'Move party from one table to another',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          // {
          //   id: 8,
          //   key: 'upcomingRes',
          //   contentType: 'video',
          //   description: 'Check upcoming reservations',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          // {
          //   id: 9,
          //   key: 'checkBlock',
          //   contentType: 'video',
          //   description: 'Check blocked until and free until for a table',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          // {
          //   id: 10,
          //   key: 'checkTableAvail',
          //   contentType: 'video',
          //   description: 'Check availability of a table',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          {
            id: 11,
            key: 'quickBlock',
            contentType: 'video',
            description: 'Quick Bock a table',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/17.PNG'
          },
          {
            id: 12,
            key: 'advBlock',
            contentType: 'video',
            description: 'Advance block tables',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/18.PNG'
          }//,
          // {
          //   id: 13,
          //   key: 'unBlock',
          //   contentType: 'video',
          //   description: 'Unblock tables',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // }
        ]
      },
      {
        title: 'Server Management',
        image: '',
        sections: [
          {
            id: 1,
            key: 'addServers',
            contentType: 'video',
            description: 'Add servers',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/19.PNG'
          },
          {
            id: 2,
            key: 'assignServers',
            contentType: 'video',
            description: 'Assign servers for tables',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/20.PNG'
          },
          {
            id: 3,
            key: 'unassignServers',
            contentType: 'video',
            description: 'Unassign servers',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/20.PNG'
          }
        ]
      },
      {
        title: 'Floor Plan Management',
        image: '',
        sections: [
          {
            id: 1,
            key: 'createTabLayout',
            contentType: 'video',
            description: 'Create a table layout',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/21.PNG'
          },
          {
            id: 2,
            key: 'customFloorPlan',
            contentType: 'video',
            description: 'Create a custom floor plan',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/22.PNG'
          }
        ]
      },
      {
        title: 'Manage Guest information',
        image: '',
        sections: [
          //{
          //   id: 1,
          //   key: 'viewGuestHistory',
          //   contentType: 'video',
          //   description: 'View guest history',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          // {
          //   id: 2,
          //   key: 'otherResHistory',
          //   contentType: 'video',
          //   description: 'View guest history from other restaurants',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          {
            id: 3,
            key: 'searchGuest',
            contentType: 'video',
            description: 'Search a guest',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/23.PNG'
          },
          {
            id: 4,
            key: 'editContact',
            contentType: 'video',
            description: 'Edit a contact',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/24.PNG'
          },
          {
            id: 5,
            key: 'deleteContact',
            contentType: 'video',
            description: 'Delete a contact ',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/25.PNG'
          },
          // {
          //   id: 6,
          //   key: 'setPreferences',
          //   contentType: 'video',
          //   description: 'Set communication preferences ',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          {
            id: 7,
            key: 'viewSpendHistory',
            contentType: 'video',
            description: 'View Guest Spend history',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/1.PNG'
          }//,
          // {
          //   id: 8,
          //   key: 'addGuestNotes',
          //   contentType: 'video',
          //   description: 'Add Guest Notes',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // }
        ]
      },
      {
        title: 'Manage Users',
        image: '',
        sections: [
          {
            id: 1,
            key: 'setRoles',
            contentType: 'video',
            description: 'Set user Roles and Permission ',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/26.PNG'
          }//,
          // {
          //   id: 2,
          //   key: 'setPins',
          //   contentType: 'video',
          //   description: 'Set user pins',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // }
        ]
      },
      {
        title: 'Reports',
        image: '',
        sections: [
          // {
          //   id: 1,
          //   key: 'viewCoverReps',
          //   contentType: 'video',
          //   description: 'View cover Report',
          //   duration: '',
          //   link: '',
          //poster: '../../../../assets/images/training_thumbnails/1.PNG'
          // },
          {
            id: 2,
            key: 'generateReps',
            contentType: 'video',
            description: 'Generate Reports',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/27.PNG'
          },
          {
            id: 3,
            key: 'scheduleReps',
            contentType: 'video',
            description: 'Schedule Reports',
            duration: '',
            link: '',
            poster: '../../../../assets/images/training_thumbnails/28.PNG'
          }
        ]
      }//,
      // {
      //   title: 'Manage Payments',
      //   image: '',
      //   sections: [
      //     {
      //       id: 1,
      //       key: 'prepaidRes',
      //       contentType: 'video',
      //       description: 'Create a prepaid reservation',
      //       duration: '',
      //       link: '',
      //poster: '../../../../assets/images/training_thumbnails/1.PNG'
      //     },
      //     {
      //       id: 2,
      //       key: 'chargeNoshow',
      //       contentType: 'video',
      //       description: 'Charge a No-Show reservation',
      //       duration: '',
      //       link: '',
      //poster: '../../../../assets/images/training_thumbnails/1.PNG'
      //     },
      //     {
      //       id: 3,
      //       key: 'manageGuestCharge',
      //       contentType: 'video',
      //       description: 'Manage the guest charges ',
      //       duration: '',
      //       link: '',
      //poster: '../../../../assets/images/training_thumbnails/1.PNG'
      //     },
      //     {
      //       id: 4,
      //       key: 'refundPayment',
      //       contentType: 'video',
      //       description: 'Refund a payment',
      //       duration: '',
      //       link: '',
      //poster: '../../../../assets/images/training_thumbnails/1.PNG'
      //     }
      //   ]
      // }//,
      // {
      //   title: 'Others',
      //   image: '',
      //   sections: [

      //   ]
      // }
    ]
    this.trainingSections.forEach(trainingSection => {
      trainingSection.sections.forEach(section => {
        let videoLink = environment.trainingVideoLinks[section.key];
        if (videoLink) {
          section.link = videoLink;
        }
      })
    });
    console.log(this.trainingSections);
  }

  viewContent(data: sectionDivsionObject) {
    this.canViewVideo = true;
    this.videoDescription = data.description;
    this.videoLink = data.link;
    this.thumbnail = data.poster
  }

  playLaunchVideo() {
    this.canViewVideo = true;
    this.videoDescription = "Welcome to Agilysys";
    this.videoLink = "https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/Modern%20Seat%20Launch%20Video_0.3.mp4";
  }

  closeContentView() {
    this.canViewVideo = false;
  }
}
