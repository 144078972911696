import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { RestaurantAuditLogItemDTO } from '@app/shared/models/SimpleAuditLogItemDTO';
import { FacadeService } from '@app/shared/services/facade.service';
import { TablesService } from '@app/shared/services/tables.service';
import { SelectBookingTypeComponent } from '@app/shared/components/select-booking-type/select-reservation-type.component';
import { SignalrService } from '@app/shared/services/signalr.service';
import { ReservationTypeComponent } from '@components/select-reservation-type/select-reservation-type.component';
import { buttonTypes, ChangedEntityType, ComponentTypes, GetReservationsOperationOptions, Menu, PartyType, RolesAndPermissionsType, SlottingType } from '@constants/commonenums';
import { AutoRefreshTimeInSecs, controlSettings, popupDialogDimension } from '@constants/globalConstants';
import { urlConfig } from '@constants/url-config';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { DashboardDataDTO } from '@models/DashBoardDataDTO';
import { SeatingAreaDTO, SettingsDTO, StateDTO } from '@models/RestaurantDTO';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AdItem } from '@popup-module/ad-item';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';
import { interval, Subscription } from 'rxjs';
import sideMenu from '@data/sidemenu.json';
import { ChangeDTO } from '@app/shared/models/ChangeTrackingOperationResultDTO';
import { ChangesByScreenDTO } from '@app/shared/models/SignalRDto';
import { DateShiftStateDTO, DateStateDTO, StandByPartyDTO, WalkInDTO } from '@app/shared/models/InputReservation';
import { format } from 'date-fns';
import { ReservationDTO } from '@app/shared/models/InputContact';
import * as globals from '@constants/globalConstants';
import { cloneDeep, property } from 'lodash';
import { I } from '@angular/cdk/keycodes';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { LoginResultDTO, RestaurantAvailableForLoginDTO } from '@app/shared/models/LoginResultDTO';
import { LoadMultiplePropertiesService } from '@app/shared/services/load-multiple-properties.service';
import { LoginFunction } from '@app/shared/utilities/login-function';
import { MachineName } from '@app/common/shared/shared.modal';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { CalendarViewComponent } from '@app/shared/components/calendar-view/calendar-view.component';
import { DatePipe } from '@angular/common';
import { RolesAndPermissionPipe } from '@app/shared/pipes/RolesAndPermission.pipe';
import { CartWindowMessageType } from '@app/common/components/menu/vcart/vcart.modal';

@Component({
  selector: 'app-header',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends Utilities implements OnInit, AfterViewInit, OnDestroy {
  @Output('onCartClick') cartClick: EventEmitter<any> = new EventEmitter();
  date: Date = new Date();
  minDate: Date;
  logoUrl: string = urlConfig.logoUrl;
  menuName: string;
  seatingAreas: SeatingAreaDTO[];
  componentLoader: AdItem;
  componentDetails: ComponentDetails;
  datePickerconfig: FieldConfig[];
  dropDownconfig: FieldConfig[];
  dayNoteconfig: FieldConfig[];
  showDayNote = true;
  restaurantNameConfig: FieldConfig[];
  seatingAreaGroup: UntypedFormGroup;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  // @ViewChild('dayNoteForm', { static: true }) dayNoteForm: DynamicFormComponent;
  @ViewChild('p2', { static: true }) notify: NgbPopover;
  @ViewChild('dayNotePopOver', { static: true }) dayNotes: NgbPopover;
  buttonSend: ButtonValue;
  buttonCancel: ButtonValue;
  SlottingType = SlottingType;
  buttonToday: ButtonValue;
  dayNote: string;
  highlightNote = false;
  showTodayButton = false;
  alertHistory: RestaurantAuditLogItemDTO[] = [];
  headerSubscription: Subscription = new Subscription();
  availablePropertiesForLogin: RestaurantAvailableForLoginDTO[] = [];
  _settings: SettingsDTO = {} as SettingsDTO;
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm1: DynamicFormComponent;
  _state: StateDTO = {} as StateDTO;
  seatingAreasAvailable: any[] = [];
  restaurantDateTime: Date;
  subscriptions: Subscription = new Subscription();
  isFirstTime = true;
  public TimerInterval: number = AutoRefreshTimeInSecs;
  private timerInstance: any;
  public IsRefreshChecked: boolean = true;
  public showAutoRefresh: boolean = !!AutoRefreshTimeInSecs;
  headerDate: Date;
  dateChangeCount = 0;
  showMoreText = '';
  isDateChangedManually = false;
  isActivityProperty: boolean = false;
  @ViewChild('restaurantNameForm', { static: true }) restaurantNameForm: DynamicFormComponent;
  isDifferentDate: boolean;
  showSeatingArea: boolean = true;
  isDateIncremented: boolean = false;
  hideHelpIcon:boolean;
  @ViewChild('daynoteView') dayNoteElement:ElementRef;
  showMoreOption = false;
  showPosSwitchOption: boolean = false;

  constructor(public router: Router, public _as: AppService, public dialog: MatDialog, public fb: UntypedFormBuilder,
    public cs: CacheService, public ps: PartyService, private fs: FacadeService, private ts: TablesService, private trs: TranslateService, public signalrService: SignalrService, private ls: LoadMultiplePropertiesService,
    private dashboardFunctions: DashboardFunctions, private lf: LoginFunction,private datePipe: DatePipe) {
      super(dialog);
    this.menuName = Utilities.getMenuNameById(this._as.selectedMenuId);
    this.isActivityProperty = this.checkActivityProperty();
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
      this._as.addDefaultSeatingArea(sett);
      this._as.addDefaultSeatingType(sett);
      this.seatingAreas = this._settings.SeatingAreas ? this._settings.SeatingAreas : [];
      const seatingAreasAvailable = [];
      if (this.seatingAreas && this.seatingAreas.length > 0) {
        this.seatingAreas.forEach(data => {
          seatingAreasAvailable.push({ id: data.Id, value: data.Name });
        });
      }
      this.seatingAreasAvailable = seatingAreasAvailable;
      if (this.dropDownconfig && this.dropDownconfig.length > 0) {
        this.seatingAreas.forEach(seatArea => {
          if (seatArea.Id == -1)
            seatArea.Name = this.trs.instant('anySeatingArea');
        })
        this.dropDownconfig[0].options = this.seatingAreasAvailable;
        this.dropDownconfig[0].isReadOnly = Utilities.controlValidate(controlSettings.Header_SeatingArea_Disable, this._as.PropertyType);
        this.dropDownconfig[0].value = Utilities.controlValidate(controlSettings.Header_SeatingArea_Disable, this._as.PropertyType) ? this.seatingAreasAvailable.filter(x => x.id == -1)[0].id : '';
      }
      if (this.datePickerconfig && this.datePickerconfig.length) {
        let dateConfig = cloneDeep(this.datePickerconfig);
        this.datePickerconfig = [];
        this.datePickerconfig = [...dateConfig];
      }
    }));

    this.subscriptions.add(cs.state.subscribe(ste => {
      this._state = ste;
      this.date = Utilities.parseDateString(this._state.CurrentTime);
      this.minDate = new Date(this._state.CurrentTime);
      this.minDate.setDate(this.minDate.getDate() - (this.cs.AllowEditForPastReservationsInDays || 0));
      if (this.isFirstTime) {
        this._as.headerSelectedDate = Utilities.parseDateString(this._state.CurrentTime);
        this._as._headerDate = Utilities.parseDateString(this._state.CurrentTime);
        this.cs.headerDate = Utilities.parseDateString(this._state.CurrentTime);
      }
      this.isFirstTime = false;
    }));
    
    this.signalrService.headerSelectedDate = Utilities.parseDateString(this._state.CurrentTime);
  }


  expandDayNote(){
   // this.showMoreOption = !this.showMoreOption;
   let classList = this.dayNoteElement.nativeElement.classList;
    if(!classList.contains('expanded-view')){
      classList.add('expanded-view');
      this.showMoreText = this.trs.instant('accordionlesstext')
    }
    else{
      classList.remove('expanded-view');
      this.showMoreText = this.trs.instant('accordionmoretext')
    }

  //  this.dayNoteElement.nativeElement.style.maxHeight = this.dayNoteElement.nativeElement.scrollHeight + 'px';
  }

  refreshCurrentComponent(fullReload) {
    if (this.cs.signalRMessageCount) {
      // if (fullReload) {
      //   this._as.triggerReservationReload();
      // } else {
      //const restaurantId = this.signalrService.signalRChangeScreenInput.RestaurantId;
      if(this.signalrService.propertyIdsChanged && this.signalrService.propertyIdsChanged.length > 0)
      {
        this.signalrService.propertyIdsChanged = [...new Set(this.signalrService.propertyIdsChanged)];
        this.signalrService.propertyIdsChanged.forEach(restId => {
          const baseUrl = urlConfig.getChangeSetForScreenNameUrl;
          const headers = Utilities.getHeaders(restId).headers;
          let input: ChangesByScreenDTO = {
            revisionId: Utilities.getLatestRevision(restId),
            restaurantId: restId,
            CommonParams: {
              RestaurantId: restId,
              BaseUrl: baseUrl,
              RestaurantApiKey: headers.get('x-ts-restaurant-api-key'),
              ClientUid: headers.get('x-ts-client-uid'),
              HostId: headers.get('x-ts-host-id'),
              MerchantKey: headers.get('x-ts-merchant-auth-key')
            },
            screenName: sideMenu.menus.find(({id}) => id === this._as.selectedMenuId)?.screen,
          };
          this._as.signalrService.getChangesByScreen(input);
          this.signalrService.propertyIdsChanged = this.signalrService.propertyIdsChanged.filter(item => item !== restId);
        })
      }
      this.cs.signalRMessageCount = 0;
      
      // this.cs.getChanges(Utilities.getLatestRevision(), sideMenu.menus[this._as.selectedMenuId].screen).subscribe((data: any) => {
      //   const changes: ChangeDTO[] = data.Payload as ChangeDTO[];
      //   this.cs.changes.next(changes);
      //   this.cs.signalRMessageCount = 0;
      // });
      // }
    }
  }

  ngOnInit() {
    this.restaurantDateTime = this.headerDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    this.ps.weekStartDate = this.restaurantDateTime;
    this.ps.weekEndDate = this.restaurantDateTime;
    let hostData = this._settings.Hosts.filter(host => host.Id == JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostId);
    if (hostData.length > 0) {
      this._as.currentUserName = hostData[0].Name
    }
    this._as.datePickerDisabled.next(true);
    this._as.seatingAreaFilterDisabled.next(true);
    this.datePickerconfig = [
      {
        type: 'date',
        placeholder: 'chooseDate',
        name: 'reservationDate',
        class: 'confirm__date',
        label: 'timelineDate',
        value: this.date,
        appearance: true,
        showNavigation: true,
        navigationIncrementer: 1,
        minDate: this.minDate,
        disabled: false,
        isReadOnly: !Utilities.controlValidate(controlSettings.Calendar_View_Hide,this._as.PropertyType),
        AllowEditForPastReservationsInDays: this.cs.AllowEditForPastReservationsInDays
      }];

    this.dropDownconfig = [{
      type: 'select',
      label: this.trs.instant("chooseSeatingArea"),
      name: 'seatingArea',
      options: this.seatingAreasAvailable,
      class: 'login__language-selection',
      showErrorText: true,
      appearance: true,
      isTranslate: false,
      isReadOnly: Utilities.controlValidate(controlSettings.Header_SeatingArea_Disable, this._as.PropertyType),
      //propertyWithDefaultCourtArea.find(x=>x == this._as.PropertyType) ? true : false,
      value: Utilities.controlValidate(controlSettings.Header_SeatingArea_Disable, this._as.PropertyType) ? this.seatingAreasAvailable.filter(x => x.id == -1)[0].id : ''
    }];
    this.dayNoteconfig = [{
      type: 'textarea',
      name: 'dayNote',
      inputType: 'text',
      showHint: true,
      charLength: 400,
      class: 'dayNoteText'
    }];
    this.setRestaurantNameConfig();

    this.buttonSend = {
      type: buttonTypes.actionPrimary,
      label: 'Save Note',
      customclass: 'next-btn',
      disbaledproperity: false
    };
    this.buttonToday = {
      type: buttonTypes.actionPrimarySmall,
      label: 'todayText',
      customclass: 'headercontainer__today-btn',
    };
    this.buttonCancel = {
      type: buttonTypes.actionSecondary,
      label: 'Cancel',
      customclass: 'cancel-btn'

    };
    this.headerSubscription.add(interval(1000).subscribe(() => {
      moment(this.restaurantDateTime).add(1, 's');
      let isTimeNotEqual = moment(this.restaurantDateTime).add(1, 's').format('YYYY-MM-DDTHH:mm') !== moment(this.restaurantDateTime).format('YYYY-MM-DDTHH:mm')
      this.restaurantDateTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
      if (isTimeNotEqual) {
        console.log(isTimeNotEqual);
        this._as.minuteTimer$.next(true);
      }
      if (new Date(this.headerDate).getDate() !== this.restaurantDateTime.getDate() && !this.isDateChangedManually) {
        this.dateChangeCount++;
        const form = this.components.first.form as UntypedFormGroup;
        form.controls['reservationDate'].setValue(this.restaurantDateTime);
      }
    }));
    if (!!AutoRefreshTimeInSecs) {
      this.OnCheckedChange();
    }
    this.loadReservations(this._as.headerSelectedDate, this._as.headerSelectedDate);

    this.showPosSwitchOption = POSEnabledProperties.includes(Utilities.RestaurantId());
    //this.ts.getBlocks();
  }

  loadReservations(startDate, endDate, PropertyId?) {
    let currentPropertyId = PropertyId ? PropertyId : Utilities.RestaurantId();
    this.ps.allWeekActivitySessions = {};
    this.ps.allWeekreservations = [];
    this.ps.noOfDaysLoaded = 0;
    this.subscriptions.add(this.ps.getParties(startDate, endDate, GetReservationsOperationOptions.Full, currentPropertyId).subscribe((data) => {
      if (data) {
        this.ps.partiesList = [];
        const currentReservations: DateStateDTO[] = data.Payload.filter((reservations: DateStateDTO) => {
          const startdate = format(new Date(startDate), 'MM/DD/YYYY');
          const enddate = format(new Date(endDate), 'MM/DD/YYYY');
          const reservationDate = format(new Date(reservations.Date), 'MM/DD/YYYY');
          return (startdate <= reservationDate) && (reservationDate <= enddate);
        });
        let propertySlottingType = this.cs.propertySettings.value && this.cs.propertySettings.value[currentPropertyId]?.settings ? this.cs.propertySettings.value[currentPropertyId].settings.PropertySetting[0]?.SlottingType : this.cs.settings.value.PropertySetting[0]?.SlottingType;
        if (propertySlottingType == SlottingType.NonDining) {
          this.showSeatingArea = false;
          this.dashboardFunctions.LoadPartiesForNonDinningProperties(currentReservations, PropertyId);
        }
        else {
          this.dashboardFunctions.LoadPartiesForDinningParties(currentReservations, PropertyId);
        }
        if (PropertyId) {
          this.ls.setDataForSelectedRestaurant(PropertyId);
          this.cs.propertySettings.value[PropertyId].headerDate = startDate;
        }
      }
    }));
  }

  closePopover(){
    this.showDayNote = false;
  }

  triggerAlertPopup(popver) {
    let restaurantDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    var start = moment(new Date(restaurantDate)).startOf('day');
    var end = moment(new Date(restaurantDate)).endOf('day');
    this._as.showNotificationSection = true;
    this.fs.getRestaurantAuditLog(moment(start).format('YYYY-MM-DDTHH:mm:ss'), moment(end).format('YYYY-MM-DDTHH:mm:ss')).subscribe((data) => {
      this.alertHistory = data.Payload;
      if(this._as.propertyNotifications[this._as.restaurantId] !== null && this._as.propertyNotifications[this._as.restaurantId] !== undefined) 
      this._as.propertyNotifications[this._as.restaurantId] = false;
      popver.open();
    });
  }

  toggleSideMenu() {
    this._as.hideSideMenu = !this._as.hideSideMenu;
    this._as.toggleMenu$.next(this._as.hideSideMenu);
  }

  checkTodayButtonConfiguartion(date) {
    this.showTodayButton = false;
    let currentDate = Utilities.Date(date);
    let currentRestDate = Utilities.Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    if (Utilities.diffBetweenDates(currentRestDate, currentDate) != 0) {
      this.showTodayButton = true;
    }
  }

  changeDate(date) {
    if (this.dateChangeCount === 1) {
      this.isDateChangedManually = true;
    }
    this.checkTodayButtonConfiguartion(date);
    const isJSDate = moment.isDate(date);

    if (!isJSDate) {
      date = moment(date).toDate();
    }

    if (this._as.selectedMenuId == Menu.Reservation || this._as.selectedMenuId == Menu.Tables || this._as.selectedMenuId == Menu.TimeLine || this._as.selectedMenuId == Menu.ActivitiesTimeline || this._as.selectedMenuId == Menu.BookingCentral) {
      this._as.headerSelectedDate$.next(date);
    }
    this._as._headerDate = date;
    this.cs.headerDate = date;
    if (!(moment(date).format('YYYY-MM-DD') >= moment(this.ps.weekStartDate).format('YYYY-MM-DD') && 
    moment(date).format('YYYY-MM-DD') <= moment(this.ps.weekEndDate).format('YYYY-MM-DD'))) {
      if (this.ps.isTimelineInWeekView) {
        // this.setDateRangeInterval(date);
      } else {
        this.ps.weekStartDate = date;
        this.ps.weekEndDate = date;
        this.loadReservations(date, date);
      }
    } else if (this._as.selectedMenuId == Menu.ActivitiesTimeline && this.ps.isTimelineInWeekView && 
      moment(date).format('YYYY-MM-DD') !== moment(this.ps.weekStartDate).format('YYYY-MM-DD')) {
      // this.setDateRangeInterval(date);
    }
    let currentRestaurantId = +sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`);
    this.cs.propertySettings.value[currentRestaurantId].headerDate = date;
    this._as.headerDate$.next(date);
    this.signalrService.headerSelectedDate = date;
  }

  setDateRangeInterval(selectedDate) {
    let [start, end] = Utilities.getWeekDateRange(selectedDate);
    this.ps.weekStartDate = start;
    this.ps.weekEndDate = end;
    this.loadReservations(start, end);
  }

  setSelectedArea(option) {
    // tslint:disable-next-line: no-string-literal
    document.getElementsByClassName('mat-form-field-label-wrapper')[0]['style'].display = 'none';
    this._as.seatingArea = option;
    this._as.seatingAreaChanged.next(option);
  }

  closePopOver() {
    if (this.notify) {
      this.notify.close();
    }
  }

  autoRefreshClick(event) {
    event.stopPropagation();
  }

  OnCheckedChange() {
    if (this.IsRefreshChecked) {
      this.startAutoRefresh();
    }
    else {
      this.TimerInterval = AutoRefreshTimeInSecs;
      this.stopAutoRefresh();
    }
  }

  startAutoRefresh() {
    this.timerInstance = setTimeout(() => {
      this.TimerInterval = this.TimerInterval - 1;
      if (this.TimerInterval == 0) {
        if (this.cs.signalRMessageCount) {
          this.refreshCurrentComponent(false);
        }
        this.TimerInterval = AutoRefreshTimeInSecs;
      }
      if (this.IsRefreshChecked) {
        clearTimeout(this.timerInstance);
        this.startAutoRefresh();
      }

    }, 1000);
  }

  stopAutoRefresh() {
    console.log("stop auto refresh timer called");
    clearTimeout(this.timerInstance);
  }

  close() {
    // this.dayNoteForm.form.patchValue({
    //   dayNote: this.dayNote
    // });
    this.dayNotes.close();
  }

  navigateToToday() {
    this.components.first.form.controls.reservationDate.setValue(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta))
    this.isDateIncremented = false;
  }

  createReservationAndWaitList() {
    this.componentDetails = {
      componentName: ReservationTypeComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    if (this._as.showCartItem) {
      this._as.showCartItem = false;
    }
    if(this._as.showCreateActivity) {
      this._as.showCreateActivity = false;
    }
    if (Utilities.IsActivitiesVenues(this._as.PropertyType)) {
      this.componentDetails = {
        componentName: SelectBookingTypeComponent,
        popupType: 'action',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        }
      };
      this.ps.reservationDialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        height: popupDialogDimension.actionDialogHeight,
        width: popupDialogDimension.createDialogWidth,
        backdropClass: 'backdropBackground',
        data: {
          title: 'Add popup', update: 'SAVE', cancel: 'CANCEL', componentDetails: this.componentDetails, popupType: 'action'
        }
      });
    } else {
      this.ps.reservationDialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        height: popupDialogDimension.actionDialogHeight,
        width: popupDialogDimension.actionDialogWidth,
        backdropClass: 'backdropBackground',
        data: {
          title: 'Add popup', update: 'SAVE', cancel: 'CANCEL', componentDetails: this.componentDetails, popupType: 'action'
        }
      });
    }
  }

  ngAfterViewInit() {
 
    this.hideHelpIcon =  Utilities.controlValidate(controlSettings.Header_Help_Icon_Hide,this._as.PropertyType)
    this._as.headerDate$.next(this._as._headerDate);
    if (this.components.length > 0) {
      this.subscriptions.add(this.components.last.form.valueChanges.subscribe(val => {
        if (val.seatingArea) {
          const data = this.seatingAreas.filter(x => x.Id === val.seatingArea);
          this.setSelectedArea(data);
        }
      }));
      this.subscriptions.add(this.components.first.form.valueChanges.subscribe(val => {
        this.dateChangeCount++;
        this.isDateIncremented = true;
        this.headerDate = val.reservationDate;
        this.changeDate(val.reservationDate);
      }));
      this.subscriptions.add(this.headerSubscription.add(this._as.seatingAreaFilterDisabled.subscribe(val => {
        this.components.last.setDisabled('seatingArea', val);
        // this.dropDownconfig[0].disabled = val;
      })));

      this.headerSubscription.add(this._as.datePickerDisabled.subscribe(val => {
        this.datePickerconfig[0].disabled = val;
      }));
      this.headerSubscription.add(this._as.propertySwitchDisabled.subscribe(val => {
        this.restaurantNameConfig[0].disabled = val;
      }));
      this.headerSubscription.add(this._as.datePickerRangeChange.subscribe(val => {
        this.datePickerconfig[0].navigationIncrementer = val;
      }));
      this.headerSubscription.add(this._as.triggerCurrentMenu$.subscribe((menu) => {
        this.menuName = Utilities.getMenuNameById(this._as.selectedMenuId);
        const selectedSeatingArea = (this._as.seatingArea && this._as.seatingArea.length > 0) ? this._as.seatingArea[0].Id : null;
        let currentRestaurantId = +sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`);
        if (menu == Menu.Reservation || menu == Menu.Tables || menu == Menu.TimeLine || menu == Menu.ActivitiesTimeline|| menu == Menu.BookingCentral) {
          (menu == Menu.TimeLine) ? this.components.last.form.controls.seatingArea.setValue(null) : this.components.last.form.controls.seatingArea.setValue(selectedSeatingArea);
          this.components.first.form.controls.reservationDate.setValue(this._as.headerSelectedDate, { emitEvent: false });
          if (Utilities.Date(this._as.headerDate$.value).startOf('day').diff(Utilities.Date(this._as.headerSelectedDate).startOf('day'), 'd')) {
            this._as.headerDate$.next(this._as.headerSelectedDate);
          }
          this.cs.propertySettings.value[currentRestaurantId].headerDate = this._as.headerSelectedDate;
          this.checkTodayButtonConfiguartion(this._as.headerSelectedDate)
        } else {
          this.components.last.form.controls.seatingArea.setValue(null);
          let propertyDateTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
          this.cs.propertySettings.value[currentRestaurantId].headerDate = propertyDateTime;
          this.showTodayButton = false;
          if (Utilities.Date(this._as.headerDate$.value).diff(Utilities.Date(this.cs.headerDate), 'd') !== 0 ) {
            this.loadReservations(this._as.headerDate$.value, this._as.headerDate$.value);
          }
        }
      }));
      this.cs.VCartItineraryUpdated$.subscribe(vCart => {
        let propertyDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)
        if (vCart && vCart.Type == CartWindowMessageType.CartItinerarySelected && vCart.data) {
          let date = new Date(vCart.data.travelStartDate) >= propertyDate ? new Date(vCart.data.travelStartDate) : propertyDate;
          this.dynamicForm1.form.get('reservationDate').setValue(date);
        } else if (vCart && vCart.Type == CartWindowMessageType.CartItineraryRemoved) {
          this.dynamicForm1.form.get('reservationDate').setValue(new Date(propertyDate));
        }
      })

      //Edit cart item between switch property 
      this.cs.propertyChangeEmitter$.subscribe(property =>{
        if(property.value){
          this.restaurantNameConfig[0].value = property.value;
          this.selectedRestaurant(property);
        }
      })
    }

    this.headerSubscription.add(this._as.headerDate$.subscribe(() => {
      this.getDayNote();
    }));
    this._as.isHeaderLoaded = true;

    this.subscriptions.add(this._as.propertyChanged.subscribe((propertyId) => {
      if (propertyId) {
        this.hideHelpIcon =  Utilities.controlValidate(controlSettings.Header_Help_Icon_Hide,this._as.PropertyType)
        this.restaurantNameConfig[0].value = this.availablePropertiesForLogin.find(property => property.Id == propertyId).Id;
      //  if (this.isDifferentDate) {
        this.cs.isSilentLogin = true;
          this.getDayNote();
        //} else {
          this.dayNote = this.cs.propertySettings.value[propertyId]?.restaurantNote ? this.cs.propertySettings.value[propertyId].restaurantNote.Text : null;
          this.highlightNote = this.dayNote ? true : false
      //  }
      }
      this.isActivityProperty = this.checkActivityProperty();
      if(this.datePickerconfig) {
        this.datePickerconfig[0].isReadOnly = !Utilities.controlValidate(controlSettings.Calendar_View_Hide,this._as.PropertyType);
      }
      if (this._as.showCartItem && !this._as.showCartItemOnEdit) {
        this._as.showCartItem = false;
      }
      if(this._as.showCreateActivity && !this.isActivityProperty) {
        this._as.showCreateActivity = false;
      }
    }))
  }

  getDayNote() {
    this.subscriptions.add(this._as.getDayNote().subscribe(data => {
      this.cs.isSilentLogin = false;
      if (data && data.Payload) {
        this.dayNote = data.Payload.Text;
        this.highlightNote = true;
        this.cs.restuarantNote = data.Payload;
        if(this.dayNoteElement?.nativeElement){
          if(this.dayNoteElement.nativeElement.scrollHeight > this.dayNoteElement.nativeElement.clientHeight){
            this.showMoreOption = true;
            this.showMoreText = this.trs.instant('accordionmoretext');
          }
        } 
        this.dayNoteElement?.nativeElement
      }
      else {
        this.dayNote = "";
        this.highlightNote = false;
      }
    }));
  }

  checkActivityProperty(): boolean {
    return controlSettings.Cart_Enable.find(x => x == this._as.PropertyType) ? true : false;
  }

  switchToPOS(){
    this._as.posView = true;
    sessionStorage.setItem('posView', 'true');
    this.router.navigate(['pos']);
  }

  logout(){
    if(this._as.showCartItem){
      this._as.showCartItem = false;
    }
    if(this._as.showCreateActivity) {
      this._as.showCreateActivity = false;
    }
    this.cs.locationListForMerchant = [];
    this.cs.LoadMultiplePropertiesServiceInit = false;
    sessionStorage.removeItem('section');
    this._as.logout();
  }

  ngOnDestroy() {
    if (this.headerSubscription) { this.headerSubscription.unsubscribe(); };
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    this._as.headerDate$.next(null);
    this._as.isHeaderLoaded = false;
    this._as.propertyNotifications = {} as any;
  }

  saveNote(dayNoteData) {
    if (dayNoteData.dayNotesValue !== this.dayNote) {
      this.subscriptions.add(this._as.setDayNote(dayNoteData.dayNotesValue).subscribe(data => {
        if (data) {
          this.highlightNote = this.dayNote ? true : false;
          this.cs.propertySettings.value[this._as.restaurantId]['restaurantNote']['Text'] = this.dayNote;
        }
      }));
    }
    this.dayNotes.close();
    this.dayNote = dayNoteData.dayNotesValue;

    setTimeout(() => {
      if(this.dayNoteElement.nativeElement.scrollHeight > 55){
        this.showMoreOption = true;
        this.showMoreText = this.dayNoteElement.nativeElement.scrollHeight > this.dayNoteElement.nativeElement.clientHeight ? this.trs.instant('accordionmoretext') :  this.trs.instant('accordionlesstext');
      }else{
        this.showMoreOption = false;
      }
    })
  }

  help() {
    window.open("help", "Agilysys_Reserve_Help");   
    // this._as.showHelpScreen = true;
  }

  showCartItems() {
    if(!this.ps.TotalCartBookingObj.some(cartItem => cartItem.selected) || this._as.showCartItem){
      this.cartClick.emit(null);
    }
  }

  setRestaurantNameConfig() {
    let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    let currentPropertyId = +sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`);
    this._as.propertyChanged.next(currentPropertyId);
    this.availablePropertiesForLogin = loginResult.RestaurantsAvailableForLogin.sort((property1: any, property2: any) => {
      if (property1.Name < property2.Name) {
        return -1;
      } else if (property1.Name > property2.Name) {
        return 1;
      } else {
        return 0;
      }
    });
    this.restaurantNameConfig = [{
      type: 'select',
      name: 'restaurantName',
      options: this.availablePropertiesForLogin.map(restaurant => { return { id: restaurant.Id, value: restaurant.Name, isLocked: restaurant.IsLockedDueToBillingIssues } }),
      class: 'headercontainer__restaurant-selection',
      appearance: true,
      isTranslate: false,
      value: this.availablePropertiesForLogin.filter(property => property.Id === currentPropertyId)[0].Id,
      cellClick: (event) => { this.selectedRestaurant(event) }
    }]

    this.availablePropertiesForLogin?.forEach(property => { this._as.propertyNotifications[property.Id] = false});
  }

  selectedRestaurant(restaurantData) {
    if (this.availablePropertiesForLogin.find(property => property.Id == restaurantData.value).IsLockedDueToBillingIssues) {
      const popUpMessage = [{
        confirmationMessage: this.trs.instant('biilingError'),
        dialogTitle: this.trs.instant('error'),
        showAlert: true
      }];
      let okbutton = this.trs.instant('ok');
      const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
        popUpMessage, popUpMessage[0].dialogTitle);
      let dialog = this.openCustomPopup(componentDetails, ComponentTypes.login, '450px', 'auto', true, '', okbutton,
        '', true);
      dialog.afterClosed().subscribe(() => {
        this.restaurantNameConfig[0].value = this._as.restaurantId;
        this.restaurantNameConfig = [...this.restaurantNameConfig];
      });
    } else {
      
      let oldRestDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
      let curRestDate = Utilities.getRestaurantDateTime(this.cs.propertySettings.value[restaurantData.value]?.settings?.General?.DaylightDelta);
      let curpropertydate = this.cs.propertySettings.value[sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`)].headerDate ? this.cs.propertySettings.value[sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`)].headerDate : curRestDate;
      let oldpropertydate = this.cs.propertySettings.value[restaurantData.value].headerDate ? this.cs.propertySettings.value[restaurantData.value].headerDate : oldRestDate;
      let currentpropertyvalue = Utilities.Date(curpropertydate);
      let oldpropertyvalue = Utilities.Date(oldpropertydate);
      this._as.headerSelectedDate = curpropertydate;
      if (Utilities.diffBetweenDates(currentpropertyvalue, oldpropertyvalue) != 0) {
        this.isDifferentDate = true;
        this.loadReservations(currentpropertyvalue, currentpropertyvalue, restaurantData.value);
      } else {
        this.isDifferentDate = false;
        this.ls.setDataForSelectedRestaurant(restaurantData.value);
      }
    }
  }
  
  showCalendarView() {
    if (!Utilities.controlValidate(controlSettings.Calendar_View_Hide,this._as.PropertyType) && !this.isDateIncremented) {
      const showAlert = false;
      const title = this.trs.instant('chooseADate')
      const popUpMessage = [{
        dialogTitle: title, showAlert, selectedDate: this._as.headerSelectedDate
      }];
      const componentDetails: ComponentDetails = Utilities.setComponentDetails(CalendarViewComponent, 'large', 'action', popUpMessage, popUpMessage[0].dialogTitle);
      this.openCustomPopup(componentDetails, ComponentTypes.CalendarViewComponent, '1000px', '600px', false, title).afterClosed().subscribe(() => {
        const form = this.components.first.form as UntypedFormGroup;
        if (this._as.headerSelectedDate >= this.minDate) {
          if (this._as.headerSelectedDate?.getTime() !== form.controls['reservationDate'].value.getTime()) {
            form.controls['reservationDate'].setValue(this._as.headerSelectedDate);
            this.checkTodayButtonConfiguartion(this._as.headerSelectedDate)
            // this.loadReservations(this._as.headerSelectedDate, this._as.headerSelectedDate);
          }
        } else {
          this._as.headerSelectedDate = form.controls['reservationDate'].value;
          let infoMsg = '';
          this.trs.get('headeMinDateInfoMessage', { minDate: this.datePipe.transform(this.minDate, 'MMMM dd yyyy') }).subscribe(msgVal => infoMsg = msgVal);
          this.dashboardFunctions.showWarningInfoPopUp(infoMsg,ComponentTypes.minDateInfoMessage, "350px", "450px", this.trs.instant('informationText'), 'action');
        }
        this.isDateIncremented = false;
      });
    }
    else {
      this.isDateIncremented = false;
    }

  }

}

const POSEnabledProperties = [2364]