<section class="member-accrual-mapping-setup newVersionWrapper" id="posttype">
    <div class="custom-retail-inputs page-header">
      <div class="d-flex flex-wrap">
        <label class="page--header font-bold text-uppercase">{{captions.memberaccuralmapping}}</label>

        <!-- <div class="d-flex pl-4">
            <label class="page--header font-bold text-uppercase">{{captions.PaymentMethod}}</label>
        </div> -->
        <div class="d-flex ml-auto width-class custSearchbox">
          <mat-form-field class="searchArea spa-searchbox align-self-end" [floatLabel]="floatLabelNever">
            <input [attr.automationId]="'Txt_memberAccrualMapping_search'" matInput autocomplete="off" class="search-input" [(ngModel)]="searchText"
              [ngModelOptions]="{standalone: true}" type="text" placeholder="{{searchPlaceholderValue}}"
              [maxlength]="100" value="" (keypress)="searchValue($event)">
            <i aria-hidden="true"  [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor" (click)="resetValue()"></i>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="post-type-grid">
      <app-retail-table [attr.automationId]="'Tbl_memberAccrualMapping_member'" [searchvalue]="searchText" [options]="tableoptions" class="h-100"
      [isViewOnly]="IsViewOnly"  (DataClickEvtEmitter)="rowDataClicked($event)">
      </app-retail-table>
    </div>
  </section>
  <div mat-dialog-actions class="actions-div">
    <div class="pl-4">
      <button [attr.automationId]="'Btn_memberAccrualMapping_save'" [ngClass]="isValid ? 'button--primary-save' : 'button--disabled'" mat-button [disabled]="isDisabled"
        (click)="savememberMappings($event)">{{commonCaptions.Save}}</button>
      <button [attr.automationId]="'Btn_memberAccrualMapping_cancel'" class="spa-button-cancel" mat-button (click)="cancelMemberMappings()">{{commonCaptions.Cancel}}</button>
    </div>
  </div>
