import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material-module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CommissionSetupRoutingModule } from './commission-setup-routing.module';
import { RetailCommissionSetupComponent } from './commission-setup.component';
import { RetailCommissionSetupTableComponent } from './commission-setup-table/commission-setup-table.component';
import { RetailSharedModule } from '../shared/retail-shared.module';
import { SPAConfig } from '../common/config/SPA-config'; 
import { HttpServiceCall } from '../shared/service/http-call.service';
import { HttpRequestService } from '../shared/service/http-request.service';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { SessionManagerService } from '../common/services/session-manager.service'; 
import { FormatText } from '../shared/pipes/formatText-pipe.pipe';
import { BreakPointAccess } from '../shared/service/breakpoint.service';
import { RetailService } from '../retail.service';
import { MessageService } from '../shared/service/messageservice.service';
import { UserAlerts } from '../common/config/alerts-config';
import { ViewMoreServiceService } from '../shared/view-more/view-more-service.service';
import { CommissionSetupService } from './commission-setup.service';
import { RetailToggleSwitchModule } from '../retail-toggle-switch/retail-toggle-switch.module';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { ImageProcessorService } from '../shared/service/image-processor-service';
import { CommonSharedModule } from 'src/app/common/shared/shared/shared.module';
import { MatDialogRef } from '@angular/material/dialog'; 

@NgModule({
    declarations: [
        RetailCommissionSetupComponent,
        RetailCommissionSetupTableComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        NgScrollbarModule,
        CommissionSetupRoutingModule,
        RetailSharedModule,
        CommonSharedModule,
        RetailToggleSwitchModule
    ],
    exports: [
        RetailCommissionSetupComponent,
        RetailCommissionSetupTableComponent
    ],
    providers: [
        SPAConfig,
        HttpServiceCall,
        HttpRequestService,
        RetailPropertyInformation,
        SessionManagerService,
        FormatText,
        BreakPointAccess,
        RetailService,
        MessageService,
        UserAlerts,
        ViewMoreServiceService,
        CommissionSetupService,
        RetailSetupService,
        ImageProcessorService,
        {
            provide: MatDialogRef,
            useValue: {}
        }
    ]
})
export class CommissionSetupModule { }
