<div class="messages-view" *ngFor="let msg of dashboardMessages" id="msg_{{msg.id}}"
  [ngClass]="{'messages-view__unread-bg':msg.viewed,'messages-view__selectEnabled':selectEnabled}">
  <!-- <mat-checkbox class="messages-view__select-message" *ngIf="selectEnabled"></mat-checkbox> -->
  <dynamic-form  *ngIf ="selectEnabled" [config]="config" #form="dynamicForm" (submit)="login($event)">
  </dynamic-form>
  <div class="messages-view__details">
    <div class="messages-view__header">
      <div class="messages-view__intials-view" *ngIf="!msg.IsIncoming">
        <span>YOU</span>
        <span class="messages-view__msg-medium">4</span>
      </div>

      <!-- <span *ngIf="msg.IsIncoming">{{dashboardData.Contact.FirstName | slice:0:1}}{{dashboardData.Contact.FirstName | slice:0:1}}</span> -->
    </div>
    <!-- <div class="msg-text"> -->
    <div class="messages-view__sender-view">
      <label class="messages-view__sender-name">{{msg.name}}</label>     
    </div>
    <span class="messages-view__msg-text">{{msg.Text}} </span>
    <label class="messages-view__msg-timing">{{msg.status}}</label>
    <div class="messages-view__msg-close">
        <i class="icon-Group-591 messages-view__close-message"   (click)="closeMessage($event,msg)"></i>
    </div>
  </div>

  <!-- </div> -->
</div>