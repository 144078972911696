import { ActionMode } from 'src/app/common/shared/shared/enums/enums';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { API as Dept } from './department.model';
import { API as categ } from './category.model';
import { UntypedFormGroup } from '@angular/forms';

export interface RevenueTypesModalInput {
    postTypeName: string;
    postTypeNumber: number;
    listOrder: number;
    active: {
        value: boolean;
        isDisabled: boolean;
    }
}

export enum Characteristics {
    debit = 1,
    creditCard = 2,
    cash = 3,
    cityLedger = 4,
    check = 5,
    otherCredit = 6,
    creditPost = 7
}

export enum Category {
    revenue = 1,
    nonRevenueDebits = 2,
    settlements = 3,
    nonSettlementCredits = 4,
}

export interface VendorDetails {
    selected: boolean;
    type: string;
    generalLedgerNumber: string;
}


export enum DialogCloseEnum {
    Close,
    GenerateLink,
    Cancel,
    Apply
}

// Added
export namespace API {

    export interface DepartmentCategoryInfo {
        departments: Dept.Department[];
        categories: categ.Category[];
    }

    export interface PostType {
        id?: number;
        department?: number;
        category: number;
        postTypeNumber: number;
		    postTypeName: string;
		    isActive: boolean;
		    isCredit: boolean;
		    isDeposit: boolean;
		    postTypeCatergoryId: number;
		    postTypeCharacteristicsId: number;
		    listOrder?: number;
        compOrder?: number;
        postTypeAdditionalInfo: PostTypeAdditionalInfo;
        postTypeGrouping: PostTypeGrouping[];
        postTypeTax: PostTypeTax[];

    }

    export interface PostTypeCompOrder {
        id: number;
        compOrder: number;
    }

    export interface PostTypeSearchParam {
        department: number[];
        isActive: boolean;
        isCredit: boolean;
        includeRelatedData: boolean;
    }

    export interface PostTypeAdditionalInfo {
        id?: number;
        postTypeId: number;
        defaultAmount: number,
        defaultBuildingId: number;
        ceilingLimit: number,
        floorLimit: number,
        groupingType: number;
        isRequireReference: boolean;
        isRequireComment: boolean;
        isRestrictReservationPost: boolean;
        isPasscode: boolean;
        isRoomRevenue: boolean;
        isFoodAndBevClassifier: boolean;
        defaultFolio: number;
        defaultMasterFolio: number;
        gLNumber: string;
        gLOffSetNumber: string;
        sageExtractNumber: string;
        extractRevenueBucket: string;
        isCommissionable: boolean;
        isCommissionPercentage: boolean;
        commissionPercentage: number;
        isChargedToCondoOwner: boolean;
        commissionAmount: number;
        isGiftCard: boolean;
        giftCardPaymentTypeId: number;
    }

    export interface PostTypeGrouping {
        postTypeId: number;
        linkedPostTypeId: number;
        value: number;
    }

    export interface PostTypeTax {
        postTypeId: number;
        taxId: number;
    }


    export interface Category {
        id?: number;
        name: string;
        departmentId: number;
        ListOrder?: number;
    }

    export interface Departments {
        id?: number;
        name: string;
        ListOrder?: number;
    }

    export interface GroupDefaults {
        id: number,
        postTypeId: number,
        folioNumber: string,
        sourceType: string,
        bookingId: number
    }
}
export interface PostTypeModalInput {
    mode: ActionMode | FromTypeEnum;
    actionButton: string;
    form: UntypedFormGroup;
    isCopy?: boolean;
    listOrder?: number;
    departments?: API.Departments[];
    categories?: API.Category[];
    isViewOnly: boolean
}

export namespace UI {

    export interface PostType {
        id?: number;
        department: {
            id: number;
        };
        category:
        {
            id: number;
        };
        postTypeNumber: number;
        postTypeName: string;
        active: {
            value: boolean;
            isDisabled: boolean;
        };
        credit: boolean;
        deposit: boolean;
        postTypeCategory:
        {
            id: number;
        };
        characteristics:
        {
            id: number;
        };
        listOrder: number;
        additionalInfoId ? :number;
        postTypeAdditionalInfo: PostTypeAdditionalInfo;
        postTypeGrouping: PostTypeGrouping[];
        postTypeTax: PostTypeTax[];
        checkedListPostTypes: CheckedListPostTypes[];
        groupingFunctionality: number
    }

    export interface PostTypeAdditionalInfo {
        id: number;
        postTypeId: number;
        defaultAmount: number,
        defaultBuilding:
        {
            id: number;
        };
        ceilingLimit: number,
        floorLimit: number,
        groupingFunctionality: number;
        requireReference: boolean;
        requireComment: boolean;
        restrictReservationPost: boolean;
        passcode: boolean;
        roomRevenue: boolean;
        foodAndBeverageClassifer: boolean;
        defaultFolio:
        {
            id: number;
        };
        defaultMasterFolio:
        {
            id: number;
        };
        generalLedgerNumber: string;
        generalLedgerOffsetNumber: string;
        sageExtractNumber: string;
        extractRevenueBucket: string;
        commissionable: boolean;
        useCommission: boolean;
        commissionPct: number;
        chargedToCondoOwner: boolean;
        commissionFlat: number;
        giftCardFunctionality: boolean;
        giftCardType:
        {
            id: number;
        };
    }

    export interface PostTypeGrouping {
        id: number;
        postTypeId: number;
        linkedPostTypeId: number;
        value: string;
    }

    export interface CheckedListPostTypes {
        id: number;
        postNumber: number;
        price: string;
    }



    export interface PostTypeTax {
        id: number;
        postTypeId: number;
        taxId: number
    }

    export interface Category {
        id?: number;
        name: string;
        departmentId: number;
        listOrderLinkId?: number;
    }

    export interface Departments {
        id?: number;
        name: string;
        listOrderLinkId?: number;
    }

    export interface GroupEdit {
        id?: number;
        departmentId: number;
        checked?: boolean;
        postNumber: number;
        price: number;
    }
}
export enum GroupingCategory {
    None = 0,
    Fixed = 1,
    Percentage = 2,
    Open = 3,
}
