<ng-scrollbar>
  <div class="refund-popup">
    <div class="refund-popup__amount seat-header-three">
      <span>{{'refundAmount' | translate}}: {{this.data.PaymentAmount ? this.data.PaymentAmount : this.data.OverpaymentAmount}}</span>
    </div>
    <ng-container *ngFor="let option of refundOptions;let index = index">
      <div class="card">
        <div class="card__type">
          <mat-checkbox class="card__type__checkbox" [(ngModel)]="option.checked"
            (change)="selectType($event.checked, index )">
            <span class="card__type__name"
              *ngIf="option.data.PaymentType == AuthorizePaymentTypes.Payment_Only">{{'CreditCard' | translate}}</span>
            <span class="card__type__name"
              *ngIf="option.data.PaymentType == AuthorizePaymentTypes.Post_To_RoomCharge">{{'RoomCharge' |
              translate}}</span>
            <span class="card__type__name"
              *ngIf="option.data.PaymentType == AuthorizePaymentTypes.Loyalty_Point">{{'LoyaltyPoints' |
              translate}}</span>
          </mat-checkbox>
        </div>
        <div class="card__field" *ngIf="option.data.PaymentType == AuthorizePaymentTypes.Payment_Only">
          <div class="card__field-label">{{'CardNumber' | translate}}</div>
          <div class="card__field-value">{{option.data.MaskedCardNumber}}</div>
        </div>

        <div class="card__field" *ngIf="option.data.PaymentType == AuthorizePaymentTypes.Post_To_RoomCharge">
          <div class="card__field-label">{{'RoomNumber' | translate}}</div>
          <div class="card__field-value">{{(option.data.RoomNumber || 'NA') | translate}}</div>
        </div>

        <div class="card__field" *ngIf="option.data.PaymentType == AuthorizePaymentTypes.Loyalty_Point">
          <div class="card__field-label">{{'BucketName' | translate}}</div>
          <div class="card__field-value">{{(option.data.BucketName || 'NA') | translate}}</div>
        </div>
        <div class="card__field">
          <div class="card__field-label">{{'ChargedOn' | translate}}</div>
          <div class="card__field-value">{{option.data.TransactionDate | localizedDate: _settings.General.DateFormat}}</div>
        </div>
        <div class="card__field">
          <div class="card__field-label">{{'AmountPaid' | translate}}</div>
          <div class="card__field-value">{{option.data.PaymentAmount}}</div>
        </div>
        <div class="card__field">
          <div class="card__field-label">{{'MembershipId' | translate}}</div>
          <div class="card__field-value">{{(data.memberShipId || 'NA') | translate}}</div>
        </div>
        <div class="card__field card__field__amount">
          <label class="card__field__seat-labels" for="">{{'RefundAmount' | translate}}</label>
          <dynamic-form [config]="option.refundAmountConfig" #form="dynamicForm">
          </dynamic-form>
        </div>
      </div>
    </ng-container>
  </div>
</ng-scrollbar>