<div *ngIf="currentPackage" class="parties-grid__scrollSize package-info" style="width: 240px;">
  <div class="reservation-notes">
    <div class="reservation-notes__header seat-popover-header Package-name">
      {{'PackageName' | translate}}
    </div>
    <div class="reservation-notes__content seat-popover-content Package-popover">
      <div *ngFor="let package of currentPackage;" class="Package-list">

        <div class="Package-Code">
            <span class="seat-tertiary-text">{{'PackageName' | translate}} :</span>
            <span class="seat-tertiary-text">{{package.PackageName}}</span>
        </div>

        <div class="Package-Code" >
            <span class="seat-tertiary-text">{{'PackageCode' | translate}} : </span>
            <span class="seat-tertiary-text">{{package.PackageCode}}</span>
        </div>
      </div>
    </div>
  </div>
</div>