import { Component,  ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'shipping-info',
    templateUrl: './shipping-info.component.html',
    styleUrls: ['./shipping-info.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class ShippingInfoComponent { 
      
    
}



