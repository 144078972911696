import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  AlertAction,
  AlertType,
  ButtonType,
  ButtonTypes,
  Product,
  PlayerCategory
} from "src/app/common/enums/shared-enums";
import { ButtonValue } from "src/app/common/Models/ag-models";
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { FolioBusiness } from "../../Folio/folio-business";
import {
  CreateDefaultFolioRequest,
  ErrorConstants,
  FolioLookup,
  FolioSearchConstants,
  LengthConstants,
  MiscConfigurationSwitches,
  SearchType,
  SourceType,
} from "../../Folio/Model/folioDetails-model";
import { PayeeInfo } from "../../shared/business/shared.modals";
import { ReplaySubject, Subject } from "rxjs";
import { debounceTime, mergeMap, takeUntil } from "rxjs/operators";
import { SEARCH_DEBOUNCE_TIME } from "src/app/common/shared/shared/setupConstants";
import { RetailDataAwaiters } from "../../shared/events/awaiters/retail.data.awaiters";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { Router } from "@angular/router";
import { FacadeService } from "src/app/common/services/facade.service";
import { UserAccessService } from "src/app/common/services/user-access.service";
import { UserAccessBreakPoints } from "../../shared/constants/useraccess.constants";
import { MemberBusinessService } from "../../shared/business/Member-business.service";
import { ClientType } from "../../shared/globalsContant";
import { NotifierBar } from "src/app/common/components/note/note.model";

@Component({
  selector: "app-folio-dialog-popup",
  templateUrl: "./folio-dialog-popup.component.html",
  styleUrls: ["./folio-dialog-popup.component.scss"],
  providers : [FacadeService,UserAccessService]
})
export class FolioDialogPopupComponent implements OnInit {
  floatLabel: string;
  captions: any;
  form: UntypedFormGroup;
  folioTypeSearch: boolean = false;
  allData: any = [];
  selectedGuestTagKey: string[] = ["name"];
  autoCompleteKeys: string[] = ['name'];
  searchGuestMaxLength = 50;
  tagSearchKey: string[] = ["name"];
  selectedTagData: any = [];
  proceedButton: ButtonValue;
  cancelButton: ButtonValue;
  folios: any = [];
  selectedFolio: any;
  showSearch: boolean = false;
  payeeInfo: PayeeInfo;
  SearchString: string = "";
  searchByFolio: boolean = true;
  searchByMember: boolean = false;
  guestSearchTextChanged = new Subject<string>();
  folioSearchTextChanged = new Subject<string>();
  memberSearchTextChanged = new Subject<string>();
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedGuest: any;
  commonCaptions = this.localization.captions;
  folioInvoiceNumber: string = '';
  overAllBalance: string = '';
  defaultFolio = {
      name: this.commonCaptions.lbl_default,
      number: this.commonCaptions.lbl_default,
      default: true,
      balance: '0',
      outstandingBalance: '0',
      id: 0,
      folioInvoiceNumber: '',
      sourceType:'',
      interfaceGuestId: ''
  }
  folioMenubp: boolean = false;
  addOnMember = SourceType.AddOnMember;
  isFolioEngageTurnedOn : boolean = false;
  selectedGuestInfoId : number = 0;
  noteBar: NotifierBar;
  noteFlag: boolean = false;
  isFromShop : boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<FolioDialogPopupComponent>,
    private fb: UntypedFormBuilder,
    public localization: RetailLocalization,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils: CommonUtilities,
    private business: FolioBusiness,
    private cd: ChangeDetectorRef,
    private retailUtils: RetailUtilities,
    private route: Router,
    private facadeService: FacadeService,
    private userAccessService: UserAccessService,
    private memberService: MemberBusinessService
  ) {
    this.captions = this.localization.captions.shop;
    this.floatLabel = this.localization.setFloatLabel;
    this.SearchString = this.captions.srch_folioname;
  }



  async ngOnInit() {
    this.selectedGuest = this.data.data.payeeInfo;
    this.isFromShop = this.data.isFromShop;
    this.initializeForm();
    let sessionValue = sessionStorage.getItem('FolioConfigurationSwitches');		
			let config = sessionValue ? JSON.parse(sessionValue) : null;
			if (config?.configValue) {
				var configValues = JSON.parse(config.configValue),
					result = Object.keys(configValues).map(k => ({
						configurationKey: [k][0],
						configurationValue: configValues[k]
					}));
			}
    const productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
		const isEngageFeatureOn: boolean = result?.find(x => x.configurationKey === MiscConfigurationSwitches.agilysysEngage)?.configurationValue ?? false;
    if (productId == Product.SPA && isEngageFeatureOn) {
      this.isFolioEngageTurnedOn = true;
    }
    if (this.selectedGuest) {
      this.selectedGuest.guestId = (this.selectedGuest?.playerCategoryId == PlayerCategory.Member || this.selectedGuest?.guesttype == ClientType.Member) ? this.data.data.memberGuestId : this.selectedGuest?.guestProfileId;
    }

    this.guestSearchTextChanged
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        mergeMap((source) => this.searchGuestByName(source))
      ).pipe(takeUntil(this.destroyed$)).subscribe();

    this.memberSearchTextChanged
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        mergeMap((source) => this.searchMemberByName(source))
      ).pipe(takeUntil(this.destroyed$)).subscribe();

    this.folioSearchTextChanged
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        mergeMap((source) => this.searchByFolioInvoiceNumber(source))
      ).pipe(takeUntil(this.destroyed$)).subscribe();
 
    if (this.data.data.isGuest) {
      try
      {        
        await this.getFolioDetails(this.selectedGuest.guestId);
        if (this.folios?.length > 0) {         
          this.folioTypeSearch = true;
        } else {
          this.utils.showCommonAlert(this.captions.warn_create_new_folio, AlertType.Warning, ButtonTypes.YesNo, (res) => {
            if (res === AlertAction.YES) {            
              this.createDefaultFolioForGuest();
              this.patchSelectedFolio();              
            } else {
              this.selectedGuest = null;
            }
          });
        }
      }
      catch(e)
      {
        this.retailUtils.ToggleLoaderWithMessage(false);
        this.handleError(e,SearchType.GUESTINFO);       
      }    
    
    }
    this.CheckBreakPointAccess();
  }

  CheckBreakPointAccess(){
    this.facadeService.getUserAccess(UserAccessBreakPoints.FOLIOMENU, false).then(o => {
			this.folioMenubp = o.isAllow;
		});
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  initializeForm() {
    let type = this.selectedGuest?.playerCategoryId == PlayerCategory.Member ? "member" : "folio";
    this.form = this.fb.group({
      folio: [""],
      type: [type],
    });
    this.proceedButton = {
      label: this.captions.btn_proceed,
      type: "primary",
      disabledproperty: true
    };
    this.cancelButton = {
      label: this.captions.cancel,
      type: "secondary",
    };
  }

  onGuestChipRemoveEvent(event: any) {
    this.allData = [];
    this.selectedGuest = null;
    this.enableDisableProceedBtn(true);
    this.folios = [];
    this.folioInvoiceNumber = ''
    this.overAllBalance = '';
    this.selectedFolio = null;
  }

  tagSearchTextEmit(searchInput: string) {
    if (!this.searchByFolio && !this.searchByMember && searchInput?.length >= LengthConstants.guestLen) {
      this.guestSearchTextChanged.next(searchInput);
    }
    else if (this.searchByMember && searchInput?.length >= LengthConstants.guestLen) {
      this.memberSearchTextChanged.next(searchInput);
    }
    else if (this.searchByFolio && searchInput?.length > LengthConstants.FolioLen) {
      this.folioSearchTextChanged.next(searchInput);
    }
    else {
      this.allData = [];
    }
  }

  enableDisableProceedBtn(value: boolean) {
    this.proceedButton.disabledproperty = value;
    this.proceedButton = { ...this.proceedButton };
  }

  onBlur(e) { }

  setGuestId(id, GuestId) {
    this.selectedGuest.guestId = GuestId;
    this.data.data.memberGuestId = GuestId;
    this.selectedGuestInfoId = id;
  }

  async selectedTagEmit(eve) {
    this.selectedGuest = eve[0];
    if (this.searchByFolio) {
      this.patchSelectedFolio();
    } 
    else if (this.searchByMember) {
      let scheduleDate = new Date();
      this.retailUtils.ToggleLoaderWithMessage(true,this.commonCaptions.lbl_inProgress)
      const memberInfo = await this.memberService.getMemberInfo(this.selectedGuest?.guestId, scheduleDate.toISOString())
      if (memberInfo == null) {
        this.retailUtils.ToggleLoaderWithMessage(false)
        this.closeFolio();
        return;
      }
      await RetailDataAwaiters.CreatePlayer(this.selectedGuest, this.setGuestId.bind(this)); 
      await this.searchByMemberGuestId(this.selectedGuest?.guestId);
      console.log(this.selectedGuest)
      if (this.selectedGuest?.folios?.length > 0) {
        this.patchSelectedFolio();
      }
    } 
    else {
      this.selectedGuestInfoId = this.selectedGuest.id;
      await this.searchByGuestId(this.selectedGuest.guestId);
      console.log(this.selectedGuest)
      if (this.selectedGuest?.folios?.length > 0) {
        this.patchSelectedFolio();
      } 
      // else {
      //   this.utils.showAlert(this.captions.warn_create_new_folio, AlertType.Warning, ButtonType.YesNo,
      //     (res) => {
      //       if (res === AlertAction.YES) {
      //         this.createDefaultFolioForGuest();
      //       } else {
      //         this.selectedGuest = null;
      //         this.dialogRef.close({ from: 'cancel' });
      //       }
      //     }
      //   );
      // }
    }
  }

  patchSelectedFolio() {
    this.folioTypeSearch = true;
    this.folios = [];
    this.folios = this.selectedGuest?.folios;
    this.folioInvoiceNumber = this.selectedGuest?.folio?.folioInvoiceNumber;
    this.overAllBalance = this.localization.localizeCurrency(this.selectedGuest?.folio?.overAllBalance);   
    this.defaultFolio.outstandingBalance = this.folios?.find(x => x.isDefault)?.outstandingBalance;
    this.defaultFolio.balance = this.folios?.find(x => x.isDefault)?.balance;    
    this.defaultFolio.sourceType = this.folios && this.folios[0] ? this.folios[0].sourceType : SourceType.AddOn.toString();
    this.defaultFolio.folioInvoiceNumber = this.folios && this.folios[0] ? this.folios[0].folioInvoiceNumber : '';
    this.defaultFolio.interfaceGuestId = this.folios && this.folios[0] ? this.folios[0].interfaceGuestId : '0';
    this.folios.unshift(this.defaultFolio);
    this.selectedFolio = this.folios[0];
    this.enableDisableProceedBtn(false);
  }

  createDefaultFolioForGuest() {
    this.retailUtils.ToggleLoaderWithMessage(true,this.commonCaptions.lbl_inProgress)
    try
    { 
      let guestResortFinanceFolioCardTokenReference = 0;
      const guestCardOnFileTokenReference = this.selectedGuest?.cardInfo ? this.selectedGuest?.cardInfo[0]?.tokenTransId : 0;
      if(guestCardOnFileTokenReference){
        this.business.GetResortFinanceCardTokenReference(guestCardOnFileTokenReference).then(result =>{
          guestResortFinanceFolioCardTokenReference = result;
          this.createFolio(guestResortFinanceFolioCardTokenReference);
        });
      }  
      else{
        this.createFolio(0);
      } 
      
    }
    catch(e)
    {     
      this.retailUtils.ToggleLoaderWithMessage(false);
    }
  
  }

  createFolio(guestCardTokenReference){
    const request: CreateDefaultFolioRequest = {
      sourceType: this.business.getSourceType(this.data?.data),
      sourceTypeId: this.selectedGuest.guestId,
      paymentTransactionId: guestCardTokenReference
    };
    this.createDefaultFolio(request).then(async (x) => {
      const folio = await this.getFolioDetails(
        this.selectedGuest.guestId
      );
      this.folioTypeSearch = true;
    
    this.retailUtils.ToggleLoaderWithMessage(false);
    });
  }


  onAction() {
    this.selectedFolio.folioInvoiceNumber = this.folioInvoiceNumber;
    this.dialogRef.close({
      from: 'save',
      selectedGuest: this.selectedGuest,
      selectedFolio: this.selectedFolio,
      folios: this.folios
    });
  }

  folioChange(eve, i) {
    this.folios.forEach((x) => {
      x.default = false;
    });
    this.selectedFolio = eve;
    this.folios[i].default = true;
  }

  closeFolio() {
    this.selectedTagData = [];
    this.showSearch = true;
    this.folioTypeSearch = false;
    this.allData = [];
    this.selectedGuest = null;
    this.enableDisableProceedBtn(true);
    this.folios = [];
    this.selectedFolio = null;
    this.folioInvoiceNumber = '';
    this.overAllBalance = '';
  }

  typeChange(eve) {
    switch (eve.value) {
      case FolioSearchConstants.Folio:
        this.SearchString = this.captions.srch_folioname;
        this.searchByFolio = true;
        this.searchByMember = false;
        this.noteFlag = false;
        break;
      case FolioSearchConstants.Guest:
        this.SearchString = this.captions.srch_guest;
        this.searchByFolio = false;
        this.searchByMember = false;
        this.noteFlag = false;
        break;
      case FolioSearchConstants.Member:
        if (this.isFromShop) {
          this.noteBar = {
            class: '',
            header: this.captions.MemberNoteHeader,
            value1: this.captions.MemberDiscountNoteInfo,
            value2: this.captions.MemberDiscountNoteConfirmation,
            isRemovable: false
          }
          this.noteFlag = true;
        }
        this.SearchString = this.captions.srch_member;
        this.searchByFolio = false;
        this.searchByMember = true;
        break;
    }
  }

  async getFolioDetails(guestId: string) {
    this.retailUtils.ToggleLoaderWithMessage(true,this.commonCaptions.lbl_inProgress)
    const request: FolioLookup = {
      sourceType: this.business.getSourceType(this.data?.data),
      folioInvoiceNumber: [],
      sourceTypeId: [guestId],
      status: [0]
    }
    const foliodetail = await this.business.folioLookup(request);
    this.folios = [];
    if (foliodetail && foliodetail.length > 0) {
      const data = foliodetail[0];
      data.folioLookupData.forEach((x) => {
        this.folios.push({
          id: x.folioId,
          name: x.folioName,
          number: x.folioNumber,
          balance: this.localization.localizeCurrency(x.creditLimit),
          isDefault : x.isDefault,
          default: false,
          folioInvoiceNumber: data.folioInvoiceNumber,
          outstandingBalance: this.localization.localizeCurrency(x.outstandingBalance),
          sourceType: data.sourceType,
          interfaceGuestId: data.guestDetailInfo?.interfaceGuestId
        });
      });
      this.folioInvoiceNumber = this.folios[0]?.folioInvoiceNumber;
      this.overAllBalance = this.localization.localizeCurrency(data.overAllBalance.toString());
      this.defaultFolio.outstandingBalance = this.folios.find(x => x.isDefault)?.outstandingBalance;
      this.defaultFolio.balance = this.folios.find(x => x.isDefault)?.balance;
      this.defaultFolio.sourceType = data.sourceType;
      this.defaultFolio.folioInvoiceNumber = data.folioInvoiceNumber;
      this.defaultFolio.interfaceGuestId = data.guestDetailInfo?.interfaceGuestId
      this.folios.unshift(this.defaultFolio);
      this.selectedFolio = this.folios[0];    
      this.enableDisableProceedBtn(false);
    } else {
      this.enableDisableProceedBtn(true);
      this.selectedFolio = null;
      this.folioInvoiceNumber = ''
      this.overAllBalance = '';
    }
    this.retailUtils.ToggleLoaderWithMessage(false);
    return foliodetail;
  }

  async createDefaultFolio(folio: CreateDefaultFolioRequest) {
    const foliodetail = await this.business.createDefaultFolio(folio);
    return foliodetail;
  }

  async searchGuestByName(name: string) {
    const requestUid = Date.now() + "" + this.utils.getRandomDecimal() * 10000;
    const guestInfo = await RetailDataAwaiters.searchPayee(name, 2, requestUid);
    if (guestInfo) {
      console.log(guestInfo);
      this.allData = this.uiMapper(guestInfo[1]);
      this.cd.detectChanges();
      console.log(this.allData);
    }
  }

  async searchMemberByName(name : string) {
    const guestInfo = await this.memberService.searchGuest(name, 1);
    if (guestInfo) {
      console.log(guestInfo);
      this.allData = this.uiMapper(guestInfo[1]);
      this.cd.detectChanges();
      console.log(this.allData);
    }
  }

  uiMapper(guests) {
    const guestInfos = [];
    guestInfos.push(
      guests.map((guest) => {
        return {
          id: guest.id,
          name: guest.name,
          address: guest.address,
          guestId: guest.guestProfileId,
          phoneNumber: guest.phoneNumber,
          country: guest.country,
          zip: guest.zip,
          city: guest.city,
          cardInfo: guest.cardInfo,
          folios: [],
          playerLinkId: guest.guestProfileId,
          firstName: guest.firstName,
          lastName: guest.lastName,
          pronounced: '',
          paymentReferenceId: guest?.paymentReferenceId
        };
      })
    );
    return guestInfos[0];
  }

  async searchByMemberGuestId(guestId: string) {
    if (guestId) {
      const request: FolioLookup = {
        sourceType: SourceType.AddOnMember.toString(),
        sourceTypeId: [guestId],
        folioInvoiceNumber: [],
        status: [0]
      };
      await this.folioSearch(request, SearchType.GUESTINFO);
    }
  }

  async searchByFolioInvoiceNumber(folioNumber: string) {
    const request: FolioLookup = {
      sourceType: this.business.getSourceType(this.data?.data),
      sourceTypeId: [],
      folioInvoiceNumber: [folioNumber],
      status: [0],
      includeMemberFolio: true
    };
    await this.folioSearch(request, SearchType.FOLIOINVOICENUMBER);
  }

  async searchByGuestId(guestId: string) {
    const request: FolioLookup = {
      sourceType: this.business.getSourceType(),
      sourceTypeId: [guestId],
      folioInvoiceNumber: [],
      status: [0]
    };
    await this.folioSearch(request, SearchType.GUESTINFO);
  }

  async folioSearch(requestWithFolioInvoiceNumber: FolioLookup, searchType: SearchType) {
    let folioInfo;
    try {
      this.retailUtils.ToggleLoaderWithMessage(true,this.commonCaptions.lbl_inProgress)
      folioInfo = await this.business.folioLookup(requestWithFolioInvoiceNumber);
      this.retailUtils.ToggleLoaderWithMessage(false)
    }
    catch (e) {
      console.log(e);
      this.retailUtils.ToggleLoaderWithMessage(false)
      this.handleError(e,searchType);
    }


    if (folioInfo && folioInfo?.length > 0) {
      const data = [];
      folioInfo.forEach(r => {
        const folios = [];
        if (r.folioLookupData?.length > 0) {
          r.folioLookupData.forEach((x) => {
            folios.push({
              id: x.folioId,
              name: x.folioName,
              number: x.folioNumber,
              balance: this.localization.localizeCurrency(x.creditLimit),
              default: false,
              folioInvoiceNumber: r.folioInvoiceNumber,
              isDefault : x.isDefault,
              outstandingBalance: this.localization.localizeCurrency(x.outstandingBalance),
              sourceType: r.sourceType,
              interfaceGuestId: r.guestDetailInfo?.interfaceGuestId
            });
          });
        }

        this.defaultFolio.outstandingBalance = folios?.find(x => x.isDefault)?.outstandingBalance;
        this.defaultFolio.balance = folios?.find(x => x.isDefault)?.balance;       

        data.push({
          id: 0,
          sourceType: r.sourceType,
          guestId: r.sourceTypeId,
          guestInfoId: this.selectedGuestInfoId,
          folioNumber: r.folioInvoiceNumber,
          overAllBalance: this.localization.localizeCurrency(r.overAllBalance),
          lastName: r.guestDetailInfo.lastName,
          firstName: r.guestDetailInfo.firstName,
          name: r.guestDetailInfo.firstName + ' ' + r.guestDetailInfo.lastName,
          phoneNumber: r.guestDetailInfo.phoneNumber?.length > 0 ? r.guestDetailInfo.phoneNumber[0] : '',
          email: r.guestDetailInfo.email?.length > 0 ? r.guestDetailInfo.email[0] : '',
          folio: r,
          folios: folios
        })
      });
      if (searchType == SearchType.GUESTINFO) {
        this.selectedGuest = data[0];
      }
      this.allData = data;
      this.cd.detectChanges();
    }
  }

  handleError(e:any, searchType: SearchType)
  {
    if (e.error && e.error.errorCode && e.error.errorCode == ErrorConstants.NOFOLIODATA) {
      if(searchType == SearchType.GUESTINFO)
      {
        this.utils.showCommonAlert(this.captions.warn_create_new_folio, AlertType.Warning, ButtonTypes.YesNo,
          (res) => {
            if (res === AlertAction.YES) {       
              this.createDefaultFolioForGuest();
              this.patchSelectedFolio();     
            } else {
              this.selectedGuest = null;
              // this.dialogRef.close({ from: 'cancel' });
              this.allData = [];
            }
          }
        );
      }
      else
      {
        this.allData = [];
      }
      
    }
    else {
      if (e.error && e.error.result) {
        this.utils.showError(e.error.result);
      }
      else {
        this.utils.showError("Unexpected error");
      }


    }

  }

  onCancel() {
    this.dialogRef.close({ from: 'cancel' });
  }
  viewFolioInNewTab(){
			if(this.folioMenubp){	
          let folioInput = {
            sourceType: this.selectedFolio?.sourceType,
            sourceTypeId: this.selectedGuest.guestId,
            folioNumber:this.folioInvoiceNumber,
            fullName: this.selectedGuest?.name,
            interfaceGuestId: this.selectedFolio?.interfaceGuestId
          }
          localStorage.setItem("checkFromNewTab",JSON.stringify(folioInput));
          let productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
          let url;
          if(productId == Product.SPA){
            url = '/Spa/folio/foliosearch';
          } else {
            url ='/Golf/folio/foliosearch';
          }
          this.route.navigate([], {
          }).then(result => { window.open(url, '_blank'); });
      } else {
        this.userAccessService.showBreakPointPopup(this.localization.captions[UserAccessBreakPoints.FOLIOMENU]);
      }
  }
}
