import { ChangeAction, PartyType } from '@constants/commonenums';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { PartyNoteDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import { Processor } from './processor';
import * as _ from 'lodash';
import { RestaurantStateService } from '@app/shared/services/restaurant-state.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { AppService } from '@app/app.service';

export class PartyNoteChangeProcessor implements Processor {

  private static instance: PartyNoteChangeProcessor;
  private constructor(public partyService: PartyService, public stateService : RestaurantStateService,public as: AppService) { }

  public static Instance(componentService: any = PartyService, rss: RestaurantStateService,_as: AppService ): PartyNoteChangeProcessor {
    if (!PartyNoteChangeProcessor.instance) {
      PartyNoteChangeProcessor.instance = new PartyNoteChangeProcessor(componentService,rss ,_as);
    }

    return PartyNoteChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: object,propertyId: number): void {
    let partiesList;
    let IsStandbyParty : boolean = false;
    let selectedContactParties = this.partyService.SelectedContactParties$.value;
    if(this.checkStandby(objectChange,propertyId))
    {
      IsStandbyParty = true;
      if (propertyId == Utilities.RestaurantId()) {
        partiesList = this.partyService.standbyPartiesList;
      }else{
        partiesList = this.as._cs.propertySettings.value[propertyId].StandbyParties;
      }
    }
    else{
      IsStandbyParty = false;
      if (propertyId == Utilities.RestaurantId()) {
      partiesList = this.partyService.partiesList;  // _.cloneDeep(this.partyService.partiesList);
    }
    else{
      partiesList = this.as._cs.propertySettings.value[propertyId].reservations;
     }
    }
    if (objectChange) {
      if (objectChange.Action === ChangeAction.Created) {
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const partyInfo = partiesList.filter(party => party.Id == partyId);
        if (partyInfo && partyInfo.length > 0) {
          this.pushNewNotes(objectChange, partyInfo)
        }

        //for parties in SelectedContactParties$
        const partyInContact = selectedContactParties.filter(party => party.Id == partyId);
        if (partyInContact && partyInContact.length > 0) {
          this.pushNewNotes(objectChange, partyInContact)
        }
      }
      if (objectChange.Action === ChangeAction.Updated && partiesList.length > 0) {
        const updatedPartyNoteId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party = partiesList.find(party => party.Id == partyId);
        if (party) {
          const index = party.Notes.findIndex(note => note.Id == updatedPartyNoteId);
          const partyNote: PartyNoteDTO = party.Notes[index];
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyNote).includes(property.PropertyName.replace('Internal', ''))) {
              partyNote[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
        }
      }
      if (objectChange.Action === ChangeAction.Updated) {
        const updatedPartyNoteId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party = partiesList.find(party => party.Id == partyId);
        if (party) {
          this.updateNotes(objectChange, updatedPartyNoteId, party, partiesList);
        }

        //for parties in SelectedContactParties$
        const partyInContact = selectedContactParties.find(party => party.Id == partyId);
        if (partyInContact) {
          this.updateNotes(objectChange, updatedPartyNoteId, partyInContact, selectedContactParties);
        }

      }
      if (objectChange.Action === ChangeAction.Removed) {
        const removedPartyNoteId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party = partiesList.find(party => party.Id == partyId);
        // Cancelled reservation has to be removed from the list and from the screen
        if (party) {
          this.removeNotes(objectChange,removedPartyNoteId,party, partiesList);
        }

        //for parties in SelectedContactParties$
        const partyInContact = selectedContactParties.find(party => party.Id == partyId);
        if (partyInContact) {
          this.removeNotes(objectChange,removedPartyNoteId,partyInContact, selectedContactParties);
        }
      }
      this.processNoteChangeForPartiesInState(objectChange,propertyId);
    }
    partiesList = _.uniqBy(partiesList, 'Id');
    if(propertyId == Utilities.RestaurantId()){
      if (IsStandbyParty){
        this.partyService.standbyPartiesList = partiesList;
        this.as._cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      }
      else {
        this.partyService.partiesList = partiesList;
        this.as._cs.propertySettings.value[propertyId].reservations = partiesList;
      }
      this.partyService.SelectedContactParties$.next(selectedContactParties); 
    }else{
      if (IsStandbyParty){
        this.as._cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      }
      else {
        this.as._cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    }
  }

  pushNewNotes(objectChange, party){
    const partyNote: PartyNoteDTO = new PartyNoteDTO();
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyNote).includes(property.PropertyName.replace('Internal', ''))) {
              partyNote[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
          party[0].Notes.push(partyNote);
          party[0].Notes = _.uniqBy(party[0].Notes, 'Id');
  }

  updateNotes(objectChange, updatedPartyNoteId, party, partiesList){
    const partyNote: PartyNoteDTO = party.Notes.find(note => note.Id == updatedPartyNoteId);
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyNote).includes(property.PropertyName.replace('Internal', ''))) {
              partyNote[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
          party.Notes[party.Notes.indexOf(partyNote)] = partyNote;
          partiesList[partiesList.indexOf(party)] = party;
  }

  removeNotes(objectChange, removedPartyNoteId, party, partiesList){
    const partyNote: PartyNoteDTO = party.Notes.find(note => note.Id == removedPartyNoteId);
    if (partyNote) {
      party.Notes = party.Notes.filter(note => note.Id != removedPartyNoteId);
    }
    partiesList[partiesList.indexOf(party)] = party;
  }

  checkStandby(change: ObjectChange,propertyId)
  {
    let partyIdList = change.PropertyChanges.filter(property => property.PropertyName.replace('Internal', '') == 'PartyId');
    if (partyIdList && partyIdList.length) {
      let partyDetail = null;
      let objPartyId = partyIdList[0].Value;
      if(propertyId == Utilities.RestaurantId()) {
        partyDetail = this.partyService.StandbyParties$.value.find((party) => party.Id === objPartyId);
      }else{
        partyDetail = this.as._cs.propertySettings.value[propertyId].StandbyParties.filter((party) => party.Id === change.ObjectId);
      }
   
      if (partyDetail && partyDetail.Type == PartyType.StandBy) {
        return true;
      }
    }

    return false;
  }

  processNoteChangeForPartiesInState(change:ObjectChange,propertyId: number){
    this.stateService.processNoteChange(change,propertyId);
  }
}
