import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CounterComponent } from '@app/pos/util-components/counter/counter.component';
import { TranslateModule } from '@ngx-translate/core';
import { GuestSearchGlobalComponent } from '../guest-search-global/guest-search-global.component';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogContainerComponent } from '@app/pos/util-components/dialog-container/dialog-container.component';

@Component({
  selector: 'respos-cart-booking-success',
  standalone: true,
  imports: [CommonModule, FormsModule, CounterComponent, TranslateModule, GuestSearchGlobalComponent, CommonModule, DialogContainerComponent],
  templateUrl: './cart-booking-success.component.html',
  styleUrls: ['./cart-booking-success.component.scss']
})
export class CartBookingSuccessComponent implements OnInit {

  dialogRef = inject(MatDialogRef);

  ngOnInit(): void {
  }

  proceedToPrint(){
    this.dialogRef.close(true);
  }

  goToHome(){
    this.dialogRef.close();
  }

}
