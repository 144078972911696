import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initialFormatter'
})
export class InitialFormatterPipe implements PipeTransform {

  transform(name: any, Lname?: any, type?): any {
    let nameWithInitials = '';
    if (type == 'server') {
      const nameArray = name?.split(' ');
      nameArray.forEach((nameInfo, index) => {
        if (index <= 1)
          nameWithInitials = nameWithInitials + nameInfo.charAt(0);
      });
      nameWithInitials = nameWithInitials + ( Lname ? Lname.charAt(0) : '' )
    } else {
      if (!Lname) {
        const nameArray = name?.split(' ');
        nameArray.forEach((nameInfo) => {
          nameWithInitials = nameWithInitials + nameInfo.charAt(0);
        });
      } else {
        nameWithInitials = (name ? name.charAt(0) : '') + (Lname ? Lname.charAt(0) : '');
      }
    }

    return nameWithInitials.toUpperCase();
  }

}
