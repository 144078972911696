import { Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { PartyState, ReservationType } from '@constants/commonenums';
import { ReservationDTO } from '@models/InputContact';
import { PartyService } from '@services/party.service';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactListComponent extends Utilities implements OnInit {
  @Output() selectedParty = new EventEmitter<any>();
  @Input() communalTableReservations: any;
  selectedIndex: ReservationDTO;
  inputData: any;
  partyState = PartyState;
  subscriptions: Subscription = new Subscription();
  _settings: SettingsDTO = {} as SettingsDTO;
  constructor(dialog: MatDialog,private _as:AppService,private ps: PartyService,private dashboardFunctions: DashboardFunctions) {
    super(dialog);
    this.subscriptions.add(this.ps.cs.settings.subscribe(settings => {
      this._settings = settings;
    }));
   }
  ngAfterViewInit() {
    this.subscriptions.add(this.ps.statusChange$.subscribe(val => {
      let party = this.communalTableReservations.filter(x => x.Id == this.selectedIndex.Id)[0];
      this.selectedCard(party);
    }));
    this.subscriptions.add(this._as.minuteTimer$.subscribe(val => {
      this.inputData.forEach(item =>{
        this.setTimerValues(item);  
        this.startTimer(item);
      })
     
      
    }));
  }

startTimer(item) {
    item.progressBarValue = this.calculatePercentile(item);
  }
  ngOnInit() {
    this.inputData = this.communalTableReservations;
  }

  ngOnChanges() {
    //this.selectedParty.emit(null);
    this.selectedIndex = null;
    this.inputData = this.communalTableReservations;
     this.inputData.forEach(reservation => {
       this.setTimerValues(reservation);
     });
  }
  setTimerValues(party) {
    if (party && party.State == PartyState.Seated) {
      party.remainingTime = this.getRemainingTime(party);
      party.progressBarValue = this.calculatePercentile(party);
      party.quotedTime = this.getQuotedTime(party);
    /// this.inputData = {...this.inputData}
    }
  }

  calculatePercentile(party) {
    const currentTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    if (!party || !party.SeatingTime) {
      return;
    }
    let seatedTimeTill = Math.round((currentTime.getTime() - new Date(party.SeatingTime).getTime()) / 60000);
    if (party.type === ReservationType.Waitlist && party.State !== PartyState.Seated) {
      seatedTimeTill = Math.round((currentTime.getTime() - new Date(party.ArrivedAt).getTime()) / 60000);
    }
    return (seatedTimeTill / this.getTotalSeatingTime(party)) * 100;
  }

  getTotalSeatingTime(party): number {
    if (party.State === PartyState.Seated) {
      return Math.round((new Date(party.DepartureTime).getTime() - new Date(party.SeatingTime).getTime()) / 60000);
    } else if (party.type == ReservationType.Waitlist) {
      return Math.round((new Date(party.SeatingTime).getTime() - new Date(party.ArrivedAt).getTime()) / 60000);
    } else {
      return 0;
    }
  }
  selectedCard(party) {
    this.selectedIndex = party;
    this.selectedParty.emit(party);
  }

  getQuotedTime(party): number {
    if (party.State == PartyState.Seated) {
      return Math.round((new Date(moment(party.DepartureTime).valueOf()).getTime() - new Date(moment(party.SeatingTime).valueOf()).getTime()) / 1000);
    }
    else {
      return 0;
    }
  }

  overrideTimeRemaining(event,party) {
    this.dashboardFunctions.overrideRemainingtime(party);
    event.stopPropagation();
    event.preventDefault();

  }

  getRemainingTime(party): number {
    let time: any;
    if (party.State === PartyState.Seated) {
      time = Utilities.parseDateString(party.DepartureTime);
    } else if (party.State !== PartyState.Seated) {
      time = (party.SeatingTime) ? Utilities.parseDateString(party.SeatingTime) : (party.ReservedFor) ?
        Utilities.parseDateString(party.ReservedFor) : Utilities.parseDateString(party.ArrivedAt);
    }
    return Math.floor((time - Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta).getTime()) / 1000);
  }

  ngOnDestroy() {
    this.selectedIndex = null;
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

}


@Pipe({
  name: 'contactNamePipe'
})
export class ContactNamePipe implements PipeTransform {
  constructor() { }
  transform(party): string {
    if ((party.State === PartyState.Seated) && (party.Contact.FirstName == null || party.Contact.FirstName == '') &&
      (party.Contact.LastName == null || party.Contact.LastName == '')) {
      return 'Unnamed';
    }
    else {
      const FirstName = (party.Contact.FirstName) ? party.Contact.FirstName : ' ';
      const LastName = (party.Contact.LastName) ? party.Contact.LastName : ' ';
      return FirstName + ' ' + LastName;
    }
  }
}


@Pipe({
  name: 'contactNamePipeInitialsPipe'
})
export class ContactNamePipeInitialsPipe implements PipeTransform {
  constructor() { }
  transform(party): string {
    if ((party.State === PartyState.Seated) && (party.Contact.FirstName == null || party.Contact.FirstName == '') &&
      (party.Contact.LastName == null || party.Contact.LastName == '')) {
      return 'UN';
    }
    else {
      const FirstName = (party.Contact.FirstName) ? party.Contact.FirstName : ' ';
      const LastName = (party.Contact.LastName) ? party.Contact.LastName : ' ';
      return FirstName.slice(0, 1) + LastName.slice(0, 1);
    }
  }
}
