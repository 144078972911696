export interface CustomerDataUI {
  checked?: boolean;
  accountId: number;
  accNumber: string;
  accName: string;
  status: string;
  statusId: number;
  preference: string;
  isExemptTax:boolean;
}

export interface CustomerDataAPI {
  id: number;
  code: string;
  name: string;
  status: number;
  arCustomerDetail?: arCustomerDetail;
  category1Id: number;
  category2Id: number;
  miscCategoryId: number;
  isExemptTax:boolean;
}
export interface EstCustomerAccountAPI {
  accountName: string,
  arCode: string,
  status: string,
  preference: string,
  isExemptTax?:boolean;
}
export interface arCustomerDetail {
  preferences: string;
}
export enum ARAccountStatus {
  Permanent = 0,
  Temporary,
  Inactive,
  Hold,
  Expired,
  Frozen,
  AccessToFacilities,
  Suspended
}

export enum SearchBy {
  AccountNumber = 1,
  AccountName,
  Status,
  Reference
}

export interface IntegrationFormSetting {
  id: number;
  moduleName: string;
  screenName: string;
  configValue: string;
}
export enum EstSearchBy {
  AccountName = 1,
  AccountNumber,
  Reference,
  Status,
}

export enum EstAccountStatus {
  Permanent = 'P',
  Temporary ='T',
  Inactive ='I',
  Hold = 'H',
  Expired = 'E',
  Frozen = 'F',
  AccessToFacilities = 'N',
  Suspended = 'S',
}