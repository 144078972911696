import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from "lodash";
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { RetailPopupComponent } from '../retail-popup/retail-popup.component';
import { RetailLocalization } from '../common/localization/retail-localization';
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { BaseResponse, RetailItemBarCode, RetailItem, InventoryOutletToDisplay, RetailItemDetail, UserSessionConfiguration } from '../retail.modals';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { BreakPointAccess } from '../shared/service/breakpoint.service';
import { DialogOverviewExampleDialog } from '../shared/dialog-popup/dialogPopup-componenet';
import * as GlobalConst from '../shared/globalsContant';
import { BarcodePrintService } from '../../retail/retail-print/barcode-print.service';
import { BarcodePrintData } from '../../retail/retail.modals';
import { AlertAction, AlertType } from '../shared/shared.modal';
import { RetailChildSetupBusiness } from './retail-child-setup.business';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { ImportRetailitemsColumn } from './retail-child-setup.model';
import { RetailFunctionalityBusiness } from '../shared/business/retail-functionality.business';
import { takeUntil } from 'rxjs/operators';
import { Vendor } from '../retail-vendor-setup/retail-vendor-setup.modals';
import { VendorInfo } from '../retail-setup/inventory/add-vendors-popup/add-vendors-popup.model';
import { Host } from '../shared/globalsContant';
// import { promise } from 'selenium-webdriver';
import { RetailSetupTableComponent } from './retail-setup-table/retail-setup-table.component';


@Component({
  selector: 'app-retail-child-setup',
  templateUrl: './retail-child-setup.component.html',
  styleUrls: ['./retail-child-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailChildSetupComponent implements OnInit, OnDestroy {
  @Input() options;
  @Input() doneEvtEmitter;
  @Output() toggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('retailSetupTableComponent') retailSetupTableComponent: RetailSetupTableComponent;

  tableoptions: any;
  tabledata: any[];
  lastSearchTxt: any = '';
  lastSelectedCategory = 0;
  lastSelectedSubCategory1 = 0;
  lastSelectedSubCategory2 = 0;
  lastSelectedSubCategory3 = 0;
  lastSelectedSubCategory4 = 0;
  lastSelectedSubCategory5 = 0;
  selectedCategory = 0;
  selectedSubCategory1 = 0;
  selectedSubCategory2 = 0;
  selectedSubCategory3 = 0;
  selectedSubCategory4 = 0;
  selectedSubCategory5 = 0;
  EditedtableOptions: any[];
  outlets: InventoryOutletToDisplay[];
  categories: Array<any> = [];
  selectedCategoryArray: Array<any> = [];
  isEditMode: boolean;
  selectedSubCategory1Array: Array<any> = [];
  doneAdvSearch: boolean;
  selectedSubCategory2Array: Array<any> = [];
  selectedSubCategory3Array: Array<any> = [];
  selectedSubCategory4Array: Array<any> = [];
  selectedSubCategory5Array: Array<any> = [];
  subCategoryOne: Array<any> = [];
  subCategoryTwo: Array<any> = [];
  subCategoryThree: Array<any> = [];
  subCategoryFour: Array<any> = [];
  subCategoryFive: Array<any> = [];
  categoriesClone: Array<any> = [];
  subCategoryOneClone: Array<any> = [];
  searchControl = new UntypedFormControl();
  subCategoryTwoClone: Array<any> = [];
  subCategoryThreeClone: Array<any> = [];
  subCategoryFourClone: Array<any> = [];
  subCategoryFiveClone: Array<any> = [];
  ChildSubCategories: Array<any> = [];
  searchTxt = '';
  filteredData: any = [];
  filterValue: any = [];
  filteredDataByOutlet: any[];
  captions: any;
  outletId: number;
  outletName: string;
  shopItems: any[];
  IsViewOnly: boolean;
  disableSyncUpBtn: boolean;
  subscription: Subscription;
  enableAdvSearch: boolean;
  setheight = true;
  selectedTabledata: any = [];
  enabletransferButton = false;
  fromOutlet: any;
  isSyncItem = true;
  caption: any = {};
  vendorId: number;
  slideValue = true;
  retailTableCloneValue: any = [];
  categoryLinkingObjNamelist: string[];
  categoryLinkingObjArray: any;
  ORGcategoryLinkingObjArray: any[];
  isInActive = false;
  selectedOutlet: any = [];
  retailSetupFormGrp: UntypedFormGroup;
  labelPosition: number;
  downloadSampleURLPath: string;
  printingBarcodeLabels = false;
  printItems: BarcodePrintData[] = [];
  userSessionConfigKey = 'userSessionConfigInfo';
  headerData: any[];
  bodyData: any;

  pageStart = 0;
  pageLength = 20;
  sortBy = 0;
  isSortByAscending = true;
  isLoading = false;

  sortables = ['itemNumber', 'itemName', 'salesPrice', 'costPrice', 'categoryName', 'quantityOnHand', 'itemPar'];
  notifier: Subject<void> = new Subject<void>();
  isScrollPositionReset = false;
  isAllItemsSelected = false;
  functionalities: { [key: string]: boolean } = {};
  defaultOutlet: number;
  filterData: any = [];
  searchoptions: any[] = [];
  filteredOptions: Promise<any[]>;
  selectedVendorIds: number[] = [];
  importing: boolean = false;
  retailTableTemp: any;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  onEditDisableFunction: boolean = true;
  floatLabel: string;
  floatLabelNever: string;
  isEatecAsMaster: boolean = false;


  constructor(private dialog: MatDialog
    , private retailService: RetailSetupService
    , public localization: RetailLocalization
    , private http: HttpServiceCall
    , private func: RetailFunctionalityBusiness
    , private utils: RetailUtilities
    , private breakPoint: BreakPointAccess
    , private cdRef: ChangeDetectorRef
    , private _retailservice: RetailSetupService
    , public PropertyInfo: RetailPropertyInformation
    , public barcodePrintService: BarcodePrintService
    , private _retailChildSetupBusiness: RetailChildSetupBusiness
  ) {
    this.isSyncItem = PropertyInfo.UseRetailInterface;
    this.defaultOutlet = PropertyInfo.GetDefaultOutlet();
    this.caption = this.localization.captions;
    this.retailSetupFormGrp = new UntypedFormGroup({
      shownIactiveRecords: new UntypedFormControl(false)
    });
    this.captions = this.localization.captions.retailsetup;
    this.downloadSampleURLPath = 'assets/RetailItemSample.csv';
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
    this.isEatecAsMaster = this.PropertyInfo.IsEatecAsMaster;
  }

  async getAllTaxConfig() {
    await this._retailservice.getAllTaxConfigurations();
  }

  async getRetailFunctionality() {
    this.functionalities = await this.func.getRetailFunctionality();
    if (!this.functionalities?.ShowRetailSetupSelectOutletField && this.defaultOutlet === 0) {
      this.defaultOutlet = await this.func.getDefaultOutlet();
      if (this.defaultOutlet > 0) {
        this.PropertyInfo.SetDefaultOutlet(this.defaultOutlet);
      }
    }
  }

  async getAllCategory() {
    await this._retailservice.GetAllCategory();
  }

  async getAllCategoryDetails() {
    await this._retailservice.GetAllCategoryDetails();
  }

  ngOnInit() {
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(bp => bp.breakPointNumber === GlobalConst.RetailBreakPoint.ItemSetup).view;
    this.tableoptions = [{
      TableHdrData: this.GetTableHeader(),
      TablebodyData: [],
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext: this.captions.ItemDescriptionCategory,
      EnableActions: true,
      SelectRows: true,
      SelectedSettingId: GlobalConst.GridType.retail,
      Searchable: false,
      EditMoreOption: true,
      Sortable: 'itemNumber',
      TableId: GlobalConst.GridType.retail,
      disableDelete: true,
      showToggle: false,
      IsViewOnly: this.IsViewOnly,
      isInitial: true,
      bufferCount: 10 // scroll buffer count, end scroll event fired when the buffer count reached
    }];
    this.getRetailFunctionality();
    this.GetOutletsByPropertyId();
    this.GetUnitOfMeasure();
    this.getAllTaxConfig();
    this.getAllCategory();
    this.getAllCategoryDetails();
    this.GetOutlets();

    const categoryLinkingObj = [{
      category: [
        { id: 1, name: 'Gift Card Sold', isSelected: false, childSubCategories: [{ id: 1, 'name': 'Bag Tags' }, { id: 2, 'name': 'Bag Tags' }] },
        { id: 2, name: 'Spa Body treatemet', isSelected: false, childSubCategories: [{ id: 2, 'name': 'Bag Tags' }, { id: 3, 'name': 'Bag Tags' }] },
        { id: 3, name: 'Spa day packages', isSelected: false, childSubCategories: [{ id: 4, 'name': 'Bag Tags' }, { id: 5, 'name': 'Bag Tags' }] },
        { id: 4, name: 'golf Foot Wear', isSelected: false, childSubCategories: [] },
        { id: 5, name: 'Goals', isSelected: true, childSubCategories: [] },
      ],
      subcategoryone: [
        { id: 1, 'name': 'Bag Tags', isSelected: false, childSubCategories: [{ id: 1, 'name': 'Bag Tags' }, { id: 2, 'name': 'Bag Tags' }] },
        { id: 2, 'name': 'Booms', isSelected: false, childSubCategories: [{ id: 1, 'name': 'Bag Tags' }, { id: 5, 'name': 'Bag Tags' }] },
        { id: 3, 'name': 'Divot Tools', isSelected: false, childSubCategories: [{ id: 2, 'name': 'Bag Tags' }, { id: 5, 'name': 'Bag Tags' }] },
        { id: 4, 'name': 'Drivers', isSelected: false },
        { id: 5, 'name': 'Goals', isSelected: false },
      ],
      subcategorytwo: [
        { id: 1, 'name': 'twoBag Tags2', isSelected: false, childSubCategories: [{ id: 1, 'name': 'threeDivot Tools3' }, { id: 3, 'name': 'threeDrivers3' }] },
        { id: 2, 'name': 'twoBooms2', isSelected: false },
        { id: 3, 'name': 'twoDivot Tools2', isSelected: false },
        { id: 4, 'name': 'twoDrivers2', isSelected: false },
        { id: 5, 'name': 'twoGoals2', isSelected: false },
      ],
      subcategorythree: [
        { id: 1, 'name': 'threeBag Tags33', isSelected: false, childSubCategories: [{ id: 1, 'name': 'Bag Tags' }, { id: 5, 'name': 'Bag Tags' }] },
        { id: 2, 'name': 'threeBooms', isSelected: false },
        { id: 3, 'name': 'threeDivot Tools3', isSelected: false },
        { id: 4, 'name': 'threeDrivers3', isSelected: false },
        { id: 5, 'name': 'threeGoals', isSelected: false, childSubCategories: [{ id: 3, 'name': 'Bag Tags' }] },
      ],
      subcategoryFour: [
        { id: 1, 'name': 'FourBag Tags33', isSelected: false, childSubCategories: [{ id: 1, 'name': 'Bag Tags' }] },
        { id: 2, 'name': 'FourBooms', isSelected: false },
        { id: 3, 'name': 'FourDivot Tools3', isSelected: false },
        { id: 4, 'name': 'FourDrivers3', isSelected: false },
        { id: 5, 'name': 'FourGoals', isSelected: false },
      ],
      subcategoryFive: [
        { id: 1, 'name': 'FiveBag Tags33', isSelected: false },
        { id: 2, 'name': 'FiveBooms', isSelected: false },
        { id: 3, 'name': 'FiveDivot Tools3', isSelected: false },
        { id: 4, 'name': 'FiveDrivers3', isSelected: false },
        { id: 5, 'name': 'FiveGoals', isSelected: false },
      ]
    }];

    this.formLinkArray(categoryLinkingObj);
    this.getSystemInfo();
    this.vendorInfo();
    this.searchControl.setValue('');
    this.searchControl.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(value => {
      this.filteredOptions = this._filter(value);
    });
    this.retailService.tabLoaderEnable.next(false);
    ///////ss
  }
  async vendorInfo() {
    var data = await this.SearchVendors();
    this.searchoptions = this.mapToGridUI(data);
  }
  async _filter(value: string) {
    this.filterData = [];
    value = value && typeof value == 'string' ? value.toString() : "";
    value = value.trim();
    const filterValue = value.toLowerCase();
    return this.searchoptions && this.searchoptions.length > 0 ? this.searchoptions.filter(option => ((option['vendorName'].toLowerCase().indexOf(filterValue) > -1)
      || (option['vendorCode'].toLowerCase().indexOf(filterValue) === 0))) : [];
  }

  public mapToGridUI(vendorAPI: Vendor[]) {
    if (vendorAPI && vendorAPI.length > 0) {
      const vendorDataUI: VendorInfo[] = vendorAPI.map(c => {
        return {
          id: 0,
          vendorId: c.id,
          retailItemId: 0,
          listOrder: c.listOrder,
          vendorCode: c.code,
          vendorName: c.name,
          supplierItemNumber: '',
          qoh: 0,
          leadDays: 0,
          minimumOrder: 0,
          unitQty: 0,
        }
      });
      return vendorDataUI.sort(x => x.listOrder);
    }
    else {
      return [];
    }
  }
  async SearchVendors() {
    try {
      const vendors = await this.InvokeHttpCalls<Vendor[]>(Host.retailManagement, 'GetVendorInfo', HttpMethod.Put, undefined);
      this.retailService.vendors = vendors;
      return vendors;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  private async InvokeHttpCalls<T>(host: Host, callDesc: string, callType: HttpMethod, body?: Vendor, uRIParams?: any): Promise<T> {
    try {
      let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
        callDesc: callDesc,
        host: host,
        method: callType,
        body: body,
        uriParams: uRIParams
      });
      return response.result;
    }
    catch (ex) {
      return null;
    }
  }
  downloadSampleCSV() {
    const anchorElement = document.createElement('a');
    document.body.appendChild(anchorElement);
    anchorElement.href = this.downloadSampleURLPath;
    anchorElement.download = this.captions.downloadSampleCSVFileName + '.csv';
    anchorElement.click();
    document.body.removeChild(anchorElement);
  }

  async importItemStatus($event: Event) {
    if (this.IsViewOnly) {
      return;
    }
    var data = await this._retailChildSetupBusiness.ImportStatus();
    if (data.length > 0) {
      this.onImportFail(data, this.captions.ImportRetailStatus);
    }
    else {
      this.utils.showAlert(this.captions.ImportRetailSuccess, AlertType.Success);
    }

  }
  async importItems($event: Event, fileInput: any) {
    if (this.IsViewOnly) {
      return;
    }

    const fileInp = event;
    this.utils.showAlert(this.captions.ImportAlertMessage + '- "' + (<HTMLInputElement>event.srcElement).files[0].name + '"', AlertType.Info, GlobalConst.ButtonType.YesNo, async (res) => {
      if (res === AlertAction.NO) {
        fileInput.value = '';
        return false;
      } else {
        this.importing = true;
        var result = true;
        result = await this._retailChildSetupBusiness.ValidateImport();
        if (!result) {
          this.utils.showAlert(this.captions.PreviousImportMessage, AlertType.Info, GlobalConst.ButtonType.YesNo, async (data) => {
            if (data === AlertAction.NO) {
              fileInput.value = '';
              this.importing = false;
              return false;
            }
            else {
              await this._retailChildSetupBusiness.readRetailitemsCSV(fileInp, true)
                .then((res2) => {
                  if (res2) {
                    this.utils.showAlert(this.captions.ImportRetailInitiate, AlertType.Success);
                  }
                  else {
                    this.utils.showAlert(this.captions.ImportRetailFailed, AlertType.Error);
                  }
                }).
                catch(x => {
                  if (x && (x.status !== 0 && x.status !== 502 && x.status !== 504)) {
                    this._retailChildSetupBusiness.handleError(x.error, this.openFailedDataPopup.bind(this));
                  }
                })
                .finally(() => {
                  fileInput.value = '';
                  this.importing = false;
                  this.utils.ToggleLoaderWithMessage(false);
                });
            }
          });
        }
        if (result) {
          await this._retailChildSetupBusiness.readRetailitemsCSV(fileInp, result)
            .then((res2) => {
              this.utils.showAlert(this.captions.ImportRetailInitiate, AlertType.Success);
            }).
            catch(x => {
              if (x && (x.status !== 0 && x.status !== 502 && x.status !== 504)) {
                this._retailChildSetupBusiness.handleError(x.error, this.openFailedDataPopup.bind(this));
              }
            })
            .finally(() => {
              fileInput.value = '';
              this.importing = false;
            });
        }
      }
    });
  }

  onAfterImport(res2: any) {
    if (res2 && res2.length > 0) {
      const itemsImported = res2.filter(x => x.id > 0);
      if (itemsImported && itemsImported.length > 0) {
        if (itemsImported.length === res2.length) {
          this.utils.showAlert(this.captions.ImportRetailSuccess, AlertType.Success);
          this.GetShopItems();
        } else {
          this.onImportFail(res2, `${itemsImported.length} out of ${res2.length} ${this.captions.ImportRetailPartialSuccess}`);
        }
      } else {
        this.onImportFail(res2, this.captions.ImportRetailFailed);
      }
    } else {
      this.onImportFail(res2, this.captions.ImportRetailFailed);
    }
  }
  onImportFail(res: any, message: string) {

    this.bodyData = res.filter(x => x.id <= 0 || x.id === undefined);
    this.bodyData.forEach((y, index) => {
      if (y) {
        y.id = index + 1;
      }
    });
    this.headerData = this._retailChildSetupBusiness.buildheader(this.bodyData);

    this.utils.showAlert(message, AlertType.Info, GlobalConst.ButtonType.viewFailedCancel, this.openFailedDataPopup.bind(this));
  }

  openFailedDataPopup(event) {
    let statusCount = {
      NotProcessed: this._retailChildSetupBusiness.NotProcessedCount,
      Processed: this._retailChildSetupBusiness.ProcessedCount,
      Failed: this._retailChildSetupBusiness.FailedCount
    }
    if (event === 'YES') {
      this.cdRef.detach();
      this.utils.showUploadAlert(this.captions.ImportItemStatus, '', AlertType.Error, this.importFailedRetailItems.bind(this), [this.headerData, this.bodyData, statusCount]);
    } else {
      this.GetShopItems();
    }
  }

  importFailedRetailItems(event) {
    this._retailChildSetupBusiness.NotProcessedCount = 0;
    this._retailChildSetupBusiness.ProcessedCount = 0;
    this._retailChildSetupBusiness.FailedCount = 0;
    if (event) {
      event.forEach(y => {
        if (y && y[ImportRetailitemsColumn.ErrorMessage]) {
          delete y[ImportRetailitemsColumn.ErrorMessage];
        }
        if (y && y.id) {
          delete y.id;
        }
      });
      this._retailChildSetupBusiness.BuildRetailItems(event, true)
        .then((res2) => {
          if (res2) {
            this.utils.showAlert(this.captions.ImportRetailInitiate, AlertType.Success);
          }
          else {
            this.utils.showAlert(this.captions.ImportRetailFailed, AlertType.Error);
          }
        }).
        catch(x => {
          this._retailChildSetupBusiness.handleError(x.error, this.openFailedDataPopup.bind(this));
        });
      console.log('popup', event);
    } else {
      this.GetShopItems();
    }
    this.cdRef.reattach();
  }

  getSystemInfo() {
    this.retailService.InvokeServiceCall('GetStandAloneProducts', GlobalConst.Host.authentication, HttpMethod.Get);
  }

  formLinkArray(CLO) {
    this.categoryLinkingObjNamelist = [];
    this.ORGcategoryLinkingObjArray = [];
    this.categoryLinkingObjArray = [];
    Object.keys(CLO[0]).forEach((prop, index) => {
      this.categoryLinkingObjNamelist.push(prop);
      const newobj = {
        'catName': prop,
        'OrgcatArr': CLO[0][prop],
        'catArr': CLO[0][prop],
        'selectedCat': '',
        'disabledFlag': true
      };
      this.ORGcategoryLinkingObjArray.push(newobj);
    });
    this.categoryLinkingObjArray = _.cloneDeep(this.ORGcategoryLinkingObjArray);
  }

  selectCatgory(e, selObj) {
    this.categoryLinkingObjArray = this.resetcatarr();
    this.filtercategory(selObj);
  }

  filtercategory(selObj) {
    const that = this;
    let validatefollowing = false;
    let clearSelectedCat = false;
    that.categoryLinkingObjArray.forEach((CLOA, index) => {
      let subcategory = [];
      if (that.categoryLinkingObjArray[0].selectedCat) {
        validatefollowing = true;
      }

      if (validatefollowing) {
        CLOA.disabledFlag = false;
      }

      if (clearSelectedCat) {
        CLOA.selectedCat = '';
      }

      if (CLOA.selectedCat) {
        const der_childSubCategories = CLOA.catArr.find(CA => {
          return CLOA.selectedCat.id === CA.id;
        });

        if (der_childSubCategories) {
          subcategory = der_childSubCategories.childSubCategories;
        }
      } else {
        let TempCategoryList = [];
        CLOA.catArr.forEach(CA => {
          if (CA.childSubCategories) {
            TempCategoryList = TempCategoryList.concat(CA.childSubCategories);
          }
        });

        TempCategoryList = _.uniqBy(TempCategoryList, 'id');
        subcategory = TempCategoryList;
      }

      if (subcategory && subcategory.length !== 0) {
        const newDD = that.categoryLinkingObjArray[index + 1].catArr.filter(x => {
          return _.find(subcategory, ['id', x.id]);
        });

        that.categoryLinkingObjArray[index + 1].catArr = newDD;
      } else {
        if (that.categoryLinkingObjArray[index + 1]) {
          that.categoryLinkingObjArray[index + 1].catArr = [];
        }
      }

      if (selObj.catName === CLOA.catName) {
        clearSelectedCat = true;
      }
    });
  }

  resetcatarr() {
    this.categoryLinkingObjArray.forEach((x, j) => {
      this.categoryLinkingObjArray[j].catArr = x.OrgcatArr;
    });
    return this.categoryLinkingObjArray;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.$destroyed) {
      this.$destroyed.next(true);
      this.$destroyed.complete();
    }
  }


  Done(e) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'UpdateRetailItemStatus',
      method: HttpMethod.Put,
      uriParams: {
        id: e.id, status: !e.isInActive
      },
      showError: true,
      extraParams: []
    });
  }

  Cancel(e) {
    this.GetShopItems();
  }

  GetTableHeader(): any {
    const currencySymbol = this.localization.currencySymbol;
    let  header =  [{ 'title': this.captions.Item, 'jsonkey': 'itemNumber', 'alignType': 'right', 'sortcolumn': 'itemNumberColumn', 'sortcolumndatatype': 'number', 'searchable': true, 'sortable': true },
    { 'title': this.captions.Description, 'jsonkey': 'itemName', 'searchable': true, 'sortable': true },
    { 'title': this.captions.Category, 'jsonkey': 'categoryName', 'searchable': true, 'sortable': false },
    { 'title': this.captions.SalesPrice + ' (' + currencySymbol + ')', 'jsonkey': 'salesPrice', 'alignType': 'right', 'sortcolumndatatype': 'number', 'datatype': 'money', 'searchable': false, 'sortable': true },
    { 'title': this.captions.QtyOnHand, 'jsonkey': 'quantityOnHand', 'searchable': false, 'sortable': true, 'alignType': 'right' },
    { 'title': this.captions.ItemPar, 'jsonkey': 'itemPar', 'searchable': false, 'sortable': true, 'alignType': 'right' },
    { 'title': this.captions.Inactive, 'jsonkey': 'isInActive', 'type': 'toggle', 'displayType': 'icon', 'sortable': false, 'searchable': false },
    { 'title': this.captions.Cost + ' (' + currencySymbol + ')', 'jsonkey': 'costPrice', 'alignType': 'right', 'sortcolumndatatype': 'number', 'datatype': 'money', 'searchable': false, 'sortable': true },
    { 'title': this.captions.weightedAverageCost + ' (' + currencySymbol + ')', 'jsonkey': 'weightedAverageCost', 'alignType': 'right', 'sortcolumndatatype': 'number', 'datatype': 'money', 'searchable': false, 'sortable': true },
    { 'title': this.captions.printQty, 'jsonkey': 'printqty', 'searchable': false }];
    if(this.PropertyInfo.IsEatecEnabled) {
      header =header.filter(x => x.jsonkey != 'quantityOnHand');
      header =header.filter(x => x.jsonkey != 'costPrice');
    }
    if(!this.PropertyInfo.IsEatecEnabled) {
      header =header.filter(x => x.jsonkey != 'weightedAverageCost');
    }
    return header;
  }

  BindTableData(data) {

    this.tableoptions = [{
      TableHdrData: this.GetTableHeader(),
      TablebodyData: data,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext: this.captions.ItemDescriptionCategory,
      EnableActions: true,
      SelectRows: true,
      SelectedSettingId: GlobalConst.GridType.retail,
      Searchable: false,
      EditMoreOption: true,
      Sortable: 'itemNumber',
      TableId: GlobalConst.GridType.retail,
      disableDelete: true,
      showToggle: false,
      IsViewOnly: this.IsViewOnly,
      bufferCount: 10 // scroll buffer count, end scroll event fired when the buffer count reached
    }];
    this.tabledata = this.retailService.retailTable;
    this.EditedtableOptions = _.cloneDeep(this.tableoptions);
    this.filteredData = this.tabledata;
    this.filteredDataByOutlet = this.tabledata;
    this.enabletransferButton = false;
  }

  SearchByOutlet(e) {
    this.fromOutlet = e.value;
    this.selectedOutlet.id = e.value;
    this.selectedTabledata = [];
    this.enabletransferButton = false;
    this.outletId = e.value;
    this.GetShopItems();
  }

  async EditRecords(data) {
    this.isEditMode = false;
    if (data[2].toLowerCase() === 'print') {
      if (data[3] && data[3].length == 0) {
        this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.captions.retailsetup.PleaseSelectanItem, GlobalConst.ButtonType.OkCancel);
        if(this.retailSetupTableComponent){
          this.retailSetupTableComponent.changeEditMode(false);
        }
        return;
      }
      const error = data[3].filter(ele => ele.printqty === 0 || ele.printqty === '');
      if (error.length > 0) {
        this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.captions.retailsetup.PleaseEnteraPrintQty, GlobalConst.ButtonType.OkCancel);
        if(this.retailSetupTableComponent){
          this.retailSetupTableComponent.changeEditMode(false);
        }
        return;
      }
      this.printingBarcodeLabels = false;
      this.labelPosition = 0;
      await this.addPrintItems(data[3]);
      if (this.printingBarcodeLabels) {
        this.getBarcodePrintStartPosition('BC', 'BARCODES');
      } else {
        this.printLabels();
      }
    } else if (data[2].toLowerCase() === 'edit') {
      this.GetItemToEdit(data[0].id);
    }
  }

  async addPrintItems(PrintItems: any) {
    this.printItems.length = 0;

    const retailItems = PrintItems.map(x => x.id);
    const result = await this.retailService.cancellableObservalble(
      this.notifier,
      'GetRetailItemDetailedInfoList',
      GlobalConst.Host.retailManagement,
      HttpMethod.Put,
      retailItems,
      ''
    ).toPromise();

    PrintItems.forEach(item => {
      this.printItems.push(this.addPrintItem(item, result.result));
    });

  }

  addPrintItem(item: any, items: any) {
    const retailItem: RetailItem = items.find(d => d.id === item.id);
    const retailItemDetail: RetailItemDetail = retailItem.retailItemDetail;
    const retailItemBarcode: RetailItemBarCode = retailItem.retailItemBarCode.find(r => r.isPrimary);
    const LabelData = {} as BarcodePrintData;
    LabelData.itemNumber = Number(item.itemNumber);
    LabelData.itemDescription = item.itemName;
    LabelData.itemPrice = retailItem.retailItemDetail.salesPrice;
    LabelData.outletName = this.outletName;
    LabelData.barcode = retailItemBarcode ? retailItemBarcode.barCode : '';
    LabelData.isHangingTicket = retailItemDetail.isHangingTicket;
    LabelData.isSmallSticker = retailItemDetail.isSmallTicket;
    LabelData.printQuantity = Number(item.printqty);

    // Prompt only once for barcode label start position
    if (!LabelData.isHangingTicket && !LabelData.isSmallSticker) {
      this.printingBarcodeLabels = true;
    }

    return LabelData;
  }

  getBarcodePrintStartPosition(type: string, header: string) {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      height: 'auto',
      width: '40%',
      data: { headername: header, closebool: true, Type: type, buttonType: GlobalConst.ButtonType.Ok },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    this.subscription = dialogRef.afterClosed().subscribe(res => {
      if (res.res === 'ok' && res.labelPosition) {
        this.labelPosition = Number(res.labelPosition);
        if (this.labelPosition < 1) {
          this.labelPosition = 1;
        } else if (this.labelPosition > 30) {
          this.labelPosition = 30;
        }
        this.printLabels();
      }
      else {
        if(this.retailSetupTableComponent){
          this.retailSetupTableComponent.changeEditMode(false);
        }
      }
    });
    return dialogRef;
  }

  printLabels() {
    let smallStickersPrinter = '';
    let hangingTicketsPrinter = '';
    if (sessionStorage.getItem(this.userSessionConfigKey) != null) {

      smallStickersPrinter = this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'SmallStickersPrinter');
      hangingTicketsPrinter = this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'HangingTicketsPrinter');
      if (this._retailservice.ProductId == GlobalConst.Product.RETAIL) {
        let userSessionConfig: UserSessionConfiguration = JSON.parse(sessionStorage.getItem(this.userSessionConfigKey));
        smallStickersPrinter = userSessionConfig.smallStickersPrinter;
        hangingTicketsPrinter = userSessionConfig.hangingTicketsPrinter;
      }
    }

    this.barcodePrintService.printBarcodeLabels(this.printItems, this.labelPosition, smallStickersPrinter, hangingTicketsPrinter)
      .then((msg => console.log(msg)));
    this.BindTableData(this.retailService.retailTable);
  }

  transferInventory() {
    if (this.breakPoint.CheckForAccess([GlobalConst.InventoryBreakPoint.ItemTransfer])) {
      this.cdRef.detach();
      this.selectedTabledata.forEach(element => {
        element['transferedQty'] = 0;
        element['checkedFlag'] = true;
      });

      const dataset = {
        entireTabledata: this.retailService.retailTable,
        selectedRow: this.selectedTabledata,
        fromOutlet: this.fromOutlet
      };

      const transferInventorydialogRef = this.dialog.open(RetailPopupComponent, {
        width: '90%',
        height: '80%',
        maxHeight: '1000px',
        maxWidth: '1200px',
        disableClose: true,
        data: { headername: this.captions.addTransferInventory, closebool: true, templatename: 'TI', datarecord: dataset, popupConfig: { operation: '' } },
        hasBackdrop: true,
        panelClass: 'small-popup'
      });

      transferInventorydialogRef.afterClosed().subscribe(result => {
        this.isAllItemsSelected = false;
        this.selectedTabledata = [];
        this.enabletransferButton = false;
        this.cdRef.reattach();
        this.GetOutletsByPropertyId();
      });
    }
  }

  createRecords() {
    this.cdRef.detach();
    const createdialogRef = this.dialog.open(RetailPopupComponent, {
      width: '90%',
      height: '80%',
      maxHeight: '1000px',
      maxWidth: '1200px',
      disableClose: true,
      data: { headername: this.captions.CreateRetailItem, closebool: true, templatename: 'RS', datarecord: '', popupConfig: { operation: 'create' } },
      hasBackdrop: true,
      panelClass: 'small-popup'
    });
    createdialogRef.afterClosed().subscribe(result => {
      this.cdRef.reattach();
      this.cdRef.detectChanges();
      this.isEditMode = false;
      this.GetOutletsByPropertyId();
    });
  }

  clearSearch() {
    this.searchTxt = '';
    this.GetShopItems();
  }
  clearSearchText(e) {
    this.searchControl.setValue('');
    this.vendorId = 0;
    this.GetShopItems();
    this.filteredOptions = new Promise((resolve) => { resolve([]); });
  }
  async SearchByDetails() {
    await this.GetShopItems();
  }

  async GetShopItems() {
    if (this.outletId) {
      this.isScrollPositionReset = true;
      this.pageStart = 0;
      this.retailService.retailTable = [];
      this.cancelOngoingCall();
      await this.loadRetailItems();
    }
  }

  cancelOngoingCall() {
    this.notifier.next();
    this.notifier.complete();
  }

  async loadRetailItems() {
    try {
      this.isLoading = true;
      this.notifier = new Subject<void>();

      const response = await this.retailService.cancellableObservalble(
        this.notifier,
        'GetItemByPagination',
        GlobalConst.Host.retailManagement,
        HttpMethod.Get,
        undefined,
        {
          outletId: this.outletId,
          includeInactive: this.isInActive,
          pageStart: this.pageStart,
          pageLength: this.pageLength,
          sortBy: this.sortBy,
          isSortByAscending: this.isSortByAscending,
          searchText: this.searchTxt,
          category: this.selectedCategory,
          subcategory1: this.selectedSubCategory1,
          subcategory2: this.selectedSubCategory2,
          subcategory3: this.selectedSubCategory3,
          subcategory4: this.selectedSubCategory4,
          subcategory5: this.selectedSubCategory5,
          vendorId: this.vendorId
        })?.toPromise();

      if (response) {
        this.retailService.retailTable.push(...response.result.map(x => {
          return {
            id: x.id,
            itemNumberColumn: parseInt(x.itemNumber, 10),
            itemNumber: x.itemNumber,
            itemName: x.itemDescription,
            salesPrice: x.salesPrice,
            isInActive: !x.isActive,
            categoryName: x.categoryName,
            outlet: x.outletItem,
            categoryId: x.category,
            subCategory1: x.subCategory1,
            subCategory2: x.subCategory2,
            subCategory3: x.subCategory3,
            subCategory4: x.subCategory4,
            subCategory5: x.subCategory5,
            quantityOnHand: x.quantityOnHand ? x.quantityOnHand : 0,
            itemPar: x.itemPar ? x.itemPar : 0,
            costPrice: x.costPrice,
            subCategory1Name: x.subCategory1Name,
            subCategory2Name: x.subCategory2Name,
            subCategory3Name: x.subCategory3Name,
            subCategory4Name: x.subCategory4Name,
            subCategory5Name: x.subCategory5Name,
            unitCost: x.unitCost,
            useInventory: x.useInventory,
            checked: this.isAllItemsSelected,
            weightedAverageCost: x.weightedAverageCost,
            isExternal: x.isExternal,
            sourceSystem: x.sourceSystem
          };
        }));
        this.retailTableTemp = this.retailService.retailTable;
        this.BindTableData(this.retailService.retailTable);
        this.pageStart += this.pageLength;
      }

      this.isLoading = false;
    } catch (ex) {
      console.error(ex);
    } finally {
      this.isLoading = false;
    }

  }

  GetAllLinking() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'GetCategoryAndSubCategoryLink',
      method: HttpMethod.Get,
      showError: true,
      uriParams: { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
      extraParams: []
    });
  }

  OnCategoryChange($event: any) {
    // clear category data before binding
    this.selectedCategory = 0;
    this.selectedSubCategory1 = 0;
    this.selectedSubCategory2 = 0;
    this.selectedSubCategory3 = 0;
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    this.selectedCategory = $event.value;
    this.selectedCategoryArray = this.categories.filter(a => a.id === this.selectedCategory);
    this.subCategoryOne = this.subCategoryOneClone.filter(a => {
      return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id === a.id);
    });
  }
  async onOptionChange(e) {
    this.vendorId = e?.option?.value?.vendorId;
    this.searchControl.setValue(e?.option?.value?.vendorCode + ' - ' + e?.option?.value?.vendorName);
  }
  OnSubCategory1Change($event: any) {
    // clear category data before binding
    this.selectedSubCategory1 = 0;
    this.selectedSubCategory2 = 0;
    this.selectedSubCategory3 = 0;
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    this.selectedSubCategory1 = $event.value;
    this.selectedCategoryArray = this.subCategoryOneClone.filter(a => a.id === this.selectedSubCategory1);
    this.subCategoryTwo = this.subCategoryTwoClone.filter(a => {
      return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id === a.id);
    });
  }

  OnSubCategory2Change($event: any) {
    // clear category data before binding
    this.selectedSubCategory2 = 0;
    this.selectedSubCategory3 = 0;
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    this.selectedSubCategory2 = $event.value;
    this.selectedCategoryArray = this.subCategoryTwoClone.filter(a => a.id === this.selectedSubCategory2);
    this.subCategoryThree = this.subCategoryThreeClone.filter(a => {
      return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id === a.id);
    });
  }

  OnSubCategory3Change($event: any) {
    // clear category data before binding
    this.selectedSubCategory3 = 0;
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    this.selectedSubCategory3 = $event.value;
    this.selectedCategoryArray = this.subCategoryThreeClone.filter(a => a.id === this.selectedSubCategory3);
    this.subCategoryFour = this.subCategoryFourClone.filter(a => {
      return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id === a.id);
    });
  }

  OnSubCategory4Change($event: any) {
    // clear category data before binding
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    this.selectedSubCategory4 = $event.value;
    this.selectedCategoryArray = this.subCategoryFourClone.filter(a => a.id === this.selectedSubCategory4);
    this.subCategoryFive = this.subCategoryFiveClone.filter(a => {
      return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id === a.id);
    });
  }

  async virtualScrollHandler(arg: { viewPortItems: string | any[]; }) {
    if (arg.viewPortItems && arg.viewPortItems.length && !this.isLoading) {
      this.isScrollPositionReset = false;
      await this.loadRetailItems();
    }
  }

  sortHandler(arg) {
    this.sortBy = this.sortables.indexOf(arg.header);
    this.isSortByAscending = arg.direction === 'asc';
    this.GetShopItems();
  }

  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]) {
    switch (callDesc) {
      case 'PosFullSync':

        if (result && result.result) {
          this.utils.showAlert(this.localization.captions.retailsetup.PosSyncSuccess, AlertType.Success, GlobalConst.ButtonType.Ok);
        }
        else {
          this.utils.showAlert(this.localization.captions.retailsetup.PosSyncFailed, AlertType.Error, GlobalConst.ButtonType.Ok);
        }
        break;

      case 'SyncRetailItems':
        this.disableSyncUpBtn = false;
        if (result.result) {
          this.GetShopItems();
        }
        break;

      case 'GetSubPropertyAccessByUser':
        let outlets: any = result.result ? result.result : [];
        outlets = outlets.filter(x => x.isActive);
        outlets = this.utils.sortObj(outlets, 'subPropertyID');
        this.retailService.outlets = outlets.map(x => {
          return { id: x.subPropertyID, name: x.subPropertyName };
        });
        this.outlets = _.cloneDeep(this.retailService.outlets);
        const defaultOutletId = this.outlets.length > 0 ? this.outlets[0].id : 0;
        this.outletId = this.outletId && this.outletId > 0 ? this.outletId : defaultOutletId;
        const defaultOutlet = this.defaultOutlet > 0 ? this.defaultOutlet : this.outletId;
        this.outletId = this.functionalities?.ShowRetailSetupSelectOutletField ? this.outletId : defaultOutlet;
        const defaultOutletName = this.outlets.length > 0 ? this.outlets[0].name : '';
        this.outletName = this.outletName && this.outletName > '' ? this.outletName : defaultOutletName;
        this.fromOutlet = this.outletId;
        this.selectedOutlet.id = this.outletId;
        this.GetShopItems();
        break;

      case 'ActiveOutlets':
        if (result.result) {
          const outletResult: any = result.result;
          this.retailService.allActiveOutlets = outletResult.map(x => {
            return { id: x.subPropertyID, name: x.subPropertyName };
          });
        } else {
          this.retailService.allActiveOutlets = [];
        }
        break;

      case 'GetShopItem':
        if (result.result) {
          this.cdRef.detach();
          const res = <any>result.result;
          const shopItem = this.retailService.originalItemDetail = res.retailItem;
          const inventoryItem = this.retailService.inventoryInfo = res.inventoryInfo;
          const dialogRef = this.dialog.open(RetailPopupComponent, {
            width: '90%',
            height: '80%',
            maxHeight: '1000px',
            maxWidth: '1200px',
            disableClose: true,
            data: {
              headername: this.captions.EditRetailItem,
              closebool: true,
              templatename: 'RS',
              datarecord: [shopItem, inventoryItem],
              popupConfig: {
                operation: 'edit'
              }
            },
            hasBackdrop: true,
            panelClass: 'small-popup'
          });

          dialogRef.afterClosed().subscribe((updatedItem) => {
            this.cdRef.reattach();
            this.cdRef.detectChanges();
            this.isEditMode = false;
            this.GetOutletsByPropertyId();
            const item = this.retailService.retailTable.find(x => x.id === updatedItem.retailItem.retailItemDetail.id);
            const outletItem = updatedItem.inventoryInfo.outlets.find(x => x.outletId === this.outletId);
            updatedItem = updatedItem.retailItem.retailItemDetail;

            if (item) {
              const category = this.retailService.categories.find(x => x.id === updatedItem.category);
              item.itemNumber = updatedItem.itemNumber;
              item.itemName = updatedItem.itemDescription;
              item.categoryName = category ? category.name : '';
              item.salesPrice = updatedItem.salesPrice;
              item.isInActive = !updatedItem.isActive;
              item.costPrice = updatedItem.costPrice;
              item.printqty = 0;

              if (outletItem) {
                item.quantityOnHand = outletItem.updatedQuantity ? outletItem.updatedQuantity : outletItem.quantity;
                item.itemPar = outletItem.itemPar;
              }
            }

            this.BindTableData(this.retailService.retailTable);
          });
        }
        break;

      case 'Outlet':
        if (result.result) {
          const outletResult: any = result.result;
          this.outlets = outletResult.map(x => {
            return {
              id: x.id, name: x.outletName
            };
          });
        } else {
          this.outlets = [];
        }
        break;

      case 'GetCategoryAndSubCategoryLink':
        const response = <any>result.result;
        this.categories = response.category ? this.utils.sortObj(response.category, 'listOrder') : [];
        this.subCategoryOne = response.subCategoryOne ? this.utils.sortObj(response.subCategoryOne, 'listOrder') : [];
        this.subCategoryTwo = response.subCategoryTwo ? this.utils.sortObj(response.subCategoryTwo, 'listOrder') : [];
        this.subCategoryThree = response.subCategoryThree ? this.utils.sortObj(response.subCategoryThree, 'listOrder') : [];
        this.subCategoryFour = response.subCategoryFour ? this.utils.sortObj(response.subCategoryFour, 'listOrder') : [];
        this.subCategoryFive = response.subCategoryFive ? this.utils.sortObj(response.subCategoryFive, 'listOrder') : [];
        this.categoriesClone = _.cloneDeep(this.categories);
        this.subCategoryOneClone = _.cloneDeep(this.subCategoryOne);
        this.subCategoryTwoClone = _.cloneDeep(this.subCategoryTwo);
        this.subCategoryThreeClone = _.cloneDeep(this.subCategoryThree);
        this.subCategoryFourClone = _.cloneDeep(this.subCategoryFour);
        this.subCategoryFiveClone = _.cloneDeep(this.subCategoryFive);
        break;

      case 'UpdateRetailItemStatus':
        this.retailService.retailTable = this.retailService.retailTable.filter(x => this.isInActive || !x.isInActive);
        this.BindTableData(this.retailService.retailTable);
        break;
    }
    this.utils.ToggleLoaderWithMessage(false);
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc === 'SyncRetailItems') {
      this.disableSyncUpBtn = false;
    }

    if (callDesc === 'PosFullSync') {
      this.utils.showAlert(this.localization.captions.retailsetup.PosSyncFailed, AlertType.Error, GlobalConst.ButtonType.Ok);
      this.utils.ToggleLoaderWithMessage(false);
    }
  }

  SyncRetailItems() {
    this.utils.ToggleLoaderWithMessage(true, this.localization.captions.lbl_processing);
    this.disableSyncUpBtn = true;
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'SyncRetailItems',
      uriParams: { outletId: this.selectedOutlet.id },
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  posFullSync() {
    // this.utils.ToggleLoader(true)
    this.utils.ToggleLoaderWithMessage(true, this.localization.captions.lbl_processing);
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'PosFullSync',
      uriParams: { outletId: this.selectedOutlet.id },
      body: {},
      method: HttpMethod.Put,
      showError: false,
      extraParams: []
    });
  }

  GetOutlets() {
    this.utils.ToggleLoaderWithMessage(true, this.localization.captions.lbl_processing);
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'ActiveOutlets',
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  GetOutletsByPropertyId() {
    this.utils.ToggleLoaderWithMessage(true, this.localization.captions.lbl_processing);
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'GetSubPropertyAccessByUser',
      method: HttpMethod.Get,
      showError: true,
      uriParams: { userId: this.utils.GetPropertyInfo("UserId") },
      extraParams: []
    });
  }

  GetItemToEdit(id) {
    this.utils.ToggleLoaderWithMessage(true, this.localization.captions.lbl_processing);
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'GetShopItem',
      method: HttpMethod.Get,
      showError: true,
      uriParams: { itemId: id },
      extraParams: []
    });
  }

  toggleAdvancedSearch() {
    this.enableAdvSearch = true;
    if (this.enableAdvSearch && this.setheight) {
      this.setheight = false;
    }
    this.GetAllLinking();
  }

  closeAdvanceSearch() {
    this.setheight = true;
    this.doneAdvSearch = true;

    const advHeight = document.getElementsByClassName('AdvancedSearchDiv')[0]['offsetHeight'];
    document.getElementById('SPACustomTable').style['height'] = (document.getElementById('SPACustomTable')['offsetHeight'] + advHeight) + 'px';
    document.getElementsByClassName('retail-CustomDataTable')[0]['style']['height'] = 'calc(100% - 170px)';

    this.enableAdvSearch = false;
    this.selectedCategory = 0;
    this.selectedSubCategory1 = 0;
    this.selectedSubCategory2 = 0;
    this.selectedSubCategory3 = 0;
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;
    this.lastSelectedCategory = 0;
    this.lastSelectedSubCategory1 = 0;
    this.lastSelectedSubCategory2 = 0;
    this.lastSelectedSubCategory3 = 0;
    this.lastSelectedSubCategory4 = 0;
    this.lastSelectedSubCategory5 = 0;

    if (this.doneAdvSearch) {
      this.doneAdvSearch = false;
      const timeOut = setTimeout(() => {
        this.SearchByDetails();
        clearTimeout(timeOut);
      }, 100);
    }
  }

  RowSelected(event) {
    if (event.length > 0 && this.fromOutlet && !event.some(s => s.isInActive)) {
      this.enabletransferButton = true;
    } else {
      this.enabletransferButton = false;
    }
    if (event.length == this.tableoptions[0].TablebodyData.length) {
      this.isAllItemsSelected = true;
      event.map(r => r.checked = true);
      this.retailService.retailTable = event;
    } else {
      this.isAllItemsSelected = false;
      if (event && event.length > 0) event.map(x => x.checked = true);
    }
    this.selectedTabledata = event;
  }

  SearchByCategories() {
    if (this.selectedCategory === 0 && this.selectedSubCategory1 === 0
      && this.selectedSubCategory2 === 0 && this.selectedSubCategory3 === 0
      && this.selectedSubCategory4 === 0 && this.selectedSubCategory5 === 0 && this.vendorId === 0) {
      return;
    }

    this.doneAdvSearch = true;
    this.lastSearchTxt = this.searchTxt;
    this.lastSelectedCategory = this.selectedCategory;
    this.lastSelectedSubCategory1 = this.selectedSubCategory1;
    this.lastSelectedSubCategory2 = this.selectedSubCategory2;
    this.lastSelectedSubCategory3 = this.selectedSubCategory3;
    this.lastSelectedSubCategory4 = this.selectedSubCategory4;
    this.lastSelectedSubCategory5 = this.selectedSubCategory5;

    this.GetShopItems();
  }

  GetUnitOfMeasure() {
    this.retailService.InvokeServiceCall('GetActiveUnitOfMeasures', GlobalConst.Host.retailManagement, HttpMethod.Get);
  }

  shownIactiveRecords(event: any) {
    this.isInActive = event[0];
    this.retailSetupFormGrp.controls.shownIactiveRecords.setValue(event[0]);
    this.GetShopItems();
  }
}
