import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';


@Component({
  selector: 'app-form-radio',
  templateUrl: './form-radio.component.html',
  styleUrls: ['./form-radio.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormRadioComponent implements OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;
  constructor() { }

  ngOnInit() {

  }

  radioChange(event, ctrlName?) {
    if (this.config.cellClick) {
      this.config.cellClick(event);
    }
  }

}
