import { Injectable } from '@angular/core';
import { API } from 'src/app/retail/letter-setup/template.model';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { RetailCommunication } from 'src/app/retail/retailcommunication.service';
import { PrintLetter, Tags } from 'src/app/retail/shared/shared.modal';

@Injectable({
    providedIn: 'root'
})
export class TemplateDataService {
    constructor(private _http: RetailCommunication) {
    }

    public getAllTemplatesByType(templateType: number, includeInActiveParam: boolean = false): Promise<API.Template[]> {
        const result = this._http.getPromise<API.Template[]>(
            { route: RetailRoutes.GetAllTemplatesByType, uriParams: {type : templateType, includeInactive: includeInActiveParam } }, false);
        return result;
    }
    public getTemplateById(templateType: number, templateId: number, nextListOrder = false): Promise<API.Template> {
        const result = this._http.getPromise<API.Template>(
            { route: RetailRoutes.GetTemplateById, uriParams: { type : templateType, id: templateId, returnNextListOrder: nextListOrder } }, false);
        return result;
    }
    public createTemplate(body: API.Template): Promise<boolean> {
        const result = this._http.postPromise<boolean>(
            { route: RetailRoutes.CreateTemplate, body }, true);
        return result;
    }
    public updateTemplate(body: API.ListOrderTemplate): Promise<boolean> {
        const result = this._http.putPromise<boolean>(
            { route: RetailRoutes.UpdateTemplate, body }, true);
        return result;
    }

    public UpdateListOrder(body: API.ListOrderTemplate): Promise<boolean> {
        const result = this._http.putPromise<boolean>(
            { route: RetailRoutes.UpdateListOrder, body }, true);
        return result;
    }
    public deleteTemplate(id: number): Promise<boolean> {
        const result = this._http.deletePromise<boolean>(
            { route: RetailRoutes.DeleteTemplate, uriParams: { id } }, true);
        return result;
    }

    public async getNextListOrder(templateType: number): Promise<number> {
        const result: number = await this._http.getPromise<number>(
            { route: RetailRoutes.GetNextListOrder_Template,
                uriParams: { type: templateType } });
        return result;
    }

    public async getLetterSetupTemplateTags(type: number) : Promise<Tags[]>  {
        return await this._http.getPromise<Tags[]>({
            route: RetailRoutes.GetTemplateTags,
            uriParams: { templateType: type }
        });
  }

  public async GetAllTemplatesForRetailDropdown(includeInActiveParam: boolean) {
    return await this._http.getPromise<API.RetailItemTemplate[]>({
      route: RetailRoutes.GetAllTemplatesForRetailDropdown,
      uriParams: {  includeInactive: includeInActiveParam}
    });
  }
  public PrintLetter(JsonData,retailItemId: number,templateType: number, printType : number): Promise<PrintLetter[]> {
    const result = this._http.postPromise<PrintLetter[]>(
        { route: RetailRoutes.PrintLetter, 
            body:JsonData,
            uriParams: { retailItemId: retailItemId,type : templateType, printType: printType } }, false);
    return result;
}
}
