<div class="financial-details" *ngIf="financialDetail">
    <div class="financial-details__header seat-popover-header">
      {{financialDetail.header}}
    </div>
    <div class="financial-details__content seat-popover-content">
      <div class="financial-details__paid">
        <div>{{financialDetail.message}}</div>
        <div class="financial-details__due" *ngIf = "financialDetail.paymentduemessage">{{financialDetail.paymentduemessage}}</div>
        <div class="financial-details__due cancelled-booking-financial-details" *ngIf="financialDetail.paymentMessage">
          {{financialDetail.paymentMessage}}</div>
        <div class="financial-details__due cancelled-booking-financial-details" *ngIf="financialDetail.subMessage">
          {{financialDetail.subMessage}}</div>

            <div *ngIf="paymentResponse.PaidPoints > 0">{{'Paid'| translate}} {{'roundsText' | translate}}: {{paymentResponse.PaidPoints}}</div>
           <div *ngIf="paymentResponse.PendingPoints > 0"> {{'pending' | translate}} {{'roundsText' | translate}}: {{paymentResponse.PendingPoints}}</div>
         
      </div>
    </div>
  </div>