import { Injectable } from '@angular/core';
import { Observable,  } from 'rxjs';
import { GridType, Host, PromptType, ButtonOptions, SPAManagementBreakPoint } from '../../globalsContant';
import { GridHeader, SetupMenu } from '../../business/view-settings.modals';
import { HttpServiceCall, HttpMethod } from '../../service/http-call.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogOverviewExampleDialog } from '../../dialog-popup/dialogPopup-componenet';
import { UntypedFormGroup } from '@angular/forms';
import { UserAlerts } from '../../../config/alerts-config';
import {BaseResponse, UserBreakPoint } from '../../business/shared.modals';
import { BreakPointAccess } from '../../service/breakpoint.service';
import { Localization } from '../../../../localization/localization';

@Injectable()
export class ViewSettingClientBusiness {
  private maxLstOrder: number;
  public activeFormGroup: UntypedFormGroup;
  public IsActiveFormDirty: boolean;
  private captions: any;
  private commonCaptions: any = this.localization.captions.common;
  public setupBreakPoints: UserBreakPoint[] = [];

  constructor(public dialog: MatDialog,
    public localization: Localization,
    private http: HttpServiceCall, private userAlerts: UserAlerts,
    private breakpoint: BreakPointAccess) {
    this.captions = this.localization.captions;
  }

  getDataheader(serviceType: number): Array<GridHeader> {
    let arrDataHeader: any;

    if (serviceType == GridType.equipment) {
      arrDataHeader = [{ "title": this.localization.captions.setting.equipcode, "jsonkey": "code", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.EquipDesc, "jsonkey": "description", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.Quantity, "jsonkey": "quantity", "alignType": "right", "searchable": false }];
    }
    else if (serviceType == GridType.location) {

      arrDataHeader = [{ "title": this.localization.captions.setting.LocationCode, "jsonkey": "code", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.LocationDesc, "jsonkey": "description", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.ListOrder, "jsonkey": "listOrder", "alignType": "left", "searchable": false }];

    } else if (serviceType == GridType.group) {
      arrDataHeader = [{ "title": this.localization.captions.setting.ServiceGroupCode, "jsonkey": "code", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.ServiceGroupDesc, "jsonkey": "description", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.ListOrder, "jsonkey": "listOrder", "alignType": "left", "searchable": false }];
    } else if (serviceType == GridType.medical) {

      arrDataHeader = [{ "title": this.localization.captions.setting.ConditionCode, "jsonkey": "code", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.MedicalDesc, "jsonkey": "description", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.ConditionWarning, "jsonkey": "warning", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.PrivateCondition, "jsonkey": "privateCondition", "alignType": "left", "searchable": false }];

    } else if (serviceType == GridType.addons) {
      arrDataHeader = [{ "title": this.localization.captions.setting.AddOnName, "jsonkey": "addOnName", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.AddOnPrice + ' (' + this.localization.currencySymbol + ')', "jsonkey": "price", "alignType": "right", "datatype": "money", "searchable": false },
      { "title": "", "jsonkey": "", "alignType": "left" },
      { "title": this.localization.captions.setting.AddOnRetailCategory, "jsonkey": "retailCategory", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.AddOnIsCommissionable, "jsonkey": "commissionable", "alignType": "left", "searchable": false }
      ];
    }
    else if (serviceType == GridType.spaServices) {
      arrDataHeader = [{ "title": this.localization.captions.setting.ServiceCode, "jsonkey": "code", "searchable": true },
      { "title": this.localization.captions.setting.ServiceDescription, "jsonkey": "description", "searchable": true },
      { "title": this.localization.captions.setting.ServiceGroup, "jsonkey": "serviceGroupDescription", "searchable": true }
      ];
    }
    else if (serviceType == GridType.therapist) {
      arrDataHeader = [{ "title": this.localization.captions.setting.TherapistCode, "jsonkey": "code", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.TherapistName, "jsonkey": "name", "alignType": "left", "searchable": true , "showStatus": true},
      { "title": this.localization.captions.setting.ListOrder, "jsonkey": "listOrder", "alignType": "left", "searchable": false }
      ];
    }
    else if (serviceType == GridType.commission) {
      arrDataHeader = [{ "title": this.localization.captions.setting.Description, "jsonkey": "itemDescription", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.Location, "jsonkey": "locationDescription", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.Threshold + ` (${this.localization.currencySymbol})`, "jsonkey": "threshold", "datatype": "money", "alignType": "right", "searchable": true },
      { "title": this.localization.captions.setting.FlatAmount + ` (${this.localization.currencySymbol})`, "jsonkey": "flatAmount", "datatype": "money", "alignType": "right", "searchable": true },
      { "title": this.localization.captions.setting.Percentage, "jsonkey": "percentage", "datatype": "percentage", "alignType": "right", "searchable": false },
      { "title": "", "jsonkey": '', "alignType": "left" },
      { "title": this.localization.captions.setting.CommissionOn, "jsonkey": "commissionon", "displayType": "icon", "searchable": true },
      { "title": this.localization.captions.setting.AfterDiscount, "jsonkey": "isAfterDiscount", "alignType": "left", "searchable": false }
      ]
    }
    else if (serviceType == GridType.spaPackage) {
      arrDataHeader = [{ "title": this.localization.captions.setting.SpaPackageCode, "jsonkey": "code", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.SpaPackageDescription, "jsonkey": "description", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.SpaPackagePrice + ' (' + this.localization.currencySymbol + ')', "jsonkey": "packagePrice", "alignType": "right", "datatype": "money", "searchable": false },
      ];
    }
    else if (serviceType == GridType.customfield) {
      arrDataHeader = [
        { "title": this.localization.captions.setting.CustomFieldHdr, "jsonkey": "columnName", "alignType": "left", "searchable": false },
        { "title": this.localization.captions.setting.DisplayFieldHdr, "jsonkey": "fieldName", "alignType": "left", "searchable": false }
      ];
    }
    else if (serviceType == GridType.breakType) {
      arrDataHeader = [{ "title": this.localization.captions.setting.breakTypeCode, "jsonkey": "code", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.BreakTypeDescription, "jsonkey": "description", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.isBreakTypeUtilized, "jsonkey": "isUtilized", "alignType": "left", "searchable": false },
      { "title": this.localization.captions.setting.breakTypeColor, "jsonkey": "color", "alignType": "left", "searchable": false },
      ];
    }
    else if (serviceType == GridType.colorLink) {
      arrDataHeader = [{ title: this.localization.captions.setting.LinkCode, jsonkey: "code", alignType: "left", "searchable": true }, { title: this.localization.captions.setting.LinkDescription, jsonkey: "description", alignType: "left", "searchable": true },
      { title: this.commonCaptions.StartDate, jsonkey: "startDate", alignType: "left", "searchable": false, "datatype": "date" }, { title: this.commonCaptions.EndDate, jsonkey: "endDate", alignType: "left", "searchable": false, "datatype": "date" },
      { title: this.localization.captions.setting.Color, jsonkey: "color", alignType: "left", "searchable": false }, { title: this.localization.captions.setting.ListOrder, jsonkey: "listOrder", alignType: "left", "searchable": false },
      { title: this.localization.captions.setting.Status, jsonkey: "status", alignType: "left", "searchable": false }]
    }
    else if (serviceType == GridType.priceType) {
      arrDataHeader = [{ "title": this.localization.captions.setting.priceCode, "jsonkey": "code", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.priceDescription, "jsonkey": "description", "alignType": "left", "searchable": true }
      ];
    }
    else if (serviceType == GridType.packageClasses) {
      arrDataHeader = [{ "title": this.localization.captions.setting.packageClassCode, "jsonkey": "code", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.packageClassDescription, "jsonkey": "className", "alignType": "left", "searchable": true }
      ];
    }
    else if (serviceType == GridType.cancelReasons) {
      arrDataHeader = [{ "title": this.localization.captions.setting.cancelCode, "jsonkey": "reasonCode", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.isCancelReasonActive, "jsonkey": "Status", "alignType": "left", "searchable": false, "showStatus": true }
      ];
    }
    else if (serviceType == GridType.Relationship) {
      arrDataHeader = [{ "title": this.localization.captions.setting.RelationshipCode, "jsonkey": "code", "alignType": "left", "searchable": true },
      { "title": this.localization.captions.setting.RelationshipName, "jsonkey": "name", "alignType": "left", "searchable": true, "showStatus": false },
      { "title": this.localization.captions.setting.ListOrder, "jsonkey": "listOrder", "alignType": "left", "searchable": false },
      { "title": this.localization.captions.setting.active, jsonkey: "toggleActive", alignType: "left", "searchable": false ,"type": "toggle"}
    ];
    }
    return arrDataHeader;
  }


  getUtilitiesDataHeader(utilType) {
    let arrDataHeader: any;

    switch (utilType) {
      case GridType.password:
        arrDataHeader = [{ "title": this.localization.captions.utilities.RuleList, "jsonkey": "ruleName", "alignType": "left" },
        { "title": this.localization.captions.utilities.PasscodeStatus, "jsonkey": "status", "displayType": "icon", "alignType": "left" }
        ];
        break;
      case GridType.locationMaintenance:
        arrDataHeader = [{ "title": this.localization.captions.utilities.LocationCode, "jsonkey": "locationCode", "alignType": "left", "searchable": true },
        { "title": this.localization.captions.utilities.LocationDescription, "jsonkey": "locationDescription", "alignType": "left", "searchable": true },
        { "title": this.localization.captions.utilities.BeginAt, "jsonkey": "beginAt", "sortcolumn": "startTime", "sortcolumndatatype": "Date", "alignType": "left", "searchable": false },
        { "title": this.localization.captions.utilities.EndAt, "jsonkey": "endAt", "sortcolumn": "endTime", "sortcolumndatatype": "Date", "alignType": "left", "searchable": false },
        { "title": this.localization.captions.utilities.Comments, "jsonkey": "comments", "alignType": "left", "searchable": false }];
        break;
      case GridType.equipmentMaintenance:
        arrDataHeader = [{ "title": this.localization.captions.utilities.EquipmentCode, "jsonkey": "equipmentCode", "alignType": "left", "searchable": true },
        { "title": this.localization.captions.utilities.EquipmentDescription, "jsonkey": "equipmentDescription", "alignType": "left", "searchable": true },
        { "title": this.localization.captions.utilities.BeginAt, "jsonkey": "beginAt", "sortcolumn": "startTime", "sortcolumndatatype": "Date", "alignType": "left", "searchable": false },
        { "title": this.localization.captions.utilities.EndAt, "jsonkey": "endAt", "sortcolumn": "endTime", "sortcolumndatatype": "Date", "alignType": "left", "searchable": false },
        { "title": this.localization.captions.utilities.Comments, "jsonkey": "comments", "alignType": "left", "searchable": false }];
        break;
      case GridType.therapistUnavailability:
        arrDataHeader = [{ "title": this.localization.captions.utilities.TherapistCode, jsonkey: "therapistCode", "alignType": "left", "searchable": true },
        { "title": this.localization.captions.utilities.TherapistName, jsonkey: "therapistName", "alignType": "left", "searchable": true },
        { "title": this.localization.captions.utilities.StartDate, jsonkey: "startDateView", "alignType": "left", "sortcolumn": "startDate", "sortcolumndatatype": "Date", "searchable": false },
        { "title": this.localization.captions.utilities.EndDate, jsonkey: "endDateView", "alignType": "left", "sortcolumn": "endDate", "sortcolumndatatype": "Date", "searchable": false },
        { "title": this.localization.captions.utilities.Reason, jsonkey: "reason", "alignType": "left", "searchable": false },
        { "title": this.localization.captions.utilities.Services, jsonkey: "services", "alignType": "left", "searchable": false }];
        break;
      default:
        break;
    }

    return arrDataHeader;
  }

  getMaxListOrder(routeUrl: string): Observable<BaseResponse<number>> {
    return this.http.CallApi<BaseResponse<number>>(HttpMethod.Get, routeUrl);
  }

  getServiceData<T>(httpMethod: HttpMethod, routeURL: string, success: <T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]) => void,
    error: <T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]) => void, showError: boolean, extraParams?: any[]): void {
    this.http.CallApiWithCallback<T[]>({
      host: Host.spaManagement,
      success: success,
      error: error,
      callDesc: routeURL,
      method: httpMethod,
      showError: showError,
      extraParams: extraParams
    });
  }

  openErrorDialog(error, Type, header): void {
    this.dialog.open(DialogOverviewExampleDialog, {
      height: 'auto',
      width: '600px',
      data: { headername: header, closebool: true, templatename: 'In the Given Data', datarecord: error, Type: Type },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
  }
  /**
   * This is prompt for unsaved changes.
   * will happen only if the form is dirty.
   * @param Dialog Current active Dialog reference.
   */
  promptUserDialog(Dialog: any) {
    if (this.activeFormGroup.dirty) {
      this.userAlerts.showPrompt(PromptType.UnsavedChanges, this.PopupCallback.bind(this), Dialog)
    } else {
      Dialog.close();
    }
  }

  public getSetupBreakPoints(setupMenu: SetupMenu[]): SetupMenu[] {
    this.setupBreakPoints = this.breakpoint.GetBreakPoint([SPAManagementBreakPoint.ServiceGroup,
    SPAManagementBreakPoint.ServiceLocation, SPAManagementBreakPoint.ServiceEquipment, SPAManagementBreakPoint.ServiceAddOn,
    SPAManagementBreakPoint.TherapistSetup, SPAManagementBreakPoint.SpaService,
    SPAManagementBreakPoint.MedicationCondition, SPAManagementBreakPoint.SpaPackage, SPAManagementBreakPoint.PackageClasses
      , SPAManagementBreakPoint.LunchSetup, SPAManagementBreakPoint.ColorLinking, SPAManagementBreakPoint.BreakType, SPAManagementBreakPoint.PriceType, SPAManagementBreakPoint.CustomFields,
    SPAManagementBreakPoint.CancelReason,SPAManagementBreakPoint.Relationship
    ]).result;

    setupMenu.find(sm => sm.id == GridType.group).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.ServiceGroup);
    setupMenu.find(sm => sm.id == GridType.location).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.ServiceLocation);
    setupMenu.find(sm => sm.id == GridType.equipment).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.ServiceEquipment);
    setupMenu.find(sm => sm.id == GridType.addons).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.ServiceAddOn);
    setupMenu.find(sm => sm.id == GridType.therapist).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.TherapistSetup);
    setupMenu.find(sm => sm.id == GridType.spaServices).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.SpaService);
    setupMenu.find(sm => sm.id == GridType.medical).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.MedicationCondition);
    setupMenu.find(sm => sm.id == GridType.spaPackage).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.SpaPackage);
    setupMenu.find(sm => sm.id == GridType.packageClasses).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.PackageClasses);
    setupMenu.find(sm => sm.id == GridType.lunchsetup).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.LunchSetup);
    setupMenu.find(sm => sm.id == GridType.colorLink).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.ColorLinking);
    setupMenu.find(sm => sm.id == GridType.breakType).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.BreakType);
    setupMenu.find(sm => sm.id == GridType.priceType).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.PriceType);
    setupMenu.find(sm => sm.id == GridType.customfield).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.CustomFields);
    setupMenu.find(sm => sm.id == GridType.cancelReasons).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.CancelReason);
    setupMenu.find(sm => sm.id == GridType.Relationship).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.Relationship);

    return setupMenu;
  }


  private IsActionAllowed(breakPoint: number): boolean {
    const _breakPoint: UserBreakPoint = this.setupBreakPoints.find(bp => bp.breakPointNumber == breakPoint);
    return _breakPoint ? (_breakPoint.allow || _breakPoint.view) : false;
  }

  private PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() === ButtonOptions.Yes) {
      extraParams.close();
    }
  }

}
