import { Injectable } from '@angular/core';
import { SettingsService } from '../services/settings.service';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentTypes, PricingBy ,ClassType} from '../constants/commonenums';
import { TemplateSelectionComponent } from '../components/activity-templates/template-selection/template-selection.component'
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '@app/popup-module/popup.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CacheService } from '@app/core/services/cache.service';
import { PrePaymentMode } from '../models/RestaurantDTO';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject,Subscription } from 'rxjs';
import moment from 'moment';
@Injectable()
export class ActivityCreateFunctions {
  templateSelectionDialog: MatDialogRef<any>
  subscriptions: Subscription = new Subscription;
  constructor(public dialog: MatDialog, public ss: SettingsService, public ts: TranslateService, public ps: PopupService, public cs: CacheService,private _fb: UntypedFormBuilder) { }
  
  ngOnInit() {
 
  }
  getAvailableInstructorsByRestaurant(calendarDetailsValue, callBack) {
    let calendarDetails = calendarDetailsValue;
    if (calendarDetails && calendarDetails.StartDate && calendarDetails.EndDate && calendarDetails.StartTime && calendarDetails.EndTime) {
      let payload = {
        ServerId: null,
        StartDate: moment(calendarDetails.StartDate).format('YYYY-MM-DD') + "T00:00:00.000" ,
        EndDate: moment(calendarDetails.EndDate).format('YYYY-MM-DD') + "T00:00:00.000" , 
        StartTime: calendarDetails.StartTime,
        EndTime: calendarDetails.EndTime,
        DayOfWeek: calendarDetails?.DayOfWeek || 7
      }
      this.subscriptions.add(this.ss.GetAvailableInstructorsByRestaurant(payload).subscribe(data => {
        callBack(data?.Payload);
      }))
    }
  }

  chooseTemplates() {
    this.subscriptions.add(this.ss.GetActivityTemplates().subscribe(data => {
      console.log(data);
      this.openTemplateSelectionDialog(data.Payload);
    }))
  }
  openTemplateSelectionDialog(InputData?: any) {
    const packageTitle: string = this.ts.instant('templateSelection');
    const componentDetails = {
      componentName: TemplateSelectionComponent,
      dimensionType: 'small',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: InputData || [],
      popupTitle: packageTitle
    }
    this.templateSelectionDialog = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '80%',
      height: '90%',
      data: {
        templates: InputData,
        componentDetails,
        from: ComponentTypes.templateSelection,
        back: true,
        standalone: true,
        showFooter: false
      }
    });
    let popupSubscription = this.ps.confirmedAction$.subscribe(val => {
      if (val == ComponentTypes.templateSelection) {

      }
    })
    this.subscriptions.add(this.templateSelectionDialog.afterClosed().subscribe(event => {
      popupSubscription.unsubscribe();
    }));
  }
  getActivityType(type) {
    switch (type) {
      case PricingBy.Session:
        return this.ts.instant('Session');
      case PricingBy.Class:
        return this.ts.instant('Class');
      case PricingBy.Duration:
        return this.ts.instant('Private');
    }
  }
  getPrePaymentMode(type) {
    switch (type) {
      case PrePaymentMode.DefferredPaymentMode:
        return this.ts.instant('DeferredPrepayment');
      case PrePaymentMode.DepositPaymentMode:
        return this.ts.instant('CollectDeposit')
      case PrePaymentMode.PrepaidPaymentMode:
        return this.ts.instant('Chargeonbooking')
      case PrePaymentMode.ChargeduringCheckIn:
        return this.ts.instant('ChargeduringCheck-In')
      case PrePaymentMode.ChargeduringCheckOut:
        return this.ts.instant('ChargeduringCheck-Out') 
      default: return this.ts.instant('None')
    }
  }
  templateToActivityConverter(activity , classType) {
    let data = activity;
    data.Id = 0;
    if (data.ActivitySessions.length) {
      data.ActivitySessions.map(session => {
        session.ActivitySessionId = 0;
        session.SpecialMealId = 0;
        if(classType === ClassType.Class){
          session.SessionGroupId = 0;
        }else{
          session.SessionGroupId = null;
        }
        if (session?.SessionStaffMappings?.length) {
          session.SessionStaffMappings.map(staffMapping => {
            staffMapping.ActivitySessionId = 0;
            staffMapping.Id = 0;
          })
        }
        if (session?.SessionLocationMappings?.length) {
          session?.SessionLocationMappings.map(sessionLocationMapping => {
            sessionLocationMapping.ActivitySessionId = 0;
            sessionLocationMapping.Id = 0;
          })
        }
      })
    }
    if (data?.ActivityAttributes.length) {
      data.ActivityAttributes.map(attributes => {
        attributes.Id = 0;
        attributes.ActivityId = 0;
        attributes.ActivitySessionId = null;
      })
    }
    if (data?.CategoryWithAddOns.length) {
      data.CategoryWithAddOns.map(category => {
        if (category.SubCategories?.length) {
          category.SubCategories.map(subCategory => {
            if (subCategory.AddOns?.length) {
              subCategory.AddOns.map(addOn => {
                if (addOn.AddonActivitiesMappings?.length) {
                  addOn.AddonActivitiesMappings.map(mapping => {
                    if (mapping) {
                      mapping.MappingId = 0;
                      mapping.ActivityId = 0;
                    }
                  })
                }
              })
            }
          })
        }
      })
    }
    if(data?.SessionGroups.length){
      data.SessionGroups.map((group =>{
        group.Id = 0 ; 
      }))
    }
    return data; 
  }
}