<div class="upload-photo">
  <div class="upload-photo__profile-view">
    <div class="image-avatar" *ngIf="guestPhoto">
      <span *ngIf="url == ''" class="upload-photo__upload-image icon-avatar">
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
      </span>
    </div>
    <div class="upload-photo__common-view" (click)="compressFile()" *ngIf="guestPhoto == false && url  == '' " >
      <span class="icon-cloud-upload"></span>
      <div class="add-img">    {{'uploadAddonImage' | translate}}  </div>
    </div>
    <span class="upload-photo__image-view" *ngIf="url != ''">
      <div class="upload-photo__form-image">
        <img class="upload-photo__load-image" [ngStyle]="{'right':guestPhoto == false ? '6%' : '0%' }" [ngClass]="{'rectangle-view' : guestPhoto == false}" [src]="url" />
      </div>
    </span>
    <div class="upload-photo__delete-image">
      <a *ngIf="url == '' && guestPhoto" (click)="compressFile()" class="upload-photo__add-image" >{{'guestBookAddImage' |
        translate}}</a>
      <a *ngIf="url != ''" [ngClass]="{'upload-actions' : guestPhoto == false}" (click)="compressFile()" class="upload-photo__edit-image">{{'guestBookChangeImage' |
        translate}}</a>
      <a *ngIf="url != ''" [ngClass]="{'upload-actions' : guestPhoto == false}" (click)="deleteImage()" class="upload-photo__edit-image ml-4">{{'guestBookRemoveImage' |
        translate}}</a>
    </div>
    <br />
  </div> 