import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardEntryModeComponent } from './card-entry-mode/card-entry-mode.component';
import { CardTypeComponent } from './card-type/card-type.component';
import { AgCaptureCardComponent } from './capture-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material-module';



@NgModule({
  declarations: [AgCaptureCardComponent,
    CardTypeComponent,
    CardEntryModeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [
    AgCaptureCardComponent,
    CardTypeComponent,
    CardEntryModeComponent
  ]
})
export class CaptureCardModule { }
