<section class="radiosearchsection">
    <mat-radio-group [(ngModel)]='radiosearchBy' (change)="radioChange($event)">
        <mat-radio-button class='ag_form--radio-button' [ngClass]="opt.hideOption ? 'ag_display--none' : 'ag_display--inblock'" [value]='opt' (searchSelectionChange) ="searchSelectionChangeEmitter($event)"
            *ngFor="let opt of radiomodeOptions" [title]="opt.viewValue">
            {{opt.viewValue}}
        </mat-radio-button>
    </mat-radio-group>    
    <app-ag-dropdown *ngIf="isStatusFiltered" id="ag-automation-select-status" class="pr-3" [inputs]="statusInput"></app-ag-dropdown>
    <mat-form-field class="radiosearchbox" [floatLabel]="floatLabelNever">
        <input type="text" [placeholder]="searchPlaceholder" [disabled]="searchDisabled" matInput [(ngModel)]="radiosearchText" autocomplete="off"
            (keydown.enter)="(radiosearchText.length >= mincharlength) && searchData($event)">
        <i aria-hidden="true" [ngClass]="radiosearchText ? 'ag_cursor--pointer icon-Cancel': 'icon-search'"
            (click)="action()"></i>
    </mat-form-field>
    <i aria-hidden="true" class="icon-search ag_cursor--pointer iconbtnstyle"
        [ngClass]="{'disabledBtn':!(radiosearchText.length >= mincharlength) }" (click)="searchData($event)"></i>
    <mat-checkbox class="ag-pl-2" *ngIf="history" (change)="includeHistory($event)">{{captions.lbl_include_history}}
    </mat-checkbox>
</section>
