<div [formGroup]="unpaidPlayersForm" class="newVersionWrapper unpaid-player-wrapper">
  <div class="header-wrapper header">
      <app-date-picker [inputs]="dateInput" (datePickerChange)="dateChanged($event)"></app-date-picker>
    <mat-form-field [floatLabel]="floatLabel">
      <mat-select [attr.automationId]="'Dd_UnpaidPlayers_Course'" [placeholder]="captions.course" name="CourseSelect"  (selectionChange)="OnCourseChange($event)" formControlName="courseSelect" >
          <mat-option *ngFor="let course of courseSelect" [value]="course.id">
              {{course.name}}
          </mat-option>
      </mat-select>
    </mat-form-field>

    <section class="right-action-section">
      <app-shop-button [automationId]="'UnpaidPlayers'" [buttontype]="actionButton" (valueChange)="pay($event)"></app-shop-button>
    </section>
  </div>
</div>
<div class="raincheckTable newVersionWrapper">
  <app-shop-tablevirtualscroll [attr.automationId]="'Dd_UnpaidPlayers_UnpaidPlayersTable'" [headerOption]="headerOption" [bodyContentData]="bodyContentdata" [childTemplate]="childTemplate" [fromUnpaidPlayer]="fromUnpaidPlayer"
      [tableOptions]="tableOptions" (EmittedData)="emitteddatavalue($event)">
  </app-shop-tablevirtualscroll>
</div>

<ng-template #childTemplate let-row="rowValue" let-data="value" let-header="headerdata" let-idx="idx">
  <ng-container [ngSwitch]="header.key">
    <ng-container *ngSwitchCase="'action'">
      <a attr.automationId='Lbl_paymentModal_activityAction{{idx}}' (click)='row.isReleaseAllow && activityAction($event,row)'
      *ngIf="row.isHold"
      [ngClass]="{'cust-button-disabled':!row.isReleaseAllow}">{{localization.captions.teetime.release}}</a>
    </ng-container>
  </ng-container>
</ng-template>