import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { SideNavService } from '@services/sidenav.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-messages-view',
  templateUrl: './messages-view.component.html',
  styleUrls: ['./messages-view.component.scss']
})
export class MessagesViewComponent implements OnInit, OnDestroy {
  dashboardMessages: any = [];
  @Input() selectEnabled: boolean;
  config: FieldConfig[];
  subscriptions: Subscription = new Subscription();

  constructor(public sn: SideNavService) { }

  ngOnInit() {
    this.config = [{
      type: 'checkbox',
      name: 'language',
      placeholder: 'selectLanguage',
      class: 'login__language-selection',
      showErrorText: true
    }];
    this.dashboardMessages = [{ id: 1, name: 'John Smith', Text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', status: 'just now', viewed: true },
    { id: 2, name: 'Adam Smith', Text: 'and more recently with desktop publishing software ', status: '2 mins ago', viewed: true },
    { id: 3, name: 'Adam Smith', Text: 'and more recently with desktop publishing software ', status: '2 mins ago', viewed: false }];

    this.subscriptions.add(this.sn.clearData.subscribe(val => {
      if (val === 0) {
        while (this.dashboardMessages.length > 0) {

          // let item = this.dashboardMessages[this.dashboardMessages.length - 1].id;
          // let data = document.getElementById('msg_'+item);
          // data.style.transition ='1s';
          // data.style.transform = 'translate(400px, 0px)';   
          // setTimeout(() => {
          this.dashboardMessages.splice(this.dashboardMessages.length - 1, 1);
          //}, 1000);

        }
        // this.dashboardMessages.forEach(element => {
        //   this.dashboardMessages.splice(element, 1);
        // });

      }
    }));

  }

  closeMessage(e, msg) {
    let element = document.getElementById('msg_' + msg.id);
    element.style.transition = '0.5s';
    element.style.transform = 'translate(400px, 0px)';
    setTimeout(() => {
      element.style.transition = '0.5s height';
      element.style.height = '0px';
      element.style.padding = '0px';
      element.style.display = 'none';
    }, 500);

  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
