import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MaterialModule } from '@app/material-module';
import { SharedModule } from '@shared/shared.module';
import { NgDragDropModule } from 'ng-drag-drop';
import {NgScrollbarModule } from 'ngx-scrollbar';
import { TimeLineRoutingModule } from './timeline-routing.module';
import { TimeLineComponent, ReservationBGColorPipe, ReservationBarLeftPipe, ReservationBarWidthPipe } from './timeline.component';

@NgModule({
    imports: [
        CommonModule,
        TimeLineRoutingModule,
        SharedModule,
        MaterialModule,
        NgScrollbarModule,
        NgDragDropModule.forRoot()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports:[TimeLineComponent, ReservationBGColorPipe, ReservationBarLeftPipe, ReservationBarWidthPipe],
    declarations: [TimeLineComponent, ReservationBGColorPipe, ReservationBarLeftPipe, ReservationBarWidthPipe]
})
export class TimelineModule { }
