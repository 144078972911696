import { Pipe, PipeTransform } from '@angular/core';
import { ReservationStatusPipe } from '@pipes/reservation-status.pipe';
import moment from 'moment';
import { PartyDTO, SettingsDTO, StateDTO } from '../models/RestaurantDTO';
import * as _ from 'lodash';

@Pipe({
  name: 'reservedTableStatusColor'
})
export class ReservedTableStatusColorPipe implements PipeTransform {
  constructor() {

  }
  transform(party: any, seatingParties:PartyDTO[]): any {
    let currentlySeatedTableIds = _.map(seatingParties,'TableIds');
    let reserverdTableAlreadySeated = _.intersection(party.TableIds,[].concat.apply([], currentlySeatedTableIds));

    if(reserverdTableAlreadySeated.length > 0){
      let seatedParty =[];
      reserverdTableAlreadySeated.forEach(tableSeated =>{
        seatedParty.push(_.find(seatingParties, ['TableIds', [tableSeated]]));
      });
      return seatedParty[0];
    }
    else{
      return null;
    }
    }
}


