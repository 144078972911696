<ng-scrollbar>
    <div class="privatelesson-booking-summary">
        <div class="privatelesson-booking-summary__booking-detail">
            <div class="privatelesson-booking-summary__summary-accordian" *ngIf="partyService.privateLessonBookingData">
                <mat-accordion class="p-10">
                    <mat-expansion-panel [expanded]="true"
                        class="privatelesson-booking-summary__summary-accordian--panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title
                                class="privatelesson-booking-summary__section-title privatelesson-booking-summary__section-title--accordian">
                                {{'bookingSummary' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="privatelesson-booking-summary__content">
                            <div class="privatelesson-booking-summary__item privatelesson-booking-summary__item--name">
                                <div class="privatelesson-booking-summary__item__label">{{'instructor' | translate}}
                                </div>
                                <div class="openbooking-summary__item__value">{{instructor?.Name}}
                                    {{instructor?.LastName || ''}}</div>
                            </div>
                            <div
                                class="privatelesson-booking-summary__item privatelesson-booking-summary__item--location">
                                <div class="privatelesson-booking-summary__item__label">{{'location' | translate}}</div>
                                <div class="openbooking-summary__item__value">
                                    {{partyService.privateLessonBookingData.LocationName}}</div>
                            </div>
                            <div class="privatelesson-booking-summary__item privatelesson-booking-summary__item--date">
                                <div class="privatelesson-booking-summary__item__label">{{'date' | translate}}</div>
                                <div class="openbooking-summary__item__value">
                                    {{partyService.privateLessonBookingData.FromDate | formatSettingsDate: cs.settings.value.General.DateFormat}} <span
                                        class="text-small">
                                        {{(partyService.privateLessonBookingData.Slots[partyService.privateLessonBookingData.Slots.length
                                        -1].LocalTime | formatSettingsDate: cs.settings.value.General.DateFormat) !==
                                        (partyService.privateLessonBookingData.FromDate | formatSettingsDate: cs.settings.value.General.DateFormat )?
                                        '(Extended Shift)' : '' }}</span></div>
                            </div>
                            <div
                                class="privatelesson-booking-summary__item  privatelesson-booking-summary__item--booking-size">
                                <div class="privatelesson-booking-summary__item__label">{{'bookingSize' | translate}}
                                </div>
                                <div class="openbooking-summary__item__value">
                                    {{partyService.privateLessonBookingData?.BookingSize}}</div>
                            </div>
                            <div class="privatelesson-booking-summary__item  privatelesson-booking-summary__item--slots"
                                *ngIf="ratePlan">
                                <div class="privatelesson-booking-summary__item__label">{{'timeSlots' | translate}}
                                </div>
                                <div class="privatelesson-booking-summary__item__value"
                                    *ngIf="ratePlan.RatePlanCalculations && ratePlan.TotalRatePlan"><span
                                        *ngFor="let slot of ratePlan.SlotList | slice:0:(showAllSlots ? partyService.privateLessonBookingData.Slots.length : 4 ); let index = index;">{{slot.TimeRange.Start
                                        | localizedDate:'LT'}}
                                        - {{slot.TimeRange.End | localizedDate: 'LT'}}{{partyService.privateLessonBookingData.Slots[index+1] && ','}}
                                        &nbsp; </span></div>
                                <div class="privatelesson-booking-summary__item__value" *ngIf="!ratePlan.TotalRatePlan">
                                    <span
                                        *ngFor="let slot of ratePlan.SlotList | slice:0:(showAllSlots ? partyService.privateLessonBookingData.Slots.length : 4 ); let index = index;">{{slot.LocalTime
                                        | localizedDate:'LT'}}
                                        - {{slot.EndTime | localizedDate: 'LT'}}{{partyService.privateLessonBookingData.Slots[index+1] && ','}}
                                        &nbsp; </span>
                                </div>
                                <div class="privatelesson-booking-summary__slots-show-action"
                                    *ngIf="partyService.privateLessonBookingData.Slots.length > 4"
                                    (click)="showAllSlots = !showAllSlots">
                                    {{showAllSlots ? 'Show Less' :
                                    '+'+(partyService.privateLessonBookingData.Slots.length-4)+' '+'more'}}</div>

                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="privatelesson-booking-summary__summary-accordian" *ngIf="partyService.selectedGuest">
                <mat-accordion class="p-10">
                    <mat-expansion-panel [expanded]="true"
                        class="privatelesson-booking-summary__summary-accordian--panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title
                                class="privatelesson-booking-summary__section-title privatelesson-booking-summary__section-title--accordian">
                                {{'GuestDetails' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="privatelesson-booking-summary__content privatelesson-booking-summary__column-gap">
                            <div
                                class="privatelesson-booking-summary__item privatelesson-booking-summary__item--location">
                                <div class="privatelesson-booking-summary__item__label">{{'FirstName' | translate}}
                                </div>
                                <div class="privatelesson-booking-summary__item__value">
                                    {{partyService.selectedGuest?.FirstName}}
                                </div>
                            </div>
                            <div
                                class="privatelesson-booking-summary__item privatelesson-booking-summary__item--location">
                                <div class="privatelesson-booking-summary__item__label">{{'LastName' | translate}}</div>
                                <div class="privatelesson-booking-summary__item__value">
                                    {{partyService.selectedGuest?.LastName}}
                                </div>
                            </div>
                            <div class="privatelesson-booking-summary__item privatelesson-booking-summary__items--name">
                                <div class="privatelesson-booking-summary__item__label">{{'Email' | translate}}</div>
                                <div class="privatelesson-booking-summary__item__value">
                                    {{partyService.selectedGuest?.EmailAddress}}
                                </div>
                            </div>
                            <div class="privatelesson-booking-summary__item privatelesson-booking-summary__item--name">
                                <div class="privatelesson-booking-summary__item__label">{{'Mobile' | translate}}</div>
                                <div class="privatelesson-booking-summary__item__value">
                                    {{partyService.selectedGuest?.PhoneNumber}}
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="privatelesson-booking-summary__rate-plan"
                *ngIf="ratePlan && ratePlan.TotalRatePlan && ratePlan.RatePlanCalculations">
                <div class="privatelesson-booking-summary__section-title">
                    {{'Rates' | translate}}
                </div>
                <mat-accordion *ngFor="let slot of ratePlan.SlotList; let index= index;">
                    <mat-expansion-panel [expanded]="index == 0"
                        class="privatelesson-booking-summary__summary-accordian--panel privatelesson-booking-summary__summary-accordian--rate-plan">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="privatelesson-booking-summary__section-title--accordian">
                                {{slot.TimeRange.Start | localizedDate:'LT'}} - {{slot.TimeRange.End | localizedDate:'LT'}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="privatelesson-booking-summary__rate-row"
                            *ngIf="slot.RatePlanVariants.TotalBaseRate">
                            <div class="privatelesson-booking-summary__rate-label">
                                <span class="privatelesson-booking-summary__rate-label--main">{{'TotalBaseRate' |
                                    translate}}</span>
                            </div>
                            <div class="privatelesson-booking-summary__rate">{{OperationCurrency}}
                                {{slot.RatePlanVariants.TotalBaseRate | currencyDecimalFormatter}}</div>
                        </div>
                        <div class="privatelesson-booking-summary__rate-row"
                            *ngIf="slot.RatePlanVariants.LocationVariant">
                            <div class="privatelesson-booking-summary__rate-label">
                                <span class="privatelesson-booking-summary__rate-label">{{'LocationVariant' |
                                    translate}} <span class="sub-text">({{'forTotalOf' | translate}}
                                        {{partyService.privateLessonBookingData?.BookingSize}}
                                        )</span>
                                </span>
                            </div>
                            <div class="privatelesson-booking-summary__rate">{{OperationCurrency}}
                                {{slot.RatePlanVariants.LocationVariant | currencyDecimalFormatter}}</div>
                        </div>
                        <div class="privatelesson-booking-summary__rate-row"
                            *ngIf="slot.RatePlanVariants.InstructorVariant">
                            <div class="privatelesson-booking-summary__rate-label">
                                <span class="privatelesson-booking-summary__rate-label">{{'InstructorVariant' |
                                    translate}} <span class="sub-text">({{'forTotalOf' | translate}}
                                        {{partyService.privateLessonBookingData?.BookingSize}}
                                        )</span>
                                </span>
                            </div>
                            <div class="privatelesson-booking-summary__rate">{{OperationCurrency}}
                                {{slot.RatePlanVariants.InstructorVariant | currencyDecimalFormatter}}</div>
                        </div>
                        <div class="privatelesson-booking-summary__rate-row"
                            *ngIf="slot.bookingTypeVariants && slot.bookingTypeVariants.length">
                            <div class="privatelesson-booking-summary__rate-label">
                                <span class="privatelesson-booking-summary__rate-label sub-label">{{'bookingType' |
                                    translate}} </span>
                            </div>
                        </div>

                        <div class="privatelesson-booking-summary__rate-row"
                            *ngFor="let variant of slot.bookingTypeVariants">
                            <div class="privatelesson-booking-summary__rate-label" *ngIf="variant.Variant">
                                <span
                                    class="privatelesson-booking-summary__rate-label--main">{{variant.BookingTypeName}}</span>
                                <span
                                    class="privatelesson-booking-summary__rate-label--sub">{{variant.bookingTypes}}</span>
                            </div>
                            <div class="privatelesson-booking-summary__rate">{{OperationCurrency}} {{variant.Variant |
                                currencyDecimalFormatter}}</div>
                        </div>
                        <div class="privatelesson-booking-summary__rate-row"
                            *ngIf="slot.coverTypeVariants && slot.coverTypeVariants.length">
                            <div class="privatelesson-booking-summary__rate-label">
                                <span class="privatelesson-booking-summary__rate-label sub-label">{{'coverType' |
                                    translate}} </span>
                            </div>
                        </div>
                        <div class="privatelesson-booking-summary__rate-row"
                            *ngFor="let variant of slot.coverTypeVariants">
                            <div class="privatelesson-booking-summary__rate-label" *ngIf="variant.Variant">
                                <span
                                    class="privatelesson-booking-summary__rate-label--main">{{variant.CoverTypeName}}</span>
                                <span class="privatelesson-booking-summary__rate-label--sub">{{variant.covers}}</span>
                            </div>
                            <div class="privatelesson-booking-summary__rate">{{OperationCurrency}} {{variant.Variant |
                                currencyDecimalFormatter}}</div>
                        </div>

                        <div class="privatelesson-booking-summary__rate-row" *ngIf="slot.MemberShipVariant">
                            <div class="privatelesson-booking-summary__rate-label">
                                <span class="privatelesson-booking-summary__rate-label--main">{{'MemberShipVariant' |
                                    translate}}</span>
                            </div>
                            <div class="privatelesson-booking-summary__rate">{{OperationCurrency}}
                                {{slot.MemberShipVariant | currencyDecimalFormatter}}</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div class="privatelesson-booking-summary__rate-row privatelesson-booking-summary__total">
                    <div class="privatelesson-booking-summary__rate-label">
                        <span class="privatelesson-booking-summary__rate-label">{{'total' | translate}}</span>
                    </div>
                    <div class="privatelesson-booking-summary__rate">{{OperationCurrency}} {{(ratePlan.TotalRatePlan || ratePlan.TotalAddonAmount ?
                        ((ratePlan.TotalRatePlan || 0) + (ratePlan.TotalAddonAmount || 0) | currencyDecimalFormatter) : '0.00') }}
                    </div>
                </div>
                <div class="privatelesson-booking-summary__rate-row privatelesson-booking-summary__total negotiation-label m-0 pt-0" *ngIf="isServiceConfigured">
                    <div class="privatelesson-booking-summary__rate-label">
                        <span class="privatelesson-booking-summary__rate-label">{{'serviceChargesWithPlural' | translate}}</span>
                    </div>
                    <div class="privatelesson-booking-summary__rate">{{OperationCurrency}} {{(ratePlan.TotalServiceChargeAmount ?
                        ((ratePlan.TotalServiceChargeAmount || 0) + (ratePlan.TotalAddonServiceChargeAmount || 0) | currencyDecimalFormatter) : '0.00') }}
                    </div>
                </div>
                <div class="privatelesson-booking-summary__rate-row privatelesson-booking-summary__total negotiation-label m-0 pt-0" *ngIf="isServiceConfigured">
                    <div class="privatelesson-booking-summary__rate-label">
                        <span class="privatelesson-booking-summary__rate-label">{{'serviceChargesWithPlural' | translate}}</span>
                    </div>
                    <div class="privatelesson-booking-summary__rate">{{OperationCurrency}} {{(ratePlan.TotalServiceChargeAmount ?
                        ((ratePlan.TotalTaxOnServiceChargeAmount || 0) + (ratePlan.TotalAddonTaxOnServiceChargeAmount || 0) | currencyDecimalFormatter) : '0.00') }}
                    </div>
                </div>
                <div class="privatelesson-booking-summary__rate-row privatelesson-booking-summary__total negotiation-label m-0 pt-0" *ngIf="isTaxConfigured">
                    <div class="privatelesson-booking-summary__rate-label">
                        <span class="privatelesson-booking-summary__rate-label">{{'tax' | translate}}</span>
                    </div>
                    <div class="privatelesson-booking-summary__rate">{{OperationCurrency}} {{(ratePlan.TotalTaxAmount || ratePlan.TotalAddonTaxAmount ?
                        ((ratePlan.TotalTaxAmount || 0) + (ratePlan.TotalAddonTaxAmount || 0) | currencyDecimalFormatter) : '0.00') }}
                    </div>
                </div>
                <div class="row negotiation-label">
                    <div class="col-lg-6 col-md-6">
                        <span class="privatelesson-booking-summary__rate-label">{{'negotiateValue' | translate}}</span>
                    </div>
                    <div class="col-lg-6 col-md-5  text-right" *ngIf="partyService.privateLessonBookingDataSaveObj">
                        {{(!partyService.privateLessonBookingDataSaveObj.NegotiatedAmount
                        || partyService.privateLessonBookingDataSaveObj.NegotiatedAmount >= 0 ? '+ ' : (partyService.privateLessonBookingDataSaveObj.NegotiatedAmount == 0 ? '' :'-'))}} {{OperationCurrency}}
                        {{(partyService.privateLessonBookingDataSaveObj.NegotiatedAmount
                        ? (partyService.privateLessonBookingDataSaveObj.NegotiatedAmount | negtiationRateFormatter) :
                        '0.00') }}
                    </div>
                </div>
                <div class="row negotiation-label">
                    <div class="col-lg-6 col-md-6">
                        <span class="privatelesson-booking-summary__rate-label"> {{'findTotal' | translate}}</span>
                    </div>
                    <div class="col-lg-6 col-md-5  text-right" *ngIf="partyService.privateLessonBookingDataSaveObj">
                        <span class="icon-information"
                            *ngIf="partyService.privateLessonBookingDataSaveObj.Comments && partyService.privateLessonBookingDataSaveObj.Comments !== ''"
                            matTooltipClass="tooltip-class"
                            title="{{partyService.privateLessonBookingDataSaveObj.Comments}}"></span>
                        {{OperationCurrency}} {{(partyService.privateLessonBookingDataSaveObj.TotalPayable ?
                        ((partyService.privateLessonBookingDataSaveObj.TotalPayable +  (ratePlan.TotalTaxAmount || 0) + (ratePlan.TotalAddonTaxAmount || 0)) + ((ratePlan.TotalServiceChargeAmount || 0) + (ratePlan.TotalAddonServiceChargeAmount || 0))| currencyDecimalFormatter) :
                        '0.00') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="privatelesson-booking-summary__guest-details" *ngIf="partyService.privateLessonBookingDataSaveObj">
            <app-iframe-container [iframe]="partyService.editIframeUrl"
                [SaveBookingObj]="partyService.privateLessonBookingDataSaveObj"
                (ReservationAttemptId)="setReservationData($event)" [componentType]="fromComponent">
            </app-iframe-container>
        </div>
    </div>
</ng-scrollbar>