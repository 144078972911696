import { Component, Host, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableActions } from 'src/app/common/enums/shared-enums';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { BaseResponse, MemberBucketLinking, MemberBucketTypes, PaymentMethod, RevenuePostTypes, SubPropertyModel,MappingScreen } from '../../retail.modals';
import { GridType } from '../../shared/globalsContant';
import * as myGlobals from '../../shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { PaymentMethods } from '../../shared/business/shared.modals';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { PostTypeBusiness } from '../post-type-mapping/post-type-mapping.business';
import { C } from '@angular/cdk/keycodes';
import _ from 'lodash';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';

@Component({
  selector: 'app-member-accrual-mapping',
  templateUrl: './member-accrual-mapping.component.html',
  styleUrls: ['./member-accrual-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MemberAccrualMappingComponent implements OnInit {

  IsViewOnly = false;
  captions: any;
  commonCaptions: any;
  memberTypeValues = [];
  selectedPostTypeValue = RevenuePostTypes.PaymentMethod;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchText: string;
  tableoptions: any[];
  dataSource = [];
  outlets: any[];
  isValid = false;
  isDisabled = true;
  paymentMethod: PaymentMethod[] = [];
  categories: any;
  outletss: any;
  linkTypeDetail: any;
  updatedSelectedData: any;
  previousSelectedData: any;
  memberBucketMappingsData: MemberBucketLinking[] = [];
  OriginalMemberBucketMappingsData: MemberBucketLinking[];
  defaultOutlet: number;
  memberMappingsData: any;
  tableHeader: { title: any; jsonkey: string; sortable: boolean; sortcolumn?: string; sortcolumndatatype?: string; highlightbold?: boolean; type?: string; alignType?: string }[];
  searchPlaceholderValue: any;
  floatLabelNever: string;
  functionalities: { [key: string]: boolean } = {};
  constructor(private localization: RetailLocalization, private dialog: MatDialog,private _rs: RetailSetupService,
    private http: HttpServiceCall, private utils: RetailUtilities, public propertyInfo: RetailPropertyInformation, private postTypeBusiness: PostTypeBusiness
    , private func: RetailFunctionalityBusiness) {
    this.captions = this.localization.captions.retailsetup;
    this.commonCaptions = this.localization.captions.common;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }
  get IsFolioPaymentMethod() { return this.functionalities.IsFolioPaymentMethod; }
  async ngOnInit() {
    await this.func.getRetailFunctionality().then(res => {
			this.functionalities = res;
    });
    this.getData();
    this.searchPlaceholderValue = this.GetSearchPlaceHolderByPostType(RevenuePostTypes.PaymentMethod);
    this.memberTypeValues = [
      //   { id: 2, description: this.captions.Discount },
      //   { id: 6, description: this.captions.Gratuity },
      { id: 3, description: this.captions.PaymentMethod }
      // { id: 5, description: this.captions.ServiceCharge },
      // { id: 4, description: this.captions.taxes },
    ];
    this.selectedPostTypeValue = 3;
    this.IsViewOnly = this._rs.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.MemberAccrualMapping)?.view;
  }

  async getData() {
    let [outlets, type, memberMappings] = await Promise.all([this.postTypeBusiness.GetActiveOutlets(),
    this.postTypeBusiness.GetPrimaryTypeMapping(RevenuePostTypes.PaymentMethod,MappingScreen.MemberBucketMapping,this.IsFolioPaymentMethod), this.postTypeBusiness.GetMemberBucketMappingsDetails()]);
    this.memberMappingsData = memberMappings;
    this.mapToRevenueType();
    this.outlets = outlets;
    this.linkTypeDetail = type;
    this.OriginalMemberBucketMappingsData = this.memberMappingsData;
    this.memberMappingsData = this.formTableDataTemp(memberMappings);
    await this.BindToGrid();
  }

  getMemberBucketMappings() {
    this.InvokeServiceCall(
      "GetMemberBucketMappingsDetails", myGlobals.Host.retailManagement, HttpMethod.Get)
  }

  memberTypeChange(event) {
    this.searchPlaceholderValue = this.GetSearchPlaceHolderByPostType(event.value);

  }

  searchValue(event) {
    this.searchText = event.target.value;
  }

  resetValue() {
    this.searchText = '';
  }

  GetSearchPlaceHolderByPostType(value: number = this.selectedPostTypeValue) {
    switch (value) {
      case RevenuePostTypes.Discount:
      case RevenuePostTypes.ServiceCharge:
      case RevenuePostTypes.Gratuity:
        return this.captions.SearchByCategories;
      case RevenuePostTypes.PaymentMethod:
        return this.captions.SearchForMemberBucketMapping;
      case RevenuePostTypes.Tax:
        return this.captions.SearchByTax;
    }
  }


  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetMemberBucketMappingsDetails") {
      this.memberMappingsData = result.result || [];
      this.mapToRevenueType();
    }

  }
  errorCallback<T>(): void {

  }

  mapToRevenueType() {
    this.memberMappingsData.forEach(element => {
      switch (element.linkType) {
        case MemberBucketTypes.PaymentMethod:
          element.linkType = 3;break;
        case MemberBucketTypes.Card:
          element.linkType = 7;break;
      }
    });
    console.log(this.memberMappingsData);
  }

  formTableData() {
    this.tableHeader = [{ title: this.GetTextByPostType(), jsonkey: 'typecategory', sortable: true, highlightbold: true }];
    this.dataSource = [];

    this.outlets.forEach(res => {
      this.tableHeader.push({
        title: res.subPropertyName,
        jsonkey: res.subPropertyName,
        sortcolumn: res.subPropertyName + 'number',
        sortcolumndatatype: 'number',
        type: 'showOnHover',
        sortable: true,
        alignType: 'right'
      });
      return res;
    });

    this.linkTypeDetail.forEach(res => {
      let resultData: any = {};
      if (Array.isArray(this.memberMappingsData)) {
        this.memberMappingsData.forEach(data => {
          if (res.id === data.linkingId && res.linkType == data.linkType) {
            this.outlets.forEach(o => {
              if (this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod && o.subPropertyID === data.outletId && (data.linkType === this.selectedPostTypeValue || data.linkType === RevenuePostTypes.Card)) {
                resultData.typecategory = res.name;
                resultData[o.subPropertyName] = data.bucketCode;
                resultData[o.subPropertyName + 'Bucket Name'] = data.bucketName && data.bucketName !== '' ? data.bucketName : '';
                resultData.id = data.id ? data.id : 0;
                resultData.category = res.name ? res.name : '';
                resultData.categoryId = data.linkingId ? data.linkingId : 0;
                resultData.outletId = data.outletId ? data.outletId : 0;
                resultData.linkType = data.linkType ? data.linkType : 0;
              }
              else if (o.subPropertyID === data.outletId && data.linkType === this.selectedPostTypeValue) {
                resultData.typecategory = res.name;
                if (!this.propertyInfo.IsVATEnabled) {
                  resultData[o.subPropertyName] = this.selectedPostTypeValue === RevenuePostTypes.Tax && res.outletId != o.subPropertyID ? "" : data.bucketCode;
                  resultData.linkType = data.linkType ? data.linkType : 0;
                }
                else {
                  resultData[o.subPropertyName] = data.bucketCode;
                  resultData.linkType = data.linkType ? data.linkType : 0;
                }
                resultData[o.subPropertyName + 'Bucket Name'] = data.bucketName && data.bucketName !== '—' ? Number(data.bucketName) : 0;
                resultData.id = data.id ? data.id : 0;
                resultData.category = res.name ? res.name : '';
                resultData.categoryId = data.linkingId ? data.linkingId : 0;
                resultData.outletId = data.outletId ? data.outletId : 0;
                resultData.linkType = data.linkType ? data.linkType : 0;
              }
            });
          }
        });
      }
      if (Object.keys(resultData).length) this.dataSource.push(resultData);
      resultData = {};
    });
    return [this.tableHeader, this.dataSource];
  }

  formTableDataTemp(memberMappingsData: MemberBucketLinking[] = []) {
    const OriginalTableData: MemberBucketLinking[] = _.cloneDeep(memberMappingsData);
    this.linkTypeDetail.forEach(cat => {
      this.outlets.forEach(out => {
        if (Array.isArray(memberMappingsData)) {
          let isPostTypeAvailable;
          if (this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod) {
            isPostTypeAvailable = memberMappingsData.find(o => o.outletId === out.subPropertyID && o.linkingId === cat.id && (o.linkType == cat.linkType));
          } else {
            isPostTypeAvailable = memberMappingsData.find(o => o.outletId === out.subPropertyID && o.linkingId === cat.id && o.linkType === this.selectedPostTypeValue);
          }

          if (!isPostTypeAvailable) {
            OriginalTableData.push({
              id: 0,
              outletId: out.subPropertyID,
              bucketCode: '—',
              bucketName: '',
              linkingId: cat.id,
              linkType: cat.linkType,
            });
          }
        }
      });
    });
    return OriginalTableData;
  }

  GetTextByPostType(value: number = this.selectedPostTypeValue) {
    switch (value) {
      case RevenuePostTypes.Settlement:
      case RevenuePostTypes.Discount:
      case RevenuePostTypes.ServiceCharge:
      case RevenuePostTypes.Gratuity:
        return this.captions.Category;
      case RevenuePostTypes.PaymentMethod:
        return this.captions.PaymentMethod;
      case RevenuePostTypes.Tax:
        return this.captions.Tax;
    }
  }

  BindToGrid() {

    const data = this.formTableData();
    this.tableoptions = [
      {
        TableHdrData: data[0],
        TablebodyData: data[1],
        pagination: false,
        sortable: true,
        CustomColumn: false,
        PlaceHoldertext: this.captions.Search,
        EnableActions: false,
        SelectRows: false,
        IsCommission: false,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: GridType.memberaccrualmapping,
        Sortable: 'typecategory',
        TableId: GridType.memberaccrualmapping,
        disableDelete: false,
        customHeader: false,
        pageTitle: 'memberaccrualmapping',
        ServiceId: 'memberaccrualmapping',
        IsViewOnly: this.IsViewOnly,
        IsReadOnly: this.IsViewOnly,
        TableDraggable: false,
        bufferCount: 20

      }
    ];
  }

  rowDataClicked(event) {
    console.log(event)
    this.openDialog(this.captions.memberbucketdetails, "MAP", event)
    const outlet = this.outlets.filter(o => o.subPropertyName === event[1]);
    const oldData = this.memberMappingsData.filter(res => res.bucketCode === event[0][event[1]] && res.linkingId === event[0].categoryId && res.outletId === outlet[0].subPropertyID && res.linkType ==event[0].linkType);
    this.previousSelectedData = oldData && oldData.length ? oldData : [event[0]];
  }

  mapApiLinkType(selectedPostType): string {
    switch (selectedPostType.linkType) {
      case 3:
        return MemberBucketTypes.PaymentMethod;
      case 7:
        return MemberBucketTypes.Card;
        default:
          this.utils.showError("Error Mapping Link Type");
          break;
    }

  }
  async savememberMappings(event) {
    let updatedPostTypes = this.memberMappingsData.filter(s => s.isUpdated && s.bucketCode !== '—');

    const selectedPostTypes = updatedPostTypes;
    selectedPostTypes.forEach(uPost => {
      uPost.linkType = this.mapApiLinkType(uPost);
      if(!uPost.linkType)
      {
      return;
      }
    })

    let deletePostTypes = this.memberMappingsData.filter(s => s.isUpdated && s.id && s.bucketCode === '—').map(t => t.id);

    if (deletePostTypes && deletePostTypes.length) {
      await this.postTypeBusiness.DeleteMemberBucketMapping(deletePostTypes);
    }
    if (selectedPostTypes && selectedPostTypes.length) {
      await this.postTypeBusiness.CreateMemberBucketMapping(selectedPostTypes);
    }
    let memberMappings = await this.postTypeBusiness.GetMemberBucketMappingsDetails();
    this.memberMappingsData = memberMappings;
    this.mapToRevenueType();
    this.OriginalMemberBucketMappingsData = this.memberMappingsData;
    this.memberMappingsData = this.formTableDataTemp(memberMappings);
    this.BindToGrid();
    this.isValid = false;
  }

  cancelMemberMappings() {
    let data = this.OriginalMemberBucketMappingsData;
    this.memberMappingsData = this.formTableDataTemp(data);
    this.mapToRevenueType();
    this.BindToGrid();
    this.isValid = false;
    this.isDisabled = false
  }


  openDialog(title, templateName, data) {
    const dialogRef = this.dialog.open(RetailPopupComponent, {
      width: '450px',
      height: '450px',
      disableClose: true,
      data: { headername: title, closebool: true, templatename: templateName, datarecord: data, LinkType: this.selectedPostTypeValue },
      hasBackdrop: true,
      panelClass: ''
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      console.log(this.tableoptions, " ", result, " ", data);

      if (result) {
        this.updatedSelectedData = result;
        const filteredData = [];
        let postTypeIndex = null;
        if (!result.value.applyForOutletsForTheSamePaymentMethod && !result.value.applyForAllPaymentMethodsForTheSameOutlet) {
          if (this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod) {
            if(this.previousSelectedData[0] && this.previousSelectedData[0].linkType == RevenuePostTypes.PaymentMethod)
            {
              postTypeIndex = this.memberMappingsData.findIndex(m => (m.linkType == RevenuePostTypes.PaymentMethod) && m.linkingId === this.previousSelectedData[0].linkingId && m.outletId === this.previousSelectedData[0].outletId);
            }
            if(this.previousSelectedData[0] && this.previousSelectedData[0].linkType == RevenuePostTypes.Card)
            {
              postTypeIndex = this.memberMappingsData.findIndex(m => (m.linkType == RevenuePostTypes.Card) && m.linkingId === this.previousSelectedData[0].linkingId && m.outletId === this.previousSelectedData[0].outletId);
            }
          }
          else {
            postTypeIndex = this.memberMappingsData.findIndex(m => m.linkType === this.selectedPostTypeValue && m.linkingId === this.previousSelectedData[0].linkingId && m.outletId === this.previousSelectedData[0].outletId);
          }
          if (postTypeIndex !== -1) {
            this.memberMappingsData[postTypeIndex].bucketCode = result.value.bucketcode ? result.value.bucketcode : '—';
            this.memberMappingsData[postTypeIndex].bucketName = result.value.bucketname ? result.value.bucketname : '';
            this.memberMappingsData[postTypeIndex].isUpdated = true;
            this.isDisabled = false;
            this.isValid = true;
          }
        }
        else {
          if (this.selectedPostTypeValue == RevenuePostTypes.PaymentMethod) {
            for (let i = 0; i < this.memberMappingsData.length; i++) {
              if((this.memberMappingsData[i].linkType == RevenuePostTypes.PaymentMethod || this.memberMappingsData[i].linkType == RevenuePostTypes.Card) && result.value.applyForOutletsForTheSamePaymentMethod && result.value.applyForAllPaymentMethodsForTheSameOutlet)
              {
                this.memberMappingsData[i].bucketCode = result.value.bucketcode ? result.value.bucketcode : '—';
                this.memberMappingsData[i].bucketName = result.value.bucketname ? result.value.bucketname : '';
                this.memberMappingsData[i].isUpdated = true;
              }
              else if ((this.memberMappingsData[i].linkType == RevenuePostTypes.PaymentMethod || this.memberMappingsData[i].linkType == RevenuePostTypes.Card) && !result.value.applyForOutletsForTheSamePaymentMethod && result.value.applyForAllPaymentMethodsForTheSameOutlet && this.memberMappingsData[i].outletId == this.previousSelectedData[0].outletId) {
                this.memberMappingsData[i].bucketCode = result.value.bucketcode ? result.value.bucketcode : '—';
                this.memberMappingsData[i].bucketName = result.value.bucketname ? result.value.bucketname : '';
                this.memberMappingsData[i].isUpdated = true;
              }
              else if ((this.memberMappingsData[i].linkType == RevenuePostTypes.PaymentMethod || this.memberMappingsData[i].linkType == RevenuePostTypes.Card) && result.value.applyForOutletsForTheSamePaymentMethod && !result.value.applyForAllPaymentMethodsForTheSameOutlet &&  this.memberMappingsData[i].linkingId === this.previousSelectedData[0].linkingId) {
                this.memberMappingsData[i].bucketCode = result.value.bucketcode ? result.value.bucketcode : '—';
                this.memberMappingsData[i].bucketName = result.value.bucketname ? result.value.bucketname : '';
                this.memberMappingsData[i].isUpdated = true;
              }
            }
          }
          else {
            for (let i = 0; i < this.memberMappingsData.length; i++) {
              if (this.memberMappingsData[i].linkType == this.selectedPostTypeValue && this.memberMappingsData[i].outletId == this.memberMappingsData[0].outletId) {
                this.memberMappingsData[i].bucketCode = result.value.bucketcode ? result.value.bucketcode : '—';
                this.memberMappingsData[i].bucketName = result.value.bucketname ? result.value.bucketname : '';
                this.memberMappingsData[i].isUpdated = true;
              }
            }
          }
          this.isDisabled = false;
          this.isValid = true;
        }
        console.log('PostTypeMappingComponent -> openDialog -> this.updatedPostTypeMappings', this.memberMappingsData);
        this.BindToGrid();
      }

    })
  }

}
