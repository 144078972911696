<ng-container>
    <app-dialog-header [title]="headerdata" (closeDialog)="close($event)"></app-dialog-header>
</ng-container>
<div class="create-payment-method-wrapper">
    <div class="ag_modal--form-height">
        <!-- <mat-horizontal-stepper linear #stepper>
            <mat-step>
                <ng-template matStepLabel>{{localization.captions.lbl_basic}}</ng-template> -->
        <app-warning-bar [message]="showClassNameAlert" *ngIf="isCopy" (closeWarning)="hideWarning()"
            [showClose]="true">
        </app-warning-bar>
        <div class="ag_container--padding-md  ag_form_wrapper--md">
            <form [formGroup]="createPaymentMethodForm" autocomplete="off" DetectFormValueChange
                [changeInputValues]="changeInputValues" (formStatusChange)="formStatusChangeEvent($event)">
                <div>
                    <ag-textbox [config]="paymentMethodCode"></ag-textbox>
                    <ag-textbox [config]="paymentMethodName"></ag-textbox>
                </div>
                <div>
                    <app-ag-dropdown id="ag-automation-select-paymentmethods-creditCardType" [inputs]="creditCardType"
                        (selectChange)="creditCardTypeChange($event)"></app-ag-dropdown>
                    <ag-textbox [config]="centralReservationEquivalent"></ag-textbox>
                </div>
                <div>
                    <app-ag-incremental [inputs]="listOrderInput" class="ag_w--50"></app-ag-incremental>
                    <app-ag-currency [inputs]="creditCard"></app-ag-currency>
                </div>
                <div class="ag_display--flex">
                    <div class="left-section ag_w--50">
                        <div class="ag_form-control">
                            <label class="ag_form--label"
                                [attr.LiteralID]="'tbl_hdr_postType'">{{captions.tbl_hdr_postType}}<sup *ngIf="fromFolio" class="span-error">*</sup></label>
                            <app-chip-search class="ag_w--50" [searchMaxCharLength]="searchPostTypeLength"
                                [allData]="masterPostTypes" [selectedData]="selectedData" [searchKey]="searchKey"
                                [autoCompleteKeys]="autoCompleteKeys" [selectedChipKey]="selectedChipKey"
                                [isFocusSearchEmitRequired]="isFocusSearchEmitRequired"
                                (selectedChipDataEmit)="selectedChipDataEmit($event)"
                                (searchTextEmit)="SearchTextHandler($event)" (onChipRemove)="onChipRemoveEvent($event)"
                                [disabled]="isChipDisabled" [placeholderText]="captions.lbl_searchByPostType">
                            </app-chip-search>
                        </div>
                        <div>
                            <mat-checkbox [attr.automationId]="'Chk_createPaymentMethod_isActive'" class="" formControlName="isActive"
                                [attr.LiteralID]="'lbl_tog_active'">
                                {{captions.lbl_tog_active}}
                            </mat-checkbox>
                        </div>
                        <div *ngIf="!fromFolio">
                            <mat-checkbox [attr.automationId]="'Chk_createPaymentMethod_isMovieRestrictions'" class="ag_mt--4" formControlName="isMovieRestrictions"
                                [attr.LiteralID]="'lbl_tog_allowAllMovies'">
                                {{captions.lbl_tog_allowAllMovies}}
                            </mat-checkbox>
                        </div>
                        <div *ngIf="!fromFolio">
                            <mat-checkbox [attr.automationId]="'Chk_createPaymentMethod_isActivateInterface'" class="ag_mt--4" formControlName="isActivateInterface"
                                [attr.LiteralID]="'lbl_tog_activeInterface'">
                                {{captions.lbl_tog_activeInterface}}
                            </mat-checkbox>
                        </div>
                        <div *ngIf="!fromFolio">
                            <mat-checkbox [attr.automationId]="'Chk_createPaymentMethod_paymentMethods'" class='ag_display--block' [matTooltip]='paymentMethods.value'
                                *ngFor='let paymentMethods of paymentMethodsCheckbox'
                                [formControlName]='paymentMethods.id'>
                                {{paymentMethods.value}}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="right-section ag_w--50" *ngIf="!fromFolio">
                        <label class="ag_form--label"
                            [attr.LiteralID]="'lbl_phoneRestriction'">{{captions.lbl_phoneRestriction}}</label>
                        <div class="radio-part">
                            <mat-radio-group [attr.automationId]="'Chk_createPaymentMethod_phoneRestrictions'" formControlName='phoneRestrictions'>
                                <mat-radio-button class='ag_form--radio-button' *ngFor='let rdo of phoneRestrictions'
                                    [value]='rdo.id'>
                                    {{rdo.value}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <input type="submit" [disabled]="actionButton.disabledproperty" class="hidden-submit" tabindex="-1"
                    (click)="onAction($event)" />
            </form>
        </div>
            <!-- </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{localization.captions.lbl_additional}}</ng-template> -->
                <!-- <form [formGroup]="additionalInfoForm" class="ag_container--padding-md additional-info-wrapper">
                    <div formArrayName="additionalInfoFormArray">
                        <div *ngFor="let row of additionalInfoForm.get('additionalInfoFormArray')['controls']; let i=index; let first=first"
                            [formGroupName]="i">
                            <div class="ag_display--flex">
                                <ag-input class="w-45" formControlName="Key" [attr.automationId]="'Txt_createCashEqivalentPayment_key'"
                                    [placeholder]="localization.captions.setting.Key" [maxlength]="200"></ag-input>
                                <ag-input class="ag_pl--8 w-45" formControlName="Value" [attr.automationId]="'Txt_createCashEqivalentPayment_value'"
                                    [placeholder]="localization.captions.lbl_value" [maxlength]="200"></ag-input>
                                <span class="icon-Plus ag_pt--8 ag_ml--6 ag_cursor--pointer" (click)="addArray(i)"
                                    *ngIf="first"></span>
                                <span class="icon-Minus ag_pt--8 ag_ml--6 ag_cursor--pointer" (click)="removeArray(i)"
                                    *ngIf="!first"></span>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper> -->
    </div>
    <div class="create-paymentMethod-footer ag_footer--actions">
        <app-button [attr.automationId]="'Btn_createPaymentMethod_action'" [buttontype]="actionButton" (valueChange)='onAction($event)'></app-button>
        <app-button [attr.automationId]="'Btn_createPaymentMethod_cancel'" [buttontype]="cancelButton" class="ag_ml--2" (valueChange)='onCancel($event)'></app-button>
    </div>
</div>