import { Injectable } from '@angular/core';
import { RetailCommunication } from 'src/app/common/communication/services/retailcommunication.service';
import { RetailRoutes } from 'src/app/retail/retail-route'; 
import { AutoRetailTransactionRequestModel, AutoRetailTransactionResponse } from '../../shared.modal';

@Injectable({
    providedIn: 'root'
})
export class AutoRetailTransactionDataService {

    constructor(private _v1RetailCommunication: RetailCommunication) {
    }

    public async InitiateAutoRetailTransaction(model: AutoRetailTransactionRequestModel[]): Promise<AutoRetailTransactionResponse[]> {
        return await this._v1RetailCommunication.postPromise<AutoRetailTransactionResponse[]>(
            { route: RetailRoutes.AutoRetailTransaction, body: model }, false);
    }

   

}