import { Injectable } from "@angular/core";
import { SorTypeEnum, HdrActionTypeEnum, ActionTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { HttpMethod } from "src/app/common/Models/http.model";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { RetailRoutes } from "src/app/retail/retail-route";
import { BaseResponse, OrphanPayments } from "src/app/retail/retail.modals";
import { Host } from "src/app/retail/shared/globalsContant";
import { HttpServiceCall } from "src/app/retail/shared/service/http-call.service";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { TransactionDataService } from "src/app/retail/sytem-config/data-service/transactions-log.service";
import { TransactionsLogSearchRequest } from "../retail-transactions-log/retail-transactions-log";

@Injectable()
export class OrphanPaymentsBusiness {
    captions: any;
    currencySymbol: string;

    constructor(private localization: RetailLocalization, private utils: RetailUtilities, private dataService: TransactionDataService,
        private http: HttpServiceCall) {
        this.captions = this.localization.captions.shop;
        this.currencySymbol = this.localization.currencySymbol;
    }

    public getTableOptions() {
        return {
            actions: [
            ],
            defaultsortingColoumnKey: 'ticketNumber',
            showTotalRecords: false,
            defaultSortOrder: SorTypeEnum.desc,
            columnFreeze: {
                firstColumn: false,
                lastColumn: true
            },
            isDragDisabled: false,
            isHeaderCheckboxAllowed: true,
            checkboxKey: 'checked',
            ignoreSort: true
        };
    }

    public getHeaderOptions() {
        return [
            {
                key: 'userName',
                displayNameId: '',
                displayName: this.captions.tbl_hdr_user,
                sortingKey: 'userName',
                sorting: true
            },
            {
                key: 'createdDTM',
                displayNameId: '',
                displayName: this.captions.tbl_hdr_transactionDate,
                sortingKey: 'createdDTM',
                sorting: true
            },
            {
                key: 'transactionType',
                displayNameId: '',
                displayName: this.captions.lbl_transactionType,
                sortingKey: 'transactionType',
                sorting: true
            },
            {
                key: 'origin',
                displayNameId: '',
                displayName: this.captions.lbl_origin,
                sortingKey: 'origin',
                sorting: true
            },          
            {
                key: 'ticketNumber',
                displayNameId: '',
                displayName: this.captions.tbl_hdr_ticketNumber,
                sortingKey: 'ticketNumber',
                sorting: true
            },
            {
                key: 'amountFormatted',
                displayNameId: '',
                displayName: this.captions.tbl_hdr_failedAmount + ' ' + '(' + this.currencySymbol + ')',
                sortingKey: 'amount',
                sorting: true
            },                        
            {
                key: 'paymentMethod',
                displayNameId: '',
                displayName: this.captions.tbl_hdr_payment_type,
                sortingKey: 'paymentMethod',
                sorting: true
            },            
            {
                key: 'reversedDTM',
                displayNameId: '',
                displayName: this.captions.tbl_hdr_reversed_dtm,
                sortingKey: 'reversedDTM',
                sorting: true
            },
            {
                key: 'status',
                displayNameId: '',
                displayName: this.captions.tbl_hdr_status,
                sortingKey: 'status',
                sorting: true
            }
        ];
    }

    async getTabledata(model: TransactionsLogSearchRequest): Promise<OrphanPayments[]> {
        let response:any = await this.http.CallApiAsync<OrphanPayments[]>({
            callDesc: RetailRoutes.GetOrphanPaymentRecords,
            host: Host.payment,
            method: HttpMethod.Put,
            body: model
        });
        return response.result;
    }

    public async getClerkDetail(clerkIds: number[]): Promise<any> {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "GetAllUsersbyUserIds",
            host: Host.authentication,
            method: HttpMethod.Get,
            uriParams: { tenantId: this.utils.GetPropertyInfo('TenantId') },
            withQueryString: "ids",
            queryString: { key: "ids", value: clerkIds }
        });

        if (!response.successStatus) {
            this.utils.showError("Invalid User");
        }
        return response.result;
    }
}