import { Injectable } from "@angular/core";
import { CustomAddTimetoDate, CustomDateConstants, CustomDateWithTimeConstants, CustomDateWithTimeConstantsTimeZone, CustomToApi } from "../constants";

@Injectable()
export class CommonPrototype {

}
declare global {
  export interface Date {
    DisplayDateFormat(format?: string, defaultDateFormat?: string): string;
    DisplayDateWithTimeformat(type?: string, timeFormat?: number): string;
    DisplayDateWithTimeformatBasedOnTimezone(timezone:string, type?: string, timeFormat?: number): string;
    AddTimeToDate(time: string, type?: string): Date;
    ToApiFormat(type?: string, timeFormat?: number): string;
    GetTime(format: number): string;
    GetDay(): number;
    GetMonth(): number;
    GetYear(): number;
    GetHours(): number;
    GetMinutes(): number;
    GetTotalMinutes(): number;
    GetTotalSeconds(): number;
  }
  export interface String {
    APIStringToDate(): Date;
    GetDateTimeFromString(): Date;
  }
}

/* only to display date format as label, dont bind or patch value in calendar field
   Pass the format type as parameter and it returns with date string*/

Date.prototype.DisplayDateFormat = function (format?: string) {
  let dateObject = new Date(this);
  let options: Intl.DateTimeFormatOptions;
  switch (format) {
    case CustomDateConstants.longDayshortMonth:
      options = { weekday: "long", year: "numeric", month: "short", day: "numeric" };
      break;
    case CustomDateConstants.shortDayShortMonth:
      options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
      break;
    case CustomDateConstants.longDayLongMonth:
      options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
      break;
    case CustomDateConstants.shortDayLongMonth:
      options = { weekday: "short", year: "numeric", month: "long", day: "numeric" };
      break;
    case CustomDateConstants.shortMonth:
      options = { year: "numeric", month: "short", day: "numeric" };
      break;
    case CustomDateConstants.dateWithShortMonth:
      options = { day: "numeric",  month: "short", year: "numeric"};
      break;
    case CustomDateConstants.longMonth:
      options = { year: "numeric", month: "long", day: "numeric" };
      break;
    default:
      options = {};
      break;
  }
  return dateObject.toLocaleString(sessionStorage.getItem("language"), options);
}

/* only to display date format with time as label, dont bind or patch value in calendar field
   Pass the format type and timeformat as parameter and it returns with date string*/

Date.prototype.DisplayDateWithTimeformat = function (format?: string, timeFormat?: number): string {
  let dateObject = new Date(this);
  let options: Intl.DateTimeFormatOptions;
  let hourFormat = timeFormat == 12 ? true : false;
  switch (format) {
    case CustomDateWithTimeConstants.longDayshortMonthWithTime:
      options = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: '2-digit', second: '2-digit', minute: '2-digit', hour12: hourFormat };
      break;
    case CustomDateWithTimeConstants.shortDayShortMonthWithTime:
      options = { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: '2-digit', second: '2-digit', minute: '2-digit', hour12: hourFormat };
      break;
    case CustomDateWithTimeConstants.longDayLongMonthWithTime:
      options = { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: '2-digit', second: '2-digit', minute: '2-digit', hour12: hourFormat };
      break;
    case CustomDateWithTimeConstants.shortDayLongMonthWithTime:
      options = { weekday: "short", year: "numeric", month: "long", day: "numeric", hour: '2-digit', second: '2-digit', minute: '2-digit', hour12: hourFormat };
      break;
    case CustomDateWithTimeConstants.shortMonthWithTime:
      options = { year: "numeric", month: "short", day: "numeric", hour: '2-digit', second: '2-digit', minute: '2-digit', hour12: hourFormat };
      break;
    case CustomDateWithTimeConstants.longMonthWithTime:
      options = { year: "numeric", month: "long", day: "numeric", hour: '2-digit', second: '2-digit', minute: '2-digit', hour12: hourFormat };
      break;
      case CustomToApi.DateWithHHMMSS:
        options = { year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute: '2-digit', hour12: hourFormat };
        break;
    default:
      options = { hour: '2-digit', second: '2-digit', minute: '2-digit', hour12: false };
      break;
  }
  return dateObject.toLocaleString(sessionStorage.getItem("language"), options);
}

/* only to display date format with time as label, dont bind or patch value in calendar field
   Pass the timezone, format type and timeformat as parameter and it returns with date string based on timezone you sent*/

Date.prototype.DisplayDateWithTimeformatBasedOnTimezone = function (timezone: string, format?: string, timeFormat?: number): string {
  let dateObject = new Date(this);
  let options: Intl.DateTimeFormatOptions;
  let hourFormat = timeFormat == 12 ? true : false;
  let getLanguage = sessionStorage.getItem("language");
  if(getLanguage == "da-DK"){
    getLanguage = "de-DE"
  }
  switch (format) {
    case CustomDateWithTimeConstantsTimeZone.shortMonthWithTime:
      options = { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: '2-digit', second: '2-digit', minute: '2-digit', hour12: hourFormat, timeZone: timezone };
      break;
    case CustomToApi.DateWithHHMMSS:
      options = { year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit', hour12: hourFormat, timeZone: timezone };
      break;
    default:
      options = { hour: '2-digit', second: '2-digit', minute: '2-digit', hour12: false, timeZone: timezone };
      break;
  }
  return dateObject.toLocaleString(getLanguage, options);
}
/* Adds time to date and returns as date object
Pass the time value and format as parameter
* Here Format parameter refers to datewiththime or datewithouttime
*/

Date.prototype.AddTimeToDate = function (time: string, format?: string): Date {
  let dateObject = new Date(this);
  let customizedDateWithTime: any = '';
  const days = dateObject.getDate();
  const months = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  switch (format) {
    case CustomAddTimetoDate.DateWithTime:
      customizedDateWithTime = new Date(year + '/' + months + '/' + days + " " + time)
      break;
    case CustomAddTimetoDate.DateWithoutTime:
        customizedDateWithTime = new Date(year + '/' + months + '/' + days)
        break;
    default:
      customizedDateWithTime = new Date(year + '/' + months + '/' + days + " " + time)
      break;
  }
  return customizedDateWithTime;
}


/* Converts date to API date format
Pass the correct format as parameter and it returns with API format*/

Date.prototype.ToApiFormat = function (format?: string): string {
  let dateObject = new Date(this);
  let customizedAPIFormat = '';
  const days = dateObject.getDate();
  const months = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const hour = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();
  switch (format) {
    case CustomToApi.DateWithHHMMSS:
      customizedAPIFormat = year + '-' + months + '-' + days + 'T' + hour + ":" + minutes + ":" + seconds;
      break;
    case CustomToApi.DateWithHHMM:
      customizedAPIFormat = year + '-' + months + '-' + days + 'T' + hour + ":" + minutes;
      break;
    default:
      customizedAPIFormat = year + '-' + months + '-' + days;
      break;
  }
  return customizedAPIFormat;
}
/**
 * Example dateTimeString "2024-02-09 08:30:00" This will return date object
 */
String.prototype.GetDateTimeFromString = function (): Date {
  return getDateObject(this);
}

/**
 * 
 * @param format 12 or 24
 * @returns time as string based on input
 */
Date.prototype.GetTime = function (format: number): string {
  let dt = new Date(this);
  return getTimeFromDate(dt, format);
}

/**
 * 
 * @returns returns day starts from sunday - 0
 */

Date.prototype.GetDay = function (): number {
  let dt = new Date(this);
  return dt.getDay();
}

/**
 * 
 * @returns returns month starts from Jan - 0
 */
Date.prototype.GetMonth = function (): number {
  let dt = new Date(this);
  return dt.getMonth();
}

/**
 * 
 * @returns returns current year 2024
 */
Date.prototype.GetYear = function (): number {
  let dt = new Date(this);
  return dt.getFullYear();
}

/**
 * 
 * @returns returns current Hour of the day
 */
Date.prototype.GetHours = function (): number {
  let dt = new Date(this);
  return dt.getHours();
}
/**
 * 
 * @returns returns current minute of current hour
 */
Date.prototype.GetMinutes = function (): number {
  let dt = new Date(this);
  return dt.getMinutes();
}

/**
 * 
 * @returns returns Total Minutes for the day
 */
Date.prototype.GetTotalMinutes = function (): number {
  let dt = new Date(this);
  return dt.getHours() * 60 + dt.getMinutes();
}

/**
 * 
 * @returns returns Total Seconds for the day
 */
Date.prototype.GetTotalSeconds = function (): number {
  let dt = new Date(this);
  return dt.getHours() * 3600 + dt.getMinutes() * 60 + dt.getSeconds();
}

/* Converts date string to Date object
  Throws invalid date if the date string is not supported*/

String.prototype.APIStringToDate = function (): Date {
  let dateObject = new Date(this);
  return dateObject;
}

// Supporting Functions 

function getDateObject(input: any) {
  if ((typeof input) === 'string') {
    // Including T in the ISO format if not present
    if (input.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/) || input.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
      input = input.replace(' ', 'T');
    }
    let dateString: string = input;

    if (input.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/) || input.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/)) {
      const splitDateArray = input.split('-');  // Contains the yyyy, MM, ddTHH:mm
      const splitTimeArray = splitDateArray[2].split('T'); // contains the dd, HH:mm:ss
      const splitHourArray = splitTimeArray[1].split(':'); // contains HH, mm
      // Appending timezone to support Safari browser
      dateString = `${input}${getTimezoneOffSet(Number(splitDateArray[0]), Number(splitDateArray[1]) - 1, Number(splitTimeArray[0]), Number(splitHourArray[0]))}`;
    } else if (input.match(/^\d{4}-\d{2}-\d{2}$/)) {
      const splitDateArray = input.split('-');
      dateString = `${input}T00:00${getTimezoneOffSet(Number(splitDateArray[0]), Number(splitDateArray[1]) - 1, Number(splitDateArray[2]))}`;
    } else if (input.match(/^\d{4}-\d{2}-\d{2}T\d{1,2}:\d{1,2} [ap][m]$/i)) {
      const inputDate = input.split('T')[0];
      const inputTime = convertAMPMTimeStringTo24Time(input.split('T')[1]);
      const splitDateArray = input.split('-');  // Contains the yyyy, MM, ddTHH:mm
      const splitTimeArray = splitDateArray[2].split('T');  // contains the dd, HH:mm:ss
      const splitHourArray = inputTime.split(':'); // contains HH, mm
      dateString = `${inputDate}T${inputTime}${getTimezoneOffSet(Number(splitDateArray[0]), Number(splitDateArray[1]) - 1, Number(splitTimeArray[0]), Number(splitHourArray[0]))}`;
    } else if (input.match(/^(\d{2})\/(\d{2})\/(\d{4}) (\d{2})\.(\d{2})\.(\d{2})$/)) {
      dateString = dateString.replace(/(\d{2})\.(\d{2})\.(\d{2})/, '$1:$2:$3');
    }
    return new Date(dateString);
  }
  if (typeof input === 'number') {
    // Custom logic for number param goes here
  }
  return new Date(input);
}

function getTimezoneOffSet(year: number, monthIndex: number, day: number, hour = 0): string {
  const date = new Date(year, monthIndex, day, hour),
    timezoneOffset = date.getTimezoneOffset(),
    hours = ('00' + Math.floor(Math.abs(timezoneOffset / 60))).slice(-2),
    minutes = ('00' + Math.abs(timezoneOffset % 60)).slice(-2),
    string = (timezoneOffset >= 0 ? '-' : '+') + hours + ':' + minutes;
  return string;
}

function convertAMPMTimeStringTo24Time(input: string): string {
  const matches = input.toLowerCase().match(/(\d{1,2}):(\d{1,2}) ([ap]m)/);
  const hours = getHour(matches);
  return (hours.toString().length === 1 ? ('0' + hours.toString()) : hours.toString()) + ':' + matches[2] + ':00';
}

function getHour(matches): number {
  if (matches[3] == 'am' && parseInt(matches[1]) === 12) {
    return 0;
  } else if (matches[3] == 'am' && parseInt(matches[1]) < 12) {
    return parseInt(matches[1]);
  } else if (matches[3] === 'pm' && parseInt(matches[1]) === 12) {
    return 12;
  } else {
    return 12 + parseInt(matches[1]);
  }
}

function getTimeFromDate(dt: Date, format: number) {
  if (format == 12) {
    return formatAMPM(dt, false);
  } else {
    return format24HrTime(dt);
  }
}

function formatAMPM(date: Date, isCapital = true, isHours2Digit = true) {
  if (typeof date == 'string') {
    date = getDateObject(date);
  }

  let Am_string = isCapital ? 'AM' : 'am';
  let Pm_string = isCapital ? 'PM' : 'pm';
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? Pm_string : Am_string;
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutestr = minutes < 10 ? '0' + minutes : minutes;
  const tempHr = isHours2Digit ? '0' + hours : hours;
  return (hours > 9 ? hours : tempHr) +
    ':' +
    minutestr +
    ' ' +
    ampm;

}

function format24HrTime(date: any): string {
  if (typeof date == 'string') {
    date = getDateObject(date);
  }

  const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  return hours + ':' + minutes;
}