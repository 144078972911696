import { ServiceParams } from 'src/app/common/Models/http.model';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RguestCommunication } from '../common/rguest-communication';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { Localization } from '../../localization/localization';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { AppService } from '../../app-service';
import { JSONReaderService } from '../load-json.service';
@Injectable({ providedIn: 'root' })
export class ReportCommunication extends RguestCommunication {

    constructor(httpclient: HttpClient, localization: Localization, utilities: CommonUtilities, PropertyInfo: CommonPropertyInformation, appService: AppService, jsonReader: JSONReaderService) {
        super(environment["PMSReport"], httpclient, localization, utilities, PropertyInfo, appService);
    }

    putHTTPBlobData(params: ServiceParams, responseType: string,isLoaderRequired?:boolean): Observable<any> {
        return super.reportPut(params, responseType,isLoaderRequired);
    }
}

