import { AfterViewInit, Component, Input, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { ITabOutputDetails } from '@app/settings/models/common.interface';
import { RefundPopupComponent } from '@components/refund-popup/refund-popup.component';
import { AuditlogComponent } from '@components/auditlog/auditlog.component';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { AdditionalinfoComponent } from '@components/create-update-guest-tab-layout/definitions/additionalinfo/additionalinfo.component';
import { AddressComponent } from '@components/create-update-guest-tab-layout/definitions/address/address.component';
import { ContactComponent } from '@components/create-update-guest-tab-layout/definitions/contact/contact.component';
import { PersonalInfoComponent } from '@components/create-update-guest-tab-layout/definitions/personal-info/personal-info.component';
import { PreferencesComponent } from '@components/create-update-guest-tab-layout/definitions/preferences/preferences.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { buttonTypes, ClassType, ComponentTypes, GetPartiesFilter, GetPartyOptions, LessonType, OperationResultState, PartyState, PartyType, PaymentMethod, ReservationEmailNotificationType, ReservationType, RolesAndPermissionsType, SlottingType } from '@constants/commonenums';
import { ActivitiesVenue, fieldType, popupDialogDimension } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { environment } from '@environments/environment';
import { BillingDTO, CommunicationList, ContactDTO, FullContactDTO, PartyChargeState, PartyPrepaymentState, ReservationDTO } from '@models/InputContact';
import { StandByPartyDTO, WalkInDTO } from '@models/InputReservation';
import { RoleType } from '@models/LoginResultDTO';
import * as restaurantDetails from '@models/RestaurantDTO';
import { BookingChargeType, ContactHistoryItemDTO, LayoutDTO, PageMethod, SettingsDTO } from '@models/RestaurantDTO';
import { AuditableEntityType } from '@models/SimpleAuditLogItemDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails, TabConfig } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import { DashboardFunctions } from '@utilities/dashboard-functions';
import { Utilities } from '@utilities/utilities';
import { ChartOptions } from 'chart.js';
import { cloneDeep, take, sortBy, groupBy, get, orderBy, uniqBy, parseInt, uniq, round } from 'lodash';
import moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';
import { OrderDetailsComponent } from '../ordercheckdetails/orderdetails.component';
import { FeedbackDTO } from '@app/shared/models/mappers/FeedbackDTO';
import { SelectBookingTypeComponent } from '@app/shared/components/select-booking-type/select-reservation-type.component';
import { FeedbackDetailsComponent } from '../feedbackdetails/feedbackdetails.component';
import _ from 'lodash';
import { LocalizedDatePipe } from '@pipes/localize-date.pipe'
import { CustomTabsComponent } from '@app/shared/components/custom-tabs/custom-tabs.component';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';

@Component({
  selector: 'app-guestdetails',
  templateUrl: './guestdetails.component.html',
  styleUrls: ['./guestdetails.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class GuestdetailsComponent extends Utilities implements OnInit, AfterViewInit, OnDestroy {
  partymessagesTypeEmail: any = [];
  partymessagesTypesms: any = [];
  tabcomponentDetails: TabConfig[] = [];
  componentDetails: ComponentDetails;
  reservationButton: ButtonValue;
  contactSubscription: ISubscription;
  profileImage: string;
  @ViewChild('mainChart', { static: true }) mainChart: BaseChartDirective;
  @Input() contactDetails: FullContactDTO;
  lastVisitedDate: Date;
  numberOfVisit: number;
  numberofNoShows: number;
  cancelled: number;
  labels: string[];
  guestOngoingReservation = new BehaviorSubject<any[]>(null);
  guestFutureReservation = new BehaviorSubject<ReservationDTO[]>(null);
  guestPastReservation = new BehaviorSubject<ReservationDTO[]>(null);
  guestWalkin = new BehaviorSubject<WalkInDTO[]>(null);
  guestPastWalkins = new BehaviorSubject<WalkInDTO[]>([]);
  guestStandbyList = new BehaviorSubject<StandByPartyDTO[]>([]);
  pieChartType = 'doughnut';
  totalVisits: number;
  chartData: number[] = [];
  chartLabelsInternal: string[];
  chartLabels: string[];
  chartOptions: any;
  communicationList: CommunicationList[] = [];
  customFieldsList: any[] = [];
  preferredTableName: number;
  preferredServerName: string;
  tagsList: any[] = [];
  customTagsList: any[] = [];
  toggleConfig: FieldConfig[] = [];
  partymessagesTypePhone: any = [];
  upcomingBillReservations: ReservationDTO[] = [];
  pastBillReservations: ReservationDTO[] = [];
  spinnerTimer: Observable<number>;
  spinnerSubscription: ISubscription;
  confirmedActionSubscription: ISubscription;
  cancelActionSubscription: ISubscription;
  isViewAllReservations = false;
  PartyState = PartyState;
  selectedIndex: number;
  renderer: any;
  RolesAndPermissionsType = RolesAndPermissionsType;
  partyWithAddon:any;
  lineChartColors: Array<any> = [
    {
      backgroundColor: ['rgba(80, 147, 225, 1)',
        'rgba(237, 20, 70, 1)',
        'rgba(237, 120, 20, 1)',
        'rgba(161, 166, 180, 1)',
        'rgba(221, 221, 221, 1)']
    }
  ];
  pieChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      titleFontSize: 14,
      bodyFontSize: 14
    },
    layout: {
      padding: {
        left: 0,
        right: 285,
        top: 0,
        bottom: 0
      }
    },
    legend: {
      position: 'right',
      align: 'center',
      labels: {
        usePointStyle: true,
        fontSize: 18,
        fontColor: '#666666',
        fontFamily: 'Roboto-Regular',
        padding: 13,
        boxWidth: 10
      }
    },
    cutoutPercentage: 83,
    animation: {
      animateRotate: true,
      animateScale: true,
      // animationSteps: 1,
      duration: 3000,
    },
    elements: {
      arc: {
        borderWidth: 0
      }
    }
  };
  pieChartOptions1: ChartOptions = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      titleFontSize: 10,
      bodyFontSize: 10
    },
    layout: {
      padding: {
        left: 0,
        right: 200,
        top: 0,
        bottom: 0
      }
    },
    legend: {
      position: 'right',
      align: 'center',
      labels: {
        usePointStyle: true,
        fontSize: 18,
        fontColor: '#666666',
        fontFamily: 'Roboto-Regular',
        padding: 13,
        boxWidth: 10
      }
    },
    cutoutPercentage: 83,
    animation: {
      animateRotate: true,
      animateScale: true,
      duration: 3000,
    },
    elements: {
      arc: {
        borderWidth: 0
      }
    }
  };
  emailButton: ButtonValue;
  smsButton: ButtonValue;
  callButton: ButtonValue;
  waitingTime: number;
  seatedTimeTill = 0;
  totalSeatingTime: number;
  floorPlan: any = [];
  standAloneTablesArray: any = [];
  _settings: SettingsDTO = {} as SettingsDTO;
  _layout: LayoutDTO = {} as LayoutDTO;
  billingPartyList: BillingDTO[] = [];
  specialMeals: any = [];
  partyList: ReservationDTO[] = [];
  mainPopupText: string;
  RefundsAreNotSupportedVisibility: boolean = false;
  NoShowsUndoTimeInMinutes: any;
  VeryOldChargeMessageVisibility: boolean = false;
  RefundedText: string;
  RefundFailedText: string;
  RefundOverpaymentButtonVisibility: boolean = false;
  RefundButtonVisibility: boolean = false;
  HostRoleType: any;
  historyData: ContactHistoryItemDTO[] = [];
  isIGIntegrated: boolean = false;
  ChargeObsolescenceInMonths: number = 6;
  isRefundConfirmationPopup: boolean = false;
  isRefundOverPaymentConfirmationPopup: boolean = false;
  refundDialogRef: MatDialogRef<any>;
  refundOverPaymentDialogRef: MatDialogRef<any>;
  cancelSubscription: ISubscription;
  confirmSubscription: ISubscription;
  isMembershipAvailable = false;
  mindate: Date;
  isCommunicationSelected: boolean = false;
  subscriptions: Subscription = new Subscription();
  feedbackconfirmSubscription: ISubscription;
  RoleType = RoleType;
  isFirstLoad: boolean = false;
  contactUpdatedCount: number = 0;
  isCustomTagsAvailable: boolean = false;
  activityProperty: boolean = false;
  classType = ClassType;
  financialPartyData: any;
  intializedParties: boolean = true;
  showActivitiesAcrossMerchant: boolean;
  propertiesUnderMerchant: restaurantDetails.PropertiesUnderMerchantDTO[] = [];

  constructor(public _as: AppService, public _gbs: GuestBookService, private partyService: PartyService,
    public dialog: MatDialog, public cs: CacheService, public popupService: PopupService,
    private dashboardFunctions: DashboardFunctions, protected translateService?: TranslateService , public localizedDatePipe? : LocalizedDatePipe) {

    super(dialog);
    this.contactSubscription = _as.updateContacts().subscribe(() => {
      this.loadContacts();
    });
    this.spinnerTimer = timer(0, 10000);
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
      this.isMembershipAvailable = this._settings.General.UseMembershipNumber;
      this.showActivitiesAcrossMerchant = this._settings.ShowOtherPropertyActivity;
      this.propertiesUnderMerchant = this._settings.PropertiesUnderMerchant;
      if (this.contactDetails)
      this.setReservations();
    }));
    this.subscriptions.add(cs.layout.subscribe(layt => {
      this._layout = layt;
    }));
    this.activityProperty = this.isActivityProperty();
  }


  setFeedbackval(status) {
    let cmdId = 0;
    let curentStatus = 0;
    let comments = this.partyService.pastReservationsData.Feedbacks.filter(x => x.Rating == 0 && x.FeedbackQuestionId == null);
    let cmtVal = "None";
    if (comments.length > 0) {
      if (comments[0].GuestComments) {
        cmtVal = comments[0].GuestComments;
      }
      cmdId = comments[0].Id;
      curentStatus = comments[0].StatusCode;
    }

    if (!this._gbs.feedbackActionTacken) {
      const requestedPayload = {} as FeedbackDTO;
      requestedPayload.Id = cmdId;
      requestedPayload.StatusCode = status;
      if (this.feedbackconfirmSubscription) {
        this.feedbackconfirmSubscription.unsubscribe();
      }
      this.feedbackconfirmSubscription = this.partyService.postFeedBackMessage(requestedPayload).subscribe(obj => {
        this._gbs.Feedbackaction.next(true);
      });
    }
  }
  ngOnInit() {
    this.reservationButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'reservationCreateButtonText',
      disbaledproperity: false,
      icon: 'icon-Union-18'
    };
    this.emailButton = {
      type: buttonTypes.actionPrimarySmall,
      label: 'guestBookSendEmail',
      disbaledproperity: true,
      icon: 'icon-envelope'
    };
    this.smsButton = {
      type: buttonTypes.actionPrimarySmall,
      label: 'guestBookSendSMS',
      disbaledproperity: true,
      icon: 'icon-SMS'
    };
    this.callButton = {
      type: buttonTypes.actionPrimarySmall,
      label: 'guestBookCall',
      disbaledproperity: true,
      icon: 'icon-ic_call_24px'
    };
    this.mindate = this.getBeforeDays();
    this.HostRoleType = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostRoleType;
    this.specialMeals = this._settings.SpecialMeals;
    this.RefundsAreNotSupportedVisibility = !this._settings.General.IsIntegratedWithPartyPaymentService;
    this.NoShowsUndoTimeInMinutes = this._settings.General.NoShowsUndoTimeInMinutes;
    this.HostRoleType = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostRoleType;
    this.subscriptions.add(this.partyService.contacts$.subscribe(contact =>{

      let selectedContactIndex = this._gbs.allAvailableContacts.value.findIndex(allcontact => contact.Id == allcontact.Id);
      if (selectedContactIndex != -1) {
        this._gbs.allAvailableContacts[selectedContactIndex] = contact as ContactDTO
      }
      this._gbs.allAvailableContacts.next([...this._gbs.allAvailableContacts.value]);
      if (!this.contactDetails) {
        this.guestOngoingReservation.next([]);
        this.guestFutureReservation.next([]);
        this.guestPastReservation.next([]);
        this.guestStandbyList.next([]);
        this.guestWalkin.next([]);
        this.guestPastWalkins.next([]);
        this.isFirstLoad = true;
      }
      else {
        this.isFirstLoad = false;
      }
      this.contactDetails = contact;
      if (get(this.contactDetails, 'PastReservations', []).length) {
        this.contactDetails.PastReservations = orderBy(this.contactDetails.PastReservations, 'ReservedFor', 'desc');
      }
      this.setData();
    }));
    this.subscriptions.add(this.partyService.statusChange$.subscribe(data => {
      // this.reservations.filter(x => {
      //   if (this.selectedParty.Id == x.Id) {
      //     x.StatusId = data.statusId;
      //   }
      // });
    }));
    this.subscriptions.add(this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.feedback) {
        this.setFeedbackval(1);
      }
    }));
    this.subscriptions.add(this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.feedback) {
        this.setFeedbackval(1);
      }
    }));
  }

  ngAfterViewInit() {
    this.fillParties();
    this.SubscribeReservation(true);
  }
  fillParties() {
    this.partyService.SelectedContactParties$.next([]);
    let parties = [];

    if (this.guestPastReservation && this.guestPastReservation.value && this.guestPastReservation.value.length) {
      this.guestPastReservation.value.forEach(p => {
        p.Contact = new ContactDTO();
        p.Contact.Id = this.contactDetails.Id;
        p.PackageName = this.cs.availablePackages.find(pack => pack.Id == 6)?.Name;
        parties.push(p);
      })
    }
    if (this.guestFutureReservation && this.guestFutureReservation.value && this.guestFutureReservation.value.length) {
      this.guestFutureReservation.value.forEach(p => {
        p.Contact = new ContactDTO();
        p.Contact.Id = this.contactDetails.Id;
        p.PackageName = this.cs.availablePackages.find(pack => pack.Id == 6)?.Name;
        parties.push(p);
      })
    }
    if (this.guestOngoingReservation && this.guestOngoingReservation.value && this.guestOngoingReservation.value.length) {
      this.guestOngoingReservation.value.forEach(p => {
        p.Contact = new ContactDTO();
        p.Contact.Id = this.contactDetails.Id;
        p.PackageName = this.cs.availablePackages.find(pack => pack.Id == 6)?.Name;
        parties.push(p);
      })
    }
    if (this.guestStandbyList && this.guestStandbyList.value && this.guestStandbyList.value.length) {
      let standbyParties = [];
      this.guestStandbyList.value.forEach(p => {
        p.Contact = new ContactDTO();
        p.Contact.Id = this.contactDetails.Id;
        standbyParties.push(p);
      })
      this.partyService.StandbyParties$.next(standbyParties);
      this.partyService.standbyPartiesList = standbyParties;
    }
    if (this.guestWalkin && this.guestWalkin.value && this.guestWalkin.value.length) {
      this.guestWalkin.value.forEach(p => {
        p.Contact = new ContactDTO();
        p.Contact.Id = this.contactDetails.Id;
        parties.push(p);
      })
    }
    if (this.guestPastWalkins && this.guestPastWalkins.value && this.guestPastWalkins.value.length) {
      this.guestPastWalkins.value.forEach(p => {
        p.Contact = new ContactDTO();
        p.Contact.Id = this.contactDetails.Id;
        parties.push(p);
      })
    }
    //this.partyService.partiesList = parties;
    this.partyService.SelectedContactParties$.next(parties);
  }
  getBeforeDays(): Date {
    let days = this.cs.AllowEditForPastReservationsInDays;
    let d = new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    let latestdate = new Date(d.setDate(d.getDate() - days)).setHours(0, 0, 0, 0);
    return new Date(latestdate);
  }

  getTables() {
    this.floorPlan = this._layout.FloorPlans;
    const floorPlanArray = [];
    this.floorPlan.forEach(element => {
      floorPlanArray.push({ id: element.Id, value: element.Name });
    });
    const standAloneTables = this.floorPlan.filter(x => x.IsDefault === true)[0].StandaloneTables;
    standAloneTables.forEach(table => {
      this.standAloneTablesArray.push({ id: table.Id, value: table.Name });
    });
  }

  getRestaurantNameFromId(restId){
    let restaurants = [];
    if(restId)
    restaurants = this.propertiesUnderMerchant.filter(r => r.RestaurantId == parseInt(restId));
    return restaurants.length > 0 ? restaurants[0].RestaurantName : '';
  }

  getContact(){
    this._gbs.getGuest(this.contactDetails.Id, false, true);
  }

  setData() {
    if (this.contactDetails) {
      if (this.contactDetails.Photo) {
        const itemImage = `data:image/png;base64,${this.contactDetails.Photo}`;
        this.profileImage = itemImage;
      } else {
        this.profileImage = '';
      }
      this.chartOptions = (window.innerWidth > 1299) ? this.pieChartOptions : this.pieChartOptions1;
      this.getTables();
      this.setPreferences();
      this.setReservations();
      this.setTagsList();
      this.setChatHistory();
      if (!this.isCommunicationSelected) {
        this.loadChart();
        this.setBillingReservations();
      }
      else if (this.isCommunicationSelected) {
        this.isCommunicationSelected = false;
      }
    }

  }
  showException(data: any) {
    let confirmationMessage = Utilities.getConfirmationMessage(data);
    if (confirmationMessage && confirmationMessage.length > 0 && confirmationMessage[0].confirmationMessage != "") {
      const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', confirmationMessage,
        confirmationMessage[0].dialogTitle);
      const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.guestCharges,
        popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, false, this.translateService.instant('error'), this.translateService.instant('ok'), '', true);
    }
  }
  setOrderDetails() {
    const isIGIntegrated = this._settings.General.IgIntegrationDTO.IsEnabled;
    let middlewareIpAddress = this._settings.General.IgIntegrationDTO.MiddlewareIpAddress;
    if (middlewareIpAddress && isIGIntegrated) {
      this.subscriptions.add(this.partyService.getHistoryUrl(this.contactDetails.Id,true).subscribe(data => {
        this.showException(data);
        if (data.State == OperationResultState.Success) {
          this.historyData = data.Payload as ContactHistoryItemDTO[];
          this.setCheckIconVisibility();
        }
      }));
    }
  }
  setCheckIconVisibility() {
    let filterHistoryData = [];
    if (this.historyData && this.historyData.length > 0) {
      filterHistoryData = this.historyData.filter(hd => hd.OrderCheckInfo && hd.OrderCheckInfo != null &&
        hd.OrderCheckInfo.OrderItems && hd.OrderCheckInfo.OrderItems.length > 0)
    }
    if (this.guestFutureReservation && this.guestFutureReservation.value && this.guestFutureReservation.value.length > 0) {
      this.guestFutureReservation.value.forEach(data => {
        let selectedReservation = filterHistoryData.filter(h => h.PartyId == data.Id)[0];
        if (selectedReservation && selectedReservation.OrderCheckInfo != null && selectedReservation.OrderCheckInfo.OrderItems.length > 0) {
          //this.contactDetails.FutureReservations.filter(fr => fr.Id == data.Id)[0].CheckIconVisibility = true;
          data.CheckIconVisibility = true;
          data.OrderPrice = (selectedReservation.OrderCheckInfo.TotalSpent > 0) ? selectedReservation.OrderCheckInfo.TotalSpent : "0";
        }
        else {
          data.CheckIconVisibility = false;
        }
      });
    }
    if (this.guestPastReservation && this.guestPastReservation.value && this.guestPastReservation.value.length > 0) {
      this.guestPastReservation.value.forEach(data => {
        let selectedReservation = filterHistoryData.filter(h => h.PartyId == data.Id)[0];
        if (selectedReservation && selectedReservation.OrderCheckInfo != null && selectedReservation.OrderCheckInfo.OrderItems.length > 0) {
          data.CheckIconVisibility = true;
          data.OrderPrice = (selectedReservation.OrderCheckInfo.TotalSpent > 0) ? selectedReservation.OrderCheckInfo.TotalSpent : "0";
        }
        else {
          data.CheckIconVisibility = false;
        }
      });
    }
    if (this.guestWalkin && this.guestWalkin.value && this.guestWalkin.value.length > 0) {
      this.guestWalkin.value.forEach(data => {
        let selectedReservation = filterHistoryData.filter(h => h.PartyId == data.Id)[0];
        if (selectedReservation && selectedReservation.OrderCheckInfo != null && selectedReservation.OrderCheckInfo.OrderItems.length > 0) {
          data.CheckIconVisibility = true;
          data.OrderPrice = (selectedReservation.OrderCheckInfo.TotalSpent > 0) ? selectedReservation.OrderCheckInfo.TotalSpent : "0";
        }
        else {
          data.CheckIconVisibility = false;
        }
      });
    }
    if (this.guestPastWalkins && this.guestPastWalkins.value && this.guestPastWalkins.value.length > 0) {
      this.guestPastWalkins.value.forEach(data => {
        let selectedReservation = filterHistoryData.filter(h => h.PartyId == data.Id)[0];
        if (selectedReservation && selectedReservation.OrderCheckInfo != null && selectedReservation.OrderCheckInfo.OrderItems.length > 0) {
          data.CheckIconVisibility = true;
          data.OrderPrice = (selectedReservation.OrderCheckInfo.TotalSpent > 0) ? selectedReservation.OrderCheckInfo.TotalSpent : "0";
        }
        else {
          data.CheckIconVisibility = false;
        }
      });
    }
  }

  CheckIconVisibility(partyId: number): boolean {
    if (this.historyData != null && this.historyData) {
      this.historyData.forEach(data => {
        if (data.PartyId == partyId) {
          if (data.OrderCheckInfo && data.OrderCheckInfo.OrderItems)
            return true;
        }
      })
    }
    return false;
  }

  setPreferences() {
    this.customFieldsList = [];
    this.communicationList = this._gbs.getCommunicationList();
    const preferredCommunication = this.contactDetails.PreferredPageMethod;
    // if (!this.contactDetails.PhoneNumber2) {
    //   let tempEmail2 = this.communicationList.filter((list) => list.Id === PageMethod.Email2);
    //   this.communicationList = this.communicationList.slice(0, 4);
    //   this.communicationList.push(tempEmail2[0]);
    // }
    if (this.communicationList) {
      this.communicationList.forEach((data: CommunicationList) => {
        if (data.Id === preferredCommunication) {
          data.setSelected = true;
        }
        switch (data.Id) {
          case restaurantDetails.PageMethod.Email:
            data.Value = this.contactDetails.EmailAddress;
            data.isDisabled = data.setSelected ? data.setSelected : !this.contactDetails.EmailAddress;
            break;
          case restaurantDetails.PageMethod.Sms:
            data.Value = this._as.formatPhoneNumber(this.contactDetails.PhoneNumber);
            data.isDisabled = data.setSelected ? data.setSelected : !this.contactDetails.PhoneNumber;
            break;
          case restaurantDetails.PageMethod.VoiceCall:
            data.Value = this._as.formatPhoneNumber(this.contactDetails.PhoneNumber);
            data.isDisabled = data.setSelected ? data.setSelected : !this.contactDetails.PhoneNumber;
            break;
          case restaurantDetails.PageMethod.Sms2:
            data.Value = this._as.formatPhoneNumber(this.contactDetails.PhoneNumber2);
            data.isDisabled = data.setSelected ? data.setSelected : !this.contactDetails.PhoneNumber2;
            break;
          case restaurantDetails.PageMethod.VoiceCall2:
            data.Value = this._as.formatPhoneNumber(this.contactDetails.PhoneNumber2);
            data.isDisabled = data.setSelected ? data.setSelected : !this.contactDetails.PhoneNumber2;
            break;
          case restaurantDetails.PageMethod.Manual:
            data.isDisabled = data.setSelected;
          case restaurantDetails.PageMethod.Email2:
            data.Value = this.contactDetails.EmailAddress2;
            data.isDisabled = data.setSelected ? data.setSelected : !this.contactDetails.EmailAddress2;
            break;
        }
      });
    }

    let preferredTable = this.contactDetails.PreferredTableId ? this.standAloneTablesArray.filter((table) => table.id === this.contactDetails.PreferredTableId) : [];
    if (preferredTable.length > 0) {
      this.preferredTableName = preferredTable[0].value;
    } else {
      this.preferredTableName = null;
    }

    let preferredServer: restaurantDetails.ServerDTO[] = this.contactDetails.PreferredServerId ? this.cs.settings.value.Servers.filter((server) => server.Id === this.contactDetails.PreferredServerId) : [];
    if (preferredServer.length > 0) {
      this.preferredServerName = preferredServer[0].Name;
    } else {
      this.preferredServerName = null;
    }

    this.contactDetails.CustomGuestField.forEach((customField) => {
      const fieldDetails = cloneDeep(this.contactDetails.ContactCustomFields.filter((contactDetails) =>
        contactDetails.CustomFieldId === customField.Id)[0]);
      if (customField.FieldType === fieldType[1] && fieldDetails) {
        const optionsArray = customField.FieldOptions.split(',');
        optionsArray.forEach((option, index) => {
          if (index.toString() === fieldDetails.CustomFieldValue) {
            fieldDetails.CustomFieldValue = option;
          }
        });
      }
        this.customFieldsList.push({
          FieldName: customField.FieldName,
          FieldValue: fieldDetails ? fieldDetails.CustomFieldValue : ''
        });
    });
  }

  setReservations(viewAllReservationSelected?: boolean) {
    let parties = [];
    if (!this.activityProperty) {
      if (this.showActivitiesAcrossMerchant) {
        (this.contactDetails.FutureReservations && this.contactDetails.FutureReservations.length > 0) ? this.guestFutureReservation.next(this.contactDetails.FutureReservations) : this.guestFutureReservation.next(this.contactDetails.FutureReservations);
  
        if (get(this.contactDetails, 'PastReservations', []).length) {
          const pastReservations = this.contactDetails.PastReservations.filter(data => data.State != PartyState.Seated);
          this.guestPastReservation.next(pastReservations);
        }
        else {
          this.guestPastReservation.next(this.contactDetails.PastReservations)
        }
  
        if (get(this.contactDetails, 'StandByList', []).length) {
          const standbyList = this.contactDetails.StandByList.filter(data => data.State != PartyState.Seated);
          this.guestStandbyList.next(standbyList);
        }
        else {
          this.guestStandbyList.next(this.contactDetails.StandByList)
        }
  
        if (get(this.contactDetails, 'Walkins', []).length) {
          const pastWalkIns = this.contactDetails.Walkins.filter(data => data.State == PartyState.Left)
          this.guestPastWalkins.next(orderBy(pastWalkIns, 'ArrivedAt', 'desc'));
          this.guestOngoingReservation.next(this.contactDetails.Walkins.filter(data => data.State == PartyState.Seated));
          this.guestWalkin.next(this.contactDetails.Walkins.filter(data => data.State == PartyState.Pending));
        }
  
        if (get(this.contactDetails, 'PastReservations', []).length) {
          const pastOngoingReservations = this.contactDetails.PastReservations.filter(data => data.State == PartyState.Seated);
          const allOngoingReservations = uniqBy([...this.guestOngoingReservation.value, ...pastOngoingReservations], 'Id');
          this.guestOngoingReservation.next(allOngoingReservations);
        }
      }
      else {
        (this.contactDetails.FutureReservations && this.contactDetails.FutureReservations.length > 0) ? this.guestFutureReservation.next(this.contactDetails.FutureReservations.filter(data => data.RestaurantId == this._as.restaurantId)) : this.guestFutureReservation.next(this.contactDetails.FutureReservations);
  
        if (get(this.contactDetails, 'PastReservations', []).length) {
          const pastReservations = this.contactDetails.PastReservations.filter(data => data.RestaurantId == this._as.restaurantId && data.State != PartyState.Seated);
          this.guestPastReservation.next(pastReservations);
        } else {
          this.guestPastReservation.next(this.contactDetails.PastReservations)
        }
  
        if (get(this.contactDetails, 'StandByList', []).length) {
          const standbyList = this.contactDetails.StandByList.filter(data => data.RestaurantId == this._as.restaurantId && data.State != PartyState.Seated);
          this.guestStandbyList.next(standbyList);
        }
        else {
          this.guestStandbyList.next(this.contactDetails.StandByList)
        }
  
  
        if (get(this.contactDetails, 'Walkins', []).length) {
          const pastWalkIns = this.contactDetails.Walkins.filter(data => data.RestaurantId == this._as.restaurantId && data.State == PartyState.Left)
          this.guestPastWalkins.next(orderBy(pastWalkIns, 'ArrivedAt', 'desc'));
          this.guestOngoingReservation.next(this.contactDetails.Walkins.filter(data => data.RestaurantId == this._as.restaurantId && data.State == PartyState.Seated));
          this.guestWalkin.next(this.contactDetails.Walkins.filter(data => data.RestaurantId == this._as.restaurantId && data.State == PartyState.Pending));
        }
        else{
          this.guestPastWalkins.next([]);
          this.guestOngoingReservation.next([]);
          this.guestWalkin.next([]);
  
        }
  
        if (get(this.contactDetails, 'PastReservations', []).length) {
          const pastOngoingReservations = this.contactDetails.PastReservations.filter(data => data.RestaurantId == this._as.restaurantId && data.State == PartyState.Seated);
          const allOngoingReservations = uniqBy([...this.guestOngoingReservation.value, ...pastOngoingReservations], 'Id');
          this.guestOngoingReservation.next(allOngoingReservations);
        }
      }
      this.setOngoingReservationTimer();
      this.isViewAllReservations = ((this.guestPastReservation.value != null && this.guestPastReservation && this.guestPastReservation.value && this.guestPastReservation.value.length > 3) || (this.guestFutureReservation.value != null && this.guestFutureReservation && this.guestFutureReservation.value && this.guestFutureReservation.value.length > 3) || (this.guestOngoingReservation.value != null && this.guestOngoingReservation && this.guestOngoingReservation.value && this.guestOngoingReservation.value.length > 3)
        || (this.guestWalkin.value != null && this.guestWalkin && this.guestWalkin.value && this.guestWalkin.value.length > 3) || (this.guestPastWalkins.value != null && this.guestPastWalkins && this.guestPastWalkins.value && this.guestPastWalkins.value.length > 3)) || (this.guestStandbyList.value != null && this.guestStandbyList && this.guestStandbyList.value && this.guestStandbyList.value.length > 3)
      if (!viewAllReservationSelected) {
        this.guestPastReservation.next(take(this.guestPastReservation.getValue(), 3));
        this.guestFutureReservation.next(take(this.guestFutureReservation.getValue(), 3));
        this.guestOngoingReservation.next(take(this.guestOngoingReservation.getValue(), 3));
        this.guestStandbyList.next(take(this.guestStandbyList.getValue(), 3));
        this.guestWalkin.next(take(this.guestWalkin.getValue(), 3));
        this.guestPastWalkins.next(take(this.guestPastWalkins.getValue(), 3));
      }
    }
    if (this.activityProperty) {
      if (this.contactDetails.FutureActivities && this.contactDetails.FutureActivities.length > 0) {
        this.contactDetails.FutureActivities = this.setActivityList(this.contactDetails.FutureActivities.filter(data => data.RestaurantId == this._as.restaurantId));
        this.guestFutureReservation.next(this.contactDetails.FutureActivities.filter(data => data.RestaurantId == this._as.restaurantId));
      }
      else
      {
        this.guestFutureReservation.next([])
      }
      if (this.contactDetails.PastActivities && this.contactDetails.PastActivities.length > 0) {
        this.contactDetails.PastActivities = this.setActivityList(this.contactDetails.PastActivities.filter(data => data.RestaurantId == this._as.restaurantId));
        this.guestPastReservation.next(this.contactDetails.PastActivities.filter(data => data.RestaurantId == this._as.restaurantId));
      }
      else
      {
        this.guestPastReservation.next([])
      }
      if (this.contactDetails.OngoingActivities && this.contactDetails.OngoingActivities.length > 0) {
        // this.contactDetails.OngoingActivities.forEach(activity => {
        //   if(activity.SpecialMeal && activity.SpecialMeal.ClassType === this.classType.Session){
        //     activity.BookedSessions = activity.BookedSessions.filter(session => session.SessionState === 1);
        //   }
        // });
        this.contactDetails.OngoingActivities = this.setActivityList(this.contactDetails.OngoingActivities.filter(data => data.RestaurantId == this._as.restaurantId));
        this.contactDetails.OngoingActivities.forEach(activity => {
          if (activity.SpecialMeal && activity.SpecialMeal.ClassType === this.classType.Session) {
            let bookedSession = activity.BookedSessions.find(session => session.SessionState === PartyState.Seated);
            if (bookedSession) {
              let session = this._settings.SpecialMeals.find(meal => meal.Id === activity.SpecialMeal.Id).ActivitySessions.find(mealSession => mealSession.ActivitySessionId === bookedSession.ActivitySessionId);
              activity.SeatingTime = new Date(bookedSession.BookedDate.toString().split('T')[0] + 'T' + session.StartTime);
              activity.DepartureTime = new Date(bookedSession.BookedDate.toString().split('T')[0] + 'T' + session.EndTime);
            }
          }
        });
        this.guestOngoingReservation.next(this.contactDetails.OngoingActivities.filter(data => data.RestaurantId == this._as.restaurantId));
      }
      else
      {
        this.guestOngoingReservation.next([])
      }
      this.isViewAllReservations = ((this.guestPastReservation.value != null && this.guestPastReservation && this.guestPastReservation.value && this.guestPastReservation.value.length > 3) || (this.guestFutureReservation.value != null && this.guestFutureReservation && this.guestFutureReservation.value && this.guestFutureReservation.value.length > 3) || (this.guestOngoingReservation.value != null && this.guestOngoingReservation && this.guestOngoingReservation.value && this.guestOngoingReservation.value.length > 3))
      if (!viewAllReservationSelected) {
        this.guestPastReservation.next(take(this.guestPastReservation.getValue(), 3));
        this.guestFutureReservation.next(take(this.guestFutureReservation.getValue(), 3));
        this.guestOngoingReservation.next(take(this.guestOngoingReservation.getValue(), 3));
      }
    }

    if (this.isFirstLoad) {
      this.setOrderDetails();
    }
    else {
      if (this.partyService.SelectedContactParties$ && this.partyService.SelectedContactParties$.value) {
        var isSameContact = this.partyService.SelectedContactParties$.value.filter(p => p.Contact.Id == this.contactDetails.Id);
        this.setOrderDetails();
      }
      this.contactUpdatedCount = isSameContact.length;
    }
  }

  setOngoingReservationTimer() {
    if (this.guestOngoingReservation.value != null && this.guestOngoingReservation.value.length > 0) {
      this.spinnerSubscription = this.spinnerTimer.subscribe((data) => {
        this.guestOngoingReservation.getValue().forEach(res => {
          res.totalSeatingTime = this.getTotalSeatingTime(res);
          this.calculatePercentile(res);
          if (res.seatedTimeTill < res.totalSeatingTime) {
            this.calculatePercentile(res);
          } else {
            this.spinnerSubscription.unsubscribe();
          }
          if (res.seatedTimeTill >= res.totalSeatingTime) {
            res.seatedTimeTill = null;
          }
        })
      });
      this.setTimerValues(this.guestOngoingReservation.value);
      this.subscriptions.add(this._as.minuteTimer$.subscribe(val => {
        this.setTimerValues(this.guestOngoingReservation.value);
      }));
    }
  }

  setActivityList(activityList) {
    activityList.forEach(activity => {
      if (activity.SpecialMeal && activity.SpecialMeal.ClassType === ClassType.Session) {
        activity.TableNames = this.getTableNames(activity);
      }
      else if (activity.SpecialMeal && activity.SpecialMeal.ClassType === ClassType.Class) {
        activity.SpecialMeal.GroupName = this.getSessionGroupName(activity);
      }
      this.setPartyAmount(activity);
    });
    return activityList;
  }

  financialDetails(attendee) {
    this.financialPartyData = attendee;
  }

  getSessionGroupName(activity: ReservationDTO): string {
    let activitySessions = this._settings.SpecialMeals.find(meal => meal.Id === activity.SpecialMealId)?.ActivitySessions;
    let group = activitySessions?.find(session => session.SessionGroupId === activity.SessionGroupId);
    return group?.SessionGroupName;
  }

  getTableNames(activity) {
    let tableNames = [];
    if (activity?.BookedSessions && activity?.BookedSessions?.length > 0) {
      let sessionIdList = activity.BookedSessions.map(item => item.ActivitySessionId);
      let sessionSelected = this._settings.SpecialMeals?.find(meal => meal.Id == activity.SpecialMealId);
      let sessionList = sessionSelected?.ActivitySessions?.filter(session => sessionIdList.indexOf(session.ActivitySessionId) != -1);
      let tableList = [];
      sessionList?.forEach(activitySession => {
        tableList = tableList.concat(activitySession.SessionLocationMappings.map(location => location.StandaloneTableId));
      });
      tableList = uniq(tableList);
      this._layout.FloorPlans.forEach(floorPlan => {
        let tablesSelected = floorPlan.StandaloneTables.filter(table => tableList?.indexOf(table.Id) != -1);
        if (tablesSelected && tablesSelected.length) {
          tableNames = tableNames.concat(tablesSelected.map(table => table.Name));
        }
      });
    }
    return tableNames;
  }

  setTimerValues(parties) {
    parties.forEach(party => {
      if (party.State == PartyState.Seated) {
        party.progressBarValue = this.calculatePercentile(party);
        party.remainingTime = this.getRemainingTime(party);
      }
    });
  }

  setPartyAmount(party) {
    let bookingAmount = this.partyService.getBookingAmount(party);
    party['partyAmount'] = bookingAmount.partyAmount;
  }

  viewOrEditBooking(item) {
    if (item.SpecialMealId && item.SpecialMeal && item.SpecialMeal.LessonType == LessonType.PrivateLesson) {
      this.subscriptions.add(this.partyService.getPartyInfo(item.Id).subscribe(response => {
        if (response.Payload) {
          response.Payload['isConfirmedReservation'] = response.Payload.Type == PartyType.Reservation ? true : false;
          response.Payload['isStandByLessonBooked'] = item.SpecialMeal.IsForStandbyReservations ? true : false;
          response.Payload['addPrivateLessonBooking'] = true;
          this.partyService.selectedParty$.next(item);
          this.dashboardFunctions.createOrEditPrivateActivityBooking(true, response.Payload);
        }
      }));
    }
    else if (item.SpecialMealId && item.SpecialMeal && item.SpecialMeal.LessonType == LessonType.GroupLesson) {
      this.subscriptions.add(this.partyService.getPartyInfo(item.Id).subscribe(response => {
        if (response.Payload) {
          response.Payload['isConfirmedReservation'] = response.Payload.Type == PartyType.Reservation ? true : false;
          response.Payload['isStandByLessonBooked'] = item.SpecialMeal.IsForStandbyReservations ? true : false;
          this.dashboardFunctions.createOrEditActivityBooking(true, response.Payload);
        }
      }));
    }
    else {
      if (this._settings.General.SpecialMealsCoverTypesEnabled) {
        this.subscriptions.add(this.partyService.getPartyInfo(item.Id).subscribe(response => {
          if (response.Payload) {
            this.partyService.selectedParty$.next(cloneDeep(response.Payload));
            this.dashboardFunctions.createOrEditOpenBooking(true, response.Payload);
          }
        }));
      }
      else {
        this.dashboardFunctions.createOrEditOpenBooking(true, item);
      }
    }
  }

  getRemainingTime(party): number {
    let time: any;
    if (party.State === PartyState.Seated) {
      time = Utilities.parseDateString(party.DepartureTime);
    } else if (party.State !== PartyState.Seated) {
      time = (party.SeatingTime) ? Utilities.parseDateString(party.SeatingTime) : (party.ReservedFor) ?
        Utilities.parseDateString(party.ReservedFor) : Utilities.parseDateString(party.ArrivedAt);
    }

    return Math.floor((time - Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta).getTime()) / 1000);
  }

  SubscribeReservation(intitialLoad?: boolean) {
    this.subscriptions.add(this.partyService.SelectedContactParties$.subscribe(party => {
      if (this.intializedParties && party && party.length > 0 && !intitialLoad) {

        //let futureReservations = party.filter(p => p.Contact.Id == this.contactDetails.Id && p.Type == PartyType.Reservation && p.State == PartyState.Pending);
        let futureReservations = party.filter(p => {

          let seatingTime = p.SeatingTime;
          const activitySessionId = p.BookedSessions?.[0]?.ActivitySessionId;
          if(p.SpecialMealId && activitySessionId) {
            p.SpecialMeal = this._settings.SpecialMeals.find(itm => itm.Id === p.SpecialMealId);
            p.SpecialMealName = p.SpecialMeal?.Name;
            const matchedSessionObj = p.SpecialMeal?.ActivitySessions.find(session => session.ActivitySessionId == activitySessionId);
            if(matchedSessionObj) {
              const bookedDate = moment(p.StartDate).format('YYYY-MM-DD');
              seatingTime = `${bookedDate}T${matchedSessionObj.StartTime}`;
            }
          }

          return p.Contact.Id == this.contactDetails.Id && p.Type == PartyType.Reservation && p.State == PartyState.Pending && !p.TooLateToCancel
          && (Utilities.Date(seatingTime).diff(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)) > 0)
        });
        if (futureReservations && futureReservations.length > 0) {
          let UpdatedList = [];
          if (this.activityProperty) {
            this.setActivityList(futureReservations);
          }
          futureReservations.forEach(element => {
            UpdatedList = [...this.AddOrUpdateInExistinglist(this.guestFutureReservation.value, element)];
            if (this.guestOngoingReservation.value) {
              let partyInExistinglistIndex = this.guestOngoingReservation.value.findIndex(x => x.Id == element.Id);
              if (partyInExistinglistIndex > -1) {
                this.guestOngoingReservation.next(this.guestOngoingReservation.value.filter(p => p.Id != element.Id));
              }
            }
          });
          this.guestFutureReservation.next(sortBy(UpdatedList, 'ReservedFor'));
        }
        else
        {
          this.guestFutureReservation.next([])
        }
        if (this.activityProperty) {
          let ongoingReservations = party.filter(p => p.Contact.Id == this.contactDetails.Id && (p.Type == PartyType.Reservation || p.Type == PartyType.WalkIn) && p.State == PartyState.Seated);
          ongoingReservations = this.setActivityList(ongoingReservations);
          if (ongoingReservations && ongoingReservations.length > 0) {
            if (this.activityProperty) {
              ongoingReservations = this.setActivityList(ongoingReservations);
            }
            ongoingReservations.forEach(element => {
              let UpdatedList = this.AddOrUpdateInExistinglist(this.guestOngoingReservation.value, element);
              UpdatedList.forEach(activity => {
                if (activity.SpecialMeal && activity.SpecialMeal.ClassType === this.classType.Session) {
                  let bookedSession = activity.BookedSessions.find(session => session.SessionState === PartyState.Seated);
                  if (bookedSession) {
                    let session = this._settings.SpecialMeals.find(meal => meal.Id === activity.SpecialMeal.Id).ActivitySessions.find(mealSession => mealSession.ActivitySessionId === bookedSession.ActivitySessionId);
                    activity.SeatingTime = new Date(bookedSession.BookedDate.toString().split('T')[0] + 'T' + session.StartTime);
                    activity.DepartureTime = new Date(bookedSession.BookedDate.toString().split('T')[0] + 'T' + session.EndTime);
                  }
                }
              });
              if ((this.isSessionBooking(element) && !element.SessionGroupId)
                || element.SessionGroupId) {
                this.guestOngoingReservation.next(sortBy(UpdatedList, 'ReservedFor'));
                if (element.Type == PartyType.Reservation && this.guestFutureReservation.value) {
                  this.guestFutureReservation.next(this.guestFutureReservation.value.filter(p => p.Id != element.Id));
                }
              }
              else {
                let removeOverlappingFromFutureReservation = this.guestFutureReservation.value.filter(reservation => reservation.Id != element.Id);
                this.guestFutureReservation.next(sortBy(removeOverlappingFromFutureReservation, 'ReservedFor'));
                if (this.guestOngoingReservation.value) {
                  this.guestOngoingReservation.next(this.guestOngoingReservation.value.filter(p => !(p.Id == element.Id && element.SpecialMealId)));
                }
              }
            });
          }
        }
        else {
          let ongoingReservations = party.filter(p => p.Contact.Id == this.contactDetails.Id && (p.Type == PartyType.Reservation || p.Type == PartyType.WalkIn) && p.State == PartyState.Seated);
          if (ongoingReservations && ongoingReservations.length > 0) {
            ongoingReservations.forEach(element => {
              let UpdatedList = this.AddOrUpdateInExistinglist(this.guestOngoingReservation.value, element)
              this.guestOngoingReservation.next(sortBy(UpdatedList, 'ReservedFor'));
              // this.setOngoingReservationTimer();
              if (element.Type == PartyType.Reservation && this.guestFutureReservation.value) {
                let partyInExistinglistIndex = this.guestFutureReservation.value.findIndex(x => x.Id == element.Id);
                if (partyInExistinglistIndex > -1) {
                  this.guestFutureReservation.next(this.guestFutureReservation.value.filter(p => p.Id != element.Id));
                }
              }
              if (element.Type == PartyType.WalkIn) {
                let partyInExistinglistIndex = this.guestWalkin.value.findIndex(x => x.Id == element.Id);
                if (partyInExistinglistIndex > -1) {
                  this.guestWalkin.next(this.guestWalkin.value.filter(p => p.Id != element.Id));
                }
              }
            });
          }
        }

        let pastReservations = party.filter(p => p.Contact.Id == this.contactDetails.Id && p.Type == PartyType.Reservation && (p.State == PartyState.Left || p.State == PartyState.Cancelled));
        if (pastReservations && pastReservations.length > 0) {
          if (this.activityProperty) {
            this.setActivityList(pastReservations);
          }
          pastReservations.forEach(element => {
            let UpdatedList = this.AddOrUpdateInExistinglist(this.guestPastReservation.value, element)
            this.guestPastReservation.next(orderBy(UpdatedList, 'ReservedFor', 'desc'));
            if (element.State == PartyState.Cancelled) {
              let partyInExistinglistIndex = this.guestFutureReservation.value.findIndex(x => x.Id == element.Id);
              if (partyInExistinglistIndex > -1) {
                this.guestFutureReservation.next(this.guestFutureReservation.value.filter(p => p.Id != element.Id));
              }
            }
            if (element.State == PartyState.Left) {
              let partyInExistinglistIndex = this.guestOngoingReservation.value.findIndex(x => x.Id == element.Id);
              if (partyInExistinglistIndex > -1) {
                this.guestOngoingReservation.next(this.guestOngoingReservation.value.filter(p => p.Id != element.Id));
              }
            }
          });
        }

        let futureWalkIns = party.filter(p => p.Contact.Id == this.contactDetails.Id && p.Type == PartyType.WalkIn && p.State == PartyState.Pending);
        if (futureWalkIns && futureWalkIns.length > 0) {
          futureWalkIns.forEach(element => {
            let UpdatedList = this.AddOrUpdateInExistinglist(this.guestWalkin.value, element)
            this.guestWalkin.next(sortBy(UpdatedList, 'ArrivedAt'));
            let partyInExistinglistIndex = this.guestOngoingReservation.value.findIndex(x => x.Id == element.Id);
            if (partyInExistinglistIndex > -1) {
              this.guestOngoingReservation.next(this.guestOngoingReservation.value.filter(p => p.Id != element.Id));
            }
          });
        }

        let pastWalkIns = party.filter(p => p.Contact.Id == this.contactDetails.Id && p.Type == PartyType.WalkIn && (p.State == PartyState.Left || p.State == PartyState.Cancelled));
        if (pastWalkIns && pastWalkIns.length > 0) {
          pastWalkIns.forEach(element => {
            let UpdatedList = this.AddOrUpdateInExistinglist(this.guestPastWalkins.value, element)
            this.guestPastWalkins.next(orderBy(UpdatedList, 'ArrivedAt', 'desc'));
            if (element.State == PartyState.Cancelled) {
              let partyInExistinglistIndex = this.guestWalkin.value.findIndex(x => x.Id == element.Id);
              if (partyInExistinglistIndex > -1) {
                this.guestWalkin.next(this.guestWalkin.value.filter(p => p.Id != element.Id));
              }
            }
            if (element.State == PartyState.Left) {
              let partyInExistinglistIndex = this.guestOngoingReservation.value.findIndex(x => x.Id == element.Id);
              if (partyInExistinglistIndex > -1) {
                this.guestOngoingReservation.next(this.guestOngoingReservation.value.filter(p => p.Id != element.Id));
              }
            }
          });
        }
      } else if(intitialLoad) {
        intitialLoad = false;
      }
      this.intializedParties = true;
    }));
    this.subscriptions.add(this.partyService.StandbyParties$.subscribe(party => {
      if (party && party.length > 0) {
        let standbyList = party.filter(p => p.Contact.Id == this.contactDetails.Id && p.State == PartyState.Pending);
        if (standbyList && standbyList.length > 0) {
          standbyList.forEach(element => {
            let UpdatedList = this.AddOrUpdateInExistinglist(this.guestStandbyList.value, element)
            this.guestStandbyList.next(sortBy(UpdatedList, 'WishedTime'));
          });
        }
      }
    }))
    this.subscriptions.add(this._as.removedWalkIn.subscribe(partyId => {
      let walkinRemoved = this.guestWalkin.value.filter(w => w.Id == partyId);
      if (walkinRemoved && walkinRemoved.length > 0) {
        this.guestWalkin.next(this.guestWalkin.value.filter(w => w.Id != partyId));
      }
    }))
  }
  isSessionBooking(element: any): boolean {
    if (element.BookedSessions != null && element.BookedSessions.length > 0) {
      var seatedSession = element.BookedSessions.filter(s => s.SessionState == PartyState.Seated);
      if (seatedSession.length > 0)
        return true;
      else
        return false;
    }
    return false;
  }

  AddOrUpdateInExistinglist(existingList: any[], party) {
    if (!existingList) {
      existingList = [];
    }
    let partyInExistinglistIndex = existingList.findIndex(x => x.Id == party.Id);
    if (partyInExistinglistIndex > -1) {
      let feedback = existingList[partyInExistinglistIndex].Feedbacks;
      existingList.splice(partyInExistinglistIndex, 1, party);
      if (feedback) {
        existingList[partyInExistinglistIndex].Feedbacks = [];
        existingList[partyInExistinglistIndex].Feedbacks = feedback;
      }
    }
    else {
      // if (feedback) {
      //
      //   party.Feedbacks = feedback;
      // }
      party.Feedbacks = [];
      existingList.push(party);
    }
    return existingList;
  }

  viewAllReservations(isView: boolean) {
    this.isViewAllReservations = isView;
    this.setReservations(true);
  }

  calculatePercentile(party) {
    let seatedTimeTill = Math.round((Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta).getTime() -
      new Date(moment(party.SeatingTime).valueOf()).getTime()) / 60000);
    return (seatedTimeTill / this.getTotalSeatingTime(party)) * 100;
  }

  getTotalSeatingTime(reservation): number {
    if (reservation) {
      if (reservation.State === PartyState.Seated) {
        this.totalSeatingTime = Math.round((new Date(reservation.DepartureTime).getTime() - new Date(reservation.SeatingTime).
          getTime()) / 60000);
        return this.totalSeatingTime;
      } else if (reservation.Type === PartyType.WalkIn) {
        this.totalSeatingTime = Math.round((new Date(reservation.SeatingTime).getTime() - new Date(reservation.ArrivedAt).getTime()) / 60000);
        return this.totalSeatingTime;
      } else {
        return 0;
      }
    }
  }

  async setBillingReservations() {
    this.billingPartyList = [];
    await this.partyService.GetPartiesForBilling(this.contactDetails.Id, GetPartiesFilter.OnlyWithCreditCards, GetPartyOptions.ForBilling).then((data) => {
      this.showException(data);
      const response = data.Payload as ReservationDTO[];
      if (response) {
        this.partyList = [...response];
        this.partyList = this.partyList.filter(party => party.RestaurantId == this._as.restaurantId);
        this.partyList.forEach(party => {
          this.billingPartyList.push({
            Id: party.Id,
            Size: party.Size,
            ReservedFor: party.ReservedFor,
            PriceAmount: party.PrepaymentState != PartyPrepaymentState.None ? (party.SpecialMealAmount + (party.TaxAmount || 0) + (party.TotalServiceChargeAmount || 0) + (party.TaxOnServiceChargeAmount || 0)): party.NoShowFeeAmount,
            PartyStatus: this.getPartyStatus(party),
            OperationCurrency: party.OperationCurrency,
            SpecialMealBadgeVisibility: party.PrepaymentState != PartyPrepaymentState.None
          });
        });
      }
    });
  }

  seletedTabChange(event) {
    this.selectedIndex = event.index;
  }

  getPartyStatus(party: ReservationDTO): string {
    switch (party.State) {
      case PartyState.Pending:
        return this.translateService.instant("NotSeated");
      case PartyState.Seated:
        return this.translateService.instant("Seated");
      case PartyState.NoShowed:
        return this.translateService.instant("NoShow");
      case PartyState.Cancelled:
        return this.translateService.instant("Cancelled");
      case PartyState.Left:
        return this.translateService.instant("Left");
    }
  }

  setTagsList() {
    this.tagsList = [];
    let contactNotes = [];
    const allTags = this.dashboardFunctions.getPredefinedContactNotes();
    allTags.forEach((data) => {
      let predefinedContactNote = this.contactDetails.Notes.filter(note => note.RelatedId == data.Id);
      if (predefinedContactNote && predefinedContactNote.length > 0) {
        predefinedContactNote.forEach(contactNote => {   
          let predefinedContact = {
            setSelected: true,
            Name: contactNote.Text,
            Category: data.Category,
            Icon: contactNote.Icon
          };
          contactNotes.push(predefinedContact);
        });
      }
    });
    let groupTagsByCategory = groupBy(contactNotes, 'Category');
    for (let categoryNote of Object.entries(groupTagsByCategory)) {
      this.tagsList.push({
        Category: categoryNote[0],
        notes: categoryNote[1]
      })
    }
    this.customTagsList = [];
    if (this.contactDetails && this.contactDetails.Notes) {
      this.isCustomTagsAvailable = !!(this.contactDetails.Notes.filter(note => !note.RelatedId).length);
      const customTags = this.contactDetails.Notes.filter(note => !note.RelatedId);
      if (customTags && customTags.length) {
        customTags.forEach(customTag => {
          this.customTagsList.push(customTag);
        })
      }
    }
  }

  selectedMatChip(tag) {
    if (!tag.isDisabled) {
      tag.setSelected = !tag.setSelected;
    }
  }

  setChatHistory() {
    this.partymessagesTypePhone = [{
      phoneNumber: 8244872755,
      CreatedAt: new Date()
    }];
    this.partymessagesTypeEmail = [{
      chatDate: new Date(),
      chatHistory: [{
        IsIncoming: false,
        Text: 'Thanks for the invitation',
        CreatedAt: new Date()
      }, {
        IsIncoming: true,
        Text: 'Hi Jessica, Your party starts at 7am. Happy to serve you!',
        CreatedAt: new Date()
      }]
    }];
    this.partymessagesTypesms = [{
      chatDate: new Date(),
      data: [{
        IsIncoming: false,
        Text: 'Hi Jessica, Your party starts at 7am. Happy to serve you!',
        CreatedAt: new Date()
      }]
    }, {
      chatDate: new Date(),
      data: [{
        IsIncoming: true,
        Text: 'Yes. I will be there on time. Please provide a kids chair',
        CreatedAt: new Date()
      }, {
        IsIncoming: false,
        Text: 'Pleasure to help you.',
        CreatedAt: new Date()
      }]
    }];
  }

  onResize(event) {
    this.chartOptions = (window.innerWidth > 1299) ? this.pieChartOptions : this.pieChartOptions1;
  }

  closeGuestDetails() {
    this._gbs.isGuestDetailsVisible.next(false);
  }

  loadChart() {
    this.getChartDetails();
  }

  getChartDetails() {
    this.chartLabelsInternal = [];
    this.chartData = [];
    this.chartLabels = [];
    this.totalVisits = 0;
    let guestVisits = 0;
    if (get(this.contactDetails, 'VisitStats', []).length) {
      let contactVisitStats = this.contactDetails.VisitStats.filter(x => x.RestaurantId == Utilities._restaurantId);
      if (contactVisitStats && contactVisitStats.length >= 0) {
        this.chartLabelsInternal.push(`${this.translateService.instant('Reservations')} (${contactVisitStats[0].NumberOfVisitsAsReservation})`);
        this.chartData.push(contactVisitStats[0].NumberOfVisitsAsReservation);
        guestVisits = guestVisits + contactVisitStats[0].NumberOfVisitsAsReservation;

        if (!this.activityProperty) {
          this.chartLabelsInternal.push(`${this.translateService.instant('walkins')} (${contactVisitStats[0].NumberOfWalkins})`);
          this.chartData.push(contactVisitStats[0].NumberOfWalkins);
          guestVisits = guestVisits + contactVisitStats[0].NumberOfWalkins;
        }

        this.chartLabelsInternal.push(`${this.translateService.instant('Cancellations')} (${contactVisitStats[0].NumberOfCancellations})`);
        this.chartData.push(contactVisitStats[0].NumberOfCancellations);
        guestVisits = guestVisits + contactVisitStats[0].NumberOfCancellations;

        this.chartLabelsInternal.push(`${this.translateService.instant('noShow')} (${contactVisitStats[0].NumberOfNoShows})`);
        this.chartData.push(contactVisitStats[0].NumberOfNoShows);
        guestVisits = guestVisits + contactVisitStats[0].NumberOfNoShows;

        this.totalVisits = contactVisitStats[0].NumberOfVisits ? contactVisitStats[0].NumberOfVisits : this.totalVisits;
        setTimeout(() => {
          this.chartLabels = this.chartLabelsInternal;
        });
      }
    }
    if (guestVisits === 0) {
      this.chartData.push(100);
      this.pieChartOptions.tooltips.enabled = false;
    } else {
      this.chartData.push(0);
      this.pieChartOptions.tooltips.enabled = true;
    }
  }


  trackByFn(index, cell) {
    return cell.setSelected;
  }

  communicationSelected(preferredCommunication: CommunicationList) {
    if (!preferredCommunication.setSelected) {
      preferredCommunication.setSelected = !preferredCommunication.setSelected;
      if (preferredCommunication.setSelected) {
        this.communicationList.forEach((data: CommunicationList) => {
          if (data.Id !== preferredCommunication.Id) {
            data.setSelected = false;
          }
        });
        this._gbs.updateContactPreferences(this._as.restaurantId, this.contactDetails.Id, preferredCommunication.Id);
      }
    }
    this.isCommunicationSelected = true;
  }

  editGuest() {
    this._gbs.editGuest = true;
    this._gbs.guestForm.reset();
    const tabcomponent: TabConfig[] = [
      {
        tabComponent: PersonalInfoComponent,
        enabledIcon: true,
        tabLabel: 'personalInfoTab',
        tabIcon: 'Path-503 ',
        isDisabled: false
      },
      {
        tabComponent: ContactComponent,
        enabledIcon: true,
        tabLabel: 'contactTab',
        tabIcon: 'Group-593',
        isDisabled: false
      },
      {
        tabComponent: AddressComponent,
        enabledIcon: true,
        tabLabel: 'addressTab',
        tabIcon: 'Group-593',
        isDisabled: false
      },
      {
        tabComponent: PreferencesComponent,
        enabledIcon: true,
        tabLabel: 'prefernencesTab',
        tabIcon: 'Group-601',
        isDisabled: false
      }
    ];
    if (this._settings.CustomGuestFields.length > 0 ) {
      tabcomponent.push(
        {
          tabComponent: AdditionalinfoComponent,
          enabledIcon: true,
          tabLabel: 'additionalInfoTab',
          tabIcon: 'Group-602',
          isDisabled: false
        });
    }

    this._gbs.tabsModal = {
      tabs: tabcomponent,
      default: true,
      componentInput: this.contactDetails,
      isNextButtonAvailable: true,
      isEditTab: true
    };

    const tabComponentDetails: ComponentDetails = {
      componentName: CustomTabsComponent,
      popupType: 'tabs',
      tabs: this._gbs.tabsModal,
      popUpDetails: {
        isStepper: true,
        eventName: 'notifyParent'
      }
    };
    this._gbs.createContactDialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      height: '90%',
      width: '90%',
      data: {
        service:this.popupService,
        title: 'Edit Guest',
        update: 'ok',
        cancel: 'cancel',
        componentDetails: tabComponentDetails,
        from: ComponentTypes.guestBook,
        back: true,
        standalone: true
      },
    });
    let guestSubscription = this.popupService.confirmedAction$.subscribe((tabOutputInfo: ITabOutputDetails) => {
      if (tabOutputInfo && tabOutputInfo.isEdit && tabOutputInfo.fromComponent === ComponentTypes.guestBook) {
        this.updateGuest(tabOutputInfo.inputData);
      } else if (tabOutputInfo && tabOutputInfo.fromComponent === ComponentTypes.guestBook) {
        this.submitGuest();
      }
      guestSubscription?.unsubscribe();
    });
    let cancelSubscription = this.popupService.cancelledAction$.subscribe(val =>{
      if(val){
        guestSubscription?.unsubscribe();
        cancelSubscription?.unsubscribe();
      }
    })
    this.subscriptions.add(this._gbs.createContactDialogRef.afterClosed().subscribe(result => {

        guestSubscription?.unsubscribe();
        cancelSubscription?.unsubscribe();
      }));
    
  }

  updateGuest(guestDetails) {
    if (this._gbs.guestForm.valid) {
      this._gbs.updateGuest(true, guestDetails, this._gbs.createContactDialogRef);
    }
  }

  submitGuest() {
    if (this._gbs.guestForm.valid) {
      this._gbs.createGuest(true, this._gbs.createContactDialogRef);
    }
  }

  deleteGuest() {
    let confirmDeleteContact = false;
    let cancelText = 'No';
    let title = this.translateService.instant('alert');
    let updateText = 'Yes';
    let showAlert = false;
    let msg = this.translateService.instant('contactCancellationMessage');
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm', showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '600px',
      height: '350px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.guestBookInfo,
        back: false,
        standalone: true,
        showAlert: false
      }
    });
    let confirmedActionSubscription: ISubscription = null;
    let cancelledActionSubscription: ISubscription = null;
    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      if (confirmedActionSubscription) { confirmedActionSubscription.unsubscribe(); }
      if (cancelledActionSubscription) { cancelledActionSubscription.unsubscribe(); }
      if (confirmDeleteContact) {
        this._gbs.isGuestDetailsVisible.next(false);
        this._gbs.deleteContacts([this.contactDetails.Id.toString()]);
      }
      else
        return;
    }));
    confirmedActionSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.guestBookInfo) {
        confirmDeleteContact = true;
      }
    });
    cancelledActionSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.guestBookInfo) {
        confirmDeleteContact = false;;
      }
    });
  }

  loadContacts() {
    this._gbs.contacts = [];
    this._gbs.loadContacts();
  }

  ngOnDestroy() {
    this.partyService.reservationFormGroup.get('selectedGuest').setValue(null);
    this.partyService.selectedGuest = null;
    if (this.contactSubscription)
      this.contactSubscription.unsubscribe();
    if (this.spinnerSubscription)
      this.spinnerSubscription.unsubscribe();
    if (this.confirmedActionSubscription)
      this.confirmedActionSubscription.unsubscribe();
    if (this.cancelActionSubscription)
      this.cancelActionSubscription.unsubscribe();
    if (this.cancelSubscription)
      this.cancelSubscription.unsubscribe();
    if (this.confirmSubscription)
      this.confirmSubscription.unsubscribe();
    this._gbs.editGuest = false;
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    this.partyService.SelectedContactParties$.next([]);
    //this.partyService.Parties$.next([]);
  }

  actionOnClick() {
    this.partyService?.selectGuestSearch$.next(null);
    this.partyService.selectedGuest = this.contactDetails;
    this.partyService.reservationFromGuestBook = true;
    if (Utilities.IsActivitiesVenues(this._as.PropertyType)) {
      this.componentDetails = {
        componentName: SelectBookingTypeComponent,
        popupType: 'action',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        }
      };
      this.partyService.reservationDialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        height: popupDialogDimension.actionDialogHeight,
        width: popupDialogDimension.createDialogWidth,
        backdropClass: 'backdropBackground',
        data: {
          title: 'Add popup', update: 'SAVE', cancel: 'CANCEL', componentDetails: this.componentDetails, popupType: 'action'
        }
      });
    } else {
      this.dashboardFunctions.createOrEditReservation(false, null);
    }
  }

  favClick() {
    this.contactDetails.IsFavorite = !this.contactDetails.IsFavorite;
    const index = this._gbs.contacts.findIndex((data: ContactDTO) => data.Id === this.contactDetails.Id);
    this._gbs.updateFavouriteContact(index);
  }

  vipClick() {
    this.contactDetails.IsVip = !this.contactDetails.IsVip;
    const index = this._gbs.contacts.findIndex((data: ContactDTO) => data.Id === this.contactDetails.Id);
    this._gbs.updateVipContact(index);
  }
  ShowSpecialMeal(party: BillingDTO) {
    const _specialMealId = this.partyList.filter(p => p.Id == party.Id)[0].SpecialMealId;
    let title = 'Special Meal';
    if (this.specialMeals.filter(sm => sm.Id == _specialMealId)[0]) {
      this.openPopup(this.specialMeals.filter(sm => sm.Id == _specialMealId)[0].Name, title);
    }
    else {
      this.openPopup('Deleted Special Meal', title);
    }
  }
  openPopup(confirmationMessage: string, title: string) {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: 'auto', //'300px',
      width: '500px',
      data: {
        title: 'Message',
        update: 'ok',
        componentDetails: this.componentDetailsForBilling(confirmationMessage, title),
        from: ComponentTypes.guestBookInfo,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: true
      }
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(() => {
      if (this.confirmedActionSubscription) {
        this.confirmedActionSubscription.unsubscribe();
      }
      if (this.cancelActionSubscription) {
        this.cancelActionSubscription.unsubscribe();
      }
    }));
  }
  openPopupForRefund(confirmationMessage: string) {
    this.refundDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '300px',
      width: '500px',
      data: {
        title: this.translateService.instant('Message'),
        update: this.translateService.instant('fullRefund'),
        cancel: this.translateService.instant('cancel'),
        componentDetails: this.componentDetailsForRefund(confirmationMessage),
        //from: ComponentTypes.billingRefundPayment,
        back: false,
        standalone: true,
        showAction: true
      }
    });

    this.subscriptions.add(this.refundDialogRef.afterClosed().subscribe(() => {
      if (this.confirmedActionSubscription) {
        this.confirmedActionSubscription.unsubscribe();
      }
      if (this.cancelActionSubscription) {
        this.cancelActionSubscription.unsubscribe();
      }
    }));
  }
  componentDetailsForRefund(confirmationMessage: string): any {
    this.popupService.restrictCloseDialog = true;
    return {
      componentName: ConfirmationPopupComponent,
      popupType: '',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: [{
        confirmationMessage: confirmationMessage,
        dialogTitle: 'Message'
      }]
    };
  }
  componentDetailsForRefundOverPayment(confirmationMessage: string): any {
    this.popupService.restrictCloseDialog = true;
    return {
      componentName: ConfirmationPopupComponent,
      popupType: '',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: [{
        confirmationMessage: confirmationMessage,
        dialogTitle: 'Message'
      }]
    };
  }
  openPopupForOverPayment(confirmationMessage: string) {
    this.refundOverPaymentDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '300px',
      width: '500px',
      data: {
        title: this.translateService.instant('Message'),
        update: this.translateService.instant('fullRefund'),
        cancel: this.translateService.instant('refundOverpayment'),
        componentDetails: this.componentDetailsForRefundOverPayment(confirmationMessage),
        //from: ComponentTypes.billingOverPayment,
        back: false,
        standalone: true,
        //showAlert: true,
        //showAction: true
      }
    });

    this.subscriptions.add(this.refundOverPaymentDialogRef.afterClosed().subscribe(() => {
      if (this.confirmedActionSubscription) {
        this.confirmedActionSubscription.unsubscribe();
      }
      if (this.cancelActionSubscription) {
        this.cancelActionSubscription.unsubscribe();
      }
    }));

  }
  componentDetailsForBilling(confirmationMessage: string, title: string) {
    return {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: [{
        confirmationMessage: confirmationMessage,
        dialogTitle: 'Message'
      }],
      popupTitle: title
    };
  }
  async PaymentIconClick(party: any) {
    this.ClearPopupTexts();
    if (this.confirmedActionSubscription) {
      this.confirmedActionSubscription.unsubscribe();
    }
    if (this.cancelActionSubscription) {
      this.cancelActionSubscription.unsubscribe();
    }
    let selectedParty = this.partyList.filter(p => p.Id == party.Id)[0];
    if (!selectedParty) {
      await this.setBillingReservations();
      selectedParty = this.partyList.filter(p => p.Id == party.Id)[0];
    }
    this.GetPopupText(selectedParty);
    if (this.VeryOldChargeMessageVisibility) {
      this.mainPopupText = this.mainPopupText + " " + this.translateService.instant('chargeMessage');
    }
    else if (this.RefundedText) {
      this.mainPopupText = this.mainPopupText + " " + this.RefundedText;
    }
    else if (this.RefundFailedText) {
      this.mainPopupText = this.mainPopupText + " " + this.RefundFailedText;
    }
    else if (this.RefundsAreNotSupportedVisibility) {
      this.mainPopupText = this.mainPopupText + " " + this.translateService.instant('refundNotSupported');
    }
    this.RefundOverpaymentButtonVisibility = this.GetRefundOverpaymentButtonVisibility(party);
    this.RefundButtonVisibility = this.GetRefundButtonVisibility(party);
    if (this.RefundOverpaymentButtonVisibility) {
      this.openPopupForOverPayment(this.mainPopupText);
    }
    else if (this.RefundButtonVisibility) {
      this.openPopupForRefund(this.mainPopupText);
    }
    else {
      this.openPopup(this.mainPopupText, '');
    }
    this.RefundAction(party, this.RefundOverpaymentButtonVisibility, this.RefundButtonVisibility);
  }
  ClearPopupTexts() {
    this.mainPopupText = null;
    this.VeryOldChargeMessageVisibility = false;
    this.RefundedText = null;
    this.RefundFailedText = null;
    this.RefundsAreNotSupportedVisibility = false;
    this.RefundOverpaymentButtonVisibility = false;
    this.RefundButtonVisibility = false;
  }
  GetRefundButtonVisibility(selectedParty: ReservationDTO): boolean {
    let isVeryOldChargeMessageVisible = (selectedParty.ChargeState == PartyChargeState.Charged || selectedParty.ChargeState == PartyChargeState.RefundFailed
      || selectedParty.PrepaymentState == PartyPrepaymentState.Prepaid || selectedParty.PrepaymentState == PartyPrepaymentState.RefundFailed || selectedParty.PrepaymentState == PartyPrepaymentState.PartialPrepayment)
      && selectedParty.ChargedAt != null &&
      new Date(new Date(selectedParty.ChargedAt).setMonth(new Date(selectedParty.ChargedAt).getMonth() + this.ChargeObsolescenceInMonths)) <= new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));


    let areRefundsSupported = this._settings.General.IsIntegratedWithPartyPaymentService; //this.RefundsAreNotSupportedVisibility;
    var isVisible = this.HostRoleType && this.HostRoleType.Name == RoleType[RoleType.Manager]
      && (selectedParty.ChargeState == PartyChargeState.Charged || selectedParty.ChargeState == PartyChargeState.RefundFailed
        || selectedParty.PrepaymentState == PartyPrepaymentState.Prepaid || selectedParty.PrepaymentState == PartyPrepaymentState.RefundFailed || selectedParty.PrepaymentState == PartyPrepaymentState.PartialPrepayment)
      && !isVeryOldChargeMessageVisible
      && areRefundsSupported;

    if (selectedParty.PrepaymentState == PartyPrepaymentState.Refunded
      && (selectedParty.RefundedAt != null || selectedParty.AutomaticallyRefundedAt != null)
      && selectedParty.SpecialMealAmount != null
      && selectedParty.ChargeState == PartyChargeState.Charged
      && this.VeryOldChargeMessageVisibility == false
      && this.RefundsAreNotSupportedVisibility == false) {
      isVisible = false;
    }
    return isVisible;
  }
  GetRefundOverpaymentButtonVisibility(selectedParty: ReservationDTO): boolean {
    let isVisible = this.GetRefundButtonVisibility(selectedParty) == true && selectedParty.OverpaymentAmount != null && selectedParty.OverpaymentAmount > 0;
    return isVisible;
  }
  GetPopupText(selectedParty: ReservationDTO) {
    if (selectedParty.PrepaymentState == PartyPrepaymentState.PrepaymentRequired ||
      selectedParty.PrepaymentState == PartyPrepaymentState.PrepaymentFailed ||
      selectedParty.PrepaymentState == PartyPrepaymentState.PrepaymentInProgress) {
      this.mainPopupText = this.translateService.instant('noCreditCardCollectedForPrepaidMeal');
      return;
    }
    if (selectedParty.PrepaymentState == PartyPrepaymentState.DeferredPayment &&
      selectedParty.CreditCardId == null) {
      this.mainPopupText = this.translateService.instant('noCreditCardCollectedForPrepayment');
      return;
    }
    if (selectedParty.CreditCardId == null) {
      this.mainPopupText = this.translateService.instant('noCreditCardCollectedForNoShow');
      return;
    }
    if (selectedParty.ChargeState == PartyChargeState.Pending) {
      this.mainPopupText = this.GetTextForPendingState(selectedParty);
      return;
    }
    if (selectedParty.ChargeState == PartyChargeState.InProgress) {
      this.mainPopupText = this.translateService.instant('reservationIsCharging');
      return;
    }

    if (selectedParty.ChargeState == PartyChargeState.ChargeFailed) {
      this.mainPopupText = this.translateService.instant('chargeFailedMsg');
      return;
    }
    if (selectedParty.ChargeState == PartyChargeState.Ignored) {
      const amount = selectedParty.PrepaymentState != PartyPrepaymentState.None ? (selectedParty.SpecialMealAmount + (selectedParty.TaxAmount || 0) + (selectedParty.TotalServiceChargeAmount || 0) + (selectedParty.TaxOnServiceChargeAmount || 0)): selectedParty.NoShowFeeAmount;
      const state = selectedParty.State == PartyState.NoShowed ? this.translateService.instant('noshowText') : this.translateService.instant('lateCancellationText');
      if (amount)
        this.mainPopupText = `${this.translateService.instant('theText')} ${selectedParty.OperationCurrency}${amount.toFixed(2)} ${state} ${this.translateService.instant('feeIgnoredOn')} ${moment(selectedParty.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(selectedParty.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")} ${this.translateService.instant('byText')} ${selectedParty.ChargeHostName}`;
    }
    else {
      const amount = selectedParty.PrepaymentState != PartyPrepaymentState.None ? (selectedParty.GrandTotal ? selectedParty.GrandTotal : (selectedParty.SpecialMealAmount + (selectedParty.RatePlanAmount || 0) + (selectedParty.AddonItemAmount || 0) + (selectedParty.AddonItemTaxAmount || 0) + (selectedParty.PackageDiscountAmount || 0) +  (selectedParty.TotalAddonNegotiatedAmount || 0) + (selectedParty.NegotiatedAmount || 0) + (selectedParty.TaxAmount || 0) + (selectedParty.TotalServiceChargeAmount || 0) + (selectedParty.TaxOnServiceChargeAmount || 0))): selectedParty.NoShowFeeAmount;
      if (amount)
        this.mainPopupText = `${this.translateService.instant('theCard')} ${selectedParty.MaskedCardNumber ? selectedParty.MaskedCardNumber : ''} ${this.translateService.instant('wasCharged')} ${selectedParty.OperationCurrency}${amount.toFixed(2)} ${this.translateService.instant('onText')} ${moment(selectedParty.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(selectedParty.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")} ${this.GetTextForChargedByString(selectedParty)}`;
    }
    if ((selectedParty.ChargeState == PartyChargeState.Charged || selectedParty.ChargeState == PartyChargeState.RefundFailed
      || selectedParty.PrepaymentState == PartyPrepaymentState.Prepaid || selectedParty.PrepaymentState == PartyPrepaymentState.RefundFailed || selectedParty.PrepaymentState == PartyPrepaymentState.PartialPrepayment)
      && selectedParty.ChargedAt != null && new Date(new Date(selectedParty.ChargedAt).setMonth(new Date(selectedParty.ChargedAt).getMonth() + this.ChargeObsolescenceInMonths)) <= new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta))) {
      this.VeryOldChargeMessageVisibility = true;
    }
    if (selectedParty.PrepaymentState == PartyPrepaymentState.Refunded
      || selectedParty.ChargeState == PartyChargeState.Refunded) {
      let isAutomaticRefund = selectedParty.AutomaticallyRefundedAt != null;
      if (isAutomaticRefund) {
        const amount = selectedParty.PrepaymentState != PartyPrepaymentState.None ? (selectedParty.SpecialMealAmount  + (selectedParty.TaxAmount || 0) + (selectedParty.TotalServiceChargeAmount || 0) + (selectedParty.TaxOnServiceChargeAmount || 0)): selectedParty.NoShowFeeAmount;
        if (amount)
          this.RefundedText = `${this.translateService.instant('theText')} ${selectedParty.OperationCurrency}${amount.toFixed(2)} ${this.translateService.instant('chargeWasAutoRefunded')} ${moment(selectedParty.AutomaticallyRefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(selectedParty.AutomaticallyRefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")} ${this.translateService.instant('dueToCancellation')} ${this.GetRefundPopupActorText(selectedParty)}`;
      }
      else {
        const amount = selectedParty.PrepaymentState != PartyPrepaymentState.None ? (selectedParty.SpecialMealAmount + (selectedParty.TaxAmount || 0) + (selectedParty.TotalServiceChargeAmount || 0) + (selectedParty.TaxOnServiceChargeAmount || 0)): selectedParty.NoShowFeeAmount;
        if (amount)
          this.RefundedText = `${this.translateService.instant('theText')} ${selectedParty.OperationCurrency}${amount.toFixed(2)} ${this.translateService.instant('chargeWasRefunded')} ${moment(selectedParty.RefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(selectedParty.RefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")} ${this.GetRefundPopupActorText(selectedParty)}`;
      }
    }
    if (selectedParty.PrepaymentState == PartyPrepaymentState.RefundFailed
      || selectedParty.ChargeState == PartyChargeState.RefundFailed) {
        this.RefundFailedText = `${this.translateService.instant('refundFailedOn')} ${moment(selectedParty.RefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(selectedParty.RefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")}`;
    }

    if (selectedParty.ChargeState == PartyChargeState.None && selectedParty.PrepaymentState == PartyPrepaymentState.None) {
      this.mainPopupText = this.GetTextForNoneState(selectedParty);
      return;
    }

  }
  GetTextForChargedByString(selectedParty: ReservationDTO): any {
    return selectedParty.ChargeHostName != null ? ` ${this.translateService.instant('byText')} ${selectedParty.ChargeHostName}` : "";
  }
  GetTextForNoneState(selectedParty: ReservationDTO): string {
    switch (selectedParty.State) {
      case PartyState.Pending:
        if (new Date(selectedParty.ReservedFor) <= new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta))) {
          if (selectedParty.ChargeState == PartyChargeState.Pending || selectedParty.ChargeState == PartyChargeState.ChargeFailed) {
            return this.translateService.instant('reservationExpiredAndNoShow');
          }
          else {
            return this.translateService.instant('reservationExpired');
          }
        }
        else {
          return this.translateService.instant('reservationWaits');
        }
        break;
      case PartyState.Seated:
        return this.translateService.instant('guestAtTable');
        break;
      case PartyState.Left:
        return this.translateService.instant('guestPresentForReservation');
        break;
      case PartyState.Cancelled:
        return this.translateService.instant('reservationCancelledWithoutViolation');
        break;
    }
  }
  GetRefundPopupActorText(selectedParty: ReservationDTO): any {
    if ((selectedParty.RefundHostName != null) && (selectedParty.RefundHostName.length > 0)) {
      return this.translateService.instant('byText') + " " + selectedParty.RefundHostName;
    }

    if ((selectedParty.RefundCRSUserName != null) && (selectedParty.RefundCRSUserName.length > 0)) {
      return `${this.translateService.instant('byCRSUser')} ${selectedParty.RefundCRSUserName}`;
    }

    return "";
  }
  GetTextForPendingState(selectedParty: ReservationDTO): string {
    let currentDay = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    let _reservedFor = cloneDeep(selectedParty.ReservedFor)
    _reservedFor = new Date(new Date(_reservedFor).setHours(0, 0, 0, 0));
    if (selectedParty.State == PartyState.Cancelled || (new Date(selectedParty.ReservedFor) <= new Date(currentDay.setMinutes(currentDay.getMinutes() - this.NoShowsUndoTimeInMinutes))) ||
      (selectedParty.PrepaymentState == PartyPrepaymentState.DeferredPayment && (selectedParty.SpecialMeal != null &&
        _reservedFor <= new Date(new Date(moment.utc().toDate().setDate(moment.utc().toDate().getDate() + selectedParty.SpecialMeal.DeferredPaymentInDays)).setHours(0, 0, 0, 0)) ||
        (selectedParty.ChargeState != PartyChargeState.Charged && new Date(selectedParty.ReservedFor) < moment().utc().toDate())))) {
      return this.translateService.instant('reservationAtGuestCharges');
    }
    else {
      return this.translateService.instant('reservationAppearInGuestChargesSoon');
    }
  }

  RefundAction(selectedParty: ReservationDTO, RefundOverpaymentButtonVisibility: boolean, RefundButtonVisibility: boolean) {
    this.confirmedActionSubscription = this.popupService.confirmedAction$.subscribe(data => {
      if (data == ComponentTypes.login) {
        return;
      }
      if (RefundButtonVisibility == true && !this.isRefundConfirmationPopup && !this.isRefundOverPaymentConfirmationPopup && data != ComponentTypes.guestBookInfo && data != ComponentTypes.billingRefundPayment) { //if (data == ComponentTypes.billingRefundPayment) {
        this.popupService.restrictCloseDialog = true;
        this.getPaymentConfirmation(selectedParty, true, false);
      }
      else if (RefundButtonVisibility == true && this.isRefundConfirmationPopup) {
        this.refund(selectedParty.Id);
      }
      else if (RefundOverpaymentButtonVisibility == true && this.isRefundOverPaymentConfirmationPopup) {
        const partyWithRefund = this.partyList.filter(party => party.Id == selectedParty.Id);
        if (data !== ComponentTypes.guestBookService && partyWithRefund.length && partyWithRefund[0].RefundOption && partyWithRefund[0].RefundOption.length > 1) {
          this.showRefundOptions(partyWithRefund[0]);
        } else {
          this.subscriptions.add(this.partyService.RefundOverpayment(selectedParty.Id, this.partyService.refundData).subscribe((data) => {
            this.showException(data);
            this.setBillingReservations();
          }));
          this.isRefundOverPaymentConfirmationPopup = false;
          this.refundOverPaymentDialogRef.close();
        }
      }
      else if(data == ComponentTypes.billingRefundPayment){
        this.popupService.restrictCloseDialog = false;
        if(this.refundDialogRef)
        this.refundDialogRef.close();
      }
    });

    this.cancelActionSubscription = this.popupService.cancelledAction$.subscribe(data => {
      this.popupService.restrictCloseDialog = false;
      if (RefundOverpaymentButtonVisibility == true && !this.isRefundOverPaymentConfirmationPopup && !this.isRefundConfirmationPopup && data.value == 1) { //if (data.from == ComponentTypes.billingOverPayment && data.value == 1) {
        this.popupService.restrictCloseDialog = true;
        this.getPaymentConfirmation(selectedParty, false, true);
      }
      else if (this.isRefundConfirmationPopup) {
        this.isRefundConfirmationPopup = false;
        if (this.popupService.restrictCloseDialog == false) {
          this.popupService.restrictCloseDialog = true;
        }
      }
      else if (this.isRefundOverPaymentConfirmationPopup) {
        this.isRefundOverPaymentConfirmationPopup = false;
        if (this.popupService.restrictCloseDialog == false) {
          this.popupService.restrictCloseDialog = true;
        }
        if (data.from == ComponentTypes.guestBookService) {
          this.popupService.saveBtnEnable$.next(true);
        }
      }
      else if (RefundOverpaymentButtonVisibility == false && !this.isRefundConfirmationPopup && !this.isRefundOverPaymentConfirmationPopup) {
        this.popupService.restrictCloseDialog = false;
        if (this.refundDialogRef)
          this.refundDialogRef.close();
      }
    });
  }
  getPaymentConfirmation(selectedParty: ReservationDTO, isRefundbutton: boolean, isRefundOverPayment: boolean) {
    this.popupService.restrictCloseDialog = false;
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: '',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: [{
        confirmationMessage: this.getRefundconfirmationMessage(selectedParty, isRefundbutton, isRefundOverPayment),
        dialogTitle: this.translateService.instant('warningTitle')
      }]

    };
    const paymentConfirmationDialogref = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '300px',
      width: '500px',
      data: {
        title: this.translateService.instant('warningTitle'),
        update: 'ok',
        cancel: 'cancel',
        componentDetails,
        from: ComponentTypes.billingRefundPayment,
        back: false,
        standalone: true,
      }
    });

    this.subscriptions.add(paymentConfirmationDialogref.afterClosed().subscribe(event => {
      if(isRefundbutton && event == ComponentTypes.billingRefundPayment){
        this.isRefundConfirmationPopup = true;
        this.refund(selectedParty.Id);        
      }
    }));
  }


  refund(partyId){
    this.subscriptions.add(this.partyService.RefundParty(partyId).subscribe((data) => {
      this.showException(data);
      this.setBillingReservations();
    }));
    this.isRefundConfirmationPopup = false;
    if (this.refundOverPaymentDialogRef) {
      this.refundOverPaymentDialogRef.close();
    }
    else if (this.refundDialogRef) {
      this.refundDialogRef.close();
    }
  }

  getRefundconfirmationMessage(selectedParty: ReservationDTO, isRefundbutton: boolean, isRefundOverPayment: boolean): string {
    let amount = 0;
    if (isRefundbutton) {
      amount = selectedParty.PrepaymentState != PartyPrepaymentState.None ? selectedParty.SpecialMealAmount + (selectedParty.TaxAmount || 0) + (selectedParty.TotalServiceChargeAmount || 0) + (selectedParty.TaxOnServiceChargeAmount || 0) + selectedParty.RatePlanAmount : selectedParty.NoShowFeeAmount;
      
    }
    else if (isRefundOverPayment) {
      amount = selectedParty.OverpaymentAmount;
      this.isRefundOverPaymentConfirmationPopup = true;
    }
    //amount = amount > 0 ? selectedParty.PaidAmount : amount;
    amount = selectedParty.PaidAmount > 0 && amount - selectedParty.PaidAmount > 0 ? selectedParty.PaidAmount : amount;
    return `${this.translateService.instant('confirmRefund')} ${selectedParty.OperationCurrency}${amount.toFixed(2)} ${this.translateService.instant('toTheCard')} ${selectedParty.MaskedCardNumber ? selectedParty.MaskedCardNumber : ''}`;
  }

  showRefundOptions(refundData) {
    const componentDetails: ComponentDetails = {
      componentName: RefundPopupComponent,
      popupType: 1 != null ? '' : 'action',
      dimensionType: 'large',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: refundData,
      popupTitle: this.translateService.instant('SelectRefundvalues')
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '80vh',
      width: '80%',
      maxWidth: '60vw',
      data: {
        title: this.translateService.instant('SelectRefundstrategy'),
        update: 'Proceed',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.guestBookService,
        back: false,
        standalone: true,
        showAction: true,
      },
    });
  }

  ShowOrderDetails(party: ReservationDTO) {
    this.historyData.forEach((data) => {
      if(data.PartyId == party.Id) {
        data.TableNames = party.TableNames || [];
        this.partyService.orderDetails.push(data);
      }
    })
    const tabComponentDetails: ComponentDetails = {
      componentName: OrderDetailsComponent,
      popupType: '',
      //tabs: tabsModal,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '90%',
      width: '90%',
      data: {
        title: this.getMessage(party),
        update: 'ok',
        cancel: 'cancel',
        componentDetails: tabComponentDetails,
        from: ComponentTypes.guestBook,
        back: false,
        standalone: true,
        showFooter: false
      },
    });
  }

  getMessage(party: ReservationDTO): any {
    const firstName = (this.contactDetails.FirstName) ? (this.contactDetails.FirstName) : '';
    const LastName = (this.contactDetails.LastName) ? (this.contactDetails.LastName) : '';
    const datetime = moment(party.ReservedFor).locale(this.dashboardFunctions.getLocaleCode()).format("DD MMM YYYY") + ' ' + moment(party.ReservedFor).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm");
    return firstName + ' ' + LastName + ' ' + datetime;
  }
  showPaymentButton(party: ReservationDTO): boolean {
    let isVisible = false;
    if (party.PrepaymentState != PartyPrepaymentState.None) {
      isVisible = party.PrepaymentState == PartyPrepaymentState.Prepaid
        || party.PrepaymentState == PartyPrepaymentState.Refunded
        || party.PrepaymentState == PartyPrepaymentState.RefundFailed
        || party.PrepaymentState == PartyPrepaymentState.PartialPrepayment;
      if (party.PrepaymentState == PartyPrepaymentState.DeferredPayment) {
        isVisible = party.ChargeState == PartyChargeState.Ignored || party.ChargeState == PartyChargeState.Charged
          || party.ChargeState == PartyChargeState.Refunded
          || party.ChargeState == PartyChargeState.RefundFailed;
      }
    }
    else {
      isVisible = party.ChargeState == PartyChargeState.Ignored || party.ChargeState == PartyChargeState.Charged
        || party.ChargeState == PartyChargeState.Refunded
        || party.ChargeState == PartyChargeState.RefundFailed;
    }
    return isVisible;
  }

  EditReservation(reservation: ReservationDTO, isPastReservation?: boolean) {
    if (!reservation.Contact)
      reservation.Contact = this.contactDetails;
    reservation['isPastReservation'] = isPastReservation;
    this.dashboardFunctions.createOrEditReservation(true, reservation);
  }
  EditWaitlist(reservation: any, isPastReservation?: boolean) {
    if (!reservation.Contact)
      reservation.Contact = this.contactDetails;
    reservation['isPastReservation'] = isPastReservation;
    this.dashboardFunctions.createOrEditWaitlist(true, reservation)
  }
  Editstandby(standbyParty: StandByPartyDTO) {
    this.dashboardFunctions.createOrEditStandbyParty(true, standbyParty);
  }
  cancelParty(party: any) {
    if (!party.Contact)
      party.Contact = this.contactDetails;
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
    if (this._as.OTASourceId.includes(party.PartySourceId)) {
      this.dashboardFunctions.showOTAAlert(party);
      return;
    }
    let msg = '';
    if (party.Type === PartyType.WalkIn) {
      msg = this.translateService.instant('cancelWaitlist');
    } else if (party.Type === PartyType.Reservation) {
      msg = party.HostId != null ? this.translateService.instant('cancelReservation') : this.translateService.instant('cancelReservationForWeb');
      msg = this.translateService.instant('cancelReservation');
    } else if (party.Type === PartyType.StandBy) {
      msg = this.translateService.instant('removeStandbyConfText');
    }
    let cancelText = 'No';
    let title = 'cancelConfirmationMsg';
    let noShowSet = false;
    let cancelReservation = false;
    let updateText = 'Yes';
    let showAlert = false;
    let noShowFeePopUp = false;
    let dialogTitle = 'cofirm';
    let isWebReservation = false;
    let currentRestaurantDateTime = new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    if(party && party.PartySourceId){
      isWebReservation = this.partyService.isWebReservation(party.PartySourceId);
    }
    if ((party.Type === PartyType.Reservation && moment(party.ReservedFor) < moment(currentRestaurantDateTime)) ||
      (party.Type === PartyType.WalkIn && moment(party.ArrivedAt) < moment(currentRestaurantDateTime))) {
      const reservationMsg = this.translateService.instant('reservationCancelMsg');
      const waitlistMsg = this.translateService.instant('waitlistCancelMsg');
      msg = party.Type === PartyType.Reservation ? reservationMsg : waitlistMsg;
      cancelText = party.Type === PartyType.Reservation ? this.translateService.instant('cancelReservationMsg') :
        this.translateService.instant('cancelParty');
      cancelReservation = false;
      updateText = this.translateService.instant('noShow');
      title = this.translateService.instant('alert');
      showAlert = true;
      noShowFeePopUp = true;
    }
    if (party.Type === PartyType.StandBy) {
      dialogTitle = this.translateService.instant('removeStandbyPartyTitle');
      showAlert = true;
    }

    const popUpMessage = [{
      confirmationMessage: msg, 
      dialogTitle, showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '600px',
      height: '350px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true
      }
    });
    let confirmedActionSubscription: ISubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.reservation && noShowFeePopUp) {
        noShowSet = true;
      }
      if (val === ComponentTypes.reservation && !noShowFeePopUp) {
        cancelReservation = true;
      }
    });
    let cancelledActionSubscription: ISubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.reservation && val.value === 1 && !cancelReservation && noShowFeePopUp) {
        cancelReservation = true;
      }
    });
    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      if (cancelReservation) {
        if (party.Type === PartyType.WalkIn) {
          this.dashboardFunctions.cancelWaitlistPartyConfirm(party);
          cancelReservation = false;
          this.closeGuestDetails();
        } else if (party.Type === PartyType.Reservation) {
          this.subscriptions.add(this.partyService.cancelReservation(party.Id).subscribe(
            (data) => {
              if (data.Payload != null) {
                this.partyService.reservationType = ReservationType.Reservation;
                if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 1) {
                  this.partyService.openConfirmationDialog(data, this.translateService.instant('refundFor') + this.cs.operationCurrency + data.Payload.RefundAmount + " " + this.translateService.instant('refundForCancellation'), party.SeatingTime);
                }
                if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 2) {
                  this.partyService.openConfirmationDialog(data, this.translateService.instant('attemptedRefund')+this.cs.operationCurrency + data.Payload.RefundAmount + " " + this.translateService.instant('refundFailed'), party.SeatingTime);
                }
                if (data.Payload && data.Payload.EmailAddress && (this.cs.settings.value.General.HostCancellationEmailSendBehavior == restaurantDetails.PartyEmailSendBehavior.Prompt)) {
                  this.partyService.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Cancelled);
                }
              }
              cancelReservation = false;
              this.closeGuestDetails();
            }));
        } else if (party.Type === PartyType.StandBy) {
          this.partyService.removeStandbyParty(party.Id).subscribe(data => {
            cancelReservation = false;
            this.closeGuestDetails();
          })
        }
      } else if (noShowSet && noShowFeePopUp) {
        this.subscriptions.add(this.partyService.noShowParty(party.Id).subscribe((data) => {
          if (data.State === OperationResultState.Success) {
            noShowSet = false;
            this.closeGuestDetails();
          }
        }));
      }
      if (confirmedActionSubscription) confirmedActionSubscription.unsubscribe();
      if (cancelledActionSubscription) cancelledActionSubscription.unsubscribe();
    }));
  }
  showFeedback(data) {

    if (data && data.Feedbacks && data.Feedbacks.length > 0) {
      return true;
      console.log("feedback true");
    }
    console.log("feedback false");
    return false;

  }
  showEditForPastReservation(data) {
    if (data)
      var _partyDate = data.ReservedFor ? data.ReservedFor : data.ArrivedAt;
    let d = new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    if (_partyDate) {
      if ((this.mindate < Utilities.parseDateString(_partyDate) && data.State == PartyState.Left) ||
        (data.State == PartyState.NoShowed && (Math.floor((d.getTime() - Utilities.parseDateString(_partyDate).getTime()) / 1000 / 60 / 60 / 24)) < this._settings.General.NoShowsUndoTimeInMinutes))
        return true;
    }
    return false;
  }

  showPartyWaiverForm(party) {
      this.subscriptions.add(this.partyService.getWaiverFormUrl(party.ConfirmationCode).subscribe(data => {
        if (data.State == OperationResultState.Success) {    
          if(!(data.Payload)){
            window.open(party.WaiverFormURL, 'Waiver Form');  
          }
          else {     
            const binaryString = window.atob(data.Payload);
            const bytes = new Uint8Array(binaryString.length);
            const binaryToBlob = bytes.map((byte, i) => binaryString.charCodeAt(i));
            const blob = new Blob([binaryToBlob], { type: 'application/pdf' });
            this.downloadFile(blob, "123.pdf");
          }
        }
      }));
  }
  
  private downloadFile(blob: any, fileName: string): void {

    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    window.open(url, '_blank');

    // rewoke URL after 15 minutes
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 15 * 60 * 1000);
    }

  showPartyAuditLog(party) {
    party = (!party.ConfirmationCode) ? this.partyList.filter(p => p.Id == party.Id)[0] : party;
    //party.Contact = (!party.Contact) ? this.contactDetails : party.Contact;
    party.Contact = this.contactDetails;

    const showAlert = false;
    const title = this.translateService.instant('auditlog') + " - " + party.ConfirmationCode;
    const popUpMessage = [{
      dialogTitle: title, showAlert, type: AuditableEntityType.Party, party: party
    }];

    const componentDetails: ComponentDetails = {
      componentName: AuditlogComponent,
      dimensionType: 'large',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };


    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: popupDialogDimension.createDialogMaxHeight,
      width: popupDialogDimension.createDialogMaxWidth,
      data: {
        title,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: false
      }
    });
  }

  ActivityPaymentClick(selectedParty: any) {
    this.ClearPopupTexts();
    if (this.confirmedActionSubscription) {
      this.confirmedActionSubscription.unsubscribe();
    }
    if (this.cancelActionSubscription) {
      this.cancelActionSubscription.unsubscribe();
    }
    let party = this.partyList.filter(p => p.Id == selectedParty.Id)[0];
    this.GetPopupMessage(party);
    this.GetOldChargeMessageVisibility(party);
    this.GetRefundText(party);
    this.GetRefundFailedText(party);
    if (this.VeryOldChargeMessageVisibility) {
      this.mainPopupText = this.mainPopupText + " " + this.translateService.instant('chargeMessage');
    }
    else if (this.RefundedText) {
      this.mainPopupText = this.mainPopupText + " " + this.RefundedText;
    }
    else if (this.RefundFailedText) {
      this.mainPopupText = this.mainPopupText + " " + this.RefundFailedText;
    }
    else if (this.RefundsAreNotSupportedVisibility) {
      this.mainPopupText = this.mainPopupText + " " + this.translateService.instant('refundNotSupported');
    }
    this.RefundButtonVisibility = this.GetRefundButtonVisibility(party);
    this.RefundOverpaymentButtonVisibility = this.GetRefundOverpaymentButtonVisibility(party);
    if (this.RefundOverpaymentButtonVisibility) {
      this.openPopupForOverPayment(this.mainPopupText);
    }
    else if (this.RefundButtonVisibility) {
      this.openPopupForRefund(this.mainPopupText);
    }
    else {
      this.openPopup(this.mainPopupText, '');
    }
    this.RefundAction(party, this.RefundOverpaymentButtonVisibility, this.RefundButtonVisibility);
  }

  GetPopupMessage(party: any) {
    let paymentDetails = this.partyService.getBookingAmount(party);
    var PaymentAmount = paymentDetails.partyAmount;
    PaymentAmount = party.PaidAmount > 0 && PaymentAmount - party.PaidAmount > 0 ? party.PaidAmount : PaymentAmount;
    if (party.PrepaymentState == PartyPrepaymentState.PrepaymentRequired
      || party.PrepaymentState == PartyPrepaymentState.PrepaymentFailed
      || party.PrepaymentState == PartyPrepaymentState.PrepaymentInProgress) {
      this.mainPopupText = this.translateService.instant('NoCreditCardWarningMsg');
      return;
    }
    if (party.PrepaymentState == PartyPrepaymentState.Prepaid
      || party.PrepaymentState == PartyPrepaymentState.Refunded
      || party.PrepaymentState == PartyPrepaymentState.RefundFailed
      || party.PrepaymentState == PartyPrepaymentState.PartialPrepayment
      || (party.PrepaymentState == PartyPrepaymentState.DeferredPayment
        && (party.ChargeState == PartyChargeState.Charged
          || party.ChargeState == PartyChargeState.Refunded
          || party.ChargeState == PartyChargeState.RefundFailed
          || party.ChargeState == PartyChargeState.ChargeFailed))) {
            this.mainPopupText = `${this.translateService.instant('theCard')} ${party.MaskedCardNumber ? party.MaskedCardNumber : ''} ${this.translateService.instant('wasCharged')} ${party.OperationCurrency}${PaymentAmount.toFixed(2)} ${this.translateService.instant('onText')} ${moment(party.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(party.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format('hh:mm A')} ${this.GetTextForChargedByString(party)}`;
      return;
    }
    if (party.ChargeState == PartyChargeState.Ignored) {
      const amount = PaymentAmount ? PaymentAmount : party.NoShowFeeAmount;
      const state = party.State == PartyState.NoShowed ? this.translateService.instant('noshowText') : this.translateService.instant('lateCancellationText');
      this.mainPopupText = `${this.translateService.instant('theText')} ${party.OperationCurrency}${amount.toFixed(2)} ${state} ${this.translateService.instant('feeIgnoredOn')} ${moment(party.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(party.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")} ${this.translateService.instant('byText')} ${party.ChargeHostName}`;
      return;
    }
    if (party.PrepaymentState == PartyPrepaymentState.DeferredPayment && party.ChargeState == PartyChargeState.Pending) {
      this.mainPopupText = `${this.translateService.instant('theCard')} ${party.MaskedCardNumber ? party.MaskedCardNumber : ''} ${this.translateService.instant('wasauthorized')} ${party.OperationCurrency}${PaymentAmount.toFixed(2)} ${this.translateService.instant('onText')} ${moment(party.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(party.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")}`;
      return;
    }
    this.mainPopupText = `${this.translateService.instant('theCard')} ${party.MaskedCardNumber ? party.MaskedCardNumber : ''} ${this.translateService.instant('wasCharged')} ${party.OperationCurrency}${PaymentAmount.toFixed(2)} ${this.translateService.instant('onText')} ${moment(party.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(party.ChargedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")}`;
  }

  GetOldChargeMessageVisibility(selectedParty: any) {
    if ((selectedParty.ChargeState == PartyChargeState.Charged || selectedParty.ChargeState == PartyChargeState.RefundFailed
      || selectedParty.PrepaymentState == PartyPrepaymentState.Prepaid || selectedParty.PrepaymentState == PartyPrepaymentState.RefundFailed || selectedParty.PrepaymentState == PartyPrepaymentState.PartialPrepayment)
      && selectedParty.ChargedAt != null && new Date(new Date(selectedParty.ChargedAt).setMonth(new Date(selectedParty.ChargedAt).getMonth() + this.ChargeObsolescenceInMonths)) <= new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta))) {
      this.VeryOldChargeMessageVisibility = true;
    }
  }
  GetRefundText(selectedParty: any) {
    const PaymentAmount = (selectedParty.SpecialMealAmount == null && selectedParty.RatePlanAmount == null) ? null : (selectedParty.SpecialMealAmount + selectedParty.RatePlanAmount + (selectedParty.TaxAmount || 0) + (selectedParty.TotalServiceChargeAmount || 0) + (selectedParty.TaxOnServiceChargeAmount || 0) + (selectedParty.AddonItemAmount || 0) + (selectedParty.AddonItemTaxAmount || 0));
    var amount = selectedParty.PrepaymentState != PartyPrepaymentState.None ? PaymentAmount : selectedParty.NoShowFeeAmount;
    //amount = amount > 0 ? selectedParty.PaidAmount : amount;
    amount = selectedParty.PaidAmount > 0 && amount - selectedParty.PaidAmount > 0 ? selectedParty.PaidAmount : amount;
    if (selectedParty.ChargeState == PartyChargeState.Refunded) {
      if (amount) {
        this.RefundedText = `${this.translateService.instant('theText')} ${selectedParty.OperationCurrency}${amount.toFixed(2)} ${this.translateService.instant('chargeWasRefunded')} ${moment(selectedParty.RefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(selectedParty.RefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")} ${this.GetRefundPopupActorText(selectedParty)}`;
        return;
      }
    }
    if (selectedParty.PrepaymentState == PartyPrepaymentState.Refunded) {
      let isAutomaticRefund = selectedParty.AutomaticallyRefundedAt != null;
      if (isAutomaticRefund) {
        if (amount) {
          this.RefundedText = `${this.translateService.instant('theText')} ${selectedParty.OperationCurrency}${amount.toFixed(2)} ${this.translateService.instant('chargeWasAutoRefunded')} ${moment(selectedParty.AutomaticallyRefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(selectedParty.AutomaticallyRefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")} ${this.translateService.instant('dueToCancellation')} ${this.GetRefundPopupActorText(selectedParty)}`;
        }
      }
      else {
        if (amount)
        this.RefundedText = `${this.translateService.instant('theText')} ${selectedParty.OperationCurrency}${amount.toFixed(2)} ${this.translateService.instant('chargeWasRefunded')} ${moment(selectedParty.RefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(selectedParty.RefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")} ${this.GetRefundPopupActorText(selectedParty)}`;
      }
    }
  }

  GetRefundFailedText(selectedParty: any) {
    if (selectedParty.PrepaymentState == PartyPrepaymentState.RefundFailed || selectedParty.ChargeState == PartyChargeState.RefundFailed) {
      this.RefundFailedText = `${this.translateService.instant('refundFailedOn')} ${moment(selectedParty.RefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat)} ${this.translateService.instant('atText')} ${moment(selectedParty.RefundedAt).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm A")}`;
    }
  }
  isActivityProperty() {
    return ActivitiesVenue.find(x => x == this._settings.PropertyType) ? true : false;
  }


  ShowFeedbackDetails(data, pastresv, currentData) {
    // this.partyService.orderDetails = this.historyData.filter(data => data.PartyId == party.Id);
    this.partyService.feedbackDetails = data;
    this.partyService.pastReservationsData = currentData;
    this.popupService.restrictCloseDialog = false;
    this._gbs.feedbackActionTacken = false;
    const componentDetails = {
      componentName: FeedbackDetailsComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '90%',
      height: '90%',
      data: {
        title: this.translateService.instant('feedback'),
        update: 'Ok',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.feedback,
        back: false,
        standalone: true
      }
    });
  }
  updateAddOnDetails(party) {
    this.partyWithAddon = party
  }
}

@Pipe({ name: 'checkIconVisibility' })

export class CheckIconVisibilityPipe implements PipeTransform {
  transform(partyId: any, historyData: ContactHistoryItemDTO[]): boolean {
    if (historyData != null && historyData) {

      let selectedParty = historyData.filter(data => data.PartyId == partyId)[0];
      if (selectedParty && selectedParty.OrderCheckInfo != null && selectedParty.OrderCheckInfo.OrderItems.length > 0)
        return true;
      else
        return false;
    }
  }
}
@Pipe({
  name: 'showRecurringIcon'
})
export class ShowRecurringIconPipe implements PipeTransform {

  transform(party: any): boolean {
    if (!party.SpecialMeal)
      return false;
    else if (party.SpecialMeal) {
      if (party.SessionGroupId) {
        if (party.SpecialMeal.ActivitySessions && party.SpecialMeal.ActivitySessions.length > 0) {
          var bookedSession = party.SpecialMeal.ActivitySessions.filter(sm => sm.SessionGroupId == party.SessionGroupId);
          return (bookedSession && bookedSession.length > 1);
        }
      }
      else {
        return party.BookedSessions && party.BookedSessions.length > 1;
      }
    }
    return false;
  }
}

@Pipe({
  name: 'getGroupName'
})
export class GetGroupNamePipe implements PipeTransform {
  transform(party: any): any {
    if (party.SpecialMeal && party.SessionGroupId) {
      if (party.SpecialMeal.ActivitySessions && party.SpecialMeal.ActivitySessions.length > 0) {
        var sessionGroup = party.SpecialMeal.ActivitySessions.filter(sm => sm.SessionGroupId == party.SessionGroupId);
        if (sessionGroup) {
          return sessionGroup[0].SessionGroupName;
        }
      }
    }
    return '';
  }
}

