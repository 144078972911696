
<mat-tab-group class="tab-view {{tabsConfig.customClass}}" animationDuration="0" [ngClass]="{'default':tabsConfig.default,
'table-blocking': fromComponent === componentTypes.advancedBlock || fromComponent === componentTypes.Unblock, 'header-less-tabs' : fromComponent === componentTypes.reservation || fromComponent === componentTypes.waitlist || fromComponent === componentTypes.notification || fromComponent === componentTypes.quickSeat || !isHeaderAvailable}"
(selectedTabChange)="seletedTabChange($event)" [selectedIndex]="tabIndex" >
  <mat-tab *ngFor="let tab of tabsConfig.tabs;let i =index;trackBy:customTrackBy" [disabled]="tab.isDisabled">
    <ng-template *ngIf="!tab.isHidden" mat-tab-label>
      <i class="icon-{{tab.tabIcon}} tab-icon seat-icon-color" *ngIf="tab.tabIcon"></i>
      <span class="tab_label tab-label-icon"></span>
      <span class="tab-name">{{tab.tabLabel | translate}}</span>
      <span *ngIf="tab.tabCounter" class="button__badge">{{tab.tabCounter}}</span>
      <!-- {{partyService.selectedSize}} -->
    </ng-template>
    <ng-container #comp *ngComponentOutlet = "tab.tabComponent; injector: dynamicComponentInjector" ></ng-container>
  </mat-tab>
</mat-tab-group>
<div *ngIf="tabsConfig.isNextButtonAvailable">  
  <mat-dialog-actions class="dialog-container__actions">
    <div class="action-buttons">
      <activities-app-button [buttontype]="buttonValueCancel" (valueChange)='previous($event)'></activities-app-button>
      <activities-app-button *ngIf="!showSaveButton" [buttontype]="buttonValueNext" (valueChange)='next($event)'></activities-app-button>
      <activities-app-button *ngIf="showAddToCart && partyService.TotalCartBookingObj?.length && !cs.isIframeEnabled" [buttontype]="buttonValueAddCart" [ngbPopover]="selectCartGroup" [autoClose]="'outside'" placement="top"></activities-app-button>
      <activities-app-button *ngIf="showAddToCart && (!partyService.TotalCartBookingObj?.length || cs.isIframeEnabled) " [buttontype]="buttonValueAddCart" (valueChange)='addToCart(false)'></activities-app-button>     
      <activities-app-button *ngIf="showSaveButton" [hidden]="hideSaveAction" [buttontype]="buttonValueSave" (valueChange)='save($event)'></activities-app-button>
      <activities-app-button  [buttontype]="buttonValueBuyItem" (valueChange)='addToCart(true)'></activities-app-button>
    </div>
  </mat-dialog-actions>
</div>

<ng-template #selectCartGroup>
  <select-cart-group (selectCartGroup)="onCartGroupSelect($event)"></select-cart-group>
</ng-template>