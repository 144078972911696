import { Injectable } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailRoutes } from '../../retail-route';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { APIMemberBucketLinking, CreditCardIssuer, MappingScreen, MemberBucketLinking, MemberBucketPrimaryType, MemberBucketTypes, PaymentMethod, PostTypeDetails, PostTypeLinking, RevenuePostingPrimaryType, RevenuePostTypes, SubPropertyModel, TaxConfiguration } from '../../retail.modals';
import { PaymentMethods } from '../../shared/business/shared.modals'; 
import { Host } from '../../shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { BaseResponse } from '../../shared/shared.modal'; 

@Injectable()
export class PostTypeBusiness {
    constructor(private _localization: RetailLocalization
        , private PropertyInfo: RetailPropertyInformation
        , private _retailservice: RetailSetupService
        , private http: HttpServiceCall) {
    }

    public async GetActiveOutlets(): Promise<SubPropertyModel[]> {
        let response: BaseResponse<SubPropertyModel[]> = await this.http.CallApiAsync({
            callDesc: "GetOutletsByProperty",
            host: Host.retailManagement,
            method: HttpMethod.Get
        });
        let activeOutlets = [];
        if (response.result && response.result.length > 0) {
            activeOutlets = response.result.filter(r => r.isActive);
        }
        return activeOutlets;
    }

    public async GetPostTypeMapping(): Promise<PostTypeLinking[]> {
        let response: BaseResponse<PostTypeLinking[]> = await this.http.CallApiAsync({
            callDesc: "GetRevenuePostings",
            host: Host.retailManagement,
            method: HttpMethod.Get
        });
        return response.result || [];
    }

    
    public async GetPostTypeMappingForResortFinance(postType): Promise<PostTypeLinking[]> {
        let response: BaseResponse<PostTypeLinking[]> = await this.http.CallApiAsync({
            callDesc: RetailRoutes.GetPostTypeDetailsForResortFinance,
            host: Host.retailManagement,
            method: HttpMethod.Get,
            uriParams: { postingType : postType }
        });
        return response.result || [];
    }


    public async GetMultiPropertyPostTypeMapping(pmsPropCode: string): Promise<PostTypeLinking[]> {
        let response: BaseResponse<PostTypeLinking[]> = await this.http.CallApiAsync({
            callDesc: RetailRoutes.GetMultiPropertyRevenuePostings,
            host: Host.retailManagement,
            method: HttpMethod.Get,
            uriParams: { propCode : pmsPropCode }
        });
        return response.result || [];
    }

    public async GetMemberBucketMappingsDetails(): Promise<MemberBucketLinking[]> {
        let response: BaseResponse<MemberBucketLinking[]> = await this.http.CallApiAsync({
            callDesc: "GetMemberBucketMappingsDetails",
            host: Host.retailManagement,
            method: HttpMethod.Get
        });
        return response.result || [];
    }

    public async GetPrimaryTypeMapping(type: RevenuePostTypes,screen:string,IsPMS:boolean = false): Promise<RevenuePostingPrimaryType[]> {
        let primaryData: BaseResponse<any[]>;
        switch (type) {
            case RevenuePostTypes.Settlement:
            case RevenuePostTypes.Discount:
            case RevenuePostTypes.ServiceCharge:
            case RevenuePostTypes.Gratuity:
                primaryData = await this.http.CallApiAsync<any[]>({
                    host: Host.retailManagement,
                    callDesc: "GetActiveCategories",
                    method: HttpMethod.Get
                });
                let categories = [];
                if (primaryData.result && primaryData.result.length > 0) {
                    if (type == RevenuePostTypes.Discount) {
                        primaryData.result = primaryData.result.filter(r => r.eligibleForDiscount);
                    }
                    categories = primaryData.result.map(x => { return { id: x.id, name: x.name, linkType: type } as RevenuePostingPrimaryType })
                }
                return categories;
            case RevenuePostTypes.PaymentMethod:
                let [paymentMethod, cardIssuer] = await Promise.all([IsPMS ? this.GetFolioPaymentMethods() : this.GetPaymentMethods(), this.GetCardIssuers()]);
                let paymentMethods = [];
                if (paymentMethod && paymentMethod.length > 0) {                  
                    const paymentMethodsToExclude = [PaymentMethods.IDTECH, PaymentMethods.V1GiftCardIdTech, PaymentMethods.ExternalGiftCardIdTech,
                    PaymentMethods.AgilysysGiftCardIdTech, PaymentMethods.PendingSettlement, PaymentMethods.CreditCard];
                    if(screen.toUpperCase() === MappingScreen.PostTypeMapping.toUpperCase() || screen.toUpperCase() === MappingScreen.ResortFinancePostTypeMapping)
                    {
                        paymentMethodsToExclude.push(PaymentMethods.RoomCharge);
                    }
                    paymentMethod = paymentMethod.filter(x => x.isActive && !paymentMethodsToExclude.includes(x.paymentTypeId)); // This is done inorder to hide 'IDTech' methods from UI
 
                    paymentMethod.forEach((method) => {
                        if (this._localization.captions.shop.paymentMethods[method.paymentTypeId]) {
                            method.paymentMethod = this._localization.captions.shop.paymentMethods[method.paymentTypeId];
                        } else {
                            method.paymentMethod = method.paymentMethod;
                        }
                    });
                    paymentMethods = paymentMethod.map(x => { return { id: x.id, name: x.paymentMethod, linkType: type } as RevenuePostingPrimaryType });

                    if(cardIssuer && cardIssuer.length > 0){
                        cardIssuer.forEach(x => {
                            paymentMethods.push({id: x.id, name: x.displayName , linkType: RevenuePostTypes.Card } as RevenuePostingPrimaryType)
                        });
                    }
                }
                return paymentMethods;
            case RevenuePostTypes.Tax:
                primaryData = await this.http.CallApiAsync<TaxConfiguration[]>({
                    host: Host.retailManagement,
                    callDesc: "GetActiveTaxes",
                    method: HttpMethod.Get
                });
                let taxes = [];
                if (primaryData.result && primaryData.result.length > 0) {
                    if(!this.PropertyInfo.IsVATEnabled){
                    taxes = (primaryData.result.filter(x => x.outletId > 0) || []).map(x => { return { id: x.id, name: x.taxName, linkType: type, outletId: x.outletId } as RevenuePostingPrimaryType })
                    }
                    else{
                        taxes = (primaryData.result || []).map(x => { return { id: x.id, name: x.taxName, linkType: type, outletId: x.outletId } as RevenuePostingPrimaryType })
                    }
                }
                return taxes;
            case RevenuePostTypes.Surcharge:
                const surcharges = [];
                const  cardIssuers = await this.GetCardIssuers();
                if(cardIssuers && cardIssuers.length > 0){
                    cardIssuers.forEach(x => {
                        surcharges.push({id: x.id, name: x.displayName , linkType: RevenuePostTypes.Surcharge } as RevenuePostingPrimaryType)
                    });
                }
                return surcharges;

        }

    }


    public async CreatePostTypeMapping(input: PostTypeLinking[]): Promise<BaseResponse<boolean>> {
        return this.http.CallApiAsync({
            callDesc: "CreateRevenuePostMapping",
            host: Host.retailManagement,
            method: HttpMethod.Post,
            body: input
        });
    }

    public async DeletePostTypeMapping(input: number[]): Promise<BaseResponse<boolean>> {
        return this.http.CallApiAsync({
            callDesc: "DeleteRevenuePostMapping",
            host: Host.retailManagement,
            method: HttpMethod.Delete,
            body: input
        });
    }

    public async CreateMemberBucketMapping(input: APIMemberBucketLinking[]): Promise<BaseResponse<boolean>> {
        return this.http.CallApiAsync({
            callDesc: "CreateMemberBucketMapping",
            host: Host.retailManagement,
            method: HttpMethod.Post,
            body: input
        });
    }

    public async DeleteMemberBucketMapping(input: number[]): Promise<BaseResponse<boolean>> {
        return this.http.CallApiAsync({
            callDesc: "DeleteMemberBucketMapping",
            host: Host.retailManagement,
            method: HttpMethod.Delete,
            body: input
        });
    }

    public async GetPostTypeDetails(): Promise<BaseResponse<PostTypeDetails[]>> {
        return this.http.CallApiAsync({
            callDesc: "GetAllPostTypes",
            host: Host.retailManagement,
            method: HttpMethod.Get
        });
    }

    public async GetMultiPropertyPostTypeDetails(pmsPropCode: string): Promise<BaseResponse<PostTypeDetails[]>> {
        return this.http.CallApiAsync({
            callDesc: RetailRoutes.GetPostTypesByPropCode,
            host: Host.retailManagement,
            method: HttpMethod.Get,
            uriParams: { propCode : pmsPropCode }
        });
    }

    public async SyncPosting(): Promise<BaseResponse<PostTypeDetails[]>> {
        return this.http.CallApiAsync({
            callDesc: "SyncPosting",
            host: Host.retailManagement,
            method: HttpMethod.Get
        });
    }

    public async SyncMultiPropertyPosting(pmsPropCode): Promise<BaseResponse<PostTypeDetails[]>> {
        return this.http.CallApiAsync({
            callDesc: RetailRoutes.SyncMultiPropertyPosting,
            host: Host.retailManagement,
            method: HttpMethod.Get,
            uriParams: { propCode : pmsPropCode }
        });
    }

    public async GetPaymentMethods() : Promise<PaymentMethod[]>{
        let result : BaseResponse<PaymentMethod[]> = await this.http.CallApiAsync<any[]>({
            host: Host.payment,
            callDesc: "PaymentMethod",
            method: HttpMethod.Get
        });
        return result ? result.result : null;
    }

    private async GetFolioPaymentMethods() : Promise<PaymentMethod[]>{
        let result : BaseResponse<any> = await this.http.CallApiAsync<any[]>({
            host: Host.folio,
            callDesc: "GetAllPaymentMethod",
            method: HttpMethod.Get
        });
        let paymentMethod: PaymentMethod[] = [];
        paymentMethod = (<any>result.result).map(x => ({ ...x, paymentMethod: x.name }));
        return paymentMethod ? paymentMethod : null;
    }

    public async GetCardIssuers(): Promise<CreditCardIssuer[]>{
        let result : BaseResponse<CreditCardIssuer[]> = await this.http.CallApiAsync<any[]>({
                host: Host.payment,
                callDesc: "GetAllCreditCardIssuerType",
                method: HttpMethod.Get});
        return result ? result.result : null;
    }
}