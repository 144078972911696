import { ComponentDetails } from "@app/popup-module/models/popup.interface";
import { AuditlogComponent } from "@app/shared/components/auditlog/auditlog.component";
import { ComponentTypes } from "@app/shared/constants/commonenums";
import { popupDialogDimension } from "@app/shared/constants/globalConstants";
import { SettingsDTO } from "@app/shared/models/RestaurantDTO";
import { AuditableEntityType } from "@app/shared/models/SimpleAuditLogItemDTO";
import { Utilities } from '@app/shared/utilities/utilities';
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


export class BookingDetail extends Utilities {

  customGuestFieldsForBooking: any[] = [];
  _settings: SettingsDTO;
  mappedGuestFields: any;
  
  sortData: {
    sortBy: string,
    sortOrder: 'asc' | 'desc'
  } = {
      sortBy: 'FirstName',
      sortOrder: 'asc'
    }

  sortOptions = {
    guestName: 'GuestName',
    size: 'Size',
    status: 'State'
  }
  
  constructor(protected dialog: MatDialog, public ts: TranslateService) {
    super(dialog)
  }

  setCustomGuestField(partyContact) {
    this.customGuestFieldsForBooking = [];
    if (partyContact.ContactCustomFields) {
      this.customGuestFieldsForBooking = partyContact.ContactCustomFields
        .filter(currentField => this.mappedGuestFields.includes(currentField.CustomFieldId))
        .map(currentField => {
          currentField.CustomFieldName = this._settings.CustomGuestFields.find(masterFieldId => masterFieldId.Id === currentField.CustomFieldId)?.FieldName || ''
          return currentField;
        })
    }
  }
  setMappedCustomGuestFields(id: number) {
    this.mappedGuestFields = [];
    if(this._settings){
      let activityMapping = this._settings.customGuestFieldsMappingValidations.find(({ ActivityId }) => ActivityId === id);
      if (activityMapping) {
        this.mappedGuestFields = activityMapping.CustomFields.map(({ FieldId }) => FieldId);
      }
    }
  }
  showPartyAuditLog(attendee) {
    const showAlert = false;
    const title = this.ts.instant('auditlog') + " - " + attendee.ConfirmationCode;
    const popUpMessage = [{
      dialogTitle: title, showAlert, type: AuditableEntityType.Party, party: attendee
    }];

    const componentDetails: ComponentDetails = Utilities.setComponentDetails(AuditlogComponent, 'large', 'action', popUpMessage, popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.reservation, popupDialogDimension.createDialogMaxWidth, popupDialogDimension.createDialogMaxHeight, false, title);
  }
  sortClick(sortBy) {
    if (this.sortData.sortBy === sortBy) {
      if (this.sortData.sortOrder === 'asc') {
        this.sortData.sortOrder = 'desc';
      } else {
        this.sortData.sortOrder = 'asc';
      }
      this.sortData = { ...this.sortData };
    } else {
      this.sortData = {
        sortBy: sortBy,
        sortOrder: 'asc'
      }
    }
  }
}  