import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/shared/localization/Localization';
import { API, UI } from './tax-exempt-category.model';
import { TaxExemptCategoryDataService } from './tax-exempt-category.data.service';
import { of } from 'rxjs';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { BaseResponse } from '../../retail.modals';
import { Host } from '../../shared/globalsContant';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';

@Injectable()
export class TaxExemptCategoryBusiness {
  captions: any;

  constructor(
    private _localization: Localization,
    private _taxExemptCategoryDataService: TaxExemptCategoryDataService,
    private http: HttpServiceCall,
    private utils: RetailUtilities
  ) {
    this.captions = this._localization.captions;
  }

  public async getAllEntries(_includeInactive: boolean): Promise<UI.TaxExemptCategory[]> {
    let apiModels: API.TaxExemptCategory[] = await this._taxExemptCategoryDataService.getAllTaxExemptCategories(_includeInactive);
    return apiModels.map(x => this.uiMapper(x));
  }

  public async getEntryById(id: number): Promise<UI.TaxExemptCategory> {
    let apiModels: API.TaxExemptCategory = await this._taxExemptCategoryDataService.getTaxExemptCategory(id);
    return this.uiMapper(apiModels);
  }

  public async create(TaxExemptCategory: UI.TaxExemptCategory): Promise<UI.TaxExemptCategory[]> {
    let apiModels: API.TaxExemptCategory[] = await this._taxExemptCategoryDataService.createTaxExemptCategory(this.APIMapper(TaxExemptCategory));
    return apiModels.map(x => this.uiMapper(x));
  }

  public async update(TaxExemptCategory: UI.TaxExemptCategory): Promise<UI.TaxExemptCategory[]> {
    let apiModels: API.TaxExemptCategory[] = await this._taxExemptCategoryDataService.updateTaxExemptCategory(this.APIMapper(TaxExemptCategory));
    return apiModels.map(x => this.uiMapper(x));
  }

  public async validateDelete(id: number): Promise<any[]> {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
      callDesc: 'GetTaxesByTaxExemptCategoryId',
      host: Host.folio,
      method: HttpMethod.Get,
      body: undefined,
      uriParams: { id }
    });
    this.utils.ToggleLoader(false);
    return response.result;
  }

  public async delete(id: number): Promise<void> {
    await this._taxExemptCategoryDataService.deleteTaxExemptCategory(id);
  }

  public async getNextListOrder(): Promise<number> {
    try {
      return await this._taxExemptCategoryDataService.getNextListOrder();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async getActiveTaxExemptCategory(_includeInactive: boolean) {
    let apiModels: API.TaxExemptCategory[] = await this._taxExemptCategoryDataService.getAllTaxExemptCategories(_includeInactive);
    let result = !apiModels ? [] : apiModels.map(x => { return { id: x.id, viewValue: x.code } });
    return of(result);
  }
  private uiMapper(TaxExemptCategoryAPIModel: API.TaxExemptCategory): UI.TaxExemptCategory {
    return {
      id: TaxExemptCategoryAPIModel.id,
      code: TaxExemptCategoryAPIModel.code,
      name: TaxExemptCategoryAPIModel.name,
      listOrder: TaxExemptCategoryAPIModel.listOrder,
      isActive: { value: TaxExemptCategoryAPIModel.isActive, isDisabled: false },
    } as UI.TaxExemptCategory;
  }


  updateListOrderOnDrag(eventObj): Promise<API.TaxExemptCategory[]> {
    const TaxExemptCategoryTypeData: API.TaxExemptCategory = {
      id: eventObj.Obj.dragdata.id,
      code: eventObj.Obj.dragdata.code,
      name: eventObj.Obj.dragdata.name,
      listOrder: eventObj.Obj.dragdata.listOrder,
      isActive: eventObj.Obj.dragdata.isActive.value,
    };
    return this._taxExemptCategoryDataService.updateTaxExemptCategory(TaxExemptCategoryTypeData);
  }
  private APIMapper(TaxExemptCategory: UI.TaxExemptCategory): API.TaxExemptCategory {
    return {
      id: TaxExemptCategory.id,
      code: TaxExemptCategory.code,
      name: TaxExemptCategory.name,
      listOrder: TaxExemptCategory.listOrder ? TaxExemptCategory.listOrder : 0,
      isActive: typeof TaxExemptCategory.isActive === 'object' ? TaxExemptCategory.isActive?.value : TaxExemptCategory.isActive,
    } as API.TaxExemptCategory;
  }

}
