import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '@app/app.service';
import { CacheService } from '@core/services/cache.service';
import hoursList from '@data/overridehours.json';
import minutesList from '@data/overrideminutes.json';
import { SettingsDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'override-remainingtime',
  styleUrls: ['./override-remainingtime.scss'],
  templateUrl: './override-remainingtime.html'
})

export class OverrideRemainingTimeComponent implements OnInit, OnDestroy {

  @Input() data: any;

  minHour: any = 0;
  maxHour: any = 10;
  minutesDelta: any = 5;
  PossibleHours: any;
  PossibleMinutes: any;
  Hours: any;
  Minutes: any;
  SeatedParty: any;
  _settings: SettingsDTO = {} as SettingsDTO;
  subscriptions: Subscription = new Subscription();

  constructor(private appService: AppService, public partyService: PartyService, public cs: CacheService) {
    this.subscriptions.add(cs.settings.subscribe(settings => {
      this._settings = settings;
    }));
  }
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.data && this.data.seatParty) {
      this.SeatedParty = this.data.dashboardData;
      if (this.data.dashboardData.remainingTime > 0) {
        this.SetTime(this.data.dashboardData.remainingTime / 60);
      }
      else {
        this.Hours = "0";
        this.Minutes = "0";
        this.partyService.partyOverrideRemainingTimeInMins = (Number(this.Hours) * 60) + Number(this.Minutes);
      }
      this.PossibleHours = hoursList.Hours;
      this.PossibleMinutes = minutesList.Minutes;
    }
  }


  SetTime(totalMinutes) {
    var hours = totalMinutes / 60;
    if (hours > this.maxHour) {
      hours = this.maxHour;
    }
    if (hours < this.minHour) {
      hours = this.minHour;
    }
    var minutes = totalMinutes % 60;
    minutes = minutes - minutes % this.minutesDelta;
    this.Hours = Math.floor(hours).toString();
    this.Minutes = Math.floor(minutes).toString();
    this.partyService.partyOverrideRemainingTimeInMins = (Number(this.Hours) * 60) + Number(this.Minutes);
  }

  setSelectedHour(option) {
    this.partyService.partyOverrideRemainingTimeInMins = (Number(this.Hours) * 60) + Number(this.Minutes);
  }

  setSelectedMinute(option) {
    this.partyService.partyOverrideRemainingTimeInMins = (Number(this.Hours) * 60) + Number(this.Minutes);
  }

}
