<div class="table-timeline__row table-timeline__row--body" [style.flexBasis]="expandMode ? 'auto' : sessionWidth+'px'">
<div class="table-timeline__row--drag-handler" [class.nohover]="dragHandlerService.lockAxis" [class.hover]="!dragHandlerService.lockAxis && dragHandlerService.dragStarted && dragHandlerService.hoveredRowId === rowData.Id" *ngIf="dragHandlerService.dragStarted" [attr.rowId]="rowData.Id" [attr.rowName]="rowData.Name">
</div>
    <div class="table-timeline__switch--column" [ngStyle]="{'backgroundColor': (rowData.CategoryId | activityColor)?.backgroundColor || '#FFFFFF' }" [class.header-size]="expandMode">
        <span class="userprofile__instructor-name" [matTooltip]="rowData | instructorNameFormatter" >
          {{rowData | instructorNameFormatter}}</span>
        <div class="expand-icon" *ngIf="showExpandButton">
          <img *ngIf="!expandMode" (click)="changeExpandMode()"
            src="../../../../assets/images/unfold_more.svg" alt="">
          <img *ngIf="expandMode" (click)="changeExpandMode()"
            src="../../../../assets/images/unfold_less.svg" alt="">
        </div>
      </div>
      <div style="display: grid;width: 100%;" [rowColumnGenerator]="timeArrayGenerated.length" [rowWidth]="rowWidth">
        <ng-container *ngFor="let session of allSessions; let i =index"  >
          <div [gridTemplateDirective]="session.rows" [isExpandable]="showExpandButton" [isExpanded]="expandMode" [defaultMaxRowDisplay]="session.defaultMaxRowDisplay" [sessionWidth]="sessionWidth"
           class="group innerCard" style="display: grid;grid-row: 1 / -1;" [style.gridRowStart]="session.gridColumnStart"
            [style.gridRowEnd]="session.gridColumnEnd" [style.gridTemplateRows]="session | templateColumnsConfig: columnWidth" [class.group-scroll]="!dragHandlerService.dragStarted">
              <ng-container *ngFor="let subSession of session.items">
                  <div class="session-card-container {{'height_'+session.rows}}" [class.session-card-size]="expandMode && session.defaultMaxRowDisplay"
                    [session]="session"
                    [style.gridRowStart]="subSession.gridColumnStart - session.gridColumnStart + 1"
                    [style.gridRowEnd]="subSession.gridColumnEnd - session.gridColumnStart + 1"
                    (promptBlockSessionEvent)="promptBlockSession(rowData, subSession)"
                    (modifySessionEvent)="modifySession(rowData, subSession)" (cancelSessionEvent)="cancelSession(rowData, subSession)"  session-card [data]="subSession"
                    [rowData]="rowData" [selectedViewId]="selectedViewId" [rows]="session.rows" [expandMode]="expandMode" [timeArrayGenerated]="timeArrayGenerated"
                    [serviceData]="partyService.allWeekActivitySessions[subSession.uniqId]" [switchAxis]="switchAxis"
                    [headLineData]="partyService.allSessionsHeadLines[subSession.SessionId]"
                    (addBookingEvent)="addBookingToSlot(rowData,subSession.DateTime, i)"
                    (blockStaffEvent)="blockStaff(rowData,subSession)"
                    (deleteBlockStaffEvent)="deleteStaffBlock(rowData,subSession)"
                    (click)="activitySelected(subSession, rowData)">
                  </div>
                  <div class="show-more-label px-2" style="display: grid;grid-row: 1 / -1;" title="+ {{session.defaultMaxRowDisplay}}" [style.gridColumn]="(session.rows - session.defaultMaxRowDisplay) + 1 +'/ span 1'" *ngIf="showExpandButton && !expandMode && session.defaultMaxRowDisplay && session.rows > session.defaultMaxRowDisplay" (click)="changeExpandMode()">
                    + {{session.defaultMaxRowDisplay}}
                  </div>
              </ng-container>
            </div>
        </ng-container>
      </div>
</div>