import { TranslateService } from '@ngx-translate/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Compiler, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { MachineName } from '@app/common/shared/shared.modal';
import { DEFAULT_LANGUAGE_CODE, DEFAULT_LANGUAGE_ID } from '@app/shared/constants/globalConstants';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { buttonTypes, ComponentTypes } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { AuthService } from '@core/services/auth.service';
import { CacheService } from '@core/services/cache.service';
import { LoginService } from '@core/services/login.service';
import { MultilingualService } from '@core/services/multilingual.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { AuthenticationResultDTO, RestaurantAvailableForLoginDTO, VerificationResultDTO } from '@models/AuthenticationResultDTO';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { SignalrService } from '@services/signalr.service';
import { LoginFunction } from '@utilities/login-function';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';
import moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';

@Component({
  selector: 'login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss']
})
export class LoginContainerComponent implements OnInit {

  currentYear:number = (new Date()).getFullYear();

  constructor(public translateService: TranslateService) {
  }

  ngOnInit(): void {
  }

}
