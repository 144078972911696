export enum UserAccessBreakPoints {
	TOURNAMENTS = 13015,
	USERSETUP = 2300,
	TOURNAMENTFORMAT = 13000,
	TOURNAMENTPACKAGE = 13005,
	TOURNAMENTCOMPONENTS = 13010,
	TOURNAMENTPLAYERS = 13020,
	TOURNAMENTTEETIMES = 13025,
	TOURNAMENTSCORING = 13030,
	TOURNAMENTPLANCOMPONENTS = 13035,
	CADDYSETUP = 14000,
	TOURNAMENTLISTREPORT = 9050,
	SCORECARDREPORT = 9055,
	CADDYSCHEDULE = 14005,
	RAINCHECKREDEEM = 14500,
	MEMBERPROFILE = 16000,
	MEMBERSTATUS = 16005,
	CREDITBOOK = 16010,
	HOLDTEETIME = 10020,
	LESSONLOCATION = 12045,
	RELEASEHOLD = 10070,
	SQUEEZETEETIME = 10025,
	RATETEETIME = 10035,
	BOOKTEETIME = 10015,
	ROLESETUP = 2305,
	RATETYPE = 12020,
	PLAYERTYPE = 12015,
	GOLFSYSTEMSETUP = 12001,
	SYSTEMSETUP = 12001,
	SPASYSTEMSETUP = 2085,
	CODELINKING = 12025,
	COURSE = 12000,
	ALLOCATIONBLOCKASSIGNMENT = 12065,
	RATESETUP = 12055,
	RAINCHECKSETUP = 12035,
	TeeTimeAllocationBlock = 12010,
	CourseCommentSetup = 12040,
	TimeIntervalSetup = 12060,
	TeeColor = 12005,
	TeeSheet = 10010,
	InstructorsSetup = 11000,
	ClearPlayers = 10060,
	Copy = 10045,
	CopytoMany = 10050,
    Move = 10040,
    DragDrop = 10145,
	FROSTDELAY = 10030,
	InstructorSchedule = 11005,
	DefaultUserConfig = 11505,
	CUSTOMFIELD = 12050,
	INSTRUCTORSCHEDULEREPORT = 9070,
	CANCELPLAYERS = 10055,
	TeeTimeSearch = 10080,
	RAINCHECKISSUE = 10140,
	CADDYTYPE = 12030,
	TeeTicketReport = 9025,
	VIEWWAITLIST = 10090,
	ADDWAITLIST = 10085,
	DELETEWAITLIST = 10095,
	CONVERTWAITLIST = 10100,
	LOSTANDFOUND = 11510,
	ROUNDSBYRATETYPE = 9090,
	ROUNDSBYPLAYERTYPE = 9095,
	TeeTimeUtilizationReport = 9075,
	RAINCHECKISSUEDREPORT = 9030,
	CancellationTeeTime = 9005,
	NoShowTeeTime = 9010,
    WaitList = 9015,
    BagTags = 9020,
    BOOKLESSON = 10115,
    CANCELLESSON = 10120,
	BULKMOVE=10165,
	BULKCANCEL=10170,
	BULKCLEAR= 10175,
	BULKTEETIMESBOOK= 10155,
	BULKTEETIMESEDIT=10160,
	PRINT = 10075,
	GROUPTEETIMES=10150,
	SHOP=10065,
	TEETIMEREFUND=10135,
    USERROLECONFIGURATION = 2310,
    DAYEND = 11500,
    TRANSACTIONLOGREPORT = 9120,
	PLAYERPROFILE = 700,
	REOPENTRASACTION = 5000,
	ADD_DEPOSIT = 10105,
	COMMISIONSETUP = 800,
	INVENTORYMANAGEMENT = 8722,
	CLUBAGREEMENT=15045,
	CARTAGREEMENT=15050,
	SHOEAGREEMENT=15055,
	RETAILSALECHIT=15060,
	PLAYEROFFERS = 150065,
	FOLIOMENU = 90070,
	PLAYERPAYRELEASE = 15095,
	REDEEMRETAILMULTIPACK = 3150
}
