<mat-form-field class="date-picker-width"  [ngClass]="className" [floatLabel]="floatLabel" [formGroup]="form" appearance="legacy" (click)="onControlClick()"  >
    <mat-label style="cursor:pointer" [attr.LiteralID]="placeHolderId">{{placeHolder}}</mat-label>
  <input matInput [min]="minDate" dateRestricter [max]="maxDate" [matDatepicker]="datePicker" 
  [disabled]="isDisabled" (dateChange)="dateChanged($event)" (input)="inputDateChanged($event)" [placeholder]="placeHolderFormat" [formControlName]="formControlName" [hidden]="hidden" [required]="isDateRequired" [attr.automationId]="automationId">
 <mat-datepicker-toggle matSuffix [for]="datePicker" >
   <i  aria-hidden="true"  class="icon-calendar" matDatepickerToggleIcon [attr.automationId]="iconAutomationId" ></i>
 </mat-datepicker-toggle>
 <mat-datepicker #datePicker></mat-datepicker>
 <mat-error *ngIf="form.controls[formControlName].hasError('matDatepickerParse')">{{captionsLocalization.dateFormat}}</mat-error>
 <mat-error *ngIf="(form.controls[formControlName].hasError('required') || form.controls[formControlName].hasError('incorrect')) && !form.controls[formControlName].hasError('matDatepickerParse')"  [attr.LiteralID]="errorMessageId">{{errorMessage}}</mat-error>
 <mat-error *ngIf="form.controls[formControlName].hasError('matDatepickerMin')">{{captionsLocalization.minimum}} {{placeHolder}}</mat-error>
 <mat-error *ngIf="form.controls[formControlName].hasError('matDatepickerMax') && !form.controls[formControlName].hasError('matDatepickerMin')">{{captionsLocalization.maximum}} {{placeHolder}}</mat-error>
</mat-form-field>
