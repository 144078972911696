import { Directive, Input, HostListener, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appDisableDoubleClick]'
})
export class DisableDoubleClickDirective implements OnInit, OnDestroy {

  throttleTime = 5;  // change this time to increase the throttling time 
  @Input('appDisableDoubleClick')
  set setThrottleTime(value: number) {
    this.throttleTime = value ? value : this.throttleTime;
  }

  @Output() throttleClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  ngOnInit() {
    this.subscription = this.clicks.pipe(
      throttleTime(this.throttleTime) // if we need initial call when user clicks instantly and last call on continuous click please use this {leading: true, trailing: true}
    ).subscribe(e =>
      this.throttleClick.emit(e)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event']) clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
