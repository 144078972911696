import { Injectable } from "@angular/core";
import { PROPERTY_CONFIGURATION_SETTINGS } from "src/app/app-constants";
import { environment } from "src/environments/environment";
import { API } from 'src/app/common/Models/property-settings.model';
import { jasperConfig } from "../components/menu/menu.constant";
import { PropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { JasperServerRequestHeaders, Jaspersoftconstants } from "../JaspersoftReport/jasperreport.model";

@Injectable({
    providedIn: 'root'
})
export class JasperServerCommonDataService {
    constructor(private PropertySettingService: PropertySettingDataService) { }

    //Get jasper BaseURL eaither Property Config or Environment
    async GetJasperServerBaseURL(): Promise<any> {
        try {
            let jasperServerURL: string = environment["JasperServerURL"];
            let propertyConfig = await this.PropertySettingService.GetAllPropertyConfigurationSettings({
                configurationName: PROPERTY_CONFIGURATION_SETTINGS,
                propertyId: this.getPropertyIdBySession(),
                productId: 0
            } as API.PropertyConfigurationSettings<any>);

            if (propertyConfig != null) {
                let jasperUrlKey = propertyConfig.configValue[jasperConfig.PROPJSCONFIG_JASPERREPORTSAPPLIANCEURL];
                jasperServerURL = jasperUrlKey != null || jasperUrlKey != undefined ? jasperUrlKey : environment["JasperServerURL"];
            }
            return jasperServerURL;
        }
        catch (error) {
            console.log('Error Occurred While GetJasper Server BaseURL');
        }
    }
    async GetJasperServerHeader(): Promise<JasperServerRequestHeaders>{   
        let header : JasperServerRequestHeaders = {
            "Content-Type": "application/json",
            "pp": await this.GetJWTTokenForJasper(),
            "tokenProvider": Jaspersoftconstants.tokenProvider
        }
        return header;
    }
    async GetJWTTokenForJasper(){
        let token = '';
        let adb2cAuthFeatureEnabled = false;
        const configObject_sessionstore = JSON.parse(sessionStorage.getItem("ADB2CAuthenticationEnabled"));
        if(configObject_sessionstore != null && configObject_sessionstore != undefined && (configObject_sessionstore === true || configObject_sessionstore == 'true')){
            adb2cAuthFeatureEnabled = true;
        }
        else{
            const configObject_localstore = JSON.parse(localStorage.getItem("ADB2CAuthenticationEnabled"));            
            if(configObject_localstore != null && configObject_localstore != undefined &&  ( configObject_localstore === true  || configObject_localstore == 'true')){
    
                    adb2cAuthFeatureEnabled = true;            
            }
        }
       
        await Promise.all([
          (async () => {
              if (adb2cAuthFeatureEnabled == false) {
                  token = sessionStorage.getItem("_jwt");
              } else {
                  token = await this.PropertySettingService.AddJwtForJasper(this.FrameRequestConnectorModel());
              }
          })()
      ]);
      return token;
    }
    FrameRequestConnectorModel(): API.RequestConnector{
        var model : API.RequestConnector = {
          client_id : "",
          displayName : "",
          email : "",
          givenName : "",
          objectId : "",
          step : "",
          surname : "",
          ui_locales : "",
          userToken : Jaspersoftconstants.jwtidentifier + sessionStorage.getItem("_jwt")
        } as API.RequestConnector;
        return model;
      }
    //Get Property Id based on session strorage
    getPropertyIdBySession() {
        let propertyInfo = sessionStorage.getItem('propertyInfo');
        const propertyId = Number(propertyInfo.split(';').map(x => ({ key: x.split('=')[0].trim(), value: x.split('=')[1] })).find(x => x.key === 'PropertyId').value);
        return propertyId;
    }
}