import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'isAllSelected'
})
export class IsAllSelectedPipe implements PipeTransform {
    transform(allData, key, isModified) {
        let returnvalue = allData && allData.length;
        if (returnvalue) {
            const checked = allData.map(x => key ? x[key] : x.checked).filter(x => x);
            returnvalue = checked.length === allData.length;
            return returnvalue;
        }
        else{
            return false;
        }    
    }
}