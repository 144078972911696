
import { Injector } from "@angular/core";
import _ from "lodash";
import { PartyService } from "../party.service";



export class ActivitiesRetailTransaction {

  private _partyService;
  constructor(injector: Injector) {

    if (injector) {
      this._partyService = injector.get(PartyService);
    }

  }

  async GetRetailTransaction(transId: Number) {
   const data = await this._partyService.GetRetailTransaction(transId).toPromise();
   return data;
  }

}
