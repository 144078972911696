import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[numMaxlength]'
})
export class NummaxLength {
  constructor(el: ElementRef) {
    let test = el.nativeElement;
  }
}
