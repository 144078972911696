import { RguestCommunication } from '../common/rguest-communication';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../../app-service';
import { Injectable } from '@angular/core';
import { Localization } from '../../localization/localization';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';

export class PrintManagerCommunication extends RguestCommunication {

  constructor(route: string,httpclient: HttpClient, localization:Localization, utilities: CommonUtilities, PropertyInfo: CommonPropertyInformation, appService: AppService) {
    super(route,httpclient, localization, utilities, PropertyInfo, appService);
}
}
