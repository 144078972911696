import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss']
})
export class UploadPhotoComponent implements OnInit {
  @Output() setPhoto = new EventEmitter<any>();
  @Input() imageUrl;
  @Input() guestPhoto;
  url = '';
  constructor(private imageCompress: NgxImageCompressService) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes.imageUrl){
      this.getPhotoUrl(changes.imageUrl.currentValue)
    }
  }
  compressFile() {
    let editUrl = ''
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      this.imageCompress.compressFile(image, orientation, 75, 50).then(
        result => {
          this.url = result
          editUrl =  result.replace(/^data:image.+;base64,/, '')
          this.setPhoto.emit(editUrl);
        }
      );
    });
  }

  deleteImage() {
    this.url = '';
    this.setPhoto.emit(this.url);
  }
  getPhotoUrl(url) {
    if(url && url !== ''){
      this.url  = `data:image/png;base64,${url}`;
    }
  }
}
