import { PaymentManagerRoutingModule } from './payment-manager-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentManagerComponent } from './payment-manager.component';
import { PaymentManagerSharedModule } from './shared/payment-manager-shared.module';



@NgModule({
  declarations: [PaymentManagerComponent],
  imports: [
    CommonModule,
    PaymentManagerRoutingModule,
    PaymentManagerSharedModule
  ]
})
export class PaymentManagerModule { }
