import { Injectable, OnDestroy } from '@angular/core';
import { AppService } from '@app/app.service';
import { TableBlockBy, TableBlockingRuleFrequencyType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { NamedEntityDTO, StandaloneTableDTO } from '@models/RestaurantDTO';
import { TableBlockingRuleDTO } from '@models/TableBlockingDTO';
import { TimeRangeDTO } from '@models/TimeRangeDTO';
import { HttpService } from '@services/http.service';
import { SettingsService } from '@services/settings.service';
import { Utilities } from '@utilities/utilities';
import _, { groupBy } from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { PartyService } from './party.service';


@Injectable({
  providedIn: 'root'
})
export class TablesService implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  selectedFloorPlan: Subject<any> = new Subject<any>();
  selectedFloorPlanPopup: Subject<any> = new Subject<any>();
  ChangeLayout: Subject<any> = new Subject<any>();
  tabData: any;
  selectedArea: number = null;
  reservations: any = [];
  timerange: TimeRangeDTO = {} as TimeRangeDTO;
  partyUnselectedEvent: Subject<any> = new Subject<any>();
  tableBlockingRules$ = new BehaviorSubject<TableBlockingRuleDTO[]>(null);
  standAloneTables$ = new BehaviorSubject<StandaloneTableDTO[]>([]);
  loadedicons$ = new BehaviorSubject<any>(false);
  standAloneTables: StandaloneTableDTO[] = [];
  selectedTableBeforeAction$ = new BehaviorSubject<StandaloneTableDTO>(null);
  overallTableBlocking: TableBlockingRuleDTO[] = [];
  blockingdataChange$ = new BehaviorSubject<TableBlockingRuleDTO[]>(null);
  selectedShift: number;
  restaurantShiftId: number;
  shiftSelectedForBlockingRule: number;
  isCustomFloorSelected: boolean = false;;
  selectedFloorPlanId: number = null;
  selectedFloorPlanPopupId: number = null;
  deactivateTableObject: Subject<any> = new Subject<any>();
  viewFullScreenServers$ = new BehaviorSubject<boolean>(null);
  dragedTablesId: Subject<any> = new Subject<any>();
  selectedTabIndex:number=0;
  overallTableBlockingRules: TableBlockingRuleDTO[] = [];
  tableDragStart: Subject<any> = new Subject<any>();
  seatingTypeIds = [];
  partyDateTime = '';
  isFirstTime = true;

  constructor(public httpService: HttpService, private appService: AppService, private cs: CacheService, public ss: SettingsService,private ps:PartyService) {
  }


  getUpdateBlockingRules(rules) {
    let blockedTablesList: TableBlockingRuleDTO[] = [];
    if (rules) {
      rules.forEach(element => {
        if (this.IsValidDate(element, this.appService._headerDate)) {
          if(element.Shifts)
          {
          if (this.isValidShift(element.Shifts)) {
            blockedTablesList.push({ ...element });
          }
        }else{
          blockedTablesList.push({ ...element });
        }
              }

      });
    }
    if (this.CheckCustomShift(this.appService._headerDate) && blockedTablesList.length > 0) {
      blockedTablesList = blockedTablesList.filter(x => x.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay)
    }
    this.tableBlockingRules$.next(blockedTablesList);
    return blockedTablesList;
  }
  getBlocks() {
    this.subscriptions.add(this.ss.GetBlocks().subscribe((result) => {
      var resultdata = result.Payload.filter(data => {
        return data.StartDate != null && data.EndDate != null && (!data.ActivitySessionId || data.ActivitySessionId == null);
      });
      this.overallTableBlocking = resultdata;
      this.ss.blockingdataChange$.next(this.overallTableBlocking);
      this.blockingdataChange$.next(resultdata);
      this.overallTableBlockingRules = resultdata;
    }));
  }

  
  getActivityBlocks(){
    this.subscriptions.add(this.ss.getBlockedActivities().subscribe(data =>{
      
      this.ps.activityBlockingChange$.next(this.setActivityBlock(data.Payload))
    }));
      
  }


  setActivityBlock(data){
    var blockRule = groupBy(data, d=>d.CustomizationId);
    var  activityBlockingRule ={};
    Object.keys(blockRule).forEach(key=>{
      activityBlockingRule[key] = blockRule[key][0];
    })
    return ({ActivityBlockingRule:activityBlockingRule});
  }

  updatedData(rules) {
    this.overallTableBlocking = rules;
    this.ss.blockingdataChange$.next(rules);
  }


  IsValidDate(blockedTable: TableBlockingRuleDTO, date: Date) {
    let selectedDate = date.setHours(0, 0, 0, 0);
    if (new Date(blockedTable.StartDate).setHours(0, 0, 0, 0) <= selectedDate && new Date(blockedTable.EndDate).setHours(0, 0, 0, 0) >= selectedDate) {
      if (blockedTable.Frequency.Type == TableBlockingRuleFrequencyType.EveryDay || blockedTable.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay) {
        return true;
      }
      else if (blockedTable.Frequency.Type == TableBlockingRuleFrequencyType.EveryWeekDay) {
        if (blockedTable.Frequency.DayOfWeek == (date).getDay()) {
          return true;
        }
      }
    }
    return false;
  }

  isValidShift(Shifts: NamedEntityDTO[]) {
    let isvalAvilable = false;
    if (!this.restaurantShiftId || this.restaurantShiftId == 0) {
      return true;
    }
    Shifts.forEach(val => {
      if (val.Id == this.restaurantShiftId) {
        isvalAvilable = true;
      }
    });

    return isvalAvilable;
  }

  getBlockingRulesFortheDate(date) {
    let blockedTablesList: TableBlockingRuleDTO[] = [];

    if(!date){
      return blockedTablesList;
    }

    if(!this.overallTableBlocking || this.overallTableBlocking?.length == 0) {
      this.overallTableBlocking = this.ss.blockingdataChange$.value ? this.ss.blockingdataChange$.value : [];
    }

    if (this.overallTableBlocking && this.overallTableBlocking.length > 0) {
      this.overallTableBlocking.forEach(element => {
        if (this.IsValidDate(element, date)) {
          blockedTablesList.push({ ...element });
        }
      });
    }
    if (this.CheckCustomShift(date) && blockedTablesList.length > 0) {
      blockedTablesList = blockedTablesList.filter(x => x.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay)
    }
    this.tableBlockingRules$.next(blockedTablesList);
    return blockedTablesList;
  }

  CheckCustomShift(date) {
    let isCustomShift = false;
    let todaysShifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, date)
    if (todaysShifts && todaysShifts.length > 0) {
      todaysShifts.forEach(shift => {
        if (shift.Date != null) {
          isCustomShift = true;
        }
      });
    }
    return isCustomShift;
  }

  getBlockingRulesForShift(blockingrules : TableBlockingRuleDTO[], shiftId : number ) {
    let requiredBlockingRules = [];
    
    let currentdatetime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    if(blockingrules){
      blockingrules.forEach(rule => {
        if (rule.Frequency.Type == TableBlockingRuleFrequencyType.EveryDay) {
          if (rule.Shifts) {
            requiredBlockingRules.push(rule);
          }
          else {
            if (this.checkisTimeBetween(rule, currentdatetime)) {
              requiredBlockingRules.push(rule);
            }
          }

      }
      else if (rule.Frequency.Type == TableBlockingRuleFrequencyType.EveryWeekDay) {
        let shifts = this.cs.settings.value.Shifts.filter(shift => shift.DayOfWeek == rule.Frequency.DayOfWeek);
        if (rule.BlockBy == TableBlockBy.ByShift) {
          if (shifts.length == rule.Shifts.length) {
          requiredBlockingRules.push(rule);
        }
        else {
          rule.Shifts.forEach(shift => {
            if (shiftId == shift.Id) {
              requiredBlockingRules.push(rule);
            }
          })
        }
      }
      else {
        if (rule.DayOfWeek == this.appService._headerDate.getDay() && this.checkisTimeBetween(rule, currentdatetime)) {
          requiredBlockingRules.push(rule);
        }
      }
    }
      else if (rule.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay) {
        let DateSelected = new Date(rule.Frequency.Date);
        let todaysShifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, DateSelected)
        if (rule.BlockBy == TableBlockBy.ByShift) {
          if (todaysShifts.length == rule.Shifts.length) {
          requiredBlockingRules.push(rule);
        }
        else {
          rule.Shifts.forEach(shift => {
            if (shiftId == shift.Id) {
              requiredBlockingRules.push(rule);

            }
          })
        }
        } else {
          if (this.checkisTimeBetween(rule, currentdatetime)) {
            requiredBlockingRules.push(rule);
          }
        }
      }
    });
  }
    return requiredBlockingRules;
  }

  checkisTimeBetween(rule, timetocheck) {
    if (rule.EffectiveRange) {
      var sDate = _.cloneDeep(timetocheck);
      var eDate = (rule.EffectiveRange[0].End > rule.EffectiveRange[0].Start) ? _.cloneDeep(timetocheck) : new Date(_.cloneDeep(timetocheck).setDate(_.cloneDeep(timetocheck).getDate() + 1));
      var StartDate = this.getDateTime(sDate, rule.EffectiveRange[0].Start);
      var EndDate = this.getDateTime(eDate, rule.EffectiveRange[0].End);
      if (StartDate <= timetocheck && EndDate >= timetocheck) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  getDateTime(date, time){
      const yy = new Date(date).getFullYear();
      const mm = new Date(date).getMonth() + 1;    
      const dd = new Date(date).getDate();
      var formattedDate = new Date(mm + '-' + dd + '-' + yy);
      formattedDate.setHours(time.split(':')[0]);
      formattedDate.setMinutes(time.split(':')[1]);
      return formattedDate;
  }
  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

}
