<form [formGroup]="GuestRoomSearchForm" class="acctSearchForm">
    <div class="acct-search-cont">
        <div>
            <mat-form-field *ngIf="IsMulitPropertyFeatureEnabled" [floatLabel]="floatLabel">
                <mat-select placeholder="{{captions.SelectProperty}}" formControlName="propertySelection">
                  <mat-option *ngFor="let property of propertyList" [value]="property.PropertyName"
                    (click)="selectProperty(property)">
                    {{ property.PropertyName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <mat-radio-group formControlName="searchType" *ngIf="ShowSearchTypeSelection">
            <div class="d-flex radio-padding-bottom searchType-cont" *ngFor="let type of searchTypeList">
                <mat-radio-button (click)="selectSearchType(type)" [checked]="type.checked" [value]="type.id">
                </mat-radio-button>
                <div class="d-flex voucher-details">{{type?.name}}</div>
            </div>
        </mat-radio-group>
        <div class="rain-check-search">
            <mat-form-field class="rc-searchbox" [floatLabel]="floatLabelNever">
                <input [type]="searchFieldType" [placeholder]="searchBoxPlaceHolder" #roomNumber matInput formControlName="roomNumber"
                    (input)="CheckRoomNumber()" [maxlength]="searchFieldType == 'password'? 1000 : 15" autocomplete="off" (keydown.enter)="searchGuestRoom()">
                </mat-form-field>
            <div class="icon-section" *ngIf="GuestRoomSearchForm?.controls?.searchType?.value != 2">
                <i  aria-hidden="true" class="icon-search icon-Search" (click)="searchGuestRoom()"></i>
            </div>
        </div>
        <div class="room-search-result-cont">
            <mat-radio-group formControlName="guestRoomSelection" *ngIf="!IsHotelCompPayment">
                    <!-- <div > -->
                        <div class="roomAccount-cont" *ngFor="let room of GuestRoomSearchResult">                   
                            <mat-radio-button (click)="SelectGuestRoom(room)" [value]="room.GuestName">
                            </mat-radio-button>
                            <div>
                                <div class="voucher-details">
                                    <div class="flx-grw">
                                        <div>{{room.GuestName}}</div>
                                    </div>
                                    <div *ngIf="room.RoomNumber != ''">{{this.captions.RoomNumber}}: {{room.RoomNumber}}</div>
                                    <div *ngIf="ShowCreditLimit">{{this.captions.lbl_creditlimit}}: {{_localization.localizeCurrency(room.creditLimit)}}</div>
                                    <div *ngIf="room.phoneNumber && room.phoneNumber != '' && room.phoneNumber != '0'">{{this.captions.PhoneNumber}}: {{room.phoneNumber}}</div>
                                </div>
                                <div *ngIf="isShowGroupBookingId && room.groupBookingId" class="groupAccountBookingId">{{this.captions.GroupBookingId}}: {{room.groupBookingId}}</div>
                            </div>
                        </div>
                    <!-- </div>                    -->
            </mat-radio-group>
            <div *ngIf="IsHotelCompPayment && SelectedGuestRoom">
                <ng-container>
                    <div class="voucher-con">
                      <div class="acct-detail-ro">
                        <div class="hotelCompLable" [matTooltip]="this.captions.lbl_HotelCompName">{{this.captions.lbl_HotelCompName}}</div>
                        <div>: </div>
                        <div class="hotelCompValue" [matTooltip]="SelectedGuestRoom.GuestName">{{SelectedGuestRoom.GuestName}}</div>
                      </div>
                      <div class="acct-detail-ro">
                        <div class="hotelCompLable" [matTooltip]="this.captions.HotelCompNumber">{{this.captions.HotelCompNumber}}</div>
                        <div>: </div>
                        <div class="hotelCompValue" [matTooltip]="SelectedGuestRoom.RoomNumber">{{SelectedGuestRoom.RoomNumber}}</div>
                      </div>
                      <div class="acct-detail-ro">
                        <div class="hotelCompLable" [matTooltip]="this.captions.HotelCompAuthorizedAmount">{{this.captions.HotelCompAuthorizedAmount}}</div>
                        <div>: </div>
                        <div class="hotelCompValue" [matTooltip]="this._localization.localizeCurrency(SelectedGuestRoom.authorizedAmount)">{{this._localization.localizeCurrency(SelectedGuestRoom.authorizedAmount)}}</div>
                      </div>
                    </div>
                </ng-container>
            </div> 
        </div>
    </div>
</form>