import { Injectable, OnDestroy } from '@angular/core';
import { AppService } from '@app/app.service';
import { ChangeAction, Menu, PartyType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { ChangeDTO, ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { AuditableActionType } from '@models/SimpleAuditLogItemDTO';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { Subscription } from 'rxjs';
import { ObjectType } from './processor';
import { ProcessorBuilder } from './processor-builder';
import { RestaurantStateService } from '@app/shared/services/restaurant-state.service';
import _ from 'lodash';

@Injectable()
export class ChangeProcessor implements OnDestroy {
  partyChangedCount = 0;
  isServerUpdated = false;
  subscriptions: Subscription = new Subscription();

  constructor(private builder: ProcessorBuilder, private cs: CacheService, private as: AppService,
    private partyService: PartyService, private stateService: RestaurantStateService) {
    this.subscriptions.add(cs.changes.subscribe((changes: ChangeDTO[]) => {
      try {
        this.partyChangedCount = 0;
        if (!changes) {
          return;
        }
        let IsStandbyParty = false;
        changes = changes.sort((change1, change2) => change1.Revision - change2.Revision);
        this.isServerUpdated = false;
        this.stateService.isStateUpdated = false;
        for (let change of changes) {
          const latestRevision = Utilities.getLatestRevision(change.ChangeSet.RestaurantId);
          if (change.Revision > latestRevision) {
            const logChanges = change.ChangeSet.ObjectChanges.filter(oc => oc.ObjectType === "AuditLogItem");
            if (logChanges.length > 0) {
              logChanges.forEach(lc => {
                let logType = lc.PropertyChanges.find(property => property.PropertyName == "AuditableActionInternal").Value;
                if (logType == AuditableActionType.Convert) {
                  this.as.showOTAMessage = false;
                }
              });
            }
            change.ChangeSet.ObjectChanges = change.ChangeSet.ObjectChanges.filter(oc => ObjectType[oc.ObjectType] >= 0);

            change.ChangeSet.ObjectChanges = change.ChangeSet.ObjectChanges.sort((objectChange1, objectChange2) => (ObjectType[objectChange1.ObjectType] - ObjectType[objectChange2.ObjectType]));
            change.ChangeSet.ObjectChanges.forEach(objectChange => {
              if (ObjectType[objectChange.ObjectType] > -1) {
                builder.getProcessor(ObjectType[objectChange.ObjectType]).Process(objectChange, change.ChangeSet.AdditionalData, change.ChangeSet.RestaurantId);
                if (!IsStandbyParty) {
                  IsStandbyParty = this.checkStandby(objectChange);
                }
              }
            });
            if (!change.isPayloadchangeset) {
              console.log('set revision based on GetChange', change.Revision);
              Utilities.setLatestRevision(change.Revision, change.ChangeSet.RestaurantId);

            } else {
              if (change.Revision - latestRevision === 1) {
                console.log('set revision', change.Revision);
                Utilities.setLatestRevision(change.Revision, change.ChangeSet.RestaurantId);
              }
            }
            if (Utilities.RestaurantId() === change.ChangeSet.RestaurantId && this.cs.propertySettings.value[change.ChangeSet.RestaurantId]) {
              this.cs.state.value.Revision = change.Revision;
              this.cs.propertySettings.value[change.ChangeSet.RestaurantId].state.Revision = change.Revision;
            } else if(this.cs.propertySettings.value[change.ChangeSet.RestaurantId]) {
              this.cs.propertySettings.value[change.ChangeSet.RestaurantId].state.Revision = change.Revision;
            }

          }
        }
        this.partyService.Parties$.next(this.partyService.partiesList);
        if (IsStandbyParty) {
          this.partyService.StandbyParties$.next(this.partyService.standbyPartiesList);
        }
        if (this.partyService.selectedParty$.value && this.as.selectedMenuId != Menu.Tables) {
          let isPartyNoteChanged = changes.filter(c => c.ChangeSet.ObjectChanges?.filter(oc => ObjectType[oc.ObjectType] === ObjectType.PartyNote).length > 0);
          let isSelectedPartyNoteChanged = isPartyNoteChanged.filter(c => c.ChangeSet.ObjectChanges.filter(oc => oc.PropertyChanges.find(property => property.PropertyName == 'PartyId')?.Value == this.partyService.selectedParty$.value.Id).length > 0).length > 0
          if (isSelectedPartyNoteChanged) {
            let partyChanged = this.partyService.Parties$.value.filter(p => p.Id == this.partyService.selectedParty$.value.Id)[0];
            if (partyChanged) {
              this.partyService.selectedParty$.value.Notes = _.cloneDeep(partyChanged.Notes);
              this.partyService.selectedParty$.next(this.partyService.selectedParty$.value);
            }
          }
        }

        let autoServerAreaObjectChanges = changes.filter(c => c.ChangeSet.ObjectChanges?.filter(oc => ObjectType[oc.ObjectType] === ObjectType.AutoServerArea || this.isServerUpdatedForStandAloneTable(oc)).length > 0);
        if ((autoServerAreaObjectChanges && autoServerAreaObjectChanges.length > 0) || this.stateService.isStateUpdated) {
          this.cs.state.next(this.cs.state.value);
        }
        let blockingRuleObjectChanges = changes.filter(change => {
          if (change.ChangeSet.ObjectChanges && change.ChangeSet.ObjectChanges.length > 0) {
            return change.ChangeSet.ObjectChanges.filter(objectChange => objectChange[objectChange.ObjectType] === ObjectType.TableBlockingRule ||
              objectChange[objectChange.ObjectType] === ObjectType.TableBlockingRuleShift || objectChange[objectChange.ObjectType] === ObjectType.TableBlockingRuleTable)
          }
        });
        if (blockingRuleObjectChanges.length > 0) {
          builder.ts.updatedData(builder.ts.overallTableBlockingRules);
        }
      } catch (exception) {
        console.error(exception);
      }

    }));
  }

  checkStandby(change: ObjectChange) {
    let objType = change.PropertyChanges.filter(property => property.PropertyName.replace('Internal', '') == 'Type');
    let partyDetail = this.partyService.StandbyParties$.value.find((party) => party.Id === change.ObjectId);
    if (objType.length > 0) {
      return objType[0].Value == PartyType.StandBy;
    }
    else if (partyDetail && partyDetail.Type == PartyType.StandBy) {
      return true;
    }
    return false;
  }


  isServerUpdatedForStandAloneTable(objectchange: ObjectChange): boolean {
    if (!this.isServerUpdated) {
      this.isServerUpdated = objectchange.Action == ChangeAction.Updated && ObjectType[objectchange.ObjectType] === ObjectType.StandaloneTable && objectchange.PropertyChanges.findIndex(c => c.PropertyName == 'ServerId') > -1;
    }
    return this.isServerUpdated;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
