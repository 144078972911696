export function groupBy(list: any[], keyProperty: string): Map<string | number, any[]> {
    
    const map = new Map();
    if(!list){
        return map;
    }
    list.forEach((item) => {
        const mapKey = item[keyProperty];
        const collection = map.get(mapKey);
        if (!collection) {
            map.set(mapKey, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export function generateCartGroupName(type, id: number, packageName: string, guestName: string): string {
    return [type, id, packageName, guestName, getRandomString() ].join("::");
}


function getRandomString(): string{
    return '#' + Math.floor(1 + Math.random() * 9) + (Math.random() + 1).toString(36).substring(2, 3) + Math.floor(10 + Math.random() * 90) + (Math.random() + 1).toString(36).substring(2, 3);
}


export function getExpireTime(expireTime): number{
    if(!expireTime){
        return 0;
    }
    let _expireTime = new Date(expireTime).getTime() - new Date(new Date().toISOString()).getTime();

    return _expireTime > 0 ? _expireTime : 0;
}

export function formatTime(time, minsText: string, secsText: string){
  return `${Math.floor(time / 60)} ${minsText} ${Math.round(Number(time % 60))} ${secsText}` ;
}