import { Pipe, PipeTransform } from '@angular/core';
import { GuestTitle } from '../models/InputContact';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'formattedName' })

export class NameFormatPipe implements PipeTransform {
  constructor(private ts: TranslateService){}
  transform(contact) {
    if (!contact.FirstName && !contact.LastName) {
      return this.ts.instant('unnamed');
    }
    else {
      return (contact.GuestTitle > 0 ? GuestTitle[contact.GuestTitle] : '') + ' ' + (contact.FirstName ? this.translateName(contact.FirstName) : '') + ' ' + (contact.LastName ? this.translateName(contact.LastName) : '');
    }
  }

  translateName(contactName: string): string {
    if(contactName.includes('(Cleared)')){ return this.ts.instant(contactName);}
    else {return contactName};
  }
}
