import { Pipe, PipeTransform } from '@angular/core';
import { PartyService } from '../services/party.service';


@Pipe({
    name: 'customGuestFieldSelector',pure: false
})
export class customGuestFieldSelectorPipe implements PipeTransform {
    constructor(public partyService : PartyService) { }
    transform(customFieldId: number, specialMeal: any): any {
        if (customFieldId && specialMeal) {
            let customGuestField = this.partyService.customGuestFieldsMappingValidations;
            if(customGuestField){
            let activityCustomField = customGuestField.find(x => x.ActivityId == specialMeal);
            if (activityCustomField && activityCustomField.CustomFields.length) {
                return  activityCustomField.CustomFields.find(x => x.FieldId == customFieldId)?.IsVisible;
            }else{
                return false;
            }
        }
        }else{
            return false;
        }
    }
}
