import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { BreakPointAccess } from './breakpoint.service';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { Product } from '../globalsContant';
import { AppService } from 'src/app/common/app-service';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';

@Injectable(
  {providedIn: 'root'}
)
export class RouteGuardService implements CanActivate, OnDestroy {
    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    childbreakPointNumbers: any = [];
    accessArray: any = {
        breakpoints: [],
        paths: []
    }
    checkAuthorization: any;
  ProductID: any;

    constructor(private router: Router, private breakpoint: BreakPointAccess, private localization: RetailLocalization
      , private _appservice: AppService, private _userAccessBusiness: UserAccessBusiness,private propertyInfo: CommonPropertyInformation) {
      this.ProductID = this._appservice.productId;
    }

    ngOnDestroy() {
        if (this.destroyed$) {
            this.destroyed$.next(true);
            this.destroyed$.complete();
        }
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if(route.data.breakPointNumber){
            let returnValue: boolean =  await this.checkAccess(route, state);
            if (returnValue){
                // To log value only if data is not present and to avoid Module and Submodule when data is present
                if(!(route.data && (route.data.isModule || route.data.isSubmodule ))){
                console.log('user accessed: ' + state.url);
                const date=this.propertyInfo.CurrentDTTM;
                
                this._userAccessBusiness.logUserMenuAccess(state.url , route.data && route.data.screenName ? route.data.screenName:" ",date);
                
                }
            }
            const extras = this.router.getCurrentNavigation().extras;
            const isFromBack = (extras.state && extras.state.fromBack);
            let sessionFromURLArray = sessionStorage.getItem('fromURLPath');
            let sessionFromURLRouteStateArray = sessionStorage.getItem('fromURLRouteState');
            let fromURLPathArray = sessionFromURLArray? JSON.parse(sessionFromURLArray): [];
            let fromURLRouteStateArray = sessionFromURLRouteStateArray? JSON.parse(sessionFromURLRouteStateArray): [];
            if(!isFromBack && returnValue){
                const prevsExtras = this.router.getCurrentNavigation().previousNavigation?.extras;
                let curURL = state.url;
                let prevURL = this.router.routerState.snapshot.url;
                let currentPath = curURL.split('/');
                let prevPath = prevURL.split('/');
                if(currentPath[1] !== prevPath[1]){
                    fromURLPathArray = [];
                    fromURLRouteStateArray = [];
                    sessionStorage.setItem('fromURLPath', JSON.stringify(fromURLPathArray));
                    sessionStorage.setItem('fromURLRouteState', JSON.stringify(fromURLRouteStateArray));
                } else {
                    fromURLPathArray.push(this.router.routerState.snapshot.url);
                    fromURLRouteStateArray.push(prevsExtras?.state);
                    sessionStorage.setItem('fromURLPath', JSON.stringify(fromURLPathArray));
                    sessionStorage.setItem('fromURLRouteState', JSON.stringify(fromURLRouteStateArray));
                }
            }
            return returnValue;
        } else {
            return true;
        }
        
    }

    private async checkAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const breakpointNumber = route.data.breakPointNumber;
        const redirectLocation = route.data.redirectTo;
        const currentRoutePath = route.routeConfig.path;
        const hasChild = route.data.hasChild;
        const extras = this.router.getCurrentNavigation().extras;
        const isModuleChange = extras.state ? extras.state.onModuleChange : false;
        const isSuModuleChange = extras.state ? (extras.state.onSubmoduleChange || extras.state.onChildChange) : false;

        if (hasChild && !(isModuleChange || isSuModuleChange)) {
            let isAuthorised = this.getChildBreakPointNos(route, breakpointNumber, redirectLocation);
            if (!isAuthorised) {
                this.redirectToURL(redirectLocation, route)
                return false;
            }
        }

        if(!hasChild && !(isModuleChange || isSuModuleChange)){
            let isAuthorised = this.getChildBreakPointNos(route, breakpointNumber, redirectLocation);
            if (!isAuthorised) {
                if(redirectLocation && extras.state && !extras.state.ShowPopup){
                    this.redirectToURL(redirectLocation, route)
                } else {
                    this.breakpoint.showBreakPointWarningPopup(this.localization.captions.breakpoint[breakpointNumber]);
                }
            return false;
            }
        }

        if (isModuleChange || isSuModuleChange) {
            let isAuthorised = this.getChildBreakPointNos(route, breakpointNumber, redirectLocation);
            if (!isAuthorised) {
                if(!hasChild && redirectLocation && isModuleChange){
                    this.redirectToURL(redirectLocation, route)
                    return false;
                }
              let lastBreakPoint = this.childbreakPointNumbers[this.childbreakPointNumbers.length - 1];

              if (this.ProductID == Product.SPA) {
                this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[lastBreakPoint]);
              }
              else
                this.breakpoint.showBreakPointWarningPopup(this.localization.captions.breakpoint[lastBreakPoint]);

                return false;
            }
            else {
                if (this.accessArray.paths.length > 0 && this.checkAuthorization.authorizationIndex) {
                    this.redirectToAccessibleURL(this.accessArray.paths[this.checkAuthorization.authorizationIndex])
                    return true;
                }
                else {
                    // this.redirectToCurrentURL(route);
                    return true;
                }
            }
        }

        if (breakpointNumber) {
            let isAuthorised = this.breakpoint.CheckForAtleastOneAccess([breakpointNumber]);
            if (isAuthorised.hasAccess) {
                return true;
            }
            else { 
                  if (this.ProductID == Product.SPA) {
                this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[breakpointNumber]);
                  } else {
                this.breakpoint.showBreakPointWarningPopup(this.localization.captions.breakpoint[breakpointNumber]);
                  }
                  return false;
            }
        }
        else {
            return true;
        }

    }

    getChildBreakPointNos(route: ActivatedRouteSnapshot, breakpoint, redirectLocation) {
        this.childbreakPointNumbers = breakpoint ? [breakpoint] : [];
        let sliceEnd = route['_routerState'].url.indexOf(route.routeConfig.path) + route.routeConfig.path.length;
        const parentUrl = route['_routerState'].url.slice(0, sliceEnd) + '/';
        this.accessArray = {
            breakpoints: [],
            paths: []
        }
        if (route && route.routeConfig && route.routeConfig.children && route.routeConfig.children.length > 0) {
            route.routeConfig.children.forEach(child => {
                if (child) {
                    this.createChildMenuPath(child, parentUrl, this.accessArray.paths);
                }
            });
        }
        this.accessArray.breakpoints = this.childbreakPointNumbers;

        if (this.childbreakPointNumbers && this.childbreakPointNumbers.length > 0) {
            this.checkAuthorization = this.breakpoint.CheckForAtleastOneAccess(this.childbreakPointNumbers);
            if (this.checkAuthorization.hasAccess) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    createChildMenuPath(child, parentUrl, pathArray) {
        if (child && child.data && child.data.breakPointNumber) {
            this.childbreakPointNumbers.push(child.data.breakPointNumber);
            pathArray.push(parentUrl.concat(child.path));
        }
        else {
            let pathMatch = (child.pathMatch && (child.pathMatch == "full"))? true : false;
            if (!pathMatch) {
                this.childbreakPointNumbers.push(null);
                pathArray.push(parentUrl.concat(child.path));
            }
        }
        let childUrl = parentUrl.concat(child.path) + '/';
        if (child.children && child.children.length > 0) {
            child.children.forEach(y => {
                this.createChildMenuPath(y, childUrl, pathArray);
            });
        }
    }

    redirectToURL(redirectLocation, route: ActivatedRouteSnapshot) {
        this.router.navigate([redirectLocation]);
    }

    redirectToCurrentURL(route: ActivatedRouteSnapshot) {
        this.router.navigate([route['_routerState'].url]);
    }

    redirectToAccessibleURL(route) {
        this.router.navigate([route]);
    }

    backTrack(){
        let sessionFromURLArray = sessionStorage.getItem('fromURLPath');
        let sessionFromURLRouteStateArray = sessionStorage.getItem('fromURLRouteState');
        let fromURLPathArray = sessionFromURLArray? JSON.parse(sessionFromURLArray): [];
        let fromURLRouteStateArray = sessionFromURLRouteStateArray ? JSON.parse(sessionFromURLRouteStateArray) : [];
        if(fromURLPathArray.length > 0){
            let stateValue = fromURLRouteStateArray[fromURLPathArray.length - 1];
            
            if (stateValue) {
                stateValue.fromBack = true;
                this.router.navigateByUrl(fromURLPathArray[fromURLPathArray.length - 1], {
                    state: stateValue 
                });
            }
            else {
                if(fromURLPathArray[fromURLPathArray.length - 1] != "" && fromURLPathArray[fromURLPathArray.length - 1] != null){
                    this.router.navigateByUrl(fromURLPathArray[fromURLPathArray.length - 1], {
                        state: { fromBack: true }
                    });
                }
            }
            fromURLPathArray.pop();
            fromURLRouteStateArray.pop();
            sessionStorage.setItem('fromURLPath', JSON.stringify(fromURLPathArray));
            sessionStorage.setItem('fromURLRouteState', JSON.stringify(fromURLRouteStateArray));
        }
    }
}

