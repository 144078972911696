import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { ChangeProcessor } from '@core/change-processors/change-processor';
import { environment } from '@environments/environment';
import { LoginResultDTO } from '@models/LoginResultDTO';
import { DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { PopupService } from '@popup-module/popup.service';
import { SignalrService } from '@services/signalr.service';
import { Utilities } from '@utilities/utilities';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import * as FullStory from '@fullstory/browser';
import { AppBusiness } from './app.business';
import { HttpService } from './shared/services/http.service';
import { SwUpdate } from '@angular/service-worker';
import { KeyboardService } from './pos/keyboard/keyboard.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AppBusiness]
})
export class AppComponent implements OnInit, OnDestroy {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  subscriptions: Subscription = new Subscription();

  public modalRef: BsModalRef;

  @ViewChild('childModal', { static: true }) childModal: ModalDirective;
  // , private loggingService: LoggingService

  constructor(public _as: AppService, private router: Router,
    private keepalive: Keepalive, public _signalRService: SignalrService, public cp: ChangeProcessor,
    private ps: PopupService,public appBusiness: AppBusiness, private httpService: HttpService,private swUpdate: SwUpdate, public keyboardService: KeyboardService) {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const token = urlParams.get('token');
    const restId = urlParams.get('restaurantId');
    const menu = urlParams.get('section');
    
    if(token != null)  {
      const sessionType = Utilities.getSessionStorageType()
      sessionStorage.setItem(`restaurantId${sessionType}`, restId);
      sessionStorage.setItem(`merchantPortalRedirectToken${sessionType}`, token);
      sessionStorage.setItem('section', menu);
    }
    
    this.subscriptions.add(this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        if (ev.url == "/" || ev.url == "/login" || ev.url == "/sso" || ev.url.indexOf("/activitiesdivscreen?") >= 0) {
          return;
        }
        try {
          let loginResult = Utilities.tryParseObject<LoginResultDTO>(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)); //Previous sessionStorage

          if (!loginResult || !sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`)) {
            this._as.logout();
          }
          Utilities.SetRetainSignalR("true");
        }
        catch (e) {
          this._as.logout();
        }
      }
    }));

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    this.subscriptions.add(keepalive.onPing.subscribe(() => this.lastPing = new Date()));
    this._as.setUserLoggedIn(false);
    this.httpService.get('assets/fullStory_config/' + environment.fullStoryLocation).subscribe((response) => {
      if(response){
        this._as.fullStoryProperties = [...response];
      }
      if (this._as.restaurantId && response && response.find(rest => rest.restaurantId === this._as.restaurantId) && sessionStorage.getItem(`isAppLoggedIn${Utilities.getSessionStorageType()}`) === 'true') {
        window['_fs_org'] = environment.fullStory;
        this._as.isFullStoryExecuted = true;
        if (!this._as.isFirstLogin) {
          FullStory.restart()
        } else {
          window['fullStoryExecutable'](window,document,window['_fs_namespace'],'script','user');
          this._as.isFirstLogin = false;
        }
      } else {
        this._as.isFullStoryExecuted = false;
      }
    })
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

  ngOnInit() {
  }

  title = 'seat-v2';

  setLanguage(lang: any) {
    this._as.setLanguage(lang);
  }


  hideChildModal(): void {
    this.childModal.hide();
  }
  logout() {
    this.childModal.hide();
    this._as.setUserLoggedIn(false);
    this._as.logout();
  }
}
