import { AfterViewInit, Component, OnInit, Pipe, PipeTransform, Renderer2, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';
import _ from 'lodash';
import { ShowSelectedOptionTitlePipe } from '../form-select/form-select.component';

@Component({
  selector: 'app-group-form-select',
  templateUrl: './form-group-select.component.html',
  styleUrls: ['form-group-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormGroupSelectComponent implements Field, OnInit {
  config: FieldConfig;
  group: FormGroup;
  FormControlName: string;
  options = [];
  showColor = false;
  valueChecked = false;
  isSelectAll: boolean;
  constructor(private renderer: Renderer2, private showSelectedpipe: ShowSelectedOptionTitlePipe) {

  }
  ngOnInit() {
    if (this.config?.selectAscendingSortOrder) {
      this.config.groups = _.orderBy(this.config?.groups, ['name'], ['asc']);
    }
    this.showColor = this.config.showColor ? this.config.showColor : false;
    this.FormControlName = this.config.name;
    this.group.controls[this.config.name].setValue(this.config.value);
    // this.group.controls[this.config.name].markAsTouched();
    this.config.title = 'Selected';
  }

  ngDoCheck() {
    if (this.config.value)
      this.config.title = 'Selected';
    else
      this.config.title = null;
    if (this.showColor && !this.valueChecked) {
      let selectedValue = document.querySelectorAll('.select-color .mat-select-value-text');
      if (selectedValue.length > 0) {
        this.valueChecked = true;
        // this.renderer.setStyle(selectedValue[0], 'color', this.config.options.filter(option => option.id == this.config.value)[0].color)
      }
    }
  }


  reset(event) {
    if (this.group.controls[this.config.name].value == "") {
      event.target.blur();
      this.group.controls[this.config.name].setValue(this.config.value)
    }
  }

  selectedOption(event) {
    this.config.title = 'Seleted Value';
    if (this.showColor) {
      let selectedValue = document.querySelectorAll('.select-color .mat-select-value-text');
      if (selectedValue.length > 0) {
        // this.renderer.setStyle(selectedValue[0], 'color', this.config.options.filter(option => option.id == event.value)[0].color)
      }
    }
    if (this.config.cellClick) {
      this.config.cellClick(event);
    }
    // if(this.config?.selectMultipleOptions && this.config?.options.length == this.config?.value.length){
    //   this.isSelectAll = true;
    // }else if(this.config?.selectMultipleOptions){
    //   this.isSelectAll = false;
    // }
  }
}
