import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { SlottingType, TableBlockBy, TableBlockingRuleFrequencyType } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PartyService } from '@app/shared/services/party.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ISubscription, Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-shift-selection',
  templateUrl: './shift-selection.component.html',
  styleUrls: ['./shift-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShiftSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  selectedValue: any[] = [];
  selectedShitIds: number[] = [];
  leftArrowDisabled = true;
  rightArrowDisabled = false;
  availableBtnSpace: any;
  noShowIcon: boolean;
  btnWidth: number;
  starttime: FieldConfig[];
  endtime: FieldConfig[];
  showNavigation = false;
  isInitial = true;
  shifts: any;
  isFormValid = false;
  isShiftisAvilable = true;
  selectedIndex = 0;
  selectedShiftType: any;
  isfrequencyChanged = false;
  isDateChanged = true;
  cachedShifts = [];
  shiftAvailabilitySubscription: ISubscription;
  settingSubscription: ISubscription;
  @ViewChild('areaContainer', { static: true }) el: ElementRef;
  selectedFrequency;
  @Input() title: string;
  tabSubscription: ISubscription;
  pageInitialize = true;
  subscriptions: Subscription = new Subscription();
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.loadNavigation();
  }
  shiftSelectionConfig: FieldConfig[];
  fromTime: any;
  toTime: any;
  shiftTypes: any;
  public selectShiftControl: UntypedFormGroup;
  constructor(public _as: AppService, @Inject(COMPONENTINPUT) private data, private translateSrvice: TranslateService,
    private ps: PopupService, private cdf: ChangeDetectorRef, private partyService: PartyService,
    private appService: AppService, private cs: CacheService, public fb: UntypedFormBuilder) {

    this.settingSubscription = cs.settings.subscribe(settings => {
      if (!this.pageInitialize) {
        this.getShifts(this.partyService.reservationFormGroup.controls.selectedDate.value);
      }
      this.pageInitialize = false;
    });
    this.setShiftTypeOptions();

    this.selectShiftControl = this.fb.group({
      fromTime: ['', Validators.required],
      toTime: ['', Validators.required]
    });
    
  }
  setShiftTypeOptions(){
   let updatedshiftTypes= _.cloneDeep(this.partyService.ShiftSelectionByOptions).map(option => {
    option.value = this.translateSrvice.instant(option.value)
    return option;
  });
    if(this.partyService.cs.settings.value.PropertySetting[0].SlottingType == SlottingType.NonDining){
      updatedshiftTypes.splice(0,1);
    }
    this.shiftTypes = updatedshiftTypes;
    this.selectedShiftType = this.shiftTypes[0].id;
    
  }

  ngOnDestroy(): void {
    if (this.shiftAvailabilitySubscription)
      this.shiftAvailabilitySubscription.unsubscribe();
    if (this.tabSubscription)
      this.tabSubscription.unsubscribe();
    if (this.settingSubscription) {
      this.settingSubscription.unsubscribe();
    }
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

  ngOnInit() {
    this.shiftSelectionConfig = [{
      type: 'select',
      label: 'Select Shift',
      name: 'shift',
      placeholder: 'Shift',
      options: this.shiftTypes,
      class: 'shift-selection',
      showErrorText: true,
      appearance: true,
      value: this.shiftTypes[0].id,
      cellClick: this.setShiftSelectionConfig.bind(this)
    }];
    this.fromTime = '';
    this.toTime = '';
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === ShiftSelectionComponent);
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedDate').valueChanges.subscribe(() => {
      this.shifts = [];
      this.selectedValue = [];
      this.isDateChanged = true;
      this.reservationFormChange();
    }));
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedFrequency').valueChanges.subscribe(() => {

      this.isfrequencyChanged = true;
      this.selectedValue = [];
      this.reservationFormChange();
    }));
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedTableFloor').valueChanges.subscribe((val) => {
      this.isfrequencyChanged = true;
      this.selectedValue = [];
      this.reservationFormChange();
    }));
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedTableShift').valueChanges.subscribe((val) => {
      this.isfrequencyChanged = true;
      this.selectedValue = [];
      this.reservationFormChange();
      
      if (this.data) {
        this.getShifts(this.partyService.reservationFormGroup.controls.selectedDate.value);
        if (this.shifts.length == 1) {
          this.selectedValue = this.selectedValue && this.selectedValue.length > 0 ? this.selectedValue : this.shifts;
        }
        this.reservationFormChange();
      }
    }));
    
    if(!this.data){
    this.partyService.reservationFormGroup.get('selectedBlockingType').setValue(this.selectedShiftType);
    }
    if (this.data) {
      this.selectedShiftType = this.data.BlockBy ? this.data.BlockBy : this.shiftSelectionConfig[0].value;
      this.shiftSelectionConfig[0].value = this.selectedShiftType;
      this.partyService.reservationFormGroup.get('selectedBlockingType').setValue(this.selectedShiftType);
      if (this.data.BlockBy != undefined) {
        if(this.data.BlockBy != TableBlockBy.ByShift){
          if (this.selectedShiftType == TableBlockBy.ByTimeRange ) {
            let startTime:any =  this.data.EffectiveRange[0].Start.split(':');
            this.fromTime = startTime[0] + ':' + startTime[1];
            this.selectShiftControl.get('fromTime').setValue(this.fromTime);
            this.partyService.reservationFormGroup.get('selectedFromTime').setValue(this.fromTime);
            let endTime = this.data.EffectiveRange[0].End.split(':');
            this.toTime =  endTime[0] + ':' + endTime[1];
            this.selectShiftControl.get('toTime').setValue(this.toTime);
            this.partyService.reservationFormGroup.get('selectedToTime').setValue(this.toTime);
            this.ps.formValid$.next(
              {
                isFormValid: true,
                currentTab: this.selectedIndex
              } as IFormValidDetails);
          } else {
            let val = { value: TableBlockBy.ByDay}
            this.setShiftSelectionConfig(val)
          }
        }
      }
      
      if(this.data.BlockBy == TableBlockBy.ByShift){
      this.getShifts(this.partyService.reservationFormGroup.controls.selectedDate.value, true);
      if (this.shifts.length == 1) {
        this.selectedValue = this.selectedValue && this.selectedValue.length > 0 ? this.selectedValue : this.shifts;
      }
    }
      if (this.selectedValue.length > 0)
        this.reservationFormChange();
    }

  }
  ngAfterViewInit() {
    this.tabSubscription = this.partyService.tabChange$.subscribe((index) => {
      if (this.selectedIndex == index) {
        if (this.isfrequencyChanged || this.isDateChanged) {
          this.getShifts(this.partyService.reservationFormGroup.controls.selectedDate.value);
          this.reservationFormChange();
          this.isfrequencyChanged = false;
        }
        if (this.shifts.length == 1) {
          this.selectedValue = this.selectedValue && this.selectedValue.length > 0 ? this.selectedValue : this.shifts;
        }
        this.reservationFormChange();
      }

    });


  }

  getShifts(date, noneed?) {
    var val = this.partyService.reservationFormGroup.get('selectedFrequency').value;

    if (this.cs.settings.value.Shifts && this.cs.settings.value.Shifts.length > 0) {
      this.isShiftisAvilable = true;
    } else {
      this.isShiftisAvilable = false;
    }
    if (val == TableBlockingRuleFrequencyType.EveryDay) {
      let allShift = [{
        "Id": -1,
        "Name": this.translateSrvice.instant("allShiftsText"),
        "DisplayedRange": { "Start": "", "End": "" }
      }]
      if (this.cs.settings.value.Shifts && this.cs.settings.value.Shifts.length > 0) {
        let updatedShifts = allShift.map(obj => ({ ...obj, imageUrl: this.getImageBaseonTime(obj) }));
        this.selectedShiftLoaded(updatedShifts);
        this.shifts = updatedShifts;
      }


    } else {
      let dates = new Date(date);
      if (this.isDateChanged) {
        let shifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, dates, val == TableBlockingRuleFrequencyType.EveryWeekDay);
        // this.ss.getShiftForDay(dates, true, null).subscribe((response) => {
        let updatedShifts = shifts.map(obj => ({ ...obj, imageUrl: this.getImageBaseonTime(obj) }));
        this.cachedShifts = [];
        this.cachedShifts = updatedShifts;
        let shiftsids = this.getShiftsForCustomFloors(date);
        let finalShifts = updatedShifts;
        if (this.partyService.reservationFormGroup.get('selectedTableFloor').value && this.partyService.reservationFormGroup.get('selectedTableShift').value) {
          finalShifts = updatedShifts.filter((el) => {
            return shiftsids.some((f) => {
              return f.ShiftId === el.Id;
            });
          });
          //updatedShifts = shiftdetails;// updatedShifts.filter(val => { return val.Id == this.partyService.reservationFormGroup.value.selectedTableShift });
        }
        else if (val == TableBlockingRuleFrequencyType.TheOnlyDay && (!this.partyService.reservationFormGroup.get('selectedTableFloor').value || this.partyService.reservationFormGroup.get('selectedTableFloor').value != "") && this.partyService.reservationFormGroup.get('selectedTableShift').value) {

          finalShifts = updatedShifts.filter(function (objFromA) {
            return !shiftsids.find(function (objFromB) {
              return objFromA.Id === objFromB.ShiftId
            })
          });

        }
        this.shifts = finalShifts;
        if (!this.selectedFrequency) {
          this.selectedShiftLoaded(finalShifts);
        }
        if (!noneed)
          this.reservationFormChange();
        //});
        this.isDateChanged = false;
      } else {
        let allshifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, dates, val == TableBlockingRuleFrequencyType.EveryWeekDay);
        allshifts = allshifts.map(obj => ({ ...obj, imageUrl: this.getImageBaseonTime(obj) }));
        let shiftsids = this.getShiftsForCustomFloors(date);
        let shiftdetails = allshifts;
        if (this.partyService.reservationFormGroup.get('selectedTableFloor').value && this.partyService.reservationFormGroup.get('selectedTableShift').value) {
          shiftdetails = allshifts.filter((el) => {
            return shiftsids.some((f) => {
              return f.ShiftId === el.Id;
            });
          });
          // allshifts.filter(val => { return val.Id == this.partyService.reservationFormGroup.value.selectedTableShift });
        }
        else if (val == TableBlockingRuleFrequencyType.TheOnlyDay && (!this.partyService.reservationFormGroup.get('selectedTableFloor').value || this.partyService.reservationFormGroup.get('selectedTableFloor').value != "") && this.partyService.reservationFormGroup.get('selectedTableShift').value) {
          shiftdetails = allshifts.filter(function (objFromA) {
            return !shiftsids.find(function (objFromB) {
              return objFromA.Id === objFromB.ShiftId
            })
          });

        }
        this.shifts = shiftdetails;
        //this.selectedShiftLoaded(shiftdetails);
      }

    }
    if (!noneed)
      this.reservationFormChange();
  }

  selectedShiftLoaded(Shifts) {

    var val = this.partyService.reservationFormGroup.get('selectedFrequency').value;
    if (val == TableBlockingRuleFrequencyType.EveryDay) {
      //if (this.data.Frequency.Type == val)
      this.selectedValue = Shifts;
    } else {
      if (this.data && this.data.Shifts) {
        let inputShiftLoaded = Shifts.filter((el) => {
          return this.data.Shifts.some((f) => {
            return f.Id == el.Id;
          });
        });
        if (inputShiftLoaded && inputShiftLoaded.length > 0 && this.data.Frequency.Type != TableBlockingRuleFrequencyType.EveryDay) {
          this.selectedValue = this.data.Shifts;
        }
      }
    }
  }

  getShiftsForCustomFloors(date) {
    let newArray = [];
    //  const parsedJson = JSON.parse(sessionStorage.getItem(this.appService.restaurantId + '_restaurantSettings'));
    let floorPlan = this.cs.layout.value.FloorPlans;
    let selectedDate = new Date(date).setHours(0, 0, 0, 0);
    let selectedShiftid = this.partyService.reservationFormGroup.value.selectedTableShift;
    let CustomFloorPlan = floorPlan.filter((Floor) => {
      if (Floor.Dates != null) {
        if (Floor.Dates.filter((dates) => {
          let dateval = new Date(dates.Date).setHours(0, 0, 0, 0);
          if (dateval == selectedDate) {
            return dates
          }
        }).length > 0) {
          return Floor;
        }
      }
    });
    if (this.partyService.reservationFormGroup.value.selectedTableFloor && selectedShiftid) {
      CustomFloorPlan = floorPlan.filter((Floor) => {
        if (Floor.Dates != null) {
          if (Floor.Dates.filter((dates) => {
            let dateval = new Date(dates.Date).setHours(0, 0, 0, 0);
            if (dateval == selectedDate && dates.ShiftId == selectedShiftid) {
              return dates
            }
          }).length > 0) {
            return Floor;
          }
        }
      });
    }
    let getShiftForDate = CustomFloorPlan
      .filter((element) =>
        element.Dates.some((subElement) => new Date(subElement.Date).setHours(0, 0, 0, 0) === selectedDate && subElement.ShiftId != null))
      .map(element => {
        //let newElt = Object.assign(element);
        return element.Dates.filter(subElement => new Date(subElement.Date).setHours(0, 0, 0, 0) === selectedDate && subElement.ShiftId != null);
      });

    for (let i = 0; i < getShiftForDate.length; ++i) {
      for (let j = 0; j < getShiftForDate[i].length; ++j)
        newArray.push(getShiftForDate[i][j]);
    }
    console.log(newArray);
    return newArray;
  }


  getImageBaseonTime(shift: any) {
    if (shift && shift.DisplayedRange && shift.DisplayedRange.Start) {
      let startday = new Date(shift.DisplayedRange.Start);
      let startHr = startday.getHours();
      let endday = new Date(shift.DisplayedRange.End);
      let endhr = endday.getHours();
      if (startHr >= 6 && startHr < 12) {
        return "assets/images/moring.svg";
      } else if (startHr >= 12 && startHr < 17) {
        return "assets/images/afternoon.svg";
      }
      else if (startHr >= 17 && startHr < 22) {
        return "assets/images/Evening.svg";
      }
      else if (startHr >= 22 && startHr < 26) {
        return "assets/images/night.svg";
      } else {
        if (endhr >= 6 && endhr < 12) {
          return "assets/images/moring.svg";
        } else if (endhr >= 12 && endhr < 17) {
          return "assets/images/afternoon.svg";
        }
        else if (endhr >= 17 && endhr < 22) {
          return "assets/images/Evening.svg";
        }
        else if (endhr >= 22 && endhr < 26) {
          return "assets/images/night.svg";
        } else {
          return "assets/images/24hrs.svg";
        }
      }
    } else {
      return "assets/images/day-and-night.svg";
    }
  }

  loadNavigation() {
    if (this.el.nativeElement.scrollWidth > this.el.nativeElement.offsetWidth) {
      this.showNavigation = true;
    } else {
      this.showNavigation = false;
    }
  }
  setSelected(val: any) {
    let selectedval = val;
    var isavailable = this.selectedValue.find(function (ele) {
      return ele.Id == selectedval.Id;
    });
    if (isavailable) {
      let vales = this.selectedValue.filter(function (obj) {
        return obj.Id != selectedval.Id;
      });
      this.selectedValue = vales;

    } else {
      this.selectedValue.push(selectedval);
    }
    this.reservationFormChange();
  }

  updateSize(event) {
    var e = event;
  }
  calculateClasses(val) {
    const allArea: HTMLCollectionOf<SVGSVGElement> = document.getElementsByTagName('svg');
    for (let i = 0; i < allArea.length; i++) {
      var getId = allArea[i].id.split("_")[1];
      if (getId) {
        var valAvailable = this.selectedValue.find(function (ele) {
          return ele.Id == getId;
        });
        let divelement = document.getElementById(getId);
        if (valAvailable) {
          allArea[i].style.setProperty('display', 'block');
          if (divelement) {
            divelement.classList.add("shifts-area__selected-area");
          }
        } else {
          allArea[i].style.setProperty('display', 'none');
          if (divelement) {
            divelement.classList.remove("shifts-area__selected-area");
          }
        }

      } else {
        allArea[i].style.setProperty('display', 'none');
      }
    }
  }
  navigateLeft() {
    const element = this.el.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      element.scrollLeft = element.scrollLeft - 186;
    }
  }

  navigateRight() {
    const element = this.el.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      element.scrollLeft = element.scrollLeft + 186;
    }
  }
  ngAfterViewChecked() {
    if (this.el.nativeElement.scrollWidth > 0 && this.el.nativeElement.offsetWidth > 0 && this.isInitial) {
      this.isInitial = false;
      this.loadNavigation();
      this.cdf.detectChanges();
    }
  }

  checkdateispast(selecteddate) {
    let selectedtdate = new Date(selecteddate).setHours(0, 0, 0, 0);
    let currentdate = new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)).setHours(0, 0, 0, 0);
    if (!(selectedtdate < currentdate)) {
      return true;
    }
    return false;
  }

  reservationFormChange() {
    this.selectedShitIds = [];

    this.selectedValue.forEach(shift => {
      this.selectedShitIds.push(shift.Id);
    });
    this.partyService.reservationFormGroup.get('selectedShift').setValue(this.selectedShitIds);

    this.isFormValid = this.getValidForm();
    if (this.selectedShiftType ==  TableBlockBy.ByShift) {
      if (this.selectedValue.length > 0) {
        if (this.partyService.reservationFormGroup.value.selectedFrequency == TableBlockingRuleFrequencyType.EveryDay) {
          this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = this.translateSrvice.instant("allShiftsText");
        } else {
          if (this.selectedValue.length > 2) {
            this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = this.selectedValue.length + " "+ this.translateSrvice.instant("Shifts");
          } else {
            this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = Array.prototype.map.call(this.selectedValue, s => s.Name).toString();
          }
        }
      }
      else {
        this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = this.translateSrvice.instant("Shifts");
      }
      this.ps.formValid$.next(
        {
          isFormValid: this.isFormValid,
          currentTab: this.selectedIndex
        } as IFormValidDetails);
    } else if (this.selectedShiftType == TableBlockBy.ByDay) {
      let fromTime = this.partyService.reservationFormGroup.get("selectedFromTime").value != '' ? this.partyService.reservationFormGroup.get("selectedFromTime").value.split(':') : '';
      let toTime = this.partyService.reservationFormGroup.get("selectedToTime").value != '' ? this.partyService.reservationFormGroup.get("selectedToTime").value.split(':') : '';
      let fromValue = '';let toValue = '';
      if (fromTime != '' && toTime != '') {
        fromValue = fromTime[0] + ':' + fromTime[1];
        toValue = toTime[0] + ':' + toTime[1];
        this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = fromValue + '-' + toValue;
      }
      this.ps.formValid$.next(
        {
          isFormValid: this.isFormValid,
          currentTab: this.selectedIndex
        } as IFormValidDetails);
    }
    else if (this.selectedShiftType == TableBlockBy.ByTimeRange) {
      let fromTime = this.partyService.reservationFormGroup.get("selectedFromTime").value != '' ? this.partyService.reservationFormGroup.get("selectedFromTime").value.split(':') : '';
      let toTime = this.partyService.reservationFormGroup.get("selectedToTime").value != '' ? this.partyService.reservationFormGroup.get("selectedToTime").value.split(':') : '';
      let fromValue = '';let toValue = '';
      if (fromTime != '' && toTime != '') {
        fromValue = fromTime[0] + ':' + fromTime[1];
        toValue = toTime[0] + ':' + toTime[1];
        // if(fromTime[0] <= 12) {
        //   fromValue = fromTime[0] + ':' + fromTime[1] + ' AM';
        // } else {
        //   fromValue = String((fromTime[0] % 12) || 12) + ':' + fromTime[1] + ' PM';
        // }
        // if(toTime[0] <= 12) {
        //   toValue = toTime[0] + ':' + toTime[1] + ' AM';
        // } else {
        //   toValue = String((toTime[0] % 12) || 12) + ':' + toTime[1] + ' PM';
        // }
        this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = fromValue + '-' + toValue;
      }
      else
      {
        this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = this.translateSrvice.instant('timeRange');
      }
      this.ps.formValid$.next(
        {
          isFormValid: this.isFormValid,
          currentTab: this.selectedIndex
        } as IFormValidDetails);
    }
    this.calculateClasses("1");
  }

  getValidForm() {

    if (this.partyService.reservationFormGroup.value.selectedFrequency != null && this.partyService.reservationFormGroup.value.selectedFrequency >= 0
      && this.partyService.reservationFormGroup.value.selectedDate && this.partyService.reservationFormGroup.get("selectedTable").value
      && this.partyService.reservationFormGroup.get("selectedTable").value.length > 0
      && 
      ((
        this.partyService.reservationFormGroup.get('selectedShift').value && this.partyService.reservationFormGroup.get('selectedShift').value.length > 0   && this.selectedShiftType == 0
      )||(
        this.selectedShiftType != 0 && this.partyService.reservationFormGroup.get("selectedFromTime").value && this.partyService.reservationFormGroup.get("selectedToTime").value
      )
     )    
      
      ) {
      return true;
    }
    return false;
  }
  setShiftSelectionConfig(event) {
    this.selectedShiftType = event.value;
    this.partyService.reservationFormGroup.get('selectedFromTime').setValue('');
    this.partyService.reservationFormGroup.get('selectedToTime').setValue('');
    this.selectedValue=[];
    this.partyService.reservationFormGroup.get('selectedBlockingType').setValue(this.selectedShiftType);
    if(event.value == TableBlockBy.ByDay) {
      this.selectShiftControl.get('fromTime').setValue('00:00');
      this.partyService.reservationFormGroup.get('selectedFromTime').setValue('00:00:00');
      this.selectShiftControl.get('toTime').setValue('23:59');
      this.partyService.reservationFormGroup.get('selectedToTime').setValue('23:59:00');
      this.fromTime = '00:00';
      this.toTime = '23:59';
      this.selectShiftControl.controls['fromTime'].disable();
      this.selectShiftControl.controls['toTime'].disable();
      this.ps.formValid$.next(
        {
          isFormValid: true,
          currentTab: this.selectedIndex
        } as IFormValidDetails);
    }
    if(event.value == TableBlockBy.ByTimeRange) {
      this.selectShiftControl = this.fb.group({
        fromTime: ['', Validators.required],
        toTime: ['', Validators.required]
      });
        this.fromTime = '';
        this.toTime = '';
        this.ps.formValid$.next(
          {
            isFormValid: false,
            currentTab: this.selectedIndex
          } as IFormValidDetails);
    }
    this.reservationFormChange();
  }
  timeChange(event, type) {
    if (type == 'from') {
      this.partyService.reservationFormGroup.get('selectedFromTime').setValue(event + ':00');
    }
    if (type == 'to') {
      this.partyService.reservationFormGroup.get('selectedToTime').setValue(event + ':00');
    }
    if (this.partyService.reservationFormGroup.get('selectedFromTime').value != '' && this.partyService.reservationFormGroup.get('selectedToTime').value != '') {
      this.ps.formValid$.next(
        {
          isFormValid: true,
          currentTab: this.selectedIndex
        } as IFormValidDetails);
    }
    this.reservationFormChange();
  }

}
