<div class="multipaging">

  <label class="seat-header-two" for="">{{'selectParties' | translate}}</label>
  <div class="multipaging__parties-view">
    <ng-scrollbar>
      <div class="multipaging__party-data"
        *ngFor="let res of allReservations | groupBy: 'ReservedFor' | pairs;let i= index">
        <div class="multipaging__control-section">
          <label class="multipaging__time-display" *ngIf="res[0] != 'undefined'">{{res[0] | localizedDate:'LT'}}</label>
          <activities-app-button [buttontype]="buttonSecondary" (click)='unselectAll(res[1], i)' class="multipaging__customBtn">
          </activities-app-button>
          <activities-app-button [buttontype]="buttonValueprimary" (click)='selectAll(res[1] ,i)' class="multipaging__customBtn">
          </activities-app-button>
        </div>
        <div class="multipaging__data-section">
          <div class="multipaging__client-data seat-border-color" (click)="selectData(client)"
            *ngFor="let client of res[1]" [ngClass]="{'multipaging__selected-border': client.selected == true}">
            <div class="multipaging__client-name" matTooltipClass="tooltip-class"
              title="{{client?.Contact | formattedName}}">
              {{client?.Contact | formattedName}}</div>
            <div class="multipaging__client-covers">{{'covers' | translate}} {{client.Size}}</div>
            <svg id="svg" class="checkmark position-absolute" *ngIf="client.selected == true" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>

<div class="message-view seat-header-two">
  <h5>{{'SelectTheMessage' | translate}}</h5>
  <hr>
  <div class="message-view__data-section">
    <ng-scrollbar>
      <div class="message-view__client-data seat-border-color" *ngFor="let message of predefinedMessages; let i=index"
        [ngClass]="{'message-view__selected-border':selectedMessage.indexOf(message.Id) != -1}"
        (click)="selectMessage(message)">
        <div class="seat-secondary-text">{{message.Text.Message}}</div>
        <svg *ngIf="selectedMessage.indexOf(message.Id) != -1" class="checkmark position-absolute" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      </div>
    </ng-scrollbar>
  </div>
  <div class="message-view__btn-Container">
    <activities-app-button [buttontype]="buttonCancel" (click)="close()"></activities-app-button>
    <activities-app-button [buttontype]="buttonSend" (click)="sendMessage()"></activities-app-button>
  </div>
</div>