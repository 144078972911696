<section class="pop-container h-100">
  <div class="pop-head">
    <label class="inner-header pl-4 LW20">{{data.header | uppercase}}</label>
    <i  aria-hidden="true" *ngIf="data.closebool" class="material-icons inner-close IC30 float-md-right cursPointer"
      (click)="DialogClose(false)">close</i>
  </div>
  <div class="pop-contents">
    <div class="upload-numbers">
      <span class="mr-3">{{retailCaptions.ImportNotProcessed}} - {{NotProcessedCount}}</span>
      <span class="mr-3 green">{{retailCaptions.ImportProcessed}} - {{ProcessedCount}}</span>
      <span class="red">{{retailCaptions.ImportFailed}} - {{FailedCount}}</span>
    </div>
      <app-retail-input-table [EnableEdit]="editData" [disableActions]="disableEditButton" [options]="tableoptions" [searchString]="searchText" (editEvt)="EditRecords($event)" (EdittedData)="EditRecords($event)" (RowSelectEmitter)="EnableSaveAction($event)"></app-retail-input-table>
  </div>

  <div mat-dialog-actions class="actions-div">
    <div class="pop-button pl-4">
      <button [ngClass]="enableSave ? 'button--primary-save' : 'button--disabled'" mat-button
        [disabled]="!enableSave" (click)="Save()">{{saveButton}}</button>
      <button class="spa-button-cancel" mat-button (click)="Cancel()">{{cancelButton}}</button>
    </div>
  </div>
</section>
