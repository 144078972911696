import { ChangesByScreenDTO, CommonParamsDto } from "../models/SignalRDto";

declare function postMessage(message: any): void;

export function GetChangesByScreen(input: ChangesByScreenDTO) {
  enum HttpVerbs {
    GET, POST, PUT, DELETE
  }
  GetChangesByScreenAPI();
  function GetChangesByScreenAPI() {
    const switchUrl = `${input.CommonParams.BaseUrl}?restaurantId=${input.restaurantId}&afterRevision=${input.revisionId}&screenName=${input.screenName}`;
    return Get(switchUrl, input.CommonParams, GetCallback);
  }

  function PostMessage(change: any) {
    postMessage(change);
  }

  function GetCallback(data): void {
    console.log("Web worker is about to post message !");
    PostMessage(data);
  }

  function Get(baseUrl: string, message: CommonParamsDto, GetCallback: any): any {
    return callWebApi(baseUrl, HttpVerbs.GET, message, GetCallback, null);
  }

  function callWebApi(url: string, verb: HttpVerbs, commonParams: CommonParamsDto, callback: any, data: any): void {

    let xhr = new XMLHttpRequest();

    xhr.onload = function () {
      let data = JSON.parse(xhr.responseText);
      callback(data);
    }

    xhr.onerror = function () {
      alert("Error while calling Web API");
    }

    let httpVerb: string;
    switch (verb) {
      case HttpVerbs.GET:
        httpVerb = "GET";
        break;
      case HttpVerbs.POST:
        httpVerb = "POST";
        break;
      case HttpVerbs.PUT:
        httpVerb = "PUT";
        break;
      case HttpVerbs.DELETE:
        httpVerb = "DELETE";
        break;
    }

    xhr.open(httpVerb, url);
    xhr.setRequestHeader('x-ts-client-uid', commonParams.ClientUid)
    xhr.setRequestHeader('x-ts-merchant-auth-key', commonParams.MerchantKey)
    xhr.setRequestHeader('x-ts-restaurant-api-key', commonParams.RestaurantApiKey)
    xhr.setRequestHeader('x-ts-host-id', commonParams.HostId)
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("ngsw-bypass","");

    if (data == null) {
      xhr.send();
    }
    else {
      xhr.send(JSON.stringify(data));
    }
  }
}