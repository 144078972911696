<!--categories filter-->
<section [formGroup]="VendorInventoryDetailFormGrp" autocomplete="off">
  <div>
    <app-retail-multi-select-dropdown *ngIf="vendorInfo" [dropDownFilterData]="vendorInfo"
      [dropDownControlname]="'vendorInfo'" [dropDownName]="captions.VendorList"
      (dropDownFrmControl)="getFormControlValue($event)" (IsAnySelected)="IsSelectionValid($event)">
    </app-retail-multi-select-dropdown>
  </div>
  <app-retail-category-component (formReady)="formInitialized($event)" (IsValidSelection)="IsSelectionValid($event)"
  [fromJobSchedulerEdit] = "isFromEdit" [editValues] = "categoryComponentFilter">
  </app-retail-category-component>
  <!--category filter End-->
  <div class="mb-3">
    <label class="LW14">{{captions.UseLastCostMethod}}</label>
    <app-retail-toggle-switch class="d-block" formControlName="lostCostMethod"></app-retail-toggle-switch>
  </div>
  <mat-checkbox class="form-group" formControlName="orderBySupplierItem">{{captions.OrderBySupplierItem}}</mat-checkbox>
  <div class="mb-3" *ngIf="!this.hideDisposableItem">
    <label class="LW14">{{captions.DisposableItems}}</label>
    <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleDisposableItems($event[0])"
      formControlName="DisposableItems"></app-retail-toggle-switch>
  </div>
  <div class="mb-3">
    <mat-radio-group class="report-radio-group" formControlName="itemFilter">
      <mat-radio-button *ngFor="let data of radioFilter" class="report-radio-button" [value]="data.id">
        {{data.name}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</section>