import { Pipe, PipeTransform } from '@angular/core';
import { PartyState } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { environment } from '@environments/environment';
import { SettingsDTO } from '@models/RestaurantDTO';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Pipe({ name: 'EditOrSeatAction' })

export class EditOrSeatActionPipe implements PipeTransform {
  _settings: SettingsDTO = {} as SettingsDTO;
  _restaurantTime: any;
  subscriptions: Subscription = new Subscription();
  constructor(public cs: CacheService) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
    this._restaurantTime = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
  }
  transform(DateTime: any, State: PartyState) {
    if (State == PartyState.Left) {
      return false;
    }
    var dt = moment(this._restaurantTime._d.setHours(0, 0, 0, 0))
    var VisibilityLimit = dt.subtract({ hours: environment.MaxMinutesBackToEditOrSeatReservation });
    return (Utilities.parseDateString(DateTime) >= new Date(VisibilityLimit.valueOf()) && State == PartyState.Pending);
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
