import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
import { ITabOutputDetails } from '@app/settings/models/common.interface';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { GeneralDataComponent } from '@components/create-update-party-tab-layout/selections/general-data/general-data.component';
import { GuestDataComponent } from '@components/create-update-party-tab-layout/selections/guest-data/guest-data.component';
import { OverrideRemainingTimeComponent } from '@components/override-remaining-time/override-remainingtime';
import { PreferredTableComponent } from '@components/preferred-table/preferred-table.component';
import { SelectServerComponent } from '@components/select-server/select-server.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { BookingBehavior, CancelActivityType, ClassType, ComponentTypes, FinancialEffectAction, FinancialEffectType, OperationResultState, PartyNoteType, PartyState, PartyType, PopupType, PricingBy, ReservationEmailNotificationType, ReservationType, RolesAndPermissionsType, SlottingMode, Status, UpdatedPartySlotType } from '@constants/commonenums';
import { DEFAULT_LANGUAGE_CODE, DEFAULT_LANGUAGE_ID, notes, popupDialogDimension } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { PageMethod, PartyPrepaymentState, ReservationDTO } from '@models/InputContact';
import { AddOnRequest, DateShiftStateDTO, DateStateDTO, StandByPartyDTO, UpdatedPartySlotDTO, UpdatedReservationDTO, UpdatedStandbyPartyDTO, UpdatedWalkInDTO, WalkInDTO } from '@models/InputReservation';
import { BookingChargeType, CollectType, FloorPlanDTO, LayoutDTO, PartyDTO, PartyEmailSendBehavior, PredefinedContactNoteDTO, PredefinedPartyNoteDTO, SettingsDTO, StaffBlockDTO, StandaloneTableDTO, StateDTO, SubCategoryDto, SupportedReservationEmailConfirmationTypes } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails, TabConfig } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import _, { uniqBy } from 'lodash';
import moment from 'moment';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { ActivityPaymentSummaryComponent } from '../components/activity-payment-summary/activity-payment-summary.component';
import { CancelActivityPopupComponent } from '../components/cancel-activity-popup/cancel-activity-popup.component';
import { AdditionalinfoComponent } from '../components/create-update-guest-tab-layout/definitions/additionalinfo/additionalinfo.component';
import { AddressComponent } from '../components/create-update-guest-tab-layout/definitions/address/address.component';
import { ContactComponent } from '../components/create-update-guest-tab-layout/definitions/contact/contact.component';
import { PersonalInfoComponent } from '../components/create-update-guest-tab-layout/definitions/personal-info/personal-info.component';
import { PreferencesComponent } from '../components/create-update-guest-tab-layout/definitions/preferences/preferences.component';
import { ActivityBookingFormComponent } from '../components/create-update-party-tab-layout/selections/activity-booking-form/activity-booking-form.component';
import { ActivityBookingSummaryComponent } from '../components/create-update-party-tab-layout/selections/activity-booking-summary/activity-booking-summary.component';
import { GuestSelectionComponent } from '../components/create-update-party-tab-layout/selections/guest-selection/guest-selection.component';
import { OpenBookingFormComponent } from '../components/create-update-party-tab-layout/selections/open-booking-form/open-booking-form.component';
import { OpenBookingSummaryComponent } from '../components/create-update-party-tab-layout/selections/open-booking-summary/open-booking-summary.component';
import { PrivateLessonBookingFormComponent } from '../components/create-update-party-tab-layout/selections/privatelesson-booking-form/privatelesson-booking-form.component';
import { PrivateLessonBookingSummaryComponent } from '../components/create-update-party-tab-layout/selections/privatelesson-booking-summary/privatelesson-booking-summary.component';
import { OpenPaymentSummaryComponent } from '../components/open-payment-summary/open-payment-summary.component';
import { PrivatelessonPaymentSummaryComponent } from '../components/privatelesson-payment-summary/privatelesson-payment-summary.component';
import { ReasonCollectionPopupComponent } from '../components/reason-collection-popup/reason-collection-popup.component';
import { RedirectToModules } from '@app/common/shared/shared/utilities/common-utilities';
import { FacadeService } from '../services/facade.service';
import { TheatreBookingFormComponent } from '../components/create-update-party-tab-layout/selections/theatre-booking-form/theatre-booking-form.component';
import { AlertType, ButtonType } from '@app/common/enums/shared-enums';
import { AddOnForBookingComponent } from '../components/add-on-for-booking/add-on-for-booking.component';
import { CommentType, RatePlanCalculationInfoNegotiation } from '../models/ActivityRatePlanRequestDTO';
import { CurrencyDecimalFormater } from '../pipes/currency-decimal.pipe';
import { RoleType } from '../models/LoginResultDTO';
import { SpecialmealBookingFormComponent } from '../components/create-update-party-tab-layout/selections/specialmeal-booking-form/specialmeal-booking-form.component';
import { SpecialmealBookingSummaryComponent } from '../components/create-update-party-tab-layout/selections/specialmeal-booking-summary/specialmeal-booking-summary.component';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { SlotsSectionService } from '../components/tablelist/slots/slots-section.service';
import { ExclusiveSessionDateDTO, LockSessionRequestDTO } from '../models/ActivityLockSessionDTO';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { TablesService } from '../services/tables.service';
import { PricingStrategy, RatePlanCalculator } from '../models/RatePlanDTO';
import { CustomTabsComponent } from '../components/custom-tabs/custom-tabs.component';
import { ReservationGuestsComponent } from '../components/create-update-party-tab-layout/selections/reservation-guests/reservation-guests.component';
import { ReservationSummaryComponent } from '../components/create-update-party-tab-layout/selections/reservation-summary/reservation-summary.component';
import { BlockStaffComponent } from '../components/block-staff/block-staff.component';
import { ServerService } from '../services/server.service';

@Injectable()
export class DashboardFunctions extends Utilities implements OnDestroy {
  cancelReservationBool = false;
  showAlert = false;
  noShowFeePopUp = false;
  noShowSet = false;
  serverId = null;
  selectedSpecialMealName = "";
  serversSubscription: ISubscription;
  type: ReservationType;
  reservationSubscription: ISubscription;
  waitlistSubscription: ISubscription;
  standbySubscription: ISubscription;
  standbyCancelSubscription: ISubscription;
  cancelConfSubscription: ISubscription;
  cancelSubscription: ISubscription;
  confirmSubscription: ISubscription;
  openBookingSubscription: ISubscription;
  privateLessonBookingSubscription: ISubscription;
  activityBookingSubscription: ISubscription;
  privateActivityBookingSubscription: ISubscription;
  openBookingCartSubscription: ISubscription;
  privateLessonBookingCartSubscription: ISubscription;
  activityBookingCartSubscription: ISubscription;
  privateActivityBookingCartSubscription: ISubscription;
  removeDialog: MatDialogRef<any>;
  convertConfSubscription: ISubscription;
  convertDialog: MatDialogRef<any>;
  preferredTablecancelSubscription: ISubscription;
  preferredTableconfirmSubscription: ISubscription;
  cancelPopUpConfirmSubscription: ISubscription;
  cancelPopUpCloseSubscription: ISubscription;
  openSeatPartyConfirmdialogRef: MatDialogRef<any>;
  prefferedTabledialogRef: MatDialogRef<any>;
  subscriptions: Subscription = new Subscription();
  OldTableIds;
  negotiateServiceCharge = false;
  enabledNegotiation = false;
  currentPackageInfo: string;
  constructor(dialog: MatDialog, private appService: AppService, public translateService: TranslateService,
    public currencyDecimalFormater: CurrencyDecimalFormater, private popupService: PopupService, private partyService: PartyService, private cs: CacheService, private datePipe: DatePipe, private gbs: GuestBookService, private fs: FacadeService, private utilities: CommonUtilities,
    private api: ApiService, public ts: TablesService, public serverService: ServerService) {
    super(dialog);
  }

  ngOnDestroy() {
    if (this.serversSubscription) {
      this.serversSubscription.unsubscribe();
    }
    if (this.preferredTableconfirmSubscription) {
      this.preferredTableconfirmSubscription.unsubscribe();
    }
    if (this.preferredTablecancelSubscription) {
      this.preferredTablecancelSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  overrideRemainingtime(party: any) {
    const popupInput = { dashboardData: party, seatParty: true };
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(OverrideRemainingTimeComponent, 'small', 'active', popupInput);
    const dialogRef = this.openCustomPopup(componentDetails, ComponentTypes.seatParty, '400px', '350px', false, this.translateService.instant('expectedDepartureTime'), 'Override', 'Cancel', false);
    this.subscriptions.add(dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.partyService.overrideDepartureTime(party.Id);
      }
    }));
  }

  /*** Function to seat a party ***/
  seatParty(party: any, from?: ComponentTypes) {
    if(this.partyService?.reservationFormGroup?.get('selectedTable')?.value){
      this.partyService.reservationFormGroup.get('selectedTable').setValue('');
    }
    if (this.preferredTableconfirmSubscription) { this.preferredTableconfirmSubscription.unsubscribe(); }
    if (this.preferredTablecancelSubscription) { this.preferredTablecancelSubscription.unsubscribe(); }
    this.partyService.reservationDateForBlockingRule = new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    const popupInput = { dashboardData: party, seatParty: true , propertyTime :  this.partyService.reservationDateForBlockingRule };
    this.popupService.restrictCloseDialog = true;
  
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(PreferredTableComponent, 'large', 'active', popupInput);
    this.prefferedTabledialogRef = this.openCustomPopup(componentDetails, ComponentTypes.table, '100%', '100%', false, this.translateService.instant('preferredTable'), 'ok',
      'Cancel', false, '100vw', 'preferred-table-panel', true);
    this.subscriptions.add(this.preferredTableconfirmSubscription = this.popupService.confirmedAction$.subscribe((result) => {
      if (result && result == ComponentTypes.table) {
        let tableWithSpecialEvent = this.partyService.getSpecialEventAvailability(this.partyService.SelectedTableIds);
        if(tableWithSpecialEvent?.length){
          this.specialEventValidation(tableWithSpecialEvent , party);
        }else{
          this.seatPartyAfterConfirmation(party);
        }
      }
      if (result && result == ComponentTypes.reservation && this.openSeatPartyConfirmdialogRef && this.openSeatPartyConfirmdialogRef.componentInstance) {
        this.popupService.restrictCloseDialog = false;
        this.openSeatPartyConfirmdialogRef.close(true);
        this.prefferedTabledialogRef ? this.prefferedTabledialogRef.close() : '';
      }
    }));

    this.preferredTablecancelSubscription = this.popupService.cancelledAction$.subscribe((data) => {
      if (data && data.from === ComponentTypes.table) {
        this.popupService.restrictCloseDialog = false;
        this.prefferedTabledialogRef ? this.prefferedTabledialogRef.close() : '';
      }
      if (data && data.from === ComponentTypes.reservation && this.openSeatPartyConfirmdialogRef && this.openSeatPartyConfirmdialogRef.componentInstance) {
        this.openSeatPartyConfirmdialogRef.close();
      }
    });

    this.subscriptions.add(this.prefferedTabledialogRef.afterClosed().subscribe(() => {
      this.popupService.restrictCloseDialog = false;
      this.partyService.reservationDateForBlockingRule = null;
      if (this.preferredTableconfirmSubscription) { this.preferredTableconfirmSubscription.unsubscribe(); }
      if (this.preferredTablecancelSubscription) { this.preferredTablecancelSubscription.unsubscribe(); }
    }));
  }
  specialEventValidation(tableWithSpecialEvent , party) {
    let dialogData = this.partyService.specialEventValidation(tableWithSpecialEvent);
    dialogData.data['service'] = this.popupService;
    let dialogRef = this.dialog.open(AppPopupComponent , dialogData);
      let confirmSubscription = this.popupService.confirmedAction$.subscribe((data) => {
        if (data && data == ComponentTypes.specialEventSelection) {
          this.seatPartyAfterConfirmation(party , true);
        }
      });

      let cancelSubscription = this.popupService.cancelledAction$.subscribe((data) => {
        if (data && data.from == ComponentTypes.specialEventSelection && cancelSubscription) {
          //this.setSelectedTableData(false)
        }
      });

      dialogRef.afterClosed().subscribe(() => {
        confirmSubscription?.unsubscribe();
        cancelSubscription?.unsubscribe();
        if (this.popupService.isRestrictCloseDialog) {
          this.popupService.restrictCloseDialog = true;
        }
      });
    }
  seatPartyAfterConfirmation(party , ignoreEventBlock:boolean=false){
    const assignedTable = [];
    const allSelectedTables = [];
    this.partyService.SelectedTableIds.forEach((table => {
      allSelectedTables.push(table);
      assignedTable.push(this.cs.state.value.TableServerAssignments.filter(x => x.StandaloneTableId === table)[0]);
    }));
    this.OldTableIds = party.TableIds;
    party.TableIds = [];
    party.TableIds.push(...allSelectedTables);
    const isServersAvailable = _.every(assignedTable, 'ServerId');
    if (isServersAvailable) {
      this.checkForSeatedParty(party , null , ignoreEventBlock);
    } else {
      const isServersNotAvailable = _.every(assignedTable, ['ServerId', null]);
      const isServerIdNotAvailable = (!_.every(assignedTable, 'ServerId') && !_.find(assignedTable, 'ServerId'))
      if (isServersNotAvailable || isServerIdNotAvailable) {
        this.popupService.restrictCloseDialog = false;
        this.prefferedTabledialogRef ? this.prefferedTabledialogRef.close() : '';
        this.addPreferedServer(party);
      } else {
        const uniqueServers = _.uniqBy(assignedTable, 'ServerId').filter((table) => table.ServerId);
        if (uniqueServers.length > 1) {
          this.popupService.restrictCloseDialog = false;
          this.prefferedTabledialogRef ? this.prefferedTabledialogRef.close() : '';
          this.addPreferedServer(party);
        } else {
          const allAvailbaleServers = assignedTable.filter((table) => table.ServerId);
          let serverId = null;
          if (allAvailbaleServers.length > 0) {
            serverId = allAvailbaleServers[0].ServerId;
          }
          this.checkForSeatedParty(party, serverId , ignoreEventBlock);
        }
      }
    }
  }

  checkForSeatedParty(party, serverId? , ignoreEventBlock: boolean=false) {
    const presentSeatedArray = [];
    party.TableIds.forEach(element => {
      this.cs.state.value.SeatingParties.forEach(status => {
        const tables: StandaloneTableDTO[] = [];
        let isCommunal = false;
        this.cs.layout.value.FloorPlans.forEach((floorDetails: FloorPlanDTO) => {
          tables.push(...floorDetails.StandaloneTables.filter((table: StandaloneTableDTO) => table.Id === element &&
            table.IsCommunalTable));
        });
        if (tables.length > 0) {
          isCommunal = true;
        }
        const data = status.TableIds.filter(x => x === element)[0];
        if (data && !isCommunal) {
          presentSeatedArray.push(data);
        }
      });
    });
    if (presentSeatedArray.length > 0) {
      this.openSeatPartyConfirm(party, serverId , ignoreEventBlock);
    } else {
      this.popupService.restrictCloseDialog = false;
      this.prefferedTabledialogRef ? this.prefferedTabledialogRef.close() : '';
      this.confirmSeatParty(party, serverId , ignoreEventBlock);
    }
  }

  /** Add Preferred Server */
  addPreferedServer(party) {
    this.serversSubscription = this.partyService.selectedServers.subscribe(data => {
      this.serverId = data.Id;
    });
    const componentDetails = Utilities.setComponentDetails(SelectServerComponent, 'large', 'active');
    const dialogRef = this.openCustomPopup(componentDetails, ComponentTypes.assignServer, '90%', '90%', true, 'Assign Server', 'Ok',
      'Cancel', false, null, null, null, false);
    this.subscriptions.add(dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.checkForSeatedParty(party, this.serverId);
      } else {
        if (this.OldTableIds) {
          party.TableIds = this.OldTableIds;
        }
      }
      this.gbs.selectedServers = null;
    }));
  }

  seatPartyFromCanvas() {
    const assignedTable = [];
    const allSelectedTables = [];
    let party = this.partyService.selectedTableObjectForDrag;
    this.partyService.SelectedTableIds.forEach((table => {
      allSelectedTables.push(table);
      assignedTable.push(this.cs.state.value.TableServerAssignments.filter(x => x.StandaloneTableId === table)[0]);
    }));
    this.OldTableIds = party.TableIds;
    party.TableIds = [];
    party.TableIds.push(...allSelectedTables);
    const isServersAvailable = _.every(assignedTable, 'ServerId');
    if (isServersAvailable) {
      this.checkForSeatedParty(party);
    } else {
      const isServersNotAvailable = _.every(assignedTable, ['ServerId', null]);
      const isServerIdNotAvailable = (!_.every(assignedTable, 'ServerId') && !_.find(assignedTable, 'ServerId'))
      if (isServersNotAvailable || isServerIdNotAvailable) {
        this.popupService.restrictCloseDialog = false;
        this.prefferedTabledialogRef ? this.prefferedTabledialogRef.close() : '';
        this.addPreferedServer(party);
      } else {
        const uniqueServers = _.uniqBy(assignedTable, 'ServerId').filter((table) => table.ServerId);
        if (uniqueServers.length > 1) {
          this.popupService.restrictCloseDialog = false;
          this.prefferedTabledialogRef ? this.prefferedTabledialogRef.close() : '';
          this.addPreferedServer(party);
        } else {
          const allAvailbaleServers = assignedTable.filter((table) => table.ServerId);
          let serverId = null;
          if (allAvailbaleServers.length > 0) {
            serverId = allAvailbaleServers[0].ServerId;
          }
          this.checkForSeatedParty(party, serverId);
        }
      }
    }
    this.partyService.SelectedTableIds = [];
  }

  showWarningInfoPopUp(message, componentType, popupHeight, popupWidth, title, popupType = 'active', attendee?, skipFinancialEffect?, collectDue?) {
    let cancelText = popupType == 'active' ? 'No' : '';
    // let title = 'Confirm Check-in';
    //let msg = 'No card details collected to capture payment amount. Are you sure to checkin the attendee?'
    let noShowSet = false;
    let confirmCheckin = false;
    let updateText = popupType == 'active' ? 'Yes' : 'Ok';
    let showAlert = popupType != 'active';
    let noShowFeePopUp = false;

    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: title, showAlert: showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: popupType,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    let dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: popupWidth,
      height: popupHeight,
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: componentType,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: true,
      }
    });
    let confirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (collectDue && componentType && componentType == ComponentTypes.CheckinSessionWithEdit && this.checkEditCheckedInReservationsConfig() && attendee) {
        this.checkInWithDues(attendee);
      }
      else if (componentType && componentType == ComponentTypes.CheckinSessionWithEdit && this.checkBookPastDaysReservationsConfig() && attendee) {
        if (attendee.RatePlanAmount > 0 && (attendee.PrepaymentState == PartyPrepaymentState.PrepaymentRequired || attendee.PrepaymentState == PartyPrepaymentState.PrepaymentFailed) && !skipFinancialEffect) {
          this.confirmSeatParty(attendee);
        }
        else {
          this.partyService.checkInOpenBooking(attendee);
        }
      }
    });
    dialogRef.afterClosed().subscribe(event => {
      if (confirmSubscription)
        confirmSubscription.unsubscribe();
    });

  }


  CheckIn(attendee, skipFinancialEffect?, collectDue?) {

    if (moment(moment(attendee.SeatingTime).format('YYYY-MM-DDTHH:mm')).diff(moment(moment(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)).format('YYYY-MM-DDTHH:mm')), 'minutes') > this.cs.settings.value.General.TimeSlotUnitInMinutes) {
      let message = this.translateService.instant('timeNotArrivedMessage') + moment(attendee.SeatingTime).locale(this.getLocaleCode()).format("DD/MM/yyyy hh:mm a");
      this.showWarningInfoPopUp(message, ComponentTypes.CheckinSession, "350px", "450px", this.translateService.instant('CheckinInfoText'), 'action');
    }
    else if (moment(moment(attendee.DepartureTime).format('YYYY-MM-DDTHH:mm')).diff(moment(moment(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)).format('YYYY-MM-DDTHH:mm')), 'minutes') <= 0) {
      let message = this.translateService.instant('sessionExpiredMessage');
      if (this.checkBookPastDaysReservationsConfig()) {
        message = message + this.translateService.instant('warningForProceeding');
      }
      this.showWarningInfoPopUp(message, this.checkBookPastDaysReservationsConfig() ? ComponentTypes.CheckinSessionWithEdit : ComponentTypes.CheckinSession, "350px", "450px", this.translateService.instant('CheckinInfoText'), !this.checkBookPastDaysReservationsConfig() ? 'action' : 'active', attendee, skipFinancialEffect, collectDue);

    }
    else {
      if (collectDue) {
        this.checkInWithDues(attendee);
      }
      else {
        if (attendee.RatePlanAmount > 0 && (attendee.PrepaymentState == PartyPrepaymentState.PrepaymentRequired || attendee.PrepaymentState == PartyPrepaymentState.PrepaymentFailed) && !skipFinancialEffect) {
          this.confirmSeatParty(attendee);
        }
        else {
          this.partyService.checkInOpenBooking(attendee);
        }
      }
    }
  }

  checkInWithDues(bookingDetail) {
    this.partyService.selectedParty$.next(bookingDetail);
    this.partyService.getFinancialDetails(bookingDetail.Id, FinancialEffectAction.CheckIn,null,null).subscribe(data => {
      console.log(data);
      this.partyService.openCheckInWithDuesPopUp(bookingDetail, data.Payload, ComponentTypes.CheckInRatePlanSummary, false);
    })
  }

  confirmSeatParty(party, serverId? , ignoreEventBlock:boolean = false) {
    const serverIdMapped = serverId ? serverId : null;
    this.partyService.seatParty(party, serverIdMapped , ignoreEventBlock);
  }

  openSeatPartyConfirm(party, serverId? , ignoreEventBlock:boolean = false) {
    this.openSeatPartyConfirmdialogRef = null;
    const popUpMessage = [{
      confirmationMessage: `${this.translateService.instant('currentlySeated')} ${party && party.Contact && party.Contact.FirstName ? party.Contact.FirstName : ''} ${party && party.Contact && party.Contact.LastName ? party.Contact.LastName : ''}?`,
      dialogTitle: 'confirm'
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'active', popUpMessage,
      popUpMessage[0].dialogTitle);
    this.openSeatPartyConfirmdialogRef = this.openCustomPopup(componentDetails, ComponentTypes.reservation, '450px', 'auto', false, this.translateService.instant('deleteTitle'), this.translateService.instant('ok'),
      'Cancel', false);
    this.subscriptions.add(this.openSeatPartyConfirmdialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.confirmSeatParty(party, serverId , ignoreEventBlock);
      }
      else {
        if (this.OldTableIds) {
          party.TableIds = this.OldTableIds;
        }
      }
    }));
  }

  showCommunalTableError() {
    const popUpMessage = [{
      confirmationMessage: `${this.translateService.instant('communalTableError')}`,
      dialogTitle: this.translateService.instant('tableSelection'),
      showAlert: true
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', popUpMessage,
      popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.reservation, '450px', 'auto', true, '', 'Ok',
      'Cancel', true);
  }

  seatPartyConfirmation(val, party) {
    const assignedTable = [];
    this.partyService.SelectedTableIds.forEach((table => {
      this.subscriptions.add(this.cs.state.subscribe((value: StateDTO) => {
        assignedTable.push(value.TableServerAssignments.filter(x => x.StandaloneTableId === table)[0]);
      }));
    }));
    const unassignedTableServers = assignedTable.filter((table) => !table.ServerId);
    let assignServerText = this.translateService.instant('assignserverbuttontext');
    if (unassignedTableServers.length > 0) {
      const componentInfo = Utilities.setComponentDetails(SelectServerComponent, 'large', 'action', '');
      const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.table,
        popupDialogDimension.createDialogWidth, popupDialogDimension.createDialogHeight, false, assignServerText, 'ok', 'cancel', true, null, null, null, false);

    } else {
      const presentSeatedArray = [];
      party.TableIds.forEach(element => {
        this.subscriptions.add(this.cs.state.subscribe((value: StateDTO) => {
          value.SeatingParties.forEach(status => {
            const data = status.TableIds.filter(x => x === element)[0];
            if (data) {
              presentSeatedArray.push(data);
            }
          });
        }));
      });
    }
    if (assignedTable) {
    } else {
      const componentInfo = Utilities.setComponentDetails(SelectServerComponent, 'large', 'action', '');
      const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.table,
        popupDialogDimension.createDialogWidth, popupDialogDimension.createDialogHeight, false, assignServerText, 'ok', 'cancel', true, null, null, null, false);

    }
  }

  cancelOpenBooking(party) {
    if (this.appService.OTASourceId.includes(party.PartySourceId)) {
      this.showOTAAlert(party);
      return;
    }
    let cancelText = 'No';
    let isRetailEnabled = Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)
    let title = this.translateService.instant('CancelConfirmationText');;
    let packageName;
    if (party.PackageId) {
      packageName = this.cs.availablePackages.find(pack => pack.Id == party.PackageId)?.Name;
    }
    let packageValidationMessage;
    this.translateService.get('packageCancelConfirmation', { packageName }).subscribe(msgVal => {
      packageValidationMessage = msgVal;
    })
    let msg = party.BookingId && !isRetailEnabled ? this.translateService.instant("cancelAllConfirmationText") : packageName ? packageValidationMessage :  this.translateService.instant("cancelThisConfirmationText");
    let noShowSet = false;
    let cancelReservation = false;
    let updateText = 'Yes';
    let showAlert = false;
    let noShowFeePopUp = false;
    this.ts.selectedTableBeforeAction$.next(null);
    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm', showAlert: showAlert, displayOptions: party.BookingId && !isRetailEnabled ? true : false
    }];

    const componentDetails: ComponentDetails = {
      componentName: CancelActivityPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: popUpMessage[0].displayOptions ? '450px' : '350px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      if (cancelReservation) {
        if (this.partyService.cancelType === CancelActivityType.OnlyThis) {
          this.getOpenBookingFinancialEffect(party);
        } else {
          this.subscriptions.add(this.partyService.cancelMultipleActivity(party.BookingId).subscribe(
            (data) => {
              cancelReservation = false;

              if (data.Payload != null) {
                this.partyService.reservationType = ReservationType.Reservation;
                let message = this.getRefundStateMessageForMultiplePartyCancellation(data.Payload);
                if (message) {
                  this.partyService.openConfirmationDialog(data, message, null);
                }
                if (data.Payload && party.Contact.EmailAddress && (this.cs.settings.value.General.HostCancellationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
                  var copyResponse = _.cloneDeep(data);
                  copyResponse.Payload = copyResponse.Payload[party.Id];
                  this.partyService.openConfirmationDialog(copyResponse, null, null, ReservationEmailNotificationType.Cancelled);
                }
              }
              this.appService.showDashboard = false;
            }));
        }
      } else if (noShowSet && noShowFeePopUp) {
        this.subscriptions.add(this.partyService.noShowParty(party.Id).subscribe((data) => {
          if (data.State == OperationResultState.Success) {
            noShowSet = false;
            this.appService.showDashboard = false;
          }
        }));
      }
    }));

    this.subscriptions.add(this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.reservation && noShowFeePopUp) {
        noShowSet = true;
      }
      if (val === ComponentTypes.reservation && !noShowFeePopUp) {
        cancelReservation = true;
      }
    }));
    this.subscriptions.add(this.popupService.cancelledAction$.subscribe(val => {
      if (val.value === ComponentTypes.reservation && !cancelReservation && noShowFeePopUp) {
        cancelReservation = true;
      }
    }));
    event.preventDefault();
    event.stopPropagation();
  }


  getOpenBookingFinancialEffect(party) {
    let cancelledItems =[{
      "BookedSessionId": null,
      "Amount": 0 //no cancellation fee for open booking
        }]
        //getcancellationcharge
        this.partyService.getCancelFinancialDetails(party.Id, cancelledItems , null , null).subscribe(data => {
      if (data.Payload != null) {
        this.partyService.selectedBooking = party;
        let financialData = data.Payload;
        this.partyService.FinancialEffectId = data.Payload.FinancialEffectId;
        this.partyService.reservationType = ReservationType.Reservation;
        if ((financialData.ReturnItem && financialData.ReturnItem.length) || (financialData.ShopItems && financialData.ShopItems.length) && Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)) {
          this.partyService.Operations = 3;
          this.partyService.BookingBehaviour = BookingBehavior.OpenBooking;
          this.partyService.cancelBookingDataObj = party;
          this.partyService.cancelBookingDataObj.ReservationAttemptId = data.Payload?.RatePlan?.Reservations[0]?.ReservationAttemptId;
          this.partyService.cancelBookingDataObj.CancellationFee = financialData?.CancellationAmount;
          const { EmailAddress, FirstName, LastName } = party.Contact;
          data.Payload = { ...data.Payload, ...party, EmailAddress, FirstName, LastName, PartyId: party.Id };
          this.partyService.bookingCancellationPayload = data;
          this.partyService.RetailCanceloperation(party, financialData);
        } else {
          this.partyService.cancelOpenBooking(party , financialData);
        }
      }
    })
  }
  getRefundStateMessageForMultiplePartyCancellation(cancelledParties): string {
    let message = null;
    let successFullyRefundedParty = ''
    let successFullyRefundedAmount = 0;
    let amountToBeRefundedManually = 0;
    let amountToBeRefundedManuallyParty = ''
    if (cancelledParties && Object.keys(cancelledParties) && Object.keys(cancelledParties).length > 0) {
      Object.keys(cancelledParties).forEach(key => {
        let party = cancelledParties[key];
        if (party.AutomaticRefundState && party.AutomaticRefundState == 1) {
          successFullyRefundedParty = successFullyRefundedParty + ', ' + this.datePipe.transform(party.ReservationTime, 'dd/MM/yyyy hh:mm a')
          successFullyRefundedAmount = successFullyRefundedAmount + party.RefundAmount;
        }
        else if (party.AutomaticRefundState && party.AutomaticRefundState == 2) {
          amountToBeRefundedManuallyParty = amountToBeRefundedManuallyParty + ', ' + this.datePipe.transform(party.ReservationTime, 'dd/MM/yyyy hh:mm a')
          amountToBeRefundedManually = amountToBeRefundedManually + party.RefundAmount;
        }
      });
      if (successFullyRefundedAmount > 0) {
        message = "A refund for " + this.cs.operationCurrency + successFullyRefundedAmount + " has been successfully processed for the cancelled reservation(s) - " + successFullyRefundedParty.substring(2, successFullyRefundedParty.length) + ". ";
      }
      if (amountToBeRefundedManually > 0) {
        message = message + (message ? "Also " : "") + "An attempted refund for " + this.cs.operationCurrency + amountToBeRefundedManually + " for the reservation(s) failed - " + amountToBeRefundedManuallyParty.substring(2, amountToBeRefundedManuallyParty.length) + ". "
      }

    }
    return message;
  }

  /***** UnSeat Party *****/
  unSeatParty(partyId) {
    this.subscriptions.add(this.partyService.unseatParty(partyId).subscribe((response) => {
      if (response.State == OperationResultState.Success) {
        //this.partyService.triggerReload();
        this.partyService.MoveCheck();
      }
    }));
  }

  /***** Clear Party *****/
  clearParty(partyId) {
    this.subscriptions.add(this.partyService.clearParty(partyId).subscribe((response) => {
      if (response.State == OperationResultState.Success) {
        //this.partyService.triggerReload();
      }
    }));
  }

  /***** Clear Party *****/
  checkOutPrivateLessonBooking(partyId) {
    this.subscriptions.add(this.partyService.checkOutPrivateLessonBooking(partyId).subscribe((response) => {
      if (response.State == OperationResultState.Success) {
        //this.partyService.triggerReload();
      }
    }));
  }
  /***** Cancel Reservation *****/
  cancelReservation(party) {
    this.cancelParty(party);
  }

  cancelParty(party) {
    let cancelText = 'No';
    let title = this.translateService.instant('cancelConfirmationMsg');
    let msg = this.translateService.instant('cancelReservation');
    let updateText = 'Yes';
    this.noShowFeePopUp = false;
    this.noShowSet = false;
    this.cancelReservationBool = false;

    if (Utilities.Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)).diff(Utilities.Date(party.ReservedFor)) > 0) {
      msg = this.translateService.instant('reservationCancelMsg');
      cancelText = this.translateService.instant('cancelReservationMsg');
      this.cancelReservationBool = false;
      updateText = this.translateService.instant('noShowState');
      title = this.translateService.instant('alert');
      this.showAlert = true;
      this.noShowFeePopUp = true;
    }

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm'
    }];
    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'active', popUpMessage, title);
    const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.CancelReservation,
      popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeigth, false, title, updateText, cancelText, false);

    if (this.confirmSubscription) { this.confirmSubscription.unsubscribe(); }
    if (this.cancelSubscription) { this.cancelSubscription.unsubscribe(); }
    let confirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (this.noShowFeePopUp) {
        this.noShowSet = true;
        this.cancelReservationBool = false;
      }
      else {
        this.cancelReservationBool = true;
      }
    });

    let cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (this.noShowFeePopUp && val.value == 0) {
        this.noShowSet = false;
        this.cancelReservationBool = false;
      }
      else if (this.noShowFeePopUp && val.value == 1) {
        this.noShowSet = false;
        this.cancelReservationBool = true;
      } else {
        this.cancelReservationBool = false;
      }
    });

    dialogRef.afterClosed().subscribe(event => {
      this.cancelReservationConfirm(party.Id, party.SeatingTime);
      cancelSubscription.unsubscribe();
      confirmSubscription.unsubscribe();
    });
  }

  cancelReservationChangesOnConfirmation() {
    if (this.noShowFeePopUp) {
      this.noShowSet = true;
    }
    if (!this.noShowFeePopUp) {
      this.cancelReservationBool = true;
    }
  }
  cancelReservationChangesOnCancellation() {
    if (!this.cancelReservation && this.noShowFeePopUp) {
      this.cancelReservationBool = true;
    }
  }

  cancelReservationConfirm(partyId, seatingTime) {
    if (this.cancelReservationBool) {
      this.subscriptions.add(this.partyService.cancelReservation(partyId).subscribe(
        (data) => {
          this.cancelReservationBool = false;
          this.partyService.reservationType = ReservationType.Reservation;
          if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 1) {
            this.partyService.openConfirmationDialog(data, "A refund for " + this.cs.operationCurrency + data.Payload.RefundAmount + " has been successfully processed for this cancelled reservation.", seatingTime);
          }
          if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 2) {
            this.partyService.openConfirmationDialog(data, "An attempted refund for " + this.cs.operationCurrency + data.Payload.RefundAmount + " for this reservation failed. Further attempts to process this refund will need to be performed manually.", seatingTime);
          }
          if (data.Payload && data.Payload.EmailAddress && (this.cs.settings.value.General.HostCancellationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
            this.partyService.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Cancelled);
          }
        }));
    } else if (this.noShowSet && this.noShowFeePopUp) {
      this.subscriptions.add(this.partyService.noShowParty(partyId).subscribe((data) => {
        if (data.State == OperationResultState.Success) {
          this.noShowSet = false;
        }
      }));
    }
  }

  IsReseatApplicable(party) {
    var departureTime = party.DepartureTime;
    var tableIds = party.TableIds;
    var isTableNotSeated = true;
    tableIds.forEach(tableId => {
      if (this.cs.state.value.SeatingParties.filter(sp => sp.TableIds.includes(tableId)).length > 0) {
        isTableNotSeated = false;
        return;
      }
    });
    let currentRestaurantTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    return isTableNotSeated && moment(currentRestaurantTime).diff(moment(departureTime), "minutes") <= this.cs.settings.value.General.MaxMinutesToReseatPartyAfterLeaving;
  }

  cancelWaitlistPartyConfirm(party) {
    const msg = this.translateService.instant('cancelWaitlist');
    const title = this.translateService.instant('cancelConfirmationMsg');
    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm', showAlert: false
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(
      ConfirmationPopupComponent, 'small', 'active', popUpMessage, popUpMessage[0].dialogTitle);
    const dialogRef = this.openCustomPopup(componentDetails, ComponentTypes.CancelDashboardReservation,
      popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeigth, false, title, 'Yes', 'No', false);
    this.subscriptions.add(dialogRef.afterClosed().subscribe((result) => {
      if (result === ComponentTypes.CancelDashboardReservation) {
        this.subscriptions.add(this.partyService.cancelWaitlist(party.Id).subscribe(
          (data) => { }));
      }
    }));
  }

  seatPartyWithConfimation(partyData, fromTables = false) {
    let party = _.cloneDeep(partyData);
    if (party.Type === PartyType.Reservation && party.ReservedFor && Utilities.diffBetweenDates(party.ReservedFor, Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)) > 0) {
      const cancelText = this.translateService.instant('no');
      const title = this.translateService.instant('confirmation');
      const msg = this.translateService.instant('seatPartyConfirmation') + moment(party.ReservedFor).format(this.cs.settings.value.General.DateFormat);
      let seatParty = false;
      const updateText = this.translateService.instant('yes');
      const showAlert = false;
      const popUpMessage = [{
        confirmationMessage: msg, dialogTitle: 'confirm', showAlert
      }];
      const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'active', popUpMessage,
        popUpMessage[0].dialogTitle);
      const dialogRef = this.openCustomPopup(componentDetails, ComponentTypes.reservation, '450px', 'auto', false, title, updateText,
        cancelText, true);
      this.subscriptions.add(dialogRef.afterClosed().subscribe(() => {
        if (seatParty) {
          this.seatParty(party);
        }
        if (confirmedactionSubscription) {
          confirmedactionSubscription.unsubscribe();
        }
        if (cancelledactionSubscription) {
          cancelledactionSubscription.unsubscribe();
        }
      }));

      let confirmedactionSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.reservation) {
          seatParty = true;
        }
      });
      let cancelledactionSubscription = this.popupService.cancelledAction$.subscribe(val => {
        if (val.value === ComponentTypes.reservation) {
          seatParty = false;
        }
      });
    } else {
      if (fromTables) {
        this.seatPartyFromCanvas();
      } else {
        this.seatParty(party);
      }

    }
  }

  setType(type: ReservationType) {
    this.partyService.reservationType = null;
    this.partyService.reservationType = type;
  }

  createOrEditReservation(isEdit: boolean, tabInputData: any, fromAlertAudit?: boolean, type?:ReservationType , customSlot?:any){
    if(this.cs.settings.value?.AdvanceBookingOptionDisabled){
      this.createDiningReservation(isEdit, tabInputData, fromAlertAudit);     
    }else{
      this.createAdvanceBooking(isEdit, tabInputData, fromAlertAudit, type, customSlot);
    }
  }


  createDiningReservation(isEdit: boolean, tabInputData: any, fromAlertAudit?: boolean) {
    if (this.reservationSubscription) {
      this.reservationSubscription.unsubscribe();
    }
    this.partyService.reservationFormGroup.reset();
    this.setType(ReservationType.Reservation);
    this.partyService.partyPageMethod = PageMethod.Manual;
    this.partyService.partyNotes = [];
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '90%',
      width: '90%',
      autoFocus: false,
      data: {
        selectedPopupType: PopupType.Reservation,
        title: 'Add popup',
        update: 'ok',
        cancel: 'cancel',
        componentDetails: this.getReservationTabDetails(isEdit, tabInputData, fromAlertAudit),
        //from: ComponentTypes.reservation,
        from: isEdit ? fromAlertAudit != undefined ? ComponentTypes.notification : ComponentTypes.EditReservation : ComponentTypes.CreateReservation,
        back: true,
        showRestaurantName: true
      }
    });
    this.reservationSubscription = this.popupService.confirmedAction$.subscribe((tabOutput: ITabOutputDetails) => {
      //if (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.reservation) {
      if ((tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.reservation) ||
        (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.CreateReservation) ||
        (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.EditReservation)) {
        const reservationFormDetails = this.partyService.reservationFormGroup.value;
        const createRequest = {
          selectednumber: reservationFormDetails.selectedSize,
          selectedarea: reservationFormDetails.selectedArea,
          selectedType: reservationFormDetails.selectedType,
          selecteddate: reservationFormDetails.selectedDate,
          selectedtime: reservationFormDetails.selectedTime,
          selectedtable: reservationFormDetails.selectedTable,
          selectedguest: reservationFormDetails.selectedGuest,
          selectedSpecialMeal: reservationFormDetails.selectedSpecialMeal,
          selectedCoverTypes: reservationFormDetails.selectedCoverTypes,
          selectedHotelId: reservationFormDetails.selectedHotelId,
          selectedConciergeId: reservationFormDetails.selectedConciergeId,
          selectedPartyStatus: reservationFormDetails.selectedPartyStatus,
          selectedSalesContactIds: reservationFormDetails.selectedSalesContactIds,
        };
        if (tabInputData && !this.checkSpecialMealApplicableForSelectedSlot(tabInputData)) {
          const selectedSpecialMealName = this.selectedSpecialMealName;
          let errorMsg = '';
          this.translateService.get('SpecialMealNotApplicableMsg', { selectedSpecialMealName }).subscribe(msgName => errorMsg = msgName);
          this.showErrorPopUp(errorMsg);
          return;
        }
        if (tabInputData && this.ValidateCovertypeAmount(createRequest)) {
          this.showErrorPopUp(this.translateService.instant('CoverTypeAmountErrorMsg'));
          return;
        }
        const buildedRequest = this.buildReservationRequest(createRequest, tabInputData);
        if (isEdit) {
          if (this.partyService.tryUndoNoShowReservation) {
            this.partyService.undoNoShowReservation(buildedRequest.Id, buildedRequest.Slot);
          }
          else if (this.partyService.isStandbySlotSelected) {
            this.showReservationToStandbyConfirmation(createRequest, tabInputData, dialogRef);
          }
          else {
            let canUpdateReservation = this.partyService.CheckRatePlanDifference(buildedRequest.TableIds, tabInputData);
            if (!canUpdateReservation) {
              //show error popup, on ok click retun
              this.showErrorPopUp(this.translateService.instant('blockReservationUpdate'));
              return;
            }
            this.partyService.updateReservation(buildedRequest, createRequest.selecteddate, dialogRef);
          }
        } else {
          if (this.partyService.isStandbySlotSelected) {
            const buildedRequest = this.buildStandbyRequest(createRequest, tabInputData);
            this.partyService.createStandbyParty(buildedRequest, dialogRef);
          }
          else {
            this.partyService.createReservation(buildedRequest, createRequest.selecteddate, dialogRef);
          }
        }
      }
    });
    this.subscriptions.add(this.popupService.confirmAddToCartAction$.subscribe((tabOutput: ITabOutputDetails) => {
      let isfrombuynow = tabOutput?.fromComponent === ComponentTypes.CreateReservation && tabOutput?.isEdit;
      if (tabOutput && (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.CreateReservation)) {
        const reservationFormDetails = this.partyService.reservationFormGroup.value;
        const createRequest = {
          selectednumber: reservationFormDetails.selectedSize,
          selectedarea: reservationFormDetails.selectedArea,
          selectedType: reservationFormDetails.selectedType,
          selecteddate: reservationFormDetails.selectedDate,
          selectedtime: reservationFormDetails.selectedTime,
          selectedtable: reservationFormDetails.selectedTable,
          selectedguest: reservationFormDetails.selectedGuest,
          selectedSpecialMeal: reservationFormDetails.selectedSpecialMeal,
          selectedCoverTypes: reservationFormDetails.selectedCoverTypes,
          selectedBookingTypes: reservationFormDetails.selectedBookingTypes,
          selectedHotelId: reservationFormDetails.selectedHotelId,
          selectedConciergeId: reservationFormDetails.selectedConciergeId
        };
        if (tabInputData && !this.checkSpecialMealApplicableForSelectedSlot(tabInputData)) {
          const selectedSpecialMealName = this.selectedSpecialMealName;
          let errorMsg = '';
          this.translateService.get('SpecialMealNotApplicableMsg', { selectedSpecialMealName }).subscribe(msgVal => errorMsg = msgVal);
          this.showErrorPopUp(errorMsg);
          return;
        }
        if (tabInputData && this.ValidateCovertypeAmount(createRequest)) {
          this.showErrorPopUp(this.translateService.instant('CoverTypeAmountErrorMsg'));
          return;
        }
        let reservationObject = this.buildReservationRequest(createRequest, tabInputData);
        reservationObject['ReservationType'] = ReservationType.Reservation;
        reservationObject = this.getPolicyInfo(reservationObject);
        reservationObject['LanguageId'] = this.appService.selectedLanguage;
        this.partyService.addBookingToCart(reservationObject, false, createRequest.selecteddate, dialogRef, isfrombuynow, this.reservationSubscription)
      }
    }));
    this.subscriptions.add(dialogRef.afterClosed().subscribe(() => {
      this.partyService.fromGuestCharges = false;
      const slotMode = this.cs.settings.value.General.SlottingMode;
      if (this.partyService.slotUnlockTimer) {
        clearTimeout(this.partyService.slotUnlockTimer);
      }
      if (this.partyService.slotLockId) {
        this.partyService.unlockSlot(this.partyService.slotLockId);
      }
      if (this.reservationSubscription) {
        this.reservationSubscription.unsubscribe();
      }
      this.partyService.partyNotes = [];
      this.partyService.selectedTimeSlot = null;
    }));
  }

  createAdvanceBooking(isEdit: boolean, tabInputData: any, fromAlertAudit?: boolean, type?:ReservationType , customSlot?:any){
    this.setType(type ? type : ReservationType.Reservation);
      this.dialog.open(AppPopupComponent, {
      disableClose: true,
      height: '97vh',
      width: '100%',
      maxWidth: '97vw',
      autoFocus: false,
      data: {
        customSlot: customSlot,
        selectedPopupType:PopupType.Reservation,
        title: 'Add popup',
        update: 'ok',
        addtocart: 'addToCart',
        cancel: 'cancel',
        componentDetails: this.getCustomTabDetails(isEdit, tabInputData, this.getSpecialMealComponentDetails(isEdit , type ?  type : ReservationType.Reservation)),
        from: ComponentTypes.specialMealBooking,
        back: true,
        isfromCart: false,
        showRestaurantName: true
      }
    });
  }
  getCustomTabDetails(isEdit: boolean, tabInputData: any, tabs) {
    this.partyService.tabsModal = {
      tabs: tabs,
      default: false,
      componentInput: tabInputData,
      isNextButtonAvailable: true,
      isEditTab: isEdit,
      //isDirectBooking: this._settings.General.RetailIntegrationDTO.IsEnabled && this._settings.RetailItems && this._settings.RetailItems.length > 0 ? true : false
    };

    return {
      componentName: CustomTabsComponent,
      dimensionType: 'large',
      popupType: 'tabs',
      tabs: this.partyService.tabsModal,
      popUpDetails: {
        isStepper: true,
        eventName: 'notifyParent'
      }
    };
  }
  getSpecialMealComponentDetails(isEdit , type ? : ReservationType) {
    let createText =  type == ReservationType.Reservation ? 'createreservationbuttontext' : 'newStandbyParty' 
    let editText = type == ReservationType.Reservation ? 'editReservation' : 'editStandbyParty'
    let tabsList: TabConfig[] = [
      {
        tabComponent: SpecialmealBookingFormComponent,
        tabLabel: 'reservationDetails',
        tabHeader: isEdit ? editText : createText,
        tabIcon: 'Group-748',
        enabledIcon: true,
        isDisabled: false
      },
      {
        tabComponent: SpecialmealBookingSummaryComponent,
        tabLabel: 'guestDetail',
        tabHeader: isEdit ? editText : editText,
        tabIcon: 'Group-748',
        isDisabled: false
      }
    ];
    return tabsList;
  }

  getReservationTabDetails(isEdit: boolean, tabInputData: any, fromAlertAudit?: boolean) {
    this.partyService.tabsModal = {
      tabs: this.getReservationComponentDetails(isEdit),
      default: false,
      componentInput: tabInputData,
      isNextButtonAvailable: true,
      isEditTab: isEdit,
      customClass: 'equal-header'
    };
    return {
      componentName: TabsComponent,
      dimensionType: 'large',
      popupType: 'tabs',
      tabs: this.partyService.tabsModal,
      popUpDetails: {
        isStepper: true,
        eventName: 'notifyParent'
      }
    };
  }

  getReservationComponentDetails(isEdit) {
    return [
      {
        tabComponent: GeneralDataComponent,
        tabLabel: 'generalLabel',
        tabHeader: isEdit ? 'editReservation' : 'createreservationbuttontext',
        tabIcon: 'Group-748',
        isDisabled: false
      },
      {
        tabComponent: GuestDataComponent,
        enabledIcon: true,
        tabLabel: 'popUpGuest',
        tabHeader: isEdit ? 'editReservation' : 'createreservationbuttontext',
        tabIcon: 'Group-748',
        isDisabled: false
      },
    ];
  }

  buildReservationRequest(request, editData?: ReservationDTO) {
    if (request.selectedguest) {
      request.selectedguest.CountryId = request.selectedguest.PhoneNumber ? request.selectedguest.CountryId : null;
      request.selectedguest.CountryId2 = request.selectedguest.PhoneNumber2 ? request.selectedguest.CountryId2 : null;
    }
    var reservationRequest: UpdatedReservationDTO = {} as UpdatedReservationDTO;
    reservationRequest.ConciergeId = request.selectedConciergeId ? +request.selectedConciergeId : null;
    reservationRequest.HotelId = request.selectedHotelId ? +request.selectedHotelId : null;
    reservationRequest.Type = PartyType.Reservation;
    reservationRequest.Size = +request.selectednumber;
    reservationRequest.SpecialMealId = request.selectedSpecialMeal;
    if (request.selectedPartyStatus != -1) {
      reservationRequest.StatusId = request.selectedPartyStatus
    }
    reservationRequest.CoverTypes = request.selectedCoverTypes;
    reservationRequest.SeatingAreaId = this.getSeatingAreaId(request);
    reservationRequest.SeatingTypeId = this.getSeatingTypeId(request);
    if (request.partyid) { reservationRequest.Id = request.partyid; }
    const slot: UpdatedPartySlotDTO = {} as UpdatedPartySlotDTO;
    // setting slot data
    this.setSlot(request, slot, editData);
    reservationRequest.SeatingTime = null;
    reservationRequest.Contact = request.selectedguest;
    //policy info
    reservationRequest = this.getPolicyInfo(reservationRequest);
    reservationRequest.Slot = slot;
    reservationRequest.HostId = this.getHostId();
    reservationRequest.Id = editData ? editData.Id : null;
    reservationRequest.TableIds = [];
    // reservationRequest.TableIds = request.selectedtable ? request.selectedtable :
    //   (reservationRequest.TableIds = editData ? editData.TableIds : []);
    reservationRequest.TableIds = request.selectedtable ? request.selectedtable : [];
    reservationRequest.PageMethod = editData && (editData.PageMethod == this.partyService.partyPageMethod) ? editData.PageMethod :
      (this.partyService.partyPageMethod ? this.partyService.partyPageMethod : PageMethod.Manual);
    reservationRequest.Notes = this.partyService.partyNotes;
    reservationRequest.PagerNumber = this.partyService.partyPagerNumber;
    reservationRequest.BookingContacts = request.selectedguest.SecondaryContactsList && request.selectedguest.SecondaryContactsList.length > 0 ? request.selectedguest.SecondaryContactsList : [];
    reservationRequest.ReferenceNumber = editData && editData.ReferenceNumber ? editData.ReferenceNumber : null;
    reservationRequest.ReferenceNumber = editData && editData.ReferenceNumber ? editData.ReferenceNumber : null;
    reservationRequest.PartySourceId = editData && editData.PartySourceId ? editData.PartySourceId : null;
    reservationRequest.PageMethod = request.selectedguest.PreferredPageMethod;
    reservationRequest.SalesContactIds = request.selectedSalesContactIds;
    reservationRequest.RoomNumber = request.Contact.RoomNumber || null;
    return reservationRequest;
  }

  ValidateCovertypeAmount(request): boolean {
    let totalAmount = 0;
    let totalPartysize = 0;
    let isCoverTypeAmountInValid = false;
    if (request.selectedSpecialMeal && request.selectedSpecialMeal > 0) {
      if (request.selectedCoverTypes && request.selectedCoverTypes.length > 0) {
        request.selectedCoverTypes.forEach(element => {
          totalAmount = totalAmount + element.Covers * element.Price;
          totalPartysize = totalPartysize + element.Covers;
        });

        if (totalAmount == 0 && request.selectednumber == totalPartysize) {
          isCoverTypeAmountInValid = true;
        }
      }
    }
    return isCoverTypeAmountInValid;
  }
  createOrEditWaitlist(isEdit: boolean, tabInputData: any, fromAlertAudit?: boolean) {
    if (this.waitlistSubscription) {
      this.waitlistSubscription.unsubscribe();
    }
    this.partyService.createReservationForm();
    this.setType(ReservationType.Waitlist);
    this.partyService.partyPageMethod = PageMethod.Manual;
    this.partyService.partyNotes = [];
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '90%',
      width: '90%',
      maxWidth: '70vw',
      autoFocus: false,
      data: {
        selectedPopupType: PopupType.Waitlist,
        title: 'Add popup',
        update: 'ok',
        cancel: 'cancel',
        addtocart: 'addToCart',
        componentDetails: this.getWaitlistTabDetails(isEdit, tabInputData, fromAlertAudit),
        //from: ComponentTypes.waitlist,
        from: isEdit ? fromAlertAudit != undefined ? ComponentTypes.notification : ComponentTypes.EditWaitList : ComponentTypes.CreateWaitList,
        back: true,
        showRestaurantName: true
      }
    });
    this.waitlistSubscription = this.popupService.confirmedAction$.subscribe((tabOutput: ITabOutputDetails) => {
      //if (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.waitlist) {
      if ((tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.waitlist) ||
        (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.CreateWaitList) ||
        (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.EditWaitList)) {
        const reservationFormDetails = this.partyService.reservationFormGroup.value;
        const createWaitlist = {
          selectednumber: reservationFormDetails.selectedSize,
          selectedarea: reservationFormDetails.selectedArea,
          selectedtime: reservationFormDetails.selectedTime,
          selectedguest: reservationFormDetails.selectedGuest,
          selectedTable: reservationFormDetails.selectedTable,
          selectedType: reservationFormDetails.selectedType,
          selectedPartyStatus: reservationFormDetails.selectedPartyStatus
        };
        const buildWaitListRequest = this.buildWaitListRequest(createWaitlist, tabInputData);
        if (isEdit) {
          this.partyService.updateWaitlist(buildWaitListRequest, dialogRef);
        } else {
          this.partyService.createWaitList(buildWaitListRequest, false, ComponentTypes.waitlist, dialogRef);
        }
      }
    });
    this.subscriptions.add(dialogRef.afterClosed().subscribe(() => {
      if (this.waitlistSubscription) { this.waitlistSubscription.unsubscribe(); }
      this.partyService.partyNotes = [];
    }));
  }


  createOrEditOpenBooking(isEdit: boolean, tabInputData: any) {
    this.partyService.FinancialEffectId = null;
    this.partyService.slotLockId = null;
    this.partyService.partyNotes = [];
    this.partyService.isStandBy = false;
    if (this.openBookingSubscription) {
      this.openBookingSubscription.unsubscribe();
    }
    this.setType(ReservationType.OpenBooking);
    let isfrombuynow = false;
    this.partyService.createReservationForm();
    const selectedLocationIds = this.partyService.selectedTableLocationId ? [this.partyService.selectedTableLocationId] : null;
    if (selectedLocationIds?.length) {
      this.partyService.reservationFormGroup.controls.selectedTable.setValue(selectedLocationIds);
    }

    this.partyService.cancelBookingContactObj = tabInputData?.Contact;
    //const dialogRef = this.dialog.open(CustomPopupComponent, {
    this.ts.selectedTableBeforeAction$.next(null);
    this.partyService.openBookingDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '97vh',
      width: '100%',
      maxWidth: '97vw',
      autoFocus: false,
      data: {
        selectedPopupType: PopupType.Booking,
        title: 'Add popup',
        update: 'ok',
        cancel: 'cancel',
        addtocart: 'addToCart',
        componentDetails: this.getCustomTabDetails(isEdit, tabInputData, this.getBookingComponentDetails(isEdit, tabInputData)),
        from: isEdit ? ComponentTypes.EditOpenBooking : ComponentTypes.AddOpenBooking,
        back: true,
        isfromCart: tabInputData?.isCartEdit
      }
    });

    this.openBookingSubscription = this.popupService.confirmedAction$.subscribe((tabOutput: ITabOutputDetails) => {
      
    });


    this.openBookingCartSubscription = this.popupService.confirmAddToCartAction$.subscribe((tabOutput: ITabOutputDetails) => {
      isfrombuynow = tabOutput?.fromComponent === ComponentTypes.AddOpenBooking && tabOutput?.isEdit;
      if (tabOutput && (tabOutput.fromComponent && (tabOutput.fromComponent === ComponentTypes.AddOpenBooking || tabOutput.fromComponent === ComponentTypes.EditOpenBooking))) {
        var guestData = _.cloneDeep(this.partyService.openBookingData.BookingContacts[0]);
        guestData.Id = guestData.ContactId;
        delete guestData.ContactId;

        guestData.MemberType = guestData.MembershipType?.AllocationName;
        guestData.TrackMembershipNumber = guestData.memberShipId ? guestData.memberShipId : guestData.TrackMembershipNumber;
        guestData.IsMemberActive = guestData.MemberActive;
        this.partyService.addToCartRequestObj.Contact = { ...guestData };
        this.partyService.addToCartRequestObj.PageMethod = this.partyService.partyPageMethod ? this.partyService.partyPageMethod : this.partyService.openBookingData.PageMethod;
        this.partyService.addToCartRequestObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;
        this.partyService.addToCartRequestObj = this.getPolicyInfo(this.partyService.addToCartRequestObj);
        this.partyService.addToCartRequestObj.SelectedAddOns = this.getSelectedAddOns();
                this.partyService.getNegotiatedValueForBooking(this.partyService.openBookingData)
        this.partyService.addToCartRequestObj.Contact.MemeberRoundRedeem = this.partyService.getMemeberRoundRedeemObjectByFinancialData(this.partyService.addToCartRequestObj,this.partyService.ratePlanForBooking);

        if (!this.partyService.ratePlanSummaryState) {
          this.applyNegotiationAlert();
          return;
        }

        this.partyService.addToCartRequestObj.MinRateAdjustment = this.partyService.openBookingData.MinRateAdjustment;
        if(tabOutput.outputData){
          this.partyService.addToCartRequestObj.ExpireAt = tabOutput.outputData.ExpireAt || this.partyService.lockData?.ExpiresAtUTC;
          this.partyService.addToCartRequestObj.CartGroupName = tabOutput.outputData.CartGroupName;
        }
        else{
          if(isEdit){
            this.partyService.addToCartRequestObj.ExpireAt = tabInputData.bookingRatePlan.SlotLockResultDTO && Object.values(tabInputData.bookingRatePlan.SlotLockResultDTO)[0]['ExpiresAtUTC'];
            this.partyService.addToCartRequestObj.SlotLockResultDTO = tabInputData.bookingRatePlan.SlotLockResultDTO;

            if(tabInputData.bookingRatePlan.SlotLockResultDTO){
              Object.values(this.partyService.addToCartRequestObj.SlotLockResultDTO).forEach(slotLockObj => {
                slotLockObj['ExpiresAt'] = this.partyService.addToCartRequestObj.ExpireAt
              });
            }

            this.partyService.addToCartRequestObj.CartGroupName = tabInputData.bookingRatePlan.CartGroupName;
          }else{
            this.partyService.addToCartRequestObj.ExpireAt = this.partyService.lockData && (this.partyService.lockData.ExpiresAtUTC ? this.partyService.lockData.ExpiresAtUTC : Object.values(this.partyService.lockData)[0]['ExpiresAtUTC']);
            
            this.partyService.addToCartRequestObj.CartGroupName = this.getCartGroupName(guestData);
          }
        }
        if(!isEdit){
          this.partyService.addToCartRequestObj.SlotLockResultDTO = this.partyService.lockData;
        }
        this.partyService.addToCartRequestObj.RatePlanTotalNegotiation = {
          Negotiation: null,
          MinRateNegotiatedAdjustment: this.partyService.addToCartRequestObj?.MinRateNegotiatedAdjustment,
          applyNegotiationOnOtherCharges: [
            {
              bookingChargeType: BookingChargeType.ServiceCharge,
              TotalAmount: this.partyService.ratePlanForBooking?.TotalServiceCharge,
              NegotiatedAmount: null,
              UseAfterNegotiation: this.negotiateServiceCharge
            }
          ],
          RatePlanCalculationInfoNegotiation: this.partyService.ratePlanForBooking?.RatePlanCalculations
        }
        this.partyService.addToCartRequestObj['LanguageId'] = this.appService.selectedLanguage || DEFAULT_LANGUAGE_ID;
        var validationErr = this.ValidatePagingInfo(this.partyService.addToCartRequestObj);
        if (validationErr) {
          this.utilities.showAlert(validationErr, AlertType.Error, ButtonType.Ok);
        }
        else {
          if (!isEdit) {
            this.partyService.addBookingToCart(this.partyService.addToCartRequestObj, false, this.partyService.addToCartRequestObj.selecteddate, this.partyService.openBookingDialogRef, isfrombuynow, this.openBookingCartSubscription);
          if(!isfrombuynow){
            this.partyService.isSlotLocked = false;
            this.partyService.lockData = null;
          }
          } else {

            let lockrequest: any = this.partyService.addToCartRequestObj.Slots.map(slotData => {
              let _lockIdToIgnore = this.partyService.addToCartRequestObj.SlotLockResultDTO && this.partyService.addToCartRequestObj?.SlotLockResultDTO[slotData.Time]?.SlotLockIdDTO?.Id || null;
              return {
                LocalTime: slotData.Time,
                PartySize: this.partyService.addToCartRequestObj.Size,
                OverBooked: this.partyService.openBookingData?.OverBooked ? this.partyService.addToCartRequestObj.Size : 0,
                SeatingAreaId: null,
                SeatingTypeId: null,
                IsCommunalTable: false,
                FromWebsite: true,
                DurationInMinutes: slotData.DurationInMinutes,
                ReservationIdToIgnore: null,
                TableIds: this.partyService.addToCartRequestObj.TableIds,
                AddOns: this.getSelectedAddOns(),
                ExpireAt: null,
                lockIdToIgnore: _lockIdToIgnore
              }
            });

            this.lockTables(lockrequest, this.partyService.openBookingData, this.partyService.addToCartRequestObj, tabInputData);

          }
        }
        if (this.openBookingCartSubscription) {
          // this.openBookingCartSubscription.unsubscribe();
        }
      }
    });

    this.subscriptions.add(this.partyService.openBookingDialogRef.afterClosed().subscribe(() => {
      this.appService.showCartItemOnEdit = false;
      const slotMode = this.cs.settings.value.General.SlottingMode;
      if (this.partyService.isSlotLocked && this.partyService.slotLockIds.length) {
        if (slotMode === SlottingMode.Auto) {
          this.subscriptions.add(this.partyService.releaseMultiple( this.partyService.slotLockIds ).subscribe((slot) => {
            this.partyService.slotLockId = null;
            this.partyService.isSlotLocked = false;
            this.partyService.lockData = null;
            this.partyService.slotLockIds = [];
          }));
        }
      }
      if (this.openBookingSubscription) {
        this.openBookingSubscription.unsubscribe();
      }
      if (this.openBookingCartSubscription) {
        this.openBookingCartSubscription.unsubscribe();
      }

      this.partyService.partyNotes = [];
      //this.partyService.selectedParty$.next(null); reverting "tracker item #879." fix
    }));
  }

  openBookingConfirmationHandler(tabOutput, isEdit){
    if ((tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.AddOpenBooking) ||
        (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.EditOpenBooking)) {
        if(this.partyService.addToCartRequestObj.OverBooked && !this.appService.hasPermission(RolesAndPermissionsType.Overbook)){
          this.utilities.showAlert(this.translateService.instant('OverbookPermissionDenied'), AlertType.AccessDenied, ButtonType.Ok);
          return;
        }
        var guestData = _.cloneDeep(this.partyService.openBookingData.BookingContacts[0]);
        guestData.Id = guestData.ContactId;
        delete guestData.ContactId;

        guestData.MemberType = guestData.MembershipType?.AllocationName;
        guestData.TrackMembershipNumber = guestData.memberShipId ? guestData.memberShipId : guestData.TrackMembershipNumber;
        guestData.IsMemberActive = guestData.MemberActive;
        this.partyService.addToCartRequestObj.Contact = { ...guestData };
        this.partyService.addToCartRequestObj.PageMethod = this.partyService.partyPageMethod ? this.partyService.partyPageMethod : this.partyService.openBookingData.PageMethod;
        this.partyService.addToCartRequestObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;
        var validationErr = this.ValidatePagingInfo(this.partyService.addToCartRequestObj);
        if (validationErr) {
          this.utilities.showAlert(validationErr, AlertType.Error, ButtonType.Ok);
          return;
        }
        if ((!guestData.MemberActive && this.partyService.savesessions) && (guestData.TrackMembershipNumber)) {
          const popUpMessage = [{
            confirmationMessage: 'Membership is either Inactive/Expired',
            dialogTitle: this.translateService.instant('Membership Status'),
            showAlert: true
          }];
          const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'static', popUpMessage);
          this.convertDialog = this.openCustomPopup(componentInfo, ComponentTypes.CancelReservation,
            '450px', '350px', false, popUpMessage[0].dialogTitle, 'OK', '', true);

        } else {
          if (isEdit) {
            if (!this.cs.settings.value.RetailItems || !this.cs.settings.value.RetailItems.length) {
              this.partyService.addToCartRequestObj.Slot = this.partyService.addToCartRequestObj.Slots[0];
              this.partyService.confirmUpdateOpenBooking(this.partyService.addToCartRequestObj, false, this.partyService.openBookingDialogRef, this.partyService.addToCartRequestObj.selecteddate);
            } else {
              this.getRegisterReservationAttempt(this.partyService.addToCartRequestObj, ComponentTypes.EditOpenBooking);
            }
          } else {
            this.partyService.createOpenBooking(this.partyService.addToCartRequestObj, false, this.partyService.addToCartRequestObj.selecteddate, this.partyService.openBookingDialogRef);
          }
        }
        // if (this.openBookingSubscription) {
        //   this.openBookingSubscription.unsubscribe();
        // }
      }
  }

  lockSessions(lockRequest: LockSessionRequestDTO, bookingData, dataSavObj, tabInputData){
    if ((!bookingData.AllowStandBy || bookingData.OverBooked)) {
      this.subscriptions.add(
        this.api.lockSessions(lockRequest).subscribe(response => {
          let _lockResponse = response.Payload;
          this.partyService.lockData = response.Payload;
          this.partyService.slotLockId = _lockResponse?.SlotLockIdDTO?.Id;
          this.partyService.slotLockIds = [_lockResponse.SlotLockIdDTO?.Id];
          if(dataSavObj){
            dataSavObj.SlotLockResultDTO = _lockResponse;
            dataSavObj.ExpireAt = _lockResponse?.ExpiresAtUTC || dataSavObj.ExpireAt;
          }
          this.partyService.updateCart(dataSavObj, tabInputData.CartId, false, '', this.partyService.activityBookingDialogRef, this.activityBookingCartSubscription);

        }));

    }
  }

  lockTables(lockRequest: LockSessionRequestDTO[], bookingData, dataSavObj, tabInputData){
    if (lockRequest.length) {
      this.subscriptions.add(
        this.api.lockTables(lockRequest).subscribe(response => {
          let _lockResponse = response.Payload;
          this.partyService.lockData = response.Payload;
          this.partyService.slotLockId = _lockResponse?.SlotLockIdDTO?.Id;
          this.partyService.slotLockIds = [_lockResponse.SlotLockIdDTO?.Id];
          if(dataSavObj){
            dataSavObj.SlotLockResultDTO = _lockResponse;
          }
          // this.unlockOpenBookingSlots(tabInputData.bookingRatePlan.SlotLockResultDTO);
          this.partyService.updateCart(dataSavObj, tabInputData.CartId, false, '', this.partyService.openBookingDialogRef, this.openBookingCartSubscription);

        }));

    }
  }


  unlockOpenBookingSlots(slotDto){
    Object.values(slotDto).forEach((SlotLockIdDTO: any) => {
      this.partyService.unlockSlot(SlotLockIdDTO.Id);
    });
  }

  getFinancialEffect(fromComponent, IsCardNeeded = false) {
 //   const avilableRound = this.partyService.selectedGuest.GolfPoints;
    if (this.partyService.reservationFormGroup && this.partyService.reservationFormGroup.value.selectedGuest) {
      this.partyService.selectedGuest = this.partyService.reservationFormGroup.value.selectedGuest
    }
    var guestData = _.cloneDeep( this.partyService.addToCartRequestObj.BookingContacts[0]);
    guestData.Id = guestData.ContactId;
    delete guestData.ContactId;

    // if(avilableRound){
    //   this.partyService.selectedGuest['GolfPoints'] = avilableRound;
    //   guestData['GolfPoints'] = avilableRound;
    // }
    guestData.MemberType = guestData.MembershipType?.AllocationName;
    guestData.TrackMembershipNumber = guestData.memberShipId ? guestData.memberShipId : guestData.TrackMembershipNumber;
    guestData.IsMemberActive = guestData.MemberActive;
    this.partyService.Operations = 2;

    // this.popupService.nextBtnEnabled$.next(true);
    if (fromComponent === ComponentTypes.EditOpenBooking) {
      this.partyService.BookingBehaviour = BookingBehavior.OpenBooking;
      this.partyService.addToCartRequestObj.Slot = this.partyService.addToCartRequestObj.Slots[0];
      this.partyService.addToCartRequestObj.Contact = { ...guestData };
      this.partyService.addToCartRequestObj.PageMethod = this.partyService.partyPageMethod ? this.partyService.partyPageMethod : this.partyService.addToCartRequestObj.PageMethod;
      this.partyService.addToCartRequestObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;
      this.partyService.addToCartRequestObj.Addons = this.getSelectedAddOns();
      this.partyService.addToCartRequestObj.SelectedAddOns = this.getSelectedAddOns();
      this.partyService.getNegotiatedValueForBooking(this.partyService.openBookingData);

      if (this.partyService.ratePlanForBooking) {
        this.partyService.addToCartRequestObj.RatePlanTotalNegotiation = {
          Negotiation: null,
          MinRateNegotiatedAdjustment: this.partyService.addToCartRequestObj?.MinRateNegotiatedAdjustment,
          applyNegotiationOnOtherCharges: [
            {
              bookingChargeType: BookingChargeType.ServiceCharge,
              TotalAmount: this.partyService.ratePlanForBooking.TotalServiceCharge,
              NegotiatedAmount: null,
              UseAfterNegotiation: this.negotiateServiceCharge
            }
          ],
          RatePlanCalculationInfoNegotiation: this.partyService.ratePlanForBooking.RatePlanCalculations
        }
      } else if (this.partyService.addToCartRequestObj) {
        this.partyService.addToCartRequestObj.RatePlanTotalNegotiation = null;
      }

      this.partyService.updateOpenBooking(this.partyService.addToCartRequestObj, false, this.partyService.addToCartRequestObj.selecteddate, this.partyService.openBookingDialogRef, IsCardNeeded)
    } else if (fromComponent === ComponentTypes.EditActivityBooking) {
      this.partyService.BookingBehaviour = BookingBehavior.ClassOrSession;
      this.partyService.addToCartRequestObj.Contact = { ...guestData };
      this.partyService.addToCartRequestObj.PageMethod = this.partyService.partyPageMethod ? this.partyService.partyPageMethod : this.partyService.addToCartRequestObj.PageMethod;
      this.partyService.addToCartRequestObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;
      this.partyService.addToCartRequestObj.Addons = this.getSelectedAddOns();
      this.partyService.addToCartRequestObj.SelectedAddOns = this.getSelectedAddOns();
      this.partyService.getNegotiatedValueForBooking(this.partyService.sessionBookingData);

      if (this.partyService.ratePlanForBooking) {
        this.partyService.addToCartRequestObj.RatePlanTotalNegotiation = {
          Negotiation: null,
          MinRateNegotiatedAdjustment: this.partyService.addToCartRequestObj.MinRateNegotiatedAdjustment,
          applyNegotiationOnOtherCharges: [
            {
              bookingChargeType: BookingChargeType.ServiceCharge,
              TotalAmount: this.partyService.ratePlanForBooking.TotalServiceCharge,
              NegotiatedAmount: null,
              UseAfterNegotiation: this.negotiateServiceCharge
            }
          ],
          RatePlanCalculationInfoNegotiation: this.partyService.ratePlanForBooking.RatePlanCalculations
        }
      } else if (this.partyService.addToCartRequestObj) {
        this.partyService.addToCartRequestObj.RatePlanTotalNegotiation = null;
      }

      this.partyService.updateSessionBooking(this.partyService.addToCartRequestObj, this.partyService.addToCartRequestObj.selecteddate, this.partyService.activityBookingDialogRef, IsCardNeeded);
    } else if (fromComponent === ComponentTypes.EditPrivateLessonBooking) {
      this.partyService.BookingBehaviour = BookingBehavior.PrivateLesson;
      this.partyService.addToCartRequestObj.Slot = this.partyService.addToCartRequestObj.Slots[0];
      this.partyService.addToCartRequestObj.Contact = { ...guestData };
      this.partyService.addToCartRequestObj.PageMethod = this.partyService.partyPageMethod ? this.partyService.partyPageMethod : this.partyService.addToCartRequestObj.PageMethod;
      this.partyService.addToCartRequestObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;
      this.partyService.addToCartRequestObj.Addons = this.getSelectedAddOns();
      this.partyService.addToCartRequestObj.SelectedAddOns = this.getSelectedAddOns();
      this.partyService.getNegotiatedValueForBooking(this.partyService.privateLessonBookingData);

      if (this.partyService.ratePlanForBooking) {
        this.partyService.addToCartRequestObj.RatePlanTotalNegotiation = {
          Negotiation: null,
          MinRateNegotiatedAdjustment: this.partyService.addToCartRequestObj?.MinRateNegotiatedAdjustment,
          applyNegotiationOnOtherCharges: [
            {
              bookingChargeType: BookingChargeType.ServiceCharge,
              TotalAmount: this.partyService.ratePlanForBooking.TotalServiceCharge,
              NegotiatedAmount: null,
              UseAfterNegotiation: this.negotiateServiceCharge
            }
          ],
          RatePlanCalculationInfoNegotiation: this.partyService.ratePlanForBooking.RatePlanCalculations
        }
      } else if (this.partyService.addToCartRequestObj) {
        this.partyService.addToCartRequestObj.RatePlanTotalNegotiation = null;
      }

      this.partyService.updatePrivateLessonBooking(this.partyService.addToCartRequestObj, false, this.partyService.addToCartRequestObj.selecteddate, this.partyService.privateLessonBookingDialogRef, IsCardNeeded)
    }
  }

  createOrEditActivityBooking(isEdit: boolean, tabInputData: any) {
    this.partyService.FinancialEffectId = null;
    this.partyService.partyNotes = [];
    this.partyService.isStandBy = false;
    // this.partyService.slotLockId =null;
    if (this.activityBookingSubscription) {
      this.activityBookingSubscription.unsubscribe();
    }
    let isfrombuynow = false;
    this.setType(ReservationType.ActivityBooking);
    this.partyService.createReservationForm();
    this.partyService.cancelBookingContactObj = tabInputData?.Contact;
    this.ts.selectedTableBeforeAction$.next(null);
    this.partyService.activityBookingDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '90vh',
      width: '90%',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        selectedPopupType: PopupType.ActivityBooking,
        title: 'Add popup',
        update: 'ok',
        addtocart: 'addToCart',
        cancel: 'cancel',
        componentDetails: this.getCustomTabDetails(isEdit, tabInputData, this.getActivityBookingComponentDetails(isEdit, tabInputData)),
        from: isEdit ? ComponentTypes.EditActivityBooking : ComponentTypes.AddActivityBooking,
        back: true,
        isfromCart: tabInputData?.isCartEdit
      }
    });

    this.activityBookingCartSubscription = this.popupService.confirmAddToCartAction$.subscribe((tabOutput: ITabOutputDetails) => {
      
      isfrombuynow = tabOutput?.fromComponent === ComponentTypes.AddActivityBooking && tabOutput?.isEdit;
      if (tabOutput && (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.AddActivityBooking || tabOutput.fromComponent === ComponentTypes.EditActivityBooking)) {
        var guestData = _.cloneDeep(this.partyService.sessionBookingData.BookingContacts[0]);
                guestData.Id = guestData.ContactId;
        delete guestData.ContactId;
        
        guestData.MemberType = guestData.MembershipType?.AllocationName;
        guestData.TrackMembershipNumber = guestData.memberShipId ? guestData.memberShipId : guestData.TrackMembershipNumber;
        guestData.IsMemberActive = guestData.MemberActive;
        this.partyService.addToCartRequestObj.Contact = { ...guestData };
        this.partyService.addToCartRequestObj.PageMethod = this.partyService.partyPageMethod ? this.partyService.partyPageMethod : this.partyService.sessionBookingData.PageMethod;
        this.partyService.addToCartRequestObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;
        this.partyService.addToCartRequestObj = this.getPolicyInfo(this.partyService.addToCartRequestObj);
        this.partyService.getNegotiatedValueForBooking(this.partyService.sessionBookingData)

        if(tabOutput.outputData){
          this.partyService.addToCartRequestObj.ExpireAt = tabOutput.outputData.ExpireAt;
          this.partyService.addToCartRequestObj.CartGroupName = tabOutput.outputData.CartGroupName;

          if(!this.partyService.addToCartRequestObj.ExpireAt && !isEdit){
            this.partyService.addToCartRequestObj.ExpireAt = this.partyService.lockData.ExpiresAt
            this.partyService.addToCartRequestObj.SlotLockResultDTO = this.partyService.lockData;
          }else if(!this.partyService.addToCartRequestObj.SlotLockResultDTO){
            this.partyService.addToCartRequestObj.SlotLockResultDTO = this.partyService.lockData;
            this.partyService.addToCartRequestObj.SlotLockResultDTO.ExpiresAt = this.partyService.addToCartRequestObj.ExpireAt;
          }

        }
        else{
          if(isEdit){
            this.partyService.addToCartRequestObj.ExpireAt = this.partyService.lockData?.ExpiresAtUTC || tabInputData.bookingRatePlan.SlotLockResultDTO.ExpiresAt;
            this.partyService.addToCartRequestObj.SlotLockResultDTO =  this.partyService.lockData || tabInputData.bookingRatePlan.SlotLockResultDTO;
            // this.partyService.addToCartRequestObj.SlotLockResultDTO.ExpiresAt = this.partyService.addToCartRequestObj.ExpireAt;
            this.partyService.addToCartRequestObj.CartGroupName = tabInputData.bookingRatePlan.CartGroupName;
          }else{
            this.partyService.addToCartRequestObj.ExpireAt = this.partyService.lockData.ExpiresAtUTC;
            this.partyService.addToCartRequestObj.SlotLockResultDTO = this.partyService.lockData;
            this.partyService.addToCartRequestObj.CartGroupName =  this.getCartGroupName(guestData);
          }
        }
        // this.partyService.ratePlanForBooking.RatePlanCalculations.forEach(data => {
        //   if ((data.totalBaseRate * coversQty) != data.TotalAmount) {
        //     data.RatePlanVariants.NegotiatedAmount = (data.totalBaseRate * coversQty) - data.TotalAmount;
        //     data.RatePlanVariants.TotalVariants = data.TotalBaseRate;
        //   }
        //   if (data.RatePlanVariants.GuestTypeVariant && data.RatePlanVariants.GuestTypeVariant.length) {
        //     data.RatePlanVariants.GuestTypeVariant = data.RatePlanVariants.GuestTypeVariant.filter(guest => guest.covers > 0)
        //     data.RatePlanVariants.GuestTypeVariant.forEach(guest => {
        //       guest.NegotiatedAmount = guest.coverTypeValue - guest.initialValue;
        //       guest.Variant = guest.initalValue;
        //     });
        //   }
        //   if (data.RatePlanVariants.BookingTypeVariant && data.RatePlanVariants.BookingTypeVariant.length) {
        //     data.RatePlanVariants.BookingTypeVariant = data.RatePlanVariants.BookingTypeVariant.filter(type => type.bookingTypes > 0)
        //     data.RatePlanVariants.BookingTypeVariant.forEach(type => {
        //       type.NegotiatedAmount = type.bookingTypeValue - type.initialValue;
        //       type.Variant = type.initalValue;
        //     })
        //   }
        //   if (data.AddonAttemptDetails && data.AddonAttemptDetails.length) {
        //     data.AddonAttemptDetails.forEach(addOn => {
        //       addOn.NegotiatedAmount = addOn.perItemValue - addOn.initialValue;
        //     })
        //   }
        // });

          this.partyService.addToCartRequestObj.Contact['MemeberRoundRedeem'] = this.partyService.getMemeberRoundRedeemObjectByFinancialData(this.partyService.addToCartRequestObj,this.partyService.ratePlanForBooking);
          if (!this.partyService.ratePlanSummaryState) {
          this.applyNegotiationAlert();
          return;
        }

        this.partyService.addToCartRequestObj.MinRateAdjustment = this.partyService.sessionBookingData.MinRateAdjustment;

        this.partyService.addToCartRequestObj.RatePlanTotalNegotiation = {
          Negotiation: null,
          MinRateNegotiatedAdjustment: this.partyService.addToCartRequestObj?.MinRateNegotiatedAdjustment,
          applyNegotiationOnOtherCharges: [
            {
              bookingChargeType: BookingChargeType.ServiceCharge,
              TotalAmount: this.partyService?.ratePlanForBooking?.TotalServiceCharge,
              NegotiatedAmount: null,
              UseAfterNegotiation: this.negotiateServiceCharge
            }
          ],
          RatePlanCalculationInfoNegotiation: this.partyService?.ratePlanForBooking?.RatePlanCalculations as RatePlanCalculationInfoNegotiation
        }

        this.partyService.addToCartRequestObj['LanguageId'] = this.appService.selectedLanguage || DEFAULT_LANGUAGE_ID;
        var validationErr = this.ValidatePagingInfo(this.partyService.addToCartRequestObj);
        this.partyService.addToCartRequestObj.SelectedAddOns = this.getSelectedAddOns();
        if (validationErr) {
          this.utilities.showAlert(validationErr, AlertType.Error, ButtonType.Ok);
        }
        else {
          if (!isEdit) {
            this.partyService.addBookingToCart(this.partyService.addToCartRequestObj, false, '', this.partyService.activityBookingDialogRef, isfrombuynow, this.activityBookingCartSubscription);
          } else {
            this.partyService.updateCart(this.partyService.addToCartRequestObj, tabInputData.CartId, false, '', this.partyService.activityBookingDialogRef, this.activityBookingCartSubscription);
            // let _reqObject = {
            //   ActivityId: this.partyService.addToCartRequestObj.SpecialMealId,
            //   StartDate: this.partyService.addToCartRequestObj.StartDate,
            //   BookingSize: this.partyService.addToCartRequestObj.Size,
            //   ClassType: this.partyService.addToCartRequestObj.Type,
            //   lockIdToIgnore: tabInputData?.bookingRatePlan?.SlotLockResultDTO?.SlotLockIdDTO?.Id || this.partyService.slotLockId || null,
            //   Sessions: this.partyService.addToCartRequestObj.SessionGroupId? [] : this.partyService.addToCartRequestObj?.Sessions?.Sessions,
            //   SessionGroupId: this.partyService.addToCartRequestObj.SessionGroupId || null,
            //   OverBooked: this.partyService.addToCartRequestObj.Size.Overbooked,
            //   IsForStandbyReservations: this.partyService.sessionBookingData.AllowStandBy,
            //   AddOns: this.getSelectedAddOns(),
            //   SessionDTOs: this.getExclusiveSession( this.partyService.addToCartRequestObj.classType , this.partyService.sessionBookingData?.Slots || [])
            // } as LockSessionRequestDTO;

            // this.lockSessions(_reqObject, this.partyService.sessionBookingData, this.partyService.addToCartRequestObj, tabInputData);
          }
        }
        if (this.activityBookingCartSubscription) {
          // this.activityBookingCartSubscription.unsubscribe();
        }
      }
    });



    this.subscriptions.add(this.partyService.activityBookingDialogRef.afterClosed().subscribe(() => {
      if (this.activityBookingSubscription) {
        this.activityBookingSubscription.unsubscribe();
      }
      if (this.activityBookingCartSubscription) {
        this.activityBookingCartSubscription.unsubscribe();
      }
      this.partyService.updateContactOnly = false;
      this.appService.showCartItemOnEdit = false;
      this.popupService.enableSaveBtnAuthorise$.next(false);

      this.partyService.isfrombuynow = false;
      if (this.activityBookingSubscription) {
        this.activityBookingSubscription.unsubscribe();
      }
      if (this.activityBookingCartSubscription) {
        this.activityBookingCartSubscription.unsubscribe();
      }
      //this.partyService.selectedParty$.next(null); reverting "tracker item #879." fix
    }));


  }
  getExclusiveSession(classType, Slots) {
    let sessions = [] as ExclusiveSessionDateDTO[];
    if (classType == ClassType.Session) {
      Slots.forEach(item => item.SessionIds.forEach(sessionId => {
        let exclusiveSession = {} as ExclusiveSessionDateDTO;
        exclusiveSession.Date = item.Date;
        exclusiveSession.SessionId = sessionId
        sessions.push(exclusiveSession);
      })
      )
    } 
    return sessions;
  }
  activityBookingConfirmationHandler(tabOutput, isEdit){
    if ((tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.AddActivityBooking) ||
    (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.EditActivityBooking)) {

   // var guestData = this.partyService.reservationFormGroup.value.selectedGuest;
    var guestData = _.cloneDeep(this.partyService.sessionBookingData.BookingContacts[0]);
    guestData.Id = guestData.ContactId;
    delete guestData.ContactId;
    
    guestData.MemberType = guestData.MembershipType?.AllocationName;
    guestData.TrackMembershipNumber = guestData.memberShipId ? guestData.memberShipId : guestData.TrackMembershipNumber;
    guestData.IsMemberActive = guestData.MemberActive;
    this.partyService.addToCartRequestObj.Contact = { ...guestData };
    this.partyService.addToCartRequestObj.PageMethod = this.partyService.partyPageMethod ? this.partyService.partyPageMethod : this.partyService.sessionBookingData.PageMethod;
    this.partyService.addToCartRequestObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;
    this.partyService.addToCartRequestObj.SelectedAddOns = this.getSelectedAddOns();
    this.partyService.addToCartRequestObj.Contact['MemeberRoundRedeem'] = this.partyService.getMemeberRoundRedeemObjectByFinancialData(this.partyService.addToCartRequestObj,this.partyService.ratePlanForBooking);
    
    if (!this.partyService.ratePlanSummaryState) {
      this.applyNegotiationAlert();
      return;
    }

    var validationErr = this.ValidatePagingInfo(this.partyService.addToCartRequestObj);
    if (validationErr) {
      this.utilities.showAlert(validationErr, AlertType.Error, ButtonType.Ok);
      return;
    }

    if ((!guestData.MemberActive && this.partyService.savesessions) && (guestData.TrackMembershipNumber)) {
      const popUpMessage = [{
        confirmationMessage: 'Membership is either Inactive/Expired',
        dialogTitle: this.translateService.instant('Membership Status'),
        showAlert: true
      }];
      const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'static', popUpMessage);
      this.convertDialog = this.openCustomPopup(componentInfo, ComponentTypes.standby,
        '450px', '350px', false, popUpMessage[0].dialogTitle, 'OK', '', true);
    } else {
      if (isEdit) {
        if (!this.cs.settings.value.RetailItems || !this.cs.settings.value.RetailItems.length) {
          this.partyService.confirmUpdateSessionBooking(this.partyService.addToCartRequestObj, false, this.partyService.activityBookingDialogRef);
        } else {
          this.getRegisterReservationAttempt(this.partyService.addToCartRequestObj, ComponentTypes.EditActivityBooking);
        }
      }
      else {
        this.partyService.createSessionBooking(this.partyService.addToCartRequestObj, false);
      }
    }
    // if (this.activityBookingSubscription) {
    //   this.activityBookingSubscription.unsubscribe();
    // }
  }
  }

  createOrEditPrivateActivityBooking(isEdit: boolean, tabInputData: any) {
    this.partyService.FinancialEffectId = null;
    this.partyService.partyNotes = [];
    this.partyService.isStandBy = false;
    this.partyService.slotLockId =null;
    this.setType(ReservationType.PrivateLessonBooking);
    let isfrombuynow = false;
    this.partyService.cancelBookingContactObj = tabInputData?.Contact;
    this.ts.selectedTableBeforeAction$.next(null);
    this.partyService.privateLessonBookingDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '97vh',
      width: '100%',
      maxWidth: '97vw',
      autoFocus: false,
      data: {
        selectedPopupType: PopupType.ActivityBooking,
        title: 'Add popup',
        update: 'ok',
        cancel: 'cancel',
        componentDetails: this.getCustomTabDetails(isEdit, tabInputData, this.getPrivateActivityBookingComponentDetails(isEdit, tabInputData)),
        from: isEdit ? ComponentTypes.EditPrivateLessonBooking : ComponentTypes.AddPrivateLessonBooking,
        back: true,
        isfromCart: tabInputData?.isCartEdit
      }
    });

    this.privateActivityBookingCartSubscription = this.popupService.confirmAddToCartAction$.subscribe((tabOutput: ITabOutputDetails) => {
      isfrombuynow = tabOutput?.fromComponent === ComponentTypes.AddPrivateLessonBooking && tabOutput?.isEdit;
      if (tabOutput && (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.AddPrivateLessonBooking || tabOutput.fromComponent === ComponentTypes.EditPrivateLessonBooking)) {
        var guestData = _.cloneDeep(this.partyService.privateLessonBookingData.BookingContacts[0]);
        guestData.Id = guestData.ContactId;
        delete guestData.ContactId;

        guestData.MemberType = guestData.MembershipType?.AllocationName;
        guestData.TrackMembershipNumber = guestData.memberShipId ? guestData.memberShipId : guestData.TrackMembershipNumber;
        guestData.IsMemberActive = guestData.MemberActive;
        this.partyService.addToCartRequestObj.Contact = { ...guestData };
        this.partyService.addToCartRequestObj.PageMethod = this.partyService.partyPageMethod ? this.partyService.partyPageMethod : this.partyService.privateLessonBookingData.PageMethod;
        this.partyService.addToCartRequestObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;
        this.partyService.addToCartRequestObj = this.getPolicyInfo(this.partyService.addToCartRequestObj);
        this.partyService.addToCartRequestObj.SelectedAddOns = this.getSelectedAddOns();

        if (!this.partyService.ratePlanSummaryState) {
          this.applyNegotiationAlert();
          return;
        }


        this.partyService.getNegotiatedValueForBooking(this.partyService.privateLessonBookingData);
        this.partyService.addToCartRequestObj.MinRateAdjustment = this.partyService.privateLessonBookingData.MinRateAdjustment;
        if(tabOutput.outputData){
          this.partyService.addToCartRequestObj.ExpireAt = tabOutput.outputData.ExpireAt;
          this.partyService.addToCartRequestObj.CartGroupName = tabOutput.outputData.CartGroupName;

          if(!this.partyService.addToCartRequestObj.ExpireAt && !isEdit){
            this.partyService.addToCartRequestObj.ExpireAt = this.partyService.lockData.ExpiresAtUTC;
            this.partyService.addToCartRequestObj.SlotLockResultDTO = this.partyService.lockData;
          }
         
        }
        else{
          if(isEdit){
            this.partyService.addToCartRequestObj.ExpireAt = tabInputData.bookingRatePlan.SlotLockResultDTO && Object.values(tabInputData.bookingRatePlan.SlotLockResultDTO)[0]['ExpiresAtUTC'];
            this.partyService.addToCartRequestObj.SlotLockResultDTO =  tabInputData.bookingRatePlan.SlotLockResultDTO || this.partyService.lockData;
            if(tabInputData.bookingRatePlan.SlotLockResultDTO){
              Object.values(this.partyService.addToCartRequestObj.SlotLockResultDTO).forEach(slotLockObj => {
                slotLockObj['ExpiresAt'] = this.partyService.addToCartRequestObj.ExpireAt
              });
            }

            this.partyService.addToCartRequestObj.CartGroupName = tabInputData.bookingRatePlan.CartGroupName;
          }else{
            this.partyService.addToCartRequestObj.ExpireAt = this.partyService.lockData && (this.partyService.lockData.ExpiresAtUTC ? this.partyService.lockData.ExpiresAtUTC : Object.values(this.partyService.lockData)[0]['ExpiresAtUTC']);
            this.partyService.addToCartRequestObj.SlotLockResultDTO = this.partyService.lockData;
            this.partyService.addToCartRequestObj.CartGroupName =  this.getCartGroupName(guestData);
          }
        }
        this.partyService.addToCartRequestObj.RatePlanTotalNegotiation = {
          Negotiation: null,
          MinRateNegotiatedAdjustment: this.partyService.addToCartRequestObj?.MinRateNegotiatedAdjustment,
          applyNegotiationOnOtherCharges: [
            {
              bookingChargeType: BookingChargeType.ServiceCharge,
              TotalAmount: null,
              NegotiatedAmount: null,
              UseAfterNegotiation: this.negotiateServiceCharge
            }
          ],
          RatePlanCalculationInfoNegotiation: null
        }
        this.partyService.addToCartRequestObj['LanguageId'] = this.appService.selectedLanguage || DEFAULT_LANGUAGE_ID;
        var validationErr = this.ValidatePagingInfo(this.partyService.addToCartRequestObj);
        if (validationErr) {
          this.utilities.showAlert(validationErr, AlertType.Error, ButtonType.Ok);
        }
        else {
        this.partyService.addToCartRequestObj.Contact['MemeberRoundRedeem'] = this.partyService.getMemeberRoundRedeemObjectByFinancialData(this.partyService.addToCartRequestObj,this.partyService.ratePlanForBooking);
          if (!isEdit) {
            this.partyService.addBookingToCart(this.partyService.addToCartRequestObj, false, '', this.partyService.privateLessonBookingDialogRef, isfrombuynow, this.privateActivityBookingCartSubscription);
          } else {
            this.partyService.updateCart(this.partyService.addToCartRequestObj, tabInputData.CartId, false, '', this.partyService.privateLessonBookingDialogRef, this.privateActivityBookingCartSubscription);
          }
        }
        if (this.privateActivityBookingCartSubscription) {
          // this.privateActivityBookingCartSubscription.unsubscribe();
        }
      }
    });
    this.subscriptions.add(this.partyService.privateLessonBookingDialogRef.afterClosed().subscribe(() => {
      this.partyService.updateContactOnly = false;
      this.appService.showCartItemOnEdit = false;
      const slotMode = this.cs.settings.value.General.SlottingMode;
      if (this.partyService.slotLockId) {
        this.subscriptions.add(this.partyService.unlockSessions(this.partyService.slotLockId).subscribe((slot) => {
          this.partyService.slotLockId = null;
        }));
      }
      if (this.partyService.slotLockIds && this.partyService.slotLockIds.length > 0) {
        // if (slotMode === SlottingMode.Auto && !isfrombuynow) {
        //   this.subscriptions.add(this.partyService.releaseMultiple(this.partyService.slotLockIds).subscribe((slot) => {
        //     this.partyService.slotLockIds = [];
        //   }));
        // }
      }
      if (this.privateActivityBookingSubscription) {
        this.privateActivityBookingSubscription.unsubscribe();
      }
      if (this.privateActivityBookingCartSubscription) {
        this.privateActivityBookingCartSubscription.unsubscribe();
      }
      //this.partyService.selectedParty$.next(null); reverting "tracker item #879." fix
    }));

  }

  privateBookingConfirmationHandler(tabOutput, isEdit){
    if ((tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.AddPrivateLessonBooking) ||
        (tabOutput.fromComponent && tabOutput.fromComponent === ComponentTypes.EditPrivateLessonBooking)) {
          var guestData = _.cloneDeep(this.partyService.privateLessonBookingData.BookingContacts[0]);
          guestData.Id = guestData.ContactId;          
          delete guestData.ContactId;
          
        guestData.MemberType = guestData.MembershipType?.AllocationName;
        guestData.TrackMembershipNumber = guestData.memberShipId ? guestData.memberShipId : guestData.TrackMembershipNumber;
        guestData.IsMemberActive = guestData.MemberActive;
        this.partyService.addToCartRequestObj.Contact = { ...guestData };
        this.partyService.addToCartRequestObj.PageMethod = this.partyService.partyPageMethod ? this.partyService.partyPageMethod : this.partyService.privateLessonBookingData.PageMethod;
        this.partyService.addToCartRequestObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;

        var validationErr = this.ValidatePagingInfo(this.partyService.addToCartRequestObj);
        if (validationErr) {
          this.utilities.showAlert(validationErr, AlertType.Error, ButtonType.Ok);
          return;
        }
        if ((!guestData.MemberActive && this.partyService.savesessions) && (guestData.TrackMembershipNumber)) {
          const popUpMessage = [{
            confirmationMessage: 'Membership is either Inactive/Expired',
            dialogTitle: this.translateService.instant('Membership Status'),
            showAlert: true
          }];
          const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'static', popUpMessage);
          this.convertDialog = this.openCustomPopup(componentInfo, ComponentTypes.standby,
            '450px', '350px', false, popUpMessage[0].dialogTitle, 'OK', '', true);
        } else {
          if (isEdit) {
            if (!this.cs.settings.value.RetailItems || !this.cs.settings.value.RetailItems.length) {
              this.partyService.addToCartRequestObj.Slot = this.partyService.addToCartRequestObj.Slots[0];
              this.partyService.confirmUpdatePrivateLessonBooking(this.partyService.addToCartRequestObj, false, this.partyService.privateLessonBookingDialogRef);
            } else {
              this.getRegisterReservationAttempt(this.partyService.addToCartRequestObj, ComponentTypes.EditPrivateLessonBooking);
            }
          }
          else {
            this.partyService.createPrivateLessonBooking(this.partyService.addToCartRequestObj, false);
          }
        }
        // if (this.privateActivityBookingSubscription) {
        //   this.privateActivityBookingSubscription.unsubscribe();
        // }
      }
  }

  getNegotiatedValueForBooking(selectedData) {
    this.partyService.ratePlanForBooking?.RatePlanCalculations?.forEach(data => {
      if (data.RatePlanVariants) {
        if ((data.totalBaseRate * selectedData.BookingSize) != data.TotalAmount && (data.RatePlanVariants.GuestTypeVariant == undefined && data.RatePlanVariants.BookingTypeVariant == undefined)) {
          data.RatePlanVariants.NegotiatedAmount = +(data.sessionCharge * selectedData.BookingSize) - ((data.RatePlanVariants?.OriginalBaseRate || 0) * selectedData.BookingSize);
          data.RatePlanVariants.TotalVariants = data.TotalBaseRate;
        }
        if (data.RatePlanVariants.GuestTypeVariant && data.RatePlanVariants.GuestTypeVariant.length) {
          data.RatePlanVariants.GuestTypeVariant = data.RatePlanVariants.GuestTypeVariant.filter(guest => guest.covers > 0)
          data.RatePlanVariants.GuestTypeVariant.forEach(guest => {
            guest.NegotiatedAmount = (guest.coverTypeValue - guest.initialValue) * guest.covers;
            guest.Variant = guest.initialValue;
          });
        }
        if (data.RatePlanVariants.BookingTypeVariant && data.RatePlanVariants.BookingTypeVariant.length) {
          data.RatePlanVariants.BookingTypeVariant = data.RatePlanVariants.BookingTypeVariant.filter(type => type.bookingTypes > 0)
          data.RatePlanVariants.BookingTypeVariant.forEach(type => {
            type.NegotiatedAmount = (type.bookingTypeValue - type.initialValue) * type.bookingTypes;
            type.Variant = type.initialValue;
          })
        }
        if (data.RatePlanVariants.OriginalLocationVariant) {
          data.RatePlanVariants.LocationNegotiatedAmount = (_.sumBy(data.RatePlanVariants.LocationVariant , 'Variant')) - (data.RatePlanVariants.OriginalLocationVariant * selectedData.BookingSize);
        }
        if (data.RatePlanVariants.OriginalInstructorVariant) {
          data.RatePlanVariants.InstructorNegotiatedAmount = (data.RatePlanVariants.instructorAmount) - (data.RatePlanVariants.OriginalInstructorVariant * (this.partyService.ratePlanForBooking?.RatePlanCalculatorType == RatePlanCalculator.Duration ? 1 : selectedData.BookingSize));
        }
        if (data.MemberShipVariant) {
          data.NegotiatedMemberShipVariant = (data.membershipAmount - data.MemberShipVariant) || 0;
        }
      }
      if (data.AddonAttemptDetails && data.AddonAttemptDetails.length) {
        data.AddonAttemptDetails.forEach(addOn => {
          addOn.NegotiatedAmount = (addOn.perItemValue * addOn.Quantity) - (addOn.TotalAmount);
        })
      }


    });
  }

  CancelPartyForSessionOrClass(party , reason?: string) {
    if (this.appService.OTASourceId.includes(party.PartySourceId)) {
      this.showOTAAlert(party);
      return;
    }
    
    this.partyService.cancelBookingContactObj = party?.Contact;
    let cancelText = 'No';
    let title = 'cancelConfirmationMsg';
    let msg = this.cs.settings.value.SpecialMeals.find(meal => meal.Id == party.SpecialMealId)?.ClassType == PricingBy.Session && (party.BookedSessions && party.BookedSessions.length > 1) ?
      this.translateService.instant('cancelMultipleGuestBooking')
      : this.translateService.instant('cancelActivityMessage') + ' "' + this.cs.settings.value.SpecialMeals.find(meal => meal.Id == party.SpecialMealId)?.Name + '".'
    if(party.PackageId){
      msg = this.partyService.getPackageName(party.PackageId , 'packageCancelConfirmation');
    }
      let noShowSet = false;
    let cancelReservation = false;
    let updateText = 'Yes';
    let showAlert = false;
    let noShowFeePopUp = false;

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm', showAlert: showAlert, displayOptions: (this.cs.settings.value.SpecialMeals.find(meal => meal.Id == party.SpecialMealId)?.ClassType == PricingBy.Session) && (party.BookedSessions && party.BookedSessions.length > 1)
    }];

    const componentDetails: ComponentDetails = {
      componentName: CancelActivityPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: popUpMessage[0].displayOptions ? '550px' : '350px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.CancelActivityBooking,
        back: false,
        standalone: true,
        showAlert: true
      }
    });
    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      this.UnSubscribeCancelPopUpAction();
      if (cancelReservation) {
        let cancelAllSessions = this.partyService.cancelType === CancelActivityType.AllRemaining;
        let bookedSessionId = cancelAllSessions ? null : party.BookedSessionId;
        cancelReservation = false;
        this.collectReason(party, cancelAllSessions, bookedSessionId , reason);
      }
    }));

    this.cancelPopUpConfirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.CancelActivityBooking) {
        cancelReservation = true;
      }
    });
    this.cancelPopUpCloseSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.CancelActivityBooking) {
        cancelReservation = false;
      }
    });
    event.preventDefault();
    event.stopPropagation();
  }

  collectReason(party, cancelAllSessions, bookedSessionId , reason?:string) {
    let title = this.translateService.instant('cancellationReasonText');
    let cancelReservation = false;


    const popUpMessage = {
      Message: "",
      Reason: reason,
    };

    const componentDetails: ComponentDetails = {
      componentName: ReasonCollectionPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: this.translateService.instant('cancellationReasonText')
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '350px',
      data: {
        title,
        update: "Confirm",
        cancel: "No",
        componentDetails,
        from: ComponentTypes.CancelActivityBooking,
        back: false,
        standalone: true,
        showAlert: true
      }
    });
    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      confirmSubscription ? this.UnSubscribePopUpAction(confirmSubscription) : '';
      cancelSubscription ? this.UnSubscribePopUpAction(cancelSubscription) : '';
      setTimeout(() => {
        if (cancelReservation) {
          let cancellationReason = '';
          if (this.partyService.ReasonFromPopUp) {
            cancellationReason = this.partyService.ReasonFromPopUp;
            this.partyService.ReasonFromPopUp = null;
            this.getCancellationCharge(party, cancelAllSessions, bookedSessionId, cancellationReason);
          }
        }
      }, 1000)
    }));

    let confirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.CancelActivityBooking) {
        cancelReservation = true;
      }
    });
    let cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.CancelActivityBooking) {
        cancelReservation = false;
      }
    });
  }

  UnSubscribePopUpAction(obj) {
    if (obj) {
      obj.unsubscribe();
    }
  }

  getCancellationCharge(party, cancelAllSessions, bookedSessionId, cancellationReason) {
    this.subscriptions.add(this.partyService.getCancellationCharge(party.Id, cancelAllSessions, bookedSessionId).subscribe(
      (data) => {
        if (data?.Payload && data.Payload?.CancellationCharge > 0) {
          this.showCancellationAlertMessage(party, cancelAllSessions, bookedSessionId, cancellationReason, data.Payload)
        }
        else {
          this.getCancelFinancialEffect(party, cancelAllSessions, bookedSessionId, cancellationReason, false, false,data.Payload);
        }
      }
    ));
  }

  showCancellationAlertMessage(party, cancelAllSessions, bookedSessionId, cancellationReason, cancellationDto) {
    let selectedactivity = this.cs.settings.value.SpecialMeals.filter(a => a.Id == party.SpecialMealId)[0];
    const collectTypeInfo = party.PrepaymentState == PartyPrepaymentState.DepositCollected && selectedactivity && !cancellationDto.WillBeChargedImmediately ?
      selectedactivity.CollectType == CollectType.CheckIn ? this.translateService.instant('checkInMessage') : this.translateService.instant('checkOutMessage') : '';
    const cancellationcharge = this.cs.settings.value.General.OperationCurrency + cancellationDto.CancellationCharge.toFixed(2);
    let alertmsg = '';
    this.translateService.get('cancellationFeeInfoMessage', { cancellationcharge, collectTypeInfo }).subscribe(msgVal => alertmsg = msgVal);
    const popUpMessage = [{
      confirmationMessage: alertmsg, dialogTitle: this.translateService.instant('alert'), showAlert: false, displayOptions: false
    }];

    const componentDetails: ComponentDetails = {
      componentName: CancelActivityPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '350px',
      data: {
        title: popUpMessage[0].dialogTitle,
        update: this.translateService.instant('Confirm'),
        cancel: this.translateService.instant('ignore'),
        componentDetails,
        from: ComponentTypes.CancelActivityBooking,
        back: false,
        standalone: true,
        showAlert: true
      }
    });
    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      confirmSubscription ? this.UnSubscribePopUpAction(confirmSubscription) : '';
      cancelSubscription ? this.UnSubscribePopUpAction(cancelSubscription) : '';
    }));

    let confirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.CancelActivityBooking) {
        this.getCancelFinancialEffect(party, cancelAllSessions, bookedSessionId, cancellationReason, true, true,cancellationDto);
      }
    });
    let cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.CancelActivityBooking && val.value != 0) { //value == 0 means close pop up (clicking cross)
        this.getCancelFinancialEffect(party, cancelAllSessions, bookedSessionId, cancellationReason, false, false,cancellationDto);

      }
    });
    event.preventDefault();
    event.stopPropagation();
  }

  getCancelledItems(cancelAllSessions, bookedSessionId, cancellationData,applyCancellationCharge){
    return [{
      "BookedSessionId": cancelAllSessions ? null : bookedSessionId,
      "Amount": cancellationData && applyCancellationCharge ? cancellationData.CancellationCharge : 0
     }]
  
  }
  getCancelFinancialEffect(party, cancelAllSessions, bookedSessionId, cancellationReason, chargeCancellation: boolean, applyCancellationCharge,cancellationData) {
   let cancelledItems = this.getCancelledItems(cancelAllSessions, bookedSessionId, cancellationData,applyCancellationCharge) 
       this.partyService.getCancelFinancialDetails(party.Id, cancelledItems, applyCancellationCharge,cancelAllSessions).subscribe(data => {
      let financialData = data.Payload;

       if ((financialData.ReturnItem && financialData.ReturnItem.length > 0) || (financialData.ShopItems && financialData.ShopItems.length)){
        this.partyService.Operations = 3;
        const { EmailAddress, FirstName, LastName } = party.Contact;
        data.Payload = { ...data.Payload, ...party, EmailAddress, FirstName, LastName, PartyId: party.Id };
        this.partyService.bookingCancellationPayload = data;
        party.cancelAllSessions = cancelAllSessions;
        party.cancellationReason = cancellationReason;
        party.bookedSessionId = bookedSessionId;
        party.chargeCancellation = chargeCancellation;
        party.cancellationFee = cancellationData?.CancellationCharge || 0;
        party.applyCancellationCharge = applyCancellationCharge;
        party.financialEffectId = financialData.FinancialEffectId;
        this.partyService.cancelBookingDataObj = party;
        this.partyService.guestChargeCancelledPartyDetails = party;
        this.partyService.cancelBookingDataObj.CancellationFee = financialData?.CancellationAmount;
        this.partyService.RetailCanceloperation(party, financialData);
      }
      else  {
        this.partyService.cancelClassOrSessionWithoutPayment(party, cancelAllSessions, bookedSessionId, chargeCancellation, cancellationReason , financialData.FinancialEffectId)
      }
    })
  }
  UnSubscribeCancelPopUpAction() {
    if (this.cancelPopUpConfirmSubscription) {
      this.cancelPopUpConfirmSubscription.unsubscribe();
    }

    if (this.cancelPopUpCloseSubscription) {
      this.cancelPopUpCloseSubscription.unsubscribe();
    }
  }

  getWaitlistTabDetails(isEdit: boolean, tabInputData: any, fromAlertAudit?: boolean) {
    this.partyService.tabsModal = {
      tabs: this.getWaitlistComponentDetails(isEdit),
      default: false,
      componentInput: tabInputData,
      isNextButtonAvailable: true,
      isEditTab: isEdit
    };

    return {
      componentName: TabsComponent,
      dimensionType: 'large',
      popupType: 'tabs',
      tabs: this.partyService.tabsModal,
      popUpDetails: {
        isStepper: true,
        eventName: 'notifyParent'
      }
    };
  }

  getBookingTabDetails(isEdit: boolean, tabInputData: any, tabs) {
    this.partyService.tabsModal = {
      tabs: tabs,
      default: false,
      componentInput: tabInputData,
      isNextButtonAvailable: true,
      isEditTab: isEdit,
      //isDirectBooking: this._settings.General.RetailIntegrationDTO.IsEnabled && this._settings.RetailItems && this._settings.RetailItems.length > 0 ? true : false
    };

    return {
      componentName: TabsComponent,
      dimensionType: 'large',
      popupType: 'tabs',
      tabs: this.partyService.tabsModal,
      popUpDetails: {
        isStepper: true,
        eventName: 'notifyParent'
      }
    };
  }

  getWaitlistComponentDetails(isEdit) {
    return [
      {
        tabComponent: GeneralDataComponent,
        tabLabel: 'generalLabel',
        tabHeader: isEdit ? 'editWaitList' : 'addWaitlist',
        tabIcon: 'Group-748',
        isDisabled: false
      },
      {
        tabComponent: GuestDataComponent,
        enabledIcon: true,
        tabLabel: 'popUpGuest',
        tabHeader: isEdit ? 'editWaitList' : 'addWaitlist',
        tabIcon: 'Group-748',
        isDisabled: true
      }
    ];

  }

  getBookingComponentDetails(isEdit, inputData) {
    let tabsList: TabConfig[] = [
      {
        tabComponent: OpenBookingFormComponent,
        tabLabel: 'activityDetail',
        tabHeader: isEdit ? 'editOpenBooking' : 'addOpenBooking',
        tabIcon: 'Group-748',
        enabledIcon: true,
        isDisabled: false
      },
      {
        tabComponent: ReservationGuestsComponent,
        tabLabel: 'GuestDetailText',
        tabHeader: isEdit ? 'editOpenBooking' : 'addOpenBooking',
        tabIcon: 'Group-748',
        enabledIcon: true,
        isDisabled: false
      },
      {
        tabComponent: ReservationSummaryComponent,
        tabLabel: 'bookingSummary',
        tabHeader: isEdit ? 'editOpenBooking' : 'addOpenBooking',
        tabIcon: 'Group-748',
        isDisabled: false
      }
    ];
    // if(this.cs.settings.value.Addons?.length > 0){
    //   tabsList.splice(1,0,{
    //     tabComponent: AddOnForBookingComponent,
    //     tabLabel: 'Add-ons',
    //     tabHeader: 'Add-ons',
    //     tabIcon: 'Group-748',
    //     enabledIcon: true,
    //     isDisabled: false
    //   })
    // }
    if (isEdit && !inputData?.isCartEdit && (!this.cs.settings.value.RetailItems || !this.cs.settings.value.RetailItems.length)) {
      tabsList.push({
        tabComponent: OpenPaymentSummaryComponent,
        tabLabel: 'Payment',
        tabHeader: 'editOpenBooking',
        tabIcon: 'Group-748',
        isDisabled: false
      });

    }
    return tabsList;
  }

  getActivityBookingComponentDetails(isEdit, inputData) {
    let tabsList: TabConfig[] = [

      {
        tabComponent: ActivityBookingFormComponent,
        tabLabel: 'activityDetail',
        tabHeader: isEdit ? 'editActivityBooking' : 'addActivityBooking',
        tabIcon: 'Group-748',
        enabledIcon: true,
        isDisabled: false
      },
      {
        tabComponent: ReservationGuestsComponent,
        tabLabel: 'GuestDetailText',
        tabHeader: isEdit ? 'editActivityBooking' : 'addActivityBooking',
        tabIcon: 'Group-748',
        enabledIcon: true,
        isDisabled: false
      },
      {
        tabComponent: ReservationSummaryComponent,
        tabLabel: 'bookingSummary',
        tabHeader: isEdit ? 'editActivityBooking' : 'addActivityBooking',
        tabIcon: 'Group-748',
        isDisabled: false
      }
    ];
    // if(this.cs.settings.value.Addons?.length > 0){
    //   this.partyService.tabsList.splice(1,0,{
    //     tabComponent: AddOnForBookingComponent,
    //     tabLabel: 'Add-ons',
    //     tabHeader: 'Add-ons',
    //     tabIcon: 'Group-748',
    //     enabledIcon: true,
    //     isDisabled: false
    //   })
    // }
    if (isEdit && (!this.cs.settings.value.RetailItems || !this.cs.settings.value.RetailItems.length) && !inputData?.isCartEdit && (!inputData?.isStandByLessonBooked || (inputData?.isStandByLessonBooked && !inputData.isConfirmedReservation))) {
      tabsList.push({
        tabComponent: ActivityPaymentSummaryComponent,
        tabLabel: 'Payment',
        tabHeader: isEdit ? 'editActivityBooking' : 'addActivityBooking',
        tabIcon: 'Group-748',
        isDisabled: false
      });

    }
    return tabsList;
  }
  getPrivateActivityBookingComponentDetails(isEdit, inputData) {
    let tabsList: TabConfig[] = [
      {
        tabComponent: PrivateLessonBookingFormComponent,
        tabLabel: 'activityDetail',
        tabHeader: isEdit ? 'editPrivateActivityBooking' : 'addPrivateActivityBooking',
        tabIcon: 'ic_update_24px',
        enabledIcon: true,
        isDisabled: false
      },
      {
        tabComponent: ReservationGuestsComponent,
        tabLabel: 'GuestDetailText',
        tabHeader: isEdit ? 'editActivityBooking' : 'addActivityBooking',
        tabIcon: 'Group-748',
        enabledIcon: true,
        isDisabled: false
      },
      {
        tabComponent: ReservationSummaryComponent,
        tabLabel: 'bookingSummary',
        tabHeader: isEdit ? 'editPrivateActivityBooking' : 'addPrivateActivityBooking',
        tabIcon: 'Group-748',
        isDisabled: false
      }
    ];
    // if(this.cs.settings.value.Addons?.length > 0){
    //   tabsList.splice(1,0,{
    //     tabComponent: AddOnForBookingComponent,
    //     tabLabel: 'Add-ons',
    //     tabHeader: 'Add-ons',
    //     tabIcon: 'Group-748',
    //     enabledIcon: true,
    //     isDisabled: false
    //   })
    // }
    if (isEdit && (!this.cs.settings.value.RetailItems || !this.cs.settings.value.RetailItems.length) && !inputData.isCartEdit && (!inputData?.isStandByLessonBooked || (inputData?.isStandByLessonBooked && !inputData.isConfirmedReservation))) {
      tabsList.push({
        tabComponent: PrivatelessonPaymentSummaryComponent,
        tabLabel: 'Payment',
        tabHeader: isEdit ? 'editPrivateActivityBooking' : 'addPrivateActivityBooking',
        tabIcon: 'Group-748',
        isDisabled: false
      });

    }
    return tabsList;
  }

  buildWaitListRequest(request, editData?: PartyDTO): UpdatedWalkInDTO {
    if (request.selectedguest) {
      request.selectedguest.CountryId = request.selectedguest.PhoneNumber ? request.selectedguest.CountryId : null;
      request.selectedguest.CountryId2 = request.selectedguest.PhoneNumber2 ? request.selectedguest.CountryId2 : null;
    }
    const waitListRequest: UpdatedWalkInDTO = {} as UpdatedWalkInDTO;
    waitListRequest.Type = PartyType.WalkIn;
    waitListRequest.LanguageId = Number(sessionStorage.getItem('languageId')) || DEFAULT_LANGUAGE_ID;
    waitListRequest.IsQuickWalkIn = false;
    waitListRequest.ManualWaitTimeInMinutesFromNow = request.selectedtime.split(' ')[0] === 'Auto' ? 0 :
      (request.selectedtime.split(' ')[0] === 'Default' ? -2 : request.selectedtime.split(' ')[0]);
    waitListRequest.Size = +request.selectednumber;
    waitListRequest.WaitTimeType = request.selectedtime.split(' ')[0] === 'Auto' ? 0 :
      (request.selectedtime.split(' ')[0] === 'Default' ? 2 : 1);
    waitListRequest.SeatingAreaId = this.getSeatingAreaId(request);
    waitListRequest.Contact = request.selectedguest;
    waitListRequest.BookingContacts = request.selectedguest?.SecondaryContactsList || [];
    waitListRequest.TableIds = request.selectedTable && request.selectedTable.length ? request.selectedTable : [];
    // policy info
    this.getPolicyInfo(waitListRequest);
    waitListRequest.HostId = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostId;
    waitListRequest.Id = editData ? editData.Id : null;
    waitListRequest.PageMethod = editData && (editData.PageMethod == this.partyService.partyPageMethod) ? editData.PageMethod :
      (this.partyService.partyPageMethod ? this.partyService.partyPageMethod : PageMethod.Manual);
    waitListRequest.Notes = this.partyService.partyNotes;
    waitListRequest.PagerNumber = this.partyService.partyPagerNumber;
    if (request.selectedPartyStatus != -1) {
      waitListRequest.StatusId = request.selectedPartyStatus
    }
    waitListRequest.SeatingTypeId = this.getSeatingTypeId(request);
    return waitListRequest;
  }

  seatMaxSizeParty(party: any, from?: ComponentTypes) {
    const popupInput = { dashboardData: party, seatParty: true };
    let popupTitle = this.translateService.instant('quickSeatParty');
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(PreferredTableComponent, 'large', 'active', popupInput);
    const dialogRef = this.openCustomPopup(componentDetails, ComponentTypes.MaxPartyQuickseat, '100%', '100%', true, popupTitle, 'Ok',
      'Cancel', false, '100vw', 'preferred-table-panel', true);
    this.subscriptions.add(dialogRef.afterClosed().subscribe((result) => {
      if (result) {

      }
    }));
  }


  // Standby List

  createOrEditStandbyParty(isEdit: boolean, tabInputData: any) {
    if (this.standbySubscription) {
      this.standbySubscription.unsubscribe();
    }
    if (this.standbyCancelSubscription) {
      this.standbyCancelSubscription.unsubscribe();
    }
    this.partyService.reservationFormGroup.reset();
    this.partyService.partyPageMethod = PageMethod.Manual;
    this.partyService.partyNotes = [];
    this.partyService.slotLockId = null;
    this.createOrEditReservation(isEdit, tabInputData , null , ReservationType.StandbyParties);

  }

  getStandbyTabDetails(isEdit: boolean, tabInputData: any) {
    this.partyService.tabsModal = {
      tabs: this.getStandbyComponentDetails(isEdit),
      default: false,
      componentInput: tabInputData,
      isNextButtonAvailable: true,
      isEditTab: isEdit,
      customClass: 'equal-header'
    };
    return {
      componentName: TabsComponent,
      dimensionType: 'large',
      popupType: 'tabs',
      tabs: this.partyService.tabsModal,
      popUpDetails: {
        isStepper: true,
        eventName: 'notifyParent'
      }
    };
  }

  getStandbyComponentDetails(isEdit) {
    return [
      {
        tabComponent: GeneralDataComponent,
        tabLabel: 'generalLabel',
        tabHeader: isEdit ? 'editStandbyParty' : 'newStandbyParty',
        tabIcon: 'Group-748',
        isDisabled: false
      },
      {
        tabComponent: GuestDataComponent,
        enabledIcon: true,
        tabLabel: 'popUpGuest',
        tabHeader: isEdit ? 'editStandbyParty' : 'newStandbyParty',
        tabIcon: 'Group-748',
        isDisabled: false
      },
    ];
  }

  buildStandbyRequest(request, editData?: StandByPartyDTO) {
    if (request.selectedguest) {
      request.selectedguest.CountryId = request.selectedguest.PhoneNumber ? request.selectedguest.CountryId : null;
      request.selectedguest.CountryId2 = request.selectedguest.PhoneNumber2 ? request.selectedguest.CountryId2 : null;
    }
    const standbyRequest: UpdatedStandbyPartyDTO = {} as UpdatedStandbyPartyDTO;
    standbyRequest.Type = PartyType.StandBy;
    standbyRequest.Size = +request.selectednumber;
    standbyRequest.WishedTime = request.selectedtime.DateTime;
    standbyRequest.SeatingAreaId = this.getSeatingAreaId(request);
    standbyRequest.SeatingTypeId = this.getSeatingTypeId(request);
    if (request.partyid) { standbyRequest.Id = request.partyid; }
    standbyRequest.SeatingTime = null;
    standbyRequest.Contact = request.selectedguest;
    standbyRequest.BookingContacts = request.selectedguest?.SecondaryContactsList || [];
    //policy info
    this.getPolicyInfo(standbyRequest);
    standbyRequest.HostId = this.getHostId();
    standbyRequest.Id = editData ? editData.Id : null;
    standbyRequest.TableIds = [];
    standbyRequest.TableIds = request.selectedtable ? request.selectedtable : [];
    standbyRequest.PageMethod = editData && (editData.PageMethod == this.partyService.partyPageMethod) ? editData.PageMethod :
      (this.partyService.partyPageMethod ? this.partyService.partyPageMethod : PageMethod.Manual);
    standbyRequest.Notes = this.partyService.partyNotes;
    standbyRequest.PagerNumber = this.partyService.partyPagerNumber;
    standbyRequest.PartySourceId = editData && editData.PartySourceId ? editData.PartySourceId : null;
    standbyRequest.ReferenceNumber = editData && editData.ReferenceNumber ? editData.ReferenceNumber : null;
    standbyRequest.BookingContacts = request.selectedguest.SecondaryContactsList && request.selectedguest.SecondaryContactsList.length > 0 ? request.selectedguest.SecondaryContactsList : [];
    return standbyRequest;
  }
  buildStandbyConversionRequest(request, editData?: StandByPartyDTO) {
    if (request.selectedguest) {
      request.selectedguest.CountryId = request.selectedguest.PhoneNumber ? request.selectedguest.CountryId : null;
      request.selectedguest.CountryId2 = request.selectedguest.PhoneNumber2 ? request.selectedguest.CountryId2 : null;
    }
    const standbyRequest: UpdatedReservationDTO = {} as UpdatedReservationDTO;
    standbyRequest.ConciergeId = request.selectedConciergeId ? +request.selectedConciergeId : null;
    standbyRequest.HotelId = request.selectedHotelId ? +request.selectedHotelId : null;
    standbyRequest.Type = PartyType.Reservation;
    standbyRequest.Size = +request.selectednumber;
    standbyRequest.SeatingAreaId = this.getSeatingAreaId(request);
    if (request.partyid) { standbyRequest.Id = request.partyid; }
    const slot: UpdatedPartySlotDTO = {} as UpdatedPartySlotDTO;
    // setting slot data
    this.setSlot(request, slot);
    standbyRequest.SeatingTime = null;
    standbyRequest.Contact = request.selectedguest;
    //policy info
    this.getPolicyInfo(standbyRequest);
    standbyRequest.Slot = slot;
    standbyRequest.HostId = this.getHostId();
    standbyRequest.Id = editData ? editData.Id : null;
    standbyRequest.TableIds = [];
    standbyRequest.TableIds = request.selectedtable ? request.selectedtable : [];
    standbyRequest.PageMethod = editData && (editData.PageMethod == this.partyService.partyPageMethod) ? editData.PageMethod :
      (this.partyService.partyPageMethod ? this.partyService.partyPageMethod : PageMethod.Manual);
    standbyRequest.Notes = this.partyService.partyNotes;
    standbyRequest.PagerNumber = this.partyService.partyPagerNumber;
    standbyRequest.PartySourceId = editData && editData.PartySourceId ? editData.PartySourceId : null;
    standbyRequest.ReferenceNumber = editData && editData.ReferenceNumber ? editData.ReferenceNumber : null;
    return standbyRequest;
  }

  getSeatingAreaId(request: any) {
    let seatingAreaId = request.selectedarea && request.selectedarea.Id ? request.selectedarea.Id : request.selectedarea;
    return seatingAreaId && seatingAreaId > 0 ? seatingAreaId : null;
  }

  getSeatingTypeId(request: any) {
    let seatingTypeId = request.selectedType && request.selectedType.Id ? request.selectedType.Id : request.selectedType;
    return seatingTypeId && seatingTypeId > 0 ? seatingTypeId : null;
  }

  getPolicyInfo(request: any) {
    if (request.Contact)
      request.Contact.PIILastUsedDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
    if (this.partyService.dataRetentionPolicyChecked && request.Contact) {
      request.Contact.PIILastUsedDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
      request.Contact.ConsentDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
      request.Contact.ConsentPolicyId = this.partyService.consentPolicyId;
    }
    return request;
  }

  getHostId() {
    //return (this.partyService.reservationHost) ? this.partyService.reservationHost.Id : JSON.parse(sessionStorage.getItem('loginResult')).HostId;
    // when editing the reservation created from widget, we dont have host id unless we set host on edit
    return (this.partyService.reservationHost) ? this.partyService.reservationHost.Id : null;
  }

  showReservationToStandbyConfirmation(request, editData: any, dialogRef: any) {
    const popUpMessage = [{
      confirmationMessage: `${this.translateService.instant('reservationToStandbyConfirmationMsg')}`,
      dialogTitle: this.translateService.instant('reservationToStandbyConfirmation'),
      showAlert: false
    }];

    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'active', popUpMessage);
    this.convertDialog = this.openCustomPopup(componentInfo, ComponentTypes.standby,
      '450px', '300px', false, popUpMessage[0].dialogTitle, 'confirm', 'cancel', true);
    this.convertConfSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.standby) {
        const buildedRequest = this.buildStandbyRequest(request, editData);
        this.partyService.convertReservationToStandby(buildedRequest, buildedRequest.Id, dialogRef);
      }
    });
    this.subscriptions.add(this.convertDialog.afterClosed().subscribe(() => {
      if (this.convertConfSubscription) {
        this.convertConfSubscription.unsubscribe();
      }
    }));
  }

  showReservationToStandbyConfirmationForAdvance(request, editData: any, dialogRef: any) {
    const popUpMessage = [{
      confirmationMessage: `${this.translateService.instant('reservationToStandbyConfirmationMsg')}`,
      dialogTitle: this.translateService.instant('reservationToStandbyConfirmation'),
      showAlert: false
    }];

    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'active', popUpMessage);
    this.convertDialog = this.openCustomPopup(componentInfo, ComponentTypes.standby,
      '450px', '300px', false, popUpMessage[0].dialogTitle, 'confirm', 'cancel', true);
    this.convertConfSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.standby) {
        request.Type = PartyType.StandBy;
        request.WishedTime =request.Slot.Time
        this.partyService.convertReservationToStandby(request, request.Id, dialogRef);
      }
    });
    this.subscriptions.add(this.convertDialog.afterClosed().subscribe(() => {
      if (this.convertConfSubscription) {
        this.convertConfSubscription.unsubscribe();
      }
    }));
  }

  showErrorPopUp(errorMsg) {
    const popUpMessage = [{
      confirmationMessage: errorMsg,
      dialogTitle: this.translateService.instant('alert'),
      showAlert: true
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'action',

      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: this.translateService.instant('alert')
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '430px',
      data: {
        title: this.translateService.instant('alert'),
        showAction: true,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.specialValidation,
      }
    });
  }

  checkSpecialMealApplicableForSelectedSlot(request): boolean {
    let SpecialMealApplicable = true;
    this.selectedSpecialMealName = "";
    let SpecialMealList = _.cloneDeep(this.cs.settings.value.SpecialMeals);
    if (request.SpecialMealId) {
      SpecialMealApplicable = false;
      let selectedMeal = SpecialMealList.filter(x => x.Id == request.SpecialMealId);
      const selecteddateTime = this.partyService.reservationFormGroup.get('selectedTime').value;
      const _selectedDate = this.partyService.reservationFormGroup.get('selectedDate').value;
      let SelectedDate = new Date(selecteddateTime.DateTime);
      if (selectedMeal && selectedMeal.length > 0 && selectedMeal[0].ShiftId) {
        var timerange = Utilities.getMealShiftDate(this.cs.settings.value, selectedMeal[0].StartDate, selectedMeal[0].EndDate, selectedMeal[0].ShiftId);
        const selecteddateTime = this.partyService.reservationFormGroup.get('selectedTime').value;
        let SelectedDate = new Date(selecteddateTime.DateTime);
        if (Utilities.datetimeBetween(timerange[0], timerange[1], SelectedDate)) {
          SpecialMealApplicable = true;
          this.selectedSpecialMealName = selectedMeal[0].Name;
        }
      } else if (selectedMeal && selectedMeal.length > 0 && !selectedMeal[0].ShiftId) {
        if (selectedMeal[0].StartDate && selectedMeal[0].EndDate && selectedMeal[0].ActivitySessions) {
          let _date;
          let _endDate = new Date(selectedMeal[0].EndDate);
          this.selectedSpecialMealName = selectedMeal[0].Name;
          let mealActivitySessions = selectedMeal[0].ActivitySessions.filter(x => x.Dayofweek == new Date(_selectedDate).getDay());
          if (mealActivitySessions.length > 0) {
            let stimeold = mealActivitySessions[0].StartTime.split(':');
            let etimeold = mealActivitySessions[0].EndTime.split(':');
            let currentstime = new Date(new Date().setHours(Number(stimeold[0]), Number(stimeold[1]), Number(stimeold[2])))
            let currentetime = new Date(new Date().setHours(Number(etimeold[0]), Number(etimeold[1]), Number(etimeold[2])))
            let _sTime = new Date('1/1/1999 ' + mealActivitySessions[0].StartTime);
            let _eTime = new Date('1/1/1999 ' + mealActivitySessions[0].EndTime);
            let _cTime = new Date(new Date('1/1/1999 ').setHours(SelectedDate.getHours(), SelectedDate.getMinutes(), SelectedDate.getSeconds()));
            if (currentstime.getTime() > currentetime.getTime()) {
              _endDate.setDate(_endDate.getDate() + 1);
              _date = (moment(_endDate).format('YYYY-MM-DDTHH:mm:ss')).toString();
              _eTime.setDate(_eTime.getDate() + 1);
              if (SelectedDate.getDate() > new Date(_selectedDate).getDate()) {
                _cTime.setDate(_cTime.getDate() + 1);
              }
            }
            let sDate = new Date(new Date(selectedMeal[0].StartDate));
            let eDate = _date ? _date : _endDate;
            let cDate = new Date(new Date(SelectedDate).setHours(0, 0, 0, 0));
            if ((mealActivitySessions.length > 0) && Utilities.datetimeBetween(sDate, eDate, cDate) && Utilities.datetimeBetween(_sTime, _eTime, _cTime)) {
              SpecialMealApplicable = true;
            } else {
              SpecialMealApplicable = false;
            }
          } else {

            SpecialMealApplicable = false;
          }
        } else {
          if (selectedMeal && selectedMeal.length > 0) {
            SpecialMealApplicable = true;
          }
        }
      }
    }
    return SpecialMealApplicable;
  }
  setSlot(request: any, slot: UpdatedPartySlotDTO, editData?: ReservationDTO) {
    const slottingMode = this.cs.settings.value.General.SlottingMode;
    const slotType = slottingMode == SlottingMode.Auto ? UpdatedPartySlotType.AutoSlot : UpdatedPartySlotType.ManualActualSlot;
    let editSlotorNewSlotId = (editData && editData.SlotId) ? editData.SlotId : null;
    if (editSlotorNewSlotId) {
      if (this.partyService.reservationFormGroup.value.selectedTime.Id == editSlotorNewSlotId) {
        editSlotorNewSlotId = editSlotorNewSlotId;
        slot.Type = slotType;
      }
      else {
        editSlotorNewSlotId = request.selectedtime.Id;
        slot.Type = request.selectedtime.Type;
      }
    }
    else {
      editSlotorNewSlotId = request.selectedtime.Id
      slot.Type = request.selectedtime.Type;
    }
    // for Past Reservation
    if (editData && editData.State == PartyState.Left && editSlotorNewSlotId) {
      editSlotorNewSlotId = editSlotorNewSlotId;
    }
    slot.Time = request.selectedtime.DateTime;
    slot.IsTimeInUtc = false;
    slot.Id = editSlotorNewSlotId;
    slot.LockId = this.partyService.slotLockId ? this.partyService.slotLockId : null;
    console.log(slot);
  }

  getPredefinedPartyNotes(removeOthers?) {
    let PredefinedPartyNotes: PredefinedPartyNoteDTO[] = [];
    this.cs.settings.value.Categories.forEach(category => {
      let { SubCategories = [] } = category;
      if (removeOthers) {
        SubCategories = SubCategories.filter(subCategory => subCategory.Text != 'Others');
      }
      SubCategories.forEach((SubCategory: SubCategoryDto) => {
        const { PredefinedPartyNote = [] } = SubCategory
        PredefinedPartyNotes.push(...PredefinedPartyNote);
      });
    });
    return PredefinedPartyNotes;
  }

  getPredefinedContactNotes(removeOthers?) {
    let PredefinedContactNotes: PredefinedContactNoteDTO[] = [];
    this.cs.settings.value.Categories.forEach(category => {
      let { SubCategories = [] } = category;
      if (removeOthers) {
        SubCategories = SubCategories.filter(subCategory => subCategory.Text != 'Others');
      }
      SubCategories.forEach((SubCategory: SubCategoryDto) => {
        const { PredefinedContactNote = [] } = SubCategory;
        PredefinedContactNotes.push(...PredefinedContactNote);
      });
    });
    return PredefinedContactNotes;
  }

  showOTAAlert(party) {
    let cancelText = 'No';
    let title = this.translateService.instant('alert');
    let msg = this.translateService.instant('cancelAlertOTA');
    let thirdPartyCancellation = false;
    let updateText = 'Yes';
    let showAlert = true;

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm', showAlert: showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '350px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.cancelOTAValidation,
        back: false,
        standalone: true,
        showAlert: true
      }
    }); this.confirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      thirdPartyCancellation = true;
    });
    this.cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      thirdPartyCancellation = false;

    });
    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      if (this.confirmSubscription) { this.confirmSubscription.unsubscribe(); }
      if (this.cancelSubscription) { this.cancelSubscription.unsubscribe(); }
      if (thirdPartyCancellation) {
        party && party.Type == PartyType.StandBy ? this.partyService.removeStandbyParty(party.Id).subscribe(data => {
          if (data) {
            dialogRef.close();
          }
        }) : this.cancelParty(party);
      }
    }));
  }
  getRegisterReservationAttempt(SaveBookingObj, componentType) {
    let registerReservationAttemptRequestDTO = {
      PartySize: SaveBookingObj.Size,
      Sessions: SaveBookingObj.Sessions,
      ActivityId: SaveBookingObj.SpecialMealId,
      CoverTypes: SaveBookingObj.CoverTypes,
      TableIds: SaveBookingObj.TableIds,
      BookingTypes: SaveBookingObj.BookingTypes,
      FromWebsite: true,
      Slots: SaveBookingObj.AttemptSlots,
      PartyId: SaveBookingObj.Id,
      MemberType: SaveBookingObj.Contact?.IsMemberActive && SaveBookingObj.Contact.MemberType ? SaveBookingObj.Contact.MemberType : null
    }

    if (componentType !== ComponentTypes.EditActivityBooking && componentType !== ComponentTypes.EditOpenBooking && componentType !== ComponentTypes.EditPrivateLessonBooking) {
      this.partyService.RegisterReservationAttempt(registerReservationAttemptRequestDTO).subscribe(data => {
        if (data) {
          this.partyService.ReservationAttemptData = data;
          this.getFinancialEffect(componentType);
        }
      });
    } else {
      this.partyService.ReservationAttemptData = null;
      this.getFinancialEffect(componentType);
    }
  }

  editGuest(contactId: number, partyId: number) {
    this.gbs.editGuest = true;
    this.subscriptions.add(this.fs.getContact(contactId).subscribe(data => {
      if (data && data.Payload && (data.Payload.FirstName != null || data.Payload.LastName)) {
        this.fs.resetGuestForm();
        const tabcomponent: TabConfig[] = [{
          tabComponent: PersonalInfoComponent,
          enabledIcon: true,
          tabLabel: 'personalInfoTab',
          tabIcon: 'Path-503 ',
          isDisabled: false
        },
        {
          tabComponent: ContactComponent,
          enabledIcon: true,
          tabLabel: 'contactTab',
          tabIcon: 'Group-593',
          isDisabled: false
        },
        {
          tabComponent: AddressComponent,
          enabledIcon: true,
          tabLabel: 'addressTab',
          tabIcon: 'Group-593',
          isDisabled: false
        },
        {
          tabComponent: PreferencesComponent,
          enabledIcon: true,
          tabLabel: 'prefernencesTab',
          tabIcon: 'Group-601',
          isDisabled: false
        }
        ];

        if (this.cs.settings.value.CustomGuestFields.length > 0) {
          tabcomponent.push({
            tabComponent: AdditionalinfoComponent,
            enabledIcon: true,
            tabLabel: 'additionalInfoTab',
            tabIcon: 'Group-602',
            isDisabled: false
          });
        }

        let tabsModal = {
          tabs: tabcomponent,
          default: true,
          componentInput: data.Payload,
          isNextButtonAvailable: true,
          isEditTab: true
        };
        this.fs.setGuestBookTabsModel(tabsModal);
        const tabComponentDetails: ComponentDetails = {
          componentName: TabsComponent,
          popupType: 'tabs',
          tabs: tabsModal,
          popUpDetails: {
            isStepper: true,
            eventName: 'notifyParent'
          }
        };

        let createContactDialogRef = this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          height: '85%',
          width: '65%',
          data: {
            title: 'Edit Guest',
            update: 'ok',
            cancel: 'cancel',
            componentDetails: tabComponentDetails,
            from: ComponentTypes.guestBook,
            back: true,
            standalone: true
          },
        });
        let confirmSubscription: ISubscription = this.popupService.confirmedAction$.subscribe((tabOutputInfo) => {
          if (tabOutputInfo.label === 'save' && tabOutputInfo.isEdit && tabOutputInfo.fromComponent === ComponentTypes.guestBook) {
            this.fs.updateGuest(true, tabOutputInfo.inputData, createContactDialogRef);
          }
        });
        createContactDialogRef.afterClosed().subscribe(() => {
          if (confirmSubscription) { confirmSubscription.unsubscribe(); }
        })
      }
      else {
        this.createTabsModalDetailsQuickSeat(contactId, partyId, false, false, true, null);
      }
    }));
  }


  createTabsModalDetailsQuickSeat(contactId: number, partyId: number, isDefault: boolean, isNext: boolean, isEdit: boolean, tabInputData: any) {
    this.partyService.tabsModal = {
      tabs: this.getQuickSeatComponentDetails(),
      default: isDefault,
      componentInput: tabInputData,
      isNextButtonAvailable: isNext,
      isEditTab: isEdit,
    };

    this.openDialogQuickSeat(contactId, partyId);
  }

  openDialogQuickSeat(contactId, partyId) {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '80%',
      width: '45%',
      data: {
        selectedPopupType: PopupType.Waitlist,
        title: this.translateService.instant('popuptitleseatguest'),
        update: 'ok',
        cancel: 'cancel',
        componentDetails: this.getQuickSeatTabDetails(),
        from: ComponentTypes.guestBook,
        back: true,
        standalone: true
      }
    });
    let confirmSubscription: ISubscription = this.popupService.confirmedAction$.subscribe((tabOutputInfo) => {
      if (tabOutputInfo === ComponentTypes.guestBook) {
        if (this.partyService.isExistingContactQS) {
          const selectedguest = this.partyService.reservationFormGroup.value.selectedGuest;
          this.partyService.isExistingContactQS = false;
          this.partyService.changeContact(partyId, selectedguest);
        }
        else {
          if (contactId) {
            const selectedguest = this.partyService.reservationFormGroup.value.selectedGuest;
            if (selectedguest) {
              selectedguest.Id = contactId;
              this.partyService.updateGuest(true, selectedguest);
            }
          }
        }
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (confirmSubscription) { confirmSubscription.unsubscribe(); }
    })
  }

  getQuickSeatTabDetails() {
    return {
      componentName: TabsComponent,
      dimensionType: 'large',
      popupType: 'active',
      tabs: this.partyService.tabsModal,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
  }

  getQuickSeatComponentDetails() {
    return [
      {
        tabComponent: GuestSelectionComponent,
        enabledIcon: true,
        tabLabel: 'popUpGuest',
        tabHeader: 'popuptitleseatguest',
        tabIcon: 'Group-593',
        isDisabled: false
      }
    ];
  }

  LoadPartiesForNonDinningProperties(reservationData: DateStateDTO[], propertyId?: number) {
    let reservations = [];
    reservations = reservationData.map(({ ActivityBookings }) => ActivityBookings).flat();
    let cancelledReservations = reservations?.filter(reservation => reservation.State == PartyState.Cancelled) || [];
    let standByReservations = reservationData.map(({ ActivityStandByBookings }) => ActivityStandByBookings).flat();
    reservations = reservations.filter(reservation => (reservation.State == PartyState.Pending || reservation.State == PartyState.Seated || reservation.State == PartyState.Left || reservation.State == PartyState.Cancelled));
    // standByReservations = uniqBy(standByReservations, 'Id');

    if (propertyId) {
      this.cs.propertySettings.value[propertyId].reservations = [...reservations];
      this.cs.propertySettings.value[propertyId].StandbyParties = [...standByReservations];
    } else {
      this.partyService.partiesList = [];
      this.partyService.partiesList = [...reservations];
      this.partyService.standbyPartiesList = [...standByReservations];
      this.partyService.StandbyParties$.next(standByReservations);
      this.partyService.Parties$.next(reservations);
      this.partyService.CancelledReservations$.next(cancelledReservations)
      if (this.cs.propertySettings.value[sessionStorage.getItem('restaurantId')]) {
        this.cs.propertySettings.value[sessionStorage.getItem('restaurantId')].cancelledReservations = [...cancelledReservations]
        this.cs.propertySettings.value[sessionStorage.getItem('restaurantId')].reservations = [...reservations];
        this.cs.propertySettings.value[sessionStorage.getItem('restaurantId')].StandbyParties = [...standByReservations];
      }
    }
  }

  LoadPartiesForDinningParties(currentReservations: DateStateDTO[], propertyId?: number) {
    const partyData = [];
    const allReservations: ReservationDTO[] = [];
    let waitlistData: WalkInDTO[] = [];
    const allStandByReservations: StandByPartyDTO[] = [];
    currentReservations.forEach(current => {
      current.Shifts.forEach((shift: DateShiftStateDTO) => {
        shift.Reservations.forEach((reservation: ReservationDTO) => {
          if (reservation.State != PartyState.Seated) {
            allReservations.push(reservation);
          }
        });
        partyData.push({
          Shift: {
            Name: shift.Shift.Name,
            EffectiveRange: shift.Shift.EffectiveRange,
            Id: shift.Shift.Id
          },
          Reservations: shift.Reservations
        });
        shift.StandByParties.forEach((reservation: StandByPartyDTO) => {
          allStandByReservations.push(reservation);
        });
      });

    });

    if (currentReservations.length > 0 && currentReservations[0].Shifts.length > 0) {
      waitlistData = currentReservations[0].Shifts[0].WaitLists.filter(p => p.State != PartyState.Seated).sort((a, b) => {
        const timeA: any = new Date(a.ArrivedAt);
        const timeB: any = new Date(b.ArrivedAt);
        const currentTime: any = new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)).getTime();
        return Math.floor((timeA - currentTime) / 1000) - Math.floor((timeB - currentTime) / 1000);
      });
    }

    const shifts = [...new Set(partyData.map(x => x.Shift.Name))];
    let parties = [];
    shifts.forEach(shift => {
      parties.push({
        Shift: {
          Name: shift
        },
        Reservations: []
      });
    });
    partyData.forEach(data => {
      parties.forEach(shift => {
        if (shift.Shift.Name == data.Shift.Name) {
          shift.Reservations = shift.Reservations.concat(data.Reservations);
        }
      });
    });
    let SeatingParties = this.cs.state.value.SeatingParties;
    if (propertyId) {
      SeatingParties = this.cs.propertySettings.value[propertyId].state.SeatingParties;
    }
    let reservations = [...allReservations, ...waitlistData, ...SeatingParties];
    if (reservations?.length) {
      reservations.map(item => {
        if (item.BookingContactAddonItems?.length) {
          let addonList = [];
          item.BookingContactAddonItems.forEach(eachItem => {
            let addon = {} as AddOnRequest;
            addon.Quantity = eachItem.AddonCount;
            addon.OverBooked = eachItem.OverBooked;
            addon.AddonId = eachItem.AddonId;
            addonList.push(addon);
          })
          item.SelectedAddOns = addonList;
        }
        if(item?.CoverTypeQuantities){
          item.CoverTypes = item.CoverTypeQuantities
        }
      })
    }
    let standbyParties = _.cloneDeep(allStandByReservations);
    let allShiftsData = partyData.map((party: DateShiftStateDTO) => party.Shift);
    if (propertyId) {
      this.cs.propertySettings.value[propertyId].reservations = [...reservations];
      this.cs.propertySettings.value[propertyId].StandbyParties = [...standbyParties];
      this.cs.propertySettings.value[propertyId].shifts = [...allShiftsData];
    } else {
      this.partyService.standbyPartiesList = standbyParties;
      this.partyService.StandbyParties$.next(standbyParties);
      this.updateShiftName(allShiftsData);
      this.partyService.shifts$.next(allShiftsData);
      this.partyService.partiesList = reservations;
      this.partyService.Parties$.next(reservations);
      if(this.cs.propertySettings.value[sessionStorage.getItem('restaurantId')]){
      this.cs.propertySettings.value[sessionStorage.getItem('restaurantId')].reservations = [...reservations];
      this.cs.propertySettings.value[sessionStorage.getItem('restaurantId')].StandbyParties = [...standbyParties];
      this.cs.propertySettings.value[sessionStorage.getItem('restaurantId')].shifts = [...allShiftsData];
      }
    }
  }
  editItem(event: Event, data) {
    event?.stopPropagation();
    let selectedParty = this.setPartyDetails(data);
    if(data.CartGroupName && !data.CartItemDetail?.CartGroupName){
      data.CartItemDetail.CartGroupName = data.CartGroupName;
    }
    if(data.CartItemDetail){
      selectedParty.SlotLockResult = data.CartItemDetail.SlotLockResultDTO?.SlotLockIdDTO;
    }
    
    switch (data.CartItemDetail.BookingBehavior) {
      case BookingBehavior.OpenBooking:
        selectedParty.ReservedFor = new Date(data.CartItemDetail.StartDate);
        selectedParty.Slots = data.CartItemDetail.Slots;
        selectedParty.bookingRatePlan = data.CartItemDetail;
        this.createOrEditOpenBooking(true, selectedParty);
        break;
      case BookingBehavior.ClassOrSession:
        selectedParty = this.setPartySessionClassInfo(selectedParty, data.CartItemDetail);
        selectedParty.bookingRatePlan = data.CartItemDetail;
        this.createOrEditActivityBooking(true, selectedParty);
        break;
      case BookingBehavior.PrivateLesson:
        selectedParty.SeatingTime = selectedParty.ReservedFor = new Date(data.CartItemDetail.StartDate);
        selectedParty.Slots = data.CartItemDetail.Slots;
        selectedParty.addPrivateLessonBooking = data.addPrivateLessonBooking;
        selectedParty.bookingRatePlan = data.CartItemDetail;
        this.createOrEditPrivateActivityBooking(true, selectedParty);
        break;
    }
    // this.appService.showCartItem = false;
  }

  setPartyDetails(partyData) {
    let party = {} as any;
    party.CartId = partyData.Id;
    party.Size = partyData.CartItemDetail.Size;
    party.CoverTypeQuantities = partyData.CartItemDetail.CoverTypes;
    party.BookingTypeQuantities = partyData.CartItemDetail.BookingTypes;
    party.BookingContacts = partyData.CartItemDetail.BookingContacts;
    party.StartDate = partyData.CartItemDetail.StartDate
    party.EndDate = partyData.CartItemDetail.EndDate;
    party.TableIds = partyData.CartItemDetail.TableIds;
    party.StaffId = partyData.CartItemDetail.InstructorIds;
    party.Contact = partyData.CartItemDetail.Contact;
    party.Contact.SecondaryContactsList = partyData.CartItemDetail.SecondaryContactsList;
    party.BookingContactAddonItems =  partyData.CartItemDetail?.BookingContacts?.length ?  partyData.CartItemDetail.BookingContacts.map(({ContactId, Addons}) => {
      return Addons.map(addon => {
        return {
          BookingContactId: ContactId,
          AddonId: addon.AddonId,
          AddonCount: addon.Quantity
        };
      })
    }).flat() : [];
    // party.BookedSessions = [];
    party.SpecialMealId = partyData.CartItemDetail.SpecialMealId;
    party.isCartEdit = true;
    party.NegotiatedAmount = partyData.NegotiatedAmount;
    party.Comments = partyData.Comments;
    party.Sessions = partyData.CartItemDetail.Sessions;
    party.Notes = partyData.CartItemDetail.Notes;
    party.SelectedAddOns = partyData.CartItemDetail.SelectedAddOns;
    party.PaidAmount = partyData.CartItemDetail.PaidAmount;
    party.IsExclusiveBooking = partyData.CartItemDetail.IsExclusiveBooking ? partyData.CartItemDetail.IsExclusiveBooking : false;
    if(partyData.RatePlan?.Reservations?.length && partyData.RatePlan.Reservations[0]?.OfferId?.length ){
      party.OfferCode = partyData.RatePlan.Reservations[0].OfferId[0]
    }
    return party;
  }

  setPartySessionClassInfo(partyData, party) {
    if (!partyData.BookedSessions) {
      partyData.BookedSessions = [];
    }
    if (party.Sessions.Sessions && party.Sessions.Sessions.length) {
      party.Sessions.Sessions.forEach(session => {
        session.SessionIds.forEach(id => {
          partyData.BookedSessions?.push({
            Id: null,
            PartyId: null,
            ActivitySessionId: id,
            BookedDate: session.Date,
            BookingSize: partyData.Size,
            SessionState: 0,
            RatePlanAmount: null
          })
        });

      });
    } else {
      partyData.SessionGroupId = party.SessionGroupId ? party.SessionGroupId : party.Sessions.SessionGroupId;
    }


    return partyData;
  }

  PopupMessageComponent(PopupMessageComponent: any, arg1: string, arg2: string, popUpMessage: { confirmationMessage: string; dialogTitle: any; showAlert: boolean; }[], dialogTitle: any): ComponentDetails {
    throw new Error('Function not implemented.');
  }

  ValidatePagingInfo(bookingData: any) {
    let validationMsg: any = null;
    if (bookingData.Contact != null) {
      if (bookingData.PageMethod == PageMethod.Email && !bookingData.Contact.EmailAddress) {
        validationMsg = this.translateService.instant('mailPagingMsg');
      }
      if (bookingData.PageMethod == PageMethod.Email2 && !bookingData.Contact.EmailAddress2) {
        validationMsg = this.translateService.instant('mail2PagingMsg');
      }
      if ((bookingData.PageMethod == PageMethod.Sms || bookingData.PageMethod == PageMethod.VoiceCall) && !this.HasAnyPhoneNumber(bookingData.Contact)) {
        validationMsg = this.translateService.instant('smsPagingMsg');
      }
      if ((bookingData.PageMethod == PageMethod.Sms2 || bookingData.PageMethod == PageMethod.VoiceCall2) && !this.HasSecondaryPhoneNumber(bookingData.Contact)) {
        validationMsg = this.translateService.instant('sms2PagingMsg');
      }
      return validationMsg;
    }
  }

  HasAnyPhoneNumber(contact: any) {
    return contact.PhoneNumber || contact.PhoneNumber2;
  }

  HasSecondaryPhoneNumber(contact: any) {
    return contact.PhoneNumber2;
  }
  getSelectedAddOns() {
    let selectedAddOns: any = this.partyService.addOnList.flat().filter(x => x.selectedQuantity > 0 && x.IsVisible);
    return selectedAddOns?.map(addOn => { return { Name: addOn.AddonName, Price: addOn.Price, AddonId: addOn.AddonId, Quantity: addOn.selectedQuantity, Category: addOn.PricingCategory, OverBooked: addOn.OverBooked } });
  }

  getPrivateAndPublicHeadline(ActivityAttributes, session, resDayWeek?) {
    let headline;
    if (ActivityAttributes?.length) {
      let columnDate = resDayWeek ? resDayWeek.find(x => x.dayOfWeek == session.Dayofweek)?.date || this.cs.headerDate : this.cs.headerDate
      headline = ActivityAttributes.find(x => x.ActivitySessionId == session.SessionId && x.Description != '' && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"));
      if(!headline){
        headline = ActivityAttributes.find(x => x.ActivitySessionId == session.SessionId && x.Description == '' && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"));
      }
      if (!headline) {
        headline = ActivityAttributes.find(x => x.ApplyForAllSessions && !x.IsDefault && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"))
      }
      if (!headline) {
        headline = ActivityAttributes.find(x => x.IsDefault && !x.ActivitySessionId);
      }
    }
    return headline ? headline.Description : null
  }

  getReservationNotes(attendee, type) {
    let notesReservation: any = [];
    let settings = this.cs.settings.value;
    let parties;
    if (type == BookingBehavior.PrivateLesson && attendee.Type == PartyType.StandBy) {
      parties = this.partyService.StandbyParties$?.value.find((party) => party.Id == attendee.Id);
    } else {
      parties = this.partyService.Parties$?.value.find((party) => party.Id == attendee.Id);
    }
    // if (settings.SpecialMeals?.length) {
    //   let specialMeal = settings.SpecialMeals?.find(meals => parties?.Notes?.some(note => note.RelatedId == meals.Id));
    //   if (specialMeal != undefined || specialMeal != null) {
    //     notesReservation.push({ 'Text': specialMeal.Name });
    //   }
    // }
    if (settings.Categories?.length) {
      let partyNotes = settings.Categories?.map((category) => category.SubCategories?.map((subCategory) => subCategory.PredefinedPartyNote?.map((partyNote) => partyNote)))?.flat(2);
      let reserveNotes = parties?.Notes?.filter(note => partyNotes?.some(partyNote => partyNote.Id == note.RelatedId && note.Type != PartyNoteType.SpecialMeal));
      if (reserveNotes?.length) {
        reserveNotes = _.sortBy(reserveNotes, "Text");
        notesReservation.push(...reserveNotes);
      }
    }
    return notesReservation;
  }


  formRatePlanSlotValue(ratePlan, coversQty, data, firstCall, dataForSlotRatePlanCalc?, partyData?, fromRatePlan?) {
    let adjustedMinRate = 0.00;
    let negotiatedDiscount = 0.00;
    let activitiesAmount = 0.00;
    let additionalCharge = 0.00;
    let coverTypes = [...this.partyService.cs.settings.value.CoverTypes];
    let bookingTypes = [...this.partyService.cs.settings.value.BookingTypes];

    let ratePlanCalc = ratePlan?.RatePlanCalculations.map(slot => {
      // if(data){

      if (fromRatePlan) {
        partyData = data.financialData.RatePlanTotal.RatePlanCalculations.find(cal => JSON.stringify(cal.TimeRange) === JSON.stringify(slot.TimeRange))
      }

      slot.activitiesAmount = 0.00;
      slot.slotItemDiscount = 0.00;
      slot.sessionCharge = 0.00;
      let ratePlanDetails = data && data.bookingRatePlan && firstCall ? data.bookingRatePlan?.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation : ratePlan.RatePlanCalculations;
      let slotItem = ratePlanDetails.find(data => data.Id == slot.Id);
      slot.totalSessionRetailCharge = 0;
      // slot.totalSessionCharge = 0;


      slot.coverTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.GuestTypeVariant ? slot.RatePlanVariants.GuestTypeVariant.map(coverVariant => {
        coverVariant.adjustedMinRate = 0;
        if (slotItem && slotItem.GuestTypeVariant?.length) {
          slotItem.GuestTypeVariant.forEach(element => {
            slot.GuestTypeVariant.filter(guest => guest.CoverTypeId == element.CoverTypeId).map(data => {
              data.NegotiatedAmount = element.NegotiatedAmount || 0;
            })
          });
        }
        coverVariant.CoverTypeName = coverTypes.find(coverType => coverType.Id == coverVariant.CoverTypeId).Name;
        coverVariant.Price = 
           coverVariant.Variant;


        coverVariant.calculatesBaseVariant = coverVariant.OriginalVariant;

        coverVariant.initialValue = this.currencyDecimalFormater.transform(((coverVariant.calculatesBaseVariant)));

        let negotiatePricePerCover = coverVariant.NegotiatedAmount ? coverVariant.NegotiatedAmount / coverVariant.covers : 0;

        if (data && data.Covers && coverVariant.NegotiatedAmount && coverVariant.covers) {
          let prevCoverData = data.CoverTypeQuantities.find(prevCover => prevCover.CoverTypeId === coverVariant.CoverTypeId);
          if (prevCoverData && prevCoverData.Covers) {
            negotiatePricePerCover = coverVariant.NegotiatedAmount / prevCoverData.Covers;
          }
        }

        if (!dataForSlotRatePlanCalc.applyNegotiationClicked) {
          dataForSlotRatePlanCalc.prevSalePrice.cover[slot.TimeRange.Start + '_' + coverVariant.CoverTypeId] = data?.Id ? this.currencyDecimalFormater.transform(((coverVariant.calculatesBaseVariant + negotiatePricePerCover))) : '-';
        }
        // let coverTypeItemFromParty  = partyData ? partyData.RatePlanVariants?.GuestTypeVariant.find(coverItem => coverVariant.CoverTypeId == coverItem.CoverTypeId) : null;
        // dataForSlotRatePlanCalc.prevSalePrice.cover[slot.TimeRange.Start+'_'+coverVariant.CoverTypeId]= Number(coverVariant.initialValue) + (coverTypeItemFromParty?(coverTypeItemFromParty.NegotiatedAmount / coverTypeItemFromParty.covers):0);
        let coverTypeItemFromParty = partyData ? partyData.RatePlanVariants?.GuestTypeVariant.find(coverItem => coverVariant.CoverTypeId == coverItem.CoverTypeId) : null;
        dataForSlotRatePlanCalc.prevSalePrice.cover[slot.TimeRange.Start + '_' + coverVariant.CoverTypeId] = coverTypeItemFromParty ? Number(coverVariant.initialValue) + (coverTypeItemFromParty ? (coverTypeItemFromParty.NegotiatedAmount / coverTypeItemFromParty.covers) : 0) : '-';
        // if(fromRatePlan){
        //   dataForSlotRatePlanCalc.prevSalePrice.cover[slot.TimeRange.Start+'_'+coverVariant.CoverTypeId]= (coverVariant.Price)+ (coverVariant.NegotiatedAmount || 0)
        // }
        coverVariant.prevSalePrice = dataForSlotRatePlanCalc.prevSalePrice.cover[slot.TimeRange.Start + '_' + coverVariant.CoverTypeId];

        if (dataForSlotRatePlanCalc.ignoreNegotiation && !dataForSlotRatePlanCalc.applyNegotiationClicked) {
          coverVariant.coverTypeValue = this.currencyDecimalFormater.transform(coverVariant.calculatesBaseVariant);
        } else {
          coverVariant.coverTypeValue = this.currencyDecimalFormater.transform(((coverVariant.NegotiatedAmount || 0) / coverVariant.covers) + coverVariant.calculatesBaseVariant);
        }

        // coverVariant.coverTypeValue =  this.currencyDecimalFormater.transform(((coverVariant.Price + (coverVariant.NegotiatedAmount || 0)) / coverVariant.covers));

        coverVariant.negotiatedBaseRate = coverVariant.coverTypeValue;

        coverVariant.itemTotal = coverVariant.negotiatedBaseRate * coverVariant.covers;
        coverVariant.originalItemValue = coverVariant.calculatesBaseVariant * coverVariant.covers;
        coverVariant.itemDiscount = coverVariant.originalItemValue - coverVariant.itemTotal;

        slot.totalSessionRetailCharge += coverVariant.originalItemValue;
        // slot.totalSessionCharge += (coverVariant.coverTypeValue * coverVariant.covers)
        slot.slotItemDiscount += coverVariant.itemDiscount;
        // this.negotiatedDiscount += coverVariant.itemDiscount;

        /*[adjustedMinRate] calculating only for One cover*/
        if (coverVariant.MinimumCoverTypeId && coverVariant.CoverTypeId != coverVariant.MinimumCoverTypeId) {
          let minCoverTypeData = slot.RatePlanVariants?.GuestTypeVariant.find(guestTypeVariant => guestTypeVariant.CoverTypeId === coverVariant.MinimumCoverTypeId);
          if (minCoverTypeData && minCoverTypeData.covers < 1) {
            coverVariant.adjustedMinRate += minCoverTypeData.OriginalVariant - coverVariant.OriginalVariant;
            adjustedMinRate += coverVariant.adjustedMinRate;
          }
        }

        slot.activitiesAmount += coverVariant.originalItemValue;

        return coverVariant;
      }).filter(coverVariant => coverVariant.covers > 0) : [];
      // if(slotItem && slotItem.RatePlanVariants && slotItem.RatePlanVariants.GuestTypeVariant?.length){
      //   slotItem.RatePlanVariants?.GuestTypeVariant.forEach(currGuestTypeVariantItem => {

      //     if(currGuestTypeVariantItem.MinimumCoverTypeId && currGuestTypeVariantItem.CoverTypeId != currGuestTypeVariantItem.MinimumCoverTypeId){
      //         let minCoverTypeData = slotItem.RatePlanVariants?.GuestTypeVariant.find(guestTypeVariant => guestTypeVariant.CoverTypeId === currGuestTypeVariantItem.MinimumCoverTypeId);
      //         if(minCoverTypeData && minCoverTypeData.covers < 1){
      //           this.adjustedMinRate += minCoverTypeData.OriginalVariant - currGuestTypeVariantItem.Variant;
      //         }
      //     }

      //   });
      // }


      slot.bookingTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.BookingTypeVariant ? slot.RatePlanVariants.BookingTypeVariant.map(bookingVariant => {
        bookingVariant.adjustedMinRate = 0;
        if (slotItem && slotItem.BookingTypeVariant?.length) {
          slotItem.BookingTypeVariant.forEach(element => {
            slot.BookingTypeVariant.filter(guest => guest.BookingTypeId == element.BookingTypeId).map(data => {
              data.NegotiatedAmount = element.NegotiatedAmount || 0;
            })
          });
        }
        bookingVariant.BookingTypeName = bookingTypes.find(bookingType => bookingType.Id == bookingVariant.BookingTypeId).Name;

        if (slot.RatePlanVariants && (!slot.RatePlanVariants.GuestTypeVariant || slot.RatePlanVariants.GuestTypeVariant.length == 0)) {
          bookingVariant.Price =  bookingVariant.Variant;

          bookingVariant.calculatesBaseVariant = bookingVariant.OriginalVariant;
        } else {
          bookingVariant.Price = bookingVariant.Variant;
          bookingVariant.calculatesBaseVariant = bookingVariant.OriginalVariant;
        }

        //  bookingVariant.calculatesBaseVariant = (slot.RatePlanVariants.OriginalBaseRate) 
        //  + (bookingVariant.OriginalVariant ) ;

        bookingVariant.initialValue = this.currencyDecimalFormater.transform(((bookingVariant.calculatesBaseVariant)));

        let negotiatePricePerBookingType = bookingVariant.NegotiatedAmount ? bookingVariant.NegotiatedAmount / bookingVariant.bookingTypes : 0;

        if (data && data.BookingTypeQuantities && bookingVariant.NegotiatedAmount && bookingVariant.bookingTypes) {
          let prevBookingTypeData = data.BookingTypeQuantities.find(prevBookingType => prevBookingType.BookingTypeId === bookingVariant.BookingTypeId);
          if (prevBookingTypeData && prevBookingTypeData.BookingTypes) {
            negotiatePricePerBookingType = bookingVariant.NegotiatedAmount / prevBookingTypeData.BookingTypes;
          }
        }

        if (!dataForSlotRatePlanCalc.applyNegotiationClicked) {
          dataForSlotRatePlanCalc.prevSalePrice.bookingType[slot.TimeRange.Start + '_' + bookingVariant.BookingTypeId] = data?.Id ? this.currencyDecimalFormater.transform(((bookingVariant.calculatesBaseVariant + negotiatePricePerBookingType))) : '-';
        }
        let bookingTypeFromParty = partyData ? partyData.RatePlanVariants?.BookingTypeVariant.find(booking => bookingVariant.BookingTypeId == booking.BookingTypeId) : null;
        dataForSlotRatePlanCalc.prevSalePrice.bookingType[slot.TimeRange.Start + '_' + bookingVariant.BookingTypeId] = bookingTypeFromParty ? Number(bookingVariant.initialValue) + (bookingTypeFromParty ? (bookingTypeFromParty.NegotiatedAmount / bookingTypeFromParty.bookingTypes) : 0) : '-';
        // if(fromRatePlan){
        //   dataForSlotRatePlanCalc.prevSalePrice.bookingType[slot.TimeRange.Start+'_'+bookingVariant.BookingTypeId]= (bookingVariant.Price)+ (bookingVariant.NegotiatedAmount || 0)
        // }
        bookingVariant.prevSalePrice = dataForSlotRatePlanCalc.prevSalePrice.bookingType[slot.TimeRange.Start + '_' + bookingVariant.BookingTypeId];

        if (dataForSlotRatePlanCalc.ignoreNegotiation && !dataForSlotRatePlanCalc.applyNegotiationClicked) {
          bookingVariant.bookingTypeValue = this.currencyDecimalFormater.transform(bookingVariant.calculatesBaseVariant);
        } else {
          bookingVariant.bookingTypeValue = this.currencyDecimalFormater.transform(((bookingVariant.NegotiatedAmount || 0) / bookingVariant.bookingTypes) + bookingVariant.calculatesBaseVariant);
        }

        // bookingVariant.bookingTypeValue =  this.currencyDecimalFormater.transform(((bookingVariant.Price + (bookingVariant.NegotiatedAmount || 0)) / bookingVariant.bookingTypes));

        bookingVariant.negotiatedBaseRate = bookingVariant.bookingTypeValue;

        bookingVariant.itemTotal = bookingVariant.negotiatedBaseRate * bookingVariant.bookingTypes;
        bookingVariant.originalItemValue = bookingVariant.calculatesBaseVariant * bookingVariant.bookingTypes;
        bookingVariant.itemDiscount = bookingVariant.originalItemValue - bookingVariant.itemTotal;

        slot.totalSessionRetailCharge += bookingVariant.originalItemValue;
        // slot.totalSessionCharge += (bookingVariant.bookingTypeValue * bookingVariant.bookingTypes)
        slot.slotItemDiscount += bookingVariant.itemDiscount;
        // this.negotiatedDiscount += bookingVariant.itemDiscount;



        slot.activitiesAmount += bookingVariant.originalItemValue;

        return bookingVariant;
      }).filter(bookingVariant => bookingVariant.bookingTypes > 0) : [];

      if (slot.RatePlanVariants ) {
        slot.sessionBaseVariant = (slot.RatePlanVariants.OriginalBaseRate || 0);

        if (dataForSlotRatePlanCalc.ignoreNegotiation && !dataForSlotRatePlanCalc.applyNegotiationClicked) {
          dataForSlotRatePlanCalc.prevSalePrice.base[slot.TimeRange.Start + '_'] = ((slot.sessionBaseVariant * data.Size) + (slot.RatePlanVariants.TotalNegotiation || 0)) / data.Size;
          slot.sessionCharge = slot.sessionBaseVariant;
        } else {
          slot.sessionCharge = slot.RatePlanVariants.TotalBaseRate + (slot.RatePlanVariants.NegotiatedAmount || 0);
        }
        slot.sessionCharge = slot.RatePlanVariants.TotalBaseRate + (slot.RatePlanVariants.NegotiatedAmount || 0);
        dataForSlotRatePlanCalc.prevSalePrice.base[slot.TimeRange.Start + '_'] = partyData?.RatePlanVariants ? (slot.sessionBaseVariant + (partyData ? partyData.RatePlanVariants.NegotiatedAmount : 0)) : '-';
        // if(fromRatePlan){
        //   dataForSlotRatePlanCalc.prevSalePrice.base[slot.TimeRange.Start+'_']= slot.sessionBaseVariant + slot.RatePlanVariants.NegotiatedAmount
        // }
        slot.basePrevSalePrice = dataForSlotRatePlanCalc.prevSalePrice.base[slot.TimeRange.Start + '_'] || '-';
        slot.sessionChargeinitialValue = slot.sessionBaseVariant;

        slot.totalSessionRetailCharge += slot.sessionChargeinitialValue;
        // slot.totalSessionCharge += (slot.sessionCharge * coversQty);
        // this.negotiatedDiscount += ((slot.sessionBaseVariant - slot.sessionCharge) * coversQty);

        slot.activitiesAmount += slot.sessionChargeinitialValue;

      }

      slot.totaladdonCharges = 0.00;
      slot.AddonAttemptDetails = slot.AddonAttemptDetails ? slot.AddonAttemptDetails.map(addOnattempt => {
        if (slotItem && slotItem.AddonAttemptDetails.length && firstCall) {
          slotItem.AddonAttemptDetails.forEach(element => {
            slot.AddonAttemptDetails.filter(addOn => addOn.AddonId == element.AddonId).map(data => {
              data.NegotiatedAmount = element.NegotiatedAmount || 0;
            })
          });
        }
        addOnattempt.prevSalePrice = '-';
        if (data && data?.BookingContactAddonItems) {
          let addonPrevQuantity = data?.BookingContactAddonItems ? data.BookingContactAddonItems.find(({ AddonId }) => AddonId == addOnattempt.AddonId) : null;
          let addonPrevAmount = data?.BookingContactAddonItems && data?.bookingRatePlan?.RatePlanTotalNegotiation?.RatePlanCalculationInfoNegotiation.length ? data?.bookingRatePlan?.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation[0].AddonAttemptDetails.find(({ AddonId }) => AddonId === addOnattempt.AddonId) : null;

          if (addonPrevQuantity && addonPrevAmount) {
            addOnattempt.prevSalePrice = this.currencyDecimalFormater.transform(((addonPrevAmount.TotalAmount || 0) + (addonPrevAmount.NegotiatedAmount || 0)) / addonPrevQuantity.AddonCount);
          } else {
            addOnattempt.prevSalePrice = '-';
          }
        }

        addOnattempt.perItemValue = this.currencyDecimalFormater.transform(((addOnattempt.TotalAmount + (addOnattempt.NegotiatedAmount || 0)) / addOnattempt.Quantity));
        if (dataForSlotRatePlanCalc.ignoreNegotiation && !dataForSlotRatePlanCalc.applyNegotiationClicked) {
          addOnattempt.perItemValue = this.currencyDecimalFormater.transform(((addOnattempt.TotalAmount) / addOnattempt.Quantity));
          addOnattempt.perItemValue * addOnattempt.Quantity;
        }

        addOnattempt.initialValue = this.currencyDecimalFormater.transform(((addOnattempt.TotalAmount) / addOnattempt.Quantity));
        let addOnData = partyData ? partyData?.AddonAttemptDetails.find(addOn => addOn.AddonId == addOnattempt.AddonId) : null;
        addOnattempt.prevSalePrice = addOnData ? addOnattempt.PricePerItem + (addOnData ? (addOnData.NegotiatedAmount / addOnData.Quantity) : 0) : '-';
        // if(fromRatePlan){
        //   addOnattempt.prevSalePrice = addOnattempt.perItemValue + addOnattempt.NegotiatedAmount
        // }
        addOnattempt.negotiatedBaseRate = addOnattempt.perItemValue;

        addOnattempt.itemTotal = addOnattempt.negotiatedBaseRate * addOnattempt.Quantity;
        addOnattempt.originalItemValue = addOnattempt.initialValue * addOnattempt.Quantity;
        addOnattempt.itemDiscount = addOnattempt.originalItemValue - addOnattempt.itemTotal;

        slot.totaladdonCharges += addOnattempt.originalItemValue;
        // slot.totalSessionCharge += addOnattempt.itemTotal;
        slot.slotItemDiscount += addOnattempt.itemDiscount;
        additionalCharge += (addOnattempt.AdditionalAmount || 0);
        // this.negotiatedDiscount += addOnattempt.itemDiscount;             
        return addOnattempt;
      }) : []

      //this.ratePlan.RatePlanVariants.TotalBaseRate * coversQty
      // slot.totalBaseRate = slot.RatePlanVariants.OriginalBaseRate * coversQty;
      //  slot.totalAddOnAmount = slot.TotalAddOnAmount + slot.OverallAddonTotalNegotiatedAmount;
      if (slot.RatePlanVariants) {
        slot.RatePlanVariants.locationAmount = _.sumBy(slot.RatePlanVariants.LocationVariant , 'Variant') + (slot.RatePlanVariants.LocationNegotiatedAmount || 0);
        slot.RatePlanVariants.instructorAmount = (slot.RatePlanVariants.InstructorVariant) + (slot.RatePlanVariants.InstructorNegotiatedAmount || 0);
      }
      additionalCharge += (slot.AdditionalRateAmount || 0);
      slot.membershipAmount = (slot.MemberShipVariant || 0) + (slot.NegotiatedMemberShipVariant || 0);

      slot.membershipAmount = (slot.MemberShipVariant || 0) + (slot.NegotiatedMemberShipVariant || 0);
      if (dataForSlotRatePlanCalc.ignoreNegotiation && !dataForSlotRatePlanCalc.applyNegotiationClicked) {
        dataForSlotRatePlanCalc.prevSalePrice.location[slot.TimeRange.Start + '_'] = data?.Id && slot.RatePlanVariants ? this.currencyDecimalFormater.transform((((slot.RatePlanVariants?.OriginalLocationVariant ) + slot.RatePlanVariants?.LocationNegotiatedAmount))) : '-';
        dataForSlotRatePlanCalc.prevSalePrice.instructor[slot.TimeRange.Start + '_'] = data?.Id && slot.RatePlanVariants ? this.currencyDecimalFormater.transform((((slot.RatePlanVariants?.OriginalInstructorVariant) + slot.RatePlanVariants?.InstructorNegotiatedAmount))) : '-';
        if (slot.RatePlanVariants) {
          slot.RatePlanVariants.locationAmount = _.sumBy(slot.RatePlanVariants.LocationVariant , 'Variant');
          slot.RatePlanVariants.instructorAmount = slot.RatePlanVariants.InstructorVariant;
        }

        if (data && data.RatePlanTotalNegotiation && data.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation) {
          let oldSlotData = data.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation.find(prevSlotData => prevSlotData.TimeRange.Start === slot.TimeRange.Start && prevSlotData.TimeRange.End === slot.TimeRange.End);
          dataForSlotRatePlanCalc.prevSalePrice.membership[slot.TimeRange.Start + '_'] = oldSlotData ? oldSlotData.NegotiatedMemberShipVariant + oldSlotData.MemberShipVariant : '';
        }
        // this.prevSalePrice.membership[slot.TimeRange.Start+'_'] = this.data?.Id ?  NegotiatedMemberShipVariant : '-'
        slot.membershipAmount = (slot.MemberShipVariant || 0);
      }
      dataForSlotRatePlanCalc.prevSalePrice.location[slot.TimeRange.Start + '_'] = partyData?.RatePlanVariants?.LocationNegotiatedAmount ? ((slot?.RatePlanVariants.OriginalLocationVariant *( this.partyService.ratePlanForBooking?.RatePlanCalculatorType == RatePlanCalculator.Duration ? 1 : data?.Size)) + (partyData ? partyData.RatePlanVariants.LocationNegotiatedAmount : 0)) : '-';
      dataForSlotRatePlanCalc.prevSalePrice.instructor[slot.TimeRange.Start + '_'] = partyData?.RatePlanVariants?.InstructorNegotiatedAmount ? ((slot?.RatePlanVariants.OriginalInstructorVariant *( this.partyService.ratePlanForBooking?.RatePlanCalculatorType == RatePlanCalculator.Duration ? 1 : data?.Size)) + (partyData ? partyData.RatePlanVariants.InstructorNegotiatedAmount : 0)) : '-';
      // if(fromRatePlan){
      //   dataForSlotRatePlanCalc.prevSalePrice.location[slot.TimeRange.Start+'_'] =  slot?.RatePlanVariants.OriginalLocationVariant + slot.RatePlanVariants.LocationNegotiatedAmount  ;
      //   dataForSlotRatePlanCalc.prevSalePrice.instructor[slot.TimeRange.Start+'_'] =slot?.RatePlanVariants.OriginalInstructorVariant + slot.RatePlanVariants.InstructorNegotiatedAmount;

      // }
      slot.prevLocationSalePrice = dataForSlotRatePlanCalc.prevSalePrice.location[slot.TimeRange.Start + '_'] || '-';
      slot.prevInstructorSalePrice = dataForSlotRatePlanCalc.prevSalePrice.instructor[slot.TimeRange.Start + '_'] || '-';
      slot.prevMembershipSalePrice = dataForSlotRatePlanCalc.prevSalePrice.membership[slot.TimeRange.Start + '_'] || '-';
      // slot.totalSessionCharge +=  slot.RatePlanVariants.LocationVariant + slot.RatePlanVariants.InstructorVariant; 
      slot.totalSessionRetailCharge += slot.totaladdonCharges;
      slot.activitiesAmount += slot.totaladdonCharges
      if (slot.RatePlanVariants) {
        let locationAndInstructorVariant = 0;
        locationAndInstructorVariant = ((_.sumBy(slot.RatePlanVariants.LocationVariant , 'Variant') || 0) + (slot.RatePlanVariants.InstructorVariant || 0))
        slot.totalSessionRetailCharge += (locationAndInstructorVariant);
        slot.activitiesAmount += locationAndInstructorVariant;
      }
      slot.activitiesAmount += (ratePlan?.MinRateAdjustment?.MinRateAdjustmentAmount || 0);
      activitiesAmount += slot.activitiesAmount;
      return slot;
    });

    return { 'ratePlan': ratePlan.RatePlanCalculations, 'activitiesAmount': activitiesAmount, 'negotiatedDiscount': negotiatedDiscount, 'adjustedMinRate': (ratePlan?.MinRateAdjustment?.MinRateAdjustmentAmount || 0), 'additionalCharge': additionalCharge };
  }

  isBookingDataUpdated(request, data) {
    let isGuestTypeChanged = false;
    let isBookingTypeChanged = false;
    let isSessionChanged = false;
    let isAddonChanged = false;
    let bookingSizeChanged = false;

    if (!data) {
      return false;
    }

    if (!data?.CoverTypeQuantities || !data?.CoverTypeQuantities) {
      return false;
    }

    if (request.GuestTypes?.length) {
      isGuestTypeChanged = request.GuestTypes.filter((reqGuestType) => {
        let savedGuestType = data.CoverTypeQuantities.find(srcGuestType => srcGuestType.CoverTypeId === reqGuestType.CoverTypeId);
        return savedGuestType && savedGuestType.Covers === reqGuestType.Covers;
      }).length !== data.CoverTypeQuantities.length;
    }

    if (request.BookingTypes?.length) {
      isBookingTypeChanged = request.BookingTypes.filter((reqBookingType) => {
        let savedBookingType = data.BookingTypeQuantities.find(srcGuestType => srcGuestType.BookingTypeId === reqBookingType.BookingTypeId);
        return savedBookingType && savedBookingType.BookingTypes === reqBookingType.BookingTypes;
      }).length !== data.BookingTypeQuantities.length;
    }

    if (!request.GuestTypes?.length && !request.BookingTypes?.length) {
      bookingSizeChanged = data.Size != +request.Size;
    }
    if (request.BookingBehavior == BookingBehavior.ClassOrSession) {
      if (data.BookedSessions) {
        isSessionChanged = !(data.BookedSessions.length === request.SessionSlot.Sessions[0].SessionIds.length
          && data.BookedSessions.filter(bookedSession =>
            request.SessionSlot.Sessions[0].SessionIds.includes(bookedSession.ActivitySessionId)).length)
      } else if (data.SessionGroupId && request.SessionSlot) {
        isSessionChanged = data.SessionGroupId !== request.SessionSlot.SessionGroupId;
      }
    }
    else if (request.BookingBehavior == BookingBehavior.OpenBooking || request.BookingBehavior == BookingBehavior.PrivateLesson) {

      if (data.SeatingTime) {
        let durationOfActivity = moment.duration(moment(data.DepartureTime, 'YYYY/MM/DD HH:mm')
          .diff(moment(data.SeatingTime, 'YYYY/MM/DD HH:mm'))).asMinutes();
        isSessionChanged = (data.SeatingTime != request.Slots[0].LocalTime) || (durationOfActivity != request.Slots[0].Duration)
      } else if (data.Slots && data.Slots[0]) {
        isSessionChanged = (data.Slots[0].Time != request.Slots[0].LocalTime) || (data.Slots[0].DurationInMinutes != request.Slots[0].Duration)
      } else {
        isSessionChanged = false;
      }

    }

    isAddonChanged = !!data.BookingContactAddonItems && (!(request.SelectedAddOns.filter((reqAddon) => {
      let savedAddon = data.BookingContactAddonItems.find(srcAddon => srcAddon.AddonId === reqAddon.AddonId);
      return savedAddon && savedAddon.AddonCount === reqAddon.Quantity
    }).length === data.BookingContactAddonItems.length) || request.SelectedAddOns.length !== data.BookingContactAddonItems.length);

    /**Pending */
    //isGroupClassUpdated

    return isGuestTypeChanged || isSessionChanged || isAddonChanged || bookingSizeChanged;
  }

  negotiationReason(data, bookingObj?) {
    if (bookingObj?.Comments) {
      return bookingObj?.Comments;
    }
    else if (data && data?.Comments && Utilities.tryParse(data?.Comments)) {
      return JSON.parse(data?.Comments)?.find(comment => comment.commentType == CommentType.Negotiation)?.Comment || '';
    }
    return '';
  }

  getMinDateBasedonConfig(activity, restaurantDate) {
    if (this.checkBookPastDaysReservationsConfig()) {
      restaurantDate = new Date(restaurantDate);
      if (activity) {
        let modifiedRestaurantDate = restaurantDate.setDate(restaurantDate.getDate() - this.cs.settings.value.PropertySetting[0].MinDaysToBookPastReservations);
        if (new Date(modifiedRestaurantDate) >= new Date(activity.StartDate))
          restaurantDate = new Date(modifiedRestaurantDate);
        //restaurantDate.setDate(restaurantDate.getDate() - 5);
        else {
          restaurantDate = new Date(activity.StartDate);
        }
      }
      else {
        restaurantDate.setDate(restaurantDate.getDate() - this.cs.settings.value.PropertySetting[0].MinDaysToBookPastReservations);
      }
    }
    return restaurantDate;
  }

  checkBookPastDaysReservationsConfig() {
    if (this.checkForManagerLogin()) {
      return this.cs.settings.value.PropertySetting[0].BookPastDaysReservations
    }
    else {
      return this.cs.settings.value.PropertySetting[0].BookPastDaysReservations && this.appService.hasPermission(RolesAndPermissionsType.BookOrEditPastReservations)
    }
    //  return this.cs.settings.value.PropertySetting[0].BookPastDaysReservations && ;
    //  return true;
  }

  scrollTop(id): void {
    let element = document.getElementById(id);
    if (element !== null) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
 }

  checkForManagerLogin() {

    const restaurantsAvilable = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).RestaurantsAvailableForLogin;
    const hostRole = restaurantsAvilable.find(restaurant => restaurant.Id == Utilities.RestaurantId())
    return hostRole.HostRoleType && hostRole.HostRoleType.Name === RoleType[RoleType.Manager]

  }

  checkEditCheckedInReservationsConfig() {
    // return this.cs.settings.value.PropertySetting[0].EditCheckedInReservations;
    if (this.checkForManagerLogin()) {
      return this.cs.settings.value.PropertySetting[0].EditCheckedInReservations
    }
    else {
      return this.cs.settings.value.PropertySetting[0].EditCheckedInReservations && this.appService.hasPermission(RolesAndPermissionsType.EditCheckedInReservations)
    }
    // return true;
  }

  applyNegotiationAlert() {
    const popUpMessage = [{
      confirmationMessage: this.translateService.instant('applyNegotiatedValues'),
      dialogTitle: this.translateService.instant('alert'),
      showAlert: true
    }];
    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'static', popUpMessage);
    this.convertDialog = this.openCustomPopup(componentInfo, ComponentTypes.standby,
      '450px', '350px', false, popUpMessage[0].dialogTitle, this.translateService.instant('ok'), '', true);
  }

  openPackage(parties) {
    this.currentPackageInfo = parties.CommonPackageInfo;
  }
  getBookingTypeTotal(bookingTypes): number {
    var bookingTypeTotal = 0;
    if (bookingTypes) {
      Object.keys(bookingTypes).forEach((key) => {
        bookingTypeTotal += Number(bookingTypes[key]) > 0 ? Number(bookingTypes[key]) : 0;
      })
    }
    return bookingTypeTotal;
  }
  getCoverTypeTotal(covers): number {
    var coverTypeTotal = 0;
    if (covers) {
      Object.keys(covers).forEach((key) => {
        coverTypeTotal += Number(covers[key]) > 0 ? Number(covers[key]) : 0;
      })
    }

    return coverTypeTotal;
  }

  hasEmptyFields(fields): boolean {
    if (fields) {
      let _fieldsKeys = Object.keys(fields);

      for (let fIndex = 0; fIndex < _fieldsKeys.length; fIndex++) {
        const fieldKey = _fieldsKeys[fIndex];
        if (fields[fieldKey] === null) {
          return true;
        }
      }
      return false;
    }
    return false;
  }
  getSalesContractList(data) {

    let salesContacts = this.cs.settings.value.Hosts.map(host => {
      return {
        id: host.Id,
        value: host.Name,
        phoneNumber: this.cs.settings.value.Servers && this.cs.settings.value.Servers?.some(server => server.HostId == host.Id) ? this.cs.settings.value.Servers?.find(server => server.HostId == host.Id).PhoneNumber : '',
        email: this.cs.settings.value.Servers && this.cs.settings.value.Servers?.some(server => server.HostId == host.Id) ? this.cs.settings.value.Servers?.find(server => server.HostId == host.Id).EmailAddress : '',
        firstName: this.cs.settings.value.Servers && this.cs.settings.value.Servers?.some(server => server.HostId == host.Id) ? this.cs.settings.value.Servers?.find(server => server.HostId == host.Id).Name : '',
        lastName: this.cs.settings.value.Servers && this.cs.settings.value.Servers?.some(server => server.HostId == host.Id) ? this.cs.settings.value.Servers?.find(server => server.HostId == host.Id).LastName : '',
      }
    });

    let multiChipValues = salesContacts.filter(o => data?.SalesContactIds?.some((id) => o.id === id));

    return { contacts: salesContacts, multiChip: multiChipValues }
  }

  PrintWristBand(attendee) {
    this.subscriptions.add(this.partyService.getWristBandInfo(attendee.Id).subscribe(data => {
      if (data) {
            //this.partyService.printWristBand(attendee,data.Payload);
      }
    }
    ));
  }

  getCartGroupName({FirstName, LastName}): string{
    return (FirstName || LastName || '') + Utilities.getRandomString();
  }

  updateShiftName(shifts){
    shifts?.forEach(shiftItem => {
      shiftItem.Name = this.cs.settings.value.Shifts.find(availbleShift => availbleShift.Id == shiftItem.Id)?.Name || shiftItem.Name;
    });
   
  }

  getShiftName(shiftId){
    return this.cs.settings.value.Shifts.find(availbleShift => availbleShift.Id == shiftId)?.Name || null;
  }


  getLocaleCode(): string {
    let defaultCode = DEFAULT_LANGUAGE_CODE;
    let languageCode = sessionStorage.getItem('languageCode') || DEFAULT_LANGUAGE_CODE;
    let allAvailableLanguages = JSON.parse(sessionStorage.getItem('languages')) || [];
    if(languageCode) {
      return languageCode;
    }
    return defaultCode;
  }

  blockStaff(staffBlockObj: StaffBlockDTO,staffSchedules: any) {
    const popUpMessage = [{
      dialogTitle: this.translateService.instant('Block hours for'),
      showAlert: false,
      staffBlockData: staffBlockObj,
      staffSchedules: staffSchedules
    }];
    const componentInfo = Utilities.setComponentDetails(BlockStaffComponent, 'medium', '', popUpMessage,popUpMessage[0].dialogTitle);
    let dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.BlockStaff,
      '700px', 'auto', false, popUpMessage[0].dialogTitle, this.translateService.instant('save'), this.translateService.instant('Cancel'), true);
    dialogRef.afterClosed().subscribe((result: { action: "cancelled" | "confirmed", reqObj }) => {
      if (result?.action === "confirmed") {
        this.subscriptions.add(this.serverService.createCustomBlockorBreakHours(result.reqObj).subscribe(data => {
          this.serverService.isUserAction$.next(true);
        }));
      }
    });
  }

  deleteBlockStaffConfirmation(blockStaffData?: StaffBlockDTO) {
    let blockTimeRange = moment(blockStaffData.EffectiveRange.Start).format(this.cs.settings.value.General.DateFormat + ' hh:mm A') + ' - ' + moment(blockStaffData.EffectiveRange.End).format(this.cs.settings.value.General.DateFormat + ' hh:mm A');;
    const popUpMessage = [{
      dialogTitle: this.translateService.instant('deleteTitle'),
      confirmationMessage: `${this.translateService.instant('Are you sure you want to delete this hours and make the available during this time?', {time: blockTimeRange})}`,
      showAlert: false,
    }];
    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', '', popUpMessage,popUpMessage[0].dialogTitle);
    let dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.BlockStaff,
      '450px', 'auto', false, popUpMessage[0].dialogTitle, this.translateService.instant('deleteText'), this.translateService.instant('Cancel'), true);
    dialogRef.afterClosed().subscribe((data) => {
      if(data == ComponentTypes.BlockStaff) {
        this.subscriptions.add(this.serverService.removeBlockorBreakHours(blockStaffData.BreakHourId).subscribe(data => {
          this.serverService.isUserAction$.next(true);
        }));
      }
    })
  }

}
  
