import { Injectable, OnDestroy } from "@angular/core";
import { CartUtilities } from "@app/common/components/menu/vcart/cart.utilities";
import { CartPayload, CartWindowMessageType, RequestObject } from "@app/common/components/menu/vcart/vcart.modal";
import { Utilities } from "../utilities/utilities";
import { urlConfig, urlWithRetailHeaders } from '@constants/url-config';
import { BookingBehavior, ClassType , PaymentMethod, VCart_Products} from "../constants/commonenums";
import { CommonUtilities } from "@app/common/shared/shared/utilities/common-utilities";
import moment from "moment";
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from "@app/core/services/cache.service";
import { LoginResultDTO } from "../models/LoginResultDTO";

@Injectable({
    providedIn: 'root'
})
export class VCartCommonService implements OnDestroy {
    constructor(private cu: CartUtilities, private utils: CommonUtilities, private ts: TranslateService, private cs: CacheService) { }
    restaurantId: number;
    createCart(request: any, isEdit: boolean = false): void {
        this.restaurantId = Utilities.RestaurantId();
        // let retailHeaderRequired = this.isRetailHeaderRequired(req.url);
        let isActivity = request.CartItemDetail?.BookingBehavior !== null && request.CartItemDetail?.BookingBehavior !== undefined;
        let contact = request.CartItemDetail.Contact
        let apiData = this.formAPIData(request);
        let cartPayload: CartPayload = {
            productId:  isActivity ? VCart_Products.Activity : VCart_Products.Dining,
            userId: Number(this.utils.GetUserInfo('userId')),
            activityType: isActivity ? 'Activity' : 'Dining',
            cardInfos: null,
            comments: '',
            depositAmount: request.CartItemDetail?.PaymentMethod == PaymentMethod.SkipPayment ?  0 : ( request.CartItemDetail?.OverallAmount || 0),
            description: this.getSessionName(request),
            startDate: this.getStartTime(request) ,
            endDate: moment(request.CartItemDetail?.EndDate).format('YYYY-MM-DDThh:mm:ss'),
            guestInfos: [{
                firstName: contact.FirstName,
                lastName: contact.LastName,
                guestId: contact?.guestProfileServiceId || '00000000-0000-0000-0000-000000000000',
                email: [{ value: contact?.EmailAddress ? contact.EmailAddress : '', isPrimary: false, isPrivate: false, type: 0 }],
                phone: [{ value: contact?.PhoneNumber ? contact.PhoneNumber : '', isPrimary: false, isPrivate: false, type: 0 }],
                platformGuestUuid: contact?.CommonGuestProfileUUID || '00000000-0000-0000-0000-000000000000',
            }],
            bookAPI: apiData[0],
            holdAPI: apiData[1],
            releaseAPI: apiData[2],
            price: request.CartItemDetail?.OverallAmount ? request.CartItemDetail?.OverallAmount : 0,
            propertyId: Number(this.utils.GetPropertyInfo('PropertyId')),
            tenantId: Number(this.utils.GetPropertyInfo('TenantId')),
            productCode: 'ACTIVITY',
            uiObject: isEdit ? JSON.parse(this.cs.VCartUpdatedData?.cartPayload?.bookAPI?.payload) : null,
        };

        if (isEdit) {
            this.cu.pushMessageToParentWindow(CartWindowMessageType.UpdateCart,
                {
                    cartDetailId: this.cs.VCartUpdatedData?.id,
                    cartItineraryId: this.cs.VCartUpdatedData.cartItineraryId,
                    cartPayload
                });
        } else {
            this.cu.pushMessageToParentWindow(CartWindowMessageType.AddToCart, cartPayload);
        }
    }

    formAPIData(request) {
        let lockSlot;
        if (request.CartItemDetail.BookingBehavior == BookingBehavior.OpenBooking) {
            lockSlot = Object.values(request.SlotLockResult ? request.SlotLockResult : request.CartItemDetail?.SlotLockResultDTO)?.filter(slotLock => slotLock['SlotLockIdDTO']).map(slotLock => slotLock['SlotLockIdDTO'])[0];

        } else if (request.CartItemDetail.BookingBehavior == BookingBehavior.ClassOrSession) {
            lockSlot = request.SlotLockResult?.SlotLockIdDTO;
        }
        let header = [...this.getVCartHeaders(), ...this.getActivityHeder(this.restaurantId, true)];
        let bookAPI: any = {
            path: `${urlConfig.VCartBookCartForMultipleProperties}?ignoreBookingValidation=${true}`,
            headers: header,
            payload: JSON.stringify({ BookingInputs: this.getPayLoadData(request, lockSlot) }),
            verb: 'POST',
        }
        let holdAPI: any = {
            path: `${urlConfig.VCartHold}?propertyId=${this.restaurantId}`,
            headers: header,
            payload: JSON.stringify([request.Id]),
            verb: 'POST',
        }
        let releaseAPI: any = {
            path: `${urlConfig.VCartDeleteCartItems}?propertyId=${this.restaurantId}`,
            headers: header,
            payload: JSON.stringify([{ LockId: lockSlot ? lockSlot.Id : null, PropertyId: this.restaurantId, BookingBehavior: request.CartItemDetail.BookingBehavior, CartId: request.Id }]),
            verb: 'POST',
        }

        return [bookAPI, holdAPI, releaseAPI]
    }
    getStartTime(request) {
        let time;
        let activity;
        if (request.CartItemDetail.SpecialMealId) {
            activity = this.cs.specialMealListForMerchant.find(meal => meal.Id == request.CartItemDetail.SpecialMealId)
        }
        if (request.CartItemDetail.BookingBehavior == BookingBehavior.OpenBooking || request.CartItemDetail.BookingBehavior == BookingBehavior.PrivateLesson) {
            time = request.CartItemDetail?.Slots[0]?.Time;
        } else if (request.CartItemDetail.BookingBehavior == BookingBehavior.ClassOrSession) {
            time = activity.ClassType == ClassType.Session ? request.CartItemDetail.Sessions?.Sessions[0]?.sessions[0].Start : time = activity.StartDate;
        } else {
            time = request.CartItemDetail?.Slot?.Time;
        }
        return moment(time).format('YYYY-MM-DDTHH:mm');
    }
    getSessionName(request) {
        let description;
        let propertyName = this.cs.settings.value.General?.RestaurantName;
        let locationName;
        let activity;
        if (request.CartItemDetail.SpecialMealId) {
            activity = this.cs.specialMealListForMerchant.find(meal => meal.Id == request.CartItemDetail.SpecialMealId)
        }
        if (request.CartItemDetail.TableIds?.length) {
            locationName = this.cs.locationListForMerchant.find(location => location.id == request.CartItemDetail.TableIds[0])?.value + ' - ';
        }
        if (request.CartItemDetail.BookingBehavior == BookingBehavior.OpenBooking) {
            description = propertyName + ' - ' + (locationName ? locationName : '') + moment(request.CartItemDetail?.Slots[0]?.Time).format('lll');
        } else if (request.CartItemDetail.BookingBehavior == BookingBehavior.ClassOrSession) {
            if (activity.ClassType == ClassType.Session) {
                description = propertyName + ' - ' + activity?.Name + ' - ' + moment(request.CartItemDetail.Sessions?.Sessions[0]?.sessions[0].Start).format('lll');
            } else if (activity.ClassType == ClassType.Class) {
                let time = activity.ActivitySessions.find(session =>  session.SessionGroupId == request.CartItemDetail.Sessions.SessionGroupId)?.StartTime;
                description = propertyName + ' - ' + activity?.Name + ' - ' + moment(activity.StartDate).format('ll') + ' ' +  moment(request.CartItemDetail.Sessions?.Sessions[0]?.sessions[0].Start).format('LT');
            }
        } else if (request.CartItemDetail.BookingBehavior == BookingBehavior.PrivateLesson) {
            description = propertyName + ' - ' + activity?.Name + ' - ' + moment(request.CartItemDetail?.Slots[0]?.Time).format('lll');
        } else {
            if (request.CartItemDetail.SpecialMealId) {
                description = propertyName + ' - ' + activity?.Name + ' - ' + moment(request.CartItemDetail?.Slot?.Time).format('lll');
            } else {
                description = propertyName + ' - ' + (locationName ? locationName : '') + moment(request.CartItemDetail?.Slot?.Time).format('lll')
            }
        }
        return description;
    }

    getPayLoadData(request, lockSlot) {
        let cartDetails = request.CartItemDetail
        let time = cartDetails.Slots?.length ? cartDetails?.Slots[0]?.Time : (cartDetails.Slot ? cartDetails.Slot.Time : '')
        return [
            {
                CartItemId: request.Id,
                BookingType: cartDetails.BookingBehavior,
                LockId: lockSlot ? lockSlot.Id : null,
                SlotTime: time,
                ReservationAttemptId: cartDetails.ReservationAttemptId || null,
                PropertyId: this.restaurantId,
                EnableTicketPrinting: false,
                TicketPerPerson: false,
                IsForStandbyReservations: false,
                PackageDiscountPercent: null,
                PackageDiscountedAmount: null,
                PackageId: null,
                FinancialEffectId: cartDetails.FinancialEffectId,
                PaymentMethod: cartDetails.PaymentMethod,
                RetailCardTokenId: null,
                LanguageId: cartDetails.LanguageId
            }
        ]
    }
    public getVCartHeaders(): any[] {
        let headers: any[] = [];
        let token = null;
        let userSessionId = null;
        if (sessionStorage.getItem("quickIdJwt")) {
            token = sessionStorage.getItem("quickIdJwt");
            userSessionId = sessionStorage.getItem("quickIdUserSession");
        }
        else {
            token = sessionStorage.getItem("_jwt");
            userSessionId = sessionStorage.getItem("userSession");
        }
        headers.push({ key: 'Accept-Language', value: navigator.language });
        headers.push({ key: 'Content-Type', value: 'application/json' });
        headers.push({ key: 'Authorization', value: token ? 'Bearer ' + token : "" });
        headers.push({ key: 'SessionId', value: userSessionId ? userSessionId : "" });
        return headers;
    }
    getActivityHeder(restaurantID?: number, isRetailHeaderRequired: boolean = false) {
        let headerKeyValue = [];
        const sessionType = Utilities.getSessionStorageType();
        headerKeyValue.push({ key: 'ngsw-bypass', value: '' });
        let loginResult: LoginResultDTO = {} as LoginResultDTO;
        if (Utilities.tryParse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${sessionType}`)}_loginResult`))) {
            loginResult = Utilities.tryParseObject<LoginResultDTO>(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${sessionType}`)}_loginResult`));
        }

        if (sessionStorage.getItem(`ClientUid${sessionType}`)) {
            headerKeyValue.push({ key: 'x-ts-client-uid', value: sessionStorage.getItem(`ClientUid${sessionType}`) })
        }
        if (loginResult && loginResult.HostId) {
            headerKeyValue.push({ key: 'x-ts-host-id', value: loginResult.HostId.toString() })
        }
        if (restaurantID && loginResult && loginResult.RestaurantsAvailableForLogin &&
            loginResult.RestaurantsAvailableForLogin.find(r => r.Id === restaurantID)) {
            headerKeyValue.push({ key: 'x-ts-restaurant-api-key', value: loginResult.RestaurantsAvailableForLogin.find(r => r.Id === restaurantID).ApiKey })
        }

        if (sessionStorage.getItem(`MerchantKey${sessionType}`)) {
            headerKeyValue.push({ key: 'x-ts-merchant-auth-key', value: sessionStorage.getItem(`MerchantKey${sessionType}`) })
        }

        if (isRetailHeaderRequired) {
            if (sessionStorage.getItem('_jwt')) {
                headerKeyValue.push({ key: 'retail-token', value: sessionStorage.getItem('_jwt') })
            }
            if (sessionStorage.getItem('userSession')) {
                headerKeyValue.push({ key: 'retail-session', value: sessionStorage.getItem('userSession') })
            }
        }

        if (Utilities._managerPin) {
            headerKeyValue.push({ key: 'x-ts-impersonate-host-pin-code', value: Utilities._managerPin })
        }

        if (localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_hubConnectionId`)) {
          //  headerKeyValue.push({ key: 'signalr-connectionid', value: localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_hubConnectionId`) })
            headerKeyValue.push({ key: 'Access-Control-Allow-Origin', value: '*.rguest.com' })
        }

        return headerKeyValue;
    }
    ngOnDestroy(): void {

    }
}