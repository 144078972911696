<section class="reports h-100">
        <app-retail-reportcontrols [reportGroup]="'RETAIL'" *ngIf="reportType === 'retail'"
        (clickSave)="saveReportConfig($event)" [editValues]="editableValue" [edit]="edit"></app-retail-reportcontrols>
        <app-retail-reportcontrols [reportGroup]="'REVENUE'" *ngIf="reportType === 'revenue'"></app-retail-reportcontrols>
      <app-retail-reportcontrols [reportGroup]="'POS'"  *ngIf="reportType === 'commission'"
      (clickSave)="saveReportConfig($event)" [editValues]="editableValue"  [edit]="edit" ></app-retail-reportcontrols>
      <app-retail-reportcontrols [reportGroup]="'GIFTCARDS'"  *ngIf="reportType === 'giftcards'"
      [editValues]="editableValue" [edit]="edit" (clickSave)="saveReportConfig($event)"></app-retail-reportcontrols>      
      <app-retail-reportcontrols [reportGroup]="'INVENTORYCONTROL'"  *ngIf="reportType === 'inventorycontrol'"></app-retail-reportcontrols>
</section>
