import { BaseReport } from '../common/base-report';
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports, OutletAndCategoryFilter } from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';
import * as _ from 'lodash';
import { ReportBusinessService } from '../../business/report-business.service';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
export class SalesBySubCategory extends BaseReport {
    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, 
        private func: RetailFunctionalityBusiness , private propertyInfo: RetailPropertyInformation, public retailLocalization : RetailLocalization, private featureFlag: RetailFeatureFlagInformationService) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.SalesBySubCategory,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: false,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'LANDSCAPE',
            allowFutureDate: false
        }; 
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        const toApi = this.localization.convertDateObjToAPIdate;
        const customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const captions: string[] = this.business.getSelectedCategoryCaptions(customFilterData);
        const reportCategoryCaption: string = this.business.getFilterCaption(this.business.FilterDataSource['allCategoryGroups'], customFilterData.reportcategory.map(x => x.id));
        return [{ 'pStartDate': toApi(this.startDate) }, { 'pEndDate': toApi(this.endDate) }, {'pDate': this.printedDate}, { 'pPageBreak': this.pageBreakToggle},
                {'pPropertyName': this.propertyName}, 
                {'pOutlet': this.functionalities.ShowOutletSelectionFieldInSaleBySubCategoryReport ? this.getFilterCaption: this.getDefaultOutletCaption},
                 {'pReportCategory': reportCategoryCaption}, {'pCategory': captions[0]},
                {'pSubCategory1': captions[1]}, {'pSubCategory2': captions[2]}, {'pSubCategory3': captions[3]}, {'pSubCategory4': captions[4]}, {'pSubCategory5': captions[5]},
                {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInSaleBySubCategoryReport ? true : false },
                {"pIsEatecEnabled":this.featureFlag.IsEatecEnabled==true ? "true" : "false"}   ];
    }


    protected formURIParams(): ReportParams[] {
        return [];
    }


    protected formFilters() {
        const customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const toApi = this.localization.convertDateObjToAPIdate;
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        const tempOutLetFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return JSON.stringify({
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate),
                OutletIds: this.functionalities.ShowOutletSelectionFieldInSaleBySubCategoryReport ? 
                       this.selectedFilterOptions.map(x => x.id) : tempOutLetFalse,
                CategoryIds: this.getCategoriesAndSubCategories(customFilterData, 0),
                ReportGroupId: customFilterData.reportcategory.map(t=>t.id),
                SubCategory1Ids: this.getCategoriesAndSubCategories(customFilterData, 1),
                SubCategory2Ids: this.getCategoriesAndSubCategories(customFilterData, 2),
                SubCategory3Ids: this.getCategoriesAndSubCategories(customFilterData, 3),
                SubCategory4Ids: this.getCategoriesAndSubCategories(customFilterData, 4),
                SubCategory5Ids: this.getCategoriesAndSubCategories(customFilterData, 5),
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            });
        }
        const filter: Partial<OutletAndCategoryFilter> = {
            startDate: toApi(this.startDate),
            endDate: toApi(this.endDate),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInSaleBySubCategoryReport ? 
                   this.selectedFilterOptions.map(x => x.id) : tempOutLetFalse,
            CategoryIds: this.getCategoriesAndSubCategories(customFilterData, 0),
            ReportGroupId: customFilterData.reportcategory.map(t=>t.id),
            SubCategory1Ids: this.getCategoriesAndSubCategories(customFilterData, 1),
            SubCategory2Ids: this.getCategoriesAndSubCategories(customFilterData, 2),
            SubCategory3Ids: this.getCategoriesAndSubCategories(customFilterData, 3),
            SubCategory4Ids: this.getCategoriesAndSubCategories(customFilterData, 4),
            SubCategory5Ids: this.getCategoriesAndSubCategories(customFilterData, 5)
        };
        return JSON.stringify(filter);
    }

    private getCategoriesAndSubCategories(customFilterData: any, index: number): number[] {
        let catArray: number[] = [];
        if (_.get(customFilterData, 'category.length')) {
            console.log(customFilterData.category);
           catArray =  customFilterData.category[index].selectedCat.length === 0 ?
           [] : customFilterData.category[index].selectedCat.map(x => x.id);
           if((customFilterData.category[index].selectedCat.length === customFilterData.category[index].catArr.length) && customFilterData.category[index].catArr.length > 0){
            catArray = [];
        }
        }
        return catArray;
    }
}


