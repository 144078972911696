<!-- New UI -->
<section class="w-100 h-100 seat-login">
    <div class="seat-login__login-page position-relative">
      <div class="seat-login__background">
        <!-- <img src="assets/images/Agilysys_logo.svg" class="seat-login__agilysys-logo" (click)="breakin()"> -->
        <!-- <img src="assets/images/seat-logo.png" class="seat-login__spa-logo"> -->
      </div>
      <div class="seat-login__login-area">
        <div class="seat-login__login-details">
          <div class="seat-login__login-header">
            <ng-content select="[seat-login-text]"></ng-content> <!-- Inject external content here -->
            <ng-content select="[seat-login__language]"></ng-content> <!-- Inject external content here -->
          </div>
          <ng-content select="[forms]"></ng-content> <!-- Inject external content here -->
          <div class="seat-login__footer">
            <img src="assets/images/Agilysys_logo.svg" class="seat-login__agilysys-logo reserve-logo" alt="">
            <div class="seat-labels">{{'copyRightsText' | translate: {year: currentYear} }}</div>
            <div class="seat-labels">{{'resolutionText' | translate}}</div>
          </div>
        </div>
      </div>
      <div class="seat-login__logo">
        <img alt="logo_activties" class="seat-login__logo--img" src="assets/images/Agilysys_Reserve_login-bg.png">
      </div>
    </div>
  </section>