import { RguestCommunication } from '../common/rguest-communication';
import { HttpClient } from '@angular/common/http';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { Localization } from '../../localization/localization';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { AppService } from '../../app-service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable(
    { providedIn: 'root' }
)
export class RetailCommunication extends RguestCommunication {

    constructor(appService: AppService, utils: CommonUtilities, httpclient: HttpClient, localization: Localization, PropertyInfo: CommonPropertyInformation) {
        super(environment["RetailGateway"], httpclient, localization, utils, PropertyInfo, appService);
    }
} 