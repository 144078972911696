import { PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsDTO } from '@models/RestaurantDTO';

@Pipe({
  name: 'getSeatingAreaName'
})
export class GetSeatingAreaNamePipe implements PipeTransform {
  constructor(private ts: TranslateService) {

  }
  transform(id: number, settings: SettingsDTO): any {
    const seatingArea = settings.SeatingAreas.filter(x => x.Id === id);
    if (seatingArea.length > 0) {
      return seatingArea[0].Name;
    }
    else {
      return this.ts.instant('seatDetailAnyWhereText');
    }
  }

}
