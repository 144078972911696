import { Pipe, PipeTransform } from "@angular/core";
import { CacheService } from "@app/core/services/cache.service";
import { days } from "../constants/globalConstants";
import { DashboardFunctions } from "../utilities/dashboard-functions";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'weekDayPipe'
  })
  export class WeekDayPipe implements PipeTransform {
  
    constructor(private cs: CacheService,private ts: TranslateService) { }
  
    transform(date: any): any {
      let weekDays = days;
      if (date) {
        return this.ts.instant(weekDays[date.getDay()]).slice(0,3);
      }
      return this.ts.instant(weekDays[this.cs.headerDate.getDay()]).slice(0,3);
    }
  }