import { Component, OnInit, Inject } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component'; 

@Component({
  selector: 'app-scheduled-markdown-popup',
  templateUrl: './scheduled-markdown-popup.component.html',
  styleUrls: ['./scheduled-markdown-popup.component.scss']
})
export class ScheduledMarkdownPopupComponent implements OnInit {
  showTable:boolean = true;
  common:any;
  minDate:any;
  minEndDate:any;
  markdownForm:UntypedFormGroup
  tableoptionsPopup:any
  selectedArray:any =[];
  btnStatus:any;
  captions:any;
  placeholderFormat: string;
  floatLabel: string;

  constructor(public ls:RetailLocalization,  private dialogRef: MatDialogRef<RetailPopupComponent> 
    ,public fb:UntypedFormBuilder, @Inject( MAT_DIALOG_DATA ) public data: any) {
      this.floatLabel = this.ls.setFloatLabel;
     }

  ngOnInit() {
    this.placeholderFormat = this.ls.inputDateFormat;
    this.common = this.ls.captions.common;
    this.captions = this.ls.captions.retailsetup;
    this.markdownForm = this.fb.group({
      startDate:['',Validators.required],
      endDate:['',Validators.required],
      discount: ['',Validators.required],
      discountMember: ['',Validators.required],

    });
   this.tableoptionsPopup = _.cloneDeep(this.data.datarecord);
   this.tableoptionsPopup[0].SelectedSettingId = "markdown_add";
   this.tableoptionsPopup[0].EnableActions = false;
   let bodyData = this.tableoptionsPopup[0].TablebodyData;
   this.selectedArray = this.data.selectedArray;
   if(this.data.status == "edit"){
    this.btnStatus = this.captions.UPDATE;

    this.markdownForm.patchValue(this.data.editedData);
    this.markdownForm.controls.startDate.setValue(new Date(this.data.editedData.startDate));
    this.markdownForm.controls.endDate.setValue(new Date(this.data.editedData.endDate));
   }
   else{
    this.btnStatus = this.captions.SAVE;
  }
 
  this.tableoptionsPopup[0].TablebodyData = this.selectedArray;
  }


  ngAfterViewInit(){
      this.tableoptionsPopup[0].Searchable = false;
      this.tableoptionsPopup[0].IsCheckAll = true;
  }

  calculate(){
    let discount = Number(this.markdownForm.controls.discount.value);
    let memberDiscount = Number(this.markdownForm.controls.discountMember.value);
    let startDate = this.markdownForm.controls.startDate.value;
    let endDate = this.markdownForm.controls.endDate.value;
    let totalData  = this.tableoptionsPopup[0].TablebodyData
    this.tableoptionsPopup[0].TablebodyData.forEach(element => {
      if(element.checked){
        element.discount = discount;
        element.discountMember = memberDiscount;
        element.adjustedPrice = element.priceSample -((discount/100) * element.priceSample);
        element.adjustedMemberPrice  = element.currentMember - ((memberDiscount/100)* element.currentMember);
        element.startDate = this.ls.LocalizeDate(startDate);
        element.endDate = this.ls.LocalizeDate(endDate);
      }
    });
  
    this.tableoptionsPopup = [...this.tableoptionsPopup];

  }



  selectedRowPopup(eve){
    eve.forEach(element => {
      element.checked = true;
    }); 
  }

  close(){
    this.dialogRef.close();
  }

  saveInfo(){
    this.dialogRef.close();
    let savedData = [];
    savedData.push(this.markdownForm.value);
    savedData.push({"array":_.filter(this.selectedArray,["checked", true])});
    console.log(savedData);
  }
}
