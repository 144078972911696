import { Component, Inject, OnInit, ViewEncapsulation, ViewContainerRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { popupConfig } from '../shop.modals';
@Component({
  selector: 'shop-dialog-popup',
  templateUrl: 'shop-dialog-popup.component.html',
  styleUrls: ['./shop-dialog-popup.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ShopDialogPopUp implements OnInit {
  public test:boolean = true;
  datRec:any;
  popupConfigs: popupConfig;
  constructor( public dialogRef: MatDialogRef<ShopDialogPopUp>, @Inject( MAT_DIALOG_DATA ) public data: any, public viewContainerRef: ViewContainerRef){ }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(){
      this.datRec = this.data.datarecord;
      this.popupConfigs = this.data.popupConfig;    
      
  }

}