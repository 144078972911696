<ng-container>
    <app-dialog-header [title]="dataInput?.headername" (closeDialog)="close($event)"></app-dialog-header>
</ng-container>

<div class="CreditCardTerminal-wrapper">
    <div class="ag_modal--form-height">
        <div [ngClass]="functionalities.ShowCreditCardTerminalOtherInfo ? 'w-100' : 'ag_form_wrapper--xs' ">
            <form class="CreditCardTerminal-formwrapper" [formGroup]="form" autocomplete="off">
                <div  [ngClass]="functionalities.ShowCreditCardTerminalOtherInfo ? 'ag_display--flex' : '' ">
                    <div [ngClass]="functionalities.ShowCreditCardTerminalOtherInfo ? 'w-50' : '' ">
                        <div>
                            <ag-textbox [config]="codeTextInputConfig"></ag-textbox>
                        </div>
                        <div *ngIf="functionalities.ShowCreditCardTerminalMerchantNumber">
                            <ag-textbox [config]="merchantNumberInputConfig"></ag-textbox>
                        </div>
                        <!-- <div *ngIf="!functionalities.ShowCreditCardTerminalActiveFlagAsToggle; else toggleTemp">
                            <mat-checkbox class="form-group ag_checkbox" formControlName="isActive"
                                [attr.LiteralID]="'CreditCardTerminallbl_IsActive'">
                                {{captions.CreditCardTerminallbl_IsActive}}</mat-checkbox>
                        </div> -->
                        <div class="mb-1"><label class="ag_form--label">{{captions.Active}}</label>
                        <app-retail-toggle-switch  [attr.automationId]="'Txt_createCreditCardTerminal_isActive'" formControlName="isActive"
                        class="toggle-switch"></app-retail-toggle-switch>
                    </div>
        
                        <div *ngIf= "ShowDefaultToggle" class="d-inline-flex" class="float-left ln-ht10" [ngClass]="functionalities.ShowCreditCardTerminalOtherInfo ? 'mt-1 pt-4' : '' ">       
                            <div  class="mb-1"><label class="ag_form--label">{{captions.lbl_isDefaultCC}}</label></div>
                         <app-retail-toggle-switch  [attr.automationId]="'Txt_createCreditCardTerminal_isActive'" formControlName="isDefault"
                           class="toggle-switch"></app-retail-toggle-switch>
                       </div>
                    </div>
                    <div *ngIf="functionalities.ShowCreditCardTerminalOtherInfo" [ngClass]="functionalities.ShowCreditCardTerminalOtherInfo ? 'w-50' : '' ">
                        <div>
                            <ag-textbox [config]="nameTextInputConfig"></ag-textbox>
                        </div>
                        <div>
                            <app-ag-incremental [inputs]="listOrderInput" class="ag_w--50"></app-ag-incremental>
                        </div>
                        <div>
                            <app-ag-number [inputs]="folioInput"></app-ag-number>
                        </div>
                        <div>
                            <ag-textbox [config]="storeIdTextInputConfig"></ag-textbox>
                        </div>
                    </div>
                </div>
                <input [attr.automationId]="'Txt_createCreditCardTerminal_outletName'" type="submit" [disabled]="actionButton.disabledproperty" class="hidden-submit"
                tabindex="-1"  (click)="onAction($event)"/>
            </form>
        </div>
    </div>
    <div class="CreditCardTerminal-footer ag_footer--actions">
        <app-button [attr.automationId]="'Btn_createCreditCardTerminal_action'" [buttontype]="actionButton" (valueChange)='onAction($event)'></app-button>
        <app-button [attr.automationId]="'Btn_createCreditCardTerminal_cancel'" [buttontype]="cancelButton" class="ag-ml-2" (valueChange)='onCancel($event)'></app-button>
    </div>
</div>

<!-- <ng-template #toggleTemp>
    <div class="float-left ln-ht10 ag_display--flex-wrap pb-3" [formGroup]="form">
        <div class="mb-1 ag_display--flex-wrap"><label class="ag_form--label">{{captions.Active}}</label></div>
        <app-retail-toggle-switch  [attr.automationId]="'Txt_createCreditCardTerminal_isActive'" formControlName="isActive"
          class="toggle-switch"></app-retail-toggle-switch>
    </div>
</ng-template> -->
