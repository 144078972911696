import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[nowhitespace]'
})

/**
 * This validator works like "required" but it does not allow whitespace either
 *
 * @export
 * @class NoWhitespaceDirective
 */

export class nowhitespaceDirective {
    constructor(private element: ElementRef, private control: NgControl) { }

    @HostListener('blur', ['$event']) onblur(event) {
        let value = String(this.control.control.value || "");
        this.noWhiteSpaceValidator(value);
    }
    @HostListener('paste', ['$event']) onpaste(event) {
        this.control.control.markAsDirty();
        let value = String(this.control.control.value || "");
        this.noWhiteSpaceValidator(value);
    }

    noWhiteSpaceValidator(value) {
        //To remove whitespace from both sides of a value
        this.control.control.setValue(value.trim());
    }
}