import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ReportDataService } from '../../../data/report-data.services';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ValidationMessage } from '../../../basereport/base-report.component';
import { DropDownData } from '../../../business/report.modals';

@Component({
  selector: 'app-inventory-variance',
  templateUrl: './inventory-variance.component.html',
  styleUrls: ['./inventory-variance.component.scss']
})
export class InventoryVarianceComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidTextInput = new EventEmitter<ValidationMessage>();
  public captions: any = this.localization.captions.reports;
  ItemFormGrp: UntypedFormGroup;
  zeroVarianceValue: boolean;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  selectedDropdownType = '';
  clerks: DropDownData[] = [];
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization,
    public business: ReportBusinessService, private dataService: ReportDataService) { }

  ngOnInit(): void {
    this.ItemFormGrp = this.fb.group({
      filterItems: false,
      searchbyitem: '',
      clerks: new UntypedFormControl([this.defaultData]),
      isIncludeZeroVariance: this.zeroVarianceValue == null ? false : this.zeroVarianceValue
    });
   this.formReady.emit(this.ItemFormGrp);
    this.onLoad();
   this.isValidText(true);
  }
  private async onLoad() {
    
  }
  
  getFormControlValue(event) {
     if (event[0] === 'clerks') {
        this.ItemFormGrp.setControl('clerks', event[1]);
    }
  }
  
  isValidText(inputValue: boolean) {
    this.IsValidTextInput.emit({ dropDownName: "TextField", validity: inputValue });
  }

  toggleZeroVariance(eve) {
    this.zeroVarianceValue = eve;
  }

}