import { I } from '@angular/cdk/keycodes';
import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { AppService } from '@app/app.service';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { RateNegotiationComponent } from '@app/shared/components/rate-negotiation/rate-negotiation.component';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { ActivityRatePlanRequest, CommentType } from '@app/shared/models/ActivityRatePlanRequestDTO';
import { MemberRatePlaneDTO } from '@app/shared/models/EngageMemberDetailDTO';
import { PartyNotes } from '@app/shared/models/global.interface';
import { PartyPrepaymentState } from '@app/shared/models/InputContact';
import { CurrencyDecimalFormater } from '@app/shared/pipes/currency-decimal.pipe';
import { RolesAndPermissionPipe } from '@app/shared/pipes/RolesAndPermission.pipe';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { BookingBehavior, buttonTypes, ComponentTypes, PartyNoteType, ReservationEmailNotificationType, RolesAndPermissionsType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { FormChipService } from '@dynamicform/components/form-chip/form-chip.service';
import { DynamicFormService } from '@dynamicform/service/dynamic-form.service';
import { BookingChargeType, Category, PartyNoteDTO, SettingsDTO, TaxableItem } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { FacadeService } from '@services/facade.service';
import { PartyService } from '@services/party.service';
import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
@Component({
  selector: 'privatelesson-booking-summary',
  templateUrl: './privatelesson-booking-summary.component.html',
  styleUrls: ['./privatelesson-booking-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivateLessonBookingSummaryComponent extends Utilities implements OnInit, OnDestroy {

  selectedIndex: number;
  intialized: boolean = false;
  subscriptions: Subscription = new Subscription();
  ratePlan: any;
  coverTypes = [];
  bookingTypes: any = [];
  lockResponse;
  _settings: SettingsDTO;
  showAllSlots: boolean = false;
  OperationCurrency: string;
  enableGuestComponent: boolean = false;
  ratePlanValidationMsg = '';
  today: number = Date.now();
  remainingAmount = 0;
  totalPayableAmount = 0;
  totalDepositAmount = 0;
  taxAmount = 0;
  totalAddOnAmount = 0;
  totalAddOnTaxAmount = 0;
  showDepositAmount = false;
  negotiationReason: any = '';
  instructor: any;
  fromComponent: any;
  restaurantPolicyAvailable: Boolean = false;
  restaurantPolicy: any = [];
  config: FieldConfig[] = [];
  includeOthers = false;
  othersId: number[] = [];
  customPartyNotes: any[] = [];
  partyNotes: PartyNotes[] = [];
  categories: Category[] = [];
  selectedPartyPreferenceTags: any[] = [];
  isTaxConfigured = false;
  isRetailEnabledProperty = false;
  selectedAddOns: any;
  totalServiceCharge: number;
  totalServiceChargeWithTax: number = 0;
  isServiceConfigured = false;
  negotiationRatesConfig: FieldConfig[];
  recalculateConfig: FieldConfig[];
  perItemEditable = false;
  totalAmountForBooking = 0;
  applyNegotiationBtn: ButtonValue;
  cancelNegotiationBtn: ButtonValue;
  rateNegotiateConfig: FieldConfig[];
  firstCall = true;
  ratePlanOriginal: any;
  originalAdjustedMinRate: number = 0.00;
  adjustedMinRate: number = 0.00;
  activitiesAmount: number = 0.00;
  subtotal: number = 0.00;
  serviceCharge: number = 0.00;
  taxOnServiceCharge: number = 0.00;
  tax: number = 0.00;
  minRateTaxOnServiceChargeAmount:number = 0.00;
  grandTotal: number = 0.00;
  negotiatedDiscount: number = 0.00;
  totalAddonTaxOnServiceChargeAmount: number =0.00;
  applyNegotiationClicked: boolean = false;
  grandTotalValidated: boolean = false;
  negotiationCutOff = 0;
  partyData:any;
  showGuestDetails: boolean = false;
  prevSalePrice: any = {
    cover: {},
    bookingType: {},
    base: {},
    location: {},
    instructor: {},
    adjustedMinRate: 0,
    membership: {}
  }
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  @ViewChild('negotiateReasonForm') negotiateReasonForm: DynamicFormComponent;
  constructor(public dialog: MatDialog, public partyService: PartyService, private cs: CacheService, private ps: PopupService, @Inject(COMPONENTINPUT) public data, public translateService: TranslateService,
    private currencyDecimalFormater: CurrencyDecimalFormater, public permissions: RolesAndPermissionPipe, public facadeService: FacadeService, private formChipService: FormChipService, private dfs: DynamicFormService, public _as: AppService, private api: ApiService, private popupService: PopupService, private df: DashboardFunctions) {
    super(dialog);
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.isTaxConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax)?.length > 0;
      this.isServiceConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge)?.length > 0;
      this.isRetailEnabledProperty = Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO);
      this.OperationCurrency = this._settings.General.OperationCurrency;
      this.coverTypes = [...this._settings.CoverTypes];
      this.bookingTypes = [...this._settings.BookingTypes];
      this.partyService.isNegotiationEnabled = this._as.hasPermission(RolesAndPermissionsType.NegotiateRates);
      if (this._settings.PropertySetting.length > 0 && (this._settings.PropertySetting[0].PreReservationMessage && this._settings.PropertySetting[0].PreReservationMessageTitle)) {
        this.restaurantPolicyAvailable = true;
        this.restaurantPolicy = this._settings.PropertySetting[0];
      }
      this.categories = this._settings.Categories.filter(category => category.Text != "Reasons");
      if (data && this._as.OTASourceId.includes(data.PartySourceId)) {
        this.includeOthers = true;
      }
      if (data && this.data.Notes && this.data.Notes.length) {
        this.othersId = this.data.Notes.filter(x => x.Type == PartyNoteType.FreeFormNote).map(({ RelatedId }) => RelatedId);
      }
      this.partyNotes = Utilities.getRestaurantPredefinedPartyNotes(this.categories, this.includeOthers, this.othersId);

    }));
  }


  ngOnInit() {
    this.partyData = !_.isEmpty(this.partyService.retailservice.oldReservation)? this.partyService.retailservice.oldReservation.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation[0]: null;
    this.subscriptions.add(this.partyService.openNextPopUp$.subscribe(data => {
      if (data) {
        this.showWarningPopup(this.ratePlanValidationMsg, ComponentTypes.RatePlanWarning);
      }
    }));
    this.perItemEditable = this.permissions.transform(RolesAndPermissionsType?.NegotiateRates)
    this.setNegotiationConfig();
    this.subscriptions.add(this.partyService.tabChange$.subscribe(value => {
      let getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, this.partyService.selectedSpecialMealId);
      if ((value === 1 && (!getAddOns || getAddOns?.length == 0 || getAddOns?.filter(addOn => addOn.IsVisible == true).length == 0)) || (value == 2 && getAddOns?.length > 0 && getAddOns?.filter(addOn => addOn.IsVisible == true).length > 0)) {
        this.intialize();
        this.showGuestDetails = true;
      }
      else{
        this.showGuestDetails = false;
      }
    }));

  }


  setNegotiationConfig() {
    this.negotiationRatesConfig = [{
      type: 'switch',
      name: 'negotiation',
      inputType: 'text',
      label: 'NegotiableRates',
      class: 'negotiation-rate',
      checked: false
    }
    ];
    this.recalculateConfig = [{
      type: 'switch',
      name: 'applyafterNegotiation',
      inputType: 'text',
      label: 'Recalculate',
      class: 'recalculate',
      checked: false
    }
    ];
   
    this.rateNegotiateConfig = [
      {
        name: 'reasonText',
        type: 'textarea',
        label: 'EnterText',
        rows: 2,
        inputType: 'text',
        showHint: true,
        charLength: 400,
        textAreaAppearance: 'outline',
        class: 'reject-options__input',
        value: this.setNegotiationReason(),
        disableErrorStateMatcher: true
        //    validation:[Validators.required]
      }]

    this.applyNegotiationBtn = {
      type: buttonTypes.actionPrimarySmall,
      label: 'apply',
      disbaledproperity: this.negotiationReason == '' ? true : false,
      customclass: 'action-bar__search-btn',
    }
    this.cancelNegotiationBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: 'reset',
      disbaledproperity: false,
      customclass: 'action-bar__search-btn',
    }

  }

  enableNegotiationOption(value) {
    if (value[this.negotiationRatesConfig[0].name]) {
      this.perItemEditable = true;
      this.df.enabledNegotiation = true;

    }
    else if (value[this.negotiationRatesConfig[0].name] == false) {
      this.perItemEditable = false;
      this.df.enabledNegotiation = false;
      this.editNegotiationAmount();
    }

  }
  applyTaxForNegotiated(value) {
    if (value.hasOwnProperty(this.recalculateConfig[0].name)) {
      this.df.negotiateServiceCharge = value[this.recalculateConfig[0].name];
    }
    else {
      this.totalServiceCharge = _.cloneDeep(this.ratePlan?.TotalServiceChargeAmount ? this.ratePlan?.TotalServiceChargeAmount : 0);
    }
  }

  intialize() {
    this.firstCall = true;
    this.partyService.ratePlanForBooking = null;
    this.selectedAddOns = this.df.getSelectedAddOns()
    if (this.data && this.data.Contact) {
      this.partyService.selectedGuest = this.data.Contact;
      this.fromComponent = ComponentTypes.EditPrivateLessonBooking
    }
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === PrivateLessonBookingSummaryComponent);
    this.lockTables();
    this.bindGuestPartyNotes();
    if (this.data && this.data.Notes && this.data.Notes.length > 0) {
      this.partyService.partyNotes = [];
      this.mapPartyNotes();
      if (this.customPartyNotes.length) {
        this.mapCustomPartyNotes();
      }
    }
    this.formChipsChange();
    this.formChangeSubscribe()
  }

  lockTables() {
    this.partyService.BookingBehaviour = BookingBehavior.PrivateLesson;
    var { ActivityId, Slots, CoverTypes, BookingTypes, Location, LocationName, BookingSize, StaffId } = this.partyService.privateLessonBookingData;
    var slots = Slots.map(slot => {
      return {
        LocalTime: slot.LocalTime,
        PartySize: BookingSize,
        SeatingAreaId: null,
        SeatingTypeId: null,
        IsCommunalTable: false,
        FromWebsite: true,
        DurationInMinutes: slot.Duration,
        TableIds: [Location],
        AddOns: this.df.getSelectedAddOns(),
        ExpireAt: null
      }
    });
    this.enableGuestComponent = false;
    this.changeFormState(false);
    if (slots.length) {
      if (this.data && this.data.ReservedFor == slots[0].LocalTime && this.data.Duration == slots[0].DurationInMinutes) {
        this.setRatePlanCalculation(ActivityId, Slots, CoverTypes, BookingTypes, Location, BookingSize, StaffId);
      } else {
        this.subscriptions.add(this.api.lockTables(slots, true).subscribe(response => {
          this.lockResponse = response.Payload;
          this.partyService.isSlotLocked = true;
          const lockData = Object.values(this.lockResponse) as any[];
          this.partyService.lockData = response.Payload;
          this.partyService.slotLockIds = lockData?.map(x => {
            if (x?.SlotLockIdDTO?.Id) {
              return x?.SlotLockIdDTO?.Id;
            }
            else {
              return 0;
            }
          });
           }));
           this.setRatePlanCalculation(ActivityId, Slots, CoverTypes, BookingTypes, Location, BookingSize, StaffId);

      }
    }
  }

  setNegotiationReason(){
    let ratePlanNegotiateReason = '';
    if(this.data && this.data.bookingRatePlan?.Comments){
      try{
        let commentsObj = JSON.parse(this.data.bookingRatePlan.Comments);
        if(commentsObj){
          ratePlanNegotiateReason = commentsObj.find(comment => comment.commentType == CommentType.Negotiation)?.Comment || '';
        }
      }catch(error){
        ratePlanNegotiateReason = this.data.bookingRatePlan.Comments || '';
      }
    }
    return ratePlanNegotiateReason;
  }
  setRatePlanCalculation(ActivityId, Slots, GuestTypes, BookingTypes, Location, BookingSize, StaffId, Payload?,resetData?) {
   // this.lockResponse = Payload;
    var request = {
      Slots: Slots,
      ActivityId: ActivityId,
      GuestTypes,
      BookingTypes,
      TableIds: [Location],
      InstructorIds: StaffId,
      Size: BookingSize,
      SessionSlot: null,
      TrackMembershipNumber: this.partyService.selectedGuest?.TrackMembershipNumber,
      MemberType: this.partyService.selectedGuest?.MembershipType,
      IsMemberActive: this.partyService.selectedGuest?.MemberActive,
      RateType: this.partyService.selectMemberRatePlane({
        ActivityId: ActivityId, 
        Slots: Slots, 
        Location : Location,
        sessionGroupId : null
      }),
      RateCode: this.partyService.selectedGuest?.RateType,
      BookingBehavior: BookingBehavior.PrivateLesson,
      SelectedAddOns: this.df.getSelectedAddOns(),
      SeatingTypeIds: null,
      RatePlanTotalNegotiation: this.data && this.data.bookingRatePlan?.RatePlanTotalNegotiation && (this.firstCall || resetData) ? this.data.bookingRatePlan?.RatePlanTotalNegotiation : this.formData(resetData)
    } as ActivityRatePlanRequest;
    if(this.df.isBookingDataUpdated(request,this.data)){
      request['RatePlanTotalNegotiation'] = this.formData(resetData)
    }
    this.totalDepositAmount = 0;
    this.showDepositAmount = false;
    this.totalDepositAmount = 0;
    this.showDepositAmount = false;
    this.originalAdjustedMinRate = 0.00;
    this.adjustedMinRate = 0.00;
    this.negotiatedDiscount = 0.00;
    this.activitiesAmount = 0.00;
    if( this.ratePlan && !this.applyNegotiationClicked){
      this.ratePlan.FinancialEffectId = null;
    }
    this.subscriptions.add(this.api.GetRatePlanCalculated(request, this.ratePlan?.FinancialEffectId || null).subscribe(response => {
      this.grandTotalValidated = true;
      let ignoreNegotiation = !this.applyNegotiationClicked && this.df.isBookingDataUpdated(request, this.data);

      if(this.partyService.privateLessonBookingDataSaveObj){
        this.partyService.privateLessonBookingDataSaveObj.summaryState = 'valid';
      }

      if (this.firstCall) {
        this.ratePlanOriginal = response.Payload;
      }
      this.partyService.ratePlanForBooking = this.ratePlan = response.Payload;
      if (this.ratePlan && this.ratePlan.RatePlanCalculations) {
        this.partyService.FinancialEffectId = this.ratePlan.FinancialEffectId;
        this.negotiationCutOff = this.ratePlan.TotalRatePlan;
        this.taxAmount = this.ratePlan.TotalTaxAmount;
        this.totalServiceCharge = _.cloneDeep(this.ratePlan.TotalServiceChargeAmount);
        this.totalServiceChargeWithTax = this.ratePlan.TotalTaxOnServiceChargeAmount;
        this.totalAddonTaxOnServiceChargeAmount = this.ratePlan.TotalAddonTaxOnServiceChargeAmount;
        this.minRateTaxOnServiceChargeAmount = this.ratePlan.MinRateAdjustment?.TaxOnServiceChargeAmount || 0;
        this.totalAddOnAmount = this.ratePlan.TotalAddonAmount;
        this.totalAddOnTaxAmount = this.ratePlan.TotalAddonTaxAmount;
        // let uneditableSlotRatePlan = this.getUneditableSlotsRatePlan();
        // if (uneditableSlotRatePlan && uneditableSlotRatePlan.length) {
        //   this.ratePlan.TotalRatePlan = this.ratePlan.TotalRatePlan + uneditableSlotRatePlan.map(r => r.TotalAmount).reduce((sum, current) => sum + current, 0);
        //   this.uneditableTaxAmount = uneditableSlotRatePlan.map(r => r.TaxAmount).reduce((sum, current) => sum + current, 0)
        //   this.taxAmount += this.uneditableTaxAmount;
        //   this.ratePlan.TotalTaxAmount = this.ratePlan.TotalTaxAmount + this.uneditableTaxAmount;
        //   this.negotiationCutOff += uneditableSlotRatePlan.filter(r => r.sessionNegotiation < 0).map(r => r.sessionNegotiation).reduce((sum, current) => sum + Math.abs(current), 0)
        //   uneditableSlotRatePlan = _.orderBy(uneditableSlotRatePlan, s => s.TimeRange.Start);
        //   this.ratePlan.RatePlanCalculations = [...uneditableSlotRatePlan, ...this.ratePlan.RatePlanCalculations];
        // }
        if (this.data && this.firstCall) {
          this.totalPayableAmount = this.partyService.privateLessonBookingDataSaveObj && (this.partyService.privateLessonBookingDataSaveObj.NegotiatedAmount || this.partyService.privateLessonBookingDataSaveObj.NegotiatedAmount == 0) ? this.ratePlan.TotalRatePlan + this.partyService.privateLessonBookingDataSaveObj.NegotiatedAmount
            : (this.data.NegotiatedAmount) ? this.ratePlan.TotalRatePlan + this.data.NegotiatedAmount : this.ratePlan.TotalRatePlan;
          this.remainingAmount = this.partyService.privateLessonBookingDataSaveObj && this.partyService.privateLessonBookingDataSaveObj.NegotiatedAmount ? this.partyService.privateLessonBookingDataSaveObj.NegotiatedAmount : this.ratePlan.OverAllNegotiation;
          this.negotiationReason = this.df.negotiationReason(this.data, this.partyService.privateLessonBookingDataSaveObj);
          let ratePlanDetails = this.data && this.data.bookingRatePlan ? this.data.bookingRatePlan.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation : this.ratePlan.RatePlanCalculations;
          this.ratePlan.RatePlanCalculations.forEach(item => {
            if (item.AddonAttemptDetails?.length) {
              let addOnItem = ratePlanDetails.find(data => data.Id == item.Id)?.AddonAttemptDetails;
              if (addOnItem) {
                addOnItem.forEach(element => {
                  item.AddonAttemptDetails.filter(addOn => addOn.AddonId == element.AddonId).map(data => {
                    data.NegotiatedAmount = element.NegotiatedAmount || 0;
                  })
                });
              }
            }
          })
        }
        else {
          this.totalPayableAmount = this.partyService.privateLessonBookingDataSaveObj?.NegotiatedAmount ? this.ratePlan.TotalRatePlan + this.partyService.privateLessonBookingDataSaveObj.NegotiatedAmount : this.ratePlan.TotalRatePlan;
          this.remainingAmount = this.partyService.privateLessonBookingDataSaveObj?.NegotiatedAmount ? this.partyService.privateLessonBookingDataSaveObj.NegotiatedAmount : this.ratePlan.OverAllNegotiation;
          this.negotiationReason = this.df.negotiationReason(this.data, this.partyService.privateLessonBookingDataSaveObj);

        }
        if (this.ratePlan.TotalDepositAmount > 0 || this.data?.DepositPaid) {
          if (!this.data || !this.data.Id || this.data.PrepaymentState == PartyPrepaymentState.DepositCollected) {
            this.totalDepositAmount = this.data?.DepositPaid ? this.data.DepositPaid : this.ratePlan.TotalDepositAmount;
            this.showDepositAmount = true;
          }
        }
        let dataForSlotRatePlanCalc = {
          applyNegotiationClicked: this.applyNegotiationClicked,
          ignoreNegotiation: ignoreNegotiation,
          prevSalePrice: this.prevSalePrice
        }
        let calculatedRatePlan = this.df.formRatePlanSlotValue(this.ratePlan, this.partyService.privateLessonBookingData.BookingSize, this.data, this.firstCall, dataForSlotRatePlanCalc,this.data && this.data.bookingRatePlan ? this.partyData : null);
        this.ratePlan.SlotList = calculatedRatePlan.ratePlan;

        this.activitiesAmount = calculatedRatePlan.activitiesAmount;

        this.negotiatedDiscount = calculatedRatePlan.negotiatedDiscount;
        this.adjustedMinRate = calculatedRatePlan.adjustedMinRate;
        this.ratePlan.MinRateAdjustment.Amount = this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount + (this.ratePlan.MinRateAdjustment.NegotiatedAmount || 0);
        this.originalAdjustedMinRate = this.adjustedMinRate;
        this.setAdjustedMinRate();
      } else {
        this.ratePlan.SlotList = Slots;
      }
      this.partyService.ratePlan$.next(this.ratePlan);
      this.createSaveRequestObject();
      this.enableGuestComponent = true;
      if (this.partyService.reservationFormGroup.controls.selectedGuest.value) {
        this.changeFormState(true);
      } else {
        this.changeFormState(false);
      }
      if (!this.ratePlan.TotalRatePlan || (this.ratePlan.RatePlanCalculations.filter(slot => !slot.TotalAmount).length)) {
        this.ratePlanValidationMsg = this.translateService.instant('noRatePlanMessage');

        if ((!this.data || (this.data && !this.data.Contact))) {
          // this.showWarningPopup(this.ratePlanValidationMsg, ComponentTypes.RatePlanWarning);

          this.ratePlanValidationInfo();
        }
        else {
          if (this.partyService.isGuestComponentLoaded) {
            this.ratePlanValidationInfo();
          }
        }
      }
      if (this.ratePlan.SlotList && this.ratePlan.SlotList.length > 0) {
        this.ratePlan.SlotList.sort((a, b) => Utilities.parseDateString(a.TimeRange.Start).getTime() - Utilities.parseDateString(b.TimeRange.Start).getTime());
      }
      if (this.partyService.privateLessonBookingData && this.partyService.privateLessonBookingData.Slots && this.partyService.privateLessonBookingData.Slots.length > 0) {
        this.partyService.privateLessonBookingData.Slots.sort((a, b) => Utilities.parseDateString(a.LocalTime).getTime() - Utilities.parseDateString(b.LocalTime).getTime());
      }
      if (!this.data && this.remainingAmount) {
        this.calculateNegotiableRate();
      }
      this.totalAmountForBooking = _.cloneDeep(this.ratePlan.TotalRatePlan + this.ratePlan.TotalAddonAmount);
      this.formChangeSubscribe();
      this.calculatePaymentDetails();
      this.setRateSummary();
      this.applyNegotiationClicked = false;
    }));
  }

  ratePlanValidationInfo(){
    const _dialogRef = this.showConfirmationPopup('Alert', this.ratePlanValidationMsg, 'active', true, 'Proceed', 'Cancel');
    _dialogRef.afterClosed().subscribe((dataValue) => {
      if(dataValue){
        this.partyService.privateLessonBookingDialogRef ? this.partyService.privateLessonBookingDialogRef.close() : '';
        this.partyService.privateLessonBookingDialogRef ? this.partyService.privateLessonBookingDialogRef = null : '';
        this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef.close() : '';
        this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef = null : '';
      }
      this.ratePlanValidationMsg = '';
    });

  }

  setRateSummary(){
    this.partyService.privateLessonBookingDataSaveObj.rateSummary = {
      Amt: this.subtotal,
      TaxAmt: this.tax,
      SCAmt: this.serviceCharge,
      TaxOnSCAmt: this.taxOnServiceCharge
    }
  }

  calculatePaymentDetails() {
    this.onNegotiate(true);
    this.serviceCharge = (this.totalServiceCharge || 0) + (this.ratePlan.TotalAddonServiceChargeAmount || 0) + (this.ratePlan.MinRateAdjustment.ServiceChargeAmount || 0);
    this.tax = (this.taxAmount || 0) + (this.totalAddOnTaxAmount || 0) + (this.ratePlan.MinRateAdjustment.TaxAmount || 0);
    this.taxOnServiceCharge = (this.totalServiceChargeWithTax || 0) + (this.totalAddonTaxOnServiceChargeAmount || 0) + (this.minRateTaxOnServiceChargeAmount || 0);
    this.grandTotal = this.subtotal + this.serviceCharge + this.tax + this.taxOnServiceCharge;
    let guestSelected = this.partyService.reservationFormGroup.controls.selectedGuest.value;
    this.changeFormState(guestSelected);
  }
  validateCharacters(evt) {
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) {
      evt.preventDefault();
    }
  }

  formData(resetData){
    
    if(this.partyService.ratePlanForBooking){
      this.partyService.getNegotiatedValueForBooking(this.partyService.privateLessonBookingData);
    return  {
      Negotiation: null,
      MinRateNegotiatedAdjustment: this.ratePlan.MinRateAdjustment.Amount - this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount,
      applyNegotiationOnOtherCharges:[
        {             
          bookingChargeType : BookingChargeType.ServiceCharge,
          TotalAmount: this.partyService.ratePlanForBooking.TotalServiceCharge,
          NegotiatedAmount: null,
          UseAfterNegotiation: this.df.negotiateServiceCharge
        }
      ],
      RatePlanCalculationInfoNegotiation: this.partyService.ratePlanForBooking?.RatePlanCalculations && !resetData ? this.partyService.ratePlanForBooking?.RatePlanCalculations : null
    }
  }
    else{
      return null;
    }
  }


  calculateValue(slot) {
    this.applyNegotiationBtn.disbaledproperity = false;
    this.calculateAmount(slot);
  }

  calculateAmount(slot) {
    // if(slot && slot.AddonAttemptDetails && slot.AddonAttemptDetails.length){
    //   slot.totalAddOnAmount =  _.sumBy(slot.AddonAttemptDetails,item => Number(item['perItemValue']));
    //  }  
    //  if(slot){
    //   slot.totalAmount =  (slot.coverTypeVariants && slot.coverTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['covers'] * o['coverTypeValue'] })  : slot.bookingTypeVariants && slot.bookingTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['bookingTypes'] * o['bookingTypeValue'] }) : (slot.totalBaseRate || 0))  ;

    // }
    // this.totalAmountForBooking = (_.sumBy(this.ratePlan.SlotList, 'totalAddOnAmount') +_.sumBy(this.ratePlan.SlotList, 'totalAmount'));
  }

  showConfirmationPopup(title, message, popupType: 'action' | 'active' | '', showAlert, saveBtnLabel, cancelBtnLabel){
          
    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: title, showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType,
      dimensionType: 'small',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };
    const _dialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      width: '550px',
      height: '350px',
      data: {
        title,
        update: saveBtnLabel,
        cancel: cancelBtnLabel,
        componentDetails,
        from: null,
        back: false,
        standalone: true,
        showAction: true
      },
    });
    return _dialogRef;
  }


  showWarningPopup(message, componentType, priorPopUp?, priorPopUpComponentType?) {
    if (message) {
      let dontContinueBooking = false

      const popUpMessage = [{
        confirmationMessage: message, dialogTitle: 'Alert', showAlert: true
      }];

      const componentDetails: ComponentDetails = {
        componentName: ConfirmationPopupComponent,
        dimensionType: 'small',
        popupType: 'active',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: popUpMessage,
        popupTitle: popUpMessage[0].dialogTitle
      };

      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '550px',
        height: '350px',
        data: {
          title: this.translateService.instant('alert'),
          update: 'Proceed',
          cancel: 'Cancel',
          componentDetails,
          from: componentType,
          back: false,
          standalone: true,
          showAlert: true
        }
      });
      let closepriorpopup = false
      this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
        if (priorPopUp && closepriorpopup) {
          this.ps.restrictCloseDialog = false;
          this.ps.isRestrictCloseDialog = false;
          priorPopUp.close(priorPopUpComponentType);
        }
        if (dontContinueBooking) {
          this.partyService.privateLessonBookingDialogRef ? this.partyService.privateLessonBookingDialogRef.close() : '';
          this.partyService.privateLessonBookingDialogRef ? this.partyService.privateLessonBookingDialogRef = null : '';
          this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef.close() : '';
          this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef = null : '';
        }
        if (cancelPopUpConfirmSubscription) {
          cancelPopUpConfirmSubscription.unsubscribe();
        }
        if (popUpConfirmationSubscription) {
          popUpConfirmationSubscription.unsubscribe();
        }
        this.ratePlanValidationMsg = '';
      }));
      let cancelPopUpConfirmSubscription = this.popupService.cancelledAction$.subscribe(val => {
        if (val.from === ComponentTypes.RatePlanWarning) {
          dontContinueBooking = true;
        }
        if (val.from === ComponentTypes.AlertNegotiation) {
          closepriorpopup = false
          dialogRef.close();
        }
      });

      let popUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.AlertNegotiation) {
          closepriorpopup = true
          this.ps.restrictCloseDialog = false;
          this.ps.isRestrictCloseDialog = false;
          dialogRef.close(priorPopUpComponentType);
        }
      });
    }
  }

  editNegotiationAmount() {
    this.ratePlan = this.partyService.ratePlanForBooking = _.cloneDeep(this.ratePlanOriginal);
    this.ratePlan.SlotList = this.ratePlan?.RatePlanCalculations.map(slot => {
      slot.coverTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.GuestTypeVariant ? slot.RatePlanVariants.GuestTypeVariant.map(coverVariant => {
        coverVariant.coverTypeValue = _.cloneDeep(coverVariant.initialValue)

        return coverVariant;
      }).filter(coverVariant => coverVariant.covers > 0) : [];

      slot.bookingTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.BookingTypeVariant ? slot.RatePlanVariants.BookingTypeVariant.map(bookingVariant => {
        bookingVariant.bookingTypeValue = _.cloneDeep(bookingVariant.initialValue)

        return bookingVariant;

      }).filter(bookingVariant => bookingVariant.bookingTypes > 0) : [];

      slot.AddonAttemptDetails = slot.AddonAttemptDetails ? slot.AddonAttemptDetails.map(addOnattempt => {
        addOnattempt.perItemValue = _.cloneDeep(addOnattempt.TotalAmount)
        return addOnattempt;
      }) : []
      slot.totalBaseRate = (slot.RatePlanVariants.TotalBaseRate + slot.RatePlanVariants.TotalVariants + slot.RatePlanVariants.TotalNegotiation) / this.partyService.privateLessonBookingData.BookingSize;
      slot.totalAmount = (slot.RatePlanVariants.TotalBaseRate + slot.RatePlanVariants.TotalVariants + slot.RatePlanVariants.TotalNegotiation);
      if (slot && slot.AddonAttemptDetails && slot.AddonAttemptDetails.length) {
        slot.totalAddOnAmount = _.sumBy(slot.AddonAttemptDetails, 'perItemValue');
      }
      if (slot) {
        slot.totalAmount = (slot.coverTypeVariants && slot.coverTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function (o) { return o['covers'] * o['coverTypeValue'] }) : slot.bookingTypeVariants && slot.bookingTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function (o) { return o['bookingTypes'] * o['bookingTypeValue'] }) : (slot.totalBaseRate || 0));
      }
      return slot;
    });
  }

  enableGuestFormComponent() {
    this.createSaveRequestObject();
    this.enableGuestComponent = true;
    if (this.partyService.reservationFormGroup.controls.selectedGuest.value) {
      this.changeFormState(true);
    } else {
      this.changeFormState(false);
    }
  }
  ngAfterViewInit() {
    this.ps.previousButtonEnabled$.next(true);
    this.formChipsChange();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.partyService.isGuestComponentLoaded = false;
    this.partyService.partyNotes = [];
    this.partyService.ratePlanForBooking = null;
    this.df.enabledNegotiation = false;
    this.df.negotiateServiceCharge = false;
  }

  createSaveRequestObject() {
    var bookingData = this.partyService.privateLessonBookingData;
    const specailMeals = this._settings.SpecialMeals.filter((meal) => meal.Id === this.partyService.privateLessonBookingData.ActivityId);
    let standbymeal = specailMeals[0].IsForStandbyReservations;
    this.partyService.isStandBy = standbymeal;
    this.partyService.privateLessonBookingDataSaveObj = {
      "Id": (this.data && this.data.Id) ? this.data.Id : null,
      "Size": bookingData.BookingSize,
      "RemindAboutVisit": false,
      "RemindAboutVisitEmail": false,
      "AgreedWithTermsOfUse": true,
      "Contact": {},
      "Slots": this.getRequestObjectSlots(),
      "PartyId": (this.data && this.data.Id) ? this.data.Id : null,
      "SeatingAreaId": null,
      "SeatingTypeId": null,
      "ConfirmationMessageOption": 0,
      "Notes": this.partyService.partyNotes,
      "TableIds": [this.partyService.privateLessonBookingData.Location],
      "InstructorIds": this.partyService.privateLessonBookingData.StaffId,
      "CoverTypes": this.partyService.privateLessonBookingData.CoverTypes,
      "BookingTypes": this.partyService.privateLessonBookingData.BookingTypes,
      "IsCommunalTable": false,
      "FromWebsite": true,
      "Language": 0,
      "Comments": this.negotiationReason,
      "ReservationAttemptId": null,
      "waiverFormTransactionId": null,
      "SecondaryContactsList": null,
      "BookingBehavior": BookingBehavior.PrivateLesson,
      "SpecialMealId": bookingData.ActivityId,
      "StartDate": new Date(this.partyService.privateLessonBookingData.FromDate).toDateString(),
      "EndDate": new Date(this.partyService.privateLessonBookingData.FromDate).toDateString(),
      "NegotiatedAmount": this.ratePlan?.TotalRatePlan > 0 ? this.remainingAmount : 0,
      "TotalPayable": (this.totalPayableAmount || 0) + (this.totalAddOnAmount || 0),
      "TaxAmount": (this.taxAmount || 0) + (this.totalAddOnTaxAmount || 0),
      "AttemptSlots": this.getAttemptSlotObj(this.partyService.privateLessonBookingData.Slots),
      "PackageConfirmationId": (this.data && this.data.PackageConfirmationId) ? this.data.PackageConfirmationId : null,
      "PackageId": (this.data && this.data.PackageId) ? this.data.PackageId : null,
      "HostId": this.data?.Id ? this.data.HostId : JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostId,
      "FinancialEffectId": this.partyService.FinancialEffectId,
      "RetailCardTokenId": this.partyService.RetailCardTokenId,
      "PaymentMethod": this.partyService.bookingPaymentType,
      "summaryState": this.partyService.privateLessonBookingDataSaveObj?.summaryState,
      "SalesContactIds": bookingData?.SalesContactIds,
      MinRateNegotiatedAdjustment:  this.ratePlan?.MinRateAdjustment?.Amount - this.ratePlan?.MinRateAdjustment?.MinRateAdjustmentAmount
    }
    this.instructor = this._settings.Servers.find(s => s.Id == this.partyService.privateLessonBookingDataSaveObj.InstructorIds[0]);
    if (this.remainingAmount) {
      this.setNegotiableRates();
    }
  }
  setPartyPaymentType(data) {
    if (data && this.partyService.privateLessonBookingDataSaveObj) {
      this.partyService.bookingPaymentType = data;
      this.partyService.privateLessonBookingDataSaveObj.PaymentMethod = data;
    }
  }
  setRetailCardTokenId(data) {
    if (data && this.partyService.privateLessonBookingDataSaveObj) {
      this.partyService.RetailCardTokenId = data;
      this.partyService.privateLessonBookingDataSaveObj.RetailCardTokenId = data;
    }
  }

  openRatenegotiationpopup() {
    const componentDetails: ComponentDetails = {
      componentName: RateNegotiationComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: '',
      popupTitle: 'Reason for Negotiation',
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '260px',
      data: {
        title: 'Reason for Negotiation',
        update: 'Proceed',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.RateNegotitaion,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    this.ps.restrictCloseDialog = true;
    this.ps.isRestrictCloseDialog = true;

    let confirmedAction = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.RateNegotitaion) {
        if ((this.partyService.rateNegotiationObject.Payable / 1) < this.totalDepositAmount) {
          this.showWarningPopup(this.translateService.instant('depositAlertMessage'), ComponentTypes.AlertNegotiation, dialogRef, ComponentTypes.RateNegotitaion);
        }
        else {
          this.ps.restrictCloseDialog = false;
          this.ps.isRestrictCloseDialog = false;
          dialogRef.close(ComponentTypes.RateNegotitaion);
        }
      }
    });

    let cancelledActionSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.RateNegotitaion) {
        this.ps.restrictCloseDialog = false;
        this.ps.isRestrictCloseDialog = false;
        dialogRef.close();
      }
    });

    let reasonforNegotiationpopupconfirmaction = dialogRef.afterClosed().subscribe(val => {
      if (confirmedAction) {
        confirmedAction.unsubscribe();
      }

      if (cancelledActionSubscription) {
        cancelledActionSubscription.unsubscribe();
      }

      if (val === ComponentTypes.RateNegotitaion) {
        this.totalPayableAmount = this.partyService.rateNegotiationObject.Payable / 1;
        this.remainingAmount = this.partyService.rateNegotiationObject.Payable - this.ratePlan.TotalRatePlan;
        this.negotiationReason = this.partyService.rateNegotiationObject.Reason;
        this.partyService.privateLessonBookingDataSaveObj.NegotiatedAmount = this.remainingAmount;
        this.partyService.privateLessonBookingDataSaveObj.Comments = this.partyService.rateNegotiationObject.Reason;
        this.partyService.privateLessonBookingDataSaveObj.TotalPayable = this.totalPayableAmount;
        this.calculateNegotiableRate();
      }
    });

    // let reasonforNegotiationpopupconfirmaction = this.popupService.confirmedAction$.subscribe(val => {
    //   if (val === ComponentTypes.RateNegotitaion) {
    //     this.totalPayableAmount = this.partyService.rateNegotiationObject.Payable / 1;
    //     this.remainingAmount = this.partyService.rateNegotiationObject.Payable - this.ratePlan.TotalRatePlan;
    //     this.negotiationReason = this.partyService.rateNegotiationObject.Reason;
    //     this.partyService.privateLessonBookingDataSaveObj.NegotiatedAmount = this.remainingAmount;
    //     this.partyService.privateLessonBookingDataSaveObj.Comments = this.partyService.rateNegotiationObject.Reason;
    //     this.partyService.privateLessonBookingDataSaveObj.TotalPayable = this.totalPayableAmount;
    //     this.calculateNegotiableRate();
    //   }
    // });
  }
  setNegotiableRates() {
    this.totalPayableAmount = this.totalPayableAmount / 1;
    this.remainingAmount = this.totalPayableAmount - this.ratePlan.TotalRatePlan;
    this.partyService.privateLessonBookingDataSaveObj.NegotiatedAmount = this.remainingAmount;
    this.partyService.privateLessonBookingDataSaveObj.TotalPayable = this.totalPayableAmount;
    this.calculateNegotiableRate();
  }
  getRequestObjectSlots() {
    var bookingData = this.partyService.privateLessonBookingData;

    return bookingData.Slots.map((slot, index) => {
      return {
        Time: slot.LocalTime,
        LockId: (this.lockResponse) ? this.lockResponse[slot.LocalTime]?.SlotLockIdDTO?.Id : null,
        Type: 3,
        DurationInMinutes: slot.Duration
      }
    })
  }
  changeFormState(enable) {
    this.ps.formValid$.next(
      {
        isFormValid: enable && this.totalPayableAmount >= 0,
        currentTab: 1
      } as IFormValidDetails
    );
  }

  calculateNegotiableRate() {
    var i = 0;
    var total = 0;
    var taxableItems = [];
    if (this.partyService.privateLessonBookingDataSaveObj.Slots && this.partyService.privateLessonBookingDataSaveObj.Slots.length) {
      this.partyService.privateLessonBookingDataSaveObj.Slots.map(slot => {
        if (slot.NegotiatedAmount) {
          slot.NegotiatedAmount = 0;
        }
      });
    }
    this.partyService.privateLessonBookingDataSaveObj.Slots.forEach(slot => {
      slot.index = i;
      var totalRatePlanAmountForSlot = this.ratePlan.SlotList.find(slt => slt.TimeRange.Start === slot.Time);
      if (totalRatePlanAmountForSlot && totalRatePlanAmountForSlot.TotalAmount) {
        totalRatePlanAmountForSlot.index = slot.index;
        if (i < this.partyService.privateLessonBookingDataSaveObj.Slots.length - 1) {

          slot.NegotiatedAmount = ((this.totalPayableAmount != 0) ? ((this.totalPayableAmount) / this.ratePlan.TotalRatePlan) * totalRatePlanAmountForSlot.TotalAmount : 0);
          total += Number(slot.NegotiatedAmount.toFixed(2));
          slot.NegotiatedAmount -= totalRatePlanAmountForSlot.TotalAmount;
        }
        else {
          slot.NegotiatedAmount = (this.totalPayableAmount - total) - totalRatePlanAmountForSlot.TotalAmount;
        }
        slot.NegotiatedAmount = Number(slot.NegotiatedAmount.toFixed(2));
      }
      i++;
      var taxItem = new TaxableItem();
      taxItem.ActivityId = this.partyService.privateLessonBookingDataSaveObj.SpecialMealId;
      taxItem.Index = slot.index;
      taxItem.StartDate = this.partyService.privateLessonBookingDataSaveObj.StartDate;
      taxItem.EndDate = this.partyService.privateLessonBookingDataSaveObj.EndDate;
      taxItem.RatePlanAmount = totalRatePlanAmountForSlot.TotalAmount + (slot.NegotiatedAmount || 0);
      taxableItems.push(taxItem)
    });
    console.log(this.partyService.privateLessonBookingDataSaveObj.Slots);
    this.calculateTax(taxableItems);
  }

  calculateTax(taxableItems) {

    this.api.GetTaxCalculated(taxableItems).subscribe(data => {
      let newTax = 0; let serviceCharge = 0; let serviceChargeWithTax = 0;
      if (data && data.Payload) {
        var taxresponse = data.Payload as TaxableItem[];
        this.ratePlan.SlotList.forEach(slot => {
          slot.TaxAmount = (taxresponse.find(t => t.Index == slot.index)?.TaxAmount || 0);
          slot.ServiceChargeAmount = (taxresponse.find(t => t.Index == slot.index)?.ServiceChargeAmount || 0);
          newTax = newTax + slot.TaxAmount;
          serviceCharge = serviceCharge + slot.ServiceChargeAmount;
          serviceChargeWithTax = serviceChargeWithTax + slot.serviceChargeWithTax;
        });
        this
        this.ratePlan.TotalTaxAmount = this.taxAmount = (newTax);
        this.ratePlan.TotalServiceChargeAmount = this.totalServiceCharge = (serviceCharge);
        this.ratePlan.TotalTaxOnServiceChargeAmount = this.totalServiceChargeWithTax = (serviceChargeWithTax);
      }
    })

  }


  getAttemptSlotObj(timeslots) {
    return timeslots.map((slot) => {
      return {
        LocalTime: slot.LocalTime,
        Duration: slot.Duration,
        ReservationAttemptId: null
      }
    })
  }

  ShowRestPolicyDetailsClick() {
    this.ShowRestPolicyDetails(this.restaurantPolicy);
  }

  bindGuestPartyNotes() {
    this.config = [];
    this.partyNotes.forEach((partyNote, index) => {
      const tag = {
        type: 'chip',
        name: `preferredPartyTags${index}`,
        inputType: 'text',
        label: partyNote.CategoryName,
        class: 'guest-view__preferred-tags-edit', // Do not modify the className, there is a dependency for editGuest details.
        options: this.getOptions(partyNote.partyNotes, partyNote.Color),
        multipleChip: true
      }
      this.config.push(tag);
    })
    if (_.get(this.data, ['Notes'], []).length) {
      this.customPartyNotes = this.data.Notes.filter(note => !note.RelatedId && note.Type == PartyNoteType.FreeFormNote);
      if (this.customPartyNotes && this.customPartyNotes.length) {
        const tag = {
          type: 'chip',
          name: `partyfreeTags`,
          inputType: 'text',
          label: 'freeNoteText',
          class: 'guest-view__free-tags-edit',
          options: this.getFreeTextOptions(),
          multipleChip: true
        }
        this.config.unshift(tag);
      }
    }

  }

  getFreeTextOptions() {
    let freeTextOptions = [];
    let freeNotes = this.data.Notes.filter(notes => !notes.RelatedId);
    freeNotes.forEach((notes) => {
      freeTextOptions.push({
        Id: notes.Id,
        Name: notes.Text,
        Icon: 'None',
        Value: ''
      })
    })
    return freeTextOptions;
  }

  getOptions(partyNotes, categoryColor): any {
    let configOptios = [];
    partyNotes.forEach((notes) => {
      configOptios.push({
        Id: notes.Id,
        Name: notes.Text,
        Icon: notes.Icon,
        Value: '',
        color: categoryColor
      });
    })
    return configOptios;
  }

  formChipsChange() {
    this.subscriptions.add(this.formChipService.formChipChange$.subscribe((selectedChips) => {
      if (selectedChips.name === 'partyfreeTags') {
        selectedChips.data.forEach((tags) => {
          if (!tags.setSelected) {
            const index = this.partyService.partyNotes.findIndex((note) => note.Id === tags.Id);
            if (index > -1) {
              this.partyService.partyNotes = this.partyService.partyNotes.filter(note => note.Id != tags.Id);
            }
          } else {
            const index = this.partyService.partyNotes.findIndex((note) => note.Id === tags.Id);
            if (index == -1) {
              const freeTextNotes: PartyNoteDTO = {} as PartyNoteDTO;
              freeTextNotes.Id = tags.Id;
              freeTextNotes.Text = tags.Name;
              freeTextNotes.Type = PartyNoteType.FreeFormNote;
              this.partyService.partyNotes.push(freeTextNotes);
            }
          }
        });
      } else if (this.config.filter((fieldConfig) => fieldConfig.name == selectedChips.name).length > 0) {
        const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
        this.selectedPartyPreferenceTags = selectedChips.data;
        const preferredTagsIndex = this.config.findIndex(fieldConfig => fieldConfig.name == selectedChips.name);
        const otherTags = selectedChips.data.filter(chip => chip.Name == 'Others');
        if (otherTags && otherTags.length > 0) {
          let otherNotesName = `otherNotes${otherTags[0].Id}`;
          let isOtherNoteAvailable = this.config.filter((config) => config.name == otherNotesName).length;
          const otherNotesControl = this.components.last.config.filter((config) => config.name == otherNotesName);
          if (!isOtherNoteAvailable && otherTags.length > 0 && otherTags[0].setSelected) {
            const otherNotes: FieldConfig = {
              name: otherNotesName,
              type: 'textarea',
              inputType: 'text',
              label: 'notes',
              showHint: true,
              class: 'guest-data__party-other-tags',
              charLength: 1000,
              isHidden: false
            }
            this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
          } else if (isOtherNoteAvailable) {
            if (otherTags[0].setSelected) {
              otherNotesControl[0].isHidden = false;
              const otherNotes = this.partyService.partyNotes.find((note) => note.RelatedId == otherTags[0].Id);
              if (!otherNotes) {
                const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
                let editPartyNote = null;
                if (this.data) {
                  editPartyNote = this.data.Notes.find(note => note.RelatedId == otherTags[0].Id);
                  if (editPartyNote) {
                    this.partyService.partyNotes.push(editPartyNote);
                  }
                } else {
                  partyNotes.Id = null;
                  partyNotes.Type = PartyNoteType.FreeFormNote;
                  partyNotes.RelatedId = otherTags[0].Id;
                  partyNotes.Text = this.components.last.form.value[otherNotesName];
                  this.partyService.partyNotes.push(partyNotes);
                }
              }
              // this.partyService.partyNotes = this.partyService.partyNotes.filter((note) => note.RelatedId != otherTags[0].Id)
            } else {
              otherNotesControl[0].isHidden = true;
              this.partyService.partyNotes = this.partyService.partyNotes.filter((note) => note.RelatedId != otherTags[0].Id)
            }
            // this.config.splice(preferredTagsIndex + 1, 1);

          }
        }
        this.config = [...this.config];
        this.selectedPartyPreferenceTags.forEach((_partyNotes) => {
          if (_partyNotes.Name !== 'Others') {
            const preferredData = this.partyService.partyNotes.filter((note) => note.RelatedId === _partyNotes.Id)[0];
            if (_partyNotes.setSelected && !preferredData) {
              partyNotes.RelatedId = _partyNotes.Id;
              partyNotes.Text = _partyNotes.Name;
              partyNotes.Id = _partyNotes.noteId ? _partyNotes.noteId : null;
              partyNotes.Type = PartyNoteType.PredefinedNote;
              this.partyService.partyNotes.push(partyNotes);
            } else if (!_partyNotes.setSelected) {
              const index = this.partyService.partyNotes.findIndex((note) => note.RelatedId === _partyNotes.Id);
              if (index > -1) {
                this.partyService.partyNotes.splice(index, 1);
              }
            }
          }
        });
      }
      this.enableGuestFormComponent();
    }));

  }
  formChangeSubscribe() {
    this.components.forEach((x, index) => {
      this.subscriptions.add(x.form?.valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(value => {
          this.formChangeHandler(value);
        }));
    })
    if(this.negotiateReasonForm?.form){
      this.subscriptions.add(this.negotiateReasonForm.form.valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(value => {
          this.formChangeHandler(value);
      }));
    }
  }

  formChangeHandler(value){
    this.bindInternalPartyNotes(value);
    this.createSaveRequestObject();
    this.enableNegotiationOption(value);
    this.applyTaxForNegotiated(value);
    this.addNegotiationReason(value);
  }

  addNegotiationReason(value) {
    if (value[this.rateNegotiateConfig[0].name]) {
      this.negotiationReason = value[this.rateNegotiateConfig[0].name];
      this.partyService.privateLessonBookingDataSaveObj.Comments = this.negotiationReason;
      this.applyNegotiationBtn.disbaledproperity = this.negotiationReason == '' ? true : false;
      // this.partyService.rateNegotiationObject = {       
      //   Reason: value[this.rateNegotiateConfig[0].name].reasonText.find(reason => reason.commentType == CommentType.Negotiation)
      // }
    }
  }
  bindInternalPartyNotes(data: any) {
    for (let preferenceProperty in data) {
      if (preferenceProperty.includes('otherNotes')) {
        const otherNotesInConfig = this.config.find(configProp => configProp.name == preferenceProperty);
        if (otherNotesInConfig && !otherNotesInConfig.isHidden) {
          const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
          partyNotes.Text = data[preferenceProperty];
          const preferencePropertyId = preferenceProperty.match(/(\d+)/);
          if (preferencePropertyId) {
            partyNotes.RelatedId = Number(preferencePropertyId[0]);
            const preferredOtherTags = this.partyService.partyNotes.filter((note) => note.RelatedId === partyNotes.RelatedId);
            if (preferredOtherTags && preferredOtherTags.length > 0) {
              partyNotes.Id = preferredOtherTags[0].Id ? preferredOtherTags[0].Id : null;
              partyNotes.Type = PartyNoteType.FreeFormNote;
            } else {
              partyNotes.Id = null;
              partyNotes.Type = PartyNoteType.FreeFormNote;
            }
          }
          this.partyService.partyNotes = this.partyService.partyNotes.filter(note => note.RelatedId != partyNotes.RelatedId);
          this.partyService.partyNotes.push({ ...partyNotes });
        }
      }
    }
    //this.changeFormState(true)
    // if (!this.holdFormValidation) {
    //   this.partyService.reservationFormGroup.markAsDirty();
    //   this.partyService.reservationFormGroup.updateValueAndValidity();
    // }
    // this.updateFormValidity();
  }



  onNegotiate(forGrandTotal?) {
    this.partyService.privateLessonBookingDataSaveObj.summaryState = forGrandTotal ? 'valid' : 'invalid';
    this.grandTotalValidated = forGrandTotal || false;
    this.changeFormState(this.partyService.reservationFormGroup.controls.selectedGuest.value);
    this.ratePlan.SlotList.forEach(slot => this.negotiateTotalSessionCharge(slot));
    this.subtotal = +this.ratePlan.SlotList.reduce((res, slot) => res + slot.negotiatedTotalSessionCharge, 0) + (this.ratePlan?.MinRateAdjustment?.Amount || 0);
    this.negotiatedDiscount = this.activitiesAmount - this.subtotal;
  }

  negotiateTotalSessionCharge(updatedSlot?) {
    //+ updatedSlot.RatePlanVariants.TotalBaseRate
    if (updatedSlot) {
      let baseRateValue = 0;
      let negotiatedTotalCoverValue = updatedSlot.coverTypeVariants && updatedSlot.coverTypeVariants.length ? updatedSlot.coverTypeVariants.reduce((res, cover) => res + ((cover.coverTypeValue) * cover.covers), 0) : 0;
      let negotiatedTotalBookingTypeValue = updatedSlot.bookingTypeVariants && updatedSlot.bookingTypeVariants.length ? updatedSlot.bookingTypeVariants.reduce((res, bookingType) => res + ((bookingType.bookingTypeValue) * bookingType.bookingTypes), 0) : 0;
      //if (!updatedSlot.coverTypeVariants?.length && !updatedSlot.bookingTypeVariants?.length) {
        baseRateValue = updatedSlot.sessionCharge;
      //}
      let negotiatedTotalAddonValue = updatedSlot.AddonAttemptDetails.reduce((res, addOn) => res + (addOn.perItemValue * addOn.Quantity), 0);
      let totalLocationVariant = +(updatedSlot.RatePlanVariants ? (updatedSlot.RatePlanVariants.locationAmount || 0) : 0);
      let totalInstructorVariant = +(updatedSlot.RatePlanVariants ? (updatedSlot.RatePlanVariants.instructorAmount || 0) : 0);
      let totalMembershipVariant = updatedSlot.membershipAmount || 0;
      updatedSlot.negotiatedTotalSessionCharge = +(totalLocationVariant + totalInstructorVariant + negotiatedTotalCoverValue + negotiatedTotalBookingTypeValue + negotiatedTotalAddonValue + baseRateValue + totalMembershipVariant);

    }
  }

  mapPartyNotes() {
    const preferredTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('preferredPartyTags'));
    this.data.Notes.forEach((note) => {
      preferredTags.forEach((fieldConfig) => {
        if (note.Type == PartyNoteType.FreeFormNote || note.Type == PartyNoteType.PredefinedNote || note.Type == PartyNoteType.SpecialRequest) {
          const filteredNotes = fieldConfig.options.find(data => data.Id === note.RelatedId);
          if (filteredNotes) {
            filteredNotes.setSelected = true;
            filteredNotes.noteId = note.Id;
            if (filteredNotes.Name.includes('Others')) {
              let otherNotesName = `otherNotes${filteredNotes.Id}`;
              if (filteredNotes.setSelected) {
                const otherNotes: FieldConfig = {
                  name: otherNotesName,
                  type: 'textarea',
                  inputType: 'text',
                  label: 'notes',
                  showHint: true,
                  class: 'guest-data__party-other-tags',
                  charLength: 1000,
                  value: note.Text
                };
                const preferredTagsIndex = this.config.findIndex(preferenceConfig => preferenceConfig.name == fieldConfig.name);
                this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
              }
            }
            this.partyService.partyNotes.push(note);
          }
        }
      })
    });
    this.config = [...this.config];
  }

  mapCustomPartyNotes() {
    const freeTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('partyfreeTags'));
    this.data.Notes.forEach(note => {
      freeTags.forEach(fieldConfig => {
        const filteredNotes = fieldConfig.options.find(data => data.Id === note.Id);
        if (filteredNotes) {
          filteredNotes.setSelected = true;
          this.partyService.partyNotes.push(note);
        }
      })
    });
  }


  applyNegotiation() {
    this.applyNegotiationClicked = true;
    this.applyNegotiationBtn.disbaledproperity = true;
    this.firstCall = false;
    this.setRateplanCalculationObject();
  }

  setRateplanCalculationObject(resetData?){
    let { ActivityId, Slots, CoverTypes, BookingTypes, Location, BookingSize, StaffId } = this.partyService.privateLessonBookingData;
    this.setRatePlanCalculation(ActivityId, Slots, CoverTypes, BookingTypes, Location, BookingSize, StaffId,null,resetData);
  }

  cancelNegotiation() {
    this.totalServiceCharge = _.cloneDeep(this.ratePlan.TotalServiceChargeAmount);
    this.df.negotiateServiceCharge = false;
    this.df.enabledNegotiation = false;
    this.negotiationReason = '';
    this.negotiateReasonForm.form.reset();
    if(this.data && this.data.bookingRatePlan?.Comments){
      this.negotiateReasonForm.form.controls?.reasonText?.setValue(this.setNegotiationReason());
    }
    else{
      this.negotiateReasonForm.form.controls?.reasonText?.setValue('');
    }
    //  this.perItemEditable = false;
    //  this.negotiationConfigForm.form.controls.negotiation.setValue(false);
    // this.editNegotiationAmount();
    this.setRateplanCalculationObject(true);
  }
  onBlur(eve) {
    eve.target.value = Number(eve.target.value).toFixed(2);
  }
  setAdjustedMinRate() {
    this.partyService.privateLessonBookingData.MinRateAdjustment = this.adjustedMinRate;
    if(this.partyService.privateLessonBookingData){
      this.partyService.privateLessonBookingData.MinRateNegotiatedAdjustment = this.ratePlan.MinRateAdjustment.Amount - this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount;
    }
  }
}
