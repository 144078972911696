import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { CacheService } from "@app/core/services/cache.service";
import { PopupService } from "@app/popup-module/popup.service";
import { ClassType, Status } from "@app/shared/constants/commonenums";
import { DynamicFormComponent } from "@app/shared/dynamicform/dynamic-form/dynamic-form.component";
import { FieldConfig } from "@app/shared/dynamicform/models/field-config.interface";
import { FloorPlanDTO, LayoutDTO, LocationGroupDTO, SettingsDTO } from "@app/shared/models/RestaurantDTO";
import { PartyService } from "@app/shared/services/party.service";
import { SettingsService } from "@app/shared/services/settings.service";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";
import moment from "moment";
import { Subscription } from "rxjs";
import { formValidation } from "../activity-creation/session-mapping/session-mapping.component";

@Component({
    selector: 'modify-session',
    templateUrl: './modify-session.component.html',
    styleUrls: ['./modify-session.component.scss']
})
export class ModifySessionComponent implements OnInit {

    sessionData;
    activityData;
    basicSettings;
    locationList = [];
    staffList = [];
    allocationList = [];
    calendarDetailsForm = {} as {StartDate: string, EndDate: string};
    classType = ClassType.Session;
    subscriptions: Subscription = new Subscription();
    sessionStrengthDetailsConfig: FieldConfig[] = [];
    @ViewChild('maxStrengthDetails', { static: true }) maxStrengthDetailsDetailsForm: DynamicFormComponent;
    warningMessgaes: string;
    requiredFieldValidation: boolean = false;
    InvalidSessionDays = [];
    locationGroup : LocationGroupDTO[];

    batchRows = [];
    sessionGroups = [];

    constructor(@Inject(MAT_DIALOG_DATA) public dialogData, private dialog: MatDialog, private ts: TranslateService, private partyService: PartyService,
        public ss: SettingsService, private pp: PopupService) {        
    }

    ngOnInit(): void {
        this.sessionData = this.dialogData.componentDetails.popupInput[0].sessionData;
        this.activityData = this.dialogData.componentDetails.popupInput[0].activityData;
        this.classType = this.sessionData.ClassType;
        this.locationGroup = this.partyService.cs.settings.value?.LocationGroups?.filter(group => group.StatusCode == Status.Approved) || [];
      
        this.calendarDetailsForm = {
            StartDate: moment(this.sessionData?.Date).format('YYYY-MM-DD') + "T00:00:00.000",
            EndDate: moment(this.sessionData?.Date).format('YYYY-MM-DD') + "T00:00:00.000",
        }

        this.subscriptions.add(this.ss.GetLessonByIdAndRestaurant(this.sessionData?.SpecialMealId || 0).subscribe(data => {
            this.basicSettings = data.Payload;
            this.setMasterData();
        }));

        this.setActivitySessionStrength();
    }

    ngAfterViewInit(): void {
        this.partyService.modeifySingleSessionForm = this.maxStrengthDetailsDetailsForm?.form;

        this.maxStrengthDetailsDetailsForm?.form.valueChanges.subscribe(x => {
            this.requiredFieldValidation = !this.maxStrengthDetailsDetailsForm?.form.valid;
            this.updateSaveBtnStatus();
        })
    }

    setMasterData() {
        this.allocationList = this.basicSettings.AllocationMappings?.map(type => { return { id: type.AllocationTypeId, value: type.AllocationName } });
        this.allocationList.unshift({ id: 0, value: this.ts.instant('OpentoAll') });

        if (this.basicSettings.SessionGroups?.length) {
            this.basicSettings.SessionGroups.forEach(group => {
               this.addBatchName(group);
            })
            setTimeout(() => { this.SaveSessionGroups() }, 500)
        }

        // this.staffList = this.basicSettings.Instructors.map(options => { return { id: options.Id, value: options.Name, IsTemplate: options.IsTemplate } });
        this.locationList = this.basicSettings?.Locations?.map(location => { return { id: location.Id, value: location.Name, IsTemplate: location.IsTemplate } });
    }

    addBatchName(group?) {
        // let row = [];
        let newBatch = {
           Name: group?.Name || '',
           SelectedAllocationId: group?.SelectedAllocationId || 0,
           IsDeleted: group?.IsDeleted || false,
           IsMapped: group?.IsMapped || false,
           Id: group?.Id || 0,
           Index: group.tempId,
           isNew: group.isNew,
        };
        this.batchRows.push(newBatch);
     }

     SaveSessionGroups() {
        let sessionGroups = this.batchRows;
        sessionGroups = sessionGroups.filter(row => row.Name !== '' && row.Name !== null);
        sessionGroups = sessionGroups.map(row => { row.Id = row.Id ? row.Id : row.Index; return row });
        this.sessionGroups = sessionGroups || [];
        // if (this.sessionGroups.length == 0 && this.classType == ClassType.Class) {
        //    this.warningMessgaes = this.ts.instant('batchNameWarning');
        // } else {
        //    this.warningMessgaes = ''
        // }
    }

    setActivitySessionStrength() {
        this.sessionStrengthDetailsConfig = [
            {
                type: 'input',
                name: this.classType == ClassType.Duration ? 'MaxDuration' : 'MaxActivityStrength',
                label: this.classType == ClassType.Duration ? this.ts.instant('MaxDuration') : this.ts.instant('MaxActivityStrength'),
                inputType: 'number',
                appearance: false,
                isTranslate: true,
                validation: [Validators.required, Validators.min(1)],
                value: this.classType == ClassType.Duration ? (this.sessionData?.MaxDuration || null) : (this.sessionData?.MaxActivityStrength || 1),
                class: 'w-100',
                disableErrorStateMatcher: true,
                isStarSymbolRequired: true,
                hide: this.classType == ClassType.Class,
                disabled: this.classType == ClassType.Class
            },
            {
                type: 'textarea',
                name: 'Reason',
                label: this.ts.instant('ModifyReason'),
                inputType: 'text',
                showHint: true,
                charLength: 400,
                value: this.sessionData?.Reason || '',
                // textAreaAppearance: 'outline',
                class: 'activity-description',
                rows: 2
                
            },
            {
                type: 'checkbox',
                name: 'SendMail',
                value: true,
                placeholder: this.ts.instant('SendMailConfirmation'),
                class: 'session-edit-send-mail-chekbox'
            }
        ];

        if(this.classType == ClassType.Class) this.sessionStrengthDetailsConfig.splice(0,1);
    }

    sessionChanges(data: any) {
        this.partyService.modifySingleSessionMappingValue = [data];
        this.dyanamicSessionValidation(data);
    }

    dyanamicSessionValidation(data: any) {
        const result = formValidation(this.classType, [data], null , null , this.ts);
        this.requiredFieldValidation = result.requiredFieldValidation;
        // this.InvalidSessionDays = result.InvalidSessionDays;
        // this.warningMessgaes = result.warningMessgaes != '' ? this.ts.instant(result.warningMessgaes) : null;

        console.log(result);        

        this.updateSaveBtnStatus();
    }

    updateSaveBtnStatus() {
        this.pp.saveBtnEnable$.next(!this.requiredFieldValidation && this.maxStrengthDetailsDetailsForm.form.valid)
    }
}