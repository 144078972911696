<!-- <div class="notification-view">
    <app-tabs [tabsConfig]="tabDetails" (tabIndexChange)="tabChange($event)"></app-tabs>
    <div class="notification-view__actions">
        <span class="notification-view__view-all" (click)="viewAll()">{{'viewAll' | translate}}</span>
        <span class="notification-view__clear-all" (click)="clearList()">{{'clearAll' | translate}}</span>
    </div>
</div> -->

<div class="notifications">

    <div class="notifications__header seat-popover-header">{{'headerAlertMessage'|translate}} ({{alertTableDataSource?.data?.length}})</div>
    <div  class= "notifications__table-section" *ngIf="alertTableDataSource?.data?.length > 0">
      <ng-scrollbar>
    <table mat-table  [dataSource]="alertTableDataSource" class="notifications__table-view" >

        <ng-container matColumnDef="party">
          <th mat-header-cell *matHeaderCellDef class="notifications__table-header seat-grid-header"> {{'partyText' | translate}} </th>
          <td mat-cell *matCellDef="let auditLog" class="notifications__table-data seat-grid-content"> {{auditLog.PartyFullName ? auditLog.PartyFullName : ('unnamed' | translate)}} </td>
        </ng-container>


        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="notifications__table-header seat-grid-header"> {{'actionText' | translate}} </th>
          <td mat-cell *matCellDef="let auditLog" class="notifications__table-data seat-grid-content"> {{auditLog.ActionDescription}} </td>
        </ng-container>


        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef class="notifications__table-header seat-grid-header"> {{'time' | translate}} </th>
          <td mat-cell *matCellDef="let auditLog" class="notifications__table-data seat-grid-content"> {{auditLog.Time | localizedDate:'LT'}} </td>
        </ng-container>


        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef class="notifications__table-header seat-grid-header"></th>
          <td mat-cell *matCellDef="let auditLog" class="notifications__table-data seat-grid-content">
              <activities-app-button *ngIf="auditLog.ActionType != auditableActionType.Remove" [buttontype]="auditLogBtn" (valueChange)='viewReservation(auditLog)'></activities-app-button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="alertColumn;sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: alertColumn;"></tr>

      </table>
    </ng-scrollbar>
    </div>
      <div *ngIf="alertTableDataSource?.data?.length == 0" class="notifications__no-data-text seat-secondary-text">{{'noDataFound' | translate}}</div>

</div>
