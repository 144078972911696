<!-- Personal Details form of Personal Info Tab on selecting AddContact on Guest Book-->
<div class="personal-info">
  <div class="personal-info__profile-view">
    <!-- <img src="assets/images/user_image.jpg" class="personal-info__profile-image" alt="profile_pic"> -->
    <div>
      <!-- <input accept="image/*" (change)="fileEvent($event)" type="file" id="fileId" hidden> -->

      <span *ngIf="url == ''" class="personal-info__upload-image icon-avatar">
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
      </span>
    </div>

    <a *ngIf="url == ''" (click)="compressFile()"  class="personal-info__add-image">{{'guestBookAddImage' | translate}}</a>
    <span class="personal-info__image-view" *ngIf="url != ''">
      <div class="personal-info__form-image">
        <img class="personal-info__load-image" [src]="url" />
      </div>
    </span>
    <div style="display: flex;justify-content: center;">
      <!-- <a *ngIf="url != ''" (click)="uploadImage()" class="personal-info__edit-image">Edit</a>
      <span *ngIf="url != ''" class="personal-info__image-divider" style="">|</span> -->
      <a *ngIf="url != ''" (click)="deleteImage()" class="personal-info__edit-image">{{'guestBookRemoveImage' | translate}}</a>
    </div>
    <br />
  </div>
  <div class="personal-info__name-error" *ngIf="isErrorMessageAvailable">
    <span class="personal-info__error-msg rs-Roboto-Regular-14">{{ 'nameMandatory' | translate}}</span>
  </div>
  <div class="personal-info__personal-form" >
  <dynamic-form  [ngClass]="showAliasName ? 'alias-name' : 'personal-info' " [config]="config" #form="dynamicForm">
  </dynamic-form>
</div>
  <!-- <span *ngIf="retentionCheckboxVisibility" class="dataRetentionPolicy" (click)="ShowPolicyDetails()">{{'dataRetentionPolicy' | translate}}</span> -->
</div>