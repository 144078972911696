<div class="server-view">
  <div class="server-view__header">
    <!-- Not required for this release [Build 3]  -->
    <!-- <div class="server-view__filter">
      <span [popover]="myPopover">
        <i class="icon-Filter"></i>
      </span>
    </div> -->
    <div class="server-view__sorting" (click)="sort()">
      <span class="icon-Ascending rs-fs-25" *ngIf="isAscending"></span>
      <span class="icon-Descending rs-fs-25" *ngIf="!isAscending"></span>
    </div>
    <!-- Not required for this release [Build 3]  -->
    <!-- <popover-content #myPopover placement="auto bottom" [animation]="true" [closeOnClickOutside]="true">
      <div class="w-100 reset_div ">
        <span class="float-left" (click)="resetFilter()">{{'serverResetAllText' | translate}}</span>
        <span class="float-right icon-Group-584" (click)="resetFilter()"></span>
      </div>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let filterItem of filterOptions;let i =index">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div style="display: flex;align-items: center; font-size: 16px;">
                <span style="padding-right: 10px;">{{filterItem.name}}</span>
                <span class="selected_filter">{{filterItem.options | showCount}}</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="p-0 m-0 list-unstyled">
            <li (click)="selectedFilter(item)" class="d-flex" style="align-items: center;"
              *ngFor="let item of filterItem.options">
              <svg class="checkmark gray_checkmark " viewBox="0 0 52 52">
                <circle class="checkmark__circle gray_circle" cx="26" cy="26" r="25" fill="gray" />
                <path class="checkmark__check gray_path" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
              <svg *ngIf="item.status" class="checkmark " viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
              <span class="pl-2 option_name">{{item.optionName}}</span>
            </li>
          </ul>
        </mat-expansion-panel>

      </mat-accordion>
    </popover-content> -->
    <div class="server-view__searching">
      <dynamic-form [config]="searchConfig" #form="dynamicForm" >
      </dynamic-form>
    </div>
  </div>
  <div class="server-view__server-data" *ngIf="serverInfoHolder?.length > 0">
    <div #scrollingBlock id="server-view-list" class="server__scroll-block h-100">
      <virtual-scroller [parentScroll]="scrollingBlock" #scroll [items]="serverInfoHolder" id="server-view-grid">
        <div *ngFor="let item of scroll.viewPortItems; trackBy: trackFunction;" class="server-view__server-list"
        [ngClass]="{'disabled-server':editableServerId !=-1 &&editableServerId != item.Id}">
        <div class="server-view__display-div pt-1">
          <div class="server-view__basic-info">
            <div class="server-view__image-view">
              <img class="server-view__profile-image" src="assets/images/user_image.jpg" alt="profile_pic">
              <div *ngIf="item?.StandaloneTables != null && item?.StandaloneTables?.length > 0" class="server-view__guest-initials rs-Roboto-Regular-10" [ngStyle]="{background: item.color}">
                <span>{{ item.Name | initialFormatter:item.LastName : 'server' }}</span>
              </div>
            </div>
            <div class="server-view__guest-details">
              <div class="server-view__guest-info">
                <div class="server-view__guest-name seat-secondary-text">{{ item.Name + " "+ ((item.LastName)?item.LastName:'')}}</div>
                <!-- <div *ngIf="item.StandaloneTables.length === 0" class="">
                  <span class="free_text ">{{'serverFreeUntilTomorrowText' | translate}}</span>
                </div> -->
                <div *ngIf="item?.StandaloneTables != null && item?.StandaloneTables?.length > 0" class="">
                  <span class="server-view__free-text rs-Roboto-Regular-14">
                    {{item.StandaloneTables.length}}
                    <i class="icon-TableServer rs-fs-14"></i>
                    <!-- {{item.StandaloneTables.length === 1 ? ('popUpTable' | translate) : ('tablesMenuText' | translate) }} -->
                  </span>
                </div>
                <div *ngIf="item.status == 'assigned' && i != editIndex" class=" icon_div ">
                  <!----------------------------- for later release ------------------------------>
                  <!-- <span class="people_icon "  style="    padding-right: 5px;">{{item.tablesCount}}</span>
                  <i class="icon-Group-592  " style="font-size: 17px;    padding-right: 7px;"></i>

                  <span class="people_icon " style="    padding-right: 5px;">{{item.peopleCount}}</span>
                  <i class="icon-add-contact " style="font-size: 13px;"></i> -->
                </div>
              </div>
              <div class="server-view__tables-list" *ngIf="item?.StandaloneTables != null && item?.StandaloneTables?.length > 0">
                <div class="server-view__tables" >
                  <div *ngFor="let table of item.StandaloneTables; let i = index" >
                    <span class="server-view__table-number" *ngIf="i < 5" #tooltip="matTooltip" matTooltipClass="tooltip-class" 
                    [matTooltip]="table.Name" (click)="tooltip.toggle()">{{table.Name}}</span>
                    <!-- <a *ngIf="i === 5" [popover]="tablePopover" class="seat-secondary-text rs-ml-5 server-view__view-more">{{'viewMore' | translate}}</a> -->
                    <a *ngIf="i === 5" [ngbPopover]="tablePopover" placement="bottom-right" [autoClose]="'outside'"
                     class="seat-secondary-text rs-ml-5 server-view__view-more">{{'viewMore' | translate}}</a>
                  </div>
                </div>
                <ng-template #tablePopover>
                    <div class="server-view__tables-assigned">
                      <div *ngFor="let table of item.StandaloneTables; let i = index">
                        <span *ngIf="i >= 5" #tooltip="matTooltip" matTooltipClass="tooltip-class" 
                          [matTooltip]="table.Name"  class="server-view__table-number" (click)="tooltip.toggle()">{{table.Name}}
                        </span>
                      </div>
                    </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="server-view__action-div">
          <activities-app-button class="server-view__action-btn" [buttontype]="editBtn"
            *ngIf="item?.StandaloneTables != null && item?.StandaloneTables?.length > 0 && editableServerId !== item.Id"
            (valueChange)='editTables($event, item)'>
          </activities-app-button>
          <activities-app-button class="server-view__action-btn" [buttontype]="assignBtn"
            *ngIf="item?.StandaloneTables == null || item?.StandaloneTables?.length == 0 && editableServerId !== item.Id"
            (valueChange)='assignServer($event, item)'></activities-app-button>
          <activities-app-button class="server-view__action-btn" [buttontype]="offDutyBtn"
            *ngIf="editableServerId !== item?.Id && item?.StandaloneTables != null && item?.StandaloneTables?.length > 0"
            (valueChange)='offDuty(item)'></activities-app-button>
          <activities-app-button class="server-view__action-btn" [buttontype]="switchServerBtn"
            *ngIf="item?.StandaloneTables != null && item?.StandaloneTables.length > 0 && editableServerId !== item.Id"
            (valueChange)='switchServer(item)'></activities-app-button>
          <activities-app-button class="server-view__action-btn" [buttontype]="doneBtn"
            *ngIf="ss?.editableMode && editableServerId === item.Id" (valueChange)='done()'></activities-app-button>
          <activities-app-button [buttontype]="cancelBtn" *ngIf="ss?.editableMode && editableServerId === item.Id" (valueChange)='cancel()'>
          </activities-app-button>
        </div>
        <svg *ngIf="inside" class="checkmark position-absolute" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
        </div>
      </virtual-scroller>
    </div>
  </div>
</div>
