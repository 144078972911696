import { Injectable } from '@angular/core';
import * as Forge from 'node-forge';

@Injectable({
  providedIn: 'root',
})
export class RSAHelper {
  public static publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApKJKCBeDitoJn4Ae9mPhNqXX2Y+VKXcoVka5Up7B30HhFEooWR9CAah6I4mv2g2AKKAGFVE7kuUBe+Yk241HaIqxpRet5YglruOShhFEgekKrYejvmc5/prY8x3iors9tEcZ32tMwY2tdxY+q4GwisTUUJAYdRWiffV8DTVZVmrniGpLBCA5e8Yz1eP9JgCPMVJrm7Psgweh6U4oB/lNsh6wTXEh2XMpf5gcQWhaGJIPAPa4MrGoDK1lPxRwabPhHsLq52JMAqPo6AputXQmsiGNLBsXHdT4dOHnml9dDYLxo3uQg8aSQcz7Bd2OTHpe+J0N08zg6Lq0kBywvjvpIQIDAQAB
  -----END PUBLIC KEY-----`;

  constructor() { }

  public static encryptWithPublicKey(valueToEncrypt: string): string {
    const rsa = Forge.pki.publicKeyFromPem(this.publicKey);
    return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
  }
}
