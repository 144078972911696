import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder,  UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ShopDialogPopUp } from '../../shop-dialog-popup/shop-dialog-popup.component';
import { RedemptionTypeToHandle } from 'src/app/retail/shared/service/payment/payment-business.model';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ShopBussinessService } from '../../shop-business.service';


@Component({
  selector: 'app-cmspatron-selection-dialog',
  templateUrl: './cmspatron-selection-dialog.component.html',
  styleUrls: ['./cmspatron-selection-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CMSPatronSelectionDialogComponent implements OnInit {
  ShopCaptions: any;
  cmsPlayersForm: UntypedFormGroup;
  @Input() datainput: any;
  cmsPlayers: any[] = [];
  selectedPlayer: any;
  redemptionTypeMap: any = RedemptionTypeToHandle;

  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public dialogRef: MatDialogRef<ShopDialogPopUp>, public _sbs: ShopBussinessService) {
    this.cmsPlayersForm = this.fb.group({
      cmsPlayerSelection: ''
    })
    this.ShopCaptions = this.localization.captions.shop;    
  }

  ngOnInit() {
    this.cmsPlayers = this.datainput;
  }

  selectPatron(playerInfo){
    this.selectedPlayer = playerInfo;
  }

  closeDialog(){
    if (this.selectedPlayer) {
      this.dialogRef.close(this.selectedPlayer);  
    }    
  }

}
