
import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

@Directive({
    selector: '[duplicateItem]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DuplicateRetailItemDirective, multi: true }]
})
export class DuplicateRetailItemDirective implements Validator {
    @Input('duplicateItem') value: string;

    validate(control: AbstractControl): { [key: string]: any } | null {
        if (this.value && control.value && control.value === this.value) {
            return { 'invalid': true };
        }
        return null;
    }    

}
