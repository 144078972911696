<section id="create-modal">
  <header>
      <div class="create-header-sect">
          <h4 class="ag_state--headers ag_cursor--pointer" (click)="back($event)">
              <i  aria-hidden="true" class="icon-right-dashboard-arrow ag_pr--3 icon-dashboard-left-arrow"></i> {{title}}
          </h4>
          <ng-content select=".additional-btn"></ng-content>
          <div class="ag_ml--auto headerLink" *ngIf="rightAlignLinks" [ngClass]="{'ag_section--disable':disabled}">
              <a href="" href="javascript:void(0)" class="ag_link" *ngFor="let link of links;let i=index" (click)="navigateTo(link,i)">
                  <span *ngIf="i>0" 
                        class="ag_ml--2 ag_mr--2 pipe-seperator">|</span>{{link.linkText}}
              </a>
          </div>
         
      </div>
  </header>
  <section class="create-content-sect">
    <ng-content></ng-content>
  </section>
</section>