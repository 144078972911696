import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import {  PaymentHistory } from 'src/app/retail/shared/service/payment/payment-business.model';
import { ShopBussinessService } from '../../shop-business.service';
import { ShopDialogPopUp } from '../../shop-dialog-popup/shop-dialog-popup.component';

@Component({
  selector: 'app-return-settlement-info-dialog',
  templateUrl: './return-settlement-info-dialog.component.html',
  styleUrls: ['./return-settlement-info-dialog.component.scss']
})
export class ReturnSettlementInfoDialogComponent {
  ShopCaptions: any;
  settlementHistory: PaymentHistory[] = [];
  @Input() datainput: any;
  constructor(
    public localization: RetailLocalization
    , private _sbs: ShopBussinessService
    , public dialogRef: MatDialogRef<ShopDialogPopUp>
  ) {
    this.ShopCaptions = this.localization.captions.shop;
  }

  ngAfterViewInit(){
    this.settlementHistory = this.datainput;
  }

  closeDialog(msg) {
    this.dialogRef.close(msg);
  }

}
