import { CdkTableModule } from '@angular/cdk/table';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injectable, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApmModule } from '@elastic/apm-rum-angular';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MomentModule } from 'angular2-moment';
import * as Hammer from 'hammerjs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './common/app-service';
import { Localization } from './common/localization/localization';
import { ServiceLocator } from './common/service.locator';
import { CommonPropertyInformation } from './common/shared/services/common-property-information.service';
import { CommonUtilities } from './common/shared/shared/utilities/common-utilities';
import { CoreModule } from './core/core.module';
import { RetailStandaloneLocalization as RetailStandAloneLocalization } from './core/localization/retailStandalone-localization';
import { AppModuleService } from './core/services/app.service';
import { RetailPropertyInformation } from './core/services/retail-property-information.service';
import { Utilities } from './core/utilities';
import { MaterialModule } from './material-module';
import { PopupModule } from './popup-module/popup.module';
import { RetailAppService } from './retail-app-service';
import { CancelorNoShowActionPipe } from './shared/pipes/Allow-CancelorNoShowAction.pipe';
import { EditOrSeatActionPipe } from './shared/pipes/Allow-EditorSeatAction.pipe';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environments/environment';
import { KeyboardComponent } from './pos/keyboard/keyboard.component';
import { LocaleService } from './shared/services/locale.service';
import { KeyboardKeyDirective } from './pos/keyboard/keyboard-key.directive';

let AppServiceFactory = (utilities: Utilities, localization: RetailStandAloneLocalization) => {
  return new RetailAppService(utilities, localization);
};
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  minScrollbarLength: 20
};


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
    pinch: { enable: true },
    rotate: { enable: false},
    tap: {enable: true}
  };
}
@NgModule({
  declarations: [
    AppComponent,
    KeyboardKeyDirective,
    KeyboardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    HttpClientModule ,
    OAuthModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    AppRoutingModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgScrollbarModule,
    CdkTableModule,
    HttpClientModule,
    NgbPaginationModule,
    NgbModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ModalModule.forRoot(),
    PopupModule,
    PerfectScrollbarModule,
    NgxMaterialTimepickerModule,
    ApmModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,            
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [DatePipe, EditOrSeatActionPipe, CancelorNoShowActionPipe,
    {
      provide: APP_BASE_HREF,
      useValue: environment.app_base_href,     
  },{
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: AppService,
      useFactory: AppServiceFactory,
      deps: [Utilities, RetailStandAloneLocalization]
    },
    { provide: CommonUtilities, useExisting: Utilities },
    RetailPropertyInformation,
    { provide: Localization, useExisting: RetailStandAloneLocalization },
    { provide: CommonPropertyInformation, useExisting: RetailPropertyInformation },
    AppModuleService,
    {
      provide: MAT_DATE_LOCALE,
      deps: [LocaleService],
      useFactory: (localeService: any) => localeService.defaultLocale()
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
    //{ provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
