<mat-form-field  [floatLabel]="floatLabel" [ngClass]="customClass" [formGroup]="form" (click)="onControlClick()"> 
    <mat-label style="cursor:pointer" [attr.LiteralID]="placeHolderId">{{placeHolder}}</mat-label>
    <input (input)="inputChanged($event)"  [attr.automationId]="automationId" (blur)="valueEmit($event)" matInput [placeholder]="placeHolder"
           [formControlName]="controlName" [minlength]="minlength" [maxlength]="maxLength" [required]="isRequired"
           inputtype="nonnegative,nodecimal">
    <mat-error *ngIf="form.controls[controlName]?.hasError('required')">
        {{captions.err_missing}} {{placeHolder}}
    </mat-error>
    <mat-error *ngIf="form.controls[controlName]?.hasError('min')">
        {{customMinErrorMsg? customMinErrorMsg : this.captions.err_min}}
    </mat-error>
    <mat-error *ngIf="form.controls[controlName]?.hasError('max')">
        {{customMaxErrorMsg? customMaxErrorMsg : this.captions.err_max}}
    </mat-error>
    <mat-error *ngIf="form.controls[controlName]?.hasError('minlength')">
        {{customMinErrorMsg? customMinErrorMsg : this.captions.err_min}}
    </mat-error>
    <mat-error *ngIf="form.controls[controlName]?.hasError('maxlength')">
        {{customMaxErrorMsg? customMaxErrorMsg : this.captions.err_max}}
    </mat-error>

</mat-form-field>