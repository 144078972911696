<section class="pop-container">


    <div class="pop-head">
      <label class="inner-header1 pl-4 LW20">{{data.headername}}</label>
      <i [attr.automationId]="'Icn_shopCommonComment_close'" aria-hidden="true" *ngIf="data.closebool" class="material-icons inner-close float-md-right cursPointer" (click)="onCancelClick()">close</i>
    </div>
    <div class="pop-contents ">
        <form [formGroup]="voidGroup" class="void-form">
            <mat-form-field class="w-50 txt-area-height" [floatLabel]="floatLabel">
              <textarea #commentField matInput [attr.automationId]="'Ta_shopCommonComment_comments'" placeholder="{{data.placeholder}}" maxlength="35" formControlName="comments"></textarea>
            </mat-form-field>
          </form>
    </div>
    <mat-dialog-actions>     
          <button mat-button [attr.automationId]="'Btn_shopCommonComment_ok'" class="body-bgcolor whitecolor LW14 ml-3 text-capital" (click)="save()" [ngClass]="'button_valid'" [disabled]="false"> {{localisation.OK}}</button>
          <button mat-button [attr.automationId]="'Btn_shopCommonComment_cancel'" class="LW14 ml-2 text-capital" (click)="onCancelClick()">{{localisation.Cancel}}</button>
      </mat-dialog-actions>
  
  
  </section>