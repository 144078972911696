import { Injectable } from '@angular/core';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { CommonVariablesService, ReturnWithTicketType } from '../service/common-variables.service';
import { POSFinancialBin } from '../service/payment/payment-business.model';
import { SelectedProducts } from '../shared.modal';
import { RetailUtilities } from '../utilities/retail-utilities';

@Injectable()
export class FinancialBinHelper {
    constructor(
        private propInfo: RetailPropertyInformation,
        private _ss: CommonVariablesService,
        private utils: RetailUtilities
    ) { }

    /**
     * Constructs FinancialBin Array based on the retailItems collection
     * @returns POSFinancialBin[]
     */
    BuildDetailedFinancialBin(retailItems: SelectedProducts[], paymentAmount: number): POSFinancialBin[] {
        const financialBin: POSFinancialBin[] = [];
        try {
            if (retailItems && retailItems.length > 0) {
                retailItems.map(item => {
                    item.Noofitems = Number(item.Noofitems);
                    const originalItem = this._ss.returnWithticketItems.find(x => x.id === item.id);
                    let itemPrice = 0;
                    let itemTax = 0;
                    let gratuity = item.Gratuity && item.Gratuity.length > 0 ?
                    item.Gratuity.map(x => x.gratuity).reduce((a, b) => a + b, 0) : 0;
                    let servCharge = item.ServiceCharge && item.ServiceCharge.length > 0 ?
                    item.ServiceCharge.map(x => x.ServiceCharge).reduce((a, b) => a + b, 0) : 0;
                    let discount = originalItem ? originalItem.Discount : item.Discount;

                    // Returning partial quantity
                    if (originalItem && originalItem.Noofitems !== item.Noofitems) {
                        // Original values
                        const originalGratuity = originalItem.Gratuity && originalItem.Gratuity.length > 0 ?
                        originalItem.Gratuity.map(x => x.gratuity).reduce((a, b) => a + b, 0) : 0;
                        const originalServCharge = originalItem.ServiceCharge && originalItem.ServiceCharge.length > 0 ?
                        originalItem.ServiceCharge.map(x => x.ServiceCharge).reduce((a, b) => a + b, 0) : 0;
                        const orignalDiscount = originalItem && originalItem.Discount;
                        const originalQuantity = Number(originalItem.Noofitems);
                        // Per unit split
                        const perUnitGratuity = originalGratuity / originalQuantity;
                        const perUnitServCharge = originalServCharge / originalQuantity;
                        const perUnitDiscount = orignalDiscount / originalQuantity;
                        // Actual GT and SC
                        gratuity = RoundOffTwo(perUnitGratuity * item.Noofitems);
                        servCharge = RoundOffTwo(perUnitServCharge * item.Noofitems);
                        discount = this.utils.MidPointRoundOffTwo(perUnitDiscount * item.Noofitems);
                    }

                    // VAT Logic
                    itemPrice = item.ProductPrice; itemTax = item.Tax;
                    if (item.netPrice || item.netUnitPrice) {
                        const lineItemNetPrice = RoundOffTwo(item.Noofitems * item.netUnitPrice);
                        itemPrice = this.propInfo.IsVATEnabled ? RoundOffTwo(lineItemNetPrice + discount) : lineItemNetPrice;
                        const totalAmt = item.isReturn ? RoundOffTwo(item.Noofitems * item.ProductPrice) : item.totalAmount;
                        itemTax = this.propInfo.IsVATEnabled ? RoundOffTwo(totalAmt - itemPrice) : itemTax;
                    }

                    const AddFinancialBin = (type: FinancialBinType, amount: number) => {
                        let roundedAmount = RoundOffTwo(amount * 100); // Upscaling for PMS XML Service
                        roundedAmount = item.isReturn ? -roundedAmount : roundedAmount;
                        if (financialBin.some(f => f.id === item.category && f.type === type)) {
                            const bin = financialBin.find(x => x.id === item.category && x.type === type);
                            if (bin) {
                                bin.amount = RoundOffTwo(bin.amount + roundedAmount);
                            }
                        } else {
                            if (roundedAmount) {
                                financialBin.push(
                                {
                                    amount: roundedAmount,
                                    id: item.category,
                                    quantity: item.Noofitems,
                                    type: type
                                } as POSFinancialBin );
                            }
                        }
                    };

                    let binsToCalculate = [
                        FinancialBinType.Sales,
                        FinancialBinType.Discount,
                        FinancialBinType.Tax,
                        FinancialBinType.ServiceCharge,
                        FinancialBinType.Gratuity
                    ];
                    switch (item.returnType) {
                        case ReturnWithTicketType.RETURNSERVICECHARGEONLY:
                            binsToCalculate = binsToCalculate.filter(x => x === FinancialBinType.ServiceCharge);
                            break;
                        case ReturnWithTicketType.RETURNGRATUITYONLY:
                            binsToCalculate = binsToCalculate.filter(x => x === FinancialBinType.Gratuity);
                            break;
                        case ReturnWithTicketType.RETURNSERVICECHARGEANDGRATUITY:
                            binsToCalculate = binsToCalculate.filter(x => x === FinancialBinType.ServiceCharge
                                 || x === FinancialBinType.Gratuity);
                            break;
                        case ReturnWithTicketType.RETAINITEMANDSERVICECHARGE:
                            binsToCalculate = binsToCalculate.filter(x => x !== FinancialBinType.Gratuity);
                            break;
                        case ReturnWithTicketType.RETAINITEMANDGRATUITY:
                            binsToCalculate = binsToCalculate.filter(x => x !== FinancialBinType.ServiceCharge);
                            break;
                        case ReturnWithTicketType.RETAINITEMONLY:
                            binsToCalculate = binsToCalculate.filter(x => x !== FinancialBinType.ServiceCharge
                                && x !== FinancialBinType.Gratuity);
                            break;
                        default: break;
                    }
                    const binTypeValueMap = {
                        [FinancialBinType.Sales]: itemPrice,
                        [FinancialBinType.Discount]: this.utils.MidPointRoundOffTwo(discount),
                        [FinancialBinType.Tax]: itemTax,
                        [FinancialBinType.ServiceCharge]: servCharge,
                        [FinancialBinType.Gratuity]: gratuity,
                    };
                    binsToCalculate.map(binType => AddFinancialBin(binType, RoundOffTwo(binTypeValueMap[binType])));
                });
            }
        } catch (error) {
            console.log('Error occurred while building FinancialBins...', error);
        }
        console.log(financialBin);
        return financialBin;
    }

    calculateWeightageAndTallyFinancialBins(financialBin: POSFinancialBin[], paymentAmount: number): POSFinancialBin[] {
        const finBinTotal = this.getFinBinTotal(financialBin);
        if ((paymentAmount * 100) !== finBinTotal){
            financialBin.forEach(e => {
                e.amount = Math.round((e.amount / finBinTotal) * (paymentAmount * 100));
            });
            const differenceAmount: number = (paymentAmount * 100) - this.getFinBinTotal(financialBin);
            if (differenceAmount !== 0) {
                financialBin[financialBin.length - 1].amount += RoundOffTwo(differenceAmount);
            }
        }
        return financialBin;
    }

    getFinBinTotal(financialBin: POSFinancialBin[]): number {
        let finBinTotal = 0;
        financialBin.forEach(e => {
            const amt = e.type === FinancialBinType.Discount ? e.amount * -1 : e.amount;
            finBinTotal = RoundOffTwo(finBinTotal + amt);
        });
        return finBinTotal;
    }


    
}

export enum FinancialBinType {
    Sales = 1,
    Discount,
    Tax,
    Gratuity,
    ServiceCharge,
    Tip,
    Round
}

export function RoundOffTwo(Amount) { return Number(Amount.customToFixed()); }
