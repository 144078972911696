import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { COMPRESSION_LIMIT, ALLOWED_IMAGE_SIZE } from '../globalsContant';
import { RetailLocalization } from '../../common/localization/retail-localization';


@Component({
  selector: 'app-retail-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class RetailImageUploaderComponent implements OnInit {

  @Input() readOnly: boolean;
  @Input() emptyImgCaption: string;
  @Input('imgData')
  set ImageData(value) {
    if (value) {
      this.url = value;
      this.ImageUploaded = true;
    } else {
      this.ImageUploaded = false;
      this.url = '';
    }
  }
  @Output() fileDeleted = new EventEmitter();
  @Output() fileUploaded = new EventEmitter();
  @Output() fileSizeExceeded = new EventEmitter();
  url: any;
  ImageUploaded: boolean;
  captions: any;
  isViewOnly:boolean = false; // was not declared

  constructor(private imageCompress: NgxImageCompressService, public _ls:RetailLocalization) { }

  ngOnInit() {

    this.captions = this._ls.captions.common;
  }

  compressFile() {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      this.url = image;
      // convert to MB
      const fileSize = this.imageCompress.byteCount(image) / (1024);
      console.log('Size in kilo bytes was:', fileSize);
      if (fileSize > COMPRESSION_LIMIT) {
        this.imageCompress.compressFile(image, orientation).then(
          result => {
            const compressedfileSize = this.imageCompress.byteCount(result) / (1024 * 1024);
            console.log('Size in Mega bytes was:', compressedfileSize);
            if (compressedfileSize <= ALLOWED_IMAGE_SIZE) {
              this.url = result;
              this.ImageUploaded = true; 
              this.compressThumbnail(result);
            } else {
              this.fileSizeExceeded.emit();
            }
          }
        );
      } else {
        this.ImageUploaded = true;
        this.compressThumbnail(image);
      }
    });
  }

  compressThumbnail(image){
    let imgData = {orgImg: image};
    this.imageCompress.compressFile(image, 1, 30, 30).then(result=>{
      const compressedfileSize = this.imageCompress.byteCount(result) / (1024);
      console.log('Size in Kilo bytes was:', compressedfileSize);
      imgData['tmbImg'] = result
      this.fileUploaded.emit(imgData);
    })
  }

  onFileDelete() {
    this.ImageUploaded = false;
    this.fileDeleted.emit();
  }
}
