<ng-container [formGroup]="contactForm" *ngIf="arrayName; else singlePhone" class="phone-container">
  <div [formArrayName]="arrayName" class="ag_position--relative ag-phone-wrapper"
    *ngFor="let phone of contactForm.get(arrayName)['controls']; let i= index; let first = first" [ngClass]="[auto?.isOpen? 'show':'hide']">
    <div [formGroupName]="i" class="phone-wrapper">
      <div class='overlay-div'></div>
      <div class="ph-fixed-width--wrapper">
        <mat-form-field [ngClass]="typeClass" [floatLabel]="floatLabel">
          <mat-select [placeholder]="placeHolder" id="phone{{i}}" [formControlName]="typeControlName"
            (selectionChange)="typeChange($event, phone, i)">
            <mat-option *ngFor="let phonetype of options" [value]="phonetype.id">
              {{phonetype.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [ngClass]="customClass1" class="customcountrycode"[floatLabel]="floatLabel">
          <span *ngIf="CountryCodeRef.value" class="position-absolute">
            <div class="iti__flag-box">
              <div class="iti__flag" [ngClass]="CountryCodeRef.value | flagSelectorRetail"></div>
            </div>+
          </span>
          <input matInput class="ag_pr--5 ag_pl--35px" [ngClass]="{'countryCodeInput': CountryCodeRef.value}" name="countryCode" formControlName="countryCode" [placeholder]="countryCodePlaceHolder"
            [maxlength]="countryCodeLength" #CountryCodeRef [matAutocomplete]="auto"
            (input)="setmandatoryField('countryCode',controlName,contactForm,i)"
            (blur)="setmandatoryField('countryCode',controlName,contactForm,i);clearOutCountryCode(CountryCodeRef.value,contactForm, i)" (keyup)="filterdata($event)">
            <mat-autocomplete [panelWidth]="300" autoActiveFirstOption #auto="matAutocomplete"  (optionSelected)='getphoneformatmask($event,i)'>
              <mat-option *ngFor="let option of countrylist"
                  [value]="option" >
                  <div class="iti__flag-box">
                    <div class="iti__flag" [ngClass]="option.flagClass"></div>
                  </div>{{option.dialCode}} {{option.name}}
              </mat-option>
          </mat-autocomplete>
          <mat-error class="Textnowrap" *ngIf="phone.get('countryCode').hasError('required')"
            title="{{captions.Missing}} {{countryCodePlaceHolder}}">
            {{captions.Missing}} {{countryCodePlaceHolder}}
          </mat-error>
        </mat-form-field>
        <mat-form-field [ngClass]="customClass" class="customphonenumber" [floatLabel]="floatLabel">
          <input matInput [name]="controlName" (input)="setmandatoryField(controlName,'countryCode',contactForm,i)"
            (blur)="setmandatoryField(controlName,'countryCode',contactForm,i)" [formControlName]="controlName"
            [textmask]="textMaskPhoneArray[i]" (focus)="updateMask(i)"  [duplicateCheck]="controlName" [currentIndex]="i">
           <mat-error class="Textnowrap" *ngIf="phone.get(controlName).hasError('required')">
             {{captions.missingPhone}}
          </mat-error>
          <mat-error *ngIf="phone.get(controlName).hasError('duplicate')">
            {{duplicateError}}
          </mat-error>
          <mat-error *ngIf="phone.get(controlName).hasError('incorrect')">
            {{errorMessage}}
          </mat-error>
        </mat-form-field>
        <span class="icon-Plus" [ngClass]="{'ag_section--disable':isPhoneDisabled}" (click)="addPhoneItem(i,'','', '')"
          *ngIf="first"></span>
        <span class="icon-Minus" [ngClass]="{'ag_section--disable':isPhoneDisabled}" (click)="removePhoneItem(i)"
          *ngIf="!first"></span>
      </div>
      <ng-container *ngIf="showSwitches">
        <div class="ag-phone-switch primary-info-toggle">
          <label class="ag_form--label ag_common-label-width">{{primaryLabel}}</label>
            <ui-switch class="LW14 switch-toggle"  size="small" [formControlName]="primarySwitchName" [checkedLabel]="captions.Yes" [uncheckedLabel]="captions.No"
            (change)="togglePrimaryContact(i)">
          </ui-switch>
        </div>
        <div class="ag_pl--5 private-info-toggle">
          <label class="ag_form--label ag_common-label-width">{{privateLabel}}</label>
          <ui-switch class="LW14 switch-toggle"  size="small" [formControlName]="privateSwitchName" [checkedLabel]="captions.Yes" [uncheckedLabel]="captions.No">
          </ui-switch>
        </div>
      </ng-container>
    </div>

    <div [formGroupName]="i" *ngIf="phone.get(typeControlName).value === phoneTypes.office"
      class="ag_display--flex ph-fixed-width--wrapper">
      <mat-form-field [ngClass]="extensionClass" class="ag_ml--auto" [floatLabel]="floatLabel">
        <input matInput name="extension" formControlName="extension" [placeholder]="captions.lbl_extension"
          [maxlength]="extensionLength" inputtype='nonnegative'>
      </mat-form-field>
    </div>
  </div>
</ng-container>

<ng-template #singlePhone>
  <ng-container [formGroup]="contactForm">
    <mat-form-field [ngClass]="typeClass" [floatLabel]="floatLabel">
      <mat-select [placeholder]="placeHolder" [formControlName]="typeControlName"
        (selectionChange)="typeChange($event, contactForm)">
        <mat-option *ngFor="let phonetype of options" [value]="phonetype.id">
          {{phonetype.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [ngClass]="customClass1" [floatLabel]="floatLabel">
      <input matInput name="countryCode" formControlName="countryCode" [placeholder]="countryCodePlaceHolder"
        [maxlength]="countryCodeLength" inputtype='nonnegative'
        (input)="setmandatoryField('countryCode',controlName,contactForm)"
        (blur)="setmandatoryField('countryCode',controlName,contactForm)">
      <mat-error *ngIf="contactForm.get('countryCode').hasError('required')">
        {{captions.Missing}} {{countryCodePlaceHolder}}
      </mat-error>
    </mat-form-field>
    <mat-form-field [ngClass]="customClass" [floatLabel]="floatLabel">
      <input matInput [name]="controlName" (input)="setmandatoryField(controlName,'countryCode',contactForm)" (blur)="setmandatoryField(controlName,'countryCode',contactForm)" [formControlName]="controlName"
        [textmask]="textMaskPhone">
      <mat-error *ngIf="contactForm.get(controlName).hasError('incorrect')">
        {{errorMessage}}
      </mat-error>
    </mat-form-field>
    <div *ngIf="contactForm.get(typeControlName).value === phoneTypes.office" class="ag_display--flex">
      <mat-form-field [ngClass]="extensionClass" class="ag_ml--auto" [floatLabel]="floatLabel">
        <input matInput name="extension" formControlName="extension" [placeholder]="extensionPlaceHolder"
          [maxlength]="extensionLength" inputtype='nonnegative'>
      </mat-form-field>
    </div>
  </ng-container>
</ng-template>