<div class="table-timeline__row table-timeline__row--body">

  <div class="table-timeline__row--drag-handler" [class.nohover]="dragHandlerService.lockAxis" [class.hover]="!dragHandlerService.lockAxis && dragHandlerService.dragStarted && dragHandlerService.hoveredRowId === rowData.Id" *ngIf="dragHandlerService.dragStarted" [attr.rowId]="rowData.Id" [attr.rowName]="rowData.Name">
  </div>
  
  <div class="table-timeline__column sticky-left" >
    <div class="instructor-image-view">
      <ng-container *ngIf="(rowData.CategoryId | activityColor) as categoryStyle">
        <div class="userprofile__instructor-initial" [ngStyle]="{'backgroundColor': categoryStyle?.backgroundColor}">
          {{rowData | guestNameFormatter}}
        </div>
      </ng-container>
    </div>
    <!-- [category-indicator]="rowData.CategoryId" -->
    <span class="userprofile__instructor-name" [title]="rowData | instructorNameFormatter">
      {{rowData | instructorNameFormatter}}</span>
    <div class="expand-icon" *ngIf="showExpandButton">
      <img *ngIf="!expandMode" (click)="expandMode=true" src="../../../../assets/images/unfold_more.svg" alt="">
      <img *ngIf="expandMode" (click)="expandMode=false" src="../../../../assets/images/unfold_less.svg" alt="">
    </div>
  </div>
  <div style="display: grid;flex-grow: 1;" [gridColumnsGenerator]="timeArrayGenerated.length" [columnWidth]="columnWidth">
    <ng-container *ngFor="let session of allSessions; let i =index">
      <div class="group innerCard"  style="display: grid;grid-row: 1 / -1;" [style.gridColumnStart]="session.gridColumnStart"
      [style.gridColumnEnd]="session.gridColumnEnd" [style.gridTemplateColumns]="session | templateColumnsConfig: columnWidth"
      *ngIf="session.gridColumnStart < lastIndex || dragHandlerService.dragStarted">
        <ng-container *ngFor="let subSession of session.items">
            <div class="session-card-container {{'height_'+session.rows}}" [class.fullHeight]="expandMode"
              [session]="session"
              [style.gridColumnStart]="subSession.gridColumnStart - session.gridColumnStart + 1"
              [style.gridColumnEnd]="subSession.gridColumnEnd - session.gridColumnStart + 1"
              (promptBlockSessionEvent)="promptBlockSession(rowData, subSession)"
              (modifySessionEvent)="modifySession(rowData, subSession)" (cancelSessionEvent)="cancelSession(rowData, subSession)" session-card [data]="subSession"
              [rowData]="rowData" [selectedViewId]="selectedViewId" [rows]="session.rows" [expandMode]="expandMode" [timeArrayGenerated]="timeArrayGenerated"
              [serviceData]="partyService.allWeekActivitySessions[subSession.uniqId]"
              [headLineData]="partyService.allSessionsHeadLines[subSession.SessionId]"
              (addBookingEvent)="addBookingToSlot(rowData,subSession.DateTime, i)"
              (blockStaffEvent)="blockStaff(rowData,subSession)"
              (deleteBlockStaffEvent)="deleteStaffBlock(rowData,subSession)"
              (click)="activitySelected(subSession, rowData)">
            </div>
        </ng-container>
      </div>

    </ng-container>

  </div>
</div>