<!-- Displaying the contact detials in Table -->
<div class="guestlist">
  <div class="guestlist__list-controls seat-border-color">
    <mat-checkbox class="guestlist__icon-btn seat-secondary-bg-color seat-border-color" *ngIf="!guestBookService.isFeedback" [(ngModel)]="allContacts" [disabled]="HostRoleType.Name != RoleType[RoleType.Manager]" (change)="selectAllContact($event)">
    </mat-checkbox>
    <button class="guestlist__icon-btn icon-ic_delete_24px margin_left_btn seat-icon-color seat-secondary-bg-color seat-border-color" *ngIf="!guestBookService.isFeedback" [ngClass]="{'disabled-icon':HostRoleType.Name != RoleType[RoleType.Manager] || checkedContancts?.length < 1}" [disabled]="HostRoleType.Name != RoleType[RoleType.Manager] || checkedContancts?.length < 1" (click)="deleteContact()"></button>
    <button class="guestlist__icon-btn icon-ic_create_24px margin_left_btn seat-icon-color seat-secondary-bg-color seat-border-color" *ngIf="!guestBookService.isFeedback" [ngClass]="{'disabled-icon':checkedContancts?.length != 1}" [disabled]="checkedContancts?.length != 1"
      (click)="editGuest()"></button>
    <div class="guestlist__action-view">
      <div class="guestlist__form-section">
        <div class="guestlist__search-by">
        <dynamic-form [config]="searchconfig"  class="guestlist__search-form" #form="dynamicForm" [ngClass]="{'guestlist__guest-details-view-search-form':guestBookService.isGuestDetailsVisible.getValue()}">
        </dynamic-form>

        <label for="search-by" class="guestlist__searchBy-text seat-tertiary-text" [ngClass]="{'guestlist__guest-details-view-search-form':guestBookService.isGuestDetailsVisible.getValue()}">{{'guestBookSearchByText' | translate}}</label>
        <dynamic-form class="guestlist__searchBy"  [config]="searchByConfig" #searchForm="dynamicForm" [ngClass]="{'guestlist__guest-details-view-search-form':guestBookService.isGuestDetailsVisible.getValue()}">
        </dynamic-form>
        <activities-app-button class="guestlist__search-by-btn" [ngClass]="{'guestlist__guest-details-view-search-btn':guestBookService.isGuestDetailsVisible.getValue()}"  [buttontype]="searchBtn" (valueChange)='searchData(true)'></activities-app-button>
      </div>
      <div class="guestlist__sort-by-view" [ngClass]="{'guestlist__guest-details-view':guestBookService.isGuestDetailsVisible.getValue()}">
        <label for="sort-by" *ngIf="!guestBookService.isFeedback" class="guestlist__sortby-text seat-tertiary-text">{{'guestBookSortByText' | translate}}</label>
        <dynamic-form *ngIf="!guestBookService.isFeedback" [config]="sortByConfig" #form="dynamicForm" >
        </dynamic-form>
        <div class="display_filter custom-display-filter" *ngIf="guestBookService.isFeedback">
          <a [ngbPopover]="myPopover" placement="bottom" container="body" [autoClose]="'outside'">
            <span class="icon-Filter"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
          </a>
          <ng-template #myPopover>
            <app-filter-content [displayOptions]="feedbackOptions" [isMessageAvailable]="true" (optionSelection)="propertySelectionChanged($event)"></app-filter-content>
          </ng-template>
        </div>
      </div>
    </div>
	</div>
    <activities-app-button [buttontype]="addContactBtn" *ngIf="!guestBookService.isFeedback" (valueChange)='addnewGuest()'></activities-app-button>
  </div>
  <div class="guestlist__table-section">
    <div class="guestlist__table-container">
      <div class="guestlist__table-data" *ngIf="!guestBookService.isFeedback">
        <perfect-scrollbar>
          <table class="guestlist__table-view" [ngClass]="{'guestlist__table-view-height': guestBookService?.allAvailableContacts?.value.length == 0}">
            <thead>
              <tr>
                <th class="guestlist__table-heading seat-border-color"></th>
                <th class="guestlist__table-heading seat-border-color"></th>
                <th class="guestlist__table-heading seat-border-color"></th>
                <th class="guestlist__table-heading seat-grid-header seat-border-color">
                  <span class="guestlist__header-sort">
                    <span class="guestlist__header-name">{{'guestBookBasicInfoText' | translate}}</span>
                    <!-------------------sorting for future release ---------------->
                    <!-- <div class="guestlist__icon-view">
                      <i class="icon-Sort-Up guestlist__sort-icon"  (click)="sortBy($event)"></i>
                      <i class="icon-Sort-Down guestlist__sort-icon" (click)="sortBy($event)"></i>
                    </div> -->
                  </span>
                </th>
                <th class="guestlist__table-heading seat-grid-header seat-border-color">
                  <span class="guestlist__header-sort">
                    <span class="guestlist__header-name">{{'guestBookDetailsPhone' | translate}}</span>
                  </span>
                </th>
                <th class="guestlist__table-heading seat-grid-header seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                  <span class="guestlist__header-sort">
                    <span class="guestlist__header-name">{{'guestBookCityText' | translate}}</span>
                       <!-------------------sorting for future release ---------------->
                    <!-- <div class="guestlist__icon-view">
                      <i class="icon-Sort-Up guestlist__sort-icon"  (click)="sortBy($event)"></i>
                      <i class="icon-Sort-Down guestlist__sort-icon" (click)="sortBy($event)"></i>
                    </div> -->
                  </span>
                </th>
                <th class="guestlist__table-heading seat-grid-header seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                  <span class="guestlist__header-sort">
                    <span class="guestlist__header-name">{{'guestBookCreatedDateText' | translate}}</span>
                        <!-------------------sorting for future release ---------------->
                    <!-- <div class="guestlist__icon-view">
                      <i class="icon-Sort-Up guestlist__sort-icon"  (click)="sortBy($event)"></i>
                      <i class="icon-Sort-Down guestlist__sort-icon" (click)="sortBy($event)"></i>
                    </div> -->
                  </span>
                </th>
                <th class="guestlist__table-heading seat-grid-header seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                  <span class="guestlist__header-sort">
                    <span class="guestlist__header-name">{{'guestBookLastVisitedDateText' | translate}}</span>
                        <!-------------------sorting for future release ---------------->
                    <!-- <div class="guestlist__icon-view">
                      <i class="icon-Sort-Up guestlist__sort-icon"  (click)="sortBy($event)"></i>
                      <i class="icon-Sort-Down guestlist__sort-icon" (click)="sortBy($event)"></i>
                    </div> -->
                  </span>
                </th>
                 <!-- Itinerary Operation -->
                 <th *ngIf="_settings.HasCGPSearch"  class="guestlist__table-heading seat-grid-header seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                  <span class="guestlist__header-sort">
                    <span class="guestlist__header-name">{{'Itinerary' | translate}}</span>
                  </span>
                </th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let contact of guestBookService.allAvailableContacts.value ;let i = index" class="guestlist__table-row seat-grid-content" [ngClass]="{'guestlist__selected-row': i == guestBookService.selectedContactIndex}">
              <td class="guestlist__table-data guestlist__icon-view seat-border-color">
                <mat-checkbox class="icon_btn checkbox_contact " (change)="selectContact(i,contact,$event)" [checked]="checkedContancts.indexOf(contact.Id) != -1"></mat-checkbox>
              </td>
              <td class="guestlist__table-data guestlist__icon-view seat-border-color">
                <i class="icon-Favourites guestlist__status-icon" [ngClass]="{'guestlist__fav-selected':contact.IsFavorite}" (click)="favClick(contact)"></i>
              </td>
              <td class="guestlist__table-data guestlist__icon-view seat-border-color">
                <i class="icon-VIP guestlist__status-icon" [ngClass]="{'guestlist__vip-selected':
                contact.IsVip}" (click)="vipClick(contact)"></i>
              </td>
              <td class="guestlist__table-data seat-border-color">
                <div class="guestlist__basic-info">
                  <div class="guestlist__image-view">
                    <!-- <img class="guestlist__profile-image" src="assets/images/user_image.jpg"  alt="profile_pic"> -->
                    <div class="guestlist__guest-initial" [ngStyle]="{'background-color': '' | randomColor}">{{contact | guestNameFormatter}}</div>
                  </div>
                  <div class="guestlist__guest-details" (click)="showGuestDetails(i,contact.Id)">
                    <div class="guestlist__guest-name">{{contact.GuestTitle | guestTitlePipe}} {{contact.FirstName}} {{contact.LastName}}</div>
                    <div class="guestlist__guest-alias-name seat-tertiary-text" *ngIf="contact?.AliasName">{{'guestBookDetailsAliasName' | translate}} : {{contact?.AliasName}}</div>
                    <div class="guestlist__guest-email seat-tertiary-text">{{contact.EmailAddress}}</div>
                  </div>
                </div>
              </td>
              <td class="guestlist__table-data seat-border-color">
                <!-- appService.formatPhoneNumber(contact.PhoneNumber) -->
                <a class="guestlist__guest-phone" *ngIf="contact.PhoneNumber">{{ contact.PhoneNumber | phoneNumberFormatter: contact.CountryId}}</a>
                <span *ngIf="!contact.PhoneNumber">{{'NA' | translate}}</span>
              </td>
              <td class="guestlist__table-data guestlist__guest-city seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}" *ngIf="contact.City">
                <span>{{contact.City}}</span>
              </td>
              <td class="guestlist__table-data seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}" *ngIf="!contact.City">
                <span>{{'NA' | translate}}</span>
              </td>
              <td class="guestlist__table-data seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                <span>{{contact.CreatedAt | localizedDate : _settings.General.DateFormat}}</span>
              </td>
              <td class="guestlist__table-data seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                <span *ngIf="contact.LastVisitedAt">{{contact.LastVisitedAt | localizedDate: _settings.General.DateFormat}}</span>
                <span *ngIf="!contact.LastVisitedAt">{{'NA' | translate}}</span>
              </td>

               <!-- Itinerary Operation -->
               <td *ngIf="_settings.HasCGPSearch" class="guestlist__table-data guestlist__icon-view seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                <i class="icon-itinerary guestlist__status-icon" style="font-size: 30px !important;" (click)="getItinerary(contact)"></i>
              </td>

            </tr>
            <!-- No Data row display -->
            <tr class="guestlist__table-row" *ngIf="guestBookService?.allAvailableContacts?.value.length == 0">
              <td [colSpan]="8" class="guestlist__no-data seat-no-content-found">{{'No Data Found' | translate}}</td>
            </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>
      <div class="guestlist__table-data" *ngIf="guestBookService.isFeedback">
        <ng-scrollbar>
          <table class="guestlist__table-view" [ngClass]="{'guestlist__table-view-height': guestBookService.allAvailableContacts?.value.length == 0}">
            <thead>
              <tr>
                <!-- <th class="guestlist__table-heading seat-border-color"></th> -->
                <th class="guestlist__table-heading seat-border-color"></th>
                <th class="guestlist__table-heading seat-border-color"></th>
                <th class="guestlist__table-heading seat-grid-header seat-border-color">
                  <span class="guestlist__header-sort">
                    <span class="guestlist__header-name">{{'guestBookBasicInfoText' | translate}}</span>
                    <!-------------------sorting for future release ---------------->
                    <!-- <div class="guestlist__icon-view">
                      <i class="icon-Sort-Up guestlist__sort-icon"  (click)="sortBy($event)"></i>
                      <i class="icon-Sort-Down guestlist__sort-icon" (click)="sortBy($event)"></i>
                    </div> -->
                  </span>
                </th>
                <th class="guestlist__table-heading seat-grid-header seat-border-color">
                  <span class="guestlist__header-sort">
                    <span class="guestlist__header-name">{{'activityName' | translate}}</span>
                  </span>
                </th>
                <th class="guestlist__table-heading seat-grid-header seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                  <span class="guestlist__header-sort">
                    <span class="guestlist__header-name">{{'guestBookLastVisitedDateText' | translate}}</span>
                       <!-------------------sorting for future release ---------------->
                    <!-- <div class="guestlist__icon-view">
                      <i class="icon-Sort-Up guestlist__sort-icon"  (click)="sortBy($event)"></i>
                      <i class="icon-Sort-Down guestlist__sort-icon" (click)="sortBy($event)"></i>
                    </div> -->
                  </span>
                </th>
                <th class="guestlist__table-heading seat-grid-header seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                  <span class="guestlist__header-sort">
                    <span class="guestlist__header-name">{{'latestFeedback'| translate}}</span>
                        <!-------------------sorting for future release ---------------->
                    <div class="guestlist__icon-view">
                      <i class="icon-Sort-Up guestlist__sort-icon" [ngClass]="[feedbackSortcount==1 ? 'highlight' : 'nonehightlight']" (click)="sortByFeedback($event)"></i>
                      <i class="icon-Sort-Down guestlist__sort-icon"  [ngClass]="[feedbackSortcount==2 ? 'highlight' : 'nonehightlight']"  (click)="sortByFeedback($event)"></i>
                    </div>
                  </span>
                </th>
                <th class="guestlist__table-heading seat-grid-header seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                  <span class="guestlist__header-sort">
                    <span class="guestlist__header-name">{{'statusText' | translate}}</span>
                        <!-------------------sorting for future release ---------------->
                    <!-- <div class="guestlist__icon-view">
                      <i class="icon-Sort-Up guestlist__sort-icon"  (click)="sortBy($event)"></i>
                      <i class="icon-Sort-Down guestlist__sort-icon" (click)="sortBy($event)"></i>
                    </div> -->
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let contact of guestBookService.allAvailableContacts.value ;let i = index" class="guestlist__table-row seat-grid-content" [ngClass]="{'guestlist__selected-row': i == guestBookService.selectedContactIndex}">
              <!-- <td class="guestlist__table-data guestlist__icon-view seat-border-color">
                <mat-checkbox class="icon_btn checkbox_contact " (change)="selectContact(i,contact,$event)" [checked]="checkedContancts.indexOf(contact.Id) != -1"></mat-checkbox>
              </td> -->
              <td class="guestlist__table-data guestlist__icon-view seat-border-color">
                <i class="icon-Favourites guestlist__status-icon" [ngClass]="{'guestlist__fav-selected':contact.IsFavorite}" (click)="favClick(contact)"></i>
              </td>
              <td class="guestlist__table-data guestlist__icon-view seat-border-color">
                <i class="icon-VIP guestlist__status-icon" [ngClass]="{'guestlist__vip-selected':
                contact.IsVip}" (click)="vipClick(contact)"></i>
              </td>
              <td class="guestlist__table-data seat-border-color">
                <div class="guestlist__basic-info">
                  <div class="guestlist__image-view">
                    <!-- <img class="guestlist__profile-image" src="assets/images/user_image.jpg"  alt="profile_pic"> -->
                    <div class="guestlist__guest-initial" [ngStyle]="{'background-color': '' | randomColor}">{{contact | guestNameFormatter}}</div>
                  </div>
                  <div class="guestlist__guest-details" (click)="showGuestDetails(i,contact.Id)">
                    <div class="guestlist__guest-name">{{contact.GuestTitle | guestTitlePipe}} {{contact.FirstName}} {{contact.LastName}}</div>
                    <div class="guestlist__guest-email seat-tertiary-text">{{contact.EmailAddress}}</div>
                  </div>
                </div>
              </td>
              <td class="guestlist__table-data seat-border-color">
                <!-- appService.formatPhoneNumber(contact.PhoneNumber) -->
                <a class="guestlist__guest-phone">{{contact?.Feedback?.ActivityName}}</a>
                <!-- <span *ngIf="!contact.PhoneNumber">NA</span> -->
              </td>
              <td class="guestlist__table-data guestlist__guest-city seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                <span>{{contact.LastVisitedAt | localizedDate : cs.settings.value.General.DateFormat}}</span>
              </td>
              <!-- <td class="guestlist__table-data seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}" *ngIf="!contact.City">
                <span>NA</span>
              </td> -->
              <td class="guestlist__table-data seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                <div class="wrap">
                <div class="rating">
                  <span class="icon-" *ngFor="let rating of (contact?.Feedback?.TempRating | customerRating)">{{rating}}</span>
                </div>
                  <span *ngIf="this._settings?.PropertySetting[0]?.RatingThreshold > contact?.Feedback?.TempRating && contact?.Feedback?.TempRating  > 0" class="guest-data__icon-exclamation icon-exclamation" matTooltip="{{'This rating is below the threshold value.' | translate}}" style="color: red;">
                    <span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                </div>
                
              </td>
              <td class="guestlist__table-data seat-border-color" [ngClass]="{'d-none':guestBookService.isGuestDetailsVisible.getValue()}">
                <span style="color: red;">{{contact?.Feedback?.StatusCode == 0 ? 'Unread' : contact?.Feedback?.StatusCode == 1 ? 'Read' : contact?.Feedback?.StatusCode == 2 ? 'Closed' : contact?.Feedback?.StatusCode == 3 ? 'Action Taken' : 'N/A'}}</span>
              </td>
            </tr>
            <!-- No Data row display -->
            <tr class="guestlist__table-row" *ngIf="guestBookService.allAvailableContacts.value.length == 0">
              <td [colSpan]="8" class="guestlist__no-data seat-no-content-found">{{'No Data Found' | translate}}</td>
            </tr>
            </tbody>
          </table>
        </ng-scrollbar>
      </div>

      <div class="guestlist__pagination-view">
        <span class="guestlist__pagesize seat-labels" *ngIf="(guestBookService.pageNumber)*guestBookService.pageSize < guestBookService.totalCount">
          {{ 'ShowingstartendOfTotalItems' | translate : { start: (guestBookService.pageNumber*guestBookService.pageSize)+1, end: (guestBookService.pageNumber+1)*guestBookService.pageSize, total: guestBookService.totalCount} }}
           <!-- {{(guestBookService.pageNumber+1)*guestBookService.pageSize}} of {{guestBookService.totalCount}} items -->
           </span>
          <span class="guestlist__pagesize seat-labels" *ngIf="(guestBookService.pageNumber)*guestBookService.pageSize > guestBookService.totalCount">
            {{ 'ShowingstartendOfTotalItems' | translate : { start: (guestBookService.pageNumber*guestBookService.pageSize)+1, end: guestBookService.totalCount, total: guestBookService.totalCount} }}

            <!-- Showing {{(guestBookService.pageNumber*guestBookService.pageSize)+1}} -
            {{guestBookService.totalCount}} of {{guestBookService.totalCount}} items -->
          </span>
        <ngb-pagination [collectionSize]="guestBookService.totalCount " [(page)]="guestBookService.page" (pageChange)="paginateTable($event)"
          [pageSize]="guestBookService.pageSize" [maxSize]="1" [rotate]="true" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst><span class="icon-First-Page guestlist__first-page"></span></ng-template>
          <ng-template ngbPaginationLast><span class="icon-last-Page guestlist__last-page"></span></ng-template>
          <ng-template ngbPaginationPrevious><span class="icon-previous guestlist__previous-page"></span></ng-template>
          <ng-template ngbPaginationNext><span class="icon-next guestlist__next-page"></span></ng-template>
        </ngb-pagination>
        <div class="guestlist__items-per-page">
          <span class="guestlist__page-details seat-labels">{{'ItemsperPage' | translate}}</span>
          <dynamic-form [config]="showByConfig" #form="dynamicForm">
          </dynamic-form>
        </div>
      </div>
    </div>

    <!----------------------Later release --  display alphabets for sorting alphabetically ---------------------------->
    <!-- <ul class="alphabet_order" style="    display: flex;
    flex-direction: column;
    justify-content: space-around;">
          <li *ngFor="let item of alphabets">
            <button mat-button matTooltip="{{item}}" [matTooltipPosition]="position" [matTooltipClass]="'toolTipClass'"
              class="alphabets_column" [ngClass]="{'selectedAlphabet':item == selectedAlphabet}" (mouseenter)="hoveredElement(item)"
              (click)="filterContactByAlphabet(item)">{{item}}</button>
      </li>
    </ul> -->
  </div>
</div>
