<section id="SearchItemAutoCompleteSection" >
    <label class="LW12 requriedMB linked-item-color blckClr label-color">{{displayTexts?.label}}</label>
    <mat-form-field style="position: relative;" class="LW16 d-inline-block spa-searchbox w-100 custSearchbox" [floatLabel]="'never'">
      <input attr.automationId = 'Txt_{{automationId}}_autocomplete' type="text"  class="LW14"  [placeholder]="displayTexts?.placeHolder" matInput #searchInput
        [formControl]="autocomplete" autocomplete="off">
        <mat-error class="" *ngIf="!linkedItemsClicked && !autocomplete.valid">
            {{displayTexts?.error}}
        </mat-error>
      <div *ngIf="(arrayIndex == searchedIndex)&&(isMultipleLinkedItem)">
        <div id="searchOuter" *ngIf="((autocomplete.value && autocomplete.valid)|| linkedItemsClicked) && searchFilter.length>0"> 
          <div  [ngClass]="searchFilter.length <= 2 ? 'search-class-min': 'search-class'"  class="w-100" clickOutside (clickOutside)="clickedOutside()">
            <div id="searchData" class="search-data">
              <ng-scrollbar [trackX]="false" >
              <mat-option attr.automationId='Srch_{{automationId}}_multipleLinkedItemSearchFilter' *ngFor="let option of searchFilter" (click)='SelectedRecord(option)' >
                  <div class="LW14 text-capitalize" > {{ option.itemNumber }} - {{
                    option.itemDescription }}</div>
                </mat-option>
              </ng-scrollbar>
            </div>  
          </div>         
        </div>
      </div>
      
      <div *ngIf="!isMultipleLinkedItem">
        <div id="searchOuter" *ngIf="((autocomplete.value && autocomplete.valid)|| linkedItemsClicked) && searchFilter.length>0"> 
          <div  [ngClass]="searchFilter.length <= 2 ? 'search-class-min': 'search-class'"  class="w-100" clickOutside (clickOutside)="clickedOutside()">
            <div id="searchData" class="search-data">
              <ng-scrollbar [trackX]="false" >
              <mat-option attr.automationId='Srch_{{automationId}}_searchFilter' *ngFor="let option of searchFilter" (click)='SelectedRecord(option)' >
                  <div class="LW14 text-capitalize" > {{ option.itemNumber }} - {{
                    option.itemDescription }}</div>
                </mat-option>
              </ng-scrollbar>
            </div>  
          </div>         
        </div>
      </div>
     
      <i  attr.automationId='Icn_{{automationId}}_cancel' aria-hidden="true" [ngClass]="!autocomplete.value && !isRetailItemSelected  ? 'icon-Search' : 'icon-Cancel'" class="cursor" (click)="filterFucntion($event)"></i>
    </mat-form-field>
  </section>
  