
import { Injectable } from '@angular/core';
import {  optionstypesenum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { API, UI } from '../../letter-setup/template.model';
import { LetterTableContent } from '../../letter-setup/letter.model';
import { SorTypeenum } from '../../shared/virtual-scroller/virtual-scroller.model';
import { TemplateDataService } from '../../shared/service/data- services/template.data.service';
import { Localization } from 'src/app/common/localization/localization';
import { RetailLocalization } from '../../common/localization/retail-localization';

@Injectable()
export class LetterLandingBusiness {
  captions: any;

  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */
  constructor(
    private localization: Localization, private templateDataService: TemplateDataService,
    private retailLocalization : RetailLocalization) {
    this.captions = this.localization.captions;
  }
  public async create(letter: UI.Template): Promise<boolean> {
    const apiModels: boolean = await this.templateDataService.createTemplate(this.APIMapper(letter));
    return apiModels;
  }

  public async getNextListOrder(type: number): Promise<number> {
    const listOrder = await this.templateDataService.getNextListOrder(type);
    return listOrder;
  }

  public async getTemplates(type: number, includeInactive: boolean): Promise<LetterTableContent[]> {
    const apiModels: API.Template[] = await this.templateDataService.getAllTemplatesByType(type, includeInactive);
    return apiModels.map(x => this.uiMapper(x));
  }


  private uiMapper(template: API.Template): LetterTableContent {
    return {
      code: template.code,
      name: template.name,
      listOrder: template.listOrder,
      active:  template.isActive,
      isInActive: template.isDefault,
      id: template.id,
      templateData: atob(template.value)
    } as LetterTableContent;
  }

  private APIMapper(letterSetup: UI.Template): API.Template {
    return {
      // Implement property mapping
      code: letterSetup.code,
      type: letterSetup.type,
      name: letterSetup.name,
      value: btoa(this.retailLocalization.curlyQuotesfix(letterSetup.templateData)),
      isActive: letterSetup.active,
      listOrder: letterSetup.listOrder,
      id: letterSetup.id ? letterSetup.id : 0,
      isDefault: letterSetup.default,

    } as API.Template;
  }
  public APILisOrderMapper(letterSetup: LetterTableContent, letterType): UI.Template {
    return {
      // Implement property mapping
      code: letterSetup.code,
      type: letterType,
      name: letterSetup.name,
      active: letterSetup.active,
      default: letterSetup.isInActive,
      templateData: letterSetup.templateData,
      value: null,
      checked: false,
      listOrder: letterSetup.listOrder,
      id: letterSetup.id ? letterSetup.id : 0

    } as UI.Template;
  }
 
  getTableHeaderOptions() {
    return [
      {
        key: 'code',
        description: this.captions.tbl_hdr_cardTemplateCode,
        alignment: 'textLeft',
        sorting: true,
        searchable: true
      },
      {
        key: 'name',
        description: this.captions.tbl_hdr_cardTemplateName,
        alignment: 'textLeft',
        sorting: false,
        searchable: false
      },
      {
        key: 'listOrder',
        description: this.captions.tbl_hdr_listOrder,
        alignment: 'textLeft',
        sorting: false,
        searchable: false
      }, {
        key: 'active',
        description: this.captions.tbl_hdr_active,
        alignment: 'textLeft',
        sorting: false,
        searchable: true
      }
    ];
  }

 getTableOptions(isViewOnly) {
  return [{ 
    options:[optionstypesenum.edit,optionstypesenum.delete, optionstypesenum.dragdrop],
    defaultsortingColoumnKey: 'id',
    defaultSortOrder: SorTypeenum.desc
  }];
 }

}

