import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { buttonTypes, ComponentTypes, ConciergeTrackingType } from '@constants/commonenums';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { cloneDeep, differenceBy } from 'lodash';
import { Subscription } from 'rxjs';
import { ISubscription } from 'rxjs-compat/Subscription';

@Component({
  selector: 'app-hotel-concierge-list',
  templateUrl: './hotel-concierge-list.component.html',
  styleUrls: ['./hotel-concierge-list.component.scss']
})
export class HotelConciergeListComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() data: any;
  conciergeTrackingList: any[] = [];
  searchString: string = '';
  searchConfig: FieldConfig[];
  actionBtn: ButtonValue;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  isEdit: boolean = false;
  newItem: string = '';
  subscriptions: Subscription = new Subscription();
  newAndUpdatedItem: any[] = [];
  deletedItems: any[] = [];
  arrayTobeCompared = [];
  ConciergeTrackingType = ConciergeTrackingType;
  conciergeTrackingType: ConciergeTrackingType;

  constructor(private dialog: MatDialog, private _ps: PartyService, private _ts: TranslateService, private _pps: PopupService) { }

  ngOnInit() {
    this.conciergeTrackingList = cloneDeep(this.data.response);
    this.arrayTobeCompared = cloneDeep(this.data.response);
    this.conciergeTrackingType = this.data.conciergeTrackingType;
    this.setDefaultValue();
    this.setControlConfig();
  }

  ngAfterViewInit() {
    this.setUpSubscriptions();
  }

  setUpSubscriptions() {
    this.subscriptions.add(this.components.first.form.valueChanges.subscribe(val => {
      this.searchString = val.searchText;
    }));
  }

  selectedItem(item) {
    this.setupReservationFormValues(this.conciergeTrackingType, item);
    this._ps.conciergeTrackingDialogRef.close(item);
  }

  setupReservationFormValues(conciergeTrackingType, selectedItem) {

    switch (conciergeTrackingType) {
      case ConciergeTrackingType.Hotel:
        this._ps.reservationFormGroup.get("selectedHotelId").setValue(selectedItem.Id);
        this._ps.reservationFormGroup.get("selectedConciergeId").setValue('');
        break;
      case ConciergeTrackingType.Concierge:
        this._ps.reservationFormGroup.get("selectedConciergeId").setValue(selectedItem.Id);
        break;
      default:
        break;
    }
    this._ps.reservationFormGroup.markAsDirty();
    this._ps.reservationFormGroup.updateValueAndValidity();
  }

  setDefaultValue() {
    if (!this.newItem) {
      this.newItem = this.conciergeTrackingType == ConciergeTrackingType.Hotel ? this._ts.instant('addHotel') : this._ts.instant('addConcierge');
    }
  }

  clearValue() {
    if (this.newItem == this._ts.instant('addHotel') || this.newItem == this._ts.instant('addConcierge')) {
      this.newItem = '';
    }
  }

  setControlConfig() {
    this.searchConfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this._ts.instant('searchText'),
        class: 'hotel-concierge__search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: (event) => this.clearSearchField(event)
      }
    ];

    this.actionBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Edit',
      disbaledproperity: false,
      customclass: 'hotel-concierge__search-btn'
    };
  }

  clearSearchField(event) {
    this.searchString = '';
    this.components.first.form.get('searchText').setValue('');
  }

  setUpDeletedAndUpdatedValues() {
    this.deletedItems = differenceBy(this.arrayTobeCompared, this.conciergeTrackingList, 'Id');
    this.newAndUpdatedItem = differenceBy(this.conciergeTrackingList, this.arrayTobeCompared, 'Name');
  }

  buildRequest() {
    if (this.conciergeTrackingType == ConciergeTrackingType.Hotel) {
      const postData = {
        NewAndUpdatedHotels: this.newAndUpdatedItem,
        RemovedHotelIds: this.deletedItems.map(({ Id }) => Id)
      }
      return postData;
    }
    else {
      const postData = {
        NewAndUpdatedConcierges: this.newAndUpdatedItem,
        RemovedConciergeIds: this.deletedItems.map(({ Id }) => Id)
      }
      return postData;
    }
  }

  isItemsValid() {
    let tempNewAndUpdatedItems = this.newAndUpdatedItem.filter(item => item.Name != '');
    return tempNewAndUpdatedItems.length == this.newAndUpdatedItem.length ? true : false;
  }

  postUpdatedValues() {
    let requestData = this.buildRequest();
    if (this.conciergeTrackingType == ConciergeTrackingType.Hotel) {
      this.subscriptions.add(this._ps.updateHotelList(requestData).subscribe(data => {
        this.getConciergeTrackingList();
      }));
    }
    else {
      this.subscriptions.add(this._ps.updateConciergeList(requestData).subscribe(data => {
        this.getConciergeTrackingList();
      }));
    }
  }

  getConciergeTrackingList() {
    this.subscriptions.add(this._ps.getConciergeTrackingList(this.conciergeTrackingType, this._ps.reservationFormGroup.value.selectedHotelId).subscribe(data => {
      if (data.Payload) {
        this.conciergeTrackingList = cloneDeep(data.Payload);
        this.arrayTobeCompared = cloneDeep(data.Payload);
      }
    }));
  }

  editOrPost(isEdit: boolean) {
    if (isEdit) {
      this.setUpDeletedAndUpdatedValues();
      if (this.deletedItems.length > 0 || this.newAndUpdatedItem.length > 0) {
        if (this.isItemsValid()) {
          this.postUpdatedValues();
          this.newAndUpdatedItem = [];
          this.deletedItems = [];
        }
        else {
          this.showPopUp(true);
          isEdit = false;
        }

      }
    }
    this.isEdit = !isEdit;
    this.actionBtn.label = this.isEdit ? this._ts.instant('serverDoneText') : "edit";
  }

  addItem() {
    if (!(this.newItem == this._ts.instant('addHotel') || this.newItem == this._ts.instant('addConcierge'))) {
      let newItem: any = {};
      if (this.conciergeTrackingType == ConciergeTrackingType.Hotel) {
        newItem = { Id: null, Name: this.newItem };
      }
      else {
        newItem = { Id: null, Name: this.newItem, HotelId: this._ps.reservationFormGroup.value.selectedHotelId };
      }

      this.conciergeTrackingList.push(newItem);
      this.conciergeTrackingList = this.conciergeTrackingList.filter(a => a.Name != "");
      this.newItem = '';
      this.setDefaultValue();
    }
  }

  deleteItem(item: any) {
    item.Id ? this.showPopUp(false, item) : this.conciergeTrackingList = this.conciergeTrackingList.filter(a => a.Name != item.Name);
  }

  showPopUp(isAlert, currentItem?) {
    let confirmedActionSubscription: ISubscription = null;
    const popUpMessage = [{
      confirmationMessage: isAlert ?
        this._ts.instant(this.conciergeTrackingType == ConciergeTrackingType.Hotel ? 'hotelEmptyValidation' : 'conciergeEmptyValidation') :
        this._ts.instant(this.conciergeTrackingType == ConciergeTrackingType.Hotel ? 'deleteHotelConfirmation' : 'deleteConciergeConfirmation'),
      dialogTitle: this._ts.instant('deleteHotelConfirmation'),
      showAlert: isAlert ? true : false,
    }]
    const componentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: isAlert ? 'static' : 'active',
      dimensionType: 'small',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
    };
    const confirmationPopUpRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: isAlert ? 'auto' : '400px',
      height: isAlert ? 'auto' : '300px',
      autoFocus: false,
      data: {
        title: isAlert ? 'Alert' : 'deleteTitle',
        update: 'ok',
        cancel: "cancel",
        componentDetails,
        back: false,
        from: ComponentTypes.commonconfirmmessage,
        standalone: true
      }
    });
    confirmedActionSubscription = this._pps.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.commonconfirmmessage) {
        this.conciergeTrackingList = this.conciergeTrackingList.filter(a => a.Id != currentItem.Id);
      }
    });
    confirmationPopUpRef.afterClosed().subscribe(() => {
      confirmedActionSubscription.unsubscribe();
    })
  }

  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe() }
    this._ps.conciergeTrackingDialogRef = null;
  }
}
