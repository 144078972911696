<div class="blockingtable-selection">
  <div class="blockingtable-selection__header">
    <div  class="reservation-select-label table-selection__select-label">
      <span class="blockingtable-selection__view-label seat-text-fields" *ngIf="updatedSelectedTableNames.length">{{'waitListPopUpSelectTable'|translate}} - {{updatedSelectedTableNames}}</span>
    </div>
    <div class="blockingtable-selection__view-legends">
      <label *ngIf="isShiftNeedToShow" class="form-helper-text">{{'shiftText' | translate}}</label>
      <dynamic-form [config]="Shiftconfig" #form="dynamicForm" >
      </dynamic-form>
      <label class="form-helper-text" for="">{{'floorText' | translate}}</label>
       <dynamic-form [config]="config" #form="dynamicForm" >
      </dynamic-form>
      </div>
  </div>
  <div class="blockingtable-selection__canvas-section" *ngIf="partyService?.layoutCofiguartion?.tables?.length > 0">
    <app-layout-view [layoutConfig]="layoutConfiguration" [editData]="editData"></app-layout-view>
  </div>
</div>