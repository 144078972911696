<div class="reservation-guest--container" [class.show-search]="allowGuestMap && showGuestSearch">

    <div class="guest-list">

        <div class="guest-list__action-row">
            <div class="error-list-container" *ngIf="!hasPrimaryGuest && primaryGuestErr?.length">
                <div class="">
                    {{primaryGuestErr}}
                </div>
            </div>
            <div class="error-list-container" *ngIf="allocationTypeError?.length; else addonErr">
                <div class="">
                    {{allocationTypeError}}
                </div>
            </div>

            <ng-template #addonErr>
                <div class="error-list-container" *ngIf="addonValidationsArr?.length; else warning">
                    <div class="" *ngFor="let err of addonValidationsArr | slice:0:2">
                        {{err}}
                    </div>
                </div>
            </ng-template>

            <ng-template #warning>
                <div class="warning-list-container" *ngIf="addonWarningArr?.length">
                    <div class="" *ngFor="let err of addonWarningArr | slice:0:2">
                        {{err}}
                    </div>
                </div>
            </ng-template>

            <ng-container *ngIf="allowGuestMap">

                <activities-app-button [buttontype]="addGuestButton" [hidden]="selectedGuestRowIndex==-1 && selectedGuests.length!==1"
                    (valueChange)='addGuestClickHandler()'></activities-app-button>
                <div class="guest-select__guest-book" [hidden]="showGuestSearch" (click)="showGuestSearch = !showGuestSearch" [matTooltip]="'SearchGuest' | translate">
                    <i class="icon-user guest-search-primary-icon"></i>
                    <i class="icon-search guest-search-addtioanl-icon"></i>
                </div>
            </ng-container>

           
           

        </div>

        <ng-container *ngFor="let guest of selectedGuests; let i=index">
            <div class="guest-detail" [class.selected]="selectedGuestRowIndex == i" (drop)="onDropGuest(i)"
                (dragover)="allowDrop($event, guest)" (click)="_additionalData[guest.bookingType.Id+'_'+guest.coverType.Id] && swapGuest(guest, i)"
                [class.error-guest]="!guest.validAddon || !guest.contact || guest.missingCustomFields?.length || _additionalData[guest.bookingType.Id+'_'+guest.coverType.Id]">
                <div class="guest-detail__primary-guest-info">
                    <!-- <span *ngIf="guest.contact" class="icon-VIP iconVIP seat-border-color"
                    [class.iconVIPactive]="guest.contact.IsVip"></span> -->
                    <!-- <span *ngIf="guest.contact?.IsVip" class="icon-VIP iconVIP iconVIPactive seat-border-color"></span> -->
                    <img *ngIf="guest.contact && guest.primaryGuest" class="guest-detail__primary-guest-icon" src="assets/images/primary-guest.svg" alt="" [matTooltip]="'PrimaryGuestText' | translate">
                    <img *ngIf="guest.contact && !guest.primaryGuest" class="guest-detail__primary-guest-icon cursor-pointer" src="assets/images/primary-guest-unselect.svg" alt=""
                    [matTooltip]="'setAsPrimaryGuestText' | translate" (click)="changePrimaryGuest(guest)">
                </div>
                <div class="guest-detail__guest-initial" [ngStyle]="{'background-color': '' | randomColor}"
                    *ngIf="guest.contact" >
                    {{guest?.contact | guestNameFormatter}}
                </div>
                <div class="guest-detail__contact" *ngIf="guest.contact" (click)="editGuest(guest.contact, i, $event)">
                    <span>
                        {{guest.contact.FirstName}} {{guest.contact.LastName}}
                        <span *ngIf="guest.contact?.IsVip" class="icon-VIP iconVIP iconVIPactive seat-border-color" [matTooltip]="'guestBookVIPText' | translate"></span>
                    </span>
                    <span class="guest-detail__contact--sub">
                        <ng-container
                            *ngIf="(guest.contact.PhoneNumber | phoneNumberSelector: guest.contact.PhoneNumber2: null: null: '': false:guest.contact.CountryId: guest.contact.CountryId2) as guestPhoneNumber">
                            {{ guestPhoneNumber }}
                            {{guestPhoneNumber && guest.contact.EmailAddress || guest.contact.EmailAddress2 ? ',' : ''}}
                        </ng-container>
                        {{ guest.contact.EmailAddress || guest.contact.EmailAddress2}}
                        <!--Needed for showing stay information-->
                        <!-- <span *ngIf="guest.contact.StayConfirmationCode" [ngbPopover]="stayInformation" (click)="getStayInfo($event,guest.contact.StayConfirmationCode)" container="body" placement="bottom" [autoClose]="'outside'"> , <span class="guest-detail__stay-info">More...</span>
                            </span>
                        <ng-template #stayInformation>
                            <div>
                                guest.contact.FirstName guest.contact.LastName
                                <div class="guest-card__name" *ngIf="guest.contact.StayConfirmationNumber">Confirmation Number: <span class="guest-card__details">guest.contact.StayConfirmationNumber</span></div>
                                <div class="guest-card__name" *ngIf="guest.contact.RoomNumber">Room Number: <span class="guest-card__details">guest.contact.RoomNumber</span></div>
                                <div class="guest-card__name" *ngIf="guest.contact.Staystartdate || item.StayEnddate">Arrival Date / Departure Date: <span class="guest-card__details">(guest.contact.Staystartdate | formatSettingsDate:
                                    cacheService.settings.value.General.DateFormat) || 'NA'   / guest.contact.StayEnddate | formatSettingsDate:
                                        cacheService.settings.value.General.DateFormat </span></div>
                                  </div>

                                   <div>
                                  <div class="guest-card__name seat-grid-header">{{guest.contact.Title}} {{guest.contact.FirstName}} {{guest.contact.LastName}}</div>
                                  <div class="guest-card__phone" *ngIf="guest.contact.PhoneNumber">{{guest.contact.PhoneNumber}}</div>
                                  <div class="guest-card__email" *ngIf="guest.contact.EmailAddress">{{guest.contact.EmailAddress}}</div>
                                  
                                 
                                    <app-search-based-guest-data [guestData]="guest.contact"></app-search-based-guest-data>
                                </div> -->
                                      
                  
   
                            <!-- <div>
                                {{guest.contact.FirstName}} {{guest.contact.LastName}}
                                <div class="guest-card__name" *ngIf="guest.contact.StayConfirmationNumber">Confirmation Number: <span class="guest-card__details">{{guest.contact.StayConfirmationNumber}}</span></div>
                                <div class="guest-card__name" *ngIf="guest.contact.RoomNumber">Room Number: <span class="guest-card__details">{{guest.contact.RoomNumber}}</span></div>
                                <div class="guest-card__name" *ngIf="guest.contact.Staystartdate || item.StayEnddate">Arrival Date / Departure Date: <span class="guest-card__details">{{(guest.contact.Staystartdate | formatSettingsDate:
                                    cacheService.settings.value.General.DateFormat) || 'NA'  }} / {{guest.contact.StayEnddate | formatSettingsDate:
                                        cacheService.settings.value.General.DateFormat }}</span></div>
                                  </div> 
                            
                          </ng-template> -->
                    </span>
                    <span class="guest-detail__contact--member" *ngIf="guest.contact.TrackMembershipNumber">
                        <span>
                            {{guest.contact.RateType}}
                        </span>
                        <span>
                            {{guest.contact.IsMemberActive ? ('Active' | translate) : ('expired' | translate)}}
                        </span>
                        <span>
                            {{guest.contact.TrackMembershipNumber}}
                        </span>
                        <span class="guest-detail__points-info" *ngIf="guest?.contact?.MemberInfo?.Bucket?.length" container="body" placement="bottom" [autoClose]="'outside'"
                        [ngbPopover]="availableMinutesPopover" (click)="getBucketList(guest,$event)">
                            {{_MESSAGES.label.availableMinutes | translate}}
                        </span>
                    </span>

                </div>
                <div class="guest-detail__action-info">
                    <div class="guest-detail__type">
                        <ng-container *ngIf="guest?.bookingType.Name">{{guest.bookingType.Name}}
                            <ng-container *ngIf="guest?.coverType.Name"> | </ng-container>
                        </ng-container>
                        <ng-container *ngIf="guest?.coverType.Name">{{guest.coverType.Name}}</ng-container>
                        <ng-container *ngIf="!guest.bookingType.Name && !guest?.coverType.Name">{{'Guest' | translate}}
                            {{i+1}}</ng-container>
                    </div>

                    <activities-app-button *ngIf='_additionalData[guest.bookingType.Id+"_"+guest.coverType.Id]; else guestSelect'
                        [buttontype]="removeGuestButton" (valueChange)='removeGuest(guest, i, $event);' [hidden]="!allowRemoveGuest"></activities-app-button>
                        

                    <ng-template #guestSelect>
                        <activities-app-button class="guest-detail__select-guest-btn" *ngIf="!guest.contact"
                            [buttontype]="selectGuestButton" (valueChange)='changeGuest(i, $event);'></activities-app-button>
                        <activities-app-button *ngIf="guest.contact && (allowChangeGuest || !guest.contact?.Id)" [buttontype]="changeGuestButton"
                            (valueChange)='changeGuest(i, $event);'></activities-app-button>
                        <activities-app-button *ngIf="!guest.primaryGuest && guest.contact && (allowChangeGuest || !guest.contact?.Id || !_individualMemberPaymentAllowed)" [buttontype]="clearGuestButton"
                            (valueChange)='clearGuest(i, $event);'></activities-app-button>
                    </ng-template>
                    <div class="guest-detail__expand-icon" [class.close]="guest.showAddons"
                        *ngIf="guest.contact && guest.addons?.length && (_individualMemberPaymentAllowed || guest.primaryGuest)" (click)="guest.showAddons=!guest.showAddons">
                        <span class="icon icon-next"></span>
                    </div>
                </div>
                <span class="error-icon"
                    *ngIf="submitAttempt && (!guest.contact && _individualMemberPaymentAllowed) || (guest.contact && !guest.validAddon || guest.missingCustomFields?.length || guest.missingMemberType || guest.missingMandatoryPreference?.length)"
                    (click)="showGuestError(guest)">
                    <i class="icon-exclamation aside__menu-warning"><i class="path1"></i><i class="path2"></i><i
                            class="path3"></i></i>
                </span>
            </div>
            <!-- && data?.isConfirmedReservation  -->
            <!-- [ngClass]="{'disabled': data?.addPrivateLessonBooking}"  -->
            <reservation-guest-addons *ngIf="guest.contact && guest.addons?.length && (_individualMemberPaymentAllowed || guest.primaryGuest)" [hidden]="!guest.showAddons"
                class="guest-detail__addon-section" [class.show-addons]="guest.showAddons" [addons]="guest.addons"
                (addonsChange)="guestAddonChanged(guest)"></reservation-guest-addons>
        </ng-container>

    </div>
    <div class="guest-select-container" *ngIf="allowGuestMap">
        <div class="close-icon-container">
            <span class="icon-Group-591 guest-close-icon" *ngIf="showGuestSearch" (click)="showGuestSearch = false"></span>
        </div>
        <!-- <button class="icon-Group-591 rounded-icon" (click)="selectedGuestRowIndex=-1"></button> -->
        <app-guest-search class="guest-search-component" [hidden]="!showGuestSearch" [bookingData]="bookingData" [selectedGuestData]="selectedGuests[selectedGuestRowIndex == -1 ?  0 : selectedGuestRowIndex]" [hasPrimary]="hasPrimaryGuest"
            [allowGuestSelect]="selectedGuestRowIndex>=0 || selectedGuests.length===1" (guestSelect)="onGuestSelect($event)"></app-guest-search>
    </div>
</div>

<div>
    <ng-template #availableMinutesPopover>
      <div *ngIf="memberBucketList?.length" class="availableRounds">
        <div *ngFor="let bucket of memberBucketList" class="availableRounds__details">
            <span class="availableRounds__details-name">{{bucket.BucketName}}</span>
            <span class="availableRounds__details-round">{{bucket.EligibleRounds}}</span>
        </div>
      </div>
    </ng-template>
  </div>
