import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes, ReservationType, TableBlockingRuleFrequencyType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { SettingsService } from '@services/settings.service';
import { TablesService } from '@services/tables.service';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';
import { ISubscription } from 'rxjs/Subscription';
import { LocalizedDatePipe } from '@pipes/localize-date.pipe'
import { DateAdapter } from '@angular/material/core';
import { LocaleService } from '@app/shared/services/locale.service';

@Component({
  selector: 'app-blocking-date-selection',
  templateUrl: './blocking-date-selection.component.html',
  styleUrls: ['./blocking-date-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockingDateSelectionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() tabLabel: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatCalendar, { static: true }) datePicker: MatCalendar<Date>;
  waitDate: any = new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
  datePipe: DatePipe;
  selectedIndex: number;
  tabSubscription: ISubscription;
  DatePickerSubscription: ISubscription;
  date: Date;
  maxdate: Date;
  mindate: Date;

  constructor(public partyService: PartyService, @Inject(COMPONENTINPUT) private data, private ps: PopupService, public fb: UntypedFormBuilder,private dateAdapter:DateAdapter<any>, private ls:LocaleService,
    private ss: SettingsService, private dialog: MatDialog, private ts: TranslateService, private cs: CacheService, private tableService: TablesService , public localizedDatePipe :  LocalizedDatePipe) {
    this.datePipe = new DatePipe(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_userLocale`));
  }

  ngOnInit() {
    this.dateAdapter.setLocale(this.ls.defaultLocale());
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === BlockingDateSelectionComponent);
    const selectedDate = this.data ? this.getGetInitialDate() : new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    this.date = selectedDate;
    this.waitDate = selectedDate;
    this.mindate = this.getBeforeDays();
    this.partyService.reservationFormGroup = this.fb.group({});

    if (this.partyService.reservationType === ReservationType.Blockingrule) {
      this.dynamicformgroup(1);
    }
    if (this.partyService.reservationType === ReservationType.Unblock) {
      this.dynamicformgroup(2);
      this.date = selectedDate;
    }
    if (this.data) {
      //this.maxdate = selectedDate;
      this.getPopupNotificationForPastDate();
    } else {
      let date = this.getBeforeDays();
      this.date = date;
    }

    this.DatePickerSubscription = this.datePicker.selectedChange.subscribe(date => {
      this.reservationFormChange();
      this.tabLabel.emit(this.localizedDatePipe.transform(date, 'MMM dd'));
      this.reservationFormChange();
      this.waitDate = date;
    });
    this.reservationFormChange();
    this.tableService.getBlockingRulesFortheDate(new Date(selectedDate))
  }

  getBeforeDays(date?) {
    let days = 3; // Days you want to subtract
    let d = date ? new Date(date) : new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    let latestdate = new Date(d.setDate(d.getDate() - days)).setHours(0, 0, 0, 0);
    console.log(d.toString());
    return new Date(latestdate);
  }

  getPopupNotificationForPastDate() {
    let selectedDatesval = new Date(this.waitDate);
    if (selectedDatesval < this.mindate) {//restrict for past reservations > 72hours
      let message = this.ts.instant('minDateValidationMessage', {hours: this.cs.AllowEditForPastReservationsInDays * 24});
      const popUpMessage = [{
        confirmationMessage: message, dialogTitle: 'confirm', showAlert: true
      }];
      const componentDetails: ComponentDetails = {
        componentName: ConfirmationPopupComponent,
        popupType: 'action',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: popUpMessage,
        popupTitle: this.ts.instant('alert')
      };
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '450px',
        height: 'auto',
        data: {
          showAction: true,
          update: 'ok',
          componentDetails,
          from: ComponentTypes.dailyEstimates
        }
      });
    }
  }


  getGetInitialDate() {
    let date = new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)); // need to set restaruent time
    if (this.data.Frequency.Type == TableBlockingRuleFrequencyType.EveryWeekDay) {
      let floorPlans = this.getLayoutFilter(date);
      while (floorPlans && floorPlans.length > 0) {
        date = this.addDays(date, 7);
        floorPlans = this.getLayoutFilter(date);
      }
      let getdate = this.GetFutureNearestDayOfWeek(date, this.data.Frequency.DayOfWeek)
      return getdate;
    }
    else if (this.data.Frequency.Type == TableBlockingRuleFrequencyType.EveryDay) {

      let floorPlans = this.getLayoutFilter(date);
      while (floorPlans && floorPlans.length > 0) {
        date = this.addDays(date, 1);
        floorPlans = this.getLayoutFilter(date);
      }
      return date;
    }
    else {
      return this.data.Frequency.Date;
    }

  }

  getLayoutFilter(date) {
    let selectedDate = new Date(date).setHours(0, 0, 0, 0);
    let floorPlans = Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, date);
    let filterfloor = floorPlans.filter(obj => {
      return obj.Dates != null
    });
    if (filterfloor && filterfloor.length > 0) {
      filterfloor = filterfloor
        .filter((element) =>
          element.Dates.some((subElement) => new Date(subElement.Date).setHours(0, 0, 0, 0) === selectedDate))
        .map(element => {
          return element;
        });
    }

    return filterfloor;
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  GetFutureNearestDayOfWeek(date, dayOfWeek) {
    let DaysInWeek = 7;
    let currentDayOfWeek = date.getDay();
    let differenceInDays = dayOfWeek - currentDayOfWeek;
    if (differenceInDays < 0) {
      differenceInDays += DaysInWeek;
    }
    return this.addDays(date, differenceInDays);
  }

  dynamicformgroup(type) {
    if (type == 1) {
      this.partyService.reservationFormGroup = this.fb.group({

        selectedDate: [''],
        selectedTable: [''],
        selectedTableFloor: [''],
        selectedTableShift: [''],
        selectedFrequency: [''],
        selectedShift: [''],
        selectedStartDate: [''],
        selectedEndDate: [''],
        selectedBlockingType: [''],
        selectedFromTime: [''],
        selectedToTime: [''],
        selectedBlockBy: [''],
        blockingReason: ['']

      });

    } else {
      this.partyService.reservationFormGroup = this.fb.group({
        selectedDate: [''],
        selectedTable: [''],
        selectedTableFloor: [''],
        selectedTableShift: [''],
        selectedFrequency: [''],
        selectedBlockingType: [''],
        selectedFromTime: [''],
        selectedToTime: [''],
        selectedBlockBy: [''],
        blockingReason: ['']

      });
    }
    Object.keys(this.partyService.reservationFormGroup.controls).forEach((control) => {
      this.partyService.reservationFormGroup.get(control).setValidators(Validators.required);
      this.partyService.reservationFormGroup.get(control).updateValueAndValidity();
    });

  }
  setSelectedDate(date) {
    this.waitDate = date;
    this.reservationFormChange();
    this.getPopupNotificationForPastDate();
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
    if (this.selectedIndex >= 0) {
      this.partyService.reservationFormGroup.get('selectedTable').setValue('');

    }
    this.tableService.getBlockingRulesFortheDate(new Date(date));
  }

  ngAfterViewInit() {
    this.tabSubscription = this.partyService.tabChange$.subscribe((data) => {
      if (data === this.selectedIndex) {
        this.reservationFormChange();
      }
    });
  }

  ngOnDestroy() {
    this.partyService.reservationType = null;
    this.datePicker.selectedChange.unsubscribe();
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
    if (this.DatePickerSubscription) {
      this.DatePickerSubscription.unsubscribe();
    }

  }
  getDateVal(waitDate) {

    let datec = Utilities.number_suffix(new Date(waitDate).getDate());
    return datec
  }
  reservationFormChange() {

    if (this.checkdateAreSame(this.waitDate, this.partyService.reservationFormGroup.controls.selectedDate.value)) {
      let date = this.waitDate._d == undefined ? this.waitDate : this.waitDate._d;
      this.partyService.reservationFormGroup.get('selectedDate').setValue(date);
      if (this.selectedIndex >= 0) {
        let timeval = this.localizedDatePipe.transform(this.waitDate , 'MMM DD');
        this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = timeval;
      }
    }

      this.ps.formValid$.next(
        {
          isFormValid: this.partyService.reservationFormGroup.value.selectedDate ? true : false,
          currentTab: this.selectedIndex
        } as IFormValidDetails);
  }

  checkdateAreSame(dateone, datetwo) {
    let dateoneval = new Date(dateone).setHours(0, 0, 0, 0);
    let dattwoeval = new Date(datetwo).setHours(0, 0, 0, 0);

    let getval = dateoneval != dattwoeval;

    return getval;
  }
}
