<section class='addCommission h-100'>
  <div class='addCommissionForm'>
    <ng-scrollbar >
      <form [formGroup]="FormGrp" autocomplete="off" class="pl-4 pr-4 pt-2 pb-2 h-100">

        <div>
          <mat-form-field class="example-full-width50" [floatLabel]="floatLabel">
            <input attr.automationId = 'Txt_{{automationId}}_templateCode' matInput placeholder={{captions.TemplateCode}}  class="text-uppercase" inputtype="capitalise,notallowspace,nospecailchar"
              formControlName="tempCode" [maxlength]="10" [readonly]="isEdit" required>
            <mat-error class="" *ngIf="FormGrp.controls['tempCode'].hasError('required') && FormGrp.controls['tempCode'].touched && !FormGrp.controls['tempCode'].valid">
              {{captions.MissingTemplateCode}}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="example-full-width50" [floatLabel]="floatLabel">
            <input attr.automationId= 'Txt_{{automationId}}_templateName' matInput placeholder={{captions.TemplateName}} formControlName="tempName" [maxlength]="50" required>
            <mat-error class="" *ngIf="FormGrp.controls['tempName'].hasError('required') && FormGrp.controls['tempName'].touched && !FormGrp.controls['tempName'].valid">
              {{captions.MissingTemplateName}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class='mt-3 radio-class'>
          <div><label for="" class="LW12 w-5 verAlignMid label-color">{{captions.class}}</label></div>
          <mat-radio-group attr.automationId = 'Rdo_{{automationId}}_commissionClasses' [(ngModel)]="SelectedType" formControlName="on" class="golf__radio">
            <mat-radio-button class='pr-2 LW14 w-25 golf--form-radio-button' *ngFor='let class of commissionClasses' value={{class.value}}>{{class.viewValue}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <mat-form-field class=" selectLevel" *ngIf="SelectedType == 'CC'" [floatLabel]="floatLabel">
            <input attr.automationId = 'Txt_{{automationId}}_selectedCC' matInput [maxlength]="9" inputtype="nonnegative,nodecimal" formControlName="selectedCC" [placeholder]="captions.level" required>
          </mat-form-field>
        </div>

        <div class='pt-4 pb-2 LW14 blckClr text-capital bold'>
          <label> {{captions.SplitLevel}}</label>
        </div>
        <div formArrayName="splitLevels" [ngClass]="{'button_invalid': IsViewOnly}">
          <div [formGroupName]="i" *ngFor="let item of FormGrp.get('splitLevels')['controls']; let i = index;" class='d-flex align-items-center'>
            <mat-form-field class="example-full-width50 mr-5" [floatLabel]="floatLabel">
              <input attr.automationId= 'Txt_{{automationId}}_levelDescription' matInput placeholder={{captions.LevelDescription}} formControlName="level" [maxlength]="30" required>

              <mat-error class="" *ngIf="FormGrp['controls'].splitLevels['controls'][i]['controls']['level'].hasError('required') && FormGrp['controls'].splitLevels['controls'][i]['controls']['level'].touched && !FormGrp['controls'].splitLevels['controls'][i]['controls']['level'].valid">
                {{captions.MissingLevelDescription}}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width50" [floatLabel]="floatLabel">
              <input attr.automationId= 'Txt_{{automationId}}_missingPercentage'  matInput percentinputtype="nonnegative,decimal,validPercentage"  placeholder={{captions.Percentage}} [maxpredecimalvalue]="percentageMaxLength" formControlName="split">
              <mat-error class="" *ngIf="FormGrp['controls'].splitLevels['controls'][i]['controls']['split'].hasError('required') && FormGrp['controls'].splitLevels['controls'][i]['controls']['split'].touched && !FormGrp['controls'].splitLevels['controls'][i]['controls']['split'].valid">
                {{captions.MissingPercentage}}
              </mat-error>
            </mat-form-field>
              <span attr.automationId= 'Icn_{{automationId}}_addItem' class="icon-Plus" [ngClass]="FormGrp['controls'].splitLevels['controls'][i]['controls']['level'].hasError('required') || FormGrp['controls'].splitLevels['controls'][i]['controls']['split'].hasError('required')?'disabled':''"
           (click)="addItem(i,'','')" *ngIf="FormGrp['controls'].splitLevels.value.length-1 == i"></span>
            <span class="icon-Minus" (click)="removeItem(i,'','')" *ngIf="!(FormGrp['controls'].splitLevels.value.length == 1)">
</span>
          </div>
        </div>
        <input attr.automationId= 'Txt_{{automationId}}_submit' type="submit"  [disabled]="!validateSaveVar" class="hidden-submit"
        tabindex="-1"  (click)="save()"/>
      </form>
    </ng-scrollbar>
  </div>
  <mat-dialog-actions>
    <div>
      <button mat-button attr.automationId= 'Btn_{{automationId}}_save' [ngClass]="validateSaveVar ? 'button_valid whitecolor text-capital body-bgcolor' : 'button--disabled '" class="LW14"
        (click)="save()" [disabled]="!validateSaveVar"> {{ isEdit ? captions.UPDATE : captions.SAVE}} </button>
      <button mat-button attr.automationId= 'Btn_{{automationId}}_cancel' class="LW14 ml-2 text-capital" (click)="onCancelClick()">{{captions.CANCEL}}</button>
    </div>
  </mat-dialog-actions>
</section>
