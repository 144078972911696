import { Pipe, PipeTransform } from "@angular/core";
import _ from "lodash";

@Pipe({
    name: 'searchData'
})
export class SearchDataPipe implements PipeTransform {

    transform(array, searchText='', field='Name'): any {
        if (array && _.isArray(array) && searchText) {
            return array.filter(o => o[field] && o[field].toLowerCase().includes(searchText.toLowerCase()));
        }
        return array || [];

    }
}

@Pipe({
    name: 'searchPartyByContact'
})
export class SearchPartyByContactPipe implements PipeTransform {

    transform(array, searchText=''): any {
        if (array && _.isArray(array) && searchText) {
            return array.filter(o => o.Contact?.FirstName && o.Contact.FirstName.toLowerCase().includes(searchText.toLowerCase())
            || o.Contact?.LastName && o.Contact.LastName.toLowerCase().includes(searchText.toLowerCase()));
        }
        return array || [];

    }
}