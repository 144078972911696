import { Component, Input, OnInit, Pipe, PipeTransform, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { PopupService } from '@app/popup-module/popup.service';
import { BookingBehavior, buttonTypes, ClassType, ComponentTypes, PartyState, PartyType, ReservationEmailNotificationType, ReservationType } from '@app/shared/constants/commonenums';
import { environment } from '@environments/environment';
import { urlConfig } from '@app/shared/constants/url-config';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PageMethod } from '@app/shared/models/InputContact';
import { DateStateDTO, StandByPartyDTO } from '@app/shared/models/InputReservation';
import { LayoutDTO, SettingsDTO, SupportedReservationEmailConfirmationTypes } from '@app/shared/models/RestaurantDTO';
import { PartyService } from '@app/shared/services/party.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { RejectStanbyComponent } from '../reject-stanby/reject-stanby.component';
import { GroupDate } from '../standby-list/standby-list.component';
import _ from 'lodash';
import { format } from 'date-fns';
import { ActivityBookingFunctions } from '@app/shared/utilities/activityBookings-functions';
import { LoaderService } from '@app/core/services/loader.service';
import { seatRetailService } from '@app/shared/services/seatretail.service';

@Component({
  selector: 'app-standby-listing',
  templateUrl: './standby-listing.component.html',
  styleUrls: ['./standby-listing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StandbyListingComponent extends Utilities implements OnInit {
  datePickerconfig: FieldConfig[];
  standbyParties: StandByPartyDTO[];
  @Input() data: any;
  _settings: SettingsDTO = {} as SettingsDTO;
  _layout:LayoutDTO ={} as LayoutDTO;
  subscriptions: Subscription = new Subscription();
  PartyState = PartyState;
  StandBYList: any = [];
  allActivitiesData: any;
  buttonToday: ButtonValue;
  RejectBtn: ButtonValue;
  isPrivateLesson: boolean;
  currentDate: any;
  searchconfig: FieldConfig[];
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;

  AcceptBtn: ButtonValue;
  AcceptDisabledBtn: ButtonValue;
  warningInfoDialogRef: any;
  searchText: string = '';
  showTodayButton: boolean;
  minDate: Date;
  constructor(public _as: AppService, public _ps: PartyService, public cs: CacheService, public dialog: MatDialog, private loaderService: LoaderService,
    public dashboardFunctions: DashboardFunctions,private abf:ActivityBookingFunctions, public popupService: PopupService, public translateService: TranslateService,public retailservice: seatRetailService) {
    super(dialog);
    this.subscriptions.add(cs.settings.subscribe(settings => {
      this._settings = settings;
    }));
    this.subscriptions.add(cs.layout.subscribe(layout => {
      this._layout = layout;
    }));
    this.currentDate = this.cs.headerDate;
  }

  ngOnInit() {
    
    this.allActivitiesData = this.data;
    this.minDate = new Date(this.cs.state.value.CurrentTime);
    this.minDate.setDate(this.minDate.getDate() - (this._settings.PropertySetting[0].MinDaysToBookPastReservations || 0));
    this.datePickerconfig = [
      {
        type: 'date',
        placeholder: 'chooseDate',
        name: 'reservationDate',
        class: 'standby-activities__date-section',
        label: 'timelineDate',
        value: this.cs.headerDate,
        appearance: true,
        showNavigation: true,
        minDate: this.minDate,
        disabled: false
      }];
    this.searchconfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.translateService.instant('searchText'),
        class: 'standby-activities__search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this)
      }
    ];
    this.buttonToday = {
      type: buttonTypes.actionSecondarySmall,
      label: this.translateService.instant('todayText'),
      customclass: 'headercontainer__today-btn',
    };
    this.RejectBtn = { label: this.translateService.instant('RejectText'), type: buttonTypes.actionSecondarySmall, customclass: 'user-card__actions__btn' , disbaledproperity : this.cs.isIframeEnabled }

    this.AcceptBtn = { label: this.translateService.instant('AcceptText'), type: buttonTypes.actionPrimarySmall, customclass: 'user-card__actions__btn' , disbaledproperity : this.cs.isIframeEnabled };
    this.AcceptDisabledBtn = { label: this.translateService.instant('AcceptText'), type: buttonTypes.actionPrimarySmall, disbaledproperity: true, customclass: 'user-card__actions__btn' };
    this.loadAllBookings(this.cs.headerDate);
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.components.first.form.valueChanges.subscribe(data => {
      this.currentDate = data.reservationDate;
      this.loadAllBookings(data.reservationDate);
      this.checkTodayButtonConfiguartion(data.reservationDate);
    }));
    this.subscriptions.add(this.components.last.form.valueChanges.pipe(debounceTime(1000),
      distinctUntilChanged()).subscribe(data => {
        this.searchText = data.searchText;
      }));
  }


  clearSearch(event) {
    this.components.last.form.get('searchText').setValue('');
  }

  loadStandbyParties() {
    let reservationData: DateStateDTO[];
    // if(moment(moment(this.currentDate).format('YYYY-MM-DDTHH:mm')).diff(moment(moment(this._as.headerDate$.value).format('YYYY-MM-DDTHH:mm')), 'day') != 0){
      this.loaderService.isLoading.next(true);
    this._ps.getParties(this.currentDate, this.currentDate).subscribe(data => {
      if (data) {
        reservationData = data.Payload.filter((reservations: DateStateDTO) => {
          const restaurantDate = format(new Date(this.currentDate), 'MM/DD/YYYY');
          const reservationDate = format(new Date(reservations.Date), 'MM/DD/YYYY');
          return (reservationDate == restaurantDate);
        });
        this.allActivitiesData = this.abf.formAllBookedActivities(this._settings,this._layout, this.currentDate,this._ps.Parties$.value, (reservationData[0] ? reservationData[0].ActivityStandByBookings : []), true).ByClass;
        if(reservationData[0] && reservationData[0].ActivityStandByBookings){
          reservationData[0].ActivityStandByBookings = <any>reservationData[0].ActivityStandByBookings.filter((reservation:any) => {
            if(reservation.BookedSessions){
              reservation.BookedSessions = reservation.BookedSessions.filter(bookedSession => format(data.Payload[0].Date, 'MM/DD/YYYY') === format(bookedSession.BookedDate, 'MM/DD/YYYY'));
              return !!reservation.BookedSessions.length
            } else if(reservation.SpecialMealId && reservation.SessionGroupId){
              return true;
            } else if(reservation.WishedTime){
              return format(data.Payload[0].Date, 'MM/DD/YYYY') === format(reservation.WishedTime, 'MM/DD/YYYY')
            }
            return false;
          })
        }
        
        this.processReservations(reservationData[0] ? reservationData[0].ActivityStandByBookings : []);
       
      }
      this.loaderService.isLoading.next(false);
    }, err => {
      this.loaderService.isLoading.next(false);
    });
  // }
  // else{
  //   this.allActivitiesData = this.abf.formAllBookedActivities(this._settings,this._layout, this.currentDate,this._ps.Parties$.value, this._ps.StandbyParties$.value, true).ByClass;
  //   this.processReservations(this._ps.StandbyParties$.value);
  //   }
  }

  processReservations(reservations){
    this.StandBYList = [];

    if(!reservations || !reservations.length){
      return;
    }
    reservations = _.uniqBy(reservations,'Id');
    reservations.forEach(party => {
      var lessonData = this.allActivitiesData.filter(lesson => lesson.Id === party.SpecialMealId)[0];
      if (lessonData) {
        if (lessonData.isPrivateLesson == true) {
          lessonData['isPrivate'] = true;
        }
       
        if (party.BookedSessions) {
          party.BookedSessions?.forEach(element => {
            let partyData: any = {};
            partyData.SpecialMealName = lessonData.Name;
            partyData.LocationName = party.TableNames.join(', ');
            partyData.SessionType = this.translateService.instant('SessionBooking');
            const sessionData = lessonData.Sessions.filter(session => session.SessionId === element.ActivitySessionId)[0];
            if (sessionData && element.SessionType !== PartyType.Reservation && element.SessionState === PartyState.Pending
              && Utilities.Date(element.BookedDate).diff(Utilities.Date(this.currentDate), 'd') == 0) {
              partyData.Instructors = sessionData.Staffs.map(staff => staff.FirstName + (staff.LastName || ''));
              partyData.BookedSessionId = element.Id;
              partyData.BookedDate = element.BookedDate;
              partyData.StartTime = '1970-01-01T' + sessionData.StartTime;
              partyData.EndTime = '1970-01-01T' + sessionData.EndTime;
              partyData.isPrivateBooking = lessonData.isPrivate;
              partyData = Object.assign(partyData, party);
              partyData.isPast = this.isPastReservation(partyData);
              if((moment(partyData.BookedDate).format("YYYY-MM-DD")) == (moment(this.currentDate).format("YYYY-MM-DD")))
              this.StandBYList.push(partyData);
            }
          });
        } 
        else if (party.SessionGroupId) {
          var lessonData = this.allActivitiesData.filter(lesson => lesson.Id === party.SpecialMealId)[0];
          
          if (lessonData.Sessions && lessonData.Sessions.length > 0) {
            var bookedSession =lessonData?.Sessions.filter(sm => sm.ActivityBookings?.length>0 );
            //var sessionId=(bookedSession?.filter(booking=>booking.ActivityBookings?.filter(booking=>booking.PartyId==party.Id).length>0))[0]?.SessionId
            //const sessionData = lessonData.Sessions.filter(session => session.SessionId === sessionId)[0];

            var bookedSessionData = (bookedSession?.filter(booking=>booking.ActivityBookings?.filter(booking=>booking.PartyId==party.Id).length>0));
            const sessionData = bookedSessionData?.filter(day => day.Dayofweek === this.currentDate.getDay())?.[0];
          
          let partyData: any = {};
          partyData.SpecialMealName = lessonData.Name;
          partyData.LocationName = party.TableNames.join(', ');
          partyData.SessionType = lessonData.isPrivate ? this.translateService.instant('PrivateLesson') : this.translateService.instant('ClassBooking');
          partyData.Duration = (moment(party.DepartureTime).diff(moment(party.SeatingTime), 'minutes'));
          if (sessionData && party.Type !== PartyType.Reservation && party.State === PartyState.Pending && this.getTimeCalulation(this.currentDate, party)) {
            partyData.isPrivateBooking = lessonData.isPrivate;
            partyData.Instructors = lessonData.Sessions && lessonData.Sessions.flatMap(sessionData => sessionData.Staffs ? sessionData.Staffs.map(staff => staff.FirstName + (staff.LastName || '')) : []);
            partyData.Instructors = _.uniq(partyData.Instructors);
            partyData.BookedDate = this.currentDate;
            partyData.StartTime = '1970-01-01T' + sessionData.StartTime;
              partyData.EndTime = '1970-01-01T' + sessionData.EndTime;
            partyData = Object.assign(partyData, party);
            partyData.isPast = this.isPastReservation(partyData);  
            if(sessionData.Dayofweek == this.currentDate.getDay()){
              this.StandBYList.push(partyData);
            }            
          }
        }
        }
        else {
          let partyData: any = {};
          partyData.SpecialMealName = lessonData.Name;
          partyData.LocationName = party.TableNames.join(', ');
          partyData.SessionType = lessonData.isPrivate ? this.translateService.instant('PrivateLesson') : this.translateService.instant('ClassBooking');
          partyData.Duration = (moment(party.DepartureTime).diff(moment(party.SeatingTime), 'minutes'));
          if (party.Type !== PartyType.Reservation && party.State === PartyState.Pending && this.getTimeCalulation(this.currentDate, party)) {
            partyData.isPrivateBooking = lessonData.isPrivate;
            partyData.Instructors = lessonData.Sessions && lessonData.Sessions.flatMap(sessionData => sessionData.Staffs ? sessionData.Staffs.map(staff => staff.FirstName + (staff.LastName || '')) : []);
            partyData.Instructors = _.uniq(partyData.Instructors);
            partyData.BookedDate = this.currentDate;
            if (!party.DepartureTime) {
              partyData.StartDate = party.WishedTime;
              partyData.EndDate = party.EndDate;
            } else {
              partyData.StartTime = party.WishedTime;
              partyData.EndTime = party.DepartureTime;
            }
            partyData = Object.assign(partyData, party);
            partyData.isPast = this.isPastReservation(partyData);
            if((moment(party.WishedTime).format("YYYY-MM-DD")) == (moment(this.currentDate).format("YYYY-MM-DD"))){
              this.StandBYList.push(partyData);
            }        
            
          }
        }
      }else if(!party.SpecialMealId){
        let partyData: any = {};
        partyData.SpecialMealName = this.translateService.instant('OpenBooking');
        partyData.LocationName = party.TableNames.join(', ');
        partyData.SessionType = this.translateService.instant('OpenBooking');
        partyData.isPrivateBooking = false;
        partyData.isOpenBooking = true;
        partyData.Duration = (moment(party.DepartureTime).diff(moment(party.SeatingTime), 'minutes'));
        if (party.Type !== PartyType.Reservation && party.State === PartyState.Pending && this.getTimeCalulation(this.currentDate, party)) {
          partyData.isPrivateBooking = 'lessonData.isPrivate';
          partyData.Instructors = [];
          partyData.BookedDate = this.currentDate;
          if (!party.DepartureTime) {
            partyData.StartDate = party.WishedTime;
            partyData.EndDate = party.EndDate;
          } else {
            partyData.StartTime = party.WishedTime;
            partyData.EndTime = party.DepartureTime;
          }
          partyData = Object.assign(partyData, party);
          partyData.isPast = this.isPastReservation(partyData);
          if((moment(party.WishedTime).format("YYYY-MM-DD")) == (moment(this.currentDate).format("YYYY-MM-DD"))){
            this.StandBYList.push(partyData);
          }        
          
        }
      }
     });
  }

  isSameDate(currentDate, inputDate) {
    return currentDate.getDate() === inputDate.getDate()
      && currentDate.getMonth() === inputDate.getMonth()
      && currentDate.getFullYear() === inputDate.getFullYear()
  }

  getTimeCalulation(currentDate, party) {
    const start = new Date(party.StartDate);
    const end = new Date(party.EndDate);
    currentDate = Utilities.Date(this.currentDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    end.setMinutes(end.getMinutes() + 30);
    return currentDate >= start && currentDate <= end;
  }

  checkTodayButtonConfiguartion(date) {
    this.showTodayButton = false;
    let currentDate = Utilities.Date(date);
    let currentRestDate = Utilities.Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    if (Utilities.diffBetweenDates(currentRestDate, currentDate) != 0) {
      this.showTodayButton = true;
    }
  }

  navigateToToday() {
    this.components.first.form.controls.reservationDate.setValue(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
  }

  RejectClick(attendee) {
    let rejectBooking = false;
    let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == attendee.SpecialMealId)[0];
    if (selectedActivity  || !attendee.SpecialMealId) {
      const componentDetails: ComponentDetails = {
        componentName: RejectStanbyComponent,
        dimensionType: 'small',
        popupType: 'active',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: '',
        popupTitle: this.translateService.instant('rejectionReason'),
      };
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '450px',
        height: '350px',
        data: {
          title: this.translateService.instant('rejectionReason'),
          update: 'Yes',
          cancel: 'No',
          componentDetails,
          from: ComponentTypes.RejectBooking,
          back: false,
          standalone: true,
          showAlert: true
        }
      });
      let rejectInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.RejectBooking) {
          if (attendee.isPrivateBooking || attendee.isOpenBooking){
            this._ps.cancelBookingDataObj = attendee;
            this._ps.rejectPrivateStandActivity(this._ps.ReasonForReject, attendee, attendee.Contact.EmailAddress, attendee.BookingId, this.removeAfterOperation, attendee.isOpenBooking);
           }
            else{
            this._ps.rejectStandActivity(this._ps.ReasonForReject, attendee, attendee.Contact.EmailAddress, attendee.BookedSessionId, this.removeAfterOperation);
           }
          rejectInPopUpConfirmationSubscription.unsubscribe();
        }
      });

      let rejectInPopUpCancellationSubscription = this.popupService.cancelledAction$.subscribe(val => {
        rejectInPopUpCancellationSubscription.unsubscribe();
        rejectInPopUpConfirmationSubscription.unsubscribe();
        this._ps.standbyDialog.close();
      });
    }
  }

  ConfirmClick(attendee) {
    let confirmBooking = false;
    let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == attendee.SpecialMealId)[0];
    if (selectedActivity || !attendee.SpecialMealId) {
      console.log(attendee);
      let msg = this.translateService.instant('areYouSureYouWanttoConfirm');
      this.showWarningInfoPopUp(msg, ComponentTypes.ConfirmBooking, '250px', '450px', this.translateService.instant('confirmReservation'))


      let confirmInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.ConfirmBooking) {
          confirmBooking = true;
          this._ps.convertActivityStandbyToReservation(attendee, false, null, this.removeAfterOperation);
          confirmInPopUpConfirmationSubscription.unsubscribe();
          this.warningInfoDialogRef?.close();
        }
      });

      let confirmInPopUpCancellationSubscription = this.popupService.cancelledAction$.subscribe(val => {
          confirmInPopUpCancellationSubscription.unsubscribe();
          confirmInPopUpConfirmationSubscription.unsubscribe();

      });
    }
  }
  editStandbyOpenBooking(attendee){
    attendee.ReservedFor = attendee.WishedTime;
    this._ps.selectedParty$.next(attendee);
    if (this._settings.General.SpecialMealsCoverTypesEnabled) { 
      this.subscriptions.add(this._ps.getPartyInfo(this._ps.selectedParty$.value.Id).subscribe(response => {
        if (response.Payload) {
          this.retailservice.oldReservation = _.cloneDeep(response.Payload);  
          this._ps.selectedParty$.value.BookingContacts = _.cloneDeep(response.Payload.BookingContacts)
          this._ps.selectedParty$.value.CoverTypeQuantities = _.cloneDeep(response.Payload.CoverTypeQuantities);
          this._ps.selectedParty$.value.BookingContactAddonItems = _.cloneDeep(response.Payload.BookingContactAddonItems) || [];
          this._ps.selectedParty$.value.BookingTypeQuantities = _.cloneDeep(response.Payload.BookingTypeQuantities)
          this._ps.selectedParty$.value.Contact = _.cloneDeep(response.Payload.Contact);
          this.dashboardFunctions.createOrEditOpenBooking(true, this._ps.selectedParty$.value);
          this._ps.standbyDialog.close();
        }
      }));
    }
    else {
      this.retailservice.oldReservation = _.cloneDeep(this._ps.selectedParty$.value);  
      this.dashboardFunctions.createOrEditOpenBooking(true, this._ps.selectedParty$.value);
      this._ps.standbyDialog.close();
    }
  }
  removeAfterOperation = (partyId, closeDialog?) => {
    if(closeDialog && Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)){
      this.popupService.closeDialog$.next(true);
    }
    this.StandBYList = this.StandBYList.filter(({ Id }) => Id !== partyId);
  }


  showWarningInfoPopUp(message, componentType, popupHeight, popupWidth, title) {

    let cancelText = 'No';
    // let title = 'Confirm Check-in';
    //let msg = 'No card details collected to capture payment amount. Are you sure to checkin the attendee?'
    let noShowSet = false;
    let confirmCheckin = false;
    let updateText = 'Yes';
    let showAlert = false;
    let noShowFeePopUp = false;

    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: 'confirm', showAlert: showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    this.warningInfoDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: popupWidth,
      height: popupHeight,
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: componentType,
        back: false,
        standalone: true,
        showAlert: true
      }
    });


  }

  loadAllBookings(dateSelected) {
    this.loadStandbyParties();
  }
  isPastReservation(party) {
    let currentTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
   if (party.SessionGroupId)
   {
    var time = party.EndTime.split('T');
    var sessionDateTime = Utilities.AppendDateWithTime(party.BookedDate, time[1]);
    if (moment(currentTime).diff(sessionDateTime, 'minute') >= 0) {
      return true;
    }
   }
   else if (party.BookedSessions) {
    let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == party.SpecialMealId)[0];
     const selectedBookedSession = party.BookedSessions?.filter(session => session.Id === party.BookedSessionId)[0];
    var selectedSession = selectedActivity ? selectedActivity.ActivitySessions.find(session => session.ActivitySessionId == selectedBookedSession.ActivitySessionId) : null;
    if (selectedSession && selectedSession.EndTime) {
      let currentTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
      var sessionDateTime = Utilities.AppendDateWithTime(party.BookedDate, selectedSession.EndTime);
      
      if (moment(currentTime).diff(sessionDateTime, 'minute') >= 0) {
        return true;
      }
    }
   }
   else if (party.WishedTime) {
      var time = party.WishedTime.split('T');
      var sessionDateTime = Utilities.AppendDateWithTime(party.BookedDate, time[1]);
      if (moment(currentTime).diff(moment(party.DepartureTime), 'minute') >= 0) {
        return true;
      }
    }
    else if (party.StartDate) {
      var sessionDateTime = Utilities.getRestaurantDateTime(party.BookedDate);
      if (moment(currentTime).diff(party.StartDate, 'minute') >= 0) {
        return true;
      }
    }
    return false;
  }

 getDateinRestaurantDateTimeOffset(datetime:any) {
    const offset = Number(this._settings.General.DaylightDelta);
    const d = new Date(datetime);
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    const nd = new Date(utc + (3600000 * offset));
    return nd;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}


@Pipe({
  name: 'arrayJoin'
})
export class ArrayJoinPipe implements PipeTransform {

  transform(list: any[], joinBy: string = ", "): string {
    return list.join(joinBy);
  }
}
