import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import moment from 'moment';
import { SettingsDTO } from '../models/RestaurantDTO';
import { Subject, Subscription } from 'rxjs';
import { BookingGuestType } from '../constants/globalConstants';
import { Utilities } from '@utilities/utilities';
import { TranslateService } from '@ngx-translate/core';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { ConfirmationPopupComponent } from '../components/confirmation-popup/confirmation-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'lodash';
import { ComponentTypes, ValidationMessageType } from '../constants/commonenums';
import { GuestBookService } from '../services/guestbook.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { PopupService } from '@app/popup-module/popup.service';
import { CancelActivityPopupComponent } from '../components/cancel-activity-popup/cancel-activity-popup.component';


@Injectable()
export class BookingTypeHandler extends Utilities {
  subscriptions: Subscription = new Subscription();
  proceedValidation: Subject<any> = new Subject<any>();
  _settings: SettingsDTO;
  bookingTypeForGuest:any;
  validationErrors = [];
  constructor(private cs: CacheService, private ts: TranslateService, public dialog: MatDialog, private gbs:GuestBookService, public ps:PopupService) {
    super(dialog);
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
  }

  validateGuestByBookingType(guestData, fromDate, toDate, selectedMeal, deferWarning) {
    let selectedGuestBookingType = guestData.bookingType;
    if ((selectedMeal && selectedMeal != -1) && selectedGuestBookingType?.Id) {
      let activitySpecificBookingType = this._settings.SpecialMeals.find(meal => meal.Id == selectedMeal)?.BookingTypeActivitiesMappings.find(type => type.Id == selectedGuestBookingType.Id)
      this.validateBookingType(activitySpecificBookingType, guestData, fromDate, toDate, deferWarning)
    }
    else {
      let bookingTypeForOthers = this._settings.BookingTypes.find(type => type.Id == selectedGuestBookingType.Id) || [];
      this.validateBookingType(bookingTypeForOthers, guestData, fromDate, toDate,deferWarning)
    }
  }

  showWarning(validationMessage,preventWarning = false) {
    let msg =validationMessage.map(messageData => (messageData?.Message));
    this.validationErrors = msg;
    if(!preventWarning){
    msg = msg.join('</br>')
  let title = this.ts.instant("warningTitle");
    const popUpMessage = [{
      confirmationMessage: msg + '<br>' + this.ts.instant('areYouSureToContinue'),
      dialogTitle: this.ts.instant("warningTitle")
    }];

    const componentDetails: ComponentDetails = {
      componentName: CancelActivityPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '600px',
      height: 'auto',
      data: {
        title,
        update: 'Yes',
        cancel: 'No',
        componentDetails,
        from: ComponentTypes.bookingTypeValidation,
        back: false,
        standalone: true,
        showAlert: true
      }
    });
    let cancelledtSubscription = this.ps.cancelledAction$.subscribe(action =>{
      if (action.from === ComponentTypes.bookingTypeValidation){
      cancelledtSubscription.unsubscribe();
      this.proceedValidation.next({state: 'invalid'})
      }
    });
   let confirmedSubscription = this.ps.confirmedAction$.subscribe(action =>{
    if (action === ComponentTypes.bookingTypeValidation){
    confirmedSubscription.unsubscribe();
      this.proceedValidation.next({state: 'valid'})
    }
    });
  }
  }


  validateBookingType(bookingType, guestInfo, fromDate, toDate,deferWarning) {
    let currentDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)
    this.validationErrors = []
    if (Math.abs(moment(fromDate).diff(moment(currentDate), 'days')) > bookingType.AdvanceDays) {
      let warningMessage = this.ts.instant('bookingTypeAdvanceDaysWarning', { bookingType: bookingType.Name })
      this.validationErrors.push(warningMessage);
      
    }

    if (bookingType.BookingGuestType == BookingGuestType.HotelGuest) {
      if (bookingType.IsWithinStayRange && guestInfo.Staystartdate && guestInfo.StayEnddate && !(fromDate >= guestInfo.Staystartdate) && !(toDate <= guestInfo.StayEnddate)) {
        this.validationErrors.push(this.ts.instant('stayRangeWarningMsg'));
             }
    }
    if (this.validationErrors.length > 0 && !deferWarning) {
      this.showWarning(this.validationErrors);
    }

  }


  formValidationDTO(guest,startDate,endDate,bookingTypeId = null,secondaryGuest = false){
    return {
        StayStartdate: guest?.Staystartdate || guest?.contact?.Staystartdate || null,
        StayEnddate:guest?.StayEnddate || guest?.contact?.StayEnddate || null,
        SearchType : guest?.SearchType,
        BookingStartDate: moment(startDate).format('YYYY-MM-DD'),
        BookingEndDate:moment(endDate).format('YYYY-MM-DD'),
        BookingTypeId: bookingTypeId || null,
        ActivityId: this.gbs.ps.selectedSpecialMealId > 0 ? this.gbs.ps.selectedSpecialMealId : null,
        ValidateStayInfo: true,
        ContactId: !secondaryGuest ? guest?.Id : guest?.ContactId

    };
  }
  validateBookingTypeForGuest(payload?,preventWarning?){
    if(payload){
      this.gbs.stayPayload = payload;
    }
    if(this.gbs.stayPayload && this.gbs.stayPayload.length){
     this.gbs.validateBooking(this.gbs.stayPayload).subscribe(data => {
      if(data.ValidationMessages.length){
        this.showWarning(data.ValidationMessages.filter(msg=> msg.Type == ValidationMessageType.ConsentMessages),preventWarning)       
      }
      else{
        this.proceedValidation.next({state: 'valid'})
      }  
    });
  }
  else{
    return true;
   }
  }
  formAdditionalInfo(){
    return this.validationErrors;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.gbs.stayPayload =[];
  }

}