import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { buttonTypes } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-day-notes',
  templateUrl: './day-notes.component.html',
  styleUrls: ['./day-notes.component.scss']
})
export class DayNotesComponent implements OnInit, AfterViewInit {
  dayNoteconfig: FieldConfig[];
  @ViewChild('dayNoteForm', { static: true }) dayNoteForm: DynamicFormComponent;
  @Input() dayNote: any;
  @Output() closePopover: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveDayNotes: EventEmitter<any> = new EventEmitter<any>();
  buttonSend: ButtonValue;
  buttonCancel: ButtonValue;
  constructor(private ts:TranslateService) { }

  ngOnInit(): void {
    this.buttonSend = {
      type: buttonTypes.actionPrimary,
      label: this.ts.instant('saveNote'),
      customclass: 'next-btn',
      disbaledproperity: false
    };
    this.buttonCancel = {
      type: buttonTypes.actionSecondary,
      label: this.ts.instant('Cancel'),
      customclass: 'cancel-btn'
    };
    this.dayNoteconfig = [{
      type: 'textarea',
      name: 'dayNote',
      inputType: 'text',
      showHint: true,
      charLength: 400,
      class: 'dayNoteText',
      value: this.dayNote
    }];
  }

  ngAfterViewInit() {
    // this.dayNoteForm.form.patchValue({
    //   dayNote: this.dayNote
    // });
  }

  close() {
    this.dayNoteForm.form.patchValue({
      dayNote: this.dayNote
    });
    this.closePopover.emit();
  }

  saveNote() {
    this.saveDayNotes.emit({dayNotesValue: this.dayNoteForm.value.dayNote});
  }
}
