import { Injectable, OnDestroy } from '@angular/core';
import { Utilities } from '@app/shared/utilities/utilities';
import { urlConfig } from '@constants/url-config';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from '@services/http.service';
import { Subscription } from 'rxjs';
import * as en_data from '@assets/i18n/en.json';
import * as fr_data from '@assets/i18n/fr.json'
@Injectable({
  providedIn: 'root'
}
)
export class MultilingualService implements OnDestroy {
  selectedLanguage: string;// to hold the selected language by the user
  labelStrings: any;
  subscriptions: Subscription = new Subscription();

  /* setting default language even after page refresh */
  constructor(public translateService: TranslateService, public _hs: HttpService) {
    let language = localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_language`);
    let setlan = this.selectedLanguage ? this.selectedLanguage : language ? language : "en";
    this.translateService.setDefaultLang(setlan);
    this.loadLabelStrings();
  }

  /* setting language on change of drop down */
  setLanguage(lang) {
   // this.selectedLanguage = lang;
    let language = localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_language`);
    let setlan = this.selectedLanguage ? this.selectedLanguage : language ? language : "en";
    this.useTranslateService(setlan);
    let restaurant = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${Utilities.RestaurantId()}_restaurantSettings`));
    if (restaurant && restaurant.PropertyType) {
      this.setPropertyType(restaurant.PropertyType, restaurant.LanguageFileURL)
    }
    // this.translateService.use(setlan);

  }

  setPropertyType(propertyType : string, languagePath?:any) {
    if(languagePath){
      this.useTranslateService(languagePath);
    }else{
      let language = localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_language`);
    let setlan = this.selectedLanguage ? this.selectedLanguage : language ? language : "en";
    let translationPath = propertyType + "/" + setlan ;
    this.useTranslateService(translationPath);
    localStorage.setItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_language`, setlan);
    localStorage.setItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_userLocale`, this.getLocale());
    }
  }

  useTranslateService(path: string, final?){
    this.translateService.use(path).subscribe(()=>{}, err => {
    //  if(!final) this.useTranslateService('en', true)
    });
  }

  getLocale() {
    let locale: string = "";
    switch (localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_userLocale`)) {
      case "en":
        locale = "en-US";
        break;
      default:
        locale = "en-US";
    }
    return locale;
  }

  loadLabelStrings() {
    switch (localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_language`) ? localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_language`) : "en") {
      case "en":
        var jsondata = (en_data as any);
        this.loadLabels(jsondata.default)
        break;
      case "fr":
        var jsondata = (fr_data as any);
        this.loadLabels(jsondata.default)
        break;
      default:
        var jsondata = (en_data as any);
        this.loadLabels(jsondata.default)
        break;
    }
  }

  loadLabels(data) {
    this.labelStrings = data;
  }

  getLabel(labelstring) {
    return this.labelStrings[labelstring];
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

}
