import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TablesService } from '@app/shared/services/tables.service';
import { CacheService } from '@core/services/cache.service';
import { ServerDTO, SettingsDTO, StandaloneTableDTO, ShiftStatsDTO } from '@models/RestaurantDTO';
import { PopupService } from '@popup-module/popup.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import { ServerService } from '@services/server.service';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';

@Component({
  selector: 'app-select-server',
  templateUrl: './select-server.component.html',
  styleUrls: ['./select-server.component.scss']
})
export class SelectServerComponent implements OnInit, OnDestroy, AfterViewInit {
  serverData: ServerDTO[] = [];
  selectedServerArray: any = [];
  showError = false;
  selectedIndex: number;
  _settings: SettingsDTO = {} as SettingsDTO;
  serverStatsData: ShiftStatsDTO = {} as ShiftStatsDTO;
  restaurantDateTime: Date;
  //preferredServer: any = [];
  @Input() data;
  subscriptions: Subscription = new Subscription();
  serverChangeProcessorData: StandaloneTableDTO[] = [];
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  text: string = "";
  searchConfig: FieldConfig[];
  // @Input() input:any
  constructor(private gbs: GuestBookService, private partyService: PartyService,
              private popupService: PopupService, public ss: ServerService, public cs: CacheService,
              private ts: TablesService, private trs: TranslateService) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
      this.restaurantDateTime = new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    }));
  }
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnInit() {
    this.subscriptions.add(this.ts.standAloneTables$.subscribe((data) => {
      this.serverChangeProcessorData = data;
    }));
    if (this.data) {
      this.serverData = this.data.filter(x => !x.IsTemplate);
    }
    else {
      if (this.serverChangeProcessorData.length) {
        let tablesWithServers = this.serverChangeProcessorData.filter(x => x.ServerId);
        this._settings.Servers.forEach(server => {
          let tableWithServer = tablesWithServers.filter(x => x.ServerId == server.Id);
          if (tableWithServer && tableWithServer.length) {
            server.StandaloneTables = [];
            tableWithServer.forEach(table => {
              server.StandaloneTables.push(table)
            })
          }
        });
        this.serverData = this._settings.Servers.filter(x => !x.IsTemplate);
      } else if (this._settings.Servers)
        this.serverData = this._settings.Servers.filter(x => !x.IsTemplate);
    }

    if (this.serverData) {
        
        this.subscriptions.add(this.ss.GetServerStats().subscribe((result) => {
          if (result.Payload) {
            this.serverStatsData = result.Payload;
            if(this.serverStatsData.ServerStats) {
              this.serverData.forEach(server => {
                let serverStat = this.serverStatsData.ServerStats.filter(stat => stat.ServerId == server.Id)[0];
                if (serverStat) {
                  server.LastStatusUpdatedAt = this.getSeatedValue(serverStat.LastActionTime);
                  server.Load = (Math.round(serverStat.Load * 100));
                  server.Duration = this.getSeatedDuration(serverStat.LastActionTime);
                }
              });
            }
            this.sortServersByAscending();
          }
        }));  
    }

    if (this.serverData) {
      this.serverData.forEach(server => {
        if (this.gbs.selectedServers && (this.gbs.selectedServers.Name === server.Name)) {
          this.selectedServerArray.push(server.Id);
          this.popupService.saveBtnEnable$.next(true);
        }
      });
    }
    if (!(this.ss.serverDetails && this.ss.serverDetails.value && this.ss.serverDetails.value.length > 0)) {
      this.ss.loadServers();
      //this.ss.updateServerMappings(this.cs.settings.value.Servers, this.cs.layout.value.FloorPlans);
    }
    this.searchConfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.trs.instant('serverSearchText'),
      //  class: 'server-selection__search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this)
      }
    ];
  }
  sortServersByAscending() {
    if(this.serverData && this.serverData.length){
    const serverWithTables = this.serverData.filter((server) => server.StandaloneTables != null && server.StandaloneTables.length > 0
      && server.Name.toLowerCase().includes(this.text)).sort((server1, server2) => server1.Name.localeCompare(server2.Name));
    const serverWithoutTables = this.serverData.filter((server) => server.StandaloneTables == null || server.StandaloneTables.length === 0
      && server.Name.toLowerCase().includes(this.text)).sort((server1, server2) => server1.Name.localeCompare(server2.Name));
    this.serverData = [...serverWithTables, ...serverWithoutTables];
  }
  } 

  ngAfterViewInit(){
     this.dynamicFormChanges();
  }

  getSeatedValue(lastActionTime: Date) {
    if (lastActionTime == null) { return '-' }
    let duration: number;
    let diffHours: number;
    duration = Math.round((new Date(this.restaurantDateTime).getTime() - new Date(lastActionTime).getTime()) / 60000);
    diffHours = duration / 60;

    if (diffHours > 24) { return '>24 h'; }
    if (diffHours <= 24 && diffHours >= 1) { return diffHours.toFixed() + ' h'; }
    if (diffHours < 1) { return duration.toFixed() + ' m'; }
  }

  getBackGroundColorLoad(load: number) {
    if (load < 50) { return '#DCF1E7'; }
    if (load >= 50 && load < 100) { return '#C6E2FE'; } // Blue
    if (load >= 100) { return '#FFD0D0'; }
  }

  getBackGroundColorSeated(duration: number) {
    if (duration < 20) { return '#FFD0D0'; }
    if (duration >= 20) { return '#DCF1E7'; }
  }

  getSeatedDuration(lastActionTime: Date) {
    if (lastActionTime == null) { return '-' }
    let duration: number;
    let diffHours: number;
    duration = Math.round((new Date(this.restaurantDateTime).getTime() - new Date(lastActionTime).getTime()) / 60000);
    return duration;
  }

  selectedServer(item) {
    this.selectedServerArray = [];
    this.selectedServerArray.push(item.Id);
    this.selectedIndex = item.Id;
    this.gbs.selectedServers = item;
    this.partyService.selectedServers.next(this.gbs.selectedServers);
    this.popupService.saveBtnEnable$.next(true);
    /////////////////////// for selecting 3 servers ///////////////
    // if (this.selectedServerArray.length < 3 && this.selectedServerArray.indexOf(item.Id) == -1) {
    //   this.selectedServerArray.push(item.Id);
    //   this.showError = false;
    //   this.selectedIndex = item.Id;
    //   this.gbs.selectedServers = '';
    //   this.selectedServerArray.forEach(element => {
    //     this.gbs.selectedServerArray = _.cloneDeep(this.selectedServerArray);
    //       const data = this.serverData.filter(x => x.Id == element);
    //       this.gbs.selectedServers = this.gbs.selectedServers + (data[0].Name) + ',';
    //   });
    // } else {
    //   if (this.selectedServerArray.indexOf(item.Id) == -1) {
    //     if (this.selectedServerArray.length == 3) {
    //      this.showError = true;
    //     }
    //   } else {
    //     const index = this.selectedServerArray.findIndex(x => item.Id == x);
    //     this.selectedServerArray.splice(index, 1);
    //     this.showError = false;
    //   }

    // }
  }

  dynamicFormChanges() {
    let serverDetailSubscription: ISubscription;
    this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe(val => {
      if (serverDetailSubscription) {
        serverDetailSubscription.unsubscribe();
      }
      this.subscriptions.add(serverDetailSubscription = this.ss.serverDetails.subscribe(details => {
        if (val.searchText) {
          this.serverData = [];
          this.text = val.searchText.toLowerCase();
          this.serverData = details.filter(x => (x.Name.toLowerCase()).includes(this.text));
        } else {
          this.text = "";
          this.serverData = [...details];
        }

        this.serverData = this.serverData.filter(x => !x.IsTemplate);
      }));
    }));
  }

  clearSearch() {
    this.text = "";
    this.dynamicForm.form.get('searchText').reset();
  }


}
