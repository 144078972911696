import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkboxchecked'
})
export class CheckboxcheckedPipe implements PipeTransform {

  transform(array: any[], checkObject: any, key: string): boolean {
    return array.some(item => item[key] === checkObject[key]);
  }

}
