<div *ngIf="_as.showDashboard" class="dashboard seat-primary-bg-color" [ngClass]="{'slots-width': cs.slotFullScreen,'close-nav': _as.showDashboard}">
  <div class="dashboard__data" *ngIf="type !== ReservationType.Server">
     <!-- <ng-scrollbar class="dashboard-scrollbar" *ngIf="!(dashboardData && dashboardData.editorDetails)"> -->
      <ng-scrollbar class="dashboard-scrollbar" *ngIf="!(dashboardData && dashboardData.editorDetails) && type !== ReservationType.Tables && !cs.slotFullScreen">
        <div *ngIf="type !== ReservationType.Tables">
          <app-dashboard-header></app-dashboard-header>
        </div>
        <div class="dashboard__coverreportborder seat-border-color" *ngIf="showCoverReportDiv">
          <div class="dashboard__report-buttons">
            <activities-app-button [buttontype]="coverReportBtn" (valueChange)="displayCoverReport()"></activities-app-button>
            <activities-app-button [buttontype]="waitTimesReportBtn" (valueChange)="displayWaitTimeReport()"></activities-app-button>
            <activities-app-button class="dashboard__standbyAction" [buttontype]="standbyBtn" (valueChange)="displayStandbyList()">
              <span *ngIf="showStandbyCount"
                class="dashboard__notification-text seat-notification red-color">{{_as.standbyListCount}}</span>
            </activities-app-button>
          </div>
        </div>
        <app-serverstatistics *ngIf="_as.selectedMenuId == Menu.Tables && showServerLoad"></app-serverstatistics>
        <app-dashboard-party-info *ngIf="(_ps.selectedParty$ | async) && type !== ReservationType.TablesFullView"
          [fromTables]="fromTables" (selectedCommunalPartyInfo)="selectedCommunalPartyInfo($event)"
          [dashboardData]="dashboardData" (reservationActions)="reservationActions($event)" [partyStatus]="partyStatus"
          [type]="type" (partyUnselectedEvent)="partyUnselectedEventHander($event)"></app-dashboard-party-info>
        <app-dashboard-status [dashboardStatus]="dashboardStatus"
          *ngIf="dashboardStatus?.length > 0 && !(_ps.selectedParty$ | async) && type !== ReservationType.TablesFullView">
        </app-dashboard-status>
        <ng-container *ngTemplateOutlet="tableList"></ng-container>
      </ng-scrollbar>
      <ng-container *ngIf="cs.slotFullScreen">
        <ng-container *ngTemplateOutlet="tableList"></ng-container>
      </ng-container>
      <div *ngIf="type == ReservationType.Tables" class="dashboard__popup-view">
        <app-dashboard-party-info *ngIf="(_ps.selectedParty$ | async) && type !== ReservationType.TablesFullView"
          [fromTables]="fromTables" (selectedCommunalPartyInfo)="selectedCommunalPartyInfo($event)"
          [dashboardData]="dashboardData" (reservationActions)="reservationActions($event)" [partyStatus]="partyStatus"
          [type]="type" (partyUnselectedEvent)="partyUnselectedEventHander($event)"></app-dashboard-party-info>
        <app-dashboard-status [dashboardStatus]="dashboardStatus"
          *ngIf="dashboardStatus?.length > 0 && !(_ps.selectedParty$ | async) && type !== ReservationType.TablesFullView">
        </app-dashboard-status>
      </div>
     <!-- </ng-scrollbar>  -->
    <app-editor-view *ngIf="dashboardData && dashboardData.editorDetails" [editorDetails]="dashboardData.editorDetails"
      (tabChangeEvent)="tabChange($event)"></app-editor-view>
  </div>
  <div class="dashboard__data" *ngIf="type === ReservationType.Server">
    <app-dashboard-header></app-dashboard-header>
    <app-server-view *ngIf="type === ReservationType.Server" (editServerTables)="editTables($event)">
    </app-server-view>
  </div>
 
  <div class="dashboard__actions" *ngIf="dashboardData?.actions?.length > 0 && !cs.isIframeEnabled">
    <activities-app-button *ngFor="let btn of dashboardData.actions;let i = index" class="w-100 button-view"
      [ngClass]="{'': (dashboardData?.actions?.length > 1 && i == 0) }" [buttontype]="btn" (valueChange)="create(btn)">
    </activities-app-button>
  </div>
  <div *ngIf="isSlotsTab" class="dashboard__actions">
    <div  class="slot-details">
      <span *ngIf="!slotSection.isFullScreen; else zoomOut" class="icon-zoom-in1" (click)="enableFullScreen()"></span>
          <ng-template #zoomOut>
              <span class="icon-zoom-out1" (click)="enableFullScreen()"></span>
          </ng-template>
      <span *ngIf="!slotSection.isEditEnabled" class="party-count seat-tertiary-text">{{slotSection.partyCount}} {{_MESSAGES.CONFIG.parties| translate}},
          {{slotSection.coverSize}} {{_MESSAGES.CONFIG.covers | translate}}</span>
      <div class="footer-actions">
          <div *ngIf="!slotSection.isEditEnabled" class="footer-actions__action-config">
            <div *ngIf="slotSection.currentShift?.Id && cs.currentShiftId != 0" class="webreservation-toggle">
            <div class="status-switch">
              <label class="switch-control__switch-label guest-form-label">{{_MESSAGES.CONFIG.WebReservations | translate}}</label>
              <div class="toggle-button">
                <label class="switch">
                  <input class="switch-input" type="checkbox" [(ngModel)]="slotSection.isWebReservableShift" (change)="setWebReservable($event.target.checked)"/>
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
              <span class="icon-Auditlog2 align-center" (click)="showSlotAuditLog()"></span>
          </div>
          <div *ngIf="slotSection.isEditEnabled" class="footer-actions__action-btn">
              <activities-app-button [buttontype]="enableSlotsBtn" (valueChange)="disableEnableSlot(false)"></activities-app-button>
              <activities-app-button [buttontype]="disableSlotsBtn" (valueChange)="disableEnableSlot(true)"></activities-app-button>
          </div>
      </div>
  </div>
  </div>
</div>
<ng-template #tableList>
  <app-table-list (tableTabsChange)="tableTabsChange($event)" *ngIf="dashboardData.tableDetails"
  [dashboardData]="dashboardData" [shiftId]="cs.currentShiftId"></app-table-list>
</ng-template>
