import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { AppService } from '@app/app.service';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { SeatingAreaDTO, SeatingTypesDTO } from '@app/shared/models/RestaurantDTO';
import { Utilities } from '@app/shared/utilities/utilities';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-theatre-booking-form',
  templateUrl: './theatre-booking-form.component.html',
  styleUrls: ['./theatre-booking-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TheatreBookingFormComponent implements OnInit {
  showsConfig: FieldConfig[];
  bookingDateConfig: FieldConfig[];
  showLocationConfig: FieldConfig[];
  showSeatingAreaConfig: FieldConfig[];
  showSeatingTypeConfig: FieldConfig[];
  seatSelectionConfig: FieldConfig[];
  seatingType: SeatingTypesDTO[] = [];
  seatingArea: SeatingAreaDTO[] = [];
  screens: any[] = [];

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.seatingType = this.appService._cs.settings.value.SeatingTypes.filter(sType => sType.IsDeleted == false);
    this.seatingType.forEach((type) => { if (type.Id === null) { type.Id = -1; } return type; });
    this.seatingType.unshift(this.seatingType.splice(this.seatingType.findIndex(item => item.Id === -1), 1)[0]);
    this.seatingArea = this.appService._cs.settings.value.SeatingAreas;
    this.seatingArea.forEach((area) => { if (area.Id === null) { area.Id = -1; } return area; });
    this.seatingArea.unshift(this.seatingArea.splice(this.seatingArea.findIndex(item => item.Id === -1), 1)[0]);
    this.showsConfig = [
      {
        type: 'select',
        name: 'shows',
        options: [],
        value: 0,
        class: 'theatre-booking__shows-list',
        showErrorText: true,
        isTranslate: false,
        validation: [Validators.required],
      }
    ];
    let restaurantDate = Utilities.getRestaurantDateTime(this.appService._cs.settings.value.General.DaylightDelta)
    this.bookingDateConfig = [
      {
        type: 'date',
        name: 'date',
        inputType: 'text',
        value: new Date(this.appService._headerDate) > restaurantDate ? this.appService._headerDate : restaurantDate,
        appearance: false,
        validation: [Validators.required],
        minDate: restaurantDate,
        class: 'theatre-booking__date'
      }
    ];
    this.showLocationConfig = [
      {
        type: 'select',
        name: 'location',
        options: [],
        value: 0,
        class: 'theatre-booking__location',
        showErrorText: true,
        isTranslate: false,
        validation: [Validators.required],
      }
    ];
    this.showSeatingAreaConfig = [
      {
        type: 'select',
        name: 'seatingArea',
        options: this.seatingArea.map(area => {return {id: area.Id, value: area.Name}}),
        value: -1,
        class: 'theatre-booking__seatingArea',
        showErrorText: true,
        isTranslate: false,
        validation: [Validators.required],
      }
    ]
    if (this.seatingType.length > 1) {

    } else {
      this.showSeatingTypeConfig = [
        {
          type: 'select',
          name: 'seatingType',
          options: this.seatingType.map(seat => {return {id: seat.Id, value: seat.Description}}),
          value: -1,
          class: 'theatre-booking__seatingType',
          showErrorText: true,
          isTranslate: false,
          validation: [Validators.required],
        }
      ]
    }
    this.screens = [
      { 
        screenName: 'Screen 1', 
        shows: [
          {
            showTiming: new Date(), 
            showRate: 20, 
            availableSeats: 45
          },
          {
            showTiming: new Date(), 
            showRate: 20, 
            availableSeats: 35
          }
        ]
      },
      {
        screenName: 'Screen 2', 
        shows: [
          {
            showTiming: new Date(), 
            showRate: 30, 
            availableSeats: 55
          },
          {
            showTiming: new Date(), 
            showRate: 25, 
            availableSeats: 10
          }
        ]
      }
    ]
  }

}
