<aside class="aside-filter"
  [ngClass]="[togglePanel?'hideMenu': '', _config?.panelClass?'aside-filter--'+_config?.panelClass: '']">
  <i  aria-hidden="true"  class="icon-filter_filled collapsible" (click)="Collapse()" attr.automationId='Icn_{{automationId}}_filterAside'></i>
  <div class="aside-filter__header">
    <span>{{_config?.filterText}}</span>
    <i  aria-hidden="true" *ngIf="_config?.isCloseRequired" class="icon-close ag_ml--auto ag_cursor--pointer" attr.automationId='Icn_{{automationId}}_close' (click)="Collapse()"></i>
    <div *ngIf="!_config?.isCloseRequired" class="ag_ml--auto">
    <a class="ag_link--sm pr-2" attr.automationId='Lbl_{{automationId}}_resetFilter'
      [ngClass]="{'ag_link--disabled': !(filterForm.dirty || _enableResetExternally || isFilterModified)}"
      href="javascript:void(0)" (click)="resetFilter()">{{_config?.resetText}}</a>
  </div>
  </div>
  <ng-content select=".upper-filter"></ng-content>
  <mat-accordion [multi]="true" displayMode="flat" attr.automationId='Accrd_{{automationId}}_asideAccordian'>
    <mat-expansion-panel class="aside-filter__panel" [expanded]="true" *ngIf="_dateSelection" #datePanel>
      <mat-expansion-panel-header [expandedHeight]="_panelHeight" [collapsedHeight]="_panelHeight" class="panel-header">
        <mat-panel-title class="panel-title">
          <span class="filter-title">{{_dateSelection.name}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="filterForm">  
        <app-ag-date-picker [inputs]="_dateInputs[idx]" (datePickerChange)="onChange($event,datefilter.name,idx)"
          *ngFor="let datefilter of _dateSelection.filters; let idx=index; trackBy: trackByFn">
        </app-ag-date-picker> 
      </form>
    </mat-expansion-panel>
    <ng-container *ngIf="_config?.isMultiSelect">
      <ng-container *ngTemplateOutlet="matExpansionPanel"></ng-container>
    </ng-container>
  </mat-accordion>
  <section *ngIf="!_config?.isMultiSelect" [formGroup]="filterForm">
    <div *ngFor="let filterGroup of _filterGroups; trackBy: trackByFn">
      <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--lg aside-filter__option--singleSelect">
        <mat-label>{{filterGroup.title}}</mat-label>
        <mat-select (selectionChange)="applySingleFilter(filterGroup, $event)" [formControlName]="filterGroup.name" attr.automationId='Dd_{{automationId}}_{{filterGroup.name}}'>
          <mat-option *ngFor="let filter of filterGroup.filters; trackBy: trackByFn" [value]="filter">
            {{filter[displayProp]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="template" [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{data: dataContext}">
      </ng-container>
    </div>
  </section>
  <ng-template #matExpansionPanel>
    <mat-expansion-panel class="aside-filter__panel"
      *ngFor="let filterGroup of _filterGroups; let i = index; let first = first"
      [expanded]="(first && !_dateSelection)|| filterGroup.expand"
      [ngClass]="[filterGroup?.className? filterGroup.className: '' , filterGroup.isHidden? 'ag_display--none' : 'ag_display--block']">
      <mat-expansion-panel-header [expandedHeight]="_panelHeight" [collapsedHeight]="_panelHeight"
        [ngClass]="{'reset-available':filterGroup?.isSectionReset}">
        <mat-panel-title>
          <span class="filter-title" [matTooltip]="filterGroup.title"><span
              class="title-name">{{filterGroup.title}}</span>
          </span>
        </mat-panel-title>
        <mat-panel-description><span *ngIf="!filterGroup.isSingleSelect"> ({{filterGroup.filtered | FilterLengthPipe:
            isChanged}})</span><span *ngIf="filterGroup.isSectionReset" (click)="$event.stopPropagation();resetSection(i)"
            class="icon-reset reset-section" [ngClass]="{'ag_link--disabled': filterGroup.isDisabled}" attr.automationId='Lbl_{{automationId}}_resetSection'></span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngIf="!filterGroup.isSingleSelect">
        <div class="filter-list">
          <div *ngFor="let filter of filterGroup.filters| slice:0:_filterLimit[i]; let j=index">
            <custom-checkbox [customCBxlabelText]="filter[displayProp]" [customCBxChecked]="filter.isSelected"
              [customCBxDisabled]="filterGroup.isDisabled || filter.isDisabled" [automationId]="automationId"
              (changeEvent)="updateCategoryArr(filterGroup,filter,i)"></custom-checkbox>
          </div>
          <div *ngIf="filterGroup.filters && filterGroup.filters.length > _filterLimit[i]">
            <a href="javascript:void(0)" class="ag_link" (click)="ShowRemaining($event, filterGroup)" attr.automationId='Lbl_{{automationId}}_showRemaining'>
              {{filterGroup.filters.length > _filterLimit[i] ? filterGroup.filters.length -
              _filterLimit[i] : 0 }}
              {{localization.captions.lbl_More}}</a>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="filterGroup.isSingleSelect">
        <mat-radio-group class="aside-filter-radiogroup" attr.automationId='Rdo_{{automationId}}_fliterGroupFilters'>
          <mat-radio-button class="ag_form--radio-button" *ngFor="let filter of filterGroup.filters; trackBy: trackByFn"
            [value]="filter" (change)="applySingleFilter(filterGroup, $event)" [checked]="filter.isSelected">
            {{filter[displayProp]}}
          </mat-radio-button>
        </mat-radio-group>
      </ng-container>
    </mat-expansion-panel>
    <ng-content></ng-content>
  </ng-template>
</aside>


<!-- aside filter view component-->
<app-more *ngIf="showMore" [moreOptions]="moreOptions" (moreApplyValueChange)="moreApplyValueChange($event)" [automationId]="automationId"
  (moreCancelValueChange)="moreCancelValueChange($event)" (closeMoreWindow)="closeMoreWindow($event)"></app-more>