import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';

@Component({
  selector: 'app-form-switch',
  templateUrl: './form-switch.component.html',
  styleUrls: ['./form-switch.component.scss']
})
export class FormSwitchComponent implements Field, OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;
  checked: boolean;
  constructor() { }

  ngOnInit() {
    this.checked = this.config.checked ? this.config.checked : false;
  }

  toggleChange(event): void {
    if(this.config.changeEvent)
    this.config.changeEvent(event.target.checked);
  }

}
