import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
  constructor(private ts: TranslateService){}
  public tempval: any = '';
  transform(time: string): any {
    
    if (time) {
        var [hrStr,min] = time.split(":");
        var session = this.ts.instant('am');
        var hr:any = +hrStr;
        if (hr >= 12) {
            if (+hr != 12) {
                hr = (hr - 12) < 10 ?
                    "0" + (hr - 12)
                    : +hr - 12;
            }
            session = this.ts.instant('pm');
        }
        hr = hr == '00' ? 12 : hr;
        return hr + ":" + min + " " + session;
    }
    else
        return "";
  }
}