<div class="modify-session">
    <div class="modify-session__activity-name" [category-indicator]="activityData?.CategoryId">
        {{activityData?.Name}} <span class="modify-session__date">{{sessionData.Date | localizedDate: 'DD MMM yyyy'}}</span>
        <span class="icon-information" matTooltipClass="modify-session-warning-tooltip" matTooltip="{{ 'modifySessionWarning' | translate }}"></span>
    </div>

    <!-- <div *ngIf="warningMessgaes" class="alert alert-warning" role="alert">
        {{warningMessgaes}} <ng-container *ngIf="InvalidSessionDays && InvalidSessionDays.length > 0"> (
            <span *ngFor="let day of InvalidSessionDays"> {{day}} </span> )
        </ng-container>
    </div> -->

    <div>
        <span *ngIf="requiredFieldValidation" class="seat-error-messages pt-1"> {{'requiredFieldValidation' |
            translate}}</span>
    </div>

    <div class="modify-session__session-mapping p-0 session-list col-lg-12 col-md-12">
        <div class="session-edit-mapping-container">
            <app-session-mapping *ngIf="sessionData" [sessionData]="sessionData" [locations]="locationList"
                [staffs]="staffList" [allocationList]="allocationList" [calendarDetails]="calendarDetailsForm"
                [classType]="classType" [sessionGroups]="sessionGroups" [isTemplate]="false"
                [isModifySingleSession]="true" (sessionChange)="sessionChanges($event)" [locationGroup]="locationGroup">
            </app-session-mapping>
        </div>
    </div>

    <div class="session-edit-customize-form-container">
        <dynamic-form [config]="sessionStrengthDetailsConfig" #maxStrengthDetails></dynamic-form>
    </div>

</div>