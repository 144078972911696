import { Pipe, PipeTransform } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Pipe({
  name: 'snackbarMessages',
})
export class GetSnackBarMessagesPipe implements PipeTransform {
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    constructor(private _snackBar: MatSnackBar){}
    
  transform(message: string, horizontal?:MatSnackBarHorizontalPosition , vertical?: MatSnackBarVerticalPosition , duration?:  number ): any {

    this._snackBar.open(message, 'Close', {
        horizontalPosition:horizontal ? horizontal :  this.horizontalPosition,
        verticalPosition: vertical ? vertical : this.verticalPosition,
        duration: duration ? duration: 3000 ,
      });

  }
}
