<section class="pop-container">


    <div class="pop-head">
      <label class="inner-header1 pl-4 LW20">{{data.headername}}</label>
      <i [attr.automationId]="'Icn_retailVoidReason_cancel'" aria-hidden="true" *ngIf="data.closebool" class="material-icons inner-close float-md-right cursPointer" (click)="onCancelClick()">close</i>
    </div>
    <div class="pop-contents ">
        <form [formGroup]="voidGroup" class="void-form">
            <mat-form-field class="w-50 txt-area-height"  [floatLabel]="floatLabel">
              <textarea [attr.automationId]="'Ta_retailVoidReason_comments'" matInput placeholder="{{localisation.Reason}}" #commentnew  maxlength="250" formControlName="comments" required ></textarea>
            </mat-form-field>
          </form>
    </div>
    <mat-dialog-actions>     
          <button [attr.automationId]="'Btn_retailVoidReason_save'" mat-button class="body-bgcolor whitecolor LW14 ml-3 text-capital" (click)="save()" [ngClass]="voidGroup.valid && voidGroup.dirty ? 'button_valid' : 'button_invalid'" [disabled]="!voidGroup.valid && !voidGroup.dirty"> {{localisation.OK}}</button>
          <button [attr.automationId]="'Btn_retailVoidReason_cancel'" mat-button class="LW14 ml-2 text-capital" (click)="onCancelClick()">{{localisation.Cancel}}</button>
      </mat-dialog-actions>
  
  
  </section>