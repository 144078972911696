<!--categories filter-->
<section [formGroup]="InventoryTransferFormGrp" autocomplete="off">

        <mat-form-field *ngIf="functionalities?.ShowOutletSelectionFieldInInventoryAuditTrailReport" [floatLabel]="floatLabel">
          <mat-select placeholder="{{captions.Outlet}}" name="outlet" formControlName="outlets">
            <ng-container *ngFor="let outlet of outlets | sortPipe : 'asc' : 'description'">
              <mat-option [value]="outlet" (click)="validateOutlet($event, outlet)">
                {{outlet.description}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      <mat-form-field class="form-group" [floatLabel]="floatLabel">
        <input matInput placeholder="Search by Item #" class=""
          formControlName="searchbyitem" inputtype="onlynumber,nonnegative,nodecimal">
      </mat-form-field>

      <mat-form-field class="form-group" [floatLabel]="floatLabel">
        <input matInput placeholder="Search by Description" class=""
          formControlName="searchbydesc">
      </mat-form-field>

      <mat-form-field class="form-group" [floatLabel]="floatLabel">
        <input matInput placeholder="Search by Supplier" class=""
          formControlName="searchbysupplier">
      </mat-form-field>

      <mat-form-field class="form-group" [floatLabel]="floatLabel">
        <input matInput placeholder="Search by Bar Code #" class=""
          formControlName="searchbybarcode">
      </mat-form-field>

      <mat-form-field class="form-group" [floatLabel]="floatLabel">
        <input matInput placeholder="Search by Other Scanner Code" class=""
          formControlName="searchbyotherscannercode">
      </mat-form-field>

      <app-retail-category-component (formReady)="formInitialized($event)"
      [fromJobSchedulerEdit] = "isFromEdit" [editValues] = "editPatchValue"></app-retail-category-component>

      <mat-form-field class="form-group" [floatLabel]="floatLabel">
        <input matInput placeholder="Search by Supplier Item #" class=""
          formControlName="searchbysupplieritem" inputtype="onlynumber,nonnegative">
      </mat-form-field>

      <mat-form-field class="form-group" [floatLabel]="floatLabel">
        <input matInput placeholder="Purchase Order #" class=""
          formControlName="purchaseorder" inputtype="onlynumber,nonnegative">
      </mat-form-field>

      <mat-checkbox class="form-group" formControlName="includeinactiveitems">{{captions.IncludeInactiveItems}}</mat-checkbox>

      <mat-checkbox class="form-group" formControlName="includenoninventoryitems">{{captions.IncludeNonInventoryItems}}</mat-checkbox>

  </section>
