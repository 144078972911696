import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { GlobalConfiguration } from '../shared/business/shared.modals';
import { Localization } from '../../localization/localization';
import { environment } from 'src/environments/environment';
import { routeJSON } from '@assets/routeJson';

declare var $: any;
@Injectable()
export abstract class ConfigRouteCommon implements OnInit {
    /**
     * Localized Captions(strings) Based on Configured Language.
     */
    public captions: any = {};
    public globalConfiguration: GlobalConfiguration;
    localizeObj: any;
    IsLangLoaded: any;
    routeJSONFile = routeJSON;
    ngOnInit() {
        this.loadGlobalConfig();
    }
    public _config: Object;
    public get RouteProperty(): object {
        return this._config;
    }
    public set RouteProperty(v: object) {
        this._config = v;
    }
    constructor(public http: HttpClient, public locale: Localization) {
        this.load();
    }

    public load() {
        this.captions = this.locale.captions;
        let classRef = this;
        let routeJSONConfig = RouteJsonConfig;
        if (routeJSONConfig) {
            classRef._config = routeJSONConfig;
        }
        else {
            // $.ajax({
            //     url: './assets/route.json',
            //     async: false,
            //     success: function (result) {
            //         classRef._config = result;
            //     },
            //     error: function (result) {
            //         console.error(result);
            //     }
            // });
            classRef._config = this.routeJSONFile;
        }
    }

    public loadRouteJson(): Observable<any> {
        // return this.http.get<any>('./assets/route.json');
        return of(this.routeJSONFile)
    }

    getUrl(key: string) {
        try {
            // return eval("this._config." + key);
            return this.getResolvedUrl(key, this._config);
        }
        catch (e) {
            return "";
        }
    }

    getResolvedUrl(key: string, obj) {
        let keys = key.split('.');
        let resolvedUrl = obj;
        keys.forEach(k => {
            resolvedUrl = resolvedUrl[k];
        })
        return resolvedUrl ?? key;
    }

    getHost(key: string) {
        return environment[key];
    }

    loadGlobalConfig() {
        this.globalConfiguration = this.loadJSON('./assets/configurations/global.configuration.json');
    }


    /**
   * @function loadJSON(path)
   * @param path string
   * @description Retrieves the JSON object from the path
   * @output <any>
   */
    loadJSON(path: string): any {
        let JSONResult = {};
        if (path) {
            $.ajax({
                url: path,
                async: false,
                success: function (result) {
                    JSONResult = result;
                },
                error: function (result) {
                    console.error(result);
                }
            });
        }
        return JSONResult;
    }
}
