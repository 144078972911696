<section  id="floating-wrpper">
    <div class="icon-container">
        <div [popover]="myPopover" popoverPlacement="left"
        [popoverOnHover]="true" [popoverCloseOnMouseOutside]="true" [popoverCloseOnClickOutside]="true">
            <i  aria-hidden="true" class="material-icons">expand_more</i>        
        </div>
        <popover-content #myPopover 
                        title="" 
                        placement=""                
                        [animation]="true" 
                        [closeOnClickOutside]="true" >
                        <div *ngFor="let item of list;let i=index">
                            <div  class="border-color"  (click)="navigate($event,item.idName);myPopover.hide()" >
                                <span class="text">{{item.displayName}}</span>
                            </div>                       
                        </div>
        </popover-content>
    </div>    
</section>
