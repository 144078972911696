<section class="comp-slip-lookup">
    <form [formGroup]="CompLookupForm">
        <div class="rain-check-search">
            <mat-form-field class="rc-searchbox" [floatLabel]="floatLabelNever" [ngStyle]="{'width.ch': width}">
                <input type="text" [placeholder]="ShopCaptions.CMS.searchPlaceHolder" matInput
                    formControlName="patronSearch" maxlength="25" autocomplete="off" required>
            </mat-form-field>
        </div>
        <div class="rain-check-search">
            <mat-form-field class="rc-searchbox" [floatLabel]="floatLabelNever" [ngStyle]="{'width.ch': width}">
                <input type="text" [placeholder]="ShopCaptions.CMS.CompSlipSearch" matInput
                    formControlName="compSlipSearch" maxlength="25" autocomplete="off" required>
            </mat-form-field>
            <div class="icon-section">
                <i  aria-hidden="true" class="icon-search icon-Search" (click)="searchCompSlip()"></i>
            </div>
        </div>
        <div class="comp-slip-info-cont">
            <ng-container *ngIf="Vouchers.length > 0">
                <div class="balance-hdr">{{ShopCaptions.CMS.OffersAndVoucher}}</div>
                <div>
                    <mat-radio-group formControlName="voucherSelection">
                        <div class="voucher-cont" *ngFor="let voucher of Vouchers">
                            <mat-radio-button (click)="selectVoucher(voucher)" [value]="voucher.voucherId">
                            </mat-radio-button>
                            <div class="d-flex voucher-details">
                                <div class="flx-grw">
                                    <div>{{voucher.name}}</div>
                                    <div>({{voucher.voucherId}})</div>
                                </div>
                                <div>{{voucher.voucherAmount | Currency}}</div>
                            </div>
                        </div>
                    </mat-radio-group>
                </div>
            </ng-container>
            <ng-container *ngIf="Vouchers.length == 0 && selectedPlayerInfo != null">
                <div class="no-offer-msg">{{ShopCaptions.NoDataAvailable}}</div>
              </ng-container>
        </div>
    </form>
</section>