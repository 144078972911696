import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant';
import { Outlet, CCTerminal } from '../../retail.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailCreditCardTerminalDataService } from './retail-credit-card-terminal-data.service';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { Router } from '@angular/router';
import { TableSearchHeader } from 'src/app/common/Models/ag-models';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CreateCreditCardTerminalComponent } from './create-credit-card-terminal/create-credit-card-terminal.component';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonType } from 'src/app/common/shared/shared/globalsContant';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-retail-credit-card-terminal',
  templateUrl: './retail-credit-card-terminal.component.html',
  styleUrls: ['./retail-credit-card-terminal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RetailCreditCardTerminalDataService]

})
export class RetailCreditCardTerminalComponent implements OnInit {
  Outlets: Outlet[] = [];
  tableoptions: any[];
  captions: any;
  currIndex: any;
  IsViewOnly = false;
  CreditCardFormGroup: UntypedFormGroup = this.Form.group({
    terminalid: ['', Validators.required],
    merchantnumber: ['', Validators.required],
    activetoggle: true,
    default: false
  });
  headerButtonName: any;
  cancelButtonName: any;
  TerminalId: any;
  MerchantNumber: any;
  active: any;
  searchPlaceholderValue: any;
  enableToggleButton = false;
  isCancelButtonAvailable = false;
  isValidRoleName = false;
  searchText = '';
  errorText = '';
  maxInputLength = 100;
  cCterminals: CCTerminal[] = [];
  userOperationType: myGlobals.OperationType;
  activeRecord: CCTerminal;
  onEditDisableFunction: boolean = true;
  // isEditFlag is used commonly toggle searchbar on edit and inline edit
  isEditFlag: boolean = false;
  // inlineEditOn is used to disable the form fields, update, cancel buttons on inline edit
  inlineEditOn: boolean = false;
  initialLoads = true;
  callCounter = 0;
  useRetailInterface: boolean;
  searchHeaderOption: TableSearchHeader;
  showInactiveTog = false;
  EditCaption: string;
  dialogSubscription: Subscription;
  enableFeature: boolean = true;
  cCterminalsDS: CCTerminal[];
  cCterminalForGrid: CCTerminal[];
  functionalities: { [key: string]: boolean } = {};

  constructor(private Form: UntypedFormBuilder, public localization: RetailLocalization, private utils: RetailUtilities,
    private business: RetailCreditCardTerminalDataService, private retailService: RetailSetupService,
    private func: RetailFunctionalityBusiness,
    private propertyInfo: RetailPropertyInformation, private router: Router, private dialog: MatDialog) {
    if (this.propertyInfo.UseRetailInterface) {
      this.useRetailInterface = this.propertyInfo.UseRetailInterface;
      this.router.navigateByUrl('/settings/retailsetup/codesetup/outlets');
    }
  }

  ngOnInit() {
    this.userOperationType = myGlobals.OperationType.Create;
    this.captions = this.localization.captions.retailsetup;
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(bp => bp.breakPointNumber == myGlobals.RetailBreakPoint.creditcardterminal).view;
      this.setSearchHeaderOption(this.captions.btn_CreditCardTerminal, this.captions.lbl_searchCreditCardTerminalPlaceholder);
      this.loadInitialData();
    });
  }

  setSearchHeaderOption(createBtnLabel, searchPlaceHolder) {
    this.searchHeaderOption = {
      createBtnLabel,
      searchPlaceHolder,
      toggleLabel: this.captions.tog_lbl_showInactive,
      showInactive: !!this.functionalities.ShowCreditCardTerminalIncludeInactiveToggle,
      toggleDisabled: false,
      createBtnDisabled: this.IsViewOnly,
      hasHeaderAction: false,
      hideActionBtn: false,
      automationId: 'retailCreditCardTerminal'
    };
  }

  private async loadInitialData() {
    [this.initialLoads, this.callCounter] = this.retailService.updateLoaderState(false, this.initialLoads, this.callCounter);
    this.cCterminalsDS = await this.business.getAllCCTerminal();
    [this.initialLoads, this.callCounter] = this.retailService.updateLoaderState(true, this.initialLoads, this.callCounter);
    this.BindToGrid(this.cCterminalsDS);
  }

  async dragDrop(event: Array<number>) {
    let cCterminalsDS: CCTerminal[] = await this.business.updateListOrder(event[0], event[1]);
    this.BindToGrid(cCterminalsDS);
  }

  resetValue() {
    this.searchText = '';
  }

  async Done(body: CCTerminal) {
    this.isEditFlag = false;
    this.inlineEditOn = false;
    this.enableFormElements(this.inlineEditOn);
    if (!body.isActive && body.isDefault) {
      this.utils.showAlert(this.captions.alert_defaultCCTerminalInactive, AlertType.Warning, ButtonType.Ok,res=>{
        this.BindToGrid(this.cCterminalForGrid);
      });
      return;
    }
    let updatedTableBody = await this.business.updateCCTerminal(body);
    this.BindToGrid(updatedTableBody);
  }

  EditRecords(data?: any, type?: any) {
    this.CreditCardFormGroup.controls["terminalid"].disable();
    this.activeRecord = data[0];
    this.isValidRoleName = false;
    this.headerButtonName = this.localization.captions.setting.update;
    this.userOperationType = myGlobals.OperationType.Edit;
    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == data[0].id);
    this.enableToggleButton = true;
    this.isCancelButtonAvailable = true;
    this.isEditFlag = true;
    let crudActionInput = {
      mode: myGlobals.GridOperationType.Edit,
      actionButton: this.captions.btn_update,
      form: {
        cTerminalId: this.tableoptions[0].TablebodyData[this.currIndex].terminalId,
        merchantnumber: this.tableoptions[0].TablebodyData[this.currIndex].merchantNumber,
        name: this.tableoptions[0].TablebodyData[this.currIndex].terminalName,
        isActive: this.tableoptions[0].TablebodyData[this.currIndex].isActive,
        listOrder: this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
        folio: this.tableoptions[0].TablebodyData[this.currIndex].folio,
        storeId: this.tableoptions[0].TablebodyData[this.currIndex].storeId,
        isDefault: this.tableoptions[0].TablebodyData[this.currIndex].isDefault,
        id: this.tableoptions[0].TablebodyData[this.currIndex].id,
      },
      enableFeature: this.enableFeature,
      nextListOrder: '',
    };
    this.openDialog(myGlobals.GridOperationType.Edit, crudActionInput);
  }

  async performEditOrSave(form: { formValues: any }) {
    let cCterminalDataSource: CCTerminal[] = [];
    let body = this.generateBody(form.formValues);
    if (this.userOperationType == myGlobals.OperationType.Create) {
      cCterminalDataSource = await this.business.createCCTerminal(body);
    } else if (this.userOperationType == myGlobals.OperationType.Edit) {
      body.id = this.activeRecord.id;
      body.listOrder = this.activeRecord.listOrder;
      cCterminalDataSource = await this.business.updateCCTerminal(body);
      this.activeRecord = null;
      this.userOperationType = myGlobals.OperationType.Create;
      this.isEditFlag = false;
    }
    this.BindToGrid(cCterminalDataSource);
  }

  private generateBody(CreditCardFormGroup: {}): CCTerminal {
    let getVal = ctl => CreditCardFormGroup[ctl];
    return {
      terminalId: getVal('cTerminalId'),
      terminalName: getVal('name'),
      merchantNumber: getVal('merchantnumber'),
      isActive: getVal('isActive') ? true : false,
      listOrder: getVal('listOrder') != "" ? getVal('listOrder') : undefined,
      folio: getVal('folio') != "" ? parseInt(getVal('folio')) : null,
      storeId: getVal('storeId'),
      isDefault: getVal('isDefault')
    } as any;
  }

  async deleteCCTerminal(cCterminal: CCTerminal) {
    let cCterminalDs: CCTerminal[] = this.cCterminalsDS;;
    if(cCterminal[0].isDefault)
    {
      this.utils.showAlert(this.captions.alert_DefaultCCTerminalDelete, AlertType.Warning, ButtonType.Ok, async (res) => {});
    }
    else
    {
      cCterminalDs = await this.business.deleteCCTerminal(cCterminal[0].id);     
      this.BindToGrid(cCterminalDs);
    }
  }

  searchChange(e) {
    this.searchText = e;
  }

  showInactiveToggler(e) {
    this.showInactiveTog = e.checked;
    this.BindToGrid(this.cCterminalsDS);
  }


  onButtonCancelClick(event) {
    this.CreditCardFormGroup.controls["terminalid"].enable();
    if (document.getElementsByClassName('rowDisabled').length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
    }
    this.enableToggleButton = false;
    this.isCancelButtonAvailable = false;
    this.headerButtonName = this.localization.captions.setting.Add;
    this.userOperationType = myGlobals.OperationType.Create;
    this.CreditCardFormGroup.reset();
    this.isEditFlag = false;
  }


  private BindToGrid(cCterminalDataSource: CCTerminal[]) {
    this.cCterminalForGrid = cloneDeep(cCterminalDataSource);
    let filteredSource = [];
    if (this.functionalities.ShowCreditCardTerminalIncludeInactiveToggle && !this.showInactiveTog) {
      filteredSource = cCterminalDataSource.filter(x => x.isActive === true);
    }
    else {
      filteredSource = cCterminalDataSource;
    }
    this.tableoptions = [
      {
        TableHdrData: [{ 'title': this.captions.TerminalID, 'jsonkey': 'terminalId', 'sortable': true, "alignType": "right" },
        { 'title': this.captions.MerchantNumber, 'jsonkey': 'merchantNumber', 'alignType': 'right', 'sortable': true },
        { 'title': '', 'jsonkey': 'dummycolumn', 'sortable': false },
        { 'title': this.captions.Active, 'jsonkey': 'isActive', 'type': 'toggle', 'sortable': true },
        { 'title': this.captions.ListOrder, 'jsonkey': 'listOrder', 'alignType': 'right', 'sortable': true, "searchable": false }],
        TablebodyData: filteredSource,
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.Search,
        EnableActions: true,
        SelectRows: true,
        IsCommission: true,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.creditcardterminal,
        Sortable: 'listOrder',
        TableId: myGlobals.GridType.creditcardterminal,
        disableDelete: false,
        customHeader: false,
        pageTitle: 'outlets',
        ServiceId: 'outlets',
        IsViewOnly: this.IsViewOnly,
        TableDraggable: true,
        automationId: 'retailCreditCardTerminal'

      }
    ];
  }

  // On Inline Active Toggle Change
  sliderChange(event) {
    this.inlineEditOn = true;
    this.disableFormElements(this.inlineEditOn);
  }

  // On Inline Cancel
  Cancel(event) {
    this.isEditFlag = event;
    this.inlineEditOn = false;
    this.enableFormElements(this.inlineEditOn);
  }

  // Method to disable the form elements
  disableFormElements(isEditFlag) {
    this.CreditCardFormGroup.controls['terminalid'].disable();
    this.CreditCardFormGroup.controls['merchantnumber'].disable();
    // This flag is set to false on inline edit mode to disable the ADD button.
    this.isValidRoleName = false;
  }

  // Method to enable the form elements
  enableFormElements(isEditFlag) {
    this.CreditCardFormGroup.controls['terminalid'].enable();
    this.CreditCardFormGroup.controls['merchantnumber'].enable();
  }

  async createEvent(e) {
    let crudActionInput = {
      mode: myGlobals.GridOperationType.Create,
      actionButton: this.captions.btn_create,
      form: undefined,
      enableFeature: this.enableFeature,
      nextListOrder: await this.business.getNextListOrder(),
    };
    this.openDialog(myGlobals.GridOperationType.Create, crudActionInput);
  }

  openDialog(preTit: any, data?: any): any {
    this.EditCaption = (preTit == myGlobals.GridOperationType.Edit) ? this.localization.captions.common.EDIT : this.localization.captions.common.pg_title_create;
    let afterClosed = true;
    return this.triggerPopup(afterClosed, data);
  }


  triggerPopup(afterClosed = true, data: any): any {
    const Dialogtitle = this.captions.pg_title_creditCardTerminal;
    const dialogRef = this.dialog.open(CreateCreditCardTerminalComponent, {
      width: '60%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: {
        headername: this.EditCaption + '  ' + Dialogtitle,
        closebool: true,
        isViewOnly: this.IsViewOnly,
        datarecord: data
      },
      disableClose: true
    });

    if (afterClosed) {
      this.dialogSubscription = dialogRef.afterClosed().subscribe((result: { formValues: any }) => {
        this.isEditFlag = false;
        if (result) {
          this.performEditOrSave(result).then(() => {
            this.utils.showCommonAlert('', AlertType.Success);
          }).catch((e) => {
            console.error(e);
          });
        }
        else{
           this.userOperationType = myGlobals.OperationType.Create;
        }
        if (document.getElementsByClassName("rowDisabled") && document.getElementsByClassName("rowDisabled").length > 0) {
          document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
          document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
        }
      });
    }
    return dialogRef;
  }
}
