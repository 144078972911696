import { Component, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TimelineService } from '@app/timeline/timeline.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { PopupService } from '@popup-module/popup.service';

@Component({
  selector: 'app-reservation-change-confirm',
  templateUrl: './reservation-change-confirm.component.html',
  styleUrls: ['./reservation-change-confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReservationChangeConfirmComponent implements OnInit {
  config: FieldConfig[];
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @Input() data: any;
  constructor(public injector: Injector, public ts: TimelineService, private ps: PopupService) { }

  ngOnInit() {

    // this.ts.draggedData;
    // this.config = [
    //       {
    //         type: 'select',
    //         // label: 'fd',
    //         name: 'language',
    //         placeholder:  'New Table Number',
    //         options: [{id: this.ts.draggedData.Id, value: this.ts.draggedData.Id }, {id: 2, value: '2'}],
    //         class: 'confirm__table-number',
    //         showErrorText: false,
    //         value: this.ts.draggedData.msg.showMessage ? '' : this.ts.draggedData.Id,
    //         showNumbers: true

    //       },
    //       {
    //         type: 'date',
    //         // label: "ds",
    //         name: 'deliveryDate',
    //         class: 'confirm__date',
    //         label: 'timelineDate',
    //         value: this.ts.draggedData.SeatingTime
    //       },
    //       {
    //         type: 'date',
    //         name: 'deliveryDate',
    //         label: 'timelineTime',
    //         class: 'confirm__time',
    //         value: this.ts.draggedData.DepartureTime
    //       },



    //     ];
    //     this.ps.nextEvent$.subscribe(val => {
    //       if (val == 'timeline') {
    //         console.log(this.dynamicForm.value);
    //     }
    //   });
  }

  ngOnChanges() {
    //console.log(this.data);
  }


}
