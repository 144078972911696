import { TransactionDetail } from "../business/shared.modals";
import { DepositTransactionStatus } from "../service/payment/payment-business.model";

export const retailEventName: string = "retailEvent";

export interface RetailEventParameters<T> {
  eventType: RetailEventType;
  data: T;
  callBack?: (data: T) => void;
}



export enum RetailEventType {
  // Common Events
  Deposit = 1,
  CancelDeposit,
  Void,
  Cancel,
  ReOpen,
  DeleteTransaction,
  ReturnWithTicket,
  Correct,
  SyncUpTransaction,
  CancelOrderSummary,
  LinkRetailItem
}

export interface VoidEventModel {
  transactionId: number;
  isDeposit?: boolean;
  ticketNumber?: string;
  lstTransactionDetailId?:number[]
}

export interface TicketChangeEventModel {
  transactionId: number;
  oldTransactionId: number;
  transactionDetail: TransactionDetail[];
  ticketNumber?: string;
  oldTicketNumber?: string;
}


export interface DepositEventModel {
  id: number;
  typeId: number;
  amount: number;
  gratuity: number;
  serviceCharge: number;
  refundAmount?: number;
  depositTransactionId?: number;
  depositTransactionDetailId?: number;
  refundTransactionId?: number;
  isVoided?: boolean;
  status?: DepositTransactionStatus;
  clientId?: number;
  clientName?: string;
  clienttype?:number;
  clientLinkId?:string;
}

export interface CancelDepositEventModel {
  id: number;
  transactionId: number;
  cancelReason: number;
  depositRefundAmount: number;
  cancelFee: any;
  cancelComments: string;
}

export interface TransactionCompletedModel {
  ticketNumber: number;
  checkNumber: string;
  transactionId: string;
}
