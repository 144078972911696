import { Component, Inject } from '@angular/core';
import { ErrorInput } from '../../interfaces/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { DialogContainerComponent } from '../dialog-container/dialog-container.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lib-error-info',
  standalone: true,
  imports: [CommonModule, DialogContainerComponent, TranslateModule],
  templateUrl: './error-info.component.html',
  styleUrls: ['./error-info.component.scss']
})
export class ErrorInfoComponent {

  constructor(public dialogRef: MatDialogRef<ErrorInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorInput){
    }
}
