<div class="parties">
<div class="parties__party-info" style="height:127px;background:white;border-radius: 5px;
display: inline-flex;
width: 100%;
align-items: center;">
  <div class="parties__party-intials">
    re
  </div>
  <div class="parties__party-data">
    <span class="parties__party-name">Andres rr</span>
    <span class="parties__table-cover">4 covers</span>
    <span class="parties__reserved-time">14:45pm</span>
  </div>
</div>
</div>