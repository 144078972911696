<div class="hotel-concierge" (click)="setDefaultValue()">
  <div class="hotel-concierge__controls">
    <activities-app-button [buttontype]="actionBtn" (click)='editOrPost(isEdit)'></activities-app-button>
    <dynamic-form [config]="searchConfig" #form="dynamicForm" class="hotel-concierge__search-text">
    </dynamic-form>
  </div>
  <div class="hotel-concierge__add-new" *ngIf="isEdit">
    <input class="hotel-concierge__input" placeholder="{{(conciergeTrackingType == ConciergeTrackingType.Hotel ? 'hotelNamePH' : 'conciergeNamePH') | translate}}" [(ngModel)]="newItem" (click)="clearValue()">
    <i class="icon-add" (click)="addItem()"></i>
  </div>
  <div class="hotel-concierge__table-data" [ngClass]="{'hotel-concierge__table-data-adjusted': isEdit}"
    *ngIf="conciergeTrackingList?.length > 0">
    <ng-scrollbar>
      <table class="hotel-concierge__table-view">
        <tr class="hotel-concierge__tbl-row" *ngFor="let item of conciergeTrackingList | filterBy: ['Name']: searchString;let i = index">
          <td *ngIf="!isEdit" (click)="selectedItem(item)">{{item.Name}}</td>
          <td *ngIf="isEdit">
            <input class="hotel-concierge__tbl-input" placeholder="{{(conciergeTrackingType == ConciergeTrackingType.Hotel ? 'hotelNamePH' : 'conciergeNamePH') | translate}}" [(ngModel)]="item.Name" value={{item.Name}}>
          </td>
          <td class="icon-ic_delete_24px" (click)="deleteItem(item)" *ngIf="isEdit"></td>
        </tr>
      </table>
    </ng-scrollbar>
  </div>
  <div *ngIf="conciergeTrackingList?.length == 0" class="hotel-concierge__empty-list">
    <p class="hotel-concierge__empty-content">{{(conciergeTrackingType == ConciergeTrackingType.Hotel ? 'emptyHotelList' : 'emptyConciergeList') | translate}}
    </p>
  </div>
</div>