<div class="preferred-table">
  <div class="preferred-table__header">
    <div  class="reservation-select-label preferred-table__select-label">
      <span class="preferred-table__view-label seat-text-fields" *ngIf="updatedSelectedTableNames.length">{{'waitListPopUpSelectTable'|translate}} - {{updatedSelectedTableNames}}</span>
      <!-- <span *ngIf="showMessage" class="error-message">Choose maximum of 3 servers</span> -->
    </div>
    <div class="preferred-table__view-legends">
      <label class="seat-tertiary-text" for="">{{'floorText' | translate}}</label>
      <dynamic-form [config]="config" #form="dynamicForm" >
      </dynamic-form>
      <!-- <app-display-legend [legends]="displayLegends"></app-display-legend> -->
    </div>
  </div>
  <div class="preferred-table__canvas-section" *ngIf="layoutConfigurationData">
    <app-layout-view [layoutConfig]="layoutConfiguration" [seatPartyData]="seatPartyData" (selectedTableEvent)="selectedTables($event)" ></app-layout-view>
  </div>
</div>