<!-- <div [ngClass]="config.class">
  <label class="toggle-label">{{ config.label }}</label>
  <div class="toggle-button" [formGroup]="group">
    <label class="switch">
      <input  class="switch-input" type="checkbox" [formControlName]="config.name" *ngIf="config.changeEvent" (change)="toggleChange($event)"/>
      <input  class="switch-input" type="checkbox" [formControlName]="config.name" *ngIf="!config.changeEvent"/>
      <span class="switch-label" data-on="Yes" data-off="No"></span>
      <span class="switch-handle"></span>
    </label>
  </div>
</div> -->
<div class="toggle-control"  [formGroup]="group">
  <label class="toggle-control__toggle-label guest-form-label">{{config.label | translate}}</label>
  <mat-button-toggle-group class="toggle-control__toggle-view" [formControlName]="config.name">
    <mat-button-toggle *ngFor="let toggle of config.options" [value]="toggle.value"  >
      <i *ngIf="toggle.icon" class="icon-{{toggle.icon}}" style="padding-right: 3px;"></i>
      <span>{{toggle.value}}</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
