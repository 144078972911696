
import { Injector } from "@angular/core";
import { Router } from "@angular/router";
import { QuickLoginUtilities } from "@app/common/shared/shared/utilities/quick-login-utilities";
import { RetailPropertyInformation } from "@app/retail/common/services/retail-property-information.service";
import { RetailItemType } from "@app/retail/retail.modals";
import { RetailService } from "@app/retail/retail.service";
import { RetailDataAwaiters } from "@app/retail/shared/events/awaiters/retail.data.awaiters";
import { RetailSharedVariableService } from "@app/retail/shared/retail.shared.variable.service";
import { CommonVariablesService } from "@app/retail/shared/service/common-variables.service";
import { ConfigKeys, RetailFeatureFlagInformationService } from "@app/retail/shared/service/retail.feature.flag.information.service";
import { SelectedProducts } from "@app/retail/shared/shared.modal";
import { RedirectToModules, RetailUtilities } from "@app/retail/shared/utilities/retail-utilities";
import { PropertyFeaturesConfigurationService } from "@app/retail/sytem-config/payment-features-config/property-feature-config.service";
import { RetailBussinessService } from "@app/shared/retail.bussiness";
import _ from "lodash";
import { PartyService } from "../party.service";



export class ActivitiesShopItemDetails {

  protected _partyService;
  protected _retailService; 
  protected _rb;
  constructor( public _ams: RetailService,injector: Injector) {
    if (injector) {
      this._partyService = injector.get(PartyService);
      this._retailService = injector.get(RetailSharedVariableService);
      this._rb = injector.get(RetailBussinessService);
    }
    if(this._retailService.memberCardNumber && this._retailService.memberCardNumber != 0){
      this._ams.clientSelect = 1;
    }
  }


  async getClientData(){
    let clientData;
    if(this._retailService.SelecedSeatProducts.length > 0){
    let cartData = this._retailService.SelecedSeatProducts[0];    
    clientData = await RetailDataAwaiters.getPayeeDetails(cartData?.guestId);   
    }
    if (clientData) {
      this._ams.labelRecords = [{
        Id: clientData?.id,
        Name: clientData?.name
      //  guestProfileId: clientData?.id
      }];
    }
  }

  intialize()
  {
       // Activities - retail integration changes
       this._ams.showDefaultOptions = this._retailService.SelecedSeatProducts.length > 0 ? true : false;
       let party = this._partyService.cancelBookingContactObj;
       this._ams.labelRecords =[{
         Id: party?.Id,
         Name: (party?.FirstName || '') + ' ' + (party?.LastName || '')
       }];
      //  this.getClientData(); 
  }

}
