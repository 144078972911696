import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { buttonTypes, ReservationType } from '@app/shared/constants/commonenums';
import { ButtonValue } from '@app/shared/dynamicform/models/field-config.interface';
import { RestaurantAuditLogItemDTO } from '@app/shared/models/SimpleAuditLogItemDTO';
import { FacadeService } from '@app/shared/services/facade.service';
import { PartyService } from '@app/shared/services/party.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { TabConfig, TabsModel } from '@popup-module/models/popup.interface';
import { SideNavService } from '@services/sidenav.service';
import { MessagesViewComponent } from './messages-view/messages-view.component';
import { ReservationViewComponent } from './reservation-view/reservation-view.component';
import { TablesViewComponent } from './tables-view/tables-view.component';
import { AuditableActionType } from '@app/shared/models/SimpleAuditLogItemDTO';
import { Utilities } from '@app/shared/utilities/utilities';
import { settings } from 'cluster';
import { SettingsDTO } from '@app/shared/models/RestaurantDTO';
import moment from 'moment';
import { actionsIcons } from '@app/shared/constants/globalConstants';
import { AppService } from '@app/app.service';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.scss']
})



export class NotificationViewComponent implements OnInit {
  tabcomponentDetails: TabConfig[];
  tabDetails: TabsModel;
  @Output() viewAllEvent = new EventEmitter();
  @Output() actionPerformed = new EventEmitter();
  @Input() alertData:RestaurantAuditLogItemDTO[];
  currentTabIndex: number;
  auditLogBtn: ButtonValue;
  alertColumn: string[] = ['party', 'action', 'time', 'view'];
  auditableActionType =AuditableActionType;
  alertTableDataSource: MatTableDataSource<RestaurantAuditLogItemDTO>;
  constructor(public router: Router, private ps: PartyService, public sidenav: SideNavService, public fs: FacadeService, public df: DashboardFunctions,private appService : AppService) { }

  ngOnInit() {


    this.auditLogBtn = {
      type: buttonTypes.actionPrimarySmall,
      label: 'view',
      customclass: 'notifications__audit-btn',
    }

    this.tabcomponentDetails = [{
      tabComponent: MessagesViewComponent,
      tabLabel: 'MESSAGES(' + 4 + ')',
    },
    {
      tabComponent: TablesViewComponent,
      tabLabel: 'TABLES',
    },
    {
      tabComponent: ReservationViewComponent,
      tabLabel: 'RESERVATION',
    }];
    this.tabDetails = {
      tabs: this.tabcomponentDetails,
    }

  }

  ngOnChanges(){
      this.alertTableDataSource = new MatTableDataSource(this.alertData);    
  }

  viewReservation(auditData: RestaurantAuditLogItemDTO) {
    let party = this.ps.Parties$.value.filter(party => party.Id == auditData.PartyId)
    this.appService.showNotificationSection = false;
    if (party.length > 0) {     
        this.actions(party[0],auditData)
    }
    else{  
      this.ps.getPartyInfo(auditData.PartyId).subscribe(data=>{
        let partyInfo = data.Payload;
        this.actions(partyInfo,auditData)
      })
    }
    this.actionPerformed.emit(true)
  }
  actions(party,auditData:RestaurantAuditLogItemDTO){
    if (party.Type == ReservationType.Reservation) {
      (auditData.ActionType == AuditableActionType.Cancel) ? this.df.createOrEditReservation(true, party, false): this.df.createOrEditReservation(true, party);
    }
    else if (party.Type == ReservationType.Waitlist) {
      (auditData.ActionType == AuditableActionType.Cancel) ? this.df.createOrEditWaitlist(true, party, false): this.df.createOrEditWaitlist(true, party);     
    }
  }

  clearList() {
    this.sidenav.clearData.next(this.currentTabIndex);
  }

  tabChange($event) {
    this.currentTabIndex = $event.index;
  }


  viewAll() {
    this.viewAllEvent.emit('close');
    this.router.navigate(['./notifications']);
  }

}
