import {
	Component,
	OnInit,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
	OnDestroy,
	Injector
} from '@angular/core';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import {
	BaseResponse,
	PaymentMethods,
	ClientCreditCardInfo,
	CardInfo,
	IDTechHandle,
	PayeeInfo, AppointmentLineNumber
} from '../../shared/business/shared.modals';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShopDialogPopUp } from '../shop-dialog-popup/shop-dialog-popup.component';
import { RetailUtilities, RedirectToModules, SNCRoute, SNCFromPage } from '../../shared/utilities/retail-utilities';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { TransactionData, RetailShopItem, ItemDiscount, ApplyDiscount, DiscountTypeDetails, enterCommentPrompt, UserSessionConfiguration, ReportAPIOptions, ReceiptConfiguration } from '../shop.modals';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import * as GlobalConst from '../../shared/globalsContant';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { RetailPropertyInformation } from "../../common/services/retail-property-information.service";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { Router } from "@angular/router";
import { CommonVariablesService, ServiceChargeGratuity, ApplyDiscountInput, SelectedProducts, LineItem, ServiceChargeGratuityEnum, Ticket } from "../../shared/service/common-variables.service";
import { AlertMessagePopupComponent } from "../../shared/alert-message-popup/alert-message-popup.component";
import { RetailTransactionService } from "../view-categories/retail-transactions/retail.transactions.service";
import { ApplyDiscountService } from "../apply-discount/appply-discount.service";
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ActionMode, PlayerCategory, Product } from 'src/app/common/enums/shared-enums';
import {
	PaymentMethod,
	PaymentHistory,
	MemberPaymentHistory,
	TryPayResponse,
	GuestStayDetail,
	ArAccount,
	ARPostPaymentResponse,
	LookUpInput,
	GuestRoomSearchResult,
	DepositTransactionStatus,
	CardEntryModeDialogResult,
	CardEntryModeDialogAction,
	PMSXMLDBInterface,
	PMSHTTPInterface,
	PMSIntegrationHostId,
	CardInputMethod
} from '../../shared/service/payment/payment-business.model';
import { ShopBussinessService } from '../shop-business.service';
import { ReceiptBusinessService } from '../receipt/business/receipt-business.service';
import { PayAgentService } from '../../shared/service/payagent.service';
import { NegativeValueValidator } from '../../shared/Validators/NegativeValueValidator';
import { UserMachineConfigurationService } from '../../common//services/user-machine-configuration.service';
import { SPAConfig } from '../../common/config/SPA-config';
import { takeUntil } from 'rxjs/operators';
import { RetailService } from '../../retail.service';
import {
	RainCheckIssue,
	PlayerPaymentstatus,
	TransactionDetailAggregate,
	TeeTicketDetail,
	PlayerTransactionStatus,
	ACESPaymentRecord,
	ScheduledTeeTimeUnPaidPlayer,
	AlertType,
	ClientInfo,
	AlertAction
} from '../../shared/shared.modal';
import {
	RetailEventType,
	RetailEventParameters,
	TicketChangeEventModel,
	VoidEventModel,
	DepositEventModel,
	CancelDepositEventModel
} from '../../shared/events/event.model';
import { retailPublisher } from '../../shared/events/pubsub/retail.publishers';
import { RetailDataAwaiters } from '../../shared/events/awaiters/retail.data.awaiters';
import { RetailValidationService } from '../../shared/retail.validation.service';
import { FinancialBinLevel, ModifyItemQuantity, PrintInformationType, PrintType, RetailItemType, SystemConfiguration } from '../../retail.modals';
import { MemberBusinessService } from '../../shared/business/Member-business.service';
import { CMSBusinessService } from '../../shared/business/CMS-business.service';
import { CMSPlayerInfo, Voucher } from 'src/app/common/Models/common.models';
import { GiftCardBusiness } from '../../sytem-config/gift-cards/gift-cards.business';
import { RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';
import { GatewayConfiguration, GiftCardBalance, GiftcardSearchResult } from '../../shared/service/payment/payment-model';
import { ARPostingBusinessService } from '../../shared/business/AR-Posting.business.service';
import { TeetimePaymentBusinessService } from '../../shared/business/Teetime-Payment.business.service';
import { RetailSharedVariableService } from '../../shared/retail.shared.variable.service';
import { CommonCommentComponent } from '../view-categories/common-comment/common-comment.component';
import { CaddyShackBusinessService } from '../caddyshack/caddyshack-business.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { DialogOverviewExampleDialog } from '../../shared/dialog-popup/dialogPopup-componenet';
import { CardEntryModeComponent } from '../../shared/card-entry-mode/card-entry-mode.component';
import { GiftCardMethods, RainCheckEventType } from '../../payment/PaymentFlowHelpers';
import { Product as ProductId } from 'src/app/common/shared/shared/utilities/common-utilities';
import { FastReportBusinessService } from '../../retail-reports/fast-report/fastreport.business.service';
import { SaleChitBusinessService } from '../salechit/salechit-business.service';
import { ApplyDiscountComponent } from '../apply-discount/apply-discount.component';
import { PaymentComponent } from '../../payment/payment.component';
import { PropertySettingDataService } from '../../sytem-config/property-setting.data.service';
import { TransactionEngineBusiness } from '../../retail-transaction-engine/transaction-engine-business';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { SurchargeConfigurationService } from '../../sytem-config/data-service/surcharge-configuration.data.service';
import { Guid } from 'guid-typescript';
import { EformsBusiness } from '../../../common/data-magine/dm-eforms/dm-eforms.business';
import { EmailReceiptComponent } from '../email-receipt/email-receipt.component';
import { ImageProcessorService } from 'src/app/common/shared/shared/service/image-processor-service';
import { RetailRoutes } from '../../retail-route';
import { FacadeService } from 'src/app/common/services/facade.service';
import { UserAccessService } from 'src/app/common/services/user-access.service';
import { UserAccessBreakPoints } from '../../shared/constants/useraccess.constants';
import { MiscConfigurationSwitches, SettingModule, SettingScreen } from '../../Folio/Model/folioDetails-model';
import { FolioBusiness } from '../../Folio/folio-business';
import { DatamagineConfigBusiness } from 'src/app/common/data-magine/data-magine-config/data-magine-config.business';
import { ActivitiesRetailOrderSummary } from '@app/shared/services/retail/activities-retail-ordersummary';
import { PartyService } from '@app/shared/services/party.service';
@Component({
	selector: 'order-summary',
	templateUrl: './order-summary.component.html',
	styleUrls: ['./order-summary.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [EformsBusiness, ImageProcessorService, FacadeService, UserAccessService, DatamagineConfigBusiness]
})
export class OrderSummaryComponenet extends ActivitiesRetailOrderSummary implements OnInit, OnDestroy {
	disableRemove: boolean = false; // to disable actions till payment is completed
	fromSection: string;
	/** Assign the Public Values */
	public multiplePayment: boolean = false;
	public FullPayment: boolean = true;
	public PaymentFull: string = 'FullPayment';
	public PaymentPartial: string = 'PartialPayment';
	public align: any = this.PaymentFull;
	public myModel: boolean = false;
	public SingleChecked: boolean = true;
	public OverAllCheck: any;
	public GridItems: any = [];
	public ArrayModerate: any = [];
	public FinalArray: any = [];
	public myModeled: any;
	public textbox: any;
	public payeeFolioInvoiceNumber: string;
	public log: any;
	public totalAmount: any;
	public subTotal = 0;
	public totalAmountWithOutTax = 0;
	public totalServiceCharge = 0;
	public totalGratuity = 0;
	public myModeledddd: any;
	public beforeClinet: boolean = true;
	public payeeInfo: PayeeInfo;
	public appoinmentEditbool = false;
	public paymentMethod: number = 0;
	public taxValue: number = 0;
	public totalTaxValue: number = 0;
	public serviceChargeTax: number = 0;
	public gratuityTax: number = 0;
	public totalTaxOnTax: number = 0;
	public serviceVAT: number = 0;
	public showServiceVAT: boolean = false;
	public exempttax: any;
	public addClient: boolean = false;
	public checkedOverall: any;
	public checkAll: any;
	public isReturnWithoutTicketForReceipt: boolean = false;
	public totalDiscount = 0;
	private isCardTransaction: boolean = false;
	// public cartFees: any;
	public availableRounds: number = 0;
	isVATEnabled: boolean = false;
	AddGuest: string = '';
	currentProductId: number;
	ProductSpaId: GlobalConst.Product;
	ProductSNCId: GlobalConst.Product;
	ProductGolfId: GlobalConst.Product;
	ProductRetailId: GlobalConst.Product;
	ProductPMSId: GlobalConst.Product;
	Memberpin: number;
	cmsSearch: string;
	CMSForm: UntypedFormGroup;
	beoTab: number = 8;
	cmsPlayerHandles: any[] = [];
	selectedGiftCard: GiftcardSearchResult;
	selectedARAccount: ArAccount;
	public commentDisable: boolean = false;
	quickIdUser: string = '';
	isMemberNotActive: boolean;
	ResetRainCheckSelection: boolean = false;
	isRetailInterfaceEnabled: any;
	productIdEnum = ProductId;
	BPointMap: Map<number, boolean> = new Map();
	TicketTotal: number = 0;
	yPos: any;
	cmsAccrualPin: string = "";
	public isCardOnFileDisabled: boolean = false;
	searchValue: string
	memberImageURL: string;
	IsExpireDateVisible: boolean = false;
	isPlatformGuestSearch: boolean = false;
	showPlatformGuestSearch: boolean = false;
	clientSearchForm: UntypedFormGroup;
	shouldTaxRecalculate: boolean = false;
	public get IsRefund() {
		return this.totalAmount < 0;
	}
	public get IsCMSPayment() {
		return (this.selectedPaymentMethod == PaymentMethods.CompRedemption
			|| this.selectedPaymentMethod == PaymentMethods.OfferRedemption
			|| this.selectedPaymentMethod == PaymentMethods.CompSlipRedemption);
	}

	public get TotalVatAmount() {
		if (!this.PropertyInfo.UseRetailInterface) {
			return this._ss && this._ss?.Ticket && this._ss?.Ticket?.lineItems && this._ss?.Ticket?.lineItems?.some(s => s.vat) ? this._ss.Ticket.lineItems.reduce((sum, current) => sum + current.vat, 0) : 0;
		}
		else {
			return 0;
		}
	}

	get selectedPaymentMethod(): PaymentMethods { return this.selectedpayment?.isCloned ? this.selectedpayment?.parentTypeId : this.selectedpayment?.paymentTypeId }

	cancelDepositAppointments: CancelDepositEventModel[] = [];
	depositArray: DepositEventModel[] = [];
	public deposit: number = 0;
	isEditTransaction: false;
	selectedClientId: number = 0;
	selectedClientGuid: string = null;
	returnReason: string = '';
	captions: any = this.localization.captions.bookAppointment;
	oCaptions: any = this.localization.captions;
	ShopCaptions: any = this.localization.captions.shop;
	giftcardCaptions: any = this.localization.captions.shop.GiftCard;
	depositFlag: any;
	cancelFlag: any;
	showPayment: any;
	transactionId: number;
	ticketNumber: number;
	printedDate: Date = new Date();
	salesDate: Date = new Date();
	outletName: string;
	clerkName: string;
	receiptTypeNumber: number;
	numberOfReceipts: number;
	displayDiscount: boolean;
	addSecondLine: boolean;
	displayOnlyPackageItem: boolean;
	suppressClerkId: boolean;
	displayPackageDescription: boolean;
	displayPackagePrice: boolean;
	displayPackageAppointmentID: boolean;
	displayPackageStaffCode: boolean;
	serviceChargeGratuityDisplay: number;
	gratuityLine: string;
	receiptNote: string;
	private CheckOutAppointmentsList: AppointmentLineNumber[] = [];
	$destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
	clientInfo: PayeeInfo;
	enableApply = false;
	xPos: number;
	isRight: boolean;
	tempItemDetails: any;
	enableSave = true;
	percentage = [{ id: 1, name: 'Staff', percentage: '30%' }];
	openedIdx: number = -1;
	isOpened: boolean = false;
	compSlipRedeem = "Comp Slip Redeem";
	paymentMethods: Array<PaymentMethod> = [];
	paymentMethodsClone: Array<PaymentMethod> = [];
	copiedPaymentMethods: Array<PaymentMethod> = [];
	selectedpayment: PaymentMethod;
	paymentType: any;
	test: string;
	returnAmount: any;
	deviceType: Array<any>;
	selectedDeviceHandle: string = '';
	cardDetailsBool: boolean = false;
	ifCreditCardBool: boolean = false;
	paymentText: string;
	ifPaymentChoose: boolean = false;
	deviceId: string;
	transitionDetails: UntypedFormGroup;
	roomDetailsArray: Array<GuestStayDetail>;
	remainingAmount: number;
	defaultSelectchecked: number = 0;
	retainPaymentText: string;
	retainselectedpayment: PaymentMethod;
	assignClientToRoom: boolean = true;
	disabledRadiobool: boolean = false;
	zipcodeFormat: any;
	profitCenter = '';
	IsRainCheckGridData: boolean = true;
	printCaddyShack: boolean = false;
	receiptComment: string = '';
	caddyshackcomment: string = '';
	isUseNewArCustomerSearch: boolean = false;
	quickLoginPopupEnabled: boolean = false;
	giftcardBalance: Array<GiftCardBalance> = [];
	receiptClerkName: string;
	receiptPrintBy: string;
	isRequireSignatureCapture: boolean = false;
	@ViewChild('paymentmethod')
	paymentmethod: ElementRef;
	@ViewChild('returnReason')
	returnReasonElement: ElementRef;
	playerCategory = PlayerCategory;

	public config: SwiperConfigInterface = {
		slidesPerView: 4,
		spaceBetween: 12,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		}
	};
	SettlementHistory: Array<PaymentHistory> = [];
	SettlementHistoryRequest: Array<PaymentHistory> = [];
	ShowSettlemtHist: boolean = false;
	allowTaxExempt: boolean = true;
	currentSelectedItemForDiscount: any;
	applyDiscountInput: ApplyDiscountInput = {
		CategoryId: 0,
		SelectedDiscount: null,
		isMultipleItems: true,
		showItemDetails: false
	};
	@ViewChild('discountPopover')
	discountPopover: any;
	@ViewChild('applyAllPopover')
	applyAllPopover: any;
	SettledAmt: number;
	ShowTenderField: boolean = false;
	ShowPaymentMethods: boolean = true;
	EnableCloseTranBtn: boolean = false;
	CurrentTryPayResponse: TryPayResponse;
	CreateRetailItemResponse: any;
	ContinuePayment: boolean = false;
	applyAllDiscountInput: ApplyDiscountInput;
	AllowCancel: boolean = true;
	SelectedRoomNo: string = '';
	isTaxRestored = false;
	paymentButton: boolean = false;
	CardOnFileforSelectedGuest: ClientCreditCardInfo;
	CurrentActiveCard: CardInfo;
	GatewayConfiguration: GatewayConfiguration;
	deviceName: string;
	IsRefundFlow: boolean = false;
	StartedWithCashOrCardOnFile: boolean = false;
	RoomChargeTenderID: string = '3';
	MissingAmountTenderMsg: string = '';
	AmountTenderInputType: string = 'nonnegative,decimal,roundoff2';
	GuestAccounts: any = [];
	TempRoomNo: string = '';
	CurrentUserConfig: UserSessionConfiguration;
	EncryptedCardData: string = '';
	ManualClosePerformed: boolean = false;
	GuestProfileRequired: boolean = false;
	NoCardSwipeTimeOut: any;
	NavigationTimeOut: any;
	rainCheckPayment = false;
	dateInput: {
		className: string;
		form: any;
		formControlName: string;
		errorMessage: string;
		minDate?: Date;
		placeHolder: string;
		value: any;
	};
	RainCheckForm: UntypedFormGroup;
	searchPlaceholder = this.localization.captions.shop.SearchByRefName;
	searchText = '';
	rainCheckTransaction: RainCheckIssue[] = [];
	rainCheckTransactionClone: RainCheckIssue[] = [];
	rainCheckSelection: number = 0;
	@ViewChild('myPopover') myPopover;
	@ViewChild('popOverTarget') popOverTarget;
	SelectedRainCheck: RainCheckIssue;
	NonPayAgentMethods: PaymentMethods[] = [
		PaymentMethods.Cash,
		PaymentMethods.RainCheck,
		PaymentMethods.ARPost,
		PaymentMethods.ARAcctPost,
		PaymentMethods.RedeemPoint,
		PaymentMethods.CreditBook,
		PaymentMethods.OfferRedemption,
		PaymentMethods.CompSlipRedemption,
	];
	SettleTransactionDetail: TransactionDetailAggregate = {
		playerTransactionDetails: [],
		teeTicketDetail: {} as TeeTicketDetail,
		rainCheckDetail: []
	};
	settleOpenTransaction: boolean = false;
	reOpenTransaction: boolean = false;
	depositOpentransaction: boolean = false;
	scheduleTeeTimeIds: number[] = [];
	playerIds: number[] = [];
	courseIds: number[] = [];
	appointmentIds: number[] = [];
	selectedDate: string = '';
	selectedCourse: number;
	tempSelectedProducts: SelectedProducts[] = [];
	clientMultipackTicket: Ticket;
	acesPaymentRecord: ACESPaymentRecord[] = null;
	selectedCMSPlayer = {
		PlayerName: "",
		PatronId: ""
	}
	Vouchers: Voucher[] = [];
	selectedVoucher: Voucher;
	cmsPlayerInfo: CMSPlayerInfo;
	GiftCardMethods: PaymentMethods[] = [
		PaymentMethods.V1GiftCard,
		PaymentMethods.V1GiftCardIdTech,
		PaymentMethods.ExternalGiftCard,
		PaymentMethods.ExternalGiftCardIdTech,
		PaymentMethods.AgilysysGiftCard,
		PaymentMethods.AgilysysGiftCardIdTech
	];
	V1GiftCardMethods: PaymentMethods[] = [
		PaymentMethods.V1GiftCard,
		PaymentMethods.V1GiftCardIdTech
	];
	get isGiftCardSelected() {
		return this.GiftCardMethods.includes(this.selectedPaymentMethod);
	}
	selectedBookingId: string | number;
	correcttransaction: boolean = false;
	oldTransactionId: number;
	IsRevenueItemIncluded: boolean = false;
	showReturnReason: boolean = true;
	cardPaymentMethods = [PaymentMethods.CreditCard, PaymentMethods.IDTECH];
	//memberCardnumber: number = 0;
	showAllPaymentMethods = false;
	currentARPostResponse: ARPostPaymentResponse = null;
	accountLookupComponentInput: LookUpInput;
	canEnableGuestEditOption = false;
	isApplyDiscountFromOrderSummaryGrid: boolean = false;
	MethodsWhichHasPaymentConfirmationPopup = [
		PaymentMethods.Cash,
		PaymentMethods.CardOnFile,
		PaymentMethods.V1GiftCard,
		PaymentMethods.V1GiftCardIdTech,
		PaymentMethods.ExternalGiftCard,
		PaymentMethods.ExternalGiftCardIdTech,
		PaymentMethods.ARPost,
		PaymentMethods.RedeemPoint,
		PaymentMethods.CreditBook,
		PaymentMethods.AgilysysGiftCard,
		PaymentMethods.AgilysysGiftCardIdTech
	];
	isAPICallInProgress: boolean = false
	manualEmailId: any[] = [];
	SelectedRetailItemDetails: any[] = [];
	get isCustomPaymentMethod() {
		return this.selectedpayment && this.selectedpayment.paymentTypeId > 0 && !(this.selectedPaymentMethod in PaymentMethods);
	}
	AgilysysGCMethods = [PaymentMethods.AgilysysGiftCard, PaymentMethods.AgilysysGiftCardIdTech];
	private get SkipRoomChargePosting() {

		const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));

		return (this.selectedpayment && this.selectedPaymentMethod == PaymentMethods.RoomCharge
			&& (this._featureFlagInfo?.PMSSystem?.toLowerCase() == GlobalConst.VISUALONE
				|| this._featureFlagInfo?.PMSSystem?.toLowerCase() == GlobalConst.VISUAL_ONE)
			|| propConfig?.PMSSystem?.toLocaleLowerCase().trim() == GlobalConst.V1.toLocaleLowerCase().trim()
			|| propConfig?.PMSSystem?.toLocaleLowerCase().trim() == GlobalConst.VERSA.toLocaleLowerCase().trim());

	}
	selectedGuestRoom: GuestRoomSearchResult;
	redeemAmount: number = 0;
	enforceMemberRedeemAmount: number = 0;
	hasMemberGuaranteedRateType: boolean = false;
	get IsTransactionSettled() {
		return (this.paymentText.trim().toLowerCase() == this.oCaptions.shop.CloseTransaction.trim().toLowerCase());
	}
	get IsOfferOrCompSlipPaymentMethod() {
		return (this.selectedPaymentMethod == PaymentMethods.OfferRedemption || this.selectedPaymentMethod == PaymentMethods.CompSlipRedemption);
	}
	get IsMemberPaymentSupportedProduct() {
		return (this._ss.ProductId == GlobalConst.Product.GOLF || this._ss.ProductId == GlobalConst.Product.SPA
			|| this._ss.ProductId == GlobalConst.Product.RETAIL || this._ss.ProductId == GlobalConst.Product.PMS)
	}
	get SurchargeEnabled() { return this._surchargeConfigService?.IsSurchargesEnabled }
	get RetailTicketNumber() {
		return this._ss.settleOpenTransaction ? this._ss.ticketNumber
			: this.CreateRetailItemResponse?.transactionData?.ticketNumber
	}
	amountEntered: string;
	IsGratuityOnNet = false;
	IsServiceChargeOnNet = false;
	transactionInProgressDialogRef: MatDialogRef<any, any>;
	isCreateTransactionValidationError = false;
	hostName: string = '';
	ipAddress: string = '';
	agentVersion: string = '';
	miscSetting: Promise<SystemConfiguration[]>;
	isIssuanceCompleted: boolean = false;
	noOfReceipts: number;
	timer = null;
	totalSurcharge = 0;
	floatLabel: string;
	isTicketCreated = false;
	selectedDataForEmailReceipt: any = null;
	public isSigCapLogicCompleted = new BehaviorSubject<[boolean, string]>([false, '']);
	public isProceedAutoCloseTransaction = new BehaviorSubject<boolean>(false);
	private orderSummaryLoader = new BehaviorSubject<boolean>(false);

	destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	thumbnailImageData: ImageData;
	folioMenubp: boolean = false;
	transactionDetailTherapistMap = new Map<number, string[]>();
	extendedProfileSearchConfig: boolean = false;
	isTaxExempted: boolean = false;

	get isEmailOnDemandProduct(): boolean {
		return this.currentProductId == this.ProductRetailId || this.currentProductId == this.ProductSpaId || this.currentProductId == this.ProductGolfId
	}

	@ViewChild(PaymentComponent) paymentComponent: PaymentComponent;
	constructor(
		private commonUtils: QuickLoginUtilities,
		private http: HttpServiceCall,
		public _ss: CommonVariablesService,
		public dialog: MatDialog,
		public utils: RetailUtilities,
		public localization: RetailLocalization,
		public router: Router,
		private BPoint: BreakPointAccess,
		public formbuilder: UntypedFormBuilder,
		public PropertyInfo: RetailPropertyInformation,
		public el: ElementRef,
		private transactionService: RetailTransactionService,
		public _sbs: ShopBussinessService,
		private applyDiscountService: ApplyDiscountService,
		public _ams: RetailService,
		private receiptService: ReceiptBusinessService,
		private payAgentService: PayAgentService,
		private userMachineConfigurationService: UserMachineConfigurationService,
		private spaConfig: SPAConfig,
		private retailValidationService: RetailValidationService,
		public _memberService: MemberBusinessService,
		private _cmsBusiness: CMSBusinessService,
		private _giftcardBusiness: GiftCardBusiness,
		private _featureFlagInfo: RetailFeatureFlagInformationService,
		private _arPostingBusiness: ARPostingBusinessService,
		public _retailService: RetailSharedVariableService,
		private _teetimePaymentBusiness: TeetimePaymentBusinessService,
		private _caddyShackBusinessService: CaddyShackBusinessService,
		private _propertyInfoService: RetailPropertyInformation,
		private _fastReportBusinessService: FastReportBusinessService,
		private _SaleChit: SaleChitBusinessService,
		private _propertySettingDataService: PropertySettingDataService,
		private transactionEngineBusiness: TransactionEngineBusiness,
		private retailFunctionalityBusiness: RetailFunctionalityBusiness,
		private _surchargeConfigService: SurchargeConfigurationService,
		public imgService: ImageProcessorService,
		private facadeService: FacadeService,
		private userAccessService: UserAccessService,
		public _folioBusiness: FolioBusiness,
		private DMbusiness: DatamagineConfigBusiness,
		injector: Injector,
		private party: PartyService
	) {
		super(utils, _retailService, _ams, _ss, router, injector);
		this.floatLabel = this.localization.setFloatLabel;
		this.exempttax = new UntypedFormControl(false);
		this._ams.isCancel = false; //Fix For No changes in client-info
		this.zipcodeFormat = GlobalConst.zipcodeformat;
		const tempPayment = _ss.isReturnWithoutTicket ? localization.captions.shop.ReturnPayment : localization.captions.shop.Pay;
		this.paymentText = PropertyInfo.UseRetailInterface
			? this.localization.captions.shop.SENDFORPAYMENT
			: tempPayment;
		this.selectedpayment = {
			id: 0,
			paymentTypeId: 0,
			parentTypeId: 0
		};
		if (this._ss.ProductId == GlobalConst.Product.SPA) {
			this.AddGuest = localization.captions.shop.AddClient;
		}
		else if (this._ss.ProductId == GlobalConst.Product.GOLF) {
			this.AddGuest = localization.captions.shop.AddPlayer;
		}
		else if (this._ss.ProductId == GlobalConst.Product.RETAIL) {
			this.AddGuest = localization.captions.shop.AddClient;
		}
		this.isVATEnabled = PropertyInfo.IsVATEnabled;
		this.CMSForm = this.formbuilder.group({
			cmsSearch: '',
			cmsPaymentSelection: '',
			voucherSelection: ''
		});
		this.canEnableGuestEditOption = (this._ss.ProductId == GlobalConst.Product.SPA || this._ss.ProductId == GlobalConst.Product.RETAIL || this._ss.ProductId == GlobalConst.Product.GOLF);

		this.isRetailInterfaceEnabled = this.PropertyInfo.UseRetailInterface;
		this._memberService.memberAPIProgressNotifier.subscribe((isAPICallInProgress) => {
			this.isAPICallInProgress = isAPICallInProgress;
		});
		this._giftcardBusiness.IssuanceProgressCompleted.pipe(takeUntil(this.$destroyed)).subscribe((issuanceStateObj) => {
			if (issuanceStateObj) setTimeout(() => {
				this.TriggerGiftcardTopup(issuanceStateObj.transactionId, issuanceStateObj.retailTicketNumber);
			}, 1000); // 1 sec delay to show the giftcard issuance success popup
		});
		this._giftcardBusiness.GiftcardActionsCompleted.pipe(takeUntil(this.$destroyed)).subscribe((issuanceStateObj) => {
			if (issuanceStateObj)
				this.printReceipt(issuanceStateObj.createItemRes);
			if (this._ss.allowAutoCloseTransaction && !this._ss.isOperationFromActivities && !this._ss.selectedProducts.some(x => x.isGiftCardItem)) { // Activities - retail integration changes
				this.PerformCloseTransaction();
			}
		});
		this.showPlatformGuestSearch = this.localization.IsPlatformGuestSearchConfigured();
	}

	public get displayCreditBookBalance(): boolean {
		return (this._propertyInfoService.getMemberConfig && this._propertyInfoService.getMemberConfig.displayCreditBookBalance || "").toUpperCase() == "TRUE";
	}
	formInitialized(arg) {
		this.transitionDetails = arg;
		this.transitionDetails.controls.amounttendered.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(val => {
			this.amountEntered = val;
		});
	}

	clientTypes = [
		// #46309 - Hide unused fields
		{ id: 1, type: this.localization.captions.shop.Member },
		{ id: 2, type: this.localization.captions.shop.Guest }
	];

	clientTypesChange(e) {
		if (e.value == 2) {
			this.SetDefaultExtendedSearch();
		}
	}

	receiveMessage($event) {
		this.makeSearchCall($event.searchValue, $event.requestUid);
	}

	async makeSearchCall(name: string, requestUid: string) {
		let guestInfo;
		if (this.currentProductId == this.ProductSNCId) {
			guestInfo = await RetailDataAwaiters.GetCateringBooking(name, { reservationStatus: GlobalConst.ReservationStatus.Active, includeInactive: false });
			this._ams.clientsearchArray = guestInfo;
		}
		else {
			if (this._ams.clientSelect == 1) {
				guestInfo = await this._memberService.searchGuest(name, this._ams.clientSelect);

			} else {
				guestInfo = await RetailDataAwaiters.searchPayee(name, this._ams.clientSelect, requestUid, this.isPlatformGuestSearch);
			}
			this._ams.clientsearchArray = this.receiptService.clientDetails = guestInfo[1];
			this._ams.guestInfo = guestInfo[0];
		}
	}

	async GetPayeeInfo(payeeId: number) {
		this.party.retailClientSearch = this._ams.clientSelect;
		if (this._ams.memberRemove == true) {
			payeeId = 0;
			this._ams.memberRemove = false;
		}
		if (!payeeId) {
			return;
		}
		this.orderSummaryLoader.next(true);
		this.payeeInfo = await RetailDataAwaiters.getPayeeInfo(payeeId);
		this.orderSummaryLoader.next(false);
		this.FetchActiveFolioInvoiceNumberForPayee();
		this.clientInfo = this.payeeInfo;
		if (this.payeeInfo) {
			this.selectedClientId = this.payeeInfo.id;
			this.selectedClientGuid = this.payeeInfo.guestProfileId;
			if (this.payeeInfo.emailId != undefined && this._retailService.manualEmailId.filter(x => x == this.payeeInfo.emailId).length == 0)
				this._retailService.manualEmailId.push(this.payeeInfo.emailId);
			this.CheckForCardOnFile(this.payeeInfo);
			if (this.ProductSNCId == this._ss.ProductId) {
				this.payeeInfo.id = this.payeeInfo.bookingId ? Number(this.payeeInfo.bookingId) : this.payeeInfo.id;
			}
			this._ams.labelRecords = [
				{
					Id: this.payeeInfo.id,
					Name: this.payeeInfo.name
				}
			];
			if (this._ams.clientSelect != 1 && this.payeeInfo.guestProfileId && this.payeeInfo.guestProfileId != GlobalConst.DefaultGUID && this._ams.clientSelect != 1) {
				this.GetAllRoomsForGuest(this.payeeInfo.guestProfileId);
			}
			if (this._ams.clientSelect == 1 && this.payeeInfo && this.payeeInfo.guestProfileId && this.IsMemberPaymentSupportedProduct && (this.payeeInfo?.guesttype == GlobalConst.ClientType.Member || this.payeeInfo?.playerCategoryId == PlayerCategory.Member)) {
				const corpId = this._ss.sourceCorpId ? this._ss.sourceCorpId : 0;
				const _memberInfo: PayeeInfo = await this._memberService.getMemberInfo(
					this.payeeInfo.guestProfileId,
					this._memberService.getScheduleDateInUTC(this._ss.SelectedPlayers), false, true, false, corpId
				);
				const memberConfig = this._propertyInfoService.getMemberConfig;
				const corpIdMap = memberConfig && memberConfig.corpIdNameMap ? JSON.parse(memberConfig.corpIdNameMap) : "";
				const corpName = corpIdMap[corpId] != null ? corpIdMap[corpId] : "";
				this._ss.isMemberNotActive = Boolean(_memberInfo && _memberInfo.membershipStatus && _memberInfo.membershipStatus.toUpperCase() != "ACTIVE");
				if (_memberInfo) {
					_memberInfo.id = this.payeeInfo.id;
					let guesttype = this.payeeInfo.guesttype;
					let guestId = this.payeeInfo.guestId;
					this.payeeInfo = _memberInfo;
					this.payeeInfo.guesttype = guesttype;
					this.payeeInfo.guestId = guestId;
					this.payeeInfo.corpName = corpName;
					this.FetchActiveFolioInvoiceNumberForPayee();
					this.memberImageURL = _memberInfo.photoUrl;

				}
			}
			if (this._ss.ProductId == GlobalConst.Product.GOLF || this._ss.ProductId == GlobalConst.Product.SPA || this._ss.ProductId == GlobalConst.Product.RETAIL) {
				if (this.payeeInfo.guestProfileId != null && this.payeeInfo.guestProfileId != "" && (this.payeeInfo.photoUrl == null || this.payeeInfo.photoUrl == "")) {
					await this.imgService.GetImagesByGuid(this.payeeInfo.guestProfileId, this.successCallback.bind(this), this.errorCallback.bind(this), true, []);
				}
			}
		}
	}

	async taxExemptChange() {
		if (!this._ss.hasUserAccessToExemptTax) {
			this._ss.showBPMessage(GlobalConst.RetailBreakPoint.TaxExempt);
			this.exempttax.setValue(!this.exempttax.value);
		} else {
			this.exempttax.setValue(this.exempttax.value);
			if (this._ss.settleOpenTransaction && !this.isTaxRestored && !this.exempttax.value) {
				await this.RestoreTaxOnSettle();
			}
		}
		this.isTaxExempted = this.exempttax.value;
		this.shouldTaxRecalculate = this.isTaxExempted;
		this.UpdateTaxAndTenderMaxLimit();
	}

	async RestoreTaxOnSettle() {
		this.isTaxRestored = true;
		this._ams.loaderEnable.next(this.ShopCaptions.AddTax);
		if (this._ss.Ticket) {
			if (this.PropertyInfo.UseRetailInterface) {
				this.taxValue = this._ss.Ticket.checkData.totalTax;
			}
			else {
				this.taxValue = this._ss.Ticket.checkData.totalItemTax;
				this.gratuityTax = this._ss.Ticket.checkData.totalGratuityTax;
				this.serviceChargeTax = this._ss.Ticket.checkData.totalServiceChargeTax;
			}

			this.UpdateTaxAndTenderMaxLimit();
			setTimeout(() => {
				this._ams.loaderEnable.next('');
			}, 1000);
		}
	}

	ValidateCheck(elem?: any) {
		if (
			this._ss.cancelFlag ||
			this._ss.depositFlag ||
			(elem && elem.isModificationRestricted) ||
			(elem && elem.isGiftCardItem) ||
			this._ss.settleOpenTransaction
		) {
			return true;
		} else {
			return false;
		}
  }

  //Activities - Retail integration
	async openDialog(e, id?: any, guestId?: any,  isEdit? : boolean , platformGuestUuid?: any) {
		await RetailDataAwaiters.openAddPayeePopup(e, this.afterAddPayee.bind(this), id, guestId, this.modifyClientNameMappedAgainstLineItem.bind(this), isEdit , platformGuestUuid);
	}
	async AddPlayer(popUpTitle, data?) {
		let guestData: any;
		if (data)
			guestData = await RetailDataAwaiters.GenerateGuestData(data.playerLinkId ? data.playerLinkId : data.guestProfileId);
		await RetailDataAwaiters.openAddPlayerPopup(this.afterAddPlayer.bind(this), popUpTitle, guestData);
	}

	async afterAddPlayer(player) {
		this._ss.selectedPayeeId = player.id;
		this.payeeInfo = player;
		this.payeeInfo.name = player.name;
		this.FetchActiveFolioInvoiceNumberForPayee();
		this.CheckForCardOnFile(this.payeeInfo);
		this.selectedClientId = this.payeeInfo.id;
		this.selectedClientGuid = this.payeeInfo.guestProfileId;
		this._ams.labelRecords = [
			{
				Id: player.id,
				Name: player.name
			}
		];
		this.totalPrice();
		this.beforeClinet = false;
		if (this._ss.ProductId == GlobalConst.Product.GOLF || this._ss.ProductId == GlobalConst.Product.SPA || this._ss.ProductId == GlobalConst.Product.RETAIL) {
			if (this.payeeInfo.guestProfileId != null && this.payeeInfo.guestProfileId != "" && (this.payeeInfo.photoUrl == null || this.payeeInfo.photoUrl == "")) {
				await this.imgService.GetImagesByGuid(this.payeeInfo.guestProfileId, this.successCallback.bind(this), this.errorCallback.bind(this), true, []);
			}
		}
	}

	editClient = (id, guestId, platformGuestUuid?) => {
		if (this._ss.ProductId == GlobalConst.Product.GOLF)
			this.AddPlayer('EDIT PLAYER', this.payeeInfo);
		else
		this.openDialog('ordersummaryedit', id, guestId, true , platformGuestUuid); // Activities - retail integration
	};

	afterAddPayee(payeeInfo: PayeeInfo) {
		if (payeeInfo) {
			this.CheckForCardOnFile(payeeInfo);
			this.totalPrice();
			// To update name in search box
			this._ams.labelRecords = [
				{
					Id: payeeInfo.id,
					Name: payeeInfo.name
				}
			];
			this.beforeClinet = false;
			this.payeeInfo = null;
			this.beforeClinet = false;
			this.payeeInfo = payeeInfo;
			this.FetchActiveFolioInvoiceNumberForPayee();
			this._sbs.PaymentBeforeClinet = false;
			this.selectedClientId = this._ss.selectedPayeeId = payeeInfo.id;
			this.selectedClientGuid = payeeInfo.guestProfileId;
			this.selectedBookingId = payeeInfo.id;
			this.clientInfo = payeeInfo;
		}
	}

	modifyClientNameMappedAgainstLineItem(payeeInfo: PayeeInfo) {
		if (payeeInfo) {
			this._ss.selectedProducts.forEach(x => {
				if (x && payeeInfo && x.clientId && payeeInfo.id && x.clientId === payeeInfo.id) {
					x.clientName = `${payeeInfo.name}`;
				}
			});
			this.FormOrderSummary();
		}
	}

	ValidateTaxExemption() {
		if (this._ss.settleOpenTransaction) {
			return true;
		} else {
			return false;
		}
	}

	deleteCompleteSearch() {
		this.removeFromSearchBar();
	}

	async removeFromSearchBar() {
		if (this._ss.memberCardNumber != "0" && this.IsMemberPaymentSupportedProduct) {
			this._ams.memberRemove = true;
			let extraRetailItems = this._ss.selectedProducts.filter(
				(x) => x.payeeId == undefined && x.playerName == undefined
			);
			if (extraRetailItems.length > 0) {
				const memberConfig = this._propertyInfoService.getMemberConfig;
				const applyMemberDiscountAutomatically = memberConfig?.applyMemberDiscountAutomatically?.toLowerCase() === 'true' ?? false;
				this._ss.selectedProducts.forEach((product) => {
					if (product.payeeId == undefined && product.playerName == undefined) {
						let currentItem = this.SelectedRetailItemDetails.find((x) => x.id == product.ItemId);
						let curSalesPrice = product.MultiPack ? product.DisplayPrice : currentItem.retailItemDetail.salesPrice;
						if (product.GroupingParentId > 0) {
							curSalesPrice = this.GroupedItemPriceHander(product, curSalesPrice, false);
						}
						const tempPrice = currentItem.retailItemDetail.isOpenItem ? product.ProductPrice :
							curSalesPrice;
						product.ProductPrice = currentItem.retailItemDetail.isScaledItem ?
							this.localization.currencyToSQLFormat(String(product.scaledUnits)) * curSalesPrice :
							tempPrice;
						product.ProductPrice = product.isReturn ? product.ProductPrice * -1 : product.ProductPrice;
						if (applyMemberDiscountAutomatically) {
							if (product.isAutoMemDiscApplied && !product.isAutoMemDiscRemoved) {
								product.DiscountPercentage = 0;
								product.DiscountTypeId = 0;
								product.discountReason = 0
								product.discountComments = "";
								product.Discount = 0;
							}
						}
						product.Discount = product.DiscountPercentage > 0
							? this.utils.RoundOff2DecimalPlaces((product.Noofitems * product.ProductPrice) * product.DiscountPercentage / 100)
							: product.Discount;
					}
				});
				if (this._ss.Ticket != null) {
					if (this.PropertyInfo.UseRetailInterface) {
						await this._ss.InvokeDiscardCheck();
					}
					await this.CreateTicket();
					this.FormOrderSummary();
				}
			}
		}
		this.thumbnailImageData = null;
		this._retailService.manualEmailId = this._retailService.manualEmailId.filter(x => x != this.payeeInfo.emailId);
		this.payeeInfo = null;
		this.FetchActiveFolioInvoiceNumberForPayee();
		this.clientInfo = this.payeeInfo;
		this.roomDetailsArray = [];
		this._ss.selectedPayeeId = 0;
		this._ss.isMemberNotActive = false;
		this.beforeClinet = true;
		this._sbs.PaymentBeforeClinet = true;
		this.selectedClientId = 0;
		this.selectedClientGuid = null;
		this.selectedBookingId = "0";
		this.paymentMethodsClone = this.paymentMethodsClone
			? this.paymentMethodsClone.filter((method) => this.utils.GetOriginalTenderId(method.paymentTypeId, method.parentTypeId) != PaymentMethods.CardOnFile)
			: [];
		this.paymentMethods = this.paymentMethods
			? this.paymentMethods.filter((method) => this.utils.GetOriginalTenderId(method.paymentTypeId, method.parentTypeId) != PaymentMethods.CardOnFile)
			: [];
		this._ss.memberCardNumber = "0";
		this._ss.sourceCorpId = 0;
		this._ss.memberDiscountType = "";
		this._ams.labelRecords = [];
		this.memberImageURL = "";
	}

	async selectPayeeFromSearch(payeeInfo: PayeeInfo) {
		this._ss.memberCardNumber = "0";
		this._ss.sourceCorpId = 0;
		this.party.retailClientSearch  = this._ams.clientSelect
		if (payeeInfo.emailId != undefined && payeeInfo.emailId != "" && this._retailService.manualEmailId.filter(x => x == payeeInfo.emailId).length == 0) {
			if (this._retailService.manualEmailId.length < 3)
				this._retailService.manualEmailId.push(payeeInfo.emailId);
			else
				this._retailService.manualEmailId[2] = payeeInfo.emailId;
		}
		if (this._ams.clientSelect == 1 && payeeInfo.guestProfileId && this.IsMemberPaymentSupportedProduct) {
			this.utils.ToggleLoader(true, this.ShopCaptions.MemberSelectionPopup.fetchingSingleMemberDetailsMsg);
			let _memberInfo : PayeeInfo;
			try {
				_memberInfo = await this._memberService.getMemberInfo(
					payeeInfo.guestProfileId,
					this._memberService.getScheduleDateInUTC(this._ss.selectedProducts), false, true, false, payeeInfo.corpId
				);
			}
			catch (ex) {
				console.log(ex);
			}
			this.utils.ToggleLoader(false);
			if (_memberInfo == null) {
				this._ams.labelRecords = [];
				this._ams.recordsArray = [];
				this._ams.multiClientInfo = [];
				this.removeFromSearchBar();
				this._ss.memberCardNumber = "0";
				this._ss.sourceCorpId = 0;
				return;
			} else {
				const extraRetailItems = this._ss.selectedProducts.filter(
					(x) => x.payeeId == undefined && x.playerName == undefined
				);
				_memberInfo.id = payeeInfo.id;
				let guesttype = payeeInfo.guesttype;
				let guestId = payeeInfo.guestId;
				this.payeeInfo = _memberInfo;
				this.payeeInfo.guesttype = guesttype;
				this.payeeInfo.guestId = guestId;
				this.payeeInfo.corpName = payeeInfo.corpName;
				this.FetchActiveFolioInvoiceNumberForPayee();
				this.memberImageURL = this.payeeInfo.photoUrl;
				this._ss.sourceCorpId = payeeInfo.corpId;
				payeeInfo = _memberInfo;
				this._ss.memberCardNumber = payeeInfo.guestProfileId;
				this._ss.memberDiscountType =  _memberInfo.discountType;
				this._memberService.setAvalableRounds(_memberInfo);
				if (extraRetailItems.length > 0) {
					const memberConfig = this._propertyInfoService.getMemberConfig;
					const applyMemberDiscountAutomatically = memberConfig?.applyMemberDiscountAutomatically?.toLowerCase() === 'true' ?? false;
					const overrideMemberDiscount = memberConfig?.overrideWithMemberDiscounts?.toLowerCase() === 'true' ?? false;
					let activeDiscountReasons = applyMemberDiscountAutomatically ? await this.applyDiscountService.getActiveDiscountReasons() : null;
					let miscSettings = await this.miscSetting;
					let nonEligibleForDiscount = [];
					this._ss.selectedProducts.forEach((product, idx) => {
						if (product.payeeId == undefined && product.playerName == undefined) {
							const currentItem = this.SelectedRetailItemDetails.find((x) => x.id == product.ItemId);
							let curMemberPrice = product.MultiPack ? product.MemberPrice : currentItem.retailItemDetail.memberPrice;
							if (product.GroupingParentId > 0) {
								curMemberPrice = this.GroupedItemPriceHander(product, curMemberPrice);
							}
							const tempMemberPrice = currentItem.retailItemDetail.isOpenItem ? product.ProductPrice :
								curMemberPrice;
							product.ProductPrice = currentItem.retailItemDetail.isScaledItem ?
								this.localization.currencyToSQLFormat(String(product.scaledUnits)) * curMemberPrice :
								tempMemberPrice;
							product.ProductPrice = product.isReturn ? Math.abs(product.ProductPrice) * -1 : product.ProductPrice;
					
							//Applying discount based on the discount type in Engage						
							if (applyMemberDiscountAutomatically && (overrideMemberDiscount || product.Discount == 0)) {
								let nonEligible = this._ss.ApplyMemberDiscountAutomatically(product, miscSettings, activeDiscountReasons, this.applyDiscountService.AllCategoryDiscountList, this.applyDiscountService.DiscountTypes, this._ss.memberDiscountType);
								if (nonEligible)
									nonEligibleForDiscount.push(nonEligible);
							}

							product.Discount = product.DiscountPercentage > 0
								? this.utils.RoundOff2DecimalPlaces((product.Noofitems * product.ProductPrice) * product.DiscountPercentage / 100)
								: product.Discount;
						}
					});
					if (nonEligibleForDiscount.length > 0) {
						this.utils.ShowErrorMessage(
							this.localization.captions.common.Error,
							this.localization.replacePlaceholders(
								this.ShopCaptions.DiscountNotEligibleForSomeItem,
								['ItemCount'],
								[nonEligibleForDiscount.length]
							)
						);
					}
					if (this._ss.Ticket != null) {
						if (this.PropertyInfo.UseRetailInterface) {
							await this._ss.InvokeDiscardCheck();
						}
						this.CreateTicket();
						this.FormOrderSummary();
					}

				}
			}
			this._ss.isMemberNotActive = Boolean(payeeInfo.membershipStatus && payeeInfo.membershipStatus.toUpperCase() != "ACTIVE");
		}
		this.payeeInfo = null;
		this.FetchActiveFolioInvoiceNumberForPayee();
		this.beforeClinet = false;
		this._sbs.PaymentBeforeClinet = false;
		this.selectedClientId = this._ss.selectedPayeeId = payeeInfo.id;
		this.selectedClientGuid = payeeInfo.guestProfileId;
		this.selectedBookingId = payeeInfo.id;
		if (!this.PropertyInfo.UseRetailInterface && this._ams.clientSelect != 1 && payeeInfo.guestProfileId && payeeInfo.guestProfileId != GlobalConst.DefaultGUID) {
			this.GetAllRoomsForGuest(payeeInfo.guestProfileId);
		}
		if (this._ss.ProductId == this.ProductSNCId && this.selectedBookingId != null && this.selectedBookingId) {
			if (this._ss.selectedProducts.every((x) => x.ItemType == 6)) {
				this._ss.depositFlag = true;
				const depositAll: DepositEventModel[] = [];
				this._ss.selectedProducts.forEach((product) => {
					depositAll.push({
						typeId: Number(this.selectedBookingId),
						amount: product.ProductPrice * product.Noofitems,
						depositTransactionId: 0
					} as DepositEventModel);
				});
				this.depositArray = depositAll;
				this._ss.RedirectModule = RedirectToModules.retail;
			}
		}
		this.payeeInfo = payeeInfo;
		this.FetchActiveFolioInvoiceNumberForPayee();
		this.clientInfo = payeeInfo;
		this.CheckForCardOnFile(this.payeeInfo);
		if (this._ss.ProductId == GlobalConst.Product.GOLF || this._ss.ProductId == GlobalConst.Product.SPA || this._ss.ProductId == GlobalConst.Product.RETAIL) {
			if (payeeInfo.guestProfileId != null && payeeInfo.guestProfileId != "" && (this.payeeInfo.photoUrl == null || this.payeeInfo.photoUrl == "")) {
				await this.imgService.GetImagesByGuid(payeeInfo.guestProfileId, this.successCallback.bind(this), this.errorCallback.bind(this), true, []);
			}
		}
		if (this._ss.ProductId == GlobalConst.Product.RETAIL || this._ss.ProductId == GlobalConst.Product.GOLF) {
			if (this._ams.clientSelect == 1) {
				this.canEnableGuestEditOption = false;
			} else {
				this.canEnableGuestEditOption = true;
				this.CheckEmailSMSConfigured(payeeInfo);
			}
		}
	}

	CheckEmailSMSConfigured(payeeInfo: PayeeInfo) {
		if (this._ss.isAutoTriggerNotificationEnabled && (!payeeInfo.emailId || !payeeInfo.phoneNumber)) {
			const extraParams: any = { payeeInfo: payeeInfo };
			const clientName = payeeInfo.name;
			this.utils.ShowErrorMessage(this.localization.captions.alertPopup.info,
				this.localization.replacePlaceholders(this.localization.captions.shop.emailConfirmation, ["Client"], [clientName]),
				GlobalConst.ButtonType.YesNo, this.EamilSmsPopupCallback.bind(this), extraParams);
		}
	}

	EamilSmsPopupCallback(result, extraParams) {
		const payeeInfo: PayeeInfo = extraParams["payeeInfo"];
		if (result.toLowerCase() === GlobalConst.ButtonOptions.No) {
			this.editClient(payeeInfo.id, payeeInfo.guestProfileId);
		}
	}

	GroupedItemPriceHander(product: SelectedProducts, curPrice, isMember = true) {
		const groupedItemDetail = this.SelectedRetailItemDetails.find((x, index) => x.id == product.GroupingParentId);
		if (groupedItemDetail && groupedItemDetail.packagedItem && groupedItemDetail.packagedItem.length) {
			var packageItemDetail = groupedItemDetail.packagedItem.find(x => x.parentItemId == product.ItemId);
			if (packageItemDetail) {
				return isMember ? packageItemDetail.memberPrice : packageItemDetail.price;
			}
		}
		return curPrice;
	}

	CheckForCardOnFile(payeeDetail: PayeeInfo) {
		if (!payeeDetail) { return }
		this.CardOnFileforSelectedGuest = payeeDetail.cardInfo ? payeeDetail.cardInfo.find((x) => x.isActive) : null; //Filter active cards and get the first card
		const CardOnFile: PaymentMethod = {
			id: PaymentMethods.CardOnFile,
			paymentTypeId: PaymentMethods.CardOnFile,
			paymentMethod: this.localization.captions.shop.paymentMethods[PaymentMethods.CardOnFile],
			type: 'Card',
			isActive: true,
			allowEarn: false
		};
		if (this.CardOnFileforSelectedGuest && this.CardOnFileforSelectedGuest.tokenTransId > 0) {
			// Card on file exists then Add payment method
			const AddCOF = (collection: PaymentMethod[]) => {
				const creditCard = collection.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.CreditCard);
				const isCardPaymentAllowed = creditCard && creditCard.isActive;
				if (!collection.some((r) => this.utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId) == PaymentMethods.CardOnFile) && isCardPaymentAllowed) {
					collection.push(CardOnFile);
				}
			}
			AddCOF(this.paymentMethodsClone);
			AddCOF(this.paymentMethods);
		} else {
			// User may have selected a guest without having card on file
			// Check if CardOnFile included in payment method, if yes remove it
			const RemoveCOF = (collection) => {
				let filter = collection.filter((method) => this.utils.GetOriginalTenderId(method.paymentTypeId, method.parentTypeId) == PaymentMethods.CardOnFile);
				if (filter.length > 0) {
					collection.splice(collection.indexOf(filter[0]), 1);
				}
			}
			RemoveCOF(this.paymentMethodsClone);
			RemoveCOF(this.paymentMethods);
		}
	}

	async GetAllRoomsForGuest(guestId: string) {
		this.roomDetailsArray = await this._ss.GetAllRoomsForGuest(guestId);
	}

	/**
   * @function appoinmentCheck()
   * @param boolean
   * @description Test Function If Appoinment View
   */
	appoinmentCheck() {
		this.beforeClinet = false;
		this._sbs.PaymentBeforeClinet = false;
	}

	/**
   * PaymentOption changes the Payment div after the Click Function
   * InputParams@ event
   * OutPut Result: Boolean
   */
	PaymentOption(e: any) {
		this.paymentMethods = _.cloneDeep(this.paymentMethodsClone);
		this.transitionDetails.controls.selectedDevice.patchValue('');
		this._sbs.AutoScrollToBottom();
		if (e.id === 2) {
			this.multiplePayment = true;
			this.transitionDetails.controls.amounttendered.enable();
			this.FullPayment = false;
			this.ShowTenderField = true;
			this.paymentMethods = this.paymentMethods.filter(
				(r) => this.utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId) != PaymentMethods.PendingSettlement
			);
			this.UpdateTaxAndTenderMaxLimit();
			if (this.IsOfferOrCompSlipPaymentMethod) {
				this.transitionDetails.controls.amounttendered.disable();
			}
			if (this.selectedPaymentMethod == PaymentMethods.RedeemPoint) {
				this.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(this.redeemAmount));
				this.transitionDetails.controls.amounttendered.disable();
				this.EnableCloseTranBtn = true;
			}
			if (this.selectedPaymentMethod == PaymentMethods.ARPost && this._ss.memberCardNumber != "0" && this._ss.SelectedPlayers && this._ss.SelectedPlayers.length && this.remainingAmount >= this.enforceMemberRedeemAmount && this.SettlementHistory.filter((x) => x.paymentMethodId == PaymentMethods.ARPost && !x.isReversed).length == 0) {
				this.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(this.enforceMemberRedeemAmount));
				this.transitionDetails.controls.amounttendered.disable();
				this.EnableCloseTranBtn = true;
			}

		} else {
			this.multiplePayment = false;
			this.FullPayment = true;
		}
		if (this._ss.settleOpenTransaction && this.SettlementHistory.length > 0) {
			this.multiplePayment = true;
		}
		this.AdjustPaymentMethods();

	}

	/**
   * Over All Selected Function
   * InputParams@ event
   * OutPut Result: Boolean
   */
	ListChecked(e) {
		if (e.checked) {
			if(this.party.Operations == 1){
				this.checkedOverall = this.checkedOverall = this.ListCheckedActivities(this.GridItems); // Activities - retail integration changes	
				this.checkedOverall = this.checkedOverall = this.GridItems.filter(items => !this._retailService.SelecedSeatProducts.some(seatItems => items.lineNumber === seatItems.LineNumber)).every((x) => x.ischecked = true); // Activities - retail integration changes
				//	}
					//this.checkAll = d.;
			}
			else{
				this.checkedOverall = this.ListCheckedActivities(this.GridItems); // Activities - retail integration changes	
			}
		} else {
			this.checkedOverall = this.GridItems.map((x) => (x.ischecked = false));
		}
		this.checkforApplyDiscountEnable();
		if (this.enableApply) {
			this.enableApply = this.IsRevenueItemIncluded ?
				!(this.GridItems.filter((r) => r.ischecked).map((r) => r.element).some((x) => x.ItemType == RetailItemType.RevenueItem || x.ItemType == RetailItemType.Deposit)) : this.enableApply;
		}
		this.disableRemove = this.GridItems.some((x) => x.ischecked && x.isModificationRestricted) || this.GridItems.every(x => x.isGiftCardItem) ||
			(this.IsRevenueItemIncluded && this.GridItems.map((r) => r.element).every((x) => x.ItemType == RetailItemType.RevenueItem || x.ItemType == RetailItemType.Deposit));
		this.isApplyDiscountFromOrderSummaryGrid = true;
	}

	checkforApplyDiscountEnable() {
		this.enableApply =
			this.GridItems.some((x) => x.ischecked) &&
			this.GridItems.filter((r) => r.ischecked).map((r) => r.element).some((x) => !(x.isGiftCardItem && this._ss.isReturnWithoutTicket));
	}

	/**
   * Removed or Add Data From tha List Array
   * InputParams@ Checked Id
   * OutPut Result: ReturnJson
   */
	ListrowChecked = (e, i, d) => {
		d= d.filter(items => !this._retailService.SelecedSeatProducts.some(seatItems => items.lineNumber === seatItems.LineNumber)); // Activities - retail integration changes
		this.checkAll = d.every((x) => x.ischecked);
		this.checkforApplyDiscountEnable();
		if (this.enableApply) {
			this.enableApply = this.IsRevenueItemIncluded ?
				!(this.GridItems.filter((r) => r.ischecked).map((r) => r.element).some((x) => x.ItemType == RetailItemType.RevenueItem || x.ItemType == RetailItemType.Deposit)) : this.enableApply;
		}

		this.disableRemove = this.GridItems.some((x) => x.ischecked && x.isModificationRestricted) || this.GridItems.every(x => x.isGiftCardItem) ||
			(this.IsRevenueItemIncluded && this.GridItems.map((r) => r.element).every((x) => x.ItemType == RetailItemType.RevenueItem || x.ItemType == RetailItemType.Deposit));
		const selectedDiscount: ItemDiscount = {
			itemId: i.element.ItemId,
			discountId: i.element.DiscountTypeId,
			discountPercentage: i.element.DiscountPercentage,
			discountAmount: i.element.DiscountPercentage > 0 ? 0 : i.element.Discount,
			discountComment: i.element.discountComments,
			discountReason: i.element.discountReason
		};
		this.applyDiscountInput = {
			index: i.element.ItemId,
			CategoryId: i.element.category,
			SelectedDiscount: selectedDiscount,
			isMultipleItems: this.GridItems.filter((x) => x.ischecked).length > 1,
			showItemDetails: false
		};
		this.isApplyDiscountFromOrderSummaryGrid = true;
	};

	/*** Remove shop items From List Array ***/
	async RemovedData() {
		const checkAll = this.GridItems.every((x) => x.ischecked);
		const indexToRemove: number[] = [];
		const removedItems = this.GridItems.filter((r) => r.ischecked).map((x) => x.element);

		if (this._ss.correctTransaction && this._ss.voidedTransactionId > 0 && removedItems.length > 0) {
			const spaRelatedItem = this.GridItems.some(
				(r) => r.ischecked && this.transactionService.IsSPAAppointmentRelatedRetailItem(r)
			);
			if (spaRelatedItem) {
				this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(10717));
				return;
			}
		}

		if (checkAll) {
			removedItems.filter((r) => {
				indexToRemove.push(this._ss.GetOrderSummaryItemIndex(r, this.GridItems.map((x) => x.element)));
			});
			this.GridItems = [];
			this.EditItems('removeall', 0, '');
			this._ss.SelectedItemDiscount = [];
		} else {
			const elementsToRemove = [];
			removedItems.filter((r) => {
				indexToRemove.push(this._ss.GetOrderSummaryItemIndex(r, this.GridItems.map((x) => x.element)));
				let seletedItemIdx = this._ss.SelectedItemDiscount.findIndex(x => x.itemId == r.ItemId);
				if (seletedItemIdx > -1) {
					this._ss.SelectedItemDiscount.splice(seletedItemIdx, 1);
				}
			});
			this.GridItems = this.GridItems.filter((x, i) => {
				if (x.ischecked) {
					elementsToRemove.push(i);
				}
				return !x.ischecked;
			});

			//Removing element from selected products
			if (elementsToRemove.length > 0) {
				elementsToRemove.sort(function (a, b) {
					return b - a;
				});
				for (const elementsToRemoveItem of elementsToRemove) {
					this._ss.selectedProducts.splice(elementsToRemoveItem, 1);
				}
			}
			this.showRainCheckIG();
		}
		if (indexToRemove.length > 0) {
			if (this.PropertyInfo.UseRetailInterface) {
				this.InvokeRemoveItems(indexToRemove);
			}
			else {
				this._ss.selectedProducts.forEach((itm, index) => {
					itm.LineNumber = index + 1;
				});

				this.InvokeUpdateTicket();
			}
		}
		this.checkAllPackagedItemRemoved();
		await this.checkAllRainCheckItemRemoved();
		await this.totalPrice();
		this.checkforApplyDiscountEnable();
		const ItemIds = Array.from(new Set(this._ss.selectedProducts.map(x => x.ItemId)));
		this.SelectedRetailItemDetails = this.SelectedRetailItemDetails.filter(t => ItemIds.includes(t.id));
		this._ss.hasMultiPackItem = this.SelectedRetailItemDetails.filter(t => t.retailItemDetail.isMultiPack).length > 0;
		this._ss.isReturnWithoutTicket = this._ss.selectedProducts && this._ss.selectedProducts.some((x) => x.isReturn);
		this.showReturnReason = (this._ss.selectedProducts && this._ss.selectedProducts.every((x) => !x.rainCheckItemId));
	}

	/*** Remove packaged item parent if all child items are deleted*/
	checkAllPackagedItemRemoved() {
		try {
			const groupedItems = this.GridItems.filter((x) => x.element.isGroupingKey);
			for (const gItem of groupedItems) {
				const element: SelectedProducts = gItem.element;
				// check if all the child items are deleted
				if (
					!this.GridItems.some(
						(x) =>
							x.element.isPackagedItem &&
							x.element.PackageGroupId == element.PackageGroupId &&
							x.element.PackageItemId == element.PackageItemId
					)
				) {
					const removeIndex = this.GridItems.indexOf(gItem);
					// remove from griditem
					this.GridItems.splice(removeIndex, 1);
					// remove from selected products
					this._ss.selectedProducts.splice(removeIndex, 1);
				}
			}
		} catch (e) { console.log(e) }
	}

	/* *PriceFunction * */
	AdjustPaymentMethods() {
		let payMethodsToRemove: PaymentMethods[] = [];
		if (this.IsRefund) {
			payMethodsToRemove = [PaymentMethods.PendingSettlement, PaymentMethods.RainCheck, PaymentMethods.CompRedemption, PaymentMethods.OfferRedemption, PaymentMethods.CompSlipRedemption];
			this.paymentMethods = this.paymentMethods.filter((r) => !payMethodsToRemove.includes(this.utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId)));
			if (payMethodsToRemove.some(x => x == this.selectedPaymentMethod)) { //Bug - 34916 Fix
				this.ResetSelectedPayment();
			}
		} else {
			this.paymentMethods = _.cloneDeep(this.paymentMethodsClone);
			if (this.multiplePayment) {
				this.paymentMethods = this.paymentMethods.filter(
					(r) => this.utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId) != PaymentMethods.PendingSettlement
				);
			}
		}

		if (!this._ss.isFromEditTeeTime && !this._ss.isFromUnpaidPlayer && (!this._ss.settleOpenTransaction
			&& this.SettleTransactionDetail.playerTransactionDetails.length == 0)) {
			payMethodsToRemove = [PaymentMethods.RainCheck];
			this.paymentMethods = this.paymentMethods.filter((r) => !payMethodsToRemove.includes(this.utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId)));
		}

	}
	// Need to check whether total is calculated from checkData
	async totalPrice() {
		this.totalAmount = 0;
		this.totalGratuity = 0;
		this.serviceVAT = 0;
		this.totalServiceCharge = 0;
		this.totalDiscount = 0;
		this.subTotal = 0;
		this.totalAmountWithOutTax = 0;
		if (this.PropertyInfo.UseRetailInterface) {
			for (const element of this.GridItems) {
				if (!(element.isGroupingKey && !element.isPackagedItem)) {
					await this.AdjustServiceChargeGratuity(element.element);
					const itemQuantity: number = element.quantity == '' ? 0 : Number(element.quantity);
					const gratuity = element.element.Gratuity && element.element.Gratuity.length > 0 ? element.element.Gratuity.map(x => x.gratuity).reduce((a, b) => a + b, 0) : 0;
					const servCharge = element.element.ServiceCharge && element.element.ServiceCharge.length > 0 ? element.element.ServiceCharge.map(x => x.ServiceCharge).reduce((a, b) => a + b, 0) : 0;
					const itemPrice = parseFloat(element.unitprice) * itemQuantity;
					this.totalAmount = this.RoundOffTwo(this.totalAmount + itemPrice);
					this.totalGratuity += gratuity;
					this.totalServiceCharge += servCharge;
					let itemDiscount = 0;
					if (itemPrice > 0) {
						this.totalAmount =
							this.totalAmount -
							((element.element.Discount || 0) > itemPrice ? itemPrice : element.element.Discount);
						itemDiscount = (element.element.Discount || 0) > itemPrice ? itemPrice : element.element.Discount;
					}
					else if (element.element.isReturn) { // Return without ticket
						this.totalAmount = this.totalAmount + (Math.abs((element.element.Discount || 0)) > Math.abs(itemPrice) ? Math.abs(itemPrice) : Math.abs(element.element.Discount));
						itemDiscount = Math.abs((element.element.Discount || 0)) > Math.abs(itemPrice) ? Math.abs(itemPrice) : Math.abs(element.element.Discount);
						itemDiscount *= -1;
					}
					this.totalDiscount += itemDiscount;
					this.totalAmount = this.RoundOffTwo(this.totalAmount + this.RoundOffTwo(gratuity + servCharge));
				}
			}
		}
		else {
			const checkData = this._ss?.Ticket?.checkData;
			var TaxDetails = this._ss?.Ticket?.lineItems.flatMap(x => x.taxDetails).flatMap(y => y.linkedTaxDetails);
			TaxDetails = [...TaxDetails];
			TaxDetails = TaxDetails.filter(x => x != null);
			console.log(TaxDetails);
			this.totalGratuity = checkData?.gratuity;
			this.totalServiceCharge = checkData?.serviceCharge;
			this.totalDiscount = checkData?.discount;
			this.subTotal = checkData?.subTotal;
			this.totalAmount = checkData?.totalAmount;
			this.serviceChargeTax = this._ss.Ticket?.lineItems?.map(x => x.serviceChargeTax)?.reduce((x, y) => x + y, 0);
			this.gratuityTax = this._ss.Ticket?.lineItems?.map(x => x.gratuityTax)?.reduce((x, y) => x + y, 0);
			this.totalTaxOnTax = checkData?.totalLinkedTax;
			this.serviceVAT = this.PropertyInfo.IsVATEnabled && this.totalServiceCharge > 0 ? await this.GetServiceChargeVATAmount() : 0;
			this.showServiceVAT = this.serviceVAT > 0 ? true : false;
			this.totalTaxValue = this.totalTaxOnTax != 0 ? checkData?.totalBaseTax : checkData?.totalItemTax;
			this.taxValue = checkData?.totalItemTax;
			this._ss.totalAmountWithoutTax = this.totalAmountWithOutTax = checkData?.totalAmountWithOutTax;
			this.TicketTotal = this.totalAmount;
		}
		const tax = this.FormatPrice((this.exempttax.value) ? 0 : this.taxValue);
		this.IsRefundFlow = (this.RoundOffTwo(this.totalAmount) + this.localization.currencyToSQLFormat(tax)) < 0;
		this.CalculateRemainingAmount();
		this.GetTicketTotal();
		this.AdjustPaymentMethods();
	}
	async GetLinkedTaxName(id: number) {
		var res = await this._ss.GetLinkedTaxConfigurationById(id);
		if (res != null)
			return res.linkedTaxName;
		else
			return this.localization.captions.shop.TaxOnTax
	}
	GetServiceChargeVATAmount() {
		let serviceVATAmount = 0;
		this._ss.Ticket.lineItems.forEach(itm => {
			if (itm.serviceCharges) {
				serviceVATAmount += itm.serviceCharges.reduce((x, y) => x + (y.serviceChargeValue - y.netServiceCharge), 0)
			}
		});
		return serviceVATAmount;
	}

	// Need to check whether total is calculated from checkData
	async AdjustServiceChargeGratuity(item: SelectedProducts) {
		const selectedProd = this._ss.selectedProducts.find(
			(r) => r.ItemId == item.ItemId && r.LineNumber == item.LineNumber
		);
		if (selectedProd && (this.IsGratuityOnNet || this.IsServiceChargeOnNet)) {
			let discountedAmount = item.Discount ? item.Discount : 0;
			let productGrossValue = selectedProd.ProductPrice * selectedProd.Noofitems;
			if (productGrossValue > 0 && discountedAmount > 0 && discountedAmount > productGrossValue) {
				discountedAmount = item.ProductPrice;
			}
			productGrossValue -= discountedAmount;
			let productNetValue = productGrossValue;
			let productNetPriceValueWithoutDiscount = productGrossValue;
			let currentItemInTicket: LineItem;
			if (this._ss.isAppointmentCheckOut && this.clientMultipackTicket) {
				currentItemInTicket = this.clientMultipackTicket.lineItems.find(
					(r) =>
						r.externalPOSItemId == item.multipackExternalPOSItemId && r.isMultiPackRedeem == item.MultiPack
				);
			} else if (this._ss.Ticket && this._ss.Ticket.lineItems && this._ss.Ticket.lineItems.length > 0) {
				currentItemInTicket = this.PropertyInfo.UseRetailInterface ?
					this._ss.Ticket.lineItems.find((r) => r.externalPOSItemId == item.ExternalPOSItemId && r.index == item.LineNumber) :
					this._ss.Ticket.lineItems.find((r) => r.itemId == item.ItemId && r.index == item.LineNumber);
				;
			}
			if (currentItemInTicket) {
				productNetValue = currentItemInTicket.netPrice;
				productNetPriceValueWithoutDiscount = currentItemInTicket.netUnitPriceWithoutDiscount * selectedProd.Noofitems || 0;
			}

			let actualValueForCharge = 0, actualSC = 0, actualGT = 0;
			if (this.IsGratuityOnNet && selectedProd.Gratuity && selectedProd.Gratuity.length > 0) {
				selectedProd.Gratuity.forEach(s => actualGT = this.RoundOffTwo(actualGT + s.Amount));
				actualValueForCharge = await this._ss.GetItemValueForCharges(
					productGrossValue,
					productNetValue, productNetPriceValueWithoutDiscount,
					ServiceChargeGratuityEnum.Gratuity
				);
				const GTSplit = this.utils.GetSplitup(actualGT, selectedProd.Gratuity.length);
				let gtindex = 1, calculatedGT = 0;
				for (const grat of selectedProd.Gratuity) {
					if (grat.Percentage > 0) {
						let gratuity = Number((grat.Percentage * actualValueForCharge / 100).customToFixed());
						if (gtindex == selectedProd.Gratuity.length && this._ss.isAppointmentCheckOut) {
							const tempRemaining = this.RoundOffTwo((actualGT - calculatedGT));
							gratuity = tempRemaining < GTSplit.upScaleValue ? tempRemaining : GTSplit.upScaleValue;
						}
						grat.Amount = grat.gratuity = gratuity;
						calculatedGT = this.RoundOffTwo(calculatedGT + gratuity);
						gtindex++;
					}
				}
				item.Gratuity = selectedProd.Gratuity;
			}

			if (this.IsServiceChargeOnNet && selectedProd.ServiceCharge && selectedProd.ServiceCharge.length > 0) {
				selectedProd.ServiceCharge.forEach(s => actualSC = this.RoundOffTwo(actualSC + s.Amount));
				actualValueForCharge = await this._ss.GetItemValueForCharges(
					productGrossValue,
					productNetValue, productNetPriceValueWithoutDiscount,
					ServiceChargeGratuityEnum.ServiceCharge
				);
				let scSplit = this.utils.GetSplitup(actualSC, selectedProd.ServiceCharge.length);
				let scindex = 1, calculatedSC = 0;
				for (const sc of selectedProd.ServiceCharge) {
					if (sc.Percentage > 0) {
						let serviceCharge = Number((sc.Percentage * actualValueForCharge / 100).customToFixed());
						if (scindex == selectedProd.ServiceCharge.length && this._ss.isAppointmentCheckOut) {
							const tempRemaining = this.RoundOffTwo((actualSC - calculatedSC));
							serviceCharge = tempRemaining < scSplit.upScaleValue ? tempRemaining : scSplit.upScaleValue;
						}
						sc.Amount = sc.ServiceCharge = serviceCharge;
						calculatedSC = this.RoundOffTwo(calculatedSC + serviceCharge);
						scindex++;
					}
				}
				item.ServiceCharge = selectedProd.ServiceCharge;
			}
		}
	}


	getSaleAmt(forPaymentPopup: boolean = false) {
		const Amount = this.GetTicketTotal();
		if (forPaymentPopup) {
			return Amount < 0 ? Amount * -1 : Amount;
		} else {
			return Amount;
		}
	}

	PerformGiftcardActionsPostSettlement() {
		//Since It is Possible to have both issue and Load in a same transaction, Added as a separate condition
		if (this._ss.selectedProducts.some(x => x.isGiftCardItem && ((x.GiftCardTransactionItem.transactionType == GlobalConst.GiftCardTransactionType.Issue) || x.GiftCardTransactionItem.isIssue))) {
			this._giftcardBusiness.PerformGiftCardIssue(
				this.CreateRetailItemResponse.transactionDetails[0].transactionId
				, this.CreateRetailItemResponse?.transactionData?.retailTicketNumber
			);
		}
		const isThereAnyIssuanceNeedToBeProcessed = (this._ss.selectedProducts.some(x => x.isGiftCardItem && ((x.GiftCardTransactionItem.transactionType == GlobalConst.GiftCardTransactionType.Issue) || x.GiftCardTransactionItem.isIssue)));
		if (!isThereAnyIssuanceNeedToBeProcessed) {
			this.TriggerGiftcardTopup(
				this.CreateRetailItemResponse.transactionDetails[0].transactionId
				, this.CreateRetailItemResponse?.transactionData?.retailTicketNumber
			);
		}
	}

	TriggerGiftcardTopup(transactionId, retailTicketNumber) {
		if (!transactionId || !retailTicketNumber) return
		if (this.tempSelectedProducts.some(x => x.isGiftCardItem && ((x.GiftCardTransactionItem.transactionType == GlobalConst.GiftCardTransactionType.Load) || !x.GiftCardTransactionItem.isIssue))) {
			this._giftcardBusiness.PerformGiftCardLoad(transactionId, retailTicketNumber, this.CreateRetailItemResponse);
		}
		else {
			this.printReceipt(this.CreateRetailItemResponse);
			if (this._ss.allowAutoCloseTransaction && !this._ss.isOperationFromActivities) { // Activities - retail integration changes
				this.PerformCloseTransaction();
			}
		}
	}

	getAmountPaid(SaleAmountResponse?, PropertyName?, saleAmount = 0) {
		let AmountPaid = 0;
		if (
			this.selectedPaymentMethod == PaymentMethods.Cash ||
			this.selectedPaymentMethod == PaymentMethods.ARAcctPost ||
			this.selectedPaymentMethod == PaymentMethods.RainCheck ||
			this.selectedPaymentMethod == PaymentMethods.ARPost ||
			this.selectedPaymentMethod == PaymentMethods.RedeemPoint ||
			this.selectedPaymentMethod == PaymentMethods.CreditBook ||
			this.selectedPaymentMethod == PaymentMethods.CompRedemption ||
			this.selectedPaymentMethod == PaymentMethods.OfferRedemption ||
			this.selectedPaymentMethod == PaymentMethods.CompSlipRedemption ||
			this.isGiftCardSelected || this.isCustomPaymentMethod || this.SkipRoomChargePosting || this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment)
		) {
			if (this.multiplePayment) {
				AmountPaid = this.localization.currencyToSQLFormat(
					this.transitionDetails.controls.amounttendered.value
				);
			} else {
				AmountPaid = this.getSaleAmt();
			}
			if (saleAmount != 0 && this.IsOfferOrCompSlipPaymentMethod) {
				AmountPaid = saleAmount;
			}
		} else if (SaleAmountResponse) {
			AmountPaid =
				this.IsRefundFlow && SaleAmountResponse[PropertyName] > 0
					? SaleAmountResponse[PropertyName] * -1
					: SaleAmountResponse[PropertyName];
		}
		return AmountPaid;
	}

	ResetSelectedPayment() {
		this.selectedpayment.id = this.selectedpayment.paymentTypeId = this.selectedpayment.parentTypeId = 0;
		this.isCardTransaction = false;

	}

	onRoomOpenDialogClosed(data: any) {
		if (data) {
			if (
				this.selectedPaymentMethod == PaymentMethods.IDTECH &&
				this._sbs.ValidateIDTechCardSwipeData(data) && data != this.localization.captions.common.OK
			) {
				this.EncryptedCardData = data;
				this.ShowTransactionInprogressDialog();
				this.ManualClosePerformed = true;
			} else if (
				this.selectedPaymentMethod == PaymentMethods.IDTECH &&
				!this.EncryptedCardData &&
				data == this.localization.captions.common.Close
			) {
				this.ManualClosePerformed = true;
				return;
			} else if (
				data != this.localization.captions.common.Close && data != this.localization.captions.common.OK &&
				this.selectedpayment.id > 0 &&
				!this.beforeClinet
			) {
				let AmountTendered = this.localization.currencyToSQLFormat(
					this.transitionDetails.controls.amounttendered.value
				);
				if (AmountTendered === Number(this.transitionDetails.controls.amountpaid.value)) {
					this.paymentText = this.oCaptions.shop.CloseTransaction;
					this.ResetSelectedPayment();
					this.EnableCloseTranBtn = true;
					this.AllowCancel = false;
				}
			} else if (data == this.localization.captions.common.Close || data == this.localization.captions.common.OK) {
				if (data == this.localization.captions.common.Close) {
					this.tempSelectedProducts = this._ss.selectedProducts;
				}
				else {
					this._ss.GoToRetailTransaction = true;
				}
				this.HandlePostPaymentRedirection();
			}
		}
	}

	PromptCaddyShackCommentDialog(useRetailInterface: boolean) {
		let checkPrintToCaddyShack = this._ss.SelectedRetailItemDetails.filter(t => t.retailItemDetail.isPrintToCaddyShack);
		if (checkPrintToCaddyShack.length > 0) {
			this.printCaddyShack = true;
			let dialogRef = this.dialog.open(CommonCommentComponent, {
				height: 'auto',
				width: '40%',
				data: { headername: this.localization.captions.shop.Caddyshack, placeholder: this.localization.captions.shop.CaddyShackComments, closebool: true },
				panelClass: 'small-popup',
				disableClose: true,
				hasBackdrop: true
			});
			dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
				if (result.action.toLowerCase() == 'ok') {
					this.caddyshackcomment = result.reason;
					this.transactionService.UpdateCaddyShackComment(this.CreateRetailItemResponse.transactionDetails[0].transactionId, this.caddyshackcomment);
					this.ProceedSale(useRetailInterface);
				}
				else {
					this.ProceedSale(useRetailInterface);
				}
				// Based on switch redirecting to res module
				this.isProceedAutoCloseTransaction.asObservable().subscribe((res) => {
					if (res) {
						if (this._ss.allowAutoCloseTransaction && !this._ss.isOperationFromActivities && !this._ss.selectedProducts.some(x => x.isGiftCardItem)) {
							this.PerformCloseTransaction();
						}
						this.isProceedAutoCloseTransaction.next(false);
						this.isProceedAutoCloseTransaction.unsubscribe();
					}
				});
			});
		} else {
			this.ProceedSale(useRetailInterface);
			// Based on switch redirecting to res module
			this.isProceedAutoCloseTransaction.asObservable().subscribe((res) => {
				if (res) {
					if (this._ss.allowAutoCloseTransaction && !this._ss.isOperationFromActivities && !this._ss.selectedProducts.some(x => x.isGiftCardItem)) { // Activities - retail integration changes
						this.PerformCloseTransaction();
					}
					this.isProceedAutoCloseTransaction.next(false);
					this.isProceedAutoCloseTransaction.unsubscribe();
				}
			});

		}
	}

	async SendNotification(ticketNumber: string, selectedOutletId: number, receiptType: GlobalConst.ReceiptType, transactionId: number, clientName?: string,
		voidReason?: string, isPendingSettlement?: boolean, isReturn?: boolean, returnTransactionId?: number, callBack?: any,
		isTaxExempt?: boolean, isMemberTransaction?: boolean, availableRounds?: number, receiptComment?: string, saleDate: Date = this.PropertyInfo.CurrentDTTM) {
		const clientInfo = {} as ClientInfo;
		const clerkName = this.quickIdUser ? this.quickIdUser : this.utils.GetPropertyInfo("userName");
		const isEnableMachineTransaction = sessionStorage.getItem('enableMachineTransaction') == "true" ? true : false;


		const memberPayment = this.MapSettlementHistForMember(this.SettlementHistory.filter(x => x.paymentMethodId == PaymentMethods.ARPost || x.parentTenderId == PaymentMethods.ARPost));
		const options: ReportAPIOptions = await this.receiptService.formReportAPIOptions(ticketNumber, selectedOutletId, receiptType, transactionId, clientName, voidReason,
			isPendingSettlement, isReturn, returnTransactionId, isTaxExempt, isMemberTransaction, availableRounds, receiptComment, clerkName,
			isEnableMachineTransaction, this._ss.memberCardNumber.toString(), '', '', false, '', memberPayment);
		clientInfo.transactionId = this.CreateRetailItemResponse.transactionDetails[0].transactionId;
		clientInfo.reportQuery = this.receiptService.reportQuery ? this._fastReportBusinessService.createAPIOptions(this.receiptService.reportQuery) : this._fastReportBusinessService.createAPIOptions(options);

		if (this.currentProductId == GlobalConst.Product.GOLF) {
			const playerIds: number[] = this._ss.SelectedPlayers.map(x => x.playerId);
			clientInfo.playerId = playerIds;
		}

		else if (this.currentProductId == GlobalConst.Product.SPA) {
			const appointmentId: number[] = this._ss.AppoinmentIdForCheckOut;
			clientInfo.appointmentId = appointmentId;
		}
		if (this.isEmailOnDemandProduct)
			RetailDataAwaiters.SendNotification(clientInfo, this._retailService.manualEmailId, true);
		this._retailService.manualEmailId = [];
	}

	MapSettlementHistForMember(payHist: PaymentHistory[]): MemberPaymentHistory[] {
		const result: MemberPaymentHistory[] = [];
		if (payHist && payHist?.length && this.paymentComponent.memberGuestList?.length) {
			payHist.forEach(p => {
				result.push({
					memberName: p.memberInfo.memberName,
					golfPoints: p.memberInfo.golfPoints,
					arAccountNumber: p.memberInfo.arAccountNumber,
					memberCardNumber: p.memberInfo.memberCardNumber,
					paymentReferenceId: p.memberInfo.paymentReferenceId
				});

			});
		}
		return result;
	}

	ProceedSale(useRetailInterface: boolean) {
		this.HandleAfterPayment();
		this.payeeInfo = null;
		this.FetchActiveFolioInvoiceNumberForPayee();
		if (useRetailInterface) {
			const paymentCloseText = this.localization.replacePlaceholders(
				this.ShopCaptions.TicketCreationSuccess,
				['checkNumber'],
				[this.CreateRetailItemResponse.transactionData.ticketNumber]
			);
			const dataObj = {
				text: paymentCloseText,
				buttonname: this.localization.captions.common.OK,
				headertext: this.ShopCaptions.PaymentComplete,
				icon: 'icon-success-icon'
			};
			this.roomOpenDialog(dataObj);
		}
		else {
			this.paymentText = this.oCaptions.shop.CloseTransaction;
			this.AllowCancel = false;
			this.ShowPaymentMethods = false;
			this.multiplePayment = false;
			this.FullPayment = false;
			this.selectedpayment.id = this.selectedpayment.paymentTypeId = this.selectedpayment.parentTypeId = 0;
			this.isCardTransaction = false;
			const isTransactionHasOnlyRoomGroupHotelCompCharge = this.SettlementHistory.every(f => this.utils.GetOriginalTenderId(f.paymentMethodInfo.paymentMethodId, f.paymentMethodInfo.parentTypeId) == PaymentMethods.RoomCharge ||
				this.utils.GetOriginalTenderId(f.paymentMethodInfo.paymentMethodId, f.paymentMethodInfo.parentTypeId) == PaymentMethods.GroupCharge ||
				this.utils.GetOriginalTenderId(f.paymentMethodInfo.paymentMethodId, f.paymentMethodInfo.parentTypeId) == PaymentMethods.HotelComp);
			const isSkipPMAgent = this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment);
			this._ss.GetWebProxyHealthCheckStatusForPMSPostings(isTransactionHasOnlyRoomGroupHotelCompCharge, false, (isTransactionHasOnlyRoomGroupHotelCompCharge && isSkipPMAgent));
		}
	}


	/**
   *@method pop-up
   *@function roomOpenDialog()
   *@param input <obj>
   *@description In the popup open the roompayment method
   *@param output <null>
   */

	roomOpenDialog(e: any) {
		return this.utils.roomOpenDialog(e, this.onRoomOpenDialogClosed.bind(this));
	}

	/**
   * @function MenuItemClick()
   * @prams obj
   * @description Remove the offers when choose the selected offer
   */
	async MenuItemClick(e, itemDetails, i?) {
		switch (e.id) {
			case 1: //edit
				this.utils.ToggleLoaderWithMessage(true, this.localization.captions.lbl_processing);
				setTimeout(() => {
					this.OpenEditCommissionGratuity(itemDetails);
				}, 1);
				break;
			case 2: // Grat
				if (!this.CheckBreakPointAccess(GlobalConst.RetailBreakPoint.EditGratuity)) {
					break;
				}
				itemDetails.element.Gratuity = null;
				e.isactive = false;
				e.isApplied = false;

				if (itemDetails.element.ItemType === 6) {
					const index = itemDetails.element.LineNumber - 1;
					if ((this.depositArray || []).length > 0) {
						this.depositArray[index].gratuity = 0;
					}
				}
				await this.UpdateTicket(TicketUpdateType.RemoveGratuity);
				break;
			case 3: // Comm
				if (!this.CheckBreakPointAccess(GlobalConst.RetailBreakPoint.EditCommission)) {
					break;
				}
				this._ams.loaderEnable.next(this.ShopCaptions.RemoveItemCommission);
				itemDetails.element.Commission = [];
				if (!this._ss.isAppointmentCheckOut) {
					itemDetails.element.StaffTransactionDetail = [];
				}
				e.isactive = false;
				e.isApplied = false;
				itemDetails.charges[0].name = this.localization.captions.shop.AddCommissionGratuity;
				setTimeout(() => {
					this._ams.loaderEnable.next('');
				}, 1000);
				break;
			case 4: // Serv
				if (!this.CheckBreakPointAccess(GlobalConst.RetailBreakPoint.EditServiceCharge)) {
					break;
				}
				itemDetails.element.ServiceCharge = null;
				e.isactive = false;
				e.isApplied = false;
				if (itemDetails.element.ItemType === 6) {
					const index = itemDetails.element.LineNumber - 1;
					if ((this.depositArray || []).length > 0) {
						this.depositArray[index].serviceCharge = 0;
					}

				}
				await this.UpdateTicket(TicketUpdateType.RemoveServicecharge);
				break;
			case 5: // Discount
				if (!this.CheckBreakPointAccess(GlobalConst.RetailBreakPoint.ApplyDiscount)) {
					break;
				}
				await this.removeDiscount(i, itemDetails);
				itemDetails.element.Discount = 0;
				itemDetails.element.DiscountTypeId = 0;
				itemDetails.element.DiscountPercentage = 0;
				itemDetails.element.discountComments = '';
				itemDetails.element.discountReason = 0;
				let seletedItemIdx = this._ss.SelectedItemDiscount.findIndex(x => x.itemId == itemDetails.element.ItemId);
				if (seletedItemIdx > -1) {
					this._ss.SelectedItemDiscount.splice(seletedItemIdx, 1);
				}
				const discountConfig = this.applyDiscountService.CategoryDiscountList.find(
					(x) => x.retailCategoryId == itemDetails.element.category && x.eligibleForDiscount
				);
				if (discountConfig) {
					this.RecalculateServiceChargeGratuityByDicount(itemDetails.element);
				}

				e.isactive = false;
				e.isApplied = false;
				break;
			default:
				break;
		}
		await this.totalPrice();
		if (e.id == 5) {
			await this.UpdateTicket(TicketUpdateType.ModifyServiceChargeAndGratuity);
		}
		if (e.id != 1) {
			this.exemptTaxForPaymentMethod();
		}
	}

	exemptTaxForPaymentMethod() {
		if (this._ss.selectedTaxExemptPayment?.isAutoRemoveTax && !this.isVATEnabled && !this.IsRefundFlow) {
			this._ss.exemptTaxForComp(this.remainingAmount).then(x => {
				if (x)
					this.updateOrderSummary();
			})
		}
	}
	/**
  * @function selectedPayment()
  * @prams id
  * @description If Click the any payment method check the id if Click id is same as Looping id Color will change
  */
	selectedPayment(e) {
		this.paymentMethod = e;
	}

	/**
   * @function roomSelected
   * @param <obj>
   * @description select the room type  of cash payment
   */

	roomSelected(e: any) {
		this.ifPaymentChoose = true;
		this.paymentText = this.oCaptions.shop.Add;
	}

	/**
   *
   * @param itemDetails;
   * @function selectDevice
   * @param <obj>
   * @description Select the device type Device Details
   */
	selectDevice(e: any) {
		this.selectedDeviceHandle = e.handle;
		this.deviceName = e.name;
		console.log(this.transitionDetails);
		this.ifPaymentChoose = true;
		this.deviceId = e.id;
		if (e.name == IDTechHandle.name) {
			this.selectedpayment.paymentTypeId = PaymentMethods.IDTECH;
		} else {
			this.selectedpayment.paymentTypeId = PaymentMethods.CreditCard;
		}
	}

	/**
   * @method function
   * @function tenderedAmount();
   * @input params <null>
   * @output <obj>
   * @description tendered
   */
	tenderedAmount() {
		let AmountTendered = this.localization.currencyToSQLFormat(this.transitionDetails.controls.amounttendered.value);
		this.CalculateSettledAmount();
		const paymentBool = Number(this.transitionDetails.controls.amountpaid.value) === AmountTendered;
		if (this.selectedPaymentMethod == PaymentMethods.CompRedemption) {
			this.selectedDeviceHandle = '';
			this.CMSForm.patchValue({ cmsPaymentSelection: '' });
		}
		if (paymentBool && this.selectedPaymentMethod === PaymentMethods.Cash) {
			this.ifPaymentChoose = true;
			this.defaultSelectchecked = 0;
			if (!this.EnableCloseTranBtn)
				this.EnableCloseTranBtn = true;
		} else {
			if (this.transitionDetails.controls.amounttendered.valid && !isNaN(AmountTendered) && this.selectedpayment.paymentTypeId > 0) {
				this.EnableCloseTranBtn = true;
			} else {
				this.EnableCloseTranBtn = false;
			}
		}
	}

	CalculateSettledAmount() {
		this.SettledAmt = 0;
		//check if previous settlements are there?
		if (this.SettlementHistory.length > 0) {
			//Get the settled amounts from all settlements
			this.SettlementHistory.forEach(
				(settlemt) => {
					if (!settlemt.isReversed) {
						(this.SettledAmt = this.RoundOffTwo(
							this.RoundOffTwo(this.SettledAmt) + this.RoundOffTwo(settlemt.amount)
						))
					}
				});
			this.CalculateRemainingAmount();
		}
		this.SettledAmt = this.RoundOffTwo(this.SettledAmt);
	}

	CalculateRemainingAmount() {
		if (this.IsRefundFlow) {
			this.remainingAmount = -Number(
				(this.getSaleAmt(true) - (isNaN(this.SettledAmt) ? 0 : this.SettledAmt) * -1).customToFixed()
			);
			this.transitionDetails.controls.amounttendered.setValidators([
				Validators.min(this.remainingAmount),
				Validators.max(0),
				Validators.required,
				NegativeValueValidator(this)
			]);
			this.transitionDetails.controls.amounttendered.updateValueAndValidity();
		} else {
			this.remainingAmount = Number(
				(this.getSaleAmt(true) - (isNaN(this.SettledAmt) ? 0 : this.SettledAmt)).customToFixed()
			);
			this.transitionDetails.controls.amounttendered.setValidators([
				Validators.max(this.remainingAmount),
				Validators.required,
				NegativeValueValidator(this)
			]);
			this.transitionDetails.controls.amounttendered.updateValueAndValidity();
			this._ss.remainingAmount = this.remainingAmount;
		}
	}

	OpenEditCommissionGratuity(itemDetails: any): void {
		try {
			let item = [itemDetails.element];
			let PerviousTherapistSameItem: number[] = [];
			PerviousTherapistSameItem =
				itemDetails.element && itemDetails.element.Commission
					? itemDetails.element.Commission.map((a) => (a.id ? a.id : 0))
					: [];
			let therapistSelectedList: number[] = [];
			this._ss.selectedProducts.forEach((x) => {

				if (x.Commission && x.Commission.length > 0) {
					therapistSelectedList = therapistSelectedList.concat(
						x.Commission.map((a) => (a.id ? a.id : (<any>a).therapistId))
					);
				}
			});

			const externalElementIndex: number = this.PropertyInfo.UseRetailInterface ?
				this._ss.findSelectedItemIndexWithoutPackage(itemDetails.element.LineNumber, this._ss.selectedProducts) + 1 :
				itemDetails.lineNumber;
			const lineItem = !this.PropertyInfo.UseRetailInterface ?
				this._ss.Ticket.lineItems.find(r => r.itemId === item[0].ItemId && r.index === externalElementIndex) : null;
			let itemNetPrice = 0;
			let multipackItem: LineItem;
			if (this._ss.isAppointmentCheckOut && this.clientMultipackTicket && this.clientMultipackTicket.lineItems) {
				multipackItem = this.clientMultipackTicket.lineItems.find(
					(r) => r.externalPOSItemId == item[0].multipackExternalPOSItemId
				);
			}
			itemNetPrice = multipackItem
				? multipackItem.netPrice
				: this.transactionService.GetNetValue(
					item[0].ProductPrice * itemDetails.quantity,
					item[0].ExternalPOSItemId,
					this._ss.Ticket,
					externalElementIndex, item[0].ItemId,
				);
			const title = this.localization.captions.shop.AssignCommissionGratuity;
			const templateName = 'AC';
			const dialogRef = this.dialog.open(ShopDialogPopUp, {
				width: '1155px',
				height: '90%',
				maxHeight: '700px',
				disableClose: true,
				hasBackdrop: true,
				data: {
					isEdit: true,
					MaxTherapistTobeSelected: itemDetails.element.MaxTherapistForCommission,
					headername: title,
					closebool: true,
					templatename: templateName,
					data: [
						item,
						itemDetails.quantity,
						therapistSelectedList,
						PerviousTherapistSameItem,
						item[0].Commission && item[0].Commission.length > 0,
						this.transactionDetailTherapistMap.size > 0 && this.transactionDetailTherapistMap.has(itemDetails.element.Id) ?
							this.transactionDetailTherapistMap.get(itemDetails.element.Id) : []
					],
					netAmount: itemNetPrice,
					netUnitPriceWithoutDiscount: this.PropertyInfo.UseRetailInterface ? item[0].netUnitPriceWithoutDiscount :
						lineItem?.netUnitPriceWithoutDiscount,
				},
				panelClass: 'small-popup'
			});
			this.utils.ToggleLoader(false);
			dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe((x) => {
				if (x) {
					this.tempItemDetails = { itemDetails: itemDetails, item: item }
					this.ServiceChargeCalculation(itemDetails, item);
				}
			});
		}
		catch (e) {
			this.http.exceptionHandle(e);
		}
	}

	async ServiceChargeCalculation(itemDetails: any, item: any) {
		if (Object.keys(itemDetails).length != 0) {
			itemDetails.charges[0].name =
				item[0].Commission && item[0].Commission.length > 0
					? this.localization.captions.shop.EditCommissionGratuity
					: this.localization.captions.shop.AddCommissionGratuity;

			itemDetails.charges[1].isactive = item[0].Gratuity && item[0].Gratuity.length > 0 && item[0].Gratuity.find(r => r.gratuity > 0) ? true : false;
			itemDetails.charges[2].isactive = item[0].Commission && item[0].Commission.length > 0 ? true : false;
			itemDetails.charges[3].isactive = item[0].ServiceCharge && item[0].ServiceCharge.length > 0 && item[0].ServiceCharge.find(r => r.ServiceCharge > 0) ? true : false;

			itemDetails.charges[1].isApplied = item[0].Gratuity && item[0].Gratuity.length > 0 && item[0].Gratuity.find(r => r.gratuity > 0) ? true : false;
			itemDetails.charges[2].isApplied = item[0].Commission && item[0].Commission.length > 0 ? true : false;
			itemDetails.charges[3].isApplied = item[0].ServiceCharge && item[0].ServiceCharge.length > 0 && item[0].ServiceCharge.find(r => r.ServiceCharge > 0) ? true : false;

		}

		if (item[0].ItemType === 6) {
			const index = item[0].LineNumber - 1;
			let gratuitySum = 0;
			let serviceChargeSum = 0;
			if (item[0].Gratuity && item[0].Gratuity.length > 0) {

				item[0].Gratuity.forEach(element => {
					gratuitySum += element.Amount;

				});
			}
			if (item[0].ServiceCharge && item[0].ServiceCharge.length > 0) {

				item[0].ServiceCharge.forEach(element => {
					serviceChargeSum += element.Amount;

				});
			}
			if ((this.depositArray || []).length > 0) {
				this.depositArray[index].gratuity = gratuitySum;
				this.depositArray[index].serviceCharge = serviceChargeSum;
			}

		}
		await this.totalPrice();
		await this.UpdateTicket(TicketUpdateType.ModifyServiceChargeAndGratuity);
		this.exemptTaxForPaymentMethod();
	}


	UpdateCenceledDeposit(transactionId: any) {
		if (this.cancelDepositAppointments && this.cancelDepositAppointments.length > 0) {
			this.cancelDepositAppointments.forEach((element) => (element.transactionId = transactionId));
			let eventData: RetailEventParameters<CancelDepositEventModel[]> = {
				data: this.cancelDepositAppointments,
				eventType: RetailEventType.CancelDeposit
			};
			this.dialog.closeAll();
			retailPublisher.publishEvent(eventData);
		}
	}
	// Not in use
	// linkTransactionId(transactionId, transactionLinkId) {
	// 	this.http.CallApiWithCallback({
	// 		host: GlobalConst.Host.retailPOS,
	// 		success: this.successCallback.bind(this),
	// 		error: this.errorCallback.bind(this),
	// 		callDesc: 'LinkTransactionId',
	// 		method: HttpMethod.Put,
	// 		uriParams: { transactionId: transactionId, transactionLinkId: transactionLinkId },
	// 		body: null,
	// 		showError: false,
	// 		extraParams: []
	// 	});
	// }

	setTransitionDetails() {
		this.transitionDetails = this.formbuilder.group({
			amounttendered: [
				{ value: '', disabled: true },
				[Validators.max(this.getSaleAmt()), Validators.required]
			],
			align: 1,
			returnReason: ['', [Validators.required]],
			numberOfReceipts: [''],
			cardtype: [{ value: '', disabled: true }],
			cardnumber: [{ value: '', disabled: true }],
			transactionnumber: [{ value: '', disabled: true }],
			amountpaid: [this.getSaleAmt()],
			roomnumber: ['', [Validators.required]],
			zipcode: [''],
			selectedDevice: ['', [Validators.required]],
			selectedGuest: [],
			receiptComment: ['']
		});
	}

	UpdateTaxAndTenderMaxLimit() {
		if (this.totalAmount) {
			const tax = this.FormatPrice((this.exempttax.value) ? 0 : this.taxValue);
			this.IsRefundFlow = (this.RoundOffTwo(this.totalAmount) + this.localization.currencyToSQLFormat(tax)) < 0;
		}
		if (this.IsRefundFlow) {
			this.transitionDetails.controls.amounttendered.setValidators([
				Validators.min(this.remainingAmount),
				Validators.max(0),
				Validators.required
			]);
		} else {
			this.transitionDetails.controls.amounttendered.setValidators([
				Validators.max(this.remainingAmount),
				Validators.required
			]);
		}
		this.CalculateRemainingAmount();
		this.AmountTenderInputType = this.IsRefundFlow ? 'onlynumber,decimal,roundoff2' : this.AmountTenderInputType;
		this.MissingAmountTenderMsg = this.IsRefundFlow
			? this.localization.captions.shop.MissingRefundAmount
			: this.localization.captions.shop.MissingAmountTenderMsg;
		const tempPaymentText = this._ss.isReturnWithoutTicket && this.IsRefundFlow
			? this.localization.captions.shop.ReturnPayment
			: this.localization.captions.shop.Pay;
		this.paymentText = this.PropertyInfo.UseRetailInterface
			? this.localization.captions.shop.SENDFORPAYMENT
			: tempPaymentText;
	}

	async ngOnInit() {
		this.setTransitionDetails();
		if (this.showPlatformGuestSearch) {
			this.extendedProfileSearchConfig = await RetailDataAwaiters.GetExtendedProfileSearchConfig() ?? false;
		}
		this.isPlatformGuestSearch = this.extendedProfileSearchConfig;
		this.clientSearchForm = this.formbuilder.group({
			isPlatformGuestSearch: this.isPlatformGuestSearch
		});
		this.miscSetting = this._ss.storeMiscSetting ? this._ss.storeMiscSetting : this._ss.GetMiscConfig();
		this.GetRequiredBreakPoints();
		sessionStorage.setItem('isChangesMade', 'true');
		this.SelectedRetailItemDetails = await this._ss.GetRetailItemDetails(this._ss.selectedProducts);
		this._ss.selectedappointments = this._retailService.selectedappointments;
		await this._memberService.SetMemberInfo();
		this._ss.hasMultiPackItem = this.SelectedRetailItemDetails?.filter(t => t.retailItemDetail.isMultiPack).length > 0;
		this._ams.clientsearchArray = [];
		this._ams.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe((loader) => {
			const loadingContainer = document.getElementById('custom-cover-spin');
			const loadingContainerMessage = document.getElementById('custom-cover-message');
			if (loadingContainer && loadingContainerMessage) {
				if (loader) {
					loadingContainer.style.display = 'block';
					loadingContainerMessage.innerText = loader;
				} else {
					loadingContainer.style.display = 'none';
					loadingContainerMessage.innerText = '';
				}
			}
		});
		this.orderSummaryLoader.pipe(takeUntil(this.$destroyed)).subscribe((enableLoader)=>
		{
			const loadingContainer = document.getElementById('cover-spin');
			if (loadingContainer) {
				if (enableLoader) {
					loadingContainer.style.display = 'block';
				} else {
					loadingContainer.style.display = 'none';
				}
			}

		});
		this.exempttax = new UntypedFormControl(false);
		this._ams.isCancel = false; //Fix For No changes in client-info
		this.selectedDate = this._ss.SelectedDate;
		this.selectedCourse = this._ss.SeletedCourseId;
		this.scheduleTeeTimeIds = this._ss.SelectedPlayers
			? this._ss.SelectedPlayers.map((x) => x.scheduledTeeTimeId)
			: [];
		this.courseIds.push(this._ss.SeletedCourseId);
		if (!this.PropertyInfo.UseRetailInterface) {
			await this.LoadDiscountData();
			this._ss.checkForAccess();
		}
		this.appointmentIds = this._ss.AppoinmentIdForCheckOut ? this._ss.AppoinmentIdForCheckOut : [];
		this.playerIds = this._ss.SelectedPlayers ? this._ss.SelectedPlayers.map((x) => x.playerId) : [];
		if (this._ss.memberCardNumber != "0") {
			let extraRetailItems = this._ss.selectedProducts.filter(
				(x) => x.payeeId == undefined && x.playerName == undefined && x.ItemType == RetailItemType.RetailItemRetailPOSOnly
			);
			if (extraRetailItems.length > 0) {
				let miscSettings = await this.miscSetting;
				const memberConfig = this._propertyInfoService.getMemberConfig;
				const applyMemberDiscountAutomatically = memberConfig?.applyMemberDiscountAutomatically?.toLowerCase() === 'true' ?? false;
				const overrideMemberDiscount = memberConfig?.overrideWithMemberDiscounts?.toLowerCase() === 'true' ?? false;
				let activeDiscountReasons = applyMemberDiscountAutomatically ? await this.applyDiscountService.getActiveDiscountReasons() : null;
				let nonEligibleForDiscount = [];
				this._ss.selectedProducts.forEach((product) => {
					if (product.payeeId == undefined && product.playerName == undefined) {
						let currentItem = this.SelectedRetailItemDetails.find(
							(x) => x.id == product.ItemId);
						let curMemberPrice = product.MultiPack ? product.MemberPrice : currentItem.retailItemDetail.memberPrice;
						if (!this._ss.settleOpenTransaction) {
							if (product.GroupingParentId > 0) {
								curMemberPrice = this.GroupedItemPriceHander(product, curMemberPrice);
							}
							const tempProductPrice = currentItem.retailItemDetail.isOpenItem ? product.ProductPrice :
								curMemberPrice;
							product.ProductPrice = currentItem.retailItemDetail.isScaledItem ?
								this.localization.currencyToSQLFormat(String(product.scaledUnits)) * curMemberPrice :
								tempProductPrice;
							product.ProductPrice = product.isReturn ? Math.abs(product.ProductPrice) * -1 : product.ProductPrice;
						}
						product.ProductPrice = product.isReturn ? Math.abs(product.ProductPrice) * -1 : product.ProductPrice;
						
						//Applying discount based on the discount type in Engage
						if (applyMemberDiscountAutomatically) {
							if (!overrideMemberDiscount && !product.isAutoMemDiscApplied && product.Discount > 0) {
								product.isAutoMemDiscApplied = true;
								product.isAutoMemDiscRemoved = true;
							}
							if (!product.isAutoMemDiscApplied && (overrideMemberDiscount || product.Discount == 0)) {
								let nonEligible = this._ss.ApplyMemberDiscountAutomatically(product, miscSettings, activeDiscountReasons, this.applyDiscountService.AllCategoryDiscountList, this.applyDiscountService.DiscountTypes, this._ss.memberDiscountType);
								if (nonEligible)
									nonEligibleForDiscount.push(nonEligible);
							}
						}

						product.Discount = product.DiscountPercentage > 0
							? this.utils.RoundOff2DecimalPlaces((product.Noofitems * product.ProductPrice) * product.DiscountPercentage / 100)
							: product.Discount;
					}
				});
				if (nonEligibleForDiscount.length > 0) {
					this.utils.ShowErrorMessage(
						this.localization.captions.common.Error,
						this.localization.replacePlaceholders(
							this.ShopCaptions.DiscountNotEligibleForSomeItem,
							['ItemCount'],
							[nonEligibleForDiscount.length]
						)
					);
				}
			}
		}
		this.FormOrderSummary();
		let data = this._ss.cancelDepositAppointments;
		this.UpdateTaxAndTenderMaxLimit();
		if (data.length > 0) {
			for (var dataItem of data) {
				let canceledDeposits: CancelDepositEventModel;
				canceledDeposits = {
					id: dataItem.id,
					cancelComments: dataItem.cancelComment,
					cancelReason: dataItem.cancelReason,
					cancelFee: 0,
					depositRefundAmount: dataItem.deposits.reduce((a, b) => a + b, 0),
					transactionId: 0
				};
				this.deposit += dataItem.deposits.reduce((a, b) => a + b, 0);
				this.cancelDepositAppointments.push(canceledDeposits);
			}
			this.beforeClinet = false;
			this._sbs.PaymentBeforeClinet = false;
			this.GetPayeeInfo(this._ss.selectedPayeeId);
		}
		this.depositArray = this._ss.depositFlag ? this._ss.depositArray : [];
		if (this.depositArray.length > 0) {
			this.beforeClinet = false;
			this._sbs.PaymentBeforeClinet = false;
			this.GetPayeeInfo(this._ss.selectedPayeeId);
			if (this._ss.excludeTaxForDeposit) {
				this.exempttax.setValue(this._ss.excludeTaxForDeposit);
				this.exempttax.disable();
			}
		}

		this.ifPaymentChoose = true;
		this.deviceType = [];
		this.allowTaxExempt = this._ss.hasUserAccessToExemptTax;
		this.CheckSpaAppoinmentCheckoutWithDepositSvcGrat();
		await this.CheckForMultipackRedemption();
		await this.CreateTicket();
		if (this._ss.settleOpenTransaction) {
			this.taxValue = _.cloneDeep(this._ss.TaxValue);
			this.UpdateTaxAndTenderMaxLimit();
		}
		this.getReceiptConfiguration();
		this.GuestProfileRequired =
			this.spaConfig && this.spaConfig.globalConfiguration && this.spaConfig.globalConfiguration.Switches
				? this.spaConfig.globalConfiguration.Switches.GuestProfileRequired
				: false;
		this.tempSelectedProducts = this._ss.selectedProducts;
		this.currentProductId = Number(this.utils.GetPropertyInfo('ProductId'));
		if (this.currentProductId == GlobalConst.Product.PMS) this.isUseNewArCustomerSearch = true;
		this.ProductSpaId = GlobalConst.Product.SPA;
		this.ProductSNCId = GlobalConst.Product.SNC;
		this.ProductGolfId = GlobalConst.Product.GOLF;
		this.ProductRetailId = GlobalConst.Product.RETAIL;
		this.ProductPMSId = GlobalConst.Product.PMS;
		this.showRainCheckIG();
		this.correcttransaction = this._ss.correctTransaction;
		this.oldTransactionId = this._ss.transactionId;
		this.IsRevenueItemIncluded = this._ss.selectedProducts.some(x => x.ItemType == RetailItemType.RevenueItem);
		this.receiptComment = this._ss.ReceiptComment;
		this.transitionDetails.controls.receiptComment.setValue(this.receiptComment);
		if (this.IsRevenueItemIncluded && this._ss.correctTransaction || this._ss.reOpenTransaction || this._ss.settleOpenTransaction) {
			RetailDataAwaiters.GetSetBeoChecks(this._ss.transactionId);
		}
		this.CheckForRequireComments();
		let folioConfigSwitches = sessionStorage.getItem('FolioConfigurationSwitches');
		let folioJwt = sessionStorage.getItem('_folioJwt');
		if (!folioConfigSwitches && folioJwt)
			await this.SetFolioConfigurationSwitches();
	}

	async SetFolioConfigurationSwitches() {
		let config;
		config = await this._folioBusiness.fetchFolioDefaultsSettings(SettingModule.FolioSetup, SettingScreen.ConfigurationSwitches);
		if (config?.configValue) {
			sessionStorage.setItem("FolioConfigurationSwitches", JSON.stringify(config));
		}
	}

	async SetDefaultExtendedSearch() {
		if (this.showPlatformGuestSearch) {
			this.isPlatformGuestSearch = this.extendedProfileSearchConfig;
			this.clientSearchForm.controls.isPlatformGuestSearch.setValue(this.isPlatformGuestSearch);
			this.clientSearchForm.controls.isPlatformGuestSearch.updateValueAndValidity();
		}
	}

	CheckSpaAppoinmentCheckoutWithDepositSvcGrat() {
		if (this._ss.isAppointmentCheckOut) {
			this._ss.selectedProducts?.forEach(e => {
				if (e.ItemType == RetailItemType.Deposit && (e?.ServiceCharge?.some(s => s.Amount < 0) || e?.Gratuity?.some(s => s.Amount < 0))) {
					let returnSvcInDeposit = e?.ServiceCharge?.filter(f => f.Amount < 0)?.reduce((sum, current) => sum + current.Amount, 0);
					let returnGratInDeposit = e?.Gratuity?.filter(f => f.Amount < 0)?.reduce((sum, current) => sum + current.Amount, 0);

					if (returnSvcInDeposit) {
						e.ProductPrice = e.ProductPrice + returnSvcInDeposit;
						e.SalesPrice = e.SalesPrice + returnSvcInDeposit;
						e.ServiceCharge = e.ServiceCharge.filter(f => f.Amount > 0);
					}

					if (returnGratInDeposit) {
						e.ProductPrice = e.ProductPrice + returnGratInDeposit;
						e.SalesPrice = e.SalesPrice + returnGratInDeposit;
						e.Gratuity = e.Gratuity.filter(f => f.Amount > 0);
					}
				}
			});
			this.FormOrderSummary();
		}
	}

	CheckForRequireComments() {
		if (this._ss.selectedProducts.length > 0 && this._ss.selectedProducts.some(x => x.isRequireComments && x.ItemType === 6 && x.isDepositOnly)) {

			var requireCommentsProducts = this._ss.selectedProducts.filter(x => x.isRequireComments
				&& x.ItemType === 6
				&& x.isDepositOnly
				&& (x.itemComments || '').trim() == '');

			requireCommentsProducts.forEach(x => {
				const dialogRef: MatDialogRef<DialogOverviewExampleDialog> = this._ss.openEnterCommentDialog(x);

				dialogRef.afterClosed()
					.pipe(takeUntil(this.$destroyed))
					.subscribe((res: enterCommentPrompt) => {
						if (res.res === GlobalConst.ButtonOptions.Save && res.comment) {
							x.itemComments = res.comment;
						}

					});
			});
		}
	}

	async CheckForMultipackRedemption() {
		if (
			(this.IsServiceChargeOnNet || this.IsGratuityOnNet) &&
			this._ss.isAppointmentCheckOut &&
			this._ss.selectedProducts &&
			this._ss.selectedProducts.some((r) => r.multipackExternalPOSItemId > 0)
		) {
			if (this.PropertyInfo.UseRetailInterface && this.clientMultipackTicket && this.clientMultipackTicket.lineItems) {
				this._ss.CancelTicket(this.clientMultipackTicket);
			}
			const lineItems = [];
			let index = 0;
			for (const item of this._ss.selectedProducts) {
				if (item.multipackExternalPOSItemId > 0) {
					const retailItem = this.SelectedRetailItemDetails.find(
						(r) => r.retailItemDetail.externalPOSId == item.multipackExternalPOSItemId
					);
					if (retailItem) {
						lineItems.push({
							ItemId: retailItem.id,
							ItemType: retailItem.retailItemDetail.itemType,
							ItemDescription: retailItem.retailItemDetail.itemDescription,
							ExternalPOSItemId: retailItem.retailItemDetail.externalPOSId,
							QuantitySold: item.Noofitems,
							UnitPrice: item.ProductPrice,
							Discount: 0,
							DiscountTypeId: 0,
							DiscountPercentage: 0,
							StaffTransactionDetail: [],
							LineNumber: 0,
							Tax: 0,
							TotalAmount: item.Noofitems * item.ProductPrice,
							OutletId: this._ss.SelectedOutletId,
							IsGroupingKey: false,
							IsPackagedItem: false,
							PackageItemId: 0,
							IsMultiPackRedeem: item.MultiPack ? item.MultiPack : false,
							IsModificationRestricted: false,
							ClientMultiPackId: 0,
							PackageGroupId: 0,
							IsOpenPricedItem: false,
							id: 0,
							IsTaxExempt: false,
							discountModel: {
								index: index,
								value: item.Discount ? item.Discount : 0,
								isPercentage: false
							},
							costPrice: retailItem.retailItemDetail.costPrice,
							marginPercentage: retailItem.retailItemDetail.marginPercentage,
							allowEarn: retailItem.retailItemDetail.allowEarn,
							discountComments: item.discountComments ? item.discountComments : '',
							discountReason: item.discountReason ? item.discountReason : 0
						});
						index++;
					}
				}
			}
			if (lineItems.length > 0) {
				this.clientMultipackTicket = this.PropertyInfo.UseRetailInterface ? await this.transactionService.CreateTicketForItem(lineItems) :
					await this.transactionEngineBusiness.CreateTicket(this._ss.SelectedOutletId, lineItems);
			}
		}
	}

	async ngOnDestroy() {
		this.commonUtils.resetQuickIdDetails();
		this.$destroyed.next(true);
		this.$destroyed.complete();
		clearTimeout(this.NoCardSwipeTimeOut);
		clearTimeout(this.NavigationTimeOut);
		if (this.retailValidationService.transactionLockId > 0) {
			this.retailValidationService.RemoveTransactionLock(this.retailValidationService.transactionLockId);
		}
		if (
			this.PropertyInfo.UseRetailInterface &&
			this._ss.Ticket &&
			this._ss.Ticket.checkData.checkNumber.trim() != ''
		) {
			await this._ss.InvokeDiscardCheck();

		}
		if (this.PropertyInfo.UseRetailInterface && this.clientMultipackTicket) {
			this._ss.CancelTicket(this.clientMultipackTicket);
		}
		if (this.IsTransactionSettled || !(this._ss.isFromContinueShopping || this._ss.isFromContinueBooking)) { this.ResetSummaryData(); }
		this._ams.paymentProcessing = false;
		this._ams.labelRecords = [];
		this._ams.memberRemove = false;
		this._ams.memberGuestId = null;
		this._memberService.RateTypes = [];
		this.isProceedAutoCloseTransaction.unsubscribe();
		if (!this._ss.isFromContinueShopping)
			this._retailService.manualEmailId = [];
	}

	ShowTransactionInprogressDialog() {
		this.dialog.closeAll();
		const Popupmessage = this.localization.captions.shop.TransactionInprogressMsg;
		const dataObj = { text: Popupmessage, buttonname: '', headertext: '', isloaderenable: true };
		this.transactionInProgressDialogRef = this.roomOpenDialog(dataObj);
	}


	SwitchToMultiPayment(SaleResponse: any, isPartialPaymentCardOnFile: boolean = false) {
		this.disabledRadiobool = true;
		this.PaymentOption({ id: 2 }); //This will change the payment mode to Partial Pay.
		this.transitionDetails.controls.align.setValue(2);
		if (this.selectedPaymentMethod == PaymentMethods.CardOnFile) {
			this.transitionDetails.controls.amounttendered.setValue(
				this.localization.localizeCurrency(SaleResponse.paymentManagerResponse.amount.authorisedAmount)
			);
		} else {
			this.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(SaleResponse.amount.authorisedAmount));
		}
	}

	async FormOrderSummary() {
		if (this._ss.selectedProducts && this._ss.selectedProducts.length > 0) {
			if (this._ss.selectedPayeeId && this._ss.selectedPayeeId > 0) {
				this.beforeClinet = false;
				this._sbs.PaymentBeforeClinet = false;
				this.GetPayeeInfo(this._ss.selectedPayeeId);
			} else {
				this.beforeClinet = true;
				this._sbs.PaymentBeforeClinet = true;
			}

			this.GridItems = [];
			let _thisref = this;
			this.GridItems = this._ss.selectedProducts.map(function (elem, index) {
				var disableAction =
					(elem.MultiPack && elem.ProductPrice < 0) ||
					_thisref._ss.cancelFlag ||
					(_thisref._ss.depositFlag && _thisref._ss.ProductId != GlobalConst.Product.SPA) ||
					elem.rainCheckItemId > 0 ||
					elem.isGiftCardItem;

				const tempInactive = elem.Gratuity &&
					(elem.Gratuity.length > 0 && elem.Gratuity.find(r => r.gratuity > 0))
					? true
					: false;
				const tempCommissionInactive = elem.Commission && elem.Commission.length > 0 ? true : false;

				// Activities - retail integration changes
				const tempServiceChargeIsActive = elem.isModificationRestricted ? false : elem.ServiceCharge &&
					(elem.ServiceCharge.length > 0 && elem.ServiceCharge.find(r => r.ServiceCharge > 0))
					? true
					: false;
				return {
					element: elem,
					id: index,
					itemId: elem.ItemId,
					product_id: index,
					itemName: elem.ProductName,
					unitprice: elem.ProductPrice,
					quantity: elem.Noofitems,
					isModificationRestricted: elem.isModificationRestricted,
					isPackagedItem: elem.isPackagedItem,
					packageItemId: elem.PackageItemId,
					isGroupingKey: elem.isGroupingKey,
					ischecked: false,
					PackageGroupId: elem.PackageGroupId,
					Gratuity: elem.Gratuity,
					ServiceCharge: elem.ServiceCharge,
					isCommissionRequired: elem.isCommissionRequired,
					isOpenPricedItem: elem.isOpenPricedItem,
					charges: [
						{
							id: 1,
							name:
								elem.Commission && elem.Commission.length > 0
									? _thisref.localization.captions.shop.EditCommissionGratuity
									: _thisref.localization.captions.shop.AddCommissionGratuity,
							alias: 'commission',
							isactive: disableAction ? false : true,
							isApplied: elem.Commission && elem.Commission.length > 0 ? true : false,
							commissionable: elem.isCommissionable
						},
						{
							id: 2,
							name: _thisref.localization.captions.shop.RemoveGratuity,
							alias: 'gratuity',
							isactive: disableAction
								? false
								: tempInactive,
							isApplied:
								elem.Gratuity &&
									(elem.Gratuity.length > 0 && elem.Gratuity.find(r => r.gratuity > 0))
									? true
									: false
						},
						{
							id: 3,
							name: _thisref.localization.captions.shop.RemoveCommission,
							alias: 'commission',
							isactive: disableAction
								? false
								: tempCommissionInactive,
							isApplied: elem.Commission && elem.Commission.length > 0 ? true : false
						},
						{
							id: 4,
							name: _thisref.localization.captions.shop.RemoveServicecharge,
							alias: 'servicecharge',
							isactive: disableAction
								? false
								: tempServiceChargeIsActive,
							isApplied:
								elem.ServiceCharge &&
									(elem.ServiceCharge.length > 0 && elem.ServiceCharge.find(r => r.ServiceCharge > 0))
									? true
									: false
						},
						{
							id: 5,
							name: _thisref.localization.captions.shop.RemoveDiscount,
							alias: 'percent',
							isactive: elem.Discount || elem.DiscountPercentage != 0 ? true : false,
							isApplied: elem.Discount || elem.DiscountPercentage != 0 ? true : false
						}
					],
					retailItemType: elem.retailItemType,
					playerName: elem.playerName,
					isGiftCardItem: elem.isGiftCardItem,
					lineNumber: elem.LineNumber,
					costPrice: elem.costPrice,
					marginPercentage: elem.marginPercentage,
					clientName: elem.clientName
				};
			});
			if (this.PropertyInfo.UseRetailInterface) {
				this.GridItems.forEach((element) => {
					element.charges = element.charges.filter((r) => r.id != 5);
				});
			}
			this._ss.isReturnWithoutTicket = this._ss.selectedProducts.some((x) => x.isReturn);
			this.showReturnReason = (this._ss.selectedProducts && this._ss.selectedProducts.every((x) => !x.rainCheckItemId));
			this.disableRemove = this.GridItems.every(x => x.isGiftCardItem) || (this.IsRevenueItemIncluded && this.GridItems.map((r) => r.element).every((x) => x.ItemType == RetailItemType.RevenueItem || x.ItemType == RetailItemType.Deposit));
		} else {
			this.utils.RedirectTo(RedirectToModules.retail);
		}


	}

	CancelOrder(): void {
		this.initiateCancel();
	}

	openVoidTxnPopup() {
		return this.dialog.open(AlertMessagePopupComponent, {
			width: '550px',
			height: '300px',
			hasBackdrop: true,
			panelClass: 'small-popup',
			data: {
				headername: this.oCaptions.common.Warning,
				headerIcon: 'icon-warning-icon',
				headerMessage: this.oCaptions.shop.CorrectionTransactionMsg,
				buttonName: this.oCaptions.common.Yes,
				noButton: true,
				noButtonName: this.oCaptions.common.No,
				type: 'message'
			},
			disableClose: true
		});
	}

	openCardEntryModeSelectionPopup() {
		return this.dialog.open(CardEntryModeComponent, {
			width: '550px',
			height: '300px',
			hasBackdrop: true,
			panelClass: 'small-popup',
			data: {
				headername: this.oCaptions.common.Warning,
				headerIcon: 'icon-warning-icon',
				headerMessage: this.oCaptions.shop.CorrectionTransactionMsg,
				buttonName: this.oCaptions.common.Yes,
				noButton: true,
				noButtonName: this.oCaptions.common.No,
				type: 'message'
			},
			disableClose: true
		});
	}

	CancelEmit() {
		if (this.CreateRetailItemResponse && this.CreateRetailItemResponse?.transactionDetails[0] &&
			this._ss.SelectedPlayers && this._ss.SelectedPlayers.length > 0
			&& !this._ss.reOpenTransaction) {
			const BeforeSubscribelogType = "Retail:CancelTransactionEventEmitBeforeSubscribeFromOrderSummary";
			const AfterSubscribelogType = "Retail:CancelTransactionEventEmitAfterSubscribeFromOrderSummary";
			let eventParams: RetailEventParameters<VoidEventModel> = {
				data: {
					transactionId: this.CreateRetailItemResponse.transactionDetails[0].transactionId,
					isDeposit: false,
					ticketNumber: this.CreateRetailItemResponse.transactionData.retailTicketNumber
				},
				eventType: RetailEventType.Cancel
			};
			this._ams.CreateTransLog(eventParams?.data?.transactionId, BeforeSubscribelogType, { newValue: eventParams });
			retailPublisher.publishEvent(eventParams);
			this._ams.CreateTransLog(eventParams?.data?.transactionId, AfterSubscribelogType, { newValue: eventParams });
		}
	}

	ResetShopObj(): void {
		this.ReleaseLock();
		if (this._ss.isTaxRecalculated) {
			this._ss.Ticket = this._ss.OriginalTicket;
			this._ss.isTaxRecalculated = false;
			this.updateOrderSummary();
		}
		if (
			((!this._ss.settleOpenTransaction && !this._ss.reOpenTransaction) || this.correcttransaction) &&
			this.CreateRetailItemResponse &&
			this.CreateRetailItemResponse.transactionDetails &&
			this.CreateRetailItemResponse.transactionDetails[0]
		) {
			if (this.SettlementHistory && this.SettlementHistory.length > 0) {
				this.OpenDeleteTransactionConfirmationDialog(this.CreateRetailItemResponse.transactionDetails[0].transactionId);
				return;
			} else {
				this.transactionService.DeleteTransactionRecord(
					this.CreateRetailItemResponse.transactionDetails[0].transactionId
				);
				this._ams.labelRecords = [];
				this._ams.paymentProcessing = false;
				this._ss.ClearServiceObj();
				this.ResetShopObjActivities(); // Activities - retail integration changes
				//this.utils.RedirectTo(RedirectToModules.retail);
				return;
			}
		}
		this._ams.paymentProcessing = false;
		this._ams.labelRecords = [];
		this._ss.ClearServiceObj();
		this.ResetShopObjActivities(); // Activities - retail integration changes
			//	this.utils.RedirectTo(RedirectToModules.retail);
		this.selectedClientId = 0;
		this.selectedClientGuid = null;
		this.payeeInfo = null;
		this.FetchActiveFolioInvoiceNumberForPayee();
		this._retailService.selectedappointments = [];
	}

	async ReleaseLock() {
		if (this.playerIds && this.playerIds.length > 0) {
			await RetailDataAwaiters.ReleasePlayerTempHold(this.playerIds);
		} else if (this.appointmentIds && this.appointmentIds.length > 0) {
			await RetailDataAwaiters.ReleaseAppointmentLock(this.appointmentIds);
		}
	}

	CancelAllPaymentCallback(result) {
		if (result) {
			this.SettlementHistory.forEach(x => {
				if (!x.isReversed) {
					this._sbs.CheckAndReversePayment(x, this);
					x.isReversed = true;
				}
			})
			if (this.SettlementHistory.some(x => x.isReversed
				&& x.paymentMethodId == PaymentMethods.RainCheck && x.additionalDetails != null)) {
				const rainCheckTrans = this.SettlementHistory.filter(x => x.paymentMethodId == PaymentMethods.RainCheck);
				const raincheckInfo = rainCheckTrans.map(r => r.additionalDetails as RainCheckIssue);
				if (raincheckInfo && raincheckInfo.length > 0 && raincheckInfo.some(x => x.id > 0)) {
					const raincheckIds = raincheckInfo.filter(x => x.id > 0).map(x => x.id);
					RetailDataAwaiters.UnRedeemRainChecks(raincheckIds);
				}
			}
			this.ShowSettlemtHist = this.SettlementHistory.some(x => !x.isReversed);
			this._ams.paymentProcessing = false;
			this.CalculateSettledAmount();
		}

	}

	OpenDeleteTransactionConfirmationDialog(transactionId) {
		this.utils.ShowErrorMessage(
			this.localization.captions.common.Warning,
			this.ShopCaptions.ConfirmDeleteTransactionMsg,
			GlobalConst.ButtonType.YesNo,
			this.TransactionDeleteCallBack.bind(this),
			[transactionId]
		);
	}

	TransactionDeleteCallBack(result, extraParam) {
		if (result.toLowerCase() == GlobalConst.ButtonOptions.Yes.toLowerCase() && extraParam[0]) {
			this.transactionService.DeleteTransactionRecord(extraParam[0]);
			this._ss.selectedProducts = [];
			this._ams.paymentProcessing = false;
			this.ResetSummaryData();
			if (this._ss.isFromSncBeo) {
				this._ss.ClearServiceObj();
				return;
			}
			this.utils.RedirectTo(RedirectToModules.retail);
		}
	}
	editQuantity(type: string, index: number, orderSummary: any) {
		this._ss.PayButtonDisable.emit(true);

		if (this.timer) {

			clearTimeout(this.timer); //cancel the previous timer.

			this.timer = null;

		}

		this.timer = setTimeout(this.EditItems.bind(this), 1000, type, index, orderSummary);
	}

	async EditItems(type: string, index: number, orderSummary: any) {
		let value = orderSummary.quantity;
		switch (type) {
			case 'quantity':
				const elemArr = [];
				value = value == '' ? 0 : value;
				if (this._ss.selectedProducts.length > 0) {

					const currentProd = this.PropertyInfo.UseRetailInterface ?
						this._ss.selectedProducts.find((x) => x.ItemId == orderSummary.itemId && x.LineNumber == orderSummary.element.LineNumber)
						: this._ss.selectedProducts.filter(x => !x.isGroupingKey).find((x) => x.ItemId == orderSummary.itemId
							&& x.LineNumber == orderSummary.element.LineNumber);

					currentProd.Noofitems = value;
					this.RecalculateSCGTOnQtyUpdate(currentProd);
					if (
						this._ss.selectedProducts[index].Commission &&
						this._ss.selectedProducts[index].Commission.length > 0
					) {
						this._ss.selectedProducts[index].Commission.forEach((x) => (x.Quantity = value));
					}
					if (this.PropertyInfo.UseRetailInterface) {
						for (const gridItem of this.GridItems) {
							if (
								gridItem.packageItemId == orderSummary.element.PackageItemId &&
								gridItem.packageItemId > 0 &&
								gridItem.PackageGroupId > 0 &&
								gridItem.PackageGroupId == orderSummary.element.PackageGroupId
							) {
								gridItem.quantity = value;
								const indexToBeChanged = this._ss.selectedProducts.indexOf(
									this._ss.selectedProducts.filter(
										(x) =>
											x.ItemId == gridItem.itemId &&
											x.PackageGroupId == gridItem.PackageGroupId &&
											x.PackageItemId == gridItem.packageItemId
									)[0]
								);
								this._ss.selectedProducts[indexToBeChanged].Noofitems = value;
								elemArr.push(gridItem);
							}
						}
					} else {
						for (const gridItem of this.GridItems.filter(x => !x.isGroupingKey)) {
							if (gridItem.packageItemId == orderSummary.element.PackageItemId &&
								gridItem.packageItemId > 0 && gridItem.PackageGroupId > 0 &&
								gridItem.PackageGroupId == orderSummary.element.PackageGroupId) {
								gridItem.quantity = value;
								const indexToBeChanged = this._ss.selectedProducts.indexOf(
									this._ss.selectedProducts.filter((x) =>
										!x.isGroupingKey &&
										x.LineNumber == gridItem.lineNumber &&
										x.ItemId == gridItem.itemId &&
										x.PackageGroupId == gridItem.PackageGroupId &&
										x.PackageItemId == gridItem.packageItemId)[0]
								);
								console.log(indexToBeChanged);
								this._ss.selectedProducts[indexToBeChanged].Noofitems = value;
								elemArr.push(gridItem);
							}
						}
					}
					if (elemArr.length == 0) {
						elemArr.push(orderSummary);
					}
					if (!this.PropertyInfo.UseRetailInterface) {
						this._ams.loaderEnable.next(this.ShopCaptions.UpdateItemQuantity);

						for (const elemArrItem of elemArr.filter(f => !f.isGroupingKey || f.isPackagedItem)) {
							const elementIndex: number = elemArrItem.element.LineNumber;
							await this.UpdateDiscountOnQuantityChange(elemArrItem, elementIndex, true);
						}
						await this.InvokeEditQuantity();

						setTimeout(() => {
							this._ams.loaderEnable.next('');
						}, 1000);
					} else {
						await this.totalPrice();
					}
				}
				break;
			case 'remove':
				this._ss.selectedProducts.splice(index, 1);
				this.showRainCheckIG();
				break;
			case 'removeall':
				this._ss.selectedProducts = [];
				this.showRainCheckIG();
				break;
			default:
				break;
		}
	}

	GetServiceCallDesc(): string {
		if (this.isUpdateTransaction()) {
			return 'UpdateRetailTransactionData';
		} else if (this._ss.correctTransaction && this._ss.voidedTransactionId > 0) {
			return 'CorrectRetailTransaction';
		} else if (this.isEditTransaction) {
			return 'UpdateRetailTransaction';
		} else if (this._ss.reOpenTransaction) {
			return 'ReOpenRetailTransaction';
		} else if (this._ss.settleOpenTransaction) {
			return 'SettleRetailTransaction';
		} else {
			return 'CreateRetailTransaction';
		}
	}

	GetCallType(): HttpMethod {
		if (this._ss.correctTransaction && this._ss.voidedTransactionId > 0) {
			return HttpMethod.Post;
		} else if (this.isEditTransaction || this.isUpdateTransaction()) {
			return HttpMethod.Put;
		} else if (this._ss.reOpenTransaction) {
			return HttpMethod.Put;
		} else if (this._ss.settleOpenTransaction) {
			return HttpMethod.Put;
		} else {
			return HttpMethod.Post;
		}
	}
	private async getReceiptConfiguration() {
		let response: BaseResponse<ReceiptConfiguration> = await this.http.CallApiAsync<ReceiptConfiguration>({
			callDesc: "GetReceiptConfigurationByOutletId",
			host: GlobalConst.Host.retailManagement,
			method: HttpMethod.Get,
			uriParams: { id: this._ss.SelectedOutletId },
			showError: false,
		});
		if (response) {
			this.setRetailReceipt(response);
		}

	}

	private setRetailReceipt(response) {
		let receiptResponse: any = response.result;
		if (this._ss.ProductId == GlobalConst.Product.SNC) {
			this.receiptService.receiptConfiguration = receiptResponse;
		}
		if (receiptResponse != null) {
			this.transitionDetails.controls.numberOfReceipts.setValue(receiptResponse.numberOfReceipts);
			this.numberOfReceipts = receiptResponse.numberOfReceipts;
			this.displayDiscount = receiptResponse.displayDiscount;
			this.addSecondLine = receiptResponse.addSecondLine;
			this.displayOnlyPackageItem = receiptResponse.displayOnlyPackageItem;
			this.suppressClerkId = receiptResponse.suppressClerkId;
			this.displayPackageDescription = receiptResponse.displayPackageDescription;
			this.displayPackagePrice = receiptResponse.displayPackagePrice;
			this.displayPackageAppointmentID = receiptResponse.displayPackageAppointmentID;
			this.displayPackageStaffCode = receiptResponse.displayPackageStaffCode;
			this.serviceChargeGratuityDisplay = receiptResponse.serviceChargeGratuityDisplay;
			this.gratuityLine = receiptResponse.gratuityLine;
			this.receiptNote = receiptResponse.receiptNote;
			this.receiptClerkName = receiptResponse.clerkIdPrintValue;
			this.receiptPrintBy = receiptResponse.printedByPrintValue;
		}
	}

	// reportParams: ReportAPIOptions;
	async printReceipt(
		result,
		isPendingSettlement?: boolean,
		isReturnWithoutTicket?: boolean,
		isMemberTransaction?: boolean,
		availableRounds?: number,
		isPreSettlement?: boolean
	) {
		let transactionId: number = result.id;
		let ticketNumber: string = result.transactionData.ticketNumber;
		let clientName: string = this.clientInfo ? this.clientInfo.name : '';
		let isHotelGuest: boolean = false;
		let isHotelSpaGuest: boolean = false;
		const memberCardNumber = this._ss.memberCardNumber ? this._ss.memberCardNumber : "0";
		isHotelGuest = (this.clientInfo?.playerCategoryId == 2);
		isHotelSpaGuest = (this.clientInfo?.guesttype == 3);
		const receiptComment: string = this.transitionDetails.controls.receiptComment.value;
		let receiptType: GlobalConst.ReceiptType;
		receiptType = isReturnWithoutTicket ? GlobalConst.ReceiptType.returnExchange : (isPreSettlement ? GlobalConst.ReceiptType.preSettlement : GlobalConst.ReceiptType.sales);
		let salesDate = this.utils.getDate(result.transactionData.transactionDate);
		const memberPayment = this.MapSettlementHistForMember(this.SettlementHistory.filter(x => x.paymentMethodId == PaymentMethods.ARPost || x.parentTenderId == PaymentMethods.ARPost));
		let observable = this.isSigCapLogicCompleted.asObservable();
		observable.subscribe((res) => {
			if (res[0] === true) {
				this.SendNotification(ticketNumber,
					this._ss.SelectedOutletId,
					receiptType,
					transactionId,
					clientName,
					undefined,
					isPendingSettlement,
					isReturnWithoutTicket,
					0,
					null,
					false,
					isMemberTransaction,
					this._ss.availableRounds,
					receiptComment,
					salesDate);
			}

		});
		if (this._ss.isFromDayEnd && (!this._ss.AllOutlets || this._ss.AllOutlets.length <= 0)) {
			await this._ss.SetAllOutletsData();
			this._ss.AllOutlets = this._ss.outlets;
		}
		let outlet = this._ss.selectedOutlet ? this._ss.selectedOutlet : this._ss.AllOutlets.find(x => x.subPropertyID == this._ss.SelectedOutletId);
		let dmConfig = await this.DMbusiness.GetDMConfigSession();
		// Posting Receipt to DM for auto print receipt off and receipt not required
		if (dmConfig && dmConfig.postCheckReceiptToDM && ((outlet && !outlet.autoReceipt) || !this._sbs.requireReceipts)) {
			await this.receiptService.PostReceiptToDM(ticketNumber, this._ss.SelectedOutletId, receiptType, transactionId, clientName, undefined
				, availableRounds, isHotelGuest, isHotelSpaGuest);
		}

		if (outlet && !outlet.autoReceipt) {
			if (this.isSigCapLogicCompleted) {
				this.isSigCapLogicCompleted.next([true, '']);
			}
			if (this.isProceedAutoCloseTransaction) {
				this.isProceedAutoCloseTransaction.next(true);
			}
			return false;
		}
		if (this.playerIds.length > 0) {
			RetailDataAwaiters.PrintAgreement(transactionId, this.playerIds);
		}
		else {
			let playersid: number[] = [];
			this.clientInfo ? playersid.push(this.clientInfo.id) : playersid.push(0)
			RetailDataAwaiters.PrintAgreement(transactionId, playersid);
		}



		let paymentInfo = this.SettlementHistory.filter(x => GiftCardMethods.includes(x.paymentMethodId));
		let updatedGiftCardBalance: string;
		if (paymentInfo) {
			// let	giftCards = paymentInfo.map(r => r.additionalDetails as GiftcardSearchResult);
			paymentInfo.forEach(element => {
				const additionalDetails = element.additionalDetails as GiftcardSearchResult;
				if (additionalDetails) {
					let data: GiftCardBalance = {
						cardType: additionalDetails.cardType,
						UpdatedBalance: additionalDetails.UpdatedBalance,
						paymentReferenceId: element.paymentReferenceId
					}
					this.giftcardBalance.push(data);
				}

			});
			updatedGiftCardBalance = JSON.stringify(this.giftcardBalance);
		}

		//Feature 57221,55423: Cart, Shoe, Club Agreement ,Retail sale Chit , Retail sale chit Not Implemented
		//Uncomment to Print Sale Chit
		//	this.printRetailSalechit(transactionId,false,PrintType.Html);
		await this.receiptService.GenerateReceipt(
			ticketNumber,
			this._ss.SelectedOutletId,
			receiptType,
			transactionId,
			memberCardNumber,
			clientName,
			undefined,
			isPendingSettlement,
			isReturnWithoutTicket,
			0,
			null,
			false,
			isMemberTransaction,
			this._ss.availableRounds,
			receiptComment,
			salesDate,
			this.quickIdUser,
			updatedGiftCardBalance,
			this._sbs.noOfReceipts,
			this._sbs.requireReceipts,
			memberPayment,
			this.isSigCapLogicCompleted,
			this._sbs.requireSignatureCapture,
			this.isProceedAutoCloseTransaction,
			isHotelGuest,
			isHotelSpaGuest
		);

	}

	printRetailSalechit(transactionId, isRePrint, printType) {
		let retailItems = this._ss.SelectedRetailItemDetails.filter(t => t.retailItemDetail.isPrintRetailSaleChit);
		this._SaleChit.getRetailSaleChit(transactionId, retailItems.map(r => r.id), isRePrint, printType);
	}
	async printCaddyShackData(
		result,
		isReturnWithoutTicket?: boolean
	) {
		let transactionId: number = result.id;
		let ticketNumber: string = result.transactionData.ticketNumber;
		this._caddyShackBusinessService.GenerateCaddyShack(
			ticketNumber,
			this._ss.SelectedOutletId,
			isReturnWithoutTicket ? GlobalConst.ReceiptType.returnExchange : GlobalConst.ReceiptType.sales,
			transactionId,
			null,
			null,
			this.caddyshackcomment
		);
	}

	async SendForPayment() {
		if (!this.CheckIfReturnReasonIsAvailableForReturnWithOutTicket()) {
			setTimeout(() => { this.isCreateTransactionValidationError = true; }, 0);
			return;
		}
		this.CreateTransaction();
		this.commentDisable = true;
	}

	async ProceedTransaction() {
		this.isCreateTransactionValidationError = false;
		//Bug- 34561 Fix
		//if (!this.CheckButtonState()) { return; } -- Commenting this, since same logic is moved to right panel

		if (this._featureFlagInfo.AllowAccrualPoints && this._featureFlagInfo.CMSRequirePin && this.payeeInfo?.patronId) {
			this.openCmsPinPopup(this.CreateTransaction.bind(this));
			return;
		}

		if (this.selectedPaymentMethod === PaymentMethods.PendingSettlement) {
			this.CreateTransaction();
			return;
		}

		if (!this.CheckIfReturnReasonIsAvailableForReturnWithOutTicket()) {
			setTimeout(() => { this.isCreateTransactionValidationError = true; }, 0);
			return;
		}

		if (!this.CheckIfGuestIsSelected()) {
			setTimeout(() => { this.isCreateTransactionValidationError = true; }, 0);
			return;
		}
		if (this.CheckIfGuestIsRequired() === true) {
			setTimeout(() => { this.isCreateTransactionValidationError = true; }, 0);
			return;
		}

		this.MakeSale();
	}

	openCmsPinPopup(callback?: () => void) {
		let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
			height: 'auto',
			width: '30%',
			data: {
				headername: this.ShopCaptions.CMS.CMSPinHeader,
				closebool: true,
				Type: 'CMSPin',
				buttonType: GlobalConst.ButtonType.OkCancel
			},
			panelClass: 'small-popup',
			disableClose: true,
			hasBackdrop: true
		});
		let subscription = dialogRef.afterClosed().subscribe((res) => {
			if (res.res == 'ok' && res.labelPosition) {
				if (callback) {
					this.cmsAccrualPin = res.labelPosition;
					callback();
				}
			} else {
				setTimeout(() => { this.isCreateTransactionValidationError = true; }, 0);
			}
		});
	}

	CheckIfGuestIsRequired(): boolean {
		if (this.selectedClientId === 0 && this._ss.selectedProducts &&
			this._ss.selectedProducts.filter(x => !x.isReturn && x.isRequestName).length > 0) {
			this.utils.showAlert(this.localization.captions.shop.RequiredGuest, AlertType.Error, GlobalConst.ButtonType.Ok);
			return true;
		}
		return false;
	}

	CheckIfReturnReasonIsAvailableForReturnWithOutTicket(): boolean {
		if (this._ss.isReturnWithoutTicket && this.transitionDetails.controls.returnReason.value == '' && this.showReturnReason) {
			//Check for return Reason
			this.transitionDetails.controls.returnReason.updateValueAndValidity();
			this.transitionDetails.controls.returnReason.markAsTouched();
			this.returnReasonElement.nativeElement.focus();
			return false;
		}
		return true;
	}

	CheckIfRoomNoIsAvailableForRoomcharge(): boolean {
		if (
			this.selectedPaymentMethod == PaymentMethods.RoomCharge && !this.selectedGuestRoom
		) {
			this.transitionDetails.controls.roomnumber.updateValueAndValidity();
			this.transitionDetails.controls.roomnumber.markAsTouched();
			return false;
		}
		return true;
	}

	CheckIfGuestIsSelected(): boolean {
		if (
			this.selectedPaymentMethod == PaymentMethods.RoomCharge &&
			this.GuestAccounts.length > 1 &&
			this.transitionDetails.controls.selectedGuest.value == ''
		) {
			this.transitionDetails.controls.selectedGuest.updateValueAndValidity();
			this.transitionDetails.controls.selectedGuest.markAsTouched();
			return false;
		}
		return true;
	}

	MapsettlementHistory(settlementHistory: PaymentHistory[]) {
		this.SettlementHistoryRequest = [...settlementHistory];
		this.SettlementHistory = settlementHistory;
	}

	PerformCloseTransactionUpdate() {
		const body = {
			transactionId: this.CreateRetailItemResponse.transactionDetails[0].transactionId,
			SettlementHistory: JSON.stringify(this.SettlementHistoryRequest)
		};
		this.transactionService.InvokeServiceCallAsync("CreateSettlementHistory", GlobalConst.Host.retailPOS, HttpMethod.Post, '', body);
	}

	async PerformCloseTransaction() {
		//Payment Success dialog
		this.isReturnWithoutTicketForReceipt = this._ss.isReturnWithoutTicket;

		/* COMMENTED TO REDUCE THE NO OF CLICKS PER TRANSACTION
		if (this.IsRefundFlow) {
			const paymentCloseText = this.localization.captions.shop.RefundSuccessMsg;
			const dataObj = {
				text: paymentCloseText,
				buttonname: this.localization.captions.common.Close,
				headertext: this.localization.captions.shop.Refund,
				icon: 'icon-success-icon'
			};
			this.roomOpenDialog(dataObj);
		} else {
			const paymentCloseText = this.localization.replacePlaceholders(
				this.ShopCaptions.TicketCreationSuccess,
				['checkNumber'],
				[this.CreateRetailItemResponse.transactionData.ticketNumber]
			);
			const dataObj = {
				text: paymentCloseText,
				buttonname: this.localization.captions.common.Close,
				headertext: this.ShopCaptions.PaymentComplete,
				icon: 'icon-success-icon'
			};
			this.roomOpenDialog(dataObj);
		}*/
		this.tempSelectedProducts = this._ss.selectedProducts;
		this.HandlePostPaymentRedirection();
		//Calling API For calculating commission

	}

	ResetSummaryData() {
		this._ss.selectedPayeeId = 0;
		this._ams.paymentProcessing = false;
		this._ss.selectedProducts = [];
		this._ams.labelRecords = [];
		this.payeeInfo = null;
		this.FetchActiveFolioInvoiceNumberForPayee();
		this._ss.SelectedItemDiscount = [];
		this._ss.memberCardNumber = "0";
		this._ss.memberDiscountType = "";
		this._ss.ResetShopObject();
		this._memberService.RateTypes = [];
		this._ss.totalAmountWithoutTax = 0;
		this._ss.totalTaxExemptRatio = 0;
		this._ss.taxExemptRatio = 0;
		this._ss.isTaxRecalculated = false;
		this._ss.remainingAmount = 0;
		this._ss.CreateItemResponse = null;
		this._ss.selectedTaxExemptPayment = {
			id: 0,
			paymentTypeId: 0,
			paymentMethodId: 0,
			postTypeId: 0,
		};
	}

	async Adddeposit(transactionData: any) {
		this.depositArray.forEach((deposit, index) => {
			deposit.depositTransactionId = transactionData?.id;
			deposit.depositTransactionDetailId = (index < transactionData?.transactionDetails?.length) ? transactionData.transactionDetails[index].id : 0;
			deposit.id = 0;
			deposit.status = this.PropertyInfo.UseRetailInterface ? DepositTransactionStatus.Pending
				: DepositTransactionStatus.Closed
		});
		let eventData: RetailEventParameters<DepositEventModel[]> = {
			data: this.depositArray,
			eventType: RetailEventType.Deposit
		};
		retailPublisher.publishEvent(eventData);
		this.dialog.closeAll();
		this._ss.excludeTaxForDeposit = false;
	}

	depositCallBack(result) {
		if (result) {
			this.dialog.closeAll();
			this.utils.RedirectTo(RedirectToModules.TeeTime);
		}
	}


	isRedeemPointPayment(payMethods: PaymentHistory[]): boolean {
		var isRedeemPointPaymentFound = false;
		var pointRedeemPayment = payMethods.filter((pay) => {
			pay.paymentMethodId = PaymentMethods.RedeemPoint;
		});
		if (pointRedeemPayment && pointRedeemPayment.length > 0) {
			isRedeemPointPaymentFound = true;
		}
		return isRedeemPointPaymentFound;
	}

	HandleAfterPayment(): boolean {
		this.quickIdUser = sessionStorage.getItem('quickIdUser');
		var isReceiptPrinted: boolean;
		this.commentDisable = true;
		const playerTransEntryReq = this.settleOpenTransaction || this.reOpenTransaction || this._ss.depositFlag;
		const playerTransDetailEntryReq = (!this.settleOpenTransaction && !this.reOpenTransaction) || (this.correcttransaction);
		var isMemberTransaction = false;
		this.isReturnWithoutTicketForReceipt = this._ss.isReturnWithoutTicket;
		this.transactionService.CalculateCommission(
			this.CreateRetailItemResponse.transactionDetails[0].transactionId,
			this.utils.convertDateFormat(this.PropertyInfo.CurrentDate)
		);
		if (this._ss.selectedProducts.some(x => x.isGiftCardItem)) {
			this.PerformGiftcardActionsPostSettlement();
		}
		if (this._ss.selectedProducts.some(x => x.ClientMultiPackId > 0 && x.MultiPack)) {
			this.PerformRetailMultiPackRedemption(this._ss.selectedProducts);
		}
		if (this._ss.memberCardNumber != "0" && this._ss.ProductId == GlobalConst.Product.GOLF && this.totalAmount > 0) {
			isMemberTransaction = true;
		}
		if (this._ss.cancelFlag) {
			this.UpdateCenceledDeposit(this.CreateRetailItemResponse.id);
			return false;
		} else if (this._ss.depositFlag) {
			this.Adddeposit(this.CreateRetailItemResponse);
			let taskList = this._teetimePaymentBusiness.ProcessPlayerTransaction(
				this,
				PlayerPaymentstatus.unPaid,
				PlayerTransactionStatus.Closed,
				this.getSaleAmt(true) - this.remainingAmount,
				playerTransEntryReq,
				playerTransDetailEntryReq
			);
			Promise.all(taskList);
			this.ProcessPartyTransaction(this) // Activities - retail integration changes
			this.printReceipt(this.CreateRetailItemResponse);
			isReceiptPrinted = true;
			this._ss.SelectedPlayers = [];
			return false;
		} else if (this._ss.isAppointmentCheckOut) {
			if (this.CheckOutAppointmentsList && this.CheckOutAppointmentsList.length > 0) {
				this._ams.CreateTransLog(this.CreateRetailItemResponse.id, "EventEmittedForAppointmentCheckout", { newValue: this.CheckOutAppointmentsList });
				this._ss.CheckOutCallback(this.CreateRetailItemResponse, _.cloneDeep(this.CheckOutAppointmentsList));
				this.CheckOutAppointmentsList = [];
				this.ReleaseLock();
				this._ss.selectedappointments = [];
				this._retailService.selectedappointments = [];
			}
			this.printReceipt(this.CreateRetailItemResponse, false, false, isMemberTransaction, this._ss.availableRounds);
			isReceiptPrinted = true;
			if (!this._ss.isFromDayEnd) {
				return false;
			}
		} else if (
			this._ss.isFromUnpaidPlayer ||
			this._ss.isFromEditTeeTime ||
			this._ss.isFromDayEnd ||
			this.settleOpenTransaction ||
			this.reOpenTransaction ||
			this._ss.isFromGiftCard
		) {
			if ((this._ss.SelectedPlayers && this._ss.SelectedPlayers.length > 0) ||
				this.SettleTransactionDetail.playerTransactionDetails.length > 0) {
				isReceiptPrinted = true;
				this._ams.CreateTransLog(this.CreateRetailItemResponse.id, "EventEmittedForUpdateTransactionsWithPlayerStatus", { newValue: this._ss.SelectedPlayers });
				this._teetimePaymentBusiness.UpdatePlayerPaymentStatus(this, playerTransDetailEntryReq, isMemberTransaction).then(() => {
					return false;
				});
			}

		}
		else{
				// Activities - retail integration changes
				if(this._ss.isOperationFromActivities)
				this.ProcessPartyTransaction(this)
		}
		if (this._ss.isFromDayEnd) {
			this.printReceipt(
				this.CreateRetailItemResponse,
				false,
				this.isReturnWithoutTicketForReceipt,
				isMemberTransaction,
				this._ss.availableRounds
			);
			isReceiptPrinted = true;
			return false;
		}
		if (this._ss.isFromRetailDashBoard) {
			if (this.printCaddyShack) {
				this.printCaddyShackData(this.CreateRetailItemResponse, this.isReturnWithoutTicketForReceipt);
			}
			this.printReceipt(this.CreateRetailItemResponse);
			return false;
		}

		const checkNumber = this._ss && this._ss.Ticket && this._ss.Ticket.checkData ? this._ss.Ticket.checkData.checkNumber : "";
		if (this._ss.isFromSncBeo && checkNumber != '') {
			RetailDataAwaiters.UpdateBeoCheck(checkNumber, this.CreateRetailItemResponse.transactionData.ticketNumber, this.CreateRetailItemResponse.id, true);
		}
		else if (this.IsRevenueItemIncluded && this._ss.settleOpenTransaction && checkNumber != '') {
			RetailDataAwaiters.UpdateBeoCheck(checkNumber, this.CreateRetailItemResponse.transactionData.ticketNumber, this.CreateRetailItemResponse.id, false);
		}
		else if (!this.IsRevenueItemIncluded && this._ss.IsbeoItemCorrected && this.correcttransaction && this.oldTransactionId > 0 && checkNumber != '') {
			RetailDataAwaiters.UpdateBeoCheckStatus(this.oldTransactionId);
			this._ss.IsbeoItemCorrected = false;
		}
		else if (this.IsRevenueItemIncluded && this.correcttransaction && this.oldTransactionId > 0) {
			RetailDataAwaiters.UpdateBeoCheck(checkNumber, this.CreateRetailItemResponse.transactionData.ticketNumber, this.CreateRetailItemResponse.id, false);
		}
		else if (this.IsRevenueItemIncluded && this._ss.reOpenTransaction && this.oldTransactionId > 0 && !this._ss.IsbeoItemReopened && checkNumber != '') {
			RetailDataAwaiters.UpdateBeoCheck(checkNumber, this.CreateRetailItemResponse.transactionData.ticketNumber, this.CreateRetailItemResponse.id, false);
		}
		else if (this.CreateRetailItemResponse.transactionData && this.CreateRetailItemResponse.transactionData.guestId && this._ss.ProductId == this.ProductSNCId) {
			RetailDataAwaiters.CreateBookingPost(this.CreateRetailItemResponse.transactionData.guestId, this.CreateRetailItemResponse.id);
		}

		if (this.printCaddyShack) {
			this.printCaddyShackData(this.CreateRetailItemResponse, this.isReturnWithoutTicketForReceipt);
		}

		if (this._ss.selectedProducts.some(x => x.isGiftCardItem)) {
			isReceiptPrinted = true;
		}

		if (!isReceiptPrinted) {

			this.printReceipt(
				this.CreateRetailItemResponse,
				false,
				this.isReturnWithoutTicketForReceipt,
				isMemberTransaction,
				this._ss.availableRounds
			);
		}
		return true;
	}

	isUpdateTransaction() {
		return this.CreateRetailItemResponse && this.SettlementHistoryRequest && this.SettlementHistoryRequest.length > 0 && this.SettlementHistoryRequest.length == this.SettlementHistoryRequest.filter(x => x.isReversed).length
	}

	MakeSale() {
		if (!this.CreateRetailItemResponse) {
			//Create the retail transaction prior to get the transaction ID for TryPay request.
			this.CreateTransaction();
		}

		//Only If Split Payment Or Partial Payment and Not the 1st Payment
		if (this.CreateRetailItemResponse) {
			//After All Payment Reversed and trying to make new payment,
			//there may be update in transaction which should be synced on db
			if (this.isUpdateTransaction()) {
				this.CreateTransaction();
			}
			// this.SendTryPayRequest(
			// 	this.CreateRetailItemResponse.transactionDetails[0].transactionId,
			// 	this.selectedpayment.paymentTypeId
			// );
		}
	}

	CreateTransaction(): void {
		if (!this.CheckBreakPointAccess(GlobalConst.RetailBreakPoint.CreateTransaction)) { return; }
		this.isCreateTransactionValidationError = false;
		const tempBlock = true; //Temprory Block the Functionality Statement
		if (tempBlock) {
			if (this._ss.selectedProducts && this._ss.selectedProducts.length > 0) {
				let productsWithNoQuantity = this._ss.selectedProducts.filter((x) => x.Noofitems == 0);
				if (productsWithNoQuantity.length > 0) {
					this.utils.ShowErrorMessage(
						this.localization.captions.common.Error,
						this.localization.captions.shop.QuantityZero
					);
					setTimeout(() => { this.isCreateTransactionValidationError = true; }, 0);
					return;
				}
			}
			let isNoTherapist: boolean = false;
			for (let element of this._ss.selectedProducts) {
				if (element.Gratuity && element.Gratuity.length > 0) {
					for (let gr of element.Gratuity) {
						if (gr.gratuity != 0 && !gr.TherapistId) {
							isNoTherapist = true;
							break;
						}
					}
				}
				if (isNoTherapist) break;
				if (element.ServiceCharge && element.ServiceCharge.length > 0) {
					for (let sc of element.ServiceCharge) {
						if (sc.ServiceCharge != 0 && !sc.TherapistId) {
							isNoTherapist = true;
							break;
						}
					}
				}
			}

			if (isNoTherapist) {
				this.utils.ShowErrorMessage(
					this.localization.captions.common.Error,
					this.localization.captions.shop.NoTherapistAssociated
				);
				setTimeout(() => { this.isCreateTransactionValidationError = true; }, 0);
				return;
			}
			let callDesc: string = this.GetServiceCallDesc();
			let method: HttpMethod = this.GetCallType();
			let transactionType: string = '';
			if (this._ss.correctTransaction && this._ss.voidedTransactionId > 0) {
				transactionType = 'Correct';
			} else if (this._ss.isAppointmentCheckOut) {
				transactionType = 'Service';
			} else {
				transactionType = 'Item';
			}
			let uriParams: any = { transactionType: transactionType };
			if (this.isEditTransaction) {
				uriParams = { id: 4 };
			} else if (this.isUpdateTransaction()) {
				uriParams = { id: (this.CreateRetailItemResponse && this.CreateRetailItemResponse.id) || 0 };
			}
			let shopItems: RetailShopItem[] = [];
			let LineNumber: number = 1;
			if (this._ss.correctTransaction && this._ss.voidedTransactionId > 0) {
				uriParams = { voidedTransactionId: this._ss.voidedTransactionId };
			}
			let index = 0;
			let matchingLineItem: LineItem;
			for (const element of this._ss.selectedProducts) {
				var servCharge: ServiceChargeGratuity[] = [];
				let totalGratuity = 0;
				let totalSc = 0;
				if (element.Gratuity && element.Gratuity.length > 0) {
					var allGratuities: ServiceChargeGratuity[] = [];
					for (const grat of element.Gratuity) {
						if (grat.gratuity != 0) {
							allGratuities.push({
								Date: this.utils.convertDateFormat(this.PropertyInfo.CurrentDate),
								TherapistId: grat.TherapistId,
								ItemId: element.ItemId ? element.ItemId : 0,
								ServiceChargePercent: 0,
								GratuityPercent: grat.Percentage ? grat.Percentage : 0,
								ServiceCharge: 0,
								Gratuity: grat.gratuity,
								Id: 0,
								TransactionDetailId: grat.TransactionDetailId ? grat.TransactionDetailId : 0,
								TotalGratuity: 0,
								TotalServiceCharge: 0,
								StaffType: element.ItemType != RetailItemType.RevenueItem ? grat.StaffType : "USER",
								serviceChargeGratuityTaxDetails: grat.externalTax
							});
							totalGratuity += grat.gratuity;
						}
					}
					allGratuities.forEach(r => r.TotalGratuity = totalGratuity);
					servCharge.push(...allGratuities);
				}

				if (element.ServiceCharge && element.ServiceCharge.length > 0) {
					var allServiceCharges: ServiceChargeGratuity[] = [];
					for (const sc of element.ServiceCharge) {
						if (sc.ServiceCharge != 0) {
							allServiceCharges.push({
								Date: this.utils.convertDateFormat(this.PropertyInfo.CurrentDate),
								TherapistId: sc.TherapistId,
								ItemId: element.ItemId ? element.ItemId : 0,
								ServiceChargePercent: sc.Percentage ? sc.Percentage : 0,
								GratuityPercent: 0,
								ServiceCharge: sc.ServiceCharge,
								Gratuity: 0,
								Id: 0,
								TransactionDetailId: sc.TransactionDetailId ? sc.TransactionDetailId : 0,
								TotalGratuity: 0,
								TotalServiceCharge: 0,
								StaffType: element.ItemType != RetailItemType.RevenueItem ? sc.StaffType : "USER",
								serviceChargeGratuityTaxDetails: sc.externalTax
							});
							totalSc += sc.ServiceCharge;
						}
					}
					allServiceCharges.forEach(r => r.TotalServiceCharge = totalSc);
					servCharge.push(...allServiceCharges);
				}

				if (this._ss.Ticket && this._ss.Ticket.lineItems && this._ss.Ticket.lineItems.length > 0) {
					matchingLineItem = this._ss.Ticket.lineItems.find(
						(r) => r.externalPOSItemId == element.ExternalPOSItemId && r.index == LineNumber
					);
				}

				const obj: RetailShopItem = {
					ItemId: element.ItemId,
					ItemType: element.ItemType,
					ItemDescription: element.ItemDescription,
					ExternalPOSItemId: element.ExternalPOSItemId,
					QuantitySold: element.Noofitems,
					UnitPrice: element.ProductPrice,
					Discount: element.Discount,
					DiscountTypeId: element.DiscountTypeId,
					DiscountPercentage: element.DiscountPercentage,
					StaffTransactionDetail: element.StaffTransactionDetail,
					LineNumber: element.isGroupingKey && !element.isPackagedItem ? 0 : LineNumber,
					Tax: 0,
					BaseTax: 0,
					LinkedTax: 0,
					TotalAmount: element.Noofitems * element.ProductPrice,
					OutletId: this._ss.SelectedOutletId,
					IsGroupingKey: element.isGroupingKey,
					IsPackagedItem: element.isPackagedItem,
					PackageItemId: element.PackageItemId,
					IsMultiPackRedeem: element.MultiPack,
					ClientMultiPackId: element.ClientMultiPackId,
					PackageGroupId: element.PackageGroupId,
					IsOpenPricedItem: element.isOpenPricedItem,
					id: this._ss.settleOpenTransaction ? element.id : 0,
					isReturn: element.isReturn ? element.isReturn : false,
					unitOfMeasureId: element.unitOfMeasureId ? element.unitOfMeasureId : 0,
					scaledUnit: element.scaledUnits ? Number(element.scaledUnits) : 0,
					IsModificationRestricted: element.isModificationRestricted
						? element.isModificationRestricted
						: false,
					IsTaxExempt: this.exempttax && this.exempttax.value ? this.exempttax.value : false,
					discountModel: {
						index: index,
						isPercentage: element.DiscountPercentage > 0,
						value: element.DiscountPercentage > 0 ? element.DiscountPercentage : element.Discount
					},
					netUnitPrice: matchingLineItem ? matchingLineItem.netUnitPrice : element.ProductPrice,
					netPrice: matchingLineItem ? matchingLineItem.netPrice : (element.Noofitems * element.ProductPrice),
					netUnitPriceWithoutDiscount: matchingLineItem
						? matchingLineItem.netUnitPriceWithoutDiscount
						: element.ProductPrice,
					itemComments: element.itemComments,
					costPrice: element.costPrice,
					marginPercentage: element.marginPercentage,
					allowEarn: element.allowEarn,
					discountComments: element.discountComments,
					discountReason: element.discountReason,
					GroupingParentId: element.GroupingParentId ? element.GroupingParentId : 0,
					GroupingUniqueIdentifier: element.GroupingUniqueIdentifier ? element.GroupingUniqueIdentifier : null,
					GroupingItemDescription: element.GroupingItemDescription ? element.GroupingItemDescription : '',
					multiPackTransactionDetailId: element.multiPackTransactionDetailId
				};
				if (element.Commission && element.Commission.length > 0) {
					element.Commission.forEach((r) => (r.id = 0));
					obj.Commission = element.Commission;
					obj.StaffTransactionDetail = element.StaffTransactionDetail;
				}
				if (servCharge) obj.ServiceChargeGratuity = servCharge;

				if (element.isGiftCardItem && element.GiftCardTransactionItem) {
					let giftcardObj = _.cloneDeep(element.GiftCardTransactionItem);
					giftcardObj.handleInfo = typeof (giftcardObj.handleInfo) == "string" ? giftcardObj.handleInfo : JSON.stringify(giftcardObj.handleInfo);
					giftcardObj.isVoided = false;
					giftcardObj.paymentMethodId = this.selectedpayment.id;
					obj.GiftCardTransactionItem = giftcardObj;
					//Updating item description if it doesn't have card number
					if (giftcardObj?.cardNumber && element.ItemDescription && !element.ItemDescription?.includes(giftcardObj.cardNumber)) {
						element.ItemDescription = `${element.ItemDescription} ${giftcardObj.cardNumber}`;
					}
				}

				shopItems.push(obj);

				if (element.CheckOutAppointmentId && !element.IsAppointmentNotCheckedOut)
					this.CheckOutAppointmentsList.push({
						appointmentId: element.CheckOutAppointmentId,
						LineNumber: LineNumber,
						addOnId: element.addOnId
					});

				if (!element.isGroupingKey || element.isPackagedItem) {
					LineNumber++;
					index++;
				}
			}
			let originalTax = this._ss.OriginalTicket.checkData.totalTax;
			const body: TransactionData = {
				Id: this._ss.transactionId ? this._ss.transactionId : 0,
				TransactionType: 'Retail',
				TransactionDate: this.utils.convertDateFormat(this.PropertyInfo.CurrentDTTM),
				ClerkId: 1,
				TotalPrice: this.PropertyInfo.UseRetailInterface ?
					this.RoundOffTwo(this.totalAmount - this.totalServiceCharge - this.totalGratuity) :
					this.RoundOffTwo(this.totalAmountWithOutTax - this.totalServiceCharge - this.totalGratuity),
				TotalTax: 0,
				TotalAmount: this.totalAmount,
				GuestId: this.selectedClientId,
				MachineNameId: this._ss.ProductId == GlobalConst.Product.PMS ? 0 : this.localization.GetMachineId(),
				MemberId: this._ss.memberCardNumber,
				SourceCorpId: this._ss.sourceCorpId,
				Comment: '',
				StayId: 0,
				IsTaxExempt: this.exempttax && this.exempttax.value ? this.exempttax.value : false,
				IsVoided: false,
				shopItems: shopItems,
				OutletId: this._ss.SelectedOutletId,
				TicketNumber: this._ss.ticketNumber ? this._ss.ticketNumber : '',
				ProductId: this._ss.ProductId,
				VoidReason: this.transitionDetails.controls.returnReason.value,
				TerminalId: this._ss.SelectedTerminalId,
				ReceiptComment: this.transitionDetails.controls.receiptComment.value,
				PatronId: (this.payeeInfo && this.payeeInfo.patronId) || null,
				GuestGuid: this._ss.memberCardNumber == "0" && this.selectedClientGuid && Guid.isGuid(String(this.selectedClientGuid)) && this.currentProductId != this.ProductSNCId ? this.selectedClientGuid : null,
				PlayerPin: this.cmsAccrualPin,
				UserId: Number(this.localization.GetUserInfo('userId')),
				TransactionAdditionalReference: this._retailService && this._retailService.transactionAdditionalReference ? this._retailService.transactionAdditionalReference : "",
				TransactionAdditionalComment: this._retailService && this._retailService.transactionAdditionalComment ? this._retailService.transactionAdditionalComment : "",
				TaxWithoutPaymentMethodExemption: originalTax
			};
			this._ams.multiClientInfo = [];
			this.ShowTransactionInprogressDialog();
			this._ams.paymentProcessing = true;
			this.ShowTransactionInprogressDialog();
			this._ams.paymentProcessing = true;
			if (
				callDesc == 'CreateRetailTransaction' ||
				callDesc == 'ReOpenRetailTransaction' ||
				callDesc == 'SettleRetailTransaction' ||
				callDesc == 'CorrectRetailTransaction' ||
				callDesc == 'UpdateRetailTransactionData'
			) {
				let updatedBodyWithTicketDetails: any = {
					transactionData: body,
					transaction: this.PropertyInfo.UseRetailInterface ? null : this._ss.Ticket
				};
				updatedBodyWithTicketDetails.transaction.lineItems.forEach(item => {
					let originalItem = this._ss.OriginalTicket.lineItems.find(x => x.index == item.index)
					item.taxDetails.forEach(tax => {
						tax.originalTaxAmount = originalItem.taxDetails.find(x => x.id == tax.id).value
					})
					item.OriginalTax = originalItem.tax;
				});

				this._retailService.manualEmailId = this._retailService.manualEmailId.filter(x => x != "")
				if (callDesc == 'CreateRetailTransaction') {
					var functionality: any;
					if (this._retailService.manualEmailId.length > 0)
						updatedBodyWithTicketDetails.transactionData.EmailId = this._retailService.manualEmailId;
					this.retailFunctionalityBusiness.getRetailFunctionality().then(x => {
						functionality = x;
						updatedBodyWithTicketDetails.isMultipleItemMultiPack = functionality.ShowMultipleLinkedRetailItemForMultipack ? true : false;
						this.InvokeServiceCall(callDesc, method, uriParams, updatedBodyWithTicketDetails);
					}

					);

				} else {
					if ((callDesc == 'ReOpenRetailTransaction' || callDesc == 'CorrectRetailTransaction') && this._retailService.manualEmailId.length > 0)
						updatedBodyWithTicketDetails.transactionData.EmailId = this._retailService.manualEmailId;
					this.InvokeServiceCall(callDesc, method, uriParams, updatedBodyWithTicketDetails);
					if (callDesc == 'SettleRetailTransaction' && this._retailService.orgEmailId != this._retailService.manualEmailId)
						this.updateEmailbyTransactionId(updatedBodyWithTicketDetails.transactionData.Id, this._retailService.manualEmailId);
				}

			} else {
				this.InvokeServiceCall(callDesc, method, uriParams, body);
			}
			// }
		}
	}

	updateEmailbyTransactionId(transactionId: number, manualEmailId: string[]) {
		this.transactionService.InvokeServiceCallAsync(RetailRoutes.UpdateEmailsByTransactionId, GlobalConst.Host.retailPOS, HttpMethod.Put, { transactionId: transactionId }, manualEmailId);
	}

	/***
   * @function successCallback
   * @input Params <Array>
   * @description Success call back function from  makeSearchCall(name: string) above method like % method
   */

	async successCallback<T>(result: BaseResponse<T>, callDesc: string) {
		this.isAPICallInProgress = false;
		switch (callDesc) {
			case 'UpdateRetailTransaction':
			case 'CreateRetailTransaction':
			case 'CorrectRetailTransaction':
			case 'SettleRetailTransaction':
			case 'ReOpenRetailTransaction':
			case 'ReturnWithoutTicket':
			case 'UpdateRetailTransactionData':
				if (callDesc == 'UpdateRetailTransactionData') {
					this._ss.transactionUpdated = true;
				}
				this.CreateRetailItemResponse = result.result;
				this._ss.CreateItemResponse = _.cloneDeep(this.CreateRetailItemResponse)
				if (callDesc == 'CreateRetailTransaction' || callDesc == 'ReOpenRetailTransaction') {
					this.retailValidationService.LockTransaction(this.CreateRetailItemResponse.id)
					if (this._ss.SelectedPlayers && this._ss.SelectedPlayers.length > 0) {
						const taskList: Promise<any>[] = this._teetimePaymentBusiness.CreatePlayerTransaction(
							this,
							PlayerPaymentstatus.unPaid,
							PlayerTransactionStatus.Pending,
							this.getSaleAmt(),
							true,
							true
						);
						Promise.all([taskList]);
					}
				}
				if (this.transactionInProgressDialogRef) this.transactionInProgressDialogRef.close();
				this._retailService.isTeeTimeAsPaid = false;
				if (this._ss.correctTransaction && this._ss.voidedTransactionId > 0) {
					let eventData: RetailEventParameters<TicketChangeEventModel> = {
						data: {
							oldTransactionId: this._ss.voidedTransactionId,
							transactionId: this.CreateRetailItemResponse.id,
							transactionDetail: this.CreateRetailItemResponse.transactionDetails,
							ticketNumber: this.CreateRetailItemResponse.transactionData.ticketNumber,
							oldTicketNumber: this._ss.voidedTicketNumber
						},
						eventType: RetailEventType.Correct
					};
					retailPublisher.publishEvent(eventData);

					this._ss.correctTransaction = false;
					this._ss.isDepositTransactionCorrection = false;
					this._ss.voidedTransactionId = 0;
					this._ss.voidedTicketNumber = "";
				}

				if (this._ss.reOpenTransaction) {
					let eventData: RetailEventParameters<TicketChangeEventModel> = {
						data: {
							oldTransactionId: this._ss.transactionId,
							transactionId: this.CreateRetailItemResponse.id,
							transactionDetail: this.CreateRetailItemResponse.transactionDetails
						},
						eventType: RetailEventType.ReOpen
					};
					retailPublisher.publishEvent(eventData);
				}

				if (this.PropertyInfo.UseRetailInterface) {
					this.ProcessSendForPayment();
				}
				break;
			case 'GetImagesByGuid':
				let imgResponse: any = result.result;
				if (imgResponse != null) {
					this.thumbnailImageData = imgResponse[0];
				}
				break;
			default:
				break;
		}
	}

	errorCallback<T>() {
		this.disableRemove = false; this.isCreateTransactionValidationError = true; this.isAPICallInProgress = false; this._ams.paymentProcessing = false;
		if (this.transactionInProgressDialogRef) this.transactionInProgressDialogRef.close();
	}

	ProcessSendForPayment() {
		this.PromptCaddyShackCommentDialog(this.PropertyInfo.UseRetailInterface);
	}

	async ProcessPendingSettlement() {
		this._ss.selectedProducts = [];
		this._ss.selectedPayeeId = 0;
		this.payeeInfo = null;
		this.FetchActiveFolioInvoiceNumberForPayee();
		this._ams.labelRecords = [];
		this._ss.SelectedItemDiscount = [];
		this._ss.GoToRetailTransaction = true;
		this._ams.paymentProcessing = false;

		if (this._ss.isAppointmentCheckOut) {
			if (this.CheckOutAppointmentsList && this.CheckOutAppointmentsList.length > 0) {
				this._ss.CheckOutCallback(this.CreateRetailItemResponse, _.cloneDeep(this.CheckOutAppointmentsList));
				this.CheckOutAppointmentsList = [];
			}
			this._ss.isAppointmentCheckOut = false;
		}
		if (this._ss.isFromDayEnd) {
			this._ss.isFromDayEnd = false;
			this._ss.isAppointmentCheckOut = false;
		}
		if (this._ss.isFromRetailDashBoard) {
			this._ss.isFromRetailDashBoard = false;
		}
		if (this._ss.SelectedPlayers && this._ss.SelectedPlayers.length > 0) {
			const taskList: Promise<any>[] = this._teetimePaymentBusiness.ProcessPlayerTransaction(
				this,
				PlayerPaymentstatus.unPaid,
				PlayerTransactionStatus.Pending,
				0,
				true,
				true
			);
			Promise.all([taskList]);
		}

		if (this._ss.isPreSettlementGenerate) {
			this.printReceipt(this.CreateRetailItemResponse, false, false, false, undefined, true);

		}
		if (this._ss.isFromSncBeo) {
			const id = this.CreateRetailItemResponse
				? this.CreateRetailItemResponse.transactionDetails[0].transactionId
				: 0
			RetailDataAwaiters.UpdateBeoCheckTransactionId(0, id);
		}
		this._ss.ResetShopObject();
		setTimeout(() => {
			this.utils.RedirectTo(RedirectToModules.RetailTransactions);
		}, 2000);
	}

	InvokeServiceCall(route: string, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
		this.isAPICallInProgress = true;
		this.http.CallApiWithCallback<any>({
			host: GlobalConst.Host.retailPOS,
			success: this.successCallback.bind(this),
			error: this.errorCallback.bind(this),
			callDesc: route,
			method: callType,
			body: body,
			showError: true,
			extraParams: extraParams,
			uriParams: uriParams
		});
	}

	ShowUnitPrice(unitPrice: number) {
		if (unitPrice < 0) {
			unitPrice *= -1;
		}
		return this.localization.localizeCurrency(unitPrice, false);
	}

	getGratuity(item) {
		const grat = this._ss.Ticket && this._ss.Ticket.lineItems.some(l => l.index == item.lineNumber) ?
			this._ss.Ticket.lineItems.find(l => l.index == item.lineNumber).gratuity : 0;
		return this.FormatPrice(grat);
	}

	getServiceCharge(item) {
		const serviceCharge = this._ss.Ticket && this._ss.Ticket.lineItems.some(l => l.index == item.lineNumber) ?
			this._ss.Ticket.lineItems.find(l => l.index == item.lineNumber).serviceCharge : 0;
		return this.FormatPrice(serviceCharge);
	}

	getSubTotal(item): string {
		let itemPrice = 0;
		if (this._ss.Ticket && this._ss.Ticket.lineItems.some(l => l.index == item.lineNumber)) {
			const element = this._ss.Ticket.lineItems.find(l => l.index == item.lineNumber);
			itemPrice = element.gratuity + element.serviceCharge + (element.unitPrice * element.quantity)
		}
		return this.FormatPrice(itemPrice);
	}

	ShowUnitGratuity(item): string {
		let Unitgratuity: string = '';
		let element = item.element;
		Unitgratuity = element.Gratuity && element.Gratuity.length > 0 ? element.Gratuity.map(x => x.gratuity).reduce((a, b) => a + b, 0) : 0;
		return Unitgratuity;
	}

	ShowUnitServiceCharge(item): string {
		let UnitServiceCharge: string = '';
		let element = item.element;
		UnitServiceCharge = element.ServiceCharge && element.ServiceCharge.length > 0 ? element.ServiceCharge.map(x => x.ServiceCharge).reduce((a, b) => a + b, 0) : 0;
		return UnitServiceCharge;
	}

	ShowSubTotal(item): string {
		let price: string = '';
		const element = item.element;

		const gratuity = element.Gratuity && element.Gratuity.length > 0 ? element.Gratuity.map(x => x.gratuity).reduce((a, b) => a + b, 0) : 0;
		const servCharge = element.ServiceCharge && element.ServiceCharge.length > 0 ? element.ServiceCharge.map(x => x.ServiceCharge).reduce((a, b) => a + b, 0) : 0;

		let itemPrice = item.unitprice * (item.quantity == '' ? 0 : item.quantity);

		itemPrice += gratuity + servCharge;
		price = this.FormatPrice(itemPrice);

		return price;
	}

	ShowDiscount(item) {
		const discount = this._ss.Ticket && this._ss.Ticket.lineItems.some(l => l.index == item.lineNumber) ?
			this._ss.Ticket.lineItems.find(l => l.index == item.lineNumber).discount : 0;
		if (item.unitprice > 0) {
			return `(${this.localization.localizeCurrency(discount)})`;
		} else {
			return this.localization.localizeCurrency(discount);
		}
	}
	GetServiceChargeTax() {
		const serviceCharge = this._ss.Ticket && this._ss.Ticket.lineItems;
	}

	GetGratutityTotal() {
		if (this.totalDiscount > 0) {
			return `(${this.localization.localizeCurrency(this.totalDiscount)})`;
		} else {
			return this.localization.localizeCurrency(this.totalDiscount * -1);
		}
	}

	ShowFinalDiscount() {
		if (this.totalDiscount > 0) {
			return `(${this.localization.localizeCurrency(this.totalDiscount)})`;
		} else {
			return this.localization.localizeCurrency(this.totalDiscount * -1);
		}
	}

	ShowGratuity() {
		return this.localization.localizeCurrency(this.totalGratuity);
	}

	ShowServiceCharge() {
		return this.localization.localizeCurrency(this.totalServiceCharge);
	}

	ShowFinalVATAmountTotal(): string {
		return this.FormatPrice(_.cloneDeep(this.TotalVatAmount));
	}

	ShowFinalSubTotal(): string {
		return this.FormatPrice(_.cloneDeep(this.PropertyInfo.UseRetailInterface ? this.totalAmount + this.totalDiscount : this.totalAmount));
	}

	FormatPrice(price: number) {
		if (price < 0) {
			return `(${this.localization.localizeCurrency(price * -1)})`;
		} else {
			return this.localization.localizeCurrency(price);
		}
	}

	async CreateTicket() {
		if (this.PropertyInfo.UseRetailInterface) {
			// To check for IG flow ***
			this.IsGratuityOnNet = await this._ss.IsGratuityOnNet();
			this.IsServiceChargeOnNet = await this._ss.IsServiceChargeOnNet();
			if (this.IsServiceChargeOnNet || this.IsGratuityOnNet) {
				this._ams.loaderEnable.next(this.ShopCaptions.AddTax);
				this.InvokeCreateTicket();
			} else {
				await this.totalPrice();
			}
			await this.ValidateItemsForSettleFlow();
			return;
		}
		if (this._ss.selectedProducts &&
			this._ss.selectedProducts.length > 0 &&
			!this._ss.settleOpenTransaction &&
			(this._ss.isFromBuy ||
				this._ss.isFromDeposit ||
				this._ss.isFromEditTeeTime ||
				this._ss.isFromUnpaidPlayer ||
				this._ss.cancelFlag ||
				this._ss.isFromGiftCard ||
				this._ss.isFromSncBeo)
		) {
			if (!this.CheckBreakPointAccess(GlobalConst.RetailBreakPoint.CreateTransaction)) { return; }
			this._ams.loaderEnable.next(this.ShopCaptions.AddTax);
			this.InvokeCreateTicket();
			this._ss.isFromDeposit = false;
		} else if (this._ss.settleOpenTransaction) {
			await this.InvokeUpdateTicket();
			if (
				this._ss.selectedProducts &&
				this._ss.selectedProducts.length > 0 &&
				this._ss.selectedProducts[0].isTaxExempt
			) {
				this.exempttax.setValue(true);
			}
		} else {
			await this.totalPrice();
		}
		if (this._ss.settleOpenTransaction || this._ss.reOpenTransaction) {
			if (this._ss.ProductId == GlobalConst.Product.GOLF) {
				await this.GetTransactionPlayerDetails(this._ss.transactionId);
				if (this._ss.selectedProducts) {
					const uniqueplayerIds: number[] = [];
					this._ss.selectedProducts.forEach((value, key) => {
						if (
							value.payeeId != undefined &&
							uniqueplayerIds.find((x) => x == value.payeeId) == undefined
						) {
							uniqueplayerIds.push(value.payeeId);
						}
					});
					let players: ScheduledTeeTimeUnPaidPlayer[] = await RetailDataAwaiters.GetPlayers(
						this.selectedCourse,
						this.selectedDate
					);
					let unpaidplayers = players ? players.filter((x) => uniqueplayerIds.includes(x.playerId)) : [];
					this._ss.SelectedPlayers = unpaidplayers;
				}
			}

			if (this._ss.ProductId == GlobalConst.Product.SPA) {
				await this.GetAppointmentClientDetails(this._ss.transactionId);
				await this.GetAppointmentTherapistDetails();
			}
		}
		this.allowTaxExempt = this._ss.hasUserAccessToExemptTax;
	}

	async InvokeEditQuantity() {
		await this.InvokeUpdateTicket();
		this.FormOrderSummary();
		await this.totalPrice();
	}

	async updateOrderSummary() {
		await this.totalPrice();
		this.CalculateSettledAmount();
		this._ss.remainingAmount = this.remainingAmount;
	}

	MapLineNumberForEmbeddedItems() {
		this.GridItems.filter(f => !f.isGroupingKey || f.isPackagedItem || f.PackageItemId > 0).forEach((itm, index) => {
			itm.lineNumber = index + 1;
		});
		this._ss.selectedProducts.filter(f => !f.isGroupingKey || f.isPackagedItem || f.PackageItemId > 0).forEach((itm, index) => {
			itm.LineNumber = index + 1;
		});
	}

	async InvokeUpdateTicket() {
		try {
			this.MapLineNumberForEmbeddedItems();
			this._ss.OriginalTicket = this._ss.Ticket = await this.transactionEngineBusiness.CreateTicket(
				this._ss.SelectedOutletId,
				this._ss.selectedProducts
			);
			if (this._ss.Ticket) {
				this.taxValue = this._ss.Ticket.checkData.totalTax;
			}
			this._ss.PayButtonDisable.emit(false);
			this.UpdateTaxAndTenderMaxLimit();
			await this.totalPrice();
			setTimeout(() => {
				this._ams.loaderEnable.next('');
			}, 1000);
		}
		catch (e) {
			console.log(e);
			const RetailCreateTicketErrorCode = -201;
			const errorMessage = this.localization.getError(RetailCreateTicketErrorCode);
			this.utils.showError(errorMessage);
		}
		finally {
			this._ams.loaderEnable.next('');
		}
	}

	async InvokeCreateTicket() {
		try {
			if (this.PropertyInfo.UseRetailInterface) {
				this._ss.Ticket = await this.transactionService.CreateTicket(
					this._ss.selectedProducts,
					this._ss.settleOpenTransaction
				);
			} else {
				this.MapLineNumberForEmbeddedItems();
				this._ss.OriginalTicket = this._ss.Ticket = await this.transactionEngineBusiness.CreateTicket(
					this._ss.SelectedOutletId,
					this._ss.selectedProducts,
					this._ss.settleOpenTransaction
				);
				this.isTicketCreated = true;
			}
			if (this._ss.Ticket) {
				this.taxValue = this._ss.Ticket.checkData.totalTax;
				this.UpdateTaxAndTenderMaxLimit();
				await this.totalPrice();
				this._ss.remainingAmountAfterPayment = this._ss.Ticket.checkData.totalAmount
			}
		}
		catch (e) {
			console.log(e)
			const RetailCreateTicketErrorCode = -201;
			const errorMessage = this.localization.getError(RetailCreateTicketErrorCode);
			this.utils.showError(errorMessage);
		}
		finally {
			this._ams.loaderEnable.next('');
		}
	}

	async UpdateTicket(updateType: TicketUpdateType) {
		if (this.PropertyInfo.UseRetailInterface) {
			return;
		}
		console.log("Calling update ticket")
		switch (updateType) {
			case TicketUpdateType.ModifyServiceChargeAndGratuity:
				await this.InvokeModifyGratuityAndServiceCharge();
				break;
			case TicketUpdateType.RemoveGratuity:
				await this.InvokeRemoveGratuityCharge();
				break;
			case TicketUpdateType.RemoveServicecharge:
				await this.InvokeRemoveServiceCharge();
				break;
			case TicketUpdateType.RemoveServiceChargeAndGratuity:
				this._ss.Ticket.checkData.serviceCharge = 0;
				this._ss.Ticket.checkData.gratuity = 0;
				break;
			case TicketUpdateType.RemoveServiceChargeDirect:
				this._ss.Ticket.checkData.serviceCharge = 0;
				break;
			case TicketUpdateType.RemoveGratuityDirect:
				this._ss.Ticket.checkData.gratuity = 0;
				break;
		}
		this._memberService.EnableDisableARPostandRedeemRoundPaymentMethod(this.paymentComponent);
	}

	async InvokeModifyGratuityAndServiceCharge() {
		this._ams.loaderEnable.next(this.ShopCaptions.AddCommissionGratuityServiceCharge);
		await this.InvokeUpdateTicket();
	}

	async InvokeRemoveServiceCharge() {
		this._ams.loaderEnable.next(this.ShopCaptions.RemoveItemServiceCharge);
		await this.InvokeUpdateTicket();
	}

	async InvokeRemoveGratuityCharge() {
		this._ams.loaderEnable.next(this.ShopCaptions.RemoveItemGratuity);
		await this.InvokeUpdateTicket();
	}

	RecalculateSCGTOnQtyUpdate(selectedProduct: SelectedProducts) {
		if (selectedProduct && selectedProduct.Gratuity && selectedProduct.ServiceCharge) {
			const itemValue = (selectedProduct.ProductPrice * Number(selectedProduct.Noofitems));
			for (const grat of selectedProduct.Gratuity) {
				grat.gratuity = grat?.PercOrAmount == 1 ? Number((grat.Percentage / 100 * itemValue).customToFixed()) : grat.gratuity;
			}
			for (const sc of selectedProduct.ServiceCharge) {
				sc.ServiceCharge = sc.PercOrAmount == 1 ? Number((sc.Percentage / 100 * itemValue).customToFixed()) : sc.ServiceCharge;
			}
		}
	}

	ContinueShopping() {
		if (this._ss.Ticket) {
			// Discard Check NA for Embedded
			if (this.PropertyInfo.UseRetailInterface && this._ss.Ticket.checkData.checkNumber.trim() != '') {
				this._ss.InvokeDiscardCheck();
			}
			else {
				this._ss.Ticket = null;
			}

			if (this.CreateRetailItemResponse != null && this.CreateRetailItemResponse.transactionDetails
				&& this.SettlementHistory && this.SettlementHistory.length > 0) {
				this.transactionService.DeleteTransactionRecord(this.CreateRetailItemResponse.transactionDetails[0].transactionId);
				this.ReleaseLock();
				this.CancelEmit();
			}
		}
		this.ResetSelectedProductMultipackPrice();
		this._ss.isFromContinueShopping = true;
		this._ss.selectedPayeeId = this.payeeInfo && this.payeeInfo.id;
		if (this._retailService.isFromSncBeo) {
			this.router.navigate([SNCRoute.Booking], { state: { from: SNCFromPage.beoPost, id: this._retailService.bookingId, beoIds: this._retailService.beoIds, beoDeposit: this._retailService.beoDeposit, tab: this.beoTab } });
		}
		else {
			this.utils.RedirectTo(RedirectToModules.BackToPrevLocation);
		}
	}

	ContinueBooking() {
		this._ss.isFromContinueBooking = true;
		this.utils.RedirectTo(RedirectToModules.TeeTime);
	}

	async InvokeRemoveItems(elementsToRemove: number[]) {
		if (!this._ss.Ticket?.lineItems || this._ss.Ticket?.lineItems?.length == 0) {
			return;
		}
		this._ams.loaderEnable.next(this.ShopCaptions.RemoveItem);
		// remove duplicate index
		elementsToRemove = Array.from(new Set(elementsToRemove));
		// remove -1 index
		elementsToRemove = elementsToRemove.filter((x) => (x != -1));
		let uriParams = {
			ticketNumber: this._ss.Ticket.checkData.checkNumber,
			outletId: this._ss.SelectedOutletId,
			checkHandleGuid: this._ss.Ticket.checkData.checkHandleGuid,
			terminalId: this._ss.SelectedTerminalId
		};
		let ticket = await this.http.CallApiAsync<any>({
			host: GlobalConst.Host.retailPOS,
			callDesc: 'RemoveItems',
			method: HttpMethod.Post,
			body: elementsToRemove,
			uriParams: uriParams
		});
		this._ss.Ticket = ticket.result;
		if (this.PropertyInfo.UseRetailInterface) {
			this.taxValue = this._ss.Ticket.checkData.totalTax;
		}
		else {
			this.taxValue = this._ss.Ticket.checkData.totalItemTax;
		}

		this.UpdateTaxAndTenderMaxLimit();
		setTimeout(() => {
			this._ams.loaderEnable.next('');
		}, 1000);
	}

	HasReturnItem(): boolean {
		let result = false;
		if (this._ss.selectedProducts.length > 0) {
			result = this._ss.selectedProducts.some((r) => r.isReturn);
		}
		if (result && !this.transitionDetails.controls.returnReason.value) {
			this.enableSave = false;
		} else {
			this.enableSave = true;
		}
		return result;
	}

	positionPopover(arg, data, idx, charge) {

		if (!this.CheckBreakPointAccess(GlobalConst.RetailBreakPoint.ApplyDiscount)) {
			//this.applyAllPopover.hide();
			return;
		}

		this.currentSelectedItemForDiscount = data;
		var selectedDiscount: ItemDiscount = {
			itemId: data.element.ItemId,
			discountId: data.element.DiscountTypeId,
			discountPercentage: data.element.DiscountPercentage,
			discountAmount: data.element.DiscountPercentage > 0 ? 0 : data.element.Discount,
			discountComment: data.element.discountComments,
			discountReason: data.element.discountReason
		};
		let categoryId: number = data.element.category;
		if (data.element.isPackagedItem && this.SelectedRetailItemDetails && this.SelectedRetailItemDetails.length > 0) {
			var parentItem = this.SelectedRetailItemDetails.find((x) => x.retailItemDetail.id == data.element.PackageItemId);
			categoryId = parentItem ? parentItem.retailItemDetail.category : categoryId;
		}
		var itemArr = this.GridItems.map((x) => x.element);
		var ItemIndex = this._ss.findSelectedItemIndexWithoutPackage(data.element.LineNumber, itemArr);
		this.applyDiscountInput = {
			index: ItemIndex,
			CategoryId: categoryId,
			SelectedDiscount: selectedDiscount,
			isMultipleItems: false,
			showItemDetails: false,
			itemName: data?.element.ItemDescription,
			itemPrice: data?.element.ProductPrice
		};

		if (charge?.id == 5) {
			this.dialog.open(ApplyDiscountComponent, {
				width: '700px',
				height: '480px',
				disableClose: true,
				hasBackdrop: true,
				data: {
					discountInput: this.applyDiscountInput
				},
			}).afterClosed().subscribe(result => {
				if (result) {
					this.applyDiscount(result)
				}
			});
		}
	}

	OpenApplyDiscountToAll(): void {
		if (!this.CheckBreakPointAccess(GlobalConst.RetailBreakPoint.ApplyDiscount)) {
			this.applyAllPopover.hide();
			return;
		}
		if (this.isApplyDiscountFromOrderSummaryGrid) {
			const filteredData = this.GridItems.filter(x => x.ischecked &&
				(x.element.Discount > 0 || x.element.DiscountPercentage > 0 || x.element.DiscountTypeId > 0));
			const checkedItems = this.GridItems.filter(x => x.ischecked);
			let sameCategoryData = checkedItems.every((x) => x.element.category == checkedItems[0].element.category);
			if (filteredData && filteredData.length == 1 && checkedItems && checkedItems.length == 1) {
				const selectedDiscount: ItemDiscount = {
					itemId: filteredData[0].element.ItemId,
					discountId: filteredData[0].element.DiscountTypeId,
					discountPercentage: filteredData[0].element.DiscountPercentage,
					discountAmount: filteredData[0].element.DiscountPercentage > 0 ? 0 : filteredData[0].element.Discount,
					discountComment: filteredData[0].element.discountComments,
					discountReason: filteredData[0].element.discountReason
				};
				this.applyAllDiscountInput = {
					CategoryId: filteredData[0].element.category,
					SelectedDiscount: selectedDiscount,
					isMultipleItems: filteredData.length > 1,
					showItemDetails: false
				}
			}
			else if (checkedItems.length == 1 && filteredData.length == 0) {
				const checkedItem = this.GridItems.filter(x => x.ischecked)
				const selectedDiscount: ItemDiscount = {
					itemId: 0,
					discountId: 0,
					discountPercentage: 0,
					discountAmount: 0,
					discountComment: '',
					discountReason: 0
				};
				this.applyAllDiscountInput = {
					CategoryId: checkedItem[0].element.category,
					SelectedDiscount: selectedDiscount,
					isMultipleItems: checkedItem.length > 1,
					showItemDetails: false
				}
			}
			else if (checkedItems.length > 1 && filteredData.length == 0 && sameCategoryData) {
				const checkedItem = this.GridItems.filter(x => x.ischecked)
				const selectedDiscount: ItemDiscount = {
					itemId: 0,
					discountId: 0,
					discountPercentage: 0,
					discountAmount: 0,
					discountComment: '',
					discountReason: 0
				};
				this.applyAllDiscountInput = {
					CategoryId: checkedItem[0].element.category,
					SelectedDiscount: selectedDiscount,
					isMultipleItems: checkedItem.length > 1,
					showItemDetails: false
				}
			}
			else {
				const selectedDiscount: ItemDiscount = {
					itemId: 0,
					discountId: 0,
					discountPercentage: 0,
					discountAmount: 0,
					discountComment: '',
					discountReason: 0
				};
				this.applyAllDiscountInput =
				{
					CategoryId: -1,
					SelectedDiscount: selectedDiscount,
					showItemDetails: false,
					isMultipleItems: true
				}
			}
		}
		else {
			this.applyAllDiscountInput = {
				CategoryId: -1,
				SelectedDiscount: null,
				showItemDetails: false,
				isMultipleItems: true
			};
		}

		this.dialog.open(ApplyDiscountComponent, {
			width: '700px',
			height: '480px',
			data: {
				discountInput: this.applyAllDiscountInput
			},
			disableClose: true,
			hasBackdrop: true,
		}).afterClosed().subscribe(result => {
			if (result) {
				this.applyAllDiscount(result);
			}
		});

	}

	DiscountAllAmountExceedCallback(result: string, extraParams: any[]) {
		if (result.toUpperCase() == 'YES') {
			this.applyAllDiscount(extraParams[0], false);
		}
	}

	async removeItemDiscount(discount: ItemDiscount, index: number, validate: boolean = true) {
		if (discount.discountAmount < 0 && validate) {
			this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.ShopCaptions.InvalidAmount);
			return;
		}

		if (
			validate &&
			(discount.discountPercentage > 100 ||
				this.GridItems.some(
					(x) =>
						x.ischecked &&
						discount.discountAmount > x.element.Noofitems * Math.abs(x.element.ProductPrice)
				))
		) {
			this.utils.ShowErrorMessage(
				this.localization.captions.common.Warning,
				this.ShopCaptions.DiscountAmountGreaterThanActual,
				GlobalConst.ButtonType.YesNo,
				this.DiscountAllAmountExceedCallback.bind(this),
				[discount]
			);
			//this.applyAllPopover.hide();
			return;
		}
		//this.applyAllPopover.hide();
		var nonApplicable = [];
		var postData: ApplyDiscount[] = [];
		var listOfItems = this.GridItems.map((x) => x.element);
		let gridItemHasSvc = this.GridItems.some(GridItem => (GridItem.element.ServiceCharge || []).length > 0);
		let gridItemHasGty = this.GridItems.some(GridItem => (GridItem.element.Gratuity || []).length > 0);
		if (!this._ss.isExcludeDiscOnServiceCharge && gridItemHasSvc) {
			await this.UpdateTicket(TicketUpdateType.RemoveServiceChargeDirect);
		}
		if (!this._ss.isExcludeDiscOnGratuity && gridItemHasGty) {
			await this.UpdateTicket(TicketUpdateType.RemoveGratuityDirect);
		}

		let GridItem = this.GridItems[index];
		// continue if it is not selected
		// if (!GridItem.ischecked) return;

		const elementItem = GridItem.element;
		// continue if the selected item is returned Item or Giftcard Item
		if (elementItem.isGiftCardItem) return;

		// continue if the selected item is a packaged item
		if (elementItem.isGroupingKey && !elementItem.isPackagedItem) return;

		let catgId: number = 0;
		if (elementItem.isPackagedItem && this.SelectedRetailItemDetails && this.SelectedRetailItemDetails.length > 0) {
			var parentItem = this.SelectedRetailItemDetails.find((x) => x.retailItemDetail.id == elementItem.PackageItemId);
			catgId = parentItem ? parentItem.retailItemDetail.category : catgId;
		}
		var discountConfig = this.applyDiscountService.CategoryDiscountList.find(
			(x) => x.retailCategoryId == elementItem.category && x.eligibleForDiscount
		);
		if (!discountConfig) {
			nonApplicable.push(elementItem);
			return;
		}
		else {
			this.RecalculateServiceChargeGratuityByDicount(elementItem, discount);
		}
		var packageDiscountConfig = this.applyDiscountService.CategoryDiscountList.find(
			(x) => x.retailCategoryId == catgId && x.eligibleForDiscount
		);


		let percentConfg: DiscountTypeDetails;

		if (elementItem.isPackagedItem) {
			percentConfg = packageDiscountConfig
				? packageDiscountConfig.discountTypeDetails.find((x) => x.discountTypeId == discount.discountId)
				: null;
		} else {
			percentConfg = discountConfig.discountTypeDetails.find((x) => x.discountTypeId == discount.discountId);
		}

		var percent = percentConfg ? percentConfg.discountValue : discount.discountPercentage;
		if (percent > 0 && discount.discountPercentage == 0) {
			discount.discountPercentage = percent;
		}
		postData.push({
			index: this._ss.findSelectedItemIndexWithoutPackage(elementItem.LineNumber, listOfItems),
			isPercentage: percent > 0,
			value: percent > 0 ? percent : discount.discountAmount
		});


		if (nonApplicable.length > 0) {
			this.utils.ShowErrorMessage(
				this.localization.captions.common.Error,
				this.localization.replacePlaceholders(
					this.ShopCaptions.DiscountNotEligibleForSomeItem,
					['ItemCount'],
					[nonApplicable.length]
				)
			);
		}
		if (postData.length > 0) {
			await this.ApplyDiscountToCheckData(postData, DiscountActions.ApplyDiscountToAllItems);
		}

		let itemGrid = this.GridItems[index];
		const elementGridItem = itemGrid.element;
		// continue if it is not selected
		// continue if the selected item is returned Item
		// continue if the selected item is a packaged item
		if (elementGridItem.isGiftCardItem || (elementGridItem.isGroupingKey && !elementGridItem.isPackagedItem)) {
			return;
		}

		let categoryId: number = elementGridItem.category;
		if (elementGridItem.isPackagedItem && this.SelectedRetailItemDetails && this.SelectedRetailItemDetails.length > 0) {
			var parentItem = this.SelectedRetailItemDetails.find((x) => x.retailItemDetail.id == elementGridItem.PackageItemId);
			categoryId = parentItem ? parentItem.retailItemDetail.category : categoryId;
		}

		var discountConfig = this.applyDiscountService.CategoryDiscountList.find(
			(x) => x.retailCategoryId == elementGridItem.category && x.eligibleForDiscount
		);
		var packageDiscountConfig = this.applyDiscountService.CategoryDiscountList.find(
			(x) => x.retailCategoryId == categoryId && x.eligibleForDiscount
		);
		if (!discountConfig) {
			return;
		}

		let percentConfig: DiscountTypeDetails;

		if (elementGridItem.isPackagedItem) {
			percentConfig = packageDiscountConfig
				? packageDiscountConfig.discountTypeDetails.find((x) => x.discountTypeId == discount.discountId)
				: null;
		} else {
			percentConfig = discountConfig.discountTypeDetails.find((x) => x.discountTypeId == discount.discountId);
		}

		if (discount.discountPercentage > 0) {
			elementGridItem.DiscountPercentage = percentConfig ? percentConfig.discountValue : discount.discountPercentage;
			elementGridItem.Discount = percentConfig
				? this.utils.RoundOff2DecimalPlaces(
					elementGridItem.Noofitems * Math.abs(elementGridItem.ProductPrice) * percentConfig.discountValue / 100
				)
				: this.utils.RoundOff2DecimalPlaces(
					elementGridItem.Noofitems * Math.abs(elementGridItem.ProductPrice) * discount.discountPercentage / 100
				);
			elementGridItem.DiscountTypeId = percentConfig ? percentConfig.discountTypeId : 0;
			let checkDiscount = this._ss.GetCheckLineItemByIndex(this._ss.findSelectedItemIndexWithoutPackage(elementGridItem.LineNumber, listOfItems))?.discount || 0;
			if ((checkDiscount != 0 || this._ss.TicketHasDiscount()) && elementGridItem.Discount != checkDiscount) {
				elementGridItem.Discount = checkDiscount;
			}
		} else {
			elementGridItem.Discount = discount.discountAmount;
			elementGridItem.DiscountPercentage = 0;
			elementGridItem.DiscountTypeId = 0;
		}
		elementGridItem.discountComments = discount.discountComment;
		elementGridItem.discountReason = discount.discountReason;
		elementGridItem.isAutoMemDiscRemoved = true;

		this._ss.SelectedItemDiscount = this._ss.SelectedItemDiscount.filter((x) => x.itemId != elementGridItem.ItemId);
		discount.itemId = elementGridItem.ItemId;
		this._ss.SelectedItemDiscount.push({ ...discount });


		this.FormOrderSummary();
		await this.totalPrice();
		if ((!this._ss.isExcludeDiscOnServiceCharge || !this._ss.isExcludeDiscOnGratuity) && (gridItemHasSvc || gridItemHasGty)) {
			if (!this.tempItemDetails || this.tempItemDetails == null) {
				this.tempItemDetails = { itemDetails: {}, item: this._ss.selectedProducts };
			}
			await this.ServiceChargeCalculation(this.tempItemDetails?.itemDetails, this.tempItemDetails?.item)
		}
		else {
			await this.UpdateTicket(TicketUpdateType.ModifyServiceChargeAndGratuity);
		}
		this.checkAll = false;
		this.enableApply = false;
		this.CheckForMultipackRedemption();
	}

	RecalculateServiceChargeGratuityByDicount(elementItem, discount?: ItemDiscount) {

		//To Recalculate SVC
		if (!this._ss.isExcludeDiscOnServiceCharge && (elementItem.ServiceCharge || []).length > 0) {
			if (!this.CheckBreakPointAccess(GlobalConst.RetailBreakPoint.EditServiceCharge)) {
				return false;
			}
			var serviceChargeDetailBackup = elementItem.ServiceCharge;
			elementItem.ServiceCharge = null;
			if (elementItem.ItemType === 6) {
				const index = elementItem.LineNumber - 1;
				if ((this.depositArray || []).length > 0) {
					this.depositArray[index].serviceCharge = 0;
				}
			}

			if (!this.tempItemDetails || this.tempItemDetails == null) {
				this.tempItemDetails = { itemDetails: {}, item: this._ss.selectedProducts };
			}
			if (this.tempItemDetails) {
				if (serviceChargeDetailBackup && serviceChargeDetailBackup.length > 0) {
					for (let svc of serviceChargeDetailBackup) {
						let discountedCostAmount = discount && discount.discountPercentage > 0 ? (elementItem.ProductPrice * discount.discountPercentage) / 100 : (discount || {}).discountAmount || 0;
						let actualAmount = elementItem.ProductPrice - discountedCostAmount;
						let amount = svc.PercOrAmount == 1 ? this.utils.MidPointRoundOffTwo((actualAmount * svc.Percentage) / 100) : svc.ServiceCharge;
						svc.ServiceCharge = amount;
						svc.Amount = amount;
					}
				}
				elementItem.ServiceCharge = serviceChargeDetailBackup;

			}

		}
		//To Recalculate GTY
		if (!this._ss.isExcludeDiscOnGratuity && (elementItem.Gratuity || []).length > 0) {
			if (!this.CheckBreakPointAccess(GlobalConst.RetailBreakPoint.EditGratuity)) {
				return false;
			}
			if (elementItem.ItemType === 6) {
				const index = elementItem.LineNumber - 1;
				if ((this.depositArray || []).length > 0) {
					this.depositArray[index].gratuity = 0;
				}
			}
			var gratuityDetailBackup = elementItem.Gratuity;
			elementItem.Gratuity = null;

			if (!this.tempItemDetails || this.tempItemDetails == null) {
				this.tempItemDetails = { itemDetails: {}, item: this._ss.selectedProducts };
			}
			if (this.tempItemDetails) {
				if (gratuityDetailBackup && gratuityDetailBackup.length > 0) {
					for (let gty of gratuityDetailBackup) {
						let discountedCostAmount = discount && discount.discountPercentage > 0 ? (elementItem.ProductPrice * discount.discountPercentage) / 100 : (discount || {}).discountAmount || 0;
						let actualAmount = elementItem.ProductPrice - discountedCostAmount;
						let amount = gty.PercOrAmount == 1 ? this.utils.MidPointRoundOffTwo((actualAmount * gty.Percentage) / 100) : gty.gratuity;
						gty.gratuity = amount;
						gty.Amount = amount;
					}
				}
				elementItem.Gratuity = gratuityDetailBackup;
			}
		}

	}

	async applyAllDiscount(discount: ItemDiscount, validate: boolean = true) {
		if (discount.discountAmount < 0 && validate) {
			this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.ShopCaptions.InvalidAmount);
			return;
		}

		if (
			validate &&
			(discount.discountPercentage > 100 ||
				this.GridItems.some(
					(x) =>
						x.ischecked &&
						discount.discountAmount > x.element.Noofitems * Math.abs(x.element.ProductPrice)
				))
		) {
			this.utils.ShowErrorMessage(
				this.localization.captions.common.Warning,
				this.ShopCaptions.DiscountAmountGreaterThanActual,
				GlobalConst.ButtonType.YesNo,
				this.DiscountAllAmountExceedCallback.bind(this),
				[discount]
			);
			//this.applyAllPopover.hide();
			return;
		}
		//this.applyAllPopover.hide();
		var nonApplicable = [];
		var postData: ApplyDiscount[] = [];
		var listOfItems = this.GridItems.map((x) => x.element);
		let gridItemHasSvc = this.GridItems.some(GridItem => (GridItem.element.ServiceCharge || []).length > 0);
		let gridItemHasGty = this.GridItems.some(GridItem => (GridItem.element.Gratuity || []).length > 0);
		if (!this._ss.isExcludeDiscOnServiceCharge && gridItemHasSvc) {
			await this.UpdateTicket(TicketUpdateType.RemoveServiceChargeDirect);
		}
		if (!this._ss.isExcludeDiscOnGratuity && gridItemHasGty) {
			await this.UpdateTicket(TicketUpdateType.RemoveGratuityDirect);
		}

		for (let GridItem of this.GridItems) {

			// continue if it is not selected
			if (!GridItem.ischecked) continue;

			const elementItem = GridItem.element;
			// continue if return without flow for Giftcard Item
			if (elementItem.isGiftCardItem && this._ss.isReturnWithoutTicket) continue;

			// continue if the selected item is a packaged item
			if (elementItem.isGroupingKey && !elementItem.isPackagedItem) continue;

			let categoryId: number = 0;
			if (elementItem.isPackagedItem && this.SelectedRetailItemDetails && this.SelectedRetailItemDetails.length > 0) {
				var parentItem = this.SelectedRetailItemDetails.find((x) => x.retailItemDetail.id == elementItem.PackageItemId);
				categoryId = parentItem ? parentItem.retailItemDetail.category : categoryId;
			}
			var discountConfig = this.applyDiscountService.CategoryDiscountList.find(
				(x) => x.retailCategoryId == elementItem.category && x.eligibleForDiscount
			);
			if (!discountConfig) {
				nonApplicable.push(elementItem);
				continue;
			}
			else {
				this.RecalculateServiceChargeGratuityByDicount(elementItem, discount);
			}
			var packageDiscountConfig = this.applyDiscountService.CategoryDiscountList.find(
				(x) => x.retailCategoryId == categoryId && x.eligibleForDiscount
			);


			let percentConfig: DiscountTypeDetails;

			if (elementItem.isPackagedItem) {
				percentConfig = packageDiscountConfig
					? packageDiscountConfig.discountTypeDetails.find((x) => x.discountTypeId == discount.discountId)
					: null;
			} else {
				percentConfig = discountConfig.discountTypeDetails.find((x) => x.discountTypeId == discount.discountId);
			}

			var percent = percentConfig ? percentConfig.discountValue : discount.discountPercentage;
			if (percent > 0 && discount.discountPercentage == 0) {
				discount.discountPercentage = percent;
			}
			postData.push({
				index: this._ss.findSelectedItemIndexWithoutPackage(elementItem.LineNumber, listOfItems),
				isPercentage: percent > 0,
				value: percent > 0 ? percent : discount.discountAmount
			});
		}

		if (nonApplicable.length > 0) {
			this.utils.ShowErrorMessage(
				this.localization.captions.common.Error,
				this.localization.replacePlaceholders(
					this.ShopCaptions.DiscountNotEligibleForSomeItem,
					['ItemCount'],
					[nonApplicable.length]
				)
			);
		}
		// if (postData.length > 0) {
		// 	await this.ApplyDiscountToCheckData(postData, DiscountActions.ApplyDiscountToAllItems);
		// }

		let index = 0;
		for (let itemGrid of this.GridItems) {
			const elementGridItem = itemGrid.element;
			// continue if it is not selected
			// continue if the selected item is returned Item
			// continue if the selected item is a packaged item
			if (!itemGrid.ischecked || (elementGridItem.isGiftCardItem && this._ss.isReturnWithoutTicket) || (elementGridItem.isGroupingKey && !elementGridItem.isPackagedItem)) {
				index++;
				continue;
			}

			let categoryId: number = elementGridItem.category;
			if (elementGridItem.isPackagedItem && this.SelectedRetailItemDetails && this.SelectedRetailItemDetails.length > 0) {
				var parentItem = this.SelectedRetailItemDetails.find((x) => x.retailItemDetail.id == elementGridItem.PackageItemId);
				categoryId = parentItem ? parentItem.retailItemDetail.category : categoryId;
			}

			var discountConfig = this.applyDiscountService.CategoryDiscountList.find(
				(x) => x.retailCategoryId == elementGridItem.category && x.eligibleForDiscount
			);
			var packageDiscountConfig = this.applyDiscountService.CategoryDiscountList.find(
				(x) => x.retailCategoryId == categoryId && x.eligibleForDiscount
			);
			if (!discountConfig) {
				index++;
				continue;
			}

			let percentConfig: DiscountTypeDetails;

			if (elementGridItem.isPackagedItem) {
				percentConfig = packageDiscountConfig
					? packageDiscountConfig.discountTypeDetails.find((x) => x.discountTypeId == discount.discountId)
					: null;
			} else {
				percentConfig = discountConfig.discountTypeDetails.find((x) => x.discountTypeId == discount.discountId);
			}

			if (discount.discountPercentage > 0) {
				elementGridItem.DiscountPercentage = percentConfig ? percentConfig.discountValue : discount.discountPercentage;
				elementGridItem.Discount = percentConfig
					? this.utils.RoundOff2DecimalPlaces(
						elementGridItem.Noofitems * Math.abs(elementGridItem.ProductPrice) * percentConfig.discountValue / 100
					)
					: this.utils.RoundOff2DecimalPlaces(
						elementGridItem.Noofitems * Math.abs(elementGridItem.ProductPrice) * discount.discountPercentage / 100
					);
				elementGridItem.DiscountTypeId = percentConfig ? percentConfig.discountTypeId : 0;
				let checkDiscount = this._ss.GetCheckLineItemByIndex(this._ss.findSelectedItemIndexWithoutPackage(elementGridItem.LineNumber, listOfItems))?.discount || 0;
				if (checkDiscount != 0 && elementGridItem.Discount != checkDiscount) {
					elementGridItem.Discount = checkDiscount;
				}
			} else {
				elementGridItem.Discount = discount.discountAmount;
				elementGridItem.DiscountPercentage = 0;
				elementGridItem.DiscountTypeId = 0;
			}
			elementGridItem.discountComments = discount.discountComment;
			elementGridItem.discountReason = discount.discountReason;
			elementGridItem.isAutoMemDiscRemoved = true;

			this._ss.SelectedItemDiscount = this._ss.SelectedItemDiscount.filter((x) => x.itemId != elementGridItem.ItemId);
			discount.itemId = elementGridItem.ItemId;
			this._ss.SelectedItemDiscount.push({ ...discount });
			index++;
		}

		this.FormOrderSummary();
		await this.totalPrice();
		if ((!this._ss.isExcludeDiscOnServiceCharge || !this._ss.isExcludeDiscOnGratuity) && (gridItemHasSvc || gridItemHasGty)) {
			if (!this.tempItemDetails || this.tempItemDetails == null) {
				this.tempItemDetails = { itemDetails: {}, item: this._ss.selectedProducts };
			}
			await this.ServiceChargeCalculation(this.tempItemDetails?.itemDetails, this.tempItemDetails?.item)
		}
		else {
			await this.UpdateTicket(TicketUpdateType.ModifyServiceChargeAndGratuity);
		}
		this.exemptTaxForPaymentMethod();
		this.checkAll = false;
		this.enableApply = false;
		this.CheckForMultipackRedemption();
	}

	CheckDiscountAmount(discount: ItemDiscount) {
		var productdetails = this.currentSelectedItemForDiscount;
		var itemDiscount = discount;
		let discountAmt: number = 0;
		if (itemDiscount) {
			discountAmt =
				itemDiscount.discountPercentage > 0
					? this.utils.RoundOff2DecimalPlaces(
						productdetails.element.Noofitems *
						productdetails.element.ProductPrice *
						itemDiscount.discountPercentage /
						100
					)
					: itemDiscount.discountAmount;
		}

		if (Math.abs(discountAmt) > productdetails.element.Noofitems * Math.abs(productdetails.element.ProductPrice)) {
			return false;
		}
		return true;
	}

	async AmountExceedCallback(result: string, extraParams) {
		if (result.toUpperCase() == 'YES') {
			var postData: ApplyDiscount[] = [
				{
					index: this.applyDiscountInput.index,
					isPercentage: extraParams[0].discountPercentage > 0,
					value:
						extraParams[0].discountPercentage > 0
							? extraParams[0].discountPercentage
							: extraParams[0].discountAmount
				}
			];
			// await this.ApplyDiscountToCheckData(postData, DiscountActions.ApplyDiscountToItem);
			await this.UpdateOrAddDiscount(extraParams[0]);
		}
	}

	async applyDiscount(discount: ItemDiscount) {
		if (discount.discountAmount < 0) {
			this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.ShopCaptions.InvalidAmount);
			return;
		}
		if (this.discountPopover) this.discountPopover.hide();
		if (!this.CheckDiscountAmount(discount)) {
			this.utils.ShowErrorMessage(
				this.localization.captions.common.Warning,
				this.ShopCaptions.DiscountAmountGreaterThanActual,
				GlobalConst.ButtonType.YesNo,
				this.AmountExceedCallback.bind(this),
				[discount]
			);
			return;
		}
		let index = this.applyDiscountInput.index;
		let isPercentage = discount.discountPercentage > 0;
		let value = discount.discountPercentage > 0 ? discount.discountPercentage : discount.discountAmount;

		var postData: ApplyDiscount[] = [
			{
				index: index,
				isPercentage: isPercentage,
				value: value
			}
		];
		// await this.ApplyDiscountToCheckData(postData, DiscountActions.ApplyDiscountToItem);
		let elementItem = this._ss.selectedProducts[this.applyDiscountInput.index];
		var discountConfig = this.applyDiscountService.CategoryDiscountList.find(
			(x) => x.retailCategoryId == elementItem.category && x.eligibleForDiscount
		);
		if (discountConfig) {
			this.RecalculateServiceChargeGratuityByDicount(elementItem, discount);
		}
		await this.UpdateOrAddDiscount(discount);
	}

	async UpdateDiscountOnQuantityChange(productdetails: any, index: number, isFromQuantityUpdate : boolean = false) {
		this.currentSelectedItemForDiscount = productdetails;
		if (productdetails.element.Discount == 0) return;
		const itemDiscount: ItemDiscount = {
			itemId: productdetails.element.ItemId,
			discountId: productdetails.element.DiscountTypeId,
			discountPercentage: productdetails.element.DiscountPercentage,
			discountAmount: productdetails.element.DiscountPercentage > 0 ? 0 : productdetails.element.Discount,
			discountComment: productdetails.element.discountComment,
			discountReason: productdetails.element.discountReason
		};

		const isPercentage = itemDiscount.discountPercentage > 0;
		const value = itemDiscount.discountPercentage > 0 ? itemDiscount.discountPercentage : itemDiscount.discountAmount;

		const postData: ApplyDiscount[] = [
			{
				index: index,
				isPercentage: isPercentage,
				value: value
			}
		];
		await this.ApplyDiscountToCheckData(postData, DiscountActions.UpdateItemQuantity);
		await this.UpdateOrAddDiscount(itemDiscount, false, isFromQuantityUpdate);
	}

	async ApplyDiscountToCheckData(postData: ApplyDiscount[], discountAction: DiscountActions) {
		if (!postData || postData.length == 0) return;
		switch (discountAction) {
			case DiscountActions.ApplyDiscountToAllItems:
				this._ams.loaderEnable.next(this.ShopCaptions.AddDiscount);
				break;
			case DiscountActions.ApplyDiscountToItem:
				this._ams.loaderEnable.next(this.ShopCaptions.AddDiscount);
				break;
			case DiscountActions.RemoveDiscount:
				this._ams.loaderEnable.next(this.ShopCaptions.RemoveItemDiscount);
				break;
			case DiscountActions.RemoveItem:
				this._ams.loaderEnable.next(this.ShopCaptions.RemoveItem);
				break;
			case DiscountActions.UpdateItemQuantity:
				this._ams.loaderEnable.next(this.ShopCaptions.UpdateItemQuantity);
				break;
		}
		console.log("Calling inside Discount");
	}

	async removeDiscount(index: number, itemDetails: any) {

		const itemIndex = this._ss.findSelectedItemIndexWithoutPackage(
			itemDetails.element.LineNumber,
			this.GridItems.map((x) => x.element)
		);
		if (itemDetails.element.isPackagedItem) {
			itemDetails.element.Discount = 0;
			itemDetails.element.DiscountTypeId = 0;
			itemDetails.element.DiscountPercentage = 0;
			itemDetails.element.discountComments = '';
			itemDetails.element.discountReason = 0;
		}

		const discount: ItemDiscount = {
			itemId: itemDetails.itemId,
			discountId: 0,
			discountPercentage: 0,
			discountAmount: 0,
			discountReason: 0,
			discountComment: 'Remove Discount'
		}


		this.removeItemDiscount(discount, itemIndex);
	}

	async UpdateOrAddDiscount(discount: ItemDiscount, updateSource: boolean = true, isFromQuantityUpdate : boolean = false) {
		const data = this.currentSelectedItemForDiscount;
		if (
			updateSource &&
			this._ss.SelectedItemDiscount.length > 0 &&
			this._ss.SelectedItemDiscount.some((x) => x.itemId == data.element.ItemId)
		) {
			var itemDiscount = this._ss.SelectedItemDiscount.find((x) => x.itemId == data.element.ItemId);
			itemDiscount.discountId = discount.discountId;
			itemDiscount.discountAmount = discount.discountAmount;
			itemDiscount.discountPercentage = discount.discountPercentage;
			itemDiscount.discountComment = discount.discountComment;
			itemDiscount.discountReason = discount.discountReason;
		} else if (updateSource) {
			discount.itemId = data.element.ItemId;
			this._ss.SelectedItemDiscount.push(discount);
		}

		let discountAmt = 0;
		if (discount) {
			discountAmt =
				discount.discountPercentage > 0
					? this.utils.RoundOff2DecimalPlaces(
						data.element.Noofitems * Math.abs(data.element.ProductPrice) * discount.discountPercentage / 100
					)
					: discount.discountAmount;
		}

		data.element.Discount = discountAmt;
		data.element.isAutoMemDiscRemoved = data.element.isAutoMemDiscRemoved || !isFromQuantityUpdate;
		data.element.DiscountTypeId = discount.discountId;
		data.element.DiscountPercentage = discount.discountPercentage;
		data.element.discountComments = discount.discountComment;
		data.element.discountReason = discount.discountReason;
		await this.UpdateTicket(TicketUpdateType.ModifyServiceChargeAndGratuity);
		this.FormOrderSummary();
		this.CheckForMultipackRedemption();
	}

	RoundOffTwo(Amount): number {
		return Number(Amount.customToFixed());
	}

	setIdx(event, index) {
		this.isOpened = true;
		if (this.openedIdx == -1) {
			this.openedIdx = index;
		}
		this.onDiscountShown(event, index);
	}

	clearIdx() {
		this.isOpened = false;
		this.openedIdx = -1;
	}

	async LoadDiscountData() {
		this.applyDiscountService.getActiveDiscountTypes();
		this.applyDiscountService.getDiscountsForItem(0);
	}

	GetTicketTotal(): number {
		if (this.PropertyInfo.UseRetailInterface) {
			let dAmt: number = Number(this.totalAmount);
			let dTax: number = this.exempttax.value ? 0 : Number(this.taxValue);
			this.TicketTotal = Number((dAmt + dTax).customToFixed());
		}
		else {
			this.TicketTotal = this.exempttax.value ? this.totalAmountWithOutTax : this.totalAmount;
		}
		return this.TicketTotal;
	}

	dateChanged(event) {
		console.log('event ', event);
	}

	searchValueChange(event) {
		console.log('event ', event);
	}

	showRainCheckSectionForIG = false;
	showRainCheckButtonIG = false;
	async rainCheckIG() {
		this.showRainCheckSectionForIG = !this.showRainCheckSectionForIG;
		this.rainCheckSelection = 0;
		if (!this.showRainCheckSectionForIG) {
			this.removeRainCheckItem();
		}
	}

	public showRainCheckIG(): boolean {
		this.showRainCheckButtonIG = this._ss.selectedProducts.some((x) => x.payeeId && x.playerName && x.playerName != '');
		return this.showRainCheckButtonIG;
	}

	async checkAllRainCheckItemRemoved() {
		if (!this._ss.selectedProducts.some((x) => x.rainCheckItemId > 0)) {
			this.SelectedRainCheck = null;
			this.rainCheckSelection = 0;
			this.ResetRainCheckSelection = true;
		}
	}

	async addRainCheckItem() {
		if (
			// With IG interface
			this.PropertyInfo.UseRetailInterface &&
			// Raincheck section is active
			this.showRainCheckSectionForIG &&
			// // rain check is selected
			!this._ss.selectedProducts.some((x) => x.rainCheckItemId > 0)
		) {
			let RCItems = [];
			let defaultFeeRetailItemId = await RetailDataAwaiters.getRainCheckRedeemIds(this._ss.SelectedOutletId);
			// handle if raincheck items are configured
			if (
				!defaultFeeRetailItemId ||
				(defaultFeeRetailItemId &&
					defaultFeeRetailItemId.greenFeeItem == 0 &&
					defaultFeeRetailItemId.greenFeeItem == 0)
			) {
				this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(777001));
				this.SelectedRainCheck = null;
				this.rainCheckSelection = 0;
				this.ResetRainCheckSelection = true;
				return;
			} else {
				// handle if the items are not found
				RCItems = await this._ss.getRetailItemsDetailedInfoByIds([defaultFeeRetailItemId.greenFeeItem, defaultFeeRetailItemId.cartFeeItem]);
				if (RCItems && RCItems.length > 0 && !RCItems.some(i => i.outletItem.some(o => o.outletId == this._ss.SelectedOutletId))) {
					this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(777002));
					this.SelectedRainCheck = null;
					this.rainCheckSelection = 0;
					this.ResetRainCheckSelection = true;
					return;
				}
			}
			// add green fee
			let greenFeeItem = RCItems.find(
				(r) => r.retailItemDetail.id == defaultFeeRetailItemId.greenFeeItem
			);
			let cartFeeItem = RCItems.find(
				(r) => r.retailItemDetail.id == defaultFeeRetailItemId.cartFeeItem
			);

			if (greenFeeItem && this.SelectedRainCheck && this.SelectedRainCheck.greenFee > 0) {
				this._ss.selectedProducts.push({
					ItemId: greenFeeItem.id,
					ExternalPOSItemId: greenFeeItem.retailItemDetail.externalPOSId,
					ItemDescription: greenFeeItem.retailItemDetail.itemDescription,
					ItemType: greenFeeItem.retailItemDetail.itemType,
					ServiceId: 0,
					SalesPrice: -this.SelectedRainCheck.greenFee,
					ProductName: greenFeeItem.retailItemDetail.itemDescription,
					ProductPrice: -this.SelectedRainCheck.greenFee,
					Noofitems: 1,
					Discount: 0,
					DiscountTypeId: 0,
					DiscountPercentage: 0,
					category: greenFeeItem.retailItemDetail.category,
					isGroupingKey: false,
					isPackagedItem: false,
					PackageItemId: 0,
					MultiPack: false,
					ClientMultiPackId: 0,
					PackageGroupId: 0,
					isOpenPricedItem: false,
					rainCheckItemId: this.rainCheckSelection,
					isReturn: true,
					isModificationRestricted: true,
					costPrice: greenFeeItem.retailItemDetail.costPrice,
					marginPercentage: greenFeeItem.retailItemDetail.marginPercentage,
					allowEarn: greenFeeItem.retailItemDetail.allowEarn,
					discountComments: '',
					discountReason: 0
				});
				this.showReturnReason = false;
			}
			if (cartFeeItem && this.SelectedRainCheck && this.SelectedRainCheck.cartFee > 0) {
				this._ss.selectedProducts.push({
					ItemId: cartFeeItem.id,
					ExternalPOSItemId: cartFeeItem.retailItemDetail.externalPOSId,
					ItemDescription: cartFeeItem.retailItemDetail.itemDescription,
					ItemType: cartFeeItem.retailItemDetail.itemType,
					ServiceId: 0,
					SalesPrice: -this.SelectedRainCheck.cartFee,
					ProductName: cartFeeItem.retailItemDetail.itemDescription,
					ProductPrice: -this.SelectedRainCheck.cartFee,
					Noofitems: 1,
					Discount: 0,
					DiscountTypeId: 0,
					DiscountPercentage: 0,
					category: cartFeeItem.retailItemDetail.category,
					isGroupingKey: false,
					isPackagedItem: false,
					PackageItemId: 0,
					MultiPack: false,
					ClientMultiPackId: 0,
					PackageGroupId: 0,
					isOpenPricedItem: false,
					rainCheckItemId: this.rainCheckSelection,
					isReturn: true,
					isModificationRestricted: true,
					costPrice: greenFeeItem.retailItemDetail.costPrice,
					marginPercentage: greenFeeItem.retailItemDetail.marginPercentage,
					allowEarn: cartFeeItem.retailItemDetail.allowEarn,
					discountComments: '',
					discountReason: 0
				});
				this.showReturnReason = false;
			}
			this.FormOrderSummary();
			this.totalPrice();
		}
	}

	async removeRainCheckItem() {
		this._ss.selectedProducts = this._ss.selectedProducts.filter((x) => !x.rainCheckItemId);
		this.showReturnReason = true;
		this.FormOrderSummary();
		await this.totalPrice();
	}

	async isRaincheckAdded() {
		let rainCheckLine = this._ss.selectedProducts.find((x) => x.rainCheckItemId > 0);
		if (rainCheckLine) {
			await this.rainCheckIG();
			await this.RainCheckGrid();
			let rainCheckItem = this.rainCheckTransaction.find((x) => x.id == rainCheckLine.rainCheckItemId);
			this.SelectedRainCheck = rainCheckItem;
			this.rainCheckSelection = rainCheckItem.id;
		}
	}

	async RainCheckGrid() {
		let raincheckdate: Date = this.RainCheckForm.get('issuedate').value;
		let rainchecks: RainCheckIssue[] = await RetailDataAwaiters.getRainChecks(
			raincheckdate,
			this.RainCheckForm.get('searchText').value
		);
		this.rainCheckTransaction = rainchecks;
		this.IsRainCheckGridData = (rainchecks.length != 0);
		this.rainCheckTransaction.forEach((check) => {
			check.issuedDateDisplay = `${this.localization.localizeDisplayDate(
				rainchecks[0].issuedDate,
				true
			)}  ${this.localization.LocalizeTime(rainchecks[0].issuedDate, true)}`;
		});
		this.rainCheckTransactionClone = _.cloneDeep(rainchecks);
		this.SelectedRainCheck = null;
		this.rainCheckSelection = 0;
		this.ResetRainCheckSelection = true;
	}

	HandleRaincheckEvents(RainCheckEvent: { event: RainCheckEventType, data: any }) {
		const event = RainCheckEvent.event;
		this.SelectedRainCheck = RainCheckEvent?.data;
		this.rainCheckSelection = this.SelectedRainCheck?.id ? this.SelectedRainCheck?.id : 0;
		switch (event) {
			case RainCheckEventType.AddRaincheckItem:
				this.showRainCheckSectionForIG = true;
				this.addRainCheckItem();
				break;
			case RainCheckEventType.RemoveRainCheckItem:
				this.removeRainCheckItem();
				break;
			default:
				break;
		}
	}


	async GetTransactionPlayerDetails(transactionId: number) {
		let transactionDetailAggregate = await RetailDataAwaiters.GetPlayerNamesByTransactionId(transactionId);
		console.log(transactionDetailAggregate.playerTransactionDetails);
		if (
			this._ss.selectedProducts.length > 0 &&
			transactionDetailAggregate.playerTransactionDetails &&
			transactionDetailAggregate.playerTransactionDetails.length > 0
		) {
			this.SettleTransactionDetail = transactionDetailAggregate;
			this._ss.selectedProducts.forEach((p) => {
				let transaction = transactionDetailAggregate.playerTransactionDetails.find(
					(t) => t.transactionDetailId == p.id
				);
				if (transaction) {
					p.playerName = transaction.playerName;
					p.payeeId = transaction.playerId;
				}
			});
			if (this._ss.selectedProducts.some((x) => x.retailItemType == RetailItemType.Deposit && x.ProductPrice > 0)) {
				this._ss.depositFlag = true;
				let depositAll: DepositEventModel[] = [];
				this._ss.selectedProducts.filter(x => x.ItemType == RetailItemType.Deposit).forEach((player) => {
					depositAll.push({
						typeId: player.payeeId,
						amount: player.ProductPrice,
						depositTransactionId: 0
					} as DepositEventModel);
				});
				this.depositArray = depositAll;
			}
		}
		this.selectedCourse = transactionDetailAggregate.teeTicketDetail.courseId;
		this.scheduleTeeTimeIds = transactionDetailAggregate.teeTicketDetail.scheduledTeeTimeIds;
		this.selectedDate = transactionDetailAggregate.teeTicketDetail.scheduleDateTime.toString();
		this.reOpenTransaction = this._ss.reOpenTransaction;
		this.settleOpenTransaction = this._ss.settleOpenTransaction;
		this.correcttransaction = this._ss.correctTransaction;
		this.oldTransactionId = this._ss.transactionId;
		this.FormOrderSummary();
	}

	async GetAppointmentClientDetails(transactionId: number) {
		const appointmentTransactions = await RetailDataAwaiters.GetAppointmentClientDetails(transactionId);
		const clientDetailsWithType = await RetailDataAwaiters.getPayeeDetails(appointmentTransactions.map(x => x.clientId));
		const depositTransactions = appointmentTransactions.filter(x => x.transactionDetailId == -1 && x.retailItemId == -1);
		if (
			this._ss.selectedProducts.length > 0 &&
			appointmentTransactions &&
			appointmentTransactions.length > 0 &&
			clientDetailsWithType?.length > 0
		) {
			this._ss.selectedProducts.forEach((p) => {
				let transaction = appointmentTransactions.find(
					(t) => t.transactionDetailId == p.id
				);
				if (transaction) {
					p.clientName = transaction.clientName;
					p.clientId = transaction.clientId;
					const clientDetail = clientDetailsWithType.find(x => x.id == transaction.clientId);
					p.clientType = clientDetail?.guesttype;
					p.clientLinkId = clientDetail?.playerLinkId;
				}
				else {
					if (p.retailItemType == RetailItemType.Deposit) {
						if (depositTransactions && depositTransactions.length > 0) {
							const removeItemIndex = depositTransactions.findIndex(x => x.amount == p.SalesPrice);
							if (removeItemIndex != -1) {
								const removeItem = depositTransactions[removeItemIndex];
								if (removeItem) {
									p.clientName = removeItem.clientName;
									p.clientId = removeItem.clientId;
									depositTransactions.splice(removeItemIndex, 1);
								}
							}
						}
					}
				}
			});
		}
		this.reOpenTransaction = this._ss.reOpenTransaction;
		this.settleOpenTransaction = this._ss.settleOpenTransaction;
		this.correcttransaction = this._ss.correctTransaction;
		this.oldTransactionId = this._ss.transactionId;
		this.FormOrderSummary();
	}

	async ValidateItemsForSettleFlow() {
		if (this._ss.ProductId == GlobalConst.Product.GOLF && (this._ss.settleOpenTransaction || this._ss.reOpenTransaction)) {
			await this.GetTransactionPlayerDetails(this._ss.transactionId);
			let defaultFeeRetailItemId = await RetailDataAwaiters.getRainCheckRedeemIds(this._ss.SelectedOutletId);
			if (defaultFeeRetailItemId) {
				this.showReturnReason = this._ss.selectedProducts.some(x => x.isReturn) && this._ss.selectedProducts.every(x => x.ItemId != defaultFeeRetailItemId.cartFeeItem && x.ItemId != defaultFeeRetailItemId.greenFeeItem);
				this._ss.selectedProducts.forEach((x) => {
					if (x.ItemId == defaultFeeRetailItemId.cartFeeItem || x.ItemId == defaultFeeRetailItemId.greenFeeItem)
						x.rainCheckItemId = x.ItemId;
				});
				this.correcttransaction = this._ss.correctTransaction;
				this.oldTransactionId = this._ss.transactionId;
				this.FormOrderSummary();
			}
		}
	}


	onShown(e, loopIndex) {
		let popOverTargetPosition = this.popOverTarget.nativeElement.getBoundingClientRect();
		let windowWidth = window.innerWidth;
		let popOverWidth = this.myPopover.element.nativeElement.childNodes[0].offsetWidth;
		let popOverHeight = this.myPopover.element.nativeElement.childNodes[0].offsetHeight;
		let offsetRight = 0;
		let targetLeft = popOverTargetPosition.left;
		offsetRight = windowWidth;
		let checkRightPosition = offsetRight - popOverWidth;

		if (targetLeft < popOverWidth / 2) {
			e.content.left = 0;
			let calcArrow = 20;
			this.checkArrowTimeout(calcArrow, 0);
		} else if (targetLeft > checkRightPosition) {
			e.content.left = checkRightPosition;
			let calcArrow =
				(windowWidth - popOverTargetPosition.left) / popOverWidth -
				popOverTargetPosition.width / 2 / popOverWidth;
			if (calcArrow * 100 < 10) {
				this.checkArrowTimeout((1 - popOverTargetPosition.width / 2 / popOverWidth) * 100, 0);
			} else {
				this.checkArrowTimeout((1 - calcArrow) * 100, 0);
			}
		}

		let windowHeight = window.innerHeight;
		let targetTopPosition = document.getElementsByClassName('i-info')[loopIndex].getBoundingClientRect().top;
		if (windowHeight - targetTopPosition < popOverHeight || windowHeight - targetTopPosition < 110) {
			// first time it cant find the offset height below the viewport so static height 110 is set
			if (popOverHeight < 100) {
				popOverHeight = 110;
			}
			e.content.top = targetTopPosition - popOverHeight - 5; //10 -span height
			e.content.effectivePlacement = 'top in';
		} else {
			e.content.top = targetTopPosition + 10; //10 -span height
			e.content.effectivePlacement = 'bottom in';
		}
	}

	checkArrowTimeout(arrowPosition, arrIndex) {
		let checkArrow = setTimeout(() => {
			let className = 'order-summary-popover';
			let popOverArrow = document
				.getElementsByClassName(className)
			[arrIndex].querySelectorAll('.arrow')[0] as HTMLElement;
			if (popOverArrow) {
				popOverArrow.style.left = arrowPosition + '%';
				clearTimeout(checkArrow);
			}
		}, 0);
	}

	DisableMoreOption(item, ordersummary) {
		return !item.isactive || (this._ss.isFromSncBeo && ordersummary.element.ItemType == 10 || (ordersummary.element.ItemType == 6 && this._ss.ProductId != GlobalConst.Product.SPA))
	}

	async HandlePostPaymentRedirection() {
		const result = await RetailDataAwaiters.getTeeTimeSetting();
		const redirectToGraphicalView = result && result.defaultToGraphicalTeeSheet;
		let redirectRoute = RedirectToModules.retail;
		if (this._ss.cancelFlag || this._ss.depositFlag) {
			this._ss.depositFlag = false;
			this._ss.cancelFlag = false;
			redirectRoute = this._ss.RedirectModule ? this._ss.RedirectModule : RedirectToModules.RetailTransactions;
		} else if (this._ss.isAppointmentCheckOut) {
			this._ss.isAppointmentCheckOut = false;
			if (!this._ss.isFromDayEnd) {
				if (this._ss.RedirectModule) {
					redirectRoute = this._ss.RedirectModule;
				}
				else {
					redirectRoute = RedirectToModules.appointment;
				}
			}
		}
		else if (this._ss.isFromUnpaidPlayer) {
			this._ss.isFromUnpaidPlayer = false;
			this._ss.SelectedPlayers = [];
			redirectRoute = redirectToGraphicalView ? RedirectToModules.TeeTimeGraphicalView : RedirectToModules.TeeSheet;
			this._ss.isFromGiftCard = false;
		} else if (this._ss.isFromDayEnd) {
			this._ss.isFromDayEnd = false;
			this._ss.isAppointmentCheckOut = false;
			redirectRoute = RedirectToModules.Dayend;
		} else if (
			this._ss.isFromEditTeeTime ||
			this._ss.isFromDayEnd ||
			this.settleOpenTransaction ||
			this.reOpenTransaction ||
			this._ss.isFromGiftCard
		) {
			this._ss.isFromUnpaidPlayer = false;
			this._ss.SelectedPlayers = [];
			let isFromGraphicalView = this._ss.isFromTeetimeGraphicalView ? RedirectToModules.TeeTimeGraphicalView : RedirectToModules.TeeTime;
			let isFromDayEnd = this._ss.isFromDayEnd ? RedirectToModules.Dayend : isFromGraphicalView;
			redirectRoute =
				this._ss.isFromUnpaidPlayer || this._ss.settleOpenTransaction || this.reOpenTransaction || this._ss.isFromGiftCard
					? RedirectToModules.retail : this._ss.isFromEditTeeTime && redirectToGraphicalView ? RedirectToModules.TeeTimeGraphicalView : isFromDayEnd;
			this._ss.isFromGiftCard = false;

		} else if (this._ss.isFromRetailDashBoard) {
			this._ss.isFromRetailDashBoard = false;
			redirectRoute = RedirectToModules.home;
		} else {
			redirectRoute = RedirectToModules.retail;
		}
		this.ResetSummaryData();
		this.HandlePostPaymentRedirectionActivities(redirectRoute); // Activities - retail integration changes
	}


	async onPaymentTransactionClick() {
		const property = await this._ss.GetPropertySettings();
		const isSystemDateEqual = this.utils.ValidateDatesAreEqual(this.utils.getDate(property.propertyDate), this.PropertyInfo.CurrentDate);
		if (!isSystemDateEqual) {
			this.utils.showAlert(this.localization.getError(-4701), AlertType.Info, GlobalConst.ButtonType.YesNo, async (res) => {
				this.isCreateTransactionValidationError = false;
				if (res === AlertAction.YES) {
					await this.triggerInitiatePay();
				}
				else {
					setTimeout(() => { this.isCreateTransactionValidationError = true; }, 0);
				}
			});
		}
		else {
			await this.triggerInitiatePay();
		}
	}

	async triggerInitiatePay() {
		let quickIdConfig = this._propertyInfoService.getQuickIdConfig;
		if (!this.quickLoginPopupEnabled) {
			if (quickIdConfig && quickIdConfig.QUICKID_RETAILTRANSACTIONS) {
				const quickLoginDialogRef = this.commonUtils.QuickLogin();
				quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
					this.isCreateTransactionValidationError = false;
					if (quickLoginDialogResult.isLoggedIn) {
						await this.initiatePay();
						this.quickLoginPopupEnabled = true;
					}
					else {
						setTimeout(() => { this.isCreateTransactionValidationError = true; }, 0);
					}
				});
			}
			else {
				await this.initiatePay();
				this.quickLoginPopupEnabled = true;
			}
		}
		else {
			await this.initiatePay();
			this.quickLoginPopupEnabled = true;
		}

	}

	async initiatePay() {
		if (this.PropertyInfo.UseRetailInterface) {
			await this.SendForPayment();
		} else {
			await this.ProceedTransaction();
		}
	}

	initiateCancel(): void {
		if (this._ss.correctTransaction && this._ss.voidedTransactionId > 0) {
			let dialogRef = this.openVoidTxnPopup();
			dialogRef.afterClosed().subscribe((result) => {
				if (result.toLowerCase() === this.oCaptions.common.Yes.toLowerCase()) {
					// UnDoCheckOut if any appointments associated with the voided transaction
					let eventData: RetailEventParameters<VoidEventModel> = {
						data: {
							transactionId: this._ss.transactionId,
							isDeposit: false,
							ticketNumber: this._ss.ticketNumber
						},
						eventType: RetailEventType.Void
					};
					retailPublisher.publishEvent(eventData);

					// Clear shop objects
					this.ResetShopObj();
					this._ss.SelectedItemDiscount = [];
				} else {
					return;
				}
			});
		} else {
			this.CancelEmit();
			this.ResetShopObj();
		}
	}

	openEmailReceipt() {
		this.dialog.open(EmailReceiptComponent, {
			height: '450px',
			width: '500px',
			panelClass: 'ag_dialog--lg',
			data: this._retailService.manualEmailId
		}).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
			this._retailService.manualEmailId = res.controls['mail'].value.map(x => x.emailValue);
		});
	}

	OnTransactionComplete(eventData) {
		if (eventData?.from == ActionMode.cancel) return; //Safe check to prevent Versa event emits
		let selectedPayment: PaymentMethod = eventData?.selectedPayment;
		this.selectedpayment = selectedPayment;
		if (selectedPayment && selectedPayment.paymentTypeId && this.utils.GetOriginalTenderId(selectedPayment.paymentTypeId, selectedPayment.parentTypeId) == PaymentMethods.PendingSettlement) {
			this.ProcessPendingSettlement();
		} else {
			this.PerformCloseTransactionUpdate();
			this.PromptCaddyShackCommentDialog(this.PropertyInfo.UseRetailInterface);
		}
	}

	OnRainCheckSelectionReseted(e) {
		this.ResetRainCheckSelection = false;
	}

	onDiscountShown(e, loopIndex) {
		let popOverTargetPosition = this.popOverTarget.nativeElement.getBoundingClientRect();
		let windowWidth = window.innerWidth;
		let popOverWidth = this.discountPopover.element.nativeElement.childNodes[1].offsetWidth;
		let popOverHeight = this.discountPopover.element.nativeElement.childNodes[1].offsetHeight;
		let offsetRight = 0;
		let headerHeight = 60;
		let orderSummaryNavigationDivHeight = 50;
		let clientDetailsHeight = document.getElementById("client-details") ? document.getElementById("client-details").offsetHeight : 0;
		let clientTypeHeight = document.getElementById("client-type") ? document.getElementById("client-type").offsetHeight : 0;
		let searchTabHeight = document.getElementById("search-tab") ? document.getElementById("search-tab").offsetHeight : 0;
		let headerButtonHeight = document.getElementById("header-button") ? document.getElementById("header-button").offsetHeight : 0;
		let targetLeft = popOverTargetPosition.left;
		offsetRight = windowWidth;
		let checkRightPosition = offsetRight - popOverWidth;

		if (targetLeft < popOverWidth / 2) {
			e.content.left = 0;
			let calcArrow = 20;
			this.checkDiscountArrowTimeout(calcArrow, 0);
		} else if (targetLeft > checkRightPosition) {
			e.content.left = checkRightPosition;
			let calcArrow =
				(windowWidth - popOverTargetPosition.left) / popOverWidth -
				popOverTargetPosition.width / 2 / popOverWidth;
			if (calcArrow * 100 < 10) {
				this.checkDiscountArrowTimeout((1 - popOverTargetPosition.width / 2 / popOverWidth) * 100, 0);
			} else {
				this.checkDiscountArrowTimeout((1 - calcArrow) * 100, 0);
			}
		}

		let windowHeight = window.innerHeight;
		let targetTopPosition = document.getElementsByClassName('i-info')[loopIndex].getBoundingClientRect().top;
		if (windowHeight - targetTopPosition < popOverHeight || windowHeight - targetTopPosition < 110) {
			// first time it cant find the offset height below the viewport so static height 110 is set
			if (popOverHeight < 100) {
				popOverHeight = 110;
			}
			e.content.top = targetTopPosition - popOverHeight - 5; //10 -span height
			e.content.effectivePlacement = 'top in';
		} else {
			e.content.top = targetTopPosition + headerHeight + orderSummaryNavigationDivHeight + clientDetailsHeight + clientTypeHeight + searchTabHeight + headerButtonHeight + 108 + ((1 + loopIndex) * 42);// 108 px from the top section margins & 42 for the table row height
			e.content.left = targetTopPosition - 70;
			e.content.effectivePlacement = 'bottom in';
		}
	}

	checkDiscountArrowTimeout(arrowPosition, arrIndex) {
		let checkArrow = setTimeout(() => {
			let className = 'discount-popover';
			let popOverArrow = document
				.getElementsByClassName(className)
			[arrIndex].querySelectorAll('.arrow')[0] as HTMLElement;
			if (popOverArrow) {
				popOverArrow.style.left = arrowPosition + '%';
				clearTimeout(checkArrow);
			}
		}, 0);
	}

	GetRequiredBreakPoints() {
		const requiredBreakPoints = [
			GlobalConst.RetailBreakPoint.CreateTransaction,
			GlobalConst.RetailBreakPoint.EditGratuity,
			GlobalConst.RetailBreakPoint.EditCommission,
			GlobalConst.RetailBreakPoint.EditServiceCharge,
			GlobalConst.RetailBreakPoint.ApplyDiscount
		];
		this.BPointMap = this._ss.GetBreakPointAccessMap(requiredBreakPoints);
		this.facadeService.getUserAccess(UserAccessBreakPoints.FOLIOMENU, false).then(o => {
			this.folioMenubp = o.isAllow;
		});
	}

	CheckBreakPointAccess(breakpoint: GlobalConst.RetailBreakPoint) {
		if (this.BPointMap.get(breakpoint)) {
			return true;
		} else {
			this._ss.showBPMessage(breakpoint);
			return false
		}
	}

	HandleSurchargeUpdates(surcharge: number) {
		this.totalSurcharge = surcharge;
	}

	ResetSelectedProductMultipackPrice() {
		if (this._ss.selectedProducts.some(x => x.MultiPack)) {
			this._ss.selectedProducts.forEach(x => {
				let selectedRetailItem = this._ss.SelectedRetailItemDetails.find(y => y.id == x.ItemId);
				if (x.MultiPack && x.multiPackTransactionDetailId == 0 && x.ClientMultiPackId == 0) {
					x.DisplayPrice = selectedRetailItem.retailItemDetail.salesPrice;
					x.ProductPrice = selectedRetailItem.retailItemDetail.salesPrice;
					x.MemberPrice = selectedRetailItem.retailItemDetail.memberPrice;
					x.costPrice = selectedRetailItem.retailItemDetail.costPrice;
				}
			});
		}
	}

	selectMethod($event) {
		this.selectedpayment = $event[0];
	}

	viewFolioInNewTab() {
		if (this.folioMenubp) {
			if (this.payeeInfo?.guestProfileId) {
				let folioInput = {
					sourceType: 5,
					sourceTypeId: this.payeeInfo?.guestProfileId,
					folioNumber: this.payeeFolioInvoiceNumber,
					folioInvoiceNumber: this.payeeFolioInvoiceNumber,
					fullName: this.payeeInfo?.name ?? '',
					guestName: this.payeeInfo?.name ?? '',
					guestFirstName: this.payeeInfo?.firstName ?? '',
					guestLastName: this.payeeInfo?.lastName ?? '',
					guestEmailId: this.payeeInfo?.emailId,
					guestPhoneNumber: this.payeeInfo?.phoneNumber,
					guestAddress: this.payeeInfo?.address
				}
				localStorage.setItem("checkFromNewTab", JSON.stringify(folioInput));
				let productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
				let url;
				if (productId == Product.SPA) {
					url = '/Spa/folio/foliosearch';
				} else {
					url = '/Golf/folio/foliosearch';
				}
				this.router.navigate([], {
				}).then(result => { window.open(url, '_blank'); });
			}
		} else {
			this.userAccessService.showBreakPointPopup(this.localization.captions[UserAccessBreakPoints.FOLIOMENU]);
		}
	}

	FetchActiveFolioInvoiceNumberForPayee() {
		let guestId;
		if (this._ss.ProductId == Product.SPA && (this.payeeInfo?.guesttype == GlobalConst.ClientType.Member || this.payeeInfo?.playerCategoryId == PlayerCategory.Member)) {
			guestId = this.payeeInfo.guestId;
			this._ams.memberGuestId = this.payeeInfo.guestId;
		}
		else {
			guestId = this.payeeInfo?.guestProfileId;
		}
		if (guestId && this.PropertyInfo.IsResortFinanceEnabled) {
			this.http.CallApiAsync<string>({
				host: GlobalConst.Host.payment,
				callDesc: RetailRoutes.GetActiveFolioInvoiceNumberForPayee,
				method: HttpMethod.Get,
				uriParams: { sourceType: 5, sourceTypeId: guestId }
			}).then(t => {
				this.payeeFolioInvoiceNumber = t.result;
			});
		}
		else {
			this.payeeFolioInvoiceNumber = '';
		}
	}

	async GetAppointmentTherapistDetails() {
		if (this._ss.transactionId) {
			let apptResponse = await this.http.CallApiAsync({
				callDesc: RetailRoutes.GetAppointmentByTransactionId,
				host: GlobalConst.Host.schedule,
				method: HttpMethod.Get,
				uriParams: { transactionId: this._ss.transactionId }
			});
			this.transactionDetailTherapistMap.clear();
			const AddTherapistMap = (transactionDetailId: number, therapistIds: string[]) => {
				if (!this.transactionDetailTherapistMap.has(transactionDetailId))
					this.transactionDetailTherapistMap.set(transactionDetailId, therapistIds);
			}
			if (apptResponse && apptResponse.result) {
				const apptDetail: any = apptResponse.result;
				if (apptDetail && apptDetail.length > 0) {
					apptDetail.map(appt => {
						if (appt.appointmentTherapists && appt.appointmentTherapists.length > 0) {
							const currentApptTherapist = appt.appointmentTherapists.filter(x => x.appointmentId == appt.id)
							let therapistIds = [];
							currentApptTherapist.map(t => { therapistIds.push(`T${t.therapistId}`) });
							AddTherapistMap(appt.appointmentDetail.transactionDetailId, therapistIds);
						}
						if (appt.appointmentAddOn && appt.appointmentAddOn.length > 0) {
							appt.appointmentAddOn.map(addOn => {
								AddTherapistMap(addOn.transactionDetailId, [`T${addOn.therapistId}`]);
							});
						}
					});
					let apptFullInfo: any = await this.http.CallApiAsync({
						callDesc: "GetAppointmentById",
						host: GlobalConst.Host.schedule,
						method: HttpMethod.Get,
						uriParams: { id: apptDetail[0].id }
					});
					if (apptFullInfo?.result?.appointmentBillingDetail?.billingDetail) {
						console.log(apptFullInfo);
						const billingDetails = JSON.parse(apptFullInfo?.result?.appointmentBillingDetail?.billingDetail);
						this._ss.GuaranteeMethodData = billingDetails ? billingDetails.guaranteePaymentMethod : null;
					}
				}
			}
		}
	}

	isPlatformGuestSearchChanged(e) {
		this.isPlatformGuestSearch = Boolean(e[0]);
	}
	PerformRetailMultiPackRedemption(selectedProducts: SelectedProducts[]) {
		try {
			let multiPackIds = Array.from(new Set(selectedProducts.filter(x => x.MultiPack && x.ClientMultiPackId > 0)?.map(x => x.ClientMultiPackId)));
			if (multiPackIds.length > 0) {
				this.http.CallApiAsync({
					callDesc: RetailRoutes.RedeemMultiPacks,
					method: HttpMethod.Put,
					host: GlobalConst.Host.retailPOS,
					body: multiPackIds
				});	
			}			
		} catch (err) {
			console.log("Error while redeeming retail multipack" + err);
		}
	}
}

export interface summary {
	id: number;
	product_id: number;
	items: string;
	unitprice: number;
	quantity: number;
}

export enum TicketUpdateType {
	ModifyServiceChargeAndGratuity = 1,
	RemoveServicecharge,
	RemoveGratuity,
	RemoveServiceChargeAndGratuity,
	RemoveServiceChargeDirect,
	RemoveGratuityDirect
}

export enum DiscountActions {
	ApplyDiscountToAllItems = 1,
	ApplyDiscountToItem,
	RemoveDiscount,
	UpdateItemQuantity,
	RemoveItem
}
