import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { PartyService } from '@app/shared/services/party.service';
import { buttonTypes, CancelActivityType } from '@constants/commonenums';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { PopupService } from '@popup-module/popup.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cancel-activity-popup',
  templateUrl: './cancel-activity-popup.component.html',
  styleUrls: ['./cancel-activity-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CancelActivityPopupComponent implements OnInit, AfterViewInit {

  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  public confirmationMessage: string;
  public dialogTitle: string;
  hideTitle: boolean;
  @Input() data: any;
  buttonValueOk: ButtonValue;
  isInitial = true;
  showAlert = false;
  cancelConfig: FieldConfig[];
  subscriptions: Subscription = new Subscription();
  displayOptions = false;
  constructor(private ps: PopupService, private cdf: ChangeDetectorRef, private partyService: PartyService) {
  }

  ngOnInit() {
    this.buttonValueOk = {
      type: buttonTypes.actionSecondary,
      label: 'ok',
    };
    this.dialogTitle = this.data[0].dialogTitle;
    this.confirmationMessage = this.data[0].confirmationMessage;
    this.showAlert = this.data[0].showAlert ? this.data[0].showAlert : false;
    if (this.dialogTitle == null || this.dialogTitle === '') { this.hideTitle = true; }
    this.displayOptions = this.data[0].displayOptions;
    this.cancelConfig = this.data[0].configOptions || [
      {
        type: 'radio',
        name: 'cancelActivity',
        placeholder: 'Cancel Activity',
        options: [{ id: CancelActivityType.OnlyThis, value: 'Cancel this session Only' }, { id: CancelActivityType.AllRemaining, value: 'Cancel all remaining sessions of this reservation' }],
        value : CancelActivityType.OnlyThis,
        class: 'cancel-activity-popup__cancel',
        appearance: true,
        showErrorText: true
      }
    ];

    this.partyService.cancelType = CancelActivityType.OnlyThis; // initializing the cancelType as
  }

  ngAfterViewInit() {
    this.cdf.detectChanges();
    if(this.data[0].displayOptions){
    this.subscriptions.add(this.components.first.form.valueChanges.subscribe(value=>{
      this.partyService.cancelType = value.cancelActivity;
    }));
    }
  }

  close(eve) {
    this.ps.closeDialog$.next();
  }
  submit() {
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
