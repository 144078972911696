import { ChangeDetectorRef, Component,  forwardRef, Host, Input, Optional, SkipSelf, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ControlContainer, ControlValueAccessor, UntypedFormBuilder, NgControl, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { Localization } from '../../localization/localization';
import { AgDropdownConfig,  AgDropDownType, DropdownOptions } from '../../Models/ag-models';

import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatSelectChange } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import {isEqual} from 'lodash';
import { cloneDeep,uniqWith,differenceBy,intersectionBy } from 'lodash';
import { Product } from '../../enums/shared-enums';

@Component({
    selector: 'ag-dropdown',
    templateUrl: 'ag-control-dropdown-mat.html',
    styleUrls: ['ag-control-dropdown-mat.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: AgysMatDropDown },
    {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => AgysMatDropDown),
    }],
    encapsulation: ViewEncapsulation.None
})
export class AgysMatDropDown implements ControlValueAccessor, MatFormFieldControl<number | string> {


    dropDownConfig: AgDropdownConfig;
    valueChangeSubscription: Subscription;
    destroyed$: ReplaySubject<boolean> = new ReplaySubject(); 
    isAccounting: boolean

    selectListControl = new UntypedFormControl('');
    @Output() selectionChange = new EventEmitter<MatSelectChange>();
    stateChanges: Subject<void> = new Subject<void>();

    private onChange = (_: any) => { //onChange 
    };
    private onTouched = (_: any) => { //onChange
    };
    _placeholder: string;
    floatLabel: string;
    valuesSelected: any[] = [];
    filteredOptions: DropdownOptions[];
    Selectedfilteredlistcount: any;
    dropdownWithSearch: boolean

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    @Input() isAll:boolean;
    @Input() formControlName: string;
    @Input() automationId: string;
    _disabled: boolean;

    @Input('dropdownWithSearch') 
    set dropdownSearch(value){
        // this.dropdownWithSearch = value == false ? false : true
        this.dropdownWithSearch = value.dropdownWithSearch ? value.dropdownWithSearch : this.isAccounting;
    }
    inputSearch: string;
    @Input()
    get value(): string | number | null {

       
        return this.selectListControl.value;

    }


    set value(tel: string | number | null) {
        
        if (typeof tel !== 'object' && tel !== this.value) {
            
            this.selectListControl.setValue(tel, { emitEvent: false });

            this.stateChanges.next();
        }

        if(Array.isArray(tel) && !(isEqual(tel, this.value))){
            this.selectListControl.setValue(tel, { emitEvent: false });
            this.stateChanges.next();
        }
    }

    @Input() errorMessage;
    @Input() showEmptyOption;

    protected initializeFormGroup() {
      //initializeFormGroup
    }

    ngOnChanges() {
        this.updateControlComponent();
    }


    ngAfterViewInit() {
        this.subscribeToValueChanges();
    }

    ngOnDestroy(){
       if( this.destroyed$){
           this.destroyed$.next(true);
           this.destroyed$.complete();
       }
    }

    matSelectionChange(event: MatSelectChange) {
        this.getSelectedlistcount();
        this.selectionChange.emit(event);
    }

    dropDownSearchClear(value){
        if(value == "icon-close"){
            this.inputSearch = "";
            this._filter("");
        }
    }


    subscribeToValueChanges() {
        this.selectListControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(s => {
            this.value = s;
            this.onChange(this.value);
            this.onTouched(this.value);
        })
    }


    // @Input() source: DropdownOptions[];
    selectedOptions:DropdownOptions[];
    _source : DropdownOptions[];
    @Input()
    get source(): DropdownOptions[] {
        return this._source;
    }
    set source(value: DropdownOptions[]) {
        this._source = value;
        this.selectedOptions = this._source;
        this.filteredOptions = this._source;
    }

    @Input() multiple: boolean = false;


    private setAgTextComponent(type: AgDropDownType | undefined) {
        this.validateCtrl(type);

      

    }



    validateCtrl(type: AgDropDownType) {
        if (type && !(type == AgDropDownType.Default || type == AgDropDownType.Mulitple)) {
            this._commonUtilities.safeAlert(`Control Type ${type} Not Found in ${this.formControlName}`);
        }
    }

    private _type: AgDropDownType = AgDropDownType.Default;
    @Input()
    public get type(): AgDropDownType {
        return this._type;
    }
    public set type(value: AgDropDownType) {
        this._type = value;
    }


    constructor(@Optional() @Host() @SkipSelf()
    public controlContainer: ControlContainer, public fb: UntypedFormBuilder,
        public localization: Localization, public cd: ChangeDetectorRef,
        public _commonUtilities: CommonUtilities) {
            this.floatLabel = this.localization.setFloatLabel? this.localization.setFloatLabel: '';
            this.isAccounting = Number(this._commonUtilities.GetPropertyInfo('ProductId')) == Product.ACCOUNTING ? true : false;
            this.dropdownWithSearch = this.isAccounting;
        this.initializeFormGroup();
    }
    id: string;
    ngControl: NgControl;
    focused: boolean;
    empty: boolean;
    shouldLabelFloat: boolean;

    errorState: boolean;
    controlType?: string;
    autofilled?: boolean;
    userAriaDescribedBy?: string;


    @Input()
    public get disabled(): boolean {
        return this._disabled;
    }
    public set disabled(value: boolean) {
        this._disabled = value;
        this.stateChanges.next();
        this.updateControlComponent();
    }

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
        this.updateControlComponent();
    }
    _required = false;
    setDescribedByIds(ids: string[]): void {
        throw new Error('Method not implemented.');
    }
    onContainerClick(event: MouseEvent): void {
        throw new Error('Method not implemented.');
    }


    writeValue(input: any): void {
        this.value = input; 
    }

    registerOnChange(fn: any): void {
      
        this.selectListControl.valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe(fn);
    }
    registerOnTouched(fn: any): void {
       
        this.selectListControl.valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe(fn);
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }


    updateControlComponent() {
        this.setAgTextComponent(this._type);
        this.dropDownConfig = { ...this.dropDownConfig }
    }

    triggerAll(event: any) {
        let pusdataset;
        if(event.checked){
          if(this.selectListControl.value != null){
          let duplicate = [...this.valuesSelected,...this.filteredOptions]
          pusdataset = uniqWith(duplicate,isEqual);
          } else {
            pusdataset = this.filteredOptions
          }
          let selectedValues = [];
          pusdataset.forEach(x=>{
              selectedValues.push(x.id);
          });
          this.selectListControl.markAsDirty();
          this.selectListControl.updateValueAndValidity();
          this.selectListControl.setValue(selectedValues);
          this.valuesSelected = pusdataset;
        }else{
          let popdataset = differenceBy(this.valuesSelected, this.filteredOptions,'id');
          let selectedValues = [];
          popdataset.forEach(x=>{
              selectedValues.push(x.id);
          });
          this.selectListControl.setValue(selectedValues);
          this.valuesSelected = popdataset;
        }
        this.getSelectedlistcount();
        this.selectionChange.emit(this.selectListControl.value);
        // if (this.isAll) {
        //     if(event.checked){
        //         let selectedValues = [];
        //         this._source.forEach(x=>{
        //             selectedValues.push(x.id);
        //         });
        //         this.selectListControl.setValue(selectedValues)
        //     } else {
        //         this.selectListControl.setValue([]);
        //     }
        // }
      }
      getSelectedlistcount(){
        let availableseldata = intersectionBy(this.valuesSelected, this.filteredOptions, 'id');
        this.Selectedfilteredlistcount=availableseldata.length;
      }
      
  filterDropDownSelected(event: any, data: any, name: string,selected: boolean) {
    if (this.dropdownWithSearch && this.multiple) {
      this.valuesSelected = this.toggleClickbtn(data, this.valuesSelected);
      let selectedValues = [];
      this.valuesSelected.forEach(x=>{
          selectedValues.push(x.id);
      });
      this.selectListControl.setValue(selectedValues);
      this.selectListControl.markAsDirty();
      this.getSelectedlistcount();
      this.selectionChange.emit(data);
    }
  }
  private toggleClickbtn(data: any, selectedDataArray: any[], allselectedCheck?: boolean): any[] {
    let selectedArray = selectedDataArray;
    const currentlySelectedOption = data;
    const currentDataIdx = selectedArray.findIndex(x=>x.id  == currentlySelectedOption.id);
    if (currentDataIdx === -1) {
      selectedArray.push(currentlySelectedOption);
    } else {
      const defaultDataIdx = selectedArray.findIndex(x=>x.id  == data.id);
      if (defaultDataIdx !== -1) {
        selectedArray.splice(defaultDataIdx, 1);
      } 
    }    
    return selectedArray;
  }

      getname(arg){
        let name='';
        if(arg!=undefined){        
        name = this._source?.find(x => x.id === arg)?.value;        
        }        
        return name;
      }
      private _filter(value: string) {
        if(value)
        {
          const filterValue = value.toLowerCase();
          this.filteredOptions = this.selectedOptions.filter(x => x.viewValue.toLowerCase().includes(filterValue) );
          return this.filteredOptions;
        }else{
          return this.filteredOptions = this.selectedOptions
        }
      }
    
      filterOptions(event) {
        this._filter(event.target.value);
        this.getSelectedlistcount();
      }
      openedChange(opened: boolean) {
        if (!opened) {
          this.inputSearch = ''
          this._filter("");
          this.getSelectedlistcount();
        }
    }
}
