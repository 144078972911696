export enum ConfigureDateTimeRulesBy {
    Hour = 1,
    Week
}
export enum DaysOfWeek {
    Sunday = 1,
    Monday = 2,
    Tuesday = 4,
    Wednesday = 8,
    Thursday = 16,
    Friday = 32,
    Saturday = 64
}

export enum PeriodType {
    hour = 1,
    week
}
export namespace API {
    export class DiscountTypeConfiguration {
        id: number;
        discountTypeId: number;
        startTime: string;
        endTime: string;
        rules: ConfigureDateTimeRulesBy;
        daysOfWeek: DaysOfWeek;
        isDeleted: boolean;
    }
    export class DiscountTypeConfigurationView
    {
        startDate : Date;
        endDate: Date;
        rules: DiscountTypeConfiguration[];
    }
}

export namespace UI
{
    export class DiscountConfigurationRules
    {
        id: number;
        discountTypeId: number;
        startTime: string;
        endTime: string;
        rules: ConfigureDateTimeRulesBy;
        daysOfWeek: DaysOfWeek;
        isDeleted: boolean;
    }
}
