import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { VCartBusiness } from "src/app/common/components/menu/vcart/vcart.business";
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AlertType } from 'src/app/common/enums/shared-enums';
import { cloneDeep } from 'lodash';
import { CartItinerary, CartWindowMessageType } from '../vcart.modal';
import { CartUtilities } from '../cart.utilities';
import { CartTransferComponent } from '../../cart-transfer/cart-transfer.component';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
@Component({
  selector: 'app-view-all-itineraries-popup',
  templateUrl: './view-all-itineraries-popup.component.html',
  styleUrls: ['./view-all-itineraries-popup.component.scss']
})
export class ViewAllItinerariesPopupComponent implements OnInit {

  captions: any;
  buttonObj: { customSaveText: any; disabled: boolean; isEdit: boolean; };
  title: string;
  tableData: { userId: number; userName: string; guestfName: string; guestlName: string; cartConfNo: string; arrivalDate: string; noOfNights: number; comments: string; cartItineraryId: number; createdOn: string; obj: CartItinerary }[];
  unfilteredtableData: { userId: number; userName: string; guestfName: string; guestlName: string; cartConfNo: string; arrivalDate: string; noOfNights: number; comments: string; cartItineraryId: number; createdOn: string; obj: CartItinerary }[];
  selectedObj: any;
  menuOption;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  userId: number;
  userData: any;
  searchValue: string = '';
  constructor(public dialog: MatDialog, private localization: Localization,
    private dialogRef: MatDialogRef<ViewAllItinerariesPopupComponent>, @Inject(MAT_DIALOG_DATA) data: any,
    private _VCartBusiness: VCartBusiness, private utils: CommonUtilities, private cartUtils: CartUtilities) {
    this.captions = this.localization.captions;
    this.tableData = [];
    this.unfilteredtableData = [];
    this.title = data?.title?.toUpperCase()
  }

  ngOnInit(): void {
    document.getElementById('cover-spin').classList.remove("disable-spinners-dashboard");
    document.getElementById('custom-cover-spin').classList.remove("disable-spinners-dashboard");
    this.userId = Number(this.utils.GetUserInfo('userId'));
    this.buttonObj = {
      customSaveText: this.captions.save,
      disabled: true,
      isEdit: false
    };

    this.menuOption = [{
      id: 1,
      value: this.captions.lbl_Transfer,
      disabled: false
    }]
    this.utils.ToggleLoader(true);
    this._VCartBusiness.getUsers().then(users => {
      this.userData = users;
      this.loadAllActiveItineraries();
    });
  }

  async loadAllActiveItineraries() {
    try {
      var itineraries: CartItinerary[] = await this._VCartBusiness.GetAllItineraries();
      if (itineraries && itineraries.length > 0) {
        this.unfilteredtableData = itineraries.filter(o => o.userId != this.userId).map(o => {
          return {
            noOfNights: o.numberOfNights,
            arrivalDate: this.localization.LocalizeDate(o.travelStartDate),
            cartConfNo: o.confirmationId,
            comments: o.cartComments,
            guestfName: o.primaryGuestFirstName,
            guestlName: o.primaryGuestLastName,
            userId: o.userId,
            userName: o.userName ? o.userName : '',
            cartItineraryId: o.id,
            createdOn: this.localizeDateTime(o.createdDTM),
            obj: o
          }
        });
        this.filterGrid(this.searchValue);
      }
    } catch (e) {

    } finally {
      this.utils.ToggleLoader(false);
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  // onSelect(obj) {
  //   this.buttonObj.disabled = false;
  //   this.buttonObj = { ...this.buttonObj }
  //   this.tableData.forEach(x => {
  //     x.selected = false
  //   });
  //   obj.selected = true;
  //   this.selectedObj = obj
  // }

  filterGrid(searchText) {
    if (searchText && searchText.length > 0) {
      var filteredData = this.unfilteredtableData.filter(x => x.userName.toLowerCase().includes(searchText)
        || x.guestfName.toLowerCase().includes(searchText)
        || x.guestlName.toLowerCase().includes(searchText)
        || x.cartConfNo.toLowerCase().includes(searchText))
      this.tableData = cloneDeep(filteredData);
    } else {
      this.tableData = cloneDeep(this.unfilteredtableData);
    }
  }
  searchValueChange(e) {
    this.searchValue = e ? e.trim().toLowerCase() : '';
    this.filterGrid(this.searchValue);
  }
  localizeDateTime(date: string) {
    if (date) {
      var value = this.localization.getDate(date);
      return value ? this.localization.LocalizeDate(value) + " - " + this.localization.LocalizeTime(value) : "";
    }
  }
  async moreOptionsemiter(options, cartItinerary) {
    if (options.id == 1) {
      // Transfer
      if (this.userData) {
        var filteredUsers = this.userData.filter(o => o.userId != cartItinerary.userId);
        var usersList = filteredUsers.map(x => ({ userId: x.userId, userName: x.userName, firstname: x.firstName, lastname: x.lastName }));
        this.dialog.open(CartTransferComponent, {
          height: '600px',
          width: '900px',
          data: {
            cartItineraryId: cartItinerary.id,
            tableData: usersList,
            title: this.captions.lbl_Transfer + ' - ' + cartItinerary.confirmationId
          }
        }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async res => {
          this.cartUtils.hideExpandVcartoverlay();
          if (res.errorCode == 0) {
            let successMsg = this.localization.replacePlaceholders(this.captions.msg_cartItineraryTransferred, ['confirmationId'], [cartItinerary.confirmationId]);;
            this.utils.showAlert(successMsg, AlertType.Success);
            await this.loadAllActiveItineraries();
          }
        });
      }
    }
  }
  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
}
