import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { LoaderService } from '@app/core/services/loader.service';
import { ReservationDTO } from '@app/shared/models/InputContact';
import { LayoutFunctions } from '@app/shared/utilities/layout-functions';
import { AccordionComponent } from '@components/accordingsidebar/accordingsidebar.component';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { MessageStatusComponent } from '@components/message-status/message-status.component';
import { MultiplePartiesComponent } from '@components/multiple-parties/multiple-parties.component';
import { ReservationChangeConfirmComponent } from '@components/reservation-change-confirm/reservation-change-confirm.component';
import { SeatStatusComponent } from '@components/seatstatus/seatstatus.component';
import { buttonTypes, ComponentTypes, GetReservationsOperationOptions, Menu, OperationResultState, PartyNoteType, PartyState, PartyType, ReservationEmailNotificationType, ReservationStatus, ReservationType } from '@constants/commonenums';
import * as globals from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { DateStateDTO, SlotDTO } from '@models/InputReservation';
import { PartyEmailSendBehavior, SettingsDTO, ShiftDTO, StandaloneTableDTO, SupportedReservationEmailConfirmationTypes } from '@models/RestaurantDTO';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails, TabConfig } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { DashboardFunctions } from '@utilities/dashboard-functions';
import { Utilities } from '@utilities/utilities';
import { cloneDeep, sortBy, uniqBy } from 'lodash';
import moment from 'moment';
import { NgScrollbar } from 'ngx-scrollbar';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';
import { TimelineService } from './timeline.service';

export interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-time-line',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TimeLineComponent extends Utilities implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  set ready(isReady: boolean) {
    if (isReady) { }
  }
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @Output() partySelectedEvent = new EventEmitter<any>();
  @ViewChild('dataCell', { static: true }) tableCell: ElementRef;
  @ViewChild('searchConfigForm', { static: true }) searchConfigForm: DynamicFormComponent;
  @ViewChild('timelineNotesPopover') private timelineNotesPopover: NgbPopover;
  @ViewChild('timelinePartyNotes') private timelinePartyNotes: NgbPopover;
  ReservationType = ReservationType;
  currentTime: any = moment().format('hh:mm a');
  displayslidder = true;
  disableZoomin = false;
  reservationsToBeDisplayed: any = [];
  disableZoomout = false;
  TLcurrentPercentage = 100;
  tabcomponentDetails: TabConfig[];
  shiftConfig: FieldConfig[];
  showTimeBar = true;
  incrementhrsBy = 1;
  selectedParty: any;
  currentdaydet = moment().format('ddd') + ', ' + moment().format('MMM D');
  TLcurrentDay: any = moment().format();
  thisTimeArr: any = [];
  definedminutes: any = [];
  nonWorkingHrsArr: any = [];
  testArrayValue: any = [];
  timeArray: any = [];
  operatingHours: any = [];
  restaurantStartTime = '2019-09-05T00:00:00';
  restaurantEndTime = '2019-09-05T23:30:00';
  clspn = 2;
  employee: any = [];
  timeArrayGenerated: any = [];
  displayLegends: any;
  zoomoutEnabled = false;
  tables: any;
  widthSet: any;
  leftSet: any;
  public colsp: any = 2;
  areaValue: any;
  mappedReservation: any = [];
  currentReservations: any = [];
  width: any;
  cell: any;
  widthSetDrag: any;
  contactInfo: any;
  rowIndex: any;
  colIndex: any;
  leftSide: any;
  componentConfigs: any;
  tableId: any;
  reservations: any[] = [];
  MappedReservation: { FloorPlanViewId: number, reservations: any[] };
  componentDetails: ComponentDetails;
  draggedElementWidth: any;
  defaultWidth = 90;
  cellDefaultWidth: number;
  reservationStatus: any;
  reservedId: number;
  buttonControls: ButtonValue[];
  showName = true;
  dashboardData: any = [];
  currentTimeView: any;
  headerSubscription: ISubscription;
  confirmSubscription: ISubscription;
  isPartySelected: boolean = false;
  serverId: number;
  unassignedCount: ButtonValue;
  unassignedCounter: number;
  unassignedReservations: any = [];
  _settings: SettingsDTO = {} as SettingsDTO;
  coversCount: number = 0;
  currentDayShifts: any;
  customFloorPlan: boolean = false;
  timelineShiftId: number;
  selectedShiftIds: number[];
  selectedShift: number;
  _standaloneUnassigned: StandaloneTableDTO = {} as StandaloneTableDTO;
  subscription: Subscription = new Subscription();
  leftShift = "";
  dataToBeAdded = [];
  partyNotes = [];
  searchString: string = '';
  searchConfig: FieldConfig[];
  seatedParties = [];
  minRowHeight = 30;
  subscriptions: Subscription = new Subscription();
  isOldShift = false;
  nextShiftTimeDiffInMins$ = new BehaviorSubject<any>(null);
  timerSubscription: ISubscription;
  partyNoteType = PartyNoteType;
  reservationCount:number =0;
  _MESSAGES = MESSAGES;
  constructor(private partyService: PartyService, public appService: AppService,
    private elementRef: ElementRef, private renderer: Renderer2,
    public translateService: TranslateService, private dashboardFunctions: DashboardFunctions,
    public dialog: MatDialog, private ts: TimelineService, private ps: PopupService, private el: ElementRef, public cs: CacheService, private lf: LayoutFunctions,private ls:LoaderService) {
      super(dialog);            
  }

  loadReservations(triggeredFromListener = false) {
    let reservationData: DateStateDTO[];
    // this.subscription.add(this.partyService.getParties(this.appService.headerDate$.value, this.appService.headerDate$.value, GetReservationsOperationOptions.Reservations).subscribe(data => {
    //   if (data) {
        let values =  this.partyService.Parties$.value;
        this.reservations = values.filter((x: ReservationDTO) => ((x.State != PartyState.NoShowed && x.State != PartyState.Left && x.State != PartyState.Cancelled)));
        this.reservations = this.reservations.filter(x => ((x.Type == PartyType.WalkIn && x.State == PartyState.Seated) || (x.Type == PartyType.Reservation)));
  
        // this.reservations = [];
        // this.coversCount = 0;
        // reservationData = data.Payload.filter((reservations: DateStateDTO) => {
        //   const restaurantDate = moment(this.appService.headerDate$.value).format('MM/DD/YYYY');
        //   const reservationDate = moment(reservations.Date).format('MM/DD/YYYY');
        //   return (reservationDate == restaurantDate);
        // });
        // if (this.timelineShiftId) {
        //   let shift = reservationData[0].Shifts.filter(shift => shift.Shift.Id == this.timelineShiftId);
        //   if (shift.length) {
        //     shift[0].Reservations.forEach(reservation => {
        //       this.coversCount = this.coversCount + reservation.Size;
        //       this.reservations.push(reservation);
        //     });
        //   }
        // } else {
        //   reservationData[0].Shifts.forEach(shift => {
        //     if (shift.Reservations.length > 0) {
        //       shift.Reservations.forEach(reservation => {
        //         this.coversCount = this.coversCount + reservation.Size;
        //         this.reservations.push(reservation);
        //       });
        //     }
        //   });
        // }
        let CurrentFloorplan = Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, this.appService.headerDate$.value, this.timelineShiftId);
        this.tables = cloneDeep(CurrentFloorplan)[0].StandaloneTables;
        let unassignedTable = this.tables.filter(x => x.Id == 0);
        if (unassignedTable.length == 0) {
          this.tables.push(this._standaloneUnassigned);
        }
        // this.reservations = this.reservations.filter(x => x.State != PartyState.NoShowed);
        // const newRestaurantDate = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)).format('MM/DD/YYYY');
        // const newHeaderDate = moment(this.appService.headerDate$.value).format('MM/DD/YYYY');
        // if(newRestaurantDate == newHeaderDate)
        // {
        //   this.reservations.push(...this.seatedParties);
        // }
        // this.reservations = uniqBy(this.reservations, 'Id');
        // this.reservations = this.reservations.filter(x => x.State != PartyState.Left);
        this.appService.reservationMenuCount = this.reservations.filter(x => x.State == PartyState.Pending && x.Type == PartyType.Reservation).length;

        // this.appService.reservationMenuCount = this.reservations && this.reservations.filter(x => x.State == PartyState.Pending && x.Type == PartyType.Reservation).length > 0 ?
        //    this.reservations.filter(x => x.State == PartyState.Pending && x.Type == PartyType.Reservation).length : 0;
        // this.partyService.partiesList = this.reservations;
        // this.partyService.Parties$.next(this.reservations);
        if (triggeredFromListener) {
          this.cs.signalRMessageCount = 0;
        }
  //    }
   // }));
    this.setData();
  }

  ngOnInit() {
    this._settings = this.cs.settings.value;
    this.buttonControls = [
      { label: this._MESSAGES.labels.currentTimeText, type: buttonTypes.actionSecondarySmall, customclass: 'timeline__timeline-btn',name: this._MESSAGES.labels.currentTimeText },
      { label: this._MESSAGES.labels.zoomIn, type: buttonTypes.actionSecondarySmall, customclass: 'timeline__zoom-in timeline__timeline-btn',name: this._MESSAGES.labels.zoomIn },
      { label: this._MESSAGES.labels.zoomOut, type: buttonTypes.actionSecondarySmall, customclass: 'timeline__zoom-out timeline__timeline-btn',name: this._MESSAGES.labels.zoomOut },
      { label: this._MESSAGES.labels.reset, type: buttonTypes.actionSecondarySmall, customclass: 'timeline__fit-view timeline__timeline-btn', name: this._MESSAGES.labels.reset }];
    this.unassignedCount = {
      type: buttonTypes.actionSecondarySmall,
      label: this.unassignedCounter,
      disbaledproperity: false,
      icon: 'icon-add-contact'
    };
    this.subscription.add(this.appService.headerDate$.subscribe(() => {
      this.currentDayShifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, this.appService.headerDate$.value);
      let shifts = this.currentDayShifts;
      this.setCustomLayout();
      this.timelineShiftId = this.currentShiftID(shifts);
      this.getShiftByDate(this.currentDayShifts);
      this.loadReservations();
      this.partyService.navigationShiftIdFromReservation = 0;
    }));

    this.appService.datePickerDisabled.next(false);
    this.appService.seatingAreaFilterDisabled.next(true);
    this.searchConfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.translateService.instant('searchReservationOrWaitlist'),
        showErrorText: true,
        appearance: true,
        class:'timeline__search-config',
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearchField.bind(this)
      }
    ];
    this.appService.applySetting(Menu.TimeLine);
    this._standaloneUnassigned = ({
      Id: 0, Name: this.translateService.instant("unassigned"), IsCommunalTable: true, MaxPartySize: 10, MinPartySize: 0, IsAvailableForReservations: true, BufferTimeInMinutes: 0,
      ServerId: 0, SeatingAreaId: 0, SeatingTypeId: 0, IsNew: true, FloorPlanViewId: 0, Left: 0, Top: 0, IsAvailableForBooking: null,
      SeatingArea: null, SeatingType: null, Price: null, label: null, radius: null, originX: null, originY: null, fill: null, stroke: null,
      strokeWidth: null, scaleX: null, scaleY: null, Angle: null, opacity: null, Width: 0, Height: 0, ShapeType: 0, RotationAngle: 0, rx: null, ry: null
    });
  }

  getSortedTable() {
    const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
    const tableName = this.tables.sort();
    return tableName.sort((a, b) => collator.compare(a.Name, b.Name));
  }

  setData() {
    this.unassignedReservations = [];
    if (this.reservations.length > 0) {
      this.unassignedReservations = this.reservations.filter(x => x.TableIds.length == 0);
    }
    this.unassignedCounter = this.unassignedReservations.length;
    this.unassignedCount.label = this.unassignedCounter.toString();
    this.unassignedCount = { ...this.unassignedCount };
    this.mappedReservation = [];
    this.appService.showDashboard = false;
    this.dashboardData = [{ partyInfo: null, countInfo: [], status: [], messages: [], actions: [] }];
    this.cellDefaultWidth = cloneDeep(this.defaultWidth);
    this.generateOperatingIntervals();
    this.areaValue = '4';
    this.displayLegends = [{ State: 1, name: 'Reserved' }, { State: 2, name: 'Arrived' }, { State: 3, name: 'Seated' },
    { State: 4, name: 'Cancelled' }, { State: 5, name: 'Blocked' }, { State: 6, name: 'non-operting hours' }];
    this.componentConfigs = [
      { componentName: SeatStatusComponent, input: '' }, { componentName: MultiplePartiesComponent, input: '' },
      { componentName: AccordionComponent, input: [{ componentName: MessageStatusComponent }] }];
    this.mappedReservation.push({ floorPlanViewId: 0, reservations: [] });
    this.areaSelected();
    if (this.timeArrayGenerated) {
      setTimeout(() => this.currentTimeDisplay(), 1000);
    }
    this.setCellHeight();
  }

  ngAfterViewInit() {
    this.subscription.add(this.scrollbarRef.scrolled.subscribe(e => {
      if (this.timelineNotesPopover && this.timelineNotesPopover.isOpen()) {
        this.timelineNotesPopover.close();
      }
      if (this.timelinePartyNotes && this.timelinePartyNotes.isOpen()) {
        this.timelinePartyNotes.close();
      }
    }));

    this.subscription.add(this.searchConfigForm.form.valueChanges.subscribe(val => {
      this.searchString = val.searchText;
    }));

    this.subscription.add(this.appService.minuteTimer$.subscribe(() => {
      this.currentTimeDisplay();
    }));
    this.subscriptions.add(this.appService.headerSelectedDate$.subscribe((date) => {
      this.appService.headerSelectedDate = date;
      this.partyService.navigationShiftIdFromReservation = 0;
    }));
    this.subscriptions.add(this.nextShiftTimeDiffInMins$.subscribe((time) => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      if (time > 0)
      this.timerSubscription = interval(1000 * time).subscribe(() => {
        this.currentDayShifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, this.appService.headerDate$.value);
        let shifts = this.currentDayShifts;
        this.timelineShiftId = this.currentShiftID(shifts);
        this.getShiftByDate(this.currentDayShifts);
        this.loadReservations();
      })
    }));

    this.subscription.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.currentDayShifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, this.appService.headerDate$.value);
      let shifts = this.currentDayShifts;
      this.setCustomLayout();
      this.timelineShiftId = this.currentShiftID(shifts);
      this.getShiftByDate(this.currentDayShifts);
    }));

    this.subscription.add(this.cs.state.subscribe(ste => {
      this.partyService.RecentlyLeftPartiesList = cloneDeep(ste.RecentlyLeftParties);
      this.seatedParties = ste.SeatingParties;
    }));
    this.subscription.add(this.cs.layout.subscribe(layt => {
      let CurrentFloorplan = Utilities.GetLayoutForDate(layt.FloorPlans, this.appService.headerDate$.value, this.timelineShiftId);
      this.tables = cloneDeep(CurrentFloorplan)[0].StandaloneTables;
      let unassignedTable = this.tables.filter(x => x.Id == 0);
      if (unassignedTable.length == 0) {
        this.tables.push(this._standaloneUnassigned);
      }
      if (this.reservations.length) {
        this.setData();
      }
    }));
    this.subscription.add(this.partyService.Parties$.subscribe((values) => {
      if (values) {
        //this.reservations = [];
        this.reservations = values.filter((x: ReservationDTO) => ((x.State != PartyState.NoShowed && x.State != PartyState.Left && x.State != PartyState.Cancelled)));
        this.reservations = this.reservations.filter(x => ((x.Type == PartyType.WalkIn && x.State == PartyState.Seated) || (x.Type == PartyType.Reservation)));
        this.coversCount = 0;
        this.reservations.forEach(reservation => {
          this.coversCount = this.coversCount + reservation.Size;
        });
        this.setData();
      }
    }));
    this.subscriptions.add(this.appService.headerSelectedDate$.subscribe((date) => {
      this.appService.headerSelectedDate = date;
    }));
  }

  clearSearchField(event) {
    this.searchString = '';
    this.searchConfigForm.form.get('searchText').setValue('');
  }

  changeEvent(name) {
    switch (name) {
      case this._MESSAGES.labels.zoomIn: {
        this.zoomOutTimeline();
        this.zoomOutTimeline();
        this.zoomInTimeline();
        this.currentTimeDisplay();
        break;
      }
      case this._MESSAGES.labels.zoomOut: {
        this.zoomInTimeline();
        this.currentTimeDisplay();
        break;
      }
      case this._MESSAGES.labels.currentTimeText: {
        this.currentTimeDisplay();
        break;
      }
      case this._MESSAGES.labels.reset: {
        this.resetPercentage();
        this.zoomInTimeline();
        this.currentTimeDisplay();
        break;
      }
    }
  }

  moveToToday() {
    this.scrollbarRef.scrollTo({ left: 0, duration: 500 });
  }

  generateOperatingIntervals() {
    this.showTimeBar = false;
    this.timeArrayGenerated = [];
    let shiftArray = cloneDeep(this.currentDayShifts);
    if (this.timelineShiftId) {
      shiftArray = shiftArray.filter(x => x.Id == this.timelineShiftId);
    }
    if (this.currentDayShifts.length == 0) { return; }
    else {
      shiftArray = shiftArray.sort(function (a, b) {
        return a.EffectiveRange.Start.toString().split('Z')[0].localeCompare(b.EffectiveRange.Start.toString().split('Z')[0]);
    });
      shiftArray.forEach(shift => {
        this.getRestaurantWorkingHours(shift);
        let isSlotAdded = false;
        const Slotval = 30;
        const Startval = shift.EffectiveRange.Start.toString().split('Z')[0];
        const Endval = moment(shift.EffectiveRange.End.toString().split('Z')[0]);
        let Startloop = moment(Startval);
        
        if(Startloop.minutes() === 15 || Startloop.minutes() === 45){
          if(this.timeArrayGenerated[this.timeArrayGenerated.length - 1] 
            && new Date(this.timeArrayGenerated[this.timeArrayGenerated.length - 1]).getHours() === Startloop.hours()
            && new Date(this.timeArrayGenerated[this.timeArrayGenerated.length - 1]).getMinutes() === (Startloop.minutes() - 15)){
              Startloop.set("minutes", Startloop.minutes() + 15);
            }else{
              Startloop.set("minutes", Startloop.minutes() - 15);
            }
        }

        while (Startloop <= Endval) {
          const tempTimeSlot = Startloop;
          const slot: SlotDTO = <SlotDTO>{};
          const slotTime = Utilities.Date(Startloop);
          slot.DateTime = slotTime.format('YYYY-MM-DDTHH:mm:ss');
          this.timeArrayGenerated.push(slot.DateTime);
          isSlotAdded = true;
          const newMinites = tempTimeSlot.get('minute') + Slotval;
          const newDate = tempTimeSlot.set('minute', newMinites);
          Startloop = moment(newDate);
        }
      })
    }
  }

  getRestaurantWorkingHours(startTimeArraySort) {
    let currentTimes = (Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    if (moment(startTimeArraySort.EffectiveRange.Start) <= moment(currentTimes) && moment(currentTimes) <= moment(startTimeArraySort.EffectiveRange.End))
      this.showTimeBar = true;
  }

  currentTimeDisplay() {
    let currentTimes = (Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    this.currentTime = moment(currentTimes).format('YYYY-MM-DDTHH:mm:ss');
    let width = 0, singleWidth = 0;
    if (this.showTimeBar) {
      const tableCell = this.elementRef.nativeElement.querySelectorAll('.timeline__display-cell');
      const displayTime = this.timeArrayGenerated;
      for (let index = 0; index < displayTime.length; index++) {
        if (moment(this.timeArrayGenerated[index + 1]) <= moment(this.currentTime)) {
          const edTime = moment(displayTime[index + 1]);
          const stTime = moment(displayTime[index]);
          const duration = edTime.diff(moment(stTime), 'minutes');
          let singleWidth1 = tableCell[0].offsetWidth / duration;
          width += singleWidth1 * duration;
        }
        else {
          const edTime = moment(displayTime[index + 1]);
          const stTime = moment(displayTime[index]);
          const duration = edTime.diff(moment(stTime), 'minutes');
          let smallWidth = moment(this.currentTime).diff(moment(displayTime[index]), 'minutes'); //(this.currentTime - displayTime[index]);
          let singleWidth = tableCell[0].offsetWidth / duration;
          width += singleWidth * smallWidth;
          break;
        }
      };
      let scrollWidth = width - 200;
      this.scrollbarRef.scrollTo({ left: (scrollWidth), duration: 250 });
      let timeBar = this.el.nativeElement.querySelector('.timeline__current-bar');
      this.currentTimeView = moment(currentTimes).format('hh:mm A');
      if (this.renderer && timeBar) {
        this.renderer.setStyle(timeBar, 'left', width + 'px');
        this.renderer.setStyle(timeBar, 'transition', '2s');
      }
      this.leftShift = ((width) - 25) + 'px';
    }
  }
  areaSelected() {
    const mappedIndex = 0
    if (this.mappedReservation[mappedIndex].reservations.length === 0) {
      this.dataToBeAdded = [];
      for (let k = 0; k < this.timeArrayGenerated.length; k++) {
        const reservationDataSet = [];
        reservationDataSet.push({ splitTime: this.timeArrayGenerated[k], data: [] });
        reservationDataSet.push({ splitTime: moment(new Date(this.timeArrayGenerated[k]).setMinutes(new Date(this.timeArrayGenerated[k]).getMinutes() + 15)).format('YYYY-MM-DDTHH:mm:ss'), data: [] }); //moment(this.timeArrayGenerated[k]).add(15, 'minutes')
        this.dataToBeAdded.push({ time: this.timeArrayGenerated[k], reservationData: reservationDataSet });
      }
      this.tables = this.getSortedTable();
      const mappedReservations = this.tables;
      mappedReservations.forEach(element => {
        element.data = [];
        element.data.push(cloneDeep(this.dataToBeAdded));
      });
      this.mappedReservation[mappedIndex].reservations.push(mappedReservations);
      this.currentReservations = this.mappedReservation[mappedIndex].reservations[0];
      this.pushContent();
    } else {
      this.currentReservations = this.mappedReservation[mappedIndex].reservations[0];
    }
  }

  pushContent() {
    const reservedDetails = this.reservations;
    reservedDetails.forEach((reservation, i) => {
      let count = 0;
      if (this.unassignedReservations.length > 0 && reservation.TableIds.length == 0) {
        this.currentReservations.forEach(current => {
          if (current.Id === 0) {
            if (current.data.length == 0) {
              current.data.push(cloneDeep(this.dataToBeAdded));
            }
            let count = 0;
            outerLoop:
            for (const currentData of current.data) {
              count++;
              re:
              for (const element of currentData) {
                for (const reservationData of element.reservationData) {
                  const seatingTime = moment(reservation.SeatingTime).format('YYYY-MM-DDTHH:mm:ss');
                  const extentedTimer = moment(reservationData.splitTime).set('minute', moment(reservationData.splitTime).get('minute') + 14).set('seconds', 59).format('YYYY-MM-DDTHH:mm:ss');
                  const splitTimeFormat = reservationData.splitTime;
                  if (((splitTimeFormat == seatingTime) ||
                    (seatingTime > splitTimeFormat && seatingTime < extentedTimer))) {
                    const reservationClonedData = cloneDeep(reservation);
                    if ((splitTimeFormat == seatingTime) ||
                      (seatingTime > splitTimeFormat && seatingTime < extentedTimer)) {
                      reservationClonedData.reservationStatus = true;
                    } else {
                      reservationClonedData.reservationStatus = false;
                    }
                      reservationClonedData.PartyNotes = this.setPartyNotes(reservationClonedData);
                      reservationClonedData.ContactNotes = reservationClonedData.Contact.Notes;
                      reservationClonedData.cssTop = 10;
                      reservationData.data.push(reservationClonedData);
                      count++;
                      const timeDifference = (moment(reservation.DepartureTime).diff(moment(reservation.SeatingTime), 'minutes'));
                      const totalvalue = (timeDifference / 30) * 2;
                      if (count == totalvalue) {
                        break outerLoop;
                      }
                    sortBy(reservationData, ['SeatingTime']);
                  }
                }
              }
            }
          };
        });
      }
      reservation.TableIds.forEach(y => {
        this.currentReservations.forEach(current => {
          if (current.Id === y) {
            current.data.forEach((currentData, index) => {
              currentData.forEach(element => {
                element.reservationData.forEach(reservationData => {
                  const seatingTime = moment(reservation.SeatingTime).format('YYYY-MM-DDTHH:mm:ss');
                  const extentedTimer = moment(reservationData.splitTime).set('minute', moment(reservationData.splitTime).get('minute') + 14).set('seconds', 59).format('YYYY-MM-DDTHH:mm:ss');
                  const splitTimeFormat = reservationData.splitTime;
                  if (((splitTimeFormat == seatingTime) ||
                    (seatingTime > splitTimeFormat && seatingTime < extentedTimer))) {
                    const reservationClonedData = cloneDeep(reservation);
                    if ((splitTimeFormat == seatingTime) ||
                      (seatingTime > splitTimeFormat && seatingTime < extentedTimer)) {
                      reservationClonedData.reservationStatus = true;
                    } else {
                      reservationClonedData.reservationStatus = false;
                    }
                    reservationClonedData.PartyNotes = this.setPartyNotes(reservationClonedData);
                    reservationClonedData.ContactNotes = reservationClonedData.Contact.Notes;
                    reservationClonedData.cssTop = 10;
                    reservationData.data.push(reservationClonedData);

                    sortBy(reservationData, ['SeatingTime']);
                  }
                });
              });
            });
          }
        });
      });
    });
    this.coversCount = 0;
    this.reservationCount =0;
    this.currentReservations.forEach(currRes => {
        let count = 0;
        currRes.data[0].forEach(res => {
            res.reservationData.forEach(time => {
                if (time.data.length > 0) {
                    count = count + time.data.length;
                    this.reservationCount = this.reservationCount + time.data.length;
                    time.data.forEach(reservedData => {
                      this.coversCount = this.coversCount + reservedData.Size;
                    });

                }
            })
        });
        if (count > 1) {
          currRes.reservationLength = count;
        }
    });
   this.currentReservations.forEach(reservation => {
     if (reservation.reservationLength > 1) {
      let availableReservations = [];
       reservation.data[0].forEach(reservationTime => {
         reservationTime.reservationData.forEach((splitTimeData) => {
          if (splitTimeData.data.length > 0) {
            availableReservations.push(...splitTimeData.data)
          }
         })
       });
       if (availableReservations.length > 0) {
         this.calcReservationTop(availableReservations, reservation)
       }
     }
   });
   this.currentReservations=[...this.currentReservations];
   this.ls.showMessage.next(false)
  }

  calcReservationTop(availableReservations: any[], reservation: any) {
    let countArray = [];
    reservation.rowHeight = 0;
    for (let i = 0; i < availableReservations.length; i++) {
      const elem = availableReservations[i];
      let elemBottom;
      let count = 0;
      for (let j = 0; j < i ; j++) {
        const prevElem = availableReservations[j];
        const prevElemBottom = prevElem.cssTop + this.minRowHeight;
        elemBottom = elem.cssTop + this.minRowHeight;
        let prevElemStartTime = moment(prevElem.SeatingTime || prevElem.ReservedFor);
        let prevElemEndTime = moment(prevElem.DepartureTime);
        let elemStartTime = moment(elem.SeatingTime || elem.ReservedFor);
        let elemEndTime = moment(elem.DepartureTime);
        if ((
          (prevElemStartTime <= elemStartTime && elemStartTime <= prevElemEndTime) ||
          (prevElemStartTime <= elemEndTime && elemEndTime <= prevElemEndTime) ||
          (prevElemStartTime >= elemStartTime && elemEndTime >= prevElemEndTime)
        ) && (
          (prevElem.cssTop <= elem.cssTop && elem.cssTop <= prevElemBottom) ||
          (prevElem.cssTop <= elemBottom && elemBottom <= prevElemBottom)
        )) {
          count++;
          elem.cssTop = prevElemBottom;
        }
      }
      countArray.push(count);
      elemBottom = elem.cssTop + this.minRowHeight + 1;
    }
    let maxCount = Math.max(...countArray);
    reservation.rowHeight = 35 * (maxCount > 5 ? maxCount : maxCount + 1) + 20;
  }

  setPartyNotes(_party: any) {
    this.partyNotes = [];
    if (_party.SpecialMealId) {
      const specialMealDetails = this._settings.SpecialMeals.filter((meal) => meal.Id === _party.SpecialMealId);
      if (specialMealDetails.length > 0 && specialMealDetails[0].Name) {
        this.partyNotes.push({ id: this.partyNotes.length, name: specialMealDetails[0].Name, isSpecialMeal: true, type: PartyNoteType.SpecialMeal });
      }
    }
    if (_party && _party.Notes && _party.Notes.length > 0) {
      _party.Notes.forEach((notes, index) => {
        let PredefinedPartyNotes = this.dashboardFunctions.getPredefinedPartyNotes();
        const predefinedPartyNote = PredefinedPartyNotes.filter((note) => note.Id === notes.RelatedId);
        if (predefinedPartyNote.length > 0 && notes.Type == PartyNoteType.PredefinedNote ) {
          this.partyNotes.push({ id: index, name: predefinedPartyNote[0].Text, isSpecialMeal: false });
        } else if (predefinedPartyNote.length > 0 && (notes.Type == PartyNoteType.FreeFormNote)) {
          this.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
        } else if (predefinedPartyNote.length > 0 && notes.Type == PartyNoteType.SpecialRequest) {
          this.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type });
        } else if (notes.Type == PartyNoteType.FreeFormNote) {
          this.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
        } else if (notes.Type == PartyNoteType.PromoCode) {
          this.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type });
        }
      });
    }
    return this.partyNotes;
  }

  setCellHeight() {
    const child = this.elementRef.nativeElement.querySelectorAll('.timeline__communal-table');
    if (child.length > 0) {
      for (let index = 0; index < child.length; index++) {
        const parent1 = child[index].parentNode;
        const childNodeIndex = Array.from(parent1.parentNode.children).indexOf(child[index].parentNode)
        const rows = this.elementRef.nativeElement.querySelectorAll('.TH_' + childNodeIndex);
        rows.forEach(element => {
          this.renderer.setStyle(element, 'height', child[index].offsetHeight + 'px');
        });
      }
    }
  }

  actions(selectedAction: any) {
    const actions = globals.actionsIcons;
    switch (actions[selectedAction.index]) {
      case actions[0]:
        this.dashboardFunctions.createOrEditReservation(true, selectedAction.partyInfo);
        break;
      case actions[1]:
        break;
      case actions[2]:
        break;
      case actions[3]:
        break;
      case actions[4]:
        break;
    }
  }

  partyUnselectedEventHander() {
    this.appService.showDashboard = false;
  }

  create(btnType: ButtonValue) {
    this.isPartySelected = true;
    switch (btnType.actionName) {
      case ReservationStatus.Arrived:
        break;
      case ReservationStatus.Cancel:
        this.cancelParty(this.partyService.selectedParty$.value);
        break;
      case ReservationStatus.ReleaseTable:
        this.subscription.add(this.partyService.unseatParty(this.partyService.selectedParty$.value.Id).subscribe((response) => {
          this.partyService.MoveCheck();
        }));
        break;
      case ReservationStatus.ClearTable:
        this.subscription.add(this.partyService.clearParty(this.partyService.selectedParty$.value.Id).subscribe(() => { }));
        break;
      case ReservationStatus.Reopen:
        break;
      case ReservationStatus.UndoNoShow:
        this.partyService.tryUndoNoShowReservation = true;
        this.dashboardFunctions.createOrEditReservation(true, this.partyService.selectedParty$.value);
        break;
      case ReservationStatus.SeatParty:
        this.dashboardFunctions.seatPartyWithConfimation(this.partyService.selectedParty$.value);
        break;
      case ReservationStatus.DeleteParty:
        this.cancelQuickSeatedParty();
        break;
    }
  }

  cancelQuickSeatedParty() {
    const popUpMessage = [{
      confirmationMessage: this.translateService.instant('quickSeatedPartyDeleteMsg'),
      dialogTitle: this.translateService.instant('deleteParty')
    }];
    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, ' ', '', popUpMessage, '');
    const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.CancelReservation,
      '600px', '353px', false, this.translateService.instant('deleteParty'), 'ok', 'cancel', false);
    this.confirmSubscription =  this.ps.confirmedAction$.subscribe((val) => {
      if (val == ComponentTypes.CancelReservation) {
        this.subscriptions.add(this.partyService.cancelWaitlist(this.partyService.selectedParty$.value.Id).subscribe(
          (data) => {

          }));
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.confirmSubscription.unsubscribe();
    })
  }

  cancelParty(party) {
    if (this.appService.OTASourceId.includes(party.PartySourceId)) {
      this.dashboardFunctions.showOTAAlert(party);
      return;
    }
    let cancelText = 'No';
    let title = this.translateService.instant('cancelConfirmationMsg');
    let msg = this.translateService.instant('cancelReservation');
    let noShowSet = false;
    let cancelReservation = false;
    let updateText = 'Yes';
    let showAlert = false;
    let noShowFeePopUp = false;
    let currentRestaurantDateTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    let isWebReservation = false;

    if(party && party.PartySourceId){
      isWebReservation = this.partyService.isWebReservation(party.PartySourceId);
    }

    if (moment(party.ReservedFor) < moment(currentRestaurantDateTime)) {
      msg = this.translateService.instant('reservationCancelMsg');
      cancelText = this.translateService.instant('cancelReservationMsg');
      cancelReservation = false;
      updateText = this.translateService.instant('noShowState');
      title = this.translateService.instant('alert');
      showAlert = true;
      noShowFeePopUp = true;
    }

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm', showAlert: showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '350px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    this.subscription.add(dialogRef.afterClosed().subscribe(event => {
      if (cancelReservation) {
        this.subscription.add(this.partyService.cancelReservation(party.Id).subscribe(
          (data) => {
            cancelReservation = false;
            if (data.Payload != null) {
              this.partyService.reservationType = ReservationType.Reservation;
              if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 1) {
                this.partyService.openConfirmationDialog(data, "A refund for " + this.cs.operationCurrency + data.Payload.RefundAmount + " has been successfully processed for this cancelled reservation.", party.SeatingTime);
              }
              if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 2) {
                this.partyService.openConfirmationDialog(data, "An attempted refund for "+this.cs.operationCurrency + data.Payload.RefundAmount + " for this reservation failed. Further attempts to process this refund will need to be performed manually.", party.SeatingTime);
              }
              if (data.Payload && data.Payload.EmailAddress && (this.cs.settings.value.General.HostCancellationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
                this.partyService.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Cancelled);
              }
            }
            this.appService.showDashboard = false;
          }));
      } else if (noShowSet && noShowFeePopUp) {
        this.subscription.add(this.partyService.noShowParty(party.Id).subscribe((data) => {
          if (data.State == OperationResultState.Success) {
            noShowSet = false;
            this.appService.showDashboard = false;
          }
        }));
      }
      if(confirmedActionSubscription){
        confirmedActionSubscription.unsubscribe();
      }
      if(cancelledActionSubscription){
        cancelledActionSubscription.unsubscribe();
      }
    }));

   let confirmedActionSubscription = this.ps.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.reservation && noShowFeePopUp) {
        noShowSet = true;
      }
      if (val === ComponentTypes.reservation && !noShowFeePopUp) {
        cancelReservation = true;
      }
    });
    let cancelledActionSubscription = this.ps.cancelledAction$.subscribe(val => {
      if (val.value === ComponentTypes.reservation && !cancelReservation && noShowFeePopUp) {
        cancelReservation = true;
      }
    });
  }

  elementSelected(TC, event) {
    this.appService.showDashboard = true;
    this.partyService.selectedParty$.next(TC);
  }

  unassignedPartySelected(unassignedParty) {
    this.appService.showDashboard = true;
    this.partyService.selectedParty$.next(unassignedParty);
  }

  getBorder(row, col) {
    if (this.el.nativeElement.getElementsByClassName('data_' + row + '_' + col).length > 0) {
      this.el.nativeElement.getElementsByClassName('data_' + row + '_' + col)[0].style['border-right-width'] = '0px';
    }
  }

  getWidth(reservationStatus, data) {
    let isDepartureTimeNotAvailableInShifts = false;
    let shiftID;
    this.currentDayShifts.forEach((shift, index) => {
      if (moment(data.SeatingTime).diff(moment(shift.EffectiveRange.End), 'minutes') === 0) {
        isDepartureTimeNotAvailableInShifts = true;
        shiftID = index;
      }
    });
    let timeDifference;
    if (!isDepartureTimeNotAvailableInShifts) {
      timeDifference = (moment(data.DepartureTime).diff(moment(data.SeatingTime), 'minutes'));
    } else {
      this.currentDayShifts.forEach((shift, index) => {
        if (index < this.currentDayShifts.length - 1 && index === shiftID) {
          const shiftTimeDiff = (moment(this.currentDayShifts[index + 1].EffectiveRange.Start)
            .diff(moment(this.currentDayShifts[index].EffectiveRange.End), 'minutes'));
          if (shiftTimeDiff > 30) {
            timeDifference = (moment(data.DepartureTime).diff(moment(data.SeatingTime), 'minutes'));
            if (timeDifference <= 30) {
              timeDifference = timeDifference;
            } else {
              timeDifference = 30;
            }
          } else {
            timeDifference = (moment(data.DepartureTime).diff(moment(data.SeatingTime), 'minutes'));
          }
        } else if (index == this.currentDayShifts.length - 1 && index === shiftID) {
          timeDifference = (moment(data.DepartureTime).diff(moment(data.SeatingTime), 'minutes'));
        }
      });
    }
    if(moment(data.DepartureTime).diff(moment(this.timeArrayGenerated[this.timeArrayGenerated.length-1]), 'minutes') > 0){
      const differenceStartTime = (new Date(this.timeArrayGenerated[this.timeArrayGenerated.length - 1]).setMinutes(new Date(this.timeArrayGenerated[this.timeArrayGenerated.length - 1]).getMinutes() + 30));
      let differenceTime = moment(data.DepartureTime).diff(moment(differenceStartTime), 'minutes')
      const totalvalue = (timeDifference / 15);
      this.widthSet = document.getElementsByClassName('timeline__display-cell')[0].clientWidth;
      const value = (((this.widthSet / 2) * totalvalue) -((this.widthSet / 2) * (differenceTime/15)) - 5) + 'px';
      return value;
    }
    else{
    const totalvalue = (timeDifference / 15);
    this.widthSet = document.getElementsByClassName('timeline__display-cell')[0].clientWidth;
    const value = (((this.widthSet / 2) * totalvalue) - 5) + 'px';
    return value;
    }
  }

  getleft(reservationStatus, data) {
    let minutes: any = moment(data.SeatingTime).format("mm");
    let nearestMinuteValue = Math.floor(((minutes / 15) * 100) / 100);
    nearestMinuteValue = nearestMinuteValue * 15;
    const totalvalue = minutes - nearestMinuteValue;
    const value = ((totalvalue * 2) + 2) + 'px';
    return value;
  }

  onDragStart(event, data, index, dataIndex, item) {
    const positionArray = event.currentTarget.parentElement.id.split('_');
    this.rowIndex = positionArray.splice(-1, 1);
    this.colIndex = positionArray.splice(-2, 1);
    this.ts.draggedData = item;
    const tableData = this.currentReservations.findIndex(i => i.Id === data.Id);
  }

  onItemDrop(event, data, index, outerIndex, innerIndex) {
    const dropevent = cloneDeep(event);
    const tableData = this.currentReservations.findIndex(i => i.Id === data.Id);
    if (!data.IsCommunalTable) {
      const timeDifference = (moment(this.ts.draggedData.DepartureTime).diff(moment(this.ts.draggedData.SeatingTime), 'minutes'));
      const totalvalue = (timeDifference / 30) * 2;
      const reservationDataLength = this.currentReservations[tableData].data[index][outerIndex].reservationData.length;
      const dataLength = this.currentReservations[tableData].data[index].length;
      let count = 0;
      let loopBreak = false;

      tableLoop:
      for (let k = outerIndex; k < dataLength; k++) {
        for (let l = innerIndex; l < reservationDataLength; l++) {

          if (this.currentReservations[tableData].data[index][k].reservationData[l].data.length == 0) {
            count++;
          } else {
            if (this.currentReservations[tableData].data[index][k].reservationData[l].data[0].Id == this.ts.draggedData.Id) {
              count++;
            } else {
              loopBreak = true;
              break tableLoop;
            }
          }
        }
        innerIndex = 0;
      }

      if (loopBreak) {
        const msg = { showMessage: true, message: 'Please select an alternate Table as Table 5 has been already occupied' };
        this.ts.draggedData.msg = msg;
        this.openPop();
      } else {
        this.ts.draggedData.msg = '';
        this.openPop();
      }
    } else {
      this.ts.draggedData.msg = '';
      this.openPop();
    }
  }

  dropData(event, TC, row, col, index) {
    let dataSet = false;
    const size = 0;
    const currentselectedDiv: HTMLElement = document.getElementById('data_' + row + '_' + col) as HTMLElement;
    const cellWidth = currentselectedDiv.offsetWidth;
    const cellDraggedElement: HTMLElement = document.getElementById('cellDragged_' + row + '_' + col + '_' + index) as HTMLElement;
    const indexArray = cellDraggedElement.id.split('_');
    const currentcell: HTMLElement = document.getElementById('cell_' + index) as HTMLElement;
    const rowIndex = indexArray[2];
    const dataIndex = indexArray[3];
    const indexOfStevie = this.currentReservations.findIndex(i => i.Id === this.tableId);
    this.currentReservations.forEach((element, index) => {

      if (TC.Id === element.Id) {
        if (element.data[rowIndex][dataIndex].available === true && element.data[rowIndex][dataIndex].name === 0) {
          element.data[rowIndex][dataIndex].name = 1;
          element.data[rowIndex][dataIndex].width = this.draggedElementWidth;
          dataSet = true;
          element.data[rowIndex][dataIndex].guestName = this.currentReservations[indexOfStevie].data[this.rowIndex][this.colIndex].guestName;
          element.data[rowIndex][dataIndex].size = this.currentReservations[indexOfStevie].data[this.rowIndex][this.colIndex].size;
          element.data[rowIndex][dataIndex].available = false;
        } else if (element.data[rowIndex][dataIndex].available === false && element.data[rowIndex][dataIndex].reservedId === this.reservedId) {
          element.data[rowIndex][dataIndex].name = 1;
          element.data[rowIndex][dataIndex].width = this.draggedElementWidth;
          dataSet = true;
          element.data[rowIndex][dataIndex].guestName = this.currentReservations[indexOfStevie].data[this.rowIndex][this.colIndex].guestName;
          element.data[rowIndex][dataIndex].size = this.currentReservations[indexOfStevie].data[this.rowIndex][this.colIndex].size;
          element.data[rowIndex][dataIndex].available = false;
        } else if (TC.IsCommunalTable && element.Id !== this.tableId) {
          dataSet = true;
          element.data[rowIndex][dataIndex].size = this.currentReservations[indexOfStevie].data[this.rowIndex][this.colIndex].size + element.data[rowIndex][dataIndex].size;
        }
      }
    });
    if (dataSet) {
      this.currentReservations[indexOfStevie].data[this.rowIndex][this.colIndex].name = 0;
      this.currentReservations[indexOfStevie].data[this.rowIndex][this.colIndex].available = true;
      this.currentReservations[indexOfStevie].data[this.rowIndex][this.colIndex].guestName = 0;
    }
    this.currentReservations = [...this.currentReservations];
    this.employee.push(TC);
  }

  openPop() {
    this.componentDetails = {
      componentName: ReservationChangeConfirmComponent,
      popupType: '',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '700px',
      data: {
        title: 'Are you sure to move your guest', update: 'CONFIRM', cancel: 'CANCEL',
        from: ComponentTypes.timeline, standalone: true, componentDetails: this.componentDetails
      }
    });
  }

  selectedReservation() {
    this.appService.closeNav = false;
  }

  zoomInTimeline() {
    const tableHeader = Array.from(document.getElementsByClassName('timeline__table-header'));
    this.defaultWidth = this.defaultWidth - 20;

    for (let i = 0; i < tableHeader.length; i++) {
      const tableHeaderRow = tableHeader[i] as HTMLElement;
      tableHeaderRow.style.minWidth = this.defaultWidth + 'px';
      tableHeaderRow.style.width = this.defaultWidth + 'px';
      if (this.cellDefaultWidth > this.defaultWidth) {
        tableHeaderRow.style.fontSize = '10px';
        if (this.defaultWidth <= 30) {
          this.showName = false;
        }
      } else {
        tableHeaderRow.style.fontSize = '12px';
        this.showName = true;
      }
    }
    const tableDataArray = Array.from(document.getElementsByClassName('timeline__display-cell'));
    for (let i = 0; i < tableDataArray.length; i++) {
      const tableDataRow = tableDataArray[i] as HTMLElement;
      tableDataRow.style.minWidth = this.defaultWidth + 'px';
      tableDataRow.style.width = this.defaultWidth + 'px';
    }
    this.zoomoutEnabled = true;
    if (this.defaultWidth <= 45) {
      this.buttonControls[2].disbaledproperity = true;
    }
    this.currentReservations = [...this.currentReservations];
  }

  zoomOutTimeline() {
    const tableData = Array.from(document.getElementsByClassName('timeline__table-header'));
    this.defaultWidth = this.defaultWidth + 20;
    this.buttonControls[2].disbaledproperity = false;
    for (let i = 0; i < tableData.length; i++) {
      const tableHeaderData = tableData[i] as HTMLElement;
      tableHeaderData.style.minWidth = this.defaultWidth + 'px';
      if (this.cellDefaultWidth > this.defaultWidth) {
        tableHeaderData.style.fontSize = '10px';
        if (this.defaultWidth < 30) {
          this.showName = false;
        }
      } else {
        tableHeaderData.style.fontSize = '12px';
        this.showName = true;
      }
    }
    const tableDataArray = Array.from(document.getElementsByClassName('timeline__display-cell'));
    for (let i = 0; i < tableDataArray.length; i++) {
      const tableRowData = tableDataArray[i] as HTMLElement;
      tableRowData.style.minWidth = 0 + 'px';
      tableRowData.style.width = this.defaultWidth + 'px';
    }
    this.currentReservations = [...this.currentReservations];
  }

  resetPercentage() {
    this.buttonControls[2].disbaledproperity = false;
    const tableData = Array.from(document.getElementsByClassName('timeline__table-header'));
    this.defaultWidth = cloneDeep(this.cellDefaultWidth);
    this.showName = true;
    for (let i = 0; i < tableData.length; i++) {
      const tableDataHeader = tableData[i] as HTMLElement;
      tableDataHeader.style.minWidth = this.defaultWidth + 'px';
      tableDataHeader.style.width = this.defaultWidth + 'px';
      tableDataHeader.style.fontSize = '12px';
    }
    const tableDataArray = Array.from(document.getElementsByClassName('timeline__display-cell'));
    for (let i = 0; i < tableDataArray.length; i++) {
      const tableRowData = tableDataArray[i] as HTMLElement;
      tableRowData.style.minWidth = 0 + 'px';
      tableRowData.style.width = this.defaultWidth + 'px';
    }
  }

  currentDayTimline() {
    document.getElementById('timeLineTable').scrollLeft = 0;
  }

  setCustomLayout() {
    this.customFloorPlan = false;
    let selectedDate = new Date(this.appService.headerDate$.value).setHours(0, 0, 0, 0);
    let CustomFloorPlanDeatils = Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, this.appService.headerDate$.value);
    if (CustomFloorPlanDeatils && CustomFloorPlanDeatils.length > 0 && CustomFloorPlanDeatils[0].Dates) {
      let getShiftForDate = CustomFloorPlanDeatils
        .filter((element) =>
          element.Dates.some((subElement) => Utilities.formateDateString(subElement.Date).setHours(0, 0, 0, 0) == selectedDate && subElement.ShiftId != null && subElement.ShiftId > 0))
        .map(element => {
          let newElt = Object.assign({}, element);
          return newElt.Dates.filter(subElement => Utilities.formateDateString(subElement.Date).setHours(0, 0, 0, 0) == selectedDate && subElement.ShiftId != null && subElement.ShiftId > 0);
        });

      if (getShiftForDate.length > 0) {
        this.customFloorPlan = true;
      }

    }
  }

  currentShiftID(TodayShifts: ShiftDTO[], canCalculateTimeDiff = true) {
    let curentshift = 0;
    let currentShiftStartTime =  null;
    this.isOldShift = false;
    if(this.ps && this.partyService.navigationShiftIdFromReservation > 0){
      return this.partyService.navigationShiftIdFromReservation;
    }
    let currentdate = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    let selecteddate = new Date(this.appService.headerDate$.value.setHours(0, 0, 0, 0)).getTime();
    let CurrentDateTime = new Date(new Date(currentdate).setHours(0, 0, 0, 0));
    if (CurrentDateTime.getTime() == selecteddate) {
      let CurrentShift = TodayShifts.filter(Shift => {
        if (Utilities.datetimeBetween(Shift.EffectiveRange.Start, Shift.EffectiveRange.End, currentdate)) {
          return true;
        }
      });
      if (CurrentShift && CurrentShift.length > 0) {
        curentshift = CurrentShift[0].Id;
      } else {
        let PrevShift = TodayShifts.filter(Shift => {
          if (Utilities.diffBetweenDateTime(Shift.EffectiveRange.End, currentdate) < 0) {
            return true;
          }
        });
        this.isOldShift = true;
        if (PrevShift && PrevShift.length > 0) {
          let maxtime = Math.max.apply(Math, PrevShift.map(function (o) { return new Date(Utilities.formateDateString(o.EffectiveRange.End)); }))
          let maxHour = new Date(maxtime).getHours() + (new Date(maxtime).getMinutes() / 60);
          let maxshift = PrevShift.filter(Shift => {
            let Shiftendhour = Utilities.formateDateString(Shift.EffectiveRange.End).getHours() + (Utilities.formateDateString(Shift.EffectiveRange.End).getMinutes() / 60);
            if (Number(Shiftendhour) == Number(maxHour)) {
              return true;
            }
          });
          if (maxshift && maxshift.length > 0) {
            curentshift = maxshift[0].Id;
          }
          else {
            if(PrevShift && PrevShift.length){
            curentshift = PrevShift[0].Id;
          }
          }

        } else {
          if(TodayShifts && TodayShifts.length){
          curentshift = TodayShifts[0].Id;
          }
        }

      }

    } else if (new Date(CurrentDateTime.setDate(CurrentDateTime.getDate() - 1)).getTime() == selecteddate) {
      let CurrentShift = TodayShifts.filter(Shift => {
        if (Utilities.datetimeBetween(Shift.EffectiveRange.Start, Shift.EffectiveRange.End, currentdate)) {
          return true;
        }
      });
      this.isOldShift = true;
      if (CurrentShift != null && CurrentShift.length > 0) {
        curentshift = CurrentShift[0].Id;
      }
      else {
        curentshift = 0;
      }
    } else {
      curentshift = 0;
    }
    if (!this.customFloorPlan && canCalculateTimeDiff) {
      let currentShift = TodayShifts.filter((shift) => shift.Id === curentshift);
      if (currentShift && currentShift.length) {
        currentShiftStartTime = currentShift[0].EffectiveRange.Start;
        let timeDiffInMins = null;
        TodayShifts.forEach((shift) => {
          if (shift.Id !== curentshift && moment(shift.EffectiveRange.Start) >= moment(currentShiftStartTime)) {
            let shiftStartTime = moment(shift.EffectiveRange.Start).format('YYYY-MM-DDTHH:mm:ss');
            let currentTime = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)).format('YYYY-MM-DDTHH:mm:ss');
            let currentTimeDiff = Math.abs(moment(shiftStartTime).diff(moment(currentTime), 'seconds'));
            if (!timeDiffInMins) {
              timeDiffInMins = currentTimeDiff;
            } else if (currentTimeDiff <= timeDiffInMins) {
              timeDiffInMins = currentTimeDiff;
            }
          }
        });
        this.nextShiftTimeDiffInMins$.next(timeDiffInMins);
      }
    }
    return curentshift;
  }

  getShiftByDate(selectedShift: ShiftDTO[]) {
    this.selectedShiftIds = selectedShift.map(function (item) { return item.Id; });
    this.shiftConfig = [];
    this.shiftConfig.push({
      type: 'select',
      label: 'Select Shift',
      name: 'shift',
      placeholder: 'Shift',
      options: [],
      class: 'timeline__shift-selection',
      showErrorText: true,
      appearance: true,
      value: -1,
      cellClick: this.setSelectedShift.bind(this)
    });

    if (this.shiftConfig) {
      this.shiftConfig[0].options = [];
      let val = [];
      if (!this.customFloorPlan) {
        this.shiftConfig[0].options.push({ id: -1, value: this.translateService.instant('All Shifts') });
        val.push({ id: -1, value: this.translateService.instant('All Shifts') });
        this.shiftConfig[0].value = (this.timelineShiftId > 0) ? this.timelineShiftId : val[0].id;
        const mappedOptions = selectedShift.map(x => ({ id: x.Id, value: x.Name }));
        mappedOptions.forEach(x => {
          this.shiftConfig[0].options.push(x);
          val.push(x);
        });
      }
      else {
        if (this.timelineShiftId == null) {
          this.shiftConfig[0].options.push({ id: -1, value: this.translateService.instant('All Shifts') });
          val.push({ id: -1, value: this.translateService.instant('All Shifts') });
          this.shiftConfig[0].value = val[0].id;
          const mappedOptions = selectedShift.map(x => ({ id: x.Id, value: x.Name }));
          mappedOptions.forEach(x => {
            this.shiftConfig[0].options.push(x);
            val.push(x);
          });
        }
        else {
          const mappedOptions = selectedShift.map(x => ({ id: x.Id, value: x.Name }));
          mappedOptions.forEach(x => {
            this.shiftConfig[0].options.push(x);
            val.push(x);
          });
          this.shiftConfig[0].value = this.timelineShiftId;
        }
      }

    }
  }

  setSelectedShift(eve) {
    this.timelineShiftId = eve.value == -1 ? 0 : eve.value;
    this.loadReservations();

  }

  trackById(index: number, data: any): string {
    return data.Id;
  }

  ngOnDestroy() {
    this.partyService.reservationType = null;
    this.partyService.navigationShiftIdFromReservation = 0
    if (this.headerSubscription) {
      this.headerSubscription.unsubscribe();
    }
    this.partyService.selectedParty$.next(null);
    // this.partyService.Parties$.next([]);
    if (this.subscription) { this.subscription.unsubscribe(); }
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    if (this.timerSubscription) { this.timerSubscription.unsubscribe(); }
  }
}

@Pipe({
  name: 'reservationBGColor'
})
export class ReservationBGColorPipe implements PipeTransform {
  transform(data: any, _settings: SettingsDTO): any {
    if (data.State == PartyState.Seated) {
      const statusColor = _settings.Statuses.filter(x => x.Id == data.StatusId)[0].Color;
      return 'rgba(' + statusColor.R + ',' + statusColor.G + ',' + statusColor.B + ',' + statusColor.A + ')';
    } else {
      switch (data.State) {
        case 0:
          return '#5093E1';
        case 3:
          return '#000';
        default:
          return '#5093E1';
      }
    }
  }
}


@Pipe({
  name: 'reservationBarLeft'
})
export class ReservationBarLeftPipe implements PipeTransform {
  transform(data: any): any {
    let minutes: any = moment(data.SeatingTime).format("mm");
    let nearestMinuteValue = Math.floor(((minutes / 15) * 100) / 100);
    nearestMinuteValue = nearestMinuteValue * 15;
    const totalvalue = minutes - nearestMinuteValue;
    const value = ((totalvalue * 2) + 2) + 'px';
    return value;
  }
}

@Pipe({
  name: 'reservationBarWidth'
})
export class ReservationBarWidthPipe implements PipeTransform {
  transform(data: any, currentDayShifts: any, timeArrayGenerated: any): any {
    let isDepartureTimeNotAvailableInShifts = false;
    let shiftID;
    currentDayShifts.forEach((shift, index) => {
      if (moment(data.SeatingTime).diff(moment(shift.EffectiveRange.End), 'minutes') === 0) {
        isDepartureTimeNotAvailableInShifts = true;
        shiftID = index;
      }
    });
    let timeDifference;
    if (!isDepartureTimeNotAvailableInShifts) {
      timeDifference = (moment(data.DepartureTime).diff(moment(data.SeatingTime), 'minutes'));
    } else {
      currentDayShifts.forEach((shift, index) => {
        if (index < currentDayShifts.length - 1 && index === shiftID) {
          const shiftTimeDiff = (moment(currentDayShifts[index + 1].EffectiveRange.Start)
            .diff(moment(currentDayShifts[index].EffectiveRange.End), 'minutes'));
          if (shiftTimeDiff > 30) {
            timeDifference = (moment(data.DepartureTime).diff(moment(data.SeatingTime), 'minutes'));
            if (timeDifference <= 30) {
              timeDifference = timeDifference;
            } else {
              timeDifference = 30;
            }
          } else {
            timeDifference = (moment(data.DepartureTime).diff(moment(data.SeatingTime), 'minutes'));
          }
        } else if (index == currentDayShifts.length - 1 && index === shiftID) {
          timeDifference = (moment(data.DepartureTime).diff(moment(data.SeatingTime), 'minutes'));
        }
      });
    }
    if (moment(data.DepartureTime).diff(moment(timeArrayGenerated[timeArrayGenerated.length - 1]), 'minutes') > 0) {
      const differenceStartTime = (new Date(timeArrayGenerated[timeArrayGenerated.length - 1]).setMinutes(new Date(timeArrayGenerated[timeArrayGenerated.length - 1]).getMinutes() + 30));
      let differenceTime = moment(data.DepartureTime).diff(moment(differenceStartTime), 'minutes')
      const totalvalue = (timeDifference / 15);
      const widthSet = document.getElementsByClassName('timeline__display-cell')[0].clientWidth;
      const value = (((widthSet / 2) * totalvalue) - ((widthSet / 2) * (differenceTime / 15)) - 5) + 'px';
      return value;
    }
    else {
      const totalvalue = (timeDifference / 15);
      const widthSet = document.getElementsByClassName('timeline__display-cell')[0].clientWidth;
      const value = (((widthSet / 2) * totalvalue) - 5) + 'px';
      return value;
    }
  }
}

const MESSAGES = {
  labels: {
    currentTimeText: "currentTimeText",
    zoomOut: "-",
    zoomIn: "+",
    reset: "100%",
  }
}