import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { buttonTypes } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { PagingOptions, SettingsDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-multi-paging',
  templateUrl: './multi-paging.component.html',
  styleUrls: ['./multi-paging.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiPagingComponent implements OnInit, OnDestroy {

  buttonValueprimary: ButtonValue;
  buttonSecondary: ButtonValue;
  buttonSend: ButtonValue;
  buttonCancel: ButtonValue;
  allReservations: any = [];
  pagingOptions: PagingOptions[];
  predefinedMessages: any = [];
  selectedMessage: any = [];
  _settings: SettingsDTO = {} as SettingsDTO;
  subscriptions: Subscription = new Subscription();
  constructor(public dialogRef: MatDialogRef<MultiPagingComponent>, public cs: CacheService, private _ps: PartyService) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
      this.predefinedMessages = sett.PredefinedPartyMessages.filter(item => item.AppliesToInitial == true);
    }));
  }

  ngOnInit() {
    this.buttonValueprimary = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Select All',
      customclass: 'multipaging__select-btn'
    }
    this.buttonSecondary = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Unselect All',
      customclass: 'multipaging__unselect-btn'
    }
    this.buttonSend = {
      type: buttonTypes.actionPrimary,
      label: 'Send',
      customclass: 'next-btn',
      disbaledproperity: true
    };
    this.buttonCancel = {
      type: buttonTypes.actionSecondary,
      label: 'Cancel',
      customclass: 'cancel-btn'

    };
    this.pagingOptions = [];
    this.allReservations = _.cloneDeep(this._ps.filteredReservations);
  }

  selectAll(data, index) {
    data.forEach(element => {
      element.selected = true;
      if (this.pagingOptions.findIndex(a => a.PartyId === element.Id) == -1)
        this.pagingOptions.push({ PartyId: element.Id, pageMethod: element.PageMethod })
    });
    if (this.selectedMessage.length > 0 && this.pagingOptions.length > 0) {
      this.buttonSend.disbaledproperity = false;
    } else {
      this.buttonSend.disbaledproperity = true;
    }
  }

  unselectAll(data, index) {
    data.forEach(element => {
      element.selected = false;
      if (this.pagingOptions.findIndex(a => a.PartyId === element.Id) > -1)
        this.pagingOptions.splice(this.pagingOptions.findIndex(a => a.PartyId === element.Id), 1)
    });
    if (this.selectedMessage.length > 0 && this.pagingOptions.length > 0) {
      this.buttonSend.disbaledproperity = false;
    } else {
      this.buttonSend.disbaledproperity = true;
    }
  }

  selectData(client) {
    client.selected = !client.selected;
    if (client.selected) {
      if (this.pagingOptions.findIndex(a => a.PartyId === client.Id) == -1)
        this.pagingOptions.push({ PartyId: client.Id, pageMethod: client.PageMethod })
    } else {
      this.pagingOptions.splice(this.pagingOptions.findIndex(a => a.PartyId === client.Id), 1)
    }
    if (this.selectedMessage.length > 0 && this.pagingOptions.length > 0) {
      this.buttonSend.disbaledproperity = false;
    } else {
      this.buttonSend.disbaledproperity = true;
    }
  }

  selectMessage(message) {
    this.selectedMessage = [];
    message.selected = !message.selected
    this.selectedMessage.push(message.Id);
    if (this.selectedMessage.length > 0 && this.pagingOptions.length > 0) {
      this.buttonSend.disbaledproperity = false;
    } else {
      this.buttonSend.disbaledproperity = true;
    }
  }

  sendMessage() {
    if (!this.buttonSend.disbaledproperity) {
      let index = this.predefinedMessages.findIndex(a => a.Id === this.selectedMessage[0]);
      this.subscriptions.add(this._ps.postPageMultiplePartiesMessage(this.predefinedMessages[index].Text.Message, this.pagingOptions,this.predefinedMessages[index].Text.Code)
        .subscribe((response) => {
          this.dialogRef.close();
        }));
    }
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
