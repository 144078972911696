import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { MatDialogRef } from "@angular/material/dialog";
import { ShopDialogPopUp } from "../../shop-dialog-popup/shop-dialog-popup.component";
import { IssueGiftCardPopupModel, GiftCardConfiguration, GiftCardTypeNames } from 'src/app/retail/shared/service/payment/payment-model';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { BaseResponse, HandleResponse, OutletSubProperty } from 'src/app/retail/shared/business/shared.modals';
import { GiftCardBusiness, SearchScreen } from 'src/app/retail/sytem-config/gift-cards/gift-cards.business';
import { ButtonType, GiftCardType, Host, Product } from 'src/app/retail/shared/globalsContant';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { HttpMethod, HttpServiceCall } from 'src/app/retail/shared/service/http-call.service';
import * as _ from 'lodash';
import { AlertType } from "src/app/retail/shared/shared.modal";

@Component({
  selector: "app-retail-gift-cards-form",
  templateUrl: "./retail-gift-cards-form.component.html",
  styleUrls: ["./retail-gift-cards-form.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class RetailGiftCardsFormComponent implements OnInit {
  giftCardsFormGroup: UntypedFormGroup;
  captions: any;
  commonCaptions: any;
  giftCardCaptions: any;
  selectedDevice = "";
  startDate = new Date();
  giftCardHandleInfo: HandleResponse = null;
  @Input("config") giftCardConfig: [IssueGiftCardPopupModel, GiftCardConfiguration];
  currentOutetId: number = 0;
  captionsLocalization: any;
  placeHolderFormat: string;
  get EnableBtn(): boolean {
    return this.giftCardsFormGroup && this.giftCardsFormGroup.controls.thirdparty.value || this.giftCardConfig[0].isTopUp ? (this.giftCardsFormGroup && this.giftCardsFormGroup.valid && this.giftCardHandleInfo != null) :
      (this.giftCardsFormGroup && this.giftCardsFormGroup.valid && this.giftCardHandleInfo
        && (this.giftCardHandleInfo.isCardActive || this.giftCardHandleInfo.paymentHandle == null)) //-- To Allow Pre-Activated Cards to Sell Again - 70804
  }
  Outlets: OutletSubProperty[] = [];
  SearchScreen = SearchScreen;
  get isAgilysysGiftcard(){
    return (
      (this.giftCardsFormGroup && this.giftCardsFormGroup.controls.thirdparty.value || this.giftCardConfig[0].thirdparty) &&
      this.giftcardBusiness.IsAgilysysGiftcard
    );
  }
  get isThirdPartyToggleAllowed() {
    return (this.giftcardBusiness.GiftcardType == GiftCardType.ExternalGiftCard
      || this.giftcardBusiness.GiftcardType == GiftCardType.V1GiftCard)
  }
  @ViewChild("giftcardAmt") giftcardAmtField: ElementRef;
  floatLabel: string;
  textmaskFormat: string;
  get AllowNameSearch() { return this.giftCardConfig[1] ? this.giftCardConfig[1]?.allowSearchByGuestName : false }  

  constructor(
    public localization: RetailLocalization,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ShopDialogPopUp>,
    private giftcardBusiness: GiftCardBusiness,
    private utils: RetailUtilities,
    private _propertyInfo: RetailPropertyInformation,
    private _shopService: CommonVariablesService,
    private _http: HttpServiceCall
  ) {
    this.captions = this.localization.captions.shop;
    this.commonCaptions = this.localization.captions.common;
    this.giftCardCaptions = this.localization.captions.shop.GiftCard;
    this.currentOutetId = _shopService.SelectedOutletId;  
    this.captionsLocalization = this.localization.captions.common;  
    this.placeHolderFormat = this.localization.inputDateFormat;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.LoadOutlets();
    this.textmaskFormat = this.localization.captions.common.PhoneFormat != '' ?
      this.localization.captions.common.PhoneFormat : '999999999999999999'
    if (this.giftCardConfig) {
      this.patchForm();
    } else {
      this.giftCardsFormGroup = this.fb.group({
        thirdparty: (this.giftcardBusiness.GiftcardType == GiftCardType.ExternalGiftCard),
        GiftCardNumber: "",
        Amount: [0, Validators.required],
        NeverExpire: false,
        ExpiryDate: [{ value: "", disabled: true }],
        FirstName: "",
        LastName: "",
        countryCode: "",
        PhoneNumber: "",
        EmailId: "",
      });
      this.SetValidatorsIfNameSearchIsEnabled();
    }
    this.DisableGuestFieldsOnTopUp();
  }

  ngAfterViewInit(){
    this.FocusGiftcardAmtField();    
  }

  ngOnChanges(): void {
    this.patchForm();
  }

  FocusGiftcardAmtField() {    
    setTimeout(() => {
      if (this.giftcardAmtField?.nativeElement) {
        //Clearing the Validators, so that field will not be highlighted with Red    
        this.giftCardsFormGroup.controls.Amount.clearValidators();
        this.giftcardAmtField.nativeElement.select();
        this.giftcardAmtField.nativeElement.focus();
      }
    }, 500);
  }

  SetValidatorsIfNameSearchIsEnabled() {
    if (this.AllowNameSearch && !this.giftCardConfig[0].isTopUp) {      
      this.giftCardsFormGroup.controls.countryCode.setValidators(Validators.required);
      this.giftCardsFormGroup.controls.PhoneNumber.setValidators(Validators.required);
      this.giftCardsFormGroup.controls.EmailId.setValidators(Validators.required);      
    } else {      
      this.giftCardsFormGroup.controls.countryCode.setValidators(null);
      this.giftCardsFormGroup.controls.PhoneNumber.setValidators(null);
      this.giftCardsFormGroup.controls.EmailId.setValidators(null);      
    }
    this.giftCardsFormGroup.updateValueAndValidity();
  }

  DisableGuestFieldsOnTopUp(){
    if(this.giftCardConfig[0].isTopUp){
      this.giftCardsFormGroup.controls.FirstName.disable();
      this.giftCardsFormGroup.controls.LastName.disable();
      this.giftCardsFormGroup.controls.countryCode.disable();
      this.giftCardsFormGroup.controls.PhoneNumber.disable();
      this.giftCardsFormGroup.controls.EmailId.disable();      
    }
  }

  setAmountFieldsValidators() {
    this.giftCardsFormGroup.controls.Amount.setValidators([Validators.min(1), Validators.required]);
  }

  LoadOutlets() {
    if (this.giftCardConfig[0].isFromBalanceScreen && this._shopService.AllOutlets.length == 0) {
      let userMachineConfig = null;
      if (this._shopService.ProductId == Product.GOLF || this._shopService.ProductId == Product.SNC) {
        userMachineConfig = this.utils.getUserDefaults();
      } else {
        userMachineConfig = this.utils.getLoggedinUserSessionConfiguration();
      }
      if (userMachineConfig && !this._shopService.SelectedOutletId) {
        this._shopService.SelectedOutletId = userMachineConfig.defaultOutletId;
      }
      let apiResponse = this._http.CallApiAsync({
        host: Host.retailManagement,
        callDesc: "GetSubPropertyAccessByUser",
        method: HttpMethod.Get,
        uriParams: { userId: this.utils.GetPropertyInfo("UserId") }
      });
      apiResponse.then((res: BaseResponse<OutletSubProperty[]>) => {
        if (res && res.successStatus && res.result && res.result.length > 0) {
          this.Outlets = res.result.filter(x => x.isActive);
          this.FocusGiftcardAmtField();
        }
      });
    } else {
      this.Outlets = this._shopService.AllOutlets;
    }
  }

  patchForm() {
    if (this.giftCardConfig && this.giftCardConfig.length == 2) {      
      if (this.giftCardConfig[0].isTopUp) {
        this.giftCardsFormGroup = this.fb.group({
          thirdparty: [{value: this.giftCardConfig[0].thirdparty, disabled: this.isThirdPartyToggleAllowed}],
          Amount: [{ value: this.giftCardConfig[0].Amount, disabled: true }],
          GiftCardNumber: this.giftCardConfig[0].GiftCardNumber,
          NeverExpire: [{ value: !this.giftCardConfig[1].allowExpiration, disabled: !this.giftCardConfig[1].allowExpiration }],
          ExpiryDate: [{ value: this.giftCardConfig[0].ExpiryDate, disabled: true }],
          FirstName: this.giftCardConfig[0].FirstName,
          LastName: this.giftCardConfig[0].LastName,
          PhoneNumber: ExtractPhoneNumber(this.giftCardConfig[0].PhoneNumber),
          countryCode: ExtractCountryCode(this.giftCardConfig[0].PhoneNumber),
          EmailId: this.giftCardConfig[0].EmailId,
          outlet: [this._shopService.SelectedOutletId > 0 ? this._shopService.SelectedOutletId : '', Validators.required]
        });
        this.giftCardHandleInfo = <HandleResponse> this.giftCardConfig[0].HandleInfo;
      }
      else {
        this.giftCardsFormGroup = this.fb.group({
          thirdparty: [{ value: (this.giftcardBusiness.GiftcardType == GiftCardType.ExternalGiftCard), disabled: this.isThirdPartyToggleAllowed }],
          Amount: [{ value: this.giftCardConfig[0].Amount, disabled: !this.giftCardConfig[0].openItem }],
          GiftCardNumber: "",
          NeverExpire: [{ value: !this.giftCardConfig[1].allowExpiration, disabled: !this.giftCardConfig[1].allowExpiration }],
          ExpiryDate: [{ value: this.localization.ConvertDateToISODate(this.localization.AddDays(this._propertyInfo.CurrentDate, this.giftCardConfig[1].expireDays)), disabled: true }],
          FirstName: "",
          LastName: "",
          PhoneNumber: "",
          EmailId: "",
          countryCode: "",
          outlet: this.giftCardConfig[0].isFromBalanceScreen ? [this._shopService.SelectedOutletId > 0 ? this._shopService.SelectedOutletId : '' , Validators.required] : 0
        });                
      }
    }
    this.FocusGiftcardAmtField();
    this.SetValidatorsIfNameSearchIsEnabled();
  }

  OnNeverExpireToggle(event) {
    if(event[0]){
      this.giftCardsFormGroup.controls.ExpiryDate.setValue(null) 
    }
    else{
      let expiryDay = this.giftCardConfig[0].ExpiryDate && this.giftCardConfig[0].ExpiryDate != this.captions.NA 
                        ? this.giftCardConfig[0].ExpiryDate 
                        :  this.localization.ConvertDateToISODate(this.localization.AddDays(this._propertyInfo.CurrentDate, this.giftCardConfig[1].expireDays));
      this.giftCardsFormGroup.controls.ExpiryDate.setValue(expiryDay)
    }
   }

  SaveGiftCardDetails() {
    let formVal = this.giftCardsFormGroup.getRawValue();
    const GCNum = this.giftCardHandleInfo?.paymentHandle != null ? this.giftCardHandleInfo.paymentHandle[0].inquiryInfo.id : this.giftCardHandleInfo?.cardNumber;
    if (this.giftCardHandleInfo) {
      let updatedValues: IssueGiftCardPopupModel = {
        Amount: this.localization.currencyToSQLFormat(formVal.Amount),
        FirstName: formVal.FirstName,
        LastName: formVal.LastName,
        PhoneNumber: formVal.countryCode && formVal.PhoneNumber ? formVal.countryCode + CountryCodeSeparator + formVal.PhoneNumber.replace(/\D/g, '') : "",
        EmailId: formVal.EmailId,
        GiftCardNumber: GCNum,
        NeverExpire: formVal.NeverExpire,
        ExpiryDate: formVal.NeverExpire || (formVal.ExpiryDate == this.captions.NA) ? null : this.utils.convertDateFormat(formVal.ExpiryDate),
        thirdparty: formVal.thirdparty,
        openItem: this.giftCardConfig[0].openItem,
        HandleInfo: this.giftCardHandleInfo,
        isTopUp: (this.giftCardConfig[0].isTopUp && this.giftCardHandleInfo.isCardActive)
      };
      this.dialogRef.close(updatedValues); 
    }    
  }

  OnCancel() {
    this.dialogRef.close();
  }

  HandleGiftCardData(giftCardData: HandleResponse) {
    console.log(giftCardData);
    this.giftCardHandleInfo = giftCardData;
    if (this.giftCardConfig[0].openItem) {
      this.giftCardsFormGroup.controls.Amount.markAsTouched();
      this.giftCardsFormGroup.controls.Amount.updateValueAndValidity();
    }    
    if (this.giftCardHandleInfo?.isCardActive && giftCardData.cardNumber) {
      this.PatchExistingCardDetails(giftCardData);
    } else {
      this.giftCardsFormGroup.controls.ExpiryDate.setValue(
        !this.giftCardConfig[1].allowExpiration ? null
          : this.localization.ConvertDateToISODate(
            this.localization.AddDays(
              this._propertyInfo.CurrentDate
              , this.giftCardConfig[1].expireDays)
          )
      );
    }
  }

  PatchExistingCardDetails(giftCardData: HandleResponse) {
    this.giftcardBusiness.getGiftCardPaymentRecords(giftCardData.cardNumber).then((result) => {
      if (result && result.length > 0) {
        if (giftCardData.isThirdParty) {
          result = result.filter(x => [GiftCardTypeNames.Shift4, GiftCardTypeNames.Vantiv, GiftCardTypeNames.Givex, GiftCardTypeNames.Agilysys].includes(x.type));
        } else {
          result = result.filter(x => x.type == GiftCardTypeNames.V1GiftCard);
        }
        result = _.orderBy(result, 'paymentTransactionId', 'desc');
        const expiryDate = result && result[0] && result[0].expiryDate;
        if (expiryDate && this.utils.getDate(expiryDate) < this._propertyInfo.CurrentDate) {
          this.giftCardHandleInfo = null; //Reset card handle to disable the add button
          this.utils.showAlert(this.giftCardCaptions.CardExpired, AlertType.Warning, ButtonType.Ok);
          return;
        }
        this.giftCardsFormGroup.patchValue({
          ExpiryDate: expiryDate ? this.localization.LocalizeDate(expiryDate) : this.captions.NA,
          FirstName: result && result[0] && result[0].firstName ? result[0].firstName : '',
          LastName: result && result[0] && result[0].lastName ? result[0].lastName : '',
          EmailId: result && result[0] && result[0].email ? result[0].email : '',
          PhoneNumber: result && result[0] && result[0].PhoneNumber ? ExtractPhoneNumber(result[0].PhoneNumber) : '',
          countryCode: result && result[0] && result[0].PhoneNumber ? ExtractCountryCode(result[0].PhoneNumber) : '',
        });
        this.giftCardsFormGroup.updateValueAndValidity();
        this.FocusGiftcardAmtField();
      }
    });
  }

  setEmailPhoneValidators(formControlName, value) {
    if (formControlName == 'PhoneNumber') {
      this.giftCardsFormGroup.controls.EmailId.setValidators(value ? null : Validators.required);
      this.giftCardsFormGroup.controls.EmailId.updateValueAndValidity();
    } else {
      this.giftCardsFormGroup.controls.PhoneNumber.setValidators(value ? null : Validators.required);
      this.giftCardsFormGroup.controls.PhoneNumber.updateValueAndValidity();
      this.giftCardsFormGroup.controls.countryCode.setValidators(value ? null : Validators.required);
      this.giftCardsFormGroup.controls.countryCode.updateValueAndValidity();
    }    
  }

  outletChange(e){
    if(e && e.value){
      this._shopService.SelectedOutletId = this.currentOutetId = e.value;
    }
  }

}

export const CountryCodeSeparator = "|";

export function ExtractPhoneNumber(phoneNumberWithCountryCode: string) {
  return phoneNumberWithCountryCode ? phoneNumberWithCountryCode?.split(CountryCodeSeparator)?.[1] : ""
}

export function ExtractCountryCode(phoneNumberWithCountryCode: string) {
  return phoneNumberWithCountryCode ? phoneNumberWithCountryCode?.split(CountryCodeSeparator)?.[0] : ""
}