<div class="h-100">
  <div class="reservation-guest">
    <div class="reservation-guest__search-field d-flex flex-column">
      <dynamic-form [hidden]="configGuestSearchBy[0]?.options?.length===1" [config]="configGuestSearchBy" #configGuestSearchByForm></dynamic-form>

      <!-- <div class="toggle-button platform-guest" [hidden]="searchType !== 1 || !hasCGPSearch">
        <label class="switch-control__switch-label guest-form-label">{{ 'PlatformGuestsearch' | translate}}</label>
        <label class="switch"  >
            <input class="switch-input" type="checkbox" (change)="platFormToggleChange()" [(ngModel)]="platformGuestSearchEnable"  />
            <span class="slider round"></span>
        </label>
    </div> -->
      <div class="guest-select__actions">
      <dynamic-form [config]="configGuestSearch" #configGuestSearchForm class="w-100"></dynamic-form>
        <div class="guest-select__guest-book" *ngIf="!autoSearchEnabled" (click)="searchGuest($event)">
          <i class="icon-search"></i>
      </div>
      </div>
    </div>
    <div class="reservation-guest__table">
      <ng-scrollbar>
        <div class="reservation-guest__table-view">
          <ul class="guest-list">
            <!-- SearchType 5 - VCart Guest -->
            <ng-container *ngIf="(searchType != 4 && !isStayConfirmationEnabled && !isPMSSearchEnabled && !isRoomNumberSearchEnabled) || searchType===5">
              <li *ngFor="let item of guestContacts" class="guest-card" (click)="getGuestDetails(item.Id,item)" draggable="true" (dragend)="getGuestDetails(item.Id,item)" >
                <div class="guest-card__name seat-grid-header">{{item.FirstName}} {{item.LastName}} <ng-container *ngIf="item?.AliasName">({{item.AliasName}})</ng-container></div>
                <div class="guest-card__phone" *ngIf="item.modifiedPhone">{{item.modifiedPhone}}</div>
                <div class="guest-card__email" *ngIf="item.modifiedEmail">{{item.modifiedEmail}}</div>
                <hr class="mt-2 mb-2" *ngIf="item.TrackMembershipNumber || item.MembershipType">
                <div class="guest-card__membership" *ngIf="item.TrackMembershipNumber">
                  {{_settings.General.MembershipNumberName}}: {{item.TrackMembershipNumber}}
                </div>
              </li>
            </ng-container>
            <ng-container *ngIf="searchType == 4 && memberShipArr.length">
              <li *ngFor="let item of memberShipArr" class="guest-card" (click)="getMemberDetails(item)" draggable="true" (dragend)="getMemberDetails(item)">
                <div class="guest-card__name seat-grid-header">{{item.FName}} {{item.LName}} <ng-container *ngIf="item?.AliasName">({{item.AliasName}})</ng-container></div>
                <div class="guest-card__phone" *ngIf="item.PhoneNumber">{{item.PhoneNumber}}</div>
                <div class="guest-card__email" *ngIf="item.Email">{{item.Email}}</div>
                <hr class="mt-2 mb-2" *ngIf="item.CardNo || item.MembershipType">
                <div class="guest-card__membership" *ngIf="_merchantSetting.ShowEngageMemberShipId.SettingValue == settingType.ENABLE">
                  <i class="icon-card"></i><span class="pl-1">{{item.CardNo ? item.CardNo :('NA' | translate)}}</span>
                </div>
                <div class="guest-card__membership" *ngIf="_merchantSetting.ShowEngageMemberShipType.SettingValue == settingType.ENABLE">
                  <i class="icon-member"></i><span class="pl-1">{{item.MembershipType? item.MembershipType :('NA' | translate)}}</span>
                </div>
              </li>
            </ng-container>
            <ng-container *ngIf="(isStayConfirmationEnabled || isPMSSearchEnabled || isRoomNumberSearchEnabled) && searchType!==5">
              <li *ngFor="let item of guestContacts" class="guest-card" (click)="getStayContact(item)" draggable="true" (dragend)="getStayContact(item)" >
                <div class="guest-card__name seat-grid-header">{{item.Title}} {{item.FirstName}} {{item.LastName}} <ng-container *ngIf="item?.AliasName">({{item.AliasName}})</ng-container></div>
                <div class="guest-card__phone" *ngIf="item.PhoneNumber">{{item.PhoneNumber}}</div>
                <div class="guest-card__email" *ngIf="item.EmailAddress">{{item.EmailAddress}}</div>
                
               
                  <app-search-based-guest-data [guestData]="item"></app-search-based-guest-data>

                    

              </li>
            </ng-container>
           
            <li *ngIf="!guestContacts?.length && !memberShipArr?.length"  class="guest-card" >
              {{'noDataFound' | translate}}
            </li>
          </ul>
        </div>
        <span *ngIf="searchType === 2 && showMore && guestContacts.length >= 10" (click)="showMoreContacts()"
          class="show-more">{{'ShowMore' | translate}} </span>
      </ng-scrollbar>

    </div>
  </div>
</div>