import { SnackBarType } from './../../shared/shared/enums/enums';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarInputs } from '../../Models/ag-models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ag-snack-bar',
  templateUrl: './ag-snack-bar.component.html',
  styleUrls: ['./ag-snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgSnackBarComponent implements OnInit {
  message: string;
  type: SnackBarType;
  snackBarIcon: string;
  minWidth: number;
  redirectURL: any;
  constructor(
    public snackBarRef: MatSnackBarRef<AgSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public snackBarInputs: SnackBarInputs, private router: Router) { }

  ngOnInit(): void {
    this.message = this.snackBarInputs.message;
    this.type = this.snackBarInputs.type;
    this.redirectURL = this.snackBarInputs.redirectURL;
    this.snackBarIcon = this.snackBarInputs.icon ? this.snackBarInputs.icon : '';
    this.minWidth = this.snackBarInputs.minWidth ? this.snackBarInputs.minWidth : 500;
  }

  redirectTo($event){
    if(this.redirectURL){
      this.router.navigate([this.redirectURL]);
    }
  }
}
