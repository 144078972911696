import { Pipe, PipeTransform } from '@angular/core';
import { PartyState } from '@constants/commonenums';
import { SettingsDTO } from '@models/RestaurantDTO';

@Pipe({
  name: 'backgroundColorFormatter'
})
export class BackgroundColorFormatterPipe implements PipeTransform {

  transform(partyState: PartyState, partyStatus: number, settings: SettingsDTO): any {
    if (partyState === PartyState.Seated) {
      const selectedStatuses = settings.Statuses.filter(x => x.Id === partyStatus);
      let statusColor;
      if (selectedStatuses.length > 0) {
        statusColor = selectedStatuses[0].Color;
      }
      if (statusColor) {
        return 'rgba(' + statusColor.R + ',' + statusColor.G + ',' + statusColor.B + ',' + statusColor.A + ')';
      }
    } else {
      switch (partyState) {
        case 0:
          return '#5093E1';
        case 2:
        case 4:
          return '#A1A6B4';
        case 3:
          return '#000';
        default:
          return '#5093E1';
      }
    }
  }

}
