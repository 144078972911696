import { BaseReport } from '../common/base-report';
import {
	ReportAPIOptions,
	ReportUIConfig,
	ReportParams,
	AllReports 
} from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';
import { ReportBusinessService } from '../report-business.service';
import { RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';

export class Shift extends BaseReport {
	startingCash: any = 0;
	shiftStartTime: any;
	shiftEndTime: any;
	functionalities: { [key: string]: boolean} = {};
	constructor(
		public reportFormGrp: UntypedFormGroup,
		public data: ReportDataService,
		public business: ReportBusinessService,
		public utils: RetailUtilities,
	    private func: RetailFunctionalityBusiness,
		private propertyInfo: RetailPropertyInformation,
		public retailLocalization : RetailLocalization
	) {
		super(reportFormGrp);
	}

	generateReportAPIOptions(): ReportAPIOptions {
		return {
			code: AllReports.Shift,
			params: this.formReportParams(),
			URIParams: this.formURIParams(),
			filters: this.formFilters(),
			pageBreak: true
		}; 
	}

	getReportUIConfig(): ReportUIConfig {
		return {
			startDatePicker: true,
			timePicker: true,
			endDatePicker: false,
			dropDownFilters: this.data.getAllOutletsByUser(),
			dropDownFilterName: this.localization.captions.reports.Outlets,
			inActiveToggle: false,
			pageBreakToggle: false,
			layout: 'PORTRAIT',
			allowFutureDate: false
		}; 
	}

	protected formReportParams(): ReportParams[] {
		this.functionalities = this.func.getFunctionality();
		let toApi = this.localization.convertDateObjToAPIdate;
        let startTime = this.localization.TimeToDateAllformat(this.startTime);
        let endTime = this.localization.TimeToDateAllformat(this.endTime);
		let timeDuration = this.startTime.toUpperCase() + ' - ' + this.endTime.toUpperCase();
		let time1 = this.startTime;
		let time2 = this.endTime;
        this.shiftStartTime = this.localization.AddTimeToDate(this.startDate, startTime);
        this.shiftEndTime = this.localization.AddTimeToDate(this.endDate, endTime);       
		let shiffReportFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
		let userCaption: string = this.business.getFilterCaption(
			this.business.FilterDataSource['allUsers'],
			this.fillFilter(shiffReportFilterData.users.filter((u) => u.showInDropDown))
		);
		this.startingCash = this.business.startingCash;
		let machineCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["allMachineNames"],shiffReportFilterData.machineName.map(x=>x.id));
		return [
			{ pStartDate: toApi(this.startDate) },
			{ pEndDate: toApi(this.endDate) },
			{ pPageBreak: this.pageBreakToggle },
			{ pPropertyName: this.propertyName },
			{ pUsers: userCaption },
			{ pOutlets: this.functionalities.ShowOutletSelectionFieldInShiftReport ? this.getFilterCaption  : this.getDefaultOutletCaption},
			{ pDate: this.printedDate },
			{ pStartingCash: this.startingCash },
			{ pTimeDuration: timeDuration },
			{pPaymentMethod:this.localization.captions.reports.RetailInterfacePayment} ,
			{ pTime1 : time1 },
			{ pTime2 :time2},
			{pMachineName: machineCaption},
			{pPrintMachineName: this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false},
			{pIncludeInactiveUsers: shiffReportFilterData.includeInactiveusers},
			{pSplitByUsers:shiffReportFilterData.toggleSplitByUsers}
		];
	}

	protected formURIParams(): ReportParams[] {
		return [ { StartDate: this.localization.convertDateTimeToAPIDateTime(this.shiftStartTime) },
			 { EndDate: this.localization.convertDateTimeToAPIDateTime(this.shiftEndTime) } ];
	}

	protected formFilters() {
		let toApi = this.localization.convertDateObjToAPIdate;
		const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
		let shiffReportFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
		let showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;
        let isAllMachineSelected: boolean = this.business.checkAllSelected(this.business.FilterDataSource["allMachineNames"],shiffReportFilterData.machineName.map(x=>x.id));      
		const tempOutletId = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
		const tempMachineId = isAllMachineSelected ? [] : shiffReportFilterData.machineName.map(x => x.id);
		if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return {
            StartDate: this.localization.convertDateTimeToAPIDateTime(this.shiftStartTime),
			EndDate: this.localization.convertDateTimeToAPIDateTime(this.shiftEndTime),
			startTime : this.startTime,
			endTime : this.endTime,
			OutletIds: this.functionalities.ShowOutletSelectionFieldInShiftReport ? this.fillFilter(this.selectedFilterOptions) :
			tempOutletId,
			UserIds: this.fillFilter(shiffReportFilterData.users.filter((u) => u.showInDropDown)),
			MachineNameId: showMachineDropDown ? tempMachineId : [],
			startingCash : this.startingCash,
			splitByUsers: shiffReportFilterData.toggleSplitByUsers,
            fromWildCard,
            toWildCard,
            fromIncrement,
            toIncrement 

            };
        }
		return {
			StartDate: this.localization.convertDateTimeToAPIDateTime(this.shiftStartTime),
			EndDate: this.localization.convertDateTimeToAPIDateTime(this.shiftEndTime),
			OutletIds: this.functionalities.ShowOutletSelectionFieldInShiftReport ? this.fillFilter(this.selectedFilterOptions) :
			tempOutletId,
			UserIds: this.fillFilter(shiffReportFilterData.users.filter((u) => u.showInDropDown)),
			MachineNameId: showMachineDropDown ? tempMachineId : [],
			splitByUsers: shiffReportFilterData.toggleSplitByUsers
		};
	}
	fillFilter(customFilterData: any): number[] {
		return customFilterData.length === 0 ? [] : customFilterData.map((x) => x.id);
	}
}
