<div class="secondary-guest-details">
  <mat-accordion>
    <mat-expansion-panel class="secondary-guest-details__guest-panel"
      [expanded]="this.secondaryContactDetails.isNew">
      <mat-expansion-panel-header class="secondary-guest-details__guest-panel-header">
        <div class="secondary-guest-details__guest-panel-details">
          <div>
            <label style="text-wrap: nowrap;">{{'secondaryGuest' | translate}}
              <span style="position: relative;display:inline-block"
                class="secondary-guest-details__panel-guest-count seat-notification green-color rs-mr-10 seat-labels">{{guestCount}}</span>
            </label>
          </div>
          <div class="seat-icon-color">
            <span *ngIf="secondaryContactDetails?.FirstName">( {{secondaryContactDetails?.FirstName}}</span>
           <span *ngIf="secondaryContactDetails?.LastName"> {{secondaryContactDetails?.LastName}}</span>
           <span *ngIf="secondaryContactDetails?.PhoneNumber"> | {{secondaryContactDetails?.PhoneNumber}}</span>
           <span *ngIf="secondaryContactDetails?.EmailAddress"> | {{secondaryContactDetails?.EmailAddress}} )</span>
           
          </div>
          <div>
            <i class="icon-ic_delete_24px rs-pr-20" (click)="deleteSecondaryGuestFromContact()"></i>
          </div>
        </div>
      </mat-expansion-panel-header>
      <div class="secondary-guest-details__guest-config">
        <div *ngIf="secondaryContactDetails.isNew" class="secondary-guest-details__search-details">
          <div class="seat-grid-header mb-2">{{'SearchGuest' | translate}}</div>
          <div class="secondary-guest-details__select-option-field">
            <guest-select
              [membershipAvailable]="isMembershipAvailable"
              [roomNoSearch]="secondaryGuestSearchType.RoomNo === selectedSearchType" [_settings]="_settings"
              (onGuestSelect)="guestSelect($event)"></guest-select>
            <profile-select *ngIf="secondaryGuestSearchType.CommonProfile === selectedSearchType"
              (onProfileSelect)="guestSelect($event)"></profile-select>
          </div>
        </div>
        <div class="secondary-guest-details__guest-data">
        <div class="seat-grid-header mb-2">{{'guestDetailsText' | translate }}</div>
        <dynamic-form [config]="secondaryGuestConfig" #form="dynamicForm"></dynamic-form>
        <div class="secondary-guest-details__member-status {{secondaryMemberStatus}}">{{secondaryMemberStatus}}</div>
        <dynamic-form [config]="guestConfig" #customGuestField></dynamic-form>
      
      </div></div>
    </mat-expansion-panel>
  </mat-accordion>
</div>