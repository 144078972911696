import { Component, OnInit, inject } from '@angular/core';
// import { ReservePOSSessionService } from '../../services/respos-session.service';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatBadgeModule} from '@angular/material/badge';
import { CartService } from '@app/pos/services/cart.service';
import { CacheService } from '@app/core/services/cache.service';
import { LoadMultiplePropertiesService } from '@app/shared/services/load-multiple-properties.service';
import { AppService } from '@app/app.service';
import { KeyboardService } from '@app/pos/keyboard/keyboard.service';
import { ReservePOSSessionService, UserPreferences } from '@app/pos/services/respos-session.service';
// import { CartService } from '../../services/cart.service';

@Component({
  selector: '[respos-layout-header]',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss']
})
export class LayoutHeaderComponent implements OnInit {

  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  // sessionService = inject(ReservePOSSessionService);
  cartService = inject(CartService);
  cs = inject(CacheService);
  ls = inject(LoadMultiplePropertiesService);
  as = inject(AppService);
  public keyboardService = inject(KeyboardService);
  posSessionService = inject(ReservePOSSessionService);

  userName: string | undefined = '';

  ngOnInit(): void {
    this.keyboardService.enableKeyboard = this.keyboardService.enableKeyboard || this.posSessionService.UserPreference.enableVirtualKeyboard === 'yes';
    // if(this.sessionService.hostInfo){
    //   this.userName = this.sessionService.hostInfo.HostRole.Name;
    // }
    if(!this.cs.LoadMultiplePropertiesServiceInit){this.ls.init()};

  }

  goToHome(){
    this.router.navigate(['pos/home'], {relativeTo: this.activatedRoute})
  }

  goTocart(){
    this.router.navigate(['pos/cart'], {relativeTo: this.activatedRoute})
  }

  goToListing(){
    this.router.navigate(['pos/listing'], {relativeTo: this.activatedRoute})
  }

  exitPOS(){
    this.as.posView = false;
    sessionStorage.setItem('posView', '')
    this.router.navigate(['activities-timeline']);
  }

  enableDisableKeyboard(){
    this.keyboardService.enableKeyboard = !this.keyboardService.enableKeyboard;
    this.posSessionService.updateUserPreference(UserPreferences.EnableVirtualKeyboard, this.keyboardService.enableKeyboard ? 'yes' : 'no');
  }

  logout() {
    if(this.as.showCartItem){
      this.as.showCartItem = false;
    }
    this.cs.locationListForMerchant = [];
    this.cs.LoadMultiplePropertiesServiceInit = false;
    sessionStorage.removeItem('section');
    this.as.logout();
  }
}
