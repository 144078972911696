<form class="gc-pin-popup">
    <div>
        <mat-form-field [floatLabel]="floatLabel">
            <input matInput [(ngModel)]="PinNumber" #pinNumber autofocus name="PinNumber" [placeholder]="captions.PinFieldPlaceholder" inputtype="onlynumber" maxlength="4">
        </mat-form-field>        
    </div>
    <div class="popup-footer">
        <button [ngClass]="PinNumber?.length == STD_PIN_LENGTH ? 'button--primary-save' : 'button--disabled'"
            [disabled]="PinNumber?.length < STD_PIN_LENGTH"
            mat-button class="mat-raised-button whitecolor LW14 ml-3 text-capital"
            (click)="closeDialog()">{{captions.Submit}}</button>
    </div>
</form>