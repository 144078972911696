<section class="transaction-report" [formGroup]="TransactionFormGrp" autocomplete="off">
        <app-retail-multi-select-dropdown *ngIf="users" [dropDownFilterData] = "users" [dropDownControlname] = "'users'" [dropDownName] = "captions.Users" (dropDownFrmControl) = "getFormControlValue($event)" (IsAnySelected)="IsSelectionValid($event)"></app-retail-multi-select-dropdown>
        <div *ngIf="showMachineDropDown">
          <app-retail-multi-select-dropdown *ngIf="machineName" [dropDownFilterData] = "machineName" [dropDownControlname] = "'machineName'" [dropDownName] = captions.Machines (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
          </div>
        <mat-error class="user-select" *ngIf="!validSelection">
            {{validSelectionErrMsg}}
         </mat-error>
      <div class="mb-3">
        <label class="LW14">{{inActiveText}}</label>
        <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleIncludeInactive($event[0])" [formControl]="TransactionFormGrp.controls['includeInactiveusers']"></app-retail-toggle-switch>

      </div>
</section>
