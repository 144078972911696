<section class="retail-vendor newVersionWrapper retail-vendor-setup-wrapper h-100 d-flex">
    <div class="vendor-filter">
        <app-aside-filter-component [automationId] = "'retailVendorSetup'" [Categories]="vendorTypecategories" (filterEmitter)="CategoryFilter($event)"></app-aside-filter-component>
    </div>
    <div class="CustomTableGrid h-100" id="customVendorContainer">
        <button [attr.automationId]="'Btn_retailVendorSetup_addVendor'" [ngClass]="IsViewOnly ? 'button_invalidbgcolor button--disabled' : 'body-bgcolor whitecolor'"
            class="float-right mr-2 createNewBtn text-capital" mat-raised-button (click)="addVendor($event)"
            [disabled]="IsViewOnly">{{captions.lbl_addVendor}}</button>
        <app-retail-table [attr.automationId]="'Tbl_retailVendorSetup_retailVendorSetup'" [options]="tableoptions" (dragDropEvt)="dragDrop($event)"    (LBLClickEvtEmitter)="onLabelClick($event)"
            (doneEvtEmitter)="Done($event)" (cancelEmitter)="Cancel($event)" (deleteEvt)="DeleteRecords($event)"
            (editEvt)="editVendor($event)" [isOnEditDisableFunctionAvailable]="onEditDisableFunction" [isEditModeSetFromParent]="isEditFlag">
        </app-retail-table>

    </div>
</section>
