import { Injectable } from "@angular/core";
import { ReportDownloadFormat, ReportAPIOptions } from "../../../retail.modals";
import * as saveAs from 'file-saver'
import { FastReportBusinessService } from '../../../retail-reports/fast-report/fastreport.business.service';
import { PrintDocumentInput } from "src/app/common/Models/common.models";
import { PrintInformationType } from "src/app/common/Models/printer-default-configuration.model";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { PaymentMethodBusiness } from "src/app/retail/retail-payment-method/payment-methods.business";
import { SubPropertyDataService } from 'src/app/retail/retail-code-setup/retail-outlets/subproperty-data.service';
import { ReportAPIModel } from "src/app/retail/retail-reports/business/report.modals";
import { RetailCommunication } from "src/app/retail/retailcommunication.service";
import { RetailRoutes } from "src/app/retail/retail-route";
import { DMReceiptPosting } from 'src/app/common/data-magine/data-magine-integration/data-magine-models';

export const saveFile = (blobContent: Blob, fileName: string) => {
    const blob = new Blob([blobContent], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
};
@Injectable()
export class ReceiptService {
    constructor(private _fastReportBusinessService: FastReportBusinessService, private Utilites: RetailUtilities, 
        private subPropService: SubPropertyDataService, private _paymentBusiness: PaymentMethodBusiness
        , private retailCommunication: RetailCommunication
        ) {

    }

    public printReceipt(format: ReportDownloadFormat, options: ReportAPIOptions, noOfReceipts?: number, callBack?: any,sigcapIndexingDetails:DMReceiptPosting=null): void {
        let receiptPrinter: PrintDocumentInput = this.Utilites.getPrinterConfigurationToPrint(PrintInformationType.Recepit);
        if (receiptPrinter && receiptPrinter.noOfCopies) receiptPrinter.noOfCopies = noOfReceipts ? noOfReceipts : this._paymentBusiness.defaultNoOfReceipt;
        this._fastReportBusinessService.downloadReport(format, options, true, callBack, null, receiptPrinter,sigcapIndexingDetails);
    }

    public printReceiptSigcap(format: ReportDownloadFormat, options: ReportAPIOptions, noOfReceipts?: number, callBack?: any,sigcapIndexingDetails?:DMReceiptPosting): void {
        let receiptPrinter: PrintDocumentInput = this.Utilites.getPrinterConfigurationToPrint(PrintInformationType.Recepit);
        if (receiptPrinter && receiptPrinter.noOfCopies) receiptPrinter.noOfCopies = noOfReceipts ? noOfReceipts : this._paymentBusiness.defaultNoOfReceipt;
        this._fastReportBusinessService.downloadReport(format, options, true, callBack, null, receiptPrinter,sigcapIndexingDetails);
    }
    

     async SaveFolioReceipt(reportData  : ReportAPIModel): Promise<boolean> {
        var result: any = await this.subPropService.SaveFoiloReceipt(reportData);
       return result
    }
    async getReceiptAsString(reportData  : any, isFromPreSettlement: boolean = false): Promise<string> {
        reportData.code = isFromPreSettlement ? reportData.code :  "CheckZoom" ;
        reportData.format = "TEXT"; 
        let body = this._fastReportBusinessService.createAPIOptions(reportData);
        var result: any = await this.retailCommunication.putPromise<string>({
            body: body,
            route: RetailRoutes.ReportString
        });
       return result
    }


}
