import { PipeTransform, Pipe } from "@angular/core";
import { Localization } from "../../../localization/localization";
import countrylist from '../../../components/ag-phone-number/country-code.json';
@Pipe({
    name: 'formatText',
    pure: true
})
export class FormatText implements PipeTransform {
    constructor(public localization: Localization){
    }
    transform(value: any, format:string) {
        if (!value || value == "") {
            return "";
        }
       const PhoneFormat = this.getPhoneFormat();
        if (format == PhoneFormat) {
            let userInput = value.toString();
            let formattedNumber;
            formattedNumber = this.formatPhoneNumber(format, userInput);
            return formattedNumber;
        }
        else {            
            let userInput = value.toString().split(':');
            let countryCodeSplit = userInput.length > 1 ? userInput[1].toString().split('|'):  userInput[0].toString().split('|')
            let phoneNumber = countryCodeSplit.length>1 ? countryCodeSplit[1]: countryCodeSplit[0];
            let formattedNumber = countryCodeSplit.length>1 ? `+(${countryCodeSplit[0]}) ` : '';
            let isDialAvailable = countrylist.find(x=>x.dialCode === countryCodeSplit[0]);
            if(isDialAvailable){
                formattedNumber = formattedNumber.concat(this.formatPhoneNumber(isDialAvailable.placeHolder,phoneNumber));
            } else {
                formattedNumber = formattedNumber.concat(this.formatPhoneNumber(PhoneFormat, phoneNumber));
            }
        
            if(userInput.length>1){
            formattedNumber = formattedNumber.concat(this.localization.captions.common.Extension);
            formattedNumber = formattedNumber.concat(userInput[0]);}
            return formattedNumber;
        }
    }

    private getPhoneFormat(): string {
        const UseUSPhoneFormat = sessionStorage.getItem("UseUSPhoneFormat");
        let PhoneFormat = this.localization.captions.common.PhoneFormat;
        if(UseUSPhoneFormat && UseUSPhoneFormat=="true"){
            PhoneFormat = "(999) 999-999999";
        }
        return PhoneFormat;
    }

    formatPhoneNumber(format, userInput) {
        let returnVal: string = "";
        let indexOfUserValue: number = 0;
        for (let data of format) {
            const char = data;
            let charCode = char.toString().charCodeAt(0);
            const IsNumber: boolean = ((charCode >= 48) && (charCode <= 57));
            if (!IsNumber) {
                returnVal = returnVal + data;
            } else {
                if (userInput[indexOfUserValue]) {
                  returnVal = returnVal + userInput[indexOfUserValue];
                  if (userInput[indexOfUserValue + 1]) {
                    indexOfUserValue++;
                  } else {
                    break;
                  }
                } else {
                    break;
                }
            }
        }
        return returnVal;
    }
}
