import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'app-cart-comments',
  templateUrl: './cart-comments.component.html',
  styleUrls: ['./cart-comments.component.scss']
})
export class CartCommentsComponent implements OnInit {

  buttonObj: { customSaveText: string; disabled: boolean; isEdit: boolean; };
  cartComments: string = '';
  title: string = '';
  captions: any;
  floatLabel: string;
  comments:string;

  constructor(public dialog: MatDialog,
    private localization: Localization,
    private dialogRef: MatDialogRef<CartCommentsComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) { 
      this.title = data?.title;
      this.comments = data?.existingComment
    }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.buttonObj = {
      customSaveText: (this.comments && this.comments.length > 0) ? this.captions.btn_update : this.captions.btn_save,
      disabled: true,
      isEdit: false
    };
  }

  onCommentsTyped(event) {
    if (event && event.length > 0) {
      this.buttonObj.disabled = false;
    } else {
      this.buttonObj.disabled = true;
    }
    this.cartComments = event;
    this.buttonObj = { ...this.buttonObj };
  }

  save(event){
    this.dialogRef.close(this.cartComments);
  }

  close() {
    this.dialogRef.close(false)
  }

}
