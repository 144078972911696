import { Component, Input, OnInit } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: '[category-indicator]',
  templateUrl: './category-indicator.component.html',
  styleUrls: ['./category-indicator.component.scss'],
})
export class CategoryIndicatorComponent implements OnInit{

  @Input('category-indicator') categoryId;
  @Input('indicatorType')  indicatorType?;
  categoryName = '';

  constructor(public cs:CacheService, private ts: TranslateService){
    
  }

  ngOnInit(): void {
    if(this.categoryId){
      this.categoryName = this.cs.categoryData[this.categoryId]?.name || '';
    }else{
      this.categoryName = this.ts.instant('OpenBooking');
    }
  }
}
