import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { environment } from '@environments/environment';

@Injectable(//{
  //providedIn: 'root'
//}
)
export class AuthService {


  private manager: UserManager = new UserManager(getClientSettings());
  user: User = null;

  constructor() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    if (!this.user) {
      this.manager.getUser().then(user => {
        this.user = user;
        sessionStorage.setItem('user', JSON.stringify(user));
      });
    }
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  logout(): void {
    this.manager.signoutRedirect();
    localStorage.clear();
  }

  getClaims(): any {
    return this.user.profile;
  }

  getAuthorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
  }
  getAccessToken(): string {
    return `${this.user.access_token}`;
  }
  startAuthentication(): Promise<void> {
    return this.manager.signinRedirect();
  }

  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then(user => {
      this.user = user;
      sessionStorage.setItem('user', JSON.stringify(user));
    });
  }

  getClientSettings(): UserManagerSettings {
    return {
      authority: environment.authority,
      client_id: 'SeatAngularClient',
      redirect_uri: environment.redirect_uri,
      post_logout_redirect_uri: environment.post_logout_redirect_uri,
      response_type: "id_token token",
      scope: "openid profile rGuest.Seat.API.GatewayProxy",
      filterProtocolClaims: true,
      loadUserInfo: true,
      automaticSilentRenew: true,
      silent_redirect_uri: environment.silent_redirect_uri
    };
  }

}

export function getClientSettings(): UserManagerSettings {
  return {
    authority: environment.authority,
    client_id: 'SeatAngularClient',
    redirect_uri: environment.redirect_uri,
    post_logout_redirect_uri: environment.post_logout_redirect_uri,
    response_type: "id_token token",
    scope: "openid profile rGuest.Seat.API.GatewayProxy",
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true,
    silent_redirect_uri: environment.silent_redirect_uri
  };
}
