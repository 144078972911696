import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import urlConfig from '../const/url-config';
import { IAddToCartRequest, IContactShort, IGetActivityAvailability, IGetSlotRequest, ILockSessionRequest, IPackageGetRequest, IRatePalnRequest, IUnlockCart } from '../interfaces/request';
import { GetContactOptions } from '@app/shared/models/InputContact';
import { ReservationSource } from '../const/global.const';

@Injectable({
  providedIn: 'root'
})
export class POSApiService {

  environment: string = environment.BaseUrl;

  http: HttpClient = inject(HttpClient);

  cs: CacheService = inject(CacheService);

  constructor() { }

  getCommonContact(searchText: string): Observable<any> {
    return this.http.get(`${environment.BaseUrl}/${urlConfig.getCommonContact}?restaurantId=${Utilities.RestaurantId()}&searchString=${searchText}&isRoomNumberSearch=false&pageNumber=0&pageSize=10&directCGPS=false`);
  }

  getPackages(startDate: string, endDate: string): Observable<any> {
    let request: IPackageGetRequest = { TimeRange: { Start: startDate, End: endDate } }
    return this.http.post(`${environment.BaseUrl}/${urlConfig.getPackages}?restaurantId=${Utilities.RestaurantId()}`, request);
  }

  getAvailabilityForActivity(request: IGetActivityAvailability): Observable<any> {
    // const headers = new HttpHeaders().set(InterceptorSkipLoaderHeader, '');
    return this.http.post(`${environment.BaseUrl}/${urlConfig.getAvailability}?restaurantId=${Utilities.RestaurantId()}`, request);
  }

  getActivityDetail(activityId: number): Observable<any> {
    // const headers = new HttpHeaders().set(InterceptorSkipLoaderHeader, '');
    return this.http.get(`${environment.BaseUrl}/${urlConfig.getActivityDetail}?restaurantId=${Utilities.RestaurantId()}&id=${activityId}`);
  }

  getSlotsByLocation(request: IGetSlotRequest): Observable<any> {
    // const headers = new HttpHeaders().set(InterceptorSkipLoaderHeader, '');
    return this.http.post(`${environment.BaseUrl}/${urlConfig.getSlotsByLocation}?restaurantId=${Utilities.RestaurantId()}`, request);
  }

  getActivities(startDate: string, endDate: string): Observable<any> {
    return this.http.get(`${environment.BaseUrl}/${urlConfig.getActivities}?restaurantId=${Utilities.RestaurantId()}`).pipe(map(({ Payload: { SpecialMealDTO: activities } }: any) => {
      return activities.filter((activity: any) => {
        return activity.StatusCode === 10
          && ((new Date(startDate).getTime() >= new Date(activity.StartDate).getTime()
            && new Date(startDate).getTime() <= new Date(activity.EndDate).getTime())
            || (new Date(endDate).getTime() >= new Date(activity.StartDate).getTime()
              && new Date(endDate).getTime() <= new Date(activity.EndDate).getTime()));
      })
    }));
  }

  lockSessions(lockSessionRequest: ILockSessionRequest): Observable<any> {
    return this.http.post(`${environment.BaseUrl}/${urlConfig.lockSessions}?restaurantId=${Utilities.RestaurantId()}`, lockSessionRequest);
  }

  unlockSessions(lockIds: number[]) {
    return this.http.post(`${environment.BaseUrl}/${urlConfig.unlockMultiple}?restaurantId=${Utilities.RestaurantId()}`, lockIds)
  }

  addToCart(addToCartRequest: IAddToCartRequest): Observable<any> {
    return this.http.post(`${environment.BaseUrl}/${urlConfig.addToCart}?propertyid=${Utilities.RestaurantId()}&hostid=${Utilities.getHostId()}&merchantid=${this.cs.settings.value.General.MerchantId}&fromExternalSystem=${this.cs.isIframeEnabled}`, addToCartRequest).pipe(map((response: any) => {
      if (response.ExceptionMessage) {
        throw Error('Something went wrong!')
      }
      return response;
    }));
  }
  updateToCart(addToCartRequest: IAddToCartRequest): Observable<any> {
    return this.http.post(`${environment.BaseUrl}/${urlConfig.updateToCart}?propertyid=${Utilities.RestaurantId()}&hostid=${Utilities.getHostId()}&merchantid=${this.cs.settings.value.General.MerchantId}&fromExternalSystem=${this.cs.isIframeEnabled}`, addToCartRequest).pipe(map((response: any) => {
      if (response.ExceptionMessage) {
        throw Error('Something went wrong!')
      }
      return response;
    }));
  }

  unlockCart(request: IUnlockCart[]): Observable<any> {
    return this.http.post(`${environment.BaseUrl}/${urlConfig.unlockCart}`, request);
  }
  getRatePlan(request: IRatePalnRequest) {
    return this.http.post(`${environment.BaseUrl}/${urlConfig.getRatePlan}?restaurantId=${Utilities.RestaurantId()}&hostid=${Utilities.getHostId()}`, request);
  }

  getCartItems(): Observable<any> {
    return this.http.get(`${environment.BaseUrl}/${urlConfig.getCartItems}?hostid=${Utilities.getHostId()}`).pipe(map(({ Payload: cartItems }: any) => cartItems.filter(({ CartItemDetail: { PartySourceId } }) => PartySourceId === ReservationSource.POS)));
  }

  removeFromCart(cartIds: number[]) {
    return this.http.post(`${environment.BaseUrl}/${urlConfig.removeFromCart}?restaurantId=${Utilities.RestaurantId()}`, cartIds);
  }

  extendLock(lockIds: number[]) {
    return this.http.post(`${environment.BaseUrl}/${urlConfig.extendLock}?restaurantId=${Utilities.RestaurantId()}`, lockIds);
  }

  createContact(contact: IContactShort) {
    return this.http.post(`${environment.BaseUrl}/${urlConfig.createContact}?restaurantId=${Utilities.RestaurantId()}`, contact);
  }

  getContact(contactId: number, contactOptions: GetContactOptions) {
    return this.http.get(`${environment.BaseUrl}/${urlConfig.getContact}?restaurantId=${Utilities.RestaurantId()}&contactId=${contactId}&options=${contactOptions}`);
  }

}

