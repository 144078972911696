import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from '@app/app.service';
import { controlSettings } from '@app/shared/constants/globalConstants';
import { Utilities } from '@app/shared/utilities/utilities';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { CacheService } from '@core/services/cache.service';
import { StandByPartyDTO } from '@models/InputReservation';
import { PageMethod, SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { DashboardFunctions } from '@utilities/dashboard-functions';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';

export interface GroupDate {
  wishedGroupTime: Date;
  parties: number;
  covers: number;
}

@Component({
  selector: 'app-standby-list',
  templateUrl: './standby-list.component.html',
  styleUrls: ['./standby-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class StandbyListComponent implements OnInit, OnDestroy {
  standbyParties: StandByPartyDTO[];
  tableDataSource: MatTableDataSource<StandByPartyDTO | GroupDate>;
  tableColumns: string[] = ['PartyName', 'PartySize', 'SeatingArea', 'PagePreference', 'CreatedDate', 'Time', 'Action'];
  headerColumns: string[] = ['timeHeader', 'covers'];
  _settings: SettingsDTO = {} as SettingsDTO;
  subscriptions: Subscription = new Subscription();
  track: GroupDate[] = [];
  groupedStandbyList: (StandByPartyDTO | GroupDate)[] = [];
  confirmSubscription: Subscription;
  partySubscription: Subscription;
  partyName: string;
  partySize: string;
  seatingArea: string;
  pagePreference: string;
  createdDateText: string;
  timeText: string;
  actionText: string;
  partyText: string;
  coversText: string;
  PageMethod = PageMethod;
  noStanbyPartyUrl: string = urlConfig.noReservationsUrl;
  disableStandbyDelete: boolean;

  constructor(public _as: AppService, public _ps: PartyService, public cs: CacheService, private dialog: MatDialog,
    public dashboardFunctions: DashboardFunctions, public popupService: PopupService, private translateService: TranslateService) {
    this.subscriptions.add(cs.settings.subscribe(settings => {
      this._settings = settings;
    }));
  }

  isGroup(index, item): boolean {
    return item.wishedGroupTime;
  }
  ngOnInit() {
    this.loadStandbyParties();
    this.disableStandbyDelete = Utilities.controlValidate(controlSettings.Location_StandbyList_Hide, this._as.PropertyType);
  }

  loadStandbyParties() {
    this.standbyParties = cloneDeep(this._ps.StandbyParties$.value);
    this.partySubscription = this._ps.StandbyParties$.subscribe((values) => {
      this.standbyParties = [];
      this.standbyParties = cloneDeep(values);
      this.groupStandbyList();
    });
  }

  groupStandbyList() {
    const standbyList: (StandByPartyDTO | GroupDate)[] = [];
    const trackDate: Date[] = [];
    this.countPartiesAndCovers();
    this.standbyParties.sort((a, b) => a.WishedTime.toLocaleString().localeCompare(b.WishedTime.toLocaleString()));
    this.standbyParties.forEach((party: StandByPartyDTO) => {
      if (trackDate.indexOf(party.WishedTime) == -1) {
        trackDate.push(party.WishedTime);
        const trackData = this.track.filter(x => x.wishedGroupTime == party.WishedTime);
        if (trackData && trackData.length > 0) {
          const partyCount = trackData[0].parties;
          const coverCount = trackData[0].covers;
          standbyList.push({ wishedGroupTime: party.WishedTime, parties: partyCount, covers: coverCount });
        }
      }
      standbyList.push(party);
    });
    this.groupedStandbyList = standbyList;
    this.tableDataSource = new MatTableDataSource(standbyList);
  }

  countPartiesAndCovers() {
    this.track = [];
    this.standbyParties.forEach((party: StandByPartyDTO) => {
      if (this.track.filter(x => x.wishedGroupTime == party.WishedTime).length == 0) {
        this.track.push({ wishedGroupTime: party.WishedTime, parties: 1, covers: party.Size });
      }
      else {
        this.track.filter(x => x.wishedGroupTime == party.WishedTime)[0].parties += 1;
        this.track.filter(x => x.wishedGroupTime == party.WishedTime)[0].covers += party.Size;
      }
    });
  }
  getSeatingArea(id: number) {
    const seatingArea = this._settings.SeatingAreas.filter(x => x.Id === id);
    if (seatingArea.length > 0) {
      return seatingArea[0].Name;
    }
    return this.translateService.instant('seatDetailAnyWhereText');
  }

  onSortData(sort: Sort) {
    this.groupedStandbyList = [];
    this.track.sort((a, b) => a.wishedGroupTime.toLocaleString().localeCompare(b.wishedGroupTime.toLocaleString()));
    this.track.forEach((time: GroupDate) => {
      let data = this.standbyParties.filter(x => x.WishedTime == time.wishedGroupTime);
      if (sort.active && sort.direction !== '') {
        data = data.sort((p1: StandByPartyDTO, p2: StandByPartyDTO) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
            case 'PartyName':
              return this.compare(p1.Contact.FirstName.toLowerCase(), p2.Contact.FirstName.toLowerCase(), isAsc);
            case 'PartySize':
              return this.compare(p1.Size, p2.Size, isAsc);
            case 'SeatingArea':
              return this.compare(this.getSeatingArea(p1.SeatingAreaId).toLowerCase(), this.getSeatingArea(p2.SeatingAreaId).toLowerCase(), isAsc);
            case 'CreatedDate':
              return this.compare(p1.CreatedAt, p2.CreatedAt, isAsc);
            default: return 0;
          }
        });
        this.groupedStandbyList.push(time);
        this.groupedStandbyList.push(...data);
      }
    });
    this.tableDataSource = new MatTableDataSource(this.groupedStandbyList);
  }

  private compare(server1, server2, isAsc) {
    return (server1 < server2 ? -1 : 1) * (isAsc ? 1 : -1);
  }

  editStandbyParty(standbyParty: any) {
    this.dashboardFunctions.createOrEditStandbyParty(true, standbyParty);
  }

  removeStandbyParty(standbyParty: any) {
    if(!this.disableStandbyDelete){
    if (this._as.OTASourceId.includes(standbyParty.PartySourceId)) {
      this.dashboardFunctions.showOTAAlert(standbyParty);
      return;
    }
    const noText: string = this.translateService.instant('notext');
    const yesText: string = this.translateService.instant('yestext');
    const popUpMessage = [{
      confirmationMessage: `${this.translateService.instant('removeStandbyConfText')}`,
      dialogTitle: `${this.translateService.instant('removeStandbyPartyTitle')}`,
      showAlert: true
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '350px',
      data: {
        title: popUpMessage[0].dialogTitle,
        update: yesText,
        cancel: noText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    this.confirmSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      this.subscriptions.add(this._ps.removeStandbyParty(standbyParty.Id).subscribe(data => {
      }));
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(() => {
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
    }));
  }
  }

  ngOnDestroy() {
    if (this.partySubscription) {
      this.partySubscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
