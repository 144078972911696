import { Component, OnInit, Input, Output, EventEmitter, PipeTransform, Pipe, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { RetailUtilities } from '../utilities/retail-utilities';
import { MoreSectionServiceService } from '../more-section/more-section-service.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-aside-filter-component',
  templateUrl: './aside-filter.component.html',
  styleUrls: ['./aside-filter.component.scss']
})
export class AsideFilterSectionComponent implements OnInit, OnChanges, OnDestroy {
  public minFromDate: any;
  public mintoDate: any; 

  @Input() Categories: any = []
  @Input() dateSelection: boolean;
  @Input() todateSelection: boolean;
  @Input() dateDefaultValue: Date;
  @Input() todateDefaultValue: Date;
  @Input() dateMaxValue: Date;
  @Input() todateMaxValue: Date;
  @Input() buttonTextColumn: string;
  @Input() automationId: string='';

  @Output() filterEmitter: EventEmitter<any> = new EventEmitter();
  @Output() resetEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() dateChangedEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild('asidefilterscroll') scrollbarRef: NgScrollbar;
  @ViewChild('dateModelShop') dateModelShopRef: NgModel;
  @ViewChild('dateToModel') dateToModelRef: NgModel;

  applyClass: any;
  subscription: Subscription;
  OriginalData: any;
  filteredDataLength: number = 0;
  filterOptions: { 'ShowMoreFilters': boolean; 'moreData': any; 'selectedMoreData': any; 'top': number; 'left': number; 'belongto': any; 'fromPage': string; 'categoryType': any; 'position':any};
  dateChanged: boolean = false;
  radioChanged:boolean = false;
  placeholderFormat: string;
  commonCaptions: any;
  floatLabel: string;
  constructor(public localization: RetailLocalization, private dialog: MatDialog, public utils: RetailUtilities, public _MoreSectionServiceService: MoreSectionServiceService) {
    this.placeholderFormat = this.localization.inputDateFormat;
    this.commonCaptions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    console.log(this.Categories);
  }

  ngOnChanges() {
    if (!this.buttonTextColumn) {
      this.buttonTextColumn = 'name';
    }
    this.calculateFilteredDataLength();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onDateChange(event: any, f?: string): void {
    if(this.dateModelShopRef.value && this.dateModelShopRef.valid){
      this.dateChanged = true;
      this.dateChangedEmitter.emit(event);
    }
  }

  // ---for logviewer--
  ontoDateChange(event: any, f?: string): void {
    if(this.dateToModelRef.valid){
      this.dateChanged = true;
      let data = {
        todateval:event,
        type: f
      }
      this.dateChangedEmitter.emit(data);
    }
  }

  onRadioChange(e,group)
  {
    this.radioChanged=true;
    group.filters.forEach(f => {
      f.isSelected = (f.id === e.value.id);
    });
    group.filtered = e.value;
    this.filterEmitter.emit(this.Categories);
  }
    // ---for logviewer--

  updateCategoryArr(ga, gv, index) {
    let filteredIdx = ga.filtered.findIndex(x => { return x == gv.id });
    let isAll = ga.filters.findIndex(x => { return x.id == gv.id && x.id == 0 });
    if (filteredIdx == -1) {
      if (isAll == 0) {
        ga.filtered = ga.filters.map(r => r.id);
      }
      else {
        ga.filtered.push(gv.id);
        let diff = _.difference(ga.filters.map(r => r.id), ga.filtered);
        if (diff.length == 1 && diff[0] == 0) {
          // push all - object
          ga.filtered.push(0);
        }
      }

    } else {
      if (isAll == 0) {
        ga.filtered = [];
      }
      else {
        ga.filtered.splice(filteredIdx, 1);
        // Remove 0 value - all
        let allIndex = _.findIndex(ga.filtered, (x) => { return x == 0 });
        if (allIndex >= 0) {
          ga.filtered.splice(allIndex, 1);
        }
      }
    }
    this.checkIndex(this.Categories, gv);
    this.Categories[index].filters = this.orderData(this.Categories[index]);
    this.calculateFilteredDataLength();
    this.filterEmitter.emit(this.Categories);
  }

  checkIndex(category, filter) {
    _.find(category.filtered, (x) => { return x.id == filter.id });
  }

  resetFilter() {
    this.Categories = this.Categories.map(x => {
      x.filtered = [];
      return x;
    })
    this.calculateFilteredDataLength();
    this.Categories.forEach(element => {
      element.filters.forEach(data => {
        data.isPresent = false;
      });
    });
    this.dateChanged = false;
    if (this.resetEventEmitter.observers.length == 0)
      this.filterEmitter.emit(this.Categories);
    this.resetEventEmitter.emit();

    this.radioChanged=false;
  }

  calculateFilteredDataLength() {
    this.filteredDataLength = this.Categories.map(x => x.filtered).reduce((acc, filteredArray) => { return acc + filteredArray.length }, 0);
  }
  categoryOpenDialog(event,category): void {
    let windowHeight= window.innerHeight;
    let d = document.getElementById(category.title);
    let topPos = event.clientY - 245 - 0; // 245 height of the dialog box // top
    let position = 'top';
    if ((windowHeight - event.clientY) > 245) {
    topPos = event.clientY + 25 - d.closest('.mat-expansion-panel-body')['offsetHeight'] ; //Bottom
    position = 'bottom';

 }
    let leftPos = d.offsetLeft + 2;
    this.filterOptions = {
      'ShowMoreFilters': true,
      'moreData': category.filters,
      'selectedMoreData': category.filtered,
      'top': topPos,
      'left': leftPos,
      'belongto': category.id,
      'fromPage': 'AF',
      'categoryType': category.title,
      'position':position
    };
  }

  filteredDataResponse(e) {
    let result = e[0];
    let id = e[1];
    let categoryIndex = this.Categories.findIndex(r => r.id === id);
    if (categoryIndex >= 0) {
      this.Categories[categoryIndex].filtered = result;
    }
    this.Categories[categoryIndex].filters = this.orderData(this.Categories[categoryIndex]);
    this.calculateFilteredDataLength();
    this.filterEmitter.emit(this.Categories);
  }

  orderData(DataArr) {
    const isAllDataArray = DataArr.filters.filter(data => {
      if (data.id === 0) {
        return data;
      }
    });
    const selectedArray = DataArr.filters.filter(data => DataArr.filtered.includes(data.id));
    const dataArray = DataArr.filters;
    return _.union(isAllDataArray, selectedArray, dataArray);
  }
}
@Pipe({
  name: 'checkIndexPipe',
})

export class checkIndexPipe implements PipeTransform {
  transform(value: any, category: any, filter: any) {
    let indexValue = _.find(category.filtered, (x) => { return x.id == filter.id });
    if (indexValue) {
      return 'body-bgcolor whitecolor LW12';
    }
    else {
      return 'body-bordercolor LW12';
    }
  }

}
