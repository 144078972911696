<form *ngIf="ShopFormGrp" [formGroup]="ShopFormGrp"  class="h-100 w-100 newVersionWrapper retail-items-wrapper">
  <div class="shopping-items h-100">
    <div class="categories d-inline-block" [ngClass]="[!toggleCategories ? 'category-panel-expand':'category-panel-collapse']">
      <div class="retail-category-toggler ag_text-right  ag_w--10"
            [ngClass]="[!toggleCategories ? 'category-expand':'category-collapse']">
            <button [attr.automationId]="'Btn_retail-items_category'" class="btn btn-default body-bgcolor whitecolor" (click)="togglerCategories($event)" type="button"><i   aria-hidden="true"
                    class="icon-right-arrow" ></i></button>
        </div>
      <ng-scrollbar >
        <button type="button" attr.automationId='Btn_retail-items_selectCategory_{{i}}'
          [ngClass]="{'body-bgcolor whitecolor': _utilities.isExist(categorySelected, category) > -1 }"
          class="contents cursor categorybtn button-in border-color LW14 textellipsis" [matTooltip]='category.name'
          (click)="SelectCategory(category)" *ngFor="let category of categories; let i=index">{{category.name}}
        </button>
      </ng-scrollbar>
    </div>
    <div class="shopping-lists d-inline-block h-100" [ngClass]="[!toggleCategories ? '':'width']">
      <div class="products d-inline-block" [ngClass]="{'cart-collapsed': togglePanel}" id="midDetailsSec">
        <div class="search-div">
          <div class="d-flex align-items-center">
            <ng-container *ngIf="functionalities[ShowOutletSelectionFieldInShopScreen]">
              <mat-form-field [floatLabel]="floatLabel">
                <mat-select [attr.automationId]="'Dd_retail-items_outlet'" formControlName="outlet"
                  [placeholder]="localization.captions.shop.Outlet" (selectionChange)="outletChange($event)"
                  [disabled]='_shopservice.selectedProducts.length > 0 || disableOutlet' required>
                  <mat-option *ngFor="let option of outlets" [value]="option.subPropertyID">{{option.subPropertyName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="ShowMultiPackRedeem">
              <button attr.automationId='Btn_retail-items_redeemMultiPack' (click)="OpenRedeemMultiPackDialog()"
                type="button"
                class="body-bgcolor whitecolor LW14 redeem-multipack">{{localization.captions.shop.MultiPackRedeemPopup.lbl_RedeemMultiPack}}</button>
            </ng-container>
          </div>
          <mat-form-field class="ml-4" *ngIf="propertyInfo.UseRetailInterface" [floatLabel]="floatLabel">
            <mat-select [attr.automationId]="'Dd_retail-items_terminal'" formControlName="terminal" [placeholder]="localization.captions.shop.Terminal"
              (selectionChange)="terminalChange($event.value)"
              [disabled]='_shopservice.selectedProducts.length > 0 || disableOutlet' required>
              <mat-option *ngFor="let terminal of outletTerminals" [value]="terminal.terminalId">
                {{terminal.terminalName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="d-flex align-items-center ">
            <div class="custwdtcalc100 custwdtcalc100_custom">
              <input [attr.automationId]="'Txt_retail-items_searchText'" type="text" class="products-search w-75 LW14" matInput
                placeholder="{{localization.captions.shop.SearchBy}}" formControlName="searchtext"
                (input)="filter($event, 'filter', false)" autocomplete="off" />
              <i [attr.automationId]="'Icn_retail-items_filterSearchTextEnable'" aria-hidden="true" class="icon-Search IC16 custSrch" *ngIf="enableSearchIcon"></i>
              <i [attr.automationId]="'Icn_retail-items_filter'" aria-hidden="true" class="icon-Cancel IC16 custSrch" *ngIf="!enableSearchIcon"
                (click)="filter('', 'filter', false)"></i>
            </div>
            <span class="LW12 pr-2 text-right ml-3">{{localization.captions.shop.Quantity}}</span>
            <div class="custwdt150">
              <input [attr.automationId]="'Txt_retail-items_scanQuantity'" type="text" inputtype="nonnegative, nodecimal" matInput class="products-search w-75"
                formControlName="scanQuantity" minlength="1" maxlength="4" autocomplete="off" />
            </div>
            <div class="custwdtcalc100">
              <input [attr.automationId]="'Txt_retail-items_searchBarCode'" type="text" class="products-search w-75 LW14" matInput #searchTextBarCode="matInput"
                placeholder="{{localization.captions.shop.SearchByBarCode}}" [readonly]="isLoading" formControlName="searchtextBarCode"
                (input)="filter($event, 'filter', true, true)" autocomplete="off" />
              <i [attr.automationId]="'Icn_retail-items_filterSearchBarCodeEnable'" aria-hidden="true" class="icon-Search IC16 custSrch" *ngIf="enablebarSearchIcon"></i>
              <i [attr.automationId]="'Icn_retail-items_filterSearchBarCode'" aria-hidden="true" class="icon-Cancel IC16 custSrch" *ngIf="!enablebarSearchIcon"
                (click)="filter('', 'filter', true, true)"></i>
            </div>
            <div class="ml-auto" *ngIf="functionalities?.ShowRentalItemCategoryInRetail && this.frompage !='RT'">
              <div class="toggle-label d-inline-flex align-items-center ml-auto d-table">
                <label class="ag_form--label toggle-switch ag_mr--3">{{localization.captions.showRentalsOnly}}</label>
                <app-retail-toggle-switch [attr.automationId]="'Tog_retail-items_showRentals'" [formControl]="ShopFormGrp.controls['ShowRentalItemOnly']"
                  (changeToggleEvent)=LoadRentalItems($event)></app-retail-toggle-switch>
              </div>
            </div>
            <div class="ml-auto" *ngIf="this.frompage!='RT' && showReturn">
              <div class="toggle-label d-inline-flex align-items-center ml-auto d-table">
                <label class="ag_form--label toggle-switch ag_mr--3">{{localization.captions.shop.RETURN}}</label>
                <app-retail-toggle-switch [attr.automationId]="'Tog_retail-items_shopReturn'" [formControl]="ShopFormGrp.controls['returnServiceFlag']"
                  (changeToggleEvent)=ValidateAccess($event) [disableToggle]="_shopservice.reOpenTransaction">
                </app-retail-toggle-switch>
              </div>
            </div>
          </div>
        </div>
        <div class='product-div'>

          <ng-scrollbar  (scrollState)="onScroll($event)">
            <span *ngIf='RetailProducts.length < 1' class='d-table'>{{localization.captions.shop.NoItemstoShow}}</span>
            <div formArrayName="products">
              <div [formGroupName]="i" *ngFor="let product of RetailProducts;let i=index"
                class=" LW14 w-100 product-desc product-desc-wrapper mb-5" (window:resize)="checkProductsWidth(i)">
                <div class="product-desc inner-wrapper" *ngIf="product.data.length > 0">
                  <div [style.display]="product.hidden ? 'none' : ''" class="product-name mb-3">
                    <span class="display-inline-block blckclr pl-1">{{product.name}}</span>
                    <a [attr.automationId]="'Lbl_retail-items_viewless'" *ngIf='!showViewLess[i]' (click)="changeProductsView(product.id, i, 'viewall')"
                      class="viewmore text-color LW12"
                      [ngStyle]="{'display':noOfProducts[i] < product.data.length ? 'inline-block' : 'none' }">{{localization.captions.common.ViewAll}}</a>
                    <a [attr.automationId]="'Lbl_retail-items_viewListMore'" *ngIf='showViewLess[i]' class="viewmore text-color LW12"
                      (click)="changeProductsView(product.id, i, 'viewless')">
                      {{localization.captions.shop.ViewLess}}</a>
                  </div>
                  <div [style.display]="product.hidden ? 'none' : ''" class="items-collapse"
                    *ngIf="product.data.length > 0"
                    [ngClass]="{'height':noOfProducts[i] == product.data.length ? '' : '214' }">
                    <div [formGroupName]="in"
                      *ngFor="let productdetails of product.data | slice : 0 : noOfProducts ? noOfProducts[i] : 0 ;let in=index"
                      class="d-inline-block details-div mt-2" [ngClass]="{'display-shop-without-images--wrapper': !isDisplayImagesInShop}">
                      <div *ngIf="isDisplayImagesInShop">

                        <div class="product-title mb-2 LW16 textellipsis" [title]="productdetails.title">
                          {{productdetails.title}}</div>

                        <div class="details  text-align-center" [ngClass]="{'custom-details-wrap':productdetails.itemNotSynced || !productdetails.isCategoryMapped}">
                          <div class="price text-align-right LWB16 px-2">
                            <span class="item-number-prod" [matTooltip]="productdetails.itemNumber? productdetails.itemNumber: ''">{{productdetails.itemNumber? productdetails.itemNumber: ''}}</span>
                            <span class="item-price-prod" [matTooltip]="productdetails.isOpenItem && !productdetails.isGiftCardCustomValueItem?'':(productdetails.dispPrice | Currency)" *ngIf="(productdetails.dispPrice > 0 && !productdetails.isGiftCardItem) || (productdetails.dispPrice > 0 && productdetails.isGiftCardCustomValueItem)">{{productdetails.isOpenItem && !productdetails.isGiftCardCustomValueItem?'':
                           (productdetails.dispPrice | Currency)}}</span></div>
                          <div class="price text-align-right LWB16 px-2 gift-card-open-label"
                            *ngIf="productdetails.isGiftCardItem && !productdetails.isGiftCardCustomValueItem">
                            {{localization.captions.shop.GiftCard.GiftCardOpenItem}}</div>
                          <div class="shop-image">

                            <app-retail-img-thumbnail *ngIf="productdetails.image" [imageContent]="productdetails.image"
                              class="image app-retail-img-thumbnail" [thumbnail]="false" imageType="retailItem">
                            </app-retail-img-thumbnail>

                            <span *ngIf="!productdetails.image">
                              <span class="icon-emptyshop"><span class="path1"></span><span class="path2"></span><span
                                  class="path3"></span><span class="path4"></span><span class="path5"></span><span
                                  class="path6"></span><span class="path7"></span><span class="path8"></span><span
                                  class="path9"></span><span class="path10"></span><span class="path11"></span><span
                                  class="path12"></span><span class="path13"></span><span class="path14"></span></span>
                            </span>

                          </div>
                          <div *ngIf="productdetails.itemNotSynced && productdetails.isCategoryMapped" class="not-sync-sect">
                            <h4>{{localization.captions.shop.Itemnotsynchronized}}</h4>
                          </div>
                          <div *ngIf="!productdetails.itemNotSynced && !productdetails.isCategoryMapped" class="not-sync-sect">
                            <h4>{{localization.captions.shop.ItemCategoryNotMapped}}</h4>
                          </div>
                          <div *ngIf="productdetails.itemNotSynced && !productdetails.isCategoryMapped" class="not-sync-sect">
                            <h4>{{localization.captions.shop.ItemCategoryNotMapped}}</h4>
                          </div>
                        </div>
                        <div class="noofitems-wrapper">
                          <div class="noofitems d-flex w-100">
                            <div class="width-style customize-textbox" *ngIf="!productdetails.isGiftCardItem">
                              <mat-form-field class="products-count{{i}} w-100 pms_shop noMargin" [floatLabel]="floatLabel">
                                <input [attr.automationId]="'Txt_retail-items_itemCount'" [readonly]='productdetails.itemNotSynced || !productdetails.isCategoryMapped' matInput type="text" inputtype="nonnegative,nodecimal" maxlength="4"
                                  autocomplete="off" formControlName="itemcount" placeholder="">
                              </mat-form-field>
                            </div>
                            <div *ngIf="!propertyInfo.UseRetailInterface && !productdetails.isGiftCardItem" class="text-align-right shop-discount margin-position"><i
                              [ngClass]="!this.ItemEligibleForDiscount[productdetails.category] ? 'button_invalid' : '' "
                              class="icon-percent iconborder blckclr ag_cursor--pointer"
                              [attr.automationId]="'Icn_retail-items_discount'"
                              (click)="OnDiscountpopClick($event,productdetails, in,discountPopover)"
                              ></i></div>

                            <!-- Added static count for Gif card feature -->
                            <div class="width-style static-item" *ngIf="productdetails.isGiftCardItem">
                              <div class="static-item-count-container">
                                <span class="static-itemNo">1</span>
                                <!-- <i aria-hidden="true" class="icon-Down-Arrow IC12"></i> -->
                              </div>
                            </div>
                            <div class="d-flex splitbuttons" [class.container-div]="!productdetails.isGiftCardItem" container="body">
                              <i [attr.automationId]="'Icn_retail-items_plus'" class="icon-actions add-item body-bgcolor whitecolor" [matTooltip]="localization.captions.shop.ADD" [ngClass]="{'cutsom-disable':productdetails.itemNotSynced || !productdetails.isCategoryMapped }" (click)="triggerPricePrompt(productdetails,i,in,ShopFormGrp.value.products[i][in].itemcount)">+</i>
                              <i [attr.automationId]="'Icn_retail-items_add'" class="icon-actions icon-add-without-commission unsetFont body-bgcolor whitecolor" [matTooltip]="localization.captions.shop.Addwithoutcommission" [ngClass]="{'cutsom-disable':productdetails.itemNotSynced || !productdetails.isCategoryMapped }" (click)="addProduct(productdetails,i,in,false)"></i>
                              <!-- <button type="button" [disabled]="productdetails.itemNotSynced || !productdetails.isCategoryMapped"  mat-button
                                class="btn btn-default buttonLineHeight addProducts body-bgcolor whitecolor"
                                (click)="triggerPricePrompt(productdetails,i,in,ShopFormGrp.value.products[i][in].itemcount)">{{localization.captions.shop.ADD}}</button>
                              <button *ngIf = "!productdetails.isGiftCardItem" [disabled]="productdetails.itemNotSynced || !productdetails.isCategoryMapped" type="button" mat-button [matMenuTriggerFor]="menu"
                                class="splitbuttons-menu body-bgcolor" [ngClass]="{'cutsom-disable':productdetails.itemNotSynced || !productdetails.isCategoryMapped }"><i
                                  class="icon-Down-Arrow IC12 whitecolor"></i></button>
                              <mat-menu #menu="matMenu" class="panel-height-reset">
                                <button type="button" [disabled]="productdetails.itemNotSynced || !productdetails.isCategoryMapped" (click)="addProduct(productdetails,i,in,false)"
                                  mat-menu-item>{{localization.captions.shop.Addwithoutcommission}}</button>
                              </mat-menu> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="display-shop-without-images" *ngIf="!isDisplayImagesInShop">
                        <div class="details text-align-center" [ngClass]="{'custom-details-wrap':productdetails.itemNotSynced || !productdetails.isCategoryMapped}">
                          <div class="prodect-desc--wrapper">
                            <div class="product-title LW16 textellipsis" [matTooltip]="productdetails.title">
                              {{productdetails.title}}</div>
                            <div [matTooltip]="productdetails.isOpenItem && !productdetails.isGiftCardCustomValueItem?'':
                            (productdetails.dispPrice | Currency)" class="item-price-prod-withoutimage text-align-right LWB16"
                              *ngIf="(productdetails.dispPrice > 0 && !productdetails.isGiftCardItem) || (productdetails.dispPrice > 0 && productdetails.isGiftCardCustomValueItem)">
                              {{productdetails.isOpenItem && !productdetails.isGiftCardCustomValueItem?'':
                             (productdetails.dispPrice | Currency)}}</div>
                          </div>
                          <div class="item-number-prod-withoutimage" [matTooltip]="productdetails.itemNumber? productdetails.itemNumber: ''">{{productdetails.itemNumber? productdetails.itemNumber: ''}}</div>

                          <div class="price text-align-right LWB16 px-2 gift-card-open-label"
                            *ngIf="productdetails.isGiftCardItem && !productdetails.isGiftCardCustomValueItem">
                            {{localization.captions.shop.GiftCard.GiftCardOpenItem}}</div>
                              <div *ngIf="productdetails.itemNotSynced && productdetails.isCategoryMapped" class="not-sync-sect">
                                <h4>{{localization.captions.shop.Itemnotsynchronized}}</h4>
                              </div>
                              <div *ngIf="!productdetails.itemNotSynced && !productdetails.isCategoryMapped" class="not-sync-sect">
                                <h4>{{localization.captions.shop.ItemCategoryNotMapped}}</h4>
                              </div>
                              <div *ngIf="productdetails.itemNotSynced && !productdetails.isCategoryMapped" class="not-sync-sect">
                                <h4>{{localization.captions.shop.ItemCategoryNotMapped}}</h4>
                              </div>
                              <div class="noofitems d-flex w-100">
                                <div class="width-style customize-textbox" *ngIf="!productdetails.isGiftCardItem">
                                  <mat-form-field class="products-count{{i}} w-100 pms_shop noMargin" [floatLabel]="floatLabel">
                                    <input [attr.automationId]="'Txt_retail-items_itemCount1'" [readonly]='productdetails.itemNotSynced || !productdetails.isCategoryMapped' matInput type="text" inputtype="nonnegative,nodecimal" maxlength="4"
                                      autocomplete="off" formControlName="itemcount" placeholder="">
                                  </mat-form-field>
                                </div>
                                <div class="text-align-right shop-discount"><i *ngIf="!propertyInfo.UseRetailInterface && !productdetails.isGiftCardItem"
                                  [ngClass]="!this.ItemEligibleForDiscount[productdetails.category] ? 'button_invalid' : '' "
                                  class="icon-percent iconborder blckclr"
                                  [attr.automationId]="'Icn_retail-items_percent'"
                                  (click)="OnDiscountpopClick($event,productdetails, in,discountPopover)"
                                  ></i></div>
                                <!-- Added static count for Gif card feature -->
                                <div class="width-style static-item" *ngIf="productdetails.isGiftCardItem">
                                  <div class="static-item-count-container">
                                    <span class="static-itemNo">1</span>
                                    <!-- <i aria-hidden="true" class="icon-Down-Arrow IC12"></i> -->
                                  </div>
                                </div>
                                <div class="d-flex splitbuttons" [class.container-div]="!productdetails.isGiftCardItem" container="body">
                                  <i [attr.automationId]="'Icn_retail-items_trigger'" class="icon-actions add-item body-bgcolor whitecolor" [matTooltip]="localization.captions.shop.ADD" [ngClass]="{'cutsom-disable':productdetails.itemNotSynced || !productdetails.isCategoryMapped }" (click)="triggerPricePrompt(productdetails,i,in,ShopFormGrp.value.products[i][in].itemcount)">+</i>
                                  <i [attr.automationId]="'Icn_retail-items_add1'" class="icon-actions icon-add-without-commission unsetFont body-bgcolor whitecolor" [matTooltip]="localization.captions.shop.Addwithoutcommission" [ngClass]="{'cutsom-disable':productdetails.itemNotSynced || !productdetails.isCategoryMapped }" (click)="addProduct(productdetails,i,in,false)"></i>
                                  <!-- <button type="button" [disabled]="productdetails.itemNotSynced || !productdetails.isCategoryMapped"  mat-button
                                    class="btn btn-default buttonLineHeight addProducts body-bgcolor whitecolor"
                                    (click)="triggerPricePrompt(productdetails,i,in,ShopFormGrp.value.products[i][in].itemcount)">{{localization.captions.shop.ADD}}</button>
                                  <button *ngIf = "!productdetails.isGiftCardItem" [disabled]="productdetails.itemNotSynced || !productdetails.isCategoryMapped" type="button" mat-button [matMenuTriggerFor]="menu"
                                    class="splitbuttons-menu body-bgcolor" [ngClass]="{'cutsom-disable':productdetails.itemNotSynced || !productdetails.isCategoryMapped }"><i
                                      class="icon-Down-Arrow IC12 whitecolor"></i></button>
                                  <mat-menu #menu="matMenu" class="panel-height-reset">
                                    <button type="button" [disabled]="productdetails.itemNotSynced || !productdetails.isCategoryMapped" (click)="addProduct(productdetails,i,in,false)"
                                      mat-menu-item>{{localization.captions.shop.Addwithoutcommission}}</button>
                                  </mat-menu> -->
                                </div>
                              </div>
                        </div>

                      </div>
                    </div>
                    <div class="d-inline-block pl-5" *ngIf="product.isViewMoreEnable">
                      <div [ngClass]="{'d-none':noOfProducts[i] > product.data.length || noOfProducts[i] == product.data.length}">
                        <div class="product-title mb-2"></div>
                        <div [attr.automationId]="'Lbl_retail-items_viewMore'" class="d-flex view__more details  text-align-center" (click)="product.viewMoreclicked ? changeProductsView(product.id, i, 'viewall') : getMoreRetailItems(product,i)">
                          <span class="theme__link">{{localization.captions.common.ViewMore}}</span>
                        </div>
                        <div class="noofitems d-flex w-100"></div>
                      </div>
                    </div>
                    <div class="res-progress-bar" *ngIf="isLoading && product.id === quickSaleCateroryId">
                      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                  </div>

                </div>
              </div>
              <!-- Popover starts  -->
              <!-- <popover-content #discountPopover placement="bottom" class="discount-popover" [style.left.px]="xPos"
                [style.top.px]="yPos" [ngClass]="isRight ? 'right-aligned': 'left-aligned'">
                <app-apply-discount [ApplyDiscountInput]="discountInput" [miscSetting]="miscSetting"
                  (discountEmitter)="applyDiscount($event,discountPopover)" (closeEmitter)="discountPopover.hide();">
                </app-apply-discount>
              </popover-content> -->
            </div>
          </ng-scrollbar>
        </div>
      </div>
      <div [ngClass]="[!togglePanel ? 'action-panel-expand':'action-panel-collapse']" class="action-bar shopping-cart d-inline-block">
        <div class="retail-slider-toggler ag_text-right  ag_w--10"
            [ngClass]="[!togglePanel ? 'summary-expand':'summary-collapse']">
            <button [attr.automationId]="'Btn_retail-items_leftArrow'" class="btn btn-default body-bgcolor whitecolor" (click)="togglerSlider($event)" type="button"><i   aria-hidden="true"
                    class="icon-left-arrow" ></i></button>
        </div>
        <div class="cart-header-text">
          <div class="items-added d-flex align-items-center">
            <label [attr.automationId]="'Lbl_retail-items_AddedToCart'" class="LWB14 uppercase">{{localization.captions.shop.ItemsAddedtoCart}}</label>
          </div>
          <div class="cart-badge" [matTooltip]="_shopservice.selectedProducts | cartcount" (click)="togglerSlider($event)">
            <mat-icon [matBadge]="_shopservice.selectedProducts | cartcount" matBadgeColor="warn"><span class="icon-shop-cart"></span></mat-icon>
          </div>
        </div>
        <div class="w-100 shopping-cart-table" id="shopping-cart-table" [ngClass]="{'shopping-cart-setHeight'  : this.frompage=='RT' || _retailService.isFromReservation}">
          <!-- <ng-scrollbar > -->
          <ng-container *ngIf="!_retailService.isFromReservation; else rentalCart">
            <table aria-describedby="" class="w-100" [attr.automationId]="'Tbl_retail-items_shopTable'"
              [ngClass]="_shopservice.reOpenTransaction && _shopservice.isReopenViewOnly ? 'button_invalid' : ''"
              id="shop-table">
              <tbody>
                <tr *ngFor="let SelectedProduct of _shopservice.selectedProducts| OrderSummaryPipe ; let i = index"
                  class="LW14 items-div-bg pt-2 split-items">
                  <td class="ag_text--left align-baseline">
                    <div class="textellipsis">{{SelectedProduct.Noofitems}} x <span class="" matTooltip="{{SelectedProduct.ProductName}}">
                      {{SelectedProduct.ProductName}}</span> </div>
                      <div class="LW12 discount mt-1" *ngIf="SelectedProduct.scaledUnits">
                        {{SelectedProduct.scaledUnits}} {{SelectedProduct.uom}}
                      </div>
                    <div *ngIf="SelectedProduct.Discount" class="LW12 discountPrice ag_p--1 mt-1 ag_w--100 text-ellipsis">
                      {{localization.captions.shop.discountApplied}}
                      ({{SelectedProduct.DiscountPercentage ? SelectedProduct.DiscountPercentage+'%' : localization.currencySymbol+SelectedProduct.Discount}})
                    </div>
                    <div *ngIf="(SelectedProduct.ServiceCharge && SelectedProduct.ServiceCharge.length > 0) || (SelectedProduct.Gratuity && SelectedProduct.Gratuity.length > 0) || (SelectedProduct.Commission && SelectedProduct.Commission.length > 0)" class="mt-2">
                      <span *ngIf="SelectedProduct.Gratuity && SelectedProduct.Gratuity.length > 0 && SelectedProduct?.Gratuity[0]?.Amount" class="icon-div icon-shop-gratuity ag_cursor--pointer" matTooltip= "{{localization.captions.shop.Gratuity}}"></span>
                      <span *ngIf="SelectedProduct.Commission && SelectedProduct.Commission.length > 0" class="icon-div icon-shop-commission ag_cursor--pointer" matTooltip="{{localization.captions.shop.commission}}"></span>
                      <span *ngIf="SelectedProduct.ServiceCharge && SelectedProduct.ServiceCharge.length > 0 && SelectedProduct?.ServiceCharge[0]?.Amount " class="icon-div icon-shop-servicecharge ag_cursor--pointer" matTooltip="{{localization.captions.shop.ServiceCharge}}"></span>
                    </div>

                  </td>
                  <td class="top-align ag_text-right">
                    <div class="textellipsis text-decoration-line-through" *ngIf="SelectedProduct.Discount" matTooltip="{{(SelectedProduct | showoriginalprice)}}" >
                      {{(SelectedProduct | showoriginalprice)}}</div>
                    <div class="textellipsis pt-2 discount-price-right" *ngIf="SelectedProduct.Discount" matTooltip="{{(SelectedProduct | showdiscountprice)}}">
                      {{(SelectedProduct | showdiscountprice)}}</div>
                    <div class="textellipsis" [ngClass]="[SelectedProduct.Discount ? 'pt-2' : '']" matTooltip="{{(SelectedProduct | showitemprice)}}">
                      {{(SelectedProduct | showitemprice)}}</div>
                  </td>
                  <td class="top-align">
                    <i aria-hidden="true" class="icon-Delete blckclr cursor"
                      [ngClass]="{ 'button_invalid' : SelectedProduct.isModificationRestricted && (!_shopservice.correctTransaction ||  !_shopservice.reOpenTransaction)}"
                      (click)="removeproduct(SelectedProduct)" matTooltip="{{localization.captions.shop.delete}}"></i>
                  </td>
                </tr>

              </tbody>
            </table>
          </ng-container>
        <div class="text-align-center no-shopingcart"
          *ngIf='(_shopservice.selectedProducts | OrderSummaryPipe).length < 1'>
          <span class="icon-Shop_nocart"><span class="path1"></span><span class="path2"></span><span
              class="path3"></span><span class="path4"></span><span class="path5"></span><span
              class="path6"></span><span class="path7"></span><span class="path8"></span><span
              class="path9"></span><span class="path10"></span><span class="path11"></span></span>
        </div>
        </div>
        <div class="ag_pt--3 action-button--wrap" [ngClass]="_shopservice.selectedProducts && _shopservice.selectedProducts.length > 0 ? 'subtotal-wrapper' : '' ">
          <div *ngIf="_shopservice.selectedProducts && _shopservice.selectedProducts.length > 0 && !_retailService.isFromReservation" class="LW14 items-div subtotal-section ag_display--flex-wrap">
            <div class="w-100">
              <span class="ag_font--bold float-left">{{(_shopservice.selectedProducts | subtotalprice)?.label}}</span>
              <span class="highlight-total float-right">{{(_shopservice.selectedProducts | subtotalprice)?.subtot}}</span>
            </div>
              <div class="highlight-sub">{{localization.captions.shop.ExtraChargesMayApply}}</div>
          </div>
          <div class="action-button--wrap position-relative"
          [ngClass]="_shopservice.reOpenTransaction && _shopservice.isReopenViewOnly ? 'button_invalid' : ''"
          [ngClass]=" checkscrollBar() ? '' : 'bottomBuySection'" *ngIf="this.frompage=='RI'">
          <ng-container *ngIf="_retailService.isFromReservation">
            <button [attr.automationId]="'Btn_retail-items_addReservation'" type="button" class="body-bgcolor whitecolor LW14 buy-button" (click)="addToReservation()"
              [disabled]='!_retailService.enableAddToReservation'
              mat-raised-button>{{ localization.captions.shop.AddToReservation }}</button>
            <button [attr.automationId]="'Btn_retail-items_selectedProduct'" type="button" class="LW14 m-0" (click)="removeproducts()" mat-button
              [ngClass]="(_shopservice.selectedProducts.length < 1  || shopservice.SelecedSeatProducts.length > 0)? 'button--tertiary--disabled':'button--tertiary'">
              {{localization.captions.shop.RESETCART}}</button>
            <button [attr.automationId]="'Btn_retail-items_cancel'" type="button" class="LW14 m-0" (click)="cancelToReservation()"
              mat-button>{{localization.captions.common.Cancel}}</button>
          </ng-container>
          <ng-container *ngIf="_shopservice.addRetailItemToSource; else showShopBtns">
            <button [attr.automationId]="'Btn_retail-items_addReservation'" type="button" class="body-bgcolor whitecolor LW14 buy-button" (click)="addToBooking()"
              [disabled]='_shopservice.selectedProducts.length == 0 && !updateItemsToAppt'
              mat-raised-button>{{ addOrUpdateBtnLabel }}</button>
            <button [attr.automationId]="'Btn_retail-items_selectedProduct'" type="button" class="LW14 m-0" (click)="removeproducts()" mat-button
              [ngClass]="(_shopservice.selectedProducts.length < 1  || shopservice.SelecedSeatProducts.length > 0)? 'button--tertiary--disabled':'button--tertiary'">
              {{localization.captions.shop.RESETCART}}</button>
            <button [attr.automationId]="'Btn_retail-items_cancel'" type="button" class="LW14 m-0" (click)="cancelToReservation()"
              mat-button>{{localization.captions.common.Cancel}}</button>
          </ng-container>
          <ng-template #showShopBtns>
            <span class="foliobuysec">
            <button [attr.automationId]="'Btn_retail-items_summary'" type="button" class="body-bgcolor whitecolor LW14 buy-button" (click)="BuyItems()" mat-raised-button
              [disabled]='_shopservice.selectedProducts.length < 1 || this.outlets.length == 0 || barcodeItemLoader'>{{
                isDebit ? localization.captions.shop.PaymentSummary :
                localization.captions.shop.BUY}}</button>
                  <i aria-hidden="true" *ngIf="postfolio_flag" class="postfolio-ic-type icon-post-to-folio border-color text-color ag_cursor--pointer" [ngClass]="{'button_invalid' :_shopservice.selectedProducts.length < 1 || this.outlets.length == 0}"  [matTooltip] = localization.captions.shop.paymentMethods[21] [attr.automationId]="'Icn_retail-items_summary'" (click)="PostToFolio()"></i>
                </span>          
            <button [attr.automationId]="'Btn_retail-items_reset'" type="button" class="LW14 m-0" (click)="removeproducts()" mat-button
              [ngClass]="(_shopservice.selectedProducts.length < 1  || shopservice.SelecedSeatProducts.length > 0)? 'button--tertiary--disabled':'button--tertiary'">
              {{localization.captions.shop.RESETCART}}</button>
          </ng-template>
        </div>
        <div class="position-relative" [ngClass]=" checkscrollBar() ? '' : 'bottomBuySection'"
          *ngIf="this.frompage=='RT'">
          <button [attr.automationId]="'Btn_retail-items_exchange'" type="button" class="body-bgcolor whitecolor LW14 buy-button mt-3 mb-3" (click)="Exchangetems()"
            mat-raised-button [disabled]='_shopservice.selectedProducts.length < 1'>
            {{localization.captions.shop.Exchange}}
          </button>
          <button [attr.automationId]="'Btn_retail-items_resetCart'" type="button" class="LW14  m-0" (click)="removeproducts()" mat-button
            [ngClass]="(_shopservice.selectedProducts.length < 1  || shopservice.SelecedSeatProducts.length > 0)? 'button--tertiary--disabled':'button--tertiary'">
            {{localization.captions.shop.RESETCART}}
          </button>
          <a [attr.automationId]="'Lbl_retail-items_resetcart'" (click)="goback()" class="LW14 reset-cart">{{localization.captions.shop.BackToReturnPage}}</a>
        </div>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #rentalCart>
  <!-- <div class="room-header">{{captions.RoomChargeNumber}} {{roomNumber}}</div> -->
  <app-retail-cart [roomNumber]="roomNumber" [stayId]="stayId" [saveCart]="saveCart"
    [products]="_shopservice.selectedProducts" [rentTypes]="rentTypes"
    (removeproductFromCart)="removeproductsFromCart($event)"></app-retail-cart>
</ng-template>
