import { Processor } from './processor';
import { ObjectChange } from '@app/shared/models/ChangeTrackingOperationResultDTO';
import { CacheService } from '@app/core/services/cache.service';
import { TablesService } from '@app/shared/services/tables.service';
import { ChangeAction, TableBlockingRuleFrequencyType } from '@app/shared/constants/commonenums';
import { TableBlockingRuleDTO } from '@app/shared/models/TableBlockingDTO';
import { Utilities } from '@app/shared/utilities/utilities';

export class TableBlockingRuleShiftChangeProcessor implements Processor {

  private static instance: TableBlockingRuleShiftChangeProcessor;
  private constructor(public cs: CacheService, public ts: TablesService) {
  }


  public static Instance(_cs: CacheService, _ts: TablesService): TableBlockingRuleShiftChangeProcessor {
    if (!TableBlockingRuleShiftChangeProcessor.instance) {
      TableBlockingRuleShiftChangeProcessor.instance = new TableBlockingRuleShiftChangeProcessor(_cs, _ts);
    }
    return TableBlockingRuleShiftChangeProcessor.instance;
  }

  Process(change: ObjectChange, additiondata: object, propertyId : number): void {
    let overallTableBlocking: TableBlockingRuleDTO[];
    if (propertyId == Utilities.RestaurantId()) {
      overallTableBlocking = this.ts.overallTableBlockingRules ? this.ts.overallTableBlockingRules
        : [];
    }
    else {
      overallTableBlocking = this.cs.propertySettings.value[propertyId].tableBlockingRules
    }
    let filteredRule: any;

    let blockedTable = new TableBlockingRuleDTO();
    if (change) {
      if (change.Action === ChangeAction.Created) {
        let ShiftTobeAdded = [];
        let starttimetobeAdded = null;
        let endtimetobeAdded = null;
        let reasontobeAdded = null;
        let dayofweektobeadd = null;
        let TableBlockingRuleId;
        change.PropertyChanges.forEach(property => {
          if (property.PropertyName === 'TableBlockingRuleId') {
            TableBlockingRuleId = property.Value;
          }
          if (property.PropertyName === 'ShiftId') {
            if (property.Value) {
              let shiftName = this.cs.settings.value.Shifts.filter(val => val.Id == property.Value);
              ShiftTobeAdded.push({ Id: JSON.parse(property.Value), Name: shiftName[0].Name });
            }
          }
          if (property.PropertyName === 'EndTimeInternal') {
            endtimetobeAdded = property.Value;
          }
          if (property.PropertyName === 'StartTimeInternal') {
            starttimetobeAdded = property.Value;

          }
          if(property.PropertyName === 'ReasonInternal') {
            reasontobeAdded = property.Value;
          }
          if (property.PropertyName === 'DayOfWeek') {
            dayofweektobeadd = property.Value;
          }
        });
        ShiftTobeAdded = ShiftTobeAdded.map(obj => ({ ...obj, TableBlockingRuleId: TableBlockingRuleId }));
        let objIndex = overallTableBlocking.findIndex((obj => obj.Id == TableBlockingRuleId));
        blockedTable = overallTableBlocking[objIndex];
        if (blockedTable) {
          blockedTable.Shifts = blockedTable.Shifts?.filter(function (obj) {
            return obj.Id !== ShiftTobeAdded[0].Id;
          });
          blockedTable.DayOfWeek = dayofweektobeadd;
          blockedTable.EffectiveRange = [];
          blockedTable.EffectiveRange.push({ Start: starttimetobeAdded, End: endtimetobeAdded, Reason: reasontobeAdded });
          if (ShiftTobeAdded && ShiftTobeAdded.length > 0) {
            blockedTable.Shifts.push({ Id: ShiftTobeAdded[0].Id, Name: ShiftTobeAdded[0].Name });
          }
          if (blockedTable.Frequency.Type == TableBlockingRuleFrequencyType.EveryWeekDay) {
            let shiftName;
            if (ShiftTobeAdded && ShiftTobeAdded.length > 0) {
              shiftName = this.cs.settings.value.Shifts.filter(shiftsval => shiftsval.Id == ShiftTobeAdded[0].Id);
            }
            blockedTable.Frequency.Date = null;
            if(shiftName && shiftName[0]){
              blockedTable.Frequency.DayOfWeek = shiftName[0].DayOfWeek;
            }else if (dayofweektobeadd) {
              blockedTable.Frequency.DayOfWeek = dayofweektobeadd;
            }
          }
          if (dayofweektobeadd) {
            blockedTable.Frequency.DayOfWeek = dayofweektobeadd;
          }
        }
      }
      else if (change.Action == ChangeAction.Removed) {
        let ShiftTobeRemoved = [];
        let TableBlockingRuleId;
        change.PropertyChanges.forEach(property => {
          if (property.PropertyName === 'TableBlockingRuleId') {
            TableBlockingRuleId = property.Value;
          }
          if (property.PropertyName === 'ShiftId') {
            if (property.Value) {
              let shiftName = this.cs.settings.value.Shifts.filter(val => val.Id == property.Value);
              ShiftTobeRemoved.push({ Id: JSON.parse(property.Value), Name: shiftName[0].Name });
            }
          }
        });
        ShiftTobeRemoved = ShiftTobeRemoved.map(obj => ({ ...obj, TableBlockingRuleId: TableBlockingRuleId }))
        let objIndex = overallTableBlocking.findIndex((obj => obj.Id == TableBlockingRuleId));
        blockedTable = overallTableBlocking[objIndex];
        if (blockedTable && blockedTable.Shifts) {
          blockedTable.Shifts = blockedTable.Shifts.filter((obj) => {
            return obj.Id !== ShiftTobeRemoved[0].Id;
          });
        }
      }
      if (overallTableBlocking && overallTableBlocking.length > 0 && blockedTable) {
        overallTableBlocking = overallTableBlocking.filter((obj) => {
          return obj.Id !== blockedTable.Id;
        });
      }
      if (blockedTable) {
        overallTableBlocking.push({ ...blockedTable });
      }
      this.ts.overallTableBlockingRules = overallTableBlocking;
      this.cs.propertySettings.value[propertyId].tableBlockingRules = overallTableBlocking;
    }
  }
}
