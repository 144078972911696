import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PaymentManagerComponent } from './payment-manager.component';
import { RetailBreakPoint } from '../shared/globalsContant';
import { RouteGuardService } from '../shared/service/route-gaurd.service';

const routes: Routes = [
  {
    path: '',
    component: PaymentManagerComponent,
    children:[
      { path: '', redirectTo: 'outlets', pathMatch: 'full',
       data: { BreakPointNumber: RetailBreakPoint.Outlets, ShowPopup: false, redirectTo: 'outlets'}
      },

      {
        path: 'outlets',
        canActivate: [RouteGuardService],
        loadChildren: () => import('src/app/retail/payment-manager/outlets/outlets.module').then(m => m.OutletsModule),
        data: { breakPointNumber: RetailBreakPoint.Outlets, redirectTo: '/settings/retailsetup/paymentmanager/payagents', syncAccess: true }
      },

      {
        path: 'payagents',
        canActivate: [RouteGuardService],
        loadChildren: () => import('src/app/retail/payment-manager/pay-agents/pay-agents.module').then(m => m.PayAgentsModule),
        data: { breakPointNumber: RetailBreakPoint.PayAgents, redirectTo: '/settings/retailsetup/paymentmanager/settlers', syncAccess: true }
      },

      {
        path: 'settlers',
        canActivate: [RouteGuardService],
        loadChildren: () => import('src/app/retail/payment-manager/settlers/settlers.module').then(m => m.SettlersModule),
        data: { breakPointNumber: RetailBreakPoint.Settlers, redirectTo: '', syncAccess: true, ShowPopup: true }
      },
      
      {
        path: 'history',
        loadChildren: () => import('src/app/retail/payment-manager/history/history.module').then(m => m.HistoryModule)
      }
    ]
  }
]
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class PaymentManagerRoutingModule { }
