import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enablerow'
})
export class EnablerowPipe implements PipeTransform {
  transform(val: boolean,index, options, editEvent) {
    if(editEvent && options) {
      return options[0].TablebodyData[index] && editEvent.id == options[0].TablebodyData[index].id ? options[0].TablebodyData[index].id : false;
    }
    return false;
  }
}
