<!--categories filter-->
<section [formGroup]="InventoryTransferFormGrp" autocomplete="off">

      <app-retail-category-component (formReady)="formInitialized($event)"></app-retail-category-component>
        <app-retail-multi-select-dropdown *ngIf="itemcatradiogrp" [dropDownFilterData] = "itemcatradiogrp" [dropDownControlname] = "'inventorytype'" [dropDownName] = "captions.InventoryType" (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
        <div class="mb-3">
          <label class="LW14">{{this.localization.captions.reports.SummaryView}}</label>
          <app-retail-toggle-switch class="d-block" formControlName="categoryView"></app-retail-toggle-switch>
        </div>
  </section>
