import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { LayoutModule } from '@app/layout/layout.module';
import { MaterialModule } from '@app/material-module';
import { SignalrService } from '@app/shared/services/signalr.service';
import { AuthCallbackComponent } from '@core/auth-callback/auth-callback.component';
import { ChangeProcessor } from '@core/change-processors/change-processor';
import { ProcessorBuilder } from '@core/change-processors/processor-builder';
import { CoreRoutingModule } from '@core/core-routing.module';
import { LoaderComponent } from '@core/loader/loader.component';
import { LoginBusiness } from '@core/login/login.business';
import { LoginComponent } from '@core/login/login.component';
import { PageNotFoundComponent } from '@core/pagenotfound/page-not-found.componenet';
import { AuthGuardService } from '@core/services/auth-guard.service';
import { AuthService } from '@core/services/auth.service';
import { LoaderInterceptor } from '@core/services/loaderInterceptor.service';
import { LoginService } from '@core/services/login.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '@shared/shared.module';
import { AuditModule } from '@app/audit/audit.module';
import { RouteLoaderService } from './services/route-loader.service';
import { RetailModule } from '@app/retail/retail.module';
import { AppService } from '@app/app.service';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { WidgetModule } from './widget/widget.module';
// import {FullCalendarModule} from 'primeng/fullcalendar';
export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateHttpLoader(http);
}


export class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    // Fetch the translations from the server if the extraParam is provided, otherwise return empty object.
    let path = lang.includes('.json') ? lang : environment.app_base_href ? `${environment.app_base_href}/assets/i18n/${lang}.json` : `/assets/i18n/${lang}.json`
      return this.http.get(
        `${path}`
      );
  }
}

@NgModule({
  declarations: [LoginComponent, AuthCallbackComponent, PageNotFoundComponent, LoaderComponent],
  imports: [
    // FullCalendarModule,
    CommonModule,
    CoreRoutingModule,
    LayoutModule,
    WidgetModule,
    SharedModule,    
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RetailModule,
    AuditModule
    
  ],
  providers:[
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    AuthService,
    LoginService,
    AuthGuardService,
    ChangeProcessor,
    ProcessorBuilder,
    LoginBusiness,    
    SignalrService,
    RouteLoaderService
  ],
  exports: [LoaderComponent],
  entryComponents:[LoaderComponent]
})
export class CoreModule { }
