import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ContentChild, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SizeSelectionComponent } from '@components/create-update-party-tab-layout/selections/size-selection/size-selection.component';
import { ReservationType } from '@constants/commonenums';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { PartyService } from '@services/party.service';
import { DashboardFunctions } from '@utilities/dashboard-functions';
import { ISubscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-select-reservation',
  templateUrl: './select-reservation-type.component.html',
  styleUrls: ['./select-reservation-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReservationTypeComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
  // popUpPrevBtnVisibility: boolean;
  ReservationType = ReservationType;
  componentDetails: ComponentDetails;
  injectorDetails: Injector;
  tabcomponentDetails = [];
  tabSubscription: ISubscription;

  @ContentChild(SizeSelectionComponent, { static: true }) sizeSlection: SizeSelectionComponent;
  constructor(public dialog: MatDialog, private cdRef: ChangeDetectorRef,
    public partyService: PartyService, private injector: Injector, private dashboardFunctions: DashboardFunctions) { }

  ngOnInit() {
    // this.injectorDetails = ReflectiveInjector.resolveAndCreate([{ provide: 'rer', useValue: 'rer' }], this.injector);
  }


  ngAfterViewInit() {
    this.tabSubscription = this.partyService.tabChange$.subscribe(val => {
      const index = this.tabcomponentDetails.findIndex(x => x.tabComponent === '');
      //  this.tabcomponentDetails[index].tabComponent = TableSelectionComponent;
    });

  }
  ngOnDestroy() {
    this.partyService.reservationType = null;
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  selectReservationType(type: ReservationType) {
    this.partyService.reservationType = type;
    this.tabcomponentDetails = [];
    if (type === ReservationType.Reservation) {
      this.dashboardFunctions.createOrEditReservation(false, null);
    } else if (type === ReservationType.Waitlist) {
      this.dashboardFunctions.createOrEditWaitlist(false, null);
    }
  }
}

