
import { Injector } from "@angular/core";
import { Router } from "@angular/router";
import { QuickLoginUtilities } from "@app/common/shared/shared/utilities/quick-login-utilities";
import { RetailPropertyInformation } from "@app/retail/common/services/retail-property-information.service";
import { RetailItemType } from "@app/retail/retail.modals";
import { RetailService } from "@app/retail/retail.service";
import { RetailDataAwaiters } from "@app/retail/shared/events/awaiters/retail.data.awaiters";
import { RetailSharedVariableService } from "@app/retail/shared/retail.shared.variable.service";
import { CommonVariablesService } from "@app/retail/shared/service/common-variables.service";
import { ConfigKeys, RetailFeatureFlagInformationService } from "@app/retail/shared/service/retail.feature.flag.information.service";
import { SelectedProducts } from "@app/retail/shared/shared.modal";
import { RedirectToModules, RetailUtilities } from "@app/retail/shared/utilities/retail-utilities";
import { PropertyFeaturesConfigurationService } from "@app/retail/sytem-config/payment-features-config/property-feature-config.service";
import { RetailBussinessService } from "@app/shared/retail.bussiness";
import _ from "lodash";




export class ActivitiesShopItem {


  constructor(public _shopservice: CommonVariablesService, public _retailSharedService: RetailSharedVariableService) {

  }

  checkSeatProductAvailability(data) {
    return this._retailSharedService.SelecedSeatProducts.filter(item => item.ItemId == data.ItemId).length > 0
  }

  Intialize() {
    
    let selectedReturnItems = [];
    // Activities - retail integration changes

    this._shopservice.selectedProducts.forEach(prod => {
      if (prod.isEditableActivityItem) {
        selectedReturnItems.push(prod)
      }
    });
    if(this._shopservice.isFirstInitialisation){
    this._shopservice.returnWithticketItems.forEach(prod => {
      prod.isEditableActivityItem = this._shopservice.seatedProductsBookingAttemptId?.ReturnItem.find(data => prod.id == data.TransactionDetailId) ? true : false;    
      if (prod.isEditableActivityItem) {
        selectedReturnItems.push(prod)
      }
    });
}
    this._shopservice.isFirstInitialisation = true;
    return selectedReturnItems;
  }

  ReturnTypeSelectionActivities() {
    // Activities - retail integration changes
    if (!this._shopservice.isOperationFromActivities)
      this._shopservice.selectedProducts = [];
  }

  CheckAllRainCheckIssuedActivities(GridItems) {
    return GridItems.selectedTransactionRetailProducts && (GridItems.selectedTransactionRetailProducts.some(x => x.rainCheckIssued === true) || GridItems.selectedTransactionRetailProducts.some(x => x.isDisabledActivityItem === true || x.isDeleteDisabled == true) || GridItems.selectedTransactionRetailProducts.some(x => x.isModificationRestricted === true));
  }
}
