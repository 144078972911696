import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-content',
  templateUrl: './filter-content.component.html',
  styleUrls: ['./filter-content.component.scss']
})
export class FilterContentComponent implements OnInit {
  @Input() displayOptions: any[] = [];
  @Input() isMessageAvailable: boolean;
  @Output() optionSelection = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  propertySelectionChanged(selectedItem) {
    this.optionSelection.emit(selectedItem);
  }

}
