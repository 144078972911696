<div class="slots-section px-2" [ngStyle]="{'opacity': data.IsDisabled || !(dateTime | expiredSlot: cs.settings.value) ? 0.6 : 1.0}" [ngClass]="{'slots-section__expired' : !(dateTime | expiredSlot: cs.settings.value)}" *ngIf="!data.PartyId; else partyView">
  <span class="slots-section__status" [ngStyle]="{'background-color': data.IsDisabled ? '#fcc203' : !(dateTime | expiredSlot: cs.settings.value) ? '#c1832e' : transparent}" [matTooltip]="data.IsDisabled ? ('disabled' | translate) : !(dateTime | expiredSlot: cs.settings.value) ? ('expired' | translate) : null"></span>
    <ng-container><label [ngStyle]="{'cursor': data.IsDisabled || !(dateTime | expiredSlot: cs.settings.value) ? 'default': 'pointer'}" (click)="slotService.selectCustomSlot(data)"><span>{{data.LimitedMinPartySize}} - {{data.LimitedMaxPartySize}}</span>
    <span class="pl-1">{{(data | slotSeatingArea : cs.settings.value) | translate }}</span></label></ng-container>
    <div #editSlotContainer *ngIf="!data.isExpired && cs.settings.value.General.SlottingMode == SlottingMode.Manual && !isEdit" class="icon-edit"
      [autoClose]="false" triggers="manual" #editSlotPopup="ngbPopover"  popoverClass="edit-popover" (click)="openEditSlotPopup()"
      [ngbPopover]="editSlot" [placement]="'left-bottom'" container="body">
    </div>
    <div *ngIf="isEdit" class="slots-section__slot-disable">
      <dynamic-form [config]="editOrDisableConfig" #enableSlots></dynamic-form>
    </div>
</div>
<ng-template #partyView>
  <div *ngIf="partyDetails" class="slots-section__party">
    <div class="slots-section__party-details"
      [ngStyle]="{'opacity': !(dateTime | expiredSlot: cs.settings.value) ? 0.6 : 1.0,'background-color':(partyDetails | partyStatusColor : cs.settings.value ),color: (partyDetails | partyStatusColor : cs.settings.value ) ? 'white':'inherit', cursor: partyDetails?.State != PartyState.Seated ? 'pointer': 'default'}"
      [ngClass]="{'slots-section__expired' : !(dateTime | expiredSlot: cs.settings.value)}" #partyStatusPopup="ngbPopover"
      popoverClass="party-status-popover" (click)="triggerPartyStatusPopup(partyStatusPopup,$event)" triggers="manual"
      [ngbPopover]="partyStatus" placement="auto" [autoClose]="'outside'" container="body">
      {{partyDetails?.Size}}
    </div>
    <div class="slots-section__party-contact pt-2" [ngStyle]="{'opacity': !(dateTime | expiredSlot: cs.settings.value) ? 0.6 : 1.0, cursor: !(dateTime | expiredSlot: cs.settings.value) ? 'auto' : 'pointer'}" [ngClass]="{'slots-section__expired' : !(dateTime | expiredSlot: cs.settings.value)}" (click)="!data.isExpired ? slotService.editReservation(partyDetails): null">
      <div class="slots-section__contact-name" matTooltipClass="tooltip-class"
        matTooltip="{{partyDetails?.Contact | formatName}}"><span class="icon-VIP px-1"
          *ngIf="partyDetails?.Contact?.IsVip"></span><span class="pl-1">{{partyDetails?.Contact | formatName}}</span></div>
          <div class="slots-section__party-action px-2">
            <span class="table-number" *ngIf="partyDetails?.TableIds?.length === 1"><i class="icon-icon" *ngIf="partyDetails.TableNames[0]"></i>
              <span class="text-overflow">{{partyDetails.TableNames[0] ? (partyDetails.TableIds | formattedTableName ) : '-'}}</span>
            </span>
            <div class="slots-section__party-notes">
              <a data-toggle="popover" [ngStyle]="{'background-color': partyDetails | pageBackgroundColor : cs.settings.value, 'border-radius': '4px', 'color': (partyDetails | pageBackgroundColor : cs.settings.value) != '#E8E8E8' ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}"
                *ngIf="(partyDetails?.PageMethod == PageMethod.Email || partyDetails?.PageMethod == PageMethod.SMSProxyRelay)" (click)="emailMessages(emailChat,$event)" #emailChat="ngbPopover"
                [ngbPopover]="messages"  container="body"  popoverClass="email-popover" [autoClose]="false" triggers="manual" placement="auto"
                class="icon-envelope ml-4 dashboard-party-info__guest-actions seat-border-color"></a>
              <a data-toggle="popover" [ngStyle]="{'background-color': partyDetails | pageBackgroundColor : cs.settings.value, 'border-radius': '4px', 'color': (partyDetails | pageBackgroundColor : cs.settings.value) != '#E8E8E8' ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}"
                *ngIf="(partyDetails?.PageMethod == PageMethod.Sms || partyDetails?.PageMethod == PageMethod.Sms2 || partyDetails?.PageMethod == PageMethod.Email2)" (click)="mobileMessages(messageChat,$event)" #messageChat="ngbPopover"
                [ngbPopover]="messages"  container="body"  popoverClass="message-popover" [autoClose]="false" triggers="manual" placement="auto"
                class="icon-SMS ml-4 dashboard-party-info__guest-actions seat-border-color"></a>
              <a [ngStyle]="{'background-color': partyDetails | pageBackgroundColor : cs.settings.value, 'border-radius': '4px', 'color': (partyDetails | pageBackgroundColor : cs.settings.value) != '#E8E8E8' ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}" *ngIf="partyDetails?.PageMethod == PageMethod.Manual" (click)="slotService.manualpage(partyDetails,cs.settings.value)"
                class="icon-Path-506 ml-4 dashboard-party-info__guest-actions seat-border-color"></a>
              <a [ngStyle]="{'background-color': partyDetails | pageBackgroundColor : cs.settings.value, 'border-radius': '4px', 'color': (partyDetails | pageBackgroundColor : cs.settings.value) != '#E8E8E8' ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}" *ngIf="(partyDetails?.PageMethod == PageMethod.VoiceCall || partyDetails?.PageMethod == PageMethod.VoiceCall2)"
                class="icon-ic_stay_current_portrait_24px ml-4 dashboard-party-info__guest-actions seat-border-color"></a>
              <ng-template class="messages" class="arrow-up" #messages>
                <app-chat-view [messages]="predefinedMsgs$.asObservable() | async" [partyInfo]="partyDetails" [historyData]="history"
                  (closePopover)="closeMobileChat()" [inputString]="inputString" [disableMessagePopup]="disableMessagePopup">
                </app-chat-view>
              </ng-template>
              <span *ngIf="partyDetails?.partyNotes?.length > 0" class="icon-exclamation pl-1"
                [autoClose]="'outside'" container="body" popoverClass="party-notes-scroll party-notes" triggers="manual" [ngbPopover]="partyNotesPopover" #partyNotes="ngbPopover"
                placement="auto" (click)="partyNotesPopup($event,partyNotes)">
                <span class="path1"></span><span class="path2"></span><span class="path3"></span>
              </span>
            </div>
          </div>
    </div> 
  </div>
</ng-template>

<div class="slots-section__guest-notes">
  <ng-template #partyNotesPopover>
    <div>
      <div class="slots-section__scrollSize">
        <div class="reservation-notes">
          <div class="reservation-notes__header seat-popover-header">
            {{_MESSAGES.CONFIG.reservationNotes | translate}}
          </div>
          <div class="reservation-notes__content seat-popover-content">
            <div class="reservation-notes__notes-details seat-border-color"
              *ngFor="let notes of partyDetails.partyNotes">
              <span class="seat-secondary-text" *ngIf="!notes.isSpecialMeal">{{notes.name}}</span>
              <span *ngIf="notes.isSpecialMeal">
                <span class="uppercase seat-secondary-text">
                  {{(notes.type | partyNoteType : cs.settings.value) | translate }}
                </span>
                <span>{{notes.name}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="slots-section__party-status">
  <ng-template #partyStatus>
    <app-party-status-popup [hidden]="hidePartyActions" [party]="partyDetails" (partyStatusId)="slotService.seatStatusChanged($event,partyDetails)"></app-party-status-popup>
  </ng-template>
</div>
<ng-template #editSlot>
    <div class="slots-section__edit-slot">
    <app-new-slot [isAdd]="false" [isSlots]="true" (closeSlot)="closeEditSlotPopup()" [slotTime]="data"></app-new-slot>
  </div>
</ng-template>