import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from "src/app/common/localization/localization";

@Component({
  selector: 'app-ag-menu-edit',
  templateUrl: './ag-menu-edit.component.html',
  styleUrls: ['./ag-menu-edit.component.scss']
})
export class AgMenuEditComponent implements OnInit {
  oldPlaceholderValue: string | number;
  oldErrorMessageValue: string | number;
  oldCustomErrorMessageValue: string | number;
  oldMinErrorValue: string | number;
  oldMaxErrorValue: string | number;
  editMenuForm: UntypedFormGroup;
  floatLabel: string;
  constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<AgMenuEditComponent>,private localization: Localization) {
    this.floatLabel = this.localization.setFloatLabel;
    this.oldPlaceholderValue = data.oldPlaceHolder;
    this.oldErrorMessageValue = data.oldErrorMessage;
    this.oldCustomErrorMessageValue = data.oldCustomErrorMessage;
    this.oldMinErrorValue = data.oldMinErrorValue;
    this.oldMaxErrorValue = data.oldMaxErrorValue;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  Update() {
    this.dialogRef.close(
      {
        newplaceholder: this.editMenuForm.value["newPlaceholderValue"],
        newErrorMessage: this.editMenuForm.value["newErrorMessageValue"],
        newCustomErrorMessage : this.editMenuForm.value["newCustomErrorMessageValue"],
        newMinErrorValue: this.editMenuForm.value["newMinErrorValue"],
        newMaxErrorValue : this.editMenuForm.value["newMaxErrorValue"]        
      });
  }

  Cancel() {
    this.dialogRef.close();
  }

  buildForm() {
    this.editMenuForm = this.fb.group({
      newPlaceholderValue: [this.oldPlaceholderValue, Validators.required],
      newErrorMessageValue: [this.oldErrorMessageValue, Validators.required],
      newCustomErrorMessageValue: [this.oldCustomErrorMessageValue, Validators.required],
      newMinErrorValue: [this.oldMinErrorValue, Validators.required],
      newMaxErrorValue: [this.oldMaxErrorValue, Validators.required]
    });
  }

}
