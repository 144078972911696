import { Injectable } from "@angular/core";
import { DropDownData, ReportSelector } from "./report.modals"; 
import * as _ from "lodash";
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import {  UserBreakPoint } from '../../retail.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';

@Injectable()
export class ReportBusinessService {
  constructor(private breakpoint: BreakPointAccess, private localization: RetailLocalization) { }

  /**
   * This can be used to save the filter data sources
   */
  public FilterDataSource :DropDownData[]=[];

  startingCash:any = 0;
  toggleIncludeInactive(IncludeInactiveToo: boolean, dropDownData: DropDownData[]) {
    let result :DropDownData[];
    result = dropDownData.map(data => {
      if (IncludeInactiveToo) {
        return data = {
          id: data.id, code: data.code, showInDropDown: true, description: data.description, isActive: data.isActive
        }
      } else {
        return data = {
          id: data.id, code: data.code, showInDropDown: data.isActive, description: data.description, isActive: data.isActive
        }
      }
    });
    result = _.orderBy(result, [user => user.description.toLowerCase()], 'asc');
    return result; 
  }

  categoryToggleIncludeInactive(IncludeInactiveToo: boolean, dropDownData: DropDownData[]) {
    let result :DropDownData[];
    result = dropDownData.map(data => {
      if (IncludeInactiveToo) {
        return data = {
          id: data.id, code: data.code, showInDropDown: true, description: data.description, isActive: data.isActive
        }
      } else {
        return data = {
          id: data.id, code: data.code, showInDropDown: data.isActive, description: data.description, isActive: data.isActive
        }
      }
    });
    return result; 
  }


  toggleClickbtn(data: DropDownData, dataSourceArray: DropDownData[], selectedDataArray: DropDownData[], defaultData?: DropDownData, allselectedCheck?): DropDownData[] {
    let selectedArray = selectedDataArray;
    const currentlySelectedArray = data;
    if (currentlySelectedArray.id == defaultData.id) { /* For all button click */
      if (allselectedCheck) {
        selectedArray = [];
        selectedArray = dataSourceArray.map(x =>
          x
        ); 
      } else {
        selectedArray = [];
      }
    } else { /* For other than all button click */
      if (selectedArray.indexOf(currentlySelectedArray) == -1) {
        selectedArray.push(currentlySelectedArray);
      } else {
        selectedArray.splice(selectedArray.indexOf(currentlySelectedArray), 1);

        if (selectedArray.indexOf(defaultData) != -1) {
          selectedArray.splice(selectedArray.indexOf(defaultData), 1);
        }
      }
    }
    selectedDataArray = selectedArray;
    return selectedArray;
  }

  fillSubCategoryFilterCaptions(data: any, captionAll: string): string {
    if (data) {
      let filterCaption: string;
      let isSpecificFilterSelected : boolean;
          let subcategory1List: any = this.fillSubCatgoryList(data.category[1]);
          let subcategory2List: any = this.fillSubCatgoryList(data.category[2]);
          let subcategory3List: any = this.fillSubCatgoryList(data.category[3]);
          let subcategory4List: any = this.fillSubCatgoryList(data.category[4]);
          let subcategory5List: any = this.fillSubCatgoryList(data.category[5]);

          if (subcategory1List && subcategory1List.length > 0) {
            filterCaption = this.fillSubCategoryName(subcategory1List, filterCaption);
            //to do: After "all" is removed modify the if condition based on the input
            isSpecificFilterSelected = data.category[1].selectedCat.length != data.category[1].catArr.length;
          } else {
            isSpecificFilterSelected = true
          }
          if (subcategory2List && subcategory2List.length > 0 ) {
            filterCaption = this.fillSubCategoryName(subcategory2List, filterCaption);
            //to do: After "all" is removed modify the if condition based on the input
            isSpecificFilterSelected = isSpecificFilterSelected ? isSpecificFilterSelected : data.category[2].selectedCat.length != data.category[3].catArr.length;
          }else {
            isSpecificFilterSelected = true
          }
          if (subcategory3List && subcategory3List.length > 0 ) {
            filterCaption = this.fillSubCategoryName(subcategory3List, filterCaption);
            //to do: After "all" is removed modify the if condition based on the input
            isSpecificFilterSelected = isSpecificFilterSelected ? isSpecificFilterSelected : data.category[3].selectedCat.length != data.category[3].catArr.length;
          }else {
            isSpecificFilterSelected = true
          }
          if (subcategory4List && subcategory4List.length > 0 ) {
            filterCaption = this.fillSubCategoryName(subcategory4List, filterCaption);
            //to do: After "all" is removed modify the if condition based on the input
            isSpecificFilterSelected = isSpecificFilterSelected ? isSpecificFilterSelected : data.category[4].selectedCat.length != data.category[4].catArr.length;
          }else {
            isSpecificFilterSelected = true
          }
          if (subcategory5List && subcategory5List.length > 0 ) {
            filterCaption = this.fillSubCategoryName(subcategory5List, filterCaption);
            //to do: After "all" is removed modify the if condition based on the input
            isSpecificFilterSelected = isSpecificFilterSelected ? isSpecificFilterSelected : data.category[5].selectedCat.length != data.category[5].catArr.length;
          }else {
            isSpecificFilterSelected = true
          }

          if (!isSpecificFilterSelected) {
            filterCaption = captionAll
          }
          return filterCaption;
      }
  }

  getSelectedCategoryCaptions(selectedCategories: any): string[] {
    const categories: string[] = [];

    selectedCategories.category.forEach((a, index) => {
      const IsAll: boolean = selectedCategories.category[index].catArr.length === selectedCategories.category[index].selectedCat.length && selectedCategories.category[index].catArr.length > 0;
      const caption: string = IsAll ? this.localization.propertyCaptions.reports.All : (a.selectedCat.map(o => o.name).join());
      categories.push(caption);
    });

    return categories;
  }

  public getFilterCaption(source: DropDownData[], selected: number[]) {
    const isAllSelected: boolean = source.length === selected.length;
    const selectedArr = [];
    selected.forEach((x) => {
      const filteredobj = source.filter((y) => {
        return x === y.id;
      })[0];
      if (filteredobj) {
        selectedArr.push(filteredobj.description);
      }
    });
    return isAllSelected ? this.localization.propertyCaptions.reports.All : selectedArr.join();
  }

  public checkAllSelected(source: DropDownData[], selected: number[]) {
    return source.length === selected.length;
  }

  fillSubCatgoryList(customFilterData: any): number[] {
    return customFilterData.selectedCat.map(x => x.name);
  }

  fillSubCategoryName(subCategoryFilterList, filterCaption: string): string {
    subCategoryFilterList.forEach(element => {
      filterCaption = filterCaption == "" || filterCaption == null ? element : filterCaption + ', ' + element
    });
    return filterCaption;
  }
  public applyBreakPoints(selector: ReportSelector[]): ReportSelector[] {
    const access: UserBreakPoint[] = this.breakpoint.GetBreakPoint(selector.map(s => s.breakPointNumber)).result;
    selector.forEach(s => {
      const breakpoint = access.find(a => a.breakPointNumber == s.breakPointNumber);
      if (breakpoint) { s.IsAuthorized = breakpoint.allow; }
    });
    return selector;
  }
}
