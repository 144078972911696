import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DayEndDateInfo, NightAuditConfig, NightAuditSchedule } from './night-audit.model';
import { NightAuditBusiness } from './night-audit.business';
import { Localization } from 'src/app/common/localization/localization';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { AgTimeConfig } from 'src/app/common/Models/ag-models';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ScheduleEmailComponent } from 'src/app/common/night-audit/schedule-email/schedule-email.component'
import { PropertyInformation } from 'src/app/common/shared/services/property-information.service';
import { CommonPropertyInformation } from '../shared/services/common-property-information.service';
import moment from 'moment';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-night-audit',
  templateUrl: './night-audit.component.html',
  styleUrls: ['./night-audit.component.scss'],
  providers: [NightAuditBusiness],
  encapsulation: ViewEncapsulation.None
})
export class NightAuditComponent implements OnInit {
  dates: DayEndDateInfo;
  captions: any;
  nightAuditForm: UntypedFormGroup;
  scheduleButton: ButtonValue;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  isToggleEnabled: boolean = false;
  selectTimeInputs: AgTimeConfig;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  dialogData: any;
  updateSchedule: boolean = false;
  distributionData: { id: number; value: string; viewValue: string; }[];
  templateData: { id: number; value: string; viewValue: string; eventIds: number[] }[];
  selectedDistributionOption: any;
  selectedTemplateOption: any;
  selectedConfigTime: Date;
  configTime: Date;
  configId: number = 0;
  productId: number = 0;
  nightAudit: NightAuditConfig;
  nightAuditSchedule: NightAuditSchedule;
  timeChanged: boolean = false;
  timeFormat: number;
  currSysDate: Date = this.commonPropertyInfo.CurrentDate;
  newSysDate: Date = this.commonPropertyInfo.CurrentDate;

  constructor(private utilities: CommonUtilities,private business: NightAuditBusiness, private localization: Localization, private fb: UntypedFormBuilder,
    public dialog: MatDialog, private propertyInfo: PropertyInformation,private commonPropertyInfo: CommonPropertyInformation) {
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.captions = this.localization.captions;
    this.timeFormat = this.localization.getTimeFormat();
  }
  ngOnInit(): void {
    this.initialize();
    this.nightAuditForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(_res => {
      this.saveButton.disabledproperty = !(this.nightAuditForm.valid && this.nightAuditForm.dirty);
      this.saveButton = { ...this.saveButton };
    });
    this.newSysDate.setDate(this.currSysDate.getDate() + 1);
    this.LoadData();
  }

  initialize() {
    this.nightAuditForm = this.fb.group({
      enableAutoDateRoll: this.isToggleEnabled,
      selectTime: ['', Validators.required]
    })
    this.scheduleButton = {
      type: 'secondary',
      label: this.captions.btn_schedule,
      disabledproperty: false
    };
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    }
    this.selectTimeInputs = {
      className: 'pt-2',
      form: this.nightAuditForm,
      formControlName: 'selectTime',
      placeHolder: this.captions.lbl_setAutoDateRollTime,
      isTimeRequired: true,
      automationId: 'Txt_nightAudit_tog'
    };

  }

  async LoadData() {
    this.utilities.ToggleLoader(true)
    const data = await this.business.getDistrubitionandTemplateData();
    this.distributionData = [];
    this.templateData = [];
    data[0].forEach(d => this.templateData.push(
      {
        id: d.id,
        value: d.id.toString(),
        viewValue: d.templateName,
        eventIds: d.eventIds
      }));

    data[1].forEach(d => this.distributionData.push(
      {
        id: d.id,
        value: d.id.toString(),
        viewValue: d.distributionName
      }));

    this.nightAudit = await this.business.GetNightAuditConfig(this.productId);
    if (this.nightAudit) {
      this.configTime = new Date(this.nightAudit.configTime);
      this.nightAuditSchedule = await this.business.GetNightAuditSchedule(this.productId);
      if (this.nightAuditSchedule) {
        this.assignScheduleData();
        this.assignTemplateAndDistribution();
      }
      else {
        this.isToggleEnabled = true;
      }

      this.configId = this.nightAudit.id;
      this.nightAuditForm.controls.enableAutoDateRoll.setValue(true);
      this.nightAuditForm.controls.selectTime.setValue(this.localization.getTime(this.configTime, this.timeFormat));

    }
    else{
      this.nightAuditForm.controls.enableAutoDateRoll.setValue(false);      
      this.onToggleChange([false]);
    }
    this.utilities.ToggleLoader(false)
  }

  assignScheduleData() {

    this.dialogData = {
      distributionList: this.nightAuditSchedule.reminderDistributionId,
      startDate: new Date(this.nightAuditSchedule.scheduleStartDate),
      endDate: new Date(this.nightAuditSchedule.scheduleEndDate),
      template: this.nightAuditSchedule.reminderTemplateId,
      successdistributionList: this.nightAuditSchedule.notifySuccessDistributionId,
      failuredistributionList: this.nightAuditSchedule.notifyFailureDistributionId,
      successtemplate: this.nightAuditSchedule.notifySuccessTemplateId,
      failuretemplate: this.nightAuditSchedule.notifyFailureTemplateId,
      hours: this.nightAuditSchedule.hours,
      minutes: this.nightAuditSchedule.minutes,
      successStatus: this.nightAuditSchedule.notifySuccessEnabled,
      failureStatus: this.nightAuditSchedule.notifyFailureEnabled
    }
  }

  onToggleChange(eve) {
    this.isToggleEnabled = eve[0];
    if (!this.isToggleEnabled) {
      this.dialogData = null;
    }
    else {
      this.assignScheduleData();
      this.assignTemplateAndDistribution();
    }
    this.saveButton.disabledproperty = !this.nightAuditForm.valid;
  }

  async save() {
    this.saveButton.disabledproperty = true;
    if (this.isToggleEnabled || this.dialogData) {
      if (this.timeChanged) {
        if (this.configId > 0) {
          this.nightAudit.configTime = this.localization.convertDateTimeToAPIDateTime(this.selectedConfigTime);
        }
        else {
          this.nightAudit = {
            id: 0,
            configTime: this.localization.convertDateTimeToAPIDateTime(this.selectedConfigTime),
            createdBy: 0,
            createdDateTime: this.propertyInfo.CurrentDateISO
          };
        }
        this.configTime = this.selectedConfigTime;
        let result = await this.business.SaveNightAuditConfig(this.nightAudit, this.productId);
        if (result) {
          if (result.id) {
            this.nightAudit = result;
            this.configId = result.id;
          }

        }
      }

      if (this.dialogData || this.updateSchedule) {
        await this.SaveScheduleConfig();
      }

    }
    else {
      await this.business.RemoveNightAuditConfig(this.configId, this.productId).then(result => {
        if (result) {
          this.saveButton.disabledproperty = true;
          this.configId = 0;
          this.nightAudit = null;
          this.nightAuditSchedule = null;
          this.dialogData = null;
          this.nightAuditForm.controls.selectTime.setValue('');
          this.nightAuditForm.controls.enableAutoDateRoll.setValue(this.isToggleEnabled);
          this.isToggleEnabled = false;
          this.nightAuditForm.reset();
        }
      });

    }
  }

  async cancel() {
    if(this.nightAuditForm.dirty){
      this.initialize();
      await this.LoadData();
      this.saveButton.disabledproperty = true; 
    }   
  }
  async schedule() {
    this.dialog.open(ScheduleEmailComponent, {
      width: '800px',
      height: '650px',
      maxWidth: '800px',
      data: {
        distributionData: this.distributionData,
        templateData: this.templateData
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async res => {
      this.dialogData = res.form?.value;
      if (this.dialogData) {
        this.saveButton.disabledproperty = !this.nightAuditForm.valid;
        this.assignTemplateAndDistribution();
      }
      this.isToggleEnabled = false;

    });
  }

  assignTemplateAndDistribution() {
    this.distributionData.forEach(x => {
      if (this.dialogData?.distributionList) {
        if (x.id == this.dialogData?.distributionList) {
          this.selectedDistributionOption = x.viewValue
        }
      }
    })
    this.templateData.forEach(x => {
      if (this.dialogData?.template) {
        if (x.id == this.dialogData?.template) {
          this.selectedTemplateOption = x.viewValue
        }
      }
    })
  }

  async SaveScheduleConfig() {
    if (this.dialogData) {
      let successdistributionId: number = 0, failuredistributionId: number = 0, successtemplateId: number = 0, failuretemplateId: number = 0;
      let successEnabled = false, failureEnabled = false;
      if (this.dialogData.successStatus) {
        successEnabled = true;
        successdistributionId = this.dialogData.successdistributionList;
        successtemplateId = this.dialogData.successtemplate;
      }
      if (this.dialogData.failureStatus) {
        failureEnabled = true;
        failuredistributionId = this.dialogData.failuredistributionList;
        failuretemplateId = this.dialogData.failuretemplate;
      }

      let totalminute: number = (this.dialogData.minutes + (this.dialogData.hours * 60));
      let scheduleDate = this.configTime;
      if (scheduleDate < this.dialogData.startDate) {
        scheduleDate = this.localization.combineDateAndTime(this.dialogData.startDate, this.localization.getTime(this.configTime, 24))
      }
      scheduleDate.setMinutes(scheduleDate.getMinutes() - totalminute);
      let scheduleId: number = 0;
      if (this.nightAuditSchedule?.id > 0) {
        scheduleId = this.nightAuditSchedule.id;
      }

      this.nightAuditSchedule = {
        id: scheduleId,
        reminderDistributionId: this.dialogData.distributionList,
        reminderTemplateId: this.dialogData.template,
        scheduleStartDate: this.localization.convertDateTimeToAPIDateTime(this.dialogData.startDate),
        scheduleEndDate: this.localization.convertDateTimeToAPIDateTime(this.dialogData.endDate),
        notifyFailureDistributionId: failuredistributionId,
        notifyFailureTemplateId: failuretemplateId,
        notifySuccessDistributionId: successdistributionId,
        notifySuccessTemplateId: successtemplateId,
        notifyFailureEnabled: failureEnabled,
        notifySuccessEnabled: successEnabled,
        sendAt: this.localization.convertDateTimeToAPIDateTime(scheduleDate),
        createdBy: 0,
        createdDateTime: this.propertyInfo.CurrentDate,
        hours: this.dialogData.hours,
        minutes: this.dialogData.minutes,
        isActive: true
      };

      var result = await this.business.SaveNightAuditScheduleConfig(this.nightAuditSchedule, this.productId);
      if (result) {
        if (result.id) {
          this.nightAuditSchedule = result;
        }
        else {
          this.updateSchedule = false;
        }

        this.timeChanged = false;
      }
    }
    else {
      await this.business.RemoveNightAuditScheduleConfig(this.nightAuditSchedule.id, this.productId);
      this.nightAuditSchedule = null;
      this.updateSchedule = false;
    }
  }

  timeChange(eve) {

    let fromTime = moment(eve[0].value.selectTime, this.localization.timeFormat).format("HH:mm");
    if (!this.nightAudit || fromTime != this.localization.getTime(this.configTime, 24)) {
      this.selectedConfigTime = this.localization.combineDateAndTime(this.propertyInfo.CurrentDate, fromTime);
      this.saveButton.disabledproperty = !this.nightAuditForm.valid;
      this.timeChanged = true;
    }
  }

  delete(eve) {
    this.dialogData = null;
    this.isToggleEnabled = true;
    this.saveButton.disabledproperty = !this.nightAuditForm.valid;
    this.updateSchedule = true;
  }
  edit(eve) {
    this.dialog.open(ScheduleEmailComponent, {
      width: '800px',
      height: '650px',
      maxWidth: '800px',
      data: {
        formData: eve,
        mode: 'edit',
        distributionData: this.distributionData,
        templateData: this.templateData
      },
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async res => {
      this.updateSchedule = true;
      this.dialogData = res.form.value;
      if (this.dialogData) {
        this.saveButton.disabledproperty = !this.nightAuditForm.valid;
        this.assignTemplateAndDistribution();
      }
    });
  }
}
