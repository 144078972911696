import { Component, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailReportControl } from '../../../basereport/base-report.component';
@Component({
  selector: 'app-retail-sales-by-subcategory',
  templateUrl: './sales-by-subcategory.component.html',
  styleUrls: ['./sales-by-subcategory.component.scss']
})
export class RetailSalesBySubcategoryComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  SubCategoryFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  public outlets: any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  selectedDropdownType = '';
  valuesSelected: any[];
  allCategoryGroups: any;
  categoryGroupSelected: any[] = [];
  editPatchValue: any;
  isFromEdit = false;
  categoryComponentFilter: any;
  categoryGroups: any[];
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, private dataService: ReportDataService
    , private container: ViewContainerRef) { }

  ngOnInit() {
    this.radioFilter = [{ id: 0, name: this.captions.DetailByRegister }, { id: 1, name: this.captions.SummaryByRegister }, { id: 2, name: this.captions.DetailByOutlet }, { id: 3, name: this.captions.SummaryByOutlet }];
    this.SubCategoryFormGrp = this.fb.group({
      allreportcategory: '',
      reportcategory: new UntypedFormControl(),
      category: new UntypedFormGroup({}),
      itemFilter: 0
    });
    this.formReady.emit(this.SubCategoryFormGrp);
    this.patchValues();
    if (!this.isFromEdit) {
      this.onLoad();
    }

  }

  private async patchValues() {
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if (this.localization.isFromJobScheduler && _parent.edit) {
        this.isFromEdit = true;
        let patchItem = this.editPatchValue;
        let allcategories: any[] = await this.dataService.GetAllCategories();
        this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();
        if (patchItem.CategoryIds != undefined && patchItem.CategoryIds?.length > 0) {
          let categoryGroupIds: any[] = allcategories.filter(x => this.editPatchValue.CategoryIds.includes(x.id)).map(x => x.categoryGroup);
          this.allCategoryGroups = this.allCategoryGroups.filter(x => categoryGroupIds.includes(x.id));
          this.categoryGroupSelected = this.allCategoryGroups;
        }
        if (patchItem.ReportGroupId != undefined && patchItem.ReportGroupId?.length > 0) {
          this.allCategoryGroups = this.allCategoryGroups.filter(x => patchItem.ReportGroupId.includes(x.id));
          this.categoryGroupSelected = this.allCategoryGroups;
        }
        if (patchItem.categoryIds == undefined || patchItem.categoryIds?.length == 0) {
          patchItem.CategoryIds = allcategories.filter(x => this.allCategoryGroups.map(x => x.id).includes(x.categoryGroup)).map(x => x.id);
        }
        this.categoryComponentFilter =
        {
          category: patchItem.CategoryIds,
          subCategory1: patchItem.SubCategory1Ids,
          subCategory2: patchItem.SubCategory2Ids,
          subCategory3: patchItem.SubCategory3Ids,
          subCategory4: patchItem.SubCategory4Ids,
          subCategory5: patchItem.SubCategory5Ids
        };
      }
    }
  }

  private async onLoad() {
    this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();
    this.business.FilterDataSource['allCategoryGroups'] = this.allCategoryGroups;
    
  }

  formInitialized(form: UntypedFormGroup) {
    this.SubCategoryFormGrp.setControl('category', form);
  }


  getFormControlValue(event) {
    if (event[0] === 'reportcategory') {
      this.SubCategoryFormGrp.setControl('reportcategory', event[1]);
      this.SubCategoryFormGrp.setControl('allreportcategory', event[1]);
    }
  }


  getCategoryGroupValue(event) {
    this.categoryGroupSelected = [...event];
  }
}
