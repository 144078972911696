import { Injectable } from '@angular/core';
import { RguestCommunication } from '../common/rguest-communication';
import { HttpClient } from '@angular/common/http';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { AppService } from '../../app-service';
import { Localization } from '../../localization/localization';
import { environment } from 'src/environments/environment';

@Injectable(
    { providedIn: 'root' }
)
export class CommonCommunication extends RguestCommunication {

    constructor(appService: AppService, httpclient: HttpClient, localization: Localization, utilities: CommonUtilities, PropertyInfo: CommonPropertyInformation) {
        super(environment["Common"], httpclient, localization, utilities, PropertyInfo, appService);
    }
}