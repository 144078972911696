<section class="pop-container">
  <div class="pop-head border-top">
    <label class="inner-header pl-4 LW20">{{data.headername | uppercase}}</label>
    <i  [attr.automationId]="'Icn_addVendors_close'" aria-hidden="true" class="material-icons inner-close IC30 float-md-right cursPointer" (click)="onNoClick()">close</i>
  </div>
  <div class="pop-contents p-4">
    <div class="d-flex">
    <form class="search-form" autocomplete="off">
        <mat-form-field class="w-100 spa-searchbox custSearchbox" [floatLabel]="floatLabelNever">
          <input [attr.automationId]="'Srch_addVendors_AddVendorSearch'" type="text" [placeholder]="captions.AddVendorSearch" aria-label="Number" matInput
            [formControl]="searchControl">
          <!-- <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onOptionChange($event)">
            <mat-option class="LW14" *ngFor="let option of filteredOptions | async" [value]="option">
              {{option.vendorCode}} - {{option.vendorName}}
            </mat-option>
          </mat-autocomplete> -->
          <i [attr.automationId]="'Icn_addVendors_clearSearch'" aria-hidden="true"  class="float-right blckClr" [ngClass]="searchControl.value ? 'icon-Cancel cursor': 'icon-Search'"
            (click)="clearSearch($event)"></i>
        </mat-form-field>
    </form>
    <div class="searchArea">
      <mat-checkbox [attr.automationId]="'Chk_addVendors_removeAssignedVendor'" (change)="switchNoVendors($event)" [disabled]="!bodyContentdata || bodyContentdata.length == 0">{{captions.NoAssigning}}</mat-checkbox>
    </div>
    <!-- <button mat-button class="ml-auto" [disabled]="isRemoveDisabled"
    [ngClass]="isRemoveDisabled ? 'spa-primary-disabled' : 'spa-primary-btn-default'"
    (click)="onRemove($event)">{{captions.RemoveVendor}}</button> -->
  </div>
  <div class="add-vendor-container" [ngClass]="'add-vendor-container setHeight'">
      <app-virtual-scroller  #tableComponent [headerOption]="headerOption"  [bodyContentData]="bodyContentdata" [automationId]="'addVendors'"
        [tableOptions]="tableOptions" [searchOptions]="searchText" [isViewMode]="isViewMode" (EmittedData)="emitteddatavalue($event)">
      </app-virtual-scroller>
    </div>
  </div>
  <mat-dialog-actions class="dialog_actions_style">
    <div class="pop-button">
      <button [attr.automationId]="'Btn_addVendors_save'" mat-button class="ml-4" [disabled]="isSaveDisabled"
        [ngClass]="isSaveDisabled ? 'spa-primary-disabled' : 'spa-primary-btn-default'"
        (click)="onSave($event)">{{captions.SAVE}}</button>
      <button [attr.automationId]="'Btn_addVendors_cancel'" mat-button class="spa-button-cancel ml-2" (click)="onCancel($event)">{{captions.CANCEL}}</button>
    </div>
  </mat-dialog-actions>
</section>