<ng-scrollbar track="all">
<div class="class-detail">
 
    <div class="class-header">
      <div class="row m-0">
        <div class="col-lg-12 col-md-12">
          <div class="card">
            <div class="card-body pt-1">
              <div class="content-view">
                <div class="fs-16"> {{'openBooking'| translate }}</div>
                <div class="class-detail__location text-muted">
                  <div *ngFor="let location of locations  | slice:0:(showAll.location ? locations?.length : 1 )"
                    [title]="location">
                    <span class="icon icon-placeholder"></span>
                    {{location}}
                  </div>
                  <div class="class-detail__slots-show-action" *ngIf="locations && locations.length > 1"
                    (click)="showAll.location = !showAll.location">
                    {{showAll.location ? 'Show Less' : '+'+(locations?.length-1)+' '+'more'}}
                  </div>
                </div>
              </div>
              <div class="location-info">
                <div class="text-muted"> <span class="icon-Calender"></span> {{ bookingDetail?.ReservedFor |
                  formatSettingsDate:
                  cs.settings.value.General.DateFormat }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Guest History will display for each booking in upcoming release  -->
        <!-- <div class="col-lg-3 col-md-3">
          <div class="card">
            <div class="card-body pt-1">
          <div class="status-container" *ngIf="dashboardStatus">
            <div class="status-row" *ngFor="let status of dashboardStatus;">
              <div *ngIf="status.Date" class="count">
                <div class="day">{{status.Date.day}} {{ status.Date.day != '-' ? status.Date.year : ''}}</div>
              </div>
              <span *ngIf="!status.Date" class="count">{{status.Count}}</span>
              <label class="text-label text-muted">{{status.Name | translate}}</label>
            </div>
          </div>
          </div>
          </div>
        </div>      -->
      </div>
    </div>


    <div class="booking-pannel">
      <div class="session-details" *ngIf="addonsForActivity.length > 0 && showAddOnIcon">
        <div class="addon-notes" *ngIf="addonsForActivity.length > 0 && showAddOnIcon">
          <div class="addon-container">
            <div class="addon-title"><i class="icon icon-addons"></i> {{'addons' | translate}}</div>
            <div class="addon-description">
              <div class="reservation-notes__notes-details" *ngFor="let addOn of addonsForActivity">
                <div>
                  <span>{{addOn.AddonId | getAddonName : _settings}}<span style="color:red" *ngIf="addOn.IsMandatory">
                      *</span></span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="booking-details" [ngClass]="{'w-100' : !(addonsForActivity.length > 0 && showAddOnIcon)}">
        <div class="attendees">
          <table class="table">
            <thead class="user-card user-card__data-header">
              <tr>
                <th>
                  <div class="user-sno">
                    <!-- <mat-checkbox></mat-checkbox> -->
                  </div>
                </th>
                <th>
                  <div class="user-card__content-view">
                    {{'GuestDetailText' | translate}}
                  </div>
                </th>

                <th>
                  <div>
                    {{'StayInfo' | translate}}
                  </div>
                  
                </th>
                <th>
                  <div>
                    {{'bookingAndConfirmation' | translate}}
                  </div>
                </th>
                <th>
                  <div class="user-card__covers">
                    {{'sizeText' | translate}}
                  </div>
                </th>
                <th>
                  <div class="user-card__timing-header">
                    {{'time' | translate}}
                  </div>
                </th>
                <th>
                  <div class="user-card__payment">
                    {{'paymentText' | translate}}
                  </div>
                </th>
                <th>
                  <div class="user-card__status-col">
                    {{'Status' | translate}}
                  </div>
                </th>
                <th class="table-action">
                  <div class="user-card__actions">
                    {{'actionText' | translate}}
                  </div>
                </th>
                <th>
                  <div class="user-card__delete">

                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="user-card" *ngFor="let attendee of availableOpenBookings">
                <td>
                  <div class="user-sno">
                    <i class="icon-VIP" [ngClass]="{'VIP-selected':attendee?.Contact.IsVip}"></i>
                  </div>
                </td>
                <td>
                  <div class="user-card__content-view" (click)="(attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left && !noActionRequired) ? createOrEditOpenBooking(attendee) : false">
                    <h3 class="user-card__name text-ellipsis" *ngIf="attendee.Contact">
                      {{attendee?.Contact?.FirstName}} {{attendee?.Contact?.LastName || ''}}
                    </h3>
                    <p class="user-card__mobile text-ellipsis">
                      {{attendee?.Contact?.PhoneNumber | phoneNumberFormatter: attendee?.Contact?.CountryId}}
                    </p>
                  </div>
                </td>

                <td>
                  <div>
                    <div   *ngIf="!attendee.RoomNumber  || attendee.RoomNumber.length == 0">   {{'NA' | translate}}</div>
                    <div  *ngIf="attendee.RoomNumber  && attendee.RoomNumber.length > 0" style="cursor: pointer;" [autoClose]="'outside'" container="body"   [ngbPopover]="packageInformation" placement="bottom" (click)="dashboardFunctions.openPackage(attendee)">
                      <p class="mb-0" *ngIf="attendee.CommonPackageInfo == undefined" ><i class="icon-reserve_v1crs" [matTooltip]="'RoomNumber' | translate" ></i> {{ attendee.RoomNumber }}</p>
                      <u *ngIf="attendee.CommonPackageInfo && attendee.CommonPackageInfo.length > 5" >
                        <i class="icon-reserve_v1crs" [matTooltip]="'RoomNumber' | translate" ></i> {{ attendee.RoomNumber }}
                      </u>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="reference-number">
                    <div class="conirmation">
                      {{attendee?.ConfirmationCode}}
                    </div>
                    <div class="reference">
                      {{attendee?.BookingReferenceId}}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="user-card__covers">
                    <span *ngIf="attendee?.Size" [class.overbooked-bg]="attendee?.OverBooked" [matTooltip]="attendee?.OverBooked ? (('OverbookedBy' | translate) + ' ' + attendee?.OverBooked) : attendee.Size"><span class="icon-add-contact"></span> {{ attendee?.Size
                      }}</span>
                  </div>
                </td>
                <td>
                  <div class="user-card__timing">
                    {{ attendee.StartTime | localizedDate : 'LT' }}
                    - {{ attendee.EndTime | localizedDate : 'LT' }}
                  </div>
                </td>
                <td>
                  <div class="user-card__payment">
                    <ng-container *ngIf="(attendee | partyPaymentState) as paymentState">
                      <span *ngIf="paymentState!=='NA'" class="user-card__payment--label payment-{{paymentState}}"
                        container="body" placement="auto" [autoClose]="'outside'" [ngbPopover]="financialStatusPopover"
                        (click)="financialDetails(attendee)">
                        {{paymentState | translate}}
                      </span>
                      <span *ngIf="paymentState=='NA'">
                        {{'NA' | translate}}
                      </span>
                      <!-- <span class="icon icon-information1 ml-2" ></span> -->
                      <!-- <span class="user-card__payment--amount">
                        {{_settings.General.OperationCurrency}}{{paymentDetail.amount | number : '1.2-2'}}
                      </span> -->
                    </ng-container>
                  </div>
                </td>
                <td>
                  <div class="user-card__status-col">
                    <span
                      class="user-card__status user-card__status--{{attendee?.State}}">{{'Status_'+PartyState[attendee.State]
                      | translate}}</span>
                  </div>
                </td>
                <td class="table-action">
                  <div class="user-card__actions">
                    <button *ngIf="attendee.State == PartyState.Pending && !attendee.checkInWithDues" mat-button
                      class="btn btn--checkin" (click)="checkIn(attendee)" [disabled]="noActionRequired">
                      {{'CheckIn' | translate}}
                    </button>
                    <button *ngIf="attendee.checkInWithDues && attendee.State == PartyState.Pending" mat-button
                      [matMenuTriggerFor]="menu" class="btn btn--checkin" [disabled]="noActionRequired"> {{'CheckIn' | translate}} <span
                        class="icon-down-arrow" style="font-size:10px"></span></button>
                    <mat-menu #menu="matMenu"
                      [hidden]="!(attendee.checkInWithDues && attendee.State == PartyState.Pending)">
                      <button mat-menu-item (click)="checkIn(attendee)">{{'CheckIn' | translate}}</button>
                      <button mat-menu-item (click)="checkInWithDues(attendee)">{{'checkInWithDues' |
                        translate}}</button>
                    </mat-menu>
                    <button *ngIf="attendee.State == PartyState.Seated " mat-button
                    [matMenuTriggerFor]="menuForCheckOut" class="btn btn--checkin" [disabled]="noActionRequired"> {{'CheckOut' | translate}} <span
                      class="icon-down-arrow" style="font-size:10px"></span></button>
                    <mat-menu #menuForCheckOut="matMenu">
                    <button mat-menu-item (click)="undoCheckIn(attendee)">{{'Unseat' | translate}}</button>
                    <button mat-menu-item (click)="checkOut(attendee)">{{'CheckOut' | translate}}</button>
                  </mat-menu>
                  </div>
                </td>
                <td>
                  <div class="user-card__cancel">
                    <div class="package-booking user-card__data-align-content"
                      *ngIf="attendee?.PackageName !== null && attendee?.PackageName !== undefined" [matTooltipDisabled]="(attendee.PackageName !== null && attendee.PackageName !== undefined)" matTooltip="{{'packages' | translate}}">
                      <span class="icon-offer-redemption" [matTooltipDisabled]="!(attendee.PackageName !== null && attendee.PackageName !== undefined)" matTooltip="{{attendee?.PackageName}}"></span>
                    </div>
                    <span class="icon icon-note mr-0 user-card__data-align-content" *ngIf="attendee?.Notes?.length > 0" matTooltip="{{'reservationNotes' | translate}}" [autoClose]="'outside'" triggers="manual"
                      container="body" placement="auto" [ngbPopover]="reservationNotesPopOver" #reservationNotePopOver="ngbPopover"
                      (click)="partyService.isChatPopoverOpened ? return : reservationNotePopOver.open();reservationNotes(attendee)"></span>
                      <ng-container *ngIf="(attendee.Contact | partyGuestFieldMapping:  mappedGuestFields) as partyGuestFields">
                        <div class="user-card__data-align-content" *ngIf="partyGuestFields?.length" matTooltip="{{'CustomGuestFields'| translate}}">
                          <span class="icon icon-Group-197 mr-0" [autoClose]="'outside'" container="body" placement="auto" triggers="manual"
                            container="body" [ngbPopover]="customGuestFieldsContainer" #customGuestFieldsPopover="ngbPopover"
                            (click)="partyService.isChatPopoverOpened ? return : customGuestFieldsPopover.open();setCustomGuestField(attendee.Contact)">
                            <!-- <span class="class-detail__profile-icon icon-profile1"></span> -->
                          </span>
                        </div>
                      </ng-container>
                    <div class="user-card__data-align-content" *ngIf="attendee?.BookingContactAddonItems?.length > 0" matTooltip="{{'addons'| translate}}">
                      <span class="icon icon-addons mr-0" [autoClose]="'outside'" container="body" placement="auto" #addOnForBookingPopover="ngbPopover"
                        container="body" triggers="manual" [ngbPopover]="addOnForBooking" 
                        (click)="partyService.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(attendee)">
                        <!-- <span class="class-detail__profile-icon icon-profile1"></span> -->
                      </span>
                    </div>
                    <ng-container *ngIf="!noActionRequired">
                    <chat-info-container class="user-card__data-align-content" [party]="attendee"></chat-info-container>
                  </ng-container>
                    <span class="icon-more user-card__data-align-content" mat-button [matMenuTriggerFor]="moreOptionsReservationTab" [matMenuTriggerData]="{attendee:attendee}"
                    [ngClass]="{'noPointerEvents': partyService.isChatPopoverOpened}"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Additional guest will displayed for each booking in upcoming release  -->
        <!-- <div  class="additional-guest" *ngIf="(additionalGuests) as additionalGuestResult">
          <div class="title">{{'AdditionalGuests' | translate}}</div>
          <div class="attendees">
            <div class="user-card" *ngFor="let attendee of additionalGuestResult">
              <div class="user-card__body">
                <div class="user-card__name-image-view">
                  <div class="intial-image-view" [ngStyle]="{'background-color': '' | randomColor}">
                    {{attendee | guestNameFormatter}}</div>
                </div>
                <div class="user-card__badge" *ngIf="attendee.Size">
                  &#x2B;{{attendee.Size - 1}}
                </div>
                <div class="user-card__content-view">
                  <h3 class="user-card__name text-ellipsis">
                    {{attendee?.FirstName}} {{attendee?.LastName}}
                  </h3>
                  <p class="user-card__mobile text-ellipsis">{{attendee?.PhoneNumber | phoneNumberFormatter:
                    attendee?.CountryId}}</p>
                  <p class="user-card__mobile text-ellipsis">{{attendee?.EmailAddress}}</p>
                </div>
              </div>

            </div>
            <div class="no-attendees" *ngIf="!additionalGuestResult?.length">
              {{'NoGuests' | translate}}
            </div>
          </div>
        </div> -->
      </div>
    </div>


    <ng-template #addOnForBooking>
      <app-add-on-popover [party]="partyWithAddon"></app-add-on-popover>
    </ng-template>

    <ng-template #customGuestFieldsContainer>
      <div>
        <div class="parties-grid__scrollSize">
          <div class="reservation-notes">
            <div class="reservation-notes__header seat-popover-header">
              {{'CustomGuestFields' | translate}}
            </div>
            <div class="reservation-notes__content seat-popover-content">
              <div class="reservation-notes__notes-details seat-border-color list-item"
                *ngFor="let guestField of customGuestFieldsForBooking">
                <label class="guest-field-name">
                  {{guestField.CustomFieldName}}
                </label>
                <span>: {{guestField.CustomFieldValue}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

</div>
</ng-scrollbar>
<div>
  <ng-template #financialStatusPopover>
    <app-financial-status-popup [partyData]="financialPartyData"></app-financial-status-popup>
  </ng-template>
</div>

<!-- Reservation Notes popover -->
<div>
  <ng-template #reservationNotesPopOver>
    <div>
      <div class="parties-grid__scrollSize">
        <div class="reservation-notes">
          <div class="reservation-notes__header seat-popover-header">
            {{'reservationNotes' | translate}}
          </div>
          <div class="reservation-notes__content seat-popover-content">
            <div class="reservation-notes__notes-details seat-border-color"
              style="justify-content: space-between;gap:20px" *ngFor="let notes of notesReservation">
              <div>{{notes.Text}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<mat-menu #moreOptionsReservationTab="matMenu">
  <ng-template matMenuContent let-attendee="attendee">
    <button mat-menu-item *ngIf="attendee?.WaiverFormURL != null" (click)="showPartyWaiverForm(attendee)">
      {{'waiverForm' | translate}}</button>
    <button mat-menu-item (click)="showPartyAuditLog(attendee)"> {{'auditlog' | translate}}</button>
    <ng-container *ngIf="!noActionRequired">
    <button mat-menu-item *ngIf="isRetailEnabled && attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left" (click)="moveToShopScreen(attendee)">{{'shop' |
      translate}}</button>
    <button mat-menu-item *ngIf="attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left" (click)="createOrEditOpenBooking(attendee)">{{'edit' | translate}}</button>
    <button mat-menu-item *ngIf="attendee.State == PartyState.Pending" (click)="cancel(attendee)">{{'Cancel' |
      translate}}</button>
    </ng-container>
      <button [hidden]="true" mat-menu-item (click)="dashboardFunctions.PrintWristBand(attendee)"> {{'PrintWristBand' |
        translate}}</button>
  </ng-template>
</mat-menu>


<ng-template #packageInformation>
  <app-package-information [packageInfo]="dashboardFunctions.currentPackageInfo"></app-package-information>
</ng-template>