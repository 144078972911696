import { SlotType } from '@constants/commonenums';
import { SlotDTO } from '@models/InputReservation';
import { ManualSlot } from '@models/ManualSlotDTO';
import { Utilities } from '@utilities/utilities';

export abstract class ManualSlotMapper {
  public static map(manualSlot: ManualSlot) {
    const slot = new SlotDTO();
    this.mapToSlotDTO(manualSlot, slot);
    return slot;
  }

  private static mapToSlotDTO(from: ManualSlot, to: SlotDTO) {
    to.Id = from.Id;
    //to.Type = (from.DefaultSlotId && from.DefaultSlotId > 0) ? SlotType.DefaultSlot : SlotType.ActualSlot;
    to.Type = SlotType.ActualSlot;
    //to.RestaurantId = from.RestaurantId;
    to.PartyId = from.PartyId;
    to.DateTime = Utilities.Date(from.DateTime).format('YYYY-MM-DDTHH:mm:ss');
    to.OriginalMinPartySize = from.MinPartySize;
    to.OriginalMaxPartySize = from.MaxPartySize;
    to.LimitedMinPartySize = from.MinPartySize;
    to.LimitedMaxPartySize = from.MaxPartySize;
    to.IsWebReservable = from.IsWebReservable;
    to.IsDisabled = from.IsDeleted;
    to.SeatingAreaId = from.SeatingAreaId;
    to.LockedAt = from.LockedAtInternal;
    to.LockExpiresAt = from.LockExpiresAtInternal;
    to.ManualSlotType = from.TypeInternal;
    to.UpdatedAt = from.UpdatedAt;
  }
}
