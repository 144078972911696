import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { CacheService } from "@app/core/services/cache.service";
import { buttonTypes } from "@app/shared/constants/commonenums";
import { DynamicFormComponent } from "@app/shared/dynamicform/dynamic-form/dynamic-form.component";
import { ButtonValue, FieldConfig } from "@app/shared/dynamicform/models/field-config.interface";
import { SettingsDTO } from "@app/shared/models/RestaurantDTO";
import { FacadeService } from "@app/shared/services/facade.service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
    selector: 'profile-select',
    templateUrl: './profile-select.component.html',
    styleUrls: ['./profile-select.component.scss']
})
export class ProfileSelectComponent implements OnInit, AfterViewInit, OnDestroy {

    @Output() onProfileSelect: EventEmitter<any> = new EventEmitter();
    @ViewChild('form') searchForm: DynamicFormComponent;
    guestConfig: FieldConfig[];
    subscription: Subscription = new Subscription();
    _settings: SettingsDTO = {} as SettingsDTO;
    buttonSearch: ButtonValue;
    guestContacts: any = [];
    isMembershipAvailable : boolean = false;

    constructor(public facadeService: FacadeService, public cs: CacheService, private ts: TranslateService) {
        this.subscription.add(cs.settings.subscribe(sett => {
            this._settings = sett;
            this.isMembershipAvailable = this._settings.General.UseMembershipNumber;
        }));
    }

    ngOnInit(): void {
        this.guestConfig = [
            {
                type: 'input',
                name: 'firstName',
                inputType: 'text',
                label: 'guestBookDetailsFN',
                class: 'field-line-align'
            },
            {
                type: 'input',
                name: 'lastName',
                inputType: 'text',
                label: 'guestBookDetailsLN',
                class: 'field-line-align'
            },

            {
                type: 'input',
                name: 'mobile',
                inputType: 'text',
                label: 'guestBookDetailsMobile',
                class: 'field-line-align',
                directive: 'numberOnly',
                showErrorText: true,
                isPhoneNumber: true,
            },
            {
                type: 'input',
                name: 'email',
                inputType: 'text',
                label: 'guestBookDetailsEmail',
                class: 'field-line-align',
                validation: [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')],
                showErrorText: true
            },
            {
                type: 'input',
                name: 'membershipCode',
                inputType: 'text',
                label: this._settings.General.MembershipNumberName,
                class: 'field-line-align'
            }
        ];
        this.buttonSearch = {
            type: buttonTypes.actionPrimarySmall,
            label: 'searchText',
            customclass: 'reservation-guest__search-btn',
            disbaledproperity: true
        };
    }

    ngAfterViewInit(): void {
        this.subscription.add(this.searchForm.form.valueChanges.subscribe(val => {
            // this.guestBookService.searchStringForNewGuest = val;
            this.buttonSearch.disbaledproperity = true;
            if (val.firstName || val.lastName || val.mobile || val.email || val.membershipCode) {
                this.buttonSearch.disbaledproperity = false;
            }
        }))
    }

    search(event) {
        var searchValue = this.searchForm.form.value;
        this.searchContactsfromCGP(searchValue.firstName ? searchValue.firstName : "", searchValue.lastName ? searchValue.lastName : "", searchValue.mobile ? searchValue.mobile : "", searchValue.email ? searchValue.email : "", searchValue.membershipCode ? searchValue.membershipCode : "");
    }

    searchContactsfromCGP(firstName?, lastName?, phoneNumber?, emailAddress?, membershipCode?) {
        this.subscription.add(this.facadeService.getContactExSuggestion(firstName, lastName, phoneNumber, emailAddress, membershipCode).subscribe((data) => {
            if (data) {
                this.guestContacts = [];
                this.guestContacts = this.guestContacts.concat(data);
            }
        }));
    }

    getGuestDetails(data) {
        this.onProfileSelect.emit(data);
        this.guestContacts = [];
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}