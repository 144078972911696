<div class='header_title'>
  <app-dialog-header [title]="header" (closeDialog)="cancel($event)"></app-dialog-header>
</div>
<section class='configure-date-time newVersionWrapper' [formGroup]='discountSetupForm'>
<div class="dateTime_Wrap formarray-wrapper overflow-auto">
    <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--1">
      <input matInput placeholder="{{captions.retailsetup.discountTypes}}" formControlName="discountName"
         autocomplete="off" inputtype = "nospecailchar" maxlength="128" minlength="3">
      <mat-error
        *ngIf="discountSetupForm.controls['discountName'].hasError('required') && !discountSetupForm.controls['discountName'].valid">
        {{captions.retailsetup.MissingDiscountType}}
      </mat-error>
      <mat-error *ngIf="discountSetupForm.controls['discountName'].errors?.minlength">
        {{captions.retailsetup.err_minLength}}
      </mat-error>
    </mat-form-field>
    <div>
      <mat-form-field class="ag_form-control--1" [floatLabel]="floatLabel">
        <input matInput type="number"
          retailinputtype="onlynumber" inputtype="nonnegative,nodecimal" placeholder="{{captions.retailsetup.ListOrder}}"
          formControlName="listOrder" min="1" max="9999">
        <mat-error *ngIf="discountSetupForm?.controls['listOrder']?.hasError('max')" [attr.LiteralID]="err_max">
          {{captions.err_max}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="mt-2 mb-3">
        <div><label class="LW12 pr-2 active-label label-color form-label-normal">{{captions.lbl_active}}</label></div>
        <app-retail-toggle-switch formControlName="activetoggle"class="toggle-switch"></app-retail-toggle-switch>
    </div>
    <div class="mt-2 mb-3">
      <div><label class="LW12 pr-2 active-label label-color form-label-normal">{{captions.lbl_enable_Rules}}</label></div>
      <app-retail-toggle-switch formControlName="enableRules" class="toggle-switch"></app-retail-toggle-switch>
    </div>
    <div *ngIf="this.discountSetupForm.controls.enableRules.getRawValue()">
    <div class="dateRange_section">
        <h4 class="ag_group--headers ag_display--flex ruleLabel">{{captions.lbl_rules}}
            <span class="mr-4 clear-all ag_cursor--pointer ag_ml--auto" (click)="addDayRange()">{{captions.lbl_addDiscountTimeframe}}</span>
            <span *ngIf="discountSetupForm.get('rule')['controls'].length > 0" class="clear-all ag_cursor--pointer" (click)="clearAllRules()">{{captions.lbl_clearAll}}</span>
          </h4> 
        <mat-accordion class="setup_Accord" multi formArrayName="rule" *ngFor="let dayRange of discountSetupForm.get('rule')['controls']; let i = index;let first = first;let last=last">
            <mat-expansion-panel #panel [expanded]="true" hideToggle id="{{i}}" [formGroupName]="i">
                <mat-expansion-panel-header [collapsedHeight]="'60px'" class="header_hover">
                    <div class="range mt-2">
                    <i *ngIf="panel.expanded" class="icon-filled-up-arrow1 mt-1 mr-3"></i>
                    <i *ngIf="!panel.expanded" class="icon-filled-down-arrow mt-sm-n1 mr-3"></i>
                      <mat-form-field class="ag_form-control--1 dayRange d-inline-block date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
                        <mat-label>{{captions.lbl_startDate}}</mat-label>
                        <input formControlName="startDate" class="" matInput dateRestricter [min]="getMinDate(dayRange.controls.startDate.value,dayRange.controls.minstartdate.value)" [max]="dayRange.controls.maxstartdate.value"
                        (dateChange)="startDateChanged(i, 'startDate', 'endDate')"
                          [matDatepicker]="picker1" [placeholder]="placeHolderFormat" name="date">
                        <mat-datepicker #picker1></mat-datepicker>
                        <i aria-hidden="true" matSuffix class="icon-Calender" (click)="picker1.open();$event.stopPropagation();"></i>
                        <mat-error *ngIf="dayRange.controls.startDate.hasError('required')">
                          {{captions.err_MissingStartDate}}</mat-error>
                        <mat-error *ngIf="dayRange.controls.startDate.hasError('overlap')">
                          {{captions.err_rateoverlapDateRange}}</mat-error>
                          <mat-error *ngIf="dayRange.controls.startDate.hasError('greater')">
                            {{captions.retailsetup.InvalidStartDate}}</mat-error>
                            <mat-error *ngIf="dayRange.controls.startDate.hasError('isinvalid')">
                              {{captions.err_invalidDate}}</mat-error>
                      </mat-form-field>
                      <mat-form-field class="ag_form-control--1 dayRange d-inline-block date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
                        <mat-label>{{captions.lbl_endDate}}</mat-label>
                        <input formControlName="endDate" class="" matInput [matDatepicker]="picker2" [min]="dayRange.controls.startDate.value" dateRestricter [max]="dayRange.controls.maxenddate.value"
                        (dateChange)="endDateChanged(i, 'startDate', 'endDate')" [placeholder]="placeHolderFormat" name="date">
                        <mat-datepicker #picker2></mat-datepicker>
                        <i aria-hidden="true" matSuffix class="icon-Calender" (click)="picker2.open();$event.stopPropagation();"></i>
                        <mat-error *ngIf="dayRange.controls.endDate.hasError('required')">
                          {{captions.err_MissingEndDate}}</mat-error>
                        <mat-error *ngIf="dayRange.controls.endDate.hasError('overlap')">
                          {{captions.err_rateoverlapDateRange}}</mat-error>
                          <mat-error *ngIf="dayRange.controls.endDate.hasError('isinvalid')">
                            {{captions.err_invalidDate}}</mat-error>
                      </mat-form-field>
                    <!-- <span class="ml-3 ag_font--bold" >{{captions.lbl_daysBefore}}</span> -->
                    <div (click)="$event.stopPropagation();"
                      [ngClass]="{'ag_section--disable':discountSetupForm.get('rule')['controls'].length==1}"
                      [attr.automationId]="'Icn_Rule_dualDelete'" class="ag_ml--auto">
                      <a class="icon-delete" title="{{captions.common.Delete}}" (click)="deleteDayRange(i)"></a>
                    </div>
                  </div>
                  </mat-expansion-panel-header>
                  <div  class="ag_display--flex mt-4 align-items-center" formArrayName="rulesByRange" *ngFor="let rule of dayRange.get('rulesByRange')['controls']; let j = index;let first = first;let last=last">
          <div class="formarray-wrapper" [formGroupName]="j" [ngClass]="[rule.valid ? 'valid' : 'notValid']">
              <div class="ag_display--flex-wrap custom-aligns">
                <mat-form-field class="dropdwnWidth never-label" [floatLabel]="floatLabel">
                  <mat-select [placeholder]="captions.lbl_period" [attr.automationId]="'Dd_Rule_dualperiod'" formControlName="rule_period" (selectionChange) ="rule_periodChanged($event.value,j,i,false,rule)">
                    <mat-option *ngFor="let data of rule.controls.rule_periodTypes.value" [value]="data.id" [disabled]="data.isDisabled"> 
                      {{data.viewValue}}
                    </mat-option>
                  </mat-select>    
                  <mat-error
                  *ngIf="rule.controls.rule_period.hasError('required')">
                  {{captions.missingPeriod}}</mat-error>          
                </mat-form-field>
                <div class='' *ngIf="rule.controls.rule_period.value!=2">
                  <mat-form-field [attr.automationId]="'Txt_Rule_dualuStartDate'" [floatLabel]="floatLabel" class="ag_time-picker date-picker-width date-width never-label">
                      <input class='ag_text--uppercase' [disabled]=""
                          [ngxTimepicker]="timepicker" matInput  [placeholder]="captions.lbl_startTime"
                          formControlName="rule_startTime" autocomplete="off"
                          [format]="timeformat" [readonly]="true" 
                          [min]="rule.controls.rule_minStartTime?.value"
                          [max]="rule.controls.rule_maxStartTime?.value">
                      <ngx-material-timepicker-toggle matSuffix class="time-picker" [for]="timepicker">
                          <i aria-hidden="true" class="icon-clock cursor"
                              ngxMaterialTimepickerToggleIcon></i>
                      </ngx-material-timepicker-toggle>
                      <ngx-material-timepicker #timepicker enableKeyboardInput="true" [cancelBtnTmpl]="cancelTimeBtn" [preventOverlayClick]="true"
                          [confirmBtnTmpl]="confirmBtn" [defaultTime]=""  (closed)="timeChanged($event,j , 'fromTime','rule',i)"
                          >
                      </ngx-material-timepicker>
                      <ng-template #cancelTimeBtn ><button class="button--tertiary" type="button"
                              [attr.LiteralID]="'btn_cancel'" (click)="cancelTimePopUp($event)">{{captions.btn_cancel}}</button>
                      </ng-template>
                      <ng-template #confirmBtn ><button class="ag_button--primary button--primary"
                              type="button" [attr.LiteralID]="'btn_ok'" (click)="ok($event)">{{captions.btn_ok}}</button>
                      </ng-template>
                      <mat-error
                          *ngIf="rule.controls.rule_startTime.hasError('required')">
                          {{captions.startTimeError}}</mat-error>
                      <mat-error
                          *ngIf="rule.controls.rule_startTime.hasError('invalid')">
                            {{captions.lbl_overlapRule}}</mat-error>
                  </mat-form-field>
              </div>
              <div class='' *ngIf="rule.controls.rule_period.value!=2">
                <mat-form-field [attr.automationId]="'Txt_Rule_dualuEndDate'" [floatLabel]="floatLabel" class="ag_time-picker date-picker-width date-width never-label">
                    <input class='ag_text--uppercase' [disabled]=""
                        [ngxTimepicker]="timepicker1" matInput [placeholder]="captions.lbl_endTime"
                        formControlName="rule_endTime" [readonly]="true" 
                        [format]="timeformat" autocomplete="off"
                        [min]="rule.controls.rule_minEndTime?.value"
                        [max]="rule.controls.rule_maxEndTime?.value">
                    <ngx-material-timepicker-toggle matSuffix class="time-picker" [for]="timepicker1">
                        <i aria-hidden="true" class="icon-clock cursor"
                            ngxMaterialTimepickerToggleIcon></i>
                    </ngx-material-timepicker-toggle>
                    <ngx-material-timepicker #timepicker1 enableKeyboardInput="true" [cancelBtnTmpl]="cancelTimeBtn" [preventOverlayClick]="true"
                        [confirmBtnTmpl]="confirmBtn" [defaultTime]=""  (closed)="timeChanged($event,j , 'toTime','rule',i)"
                        >
                    </ngx-material-timepicker>
                    <ng-template #cancelTimeBtn ><button class="button--tertiary" type="button"
                      [attr.LiteralID]="'btn_cancel'" (click)="cancelTimePopUp($event)">{{captions.btn_cancel}}</button>
                    </ng-template>
                    <ng-template #confirmBtn ><button class="ag_button--primary button--primary"
                            type="button" [attr.LiteralID]="'btn_ok'" (click)="ok($event)">{{captions.btn_ok}}</button>
                    </ng-template>
                    <mat-error
                          *ngIf="rule.controls.rule_endTime.hasError('required')">
                          {{captions.endTimeError}}</mat-error>
                    <mat-error
                          *ngIf="rule.controls.rule_endTime.hasError('invalid')">
                            {{captions.lbl_overlapRule}}</mat-error>
                </mat-form-field>
            </div>
                <div class="ag_display--flex">
                  <label class="ag_form--label mr-2">{{captions.lbl_applyForAllDays}}</label>
                  <ui-switch class="LW14 switch-toggle" size="small" [attr.automationId]="'Tog_Rule_dualapplyForallDays'" (change)="onToggleChange($event,rule,'rule_customizeAllDays','rule_period','rule_isAddDisable','rule','rule_startTime','rule_endTime',j,'rule_periodTypes','rule_applyForAllDays',i)" 
                  [checkedLabel]="captions.checkedLable" [uncheckedLabel]="captions.uncheckedLable" formControlName="rule_applyForAllDays"></ui-switch>
                </div>
                <div class="ml-4">
                  
                  <div class="ag_display--flex-wrap" >
                    <div *ngFor="let day of rule.controls.rule_customizeAllDays?.value; let ind = index" [ngClass]="[day.isClicked ? 'clicked' : 'notClicked',day.isDisabled ? 'ag_section--disable' : '']" 
                    (click)="onDayClick(ind,rule,'rule_customizeAllDays','rule_applyForAllDays','rule_period','rule_isAddDisable',j,'rule','rule_startTime','rule_endTime','rule_periodTypes',i)" 
                    class="customBtn mr-1 ag_cursor--pointer">
                      {{day.value}}
                    </div>
                  </div>
                  
                </div>
                <div
                  [ngClass]="{'ag_section--disable': (!rule.valid)||(rule.controls.rule_isAddDisable.value==true)||(rule.controls.rule_period.value==discountTypeRules.Week)}"
                  [attr.automationId]="'Icn_Rule_dualCopy'" class="ag_ml--auto">
                  <a class="icon-copy" title="{{captions.common.Copy}}" (click)="copyRules(j,i)"></a>
                </div>
    
              </div>
            </div>
            <div class="marTop">
              <span *ngIf="last" class="icon-Plus" [attr.automationId]="'Icn_Rule_dualPlusArray'" [ngClass]="{'ag_section--disable': (discountSetupForm.get('rule')['controls'].length==1) && (!rule.valid) || (!rule.valid) || (rule.controls.rule_isAddDisable.value==true) }" (click)="addDualRules(i,j)"></span>
              <span *ngIf="!(first && last)" class="icon-Minus" [attr.automationId]="'Icn_Rule_dualMinusArray'" [ngClass]="{'ag_section--disable':dayRange.get('rulesByRange')['controls'].length==1}" (click)="removeRules(j,i)"></span>
            </div>
         </div>
            </mat-expansion-panel>
            <div *ngIf="!last" class="border-line mb-4"></div>
        </mat-accordion>
    </div>
</div>
</div>
<mat-dialog-actions class="dialog-container__actions pt-3">
    <app-button [buttontype]='saveButton' [attr.automationId]="'Btn_Rule_save'" (valueChange)='save($event)'></app-button>
    <app-button [buttontype]='cancelButton' [attr.automationId]="'Btn_Rule_cancelBtn'" (valueChange)='cancel($event)'></app-button>
</mat-dialog-actions>
</section>