<div class="CustomDataTable CustomDataTable-Theme" id="table_{{SelectedSettingId}}" (window:resize)="viewCheckedFlag = false">
  <form class="w-100 h-100" [formGroup]="table">
    <div class="page-header" id="pageHeader" (window:resize)="calculateWidth()">
      <mat-form-field *ngIf="options ? options[0].Searchable : false" class="spa-searchbox searchpt custSearchbox"
        floatLabel="floatLabelNever" id="searchInput">
        <input #tableInput matInput autocomplete="off" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
          type="text" placeholder="{{captions.common.SearchBy}} {{PlaceHoldertext}}" value="" (keypress)="currentPage=1"
          (ngModelChange)="viewCheckedFlag=false;calculateWidth()">
        <i aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor"
          (click)="searchText='';viewCheckedFlag = false"></i>
      </mat-form-field>

      <div class="search-container pb-1 d-inline-block example-margin" *ngIf="InactiveTherapists || ChkInactiveService">
        <div class="yes-col toggle-section" *ngIf="InactiveTherapists">
          <label class="LW12 label-color">{{captions.common.ShowInactiveTherapist}}</label>
          <app-retail-toggle-switch [formControl]="inActiveTherapist" (changeToggleEvent)="InactiveTherapist($event)">
          </app-retail-toggle-switch>
        </div>
        <div class="yes-col toggle-section" *ngIf="ChkInactiveService">
          <label class="LW12 label-color">{{captions.common.ShowInactiveService}}</label>
          <app-retail-toggle-switch [formControl]="inActiveService" (changeToggleEvent)="InactiveService($event)">
          </app-retail-toggle-switch>
        </div>
      </div>
      <div class="align-items-center pt-1 ml-auto table-toggle-switches" *ngIf="showToggle">
        <span class="LW14 pr-2 display-text">{{toggleDisplayText}}</span>
        <ui-switch class="LW14 switch-toggle" size="small" checkedLabel={{localization.captions.common.Yes}}
          uncheckedLabel={{localization.captions.common.No}} (change)="sliderChange($event)"></ui-switch>
      </div>


      <!--- end -->
    </div>



    <div class="fixed-CommissionSetup-container" id="fixed-CommissionSetup-container"
      [ngClass]="{'noSort': bodyArray.length < 1}">     
      <label *ngIf="functionalities[ShowNoOfRecordInGrid]" class="ag_w--100 total-count-label text mr-2">
        {{totalMsg}} </label>
      <div class="w-100 grid-table h-100" id="SPACustomTable">
        <virtual-scroller #scroll [items]="(bodyArray | filterPipe: searchText : hdrkeyArray)"
          style="height: calc(100% - 5px)" (vsEnd)="scrollEndHandler($event)" [ngClass]="{'hideScroll':hideScroll}">
          <table aria-describedby="" class="table mb-0 commission-setup-table">
            <thead #header>
              <tr id="SPAHeaderRow">

                <th scope='col' *ngIf="EditMoreOption && SelectRow" class="pr-3 table-checkbox noellipsis">
                  <div class="th-inner">
                    <mat-checkbox
                      [disabled]="isReadOnly || ((bodyArray | filterPipe: searchText : hdrkeyArray).length <= 0)"
                      (change)="RowSelect($event,bodyArray,'All')"
                      [checked]="SelectedData.length && SelectedData.length == (bodyArray | filterPipe: searchText : hdrkeyArray).length"
                      formControlName="IsCheckAll"></mat-checkbox>
                  </div>
                </th>
                <th scope='col' *ngFor="let hdrCnt of hdrArray;let hi=index" id="header{{hi}}"
                  [ngClass]="[hdrCnt.alignType=='right' ? 'text-right' : hdrCnt.alignType=='center' ? 'text-center':'',hdrCnt.sortable != undefined ? (hdrCnt.sortable ? '': 'pointerevents-none') : '']"
                  [class.cursordefault]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? false: true) : false"
                  [class.cursor]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? true: false) : true"
                  (click)="sortingFunc(hdrCnt.jsonkey,hi,'Frmtable', hdrCnt.sortcolumn, hdrCnt.sortcolumndatatype)">
                  <div class="th-inner"
                    [class.font-weight-bold]="selectedDefaultHeader == hdrCnt.jsonkey && (bodyArray | filterPipe: searchText : hdrkeyArray).length > 0 && hdrCnt.sortable != undefined ? hdrCnt.sortable : (selectedDefaultHeader == hdrCnt.jsonkey && bodyArray.length > 0)">
                    {{hdrCnt.title}}
                    <span class="pl-1 position-relative" *ngIf="hdrCnt.jsonkey != ''">
                      <i aria-hidden="true" id="sortArrow{{hi}}"
                        *ngIf="(hdrCnt.jsonkey != '' && hdrCnt.sortable != undefined ? hdrCnt.sortable : true) && (bodyArray | filterPipe: searchText : hdrkeyArray).length != 0"
                        [ngStyle]="{'opacity':selectedDefaultHeader == hdrCnt.jsonkey ? '1' : '0.3' }" [ngClass]="selectedDefaultHeader == hdrCnt.jsonkey && orderType == 'asc' ? 'icon-filled_up_arrow position-absolute deg0 IC6': selectedDefaultHeader == hdrCnt.jsonkey && orderType =='desc' ?
                        'icon-filled_up_arrow deg180 position-absolute IC6': 'icon-sortArrow sort-icon'">

                      </i>
                    </span>
                  </div>
                </th>
                <th scope='col' *ngIf="SelectedSettingId != GridType.retail"></th>
                <th scope='col' *ngIf="!isRoleSetUpReadOnly && EnableActions"
                  [ngClass]="{'column-sticky': sticky, 'minwidth177':DoneCancel, 'minwidth100':!DoneCancel}">
                  <div class="th-inner  ">{{captions.setting.Actions}}</div>
                </th>

              </tr>
            </thead>
            <tbody formArrayName="tablebody" #container>
              <tr
                [ngClass]="{'highlight' :((NewerData[0] === bodyCnt) || (SelectedData === bodyCnt)), 'onEdit':isEdit, 'editRow':editableRow == bodyCnt.id, 'rowDisabled': (enableRowCheck | enablerow : bi : options : editEvent)}"
                id="{{bodyCnt.id}}" class="cust-row"
                *ngFor="let bodyCnt of scroll.viewPortItems;let bi=index;trackBy:trackByFn" [formGroupName]="bi">
                <td class="pr-3 table-checkbox noellipsis">
                  <mat-checkbox (change)="RowSelect($event,bodyCnt,'Single')" class="suppress-z"
                    [checked]="SelectedData.indexOf(bodyCnt) != -1" [disabled]="isReadOnly || bodyCnt.checkbox">
                  </mat-checkbox>
                </td>
                <td>{{bodyCnt.itemDescription}}</td>
                <td *ngIf="showOutletInGrid || false">{{bodyCnt.locationDescription}}</td>
                <td class="text-right">{{bodyCnt.threshold | Currency : false}}</td>
                <td class="text-right">{{bodyCnt.flatAmount | Currency : false}}</td>
                <td class="text-right">{{bodyCnt.percentage}}</td>
                <td></td>
                <td>
                  <span>
                    <i aria-hidden="true" *ngIf="bodyCnt.commissionon == captions.setting.Revenue"
                      class="icon-Revenue"></i>
                    <i aria-hidden="true" *ngIf="bodyCnt.commissionon == captions.setting.Items" class="icon-Item"></i>
                    {{bodyCnt.commissionon}}
                  </span>
                </td>
                <td>
                  <span>
                    <i aria-hidden="true" *ngIf="bodyCnt.isAfterDiscount" class="icon-SuccessDark"></i>
                    <span *ngIf="bodyCnt.isAfterDiscount">{{captions.setting.Yes}}</span>
                    <i aria-hidden="true" *ngIf="!bodyCnt.isAfterDiscount" class="icon-error-icon"></i>
                    <span *ngIf=" !bodyCnt.isAfterDiscount">{{captions.setting.No}}</span>
                  </span>
                </td>
                <td>
                  <i aria-hidden="true" class="icon-More cursor more-icon"
                    [ngClass]="IsMoreOptionReadOnly ? 'button_invalid':''" matTooltip="{{captions.common.More}}"
                    [matMenuTriggerFor]="menu"></i>
                  <mat-menu #menu="matMenu" class="matMenu">
                    <button mat-menu-item (click)="editRow($event,bodyCnt,'new',options[0].ServiceId)">
                      <span class="LW14">{{captions.common.New}}</span>
                    </button>

                    <button mat-menu-item (click)="editRow($event,bodyCnt,'edit',options[0].ServiceId, bi)">
                      <span class="LW14">{{captions.common.Edit}}</span>
                    </button>
                  </mat-menu>
                </td>


              </tr>
              <tr *ngIf="(bodyArray | filterPipe: searchText : hdrkeyArray).length == 0 && !isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1"
                  class="text-center"> {{captions.common.NoDataFound}} </td>
              </tr>
              <tr *ngIf="isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1"
                  class="text-center">{{captions.common.loadingData}}</td>
              </tr>
            </tbody>
          </table>
        </virtual-scroller>
      </div>
    </div>
  </form>
</div>