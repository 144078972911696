import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CacheService } from '@app/core/services/cache.service';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { ComponentTypes } from '@app/shared/constants/commonenums';
import { BookingChargeType } from '@app/shared/models/RestaurantDTO';
import { FacadeService } from '@app/shared/services/facade.service';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-open-payment-summary',
  templateUrl: './open-payment-summary.component.html',
  styleUrls: ['./open-payment-summary.component.scss']
})
export class OpenPaymentSummaryComponent implements OnInit, OnDestroy {
  OperationCurrency: any;
  isServiceConfigured = false;
  subscriptions: Subscription = new Subscription();
  ratePlan: any;
  selectedGuest: any;
  fromComponent = ComponentTypes.EditOpenBooking;
  selectedGuestData: any;
  constructor(public dialog: MatDialog, public partyService: PartyService, @Inject(COMPONENTINPUT) public data, public translateService: TranslateService,
    public facadeService: FacadeService, public popupService: PopupService, public cs: CacheService) {
    this.subscriptions.add(this.partyService.ratePlan$.subscribe(data => {
      this.ratePlan = data;
    }));
    this.selectedGuest = this.partyService.selectedGuest ?? this.partyService.reservationFormGroup.value.selectedGuest;
    this.fromComponent = this.partyService.isfrombuynow ? ComponentTypes.AddOpenBooking : ComponentTypes.EditOpenBooking;
   
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this.OperationCurrency = sett.General.OperationCurrency;
      this.isServiceConfigured = sett.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge)?.length > 0 ;
    }));
  }



  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  setReservationData(value) {
    this.partyService.openBookingDataSaveObj.ReservationAttemptId = value;
    if (this.fromComponent == ComponentTypes.AddOpenBooking && this.partyService.isfrombuynow) {
      this.partyService.reservationConfirm();
    }
  }
}
