<div class="addon__search-section">
  <dynamic-form [config]="searchConfig" class="guestlist__search-form" #searchConfigForm>
  </dynamic-form>
</div>
<div class="addon__not-found seat-header-three"
  *ngIf="(allEligibleAddOns | wildSearch: searchText :customWildSearch).length == 0">
  {{'noAddonsFound' | translate}}
</div>
<div class="addon__panel-view">
  <div class="addon__panel-body">
    <div *ngFor="let item of allEligibleAddOns | wildSearch: searchText :customWildSearch" class="addon__addon-section"
      [ngStyle]="{'border-bottom-color':item | getCategoryData : _settings.Categories :'Color' }">
      <div *ngIf="item.IsVisible" class="addon__card-details">
        <div class="addon__card-view">
          <div class="addon__addon-details">
            <div class="addon__name">
              <span class="addon_name_formatted" [title]="item.AddonName">
                {{item.AddonName}}</span>
              <span *ngIf="item.IsMandatory" style=" color:read;font-weight: bold;color: red;font-size: 20px;"> *</span>
            </div>
            <span class="seat-labels" [ngStyle]="{'color':item | getCategoryData : _settings.Categories :'Color' }">
              {{item | getCategoryData : _settings.Categories :'Text'}}</span>
          </div>
          <div class="addon__addon-price">
            <div style="text-align:right">{{_settings.General.OperationCurrency}} {{item.Price | number : '1.0-2'}}
            </div>
            <div *ngIf="item.PricingCategory == PricingCategory.ByDuration">
              <span class="seat-body-text" *ngIf="item.PricingType == PricingType.ByHour">
                {{'For' | translate}} {{ item.AddOnDetails[0].Duration }} {{'hours' | translate}}
              </span>
              <span class="seat-body-text" *ngIf="item.PricingType == PricingType.ByMinutes">
                {{'For' | translate}} {{ item.AddOnDetails[0].Duration }} {{'minute' | translate}}
              </span>
            </div>
            <div class="seat-body-text" *ngIf="item.PricingCategory == PricingCategory.BySession">{{'perSession' |
              translate}}</div>
          </div>
        </div>
        <div class="addon__card-quantity seat-header-four">
          <div class="addon__availablility">
            <label for="" class="addon__available-text">{{"partyStatusAvailableText" | translate}}</label>
            <label *ngIf="!item.IsUnlimited" class="seat-grid-content" for="">
              <span
                [ngClass]="{'addon__available': item.AvailableQuantity, 'addon__soldout' : !item.AvailableQuantity }">{{item.AvailableQuantity}}</span>
              {{'of' | translate}} {{item.OverAllQuantity}}
            </label>
            <label class="addon__unlimited" *ngIf="item.IsUnlimited">
              {{'UnlimitedInventory' | translate}}
            </label>
            <!-- <span class="addon__overbooked" *ngIf="item.Overbooked">{{'Overbooked' | translate}}: {{item.Overbooked}}</span> -->
          </div>
          <div class="addon__quantity-selection">
            <div class="addon__icon-area">
              <span class="icon-minus-outline" (click)="decreaseAddOn(item)"></span>
              <mat-form-field appearance="standard" class="addon-count">
                <input [(ngModel)]="item.selectedQuantity" type="text" (keypress)="isNumberKey($event)" (paste)="validateAddOnsQtyOnChange(item)"
                  (focusout)="addonQuantityValidations($event, item)" numbersOnly matInput>
              </mat-form-field>
              <span class="icon-add-solid" (click)="increaseAddOn(item)"></span>
            </div>
            <span class="addon__overbooked" *ngIf="item.OverBooking">{{'OverbookingBy' | translate}}
              {{item.OverBooking}}</span>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<div class="addon-alert-box">
<div *ngIf="warningMessages?.length" class="alert alert-warning alert-dismissible fade show" role="alert">
  <div *ngFor="let message of warningMessages">
    <span class="icon-error_FILL0_wght400_GRAD0_opsz24"></span> <span> {{message}}</span>
  </div>
</div>
</div>