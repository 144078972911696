<virtual-scroller #scroll [items]="_bodyContentData" style="height: calc(100% - 5px)" id="commonSimpleTable"
  *ngIf="_bodyContentData && _bodyContentData.length>0"
  [ngClass]="{'stopscroll': this.checkvaluechanged && this.checkvaluechanged!=''}">
  <table aria-describedby="">
    <thead #header>
      <tr>
        <th scope='col'  *ngIf="tableOptions.selectType" class="textCenter">
          <mat-checkbox (change)="!this.checkvaluechanged!=''&& emitcheckAlldata($event)"
            [checked]="isAllChecked()" *ngIf=" !this.checkvaluechanged!=''"></mat-checkbox>
          
        </th>
        <th  scope='col' *ngFor="let headerdata of  headerOption" [ngClass]="headerdata.alignment"
          (click)="headerdata.sorting && !this.checkvaluechanged!='' && sorting(headerdata.key,ordertype)">
          {{headerdata.description}}
          <span *ngIf="headerdata.sorting && !this.checkvaluechanged!=''">
            <span *ngIf="ordertype=='asc'">
              <span class="icon-up-arrow"></span>
            </span>
            <span *ngIf="ordertype=='desc'">
              <span class="icon-down-arrow"></span>
            </span>
          </span>
        </th>
        <th scope='col'  *ngIf="tableOptions.options" class="textCenter">{{this.captions.actions}}</th>
      </tr>
    </thead>
    <tbody #container cdkDropList class="TBodydataList" (cdkDropListDropped)="drop($event)">
      <tr cdkDrag *ngFor="let _bodyContentData of  scroll.viewPortItems;let i=index"
        [cdkDragDisabled]="(this.checkvaluechanged && this.checkvaluechanged!=='')?true:false"
        [ngClass]="((this.checkvaluechanged && this.checkvaluechanged!=='') && (_bodyContentData != this.checkvaluechanged)) ? 'disabledow' : ''">
        <td *ngIf="tableOptions.selectType" class="textCenter">
          <mat-checkbox [disabled]="fromUnpaidPlayer && _bodyContentData['isHold']" (change)="emitcheckdata($event,_bodyContentData)"
            [checked]="checkeddata.length | checkorderExist:_bodyContentData:checkeddata"></mat-checkbox>
        </td>
        <td *ngFor="let headerdata of  headerOption" [ngClass]="headerdata.alignment">
          <span *ngIf="!headerdata.templatecontainer">{{_bodyContentData[headerdata.key]}}</span>
          <span *ngIf="headerdata.templatecontainer">
            <ng-container [ngTemplateOutlet]="this[headerdata.templatecontainer.templateName]"
              [ngTemplateOutletContext]="{rowValue:_bodyContentData,value:_bodyContentData[headerdata.key],headerdata:headerdata,idx:i}">
            </ng-container>
          </span>
        </td>
        <td *ngIf="tableOptions.options" class="textCenter">
          <ng-container *ngIf="_bodyContentData != this.checkvaluechanged">
            <ng-container *ngFor="let tabOpt of tableOptions.options" [ngTemplateOutlet]="this[tabOpt]"
              [ngTemplateOutletContext]="{rowValue:_bodyContentData}"></ng-container>
            <span class="icon-drag-drop custtab-pr5 custtab-pointer" cdkDragHandle
              [ngClass]="{'displayNone':(tableOptions.options.indexOf('dragdrop')==-1)}"></span>
          </ng-container>

          <ng-container *ngIf="_bodyContentData == this.checkvaluechanged">
            <ng-container *ngTemplateOutlet="DoneCancel; context: {rowValue:_bodyContentData}"></ng-container>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="((_bodyContentData && _bodyContentData.length<=0) && (originaldata && originaldata.length>0)) "
    class="norecords-alignment fittotable">
    <app-retail-no-data-found [isSearch] = 'true'></app-retail-no-data-found>
  </div>
</virtual-scroller>
<div *ngIf="(originaldata && originaldata.length<=0)" class="norecords-alignment">
  <app-retail-no-data-found [isSearch] = 'false'></app-retail-no-data-found>
</div>
 
<ng-template #inputTemplate let-x="rowValue" let-y="value" let-HdrData="headerdata">
  <input matInput placeholder="Favorite food" [value]="y">
</ng-template>

<ng-template #template1 let-x="rowValue" let-y="value" let-HdrData="headerdata">
  <input type="password" placeholder="Favorite food" [value]="y">
</ng-template>

<ng-template #toggleTemplate let-x="rowValue" let-y="value" let-HdrData="headerdata">
  <ui-switch [disabled]="HdrData.templatecontainer.disabledFlag" [checkedLabel]="this.captions.yes"
    [uncheckedLabel]="this.captions.no" [checked]="y" (change)="valueChanged($event, x,HdrData)" class="ui-switch">
  </ui-switch>
</ng-template>

<ng-template #templateIconName let-x="rowValue" let-y="value" let-HdrData="headerdata">
  <i  aria-hidden="true" class="icon-Circle" [ngClass]="x.iconname"></i>{{y}}
</ng-template>
 
<ng-template #edit let-x="rowValue">
  <span class="icon-edit-new custtab-pr5 custtab-pointer" [ngClass]="{'restricted': x.isRestrictEdit}"
    (click)="emitEditrowdata($event,x)"></span>
</ng-template>
<ng-template #delete let-x="rowValue">
  <span class="icon-delete custtab-pr5 custtab-pointer" [ngClass]="{'restricted': x.isRestrictDelete}"
    (click)="emitDeleterowdata($event,x)"></span>
</ng-template>
<ng-template #claim let-x="rowValue">
  <span class="icon-confirmed custtab-pr5 custtab-pointer" (click)="emitClaimrowdata($event,x)"></span>
</ng-template>
<ng-template #moreoption let-x="rowValue">
  <span class="icon-more custtab-pr5 custtab-pointer" [matMenuTriggerFor]="menuOptions"
    [matMenuTriggerData]="{'menuList':x.menuList,'rowData':x}"></span>
</ng-template>
 
<!-- Direct Template Implementation-->
<ng-template #DoneCancel let-x="rowValue">
  <span class='done' (click)='done($event,x)'>
    <i  aria-hidden="true" class='icon-done'>&nbsp;</i>{{this.captions.done}}
  </span>
  <span class='cancel' (click)='cancel($event,x)'>
    <i  aria-hidden="true" class='icon-Cancel'>&nbsp;</i>{{this.captions.cancel}}
  </span>
</ng-template>

<!-- Menu Options-->
<mat-menu #menuOptions="matMenu">
  <ng-template let-menuList="menuList" let-rowData="rowData" matMenuContent>
    <button *ngFor="let x of menuList" mat-menu-item (click)="emitmoreoptiondata($event,x,rowData)">{{x}}</button>
  </ng-template>
</mat-menu>
