import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FloorplanService } from '@services/floorplan.service';
import { ISubscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-editor-view',
  templateUrl: './editor-view.component.html',
  styleUrls: ['./editor-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditorViewComponent implements OnInit, OnDestroy {
  @Output() tabChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() editorDetails: any;
  selectedTabIndex: any;
  selectedTabSubscription: ISubscription;
  constructor(private fs: FloorplanService) { }

  ngOnInit() {
    this.selectedTabSubscription = this.fs.selectedTabIndex$.subscribe(index => {
      if (index != null) {
        this.selectedTabIndex = index;
        // this.selectedTabIndex = { ...this.selectedTabIndex };
      }
    })
  }

  tabChange($event) {
    this.selectedTabIndex = $event.index;
    this.tabChangeEvent.emit($event);
  }

  ngOnDestroy() {
    if (this.selectedTabSubscription) {
      this.selectedTabSubscription.unsubscribe();
    }
  }



}
