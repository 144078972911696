import { I } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, EventEmitter, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CacheService } from '@app/core/services/cache.service';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { ComponentTypes, PaymentGateways, PaymentStates } from '@app/shared/constants/commonenums';
import { PartyService } from '@app/shared/services/party.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-iframe-container',
  templateUrl: './iframe-container.component.html',
  styleUrls: ['./iframe-container.component.scss']
})
export class IframeContainerComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() SaveBookingObj;
  @Input() componentType;
  @Input() iframe;
  @Output() ReservationAttemptId: EventEmitter<any> = new EventEmitter<any>();
  ReservationAttemptData: any;
  showIframe = false;
  SlotLockId: any;
  iframeURL: any;
  PaymentGateway: PaymentGateways;
  _settings: any;
  reservationAttemptIds = [];
  subscriptions: Subscription = new Subscription();
  isRegistered = false;
  constructor(public partyService: PartyService, public translateService: TranslateService, public sanitizer: DomSanitizer, private popupService: PopupService,
    public dialog: MatDialog, public dashboardData: DashboardFunctions, public cs: CacheService) {
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
    this.subscriptions.add(this.partyService.tabChange$.subscribe(value => {
      if (value === 2) {
        this.iframeURL = null;
        this.showIframe = false;
        this.partyService.paymentInfo = null;
        this.getRegisterReservationAttempt();
      }
    }));

  }

  ngOnInit(): void {

  }

  setPaymentGateWay() {
    if (this.componentType == ComponentTypes.AdditionalCharge) {
      this.PaymentGateway = this.SaveBookingObj.PaymentGateway
    } else {
      if (this.componentType === ComponentTypes.AddOpenBooking) {
        this.PaymentGateway = this.partyService.paymentInfo ? this.partyService.paymentInfo.PaymentGateway : this.partyService.MultipleReservationAttemptData.PaymentGateway;
      }
      else {
        this.PaymentGateway = this.partyService.paymentInfo ? this.partyService.paymentInfo.PaymentGateway : this.ReservationAttemptData?.PaymentGateway ? this.ReservationAttemptData?.PaymentGateway : this.PaymentGateway;
      }
    }
  }
  ngAfterViewInit() {
    this.setPaymentGateWay();
  }

  ngOnChanges() {
    if (this.iframe) {
      this.LoadAndDisplayIframe();
    }

  }

  LoadAndDisplayIframe() {
    this.setPaymentGateWay();
    this.LoadIframe(this.iframe);
    this.showIframe = true;
  }


  getRegisterReservationAttempt() {
    if (!this.partyService.isfrombuynow && this.componentType !== ComponentTypes.AddOpenBooking && this.componentType !== ComponentTypes.AddActivityBooking && this.componentType !== ComponentTypes.AddPrivateLessonBooking) {
      let registerReservationAttemptRequestDTO = {
        PartySize: this.SaveBookingObj.Size,
        Sessions: this.SaveBookingObj.Sessions,
        ActivityId: this.SaveBookingObj.SpecialMealId,
        CoverTypes: this.SaveBookingObj.CoverTypes,
        TableIds: this.SaveBookingObj.TableIds,
        BookingTypes: this.SaveBookingObj.BookingTypes,
        FromWebsite: true,
        Slots: this.SaveBookingObj.AttemptSlots,
        PartyId: this.SaveBookingObj.Id,
        MemberType: this.SaveBookingObj.Contact?.IsMemberActive && this.SaveBookingObj.Contact.MemberType ? this.SaveBookingObj.Contact.MemberType : null
      }

      this.partyService.RegisterReservationAttempt(registerReservationAttemptRequestDTO).subscribe(data => {
        if (data) {
          this.ReservationAttemptData = data;
          this.partyService.ReservationAttemptData = data;
          this.PaymentGateway = this.partyService.paymentInfo ? this.partyService.paymentInfo.PaymentGateway : data.PaymentGateway;
          if (this.ReservationAttemptData.ShouldCreditCardBeEntered) {
            if (this.ReservationAttemptData.CreditCardIFrameURL) {
              this.iframe = this.ReservationAttemptData.CreditCardIFrameURL;
              this.LoadAndDisplayIframe();
              this.reservationAttemptIds = [];
              if (data.ReservationAttemptId) {
                this.reservationAttemptIds.push(data.ReservationAttemptId);
              }
              else if (data.Slots) {
                this.reservationAttemptIds = data.Slots.filter(x => x.ReservationAttemptId).map(({ ReservationAttemptId }) => ReservationAttemptId);
              }
            }
            else {
              this.showMessage(this.translateService.instant('SorryForPaymentOffline'));
            }
          }

          this.dashboardData.getFinancialEffect(this.componentType, this.ReservationAttemptData.ShouldCreditCardBeEntered);

        }
      });
    } else {
      this.reservationAttemptIds = [];
      this.iframe = this.partyService.MultipleReservationAttemptData.CreditCardIframeUrl;
      this.ReservationAttemptData = this.partyService.ReservationAttemptData;
      this.PaymentGateway = this.partyService.paymentInfo ? this.partyService.paymentInfo.PaymentGateway : this.partyService.MultipleReservationAttemptData.PaymentGateway;
      if (this.partyService.MultipleReservationAttemptData.ResponseDTOs[0].ReservationAttemptId) {
        this.reservationAttemptIds.push(this.partyService.MultipleReservationAttemptData.ResponseDTOs[0].ReservationAttemptId);
      }
      else if (this.partyService.MultipleReservationAttemptData.ResponseDTOs[0].Slots) {
        this.reservationAttemptIds = this.partyService.MultipleReservationAttemptData.ResponseDTOs[0].Slots.filter(x => x.ReservationAttemptId).map(({ ReservationAttemptId }) => ReservationAttemptId);
      }
      this.LoadAndDisplayIframe();
      this.popupService.nextBtnEnabled$.next(true);
    }
    this.partyService.isfromBuyNowAmountNegotiated = false;
  }

  UpdateReservationAttempt(updateReservationAttemptDTO) {
    this.partyService.UpdateReservationAttempt(updateReservationAttemptDTO).subscribe(data => {
      if (data && (data.ErrorMessage || data.ExceptionMessage || (data.ValidationMessages && data.ValidationMessages.length > 0) || data.length == 0)) {
        if (data.ErrorMessage && data.ErrorMessage.length > 0) {
          var sorrysomethingwentwromg = this.translateService.instant('SorryWeGotSomeErrorWhileProcessing');
          var CCUprocessableTryAgainErrorMessage = this.translateService.instant('CCUprocessableTryAgainErrorMessage');
          var ImportantErrorMessage = this.translateService.instant('ImportantErrorMessage');
          var CreditCardAuthorizationError = this.translateService.instant('CreditCardAuthorizationError');
          var TryAgainMessage = this.translateService.instant('TryAgainMessage');
          var CardNumbershouldContainOnlyDigits = this.translateService.instant('CardNumbershouldContainOnlyDigits');
          var CardNumberShouldConsists13To16Digits = this.translateService.instant('CardNumberShouldConsists13To16Digits');
          var CardNumberShouldNotBlank = this.translateService.instant('CardNumberShouldNotBlank');
          var SorryWeGotSomeErrorCardExpired = this.translateService.instant('SorryWeGotSomeErrorWhileProcessingCardExpired');
          if (data.ErrorMessage == "Please try it one more time, if the problem repeats, contact to rGuestSeat@Agilysys.com")
            var url = sorrysomethingwentwromg + TryAgainMessage;
          else if (data.ErrorMessage == "We're sorry, but we couldn�t process your credit card based on the information you provided. Please try again.")
            var url = sorrysomethingwentwromg + CCUprocessableTryAgainErrorMessage;
          else if (data.ErrorMessage == "[IMPORTANT] Some error happens at the moment of database saving. There is a probability that the payment transaction has performed successfully but this fact hasn't been saved properly. Don't try to do it one more time. Please, contact rGuestSeat@Agilysys.com regarding this error")
            var url = sorrysomethingwentwromg + ImportantErrorMessage;
          else if (data.ErrorMessage == "The provided credit card hasn't been authorized succesfully")
            var url = sorrysomethingwentwromg + CreditCardAuthorizationError;
          else if (data.ErrorMessage.indexOf("The card is expired"))
            var url = SorryWeGotSomeErrorCardExpired;
          else if (data.ErrorMessage == "The card number should consist of only digits")
            var url = sorrysomethingwentwromg + CardNumbershouldContainOnlyDigits;
          else if (data.ErrorMessage == "The card number should consist of from 13 to 16 digits")
            var url = sorrysomethingwentwromg + CardNumberShouldConsists13To16Digits;
          else if (data.ErrorMessage == "The card number shouldn't be blank")
            var url = sorrysomethingwentwromg + CardNumberShouldNotBlank;
          else
            var url = sorrysomethingwentwromg + data.ErrorMessage;
          this.showMessage(url);
        }
        else {
          var url = sorrysomethingwentwromg + TryAgainMessage;
          this.showMessage(url);
        }
        this.showMessage(url);
      } else if (data.RedirectUrl) {
        this.LoadIframe(data.RedirectUrl);
        setTimeout(() => {
          this.showMessage("Your current payment of " + this._settings.General.OperationCurrency + updateReservationAttemptDTO.PaymentAmount + " is processed successfully! Please proceed to pay your pending amount " + this._settings.General.OperationCurrency + data.PendingAmount + " to place your reservation.");
        }, 1);
      }
      else {
        if (this.partyService.paymentInfo?.ReservationAttemptId > 0 || (this.reservationAttemptIds && this.reservationAttemptIds.length == 1)) {
          let reservationAttemptId = this.partyService.paymentInfo?.ReservationAttemptId ? this.partyService.paymentInfo.ReservationAttemptId : this.reservationAttemptIds[0];
          this.partyService.GetRegisterReservationAttempt(reservationAttemptId).subscribe(data => {
            if (data && (data.State == PaymentStates.Authorized || data.State == PaymentStates.ChargeCaptured)) {
              this.ReservationAttemptId.emit(reservationAttemptId);
              this.popupService.nextBtnEnabled$.next(false);
            }
            else {
              this.showMessage(this.translateService.instant("CreditCardNotBeProcessed"));
            }
          });
        }
        else {
          const ReservationIds = this.ReservationAttemptData.map((item) => {
            if (item.Slots && item.Slots.length) {
              return item.Slots.filter(x => x.ReservationAttemptId).map(x => x.ReservationAttemptId);
            } else {
              return item.ReservationAttemptId
            }
          }).flat().filter(id=>id);
          if (data.ReservationState) {
            let isAuthorized = true;
            data.ReservationState.forEach(reservationData => {
              if (reservationData.State != PaymentStates.Authorized && reservationData.State != PaymentStates.ChargeCaptured) {
                isAuthorized = false;
              }
            });

            if (isAuthorized) {
              this.ReservationAttemptId.emit(ReservationIds);
              this.popupService.nextBtnEnabled$.next(false);
            } else {
              this.showMessage(this.translateService.instant("CreditCardNotBeProcessed"));
            }
          }
        }
      }
    });
  }

  getAttemptIds() {
    if (this.componentType == ComponentTypes.AdditionalCharge) {
      return [];
    }
    if (this.partyService.paymentInfo && this.partyService.paymentInfo.ReservationAttemptId) {
      const paymentInfo = this.partyService.paymentInfo;
      return [paymentInfo.ReservationAttemptId];
    }
    else {
      if (this.reservationAttemptIds && this.reservationAttemptIds.length) {
        return this.reservationAttemptIds;
      }
    }
  }

  processTransactions(transInfo) {
    //var authTransactionID = transInfo.authorizeTransactionID;
    //var paymentType = transInfo.paymentType;
    var totalAmount = transInfo.paidAmount;
    var overAllStatus = transInfo.status;
    var successCode = transInfo.successCode;
    var errorCode = transInfo.errorCode;
    var errorMessage = transInfo.errorMessage;
    var transactions = transInfo.payments;
    var authorizeTransactions = [];

    if (transactions && transactions.length > 0) {
      transactions.forEach(transaction => {
        if (transaction.successCode == 1) {
          var name = '';
          var paymentDetail = transaction.paymentDetail;
          var roomDetails = {
            RoomNumber: null,
            ConfirmationNumber: null,
            LastName: null
          };
          var pointsDetails = {
            AccountNumber: null,
            BucketName: null,
            Name: null,
            LoyaltyTransactionId: null
          };

          if (transaction.paymentType == 1 || transaction.paymentType == 2) { //credit card
            name = paymentDetail.accountName;
          }

          if (transaction.paymentType == 3) { //Post to room charges
            roomDetails.RoomNumber = paymentDetail.cardIssuer;
            roomDetails.ConfirmationNumber = paymentDetail.cardNumber;
            roomDetails.LastName = paymentDetail.accountName;
            name = paymentDetail.accountName;
          }
          if (transaction.paymentType == 5) { //Loyalty
            pointsDetails.BucketName = paymentDetail.tender;
            pointsDetails.AccountNumber = paymentDetail.cardNumber;
            pointsDetails.Name = paymentDetail.accountName;
            pointsDetails.LoyaltyTransactionId = paymentDetail.followOnData;
            name = paymentDetail.accountName;
          }
          var dto = {
            CardNumber: paymentDetail.cardNumber ? paymentDetail.cardNumber : "",
            ExpirationDate: paymentDetail.expirationDate ? paymentDetail.expirationDate.substring(0, 4) + paymentDetail.expirationDate.substring(4) : "",
            Name: name ? name : "",
            Token: paymentDetail.token ? paymentDetail.token : null,
            TransactionFollowOnData: paymentDetail.followOnData ? paymentDetail.followOnData : null,
            AuthorizeTransactionID: transaction.authorizeTransactionID,
            Amount: transaction.paidAmount,
            PaymentType: transaction.paymentType ? transaction.paymentType : null,
            InvoiceID: transaction.invoiceID ? transaction.invoiceID : null,
            TransactionDate: transaction.transactionsDate ? transaction.transactionsDate : null,
            RoomNumber: roomDetails && roomDetails.RoomNumber ? roomDetails.RoomNumber : null,
            ConfirmationNumber: roomDetails && roomDetails.ConfirmationNumber ? roomDetails.ConfirmationNumber : null,
            BucketName: pointsDetails && pointsDetails.BucketName ? pointsDetails.BucketName : null,
            LoyaltyAccountNumber: pointsDetails && pointsDetails.AccountNumber ? pointsDetails.AccountNumber : null,
            LoyaltyTransactionId: pointsDetails && pointsDetails.LoyaltyTransactionId ? pointsDetails.LoyaltyTransactionId : null,
          };
          authorizeTransactions.push(dto);
        } else if (transaction.errorMessage) {
          this.showMessage(transaction.errorMessage);
        }
      });
      return authorizeTransactions;
    }
  }

  showMessage(msg) {
    const title = this.translateService.instant('alert');
    const messageDialogRef = Utilities.showSettingsChangedInfo(msg, title, this.dialog);
  }

  LoadIframe(IframeURL) {
    IframeURL = '' + IframeURL + "&origin=" + window.location.origin;
    this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(IframeURL);
    this.iframeURL = this.iframeURL.changingThisBreaksApplicationSecurity ? this.iframeURL.changingThisBreaksApplicationSecurity : this.iframeURL;
    const iframe = document.getElementsByTagName('iframe')[0];

  }

  ngOnDestroy() {
    this.iframeURL = null;
    this.partyService.editIframeUrl = null;
    this.partyService.paymentInfo = null;
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:message', ['$event'])
  onMessage({ origin, data }) {
    if (origin != environment.authorizeIframeResponseOrigin && origin != environment.rGuestPayIframeResponsOrigin) { // set your origin
      return false;
    }
    var rGuestPayRespose = data;
    var payResponse = data;
    if (typeof data != "object") {
      rGuestPayRespose = JSON.parse(data);
    }
    if ((data.type == "txn-completed" && data.transactionInfo && data.transactionInfo.transactionInfo && data.transactionInfo.transactionInfo.payments) || (rGuestPayRespose && (rGuestPayRespose.code || rGuestPayRespose.gatewayResponseData))) {
      if (this.PaymentGateway == PaymentGateways.AuthorizePay) { //Authorize Pay 

        var transInfo = payResponse.transactionInfo.transactionInfo;
        if (transInfo.errorMessage || transInfo.successCode != 1) {
          if (transInfo.errorMessage) {
            this.showMessage(transInfo.errorMessage);
          }
          else {
            this.showMessage(this.translateService.instant("CardInformationProcessNotCompleted"));
          }
        } else if (payResponse && transInfo && transInfo.successCode == 1) {

          var transactions = this.processTransactions(transInfo);
          var dto = {
            RestaurantId: Utilities.RestaurantId(),
            PartyId: this.SaveBookingObj.Id,
            SlotLockIds: this.partyService.slotLockIds && this.partyService.slotLockIds.length ? this.partyService.slotLockIds : this.partyService.slotLockId ? [this.partyService.slotLockId] : [],
            BookingID: null,
            TokenType: "",
            PaymentAmount: transInfo.paidAmount,
            PaymentGateway: this.PaymentGateway,
            AuthorizeTransactions: transactions,
            ReservationAttemptIds: this.getAttemptIds(),
          };
          if (this.componentType == ComponentTypes.AdditionalCharge) {
            this.ReservationAttemptId.emit(dto);
            return;
          }
          this.UpdateReservationAttempt(dto);
        } else {
          this.showMessage(this.translateService.instant("CardInformationProcessNotCompleted"));
        }
      }
      else {
        if (rGuestPayRespose.code && rGuestPayRespose.code != "100") {
          rGuestPayRespose = JSON.parse(data);
          if (rGuestPayRespose.validationErrors && rGuestPayRespose.validationErrors.length > 0) {
            var errorString = "";
            rGuestPayRespose.validationErrors.forEach(function (error, i, arr) {
              errorString = error.fieldName + " " + error.value + "\n";
            })
            this.showMessage(errorString);
          }
          else if (rGuestPayRespose && rGuestPayRespose.gatewayResponseData && rGuestPayRespose.gatewayResponseData.message) {
            this.showMessage(rGuestPayRespose.gatewayResponseData.message);
          }
          else {
            this.showMessage(this.translateService.instant("CardInformationProcessNotCompleted"));
          }
        }
        else if (rGuestPayRespose && rGuestPayRespose.gatewayResponseData && rGuestPayRespose.gatewayResponseData.code == "100") {
          let callbackDto = {
            ReservationAttemptId: this.ReservationAttemptData.ReservationAttemptId,
            ReservationAttemptIds: this.ReservationAttemptData.Slots?.filter(x => x.ReservationAttemptId > 0).map(x => x.ReservationAttemptId),
            CardNumber: rGuestPayRespose.cardInfo.accountNumberMasked,
            ExpirationDate: new Date(data.cardInfo.expirationYearMonth.substring(0, 4) + '-' + data.cardInfo.expirationYearMonth.substring(4) + '-01'),
            CVC: "",
            FirstNameHolder: "",
            LastNameHolder: "",
            AddressLine1: "",
            AddressLine2: "",
            City: "",
            State: "",
            PostalCode: "",
            TokenType: "",
            Token: data.token
          };
          if (this.componentType == ComponentTypes.AdditionalCharge) {
            this.ReservationAttemptId.emit(callbackDto);
            return;
          }
          this.UpdateReservationAttempt(callbackDto);
        }
        else {
          this.showMessage(this.translateService.instant("CardInformationProcessNotCompleted"));
        }
      }
    }
  }

}
