import { Component, OnInit } from '@angular/core';
import { Field } from '@dynamicform/models/field.interface';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { UntypedFormGroup } from '@angular/forms';


@Component({
  selector: 'app-form-number',
  templateUrl: './form-number.component.html',
  styleUrls: ['./form-number.component.css']
})
export class FormNumberComponent implements OnInit, Field {
  public FormControlName;
  config: FieldConfig;
  group: UntypedFormGroup;

  constructor() { }

  ngOnInit() {
    this.FormControlName = this.config.name;
  }

}
