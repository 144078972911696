import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { PopupService } from '@app/popup-module/popup.service';
import { buttonTypes, TableBlockingRuleFrequencyType } from '@app/shared/constants/commonenums';
import { days } from '@app/shared/constants/globalConstants';
import { StandaloneTableDTO } from '@app/shared/models/RestaurantDTO';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';


@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss']
})
export class SelectDateComponent implements OnInit, OnChanges, OnDestroy {

  @Input('data') data;
  dateSelected: string;
  selectButton: ButtonValue;
  selectDateShifts: string;
  subscriptions: Subscription = new Subscription();
  standAloneTables: StandaloneTableDTO[];
  weekDays = days;


  constructor(private ts: TranslateService, public cacheService: CacheService,private ps: PopupService,private as: AppService,private dashboardFunctions: DashboardFunctions) {
    this.subscriptions.add(this.cacheService.layout?.subscribe(layout => {
      let floorPlan = layout.FloorPlans;
      floorPlan?.map(data => {
        this.standAloneTables = _.concat(this.standAloneTables, data?.StandaloneTables);
      });
    }));
  }


  ngOnChanges(changes) {
    this.getSelectedDate();
  }

  ngOnInit() {
    this.initializeConfig();
  }

  getSelectedDate() {
    if (this.data && this.data.overviewData?.length) {
      let month = new Date(this.data?.overviewData[0]?.Date).toLocaleString(this.dashboardFunctions.getLocaleCode(), { month: 'long' });
      this.dateSelected = month + " " + this.data?.date + ", " + this.data?.year;
    }
  }

  initializeConfig() {
    this.selectButton = {
      type: buttonTypes.actionPrimarySmall,
      label: this.ts.instant('select'),
      disbaledproperity: false
    };
  }

  hasProperty(overviewObj, value) {
    return overviewObj.hasOwnProperty(value);
  }

  selectCurrentDate(data) {
    if (data.overviewData?.length) {
      this.as.headerSelectedDate = new Date(data.overviewData[0]?.Date);
      this.ps.closeDialog$.next();
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}