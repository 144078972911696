<section [formGroup]="giftCardRedeemFormGrp" autocomplete="off">
  <mat-form-field class="form-group" [floatLabel]="floatLabel">
    <input matInput [placeholder]="captions.CardNumber" class="" inputtype="onlynumber" maxlength="50"
      formControlName="cardnumber">
  </mat-form-field>
  <app-retail-multi-select-dropdown *ngIf="users" [dropDownFilterData] = "users" [dropDownControlname] = "'users'" [dropDownName] = "captions.users" (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
  <div class="mb-3">
    <label class="LW14">{{captions.inActiveClerk}}</label>
    <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleIncludeInactive($event[0])" [formControl]="giftCardRedeemFormGrp.controls['includeInactiveclerks']"></app-retail-toggle-switch>

  </div>
</section>
