import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SlotsSectionService } from '@app/shared/components/tablelist/slots/slots-section.service';
import { AuditlogComponent } from '@components/auditlog/auditlog.component';
import { buttonTypes, ComponentTypes, SlotType } from '@constants/commonenums';
import { popupDialogDimension } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { UpdatedManualSlotDTO } from '@models/ManualSlotDTO';
import { ManualSlotIdDTO, SettingsDTO } from '@models/RestaurantDTO';
import { AuditableEntityType } from '@models/SimpleAuditLogItemDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-slot',
  templateUrl: './new-slot.component.html',
  styleUrls: ['./new-slot.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewSlotComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isAdd: any;
  @Input() slotTime: any;
  @Input() isSlots: boolean;
  @Output() closeSlot = new EventEmitter();
  partySizeArray: any[];
  maxPartySizeAllowed = 50;
  customSlotConfig: FieldConfig[];
  seatingArea: any;
  settings: SettingsDTO;
  cancelSlotButton: ButtonValue;
  addCustomSlotButton: ButtonValue;
  enableSlotButton: ButtonValue;
  headerName: any;
  isSlotDisabled = false;
  subscriptions: Subscription = new Subscription();
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  constructor(private cs: CacheService, private partyService: PartyService, public translateService: TranslateService,
    public dialog: MatDialog,public slotService: SlotsSectionService) {
    this.subscriptions.add(this.cs.settings.subscribe((settings) => {
      this.settings = settings;
    }));
  }

  ngOnInit() {
    this.headerName = this.isAdd ? 'addslot' : 'editSlot';
    this.cancelSlotButton = {
      type: buttonTypes.actionSecondary, label: this.isAdd ? 'cancel' : 'disable',
      customclass: 'slot-popover__cancel'
    };
    this.addCustomSlotButton = {
      type: buttonTypes.actionPrimary, label: this.isAdd ? 'addText' : 'updateSlot',
      customclass: 'slot-popover__add', disbaledproperity: false
    };
    this.enableSlotButton = {
      type: buttonTypes.actionPrimary, label: 'enable',
      customclass: 'slot-popover__enable'
    };
    this.isSlotDisabled = this.isAdd ? false : this.slotTime.IsDisabled;
    this.setCustomSlotFormConfig();
  }

  ngAfterViewInit() {
    if (this.dynamicForm) {
      this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe((data) => {
        this.addCustomSlotButton.disbaledproperity = false;
        this.dynamicForm.form.get('maxSize').setValue((data && data.minSize > data.maxSize) ? data.minSize : data.maxSize, { emitEvent: false });
        this.customSlotConfig[1].value = (data && data.minSize > data.maxSize) ? data.minSize : data.maxSize;
        this.customSlotConfig[1].options = this.partySizeArray.filter(s => s.id >= data.minSize);
      }));
    }
  }

  setCustomSlotFormConfig() {
    this.setPartySize();

    this.customSlotConfig = [{
      type: 'select',
      label: 'minSize',
      name: 'minSize',
      options: this.partySizeArray,
      class: 'slot-popover__min-size',
      showErrorText: true,
      isTranslate: false,
      value: this.isAdd ? this.partySizeArray[1].id : this.slotTime.LimitedMinPartySize
    },
    {
      type: 'select',
      label: 'maxSize',
      name: 'maxSize',
      options: this.partySizeArray.filter(s => s.id >= (this.isAdd ? this.partySizeArray[1].id : this.slotTime.LimitedMinPartySize)),
      class: 'slot-popover__max-size',
      showErrorText: true,
      isTranslate: false,
      value: this.isAdd ? this.partySizeArray[3].id : this.slotTime.LimitedMaxPartySize
    },
    {
      type: 'select',
      label: 'seatingArea',
      name: 'seatingArea',
      options: this.seatingArea,
      class: 'slot-popover__seatingarea',
      showErrorText: true,
      isTranslate: false,
    },
    {
      type: 'switch',
      name: 'webReservable',
      inputType: 'text',
      label: 'webReservable',
      class: 'slot-popover__web-reservable',
      checked: this.isAdd ? true : this.slotTime.IsWebReservable,
      value: this.isAdd ? true : this.slotTime.IsWebReservable
    }
    ];
    this.setSeatingArea();
  }

  setPartySize() {
    this.partySizeArray = [];
    for (let i = 1; i <= this.maxPartySizeAllowed; i++) {
      this.partySizeArray.push({ id: i, value: i.toString() });
    }
  }

  setSeatingArea() {
    this.seatingArea = this.settings.SeatingAreas;
    this.seatingArea.forEach((area) => { if (area.Id === null) { area.Id = -1; } return area; });
    this.seatingArea.unshift(this.seatingArea.splice(this.seatingArea.findIndex(item => item.Id === -1), 1)[0]);
    this.customSlotConfig[2].options = this.seatingArea.map(x => ({ id: x.Id, value: x.Name }));
    this.customSlotConfig[2].value = this.isAdd ? this.customSlotConfig[2].options.filter(x => x.id == -1)[0].id :
      this.slotTime.SeatingAreaId ? this.customSlotConfig[2].options.
        filter(x => x.id === this.slotTime.SeatingAreaId)[0].id :
        this.customSlotConfig[2].options.filter(x => x.id == -1)[0].id;
  }

  addSlot() {
    if (this.isAdd) {
      const addManualSlot: UpdatedManualSlotDTO = {
        MinPartySize: this.dynamicForm.value.minSize,
        MaxPartySize: this.dynamicForm.value.maxSize,
        IsWebReservable: this.dynamicForm.value.webReservable !== undefined ? this.dynamicForm.value.webReservable : true,
        SeatingAreaId: this.dynamicForm.value.seatingArea !== -1 ? this.dynamicForm.value.seatingArea : null,
        Id: {
          DateTime: this.slotTime,
          Id: 0,
          IsDefault: false
        }
      };
      this.subscriptions.add(this.partyService.createManualSlot(addManualSlot).subscribe(data => {
        this.setSlots();
      }));
    } else {
      const editManualSlot: UpdatedManualSlotDTO = {
        MinPartySize: this.dynamicForm.value.minSize,
        MaxPartySize: this.dynamicForm.value.maxSize,
        IsWebReservable: this.dynamicForm.value.webReservable !== undefined ? this.dynamicForm.value.webReservable : true,
        SeatingAreaId: this.dynamicForm.value.seatingArea !== -1 ? this.dynamicForm.value.seatingArea : null,
        Id: {
          DateTime: this.slotTime.DateTime,
          Id: this.slotTime.Id,
          IsDefault: this.slotTime.Type == SlotType.DefaultSlot
        }
      };
      this.subscriptions.add(this.partyService.updateManualSlot(editManualSlot).subscribe(data => {
        this.setSlots();
      }));
    }
  }

  cancelSlot() {
    if (this.isAdd) {
      this.closeSlot.emit();
    } else {
      const ids: ManualSlotIdDTO[] = [{
        DateTime: this.slotTime.DateTime,
        Id: this.slotTime.Id,
        IsDefault: this.slotTime.Type == SlotType.DefaultSlot
      }];
      this.subscriptions.add(this.partyService.removeManualSlot(ids, true).subscribe(data => {
        this.setSlots();
      }));
    }
  }

  enableSlot() {
    const ids: ManualSlotIdDTO[] = [{
      DateTime: this.slotTime.DateTime,
      Id: this.slotTime.Id,
      IsDefault: this.slotTime.Type == SlotType.DefaultSlot
    }];
    this.subscriptions.add(this.partyService.removeManualSlot(ids, false).subscribe(data => {
      this.setSlots();
    }));
  }

  closePopover() {
    this.closeSlot.emit();
  }

  showManualSlotAuditLog() {
    const showAlert = false;
    const title = this.translateService.instant('auditlog');
    const popUpMessage = [{
      dialogTitle: title, showAlert, type: AuditableEntityType.ManualSlot, slot: this.slotTime
    }];

    const componentDetails: ComponentDetails = {
      componentName: AuditlogComponent,
      dimensionType: 'large',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };


    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: popupDialogDimension.createDialogHeight,
      width: popupDialogDimension.createDialogWidth,
      data: {
        title,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: false
      }
    });
  }

  setSlots():void {
    if(!this.isSlots)
    this.partyService.getTimeDetails(Utilities.formateDateString(this.partyService.reservationFormGroup.value.selectedDate));
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
