import { Component, OnInit } from '@angular/core';
import { FieldConfig } from '../../models/field-config.interface';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';

@Component({
  selector: 'app-form-slider',
  templateUrl: './form-slider.component.html',
  styleUrls: ['./form-slider.component.scss']
})
export class FormSliderComponent implements OnInit, Field {
  config: FieldConfig;
  group: UntypedFormGroup;
  constructor() { }

  ngOnInit() {
  }


  sliderChanged(event){
    if (this.config.cellClick) {
      this.config.cellClick(event);
    }

  }
}
