import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Host, Input,  Optional, Output, SkipSelf, ViewEncapsulation } from '@angular/core';
import { ControlContainer, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import {  Subscription } from 'rxjs';
import { AgDateAttributes, AgDateConfig, AgDateType, AgysControlOnDateChange } from '../../Models/ag-models';
import { BaseAGYSDateControl } from './base-agys-date-control';
import { AgDateBehaviour } from './ag-date-behaviour';
import { Localization } from '../../shared/localization/Localization';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';

@Component({
    selector: 'ag-date',
    templateUrl: 'ag-control-date-mat.html',
    styleUrls: ['ag-control-date-mat.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: AgysMatDate },
    {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => AgysMatDate),
    }],
    encapsulation: ViewEncapsulation.None
})
export class AgysMatDate extends BaseAGYSDateControl<string | Date, string | Date> {


    @Output() dateChange: EventEmitter<AgysControlOnDateChange> = new EventEmitter<AgysControlOnDateChange>();
    inputConfig: AgDateConfig;


    mapToForm(value: string | Date): string | Date {
        return this.getDateCtlBehaviour().toFormMapper(value);
    }
    mapToDom(value: string | Date): string | Date {
        if (this.isWriteValueChange) {
            return this.getDateCtlBehaviour().toDomMapper(value);
        }

        return value;
    }  


    behaviour: AgDateBehaviour = new AgDateBehaviour(this.localization, this.utils);
    @Input() automationId: string;

    private getDateCtlBehaviour = () =>
        this._type && this.behaviour.inputTypes.find(o => o.type == this._type);

    valueChangeSubscription: Subscription;

    @Input()
    get value(): string | Date | null {
        if (this.customFormGrp.valid) {
            const {
                value: { agysControl }
            } = this.customFormGrp;

            return this.mapToForm(agysControl);
        }
        return null;

    }
    set value(tel: string | Date | null) {
        const telForm = tel ? this.mapToDom(tel) : tel;
        this.customFormGrp.controls['agysControl'].setValue(telForm, { emitEvent: false });
        this.stateChanges.next();
    }

    private _minDate: Date = undefined;
    @Input()
    public get minDate(): Date {
        return this._minDate;
    }
    public set minDate(value: Date) {
        this._minDate = value;
    }

    private _maxDate: Date = undefined;
    @Input()
    public get maxDate(): Date {
        return this._maxDate;
    }
    public set maxDate(value: Date) {
        this._maxDate = value;
    }

    @Input() customPlaceholder;


    private setAgDateComponent(type: AgDateType | undefined) {
        let className = 'ag_form-control--sm';
        let _maxDate, _minDate: Date = undefined;

        if (typeof type !== 'undefined') {
            const ctlBehaviour = this.behaviour.inputTypes.find(o => o.type == this._type);
            this.validateCtrl(ctlBehaviour, type);
            className = ctlBehaviour.customClass;
            _maxDate = this.maxDate || ctlBehaviour.maxDate;
            _minDate = this.minDate || ctlBehaviour.minDate;
        }


        this.inputConfig = {
            className: className,
            form: this.customFormGrp,
            formControlName: 'agysControl',
            placeHolder: this.placeholder,
            placeHolderId: '',
            maxDate: _maxDate,
            minDate: _minDate,
            errorMessage: `Missing ${this.placeholder}`,
            isDisabled: this.disabled,
            isDateRequired: this.required,
            automationId : this.automationId
        };

    }

    validateCtrl(ctlBehaviour: AgDateAttributes, type: AgDateType) {
        if (!ctlBehaviour) {
            alert(`Control Type ${type} Not Found`);
            throw (`Control Type ${type} Not Found`);
        }
    }

    private _type: AgDateType = AgDateType.DEFAULT;
    @Input()
    public get type(): AgDateType {
        return this._type;
    }
    public set type(value: AgDateType) {
        this._type = value;
    }

    constructor(@Optional() @Host() @SkipSelf()
    public controlContainer: ControlContainer, public fb: UntypedFormBuilder,
        public localization: Localization, public cd: ChangeDetectorRef,
        public utils: CommonUtilities
    ) {
        super(controlContainer, fb);

    }



    ngOnChanges() {
        this.updateControlComponent();
    }

    ngAfterViewInit() {
        this.subscribeToValueChanges();
    }

    updateControlComponent() {
        this.setAgDateComponent(this._type);
        this.inputConfig = { ...this.inputConfig }
    }

    onDateChange(e) { 
        this.subscribeToValueChanges();       
        this.isOnLoadCompleted = true;
        const dateObj: Date = this.localization.getDate(this.value);
        const monthObj = this.localization.monthsArray.find(s => s.id === dateObj.getMonth());
        const dayObj = this.localization.daysArray.find(s => s.id === dateObj.getDay());
        const event: AgysControlOnDateChange = {
            date: dateObj,
            dateISOString: this.value as string,
            dateInMilliSeconds: dateObj.getTime(),
            dayCode: dayObj.code,
            localizedDisplayDate: this.localization.localizeDisplayDate(new Date),
            localizedDay: dayObj.short,
            localizedDayLong: dayObj.long,
            localizedMonthShort: monthObj.short,
            localizedMonthLong: monthObj.long,
            day: dateObj.getDay(),
            year: dateObj.getFullYear(),
            month: dateObj.getMonth(),
            hour: new Date().getHours(),
            minute: new Date().getMinutes(),
            second: new Date().getSeconds()
        }
        this.dateChange.emit(event);
    }





}
