import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter, Input, ContentChildren, QueryList, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { VIRTUAL_SCROLL_STRATEGY, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CustomVirtualScrollStrategy, GridTableDataSource } from './cdkvirtual.base';
import { CdkDragDrop, moveItemInArray, CdkDragHandle, transferArrayItem } from '@angular/cdk/drag-drop';
import { SorTypeEnum, FromTypeEnum, ActionTypeEnum, HdrActionTypeEnum, aligment } from './cdkvirtual.model';
import { TableActions } from '../../enums/shared-enums';
import * as _ from 'lodash';
import * as myGlobals from 'src/app/common/shared/shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ButtonValue, AgDurationConfig, TableOptions, TableHeaderOptions,ServerPaginationConfiguration } from 'src/app/common/Models/ag-models';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonTypes, AlertType, AlertAction } from 'src/app/common/Models/common.models';
import { Localization } from '../../localization/localization';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from './cdkvirtual.excel.service';
import { I } from '@angular/cdk/keycodes';


export const TOT_REC_HEIGHT = 24;

@Component({
  selector: 'app-cdkvirtual',
  templateUrl: './cdkvirtual.component.html',
  styleUrls: ['./cdkvirtual.component.scss'],
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy },ExcelService,{ provide: MatPaginatorIntl }],
  changeDetection: ChangeDetectionStrategy.OnPush
  ,
  encapsulation: ViewEncapsulation.None
})


export class CdkvirtualComponent implements OnInit, OnDestroy {
 
  placeholderHeight = 0;
  dynamicHeight = 110;
  totalrecordHeight = 24;
  showTotalRecords: boolean;
  checkvaluechanged: any;
  filterPopover: boolean;
  checkeddata: any[] = [];
  originaldata: any[];
  _tableContent: any = [];
  ordertype: string;
  sortingColoumnKey: any;
  selectedData: any[] = [];
  commentsForm: UntypedFormGroup;
  isReadOnlyAllowed: boolean = false;
  _options: TableOptions;
  footer: any;
  showFooter: boolean;
  isFilterByAPI: boolean = false;
  isModified = false;

  _configureHeaderOptions: any[]
  cdk_configureButtonType: ButtonValue;

  _headerOptions: TableHeaderOptions[];
  clickedRowData: string | number;
  uniqueKey: string;
  scrolledIndex: number;
  activeRowIdx: number;
  paginationHeight: number = 0;
  enableExportExcel:boolean = false;
  aligment = aligment;
  selectedRowId = -1;
  defaultPageSize;
  prodId: number;
  floatLabel: string;
  noDataLabel: string;
  imageCaption: string;
  _orgtableContent: any;
  @Output() serverPageEmit = new EventEmitter();
  serverPageConfig:ServerPaginationConfiguration;
  totalRecords: any;
  PMSProduct:number = myGlobals.Product.PMS;
  pageSize: number = 10;
  lastUpdatedPageSize: number;
  selectedIndex: number;
  paginatorIndex: number;
  scrollPosition: any;
  @Input('headerOptions')
  set headerValue(value: TableHeaderOptions[]) {    
    this._headerOptions = value;
    this.displayedColumns = value && value.map(col => col.key);
    this._configureHeaderOptions = this.uiHDRmapper(this._headerOptions);
    if (this.searchText) { this.SearchFilter(this.searchText)};
  
  }

  @Input('serverPageConfig')
  set serverPageConfigValue(value: ServerPaginationConfiguration) {
    this.serverPageConfig = value;
  }
  @Input('totalRecordsCount')
  set recordCountValue(value: number){
    this.totalRecords = value;
  }
  @Input('retaindata') 
  set lastvisitedData(value){
    if(value) {
      let page = sessionStorage.getItem('pageSize')
      if(page) {
        this.pageSize = Number(page);
        sessionStorage.removeItem('pageSize')
      }
    }
  }

  @Input()
  set options(value: TableOptions) {
    this._options = _.cloneDeep(value);
    if(this._options){
    this.paginationHeight = this._options.enablePagination ? 54 : 0;
    this.ordertype = this._options.defaultSortOrder ? this._options.defaultSortOrder : SorTypeEnum.asc;
    this.sortingColoumnKey = this._options.defaultsortingColoumnKey;
    this.dynamicHeight = ((this._options.showTotalRecords) || (this._options.CDK_showConfigure)) ? this.dynamicHeight + this.totalrecordHeight : this.dynamicHeight
    this.totalrecordHeight = ((this._options.showTotalRecords) || (this._options.CDK_showConfigure)) ? TOT_REC_HEIGHT + this.paginationHeight : 0 + this.paginationHeight;
    this.displayedColumns = this._headerOptions && this._headerOptions.map(col => col.key);
    this.deleteMsgKey = this._options.deleteMsgKey ? this._options.deleteMsgKey : 'name';
    this.isDragInProgress = this._options.isDragDisabled;
    this.showDeleteMsg = !this._options.showDeleteMsg ? false : true;
    this.commentKey = this._options.commentKey ? this._options.commentKey : 'comments';
    this.isFilterByAPI = !this._options.isFilterByAPI ? false : true;
    this.uniqueKey = this._options.uniqueKey ? this._options.uniqueKey : 'id';
    this.enableExportExcel = this._options.enableExportExcel ? this._options.enableExportExcel : false;
    this.defaultPageSize = this._options.defaultPageSize ? this._options.defaultPageSize: 10;
    this.noDataLabel = this._options?.noDataLabel;
    this.imageCaption = this._options?.imageCaption;
    if (this._options.enablePagination || this._options?.enableServerPagination ) {
      this.dataSource = new MatTableDataSource(this._tableContent);      
      this.dataSource.paginator = this.paginator;
    } else{ 
      if(!this.dataSource || this.dataSource.hasOwnProperty('filteredData')){
      this.dataSource = new GridTableDataSource(this._tableContent, this.viewport, this.customRowHeight ? this.customRowHeight : this.itemSize, this.onDrag);      
      this.dataSource.offsetChange.pipe(takeUntil(this.destroyed$)).subscribe(offset => {
        this.placeholderHeight = offset;
        setTimeout(()=> this.cdRef.detectChanges(),1);
      });
      } else {
        this.dataSource.data = this._tableContent
      }
    }
    if (this._tableContent && this._options && this._options.showTotalRecords || this.prodId==this.PMSProduct) {
      this.totalMsg = this._Localization.replacePlaceholders(this.captions.lbl_totalRecordsFoundMsg, ['count'], [this._tableContent.length]);
    }

    if (this._options?.commentKey) {
      this.commentsForm = this.fb.group({
        comment: [''],
        currentRow: ['']
      });
      this.commentsForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.actionButton.disabledproperty = !(this.commentsForm.valid && this.commentsForm.dirty);
      });
    }
  }
  }

  @Input() IsEditModeEnabled: boolean;
  @Input() customRowHeight: number;
  searchText: string;
  tableActionEnums: typeof TableActions;
  actionTypeenum = ActionTypeEnum;
  hdrActionTypeenum = HdrActionTypeEnum;
  sorTypeenum = SorTypeEnum;
  captions: any;
  deleteMsgKey: any;
  showDeleteMsg: boolean;
  isDragInProgress: boolean;
  selectedItemId: string | number;
  onDrag: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() childTemplate: TemplateRef<any>;
  inlineEditTriggered: boolean;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  commentKey: string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  boundary: any;
  dragDisabled: boolean = true;
  isInavalidNumber: boolean;
  durationInput: AgDurationConfig;
  @Output() isEditModeOn = new EventEmitter();
  @Output() SearchChangeEmitter = new EventEmitter();
  @Input() isViewMode: boolean;
  checkboxKey: string;
  totalMsg: string;
  @Input()
  set searchOptions(searchtext) { if (searchtext != undefined) { this.searchText = searchtext; this.SearchFilter(searchtext); } }
  get searchOptions() { return this.searchText; }
  @Input('scrollItem')
  set scrollItem(value) {
    if (value) {
      if(value.selectedIndex >= 0)
      this.selectedIndex = value.selectedIndex;
      setTimeout(() => {
        this.scroll(value.selectedIndex);
        }, 500);
    }
  }
  @Input('footerObj')
  set footerValue(value) {
    this.showFooter = true;
    this.footer = value; 
  }
  @Input('manualInlineEditObj')
  set manualInlineEdit(value) {
    if (value && value.isManualInlineEdit) {
      this.emitEditrowdata('', value.data, null);
    }
   }
  @Input()
  set tableContent(value) {
    this.IsEditModeEnabled = false;
    this._orgtableContent=value;
    this._tableContent = value ? _.cloneDeep(value) : [];
    this.checkboxKey = this._options && this._options.checkboxKey ? this._options.checkboxKey : 'isChecked';
    this.checkeddata = this._tableContent && this._tableContent.length && this._tableContent.filter(x => x && x[this.checkboxKey]);
    this.originaldata = _.cloneDeep(value);
    if (this.searchText !== undefined && !this.isFilterByAPI) { this.SearchFilter(this.searchText); }
    const tempOrder = (this._options && this._options.defaultSortOrder ? this._options.defaultSortOrder : SorTypeEnum.asc);
    this.ordertype = this.ordertype ? this.ordertype : tempOrder;
    const tempColumn = (this._options && this._options.defaultsortingColoumnKey ? this._options.defaultsortingColoumnKey : this.findFirstColoum());
    this.sortingColoumnKey = this.sortingColoumnKey ? this.sortingColoumnKey : tempColumn;
    if (this._options && !this._options.ignoreSort) {
      this.contentSorting(this.sortingColoumnKey, this.ordertype);
    }
    this.isReadOnlyAllowed = this._options && this._options.isReadOnly;
    this.displayedColumns = this._headerOptions && this._headerOptions.map(col => col.key);
    if (this.dataSource && this._tableContent) {    
      this.IsEditModeEnabled = false;
      this.inlineEditTriggered = false;
      if(this._options){
      if (this._options.enablePagination || this._options?.enableServerPagination) {
        this.dataSource = new MatTableDataSource<any>(this._tableContent);
        this.dataSource.paginator = this.paginator;
      } else {
        this.dataSource.data = this._tableContent;
        this.itemSize = this.customRowHeight ? this.customRowHeight: 38;        
        this.dataSource['itemSize'] = this.itemSize;
        this.viewport.scrollToOffset(this.itemSize * this.scrolledIndex);
      }
      this.dataBind.emit({
        fromType: FromTypeEnum.valueBind,
        array: this._tableContent,
        value: '',
        Obj: ''
      });
    }
    }
    this.isDragInProgress = (this._options && this._options.isDragDisabled ? this._options.isDragDisabled : false) || !value;
    if (this._tableContent && this._options && this._options.showTotalRecords || this.prodId==this.PMSProduct) {
      this.totalMsg = this._Localization.replacePlaceholders(this.captions.lbl_totalRecordsFoundMsg, ['count'], [this._tableContent.length]);
    }

    this._configureHeaderOptions && this.onApplyClick();
    if(value?.length == 0) this.noDataLabel = this.captions?.lbl_noDataFound;
    this.clickedRowData = (this._options && this._options.isRowClick && this._tableContent ) ? this._tableContent[0][this.uniqueKey] : '';
    setTimeout(() => {
      if(value && this.selectedIndex>=0) {
        let selectRecordIndex = (this.selectedIndex + 1) % this.pageSize;
        if(selectRecordIndex === 0) {
          selectRecordIndex = this.pageSize;
        }
        const element = document.getElementsByClassName('ag_table');
        const tbody = element[0].children[0].children[0].children[1];
        if(tbody) {
          const trElements = tbody.getElementsByTagName('tr');
          if(trElements) {
            trElements[selectRecordIndex  ].classList.add('highlight-row')
          }
        }
      } 
    },1000) 
  }

  displayedColumns: any[];
  dataSource: GridTableDataSource | MatTableDataSource<any>;

  itemSize = 38;

  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('preview') preview;
  @ViewChild('comment') comment;
  @ViewChild('edit') edit;
  @ViewChild('copy') copy;
  @ViewChild('delete') delete;
  @ViewChild('drag') drag;
  @ViewChild('done') done;
  @ViewChild('cancel') cancel;
  @ViewChild('menu') menu;
  @ViewChild('userBlock') userBlock;
  @ViewChild('numberInput') numberInput;
  @ViewChild('decimalInput') decimalInput;
  @ViewChild('currencyInput') currencyInput;
  @ViewChild('decimalInputMaxval') decimalInputMaxval;
  @ViewChild('notesPopover') myPopover;
  @ViewChild('alphaNumeric') alphaNumeric;
  @ViewChild('columnWithNumber') columnWithNumber;
  @ViewChild('replace') replace;
  @ViewChild('history') history;
  @ViewChild('sync') sync;
  @ViewChild('datepicker') datepicker;
  @ViewChild('customAction') customAction;
  @ViewChild('folioAction') folioAction;  
  @ViewChild('CDK_configurePopover') CDK_configurePopover;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('download') download;
  @ViewChild('denialAction') denialAction;
  @ViewChild('reservationAction') reservationAction;

  @ContentChildren(CdkDragHandle, { descendants: true }) dragHandles: QueryList<CdkDragHandle>
  @Output() EmittedData = new EventEmitter();
  @Output() EmitHdrClick = new EventEmitter();
  @Output() sortEmit = new EventEmitter();
  @Output() rowClickEmit = new EventEmitter();
  @Output() dataBind = new EventEmitter();
  constructor(private _Localization: Localization, private _utilities: CommonUtilities, private fb: UntypedFormBuilder, private cdRef: ChangeDetectorRef,private excelService:ExcelService,public _MatPaginatorIntl: MatPaginatorIntl  ) {
    this.captions = this._Localization.captions;
    this.floatLabel = this._Localization.setFloatLabel;
    this.dataSource = new MatTableDataSource();
    this.noDataLabel = '';
    this.imageCaption = ''
  }

  ngOnInit() {
    this.prodId=Number(this._Localization.GetsessionStorageValue('propertyInfo', 'ProductId'));
    this._MatPaginatorIntl = new MatPaginatorIntl();
    this._MatPaginatorIntl.itemsPerPageLabel = this.captions.common.itemsPerPage;
    this.IsEditModeEnabled = false;
    this.tableActionEnums = TableActions;
    if(this._options)    {
    if ( this._options.enablePagination || this._options?.enableServerPagination) {
      this.dataSource = new MatTableDataSource(this._tableContent)
    } else {    
      if(!this.dataSource){
      this.dataSource = new GridTableDataSource(this._tableContent, this.viewport, this.customRowHeight ? this.customRowHeight : this.itemSize, this.onDrag);           
      this.dataSource['itemSize'] = this.itemSize;   
      this.dataSource.offsetChange.pipe(takeUntil(this.destroyed$)).subscribe(offset => {
        this.placeholderHeight = offset;
      });
      }
    }}
    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
   
    if (this._tableContent && this.dataSource) {
      this.dataSource.data = this._tableContent;
      this.emitTableDataOnInit();
    }
    this.cdk_configureButtonType = {
      label: this.captions.btn_apply,
      type: 'secondary',
      customclass: 'ag_w--100',
      disabledproperty: true
    };
  }

  ngAfterViewInit() {
    if(this._options){
    if ( this._options.enablePagination || this._options?.enableServerPagination) {
      (this.dataSource as MatTableDataSource<any>).paginator = this.paginator;
    } else {
      this.viewport.scrolledIndexChange.pipe(takeUntil(this.destroyed$)).subscribe(x => {
        this.scrolledIndex = x;
      })
    }
  }
  }



  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    this.checkInstanceExist();
  }

  scroll(selectedIndex) {  
    if(this.paginator){
      let page = sessionStorage.getItem('pageSize')
      if(page) {
        this.pageSize = Number(page);
        sessionStorage.removeItem('pageSize')
      }
      if(!this.pageSize) {
        this.pageSize = 5 //5 is assigned because the starting value for pageSize is 5
      }
      let idx;
      idx = (selectedIndex)/this.pageSize;
      if(idx >= 1) {
        if(parseInt(idx) === idx) {
          this.paginatorIndex = idx
        } else {
          this.paginatorIndex =  Math.ceil(idx) - 1;
        }  
      }
      else {
        this.paginatorIndex =  Math.floor(idx)
      }
      this.paginator.pageIndex = this.paginatorIndex;
      const event: PageEvent = {
        length: this.paginator.length,
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize
     };
     this.paginator.page.next(event); 
    this.scrollPosition = sessionStorage.getItem('cdkScrollPosition')
    setTimeout(()=> {
        this.viewport.scrollTo({top : this.scrollPosition})
        }, 500)
    } else{
      this.viewport.scrollToOffset(this.itemSize * selectedIndex, 'smooth');
    }
  }

  placeholderWhen(index: number) {
    return index == 0;
  }
  /**
   * cdk drop
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
   
    this.emitDragrowdata(event);
    this.onDrag.next(false);
    this.dragDisabled = true;
  }
  /**
   * more options
   * @param e
   * @param menu
   * @param data
   */
  menuOptionsClick(e, menu, data) {
    switch (menu.id) {
      case TableActions.delete: if (this.showDeleteMsg) {
        const deleteWarningMessage = `${this.captions.lbl_delete}  ${data[this.deleteMsgKey]}`;
        this._utilities.showCommonAlert(deleteWarningMessage, AlertType.Warning, ButtonTypes.YesNo, (res) => {
          if (res === AlertAction.YES) {
            this.menudeleteEmitData(data, menu);
          }
        });
      } else {
        this.menudeleteEmitData(data, menu);
      }
        break;
      case TableActions.edit: this.IsEditModeEnabled = true;
        data.isEditable = true;
        this.inlineEditTriggered = this.options && this.options.isInternalEdit;
        this.EmittedData.emit({
          fromType: FromTypeEnum.edit,
          array: '',
          value: '',
          Obj: menu,
          menuData: data
        });
        break;
      case TableActions.copy: this.IsEditModeEnabled = true;
        data.isEditable = true;
        this.inlineEditTriggered = this.options && this.options.isInternalEdit;
        this.EmittedData.emit({
          fromType: FromTypeEnum.copy,
          array: '',
          value: '',
          Obj: menu,
          menuData: data
        });
        break;
      default: this.EmittedData.emit({
        fromType: FromTypeEnum.menuoption,
        array: '',
        value: '',
        Obj: menu,
        menuData: data
      });
        break;
    }
  }

  menudeleteEmitData(data, menu) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.delete,
      array: '',
      value: '',
      Obj: menu,
      menuData: data
    });
  }

  /**
   * <th> checkbox select all
   * @param eve
   */
  emitcheckAlldata(eve, key) {
    this.checkeddata = [];
    const checkBoxKey = key ? key : 'checked';
    if (eve.checked) {
      this.dataSource.data.forEach(x => {
        if (x['isCheckboxDisabled']) {
          return;
        }
        else {
          x[checkBoxKey] = true
        }
      });
      this.checkeddata = _.cloneDeep(this.dataSource.data);
      this.checkeddata = [...this.checkeddata];    
    } else {
      this.dataSource.data.forEach(x => {
        if (x['isCheckboxDisabled']) {
          return;
        }
        else {
          x[checkBoxKey] = false
        }
      });
      this.checkeddata = [];
      this.checkeddata = [...this.checkeddata];
    }
    this.isModified = !this.isModified;
    this.EmittedData.emit({
      fromType: FromTypeEnum.allcheckbox,
      array: this.dataSource.data,
      value: eve.checked,
      Obj: '',
      checkedData: this.checkeddata
    });
  }

  /**
  * <td> checkbox select
  * @param eve
  * @param data
  */
  emitcheckdata(eve, data, key) {
    sessionStorage.setItem("cdkScrollPosition", this.viewport.elementRef.nativeElement.scrollTop.toString());
    data[key] = eve.checked;
    this.isModified=!this.isModified
    
    this.checkUncheckDataEmitter(eve.checked, data,key); 
  }

  checkUncheckDataEmitter(isChecked, data,key?:string) {
    if (isChecked) {
      this.checkeddata.push(data);
    } else {
      const index = this.checkeddata.findIndex(x => x[this.uniqueKey] === data[this.uniqueKey]);
      this.checkeddata.splice(index, 1);
      this.checkeddata = [...this.checkeddata];
    }
    this.EmittedData.emit({
      fromType: FromTypeEnum.rowcheck,
      array: this._tableContent,
      value: '',
      Obj: data,
      checkedData: this.checkeddata,
      key
    });
  }
  /**
   * row edit click
   * @param eve
   * @param data
   */
  emitEditrowdata(eve, data, key) {
    sessionStorage.setItem("cdkScrollPosition", this.viewport.elementRef.nativeElement.scrollTop.toString());
    if(this.lastUpdatedPageSize) {
      sessionStorage.setItem("pageSize", this.lastUpdatedPageSize.toString());
    }
    this.IsEditModeEnabled = true;
    if (this._options.isInternalEdit) {
      data.isEditable = true;
      this.inlineEditTriggered = true;
    } 
    this.EmittedData.emit({
      fromType: this.inlineEditTriggered ? FromTypeEnum.inlineEdit : FromTypeEnum.edit,
      array: '',
      value: '',
      Obj: data,
      key
    });
    this.isEditModeOn.emit(this.inlineEditTriggered);
  }

  emitCopyrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.copy,
      array: '',
      value: '',
      Obj: data
    });
  }

  emitReplacerowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.replace,
      array: '',
      value: '',
      Obj: data
    });
  }

  /**
   * row delete click
   * @param eve
   * @param data
   */
  emitDeleterowdata(eve, data) {
    if (this.showDeleteMsg) {
      const deleteWarningMessage = `${this.captions.lbl_delete}  ${data[this.deleteMsgKey]}`;
      this._utilities.showCommonAlert(deleteWarningMessage, AlertType.Confirmation, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.deleteEmitData(data);
        }
      });
    } else {
      this.deleteEmitData(data);
    }

  }



  /**
   * row block click
   * @param eve
   * @param data
   */
  emitBlockrowdata(eve, data) {
    this.blockEmitData(data);
  }

  emitDownloadrowdata(eve,data) {
    this.downloadEmitData(data);
  }


  emitCommentrowdata(arg, data, headervalue, idx) {

    this.commentsForm.get('comment').setValue(data[this.commentKey]);
    this.commentsForm.get('currentRow').setValue(data);
    if (data.isNotesActionHide) this.commentsForm.get('comment').disable();
    else this.commentsForm.get('comment').enable();
    this.positionPopover(arg, headervalue, idx);
  }
  emitDonerowdata(arg, data) {
    if(data && data['isRowInvalid'])
    {
      return;
    }
    this.EmittedData.emit({
      fromType: FromTypeEnum.done,
      array: '',
      value: '',
      Obj: data
    });
    data.isEditable = false;
    this.inlineEditTriggered = false;
    this.IsEditModeEnabled = false;
    this.isEditModeOn.emit(this.inlineEditTriggered);
  }
  emitCancelrowdata(arg, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.cancel,
      array: '',
      value: '',
      Obj: data
    });  
    if (data && data.id) {
      const orgObj = this.originaldata.find(x => x.id === data.id);
      Object.keys(orgObj).forEach(k => {
        data[k] = orgObj[k];
      });
    }
    data.isEditable = false;
    this.IsEditModeEnabled = false;
    this.inlineEditTriggered = false;
    this.isEditModeOn.emit(this.inlineEditTriggered);
  }

  deleteEmitData(data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.delete,
      array: '',
      value: '',
      Obj: data
    });
  }

  blockEmitData(data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.userBlock,
      array: this._tableContent,
      value: '',
      Obj: data
    })
  }

  downloadEmitData(data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.download,
      array: this._tableContent,
      value: '',
      Obj: data
    })
  }

  emitDragrowdata(event) {
    this.isDragInProgress = true;
    const draggedoj = event.item.data;
    
   
    const droppeddoj = this.pageIndex(event);

    const data = {
      dragdata: draggedoj,
      dropdata: droppeddoj
    };
    this.EmittedData.emit({
      fromType: FromTypeEnum.dragdrop,
      array: '',
      value: '',
      Obj: data
    });
  }

  // differentiate between mattabledata source (pagination page obj) & gridtabledata source(not a pagination screen)
  pageIndex(event) {
    if (this._options.enablePagination) {
      let paginatorVariable = event.container.data._paginator;
      let currentIndex = (paginatorVariable._pageSize * paginatorVariable._pageIndex) + event.currentIndex ;
      return event.container.data['filteredData'][currentIndex];
    }else if(this._options?.enableServerPagination){
      let currentIndex = (this.serverPageConfig.initPageSize * this.serverPageConfig.initPageIndex) + event.currentIndex;
      return event.container.data['filteredData'][currentIndex];
    } else {
      return event.container.data['visibleData'].getValue()[event.currentIndex]
    }
  }

  positionPopover(arg, header, idx) {
    const viewportElement = this.viewport.elementRef.nativeElement;
    const targetElement = arg.currentTarget.getBoundingClientRect();
    const popoverContent = this.myPopover.element.nativeElement.childNodes[0];
    const popoverWidth = popoverContent.offsetWidth;
    const x = viewportElement.querySelectorAll('.cdk-column-action')[idx] as HTMLElement;
    const topPosition = x.getBoundingClientRect().top;
    this.myPopover.top = topPosition - 88;
    this.myPopover.left = targetElement.left - popoverWidth;
    this.myPopover.title = header.tooltip ? header.tooltip : '';
  }


  /**
  * <th> on click row sorting
  * @param key
  * @param type
  */
  sorting(key, type) {
    this.sortingColoumnKey = key;
    let sortype;
    if (type == SorTypeEnum.asc) {
      sortype = this.ordertype = SorTypeEnum.desc;
    } else {
      sortype = this.ordertype = SorTypeEnum.asc;
    }
    if (this.dataSource && this._tableContent) {
      this.dataSource.data = this.contentSorting(key, sortype);
      this.sortEmit.emit({
        fromType: FromTypeEnum.sort,
        array: this._tableContent,
        sortColumnkey:key,
        sortype: sortype
      });
    }
  }

  /**
  * content sorting
  * @param key
  * @param sortype
  */
  contentSorting(key, sortype) {
    if (this._tableContent && this._tableContent.length > 0) {
      this._tableContent = _.orderBy(this._tableContent, [(o) => {
        const val = this.getDescendantProp(o, key);
        if (typeof (val) == 'string') {
          return val.toLowerCase();
        } else {
          return val;
        }
      }], sortype);
      if(this._options && this._options.enablePagination ){
      this.paginator.pageIndex = 0;}
      else if(this._options?.enableServerPagination){
        
      }
    }
    return this._tableContent;
  }

  getDescendantProp(obj, key) {
    const arr = key.split('.');
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  }

  findFirstColoum(): string {
    const headerarray = this._headerOptions && this._headerOptions.filter(x => x.searchable).map(x => x.key);
    return headerarray && headerarray[0];    
  }

  /**
   * search filter
   * @param searchText
   */
  SearchFilter(searchText: string): any {
    const headerarray = this._headerOptions && this._headerOptions.filter(x => x.searchable).map(x => x.key);
    if (headerarray && headerarray.length > 0 && this.originaldata) {
      this._tableContent = this.originaldata.filter(result => {
        const headerKey = headerarray;
        for (const key in result) {
          if (typeof (result[key]) == 'string' && result[key].toLowerCase().includes(searchText.toLowerCase())) {
            if (headerKey.indexOf(key) != -1) {
              return result[key].toLowerCase().includes(searchText.toLowerCase());
            }
          } else if (typeof (result[key]) == 'number') {
            if (headerKey.indexOf(key) != -1) {
              const matchedValue = Number(result[key].toString().toLowerCase().includes(searchText.toLowerCase()));
              if (matchedValue) {
                return matchedValue;
              }
            }
          } else if (Array.isArray(result[key])) {
            if (headerKey.indexOf(key) != -1) {
              const matchedArr = result[key].filter(x => {
                if (typeof (x) == 'string' && x.toLowerCase().includes(searchText.toLowerCase())) {
                    return x.toLowerCase().includes(searchText.toLowerCase());
                } else if (typeof (x) == 'number') {
                    const matchedValue = Number(x.toString().toLowerCase().includes(searchText.toLowerCase()));
                    if (matchedValue) {
                      return matchedValue;
                    }
                }
              });
              if (matchedArr.length > 0) {
                return matchedArr;
              }
            }
          }
        }
      });
      const sortedResult = this.contentSorting(this.sortingColoumnKey, this.ordertype);
      if (this.dataSource && this._tableContent) {
        this.dataSource.data = sortedResult;
      }
      this.totalMsg = this._Localization.replacePlaceholders(this.captions.lbl_totalRecordsFoundMsg, ['count'], [this._tableContent.length]);
      this.viewport.checkViewportSize()
    }
    this.SearchChangeEmitter.emit(this._tableContent);

    if(this._tableContent?.length == 0) this.noDataLabel = this.captions?.lbl_noDataFound;
  }

  /**
   * Event on Toggle Change in table tow
   * @param change event
   */
  toggleChange(event, row, key) {
    sessionStorage.setItem("cdkScrollPosition", this.viewport.elementRef.nativeElement.scrollTop.toString());
    if (row) {
      row[key].value = event.checked;
      this.EmittedData.emit({
        fromType: FromTypeEnum.switch,
        array: this._tableContent,
        value: event.checked,
        Obj: row,
        toggleKey: key
      });
    }
  }

  emitTableData(eve, data, key, flag) {
    if (flag) {
      return;
    }    
    data['isRowInvalid'] = this.isInavalidNumber;
    this.EmittedData.emit({
      fromType: FromTypeEnum.input,
      array: this._tableContent,
      value: eve.target.value,
      Obj: data,
      updatedKey: key,
      isInavalidNumber: this.isInavalidNumber
    });
  }

  emitTableDataOnInit() {
    this.EmittedData.emit({
      fromType: FromTypeEnum.oninit,
      array: this._tableContent,
      value: '',
      Obj: '',
    });
  }

  /**
  * Event on Header Action Click
  * @param event, Hdr Key
  */
  emitDropdownData(eve, data, key) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.dropdown,
      array: this._tableContent,
      value: eve.target.value,
      Obj: data,
      updatedKey: key
    });
  }
  /**
  * Event on Header Action Click
  * @param event, Hdr Key
  */

  headerActionClick(from, hdr) {
    this.EmitHdrClick.emit(
      {
        fromType: from,
        key: hdr,
        array: this._tableContent
      });
  }

  emitSelecteddata(eve, data, key, radioKey) { 
    if(data[radioKey] !== undefined){
      data[radioKey] = true;
    }
    this.selectedData = [];
    this._tableContent.forEach(x => {
      if(x[key] !== undefined){
        x[radioKey] = x[key] === data[key];
      }
     });
    this.selectedData.push(data);
    this.EmittedData.emit({
      fromType: FromTypeEnum.radioButton,
      array: this._tableContent,
      value: '',
      Obj: data,
      selectedData: this.selectedData
    });
  }

  saveNote() {
    const currentRow = this.commentsForm.get('currentRow').value;
    currentRow[this.commentKey] = this.commentsForm.value.comment;
    this.EmittedData.emit({
      fromType: FromTypeEnum.comment,
      array: this._tableContent,
      value: this.commentsForm.value.comment,
      Obj: currentRow
    });
    this.commentsForm.reset();
  }

  cancelComment() {
    this.commentsForm.reset();
  }

  recordMove(arg) {
    this.onDrag.next(true);
  }

  startDrag(arg){
    this.onDrag.next(true);
  }

  trackByFn(idx, obj) {
    return idx;
  }

  validateMaxValue(event, maxValue) {
    if (maxValue && event) {
      if (Number(event.target.value) > maxValue) {
        event.target.classList.add('invalid-number');


      } else {
        if (event.target.classList.contains('invalid-number')) {
          event.target.classList.remove('invalid-number');
        }


      }
      this.validateSave();
      this.isInavalidNumberValidate()

    }
  }

  isInavalidNumberValidate() {
    if (document.getElementsByClassName('invalid-number').length) {
      this.isInavalidNumber = true
    } else {
      this.isInavalidNumber = false
    }
  }

  validateSave() {
    if (document.getElementsByClassName('invalid-number').length && document.getElementsByClassName('icon-save')[0]) {
      document.getElementsByClassName('icon-save')[0].classList.add('disabled');
    } else {
      if (document.getElementsByClassName('icon-save')[0]
        && document.getElementsByClassName('icon-save')[0].classList.contains('disabled')) {
        document.getElementsByClassName('icon-save')[0].classList.remove('disabled');
      }
    }
  }

  emitCustomActionrowdata(eve, data, customClass) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.customAction,
      array: this._tableContent,
      value: '',
      Obj: data,
      customClass: customClass
    });
  }

  emitFolioActionrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.folioAction,
      array: this._tableContent,
      value: '',
      Obj: data
    });
  }


  emitPreviewrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.preview,
      array: this._tableContent,
      value: '',
      Obj: data
    });

  }

  emitHistoryrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.history,
      array: this._tableContent,
      value: '',
      Obj: data
    });
  }

  emitSyncrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.sync,
      array: this._tableContent,
      value: '',
      Obj: data
    });
  }

  emitDenialActionrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.denialAction,
      array: this._tableContent,
      value: '',
      Obj: data
    });
  }

  emitResrvationActionrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.reservationAction,
      array: this._tableContent,
      value: '',
      Obj: data
    });
  }

  durationEmit(event, key, data) {
    data[key] = event.value;
    this.EmittedData.emit({
      fromType: FromTypeEnum.duration,
      array: this._tableContent,
      value: event,
      Obj: data
    });
  }

  rowClick(e, rowData, rowIdx) {
    this.activeRowIdx = rowIdx;
    this.selectedRowId = (this.checkboxKey && rowData[this.checkboxKey]) || !this.checkboxKey? rowData[this.uniqueKey]: -1;
    this.rowClickEmit.emit(rowData);
  }


  selectRow(rowData, templateName, rowIndex, e) { 
    if(e.target.closest('mat-checkbox') ||e.target.closest('mat-radio-button')){
      return;
    }
    if ((!templateName || (templateName === ActionTypeEnum.custom||templateName === ActionTypeEnum.checkbox||templateName===ActionTypeEnum.radioButton)) && !this._options.isRowClick) {
      let template = this._headerOptions.find(x => x.templateName === this.actionTypeenum.checkbox);
      if (template && !template.isCheckboxDisabled ? !rowData.isCheckboxDisabled : false) { // added template.ischeckboxdisabled for bug 55283(issue 5) pms 
        rowData[template.key] = !rowData[template.key];
        this.checkUncheckDataEmitter(rowData[template.key], rowData);
      }
      let radioTemplate = this._headerOptions.find(x => x.templateName === this.actionTypeenum.radioButton);
      if (radioTemplate) { 
        rowData[radioTemplate.key] = !rowData[radioTemplate.key];
        this.emitSelecteddata(null, rowData, radioTemplate.radioKey, radioTemplate.key);
      }
    }
     //Added condition to avoid event emit on checkbox click
     if (!templateName) {
      this.clickedRowData = rowData[this._options.uniqueKey];
      this.rowClickEmit.emit({
        fromType: FromTypeEnum.rowClick,
        Obj: rowData,
        table: this._tableContent
      });
    }
  }  
  public uiHDRmapper(APIHDRobj) {
    let objcoll = []
    APIHDRobj && APIHDRobj.forEach((element, index) => {
      let obj = {
        key: element.key,
        displayName: element.displayName, 
        disabled: false,
        checked: true,
        show: true,
        order: index,
      };
      objcoll.push(obj);
    });
    return objcoll;
  }
  onCdkDragStarted(e) {
    if (document.querySelectorAll('.cdk-drag-preview')[0]) {
      document.querySelectorAll('.cdk-drag-preview')[0].classList.add('previewIndex');
    }
  }

  onCdkDragEnded(e) {
    if (document.querySelectorAll('.cdk-drag-preview')[0]) {
      document.querySelectorAll('.cdk-drag-preview')[0].classList.remove('previewIndex');
    }
  }
  onApplyClick() {
    this._configureHeaderOptions.forEach((x, index) => {
      x.order = index;
    });
    this.displayedColumns = this._configureHeaderOptions.filter(x => x.checked).map(col => col.key);
    if (this.cdk_configureButtonType) { this.cdk_configureButtonType.disabledproperty = true; }
    if(this._headerOptions != null && this._headerOptions != undefined){
      this._headerOptions=[...this._headerOptions]
    }
  }
  onDrop(event) {
    this.cdk_configureButtonType.disabledproperty = false;

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data,
        event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
 

  }
  configureOptionClick(headerOptions, headerOption, index) {
    headerOption.checked = !headerOption.checked;
  
    this.cdk_configureButtonType.disabledproperty = !headerOptions.some(x => {
      if (x.key === ActionTypeEnum.menu || x.key === ActionTypeEnum.checkbox || x.displayName === '') { // these values have checked true from configureMapper()
        return false;
      } else {
        return x.checked;
      }
    });
  }
  exportAsXLSX():void {
    let displayedColumns=this.displayedColumns;
    let headingobj={};
    displayedColumns.forEach(v=>{ 
      if(this.dataSource.data[0][v]){
      let dispname = this._headerOptions.find(x=>x.key==v).displayName;
      headingobj[v]=dispname;
      } 
    })

    let formedexceldata = this.dataSource.data.map(a => {
        let x = {};
        displayedColumns.forEach(v=>{
          if(a[v]){ x[v]=a[v]; }
        })
        return x; 
      });   
    this.excelService.exportAsExcelFile(headingobj,formedexceldata, 'PMS_Data');
  }

  private checkInstanceExist(){
    let existingInstance = (this.dataSource as GridTableDataSource);
    if(existingInstance?.destroyed$){
      existingInstance.destroyed$.next(true);
      existingInstance.destroyed$.complete();
    }
  }
  onServerPageEmit(event){
    let emitServerPageData = {
      searchText: this.searchText,
      sortingType: this.ordertype,
      sortColKey: this.sortingColoumnKey
    }
    this.serverPageEmit.emit({...event,...emitServerPageData});
  }

  checkKeyIsPresent(key,array){
    if(array?.length){
      const isKey = array.includes(key);
      return isKey
    }
   
  }
  pageChanged(eve) {
    if(eve.pageSize) {
      this.pageSize=eve.pageSize;
      this.lastUpdatedPageSize = eve.pageSize;
    }
  }
}
