import { Processor } from './processor';
import { ObjectChange } from '@app/shared/models/ChangeTrackingOperationResultDTO';
import { CacheService } from '@app/core/services/cache.service';
import { TablesService } from '@app/shared/services/tables.service';
import { ChangeAction } from '@app/shared/constants/commonenums';
import { TableBlockingRuleDTO } from '@app/shared/models/TableBlockingDTO';
import { Utilities } from '@app/shared/utilities/utilities';


export class TableBlockingRuleTableChangeProcessor implements Processor {

  private static instance: TableBlockingRuleTableChangeProcessor;
  private constructor(public cs: CacheService, public ts: TablesService) {
  }

  public static Instance(_cs: CacheService, _ts: TablesService): TableBlockingRuleTableChangeProcessor {
    if (!TableBlockingRuleTableChangeProcessor.instance) {
      TableBlockingRuleTableChangeProcessor.instance = new TableBlockingRuleTableChangeProcessor(_cs, _ts);
    }
    return TableBlockingRuleTableChangeProcessor.instance;
  }

  Process(change: ObjectChange, additiondata: object, propertyId: number): void {
    let overallTableBlocking: TableBlockingRuleDTO[];
    if (propertyId == Utilities.RestaurantId()) {
      overallTableBlocking = this.ts.overallTableBlockingRules ? this.ts.overallTableBlockingRules
        : [];
    }
    else {
      overallTableBlocking = this.cs.propertySettings.value[propertyId].tableBlockingRules
    }
    let blockedTable = new TableBlockingRuleDTO();
    if (change) {
      if (change.Action === ChangeAction.Created) {
        let TableTobeAdded = [];
        let TableBlockingRuleId;
        change.PropertyChanges.forEach(property => {
          if (property.PropertyName === 'TableBlockingRuleId') {
            TableBlockingRuleId = property.Value;
          }
          if (property.PropertyName === 'StandaloneTableId') {
            TableTobeAdded.push({ StandaloneTableId: JSON.parse(property.Value) });
          }
        });
        TableTobeAdded = TableTobeAdded.map(obj => ({ ...obj, TableBlockingRuleId: TableBlockingRuleId }));
        let objIndex = overallTableBlocking.findIndex((obj => obj.Id == TableBlockingRuleId));
        blockedTable = overallTableBlocking[objIndex];
        if (blockedTable) {
          blockedTable.TableIds = blockedTable.TableIds.filter((obj) => {
            return obj !== TableTobeAdded[0].StandaloneTableId;
          });
          blockedTable.TableIds.push(TableTobeAdded[0].StandaloneTableId);
        }
      }
      else if (change.Action == ChangeAction.Removed) {
        let TableToberemove = [];
        let TableBlockingRuleId;
        change.PropertyChanges.forEach(property => {
          if (property.PropertyName === 'TableBlockingRuleId') {
            TableBlockingRuleId = property.Value;
          }
          if (property.PropertyName === 'StandaloneTableId') {
            TableToberemove.push({ StandaloneTableId: JSON.parse(property.Value) });
          }
        });
        TableToberemove = TableToberemove.map(obj => ({ ...obj, TableBlockingRuleId: TableBlockingRuleId }));
        let objIndex = overallTableBlocking.findIndex((obj => obj.Id == TableBlockingRuleId));
        blockedTable = overallTableBlocking[objIndex];
        if (blockedTable) {
          blockedTable.TableIds = blockedTable.TableIds.filter((obj) => {
            return obj !== TableToberemove[0].StandaloneTableId;
          });
        }
      }
      if (overallTableBlocking && overallTableBlocking.length > 0 && blockedTable) {
        overallTableBlocking = overallTableBlocking.filter((obj) => {
          return obj.Id !== blockedTable.Id;
        });
      }
      if (blockedTable) {
        overallTableBlocking.push({ ...blockedTable });
      }
      this.ts.overallTableBlockingRules = overallTableBlocking;
      this.cs.propertySettings.value[propertyId].tableBlockingRules = overallTableBlocking;
    }
  }
}
