<div class="slot-popover">
  <div class="slot-popover__slot-header">
    <span class="slot-popover__header-label">{{headerName | translate}}</span>
    <a class="icon-Group-591 slot-popover__close-button seat-icon-color" (click)="closePopover()"></a>
    <!-- (click)="sl.close();" -->
  </div>
  <div class="slot-popover__slot-config" *ngIf="!isSlotDisabled">
    <dynamic-form [config]="customSlotConfig" #form="dynamicForm"></dynamic-form>
  </div>
  <div class="slot-popover__action-buttons" *ngIf="!isSlotDisabled">
    <activities-app-button [buttontype]="cancelSlotButton" (valueChange)="cancelSlot()"></activities-app-button>
    <activities-app-button [buttontype]="addCustomSlotButton" (valueChange)="addSlot()"></activities-app-button>
  </div>
  <div class="slot-popover__enable-button" *ngIf="isSlotDisabled">
    <activities-app-button [buttontype]="enableSlotButton" (valueChange)="enableSlot()"></activities-app-button>
  </div>
  <div class="slot-popover__slot-edit-time" *ngIf="!isAdd">
    <span *ngIf="slotTime.UpdatedAt">{{'lastEdit' | translate}}: {{slotTime.UpdatedAt | formatSettingsDate: cs.settings.value.General.DateFormat}} {{slotTime.UpdatedAt | localizedDate: 'LT'}}</span>
    <span *ngIf="!slotTime.UpdatedAt">{{'lastEdit' | translate}}: {{'neverEdited' | translate}}</span>
    <i class="icon-Auditlog2 slot-popover__audit-log" (click)="showManualSlotAuditLog()"></i>
  </div>
</div>
