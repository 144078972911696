import { Component, OnInit, ViewEncapsulation, Inject, Input, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from "lodash";
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { ItemInventoryTransfer, ItemInventoryTransferOutlets, BaseResponse } from '../../retail.modals';
import { AlertMessagePopupComponent } from '../../shared/alert-message-popup/alert-message-popup.component';
import { AddTransferDataService } from '../add-transfer-inventory-popoup-data.services';
import { Host } from '../../shared/globalsContant';
import { InventoryService } from '../../inventory/inventory.service';

@Component({
  selector: 'app-multiple-to-single',
  templateUrl: './multiple-to-single.component.html',
  styleUrls: ['./multiple-to-single.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultipleToSingleComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() outlets: { "id": number; "name": string; }[];
  captions: any = this._Localization.captions;
  multipltosingleFG: UntypedFormGroup;
  entiretablerow: any;
  selectedtablerow: any;
  TableHdrData: any;
  searchFilter: any;
  transferquantity: any;
  checkedarrcount: number;
  totalChecked: number;
  transferItemData: ItemInventoryTransfer[] = [];
  transferItems: ItemInventoryTransferOutlets[] = [];
  itemsData: string = "";
  isExcessQuantity: boolean = false;
  fromOutlets: { "id": number; "name": string; }[];
  toOutlets: { "id": number; "name": string; }[];
  checkedTableRowBool: boolean;
  checkedsecondaryBool: boolean;
  message: string = "";
  selectedDefaultHeader: any;
  orderType: any = 'asc';
  selectedValueObject: any;
  disableAdd: boolean = true;
  floatLabel: string;
  constructor(public _Localization: RetailLocalization, private ad: AddTransferDataService,
    private dialogRef: MatDialogRef<RetailPopupComponent>, private _FormBuilder: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any
    , private http: HttpServiceCall, private utils: RetailUtilities, private dialog: MatDialog, private inventoryService: InventoryService) {
      this.floatLabel = this._Localization.setFloatLabel;
     }


  tableArray() {
    this.checkedTableRowBool = ((this.selectedtablerow.every(x => { if (parseInt(x.transferedQty) > 0 || !x.checkedFlag) { return true; } })) && this.selectedtablerow.length > 0 && this.selectedtablerow.filter(x => x.checkedFlag).length > 0);
  }
  ngAfterViewInit() {
    this.tableArray();
  }

  dataChanged(e) {
    this.tableArray();
  }

  ngOnInit() {
    this.checkedTableRowBool = false;
    this.checkedsecondaryBool = false;
    this.multipltosingleFG = this._FormBuilder.group({
      fromOutlet: [this.data.datarecord.fromOutlet],
      toOutlet: ['', Validators.required],
      transferReason: ['', Validators.required],
      searchTxt: ['']
    });
    if (this.ad.multipltosingleFG)
      this.multipltosingleFG.patchValue(this.ad.multipltosingleFG.value);
    this.InitializeComponent();

  }

  InitializeComponent(): void {
    this.transferquantity = 0.00;
    this.fromOutlets = _.cloneDeep(this.outlets);
    let modifiedoutlet = this.fromOutlets.filter(x => { return x.id != this.data.datarecord.fromOutlet });
    this.toOutlets = _.cloneDeep(modifiedoutlet);
    this.TableHdrData = [{ "title": this.captions.retailsetup.Item, "jsonkey": "itemNumberColumn", "sortcolumndatatype": "number", "searchable": true },
    { "title": this.captions.retailsetup.ItemDescription, "jsonkey": "itemName", "searchable": true },
    { "title": this.captions.retailsetup.Category, "jsonkey": "categoryName", "searchable": true },
    { "title": this.captions.retailsetup.SalesPrice + " (" + this._Localization.currencySymbol + ")", "jsonkey": "salesPrice", "alignType": "right", "sortcolumndatatype": "number", "datatype": "money", "searchable": false },
    { "title": this.captions.retailsetup.QuantityAvailable, "jsonkey": "quantityOnHand", "sortcolumndatatype": "number", "searchable": false },
    { "title": this.captions.retailsetup.ItemPar, "jsonkey": "itemPar", "sortcolumndatatype": "number", "searchable": false },
    { "title": this.captions.Inactive, "jsonkey": "isInActive", "sortable": false, "searchable": false },
    { "title": this.captions.retailsetup.UnitCost + " (" + this._Localization.currencySymbol + ")", "jsonkey": "unitCost", "alignType": "right", "sortcolumndatatype": "number", "datatype": "money", "searchable": false },
    ];
    this.entiretablerow = this.data.datarecord.entireTabledata.filter(x => x.useInventory === true && !x.isInActive);
    this.data.datarecord.selectedRow = this.data.datarecord.selectedRow.filter(x => x.useInventory);
    this.selectedtablerow = _.cloneDeep(this.data.datarecord.selectedRow);
    if (this.ad.selectedRowTable) {
      this.selectedtablerow = _.cloneDeep(this.ad.selectedRowTable);
      this.selectedtablerow.forEach((element, index) => {
        element.transferedQty = this.ad.selectedRowTable[index].transferedQty;
      });
    }
    this.selectedtablerow = this.selectedtablerow.filter(x => x.useInventory);
    this.addquamtity();
    this.orderType = "desc";
    this.sortingColoumns("itemNumberColumn", true);
  }

  ngOnChanges(): void {
    this.InitializeComponent();
  }

  close() {
    this.dialogRef.close();
  }
  filterFucntion($event) {
    let data: string = $event.target && $event.target.value ? $event.target.value : '';
    console.log("filterFucntion data", data, this.entiretablerow);

    let newarr = [];
    newarr = this.entiretablerow.filter(x => {
      let selectedID = _.map(this.selectedtablerow, 'id');
      if (!_.includes(selectedID, x.id)) {
        let check1 = _.includes(x.itemNumber.toString().toLowerCase(), data.toLowerCase());
        let check2 = _.includes(x.itemName.toLowerCase(), data.toLowerCase());
        let check3 = _.includes(x.categoryName.toLowerCase(), data.toLowerCase());
        return (check1 || check2 || check3)
      }
    });
    this.searchFilter = newarr;
  }
  SelectedRecord(e: any) {
    e.value['transferedQty'] = 0;
    e.value['checkedFlag'] = true;
    this.selectedValueObject = e.value;
    const searchTxtValue = `${e.value.itemNumberColumn} - ${e.value.itemName}`;
    this.multipltosingleFG.controls.searchTxt.setValue(searchTxtValue);
    this.disableAdd = false;
  }

  addItemToTable() {
    this.selectedtablerow.push(this.selectedValueObject);
    this.data.datarecord.selectedRow = this.selectedtablerow;
    this.addquamtity();
    this.tableArray();
    this.sortingColoumns("itemNumberColumn", false);
    this.clearSearch();
  }

  togglecheck(e, currentdata) {
    this.selectedtablerow.forEach(x => {
      if (x.id == currentdata.id) {
        x['checkedFlag'] = e.checked;
      }
    });
    this.data.datarecord.selectedRow = this.selectedtablerow;
    this.addquamtity();
    this.tableArray();
  }
  checkAll(e) {
    this.selectedtablerow.forEach(x => {
      x['checkedFlag'] = e.checked;
    });
    this.data.datarecord.selectedRow = this.selectedtablerow;
    this.addquamtity();
    this.tableArray();
  }
  addquamtity() {
    let total = 0;
    let count = 0;
    this.selectedtablerow.forEach(ele => {
      if (ele.checkedFlag) {
        let transferedCost = ele.unitCost ? ele.unitCost : 0;
        let transferQuantity = ele.transferedQty ? ele.transferedQty : 0;
        let transferquantityCost = transferedCost * transferQuantity;
        total += parseFloat(transferquantityCost.toString());
        count += parseFloat(transferQuantity.toString());

      }
    });
    this.checkedarrcount = count;
    this.totalChecked = this.selectedtablerow.filter(x => x.checkedFlag).length
    this.transferquantity = total;
  }
  async transferdata() {
    let checkedrow = this.selectedtablerow.filter(x => {
      return x.checkedFlag;
    });
    if (checkedrow) {
      await this.CheckExcessQuantityTransfer(checkedrow);
    }
  }

  async FormTransferData(checkedrow: any) {
    checkedrow.forEach(element => {
      this.transferItems = [];
      this.transferItems.push({ fromOutletId: this.multipltosingleFG.value.fromOutlet, toOutletId: this.multipltosingleFG.value.toOutlet, quantity: element.transferedQty })
      this.transferItemData.push({ itemId: element.id, reason: this.multipltosingleFG.value.transferReason, outlets: this.transferItems })
    });

    if (this.transferItemData.length > 0) {
      let body = this.inventoryService.BuildItemOutletMappingForTransfer(this.transferItemData);
      let apiResponse: BaseResponse<boolean> = await this.inventoryService.InvokeServiceCallAsync('LinkOutletsWithRetailItem', Host.retailManagement, HttpMethod.Put, '', body);
      if (apiResponse && apiResponse.successStatus) {
        this.CreateTransfer(this.transferItemData);
      }
    }
  }

  async CheckExcessQuantityTransfer(checkedrow: any) {
    for (let x = 0; x < checkedrow.length; x++) {
      if (checkedrow[x].quantityOnHand < checkedrow[x].transferedQty) {
        this.itemsData = this.itemsData + (x > 0 ? "," : "") + (checkedrow[x].itemName ? checkedrow[x].itemName : "");
        this.isExcessQuantity = true;
      }
    }
    if (this.isExcessQuantity) {
      let dialogRef = this.openAlertPopup();
      dialogRef.afterClosed().subscribe(result => {
        if (result.toLowerCase() == this.captions.common.Yes.toLowerCase()) {
          this.FormTransferData(checkedrow);
        }
        else {
          this.transferItemData = [];
          this.transferItems = [];
          this.itemsData = "";
          this.message = "";
        }
      });
    }
    else {
      this.FormTransferData(checkedrow);
    }

  }


  openAlertPopup() {
    this.message = this._Localization.replacePlaceholders(this.captions.retailsetup.excessQuantityWarning, ["items"], [this.itemsData]);
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '550px',
      height: 'auto',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.captions.common.Warning, headerIcon: 'icon-warning-icon', headerMessage: this.message, buttonName: this.captions.common.Yes, noButton: true, noButtonName: this.captions.common.No, type: 'message'
      },
      disableClose: true,
    }); 
  }



  outletsSelected(e, belongTo) {
    let val = e.value;
    if (belongTo == 'from') {
      this.toOutlets = this.outlets.filter(x => { return x.id != val });
    }
    if (belongTo == 'to') {
      this.fromOutlets = this.outlets.filter(x => { return x.id != val });
    }
  }


  CreateTransfer(transferItemData: ItemInventoryTransfer[]) {
    this.http.CallApiWithCallback({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "TransferInventoryItem",
      method: HttpMethod.Post,
      body: transferItemData,
      showError: true,
      extraParams: []
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "TransferInventoryItem") {
      const dialogRef = this.OpenProcessCompletePopUp();
      dialogRef.afterClosed().subscribe(data => {
        if (data.toLowerCase() == this.captions.common.OK.toLowerCase()) {
          this.dialogRef.close();
        }
        else {
          this.transferItemData = [];
          this.transferItems = [];
          this.itemsData = "";
          this.message = "";
        }
      });
    }
  }

  OpenProcessCompletePopUp() {
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: 'auto',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: { headername: this.captions.retailsetup.processComplete, headerIcon: 'icon-success-icon', buttonName: this.captions.common.OK, type: 'message' },
      disableClose: true
    }); 
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "TransferInventoryItem") {
        this.transferItemData = [];
        this.transferItems = [];
    }
  }
  clearSearch() {
    this.multipltosingleFG.controls.searchTxt.setValue('');
    this.searchFilter = [];
    this.disableAdd = true;
  }


  sortingColoumns(colname, bool) {
    console.log("bool", bool);
    this.selectedDefaultHeader = colname;
    this.orderType = (this.orderType == 'asc' && bool) ? 'desc' : 'asc';
    this.selectedtablerow = _.orderBy(this.selectedtablerow, [this.selectedDefaultHeader], this.orderType);
  }

  ngOnDestroy() {
    this.ad.multipltosingleFG = _.cloneDeep(this.multipltosingleFG);
    this.ad.selectedRowTable = _.cloneDeep(this.selectedtablerow);
  }
}
