<div class="ticket">
	<div>
		<div class="row mb-2 ml-0 mr-0 pl-0">
			<div class="col-lg-7 col-md-7 pl-2">
				<div class="select-option">
					<activities-app-button [buttontype]="buttonSecondary" (click)='partiesSelection(SelectionType.unselect)'
						class="chit-print__select-btn"> </activities-app-button>
				</div>
				<div class="select-option">
					<activities-app-button [buttontype]="buttonValueprimary" (click)='partiesSelection(SelectionType.select)'
						class="chit-print__unselect-btn">
					</activities-app-button>
				</div>
				<div class="select-option">
				<dynamic-form [config]="ipconfig" #form="dynamicForm">
				</dynamic-form>
				</div>
			</div>
			<div class="col-lg-3 col-md-3 pr-2">
				<div class="float-right">
					<dynamic-form [config]="searchConfig" #form="dynamicForm">
					</dynamic-form>
				</div>
			</div>
		</div>
		<div class="ticket-list" *ngIf="bookingData && bookingData.length > 0">
			<ng-scrollbar>
				<ng-container *ngFor="let ticket of (bookingData | checkPrintTicketEnabled)">
					<div class="ticket__container" 
					[ngClass]="{'selected-border': ticket.selected == true}" (click)="selectData(ticket)">
					<div>
						<svg id="svg" class="checkmark position-absolute" *ngIf="ticket.selected == true"
							viewBox="0 0 52 52">
							<circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
							<path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
						</svg>
						<div class="row">
							<div class="col-lg-8 col-md-8 left-bor">
								<div class="ticket__section user-detail">
									<div class="propertyname">
										{{ticket?.RestaurantName}}
									</div>
									<div class="panel left-panel">
										<div class="ticket-time">
											<div class="user-data tickt-data">
												<label>{{'Name of Player' | translate}}</label>
												<div class="user-name">{{ticket?.Name}}</div>
											</div>
											<table>
												<tr>
													<td>
														<label>{{'Date' | translate}}</label>
													</td>
													<td>
														{{ticket?.StartDate | formatSettingsDate: cs.settings.value.General.DateFormat}}
														{{ticket?.StartDate == ticket?.EndDate ? '' : (' - ' +
														(ticket?.EndDate | formatSettingsDate: cs.settings.value.General.DateFormat))}}
													</td>
												</tr>
												<tr>
													<td>
														{{'Class Name' | translate}}
													</td>
													<td>
														{{ticket?.ClassName}}
													</td>
												</tr>
												<tr>
													<td>
														{{'time' | translate}}
													</td>
													<td>
														{{ticket?.StartTime | localizedDate : 'LT' }} - {{ticket?.EndTime | localizedDate : 'LT'}}
													</td>
												</tr>
												<tr>
													<td>
														{{'ConfirmationCode' | translate}}
													</td>
													<td>
														{{ticket?.ConfirmationCode}}
													</td>
												</tr>
												<tr *ngIf="showSeatSelected">
													<td>
														{{'Selected Seat' | translate}}
													</td>
													<td>
														{{ticket?.TableName || 'NA'}}
													</td>
												</tr>
											</table>
										</div>
									</div>
									<div class="panel right-panel">
										<div class="scan">
											<qr-code [value]="ticket.barcodeInput" size="100"></qr-code>
										</div>
									</div>
								</div>
								<div class="bottom-session court-details">
									<div class="user-counter-details">
										<div class="court-section">
											<div class="group flight">
												<div>{{'Court Name' | translate}} | <span class="lb-bold">{{ticket?.TableName}}</span>
												</div>
											</div>
											<div class="group group-number">
												<div>{{'Party Size' | translate}} | <span class="lb-bold">{{ticket.TicketPerPerson ? (ticket?.ticketOrder + ' ' +  ('of' | translate) + ' ' + ticket?.Size) : ( ticket?.Size)}}</span></div>
												<div></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4">
								<div class="counter-area">
									<div class="propertyname">
										{{ticket?.RestaurantName}}
									</div>
									<div class="name">
										<div class="ticketticketmainheader">{{'Name of Player' | translate}}</div>
										<div class="ticketinfo">{{ticket?.Name}}</div>
									</div>
									<table>
										<tr>
											<td>
												{{'Date' | translate}}
											</td>
											<td>
												{{ticket?.StartDate | formatSettingsDate: cs.settings.value.General.DateFormat }}
												{{ticket?.StartDate == ticket?.EndDate ? '' : (' - ' +
												(ticket?.EndDate | formatSettingsDate: cs.settings.value.General.DateFormat))}}
											</td>
										</tr>
										<tr>
											<td>
												{{'Class Name' | translate}}
											</td>
											<td>
												{{ticket?.ClassName}}
											</td>
										</tr>
										<tr>
											<td>
												{{'ConfirmationCode' | translate}}
											</td>
											<td>
												{{ticket?.ConfirmationCode}}
											</td>
										</tr>
										<tr *ngIf="showSeatSelected">
											<td>
												{{'Selected Seat' | translate}}
											</td>
											<td>
												{{ticket?.TableName || 'NA'}}
											</td>
										</tr>
									</table>
									<div class="court-section">
										<div class="group flight" >
											<div class="text-bold">{{'Court Name' | translate}}</div>
											<div>{{ticket?.TableName}}</div>
										</div>
										<div class="group group-number">
											<div class="text-bold">{{'Party Size' | translate}}</div>
											<div>{{ticket.TicketPerPerson ? (ticket?.ticketOrder + ' ' + ('of' | translate) + ' ' + ticket?.Size) : ( ticket?.Size)}}</div>
										</div>
									</div>
								</div>
								<div class="bottom-session time-details">
									<div class="time-title">{{'time' | translate}} <span>{{ticket?.StartTime | localizedDate : 'LT' }} -
											{{ticket?.EndTime
											| localizedDate : 'LT'}}</span></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</ng-container>
			</ng-scrollbar>
		</div>
		<div class="empty-data" *ngIf="bookingData && bookingData.length === 0">
			No Data Found
		</div>
	</div>

	<!-- printing final data -->
<div [hidden]="true">
	<div id="ticket-print-section">
		<div class="ticket__container print-selection" *ngFor="let ticket of printResult">
				<div class="ticket-row">
					<div class="ticket-col-8 col-list left-section">
						<div class="ticket__section user-detail">
							<div class="propertyname">
								{{ticket?.RestaurantName}}
							</div>
							<div class="panel left-panel">
								<div class="ticket-time">
									<div class="user-data tickt-data">
										<label>{{'Name of Player' | translate}}</label>
										<div class="user-name">{{ticket?.Name}}</div>
									</div>
									<table>
										<tr>
											<td>
												<label>{{'Date' | translate}}</label>
											</td>
											<td>
												{{ticket?.StartDate | formatSettingsDate: cs.settings.value.General.DateFormat}}
												{{ticket?.StartDate == ticket?.EndDate ? '' : (' - ' +
												(ticket?.EndDate | formatSettingsDate: cs.settings.value.General.DateFormat))}}
											</td>
										</tr>
										<tr>
											<td>
												{{'ClassName' | translate}}
											</td>
											<td>
												{{ticket?.ClassName}}
											</td>
										</tr>
										<tr>
											<td>
												{{'time' | translate}}
											</td>
											<td>
												{{ticket?.StartTime | localizedDate : 'LT' }} - {{ticket?.EndTime | localizedDate
												: 'LT'}}
											</td>
										</tr>
										<tr>
											<td>
												{{'ConfirmationCode' | translate}}
											</td>
											<td>
												{{ticket?.ConfirmationCode}}
											</td>
										</tr>
										<tr *ngIf="showSeatSelected">
											<td>
												{{'Selected Seat' | translate}}
											</td>
											<td>
												{{ticket?.TableName || 'NA'}}
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div class="panel right-panel">
								<div class="scan">
									<qr-code [value]="ticket.barcodeInput" size="100"></qr-code>
								</div>
							</div>
						</div>
						<div class="ticket-col-8 col-list left-bor left-section row-width">
							<div class="bottom-session court-details">
								<div class="user-counter-details">
									<div class="court-section">
										<div class="group flight">
											<div>{{'Court Name' | translate}} | <span class="lb-bold">{{ticket?.TableName}}</span></div>
										</div>
										<div class="group group-number">
											<div>{{'Party Size' | translate}} | <span class="lb-bold">{{ticket.TicketPerPerson ? (ticket?.ticketOrder + ' ' + ('of' | translate) + ' ' + ticket?.Size) : ( ticket?.Size)}}</span></div>
											<div></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="ticket-col-4 right-section col-list right-section-div">
						<div class="counter-area">
							<div class="propertyname">
								{{ticket?.RestaurantName}}
							</div>
							<div class="name">
								<div class="ticketticketmainheader">{{'Name of Player' | translate}}</div>
								<div class="ticketinfo">{{ticket?.Name}}</div>
							</div>
							<table>
								<tr>
									<td>
										{{'Date' | translate}}
									</td>
									<td>
										{{ticket?.StartDate | formatSettingsDate: cs.settings.value.General.DateFormat}}
										{{ticket?.StartDate == ticket?.EndDate ? '' : (' - ' +
										(ticket?.EndDate | formatSettingsDate: cs.settings.value.General.DateFormat))}}
									</td>
								</tr>
								<tr>
									<td>
										{{'Class Name' | translate}}
									</td>
									<td>
										{{ticket?.ClassName}}
									</td>
								</tr>
								<tr>
									<td>
										{{'ConfirmationCode' | translate}}
									</td>
									<td>
										{{ticket?.ConfirmationCode}}
									</td>
								</tr>
								<tr *ngIf="showSeatSelected">
									<td>
										{{'Selected Seat' | translate}}
									</td>
									<td>
										{{ticket?.TableName || 'NA'}}
									</td>
								</tr>
							</table>
							<div class="court-section">
								<div class="group flight">
									<div class="text-bold">{{'Court Name' | translate}}</div>
									<div>{{ticket?.TableName}}</div>
								</div>
								<div class="group group-number">
									<div class="text-bold">{{'Party Size' | translate}}</div>
									<div>{{ticket.TicketPerPerson ? (ticket?.ticketOrder + ' ' + ('of' | translate) + ' ' + ticket?.Size) : ( ticket?.Size)}}</div>
								</div>
							</div>
						</div>
						<div class="ticket-col-4 col-list right-section row-width">
							<div class="bottom-session time-details">
								<div class="time-title">{{'time' | translate}} <span class="ml-2">{{ticket?.StartTime | localizedDate : 'LT' }} -
										{{ticket?.EndTime
										| localizedDate : 'LT'}}</span></div>
							</div>
						</div>
					</div>
				</div>
		</div>
	</div>
</div>
</div>

