import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ClassType, LessonType, PartyState, Status, PartyType, ComponentTypes } from '../constants/commonenums';
import { SettingsDTO } from '../models/RestaurantDTO';
import { PartyService } from '../services/party.service';
import { Utilities } from './utilities';
import { format } from "date-fns";
import _, { uniqBy } from "lodash";
import moment from 'moment';
import { ISubscription } from 'rxjs/Subscription';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { TableSelectionComponent } from '../components/create-update-party-tab-layout/selections/table-selection/table-selection.component';

const enum GroupType {
    Location = 0,
    Staff = 1,
    Class = 2
}
@Injectable({
    providedIn: 'root'
    
})
export class ActivityBookingFunctions extends Utilities {

    //variable declaration aand assignment
    subscriptions: Subscription = new Subscription();
    _settings: SettingsDTO = {} as SettingsDTO;
    session: any;
    date = new Date();
    viewPeriodSelected: 'day' | 'week';

    constructor(protected _ps: PartyService, protected _cs: CacheService, dialog: MatDialog,
        protected translateService: TranslateService) {
        super(dialog)
    }

    //form the bookings array
    formAllBookedActivities(settings, layout, filterDate, reservationList, standbyList, refreshActivitiesData) {
        this._settings = settings;
        this.date = filterDate;
        let locationList = Utilities.getRestaurantTables(layout.FloorPlans);
        let date = this.getDateForActivities();
        let lastDateObj = new Date(filterDate);
        lastDateObj.setDate(lastDateObj.getDate() + 7);
        let lastDate = this.getDateForActivities(lastDateObj);
        let dayofweek = date.getDay();
        reservationList = reservationList?.filter(reservation => (reservation.State == PartyState.Pending) || (reservation.State == PartyState.Seated) || (reservation.State == PartyState.Left));
        standbyList = standbyList?.filter(reservation => (reservation.State == PartyState.Pending) || (reservation.State == PartyState.Seated));
        let mappedSpecialMealList: any[] = [];
        let locationTempData = {};
        let staffTempData = {};
        
        settings.SpecialMeals.forEach(specialMeal => {
            let startDateObj = new Date(specialMeal.StartDate);
            let endDateObj = new Date(specialMeal.EndDate);
            let validDate = (specialMeal.ActivitySessions && specialMeal.ActivitySessions.length > 0)
                && (specialMeal.StatusCode == Status.Approved)
                && ((endDateObj.getTime() >= date.getTime() && startDateObj.getTime() <= date.getTime())
                    || endDateObj.getTime() >= lastDate.getTime() && startDateObj.getTime() <= lastDate.getTime()
                    || startDateObj.getTime() > date.getTime() && endDateObj.getTime() < lastDate.getTime());

            if(validDate){
                let isPrivateLesson = (specialMeal.LessonType == LessonType.PrivateLesson);
                let sessions = this.getSessionsForClass(specialMeal, dayofweek, locationList, isPrivateLesson, GroupType.Class, reservationList, standbyList, null, lastDate, locationTempData, staffTempData );

                if(sessions && sessions.length){
                    let resData = {
                        Id: specialMeal.Id,
                        Name: specialMeal.Name.trim(),
                        CategoryId: specialMeal.CategoryId || -1,
                        SubcategoryId: specialMeal.SubcategoryId || -1,
                        SortOrder: specialMeal.SortOrder,
                        CategoryName: this._settings.Categories.find(category => category.Id === specialMeal.CategoryId)?.Text,
                        Sessions: sessions,
                        isPrivateLesson: isPrivateLesson,
                        ClassType: specialMeal.ClassType,
                        ...(isPrivateLesson) && { Bookings: _.sortBy(this.getBookingsForPrivateLesson(specialMeal, reservationList, standbyList), ['PartyId']) },
                    }

                    mappedSpecialMealList.push(resData);

                }
            }
        });

        // let lessonNameWithString = _.filter(mappedSpecialMealList, function (o) { return isNaN(Number(o.Name)) });
        // let lessonNameWithNumber = _.filter(mappedSpecialMealList, function (o) { return !isNaN(Number(o.Name)) });
        // mappedSpecialMealList = [...(_.orderBy(lessonNameWithNumber, function (s) { return Number(s.Name) })).map(x => { x.Name.toString(); return x }), ...(_.orderBy(lessonNameWithString, ['Name']))]
                
        let mappedLocationList = locationList.map(location => {

            let locationMapData = locationTempData[location.Id];

            if(!locationMapData){
                locationMapData = {
                    ActivityBookings: [],
                    Id: location.Id,
                    Name: location.Name.trim(),
                    BufferTimeInMinutes: location.BufferTimeInMinutes || 0
                }
            }

            locationMapData.OpenBookings = [];
            locationMapData.BufferTimeInMinutes = location.BufferTimeInMinutes || 0

            return locationMapData;
        });
       
        let mappedServerList = settings.Servers.map(server => {
            let serverMapData = staffTempData[server.Id];

                if(!serverMapData){
                    serverMapData = {
                        ActivityBookings: [],
                        FirstName: server.Name.trim(),
                        Id: server.Id,
                        LastName: server.LastName
                    }
                }
    
                serverMapData.ExpertiseLevel = server.ExpertiseLevelIds.length > 0 ? this.getExpertiseLevel(server.ExpertiseLevelIds) : [];
    
                return serverMapData;
        })

        this._ps.allBookedActivities.ByClass = mappedSpecialMealList;
        this._ps.allBookedActivities.ByLocation = mappedLocationList;
        this._ps.allBookedActivities.ByStaff = mappedServerList;

        if (!refreshActivitiesData) {
            this._cs.activitiesData.next(this._ps.allBookedActivities);
        }

        return this._ps.allBookedActivities;

    }

    //get current Date
    getDateForActivities(dateSelected?) {
        // let date = this.appService._headerDate         
        let date = new Date(dateSelected || this.date);
        date.setHours(0, 0, 0, 0)
        return date
    }

    //get current Level Name
    getExpertiseLevel(expertiseIds) {
        let expertiseLevels = [];
        expertiseLevels = this._settings.ExpertiseLevels.filter(level => expertiseIds.includes(level.Id)).map(expertiseLevel => { return expertiseLevel.ExpertiseName });
        return expertiseLevels;
    }

    //get current location Name
    getLocationName(locationId, locationList) {
        let location = locationList.filter(location => location.Id == locationId);
        if (location.length > 0) {
            return location.Name;
        }
        return;
    }

    //get OpenBookings For Location
    getOpenBookingsForLocation(locationId, reservationList) {
        let activityBookings = reservationList;
        let openBookings = activityBookings.filter(booking => (!booking.SpecialMealId && booking.TableIds.includes(locationId))).map(activity => { return activity.Id });
        return openBookings;
    }

    //get Bookings for Staff
    getBookingsForStaff(specialMealList, dayofweek, serverId, locationList, reservationList, standbyList, lastDate) {
        let bookingsForDay = [].concat.apply([], specialMealList.map(specialMeal => {
            return specialMeal.ActivitySessions
        }))
        let bookingsForServer = bookingsForDay.filter(meal => meal.SessionStaffMappings?.find(staff => staff.ServerId == serverId)).map(data => {
            let specialMeal = specialMealList.filter(specialMeal => specialMeal.Id == data.SpecialMealId)[0]
            return {
                ...(specialMeal.LessonType == LessonType.PrivateLesson) && { Bookings: _.sortBy(this.getBookingsForPrivateLessonForStaff(specialMeal, serverId, reservationList, standbyList), ['PartyId']) },
                Id: specialMeal.Id,
                isPrivateLesson: specialMeal.LessonType == LessonType.PrivateLesson,
                Name: this.getActivityName(specialMealList, data.SpecialMealId),
                Sessions: this.getSessionsForClass(specialMeal, dayofweek, locationList, specialMeal.LessonType == LessonType.PrivateLesson, GroupType.Staff, reservationList, standbyList, serverId, lastDate)
            }
        });
        return uniqBy(bookingsForServer, 'Id');
    }

    //get Bookings for Location
    getBookingsForLocation(specialMealList, dayofweek, locationId, locationList, reservationList, standbyList, lastDate) {
        let bookingsForDay = [].concat.apply([], specialMealList.map(specialMeal => {
            return specialMeal.ActivitySessions
        }));
        let bookingsForLocation = bookingsForDay.filter(meal => meal.SessionLocationMappings?.filter(location => location.StandaloneTableId == locationId).length != 0).map(data => {
            let specialMeal = specialMealList.filter(specialMeal => specialMeal.Id == data.SpecialMealId)[0]
            return {
                Id: specialMeal.Id,
                isPrivateLesson: specialMeal.LessonType == LessonType.PrivateLesson,
                Name: this.getActivityName(specialMealList, data.SpecialMealId),
                Sessions: this.getSessionsForClass(specialMeal, dayofweek, locationList, specialMeal.LessonType == LessonType.PrivateLesson, GroupType.Location, reservationList, standbyList, locationId, lastDate),
                ...(specialMeal.LessonType == LessonType.PrivateLesson) && { Bookings: _.sortBy(this.getBookingsForPrivateLessonForLocation(specialMeal, locationId, reservationList, standbyList), ['PartyId']) },
            }
        });
        return bookingsForLocation;

    }

    //get specialMeal Name
    getActivityName(specialMealList, specialMealId) {
        return specialMealList.filter(meal => meal.Id == specialMealId)[0].Name
    }

    //setting  up the sessions
    getSessionsForClass(meal, dayofweek, locationList, isPrivateLesson, from, reservationList, standbyList, type, lastDate, locationTempData?, staffTempData?) {
        let sessions = [];
        let activitySession = meal.ActivitySessions;
        let validDays = [];
        validDays = this.getValidDays(new Date(this._cs.headerDate), new Date(meal.StartDate), new Date(meal.EndDate));
        activitySession = activitySession.filter(session => validDays.includes(session.Dayofweek));
        let modifySessionIds = [];
        activitySession.map(session => {
            if(session.ParentSessionId && Utilities.datetimeBetween(new Date(moment(this.date).format('YYYY-MM-DD')), lastDate, session.SessionDate || session.Date)) {
                modifySessionIds.push(session.ParentSessionId);
            }
        })
        
        if (activitySession.length > 0) {

            activitySession.forEach(session => {

                let hasSession = true;
                if(!validDays.includes(session.Dayofweek)){
                    return;
                }
                if (from == GroupType.Location) {
                    hasSession = session.SessionLocationMappings?.find(location => location.StandaloneTableId == type);
                }
                else if (from == GroupType.Staff) {
                    hasSession = session.SessionStaffMappings?.find(staff => staff.ServerId == type);
                }

                if(!hasSession){
                    return;
                }

                let sessionObject = {
                    ...(!isPrivateLesson) && { ActivityBookings: [] },
                    Available: session.SessionCustomization?.MaxActivityStrength ? session.SessionCustomization.MaxActivityStrength : meal.MaxActivityStrength,
                    Booked: 0,
                    EndTime: "",
                    Locations: [],
                    SessionId: 0,
                    Staffs: [],
                    StartTime: "",
                    SessionGroupId: session.SessionGroupId,
                    LocationGroupId: session.LocationGroupId,
                    Dayofweek: session.Dayofweek,
                    MaxActivityStrength: session.SessionCustomization?.MaxActivityStrength ? session.SessionCustomization.MaxActivityStrength : meal.MaxActivityStrength,
                    AllowStandBy: meal.AllowStandBy,
                    CategoryId: meal.CategoryId || -1,

                }       
                let currentSession = session;
                
                sessionObject.SessionId = currentSession.ActivitySessionId;
                sessionObject.StartTime = currentSession.StartTime;
                sessionObject.EndTime = currentSession.EndTime;
                this.session = currentSession;
                sessionObject['Index'] = currentSession.Index;
                sessionObject['ParentSessionId'] = currentSession.ParentSessionId;
                sessionObject['SessionStaffMappings'] = currentSession.SessionStaffMappings;
                sessionObject['SessionLocationMappings'] = currentSession.SessionLocationMappings;
                sessionObject['ActivitySessionId'] = currentSession.ActivitySessionId;
                sessionObject['SessionDate'] = currentSession.SessionDate;
                sessionObject['StatusCode'] = currentSession.StatusCode;
                sessionObject['cancelledBy'] =  this.translateService.instant('cancelledBy') + " " + (session?.SessionCustomization  ? this._ps.getHostName(session.SessionCustomization?.ActionBy) : '');
                if (from == GroupType.Class || from == GroupType.Location) {  
                    sessionObject.Staffs = [];              
                    currentSession.SessionStaffMappings?.forEach(server => {
                        let currentServer = this._settings.Servers.filter(serverData => serverData.Id == server.ServerId)[0];
                        if(currentServer)
                        {
                            sessionObject.Staffs.push({
                                Id: currentServer.Id,
                                FirstName: currentServer.Name,
                                LastName: currentServer.LastName,
                                ExpertiseLevel: currentServer.ExpertiseLevelIds.length > 0 ? this.getExpertiseLevel(currentServer.ExpertiseLevelIds) : [],
                            })

                            if(from == GroupType.Class && !isPrivateLesson){
                                if(staffTempData[currentServer.Id]){
                                    let activitySession = staffTempData[currentServer.Id].ActivityBookings.find(lSession => lSession.Id === meal.Id);
                                    if(activitySession) {
                                        activitySession.Sessions.push(sessionObject);
                                    }
                                    else {
                                        staffTempData[currentServer.Id].ActivityBookings.push({Id: meal.Id, Name: meal.Name, Sessions: [sessionObject]});
                                    }
                                }else{
                                    let data = {
                                        ActivityBookings: [{Id: meal.Id, Name: meal.Name, Sessions: [sessionObject]}],
                                        Id: currentServer.Id,
                                        ExpertiseLevel: currentServer.ExpertiseLevelIds.length > 0 ? this.getExpertiseLevel(currentServer.ExpertiseLevelIds) : [],
                                        FirstName: currentServer.Name.trim(),
                                        LastName: currentServer.LastName
                                    }
                                    staffTempData[currentServer.Id] = data
                                }
                            }
                        }
                    })
                }
                if (from == GroupType.Class || from == GroupType.Staff) {
                    sessionObject.Locations = [];
                    currentSession.SessionLocationMappings?.forEach(location => {
                        let currentLocation = locationList.find(locationData => locationData.Id == location.StandaloneTableId);

                        if(currentLocation){
                            sessionObject.Locations.push({
                                Id: currentLocation.Id,
                                Name: currentLocation.Name,
                            })
                            if(from == GroupType.Class && !isPrivateLesson){
                                if(locationTempData[currentLocation.Id]){
                                    let activitySession = locationTempData[currentLocation.Id].ActivityBookings.find(lSession => lSession.Id === meal.Id);
                                    if(!modifySessionIds.includes(sessionObject.SessionId)) {
                                    if(activitySession){
                                        activitySession.Sessions.push(sessionObject);
                                    }else {
                                        locationTempData[currentLocation.Id].ActivityBookings.push({Id: meal.Id, Name: meal.Name, Sessions: [sessionObject]})
                                    }
                                }
                                }else{
                                    if(!modifySessionIds.includes(sessionObject.SessionId)){
                                    let data = {
                                        ActivityBookings: [{Id: meal.Id, Name: meal.Name, Sessions: [sessionObject]}],
                                        Id: currentLocation.Id,
                                        Name: currentLocation.Name.trim()
                                    }
                                    locationTempData[currentLocation.Id] = data
                                }
                                }
                            }
                        }

                            
                    })
                }
                if (sessionObject.ActivityBookings) {
                    sessionObject.ActivityBookings = _.sortBy(this.getBookingsForClassorSession(meal, sessionObject, reservationList, standbyList), ['BookedSessionId'])
                }
                sessions.push(sessionObject);
                
        });
            return sessions;
        }
        return;
    }

    //map the bookings related to private lesson
    getBookingsForPrivateLesson(meal, reservationList, standbyList) {
        let parties = reservationList.filter(booking => (booking.SpecialMealId == meal.Id && !booking.BookedSessions)).map(activity => { return activity.Id });
        let standByParties = standbyList.filter(booking => (booking.SpecialMealId == meal.Id && !booking.BookedSessions)).map(activity => { return activity.Id });
        return [...parties, ...standByParties];
    }

    //map the bookings related to private lesson for staff
    getBookingsForPrivateLessonForStaff(meal, serverId, reservationList, standbyList) {
        let parties = reservationList.filter(booking => (booking.SpecialMealId == meal.Id && !booking.BookedSessions && serverId == booking.ServerId)).map(activity => { return activity.Id });
        let standByParties = standbyList.filter(booking => (booking.SpecialMealId == meal.Id && !booking.BookedSessions && serverId == booking.ServerId)).map(activity => { return activity.Id });
        return [...parties, ...standByParties];
    }

    //map the bookings related to private lesson for location
    getBookingsForPrivateLessonForLocation(meal, locationId, reservationList, standbyList) {
        let parties = reservationList.filter(booking => (booking.SpecialMealId == meal.Id && !booking.BookedSessions && booking.TableIds.includes(locationId))).map(activity => { return activity.Id });
        let standByParties = standbyList.filter(booking => (booking.SpecialMealId == meal.Id && !booking.BookedSessions && booking.TableIds.includes(locationId))).map(activity => { return activity.Id });
        return [...parties, ...standByParties];
    }

    //filter the bookings for classtype class/session
    getBookingsForClassorSession(meal, sessionObject, reservationList, standbyList) {
        switch (meal.ClassType) {
            case ClassType.Session: {
                let count = 0;
                const restaurantDate = format(this.date, 'MM/DD/YYYY');
                let lastDateObj = new Date(restaurantDate);
                lastDateObj.setDate(lastDateObj.getDate() + 7);
                let reservations = [...reservationList, ...standbyList]
                let parties = reservations.filter(booking => ((booking.SpecialMealId == meal.Id) && booking.BookedSessions && (booking.BookedSessions?.filter(session => (session?.ActivitySessionId == this.session.ActivitySessionId) && (moment(format(session?.BookedDate, 'MM/DD/YYYY')).isBetween(restaurantDate, format(lastDateObj, 'MM/DD/YYYY'), null, '[)')) && (session?.SessionState == PartyState.Pending || session?.SessionState == PartyState.Seated)).length != 0)))
                    .map(activity => {
                        let session = activity.BookedSessions?.filter(session => (session.ActivitySessionId == this.session.ActivitySessionId) && (session.SessionState == PartyState.Pending || session.SessionState == PartyState.Seated))[0]
                        count = count + ((session.SessionType == PartyType.Reservation) ? session.BookingSize : 0);
                        if (session) {
                            return {
                                TaxAmount: session.TaxAmount,
                                Amount: session.RatePlanAmount ? (session.RatePlanAmount + (session.NegotiatedAmount == undefined ? 0 : session.NegotiatedAmount)) : 0,
                                BookedSessionId: session.Id,
                                PartyId: session.PartyId,
                                State: session.SessionState,
                            }
                        }
                        else {
                            return;
                        }
                    });
                reservations.filter(booking => (booking.SpecialMealId == meal.Id && booking.BookedSessions && (booking.BookedSessions?.filter(session => session && (session.ActivitySessionId == this.session.ActivitySessionId) && (format(session.BookedDate, 'MM/DD/YYYY') == restaurantDate) && (session.SessionState == PartyState.Left)).length != 0))).map(activity => {
                    let session = activity.BookedSessions?.filter(session => (session.ActivitySessionId == this.session.ActivitySessionId))[0]
                    count = count + ((session.SessionType == PartyType.Reservation) ? session.BookingSize : 0);

                });
                sessionObject.Booked = count;
                sessionObject.Available = sessionObject.Available - count
                return parties;
            }
            case ClassType.Class: {
                let count = 0;
                let reservations = [...reservationList, ...standbyList]
                let parties = reservations.filter(booking => (booking.SpecialMealId == meal.Id && (!booking.BookedSessions || !booking.BookedSessions.length) && booking.SessionGroupId && (booking.SessionGroupId == this.session.SessionGroupId)))
                    .map(activity => {
                        count = count + ((activity.Type == PartyType.Reservation) ? activity.Size : 0);
                        return {
                            Amount: activity.RatePlanAmount ? activity.RatePlanAmount : 0,
                            PartyId: activity.Id,
                            State: activity.State,
                        }
                    });
                sessionObject.Booked = count;
                sessionObject.Available = sessionObject.Available - count
                return parties;
            }
        }

    }

    getValidDays(firstDate, mealStartDate, mealEndDate) {
        firstDate = new Date(firstDate)
        firstDate.setMinutes(0);
        firstDate.setHours(0);
        firstDate.setSeconds(0);
        firstDate.setMilliseconds(0);
        var days = [];
        for (let i = 0; i <= 6; i++) {
            firstDate = moment(firstDate).format('MM/DD/YYYY');
            let firstColumnDate = new Date(firstDate)
            let columDate = new Date(firstColumnDate.setDate(firstColumnDate.getDate() + i))
            if ((columDate.getTime() >= mealStartDate.getTime()) && (columDate.getTime() <= mealEndDate.getTime())) {
                days.push(columDate.getDay())
            }
        }
        return days;
    }
}


