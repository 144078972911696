import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[isEllipsis]'
})
export class IsEllipsisDirective implements AfterViewInit {
  @Input() isEllipsis: any;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  ngAfterViewInit(): void {
    let enableEllipsis = false;
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      enableEllipsis = element.offsetWidth < element.scrollWidth ? true : false;
      if(enableEllipsis) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'title', this.isEllipsis);
      }
    }, 1);
  }
}
