import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Pipe, PipeTransform, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppService } from '@app/app.service';
import { ButtonValue } from '@app/shared/dynamicform/models/field-config.interface';
import { TablesService } from '@app/shared/services/tables.service';
import { buttonTypes, TableTabs } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { PartyDTO, SettingsDTO, StateDTO, StatusDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { Utilities } from '@utilities/utilities';
import moment from 'moment-timezone';
import { interval, Subscription } from 'rxjs';
import { ISlotPartyOrSection, SlotsSectionService } from './slots/slots-section.service';

@Component({
  selector: 'app-table-list',
  templateUrl: './tablelistComponent.html',
  styleUrls: ['./tablelistComponent.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TableListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dashboardData: any;
  @Input() shiftId: number;
  temp_holder: any = [];
  seatButton: ButtonValue;
  @Output() tableTabsChange = new EventEmitter<any>();
  tabNameList: any;
  partyState = TableTabs;
  _state: StateDTO = {} as StateDTO;
  _settings: SettingsDTO = {} as SettingsDTO;
  tabSelected: string;
  partyStatusBasedonType: StatusDTO[];
  subscriptions: Subscription = new Subscription();
  seatedTableColor: string = '';
  confirmSubscription: Subscription;
  cancelSubscription: Subscription;
  seatingParties: PartyDTO[] = [];
  selectedTabIndex : number = 0;
  allSlots: ISlotPartyOrSection[] = [];
  @ViewChild("tabsView") tabsView: ElementRef;
  constructor(public cs: CacheService,private ts: TablesService, private as:AppService, private translateService: TranslateService,private slotSection: SlotsSectionService) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
    this.subscriptions.add(cs.state.subscribe(state => {
      this.seatingParties = state.SeatingParties;
    }));
  }
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnInit() {
    this.seatButton = {
      type: buttonTypes.actionPrimarySmall,
      label: 'seat',
      customclass: 'seat-party'
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.tabNameList = [
      {
        count: this.dashboardData.tableDetails.reservations.length,
        name: 'reservationMenuText',
        data: this.dashboardData.tableDetails.reservations,
        state: TableTabs.Reservation,
        searchText: this.translateService.instant('searchReservation')
      },
    
      {
        count: this.dashboardData.tableDetails.seatingParties.length,
        name: 'seatedState',
        data: this.dashboardData.tableDetails.seatingParties,
        state: TableTabs.Seated,
        searchText: this.translateService.instant('searchSeatedParties')
      }
    ];
    if(!Utilities.IsActivitiesVenues(this.as.PropertyType)){
      let waitlistTab = {
        count: this.dashboardData.tableDetails.waitlists.length,
        name: 'waitListMenuText',
        data: this.dashboardData.tableDetails.waitlists,
        state: TableTabs.WaitLists,
        searchText: this.translateService.instant('searchWaitlist')
      };
      this.tabNameList.splice(1,0,waitlistTab);
      let slotsTab = {
        count: this.translateService.instant('slotsText'),
        name: '',
        data: [],
        state: TableTabs.Slots,
        searchText: ''
      };
      this.tabNameList.push(slotsTab);
  }
    this.subscriptions.add(interval(1000).subscribe(() => {
      this.dashboardData.tableDetails.waitlists.forEach(element => {
        this.startTimer(element);
      });
    }));
    
    if(!Utilities.IsActivitiesVenues(this.as.PropertyType) && this.slotSection.selectedSlotsTab) {
      this.selectedTabIndex = (this.tabNameList.length - 1);
      this.slotSection.selectedSlotsTab = false;
    }
    if(!Utilities.IsActivitiesVenues(this.as.PropertyType) && this.selectedTabIndex == (this.tabNameList.length - 1)) {
    this.slotSection.setReservationData();
    this.slotSection.setFilteredSlots(this.as.headerSelectedDate,this.cs.settings.value)
    this.slotSection.getWebReservableShift();
    }
    if(changes.shiftId?.currentValue) {
      this.slotSection.shiftChange(true);
    }
  }

  startTimer(party) {
    party.quotedTime = this.getQuotedTime(party);
    party.remainingTime = this.getRemainingTime(party);
    party.totalSeatingTime = this.getTotalSeatingTime(party);
  }

  seletedTabChange(event) {
    this.ts.selectedTabIndex = event.index;
    this.tableTabsChange.emit(event);
  }

  getQuotedTime(party): number {
    if (party.QuotedSeatingTime == new Date('0001-01-01T00:00:00') || party.SeatingTime == null) {
      return -1;
    }
    const time = Math.round((new Date(moment(party.SeatingTime).valueOf()).getTime() -
      new Date(moment(party.ArrivedAt).valueOf()).getTime()) / 1000);
    return time > 0 ? time : 0;
  }

  getRemainingTime(party): number {
    let time: any;
    time = (party.SeatingTime) ? Utilities.parseDateString(party.SeatingTime) : (party.ReservedFor) ?
      Utilities.parseDateString(party.ReservedFor) : Utilities.parseDateString(party.ArrivedAt);

    const remainingTime = Math.floor((time - Utilities.parseDateString(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)).getTime()) / 1000);
    return remainingTime;
  }
  getTotalSeatingTime(party): number {
    const time = Math.round((new Date(moment(party.SeatingTime).valueOf()).getTime() -
      new Date(moment(party.ArrivedAt).valueOf()).getTime()) / 60000);
    return time > 0 ? time : 0;
  }

  tabClicked(event:Event, index){
    setTimeout(() => {
      this.selectedTabIndex = index;
      if (!Utilities.IsActivitiesVenues(this.as.PropertyType) && this.selectedTabIndex == (this.tabNameList.length - 1)) {
        this.tabsView?.nativeElement.scrollIntoView();
      }
    })
    event.preventDefault();
    event.stopPropagation();
  }

  slotRefreshEvent(event): void {
    if(event) {
      this.slotSection.setFilteredSlots(this.as.headerSelectedDate,this._settings);
    }
  }

}



@Pipe({
  name: 'quotedTimeFormatter'
})
export class QuotedTimeFormatterPipe implements PipeTransform {
  constructor(private ts: TranslateService) { }
  transform(party: any): any {

    if (party.QuotedSeatingTime == new Date('0001-01-01T00:00:00') || party.SeatingTime == null) {
      return -1;
    }
    const time = Math.round((new Date(party.QuotedSeatingTime).getTime() - new Date(party.ArrivedAt).getTime()) / 1000);
    let quotedTimeSet = time > 0 ? time : 0;
    return quotedTimeSet;
  }
}


@Pipe({
  name: 'remainingTimeFormatter'
})
export class RemainingTimeFormatterPipe implements PipeTransform {
  constructor(private ts: TranslateService) { }
  transform(party: any, quotedTime): any {
    let quotedTimeSet;
    let timeSet;
    if (party.QuotedSeatingTime == new Date('0001-01-01T00:00:00') || party.SeatingTime == null) {
      quotedTimeSet = -1;
    }
    else {
      const time = Math.round((new Date(party.QuotedSeatingTime).getTime() - new Date(party.ArrivedAt).getTime()) / 1000);
      quotedTimeSet = time > 0 ? time : 0;
      timeSet = (party.SeatingTime) ? new Date(party.SeatingTime) : (party.ReservedFor) ?
        new Date(party.ReservedFor) : new Date(party.ArrivedAt);
    }
    return (quotedTimeSet - Math.floor((timeSet - new Date().getTime()) / 1000));
  }
}
