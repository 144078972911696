import { Injectable } from '@angular/core';
import { OrderSummaryComponenet } from '../../shop/order-summary/order-summary.component';
import { PlayerTransactionStatus, PlayerTransaction, PlayerPaymentstatus, PlayerTransactionDetail, RetailItemType } from '../shared.modal';
import { RetailDataAwaiters } from '../events/awaiters/retail.data.awaiters';
import { RetailUtilities } from '../utilities/retail-utilities';
import { Location } from '@angular/common';
import { ShopBussinessService } from '../../shop/shop-business.service';
import { CommonVariablesService } from '../service/common-variables.service';

@Injectable()
export class TeetimePaymentBusinessService {
  constructor(private _location: Location, public utils: RetailUtilities, private _cvs: CommonVariablesService) { }

  async UpdatePlayerPaymentStatus(compScope: OrderSummaryComponenet, playerTransDetailEntryReq: boolean, isMemberTransaction: boolean) {
    if (
      (compScope._ss.SelectedPlayers && compScope._ss.SelectedPlayers.length > 0) ||
      compScope.SettleTransactionDetail.playerTransactionDetails.length > 0
    ) {
      const playerStatus = compScope.PropertyInfo.UseRetailInterface ? PlayerPaymentstatus.unPaid : (compScope._retailService?.isFromCheckInCheckOutPopUP ? PlayerPaymentstatus.checkOut | PlayerPaymentstatus.paid : (compScope._retailService?.isToMarkCheckIn ? PlayerPaymentstatus.checkIn | PlayerPaymentstatus.paid : PlayerPaymentstatus.paid));
      const playerTransactionStatus = compScope.PropertyInfo.UseRetailInterface ? PlayerTransactionStatus.Pending : PlayerTransactionStatus.Closed;
      let _playerTransactions: PlayerTransaction[] = this.FormPlayerTransaction(
        compScope,
        playerStatus,
        playerTransactionStatus,
        compScope.getSaleAmt(true) - compScope.remainingAmount
      );
      let taskList = this.ProcessPlayerTransaction(
        compScope,
        playerStatus,
        playerTransactionStatus,
        compScope.getSaleAmt(true) - compScope.remainingAmount,
        true,
        playerTransDetailEntryReq
      );
      compScope.printReceipt(
        compScope.CreateRetailItemResponse,
        false,
        compScope.isReturnWithoutTicketForReceipt,
        isMemberTransaction,
        compScope._ss.availableRounds
      );
      Promise.all(taskList)
        .then(() => {
          compScope._ss.retailTransaction.tabState =
            compScope._ss.isFromUnpaidPlayer || compScope._ss.settleOpenTransaction
              ? 2
              : compScope._ss.retailTransaction.tabState;              
              compScope._ams.CreateTransLog(compScope.CreateRetailItemResponse.id, "UpdatedTransactionsWithPlayerStatus", { newValue: _playerTransactions });
          RetailDataAwaiters.PrintTeeTicket(
            [],
            compScope.scheduleTeeTimeIds,
            compScope.playerIds,
            compScope.selectedDate
          );
          Promise.resolve();
        })
        .catch((errorResponse) => {
          console.log(errorResponse);
          compScope._ams.CreateTransLog(compScope.CreateRetailItemResponse.id, "FailedUpdateTransactionsWithPlayerStatus", { newValue: _playerTransactions });
        });
    }
  }

  ProcessPlayerTransaction(
    compScope: OrderSummaryComponenet,
    playerStatus: PlayerPaymentstatus,
    transStatus: PlayerTransactionStatus,
    amount: number,
    transReq: boolean,
    transDetailReq: boolean
  ): Promise<any>[] {
    let taskList: Promise<any>[] = [];
    if (transReq) {
      let _playerTransactions: PlayerTransaction[] = this.FormPlayerTransaction(
        compScope,
        playerStatus,
        transStatus,
        amount
      );
      if (_playerTransactions.length > 0) {
        compScope._ams.CreateTransLog(compScope.CreateRetailItemResponse.id, "EmittedUpdateTransactionsWithPlayerStatus", { newValue: _playerTransactions });
        taskList.push(compScope._ams.UpdateTransactionsWithPlayerStatus(_playerTransactions));
        compScope._ams.CreateTransLog(compScope.CreateRetailItemResponse.id, "UpdatedTransactionsWithPlayerStatus", { newValue: _playerTransactions });
      }
    }
    if (transDetailReq) {
      let transactionDetails = this.FormPlayerTransactionDetails(compScope);
      if (transactionDetails.length > 0) {
        compScope._ams.CreateTransLog(compScope.CreateRetailItemResponse.id, "MapTransactionDetailWithPlayer", { newValue: transactionDetails })
        taskList.push(compScope._ams.MapTransactionDetailWithPlayer(transactionDetails));
      }
    }
    return taskList;
  }



  CreatePlayerTransaction(
    compScope: OrderSummaryComponenet,
    playerStatus: PlayerPaymentstatus,
    transStatus: PlayerTransactionStatus,
    amount: number,
    transReq: boolean,
    transDetailReq: boolean
  ): Promise<any>[] {
    let taskList: Promise<any>[] = [];
    if (transReq) {
      let _playerTransactions: PlayerTransaction[] = this.FormPlayerTransaction(
        compScope,
        playerStatus,
        transStatus,
        amount
      );
      if (_playerTransactions.length > 0) {
        taskList.push(compScope._ams.InsertPlayerTransactionRecord(_playerTransactions));
      }
    }
    if (transDetailReq) {
      let transactionDetails = this.FormPlayerTransactionDetails(compScope);
      if (transactionDetails.length > 0) {
        compScope._ams.CreateTransLog(compScope.CreateRetailItemResponse.id, "MapTransactionDetailWithPlayer", { newValue: transactionDetails })
        taskList.push(compScope._ams.MapTransactionDetailWithPlayer(transactionDetails));
      }
    }
    return taskList;
  }

  FormPlayerTransactionDetails(compScope: OrderSummaryComponenet) {
    if (compScope.CreateRetailItemResponse) {
      let playerTransactionDetails: PlayerTransactionDetail[] = [];
      compScope.CreateRetailItemResponse.transactionDetails.forEach((transactionRow) => {
        const currentProduct = compScope.tempSelectedProducts.find((x) => x.LineNumber == transactionRow.lineNumber);
        let playerTransactionDetail = {} as PlayerTransactionDetail;
        playerTransactionDetail.id = 0;
        playerTransactionDetail.transactionDetailId = transactionRow.id;
        playerTransactionDetail.playerId = currentProduct && currentProduct.payeeId ? currentProduct.payeeId : 0;
        if (playerTransactionDetail.playerId > 0) {
          playerTransactionDetails.push(playerTransactionDetail);
        }
      });
      return playerTransactionDetails;
    }
  }
  FormreturnPlayerTransactionDetails(retainedItems, ReturnWithTicketResponse) {
    if (ReturnWithTicketResponse) {
      let playerTransactionDetails: PlayerTransactionDetail[] = [];

      let linenumber = 1;
      retainedItems.forEach(element => {
        element.LineNumber = linenumber;
        linenumber = linenumber + 1;
      });
      ReturnWithTicketResponse.transactionDetails.forEach((transactionRow) => {
        let currentProduct = retainedItems.find((x) => x.LineNumber == transactionRow.lineNumber);
        if (currentProduct && currentProduct.payeeId && currentProduct.playerName) {
          let playerTransactionDetail = {} as PlayerTransactionDetail;
          playerTransactionDetail.id = 0;
          playerTransactionDetail.transactionDetailId = transactionRow.id;
          playerTransactionDetail.playerId = currentProduct && currentProduct.payeeId ? currentProduct.payeeId : 0;
          if (playerTransactionDetail.playerId > 0) {
            playerTransactionDetails.push(playerTransactionDetail);
          }
        }
      });
      return playerTransactionDetails;
    }
  }

  FormPlayerTransaction(compScope: OrderSummaryComponenet, playerStatus: PlayerPaymentstatus, status: PlayerTransactionStatus, amountPaid: number) {
    let _playerTransactions: PlayerTransaction[] = [];
    if (!compScope.settleOpenTransaction && !compScope.reOpenTransaction) {
      if (compScope._ss.SelectedPlayers && compScope._ss.SelectedPlayers.length > 0) {
        compScope._ss.SelectedPlayers.forEach((player) => {
          let cartfeeLineItem, greenfeeLineItem;
          if (!compScope.PropertyInfo.UseRetailInterface) {
            cartfeeLineItem = compScope._ss.Ticket.lineItems.find(
              (l) => l.externalPOSItemId == player.cartFeeRetailItemId
            );
            greenfeeLineItem = compScope._ss.Ticket.lineItems.find(
              (l) => l.externalPOSItemId == player.greenFeeRetailItemId
            );
          }
          let _playertransaction = {} as PlayerTransaction;
          _playertransaction.id = 0;
          _playertransaction.playerId = player.playerId;
          _playertransaction.greenFeeTax = greenfeeLineItem ? greenfeeLineItem.tax : 0;
          _playertransaction.cartFeeTax = cartfeeLineItem ? cartfeeLineItem.tax : 0;
          _playertransaction.transactionId = compScope.CreateRetailItemResponse
            ? compScope.CreateRetailItemResponse.transactionDetails[0].transactionId
            : 0;
          _playertransaction.amountPaid = amountPaid;
          _playertransaction.remainingDue = compScope.remainingAmount;
          _playertransaction.playerStatus = playerStatus;
          _playertransaction.status = status;
          _playertransaction.ticketNumber = compScope.CreateRetailItemResponse.transactionData.ticketNumber;
          if (!_playerTransactions.some(x => x.playerId == player.playerId)) {
            _playerTransactions.push(_playertransaction);
          }
        });
      }
    } else {
      if (compScope.SettleTransactionDetail.playerTransactionDetails.length > 0) {
        compScope.SettleTransactionDetail.playerTransactionDetails.forEach((s) => {
          let playerTrans: PlayerTransaction = {
            id: compScope.reOpenTransaction ? 0: s.playerTransactionId,
            transactionId: compScope.CreateRetailItemResponse.id,
            status: status,
            amountPaid: compScope.getSaleAmt(true) - compScope.remainingAmount,
            greenFeeTax: s.greenFeeTax,
            cartFeeTax: s.cartFeeTax,
            entryFeeTax: s.entryFeeTax,
            playerId: s.playerId,
            ticketNumber: compScope.CreateRetailItemResponse.transactionData.ticketNumber,
            playerStatus: playerStatus,
            remainingDue: compScope.remainingAmount
          };
          if (!_playerTransactions.some(x => x.playerId == s.playerId)) {
            _playerTransactions.push(playerTrans);
          }
        });
      }
    }
    return _playerTransactions;
  }

  async UpdateRefundedPlayerStatus(returnedItems, retainedItems, $scope: ShopBussinessService) {
    let transactionDetailAggregate = await RetailDataAwaiters.GetPlayerNamesByTransactionId($scope._ss.transactionId);
    if (transactionDetailAggregate.playerTransactionDetails.length > 0 && $scope.ReturnWithTicketResponse) {  
      let returningPlayerIds = returnedItems.map(s => s.payeeId);
      let _playerTransactions: PlayerTransaction[] = [];
      let _refundedPlayers = [];
      const isDepositTransaction = returnedItems.every(r => r.ItemType == RetailItemType.Deposit);
      transactionDetailAggregate.playerTransactionDetails.forEach(player => {
        let _playertransaction = {} as PlayerTransaction;
        let _isRefundedPlayer = returnedItems.some(s => s.id == player.transactionDetailId);
        if (_isRefundedPlayer) { _refundedPlayers.push(player.playerId); }
        _playertransaction.playerTransactionDetailReferenceId = player.id;
        _playertransaction.id = player.playerTransactionId;
        _playertransaction.playerId = player.playerId;
        _playertransaction.greenFeeTax = 0;
        _playertransaction.cartFeeTax = 0;
        _playertransaction.amountPaid = $scope.SettledAmt;
        _playertransaction.remainingDue = $scope.remainingAmount;
        if (!isDepositTransaction) {
          _playertransaction.playerStatus = ((_isRefundedPlayer || (player.playerStatus & PlayerPaymentstatus.refund) != 0) && (!$scope.PropertyInfo.UseRetailInterface)) ? PlayerPaymentstatus.refund : player.playerStatus;
        }
        _playertransaction.isDepositRefundTransaction = (isDepositTransaction && returnedItems.some(x=> x.ItemType == RetailItemType.Deposit && x.id == player.transactionDetailId));
        _playertransaction.status = !$scope.PropertyInfo.UseRetailInterface ? PlayerTransactionStatus.Closed : PlayerTransactionStatus.Pending;
        _playertransaction.transactionId = $scope.ReturnWithTicketResponse.transactionData.id;
        _playertransaction.ticketNumber = !$scope.PropertyInfo.UseRetailInterface ? String($scope.ReturnWithTicketResponse.transactionData.retailTicketNumber) :
          String($scope.ReturnWithTicketResponse.transactionData.ticketNumber);
        _playertransaction.depositTransactionId = $scope._ss.transactionId;
        if (!_playerTransactions.some(x => x.playerId == player.playerId && x.playerTransactionDetailReferenceId == player.id)) {
          _playerTransactions.push(_playertransaction);
        }
      });
      if (!isDepositTransaction) {
        _playerTransactions.forEach(x => x.playerStatus = _refundedPlayers.some(y => y == x.playerId) && (!$scope.PropertyInfo.UseRetailInterface) ? PlayerPaymentstatus.refund : x.playerStatus);
      }
      $scope._ams.UpdateTransactionsWithPlayerStatus(_playerTransactions).then(() => {
        Promise.resolve();
      }).catch((errorResponse) => {
        console.log(errorResponse);
      });
      if (retainedItems && retainedItems.length > 0) {
        var playerTransactiondetails = this.FormreturnPlayerTransactionDetails(retainedItems, $scope.ReturnWithTicketResponse);
        if (playerTransactiondetails.length > 0) {
          $scope._ams.MapTransactionDetailWithPlayer(playerTransactiondetails).then(() => {
            Promise.resolve();
          }).catch((errorResponse) => {
            console.log(errorResponse);
          });
        }
      }
    }
  }
}