
<section class="select-cart">
<div class="d-flex " style="align-items: center;">
<dynamic-form [config]="searchConfig" #searchConfigForm style="width: calc(100% - 150px);">
</dynamic-form>
<activities-app-button  [buttontype]="buttonAddNew" (valueChange)='cartGroupSelectHandler(null)'></activities-app-button>
</div>
<ul class="select-cart-group">
    <li *ngFor="let cartGroup of (cartGroups | searchGroup : searchText)">
        <h6 class="header-text seat-header-four">{{cartGroup.CartGroupName}}</h6>
        <div class="count-text seat-no-content-found">{{cartGroup.ItemCount}} {{'Items' | translate}}</div>
        <h6 class="header-text text-center seat-no-content-found">{{cartGroup.Name}}</h6>
        <activities-app-button  [buttontype]="buttonSelectGroup" (valueChange)='cartGroupSelectHandler(cartGroup)'></activities-app-button>
       
        <!-- <div class="TotalAmt-text">{{cs.operationCurrency}}{{cartGroup.TotalAmt}}</div> -->
    </li>
    <li *ngIf="!(cartGroups | searchGroup : searchText).length" class="seat-no-content-found text-center d-block">
        {{'noDataFound' | translate}}
    </li>
   
</ul>
</section>