import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { RoleType } from '@app/shared/models/LoginResultDTO';
import { AppService } from '@app/app.service';
import { ActivitiesVenue } from '@app/shared/constants/globalConstants';
import { Utilities } from '@app/shared/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService,private router: Router,private as:AppService) { }

  // canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

  //   if (this.authService.isLoggedIn()) {
  //     return true;
  //   }

  //   this.authService.startAuthentication();
  //   return false;
  // }
  canActivate() {
    let restaurantDetails;
    const hostRole = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    if(hostRole) {
      restaurantDetails = hostRole.RestaurantsAvailableForLogin?.find((restaurant)=> restaurant.Id == this.as.restaurantId);
    } 
    if(ActivitiesVenue.indexOf(this.as.PropertyType)>-1 || restaurantDetails?.HostRoleType.Name == RoleType[RoleType.Manager])
    {
      return true;
    }
    else{
    return this.router.navigate(['./login']);
    }
  }
}
