import {
    Directive,
    OnInit,
    ElementRef,
    Renderer2,
    HostListener,
    Output,
    EventEmitter
} from "@angular/core";
import { Subscription } from "rxjs";
import { KeyboardService } from "./keyboard.service";
import { NgControl } from "@angular/forms";

@Directive({
    selector: "[appOskInput]"
})
export class OskInputDirective implements OnInit {
    private keySubscription: Subscription;
    private backspaceSubscription: Subscription;
    private enterSubscription: Subscription;
    private measure: HTMLElement;

    @Output('input') inputAction = new EventEmitter();

    constructor(private el: ElementRef, private keyboard: KeyboardService, public ngControl: NgControl) { }

    ngOnInit() {
        // TODO I'm sure there's an "Angular way" of doing this
        let thisStyle = window.getComputedStyle(this.el.nativeElement);
        this.measure = document.createElement("span");
        this.measure.style.position = "absolute";
        this.measure.style.right = "100%";
        this.measure.style.font = thisStyle.font;
        document.body.appendChild(this.measure);
    }

    @HostListener("focus")
    private onFocus() {
        if(this.keyboard.enableKeyboard){
            this.keyboard.fireKeyboardRequested(true);
            this.subscribeToKeyboardEvents();
            setTimeout(() => {
                this.el.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
            }, 500);
        }
    }

    @HostListener("blur")
    private onBlur() {
        this.keyboard.fireKeyboardRequested(false);
        this.unsubscribeFromKeyboardEvents();
    }

    private subscribeToKeyboardEvents() {
        this.keySubscription = this.keyboard.keyPressed.subscribe(key =>
            this.onKey(key)
        );
        this.backspaceSubscription = this.keyboard.backspacePressed.subscribe(_ =>
            this.onBackspace()
        );
        this.enterSubscription = this.keyboard.enterPressed.subscribe(_ =>
            this.onEnter()
        );
    }

    private unsubscribeFromKeyboardEvents() {
        this.keySubscription?.unsubscribe();
        this.backspaceSubscription?.unsubscribe();
        this.enterSubscription?.unsubscribe();
    }

    private onKey(key: string) {
        // TODO Refactor this into a single method with the code in onBackspace
        let element = this.el.nativeElement,
            start = element.selectionStart,
            end = element.selectionEnd;

        let _value = this.ngControl.control.value ?? '';
        this.measure.textContent = _value.substr(0, start) + key;
        _value =
            _value.substr(0, start) + key + _value.substr(end);
        element.focus();
        element.selectionStart = element.selectionEnd = start + 1;
        this.ngControl.control.setValue(_value, { emitEvent: true });
        this.inputAction.emit({target: {value: _value}});
        this.updateScrollPosition();
    }

    private onBackspace() {
        let element = this.el.nativeElement,
            start = element.selectionStart,
            end = element.selectionEnd;

        if (start == 0) {
            return;
        }

        if (start == end) {
            start--;
        }

        let _value = this.ngControl.control.value;

        this.measure.textContent = _value.substr(0, start);
        _value = _value.substr(0, start) + _value.substr(end);
        element.focus();
        element.selectionStart = element.selectionEnd = start;
        this.ngControl.control.setValue(_value, { emitEvent: true });
        this.inputAction.emit({target: {value: _value}});
        this.updateScrollPosition();
    }

    private updateScrollPosition() {
        let element = this.el.nativeElement;
        element.scrollLeft = this.measure.offsetWidth - (element.clientWidth - 10);
    }

    private onEnter() {
        // TODO
        alert("Enter");
    }
}
