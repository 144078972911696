<div class="confirmation-header-title">
    <div class="header-title">{{inputData.title}}</div>
    <p class="confirmation-header-title--subtext">{{inputData.subText}}</p>
</div>

<ul class="change-list" [class.mb-40]="!formConfig?.length">
    <div class="header-title">{{'ReservationFrom' | translate}}</div>
    <div class="change-list__content">
        <li class="change-item" *ngFor="let changeItem of inputData.changes">
            <label class="change-item--label" for="">{{changeItem.label | translate}}</label>
            <div class="change-item--new">{{changeItem.oldValue}}<span *ngIf="changeItem.time">,{{changeItem.time}}</span></div>
        </li>
    </div>
    <div class="header-title">{{'UpdateTo' | translate}}</div>
    <div  class="change-list__content">
    <li class="change-item" *ngFor="let changeItem of inputData.changes">
        <label class="change-item--label" for="">{{changeItem.label | translate}}</label>
        <div class="change-item--new">{{changeItem.newValue}}<span *ngIf="changeItem.time">,{{changeItem.time}}</span></div>
    </li>
</div>
</ul>
<div class="form-container mb-40">
    <dynamic-form [config]="formConfig" #form></dynamic-form>
</div>