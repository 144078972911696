<div class="{{config.class}}">
  <mat-form-field  [formGroup]="group" [hideRequiredMarker]="!config.isStarSymbolRequired">
    <mat-label class="select-label">{{config.label  | translate}}</mat-label>
    <input matInput type="text" [ngxTimepicker]="toggleTimepicker"
      [formControlName]="config.name" name="timepicker-{{config.name}}"  placeholder="{{config.placeholder}}" [format]="24" [value]="config.value"autocomplete="off" readonly>
  </mat-form-field>
  <ngx-material-timepicker-toggle [for]="toggleTimepicker">
    <i class="icon-clock cursor" ngxMaterialTimepickerToggleIcon></i>
  </ngx-material-timepicker-toggle>
  <ngx-material-timepicker #toggleTimepicker [minutesGap]="5" [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn" (timeSet)="setTime($event)"></ngx-material-timepicker>
  <ng-template #cancelBtn ><button class="timepickerCustBtn timepicker-button">{{'cancel' | translate}}</button></ng-template>
  <ng-template #confirmBtn ><button class="timepickerCustBtn timepicker-button">{{'ok' | translate}}</button></ng-template>
</div>