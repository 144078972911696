import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CacheService } from '@app/core/services/cache.service';
import { ServerDTO, SettingsDTO, ShiftStatsDTO, ShiftStatsTableDataSource } from '@app/shared/models/RestaurantDTO';
import { SettingsService } from '@app/shared/services/settings.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-serverstatistics',
  templateUrl: './serverstatistics.component.html',
  styleUrls: ['./serverstatistics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServerstatisticsComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  allServersDataSource: ShiftStatsTableDataSource[] = [];
  tableDataSource: MatTableDataSource<ShiftStatsTableDataSource>;
  allServersList: ServerDTO[] = [];
  _settings: SettingsDTO = {} as SettingsDTO;
  shiftStatsData: ShiftStatsDTO = {} as ShiftStatsDTO;
  subscriptions: Subscription = new Subscription();
  serverSearchText: any;
  tableColumns: string[] = ['serverName', 'load'];
  dayOfWeek: string;
  currentTime: string;
  displayDate: string;
  restaurantDateTime: Date;
  displayDayName: string;
  showServerLoad = false;
  constructor(private ss: SettingsService, public dialog: MatDialog, private ts: TranslateService,
    public cs: CacheService) { 

    }

  ngOnInit(): void {

    this.subscriptions.add(this.cs.settings.subscribe(settings => {
      this._settings = settings;
      this.restaurantDateTime = new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
      this.allServersList = this._settings.Servers;
    }));
    this.loadShiftStats();
    
    this.subscriptions.add(this.cs.refreshServerStatistics$.subscribe(servershiftStats => {
      if (servershiftStats) {
        this.SetShiftStats(servershiftStats);
      }
    }));
  }

  loadShiftStats() {
    //this.hide_avgTime = Utilities.controlValidate(controlSettings.ShiftStatistics_WaitTime_Hide , this._as.PropertyType);
    this.subscriptions.add(this.ss.GetShiftStats().subscribe((result) => {
      if (result.Payload) {
        this.SetShiftStats(result.Payload);
      }
    }));
  } 

  SetShiftStats(servershiftStats:any){
    this.shiftStatsData = servershiftStats;
    this.dayOfWeek = ' (' + moment(this.shiftStatsData.DisplayedRange.Start).format('MMM DD hh.mm A') + ' - ' + moment(this.shiftStatsData.DisplayedRange.End).format('MMM DD hh.mm A') + ')';
    this.currentTime = moment(this.restaurantDateTime).format('hh.mm A');
    this.displayDate = moment(this.restaurantDateTime).format('MMM DD, YYYY');
    this.displayDayName = moment(this.shiftStatsData.DisplayedRange.Start).format('dddd');
    this.setTableDataSource();
  }

  setTableDataSource() {
    if (!this.serverSearchText) {
      this.allServersDataSource = [];
      //this.subscriptions.add(this.cs.settings.subscribe((value) => {
        this.allServersList = this._settings.Servers;
     // }));

      this.shiftStatsData.ServerStats.forEach((server) => {
        const serverDetails: ShiftStatsTableDataSource = {
          serverName: this.allServersList.filter(x => x.Id == server.ServerId)[0].Name,
          seated: this.getSeatedValue(server.LastActionTime),
          load: (Math.round(server.Load * 100)),
          current: server.CurrentPartiesSeating + "/" + server.CurrentGuestsSeating,
          tally: server.TotalPartiesSeated + "/" + server.TotalGuestsSeated,
          duration: this.getSeatedDuration(server.LastActionTime)
        };
        this.allServersDataSource.push(serverDetails);
      });
    }
    this.tableDataSource = new MatTableDataSource(this.allServersDataSource);
    this.tableDataSource.sort = this.sort;
    this.onSortData({ active: 'load', direction: 'asc' });
  }

  getSeatedValue(lastActionTime: Date) {
    if (lastActionTime == null) { return '-' }
    let duration: number;
    let diffHours: number;
    duration = Math.round((new Date(this.restaurantDateTime).getTime() - new Date(lastActionTime).getTime()) / 60000);
    diffHours = duration / 60;

    if (diffHours > 24) { return '>24 h'; }
    if (diffHours <= 24 && diffHours >= 1) { return diffHours.toFixed() + ' h'; }
    if (diffHours < 1) { return duration.toFixed() + ' m'; }
  }

  getSeatedDuration(lastActionTime: Date) {
    if (lastActionTime == null) { return '-' }
    let duration: number;
    let diffHours: number;
    duration = Math.round((new Date(this.restaurantDateTime).getTime() - new Date(lastActionTime).getTime()) / 60000);
    return duration;
  }

  onSortData(sort: Sort) {
    let data = this.allServersDataSource.slice();
    if (sort.active && sort.direction !== '') {
      data = data.sort((server1: ShiftStatsTableDataSource, server2: ShiftStatsTableDataSource) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'serverName':
            return this.compare(server1.serverName.toLowerCase(), server2.serverName.toLowerCase(), isAsc);
          case 'load':
            return this.compare(server1.load, server2.load, isAsc);
          case 'tally':
            let tallySer1 = parseInt(server1.tally.split("/")[1]);
            let tallySer2 = parseInt(server2.tally.split("/")[1]);
            return this.compare(tallySer1, tallySer2, isAsc);
          case 'current':
            let currentSer1 = parseInt(server1.current.split("/")[1]);
            let currentSer2 = parseInt(server2.current.split("/")[1]);
            return this.compare(currentSer1, currentSer2, isAsc);
          case 'seated':
            return this.compare(server1.duration, server2.duration, isAsc);
          default: return 0;
        }
      });
    }
    this.tableDataSource = new MatTableDataSource(data);
  }

  private compare(server1, server2, isAsc) {
    return (server1 < server2 ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getBackGroundColor(load: number) {
    if (load < 50) { return '#36A322'; }
    if (load >= 50 && load < 100) { return '#0779E4'; }
    if (load >= 100) { return '#ED2C2C'; }
  }

  getBackGroundColorLoad(load: number) {
    if (load < 50) { return '#DCF1E7'; }
    if (load >= 50 && load < 100) { return '#C6E2FE'; } // Blue
    if (load >= 100) { return '#FFD0D0'; }
  }

  getBackGroundColorSeated(duration: number) {
    if (duration < 20) { return '#FFD0D0'; }
    if (duration >= 20) { return '#DCF1E7'; }
  }



}
