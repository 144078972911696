<section class="dialog-parent-container" #dialogconatiner>

  <div *ngIf="dialogData.componentDetails.popupType === 'action'" class="action-popup-container">
    <div mat-dialog-title class="dialog-container__header" *ngIf="showTitle">
      <span class="dialog-container__header-title seat-header-one">{{this.dialogTitle | translate}} </span>
      <span class="dialog-container__header-close icon-Group-591" (click)="close(0)"></span>
    </div>
    <div *ngIf="!showTitle" class="icon-Group-591 action-close-icon" (click)="close(0)"></div>
    <div class="action-container-body" [ngClass]="{'container-adjusted-height': dialogData.showAction}">
      <ng-container #dynamicComponent></ng-container>
    </div>
     <div *ngIf="dialogData.showAction" class="dialog-actions-btn">
      <activities-app-button [buttontype]="buttonValueprimary" (valueChange)='save($event)'></activities-app-button>
     </div>
  </div>
  <div class="dialog-container"
    *ngIf="dialogData.componentDetails.popupType !== 'action' && dialogData.componentDetails.popupType !== 'tabs' && dialogData.componentDetails.popupType !== 'static'">
    <div mat-dialog-title class="dialog-container__header" [ngClass]="{'dialog-container__no-back-button': !isBackAvailable}">
      <span class="icon-Path-490 dialog-container__header-back" *ngIf="isBackAvailable" [ngClass]="{'dialog-container__back-disabled': isBackDisabled}" (click)='previous($event)'>
        <span class="back-text">{{'back' | translate}}</span></span>
      <span class="dialog-container__header-title seat-header-one"
        *ngIf="!dialogData.standalone">{{ps.dialogPopupTittle | translate}}</span>
      <span class="dialog-container__header-title seat-header-one" *ngIf="dialogData.standalone">{{dialogData.title | translate}}</span>
      <span class="dialog-container__header-close icon-Group-591" *ngIf="showClose" (click)="close(0)"></span>
    </div>
    <div class="dialog-container__content" [ngClass]="{'dialog-container__content-adjust': !showFooter}">
      <ng-container #dynamicComponent></ng-container>
    </div>
    <mat-dialog-actions class="dialog-container__actions" *ngIf="showFooter">
      <div *ngIf="!this.dialogData.componentDetails.popUpDetails.isStepper" class="action-buttons" [ngClass]="{'selection-view': dialogData.selectionView == true}">
        <activities-app-button [buttontype]="buttonSecondary" (valueChange)='close(1)'></activities-app-button>
        <activities-app-button [buttontype]="buttonValueprimary" (valueChange)='save($event)'></activities-app-button>
        <!-- <button mat-button class="actions__cancel" (click)="close()">{{dialogData.cancel}}</button> -->
        <!-- <button mat-button [disabled]="false" class="actions__save" (click)="save('save')">{{dialogData.update}}</button> -->
      </div>
      <div *ngIf="this.dialogData.componentDetails.popUpDetails.isStepper" class="action-buttons">
        <!-- <button mat-button (click)="stepChange('previous')" [disabled]="!stepperService.enablePrevious">previous</button>
        <button mat-button (click)="close()">cancel</button>
        <button mat-button (click)="stepChange(stepperService.buttonText)"
          [disabled]="!stepperService.enableNext">{{stepperService.buttonText}}</button> -->


        <!-- <activities-app-button [buttontype]="buttonValuePrevious" (click)='previous($event)'></activities-app-button> -->
        <activities-app-button [buttontype]="buttonValueCancel" (valueChange)='previous($event)'></activities-app-button>
        <activities-app-button [buttontype]="buttonValueNext" (valueChange)='next($event)'></activities-app-button>
      </div>
    </mat-dialog-actions>
  </div>
  <div *ngIf="dialogData.componentDetails.popupType === 'tabs'" class="dialog-container">
    <div mat-dialog-title class="dialog-container__header">
      <span class="icon-Path-490 dialog-container__header-back" [ngClass]="{'dialog-container__back-disabled': isBackDisabled}" (click)='previous($event)'>
        <span class="back-text">{{'back' | translate}}</span></span>
      <span class="dialog-container__header-title seat-header-one"
        *ngIf="!dialogData.standalone">{{ps.dialogPopupTittle | translate}} <span *ngIf="dialogData.showRestaurantName">({{restaurantName}})</span></span>
      <span class="dialog-container__header-title seat-header-one" *ngIf="dialogData.standalone">{{dialogData.title | translate}}</span>
      <span class="dialog-container__header-close icon-Group-591" (click)="close(0)"></span>
    </div>
    <div class="dialog-container__content">
      <ng-container #dynamicComponent></ng-container>
    </div>
  </div>
  <div class="action-popup-container"
  *ngIf="dialogData.componentDetails.popupType === 'static'">
  <div mat-dialog-title class="dialog-container__header" [ngClass]="{'dialog-container__no-back-button': !isBackAvailable}">

    <span class="dialog-container__header-title seat-header-one" *ngIf="dialogData.standalone">{{dialogData.title | translate}}</span>
    <span class="dialog-container__header-close icon-Group-591" (click)="close(0)"></span>
  </div>
  <div class="dialog-container__content" [ngClass]="{'dialog-container__content-adjust': !showFooter}">
    <ng-container #dynamicComponent></ng-container>
  </div>
    <div  class="dialog-actions-btn">
      <activities-app-button [buttontype]="buttonValueprimary" (valueChange)='close(0)'></activities-app-button>
    </div>

  </div>
</section>
