<div class="layout iframe-load">
  <div class="layout__header " *ngIf="isLoaded">
    <app-header (onCartClick)="cartClick($event)"></app-header>
    <div [class.showcart]="disableCart" [hidden]="true" class="layout__cart-container">
      <app-cart-items-display [showCartItem]="disableCart" [count]="ps.cartCount" [cartUpdated]="ps.isCartUpdated"></app-cart-items-display>
    </div>
  </div>
  <div class="layout" *ngIf="isLoaded && !Activitiestimeline">   
    <app-time-line></app-time-line>
  </div>

  <div class="layout" *ngIf="isLoaded && Activitiestimeline">
    <app-activities-main-timeline></app-activities-main-timeline>
  </div>


</div>