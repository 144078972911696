<section class="newVersionWrapper retail-transcations-log-wrapper com-pad-12">
    <div class="header-filter-sect d-flex ">
     <form [formGroup]="revenueForm" class="d-flex">
         <mat-form-field class="d-inline-block ct-pad-3 date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
            <mat-label>{{captions.fromDate}}</mat-label>
            <input formControlName="fromDate" dateRestricter (dateChange)="fromDate($event)" class="LW14" matInput
              [matDatepicker]="picker3" [placeholder]="captions.fromDate" name="date" [attr.automationId]="'Txt_revenuePostingLogs_fromDate'">
            <mat-datepicker #picker3></mat-datepicker>
            <i [attr.automationId]="'Icn_revenuePostingLogs_fromDate'" aria-hidden="true" matSuffix class="icon-Calender" (click)="picker3.open()"></i>
            <mat-error *ngIf="revenueForm.controls['fromDate'].hasError('matDatepickerParse')">{{captions.common.dateFormat}}</mat-error>
            <mat-error *ngIf="revenueForm.controls['fromDate'].hasError('matDatepickerMin')">{{captions.common.minimum}} {{captions.shop.fromDate}}</mat-error>
            <mat-error *ngIf="revenueForm.controls['fromDate'].hasError('matDatepickerMax')">{{captions.common.maximum}} {{captions.shop.fromDate}}</mat-error>
          </mat-form-field>
          <mat-form-field class="d-inline-block ct-pad-3 date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
            <mat-label>{{captions.toDate}}</mat-label>
            <input formControlName="toDate" [min]="fromDate" dateRestricter (dateChange)="toDate($event)" class="LW14" matInput
              [matDatepicker]="picker2" [placeholder]="captions.toDate" name="date" [attr.automationId]="'Txt_revenuePostingLogs_toDate'">
            <mat-datepicker #picker2></mat-datepicker>
            <i [attr.automationId]="'Icn_revenuePostingLogs_toDate'" aria-hidden="true" matSuffix class="icon-Calender" (click)="picker2.open()"></i>
            <mat-error *ngIf="revenueForm.controls['toDate'].hasError('matDatepickerParse')">{{captions.common.dateFormat}}</mat-error>
            <mat-error *ngIf="revenueForm.controls['toDate'].hasError('matDatepickerMin')">{{captions.common.minimum}} {{captions.shop.toDate}}</mat-error>
            <mat-error *ngIf="revenueForm.controls['toDate'].hasError('matDatepickerMax')">{{captions.common.maximum}} {{captions.shop.toDate}}</mat-error>
          </mat-form-field>
     </form>

    </div>
    <div class="advanced-search-sect">
        <span class="ag_mb-1">{{captions.AdvancedSearch}}</span>
      <form [formGroup]="advanceForm" class="d-flex al-centre">
        <input matInput autocomplete="off" formControlName="ticketNumber" class="search-input" type="text" 
        [attr.automationId]="'Txt_revenuePostingLogs_ticketNumber'" [placeholder]="captions.tbl_hdr_ticketNumber" />
        <input matInput autocomplete="off" formControlName="failedAmount" class="search-input" inputtype="nonnegative,decimal,roundoff2"
        [attr.automationId]="'Txt_revenuePostingLogs_failedAmount'" [placeholder]="captions.tbl_hdr_failedAmount" />
        <input matInput autocomplete="off" formControlName="user" class="search-input" type="text"
        [attr.automationId]="'Txt_revenuePostingLogs_user'" [placeholder]="captions.tbl_hdr_user" />
         <app-button [buttontype]="searchButton" [attr.automationId]="'Btn_revenuePostingLogs_search'" class="ag-pl-3 " (valueChange)="search($event)"></app-button>
         <app-button [buttontype]="cancelButton" [attr.automationId]="'Btn_revenuePostingLogs_cancel'" (valueChange)="cancel($event)"></app-button>
      </form>
    </div>
    <div class="prop-actions d-flex">
            <marquee *ngIf = "isBatchProcessPending">{{batchInProgressMsg}}</marquee>
            <app-button [buttontype]="refreshButton" [attr.automationId]="'Btn_revenuePostingLogs_refresh'" (valueChange)="refreshGrid($event)"></app-button>
            <app-button [buttontype]="retryButton" [attr.automationId]="'Btn_revenuePostingLogs_retry'" (valueChange)="retry($event)"></app-button>
            <app-button [buttontype]="closeButton" [attr.automationId]="'Btn_revenuePostingLogs_close'" (valueChange)="close($event)"></app-button>
     </div>
    <div class="table-sect w-100 " [attr.automationId]="'Tbl_revenuePostingLogs_revenuePostingTable'">
     <app-cdkvirtual [headerOptions]="headerOptions" [tableContent]="tableContent | async" [options]="tableOptions"
     [searchOptions]="searchText"  [childTemplate]="childTemplate" (EmittedData)='tableAction($event)'>
    </app-cdkvirtual>
    </div>


   <ng-template #childTemplate let-element="element" let-key="key" let-index="idx">
     <ng-container [ngSwitch]="key">
         <ng-container *ngSwitchCase="'errorMessage'">
             <div class="revenue-pop ag_display--inblock">
                 <span>{{element['errorMessage']}} <i class="icon-information" [matTooltipClass]="'AgysMatCustTooltip'" [matTooltip]="element['errorMessagefullView']"></i></span>
                    <!-- <popover-content #errorMsg class="transc-pop" placement="left" class="ag_w--100"
                    [closeOnClickOutside]="true" [closeOnMouseOutside]="false">
                    <div >
                        <span>{{element['errorMessagefullView']}}</span>

                    </div>
                   </popover-content> -->
             </div>
         </ng-container>
         <ng-container *ngSwitchCase="'paymentMethod'">
          <div class="revenue-pop ag_display--inblock">
              <span>{{element['paymentMethod']}} <i class="icon-information" [matTooltipClass]="'AgysMatCustTooltip'" [matTooltip]="element['paymentMethodfullView']"></i></span>
                 <!-- <popover-content #errorMsg class="transc-pop" placement="left" class="ag_w--100"
                 [closeOnClickOutside]="true" [closeOnMouseOutside]="false">
                 <div >
                     <span>{{element['paymentMethodfullView']}}</span>

                 </div>
                </popover-content> -->
          </div>
      </ng-container>
         <ng-container *ngSwitchCase="'Action'">
             <div class="room-block ag_display--inblock">
                 <button class="custom-focus-btn custom-left-padding ag_pr--2 ag_cursor--pointer icon-reset"
                 [ngClass]="{'ag_section--disable': isBatchProcessPending}" (click)="tableRetry(element,key)"
                 [matTooltipClass]="'AgysMatCustTooltip'" [matTooltip]="captions.retry" [attr.automationId]="'Btn_revenuePostingLogs_tableRetry'"></button>

                 <button class="custom-focus-btn1 custom-left-padding ag_pr--2 ag_pl--2 ag_cursor--pointer icon-error-icon"
                 [ngClass]="{'ag_section--disable': isBatchProcessPending}" (click)="tableClose(element,key)"
                 [matTooltipClass]="'AgysMatCustTooltip'" [matTooltip]="captions.Close" [attr.automationId]="'Btn_revenuePostingLogs_tableClose'"></button>


             </div>
         </ng-container>
         <ng-container *ngSwitchCase="'failedAmount'">
            <div class="room-block ag_display--inblock">
                <span>
                    {{element['failedAmount'] | Currency}}
                  </span>
            </div>
        </ng-container>
     </ng-container>
 </ng-template>



 </section>
