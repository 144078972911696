import { Injectable } from '@angular/core';
import { MenuSearchModel, searchtitleenum, MenuSearchData } from './menu-search.model';
import { MenuObj } from './menu.model';
import { MenuSearchDataService } from './menu-search.service';
import { AppService } from 'src/app/common/app-service';
import { Localization } from 'src/app/common/localization/localization';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { CommonUtilities } from '../../../../shared/shared/utilities/common-utilities';
import { PMS_SYSTEM, Product } from '../../globalsContant';
import { Menu } from '../../../../constants';

@Injectable()
export class MenuSearchBusiness {

  private readonly ProductID: number;
  captions: any;
  constructor(private _menuSearchService: MenuSearchDataService, private _appservice: AppService, private _localization: Localization,
    private routeDataService: RouteLoaderService,
    private PropertyInfo: CommonPropertyInformation, private utilities: CommonUtilities) {
    this.ProductID = this._appservice.productId;
    this.captions = this._localization.captions.common;
  }

  filteredMenus: MenuObj[] = [];
  currParentMenus: MenuObj[] = [];
  public async menuSearch(pattern: string): Promise<MenuSearchModel[]> {
    const filterData: MenuSearchModel[] = [];
    //Menu
    let Menudata = await this.searchMenus(pattern);
    if (Menudata && Menudata.length > 0) {
      filterData.push(this.mapMenuData(Menudata));
    }
    return filterData;
  }

  private async searchMenus(pattern: string) {
    //get all menu's
    let menuList: MenuObj[] = await this.getAllMenus();
    if (menuList && menuList.length > 0) {
      this.filteredMenus = [];
      this.currParentMenus = [];
      let isCheckInCheckOutEnabled = false;
      if (this.ProductID == Product.GOLF) {
        let golfTeeTimeSetting = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
        isCheckInCheckOutEnabled = golfTeeTimeSetting && golfTeeTimeSetting != null && golfTeeTimeSetting.requireCheckInCheckOut;
      }
      menuList.forEach(menu => {
        if (menu.text.toLowerCase().includes(pattern.toLowerCase()) && menu.visibility)
          this.filteredMenus.push(menu);
        if (menu.linkedElement && menu.linkedElement.length > 0) {
          if (isCheckInCheckOutEnabled) {
            menu.linkedElement.map(x => {
              if (x.elementID == Menu.unPaidPlayerElementId) {
                x.text = this._localization.captions.shop?.CheckedInUnpaidMenu;
              }
              return x;
            })
          }
          this.currParentMenus.push(menu);
          this.filterLinkElements(menu.linkedElement, pattern);

        }
      })
    }
    return this.filteredMenus;
  }

  private async getAllMenus(): Promise<MenuObj[]> {
    let menus: MenuObj[] = [];
    let retailMenus: string[] = [
      '/settings/retailsetup/codesetup/taxes',
      '/settings/retailsetup/codesetup/discounttypes',
      '/settings/retailsetup/codesetup/paymentmethods',
      '/settings/retailsetup/codesetup/creditcardterminals',
      '/settings/retailsetup/codesetup/creditcards',
      '/settings/retailsetup/codesetup/giftcards',
      '/settings/retailsetup/codesetup/vendortypes',
      '/settings/retailsetup/codesetup/posttypemapping',
      '/settings/retailsetup/codesetup/resortfinanceposttypemapping',
      '/settings/retailsetup/codesetup/discountreason',
      '/settings/retailsetup/codesetup/machinename',
      '/settings/retailsetup/codesetup/generalledgermapping'
    ];

    menus = await this.routeDataService.currentSettings;
    if (this.ProductID == 1 || this.ProductID == 3 || this.ProductID == 2 || this.ProductID === 6) {
      menus.filter(x => x.routePath === '/settings')[0].linkedElement
        .filter(x => x.routePath === '/settings/retailsetup')[0].linkedElement
        .filter(x => x.routePath === '/settings/retailsetup/codesetup')[0].linkedElement
        .map(result => {
          if (result) {
            if (this.PropertyInfo.UseRetailInterface) {
              if (retailMenus.includes(result.routePath)) {
                result.visibility = false;
              }
            }
            else {
              if (result.routePath === '/settings/retailsetup/codesetup/storeterminals') {
                result.visibility = false;
              }

              if (result.routePath === '/settings/retailsetup/codesetup/posttypemapping') {
                const pmsSystem = sessionStorage.getItem("pmsSystem")?.toLowerCase();
                result.visibility = (pmsSystem && pmsSystem === PMS_SYSTEM.VISUALONE.toLowerCase());                           
              }

              if (result.routePath === '/settings/retailsetup/codesetup/resortfinanceposttypemapping') {
                const resortFinance = "enableResortFinance";
                const booleanTrue = "true";
                const enableResortFinance = sessionStorage.getItem(resortFinance);
                result.visibility = (enableResortFinance?.toLowerCase() === booleanTrue.toLowerCase());                           
              }

              if (result.routePath === '/settings/retailsetup/codesetup/generalledgermapping') 
              {
                const feature = JSON.parse(sessionStorage.getItem('propConfig'));
                result.visibility = feature && feature.EnableGLExtract?.toLowerCase() === 'true';
              }
              if (result.routePath === '/settings/retailsetup/codesetup/vendortypes') {                
                result.visibility = !this.PropertyInfo.IsEatecAsMaster
              }
            }

          }
        });
      menus.filter(x => x.routePath === '/settings')[0].linkedElement
        .filter(x => x.routePath === '/settings/retailsetup')[0].linkedElement
        .map(result => {
          if (result) {
            if (this.PropertyInfo.UseRetailInterface) {
              if (result.routePath === '/settings/retailsetup/discountconfiguration') {
                result.visibility = false;
              }
            }
          }
        });

      menus.filter(x => x.routePath === '/settings')[0].linkedElement
        .filter(x => x.routePath === '/settings/retailsetup')[0].linkedElement
        .map(result => {
          if (result) {
            const isEatecAsMaster = this.PropertyInfo.IsEatecAsMaster;
            const isEatecEnabled = this.PropertyInfo.IsEatecEnabled;
            const isRetailIC = this.PropertyInfo.IsRetailIC;
            if (this.PropertyInfo.UseRetailInterface || isEatecAsMaster) {
              if (result.routePath === '/settings/retailsetup/vendorsetup') {
                result.visibility = false;
              }
            }
            if (result.routePath === '/settings/retailsetup/eatecintegration') {
              result.visibility = isEatecAsMaster || (isEatecEnabled && !isRetailIC);
                result.linkedElement.forEach(result => {
                  if (result) {
                    if (result.routePath === '/settings/retailsetup/eatecintegration/saledepletionlog')
                      result.visibility = isEatecAsMaster || (isEatecEnabled && !isRetailIC);
                    if (result.routePath === '/settings/retailsetup/eatecintegration/itemmapping')
                      result.visibility = isEatecAsMaster;
                  }
                });
            }
            
          }
        });

      menus.filter(x => x.routePath === '/settings')[0].linkedElement
        .forEach(result => {
          if (result) {
            if(this.PropertyInfo.IsEatecAsMaster) { // Eatec as Master
              if (result.routePath === '/settings/inventory/inventory' || result.routePath === '/settings/inventorysetup' || result.routePath === '/settings/inventory') {
                result.visibility = false;
              }
              if (result.routePath === '/settings/enhancedInventory') {
                result.visibility = false;
                result.linkedElement.forEach(result => {
                  if (result) {
                    if (result.routePath.includes('/settings/enhancedInventory')) {
                      result.visibility = false;
                      result.linkedElement.forEach(result => {
                        if (result) {
                          if (result.routePath.includes('/settings/enhancedInventory')) {
                            result.visibility = false;
                          }
                        }
                      })
                    }
                  }
                })
              }
              if (result.routePath === '/settings/utilities'){
                result.linkedElement.forEach(res => {
                    if (res.routePath === '/settings/utilities/inventorysync')
                      res.visibility = false;
                  }
                )
              }
            }
            else if (this.PropertyInfo.UseRetailInterface || !this.PropertyInfo.IsEatecEnabled) { // Retail Inventory
              if (result.routePath === '/settings/enhancedInventory') {
                result.visibility = false;
                result.linkedElement.forEach(result => {
                  if (result) {
                    if (result.routePath.includes('/settings/enhancedInventory')) {
                      result.visibility = false;
                      result.linkedElement.forEach(result => {
                        if (result) {
                          if (result.routePath.includes('/settings/enhancedInventory')) {
                            result.visibility = false;
                          }
                        }
                      })
                    }
                  }
                })
              }
              if (result.routePath === '/settings/utilities'){
                result.linkedElement.forEach(res => {
                    if (res.routePath === '/settings/utilities/inventorysync')
                      res.visibility = false;
                  }
                )
              }
            }
            else if (this.PropertyInfo.IsEatecEnabled) { // Eatec or Enable retail IC
              if (result.routePath === '/settings/inventory/inventory' || result.routePath === '/settings/inventorysetup' || result.routePath === '/settings/inventory') {
                result.visibility = false;
              }
              let propConfig = sessionStorage.getItem('propConfig') ? JSON.parse(sessionStorage.getItem('propConfig')) : null;        
                if (result.routePath === '/settings/utilities'){
                  result.linkedElement.forEach((res) => {
                    if (res.routePath === "/settings/utilities/inventorysync") {
                      res.visibility= true;
                    }
                  });
                }  
              if (result.routePath === '/settings/enhancedInventory'){
                  result.linkedElement.forEach((res) => {
                      if(res.visibility){
                       if (res.routePath === "/settings/enhancedInventory/inventory") {
                           res.routePath= "/settings/enhancedInventory/masterlist/inventorylist";
                           res.routePathIC = "/settings/enhancedInventory/inventory";
                       }
                          if (res.routePath === "/settings/enhancedInventory/procurement") {
                           res.routePath= "/settings/enhancedInventory/transaction/view-receiving";
                           res.routePathIC = "/settings/enhancedInventory/procurement";
                       }
                      }
                   });
              }            
            }
          }
        });

      menus.filter(x => x.routePath === '/shop/viewshop')[0].linkedElement
        .forEach(result => {
          if (result) {
            if (result.routePath === '/shop/viewshop/giftcards') {
              result.visibility = (!this.PropertyInfo.UseRetailInterface);
            }
          }
        });
      menus.filter(x => x.routePath === '/shop/viewshop')[0].linkedElement
        .filter(x => x.routePath === '/shop/viewshop/retailtransactions')[0].linkedElement
        .map(result => {
          if (result) {
            if (result.routePath === '/shop/viewshop/retailtransactions/returnwithticket' || result.routePath === '/shop/viewshop/retailtransactions/transactionslog'
              || result.routePath === '/shop/viewshop/retailtransactions/revenuepostingslog') {
              result.visibility = (!this.PropertyInfo.UseRetailInterface);
            } else if (this.PropertyInfo.UseRetailInterface && result.routePath === '/shop/viewshop/retailtransactions/returnwithoutticket') {
              result.text = result.text.replace('without', '');
            }
            if (result.routePath === '/shop/viewshop/retailtransactions/datamaginereceiptlog') {
              let feature = JSON.parse(sessionStorage.getItem('DataMagineConfig'));
              result.visibility = feature && feature?.postCheckReceiptToDM  || false;
            }
          }
        });
      menus.filter(x => x.routePath === '/reports')[0].linkedElement
        .forEach(result => {
          if (result) {
            if (result.routePath === '/reports/giftcards') {
              result.visibility = (!this.PropertyInfo.UseRetailInterface);
            }
            if (result.routePath === '/reports/inventorycontrol') {
              result.visibility =  this.PropertyInfo.IsEatecAsMaster ? false :  this.PropertyInfo.IsEatecEnabled;
            }
          }
        });

      if (this.ProductID == 3) {
        menus.filter(x => x.routePath === '/settings')[0].linkedElement
          .filter(x => x.routePath === '/settings/usersetup')[0].linkedElement
          .forEach(result => {
            if (result) {
              if (result.routePath === '/settings/usersetup/bookingPermissions') {
                const setting = JSON.parse(sessionStorage.getItem('GOLFSETTING'));
                if (setting && setting.enableAllocationCodePermissionByRole) {
                  result.visibility = true;
                } else {
                  result.visibility = false;
                }
              }
            }
          });
      }
    }
    else {
      // For sales&Catering the route path is "/setting/retailsetup", where in other products
      // it is "/settings/retailsetup". Once the routepath is corrected in S&C, we can remove the if condition for product check
      // Until then kindly have the code for menu filtering using the routes available in S&C today
      // for eg: use -> menus.filter(x => x.routePath === '/setting')[0].linkedElement                
      // or use any unique column values across products
    }
    if (this.ProductID == 1 || this.ProductID == 2 || this.ProductID == 3 || this.ProductID == 5) {
      menus.filter(x => x.routePath === '/settings')[0].linkedElement
        .filter(x => x.routePath === '/settings/retailsetup')[0].linkedElement
        .filter(x => x.routePath === '/settings/retailsetup/codesetup')[0].linkedElement
        .map(result => {
          if (result) {
            if (result.routePath === '/settings/retailsetup/codesetup/machinename') {
              if (this.PropertyInfo.GetEnablemachineTransaction() == 'false') {
                result.visibility = false;
              } else {
                result.visibility = true;
              }
            }
          }
        });
    }
    if(this.ProductID == Product.GOLF){
      menus.filter(x => x.routePath === "/guest")[0].linkedElement
      .map(result => {
        if (result) {
          if (result.routePath === "/guest/members/members") {
            result.visibility = this.PropertyInfo.IsAcesMembershipEnabled ? true : false;            
            result.linkedElement.map(x=>x.visibility = false);
          }
        }
      });
    }
    return menus.filter(x => x.productID == this.ProductID);
  }

  private filterLinkElements(linkElements: MenuObj[], pattern: string) {
    if (linkElements && linkElements.length > 0) {
      linkElements.forEach(element => {
        if (this.ProductID == Product.GOLF && (element.routePath === '/settings/rate/dynamicpricing' || element.routePath === '/settings/golfsetup/seasonSetup')) {
          let golfsetting = JSON.parse(sessionStorage.getItem('GOLFSETTING')); 
          element.visibility = golfsetting && golfsetting.enableDynamicPricing  ? true : false; 
        }
        if (element.text.toLowerCase().includes(pattern.toLowerCase()) && element.visibility) {
          let parentElement = this.currParentMenus.find(x => x.elementID == element.parentID);
          if (parentElement) {
            element.parentElementText = parentElement.text ? parentElement.text : "";
          }
          this.filteredMenus.push(element);
        }

        if (element.linkedElement && element.linkedElement.length > 0) {
          this.currParentMenus.push(element);
          this.filterLinkElements(element.linkedElement, pattern);
        }
      })
    }
  }

  private mapMenuData(menuResult: MenuObj[]): MenuSearchModel {
    return <MenuSearchModel>{
      title: { name: searchtitleenum.MENUSEARCH },
      dataCollection: menuResult.map(menu => {
        return <MenuSearchData>{
          id: menu.tenantID,
          value: menu.text,
          productID: menu.productID,
          routePath: menu.routePath,
          breakPointNo: menu.breakPointNumber ? menu.breakPointNumber : 0,
          menuPosition: menu.menuPosition,
          parentValue: menu.parentElementText ? menu.parentElementText : "",
          textID: menu.textID,
          EntityElementID: menu.elementID,
          isReportUrl : menu.routePath.includes('/reports')
        }
      })
    };
  }
}
