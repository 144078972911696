
import { Injector } from "@angular/core";
import { Router } from "@angular/router";
import { QuickLoginUtilities } from "@app/common/shared/shared/utilities/quick-login-utilities";
import { RetailPropertyInformation } from "@app/retail/common/services/retail-property-information.service";
import { RetailItemType } from "@app/retail/retail.modals";
import { RetailService } from "@app/retail/retail.service";
import { RetailDataAwaiters } from "@app/retail/shared/events/awaiters/retail.data.awaiters";
import { RetailSharedVariableService } from "@app/retail/shared/retail.shared.variable.service";
import { CommonVariablesService } from "@app/retail/shared/service/common-variables.service";
import { ConfigKeys, RetailFeatureFlagInformationService } from "@app/retail/shared/service/retail.feature.flag.information.service";
import { SelectedProducts } from "@app/retail/shared/shared.modal";
import { RedirectToModules, RetailUtilities } from "@app/retail/shared/utilities/retail-utilities";
import { PropertyFeaturesConfigurationService } from "@app/retail/sytem-config/payment-features-config/property-feature-config.service";
import _ from "lodash";
import { PartyService } from "../party.service";
import { ShopBussinessService } from "@app/retail/shop/shop-business.service";
import { AppService } from "@app/app.service";



export class ActivitiesRetailOrderSummary {
  private _partyService: PartyService;
  constructor(public utils: RetailUtilities, public _retailService: RetailSharedVariableService, public _ams: RetailService, public _ss: CommonVariablesService, public router: Router, injector: Injector) {
    if (injector) {

      this._partyService = injector.get(PartyService);
    }
    if((this._retailService.memberCardNumber && this._retailService.memberCardNumber != '0') || this._ss.memberCardNumber){
      this._ams.clientSelect = 1;
    }

  }




  ProcessPartyTransaction(transInfo: any) {
    return RetailDataAwaiters.ProcessPartyTransaction(this,'order-summary');
  }

  SaveRetailOrderDetails(guestId, transInfo: any) {
    let partyDetails = null;
    if (this._partyService.currentPartyId) {
      partyDetails = { PartyId: null, ContactId: null };
      partyDetails.ContactId = guestId;
      partyDetails.PartyId = this._partyService.currentPartyId;
    }
    RetailDataAwaiters.SaveRetailOrderDetails(transInfo, true, null, partyDetails);// Activities - retail integration changes
  }

  ResetShopObjActivities() {
    if (this._ss.isCreateOperation) { // Activities - retail integration changes
      this._ss.isCreateOperation = false;
      this._ss.destroy();
      this._ss.selectedProducts = [];
      this._retailService.SelecedSeatProducts = [];
      let defaultredirectPath = this._ss.isOperationFromPos ? '/pos/cart' : '/activities-timeline';
      this.resetCart();
      this.router.navigate([this._partyService.redirectPath || defaultredirectPath]);
    } else {
      this.utils.RedirectTo(RedirectToModules.retail);
      this._ss.ClearServiceObj();
    }
    this._ams.labelRecords = [];
    this._retailService.guestId = null;
    this._ams.paymentProcessing = false;
  }

  HandlePostPaymentRedirectionActivities(redirectRoute) {
    if (this._ss.isCreateOperation) { // Activities - retail integration changes
      this._ss.isCreateOperation = false;
      let defaultredirectPath = this._ss.isOperationFromPos ? '/pos/cart' : '/activities-timeline';
      this.resetCart();
      this.router.navigate([this._partyService.redirectPath || defaultredirectPath]);
    } else {
      this.utils.RedirectTo(redirectRoute);
    }
  }

  validateSeatProducts(GridItems, elem?: any) {
    if ((this._retailService.SelecedSeatProducts.length == GridItems.length) || (
      this._ss.cancelFlag ||
      (elem && elem.isModificationRestricted) ||
      (elem && elem.isGiftCardItem) ||
      this._ss.settleOpenTransaction
    ) || (GridItems.filter((x) => x.ischecked).length == 0)) {
      return true;
    }
    else {
      return false;
    }
  }

  ListCheckedActivities(GridItems) {
    return GridItems.forEach((x) => {
      if (this._retailService.SelecedSeatProducts.filter(product => product.ItemId == x.itemId).length == 0)
        x.ischecked = true;
    });
  }

  ListrowCheckedActivities(d) {
    return _.reject(d, (item) => _.find(this._retailService.SelecedSeatProducts, { ItemId: item.itemId }));
  }

  resetCart() {
    this._partyService.TotalCartBookingObj?.forEach(cartItem => {
      cartItem.selected = false;
    });
  }
}
