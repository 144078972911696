import { MemberPayeeInfo } from './../shared/business/Member-business.service';
import { Component, ContentChild, ElementRef, EventEmitter, Host, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CMSAccountInfo, CMSPlayerInfo, Product, SMReturnCode, Voucher } from 'src/app/common/Models/common.models';
import { RetailLocalization } from '../common/localization/retail-localization';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { ActiveCreditCardInfo, CardInfo, ClientCreditCardInfo, GuaranteeMethod, GuaranteeMethodDetail, IDTechHandle, PayeeInfo, PaymentMethods, ScreenConstants } from '../shared/business/shared.modals';
import { ArAccount, ARPostPaymentResponse, AuthByTokenRequest, AuthByTokenResponse, CardInputMethod, EnableZeroPosting, GuestRoomSearchResult, LookUpInput, PaymentHistory, PaymentMethod, PaymentMethodIconConst, PMSHTTPInterface, PMSXMLDBInterface, ValidateAuthResponse, ValidateCreditLimit } from '../shared/service/payment/payment-business.model';
import { GatewayConfiguration, GiftcardSearchResult, GuestStayDetail, PayHandle, ReversalRequestParams } from '../shared/service/payment/payment-model';
import { RetailFeatureFlagInformationService } from '../shared/service/retail.feature.flag.information.service';
import { ACESPaymentRecord, AlertAction, AlertType, RainCheckIssue } from '../shared/shared.modal';
import * as GlobalConst from '../shared/globalsContant';
import { ShopBussinessService } from '../shop/shop-business.service';
import { CommonVariablesService } from '../shared/service/common-variables.service';
import * as _ from 'lodash';
import { HttpMethod, HttpServiceCall, ServiceParamsAsync } from '../shared/service/http-call.service';
import { RetailValidationService } from '../shared/retail.validation.service';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import * as myGlobals from '../shared/globalsContant';
import { BreakPointAccess } from '../shared/service/breakpoint.service';
import {
	CardPaymentMethods, ExternalGCMethods, GiftcardIDTechMethodMapper, GiftcardIDTechMethods,
	GiftCardMethods, MemberShipMethods, MethodsWhichHasPaymentConfirmationPopup, PaymentOptionType,
	PaymentTransactionState, RainCheckEventType, RetailScreenType, RoomGrpChargeMethod,
	V1GiftCardMethods, SurchargePaymentMethods
} from './PaymentFlowHelpers';
import { PayAgentService } from '../shared/service/payagent.service';
import { RetailService } from '../retail.service';
import { CMSBusinessService } from '../shared/business/CMS-business.service';
import { RedirectToModules, RetailUtilities } from '../shared/utilities/retail-utilities';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { NegativeValueValidator } from '../shared/Validators/NegativeValueValidator';
import { RetailTransactionService } from '../shop/view-categories/retail-transactions/retail.transactions.service';
import { RetailDataAwaiters } from '../shared/events/awaiters/retail.data.awaiters';
import { PaymentBusinessLogicHandler } from './PaymentBusinessLogicHandler';
import { MatDialog } from '@angular/material/dialog';
import { GiftCardBusiness } from '../sytem-config/gift-cards/gift-cards.business';
import { ARPostingBusinessService } from '../shared/business/AR-Posting.business.service';
import { UserMachineConfigurationService } from '../common/services/user-machine-configuration.service';
import { ActionMode, DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { RetailFunctionalityBusiness } from '../shared/business/retail-functionality.business';
import { MemberBusinessService } from '../shared/business/Member-business.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { RetailItemType, UserSessionConfiguration } from '../retail.modals';
import { ArCustomerSearchComponent } from '../component/ar-customer-search/ar-customer-search.component';
import { ButtonType, CardIssuerIconMap, CreditCardIssuerConfig, CreditCardIssuerType, MiscellaneousSwitch } from '../shared/globalsContant';
import { PaymentMethodBusiness } from '../retail-payment-method/payment-methods.business';
import { ComponentAccessTypeLinkConfigProvider } from 'src/app/common/services/component-access-type-linking-config-provider.service';
import { PropertySettingDataService } from '../sytem-config/property-setting.data.service';
import { RetailEventParameters, RetailEventType, VoidEventModel } from '../shared/events/event.model';
import { retailPublisher } from '../shared/events/pubsub/retail.publishers';
import { SurchargeConfigurationService } from '../sytem-config/data-service/surcharge-configuration.data.service';
import { GatewayConfigurationType } from 'src/app/retail/shared/globalsContant';
import { CreditCardConfigurationService } from 'src/app/retail/sytem-config/credit-cards/credit-card.service';
import { CMSRedemptionType } from 'src/app/common/Models/payment.model';
import { FolioBusiness } from '../Folio/folio-business';
import { RetailRoutes } from '../retail-route';
import { AuthorizeTokenBySession } from 'src/app/common/shared/retail.modals';
import { RedemptionBucketMapping, SourceType, SettingModule, SettingScreen, MiscConfigurationSwitches } from '../Folio/Model/folioDetails-model';
import { WalletBusinessService } from '../shared/business/Wallet-business.service';
import { AgFieldConfig } from 'src/app/common/Models/ag-models';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/common/services/facade.service';
import { UserAccessService } from 'src/app/common/services/user-access.service';
import { UserAccessBreakPoints } from '../shared/constants/useraccess.constants';
import { NonPMAgentRequestHandler } from './NonPMAgentRequestHandler';	
@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [FacadeService,UserAccessService]
})
export class PaymentComponent implements OnInit, OnDestroy, OnChanges {

	PayMethodConst: any = PaymentMethods;
	@Input() isReturnWithoutTicket = false;
	OriginalTotalAmount = 0;	
	totalAmount;
	currentAmountTendered:Number = 0;
	floatLabelNever: string;
	taxExemptCalcTriggered: boolean = false;
	@Input('postingCreated') postingCreated : Observable<boolean> = new Observable();
	@Input('totalAmount')
	public set setTotalAmount(value) {
		this.totalAmount = this.OriginalTotalAmount = !isNaN(value) ? value : 0;
		this.patchRemainingAmount();
		this.SelectDefaultPaymentMethod();
		if (this.isSurchargeApplied && this.IsSurchargeApplicableMethod) {
			this.amountBeforeSurcharge = this.totalAmount;
			this.ApplySurcharge(this.totalAmount);
		}
	}

	@Input() isUseNewArCustomerSearch = false;
	@Input() isARAccountAvailable : boolean = false;
	@Input("isPayButtonDisable")
	set SetPayButtonDisable(value){
			this.isPayButtonDisable = value ?? false;
	}
	folioSettings = null;
	@Input("folioSettings")
	set folioSettingsInputsValue(val) {
		if (val) {
			this.folioSettings = val;
		}
	}
	@Input("OutletIdFromResortFolio")
	set changeTerminalFolioTerminalOnOutletChange(value){
		if(value){
		 	this.CEDS_ByTerminal = this.getCEDSValue(value);
		}
	}
	@Input() postId;
	@Input() isPostingDeleted;
	multipleMemberPayment = false;
	selectedMember: MemberPayeeInfo;
	payeeInfo: PayeeInfo;
	original_payeeInfo: PayeeInfo;
	PMSSurchargeEnabled: boolean = false;
	@Input("payeeInfo")
	set PayeeDetails(value) {
		if (this.transactionState != PaymentTransactionState.Closed && (this.payeeInfo?.id != value?.id || !value)) {
			//Switching the context of "this" using apply
			this._sbs.ValidatePaymentMethodActivationBasedOnComponentAccessLink.call(this, value).then(x => {				
				this.SelectDefaultPaymentMethod(value);
				this._memberService.EnableDisableARPostandRedeemRoundPaymentMethod(this);				
				this.DisableRoomGroupMemberCityLedgerForDeposit();				
			});
		}
		this.payeeInfo = value;
	}
	@Input() CreateRetailItemResponse: any = null;
	depositPostTypeCollection: number[] = [];
	userSessionConfiguration: UserSessionConfiguration = null;
	@Input('SelectedTerminalId')
	set terminalValue(value) {
		this._ss.SelectedTerminalId = value;
		this.SelectedTerminalId = value;
	}
	@Input('SelectedOutletId')
	set outletValue(value) {
		this._ss.SelectedOutletId = value;
		this.SelectedOutletId = value;
	}
	@Input() GatewayConfigurationInput: GatewayConfiguration = null;

	ScreenName: RetailScreenType = RetailScreenType.Shop;
	@Input('ScreenName')
	
	set ScreenNameValue(val) {
		if (val) {
			this.ScreenName = val;
		}
	}
	
	sourceTypeId: Number = 0;
	isFromPMS: boolean = false;
	AdditionalInputs: any = {};
	@Input("AdditionalInputs")
	set AdditionalInputsValue(val) {
		if (val) {
			this.AdditionalInputs = val;
			this.setAdditionalInput(val);
		}
		this.CheckResortFinanceCreditCartAuthFlow();
	}
	@Input("memberFolioInput")
	set memberFolioInput(val) {
        this.setAdditionalInput(val);
    }
	@Input() authInput: any = {};
	@Input() GuestProfileRequired = false;
	@Input() GuaranteeMethodData: GuaranteeMethodDetail = { GuaranteeMethod: 0, ARAccountDetail: null, RoomDetail: null, CardDetail: { PaymentReferenceId: 0 } };
	@Input() roomDetailsArray: Array<GuestStayDetail> = [];
	@Input() GridItems: any = [];
	@Input() isTaxExempted: boolean = false;
	@Input('recalculateTax')
	set reloadTicketValues(value) {
		this.isTaxExempted = value;
		if (this.selectedpayment.id > 0) {
			if (value) {
				this._ss.Ticket = _.cloneDeep(this._ss.OriginalTicket);
				this.TicketRecalculated.emit();
				this.taxExemptCalcTriggered = false;
			}
			else {
				this.selectpaymentMethod(this.selectedpayment, true);
			}
		}
	}
	@Input("IsDeposit") IsFolioDepositTransaction = false;
	@Input() showPaymentPrompt = false;
	@Input() showRainCheckButtonIG = false;
	@Input() ResetRainCheckSelection = false;
	@Input() CreateCallValidationError = false;
	@Input() arAccountName: any;
	IsExpireDateVisible: boolean = false;
	isTenderedAmountChanged: boolean = false;
	@Input("IsExpireDateVisible")
	set ExpirationDateInputsValue(val){	
		val = this.productId != Product.PMS ? false: val;
		if(val){
			this._pblh.IsExpireDateVisible = val;
			this.IsExpireDateVisible = val;
		}
	}
	@Input() isTicketCreated: boolean = false;

	resortfolioInput: any;
	@Input('resortfolioInput')
	set setresortfolioInput(value) {
		if (value) 
		{
			this.resortfolioInput = value;
		}
	}

	@Output() BeginTransaction: EventEmitter<any> = new EventEmitter();
	@Output() TransactionCompleted: EventEmitter<any> = new EventEmitter();
	@Output() Cancelled: EventEmitter<any> = new EventEmitter();
	@Output() CloseTransactionClick: EventEmitter<any> = new EventEmitter();
	@Output() PaymentHistoryEmit: EventEmitter<PaymentHistory[]> = new EventEmitter();
	@Output() PaymentMethodSelection: EventEmitter<any> = new EventEmitter();
	@Output() NewArCustomerSearch: EventEmitter<any> = new EventEmitter();
	@Output() NewPaymentReferenceID = new EventEmitter();
	@Output() RainCheckEvents = new EventEmitter();
	@Output() SurchargeUpdated = new EventEmitter();
	@Output() RainCheckSelectionResetedEvent: EventEmitter<Boolean> = new EventEmitter();
	@Output() TicketRecalculated: EventEmitter<any> = new EventEmitter();
	public get IsCMSPayment() {
		return (this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption)
			|| this.SelectedPaymentMethodEquals(PaymentMethods.OfferRedemption)
			|| this.SelectedPaymentMethodEquals(PaymentMethods.CompSlipRedemption));
	}

	public get disableCardCapture() {
		const isZipZodeValid = (this.isCardTransaction && this.GatewayConfiguration?.isAVSActive) ? this.transitionDetails.controls.zipcode.valid : true;
		return ((this.CEDS_ByTerminal == '' || this.PaymentReferenceIDMap.get(this.selectedpayment.id) > 0 ) && !this.IsResortFinanceFolioAuthFlow && !this.IsResortFinanceMakePaymentFlow) || // Dissable Logic For VERSA
				(this.IsResortFinanceFolioAuthFlow && this.PaymentReferenceIDMap.get(this.selectedpayment.id) > 0) || // Dissable For RF Existing Card Available
				(this.ScreenName == RetailScreenType.FolioDefinition && !isZipZodeValid); // Dissable Logic For RF Zip Code Invalid
	}

	public get IsCompOrOfferRedemption() {
		return (this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption) || this.SelectedPaymentMethodEquals(PaymentMethods.OfferRedemption))
	}

	public get IsResortFinanceMakePaymentFlow(){
		return (this._ss.enableResortFinance && this.ScreenName == RetailScreenType.FolioMakePayment);
	}

	public get IsResortFinanceFolioAuthFlow(){
		return (this._ss.enableResortFinance && this.ScreenName == RetailScreenType.FolioDefinition);
	}

	public get IsUserHasFolioAccess() : boolean{
		var folioAccessJWT = sessionStorage.getItem('_folioJwt');
		return folioAccessJWT? true : false;
	}

	public get TaxExemptByPaymentApplied() : number{
		var res = Number((this._ss.OriginalTicket?.checkData?.totalTax - this._ss.Ticket?.checkData?.totalTax).customToFixed());
		return res;
	}
	
	creditLimitInput: AgFieldConfig;
	authorizationAmtInput: AgFieldConfig;
	newcreditLimitInput: AgFieldConfig;
	isResortFinanceCreditCartAuthFlow: boolean = false;
	arSearchPlaceHolder: string;
	selectedText: string;
	screenTypeConst = RetailScreenType;
	ShowSettlemtHist = false;
	SelectedOutletId: number = 0;
	authAmount: number;
	SelectedTerminalId: number = 0;
	multiplePayment: boolean = false;
	transactionState: PaymentTransactionState = PaymentTransactionState.NotStarted;
	ShowTenderField: boolean = false;
	isCardTransaction: boolean = false;
	showAllPaymentMethods = false;
	CardOnFileforSelectedGuest: ClientCreditCardInfo;
	redeemAmount = 0;
	enforceMemberRedeemAmount = 0;
	transitionDetails: UntypedFormGroup = new UntypedFormGroup({});
	RainCheckForm: UntypedFormGroup;
	lblamountTentered: string;
	taxExemptCaption: string;
	dateInput: {
		className: string;
		form: any;
		formControlName: string;
		errorMessage: string;
		minDate?: Date;
		placeHolder: string;
		value: any;
		automationId?: string;
	};
	paymentMethodType = [
		{ id: PaymentOptionType.FullPayment, name: this.localization.captions.shop.fullPayment },
		{ id: PaymentOptionType.MultiPayment, name: this.localization.captions.shop.multiPayment }
	];
	selectedpayment: PaymentMethod = {
		id: 0,
		paymentTypeId: 0,
		paymentMethodId: 0,
		postTypeId: 0,
	};
	isRequireSignatureCapture: boolean = false;
	cashPaymenHandleData: any = null;
	isAPICallInProgress: boolean = false;
	isPayButtonDisable: boolean = false;
	cartFees: any;
	remainingAmount = 0;
	isValidUser = true;
	checkResponse;
	SettlementHistory: Array<PaymentHistory> = [];
	tempSettlementHistory: Array<PaymentHistory> = [];
	paymentMethods: Array<PaymentMethod> = [];
	paymentMethodsClone: Array<PaymentMethod> = [];
	copiedPaymentMethods: Array<PaymentMethod> = [];
	acesPaymentRecord: ACESPaymentRecord[] = null;
	$destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
	NoCardSwipeTimeOut: any;
	NavigationTimeOut: any;
	EnableCloseTranBtn: boolean;
	cardDetailsBool: boolean = false;
	CurrentTryPayResponse: any;
	productId: number;
	ShopCaptions: any = this.localization.captions.shop;
	giftcardCaptions: any = this.localization.captions.shop.GiftCard;
	paymentMethodSource: any = [];
	isPaymentCommentRequired: boolean = false;
	isEnablePaymentComment: boolean = false;
	GatewayConfiguration: GatewayConfiguration;
	ConfirmationPopupShown = false;
	CurrentActiveCard: CardInfo;
	ActiveCreditCards: ActiveCreditCardInfo[] = [];
	SettledAmt: number = 0;
	ContinuePayment = false;
	disabledRadiobool = false;
	AllowCancel = true;
	paymentText = this.localization.captions.shop.Pay;
	oCaptions = this.localization.captions;
	cmsPlayerHandles: any[] = [];
	EncryptedCardData: string = '';
	currentARPostResponse: ARPostPaymentResponse = null;
	profitCenter = '';
	roomChargePostingPayMethodId = 0;
	ManualClosePerformed = false;
	TempRoomNo = "";
	deviceList: Array<any> = [];
	terminalList: Array<any> = [];
	Vouchers: Voucher[] = [];
	authorizedUserId;
	accountLookupComponentInput: LookUpInput;
	searchPlaceholder = this.localization.captions.shop.SearchByRefName;
	rainCheckPayment: boolean;
	IsRainCheckGridData: boolean;
	rainCheckTransaction: RainCheckIssue[] = [];
	rainCheckTransactionClone: RainCheckIssue[] = [];
	rainCheckSelection: number = 0;
	showRainCheckSectionForIG = false;
	correcttransaction: boolean;
	ShowPaymentMethods = true;
	checkData: any;
	PaymentReferenceIDMap = new Map();
	PaymentReferenceID: number = 0;
	authTransactionId: number;
	totalAuthorizedAmount: number;
	CEDS_ByTerminal: string = "";
	paymentComments: string = "";
	searchTextChanged = new Subject<string>();
	moreoptionButton = {
		type: 'split',
		label: this.ShopCaptions.btn_process,
		disabledproperty: false,
		customclass: 'ag_button--secondary ag_display--inblock'
	};
	actionList = [
		{ id: '1', label: this.ShopCaptions.btn_reqCard }
	];
	functionalities: { [key: string]: boolean } = {};
	preferredInputSelectedBanner = "";
	expandPaymentMethodsByDefault: boolean = false;
	isComponentAccessLinkingFilterApplied = false;
	paymentMethodsBeforeCompAccessValidation: PaymentMethod[] = [];
	patchTotalAmountInCashPopup = true;
	memberGuestList: MemberPayeeInfo[] = [];
	COFCardsData = [];
	floatLabel: string;
	isAllowARPayments: boolean = false;
	postTofolio : string;
	folioSection : boolean = false;
	postToFolioInfo: any;
	postfolioData : any;	
	currentPaymentPostfolioData : any;
	addonMember = SourceType.AddOnMember;

	@ViewChild('popOverTarget') popOverTarget;
	@ViewChild('SCpopOverTarget') SCpopOverTarget;
	@ViewChild('rainCheckPopover') rainCheckPopover;
	@ViewChild('surchargePopover') surchargePopover;
	amounttenderedField: ElementRef;
	@ViewChild("amounttenderedField", { static: false }) set content(content: ElementRef) {
		if (content) { this.amounttenderedField = content; }
	}
	paymentCommentField: ElementRef;
	@ViewChild("paymentCommentField", { static: false }) set comment(content: ElementRef) {
		if (content) { this.paymentCommentField = content; }
	}
	cmsSearchField: ElementRef;
	@ViewChild("cmsSearchField", { static: false }) set searchField(content: ElementRef) {
		if (content) { this.cmsSearchField = content; }
	}

	walletInputField: ElementRef;
	@ViewChild("walletInputField", { static: false }) set inputField(content: ElementRef) {
		if (content) { this.walletInputField = content; }
	}

	returnReasonField: ElementRef;
	@ContentChild("returnReason", { static: false }) set reason(content: ElementRef) {
		if (content) { this.returnReasonField = content; }
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this._sbs.SetPaymentSectionScrollHeight();
	}

	//#region Getters

	get IsProceedBtnEnabled() { return this.CheckButtonState(); }

	get showPreferredCardEntryBanner() {
		return (this.GatewayConfiguration
			&& this.GatewayConfiguration?.cardInputMethod == CardInputMethod.All
			&& this.GatewayConfiguration?.preferredCardEntryMode > 0
			&& this.selectedDeviceHandle)
	}

	get IsRefundFlow() {
		return (this.totalAmount < 0);
	}
	get AllowPaymentReversal() {
		return (this.multiplePayment && this.transactionState != PaymentTransactionState.Closed && !this.IsRefundFlow);
	}
	get isGiftCardSelected() {
		return this.selectedpayment && (GiftCardMethods.includes(this.selectedPaymentMethod));
	}
	get isThirdPartyGiftcardSelected() {
		return this.selectedpayment && ExternalGCMethods.includes(this.selectedPaymentMethod);
	}
	get isCustomPaymentMethod() {
		return this.selectedpayment && this.selectedpayment.paymentTypeId > 0 && this.selectedpayment.parentTypeId == 0 && !this.selectedpayment.isCloned && !(this.selectedpayment.paymentTypeId in PaymentMethods);
	}
	get SkipRoomChargePosting() {
		const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));		
		return (this.selectedpayment && RoomGrpChargeMethod.includes(this.selectedPaymentMethod)
			&& (this._featureFlagInfo?.PMSSystem?.toLowerCase().trim() == GlobalConst.VISUALONE
				|| this._featureFlagInfo?.PMSSystem?.toLowerCase().trim() == GlobalConst.VISUAL_ONE
				|| propConfig?.PMSSystem?.toLowerCase().trim() == GlobalConst.V1.toLowerCase().trim()
				|| propConfig?.PMSSystem?.toLowerCase().trim() == GlobalConst.VERSA.toLowerCase().trim()));
	}
	get validateCreditLimitForRoomGroupCharge(){
		const additionalConfig =  this.selectedpayment.additionalConfigurations ? JSON.parse(this.selectedpayment.additionalConfigurations) : null;
		const config = additionalConfig?.find(x => x.Key.toUpperCase().trim()  === ValidateCreditLimit) ;
		const validateCreditLimit = config?.Value ?? '';
		return this.selectedpayment && this.IsRoomOrGroupCharge && additionalConfig ? validateCreditLimit.trim().toLowerCase() == 'true': false;
	}
	get IsOfferOrCompSlipPaymentMethod() {
		return (this.SelectedPaymentMethodEquals(PaymentMethods.OfferRedemption) || this.SelectedPaymentMethodEquals(PaymentMethods.CompSlipRedemption));
	}
	get FullPayment() {
		return (this.transitionDetails && this.transitionDetails.controls.paymentMode.value);
	}
	get IsNonPaymentScreen() { return (this.ScreenName != RetailScreenType.Shop); }
	get IsShopScreen() { return (this.ScreenName == RetailScreenType.Shop); }
	get IsPaymentScreen() {
		return (this.ScreenName == RetailScreenType.FolioPosting || this.ScreenName == RetailScreenType.FolioMakePayment);
	}
	get IsFolioDefinitionScreen() { return (this.ScreenName == RetailScreenType.FolioDefinition); }
	get IsFolioPaymentMethod() { return this.functionalities.IsFolioPaymentMethod || (this.ScreenName == RetailScreenType.FolioDefinition || this.ScreenName == RetailScreenType.FolioMakePayment) }
	get IsCheckOutPaymentProcess() { return this.functionalities.IsCheckOutPaymentProcess; }
	get IsCheckOutScreen() { return (this.ScreenName == RetailScreenType.IndividualCheckOut) }
	get IsMakePaymentScreen() { return (this.ScreenName == RetailScreenType.FolioMakePayment) }
	get DisableIfFolioNotSelected() { return (this.IsMakePaymentScreen && this.AdditionalInputs && !this.AdditionalInputs.folioSelected) }
	get IsShift4GC() { return this._giftcardBusiness.IsShift4Giftcard }
	get IsRefundAsGiftcardFlow() {
		return this.isGiftCardSelected && this._ss.isReturnWithoutTicket && this.IsRefundFlow &&
			(V1GiftCardMethods.includes(this.selectedPaymentMethod) ? !this.selectedDeviceHandle : this.selectedDeviceHandle != null)
	}
	get AmountDueMsg() {
		return this.IsRefundFlow ?
			this.localization.captions.shop.Refund
			: this.localization.captions.shop.Collect
	}

	get AmountPaidMsg() {
		return this.IsRefundFlow ?
			this.localization.captions.shop.GiftCard.AmountRefunded
			: this.localization.captions.shop.GiftCard.AmountPaid
	}

	get selectedPaymentMethod(): PaymentMethods { return this.selectedpayment?.isCloned ? this.selectedpayment?.parentTypeId : this.selectedpayment?.paymentTypeId }

	get IsRoomOrGroupCharge() { return RoomGrpChargeMethod.includes(this.selectedPaymentMethod) }
	get IsHotelCompPayment() { return this.selectedPaymentMethod == PaymentMethods.HotelComp}

	get IsRefundToCOF() { return (this.IsRefundFlow && this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)) }
    
	get IsRefundToPostToFolio() { return (this.IsRefundFlow && this.SelectedPaymentMethodEquals(PaymentMethods.PostToFolio)) }

	get IsMemberRefund() { return (this.IsRefundFlow && MemberShipMethods.includes(this.selectedPaymentMethod)) }

	get IsCMSRequirePin() { return this._featureFlagInfo.CMSRequirePin }

	get CMSPin() {
		return this.IsCMSRequirePin ? this.CMSForm?.controls?.cmsPin?.value : ""
	}

	get IsSurchargeApplicableMethod() {
		return (this._surchargeConfigService?.IsSurchargesEnabled
			&& SurchargePaymentMethods.includes(this.selectedPaymentMethod)
			&& this.cardIssuerTypes.some(x => x.isActive)
			&& !this.IsRefundFlow)
	}

	get ShowSurchargeSelection() {
		return (this._surchargeConfigService?.IsSurchargesEnabled
			&& CardPaymentMethods.includes(this.selectedPaymentMethod)
			&& this.cardIssuerTypes.some(x => x.isActive)
			&& !this.IsRefundFlow)
	}

	get SurchargeEnabled() { return this._surchargeConfigService?.IsSurchargesEnabled || this.PMSSurchargeEnabled }

	get AmountEntered() {
		return this.localization.currencyToSQLFormat(
			this.transitionDetails.controls.amounttendered.value
		)
	}
	get AmtForSurchargeCalc() {
		return this.isSurchargeApplied && this.amountBeforeSurcharge > 0 ? this.amountBeforeSurcharge :
			this.remainingAmountBeforeSurcharge > 0 ? this.remainingAmountBeforeSurcharge :
				this.remainingAmount;
	}
	get PrevSurchargeTotal() {
		let prevSurcharges = 0;
		if (this.SettlementHistory?.length > 0) {
			this.SettlementHistory?.filter(x => !x.isReversed)?.map(x => prevSurcharges += x.surcharge);
		}
		return prevSurcharges;
	}
	get isDepositTransaction() {		
		return this._ss.selectedProducts.some(x => x.ItemType == RetailItemType.Deposit && x.SalesPrice > 0) && (this._ss.isFromDeposit || this._ss.depositFlag)
	}


	//#endregion

	//#region PaymentMethodBasedDataRetainingVariables
	SelectedRainCheck: RainCheckIssue;
	selectedVoucher: Voucher;
	selectedGuestRoom: GuestRoomSearchResult;
	selectedDeviceHandle = '';
	selectedCMSType: any = null;
	selectedGiftCard: GiftcardSearchResult;
	selectedARAccount: ArAccount = { accountName: "", accountNumber: "", isEmittedForPrefill: false };
	SelectedRoomNo: string = '';
	isDefaultRoomLookUpByName: boolean = false;
	selectedCMSPlayer = {
		PlayerName: "",
		PatronId: ""
	}

	cmsPlayerInfo: CMSPlayerInfo;
	CMSForm: UntypedFormGroup;
	defaultCardInputMethod: CardInputMethod;
	Memberpin: string = '';
	requiredApprovalCodeForCC: boolean = false;
	//#endregion	
	hostName: string = '';
	ipAddress: string = '';
	agentVersion: string = '';
	isCheckZoomEnabled: boolean = false;
	noOfReceipts: number;
	hidePassword = false;
	selectRoomFromResult = new EventEmitter<boolean>();
	cardIssuerTypes: CreditCardIssuerConfig[] = [];
	isSurchargeApplied: boolean = false;
	isSurchargedCollected: boolean = false;
	selectedIssuerType: CreditCardIssuerConfig;
	surchargeAmount = 0;
	totalSurcharge = 0;
	remainingAmountBeforeSurcharge = 0;
	issuerTypeChange = false;
	amountBeforeSurcharge = 0;
	folioMenubp: boolean = false;
	isCreditLimitPopUpShow: boolean = false;
	
	get SkipPMAgent() {
		return this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment);
	}
	get PaymentProcessor() {
		return this.SkipPMAgent ? this._nonPMAgentHandler : this.payAgentService;
	}

	constructor(
		public localization: RetailLocalization
		, public commonUtils: QuickLoginUtilities
		, public utils: RetailUtilities
		, public formBuilder: UntypedFormBuilder
		, public dialog: MatDialog
		, public PropertyInfo: RetailPropertyInformation
		, public _featureFlagInfo: RetailFeatureFlagInformationService
		, public _sbs: ShopBussinessService
		, public _ss: CommonVariablesService
		, public http: HttpServiceCall
		, public retailValidationService: RetailValidationService
		, public BPoint: BreakPointAccess
		, public payAgentService: PayAgentService
		, public _ams: RetailService
		, public _cmsBusiness: CMSBusinessService
		, public _giftcardBusiness: GiftCardBusiness
		, public _arPostingBusiness: ARPostingBusinessService
		, public transactionService: RetailTransactionService
		, public userMachineConfigurationService: UserMachineConfigurationService
		, public _pblh: PaymentBusinessLogicHandler
		, private func: RetailFunctionalityBusiness
		, public _memberService: MemberBusinessService
		, private _paymentBusinessService: PaymentMethodBusiness
		, private _componentAccessConfigService: ComponentAccessTypeLinkConfigProvider
		, private _propertySettingDataService: PropertySettingDataService
		, public _surchargeConfigService: SurchargeConfigurationService
		, private creditCardConfigurationService: CreditCardConfigurationService
		, public _folioBusiness: FolioBusiness
		, public _walletBusiness: WalletBusinessService
		, public route: Router
		, private facadeService: FacadeService
		, private userAccessService: UserAccessService		
		, private _nonPMAgentHandler: NonPMAgentRequestHandler
	) {
		this._pblh.$scope = this;
		this.floatLabel = this.localization.setFloatLabel;
		this.InitializeFormGroupsAndVariables();
	}

	ngOnInit(): void {
		this.func.getRetailFunctionality().then(res => {
			this.functionalities = res;
			if (this.IsFolioPaymentMethod) {
				this.GetCreditCardConfiguration();
			}
			this.GetUserSessionConfig().then(x => {
				this.GetPaymentMethods();
			});
		});

		this.searchTextChanged.pipe(
			debounceTime(500),
			distinctUntilChanged())
			.subscribe(async (searchtxt) => this.searchValue(searchtxt));


		this._surchargeConfigService.GetSurchargeSwitchConfig().then(res => {
			this.GetSurchargeConfig();
		});
		this.arSearchPlaceHolder = this.localization.captions.lbl_searchByAccountNumberName;
		this._memberService.memberAPIProgressNotifier.subscribe((isAPICallInProgress) => {
			this.isAPICallInProgress = isAPICallInProgress;
		});
		this._giftcardBusiness.IssuanceProgressCompleted.subscribe((issuanceStateObj) => {
			if (issuanceStateObj) { this.isAPICallInProgress = false; }
		});
		this.CheckBreakPointAccess();

		this._ss.PayButtonDisable?.subscribe((isPayButtonDisable) => {
			this.isPayButtonDisable = isPayButtonDisable;
		});
	}
	CheckBreakPointAccess() {
		this.facadeService.getUserAccess(UserAccessBreakPoints.FOLIOMENU, false).then(o => {
			this.folioMenubp = o.isAllow;
		});
	}

	async GetCreditCardConfiguration() {
		const result = await this.creditCardConfigurationService.getCreditCardConfiguration({ type: GatewayConfigurationType.CreditCard });
		this.GatewayConfiguration = result.find(t => t.type == GatewayConfigurationType.CreditCard);
		this.ValidateGatewayConfiguration();
		if (this.productId == Product.PMS) {
			const creditCardConfiguration = await this.creditCardConfigurationService.getCreditCardConfigurationByScreen();
			this.IsExpireDateVisible = creditCardConfiguration.showCreditCardExpDate ?? this.IsExpireDateVisible;
			this._pblh.IsExpireDateVisible = this.IsExpireDateVisible;
		}
	}

	ngAfterViewInit() {
		this.InitializeFormGroupsAndVariables();
		this.transitionDetails.controls.amounttendered.valueChanges.pipe(
			debounceTime(500),
			distinctUntilChanged(),
			takeUntil(this.$destroyed)).subscribe(val => {
					this.exemptTaxAndUpdateAmountTendered(val);
				if (this.IsSurchargeApplicableMethod && val && !this.issuerTypeChange) {
					this.amountBeforeSurcharge = this.AmountEntered;
					this.ApplySurcharge(this.getAmountPaid());
				}
			});
		if (!this.PropertyInfo.UseRetailInterface && !this.IsShopScreen) {
			this._ss.checkForAccess();
		}
		this.AssignPaymentText();
		//Switching to Multiple Payment
		this.PaymentOption(this.paymentMethodType[1]);
		this._sbs.SetPaymentSectionScrollHeight();
		this.postingCreated.pipe(takeUntil(this.$destroyed)).subscribe(x => {
			if (x) this._pblh.InitiateSaleReqBasedOnPaymentMethod(this.getAmountPaid())
		});
	}

	ngOnDestroy() {
		this.$destroyed.next(true);
		this.$destroyed.complete();
		clearTimeout(this.NoCardSwipeTimeOut);
		clearTimeout(this.NavigationTimeOut);	
	}

	ngOnChanges() {
		this.HandleTransactionUpdates();
		this.HandlePaymentMethodUpdates();
		this.UpdateFormControlValues();
		this.AssignPaymentText();
		this.HandleCheckTotalUpdates();		
		if (this.selectedpayment && this.selectedpayment.requireComments && this.transactionState == 0) {
			this.isEnablePaymentComment = this.isPaymentCommentRequired = true;			
		}
	}

	async exemptTaxAndUpdateAmountTendered(val) {
		let AmountTendered = this.localization.currencyToSQLFormat(val);
		if (AmountTendered > 0 && this.IsShopScreen && !this.isTaxExempted && this.currentAmountTendered != AmountTendered && this.selectedpayment.isAutoRemoveTax) {
			this._ss.PayButtonDisable.emit(true);
			await this.exemptTaxForSelectedPaymentMethod(AmountTendered);
			this.currentAmountTendered = AmountTendered;
			if (AmountTendered <= this._ss.totalAmountWithoutTax) {
				setTimeout(() => {
					this.transitionDetails.controls.amounttendered.setValidators([
						Validators.max(this._ss.remainingAmount),
						Validators.required,
						NegativeValueValidator(this)
					]);
					this._pblh.PatchRemainingAmountAndHighlight(AmountTendered, true);
					setTimeout(() => {
						this._ss.PayButtonDisable.emit(false);
					}, 100);
					this._ams.loaderEnable.next("");
				}, 400);
			}
		}
		else {
			this.taxExemptCalcTriggered = false;
		}
	}


	GetPaymentMethods() {
		let serviceParams: ServiceParamsAsync;
		if (!this.IsFolioPaymentMethod) {
			serviceParams = {
				callDesc: "GetPaymentConfiguration",
				host: GlobalConst.Host.payment,
				method: HttpMethod.Get
			}
		} else {
			this.GetCCTerminal();
			serviceParams = {
				callDesc: "GetPaymentMethodWithReferences",
				host: GlobalConst.Host.folio,
				method: HttpMethod.Get,
				uriParams: { includeInactive: true }
			}
		}
		const paymentMethodResponse = this.http.CallApiAsync<any>(serviceParams);
		paymentMethodResponse.then(async (res) => {
			console.log(res);
			let paymentMethods: any[] = [];
			if (this.IsFolioPaymentMethod) {
				let redemptionBucketMapping;
				const tempMethodList: any[] = res.result;
				const paymentMethodIds = tempMethodList.map(o => o.paymentMethod.id);
				if (this.isFromPMS) {
					const requestParam = {
						callDesc: "GetRedemptionBucketMapping",
						host: GlobalConst.Host.folio,
						method: HttpMethod.Put,
						body: paymentMethodIds
					}
					const response = await this.http.CallApiAsync<RedemptionBucketMapping>(requestParam);
					redemptionBucketMapping = response != null && response.result && response.result != null ? response.result : [];
				}
				tempMethodList.forEach(x => {
					const bucket = redemptionBucketMapping && redemptionBucketMapping != null && redemptionBucketMapping.length ? redemptionBucketMapping.find(o => o.paymentMethodId == x.paymentMethod.id) : null;
					const paymentServiceObj = x.paymentMethodServiceReferences.find(o => o.paymentMethodId == x.paymentMethod.id);
					const payMethod: PaymentMethod = {
						paymentMethod: x.paymentMethod.name,
						paymentMethodId: paymentServiceObj?.paymentMethodId,
						paymentTypeId: paymentServiceObj?.paymentTypeId,
						parentTypeId : 0,
						isDisabled: !x.paymentMethod.isActive,
						isActive: x.paymentMethod.isActive,
						id: x.paymentMethod.id,
						postTypeId: x.paymentMethod.postTypeId,
						isSurcharge: x.paymentMethod.isSurcharge,
						isPercent: x.paymentMethod.isPercent,
						surchargeValue: x.paymentMethod.surchargeValue,
						bucketCode: bucket && bucket != null ? bucket.code : '',
						bucketName: bucket && bucket != null ? bucket.name : '',
						isAutoRemoveTax: x.paymentMethod.isAutoRemoveTax,
					};
					paymentMethods.push(payMethod);
				});
				if (this.IsShopScreen && this.functionalities.AddPendingSettlementPaymentMethodAdditionally) {
					const pendingSettlementPayment: PaymentMethod = {
						id: Math.max(...paymentMethods.map(p => p.id)) + 1,
						isActive: true,
						paymentTypeId: PaymentMethods.PendingSettlement,
						paymentMethod: this.ShopCaptions.paymentMethods[PaymentMethods.PendingSettlement]
					}
					paymentMethods.push(pendingSettlementPayment);
				}
				//Removing Room/Group charge for folio make payment screens - Bug-93628
				if (!this.IsResortFinanceMakePaymentFlow && !this.IsShopScreen) {
					paymentMethods = paymentMethods.filter(x => !RoomGrpChargeMethod.includes(x.paymentTypeId));
				}

				//Show Only Credit Card Payment Type For Resort Finance Auth Flow
				if(this.IsResortFinanceFolioAuthFlow){
					paymentMethods = paymentMethods.filter(x => x.paymentTypeId == PaymentMethods.CreditCard || x.paymentTypeId == PaymentMethods.IDTECH || x.paymentTypeId == PaymentMethods.CardOnFile);
				}
			} else {
				paymentMethods = res.result?.paymentMethods;
				const folioEnabled = sessionStorage.getItem(AuthorizeTokenBySession.FolioUserSession);
				if (!this._ss.enableResortFinance || (!folioEnabled || folioEnabled == '')) {
					paymentMethods = paymentMethods.filter(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) != PaymentMethods.PostToFolio);
				}
				this.GatewayConfiguration = res.result?.gatewayConfiguration.find(t => t.type == GlobalConst.GatewayConfigurationType.CreditCard);
				this.ValidateGatewayConfiguration();
			}

			if (!this.IsFolioDefinitionScreen) {
				const filteredPaymenthMethods = paymentMethods.filter(methods => methods.paymentTypeId !== PaymentMethods.AuthorizePayment);
				paymentMethods = filteredPaymenthMethods;
			}

			this.paymentMethodSource = paymentMethods;
			paymentMethods = this.ScreenName == RetailScreenType.FolioPosting ? paymentMethods.filter(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.CreditCard) : paymentMethods;
			if (this.PropertyInfo.IsResortFinanceEnabled && this.ScreenName == RetailScreenType.FolioMakePayment) {
				paymentMethods = paymentMethods.filter(x => Object.values(GlobalConst.ResortFinancePaymentMethods).includes(x.paymentTypeId));
			}			
			if (this.IsMakePaymentScreen) { this.GetDepositEnabledPostTypes(); }
			this.ValidatePaymentMethods(paymentMethods);
		}).catch((err) => {
			console.log(err);
		})

		if (this._ss.storeMiscSetting)
			this.GetMiscSettingsFromStore();
		else
			this.GetMiscSettings();

	}

	GetMiscSettings() {

		var params: ServiceParamsAsync = { callDesc: "GetMiscConfiguration", method: HttpMethod.Get, host: GlobalConst.Host.retailManagement };
		this.http.CallApiAsync<any>(params).then(
			res => {
				const miscValue = res.result.find(x => x.switch == MiscellaneousSwitch.EXPAND_PAYMENT_METHODS)?.value;
				if (miscValue && miscValue.toLowerCase() === 'true') {
					this.expandPaymentMethodsByDefault = true;
					this.showAllPaymentMethods = true;
				}
				this._sbs.SetCashPopupPrefillFlag.call(this, _.cloneDeep(res?.result));
			}
		);
	}



	GetMiscSettingsFromStore() {
		this._ss.storeMiscSetting.then(res => {
			const miscValue = res.find(x => x.switch == MiscellaneousSwitch.EXPAND_PAYMENT_METHODS);
			if (miscValue && miscValue.value.toString().toLowerCase() === 'true') {
				this.expandPaymentMethodsByDefault = true;
				this.showAllPaymentMethods = true;
			}
			this._sbs.SetCashPopupPrefillFlag.call(this, _.cloneDeep(res));
		});
	}

	async GetUserSessionConfig() {
		this.userSessionConfiguration = await this.userMachineConfigurationService.getUserSessionConfiguration(
			Number(this.localization.GetPropertyInfo('UserId'))
		);
	}

	GetCCTerminal() {
		const ccTerminalRes = this.http.CallApiAsync<any>({
			callDesc: "GetAllCreditCardTerminal",
			host: GlobalConst.Host.retailManagement,
			method: HttpMethod.Get,
			uriParams: { includeInactive: true }
		});
		ccTerminalRes.then((res) => {
			this.terminalList = res.result;
		}).catch((err) => { console.log(err); });
	}

	UpdateFormControlValues() {
		if (Object.getOwnPropertyNames(this.AdditionalInputs).length > 0) {
			if (this.AdditionalInputs.paymentType && this.paymentMethods.find(p => p.id == Number(this.AdditionalInputs.paymentValue))) {
				this.selectedpayment = this.paymentMethods.find(p => p.id == Number(this.AdditionalInputs.paymentValue));
				if (this.AdditionalInputs.paymentType) {
					if (this.AdditionalInputs?.paymentType == Number(PaymentMethods.CardOnFile)) {
						this.selectedpayment = this.paymentMethods.find(p => this.utils.GetOriginalTenderId(p.paymentTypeId, p.parentTypeId) == Number(PaymentMethods.CardOnFile));
					}
					if (this.AdditionalInputs.paymentType == PaymentMethods.IDTECH) {
						this.selectedpayment.paymentTypeId = PaymentMethods.IDTECH
					}
				}
				
				if (this._pblh.IsFolioPosting) {
					if (!this.ConfirmationPopupShown)
						this.selectpaymentMethod(this.selectedpayment, true);
				}
				else {
					this.selectpaymentMethod(this.selectedpayment, true);
				}			
			}

			this.PaymentReferenceIDMap.set(Number(this.AdditionalInputs.paymentValue), this.AdditionalInputs.paymentTransactionRefId);
			this.transitionDetails.controls.thirdparty.setValue(this.AdditionalInputs.isThirdPartyPayment);
			this.transitionDetails.controls.donotdisclose.setValue(!this.AdditionalInputs.discloseTransactionToGuest);
			if (this.AdditionalInputs.selectedTerminal)
				this.transitionDetails.controls.selectedTerminal.setValue(this.AdditionalInputs.selectedTerminal);
			if (this.AdditionalInputs.selectedDevice)
				this.transitionDetails.controls.selectedDevice.setValue(this.AdditionalInputs.selectedDevice);
			this.transitionDetails.controls.creditLimit.setValue(this.localization.localizeCurrency(this.AdditionalInputs.creditLimit, false));
		}
		if (this.CreateCallValidationError && this.isAPICallInProgress) this.isAPICallInProgress = false;
		if (this.SelectedPaymentMethodEquals(PaymentMethods.ARAcctPost) && this.arAccountName) {
			this.selectedARAccount.accountName = this.arAccountName;
			this.selectedARAccount.accountNumber = this.arAccountName;
			this.selectedText = this.arAccountName;
		}
		else if(this.isARAccountAvailable){
			this.selectedARAccount.accountName = "";
			this.selectedARAccount.accountNumber = "";
			this.selectedText = "";
		}

		this.AutoPopulateActivityRoomDetails();
		if (this.IsCheckOutScreen && this.transitionDetails.controls.amounttendered.valid) {
			this.disableAmountTendered()
		}	
	}

	AutoPopulateActivityRoomDetails() {
		// TO Auto select the room retrieved from Activity result
		if (this.roomDetailsArray && this.roomDetailsArray?.length == 1) {
			this.transitionDetails.controls.activityRoomSelection.setValue(this.roomDetailsArray[0].roomNo);
			this.SelectRoomFromActivityResult(this.roomDetailsArray[0]);
		} else {
			this.transitionDetails.controls.activityRoomSelection.setValue("");
			this.SelectedRoomNo = "";
		}
	}

	HandleTransactionUpdates() {
		if (!this.PropertyInfo.UseRetailInterface && ((this.CreateRetailItemResponse != null && this.transactionState == PaymentTransactionState.NotStarted)
			|| (this._ss.transactionUpdated && this.transactionState == PaymentTransactionState.InProgress))) {
			this.transactionState = this.CreateRetailItemResponse ? PaymentTransactionState.InProgress : this.transactionState;
			this._ams.paymentProcessing = true;
			this.isAPICallInProgress = false;
			// this.checkData = JSON.parse(this.CreateRetailItemResponse.transactionData.checkData);
			this._pblh.MakeSale(true);
			this.remainingAmount = this.totalAmount;
			this.currentAmountTendered = this._ss.remainingAmount;
		}
	}

	patchRemainingAmount() {
		if (!this._ss.transactionUpdated && !this.taxExemptCalcTriggered) {
			const tempRemaininAmount = this.remainingAmount;
			const isAllSettlementsReversed = (this.transactionState == PaymentTransactionState.InProgress && this.SettlementHistory.every(x => x.isReversed));
			if (this._ss.settleOpenTransaction && this.SettlementHistory && this.SettlementHistory.length > 0 && !isNaN(this.totalAmount)) {
				const prevVal = this.remainingAmount;
				this.CalculateSettledAmount();
				if (isNaN(prevVal) && !isNaN(this.remainingAmount)) {
					this._pblh.PatchRemainingAmountAndHighlight(this.remainingAmount, true);
				}
			} else {
				this.remainingAmount = (this.transactionState == PaymentTransactionState.NotStarted || isAllSettlementsReversed) && !this.isSurchargeApplied
					? this.totalAmount : this.remainingAmount;
				if ((!isNaN(tempRemaininAmount) && !isNaN(this.remainingAmount) && (tempRemaininAmount != this.remainingAmount))
					|| (!this.transitionDetails.controls.amounttendered.value && !isNaN(this.remainingAmount))) {
					this._pblh.PatchRemainingAmountAndHighlight(this.remainingAmount, true);
				}
			}
			this.currentAmountTendered = this.remainingAmount;
		}
	}

	HandleCheckTotalUpdates() {
		this.patchRemainingAmount();
		this.AdjustPaymentMethods();
	}

	AssignPaymentText() {
		const existingFlow = this.paymentText == this.oCaptions.shop.Proceed ? this.oCaptions.shop.Proceed : this.localization.captions.shop.Pay;
		const tempIsRefundFlow = (this.IsRefundFlow) ? this.localization.captions.shop.ReturnPayment : existingFlow;
		const tempPayment = this.PropertyInfo.UseRetailInterface ? this.localization.captions.shop.SENDFORPAYMENT : tempIsRefundFlow;
		this.paymentText = this.transactionState == PaymentTransactionState.Closed ?
			!this.PropertyInfo.UseRetailInterface && this.CreateRetailItemResponse?.transactionData?.ticketNumber ?
				`${this.ShopCaptions.CloseTransaction} - ${this.ShopCaptions.TicketNumber} ${this.CreateRetailItemResponse?.transactionData?.ticketNumber}`
				: this.ShopCaptions.CloseTransaction
			: tempPayment;
	}

	HandlePaymentMethodUpdates() {		
		this.PaymentReferenceID = this.AdditionalInputs && this.AdditionalInputs.paymentTransactionRefId ? this.AdditionalInputs.paymentTransactionRefId : 0;
		if (this.IsFolioPaymentMethod && this.ScreenName == RetailScreenType.FolioPosting) {
			this.totalAmount = this.AdditionalInputs && this.AdditionalInputs.isCreditPayment ? -this.totalAmount : this.totalAmount;
		}
		this.CheckForCardOnFile(this.payeeInfo);
		this.CheckForGuaranteeMethod();
		this.disableAmountTendered();
		/* COMMENTED OUT SINCE FULL PAYMENT MODE OPTION ITSELF IS REMOVED		 
		if (this.IsCheckOutScreen || this.IsMakePaymentScreen) {
			this.multiplePayment = this.AdditionalInputs && this.AdditionalInputs.singleFolio;
			this.disabledRadiobool = this.AdditionalInputs && !this.AdditionalInputs.singleFolio;
			this.PaymentOption({ id: PaymentOptionType.FullPayment });
			this.transitionDetails.controls.paymentMode.setValue(PaymentOptionType.FullPayment);
		}*/

		if (this.IsMakePaymentScreen) {
			this.isEnablePaymentComment = !(this.selectedpayment.isDefault || this.selectedpayment.isCloned);
			if (this.IsFolioDepositTransaction) {
				//this.paymentMethods = this.paymentMethods.filter(x => this.depositPostTypeCollection.some(d => d == x.postTypeId));
			} else {
				this.paymentMethods = this.paymentMethodsClone;
				this.paymentMethods = this.IsFolioDepositTransaction ?
					this.paymentMethods.filter(x => this.depositPostTypeCollection.some(d => d == x.postTypeId))
					: this.paymentMethodsClone;
				if (this.AdditionalInputs?.isMemberPayment && this.AdditionalInputs?.memberNumber && this._ss.memberCardNumber == "0") {
					const memberNumber = this.AdditionalInputs?.memberNumber;
					this._memberService.getMemberInfo(memberNumber, this._memberService.getScheduleDateInUTC([])
					).then(_memberInfo => {
						this.payeeInfo = _memberInfo;
						this._ss.memberCardNumber = memberNumber;
						this._memberService.setAvalableRounds(_memberInfo);
						this._ss.isMemberNotActive = Boolean(_memberInfo.membershipStatus &&_memberInfo.membershipStatus.toUpperCase() != "ACTIVE");						
						this._memberService.EnableDisableARPostandRedeemRoundPaymentMethod(this);						
						this.DisableRoomGroupMemberCityLedgerForDeposit();
					});
				}
			}
		}

		if (this.AdditionalInputs && this.AdditionalInputs.showAllPayments) {
			this.showAllPaymentMethods = this.AdditionalInputs.showAllPayments;
		}
		if (this.ResetRainCheckSelection) {
			this.SelectedRainCheck = null;
			this.rainCheckSelection = 0;
			this.RainCheckSelectionResetedEvent.emit(true);
		}
		if (this.isTicketCreated && this._ss.memberCardNumber != "0" && !this._ss.isMemberNotActive) {
			const enforceMemberRedeemAmount = this._memberService.CalculateRedeemAmountForEnforceMember(this.GridItems, this.isTaxExempted, this.productId);
			this.enforceMemberRedeemAmount = enforceMemberRedeemAmount;
		}
	}

	ValidateGatewayConfiguration() {
		if (!this.GatewayConfiguration) return;
		const preferredInputMap = {
			[CardInputMethod.KeyIn]: this.localization.captions.setting.keyIn,
			[CardInputMethod.SwipeInsertTap]: this.localization.captions.setting.swipeInsertTap
		}
		if (preferredInputMap[this.GatewayConfiguration?.preferredCardEntryMode]) {
			this.preferredInputSelectedBanner = this.localization.replacePlaceholders(
				this.localization?.captions?.shop?.preferredInputModeSelectedBanner??'', ['preferredMethod'],
				[preferredInputMap[this.GatewayConfiguration?.preferredCardEntryMode]]
			);
		}

		if (this.GatewayConfiguration && this.GatewayConfiguration?.isAVSActive) {
			this.transitionDetails.controls.zipcode.setValidators(Validators.required);
		}
	}

	DisableRoomGroupMemberCityLedgerForDeposit() {	
		if (this.isDepositTransaction && this.paymentMethods?.length > 0) {
			const methodsToBeDisabledInDeposit = [PaymentMethods.RoomCharge, PaymentMethods.GroupCharge, PaymentMethods.ARPost, PaymentMethods.ARAcctPost, PaymentMethods.HotelComp];
			this.paymentMethods.map((p) => {
				if (methodsToBeDisabledInDeposit.includes(p.paymentTypeId) || methodsToBeDisabledInDeposit.includes(p.parentTypeId)) {
					p.isDisabled = true;
				}
			});
			const isCashResetRequired = methodsToBeDisabledInDeposit.some(m => m == this.selectedpayment?.paymentTypeId);
			if (this.transactionState == PaymentTransactionState.NotStarted && isCashResetRequired) { //Set Payment method only if transaction is not started
				const cashMethodAvailable = this.paymentMethods.some(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.Cash);
				const cashMethod = this.paymentMethods.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.Cash);
				//Set default payment method
				if (this._ss.hasAccessSettleCash && cashMethodAvailable && !this.AdditionalInputs.paymentType) {
					this.selectedpayment = {
						id: cashMethod.id,
						paymentTypeId: cashMethod.paymentTypeId,
						parentTypeId: cashMethod.parentTypeId,
						paymentMethod: cashMethod.paymentMethod,
						allowEarn: cashMethod.allowEarn,
						noOfReceipts: cashMethod.noOfReceipts,
						requireReceipts: cashMethod.requireReceipts
					};
				}
				else {
					this.ResetSelectedPayment();
				}
			}
		}
	}

	GetDepositEnabledPostTypes() {
		RetailDataAwaiters.getAllPostTypes(false, false).then(postTypes => {
			console.log(postTypes);
			if (postTypes && postTypes.length > 0) {
				this.depositPostTypeCollection = postTypes.filter(x => x.isDeposit)?.map(p => p.id);
			}
		});
	}

	InitializeFormGroupsAndVariables() {
		this.transitionDetails = this.formBuilder.group({
			amounttendered: [
				{ value: '', disabled: true },
				[Validators.max(this.totalAmount), Validators.required]
			],
			paymentMode: PaymentOptionType.MultiPayment,
			returnReason: ['', [Validators.required]],
			returnCashCreditLimit: [''],
			numberOfReceipts: [''],
			cardtype: [{ value: '', disabled: true }],
			cardnumber: [{ value: '', disabled: true }],
			transactionnumber: [{ value: '', disabled: true }],
			paymentComment: [''],
			amountpaid: [this.totalAmount],
			roomnumber: ['', [Validators.required]],
			zipcode: ['', this.GatewayConfiguration?.isAVSActive ? Validators.required : null],
			selectedDevice: ['', [Validators.required]],
			selectedTerminal: [''],
			selectedGuest: [],
			thirdparty: [''],
			donotdisclose: [''],
			creditLimit: [''],
			authorizationAmt: [''],
			newcreditLimit: [''],
			activityRoomSelection: [''],
			creditcardType: 0,
			walletInput:['']
		});
		this.CMSForm = this.formBuilder.group({
			cmsSearch: '',
			cmsPaymentSelection: '',
			voucherSelection: '',
			cmsPin: ['', [Validators.minLength(4), Validators.maxLength(10)]]
		});
		this.selectedpayment = {
			id: 0,
			paymentTypeId: 0,
			parentTypeId: 0,
			paymentMethodId: 0,
			postTypeId: 0
		};
		if (this.IsNonPaymentScreen)
			this.transitionDetails.controls.selectedTerminal.setValidators(Validators.required);
		this.RainCheckConfiguration();
		this.lblamountTentered = this.localization.captions.shop.AmountTendered;
		this.authAmount = this.transitionDetails.controls.authorizationAmt ? this.localization.currencyToSQLFormat(this.transitionDetails.controls.authorizationAmt.value) : 0;
		this.AdditionalInputs.authAmount = this.authAmount;
		this.productId = this._ss.ProductId;

		this.creditLimitInput = {
			className: 'ag_form-control--lg ag_p--0 ag_display--inblock ag_section--disable',
			form: this.transitionDetails,
			formControlName: 'creditLimit',
			placeHolderId: 'lbl_creditlimit',
			placeHolder: this.ShopCaptions.lbl_creditlimit,
			automationId: 'Currency_CreditLimit'
		};
		this.authorizationAmtInput = {
			className: 'ag_form-control--lg ag_p--0 ag_display--inblock',
			form: this.transitionDetails,
			formControlName: 'authorizationAmt',
			placeHolderId: 'lbl_authorizationamt',
			placeHolder: this.ShopCaptions.lbl_authorizationamt,
			automationId: 'Currency_AuthorizationAmt'
		};
		this.newcreditLimitInput = {
			className: 'ag_form-control--lg ag_p--0 ag_display--inblock ag_section--disable',
			form: this.transitionDetails,
			formControlName: 'newcreditLimit',
			placeHolderId: 'lbl_newcreditLimit',
			placeHolder: this.ShopCaptions.lbl_newcreditLimit,
			automationId: 'Currency_NewcreditLimit'
		};
	}

	checkboxclick(eve) {
		console.log(eve);
	}

	FormatPrice(price: number) {
		if (price < 0) {
			return `(${this.localization.localizeCurrency(price * -1)})`;
		} else {
			return this.localization.localizeCurrency(price);
		}
	}

	RainCheckConfiguration() {
		this.RainCheckForm = this.formBuilder.group({
			issuedate: this.PropertyInfo.CurrentDTTM,
			searchText: '',
			rainCheckSelection: ''
		});
		this.dateInput = {
			className: 'golf-form-control--lg date-picker-width',
			form: this.RainCheckForm,
			formControlName: 'issuedate',
			errorMessage: 'Missing Date',
			placeHolder: 'Issue Date',
			value: this.PropertyInfo.CurrentDTTM,
			automationId : 'payment'
		};
	}

	async ValidatePaymentMethods(paymentMethods) {
		this.paymentMethods = this._sbs.FilterIdTechAndInactiveMethods(paymentMethods);
		if (!this._featureFlagInfo.SupportCompSlipRedemption) {
			this.paymentMethods = this.paymentMethods.filter(method => this.utils.GetOriginalTenderId(method.paymentTypeId, method.parentTypeId) != PaymentMethods.CompSlipRedemption)
		}
		const cashMethodAvailable = this.paymentMethods.some(x => x.paymentTypeId == PaymentMethods.Cash && x.isActive);
		const cashMethod = cashMethodAvailable ? this.paymentMethods.find(x => x.paymentTypeId == PaymentMethods.Cash && x.isActive) :
			this.paymentMethods.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.Cash && x.isActive);
		//Set default payment method
		if (this._ss.hasAccessSettleCash && cashMethod && !this.AdditionalInputs.paymentType) {
			this.selectedpayment = {
				id: cashMethod.id,
				paymentTypeId: cashMethod.paymentTypeId,
				parentTypeId: cashMethod.parentTypeId,
				paymentMethod: cashMethod.paymentMethod,
				allowEarn: cashMethod.allowEarn,
				noOfReceipts: cashMethod.noOfReceipts,
				requireReceipts: cashMethod.requireReceipts,
				isCloned: cashMethod.isCloned,
				isAutoRemoveTax: cashMethod.isAutoRemoveTax
			};
		}
		
		this.paymentMethods.forEach((method) => {
			if (PaymentMethodIconConst[method.paymentTypeId]) {
				method.type = PaymentMethodIconConst[method.paymentTypeId];
			}
			else if (PaymentMethodIconConst[method.parentTypeId]) {
				method.type = PaymentMethodIconConst[method.parentTypeId];
			} else {
				const otherPaymentMethod = 44; //WE DON'T HAVE ANY PAYMENT METHOD CALLED 'OTHER' SO JUST ADDING A const AS 44
				method.type = PaymentMethodIconConst[otherPaymentMethod];
			}
			let paymentMethodName = myGlobals.PaymentMethodValue[method.paymentTypeId];
			if (!this.IsFolioPaymentMethod && method.paymentMethod == paymentMethodName) {
				method.paymentMethod = this.ShopCaptions.paymentMethods[method.paymentTypeId];
			} else {
				method.paymentMethod = method.paymentMethod;
			}
			const additionalConfig = method.additionalConfigurations ? JSON.parse(method.additionalConfigurations) : null;			
			const EnableZeroPostingConfigValues = additionalConfig?.find(x => x.Key.trim().toUpperCase() === EnableZeroPosting);
			method.allowZeroPosting = additionalConfig ? EnableZeroPostingConfigValues?.Value.trim().toLowerCase() == 'true' || method.paymentTypeId == PaymentMethods.Cash  : false;
		});
		this.paymentMethodsBeforeCompAccessValidation = _.cloneDeep(this.paymentMethods);
		this.copiedPaymentMethods = _.cloneDeep(this.paymentMethods);
		await this._sbs.ValidatePaymentMethodActivationBasedOnComponentAccessLink.call(this, this.payeeInfo); //Switching the context of "this"
		//const cashMethodAvailable = this.paymentMethods.some(x => x.paymentTypeId == PaymentMethods.Cash);
		//const cashMethod = this.paymentMethods.find(x => x.paymentTypeId == PaymentMethods.Cash);
		//Set default payment method
		if (this._ss.hasAccessSettleCash && cashMethod && !this.AdditionalInputs.paymentType) {
			this.selectedpayment = {
				id: cashMethod?.id,
				paymentTypeId: cashMethod?.paymentTypeId,
				parentTypeId: cashMethod?.parentTypeId,
				paymentMethod: cashMethod?.paymentMethod,
				allowEarn: cashMethod?.allowEarn,
				noOfReceipts: cashMethod?.noOfReceipts,
				requireReceipts: cashMethod?.requireReceipts,
				isCloned: cashMethod?.isCloned,
				isAutoRemoveTax: cashMethod.isAutoRemoveTax
			};
			this.EnableCloseTranBtn = true;
		}		
		this.CheckForCardOnFile(this.payeeInfo);
		this.CheckForGuaranteeMethod();
		this.UpdateFormControlValues();
		this.paymentMethods = this._sbs.FilterPaymentBasedOnProduct(this.paymentMethods);
		// commented this due to HardCode Giftcard Name from API
		//this._sbs.ReplaceGiftcardVendorName(this.paymentMethods);
		this.paymentMethodsClone = _.cloneDeep(this.paymentMethods);		
		console.log("Before Membership logic, payment Methods", this.paymentMethods);
		this._memberService.EnableDisableARPostandRedeemRoundPaymentMethod(this);		
		this.paymentMethods.forEach(x => x.isDisabled = false);
		this.AdjustPaymentMethods();
		console.log("After Membership logic, payment Methods", this.paymentMethods);
		if (this.paymentMethods[0] && (this.utils.GetOriginalTenderId(this.paymentMethods[0]?.paymentTypeId, this.paymentMethods[0]?.parentTypeId) == PaymentMethods.CreditCard || this.utils.GetOriginalTenderId(this.paymentMethods[0]?.paymentTypeId, this.paymentMethods[0]?.parentTypeId) == PaymentMethods.CardOnFile)) {
			this.isCardTransaction = true;
			this.checkZipCodeValidity();
		}		
		this.SelectDefaultPaymentMethod();
		await this._pblh.CheckForAnyPrevSettlements();
		if( !this.IsRefundFlow && this.IsShopScreen){
			if (this.SettlementHistory.length > 0 && this._ss.settleOpenTransaction) {
				let total = 0;
				this.SettlementHistory.forEach(x => {
					let paymentMethod = this.paymentMethods.find(y => y.paymentMethod == x.paymentMethod);
					if (paymentMethod.isAutoRemoveTax) {
						total += Number(x.amount.customToFixed());
					}
				});
				if (total > 0 && await this._ss.exemptTaxForComp(total)) {
					this._ss.TempTicket = _.cloneDeep(this._ss.Ticket);
					await this.TicketRecalculated.emit();
					this._pblh.PatchRemainingAmountAndHighlight(this._ss.remainingAmount, true);
					this._ss.isTaxRecalculated = true;
					this._ss.remainingAmountAfterPayment = this.currentAmountTendered = this._ss.remainingAmount;
					this._ss.totalTaxExemptRatio = Number((total/this._ss.totalAmountWithoutTax).toFixed(2))
				}
			}
			else {
				this.exemptTaxForSelectedPaymentMethod(this.localization.currencyToSQLFormat(this.transitionDetails.controls.amounttendered.value)).then(x => {
					this._pblh.PatchRemainingAmountAndHighlight(this._ss.remainingAmount, true);
					this.currentAmountTendered = this._ss.remainingAmount;
				});
			}
		}
	}

	SelectDefaultPaymentMethod(payeeInfo?: PayeeInfo) {
		payeeInfo = payeeInfo ? payeeInfo : this.payeeInfo;		
		if (this.totalAmount == 0 && this._ss.memberCardNumber == "0") {
			this.SelectOnlyCashPaymentMethod();
		}
		else{
			this._sbs.SelectDefaultPaymentMethod(this, payeeInfo);
		}
	}

	async SelectOnlyCashPaymentMethod(){		
			const cashMethod = this.paymentMethods.find(x => x.paymentTypeId == PaymentMethods.Cash && x.isActive);
			if (this._ss.hasAccessSettleCash && cashMethod && !this.AdditionalInputs.paymentType) {
				this.selectedpayment = {
					id: cashMethod.id,
					paymentTypeId: cashMethod.paymentTypeId,
					parentTypeId: cashMethod.parentTypeId,
					paymentMethod: cashMethod.paymentMethod,
					allowEarn: cashMethod.allowEarn,
					noOfReceipts: cashMethod.noOfReceipts,
					requireReceipts: cashMethod.requireReceipts,
					isCloned: cashMethod.isCloned,
					isAutoRemoveTax: cashMethod.isAutoRemoveTax
				};
				this.selectpaymentMethod(this.selectedpayment);
			}
	}

	async exemptTaxForSelectedPaymentMethod(amountTendered:Number) {
		this.taxExemptCalcTriggered = false;
		if (this.isTaxExempted || !this._ss.OriginalTicket) {
			return;
		}
		if(this.IsShopScreen)
		{
			if (this.selectedpayment.isAutoRemoveTax && !this.PropertyInfo.IsVATEnabled && !this.IsRefundFlow) {
				if (await this._ss.exemptTaxForComp(amountTendered)) {
					this.taxExemptCalcTriggered = true;
					this.TicketRecalculated.emit();
				}
			}
			else if(!this._ss.isTaxRecalculated){
				this._ss.Ticket = _.cloneDeep(this._ss.OriginalTicket);
				this.TicketRecalculated.emit();
			}
			else if(!this.selectedpayment.isAutoRemoveTax  && !this.PropertyInfo.IsVATEnabled && !this.IsRefundFlow && this._ss.isTaxRecalculated)
			{
				this._ss.Ticket = _.cloneDeep(this._ss.TempTicket);
				this.TicketRecalculated.emit();
			}
		}
	}

	setTaxValues(raincheck: RainCheckIssue, event) {
		event.stopPropagation();
		if (raincheck) {
			this.cartFees = [
				{
					name: 'Greens Fee',
					price: this.localization.localizeCurrency(raincheck.greenFee)
				},
				{
					name: 'Cart Fee',
					price: this.localization.localizeCurrency(raincheck.cartFee)
				},
				{
					name: 'Tax on Greens Fee',
					price: this.localization.localizeCurrency(raincheck.greenFeeTax)
				},
				{
					name: 'Tax on Cart Fee',
					price: this.localization.localizeCurrency(raincheck.cartFeeTax)
				}
			];
		}
	}

	/**
   *
   * @param itemDetails;
   * @function selectDevice
   * @param <obj>
   * @description Select the device type Device Details
   */
	selectDevice(e: any) {
		this.selectedDeviceHandle = e.handle;
		console.log(this.transitionDetails);
		if (e.name == IDTechHandle.name) {
			const clonedIdTech = this.paymentMethods.find(p => p.parentMethodId == this.selectedpayment.id && p.parentTypeId == PaymentMethods.IDTECH);
			this.selectedpayment.paymentTypeId = this.selectedpayment.isCloned && clonedIdTech ? clonedIdTech.paymentTypeId : PaymentMethods.IDTECH;
			this.selectedpayment.paymentTypeEqualant = PaymentMethods.CreditCard;
			this.selectedpayment.parentTypeId = PaymentMethods.IDTECH;
			const amtTenderField = this.transitionDetails.controls.amounttendered;
			this.EnableCloseTranBtn = (amtTenderField.valid && amtTenderField.value);
		} else {
			this.selectedpayment.paymentTypeId = this.paymentMethods?.find(p => p.id == this.selectedpayment.id)?.paymentTypeId;
			this.selectedpayment.parentTypeId = this.paymentMethods?.find(p => p.id == this.selectedpayment.id)?.parentTypeId;
		}
	}

	setTenderedAmount(val)
	{
		this.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(val, false));
		this.currentAmountTendered = val;
	}


	/**
	* @method Function
	* @function selectpaymentMethod
	* @param e <obj>
	* @type any
	* @description select payment type of shoping
	* @return none
	* @change active the select class compare to id
	*
	*/
	async selectpaymentMethod(e: PaymentMethod, isOnLoad = false) {				
		this.isValidUser = true;
		this.isPaymentCommentRequired = e?.requireComments;
		this.paymentComments = "";
		this.transitionDetails.controls.paymentComment.setValue("");
		this.transitionDetails.controls.paymentComment.updateValueAndValidity();
		this.isEnablePaymentComment = !(e.isDefault || e.isCloned);
		this.isCardTransaction = false;
		const eventPaymentType = e.isCloned ? e.parentTypeId : e.paymentTypeId;
		this.taxExemptCaption = this.ShopCaptions.lbl_TaxExemptByPaymentMethod
		switch (eventPaymentType) {
			case PaymentMethods.CreditCard:
				if (this.IsRefundFlow && this._sbs.CheckIfCreditToGuestIsAllowed(this.GatewayConfiguration)) return;
				this.isCardTransaction = true;
				this.checkZipCodeValidity();
				if (!this._ss.hasAccessSettleCC) {
					this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToCreditCard);
					return;
				}
				this.setTerminalDefault();
				break;
			case PaymentMethods.RoomCharge:
				if (!this._ss.hasAccessSettleRC) {
					this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToRoomCharge);
					return;
				}
				break;
			case PaymentMethods.GroupCharge:
				if (!this._ss.hasAccessSettleGC) {
					this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SETTLETOGROUPCHARGE);
					return;
				}
				break;
			case PaymentMethods.HotelComp:
					if (!this._ss.hasAccessSettleHC) {
						this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SETTLETOGHOTELCOMP);
						return;
					}
			break;
			case PaymentMethods.Cash:
				if (!this._ss.hasAccessSettleCash) {
					this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToCash);
					return;
				}
				break;
			case PaymentMethods.CardOnFile:
				this.isCardTransaction = true;
				if (this.IsRefundFlow && this._sbs.CheckIfCreditToGuestIsAllowed(this.GatewayConfiguration)) return;
				this.checkZipCodeValidity();
				if (!this._ss.hasAccessSettleCC) {
					this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToCreditCard);
					return;
				}
				break;
			case PaymentMethods.RainCheck:
				if (!this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.RainCheckRedeem], false)) {
					this._ss.showBPMessage(GlobalConst.RetailBreakPoint.RainCheckRedeem);
					return;
				}
				break;
			case PaymentMethods.IDTECH:
				this.isCardTransaction = true;
				break;
			case PaymentMethods.PostToFolio:
				if (!this._ss.hasAccessSettlePostToFolio) {
					this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToPostToFolio);
					return;
				}
				break;
			case PaymentMethods.CompRedemption: //TO-DO
				//Break point code goes here
				break;
			case PaymentMethods.OfferRedemption: //TO-DO
				//Break point code goes here
				break;
			case PaymentMethods.V1GiftCard:
			case PaymentMethods.V1GiftCardIdTech:
			case PaymentMethods.ExternalGiftCard:
			case PaymentMethods.ExternalGiftCardIdTech: //TO-DO
			case PaymentMethods.AgilysysGiftCard:
			case PaymentMethods.AgilysysGiftCardIdTech:
				this.selectedGiftCard = null;
				break;
			case PaymentMethods.ARAcctPost:
				this.isAllowARPayments = this._ss.hasAccessSettleAR;	
				
				if (!this.profitCenter) {
					await this._ss.SetAllOutletsData();
					this.profitCenter = await this._ss.GetCurrentProfitCenter(Number(this._ss.SelectedOutletId));
				}

				if (!this._ss.hasAccessSettleAR) {
					this._ss.showBPMessage(GlobalConst.RetailBreakPoint.ALLOWARPAYMENTS)
					return;
				}
				break;		
			case PaymentMethods.CreditBook:
				this._ss.restrictNegativeRedeemption = this.isFromPMS && (this.IsMakePaymentScreen || this.IsCheckOutScreen);		
				break;
		}
		if (e.paymentTypeId > 0 && !e.isCloned && !(e.paymentTypeId in PaymentMethods) && !this._ss.hasAccessSettleOthers) {
			this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToOthers);
			return;
		}
		this.selectedpayment = _.cloneDeep(e);
		const ResetAndDisableAmountTenderedField = () => {
			this.transitionDetails.controls.amounttendered.setValue('');
			this.transitionDetails.controls.amounttendered.disable();
		}
		this.transitionDetails.controls.amounttendered.enable();
		this.transitionDetails.controls.selectedDevice.patchValue('');
		this.transitionDetails.controls.zipcode.patchValue('');
		this.CMSForm.controls.cmsPin.setValue('');
		this.EnableCloseTranBtn = false;
		this.cardDetailsBool = false;
		this.CurrentTryPayResponse = null;
		this.selectedDeviceHandle = this.selectedDeviceHandle ? this.selectedDeviceHandle : '';
		this.EncryptedCardData = '';
		this.SelectedRainCheck = null;
		this.folioSection = false;
		this.postfolioData = null;
		this.postToFolioInfo = null;
		this.rainCheckSelection = 0;
		this.cmsPlayerHandles = [];
		this.Vouchers = [];
		this.selectedCMSPlayer = { PlayerName: '', PatronId: '' };
		this.selectedVoucher = null;
		this.cmsPlayerInfo = null;
		this.selectedGiftCard = this.selectedGiftCard ? this.selectedGiftCard : null;
		this.currentARPostResponse = null;
		this.selectedGuestRoom = null;
		this.SelectedRoomNo = this.roomDetailsArray && this.roomDetailsArray?.length == 1 ? this.roomDetailsArray[0].roomNo : "";
		this.Memberpin = '';
		this.prefillAccountLookupComponent();
		this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
		this.PaymentReferenceID = this.PaymentReferenceIDMap.has(this.selectedpayment.id) ?
			this.PaymentReferenceIDMap.get(this.selectedpayment.id) : this.PaymentReferenceID;
		this.PaymentMethodSelection.emit(
			[this.selectedpayment, {
				thirdparty: this.transitionDetails.controls.thirdparty.value,
				donotdisclose: this.transitionDetails.controls.donotdisclose.value
			}]);
			console.log(this.paymentMethods);
		if (this.IsShopScreen) {
			if (this.selectedpayment.id != this._ss.selectedTaxExemptPayment.id || !this.isTaxExempted) {
				this.exemptTaxForSelectedPaymentMethod(this._ss.remainingAmountAfterPayment).then(x => {
					this.setTenderedAmount(this._ss.remainingAmount);
					setTimeout(() => {
						if (this.amounttenderedField?.nativeElement) {
							this.tenderedAmount(false);
							this.amounttenderedField?.nativeElement?.select();
							this.amounttenderedField?.nativeElement?.focus();
						}
					}, 100);
					this.currentAmountTendered = this._ss.remainingAmount;
				});
			}
			this._ss.selectedTaxExemptPayment = _.cloneDeep(this.selectedpayment)
		}
		else {
			this._pblh.PatchRemainingAmountAndHighlight(this.remainingAmount, true);
		}
		if (!isOnLoad) this.ResetSurchargeFlags();
		this.PMSSurchargeEnabled = false;
		switch (this.selectedPaymentMethod) {
			case PaymentMethods.Cash:
				this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
				this.EnableCloseTranBtn = true;
				break;
			case PaymentMethods.PendingSettlement:
				this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
				this.transitionDetails.controls.amounttendered.disable();
				this.EnableCloseTranBtn = true;
				break;
			case PaymentMethods.CreditCard:
				this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.ProceedRefund : this.oCaptions.shop.Proceed;
				//this.transitionDetails.controls.amounttendered.updateValueAndValidity();
				//this.transitionDetails.controls.selectedDevice.updateValueAndValidity();
				this._pblh.GetHandles(isOnLoad);
				break;

			case PaymentMethods.IDTECH:
				this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.ProceedRefund : this.oCaptions.shop.Proceed;
				this._pblh.GetHandles(isOnLoad);
				break;
			case PaymentMethods.RoomCharge:
			case PaymentMethods.GroupCharge:
			case PaymentMethods.HotelComp:
				if (!this.profitCenter) {
					await this._ss.SetAllOutletsData();
					let config = await this._ss.GetRoomChargeConfigByOutlet(Number(this._ss.SelectedOutletId));
					this.profitCenter = config.profitCenter;
					this.roomChargePostingPayMethodId = config.paymentMethodId;
				}
				
				if (this.selectedpayment.id == PaymentMethods.GuaranteeMethod) {
					this.PrefillValuesBasedOnGuaranteeMethodValue();
				}

				//Pre Fills And Auto Search Room By Name For Resort Finance
				if(this.IsResortFinanceMakePaymentFlow){					
				   this.PrefillValuesBasedOnResortFinanceInput();			
				}
				
				this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
				this.selectRoomFromResult.emit(true);
				break;
			case PaymentMethods.CardOnFile:
				this.ConfirmationPopupShown = false;
				let COF_PaymentRef = 0;
				if (this.selectedpayment.id == PaymentMethods.GuaranteeMethod) {
					this.PrefillValuesBasedOnGuaranteeMethodValue();
					COF_PaymentRef = this.GuaranteeMethodData?.CardDetail?.PaymentReferenceId;
				}
				else if (this.AdditionalInputs?.paymentTransactionRefId) {
					COF_PaymentRef = this.AdditionalInputs.paymentTransactionRefId;
				}
				else {
					COF_PaymentRef = this.payeeInfo?.cardInfo?.find((x) => x.isActive)?.tokenTransId;
				}
				this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
				if (COF_PaymentRef) {

					if(this.IsResortFinanceFolioAuthFlow || this.IsResortFinanceMakePaymentFlow){
						const cardInfo = await this.payAgentService.FolioGetCardInfo(COF_PaymentRef);							
						this.ActiveCreditCards = [];				
						this.ActiveCreditCards.push({
							paymentTransactionId: COF_PaymentRef, 
							cardInfo: cardInfo
						});
					}
					else{						

						this.CurrentActiveCard = isOnLoad || !this.CurrentActiveCard  ? 
												 await this.payAgentService.GetCardInfo(COF_PaymentRef) : 
												 this.CurrentActiveCard;		
					}
					this.CurrentActiveCard.cardNumber = this.payAgentService.MaskCreditCardNumber(
						this.CurrentActiveCard?.cardNumber??""
					);
					//Auto Select card type config based on issuerType
					this.selectedIssuerType = this.cardIssuerTypes.find(c => c.type == this.CurrentActiveCard?.issuerType);
					
					this.ApplySurcharge(this.AmtForSurchargeCalc);
				}
				break;
			case PaymentMethods.RainCheck:
				this.RainCheckForm.controls['searchText'].setValue('');
				this.rainCheckTransaction = [];
				this.IsRainCheckGridData = true;
				this.rainCheckPayment = true;
				this.searchPlaceholder = this.localization.captions.shop.SearchByRefName;
				ResetAndDisableAmountTenderedField();
				break;
			case PaymentMethods.RedeemPoint:
				if (this.multiplePayment) {
					this.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(this.redeemAmount));
					this.transitionDetails.controls.amounttendered.disable();
					this.EnableCloseTranBtn = true;
				}
				break;
			case PaymentMethods.ARPost:
				if (this.multiplePayment
					&& this.SelectedPaymentMethodEquals(PaymentMethods.ARPost)
					&& this._ss.memberCardNumber != "0"
					&& this._ss.SelectedPlayers
					&& this._ss.SelectedPlayers.length
					&& this.enforceMemberRedeemAmount > 0
					&& this.remainingAmount >= this.enforceMemberRedeemAmount
					&& !this.SettlementHistory.some((x) => x.paymentMethodId == PaymentMethods.ARPost && !x.isReversed)
				) {

					if (!this.profitCenter) {
						await this._ss.SetAllOutletsData();
						this.profitCenter = await this._ss.GetCurrentProfitCenter(Number(this._ss.SelectedOutletId));
					}
					// Activities - retail integration changes
					this.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(this.enforceMemberRedeemAmount, false));
					if (!this._memberService.AllowZeroTenderForMember)
						this.transitionDetails.controls.amounttendered.disable();
					this.EnableCloseTranBtn = true;
				}
				break;
			case PaymentMethods.CompRedemption:
			case PaymentMethods.OfferRedemption:
				this.searchPlaceholder = this.localization.captions.shop.CMS.searchPlaceHolder;
				if (this.SelectedPaymentMethodEquals(PaymentMethods.OfferRedemption)) { ResetAndDisableAmountTenderedField(); }
				this.CMSForm.patchValue({
					cmsSearch: this.payeeInfo && this.payeeInfo.patronId ? this.payeeInfo.patronId : '',
					cmsPaymentSelection: '',
					voucherSelection: ''
				});
				this.searchCMSPlayer(isOnLoad);
				break;
			case PaymentMethods.CompSlipRedemption:
				ResetAndDisableAmountTenderedField();
				break;
			case PaymentMethods.V1GiftCard:
			case PaymentMethods.V1GiftCardIdTech:
			case PaymentMethods.ExternalGiftCard:
			case PaymentMethods.ExternalGiftCardIdTech:
			case PaymentMethods.AgilysysGiftCard:
			case PaymentMethods.AgilysysGiftCardIdTech:
				this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.ProceedRefund : this.oCaptions.shop.Proceed;
				this.transitionDetails.controls.amounttendered.updateValueAndValidity();
				break;
			case PaymentMethods.PostToFolio:				
				this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
				this._folioBusiness.openFolioDialog(this,null,ScreenConstants.ORDERSUMMARY);
				break;
			default:
				this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
				break;
		}
		this.disableAmountTendered();
		if (this.isCustomPaymentMethod && this.selectedpayment.requireComments && !this.multiplePayment) {
			//since payment cmt field is dynamic and Angular CD will take some time to reflect DOM, Added Set timeout is to push this into event loop
			setTimeout(() => { this.paymentCommentField.nativeElement.focus(); });
		}
		this.CheckResortFinanceCreditCartAuthFlow(true);
	}
	disableAmountTendered() {
		if (this.IsCheckOutScreen || (this.IsMakePaymentScreen && (this.selectedPaymentMethod != PaymentMethods.OfferRedemption))) {
			const singleFolio = this.AdditionalInputs && this.AdditionalInputs.singleFolio;
			this.transitionDetails.controls.amounttendered.enable();
			if (!singleFolio) {
				this.transitionDetails.controls.amounttendered.disable();
			}
		}
	}

	/**
   * @method function
   * @function tenderedAmount();
   * @input params <null>
   * @output <obj>
   * @description tendered
   */
	tenderedAmount(isUserEvent: boolean = true) {
		let AmountTendered = this.localization.currencyToSQLFormat(this.transitionDetails.controls.amounttendered.value);
		if (!(this.IsFolioPaymentMethod && this.isSurchargeApplied)) {
			this.CalculateSettledAmount();
		}
		if (this.utils.GetOriginalTenderId(this.selectedpayment?.paymentTypeId, this.selectedpayment?.parentTypeId) === PaymentMethods.Cash
			|| this.transitionDetails.controls.amounttendered.disabled
			|| (this.transitionDetails.controls.amounttendered.valid && !isNaN(AmountTendered) && this.selectedpayment?.paymentTypeId > 0)) {
			this.EnableCloseTranBtn = true;
		} else if (this.multiplePayment && this.SelectedPaymentMethodEquals(PaymentMethods.ARPost)
			&& this._ss.memberCardNumber != "0" && this._ss.SelectedPlayers && this._ss.SelectedPlayers.length
			&& this.remainingAmount >= this.enforceMemberRedeemAmount
			&& this.SettlementHistory.filter((x) => x.paymentMethodId == PaymentMethods.ARPost && !x.isReversed).length == 0) {
			this.EnableCloseTranBtn = true;
		}
		this.issuerTypeChange = !isUserEvent;
	}

	async searchCMSPlayer(isOnLoad = false) {
		if (this.CMSForm.controls['cmsSearch'] && this.CMSForm.controls['cmsSearch'].value) {
			let patronId = this.CMSForm.controls['cmsSearch'].value;
			this.selectedDeviceHandle = '';
			this.selectedCMSType = null;
			this.cmsPlayerHandles = [];
			this.selectedCMSPlayer = { PlayerName: '', PatronId: '' };
			this.CMSForm.patchValue({ cmsPaymentSelection: '', voucherSelection: '' });
			if (this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption)) {
				if (this._featureFlagInfo.SkipPMAgentCMS) {
					let loaderMsg = {
						[PaymentMethods.CompRedemption]: this.ShopCaptions.RetrievePatronDetails
					};
					this._ams.loaderEnable.next(loaderMsg[this.selectedPaymentMethod]);
					try {
						let accountInfo: CMSAccountInfo = await this._cmsBusiness.GetAccountInfo(patronId, this.CMSForm.controls['cmsPin'].value, this.selectedpayment?.paymentTypeId, true);
						if (accountInfo?.returnCode == SMReturnCode.Success) {
							if (!this._pblh.IsFolioPosting)this._cmsBusiness.CreatePatronGuest(patronId);
							let pointsDetails: PayHandle = {
								playerId: patronId,
								playerPin: this.CMSForm.controls['cmsPin'].value,
								name: accountInfo.accountName,
								type: CMSRedemptionType[CMSRedemptionType.Points],
								balance: accountInfo.cmsBalanceDetails.compRedemtion.points.availablePointsInDollars,
								handle: "",
								inquiryInfo: null,
								isBalanceVisible: false,
								isPartialTenderAllowed: false,
								isRefundable: false,
								additionalAttributes: null,
								allowedAPIs: [],
								referenceNumber1: "",
								referenceNumber2: "",
							}
							let compsDetails: PayHandle = {
								playerId: patronId,
								playerPin: this.CMSForm.controls['cmsPin'].value,
								name: accountInfo.accountName,
								type: CMSRedemptionType[CMSRedemptionType.Comps],
								balance: accountInfo.cmsBalanceDetails.compRedemtion.comps.availableCompInDollars,
								handle: "",
								inquiryInfo: null,
								isBalanceVisible: false,
								isPartialTenderAllowed: false,
								isRefundable: false,
								additionalAttributes: null,
								allowedAPIs: [],
								referenceNumber1: "",
								referenceNumber2: "",
							}
							this.cmsPlayerHandles.push(pointsDetails, compsDetails);
							this.cmsPlayerHandles = this._sbs.FilterCMSApplicableBuckets(this.cmsPlayerHandles);
							this.selectedCMSPlayer = {
								PlayerName: accountInfo.accountName,
								PatronId: patronId
							}
						}
						else {
							let notFoundMsg = {
								[PaymentMethods.CompRedemption]: this.localization.captions.shop.NoPlayersFound
							};
							if (!isOnLoad) {
								this.utils.ShowErrorMessage(
									this.localization.captions.common.Error,
									accountInfo.message ? accountInfo.message
										: notFoundMsg[PaymentMethods.CompRedemption]

								);
							}
							this._sbs.EnableCloseTranBtn = false;
						}
						setTimeout(() => {
							this._ams.loaderEnable.next('');
						}, 1000);
					}
					catch (error) {
						console.error("exception occurred while fetching player details:" + error?.message);
						this.utils.ShowErrorMessage(
							this.localization.captions.common.Error,
							this.localization.captions.shop.NoPlayersFound
						);
						this._ams.loaderEnable.next('');
					}
				}
				else {
					this._pblh.GetHandles(isOnLoad);
				}
			} else {
				this._ams.loaderEnable.next(this.ShopCaptions.RetrievePatronDetails);
				this.cmsPlayerInfo = null;
				this.Vouchers = [];
				try {
					let playerInfo: CMSPlayerInfo = await this._cmsBusiness.GetCMSPlayerInfo(patronId, this.selectedpayment?.paymentTypeId, this.CMSForm.controls['cmsPin'].value, true);
					if (playerInfo && playerInfo?.returnCode == SMReturnCode.Success) {
						console.log(playerInfo);
						let patronIdData = this.CMSForm.controls['cmsSearch'].value;
						let vouchers = playerInfo.cmsBalanceDetails.offerRedemption.vouchers;
						if (vouchers && vouchers.length && vouchers.find(x => x.vendorType.toUpperCase() == this._featureFlagInfo.GetCMSSiteId.toUpperCase())) {
							if (!this._pblh.IsFolioPosting)this._cmsBusiness.CreatePatronGuest(patronIdData);
						}
						this.cmsPlayerInfo = playerInfo;
						this.selectedCMSPlayer = {
							PlayerName: playerInfo.personalDetails ? `${playerInfo.personalDetails.firstName} ${playerInfo.personalDetails.lastName}` : "",
							PatronId: playerInfo.playerID
						};
						this.Vouchers = playerInfo.cmsBalanceDetails.offerRedemption.vouchers;
					} else {
						if (!isOnLoad) {
							this.utils.ShowErrorMessage(
								this.localization.captions.common.Error,
								playerInfo?.message ? playerInfo?.message : this.localization.captions.shop.NoPlayersFound
							);
						}
					}
					setTimeout(() => {
						this._ams.loaderEnable.next('');
					}, 1000);
				} catch (error) {
					console.error("exception occurred while fetching player details:" + error?.message);
					this.utils.ShowErrorMessage(
						this.localization.captions.common.Error,
						this.localization.captions.shop.NoPlayersFound
					);
					this._ams.loaderEnable.next('');
				}
			}
		}
	}

	async getstayPaymentCreditCardInfos(stayId: string) {
		this.ActiveCreditCards = [];
		const Response: any = await this.http.CallApiAsync<any>({
			host: GlobalConst.Host.reservation,
			callDesc: 'GetStayPaymentCardOnFileInfo',
			method: HttpMethod.Get,
			uriParams: { stayId: stayId }
		});
		this.COFCardsData = Response.result;
		let uniqPaymentRefIds = Array.from(new Set([...Response.result.map(r => r.paymentTransactionId)]));
		uniqPaymentRefIds = uniqPaymentRefIds.filter(u => u > 0);
		if (uniqPaymentRefIds.length > 0) {
			uniqPaymentRefIds.forEach(async (id) => {
				if (this.ActiveCreditCards.findIndex(x => x.paymentTransactionId === id) < 0) {
					const cardInfo = (this.IsResortFinanceFolioAuthFlow || this.IsResortFinanceMakePaymentFlow) ? await this.payAgentService.FolioGetCardInfo(id) : await this.payAgentService.GetCardInfo(id);
					this.ActiveCreditCards.push({
						paymentTransactionId: id,
						cardInfo: cardInfo						
					});
				}
			});
		}
	}

	async prefillAccountLookupComponent() {
		if (this._ss.isFromSncBeo && this._ss.arAccountName && this._ss.arAccountNumber) {
			this.accountLookupComponentInput = {
				arAccountName: this._ss.arAccountName,
				arAccountNumber: this._ss.arAccountNumber,
				isCustomUIRequired: false,
				selectedPaymentMethod: this.selectedpayment?.paymentTypeId,				
				profitCenter: Number(this.profitCenter??'0')
			};
		}
		else {
			this.accountLookupComponentInput = {
				arAccountName: "",
				arAccountNumber: "",
				isCustomUIRequired: false,
				selectedPaymentMethod: this.selectedpayment?.paymentTypeId,				
				profitCenter: Number(this.profitCenter??'0')
			};
			this._ss.arAccountName = this._ss.arAccountNumber = "";
		}
		this.accountLookupComponentInput = { ...this.accountLookupComponentInput };
	}

	selectCMSPaymentType(playerInfo, event) {
		if (this._featureFlagInfo.SkipPMAgentCMS) {
			this.selectedCMSType = this._sbs.cmsPaymentSelectType(
				this.getAmountPaid(),
				playerInfo,
				event,
				this.CMSForm
			);
			this.EnableCloseTranBtn = (this.selectedCMSType != null)
		}
		else {
			this.selectedDeviceHandle = this._sbs.selectCMSPaymentType(
				this.getAmountPaid(),
				playerInfo,
				event,
				this.CMSForm
			);
			if(this.IsFolioPaymentMethod){
				this.selectedCMSType = playerInfo;
			}
			this.EnableCloseTranBtn = (this.selectedDeviceHandle != "");
		}
	}

	getSaleAmt(forPaymentPopup: boolean = false) {
		let Amount = this.totalAmount;
		if (forPaymentPopup) {
			return Amount < 0 ? Amount * -1 : Amount;
		} else {
			return Amount;
		}
	}

	getAmountPaid(SaleAmountResponse?, PropertyName?, saleAmount = 0) {
		let AmountPaid = 0;
		const MethodWhichDoesNotHavePartialPaymentFlow = [
			PaymentMethods.Cash,
			PaymentMethods.ARAcctPost,
			PaymentMethods.RainCheck,
			PaymentMethods.ARPost,
			PaymentMethods.RedeemPoint,
			PaymentMethods.CreditBook,
			PaymentMethods.CompRedemption,
			PaymentMethods.OfferRedemption,
			PaymentMethods.CompSlipRedemption,
			PaymentMethods.PostToFolio,
			PaymentMethods.Wallet
		];
		const cardMethods = [
			PaymentMethods.CreditCard,
			PaymentMethods.IDTECH,
			PaymentMethods.CardOnFile
		]
		if (MethodWhichDoesNotHavePartialPaymentFlow.includes(this.selectedPaymentMethod) ||
			this.isGiftCardSelected || this.isCustomPaymentMethod || this.SkipRoomChargePosting
			|| cardMethods.includes(this.selectedPaymentMethod) && !SaleAmountResponse) // If PaymentMgr Call failed then response will be null so sending value from UI
		{
			if (this.multipleMemberPayment && SaleAmountResponse != undefined) {
				AmountPaid = SaleAmountResponse;
			}
			else if (this.multiplePayment) {
				AmountPaid = this._sbs.ValidateSaleAmount(this.localization.currencyToSQLFormat(
					this.transitionDetails.controls.amounttendered.value
				), this);
			} else {
				AmountPaid = this.getSaleAmt();
			}
			if (saleAmount != 0 && this.IsOfferOrCompSlipPaymentMethod) {
				AmountPaid = saleAmount;
			}
		} else if (SaleAmountResponse) {
			AmountPaid =
				this.IsRefundFlow && SaleAmountResponse[PropertyName] > 0
					? SaleAmountResponse[PropertyName] * -1
					: SaleAmountResponse[PropertyName];
		}
		return AmountPaid;
	}

	selectVoucher(voucher: Voucher) {
		if (voucher) {
			this.selectedVoucher = voucher;
			let saleAmt = this.remainingAmount < voucher.voucherAmount ? this.remainingAmount : voucher.voucherAmount;
			this.selectedVoucher.transactionAmount = saleAmt;
			this.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(saleAmt));
			this.EnableCloseTranBtn = true;			
			this.ConfirmationPopupShown = true;// Added to prevent selectedVoucher object getting reset. TODO: Refactor	
		}
	}

	SelectGiftCard(selectedGiftCard: GiftcardSearchResult) {
		selectedGiftCard.paymentMethodId = this.selectedpayment.id;
		this.selectedGiftCard = selectedGiftCard;
		if (selectedGiftCard && selectedGiftCard.handle) {
			this.selectedDeviceHandle = selectedGiftCard.handle;
			this.selectedpayment.paymentTypeId = selectedGiftCard.tenderId;
			this.selectedpayment.paymentTypeEqualant = GiftcardIDTechMethods.includes(selectedGiftCard.tenderId) ?
				GiftcardIDTechMethodMapper[selectedGiftCard.tenderId] : selectedGiftCard.tenderId;
			const amtTendered = this.localization.currencyToSQLFormat(this.transitionDetails.controls.amounttendered.value);
			//Updating Amount tendered whichever the lesser value
			if (selectedGiftCard.amount < amtTendered)
			{
				this.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(selectedGiftCard.amount));
				if(this.IsResortFinanceMakePaymentFlow){
					this.tenderedAmount();
				}
			}
		} 
		else {
			this.selectedDeviceHandle = '';
		}
	}

	SelectAccount(selectedARAccount: ArAccount) {
		if (selectedARAccount) {
			this.selectedARAccount = selectedARAccount;
			this._ss.arAccountName = selectedARAccount.accountName;
			this._ss.arAccountNumber = selectedARAccount.accountNumber;
		} else {
			this.selectedARAccount = null;
			this._ss.arAccountName = this._ss.arAccountNumber = "";
		}
	}

	/**
	 * @description This method will get fired when user selected room number from
	 *  Guest has PMS Reservation Activity and it is listed above
	 * 	Guest room lookup component
	 * @param room 
	 */
	SelectRoomFromActivityResult(room: GuestStayDetail) {
		this.SelectedRoomNo = "";
		if (room && room?.roomNo) {
			this.SelectedRoomNo = room?.roomNo;
		}
	}

	/**
	 * @description This method is to handle selection event from Guest room lookup component
	 */
	SelectGuestRoom(room) {
		if (room && room.handle) {
			this.selectedDeviceHandle = room.handle;
			this.selectedGuestRoom = room;
			this.EnableCloseTranBtn = true;
		} else {
			this.selectedDeviceHandle = "";
			this.selectedGuestRoom = null;
		}
	}

	selectCompSlip(event) {
		this.selectedCMSPlayer = { PlayerName: '', PatronId: '' };
		this.cmsPlayerInfo = null;
		this.selectedVoucher = null;
		if (event && Array.isArray(event) && event.length == 2 && event[0] && event[1]) {
			const playerInfo: CMSPlayerInfo = event[0];
			const voucher: Voucher = event[1];
			this.selectVoucher(voucher);
			this.cmsPlayerInfo = playerInfo;
			this.selectedCMSPlayer = {
				PlayerName: `${playerInfo.personalDetails.firstName} ${playerInfo.personalDetails.lastName}`,
				PatronId: playerInfo.playerID
			};
		}
	}

	PaymentOption(e: any) {
		this.paymentMethods = _.cloneDeep(this.paymentMethodsClone);
		this.transitionDetails.controls.selectedDevice.patchValue('');
		if (e.id === 2) {
			this.multiplePayment = true;
			this.transitionDetails.controls.amounttendered.enable();
			if (this.remainingAmount > 0) this._pblh.PatchRemainingAmountAndHighlight(this.remainingAmount);
			this.ShowTenderField = true;
			// this.paymentMethods = this.paymentMethods.filter(
			// 	(r) => r.paymentTypeId != PaymentMethods.PendingSettlement
			// );
			if (this.IsOfferOrCompSlipPaymentMethod) {
				this.transitionDetails.controls.amounttendered.setValue(0); //Offer value will be prefilled on Selection
				this.transitionDetails.controls.amounttendered.disable();
			}
			if (this.SelectedPaymentMethodEquals(PaymentMethods.RedeemPoint)) {
				this.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(this.redeemAmount));
				this.transitionDetails.controls.amounttendered.disable();
				this.EnableCloseTranBtn = true;
			}
			if (this.SelectedPaymentMethodEquals(PaymentMethods.ARPost) && this._ss.memberCardNumber != "0" && this._ss.SelectedPlayers && this._ss.SelectedPlayers.length && this.enforceMemberRedeemAmount > 0 && this.remainingAmount >= this.enforceMemberRedeemAmount && this.SettlementHistory.filter((x) => x.paymentMethodId == PaymentMethods.ARPost && !x.isReversed).length == 0) {
				this.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(this.enforceMemberRedeemAmount));
				this.transitionDetails.controls.amounttendered.disable();
				this.EnableCloseTranBtn = true;
			}

		} else {
			this.multiplePayment = false;
			this.transitionDetails.controls.amounttendered.setValue(0);
		}
		if (this._ss.settleOpenTransaction && this.SettlementHistory.length > 0) {
			this.multiplePayment = true;
		}
		this.AdjustPaymentMethods();
	}

	AdjustPaymentMethods() {
		let payMethodsToRemove: PaymentMethods[] = [];
		if (this.IsRefundFlow) {
			payMethodsToRemove = [PaymentMethods.PendingSettlement, PaymentMethods.RainCheck, PaymentMethods.CompRedemption, PaymentMethods.OfferRedemption, PaymentMethods.CompSlipRedemption, PaymentMethods.Wallet];
			this.paymentMethods = this.paymentMethods.filter((r) => !payMethodsToRemove.includes(this.utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId)));
			if (payMethodsToRemove.some(x => x == this.selectedPaymentMethod)) { //Bug - 34916 Fix
				this.ResetSelectedPayment();
			}
		} else {
			this.paymentMethods = _.cloneDeep(this.paymentMethodsClone);
			if (this.SettlementHistory.length > 0 && this.SettlementHistory.some(x => !x.isReversed)) {
				this.paymentMethods = this.paymentMethods.filter((r) => !payMethodsToRemove.includes(this.utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId)));
			}
			this.DisableRoomGroupMemberCityLedgerForDeposit();
		}
	}

	ResetSelectedPayment() {
		this.selectedpayment = {
			id: 0,
			paymentTypeId: 0,
			parentTypeId: 0,
			paymentMethodId: 0,
			postTypeId: 0
		};
		this.selectedpayment = {...this.selectedpayment}
		this.isCardTransaction = false;
		this.ResetSurchargeFlags();
	}

	CheckForCardOnFile(payeeDetail: PayeeInfo) {
		this.CardOnFileforSelectedGuest = payeeDetail && payeeDetail.cardInfo ? payeeDetail.cardInfo.find((x) => x.isActive) : null; //Filter active cards and get the first card
		this.CardOnFileforSelectedGuest = (this.ScreenName == RetailScreenType.FolioPosting || this.IsCheckOutScreen || this.IsMakePaymentScreen) && this.PaymentReferenceID > 0 ?
			{
				clientId: 0,
				tokenTransId: this.PaymentReferenceID,
				isActive: true
			} : this.CardOnFileforSelectedGuest;

		let CardOnFile: PaymentMethod = {
			id: PaymentMethods.CardOnFile,
			paymentTypeId: PaymentMethods.CardOnFile,
			paymentMethod: this.localization.captions.shop.paymentMethods[PaymentMethods.CardOnFile],
			type: 'pay-card',
			isActive: true,
			isDefault: true
		};
		const paymentMethodRef = this.AdditionalInputs?.paymentValue ? this.AdditionalInputs.paymentValue : payeeDetail?.paymentMethodId ?? 0;
		const PaymentData = this.paymentMethods.find(x => x.id == paymentMethodRef);
		if (this.IsFolioPaymentMethod && PaymentData) {
			CardOnFile.isSurcharge = PaymentData.isSurcharge;
			CardOnFile.isPercent = PaymentData.isPercent;
			CardOnFile.surchargeValue = PaymentData.surchargeValue;
		}
		if (this.CardOnFileforSelectedGuest && this.CardOnFileforSelectedGuest.tokenTransId > 0) {
			// Card on file exists then Add payment method
			const AddCOF = (collection: PaymentMethod[]) => {
				const creditCard = collection.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.CreditCard);
				const isCardPaymentAllowed = creditCard && creditCard.isActive;
				CardOnFile.requireReceipts = creditCard?.requireReceipts;
				CardOnFile.noOfReceipts = creditCard?.noOfReceipts;
				if (!collection.some((r) => this.utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId) == PaymentMethods.CardOnFile) && isCardPaymentAllowed) {
					collection.push(CardOnFile);
				}
			}
			AddCOF(this.paymentMethodsClone);
			AddCOF(this.paymentMethods);
		} else {
			// User may have selected a guest without having card on file
			// Check if CardOnFile included in payment method, if yes remove it
			const RemoveCOF = (collection) => {
				let filter = collection.filter((method) => this.utils.GetOriginalTenderId(method.paymentTypeId, method.parentTypeId) == PaymentMethods.CardOnFile);
				if (filter.length > 0) {
					collection.splice(collection.indexOf(filter[0]), 1);
				}
			}
			RemoveCOF(this.paymentMethodsClone);
			RemoveCOF(this.paymentMethods);
		}
	}

	CheckForGuaranteeMethod() {
		try {
			if (this.GuaranteeMethodData && this.GuaranteeMethodData.GuaranteeMethod > 0) {
				// Guarantee method's behaviour is determined based on this mapping
				const guaranteeMethodWithRetailMethodMap = {
					[GuaranteeMethod.Creditcard]: PaymentMethods.CardOnFile,
					[GuaranteeMethod.RoomCharge]: PaymentMethods.RoomCharge,
					[GuaranteeMethod.MemberARAccount]: PaymentMethods.ARAcctPost
				}
				const guaranteeMethodReceiptTenderMap = {
					[GuaranteeMethod.Creditcard]: PaymentMethods.CreditCard,
					[GuaranteeMethod.RoomCharge]: PaymentMethods.RoomCharge,
					[GuaranteeMethod.MemberARAccount]: PaymentMethods.ARAcctPost
				}
				const equivalentRetailPaymentMethod = this.paymentMethods.find(p => this.utils.GetOriginalTenderId(p.paymentTypeId, p.parentTypeId) == guaranteeMethodReceiptTenderMap[this.GuaranteeMethodData?.GuaranteeMethod]);
				// Dummy object to display GuaranteeMethod with other methods
				const guaranteeMethod: PaymentMethod = {
					id: PaymentMethods.GuaranteeMethod,
					paymentTypeId: guaranteeMethodWithRetailMethodMap[this.GuaranteeMethodData?.GuaranteeMethod],
					paymentMethod: this.localization.captions.shop.paymentMethods[PaymentMethods.GuaranteeMethod],
					type: PaymentMethodIconConst[PaymentMethods.GuaranteeMethod],
					isActive: true,
					requireReceipts: equivalentRetailPaymentMethod?.requireReceipts,
					noOfReceipts: equivalentRetailPaymentMethod?.noOfReceipts
				}
				const AddGuaranteeMethod = (paymentMethods) => {
					if (!paymentMethods.some(p => p.id == guaranteeMethod.id)) { paymentMethods.push(guaranteeMethod); }
				}
				AddGuaranteeMethod(this.paymentMethods);
				AddGuaranteeMethod(this.paymentMethodsClone);
				this.PrefillValuesBasedOnGuaranteeMethodValue();
			}
		} catch (error) {
			console.log(error)
		}
	}

	PrefillValuesBasedOnGuaranteeMethodValue() {
		//Pre-fill necessary variable to continue the existing flow
		switch (this.GuaranteeMethodData && this.GuaranteeMethodData.GuaranteeMethod) {
			case GuaranteeMethod.Creditcard:
				this.CardOnFileforSelectedGuest = {
					clientId: 0,
					isActive: true,
					tokenTransId: this.GuaranteeMethodData.CardDetail.PaymentReferenceId
				}
				break;
			case GuaranteeMethod.MemberARAccount:
				this.accountLookupComponentInput = {
					arAccountName: this.GuaranteeMethodData.ARAccountDetail.accountName,
					arAccountNumber: this.GuaranteeMethodData.ARAccountDetail.accountNumber,
					isCustomUIRequired: false,
					selectedPaymentMethod: this.selectedpayment?.paymentTypeId,
					profitCenter: Number(this.profitCenter??'0')
				}
				this.accountLookupComponentInput = { ...this.accountLookupComponentInput };
				break;
			case GuaranteeMethod.RoomCharge:
				this.SelectedRoomNo = this.GuaranteeMethodData.RoomDetail.roomNumber;
				break;
			default:
				break;
		}
	}

	async ProceedPayment() {
		if (!this._pblh.IsFolioPosting && !this.CheckButtonState()) {
			return;
		}

		this._ss.isPreSettlementGenerate = false;
		////When the payment method is ‘Card on file Card,’ the ‘Createcheck()’ function will not be triggered here. Instead, it will be triggered when the ‘Card on file PopUp’ is closed.
		if(!this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)) {  
			await this.CreateCheck();
		}
		switch (this.transactionState) {
			case PaymentTransactionState.NotStarted:
				if (this._ss.hasMultiPackItem && !this.payeeInfo) {
					const dataObj = {
						text: this.oCaptions.shop.SelectGuestForMultiPack,
						buttonname: 'okay',
						headertext: 'Information',
						icon: 'icon-information',
						dialogReturn: false
					};
					this.roomOpenDialog(dataObj);
					return;
				}
				//*** OPEN CONFIRMATION DIALOG BASED ON SELECTED PAYMENT
				if ((MethodsWhichHasPaymentConfirmationPopup.includes(this.selectedPaymentMethod) && !this.IsRefundFlow)
					|| this.IsRefundToCOF || this.IsMemberRefund) {
					this._pblh.OpenPaymentDialog();
					return;
				} else if (this.SelectedPaymentMethodEquals(PaymentMethods.PendingSettlement)
					&& (this.GridItems.some(x => x.element.ItemType == RetailItemType.SpaServices)
						|| this.GridItems.some(x => x.retailItemType == RetailItemType.SpaServices))) {

					let isPreSettlementEnabled: boolean = false;

					isPreSettlementEnabled = sessionStorage.getItem("RequirePreSettlement")?.toLowerCase() == "true";

					if (isPreSettlementEnabled) {
						this.utils.showAlert(this.oCaptions.PreSettlementConfirmation, AlertType.Info, ButtonType.YesNo, (res) => {
							if (res === AlertAction.YES) {
								this._ss.isPreSettlementGenerate = true;
							}
							this._pblh.MakeSale();
						});
						return;
					}
					else {
						this._pblh.MakeSale();
					}
				}
				else {
					if (!this.selectedpayment.isDefault) {
						this.paymentComments = this.transitionDetails.controls.paymentComment.value;
					}
					else {
						this.paymentComments = "";
					}
					if (this._pblh.isAdyenWithAllEntryMode() && (this.IsMakePaymentScreen || this.IsCheckOutScreen))
					{
						this._pblh.InitiateSaleReqBasedOnPaymentMethod(this.getAmountPaid())
					}
					else {
						this._pblh.MakeSale();
					}
					
					if(!this.IsResortFinanceMakePaymentFlow){
						this.isEnablePaymentComment = false;
						this.isPaymentCommentRequired = false;
						this.transitionDetails.controls.paymentComment.setValue("");
					}
				}
				break;
			case PaymentTransactionState.InProgress:
				if (!this.selectedpayment.isDefault) {
					this.paymentComments = this.transitionDetails.controls.paymentComment.value;
				}
				else {
					this.paymentComments = "";
				}
				this._pblh.MakeSale();
				if(!this.IsResortFinanceMakePaymentFlow){
					this.isEnablePaymentComment = false;
					this.isPaymentCommentRequired = false;
					this.transitionDetails.controls.paymentComment.setValue("");
				}
				break;
			case PaymentTransactionState.Closed:
				this.CloseTransactionClick.emit();
				break;
		}
	}

	roomOpenDialog(e: any) {
		this.utils.roomOpenDialog(e);
	}

	CheckButtonState() {
		//If the payment text is close transaction, then by default, the button should be enabled.		
		if (this.paymentText?.trim().toLowerCase() == this.oCaptions.shop.CloseTransaction.trim().toLowerCase() || this.transactionState == PaymentTransactionState.Closed) {
			return true;
		} else {
			let isZipcodevalid = (this.isCardTransaction && this.GatewayConfiguration?.isAVSActive) ? this.transitionDetails?.controls?.zipcode?.valid : true;
			
			let validateRoomCharge = RoomGrpChargeMethod.includes(this.selectedPaymentMethod) ?
				this.selectedDeviceHandle != '' && this.selectedGuestRoom != null 
				: true;

			let validateHotelComp = (this.selectedPaymentMethod == PaymentMethods.HotelComp)?
			this.selectedDeviceHandle != '' && this.selectedGuestRoom != null
			: true;

			let validateRainCheck = 
				this.SelectedPaymentMethodEquals(PaymentMethods.RainCheck)
					? this.SelectedRainCheck != null && this.rainCheckSelection > 0
					: true;
			let validateCMS =
				this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption)
					? (this.selectedCMSType != null || this.selectedDeviceHandle != '') && this.CMSForm.controls['cmsPaymentSelection'].value != ''
					: true;
			let validateOfferRedemption =
				this.SelectedPaymentMethodEquals(PaymentMethods.OfferRedemption)
					? this.selectedVoucher != null && this.CMSForm.controls['voucherSelection'].value != ''
					: true;
			let validateGiftcard = this.isGiftCardSelected ?
				(this.selectedDeviceHandle != '' || this.IsRefundAsGiftcardFlow)
				&& this.selectedGiftCard != null : true;
			let validateArAcct = this.SelectedPaymentMethodEquals(PaymentMethods.ARAcctPost) ? this.selectedARAccount != null && this.selectedARAccount.accountName != '' && this.selectedARAccount.accountNumber != '' : true;
			// check IG rain check condition
			let validateRainCheckForIG = !this.PropertyInfo.UseRetailInterface
				? true
				: !this.showRainCheckSectionForIG ||
				(this.showRainCheckSectionForIG && (this.SelectedRainCheck != null && this.rainCheckSelection > 0));
			const validateIGAndRWOT = (this.PropertyInfo.UseRetailInterface
				|| ((this.remainingAmount == 0 || this._ss.isReturnWithoutTicket)
					&& (this.transitionDetails.controls.amounttendered.valid
						|| this.transitionDetails.controls.amounttendered.disabled)));
			let validateAmtTendered = this._pblh.IsFolioPosting ? (this.transitionDetails.controls.amounttendered.value != 0 && (this.transitionDetails.controls.amounttendered.valid || this.transitionDetails.controls.amounttendered.disabled)) : (this.multiplePayment ? (this.transitionDetails.controls.amounttendered.value != 0 &&
				((this.transitionDetails.controls.amounttendered.enabled && this.transitionDetails.controls.amounttendered.valid) ||
					this.transitionDetails.controls.amounttendered.disabled) && this.EnableCloseTranBtn) || validateIGAndRWOT
				: (this.transitionDetails.invalid && this.selectedpayment?.paymentTypeId > 0) || validateIGAndRWOT);
			let validateCompSlip = this.SelectedPaymentMethodEquals(PaymentMethods.CompSlipRedemption)
				? this.selectedVoucher != null && this.selectedCMSPlayer != null : true;
			let validatePaymentComment = (this.transitionDetails.controls.paymentComment.valid || !this.isPaymentCommentRequired);
			let validatePaymentMethodSelection = !this.PropertyInfo.UseRetailInterface && this.transactionState < PaymentTransactionState.Closed ? this.selectedpayment?.paymentTypeId > 0 : true
			let validateCMSPin = this.IsCompOrOfferRedemption && this.IsCMSRequirePin ? (!this.CMSForm.controls.cmsPin.value || this.CMSForm.controls.cmsPin.valid) : true;
			let validatePostToFolio = this.SelectedPaymentMethodEquals(PaymentMethods.PostToFolio) ?
			            this.postfolioData != '' && this.postfolioData != null
			: true;
			let validateWallet = this.SelectedPaymentMethodEquals(PaymentMethods.Wallet)
				? (this.transitionDetails.controls.walletInput.value != 0 && this.transitionDetails.controls.walletInput.valid)	: true;
			let validateGridItemExist = this.IsShopScreen ? this.GridItems?.length > 0 : true;

			return (!this.isAPICallInProgress &&
				isZipcodevalid &&
				(this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard) ||
					this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH)
					? this.transitionDetails.controls.selectedDevice.valid && this.selectedDeviceHandle != '' : true) &&
				validateRoomCharge &&
				validateHotelComp &&
				validateRainCheck &&
				validateCMS &&
				validateOfferRedemption &&
				validateCMSPin &&
				validateGiftcard &&
				validateArAcct &&
				validateCompSlip &&
				validateAmtTendered &&
				validateRainCheckForIG &&
				validatePaymentComment &&
				validatePaymentMethodSelection &&
				this.isValidUser &&
				validatePostToFolio &&
				validateWallet &&
				validateGridItemExist &&
				(this._ss.Ticket != null || this.ScreenName != RetailScreenType.Shop)
			);
		}
	}

	CancelOrder() {
		if (this.ScreenName == RetailScreenType.FolioPosting) {
			this.Cancelled.emit({ from: ActionMode.cancel });
			return;
		}

		if(this.IsResortFinanceMakePaymentFlow){
			this.ResetSelectedPayment();
			this.CancelResortFinancePaymentMethodValues();
			this.Cancelled.emit({ from: ActionMode.cancel });
		}

		if (
			((!this._ss.settleOpenTransaction && !this._ss.reOpenTransaction) || this.correcttransaction) &&
			this.CreateRetailItemResponse &&
			this.CreateRetailItemResponse.transactionDetails &&
			this.CreateRetailItemResponse.transactionDetails[0]
		) {
			if (!this._sbs.ValidateTransactionCancellation(this.SettlementHistory
				, this.FormReversalParam()
				, this.CancelAllPaymentCallback.bind(this)
			)) {
				return;
			}
			if (this.SettlementHistory && this.SettlementHistory.length > 0) {
				this.OpenDeleteTransactionConfirmationDialog(this.CreateRetailItemResponse.transactionDetails[0].transactionId);
				return;
			} else {
				this.transactionService.DeleteTransactionRecord(
					this.CreateRetailItemResponse.transactionDetails[0].transactionId
				);
			}
		}
		this.Cancelled.emit();
	}

	CancelResortFinancePaymentMethodValues(){		
		this.transitionDetails.controls.paymentComment.setValue("");
		this.transitionDetails.controls.amounttendered.setValue(0);
		this.selectedText = "";
		this.selectedDeviceHandle = "";
		this.selectedGiftCard = null;
	}

	CancelEmit() {
		const BeforeSubscribelogType = "Retail:CancelTransactionEventEmitBeforeSubscribeFromOrderSummary";
		const AfterSubscribelogType = "Retail:CancelTransactionEventEmitAfterSubscribeFromOrderSummary";
		const eventParams: RetailEventParameters<VoidEventModel> = {
			data: {
				transactionId: this.CreateRetailItemResponse.transactionDetails[0].transactionId
			},
			eventType: RetailEventType.Cancel
		}
		this._ams.CreateTransLog(eventParams?.data?.transactionId, BeforeSubscribelogType, { newValue: eventParams });
		retailPublisher.publishEvent(eventParams);
		this._ams.CreateTransLog(eventParams?.data?.transactionId, AfterSubscribelogType, { newValue: eventParams });
		this.ReleaseLock();
	}

	async ReleaseLock() {
		let playerIds: number[] = [];
		if (this._ss.SelectedPlayers != null && this._ss.SelectedPlayers.length > 0) {
			this._ss.SelectedPlayers.forEach(p => {
				if (p.playerId > 0) {
					playerIds.push(p.playerId);
				}
			});
		}
		if (playerIds && playerIds.length > 0) {
			await RetailDataAwaiters.ReleasePlayerTempHold(playerIds);
		}
	}

	ReversePayment(settlement: PaymentHistory) {
		console.log(settlement);
		if (settlement) {
			this._sbs.OpenPaymentReverseConfirmationDialog(settlement
				, this.FormReversalParam()
				, this.ReversePaymentCallBack.bind(this));
		}
	}

	ReversePaymentCallBack(settlement: PaymentHistory) {
		if (settlement) {
			console.log(settlement);
			settlement.isReversed = true;
			this.ShowSettlemtHist = this.SettlementHistory.some(x => !x.isReversed);
			this.tempSettlementHistory = [...this.SettlementHistory];
			this._pblh.AlterSettlementHistory();
			this.PaymentHistoryEmit.emit(this.tempSettlementHistory);
			if (settlement.paymentMethodId == PaymentMethods.RainCheck && settlement.additionalDetails != null) {
				const raincheckInfo = settlement.additionalDetails as RainCheckIssue;
				if (raincheckInfo && raincheckInfo.id > 0) {
					RetailDataAwaiters.UnRedeemRainChecks([raincheckInfo.id]);
				}
			}
			if (!this.ShowSettlemtHist) {
				this._ss.TempTicket = this._ss.Ticket = _.cloneDeep(this._ss.OriginalTicket);
				this.TicketRecalculated.emit(true);
				this.resetTaxExemptObjects();
			}
			let isAutoRemoveTax = settlement.paymentMethodInfo?.isAutoRemoveTax ?? this.paymentMethods.find(x => x.paymentTypeId == settlement.paymentMethodId).isAutoRemoveTax
			if (isAutoRemoveTax) {
				let settlementTaxExemptRatio = Number((settlement.amount / this._ss.totalAmountWithoutTax).toFixed(2));
				this._ss.totalTaxExemptRatio -= settlementTaxExemptRatio;
				this._ss.remainingAmountAfterPayment += settlement.amount;
				this._ss.exemptTaxForComp(0).then(x => {
					if (x) {
						this.taxExemptCalcTriggered = true;
						this.TicketRecalculated.emit();
						setTimeout(() => {
							this.CalculateSettledAmount();
							this._sbs.CheckAndReversePayment(settlement, this);
							this._pblh.PatchRemainingAmountAndHighlight(this._ss.remainingAmount, true);
							this.currentAmountTendered = this._ss.remainingAmount;
						}, 200)
					}
				});
			}
			else {
				this.CalculateSettledAmount();
				this._sbs.CheckAndReversePayment(settlement, this);
				this._pblh.PatchRemainingAmountAndHighlight(this.remainingAmount, true);
			}
			this.currentAmountTendered = this.remainingAmount;
			if (this._ss.memberCardNumber) {
				this._memberService.EnableDisableARPostandRedeemRoundPaymentMethod(this);
			}
		}
	}

	FormReversalParam() {
		let params: ReversalRequestParams = null;
		if (this._ss.settleOpenTransaction && this._ss.Ticket && this._ss.Ticket.checkData) {
			params = {
				transactionId: this._ss.transactionId,
				ticketNumber: this._ss.Ticket.checkData.checkNumber,
				outletId: this._ss.SelectedOutletId,
				checkHandleGuid: this._ss.Ticket.checkData.checkHandleGuid,
				terminalId: this._ss.SelectedTerminalId
			}
		} else if (this.CreateRetailItemResponse && this.CreateRetailItemResponse.transactionData) {
			const checkData = JSON.parse(this.CreateRetailItemResponse.transactionData.checkData);
			params = {
				transactionId: this.CreateRetailItemResponse && this.CreateRetailItemResponse.transactionData.id,
				ticketNumber: checkData && checkData.CheckHandle.CheckNumber,
				outletId: this._ss.SelectedOutletId,
				checkHandleGuid: this.CreateRetailItemResponse.transactionData.checkHandleGuid,
				terminalId: checkData && checkData.Terminal.Id
			}
		}
		return params;
	}

	CancelAllPaymentCallback(result) {
		if (result) {
			this.SettlementHistory.forEach(x => {
				if (!x.isReversed) {
					this._sbs.CheckAndReversePayment(x, this);
					x.isReversed = true;
				}
			});
			this.tempSettlementHistory = [...this.SettlementHistory];
			this.PaymentHistoryEmit.emit(this.tempSettlementHistory);
			if (this.SettlementHistory.some(x => x.isReversed
				&& x.paymentMethodId == PaymentMethods.RainCheck && x.additionalDetails != null)) {
				const rainCheckTrans = this.SettlementHistory.filter(x => x.paymentMethodId == PaymentMethods.RainCheck);
				const raincheckInfo = rainCheckTrans.map(r => r.additionalDetails as RainCheckIssue);
				if (raincheckInfo && raincheckInfo.length > 0 && raincheckInfo.some(x => x.id > 0)) {
					const raincheckIds = raincheckInfo.filter(x => x.id > 0).map(x => x.id);
					RetailDataAwaiters.UnRedeemRainChecks(raincheckIds);
				}
			}
			this.ShowSettlemtHist = this.SettlementHistory.some(x => !x.isReversed);
			if(!this.ShowSettlemtHist){
				this._ss.TempTicket = this._ss.Ticket = _.cloneDeep(this._ss.OriginalTicket);
				this.TicketRecalculated.emit(true);
				this.resetTaxExemptObjects();
			}
			this._ams.paymentProcessing = false;
			this._pblh.AlterSettlementHistory();
			this.CalculateSettledAmount();
			this._pblh.PatchRemainingAmountAndHighlight(this.remainingAmount, true);
			this._memberService.EnableDisableARPostandRedeemRoundPaymentMethod(this);
		}
	}

	resetTaxExemptObjects() {
		this._ss.totalTaxExemptRatio = 0;
		this._ss.taxExemptRatio = 0;
		this._ss.remainingAmountAfterPayment = this._ss.OriginalTicket.checkData.totalAmount;
		this._ss.isTaxRecalculated = false;
	}

	async checkTaxRemovalForDeletedPayments() {
		let total = 0;
		this.SettlementHistory.filter(x => !x.isReversed).forEach(settlement => {
			if (settlement.paymentMethodInfo.isAutoRemoveTax) {
				total += Number(settlement.amount.customToFixed());
			}
		})
		if (total > 0 && await this._ss.exemptTaxForComp(total)){
			this.TicketRecalculated.emit(true);
		}
	}

	OpenDeleteTransactionConfirmationDialog(transactionId) {
		this.utils.ShowErrorMessage(
			this.localization.captions.common.Warning,
			this.ShopCaptions.ConfirmDeleteTransactionMsg,
			GlobalConst.ButtonType.YesNo,
			this.TransactionDeleteCallBack.bind(this),
			[transactionId]
		);
	}

	TransactionDeleteCallBack(result, extraParam) {
		if (result.toLowerCase() === GlobalConst.ButtonOptions.Yes.toLowerCase() && extraParam[0]) {
			this.transactionService.DeleteTransactionRecord(extraParam[0]);
			this._ss.selectedProducts = [];
			this._ams.paymentProcessing = false;			
			this._ss.selectedPayeeId = null;			
			const BeforeSubscribelogType = "Retail:CancelTransactionEventEmitBeforeSubscribeFromOrderSummary";
			const AfterSubscribelogType = "Retail:CancelTransactionEventEmitAfterSubscribeFromOrderSummary";
			const eventParams: RetailEventParameters<VoidEventModel> = {
				data: {
					transactionId: extraParam[0]
				},
				eventType: RetailEventType.Cancel
			}
			this._ams.CreateTransLog(eventParams?.data?.transactionId, BeforeSubscribelogType, { newValue: eventParams });
			retailPublisher.publishEvent(eventParams);
			this._ams.CreateTransLog(eventParams?.data?.transactionId, AfterSubscribelogType, { newValue: eventParams });
			this.ReleaseLock();
			this._ss.ClearServiceObj();
			return;
		}
	}

	CalculateSettledAmount() {
		this.SettledAmt = 0;
		//check if previous settlements are there?
		if (this.SettlementHistory.length > 0) {
			//Get the settled amounts from all settlements
			this.SettlementHistory.forEach(
				(settlemt) => {
					if (!settlemt.isReversed) {
						(this.SettledAmt = this.RoundOffTwo(
							this.RoundOffTwo(this.SettledAmt) + this.RoundOffTwo(settlemt.amount)
						))
					}
				});
		}
		this.CalculateRemainingAmount();
		this.SettledAmt = this.RoundOffTwo(this.SettledAmt);
		this.disabledRadiobool = this.SettlementHistory.every(s => s.isReversed) ? false : this.disabledRadiobool;
	}

	CalculateRemainingAmount() {
		if (this.IsRefundFlow) {
			this.remainingAmount = -Number(
				(this.getSaleAmt(true) - (isNaN(this.SettledAmt) ? 0 : this.SettledAmt) * -1).customToFixed()
			);
			this.transitionDetails.controls.amounttendered.setValidators([
				Validators.min(this.remainingAmount),
				Validators.max(0),
				Validators.required,
				NegativeValueValidator(this)
			]);
			this.transitionDetails.controls.amounttendered.updateValueAndValidity();
		} else {
			this.remainingAmount = Number(
				(this.getSaleAmt(true) - (isNaN(this.SettledAmt) ? 0 : this.SettledAmt)).customToFixed()
			);
			this.transitionDetails.controls.amounttendered.setValidators([
				Validators.max(this.remainingAmount),
				Validators.required,
				NegativeValueValidator(this)
			]);
			this.transitionDetails.controls.amounttendered.updateValueAndValidity();
		}
	}

	async changeTerminal(value = null) {
		console.log(value);
		let CEDS_Response: any = await this.http.CallApiAsync<any>({
			host: GlobalConst.Host.payment,
			callDesc: RetailRoutes.GetCEDSByTerminalId,
			method: HttpMethod.Get,
			uriParams: { terminalId: value.terminalId }
		});
		this.CEDS_ByTerminal = CEDS_Response.result;
		if (this.IsNonPaymentScreen || (!this.IsNonPaymentScreen && this.IsFolioPaymentMethod))
			this._pblh.GetHandles();
	}

	getCEDSValue(outletId): string{
		const tenantId = this.utils.GetPropertyInfo("TenantId")
		return tenantId+'_'+this.PropertyInfo.PropertyId+'_'+outletId+'_'+outletId
	}

	authAmountChange(e) {
		const authAmount = this.localization.currencyToSQLFormat(this.transitionDetails.get('authorizationAmt').value);
		let creditLimit = this.localization.currencyToSQLFormat(this.transitionDetails.get('creditLimit').value);
		creditLimit = this.PaymentReferenceID == 0 ? 0 : creditLimit;
		const newcredLimitauthamount = authAmount + creditLimit;
		this.transitionDetails.get('newcreditLimit').setValue(this.localization.localizeCurrency(newcredLimitauthamount, false));
		this.authAmount = authAmount;
		this.AdditionalInputs.authAmount = authAmount;
		this.AdditionalInputs.IsFolioPosting = this._pblh.IsFolioPosting;
	}

	onmoreAction(eve) {
		console.log('eve,type', eve);
		this.AdditionalInputs.zipcode = this.GatewayConfiguration?.isAVSActive ? this.transitionDetails.get('zipcode').value : '';
		//this.transitionDetails.controls.creditLimit.setValue(this.localization.localizeCurrency(0, false));
		//this.transitionDetails.controls.newcreditLimit.setValue(this.localization.localizeCurrency(0, false));
		const authAmount = this.localization.currencyToSQLFormat(this.transitionDetails.controls.authorizationAmt.value);
		this.AdditionalInputs.isPartialPayAllowed = this.GatewayConfiguration?.isPartialPayAllowed;
		if (eve.id && eve.id === '1') {
			this.PaymentReferenceID = 0;
			if (this.PaymentReferenceIDMap.has(this.selectedpayment.id)) {
				this.PaymentReferenceIDMap.set(this.selectedpayment.id, 0)
			}
		} else if (this.PaymentReferenceIDMap.has(this.selectedpayment.id)
			&& this.PaymentReferenceIDMap.get(this.selectedpayment.id) != 0
			&& authAmount) {
			this.onPaymentTransactionClick(authAmount);
		}
	}

	AuthByToken(authAmount) {
		if (!authAmount) { return }
		this.utils.ToggleLoader(true);
		const authByTokenRoute = this._pblh.IsResortFinanceFolioPosting? RetailRoutes.FolioAuthByToken : RetailRoutes.RetailAuthByToken;
		const RequestBody = this.FormAuthByTokenRequestBody(String(PaymentMethods.CreditCard), authAmount, this.authInput, this.PaymentReferenceID, this.authorizedUserId);
		const outletId = this.authInput.outletId ? this.authInput.outletId : this.payAgentService.extractOutletFromCEDS(this.CEDS_ByTerminal);
		const authResponse: any = this.http.CallApiAsync<any>({
			host: GlobalConst.Host.payment,
			callDesc: authByTokenRoute,
			method: HttpMethod.Post,
			body: RequestBody,
			uriParams: { outletId: outletId },
			showError: true
		});
		console.log(authResponse);
		authResponse.then((response) => {
			let authByTokenResponse: AuthByTokenResponse = response.result;
			const clickReturnValue = {
				from: ActionMode.create,
				formValues: this.transitionDetails.getRawValue(),
				form: this.transitionDetails,
				transactionId: authByTokenResponse.transactionId,
				maskedCardNumber: authByTokenResponse.paymentManagerResponse.clientInfo.accountNumber,
				totalAuthorizedAmount: authByTokenResponse.paymentManagerResponse.totalAuthorizedAmount,
				folioNumber: this.AdditionalInputs.folioNumber,
				checkReponse: this.checkResponse,
				selectedPayment: this.selectedpayment,
				paymentComments: this.paymentComments
			};
			this.utils.ToggleLoader(false);
			this.utils.showAlert(this.ShopCaptions.AuthorizationSuccessMsg, AlertType.Success, GlobalConst.ButtonType.Ok, async (result) => {
				if (result === AlertAction.CONTINUE) {
					this.TransactionCompleted.emit(clickReturnValue);
				}
			});
		});
		authResponse.catch((errorResponse) => {
			this._pblh.HandleSaleFailure(errorResponse);
			const clickReturnValue = {
				from: ActionMode.cancel
			};
			this.utils.ToggleLoader(false);
			this.utils.showAlert(this.ShopCaptions.AuthorizationFailureMsg, AlertType.Info, GlobalConst.ButtonType.Ok, async (result) => {
				if (result === AlertAction.CONTINUE) {
					this.TransactionCompleted.emit(clickReturnValue);
				}
			});
		});
	}

	/**
* @function FormAuthByTokenRequestBody
* @description Perform the auth using the credit card token
*/
	FormAuthByTokenRequestBody(tenderId, authAmount, authInfo, tokenTransactiond, authorizedUserId = null): AuthByTokenRequest {
		let userId = this.localization.GetPropertyInfo('UserId');
		userId = authorizedUserId ? authorizedUserId : userId;
		return {
			inquirerInfo: {
				terminalId: String(authInfo.terminalId),
				orderNumber: this.IsResortFinanceFolioAuthFlow? authInfo.folioNumber : String(authInfo.sourceTypeId),
				profitCenter: authInfo.ProfitCenter,
				zipcode: authInfo.zipcode ? authInfo.zipcode : '',
				isPartialTenderAllowed: authInfo.isPartialPayAllowed ? authInfo.isPartialPayAllowed : false,
				tenderId: tenderId,
				employeeId: userId ? userId : '0',
				customer: userId ? userId : '0',
				enterprise: String(this.PropertyInfo.PropertyId),
				clientId: "",
				postingId: ""
			},
			transactionId: tokenTransactiond,
			amount: authAmount,
			sourceType: authInfo.sourceType,
			sourceTypeId: authInfo.sourceTypeId,
			folioNumber: authInfo.folioNumber,
			roomNumber: authInfo.roomNumber,
			hostName: this.hostName,
			ipAddress: this.ipAddress,
			agentVersion: this.agentVersion,
			postId: 0,
            guestGuid: authInfo?.guestId
		};
	}

	SaveReferenceId(newPaymentReferenceId: number) {
		if (newPaymentReferenceId !== 0) {
			this.PaymentReferenceID = newPaymentReferenceId;
			this.PaymentReferenceIDMap.set(this.selectedpayment.id, newPaymentReferenceId);
			this.NewPaymentReferenceID.emit(
				[newPaymentReferenceId, {
					thirdparty: this.transitionDetails.controls.thirdparty.value,
					donotdisclose: this.transitionDetails.controls.donotdisclose.value
				}]
			);
		}
	}

	async CreateCheck() {
		if (this.IsCheckOutScreen && this.IsCheckOutPaymentProcess) {
			const checkBodyData = {
				folioId: this.AdditionalInputs.folioId,
				buildingId: this.AdditionalInputs.buildingId,
				outletId: this.AdditionalInputs.outletId,
				terminalId: this.AdditionalInputs.terminalId,
				saleAmount: this.multiplePayment ? this.localization.currencyToSQLFormat(this.transitionDetails.controls.amounttendered.value) : this.totalAmount,
				sourceTypeId: this.AdditionalInputs.sourceTypeId,
				postTypeId: this.AdditionalInputs.paymentTransactionRefId && this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)
					? this.AdditionalInputs.postTypeId : this.selectedpayment.postTypeId,
				guestId: this.AdditionalInputs.guestId,
				roomNumber: this.AdditionalInputs.roomNumber,
				departmentId: this.AdditionalInputs.departmentId ? this.AdditionalInputs.departmentId : 0
			}
			this.checkResponse = await RetailDataAwaiters.getNormalizedPostTypes(checkBodyData);
		}
	}

	SaveAuthTransactionId(validateAuthResponse: ValidateAuthResponse) {
		if (validateAuthResponse && validateAuthResponse !== null) {
			this.authTransactionId = validateAuthResponse.transactionId;
			this.PaymentReferenceID = validateAuthResponse.transactionId;
			this.PaymentReferenceIDMap.set(this.selectedpayment.id, this.PaymentReferenceID);
			this.NewPaymentReferenceID.emit([this.PaymentReferenceID, {
				thirdparty: this.transitionDetails.controls.thirdparty.value,
				donotdisclose: this.transitionDetails.controls.donotdisclose.value
			}]);
			this.totalAuthorizedAmount = validateAuthResponse.paymentManagerResponse.totalAuthorizedAmount;
			const clickReturnValue = {
				from: ActionMode.create,
				formValues: this.transitionDetails.getRawValue(),
				form: this.transitionDetails,
				totalAuthorizedAmount: this.totalAuthorizedAmount,
				transactionId: validateAuthResponse.transactionId,
				selectedPayment: this.selectedpayment,
				paymentComments: this.paymentComments
			};
			this.utils.showAlert(this.ShopCaptions.AuthorizationSuccessMsg, AlertType.Success, GlobalConst.ButtonType.Ok, async (result) => {
				if (result === AlertAction.CONTINUE) {
					this.TransactionCompleted.emit(clickReturnValue);
				}
			});
		} else {
			const clickReturnValue = {
				from: ActionMode.cancel
			};
			this.utils.showAlert(this.ShopCaptions.AuthorizationFailureMsg, AlertType.Info, GlobalConst.ButtonType.Ok, async (result) => {
				if (result === AlertAction.CONTINUE) {
					this.TransactionCompleted.emit(clickReturnValue);
				}
			});
		}
	}

	async RainCheckGrid() {
		let raincheckdate: Date = this.RainCheckForm.get('issuedate').value;
		let rainchecks: RainCheckIssue[] = await RetailDataAwaiters.getRainChecks(
			raincheckdate,
			this.RainCheckForm.get('searchText').value
		);
		this.rainCheckTransaction = rainchecks;
		this.IsRainCheckGridData = (rainchecks.length != 0);
		this.rainCheckTransaction.forEach((check) => {
			check.issuedDateDisplay = `${this.localization.localizeDisplayDate(
				rainchecks[0].issuedDate,
				true
			)}  ${this.localization.LocalizeTime(rainchecks[0].issuedDate, true)}`;
		});
		this.rainCheckTransactionClone = _.cloneDeep(rainchecks);
		this.SelectedRainCheck = null;
		this.rainCheckSelection = 0;
	}

	dateChanged(event) {
		console.log('event ', event);
	}

	searchValueChange(event) {
		console.log('event ', event);
	}

	searchValueChangeEmit(event){
		this.selectedText = event;

		if (event.length > 0) {
			this.searchTextChanged.next(this.selectedText);
		  }
	}

	async SelectRainCheck(raincheck: RainCheckIssue) {
		this.RainCheckEvents.emit({
			event: RainCheckEventType.RemoveRainCheckItem,
			data: null
		});
		this.SelectedRainCheck = null;
		this.rainCheckSelection = 0;
		if (raincheck && raincheck.id != this.rainCheckSelection && this.remainingAmount > 0) {
			this.SelectedRainCheck = raincheck;
			this.rainCheckSelection = raincheck.id;
			if (this.multiplePayment) {
				let redeemValue: number =
					this.remainingAmount >= raincheck.rainCheckValue ? raincheck.rainCheckValue : this.remainingAmount;
				this.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(redeemValue));
				this.transitionDetails.controls.amounttendered.disable();
				this.EnableCloseTranBtn = true;
			}
			this.RainCheckEvents.emit({
				event: RainCheckEventType.AddRaincheckItem,
				data: this.SelectedRainCheck
			});
		}
	}

	onShown(e, loopIndex) {
		let popOverTargetPosition = this.popOverTarget.nativeElement.getBoundingClientRect();
		let windowWidth = window.innerWidth;
		let popOverWidth = this.rainCheckPopover.element.nativeElement.childNodes[0].offsetWidth;
		let popOverHeight = this.rainCheckPopover.element.nativeElement.childNodes[0].offsetHeight;
		let offsetRight = 0;
		let targetLeft = popOverTargetPosition.left;
		offsetRight = windowWidth;
		let checkRightPosition = offsetRight - popOverWidth;

		if (targetLeft < popOverWidth / 2) {
			e.content.left = 0;
			let calcArrow = 20;
			this.checkArrowTimeout(calcArrow, 0);
		} else if (targetLeft > checkRightPosition) {
			e.content.left = checkRightPosition;
			let calcArrow =
				(windowWidth - popOverTargetPosition.left) / popOverWidth -
				popOverTargetPosition.width / 2 / popOverWidth;
			if (calcArrow * 100 < 10) {
				this.checkArrowTimeout((1 - popOverTargetPosition.width / 2 / popOverWidth) * 100, 0);
			} else {
				this.checkArrowTimeout((1 - calcArrow) * 100, 0);
			}
		}

		let windowHeight = window.innerHeight;
		let targetTopPosition = document.getElementsByClassName('i-RC')[loopIndex].getBoundingClientRect().top;
		if (windowHeight - targetTopPosition < popOverHeight || windowHeight - targetTopPosition < 110) {

			if (popOverHeight < 100) {
				popOverHeight = 110;
			}
			e.content.top = targetTopPosition - popOverHeight - 5; //10 -span height
			e.content.effectivePlacement = 'top in';
		} else {
			e.content.top = targetTopPosition + 10; //10 -span height
			e.content.effectivePlacement = 'bottom in';
		}
	}

	showSurchargePopover(e) {
		let popOverTargetPosition = this.SCpopOverTarget.nativeElement.getBoundingClientRect();
		let windowWidth = window.innerWidth;
		let popOverWidth = this.surchargePopover.element.nativeElement.childNodes[0]?.offsetWidth ?
			this.surchargePopover.element.nativeElement.childNodes[0]?.offsetWidth
			: this.surchargePopover.element.nativeElement.childNodes[1]?.offsetWidth;
		let popOverHeight = this.surchargePopover.element.nativeElement.childNodes[0]?.offsetHeight ?
			this.surchargePopover.element.nativeElement.childNodes[0]?.offsetHeight
			: this.surchargePopover.element.nativeElement.childNodes[1]?.offsetHeight;
		let offsetRight = 0;
		let targetLeft = popOverTargetPosition.left;
		offsetRight = windowWidth;
		let checkRightPosition = offsetRight - popOverWidth;

		if (targetLeft < popOverWidth / 2) {
			e.content.left = 0;
			let calcArrow = 20;
			this.checkArrowTimeout(calcArrow, 0);
		} else if (targetLeft > checkRightPosition) {
			e.content.left = checkRightPosition - 50;
			let calcArrow =
				(windowWidth - popOverTargetPosition.left) / popOverWidth -
				popOverTargetPosition.width / 2 / popOverWidth;
			if (calcArrow * 100 < 10) {
				this.checkArrowTimeout((1 - popOverTargetPosition.width / 2 / popOverWidth) * 100, 0);
			} else {
				this.checkArrowTimeout((1 - calcArrow) * 100, 0);
			}
		}

		let windowHeight = window.innerHeight;
		let targetTopPosition = document.getElementById('surcharge-info').getBoundingClientRect().top;
		if (windowHeight - targetTopPosition < popOverHeight || windowHeight - targetTopPosition < 40) {
			if (popOverHeight < 40) {
				popOverHeight = 40;
			}
			e.content.top = targetTopPosition - popOverHeight - 10; //10 -span height
			e.content.effectivePlacement = 'top in';
		} else {
			e.content.top = targetTopPosition + 10; //10 -span height
			e.content.effectivePlacement = 'bottom in';
		}
	}

	checkArrowTimeout(arrowPosition, arrIndex) {
		let checkArrow = setTimeout(() => {
			let className = 'order-summary-popover';
			let popOverArrow = document
				.getElementsByClassName(className)
			[arrIndex].querySelectorAll('.arrow')[0] as HTMLElement;
			if (popOverArrow) {
				popOverArrow.style.left = arrowPosition + '%';
				clearTimeout(checkArrow);
			}
		}, 0);
	}

	async setApprovalCode() {
		if (this.productId === GlobalConst.Product.PMS && (this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard)
			|| this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile))) {
			if (!this.folioSettings) {
				const response = await this.http.CallApiAsync<any>({
					host: GlobalConst.Host.folio,
					callDesc: "GetFolioSettingByScreen",
					method: HttpMethod.Get,
					uriParams: { module: 'SYSTEMSETUP', screen: 'INCIDENTALSETTINGS' }
				});
				this.folioSettings = response?.result;
				this.requiredApprovalCodeForCC = this.folioSettings && this.folioSettings.configValue ? this.folioSettings.configValue.requiredApprovalCode : false;
			};
		}
	}

	async onPaymentTransactionClick(authAmount = null) {
		this.productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
		let continueOperation = this.productId !== GlobalConst.Product.PMS;
		if (continueOperation) {
			await this.performPaymentOperation(authAmount);
		}
		else if (this.showPaymentPrompt) {
			this.utils.showAlert(this.ShopCaptions.PaymentPrompt, AlertType.Info, ButtonType.YesNo, async (res) => {
				if (res === AlertAction.YES) {
					await this.performPaymentOperation(authAmount);
				}
				else {
					return;
				}
			});
		}
		else {
			await this.performPaymentOperation(authAmount);
		}
	}

	async performPaymentOperation(authAmount = null) {
		this.productId = parseInt(this.localization.GetPropertyInfo('ProductId'));		
		//To fetch greatest number of receipts value if multiple payment.
		let paymentDetails = this.paymentMethods;
		let selectedPaymentId = this.selectedpayment.paymentTypeId;
		let paymentmethodIds = this.SettlementHistory.map(x => x.paymentMethodId);
		let isUpdateTransRequired = this.SettlementHistory.length > 0 && this.selectedpayment.isAutoRemoveTax;
		paymentDetails = paymentDetails.filter( x => paymentmethodIds.includes(x.paymentTypeId) || x.paymentTypeId==selectedPaymentId);
		if(this.validateCreditLimitForRoomGroupCharge && this.AmountEntered > 0){
			if(this.AmountEntered > this.selectedGuestRoom.creditLimit) {
				this.utils.showAlert(this.localization.getError(110060), AlertType.Error, ButtonType.Ok);
				return;
			}
		}
		this._sbs.requireSignatureCapture = paymentDetails.some(x=> x.requireSignatureCapture || x.paymentTypeId == PaymentMethods.PendingSettlement );
		this._sbs.noOfReceipts = this.SettlementHistory.length > 0 ? (this.selectedpayment.noOfReceipts > this._sbs.noOfReceipts ? this.selectedpayment.noOfReceipts : this._sbs.noOfReceipts) : (this.selectedpayment && this.selectedpayment.noOfReceipts ? this.selectedpayment.noOfReceipts : this._paymentBusinessService.defaultNoOfReceipt);
		
		if (this.productId != GlobalConst.Product.PMS) {
			this._sbs.requireReceipts = this.SettlementHistory.length > 0 ? (this.selectedpayment.requireReceipts || this._sbs.requireReceipts ? true : false) : (this.selectedpayment && this.selectedpayment.requireReceipts ? this.selectedpayment.requireReceipts : false)
		}
		else {
			this._sbs.requireReceipts = true;
		}
		if (this.folioSettings) {
			this.requiredApprovalCodeForCC = this.folioSettings?.requiredApprovalCode;
		}
		else {
			await this.setApprovalCode();
		}
		if (!authAmount) {
			if (this.productId === GlobalConst.Product.PMS && this.requiredApprovalCodeForCC && (this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard) || this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile))) {
				const quickLoginDialogRef = this.commonUtils.QuickLogin();
				quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {

					if (quickLoginDialogResult.isLoggedIn) {
						this.isValidUser = true;
						this.authorizedUserId = quickLoginDialogResult.userDetails.userId;
						this.ProceedPayment();
					}
					else {
						this.isValidUser = false;
						if (!this.CheckButtonState()) { return; }
					}
				});
			}
			else {		
				if(this.selectedPaymentMethod == PaymentMethods.PostToFolio){
					this.validateResortFolioCreditLimit();
				}
				else{
					this.ProceedPayment();
				}
			}
		}
		else {

			if (this.productId === GlobalConst.Product.PMS && this.requiredApprovalCodeForCC) {
				const quickLoginDialogRef = this.commonUtils.QuickLogin();
				quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {

					if (quickLoginDialogResult.isLoggedIn) {
						this.authorizedUserId = quickLoginDialogResult.userDetails.userId;
						this.AuthByToken(authAmount);
					}
					else {
						return;
					}
				});
			}
			else {
				this.AuthByToken(authAmount);
			}

		}
	}

	async rainCheckIG() {
		this.showRainCheckSectionForIG = !this.showRainCheckSectionForIG;
		this.rainCheckSelection = 0;
		if (!this.showRainCheckSectionForIG) {
			this.RainCheckEvents.emit({
				event: RainCheckEventType.RemoveRainCheckItem,
				data: null
			});
		}
	}

	RoundOffTwo(Amount): number {
		return Number(Amount.customToFixed());
	}

	onAmtTenderBlur() {
		if (this.isCustomPaymentMethod && this.selectedpayment.requireComments && this.paymentCommentField?.nativeElement) {
			this.paymentCommentField?.nativeElement?.focus();
		}
		let AmountTendered = this.localization.currencyToSQLFormat(this.transitionDetails.controls.amounttendered.value);
		if (AmountTendered > 0 && AmountTendered != this.remainingAmount && !this.issuerTypeChange && this.isSurchargeApplied && this.IsFolioPaymentMethod) {
			this.ApplySurcharge(AmountTendered);
		}
	}

	checkZipCodeValidity() {
		let isZipcodevalid = (this.isCardTransaction && this.GatewayConfiguration?.isAVSActive) ? this.transitionDetails.controls.zipcode.value? true : false : true;
		this.moreoptionButton.disabledproperty = !isZipcodevalid;
		this.moreoptionButton = { ...this.moreoptionButton };
		if(this.IsResortFinanceFolioAuthFlow){
			this.moreoptionButton.disabledproperty = !isZipcodevalid;
			this.moreoptionButton = { ...this.moreoptionButton };
		}
	}

	searchValue(event) {
		event.stopPropagation();
		this.dialog.open(ArCustomerSearchComponent, {
			width: '100vw',
			height: '100vw',
			maxWidth: '100vw',
			data:
			{
				onlyActiveAccounts: true
			}
		}).afterClosed().subscribe(result => {
			if (result.data && result.from === DialogCloseEnum.Action) {
				let selectedARAccount: ArAccount = {
					accountName: '',
					accountNumber: ''
				};
				this._ss.arAccountName = result.data.accName ? result.data.accName : result.data.accountName;
				this._ss.arAccountNumber = result.data.accNumber ? result.data.accNumber : result.data.arCode;
				this.selectedText = this._ss.arAccountNumber;
				this.moreoptionButton.disabledproperty = false;
				this.moreoptionButton = { ...this.moreoptionButton };
				selectedARAccount.accountNumber = this._ss.arAccountNumber;
				selectedARAccount.accountName = this._ss.arAccountName;
				this.selectedARAccount = selectedARAccount;
				this.NewArCustomerSearch.emit({ isARAccountValid: true, selectedARAccount: this.selectedARAccount });
			} else {
				this.selectedARAccount = null;
				this._ss.arAccountName = this._ss.arAccountNumber = "";
				this.moreoptionButton.disabledproperty = true;
				this.moreoptionButton = { ...this.moreoptionButton };
				this.NewArCustomerSearch.emit({ isARAccountValid: false, selectedARAccount: this.selectedARAccount });
			}
		});
	}

	SetCheckZoomFlag(flag: boolean) { this.isCheckZoomEnabled = flag; }

	togglePaymentGrid() {
		this.showAllPaymentMethods = !this.showAllPaymentMethods;
		this._sbs.SetPaymentSectionScrollHeight();
	}

	checkCurrentPaymentSelection(paymentMethod: PaymentMethod) {
		return (paymentMethod.id == this.selectedpayment?.id
			&& (this.utils.GetOriginalTenderId(paymentMethod.paymentTypeId, paymentMethod.parentTypeId) == this.utils.GetOriginalTenderId(this.selectedpayment?.paymentTypeId, this.selectedpayment?.parentTypeId) ||
				this.utils.GetOriginalTenderId(paymentMethod.paymentTypeId, paymentMethod.parentTypeId) == this.selectedpayment?.paymentTypeEqualant))
	}
	focusCMSSearch() {
		if (this.cmsSearchField?.nativeElement) {
			this.cmsSearchField?.nativeElement.focus();
		}
	}

	focusWalletInput() {
		if (this.walletInputField?.nativeElement) {
			this.walletInputField?.nativeElement.focus();
		}
	}

	resetPlayerPin() {
		if (this.IsCMSRequirePin) {
			this.CMSForm?.controls?.cmsPin?.setValue('');
		}
	}

	async GetSurchargeConfig() {
		try {
			const surchargeConfig = await this._surchargeConfigService.GetSurchargeConfigurationWithCreditCardIssuer(PaymentMethods.CreditCard);
				this.cardIssuerTypes = [];
				surchargeConfig.map(issuerType => {
				if (issuerType?.isActive) {
					this.cardIssuerTypes.push
						({
							name: issuerType?.creditCardIssuerType?.displayName,
							type: issuerType?.creditCardIssuerType?.issuerType,
							isPercentage: issuerType.isPercentage,
							surchargeValue: issuerType.value,
							isActive: issuerType.isActive,
							checked: false
						})
				}
			});
		} catch (error) {
			console.log(error);
		}
	}

	selectcardIssuer(cardIssuerConf: CreditCardIssuerConfig) {
		this.cardIssuerTypes.filter(x => x.type != cardIssuerConf.type).map(x => x.checked = false);
		cardIssuerConf.checked = !cardIssuerConf.checked;
		this.selectedIssuerType = null;
		if (cardIssuerConf.checked && cardIssuerConf?.isActive) {
			this.issuerTypeChange = true;
			this.selectedIssuerType = cardIssuerConf;
			this.ApplySurcharge(this.AmtForSurchargeCalc);
		} else {
			this.ResetSurchargeFlags();
		}
	}
	ApplySurcharge(paymentAmount: number) {
		if (!paymentAmount || !this.SurchargeEnabled || this.IsRefundFlow || !this.selectedIssuerType) return;
		this._ams.loaderEnable.next(this.ShopCaptions.lbl_ApplyingSurchargeLoaderMsg);
		this.remainingAmountBeforeSurcharge = this.RoundOffTwo(this.remainingAmount - this.surchargeAmount);
		const surchargeAmt = !this.selectedIssuerType?.isPercentage ? this.selectedIssuerType.surchargeValue
			: this.RoundOffTwo(paymentAmount * (this.selectedIssuerType.surchargeValue / 100));
		setTimeout(() => {
			this.surchargeAmount = surchargeAmt;
			if (!this.IsNonPaymentScreen) this.totalAmount = this.RoundOffTwo(this.OriginalTotalAmount + this.PrevSurchargeTotal + surchargeAmt);
			this.remainingAmount = this.RoundOffTwo(this.remainingAmountBeforeSurcharge + surchargeAmt);
			this.transitionDetails.controls.amounttendered.setValidators([
				Validators.max(this.remainingAmount),
				Validators.required,
				NegativeValueValidator(this)
			]);
			this.isSurchargeApplied = true;
			this.SurchargeUpdated.emit(this.RoundOffTwo(this.PrevSurchargeTotal + surchargeAmt));
			this._pblh.PatchRemainingAmountAndHighlight(this.RoundOffTwo(paymentAmount + surchargeAmt), true);
			//if (!this.IsFolioPaymentMethod) this.lblamountTentered = this.localization.captions.shop.AmountTendered + ' (' + this.localization.captions.shop.lbl_IncludingSurcharge + ')';
			this._ams.loaderEnable.next("");
		}, 1000);
	}

	RemoveSurcharge(surchargeAmt: number, isReversal = false) {
		if ((!this.isSurchargedCollected && this.isSurchargeApplied && this.surchargeAmount > 0) || isReversal) {
			this._ams.loaderEnable.next(this.ShopCaptions.lbl_RemovingSurchargeLoaderMsg);
			if (!this.IsNonPaymentScreen) this.totalAmount = this.RoundOffTwo(this.totalAmount - surchargeAmt);
			this.remainingAmount = this.RoundOffTwo(this.remainingAmount - surchargeAmt);
			this.transitionDetails.controls.amounttendered.setValidators([
				Validators.max(this.remainingAmount),
				Validators.required,
				NegativeValueValidator(this)
			]);
			this.isSurchargeApplied = false;
			this.SurchargeUpdated.emit(this.RoundOffTwo(this.PrevSurchargeTotal));
			this._pblh.PatchRemainingAmountAndHighlight(this.remainingAmount, true);
			this.lblamountTentered = this.localization.captions.shop.AmountTendered;
			setTimeout(() => {
				this._ams.loaderEnable.next("");
			}, 500);
		}
	}

	ResetSurchargeFlags() {
		this.RemoveSurcharge(this.surchargeAmount);
		this.selectedIssuerType = null;
		this.isSurchargeApplied = this.isSurchargedCollected = false;
		this.amountBeforeSurcharge = this.surchargeAmount = this.remainingAmountBeforeSurcharge = 0;
		this.cardIssuerTypes.map(c => c.checked = false);
		this.transitionDetails.controls.creditcardType.setValue('');
	}

	isDeviceTransaction(): boolean {
		let paymentTypeId = this.selectedpayment.paymentTypeId;
		return (paymentTypeId == PaymentMethods.CreditCard || paymentTypeId == PaymentMethods.IDTECH ||
			paymentTypeId == PaymentMethods.V1GiftCard || paymentTypeId == PaymentMethods.ExternalGiftCard ||
			paymentTypeId == PaymentMethods.V1GiftCardIdTech || paymentTypeId == PaymentMethods.ExternalGiftCardIdTech ||
			paymentTypeId == PaymentMethods.AgilysysGiftCard || paymentTypeId == PaymentMethods.AgilysysGiftCardIdTech)
	}

	SelectedPaymentMethodEquals(method: PaymentMethods) {
		return (this.selectedpayment.paymentTypeId === method || this.selectedpayment.parentTypeId === method)
	}
	
	async validateResortFolioCreditLimit(){
		this.currentPaymentPostfolioData = this.postToFolioInfo;
		let currentOutStandingBalanceForSelectedFolio = this.localization.currencyToSQLFormat(this.postfolioData.outstandingBalance);
		let currentCreditLimitForSelectedFolio = this.localization.currencyToSQLFormat(this.postfolioData.balance);		
		let estimatedRemainingCreditLimit = currentCreditLimitForSelectedFolio - currentOutStandingBalanceForSelectedFolio;
		let sessionValue = sessionStorage.getItem('FolioConfigurationSwitches');		
		let config = sessionValue ? JSON.parse(sessionValue) : null;		
		if (config?.configValue) {
			var configValues = JSON.parse(config.configValue),
			  result = Object.keys(configValues).map(k => ({
			    configurationKey: [k][0],
			    configurationValue: configValues[k]
			  }));
			}
		if (result && result.length > 0) {			
        	if(result.find(x => x.configurationKey == MiscConfigurationSwitches.validateCreditLimit)?.configurationValue == true){
			this.isCreditLimitPopUpShow = true
		  }
		}
		if(!currentCreditLimitForSelectedFolio && !this.IsRefundToPostToFolio && this.isCreditLimitPopUpShow){
			this.utils.showAlert(this.ShopCaptions.ResortFolioUnAuthorized, AlertType.Warning, ButtonType.YesNo, (res) => {
				if (res === AlertAction.YES) {
					this.ProceedPayment();
				}
			});
		}
		else if((estimatedRemainingCreditLimit < this.AmountEntered) && !this.IsRefundToPostToFolio && this.isCreditLimitPopUpShow){
			this.utils.showAlert(this.ShopCaptions.ResortFolioLowCreditLimit, AlertType.Warning, ButtonType.YesNo, (res) => {
				if (res === AlertAction.YES) {
					this.ProceedPayment();
				}
			});
		}		 
		else{
			this.ProceedPayment();			
		}
	}


	CheckResortFinanceCreditCartAuthFlow(isPaymentMethodSelected = false){		
		if(this.IsResortFinanceFolioAuthFlow){
			if(!isPaymentMethodSelected){
				this.selectedpayment.paymentTypeId = Number(this.AdditionalInputs?.paymentType??'0');
			}
			this.isResortFinanceCreditCartAuthFlow = (this.SelectedPaymentMethodEquals(this.PayMethodConst.CreditCard) ||
                      this.SelectedPaymentMethodEquals(this.PayMethodConst.IDTECH)) ||
                      this.SelectedPaymentMethodEquals(this.PayMethodConst.CardOnFile);
			if(this.AdditionalInputs.creditLimit && !isPaymentMethodSelected){
				this.transitionDetails.controls.creditLimit.setValue(this.localization.localizeCurrency(this.AdditionalInputs.creditLimit, false));
			}		  
		}
	}

	PrefillValuesBasedOnResortFinanceInput(){						
		if(this.resortfolioInput?.guestLastName && this.IsResortFinanceMakePaymentFlow){
			this.SelectedRoomNo = this.resortfolioInput?.guestLastName;
			this.isDefaultRoomLookUpByName = true;
		}
		else{
			this.isDefaultRoomLookUpByName = false;				
		}
	}

	viewFolioInNewTab(settlement) {
		if (this.folioMenubp) {
			if (settlement) {
				let folioInput = {
					sourceType: settlement?.folioSourceType,
					sourceTypeId: settlement?.folioSourceTypeId,
					folioNumber: settlement?.folioInvoiceNumber,
					fullName: settlement?.folioGuestName,
					interfaceGuestId: settlement?.interfaceGuestId
				};
				localStorage.setItem("checkFromNewTab", JSON.stringify(folioInput));
			}
			else {
				let folioInput = {
					sourceType: this.postToFolioInfo?.selectedFolio?.sourceType,
					sourceTypeId: this.postToFolioInfo?.selectedGuest?.guestId,
					folioNumber: this.postToFolioInfo?.selectedGuest?.folioNumber,
					fullName: this.postToFolioInfo?.selectedGuest?.name,
					interfaceGuestId: this.postToFolioInfo?.selectedFolio?.interfaceGuestId
				};
				localStorage.setItem("checkFromNewTab", JSON.stringify(folioInput));
			}

			let productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
			let url;
			if (productId == Product.SPA) {
				url = '/Spa/folio/foliosearch';
			} else {
				url = '/Golf/folio/foliosearch';
			}
			this.route.navigate([], {
			}).then(result => { window.open(url, '_blank'); });
		} else {
			this.userAccessService.showBreakPointPopup(this.localization.captions[UserAccessBreakPoints.FOLIOMENU]);
		}
	}

	private setAdditionalInput(val: any) {
        if (val) {
            const currentProductId = Number(this.utils.GetPropertyInfo('ProductId'));
            this.isFromPMS = currentProductId == Product.PMS;
            if (val?.isMemberPayment && val?.memberNumber && val?.sourceTypeId && this.isFromPMS) {
                this.sourceTypeId = val?.sourceTypeId;
                const memberNumber = val?.memberNumber;
                this._memberService.getMemberInfo(memberNumber, this._memberService.getScheduleDateInUTC([])
                ).then(_memberInfo => {
                    this.payeeInfo = _memberInfo;
                    this._ss.memberCardNumber = memberNumber;
                    this._memberService.setAvalableRounds(_memberInfo);
                    this._ss.isMemberNotActive = Boolean(_memberInfo.membershipStatus && _memberInfo.membershipStatus.toUpperCase() != "ACTIVE");
                    this._memberService.EnableDisableARPostandRedeemRoundPaymentMethod(this);
                });
            }
            if (val?.sourceTypeId > 0) {
                this.getstayPaymentCreditCardInfos(val.sourceTypeId);
            }
        }
    }
	private setTerminalDefault(): void {
		if (!this.terminalList || this.terminalList.length === 0) {
			return; // No terminals available, unable to set default
		}
	
		const activeTerminal = this.terminalList.find(terminal => terminal.isActive);
	
		if (activeTerminal && this.terminalList.length === 1) {
			const selectedTerminalId = activeTerminal.terminalId;
			this.transitionDetails.get('selectedTerminal').setValue(selectedTerminalId);
			this.changeTerminal({ terminalId: selectedTerminalId });
			return; // Default terminal set successfully
		}
	
		return; // Default terminal not set
	}
}
