import { Injectable } from "@angular/core";
import { API, UI, ConfigureDateTimeRulesBy, DaysOfWeek } from "./configure-date-time.model";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { BaseResponse } from '../../shared/business/shared.modals';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
// import { element } from "protractor";


@Injectable()
export class ConfigureDateTimeBusiness {
    captions: any;
    editData: API.DiscountTypeConfiguration[];
    discountTypeConfiguration: API.DiscountTypeConfiguration[];
    discountTypeConfigView: API.DiscountTypeConfigurationView[];
    discountTypes = [];

    constructor(private localization: RetailLocalization, private _utilities: CommonUtilities, private http: HttpServiceCall, private utils: RetailUtilities) {
        this.captions = this.localization.captions;
    }

    getperiodTypes() {
        return [
            {
                id: ConfigureDateTimeRulesBy.Hour,
                value: 1,
                viewValue: this.captions.lbl_applyRulesToHour_discount,
                isDisabled: false
            },
            {
                id: ConfigureDateTimeRulesBy.Week,
                value: 2,
                viewValue: this.captions.lbl_applyToWeek_discount,
                isDisabled: false
            }
        ]
    }
    getWeekList() {
        return [
            {
                id: DaysOfWeek.Monday,
                value: "M",
                viewVal: "Mon",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 1
            },
            {
                id: DaysOfWeek.Tuesday,
                value: "T",
                viewVal: "Tue",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 2
            },
            {
                id: DaysOfWeek.Wednesday,
                value: "W",
                viewVal: "Wed",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 3
            },
            {
                id: DaysOfWeek.Thursday,
                value: "T",
                viewVal: "Thu",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 4
            },
            {
                id: DaysOfWeek.Friday,
                value: "F",
                viewVal: "Fri",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 5
            },
            {
                id: DaysOfWeek.Saturday,
                value: "S",
                viewVal: "Sat",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 6
            },
            {
                id: DaysOfWeek.Sunday,
                value: "S",
                viewVal: "Sun",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 0
            },
        ]
    }

    async save(data: API.DiscountTypeConfiguration[]) {
        this._utilities.ToggleLoader(true);
        const response = await this.InvokeServiceCallAsync("CreateDiscountTypeConfiguration", myGlobals.Host.retailManagement, HttpMethod.Post, '', data);
        return response;
    }



    async update(data: API.DiscountTypeConfiguration[]) {
        this._utilities.ToggleLoader(true);
        const response = await this.InvokeServiceCallAsync("UpdateDiscountTypeConfiguration", myGlobals.Host.retailManagement, HttpMethod.Put, '', data);
        return response;
    }

    async getDiscountTypeConfiguration(discountId) {
        this._utilities.ToggleLoader(true);
        const response = await this.InvokeServiceCallAsync("GetDiscountTypeConfigurationById", myGlobals.Host.retailManagement, HttpMethod.Get, { discountTypeId: discountId });
        return response
    }

    async DeleteDiscountTypeConfigurationById(ids) {
        this._utilities.ToggleLoader(true);
        const response = await this.InvokeServiceCallAsync("DeleteDiscountTypeConfigurationByIds", myGlobals.Host.retailManagement, HttpMethod.Delete, '', ids);
        return response
    }
    async AddDiscountType(discountTypeObj) {
        let res: any = await this.http.CallApiAsync<any>({
            host: myGlobals.Host.retailManagement,
            callDesc: "CreateDiscountType",
            method: HttpMethod.Post,
            body: discountTypeObj
        });
        let response = <any>res.result;
        this.discountTypes = [];
        response.forEach(element => {
            let discountType: any = {
                id: element.id,
                discountType: element.type,
                active: element.isActive,
                listOrder: element.listOrder,
                enableDiscountRules: element.enableDiscountRules
            }
            this.discountTypes.push(discountType);
        });
        let id = response.filter(x => x.type == discountTypeObj.Type)[0].id;
        return id;
    }
    async updateDiscountType(discountTypeObj) {
        let res: any = await this.http.CallApiAsync<any>({
            host: myGlobals.Host.retailManagement,
            callDesc: "UpdateDiscountType",
            method: HttpMethod.Put,
            body: discountTypeObj,
            uriParams: { id: discountTypeObj.id }
        });
        let response = <any>res.result;
        return response
    }
    async getDiscountTypeConfigView(discountId) {
        this._utilities.ToggleLoader(true);
        let res: any = await this.http.CallApiAsync<any>({
            host: myGlobals.Host.retailManagement,
            callDesc: "GetDiscountTypeConfigurationById",
            method: HttpMethod.Get,
            uriParams: { discountTypeId: discountId }
        });
        let response = <any>res.result;
        let data:API.DiscountTypeConfigurationView[] = []
        response.forEach(element => {
            let startDate = this.localization.getDate(element.startTime);
            let endDate = this.localization.getDate(element.endTime);
            let group = data.find(x=>this.localization.getformattedDateDDMMYYYY(x.startDate) === this.localization.getformattedDateDDMMYYYY(startDate) && this.localization.getformattedDateDDMMYYYY(x.endDate) === this.localization.getformattedDateDDMMYYYY(endDate));
            let ruleTemp: API.DiscountTypeConfiguration = {
                daysOfWeek: element.daysOfWeek,
                discountTypeId: element.discountTypeId,
                endTime: element.endTime,
                startTime: element.startTime,
                id: element.id,
                isDeleted: element.isDeleted,
                rules: element.rules 
            }
            if(group)
            {
                group.rules.push(ruleTemp);
            }
            else
            {
                let temp:API.DiscountTypeConfigurationView = 
                {
                    startDate: startDate,
                    endDate: endDate,
                    rules:[ruleTemp]
                }
                data.push(temp);
            }
        });
        this.discountTypeConfigView = data;
        return this.discountTypeConfigView;
    }
    async InvokeServiceCall(route: string, domain: myGlobals.Host.retailManagement, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
        this.http.CallApiWithCallback<any>({
            host: domain,
            success: await this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: route,
            method: callType,
            body: body,
            showError: true,
            extraParams: extraParams,
            uriParams: uriParams
        });
    }
    async InvokeServiceCallAsync(route: string, domain: myGlobals.Host.retailManagement, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
        try {
            return await this.http.CallApiAsync({
                host: domain,
                callDesc: route,
                method: callType,
                body: body,
                uriParams: uriParams,
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): Promise<void> {
        if (callDesc == "GetDiscountTypeConfigurationById" || callDesc == "UpdateDiscountTypeConfiguration") {
            let response = <any>result.result;
            this.discountTypeConfiguration = [];
            response.forEach(element => {
                let discountTypeConfiguration: API.DiscountTypeConfiguration = {
                    id: element.id,
                    discountTypeId: element.DiscountTypeId,
                    startTime: element.StartTime,
                    endTime: element.EndTime,
                    rules: element.Rules,
                    daysOfWeek: element.DaysOfWeek,
                    isDeleted: element.IsDeleted,
                }
                this.discountTypeConfiguration.push(discountTypeConfiguration);
            });
            console.log(response);
            this.utils.ToggleLoader(false);
        }
    }



    errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        console.error(error.result);
        this._utilities.ToggleLoader(false)
    }

}