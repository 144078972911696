<div class="confirm">
<div class="confirm__reservation-details">
  <span class="confirm__details-label">Guest Name</span>
  <span class="confirm__details-label">Old Table Number</span>
  <span class="confirm__details-data">{{data.Party?.Contact?.FirstName}}</span>
  <span class="confirm__details-data">{{data.Id}}</span>
</div>
<div class="confirm__details-form">
  <!-- <dynamic-form [config]="config" #form="dynamicForm" >
  </dynamic-form> -->
</div>
</div>