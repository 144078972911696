import { Component, OnInit, ViewChild, Input, ViewEncapsulation, Output, EventEmitter, TemplateRef } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { HeaderOptionInteface, tableOptionInteface, SorTypeenum, FromTypeenum } from './tablevirtualscroll.model';

@Component({
  selector: 'app-shop-tablevirtualscroll',
  templateUrl: './tablevirtualscroll.component.html',
  styleUrls: ['./tablevirtualscroll.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TablevirtualscrollComponent implements OnInit {
  @ViewChild('scroll') scrollvalue;

  @ViewChild('inputTemplate') inputTemplate;
  @ViewChild('template1') template1;
  @ViewChild('toggleTemplate') toggleTemplate;
  @ViewChild('templateIconName') templateIconName;

  @ViewChild('edit') edit;
  @ViewChild('delete') delete;
  @ViewChild('dragdrop') dragdrop;
  @ViewChild('claim') claim;
  @ViewChild('moreoption') moreoption;


  @Input() headerOption: HeaderOptionInteface[];
  @Input() childTemplate: TemplateRef<any>;
  searchText: any;
  @Input() fromUnpaidPlayer: boolean;

  @Input()
  set bodyContentData(value) {
    if(!this.fromUnpaidPlayer)
    {
      this.checkeddata = [];
    }  
    this._bodyContentData = value;
    this.originaldata = _.cloneDeep(value);
    if (this.searchText != undefined) { this.SearchFilter(this.searchText.trim()) }
  }
  @Input() tableOptions: tableOptionInteface;
  @Input()
  set searchOptions(searchtext) { if (searchtext != undefined) { this.searchText = searchtext; this.SearchFilter(searchtext.trim()) } }
  get searchOptions() { return this.searchText; }


  @Output() EmittedData = new EventEmitter();
 
  ordertype: string;
  checkvaluechanged: any;
  checkeddata: any[] = [];
  originaldata: any;
  _bodyContentData: any;

  captions: any;


  ngOnInit() { 
    this.ordertype = SorTypeenum.asc;
    this.setcaptions();
  }
  
  setcaptions() {
    return this.captions = {
      yes: 'Yes',
      no: 'No',
      done: 'done',
      cancel: 'cancel',
      actions: 'Actions'
    }
  }

  /**
   *
   * @param key
   * @param type
   */
  sorting(key, type) {
    console.log("key,type", key, type);
    let sortype;
    if (type == SorTypeenum.asc) {
      sortype = this.ordertype = SorTypeenum.desc;
    } else {
      sortype = this.ordertype = SorTypeenum.asc;
    }
    this._bodyContentData = _.orderBy(this._bodyContentData, key, sortype);
  }

  /**
   *
   * @param eve
   * @param data
   */
  valueChanged(eve, data, HdrData) {
    console.log("valueChanged", eve, data, HdrData);
    let q = _.find(this._bodyContentData, data);
      q[HdrData['key']] = eve;
    this.checkvaluechanged = data;
  }
  isAllChecked()
  {
    let dataLength = this._bodyContentData.filter(x=> !(this.fromUnpaidPlayer && x.isHold)).length
    return dataLength > 0 && (this.checkeddata.length == dataLength);
  }

  /**
   *
   * @param eve
   * @param data
   */
  done(eve, data) {
    console.log("valueChanged", eve, data);
    this.checkvaluechanged = '';
    this.EmittedData.emit({
      'fromType': FromTypeenum.done,
      'array': '',
      'value': '',
      'Obj': data
    })
  }

  /**
   *
   * @param eve
   * @param data
   */
  cancel(eve, data) {
    console.log("valueChanged", eve, data);
    this.checkvaluechanged = ''; 
    this._bodyContentData = _.cloneDeep(this.originaldata);
    this.EmittedData.emit({
      'fromType': FromTypeenum.cancel,
      'array': '',
      'value': '',
      'Obj': data
    })
  }

  /**
   *
   * @param eve
   */
  emitcheckAlldata(eve) {
    console.log(eve.checked)
    this.checkeddata = [];
    if (eve.checked) {
      this.checkeddata = _.cloneDeep(this.originaldata.filter(x=> !(this.fromUnpaidPlayer && x.isHold)));
    } else {
      this.checkeddata = [];
    }

    console.log("emitcheckAlldata", this.checkeddata);
    this.EmittedData.emit({
      'fromType': FromTypeenum.allcheckbox,
      'array': '',
      'value': '',
      'Obj': '',
      'checkedData': this.checkeddata
    })
  }

  /**
   *
   * @param eve
   * @param data
   */
  emitcheckdata(eve, data) {
    if (eve.checked) {
      this.checkeddata.push(data);
    } else {
      _.remove(this.checkeddata, data);
    }
    console.log("emitcheckdata", this.checkeddata);
    this.EmittedData.emit({
      fromType: FromTypeenum.rowcheck,
      'array': '',
      'value': '',
      'Obj': data,
      'checkedData': this.checkeddata
    })
  }

  /**
   *
   * @param eve
   * @param data
   */
  emitEditrowdata(eve, data) {
    console.log("emitEditrowdata", data);
    this.EmittedData.emit({
      'fromType': FromTypeenum.edit,
      'array': '',
      'value': '',
      'Obj': data,
      'checkedData': this.checkeddata
    })
  }

  /**
   *
   * @param eve
   * @param data
   */
  emitDeleterowdata(eve, data) {
    console.log("emitDeleterowdata", data); 
    this.EmittedData.emit({
      'fromType': FromTypeenum.delete,
      'array': '',
      'value': '',
      'Obj': data,
      'checkedData': this.checkeddata
    })
  }

  /**
   *
   * @param eve
   * @param data
   */
  emitClaimrowdata(eve, data) {
    console.log("emitDeleterowdata", data); 
    this.EmittedData.emit({
      'fromType': FromTypeenum.claim,
      'array': '',
      'value': '',
      'Obj': data,
      'checkedData': this.checkeddata
    })
  }

  /**
   *
   * @param eve
   */
  emitmoreoptiondata(eve,from,rowdata){
    console.log("emitDeleterowdata", eve,from,rowdata);
  }

  /**
   *
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {

    let draggedoj = this.scrollvalue.viewPortItems[event.previousIndex];
    let droppeddoj = this.scrollvalue.viewPortItems[event.currentIndex];
 
    let data = {
      'dragdata': draggedoj,
      'dropdata': droppeddoj
    }
    
    this.EmittedData.emit({
      'fromType': FromTypeenum.dragdrop,
      'array': '',
      'value': '',
      'Obj': data
    })
  }


  SearchFilter(searchText: string): any {
    let headerarray = this.headerOption.filter(x => x.searchable).map(x => x.key);
     
    if (headerarray.length > 0) {
      this._bodyContentData = this.originaldata.filter(result => {
        const headerKey = headerarray;
        for (let key in result) {
          if (typeof (result[key]) == 'string' && result[key].toLowerCase().includes(searchText.toLowerCase())) {
            if (headerKey.indexOf(key) != -1) {
              return result[key].toLowerCase().includes(searchText.toLowerCase());
            }
          }
          else if (typeof (result[key]) == 'number') {
            if (headerKey.indexOf(key) != -1) {
              let matchedValue = Number(result[key].toString().toLowerCase().includes(searchText.toLowerCase()));
              if (matchedValue) {
                return matchedValue;
              }
            }
          }
        }
      });
    }
  }

}
