import { AfterViewInit, Component, EventEmitter, Inject, OnChanges, OnDestroy, OnInit, Output, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { SelectServerComponent } from '@components/select-server/select-server.component';
import { buttonTypes, ComponentTypes, Labels } from '@constants/commonenums';
import { popupDialogDimension, serverActions } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { LayoutDTO, ServerDTO, SettingsDTO, StandaloneTableDTO, TableServerDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '@popup-module/popup.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import { ServerService } from '@services/server.service';
import { Utilities } from '@utilities/utilities';
import * as _ from 'lodash';
import { ISubscription, Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-server-view',
  templateUrl: './server-view.component.html',
  styleUrls: ['./server-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServerViewComponent extends Utilities implements OnInit, AfterViewInit, OnDestroy {
  public filterOptions: any = [
    {
      name: 'Shift',
      options: [
        {
          optionName: 'All',
          status: false,
        },
        {
          optionName: 'Morning',
          status: false,
        },
        {
          optionName: 'Night',
          status: true,
        },
        {
          optionName: 'Snacks',
          status: true,
        },
        {
          optionName: 'Brunch',
          status: true,
        },

      ]
    },
    {
      name: 'Status',
      options: [
        {
          optionName: 'OffDuty',
          status: true,
        },
        {
          optionName: 'OnDuty',
          status: true,
        }
      ]
    }
  ];
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  @Output() editServerTables = new EventEmitter<EmitterActions>();
  searchConfig: FieldConfig[];
  serverInfoHolder: ServerDTO[] = [] as ServerDTO[];
  filterDetails: any = [];
  arrayLength = 0;
  editServer = false;
  isOffDuty = false;
  tableCountNumber: any;
  text: string = "";
  editIndex: any;
  assignBtn: ButtonValue;
  editBtn: ButtonValue;
  switchServerBtn: ButtonValue;
  offDutyBtn: ButtonValue;
  doneBtn: ButtonValue;
  cancelBtn: ButtonValue;
  editableServerId: any = -1;
  confirmSubscription: ISubscription;
  tableSubscription: ISubscription;
  serverOffDuty: ServerDTO;
  serverSwitchDetails: ServerDTO;
  serverEmitterAction = {} as EmitterActions;
  showNavigation = false;
  defaultSort = 'asc';
  isAscending = true;
  allAvailableTables: StandaloneTableDTO[] = [];
  _layout: LayoutDTO = {} as LayoutDTO;
  _settings: SettingsDTO = {} as SettingsDTO;
  serverSubscription: any;
  currentLayoutId: number;
  subscriptions: Subscription = new Subscription();
  constructor(@Inject(MatDialog) public dialog: MatDialog, private ss: ServerService, private ts: TranslateService,
    private popupService: PopupService, private appService: AppService, private partyService: PartyService,
    private gbs: GuestBookService, public cs: CacheService) {
    super(dialog);
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
    this.subscriptions.add(cs.layout.subscribe(layt => {
      this._layout = layt;
    }));
    this.currentLayoutId = this.ss.currentLayoutId;
  }
  ngOnInit() {
    this.subscriptions.add(this.ss.serverDetails.subscribe(details => {
      const tempServerList = details.filter(x => !x.IsTemplate);
      this.isAscending ? this.sortServersByAscending(tempServerList) : this.sortServersByDescending(tempServerList);
    }));
    this.searchConfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.ts.instant('serverSearchText'),
        class: 'server-view__search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this)
      }
    ];
    this.editBtn = {
      type: buttonTypes.actionPrimarySmall,
      label: 'serverEditText',
      disbaledproperity: false,
      customclass: 'edit',
    };
    this.assignBtn = {
      type: buttonTypes.actionPrimarySmall,
      label: 'startShift',
      disbaledproperity: false,
      customclass: 'assign',
    };
    this.offDutyBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: 'serverOffDutyText',
      disbaledproperity: false
    };
    this.switchServerBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: 'serverSwitchServer',
      disbaledproperity: false,
      customclass: 'server-view__serverSwitch-btn',
    };
    this.doneBtn = {
      type: buttonTypes.actionPrimarySmall,
      label: 'serverDoneText',
      disbaledproperity: true,
      customclass: 'server-view__done-btn'
    };
    this.cancelBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: 'cancel',
      disbaledproperity: false,
      customclass: 'server-view__cancel',
    };
    this.allAvailableTables = Utilities.getRestaurantTables(this._layout.FloorPlans);
  }

  ngAfterViewInit() {
    this.dynamicFormChanges();
    this.layoutTableSelectionChange();
    setTimeout(() => {
      this.virtualScrollHeightCalculation();
    });
  }

  virtualScrollHeightCalculation() {
    const tabName = `server-view-list`;
    const tabHeight = document.getElementById(tabName);
    const virtualScrollDiv = document.getElementById(`server-view-grid`);
    const windowHeight = window.innerHeight;
    const headerHeight: any = document.getElementsByClassName('layout__header').length > 0 ?
      document.getElementsByClassName('layout__header')[0]['offsetHeight'] : 0;
    const dashboardHeader = document.getElementsByClassName('dashboard-header');
    const dashboardHeaderHeight = dashboardHeader.length > 0 ? dashboardHeader[0]['offsetHeight'] : 0;
    const matGroupHeader = document.getElementsByClassName('mat-tab-label-container');
    const matGroupHeaderHeight = matGroupHeader.length ? matGroupHeader[0]['offsetHeight'] : 0;
    const dashboardActions = document.getElementsByClassName('dashboard__actions');
    const dashboardActionsHeight = dashboardActions.length ? dashboardActions[0]['offsetHeight'] : 0;
    const estimatedHeight = windowHeight - (headerHeight + dashboardHeaderHeight + matGroupHeaderHeight + dashboardActionsHeight);
    if (virtualScrollDiv) {
      virtualScrollDiv.style.maxHeight = `${estimatedHeight}px`;
    }
    this.reassignServersAction();
  }

  trackFunction(index) {
    return index;
  }

  layoutTableSelectionChange() {
    this.tableSubscription = this.partyService.tableSelected.subscribe((isEdited) => {
      this.doneBtn.disbaledproperity = !isEdited;
      if (this.ss.selectedTableIds) {
        this.serverInfoHolder.filter((server) => server.Id === this.editableServerId).forEach((server) => {
          server.StandaloneTables = [];
          let selectedStandAloneTable = {} as StandaloneTableDTO;
          this.ss.selectedTableIds.forEach((table) => {
            const tableInfo = this.allAvailableTables.filter((availableTable) => availableTable.Id === table);
            if (tableInfo.length > 0) {
              selectedStandAloneTable = tableInfo[0];
              server.StandaloneTables.push(selectedStandAloneTable);
            }
          });
        });
      }
    });
  }

  dynamicFormChanges() {
    let serverDetailSubscription: ISubscription;
    this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe(val => {
      if (serverDetailSubscription) {
        serverDetailSubscription.unsubscribe();
      }
      this.subscriptions.add(serverDetailSubscription = this.ss.serverDetails.subscribe(details => {
        if (val.searchText) {
          this.serverInfoHolder = [];
          this.text = val.searchText.toLowerCase();
          this.serverInfoHolder = details.filter(x => (x.Name.toLowerCase()).includes(this.text));
        } else {
          this.text = "";
          this.serverInfoHolder = [...details];
        }
        this.serverInfoHolder = this.serverInfoHolder.filter(x => !x.IsTemplate);
        this.reassignServersAction();
      }));
    }));
  }
  reassignServersAction(){
    if(this.switchServerBtn && this.serverInfoHolder && this.serverInfoHolder.length === 1){
      this.switchServerBtn.disbaledproperity = true;
    }else if(this.switchServerBtn){
      this.switchServerBtn.disbaledproperity  = false;
    }
  }
  popupConfirmationActions() {
    this.confirmSubscription = this.popupService.confirmedAction$.subscribe((data) => {
      if (data === ComponentTypes.server) {
        const floorPlan = this._layout.FloorPlans;
        const layoutId = floorPlan.filter((floor) => floor.Id == this.currentLayoutId)[0].Id;
        if (this.isOffDuty) {
          this.subscriptions.add(this.ss.setServerOffDuty(layoutId, this.serverOffDuty.Id).subscribe(() => {
            this.setServerEmitActions(null, serverActions.offDuty);
          }));
        } else {
          let mappedTables: StandaloneTableDTO[] = [] as StandaloneTableDTO[];
          this.serverSubscription = this.ss.serverDetails.subscribe(details => {
            details.filter(s => s.Id == this.serverSwitchDetails.Id).forEach(_s => {
              mappedTables = _s.StandaloneTables;
              _s.StandaloneTables = [];
            });
            details.filter(s => s.Id == this.gbs.selectedServers.Id).forEach(_s => {
              //_s.StandaloneTables = mappedTables;
              mappedTables.forEach(table => _s.StandaloneTables.push(table));
            });
          });
          this.subscriptions.add(this.ss.switchServer(layoutId, this.serverSwitchDetails.Id, this.gbs.selectedServers.Id).subscribe((response) => {
            if (this.serverSubscription) this.serverSubscription.unsubscribe();
            const confirmationMessages = Utilities.getConfirmationMessage(response,
              this.ts.instant(Labels[Labels.switchserverconfirmationtext]));
            const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', confirmationMessages,
              confirmationMessages[0].dialogTitle);
            const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.switchServer,
              popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, false, '', this.translateService.instant('ok'), '', true);
            this.subscriptions.add(dialogRef.afterClosed().subscribe((dialogCloseData) => {
              this.setServerEmitActions(null, serverActions.switchServer);
            }));
          }));
        }
      }
    });
  }
  ngOnDestroy() {
    this.ss.editableMode = false;
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
    if (this.tableSubscription) {
      this.tableSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  clearSearch() {
    this.text = "";
    this.dynamicForm.form.get('searchText').reset();
  }
  selectedFilter(item) {
    item.status = !item.status;
  }

  switchServer(switchServer: ServerDTO) {
    this.popupConfirmationActions();
    this.serverSwitchDetails = switchServer;
    this.isOffDuty = false;
    let serversList: ServerDTO[] = [] as ServerDTO[];
    this.subscriptions.add(this.ss.serverDetails.subscribe(details => {
      serversList = details.filter((server: ServerDTO) => server.Id !== switchServer.Id);
    }));
    const title = this.ts.instant('serverSwitch');
    const update = this.ts.instant('switchServer');
    const cancel = this.ts.instant('cancel');
    this.gbs.selectedServers = null;
    const componentDetails = Utilities.setComponentDetails(SelectServerComponent, 'large', 'active', serversList);
    const switchDialogRef = this.openCustomPopup(componentDetails, ComponentTypes.server, '90%', '90%', false, title, update, cancel, false, null, null, null, false);
    switchDialogRef.afterClosed().subscribe(() => {
        if (this.confirmSubscription) {
          this.confirmSubscription.unsubscribe();
        }
    })
  }

  offDuty(server: ServerDTO) {
    this.serverOffDuty = server;
    this.isOffDuty = true;
    const layoutId = this.currentLayoutId; //Utilities.getLayoutId(this._layout.FloorPlans);
    this.serverSubscription = this.ss.serverDetails.subscribe(_detail => {
      _detail.filter(s => s.Id == server.Id).forEach(_s => {
        _s.StandaloneTables = [];
      });
    });
    this.subscriptions.add(this.ss.setServerOffDuty(layoutId, this.serverOffDuty.Id).subscribe(() => {
      if (this.serverSubscription) this.serverSubscription.unsubscribe();
      this.setServerEmitActions(null, serverActions.offDuty);
    }));
    // Popup is not for this release [Build 3]
    // const title = this.ts.instant('offDutyAlert');
    // const update = this.ts.instant('confirm');
    // const cancel = this.ts.instant('cancel');
    // const componentDetails = Utilities.setComponentDetails(OffDutyComponentComponent, 'large', 'active', server);
    // const dialogRef = this.openCustomPopup(componentDetails, ComponentTypes.server, '50%', '50%', false, title, update, cancel);
    // dialogRef.afterClosed().subscribe(() => {
    //   this.serverOffDuty = null;
    // });
  }
  assignServer(event, server) {
    this.editableServerId = server.Id;
    this.ss.editableMode = true;
    this.setServerEmitActions(server, serverActions.assignServer);
  }
  editTables($event, server) {
    this.ss.selectedTableIds = [];
    this.ss.unselectedTableIds = [];
    this.editableServerId = server.Id;
    this.ss.editableMode = true;
    this.setServerEmitActions(server, serverActions.editData);
  }
  resetFilter() {
    this.filterOptions.forEach(element => {
      element.options.forEach(option => {
        option.status = false;
      });
    });

  }
  done() {
    // this.ss.editableMode = false;
    // this.doneBtn.disbaledproperity = true;
    const serverDetails = this.serverTablesRequest();
    if (serverDetails.length > 0) {
      this.subscriptions.add(this.ss.assignServers(serverDetails).subscribe((data) => {
        this.ss.editableMode = false;
        this.doneBtn.disbaledproperity = true;
        this.ss.resetSelectionArrayEvent.next();
        this.changeEditMode(serverActions.done);
      }));
    }
    else {
      this.subscriptions.add(this.ss.setServerOffDuty(this.currentLayoutId, this.editableServerId).subscribe(() => {
        this.ss.editableMode = false;
        this.doneBtn.disbaledproperity = true;
        this.ss.resetSelectionArrayEvent.next();
        this.changeEditMode(serverActions.done);
      }));
    }
  }

  serverTablesRequest(): TableServerDTO[] {
    let tableServerDetails: TableServerDTO[] = [];
    this.ss.selectedTableIds.forEach((table) => {
      const tableDetails = {} as TableServerDTO;
      tableDetails.ServerId = this.editableServerId;
      tableDetails.StandaloneTableId = table;
      if (tableDetails.ServerId != null) {
        tableServerDetails.push(tableDetails);
      }
    });
    this.ss.unselectedTableIds.forEach((table) => {
      const tableDetails = {} as TableServerDTO;
      tableDetails.ServerId = null;
      tableDetails.StandaloneTableId = table;
      tableServerDetails.push(tableDetails);
    });
    return tableServerDetails;
  }

  cancel() {
    //this.serverInfoHolder = _.cloneDeep(this.serverList);
    this.doneBtn.disbaledproperity = true;
    this.ss.updateServerMappings(this.cs.settings.value.Servers, this.cs.layout.value.FloorPlans);
    this.ss.resetSelectionArrayEvent.next();
    this.changeEditMode(serverActions.cancel);
  }

  changeEditMode(actionType) {
    this.ss.editableMode = false;
    this.editableServerId = -1;
    this.setServerEmitActions(null, actionType);
  }

  setServerEmitActions(data: any, actionType: serverActions) {
    this.serverEmitterAction.data = data;
    this.serverEmitterAction.action = actionType;
    this.editServerTables.emit(this.serverEmitterAction);
  }

  sort() {
    if (this.defaultSort === 'asc') {
      this.defaultSort = 'desc';
      this.isAscending = false;
      this.sortServersByDescending(this.ss.serverDetails.value);
    } else {
      this.defaultSort = 'asc';
      this.isAscending = true;
      this.sortServersByAscending(this.ss.serverDetails.value);
    }
  }

  sortServersByAscending(serverDetails) {
    const serverWithTables = serverDetails.filter((server) => server.StandaloneTables != null && server.StandaloneTables.length > 0
      && server.Name.toLowerCase().includes(this.text)).sort((server1, server2) => server1.Name.localeCompare(server2.Name));
    const serverWithoutTables = serverDetails.filter((server) => server.StandaloneTables == null || server.StandaloneTables.length === 0
      && server.Name.toLowerCase().includes(this.text)).sort((server1, server2) => server1.Name.localeCompare(server2.Name));
    this.serverInfoHolder = [...serverWithTables, ...serverWithoutTables];
    this.reassignServersAction();
  }

  sortServersByDescending(serverDetails) {
    const serverWithTables = serverDetails.filter((server) => server.StandaloneTables != null && server.StandaloneTables.length > 0
      && server.Name.toLowerCase().includes(this.text)).sort((server1, server2) => server2.Name.localeCompare(server1.Name));
    const serverWithoutTables = serverDetails.filter((server) => server.StandaloneTables == null || server.StandaloneTables.length === 0
      && server.Name.toLowerCase().includes(this.text)).sort((server1, server2) => server2.Name.localeCompare(server1.Name));
    this.serverInfoHolder = [...serverWithTables, ...serverWithoutTables];
    this.reassignServersAction();
  }

}



@Pipe({
  name: 'showCount'
})
export class ShowCountPipe implements PipeTransform {
  transform(options: any): any {
    const filteredData = options.filter(x => x.status == true);
    return filteredData.length;
  }

}

export class EmitterActions {
  data: any;
  action: serverActions;
}
