import { ClassType, PartyState, PartyType, PaymentMethod,LessonType } from '../constants/commonenums';
import { AddOnRequest, StandByPartyDTO, WalkInDTO } from './InputReservation';
import { BookedSessionDTO, BookingAmountsDTO, BookingContactAddonItemDTO, MemberInfoBase, PartyMessageDTO, SecondaryContactDTO } from './RestaurantDTO';

export class InputContact {
  public RestaurantId: number;
  public InputContactModel: FullUpdatedContactDTO;
}
export class UpdatedContactDTO {
  public Id?: number;
  public FirstName: string;
  public LastName: string;
  public PhoneNumber: string;
  public PhoneNumber2: string;
  public EmailAddress: string;
  public Birthday: Date;
  public Anniversary: Date;
  public IsVip: boolean;
  public IsFavorite: boolean;
  public IsFeedback: boolean;
  public SiteUserId: number;
  public TrackMembershipNumber: string;
  public PreferredPageMethod: PageMethod;
  public GuestProfileServiceId: string;
  public Notes: ContactNoteDTO[];
  public ContactCustomFields: CustomFieldsDTO[];
  public Photo: string;
  public ShouldUpdatePhoto: boolean;
  public PIILastUsedDate: string;
  public ConsentDate: string;
  public ConsentPolicyId: number;
  public CountryId?: number;
  public GuestTitle: GuestTitle = 0;
  public MarketingOptedIn?: boolean;
  public EmailAddress2?: string;
  public CountryId2?: number;
  public AliasName : string;
  public MemberInfo?: MemberInfoBase
}
export class FullUpdatedContactDTO extends UpdatedContactDTO {
  public BillingPreferences: string;
  public PreferredTableId: number;
  public PreferredServerId: number;
  public PartnerName: string;
  public PartnerBirthday: Date;
  public Addresses: ContactAddressDTO[];
}
export class ContactAddressDTO {
  public Id: number;
  public Name: string;
  public Street1: string;
  public Street2: string;
  public City: string;
  public Country: string;
  public State: string;
  public PostalCode: string;
}
export class ContactNoteDTO {
  public Id: number;
  public Text: string;
  public Label: string;
  public RelatedId: number;
  public IsDietaryRestriction: boolean;
  public RestaurantId: number;
  public Icon: string;
}

export class SMSMessageTemplateModel{
  public Message: string;
  public Code: string;
  public Name: string;
}


export enum PageMethod {
  Manual,
  Sms,
  VoiceCall,
  Email,
  Sms2,
  VoiceCall2,
  SMSProxyRelay,
  Email2
}

export enum SmsProvider
{
    Twilio,
    Platform_Twilio,
    Platform_Kakao
}

 export enum PageMethods
 {
  None,
  Manual,
  Sms,
  VoiceCall,
  Email
 }
export enum WaitTimeType {
  Auto = 0,
  Manual = 1,
  Default = 2
}

export enum GuestTitle {
  None = 0,
  "Mr" = 1,
  "Mrs",
  "Ms",
  "His Excellency Ambassador",
  "Your Excellency",
  "Dame",
  "Dato",
  "Dr",
  "Dr & Mr",
  "Dr & Mrs",
  "Governor",
  "His/Her honourable",
  "Lady",
  "Lord",
  "Madame",
  "Mayor",
  "Monsieur",
  "Mr & Mr",
  "Mr & Mrs",
  "Her Royal Highness",
  "His Royal Highness",
  "Professor",
  "Sir",
  "Miss",
  "Fr",
  "Prof",
  "Rev",
  "Congrm",
  "Congrw",
  "Sen",
  "Amb",
  "Sr",
  "Admin",
  "Mr/S",
  "Judge"
}

export class ContactDTO {
  public Id: number = null;
  public FirstName: string = null;
  public LastName: string = null;
  public AliasName: string = null;
  public IsVip: boolean = null;
  public IsFavorite: boolean = null;
  public IsFeedback: boolean = null;
  public PhoneNumber: string = null;
  public PhoneNumber2: string = null;
  public EmailAddress: string = null;
  public SiteUserId: number = null;
  public GuestScore: number = null;
  public CreatedAt: Date = null;
  public Notes: ContactNoteDTO[] = [];
  public CustomGuestField: CustomGuestFieldDTO[] = [];
  public ContactCustomFields: CustomFieldsDTO[] = [];
  public PIILastUsedDate: Date = null;
  public ConsentDate: Date = null;
  public ConsentPolicyId: number = null;
  public CountryId: number = null;
  public ContactAddress: ContactAddressDTO[] = [];
  public GuestTitle: GuestTitle = 0;
  public MarketingOptedIn?: boolean;
  public CountryId2: number = null;
  public EmailAddress2: string = null;
  public memberShipId: string = null; //card no from engage
}

export class CustomFieldsDTO {
  public Id: number;
  public ContactId: number;
  public CustomFieldId: number;
  public CustomFieldValue: string;
  public CustomFieldName: string;
}

export class CustomGuestFieldDTO {
  public Id: number;
  public MasterMerchantId: number;
  public FieldType: string;
  public FieldName: string;
  public FieldDescription: string;
  public IsCustomGuestFieldVisible: boolean;
  public FieldOptions: string;
  public MasterMerchant: MasterMerchant;
  public ContactCustomFields: any;
}

export class MasterMerchant implements IEntity, ITrackCreatedAtDateTime, ITrackUpdatedAtDateTime, INamedEntity {
  public Id: number;
  public Name: string;
  public CreatedAt: Date;
  public UpdatedAt: Date;
  public UseGuestScore: boolean;
  public GuestScoreVisits: number;
  public IntegrationTenantId: string;
  public Merchants: Merchant[];
  public Restaurants: any[];
  public Contacts: any[];
  public CustomGuestFields: any[];
}
export interface IEntity {
  Id: number;
}
export interface ITrackCreatedAtDateTime {
  CreatedAt: Date;
}
export interface ITrackUpdatedAtDateTime {
  UpdatedAt: Date;
}
export interface INamedEntity {
  Id: number;
  Name: string;
}
export interface IAuthenticatable extends IEntity {
  UserType: UserType;
  MerchantId: number;
  VerifyPassword(password: String, hashManager: IHashManager): boolean;
}
export enum UserType {
  Admin,
  MerchantUser,
  CRSUser
}
export interface IHashManager {
  GenerateSalt(): string;
  HashPassword(password: string, salt: string): string;
  VerifyPassword(password: string, hashedPass: string): boolean;
}
export interface IRestaurantEntity {
  RestaurantId: number;
}

export class Merchant implements IEntity, ITrackCreatedAtDateTime, ITrackUpdatedAtDateTime, INamedEntity {
  public Id: number;
  public Name: string;
  public MasterMerchantId: number;
  public CreatedAt: Date;
  public UpdatedAt: Date;
  public IsOnPremise: boolean;
  public LicenseKey: string;
  public EncryptedLicense: string;
  public MerchantUsers: MerchantUser[];
  public Restaurants: any[];
  public MasterMerchant: MasterMerchant;
}

export class MerchantUser implements IEntity, INamedEntity, IAuthenticatable, ITrackCreatedAtDateTime, ITrackUpdatedAtDateTime {
  constructor() {
    this.MerchantUserRestaurants = {} as MerchantUserRestaurant[];
    this.ApnsTokens = {} as ApnsToken[];
  }
  public Id: number;
  public MerchantId: number;
  public IsSuperAdmin: boolean;
  public IsTrained: boolean;
  public Name: string;
  public Email: string;
  public EncryptedPassword: string;
  public PasswordSalt: string;
  public ResetPasswordToken: string;
  public ResetPasswordSentAt: Date;
  public CreatedAt: Date;
  public UpdatedAt: Date;
  public Merchant: Merchant;
  public MerchantUserRestaurants: MerchantUserRestaurant[];
  public ApnsTokens: ApnsToken[];
  public VerifyPassword(password: string, hashManager: IHashManager): boolean {
    return hashManager.VerifyPassword(password, this.EncryptedPassword);
  }
  public get UserType(): UserType {
    return UserType.MerchantUser;
  }
  // public GenerateResetPasswordToken(): void {
  //    this.ResetPasswordToken = PasswordHelper.GenerateAlphaNumericPassword(32);
  //    this.ResetPasswordSentAt = Date.UTC;
  // }
  public ClearResetPasswordToken(): void {
    this.ResetPasswordToken = null;
    this.ResetPasswordSentAt = null;
  }
}

export class ApnsToken implements IEntity, ITrackCreatedAtDateTime, ITrackUpdatedAtDateTime {
  public Id: number;
  public RestaurantId: number;
  public Token: String;
  public MerchantUserId: number;
  public CreatedAt: Date;
  public UpdatedAt: Date;
  public Restaurant: any;
  public MerchantUser: MerchantUser;
}

export class MerchantUserRestaurant implements IEntity, IRestaurantEntity, ITrackCreatedAtDateTime, ITrackUpdatedAtDateTime {
  public Id: number;
  public MerchantUserId: number;
  public RestaurantId: number;
  public IsAdmin: boolean;
  public CreatedAt: Date;
  public UpdatedAt: Date;
  public MerchantUser: MerchantUser;
  public Restaurant: any;
}

export class CountryDTO {
  public Id: number;
  public Name: string;
  public CountryPhoneCode: string;
  Label?: string;
}

export class FullContactDTO extends ContactDTO {
  public Options: GetContactOptions = null;
  public PreferredPageMethod: PageMethod = null;
  public SecondaryContactsList: SecondaryContactDTO[] = [];
  public Birthday: Date | string = null;
  public Anniversary: Date | string = null;
  public VisitStats: ContactVisitStatsDTO[] = [];
  public FutureReservations: ReservationDTO[] = [];
  public FutureActivities: ReservationDTO[] = [];
  public PastReservations: ReservationDTO[] = [];
  public PastActivities: ReservationDTO[] = [];
  public OngoingActivities: ReservationDTO[] = [];
  public StandByList: StandByPartyDTO[] = [];
  public Walkins: WalkInDTO[] = [];
  public BillingPreferences: string = null;
  public PreferredTableId: number = null;
  public PreferredServerId: number = null;
  public PartnerName: string = null;
  public PartnerBirthday: Date = null;
  public TrackMembershipNumber: string = null;
  public Addresses: ContactAddressDTO[] = [];
  public Photo: string = null;
  public ShouldUpdatePhoto: boolean = false;
  public MembershipType: any;
  public CountryPhoneCode: any = null;
  public CountryPhoneCode2: any = null;
}


export enum NotificationMode{
  EMAIL,
  SMS,
  BOTH
}

export enum EventType{
  CREATE_ITINERARY,
  UPDATE_ITINERARY,
  CANCEL_ITINERARY,
  ITINERARY_RENOTIFY
}

export class ItineraryNotifyRequestDTO{
  public TenantId : string;
  public ItineraryNotifyRequest : ItineraryNotifyRequest
}

export class ItineraryNotifyRequest{
  public itineraryUuid : string;
  public contextId : string;
  public toEmailAddress : string;
  public phoneNumber : string;
  public eventType : string;
  public notificationMode : string;
  public primaryGuestProfileId : string;
}

export class ItineraryApiResponse{
  public TotalRecord : number = 0;
  public ItineraryDTO : ItineraryDTO[]

} 

export class ItineraryDTO{
public startDateTime : Date
public endDateTime : Date
public cancelled:boolean
public uuid : string;
public profileUuids : string[];
public businessContextIds : string[];
public activities : CommonActivities[]
}

export class CommonActivities{
public status : string;
public productName : string;
public itineraryUuid : string;
public noOfGuests : number;
public startDateTime : Date
public endDateTime : Date
public activityName : string;
public confirmationId : string;
public activityUuid : string;
}

export enum GetContactOptions {
  None = 0,
  BasicContact = 1,
  ExtendedContactFields = 2,
  Notes = 4,
  Photo = 7,
  VisitStatsForCurrentRestaurant = 8,
  VisitStatsForOtherRestaurants = 16,
  Addresses = 32,
  PredefinedNotesText = 64,
  FutureReservations = 128,
  ReservationsAcrossAllRestaurants = 256,
  CreditCardsForFutureReservations = 512,
  StandByList = 1024,
  BasicContactWithNotes = BasicContact | Notes,
  FullContact = BasicContact | ExtendedContactFields | Notes | Addresses,
  FullContactWithPhoto = BasicContact | ExtendedContactFields | Notes | Addresses | Photo,
  FullWithStats = FullContact | VisitStatsForCurrentRestaurant | VisitStatsForOtherRestaurants,
  FullWithStatsAndPredefinedNotes = FullWithStats | PredefinedNotesText | FutureReservations | ReservationsAcrossAllRestaurants,
  FullWithStatsAndPredefinedNotesIncludeCreditCards = FullWithStats | PredefinedNotesText | FutureReservations | ReservationsAcrossAllRestaurants | CreditCardsForFutureReservations | StandByList,
  FullWithStatsAndPredefinedNotesIncludeCreditCardsAndPhoto = FullWithStats | PredefinedNotesText | FutureReservations | ReservationsAcrossAllRestaurants | CreditCardsForFutureReservations | Photo,
  ContactCustomFields = 3,
  CustomGuestFields = 5,
  BasicContactWithNotesAndCustomFields = BasicContact | Notes | ContactCustomFields | CustomGuestFields
}

export class ContactVisitStatsDTO {
  public RestaurantId: number;
  public RestaurantName: string;
  public ContactId: number;
  public LastVisitedAt: Date;
  public NumberOfVisits: number;
  public NumberOfVisitsLast30Days: number;
  public NumberOfCancellations: number;
  public NumberOfCancellationsLast30Days: number;
  public NumberOfNoShows: number;
  public NumberOfNoShowsLast30Days: number;
  public LastServerName: string;
  public LastTableNames: string;
  public TotalSpend: number;
  public AverageSpend: number;
  public NumberOfWalkins: number;
  public NumberOfVisitsAsReservation: number;
}

export interface CustomGuestField {
  Id: number;
  MasterMerchantId: number;
  FieldType: string;
  FieldName: string;
  FieldDescription: string;
  IsCustomGuestFieldVisible: boolean;
  FieldOptions: string;
  MasterMerchant: any;
  ContactCustomFields: any;
}

export enum PartyStateUpdateSource {
  TableSaver,
  CleanUpScript
}

export class PartyDTO {
  public Id: number = null;
  public RestaurantId: number = null;
  public Type: PartyType = null;
  public State: PartyState = null;
  public StateUpdateSource: PartyStateUpdateSource = null;
  public Size: number = null;
  public SeatingAreaId: number = null;
  public StaffIds: number[] = [];
  public TableIds: number[] = [];
  public TableNames: string[] = [];
  public SeatingTime: Date = null;
  public DepartureTime: Date = null;
  public NoShowedAt: Date = null;
  public CancelledAt: Date = null;
  public CreatedAt: Date = null;
  public UpdatedAt: Date = null;
  public HostId: number = null;
  public StatusId: number = null;
  public PartySourceId: number = null;
  public CancelationSourceId: number = null;
  public ConfirmationCode: string = null;
  public IsConfirmed: boolean = null;
  public IsVipPartyServed: boolean = null;
  public PagerNumber: string = null;
  public PageMethod: PageMethod = null;
  public LastPagedAt: Date = null;
  public Contact: ContactDTO = {} as ContactDTO;
  public Notes: PartyNoteDTO[] = [];
  public Messages: PartyMessageDTO[] = [];
  public CheckSum: number = null;
  public NumberOfChecks: number = null;
  public GuestScore: string = null;
  public ActivityId: string = null;
  public GuestProfileId: string = null;
  public Covers: CoverTypeQuantityDTO[] = [];
  public SpecialMealId: number = null;
  public SpecialMeal: SpecialMealsDTO = null;
  public ChargeState: PartyChargeState = null;
  public SeatingTypeId: number = null;
  public RoomNumber: string = null;
  public ReferenceNumber: string = null;
  public DepositPaid: number = null;
  public RatePlanAmount: number = null;
  public CreditCardId: number = null;
  public PrepaymentState: number = null;
  public ServerId: number = null;
  public StartDate: Date = null;
  public EndDate: Date = null;
  public UniqueId: string = null;
  public Charges: string = null;
  public TaxAmount: number = null;
  public PackageId: number = null;
  public BookingContactAddonItems: BookingContactAddonItemDTO[];
  public AddonItemAmount: number = null;
  public AddonItemTaxAmount: number = null;
  public WaiverFormURL: string = null;
  public PackageDiscountAmount: number = null;
  public Comments: string = null;
  public SalesContactIds: number[] = [];
  public OverBooked: number = 0;
  public CancellationFeeAmount?: number = null;
  public CommonPackageInfo?: string = null;
  public TotalServiceChargeAmount?: number = null;
  public TaxOnServiceChargeAmount?: number = null;
  public SelectedAddOns?:AddOnRequest[];
  public CoverTypeQuantities?: CoverTypeQuantityDTO[] = [];
  public CoverTypes: CoverTypeQuantityDTO[] = [];
  public IsExclusiveBooking?: boolean = false;
  
}

export class SpecialMealsDTO {
  public Id: number = null;
  public RestaurantId: number = null;
  public ClassType: ClassType;
  public Name: string = null;
  public MinPartySize: number = null;
  public MaxPartySize: number = null;
  public SeatingAreaId: number = null;
  public DoesRequirePrepayment: boolean = null;
  public ChargeOnBooking: boolean = null;
  public DeferredPaymentInDays: number = null;
  public CutOffMinutes: number = null;
  public Price: number = null;
  public SortOrder: number = null;
  public CreatedAt: Date = null;
  public UpdatedAt: Date = null;
  public GroupName: string = '';
  public ActivitySessions:any =[];
  public LessonType: LessonType = null
}
export class CoverTypeQuantityDTO {
  public Id: number = null;
  public CoverTypeId: number = null;
  public Covers: number = null;
  public CoverTypeName: string = null;
  public Price: number = null;
}

export enum PartyNoteType {
  FreeFormNote,
  PredefinedNote,
  SpecialRequest,
  PromoCode,
  SpecialMeal
}
export class PartyNoteDTO {
  public Id: number = null;
  public Text: string = null;
  public Type: PartyNoteType = null;
  public RelatedId: number = null;
  public addnlId: number;
}

export class ReservationDTO extends PartyDTO {
  public Id:number = null;
  public ReservationDate: Date = null;
  public ReservedFor: Date = null;
  public SlotId: number = null;
  public SpecialMealId: number = null;
  public SessionGroupId: number = null;
  public HotelId: number = null;
  public ConciergeId: number = null;
  public Token: string = null;
  public IsCommunalTable: boolean = null;
  public IsCCAssociated: boolean = null;
  public NoShowFeeAmount: number = null;
  public ChargedAt: Date = null;
  public RefundedAt: Date = null;
  public AutomaticallyRefundedAt: Date = null;
  public ChargeState: PartyChargeState = null;
  public PrepaymentState: PartyPrepaymentState = null;
  public ChargeHostName: string = null;
  public RefundHostName: string = null;
  public RefundCRSUserName: string = null;
  public CreditCardId: number = null;
  public MaskedCardNumber: string = null;
  public SpecialMealAmount: number = null;
  public PaidAmount: number = null;
  public OperationCurrency: string = null;
  public RemindAboutVisit: boolean = null;
  public RemindAboutVisitEmail: boolean = null;
  public TooLateToCancel: boolean = null;
  public CoverTypeQuantities: CoverTypeQuantityDTO[] = [];
  public SpecialMealName: string = null;
  public OverpaymentAmount: number = null;
  public SpecialMeal: SpecialMealsDTO = null;
  public RatePlanAmount: number = null;
  public NegotiatedAmount: number = null;
  public CheckIconVisibility: boolean = null;
  public SecondaryContacts: SecondaryContactDTO[] = [];
  public BookedSessions: BookedSessionDTO[] = [];
  public BookingAmounts: BookingAmountsDTO[] = [];
  public BookingId: string = null;
  public OrderPrice: any;
  public RefundOption: any;
  public WishedTime: Date = null;
  public UniqueId: string = null;
  public ArrivedAt: Date = null;
  public PackageName: string = null;
  public PackageId: number = null;
  public PackageDiscountAmount: number = null;
  public PackageDiscountPercentage: number = null;
  public Type: PartyType = null;
  public PaymentMethod: PaymentMethod;
  public Comments: string = null;
  public SalesContactIds: number[] = [];
  public CommonPackageInfo: string = null;
  public CancellationFeeAmount ?: number = null;
  public PendingAmount ? : number;
  public GrandTotal? : number;
  public SelectedAddOns?:AddOnRequest[] = [];
  public TotalAddonNegotiatedAmount: number = null;
  public IsExclusiveBooking?:boolean = false;
}

export enum PartyChargeState {
  None,
  Pending,
  InProgress,
  Charged,
  ChargeFailed,
  Ignored,
  Refunded,
  RefundFailed
}

export enum PartyPrepaymentState {
  None,
  PrepaymentRequired,
  PrepaymentInProgress,
  Prepaid,
  PrepaymentFailed,
  Refunded,
  RefundFailed,
  PartialPrepayment,
  DeferredPayment,
  DepositCollected,
  CancellationFeeApplied,//May be refunded balance after charging cancellation or not refunded as cancellation charge would be same as actual amount
  AdditionalPaymentPending,
  PaymentDuePending,
  RefundDuePending
}

export class ReAssignAssetDTO {
  ActivityId: number;
  ActivitySessionId: number;
  ActivitySessionDate: Date;
}

export enum PaymentTargets {
  None,
  SpecialMeal,
  NoShowFee,
  RatePlan,
  SpecialMealAndRatePlan,
  NoshowFeeAndRatePlan
}

export enum PartyPaymentResult {
  InProgress,
  Failed,
  Success
}

export class CommunicationList {
  public Id: number;
  public Name: string;
  public Icon: string;
  public setSelected: boolean;
  public Value: string;
  isDisabled?: boolean;
  // parameterized construtor
  constructor(Id: number, Name: string, Icon: string, setSelected: boolean, Value: string, isDisabled: boolean) {
    this.Id = Id;
    this.Name = Name;
    this.Icon = Icon;
    this.setSelected = setSelected;
    this.Value = Value;
    this.isDisabled = isDisabled;
  }

}
export class BillingDTO {
  public Id: number;
  public Size: number;
  public ReservedFor: Date;
  public PriceAmount: number;
  public PartyStatus: string;
  public OperationCurrency: string;
  public SpecialMealBadgeVisibility: boolean;
}
export class TitleList {
  public id: number;
  public value: string;
  constructor(Id: number, Value: string) {
    this.id = Id;
    this.value = Value;
  }
}

export enum CheckinCheckOutBehaviour {
  CheckIn = 1,
  CheckOut,
  AdditionalCharge,
  CheckinWithAdditionalCharge,
  CheckOutWithAdditionalCharge
}

export class CustomGuestFieldsMappingDTO {
  public ActivityId: number
  public CustomFields: CustomFields[] = [];
}
export class CustomFields {
  Id: number;
  public FieldId: number;
  public IsVisible: boolean;
  public AvailableForWebReservation: boolean;
  public Validations: ValidationType[] = [];
}
export class ValidationType {
  public Id: number;
  public SelectedValidationType: number;
  public TargetValue: string;
}