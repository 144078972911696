<section [formGroup]="CategoryFormGrp" autocomplete="off">
      <app-retail-multi-select-dropdown *ngIf="allCategoryGroups" [dropDownFilterData] = "allCategoryGroups" [dropDownControlname] = "'reportcategory'" [dropDownName] = captions.ReportCategory (dropDownFrmControl) = "getFormControlValue($event)" (categoryGroup) = "getCategoryGroupValue($event)"></app-retail-multi-select-dropdown>

    <app-retail-category-component (formReady)="formInitialized($event)" [categoryReportGroup]="categoryGroupSelected" 
  [fromJobSchedulerEdit] = "isFromEdit" [editValues] = "categoryComponentFilter"></app-retail-category-component>
  <app-retail-multi-select-dropdown *ngIf="registers" [dropDownFilterData] = "registers" [dropDownControlname] = "'register'" [dropDownName] = "'Registers'" (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
      <app-retail-multi-select-dropdown *ngIf="clerks" [dropDownFilterData] = "clerks" [dropDownControlname] = "'clerks'" [dropDownName] = captions.Users (dropDownFrmControl) = "getFormControlValue($event)"  (IsAnySelected)="IsSelectionValid($event)"></app-retail-multi-select-dropdown>
      <mat-error class="user-select" *ngIf="!validSelection">{{validSelectionErrMsg}} </mat-error>
      <div *ngIf="showMachineDropDown">
        <app-retail-multi-select-dropdown *ngIf="machineName" [dropDownFilterData] = "machineName" [dropDownControlname] = "'machineName'" [dropDownName] = captions.Machines (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
        </div>

  <div class="mb-3">
    <mat-radio-group [value]="0" class="report-radio-group" formControlName="itemFilter">
        <mat-radio-button *ngFor="let data of radioFilter" class="report-radio-button" [value]="data.id">
          {{data.name}}
        </mat-radio-button>
      </mat-radio-group>
  </div>
  <div class="mb-3" *ngIf = "this.includeZeroMemberCharge">
    <label class="LW14">{{captions.IncludeZeroMemberCharge}}</label>
    <app-retail-toggle-switch class="d-block" formControlName="includeZeroMemberChargeToggle"></app-retail-toggle-switch>
  </div>
  <div class="mb-3">
  <label class="LW14">{{captions.IncludeNotMapped}}</label>
  <app-retail-toggle-switch class="d-block" [formControl]="CategoryFormGrp.controls['includeCategoryNotMapped']">
  </app-retail-toggle-switch>
  </div>


</section>
