import { Pipe } from "@angular/core";
import { PartyState } from "../constants/commonenums";

@Pipe({
    name: 'attendeesFilter'
})
export class AttendeesFilter {

    transform(attendees, searchText): any {
        searchText = searchText.toLowerCase();
        return attendees.filter(attendee => (attendee.State == PartyState.Pending || attendee.State == PartyState.Seated || attendee.State == PartyState.Left || attendee.State == PartyState.Cancelled)
            && ((attendee.Contact.FirstName && attendee.Contact.FirstName?.toLowerCase().indexOf(searchText) != -1)
                || (attendee.Contact.LastName && attendee.Contact.LastName?.toLowerCase().indexOf(searchText) != -1)
                || (((attendee.Contact.FirstName + (attendee.Contact.LastName ? ' ' + attendee.Contact.LastName : '')).toLowerCase()).indexOf(searchText) != -1)
                || (attendee.Contact.PhoneNumber && attendee.Contact.PhoneNumber?.toLowerCase().indexOf(searchText) != -1))
        );
    }
}