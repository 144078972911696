import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-dialog-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogContainerComponent {

  ts: TranslateService = inject(TranslateService);

  @Input() title: string = '';
  @Input() showSubmit: boolean = true;
  @Input() showCancel: boolean = true;
  @Input() closeOnCancel: boolean = true;
  @Input() showFooter: boolean = true;
  
  @Input() saveLabel: string = this.ts.instant('Save');
  @Input() cancelLabel: string = this.ts.instant('Cancel');

  @Output() submitaction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public dialogRef: DialogRef<any>) {

  }

  submitClick() {
    this.submitaction.next(true);
  }

  cancelClick() {
    if (this.closeOnCancel) {
      this.dialogRef.close();
    } else {
      this.cancel.next(true);
    }
  }
}
