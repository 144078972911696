import { Utilities } from '@app/shared/utilities/utilities';
import { ChangeAction } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { AutoServerAreaDTO, AutoServerArea } from '@models/RestaurantDTO';
import _ from 'lodash';
import { AutoServerAreasDTOMapper } from '../../shared/models/mappers/AutoServerAreasDTOMapper';
import { Processor } from './processor';

export class AutoServerAreasChangeProcessor implements Processor {

  private static instance: AutoServerAreasChangeProcessor;
  private constructor(public cs: CacheService) {
  }

  public static Instance(cs: CacheService): AutoServerAreasChangeProcessor {
    if (!AutoServerAreasChangeProcessor.instance) {
      AutoServerAreasChangeProcessor.instance = new AutoServerAreasChangeProcessor(cs);
    }

    return AutoServerAreasChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: any, propertyId : number): void {
    let state = this.cs.state.value;
    let autoServerAreas = [];
    if (propertyId == Utilities.RestaurantId()) {
      autoServerAreas = this.cs.state.value.AutoServerAreas;
    }
    else {
      autoServerAreas = this.cs.propertySettings.value[propertyId].state.AutoServerAreas;
    }
    if (!autoServerAreas) {
      return;
    }
    if (objectChange) {
      if (objectChange.Action === ChangeAction.Created) {
        const autoServerArea = new AutoServerArea();
        objectChange.PropertyChanges.forEach(property => {
          const isObject = _.isObject(autoServerArea[property.PropertyName]);
          if (Object.getOwnPropertyNames(autoServerArea).includes(property.PropertyName)) {
            autoServerArea[property.PropertyName] = isObject ? JSON.parse(property.Value) : property.Value;
          }
          if (Object.getOwnPropertyNames(autoServerArea).includes(property.PropertyName.replace('Internal', ''))) {
            autoServerArea[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
          }
        });
        let dto = AutoServerAreasDTOMapper.map(autoServerArea);
        autoServerAreas.push({ ...dto });
        //this.cs.state.next(state);
      }
      if (objectChange.Action === ChangeAction.Updated) {
        if (objectChange && autoServerAreas && autoServerAreas.length > 0) {
          const autoServerArea = autoServerAreas.find((autoServerArea) => autoServerArea.Id === objectChange.ObjectId);
          if (autoServerArea) {
            objectChange.PropertyChanges.forEach(property => {
              const isObject = _.isObject(autoServerArea[property.PropertyName.replace('Internal', '')]);
              //this.mapMissedProperties(property, slotDetail);
              if (Object.getOwnPropertyNames(autoServerArea).includes(property.PropertyName.replace('Internal', ''))) {
                autoServerArea[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
              }
            });
            //this.cs.state.next(state);
          }
        }
      }
      if (objectChange.Action === ChangeAction.Removed) {
        autoServerAreas = autoServerAreas.filter(autoServerArea => autoServerArea.Id !== objectChange.ObjectId);
        //this.cs.state.next(state);
      }
      this.cs.propertySettings.value[propertyId].state.AutoServerAreas = autoServerAreas;
    }
  }

}
