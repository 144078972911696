<ng-container>
  <app-dialog-header [title]="headername" (closeDialog)="close($event)"></app-dialog-header>
</ng-container>
<div class="create-tax-type-wrapper" id="tax">
  <div class="ag_container--padding-md ag_modal--form-height">
    <form class="ag_form_wrapper--md" [formGroup]="createTaxTypeForm" DetectFormValueChange
      [changeInputValues]="changeInputValues" (formStatusChange)="formStatusChangeEvent($event)">
      <h4 class="ag_group--headers" id="taxMaintenance" [attr.LiteralID]="'lbl_taxMaintenance'">
        {{captions.lbl_taxMaintenance}}</h4>

      <div class="ag_display--flex">
        <ag-textbox class="ag_w--50" [config]="taxTypeInput"></ag-textbox>
        <div class="error-padding ag_w--50">
          <app-chip-search class="ag_w--50" [searchMaxCharLength]="searchPostTypeLength" [allData]="masterPostTypes"
            [selectedData]="selectedData" [searchKey]="searchKey" [autoCompleteKeys]="autoCompleteKeys"
            [selectedChipKey]="selectedChipKey" (selectedChipDataEmit)="selectedChipDataEmit($event)"
            (searchTextEmit)="SearchTextHandler($event)" (onChipRemove)="onChipRemoveEvent($event)" [isFocusSearchEmitRequired] ="isFocusSearchEmitRequired"
            [disabled]="isChipDisabled" [placeholderText]="captions.lbl_searchByPostType"></app-chip-search>
          <div>
            <mat-error *ngIf="!isPostTypeSelected">
              {{captions.err_postTypeNumber}}
            </mat-error>
          </div>
        </div>
      </div>
      <div>
        <app-ag-number [inputs]="changeDaysInput"></app-ag-number>
        <app-ag-date-picker [inputs]="effectiveDaysInput">
        </app-ag-date-picker>
      </div>
      <div>
        <app-ag-dropdown id="ag-automation-select-taxType-pstTax" [inputs]="pstTaxInput"></app-ag-dropdown>
        <ag-textbox [config]="extractCodeInput"></ag-textbox>
      </div>
      <div>
        <app-ag-incremental [inputs]="listOrderInput"></app-ag-incremental>
        <mat-checkbox class="ag_form-control--sm" formControlName='isActive' [attr.LiteralID]="'lbl_tog_active'">
          {{captions.lbl_tog_active}}</mat-checkbox>
      </div>
      <app-search-with-checkbox [inputs]="searchCheckBoxInput" (itemChange)="onItemChange($event)" [isViewOnly]="viewOnly">
      </app-search-with-checkbox>
    
      <ag-dropdown class="ag_display--block ag_form-control--sm" [placeholder]="captions.lbl_taxExemptCategories" formControlName="taxExemptCategories" [source]="taxExemptCategory |async"
      [showEmptyOption]="true"></ag-dropdown>
    </form>

    <form [formGroup]="rangeMaintenanceForm" class="ag_mt--6" DetectFormValueChange
      [changeInputValues]="rangeMaintenanceChangeInputValues"
      (formStatusChange)="formStatusChangeEventRangeMaintenance($event)">
      <div class="border-color">
        <div class="title-wrapper">
          <h4 class="ag_group--headers" id="rangeMaintenance" [attr.LiteralID]="'lbl_rangeMaintenance'">
            {{captions.lbl_rangeMaintenance}}</h4>
        </div>
        <div class="ag_form_wrapper--md ag_container--padding-md">
          <div>
            <app-ag-date-picker [inputs]="rangeFromInput" (datePickerChange)="onfromDateChange($event)">
            </app-ag-date-picker>
            <app-ag-date-picker [inputs]="rangeToInput">
            </app-ag-date-picker>
          </div>
          <div class="ag_display--flex">
            <div class="ag_form-control--sm">
              <mat-radio-group formControlName="taxBasedOn">
                <label class="ag_form--label" [attr.LiteralID]="'lbl_taxBasedOn'">{{captions.lbl_taxBasedOn}}</label>
                <mat-radio-button class="ag_form--radio-button" (change)="taxBasedRadioChange($event)"
                  *ngFor="let opt of taxBasedList" [value]="opt.id">
                  {{opt.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <!-- <app-ag-percentage class="ag_w--50" *ngIf="!isAmount" [inputs]="taxPercentInput"></app-ag-percentage> -->
            <div class="ag_w--50" *ngIf="!isAmount">
              <mat-form-field class="ag_form-control--lg" [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_createtaxtype_taxPercent'" matInput RetailCurrencyFormatter
                  [isFromTaxPercentage]="isFromTaxPercentage"
                  [postDecimalLengthForTaxes]="decimalMaxLength" [preDecimalLength]="taxMaxLength" name="taxPercent" 
                  formControlName="taxPercent" type='text' placeholder={{captions.lbl_taxPercent}} />
                <mat-error *ngIf="rangeMaintenanceForm.controls['taxPercent'].invalid">
                  {{ captions.err_missingTaxPercent }}
                </mat-error>
              </mat-form-field>
            </div>
            <app-ag-currency class="ag_w--50" *ngIf="isAmount" [inputs]="flatAmountInput"></app-ag-currency>
          </div>
          <div class="ag_display--flex">
            <div class="ag_form-control--sm">
              <mat-radio-group formControlName="newTaxBasedOn">
                <label class="ag_form--label"
                  [attr.LiteralID]="'lbl_newTaxBasedOn'">{{captions.lbl_newTaxBasedOn}}</label>
                <mat-radio-button class="ag_form--radio-button" (change)="newTaxBasedRadioChange($event)"
                  *ngFor="let opt of taxBasedList" [value]="opt.id">
                  {{opt.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <!-- <app-ag-percentage class="ag_w--50" *ngIf="!isNewAmount" [inputs]="newTaxPercentInput"></app-ag-percentage> -->
            <div class="ag_w--50" *ngIf="!isNewAmount">
              <mat-form-field class="ag_form-control--lg" [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_createtaxtype_newtaxPercent'" matInput RetailCurrencyFormatter
                  [isFromTaxPercentage]="isFromTaxPercentage"
                  [postDecimalLengthForTaxes]="decimalMaxLength" [preDecimalLength]="taxMaxLength" name="newTaxPercent" 
                  formControlName="newTaxPercent" type='text' placeholder={{captions.lbl_newTaxPercent}} />
              </mat-form-field>
            </div>
            <app-ag-currency class="ag_w--50" *ngIf="isNewAmount" [inputs]="flatAmountInput1"></app-ag-currency>
          </div>
          <div>

          </div>
          <div class="w-50">
            <label for="" class="ag_form--label"
              [attr.LiteralID]="'lbl_newPostTypeNumber'">{{captions.lbl_newPostTypeNumber}}</label>
            <app-chip-search [searchMaxCharLength]="searchPostTypeLength" [allData]="masterNewPostTypes" class="app-chip-search"
              [selectedData]="selectedNewData" [searchKey]="searchKey" [autoCompleteKeys]="autoCompleteKeys"
              [selectedChipKey]="selectedNewChipKey" (selectedChipDataEmit)="selectedNewChipDataEmit($event)"
              (searchTextEmit)="SearchNewTextHandler($event)" (onChipRemove)="onNewChipRemoveEvent($event)" [isFocusSearchEmitRequired] ="isFocusSearchEmitRequired"
              [disabled]="isChipDisabled" [placeholderText]="captions.lbl_searchByPostType"></app-chip-search>
          </div>
          <mat-form-field class="ag_form-control--lg txt-area-height" [floatLabel]="floatLabel">
            <textarea matInput [placeholder]="captions.lbl_reference" name="keywords" inputtype="noprespace"
              [maxlength]="referncekeywordsMaxLength" formControlName="reference"></textarea>
          </mat-form-field>
        </div>
        <div class="table-container">
          <app-cdkvirtual [IsEditModeEnabled]="IsEditModeEnabledFlag" [headerOptions]="headerOptions"
            [tableContent]="tableContent" [options]="tableOptions" [searchOptions]="searchText"
            (EmittedData)='tableAction($event)'></app-cdkvirtual>
        </div>
        <div class="create-range-maintenance-footer ag_footer--actions">
          <app-button [buttontype]="saveButton" (valueChange)='save($event)'></app-button>
          <app-button [buttontype]="rangeCancelButton" class="ag_ml--2" (valueChange)='cancel($event)'></app-button>
        </div>
      </div>
    </form>
  </div>
  <div class="create-tax-type-footer ag_footer--actions">
    <app-button [buttontype]="actionButton" (valueChange)='onAction($event)'></app-button>
    <app-button [buttontype]="cancelButton" class="ag_ml--2" (valueChange)='onCancel($event)'></app-button>
  </div>
</div>
<pms-floating-navigation [inputs]="floatingInputs"></pms-floating-navigation>