<form autocomplete="off" name="General" [formGroup]="otherInfoFormGrp" class="h-100">
    <div class='otherInfo'>
        <ng-scrollbar >
            <div [ngClass]="isEdit && IsViewOnly ? 'disableItemSetup' : ''" (click)="stopdefaultClick($event)"
                (keydown)="stopdefaultClick($event)">
                <div class='left'>
                    <div class='groupKey' [ngClass]=" generalFormGroup['controls'].itemcatradiogrp.value == 10 || generalFormGroup['controls'].itemcatradiogrp.value == retailItemTypes.CustomFee ? 'button_invalid' : ''">
                        <div class='title LW14'>{{captions.GroupingKey}} &amp; {{captions.ScaledItem}}</div>
                        <div class="info-toggle mt-0 yes-col ">
                            <label class="LW14 label">{{captions.GroupingKey}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_groupKey'" formControlName="groupKey" (changeToggleEvent)=GKtoogleChange($event)
                                [readonly]="IsViewOnly"></app-retail-toggle-switch>
                        </div>
                        <div  [ngClass]=" generalFormGroup['controls'].itemcatradiogrp.value == 10 ? 'button_invalid' : ''  "> 
                            <div class="info-toggle mt-0 yes-col pb-0 display-in-bl">
                                <label class="LW14 label">{{captions.ScaledItem}}</label>
                                <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_scaleItem'" formControlName="scaleItem"
                                    (changeToggleEvent)=SItoogleChange($event) [readonly]="IsViewOnly">
                                </app-retail-toggle-switch>
                            </div>
                            <div class='display-in-bl mt-0 ml-4'  [ngClass]=" generalFormGroup['controls'].itemcatradiogrp.value == 10 ? 'button_invalid' : ''  ">
                                <mat-form-field class="LW14" [floatLabel]="floatLabel">
                                    <mat-select [attr.automationId]="'Dd_otherInformation_unit'" [placeholder]="captions.UnitOfMeasure" class="LW14"
                                        formControlName="unit"
                                        [disabled]="!otherInfoFormGrp['controls'].scaleItem.value || IsViewOnly">
                                        <mat-option *ngFor="let uom of _retailService.unitOfMeasure" [value]='uom.id'>
                                            {{ uom.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error class=""
                                        *ngIf="otherInfoFormGrp.controls['unit'].hasError('required') && otherInfoFormGrp.controls['unit'].touched">
                                        {{captions.MissingUnitofMeasure}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class='golf' *ngIf="loginProd == 'GOLF'">
                        <div class='title LW14'>{{captions.GolfPro}}</div>
                        <div class="info-toggle mt-0 yes-col ">
                            <label class="LW14 label">{{captions.TeeTimeTrigger}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_timeTrigger'" formControlName="timeTrigger" [readonly]="IsViewOnly">
                            </app-retail-toggle-switch>
                        </div>
                        <div class="info-toggle mt-0 yes-col pb-0">
                            <label class="LW14 label">{{captions.CartTrigger}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_cartTrigger'" formControlName="cartTrigger" [readonly]="IsViewOnly">
                            </app-retail-toggle-switch>
                        </div>
                    </div>
                    <div class='mis'[ngClass]=" generalFormGroup['controls'].itemcatradiogrp.value == 10  || generalFormGroup['controls'].itemcatradiogrp.value == retailItemTypes.CustomFee ? 'button_invalid' : ''">
                        <div class='title LW14'>{{captions.Miscellaneous}}</div>
                        <div class="info-toggle mt-0 yes-col left">
                            <label class="LW14 label">{{captions.Inactive}}</label>
                            <app-retail-toggle-switch  [attr.automationId]="'Tog_otherInformation_inactive'" formControlName="inactive"
                                [ngClass]="this.isSyncItem ? 'button_invalid' : 'button_valid'" [readonly]="IsViewOnly">
                            </app-retail-toggle-switch>
                        </div>
                       
                        <div class="info-toggle mt-0 yes-col  right" [ngClass]="[10].includes(generalFormGroup['controls'].itemcatradiogrp.value)? 'button_invalid' : ''  ">
                            <label class="LW14 label">{{captions.OpenItem}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_openItem'" formControlName="openItem" [readonly]="IsViewOnly"></app-retail-toggle-switch>
                        </div> 
                        <div [ngClass]="productUiConfig.pms.hidden ? 'display-none' : ''" class="info-toggle mt-0 yes-col  left">
                            <label class="LW14 label">{{captions.PMSPackageItem}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_pms'" formControlName="pms" [readonly]="IsViewOnly"></app-retail-toggle-switch>
                        </div>
                        <div *ngIf="functionalities['ShowMultiPackToggleOnRetailItemCreation'] || false" [ngClass]="productUiConfig.multipack.hidden ? 'display-none' : ''" class="info-toggle mt-0 yes-col pb-0 right">
                            <label class="LW14 label">{{captions.Multipack}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_multipack'" formControlName="multipack" (changeToggleEvent)="toogleChange($event)"
                                [readonly]="IsViewOnly"></app-retail-toggle-switch>
                        </div>
                        <div class="info-toggle mt-0 yes-col right">
                            <label class="LW14 label">{{captions.RequireComments}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_requireComments'" formControlName="requireComments" [readonly]="IsViewOnly">
                            </app-retail-toggle-switch>
                        </div>
                        <div class="info-toggle mt-0 yes-col  left">
                            <label class="LW14 label">{{captions.RequestName}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_requestName'" formControlName="requestName" [readonly]="IsViewOnly">
                            </app-retail-toggle-switch>
                        </div>
                    </div>
                </div>
                <div class='right'>
                    <div *ngIf="functionalities['ShowAutoGratuityAndServiceChargeOnRetailItemCreation'] || false"
                        [ngClass]="productUiConfig.gratuityServiceCharge.hidden ? 'display-none' : ''" class='gratuity'
                        [ngClass]="[1,2,4,retailItemTypes.CustomFee].includes(generalFormGroup['controls'].itemcatradiogrp.value) ? 'button_invalid' : ''  ">
                        <div class='title LW14'>{{captions.Gratuity}} &amp; {{captions.ServiceCharge}}</div>
                        <div class="info-toggle mt-0 yes-col w-50 gratuitySwitch">
                            <label class="LW14 label">{{captions.Gratuity}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_gratuity'" formControlName="gratuity" [readonly]="IsViewOnly"></app-retail-toggle-switch>
                        </div>
                        <div class="info-toggle mt-0 yes-col pb-0">
                            <label class="LW14 label">{{captions.ServiceCharge}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_serviceTax'" formControlName="serviceTax" [readonly]="IsViewOnly"></app-retail-toggle-switch>
                        </div>
                        <div class='mt-0 pl-0'>
                            <div class='display-in-bl mt-0 w-50'>
                                <mat-form-field class="LW14" [floatLabel]="floatLabel">
                                    <input matInput [attr.automationId]="'Txt_otherInformation_gratuityValue'" formControlName="gratuityValue" [placeholder]="captions.AutoGratiuty"
                                        RetailCurrencyFormatter [preDecimalLength]="percentageMaxLength"
                                        [readonly]="!otherInfoFormGrp.controls.gratuity.value || IsViewOnly">
                                </mat-form-field>
                            </div>
                            <div class='display-in-bl mt-0 serviceTaxValue'>
                                <mat-form-field class="LW14" [floatLabel]="floatLabel">
                                    <input matInput [attr.automationId]="'Txt_otherInformation_serviceTaxValue'" formControlName="serviceTaxValue" [placeholder]="captions.AutoServiceCharge"
                                        RetailCurrencyFormatter [preDecimalLength]="percentageMaxLength"
                                        [readonly]="!otherInfoFormGrp.controls.serviceTax.value || IsViewOnly">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class='printInfo' [ngClass]=" generalFormGroup['controls'].itemcatradiogrp.value == 10 ? 'button_invalid' : ''  ">
                        <div class="display-flex-wrap">
                            <div class='LW14 page'>{{captions.PrintingInformation}}</div>
                            <!-- //Feature 57221: Cart, Shoe, Club Agreement ,Retail sale Chit , Retail sale chit Not Implemented -->
                            <!-- <div class='LW14 pagetitle ag-mr-7'>{{captions.lbl_agreementTemplates}}</div> -->
                        </div>
                        <div *ngIf="this.loginProd?.toLowerCase() == this.Golf?.toLowerCase()">
                            <div class="info-toggle mt-0 yes-col ">
                                <label class="LW14 label">{{captions.PrintCartAgreement}}</label>
                                <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_printCart'" formControlName="printCart" [readonly]="IsViewOnly" (changeToggleEvent)="Validate('cartTemplate',$event)"
                                [disableToggle]="isEformsEnabled">
                                </app-retail-toggle-switch>
                                <!-- <mat-form-field class="LW14 ag-ml-5" *ngIf="otherInfoFormGrp['controls'].printCart.value"> -->
                                    <mat-form-field class="LW14 ag-ml-5" *ngIf="false" [floatLabel]="floatLabel">
                                    <mat-select [attr.automationId]="'Dd_otherInformation_cartTemplate'" [placeholder]="Cart" class="LW14"
                                        formControlName="cartTemplate"
                                        [disabled]="!otherInfoFormGrp['controls'].printCart.value || IsViewOnly">
                                        <mat-option *ngFor="let item of cartAgreementOptions" [value]='item.id'>
                                            {{ item.viewValue }}
                                        </mat-option>
                                    </mat-select>                                  
                                </mat-form-field>
                            </div>
                            <div class="info-toggle mt-0 yes-col ">
                                <label class="LW14 label">{{captions.PrintClubAgreement}}</label>
                                <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_printClub'" formControlName="printClub" [readonly]="IsViewOnly"
                                    (changeToggleEvent)="Validate('clubTemplate',$event)" [disableToggle]="isEformsEnabled"></app-retail-toggle-switch>
                                    <!-- <mat-form-field class="LW14 ag-ml-5" *ngIf="otherInfoFormGrp['controls'].printClub.value"> -->
                                        <mat-form-field class="LW14 ag-ml-5" *ngIf="false" [floatLabel]="floatLabel">
                                        <mat-select [attr.automationId]="'Dd_otherInformation_clubTemplate'" [placeholder]="Club" class="LW14"
                                            formControlName="clubTemplate"
                                            [disabled]="!otherInfoFormGrp['controls'].printClub.value || IsViewOnly">
                                            <mat-option *ngFor="let item of clubAgreementOptions" [value]='item.id'>
                                                {{ item.viewValue }}
                                            </mat-option>
                                        </mat-select>                                  
                                    </mat-form-field>
                            </div>
                            <div class="info-toggle mt-0 yes-col ">
                                <label class="LW14 label">{{captions.PrintShoeAgreement}}</label>
                                <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_printShoe'" formControlName="printShoe" [readonly]="IsViewOnly"  (changeToggleEvent)="Validate('shoeTemplate',$event)"
                                [disableToggle]="isEformsEnabled">
                                </app-retail-toggle-switch>
                                <!-- <mat-form-field class="LW14 ag-ml-5" *ngIf="otherInfoFormGrp['controls'].printShoe.value"> -->
                                    <mat-form-field class="LW14 ag-ml-5" *ngIf="false" [floatLabel]="floatLabel">
                                    <mat-select [attr.automationId]="'Dd_otherInformation_shoeTemplate'" [placeholder]="Shoe" class="LW14"
                                        formControlName="shoeTemplate"
                                        [disabled]="!otherInfoFormGrp['controls'].printShoe.value || IsViewOnly">
                                        <mat-option *ngFor="let item of shoeAgreementOptions" [value]='item.id'>
                                            {{ item.viewValue }}
                                        </mat-option>
                                    </mat-select>                                  
                                </mat-form-field>
                            </div>
                            <div class="info-toggle mt-0 yes-col ">
                                <label class="LW14 label">{{captions.PrintToCaddyShack}}</label>
                                <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_printShack'" formControlName="printShack" [readonly]="IsViewOnly" >
                                </app-retail-toggle-switch>
                            </div>
                        </div>
                       
                        <div class="info-toggle mt-0 yes-col ">
                            <label class="LW14 label">{{captions.HangingTicket}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_hangTicket'" formControlName="hangTicket" [readonly]="IsViewOnly"></app-retail-toggle-switch>
                        </div>
                        <div class="info-toggle mt-0 yes-col  pb-0">
                            <label class="LW14 label">{{captions.SmallSticker}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Tog_otherInformation_sticker'" formControlName="sticker" [readonly]="IsViewOnly"></app-retail-toggle-switch>
                        </div>
                        <!-- //Feature 57221: Cart, Shoe, Club Agreement ,Retail sale Chit , Retail sale chit Not Implemented -->

                        <!-- <div class="info-toggle mt-0 yes-col" *ngIf="_retailService.GeneralFormGrp.controls['itemcatradiogrp'].value == 1">
                            <label class="LW14 label">{{captions.PrintSaleChit}}</label>
                            <app-retail-toggle-switch formControlName="printSaleChit" [readonly]="IsViewOnly"  (changeToggleEvent)="Validate('saleChit',$event)">
                            </app-retail-toggle-switch>
                            <mat-form-field class="LW14 ag-ml-5" *ngIf="otherInfoFormGrp['controls'].printSaleChit.value">
                                <mat-select [placeholder]="SaleChit" class="LW14"
                                    formControlName="saleChit"
                                    [disabled]="!otherInfoFormGrp['controls'].printSaleChit.value || IsViewOnly">
                                    <mat-option *ngFor="let item of saleChitOptions" [value]='item.id'>
                                        {{ item.viewValue }}
                                    </mat-option>
                                </mat-select>                                  
                            </mat-form-field>
                        </div> -->
                    </div>
                </div>
                <div class='mt-4 pl-0' *ngIf="_retailService.GeneralFormGrp.controls['itemcatradiogrp'].value == 7">
                    <div class='title LW14 pb-2'>{{captions.RatesInformation}}</div>
                    <mat-form-field class="LW14 pr-4" [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_otherInformation_gratuityValue'" matInput formControlName="perHourRentalRate" [placeholder]="captions.PerHourRate" RetailCurrencyFormatter 
                            [readonly]="IsViewOnly">
                    </mat-form-field>
                
                    <mat-form-field class="LW14 perDay-text" [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_otherInformation_perDayRentalRate'" matInput formControlName="perDayRentalRate" [placeholder]="captions.PerDayRate" RetailCurrencyFormatter
                            [readonly]="IsViewOnly">
                    </mat-form-field>
                </div>
            </div>
        </ng-scrollbar>
    </div>
    <mat-dialog-actions class="float-right actionButtons pr-4">
        <button [attr.automationId]="'Btn_otherInformation_cancel'" class="" mat-button type="button"
            (click)="close()">{{this.localization.captions.common.CANCEL}}</button>
        <button [attr.automationId]="'Btn_otherInformation_previous'"  class="" mat-button matStepperPrevious type="button"
            class="LW14 text_capitalize  body-bordercolor">{{this.localization.captions.common.PREVIOUS}}</button>
        <button [attr.automationId]="'Btn_otherInformation_next'"  class="" mat-button matStepperNext type="button" color="primary" *ngIf="(!isExternalItem && _retailService.GeneralFormGrp.controls['inventory'].value
         || _retailService.GeneralFormGrp.controls['commissionableitem'].value || _retailService.otherInfoFormGrp.controls['multipack'].value 
         || _retailService.otherInfoFormGrp.controls['groupKey'].value || (_retailService.showRetailSetupRoomTypesAssignmentTab))"
            [ngClass]="IsViewOnly || ((validateNext && !otherInfoFormGrp.hasError('required') && otherInfoFormGrp.valid) || !otherInfoFormGrp['controls'].scaleItem.value) ? 'button_valid' : ' button_invalid'"
            class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.NEXT}}</button>
        <button [attr.automationId]="'Btn_otherInformation_save'"  mat-button type="button" *ngIf="!isEdit && !(_retailService.GeneralFormGrp.controls['inventory'].value 
       || _retailService.GeneralFormGrp.controls['commissionableitem'].value || _retailService.otherInfoFormGrp.controls['multipack'].value 
       || _retailService.otherInfoFormGrp.controls['groupKey'].value || (_retailService.showRetailSetupRoomTypesAssignmentTab))" (click)="save()" color="primary"
            [ngClass]="otherInfoFormGrp.valid ? 'button_valid' : ' button_invalid'"
            class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.SAVE}}</button>
        <button mat-button [attr.automationId]="'Btn_otherInformation_update'"  type="button" *ngIf="isExternalItem || isEdit && !(_retailService.GeneralFormGrp.controls['inventory'].value ||
         _retailService.GeneralFormGrp.controls['commissionableitem'].value || _retailService.otherInfoFormGrp.controls['multipack'].value || 
         _retailService.otherInfoFormGrp.controls['groupKey'].value || (_retailService.showRetailSetupRoomTypesAssignmentTab))" (click)="save()" color="primary"
            [ngClass]="(otherInfoFormGrp.valid && (parentForm.get('generalFormGroup').dirty || parentForm.get('otherInfoFormGroup').dirty || parentForm.get('inventoryFormGroup')?.dirty
			|| parentForm.get('multipackFormGroup')?.dirty || parentForm.get('packagedItemFormGroup')?.dirty)) ? 'button_valid' : ' button_invalid'"
            class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.Update}}</button>
    </mat-dialog-actions>
</form>
