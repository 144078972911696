import { BaseReport } from "../common/base-report";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportAPIOptions, AllReports, ReportUIConfig, ReportParams,   StaffFilter, StaffFilterData, StaffReportRequest } from "../report.modals";
import { Product } from '../../../shared/globalsContant';
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { PropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import { MiscellaneousSwitch } from 'src/app/retail/shared/globalsContant';
export class GratuityReport extends BaseReport {

    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService ,private productId : number,
        public retailLocalization : RetailLocalization, public patcheableValue, private propertySettingsDataService: PropertySettingDataService) {
        super(reportFormGrp);
    }

    houseGratuityPercentage: string = "0.00";

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.Gratuity,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }

    getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
        return {
            startDatePicker: true,
            endDatePicker: true,
            dropDownFilters: this.data.getAllStaffs(AllReports.Gratuity,this.productId),
            dropDownFilterName: this.captions.Users,
            inActiveToggle: true,
            inActiveToggleName: this.captions.IncludeInactiveUsers,
            pageBreakToggle: true,
            layout: 'LANDSCAPE',
            allowFutureDate: false,
            showHelperText : false,
            hideCorrectedValues: true,
            includeInhouseGratuity: this.getHouseGratuityPercentageSettingsValue()
        };
    }

    formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        let houseGratuityPercentage = this.getHouseGratuityPercentage();
        houseGratuityPercentage =  Number(houseGratuityPercentage)>0? houseGratuityPercentage : "0.00";

        if(this.reportFormGrp.controls.includeInhouseGratuityToggle.value == false)
        {
            houseGratuityPercentage = "0.00";
        }
        if (this.commonLocalization.isFromJobScheduler)
        {   
            return [
                    { "pStartDate": toApi(this.startDate) },
                    { "pEndDate": toApi(this.endDate) },
                    { "pPageBreak": this.pageBreakToggle }, 
                    { "pFilterCaption": this.getFilterCaption },
                    { "pIncludeInactiveUsers": this.reportFormGrp.controls.includeInactiveusers.value},
                    { "pHouseGratuityPercentage": houseGratuityPercentage},
                    { "pPropertyName": this.propertyName }, 
                    { "pDate": this.printedDate },
                    { "fromWildCard": this.reportFormGrp.controls.timelineFrom.value},
                    { "fromIncrement": this.reportFormGrp.controls.timelineCountFrom.value},
                    { "toWildCard": this.reportFormGrp.controls.timelineTo.value},
                    { "toIncrement": this.reportFormGrp.controls.timelineCountTo.value},
                    { "PatchEditParam": true},
                    { "pInhouseReportType": this.reportFormGrp.controls.reportTypeGRSelection.value}
                    ];
        }
        return [{ "pStartDate": toApi(this.startDate) },
        { "pEndDate": toApi(this.endDate) },
        { "pPageBreak": this.pageBreakToggle }, { "pFilterCaption": this.getFilterCaption },
        { "pIncludeInactiveUsers": this.reportFormGrp.controls.includeInactiveusers.value},
        { "pHouseGratuityPercentage": houseGratuityPercentage},
        { "pPropertyName": this.propertyName }, { "pDate": this.printedDate },
        { "pInhouseReportType": this.reportFormGrp.controls.reportTypeGRSelection.value}
        ];
    }

    formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) }, { "reportType": "Gratuity" },
        {"HideCorrectedValues":this.reportFormGrp.controls.hideCorrectedValuesToggle.value},
        { "ProductId": this.productId}];
    }

    formFilters(): StaffReportRequest {
        let selectedLocations: StaffFilter[] = this.selectedFilterOptions;
        let data = selectedLocations.filter(r => r.showInDropDown).map(s => { return { staffId: s.id, staffType: s.staffType } });
       if(this.commonLocalization.isFromJobScheduler){
        return { staffFilter : data,fromIncrement:this.reportFormGrp.controls.timelineCountFrom.value,
            fromWildCard: this.reportFormGrp.controls.timelineFrom.value,toIncrement:this.reportFormGrp.controls.timelineCountTo.value
            ,toWildCard:this.reportFormGrp.controls.timelineTo.value};
        }       
        return { staffFilter : data};
    }

    patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);            

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);
            
        }, 1);
    }

    getHouseGratuityPercentage(): string{
        let houseGratuityPercentage : string = "0.00";
        let houseGratuityPercentageSession = sessionStorage.getItem('houseGratuityPercentage');    
        if(houseGratuityPercentageSession){
            houseGratuityPercentage = houseGratuityPercentageSession;
        }
        return houseGratuityPercentage;
    }

    async getHouseGratuityPercentageSettingsValue(){
        let showHouseGratuityToggle = false;
        let miscSettings = await this.propertySettingsDataService.GetMiscConfigurationSetting();
        let houseGratuityPercentageSettingsValue = miscSettings.find(f => f.switch == MiscellaneousSwitch.HOUSE_GRATUITY_PERCENTAGE);
        if(houseGratuityPercentageSettingsValue){
            this.houseGratuityPercentage = String(houseGratuityPercentageSettingsValue.value); 
        }
        sessionStorage.setItem('houseGratuityPercentage', this.houseGratuityPercentage);

        if(Number(this.houseGratuityPercentage) > 0)
            return true;
        return showHouseGratuityToggle;
    }
}