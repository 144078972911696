import { EventEmitter, Injectable } from '@angular/core';
import { DmConfig, Product } from 'src/app/common/Models/common.models';
import { InternalIntegrationCommunication } from '../communication/services/internal-intergration-service';
import { CommonApiRoutes } from "../common-route";
import { I } from '@angular/cdk/keycodes';
import { Localization } from 'src/app/common/localization/localization';
import { BaseResponse } from '../Models/http.model';
import { Host } from 'src/app/common/shared/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';

@Injectable({
  providedIn: 'root'
})

export class DMConfigDataService {

  public DMConfigNotifier = new EventEmitter<boolean>();

  //DataMagineConfig = sessionStorage.getItem('DataMagineConfig');
  constructor(private v1InternalIntegration: InternalIntegrationCommunication, private _localization: Localization,
    private http: HttpServiceCall
  ) {
  }

  public async getDataMagineConfig(): Promise<DmConfig> {
    let productId = parseInt(this._localization.GetPropertyInfo('ProductId'));
    switch (productId) {

      case Product.SPA:
        const response: BaseResponse<DmConfig> = await this.http.CallApiAsync<DmConfig>(
          {
            callDesc: 'GetDataMagineConfiguration',
            host: Host.schedule,
            method: HttpMethod.Get,
          }
        );
        return response.result;
        break;

      case Product.PMS:
        const result = this.v1InternalIntegration.getPromise<DmConfig>(
          { route: CommonApiRoutes.GetDataMagineConfig }, false);
        return result;
        break;

      case Product.GOLF:
        const res: BaseResponse<DmConfig> = await this.http.CallApiAsync<DmConfig>(
          {
            callDesc: 'GetDataMagineConfiguration',
            host: Host.golfManagement,
            method: HttpMethod.Get,
          }
        );
        return res.result;
        break;
      case Product.RETAIL:
        const configRes: BaseResponse<DmConfig> = await this.http.CallApiAsync<DmConfig>(
          {
            callDesc: 'GetDataMagineConfiguration',
            host: Host.retailManagement,
            method: HttpMethod.Get,
          }
        );
        return configRes.result;
      default:
        return null;
        break;

    }


  }

  public async getDataMagineConfigSession(): Promise<DmConfig> {

    let DataMagineConfig = sessionStorage.getItem('DataMagineConfig');
    if (DataMagineConfig) {
      return JSON.parse(DataMagineConfig);
    }
    else {
      return await this.getDataMagineConfig();
    }
  }

  public async SetDataMagineConfig() {
    let DmConficg = await this.getDataMagineConfig();
    if (DmConficg) {
      sessionStorage.setItem('DataMagineConfig', JSON.stringify(DmConficg));
    } else {
      sessionStorage.removeItem('DataMagineConfig');
    }
    this.DMConfigNotifier.emit(true);
  }

  public async createDataMagineConfig(body: DmConfig): Promise<DmConfig> {
    let productId = parseInt(this._localization.GetPropertyInfo('ProductId'));
    switch (productId) {
      case Product.SPA:
        const response: BaseResponse<DmConfig> = await this.http.CallApiAsync<DmConfig>(
          {
            callDesc: 'CreateDataMagineConfiguration',
            host: Host.schedule,
            method: HttpMethod.Post,
            body: body
          }
        );
        return response.result;
        break;
      case Product.GOLF:
        const res: BaseResponse<DmConfig> = await this.http.CallApiAsync<DmConfig>(
          {
            callDesc: 'CreateDataMagineConfiguration',
            host: Host.golfManagement,
            method: HttpMethod.Post,
            body: body
          }
        );
        return res.result;
        break;
      case Product.RETAIL:
        const configRes: BaseResponse<DmConfig> = await this.http.CallApiAsync<DmConfig>(
          {
            callDesc: 'CreateDataMagineConfiguration',
            host: Host.retailManagement,
            method: HttpMethod.Post,
            body: body
          }
        );
        return configRes.result;
      default:
        const result = this.v1InternalIntegration.postPromise<DmConfig>(
          { route: CommonApiRoutes.CreateDataMagineConfig, body }, true);
        return result;
        break;

    }


  }
  public async updateDataMagineConfig(body: DmConfig): Promise<DmConfig> {
    let productId = parseInt(this._localization.GetPropertyInfo('ProductId'));
    switch (productId) {
      case Product.SPA:
        const response: BaseResponse<DmConfig> = await this.http.CallApiAsync<DmConfig>(
          {
            callDesc: 'UpdateDataMagineConfiguration',
            host: Host.schedule,
            method: HttpMethod.Put,
            body: body
          }
        );
        return response.result;
        break;
      case Product.GOLF:
        const res: BaseResponse<DmConfig> = await this.http.CallApiAsync<DmConfig>(
          {
            callDesc: 'UpdateDataMagineConfiguration',
            host: Host.golfManagement,
            method: HttpMethod.Put,
            body: body
          }
        );
        return res.result;
      case Product.RETAIL:
        const configRes: BaseResponse<DmConfig> = await this.http.CallApiAsync<DmConfig>(
          {
            callDesc: 'UpdateDataMagineConfiguration',
            host: Host.retailManagement,
            method: HttpMethod.Put,
            body: body
          }
        );
        return configRes.result;
      default:

        const result = this.v1InternalIntegration.putPromise<DmConfig>(
          { route: CommonApiRoutes.UpdateDataMagineConfig, body }, false);
        return result;
    }

  }

  public checkConnection(body: { userName: string; password: string; }): Promise<any> {
    const result = this.v1InternalIntegration.postPromise<any>(
      { route: CommonApiRoutes.CheckConnection, body }, true);
    return result;
  }

  public SaveDataMagineConfig(body: DmConfig): Promise<DmConfig> {
    const result = this.v1InternalIntegration.putPromise<DmConfig>(
      { route: CommonApiRoutes.UpdateDataMagineConfig, body }, false);
    return result;
  }

}
