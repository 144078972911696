import { Component, EventEmitter, OnInit, Output, ViewEncapsulation,ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { DropDownData } from '../../../business/report.modals';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { PropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import { RetailReportControl, ValidationMessage } from '../../../basereport/base-report.component';

@Component({
  selector: 'app-surcharges',
  templateUrl: './surcharges.component.html',
  styleUrls: ['./surcharges.component.scss']
})
export class SurchargesComponent implements OnInit {
  SurchargeFormGrp: any;
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  captions: any;
  isFromJobScheduler: boolean = false;
  editPatchValue: any;
  card: DropDownData[];
  constructor(private fb: UntypedFormBuilder, private localization: RetailLocalization, 
     private PropertyInfo: RetailPropertyInformation, private propertySettingsDataService: PropertySettingDataService, private container: ViewContainerRef) { }

  ngOnInit() {
    this.isFromJobScheduler = this.localization.isFromJobScheduler;
    this.captions = this.localization.captions.reports;
    this.SurchargeFormGrp = this.fb.group({
      card: new UntypedFormControl([])
    });
    this.formReady.emit(this.SurchargeFormGrp);
    if (this.container) 
    {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      this.SetCardTypeValues();            
    }
  }

  async SetCardTypeValues()
  {
    this.card = await this.propertySettingsDataService.GetCardIssuers();
    this.SurchargeFormGrp.controls['card'].setValue([this.card]);
  }

  getFormControlValue(event) {
    if (event[0] === 'card') {
    this.SurchargeFormGrp.setControl('card', event[1]);
    }
  }

}
