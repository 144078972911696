<div class="theatre-booking">
  <div class="theatre-booking__config">
    <ng-scrollbar>
      <div class="theatre-booking__show">
        <label class="theatre-booking__show-label seat-labels"> {{'ClassName' | translate}}</label>
        <dynamic-form [config]="showsConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="theatre-booking__date">
        <label class="theatre-booking__show-label seat-labels"> {{'reservationSelectDate' | translate}}</label>
        <dynamic-form [config]="bookingDateConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="theatre-booking__location">
        <label class="theatre-booking__show-label seat-labels"> {{'selectLocation' | translate}}</label>
        <dynamic-form [config]="showLocationConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="theatre-booking__seatingArea">
        <label class="theatre-booking__show-label seat-labels"> {{'selectSeatingArea' | translate}}</label>
        <dynamic-form [config]="showSeatingAreaConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div *ngIf="seatingType.length == 1" class="theatre-booking__seatingType">
        <label class="theatre-booking__show-label seat-labels"> {{'seatingType' | translate}}</label>
        <dynamic-form [config]="showSeatingTypeConfig" #form="dynamicForm"></dynamic-form>
      </div>
      <div *ngIf="seatingType.length > 1" class="theatre-booking__seatingType">
        <label class="theatre-booking__show-label seat-labels"> {{'seatingType' | translate}}</label>
        <div class="theatre-booking__seatTypes">
          <div *ngFor="let seatType of seatingType" class="rs-pr-10">
            <div class="theatre-booking__show-label seat-labels">{{seatType.Description}}</div>
            <mat-form-field>
              <mat-select>
                <mat-option value="option">Option</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
  <div class="theatre-booking__show-selection">
    <ng-scrollbar>
      <div class="theatre-booking__screen-labels">
        <div class="theatre-booking__shows-label">{{'selectScreens' | translate}}</div>
        <div class="theatre-booking__show-duration">{{'duration' | translate}}: 120 {{'mins' | translate}}</div>
      </div>
      <div *ngFor="let screen of screens" class="rs-mb-10">
        <label>{{screen.screenName}}</label>
        <div class="theatre-booking__shows-display">
          <div *ngFor="let show of screen.shows" class="theatre-booking__show-details">
            <p style="text-align: center;">{{show.showTiming |localizedDate : 'LT'}}</p>
            <div class="theatre-booking__show-info">
              <div class="rs-pr-10">
                {{'startsFrom' | translate}} {{appService._cs.settings.value.General.OperationCurrency}}{{show.showRate}}
              </div>
              <div>
                {{'availableSeats' | translate}}: {{show.availableSeats}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>