import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '@utilities/utilities';

@Pipe({ name: 'randomColor' })

export class GetRandomColorPipe implements PipeTransform {
  transform(item) {
    //return '#' + (Utilities.getRandomDecimal() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
    var x = Math.floor(Utilities.getRandomDecimal() * 256);
    var y = 150; //added to get only bright colors as background
    var z = 50 + Math.floor(Utilities.getRandomDecimal() * 256);
    var bgColor = "rgb(" + x + "," + y + "," + z + ")";
    return bgColor;
  }
}
