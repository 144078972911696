import { Component, OnInit, HostListener, ViewEncapsulation  } from '@angular/core';
import 'zone.js';



@Component({
    selector: 'seat-status',
    templateUrl: './seatstatus.component.html',
    styleUrls: ['./seatstatus.component.scss'],
    encapsulation:ViewEncapsulation.None
})

export class SeatStatusComponent implements OnInit {
// constructor(public _ts: TableTabService, public _cs: CommonService){}

//     data_holder: any = {};
//     temp_holder: any = [];
//     waitingTime: any;
//     color = 'red';
//     mode = 'determinate';
//     animateloop: any;
//     x: any;

//     animateloopfunc() {
//         let wt = this._ts.waitingTime;
//         if (this.x <= wt) {
//           this.waitingTime = this.x;
//           this.x++;
//         } else {
//           clearInterval(this.animateloop);
//         }
//       }
//        /* get the type of seat status */ 
 
//   getbackground(color){
//     switch (color) {
//       case 'reserved':
//         return '#5093E1';
//       case 'seated':
//         return '#9CC62B';
//       case 'arrived':
//         return '#ED1446';
//       case 'cancelled':
//       return '#C8C9CA';  
//     }
//   }  

    ngOnInit(){
        // this.x = 0;
        // this.animateloop = setInterval(() => this.animateloopfunc(), 25);
    }

}
