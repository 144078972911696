import { Pipe, PipeTransform } from '@angular/core';
import { buttonTypes } from '@app/shared/constants/commonenums';
import { ButtonValue } from '@dynamicform/models/field-config.interface';

@Pipe({
  name: 'buttonInputFormater'
})
export class ButtonInputFormater implements PipeTransform {
  btnFormat: ButtonValue;

  transform(data: any, type: buttonTypes, customClass?: string, disabledProperty?: boolean, additionalInfo?: string, color?: string): any {
    var styleObj;
    if(color){
      styleObj = {'border-color': color, 'color': color}
    }
    this.btnFormat = {
      label: data,
      type,
      customclass: customClass,
      disbaledproperity: disabledProperty,
      additionalText: additionalInfo,
      customStyle: styleObj || ""
    };
    return this.btnFormat;
  }
}


