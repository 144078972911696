import { Directive, HostListener, ElementRef, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[module-class]'
})
export class ModuleClassDirective implements OnChanges {

    @Input('posView') isPOSView: boolean = false;

    constructor(private el: ElementRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.setClass();
    }

    setClass(){
        var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
        var body = document.getElementsByTagName('body')[0]; // '0' to assign the first (and only `BODY` tag)

        if (this.isPOSView) {
            root.classList.add('respos');
            body.classList.remove('greentheme');
        } else {
            root.classList.remove('respos');
            body.classList.add('greentheme');
            root.classList.add('reserve');
        }
    }

}
