import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/Subscription';
import { SettingsService } from '@app/shared/services/settings.service';
import { CacheService } from '@app/core/services/cache.service';
import { PopupService } from '@app/popup-module/popup.service';
import { PartyService } from '@app/shared/services/party.service';
import { PackageDTO } from '@shared/models/PackageDTO';
import { buttonTypes, ComponentTypes, PopupType, PackageType, SingleDayActivityType, PackageStatusDto, BookingBehavior, ActivityType } from '@constants/commonenums';
import _ from 'lodash';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Utilities } from '@app/shared/utilities/utilities';
import { LoginResultDTO } from '@app/shared/models/LoginResultDTO';

@Component({
  selector: 'app-package-booking',
  templateUrl: './package-booking.component.html',
  styleUrls: ['./package-booking.component.scss']
})
export class PackageBookingComponent implements OnInit {
  subscriptions: Subscription = new Subscription;
  @Output() availableCartItems = new EventEmitter<any>();
  applyPackageBtn: ButtonValue;
  cancelBtn: ButtonValue;
  cartDataList: any[] = [];
  showAction: boolean = false;
  isUniqGuest: boolean = true;
  isPackageAplied: boolean = false;
  SingleDayActivityTypeValue = SingleDayActivityType  
  propertyDateAndTime: Date;
  defaultFilter = {
    Status: 0,
    TimeRange: {
      Start: new Date(),
      End: new Date()
    }
  }
  constructor(public ts?: TranslateService, public ss?: SettingsService, public cs?: CacheService, public ps?: PopupService, public partyService?: PartyService, public datePipe?: DatePipe, public toastrService?: ToastrService) { }

  ngOnInit() {
    this.propertyDateAndTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    this.defaultFilter.TimeRange.Start.setDate( this.propertyDateAndTime.getDate() - (this.cs.AllowEditForPastReservationsInDays || 0));
    this.defaultFilter.TimeRange.End = this.propertyDateAndTime;
    this.GetPackages();
    this.loadButtonconfig();
  }
  loadButtonconfig() {
    this.applyPackageBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: this.ts.instant('apply'),
      disbaledproperity: false,
      customclass: 'action-bar__book-btn',
    }
    this.cancelBtn = {
      label: this.ts.instant('clearPackage'),
      type: buttonTypes.actionSecondarySmall,
      disbaledproperity: false,
      customclass: 'action-bar__book-btn',
    }
  }
  ngAfterViewInit() {
    this.partyService.packageMatchValidation$.subscribe(data => {
      if (data) {
        this.cartDataList = data;
        if (this.cartDataList && this.cartDataList.length) {
          this.checkPackageMatchCase(data);
        }else{
          this.partyService.selectedPackageId = null;
          this.partyService.selectedPackage = null;
          this.partyService.packages.map(pack => {
            pack.highlightPackage = false;
            return pack;
          })
        }
      }
    })
    this.cs.refreshPackages.subscribe(data => {
      if (data === true) {
        this.GetPackages();
      }
    })
  }

  uniqGuestValidation() {
    let uniqGuestWithMail = _.uniqBy(this.cartDataList, cart => cart.CartItemDetail.EmailAddress !== null && cart.CartItemDetail.Contact.Id !== null && cart.CartItemDetail.EmailAddress)
    if ((uniqGuestWithMail && uniqGuestWithMail.length == 1)) {
      this.isUniqGuest = true;
      this.applyPackageBtn.disbaledproperity = false;
    } else {
      if (this.cartDataList && this.cartDataList.length) {
        this.isUniqGuest = false;
        this.applyPackageBtn.disbaledproperity = true;
      } else {
        this.isUniqGuest = true;
      }
  
    }
    let non_payableItems = [];
    return (this.isUniqGuest && !non_payableItems.length && this.cartDataList?.length) ? true : false;
  }
  checkPackageMatchCase(data) {
    this.partyService.selectedPackageId = null;
    this.partyService.selectedPackage = null;
    this.partyService.packages.forEach(pack => {
      if (this.cartDataList && this.cartDataList.length > 0) {
        if (pack.SingleDayActivityType == SingleDayActivityType.AnyActivity) {
          this.higlightAnyActivityTypePackage(pack, this.dateAndPropertyFilter(pack))
        } else if (pack.SingleDayActivityType == SingleDayActivityType.SpecificActivity) {
          this.activitiesMatchCountCheck(pack, this.dateAndPropertyFilter(pack));
        }
      } else {
        pack.highlightPackage = false;
      }
    })
    console.log(data);
  }

  activitiesMatchCountCheck(packageData, availableCartItems) {
    let activitiesMatchCount = 0;
    let openBookingMatch = this.getOpenBookingMatchCount(packageData , availableCartItems);
    let sessionMatch = this.getSessionBookingMatchCount(packageData , availableCartItems);
    let privateBookingMatch = this.getPrivateBookingMatchCount(packageData,availableCartItems);
    let classBookingMatch = this.getClassBookingMatchCount(packageData,availableCartItems);
    activitiesMatchCount = Number(openBookingMatch || 0) + Number(sessionMatch || 0) + Number(privateBookingMatch || 0) + Number(classBookingMatch || 0);
    if (packageData.PackageActivities[0].NumberOfActivitiesForDay == -1) {
      packageData.highlightPackage =  (this.exactMatchWithCart(packageData.PackageActivities) && packageData.PackageActivities.length == activitiesMatchCount)  ? true : false;
    } else {
      packageData.highlightPackage = (this.exactMatchWithCart(packageData.PackageActivities) && activitiesMatchCount === packageData.PackageActivities[0].NumberOfActivitiesForDay)  ? true : false;
    }
  }
  exactMatchWithCart(PackageActivities){
    let misMatchCount = 0 ;
    this.cartDataList.forEach(cart =>{
      if(cart.CartItemDetail.BookingBehavior == BookingBehavior.OpenBooking){
        if(!PackageActivities.find(activity => activity.PropertyId == cart.PropertyId && activity.ActivityType == ActivityType.OpenBooking)){
          misMatchCount = misMatchCount + 1 ;
        }
      }else{
        if(!PackageActivities.find(activity => activity.ActivityId == cart.CartItemDetail.SpecialMealId && activity.ActivityType != ActivityType.OpenBooking)){
          misMatchCount = misMatchCount + 1 ;
        }
      }
    })
    return misMatchCount == 0 ? true : false;
  }
  getPrivateBookingMatchCount(packageData , availableCartItems){
    let activitiesMatchCount = 0;
    let packagePrivateBookingIds = packageData.PackageActivities.filter(item => item.ActivityType == ActivityType.PrivateBooking)?.map(pack => pack.ActivityId);
    packagePrivateBookingIds.forEach(activityId => {
      let specificCartDetail = availableCartItems.filter(cart => cart.CartItemDetail.SpecialMealId == activityId);
      let specificActivityDetails = packageData.PackageActivities.find(pack => pack.ActivityId == activityId);
      let durationTotal = 0;
      if(specificActivityDetails.DurationInMinutes == -1 && specificCartDetail?.length > 0){
        activitiesMatchCount = activitiesMatchCount + 1
      }else if(specificActivityDetails.DurationInMinutes !== -1 && specificCartDetail?.length > 0){
        let slots = [];
        specificCartDetail.forEach(cart => {slots.push(cart.CartItemDetail.Slots)});
        slots = slots.flat();
        slots.forEach(item => { durationTotal = durationTotal + item.DurationInMinutes })
        if (specificActivityDetails.DurationInMinutes == durationTotal) {
            activitiesMatchCount = activitiesMatchCount + 1;
          }
      }    
    })
    return activitiesMatchCount;
  }
  getSessionBookingMatchCount(packageData , availableCartItems){
    let activitiesMatchCount = 0;
    let packageSessionIds = packageData.PackageActivities.filter(item => item.ActivityType == ActivityType.SessionBooking)?.map(pack => pack.ActivityId);
    packageSessionIds.forEach(sessionId => {
      let specificCartDetail = availableCartItems.filter(cart => cart.CartItemDetail.SpecialMealId == sessionId);
      let specificActivityDetails = packageData.PackageActivities.find(pack => pack.ActivityId == sessionId);
      let sessionCount = 0;
      specificCartDetail.forEach(pack => {sessionCount = sessionCount + pack.CartItemDetail.TotalSessions})
      if ((specificCartDetail && specificActivityDetails) && (sessionCount == specificActivityDetails.SessionCount)) {
        activitiesMatchCount = activitiesMatchCount + 1;
      }
    })
    return activitiesMatchCount;
  }
  getOpenBookingMatchCount(packageData,availableCartItems){
    let packageOpenBookingIds = packageData.PackageActivities.filter(item => item.ActivityType == ActivityType.OpenBooking)?.map(pack => pack.PropertyId);
   let activitiesMatchCount = 0;
    packageOpenBookingIds.forEach(propertyId => {
      let specificCartDetail = availableCartItems.filter(cart => cart.PropertyId == propertyId && cart.CartItemDetail.BookingBehavior == BookingBehavior.OpenBooking);
      let specificActivityDetails = packageData.PackageActivities.find(pack => pack.PropertyId == propertyId && pack.ActivityType == ActivityType.OpenBooking);
      let durationTotal = 0;
      if(specificActivityDetails.DurationInMinutes == -1 && specificCartDetail?.length > 0){
        activitiesMatchCount = activitiesMatchCount + 1
      }else if(specificActivityDetails.DurationInMinutes !== -1 && specificCartDetail?.length > 0){
        let slots = [];
        specificCartDetail.forEach(cart => {slots.push(cart.CartItemDetail.Slots)});
        slots = slots.flat();
        slots.forEach(item => { durationTotal = durationTotal + item.DurationInMinutes })
        if (specificActivityDetails.DurationInMinutes == durationTotal) {
            activitiesMatchCount = activitiesMatchCount + 1;
          }
      }
    })
    return activitiesMatchCount;
  }
  getClassBookingMatchCount(packageData,availableCartItems) {
    let activitiesMatchCount = 0;
    let packageClassBookingIds = packageData.PackageActivities.filter(item => item.ActivityType == ActivityType.ClassBooking)?.map(pack => pack.ActivityId);
    packageClassBookingIds.forEach(sessionId => {
      let specificCartDetail = availableCartItems.find(cart => cart.CartItemDetail.SpecialMealId == sessionId);
      let specificActivityDetails = packageData.PackageActivities.find(pack => pack.ActivityId == sessionId);
      if (specificCartDetail && specificActivityDetails && specificCartDetail.CartItemDetail.SpecialMealId == sessionId) {
        activitiesMatchCount = activitiesMatchCount + 1;
      }
    })
    return activitiesMatchCount;
  }
  higlightAnyActivityTypePackage(packageData, availableCartItems) {
    // filter for selected cart item is avaialble for package by properties
    if (packageData.NumberOfActivities == this.cartDataList.length && availableCartItems.length == packageData.NumberOfActivities) {
      packageData.highlightPackage = true;
    } else {
      packageData.highlightPackage = false;
    }
  }
  dateAndPropertyFilter(packageData) {
    let availableCartItems = [];
    // filter for selected cart item is avaialble for package by date
    this.cartDataList.forEach(cart => {
      // let maxDate;
      // let minDate;
      let start = new Date(moment(packageData.StartDate).format('YYYY-MM-DD'));
      let end = new Date(moment(packageData.EndDate).format('YYYY-MM-DD'));
      let currentDate = new Date(moment(this.propertyDateAndTime).format('YYYY-MM-DD')); 
      if (currentDate >= start &&  currentDate <= end) {
        availableCartItems.push(cart);
      }
      // if (cart.CartItemDetail.BookingBehavior == BookingBehavior.ClassOrSession) {
      //   let sessionMinDate = _.minBy(cart.CartItemDetail.Sessions.Sessions, function (session) { return session['Date'] })
      //   let sessionMaxDate = _.maxBy(cart.CartItemDetail.Sessions.Sessions, function (session) { return session['Date'] })
      //   if (!sessionMaxDate || !sessionMinDate) {
      //     sessionMinDate = cart.CartItemDetail.StartDate;
      //     sessionMaxDate = cart.CartItemDetail.EndDate;
      //   }
      //   maxDate = new Date(moment(sessionMaxDate['Date']).format('YYYY-MM-DD'));
      //   minDate = new Date(moment(sessionMinDate['Date']).format('YYYY-MM-DD'));
      //   if (minDate >= start && maxDate <= end) {
      //     // sessionMaxDate['Time'].format('YYYY-MM-DD') > sessionMinDate['Time'].format('YYYY-MM-DD')
      //     if (packageData.PackageType == PackageType.SingleDay) {
      //       availableCartItems.push(cart);
      //       // sessionMaxDate['Time'].format('YYYY-MM-DD') > sessionMinDate['Time'].format('YYYY-MM-DD')
      //     } else if (packageData.PackageType == PackageType.MultiDay) {
      //       availableCartItems.push(cart);
      //     }
      //   }
      // } else if (cart.CartItemDetail.BookingBehavior == BookingBehavior.OpenBooking || cart.CartItemDetail.BookingBehavior == BookingBehavior.PrivateLesson) {
      //   let sessionMinDate = _.minBy(cart.CartItemDetail.Slots, function (slot) { return slot['Time'] });
      //   let sessionMaxDate = _.maxBy(cart.CartItemDetail.Slots, function (slot) { return slot['Time'] });
      //   maxDate = new Date(moment(sessionMaxDate['Time']).format('YYYY-MM-DD'));
      //   minDate = new Date(moment(sessionMinDate['Time']).format('YYYY-MM-DD'));
      //   if (minDate >= start && maxDate <= end) {
      //     if (packageData.PackageType == PackageType.SingleDay) {
      //       availableCartItems.push(cart);
      //     } else if (packageData.PackageType == PackageType.MultiDay) {
      //       availableCartItems.push(cart);
      //     }
      //   }
      // }
    })

    // filter for selected cart item is avaialble for package by NumberOfActivities 
    let availableProperties = packageData.PackagePropertyMapping.map(pack => { return pack.PropertyId })
    availableCartItems = availableCartItems.filter(cart => availableProperties.includes(cart.CartItemDetail.PropertyId));
    return availableCartItems;
  }
  GetPackages() {
    this.subscriptions.add(this.ss.GetPackages(this.cs.settings.value.PropertySetting[0].RestaurantId, this.defaultFilter).subscribe(data => {
      if (data.Payload) {
        let propertyDate = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
        propertyDate = new Date( moment(propertyDate).format('YYYY-MM-DD') +'T00:00:00');
        data.Payload = data.Payload.filter(item => moment(propertyDate).isBetween(item.StartDate, item.EndDate) || moment(propertyDate).isSame(item.StartDate) ||  moment(propertyDate).isSame(item.EndDate) )
        this.partyService.packages = data.Payload as PackageDTO[];
        this.cs.availablePackages = data.Payload as PackageDTO[];
        this.activitiesNameMapping();
      }
    }));
  }
  activitiesNameMapping() {
    let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    this.partyService.packages.map(pack => {
      let mappedData = [];
      pack.PackageActivities.forEach(activity => {
        let mappedActivity = {
          type: activity.ActivityType,
          Name: activity.ActivityId ? (this.cs.specialMealListForMerchant.find(meal => meal.Id == activity.ActivityId)?.Name) : loginResult.RestaurantsAvailableForLogin.find(property => property.Id == activity.PropertyId)?.Name,
          isOpenBooking: !activity.ActivityId,
          count: activity.SessionCount,
          duration: activity.DurationInMinutes,
          discountPercent: activity.DiscountPercent,
        }
        mappedData.push(mappedActivity);
      })
      pack.nameMapping = mappedData;
    })
  }
  selectSpecifyPackage(data) {
    if (data.highlightPackage) {
      if (this.partyService.selectedPackageId !== data.Id) {
        this.showAction = true;
        // this.availableCartItems.emit(false);
      }
      if (data.highlightPackage) {
        this.partyService.selectedPackage = data
        this.partyService.selectedPackageId = data.Id;
      } else {
        this.partyService.selectedPackageId = null;
        this.partyService.selectedPackage = null;
      }
      this.partyService.selectedPackage = data;
    }
  }
  applyPackage() {
      this.showAction = false;
      this.availableCartItems.emit(true);
  }
  cancelPackage() {
    this.showAction = false;
    this.availableCartItems.emit(false);
  }
}
