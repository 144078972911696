import { Injectable, inject } from "@angular/core";
import { HostInfo, LoginAuthInfo, PropertyInfo } from "../interfaces/login";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ReservePOSSessionService {
    router = inject(Router);

    loginAuthInfo: LoginAuthInfo = {} as LoginAuthInfo;
    hostInfo: HostInfo | undefined;
    propertyInfo: PropertyInfo | undefined;
    quickSelectGuestList: Map<number, any> = new Map();

    private userPreferences: UserPreference;

    get UserPreference(): UserPreference {
        if (!this.userPreferences) {
            let _userPreferences = sessionStorage.getItem('respos_userpreference');
            this.userPreferences = this.getParsedJsonValue(_userPreferences) || {};
        }
        return this.userPreferences;
    }

    updateUserPreference(property: UserPreferences, value: any) {
        if(!this.userPreferences){
            let _userPreferences = sessionStorage.getItem('respos_userpreference');
            this.userPreferences = this.getParsedJsonValue(_userPreferences) || {};
        }
        this.userPreferences[property.toString()] = value;
        sessionStorage.setItem('respos_userpreference', JSON.stringify(this.userPreferences))
    }

    getParsedJsonValue(jsonString: string): any {
        try {
            return JSON.parse(jsonString);
        } catch (err) {
            return {}
        }
    }

    logout() {
        let language = sessionStorage.getItem('language');
        sessionStorage.clear();
        this.router.navigate(['login'], { queryParams: { language } })
    }
}


type UserPreference = {
    listingView: 'short' | 'detail';
    enableVirtualKeyboard: 'yes' | 'no';
}

export enum UserPreferences {
    ListingView = 'listingView',
    EnableVirtualKeyboard = 'enableVirtualKeyboard'
}