<section class='change-wrapper dialog-container ag_height--100'>
    <h1 class='ag_p--4 ag_m--0 ag_display--flex title' mat-dialog-title>{{captions.pg_title_selectProperty}}
        <i  aria-hidden="true" class='icon-close ag_ml--auto' (click)="closeDialog(DialogCloseOptionEnum.Cancel)">&nbsp;</i>
    </h1>
    <div class='dialog-content ag_container--padding-lg ag_m--0' mat-dialog-content>
       
        <app-simple-search class="ag_w--100" [reduceHeightAutoComplete]="reduceHeightAutoComplete" [noDataCaption]="captions.lbl_noPropertyAvailable" [inputMaxLength]="50" [placeholder]="captions.lbl_search_propertyName" (searchChange)="searchValueChange($event)" [options]='propertyList' (searchOptionChange)='optionSelected($event)' [focusField]="isFocused">
        </app-simple-search>

    </div>
    <app-dialog-footer [buttonObj]="buttonObj" (save)="changeProperty()" (cancel)="cancel($event)"></app-dialog-footer>
</section>
