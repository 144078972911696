<form [formGroup]="group" autocomplete="off">
  <div class="form-autocomplete">
    <span *ngIf="config.formInputIcon" class="rs-mr-5">
      <i class="{{config.formInputIcon}} seat-icon-color"></i>
    </span>

  <mat-form-field class="basic-input-field " [hideRequiredMarker]="!config.isStarSymbolRequired" [ngClass]="config.class" *ngIf="!config.isDateTime && !appearance && !config.partySize && !config.autoCompleteWithId" [hidden]="config.isHidden">
    <span class="tool-tip-title" matTooltip="{{config.label | translate}}" *ngIf="config.toolTipEnabled" [matTooltipPosition]="'above'"> <mat-label
      class="select-label">{{config.label | translate}}</mat-label></span>
      <mat-label class="select-label" *ngIf="!config.toolTipEnabled">{{config.label | translate}}</mat-label>
      <input matInput appOskInput [ngClass]="{'adjusted-width': config.icon1}" [type]="config.inputType == 'number' ? 'number' : 'text'" placeholder="{{config.placeholder | translate}}"    #trigger="matAutocompleteTrigger"   [matAutocomplete]="auto" [formControlName]="config.name"  [attr.name]="config.name" (input)='filterOptions($event)' (focus)='filterOptions($event)' autocomplete="new-password" >
      <i class="search-icon {{config.icon1}} seat-icon-color" [ngClass]="{'show-icon':config.icon1 && group.controls[FormControlName].value == ''}"  (click)="iconClick($event,config.index,config.index)"></i>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" (closed)="validInput($event)" >
          <perfect-scrollbar class="scroll-event">
            <mat-option [ngClass]="{'option-with-icon': autocompleteOption.isLocked}" *ngFor="let autocompleteOption of autocompleteOptions" [value]="autocompleteOption.value">
              <span [innerHTML]="autocompleteOption.value"></span>
              <em *ngIf="autocompleteOption.isLocked" class="icon-padlock"></em>
            </mat-option>
          </perfect-scrollbar>
          <!-- <mat-option *ngIf = "!(autocompleteOptions.length > 0)">
              <span>No Data Found</span>
            </mat-option> -->
        </mat-autocomplete>
        <mat-error *ngIf="showErrorText">
          {{'selectValue' | translate }}
        </mat-error>
        <mat-error *ngIf="group.controls[FormControlName].hasError('required') && group.controls[FormControlName].touched && config.showErrorText">
          {{config.errorMessage | translate}}
       </mat-error>

    </mat-form-field>
    <mat-form-field class="basic-input-field " [hideRequiredMarker]="!config.isStarSymbolRequired" [ngClass]="config.class" *ngIf="!config.isDateTime && !appearance && !config.partySize && config.autoCompleteWithId && !config.isChipsEnabled" [hidden]="config.isHidden">
      <mat-label class="select-label">{{config.label | translate}}</mat-label>
      <input matInput appOskInput [ngClass]="{'adjusted-width': config.icon1}" (blur)="onFocusOut($event)" placeholder="{{config.placeholder | translate}}"    #trigger="matAutocompleteTrigger"   [matAutocomplete]="auto" [formControlName]="config.name"  [attr.name]="config.name" (input)='filterOptions($event)' (focus)='filterOptions($event)' autocomplete="new-password" >
      <i class="search-icon {{config.icon1}} seat-icon-color" [ngClass]="{'show-icon':config.icon1 && group.controls[FormControlName].value == ''}"  (click)="iconClick($event,config.index,config.index)"></i>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this,config.options)" (optionSelected)="selected($event)" (closed)="validInput($event)" >
          <perfect-scrollbar class="scroll-event">
            <mat-option *ngFor="let autocompleteOption of autocompleteOptions" [value]="autocompleteOption.id">
              <span [innerHTML]="autocompleteOption.value"></span>
              <span *ngIf="autocompleteOption.icon"  class="icon-container {{autocompleteOption.icon}}"></span>
            </mat-option>
          </perfect-scrollbar>
          <!-- <mat-option *ngIf = "!(autocompleteOptions.length > 0)">
              <span>No Data Found</span>
            </mat-option> -->
        </mat-autocomplete>
        <mat-error *ngIf="showErrorText">
          {{'selectValue' | translate }}
        </mat-error>
        <mat-error *ngIf="group.controls[FormControlName].hasError('required') && group.controls[FormControlName].touched && config.showErrorText">
          {{config.errorMessage | translate}}
       </mat-error>

    </mat-form-field>
    <mat-form-field class="basic-input-field " [hideRequiredMarker]="!config.isStarSymbolRequired" [ngClass]="config.class" *ngIf="!config.isDateTime && !appearance && !config.partySize && config.autoCompleteWithId && config.isChipsEnabled" [hidden]="config.isHidden">
      <mat-label class="select-label">{{config.label | translate}}</mat-label>
      <mat-chip-list #chipGrid>
        <mat-chip *ngFor="let values of config.multiChipValues">
          <span *ngIf="values.firstName == '' && values.lastName == '';else showFirstLast" [innerHTML]="values.value"></span>
          <ng-template #showFirstLast>
            <span>{{values.firstName}} {{values.lastName}}</span>
          </ng-template>
          <button matChipRemove [attr.aria-label]="'remove ' + values.value">
            <i class="search-icon icon-Group-591 seat-icon-color" (click)="remove(values)" ></i>
          </button>
        </mat-chip>
        <input matInput appOskInput [ngClass]="{'adjusted-width': config.icon1}" placeholder="{{config.placeholder | translate}}" #multiChips #trigger="matAutocompleteTrigger"   [matAutocomplete]="auto" [formControlName]="config.name"  [attr.name]="config.name" (input)='filterOptions($event)' (focus)='filterOptions($event)' [matChipInputFor]="chipGrid"
         autocomplete="new-password" >
        <i class="search-icon {{config.icon1}} seat-icon-color" [ngClass]="{'show-icon':config.icon1 && group.controls[FormControlName].value == ''}" 
          (click)="iconClick($event,config.index,config.index)"></i>
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this,config.options)" (optionSelected)="multiSelected($event)" (closed)="validInput($event)" >
          <perfect-scrollbar class="scroll-event">
            <mat-option *ngFor="let autocompleteOption of autocompleteOptions" [value]="autocompleteOption.id">
              <span *ngIf="autocompleteOption.firstName == '' && autocompleteOption.lastName == '';else showFirstLast" [innerHTML]="autocompleteOption.value"></span>
              <ng-template #showFirstLast>
                <span>{{autocompleteOption.firstName}} {{autocompleteOption.lastName}}</span>
              </ng-template>
            </mat-option>
          </perfect-scrollbar>
        </mat-autocomplete>

    </mat-form-field>
    <app-form-add-button *ngIf="config.addBtnConfig" [addBtnConfig]="config.addBtnConfig"></app-form-add-button>
  </div>
    <mat-form-field class="basic-input-field {{config.class}}" [hideRequiredMarker]="!config.isStarSymbolRequired" *ngIf="appearance && !config.isDateTime && !config.partySize" [ngClass]="{'input-with-boundary':config.appearance}" [hidden]="config.isHidden">
        <mat-label class="select-label">{{config.label | translate}}</mat-label>
        <input matInput appOskInput placeholder="{{config.placeholder | translate}}" [matAutocomplete]="auto" [formControlName]="config.name"  [attr.name]="config.name" (input)='filterOptions($event)' (focus)='filterOptions($event)' >
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" (closed)="validInput($event)">
          <!-- <ng-scrollbar class="scroll-event"> -->
            <perfect-scrollbar>
            <mat-option *ngFor="let autocompleteOption of autocompleteOptions" [value]="autocompleteOption.value">
              <span [innerHTML]="autocompleteOption.value"></span>
            </mat-option>
          </perfect-scrollbar>
          <!-- </ng-scrollbar> -->

            <!-- <mat-option *ngIf = "!(autocompleteOptions.length > 0)">
                <span>No Data Found</span>
              </mat-option> -->
          </mat-autocomplete>
          <mat-error *ngIf="showErrorText">
            {{'selectValue' | translate }}
          </mat-error>
      </mat-form-field>
      <div class="form-autocomplete" *ngIf="config.isDateTime && !appearance && !config.partySize">
        <mat-form-field class="basic-input-field " [hideRequiredMarker]="!config.isStarSymbolRequired" [ngClass]="config.class" *ngIf="!appearance" [hidden]="config.isHidden">
          <mat-label class="select-label">{{config.label | translate}}</mat-label>
          <input matInput appOskInput [ngClass]="{'adjusted-width': config.icon1}" placeholder="{{config.placeholder | translate}}" #trigger="matAutocompleteTrigger"   [matAutocomplete]="auto" [formControlName]="config.name"  [attr.name]="config.name" (input)='filterOptions($event)' (focus)='filterOptions($event)' autocomplete="off" (keypress)="omit_Characters($event)" >
          <i class="search-icon {{config.icon1}} seat-icon-color" [ngClass]="{'show-icon':config.icon1 && group.controls[FormControlName].value == ''}"  (click)="iconClick($event,config.index)"></i>
          <mat-autocomplete #auto="matAutocomplete">
            <!-- <ng-scrollbar class="scroll-event"> -->
              <mat-option *ngFor="let autocompleteOption of autocompleteOptions" (click)="dateSelection(autocompleteOption)" [value]="autocompleteOption.DateTime | slotTimeFormatter" [disabled]="autocompleteOption.IsDisabled">
                {{autocompleteOption.DateTime | slotTimeFormatter}}
              </mat-option>
            <!-- </ng-scrollbar> -->

              <!-- <mat-option *ngIf = "!(autocompleteOptions.length > 0)">
                  <span>No Data Found</span>
                </mat-option> -->
            </mat-autocomplete>
            <mat-error *ngIf="showErrorText">
              {{'selectValue' | translate }}
            </mat-error>
            <mat-error *ngIf="group.controls[FormControlName].hasError('required') && group.controls[FormControlName].touched && config.showErrorText">
              {{config.errorMessage | translate}}
           </mat-error>

        </mat-form-field>
      </div>

      <div class="form-autocomplete" *ngIf="config.partySize">
        <mat-form-field class="basic-input-field " [ngClass]="config.class" [hideRequiredMarker]="!config.isStarSymbolRequired" [hidden]="config.isHidden">
          <mat-label class="select-label">{{config.label | translate}}</mat-label>
          <input matInput appOskInput [ngClass]="{'adjusted-width': config.icon1}" placeholder="{{config.placeholder | translate}}" [maxlength]="config.charLength" #trigger="matAutocompleteTrigger"   [matAutocomplete]="auto" [formControlName]="config.name"  [attr.name]="config.name" (input)='filterOptions($event)' (focus)='filterOptions($event)' autocomplete="off" (keypress)="omit_Characters($event)" numbersOnly>
          <i class="search-icon {{config.icon1}} seat-icon-color" [ngClass]="{'show-icon':config.icon1 && group.controls[FormControlName].value == ''}" (click)="iconClick($event,config.index)"></i>
          <mat-autocomplete #auto="matAutocomplete" (closed)="validInput($event)" >
            <!-- <ng-scrollbar class="scroll-event"> -->
            <perfect-scrollbar>
              <mat-option *ngFor="let autocompleteOption of autocompleteOptions" [value]="autocompleteOption.value">
                <span [innerHTML]="autocompleteOption.value"></span>
              </mat-option>
            </perfect-scrollbar>
            <!-- </ng-scrollbar> -->

            </mat-autocomplete>
            <mat-error *ngIf="showErrorText">
              {{'selectValue' | translate }}
            </mat-error>
            <mat-error *ngIf="group.controls[FormControlName].hasError('required') && group.controls[FormControlName].touched && config.showErrorText && !group.controls[FormControlName].hasError('min')">
              {{ 'emptyPartySize' | translate}}
           </mat-error>
           <mat-error *ngIf="group.controls[FormControlName].hasError('min') && group.controls[FormControlName].touched && config.showErrorText && !group.controls[FormControlName].hasError('required')">
            {{'zeroPartySizeValidation' | translate}}
         </mat-error>

        </mat-form-field>
      </div>
</form>