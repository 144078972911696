import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';


@Component({
  selector: 'form-button',
  templateUrl: './form-button.component.html',
  styleUrls: ['form-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormButtonComponent implements Field, OnInit {
    
  config: FieldConfig;
  group: UntypedFormGroup;

  ngOnInit(){
  }
}
