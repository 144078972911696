import { Pipe, PipeTransform } from '@angular/core';
import { SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';

@Pipe({
  name: 'timeLapse'
})
export class TimeLapsePipe implements PipeTransform {
constructor(private ts:TranslateService){}
  transform(sendTime: any, value: SettingsDTO): any {
    //      var duration = moment.duration(moment(sendTime).diff(moment()));
    const currentTime = moment(Utilities.getRestaurantDateTime(value.General.DaylightDelta));
    const duration = currentTime.diff(moment(sendTime), 'minutes');
    const absDuration = Math.abs(duration);
    if (absDuration > 59) {
      const hrs = currentTime.diff(moment(sendTime), 'hours');
      if (hrs > 24) {
        const day = currentTime.diff(moment(sendTime), 'days');
        const dayText = ' ' + (day > 1 ? this.ts.instant('daysText') : this.ts.instant('dayText'));
        return day + dayText;
      } else {
        const hrsText = ' ' + (hrs > 1 ?   this.ts.instant('hrsText') : this.ts.instant('hrText'));
        return hrs + hrsText;
      }
    } else if (absDuration < 60) {
      if (duration < 2) {
        if (duration == 0) {
          return '<1 '+ this.ts.instant('min');
        }
        return (duration + ' ' +this.ts.instant('min'));
      } else {
        return (duration + ' '+this.ts.instant('mins'));
      }
    }
  }
  // if (!value) return  items;
  // if (value == '' || value == null) return [];
  // return items.filter(e => e.toLowerCase().indexOf(value) > -1 );
}


