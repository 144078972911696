import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { PopupService } from "@app/popup-module/popup.service";
import { DynamicFormComponent } from "@app/shared/dynamicform/dynamic-form/dynamic-form.component";
import { FieldConfig } from "@app/shared/dynamicform/models/field-config.interface";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'update-confirmation-detail',
    templateUrl: './update-confirmation-detail.component.html',
    styleUrls: ['./update-confirmation-detail.component.scss']
})
export class UpdateConfirmationDetailComponent implements OnInit {
    formConfig: FieldConfig[] = [];
    inputData;
    @ViewChild('form', { static: true }) form: DynamicFormComponent;

    constructor(private popupService: PopupService, public dialogRef: MatDialogRef<UpdateConfirmationDetailComponent>, private translateService: TranslateService) {

    }

    @Input('data') data: {
        inputData: {
            title: string,
            subText: string,
            changes: {
                label: string,
                newValue: string,
                oldValue: string,
                time: string
            }[]
        }
        formConfig
    }

    ngOnInit() {
        this.inputData = this.data.inputData;
        this.formConfig = this.data.formConfig || [];
        this.popupService.restrictCloseDialog = true;
        this.popupService.cancelledAction$.subscribe(cancelled => {
            this.dialogRef.close({ action: "cancelled" });
        })
        this.popupService.confirmedAction$.subscribe(confirmed => {
            this.dialogRef.close({ action: "confirmed", data: this.form?.form?.value });
        })
    }


}