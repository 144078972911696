import { Injectable } from "@angular/core";
import { RetailDataAwaiters } from "../../../retail/shared/events/awaiters/retail.data.awaiters";
import { MatDialog } from "@angular/material/dialog";
import { RetailStandaloneLocalization } from "../../../core/localization/retailStandalone-localization";
import { RouteLoaderService } from '@app/core/services/route-loader.service';
import { PayeeInfo } from '@app/retail/shared/business/shared.modals';
import { ClientDataService } from '../../data-services/client.data.service';
import { ClientSearchModel, Client, ClientInfo } from '@app/client/client-popup/create-client/client.modal';
import { DefaultGUID } from '@app/common/shared/shared/globalsContant';
import { ClientPopupComponent } from '@app/client/client-popup/client-popup.component';
import { UserdefaultsInformationService } from '@app/core/services/UserdefaultsInformationService';
import { first } from 'rxjs/operators';
import { NotificationDataService } from '../../data-services/notification.data.service';
import { NotifyPopupComponent } from '../../components/notify-popup/notify-popup.component';
import { BreakPoint } from '../../models/breakpoint-models';
import { UserAccessBusiness } from '@app/common/dataservices/authentication/useraccess.business';
import * as RetailClientInfo from '@app/retail/shared/shared.modal';
import { NotificationConfigurationService } from "@app/common/templates/notification-configuration/notification-configuration.service";
import { EventNotificationGroup } from "@app/common/templates/notification-configuration/notification-configuration.model";
import { RetailBussinessService } from "@app/shared/retail.bussiness";
import { GuestBookService } from "../guestbook.service";
import { PropertySettingDataService } from "src/app/retail/sytem-config/property-setting.data.service";
@Injectable({
    providedIn: "root"
})
export class DataAwaiterService {

    captions = this.localization.captions.bookAppointment;

    constructor(
        private dialog: MatDialog,
        private localization: RetailStandaloneLocalization,
        private routeLoaderService: RouteLoaderService,
        private clientDataService: ClientDataService,
        private userDefaultService: UserdefaultsInformationService,
        private notificationDataService: NotificationDataService,
        private userAccessBusiness : UserAccessBusiness,
        private notificationConfigurationService: NotificationConfigurationService,
        private propertySettingDataService: PropertySettingDataService,
        private rb:RetailBussinessService,
        private guestBookService:GuestBookService
    ) {
        this.setAwaiters();
    }

    private setAwaiters(): void {
        RetailDataAwaiters.GetChildMenu = this.getChildMenu.bind(this);
        RetailDataAwaiters.searchPayee = this.searchClient.bind(this);
        RetailDataAwaiters.searchTransactionGuest = this.searchClient.bind(this);
        RetailDataAwaiters.CreatePlayer = this.createClient.bind(this);
        RetailDataAwaiters.openAddPayeePopup = this.openAddGuestPopup.bind(this);
        RetailDataAwaiters.getPayeeDetails = this.getClientDetails.bind(this);
        RetailDataAwaiters.getPayeeInfo = this.getClientInfo.bind(this);
        RetailDataAwaiters.getMemberInfo = this.getMemberInfo.bind(this);
        RetailDataAwaiters.GetDefaultOutlet = this.GetDefaultOutlet.bind(this);
        RetailDataAwaiters.SendNotification = this.SendNotification.bind(this);
        RetailDataAwaiters.OpenManualNotifyPopup = this.OpenManualNotifyPopup.bind(this);
        RetailDataAwaiters.getGuestStayDetails = this.getGuestStayDetails.bind(this);
        RetailDataAwaiters.GetExistingPlayer = this.getExistingPlayer.bind(this);
        RetailDataAwaiters.openGuestPatronPopup = this.openGuestPatronPopup.bind(this);        
        RetailDataAwaiters.searchPayee = this.searchClient.bind(this);  
        // Activities Retail changes
        RetailDataAwaiters.ProcessPartyTransaction = this.ProcessPartyTransaction.bind(this); 
        RetailDataAwaiters.SaveRetailOrderDetails = this.SaveReatilOrderDetails.bind(this);
        RetailDataAwaiters.getActivitiesGuestInfo=this.getActivitiesGuestInfo.bind(this); 
        RetailDataAwaiters.getGuestForOrder = this.getsearchGuestForOrder.bind(this);
        RetailDataAwaiters.GetExtendedProfileSearchConfig = this.GetExtendedProfileSearchConfig.bind(this);
    }

    getChildMenu(url, menutype?) {
        return this.routeLoaderService.GetChildMenu(url, menutype);
    }

    async openGuestPatronPopup(e, callback: Function, id, guestId , fromEdit) {
        this.openAddGuestPopup(e, callback, id, guestId, null, fromEdit);
     }
    private async getExistingPlayer(patronId) {
        let client = await this.clientDataService.searchClientByPatron(patronId);
        return client;
    }

    /* private async searchClient(name: string, requestUid: string): Promise<[ClientSearchModel[], PayeeInfo[]]> {
        let response: any = await this.clientDataService.searchClient(name, requestUid);

        let clientDetails: PayeeInfo[] = [];
        let responseUid = "";
        if (response) {
            responseUid = response[0].requestUid;

            if ((requestUid != "" && responseUid != "" && requestUid == responseUid) || (requestUid == "" || responseUid == "")) {
                response.forEach(client => {
                    client.playerCategoryId = 1;
                    clientDetails.push(this.BuildPayeeData(client));
                });
            }
        }
        return [response, clientDetails];
    } */

    private BuildPayeeData(client: ClientSearchModel): PayeeInfo {
        let emailId = '';
        let phoneNo = '';
        let emailObj = client.emails;
        let phoneObj = client.phoneNumbers;

        if (emailObj && emailObj.length) {
            emailObj = emailObj.sort((a, b) => a.contactTypeId < b.contactTypeId ? -1 : a.contactTypeId > b.contactTypeId ? 1 : 0);
            emailId = emailObj.find(x=> !x.isPrivate && x.isPrimary) ? emailObj.find(x=> !x.isPrivate && x.isPrimary).emailId :  emailObj[0].emailId;
        }

        if(phoneObj && phoneObj.length) {
            phoneObj = phoneObj.sort((a, b) => a.contactTypeId < b.contactTypeId ? -1 : a.contactTypeId > b.contactTypeId ? 1 : 0);
            phoneNo = phoneObj.find(x=> !x.isPrivate && x.isPrimary) ? phoneObj.find(x=> !x.isPrivate && x.isPrimary).number :  phoneObj[0].number;
        }

        let payee: PayeeInfo = {
            id: client.id,
            name: client.firstName + ' ' + client.lastName,
            address: client.addresses ? (client.addresses.addressLine1 ? client.addresses.addressLine1 : ''  + ' ' + client.addresses.state ? client.addresses.state : '') : '',
            country: client.addresses ? client.addresses.country ? client.addresses.country : '' : '',
            zip: client.addresses ? client.addresses.zipCode ? client.addresses.zipCode : '' : '',
            city: client.addresses ? client.addresses.city ? client.addresses.city : '' : '',
            guestProfileId: client.memberId ? client.memberId : client.guestId,
            cardInfo: client.clientCreditCardInfo ? client.clientCreditCardInfo : [],
            patronId: client.loyaltyDetail && client.loyaltyDetail[0] ? client.loyaltyDetail[0].patronId : '',
            rank: client.loyaltyDetail && client.loyaltyDetail[0] ? client.loyaltyDetail[0].rank : '',
            playerCategoryId: client.clientCategoryId != 0 ? client.clientCategoryId : 1,
            emailId: emailId,
            phoneNumber: phoneNo,
            lastName: client.lastName,
            platformGuestUuid: client.platformGuestUuid
        };
        return payee;
    }

    private async createClient(clientobj, callback): Promise<any> {
        const response = await this.clientDataService.CreateClientDetails(this.MapToClientInfoObj(clientobj));
        callback(response.id);
    }

    private MapToClientInfoObj(clientobj) {
        return {
            id: clientobj.id,
            client: {
                id: clientobj.id,
                guestId: clientobj.playerCategoryId == 1 && clientobj.guestId ? clientobj.guestId : DefaultGUID,
                title: clientobj.title ? clientobj.title : "",
                firstName: clientobj.firstName ? clientobj.firstName : "",
                lastName: clientobj.lastName ? clientobj.lastName : "",
                pronounce: clientobj.pronounce ? clientobj.pronounce : "",
                gender: clientobj.gender ? clientobj.gender : "",
                dateOfBirth: clientobj.dateOfBirth ? clientobj.dateOfBirth : "",
                comments: clientobj.comments ? clientobj.comments : "",
                lastChangeId: clientobj.lastChangeId ? clientobj.guestId : DefaultGUID,
                interfaceGuestId: clientobj.interfaceGuestId ? clientobj.interfaceGuestId : "",
                loyaltyDetail: clientobj.loyaltyDetail ? clientobj.loyaltyDetail : [],
                memberId: clientobj.playerCategoryId == 3 && clientobj.playerLinkId ? clientobj.playerLinkId : null,
                clientCategoryId: clientobj.playerCategoryId
            } as Client,
            emails: clientobj.emails,
            addresses: clientobj.addresses,
            phoneNumbers: clientobj.phoneNumbers,
            clientCreditCardInfo: clientobj.clientCreditCardInfo ? clientobj.clientCreditCardInfo : null
        } as ClientInfo;
    }

    async openAddGuestPopup(e, callback: Function,id,guestId,LineItem, fromEdit) {
        if (e.toLowerCase() == "ordersummary" ) {
            var result = await this.userAccessBusiness.getUserAccess(BreakPoint.AddNewClientProfile);
            if (result.isAllow || result.isViewOnly) {
                this.guestBookService.addGuest(fromEdit,id);
            }
        } else if(e.toLowerCase() == "ordersummaryedit") {
            var result = await this.userAccessBusiness.getUserAccess(BreakPoint.EditClientProfile);
            if (result.isAllow || result.isViewOnly) {
                this.guestBookService.addGuest(fromEdit,id);
            }
        }

        let subscription = this.guestBookService.createdGuestData$.subscribe(async data => {
            if (data?.Payload?.Id) {
                let response  =  await this.rb.GetPartyById(data.Payload?.Id);
                subscription.unsubscribe();
                this.guestBookService.createdGuestData$.next(null)
                callback ? callback(response) : null;
            }
        });

    }
  /*   private async getClientDetails(id: number[]): Promise<PayeeInfo[]> {
        let response: any = await this.clientDataService.getClients(id);
        let clientDetails: PayeeInfo[] = [];
        if (response && response.length > 0) {
            response.forEach(client => {
                clientDetails.push({
                    id: client.id,
                    name: client.firstName + ' ' + client.lastName,
                    guestProfileId: client.guestId,
                    address: '',
                    country: '',
                    city: '',
                    zip: '',
                    cardInfo: []
                });
            });
        }
        return clientDetails;
    } */

    // private async getClientInfo(id: number): Promise<PayeeInfo> {
    //     let response: any = await this.clientDataService.getClients([id]);
    //     let clientDetails: PayeeInfo;
    //     if (response && response.length > 0) {
    //         const client = response[0];
    //         clientDetails = this.BuildPayeeData(client);
    //     }
    //     return clientDetails;
    // }

    GetDefaultOutlet() {
        return this.userDefaultService.GetDefaultOutlet();
    }

    async SendNotification(clientInfo: RetailClientInfo.ClientInfo, emailId: any[] = [], isDistributionListRequired: boolean = true) {
        const eventConfiguration: EventNotificationGroup[] = await this.notificationConfigurationService.GetEventNotificationGroupByProduct();
        const guesteventConfiguration = eventConfiguration.filter(x => x.groupName === "Guest");
        let canSendemail: boolean, canSendSMS: boolean ;
        if(guesteventConfiguration && guesteventConfiguration.length > 0){
            canSendemail = guesteventConfiguration[0].sendMail;
            canSendSMS = guesteventConfiguration[0].sendSMS;
        }
        this.notificationDataService.SendNotification(clientInfo.transactionId, false, emailId, '', canSendSMS,canSendemail,clientInfo.reportQuery,true,isDistributionListRequired);
    }
    OpenManualNotifyPopup(transactionId: number, guestId: number ) {
        let dialogRef = this.dialog.open(NotifyPopupComponent, {
            width: '85%',
            height: '75%',
            disableClose: true,
            hasBackdrop: true,
            data:  { mode: 'EDIT', title: this.captions.notify, type: this.captions.Update, guestId :guestId, transactionId: transactionId, closebool: true },
            panelClass: 'small-popup'
        });
    }

    private async getGuestStayDetails(guestId: string) {
        return await this.clientDataService.getGuestStayDetails(guestId);
    }
    
    // Activities Retail changes
    private async getClientInfo(id: number): Promise<PayeeInfo> {
        if(this.rb.shopService.isOperationFromActivities){
        return this.rb.GetPartyById(id);
        }
    else{
             let response: any = await this.clientDataService.getClients([id]);
        let clientDetails: PayeeInfo;
        if (response && response.length > 0) {
            const client = response[0];
            clientDetails = this.BuildPayeeData(client);
        }
        return clientDetails;
    }
    }
    private async getClientDetails(id: number[]): Promise<PayeeInfo[]> {
        return this.rb.getActivitiesGuestInfo(id); 
    }  

    private async searchClient(name: string,type:number, requestUid: string): Promise<PayeeInfo[]> {      
        return this.rb.searchGuest(name,type);        
    }

    private async ProcessPartyTransaction(trasactionInfo,component): Promise<any> {      
        return this.rb.ProcessPartyTransaction(trasactionInfo,component);        
    }

    private async SaveReatilOrderDetails(transactionInfo, directSale?, linkId?,partyDetails?): Promise<any> {
        return this.rb.SaveRetailOrderDetails(transactionInfo, partyDetails, directSale, linkId)
      }

    private async getActivitiesGuestInfo(name): Promise<PayeeInfo[]> {      
        return this.rb.getActivitiesGuestInfo(name);        
    }

    private async getsearchGuestForOrder(name: string,type:number) {
        return await this.rb.searchGuestForOrder(name,type);
    }

    private async getMemberInfo(cardNo: string, scheduleDateTime: string){
        return await this.clientDataService.getMemberInfo(cardNo, scheduleDateTime);
    }

    private async GetExtendedProfileSearchConfig() : Promise<boolean>
    {
        let platformGuestSearch = await this.propertySettingDataService.GetEnableExtendedProfileSearchByDefaultSetting();
        return platformGuestSearch && platformGuestSearch.value === 'true' ? true : false;
    }

}
