<!-- Cancelled  Session header -->

<div class="cancelled-session" *ngIf="sessionData?.StatusCode == statusMaster.Cancelled">
    <div >{{sessionData.SessionDate | localizedDate: 'DD MMM yyyy'}}  </div><div class="cancel-highlight">{{'sessionCancelled' | translate}}  </div><div>  - {{sessionData.Reason}} | </div><div>{{'cancelledBy' | translate}} : {{cancelledHostName}}</div>
</div>

<!-- Modify Session header -->
<div class="session-mapping-date" *ngIf="sessionData?.StatusCode !== statusMaster.Cancelled && !isModifySingleSession && sessionData?.SessionDate">
    {{sessionData.SessionDate | localizedDate: 'DD MMM yyyy'}}
</div>


<div *ngIf="noInstructorsWarning && !isTemplate" class="alert alert-warning" role="alert">
    {{'NoInstructorsWarning' | translate}}
</div>

<div *ngIf="hasBookedReservationWarning && sessionData?.IsBooked && !isTemplate" class="alert alert-warning" role="alert">
    {{'bookedSessionReservationWarningMessage' | translate}}
</div>

<dynamic-form [config]="sessionDetailsConfig" #sessionDetails></dynamic-form>