import { Component, OnInit } from '@angular/core';
import { TaxExemptCategoryBusiness } from './tax-exempt-category.business';
import { UI } from './tax-exempt-category.model';
import { RetailSettingBaseComponent } from '../../setup.base.component';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { TaxExemptCategoryDataService } from './tax-exempt-category.data.service';
import { HeaderInput, InputActionPlaceHolder } from 'src/app/common/Models/ag-models';
import { FromTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { AlertType, TableActions } from 'src/app/common/enums/shared-enums';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { FlagSelectorPipe } from 'src/app/common/components/ag-phone-number/ag-phone-number.component';

@Component({
  selector: 'app-taxexemptcategory',
  templateUrl: './tax-exempt-category.component.html',
  styleUrls: ['./tax-exempt-category.component.scss'],
  providers: [TaxExemptCategoryBusiness, TaxExemptCategoryDataService]
})

export class TaxExemptCategoryComponent extends RetailSettingBaseComponent<UI.TaxExemptCategory> implements OnInit {

  inputPlaceHolder: InputActionPlaceHolder;
  isEditMode: boolean;
  disableInputHeader: any;
  originalData$: Promise<any[]>;
  originalData: Observable<any[]>;
  clearInput = false;
  noSpaceAllowed=true;
  editObj: HeaderInput;
  constructor(
    private _taxExemptCategoryBusiness: TaxExemptCategoryBusiness,
    _utilities: RetailUtilities,
    userAccess: UserAccessBusiness,
    private localization: RetailLocalization
  ) {
    super(_taxExemptCategoryBusiness, _utilities, userAccess)
  }

  ngOnInit() {
    this.captions = this._taxExemptCategoryBusiness.captions;
    this.createTitle = this.captions.btn_TaxExemptCategory;
    this.editTitle = this.captions.lbl_editTaxExemptCategoryTitle;
    this.enablePagination = false;
    this.inputPlaceHolder = {
      code: this.captions.TaxExemptCategorylbl_Code,
      name: this.captions.TaxExemptCategorylbl_Name,
      errorMessageCode: this.captions.TaxExemptCategoryerr_missing_Code,
      errorMessageName: this.captions.TaxExemptCategoryerr_missing_Name
    };
    this.loadData();
  }

  async loadData(){
    this.utils.ToggleLoader(true,this.localization.captions.setting.lbl_processing);
    this.getValidators();
    this.generateTableData();
    await this.validateUserAccess();
    this.refreshGrid();
  }

  generateTableData() {
    this.setSearchHeaderOption(this.captions.btn_TaxExemptCategory, this.captions.lbl_searchTaxExemptCategoryPlaceholder);
    this.headerOptions = [];
    this.addHeaderOption(this.captions.TaxExemptCategory_grid_hdr_Code, 'code', 'code', true, 'taxexempt_grid_hdr_Code');
    this.addHeaderOption(this.captions.TaxExemptCategory_grid_hdr_Name, 'name', 'name', true);
    this.addDefaults();
  }

  async tableAction($event) {
    
    if ($event.fromType === FromTypeEnum.delete || ($event.fromType === FromTypeEnum.switch && !$event.value)) {
      const res = await this._taxExemptCategoryBusiness.validateDelete($event.Obj.id);
      if (res.length > 0) {
        if ($event.fromType === FromTypeEnum.delete) {
          this.utils.showAlert(this.localization.getError(100923), AlertType.Warning);
        }
        if ($event.fromType === FromTypeEnum.switch) {
          this.utils.showAlert(this.localization.getError(100924), AlertType.Warning);
        }
      } else {
        await super.tableAction($event);
        this.clearInput = true;
      }
    } else {
      await super.tableAction($event);
    }
  }

  async editEvent(rowData) {
    rowData['toggleShow'] = true;
    rowData['isActive'] = rowData['isActive']?.value;
    this.editObj = { ...rowData };
    this.isEditMode = true;
    this.searchHeaderOption = {
      searchPlaceHolder: this.searchHeaderOption.searchPlaceHolder,
      toggleLabel: this.searchHeaderOption.toggleLabel,
      showInactive: false,
      toggleDisabled: false,
      createBtnDisabled: true,
      hasHeaderAction: true,
      searchBarhide: true
    };
    this.utils.ToggleLoader(false);
  }
  cancel(event) {
    this.IsEditModeEnabledFlag = false;
    this.isEditMode = false;
    this.searchHeaderOption = {
      createBtnLabel: this.searchHeaderOption.createBtnLabel,
      searchPlaceHolder: this.searchHeaderOption.searchPlaceHolder,
      toggleLabel: this.searchHeaderOption.toggleLabel,
      showInactive: true,
      toggleDisabled: false,
      createBtnDisabled: true,
      hasHeaderAction: true,
      searchBarhide: false
    };
  }

  saveCancelCodes(event) {
    this.clearInput = false;
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    if (this.isEditMode) {
      this.searchHeaderOption.searchBarhide = false;
      this.searchHeaderOption.showInactive = true;
      this.business.update(event).then(x => {
        this.utils.ToggleLoader(false);
        this.refreshGrid();
        this.isEditMode = false;
        this.clearInput = true;
      });
    } else {
      this.business.create(event).then(x => {
        this.utils.ToggleLoader(false);
        this.refreshGrid();
        this.clearInput = true;
      });
    }
  }

  setSearchHeaderOption(createBtnLabel, searchPlaceHolder) {
    this.searchHeaderOption = {
      createBtnLabel,
      searchPlaceHolder,
      toggleLabel: this.captions.tog_lbl_showInactive,
      showInactive: true,
      toggleDisabled: this.isViewOnly,
      createBtnDisabled: this.isViewOnly,
      hasHeaderAction: true,
      maxLength: this.settingsSearchTextMaxLength
    };
  }

  setTableOptions() {
    this.options = {
      actions: [
        {
          type: TableActions.edit,
          disabled: false
        }, {
          type: TableActions.delete,
          disabled: this.isViewOnly
        },
        {
          type: TableActions.drag,
          disabled: this.isViewOnly
        }
      ],
      defaultsortingColoumnKey: this.defaultSortingKey,
      defaultSortOrder: SorTypeEnum.asc,
      deleteMsgKey: this.deleteMsgKey,
      isDragDisabled: this.isViewOnly,
      showTotalRecords: true,
      columnFreeze: {
        firstColumn: false,
        lastColumn: true
      },
      enablePagination: this.enablePagination
    };
  }

  refreshGrid() {
    this.utils.ToggleLoader(true);
    if (this.form) {
      this.form.reset({
        isActive: true,
        id: 0,
        listOrder: 0
      });
    }
    this.originalData$ = this.business.getAllEntries(this.showInactiveTog);
    this.originalData$.then(response => {
      this.originalData = of(response);
        this.tableContent = this.originalData;
    }).finally(()=>{
      this.utils.ToggleLoader(false);
    });
  }

}
