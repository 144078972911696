<section class="cms-patron-selection">
    <div class="subhdr-msg">{{ShopCaptions.CMS.PatronSelectionDialog.subheaderMsg}}</div>
    <form [formGroup]="cmsPlayersForm">
        <div class="player-list-cont">
            <mat-radio-group [attr.automationId]="'Rdo_shopCMSPatronSelection_cmsPlayerSelection'" formControlName="cmsPlayerSelection">
                <div class="payment-type-row d-flex" *ngFor="let playerInfo of cmsPlayers">
                    <mat-radio-button (click)="selectPatron(playerInfo)" [value]="playerInfo.additionalInformation.PatronId">
                    </mat-radio-button>
                    <div class="d-flex cms-player-type">
                        <div class="flx-grw">{{playerInfo.additionalInformation?.PatronName}} ({{playerInfo.additionalInformation?.PatronId}}) |
                            {{_sbs.GetCMSHandleName(redemptionTypeMap[playerInfo.additionalInformation?.RedemptionType])}}</div>
                        <div>| {{playerInfo.additionalInformation?.Amount | Currency}}</div>
                    </div>
                </div>
            </mat-radio-group>
        </div>
        <div class="payment-footer">
            <button [attr.automationId]="'Btn_shopCMSPatronSelection_ok'" [ngClass]="cmsPlayersForm.dirty ? 'button--primary-save' : 'button--disabled'"
                [disabled]="cmsPlayersForm.pristine" mat-button
                class="mat-raised-button whitecolor LW14 ml-3 text-capital"
                (click)="closeDialog()">{{localization.captions.common.OK}}</button>
        </div>
    </form>
</section>