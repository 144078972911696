import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, HostListener, ViewEncapsulation, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { NgScrollbar } from 'ngx-scrollbar';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { UserAlerts } from '../../common/config/alerts-config';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { AlertMessagePopupComponent } from '../../shared/alert-message-popup/alert-message-popup.component';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import * as _ from 'lodash';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';


@Component({
  selector: 'app-retail-setup-table',
  templateUrl: './retail-setup-table.component.html',
  styleUrls: ['./retail-setup-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailSetupTableComponent implements OnInit, OnChanges {

  @ViewChild('scroll') scrollvalue;
  @Input() isEditModeSetFromParent;
  @Input() options;
  @Input() isRoleSetUpReadOnly;
  @Input() isOnEditDisableFunctionAvailable;
  @Input() scrollPositionReset;
  @Output() afterEditClose: EventEmitter<any> = new EventEmitter();
  @Output() dragDropEvt: EventEmitter<any> = new EventEmitter();
  @Output() deleteEvt: EventEmitter<any> = new EventEmitter();
  @Output() editEvt: EventEmitter<any> = new EventEmitter();
  @Output() InActiveTherapistEvt: EventEmitter<any> = new EventEmitter();
  @Output() RowSelectEmitter: EventEmitter<any> = new EventEmitter();
  @Output() userActionEvt: EventEmitter<any> = new EventEmitter();
  @Output() CurrentRowSelectedEmitter: EventEmitter<any> = new EventEmitter();
  @Output() toggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() addClick: EventEmitter<any> = new EventEmitter();
  @Output() dropDownChange: EventEmitter<any> = new EventEmitter();
  @Output() printEvt: EventEmitter<any> = new EventEmitter();
  @Output() inactiveToggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() doneEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() LBLClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() radioClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openWaitlist: EventEmitter<any> = new EventEmitter();
  @Output() cancelEmitter: EventEmitter<any> = new EventEmitter();
  @Output() scrollEmitter: EventEmitter<any> = new EventEmitter();
  @Output() sortEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('tableInput') tableInput: ElementRef;
  @ViewChild(NgScrollbar) scrollRef: NgScrollbar;
  skipValidationHeaderComp: boolean = true;
  showErrForValidationHeaderComp: boolean = false;
  customEditCanceled: boolean; 
  isEditModeSet: boolean = false; 
  inlineEditOn: boolean = false;
  edittedIndex: any;
  groupSort: boolean;
  groupSortType: any;
  groupSortJsonKey: string;
  isScrollAvailable: boolean = true;
  isDataLoading: boolean = false;
  hideScroll: boolean;
  freezeScroll: boolean;
  bufferCount: any;
  floatLabelNever: string;
  isEatec: boolean;
  totalMsg: string;
  ShowNoOfRecordInGrid = myGlobals.RetailFunctionalities.ShowNoOfRecordInGrid;
  functionalities: { [key: string]: boolean } = {};
  @Input('searchvalue')
  set searchvalue(val) {
    this.searchText = val;
  }
  @Input() automationId: string ='';
  @Input('customEditCancelled')
  set customEditCancelled(val) {
    if (val) {
      this.onButtonCancelClick(val);
    }
  }

  SelectedData: any = [];
  sortingColoumn: any;
  setinterscroll: any;
  editEvent: any;
  InActiveTherapistChkBoxEvt: any;
  orderTypearr: any = [];
  orderType: any = 'asc';
  hdrArray: any = [];
  bodyArray: any = [];
  selectedDefaultHeader: any;
  searchText: any;
  hdrkeyArray: any = [];
  originalHdrKeyArray: any = [];
  Sortable: any;
  highlight: any;
  NewerData: any;
  currentPage: any = 1;
  givenOrderType = 'asc';
  EditMoreOption: any;
  defaulSortColumn: any;
  dropdownOptions: any = [];
  captions: any = {};
  disableDelete: any;
  toggleDisplayText: any;
  editableRow: any;
  tempTableData: any;
  editRecordsArray: any = [];
  retailHeaderMaxLength: number;
  retailHeaderMinLength: number;
  SelectRow: boolean = false;
  enableToggleButton: boolean = false;
  IfBooleanCheck: boolean = false;
  SetColumnGridValue: boolean = false;
  CheckInnerBoolValue: boolean = false;
  enableRowCheck: boolean = false;
  IsViewOnly: boolean = false;
  IsCheckAll: boolean = false;
  EnableActions: boolean;
  EnablePagination: boolean = true;
  ChkInactiveService: boolean = false;
  blnDraggable: boolean = true;
  editable: boolean = true;
  waitlistIcon: boolean = false;
  customHeader: boolean = false;
  sticky: boolean = false;
  DoneCancel: boolean = false;
  viewCheckedFlag: boolean = false;
  IsRetailCodeSetup: boolean;
  disableEditButton: boolean; 
  isValidRoleName: boolean = false;
  isEdit: boolean;
  isReadOnly: boolean = false;
  isCancelAvailable: boolean;
  enableTerminal: boolean;
  headerOptions: any;

  SelectedSettingId: number | string = 1;
  inputLength: number;

  PlaceHoldertext: string;
  overriddenSortColumn: string;
  sortColumnDataType: string;
  pageTitle: string;
  customHeaderButton: string;
  setupName: string;
  errorMessage: string;

  outlets: UntypedFormGroup;
  quicksale: UntypedFormGroup;
  measures: UntypedFormGroup;
  discounttype: UntypedFormGroup;
  payment: UntypedFormGroup;
  mailOrderSource: UntypedFormGroup;
  table: UntypedFormGroup;
  formGroupName: UntypedFormGroup;

  GridType = myGlobals.GridType;
  RetailTransactions = myGlobals.RetailTransactions;
  categorygroup: UntypedFormGroup;
  carriers: UntypedFormGroup;
  showItem: boolean[] = [];
  phoneLength: any[] = [];
  showText: string[] = [];
  showLess: boolean[] = [];
  isSyncItem = true;
  constructor(public dialog: MatDialog, private userAlerts: UserAlerts, public el: ElementRef, public fb: UntypedFormBuilder, public localization: RetailLocalization, private _cdRef: ChangeDetectorRef, public PropertyInfo: RetailPropertyInformation,private utilities:RetailUtilities, private func: RetailFunctionalityBusiness) {
    this.isSyncItem = PropertyInfo.UseRetailInterface;
    this.table = this.fb.group({
      IsCheckAll: false,
      tablebody: this.fb.array([this.fb.group({ id: '', activetoggle: false, donecancel: false, category: "" })])
    });
    this.outlets = this.fb.group({
      setupCodeName: ['', [Validators.required, Validators.minLength(2)]],
      terminalId: [''],
      activetoggle: [false]
    });
    this.measures = this.fb.group({
      setupCodeName: ['', Validators.required],
      activetoggle: [false]
    });
    this.quicksale = this.fb.group({
      setupCodeName: ['', Validators.required],
      activetoggle: [false]
    });
    this.discounttype = this.fb.group({
      setupCodeName: ['', [Validators.required, Validators.minLength(3)]],
      activetoggle: [false]
    });
    this.payment = this.fb.group({
      setupCodeName: ['', Validators.required],
      activetoggle: [false]
    });
    this.mailOrderSource = this.fb.group({
      setupCodeName: ['', Validators.required],
      activetoggle: [false]
    });
    this.categorygroup = this.fb.group({
      setupCodeName: ['', [Validators.required, Validators.minLength(2)]],
      activetoggle: [false]
    });
    this.carriers = this.fb.group({
      setupCodeName: ['', Validators.required],
      activetoggle: [false]
    })
    this.floatLabelNever = this.localization.setFloatLabelNever;
    this.captions = this.localization.captions;
    this.customHeaderButton = this.localization.captions.setting.Add;
    this.isEatec= this.PropertyInfo.IsEatecEnabled;
    this.enableCancelButton();
  }

  ngOnChanges() {
    if (this.isEditModeSetFromParent != undefined) {
      this.changeEditMode(this.isEditModeSetFromParent);
    }
    this.showText = this.captions.retailsetup.showMore;
    this.editRecordsArray = [];
    this.viewCheckedFlag = false;
    this.table.value.tablebody = this.CreateTablerowFormGroup(this.options);
    if(this.functionalities[myGlobals.RetailFunctionalities.ShowNoOfRecordInGrid]){
      this.totalMsg = this.localization.replacePlaceholders(this.captions.lbl_totalRecordsFoundMsg, ['count'], [this.table.value.tablebody.length]);
    }
    this.tempTableData = _.cloneDeep(this.options);
    this.IsCheckAll = false;
    if (typeof this.options == "undefined") { this.isDataLoading = true; return; }
    this.isDataLoading = false;
    this.bufferCount = this.options[0].bufferCount;
    this.isReadOnly = this.options[0].IsReadOnly ? true : false;
    this.SelectRow = this.options[0].SelectRows;
    this.DoneCancel = this.options[0].DoneCancel;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    this.blnDraggable = this.options[0].TableDraggable;
    this.editable = this.options[0].Editable != undefined ? this.options[0].Editable : true;
    this.waitlistIcon = this.options[0].waitlistIcon != undefined ? this.options[0].waitlistIcon : false;
    this.SelectedSettingId = this.options[0].SelectedSettingId;
    this.sticky = this.options[0].sticky ? this.options[0].sticky : false;
    this.hdrArray = this.options[0].TableHdrData;
    this.originalHdrKeyArray = this.options[0].TableHdrData;
    this.bodyArray = [];
    this.bodyArray = this.options[0].TablebodyData;
    this.SelectedData = [];
    this.enableTerminal = this.options[0].enableTerminal;
    this.headerOptions = this.options[0].headerOptions;
    this.showLess = [];
    let index = 0; 
    this.bodyArray.forEach(element => {
      if (this.SelectedSettingId == myGlobals.GridType.retailSuppliers) {
        let totalVal = '';
        this.showLess.push(true);
        this.showItem.push(true);
        this.showText.push(this.captions.retailsetup.showMore);
        for (let i = 0; i < element.phoneDetails.length; i++) {
          totalVal = totalVal + element.phoneDetails[i].PhoneNumber;
          if (i != (element.phoneDetails.length - 1)) {
            totalVal = totalVal + ' , ';
          }
        }
        this.phoneLength.push(totalVal);
        index++;
      }
      if (element.checked != null && element.checked)
        this.SelectedData.push(element);
      index++;
      element['printqty'] =element['printqty'] ? element['printqty'] : 0;
    });
    this.NewerData = this.options[0].NewData;
    this.NewerData = this.bodyArray.filter(o => {
      if (this.NewerData) {
        return ((o.code && o.code == this.NewerData.code) || (o.name && o.name == this.NewerData.name) || (o.addOnName && o.addOnName == this.NewerData.addOnName));
      }
    });
    if (this.NewerData[0]) {
      this.setinterscroll = setInterval(() => {
        const tempCur = (this.NewerData[0] ? this.NewerData[0].id : '');
        let curiddata = this.NewerData ? tempCur : '';
        this.autoscrolltocurrtime(curiddata);
      }, 500);

    }
    this.searchText = this.options[0].TableSearchText ? this.options[0].TableSearchText : this.searchText;
    this.hdrkeyArray = [];
    if (this.hdrArray) {
      for (let item of this.hdrArray) {
        if (item.searchable != undefined ? item.searchable : true)
          this.hdrkeyArray.push(item.jsonkey);
      }
    }
    this.PlaceHoldertext = this.options[0].PlaceHoldertext;
    this.EnableActions = this.options[0].EnableActions;
    this.disableDelete = this.options[0].disableDelete;
    this.EnablePagination = this.options[0].pagination;
    this.ChkInactiveService = this.options[0].InactiveService;
    this.EditMoreOption = this.options[0].EditMoreOption;
    this.customHeader = this.options[0].customHeader;
    this.pageTitle = this.options[0].pageTitle;
    this.setCodeSetupValues();
    this.dropdownOptions = this.options[0].dropdownOptions;
 
    if (this.ChkInactiveService)
      this.InactiveService({ checked: false });
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    

    this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    setTimeout(() => {
      this.customtablealignment();
      if (this.scrollPositionReset) this.scrollvalue.scrollToIndex(-1);
    }, 1);
    this.IsViewOnly = this.options[0].IsViewOnly;
    this.disableEditButton = this.options[0].disableEditButton;
    this.IsRetailCodeSetup = (this.options[0].ServiceId == 'quicksale' || this.options[0].ServiceId == 'measures' || this.options[0].ServiceId == 'categoryGroup' || this.options[0].ServiceId == 'RetailCategories' || this.options[0].ServiceId == 'outlets' || this.options[0].ServiceId == 'payment' || this.options[0].ServiceId == 'mailOrderSource' || this.options[0].ServiceId == 'carriers' || this.options[0].ServiceId == 'discounttype');
    this.calwidthwithtimeout();
    this.resetForm();
  }

  ngOnInit() {
    if (typeof this.options == "undefined") { return; }

    this.bufferCount = this.options[0].bufferCount;
    this.defaulSortColumn = this.options[0].Sortable;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = this.selectedDefaultHeader ? this.selectedDefaultHeader : temp;
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    this.selectedDefaultHeader = this.Sortable;
    let overriddenSortColumn = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumn : null;
    let SortColumnDataType = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumndatatype : null;
    this.orderTypearr = [];
    this.inputLength = 250;
    this.sortHeader(this.selectedDefaultHeader, overriddenSortColumn, SortColumnDataType, this.sortingColoumn, 'onInit',  this.groupSort, this.groupSortType, this.groupSortJsonKey);
  }

  async ngAfterViewInit() {
    this.functionalities = await this.func.getRetailFunctionality();
    this.calwidthwithtimeout();
    this.calcShowMore();
    this._cdRef.detectChanges();
  }

  ngAfterViewChecked() {
    if (!this.viewCheckedFlag) {
      this.viewCheckedFlag = true;
      this.calwidthwithtimeout();
      this.calcShowMore();
    }
    this._cdRef.detectChanges();
  }

  onDragOver(event, ele, newarr) {
    event.preventDefault(); 
  }

  onItemDrop(event, ele, newarr) {
    let draggeddata = event.dragData;
    let droppeddata = ele;
    let dragindex = newarr.indexOf(draggeddata);
    let dropindex = newarr.indexOf(droppeddata);
    let dragLstOrder: listOrder = draggeddata;
    let dropLstOrder: listOrder = droppeddata;
    this.dragDropEvt.emit([dragLstOrder.listOrder, dropLstOrder.listOrder, this.SelectedSettingId, this.InActiveTherapistChkBoxEvt]);
    newarr.splice(dragindex, 1);
    newarr.splice(dropindex, 0, draggeddata);
  }

  allowDrop(event, ele, newarr) {
    let draggeddata = event.dragData;
    let dragindex = newarr.indexOf(draggeddata);
    newarr.splice(dragindex, 1);
  }

  checkValid(event) {
    if (event && event.target && event.target.value.trim() !== '') {
      this.isValidRoleName = true;
    }
    else {
      this.isValidRoleName = false;
    }
  }

  searchInputValue(event) {
    this.searchText = event;
    this.viewCheckedFlag = false;
  }

  CreateTablerowFormGroup(optionsData) {
    let bodyArr: any = this.table.get('tablebody') as UntypedFormArray;
    bodyArr.controls = [];
    bodyArr.value = [];

    if (optionsData) {
      for (let data of optionsData[0].TablebodyData) {
        let optionsValue = data;
        const tempActive = (optionsValue.active ? optionsValue.active : false);
        bodyArr.push(this.fb.group({
          id: optionsValue.id,
          activetoggle: optionsValue.isActive ? (optionsValue.isActive) : tempActive,
          donecancel: this.fb.control(false),
          category: this.fb.control(optionsValue.category),
          rowDisabled: this.editEvent && this.editEvent.id == optionsValue.id ? this.editEvent.rowDisabled : false
        }));
      }
    }
    return bodyArr;
  }

  scrollEndHandler(arg) {
    if (!this.bodyArray || !this.bodyArray.length || arg.endIndex < this.bodyArray.length - (this.bufferCount + 1)) { return; }
    this.scrollEmitter.emit({
      scrollArg: arg,
      viewPortItems: this.scrollvalue.viewPortItems
    });
  }

  setMatformWidth(myElement) {
    if (this.tableInput) {
      let minWidth = myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0] ? myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0]['offsetWidth'] : 300; //min-300 max-470
      minWidth += myElement.getElementsByClassName('search-container')[0] ? myElement.getElementsByClassName('search-container')[0]['offsetWidth'] : 0;
      minWidth += myElement.getElementsByClassName('table-toggle-switches')[0] ? myElement.getElementsByClassName('table-toggle-switches')[0]['offsetWidth'] : 0;
      minWidth += myElement.getElementsByClassName('custom-retail-inputs')[0] ? myElement.getElementsByClassName('custom-retail-inputs')[0]['offsetWidth'] : 0;
      return minWidth;
    }
  }

  calculateWidth() {
    Array.from(document.querySelectorAll('#SPACustomTable>ng-scrollbar>.ng-scrollbar-container>.ng-scrollbar-view>table')).forEach((table, index) => {
      if (table) {
        let tableHeight = table['offsetHeight'];
        let parentHeight = table.closest("#fixed-table-container")['offsetHeight'];
        if (parentHeight > tableHeight) {
          table.closest("#SPACustomTable")['style']['height'] = tableHeight + 17 + 'px';
          this.isScrollAvailable = false;
        }
        else if (parentHeight <= tableHeight) {
          table.closest("#SPACustomTable")['style']['height'] = parentHeight + 'px';
          this.isScrollAvailable = true;
        }
      }
    });
    let searchClass = document.getElementsByClassName('retail-CustomDataTable');
    for (let i = 0; i < searchClass.length; i++) {
      let pageHeader = searchClass[i].getElementsByClassName('page-header')[0] ? searchClass[i].getElementsByClassName('page-header')[0]['offsetWidth'] : 0;
      let searchInput = searchClass[i].getElementsByClassName('searchpt')[0];
      if (pageHeader > 0) {
        pageHeader = pageHeader - this.setMatformWidth(searchClass[i]) - 60;
      }
      let inputLength = this.tableInput ? this.tableInput.nativeElement.getAttribute('data-placeholder').length : 1;
      let inputWidth = inputLength <= 30 ? inputLength * 10 : inputLength * 7.5 + 20;
      if (searchInput && pageHeader > 0) {
        searchInput['style'].width = (pageHeader > inputWidth) ? inputWidth + 'px' : pageHeader + 'px';
      }
    } 
  }



  setCodeSetupValues() {
    let retailsetup = this.captions.retailsetup;
    switch (this.pageTitle) {
      case 'outlets':
        this.retailHeaderMaxLength = 25;
        this.retailHeaderMinLength = 2;
        this.skipValidationHeaderComp = false;
        this.showErrForValidationHeaderComp = false;
        this.assignSetupValues(this.outlets, retailsetup.OutletName, retailsetup.MissingOutletName);
        break;
      case 'quicksale':
        this.assignSetupValues(this.quicksale, retailsetup.QuickSaleCategory, retailsetup.MissingQuickSaleCategory);
        break;
      case 'quicksaleCategories':
        this.assignSetupValues(this.quicksale, retailsetup.QuickSaleCategory, retailsetup.MissingQuickSaleCategory);
        break;
      case 'measures':
        this.retailHeaderMaxLength = 30;
        this.retailHeaderMinLength = 1;
        this.skipValidationHeaderComp = true;
        this.showErrForValidationHeaderComp = false;
        this.assignSetupValues(this.measures, retailsetup.UnitName, retailsetup.MissingUnitName);
        break;
      case 'discounttype':
        this.retailHeaderMaxLength = 12;
        this.retailHeaderMinLength = 3;
        this.skipValidationHeaderComp = false;
        this.showErrForValidationHeaderComp = false;
        this.assignSetupValues(this.discounttype, retailsetup.discountTypes, retailsetup.MissingDiscountType);
        break;
      case 'payment':
        this.assignSetupValues(this.payment, retailsetup.PaymentMethods, retailsetup.MissingPaymentMethod);
        break;
      case 'mailOrderSource':
        this.assignSetupValues(this.mailOrderSource, retailsetup.mailOrderSource, retailsetup.MissingMailOrderSource);
        break;
      case 'categoryGroup':
        this.retailHeaderMaxLength = 25;
        this.retailHeaderMinLength = 2;
        this.skipValidationHeaderComp = false;
        this.showErrForValidationHeaderComp = false;
        this.assignSetupValues(this.categorygroup, retailsetup.categoryGroup, retailsetup.MissingCategoryGroup);
        break;
      case 'carriers':
        this.assignSetupValues(this.carriers, retailsetup.carriers, retailsetup.MissingCarriers);
        break;
    }
  }

  assignSetupValues(formName: UntypedFormGroup, setupName: string, errorMessage: string) {
    this.formGroupName = formName;
    this.setupName = setupName;
    this.errorMessage = errorMessage;
  }

  customtablealignment() {
    let dropdown: any = [];
    let groupArray: any = {
      "outlets": {
        "outletName": "25",
        "terminalId": "25",
        "active": "10",
        "outletNumber": "10"
      },
      "unitofmeasure": {
        "measuringUnit": "25",
        "active": "25",
        "listOrder": "25"
      },
      "discounttypes": {
        "discounttype": "25",
        "active": "25",
        "listOrder": "25"
      },
      "categorygroup": {
        "categorygroup": "25",
        "active": "25",
        "listOrder": "25"
      },
      "quicksale": {
        "checkbox": "0",
        "desc": "40",
        "itemid": "20",
        "temp": "10",
        "category": "25"
      },
      "quicksaleCategories": {
        "measuringUnit": "25",
        "active": "25",
        "listOrder": "25"
      },
      "retail": {
        "checkbox": "3",
        "itemId": "10",
        "itemDescription": "30",
        "category": "10",
        "SalesPrice": "10",
        "dummy": "10",
        "Qty": "5",
        "ItemPar": "5",
        "Inactive": "10",
        "Cost": "10",
        "Print": "5",
      },
      "creditcard": {
        "terminalId": "15",
        "merchantNumber": "15",
        "dummy": "10",
        "active": "10",
        "listOrder": "15"
      },
      "taxes": {
        "Outlet": "20",
        "TaxName": "20",
        "TaxAmount": "15",
        "StartDate": "15",
        "EndDate": "15"
      },
      "retailCategories": {
        "categoryName": "20",
        "categoryGroup": "20",
        "listOrder": "20",
        "active": "20",
        "eligibleForDiscount": "20"
      },
      "retailSuppliers": {
        "suppliercode": "20",
        "suppliername": "20",
        "phone": "30",
        "accountrep": "20",
        "ap": "10"
      },
      "supplierDetails": {
        "description": "40",
        "item": "10",
        "onhand": "10",
        "mtd": "10",
        "ytd": "5",
        "par": "5",
        "cost": "15",
        "price": "15"
      }
    };
    switch (this.SelectedSettingId) {
      case myGlobals.GridType.outlet:
        dropdown = (Object.values(groupArray.outlets));
        break;
      case myGlobals.GridType.unitOfMeasure:
        dropdown = (Object.values(groupArray.unitofmeasure));
        break;
      case myGlobals.GridType.retail:
        dropdown = (Object.values(groupArray.retail));
        break;
      case myGlobals.GridType.quickSale:
        dropdown = (Object.values(groupArray.quicksale));
        break;
      case myGlobals.GridType.quickSaleCategories:
        dropdown = (Object.values(groupArray.quicksaleCategories));
        break;
      case myGlobals.GridType.creditcardterminal:
        dropdown = (Object.values(groupArray.creditcard));
        break;
      case myGlobals.GridType.taxes:
        dropdown = (Object.values(groupArray.taxes));
        break;
      case myGlobals.GridType.retailCategories:
        dropdown = (Object.values(groupArray.retailCategories));
        break;
      case myGlobals.GridType.retailSuppliers:
        dropdown = (Object.values(groupArray.retailSuppliers));
        break;
      case myGlobals.GridType.supplierDetails:
        dropdown = (Object.values(groupArray.supplierDetails));
        break;
      case myGlobals.GridType.discountTypes:
        dropdown = (Object.values(groupArray.discounttypes));
        break;
      case myGlobals.GridType.categorygroup:
        dropdown = (Object.values(groupArray.categorygroup));
        break;
    }
    let overallgivenwidth = 0;
    let ga;
    if (dropdown) {
      let parentWidth = document.getElementById('SPACustomTable') && document.getElementById('SPACustomTable').offsetWidth;
      for (ga = 0; ga < dropdown.length; ga++) {
        if (document.getElementsByTagName('th')[ga]) {
          document.getElementsByTagName('th')[ga].style.width = Object.values(dropdown)[ga] + '%';
        }
        overallgivenwidth += Number(dropdown[ga]);
      }
      if (dropdown && this.bodyArray.length > 0) {
        let tablerow = document.getElementsByTagName('tr');
        for (let i = 1; i <= this.bodyArray.length; i++) {
          if (tablerow[i]) {
            for (let j = 0; j < dropdown.length; j++) {
              if (document.getElementsByTagName('tr')[i].cells) {
                if (document.getElementsByTagName('tr')[i].cells[j]?.querySelectorAll("#content")?.length > 0) {
                  document.getElementsByTagName('tr')[i].cells[j].querySelectorAll("#content")[0]['style'].maxWidth = parentWidth * (Number(Object.values(dropdown)[j]) / 100) + 'px';
                }
              }
            }
          }
        }
      } 
    }
  }

  setWidth(coloumncount, count, width, maxwidth) {
    document.getElementsByTagName('th')[coloumncount - count].style.width = width;
    document.getElementsByTagName('td')[coloumncount - count].style.maxWidth = maxwidth;
  }

  showInactiveRoles(event, rowData, index) {
    this.hideScroll = true;
    this.freezeScroll = true;
    this.toggleEvtEmitter.emit(event);
    this.edittedIndex = index;
    this.editRecordsArray.push({ index: index, data: rowData });
    let doneCancelCount = _.reduce(this.table.value.tablebody, (acc, val) => {
      acc = val.donecancel ? (acc + 1) : acc;
      return acc;
    }, 0);
    let doneCancelRecords: any = _.find(this.table.value.tablebody, (data) => {
      return data.donecancel;
    });
    if (doneCancelRecords && doneCancelRecords.id === rowData.id) {
      return;
    }
    let editRecordList = document.getElementsByClassName("rowDisabled");
    if (editRecordList.length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
      this.resetForm();
      this.enableToggleButton = false;
      this.enableDoneCancel(index, rowData);
    }
    else {
      this.enableDoneCancel(index, rowData);
    }
    this.toggleInlineEditMode(true);
  }

  fieldEdit(event, selectedRow, key, index) {
    // this.bodyArray[index][key] = [event.target.value ? event.target.value : 0];
    console.log(this.bodyArray);
  }

  resetForm() {
    this.outlets.reset();
    this.measures.reset();
    this.quicksale.reset();
    this.discounttype.reset();
    this.payment.reset();
    this.mailOrderSource.reset();
    this.categorygroup.reset();
    this.customHeaderButton = this.localization.captions.setting.Add;
    this.enableCancelButton();
    this.carriers.reset();
  }

  enableDoneCancel(index, rowData) {
    this.table.value.tablebody[index].donecancel = true;
    this.table.controls['tablebody']['controls'][index].controls.donecancel.setValue(true);
    let data = { 'value': event, 'data': rowData };
    this.inactiveToggleEvtEmitter.emit(data);
    this.isValidRoleName = true;
    if (this.isOnEditDisableFunctionAvailable) {
      this.changeEditMode(true);
    }
  }

  Done(rowData, index) {
    this.hideScroll = false;
    this.freezeScroll = false;
    this.editRecordsArray = [];
    this.enableToggleButton = false;
    this.editEvent = {};
    this.table.value.tablebody[index].donecancel = false;
    this.doneEvtEmitter.emit(rowData);
    this.resetForm();
    if (this.isOnEditDisableFunctionAvailable) {
      this.changeEditMode(false);
      this.toggleInlineEditMode(false);
    }
  }

  lblclick(rowData, index, clickable) {
    if (clickable == 'clickable') {
      this.LBLClickEvtEmitter.emit(rowData);
      return;
    } else {
      return false;
    }
  }

  Cancel(rowData, index) {
    this.hideScroll = true;
    this.freezeScroll = false;
    let enableToggle;
    let enableEligiblity;
    _.forEach(this.tempTableData[0].TablebodyData, (data) => {
      if (data.id == rowData.id) {
        enableToggle = data.hasOwnProperty('isActive') ? data.isActive : data.active;
        enableEligiblity = data.hasOwnProperty('eligibleForDiscount') ? data['eligibleForDiscount'] : false;
      }
    });
    if (this.options[0].TablebodyData[index].hasOwnProperty('isActive')) {
      this.options[0].TablebodyData[index].isActive = enableToggle;
    }
    else {
      this.options[0].TablebodyData[index].active = enableToggle;
    }
    if (this.options[0].TablebodyData[index].hasOwnProperty('eligibleForDiscount')) {
      this.options[0].TablebodyData[index].eligibleForDiscount = enableEligiblity;
    }
    this.table.value.tablebody[index].activetoggle = enableToggle;
    this.table.value.tablebody[index].donecancel = false;
    if (this.isOnEditDisableFunctionAvailable) {
      this.changeEditMode(false);
      this.toggleInlineEditMode(false);
    }
    this.cancelEmitter.emit(false);
  }

  onButtonClick() {
    let belonTo = this.options[0].ServiceId;
    let type = this.customHeaderButton;
    this.editEvent = {};
    this.editRecordsArray = [];
    let data = {};
    switch (belonTo) {
      case 'outlets': data = { 'value': this.outlets, 'type': type };
        break;
      case 'measures': data = { 'value': this.measures, 'type': type };
        break;
      case 'quicksale': data = { 'value': this.quicksale, 'type': type };
        break;
      case 'quicksaleCategories': data = { 'value': this.quicksale, 'type': type };
        break;
      case 'discounttype': data = { 'value': this.discounttype, 'type': type };
        break;
      case 'categoryGroup': data = { 'value': this.categorygroup, 'type': type };
        break;
      case 'payment': data = { 'value': this.payment, 'type': type }
        break;
      case 'mailOrderSource': data = { 'value': this.mailOrderSource, 'type': type }
        break;
      case 'carriers': data = { 'value': this.carriers, 'type': type }
        break;
      default: data = { 'value': '', 'type': type };
    }
    this.enableToggleButton = false;
    this.addClick.emit(data);
    /*Reset Form values to Default*/
    if (document.getElementsByClassName('rowDisabled').length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
    }
    this.enableToggleButton = false; 
    if (this.isOnEditDisableFunctionAvailable) {
      this.changeEditMode(false);
    }
  }

  onButtonCancelClick(event) {
    if (document.getElementsByClassName('rowDisabled').length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
    }
    this.enableToggleButton = false;
    this.resetForm();
    if (this.isOnEditDisableFunctionAvailable) {
      this.changeEditMode(false);
    }
  }

  RowSelect(event, SelectedRow, Frm) {
    if (Frm == 'All') {
      if (event.checked) {
        this.SelectedData = [];
        for (let item of SelectedRow) {
          this.SelectedData.push(item);
        }
      }
      else {
        this.SelectedData = _.difference(this.SelectedData, this.bodyArray);
      }
    }
    else {
      if (this.SelectedData.indexOf(SelectedRow) == -1) {
        this.SelectedData.push(SelectedRow);
      } else {
        this.SelectedData.splice(this.SelectedData.indexOf(SelectedRow), 1);
        let checkedRow = this.bodyArray.findIndex(item => item.id == SelectedRow.id);
        this.bodyArray[checkedRow].checked = false;
      }
      this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    }
    this.RowSelectEmitter.emit(this.SelectedData);
    this.CurrentRowSelectedEmitter.emit({ "event": event, "SelectedRow": SelectedRow, "From": Frm });
  }

  InactiveService(e) {
    if (e.checked) {
      this.bodyArray = [];
      this.bodyArray = this.options[0].TablebodyData;
    } else {
      let tempArr = [];
      this.bodyArray.forEach(row => {
        if (row.isActive)
          tempArr.push(row);
      });
      this.bodyArray = tempArr;
    }
  }

  openAlertPopup() {
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.captions.common.Warning, headerIcon: 'icon-warning-icon', headerMessage: this.captions.common.saveChangesMessage, buttonName: this.captions.common.Yes, noButton: true, noButtonName: this.captions.common.No, type: 'message'
      },
      disableClose: true,
    }); 
  }

  sortingFunc(dh, i, from, overriddenSortColumn, sortColumnDataType, groupSort = false, groupSortType, groupSortJsonKey) {
    this.groupSort = groupSort;
    this.groupSortType = groupSortType;
    this.groupSortJsonKey = groupSortJsonKey;

    if (!this.isEditModeSet) { 

        this.sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from, groupSort, groupSortType, groupSortJsonKey);
        this.sortEmitter.emit({
          header: dh,
          overriddenSortColumn,
          sortColumnDataType,
          groupSort,
          groupSortType,
          groupSortJsonKey,
          direction: this.orderType
        }); 
    }
  }

  sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from, groupSort, groupSortType, groupSortJsonKey) {
    this.selectedDefaultHeader = dh;
    this.overriddenSortColumn = overriddenSortColumn;
    this.sortColumnDataType = sortColumnDataType;
    if (!this.options[0].isInitial && this.defaulSortColumn == this.selectedDefaultHeader && from == "change") {
      if (this.orderTypearr.length > 0) {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
      }
      else {
        this.orderTypearr.push(dh);
      }
    }
    else {
      if (this.givenOrderType == 'desc') {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.givenOrderType = '';
      } else {
        this.givenOrderType = '';
      }
      if (this.orderTypearr.indexOf(dh) == -1) {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.orderType = 'asc';
      } else {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
        this.orderType = 'desc';
      }
    }
 
  }

  sortArrowFunction(i, eventType) {
    let element = document.getElementById("sortArrow" + i);
    if (element)
      eventType == 'remove' ? element.classList.remove("IC6") : element.classList.add("IC6");
    let headerElement = document.getElementById("header" + i);
    if (headerElement)
      eventType == 'remove' ? headerElement.classList.remove("IC6") : headerElement.classList.add("IC6");
  }

  DeleteRecords(e) {
    this.userAlerts.showPrompt(myGlobals.PromptType.Delete, this.PopupCallback.bind(this), e);
    this.calwidthwithtimeout();
  }

  PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == "ok") {
      this.deleteEvt.emit([extraParams, this.options[0].ServiceId, this.InActiveTherapistChkBoxEvt]);
    }
  }

  autoscrolltocurrtime(curid) {
    if (document.getElementsByClassName('highlight').length > 0) {
      let curRow = curid.toString();
      let elms = document.getElementById(curRow);
      if (elms) {
        let scrolltoPos = (document.getElementById(curRow).offsetTop);
        let thPos = (document.getElementById('SPAHeaderRow').offsetHeight);
        document.getElementById("SPACustomTable").scrollTop = scrolltoPos - thPos;
        clearInterval(this.setinterscroll);
      }
    }
  }

  openAlertDialog = (editableRecord, currentRecord, eventType, index, event, cancelCount) => {
    let dialogRef = this.openAlertPopup();
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        if (document.getElementsByClassName("rowDisabled").length > 0) {
          document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
          document.getElementsByClassName("rowDisabled")[0].classList.remove("rowDisabled");
        }
        if (cancelCount > 0) {
          let recordArray = this.editRecordsArray;
          recordArray.length == 1 ? this.Cancel(recordArray[0].data, recordArray[0].index) : this.Cancel(recordArray[recordArray.length - 2].data, recordArray[recordArray.length - 2].index);
        }
        if (eventType == 'toggle') {
          this.table.value.tablebody[index].donecancel = true;
          _.forEach(this.table.value.tablebody, (result, i) => {
            if (result.donecancel && result.id != this.table.value.tablebody[index].id) {
              this.table.value.tablebody[i].donecancel = !this.table.value.tablebody[i].donecancel;
            }
          });
          let data = { 'value': event, 'data': currentRecord };
          this.inactiveToggleEvtEmitter.emit(data);
          this.enableToggleButton = false;
          this.resetForm();
        }
        else {
          this.editEvent = { id: currentRecord.id, rowDisabled: true };
          _.forEach(this.table.value.tablebody, (data, i) => {
            if (data.donecancel) {
              data.donecancel = !data.donecancel;
            }
          });
          this.editRow(event, currentRecord, 'edit', this.options[0].ServiceId);
        }
      }
      else {
        if (this.editRecordsArray.length > 0) this.editRecordsArray.pop();
        if (eventType == 'toggle') {
          this.Cancel(currentRecord, index);
        }
      }
    });
  }

  editRow(event, e, type, belonTo, index?) {
    if (this.isOnEditDisableFunctionAvailable) {
      this.changeEditMode(true);
    }
    /*Updated Edit for Retail*/
    let doneCancelCount = _.reduce(this.table.value.tablebody, (acc, val) => {
      acc = val.donecancel ? (acc + 1) : acc;
      return acc;
    }, 0);
    let doneCancelRecords: any = _.filter(this.table.value.tablebody, (data) => {
      return data.donecancel;
    });
    if (doneCancelCount > 0 && this.editRecordsArray.length > 0) {
      let lastRecord = this.editRecordsArray.pop();
      this.editRecordsArray = [];
      this.editRecordsArray.push(lastRecord);
    }
    else {
      this.editRecordsArray = [];
    }
    let editRecordList = document.getElementsByClassName("rowDisabled");
    if ((editRecordList.length > 0 || doneCancelCount > 0) && this.isValidRoleName) {
      let editableRecord = editRecordList.length > 0 ? _.filter(this.options[0].TablebodyData, data => data.id == editRecordList[0].id) : _.filter(this.options[0].TablebodyData, data => data.id == doneCancelRecords[0].id);
      let currentRecord = e;
      this.openAlertDialog(editableRecord, currentRecord, 'edit', index, event, doneCancelCount);
    } else {
      if (this.customHeader) {
        this.customHeaderButton = this.localization.captions.setting.UPDATE;
        this.enableToggleButton = true;
        switch (belonTo) {
          case 'outlets':
            this.outlets.controls['terminalId'].setValue(e.CCTerminalId);
            this.setCustomHeaderValues(this.outlets, e.outletName, e.isActive, event, e.terminalId, belonTo);
            break;
          case 'measures':
            this.setCustomHeaderValues(this.measures, e.measuringUnit, e.active, event);
            break;
          case 'quicksale':
            this.setCustomHeaderValues(this.quicksale, e.quickSaleCategory, e.isActive, event);
            break;
          case 'quicksaleCategories':
            this.setCustomHeaderValues(this.quicksale, e.quickSaleCategory, e.isActive, event);
            break;
          case 'discounttype':
            this.setCustomHeaderValues(this.discounttype, e.discountType, e.active, event);
            break;
          case 'payment':
            this.setCustomHeaderValues(this.payment, e.paymentMethod, e.isActive, event);
            break;
          case 'mailOrderSource':
            this.setCustomHeaderValues(this.mailOrderSource, e.mailOrderSource, e.active, event);
            break;
          case 'categoryGroup':
            this.setCustomHeaderValues(this.categorygroup, e.categoryGroup, e.isActive, event);
            break;
          case 'carriers':
            this.setCustomHeaderValues(this.carriers, e.carriers, e.active, event);
            break;
        }
        if (typeof event == 'object') {
          if (document.getElementsByClassName("rowDisabled").length > 1) {
            let classList = document.getElementsByClassName("rowDisabled");
            for (let i = 0; i < classList.length; i++) {
              if (classList[i].id != e.id) {
                document.getElementsByClassName("rowDisabled")[i].classList.remove("highlight");
                document.getElementsByClassName("rowDisabled")[i].classList.remove("rowDisabled");
              }
            }
          }
        }
        this.EditRecords(e, type, index);
      }
      else {
        if (document.getElementsByClassName("rowDisabled").length > 0) {
          document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
          document.getElementsByClassName("rowDisabled")[0].classList.remove("rowDisabled");
        }
       
        this.EditRecords(e, type, index);
      }
    }
    this.enableCancelButton();
  }

  setCustomHeaderValues(setupFormGroupName: UntypedFormGroup, codeName: any, isActive: boolean, event: any, belonTo?: any, terminalId?: number) {
    setupFormGroupName.controls['setupCodeName'].setValue(codeName);
    setupFormGroupName.controls['activetoggle'].setValue(isActive);
    if (belonTo == "outlets") {
      setupFormGroupName.controls['terminalId'].setValue(terminalId);
    }
    this.isValidRoleName = false;
    if (typeof event == 'object' && event.target.parentElement.parentElement.classList.contains("rowDisabled")) {
      setupFormGroupName.controls['setupCodeName'].setValue("");
      setupFormGroupName.controls['activetoggle'].setValue(false);
      if (belonTo == "outlets") {
        setupFormGroupName.controls['terminalId'].setValue(terminalId);
      }
      this.customHeaderButton = this.localization.captions.setting.Add;
    }
  }

  enableCancelButton() {
    this.isCancelAvailable = this.customHeaderButton.toLowerCase() == this.localization.captions.setting.UPDATE.toLowerCase();
  }


  EditRecords(e, type, index?) {
    if (this.editEvt) {
      if (this.SelectedSettingId === myGlobals.GridType.commission) {
        this.editEvt.emit([e, type, index]);
      } else if (type.toLowerCase() === 'print') {
        this.bodyArray.forEach(element => {
          if (this.SelectedSettingId == myGlobals.GridType.retail) {
            element['checked'] = false;
          }
        })
        this.editEvt.emit([e, this.options[0].ServiceId, type, this.SelectedData]);
      } else {
        this.editEvt.emit([e, this.options[0].ServiceId, type]);
        this.givenOrderType = this.orderType;
      }
    }

    this.givenOrderType = this.orderType;
  }

  optionChange(e, belonTo, data) { 
    if (this.GridType.quickSale == this.SelectedSettingId && e.value == 0) {
      this.SelectedData = this.SelectedData.filter(r => { return r.id != data.id; });
    }
    this.dropDownChange.emit([e, belonTo, data]);
  }

  calwidthwithtimeout() {
    setTimeout(() => {
      this.calculateWidth();
    }, 1);
  }

  clearSearchText() {
    this.searchText = "";
    this.calwidthwithtimeout();
  }


  showMore(index) {
    this.showLess[index] = !this.showLess[index];
    if (this.showLess[index]) {
      this.showText[index] = this.captions.retailsetup.showMore;
      this.calcShowMore();
    } else {
      this.showText[index] = this.captions.retailsetup.showLess;
      this.showLess[index] = false;
    }
    this.calwidthwithtimeout();
  }

  calcShowMore() {
    if (this.SelectedSettingId == myGlobals.GridType.retailSuppliers) {
      setTimeout(() => {
        this.setShowMoreWidth();
      }, 1);
    }
  }

  setShowMoreWidth() {
    let thWidth = document.getElementsByTagName("td")[2];
    let leftClass = document.getElementsByClassName("left");
    let rightClass: any = document.getElementsByClassName("right");
    let firstRight;
    for (let i = 0; i < rightClass.length; i++) {
      if (rightClass[i].offsetWidth > 0) {
        firstRight = rightClass[i].offsetWidth;
        break;
      }
    }
    let leftWidth = (thWidth.offsetWidth) - 40;
    let leftCompareWidth = leftWidth - 20;
    for (let i = 0; i < leftClass.length; i++) {
      leftClass[i]['style'].width = leftWidth - firstRight + 'px'; // subtracting show more text width from the full width and assigning to the left width

      let size = 9; // for font-size
      if ((this.phoneLength[i].length * size) > leftCompareWidth) {
        this.showLess[i] = true;
        this.showItem[i] = true;
      }
      else if ((this.phoneLength[i].length * size) <= leftCompareWidth) {
        this.showLess[i] = true;
        this.showItem[i] = false;
      }
    }
  }

  changeEditMode(getEditModeFlag) {
    if (this.isOnEditDisableFunctionAvailable) {
      this.isEditModeSet = getEditModeFlag;
    }
  }
  toggleInlineEditMode(getInlineEditFlag) {
    if (this.isOnEditDisableFunctionAvailable) {
      this.inlineEditOn = getInlineEditFlag;
    }
  }

  @HostListener('mousedown', ['$event', '$event.target'])
  onMousedown(e: MouseEvent, element: any) {
    if (!element.value) {
      const isDraggable = element.classList.contains('draggable');
      if (!isDraggable && element.closest('td')) {
        e.preventDefault();
      }
    }
  }
}

export interface listOrder {
  listOrder: number;
}
