import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ViewChildren, QueryList, Directive, ElementRef, HostListener, Pipe, PipeTransform } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { AppService } from '@app/app.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { LockSessionRequestDTO } from '@app/shared/models/ActivityLockSessionDTO';
import { ActivityRatePlanRequest, BookingCharges, BookingTypeVariant, ChargeType, CommentType, GuestTypeVariant, RatePlanCalculationInfo, RatePlanCalculationInfoNegotiation, RatePlanVariants } from '@app/shared/models/ActivityRatePlanRequestDTO';
import { SessionBookingDTO } from '@app/shared/models/SessionBookingDTO';
import { AvailabilityCriteria, BookingBehavior, ClassType, ComponentTypes, PartyNoteType, PartyState, PartyType, RolesAndPermissionsType, ValidationMessageType ,PaymentMethod, buttonTypes} from '@constants/commonenums';
import { Utilities } from '@app/shared/utilities/utilities';
import { CacheService } from '@core/services/cache.service';
import { FormChipService } from '@dynamicform/components/form-chip/form-chip.service';
import { DynamicFormService } from '@dynamicform/service/dynamic-form.service';
import { BookingChargeType, Category, PartyNoteDTO, SettingsDTO, TaxableItem } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { FacadeService } from '@services/facade.service';
import { PartyService } from '@services/party.service';
import moment from 'moment';
import { catchError, map, mergeAll } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RateNegotiationComponent } from '@app/shared/components/rate-negotiation/rate-negotiation.component';
import { PartyNotes } from '@app/shared/models/global.interface';
import { add, forEach, get } from 'lodash';
import { PartyPrepaymentState } from '@app/shared/models/InputContact';
import { I, T } from '@angular/cdk/keycodes';
import _ from 'lodash';
import { TimeRangeDTO } from '@app/shared/models/TimeRangeDTO';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { RolesAndPermissionPipe } from '@app/shared/pipes/RolesAndPermission.pipe';
import { CurrencyDecimalFormater } from '@app/shared/pipes/currency-decimal.pipe';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';

@Component({
  selector: 'activity-booking-summary',
  templateUrl: './activity-booking-summary.component.html',
  styleUrls: ['./activity-booking-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityBookingSummaryComponent extends Utilities implements OnInit, OnDestroy {

  selectedIndex: number;
  intialized: boolean = false;
  subscriptions: Subscription = new Subscription();
  ratePlan: any;
  coverTypes = [];
  bookingTypes = [];
  lockResponse;
  _settings: SettingsDTO;
  enableGuestComponent: boolean = false;
  isSessionClass: boolean;
  showAllSlots: boolean = false;
  OperationCurrency: string;
  activityName: string;
  ratePlanValidationMsg = '';
  islockneeded = false;
  isPaymentScreen = false;
  ReservationAttemptData: any;
  showIframe: boolean;
  PaymentGateway: any;
  iframeURL: any;
  fromComponent: any;
  remainingAmount = 0;
  totalPayableAmount = 0;
  totalDepositAmount = 0;
  taxAmount = 0;
  uneditableTaxAmount = 0
  negotiationCutOff = 0;
  totalAddOnAmount = 0;
  totalAddOnTaxAmount = 0;
  showDepositAmount = false;
  negotiationReason: any = '';
  restaurantPolicyAvailable: Boolean = false;
  restaurantPolicy: any = [];
  config: FieldConfig[] = [];
  includeOthers = false;
  othersId: number[] = [];
  customPartyNotes: any[] = [];
  partyNotes: PartyNotes[] = [];
  categories: Category[] = [];
  selectedPartyPreferenceTags: any[] = [];
  isTaxConfigured = false;
  isRetailEnabledProperty = false;
  selectedAddOns: any;
  ratePlanCals: any;
  totalServiceCharge:any =0;
  totalServiceChargeWithTax: number =0;
  isServiceConfigured = false;
  negotiationRatesConfig: FieldConfig[];
  recalculateConfig: FieldConfig[];
  perItemEditable = false;
  sessionEditable = false;
  subTotalEditable = false;
  FormGrp: UntypedFormGroup;
  NegotiationAddon:any;
  totalAmountForBooking = 0;
  FinancialEffectId:number;
  applyNegotiationBtn:ButtonValue;
  cancelNegotiationBtn:ButtonValue;
  rateNegotiateConfig: FieldConfig[];
  firstCall = true;
  ratePlanOriginal:any ;
  appliedNegotiation = false;
  originalAdjustedMinRate: number = 0.00;
  adjustedMinRate: number = 0.00;
  activitiesAmount: number = 0.00;
  subtotal: number = 0.00;
  serviceCharge: number = 0.00;
  taxOnServiceCharge: number = 0.00;
  totalAddonTaxOnServiceChargeAmount: number = 0.00;
  tax: number = 0.00;
  minRateTaxOnServiceChargeAmount:number = 0.00;
  grandTotal: number = 0.00;
  negotiatedDiscount: number = 0.00;
  applyNegotiationClicked: boolean = false;
  partyData:any;
  prevSalePrice: any = {
    cover: {},
    bookingType: {},
    base: {},
    location: {},
    instructor: {},
    adjustedMinRate: 0,
    membership: {}
  }
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  @ViewChild('negotiateReasonForm') negotiateReasonForm: DynamicFormComponent;
  constructor(public dialog: MatDialog, public partyService: PartyService, public cs: CacheService, private ps: PopupService, @Inject(COMPONENTINPUT) public data, public translateService: TranslateService,
    public facadeService: FacadeService, private formChipService: FormChipService, private dfs: DynamicFormService, public _as: AppService, private api: ApiService,
    public permissions: RolesAndPermissionPipe,private currencyDecimalFormater:CurrencyDecimalFormater,public popupService: PopupService, private sanitizer: DomSanitizer, private df: DashboardFunctions, private fb:UntypedFormBuilder) {
    super(dialog);
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.isTaxConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax)?.length > 0;
      this.isServiceConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge)?.length > 0;
      this.isRetailEnabledProperty = Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO);
      this.OperationCurrency = this._settings.General.OperationCurrency;
      this.coverTypes = [...this._settings.CoverTypes];
      this.bookingTypes = [...this._settings.BookingTypes];
      this.partyService.isNegotiationEnabled = this._as.hasPermission(RolesAndPermissionsType.NegotiateRates);
      if (this._settings.PropertySetting.length > 0 && (this._settings.PropertySetting[0].PreReservationMessage && this._settings.PropertySetting[0].PreReservationMessageTitle)) {
        this.restaurantPolicyAvailable = true;
        this.restaurantPolicy = this._settings.PropertySetting[0];
      }
      this.categories = this._settings.Categories.filter(category => category.Text != "Reasons"); // changed after confirmation
      if (data && this._as.OTASourceId.includes(data.PartySourceId)) {
        this.includeOthers = true;
      }
      if (data && this.data.Notes && this.data.Notes.length) {
        this.othersId = this.data.Notes.filter(x => x.Type == PartyNoteType.FreeFormNote).map(({ RelatedId }) => RelatedId);
      }
      this.partyNotes = Utilities.getRestaurantPredefinedPartyNotes(this.categories, this.includeOthers, this.othersId);

    }));
  }


  ngOnInit() {
    this.partyData = !_.isEmpty(this.partyService.retailservice.oldReservation)? this.partyService.retailservice.oldReservation.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation[0]: null;
    this.subscriptions.add(this.partyService.openNextPopUp$.subscribe(data => {
      if (data) {
        this.showWarningPopup(this.ratePlanValidationMsg, ComponentTypes.RatePlanWarning);
        this.partyService.operationType = false
      }
    }));

    this.perItemEditable = this.permissions.transform(RolesAndPermissionsType?.NegotiateRates);
    this.subscriptions.add(this.partyService.tabChange$.subscribe(value => {
      let getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, this.partyService.selectedSpecialMealId);
      if ((value === 1 && (!getAddOns || getAddOns?.length == 0 || getAddOns?.filter(addOn => addOn.IsVisible == true).length == 0)) || (value == 2 && getAddOns?.length > 0 && getAddOns?.filter(addOn => addOn.IsVisible == true).length > 0)) {
        this.activityName = this._settings.SpecialMeals.find(meal => meal.Id === this.partyService.sessionBookingData?.ActivityId)?.Name;
        this.applyNegotiationClicked = false;
        this.intialize();
      } else if (value === 0 && this.lockResponse) {
        this.partyService.slotLockId = this.lockResponse.SlotLockIdDTO?.Id;
        this.partyService.slotLockIds = [this.lockResponse.SlotLockIdDTO?.Id];
      }
    }));
    this.setNegotiationConfig(); 


  }

  setNegotiationConfig() {
    this.negotiationRatesConfig = [{
      type: 'switch',
      name: 'negotiation',
      inputType: 'text',
      label: 'NegotiableRates',
      class: 'negotiation-rate',
      checked: false
    }
    ];
    this.recalculateConfig = [{
      type: 'switch',
      name: 'applyafterNegotiation',
      inputType: 'text',
      label: this.translateService.instant('Recalculate'),
      class: 'recalculate',
      checked: false,
      
    }
    ];

        this.rateNegotiateConfig = [
      {
        name: 'reasonText',
        type: 'textarea',
        label: 'EnterText',
        rows: 2,
        inputType: 'text',
        showHint: true,
        charLength: 400,
        textAreaAppearance: 'outline',
        class: 'reject-options__input',
        value: this.setNegotiationReason(),
        validation:[Validators.required],
        disableErrorStateMatcher:true
      }]

    this.applyNegotiationBtn = {
      type: buttonTypes.actionPrimarySmall,
      label: 'apply',
      disbaledproperity: this.negotiationReason == '',
      customclass: 'action-bar__search-btn',      
    }
    this.cancelNegotiationBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: 'reset',
      disbaledproperity: false,
      customclass: 'action-bar__search-btn',
    }
    
  }


  setNegotiationReason(){
    let ratePlanNegotiateReason = '';
    if(this.data && this.data.bookingRatePlan?.Comments){
      try{
        let commentsObj = JSON.parse(this.data.bookingRatePlan.Comments);
        if(commentsObj){
          ratePlanNegotiateReason = commentsObj.find(comment => comment.commentType == CommentType.Negotiation)?.Comment || '';
        }
      }catch(error){
        ratePlanNegotiateReason = this.data.bookingRatePlan.Comments || '';
      }
    }
    return ratePlanNegotiateReason;
  }

  seteditablecontent(event) {
    switch (event.value) {
      case 1:
        this.perItemEditable = true;
        this.sessionEditable = false;
        this.subTotalEditable = false;
        break;
      case 2:
        this.perItemEditable = false;
        this.sessionEditable = false;
        this.subTotalEditable = true;
        break;
      case 3:
        this.perItemEditable = false;
        this.sessionEditable = true;
        this.subTotalEditable = false;
        break;
    }
  }

  intialize() {
    this.firstCall = true;
    this.partyService.ratePlanForBooking = null;
    this.selectedAddOns = this.df.getSelectedAddOns();
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === ActivityBookingSummaryComponent);
    const specailMeals = this._settings.SpecialMeals.filter((meal) => meal.Id === this.partyService.sessionBookingData?.ActivityId);
    let standbymeal = specailMeals[0].IsForStandbyReservations;
    if (specailMeals && (!specailMeals[0].IsForStandbyReservations || !specailMeals[0].AllowStandBy)) {
      this.islockneeded = true;
    }
    this.lockTables(!!this.data?.CartId);
    this.bindGuestPartyNotes();
    if (this.data && this.data.Notes && this.data.Notes.length > 0) {
      this.partyService.partyNotes = [];
      this.mapPartyNotes();
      if (this.customPartyNotes.length) {
        this.mapCustomPartyNotes();
      }
    }
    this.formChipsChange();
  }

  lockTables(bypassLock) {
    this.ratePlan = null;
    this.partyService.BookingBehaviour = BookingBehavior.ClassOrSession;
    let { ActivityId, FromDate, Slots, CoverTypes, BookingTypes, Location, StaffId, BookingSize, SessionGroupId, OverBooked } = this.partyService.sessionBookingData;
    const classType = this.partyService.sessionBookingData.ClassType;
    this.isSessionClass = classType === ClassType.Session;

    let _lockIdToIgnore = null;

      if(this.data?.isCartEdit){
        if(this.data.bookingRatePlan.SlotLockResultDTO){
          _lockIdToIgnore = this.data.bookingRatePlan.SlotLockResultDTO?.SlotLockIdDTO?.Id;
        }else if(this.data.bookingRatePlan.SlotLockIdDTO){
          _lockIdToIgnore = this.data.bookingRatePlan.SlotLockIdDTO?.Id;
        }
      }
    
    let lockRequest = {
      ActivityId: ActivityId,
      StartDate: FromDate,
      BookingSize: BookingSize,
      ClassType: classType,
      IsForStandbyReservations: this.partyService.sessionBookingData.AllowStandBy,
      lockIdToIgnore: _lockIdToIgnore || this.partyService.slotLockId || null,
      Sessions: classType == ClassType.Class ? [] : Slots,
      SessionGroupId: classType == ClassType.Class ? SessionGroupId : null,
      OverBooked,
      ReservationIdToIgnore: this.data ? this.data.Id : null,
      AddOns: this.df.getSelectedAddOns(),
      ExpireAt: null
    } as LockSessionRequestDTO;
    this.enableGuestComponent = false;
    this.changeFormState(false);
    if (this.islockneeded && (!this.partyService.sessionBookingData.AllowStandBy || this.partyService.sessionBookingData.OverBooked) && !bypassLock) {
      this.subscriptions.add(
        this.api.lockSessions(lockRequest).subscribe(response => {
          this.lockResponse = response.Payload;
          this.partyService.lockData = response.Payload;
          this.partyService.slotLockId = this.lockResponse?.SlotLockIdDTO?.Id;
          this.partyService.slotLockIds = [this.lockResponse.SlotLockIdDTO?.Id];
          this
                  }));
                  this.getRatePlanAndEnableGuest(Slots, CoverTypes, BookingTypes, Location, BookingSize, ActivityId, classType == ClassType.Class ? SessionGroupId : null);

    }
    else {
      this.getRatePlanAndEnableGuest(Slots, CoverTypes, BookingTypes, Location, BookingSize, ActivityId, classType == ClassType.Class ? SessionGroupId : null)
    }
  }
  getRatePlanAndEnableGuest(Slots, GuestTypes, BookingTypes, Location, BookingSize, ActivityId, SessionGroupId) {
    let selectedAddons = this.df.getSelectedAddOns();
    if (this._settings.SpecialMeals.find(activity => activity.Id == this.partyService.sessionBookingData?.ActivityId)?.IsPrepaymentRequired || (selectedAddons?.filter(data => data.Price > 0).length)) {
      this.setRatePlanCalculation(Slots, GuestTypes, BookingTypes, Location, BookingSize, ActivityId, SessionGroupId);
    }
    else {
      this.enableGuestSelectionComponent();
      this.formChangeSubscribe();
    }
  }
  getAvailabilityCriteria(locations, instructors) {
    if (!locations.length && !instructors.length) {
      return AvailabilityCriteria.None
    } else if (locations.length && !instructors.length) {
      return AvailabilityCriteria.IncludeInstructor
    } else if (!locations.length && instructors.length) {
      return AvailabilityCriteria.IncludeLocation
    } else {
      return AvailabilityCriteria.IncludeLocationAndInstructor
    }
  }

  isPendingActiveSession(session) {
    let propertyCurrentDate = new Date(new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)).setHours(0, 0, 0, 0));
    return new Date(session.BookedDate) < propertyCurrentDate && session.SessionState == PartyState.Pending;
  }

  isStandByConfirmedSession(party, session) {
    return session.SessionType == PartyType.Reservation && party.Type == PartyType.StandBy && session.SessionState == PartyState.Pending;
  }

  getUneditableSlotsRatePlan(): any[] { // to get the amount of past and confirmed Sessions
    let ratePlanCalculations = [];
    if (this.data && this.data.BookedSessions && this.data.BookedSessions.length) {
      //var neededSessions = this.data.BookedSessions.filter(s=>s.SessionState != PartyState.Cancelled && s.SessionState != PartyState.CancellationInprogress);
      let bookedSessionIds = this.data.BookedSessions.map(bookedsession => bookedsession.Id)
      this.data.BookedSessions.forEach(session => {
        let sessionCurrentState = session.SessionState == PartyState.Seated ? this.translateService.instant('checkedin') : session.SessionState == PartyState.Left ? this.translateService.instant('checkedout') : this.isPendingActiveSession(session) ? this.translateService.instant('expired') : this.isStandByConfirmedSession(this.data, session) ? this.translateService.instant('confirmed') : ''
        //if(session.SessionState == PartyState.Cancelled || session.SessionState == PartyState.CancellationInprogress || session.SessionState == PartyState.Seated || session.SessionState == PartyState.Left || this.isPendingActiveSession(session) || this.isStandByConfirmedSession(this.data, session)){
        if (sessionCurrentState !== '' && !bookedSessionIds.includes(session.Id)) {
          if (session.Charges && session.RatePlanAmount) {
            let ratePlan = this.getRatePlan(session);
            ratePlan.sessionState = sessionCurrentState;
            ratePlan.size = session.BookingSize;
            ratePlan.sessionNegotiation = session.NegotiatedAmount;
            ratePlan.TaxAmount = session.TaxAmount;
            ratePlan ? ratePlanCalculations.push(ratePlan) : '';
          }
        }
      });
    }
    return ratePlanCalculations;
  }

  getRatePlan(session): any {
    let activitySession = this.cs.settings.value.SpecialMeals.filter(activity => activity.Id == this.data.SpecialMealId)[0]?.ActivitySessions.filter(s => s.ActivitySessionId == session.ActivitySessionId)[0];
    if (activitySession) {
      let bookedDate = session.BookedDate;
      let sessionStartTime = session.BookedDate.split('T')[0] + 'T' + activitySession.StartTime;
      let sessionEndTime = session.BookedDate.split('T')[0] + 'T' + activitySession.EndTime;
      let timeRange = new TimeRangeDTO();
      timeRange.Start = new Date(sessionStartTime);
      timeRange.End = new Date(sessionEndTime);
      //let timeRange = {Start: sessionStartTime, End : sessionEndTime};
      let bookingCharges = _.groupBy(JSON.parse(session.Charges) as BookingCharges[], c => c.ChargeType);
      let ratePlanCalculation = new RatePlanCalculationInfo();
      ratePlanCalculation.RatePlanVariants = new RatePlanVariants();
      Object.keys(bookingCharges).forEach((chargeType) => {
        switch (Number(chargeType) as ChargeType) {
          case ChargeType.BasePrice:
            ratePlanCalculation.RatePlanVariants.TotalBaseRate = bookingCharges[chargeType].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
            break;
          case ChargeType.Location:
            ratePlanCalculation.RatePlanVariants.LocationVariant = bookingCharges[chargeType].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
            break;
          case ChargeType.Staff:
            ratePlanCalculation.RatePlanVariants.InstructorVariant = bookingCharges[chargeType].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
            break;
          case ChargeType.Membership:
            ratePlanCalculation.MemberShipVariant = bookingCharges[chargeType].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
            break;
          case ChargeType.CoverType:
            let guestTypeVariantList: GuestTypeVariant[] = [];
            let coverTypes = _.groupBy(bookingCharges[chargeType], c => c.EntityId);
            Object.keys(coverTypes).forEach(covertype => {
              //var coverSize = this.data.CoverTypeQuantities.filter(c=>c.CoverTypeId == covertype)[0].Covers;
              var guestTypeVariant = new GuestTypeVariant();
              guestTypeVariant.CoverTypeId = Number(covertype);
              guestTypeVariant.covers = coverTypes[covertype][0].Size;
              guestTypeVariant.Variant = coverTypes[covertype].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
              guestTypeVariantList.push(guestTypeVariant);
            });
            ratePlanCalculation.RatePlanVariants.GuestTypeVariant = guestTypeVariantList;
            break;
          case ChargeType.BookingType:
            let bookingTypeVariantList: BookingTypeVariant[] = [];
            let bookingTypes = _.groupBy(bookingCharges[chargeType], c => c.EntityId);
            Object.keys(bookingTypes).forEach(bookingType => {
              //var coverSize = this.data.BookingTypeQuantities.filter(c=>c.BookingTypeId == bookingType)[0].BookingTypes;
              let bookingTypeVariant = new BookingTypeVariant();
              bookingTypeVariant.bookingTypes = bookingTypes[bookingType][0].Size;
              bookingTypeVariant.BookingTypeId = Number(bookingType);
              bookingTypeVariant.Variant = bookingTypes[bookingType].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
              bookingTypeVariantList.push(bookingTypeVariant);
            });
            ratePlanCalculation.RatePlanVariants.BookingTypeVariant = bookingTypeVariantList;
            break;
        }
      });
      ratePlanCalculation.RatePlanVariants.TotalVariants = (ratePlanCalculation.RatePlanVariants.LocationVariant ? ratePlanCalculation.RatePlanVariants.LocationVariant : 0)
        + (ratePlanCalculation.RatePlanVariants.InstructorVariant ? ratePlanCalculation.RatePlanVariants.InstructorVariant : 0)
        + (ratePlanCalculation.RatePlanVariants.GuestTypeVariant ? ratePlanCalculation.RatePlanVariants.GuestTypeVariant.map(v => (v.Variant ? v.Variant : 0)).reduce((sum, value) => sum + value, 0) : 0)
        + (ratePlanCalculation.RatePlanVariants.BookingTypeVariant ? ratePlanCalculation.RatePlanVariants.BookingTypeVariant.map(v => (v.Variant ? v.Variant : 0)).reduce((sum, value) => sum + value, 0) : 0);
      ratePlanCalculation.TotalAmount = (ratePlanCalculation.RatePlanVariants.TotalBaseRate ? ratePlanCalculation.RatePlanVariants.TotalBaseRate : 0) + ratePlanCalculation.RatePlanVariants.TotalVariants;
      ratePlanCalculation.TimeRange = timeRange;
      return ratePlanCalculation;
    }
  }

 test(event,slot){
   console.log(slot);
//    const initalValue = event.target.innerText;
//   const moifiedValue = initalValue.replace(^[1-9]\d*(\.\d+)?$);
//    if (initalValue !== moifiedValue) {
//     event.preventDefault();
//    }
 }


  setRatePlanCalculation(Slots, GuestTypes, BookingTypes, Location, BookingSize, ActivityId, SessionGroupId,FinancialEffectId?, autoRateplanRecalculate?, resetData?) {
    
    const selectMemberRatePlane =  this.partyService.selectMemberRatePlane(
      {
        ActivityId: ActivityId, 
        Slots: Slots, 
        Location : Location,
        sessionGroupId : SessionGroupId
      })
    
    var request = {
      Slots: [],
      ActivityId,
      GuestTypes,
      BookingTypes,
      TableIds: Location,
      Size: BookingSize,
      SessionSlot: {
        Sessions: SessionGroupId ? null : Slots,
        SessionGroupId: SessionGroupId,
        OverBooked: this.partyService.sessionBookingData?.OverBooked || 0
      },
      TrackMembershipNumber: this.partyService.selectedGuest?.TrackMembershipNumber,
      MemberType: this.partyService.selectedGuest?.MembershipType,
      IsMemberActive: this.partyService.selectedGuest?.MemberActive,
      RateType: selectMemberRatePlane,
      RateCode: this.partyService.selectedGuest?.RateType,
      BookingBehavior: BookingBehavior.ClassOrSession,
      NegotiatedAmount: this.data?.NegotiatedAmount,
      SelectedAddOns: this.df.getSelectedAddOns(),
      SeatingTypeIds: null,
      FinancialEffectId: !resetData ? (FinancialEffectId || null) : null,
      RatePlanTotalNegotiation: this.data && this.data.bookingRatePlan?.RatePlanTotalNegotiation && (this.firstCall || resetData)  ? this.data.bookingRatePlan?.RatePlanTotalNegotiation  : this.formData(resetData)

    } as ActivityRatePlanRequest;
    if(this.isBookingDataUpdated(request)){
      request['RatePlanTotalNegotiation'] = this.formData(resetData)
    }
    this.totalDepositAmount = 0;
    this.showDepositAmount = false;
    this.originalAdjustedMinRate = 0.00;
    this.adjustedMinRate = 0.00;
    // this.negotiatedDiscount = 0.00;
    this.activitiesAmount = 0.00;

    if(autoRateplanRecalculate){
      request['RatePlanTotalNegotiation'].MinRateNegotiatedAdjustment = 0;
    }
    
    if( this.ratePlan && !this.applyNegotiationClicked){
      this.ratePlan.FinancialEffectId = null;
    }
    this.subscriptions.add(this.api.GetRatePlanCalculated(request,this.ratePlan?.FinancialEffectId || null).subscribe(response => {
      // if(this.firstCall){
      //   this.partyService.ratePlanForNegotiation = response.Payload;
      //  // this.firstCall = false;
      // }
      if(this.partyService.sessionBookingDataSaveObj){
        this.partyService.sessionBookingDataSaveObj.summaryState = 'valid';
      }
      let ignoreNegotiation = !this.applyNegotiationClicked && this.isBookingDataUpdated(request);

      if(this.firstCall){
        this.ratePlanOriginal = response.Payload;
      }
      this.partyService.ratePlanForBooking =this.ratePlan = response.Payload;
      if (this.ratePlan && this.ratePlan.RatePlanCalculations) {
        this.partyService.FinancialEffectId = this.ratePlan.FinancialEffectId;
        this.negotiationCutOff = this.ratePlan.TotalRatePlan;
        this.taxAmount = this.ratePlan.TotalTaxAmount;
        this.totalServiceCharge = this.ratePlan.TotalServiceChargeAmount;
        this.totalServiceChargeWithTax = this.ratePlan.TotalTaxOnServiceChargeAmount;
        this.totalAddonTaxOnServiceChargeAmount = this.ratePlan.TotalAddonTaxOnServiceChargeAmount;
        this.minRateTaxOnServiceChargeAmount = this.ratePlan.MinRateAdjustment?.TaxOnServiceChargeAmount || 0;
        this.totalAddOnAmount = this.ratePlan.TotalAddonAmount;
        this.totalAddOnTaxAmount = this.ratePlan.TotalAddonTaxAmount;    
        let uneditableSlotRatePlan = this.getUneditableSlotsRatePlan();
        if (uneditableSlotRatePlan && uneditableSlotRatePlan.length) {
          this.ratePlan.TotalRatePlan = this.ratePlan.TotalRatePlan + uneditableSlotRatePlan.map(r => r.TotalAmount).reduce((sum, current) => sum + current, 0);
          this.uneditableTaxAmount = uneditableSlotRatePlan.map(r => r.TaxAmount).reduce((sum, current) => sum + current, 0)
          this.taxAmount += this.uneditableTaxAmount;
          this.ratePlan.TotalTaxAmount = this.ratePlan.TotalTaxAmount + this.uneditableTaxAmount;
          this.negotiationCutOff += uneditableSlotRatePlan.filter(r => r.sessionNegotiation < 0).map(r => r.sessionNegotiation).reduce((sum, current) => sum + Math.abs(current), 0)
          uneditableSlotRatePlan = _.orderBy(uneditableSlotRatePlan, s => s.TimeRange.Start);
          this.ratePlan.RatePlanCalculations = [...uneditableSlotRatePlan, ...this.ratePlan.RatePlanCalculations];
        }
        if (this.data && this.firstCall) {
          this.totalPayableAmount = this.partyService.sessionBookingDataSaveObj && (this.partyService.sessionBookingDataSaveObj.NegotiatedAmount || this.partyService.sessionBookingDataSaveObj.NegotiatedAmount == 0) ? this.ratePlan.TotalRatePlan + this.partyService.sessionBookingDataSaveObj.NegotiatedAmount
            : (this.data.NegotiatedAmount) ? this.ratePlan.TotalRatePlan + this.data.NegotiatedAmount : this.ratePlan.TotalRatePlan;
          this.remainingAmount = this.partyService.sessionBookingDataSaveObj && this.partyService.sessionBookingDataSaveObj.NegotiatedAmount ? this.partyService.sessionBookingDataSaveObj.NegotiatedAmount : this.ratePlan.OverAllNegotiation;
          this.negotiationReason = this.df.negotiationReason(this.data,this.partyService.sessionBookingDataSaveObj);
          let ratePlanDetails = this.data && this.data.bookingRatePlan ? this.data.bookingRatePlan.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation : this.ratePlan.RatePlanCalculations; 
          this.ratePlan.RatePlanCalculations.forEach(item =>{
            if(item.AddonAttemptDetails?.length){
              let addOnItem = ratePlanDetails.find(data => data.Id == item.Id)?.AddonAttemptDetails;
              if(addOnItem)
              {
              addOnItem.forEach(element => {
                item.AddonAttemptDetails.filter(addOn => addOn.AddonId == element.AddonId).map(data =>{
                  data.NegotiatedAmount = element.NegotiatedAmount || 0;
                })
              });
            }
            }
           
          })
          
                 }
        else {
          this.totalPayableAmount = this.partyService.sessionBookingDataSaveObj?.NegotiatedAmount ? this.ratePlan.TotalRatePlan + this.partyService.sessionBookingDataSaveObj.NegotiatedAmount : this.ratePlan.TotalRatePlan;
          this.remainingAmount = this.partyService.sessionBookingDataSaveObj?.NegotiatedAmount ? this.partyService.sessionBookingDataSaveObj.NegotiatedAmount : this.ratePlan.OverAllNegotiation;
          this.negotiationReason = this.df.negotiationReason(this.data,this.partyService.sessionBookingDataSaveObj);

        }
        if (this.ratePlan.TotalDepositAmount > 0 || this.data?.DepositPaid) {
          if (!this.data || !this.data.Id || this.data.PrepaymentState == PartyPrepaymentState.DepositCollected) {
            this.totalDepositAmount = this.data?.DepositPaid ? this.data.DepositPaid : this.ratePlan.TotalDepositAmount;
            this.showDepositAmount = true;
          }
        }
        this.ratePlan.SlotList = this.ratePlan?.RatePlanCalculations.map(slot => {
         // if(this.data){
          slot.activitiesAmount = 0.00;
          slot.slotItemDiscount = 0.00;          
          slot.sessionCharge = 0.00;          
          let ratePlanDetails= this.data && this.data.bookingRatePlan && this.firstCall ? this.data.bookingRatePlan?.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation:this.ratePlan.RatePlanCalculations;
          let slotItem  = ratePlanDetails.find(data => data.Id == slot.Id);
          slot.totalSessionRetailCharge = 0;
          // slot.totalSessionCharge = 0;
          

          slot.coverTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.GuestTypeVariant ? slot.RatePlanVariants.GuestTypeVariant.map(coverVariant => {
            coverVariant.adjustedMinRate = 0;
            if(slotItem && slotItem.GuestTypeVariant?.length){
              slotItem.GuestTypeVariant.forEach(element => {
                slot.GuestTypeVariant.filter(guest => guest.CoverTypeId == element.CoverTypeId).map(data =>{
                  data.NegotiatedAmount = element.NegotiatedAmount || 0;
                })
              });
            }
            coverVariant.CoverTypeName = this.coverTypes.find(coverType => coverType.Id == coverVariant.CoverTypeId).Name;
                     coverVariant.Price =  coverVariant.Variant;
           

            coverVariant.calculatesBaseVariant =  (coverVariant.OriginalVariant ) ;

            coverVariant.initialValue =  this.currencyDecimalFormater.transform(((coverVariant.calculatesBaseVariant ))) ;
            
            let negotiatePricePerCover = coverVariant.NegotiatedAmount ? coverVariant.NegotiatedAmount / coverVariant.covers : 0;

            if(this.data && this.data.Covers && coverVariant.NegotiatedAmount && coverVariant.covers){
              let prevCoverData = this.data.Covers.find(prevCover => prevCover.CoverTypeId === coverVariant.CoverTypeId);
              if(prevCoverData && prevCoverData.Covers){
                negotiatePricePerCover = coverVariant.NegotiatedAmount / prevCoverData.Covers;
              }
            }

          //  if(!this.prevSalePrice.cover[slot.TimeRange.Start+'_'+coverVariant.CoverTypeId] && !this.applyNegotiationClicked){
              let coverTypeItemFromParty  = this.partyData ? this.partyData.RatePlanVariants?.GuestTypeVariant.find(coverItem => coverVariant.CoverTypeId == coverItem.CoverTypeId) : null;
              this.prevSalePrice.cover[slot.TimeRange.Start+'_'+coverVariant.CoverTypeId]= coverTypeItemFromParty ? Number(coverVariant.initialValue) + (coverTypeItemFromParty?(coverTypeItemFromParty.NegotiatedAmount / coverTypeItemFromParty.covers):0):'-';
           //   }

                     coverVariant.prevSalePrice = this.prevSalePrice.cover[slot.TimeRange.Start+'_'+coverVariant.CoverTypeId];

            if(ignoreNegotiation && !this.applyNegotiationClicked){
              coverVariant.coverTypeValue =  this.currencyDecimalFormater.transform(coverVariant.calculatesBaseVariant);
            }else{
              coverVariant.coverTypeValue =  this.currencyDecimalFormater.transform(((coverVariant.Price + (coverVariant.NegotiatedAmount || 0)) / coverVariant.covers));
            }

            // coverVariant.coverTypeValue =  this.currencyDecimalFormater.transform(((coverVariant.Price + (coverVariant.NegotiatedAmount || 0)) / coverVariant.covers));

            coverVariant.negotiatedBaseRate = coverVariant.coverTypeValue;

            coverVariant.itemTotal = coverVariant.negotiatedBaseRate * coverVariant.covers;
            coverVariant.originalItemValue = coverVariant.calculatesBaseVariant * coverVariant.covers;
            coverVariant.itemDiscount = coverVariant.originalItemValue - coverVariant.itemTotal;
              
            slot.totalSessionRetailCharge += coverVariant.originalItemValue;
            // slot.totalSessionCharge += (coverVariant.coverTypeValue * coverVariant.covers)
            slot.slotItemDiscount += coverVariant.itemDiscount;
            // this.negotiatedDiscount += coverVariant.itemDiscount;

            /*[adjustedMinRate] calculating only for One cover*/
            if(coverVariant.MinimumCoverTypeId && coverVariant.CoverTypeId != coverVariant.MinimumCoverTypeId){
              let minCoverTypeData = slot.RatePlanVariants?.GuestTypeVariant.find(guestTypeVariant => guestTypeVariant.CoverTypeId === coverVariant.MinimumCoverTypeId);
              if(minCoverTypeData && minCoverTypeData.covers < 1){
                coverVariant.adjustedMinRate += minCoverTypeData.OriginalVariant - coverVariant.OriginalVariant;
                // this.adjustedMinRate += coverVariant.adjustedMinRate;
              }
            }

            slot.activitiesAmount += coverVariant.originalItemValue;

            return coverVariant;
          }).filter(coverVariant => coverVariant.covers > 0) : [];
          // if(slotItem && slotItem.RatePlanVariants && slotItem.RatePlanVariants.GuestTypeVariant?.length){
          //   slotItem.RatePlanVariants?.GuestTypeVariant.forEach(currGuestTypeVariantItem => {

          //     if(currGuestTypeVariantItem.MinimumCoverTypeId && currGuestTypeVariantItem.CoverTypeId != currGuestTypeVariantItem.MinimumCoverTypeId){
          //         let minCoverTypeData = slotItem.RatePlanVariants?.GuestTypeVariant.find(guestTypeVariant => guestTypeVariant.CoverTypeId === currGuestTypeVariantItem.MinimumCoverTypeId);
          //         if(minCoverTypeData && minCoverTypeData.covers < 1){
          //           this.adjustedMinRate += minCoverTypeData.OriginalVariant - currGuestTypeVariantItem.Variant;
          //         }
          //     }

          //   });
          // }


          slot.bookingTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.BookingTypeVariant ? slot.RatePlanVariants.BookingTypeVariant.map(bookingVariant => {
            bookingVariant.adjustedMinRate = 0;
            if(slotItem && slotItem.BookingTypeVariant?.length){
              slotItem.BookingTypeVariant.forEach(element => {
                slot.BookingTypeVariant.filter(guest => guest.BookingTypeId == element.BookingTypeId).map(data =>{
                  data.NegotiatedAmount = element.NegotiatedAmount || 0;
                })
              });
            }
            bookingVariant.BookingTypeName = this.bookingTypes.find(bookingType => bookingType.Id == bookingVariant.BookingTypeId)?.Name;
            
                      if(slot.RatePlanVariants && (!slot.RatePlanVariants.GuestTypeVariant || slot.RatePlanVariants.GuestTypeVariant.length == 0)){
                        
                         bookingVariant.Price =  bookingVariant.Variant;
           
                          bookingVariant.calculatesBaseVariant =  (bookingVariant.OriginalVariant ) ;
                      }else{
                          bookingVariant.Price = bookingVariant.Variant;
                          bookingVariant.calculatesBaseVariant = bookingVariant.OriginalVariant;
                      }

            bookingVariant.initialValue =  this.currencyDecimalFormater.transform(((bookingVariant.calculatesBaseVariant ))) ;
            
            let negotiatePricePerBookingType = bookingVariant.NegotiatedAmount ? bookingVariant.NegotiatedAmount / bookingVariant.bookingTypes : 0;

            if(this.data && this.data.BookingTypeQuantities && bookingVariant.NegotiatedAmount && bookingVariant.bookingTypes){
              let prevBookingTypeData = this.data.BookingTypeQuantities.find(prevBookingType => prevBookingType.BookingTypeId === bookingVariant.BookingTypeId);
              if(prevBookingTypeData && prevBookingTypeData.BookingTypes){
                negotiatePricePerBookingType = bookingVariant.NegotiatedAmount / prevBookingTypeData.BookingTypes;
              }
            }

            if(!this.prevSalePrice.bookingType[slot.TimeRange.Start+'_'+bookingVariant.BookingTypeId] && !this.applyNegotiationClicked){
                          }
                          let bookingTypeFromParty = this.partyData? this.partyData.RatePlanVariants?.BookingTypeVariant.find(booking => bookingVariant.BookingTypeId == booking.BookingTypeId) : null;
                          this.prevSalePrice.bookingType[slot.TimeRange.Start+'_'+bookingVariant.BookingTypeId] = bookingTypeFromParty ? Number(bookingVariant.initialValue) + (bookingTypeFromParty? (bookingTypeFromParty.NegotiatedAmount / bookingTypeFromParty.bookingTypes) : 0):'-';
            
                     bookingVariant.prevSalePrice = this.prevSalePrice.bookingType[slot.TimeRange.Start+'_'+bookingVariant.BookingTypeId];

            if(ignoreNegotiation && !this.applyNegotiationClicked){
              bookingVariant.bookingTypeValue =  this.currencyDecimalFormater.transform(bookingVariant.calculatesBaseVariant);
            }else{
              bookingVariant.bookingTypeValue =  this.currencyDecimalFormater.transform(((bookingVariant.Price + (bookingVariant.NegotiatedAmount || 0)) / bookingVariant.bookingTypes));
            }

            // bookingVariant.bookingTypeValue =  this.currencyDecimalFormater.transform(((bookingVariant.Price + (bookingVariant.NegotiatedAmount || 0)) / bookingVariant.bookingTypes));

            bookingVariant.negotiatedBaseRate = bookingVariant.bookingTypeValue;

            bookingVariant.itemTotal = bookingVariant.negotiatedBaseRate * bookingVariant.bookingTypes;
            bookingVariant.originalItemValue = bookingVariant.calculatesBaseVariant * bookingVariant.bookingTypes;
            bookingVariant.itemDiscount = bookingVariant.originalItemValue - bookingVariant.itemTotal;
              
            slot.totalSessionRetailCharge += bookingVariant.originalItemValue;
            // slot.totalSessionCharge += (bookingVariant.bookingTypeValue * bookingVariant.bookingTypes)
            slot.slotItemDiscount += bookingVariant.itemDiscount;
            // this.negotiatedDiscount += bookingVariant.itemDiscount;

           

            slot.activitiesAmount += bookingVariant.originalItemValue;

            return bookingVariant;
          }).filter(bookingVariant => bookingVariant.bookingTypes > 0) : [];

          if(slot.RatePlanVariants){
            slot.sessionBaseVariant =(slot.RatePlanVariants.OriginalBaseRate || 0);
            this.prevSalePrice.base[slot.TimeRange.Start+'_'] ='-';
          //  if(ignoreNegotiation && !this.applyNegotiationClicked){
            this.prevSalePrice.base[slot.TimeRange.Start+'_'] = this.data && this.data.bookingRatePlan?.RatePlanTotalNegotiation ? (slot.sessionBaseVariant + (this.partyData ? this.partyData.RatePlanVariants?.NegotiatedAmount : 0)): '-';
            //}
            // }else{
            //   slot.sessionCharge = ((slot.sessionBaseVariant * this.partyService.sessionBookingData.BookingSize) + (slot.RatePlanVariants.TotalNegotiation || 0)) / this.partyService.sessionBookingData.BookingSize;
            //   this.prevSalePrice.base[slot.TimeRange.Start+'_'] = slot.sessionCharge;
            // }
            slot.sessionCharge = ((slot.RatePlanVariants.TotalBaseRate ) + (slot.RatePlanVariants.NegotiatedAmount || 0)) ;
           // this.prevSalePrice.base[slot.TimeRange.Start+'_'] = ignoreNegotiation ?slot.sessionBaseVariant + this.partyData.RatePlanVariants.NegotiatedAmount : '-';
            slot.basePrevSalePrice = this.prevSalePrice.base[slot.TimeRange.Start+'_'] || '-'; 
            slot.sessionChargeinitialValue = slot.sessionBaseVariant * this.partyService.sessionBookingData.BookingSize;
          
            slot.totalSessionRetailCharge += slot.sessionChargeinitialValue;
            // slot.totalSessionCharge += (slot.sessionCharge * this.partyService.sessionBookingData.BookingSize);
            // this.negotiatedDiscount += ((slot.sessionBaseVariant - slot.sessionCharge) * this.partyService.sessionBookingData.BookingSize);
  
            slot.activitiesAmount +=  slot.sessionChargeinitialValue;
            
          }

          slot.totaladdonCharges = 0.00;
          slot.AddonAttemptDetails = slot.AddonAttemptDetails ? slot.AddonAttemptDetails.map(addOnattempt => {
            if(addOnattempt.OverBooked){
              addOnattempt.Quantity -= addOnattempt.OverBooked;
            }
            if(slotItem && slotItem.AddonAttemptDetails.length && this.firstCall){
              slotItem.AddonAttemptDetails.forEach(element => {
                slot.AddonAttemptDetails.filter(addOn => addOn.AddonId == element.AddonId).map(data =>{
                  data.NegotiatedAmount = element.NegotiatedAmount || 0;
                })
              });
            }
            addOnattempt.prevSalePrice = '-';
            if(this.data && this.data?.BookingContactAddonItems){
              let addonPrevQuantity = this.data?.BookingContactAddonItems ? this.data.BookingContactAddonItems.find(({AddonId}) => AddonId == addOnattempt.AddonId) : null;
              let addonPrevAmount = this.data?.BookingContactAddonItems && this.data.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation.length ? this.data.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation[0].AddonAttemptDetails.find(({AddonId}) => AddonId === addOnattempt.AddonId) : null;

              // if(addonPrevQuantity && addonPrevAmount){
              //   addOnattempt.prevSalePrice = this.currencyDecimalFormater.transform(((addonPrevAmount.TotalAmount || 0) + (addonPrevAmount.NegotiatedAmount || 0)) / addonPrevQuantity.AddonCount);
              // }else{
              //   addOnattempt.prevSalePrice = '-';
              // }
              let addOnData = this.partyData ? this.partyData.AddonAttemptDetails.find(addOn => addOn.AddonId == addOnattempt.AddonId) : null;
              addOnattempt.prevSalePrice =  addOnData  ? addOnattempt.PricePerItem + (addOnData ? (addOnData.NegotiatedAmount / addOnData.Quantity) : 0):'-';
  
                        }

            addOnattempt.perItemValue = this.currencyDecimalFormater.transform(((addOnattempt.TotalAmount + (addOnattempt.NegotiatedAmount || 0)) / addOnattempt.Quantity));
            if(ignoreNegotiation && !this.applyNegotiationClicked){
              addOnattempt.perItemValue = this.currencyDecimalFormater.transform(((addOnattempt.TotalAmount) / addOnattempt.Quantity));
              addOnattempt.perItemValue * addOnattempt.Quantity;
            }

            addOnattempt.initialValue = this.currencyDecimalFormater.transform(((addOnattempt.TotalAmount) / addOnattempt.Quantity));
                       addOnattempt.negotiatedBaseRate = addOnattempt.perItemValue;

            addOnattempt.itemTotal = addOnattempt.negotiatedBaseRate * addOnattempt.Quantity;
            addOnattempt.originalItemValue = addOnattempt.initialValue * addOnattempt.Quantity;
            addOnattempt.itemDiscount = addOnattempt.originalItemValue - addOnattempt.itemTotal;
              
            slot.totaladdonCharges += addOnattempt.originalItemValue;
            // slot.totalSessionCharge += addOnattempt.itemTotal;
            slot.slotItemDiscount += addOnattempt.itemDiscount;
          //  slot.activitiesAmount +=  addOnattempt.originalItemValue;

            // this.negotiatedDiscount += addOnattempt.itemDiscount;             
            return addOnattempt;
          }) :[]

          //this.ratePlan.RatePlanVariants.TotalBaseRate * this.partyService.sessionBookingData.BookingSize
          // slot.totalBaseRate = slot.RatePlanVariants.OriginalBaseRate * this.partyService.sessionBookingData.BookingSize;
        //  slot.totalAddOnAmount = slot.TotalAddOnAmount + slot.OverallAddonTotalNegotiatedAmount;
        if(slot.RatePlanVariants){
        slot.RatePlanVariants.locationAmount = (_.sumBy(slot.RatePlanVariants.LocationVariant , 'Variant') || 0) + (slot.RatePlanVariants.LocationNegotiatedAmount || 0);
        slot.RatePlanVariants.instructorAmount = (slot.RatePlanVariants.InstructorVariant || 0) + (slot.RatePlanVariants.InstructorNegotiatedAmount || 0);
        }
          slot.membershipAmount = (slot.MemberShipVariant || 0) + (slot.NegotiatedMemberShipVariant || 0);
          if(ignoreNegotiation && !this.applyNegotiationClicked){
                        if(slot.RatePlanVariants){
            slot.RatePlanVariants.locationAmount = _.sumBy(slot.RatePlanVariants.LocationVariant , 'Variant');
            slot.RatePlanVariants.instructorAmount = slot.RatePlanVariants.InstructorVariant;
            }
            if(this.data && this.data.RatePlanTotalNegotiation && this.data.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation){
              let oldSlotData = this.data.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation.find(prevSlotData => prevSlotData.TimeRange.Start === slot.TimeRange.Start && prevSlotData.TimeRange.End === slot.TimeRange.End);
              this.prevSalePrice.membership[slot.TimeRange.Start+'_'] = oldSlotData ? oldSlotData.NegotiatedMemberShipVariant + oldSlotData.MemberShipVariant : '';
            }
           
            // this.prevSalePrice.membership[slot.TimeRange.Start+'_'] = this.data?.Id ?  NegotiatedMemberShipVariant : '-'
            slot.membershipAmount = (slot.MemberShipVariant || 0);
          }
          this.prevSalePrice.location[slot.TimeRange.Start+'_'] =  this.data && this.data.bookingRatePlan?.RatePlanTotalNegotiation ? ((slot?.RatePlanVariants?.OriginalLocationVariant ) + (this.partyData ? this.partyData.RatePlanVariants?.LocationNegotiatedAmount : 0)):'-' ;
          this.prevSalePrice.instructor[slot.TimeRange.Start+'_'] = this.data && this.data.bookingRatePlan?.RatePlanTotalNegotiation ? ((slot?.RatePlanVariants?.OriginalInstructorVariant ) + (this.partyData ? this.partyData.RatePlanVariants?.InstructorNegotiatedAmount : 0)):'-';

                  slot.prevLocationSalePrice = this.prevSalePrice.location[slot.TimeRange.Start+'_'] || '-';
          slot.prevInstructorSalePrice = this.prevSalePrice.instructor[slot.TimeRange.Start+'_'] || '-';
          slot.prevMembershipSalePrice = this.prevSalePrice.membership[slot.TimeRange.Start+'_'] || '-';
          // slot.totalSessionCharge +=  slot.RatePlanVariants.LocationVariant + slot.RatePlanVariants.InstructorVariant; 
          slot.totalSessionRetailCharge += slot.totaladdonCharges;
          slot.activitiesAmount += slot.totaladdonCharges 
          if(slot.RatePlanVariants ){
            let locationAndInstructorVariant = 0;
            locationAndInstructorVariant = ((_.sumBy(slot.RatePlanVariants.LocationVariant , 'Variant') || 0) + ( slot.RatePlanVariants.InstructorVariant || 0))
            slot.totalSessionRetailCharge += (locationAndInstructorVariant * BookingSize);
            slot.activitiesAmount += locationAndInstructorVariant;
          }          
          slot.activitiesAmount += this.ratePlan?.MinRateAdjustment?.MinRateAdjustmentAmount || 0;
           this.activitiesAmount += slot.activitiesAmount;
          return slot;
        });
        // this.originalAdjustedMinRate = this.adjustedMinRate;
        // this.setAdjustedMinRate();
        if(ignoreNegotiation){
      //    this.prevSalePrice.adjustedMinRate =  this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount +(this.partyService.retailservice.oldReservation ? this.partyService.retailservice.oldReservation.RatePlanTotalNegotiation?.MinRateNegotiatedAdjustment : 0);

        }
        this.prevSalePrice.adjustedMinRate = this.data && this.data.bookingRatePlan?.RatePlanTotalNegotiation ? this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount +(this.partyService.retailservice.oldReservation ? this.partyService.retailservice.oldReservation.RatePlanTotalNegotiation?.MinRateNegotiatedAdjustment : 0):'-';
        this.ratePlan.MinRateAdjustment.Amount = this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount + (this.ratePlan.MinRateAdjustment.NegotiatedAmount || 0);
        this.onMinRateAdjusted();

      } else {
        this.ratePlan.SlotList = Slots;
      }
      this.partyService.ratePlan$.next(this.ratePlan);
      this.enableGuestSelectionComponent();
      if (!this.ratePlan.TotalPartyAmount && !this.ratePlan.TotalRatePlan && response?.ValidationMessages?.length ) {
        this.ratePlanValidationMsg = response?.ValidationMessages?.filter(x => x.Type == ValidationMessageType.Warning)[0]?.Message;

        if ((!this.data || (this.data && !this.data.Contact))) {
          this.ratePlanValidationInfo();
        }
        else {
          if (this.partyService.isGuestComponentLoaded) {
            this.ratePlanValidationInfo();
          }
        }
      }
      if (!this.data && this.remainingAmount) {
        this.calculateNegotiableRate();
      }
     this.totalAmountForBooking = (_.sumBy(this.ratePlan.SlotList, 'totalAddOnAmount') +_.sumBy(this.ratePlan.SlotList, 'totalAmount'));
      this.formChangeSubscribe();
      this.createForm()


      if(ignoreNegotiation && !autoRateplanRecalculate){
        this.applyNegotiationClicked = true;
        this.firstCall = false;
     //   this.setRateplanCalculationObject(true);
      }
      this.calculatePaymentDetails();
      this.setRateSummary();
      this.applyNegotiationClicked = false;

    }));
  }

  isBookingDataUpdated(request){
    let isGuestTypeChanged = false;
    let isBookingTypeChanged = false;
    let isSessionChanged = false;
    let isAddonChanged  = false;
    let bookingSizeChanged = false;

    if(!this.data){
      return false;
    }

    if(!this.data?.CoverTypeQuantities || !this.data?.CoverTypeQuantities){
      return false;
    }

    if(request.GuestTypes?.length){
      isGuestTypeChanged = request.GuestTypes.filter((reqGuestType) => {
        let savedGuestType = this.data.CoverTypeQuantities.find(srcGuestType => srcGuestType.CoverTypeId === reqGuestType.CoverTypeId);
        return savedGuestType && savedGuestType.Covers === reqGuestType.Covers;
      }).length !== this.data.CoverTypeQuantities.length;
    }

    if(request.BookingTypes?.length){
      isBookingTypeChanged = request.BookingTypes.filter((reqBookingType) => {
        let savedBookingType = this.data.BookingTypeQuantities.find(srcGuestType => srcGuestType.BookingTypeId === reqBookingType.BookingTypeId);
        return savedBookingType && savedBookingType.BookingTypes === reqBookingType.BookingTypes;
      }).length !== this.data.BookingTypeQuantities.length;
    }

    if(!request.GuestTypes?.length && !request.BookingTypes?.length){
      bookingSizeChanged = this.data.Size != +request.Size;
    }

    if(this.data.BookedSessions?.length){
      isSessionChanged = !(this.data.BookedSessions.length === request.SessionSlot.Sessions[0].SessionIds.length 
      && this.data.BookedSessions.filter(bookedSession => 
      request.SessionSlot.Sessions[0].SessionIds.includes(bookedSession.ActivitySessionId)).length)
    }else if(this.data.SessionGroupId && request.SessionSlot){
      isSessionChanged = this.data.SessionGroupId !== request.SessionSlot.SessionGroupId;
    }
    if(this.data?.BookingContactAddonItems){
      isAddonChanged = !(request.SelectedAddOns.filter((reqAddon) => {
        let savedAddon = this.data.BookingContactAddonItems.find(srcAddon => srcAddon.AddonId === reqAddon.AddonId);
        return savedAddon && savedAddon.AddonCount === reqAddon.Quantity
      }).length === this.data.BookingContactAddonItems.length) || request.SelectedAddOns.length !== this.data.BookingContactAddonItems.length;      
    }

    return isGuestTypeChanged || isSessionChanged || isAddonChanged || bookingSizeChanged || isBookingTypeChanged;
  }

  findCoverPrice(coverTypesForMeal,cover ){
    if(coverTypesForMeal.length){
      return (coverTypesForMeal.find(meal => meal.Id == cover.CoverTypeId)?.Price  || 0)
    }
  }

  setRateSummary(){
    this.partyService.sessionBookingDataSaveObj.rateSummary = {
      Amt: this.subtotal,
      TaxAmt: this.tax,
      SCAmt: this.serviceCharge,
      TaxOnSCAmt: this.taxOnServiceCharge
    }
  }

  calculatePaymentDetails(){
    // this.activitiesAmount = this.ratePlan.TotalRatePlan + this.ratePlan.TotalAddonAmount + this.adjustedMinRate;
   // this.activitiesAmount = this.activitiesAmount - this.slotItemDiscount
    // this.subtotal = this.activitiesAmount - this.negotiatedDiscount;
    this.onNegotiate(true);
    this.serviceCharge = (this.totalServiceCharge || 0) + (this.ratePlan.TotalAddonServiceChargeAmount || 0) + (this.ratePlan.MinRateAdjustment.ServiceChargeAmount || 0);
    this.taxOnServiceCharge = (this.totalServiceChargeWithTax || 0) + (this.totalAddonTaxOnServiceChargeAmount || 0) + (this.minRateTaxOnServiceChargeAmount || 0);
    this.tax = (this.taxAmount || 0) + (this.totalAddOnTaxAmount || 0) + (this.ratePlan.MinRateAdjustment.TaxAmount || 0);
    this.grandTotal = this.subtotal + this.serviceCharge + this.taxOnServiceCharge + this.tax;
    let guestSelected = this.partyService.reservationFormGroup.controls.selectedGuest.value;
    this.changeFormState(guestSelected);
  }

  onNegotiate(forGrandTotal?){
    this.partyService.sessionBookingDataSaveObj.summaryState = forGrandTotal ? 'valid' : 'invalid';
    this.changeFormState(this.partyService.reservationFormGroup.controls.selectedGuest.value);
    this.ratePlan.SlotList.forEach(slot => this.negotiateTotalSessionCharge(slot));
    this.subtotal = +this.ratePlan.SlotList.reduce((res , slot) => res + slot.negotiatedTotalSessionCharge, 0) + (this.ratePlan?.MinRateAdjustment?.Amount || 0);
    this.negotiatedDiscount = this.activitiesAmount - this.subtotal;
  }

  onMinRateAdjusted(){
    if(this.partyService.sessionBookingDataSaveObj){
      this.partyService.sessionBookingDataSaveObj.MinRateNegotiatedAdjustment = this.ratePlan.MinRateAdjustment.Amount - this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount;
    }
  }

  negotiateTotalSessionCharge(updatedSlot?){
//+ updatedSlot.RatePlanVariants.TotalBaseRate
    if(updatedSlot){
      let baseRateValue = 0;
      let negotiatedTotalCoverValue = updatedSlot.coverTypeVariants && updatedSlot.coverTypeVariants.length ? updatedSlot.coverTypeVariants.reduce((res, cover) => res + ((cover.coverTypeValue) * cover.covers), 0): 0;
      let negotiatedTotalBookingTypeValue = updatedSlot.bookingTypeVariants && updatedSlot.bookingTypeVariants.length ? updatedSlot.bookingTypeVariants.reduce((res, bookingType) => res + ((bookingType.bookingTypeValue) * bookingType.bookingTypes), 0): 0;
      //if(!updatedSlot.coverTypeVariants?.length && !updatedSlot.bookingTypeVariants?.length){
        baseRateValue = updatedSlot.sessionCharge;
      //}
      let negotiatedTotalAddonValue = updatedSlot.AddonAttemptDetails.reduce((res, addOn) => res + (addOn.perItemValue * addOn.Quantity), 0);
      let totalLocationVariant = +(updatedSlot.RatePlanVariants? updatedSlot.RatePlanVariants.locationAmount : 0);
      let totalInstructorVariant = +(updatedSlot.RatePlanVariants? updatedSlot.RatePlanVariants.instructorAmount : 0);
      let totalMembershipVariant = updatedSlot.membershipAmount || 0;
      updatedSlot.negotiatedTotalSessionCharge = +(totalLocationVariant + totalInstructorVariant + negotiatedTotalCoverValue + negotiatedTotalBookingTypeValue + negotiatedTotalAddonValue + baseRateValue + totalMembershipVariant);
    }
  }

  formData(resetData){
    if(this.partyService.ratePlanForBooking){
      this.partyService.getNegotiatedValueForBooking(this.partyService.sessionBookingData);
    return  {
      Negotiation: null,
      MinRateNegotiatedAdjustment: this.ratePlan.MinRateAdjustment.Amount - this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount,
      applyNegotiationOnOtherCharges:[
        {             
          bookingChargeType : BookingChargeType.ServiceCharge,
          TotalAmount: this.partyService.ratePlanForBooking.TotalServiceCharge,
          NegotiatedAmount: null,
          UseAfterNegotiation: this.df.negotiateServiceCharge
        }
      ],
      RatePlanCalculationInfoNegotiation: this.partyService.ratePlanForBooking?.RatePlanCalculations && !resetData ? this.partyService.ratePlanForBooking?.RatePlanCalculations as RatePlanCalculationInfoNegotiation: null
    }
   
  }
    else{
      return null;
    }
  }
  createForm(){
    this.FormGrp = this.fb.group({
      baseRate: '',
      NegotiationLesson: '',
      NegotiationAddon: '',

    });
  }

  enableGuestSelectionComponent() {
    this.createSaveRequestObject();
    this.enableGuestComponent = true;
    if (this.partyService.reservationFormGroup.controls.selectedGuest.value) {
      this.changeFormState(true);
    } else {
      this.changeFormState(false);
    }

  }
  ngAfterViewInit() {
    if (this.data) {
      const specailMeals = this._settings.SpecialMeals.filter((meal) => meal.Id === this.data.SpecialMealId);
      if (specailMeals[0].IsForStandbyReservations && this.data.isConfirmedReservation) {
        this.ps.previousButtonEnabled$.next(false);
      } else {
        this.ps.previousButtonEnabled$.next(true);
      }
      if (this.data && this.data.Notes && this.data.Notes.length > 0) {
        let filteredNotes = this.data.Notes.filter(_note => _note.Type == PartyNoteType.SpecialMeal);
        if (filteredNotes && filteredNotes.length > 0) {
          filteredNotes.forEach(element => {
            this.partyService.partyNotes.push(element);
          });
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();

    }
    this.partyService.partyNotes = [];
    this.partyService.isGuestComponentLoaded = false;
    this.partyService.ratePlanForBooking = null;
    this.df.enabledNegotiation = false;
    this.df.negotiateServiceCharge = false;
  }

  createSaveRequestObject() {
    var bookingData = this.partyService.sessionBookingData;
    const specailMeals = this._settings.SpecialMeals.filter((meal) => meal.Id === this.partyService.sessionBookingData?.ActivityId);
    if(specailMeals?.length) {
      let standbymeal = specailMeals[0].IsForStandbyReservations;
      this.partyService.isStandBy = standbymeal;
      this.partyService.sessionBookingDataSaveObj = {
        Id:  this.data ? this.data.Id : null,
        Size:  bookingData.BookingSize,
        RemindAboutVisit:  false,
        RemindAboutVisitEmail:  false,
        AgreedWithTermsOfUse:  true,
        Contact:  {},
        Slot:  this.getRequestObjectSlot(),
        Sessions:  this.getRequestObjectSlots(),
        PartyId:  (this.data && this.data.Id) ? this.data.Id : null,
        SeatingAreaId:  null,
        SeatingTypeId:  null,
        ConfirmationMessageOption:  0,
        Notes:  this.partyService.partyNotes,
        TableIds:  this.partyService.sessionBookingData.Location,
        InstructorIds:  this.partyService.sessionBookingData.StaffId,
        CoverTypes:  this.partyService.sessionBookingData.CoverTypes,
        IsForStandbyReservations:  standbymeal || (this.partyService.sessionBookingData.AllowStandBy && !this.partyService.sessionBookingData.OverBooked),
        BookingTypes:  this.partyService.sessionBookingData.BookingTypes,
        IsCommunalTable:  false,
        FromWebsite:  true,
        Language:  0,
        ReservationAttemptId:  null,
        waiverFormTransactionId:  null,
        SecondaryContactsList:  null,
        BookingBehavior:  BookingBehavior.ClassOrSession,
        SpecialMealId:  bookingData.ActivityId,
        StartDate:   this.partyService.sessionBookingData.ClassType == ClassType.Session ?  this.getStartDate() : new Date(bookingData.FromDate).toDateString(),
        EndDate:   this.partyService.sessionBookingData.ClassType == ClassType.Session ?  this.getEndDate() : new Date(bookingData.ToDate).toDateString(),
        NegotiatedAmount:  this.ratePlan?.TotalRatePlan > 0 ? this.remainingAmount : 0,
        Comments:  this.negotiationReason,
        TotalPayable:  this.totalPayableAmount + (this.totalAddOnAmount || 0),
        TaxAmount:  this.taxAmount + (this.totalAddOnTaxAmount || 0),
        RestaurantId:  Utilities.RestaurantId(),
        PackageConfirmationId:  (this.data && this.data.PackageConfirmationId) ? this.data.PackageConfirmationId : null,
        PackageId:  (this.data && this.data.PackageId) ? this.data.PackageId : null,
        Type:  standbymeal ? PartyType.StandBy : this.partyService.sessionBookingData.AllowStandBy && !this.partyService.sessionBookingData.OverBooked ? PartyType.StandBy : PartyType.Reservation,
        HostId:  this.data?.Id ? this.data.HostId : JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostId,
        FinancialEffectId: this.partyService.FinancialEffectId,
        PaymentMethod: this.partyService.bookingPaymentType,
        RetailCardTokenId: this.partyService.RetailCardTokenId,
        MinRateNegotiatedAdjustment:  this.ratePlan?.MinRateAdjustment?.Amount - this.ratePlan?.MinRateAdjustment?.MinRateAdjustmentAmount,
        summaryState:  this.partyService.sessionBookingDataSaveObj?.summaryState,
        SalesContactIds: bookingData?.SalesContactIds,
      }
    }
  }

  getStartDate(){
    let dateArray =this.getRequestObjectSlots().Sessions.map(x=> new Date(x.Date))
    return  moment(Math.min.apply(null,dateArray)).format('YYYY-MM-DD') + "T00:00:00.000"
  }

  getEndDate(){
    let dateArray =this.getRequestObjectSlots().Sessions.map(x=> new Date(x.Date))
    return moment(Math.max.apply(null,dateArray)).format('YYYY-MM-DD') + "T00:00:00.000"
  }
  setPartyPaymentType(data){
    if(data && this.partyService.sessionBookingDataSaveObj){
      this.partyService.bookingPaymentType = data;
      this.partyService.sessionBookingDataSaveObj.PaymentMethod = data;
    }
  }
  setRetailCardTokenId(data){
    if(data && this.partyService.sessionBookingDataSaveObj){
      this.partyService.RetailCardTokenId = data;
      this.partyService.sessionBookingDataSaveObj.RetailCardTokenId = data;
    }
  }
  getRequestObjectSlots() {
    var bookingData = this.partyService.sessionBookingData;
    var sessionClass = this.partyService.sessionBookingData.ClassType === ClassType.Session;

    return {
      LockId: this.lockResponse?.SlotLockIdDTO?.Id || null,
      Sessions: sessionClass ? bookingData.Slots : [],
      SessionGroupId: sessionClass ? null : bookingData.SessionGroupId,
      OverBooked: bookingData.OverBooked,
      BookedSessionIds: this.data?.BookedSessions && this.df.checkEditCheckedInReservationsConfig() ? this.fetchIdsOfSelectedSessions(this.data.BookedSessions) : []
    }
  }
  getRequestObjectSlot() {
    var bookingData: SessionBookingDTO = this.partyService.sessionBookingData;

    return {
      "Time": new Date(bookingData.FromDate).toDateString(),
      "LockId": this.lockResponse?.SlotLockIdDTO?.Id || null,
      "Type": bookingData.AvailabilityCriteria
    }
  }

  fetchIdsOfSelectedSessions(bookedSessions){
    let sessionIds =[];
    let activityMinDate = this.df.getMinDateBasedonConfig(this._settings.SpecialMeals.find(meal => meal.Id == this.partyService.selectedSpecialMealId),Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    activityMinDate.setHours(0);
    activityMinDate.setMinutes(0);
    activityMinDate.setSeconds(0);
    activityMinDate.setMilliseconds(0);
    bookedSessions.map(slot => {
      if(new Date(slot.BookedDate).getTime() >= new Date(activityMinDate).getTime() && slot.SessionState != PartyState.Left && slot.Id){
        sessionIds.push(slot.Id);
      }})
    return sessionIds;
  }

  changeFormState(enable) {
    this.ps.formValid$.next(
      {
        isFormValid: this.popupService.enableSaveBtnAuthorise$.value == true ? false : enable && this.grandTotal >= 0,// this.totalPayableAmount >= 0,
        currentTab: 2
      } as IFormValidDetails
    );
  }

  ratePlanValidationInfo(){
    const _dialogRef = this.showConfirmationPopup(this.translateService.instant('alert'), this.ratePlanValidationMsg, 'active', true, 'Proceed', 'Cancel');
    _dialogRef.afterClosed().subscribe((dataValue) => {
      if(dataValue){
        this.partyService.activityBookingDialogRef ? this.partyService.activityBookingDialogRef.close() : '';
        this.partyService.activityBookingDialogRef ? this.partyService.activityBookingDialogRef = null : '';
        this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef.close() : '';
        this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef = null : '';
      }
      
      this.ratePlanValidationMsg = '';
    });

  }

  showConfirmationPopup(title, message, popupType: 'action' | 'active' | '', showAlert, saveBtnLabel, cancelBtnLabel){
          
    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: title, showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType,
      dimensionType: 'small',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };
    const _dialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      width: '550px',
      height: '350px',
      data: {
        title,
        update: saveBtnLabel,
        cancel: cancelBtnLabel,
        componentDetails,
        from: null,
        back: false,
        standalone: true,
        showAction: true
      },
    });
    return _dialogRef;
  }

  showWarningPopup(message, componentType, priorPopUp?, priorPopUpComponentType?) {
    if (message) {
      let dontContinueBooking = false

      const popUpMessage = [{
        confirmationMessage: message, dialogTitle: this.translateService.instant('alert'), showAlert: true
      }];
      let popUpType = componentType == ComponentTypes.AlertWrongNegotiation ? 'action' : 'active';
      const componentDetails: ComponentDetails = {
        componentName: ConfirmationPopupComponent,
        dimensionType: 'small',
        popupType: popUpType,
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: popUpMessage,
        popupTitle: popUpMessage[0].dialogTitle
      };

      let updateText = componentType == ComponentTypes.AlertWrongNegotiation ? 'Ok' : 'Proceed';
      let cancelText = componentType == ComponentTypes.AlertWrongNegotiation ? '' : 'Cancel';

      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '550px',
        height: '350px',
        data: {
          title: this.translateService.instant('alert'),
          update: updateText,
          cancel: cancelText,
          componentDetails,
          from: componentType,
          back: false,
          standalone: true,
          showAlert: true,
          showAction: true
        }
      });
      let closepriorpopup = false
      this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
        if (priorPopUp && closepriorpopup) {
          this.ps.restrictCloseDialog = false;
          this.ps.isRestrictCloseDialog = false;
          priorPopUp.close(priorPopUpComponentType);
        }
        if (dontContinueBooking) {
          this.partyService.activityBookingDialogRef ? this.partyService.activityBookingDialogRef.close() : '';
          this.partyService.activityBookingDialogRef ? this.partyService.activityBookingDialogRef = null : '';
          this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef.close() : '';
          this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef = null : '';
        }
        if (cancelPopUpConfirmSubscription) {
          cancelPopUpConfirmSubscription.unsubscribe();
        }
        if (popUpConfirmationSubscription) {
          popUpConfirmationSubscription.unsubscribe();
        }
        this.ratePlanValidationMsg = '';
      }));
      let cancelPopUpConfirmSubscription = this.popupService.cancelledAction$.subscribe(val => {
        if (val.from === ComponentTypes.RatePlanWarning) {
          dontContinueBooking = true;
        }
        if (val.from === ComponentTypes.AlertNegotiation) {
          closepriorpopup = false
          dialogRef.close();
        }
        if (val.from === ComponentTypes.AlertWrongNegotiation) {
          closepriorpopup = false
          dialogRef.close();
        }
      });

      let popUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.AlertNegotiation) {
          closepriorpopup = true
          this.ps.restrictCloseDialog = false;
          this.ps.isRestrictCloseDialog = false;
          dialogRef.close(priorPopUpComponentType);
        }
        if (val === ComponentTypes.AlertWrongNegotiation) {
          closepriorpopup = false
          dialogRef.close();
        }
      });
    }
  }
  formChangeSubscribe() {
    this.components.forEach((x, index) => {
      this.subscriptions.add(x.form?.valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(value => {
          this.formChangeHandler(value);
        }));
    })

    if(this.negotiateReasonForm?.form){
      this.subscriptions.add(this.negotiateReasonForm.form.valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(value => {
          this.formChangeHandler(value);
      }));
    }
  }

  formChangeHandler(value){
    this.bindInternalPartyNotes(value);
    this.enableGuestSelectionComponent();
    this.enableNegotiationOption(value);
    this.applyTaxForNegotiated(value);
    this.addNegotiationReason(value);
  }

  addNegotiationReason(value){
    if (value.hasOwnProperty(this.rateNegotiateConfig[0].name)) { 
      this.negotiationReason = value[this.rateNegotiateConfig[0].name];  
      this.partyService.sessionBookingDataSaveObj.Comments = this.negotiationReason;
      this.applyNegotiationBtn.disbaledproperity = this.negotiationReason == '' ? true : false;
      // this.partyService.rateNegotiationObject = {       
      //   Reason: value[this.rateNegotiateConfig[0].name].reasonText.find(reason => reason.commentType == CommentType.Negotiation)
      // }
    }
  }

  validateCharacters(evt){
    if (evt.which != 8 && evt.which != 0 && evt.which != 46 && evt.which < 48 || evt.which > 57)
    {
        evt.preventDefault();
    }
  }

  calculateValue(slot){
    // if(!this.firstCall){
    // this.partyService.ratePlanForNegotiation =_.cloneDeep(this.partyService.ratePlanForBooking);
    // }
      this.calculateAmount(slot);
      this.applyNegotiationBtn.disbaledproperity = false;
  }

  enableNegotiationOption(value) {
    if (value[this.negotiationRatesConfig[0].name]) {
      this.perItemEditable = true;
      this.df.enabledNegotiation = true;
      
    }
    else if(value[this.negotiationRatesConfig[0].name] == false){
      this.perItemEditable = false;
      this.df.enabledNegotiation = false;
      this.editNegotiationAmount();
    }

  }
  applyTaxForNegotiated(value){
    if (value.hasOwnProperty(this.recalculateConfig[0].name)) {      
      this.applyNegotiationBtn.disbaledproperity = this.negotiationReason != '' ? false : true;
      this.df.negotiateServiceCharge = value[this.recalculateConfig[0].name]; 
    }
    else{
      //this.totalServiceCharge = _.cloneDeep(this.ratePlan?.TotalServiceChargeAmount ?  this.ratePlan?.TotalServiceChargeAmount : 0);
    }
  }

  applyNegotiation(){
    this.firstCall = false;
    this.applyNegotiationClicked = true;
    this.applyNegotiationBtn.disbaledproperity = true;
  //  this.changeFormState(false);
 //   this.appliedNegotiation = false;
    this.setRateplanCalculationObject(false);
  }

  setRateplanCalculationObject(autoRateplanRecalculate,resetData?){
    let { ActivityId, FromDate, Slots, CoverTypes, BookingTypes, Location, StaffId, BookingSize, SessionGroupId } = this.partyService.sessionBookingData;
    this.setRatePlanCalculation(Slots, CoverTypes, BookingTypes, Location, BookingSize, ActivityId, SessionGroupId,this.ratePlan.FinancialEffectId, autoRateplanRecalculate, resetData);
  }

  cancelNegotiation(){
 //   this.appliedNegotiation = false;
 //   this.changeFormState(false);
    this.totalServiceCharge = _.cloneDeep(this.ratePlan.TotalServiceChargeAmount);
    this.df.negotiateServiceCharge = false; 
    this.df.enabledNegotiation = false;    
    this.negotiationReason = '';
    this.rateNegotiateConfig[0].value = '';
    this.negotiateReasonForm.form.reset();
    if(this.data && this.data.bookingRatePlan?.Comments){
      this.negotiateReasonForm.form.controls?.reasonText?.setValue(this.setNegotiationReason());;
    }
    else{
      this.negotiateReasonForm.form.controls?.reasonText?.setValue('');
    }
  //  this.perItemEditable = false;

    //this.negotiationConfigForm.form.controls.negotiation.setValue(false);
    // this.editNegotiationAmount();
    this.setRateplanCalculationObject(false,true);
  }

  editNegotiationAmount() {
    // this.adjustedMinRate = this.originalAdjustedMinRate;
    this.ratePlan = this.partyService.ratePlanForBooking = _.cloneDeep(this.ratePlanOriginal);
    this.ratePlan.SlotList = this.ratePlan?.RatePlanCalculations.map(slot => {
      
      slot.RatePlanVariants.locationAmount = _.sumBy(slot.RatePlanVariants.LocationVariant , 'Variant'); // + (slot.RatePlanVariants.LocationNegotiatedAmount || 0)
      slot.RatePlanVariants.instructorAmount = (slot.RatePlanVariants.InstructorVariant); // + (slot.RatePlanVariants.InstructorNegotiatedAmount || 0);

      slot.coverTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.GuestTypeVariant ? slot.RatePlanVariants.GuestTypeVariant.map(coverVariant => { 
              coverVariant.coverTypeValue =  _.cloneDeep(coverVariant.initialValue) 

         return coverVariant;
       }).filter(coverVariant => coverVariant.covers > 0) : [];
       
       slot.bookingTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.BookingTypeVariant ? slot.RatePlanVariants.BookingTypeVariant.map(bookingVariant => {
        bookingVariant.bookingTypeValue =  _.cloneDeep(bookingVariant.initialValue) 

        return bookingVariant;
         
       }).filter(bookingVariant => bookingVariant.bookingTypes > 0) : [];

       slot.AddonAttemptDetails = slot.AddonAttemptDetails ? slot.AddonAttemptDetails.map(addOnattempt => {
             addOnattempt.perItemValue =  _.cloneDeep(addOnattempt.TotalAmount / addOnattempt.Quantity)
         return addOnattempt;
       }) :[]
       slot.totalBaseRate = slot.RatePlanVariants ? (slot.RatePlanVariants.TotalBaseRate + slot.RatePlanVariants.TotalVariants + slot.RatePlanVariants.TotalNegotiation )/ this.partyService.sessionBookingData.BookingSize : 0;
      //  slot.totalSessionRetailCharge = slot.RatePlanVariants ? (slot.RatePlanVariants.TotalBaseRate + slot.RatePlanVariants.TotalVariants + slot.RatePlanVariants.TotalNegotiation ) : 0;
       if(slot && slot.AddonAttemptDetails && slot.AddonAttemptDetails.length){
        slot.totalAddOnAmount =  _.sumBy(slot.AddonAttemptDetails,'perItemValue');
       }  
      //  if(slot){
      //   slot.totalSessionRetailCharge =  (slot.coverTypeVariants && slot.coverTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['covers'] * o['coverTypeValue'] })  : slot.bookingTypeVariants && slot.bookingTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['bookingTypes'] * o['bookingTypeValue'] }) : (slot.totalBaseRate || 0))  ;    
      // }
       return slot;
     });

     this.onNegotiate();

  }

  openRatenegotiationpopup() {
    const componentDetails: ComponentDetails = {
      componentName: RateNegotiationComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: '',
      popupTitle: 'Reason for Negotiation',
    };
    let dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '260px',
      data: {
        title: 'Reason for Negotiation',
        update: 'Proceed',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.RateNegotitaion,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    this.ps.restrictCloseDialog = true;
    this.ps.isRestrictCloseDialog = true;

    let confirmedAction = this.popupService.confirmedAction$.subscribe(val => {
      // if (val === ComponentTypes.RateNegotitaion) {
      //   if ((this.partyService.rateNegotiationObject.Payable / 1) < this.totalDepositAmount) {
      //     this.showWarningPopup(this.translateService.instant('depositAlertMessage'), ComponentTypes.AlertNegotiation, dialogRef, ComponentTypes.RateNegotitaion);
      //   }
      //   else if (this.negotiationCutOff + ((this.partyService.rateNegotiationObject.Payable / 1) - this.ratePlan.TotalRatePlan) < 0) {
      //     //let message = this.translateService.instant('negotiationAlertMessage',{amount: (this.negotiationCutOff.toString()});
      //     //let amount = 
      //     this.showWarningPopup(this.translateService.instant('negotiationAlertMessage', { amount: (this.cs.settings.value.General.OperationCurrency + this.negotiationCutOff.toString()) }), ComponentTypes.AlertWrongNegotiation, dialogRef, ComponentTypes.RateNegotitaion);
      //   }
      //   else {
      //     this.ps.restrictCloseDialog = false;
      //     this.ps.isRestrictCloseDialog = false;
           dialogRef.close(ComponentTypes.RateNegotitaion);
      //   }
      // }
    });

    let cancelledActionSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.RateNegotitaion) {
        this.ps.restrictCloseDialog = false;
        this.ps.isRestrictCloseDialog = false;
        dialogRef.close();
      }
    });

    let reasonforNegotiationpopupconfirmaction = dialogRef.afterClosed().subscribe(val => {
      if (confirmedAction) {
        confirmedAction.unsubscribe();
      }

      if (cancelledActionSubscription) {
        cancelledActionSubscription.unsubscribe();
      }

      if (val === ComponentTypes.RateNegotitaion) {
        this.totalPayableAmount = this.partyService.rateNegotiationObject.Payable / 1;
        this.remainingAmount = this.partyService.rateNegotiationObject.Payable - this.ratePlan.TotalRatePlan;
        this.negotiationReason = this.partyService.rateNegotiationObject.Reason;
        this.partyService.sessionBookingDataSaveObj.NegotiatedAmount = this.remainingAmount;
        this.partyService.sessionBookingDataSaveObj.Comments = this.partyService.rateNegotiationObject.Reason;
        if (this.ratePlan && this.partyService.isfrombuynow && (this.partyService.sessionBookingDataSaveObj.TotalPayable != this.totalPayableAmount)) {
          this.partyService.isfromBuyNowAmountNegotiated = true;
        }

        this.partyService.sessionBookingDataSaveObj.TotalPayable = this.totalPayableAmount;
      //  this.calculateNegotiableRate();
        if (this.partyService.isfrombuynow && this.partyService.isfromBuyNowAmountNegotiated) {
          this.partyService.proceedCard(this.partyService.isfromBuyNowCartId);
        }
      }
    });


  }

  calculateNegotiableRate() {
    let i = 0;
    let total = 0;
    let negotiationAmountOfSlots = 0;
    let taxableItems = [];
    if (this.partyService.sessionBookingDataSaveObj.Sessions.SessionGroupId) {
      this.ratePlan.SlotList.forEach(s => s.index = 0);
      let addonslist = this.ratePlan.SlotList[0].AddonAttemptDetails;
      let taxItem = new TaxableItem();
      taxItem.ActivityId = this.partyService.sessionBookingDataSaveObj.SpecialMealId;
      taxItem.Index = 0;
      taxItem.StartDate = this.partyService.sessionBookingDataSaveObj.StartDate;
      taxItem.EndDate = this.partyService.sessionBookingDataSaveObj.EndDate;
      taxItem.RatePlanAmount = this.totalPayableAmount;
      taxableItems.push(taxItem)
    } else {
      let sessionsDate = this.partyService.sessionBookingDataSaveObj.Sessions.Sessions;
      let selectedSessions = sessionsDate.map(s => s.sessions).flat();
      if (selectedSessions && selectedSessions.length) {
        selectedSessions.map(slot => {
          if (slot.NegotiatedAmount) {
            slot.NegotiatedAmount = 0;
            slot.selectedAddOn = [];
          }
        });
      }

      let pastSessions = this.ratePlan.SlotList.filter(s => s.sessionState);
      let ratePlanTotal = this.perItemEditable ? _.sumBy(this.ratePlan.SlotList, 'totalAmount') : this.ratePlan.TotalRatePlan
      let totalpayable = _.sumBy(this.ratePlan.SlotList, 'TotalAddOnAmount') + (this.perItemEditable ?  _.sumBy(this.ratePlan.SlotList, 'totalAmount'): _.sumBy(this.ratePlan.SlotList, 'TotalAmount')); 

      if (pastSessions && pastSessions.length) {
        let pastSessionTotal = pastSessions.map(r => r.TotalAmount).reduce((sum, current) => sum + (current || 0), 0);
        let pastSessionNegotiation = pastSessions.map(r => r.sessionNegotiation).reduce((sum, current) => sum + (current || 0), 0);
        ratePlanTotal = this.ratePlan.TotalRatePlan - pastSessionTotal;
        totalpayable = (totalpayable - (pastSessionTotal + pastSessionNegotiation)); // total payable of current sessions
      }
      ;
      selectedSessions.forEach(slot => {
        slot.index = i;
        var totalRatePlanAmountForSlot = this.ratePlan.SlotList.find(slt => slt.TimeRange?.Start === slot.Start);
        if (totalRatePlanAmountForSlot && totalRatePlanAmountForSlot.TotalAmount) {
          totalRatePlanAmountForSlot.index = slot.index;
          if (i < selectedSessions.length - 1) {
            slot.NegotiatedAmount = this.getNegotiatedAmount(totalpayable, ratePlanTotal, totalRatePlanAmountForSlot.totalBaseRate, total, false);

            slot.NegotiatedAmount -= totalRatePlanAmountForSlot.TotalAmount;
            slot.NegotiatedAmount = Number(slot.NegotiatedAmount.toFixed(2));
            total += Number(slot.NegotiatedAmount);
          }
          else {
            slot.NegotiatedAmount = this.getNegotiatedAmount(totalpayable, ratePlanTotal, totalRatePlanAmountForSlot.totalBaseRate, total, true);

          }
          slot.NegotiatedAmount = Number(slot.NegotiatedAmount);
          totalRatePlanAmountForSlot.AddonAttemptDetails.forEach(addOnItem => {
          let taxItem = new TaxableItem();
        //  taxItem.ActivityId = this.partyService.sessionBookingDataSaveObj.SpecialMealId;
          taxItem.Index = addOnItem.index;
          taxItem.Quantity = addOnItem.Quantity
        //  taxItem.StartDate = this.partyService.sessionBookingDataSaveObj.StartDate;
      //    taxItem.EndDate = this.partyService.sessionBookingDataSaveObj.EndDate;
            taxItem.RetailItemId = this._settings.Addons.find(addOn => addOn.AddonId == addOnItem.AddonId)?.RetailItemId;
          taxItem.AddonId = addOnItem.AddonId;
         taxItem.RetailExternalPOSItemId = null;
         taxItem.AddonAmount = (addOnItem.TotalAmount + (addOnItem.NegotiatedAmount || 0))/addOnItem.Quantity;
          taxableItems.push(taxItem);
          });
          let taxItem = new TaxableItem();
          taxItem.ActivityId = this.partyService.sessionBookingDataSaveObj.SpecialMealId;
          taxItem.Index = slot.index;
          taxItem.StartDate = this.partyService.sessionBookingDataSaveObj.StartDate;
          taxItem.EndDate = this.partyService.sessionBookingDataSaveObj.EndDate;
          taxItem.RatePlanAmount = totalRatePlanAmountForSlot.totalBaseRate + (slot.NegotiatedAmount || 0);
          taxableItems.push(taxItem);
        }
        i++;
      });
    }
//    this.calculateTax(taxableItems);
  }

  getNegotiatedAmount(totalpayable, partyTotalAmount, itemTotal, forNowNegotiated, isLast) {
    if (!isLast) {
      return ((totalpayable != 0) ? ((totalpayable) / partyTotalAmount) * itemTotal : 0);
    }
    else {
      return -((Number((partyTotalAmount - totalpayable).toFixed(2))) + forNowNegotiated)
    }
  }



  calculateTax(taxableItems) {

    this.api.GetTaxCalculated(taxableItems).subscribe(data => {
      let newTax = 0;let serviceCharge =0;let serviceChargeWithTax = 0;
      if (data && data.Payload) {
        var taxresponse = data.Payload as TaxableItem[];
        this.ratePlan.SlotList.forEach(slot => {
          slot.TaxAmount = (taxresponse.find(t => t.Index == slot.index)?.TaxAmount || 0);
          slot.ServiceChargeAmount = (taxresponse.find(t => t.Index == slot.index)?.ServiceChargeAmount || 0);
          newTax = newTax + slot.TaxAmount;
          serviceCharge = serviceCharge + slot.ServiceChargeAmount;
          serviceChargeWithTax = serviceChargeWithTax + slot.serviceChargeWithTax;
        });
        this
        this.ratePlan.TotalTaxAmount = this.taxAmount = (newTax + this.uneditableTaxAmount);
        this.ratePlan.TotalServiceChargeAmount = this.totalServiceCharge = (serviceCharge);
        this.ratePlan.TotalTaxOnServiceChargeAmount = this.totalServiceChargeWithTax = (serviceChargeWithTax);
      }
    })

  }

  showActivityRatePlanInfo() {
    let message = this.translateService.instant('noRatePlanApplicableForBooking');
    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: 'Rate Plan Info', showAlert: true
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: this.translateService.instant('alert')
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: 'auto',
      data: {
        showAction: true,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.ActivityRatePlanInfo
      }
    });
  }
  ShowRestPolicyDetailsClick() {
    this.ShowRestPolicyDetails(this.restaurantPolicy);
  }
  bindGuestPartyNotes() {
    this.config = [];
    this.partyNotes.forEach((partyNote, index) => {
      const tag = {
        type: 'chip',
        name: `preferredPartyTags${index}`,
        inputType: 'text',
        label: partyNote.CategoryName,
        class: 'guest-view__preferred-tags-edit', // Do not modify the className, there is a dependency for editGuest details.
        options: this.getOptions(partyNote.partyNotes, partyNote.Color),
        multipleChip: true
      }
      this.config.push(tag);
    })
    if (get(this.data, ['Notes'], []).length) {
      this.customPartyNotes = this.data.Notes.filter(note => !note.RelatedId && note.Type == PartyNoteType.FreeFormNote);
      if (this.customPartyNotes && this.customPartyNotes.length) {
        const tag = {
          type: 'chip',
          name: `partyfreeTags`,
          inputType: 'text',
          label: 'freeNoteText',
          class: 'guest-view__free-tags-edit',
          options: this.getFreeTextOptions(),
          multipleChip: true
        }
        this.config.unshift(tag);
      }
    }

  }

  getFreeTextOptions() {
    let freeTextOptions = [];
    let freeNotes = this.data.Notes.filter(notes => !notes.RelatedId);
    freeNotes.forEach((notes) => {
      freeTextOptions.push({
        Id: notes.Id,
        Name: notes.Text,
        Icon: 'None',
        Value: ''
      })
    })
    return freeTextOptions;
  }

  getOptions(partyNotes, categoryColor): any {
    let configOptios = [];
    partyNotes.forEach((notes) => {
      configOptios.push({
        Id: notes.Id,
        Name: notes.Text,
        Icon: notes.Icon,
        Value: '',
        color: categoryColor
      });
    })
    return configOptios;
  }

  formChipsChange() {
    this.subscriptions.add(this.formChipService.formChipChange$.subscribe((selectedChips) => {
      if (selectedChips.name === 'partyfreeTags') {
        selectedChips.data.forEach((tags) => {
          if (!tags.setSelected) {
            const index = this.partyService.partyNotes.findIndex((note) => note.Id === tags.Id);
            if (index > -1) {
              this.partyService.partyNotes = this.partyService.partyNotes.filter(note => note.Id != tags.Id);
            }
          } else {
            const index = this.partyService.partyNotes.findIndex((note) => note.Id === tags.Id);
            if (index == -1) {
              const freeTextNotes: PartyNoteDTO = {} as PartyNoteDTO;
              freeTextNotes.Id = tags.Id;
              freeTextNotes.Text = tags.Name;
              freeTextNotes.Type = PartyNoteType.FreeFormNote;
              this.partyService.partyNotes.push(freeTextNotes);
            }
          }
        });
      } else if (this.config.filter((fieldConfig) => fieldConfig.name == selectedChips.name).length > 0) {
        const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
        this.selectedPartyPreferenceTags = selectedChips.data;
        const preferredTagsIndex = this.config.findIndex(fieldConfig => fieldConfig.name == selectedChips.name);
        const otherTags = selectedChips.data.filter(chip => chip.Name == 'Others');
        if (otherTags && otherTags.length > 0) {
          let otherNotesName = `otherNotes${otherTags[0].Id}`;
          let isOtherNoteAvailable = this.config.filter((config) => config.name == otherNotesName).length;
          const otherNotesControl = this.components.last.config.filter((config) => config.name == otherNotesName);
          if (!isOtherNoteAvailable && otherTags.length > 0 && otherTags[0].setSelected) {
            const otherNotes: FieldConfig = {
              name: otherNotesName,
              type: 'textarea',
              inputType: 'text',
              label: 'notes',
              showHint: true,
              class: 'activity-booking-summary__party-other-tags',
              charLength: 1000,
              isHidden: false
            }
            this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
          } else if (isOtherNoteAvailable) {
            if (otherTags[0].setSelected) {
              otherNotesControl[0].isHidden = false;
              const otherNotes = this.partyService.partyNotes.find((note) => note.RelatedId == otherTags[0].Id);
              if (!otherNotes) {
                const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
                let editPartyNote = null;
                if (this.data) {
                  editPartyNote = this.data.Notes.find(note => note.RelatedId == otherTags[0].Id);
                  if (editPartyNote) {
                    this.partyService.partyNotes.push(editPartyNote);
                  }
                } else {
                  partyNotes.Id = null;
                  partyNotes.Type = PartyNoteType.FreeFormNote;
                  partyNotes.RelatedId = otherTags[0].Id;
                  partyNotes.Text = this.components.last.form.value[otherNotesName];
                  this.partyService.partyNotes.push(partyNotes);
                }
              }
              // this.partyService.partyNotes = this.partyService.partyNotes.filter((note) => note.RelatedId != otherTags[0].Id)
            } else {
              otherNotesControl[0].isHidden = true;
              this.partyService.partyNotes = this.partyService.partyNotes.filter((note) => note.RelatedId != otherTags[0].Id)
            }
            // this.config.splice(preferredTagsIndex + 1, 1);

          }
        }
        this.config = [...this.config];
        this.selectedPartyPreferenceTags.forEach((_partyNotes) => {
          if (_partyNotes.Name !== 'Others') {
            const preferredData = this.partyService.partyNotes.filter((note) => note.RelatedId === _partyNotes.Id)[0];
            if (_partyNotes.setSelected && !preferredData) {
              partyNotes.RelatedId = _partyNotes.Id;
              partyNotes.Text = _partyNotes.Name;
              partyNotes.Id = _partyNotes.noteId ? _partyNotes.noteId : null;
              partyNotes.Type = PartyNoteType.PredefinedNote;
              this.partyService.partyNotes.push(partyNotes);
            } else if (!_partyNotes.setSelected) {
              const index = this.partyService.partyNotes.findIndex((note) => note.RelatedId === _partyNotes.Id);
              if (index > -1) {
                this.partyService.partyNotes.splice(index, 1);
              }
            }
          }
        });
      }
    }));
  }


  bindInternalPartyNotes(data: any) {
    for (let preferenceProperty in data) {
      if (preferenceProperty.includes('otherNotes')) {
        const otherNotesInConfig = this.config.find(configProp => configProp.name == preferenceProperty);
        if (otherNotesInConfig && !otherNotesInConfig.isHidden) {
          const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
          partyNotes.Text = data[preferenceProperty];
          const preferencePropertyId = preferenceProperty.match(/(\d+)/);
          if (preferencePropertyId) {
            partyNotes.RelatedId = Number(preferencePropertyId[0]);
            const preferredOtherTags = this.partyService.partyNotes.filter((note) => note.RelatedId === partyNotes.RelatedId);
            if (preferredOtherTags && preferredOtherTags.length > 0) {
              partyNotes.Id = preferredOtherTags[0].Id ? preferredOtherTags[0].Id : null;
              partyNotes.Type = PartyNoteType.FreeFormNote;
            } else {
              partyNotes.Id = null;
              partyNotes.Type = PartyNoteType.FreeFormNote;
            }
          }
          this.partyService.partyNotes = this.partyService.partyNotes.filter(note => note.RelatedId != partyNotes.RelatedId);
          this.partyService.partyNotes.push({ ...partyNotes });
        }
      }
    }
    //this.changeFormState(true)
    // if (!this.holdFormValidation) {
    //   this.partyService.reservationFormGroup.markAsDirty();
    //   this.partyService.reservationFormGroup.updateValueAndValidity();
    // }
    // this.updateFormValidity();
  }



  mapPartyNotes() {
    const preferredTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('preferredPartyTags'));
    this.data.Notes.forEach((note) => {
      preferredTags.forEach((fieldConfig) => {
        if (note.Type == PartyNoteType.FreeFormNote || note.Type == PartyNoteType.PredefinedNote || note.Type == PartyNoteType.SpecialRequest) {
          const filteredNotes = fieldConfig.options.find(data => data.Id === note.RelatedId);
          if (filteredNotes) {
            filteredNotes.setSelected = true;
            filteredNotes.noteId = note.Id;
            if (filteredNotes.Name.includes('Others')) {
              let otherNotesName = `otherNotes${filteredNotes.Id}`;
              if (filteredNotes.setSelected) {
                const otherNotes: FieldConfig = {
                  name: otherNotesName,
                  type: 'textarea',
                  inputType: 'text',
                  label: 'notes',
                  showHint: true,
                  class: 'activity-booking-summary__party-other-tags',
                  charLength: 1000,
                  value: note.Text
                };
                const preferredTagsIndex = this.config.findIndex(preferenceConfig => preferenceConfig.name == fieldConfig.name);
                this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
              }
            }
            this.partyService.partyNotes.push(note);
          }
        }
      })
    });
    this.config = [...this.config];
  }

  mapCustomPartyNotes() {
    const freeTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('partyfreeTags'));
    this.data.Notes.forEach(note => {
      freeTags.forEach(fieldConfig => {
        const filteredNotes = fieldConfig.options.find(data => data.Id === note.Id);
        if (filteredNotes) {
          filteredNotes.setSelected = true;
          this.partyService.partyNotes.push(note);
        }
      })
    });
  }


  checkForNumber(event) {
    const initalValue = event.key;
    let data = initalValue.replace(/[^0-9]*/g, '');
    if (initalValue !== data) {
      return '';
    }

  }

  calculateAmount(slot){
  //  cover.originalItemValue
    //this.negotiatedDiscount = this.negotiatedDiscount - 
 //   this.changeFormState(false);
 //   this.appliedNegotiation = true
    // if(slot && slot.AddonAttemptDetails && slot.AddonAttemptDetails.length){
    //   slot.totalAddOnAmount =  _.sumBy(slot.AddonAttemptDetails,item => Number(item['perItemValue']));
    //  }  
    //  if(slot){
    //   slot.totalSessionCharge =  (slot.coverTypeVariants && slot.coverTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['covers'] * o['coverTypeValue'] })  : slot.bookingTypeVariants && slot.bookingTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['bookingTypes'] * o['bookingTypeValue'] }) : (slot.totalBaseRate || 0))  ;
  
    // }
    // this.totalAmountForBooking = (_.sumBy(this.ratePlan.SlotList, 'totalAddOnAmount') +_.sumBy(this.ratePlan.SlotList, 'totalAmount'));
  }

  onBlur(eve){
  eve.target.value = Number(eve.target.value).toFixed(2);
  }
}



@Directive({
  selector: 'numbersOnly'
})
export class NumberDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('div', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}


@Pipe({
  name: 'getAddonAmount'
})
export class GetAddonAmountPipe implements PipeTransform {

  transform(slot: any): any {
   if(slot && slot.AddonAttemptDetails && slot.AddonAttemptDetails.length){
    return _.sumBy(slot.AddonAttemptDetails,'perItemValue')
   }  
  }
}

