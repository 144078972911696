import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AgDateConfig } from '../../Models/ag-models';
import { MatDialog } from '@angular/material/dialog';
import { AgMenuEditComponent } from '../ag-menu-edit/ag-menu-edit.component';
import { Localization } from '../../localization/localization';

@Component({
  selector: 'app-ag-date-picker',
  templateUrl: './ag-date-picker.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AgDatePickerComponent implements OnInit {
  className: string;
  errorMessage: string;
  minDateErrorMessage : string;
  form: UntypedFormGroup;
  formControlName: string;
  hidden = false;
  maxDate: string | Date;
  minDate: string | Date;
  placeHolder: string;
    value: string | Date;
    placeHolderId: string;
    errorMessageId: string;
    automationId: string;
  captions = {
    invalid: 'Invalid'
  };
  isDateRequired: boolean;
  @Output() datePickerChange = new EventEmitter();
  @Output() inputDateChange = new EventEmitter();
  isDisabled: boolean;
  placeHolderFormat: string;
  @Input() customPlaceholderLabel;
  captionsLocalization:any;
  floatLabel: string;
  iconAutomationId;
  @Input('inputs')
  set inputOptions(value: AgDateConfig) {
    if(value){
      this.floatLabel = this.localization.setFloatLabel? this.localization.setFloatLabel: '';
      this.className = value.className;
      this.errorMessage = value.errorMessage;
      this.form = value.form;
      this.formControlName = value.formControlName;
      this.hidden = value.hidden ? value.hidden : false;
      this.maxDate = value.maxDate;
      this.minDate = value.minDate;
      this.placeHolder = value.placeHolder;
      this.value = value.value;
      this.isDateRequired = value.isDateRequired ? value.isDateRequired : false;
      this.isDisabled = value.isDisabled ? value.isDisabled : false;
        this.minDateErrorMessage = value.minDateErrorMessage ? value.minDateErrorMessage : `${this.captions.invalid} ${this.placeHolder}`;
        this.errorMessageId = value.errorMessageId;
        this.placeHolderId = value.placeHolderId;
        this.automationId = value.automationId ? value.automationId : '';
        this.iconAutomationId = value.automationId ? 'Icn'+value.automationId : '';
    }
  }
    constructor(public dialog: MatDialog, private localization:Localization) {
      this.captionsLocalization = this.localization.captions.common;
    }

  ngOnInit() {
    this.placeHolderFormat = this.customPlaceholderLabel ? this.customPlaceholderLabel : this.localization.inputDateFormat;
  }

  dateChanged(event) {
    this.datePickerChange.emit([this.form, this.formControlName]);
  }
  inputDateChanged(event)
  {
    this.inputDateChange.emit([this.form, this.formControlName]);
  }

    onControlClick() {
        if (document.designMode == 'on') {
           this.dialog.open(AgMenuEditComponent, {
                width: '700px',
                height: '700px',
                data: {
                    oldPlaceHolder: this.placeHolder,
                    oldErrorMessage: this.errorMessage,
                },
                disableClose: true
            }).afterClosed().subscribe(result => {
                if (result) {
                    this.placeHolder = result.newplaceholder;
                    this.errorMessage = result.newErrorMessage;
                }
            });
        }
    }
}
