import { Component, OnInit } from '@angular/core';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cancel-reason',
  templateUrl: './cancel-reason.component.html',
  styleUrls: ['./cancel-reason.component.scss']
})
export class CancelReasonComponent implements OnInit {
  config: FieldConfig[];
  constructor(public translateService: TranslateService) { }

  ngOnInit() {
    this.config = [
      {
        type: 'select',
        label: this.translateService.instant('cancellationReasonText'),
        name: 'language',
        placeholder: this.translateService.instant('cancellationReasonText'),
        options: [{ id: 1, value: this.translateService.instant('noShow') }],
        class: 'login__language-selection',
        showErrorText: true,
        isTranslate: false
      }
    ];
  }

}
