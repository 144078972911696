import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { FloorPlanImagesDto } from '@app/shared/models/FloorPlanImageInputDto';
import { LoadRestaurantOptions } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { ChangeDTO, PayloadOperationResultDTO } from '@models/ChangeTrackingOperationResultDTO';
import { DataRetentionPolicy, LayoutDTO, RestaurantDTO, RestaurantNoteDTO, ScheduleDTO, ServerDTO, SettingsDTO, SpecialMealDTO, SpecialMealsDTO, StandaloneTableDTO, StateDTO } from '@models/RestaurantDTO';
import { AllowedSettingsPublish } from '@models/SignalRDto';
import { HttpService } from '@services/http.service';
import { Utilities } from '@utilities/utilities';
import { BehaviorSubject, EMPTY, Observable, Subject, from } from 'rxjs';
import { catchError, map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import _ from 'lodash';
import { PackageDTO } from '@app/shared/models/PackageDTO';
import { RouteLoaderService } from './route-loader.service';
import { PropertyFeaturesConfigurationService } from '@app/retail/sytem-config/payment-features-config/property-feature-config.service';
import { RetailPropertyInformation } from './retail-property-information.service';
import { adjust, RGBToHex } from '@app/activities-timeline/pipes/activity-color.pipe';
import { fabric } from 'fabric';
import { RetailLoginService } from '@app/shared/services/retail-login.service';
import { ScreenType } from '@app/shared/constants/globalConstants';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CacheService implements CanLoad {

  private settings$: Observable<PayloadOperationResultDTO>;
  public settingsRestored$: Observable<boolean>;
  public settings: BehaviorSubject<SettingsDTO> = new BehaviorSubject<SettingsDTO>(null);
  public state: BehaviorSubject<StateDTO> = new BehaviorSubject<StateDTO>(null);
  public layout: BehaviorSubject<LayoutDTO> = new BehaviorSubject<LayoutDTO>(null);
  public restaurantImages: BehaviorSubject<FloorPlanImagesDto> = new BehaviorSubject<FloorPlanImagesDto>(null);
  public dataRetentionPolicy: BehaviorSubject<DataRetentionPolicy[]> = new BehaviorSubject<DataRetentionPolicy[]>(null);
  public changes: BehaviorSubject<ChangeDTO[]> = new BehaviorSubject<ChangeDTO[]>(null);
  private state$: Observable<PayloadOperationResultDTO>;
  public layout$: Observable<PayloadOperationResultDTO>;
  private dataRetentionPalicy$: Observable<PayloadOperationResultDTO>;
  public settingsOperationName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public isCustomFloorplan: boolean; // need to change the custom Floorplan ca
  public signalRMessageCount: number = 0;
  public settingsCount: number = 0;
  public changesCount: number = 0;
  public activitiesData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public headerDate = new Date();
  public roles_OperationType = new BehaviorSubject<string>(null);
  public propertySettings: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public restuarantNote = new RestaurantNoteDTO();
  public operationCurrency: string;
  specialMealListForMerchant: SpecialMealDTO[] = [];
  instructorListForMerchant: any[] = [];
  locationListForMerchant: any[] = [];
  refreshCart$: Subject<any> = new Subject<any>();
  refreshServerStatistics$: Subject<any> = new Subject<any>();
  igIntegrateDataRefresh$: Subject<any> = new Subject<any>();
  VCartItineraryUpdated$: Subject<any> = new Subject<any>();
  VCartUpdatedData:any;
  isSilentLogin = false;
  IGServerCallInOpenCheck: boolean = false;
  IGServerCallInMoveCheck: boolean = false;
  refreshPackages: Subject<any> = new Subject<any>();
  availablePackages: PackageDTO[] = [];
  categoryData: any
  vipIcon: any = '';
  linkIcon: any = '';
  partyNotesWithoutSpecialMealIcon: any = '';
  favIcon: any = '';
  blockIcon: any = '';
  specialMealIcon:any = '';
  TableselectIcon: any = '';
  iconsIntialized: boolean = false;
  slotFullScreen: boolean = false;
  currentShiftId: number = 0;
  LoadMultiplePropertiesServiceInit = false;
  languageConstants: any = [];
  isIframeEnabled:boolean = false;
  propertyChangeEmitter$ :  Subject<any> = new Subject<any>();
  constructor(public http: HttpClient, public _hs: HttpService, private rs: RouteLoaderService, private pf: PropertyFeaturesConfigurationService, private rp: RetailPropertyInformation, private rls: RetailLoginService) {
    if (!this.iconsIntialized) {
      this.loadIcons();
    }
  }

  canLoad(route: import("@angular/router").Route, segments: import("@angular/router").UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return this.restoreSettings(LoadRestaurantOptions.Full);
  }
  public resetSignalRCount() {
    this.signalRMessageCount = 0;
  }

  public loadSettingsInStorage(_settings: SettingsDTO, propertyId: number) {
    localStorage.setItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${propertyId}_restaurantSettings`, JSON.stringify(_settings));
    if (propertyId == Utilities.RestaurantId()) {
      if (_settings?.General?.OperationCurrency) {
        this.operationCurrency = _settings?.General?.OperationCurrency;
      }
      this.settings.next(_settings);
    }
    this.propertySettings.value[propertyId].settings = _settings;
    this.loadCategoryData();

    if (_settings.SpecialMeals) {
      //  this.specialMealListForMerchant.push(..._settings.SpecialMeals);
      _settings.SpecialMeals.forEach(meal => {
        let dataIndex = this.specialMealListForMerchant.findIndex(specialMealData => specialMealData.Id == meal.Id);
        if (dataIndex > -1) {
          this.specialMealListForMerchant[dataIndex] = meal;
        }
        else {
          this.specialMealListForMerchant.push(meal)
        }
      });
      this.specialMealListForMerchant = _.uniqBy(this.specialMealListForMerchant, 'Id');
    }
  }

  loadCategoryData() {
    this.categoryData = {};

    this.settings.value.Categories.forEach(category => {
      this.categoryData[category.Id] = {
        id: category.Id,
        name: category.Text,
        type: category.CategoryType,
        visible: category.IsVisible,
        style: {}
      }
      if (category.Color && category.Color.R) {
        let hexColor = RGBToHex(category.Color.R, category.Color.G, category.Color.B);
        // let color, bgColor;
        // if((category.Color.R + category.Color.G + category.Color.B) < 350){
        // bgColor = `${adjust(hexColor, 220)}`;
        // color = hexColor;
        // }else{
        //   bgColor = hexColor;
        //   color = `${adjust(hexColor, -220)}`;
        // }
        this.categoryData[category.Id].style = { backgroundColor: hexColor };
      }
    })
  }

  // Get Settings from server | HTTP GET
  loadSettings(loadRestaurantOptions: LoadRestaurantOptions): Observable<PayloadOperationResultDTO> {
    return this._hs.get(urlConfig.loadRestaurantUrl + Utilities.RestaurantId() + '&languageId=' +  sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`) +'&options=' + loadRestaurantOptions).pipe(
      map(data => data as PayloadOperationResultDTO),
      publishReplay(1), // this tells Rx to cache the latest emitted
      refCount(), // and this tells Rx to keep the Observable alive as long as there are any Subscribers
      catchError(err => {
        this.settings$ = null;
        return EMPTY;
      })
    );

  }

  // localizeSettings(restaurantId, settings) {
  //   return this._hs.post(urlConfig.localizeSettingsUrl + '&restaurantId=' + restaurantId + '&languageId=' +  sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`), settings, false, restaurantId);
  // }

  restoreSettingsAndFillCache(loadRestaurantOptions: LoadRestaurantOptions): Observable<boolean> {
    let loadSettingsUrl = urlConfig.loadRestaurantUrl + Utilities.RestaurantId() + '&languageId=' + sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`) + '&options=' + loadRestaurantOptions;
    return this._hs.get(loadSettingsUrl).pipe(
      map((data: PayloadOperationResultDTO) => {
        console.log("file.cache.service [restoreSettingsAndFillCache]  Http Response Pipe Start: data", data);
        if (data.State == 0) {
          let _restaurantSettings: RestaurantDTO = data.Payload as RestaurantDTO;
          if (_restaurantSettings?.Settings?.General?.OperationCurrency) {
            this.operationCurrency = _restaurantSettings?.Settings?.General?.OperationCurrency;
          }
          console.log("file.cache.service [restoreSettingsAndFillCache]  before Settings emit");
          this.settings.next(_restaurantSettings.Settings);
          console.log("file.cache.service [restoreSettingsAndFillCache]  before loadCategoryData");
          this.loadCategoryData();
          console.log("file.cache.service [restoreSettingsAndFillCache]  after loadCategoryData");
          localStorage.setItem(`${sessionStorage.getItem('sessionGUID')}_${Utilities.RestaurantId()}_restaurantSettings`, JSON.stringify(_restaurantSettings.Settings));
          console.log("file.cache.service [restoreSettingsAndFillCache]  after localstorage set: ");
          sessionStorage.setItem('ShowAliasName', JSON.stringify(_restaurantSettings.ShowAliasName));
          sessionStorage.setItem(`restaurantName${Utilities.getSessionStorageType()}` , _restaurantSettings.Settings.General.RestaurantName);
          this.layout.next(_restaurantSettings.Layout);
          this.state.next(_restaurantSettings.State);
          Utilities.setLatestRevision(_restaurantSettings.State.Revision);
          console.log("file.cache.service [restoreSettingsAndFillCache]  after setLatestRevision: ");

          this.dataRetentionPolicy.next(_restaurantSettings.DataRetentionPolicy);
          this.settingsRestored$ = new Observable(observer => {
            observer.next(true);
            observer.complete();
          });
          this.rs.retailMenus = false;
          console.log("file.cache.service [restoreSettingsAndFillCache] before isRetailEnabledPropertyWithSession: ");
          
          if (Utilities.isRetailEnabledPropertyWithSession(this.settings.value.General.RetailIntegrationDTO)) {
            this.rs.loadSettings().then(result => {
              if (result) {
                this.rs.retailMenus = true;
                const value = this.rs.GetChildMenu('/');
              }
              this.rls.OtherRetailInitialization();             
            });
          }
          return true;
        }
        return false;
      }),
      publishReplay(1), // this tells Rx to cache the latest emitted
      refCount(), // and this tells Rx to keep the Observable alive as long as there are any Subscribers
      catchError(err => {
        console.error("file.cache.service [restoreSettingsAndFillCache]  Catch", err);
        this.settings$ = null;
        return EMPTY;
      })
    );

  }

  restoreSettings(loadRestaurantOptions: LoadRestaurantOptions) {
    if (this.settingsRestored$) {
      return this.settingsRestored$;
    }
    this.getRestaurantImages();

    return this.restoreSettingsAndFillCache(loadRestaurantOptions).toPromise()
  }

  getSettings(loadRestaurantOptions: LoadRestaurantOptions) {
    switch (loadRestaurantOptions) {
      case LoadRestaurantOptions.Settings:
        this.settings$ = this.settings$ ? this.settings$ : this.loadSettings(loadRestaurantOptions);
        return this.settings$.toPromise();
      case LoadRestaurantOptions.State:
        this.state$ = this.state$ ? this.state$ : this.loadSettings(loadRestaurantOptions);
        return this.state$.toPromise();
      case LoadRestaurantOptions.Layout:
        this.layout$ = this.layout$ ? this.layout$ : this.loadSettings(loadRestaurantOptions);
        return this.layout$.toPromise();
      case LoadRestaurantOptions.DataRetentionPolicy:
        this.dataRetentionPalicy$ = this.dataRetentionPalicy$ ? this.dataRetentionPalicy$ : this.loadSettings(loadRestaurantOptions);
        return this.dataRetentionPalicy$.toPromise();
    }
  }

  getRestaurantImages() {
    this.http.get(urlConfig.getLayoutImagesURL + Utilities.RestaurantId()).subscribe((images: PayloadOperationResultDTO) => {
      const allAvailableImages = images.Payload as FloorPlanImagesDto;
      this.restaurantImages.next(allAvailableImages);
    });
  }

  async getRestaurantSettings() {
    const settingsResult: PayloadOperationResultDTO = (await this.getSettings(LoadRestaurantOptions.Settings));
    const settings: SettingsDTO = (settingsResult.Payload as RestaurantDTO).Settings;
    return settings;
  }
  async getRestaurantState() {
    const settingsResult: PayloadOperationResultDTO = (await this.getSettings(LoadRestaurantOptions.State));
    const state: StateDTO = (settingsResult.Payload as RestaurantDTO).State;
    return state;
  }
  async getRestaurantLayout() {
    const settingsResult: PayloadOperationResultDTO = (await this.getSettings(LoadRestaurantOptions.Layout));
    const layout: LayoutDTO = (settingsResult.Payload as RestaurantDTO).Layout;
    return layout;
  }
  async getRestaurantDataRetentionPolicy() {
    const settingsResult: PayloadOperationResultDTO = (await this.getSettings(LoadRestaurantOptions.DataRetentionPolicy));
    const policy: DataRetentionPolicy[] = (settingsResult.Payload as RestaurantDTO).DataRetentionPolicy;
    return policy;
  }

  // Clear configs
  clearSettings() {
    this.settings$ = null;
  }
  clearState() {
    this.state$ = null;
  }
  clearLayout() {
    this.layout$ = null;
  }

  getChanges(revision: number, screenName: string) {
    const changeURL = `${urlConfig.getChangeSetForScreenNameUrl}?restaurantId=${Utilities.RestaurantId()}&afterRevision=${revision}&screenName=${screenName}`;
    return this.http.get(changeURL);
  }

  HandleResponse(value) {
    this.igIntegrateDataRefresh$.next(value);
  }

  getLanguageConstants(merchantId, langId) {
    return this._hs.get(`${urlConfig.getLanguageConstants}?merchantId=${merchantId}&languageId=${langId}`)
  }

  get AllowEditForPastReservationsInDays(): number{
    return this.settings?.value?.PropertySetting[0]?.MinDaysToBookPastReservations || environment.AllowEditForPastReservationsInDays;
  }

  loadIcons() {
    const that = this;
    fabric.loadSVGFromURL('assets/images/vip.svg', function (objects, options) {
      that.vipIcon = fabric.util.groupSVGElements(objects, options);
    });
    fabric.loadSVGFromURL('assets/images/fav.svg', function (objects, options) {
      that.favIcon = fabric.util.groupSVGElements(objects, options);
    });
    fabric.loadSVGFromURL('assets/images/link.svg', function (objects, options) {
      that.linkIcon = fabric.util.groupSVGElements(objects, options);
    });
    fabric.loadSVGFromURL('assets/images/notes.svg', function (objects, options) {
      that.partyNotesWithoutSpecialMealIcon = fabric.util.groupSVGElements(objects, options);
    });

    fabric.loadSVGFromURL('assets/images/check.svg', function (objects, options) {
      that.TableselectIcon = fabric.util.groupSVGElements(objects, options);
    });
    fabric.loadSVGFromURL('assets/images/block.svg', function (objects, options) {
      that.blockIcon = fabric.util.groupSVGElements(objects, options);
    });
    fabric.loadSVGFromURL('assets/images/specialEvent.svg', function (objects, options) {
      that.specialMealIcon = fabric.util.groupSVGElements(objects, options);
    });
    this.iconsIntialized = true;
  }
}
