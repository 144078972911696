import { Component, OnInit, Input, ElementRef,  Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import * as myGlobals from '../../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import * as _ from 'lodash';
import { RetailLocalization } from '../../../common/localization/retail-localization';
import { UserAlerts } from '../../../common/config/alerts-config';
import { AlertMessagePopupComponent } from '../../../shared/alert-message-popup/alert-message-popup.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-retail-input-table',
  templateUrl: './retail-input-table.component.html',
  styleUrls: ['./retail-input-table.component.scss']
})
export class RetailInputTableComponent implements OnInit {
  @Input() options;
  @Input() isRoleSetUpReadOnly;
  @Input() EnableEdit: boolean [] = [];
  @Input() searchString: string;
  @Output() afterEditClose: EventEmitter<any> = new EventEmitter();
  @Output() dragDropEvt: EventEmitter<any> = new EventEmitter();
  @Output() deleteEvt: EventEmitter<any> = new EventEmitter();
  @Output() editEvt: EventEmitter<any> = new EventEmitter();
  @Output() InActiveTherapistEvt: EventEmitter<any> = new EventEmitter();
  @Output() RowSelectEmitter: EventEmitter<any> = new EventEmitter();
  @Output() userActionEvt: EventEmitter<any> = new EventEmitter();
  @Output() CurrentRowSelectedEmitter: EventEmitter<any> = new EventEmitter();
  @Output() toggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() addClick: EventEmitter<any> = new EventEmitter();
  @Output() dropDownChange: EventEmitter<any> = new EventEmitter();
  @Output() printEvt: EventEmitter<any> = new EventEmitter();
  @Output() inactiveToggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() doneEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() userEditing: EventEmitter<any> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<any> = new EventEmitter();
  @Output() LBLClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() radioClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openWaitlist: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('tableInput') tableInput: ElementRef;
  @Output() FieldEditEmitter: EventEmitter<any> = new EventEmitter();
  @Output() DeleteData: EventEmitter<any> = new EventEmitter();
  @Output() EdittedData: EventEmitter<any> = new EventEmitter();

  SelectedData: any = [];
  valueChanged= false;
  sortingColoumn: any;
  setinterscroll: any;
  editEvent: any;
  InActiveTherapistChkBoxEvt: any;
  orderTypearr: any = [];
  orderType: any = 'asc';
  hdrArray: any = [];
  bodyArray: any = [];
  selectedDefaultHeader: any;
  searchText: any;
  hdrkeyArray: any = [];
  originalHdrKeyArray: any = [];
  Sortable: any;
  highlight: any;
  NewerData: any;
  currentPage: any = 0;
  givenOrderType: any;
  EditMoreOption: any;
  defaulSortColumn: any;
  dropdownOptions: any = [];
  captions: any = {};
  disableDelete: any;
  toggleDisplayText: any;
  editableRow: any;
  tempTableData: any;
  editRecordsArray: any = [];
  editingData: any;

  SelectRow = false;
  enableToggleButton = false;
  IfBooleanCheck = false;
  SetColumnGridValue = false;
  CheckInnerBoolValue = false;
  enableRowCheck = false;
  IsViewOnly= false;
  IsCheckAll: boolean = false;
  EnableActions: boolean;
  EnablePagination = true;
  ChkInactiveService = false;
  blnDraggable = true;
  editable = true;
  waitlistIcon = false;
  customHeader = false;
  sticky = false;
  DoneCancel = false;
  viewCheckedFlag = false;
  IsRetailCodeSetup: boolean;
  disableEditButton: boolean; //this boolean will prevent certain screens to show Edit button during view only break point applied.
  isValidRoleName = false;
  isEdit: boolean;
  isReadOnly = false;
  isDataLoading = false;
  isCancelAvailable: boolean;

  SelectedSettingId = 1;
  inputLength: number;
  copyOfTableData: any = [];
  copyOfSearchData: any = [];
  copyOfData: any = [];
  PlaceHoldertext: string;
  overriddenSortColumn: string;
  sortColumnDataType: string;
  pageTitle: string;
  customHeaderButton: string;
  setupName: string;
  errorMessage: string;
  newObject: any;
  outlets: UntypedFormGroup;
  quicksale: UntypedFormGroup;
  measures: UntypedFormGroup;
  table: UntypedFormGroup;
  formGroupName: UntypedFormGroup;

  GridType = myGlobals.GridType;
  RetailTransactions = myGlobals.RetailTransactions;
  defaultMin = 1;
  defaultMax = 1;
  isEditModeSet= false;
  edittingIndex: any;
  _disableAction: boolean;
  maxLength = 5;
  pageSize = 10;
  dataSource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  totalSize = 0;
  end: number;
  start: number;
  @Input('disableActions')
  set disableActions(value) {
    this._disableAction = value;
  }
  constructor( public dialog: MatDialog, private userAlerts: UserAlerts, public el: ElementRef, public fb: UntypedFormBuilder, public localization: RetailLocalization, private _cdRef: ChangeDetectorRef) {
    this.table = this.fb.group({
      IsCheckAll : false,
      tablebody: this.fb.array([this.fb.group({})])
      });
      this.outlets = this.fb.group({
        setupCodeName: ['', Validators.required],
        activetoggle: [false]
      });
      this.measures = this.fb.group({
        setupCodeName: ['', Validators.required],
        activetoggle: [false]
      });
      this.quicksale = this.fb.group({
        setupCodeName: ['', Validators.required],
        activetoggle: [false]
      });
    this.captions = this.localization.captions;
    this.customHeaderButton = this.localization.captions.setting.Add;
    this.enableCancelButton();
  }

  ngOnChanges() {
    this.editRecordsArray = [];
    this.viewCheckedFlag = false;
    this.table.value.tablebody = this.CreateTablerowFormGroup(this.options);
    this.tempTableData = this.options;
    this.IsCheckAll = false;
    if (typeof this.options == 'undefined' || !this.options[0]) { this.isDataLoading = true; return; }
    this.isDataLoading = false;
    this.orderTypearr = [];
    this.defaulSortColumn = this.options[0] && this.options[0].Sortable;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = (this.selectedDefaultHeader && !this.options[0].isInitial) ? this.selectedDefaultHeader : temp;
    this.isReadOnly = this.options[0].IsReadOnly ? true : false;
    this.selectedDefaultHeader = this.Sortable;
    this.SelectRow = this.options[0].SelectRows;
    this.DoneCancel = this.options[0].DoneCancel;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    this.blnDraggable = this.options[0].TableDraggable;
    this.editable = this.options[0].Editable != undefined ? this.options[0].Editable : true;
    this.waitlistIcon = this.options[0].waitlistIcon != undefined ? this.options[0].waitlistIcon : false;
    this.SelectedSettingId = this.options[0].SelectedSettingId;
    this.sticky = this.options[0].sticky ? this.options[0].sticky : false;
    this.hdrArray = this.options[0].TableHdrData;
    this.originalHdrKeyArray = this.options[0].TableHdrData;
    this.bodyArray = [];
    this.bodyArray = this.options[0].TablebodyData;
    this.copyOfTableData = this.bodyArray;
    this.copyOfSearchData = this.bodyArray;
    if (this.searchString != null &&  this.searchString.length > 0) {
      this.searchString = this.searchString.toLowerCase();
      this.bodyArray = this.copyOfSearchData.filter(value => value.category_name.toLowerCase().includes(this.searchString));
      this.copyOfTableData = this.bodyArray;
    } else {
      this.bodyArray = this.copyOfSearchData;
      this.copyOfTableData = this.bodyArray;
    }
    this.SelectedData = [];
    //Set Checked items on load - based on the checked field in body data
    let index = 0;
    this.EnableEdit = [];
    this.bodyArray.forEach(element => {
      if (element.checked != null && element.checked) {
        this.SelectedData.push(element);
      }
      index++;
    });
    this.NewerData = this.options[0].NewData;
    this.NewerData = this.bodyArray.filter(o => {
      if (this.NewerData) {
        return ((o.code && o.code == this.NewerData.code) || (o.name && o.name == this.NewerData.name) || (o.addOnName && o.addOnName == this.NewerData.addOnName));
      }
    });
    if (this.NewerData[0]) {
      this.setinterscroll = setInterval(() => {
        const curiddata = this.NewerData ? (this.NewerData[0] ? this.NewerData[0].id : '') : '';
        this.autoscrolltocurrtime(curiddata);
      }, 500);

    }
    this.searchText = this.options[0].TableSearchText ? this.options[0].TableSearchText : this.searchText;
    this.hdrkeyArray = [];
    if (this.hdrArray) {
      for (let item of this.hdrArray) {
        if (item.searchable != undefined ? item.searchable : true) {
          this.hdrkeyArray.push(item.jsonkey);
        }
      }
    }
    this.PlaceHoldertext = this.options[0].PlaceHoldertext;
    this.EnableActions = this.options[0].EnableActions;
    this.disableDelete = this.options[0].disableDelete;
    this.EnablePagination = this.options[0].pagination;
    this.ChkInactiveService = this.options[0].InactiveService;
    this.EditMoreOption = this.options[0].EditMoreOption;
    this.customHeader = this.options[0].customHeader;
    this.pageTitle = this.options[0].pageTitle;
    this.setCodeSetupValues();
    this.dropdownOptions = this.options[0].dropdownOptions;

    // Load Only active services
    if (this.ChkInactiveService) {
      this.InactiveService({ checked: false });
    }
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    const overriddenSortColumn = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumn : null;
    const SortColumnDataType = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumndatatype : null;
    this.sortingFunc(this.selectedDefaultHeader, this.sortingColoumn, 'change', overriddenSortColumn, SortColumnDataType);
    this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    setTimeout(this.customtablealignment.bind(this), 1);
    this.IsViewOnly = this.options[0].IsViewOnly;
    this.disableEditButton = this.options[0].disableEditButton;
    this.IsRetailCodeSetup = (this.options[0].ServiceId == 'quicksale' || this.options[0].ServiceId == 'measures' || this.options[0].ServiceId == 'outlets' );
    this.calwidthwithtimeout();
  }

  ngOnInit() {
    if (typeof this.options == 'undefined' || !this.options[0]) { return; }

    this.defaulSortColumn = this.options[0].Sortable;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = this.selectedDefaultHeader ? this.selectedDefaultHeader : temp;
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    this.selectedDefaultHeader = this.Sortable;
    this.orderTypearr = [];
    this.inputLength = 250;
    this.getArray();
  }

  ngAfterViewInit() {
    this.calwidthwithtimeout();
    this._cdRef.detectChanges();
  }

  ngAfterViewChecked() {
    if (!this.viewCheckedFlag) {
      this.viewCheckedFlag = true;
      this.calwidthwithtimeout();
      this.customtablealignment();
    }
    this._cdRef.detectChanges();
  }

  sortingFunc(dh, i, from, overriddenSortColumn, sortColumnDataType) {
    if (!this.isEditModeSet) {
      if (this.editRecordsArray.length > 0) {
        const dialogRef = this.openAlert();
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'Yes') {
            this.Cancel(this.editRecordsArray[0].data, this.editRecordsArray[0].index);
            this.editRecordsArray = [];
            this.sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from);
          }
        });
      } else {
        this.sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from);
      }
    }
  }

  openAlert () {
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.captions.common.Warning,  headerIcon: 'icon-warning-icon',  headerMessage: this.captions.common.saveChangesMessage , buttonName: this.captions.common.Yes, noButton: true, noButtonName: this.captions.common.No, type: 'message'
      },
      disableClose: true,
    }); 
  }

  onDragOver(event, ele, newarr) {
    event.preventDefault();
    const newdata = [];
    const overdata = ele;
    const newdatindex = newarr.indexOf(newdata);
    const overindex = newarr.indexOf(overdata);
  }

  onItemDrop(event, ele, newarr) { 
    const draggeddata = event.dragData;
    const droppeddata = ele;
    const dragindex = newarr.indexOf(draggeddata);
    const dropindex = newarr.indexOf(droppeddata);
    const dragLstOrder: listOrder = draggeddata;
    const dropLstOrder: listOrder = droppeddata;
    this.dragDropEvt.emit([dragLstOrder.listOrder, dropLstOrder.listOrder, this.SelectedSettingId, this.InActiveTherapistChkBoxEvt]);
    newarr.splice(dragindex, 1);
    newarr.splice(dropindex, 0, draggeddata);
  }

  allowDrop(event, ele, newarr) {
    const draggeddata = event.dragData;
    const dragindex = newarr.indexOf(draggeddata);
    newarr.splice(dragindex, 1);
  }

  checkValid(event) {
    if (event && event.target && event.target.value.trim() !== '') {
    this.isValidRoleName = true;
    } else {
      this.isValidRoleName = false;
    }
  }

  searchInputValue(event) {
    this.searchText = event;
    this.viewCheckedFlag = false;
  }

  CreateTablerowFormGroup(optionsData) {
    const bodyArr: any = this.table.get('tablebody') as UntypedFormArray;
    bodyArr.controls = [];
    bodyArr.value = [];

    if (optionsData.length > 0) {
      for (let i = 0; i < (optionsData[0].TablebodyData && optionsData[0].TablebodyData.length); i++) {
        const optionsValue = optionsData[0].TablebodyData[i];       
        bodyArr.push(this.fb.group(
          optionsValue
        ));
        optionsData[0].TableHdrData.forEach((element) => {
          if (element.isRequired) {
            bodyArr.controls[i].controls[element.jsonkey].setValidators([Validators.required]);
          } else {
            bodyArr.controls[i].controls[element.jsonkey].setValidators([]);
          }
          if (element.type === 'number' && bodyArr.controls[i].controls[element.jsonkey].value === 0) {
            bodyArr.controls[i].controls[element.jsonkey].setValue(this.LoadDecimalValue(0));
          }
      });
      }
    }
    return bodyArr;
  }

  setMatformWidth(myElement) {
    if (this.tableInput) {
        let minWidth = myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0] ? myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0]['offsetWidth'] : 300; //min-300 max-470
        minWidth += myElement.getElementsByClassName('search-container')[0] ? myElement.getElementsByClassName('search-container')[0]['offsetWidth'] : 0;
        minWidth += myElement.getElementsByClassName('table-toggle-switches')[0] ? myElement.getElementsByClassName('table-toggle-switches')[0]['offsetWidth'] : 0;
        minWidth += myElement.getElementsByClassName('custom-retail-inputs')[0] ? myElement.getElementsByClassName('custom-retail-inputs')[0]['offsetWidth'] : 0;
        return minWidth;
    }
  }

  calculateWidth() {
    Array.from(document.querySelectorAll('#SPACustomTable>ng-scrollbar>.ng-scrollbar-container>.ng-scrollbar-view>table')).forEach((table, index) => {
      if (table) {
        const tableHeight = table['offsetHeight'];
        const parentHeight = table.closest('#fixed-table-container')['offsetHeight'];
        if (parentHeight > tableHeight) {
          table.closest("#SPACustomTable")['style']['height'] = tableHeight + 2 + 'px';
        }
        else if (parentHeight < tableHeight) {
          table.closest("#SPACustomTable")['style']['height'] = parentHeight + 'px';
             }
      }
    });
    const searchClass = document.getElementsByClassName('retail-CustomDataTable');
    for (let i = 0; i < searchClass.length; i++) {
      let pageHeader = searchClass[i].getElementsByClassName('page-header')[0] ? searchClass[i].getElementsByClassName('page-header')[0]['offsetWidth'] : 0;
      const searchInput = searchClass[i].getElementsByClassName('searchpt')[0];
      if (pageHeader > 0) {
        pageHeader = pageHeader - this.setMatformWidth(searchClass[i]) - 60;
      }
      const inputLength = this.tableInput ? this.tableInput.nativeElement.getAttribute('data-placeholder').length : 1;
      const inputWidth = inputLength <= 30 ? inputLength * 10 : inputLength * 7.5 + 20;
      if (searchInput && pageHeader > 0) {
        searchInput['style'].width = (pageHeader > inputWidth) ? inputWidth + 'px' : pageHeader + 'px';
      }
    }
  }

  setCodeSetupValues() {
    const retailsetup = this.captions.retailsetup;
    switch (this.pageTitle) {
      case 'outlets':
        this.assignSetupValues(this.outlets, retailsetup.OutletName, retailsetup.MissingOutletName);
        break;
      case 'quicksale':
        this.assignSetupValues(this.quicksale, retailsetup.QuickSaleCategory, retailsetup.MissingQuickSaleCategory);
        break;
      case 'measures':
        this.assignSetupValues(this.measures, retailsetup.UnitName, retailsetup.MissingUnitName);
        break;
    }
  }

  assignSetupValues (formName: UntypedFormGroup, setupName: string, errorMessage: string) {
    this.formGroupName = formName;
    this.setupName = setupName;
    this.errorMessage = errorMessage;
  }

  customtablealignment() {
    let dropdown: any = [];
    const groupArray: any = {
      'outlets': {
        'outletName': '25',
        'terminalId': '25',
        'active': '25',
        'outletNumber': '25'
      },
      'quicksale': {
        'checkbox': '0',
        'desc': '30',
        'itemid': '20',
        'temp': '10',
        'category': '15'
      },
      'retail': {
        'category': '10',
      },
      'inputTable':  {
        'chcekbox': '5',
        'Description': '20',
        'Item Number': '15',
        'Category': '15',
        'Unit of Measure': '15',
        'PRICE': '10',
        'BARCODE': '15',
        'sub-cat 1': '15',
        'sub-cat 2': '15',
        'sub-cat 3': '15',
        'sub-cat 4': '15',
        'sub-cat 5': '15',
        'outlet #': '15',
        'Other Bar Code': '15',
        'Commission Percent': '25',
        'Cost': '15',
        'Quantity': '15',
        'Outlet Switch': '20',
        'categoryId': '10',
        'subCategory1Id': '10',
        'unitOfMeasureId': '10',
        'id': '10',
        'errorMessage': '25'
      }
    };
    this.hdrArray.forEach(element => {
      groupArray.retail[element.jsonkey] = '10';
    });
      groupArray.retail['actions'] ='10';
    switch (this.SelectedSettingId) {
      case myGlobals.GridType.outlet:
        dropdown = (Object.values(groupArray.outlets));
        break;
      case myGlobals.GridType.retail:
        dropdown = (Object.values(groupArray.retail));
        break;
      case myGlobals.GridType.quickSale:
        dropdown = (Object.values(groupArray.quicksale));
        break;
      case myGlobals.GridType.inputEditTable:
        dropdown = (Object.values(groupArray.inputTable));
        break;
    }
    const coloumncount = document.getElementsByTagName('th').length;
    let overallgivenwidth = 0;
    let ga;
    if (dropdown) {
      const parentWidth = document.getElementById('SPACustomTable') && document.getElementById('SPACustomTable').offsetWidth;
      for (ga = 0; ga < dropdown.length; ga++) {
        if (document.getElementsByTagName('th')[ga]) {
          document.getElementsByTagName('th')[ga].style.width = Object.values(dropdown)[ga] + '%';
        }
        overallgivenwidth += Number(dropdown[ga]);
      }
      if (dropdown && this.bodyArray.length > 0) {
        const tablerow = document.getElementsByTagName('tr');
        for (let i = 1; i <= this.bodyArray.length; i++) {
          if (tablerow[i]) {
            for (let j = 0; j < dropdown.length; j++) {
              if (document.getElementsByTagName('tr')[i].cells[j]) {
                document.getElementsByTagName('tr')[i].cells[j].querySelectorAll('#content') && document.getElementsByTagName('tr')[i].cells[j].querySelectorAll('#content').length > 0 ? document.getElementsByTagName('tr')[i].cells[j].querySelectorAll('#content')[0]['style'].maxWidth = parentWidth * (Number(Object.values(dropdown)[j]) / 100) + 'px' : '';
              }
            }
          }
        }
      }
       
    }
  }

  setWidth(coloumncount, count, width, maxwidth) {
    document.getElementsByTagName('th')[coloumncount - count].style.width = width;
    document.getElementsByTagName('td')[coloumncount - count].style.maxWidth = maxwidth;
  }

  resetForm() {
    this.outlets.reset();
    this.measures.reset();
    this.quicksale.reset();
    this.customHeaderButton = this.localization.captions.setting.Add;
    this.enableCancelButton();
  }

  enableDoneCancel(index, rowData) {
    this.table.value.tablebody[index].donecancel = true;
    const data = { 'value': event, 'data': rowData };
    this.inactiveToggleEvtEmitter.emit(data);
    this.isValidRoleName = true;
  }

  Done(rowData, index) {
    this.editRecordsArray = [];
    this.enableToggleButton = false;
    this.editEvent = {};
     
    const editedData = this.copyOfTableData[index];

      this.EnableEdit[index] = false;
      this.doneEvtEmitter.emit([editedData, index]);
      this.valueChanged = false;
      this.changeEditMode(false);

  }

  lblclick(rowData, index, clickable) {
    if (clickable == 'clickable') {
      this.LBLClickEvtEmitter.emit(rowData);
      return;
    } else {
      return false;
    }
  }

  Cancel(rowData, index) {
    
    console.log('Temp Table data', this.tempTableData[0].TablebodyData.filter(x => x.id == rowData.id)[0]);
    this.bodyArray[index] = this.editingData;
    this.EnableEdit[index] = false;
    this.valueChanged = false;
    this.userEditing.emit(false);
    this.changeEditMode(false);
  }

  onButtonClick() {
    const belonTo = this.options[0].ServiceId;
    const type = this.customHeaderButton;
    this.editEvent = {};
    this.editRecordsArray = [];
    let data = {};
    switch (belonTo) {
      case 'outlets': data = { 'value': this.outlets, 'type': type };
        break;
      case 'measures': data = { 'value': this.measures, 'type': type };
        break;
      case 'quicksale': data = { 'value': this.quicksale, 'type': type };
        break;
      default: data = { 'value': '', 'type': type };
    }
    this.enableToggleButton = false;
    this.addClick.emit(data);
    this.resetForm();
  }

  onButtonCancelClick(event) {
    if (document.getElementsByClassName('rowDisabled').length > 0) {
      document.getElementsByClassName('rowDisabled')[0].classList.remove('highlight');
      document.getElementsByClassName('rowDisabled')[0].classList.remove('rowDisabled');
    }
    this.enableToggleButton = false;
    this.resetForm();
  }

  RowSelect(event, SelectedRow, Frm, index?) {
    if (Frm == 'All') {
      if (event.checked ) {
        this.SelectedData = [];
        for (let item of SelectedRow) {
          this.SelectedData.push(item);
        }
      } else {
        this.SelectedData = _.difference(this.SelectedData, this.bodyArray);
      }
    } else {
      if (this.SelectedData.indexOf(SelectedRow) == -1) {
        this.SelectedData.push(SelectedRow);
      } else {
        this.SelectedData.splice(this.SelectedData.indexOf(SelectedRow), 1);
        const checkedRow = this.bodyArray.findIndex(item => item.id == SelectedRow.id);
        this.bodyArray[checkedRow].checked = false;
      }
      this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    }

    let isAllRowValid=true;

    if(index==undefined && Frm=='All')
    {
      for (let l = 0; l < SelectedRow.length; l++) {
        if(!this.table.controls.tablebody['controls'][l].valid)
        isAllRowValid=false;
      }
    }
    const allSelectedDataValid = this.checkAllDataValid();

    if ((index >= 0 && allSelectedDataValid)||(index==undefined && isAllRowValid)) {
    this.RowSelectEmitter.emit(this.SelectedData);
    } else {
      this.RowSelectEmitter.emit([]);
    }
    this.CurrentRowSelectedEmitter.emit({ 'event': event, 'SelectedRow': SelectedRow, 'From': Frm });
  }

  InactiveService(e) {
    if (e.checked) {
      this.bodyArray = [];
      this.bodyArray = this.options[0].TablebodyData;
    } else {
      const tempArr = [];
      this.bodyArray.forEach(row => {
        if (row.isActive) {
          tempArr.push(row);
        }
      });
      this.bodyArray = tempArr;
    }
  }

  openAlertPopup (currentIndex, editingIndex) {
    const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.captions.common.Warning,  headerIcon: 'icon-warning-icon',  headerMessage: this.captions.common.saveChangesMessage , buttonName: this.captions.common.Yes, noButton: true, noButtonName: this.captions.common.No, type: 'message'
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        this.EnableEdit[editingIndex] = !this.EnableEdit[editingIndex];
        this.EnableEdit[currentIndex] = !this.EnableEdit[currentIndex];
      }  
    });
    this.valueChanged = false;
    return dialogRef;
  }
 

  LoadDecimalValue(val: any): string {
 
    const result: any = this.localization.localizeCurrency(val, false);
    return result;
  }

  sortHeader (dh, overriddenSortColumn, sortColumnDataType, i, from) {
    this.selectedDefaultHeader = dh;
    this.overriddenSortColumn = overriddenSortColumn;
    this.sortColumnDataType = sortColumnDataType;
    if (!this.options[0].isInitial && this.defaulSortColumn == this.selectedDefaultHeader && from == 'change') {
      if (this.orderTypearr.length > 0) {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
      } else {
        this.orderTypearr.push(dh);
      }
    } else {
      if (this.givenOrderType == 'desc') {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.givenOrderType = '';
      } else {
        this.givenOrderType = '';
      }
      if (this.orderTypearr.indexOf(dh) == -1) {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.orderType = 'asc';
      } else {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
        this.orderType = 'desc';
      }
    }

    if (document.getElementById('sortArrow' + i)) {
      for (let i = 0; i < this.options[0].TableHdrData.length; i++) {
        this.sortArrowFunction(i, 'remove');
      }
      this.sortArrowFunction(i, 'add');
    }
  }

  sortArrowFunction(i, eventType) {
    const element = document.getElementById('sortArrow' + i);
    if (element) {
      eventType == 'remove' ? element.classList.remove("IC6") : element.classList.add("IC6");
    }
    const headerElement = document.getElementById('header' + i);
    if (headerElement) {
      eventType == 'remove' ? headerElement.classList.remove("IC6") : headerElement.classList.add("IC6");
    }
  }

  DeleteRecords(e) {
    this.userAlerts.showPrompt(myGlobals.PromptType.Delete, this.PopupCallback.bind(this), e);
    this.changeEditMode(false);
    this.DeleteData.emit(e);

  }

  PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == 'ok') {
      this.deleteEvt.emit([extraParams, this.options[0].ServiceId, this.InActiveTherapistChkBoxEvt]);
      this.givenOrderType = this.orderType;
    }
  }

  autoscrolltocurrtime(curid) {
    if (document.getElementsByClassName('highlight').length > 0) {
      const curRow = curid.toString();
      const elms = document.getElementById(curRow);
      if (elms) {
        const scrolltoPos = (document.getElementById(curRow).offsetTop);
        const thPos = (document.getElementById('SPAHeaderRow').offsetHeight);
        document.getElementById('SPACustomTable').scrollTop = scrolltoPos - thPos;
        clearInterval(this.setinterscroll);
      }
    }
  }



  setCustomHeaderValues(setupFormGroupName: UntypedFormGroup, codeName: any, isActive: boolean, event: any) {
    setupFormGroupName.controls['setupCodeName'].setValue(codeName);
    setupFormGroupName.controls['activetoggle'].setValue(isActive);
    this.isValidRoleName = false;
    if (typeof event == 'object' &&  event.target.parentElement.parentElement.classList.contains('rowDisabled')) {
      setupFormGroupName.controls['setupCodeName'].setValue('');
      setupFormGroupName.controls['activetoggle'].setValue(false);
      this.customHeaderButton = this.localization.captions.setting.Add;
    }
  }

  enableCancelButton () {
    this.isCancelAvailable = this.customHeaderButton.toLowerCase() == this.localization.captions.setting.UPDATE.toLowerCase();
  }

  EditRecords(e, type, index?) {
    if (this.editEvt) {
      if (this.SelectedSettingId == myGlobals.GridType.commission) {
        this.editEvt.emit([e, type, index]);
      } else {
        this.editEvt.emit([e, this.options[0].ServiceId, type]);
        this.givenOrderType = this.orderType;
      }
    }
  }

  optionChange(e, belonTo, data) {
    // Needs to be moved to Retail table component
    if (this.GridType.quickSale == this.SelectedSettingId && e.value == 0) {
      this.SelectedData =  this.SelectedData.filter(r => r.id != data.id);
    }
    this.dropDownChange.emit([e, belonTo, data]);
  }

  calwidthwithtimeout() {
    setTimeout(() => {
      this.calculateWidth();
      }, 1);
  }

  clearSearchText () {
      this.searchText = '';
  }

  fieldEdit(event, selectedRow, key, index) { 
    this.copyOfTableData[index][key] = event.target.value == 0.00 || event.target.value == null ? this.LoadDecimalValue(0) : event.target.value;
    this.valueChanged = true;
    this.tableDataEdited(event, selectedRow, key, index);
  }

  checkValidEdit(currentIndex) {
    this.editingData = _.cloneDeep(this.bodyArray[currentIndex]); 
    this.EnableEdit[currentIndex] = !this.EnableEdit[currentIndex];
    this.userEditing.emit(true);
    this.changeEditMode(true);
  }

  changeEditMode(getEditModeFlag) {
    this.isEditModeSet = getEditModeFlag;
}

tableDataEdited(event, selectedRow, key, index) {
  this.bodyArray[index][key] = event.target.value;
  const editedDataSelected = this.SelectedData.findIndex(data => data.id === this.table.controls.tablebody['controls'][index].value.id);
  const allSelectedDataValid = this.checkAllDataValid();
   if (editedDataSelected === -1 && allSelectedDataValid) {
    this.EdittedData.emit(this.SelectedData);
    } else if (allSelectedDataValid) {
      this.EdittedData.emit(this.SelectedData);
    } else {
      this.EdittedData.emit([]);
    }
}

  checkAllDataValid() {
    const onlySelectedDataControls = [];
    this.SelectedData.forEach(data => this.table.controls.tablebody['controls'].map(x => {
      if (x.value.id === data.id) {
        onlySelectedDataControls.push(x);
      }
    }));
    return onlySelectedDataControls.every(data => data.valid);
  }

  private getArray() {
    this.totalSize = this.bodyArray.length;
    this.iterator();
  }

  handlePaginator(eve) {
    this.currentPage = eve.pageIndex;
    this.pageSize = eve.pageSize;
    this.iterator();
  }

  private iterator() {
    this.end = (this.currentPage + 1) * this.pageSize;
    this.start = this.currentPage * this.pageSize;
    const part = this.bodyArray.slice(this.start, this.end);
    this.dataSource = part;
  }
}

export interface listOrder {
  listOrder: number;
}


