import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { RetailLocalization } from './retail-localization'

@Pipe({ name: "Currency" })
@Injectable()
export class CustomCurrencyPipe implements PipeTransform {
    constructor(private language: RetailLocalization) {
    }

    transform(value: any, currencySymbolRequired: boolean = true): string {       
        return this.language.localizeCurrency(value, currencySymbolRequired);        
    }
}