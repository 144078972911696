

export interface GetDeviceResponse {
    devices: rGuestPayDevice[];
}

export interface rGuestPayDevice {
    deviceGuid: string;
    handlerId: string;
    deviceNumber: number;
    handlerStatus: string;
    deviceStatus: string;
    statusMessage: string;
    handler: string;
    manufacturer: string;
    product: string;
    description: string;
    deviceType: string;
    devicePath: string;
    serialNumber: string;
    serialNumber2: string;
    extendedData: ExtendedDatum[];
}

export interface ExtendedDatum {
    key: string;
    value: string;
}

export interface GetDeviceInfoResponse {
    deviceInfo: rGuestPayDevice;
}

export interface CaptureCardRequest {
    amount:          number;
    currencyCulture: CurrencyCulture;
}

export interface CurrencyCulture {
    currencyCultureName: string;
    currencySymbol:      string;
}


export interface CapturedCardResponse {
    cardData: CardData;
}

export interface CardData {
    accountName: string;
    accountNumber: string;
    expirationDate: string;
    cvv2: string;
    postalCode: string;
    track1: string;
    track2: string;
    cardDataSource: string;
    encryptionType: string;
    p2peData: P2PEData;
}

export interface P2PEData {
    deviceType: string;
    deviceIdentifier: string;
    encryptionIdentifier: string;
    encryptedData1: string;
    encryptedData2: string;
    additionalData: AdditionalDatum[];
}

export interface AdditionalDatum {
    name: string;
    value: string;
}

export interface RGuestSaleResponse {
    transactionReferenceData: TransactionReferenceData;
    transactionResponseData:  TransactionResponseData;
    gatewayResponseData:      { [key: string]: string };
    cardInfo:                 CardInfo;
    emvInfo:                  EmvInfo;
    pinVerified:              boolean;
    signatureStatus:          string;
    signatureData:            SignatureData;
    dccResponseData:          DCCResponseData;
    isOfflineApproved:        boolean;
    receiptData:              ReceiptData;
}

export interface CardInfo {
    cardHolderName:      string;
    accountNumberMasked: string;
    cardIssuer:          string;
    cardIssuerExtension: string;
    cardType:            string;
    entryMode:           string;
    expirationYearMonth: string;
    correlationId:       string;
}

export interface DCCResponseData {
    exchangeRate:          number;
    margin:                number;
    foreignCurrencyAlpha:  string;
    foreignCurrencyCode:   string;
    foreignCurrencyAmount: number;
}

export interface EmvInfo {
    isFallback:                   boolean;
    mode:                         string;
    applicationIdentifier:        string;
    applicationLabel:             string;
    applicationPreferredName:     string;
    issuerCodeTableIndex:         string;
    issuerApplicationData:        string;
    cryptogram:                   string;
    terminalVerificationResults:  string;
    transactionStatusInformation: string;
    authorizationResponseCode:    string;
    tags:                         Tag[];
}

export interface Tag {
    id:    string;
    value: string;
}

export interface ReceiptData {
    merchantReceiptText: string[];
    customerReceiptText: string[];
}

export interface SignatureData {
    format:    string;
    height:    number;
    width:     number;
    polyLines: PolyLine[];
}

export interface PolyLine {
    points: Point[];
}

export interface Point {
    x: number;
    y: number;
}

export interface TransactionReferenceData {
    transactionId:           string;
    token:                   string;
    tokenExpirationDate:     string;
    transactionState:        string;
    transactionFollowOnData: string;
    complianceDataValue:     string;
}

export interface TransactionResponseData {
    authorizedAmount: number;
    subTotalAmount:   number;
    tipAmount:        number;
    totalAmount:      number;
    availableBalance: number;
    surchargeAmount:  number;
}

export enum TransactionType {
    Auth = 1,
    Sale,
    Credit
}

export interface TokenResponse {
    token: string;
    tokenExpirationDate: string;
    gatewayResponseData: { [key: string]: string };
    cardInfo: CardInfo;
}

export interface CardInfo {
    cardHolderName: string;
    accountNumberMasked: string;
    cardIssuer: string;
    cardIssuerExtension: string;
    cardType: string;
    entryMode: string;
    expirationYearMonth: string;
    correlationId: string;
}