<div class="order-details">
  <!-- <div class="order-details__server-data">
      <app-dashboard-header *ngIf="dashboardData.length > 0" [dashboardData]="dashboardData"></app-dashboard-header>
    </div> -->
  <!-- <div class="order-details__server-actions"> -->
  <div class="customerdetails__reservations customerdetails__recent ordersMainDiv">
    <div *ngIf="orderDetails">
          <label for="personal_preference"
            class="customerdetails__mat-list-label customerdetails__cover orderDetailsHeader1">{{'reservationTurnTime' | translate}}</label>
          <label for="personal_preference" *ngIf="orderDetails.TableNames?.length"
            class="customerdetails__mat-list-label customerdetails__table orderDetailsHeader1">{{'guestBookTableText' | translate}}</label>
          <label for="personal_preference"
            class="customerdetails__mat-list-label customerdetails__cover orderDetailsHeader1">{{'partySize' | translate}}</label>
          <label for="personal_preference"
            class="customerdetails__mat-list-label customerdetails__table orderDetailsHeader1">{{'totalSpent' | translate}}</label>
          <label for="personal_preference"
            class="customerdetails__mat-list-label customerdetails__table orderDetailsHeader1">{{'chequeId' | translate}}</label>
    </div>
    <div *ngIf="orderDetails">
          <label for="personal_preference"
            class="customerdetails__mat-list-label customerdetails__cover orderDetailsData1">{{getTurnTime()}}</label>
          <label for="personal_preference" *ngIf="orderDetails.TableNames?.length"
            class="customerdetails__mat-list-label customerdetails__table orderDetailsData1">{{orderDetails.TableNames.toString()}}</label>
          <label for="personal_preference"
            class="customerdetails__mat-list-label customerdetails__cover orderDetailsData1">{{orderDetails.PartySize}}</label>
          <label for="personal_preference"
            class="customerdetails__mat-list-label customerdetails__table orderDetailsData1">{{cs.operationCurrency}}{{orderDetails.OrderCheckInfo.TotalSpent}}</label>
          <label for="personal_preference"
            class="customerdetails__mat-list-label customerdetails__table orderDetailsData1">#{{orderDetails.OrderCheckInfo.CheckId}}</label>
    </div>
  </div>

  <div class="order-details__order-table" style="text-align: center;">
    <ng-scrollbar>
      <table mat-table [dataSource]="tableDataSource" matSort class="mat-elevation-z8">

        <ng-container matColumnDef="item">
          <th class="rs-Roboto-Medium-16" mat-header-cell *matHeaderCellDef> {{ 'item' | translate}} </th>
          <td mat-cell *matCellDef="let tableDataSource">
            <div>
              {{tableDataSource.Name}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th class="rs-Roboto-Medium-16" mat-header-cell *matHeaderCellDef> {{ 'quantity' | translate}} </th>
          <td mat-cell *matCellDef="let tableDataSource"> {{tableDataSource.Quantity}} </td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th class="rs-Roboto-Medium-16" mat-header-cell *matHeaderCellDef> {{ 'pricePerItem' | translate}} ({{cs.operationCurrency}})</th>
          <td mat-cell *matCellDef="let tableDataSource"> {{tableDataSource.PricePerItem}} </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th class="rs-Roboto-Medium-16" mat-header-cell *matHeaderCellDef> {{ 'totaldollar' | translate}} ({{cs.operationCurrency}})</th>
          <td mat-cell *matCellDef="let tableDataSource">
            {{tableDataSource.TotalPrice}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
      </table>
    </ng-scrollbar>
  </div>
</div>