<div class="user-pin">
    <div class="user-pin__container">

      <div class="subcontainer">
        <div style="float:left;width:80%">
          <input class="userPinInput" type="password" maxlength='4' required pattern="[0-9]*" (keypress)="numberOnly($event)"
            autocomplete="new-name" appBlockPaste (input)="checkEnterval($event)" [(ngModel)]="userPinval"
            inputmode="numeric" />
        </div>
        <div class="emptyPIN">
          <span class="dot icon-Group-591" *ngIf="ishasValue" (click)="emptyval()"></span>
        </div>
        <div id="container" style="clear:both; padding-top: 10%">
          <ul id="keyboard">
            <li class="letter" (click)="numberpress(1)">1</li>
            <li class="letter" (click)="numberpress(2)">2</li>
            <li class="letter" (click)="numberpress(3)">3</li>
            <li class="letter clearl" (click)="numberpress(4)">4</li>
            <li class="letter" (click)="numberpress(5)">5</li>
            <li class="letter" (click)="numberpress(6)">6</li>
            <li class="letter clearl" (click)="numberpress(7)">7</li>
            <li class="letter " (click)="numberpress(8)">8</li>
            <li class="letter" (click)="numberpress(9)">9</li>
            <li class="letter clearl" (click)="emptyval()">C</li>
            <li class="letter" (click)="numberpress(0)">0</li>
            <li class="icon-close" (click)="removelastval()"></li>
          </ul>
        </div>

      </div>
      <div style="height: 10%; clear: both;">
        <mat-error *ngIf="ithaserror" class="errorblink" style="width: 100%;text-align:center;">
          {{ithaserror ? errorValue : '' }} </mat-error>
      </div>
    </div>