<div class='ag-dropdown-search ag_display--flex'>
    <mat-form-field [floatLabel]="floatLabel" class="ag_mt--0 pr-2" appearance="fill">
        <mat-select (selectionChange)='onChange($event)' [(ngModel)]='searchBy'>
            <mat-option *ngFor='let option of dropdownOptions' [value]="option.id">{{option.viewValue}}</mat-option>
        </mat-select>
    </mat-form-field>
    <app-simple-search *ngIf="!isShowDate" (keydown.enter)='newsearchvalue($event)' class='ag_ml--auto pr-2' [placeholder]="placeholderSearch" (searchChange)="search($event)">
    </app-simple-search>

    <mat-form-field class="date-picker-width ag_mt--0 pr-2 po-date" *ngIf="isShowDate"   [floatLabel]="floatLabel" appearance="legacy">
      <input matInput   [matDatepicker]="datePicker"  (dateChange)="onDateChange($event)"  [(ngModel)]='date' >
     <mat-datepicker-toggle matSuffix [for]="datePicker" >
       <i  aria-hidden="true"  class="icon-calendar" matDatepickerToggleIcon ></i>
     </mat-datepicker-toggle>
     <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>


    <app-button [buttontype]="searchBtn" class="searchBtn custbtnheight" (valueChange)="newsearchvalue($event)">
    </app-button>
</div>