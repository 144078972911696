import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'sortByDatePipe'
  })
  export class SortByDatePipe implements PipeTransform {
  
    transform(list: any[], property): any[] {
  
      var temp = [...list];
      temp.sort((a, b) => {
        let _a:any = property ? new Date(a[property]) : new Date(a);
        let _b:any = property ? new Date(b[property]) : new Date(b);
        return _a - _b
      });
  
      return temp;
    }
  
  
  }