<section class="night-audit-wrapper newVersionWrapper">
    <div class="bg-color">
        <div class="ag_display--flex-wrap ag_container--padding-lg">
            <div>
                <h6>{{captions.lbl_currentDate}}</h6>
                <h4>{{currSysDate | localizeDate}}</h4>
            </div>
            <div class="pt-4">
                <i class="icon-arrow-pointing-to-right pl-4 pr-4"></i>
            </div>
            <div>
                <h6>{{captions.lbl_systemDate}}</h6>
                <h4>{{newSysDate | localizeDate}}</h4>
            </div>
        </div>
    </div>
    <div class="ag_container--padding-lg night-audit-container">
        <form [formGroup]="nightAuditForm">
            <div>
                <div class="ag_display--flex-wrap">
                    <div class='d-inline-block mr-4'>
                        <label class="form-label-normal">{{captions.lbl_enableAutoDateRoll}}</label>
                        <app-retail-toggle-switch [attr.automationId]="'Tog_nightAudit_tog'" class="d-block mb-2" formControlName="enableAutoDateRoll"
                            (changeToggleEvent)="onToggleChange($event)"></app-retail-toggle-switch>
                    </div>
                    <div [ngClass]="[isToggleEnabled ? 'ag_display--block' : 'ag_display--none']" class="ml-4">
                        <app-button [attr.automationId]="'Btn_nightAudit_schedule'"  [buttontype]="scheduleButton" class="in-block" (valueChange)="schedule()" >
                        </app-button>
                    </div>
                </div>
                <div *ngIf="isToggleEnabled || dialogData">
                    <app-ag-time-picker [inputs]="selectTimeInputs" (timeChangeEvent)="timeChange($event)">
                    </app-ag-time-picker>
                </div>
            </div>
        </form>
        <div *ngIf="dialogData">
            <label class="ag_font--bold">{{captions.lbl_scheduleEmailSettings}}</label>
            <div class="scheduleEmailWrapper">
                <div class="p-3 mt-3">
                    <div class="ag_display--flex-wrap">
                        <i class="icon-calendar"></i>
                        <span class="pl-2"> {{dialogData?.startDate | date}} -
                            {{dialogData?.endDate | date}}</span>
                        <i [attr.automationId]="'Icn_nightAudit_edit'" class="icon-Edit ag_ml--auto cursor" (click)="edit(dialogData)"></i>
                    </div>
                    <div class="ag_display--flex-wrap pt-4">
                        <i class="icon-profile"></i>
                        <span class="pl-2">{{selectedDistributionOption}}</span>
                    </div>
                    <div class="ag_display--flex-wrap pt-2">
                        <span class="ml-3 pl-1">{{selectedTemplateOption}}</span>
                        <i [attr.automationId]="'Icn_nightAudit_delete'" class="icon-Delete ag_ml--auto cursor" (click)="delete(dialogData)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="ag_footer--actions">
    <div>
        <app-button [attr.automationId]="'Btn_nightAudit_save'" [buttontype]="saveButton" class="in-block pl-2" (valueChange)="save()"></app-button>
        <app-button [attr.automationId]="'Btn_nightAudit_cancel'" [buttontype]="cancelButton" class="in-block pl-2" (valueChange)="cancel()"></app-button>
    </div>
</div>
