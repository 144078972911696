<section [formGroup]="ItemFormGrp" autocomplete="off">
    <div *ngIf="!ItemFormGrp.value.filterItems">
    
    
      <app-retail-multi-select-dropdown *ngIf="!useRetailInterface && paymentMethods" [dropDownFilterData] = "paymentMethods" 
      [dropDownControlname] = "'paymentMethods'" [dropDownName] = "captions.PaymentMethods" 
      (dropDownFrmControl) = "getFormControlValue($event)" (IsAnySelected)="IsSelectionValid($event)"></app-retail-multi-select-dropdown>
         
      <app-retail-multi-select-dropdown *ngIf="allCategoryGroups"  [dropDownFilterData] = "allCategoryGroups"
       [dropDownControlname] = "'reportcategory'" [dropDownName] = captions.ReportCategory (dropDownFrmControl) = "getFormControlValue($event)" 
        (IsAnySelected)="IsSelectionValid($event)" (categoryGroup) = "getCategoryGroupValue($event)"></app-retail-multi-select-dropdown>
    
          <app-retail-category-component (formReady)="formInitialized($event)" [fromJobSchedulerEdit] = "isFromEdit"  [editValues] = "categoryComponentFilter" [categoryReportGroup]="categoryGroupSelected" ></app-retail-category-component>
    
          <app-retail-multi-select-dropdown *ngIf="clerks" [dropDownFilterData] = "clerks" [dropDownControlname] = "'clerks'" [dropDownName] = captions.Users (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
        
    
                <div class="mb-3">
                  <label class="LW14">{{inActiveText}}</label>
                  <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleIncludeInactive($event[0])" [formControl]="ItemFormGrp.controls['includeInactiveusers']"></app-retail-toggle-switch>
    
                </div>
                <mat-error class="user-select" *ngIf="!validSelection"> 
                  {{validSelectionErrMsg}}
               </mat-error>
    </div>
    </section>
    