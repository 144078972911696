<ng-container
    *ngIf="config && config.inputType && (config.inputType != undefined || config.inputType != '' ); else plainText">
    <mat-form-field [floatLabel]="floatLabel" *ngIf="config.changeLabelOnEdit? !config.isEditMode: true" [ngClass]="config.className"
                    [attr.id]="config.id" [formGroup]="config.form" [floatLabel]="config.floatLabel" (click)="onclick()">
        <mat-label [attr.LiteralID]="config.placeHolderId" style="cursor:pointer">{{config.placeHolder}}</mat-label>
        <input #inputFocus [attr.automationId]="config?.automationId" (input)="emitValue($event)" (blur)="emitBlurValue($event)" #inputField matInput [formControlName]="config.formControlName" [placeholder]="config.placeHolder" [readonly]="config.readonly" [attr.LiteralID]="config.placeHolderId"
               [required]="config.showRequired" [autocomplete]="config.autocomplete ? 'on': 'off'"
               [inputtype]="config.inputType" [maxlength]="config?.maxlength" [minlength]="config?.minlength">
        <mat-error *ngIf="config.form.controls[config.formControlName].hasError('required')">
            <span *ngIf="!config.customErrorMessage"
                  [attr.LiteralID]="config.errorMessageId">{{config.errorMessage}}</span>
            <span *ngIf="config.customErrorMessage && config.customErrorMessage.length > 0 "
                  [attr.LiteralID]="config.customErrorMessageId">{{config.customErrorMessage}} </span>
        </mat-error>
        <mat-error *ngIf="config.form.controls[config.formControlName].hasError('invalid')">
            {{config.customErrorMessage}}
        </mat-error>
        <mat-error *ngIf="config.form.controls[config.formControlName].hasError('minlength')"
                   [attr.LiteralID]="'err_min'">
            {{captions.err_min}}
        </mat-error>
        <mat-error *ngIf="config.form.controls[config.formControlName].hasError('maxlength')"
                   [attr.LiteralID]="'err_max'">
            {{captions.err_max}}
        </mat-error>
        <mat-error *ngIf="config.form.controls[config.formControlName].hasError('incorrect')"
                [attr.LiteralID]="captions.err_invalidEmailFormat">
            {{captions.err_invalidEmailFormat}}
        </mat-error>
    </mat-form-field>
    <div class="ag_display--inblock ag_pb--4" [ngClass]="config?.className" *ngIf="config.changeLabelOnEdit && config.isEditMode">
        <label class="ag_form--label" [attr.LiteralID]="config.placeHolderId">{{config.placeHolder}}</label>
        <span>{{config.form.controls[config.formControlName].value}}</span>
    </div>
</ng-container>

<ng-template #plainText>
    <ng-container>
        <mat-form-field [floatLabel]="floatLabel" *ngIf="config?.changeLabelOnEdit? !config?.isEditMode: true" [ngClass]="config?.className" [attr.id]="config?.id"
            [formGroup]="config?.form" [floatLabel]="config?.floatLabel" [attr.LiteralID]="config?.placeHolderId">
            <input (input)="emitValue($event)" [type]="config?.type ? config?.type : 'text'" (blur)="emitBlurValue($event)"  #inputField matInput [formControlName]="config?.formControlName" [placeholder]="config?.placeHolder" [readonly]="config?.readonly"
            [attr.automationId]="config?.automationId"  [required]="config?.showRequired" [autocomplete]="config?.autocomplete ? 'on': 'off'" 
                   [maxlength]="config?.maxlength" [minlength]="config?.minlength">
            <mat-error *ngIf="config?.form.controls[config?.formControlName].hasError('required')"  [attr.LiteralID]="config?.errorMessageId">
                <span *ngIf="!config?.customErrorMessage">{{config?.errorMessage}}</span>
                <span *ngIf="config?.customErrorMessage && config?.customErrorMessage.length > 0 ">{{config?.customErrorMessage}} </span>
            </mat-error>
            <mat-error *ngIf="config?.form.controls[config?.formControlName].hasError('invalid')" [attr.LiteralID]="customErrorMessageId">
                {{config.customErrorMessage}}
            </mat-error>
            <mat-error *ngIf="config?.form.controls[config?.formControlName].hasError('minlength')" [attr.LiteralID]="captions.err_min">
                {{captions.err_min}}
            </mat-error>
            <mat-error *ngIf="config?.form.controls[config?.formControlName].hasError('maxlength')" [attr.LiteralID]="captions.err_max">
                {{captions.err_max}}
            </mat-error>
        </mat-form-field>
        <div class="ag_display--inblock ag_pb--4" [ngClass]="config?.className" *ngIf="config?.changeLabelOnEdit && config.isEditMode">
            <label class="ag_form--label" [attr.LiteralID]="config.placeHolderId">{{config.placeHolder}}</label>
            <span>{{config?.form.controls[config?.formControlName].value}}</span>
        </div>
    </ng-container>
</ng-template>