import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import moment from 'moment'
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { Host, RetailBreakPoint } from '../../shared/globalsContant';
import { HttpMethod } from '../../shared/service/http-call.service';

import { Subscription, Subject } from 'rxjs';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { PackagedItem, PackageItemInfo } from '../../retail.modals';
import { RetailSetupService } from '../retail-setup.service';
import { RetailDataAwaiters } from '../../shared/events/awaiters/retail.data.awaiters';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
@Component({
  selector: 'app-packaged-items',
  templateUrl: './packaged-items.component.html',
  styleUrls: ['./packaged-items.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PackagedItemsComponent implements OnInit, AfterViewInit, OnDestroy {
  displayPIcount: number = 1;
  SelectedData: any = [];
  AdvanceSearchEnabled: boolean = false;
  tableHDRdata: any[];
  tabledata: PackageItemInfo[] = [];
  category: any[];
  subCategory1: any[];
  subCategory2: any[];
  subCategory3: any[];
  subCategory4: any[];
  subCategory5: any[];

  categories: Array<any> = [];
  selectedCategoryArray: Array<any> = [];
  selectedCategory = 0;
  selectedSubCategory1 = 0;
  selectedSubCategory2 = 0;
  selectedSubCategory3 = 0;
  selectedSubCategory4 = 0;
  selectedSubCategory5 = 0;

  subCategoryOne: Array<any> = [];
  subCategoryTwo: Array<any> = [];
  subCategoryThree: Array<any> = [];
  subCategoryFour: Array<any> = [];
  subCategoryFive: Array<any> = [];
  categoriesClone: Array<any> = [];
  subCategoryOneClone: Array<any> = [];
  subCategoryTwoClone: Array<any> = [];
  subCategoryThreeClone: Array<any> = [];
  subCategoryFourClone: Array<any> = [];
  subCategoryFiveClone: Array<any> = [];
  ChildSubCategories: Array<any> = [];
  IsViewOnly: boolean = false;

  filteredData: any[];
  packageItems = [];
  categorySearches = [];
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectable = true;
  removable = true;
  packageSearchArray: any[];
  selectedPackageDetails: any = [];
  IsCheckAll: boolean = false;
  searchText: string = '';
  minFromDate;
  minToDate;
  popTemplate: any;
  myPopover: any;
  public selectedOutletMgrArr: any = [];
  captions: any;
  @ViewChild('packageInput') packageInput: ElementRef;
  @ViewChild('scroll') scrollvalue;
  packagedItemsFormGrp: UntypedFormGroup;
  outletSubscription: Subscription;
  bufferCount = 10;
  @Input() isEdit;
  pageStart = 0;
  pageLength = 20;
  sortBy = 0;
  isSortByAscending = true;
  isLoading = false;
  notifier = new Subject<void>();
  validateBtn: any;
  validateBtnSubscription: Subscription;
  packedItemsChanges: Subscription;
  functionalities: { [key: string]: boolean} = {};
  captionsCommon: any;
  placeholderFormat: string;
  floatLabel: string;
  @Input() parentForm;

  constructor(public _retailService: RetailSetupService, private dialogRef: MatDialogRef<RetailPopupComponent>,
    public localization: RetailLocalization, private PropertyInfo: RetailPropertyInformation, private utils: RetailUtilities, 
    private func:RetailFunctionalityBusiness) {
      this.captionsCommon = this.localization.captions.common;
      this.floatLabel = this.localization.setFloatLabel;

  }

  ngOnInit() {
    this.placeholderFormat = this.localization.inputDateFormat;
    this.captions = this.localization.captions.retailsetup;
    this.packagedItemsFormGrp = this._retailService.packagedItemForm;
    this._retailService.packagedItemEdited = false;
    this.selectedPackageDetails = this._retailService.packagedItems ? this._retailService.packagedItems : [];
    this._retailService.packagedItems = this.selectedPackageDetails;
    this.category = [];
    this.minFromDate = this.minToDate = this.PropertyInfo.CurrentDate;
    this.IsViewOnly = this._retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == RetailBreakPoint.ItemSetup).view;
    this.tableHDRdata = [this.captions.item, this.captions.ItemDescription, this.captions.Price + ' (' + this.localization.currencySymbol + ')', this.captions.Category, this.captions.Subcategory1, this.captions.Subcategory2, this.captions.Subcategory3, this.captions.Subcategory4, this.captions.Subcategory5];
    this.outletSubscription = this._retailService.selectedOutletMgrArr.subscribe(outlet => { this.OnOutletChange(outlet) });
    this.GetCategoryLinking();

    this._retailService.itemInfoLoaded.subscribe(() => {
      this.selectedPackageDetails = this._retailService.packagedItems ? this._retailService.packagedItems : [];
    });

    this.packedItemsChanges = this.packagedItemsFormGrp.valueChanges.subscribe(data => {
      this._retailService.ValidateUpdate();
    });
    
    this.validateBtnSubscription = this._retailService.validateBtn.subscribe(isValid =>
      this.validateBtn = isValid);
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
    });
    if(this.parentForm){
      this.parentForm.addControl('packagedItemFormGroup', this.packagedItemsFormGrp)
    }
  }

  ngAfterViewInit() {
    this.selectedPackageDetails = this._retailService.packagedItems ? this._retailService.packagedItems : [];
  }

  async filterBasedOnOutlets() {
    this.filteredData = [];
    this.pageStart = 0;
    await this.loadRetailItems();
  }

  async OnOutletChange(outlet) {
    this.selectedOutletMgrArr = outlet;
    await this.filterBasedOnOutlets();
  }

  ngOnDestroy() {
    this.outletSubscription.unsubscribe();
    this._retailService.itemInfoLoaded.unsubscribe();
    this.validateBtnSubscription?.unsubscribe();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Reset the input value
    if (input) {
      input.value = value;
    }
  }

  async getSearchResult() {
    await this.filterBasedOnOutlets();
  }

  clearInput = () => {
    this.packageSearchArray = [];
  }

  searchPackages = () => {
    let input = this.packageInput.nativeElement.value;
    let packArr = _.filter(this.tabledata, item => {
      let serachValue = input.toLowerCase();
      return item.itemNumber.includes(serachValue) || item.itemDescription.toLowerCase().includes(serachValue)
    });

    let packageResult = packArr.map(item => {
      let searchInp = !isNaN(input) ? input.toLowerCase() : input;
      if (item.itemNumber.includes(searchInp)) {
        return item.itemNumber;
      }
      if (item.itemDescription.toLowerCase().includes(searchInp)) {
        return item.itemDescription;
      }
    });
    this.packageSearchArray = _.uniq(packageResult);

  }

  OpenAdvaceSearch() {
    document.getElementsByClassName('packagedItem-content')[0].classList.add('AdSE');
    this.AdvanceSearchEnabled = true;
  }

  async closeAdvaceSearch() {
    document.getElementsByClassName('packagedItem-content')[0].classList.remove('AdSE');
    this.AdvanceSearchEnabled = false;
    this.packagedItemsFormGrp.setValue({
      PI_searchText: [''],
      PI_Category1: [''],
      PI_Category2: [''],
      PI_Category3: [''],
      PI_Category4: [''],
      PI_Category5: [''],
      PI_Category6: ['']
    });

    this.selectedCategory = 0;
    this.selectedSubCategory1 = 0;
    this.selectedSubCategory2 = 0;
    this.selectedSubCategory3 = 0;
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    await this.filterBasedOnOutlets();
  }

  RowSelect(eve: any, data: any, checkType: string) {
    if (checkType === 'All') {
      if (eve.checked) {
        this.SelectedData = [];
        const availablePackageDetails = _.difference(data, this.selectedPackageDetails);
        _.forEach(availablePackageDetails, packageData => {

          packageData['checked'] = eve.checked;
          packageData['isDone'] = false;
          packageData['startDate'] = this.utils.getDate(moment(this.PropertyInfo.CurrentDate).add(0, "days").format());
          packageData['endDate'] = this.utils.getDate(moment(this.PropertyInfo.CurrentDate).add(7, "days").format());
          packageData['minFromDate'] = this.minFromDate;
          packageData['minToDate'] = this.minToDate;

          this.SelectedData.unshift(_.cloneDeep(packageData));
        });
      } else {
        this.SelectedData = [];
        _.forEach(data, packageData => {
          packageData.checked = false;
        });
      }
    } else if (checkType === 'Single') {
      data.checked = eve.checked;
      if (eve.checked) {
        data.isDone = false;
        data.startDate = this.utils.getDate(moment(this.PropertyInfo.CurrentDate).add(0, "days").format());
        data.endDate = this.utils.getDate(moment(this.PropertyInfo.CurrentDate).add(7, "days").format());
        data.minFromDate = this.minFromDate;
        data.minToDate = this.minToDate;
        // ADDING DATA INSTANCE TO AVOID LOCALIZATION ERRORS BUG ID : 22869
        const GroupedItemsData = _.cloneDeep(data);
        GroupedItemsData.salesPrice = this.localization.localizeCurrency(data.salesPrice, false);
        GroupedItemsData.memberPrice = this.localization.localizeCurrency(data.memberPrice, false);
        this.SelectedData.unshift(_.cloneDeep(GroupedItemsData));
      } else {
        const selected = _.cloneDeep(this.selectedPackageDetails.filter(x => x.itemNumber == data.itemNumber && !x.isDone))
        this.SelectedData = _.filter(this.SelectedData, packDetails => packDetails.itemNumber != data.itemNumber);
        if (selected && selected.length > 0 && selected[0].isDone) {
          this._retailService.packagedItemEdited = true;
        }
      }
    }

    this.IsCheckAll = this.filteredData.every(x => x.checked);
    this._retailService.packagedItems = this.selectedPackageDetails;
  }

  close() {
    this.dialogRef.close();
  }

  async deleteText() {
    this.searchText = '';
    this.packageInput.nativeElement.value = '';

    await this.filterBasedOnOutlets();
  }

  remove = (packageName) => {
    this.packageItems = this.packageItems.filter(item => item != packageName);
    if (this.packageItems.length == 0) {
      this.filteredData = this.tabledata;
    }
    this.searchText = '';
  }

  selected = (event) => {
    this.packageItems.push(this.packageSearchArray.filter(item => item == event.option.value)[0]);
    this.packageItems = _.uniq(this.packageItems);
    this.packageInput.nativeElement.value = '';
    this.searchText = '';
    this.clearInput();
  }

  cancelPackage = (packageDetails) => {
    packageDetails.salesPrice = null;
    packageDetails.memberPrice = null;
    this._retailService.ValidateUpdate();
  }

  updatePackge = (packageDetails) => {
    packageDetails.salesPrice = packageDetails.salesPrice ? this.localization.localizeCurrency(this.localization.currencyToSQLFormat(packageDetails.salesPrice)) : 0;
    packageDetails.memberPrice = packageDetails.memberPrice ? this.localization.localizeCurrency(this.localization.currencyToSQLFormat(packageDetails.memberPrice)) : 0;
    this.selectedPackageDetails.push(_.filter(this.SelectedData, packDetails => {
      if (packDetails.itemNumber == packageDetails.itemNumber) {
        let index = this.filteredData.findIndex(x => x.id == packDetails.id);
        if(index >= 0){
          this.filteredData[index].isDone = !packDetails.isDone;
          this.filteredData[index].checked = packDetails.isDone;
        }        
        packDetails.isDone = !packDetails.isDone;
        return packDetails;
      }
    })[0]);
    this.SelectedData = _.filter(this.SelectedData, packDetails => packDetails.itemNumber != packageDetails.itemNumber);
    this._retailService.packagedItemEdited = true;
    this._retailService.packagedItems = this.selectedPackageDetails;
    this.calculatePrice();
    this.IsCheckAll = _.every(this.filteredData, ['checked', true]);
    this._retailService.ValidateUpdate();
    this.packagedItemsFormGrp.markAsDirty();
  }

  editPackage = (packDetails, index) => {
    packDetails.isDone = !packDetails.isDone;
    this.selectedPackageDetails.splice(index, 1);
    this.SelectedData.push(packDetails);
  }

  deletePackage = (packDetails, index) => {
    packDetails.checked = false;
    packDetails.isDone = false;
    this.selectedPackageDetails.splice(index, 1);
    this.IsCheckAll = _.every(this.filteredData, ['checked', true]);
    this._retailService.packagedItemEdited = true;
    this._retailService.packagedItems = this.selectedPackageDetails;
    this.filteredData.filter(x => x.itemNumber == packDetails.itemNumber)[0].checked = false;
    this.calculatePrice();
  }
  prevData = () => {
    if (this.displayPIcount > 1) {
      this.displayPIcount = this.displayPIcount - 1;
    }

  }
  NextData = () => {
    if (this.displayPIcount != this.SelectedData.length) {
      this.displayPIcount = this.displayPIcount + 1;
    }
  }

  fromDateChange($event, packageDetails) {
    packageDetails.minToDate = this.utils.getDate($event);
    if (this.utils.getDate(packageDetails.endDate) < this.utils.getDate($event)) {
      packageDetails.endDate = this.utils.getDate($event);
    }
  }
  returnCorrectDate(fromdt, mindate) {
    let returndate = fromdt;
    if (moment(mindate) > moment(fromdt)) {
      returndate = mindate;
    }
    return returndate;
  }

  calculatePrice() {
    if (!this.PropertyInfo.UseRetailInterface && this._retailService.packagedItems && this._retailService.packagedItems.length > 0) {

      let packagedItems: PackagedItem[] = this._retailService.packagedItems.map(x => {
        return {
          retailItemId: 0,
          parentItemId: x.id,
          startDate: this.localization.convertDateObjToAPIdate(x.startDate),
          endDate: this.localization.convertDateObjToAPIdate(x.endDate),
          price: x.salesPrice ? this.localization.currencyToSQLFormat(x.salesPrice) : 0,
          memberPrice: x.memberPrice ? this.localization.currencyToSQLFormat(x.memberPrice) : 0
        }
      })

      var salesPrice = packagedItems.reduce(function (prev, cur) {        
        return prev + cur.price;
      }, 0);

      var memberPrice = packagedItems.reduce(function (prev, cur) {
        return prev + cur.memberPrice;
      }, 0);
      this._retailService.GeneralFormGrp.controls.salesprice.setValue(this.localization.localizeCurrency(salesPrice, false));
      this._retailService.GeneralFormGrp.controls.memberprice.setValue(this.localization.localizeCurrency(memberPrice, false));
      this._retailService.calculateItemPrice(salesPrice, 'SP')
    }
  }

  OnCategoryChange($event: any) {
    this.selectedCategory = $event.value.id;
    this.selectedCategoryArray = this.categories.filter(a => a.id == this.selectedCategory);
    this.subCategoryOne = this.subCategoryOneClone.filter(a => { return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == a.id) });
    this.subCategoryTwo = this.subCategoryTwoClone.filter(a => { return this.subCategoryOne.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) });
    this.subCategoryThree = this.subCategoryThreeClone.filter(a => { return this.subCategoryTwo.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) });
    this.subCategoryFour = this.subCategoryFourClone.filter(a => { return this.subCategoryThree.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) });
    this.subCategoryFive = this.subCategoryFiveClone.filter(a => { return this.subCategoryFour.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) });
  }

  OnSubCategory1Change($event: any) {
    this.selectedSubCategory1 = $event.value.id;
    this.selectedCategoryArray = this.subCategoryOneClone.filter(a => a.id == this.selectedSubCategory1);
    this.subCategoryTwo = this.subCategoryTwoClone.filter(a => { return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == a.id) });
    this.subCategoryThree = this.subCategoryThreeClone.filter(a => { return this.subCategoryTwo.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) });
    this.subCategoryFour = this.subCategoryFourClone.filter(a => { return this.subCategoryThree.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) });
    this.subCategoryFive = this.subCategoryFiveClone.filter(a => { return this.subCategoryFour.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) });
  }

  OnSubCategory2Change($event: any) {
    this.selectedSubCategory2 = $event.value.id;
    this.selectedCategoryArray = this.subCategoryTwoClone.filter(a => a.id == this.selectedSubCategory2);
    this.subCategoryThree = this.subCategoryThreeClone.filter(a => { return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == a.id) });
    this.subCategoryFour = this.subCategoryFourClone.filter(a => { return this.subCategoryThree.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) });
    this.subCategoryFive = this.subCategoryFiveClone.filter(a => { return this.subCategoryFour.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) });
  }

  OnSubCategory3Change($event: any) {
    this.selectedSubCategory3 = $event.value.id;
    this.selectedCategoryArray = this.subCategoryThreeClone.filter(a => a.id == this.selectedSubCategory3);
    this.subCategoryFour = this.subCategoryFourClone.filter(a => { return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == a.id) });
    this.subCategoryFive = this.subCategoryFiveClone.filter(a => { return this.subCategoryFour.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) });
  }

  OnSubCategory4Change($event: any) {
    this.selectedSubCategory4 = $event.value.id;
    this.selectedCategoryArray = this.subCategoryFourClone.filter(a => a.id == this.selectedSubCategory4);
    this.subCategoryFive = this.subCategoryFiveClone.filter(a => { return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == a.id) });
  }

  async scrollEndHandler(arg) {
    if (!this.filteredData || !this.filteredData.length || arg.endIndex < this.filteredData.length - (this.bufferCount + 1)) { return; }

    if (this.scrollvalue.viewPortItems && this.scrollvalue.viewPortItems.length && !this.isLoading) {
      await this.loadRetailItems();
    }
    
  }

  async loadRetailItems() {
    try {
      this.isLoading = true;
      this.notifier = new Subject<void>();

      const outletIds = this._retailService.selectedOutletMgrArr.value.map(x => x.id);
      const outletId = outletIds.join('&outletId=');
      const response = await this._retailService.cancellableObservalble(
        this.notifier,
        'GetItemByPagination',
        Host.retailManagement,
        HttpMethod.Get,
        undefined,
        {
          outletId,
          includeInactive: false,
          pageStart: this.pageStart,
          pageLength: this.pageLength,
          sortBy: this.sortBy,
          isSortByAscending: this.isSortByAscending,
          searchText: this.searchText,
          category: this.selectedCategory,
          subcategory1: this.selectedSubCategory1,
          subcategory2: this.selectedSubCategory2,
          subcategory3: this.selectedSubCategory3,
          subcategory4: this.selectedSubCategory4,
          subcategory5: this.selectedSubCategory5
        }).toPromise();

      if (response &&  response.result.length > 0) {

        const items = response.result;
        for (const i of items) {
          const item = this.SelectedData.find(x => x.id === i.id);
          i.checked = !!item;
        }
        this.filteredData.push(...items);
        this.filteredData = _.uniqBy(this.filteredData, 'id')
        this.IsCheckAll = this.filteredData.every(x => x.checked);

        this.pageStart += this.pageLength;
      }

      this.isLoading = false;
    } catch (ex) {
      console.error(ex);
    } finally {
      this.isLoading = false;
    }

  }

  async GetCategoryLinking() {
    if (this._retailService.categories || this._retailService.categories.length == 0) {
      await this._retailService.GetAllCategory();
    }
    this.categories = this._retailService.categories;
    this.subCategoryOne = this._retailService.subCategoryOne;
    this.subCategoryTwo = this._retailService.subCategoryTwo;
    this.subCategoryThree = this._retailService.subCategoryThree;
    this.subCategoryFour = this._retailService.subCategoryFour;
    this.subCategoryFive = this._retailService.subCategoryFive;
    this.categoriesClone = _.cloneDeep(this.categories);
    this.subCategoryOneClone = _.cloneDeep(this.subCategoryOne);
    this.subCategoryTwoClone = _.cloneDeep(this.subCategoryTwo);
    this.subCategoryThreeClone = _.cloneDeep(this.subCategoryThree);
    this.subCategoryFourClone = _.cloneDeep(this.subCategoryFour);
    this.subCategoryFiveClone = _.cloneDeep(this.subCategoryFive);
  }
  stopdefaultClick(e) {
    if (this.isEdit && this.IsViewOnly) {
      e.preventDefault();
    }
  }

  async save() {

    this._retailService.ValidateUpdate();
    if (!this.validateBtn) return;
    if (!this.isEdit) {
      this.validateBtn = false;
    }
    let updateBody = this._retailService.FormBodyData(this.isEdit, this.PropertyInfo.UseRetailInterface);
    let response;

    let itemId = 0;
    if (this.isEdit) {
      response = await this._retailService.InvokeServiceCallAsync("UpdateRetailItem", Host.retailManagement, HttpMethod.Put, updateBody, { id: updateBody.retailItem.retailItemDetail.id })
      if (response) {
        this._retailService.updateRetailItemImage(updateBody.retailItem.retailItemDetail.id);
        itemId = updateBody.retailItem.retailItemDetail.id;
      }
    }
    else {
      response = await this._retailService.InvokeServiceCallAsync("CreateRetailItem", Host.retailManagement, HttpMethod.Post, updateBody)
      if (response) {
        this._retailService.saveImage(response.result)
        itemId = response.result;
      }      
    }
    if (this._retailService.showRetailSetupRoomTypesAssignmentTab){
      RetailDataAwaiters.createOrUpdateRoomTypeAssignment(updateBody.retailItem.roomTypeAssignmentRetailItemMapping , itemId)
    }
    if (response && response.result) {
      this._retailService.retailItemUpdateEvent.next(itemId);
      this.dialogRef.close(updateBody);
    }
  }

}

@Pipe({
  name: 'getoutletname'
})
export class GetOutletNamePipe implements PipeTransform {
  transform(outlet) {
    if (outlet && outlet.name) {
      return outlet.name;
    }
    return '';
  }

}
