<ng-container [formGroup]="contactForm">
  <div class="ag-address ag_position--relative" [formArrayName]="arrayName"
    *ngFor="let addressDetail of contactForm.get(arrayName)['controls']; let i = index; let first = first">
    <div [formGroupName]="i" class="fixed-width-wrapper ag_display--flex align-items-center">
      <mat-form-field [ngClass]="customClass" [floatLabel]="floatLabel">
        <input matInput *ngIf="first" ngx-google-places-autocomplete #placesRef="ngx-places"
          (onAddressChange)="bindAddressFromGoogle($event,true,i)" [maxlength]='maxLength' id="AddressInput{{i}}"
          [formControlName]="controlName" [placeholder]="placeHolder" [disabled]="isAddressDisabled">
        <input matInput *ngIf="!first" (onAddressChange)="bindAddressFromGoogle($event,true,i)" [maxlength]='maxLength'
          id="AddressInput{{i}}" [formControlName]="controlName" [placeholder]="placeHolder"
          [disabled]="isAddressDisabled">
      </mat-form-field>
      <span class="icon-Plus position-relative mt-0" [ngClass]="{'ag_section--disable':isAddressDisabled}" (click)="addAddress('')"
        *ngIf="first && addresslength != 3"></span>
      <span class="icon-Minus position-relative mt-0" [ngClass]="{'ag_section--disable':isAddressDisabled}" (click)="removeAddress(i)"
        *ngIf="!first"></span>
    </div>
  </div>
</ng-container>