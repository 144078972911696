import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { AppService } from '@app/app.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { PartyState, SlottingMode, PartyType, ComponentTypes, ReservationType, buttonTypes, RolesAndPermissionsType, BookingBehavior } from '@app/shared/constants/commonenums';
import { OpenBookingDTO } from '@app/shared/models/OpenBookingDTO';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { AddonActivitiesMappingsDTO, AddonDTO, AllocationTypeDTO, BreakHoursDTO, FloorPlanDTO, LayoutDTO, SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { LayoutFunctions } from '@utilities/layout-functions';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import _ from 'lodash';
import { AddOnAvailability } from '@app/shared/models/InputReservation';
import { AddOnForBookingComponent } from '@app/shared/components/add-on-for-booking/add-on-for-booking.component';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ISubscription } from 'rxjs/Subscription';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { TableSelectionComponent } from '../table-selection/table-selection.component';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { HostDTO } from '@app/shared/models/HostDTO';
import { SettingsService } from '@app/shared/services/settings.service';
import { EngageMemberByCardIdRequestDTO } from '@app/shared/models/EngageMemberByCardIdRequestDTO';
import { EngageMemberDetailDTO, EngageProfile } from '@app/shared/models/EngageMemberDetailDTO';
import { RoleType } from '@app/shared/models/LoginResultDTO';
import { AnyMembers } from '@app/shared/constants/globalConstants';
import { ExcludeLocationGroupPipe } from '@app/shared/pipes/ExcludeLocationGroup.pipe';


@Component({
  selector: 'open-booking-form',
  templateUrl: './open-booking-form.component.html',
  styleUrls: ['./open-booking-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OpenBookingFormComponent implements OnInit, AfterViewInit, OnDestroy {

  bookingFromDateConfig: FieldConfig[];
  bookingToDateConfig: FieldConfig[];
  locationConfig: FieldConfig[];
  membershipDetailConfig: FieldConfig[];
  bookingSizeArray: any = [];
  selectedBookingSize: number;
  maxSizeAllowed: number = 60;
  locationList: any = [];
  floorPlan: FloorPlanDTO[] = [];
  _settings: SettingsDTO;
  _layout: LayoutDTO = {} as LayoutDTO;
  subscriptions: Subscription = new Subscription();
  displaySlots: boolean = false;
  coverTypes = [];
  bookingTypes = [];
  hasMembershipValue: boolean = false;
  timeSlotUnitInMinutes: number = 30;
  validCoverTypes: boolean = true;
  allocationCodes: AllocationTypeDTO[];
  validBookingTypes: boolean = true;
  memberShipdetail: any = {};
  selectedMemberDetail : EngageMemberDetailDTO = null;
  MemberDetailsArray: any = [];
  memberShipArr: any = [];
  slotResponse: any = [];
  editCheckedInReservation = false;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  isMultipleSlotSelectionEnabled: any;
  ReservationType = ReservationType;
  defaultTableText = '';
  selectedLocationIds: Number;
  showSalesContact: boolean = false;
  salesContactConfig: FieldConfig[];
  @ViewChild('salesContactForm') salesContactForm: DynamicFormComponent;
  memberSearchButton: ButtonValue;
  bookingTypeRequiredMsg: string = '';
  coverTypeRequiredMsg: string = '';
  sizeMapping:any;

  constructor(private cs: CacheService, @Inject(COMPONENTINPUT) public data, private dialog: MatDialog, private apiService: ApiService,
    public partyService: PartyService, private fb: UntypedFormBuilder, private ps: PopupService, private ts: TranslateService, private lf: LayoutFunctions,
    public appService: AppService, private df: DashboardFunctions, private settingsService: SettingsService , public excludeLocationGroupPipe : ExcludeLocationGroupPipe) {
    if (this.data) {
      if (this.data.Size) {
        this.maxSizeAllowed = this.data.Size < 60 ? 60 : this.data.Size;
      }
      this.isMultipleSlotSelectionEnabled = this.data?.Slots || false;
    }
    this.generatePartySizeArray();
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      if (this._settings.PropertySetting && this._settings.PropertySetting[0]?.TrackingofSalesPerson) {
        this.showSalesContact = true;
      }
      this.timeSlotUnitInMinutes = this._settings.General.TimeSlotUnitInMinutes;
      this.coverTypes = [...this._settings.CoverTypes.filter(covers => covers.IsRegularReservation)];
      this.bookingTypes = [...this._settings.BookingTypes];
    }));
    this.subscriptions.add(cs.layout.subscribe(layt => {
      this._layout = layt;
    }));
    this.loadLocations();
    this.partyService.BookingBehaviour = BookingBehavior.OpenBooking;
  }

  ngOnInit() {

    this.partyService.openBookingDataSaveObj = null;
    let restaurantDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    this.partyService.customFieldValidation = [];
    this.partyService.isSlotLocked = false;
    this.bookingFromDateConfig = [
      {
        type: 'date',
        name: 'fromDate',
        inputType: 'text',
        class: 'field-90',
        value: (this.data && this.data.StartDate) ? new Date(this.data.StartDate) : this.df.checkBookPastDaysReservationsConfig() ? this.appService.headerDate$.value : (this.data && this.data.ReservedFor) ? this.data.ReservedFor : this.appService.headerDate$.value > restaurantDate ? this.appService.headerDate$.value : restaurantDate,
        appearance: false,
        validation: [Validators.required],
        minDate: this.df.getMinDateBasedonConfig(null, restaurantDate),
        disabled: this.df.checkEditCheckedInReservationsConfig() && this.data && this.data.State == PartyState.Seated ? true : false
      }];
    this.bookingToDateConfig = [{
      type: 'date',
      name: 'toDate',
      inputType: 'text',
      class: 'field-90',
      value: (this.data && this.data.EndDate) ? new Date(this.data.EndDate) : this.df.checkBookPastDaysReservationsConfig() ? this.appService.headerDate$.value : (this.data && this.data.ReservedFor) ? this.data.ReservedFor : this.appService.headerDate$.value > restaurantDate ? this.appService.headerDate$.value : restaurantDate,
      appearance: false,
      disabled: true,
      minDate: restaurantDate
    }];
    this.locationConfig = [{
      type: 'autocomplete',
      name: 'location',
      options: this.locationList,
      value: (this.data && this.data.TableIds) ? this.locationList.filter(l => l.id == this.data.TableIds[0])[0].id : (this.data && this.data.addOpenBooking) ? this.data.Id : this.locationList[0].id,
      class: 'open-booking__seating-type',
      showErrorText: true,
      isTranslate: false,
      validation: [Validators.required],
      autoCompleteWithId: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      returnFunction: this.clearFormField.bind(this, 'location'),
      disabled: this.df.checkEditCheckedInReservationsConfig() && this.data && this.data.State == PartyState.Seated
    }];

    if (this.data?.addOpenBooking) {
      this.data.Id = null;
    }
    this.partyService.isOverBookSlotSelected = this.data && this.data.OverBooked && this.data.OverBooked > 0;

    if (this.partyService.reservationFormGroup.value.selectedTable?.length) {
      this.locationConfig[0].value = this.partyService.reservationFormGroup.value.selectedTable[0];
    }

    this.membershipDetailConfig = [{
      type: 'autocomplete',
      name: 'membershipId',
      inputType: 'text',
      options: this.MemberDetailsArray,
      class: 'party-size',
      showErrorText: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      appearance: false,
      isTranslate: false,
      partySize: false,
      label: 'searchbyphoneandemail',
      cellClick: this.setSelectedGuest.bind(this),
      returnFunction: this.clearmembershipDetail.bind(this)
    }];
    this.salesContactConfig = [{
      type: 'autocomplete',
      name: 'salescontacts',
      options: [],
      class: 'activity-booking__seating-type',
      showErrorText: true,
      isTranslate: false,
      autoCompleteWithId: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      isChipsEnabled: true,
      multiChipValues: [],
      returnFunction: this.clearFormField.bind(this, 'salescontact'),
    }];
    this.memberSearchButton = {
      type: buttonTypes.actionPrimarySmall,
      label: 'searchText',
      disbaledproperity: false,
      customclass: 'action-bar__add-class-btn'
    }
    this.partyService.bookingSize = (this.data && this.data.Size) ? this.data.Size : 1;
    //this.bookingSizeConfig[0].options = this.calculatePartySize();
    
    if ((!this.data) || (this.data && this.data.addOpenBooking)) {
      // Set intial value here
    }
    this.subscriptions.add(this.partyService.tabChangeEvent$.subscribe(value => {
      let components =  [ComponentTypes.EditActivityBooking , ComponentTypes.AddActivityBooking , ComponentTypes.AddOpenBooking , ComponentTypes.EditOpenBooking , ComponentTypes.AddPrivateLessonBooking , ComponentTypes.EditPrivateLessonBooking];
      if (value?.index === 1 && components.includes(value?.from) ) {
        this.setBookingData();
        if (this.partyService.selectedGuest && this.partyService.selectedGuest.MembershipType) {
          setTimeout(() => {
            this.updateFormValidity();
          }, 100)
        } else {
          this.updateFormValidity();
        }
        this.ps.previousButtonEnabled$.next(false);
      }
      else if (value?.index === 0) {
        this.updateFormValidity();
      }
    }));
    if (this.data && !this.data.addOpenBooking && (this.partyService.selectedParty$.value?.Id || this.data.isCartEdit)) {
      this.partyService.isEditData = true;
    }
    // if (this.data) {
    //   this.partyService.addOrRemoveAddOn(null);
    // }
    this.partyService.selectedSpecialMealId = -1;

    if (this.partyService.BookingOpenedFromSidePanel && this.partyService.selectedTableLocationId !== null && this.partyService.selectedTableLocationId >= 0) {
      this.locationConfig[0].value = this.partyService.selectedTableLocationId;
    }

    if (!this.partyService.reservationFormGroup?.value?.selectedTable?.length) {
      this.partyService.reservationFormGroup.get('selectedTable').setValue([this.locationConfig[0].value]);
    }

    this.subscriptions.add(this.partyService.tableSelected.subscribe(val => {
      this.selectedLocationIds = val[0];
    }));


    this.editCheckedInReservation = this.df.checkEditCheckedInReservationsConfig();
  }
  updateCoverType(value) {
    if (value > this.maxSizeAllowed) {
      this.maxSizeAllowed = value;
      this.generatePartySizeArray();
    } else {
      this.maxSizeAllowed = 60;
    }
  }


  getCoverTypeConfig(): FieldConfig[] {
    return this.coverTypes.map(coverType => {
      return {
        type: 'autocomplete',
        label: coverType.Name,
        name: coverType.Name,
        value: (this.data && this.data.CoverTypeQuantities && this.data.CoverTypeQuantities.filter(ct => ct.CoverTypeId == coverType.Id && ct.Covers > 0).length > 0) ? this.bookingSizeArray[this.data.CoverTypeQuantities.filter(ct => ct.CoverTypeId == coverType.Id)[0].Covers - 1].id : 0,
        options: this.bookingSizeArray,
        class: 'open-booking__cover-type-item',
        showErrorText: true,
        isTranslate: false,
        validation: [Validators.required],
        toolTipEnabled: true,
        inputType: 'number'
      } as FieldConfig;
    })
  }
  getBookingTypeConfig(): FieldConfig[] {
    return this.bookingTypes.map(bookingType => {
      return {
        type: 'autocomplete',
        label: bookingType.Name,
        name: bookingType.Name,
        value: (this.data && this.data.BookingTypeQuantities && this.data.BookingTypeQuantities.filter(ct => ct.BookingTypeId == bookingType.Id && ct.BookingTypes > 0).length > 0) ? this.bookingSizeArray[this.data.BookingTypeQuantities.filter(ct => ct.BookingTypeId == bookingType.Id)[0].BookingTypes - 1].id : 0,
        options: [{ id: 0, value: this.ts.instant("0") }, ...this.bookingSizeArray],
        class: 'open-booking__cover-type-item',
        showErrorText: true,
        isTranslate: false,
        validation: [Validators.required],
        toolTipEnabled: true,
        inputType: 'number'
      } as FieldConfig;
    })
  }

  loadLocations() {
    this.floorPlan = this._layout.FloorPlans;
    let standAloneTables = this.floorPlan.filter(x => x.IsDefault == true)[0].StandaloneTables;
    standAloneTables = _.orderBy(standAloneTables, ['Name'], ['asc'])
    standAloneTables = this.excludeLocationGroupPipe.transform(standAloneTables);
    standAloneTables.forEach(table => {
      if (table.IsAvailableForReservations && !table.IsTemplate) {
        this.locationList.push({ id: table.Id, value: table.Name });
      }
    });
  }

  ngAfterViewInit() {
    this.formChangeSubscribe();
    var [fromDate, , location, , ,] = this.components;
    if (this.data && (this.data.ReservedFor || this.data.addOpenBooking || this.data.isCartEdit) || (!this.partyService.isEditData && location.form?.get("location").value)) {
      this.getSlotsByLocation();
    }
    if (this.showSalesContact) {
      this.salesContactUsers();
    }
    if (this.data && this.data.Contact) {
      this.partyService.openBookingDataSaveObj = null;
      this.partyService.openBookingData = null;
      if (this.data.Contact?.TrackMembershipNumber && this.data.Contact.MemberType) {
        this.hasMembershipValue = true;
        this.partyService.selectedGuest = this.data.Contact;
        var memberType = this._settings.AllocationType.find(allocation => allocation.AllocationName === this.data.Contact.MemberType);
        this.partyService.selectedGuest.memberShipId = this.data.TrackMembershipNumber;
        this.partyService.selectedGuest.MembershipType = memberType;
        this.partyService.selectedGuest.MemberActive = this.data.Contact.IsMemberActive;
       // this.CheckMemberShip(this.data.Contact.TrackMembershipNumber, this.data.Contact);
      } else if (this.data.Contact?.TrackMembershipNumber) {
        this.CheckMemberShip(this.data.Contact.TrackMembershipNumber, this.data.Contact);
      } else {
        this.partyService.selectedGuest = this.data.Contact;
      }
    }
    // this.partyService.addOrRemoveAddOn(null);
    this.updateFormValidity();
  }

  onSlotUpdate(slot) {
    this.partyService.selectedSlot$.next([slot]);
    this.updateFormValidity();
  }

  getSlotsByLocation() {
    var [fromDate, , location, , ,] = this.components;
    var date = moment(fromDate.form.get("fromDate").value).format("YYYY-MM-DD");
    if (location.form.get("location").value) {
      this.partyService.selectedOpenBookingSlots = {};

      this.partyService.slots_holder = [];
      this.displaySlots = false;

      let _lockIdsToIgnore:number[] = [];
      if(this.data?.CartId){
        let _slotValues: any[] = this.data.bookingRatePlan.SlotLockResultDTO;
        _lockIdsToIgnore = _slotValues && Object.values(_slotValues)?.length && Object.values(_slotValues).filter((({SlotLockIdDTO}) => SlotLockIdDTO?.Id))?.length ? Object.values(_slotValues).map((({SlotLockIdDTO}) => SlotLockIdDTO.Id)) : [];
      }

      this.subscriptions.add(this.partyService.getSlotsForTable([location.form.get("location").value], null, null, this.data ? this.data.Id : null, date, _lockIdsToIgnore).subscribe(response => {
        this.slotResponse = response;
        this.partyService.selectedOpenBookingSlots = {};
        this.partyService.slots_holder = [];
        this.setSlots(response);
        setTimeout(() => {
          this.partyService.slotAvailabilitySubject$.next(response);
          this.partyService.partySlots$.next(true);
        }, 100);

      }));
    }
  }


  setSlots(response, partySize?) {

    let overAllAddons = Utilities.getAddons(this.cs.settings.value?.Addons, null);
    let Addons = Utilities.getMandatoryAddons(this.cs.settings.value?.Addons, null);
    this.allocationCodes = [];
    response.Payload.forEach(element => {
      element.OpenHours.forEach(openHour => {
        var tableId = openHour.SlotsByTableId[0].TableId;
        let isStandbyAvailable = openHour.SlotsByTableId[0].Slots.filter(x => x['IsStandByBookingSlot']) && openHour.SlotsByTableId[0].Slots.filter(x => x['IsStandByBookingSlot']).length > 0
        if (this._settings.PropertySetting && this._settings.PropertySetting[0]?.AllowStandby && !isStandbyAvailable) {
          let standBySlots = this.isOverBookSlot(openHour.SlotsByTableId[0].TableId, openHour.Reservations, openHour.SlotsByTableId[0].Slots);
          let removeStandbySlots = openHour.SlotsByTableId[0].Slots.filter(openHourSlot => !standBySlots[openHourSlot.DateTime]);
          removeStandbySlots.push(...Object.values(standBySlots));
          // removeStandbySlots.forEach((_rSlotData,index) =>{
          //   if(standBySlots[_rSlotData.DateTime]){
          //     removeStandbySlots[index].IsStandByBookingSlot = standBySlots[_rSlotData.DateTime].IsStandByBookingSlot;
          //     removeStandbySlots[index].AddOnAvailability = standBySlots[_rSlotData.DateTime].AddOnAvailability;
          //   }
          // })
        //  removeStandbySlots.push(...standBySlots)
          openHour.SlotsByTableId[0].Slots = removeStandbySlots;
        }
        openHour.SlotsByTableId[0].Slots.sort((a, b) => Utilities.parseDateString(a.DateTime).getTime() - Utilities.parseDateString(b.DateTime).getTime());
        openHour.SlotsByTableId[0].Slots.forEach(slot => {
          slot.IsOverbookAvailable = (this.data?.OverBooked && slot.DateTime == this.data?.ReservedFor) || ( this.partyService.reservationType === ReservationType.OpenBooking && this.cs.settings.value.PropertySetting[0].AllowOverbook && this.appService.hasPermission(RolesAndPermissionsType.Overbook) && (slot.PartyId  || slot.IsStandByBookingSlot));
          if(this.data && slot.IsOverbookAvailable && this.data.Id){
            slot.IsOverbookAvailable = this.data.Id != slot.PartyId
          }
          if (slot.IsOverbookAvailable || !slot.PartyId || (this.data && slot.PartyId && this.data.Id == slot.PartyId)) {
            if (slot.AllocationTypeId && this._settings.AllocationType.find(type => type.Id === slot.AllocationTypeId)['AllocationPropertyMappings'][0]) {
              var { Color: { R, G, B, A } } = this._settings.AllocationType.find(type => type.Id === slot.AllocationTypeId)['AllocationPropertyMappings'][0];
              var AllocationTypeName = this._settings.AllocationType.find(type => type.Id === slot.AllocationTypeId).AllocationName
              slot['color'] = `rgba(${R}, ${G}, ${B}, ${A})`;
              let allocationDetail = {} as AllocationTypeDTO;
              allocationDetail.AllocationName = AllocationTypeName;
              allocationDetail.color = slot['color'];
              this.allocationCodes.push(allocationDetail);
              slot['AllocationName'] = AllocationTypeName;
              //let isAnyMember = this._settings.AllocationType.find(type => type.Id === slot.AllocationTypeId);
              let isAnyMember = Utilities.IsMember(slot);
              //let AnyMember = isAnyMember.AllocationName == 'AnyMembers';              
            }
            this.partyService.SlotIds += 1;
            slot.Id = slot.Id == 0 ? this.partyService.SlotIds : slot.Id;
            var bookingSize = this.getCoverTypesize();
            if ((Utilities.filterSlotsbyAddon(this.cs.settings.value?.Addons, overAllAddons, Addons, slot.AddOnAvailability, bookingSize, -1) || (this.data && this.data.Id == slot.PartyId) || slot.IsOverbookAvailable)) {
              if (this.df.checkBookPastDaysReservationsConfig() || (this.df.checkEditCheckedInReservationsConfig() && this.partyService.selectedParty$.value?.State == PartyState.Seated)) {
                this.partyService.slots_holder.push(slot);
              }
              else {
                if (this.isNotPastTimeSlot(slot)) {
                  this.partyService.slots_holder.push(slot);
                }
              }
            }

            let selectedSlot = null;
            if (this.data?.Slots) {
              selectedSlot = this.data.Slots.find(x => x.Time === slot.DateTime);
            }
            if (this.data && slot.PartyId && this.data.TableIds?.length && this.data.TableIds[0] == tableId && this.data.Id == slot.PartyId) {
              if ((this.isNotPastTimeSlot(slot) && !this.df.checkBookPastDaysReservationsConfig()) || this.df.checkBookPastDaysReservationsConfig() || (this.df.checkEditCheckedInReservationsConfig() && this.partyService.selectedParty$.value?.State == PartyState.Seated)) {
                this.partyService.reservationFormGroup.value.selectedTime = slot.DateTime;
                this.data.SelectedSlot = slot.DateTime;
                this.partyService.selectedOpenBookingSlots[slot.Id] = !this.partyService.selectedOpenBookingSlots[slot.Id];
              }
              if (!this.data.Duration) {
                this.data.Duration = Utilities.diffBetweenDatesInMinutes(this.data.DepartureTime, this.data.SeatingTime);
              }
              if (this.data.Duration > this._settings.General.TimeSlotUnitInMinutes) {

                var duration = this.data.Duration - this._settings.General.TimeSlotUnitInMinutes;
                for (var i = 1; i <= duration / this._settings.General.TimeSlotUnitInMinutes; i++) {
                  var newSlot = this.partyService.generateSlot(Utilities.Date(slot.DateTime).add(i * this._settings.General.TimeSlotUnitInMinutes, 'minutes'));

                  newSlot.PartyId = slot.PartyId;
                  if (newSlot.Id == 0) {
                    this.partyService.SlotIds += 1;
                    newSlot.Id = this.partyService.SlotIds;
                  }
                  this.partyService.slots_holder.push(newSlot);
                  this.partyService.selectedOpenBookingSlots[newSlot.Id] = true;
                }
                this.setBookingData();
                this.onSlotUpdate(slot);
              }
              this.updateFormValidity();
            } else if (selectedSlot) {
              this.partyService.selectedOpenBookingSlots[slot.Id] = !this.partyService.selectedOpenBookingSlots[slot.Id];
              if (selectedSlot.Duration > this._settings.General.TimeSlotUnitInMinutes) {
                var duration = selectedSlot.Duration - this._settings.General.TimeSlotUnitInMinutes;
                for (var i = 1; i <= duration / this._settings.General.TimeSlotUnitInMinutes; i++) {
                  var newSlot = this.partyService.generateSlot(Utilities.Date(slot.DateTime).add(i * this._settings.General.TimeSlotUnitInMinutes, 'minutes'));
                  newSlot.PartyId = slot.PartyId;
                  if (newSlot.Id == 0) {
                    this.partyService.SlotIds += 1;
                    newSlot.Id = this.partyService.SlotIds;
                  }
                  this.partyService.slots_holder.push(newSlot);
                  this.partyService.selectedOpenBookingSlots[newSlot.Id] = true;
                }
              }
              if((this.data.isCartEdit && this.data.bookingRatePlan?.BookingBehavior === BookingBehavior.OpenBooking)){
                this.partyService.selectedSlot$.next([slot])
              }
              this.updateFormValidity();
            }
          }
        });
        var openhrSlots = openHour.SlotsByTableId[0].Slots;
        openhrSlots.forEach(slot => {
          if (this.data?.Slots) {
            var selectedSlot = this.data.Slots.find(x => x.Time === slot.DateTime);
            if (selectedSlot && selectedSlot.DurationInMinutes > this._settings.General.TimeSlotUnitInMinutes) {
              var duration = selectedSlot.DurationInMinutes - this._settings.General.TimeSlotUnitInMinutes;
              for (var i = 1; i <= duration / this._settings.General.TimeSlotUnitInMinutes; i++) {
                var existingslot = openhrSlots.find(x => Utilities.Date(x.DateTime).isSame(Utilities.Date(slot.DateTime).add(i * this._settings.General.TimeSlotUnitInMinutes, 'minutes')));
                if (existingslot) this.partyService.selectedOpenBookingSlots[existingslot.Id] = true;
              }
            }
          }
        });
        this.partyService.slots_holder = _.unionBy(this.partyService.slots_holder, "DateTime")
        this.updateFormValidity();
      });
    });

    this.allocationCodes = _.uniqBy(this.allocationCodes, 'AllocationName');
    if (this.data && (this.data.addOpenBooking || this.data.isOpenBooking)) {
      const slot = this.partyService.slots_holder.filter(slot => slot.DateTime === this.data.SelectedSlot)[0];
      this.partyService.selectedSlot$.next(this.partyService.slots_holder.filter(slot => slot.DateTime === this.data.SelectedSlot))
      const hostRoleType = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`))?.HostRoleType;
      if (slot?.Id && (hostRoleType?.Name === RoleType[RoleType.Manager] || !(slot?.AllocationName && !(this.data?.Id)))) {
        this.partyService.selectedOpenBookingSlots[slot.Id] = true;
      }
        this.updateFormValidity();
    }
    this.displaySlots = true;
    if (this.data && this.data.State == PartyState.Seated && !this._settings.PropertySetting[0]?.EditCheckedInReservations) {
      this.checkInReservation();
    }
    if (this.data && this.data.State == PartyState.Seated && this.editCheckedInReservation) {
      this.partyService.slots_holder.map(items => {
        items.IsDisabled = true;
      })
    }
  }
  isOverBookSlot(tableId, Reservations, slots) {
    let overBookSlot = {};
    Reservations.forEach(reservation => {
      if (!(slots.find(slot => slot.DateTime) == reservation.ReservedFor) && reservation.TableIds[0] == tableId && (!this.data || this.data?.ReservedFor !== reservation.ReservedFor || this.data.Type == PartyType.StandBy)) {
        var diff = (new Date(reservation.ReservedFor).getTime() - new Date(reservation.DepartureTime).getTime()) / 1000;
        diff /= 60;
        let timeDiff = Math.abs(Math.round(diff));
        if (timeDiff == this.timeSlotUnitInMinutes) {
          let newSlot = Object.assign({}, slots[0]);
          newSlot.IsStandByBookingSlot = true;
          newSlot.DateTime = reservation.ReservedFor
          newSlot.Id = 0;
          if (this.isNotPastTimeSlot(newSlot)) {
            if (this.data && this.data.WishedTime == newSlot.DateTime && this.data.Type == PartyType.StandBy) {
              newSlot.PartyId = this.data.Id
            }
            let Addon = this.partyService.getAddonAvailability(this.cs.settings.value?.Addons, null);
            newSlot.AddOnAvailability = Addon;
            overBookSlot[newSlot.DateTime] = newSlot;
          }
        } else {
          for (let i = 0; i < timeDiff / this.timeSlotUnitInMinutes; i++) {
            let newSlot = Object.assign({}, slots[0]);
            // if (i == 1) {
            //   newSlot.DateTime = reservation.ReservedFor;
            // } else {
              let slotDate = new Date(new Date(reservation.ReservedFor).getTime() + this.timeSlotUnitInMinutes * i * 60000);
              newSlot.DateTime = moment(slotDate).format('YYYY-MM-DDTHH:mm:ss');
            newSlot.IsStandByBookingSlot = true;
            newSlot.Id = 0;
            if (this.isNotPastTimeSlot(newSlot)) {
              if (this.data && this.data.WishedTime == newSlot.DateTime && this.data.Type == PartyType.StandBy) {
                newSlot.PartyId = this.data.Id
              }
              let Addon = this.partyService.getAddonAvailability(this.cs.settings.value?.Addons, null);
              newSlot.AddOnAvailability = Addon;
              overBookSlot[newSlot.DateTime] = newSlot;
            }
          }
        }
      }
    })
    return overBookSlot;
  }
  checkInReservation() {
    this.ps.formValid$.next(
      {
        isFormValid: true,
        currentTab: 0,
        gotoNextTab: true,
        tabsToNavigateCount: this.df.checkEditCheckedInReservationsConfig() ? 0 : 1,
        disablePrevious: true
      } as IFormValidDetails
    );
    setTimeout(() => {
      this.ps.previousButtonEnabled$.next(true);
    }, 500);
  }
  isNotPastTimeSlot(slot): boolean {
    const maxPastTime = this._settings.General.MaxPastTimeForReservationsInMinutes;
    const currentTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    let minutes = this.cs.settings.value.PropertySetting[0].ShowOngoingSlotsTill ? (this.cs.settings.value.PropertySetting[0].ShowOngoingSlotsTill * 1000) : 60;
    const past1hr = new Date(currentTime.valueOf() - (maxPastTime * minutes));
    const slotTime = Utilities.parseDateString(slot.DateTime);
    return slotTime >= past1hr;
  }

  generatePartySizeArray() {
    this.bookingSizeArray = [];
    for (let i = 1; i <= this.maxSizeAllowed; i++) {
      this.bookingSizeArray.push({ id: i, value: i.toString() });
    }
    // this.bookingSizeArray.push({ id: this.maxSizeAllowed, value: this.maxSizeAllowed.toString() + "+" });

    return this.bookingSizeArray;
  }

  formChangeSubscribe() {
    this.components.forEach((x, index) => {
      this.subscriptions.add(x.form?.valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(value => {
          if (value.bookingSize) {
            this.updateCoverType(value.bookingSize)
            this.partyService.bookingSize = value.bookingSize;
            this.partyService.slots_holder = [];
            this.partyService.selectedSlot$.next(this.partyService.selectedSlot$.value);
            this.setSlots(this.slotResponse);
            setTimeout(() => {
              this.partyService.slotAvailabilitySubject$.next(this.slotResponse);
              this.partyService.partySlots$.next(true);
            }, 100);
          }
          if (value.fromDate) {
            this.components["_results"][1].form.controls.toDate.setValue(value.fromDate)
          }
          if ((value.location && this.locationList.find(element => element.id == value.location)) || value.fromDate) {
            if (this.partyService.isSlotLocked) {
              if ((this.partyService.slotLockIds && this.partyService.slotLockIds.length > 0)) {
                this.partyService.isSlotLocked = false;
                this.subscriptions.add(this.partyService.releaseMultiple(this.partyService.slotLockIds).subscribe((slot) => {
                  this.getSlotsByLocation();
                  this.partyService.slotLockIds = [];
                }));
              }
            }
            else {
              this.getSlotsByLocation();
            }
          }
          else {
            
          }
          if (this.partyService.selectedGuest?.MembershipType && (value.membershipId == "") && !this.memberShipdetail) {
            this.memberSearchClick();
          } else {
            this.updateFormValidity();
          }
        }));
    });
  }
  
  memberSearchClick() {
    let memberControl: DynamicFormComponent = this.components.last;
    let memberSearchText: string = this.getFormValue(memberControl, "membershipId");
    if (memberSearchText && memberSearchText.length) {
      this.memberSearchHandler(memberSearchText);
    }
  }

  memberSearchHandler(memberSearchText: string) {
    this.hasMembershipValue = memberSearchText.length > 0;
    this.memberShipdetail = {};
    this.partyService.selectedGuest = null;
    this.updateSelectedSlotsByMembersihps();
    this.partyService.selectedMembershipDetail$.next(false);
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.CheckMemberShip(memberSearchText, this.partyService.reservationFormGroup.value.selectedGuest ? this.partyService.reservationFormGroup.value.selectedGuest : null);
    this.updateSelectedSlotsByMembersihps();
    this.updateFormValidity();
    if (this.data && this.data.Contact && this.partyService.reservationFormGroup) {
      this.partyService.reservationFormGroup.markAsDirty();
      this.partyService.reservationFormGroup.updateValueAndValidity();
    }
  }

  CheckMemberShip(membershipId, editContactData) {
    this.memberShipArr = [];
    this.selectedMemberDetail = null;
    this.subscriptions.add(this.apiService.wildSearchEngageMember(membershipId).subscribe(response => {
      this.memberShipArr = response.Payload;
      this.MemberDetailsArray = [];
      this.memberShipArr.forEach((element, index) => {
        this.MemberDetailsArray.push({
          id: index,
          value: this.getMemberOptionValue(element, index)
        })
      });
      this.membershipDetailConfig[0].options = this.MemberDetailsArray;
      this.membershipDetailConfig[0].value = membershipId;

      if (this.MemberDetailsArray && this.MemberDetailsArray.length === 1) {
        this.components.last.form.controls['membershipId']?.setValue(this.MemberDetailsArray[0].value);
        this.setSelectedGuest();
      } else if (this.MemberDetailsArray && this.MemberDetailsArray.length > 1) {
        document.getElementById("memberDetail")?.querySelector("input")?.focus();
      }
    }));
  }

  setSelectedMembershipDetail(memberShip : EngageMemberDetailDTO, editContactData) {
    const memberShipdetail = memberShip?.ProfileInfoGetByCardId?.ProfileValue;
    var guestObject = {
      FirstName: memberShipdetail.FName,
      LastName: memberShipdetail.LName,
      EmailAddress: memberShipdetail?.EmailList[0]?.EmailId,
      PhoneNumber: memberShipdetail?.PhoneList[0]?.PhoneNumber,
      CountryId: this.partyService.getCountryId(memberShipdetail?.PhoneList[0]?.PhoneCode),
      PIILastUsedDate: null,
      Birthday: memberShipdetail.Dob,
      ContactCustomFields: [],
      Notes: [],
      VisitStats: [],
      MarketingOptedIn: null,
      memberShipId: memberShipdetail.CardNo,
      MembershipType: memberShipdetail.MembershipType,
      GolfPoints : memberShip.GolfPoints,
      RateType: memberShipdetail.RateType,
      MemebershipTypeFromEngage: memberShipdetail.MembershipType,
      MemberRateTypeAfterRoundsUsed : memberShipdetail.RateTypeAfterRoundsUsed,
      MemberRateTypeMembershipExpiry : memberShipdetail.RateTypeMembershipExpiry,
      MemberActive: !memberShipdetail.IsMembershipExpired,
      MemberAllocationTypesIds : []
    }
    if (editContactData) {
      editContactData.memberShipId = memberShipdetail.CardNo;
      editContactData.MembershipType = memberShipdetail.MembershipType;
      editContactData.RateType = memberShipdetail.RateType;
      editContactData.MemberActive = memberShipdetail.MembershipStatus && memberShipdetail.MembershipStatus?.toLowerCase() === "active"
    }
    this.partyService.selectedGuest = editContactData ? editContactData : guestObject;
    if (this._settings.General.UseMembershipNumber) {
      this.partyService.selectedGuest.TrackMembershipNumber = memberShipdetail.CardNo; // to bind it in the guest form
    }
    const allocationTypes = this._settings.AllocationType
                           .filter(allocation => allocation.AllocationName === guestObject.RateType ||
                                                allocation.AllocationName === guestObject.MemberRateTypeAfterRoundsUsed ||
                                                allocation.AllocationName === guestObject.MemberRateTypeMembershipExpiry);
    if(this.partyService.selectedGuest && allocationTypes){
      this.partyService.selectedGuest.MemberAllocationTypesIds =  allocationTypes.map(a=>a.Id);
    }

    this.partyService.selectedMembershipDetail$.next(true);
    this.updateFormValidity();
  }

  updateSelectedSlotsByMembersihps() {
    Object.entries(this.partyService.selectedOpenBookingSlots).forEach((itm => {
      if (itm[1]) {
        const allocationTypeId = this.partyService.slots_holder.find(slot_holder => slot_holder.Id == itm[0]).AllocationTypeId;
        if (allocationTypeId && (
          !this.partyService.selectedGuest || !this.partyService.selectedGuest.MembershipType || !this.partyService.selectedGuest.MemberActive || allocationTypeId !== this.partyService.selectedGuest.MembershipType?.Id)) {
          this.partyService.selectedOpenBookingSlots[itm[0]] = false;
        }
      }

    }))
  }

  cellClick(event) {
    this.selectedBookingSize = event.option.value;
  }

  isFormValid() {
    var isValid = true;
    this.components?.forEach(componet => {
      if (!componet.form.valid && componet.form.status !== "DISABLED") {
        isValid = false;
      }
    })
    return isValid && this.sizeMapping?.valid;
  }

  isSelectedSlotsValid() {
    let standByBooking = 0;
    let otherBooking = 0;
    let overBookAvailable = 0;
    if (this.partyService.slots_holder?.length > 0) {
      Object.keys(this.partyService.selectedOpenBookingSlots).forEach(key => {
        let value = this.partyService.selectedOpenBookingSlots[key];
        if (value && this.partyService.slots_holder.find(item => item.Id == key)?.IsStandByBookingSlot) {
          standByBooking = standByBooking + 1;
        } 
        else if (value && this.partyService.slots_holder.find(item => item.Id == key)?.IsOverbookAvailable) {
          overBookAvailable = overBookAvailable + 1;
        } 
        else if (value && this.partyService.slots_holder.find(item => item.Id == key)) {
          otherBooking = otherBooking + 1;
        }
      });
      return (standByBooking == 0 && overBookAvailable == 0) ? otherBooking > 0 : ((standByBooking == 1 || overBookAvailable == 1) && otherBooking == 0)
    } else {
      return false;
    }

  }

  isValidSlotSelectedByType(): boolean{
    const alocationTypesSelected = this.getSlotsObj().map(({AllocationTypeId}) => AllocationTypeId)
    return Utilities.validMemberTypesSelected(this._settings.AllocationType, alocationTypesSelected)
  }

  updateFormValidity() {
    if(this.ps.tabsActionData?.length > 0) {
    this.ps.tabsActionData[0].gotoNextTab = this.isFormValid() && this.isValidBookingSizeSelected() && this.isSelectedSlotsValid() && this.isValidSlotSelectedByType();
    this.ps.tabsActions$.next(this.ps.tabsActionData);
    }
  }

  getFormComponent(...args) {
    return args.map(arg => this.components.find(form => form.value[arg]))
  }

  
  getCoverTypesize(): number {
    return this.sizeMapping.sizes.reduce((res, curr) => res + (+curr.Covers), 0);
  }

  isValidBookingSizeSelected(){
    return this.sizeMapping.valid;
  }

  clearmembershipDetail(event) {
    const [memberdetail] = this.getFormComponent('membershipId');
    memberdetail.form.controls.membershipId.setValue('');
  }

  setBookingData() {
    this.partyService.openBookingData = null;
    var [fromDate, toDate, location, bookingSize, bookingTypes, covers, memberShipDetails] = this.components;
    let bookingSizeValue = this.sizeMapping.sizes.reduce((res, curr) => +curr.Covers + res, 0);
    this.partyService.bookingSize = bookingSizeValue;
    this.partyService.openBookingData = new OpenBookingDTO(
      this.data?.Id || null,
      this.getFormValue(fromDate, "fromDate"),
      this.getFormValue(toDate, "toDate"),
      [this.getFormValue(location, "location")],
      this.getLocationName(this.getFormValue(location, "location")),
      bookingSizeValue,
      this.getFormValue(this.salesContactForm, "salescontacts") || [],
      this.getFormValue(memberShipDetails, "memberShipDetails"),
      this.sizeMapping.sizes,
      this.getBookingTypeValues(bookingTypes),
      this.getSlotsObj(),
      BookingBehavior.OpenBooking,
      null,
      null,null, null
    );

    if(this.data?.PageMethod){
      this.partyService.openBookingData.PageMethod = this.data.PageMethod;
    }
    this.partyService.openBookingData.OverBooked = this.partyService.isOverBookSlotSelected ? bookingSizeValue : 0;
    this.partyService.openBookingData.IsOverBook = this.partyService.isOverBookSlotSelected;

  }

  getLocationName(locationId) {
    let location = this.locationList?.find(location => location.id == locationId)?.value || null;
    return location ? [location] : null;
  }

  setMemberDetailsArray() {
    this.MemberDetailsArray = [];
    this.memberShipArr.forEach((element, index) => {
      this.MemberDetailsArray.push({
        id: index,
      })
    });
    this.membershipDetailConfig[0].options = this.MemberDetailsArray;
  }

  setSelectedGuest() {
    const [memberdetail] = this.getFormComponent('membershipId');
    if(memberdetail){
    const seectedGuestValue = memberdetail.form.controls.membershipId.value;
    let selectedIndex = Number(seectedGuestValue.substring(0, 1));
    memberdetail.form.controls.membershipId.setValue('');
    this.hasMembershipValue = false;
    this.memberShipdetail = this.memberShipArr[selectedIndex - 1];
    let engageIntegrationData = this._settings.General.EngageIntegrationDTO;
    let obj: EngageMemberByCardIdRequestDTO = new EngageMemberByCardIdRequestDTO(this.memberShipdetail.CardNo, engageIntegrationData?.TenantId, engageIntegrationData?.SiteId);
    this.apiService.getEngageMemberByCardId(obj).subscribe((memberDetailResponse: EngageMemberDetailDTO) => {
      this.selectedMemberDetail = memberDetailResponse;
      this.membershipDetailConfig[0].options = [];
      this.setSelectedMembershipDetail(memberDetailResponse, this.data ? this.data.Contact : null)
      this.df.scrollTop("memberInformation");
    })
  }
  }

  getMemberOptionValue(data, index) {
    return '' + (index + 1) + ' ' + data.FName + ' - ' + data.CardNo + ' - ' + data.Email + ' - ' + data.PhoneNumber
  }


  calculateEndTime(startTimeStr, duration) {
    var startTime = new Date(startTimeStr);
    var startMins = startTime.getMinutes();
    startTime.setMinutes(startMins + duration);
    return startTime;
  }

  getFormValue(formObj, fieldName) {
    if (!formObj) {
      return formObj;
    }
    return formObj.form.get(fieldName)?.value;
  }

  getCoverTypeValues(covers) {
    return this.coverTypes.map(coverType => {
      return { Covers: this.getFormValue(covers, coverType.Name), CoverTypeId: coverType.Id, CoverTypeName: coverType.Name }
    })
  }

  getBookingTypeValues(bookingTypes) {
    return this.bookingTypes.map(bookingType => {
      return { BookingTypes: this.getFormValue(bookingTypes, bookingType.Name), BookingTypeId: bookingType.Id, BookingTypeName: bookingType.Name }
    })
  }

  getSlotsObj() {
    let { selectedOpenBookingSlots, slots_holder } = this.partyService;
    let resSlots = [];
    let slots = Object.entries(selectedOpenBookingSlots)
      .filter(arr => arr[1]);
    for (let slotI = 0; slotI < slots.length; slotI++) {
      let itm = slots[slotI];
      let currentSlotIndex = slots_holder.findIndex(slot_holder => slot_holder.Id == itm[0]);
      if (currentSlotIndex > -1) {
        let slot = slots_holder[currentSlotIndex];
        let resSlot = {
          Time: slot.DateTime,
          DurationInMinutes: this.timeSlotUnitInMinutes,
          EndTime: this.calculateEndTime(slots_holder[currentSlotIndex].DateTime, this.timeSlotUnitInMinutes),
          PartyId: (slot.PartyId && this.data) ? slot.PartyId : null,
          AllocationTypeId: slot.AllocationTypeId
        }
        for (let i = (currentSlotIndex + 1); slots_holder[i]; i++) {
          let slotDateTime = new Date(slots_holder[i].DateTime);
          let defaultDateTime = new Date(slots_holder[currentSlotIndex].DateTime);
          let mins: any = (slotDateTime.getTime() - defaultDateTime.getTime()) / (60 * 1000);
          if (slots.find(slot => slot[1] && +slot[0] == slots_holder[i].Id && mins <= resSlot.DurationInMinutes)) {
            resSlot.DurationInMinutes += this.timeSlotUnitInMinutes;
            slotI += 1;
          } else {
            break;
          }
        }
        resSlot.EndTime = this.calculateEndTime(resSlot.Time, resSlot.DurationInMinutes)
        resSlots.push(resSlot);
      }
    }
    return resSlots;
  }

  clearFormField(name, event) {
    var [formField] = this.getFormComponent(name);
    formField.form.controls[`${name}`].setValue("");
  }

  resetOnOpenBookingTerminate() {
    this.partyService.selectedMembershipDetail$.next(false);
    if (!this.partyService.reservationFromGuestBook) {
      this.partyService.selectedGuest = null;
    }
    this.partyService.selectedOpenBookingSlots = {};
    this.partyService.openBookingData = null;
    this.partyService.slots_holder = [];
    this.partyService.SlotIds = 0;
    //this.partyService.openBookingDataSaveObj = null;
    this.partyService.isEditData = false;
  }
  selectedTable(selectedTableIds?) {
    let cancelledActionSubscription: ISubscription = null;
    let confirmActionSubscription: ISubscription = null;
    var [fromDate, , location, , ,] = this.components;
    var date = moment(fromDate.form.get("fromDate").value).format("YYYY-MM-DD");
    this.partyService.reservationFormGroup.get('selectedTable').setValue([this.locationConfig[0].value]);
    const componentDetails: ComponentDetails = {
      componentName: TableSelectionComponent,
      dimensionType: 'large',
      popupType: 'active',

      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: {
        TableIds: [this.locationConfig[0].value],
        date: date
      }
    };
    const moveTabledialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      panelClass: 'preferred-table-panel',
      data: {
        title: 'Select Table',
        update: 'Ok',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.table,
        back: false,
        standalone: true,
        selectionView: true
      }
    });
    confirmActionSubscription = this.ps.confirmedAction$.subscribe(async val => {
      if (val === ComponentTypes.table) {
        if (String(this.selectedLocationIds)?.trim()?.length) {
          this.locationConfig[0].value = this.selectedLocationIds;
          this.setLocationFormValue();
        }
      }
    });

    cancelledActionSubscription = this.ps.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.table) {
        // this.setLocationFormValue(); 
      }
    });

    this.subscriptions.add(moveTabledialogRef.afterClosed().subscribe(event => {
      if (confirmActionSubscription) { confirmActionSubscription.unsubscribe(); }
      if (cancelledActionSubscription) { cancelledActionSubscription.unsubscribe(); }

    }));

  }

  setLocationFormValue() {
    const [location] = this.getFormComponent('location');
    location.form.controls.location.setValue(this.locationConfig[0].value);
  }

  salesContactUsers() {
    let salesContact = this.df.getSalesContractList(this.data);
    this.salesContactConfig[0].options = salesContact.contacts
    this.salesContactConfig[0].multiChipValues = salesContact.multiChip;
   if (this.data?.SalesContactIds) {
      this.salesContactForm?.form.get('salescontacts').setValue(this.data?.SalesContactIds);
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.resetOnOpenBookingTerminate();
  }
}
