import { Component, OnInit } from '@angular/core';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { menuTypes } from '../retail.modals';
import { TemplateDataService } from '../shared/service/data- services/template.data.service';


@Component({
  selector: 'app-letter-setup',
  templateUrl: './letter-setup.component.html',
  styleUrls: ['./letter-setup.component.scss'],
  providers: [TemplateDataService]
})
export class LetterSetupComponent implements OnInit {
  captions: any;
  relativePath: string;
  menuList: any;
  menuType = menuTypes;

  constructor(private routeDataService: RouteLoaderService) { 
    const value = this.routeDataService.GetChildMenu('/settings/retailsetup/lettersetup');
    this.menuList = {
      menu: value.linkedElement,
      menuType : menuTypes.lowerLevel
    };
  }

  ngOnInit() {

  }

}
