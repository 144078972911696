<app-dialog-header [title]="title" (closeDialog)="close()"></app-dialog-header>
<div class="ag_w--70 ag_p--5">
  <app-simple-search class='ag_w--100' [placeholder]="captions.lbl_viewAllItineraries_SearchBy"
  (searchChange)="searchValueChange($event)">
  </app-simple-search>
</div>

<div class="view-all-itinery-wrapper ag_mt--3 ag_container--padding-md">
   <div class="table-wrpper">
    <table>
        <tr>
          <th>{{captions.lbl_cartConfNo}}</th>
          <th>{{captions.lbl_username}}</th>
          <th>{{captions.lbl_createdOn}}</th>
          <th>{{captions.lbl_GuestFname}}</th>
          <th>{{captions.lbl_GuestLname}}</th>
          <th>{{captions.lbl_ArrivalDate}}</th>
          <th>{{captions.lbl_NoOfNights}}</th>
          <th>{{captions.lbl_action}}</th>
        </tr>
        <ng-container *ngIf="tableData.length > 0; else noData">
          <tr class="ag_cursor--pointer" *ngFor="let x of tableData">
            <td><span>{{x.cartConfNo}}</span></td>
            <td><span>{{x.userName}}</span></td>
            <td><span>{{x.createdOn}}</span></td>
            <td><span>{{x.guestfName}}</span></td>
            <td><span>{{x.guestlName}}</span></td>
            <td><span>{{x.arrivalDate}}</span></td>
            <td><span>{{x.noOfNights}}</span></td>
            <td>
              <i class="icon-more ag_display--inblock ag_cursor--pointer" [matMenuTriggerFor]="menu"
              [matMenuTriggerData]="{'element': menuOption, 'obj': x.obj}"></i>
            </td>
          </tr>
        </ng-container>
       
      </table>
   </div> 
</div>
<mat-menu #menu="matMenu" class='ag-split-button-menu'>
    <ng-template let-element="element" let-obj="obj" matMenuContent>
      <button (click)="moreOptionsemiter(options, obj)" *ngFor="let options of element" mat-menu-item
        class='split-button-list' [disabled]="options.disabled">{{options.value}}</button>
    </ng-template>
  </mat-menu>


  <ng-template #noData>
    <tr>
        <td class='no-data-container' [attr.colspan]="8">
            <div>
                   <ng-container  *ngTemplateOutlet="noDataImgCredit"></ng-container>
            </div>
        </td>
    </tr>
</ng-template>

<ng-template #noDataImgCredit>
  <div class="no-img">
      <img src="./assets/images/SearchIllustration.png" alt="No Data">
      <div class="ag_mt--2">{{captions.lbl_noRecordsFound}}</div>
     </div>
</ng-template>
