<section class="external-pms-mapping__section ag_pl--6 ag_pt--6" [formGroup]="externalpmsposttypemappingform">

  <div>
    <ag-input [automationId]="'Txt_externalPMSPostTypeMapping_Transactioncode'" class='ag_display--block ag_w--60'
      formControlName='transactioncode' [placeholder]="captions.lbl_transactioncode" [maxlength]="100"></ag-input>
  </div>
  <!-- <div class="yes-col ag_ml--auto ag_display--flex ag_mt--2" >
      <app-toggle-switch (changeToggleEvent)="applyToAll($event[0])"></app-toggle-switch>
      <label class="LW12 d-block form-label-normal ag_mt--1 ag_pl--5">{{captions.lbl_applyforall}}</label>
    </div> -->
  <div class="ag_display--flex">
    <div class="">
      <label class="form-label-normal">{{postTypeCaption}}</label>
      <app-retail-toggle-switch [attr.automationId]="'Txt_externalPMSPostTypeMapping_allPaymentApply'" class="d-block"
        (changeToggleEvent)="applyForAllPaymentMethodsForTheSameOutlet($event[0])"
        formControlName='applyForAllPaymentMethodsForTheSameOutlet'>
      </app-retail-toggle-switch>
    </div>
  </div>
  <div class="ag_display--flex" *ngIf=!isFromResortFinance>
    <div class="mt-2">
      <label class="form-label-normal">{{captions.lbl_applyForOutletsForTheSamePaymentMethod}}</label>
      <app-retail-toggle-switch [attr.automationId]="'Txt_externalPMSPostTypeMapping_applyOutlets'" class="d-block"
        (changeToggleEvent)="applyForOutletsForTheSamePaymentMethod($event[0])"
        formControlName='applyForOutletsForTheSamePaymentMethod'>
      </app-retail-toggle-switch>
    </div>
  </div>
</section>

<div mat-dialog-actions class="actions-div">
  <div class="pl-4">
    <button [attr.automationId]="'Btn_externalPMSPostTypeMapping_save'"
      [ngClass]="isValid && externalpmsposttypemappingform.valid && externalpmsposttypemappingform.dirty? 'button--primary-save' : 'button--disabled'"
      mat-button [disabled]="false" (click)="saveMappingType()">{{commonCaptions.Save}}</button>
    <button class="spa-button-cancel" mat-button (click)="onClick()">{{commonCaptions.Cancel}}</button>
  </div>
</div>