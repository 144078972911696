import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'size-mapping',
    templateUrl: './size-mapping.component.html',
    styleUrls: ['./size-mapping.component.scss']
})
export class SizeMappingComponent implements OnInit {
    @Input() coverTypes: any[];
    @Input() bookingTypes: any[];
    @Input() maxSize: number = 61;
    @Input() minSize: number = 1;
    @Input() dataCovers;
    @Input() dataSize;

    @Output() sizeChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() size: {
        valid: boolean;
        sizes: any
    };


    mappedTypes: any[] = [];
    coverTypeName: {
        [key: string]: string
    } = {};
    bookingTypeName: {
        [key: string]: string
    } = {};

    sizeArr: number[] | string[] = Array.from(Array(61).keys());
    formGroup = new FormGroup({})
    errors: string[];

    constructor(private translateService: TranslateService) {

    }

    ngOnInit() {
        this.mappedTypes = [];
        let _maxSize = this.maxSize + 1;
        this.sizeArr = Array.from(Array(_maxSize).keys());
        this.setConfigTypes();
        this.sizeChange.emit({ sizes: this.mappedTypes, valid: !this.formValidate().length });
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.bookingTypes || changes.coverTypes || changes.dataCovers){
            this.mappedTypes = [];
            this.setConfigTypes();
            this.sizeChange.emit({ sizes: this.mappedTypes, valid: !this.formValidate().length });
        }
    }

    setConfigTypes(){
        let coverObject = {};
        if(this.dataCovers && this.dataCovers.length){
            let defaultCoverType = this.coverTypes && this.coverTypes.length ? this.coverTypes[0].Id : 0;
            let defaultBookingType = this.bookingTypes && this.bookingTypes.length ? this.bookingTypes[0].Id : 0;
            this.dataCovers.forEach(({CoverTypeId, BookingTypeId, Covers}) => {
                coverObject[(CoverTypeId || defaultCoverType) + "_" + (BookingTypeId || defaultBookingType)] = Covers
            });
        }
        this.bookingTypes.forEach(bookingType => {
            this.bookingTypeName[bookingType.Id] = bookingType.Name;
        })
        this.coverTypes.forEach((coverType: any) => {
            this.coverTypeName[coverType.Id] = coverType.Name;
        })

        if(!this.bookingTypes.length){
            this.bookingTypes = [{Id: 0}]
        }

        if (this.bookingTypes?.length) {
            this.bookingTypes.forEach((bookingtype, bIndex) => {
                if (this.coverTypes?.length) {
                    this.coverTypes.forEach((cover: any) => {
                        this.mappedTypes.push(this.getTypeObj(bookingtype.Id, cover.Id, bIndex === 0 ? (cover.MinimumQuantity || 0) : 0));
                    })

                    if(!this.mappedTypes.find(mappedType => mappedType.Covers)){
                        this.mappedTypes[0].Covers = this.minSize || 1;
                    }
                } else {
                    this.mappedTypes.push(this.getTypeObj(bookingtype.Id, 0, bIndex === 0 ? (this.minSize || 1) : 0));
                }
            })
        }

        if(this.dataCovers && this.dataCovers.length){
            this.mappedTypes.forEach(type => {
                type.Covers = coverObject[type.CoverTypeId + "_" + type.BookingTypeId] || 0;
            })
        }

    }

    getTypeObj(BookingTypeId, CoverTypeId, defaultSize){
        return {
            BookingTypeId: BookingTypeId,
            BookingTypeName: this.bookingTypeName[BookingTypeId],
            CoverTypeId: CoverTypeId,
            CoverTypeName: this.coverTypeName[CoverTypeId],
            Covers: defaultSize
        }
    }

    onSizeChange() {
        this.errors = this.formValidate();
        this.sizeChange.emit({ sizes: this.mappedTypes, valid: !this.errors.length });
    }

    formValidate(): string[] {
        let coverTypeQuantities = {};
        let totalQuantity = 0;
        this.mappedTypes.forEach(type => {
            if (!coverTypeQuantities[type.CoverTypeId]) {
                coverTypeQuantities[type.CoverTypeId] = 0;
            }

            coverTypeQuantities[type.CoverTypeId] += +type.Covers;
            totalQuantity += +type.Covers
        });

        let errors = [];

        if(!totalQuantity || (totalQuantity < this.minSize)){
            errors.push(this.translateService.instant('MinimumCoverQuantityText', {quantity: this.minSize, name: this.translateService.instant('cover')}))
        }else if(this.coverTypes.length){
            this.coverTypes.forEach((cover: any) => {
                if (coverTypeQuantities[cover.Id] < cover.MinimumQuantity) {
                    errors.push(this.translateService.instant('MinimumCoverQuantityText', {quantity: cover.MinimumQuantity, name: cover.Name}))
                }
            })
        }
        if(totalQuantity > this.maxSize){
            errors.push(this.translateService.instant('MaximumCoverSizeText', {quantity: this.maxSize}))
        }

        return errors
    }
}