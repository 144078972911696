import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';


@Component({
  selector: 'app-form-multi-select',
  templateUrl: './form-multi-select.component.html',
  styleUrls: ['./form-multi-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormMultiSelectComponent implements Field, OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;
  FormControlName: string;
  options = []
  allSelected = false;
  optionsFullycleared = false;
  selectedOptions = [];
  constructor() {

  }
  ngOnInit() {
    this.FormControlName = this.config.name;
    this.selectedOptions = this.getAlloptions();
    this.group.controls[this.config.name].setValue(this.getAlloptions());
  }


  reset(event) {
    if (this.group.controls[this.config.name].value == "") {
      event.target.blur();
      this.group.controls[this.config.name].setValue(this.config.multiSelectvalue)
    }
  }

  selectedOption(event) {
    if (this.config.cellClick) {
      if (this.config.multiSelectvalue && this.config.multiSelectvalue.length > 0) {
        let index = this.config.multiSelectvalue.findIndex(x => x == 0)
        if (index > -1) {
          this.config.multiSelectvalue.splice(index, 1);
          this.allSelected = false;
          this.group.controls[this.config.name].setValue(this.config.multiSelectvalue)
        }
        else {
          if (this.config.multiSelectvalue.length == this.config.options.length) {
            this.group.controls[this.config.name].setValue(this.getAlloptions());
          }
        }
      }

      this.config.cellClick(this.config.multiSelectvalue);
    }
  }

  toggleAllSelection() {
    if (this.config.cellClick) {
      if (this.allSelected) {
        this.allSelected = !this.allSelected;
        this.selectedOptions = []
        this.config.multiSelectvalue = []
        this.group.controls[this.config.name].setValue(this.selectedOptions)
      }
      else {
        this.allSelected = !this.allSelected;
        this.selectedOptions = this.getAlloptions();
        this.config.multiSelectvalue = this.getAlloptions();
        this.group.controls[this.config.name].setValue(this.selectedOptions);
      }
      this.config.cellClick(this.config.multiSelectvalue);
    }
  }

  getAlloptions(): any[] {
    let selectedArray = [];
    selectedArray.push("0");
    if (this.config.options) {
      this.allSelected = true;
      this.config.options.forEach(val => {
        selectedArray.push(val.id);
      })
    }
    return selectedArray;
  }
}
