<section id="edit-menu-container">

    <div class="header">
        <h4 [attr.literalID]="'hdr_editMenu'">Edit Literals</h4>
        <i  aria-hidden="true" class="icon-close ml--auto" (click)="Cancel()"></i>
    </div>


    <div class="edit-wrapper">
        <form [formGroup]="editMenuForm" autocomplete="off" class="container-padding">
            <span>
                <div class="first-container" *ngIf="oldPlaceholderValue&&oldPlaceholderValue!=''">
                   
                    <mat-form-field [floatLabel]="floatLabel" class="first-container">
                        <input matInput formControlName="newPlaceholderValue" placeholder="Placeholder Value"
                            required>
                        <mat-error *ngIf="editMenuForm.controls['newPlaceholderValue'].hasError('required')" [attr.literalID]="err_missingNewValue">
                            Missing new value</mat-error>
                    </mat-form-field>
                </div>
            </span>
            <br>
            <span>
                <div class="first-container" *ngIf="oldErrorMessageValue&&oldErrorMessageValue!=''">
                    <mat-form-field [floatLabel]="floatLabel" class="first-container">
                        <input matInput formControlName="newErrorMessageValue" placeholder="ErrorMessage Value"
                            required>
                        <mat-error *ngIf="editMenuForm.controls['newErrorMessageValue'].hasError('required')"
                            [attr.literalID]="'err_missingNewValue'">
                            Missing new value</mat-error>
                    </mat-form-field>
                </div>
            </span>
            <br>
            <span>
                <div class="first-container" *ngIf="oldCustomErrorMessageValue&&oldCustomErrorMessageValue!=''">
                    <mat-form-field [floatLabel]="floatLabel" class="first-container">
                        <input matInput formControlName="newCustomErrorMessageValue" placeholder="CustomErrorMessage Value"
                            required>
                        <mat-error *ngIf="editMenuForm.controls['newCustomErrorMessageValue'].hasError('required')"
                            [attr.literalID]="'err_missingNewValue'">
                            Missing new value</mat-error>
                    </mat-form-field>
                </div>
            </span>       
            <span>
                <div class="first-container" *ngIf="oldMinErrorValue&&oldMinErrorValue!=''">
                    <mat-form-field [floatLabel]="floatLabel" class="first-container">
                        <input matInput formControlName="newMinErrorValue" placeholder="MinError Value"
                            required>
                        <mat-error *ngIf="editMenuForm.controls['newMinErrorValue'].hasError('required')"
                            [attr.literalID]="'err_missingNewValue'">
                            Missing new value</mat-error>
                    </mat-form-field>
                </div>
            </span>
            <span>
                <div class="first-container" *ngIf="oldMaxErrorValue&&oldMaxErrorValue!=''">
                    <mat-form-field [floatLabel]="floatLabel" class="first-container">
                        <input matInput formControlName="newMaxErrorValue" placeholder="MaxError Value"
                            required>
                        <mat-error *ngIf="editMenuForm.controls['newMaxErrorValue'].hasError('required')"
                            [attr.literalID]="'err_missingNewValue'">
                            Missing new value</mat-error>
                    </mat-form-field>
                </div>
            </span>     
        </form>
    </div>

    <div class="footer">
        <button mat-button class="ml-4 spa-primary-btn-default" type="button" (click)="Update()">Update</button>
        <button mat-button class="ml-2 text_capitalize LW14" type="button" (click)="Cancel()">Cancel</button>
    </div>

</section>