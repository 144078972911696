import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "./http-call.service";
import { Host } from "../globalsContant";
import { BaseResponse, DepositAPIModel, AppointmentCancel, TransactionDetail } from "../business/shared.modals";

@Injectable({
    providedIn: "root"
})
export class RetailEventHandler {
    constructor(private http: HttpServiceCall) {
    }

  private async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
      }
    }

    public async UpdateAppointmentTransaction(oldTransactionId: number, newTransactionId: number, transactionDetail: TransactionDetail[]) {
      await this.UpdateAppointmentsWithCommonTransactionId(oldTransactionId, newTransactionId, transactionDetail);
    }

    public async UpdateAppointmentsWithCommonTransactionId(oldTransactionId: number, newTransactionId: number, transactionDetail: TransactionDetail[]) {
      const transDetailsCopy = [...transactionDetail];
      const appointments = await this.InvokeServiceCallAsync('GetAppointmentDetailsByTransactionIds', Host.schedule, HttpMethod.Put, null, [oldTransactionId]);
      if (appointments.successStatus && appointments.result && appointments.result.length > 0) {
        appointments.result.map(async (appointment) => {
          const service = await this.InvokeServiceCallAsync('GetSpecificService', Host.spaManagement, HttpMethod.Get, { id: appointment.serviceId });
          if (service.successStatus && service.result && service.result.id > 0) {
            let newTransactionDetailId = appointment.transactionDetailId; // default transdetail id
            const detailIndex = transDetailsCopy.findIndex(t => t.itemId == service.result.serviceDetail.retailItemId);
            if (detailIndex !== -1) {
              const newTransactionDetail = transDetailsCopy[detailIndex];
              if (newTransactionDetail) {
                newTransactionDetailId = newTransactionDetail.id;
                transDetailsCopy.splice(detailIndex, 1);
              }
            }
            const requestContent = { id: appointment.id, transactionId: newTransactionId, transactionDetailId: newTransactionDetailId };
            await this.InvokeServiceCallAsync('UpdateAppointmentTransactionId', Host.schedule, HttpMethod.Put, requestContent);
          }
        });
      }
    }

    public async AddDeposit(deposit: DepositAPIModel[]) {
        this.InvokeServiceCallAsync('UpdateAppointmentDeposit', Host.schedule, HttpMethod.Put, { transactionId: deposit[0].depositTransactionId }, deposit);
    }

    public async RevertDeposit(transactionId: number) {
        this.InvokeServiceCallAsync('RevertDeposit', Host.schedule, HttpMethod.Put, { transactionId: transactionId });
    }

    public async CancelAppointmentDeposit(transactionId: number, appointments: AppointmentCancel[]) {
        this.InvokeServiceCallAsync('CancelDepositAppointment', Host.schedule, HttpMethod.Put, { transactionId: transactionId }, appointments);
    }

  public async UndoAppointment(transactionId: number): Promise<any> {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.schedule,
        callDesc: "UndoCheckOutAppointmentByTransactionId",
        method: HttpMethod.Put,
        uriParams: { transactionId: transactionId }
      });
    } catch (e) {
      this.http.exceptionHandle(e);
      }
    }
}