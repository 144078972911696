import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { SlotType, ManualSlotType, SlottingMode, PartyNoteType, ComponentTypes, OperationResultState, PartyState, ReservationType } from '@app/shared/constants/commonenums';
import { PageMethod, SettingsDTO, ShiftDTO } from '@app/shared/models/RestaurantDTO';
import { TimeRangeDTO } from '@app/shared/models/TimeRangeDTO';
import { ExpiredSlotsPipe } from '@app/shared/pipes/time-slots.pipe';
import { PartyService } from '@app/shared/services/party.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { environment } from '@environments/environment';
import { startOfDay, endOfDay, format } from 'date-fns';
import moment from 'moment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmationPopupComponent } from '../../confirmation-popup/confirmation-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { filter } from 'lodash';
import _ from 'lodash';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

export interface ISlotPartyOrSection {
  DateTime: string;
  Shift: string;
  PartyList: IPartiesOrSection[];
  Sections: IPartiesOrSection[];
}

export interface IPartiesOrSection {
  Id: number;
  DateTime: string;
  LimitedMinPartySize: number;
  LimitedMaxPartySize:number;
  IsDisabled: boolean;
  IsWebReservable: boolean;
  Type: SlotType;
  ManualSlotType: ManualSlotType;
  LockedAt?: Date;
  LockExpiresAt?: Date;
  isChecked?: boolean;
  UpdatedAt?: string;
  PartyId?: number;
  SeatingAreaId?: number;
  isExpired:boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SlotsSectionService extends Utilities {

  masterSlotsList: ISlotPartyOrSection[] = [];
  subscriptions: Subscription = new Subscription();
  filteredSlots$ :BehaviorSubject<ISlotPartyOrSection[]> = new BehaviorSubject<ISlotPartyOrSection[]>([]);
  reservationDetails: any;
  partyCount: number = 0;
  coverSize: number = 0;
  slotTimes: any[] = [];
  slotFilters: any = {partySize: 0,hideFilled: false,hidePast: false,hideDisabled: false};
  selectedSlotCount: number = 0;
  currentShift: ShiftDTO;
  existingeditPopup: NgbPopover;
  isWebReservableShift: boolean = false;
  isEditEnabled:boolean = false;
  isFullScreen: boolean = false;
  selectedSlotsTab: boolean = false;
  slotsActions$ :BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public partyService: PartyService,public as: AppService,public cs: CacheService,private expiredPipe: ExpiredSlotsPipe,public dashboardFunctions: DashboardFunctions, public dialog: MatDialog) { super(dialog) }

  setFilteredSlots(date: Date,settings: SettingsDTO): void {
  this.currentShift = this.getCurrentShift(settings,date);
    this.subscriptions.add(this.getCurrentSlots(date).subscribe(slots => {
      slots?.sort((a,b) => {
        return new Date(a.DateTime).getTime() - new Date(b.DateTime).getTime();
      })
      if(settings.General.SlottingMode == SlottingMode.Manual){
        this.filteredSlots$.next(this.setManualModeSlots(settings.General.SlottingMode,date,slots));
      }
      else {
        this.filteredSlots$.next(slots);
      }
      this.shiftChange(true);
      this.filterSlots(this.slotFilters)
    }));
  }

  getCurrentSlots(date: Date): Observable<ISlotPartyOrSection[]> {
    let timeRange = {} as TimeRangeDTO;
    timeRange.Start = startOfDay(date);
    timeRange.End = endOfDay(date);
    return this.partyService.getSlots(timeRange).pipe(map(data => this.setSlots(data.Payload, date)));
  }

  setSlots(data: any,date: Date): ISlotPartyOrSection[] {
    if(data?.length) {
      this.masterSlotsList = [];
      this.partyCount =0;
      this.coverSize =0;
      let shiftsData = [];
      data.forEach((element) => {
        if(format(new Date(element.Date?.split('Z')[0]), 'MM/DD/YYYY') === format(new Date(date), 'MM/DD/YYYY')) {
          element.Shifts?.forEach((shift) => {
            shiftsData.push(shift.Shift);
            shift.Slots?.forEach((slot) => {
              let slotSection: ISlotPartyOrSection = {DateTime: slot.DateTime,Shift: shift.Shift.Name, PartyList: null, Sections: null};
              if(this.masterSlotsList?.length && this.masterSlotsList.findIndex(element => element.DateTime == slot.DateTime) > -1) {
                let index = this.masterSlotsList.findIndex(element => element.DateTime == slot.DateTime);
                if(index > -1) {
                  let partyData: IPartiesOrSection = this.generatePartiesOrSection(slot);
                  this.setPartySectionData(this.masterSlotsList[index],partyData);

                }
              }
              else {
                let partyData: IPartiesOrSection = this.generatePartiesOrSection(slot);
                this.setPartySectionData(slotSection,partyData);
                this.masterSlotsList.push(slotSection);
              }
            });
          });
        }
      })
    }
    return this.masterSlotsList;
  }

  generatePartiesOrSection(slot: any): IPartiesOrSection {
    const data: IPartiesOrSection = <IPartiesOrSection>{};
    const { Id, DateTime, PartyId, IsDisabled, IsWebReservable, LimitedMinPartySize, 
      LimitedMaxPartySize, OriginalMinPartySize, OriginalMaxPartySize,
       ManualSlotType, Type, SeatingAreaId, UpdatedAt, LockedAt, LockExpiresAt } = slot;
    data.Id = Id;
    data.DateTime = DateTime;
    data.PartyId = PartyId || null;
    data.IsWebReservable = IsWebReservable;
    data.LimitedMinPartySize = LimitedMinPartySize > 0 ? LimitedMinPartySize : OriginalMinPartySize;
    data.LimitedMaxPartySize = LimitedMaxPartySize > 0 ? LimitedMaxPartySize : OriginalMaxPartySize;
    data.IsDisabled = IsDisabled || false;
    data.ManualSlotType = ManualSlotType;
    data.Type = Type;
    data.SeatingAreaId= SeatingAreaId || null;
    data.LockExpiresAt = LockExpiresAt || '';
    data.LockedAt= LockedAt || '';
    data.UpdatedAt= UpdatedAt || '';
    data.isChecked = false;
    this.setSlotsDisabled(data,data.DateTime,this.cs.settings.value);
    return data;
  }
 
  setPartySectionData(partySection: ISlotPartyOrSection,data: IPartiesOrSection): void {
    if(data.PartyId) {
      if(!partySection.PartyList?.length) {
        partySection.PartyList = [];
      }
      this.partyCount = this.partyCount + 1;
      let partyData = this.reservationDetails[data.PartyId];
      if(partyData)
      this.coverSize += partyData.Size
      partySection.PartyList.push(data);
    }
    else {
      if(!partySection.Sections?.length) {
        partySection.Sections = [];
      }
      partySection.Sections.push(data);
    }
  }

  setManualModeSlots(slotMode: SlottingMode,date: Date,allSlots: ISlotPartyOrSection[]) : ISlotPartyOrSection[] {
    if(slotMode == SlottingMode.Manual) {
      let currentDayOpenHours = Utilities.getRestaurantOpenHoursForDay(this.cs.settings.value.OpenHours, date);
      let currentDayOpenHoursRange = Utilities.GetWideRange(currentDayOpenHours);
      const slotTimeRange = this.cs.settings.value.General.TimeSlotUnitInMinutes;
      const startTimeSlot = new Date(currentDayOpenHoursRange[0].split('Z')[0]);
      const endTimeSlot = new Date(currentDayOpenHoursRange[1].split('Z')[0]);
      let startVal = new Date(startTimeSlot);
      let todaysShifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, date);
      let manualSlots: ISlotPartyOrSection[] = [];
      while (startVal <= endTimeSlot) {
        let slotSection: ISlotPartyOrSection = {DateTime: Utilities.Date(startVal).format('YYYY-MM-DDTHH:mm:ss'), Shift: '', PartyList: [], Sections: []};
        if(todaysShifts?.length) {
          todaysShifts.forEach(shift => {
            if (Utilities.datetimeBetween(shift.EffectiveRange.Start, shift.EffectiveRange.End, startVal)) {
              slotSection.Shift = shift.Name;
            }
          })
        }
        allSlots.forEach(element => {
          if(element.DateTime == slotSection.DateTime) {
            slotSection.PartyList = element.PartyList;
            slotSection.Sections = element.Sections;
          }
        });
        manualSlots.push(slotSection);
        const tempTimeSlot = startVal;
        const nextSlotMinute = new Date(tempTimeSlot).getMinutes() + slotTimeRange;
        const nextSlot = tempTimeSlot.setMinutes(nextSlotMinute);
        startVal = new Date(nextSlot);
      }
      return this.masterSlotsList = manualSlots;
    }
  }

  getPartyNotes(party: any,settings: SettingsDTO): any[] {
    let partyNotes = [];
    if (party?.SpecialMealId) {
      const specialMealDetails = settings.SpecialMeals.filter((meal) => meal.Id === party.SpecialMealId);
      if (specialMealDetails.length > 0 && specialMealDetails[0].Name) {
        partyNotes.push({ id: partyNotes.length, name: specialMealDetails[0].Name, isSpecialMeal: true, type: PartyNoteType.SpecialMeal });
      }
    }
    if (party?.Notes.length > 0) {
      let PredefinedPartyNotes = this.dashboardFunctions.getPredefinedPartyNotes();
      party.Notes.forEach((notes, index) => {
        if (notes.RelatedId) {
          const predefinedPartyNote = PredefinedPartyNotes.filter((note) => note.Id === notes.RelatedId);
          if (predefinedPartyNote.length > 0 && (notes.Type == PartyNoteType.PredefinedNote)) {
            partyNotes.push({ id: index, name: predefinedPartyNote[0].Text, isSpecialMeal: false });
          } else if (predefinedPartyNote.length > 0 && (notes.Type == PartyNoteType.FreeFormNote)) {
            partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
          } else if (predefinedPartyNote.length > 0 && notes.Type == PartyNoteType.SpecialRequest) {
            partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type });
          }
        } else if (notes.Type == PartyNoteType.FreeFormNote) {
          partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
        } else if (notes.Type == PartyNoteType.PromoCode) {
          partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type });
        }
      });
    }
    return partyNotes;
  }

  setSlotsDisabled(data: IPartiesOrSection, dateTime: string, settings: SettingsDTO): void {
    if (data) {
      const currentTime = Utilities.getRestaurantDateTime(settings.General.DaylightDelta);
      data.isExpired = !(this.expiredPipe.transform(dateTime, settings));
      if (data.LockedAt && !data.IsDisabled)
        data.isExpired = Utilities.formateDateString(data.LockExpiresAt).getTime() > currentTime.getTime()
    }
  }

  setReservationData(): void {
    this.subscriptions.add(this.partyService.Parties$.subscribe(parties => {
      this.reservationDetails = {};
      parties.map(party => {
        this.reservationDetails[party.Id] = party;
      });
    }));
  }

  setPageBackgroudColor(party: any, settings: SettingsDTO, pageTimer: Subscription): void {
    if (party && party.PageMethod == PageMethod.Manual) {
      if (!party.LastPagedAt) {
        return;
      }
      const lastPagedAt = moment(party.LastPagedAt);
      const time = moment(Utilities.getRestaurantDateTime(settings.General.DaylightDelta));
      let differenceTime = (this.cs.settings.value.General.MinIntervalBetweenPartyPagesInMinutes * 60) - (time.diff(lastPagedAt, 'seconds'));
      if ((time.diff(lastPagedAt, 'minutes')) >= this.cs.settings.value.General.MinIntervalBetweenPartyPagesInMinutes) {
        party.pageBackgroundColor = 'red';
        if (pageTimer) {
          pageTimer.unsubscribe();
          this.subscriptions.remove(pageTimer);
        }
      } else {
        party.pageBackgroundColor = 'green';
        if (differenceTime > 0) {
          this.subscriptions.add(pageTimer);
        }
      }
    }
    if (party && (party.PageMethod == PageMethod.Sms || party.PageMethod == PageMethod.Sms2 || party.PageMethod == PageMethod.Email || party.PageMethod == PageMethod.Email2)) {
      if (party && party.Messages && party.Messages.length > 0) {
        party.pageBackgroundColor = 'green';
      }
      if (party && party.Messages && party.Messages.length > 0 && party.Messages[party.Messages.length - 1].IsIncoming) {
        party.pageBackgroundColor = 'red';
      }
    }
  }

  seatStatusChanged(event: any,party: any): void {
    this.subscriptions.add(this.partyService.postSeatedPartyStatus(Utilities.RestaurantId(), party.Id, event).subscribe(val => {}));
  }

  manualpage(party: any,settings: SettingsDTO): void {
    if (party.State != PartyState.Pending || party.PageMethod != PageMethod.Manual) {
      return;
    }
    const time = moment(Utilities.getRestaurantDateTime(settings.General.DaylightDelta));
    if (!party.LastPagedAt || (time.diff(moment(party.LastPagedAt), 'minutes')) >= settings.General.MinIntervalBetweenPartyPagesInMinutes) {
      this.subscriptions.add(this.partyService.postPartyMessage(party.Id, party.PageMethod, null,null).subscribe((data) => {
        if (data.State == OperationResultState.Success) {
        }
      }));
    } else {
      this.showPagingError();
    }
  }

  showPagingError(): void {
    const popUpMessage = [{
      confirmationMessage: `${this.dashboardFunctions.translateService.instant('manualPagingError')}`,
      dialogTitle: this.dashboardFunctions.translateService.instant('Paging'),
      showAlert: true
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', popUpMessage,
      popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.reservation, '450px', 'auto', true, '', 'Ok',
      'Cancel', true);
  }

  getPastReservationStatus(party: any,settings: SettingsDTO): boolean {
    let restaurantTime:any = moment(Utilities.getRestaurantDateTime(settings.General.DaylightDelta));
    var dt = moment(restaurantTime._d.setHours(0, 0, 0, 0))
    var VisibilityLimit = dt.subtract({ hours: environment.MaxMinutesBackToEditOrSeatReservation });
    if (Utilities.parseDateString(party.ReservedFor) >= new Date(VisibilityLimit.valueOf())) {
      return false;
    } else {
      return true;
    }
  }

  editReservation(party: any):void {
    this.currentShift = this.getCurrentShift(this.cs.settings.value,this.as.headerSelectedDate,party.ReservedFor);
    this.dashboardFunctions.createOrEditReservation(true, party);
  }

  selectCustomSlot(selectedSlot: any):void {
    if(!selectedSlot.isExpired && !selectedSlot.IsDisabled){
      this.partyService.selectedTimeSlot = selectedSlot;
      this.dashboardFunctions.createOrEditReservation(false, null ,null , ReservationType.Reservation , selectedSlot);
      this.currentShift = this.getCurrentShift(this.cs.settings.value,this.as.headerSelectedDate,selectedSlot.DateTime);
    }
  }

  addToStandBy(dateTime: string): void {
    let slot: any = {};
    slot.DateTime = dateTime;
    slot.IsDisabled = false;
    slot.IsOverride = false;
    slot.LimitedMinPartySize = 2;
    slot.LimitedMaxPartySize = 2;
    slot.slotName = null;
    this.partyService.selectedTimeSlot = slot;
    this.partyService.isStandbySlotSelected = true;
    this.dashboardFunctions.createOrEditReservation(false,null);
  }

  disableEnableSlot(settings: SettingsDTO,slotsTime: any[],isDisable: boolean):void {
    if(settings.General.SlottingMode == SlottingMode.Auto) {
    this.subscriptions.add(this.partyService.disableEnableAutoSlot(slotsTime,isDisable).subscribe(data => {
    }));
  }
  else {
    this.subscriptions.add(this.partyService.removeManualSlot(slotsTime,isDisable).subscribe(data => {
    }));
  }
}

getWebReservableShift(): void {
  if(this.currentShift){
    this.subscriptions.add(this.partyService.getWebReservableShift(this.currentShift?.Id,this.as.headerSelectedDate).subscribe(data => {
      if(data) {
        this.isWebReservableShift = data.Payload;
      }
    }));
  }
}

setWebReservableShift(shiftId: number,dateTime: any, isWebReservable: boolean): void {
  this.subscriptions.add(this.partyService.setWebReservableShift(shiftId,dateTime,isWebReservable).subscribe(data => {
    // = isWebReservable
  }));
}
getCurrentShift(settings: SettingsDTO,date: Date,slotDateTime?: string): ShiftDTO {
    const restaurantDate = slotDateTime ? new Date(slotDateTime) : Utilities.getRestaurantDateTime(settings.General.DaylightDelta);
    let currentDayShift = Utilities.getRestaurantShiftForDay(settings.Shifts,date);
    let effectiveStartDate: Date;
    let effectiveEndDate: Date;
    let todayShift: ShiftDTO;
    currentDayShift.forEach(shift => {
        effectiveStartDate = Utilities.parseDateString(shift.EffectiveRange.Start);
        effectiveEndDate = Utilities.parseDateString(shift.EffectiveRange.End);
        if(restaurantDate >= effectiveStartDate && restaurantDate <= effectiveEndDate) {
          todayShift = shift;
        }
    });
    return todayShift;
  }

  filterSlots(slotFilter: any): void {
    let tempList;
    this.subscriptions.add(this.filteredSlots$.subscribe(slots => {
      tempList = _.cloneDeep(slots);
    }))
    if(slotFilter) {
      tempList?.map(slots=> {
        if(slotFilter.partySize > 0) {
          slots.PartyList = slots.PartyList?.filter(party => party.PartyId == this.reservationDetails[party.PartyId].Id && this.reservationDetails[party.PartyId].Size >= slotFilter.partySize) || [];
        }
        if(slotFilter.hideFilled) {
          if(!slots.Sections || slots.Sections.length == 0) {
            slots.PartyList = [];
          }
        }
        if(slotFilter.hidePast) {
          slots.PartyList = slots.PartyList?.filter(party => !party.isExpired) || [];
          slots.Sections = slots.Sections?.filter(party => !party.isExpired) || [];
        }
        if(slotFilter.hideDisabled) {
          slots.PartyList = slots.PartyList?.filter(party => !party.IsDisabled) || [];
          slots.Sections = slots.Sections?.filter(party => !party.IsDisabled) || [];
        }
      });
      this.filteredSlots$.next(tempList);
    }
  }

  shiftChange(isChanged: boolean): void {
    if(this.cs.currentShiftId != 0) {
      this.currentShift = this.cs.settings.value.Shifts.find(shift => shift.Id === this.cs.currentShiftId);
      if(this.currentShift) {
        let tempList = _.cloneDeep(this.masterSlotsList)
        this.filteredSlots$.next(tempList?.filter(slot => slot.Shift == this.currentShift.Name) || []);
        if(isChanged)
        this.getWebReservableShift();
      }
    }
    else {
      this.currentShift = null;
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}