import { PartyType, UpdatedPartySlotType, ModuleName, Actions, SlotType, ManualSlotType } from '@constants/commonenums';
import { PageMethod, UpdatedContactDTO, WaitTimeType, ReservationDTO, PartyDTO } from './InputContact';
import { TimeRangeDTO } from './TimeRangeDTO';
import { BookedSessionDTO, BookingTypeQuantityDTO, CoverTypeQuantityDTO, OpenHoursDTO, PartyNoteDTO, PricingCategory, SecondaryContactDTO } from './RestaurantDTO';
import { TableBlockingRuleDTO } from './TableBlockingDTO';

export class UpdatedPartyDTO {
  Id: number;
  Type: PartyType;
  Size: number;
  SeatingAreaId: number;
  SeatingTypeId: number;
  SeatingTime: Date | null;
  PageMethod: PageMethod;
  Contact: UpdatedContactDTO;
  HostId: number;
  TableIds: number[];
  PagerNumber: string;
  Notes: PartyNoteDTO[];
  PartySourceId: number = null;
  ReferenceNumber: string = null;
  BookingContacts: SecondaryContactDTO[] = [];
  SalesContactIds: number[] = [];
  CoverTypes: CoverTypeQuantityDTO[] = [];
  LanguageId: number;
}

export class UpdatedReservationDTO extends UpdatedPartyDTO {
  // Notes: PartyNoteDTO[]; //ContactNoteDTO;
  Slot: UpdatedPartySlotDTO;
  SpecialMealId: number;
  CoverTypes: CoverTypeQuantityDTO[] = [];
  BookingTypes: BookingTypeQuantityDTO;
  HotelId: number;
  ConciergeId: number;
  StatusId:number;
  RetailCardTokenId:number;
  AddOns?:AddOnRequest[] = [];
  PromoCode?: number;
  AdditionalBookingInfo? = []
  RoomNumber: string
}

export class AddOnRequest {
  AddonId: number;
  Quantity: number
  Price: number
  Category: PricingCategory
  TransDetailId: number
  OverBooked: number
  AvailabilityCount?:number
}
export class InputReservationDTO {
  RestaurantId: number;
  ReservationDTO: UpdatedReservationDTO;
  Module: ModuleName;
  Action: Actions;
}

export class UpdatedWalkInDTO extends UpdatedPartyDTO {
  Notes: PartyNoteDTO[];
  WaitTimeType: WaitTimeType;
  ManualWaitTimeInMinutesFromNow: number;
  IsQuickWalkIn: boolean;
  ServerIdForNonAssignedTables: number;
  StatusId: number;
}
export class InputWaitlistDTO {
  RestaurantId: number;
  WalkInDTO: UpdatedWalkInDTO;
}

export class UpdatedPartySlotDTO {
  Id: number | null;
  Time: Date;
  IsTimeInUtc: boolean;
  Type: UpdatedPartySlotType;
  LockId: number | null;
}

export class RequestBody {
  Request: object;
  Action: Actions;
  Module: ModuleName;
}

export class SeatingEstimateRequestDTO {
  RestaurantId: number;
  SeatingEstimateDTO: SeatingEstimatePartyDTO;
}

export class SeatingEstimatePartyDTO {
  public Id: number = null;
  public IsReservation: boolean = false;
  public ReservedFor: Date = null;
  public ArrivedAt: Date = null;
  public CreatedAt: Date = null;
  public Size: number = null;
  public SeatingAreaId: number = null;
  public SeatingTypeId: number = null;
  public TableIds: any;
  public eventId: number = null;
}

export class SeatingEstimateResultDTO {
  SeatingTime: Date;
  StandaloneTableIds: number[];
}
export class SlotDTO {
  Id: number = null;
  Type: SlotType = null;
  DateTime: any = null;
  PartyId: number = null;
  OriginalMinPartySize: number = null;
  OriginalMaxPartySize: number = null;
  LimitedMinPartySize: number = null;
  LimitedMaxPartySize: number = null;
  IsWebReservable: boolean = false;
  IsDisabled: boolean = false;
  SeatingAreaId: number = null;
  LockedAt: Date = null;
  LockExpiresAt: Date = null;
  ManualSlotType: ManualSlotType = null;
  UpdatedAt: Date = null;
  SlotLockIdDTO:any;
  ExpiresAt:string = null;
  ExpiresAtUTC: string = null;
  AddOnAvailability:AddOnAvailability[];
}
export class AddOnAvailability{
  AddonId: number;
  SpecialMealId : number;
  AvailableQuantity: number;
  LockedQuantity: number;
  SoldQuantity: number;
  OverAllQuantity: number;
  IsUnlimited: boolean = false;
}
export class DateShiftDTO {
  Id: number;
  Name: string;
  EffectiveRange: TimeRangeDTO;
  DisplayedRange: TimeRangeDTO;
  IsWebReservable: boolean;
}

export class StandByPartyDTO extends PartyDTO {
  WishedTime: Date;
  BookedSessions: BookedSessionDTO[];
  SessionGroupId: number;
  BookingTypeQuantities: any[];
  NegotiatedAmount: number;
}

export class UpdatedStandbyPartyDTO extends UpdatedPartyDTO {
  WishedTime: Date;
}

export class WalkInDTO extends PartyDTO {
  public ArrivedAt: Date;
  public QuotedSeatingTime: Date;
  public IsSeatingTimeOverriden: boolean;
  public Position: number;
  public CheckIconVisibility: boolean = null;
  public OrderPrice: any;
}

export class DateShiftStateDTO {
  Shift: DateShiftDTO;
  Slots: SlotDTO[];
  Reservations: ReservationDTO[];
  StandByParties: StandByPartyDTO[];
  WaitLists: WalkInDTO[];
  //TableAvailability : TableAvailabilityDTO;
}

export class NamedEntityDTO {
  Id: number;
  Name: string;
}

export class DateStateDTO {
  Date: Date;
  Shifts: DateShiftStateDTO[];
  TableBlockingRules: TableBlockingRuleDTO[];
  OpenHours: OpenHoursDTO[];
  public TableAvailabilities: TableAvailabilityDTO[];
  public ActivityBookings: [];
  public ActivityStandByBookings: []
}
export class TableAvailabilityDTO {
  public ShiftId: number;
  public TotalTablesForShift: number;
  public AvailableTablesForShift: number;
  public BookedTablesForShift: number;
}
export class PartyIDs {
  partyIds: number[];
}

export class SeatOperationDetailsDTO {
  RestaurantId: number;
  PartyId: number;
  PartySize: number;
  TableNames: string[];
  ServerIds: number[];
  CheckItems: CheckItems[];
}

export class MoveOperationDetailsDTO {
  RestaurantId: number;
  PartyId: number;
  TableNames: string[];
  ServerIds: number[];
}

export class CheckItems{
  itemid : number;
  quantity : number;
  price : number;
}
