<mat-form-field  class="example-chip-list multi-serch spa-searchbox LW14 custSearchbox" [floatLabel]="floatLabelNever">
    <mat-chip-list #chipList >
        <mat-chip class="body-bgcolor whitecolor"
                  *ngFor="let client of _as.labelRecords"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(client.Id)">
                  <div *ngIf="showProfileId"><ng-container *ngIf="guestProfileId"> {{guestProfileId}} - </ng-container>
                  <ng-container *ngIf="!guestProfileId"> {{client.Id}} - </ng-container>
                  </div>{{client.Name}}
                  
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input [disabled]="(this._as.labelRecords.length == Datalimit && Datalimit != -1) || Disabled" matInput
               #dataInput
               placeholder={{allCaptions.common.Search}}
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event)"
               [(ngModel)]="searchValue"
               (input)="sendMessage($event)"
               (mousedown)="clearInput()" attr.automationId='Txt_{{automationId}}_searchInput'>
        <i  aria-hidden="true" class="icon-Search align-middle" *ngIf="!inputIcons" [ngClass]=" _as.labelRecords.length >= 1 ? 'icon-align': ''"></i>
        <i aria-hidden="true" class="icon-Cancel close-icon align-middle cursor" *ngIf="inputIcons" [ngClass]=" _as.labelRecords.length >= 1 ? 'icon-align': ''" (click)="deleteaallRecords()"></i>
        <i  aria-hidden="true" class="icon-Close align-middle"></i>
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option class="auto-complete client-search multi-select" *ngFor="let clientInfo of _as.clientsearchArray" [value]="clientInfo">
            <label class="label-select LW14 bold m-0"><ng-container *ngIf="showProfileId">{{clientInfo.bookingId}} - </ng-container> 
                <span dir="auto">{{clientInfo.name}}</span> 
                <span *ngIf="clientInfo.phoneNumber"> | {{ clientInfo.phoneNumber }}</span> 
                <span *ngIf="clientInfo.emailId"> | {{ clientInfo.emailId }}</span>
                <span *ngIf="clientInfo.patronId"> | {{localization.captions.bookAppointment.PatronID}}: {{clientInfo.patronId}}</span> 
                <span *ngIf="clientInfo.corpName"> | {{clientInfo.corpName}}</span>
            </label>
            <p class="LW12 m-0">{{clientInfo.address}} {{clientInfo.country}}</p>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>







