import { Pipe, PipeTransform } from '@angular/core';
import { ReservationDTO } from '@models/InputContact';
import { PartyNoteType, SettingsDTO } from '@models/RestaurantDTO';
import { DashboardFunctions } from '@utilities/dashboard-functions';

@Pipe({
  name: 'reservationNotes'
})
export class ReservationNotesPipe implements PipeTransform {
  constructor(public dashboardFunctions: DashboardFunctions) {

  }
  transform(party: ReservationDTO, settings: SettingsDTO): any {
    const partyNotes = [];
    if (party.SpecialMealId) {
      const specialMealDetails = settings.SpecialMeals.filter((meal) => meal.Id === party.SpecialMealId);
      if (specialMealDetails.length > 0 && specialMealDetails[0].Name) {
        partyNotes.push({ id: partyNotes.length, name: specialMealDetails[0].Name, isSpecialMeal: true, type: PartyNoteType.SpecialMeal });
      }
    }
    let PredefinedPartyNotes = this.dashboardFunctions.getPredefinedPartyNotes();
    if (party.Notes.length > 0) {
      party.Notes.forEach((notes, index) => {
        const predefinedPartyNote = PredefinedPartyNotes.filter((note) => note.Id === notes.RelatedId);
        if (predefinedPartyNote.length > 0 && notes.Type == PartyNoteType.PredefinedNote ) {
          partyNotes.push({ id: index, name: predefinedPartyNote[0].Text, isSpecialMeal: false });
        } else if (predefinedPartyNote.length > 0 && (notes.Type == PartyNoteType.FreeFormNote)) {
          partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
        } else if (predefinedPartyNote.length > 0 && notes.Type == PartyNoteType.SpecialRequest) {
          partyNotes.push({id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type});
        } else if (notes.Type == PartyNoteType.FreeFormNote) {
          partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
        } else if (notes.Type == PartyNoteType.PromoCode) {
          partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type });
        }
      });
    }
    return partyNotes;
  }

}
