import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports, OutletAndCategoryFilter } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from "../report-business.service";
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

export class SalesByVendor extends BaseReport {
    functionalities: { [key: string]: boolean } = {};
    // tslint:disable-next-line:max-line-length
    constructor(public reportFormGrp: UntypedFormGroup,
                public data: ReportDataService,
                public business: ReportBusinessService,
                private func: RetailFunctionalityBusiness,
                private propertyInfo: RetailPropertyInformation
                ,public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.SalesByVendor,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: false
        };
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: false,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlet,
            inActiveToggle: false,
            pageBreakToggle: false,
            layout: 'PORTRAIT',
            allowFutureDate: false
        };
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;
        let reportcategoryCaption: string;
        let captions: string[] = [];
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        if (!customFilterData.filterItems) {
            captions = this.business.getSelectedCategoryCaptions(customFilterData);
        }
        let clerkCaption: string = customFilterData.clerks ? this.business.getFilterCaption(this.business.FilterDataSource["allClerks"],
            customFilterData.clerks.map(x => x.id)) : "";

        return [
            { "pStartDate": toApi(this.startDate) },
            { "pEndDate": toApi(this.endDate) },
            { "pUser": clerkCaption },
            { "pSearchByItem": customFilterData.filterItems ? "true" : "false" },
            {
                "pOutlet": this.functionalities.ShowOutletSelectionFieldInVendorInventoryDetailReport ?
                    this.getFilterCaption : this.getDefaultOutletCaption
            },
            { "pPropertyName": this.propertyName },
            { "pIsDetailView": customFilterData.IsDetailView ? "true" : "false" },
            { "pIsNewPageAfterOutlet": customFilterData.IsNewPageAfterEachOutlet ? "true" : "false" },
            { "pCategory": captions[0] },
            { "pSubCategory1": captions[1] },
            { "pSubCategory2": captions[2] },
            { "pSubCategory3": captions[3] },
            { "pSubCategory4": captions[4] },
            { "pSubCategory5": captions[5] },
            { 'pDate': this.printedDate },
            { 'pVendor': clerkCaption }];
    }


    protected formURIParams(): ReportParams[] {
        return [];
    }


    protected formFilters() {
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let toApi = this.localization.convertDateObjToAPIdate;
        let ids: number[];
        if (customFilterData.filterItems && customFilterData.searchbyitem != "") {
            ids = customFilterData.searchbyitem.split(',');
        }
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        const tempOutletFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return JSON.stringify({
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate),
                VendorIds: customFilterData.clerks.map(o => o.id),
                OutletIds: this.functionalities.ShowOutletSelectionFieldInVendorInventoryDetailReport ?
                this.selectedFilterOptions.map(x => x.id) : tempOutletFalse,
                CategoryIds: this.fillFilter(customFilterData.category[0]),
                SubCategory1Ids: this.fillFilter(customFilterData.category[1]),
                SubCategory2Ids: this.fillFilter(customFilterData.category[2]),
                SubCategory3Ids: this.fillFilter(customFilterData.category[3]),
                SubCategory4Ids: this.fillFilter(customFilterData.category[4]),
                SubCategory5Ids: this.fillFilter(customFilterData.category[5]),
                SelectedItemIds: ids ? ids : null,
                ReportCode: AllReports.SalesByVendor,
                isVendor: true,
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            });
        }
        let filter: Partial<OutletAndCategoryFilter> = {
            startDate: toApi(this.startDate),
            endDate: toApi(this.endDate),
            VendorIds: customFilterData.clerks.map(o => o.id),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInVendorInventoryDetailReport ?
            this.selectedFilterOptions.map(x => x.id) : tempOutletFalse,
            CategoryIds: this.fillFilter(customFilterData.category[0]),
            SubCategory1Ids: this.fillFilter(customFilterData.category[1]),
            SubCategory2Ids: this.fillFilter(customFilterData.category[2]),
            SubCategory3Ids: this.fillFilter(customFilterData.category[3]),
            SubCategory4Ids: this.fillFilter(customFilterData.category[4]),
            SubCategory5Ids: this.fillFilter(customFilterData.category[5]),
            SelectedItemIds: ids ? ids : null,
            ReportCode: AllReports.SalesByVendor,
            isVendor: true
        };
        return JSON.stringify(filter);
    }

    fillFilter(customFilterData: any): number[] {
        let ids: number[] = customFilterData.selectedCat.length === 0 ? [] : customFilterData.selectedCat.map(x => x.id);
        if (customFilterData.selectedCat.length === customFilterData.catArr.length && customFilterData.catArr.length > 0) {
            ids = [];
        }
        return ids;
    }
}


