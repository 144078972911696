import { Pipe, PipeTransform } from "@angular/core";
import _ from "lodash";

@Pipe({
    name: 'groupBy'
  })
  export class GroupByPipe implements PipeTransform {
  
    transform(arr: any[], groupBy:string): any {
      return Object.entries(_.groupBy(arr, groupBy));
    }
  }