import { Injectable } from '@angular/core';
import { DialogOverviewExampleDialog } from '../shared/dialog-popup/dialogPopup-componenet';
import { MatDialog } from '@angular/material/dialog';
import { GridHeader, OutletSubProperty, CommissionableRetailItem, Commission, BaseResponse, CommissionSetupRequest, CommissionSetupResponse, CommissionDetailsUI, UpdateCommissionSetupValue, CommissionDetails, CommissionSetupUpdateAllResult } from './commission-setup.modals';
import { GridType, Host } from '../shared/globalsContant';
import { RetailLocalization } from '../common/localization/retail-localization';
import { BehaviorSubject } from 'rxjs';
import { HttpMethod, HttpServiceCall } from '../shared/service/http-call.service';
import { RetailUtilities } from '../shared/utilities/retail-utilities';

@Injectable({
  providedIn: 'root'
})
export class CommissionSetupService {

  tabLoaderEnable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  commissionGridData: CommissionDetailsUI[] = [];
  negativeNumber = 0;
  public get NextNegativeNumber() {
    this.negativeNumber -= 1;
    return this.negativeNumber;
  }
  public get ProductId() {
    return Number(this.utils.GetPropertyInfo('ProductId'));
  }
  constructor(private dialog: MatDialog, private localization: RetailLocalization, private http: HttpServiceCall, private utils: RetailUtilities) { }

  openErrorDialog(error, Type, header): void {
    this.dialog.open(DialogOverviewExampleDialog, {
      height: 'auto',
      width: '600px',
      data: { headername: header, closebool: true, templatename: 'In the Given Data', datarecord: error, Type: Type },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
  }

  getDataheader(serviceType: number): Array<GridHeader> {
    let arrDataHeader: any;

    if (serviceType == GridType.commission) {
      arrDataHeader = [{ "title": this.localization.captions.setting.Description, "jsonkey": "itemDescription", "alignType": "left", "searchable": true, "sortable": false },
      { "title": this.localization.captions.setting.Location, "jsonkey": "locationDescription", "alignType": "left", "searchable": true, "sortable": false },
      { "title": this.localization.captions.setting.Threshold + ` (${this.localization.currencySymbol})`, "jsonkey": "threshold", "datatype": "money", "alignType": "right", "searchable": true, "sortable": false },
      { "title": this.localization.captions.setting.FlatAmount + ` (${this.localization.currencySymbol})`, "jsonkey": "flatAmount", "datatype": "money", "alignType": "right", "searchable": true, "sortable": false },
      { "title": this.localization.captions.setting.Percentage, "jsonkey": "percentage", "datatype": "percentage", "alignType": "right", "searchable": false, "sortable": false },
      { "title": "", "jsonkey": '', "alignType": "left" },
      { "title": this.localization.captions.setting.CommissionOn, "jsonkey": "commissionon", "displayType": "icon", "searchable": true, "sortable": false },
      { "title": this.localization.captions.setting.AfterDiscount, "jsonkey": "isAfterDiscount", "alignType": "left", "searchable": false, "sortable": false}
      ]
    }
    return arrDataHeader;
  }

  /**
   * @function updateLoaderState
   * @param isCallBack
   * @param isInitialLoad
   * @param callCounter
   */
  updateInitalLoads(isCallBack, initialLoads, callCounter) {
    if (initialLoads) {
      if (isCallBack) {
        callCounter--;
      }
      else {
        callCounter++;
      }
      if (callCounter === 0) {
        this.tabLoaderEnable.next(false)
        initialLoads = false;
      }
    }
    return [initialLoads, callCounter];
  }

  async GetActiveOutletsByUserAccess(): Promise<OutletSubProperty[]> {
    let outlets: OutletSubProperty[] = [];
    let apiResponse: OutletSubProperty[] = await this.InvokeServiceCallAsync<OutletSubProperty[]>('GetSubPropertyAccessByUser', Host.retailManagement, HttpMethod.Get, { userId: this.utils.GetPropertyInfo("UserId") });
    if (apiResponse && apiResponse.length > 0) {
      apiResponse.forEach(e => {
        if (e.isActive) {
          outlets.push(e);
        }
      });
    }
    return outlets;
  }

  async GetCommissionData(classId: number, selectedOutlet: number[]): Promise<any[]> {
    const outletId = 0;
    let [commissionItems, defaultClassData] = await Promise.all([
      this.InvokeServiceCallAsync<CommissionableRetailItem[]>('GetCommissionItems', Host.retailManagement, HttpMethod.Get, { outletId: outletId }),
      this.InvokeServiceCallAsync<Commission[]>('GetCommissions', Host.commission, HttpMethod.Get,
        { classId: classId, productId: this.ProductId, outletId: outletId })
    ]);
    return [commissionItems, defaultClassData];
  }

  async InvokeServiceCallAsync<T>(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<T> {
    let result: BaseResponse<any> = await this.http.CallApiAsync({
      host: domain,
      callDesc: route,
      method: callType,
      body: body,
      uriParams: uriParams,
    });
    if (result.successStatus && result.result) {
      return result.result
    }
    else {
      return null;
    }
  }

  async GetAllCategories() {
    try {
      return await this.InvokeServiceCallAsync<any>('GetAllCategories', Host.retailManagement, HttpMethod.Get);
    }
    catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async getCommissionSetup(body: CommissionSetupRequest): Promise<CommissionSetupResponse[]> {
    try {
      return await this.InvokeServiceCallAsync<CommissionSetupResponse[]>('GetCommissionSetup', Host.retailManagement, HttpMethod.Put, '', body);
    }
    catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async UpdateCommissionSetUpByFilter(commissionSetupRequest: CommissionSetupRequest, updateCommissionSetupValue: UpdateCommissionSetupValue): Promise<CommissionSetupUpdateAllResult> {
    try {
      return await this.InvokeServiceCallAsync<CommissionSetupUpdateAllResult>('UpdateCommissionSetupByFilter', Host.commission, HttpMethod.Put, '', {commissionSetupRequest: commissionSetupRequest,updateCommissionSetupValue: updateCommissionSetupValue});
    }
    catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async UpdateRetailCommissionSetUpByFilter(commissionSetupRequest: CommissionSetupRequest, updateCommissionSetupValue: UpdateCommissionSetupValue,CommissionSetup: CommissionDetails[]): Promise<CommissionSetupUpdateAllResult> {
    try {
      return await this.InvokeServiceCallAsync<CommissionSetupUpdateAllResult>('UpdateRetaCommissionSetupByFilter', Host.retailManagement, HttpMethod.Put, '', {commissionSetupRequest: commissionSetupRequest,updateCommissionSetupValue: updateCommissionSetupValue, CommissionSetup: CommissionSetup});
    }
    catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async GetCommissionSetupForItem(itemId : number) : Promise<Commission[]>{
    try {
      return await this.InvokeServiceCallAsync<Commission[]>('GetCommissionByItem', Host.commission, HttpMethod.Get, { itemId: itemId, productId: this.ProductId })
    }
    catch (e) {
      this.http.exceptionHandle(e);
    }
  }
}
