import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { PreferredTableComponent } from '@components/preferred-table/preferred-table.component';
import { ComponentTypes, Menu, OperationResultState, PartyNoteType, PartyState, ReservationEmailNotificationType, ReservationType, SlottingMode } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { PartyPrepaymentState } from '@models/InputContact';
import { BookingChargeType, PageMethod, PartyChargeState, SeatingInfoDTO, PartyEmailSendBehavior, SettingsDTO, SpecialMealDTO, StatusDTO, SupportedReservationEmailConfirmationTypes } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { DashboardFunctions } from '@utilities/dashboard-functions';
import { Utilities } from '@utilities/utilities';
import { cloneDeep, round } from 'lodash';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';
import _ from 'lodash';
import { CacheService } from '@core/services/cache.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';

@Component({
  selector: 'app-partiesgrid',
  templateUrl: './partiesgrid.component.html',
  styleUrls: ['./partiesgrid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PartiesgridComponent extends Utilities implements OnDestroy, OnChanges, AfterViewInit {
  @Input() parties: any[] = [];
  @Input() type: ReservationType;
  @Input() panelState: boolean;
  @Input() showCreatedDate: boolean;
  @Input() isNoShowSelected: boolean;
  @Input() generalSettings: {
    HasRoomNumberSearch: boolean,
    SpecialMeals: SpecialMealDTO[],
    Statuses: StatusDTO[],
    PromoCodeLabel,
    General: {
      DaylightDelta: string,
      NoShowsUndoTimeInMinutes: number,
      MinIntervalBetweenPartyPagesInMinutes: number,
      SupportedReservationEmailConfirmationTypes: SupportedReservationEmailConfirmationTypes,
      SlottingMode: SlottingMode
    }
  };
  @ViewChild('addOnForBookingPopover') private addOnForBookingPopover: NgbPopover;
  addonsForBooking: any = [];
  reservationUrl: string = urlConfig.noReservationsUrl;
  selectedParty: any;
  ReservationType = ReservationType;
  PartyState = PartyState;
  partyStatusList: StatusDTO[];
  remainingTime: number;
  counter$: Observable<void>;
  subscriptions: Subscription = new Subscription();
  setActionDisabled = false;
  hasRoomNUmberSearch = false;
  paymentPaid = false;
  paymentNotPaid = false;
  paymentAuthorised = false;
  paymentIgnored = false;
  quotedTime: number;
  totalSeatingTime: number;
  progressBarValue: number;
  financialDetail = {
    status: '',
    date: '',
    amount: 0,
    header: ''
  };
  IsCharged = false;
  _settings: SettingsDTO = {} as SettingsDTO;
  headerSubscription: ISubscription;
  cancelSubscription: ISubscription;
  confirmSubscription: ISubscription;
  pageTimer: Subscription;
  isShowParty = true;
  contactName: string;
  partyNotes: any[] = [];
  guestNotes: any[] = [];
  partiesSubscription: Subscription;
  selectedIndex: any;
  isFinancialStatusAvailable: boolean;
  @Input() index: any;
  filteredParties: any[] = [];
  Menu = Menu;
  partyNoteType = PartyNoteType;
  @ViewChild('partyStatusPopup') private partyStatusPopup: NgbPopover;
  selectedStatusParty: any;
  partyStatusPopupHide: PartyState[] = [PartyState.Cancelled ,PartyState.Seated ,PartyState.Left ,PartyState.NoShowed];
  showAddOnDetails:boolean = false;
  addonsForActivitySelected  = [];
  partyWithAddon: any;
  constructor(public _ps: PartyService, public _as: AppService, private ps: PopupService,
    private ts: TranslateService, private dashboardFunctions: DashboardFunctions, dialog: MatDialog,
    private cdf: ChangeDetectorRef,public cs: CacheService) {
    super(dialog);
  }

  showParty(party): boolean {
    if (party.NoShowedAt == null && party.State != PartyState.NoShowed) {
      return true;
    } else if (party.NoShowedAt != null && party.State == PartyState.NoShowed) {
      const noShowDate = Utilities.Date(party.ReservedFor);
      const currentDay = Utilities.Date(Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta));
      if ((currentDay.diff(noShowDate, 'minutes')) < this.generalSettings.General.NoShowsUndoTimeInMinutes) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  filterParties() {
    this.filteredParties = [];
    this.parties.forEach(party => {
      if (party.NoShowedAt == null && party.State != 3) {
        this.filteredParties.push(party);
      }
      else if (party.NoShowedAt != null && party.State == 3) {
        let noShowDate = moment(party.ReservedFor);
        let currentDay = Utilities.Date(Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta));
        if ((currentDay.diff(noShowDate, 'minutes')) < this.generalSettings.General.NoShowsUndoTimeInMinutes) {
          this.filteredParties.push(party);
        }
      }
    })
  }

  ngOnDestroy() {
    if (this.selectedParty) {
      const selectedPartyDiv = document.getElementById('div_' + this.selectedParty.Id);
      if (selectedPartyDiv) {
        selectedPartyDiv.className = 'parties-grid__row-seat-register seat-border-color';
      }
      const selectedParty = this.parties.filter(party => party.Id === this.selectedParty.Id);
      if (selectedParty.length) {
        selectedParty[0].isSelected = false;
      }
      this.selectedParty = null;
    }
    this._ps.reservationType = null;
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.headerSubscription) { this.headerSubscription.unsubscribe(); }
    if (this.partiesSubscription) { this.partiesSubscription.unsubscribe(); }
  }

  ngOnChanges() {
    if (this.panelState) {
      this.hasRoomNUmberSearch = this.generalSettings.HasRoomNumberSearch;
      this.filterParties();
      if (this.parties.length > 0) {
        this.parties.forEach((party) => {
          this.setPageBackgroudColor(party);
          this.setActive(party);
          this.SetIconVisibility(party);
          this.setPartyNotes(party);
        });
      }
      setTimeout(() => {
        if (this.parties.length > 0) {
          const expansionPanel: any = document.getElementsByClassName('shift-mat-expansion-panel-header');
          let height = 0;
          for (const panel of expansionPanel) {
            height += panel.offsetHeight;
          }
          const windowHeight = window.innerHeight;
          const headerHeight: any = document.getElementsByClassName('layout__header').length > 0 ?
            document.getElementsByClassName('layout__header')[0]['offsetHeight'] : 0;
          let reservationHeader;
          let paddingHeight;
          if (this.type === ReservationType.Reservation) {
            reservationHeader = document.getElementsByClassName('reservation__row-header').length > 0 ?
              document.getElementsByClassName('reservation__row-header')[0]['offsetHeight'] : 0;
            paddingHeight = 55;
          } else if (this.type === ReservationType.Waitlist) {
            reservationHeader = document.getElementsByClassName('row-header').length > 0 ?
              document.getElementsByClassName('row-header')[0]['offsetHeight'] : 0;
            paddingHeight = 30;
          }

          const estimatedHeight = windowHeight - (height + headerHeight + reservationHeader + paddingHeight);
          const gridName = `parties-grid-${this.index}`;
          const gridHeight = document.getElementById(gridName);
          gridHeight.style.maxHeight = estimatedHeight + 'px';
          const virtualDivName = `virtual-scroll-${this.index}`;
          const virtualDivHeight = document.getElementById(virtualDivName);
          if (this.parties.length > 0) {
            virtualDivHeight.style.minHeight = 200 + 'px';
          } else {
            virtualDivHeight.style.minHeight = 0 + 'px';
          }
        }
      });
    }
  }

  setPageBackgroudColor(party: any) {
    if (party && party.PageMethod == PageMethod.Manual) {
      if (!party.LastPagedAt) {
        return;
      }
      const lastPagedAt = moment(party.LastPagedAt);
      const time = moment(Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta));
      let differenceTime = (this.generalSettings.General.MinIntervalBetweenPartyPagesInMinutes * 60) - (time.diff(lastPagedAt, 'seconds'));
      if ((time.diff(lastPagedAt, 'minutes')) >= this.generalSettings.General.MinIntervalBetweenPartyPagesInMinutes) {
        party.pageBackgroundColor = 'red';
        if (this.pageTimer) {
          this.pageTimer.unsubscribe();
          this.subscriptions.remove(this.pageTimer);
        }
      } else {
        party.pageBackgroundColor = 'green';
        if (differenceTime > 0) {
          this.subscriptions.add(this.pageTimer);
        }
      }
    }
    if (party && (party.PageMethod == PageMethod.Sms || party.PageMethod == PageMethod.Sms2 || party.PageMethod == PageMethod.Email || party.PageMethod == PageMethod.Email2)) {
      if (party && party.Messages && party.Messages.length > 0) {
        party.pageBackgroundColor = 'green';
      }
      if (party && party.Messages && party.Messages.length > 0 && party.Messages[party.Messages.length - 1].IsIncoming) {
        party.pageBackgroundColor = 'red';
      }
    }
  }

  setPartyNotes(party) {
    party.partyNotes = [];
    if (party.SpecialMealId) {
      const specialMealDetails = this.generalSettings.SpecialMeals.filter((meal) => meal.Id === party.SpecialMealId);
      if (specialMealDetails.length > 0 && specialMealDetails[0].Name) {
        party.partyNotes.push({ id: this.partyNotes.length, name: specialMealDetails[0].Name, isSpecialMeal: true, type: PartyNoteType.SpecialMeal });
      }
    }
    if (party.Notes.length > 0) {
      let PredefinedPartyNotes = this.dashboardFunctions.getPredefinedPartyNotes();
      party.Notes.forEach((notes, index) => {
        if (notes.RelatedId) {
          const predefinedPartyNote = PredefinedPartyNotes.filter((note) => note.Id === notes.RelatedId);
          if (predefinedPartyNote.length > 0 && (notes.Type == PartyNoteType.PredefinedNote)) {
            party.partyNotes.push({ id: index, name: predefinedPartyNote[0].Text, isSpecialMeal: false });
          } else if (predefinedPartyNote.length > 0 && (notes.Type == PartyNoteType.FreeFormNote)) {
            party.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
          } else if (predefinedPartyNote.length > 0 && notes.Type == PartyNoteType.SpecialRequest) {
            party.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type });
          }
        } else if (notes.Type == PartyNoteType.FreeFormNote) {
          party.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
        } else if (notes.Type == PartyNoteType.PromoCode) {
          party.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type });
        }
      });
    }
  }

  mapPartyNotes(party) {
    this.partyNotes = [...party.partyNotes];
  }

  mapGuestNotes(Notes) {
    let guestNotesText = [];
    let customnotesText = [];
    let guestnotes = cloneDeep(Notes);
    if (guestnotes.length > 4) {
      let requiredNotes = guestnotes.splice(4, guestnotes.length);
      let NotesWithRelatedId = requiredNotes.filter(x => x.RelatedId != null);
      let NotesWithoutRelatedId = requiredNotes.filter(x => x.RelatedId == null);
      guestNotesText = NotesWithRelatedId.map(n => n.Text);
      if (NotesWithoutRelatedId && NotesWithoutRelatedId.length > 0) {
        NotesWithoutRelatedId.forEach(element => {
          let tempArray = [];
          tempArray = element.Text.split("\n");
          tempArray.forEach(e => {
            customnotesText.push(e);
          })
        });

      }
      this.guestNotes = [...guestNotesText, ...customnotesText];

    }
  }

  setActive(party) {
    if (!this.selectedParty) {
      const selectedParty = document.getElementById('div_' + party.Id);
      if (selectedParty) {
        selectedParty.className = 'parties-grid__row-seat-register seat-border-color';
      }
    } else if (this.selectedParty && this.selectedParty.Id === party.Id) {
      setTimeout(() => {
        if (this.selectedParty) {
          const selectedParty = document.getElementById('div_' + this.selectedParty.Id);
          if (selectedParty) {
            selectedParty.className = 'parties-grid__active parties-grid__row-seat-register';
            const party = this.parties.filter(party => party.Id === this.selectedParty.Id);
            if (party.length) {
              party[0].isSelected = true;
            }
          }
        }
      }, 100);
    }
    if(party)
    party.isShowParty = this.showParty(party);
  }

  SelectParty(party) {
    if (this.selectedParty && this.selectedParty.Id !== party.Id) {
      this.selectedParty.isSelected = false;
      this.parties.forEach(x => {
        if (x.isSelected) {
          x.isSelected = false;
        }
      });
      party.isSelected = true;
      this._ps.selectedParty$.next(party);
    } else if (this.selectedParty && this.selectedParty.Id === party.Id) {
      return;
    } else {
      this.parties.forEach(x => {
        if (x.isSelected) {
          x.isSelected = false;
        }
      });
      party.isSelected = true;
      this._ps.selectedParty$.next(party);
    }
  }

  seatParty(party: any, event) {
    this.dashboardFunctions.seatPartyWithConfimation(party);
  }

  cancelParty(party, event) {
    if (this._as.OTASourceId.includes(party.PartySourceId)) {
      this.dashboardFunctions.showOTAAlert(party);
      return;
    }
    let msg = '';
    let cancelText = 'No';
    let title = 'cancelConfirmationMsg';
    let noShowSet = false;
    let cancelReservation = false;
    let updateText = 'Yes';
    let showAlert = false;
    let noShowFeePopUp = false;
    let isWebReservation = false;

    if (party && party.PartySourceId) {
      isWebReservation = this._ps.isWebReservation(party.PartySourceId);
    }

    if (this.type === ReservationType.Waitlist) {
      msg = this.ts.instant('cancelWaitlist');
    } else if (this.type === ReservationType.Reservation) {
      msg = isWebReservation ? this.ts.instant('cancelReservationForWeb') : this.ts.instant('cancelReservation');
      msg = this.ts.instant('cancelReservation');
    }

    const currentRestaurantDateTime = new Date(Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta));
    if ((this.type === ReservationType.Reservation && moment(party.ReservedFor) < moment(currentRestaurantDateTime)) ||
      (this.type === ReservationType.Waitlist && moment(party.ArrivedAt) < moment(currentRestaurantDateTime))) {
      const reservationMsg = this.ts.instant('reservationCancelMsg');
      const waitlistMsg = this.ts.instant('waitlistCancelMsg');
      msg = this.type === ReservationType.Reservation ? reservationMsg : waitlistMsg;
      cancelText = this.type === ReservationType.Reservation ? this.ts.instant('cancelReservationMsg') :
        this.ts.instant('cancelParty');
      cancelReservation = false;
      updateText = this.ts.instant('noShow');
      title = this.ts.instant('alert');
      showAlert = true;
      noShowFeePopUp = true;
    }

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm', showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '600px',
      height: '350px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true
      }
    });
    dialogRef.afterClosed().subscribe(event => {
      if (cancelReservation) {
        if (this.type === ReservationType.Waitlist) {
          this.dashboardFunctions.cancelWaitlistPartyConfirm(party);
          cancelReservation = false;
        } else {
          this.subscriptions.add(this._ps.cancelReservation(party.Id).subscribe(
            (data) => {
              if (data.Payload != null) {
                this._ps.reservationType = ReservationType.Reservation;
                if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 1) {
                  this._ps.openConfirmationDialog(data, this.ts.instant('CancelSuccessProcessText',  {operationCurrency: this.cs.operationCurrency, RefundAmount: data.Payload.RefundAmount }), party.SeatingTime);
                }
                if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 2) {
                  this._ps.openConfirmationDialog(data, this.ts.instant('RefundFailProcessManualText',  {operationCurrency: this.cs.operationCurrency, RefundAmount: data.Payload.RefundAmount }), party.SeatingTime);
                }
                if (data.Payload && data.Payload.EmailAddress && (this.cs.settings.value.General.HostCancellationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
                  this._ps.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Cancelled);
                }
              }
              cancelReservation = false;
            }));
        }
      } else if (noShowSet && noShowFeePopUp) {
        this.subscriptions.add(this._ps.noShowParty(party.Id).subscribe((data) => {
          if (data.State === OperationResultState.Success) {
            noShowSet = false;
          }
        }));
      }
      if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
      if (this.confirmSubscription) this.confirmSubscription.unsubscribe();
    });

    this.confirmSubscription = this.ps.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.reservation && noShowFeePopUp) {
        noShowSet = true;
      }
      if (val === ComponentTypes.reservation && !noShowFeePopUp) {
        cancelReservation = true;
      }
    });
    this.cancelSubscription = this.ps.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.reservation && val.value === 1 && !cancelReservation && noShowFeePopUp) {
        cancelReservation = true;
      }
    });
    event.preventDefault();
    event.stopPropagation();
  }

  ngAfterViewInit() {
    this.subscriptions.add(this._ps.selectedParty$.subscribe(party => {
      this.selectedParty = party;
      
      this.parties.forEach((partie) => {
        if(partie.CommonPackageInfo && partie.CommonPackageInfo.length > 5){
          partie['CommonPackageInformation'] =  JSON.parse(partie.CommonPackageInfo);
        }
      });

      this.parties.forEach((reservedParty) => {
        this.setActive(reservedParty);
      });
    if (party) {
      this.setPageBackgroudColor(party);
    }
  }));
    this.cdf.detectChanges();
  }

  SetIconVisibility(party) {
    party.paymentPaid = false;
    party.paymentAuthorised = false;
    party.paymentNotPaid = false;
    party.paymentIgnored = false;
    party.PartyPayments = this._ps.getBookingAmount(party);
    if ((party.IsCCAssociated || party.CreditCardId != null || party.SpecialMealId != null || party.RatePlanAmount != null) && party.PrepaymentState != PartyPrepaymentState.Refunded && this.anyAmountInvolved(party)) {
      if (party.PrepaymentState != PartyPrepaymentState.None && party.Type == ReservationType.Reservation) {
        // Paid SM
        const isSpecMealPrepaid = party.PrepaymentState == PartyPrepaymentState.Prepaid
          || party.PrepaymentState == PartyPrepaymentState.Refunded
          || party.PrepaymentState == PartyPrepaymentState.RefundFailed
          || party.PrepaymentState == PartyPrepaymentState.PartialPrepayment;

        party.paymentPaid = isSpecMealPrepaid;
        party.paymentNotPaid = !isSpecMealPrepaid;

        if (!isSpecMealPrepaid && party.PrepaymentState == PartyPrepaymentState.DeferredPayment && party.CreditCardId != null) {
          if (party.ChargeState == PartyChargeState.Charged) {
            party.paymentPaid = true;
            party.paymentAuthorised = false;
            party.paymentNotPaid = false;
          } else if (party.ChargeState === PartyChargeState.Ignored) {
            party.paymentPaid = false;
            party.paymentIgnored = true;
            party.paymentNotPaid = false;
          }
          else {
            party.paymentAuthorised = true;
            party.paymentNotPaid = false;
          }
        }
      } else if (party.Type == ReservationType.Reservation) {
        // NoShowFee
        party.paymentAuthorised = party.CreditCardId != null && party.NoShowFeeAmount;
        // this.paymentNotPaid = party.CreditCardId == null;
      }
    }
  }
  anyAmountInvolved(_party) {
    return (_party.NoShowFeeAmount || _party.PartyPayments?.partyAmount || 0);
  }

  financialDetails(party, status, event) {
    this.isFinancialStatusAvailable = true;
    let amount;
    party.NoShowFeeAmount = party.NoShowFeeAmount ? party.NoShowFeeAmount : 0;
    party.RatePlanAmount = party.RatePlanAmount ? party.RatePlanAmount : 0;
    party.SpecialMealAmount = party.SpecialMealAmount ? party.SpecialMealAmount : 0;
    party.AddonItemAmount = party.AddonItemAmount ? party.AddonItemAmount : 0;
    this.financialDetail = {
      status: '',
      date: '',
      amount: 0,
      header: ''
    };
    let _partyAmount = this._ps.getBookingAmount(party);
    party.PartyPayments = this._ps.getBookingAmount(party);
    party['bookingAmount'] = _partyAmount.bookingAmount;
    party['taxOnServiceCharge'] = _partyAmount.taxOnServiceCharge;

    if (party.NoShowFeeAmount > 0 || this.anyAmountInvolved(party)) {
      if (status === 'authorized' || status == 'ignored') {
        switch (status) {
          case 'authorized':
            amount = ((party.NoShowFeeAmount) ? round(Number(party.NoShowFeeAmount), 2) : 0) +  round(Number(party.PartyPayments?.partyAmount || 0), 2) ;
           // amount = Number(amount.toFixed(2)) + Number(party.TaxAmount || 0) + Number(party.TotalServiceChargeAmount || party['bookingAmount'] || 0) + Number(party.TaxOnServiceChargeAmount || party['taxOnServiceCharge'] || 0) ;
            this.financialDetail = { status: 'was authorized', date: party.CreatedAt, amount, header: 'Financial Details' };
            this.IsCharged = true;
            if (amount <= 0) {
              this.financialDetail.status = this.ts.instant('noCreditCardText');
              this.financialDetail.header = this.ts.instant('paymentDetails');
              this.IsCharged = false;
            }
            return;
          case 'ignored':
            amount = round(Number(party.NoShowFeeAmount), 2) + round(Number(party.PartyPayments?.partyAmount || 0), 2);
           // amount = Number(amount.toFixed(2)) + Number(party.TaxAmount || 0) + Number(party.TotalServiceChargeAmount || party['bookingAmount'] || 0) + Number(party.TaxOnServiceChargeAmount || party['taxOnServiceCharge'] || 0) ;
            this.financialDetail = { status: 'was ignored', date: party.CreatedAt, amount, header: 'Financial Details' };
            this.IsCharged = true;
            if (amount <= 0) {
              this.financialDetail.status = this.ts.instant('noPaymentText');
              this.financialDetail.header = this.ts.instant('paymentDetails');
              this.IsCharged = false;
            }
            return;
        }
      }
      this._ps.getPartyPaymentStaus(party.Id).subscribe(data => {
        amount = data.Payload.PaidAmount;
        if (amount > 0 && amount <= data.Payload.TotalAmount) {
          this.financialDetail = { status: this.ts.instant('hasPaid'), date: party.ChargedAt, amount, header: this.ts.instant("financialdetails") };
          this.IsCharged = true;
          return;
        }
        switch (status) {
          case 'paid':
            //amount = ((party.SpecialMealAmount) ? round(Number(party.SpecialMealAmount), 2) : 0) + ((party.RatePlanAmount) ? round(Number(party.RatePlanAmount), 2) : 0) + ((party.NoShowFeeAmount) ? round(Number(party.NoShowFeeAmount), 2) : 0);
            amount = amount.toFixed(2);
            this.financialDetail = { status: this.ts.instant('hasPaid'), date: party.ChargedAt, amount, header: this.ts.instant("financialdetails") };
            this.IsCharged = true;
            if (amount <= 0) {
              this.financialDetail.status = this.ts.instant('noPaymentText');
              this.financialDetail.header = this.ts.instant('paymentDetails');
              this.IsCharged = false;
            }
            break;
          case 'notPaid':
            this.financialDetail.status = this.ts.instant('noPaymentText');
            this.financialDetail.header = this.ts.instant('paymentDetails')
            this.IsCharged = false;
            break;
        }
      });
    }

    event.preventDefault();
    event.stopPropagation();
  }

  openTable(party, reservationType, isCurrentFloor?) {
    if (party.State !== PartyState.Seated && party.State !== PartyState.Left && party.State !== PartyState.Cancelled) {
      if (reservationType === ReservationType.Waitlist) {
        this._ps.isAssignTables = true;
        this._ps.reservationDateForBlockingRule = new Date(Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta));
      }
      else {
        this._ps.reservationDateForBlockingRule = party.ReservedFor;
      }
      if (reservationType === ReservationType.Reservation && this.generalSettings.General.SlottingMode === SlottingMode.Auto) {
        this.ps.restrictCloseDialog = true;
        this.ps.isRestrictCloseDialog = true;
      }
      
      const popupInput = { dashboardData: party, seatParty: true, selectTables: true, isCurrentFloor: isCurrentFloor };
      const componentDetails: ComponentDetails = Utilities.setComponentDetails(PreferredTableComponent, 'large', 'active', popupInput);
      const dialogRef = this.openCustomPopup(componentDetails, ComponentTypes.table, '100%', '100%', false, this.ts.instant('tableSelect'), this.ts.instant('ok'),
        'Cancel', false, '100vw', 'preferred-table-panel', true);
        this.ps.restrictCloseDialog = true;
      this.cancelSubscription = this.ps.cancelledAction$.subscribe((data) => {
        if (data.from === ComponentTypes.table || data.from === ComponentTypes.commonconfirmmessage) {
          this.ps.closeDialog$.next();
          this.ps.restrictCloseDialog = false;
          this.ps.isRestrictCloseDialog = false;
          this._ps.isAssignTables = false;
        }
      });
      this.confirmSubscription = this.ps.confirmedAction$.subscribe((data) => {
        if (data === ComponentTypes.table) {
          let tableWithSpecialEvent = this._ps.getSpecialEventAvailability(this._ps.SelectedTableIds);
          if (this._ps.SelectedTableIds.length > 0 && !tableWithSpecialEvent?.length) {
              this.ps.restrictCloseDialog = false;
              this.ps.isRestrictCloseDialog = false;
              this.ps.closeDialog$.next(ComponentTypes.table);

          }
          if (this.ps.restrictCloseDialog || ( tableWithSpecialEvent?.length && this.type === ReservationType.Waitlist)) {
            const msg = tableWithSpecialEvent?.length ? this._ps.getSpecialEventName(tableWithSpecialEvent) : this.ts.instant('unassignTableError');
            const headerTitle = this.ts.instant('deleteTitle');
            const popUpMessage = [{
              confirmationMessage: msg, dialogTitle: 'confirm', showAlert: true
            }];
            const alertComponentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small',
              'active', popUpMessage, popUpMessage[0].dialogTitle);
            this.openCustomPopup(alertComponentDetails, ComponentTypes.commonconfirmmessage, '450px', 'auto',
              false, headerTitle, 'Ok', 'Cancel', true);
          } else {
            this.triggerAssignTablesAPI(party  , false);
          }
        } else if (data === ComponentTypes.commonconfirmmessage) {
          this.ps.closeDialog$.next(ComponentTypes.commonconfirmmessage);
          this.triggerAssignTablesAPI(party , true);
        }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.ps.restrictCloseDialog = false;
        this.ps.isRestrictCloseDialog = false;
        this._ps.isAssignTables = false;
        this._ps.reservationDateForBlockingRule = null;
        if (this.cancelSubscription) {
          this.cancelSubscription.unsubscribe();
        }
        if (this.confirmSubscription) {
          this.confirmSubscription.unsubscribe();
        }
      });
    }
  }
  
  triggerAssignTablesAPI(party , ignoreEventBlock:boolean = false) {
    let canUpdateReservation = this._ps.CheckRatePlanDifference(this._ps.SelectedTableIds, party, true);
    if (!canUpdateReservation) {
      this.showErrorPopUp(this.ts.instant('blockReservationUpdate'));
      return;
    }
    this._ps.isAssignTables = false;
    if (party.Type == ReservationType.Reservation) {
      this._ps.assignTables(party.Id, this._ps.SelectedTableIds, party , ignoreEventBlock);
    } else {
      let selectedTable = { } as SeatingInfoDTO;
      selectedTable.StandaloneTableIds = this._ps.SelectedTableIds;
      this.subscriptions.add(this._ps.assignTables(party.Id, selectedTable, party).subscribe((data) => { }));
    }
  }

  showErrorPopUp(errorMsg) {
    const popUpMessage = [{
      confirmationMessage: errorMsg,
      dialogTitle: this.ts.instant('alert'),
      showAlert: true
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'action',

      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: this.ts.instant('alert')
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '430px',
      data: {
        title: this.ts.instant('alert'),
        showAction: true,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.specialValidation,
      }
    });
  }

  trackFunction(index) {
    return index;
  }

  triggerPartyStatusPopup(popover, party) {
    if (!this.partyStatusPopupHide.includes(party.State)) {
      popover.toggle();
      this.selectedStatusParty = party;
    }
  }

  seatStatusChanged(event) {
    this.subscriptions.add(this._ps.postSeatedPartyStatus(Utilities.RestaurantId(), this.selectedStatusParty.Id, event).subscribe(val => {

    }));
    if (this.partyStatusPopup)
      this.partyStatusPopup.close();
  }
  updateAddOnDetails(party) {
    this.partyWithAddon = party
  }
}


@Pipe({ name: 'negativeNumber' })

export class NegativeNumberFormatPipe implements PipeTransform {
  transform(value, date, settings: SettingsDTO) {
    const textArray = value.split(' ');
    if (parseInt(textArray[0]) < 0 && Utilities.diffBetweenDates(date, Utilities.getRestaurantDateTime(settings.General.DaylightDelta)) < 0) {
      return (Math.abs(textArray[0]) + ' ' + textArray[1]);
    } else {
      return '';
    }
  }
}

@Pipe({ name: 'formatWaitTime' })

export class FormatWaitTimePipe implements PipeTransform {
  constructor(private ts: TranslateService) {

  }

  transform(time) {
    if (time === -1 || (isNaN(time))) {
      return '?';
    }
    if (time == 0) {
      return this.ts.instant('partyStatusSeatText');
    }
    let days;
    let hours;
    let minutes;
    let seconds;
    days = Math.floor(time / 86400);
    time -= days * 86400;
    hours = Math.floor(time / 3600) % 24;
    time -= hours * 3600;
    minutes = Math.floor(time / 60) % 60;
    time -= minutes * 60;
    seconds = time % 60;
    //   return ((60*hours)+(24*days)+(minutes));
    let dayHours = 0;
    if (days > 0) {
      dayHours = (days * 24);
    }
    if ((hours + dayHours) > 9) {
      return '>9' + this.ts.instant('hrsShortText');
    }
    if (hours + dayHours > 0 || minutes > 0 || seconds > 0) {
      return [
        (hours + dayHours) > 0 ? (hours + dayHours) + this.ts.instant('hrsShortText') : '',
        minutes > 0 ? minutes + this.ts.instant('minsShortText') : ((hours > 0) ? '' : '<1'+this.ts.instant('minsShortText'))//,
        //seconds > 0 && (hours + dayHours) < 1 ? seconds + 's' : ''
      ].join(' ');
    } else {
      return '0'+this.ts.instant('minsShortText');
    }

  }
}

@Pipe({ name: 'communicationIcon' })

export class CommunicationIconPipe implements PipeTransform {
  // tslint:disable-next-line: variable-name
  constructor(private _as: AppService) {

  }

  transform(pageMethod) {
    return this._as.getCommunicationIcon(pageMethod);
  }
}
