import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CacheService } from '@app/core/services/cache.service';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { ComponentTypes, PartyState } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { Utilities } from '@app/shared/utilities/utilities';
import { PartyService } from '@app/shared/services/party.service';
import moment from 'moment';

@Component({
  selector: 'app-cancel-session-popup',
  templateUrl: './cancel-session-popup.component.html',
  styleUrls: ['./cancel-session-popup.component.scss']
})
export class CancelSessionPopupComponent implements OnInit {
  @ViewChild('reasons', { static: true }) reasonsForm: DynamicFormComponent;
  bookings: any[] = [];
  sessionData: any;
  activityData: any;
  warningMessage: string;
  cancellationDate: any;
  reasonConfig: FieldConfig[] = [];
  constructor(public popupService: PopupService, @Inject(MAT_DIALOG_DATA) public dialogData, public ts: TranslateService, public cs: CacheService, private partyService: PartyService ,  public dialog: MatDialog) { }

  ngOnInit() {
    this.activityData = this.dialogData.componentDetails.popupInput?.activityData;
    this.sessionData = this.dialogData.componentDetails.popupInput?.sessionData;
    this.cancellationDate = this.dialogData.componentDetails.popupInput?.cancellationDate;
    this.bookings = this.dialogData.componentDetails.popupInput?.bookings;
    this.sessionData.startTime = new Date('1970-01-01 ' + this.sessionData.StartTime);
    this.sessionData.endTime = new Date('1970-01-01 ' + this.sessionData.EndTime);
    this.getCancellationMsg();
  }
  ngAfterViewInit() {
    this.popupActions();
    this.dynamicFormValidation();
    this.reasonsForm.form.valueChanges.subscribe((data) => {
      if (data) {
        this.dynamicFormValidation();
      }
    })
  }

  getCancellationMsg() {
    this.warningMessage = this.ts.instant('sessionCancelWarningMessage');
    if (this.bookings?.length && this.bookings.filter(booking => booking.State !== PartyState.Cancelled && booking.State !== PartyState.Left)?.length) {
      this.warningMessage = this.ts.instant('sessionWithBookingWarningMessage');
    }
    this.reasonConfig = [
      {
        type: 'textarea',
        name: 'Reason',
        label: this.ts.instant('CancelReason'),
        inputType: 'text',
        showHint: true,
        charLength: 400,
        value: '',
        textAreaAppearance: 'outline',
        rows: 3,
        disableErrorStateMatcher: false,
        isStarSymbolRequired: false,
        class: 'w-100'

      },
      {
        type: 'checkbox',
        name: 'SendMail',
        value: true,
        placeholder: this.ts.instant('SendMailConfirmation'),
        class: 'session-edit-send-mail-chekbox'
      }
    ];
  }

  dynamicFormValidation() {
    let isValid = this.reasonsForm?.value?.Reason;
    this.popupService.saveBtnEnable$.next(isValid);
  }
  popupActions() {
    let confirmedAction = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.cancelIndividualSession) {
        this.createCancelRequest()
        confirmedAction.unsubscribe()
      }
    });
    let cancelAction = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.cancelIndividualSession) {
        cancelAction.unsubscribe();
      }
    });
  }
  createCancelRequest() {
    const ActivitySessionId = this.sessionData.ParentSessionId ? this.sessionData.ActivitySessionId : 0;
    const locationMappings = this.sessionData.SessionLocationMappings?.map(val => {
        val.ActivitySessionId = ActivitySessionId;
        val.Id = 0;
        return val;
    });

    const staffMappings = this.sessionData.SessionStaffMappings?.map(val => {
        val.ActivitySessionId = ActivitySessionId;
        val.Id = 0;
        return val;
    });
    let reqObj = [{
      ActivitySessionId,
      ParentSessionId:  this.sessionData.ParentSessionId ?  this.sessionData.ParentSessionId :  this.sessionData.ActivitySessionId,
      Dayofweek: this.sessionData.Dayofweek,
      SpecialMealId: this.activityData.Id,
      Reason: this.reasonsForm?.value?.Reason,
      Index: this.sessionData.Index,
      SessionDate: moment(this.cancellationDate).format('YYYY-MM-DD') + "T00:00:00.000",
      AllocationTypeId: null,
      StartTime: this.sessionData.StartTime,
      EndTime: this.sessionData.EndTime,
      SessionLocationMappings:locationMappings,
      SessionStaffMappings: staffMappings,
      LocationGroupId: this.sessionData.LocationGroupId
    }]
    this.partyService.cancelSession(reqObj, this.activityData.Id, this.reasonsForm?.value?.SendMail).subscribe(response => {
    this.dialog?.closeAll();
      if (response) {
        this.showSuccessDialog();
      }
    }, err => {
    }, () => { })
  }

  showSuccessDialog() {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant('SessionCancelSuccess'),
      dialogTitle: null,
    }];
    let okbutton = this.ts.instant('ok');
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
      popUpMessage, popUpMessage[0].dialogTitle);
    this.partyService.openAppPopup(componentDetails, ComponentTypes.ModifySessionConfirmation, '400px', '400px', true, '', okbutton,
      '', true);
    let popupSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val == ComponentTypes.ModifySessionConfirmation) {
        popupSubscription.unsubscribe();
      }
    });
  }
}
