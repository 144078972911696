import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, QueryList, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import { ApiService } from "@app/activities-timeline/services/api.service";
import { DynamicFormComponent } from "@app/shared/dynamicform/dynamic-form/dynamic-form.component";
import { ButtonValue, FieldConfig } from "@app/shared/dynamicform/models/field-config.interface";
import { Change } from "@app/shared/models/SignalRDto";
import { PhoneNumberFormatterPipe } from "@app/shared/pipes/phone-number-formatter.pipe";
import { FacadeService } from "@app/shared/services/facade.service";
import { PartyService } from "@app/shared/services/party.service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { EmailSelectorPipe, phoneNumberSelectorPipe } from "../guest-selection/guest-selection.component";
import { EventIntializers } from "@app/shared/constants/globalConstants";
import { BookingTypeHandler } from "@app/shared/utilities/BookingTypeHandler";
import { PopupService } from "@app/popup-module/popup.service";
import { MemberInfoBase, SettingType } from "@app/shared/models/RestaurantDTO";
import { EngageMemberByCardIdRequestDTO } from "@app/shared/models/EngageMemberByCardIdRequestDTO";
import { CacheService } from "@app/core/services/cache.service";
import { buttonTypes } from "@app/shared/constants/commonenums";



@Component({
    selector: 'guest-select',
    templateUrl: './guest-select.component.html',
    styleUrls: ['./guest-select.component.scss']
})
export class GuestSelectComponent implements OnInit, AfterViewInit, OnDestroy {
    
    @Input() membershipAvailable;
    @Input() roomNoSearch: boolean = false;
    @Input() _settings:any;
    @Output() onGuestSelect: EventEmitter<any> = new EventEmitter();
    hasRoomNUmberSearch = false;
    public config: FieldConfig[];
    @ViewChild('form') dynamicForm: DynamicFormComponent;
    public guestContacts: any[];
    public subscription: Subscription = new Subscription();
    platformGuestSearchEnable: boolean = false;
    searchValue: string;
    hasCGPSearch = false;
    searchType = 1;
    memberInfo : MemberInfoBase;
    isPMSSearchEnabled:boolean = false;
    isStayConfirmationEnabled:boolean = false;
    configGuestSearch: FieldConfig[];
    configGuestSearchBy: FieldConfig[];
    _merchantSetting:any;
    isMembershipAvailable = false;
    settingType = SettingType;
    @ViewChild('configGuestSearchByForm', { static: true }) configGuestSearchByForm: DynamicFormComponent;
    @ViewChild('configGuestSearchForm', { static: true }) configGuestSearchForm: DynamicFormComponent;
    autoSearchEnabled: boolean = true;
    buttonSearch: ButtonValue;
    constructor(public cs:CacheService,public facadeService:FacadeService,private ts:TranslateService,private emailSelector: EmailSelectorPipe, private phoneSelector: phoneNumberSelectorPipe,private apiService:ApiService,private bh:BookingTypeHandler,private popupService:PopupService){
      this.subscription.add(this.cs.settings.subscribe(sett => {
        this._settings = sett;
        this._merchantSetting = this._settings.MerchantSettings;
        this.searchType = Number(this._merchantSetting?.DefaultGuestSearchOption?.SettingValue) || 1;
        if(!this._merchantSetting?.AutoSearchEnabled || this._merchantSetting?.AutoSearchEnabled?.SettingValue == SettingType.ENABLE) {
          this.autoSearchEnabled = true;
        }else {
          this.autoSearchEnabled = false;
        }
        this.loadSearchLabel(this.searchType);
        this.setSearchProperty();
        this.isMembershipAvailable = this._settings.General.UseMembershipNumber;
      }));

    }    
    memberShipArr:any =[];
    isRoomNumberSearchEnabled = false;
    ngOnInit() {
      this.hasRoomNUmberSearch = this._settings.HasRoomNumberSearch;
      this.hasCGPSearch = this._settings.HasCGPSearch;
      let optionval = this.getsupportedoption();
      if(!optionval.find(option => option.id == this.searchType)){
        this.searchType = 1;
      }
        this.config = [
            {
              type: 'input',
              name: 'guestSearch',
              inputType: 'text',
              label: 'selectGuestSearchText'
            }
          ];
          this.configGuestSearchBy = [
            {
              type: 'radio',
              name: 'byguestSearch',
              inputType: 'text',
              class: 'reservation-guest__search-field-input',
              options: optionval,
              cellClick: this.setGuestSearch.bind(this),
              value: this.searchType
            }
          ];
          this.configGuestSearch = [
            {
              type: 'input',
              name: 'guestSearch',
              inputType: 'text',
              label: this.loadSearchLabel(this.searchType),
              class: 'reservation-guest__search-field-input',
              blurClick: this.blurClick.bind(this)
            }
          ];
          this.buttonSearch = {
            type: buttonTypes.actionSecondarySmall,
            label: 'searchText',
            customclass: 'reservation-guest__search-guest-btn'
          }
    }

    setGuestSearch(eve) {
      this.guestContacts = [];
      this.memberShipArr = [];
      this.searchType = eve.value;
      this.isRoomNumberSearchEnabled = false;
      this.configGuestSearchForm.form.controls.guestSearch.setValue('', { emitEvent: false })
      this.setSearchProperty();
      this.configGuestSearch[0].label = this.loadSearchLabel(this.searchType)
    }

    setSearchProperty(){
      this.isPMSSearchEnabled = this.platformGuestSearchEnable = (this.searchType == 7)  ? true: false;
      this.isStayConfirmationEnabled = this.searchType == 6 ? true: false;
      this.isRoomNumberSearchEnabled = this.searchType == 3 ? true: false
    }

    getStayContact(item){
      this.facadeService.guestbookService.createGuestHandler(false, null, false, EventIntializers.CREATEGUESTFORRESERVATION, item, EventIntializers.CREATEGUESTFORRESERVATION);
     this.fetchContactDetails(item)
  
    }
  
    loadSearchLabel(value) {
      switch (value) {
        case 1:
          return 'selectGuestSearchText'
        case 4:
          return 'searchbyphoneandemail'
        case 3:
          return 'searchByRoomText'
        case 7:
          return 'searchPMSLabel'
        case 6:
          return 'searchStayConfirmationLabel'
      }
    }

    getsupportedoption() {
      const radioarray = [];
      
      this.hasRoomNUmberSearch = this._settings.HasRoomNumberSearch;
       this.hasCGPSearch = this._settings.HasCGPSearch;
      radioarray.push({ id: 1, value: this.ts.instant("reserveText") })
      if(this._settings.General?.EngageIntegrationDTO?.IsEnabled){
        radioarray.push({ id: 4, value: this.ts.instant("memberText")})
      }
      if (this.hasRoomNUmberSearch) {
        radioarray.push(
        { id: 3, value: this.ts.instant("RoomNo") },
        { id: 6, value: this.ts.instant("stayConfirmation")},
        // { id: 7, value: this.ts.instant("pmsSearch")}
      );
      }
      if(this.hasCGPSearch || this.hasRoomNUmberSearch){
        radioarray.push({ id: 7, value: this.ts.instant("pmsSearch")})
      }
      return radioarray;
    }
    ngOnChanges(changes: SimpleChanges){

    if(changes?.roomNoSearch && this.config){
      this.config[0].label = changes.roomNoSearch.currentValue ? 'searchByRoomText' : 'selectGuestSearchText'
    }
  }

  // platFormToggleChange(){
  //   if(this.searchValue)
  //   this.searchContacts(this.searchValue);
  // }
  searchContacts(value, isRoomNumberSearchEnabled) {
    let searchValue = value;
    if (this.searchType == 4) {
      this.memberSearchHandler(value);
      
    }
    else if (searchValue && (searchValue.length >= 2)) {
      this.subscription.add(this.facadeService.getContactSuggestion(searchValue, isRoomNumberSearchEnabled,0,10,this.platformGuestSearchEnable,this.isStayConfirmationEnabled, this.isPMSSearchEnabled).subscribe((data) => {
        if (data) {
          this.guestContacts = [];
          data.forEach(contact => {
            contact.modifiedEmail = this.emailSelector.transform(contact.EmailAddress, contact.EmailAddress2, searchValue);
            contact.modifiedPhone = this.phoneSelector.transform(contact.PhoneNumber, contact.PhoneNumber2, contact.CountryPhoneCode, contact.CountryPhoneCode2, searchValue);
          });
          this.guestContacts = this.guestContacts.concat(data);
          // this.showAddNewGuestBtn = (this.guestContacts.length > 0 || this.memberShipArr.length > 0) ? false : true;
          // this.showMore = data && data.length > 10;
        }
      }));
    } else {
      this.guestContacts = [];
    //  this.showAddNewGuestBtn = true;
    }
  }

  memberSearchHandler(searchText: string) {
    if (searchText && searchText.length) {
      this.apiService.wildSearchEngageMember(searchText).subscribe(response => {
        this.memberShipArr = response.Payload;
      //  this.showAddNewGuestBtn = this.memberShipArr.length > 0 ? false :true
      })
    }
  }

    ngAfterViewInit(): void {
        // this.dynamicForm.form.valueChanges.pipe(debounceTime(600), distinctUntilChanged()).subscribe(value => {
        //   this.searchValue = value.guestSearch;
        //    this.searchContacts(value.guestSearch);
        // })
        this.formChange();
    }
    formChange() {
      this.configGuestSearchForm.form?.valueChanges.pipe(debounceTime(600), distinctUntilChanged()).subscribe(value => {
        if (value.guestSearch) {
          this.facadeService.guestbookService.searchStringForNewGuest = value.guestSearch;
          this.searchValue = value.guestSearch;
          if(this.autoSearchEnabled) {
            this.searchContacts(value.guestSearch, this.isRoomNumberSearchEnabled);
          }
        }
      });
    }

    getMemberDetails(contact) {    
    
      let engageIntegrationData = this._settings.General.EngageIntegrationDTO;
      let obj: EngageMemberByCardIdRequestDTO = new EngageMemberByCardIdRequestDTO(contact.CardNo, engageIntegrationData?.TenantId, engageIntegrationData?.SiteId);
      this.memberInfo = new MemberInfoBase(contact.CardNo);
      this.apiService.getEngageMemberByCardId(obj).subscribe((memberResponse) => {
        if (memberResponse.SuccessCode) {
          this.setMemberDetails(memberResponse);
          let createContactReq = this.createContactReq(memberResponse.ProfileInfoGetByCardId.ProfileValue);
          this.facadeService.guestbookService.createGuestHandler(false, null, false, EventIntializers.CREATEGUESTFORRESERVATION, createContactReq, EventIntializers.CREATEGUESTFORRESERVATION);
          this.fetchContactDetails(contact);
        }
      })
    }

    createContactReq(memberShipdetail) {
      let contact = {
         FirstName: memberShipdetail.FName,
         LastName: memberShipdetail.LName,
         EmailAddress: memberShipdetail?.EmailList[0]?.EmailId,
         PhoneNumber: memberShipdetail?.PhoneList[0]?.PhoneNumber,
         CountryId: this.facadeService.partyService.getCountryId(memberShipdetail?.PhoneList[0]?.PhoneCode),
         PIILastUsedDate: null,
         Birthday: memberShipdetail.Dob,
         ContactCustomFields: [],
         Notes: [],
         VisitStats: [],
         MarketingOptedIn: null,
         MemberAllocationTypesIds : [],
         TrackMembershipNumber: memberShipdetail.CardNo,
         RateType: memberShipdetail.RateType,
         MembershipType: memberShipdetail.MembershipType,
         MemberRateTypeAfterRoundsUsed : memberShipdetail.RateTypeAfterRoundsUsed,
         MemberRateTypeMembershipExpiry : memberShipdetail.RateTypeMembershipExpiry,
         IsMemberActive: !memberShipdetail.IsMembershipExpired
       }
       return contact;
     }

    setMemberDetails(memberResponse){
      if(memberResponse?.ProfileInfoGetByCardId?.ProfileValue){
        let profileValue =  memberResponse.ProfileInfoGetByCardId.ProfileValue;
        
        this.memberInfo.IsMembershipExpired = profileValue.IsMembershipExpired;
        this.memberInfo.MembershipType = profileValue.MembershipType
        this.memberInfo.PlayerType = profileValue.PlayerType;
        
        this.memberInfo.RateType = profileValue.RateType;
        this.memberInfo.RateTypeAfterRoundsUsed = profileValue.RateTypeAfterRoundsUsed;
        this.memberInfo.RateTypeMembershipExpiry = profileValue.RateTypeMembershipExpiry;
        this.memberInfo.ConsumedMinutes = 0;
        this.memberInfo.CourseName = "";
        this.memberInfo.Bucket = memberResponse.GolfPoints;
      }
    
    }

    
  getGuestDetails(contactId, isEdit?, fullContact?) {

 

    if (fullContact?.isFromExternalResource || this.platformGuestSearchEnable || !contactId) {
      if((this.platformGuestSearchEnable && fullContact.CommonGuestProfileUUID) || (!contactId && fullContact.CommonGuestProfileUUID)){
        fullContact.primaryGuestId = '';
        fullContact.primaryGuestUuId = fullContact.CommonGuestProfileUUID;
      }
      this.getCommonGuestProfile(fullContact , isEdit);
    } else {
      if (this.searchType !== 2 && contactId) {
        fullContact.SearchType = this.searchType;
        let payload = this.bh.formValidationDTO(fullContact, this.facadeService.partyService.reservationFormGroup.value.selectedTime.DateTime, this.facadeService.partyService.reservationFormGroup.value.selectedTime.DateTime)
        this.bh.validateBookingTypeForGuest([payload]);   
        let subscription = this.bh.proceedValidation.subscribe(info =>{   
          subscription.unsubscribe();
          if(info.state == 'valid'){
            let subscription = this.facadeService.getContact(contactId).subscribe(data => {
              this.setguestDetails(data.Payload);   
              subscription.unsubscribe();
            });
          }
          else if(info.state == 'invalid'){
            this.popupService.previousEvent$.next(true)
          }
        }) 
      


      }
      else {
        let modifycontact = fullContact;
        if (fullContact.Id == 0) {
          modifycontact.Id = null;
        }
        this.setguestDetails(modifycontact);
      }
    }
  
  }

  getCommonGuestProfile(commonGuestProfile ,isEdit?) {
    this.subscription.add(this.facadeService.getCommonProfileContact(commonGuestProfile.primaryGuestId, commonGuestProfile.primaryGuestUuId).subscribe(data => {
      this.setguestDetails(data.Payload );
    }));
  }

  

    fetchContactDetails(data){
      let createEditGuestSubscription = this.facadeService.guestbookService.createEditGuestSubscription$.subscribe((response) => {
        let contactId;
        if(response.Payload){
            contactId = response.Payload.ContactId || response.Payload.Id;
        }else if(response.Change?.ChangeSet?.AdditionalData[0]?.Id){
            contactId = response.Change?.ChangeSet?.AdditionalData[0]?.Id;
        }
        data.Id = contactId;
        data.SearchType = this.searchType;
        createEditGuestSubscription.unsubscribe();
        let payload = this.bh.formValidationDTO(data, this.facadeService.partyService.reservationFormGroup.value.selectedTime.DateTime, this.facadeService.partyService.reservationFormGroup.value.selectedTime.DateTime)
        this.bh.validateBookingTypeForGuest([payload]);   
        let subscription = this.bh.proceedValidation.subscribe(info =>{
          subscription.unsubscribe();
          if(info.state == 'valid' ){
            this.subscription.add(this.facadeService.guestbookService.getContact(contactId).subscribe(({ Payload: contact }) => {
              contact.StayInfo = {startDate: data.Staystartdate,endDate: data.StayEnddate};
              contact.SearchType = this.searchType;
              contact.MemberInfo = this.memberInfo;
              contact.StayConfirmationCode = data.StayConfirmationNumber || null;
              this.setguestDetails(contact);
              
            }))
          }
          else if(info.state == 'invalid'){
            this.popupService.previousEvent$.next(true)
          }
        });
    })
    }

   
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    setguestDetails(data){
        if (this.searchType == 2) {
          this.subscription.add(this.facadeService.getProfilePreference(data.CommonGuestProfileUUID).subscribe(data => {
            if (data) {
              if (Array.isArray(data)) {
                this.facadeService.partyService.selectedMemberGuestPreferences = data;
              } else {
                this.facadeService.partyService.selectedMemberGuestPreferences = [data];
              }
            }
          }));
        }      
        this.onGuestSelect.emit(data);
        this.configGuestSearchForm.form.controls.guestSearch.setValue('', { emitEvent: false })
            this.guestContacts = [];
            this.memberShipArr =[];
      }

      searchGuest(event) {
        if(this.searchValue && !this.autoSearchEnabled) {
          let roomNoSearch = this.searchType == 3;
          this.searchContacts(this.searchValue, roomNoSearch);
        }else {
          this.guestContacts = [];
          this.memberShipArr = [];
        }
      }
    
      blurClick(event) {
        if(event?.keyCode == 13 && !this.autoSearchEnabled) {
          this.searchValue = event.target.value;
          let roomNoSearch = this.searchType == 3;
          this.searchContacts(this.searchValue, roomNoSearch);
        }else if(event?.keyCode == 13 && !this.autoSearchEnabled){
          this.guestContacts = [];
          this.memberShipArr = [];
        }
      }
    
    
}

@Pipe({
  name: 'phoneNumberSelector1'
})
export class phoneNumberSelectorPipe1 implements PipeTransform {
  constructor(private phoneNumberFormatterPipe:PhoneNumberFormatterPipe,private ps: PartyService) { }
  transform(value1:string, value2:string, countryCode1:string, countryCode2:string, searchString:string): string {
    if(value1 && searchString && value1.indexOf(searchString) !== -1 && countryCode1){
      let UniqueCountry = this.ps._settings.Countries.find( (country) =>{ return country.CountryPhoneCode == countryCode1})
      return this.phoneNumberFormatterPipe.transform(value1,UniqueCountry.Id);
    }
    else if(value2 && searchString && value2.indexOf(searchString) !== -1 && countryCode2){
      let UniqueCountry = this.ps._settings.Countries.find( (country) =>{ return country.CountryPhoneCode == countryCode2})
      return this.phoneNumberFormatterPipe.transform(value2,UniqueCountry.Id);
    }
    else{
      let UniqueCountry1 = this.ps._settings.Countries.find( (country) =>{ return country.CountryPhoneCode == countryCode1})
      let UniqueCountry2 = this.ps._settings.Countries.find( (country) =>{ return country.CountryPhoneCode == countryCode2})
      if (UniqueCountry1 || UniqueCountry2)
        return value1 && countryCode1 ? this.phoneNumberFormatterPipe.transform(value1, UniqueCountry1.Id) : value2 && countryCode2 ? this.phoneNumberFormatterPipe.transform(value2, UniqueCountry2.Id) : "NA"
      else {
        let defaultCountry = this.ps._settings.Countries.find(c => c.Name == 'USA').Id;
        return value1 && defaultCountry ?  this.phoneNumberFormatterPipe.transform(value1, defaultCountry) : value2 && defaultCountry ? this.phoneNumberFormatterPipe.transform(value2, defaultCountry) : "NA"
      }
    }
  }

}