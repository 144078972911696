import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IntegrationOperationsLog, IntegrationOperationsStatus, IntegrationOperationsTransactionType, PMAgentURL } from 'src/app/common/Models/common.models';
import { ServiceParams } from 'src/app/common/Models/http.model';
import { RetailRoutes } from '../../retail-route';
import { PaymentMethods } from '../business/shared.modals';
import { Host } from '../globalsContant';
import { IntegrationLog } from '../shared.modal';
import { HttpMethod, HttpServiceCall } from './http-call.service';
import { PaymentCommunication } from '../../payment-communication.service';

@Injectable({ providedIn: 'root' })
export class RetailIntegrationLogService {

  constructor(private http: HttpServiceCall, private _retailHttp: PaymentCommunication) { }

  public async sendLogData(route: string, screen: string, input: string, output: string, action: string, correlationId: string) {
    let machineId = parseInt(sessionStorage.getItem('MachineId'));
    let machineName = sessionStorage.getItem('MachineName')?.toString();
    if (isNaN(machineId) || machineId == null) {
      machineId = 0;
    }
    if (machineName == undefined || machineId == null) {
      machineName = "";
    }
    let data: IntegrationLog = {
      id: 0,
      Route: route,
      ScreenRoute: screen,
      Request: input,
      Response: output,
      MachineId: machineId,
      MachineName: machineName,
      CorrelationId: correlationId
    }
    if (action == "CREATE") {
      return await this.postIntegrationsLog(data);
    }
    if (action == "UPDATE") {
      return await this.updateIntegrationsLog(data);
    }
  }

  public async postIntegrationsLog(log: IntegrationLog) {
    try {
      const routeUrl = 'SaveIntegrationsLog';
      let result = await this.http.CallApiAsync<IntegrationLog>({ host: Host.payment, method: HttpMethod.Post, callDesc: routeUrl, body: log })
      return result.result;
    } catch (ex) {
    }

    return { result: [] };
  }

  public async updateIntegrationsLog(log: IntegrationLog) {
    try {
      const routeUrl = 'UpdateIntegrationsLog';
      return await this.http.CallApiAsync<IntegrationLog>({ host: Host.payment, method: HttpMethod.Put, callDesc: routeUrl, body: log })
    } catch (ex) { }
    return { result: [] };
  }

  GetRequestId(isPMAgentCall: boolean, screenRoute: string, req: HttpRequest<any>): Observable<any> {
    const tenderId = req.body?.inquirerInfo?.tenderId?.split('.')?.[1];
    if (isPMAgentCall && (tenderId == PaymentMethods.CreditCard || tenderId == PaymentMethods.IDTECH) && (req.url.toLowerCase().includes(PMAgentURL.Sale) || req.url.toLowerCase().includes(PMAgentURL.Auth) || req.url.toLowerCase().includes(PMAgentURL.Credit))) {
      let request: IntegrationOperationsLog = {
        request: JSON.stringify(req.body),
        route: screenRoute,
        tenderId: tenderId,
        agentVersion: req.body.agentVersion,
        hostName: req.body.hostName,
        ipAddress: req.body.ipAddress,
        type: this.GetTransactionType(req),
        typeId: req.body?.inquirerInfo?.posTransactionId??0,
        status: IntegrationOperationsStatus.Initiated,
        amount: req.body?.amount.requestAmount,
        ticketNumber: req.body?.inquirerInfo?.isFolioPosting ?  req.body.inquirerInfo?.postId : req.body?.inquirerInfo?.orderNumber
        
      }
      return this.InsertIntergrationOPLog(request);
    } else return of("");
  }

  InsertIntergrationOPLog(request: IntegrationOperationsLog) {
    let requestParam: ServiceParams = {
      route: RetailRoutes.CreateIntegrationOperationsLog,
      body: request
    }
    return this._retailHttp.Post<IntegrationOperationsLog>(requestParam);
  }

  UpsertIntergrationOPLog(request: IntegrationOperationsLog) {
    let requestParam: ServiceParams = {
      route: RetailRoutes.UpsertIntegrationOperationsLog,
      body: request
    }
    return this._retailHttp.putPromise<IntegrationOperationsLog>(requestParam, false);
  }

  isDeviceTransaction(paymentTypeId: number): boolean {
    return (paymentTypeId == PaymentMethods.CreditCard || paymentTypeId == PaymentMethods.IDTECH ||
      paymentTypeId == PaymentMethods.V1GiftCard || paymentTypeId == PaymentMethods.ExternalGiftCard ||
      paymentTypeId == PaymentMethods.V1GiftCardIdTech || paymentTypeId == PaymentMethods.ExternalGiftCardIdTech ||
      paymentTypeId == PaymentMethods.AgilysysGiftCard || paymentTypeId == PaymentMethods.AgilysysGiftCardIdTech)
  }

  GetTransactionType(req: HttpRequest<any>): IntegrationOperationsTransactionType {
    let transType = 0;
    try {
      let paymentTypeId = req.body?.inquirerInfo?.tenderId?.split(".")?.[1];
      if (this.isDeviceTransaction(paymentTypeId)) {
        if (req.body?.inquirerInfo?.isFolioPosting) {
          if (req.url.toLowerCase().includes(PMAgentURL.Sale)) {
            transType = IntegrationOperationsTransactionType.CreditCardSalePaymentForFolioPosting
          } else if (req.url.toLowerCase().includes(PMAgentURL.Credit)) {
            transType = IntegrationOperationsTransactionType.CreditCardCreditPaymentForFolioPosting
          } else if (req.url.toLowerCase().includes(PMAgentURL.Auth)) {
            transType = IntegrationOperationsTransactionType.CreditCardAuthForFolioPosting
          }
        } else {
          if (req.url.toLowerCase().includes(PMAgentURL.Sale)) {
            transType = IntegrationOperationsTransactionType.CreditCardSalePaymentForRetailTransaction
          } else if (req.url.toLowerCase().includes(PMAgentURL.Credit)) {
            transType = IntegrationOperationsTransactionType.CreditCardCreditPaymentForRetailTransaction
          }
        }
      }
    } catch (error) {
      console.log("Error in GetTransactionType:" + error);
    }
    return transType;
  }

  SetRequestIdAndTransactionType(reqBody, integrationOpLogResponse: IntegrationOperationsLog) {
    try {
      if (reqBody && integrationOpLogResponse) {
        reqBody.requestId = integrationOpLogResponse.requestId;
        reqBody.transactionType = integrationOpLogResponse.type;
      }
    } catch (error) {
      console.log("Error while trying to set RequestID - SetRequestIdAndTransactionType:" + error);
    }
    return reqBody;
  }

}
