import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

export class ReturnedItems extends BaseReport {
    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public propertyInformation: RetailPropertyInformation
        ,private func: RetailFunctionalityBusiness,public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.ReturnedItems,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: false,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'LANDSCAPE',
            allowFutureDate: false
        }; 
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;

        if (this.retailLocalization.isFromJobScheduler)
        {
            return [
                { "pStartDate": toApi(this.startDate) }, 
                { "pEndDate": toApi(this.endDate) },
                { "pPageBreak": this.pageBreakToggle},
                {"pPropertyName":this.propertyName},   
                {"pFilterCaption": this.functionalities.ShowOutletSelectionFieldInReturnedItemsReport ? this.getFilterCaption : this.getDefaultOutletCaption},
                {'pDate': this.printedDate }, 
                { 'pVATEnable': this.propertyInformation.IsVATEnabled },
                {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInReturnedItemsReport ? true : false },
                { "fromWildCard": this.reportFormGrp.controls.timelineFrom.value},
                { "fromIncrement": this.reportFormGrp.controls.timelineCountFrom.value},
                { "toWildCard": this.reportFormGrp.controls.timelineTo.value},
                { "toIncrement": this.reportFormGrp.controls.timelineCountTo.value},
                { "PatchEditParam": true}
             ];
        }

        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) },{ "pPageBreak": this.pageBreakToggle},{"pPropertyName":this.propertyName}
            ,{"pFilterCaption": this.functionalities.ShowOutletSelectionFieldInReturnedItemsReport ? this.getFilterCaption : this.getDefaultOutletCaption},
            {'pDate': this.printedDate }, { 'pVATEnable': this.propertyInformation.IsVATEnabled },
            {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInReturnedItemsReport ? true : false }
        ];
    }


    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) }]
    }


    protected formFilters() {
       const defaultOutlet = this.propertyInformation.GetDefaultOutlet();
        const tempReport = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return {
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement
            };  
        }
       return  this.functionalities.ShowOutletSelectionFieldInReturnedItemsReport ? 
              this.selectedFilterOptions.map(s => s.id) : tempReport ;
    }


}


