import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, ViewEncapsulation, Input, OnInit, EventEmitter, Output, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Localization } from 'src/app/common/localization/localization';
import { DataType } from '../../Models/ag-models';
@Component({
  selector: 'app-chip-search',
  templateUrl: './chip-search.component.html',
  styleUrls: ['./chip-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChipSearchComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new UntypedFormControl();
  chipAdd : boolean = false;
  filteredData: DataType[];
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  captions: any;
  originalPlaceholder: string;
  @Input() isAutoCompleteDisabled: boolean = false;
  iconclassName: string;
  iconclassNameForBlockedGuest: string;
  loader: boolean=false;
  @Input('placeholderText')
  set _placeHolder(value: string) {
    this.placeholder = value;
    this.originalPlaceholder = value;
  }
  @Input() searchKey: string[];
  @Input() selectedChipKey: string[];
  @Input() selectedChipKeySeperator = ',';
  @Input() autoCompleteKeys: string[];
  @Input() autoCompleteKeysSeperator = '|';
  @Input() maxChips = 1;
  @Input() disabled: boolean;
  @Input() searchMaxCharLength = 0;
  @Input('loader')
  set loaderVal(val:boolean)
  {
    this.loader=val?val:false
  }
  floatLabel: string;

  chipData: any[] = [];
  searchedText: string;
  @Input('allData')
  set _allData(data: any[]) {
    this.chipData = ((this.searchedText && this.searchedText.length > 0) && (data && data.length > 0)) ? data : [];
    this.filteredData = ((this.searchedText && this.searchedText.length > 0) && (data && data.length > 0)) ? data : [];
    this.loader = false;
  }
  @Input ('chipAdd')
  set setAdd(value) {
    if(value){
      this.chipAdd = value;
    }
  }
  @Input ('iconclassName')
  set seticonclassName(value:string) {
    if(value){
      this.iconclassName = value??'';
    }
  }
  @Input ('iconclassNameForBlockedGuest')
  set seticonclassNameForBlockedGuest(value:string) {
    if(value){
      this.iconclassNameForBlockedGuest = value??'';
    }
  }
  @Input() showMoreData: boolean;
  @Input() selectedData: any[] = [];
  @Output() selectedChipDataEmit: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() selectedChipDataEmit2: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() searchTextEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChipRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOnBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onIconClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() fromComponentNumber: number;
  @Input() automationId: string = '';
  placeholder: string;
  @Input() isFocusSearchEmitRequired: boolean = true;

  constructor(private localization: Localization) {
    this.captions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
  }

  @HostListener('keydown', ['$event']) keydown(event) {
    if(event.key=="Enter" && event.target.value && this.chipAdd){
      let data ={
        id : this.selectedData.length+1,
        value : event.target.value,
        name : event.target.value
       }
      this.selectedData.push(data);
      event.target.value = "";
      this.selectedChipDataEmit.emit(this.selectedData);
    }
  }

  public searchText(value: string): void {
    const txt = value.trim();
    this.searchedText = txt;
    this.searchTextEmit.emit(txt);
    this.loader = value?.length>0?true:false;
  }

  remove(selectedElement: DataType, fromComponent?:number): void {
    if (this.disabled) {
      return;
    }
    const index = this.selectedData.indexOf(selectedElement);

    if (index >= 0) {
      this.selectedData.splice(index, 1);
    }
    this.disabled = false;
    this.filterData();
    this.checkPlaceHolder();
    this.chipDataEmitter(fromComponent);
  }

  selected(event: MatAutocompleteSelectedEvent, fromComponent?:number): void {
    if (this.selectedData.length < this.maxChips) {
      this.selectedData.push(event.option.value);
      this.chipInput.nativeElement.value = '';
      this.chipCtrl.setValue(null);
      this.checkPlaceHolder();
      this.filterData();
      this.chipDataEmitter(fromComponent);
    }
  }

  chipDataEmitter(fromComponent?){
    if(fromComponent === 0){
      this.selectedChipDataEmit.emit(this.selectedData);
    }
    else if(fromComponent === 1){
      this.selectedChipDataEmit2.emit(this.selectedData);
    }
    else{
      this.selectedChipDataEmit.emit(this.selectedData);
    }
  }

  private filterData() {
    this.filteredData = this.chipData.filter(data => (!this.selectedData.find(existingData => existingData.id === data.id)));
  }

  checkPlaceHolder() {
    if (this.selectedData.length > 0) {
      this.placeholder = '';
    } else {
      this.placeholder = this.originalPlaceholder;
    }
  }

  ngOnInit() {
    this.checkPlaceHolder();
  }

  ngOnChanges(){
    this.loader = false;
    this.checkPlaceHolder();
  }

  onRemove(event) {
    if(!this.disabled)
    {
      this.onChipRemove.emit(event);
    }
  }

  iconClick(event){
    this.onIconClick.emit(event);
  }
  onBlur(event){
    this.chipInput.nativeElement.value = '';
    this.chipCtrl.setValue(null);      // bug 38449 - removing invalid input on blur event
    this.emitOnBlur.emit(event);
   // bug fix 47847 -  Without giving the search input system displays the search value as suggestion
  }

  onFocus(){
    this.searchedText = '';
    this.filteredData = [];
    if(this.isFocusSearchEmitRequired) {
    this.searchTextEmit.emit('');
    }
  }
}
import { Pipe, PipeTransform, Injectable } from "@angular/core";


@Pipe({ name: "titlecompose" })
@Injectable()
export  class TitlecomposePipe implements PipeTransform {

    constructor() {  
    }

    transform(obj: DataType, autoKeys: string[],autoCompleteKeysSeperator:string) {
              let autoCompleteKeysSeperators=' ';
              let concatedString = '';
              autoKeys.map((x,i)=>{
                if(x==='' && i!=0){
                  autoCompleteKeysSeperators = autoCompleteKeysSeperator;
                }
                return concatedString+=obj[x]?(autoCompleteKeysSeperators+obj[x]):'';
               })
              return concatedString
    }
}
