import { TitleCasePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { DataRetentionPoliciesComponent } from '@app/reservation/data-retention-policies/data-retention-policies.component';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { DynamicFormService } from '@app/shared/dynamicform/service/dynamic-form.service';
import { CapitalizeFirstCharacterInText } from '@app/shared/pipes/guestname-initial-formatter.pipe';
import { ComponentTypes, Labels } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { FullContactDTO, GuestTitle, TitleList } from '@models/InputContact';
import { DataRetentionPolicy, SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ISubscription, Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PersonalInfoComponent implements OnInit, AfterViewInit, OnDestroy {
  // personalDetails: FormGroup;
  personalDetailsForm: UntypedFormGroup;
  vipStatus: any;
  Slected: any;
  vipStatusValue: string = Labels[Labels.notext];
  config: FieldConfig[] = [];
  url = '';
  file: any;
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  selectedIndex: any;
  tabChangeSubscription: ISubscription;
  subscriptions: Subscription = new Subscription();
  dataRetentionPolicy: any = [];
  retentionCheckboxVisibility = false;
  formUpdationCount = 0;
  isErrorMessageAvailable = true;
  _settings: SettingsDTO = {} as SettingsDTO;
  _retentionPolicy: DataRetentionPolicy[] = [] as DataRetentionPolicy[];
  showAliasName : boolean ;
  constructor(private fb: UntypedFormBuilder, private gbs: GuestBookService, @Inject(COMPONENTINPUT) private data: FullContactDTO,
    private ps: PopupService, private partyService: PartyService, private cdf: ChangeDetectorRef,
    private imageCompress: NgxImageCompressService, private as: AppService, private ts: TranslateService,
    private dialog: MatDialog, public cs: CacheService, private titleCasePipe: TitleCasePipe,private dynamicFormService:DynamicFormService,public capitalizeText: CapitalizeFirstCharacterInText) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));

    this.subscriptions.add(cs.dataRetentionPolicy.subscribe(policy => {
      this._retentionPolicy = policy;
    }));

  }

  ngOnInit() {
    const isMembershipAvailable = this._settings.General.UseMembershipNumber || this._settings.General.EngageIntegrationDTO?.IsEnabled;
    const retentionPolicy = this._retentionPolicy;
    if (this.gbs && this.gbs.editGuest) {
      this.retentionCheckboxVisibility = false;
    }
    else {
      if (this._settings.General.IsGDPREnabled && retentionPolicy) {
        this.dataRetentionPolicy = retentionPolicy;
        this.dataRetentionPolicy[1].PolicyDetails.forEach((policy) => {
          const retentionDays = policy.RetentionInDays;
          if (retentionDays > 0) {
            this.retentionCheckboxVisibility = true;
          }
        });
      }
    }
    this.showAliasName = JSON.parse(sessionStorage.getItem('ShowAliasName'));

    this.config = [
      {
        type: 'select',
        name: 'guestTitle',
        inputType: 'number',
        label: 'GuestTitle',
        class: 'personal-info__guest-title',
        options: []
      },
      {
        type: 'input',
        name: 'firstName',
        inputType: 'text',
        label: 'guestBookDetailsFN',
        class: 'personal-info__first-name',
        validation: [Validators.required ,Validators.maxLength(100)]
        // blurClick: this.updateSize.bind(this)
      },
      {
        type: 'input',
        name: 'lastName',
        inputType: 'text',
        label: 'guestBookDetailsLN',
        class: 'personal-info__last-name',
        validation: [Validators.required, Validators.maxLength(100)]
        // blurClick: this.updateSize.bind(this)
      },
      {
        type: 'input',
        name: 'aliasName',
        inputType: 'text',
        label: 'guestBookDetailsAliasName',
        class: 'personal-info__last-name', 
        isHidden: this.showAliasName ? false: true,
        validation: [Validators.maxLength(100)],
        errorMessage: this.ts.instant('aliasNameValidation'),
        showErrorText:true
        // blurClick: this.updateSize.bind(this)
      },
      {
        type: 'date',
        name: 'dob',
        inputType: 'text',
        label: 'guestBookDetailsDOB',
        class: 'personal-info__dob',
        maxDate: new Date()
      },
      {
        type: 'date',
        name: 'anniversary',
        inputType: 'text',
        label: 'guestBookDetailsAnniversary',
        class: 'personal-info__anniversary',
        maxDate: new Date()
      },
      {
        type: 'switch',
        name: 'vipStatus',
        inputType: 'text',
        label: 'guestBookMarkVIPText',
        class: 'personal-info__vip',
      }
    ];
    if (isMembershipAvailable) {
      const membershipName = this._settings.General.MembershipNumberName;
      const fieldConfig: FieldConfig = {
        type: 'input',
        name: 'memberShipId',
        inputType: 'text',
        label: membershipName
      };
      this.config.splice((this.config.length - 1), 0, fieldConfig);
    }
    // retention policy must always be the last feild.Make Sure !!
    if (this.retentionCheckboxVisibility) {
      this.config.push({
        type: 'checkbox',
        placeholder: this.ts.instant('dataRetentionPolicyLabel'),
        value: false,
        name: 'dataRetentionPolicy',
        class: 'dataRetentionPolicyCheckBox',
        addHyperlink: true,
        hyperLinkText: this.ts.instant('dataRetentionPolicy'),
        hyperLinkClicked:(event) => this.ShowPolicyDetails(),
      });
    }
    let personalInfo = {};
    for (const info of this.config) {
      personalInfo[info.name] = ['', info.validation];
    }
    personalInfo = { ...personalInfo, photo: '', ShouldUpdatePhoto: false };
    const personalFormGroup = this.fb.group(personalInfo);
    this.gbs.guestForm.addControl('personalDetailsForm', personalFormGroup);
    this.config[0].options = this.getGuestTitles();
  }

  ngAfterViewInit() {
    this.selectedIndex = this.gbs.tabsModal.tabs.findIndex(x => x.tabComponent === PersonalInfoComponent);
    this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe((data) => {
      if (data.dataRetentionPolicy && data.dataRetentionPolicy === true) {
        this.gbs.dataRetentionPolicyChecked = true;
      } else {
        this.gbs.dataRetentionPolicyChecked = false;
      }
      const pipedfirstName = this.capitalizeText.transform(data.firstName);
      const pipedLastName = this.capitalizeText.transform(data.lastName);
      const pipedAliasName = this.capitalizeText.transform(data.aliasName);
      if (data.firstName !== pipedfirstName)
        this.dynamicForm.form.patchValue({
          firstName: pipedfirstName
        });
      if (data.lastName !== pipedLastName)
        this.dynamicForm.form.patchValue({
          lastName: pipedLastName
        });
        if (data.aliasName !== pipedAliasName)
        this.dynamicForm.form.patchValue({
          aliasName: pipedAliasName
        });
      this.gbs.guestForm.get('personalDetailsForm').patchValue(data);
      if (this.dynamicForm.form.dirty) {
        this.gbs.guestForm.get('personalDetailsForm').markAsDirty();
        this.gbs.guestForm.markAsDirty();
      }
      if (!data.firstName && !data.lastName) {
        this.updateFormValidity(true, this.updateNameValidity, Validators.required);
      } else if (data.firstName && data.lastName) {
        this.updateFormValidity(false, this.updateNameValidity, Validators.required);
      } else if (data.firstName) {
        this.updateFormValidity(false, this.updateLastNameValidity);
      } else if (data.lastName) {
        this.updateFormValidity(false, this.updateFirstNameValidity);
      }
      if (this.ps.tabsActionData?.length) {
        this.ps.tabsActionData[this.selectedIndex].gotoNextTab = this.dynamicForm.form.valid;
        this.ps.tabsActions$.next(this.ps.tabsActionData);
      }

      
    }));
    if (this.data) {
      const { FirstName, LastName, AliasName , Birthday, Anniversary, IsVip, Photo, TrackMembershipNumber, GuestTitle } = this.data;

      this.dynamicForm.form.patchValue({
        firstName: this.capitalizeText.transform(FirstName),
        lastName: this.capitalizeText.transform(LastName),
        aliasName: this.capitalizeText.transform(AliasName),
        dob: Birthday,
        anniversary: Anniversary,
        vipStatus: IsVip,
        photo: Photo,
        ShouldUpdatePhoto: false,
        guestTitle: GuestTitle
      });
      if (TrackMembershipNumber) {
        this.dynamicForm.form.get('memberShipId').setValue(TrackMembershipNumber);
      }
      this.gbs.guestForm.get('personalDetailsForm')['controls']['photo'].setValue(Photo);
      this.gbs.guestForm.get('personalDetailsForm')['controls']['ShouldUpdatePhoto'].setValue(false);
      this.gbs.contactId = this.data.Id;

      if (Photo !== '') {
        this.url = 'data:image/png;base64,' + Photo;
      } else {
        this.url = '';
      }
    }

    setTimeout(() => {
      if (this.ps.tabsActionData?.length) {
        this.ps.tabsActionData[this.selectedIndex].gotoNextTab = this.dynamicForm.form.valid;
      }
      this.ps.tabsActions$.next(this.ps.tabsActionData);  
    } , 200)
  }


  setDateToCurrentYear(date: Date) {
    date = new Date(date);
    let month = date.getMonth();
    let day = date.getDate();
    let dateInCurrentYear = new Date(new Date().getFullYear(), month, day);

    return dateInCurrentYear;
  }

  updateFormValidity(isError: boolean, callBack: (validators?: ValidatorFn) => void, validators?: ValidatorFn) {
    this.isErrorMessageAvailable = isError;
    this.formUpdationCount = this.formUpdationCount + 1;
    if (this.formUpdationCount === 1) {
      validators ? callBack(validators) : callBack();
    }
  }

  updateLastNameValidity = () => {
    const personalForm = this.gbs.guestForm.get('personalDetailsForm') as UntypedFormGroup;
    this.setValidators(personalForm.controls, 'lastName', personalForm, Validators.nullValidator);

    this.setValidators(this.dynamicForm.form.controls, 'lastName', this.dynamicForm.form, Validators.nullValidator);
    this.formUpdationCount = 0;
  }

  updateFirstNameValidity = () => {
    const personalForm = this.gbs.guestForm.get('personalDetailsForm') as UntypedFormGroup;
    this.setValidators(personalForm.controls, 'firstName', personalForm, Validators.nullValidator);

    this.setValidators(this.dynamicForm.form.controls, 'firstName', this.dynamicForm.form, Validators.nullValidator);
    this.formUpdationCount = 0;
  }

  updateNameValidity = (validators: ValidatorFn) => {
    const dynamicFormControls = this.dynamicForm.form;
    this.setValidators(dynamicFormControls.controls, 'firstName', dynamicFormControls, validators);
    this.setValidators(dynamicFormControls.controls, 'lastName', dynamicFormControls, validators);

    const personalForm = this.gbs.guestForm.get('personalDetailsForm') as UntypedFormGroup;
    this.setValidators(personalForm.controls, 'firstName', personalForm, validators);
    this.setValidators(personalForm.controls, 'lastName', personalForm, validators);
    this.formUpdationCount = 0;
  }

  setValidators(allControls, formControl: string, formGroup: UntypedFormGroup, validators: ValidatorFn) {
    Object.keys(allControls).forEach((control) => {
      if (control === formControl) {
        formGroup.get(control).setValidators([validators,Validators.pattern(this.dynamicFormService.HTMLPattern), Validators.maxLength(100)]);
        formGroup.get(control).updateValueAndValidity();
      }
    });
  }

  ngOnDestroy() {
    if (this.tabChangeSubscription) {
      this.tabChangeSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }    
  }

  uploadImage(e) {
    document.getElementById('fileId').click();
    console.log(document.getElementById('fileId'));
  }


  //   fileEvent(fileInput: any) {
  //     console.log(fileInput.target);
  //     this.Slected = fileInput.target.value;
  //     this.previewFile();
  // }

  compressFile() {
    // if (event.target.files && event.target.files[0]) {
    //   var reader = new FileReader();
    //   let that = this;
    //   // tslint:disable-next-line: no-shadowed-variable
    //   reader.onload = (event) => { // called once readAsDataURL is completed
    //     if (event) {
    //       this.url = reader.result;
    //       that.gbs.guestForm.get('personalDetailsForm').value.photo =   this.url.replace(/^data:image.+;base64,/, '');
    //       that.file = this.url.replace(/^data:image.+;base64,/, '');

    //     }
    //   }
    // }
    this.imageCompress.uploadFile().then(({ image, orientation }) => {


      this.imageCompress.compressFile(image, orientation, 75, 50).then(
        result => {
          console.log(result);
          this.url = result;
          this.gbs.guestForm.get('personalDetailsForm')['controls']['photo'].setValue(result.replace(/^data:image.+;base64,/, ''));
          this.gbs.guestForm.get('personalDetailsForm')['controls']['ShouldUpdatePhoto'].setValue(true);
          this.gbs.guestForm.get('personalDetailsForm').markAsDirty();
          this.file = result.replace(/^data:image.+;base64,/, '');

        }
      );

    });
  }
  deleteImage() {
    this.url = '';
    this.gbs.guestForm.get('personalDetailsForm')['controls']['photo'].setValue('');
    this.gbs.guestForm.get('personalDetailsForm')['controls']['ShouldUpdatePhoto'].setValue(true);
    this.gbs.guestForm.get('personalDetailsForm').markAsDirty();
  }

  ShowPolicyDetails() {
    const componentDetails: ComponentDetails = {
      componentName: DataRetentionPoliciesComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupTitle: this.ts.instant('dataRetentionPolicy')
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '80%',
      width: '50%',
      data: {
        title: this.ts.instant('dataRetentionPolicy'),
        update: 'ok',
        componentDetails,
        from: ComponentTypes.dataRetention,
        back: true,
        standalone: true,
        showAction: true
      },
    });
  }

  getGuestTitles(): TitleList[] {
    const communicationList: TitleList[] = [];
    let i = 0;
    for (const enumMember in GuestTitle) {
      if (enumMember) {
        const isValueProperty = parseInt(enumMember) >= 0;
        if (isValueProperty) {
          communicationList.push({ id: i, value: this.ts.instant(GuestTitle[enumMember]) });
        }
        ++i;
      }
    }
    return communicationList;
  }
}
