<div class="cancel-activity-popup">

  <div class="cancel-activity-popup__apiresponsediv">
    <p class="cancel-activity-popup__message seat-alert-messages" id="confirmationMsg" [innerHTML]="confirmationMessage"></p>
    <!-- <p class="cancel-activity-popup__message seat-alert-messages" id="confirmationMsg">{{confirmationMessage}}</p> -->
  </div>

  <div class="cancel-activity-popup__guest-mail-details">

  </div>
  <div *ngIf = "displayOptions" class="seat-alert-messages">
  <dynamic-form [config]="cancelConfig" #form="dynamicForm">
  </dynamic-form>
</div>
</div>
