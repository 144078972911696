<div class="confirmation-popup">
    <div *ngIf="modifyDetails" class="confirmation-header-title">
        <div class="header-title">{{modifyDetails.title}}</div>
        <p class="confirmation-header-title--subtext">{{modifyDetails.subText}}</p>
    </div>
    
    <ul *ngIf="modifyDetails?.changes?.length" class="change-list">
        <div class="header-title">{{'ReservationFrom' | translate}}</div>
        <div class="change-list__content">
            <li class="change-item" *ngFor="let changeItem of modifyDetails.changes">
                <label class="change-item--label" for="">{{changeItem.label | translate}}</label>
                <div class="change-item--new">{{changeItem.oldValue}}<span *ngIf="changeItem.time"> , {{changeItem.time}}</span></div>
            </li>
        </div>
        <div class="header-title">{{'UpdateTo' | translate}}</div>
        <div  class="change-list__content">
        <li class="change-item" *ngFor="let changeItem of modifyDetails.changes">
            <label class="change-item--label" for="">{{changeItem.label | translate}}</label>
            <div class="change-item--new">{{changeItem.newValue}}<span *ngIf="changeItem.time"> , {{changeItem.time}}</span></div>
        </li>
    </div>
    </ul>
    <div class="mt-2 mx-3">
        <strong class="confirmation-popup__message seat-alert-messages" id="confirmationMsg">{{confirmationMessage}}</strong>
        <div class="mt-2">
            <dynamic-form [config]="paymentOptionsConfig" #paymentType></dynamic-form>
        </div>
    </div>
</div>