import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material-module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { RetailSharedModule } from '../shared/retail-shared.module';
import { EatecRoutingModule } from './eatec-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { EatecComponent } from './eatec.component';

@NgModule({
    declarations: [EatecComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        NgScrollbarModule,
        EatecRoutingModule,
        RetailSharedModule,
        SharedModule
    ],
    exports: [],
    providers: []
})
export class EatecModule { }
