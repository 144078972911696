import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PaymentMethods } from '../business/shared.modals';
import { ShopBussinessService } from '../../shop/shop-business.service';

export function CMSValidator(ParentScope: ShopBussinessService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        let localizedVal = Number(ParentScope.localization.currencyToSQLFormat(control.value));
        const controlVal = (localizedVal < 0 ? localizedVal * -1 : localizedVal);

        let prevSettlementWithCMS: number = 0;
        if (ParentScope.TransactionPaymentsAggregate && ParentScope.TransactionPaymentsAggregate.length > 0 && ParentScope.TransactionPaymentsAggregate.some(x => ParentScope._utils.GetOriginalTenderId(Number(x.transactionPayment.paymentMethod),x.transactionPayment.parentTenderId) == PaymentMethods.CompRedemption)) {
            ParentScope.TransactionPaymentsAggregate.filter(x => ParentScope._utils.GetOriginalTenderId(Number(x.transactionPayment.paymentMethod),x.transactionPayment.parentTenderId) == PaymentMethods.CompRedemption).forEach(x => prevSettlementWithCMS += x.additionalInformation.Amount);
        }
         
        let OverAllMaxCMSAmt = Math.abs(prevSettlementWithCMS);
        if (control.value !== undefined && (isNaN(localizedVal) || controlVal > OverAllMaxCMSAmt)) {
            return { 'cmsLimitExceeded': true };
        }
        return null;
    };
}