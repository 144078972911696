import { Utilities } from '@app/shared/utilities/utilities';
import { ChangeAction } from '@constants/commonenums';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { ReservationDTO } from '@models/InputContact';
import { PartyMessageDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import _ from 'lodash';
import { CacheService } from '../services/cache.service';
import { Processor } from './processor';

export class PartyMessageChangeProcessor implements Processor {

  private static instance: PartyMessageChangeProcessor;
  private constructor(public partyService: PartyService, public cs: CacheService) { }

  public static Instance(componentService: any = PartyService, cs: CacheService): PartyMessageChangeProcessor {
    if (!PartyMessageChangeProcessor.instance) {
      PartyMessageChangeProcessor.instance = new PartyMessageChangeProcessor(componentService, cs);
    }

    return PartyMessageChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: object, propertyId: number): void {
    let partiesList =[];
    let standBy = false;
    if (objectChange) {
      const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
      if(this.partyService.StandbyParties$.value.filter(party =>party.Id == partyId).length > 0){
        partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.StandbyParties$.value : this.cs.propertySettings.value[propertyId].StandbyParties;
        standBy = true;       
      }
      else{
        partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.Parties$.value : this.cs.propertySettings.value[propertyId].reservations;
        standBy= false;
      }
      if (objectChange.Action === ChangeAction.Created) {
        const createdPartyMsgId = objectChange.PropertyChanges.find(property => property.PropertyName === 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
        const party = partiesList.filter(party => party.Id == partyId);
        if (party && party.length) {
          const partyMsg: PartyMessageDTO = new PartyMessageDTO();
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyMsg).includes(property.PropertyName.replace('Internal', ''))) {
              partyMsg[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
          this.partyService.showSnackBar(partyMsg);
          party[0].Messages.push(partyMsg);
        }
      }
      if (objectChange.Action === ChangeAction.Updated && partiesList.length > 0) {
        const updatedPartyMsgId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party = partiesList.filter(party => party.Id == partyId);
        if (party && party.length) {
          const partyMsg: PartyMessageDTO = party[0].Messages.find(note => note.Id == updatedPartyMsgId);
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyMsg).includes(property.PropertyName.replace('Internal', ''))) {
              partyMsg[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
          // ToDo: party message has to be added in this.parties and added reservaion should be shown in the screen without refresh
        }
      }
      if (objectChange.Action === ChangeAction.Updated) {
        const updatedPartyMsgId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party: ReservationDTO = partiesList.find(party => party.Id == partyId);
        if (party) {
          const partyMsg: PartyMessageDTO = party.Messages.find(note => note.Id == updatedPartyMsgId);
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyMsg).includes(property.PropertyName.replace('Internal', ''))) {
              partyMsg[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
          party.Messages[party.Messages.indexOf(partyMsg)] = partyMsg;
          partiesList[partiesList.indexOf(party)] = party;
        }
      }
      if (objectChange.Action === ChangeAction.Removed) {
        const removedPartyMsgId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party: ReservationDTO = partiesList.find(party => party.Id == partyId);
        // Cancelled reservation has to be removed from the list and from the screen
        if (party) {
          const partyMsg: PartyMessageDTO = party.Messages.find(note => note.Id == removedPartyMsgId);
          if (partyMsg) {
            party.Notes = party.Notes.filter(note => note.Id == removedPartyMsgId);
          }
          partiesList[partiesList.indexOf(party)] = party;
        }
      }
    }
    partiesList = _.uniqBy(partiesList, 'Id');
    if (propertyId == Utilities.RestaurantId()) {
      if(standBy) {
        this.partyService.StandbyParties$.next(partiesList);
        this.partyService.standbyPartiesList = partiesList;
        this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      } else {
        this.partyService.Parties$.next(partiesList);
        this.partyService.partiesList = partiesList;
        this.cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    } else {
      if (standBy) {
        this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      } else {
         this.cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    }
  }
}
