import { Injectable } from '@angular/core';
import { API } from 'src/app/common/Models/property-settings.model';
import { CommonApiRoutes, PropertyApiRoutes } from '../../common-route';
import { FeatureApiRoutes } from '../../common-route';
import { ccOuletTerminal, FeaturesConfigurations, PaymentConfiguration, PropertyFeature } from '../../shared/retail.modals';
import { CommonUtilities, PatchJson, PatchOperation, Product } from '../../shared/shared/utilities/common-utilities';
import { RetailCommunication } from '../../communication/services/retailcommunication.service';
import { TenantManagementCommunication } from '../../communication/services/tenantmanagement-communication-service';
import { AppService } from '../../app-service';
import { HttpClient } from '@angular/common/http';
import { Localization } from '../../localization/localization';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { UserAccessModel } from '../authentication/useraccess-model.model'
import { CommonControllersRoutes } from '../../communication/common-route';
import { AlertType } from '../../Models/common.models';
import { ButtonType } from '../../enums/shared-enums';
import { JasperSynchronizeCommunication } from '../../communication/services/jasper-synchronize.service';
import { CommonGatewayCommunication } from '../../communication/services/commongateway-communication.service';
import { UICache } from '../../services/cache/cache-constants';

@Injectable({
    providedIn: 'root'
})
export class PropertySettingDataService {
    captions: any;
    constructor(private _retailCommunication: RetailCommunication, private _authenticationCommunication: TenantManagementCommunication, private appService: AppService, private utils: CommonUtilities, private httpclient: HttpClient, private localization: Localization, private PropertyInfo: CommonPropertyInformation, private _reportingCommunication: JasperSynchronizeCommunication, private _commonGatewayCommunication: CommonGatewayCommunication) {
        this.captions = this.localization.captions;
     }

    public reCreateRetailCommunicationInstance() {
        this._retailCommunication =
            new RetailCommunication(this.appService, this.utils, this.httpclient, this.localization, this.PropertyInfo);
    }

    public async getUserAccessDetails(breakPointNumber: number, roleId?: string): Promise<UserAccessModel.BreakPointResult> {
        let result: UserAccessModel.BreakPointResult = {
            isAllow: false,
            isViewOnly: false
        };
        try {
            
            const userRoleId = roleId ? roleId : this.utils.GetLocalStorageValue('_userInfo', 'roleId');
            let accesses = await this._authenticationCommunication.getPromise<UserAccessModel.BreakPointAccess[]>({
                route: CommonControllersRoutes.GetUserClaimsAsync
                , uriParams: { roleID: userRoleId, bkpn: breakPointNumber }
            });
            accesses = accesses ? accesses : [];
            let access: UserAccessModel.BreakPointAccess = accesses.find(x => x.breakPointNumber == breakPointNumber);
            if (access) {
                result = {
                    isAllow: access.allow,
                    isViewOnly: access.view,
                    breakPointNumber: access.breakPointNumber
                };
            }
          } catch (e) {
            console.error(e);
            throw e;
          }

       
        return result;
    }

    public async getUserAccess( breakPointNumber: number, showUserMessage: boolean = true,callBack?: any): Promise<UserAccessModel.BreakPointResult> {
        const result = await this.getUserAccessDetails(breakPointNumber);
        const bpMessage = this.localization.captions[breakPointNumber];
        if (!result.isAllow && !result.isViewOnly && showUserMessage) {
          this.showBreakPointPopup(bpMessage, callBack);
        }
        return result;
    }

    public showBreakPointPopup(functionName?: string, callBack?: any) {
        let message: string;
        message = `${this.captions.err_breakPointAccessDeniedMsg}
             <br><br>${this.localization.captions.common.lbl_breakpoint}: ${functionName}`;
        this.utils.showAlert(message, AlertType.AccessDenied, ButtonType.Ok, callBack);
      }

    public getAllPropertySetting(_propertyId: number): Promise<API.PropertySetting> {
        return this._authenticationCommunication.getPromise<API.PropertySetting>(
            { route: PropertyApiRoutes.GetAllPropertySettings, uriParams: { propertyId: _propertyId } }, false, false, true);

    }

    public async getPropertyFeatures(propertyId, productId = Product.RETAIL): Promise<PropertyFeature[]> {
        return this._authenticationCommunication.getPromise<PropertyFeature[]>(
            {
                route: PropertyApiRoutes.GetPropertyFeatures,
                uriParams: { propertyId, productId }
            }, false);
    }

    public async getFeatureConfiguration(featureId: number, moduleId: number): Promise<FeaturesConfigurations[]> {

        return this._retailCommunication.getPromise<FeaturesConfigurations[]>(
            { route: PropertyApiRoutes.GetPropertyFeatureConfiguration, uriParams: { PropertyFeatureId: featureId } }, false);
    }

    public async GetPaymentConfigurationByProperty(propertyId: number): Promise<PaymentConfiguration[]> {
        return this._retailCommunication.getPromise<PaymentConfiguration[]>(
            { route: PropertyApiRoutes.GetPaymentConfigurationByProperty, uriParams: { propertyId: propertyId } }, false);

    }

    public async getDefaultOutlet(): Promise<number> {
        return this._retailCommunication.getPromise<number>(
            { route: PropertyApiRoutes.GetDefaultOutlet });

    }

    public async GetOutletsCCTerminalAggregate(propertyId: number): Promise<ccOuletTerminal[]> {
        return await this._retailCommunication.getPromise<ccOuletTerminal[]>({
           route: PropertyApiRoutes.GetOutletsCCTerminalAggregate,
           uriParams: {
            propertyId
            }
        }, true, true);
    }

    public async getOutletById(outletId: number): Promise<any> {
        return this._retailCommunication.getPromise<number>(
            {
                route: PropertyApiRoutes.OutletWithId,
                uriParams: {id: outletId}
            });
    }

    public async CheckandCreateOutletAccess(outletId: number, userId: number): Promise<boolean> {
        return this._retailCommunication.putPromise<boolean>(
            {
                route: PropertyApiRoutes.CreateUserOutletAccess,
                uriParams: { outletId, userId }
            }, false);
    }

    public async syncDefaultOutlet(): Promise<string> {
        return await this._retailCommunication.postPromise<string>(
            { route: PropertyApiRoutes.SyncDefaultOutlet }, false);

    }
    UpdatePropertySettingDateforaccounting(propertyDate: string, propertyId: number): Promise<boolean> {
        return this._authenticationCommunication.postPromise({
            route: PropertyApiRoutes.UpdatePropertySettingDateforaccounting,
            uriParams: {
                propertyId: propertyId,
                propertyDate: propertyDate
            }
        });
    }

    UpdatePropertySettingDateByProductId(propertyDate: string, propertyId: number, productId: number): Promise<boolean> {
        return this._authenticationCommunication.postPromise({
            route: PropertyApiRoutes.UpdatePropertySettingDateByProductId,
            uriParams: {
                propertyId: propertyId,
                productId: productId,
                propertyDate: propertyDate,
            }
        });
    }

    public updatePropertySetting(value: Partial<API.PropertySetting>, propertyName: string, propertyId: number): Promise<boolean> {

        let patchJson: PatchJson = {
            op: PatchOperation.replace,
            path: `/${propertyName}`,
            value: value.vatEnabled
        };

        return this._authenticationCommunication.patchPromise({
            route: PropertyApiRoutes.UpdatePropertySetting,
            body: [patchJson],
            uriParams: { propertyId: propertyId }
        });
    }

    public GetAllPropertyConfigurationSettings(propertyConfigurationSettings: API.PropertyConfigurationSettings<any>): Promise<API.PropertyConfigurationSettings<any>> {
        return this._authenticationCommunication.getPromise<API.PropertyConfigurationSettings<any>>(
            {
                route: PropertyApiRoutes.GetAllPropertyConfigurationSettings,
                uriParams: {
                    configurationName: propertyConfigurationSettings.configurationName,
                    propertyId: propertyConfigurationSettings.propertyId,
                    productId: propertyConfigurationSettings.productId
                }
            });
    }

    public GetAllPropertyConfigurationSettingsByUserId(propertyConfigurationSettings: API.PropertyConfigurationSettings<any>):
        Promise<API.PropertyConfigurationSettings<any>[]> {
        return this._authenticationCommunication.getPromise<API.PropertyConfigurationSettings<any>[]>(
            {
                route: PropertyApiRoutes.GetAllPropertyConfigurationsByUserId,
                uriParams: {
                    configurationName: propertyConfigurationSettings.configurationName,
                    userId: propertyConfigurationSettings.userId
                }
            });
    }
    public GetJasperSoftServerUri(): Promise<string> {
        return this._authenticationCommunication.getPromise<string>(
            {
                route: PropertyApiRoutes.GetJaspersoftServerUri
            });
    }
    public async UpdateRoleAndAttributeToUser(isLoaderRequired:boolean = false): Promise<boolean> {
            return await this._reportingCommunication.putPromise<boolean>(
                {
                    route: PropertyApiRoutes.UpdateRoleAndAttributesToUser
                }, false,isLoaderRequired);
    }
    //Gets EnableTransactionbyMachine,promptOnLogin,Printer URI
    public async GetMiscConfigurationSettingTenantByPropertyId(miscellaneousConfigurationSettings: API.MiscellaneousConfigurationSettings<any>) {
        return this._authenticationCommunication.getPromise<API.MiscellaneousConfigurationSettings<any>>(
            {
                route: PropertyApiRoutes.GetAllPropertyConfigurationSettings,
                uriParams: {
                    configurationName: miscellaneousConfigurationSettings.configurationName,
                    propertyId: miscellaneousConfigurationSettings.propertyId,
                    productId: miscellaneousConfigurationSettings.productId
                }
            });
    }
    //Save EnableTransactionbyMachine,promptOnLogin,Printer URI
    public async saveMiscConfigurationSettingTenantByPropertyId(body: API.MiscellaneousConfigurationSettings<any>) {
        return this._authenticationCommunication.putPromise<API.MiscellaneousConfigurationSettings<any>[]>(
            {
                route: PropertyApiRoutes.SavePropertyConfiguration,
                body: body
            });
    }

    public async GetPMSConfigurationSettingTenantByPropertyId(miscellaneousConfigurationSettings: API.PMSIntegrationConfigurationSettings<any>) {
        return this._authenticationCommunication.getPromise<API.PMSIntegrationConfigurationSettings<any>>(
            {
                route: PropertyApiRoutes.GetAllPropertyConfigurationSettings,
                uriParams: {
                    configurationName: miscellaneousConfigurationSettings.configurationName,
                    propertyId: miscellaneousConfigurationSettings.propertyId,
                    productId: miscellaneousConfigurationSettings.productId
                }
            });
    }

    public async savePMSConfigurationSettingTenantByPropertyId(body: API.PMSIntegrationConfigurationSettings<any>) {
        return this._authenticationCommunication.putPromise<API.PMSIntegrationConfigurationSettings<any>[]>(
            {
                route: PropertyApiRoutes.SavePropertyConfiguration,
                body: body
            });
    }

    public async GetSupportedPMAgentVersionByPropertyID(propertyId: number): Promise<any> {

        return this._retailCommunication.getPromise<any[]>(
            { route: PropertyApiRoutes.GetSupportedPMAgentVersionByProperty, uriParams: { propertyId: propertyId } }, false);


    }
    
    public async IsFeatureEnabled(featureName:string): Promise<boolean> {
        const result = this._authenticationCommunication.getPromise<boolean>({
                route: FeatureApiRoutes.IsFeatureEnabled
                , uriParams: { featureName }
            },true);
        return result;        
    }

    public async GetWebCommunicationProxyVersion(): Promise<any> {     
            return await this._retailCommunication.getPromise<any>({route: PropertyApiRoutes.GetWebCommunicationProxyVersion});
    }
    public  GetProductADB2CConfiguration(tenantId,product:Product):Promise<any>{
        return  this._authenticationCommunication.getPromise<any>({route : PropertyApiRoutes.GetADB2CAuthConfig,uriParams: { "tenantId": tenantId, "productId": product }})
    }
    public  async AddJwtForJasper(requestconnector : API.RequestConnector):Promise<any>{
        return  this._authenticationCommunication.postPromise<any>({route : PropertyApiRoutes.AddJwtForJasper,body : requestconnector},false,true)
    }
    public getUICacheJSON():Promise<UICache>{
        return this._authenticationCommunication.getPromise<UICache>({route: CommonControllersRoutes.GetUICacheData});
    }
}