import { Pipe, PipeTransform } from "@angular/core";
import { AppService } from "@app/app.service";
import { CacheService } from "@app/core/services/cache.service";
import _ from "lodash";

@Pipe({
    name: 'partyGuestFieldMapping'
  })
  export class PartyGuestFieldMapping implements PipeTransform {

    constructor(private cs: CacheService, private appService: AppService){
        
    }
  
    transform(partyContact, mappedGuestFields: number[] = []): any {
        let customGuestFieldsForBooking = [];
        if (partyContact?.ContactCustomFields) {
          customGuestFieldsForBooking = partyContact.ContactCustomFields
            .filter(currentField => mappedGuestFields.includes(currentField.CustomFieldId))
            .map(currentField => {
              currentField.CustomFieldName = this.cs?.settings?.value.CustomGuestFields.find(masterFieldId => masterFieldId.Id === currentField.CustomFieldId)?.FieldName || ''
              return currentField;
            })
        }

        return _.uniqBy(customGuestFieldsForBooking, "Id");
    }
  
  
  }