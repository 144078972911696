import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Host } from '../../shared/globalsContant';
import * as _ from "lodash";
import { RetailLocalization } from '../../common/localization/retail-localization';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { BaseResponse } from '../../shared/business/shared.modals';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { RetailRoutes } from 'src/app/retail/retail-route';

@Component({
  selector: 'app-retail-search-items',
  templateUrl: './search-items.component.html',
  styleUrls: ['./search-items.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailSearchItemsComponent implements OnInit, OnDestroy {
  @Output() doneEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() searchControlValidator: EventEmitter<any> = new EventEmitter();
  @Input() retailItemType: number;
  private _retailItemId: number;
  get item(): number {
    return this._retailItemId;
  }
  @Input()
  set RetailItemId(val: number) {
    if (val > 0) {
      this._retailItemId = val;
      this.LoadAndDisplayItem();
    }
  }
  @Input() autocomplete: UntypedFormControl;
  ORG_searchFilter: any;
  @Input() Disabled = false;
  searchFilter: any = [];
  isRetailItemSelected = false;
  private searchFilterResult: any = [];
  searchText: string;
  captions: any = this._Localization.captions.retailsetup;
  settingCaptions: any = this._Localization.captions.setting; 
  subscription: ISubscription;
  @ViewChild('autoCompleteInput') autoCompleteSelect: MatAutocompleteTrigger;
  linkedItemsClicked: boolean;

  constructor(public _Localization: RetailLocalization, private http: HttpServiceCall) {

  } 
  ngOnInit() {
    this.subscription = this.autocomplete.valueChanges
      .subscribe(searchData => {
        this.makeSearchCall(searchData);
      });
    if (this.Disabled) {
      this.autocomplete.disable();
    }
    if (this.retailItemType) {
      this.GetRetailItemByTypeForLookup(this.retailItemType);
    }
    else
      this.GetRetailItemForServiceAddOn();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  filterFucntion($event) {
    let data: string = $event && $event.target && $event.target.value ? $event.target.value : '';
    let newarr = [];
    const orgdata = this.searchFilterResult;
    if (data == '') {
      newarr = orgdata;
      this.searchText = null;
      this.autocomplete.setValue(undefined); 

    } else if (data.length > 0 && data != ' ') {
      newarr = orgdata.filter(x => {
        const id = x.itemNumber.toString();
        const desc = x.itemDescription.toLowerCase();
        return (_.includes(id, data) || _.includes(desc.toLowerCase(), data.toLowerCase()))
      });


    }
    this.Done(-1);
    this.searchFilter = newarr; 
    setTimeout(() => {
      this.heightCalc();
    }, 10);

  }
  Done(id) {
    this.doneEvtEmitter.emit(id);
  }

  GetRetailItemForServiceAddOn() {
    this.http.CallApiWithCallback<any>({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetActiveRetailItems",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  GetRetailItemByType(val: number) {
    this.http.CallApiWithCallback<any>({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetRetailItemByItemType",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { "type": val },
      extraParams: []
    });
  }

  GetRetailItemByTypeForLookup(val: number) {
    this.http.CallApiWithCallback<any>({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: RetailRoutes.GetRetailItemByItemTypeForLookup,
      method: HttpMethod.Get,
      showError: true,
      uriParams: { "type": val },
      extraParams: []
    });
  }

  SelectedRecord(data) {
    const id = data.id;
    this.Done(id);
    this.searchText = data.itemNumber + ' - ' + data.itemDescription;
    this.searchFilter = [];
    this.linkedItemsClicked = false;
  }

  autocompleteShow(e) {
    this.linkedItemsClicked = true;
    e.stopPropagation();
  }

  clickedOutside() {
    if (this.linkedItemsClicked) {
      this.linkedItemsClicked = false;
    }
  } 

  makeSearchCall(name: string) {
    this.isRetailItemSelected = false;
    if (name != undefined && name.length > 0) {
      const result = [];
      this.searchFilterResult.forEach(element => {
        if (this.ToMatch(element.id, name) || this.ToMatch(element.itemDescription, name)) {
          result.push(element);
        }
      });
      this.searchFilter = result;
    }
  }

  ToMatch(from: string, to: string): boolean {
    let fromValue: string = from.toString().toUpperCase();
    fromValue = fromValue.replace(/[^a-zA-Z0-9]/g, "")
    to = to.toUpperCase();
    to = to.replace(/[^a-zA-Z0-9]/g, "")
    if (fromValue.match(to)) {
      return true;
    } else {
      return false;
    }
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetAllShopItems") {
      const LinkedList = result.result ? result.result : [];
      this.ORG_searchFilter = _.cloneDeep(LinkedList);
    } else if (callDesc == "GetActiveRetailItems") {
      this.searchFilterResult = result ? result.result : [];
      this.searchFilter = this.searchFilterResult;
      this.searchFilter = [];
      this.LoadAndDisplayItem();
    }
    else if (callDesc == "GetRetailItemByItemType") {
      this.searchFilterResult = result ? (result.result as unknown as any[]).map(val => {
        if (val)
          return val.retailItemDetail
      }) : [];
      this.searchFilter = this.searchFilterResult;
      this.searchFilter = [];
      this.LoadAndDisplayItem();
    }
    else if (callDesc == RetailRoutes.GetRetailItemByItemTypeForLookup) {
      this.searchFilterResult = result ? (result.result as unknown as any[]).map(val => {
        if (val)
          return val
      }) : [];
      this.searchFilter = this.searchFilterResult;
      this.searchFilter = [];
      this.LoadAndDisplayItem();
    }
  }

  LoadAndDisplayItem() {
    if (this.searchFilterResult.length && this._retailItemId) {
      const retailItem = this.searchFilterResult.find(x => x.id == this._retailItemId);
      this.autocomplete.setValue(retailItem);
      this.isRetailItemSelected = true;
      this.displayFn(retailItem);
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }
  displayFn(searchVal: any) {
    if (searchVal) {
      const dispValue = searchVal.itemNumber + ' - ' + searchVal.itemDescription;
      this.isRetailItemSelected = true;
      if (this.isRetailItemSelected) {
        this.searchText = dispValue;
      }
      return dispValue;
    }
  }
  formFieldValidator() {
    this.searchControlValidator.emit((this.autocomplete.value) ? true : false);
  }

  heightCalc() {
    let searchData = document.getElementById('searchData');
    let searchLength = this.searchFilter.length * 48;
    let searchOuter = document.getElementById('searchOuter');
    if (searchData && searchOuter) {
      if (searchLength < searchOuter['offsetHeight']) {
        searchData['style']['height'] = searchLength + 'px';
      } else {
        searchData['style']['height'] = searchOuter['offsetHeight'] + 2 + 'px';
      }
    } else {
      if (searchOuter)
        searchOuter['style']['height'] = 0 + 'px';
    }
  }
}
