<div class="waittime">
  <div class="waittime__actions">
    <div class="waittime__filterby">
      <activities-app-button [buttontype]="seatingAreaBtn" (click)="bySectionMode()"></activities-app-button>
      <activities-app-button [buttontype]="restaurantBtn" (click)="restaurantMode(false)"></activities-app-button>
    </div>
    <div class="waittime__waittimemode">
      <span class="waittime__actionButton" *ngIf="IsInManualWaitTimeMode == true">{{'manualmode' | translate}}</span>
      <span class="waittime__actionButton"
        *ngIf="IsInManualWaitTimeMode == false">{{'automaticmode' | translate}}</span>
      <span class="waittime__actionButton" *ngIf="isFormDirty == true">({{timeSinceLastUpdated}})</span>
      <activities-app-button [buttontype]="autoResetBtn" *ngIf="IsInManualWaitTimeMode == true" (click)="resetWaitTime()">
      </activities-app-button>
    </div>
  </div>
  <div class="waittime__mat-tableDiv">
    <ng-scrollbar track="all">
      <table mat-table [dataSource]="waitTimesDataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="partysize">
          <th mat-header-cell *matHeaderCellDef> {{'partySize' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.PartySize}} </td>
        </ng-container>
        <ng-container *ngFor="let disCol of Columns; let colIndex = index" matColumnDef="{{disCol}}">
          <th mat-header-cell *matHeaderCellDef>{{disCol}} <br> <span>
              <button mat-button
                (click)="increaseOrDecreaseWaitTimeBySection(colIndex,-1)">{{'labelDecrease5mins' | translate}}</button>
              <button mat-button
                (click)="increaseOrDecreaseWaitTimeBySection(colIndex,1)">{{'labelIncrease5mins' | translate}}</button>
            </span></th>
          <td mat-cell class="mat-cell-edit" *matCellDef="let element; let i = index" [attr.colspan]="1">
            <editable (update)="updateField(i, colIndex)">
              <ng-template viewMode>
                <span>{{element.WaitTimes[colIndex].OriginalWaitTimeString}}</span>
              </ng-template>
              <ng-template editMode>
                <mat-form-field class="example-full-width">
                  <div class="tableCell">
                    <button mat-button class="decrement" (click)="increaseOrDecreaseWaitTime(element,i,colIndex,-1)"> -
                    </button>
                    <span><input matInput [formControl]="getControl(i, colIndex)"></span>
                    <button mat-button class="increment"
                      (click)="increaseOrDecreaseWaitTime(element,i,colIndex,1)">+</button>
                  </div>
                </mat-form-field>
              </ng-template>
            </editable>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </ng-scrollbar>
  </div>
  <div class="waittime__finalActions" *ngIf="isFormDirty == true">
    <div class="waittime__actionButtons">
      <activities-app-button [buttontype]="saveChangesBtn" (click)="saveChanges()"></activities-app-button>
      <activities-app-button [buttontype]="cancelChangesBtn" (click)="cancelChanges()"></activities-app-button>
    </div>
  </div>
</div>