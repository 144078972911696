import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-ts-client-uid': sessionStorage.getItem("ClientUid") ? sessionStorage.getItem("ClientUid") : '' })
};

@Injectable({
  providedIn: 'root'
}
)
export class HttpService {

  constructor(public http: HttpClient) {
  }

  get(path: string, ForceDisableLoader = false, restaurantId?: number): Observable<any> {
    if (restaurantId) {
      httpOptions.headers = httpOptions.headers.set('restaurantID', restaurantId.toString());
    } else {
      httpOptions.headers = httpOptions.headers.set('restaurantID', '');
    }
    httpOptions.headers = httpOptions.headers.set('disableLoader', ForceDisableLoader.toString());
    return this.http.get(`${path}`, httpOptions).pipe(map(response => response), catchError(this.handleError));
  }

  put(path: string, body: Object = {}, ForceDisableLoader = false, restaurantId?: number): Observable<any> {
    if (restaurantId) {
      httpOptions.headers = httpOptions.headers.set('restaurantID', restaurantId.toString());
    } else {
      httpOptions.headers = httpOptions.headers.set('restaurantID', '');
    }
    httpOptions.headers = httpOptions.headers.set('disableLoader', ForceDisableLoader.toString());
    return this.http.put(`${path}`,
      body,
      httpOptions
    ).pipe(map(response => response), catchError(this.handleError));
  }

  post(path: string, body: Object = {}, ForceDisableLoader = false, restaurantId?: number): Observable<any> {
    if (restaurantId) {
      httpOptions.headers = httpOptions.headers.set('restaurantID', restaurantId.toString());
    } else {
      httpOptions.headers = httpOptions.headers.set('restaurantID', '');
    }
    httpOptions.headers = httpOptions.headers.set('disableLoader', ForceDisableLoader.toString());
    return this.http.post(`${path}`,
      body,
      httpOptions
    ).pipe(map(response => response));
  }

  delete(path, ForceDisableLoader = false, restaurantId?: number): Observable<any> {
    if (restaurantId) {
      httpOptions.headers = httpOptions.headers.set('restaurantID', restaurantId.toString());
    } else {
      httpOptions.headers = httpOptions.headers.set('restaurantID', '');
    }
    httpOptions.headers = httpOptions.headers.set('disableLoader', ForceDisableLoader.toString());
    return this.http.delete(`${path}`, httpOptions
    ).pipe(map(response => response));
  }

  handleError(error: any) {
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : "Server errorsssss";
    console.error(errMsg);
    return throwError(errMsg);
  }
}
