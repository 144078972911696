import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BlockActivityType, HeadlineActivityType } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-block-session',
  templateUrl: './block-session.component.html',
  styleUrls: ['./block-session.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockSessionComponent implements OnInit {
  blockSessionConfig: FieldConfig[];
  @Input() data: any;
  activityData:any;
  @ViewChild('blockSessionForm', { static: true }) blockSessionForm: DynamicFormComponent;


  constructor(private ts:TranslateService, private partyService: PartyService) { }

  ngOnInit(): void {
    this.setConfig();
    this.activityData = this.data[0].activityData;
  }


  ngAfterViewInit(): void {
    this.partyService.blockSessionForm = this.blockSessionForm.form;
  }

  setConfig(){
    this.blockSessionConfig = [
               {
          type: 'radio', 
          name: 'blockType',
          placeholder: '',
          options: this.data[0].options,
          value: BlockActivityType.OnlyThis,
          class: 'block-session__session-selection',
        }];
        if(this.data[0].blockSession){
          this.blockSessionConfig.push({
          type: 'textarea',
          name: 'blockReason',
          placeholder: "blockReason",
          label: "blockReason",
          inputType: 'text',
          textAreaAppearance: true,
          class: "block-session__notes-section",
        });
  
  }
  }
}
