import { Component, Input, OnInit, ViewEncapsulation, QueryList, ViewChildren, Pipe, PipeTransform } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { buttonTypes, ComponentTypes, LessonType, PartyType, PropertyType, SelectionType, PartyState } from '@constants/commonenums';
import { LoaderService } from '@core/services/loader.service';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { OperationResultDTO } from '@models/ChangeTrackingOperationResultDTO';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import _, { size } from 'lodash';
import { ContactNameFilterPipe } from '@pipes/contactNameFilter.pipe';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { Subscription } from 'rxjs';
import moment from "moment";
import * as globals from '@constants/globalConstants';
import { FloorPlanDTO, SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { CacheService } from '@app/core/services/cache.service';
import { I } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TicketComponent extends Utilities implements OnInit {
  explainBtn: ButtonValue;
  cancelBtn: ButtonValue;
  public _settings: SettingsDTO = {} as SettingsDTO;
  @Input() data: any;
  barcodeInput: any;
  dataString;
  bookingData: any = [];
  standByParties: any = [];
  ActivityBookings: any = [];
  confirmedBookings: any = [];
  specialMeals: any = [];
  bookedMultipleSessions: any = [];
  reservationData: any = [];
  LessonType = LessonType;
  PropertyType = PropertyType;
  SelectionType = SelectionType;
  buttonValueprimary: ButtonValue;
  buttonSecondary: ButtonValue;
  searchConfig: FieldConfig[];
  ipconfig: FieldConfig[] = [];
  searchString: string = '';
  subscriptions: Subscription = new Subscription();
  printResult: any = [];
  tempBookingdata = [];
  timeSlotUnitInMinutes: any;
  tempTicketId = 0;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  showSeatSelected: boolean = false;

  constructor(private appService: AppService, private partyService: PartyService, public popupService: PopupService, private _ts: TranslateService, private searchStringFilter: ContactNameFilterPipe,
    dialog: MatDialog, private cs: CacheService) {
    super(dialog);
    this.showSeatSelected = globals.SeatVenue.indexOf(TicketComponent.getPropertyType()) > -1;
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
  }

  ngOnInit(): void {
    this.setUpControlConfigs();
    this.specialMeals = this.appService._setting.SpecialMeals;
    this.confirmedBookings = Array.isArray(this.partyService.bookingConfirmationData) ? this.partyService.bookingConfirmationData : [this.partyService.bookingConfirmationData];
    this.confirmedBookings = this.confirmedBookings.flat()
    this.reservationData = this.partyService.reservationConfirmationData;
    if (Utilities.IsActivitiesVenues(this.appService._setting.PropertyType)) {
      this.getBookingData();
    }
    if (Utilities.AnyVenue(this.appService._setting.PropertyType)) {
      this.timeSlotUnitInMinutes = this.appService._setting.General.TimeSlotUnitInMinutes;
      this.getReservationData();
    }
    this.formatingPrintData();
  }

  partiesSelection(selectionType) {
    if (selectionType == SelectionType.select) {
      this.bookingData.forEach(element => {
        element.selected = true;
      });
    }
    else if (selectionType == SelectionType.unselect) {
      this.bookingData.forEach(element => {
        element.selected = false;
      });
    }
    this.formatingPrintData();
  }
  setUpControlConfigs() {
    this.buttonValueprimary = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Select All',
      customclass: 'chip-print__select-btn'
    }
    this.buttonSecondary = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Unselect All',
      customclass: 'chip-print__unselect-btn'
    }

    this.searchConfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this._ts.instant('searchByName'),
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: (event) => this.clearSearchField(event)
      }
    ];
    if (this._settings.PrintersInfo && this._settings.PrintersInfo.length) {
      let val = this._settings.PrintersInfo.filter(x => x.IsDefault);
      this.ipconfig = [
        {
          type: 'select',
          name: 'ipConfig',
          placeholder: '',
          options: this.getPrinters(),
          class: 'guestlist__sort-by',
          showErrorText: true,
          appearance: true,
          isTranslate: true,
          value: val ? val[0].Id : this._settings.PrintersInfo[0].Id
        }

      ]
        ;
    }
  }

  getPrinters() {
    return this._settings.PrintersInfo.map(printer => {
      return {
        id: printer.Id,
        value: printer.PrinterName + "(" + printer.IPAddress + ")"
      }
    })
  }
  clearSearchField(event) {
    this.searchString = '';
    this.components.first.form.get('searchText').setValue('');
  }
  getReservationData() {
    this.bookingData.push(this.setPayload(this.ActivityBookings.find((party) => party.Id === this.reservationData.PartyId), PropertyType.Restaurant));
    this.tempBookingdata = this.bookingData;
  }
  getBookingData() {
    this.bookedMultipleSessions = [];
    this.confirmedBookings.forEach((booking) => {
      this.getBookedSessionFromSpecialMeals(booking, LessonType.GroupLesson)
    })
    this.bookingData = this.multipleBookingData(this.bookedMultipleSessions);
    this.tempBookingdata = this.bookingData;
  }

  getBookedSessionFromSpecialMeals(booking, type) {
    let specialMeal = this.cs.specialMealListForMerchant.find(specialMeal => specialMeal.Id == booking.SpecialMealId);
    if ((specialMeal && specialMeal.EnableTicketPrinting)) {
      booking.ConfirmedSessionsForCart.forEach(session => {
        if (session.SessionType === PartyType.Reservation && session.SessionState !== PartyState.Seated && session.SessionState !== PartyState.Left) {
          let propertyName = this.appService._cs.propertySettings.value[booking.PropertyId ? booking.PropertyId : this.appService._setting.PropertySetting[0].RestaurantId].settings.General.RestaurantName;
          let defaultFloorplan: FloorPlanDTO = this.appService._cs.propertySettings.value[booking.PropertyId ? booking.PropertyId : this.appService._setting.PropertySetting[0].RestaurantId].layout.FloorPlans.find(floor => floor.IsDefault);
          let tableName = '';
          if (defaultFloorplan) {
            let selectedTable = defaultFloorplan.StandaloneTables.find(standaloneTable => standaloneTable.Id == session.TableIds[0]);
            if (selectedTable) {
              tableName = selectedTable.Name;
            }
          }
        let bookingDetails = {
          Name: (booking.FirstName ? booking.FirstName : '') + " " + (booking.LastName ? booking.LastName : ''),
          StartDate: booking.StartDate,
          EndDate: booking.EndDate,
          ClassName: specialMeal ? specialMeal.Name : 'Open Booking',
          RestaurantName: propertyName,
          StartTime: `${booking.StartDate.split("T")[0]}T${session.StartTime}`,
          EndTime: `${booking.EndDate.split("T")[0]}T${session.EndTime}`,
          Size: session.BookingSize > 0 ? session.BookingSize :booking.PartySize,
          TableName: tableName ? tableName : 'Any',
          Type: type,
          barcodeInput: booking.UniqueId,
          selected: false,
          Id: booking.PartyId,
          ConfirmationCode: booking.ConfirmationCode ? booking.ConfirmationCode : '-',
          EnableTicketPrinting: specialMeal?.EnableTicketPrinting,
          TicketPerPerson: specialMeal?.TicketPerPerson,
          ticketOrder: 1,
          BatchName: booking.SessionGroupName,
          PackageId : booking.PackageId  ? booking.PackageId : null
        }
        this.bookedMultipleSessions.push(bookingDetails);
      }
      })
    }
  }
  multipleBookingData(bookingData) {
    let ticketList = []
    bookingData.forEach(data => {
      if (data.TicketPerPerson) {
        for (let i = 0; i < data.Size; i++) {
          data.tempTicketId = this.tempTicketId + 1;
          data.ticketOrder = i + 1;
          let uniqueData = Object.assign({}, data);
          ticketList.push(uniqueData);
          this.tempTicketId = this.tempTicketId + 1;
        }
      } else {
        data.tempTicketId = this.tempTicketId + 1;
        ticketList.push(data);
        this.tempTicketId = this.tempTicketId + 1;
      }
    })
    return ticketList;
  }
  selectData(ticket) {
    this.bookingData.forEach((data) => {
      if (ticket.Id === data.Id && ticket.tempTicketId == data.tempTicketId) {
        data.selected = !data.selected;
      }
    })
    if (this.bookingData && this.bookingData.length > 0) {
      this.formatingPrintData();
    }

  }
  formatingPrintData() {
    if (this.bookingData && this.bookingData.length > 0) {
      this.printResult = this.bookingData.filter(data => data.selected === true);
      this.partyService.printresult = this.printResult;
    }
    this.popupService.saveBtnEnable$.next(this.printResult.length > 0 ? true : false);
  }
  getActivitySessions(ActivitySessions, ActivitySessionId, type) {
    let meal = ActivitySessions.find(session => session.ActivitySessionId === ActivitySessionId);
    return type === 'StartTime' ? meal.StartTime : meal.EndTime;
  }
  setPayload(party, type) {
    let specialMeal = this.specialMeals.find(specialMeal => specialMeal.Id == party.SpecialMealId);
    if (specialMeal && specialMeal.EnableTicketPrinting) {
      let bookingDetails = {
        Name: (party.Contact.FirstName ? party.Contact.FirstName : '') + " " + (party.Contact.LastName ? party.Contact.LastName : ''),
        StartDate: party.StartDate ? party.StartDate : party.ReservedFor,
        EndDate: party.EndDate ? party.EndDate : party.ReservedFor,
        ClassName: specialMeal ? specialMeal.Name : 'Open Booking',
        RestaurantName: this.appService.menuHeader,
        StartTime: party.SeatingTime,
        EndTime: Utilities.IsActivitiesVenues(this.appService.PropertyType) ? party.DepartureTime : this.getEndTime(party.SeatingTime),
        Size: party.Size,
        TableName: party.TableNames[0] ? party.TableNames[0] : 'Any',
        Type: type,
        barcodeInput: party.UniqueId,
        selected: false,
        Id: party.Id,
        ConfirmationCode: party.ConfirmationCode ? party.ConfirmationCode : '-',
        EnableTicketPrinting: specialMeal?.EnableTicketPrinting,
        TicketPerPerson: specialMeal?.TicketPerPerson,
        tempTicketId: this.tempTicketId + 1,
        ticketOrder: 1,
        BatchName: party.SessionGroupName ? party.SessionGroupName : null,
        PackageId : party.PackageId  ? party.PackageId : null
      }
      this.tempTicketId = this.tempTicketId + 1
      return bookingDetails
    }
  }
  getEndTime(time) {
    var endTime = new Date(time);
    endTime.setMinutes(endTime.getMinutes() + this.timeSlotUnitInMinutes);
    endTime = new Date(endTime);
    return moment(endTime).format('YYYY-MM-DDTHH:mm:ss');;
  }
  ngAfterViewInit() {
    /*  this.subscriptions.add(this.components.form.valueChanges.subscribe(val => {
       this.searchString = val.searchText;
       this.getSearchData();
     })); */
    this.components.forEach(form => {
      form.form?.valueChanges.subscribe(data => {

        if (data.searchText || data.searchText == '' ) {
          this.searchString = data.searchText;
          this.getSearchData();
        }
        if (data.ipConfig) {
          this.partyService.selecetedPrinter = data.ipConfig;
        }
      })
    });
  }
  getSearchData() {
    this.bookingData = this.searchString ? this.tempBookingdata.filter(res => res.Name.toLowerCase().includes(this.searchString.toLowerCase())) : this.tempBookingdata;
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}


@Pipe({
  name: 'arrayGenerator'
})
export class ArrayGeneratorPipe implements PipeTransform {

  transform(number: number): any {
    if (!number || isNaN(number)) {
      return [1];
    }
    return Array.from(Array(number).keys());
  }
}

@Pipe({
  name: 'checkPrintTicketEnabled'
})
export class CheckPrintTicketEnabledPipe implements PipeTransform {

  transform(bookindData: any[]): any {
    if (bookindData) {
      return bookindData.filter(data => data.EnableTicketPrinting);
    }
    return [];
  }
}