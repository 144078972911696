import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortOrder'
})
export class SortOrderPipe implements PipeTransform {
  orderBy = 'aesc';
  transform(data: any[], type: string, orderBy: string): any {
    this.orderBy = orderBy;
    if (!data) return data;
    data.sort((a, b) => {
      const data1 = a[type];
      const data2 = b[type];
      if (this.orderBy === 'desc') {
        if (data2 < data1) {
          return -1;
        }
        if (data2 > data1) {
          return 1;
        }
        return 0;
      } else {
        if (data1 < data2) {
          return -1;
        }
        if (data1 > data2) {
          return 1;
        }
        return 0;
      }
    });
    return data;
  }

}
