import { Pipe, PipeTransform } from '@angular/core';
import { MultilingualService } from '@app/core/services/multilingual.service';
import { Labels } from '@app/shared/constants/commonenums';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';
import { DEFAULT_LANGUAGE_CODE } from '../constants/globalConstants';

@Pipe({
    name: 'slotTimeFormatter'
})
export class SlotTimeFormatter implements PipeTransform {
    btnFormat: ButtonValue;
    constructor(public ms: MultilingualService) {
    }

    transform(data: any): any {
        // let timeZoneDate = new Date(data);
        // let SlotDate = new Date(timeZoneDate.getTime() + (timeZoneDate.getTimezoneOffset() * 60000));
        // const SlotDate = Utilities.parseDateString(data);
        // let amPm = Labels[Labels.slotTimeAM];
        // let hrs = SlotDate.getHours();
        // let mins = ('0' + SlotDate.getMinutes()).slice(-2);
        // if (hrs >= 12) {
        //   hrs = hrs === 12 ? 12 : hrs - 12;
        //   amPm = Labels[Labels.slotTimePM];
        // }
        // if(hrs == 0){
        //     hrs=12;
        // }
        // const slotTime = hrs + '.' + mins + ' ' + this.translateService.instant(amPm);
        // return slotTime;
            let localMoment = moment(data);
            let languagesAvailable = JSON.parse(sessionStorage.getItem(`languages${Utilities.getSessionStorageType()}`));
            let languageCode =languagesAvailable ? Utilities.findLocale(languagesAvailable) : DEFAULT_LANGUAGE_CODE;
                    return localMoment.locale(languageCode).format('LT');      }

    
    



}


