import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ReservationType, TableBlockingRuleFrequencyType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { SettingsService } from '@services/settings.service';
import { Utilities } from '@utilities/utilities';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { DateRangeSelectionComponent } from '../date-range-selection/date-range-selection.component';


@Component({
  selector: 'app-schedule-selection',
  templateUrl: './schedule-selection.component.html',
  styleUrls: ['./schedule-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  selectedFrequencyValue: TableBlockingRuleFrequencyType = null;//= TableBlockingRuleFrequencyType.TheOnlyDay;
  leftArrowDisabled = true;
  rightArrowDisabled = false;
  availableBtnSpace: any;
  noShowIcon: boolean;
  btnWidth: number;
  config: FieldConfig[];
  seatingArea: any;
  commontabs: any = [];
  selectedfrequencyDate: Date;
  selectedIndex = 0;
  tabSubscription: ISubscription;
  @ViewChild('buttonView') btnView: ElementRef;
  @Input() title: string;
  tabChangeSubscription: ISubscription;
  TableBlockingRuleType = TableBlockingRuleFrequencyType;
  Reservationtypes: number = 0;
  IscustomFloor = false;
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  subscriptions: Subscription = new Subscription();
  @HostListener('window:resize', ['$event'])
  onResize(event) {

  }


  constructor(public translateService: TranslateService, public fb: UntypedFormBuilder, public partyService: PartyService, @Inject(COMPONENTINPUT) private data,
    private ps: PopupService, private ss: SettingsService, private datePipe: DatePipe, public cs: CacheService) {
    this.datePipe = new DatePipe(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_userLocale`));
  }


  getdayName(date) {
    var d = new Date(date);
    return this.days[d.getDay()];
  }

  setFrequencyValue(selectedval) {
    this.selectedFrequencyValue = selectedval;
    const defaultSelectedArea = this.partyService.reservationFormGroup.value.selectedSchedule;
    if (selectedval !== defaultSelectedArea) {
      if (this.partyService.reservationType === ReservationType.Blockingrule) {
        this.updateTabs();
        this.Reservationtypes = 1;
      }
      this.reservationFormChange();
      this.partyService.reservationFormGroup.markAsDirty();
      this.partyService.reservationFormGroup.updateValueAndValidity();
    }
  }
  updateTabs() {
    let tabdata = [{
      tabComponent: DateRangeSelectionComponent,
      enabledIcon: true,
      tabLabel: 'popUpDate',
      tabHeader: 'popUpTitleSeatDate',
      tabIcon: 'Group-595',
      isDisabled: false
    }];



    let gettabs = this.partyService.tabsModal.tabs.length;
    let tabisavilable = this.partyService.tabsModal.tabs.filter(tab => {
      return tab.tabComponent == DateRangeSelectionComponent
    });


    if (this.selectedFrequencyValue == TableBlockingRuleFrequencyType.TheOnlyDay) {
      if (tabisavilable.length > 0)
        this.partyService.tabsModal.tabs.splice(gettabs - 1, 1);

    } else {
      if (!(tabisavilable.length > 0)) {
        tabdata.forEach(element => {
          this.partyService.tabsModal.tabs.push(element);
        });
      }
    }
  }

  ngOnInit() {
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === ScheduleSelectionComponent);
    this.selectedfrequencyDate = this.partyService.reservationFormGroup.controls.selectedDate.value;
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedTable').valueChanges.subscribe((val) => {
      let crntSelectedVal = this.partyService.reservationFormGroup.get("selectedTable").value
      let type = ( this.data && this.data.Frequency ) ? this.data.Frequency.Type :TableBlockingRuleFrequencyType.TheOnlyDay 
      this.setFrequencyValue(type);
      this.reservationFormChange();
    }));
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedDate').valueChanges.subscribe((val) => {
      this.selectedfrequencyDate = this.partyService.reservationFormGroup.controls.selectedDate.value;
      this.selectedFrequencyValue = null;
      this.reservationFormChange();
    }));
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedTableFloor').valueChanges.subscribe((val) => {
      this.selectedFrequencyValue = null;
      this.reservationFormChange();
    }));
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedTableShift').valueChanges.subscribe((val) => {
      this.selectedFrequencyValue = null;
      this.reservationFormChange();
      if (this.data && this.data.Frequency && this.data.Frequency.Type && this.partyService.reservationFormGroup.get("selectedTable").value) {
        this.setFrequencyValue(this.data.Frequency.Type);
      } else {
        this.setFrequencyValue(TableBlockingRuleFrequencyType.TheOnlyDay);
      }
    }));
    if (this.partyService.reservationType === ReservationType.Blockingrule) {
      this.Reservationtypes = 1;
    }
    else {
      this.Reservationtypes = 0;
    }
    if (this.data && this.data.Frequency && this.data.Frequency.Type != null && this.partyService.reservationFormGroup.get("selectedTable").value) {
      this.setFrequencyValue(this.data.Frequency.Type);
    }
  }


  ngAfterViewInit() {
    this.tabSubscription = this.partyService.tabChange$.subscribe((index) => {
      if (this.selectedIndex == index) {
        this.IscustomFloor = this.partyService.reservationFormGroup.controls.selectedTableFloor.value ? true : false;
        this.selectedfrequencyDate = this.partyService.reservationFormGroup.controls.selectedDate.value;
        this.reservationFormChange();
      }

    });


  }
  ngOnDestroy(): void {
    this.partyService.reservationType = null;
    if (this.tabSubscription)
      this.tabSubscription.unsubscribe();
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

  reservationFormChange() {
    let numberTest = (this.partyService.reservationFormGroup.value.selectedFrequency);
    if (numberTest === "" || this.selectedFrequencyValue != this.partyService.reservationFormGroup.value.selectedFrequency) {
      this.partyService.reservationFormGroup.get('selectedFrequency').setValue(this.selectedFrequencyValue);
    }
    if (this.selectedIndex >= 0) {
      let names = this.translateService.instant("frequency");
      if (this.selectedFrequencyValue == TableBlockingRuleFrequencyType.TheOnlyDay) {
        names = this.translateService.instant("oneDay");
      }
      else if (this.selectedFrequencyValue == TableBlockingRuleFrequencyType.EveryDay) {
        names = this.translateService.instant("everyday");
      } else if (this.selectedFrequencyValue == TableBlockingRuleFrequencyType.EveryWeekDay) {
        names = this.translateService.instant("every")+ ' ' + this.translateService.instant(this.getdayName(this.selectedfrequencyDate));
      }

      this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = names;
    }
    this.ps.formValid$.next(
      {
        isFormValid: this.checkIsformIsValid(),
        currentTab: this.selectedIndex
      } as IFormValidDetails);
  }

  checkIsformIsValid() {

    if (this.partyService.reservationFormGroup.value.selectedFrequency != null && this.partyService.reservationFormGroup.value.selectedFrequency >= 0
      && this.partyService.reservationFormGroup.value.selectedDate && this.partyService.reservationFormGroup.get("selectedTable").value
      && this.partyService.reservationFormGroup.get("selectedTable").value.length > 0) {
      if (this.partyService.reservationFormGroup.get('selectedFrequency').value == TableBlockingRuleFrequencyType.TheOnlyDay) {
        if (!this.checkIspastDate()) {
          return false;
        }
        return true;
      }
      else {
        return true;
      }
    }
    return false;

  }
  showerromsg() {
    if (this.IscustomFloor) {
      if (!this.checkIspastDate()) {
        return true;
      }
    }
    return false;
  }
  checkIspastDate() {
    let currentdate = new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)).setHours(0, 0, 0, 0);
    let SelectedDates = new Date(this.selectedfrequencyDate).setHours(0, 0, 0, 0);
    let val = currentdate <= SelectedDates;
    return val;
  }

}

