import { Component,  ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { createModal, RightSideLinkModel } from './create-model';

@Component({
  selector: 'app-create-modal',
  templateUrl: './create-modal.component.html',
  styleUrls: ['./create-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateModalComponent {
  title: string;
  rightAlignLinks: boolean;
  headerTabData:boolean;
  @Output() clickEvent = new EventEmitter();
  @Output() clickNavigationLink = new EventEmitter();
  links: RightSideLinkModel[];
  disabled:boolean;
  @Input('input')
  set inputOptions(value: createModal) {
    this.title = value?.title;
    this.rightAlignLinks = value?.isRightSideLinksAvailable ? value?.isRightSideLinksAvailable : false;
    this.links = value?.rightSideLinks ? value?.rightSideLinks : [];
    this.headerTabData= value?.headerTabData ? value?.headerTabData :false;
    this.captions= value?.captions ? value?.captions : '';
    this.disabled=value?.disable ? value?.disable : false
  }
  captions:any;
  back(event) {
    this.clickEvent.emit(event)
  }

  navigateTo(data,i){
    this.clickNavigationLink.emit(data);
  }

}
