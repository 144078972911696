import { S } from "@angular/cdk/keycodes";
import { CoverType, getGuestTypes } from "./OpenBookingDTO";
import { BookingType } from "./OpenBookingDTO";
import { BookingBehavior } from "../constants/commonenums";
import _ from "lodash";
import { PackageShortDTO } from "./PackageDTO";

export class SessionBookingDTO {
    BookingId:number;
    ActivityId: number;
    ClassType: number;
    FromDate: string;
    ToDate: string;
    Location: number[];
    LocationName: string[];
    StaffId: number[];
    BookingSize: number;
    SalesContactIds:number[];
    MembershipDetail: string;
    CoverTypes: CoverType[];
    BookingTypes: BookingType[];
    Slots: any[];
    SessionGroupId: number;
    AvailabilityCriteria: number;
    SessionGroupName: string;
    AllowStandBy: boolean;
    MinRateAdjustment: number;
    OverBooked: number;
    SizeMapping: any;
    BookingContacts: any[];
    BookingBehavior: number;
    SpecialMealId?: number;
    PageMethod: number;
    IsOverBook: boolean;
    PackageDetails?:PackageShortDTO;
    IsExclusiveBooking?: boolean;
    AdditionalInfo: string[];
    constructor(bookingId, activityId, classType, fromDate, toDate, location, locationName, staffId,
        bookingSize,salesContactIds, membershipDetails, guestTypes,bookingTypes, slots, sessionGroupId,specialMealId , PackageDetails? , IsExclusiveBooking?: boolean,AdditionalInfo?){
        this.BookingId = bookingId;
        this.ActivityId = activityId;
        this.ClassType = classType;
        this.FromDate = fromDate;
        this.ToDate = toDate;
        this.Location = location;
        this.LocationName = locationName;
        this.StaffId = staffId;
        this.BookingSize = bookingSize;
        this.SalesContactIds = salesContactIds;
        this.MembershipDetail = membershipDetails;
        this.CoverTypes = getGuestTypes(guestTypes);
        this.BookingTypes=bookingTypes;
        this.Slots = slots;
        this.SessionGroupId = sessionGroupId;
        this.BookingBehavior = BookingBehavior.ClassOrSession;
        this.SpecialMealId = specialMealId;
        this.PackageDetails = PackageDetails;
        this.IsExclusiveBooking  = IsExclusiveBooking;
        this.AdditionalInfo = AdditionalInfo;
    }
}