import { BaseReport } from '../common/base-report';
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports, OutletAndCategoryFilter } from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';
import * as _ from 'lodash';
import { ReportBusinessService } from '../report-business.service';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
export class SalesByCategory extends BaseReport {
    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, private func: RetailFunctionalityBusiness, private propertyInfo: RetailPropertyInformation
        , public retailLocalization : RetailLocalization, private featureFlag: RetailFeatureFlagInformationService) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.SalesByCategory,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: false,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'LANDSCAPE',
            allowFutureDate: false
        }; 
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        const toApi = this.localization.convertDateObjToAPIdate;
        const customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const captions: string[] = this.business.getSelectedCategoryCaptions(customFilterData);
        const reportCategoryCaption: string = this.business.getFilterCaption(this.business.FilterDataSource['allCategoryGroups'], customFilterData.reportcategory.map(x => x.id));
        const userCaption: string = this.business.getFilterCaption(this.business.FilterDataSource['clerks'], customFilterData.clerks.map(x => x.id));
        const radioFilter = customFilterData.itemFilter === 0 ? true : false;
        let machineCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["allMachineNames"],customFilterData.machineName.map(x=>x.id));
        return [{'pStartDate': toApi(this.startDate) }, 
                { 'pEndDate': toApi(this.endDate) }, 
                {'pDate': this.printedDate}, 
                { 'pPageBreak': this.pageBreakToggle},
                {'pIncludeZeroMemberCharge':customFilterData.includeZeroMemberChargeToggle},
                {'pPropertyName': this.propertyName}, 
                {'pUser': userCaption},
                {'pOutlet': this.functionalities.ShowOutletSelectionFieldInSaleByCategoryReport ? this.getFilterCaption : this.getDefaultOutletCaption}, 
                {'pDetail': radioFilter},
                {'pReportCategory': reportCategoryCaption}, 
                {'pCategory': captions[0]}, 
                {'pSubCategory1': captions[1]}, 
                {'pSubCategory2': captions[2]},
                {'pSubCategory3': captions[3]}, 
                {'pSubCategory4': captions[4]}, 
                {'pSubCategory5': captions[5]},
                {'pMachineName': machineCaption},
                {'pPrintMachineName': this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false},
                {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInSaleByCategoryReport ? true : false },
                {'pIncludeNotMappedValue': customFilterData?.includeCategoryNotMapped ?? false },
                {"pIsEatecEnabled":this.featureFlag.IsEatecEnabled==true ? "true" : "false"}    ];
    }


    protected formURIParams(): ReportParams[] {
        return [];
    }


    protected formFilters() { 
        const customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        const toApi = this.localization.convertDateObjToAPIdate;
        let showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;
        let isAllMachineSelected: boolean = this.business.checkAllSelected(this.business.FilterDataSource["allMachineNames"],customFilterData.machineName.map(x=>x.id));      
        const tempOutFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        const reportGroups = this.business.FilterDataSource['allCategoryGroups'];
        let filterReportGroupIds : number[] =  customFilterData.reportcategory.map(t=>t.id);
        if(reportGroups && reportGroups.length > 0 && reportGroups.length === filterReportGroupIds.length && customFilterData.includeCategoryNotMapped ){
            filterReportGroupIds = [];
        }
        const tempMachineNameIds = isAllMachineSelected ? [] : customFilterData.machineName.map(x => x.id);
        if (this.isJasperReportEnabled) {
            let outletids = this.functionalities.ShowOutletSelectionFieldInSaleByCorrectOrVoidReport ? this.getMultiSelectValuesForJasper('outletOptions')
                : this.getDefaultOutletIdForJasper;
            return {
                StartDate: toApi(this.startDate),
                EndDate: toApi(this.endDate),
                CategoryIds: customFilterData.category[0].selectedCat.length > 0  && customFilterData.category[0].selectedCat.length != customFilterData.category[0].catArr.length ? this.getCategoryMultiSelectValuesForJasper(customFilterData.category[0].selectedCat) : [],
                SubCategory1Ids: customFilterData.category[1].selectedCat.length > 0 && customFilterData.category[1].selectedCat.length != customFilterData.category[1].catArr.length? this.getCategoryMultiSelectValuesForJasper(customFilterData.category[1].selectedCat) : [],
                SubCategory2Ids: customFilterData.category[2].selectedCat.length > 0 && customFilterData.category[2].selectedCat.length != customFilterData.category[2].catArr.length ? this.getCategoryMultiSelectValuesForJasper(customFilterData.category[2].selectedCat) : [],
                SubCategory3Ids: customFilterData.category[3].selectedCat.length > 0 && customFilterData.category[3].selectedCat.length != customFilterData.category[3].catArr.length ? this.getCategoryMultiSelectValuesForJasper(customFilterData.category[3].selectedCat) : [],
                SubCategory4Ids: customFilterData.category[4].selectedCat.length > 0 && customFilterData.category[4].selectedCat.length != customFilterData.category[4].catArr.length ? this.getCategoryMultiSelectValuesForJasper(customFilterData.category[4].selectedCat) : [],
                SubCategory5Ids: customFilterData.category[5].selectedCat.length > 0 && customFilterData.category[5].selectedCat.length != customFilterData.category[5].catArr.length ? this.getCategoryMultiSelectValuesForJasper(customFilterData.category[5].selectedCat) : [],
                ReportCategoryIds: customFilterData.reportcategory.length > 0 ? this.getValuesForJasper(customFilterData.reportcategory.filter(x=>x.isActive)) : [],
                OutletIds: outletids,
                currentUserId: this.localization.GetUserInfo('userId'),
                PageBreak: this.pageBreakToggle,
                IsEatecEnabled: this.propertyInfo.IsEatecEnabled,
                IncludeCategoriesNotMapped: customFilterData?.includeCategoryNotMapped ?? false,
                ReportType: customFilterData.itemFilter,
                IncludeZeroMemberCharges: customFilterData.includeZeroMemberChargeToggle,
                UserIds: this.getValuesForJasper(customFilterData.clerks),
                MachineNameIds: showMachineDropDown && !isAllMachineSelected ? this.getCategoryMultiSelectValuesForJasper(customFilterData.machineName) : [],
            };
        }
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return JSON.stringify({
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate),
                OutletIds: this.functionalities.ShowOutletSelectionFieldInSaleByCategoryReport ? this.selectedFilterOptions.map(x => x.id) :
                           tempOutFalse,
                CategoryIds: this.getCategoriesAndSubCategories(customFilterData, 0),
                ReportGroupId: filterReportGroupIds,
                MachineNameIds: showMachineDropDown  ? tempMachineNameIds : [],
                SubCategory1Ids: this.getCategoriesAndSubCategories(customFilterData, 1),
                SubCategory2Ids: this.getCategoriesAndSubCategories(customFilterData, 2),
                SubCategory3Ids: this.getCategoriesAndSubCategories(customFilterData, 3),
                SubCategory4Ids: this.getCategoriesAndSubCategories(customFilterData, 4),
                SubCategory5Ids: this.getCategoriesAndSubCategories(customFilterData, 5),
                ClerkIds: customFilterData.clerks.map(x => x.id),
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            });
        }
         const filter: Partial<OutletAndCategoryFilter> = {
             startDate: toApi(this.startDate),
             endDate: toApi(this.endDate),
             OutletIds: this.functionalities.ShowOutletSelectionFieldInSaleByCategoryReport ? this.selectedFilterOptions.map(x => x.id) :
                        tempOutFalse,
             CategoryIds: this.getCategoriesAndSubCategories(customFilterData, 0),
             ReportGroupId: filterReportGroupIds,
             MachineNameIds: showMachineDropDown  ? tempMachineNameIds : [],
             SubCategory1Ids: this.getCategoriesAndSubCategories(customFilterData, 1),
             SubCategory2Ids: this.getCategoriesAndSubCategories(customFilterData, 2),
             SubCategory3Ids: this.getCategoriesAndSubCategories(customFilterData, 3),
             SubCategory4Ids: this.getCategoriesAndSubCategories(customFilterData, 4),
             SubCategory5Ids: this.getCategoriesAndSubCategories(customFilterData, 5),
             ClerkIds: customFilterData.clerks.map(x => x.id)
         };
            return JSON.stringify(filter);
    }

    private getCategoriesAndSubCategories(customFilterData: any, index: number): number[] {
        let catArray: number[] = [];
        if (_.get(customFilterData, 'category.length')) {
            console.log(customFilterData.category);
           catArray =  customFilterData.category[index].selectedCat.length === 0 ?
           [] : customFilterData.category[index].selectedCat.map(x => x.id);
           if((customFilterData.category[index].selectedCat.length === customFilterData.category[index].catArr.length) && customFilterData.category[index].catArr.length > 0 && !this.retailLocalization.isFromJobScheduler){
            catArray=[];
        }
        }
        return catArray;
    }

}


