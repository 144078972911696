import { AppService } from '@app/app.service';
import { ChangeAction, PartyType } from '@constants/commonenums';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { ContactDTO, SecondaryContactDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import _, { forEach, remove } from 'lodash';
import { Processor } from './processor';
import { Utilities } from '@utilities/utilities';

export class SecondaryContactChangeProcessor implements Processor {

  private static instance: SecondaryContactChangeProcessor;
  public party: any;
  public partyIndex: number;
  private constructor(public partyService: PartyService,public _as: AppService) {
  }

  public static Instance(ps: PartyService,_as: AppService): SecondaryContactChangeProcessor {
    if (!SecondaryContactChangeProcessor.instance) {
      SecondaryContactChangeProcessor.instance = new SecondaryContactChangeProcessor(ps,_as);
    }

    return SecondaryContactChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: object,propertyId: number): void {
    let selectedContactParties = this.partyService.SelectedContactParties$.value;
    let partiesList = [];
    let standbyPartiesList = [];
    if (propertyId == Utilities.RestaurantId()) {
      partiesList = this.partyService.Parties$.value;
      standbyPartiesList = this.partyService.StandbyParties$.value;
    }else{
      partiesList = this._as._cs.propertySettings.value[propertyId].reservations;
      standbyPartiesList = this._as._cs.propertySettings.value[propertyId].StandbyParties;
    }
    partiesList = [...partiesList, ...standbyPartiesList];
    if (objectChange) {
      const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
      if (partiesList && partiesList.length > 0) {
        this.partyIndex = _.findIndex(partiesList, function (partyDetails) { return partyDetails.Id === partyId; });
        const party = partiesList.filter(party => party.Id == partyId);
        if (party && party.length) {
          this.updateSecondaryContactsInParty(objectChange, party);
        }

         //for parties in SelectedContactParties$
        const partyInContact = selectedContactParties.filter(party => party.Id == partyId);
        if (partyInContact && partyInContact.length) {
          this.updateSecondaryContactsInParty(objectChange, partyInContact);
        }
      }
    }
    const partyList = _.cloneDeep(partiesList.filter(x => x.Type !== PartyType.StandBy));
    const standbyParties = _.cloneDeep(partiesList.filter(x => x.Type === PartyType.StandBy));
    if (propertyId == Utilities.RestaurantId()) {
      this.partyService.Parties$.next([...partyList]);
      this.partyService.partiesList = partyList;
      this.partyService.StandbyParties$.next([...standbyParties]);
      this.partyService.SelectedContactParties$.next(selectedContactParties);
      this._as._cs.propertySettings.value[propertyId].reservations = [...partyList];
      this._as._cs.propertySettings.value[propertyId].StandbyParties = [...standbyParties];
    }else{
      this._as._cs.propertySettings.value[propertyId].reservations = [...partyList];
      this._as._cs.propertySettings.value[propertyId].StandbyParties = [...standbyParties];
    }
  }

  updateSecondaryContactsInParty(objectChange,party ){
    if (objectChange.Action == ChangeAction.Removed) {
      const parties = party[0].SecondaryContacts.filter((contact) => contact.Id !== objectChange.ObjectId);
      party[0].SecondaryContacts = parties;
    } else if (objectChange.Action === ChangeAction.Created) {
      if (party && party.length > 0) {
        const secondaryContact: SecondaryContactDTO = new SecondaryContactDTO();
        objectChange.PropertyChanges.forEach(property => {
          if (Object.getOwnPropertyNames(secondaryContact).includes(property.PropertyName.replace('Internal', ''))) {
            secondaryContact[property.PropertyName.replace('Internal', '')] = property.Value;
          }
        });
        party[0].SecondaryContacts.push(secondaryContact);
      }
    }
  }
}
