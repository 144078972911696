<div class="blocking-date ">
  <div class="blocking-date__date-details">
    <div class="blocking-date__date-view">
      <span class="blocking-date__select-text blocking-select-label seat-panel-header">{{'reservationSelectDate'|translate}}</span>
      <span class="blocking-date__select-day seat-header-three">{{waitDate | localizedDate: 'dddd'}}</span>
      <span class="blocking-date__select-date seat-header-two">{{waitDate | localizedDate: 'MMM DD'}} {{getDateVal(waitDate) | lowercase }}</span>
    </div>
    <div class="blocking-date__calendar-view">
      <div>
        <mat-calendar [selected]="partyService.reservationFormGroup.value.selectedDate"
          (selectedChange)="setSelectedDate($event)" [startAt]="partyService.reservationFormGroup.value.selectedDate"
          [minDate]="" [maxDate]="maxdate"></mat-calendar>

      </div>
      <mat-error class="blocking-date__error seat-error-messages"> {{'userSelectDateError' | translate}}</mat-error>
    </div>
  </div>
</div>