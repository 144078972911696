import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';
import { FormChipService } from './form-chip.service';

@Component({
  selector: 'app-form-chip',
  templateUrl: './form-chip.component.html',
  styleUrls: ['./form-chip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormChipComponent implements OnInit, Field {
  config: FieldConfig;
  group: UntypedFormGroup;
  multiple: boolean = false;
  constructor(private formChipService: FormChipService) { }

  ngOnInit() {
    this.multiple = this.config.multipleChip != undefined ? this.config.multipleChip : true;
  }

  selectedChip(index) {
    if (!this.config.options[index].isDisabled) {
      if (this.multiple) {
        if (this.config.options[index].setSelected) {
          this.config.options[index].setSelected = !this.config.options[index].setSelected;
        } else {
          this.config.options[index].setSelected = true;
        }
      } else {
        this.config.options.forEach(val => {
          val.setSelected = false;
        });
        this.config.options[index].setSelected = true;
      }
      this.formChipService.formChipChange$.next({ data: this.config.options, name: this.config.name });
    }
  }

  additionalNotesClick(event: Event){
    event.stopPropagation();
    this.formChipService.formChipChange$.next({ data: this.config.options, name: this.config.name, isOthersAdd: true });
  }
}
