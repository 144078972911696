import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';

@Component({
  selector: 'app-auto-sync',
  templateUrl: './auto-sync.component.html',
  styleUrls: ['./auto-sync.component.scss']
})
export class AutoSyncComponent implements OnInit {
  public FormControlName;
  config: FieldConfig;
  group: UntypedFormGroup;
  @Output() clickSync: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.FormControlName = this.config.name;
  }


}

