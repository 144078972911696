import { Injectable } from '@angular/core';
import { GiftCardConfiguration } from '../../shared/service/payment/payment-model';
import { BaseResponse } from '../../retail.modals';
import { Host } from '../../shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import {  POS } from '../../shared/shared.modal';

@Injectable()
export class GiftCardsService {

  constructor(private http: HttpServiceCall) { }

  public async getGiftCardConfiguration(): Promise<GiftCardConfiguration> {
    let result: GiftCardConfiguration;
    try {
      result = await this.InvokeGiftCardConfigurationCalls<GiftCardConfiguration>(Host.payment, "GetGCConfiguration", HttpMethod.Get);
      result.giftCardDefaultValues = result.giftCardDefaultValues || [];
    }
    catch (ex) {
      console.log(ex);
    }
    return result;
  }

  public async createOrUpdateGiftCardConfiguration(body: GiftCardConfiguration): Promise<GiftCardConfiguration> {
    try {
      return await this.InvokeGiftCardConfigurationCalls<GiftCardConfiguration>(Host.payment, "UpdateGCConfiguration", HttpMethod.Put, body);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async GetRetailItemById(retailItemId: number): Promise<POS.RetailItem> {
    try {
      return await this.InvokeGiftCardConfigurationCalls<POS.RetailItem>(Host.retailPOS, "GetRetailItemById", HttpMethod.Get, undefined, { itemId: retailItemId });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  private async InvokeGiftCardConfigurationCalls<T>(host: Host, callDesc: string, callType: HttpMethod, body?: GiftCardConfiguration, uRIParams?: any): Promise<T> {

    try {
      let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
        callDesc: callDesc,
        host: host,
        method: callType,
        body: body,
        uriParams: uRIParams
      });

      return response.result;
    }
    catch (ex) {
      return null;
    }
  }
}
