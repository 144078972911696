<div *ngIf="_merchantSetting &&  guestData"  class="guest-info">
    <hr class="mt-2 mb-2">
    <div class="guest-card__name" *ngIf="_merchantSetting.ShowStayConfirmationNumber?.SettingValue == settingType.ENABLE" [matTooltip]="'confirmationcode' | translate"> <i class="icon-Audit" ></i><span class="pl-1">{{(guestData.StayConfirmationNumber || 'NA') | translate}}</span></div>
    <div class="guest-card__name" *ngIf="_merchantSetting.ShowRoomNumber?.SettingValue == settingType.ENABLE" [matTooltip]="'ShowRoomNumber' | translate"><i class="icon-reserve_v1crs" ></i><span class="pl-1 ">{{(guestData.RoomNumber || 'NA') | translate}}</span></div>
    <div class="guest-card__name" *ngIf="_merchantSetting.ShowStayDates?.SettingValue == settingType.ENABLE && (guestData?.StayEnddate || guestData?.Staystartdate)"  [matTooltip]="'arrivalDepartureDate' | translate"><i class="icon-appointment-calender" ></i><span *ngIf="(guestData?.StayEnddate || guestData?.Staystartdate)" class="pl-1 ">{{ guestData.Staystartdate ? (guestData.Staystartdate | formatSettingsDate:
      this._settings.General.DateFormat) : ('NA' | translate)  }} - {{guestData.StayEnddate ? (guestData.StayEnddate | formatSettingsDate:
        this._settings.General.DateFormat) :('NA' | translate) }}</span>
    <span class="pl-1" *ngIf="(!guestData?.StayEnddate && !guestData?.Staystartdate)">{{'NA' | translate}}</span>
    </div>
    <div class="guest-card__name" *ngIf="_merchantSetting.ShowPackageName?.SettingValue == settingType.ENABLE" [matTooltip]="'ShowPackageName' | translate"><i class="icon-offer-redemption" ></i><span class="pl-1 ">{{(guestData.PackageName || 'NA') | translate}}</span></div>
</div>
