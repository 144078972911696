import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { AppService } from '@app/app.service';
import { buttonTypes, Menu, OperationResultState, PartyState, ReservationType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { SettingsDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'client-section',
  templateUrl: './client-section.component.html',
  styleUrls: ['./client-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ClientSectionComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  public SeatStatus: Array<any> = [];
  @Input() seatData: any;
  @Output() seatStatusChange = new EventEmitter();
  @Output() close = new EventEmitter();
  statusBtn: any;
  buttonValue: ButtonValue;
  buttonType: any;
  selectedStatusId: any;
  _settings: SettingsDTO = {} as SettingsDTO;
  subscriptions: Subscription = new Subscription();
  constructor(public appService: AppService, private partyService: PartyService, public cs: CacheService, private renderer: Renderer2) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
  }
  ngOnInit() {
    // this.buttonValue = {
    //   // label:'ererr',
    //   type: 'status-indicator'
    // };
    this.buttonType = buttonTypes;
    // this.SeatStatus = [
    //   { id: 1, name: 'Fully Seated', clicked: true },
    //   { id: 2, name: 'Has Drinks', clicked: false },
    //   { id: 3, name: 'Entreed', clicked: true },
    //   { id: 4, name: 'Paid', clicked: true },
    //   { id: 5, name: 'Bussing', clicked: false },
    //   { id: 5, name: 'Strugling', clicked: true }
    // ];
    this.setPartyStatus();

    if (this.seatData?.StatusId) {
      this.selectedStatusId = this.seatData.StatusId;
      this.statusColorChanged()
    }
  }

  ngAfterViewInit() {

    this.subscriptions.add(this.partyService.selectedParty$.subscribe((party) => {
      this.seatData = party;
      this.setPartyStatus();
      this.selectedStatusId = this.seatData?.StatusId;
      this.buttonType = buttonTypes;
      setTimeout(() => {
        this.statusColorChanged();
      }, 100);
    }));

    this.statusColorChanged();

  }

  //const statusColor = this._settings.Statuses.filter(status => status.Id == data.StatusId)[0].Color;   


  statusColorChanged() {
    this.statusBtn = document.querySelectorAll('[id^="btn"]');
    this.statusBtn?.forEach(btn => {
      let id = Number(btn.id.split('-')[1]);
      const statusColor = this._settings.Statuses.filter(status => status.Id == id)[0]?.Color;
      const color = statusColor ? 'rgba(' + statusColor.R + ',' + statusColor.G + ',' + statusColor.B + ',' + statusColor.A + ')' : '#000000';
      if (id == this.selectedStatusId) {
        this.renderer.setStyle(btn.getElementsByTagName("button")[0], 'background', color);
        this.renderer.setStyle(btn.getElementsByTagName("button")[0], 'color', 'white');
        this.renderer.setStyle(btn.getElementsByTagName("button")[0], 'border', 'none');
      }
      else {
        this.renderer.setStyle(btn.getElementsByTagName("button")[0], 'border', `1px solid ${color}`);
        this.renderer.setStyle(btn.getElementsByTagName("button")[0], 'background', 'white');
        this.renderer.setStyle(btn.getElementsByTagName("button")[0], 'color', color);
      }
    });
  }

  ngOnChanges(changes) {
    // this.appService.partyStatusList.forEach(status => {
    //   if (status.Name === this.seatStatusDetails) {
    //     status.color = '#1DA664';
    //     // `rgba(${status.Color.R}, ${status.Color.G}, ${status.Color.B}, ${status.Color.A})`;
    //   } else {
    //     status.color = '';
    //   }
    // });
    if(changes) {
      this.seatData = changes['seatData'].currentValue;   
    this.setPartyStatus();
    this.selectedStatusId = this.seatData?.StatusId;
    this.buttonType = buttonTypes;
    setTimeout(() => {
      this.statusColorChanged();
    }, 100);
   
  }
  }

  setPartyStatus() {
    if (this.seatData?.State === PartyState.Seated) {
      this.SeatStatus = _.filter(this._settings.Statuses, 'AppliesToSeatingParties');
    }
    else {
      let defaultOption = {
        Id: -1,
        Name: 'None',
        AppliesToReservations: false,
        AppliesToWalkIns: false,
        AppliesToSeatingParties: false,
        Color: null,
        IsDefault: false
      }
      if (this.seatData?.Type === ReservationType.Reservation) {
        this.SeatStatus = _.filter(this._settings.Statuses, 'AppliesToReservations');
        this.SeatStatus = [defaultOption, ...this.SeatStatus];
      }
      else if (this.seatData?.Type === ReservationType.Waitlist) {
        this.SeatStatus = _.filter(this._settings.Statuses, 'AppliesToWalkIns');
        this.SeatStatus = [defaultOption, ...this.SeatStatus];
      }
    }
  }

  seatStatusChanged(eve) {
    let statusId = eve.Id == -1 ? null : eve.Id;
    this.subscriptions.add(this.partyService.postSeatedPartyStatus(this.appService.restaurantId, this.seatData?.Id, statusId).subscribe(val => {
      if (val.State == OperationResultState.Success) {
        this.selectedStatusId = eve.Id;
        this.statusColorChanged();
        this.partyService.statusChange$.next({ party: this.seatData, statusId: eve.Id });
        if (this.appService.selectedMenuId == Menu.Tables) {
          this.close.emit();
        }
      }
    }));
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}





