<mat-form-field [floatLabel]="floatLabel" [ngClass]="className" [formGroup]="form" *ngIf="form" (click)="onclick()" class="mat-form-field {{floatLabel}}">
    <mat-select [attr.automationId]="automation" [placeholder]="placeHolder" [formControlName]="formControlName" [disabled]="isDisabled"
                [multiple]="isMultiple" [compareWith]="compareSelect"[matTooltipClass]="'AgysMatCustTooltip'" [matTooltip]="selectedData"
                [required]='showRequired' [attr.LiteralID]="placeHolderId" (keydown.enter)="changedropdown($event, formControlName)" (openedChange)="openedChange($event)">
                <mat-select-trigger *ngIf="isMultiple">
                    <span class="ag_display--flex" *ngIf="form.controls[formControlName].value?.length != dataSourse?.length">
                        <span class="first-option">{{form.controls[formControlName].value ? form.controls[formControlName].value[0]?.viewValue : ''}}</span>
                        <span *ngIf="form.controls[formControlName].value?.length > 1" class="additional-selection-Count ag_pl--1">
                            (+{{form.controls[formControlName].value.length - 1}} {{captions.lbl_others}})
                        </span>
                    </span>
                    <span *ngIf="form.controls[formControlName].value?.length == dataSourse?.length">
                        {{defaultData.viewValue}}
                      </span>
                </mat-select-trigger>
                <div class="dropdown-search">
                    <input *ngIf="dropdownWithSearch" placeholder="{{captions.lbl_searchBy}} {{placeHolder}}" [(ngModel)]="inputSearch" [ngModelOptions]="{standalone: true}" (input)='filterOptions($event)' (keydown)="$event.stopPropagation()">
                    <i *ngIf="dropdownWithSearch" [ngClass]="inputSearch ? 'icon-close ag_cursor--pointer' : 'icon-search'" (click)="dropDownSearchClear(inputSearch ? 'icon-close' : 'icon-search')"></i>
                </div>       
        <mat-checkbox *ngIf="isAll" class="mat-option all-multiselect w-100" (click)="$event.stopPropagation();" [disableRipple]="true"
                      (change)="allcheckbox($event)"
                      [checked]="Selectedfilteredlistcount  == filteredOptions?.length">
            {{defaultData.viewValue}}
        </mat-checkbox>
        <mat-option *ngIf='isFirstEmpty'></mat-option>
        <mat-option *ngIf='isSelect'></mat-option>
        <mat-option *ngFor='let option of filteredOptions' [matTooltip]="option.viewValue" [value]="option" [disabled]="option.disabled" (click)="filterDropDownSelected($event, option, formControlName,matOption.selected)" #matOption>
            {{option.viewValue}}
        </mat-option>

    </mat-select>
    <mat-error *ngIf="form.controls[formControlName].hasError('required')">
        {{errorMessage}}
    </mat-error>
    <mat-error *ngIf="form.controls[formControlName].hasError('invalid')" [attr.LiteralID]="customErrorMessageId">
        {{customErrorMessage}}
    </mat-error>
</mat-form-field>