import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, FormGroup, Validators, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { ReservationType, SecondaryGuestSearchType } from '@app/shared/constants/commonenums';
import { FormChipService } from '@app/shared/dynamicform/components/form-chip/form-chip.service';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { DynamicFormService } from '@app/shared/dynamicform/service/dynamic-form.service';
import { ContactNotes } from '@app/shared/models/global.interface';
import { ContactNoteDTO, PageMethod } from '@app/shared/models/InputContact';
import { Category, CountryDTO, RequiredPartyFields, SecondaryContactDTO, SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { PartyService } from '@app/shared/services/party.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { isArray, range } from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { CustomGuestFields } from '../CustomGuestField';
import moment from 'moment';
import { fieldType } from '@constants/globalConstants';

@Component({
  selector: 'app-view-secondary-guest-details',
  templateUrl: './view-secondary-guest-details.component.html',
  styleUrls: ['./view-secondary-guest-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewSecondaryGuestDetailsComponent extends CustomGuestFields implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() secondaryContactDetails: SecondaryContactDTO;
  @Input() countryCode: number;
  @Input() guestCount;
  @Input() isEdit: boolean;
  @Output() validateSecondaryGuest: EventEmitter<boolean> = new EventEmitter();
  @Output() deleteSecondaryGuest: EventEmitter<any> = new EventEmitter();
  secondaryGuestConfig: FieldConfig[] = [];
  countryCodes: { id: string; value: string; countryCode: string; }[];
  subscriptions: Subscription = new Subscription();
  _settings: SettingsDTO = {} as SettingsDTO;
  guestNotes: ContactNotes[] = [];
  categories: Category[] = [];
  contactGuestFields: any[] = [];
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @ViewChild('memberForm') memberForm: DynamicFormComponent;
  @ViewChild('customGuestField') customGuestFieldForm: DynamicFormComponent;
  selectedSecondaryGuestPreferenceTags: any[] = [];
  isMembershipAvailable: boolean = false;
  secondaryMemberStatus: string;
  hasRoomNUmberSearch = false;
  hasCGPSearch = false;
  mobileRequired = true;
  secondaryGuestSearchType = SecondaryGuestSearchType;
  showAliasName: boolean;
  constructor(private cs: CacheService, private formChipService: FormChipService,
    public partyService: PartyService, private dfs: DynamicFormService, private apiService: ApiService, private as: AppService) {
    super(partyService, as.PropertyType)
    this.subscriptions.add(cs.settings.subscribe(settings => {
      this._settings = settings;
      if (settings.customGuestFieldsMappingValidations) {
        this.customGuestFieldsMappingValidations = settings.customGuestFieldsMappingValidations;
      }
      this.categories = this._settings.Categories.filter(category => category.Text != "Reasons");
      this.isMembershipAvailable = this._settings.General.UseMembershipNumber;
    }))
  }

  ngOnInit(): void {
    this.isEditGuest = !this.isEdit;
    this.hasRoomNUmberSearch = this._settings.HasRoomNumberSearch;
    this.hasCGPSearch = this._settings.HasCGPSearch;
    this.showAliasName =   JSON.parse(sessionStorage.getItem('ShowAliasName'));
    this.getGuestFieldVisibilityMapping(this.partyService.selectedSpecialMealId);

    //let isNewGuest = this.secondaryContactDetails.isNew;
    // Enabling secondary guest fields
    let isNewGuest = true;
    this.setConfigByGuestVisibilityField(isNewGuest);
    this.setContactNotes(isNewGuest);
    this.setCustomFields(!this.SecondaryGuestFieldVisibility.CustomGuestFields);
  }

  setCustomFieldBasedonActivity() {
    if (this.guestConfig) {
      this.specialMealSelectAfterGuestEdit = true;
      this.guestConfig = this.guestConfig.filter(field => !field.name.includes('customField'))
      this.setCustomFields(!this.SecondaryGuestFieldVisibility.CustomGuestFields)
      if (this.guestConfig.filter(field => field.name.includes('customField'))?.length > 0) {
        setTimeout(() => {
          this.mapCustomFieldsData();
        }, 1000)
      }
    }
  }

  mapCustomFieldsData() {
    this.contactGuestFields = this.secondaryContactDetails?.ContactCustomFields
    this.guestConfig.forEach((data, index) => {
      if (data.name.includes('customField')) {
        const customFieldValue = this.customFields.filter((field) => field.Id === data.id);
        const contactCustomField = this.contactGuestFields && this.contactGuestFields.
          filter((contact) => contact.CustomFieldId === customFieldValue[0].Id);
        if (customFieldValue[0].FieldType === fieldType[0] && this.customGuestFieldForm.form.get(data.name)) {
          this.customGuestFieldForm.form.get(data.name).setValue(
            (contactCustomField && contactCustomField.length > 0) ? contactCustomField[0].CustomFieldValue : '');
        } else {
          if (contactCustomField && contactCustomField.length > 0 && this.customGuestFieldForm.form.get(data.name)) {
            const customField = this.dropDownFieldOptions.filter(dropDownField => dropDownField.dropDownCustomFieldId == customFieldValue[0].Id);
            let customFieldOptionArr = [];
            if (customField.length > 0) {
              customFieldOptionArr = customField[0].options.filter(option => option.value == contactCustomField[0].CustomFieldValue);
              this.customGuestFieldForm.form.get(data.name).setValue(customFieldOptionArr.length > 0 ? customFieldOptionArr[0].id : '');
            } else {
              this.customGuestFieldForm.form.get(data.name).setValue('');
            }
          }
          else
            if (this.customGuestFieldForm.form.get(data.name)) {
              this.customGuestFieldForm.form.get(data.name).setValue('');
            }
        }
      }
    });
  }
  setConfigByGuestVisibilityField(isNewGuest) {
    let requiredField = this._settings.General.RequiredPartyFields;
    const countries = this._settings.Countries;
    this.countryCodes = Utilities.getCountryCodes(this._settings);
    this.mobileRequired = RequiredPartyFields.PhoneNumber === requiredField;
    let guestCountry = countries.filter((country: CountryDTO) => country.Id === this.countryCode);
    let guestCountryName;
    if (guestCountry && guestCountry.length > 0) {
      guestCountryName = guestCountry[0].Name;
    }
    let config = [{
      type: 'input',
      name: 'FirstName',
      inputType: 'text',
      label: 'guestBookDetailsFN',
      class: 'secondary-guest-details__firstName',
      disabled: !isNewGuest,
      value: this.secondaryContactDetails.FirstName,
      validation: this.SecondaryGuestFieldVisibility.GuestNames ? [Validators.required] : [],
      isHidden: !this.SecondaryGuestFieldVisibility.GuestNames
    },
    {
      type: 'input',
      name: 'LastName',
      inputType: 'text',
      label: 'guestBookDetailsLN',
      class: 'secondary-guest-details__lastName',
      disabled: !isNewGuest,
      value: this.secondaryContactDetails.LastName,
      validation: this.SecondaryGuestFieldVisibility.GuestNames ? [Validators.required] : [],
      isHidden: !this.SecondaryGuestFieldVisibility.GuestNames
    },
    {
      type: 'input',
      name: 'AliasName',
      inputType: 'text',
      label: 'guestBookDetailsAliasName',
      class: 'secondary-guest-details__email',
      disabled: !isNewGuest,
      value: this.secondaryContactDetails.AliasName,
      isHidden: this.showAliasName ? false : true,
    },
    {
      type: 'select',
      name: 'CountryId',
      inputType: 'number',
      label: 'primaryCodeLbl',
      options: this.countryCodes,
      value: this.secondaryContactDetails.CountryId ? this.getCountryById(this.secondaryContactDetails.CountryId) : guestCountryName,
      class: 'secondary-guest-details__countryCode',
      cellClick: (event) => this.countryCodeChange(event, 'PhoneNumber'),
      disabled: false,
      isHidden: !this.SecondaryGuestFieldVisibility.PrimaryContacts,
      validation: [],
    },
    {
      type: 'input',
      name: 'PhoneNumber',
      inputType: 'text',
      label: 'guestBookDetailsMobile',
      directive: 'numberOnly',
      isPhoneNumber: true,
      class: 'secondary-guest-details__mobileNumber',
      disabled: !isNewGuest,
      showErrorText: true,
      blurClick: (event) => this.mobileNumberValidation('PhoneNumber', 'CountryId'),
      isHidden: !this.SecondaryGuestFieldVisibility.PrimaryContacts,
      value: this.secondaryContactDetails.PhoneNumber,
      validation: [],
    },
    {
      type: 'select',
      name: 'CountryId2',
      inputType: 'number',
      label: 'secondaryCodeLbl',
      class: 'secondary-guest-details__secondary-mobile-code-edit',
      options: this.countryCodes,
      cellClick: (event) => this.countryCodeChange(event, 'PhoneNumber2'),
      value: this.secondaryContactDetails?.CountryId2 ? this.getCountryById(this.secondaryContactDetails.CountryId2) : guestCountryName,
      isHidden: !this.SecondaryGuestFieldVisibility.SecondaryContacts,
      validation: [],
    },
    {
      type: 'input',
      name: 'PhoneNumber2',
      inputType: 'text',
      label: 'secondaryPhoneLabel',
      directive: 'numberOnly',
      class: 'secondary-guest-details__phone-edit', // Do not modify the className, there is a dependency for editGuest details.
      showErrorText: true,
      isPhoneNumber: true,
      isHidden: !this.SecondaryGuestFieldVisibility.SecondaryContacts,
      value: this.secondaryContactDetails.PhoneNumber2,
      blurClick: (event) => this.mobileNumberValidation('PhoneNumber2', 'CountryId2'),
      validation: [],
    },
    {
      type: 'input',
      name: 'EmailAddress',
      inputType: 'text',
      label: 'guestBookDetailsEmail',
      validation: [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')],
      showErrorText: true,
      class: 'secondary-guest-details__email',
      disabled: false,
      value: this.secondaryContactDetails.EmailAddress,
      isHidden: !this.SecondaryGuestFieldVisibility.PrimaryContacts,
    },
    {
      type: 'input',
      name: 'EmailAddress2',
      inputType: 'text',
      label: 'secondaryEmailLabel',
      class: 'guest-view__secondary-email-edit', // Do not modify the className, there is a dependency for editGuest details.
      validation: [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')],
      showErrorText: true,
      value: this.secondaryContactDetails.EmailAddress2,
      isHidden: !this.SecondaryGuestFieldVisibility.SecondaryContacts
    },
    {
      type: 'input',
      name: 'TrackMembershipNumber',
      inputType: 'text',
      label: 'MembershipId',
      isTranslate: true,
      value: this.secondaryContactDetails.TrackMembershipNumber,
      isHidden: !this.SecondaryGuestFieldVisibility.MembershipDetails,
      validation: [],
    },
    {
      type: 'input',
      name: 'MemberType',
      inputType: 'text',
      label: 'MemberType',
      isTranslate: false,
      isHidden: !this.SecondaryGuestFieldVisibility.MembershipDetails,
      value: this.secondaryContactDetails?.MemberType,
      validation: [],
    },
    {
      type: 'monthPicker',
      name: 'Birthday',
      inputType: 'text',
      label: 'guestBookDetailsDOB',
      class: 'secondary-guest-details__guestBookDetailsDOB',
      // class: 'guest-view__dob-edit', // Do not modify the className, there is a dependency for editGuest details.
      minDate: new Date(new Date().getFullYear(), 0, 1),
      maxDate: new Date(new Date().getFullYear(), 11, 31),
      value: this.secondaryContactDetails.Anniversary ? this.setDateToCurrentYear(new Date(this.secondaryContactDetails.Birthday)) : null,
      isHidden: !this.SecondaryGuestFieldVisibility.SpecialOccasions,
      validation: [],
    },
    {
      type: 'monthPicker',
      name: 'Anniversary',
      inputType: 'text',
      label: 'guestBookDetailsAnniversary',
      class: 'secondary-guest-details__guestBookDetailsAnniversary',
      // class: 'guest-view__anniversary-edit', // Do not modify the className, there is a dependency for editGuest details.
      minDate: new Date(new Date().getFullYear(), 0, 1),
      maxDate: new Date(new Date().getFullYear(), 11, 31),
      value: this.secondaryContactDetails.Anniversary ? this.setDateToCurrentYear(new Date(this.secondaryContactDetails.Anniversary)) : null,
      isHidden: !this.SecondaryGuestFieldVisibility.SpecialOccasions,
      validation: [],
    }
    ]
    let contactPreference = {
      isValid: true,
      pageMethod: PageMethod.Manual
    }
    let result = this.requireFliedValidation(this._settings.General.RequiredSecondaryGuestFields, config, contactPreference);
    this.secondaryGuestConfig = this.removeValidationForHiddenField(result.fieldConfig);
  }
  setContactNotes(isNewGuest) {
    this.guestNotes = Utilities.getRestaurantPredefinedContactNotes(this.categories);
    this.guestNotes.forEach((guestNote, index) => {
      const tag = {
        type: 'chip',
        name: `secondaryGuestPreferredTags${index}`,
        inputType: 'text',
        label: guestNote.CategoryName,
        class: 'secondary-guest-details__preferred-tags-edit',
        options: this.getOptions(guestNote.contactNotes, guestNote.Color, isNewGuest),
        multipleChip: true,
        isHidden: !this.SecondaryGuestFieldVisibility.ContactNotes
      }
      this.secondaryGuestConfig.push(tag);
    });
  }
  countryCodeChange(event, formName) {
    this.dfs.selectedCountryCode = this.getPhoneCodeByCountryName(event?.value);
    this.dfs.selectedCountryId = this.getPhoneIdByCountryName(event?.value)
    const mobile = this.dynamicForm.form.get(formName).value;
    let mobileNumber: any;
    if (mobile)
      mobileNumber = this.dfs.onInputChange(mobile, false);
    if (mobileNumber && this.dynamicForm.form.get(formName).value.length > 0) {
      this.dynamicForm.form.get(formName).setValue(mobileNumber, { emitEvent: false });
    }
  }
  getPhoneCodeByCountryName(countryName) {
    let phoneCode;
    phoneCode = this._settings.Countries.find(country => country.Name == countryName)?.CountryPhoneCode;
    if (!phoneCode) {
      phoneCode = Utilities.getDefaultCountry(this.countryCodes, this._settings)?.countryCode;
    }
    return phoneCode;
  }

  getPhoneIdByCountryName(countryName) {
    let phoneId;
    phoneId = this._settings.Countries.find(country => country.Name == countryName)?.Id;
    if (!phoneId) {
      phoneId = Utilities.getDefaultCountry(this.countryCodes, this._settings)?.id;
    }
    return phoneId;
  }

  mobileNumberValidation(formName?, mobileCode?) {
    let selectedPhoneCode = this.getPhoneCodeByCountryName(this.dynamicForm.form.get(mobileCode).value);
    this.dfs.selectedCountryCode = selectedPhoneCode;
    this.dfs.selectedCountryId = this.getPhoneIdByCountryName(this.dynamicForm.form.get(mobileCode).value);
    const mobile = this.dynamicForm.form.get(formName).value;
    let mobileNumber: any;

    if (mobile)
      mobileNumber = this.dfs.onInputChange(mobile, false);

    if (mobileNumber && this.dynamicForm.form.get(formName).value.length > 0) {
      this.dynamicForm.form.get(formName).setValue(mobileNumber);
    }
    if (this.dfs.requiredPhoneNumberLength$) {
      const count = this.dfs.requiredPhoneNumberLength$;
      const fieldRequired = this.dynamicForm.form.get(formName)?.errors?.required
      fieldRequired ? this.dynamicForm.form.get(formName).setValidators([Validators.required, Validators.minLength(count), Validators.maxLength(count)]) : this.dynamicForm.form.get(formName).setValidators([Validators.minLength(count), Validators.maxLength(count)]);
      this.dynamicForm.form.get(formName).updateValueAndValidity({ emitEvent: false });
    }
  }

  ngOnChanges() {
    setTimeout(() => {
      this.editSecondaryGuestFields();
    }, 100);
  }

  ngAfterViewInit() {
    if (this.secondaryContactDetails.Notes && this.secondaryContactDetails.Notes.length > 0) {
      this.mapOtherNotes();
    } else {
      this.secondaryContactDetails.Notes = [];
    }
    this.subscriptions.add(this.formChipService.formChipChange$.subscribe((selectedChips) => {
      if (this.secondaryGuestConfig.filter((fieldConfig) => fieldConfig.name == selectedChips.name).length > 0) {
        this.selectedSecondaryGuestPreferenceTags = selectedChips.data
        const preferredTagsIndex = this.secondaryGuestConfig.findIndex(fieldConfig => fieldConfig.name == selectedChips.name);
        const otherTags = selectedChips.data.filter(chip => chip.Name == 'Others');
        if (otherTags && otherTags.length > 0) {
          let otherNotesName = `otherNotes${otherTags[0].Id}`;
          let isOtherNoteAvailable = this.secondaryGuestConfig.filter((config) => config.name == otherNotesName).length;
          const otherNotesControl = this.dynamicForm.config.filter((config) => config.name == otherNotesName);
          if (!isOtherNoteAvailable && otherTags.length > 0 && otherTags[0].setSelected) {
            const otherNotes: FieldConfig = {
              name: otherNotesName,
              type: 'textarea',
              inputType: 'text',
              label: 'notes',
              showHint: true,
              class: 'secondary-guest-details__other-tags',
              charLength: 1000,
              isHidden: false
            }
            this.secondaryGuestConfig.splice(preferredTagsIndex + 1, 0, otherNotes);
            this.dynamicForm.form.addControl(otherNotesName, new UntypedFormControl());
          } else if (isOtherNoteAvailable) {
            if (otherTags[0].setSelected) {
              otherNotesControl[0].isHidden = false;
            } else {
              this.secondaryGuestConfig[preferredTagsIndex + 1].isHidden = true;
            }
          }
        }
        this.secondaryGuestConfig = [...this.secondaryGuestConfig];
        const contactNotesPreNotes: ContactNoteDTO = {} as ContactNoteDTO;
        this.selectedSecondaryGuestPreferenceTags.forEach((preferredTags) => {
          if (preferredTags.Name !== 'Others') {
            const preferredData = this.secondaryContactDetails.Notes.filter((note) => note.RelatedId === preferredTags.Id)[0];
            if (preferredTags.setSelected && !preferredData) {
              contactNotesPreNotes.RelatedId = preferredTags.Id;
              contactNotesPreNotes.Text = preferredTags.Name;
              contactNotesPreNotes.Id = preferredTags.noteId ? preferredTags.noteId : null;
              contactNotesPreNotes.Icon = preferredTags.Icon;
              this.secondaryContactDetails.Notes.push(contactNotesPreNotes);
            } else if (!preferredTags.setSelected) {
              const index = this.secondaryContactDetails.Notes.findIndex((note) => note.RelatedId === preferredTags.Id);
              if (index > -1) {
                this.secondaryContactDetails.Notes.splice(index, 1);
              }
            }
          }
        });
      }
    }));
    this.mobileNumberValidation('PhoneNumber', 'CountryId');
    this.mobileNumberValidation('PhoneNumber2', 'CountryId2');
    if (this.secondaryContactDetails.isNew) {
      this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe((formValues) => {
        this.checkReqPartyFieldsAnyOne(formValues);
        this.mapSecondaryContactDetails(formValues);
        this.setNameValidators(formValues);
      }));
    }
    this.customGuestFieldForm.form.valueChanges.subscribe((customField) => {
      console.log(customField);
      this.secondaryContactDetails.ContactCustomFields = this.formCustomFieldRequestPayload(customField, this.secondaryContactDetails.ContactId, [], []);
      this.validateSecondaryGuest.emit(this.secondaryContactDetails.isFormValid = this.dynamicForm.form.valid);
    })
    this.secondaryContactDetails.isFormValid = this.dynamicForm.form.valid;

    if (this.dynamicForm && this.dynamicForm.form) {
      Object.keys(this.dynamicForm.form.controls).forEach((key: string) => {
        if (key !== 'CountryId') {
          const abstractControl = this.dynamicForm.form.get(key);
        }
      });
    }
    this.mapCustomFieldsData();
    this.subscriptions.add(this.partyService.activityChanges$.subscribe(activity => {
      if (this.isEditGuest || this.isAddGuest) {
        this.secondaryGuestConfig = this.removeValidationForHiddenField(this.secondaryGuestConfig);
        this.setCustomFieldBasedonActivity();
        this.getGuestFieldVisibilityMapping();
      }
      this.partyService.activityChanges$?.subscribe();
    }))
    this.partyService.reservationFormGroup.controls.selectedSpecialMeal.valueChanges.subscribe((data) => {
      this.secondaryGuestConfig = this.removeValidationForHiddenField(this.secondaryGuestConfig);
      this.setCustomFieldBasedonActivity();
      this.getGuestFieldVisibilityMapping();
    })
  }
  mapSecondaryContactDetails(formValues) {
    const { EmailAddress, FirstName, LastName, AliasName , PhoneNumber, CountryId2, PhoneNumber2, TrackMembershipNumber, MemberType, Birthday, Anniversary, EmailAddress2 } = formValues;
    this.secondaryContactDetails.EmailAddress = EmailAddress;
    this.secondaryContactDetails.FirstName = FirstName;
    this.secondaryContactDetails.LastName = LastName;
    this.secondaryContactDetails.AliasName = AliasName;
    this.secondaryContactDetails.PhoneNumber = PhoneNumber;
    this.secondaryContactDetails.Birthday = Birthday ? moment(Birthday).format('MM/DD/YYYY') : null;
    this.secondaryContactDetails.Anniversary = Anniversary ? moment(Anniversary).format('MM/DD/YYYY') : null;
    this.secondaryContactDetails.PhoneNumber2 = PhoneNumber2;
    this.secondaryContactDetails.EmailAddress2 = EmailAddress2;
    this.secondaryContactDetails.TrackMembershipNumber = TrackMembershipNumber;
    this.secondaryContactDetails.MemberType = MemberType;
    let rawvalue = this.dynamicForm.form.getRawValue();
    let id = this._settings.Countries.find(country => country.Name == rawvalue.CountryId);
    if (id) {
      this.secondaryContactDetails.CountryId = id.Id;
    }
    let secondaryMobileCode = this._settings.Countries.find(country => country.Name == rawvalue.CountryId2);
    if (secondaryMobileCode) {
      this.secondaryContactDetails.CountryId2 = secondaryMobileCode.Id;
    }
    this.bindOtherNotes(formValues);
    if (this.dynamicForm.form.dirty || this.dynamicForm.valid) {
      this.partyService.reservationFormGroup.markAsDirty();
    }
    this.validateSecondaryGuest.emit(this.secondaryContactDetails.isFormValid = this.dynamicForm.form.valid);
  }
  bindOtherNotes(data) {
    for (let preferenceProperty in data) {
      if (preferenceProperty.includes('otherNotes')) {
        const otherNote: ContactNoteDTO = {} as ContactNoteDTO;
        otherNote.Text = data[preferenceProperty];
        if (otherNote.Text) {
          const preferencePropertyId = preferenceProperty.match(/(\d+)/);
          if (preferencePropertyId) {
            otherNote.RelatedId = Number(preferencePropertyId[0]);
            const preferredOtherTags = this.selectedSecondaryGuestPreferenceTags.filter((note) => note.Id === otherNote.RelatedId);
            if (preferredOtherTags && preferredOtherTags.length > 0) {
              otherNote.Id = preferredOtherTags[0].noteId ? preferredOtherTags[0].noteId : null;
            }
          }
          this.secondaryContactDetails.Notes = this.secondaryContactDetails.Notes.filter(note => note.RelatedId != otherNote.RelatedId);
          this.secondaryContactDetails.Notes.push({ ...otherNote });
        } else {
          this.secondaryContactDetails.Notes = this.secondaryContactDetails.Notes.filter(note => note.RelatedId != otherNote.RelatedId);
        }

      }
    }
  }

  memberSelect(data) {
    var { FName: FirstName, LName: LastName,AliasName : AliasName, PhoneCode: CountryId, PhoneNumber: PhoneNumber, Email: EmailAddress } = data;
    this.setFormValues(FirstName, LastName,AliasName, this.getCountryCodeId(CountryId), PhoneNumber, EmailAddress);
    this.memberForm.form.controls.memberId.setValue(data.CardNo);
    this.memberForm.form.controls.memberType.setValue(data.MembershipType);
    this.secondaryMemberStatus = data.MembershipStatus;
    this.secondaryContactDetails.TrackMembershipNumber = data.CardNo;
  }

  guestSelect(data) {
    if (data) {
      if (this.dynamicForm?.form.controls) {
        let formControls = Object.entries(this.dynamicForm.form.controls);
        formControls.forEach((control) => {
          if (data[control[0]] && control) {
            control[1].setValue(data[control[0]], { emitEvent: false });
          }
        })
      }
      let CountryId = data.CountryId ? this.getCountryById(data?.CountryId) : Utilities.getDefaultCountry(this.countryCodes, this._settings)?.id
      this.dynamicForm?.form.get('CountryId').setValue(CountryId);
      let CountryId2 = data.CountryId2 ? this.getCountryById(data?.CountryId2) : Utilities.getDefaultCountry(this.countryCodes, this._settings)?.id
      this.dynamicForm?.form.get('CountryId2').setValue(CountryId2);
      this.mobileNumberValidation('PhoneNumber', 'CountryId');
      this.mobileNumberValidation('PhoneNumber2', 'CountryId2');
      this.dynamicForm?.form.get('Birthday').setValue(data.Birthday ? this.setDateToCurrentYear(data.Birthday) : null ,  {emitEvent: false});
      this.dynamicForm?.form.get('Anniversary').setValue(data.Anniversary ? this.setDateToCurrentYear(data.Anniversary) : null , {emitEvent: false});
      this.dynamicForm?.form.updateValueAndValidity();
      this.secondaryContactDetails.ContactId = data.Id;
      this.secondaryContactDetails.SearchType = data.SearchType;
      this.secondaryContactDetails.Notes = data.Notes;
      this.secondaryContactDetails.StayConfirmationCode = data.StayConfirmationCode;
      this.secondaryContactDetails.ContactCustomFields = data.ContactCustomFields;
      this.selectedSecondaryGuestPreferenceTags.forEach(tag => tag.setSelected = false)
      if (this.secondaryContactDetails.Notes && this.secondaryContactDetails.Notes.length) {
        this.mapOtherNotes();
      }
      if (this.secondaryContactDetails.ContactCustomFields && this.secondaryContactDetails.ContactCustomFields.length) {
        this.mapCustomFieldsData();
      }
    }
  }

  getCountryCodeId(mobileCode) {
    if (!mobileCode) {
      return null;
    }
    let country = this.countryCodes.find(country => country.countryCode === mobileCode)
    let guestCountryName;
    if (!country) {
      let guestCountry = this._settings.Countries.filter((country: CountryDTO) => country.Id === this.countryCode);
      mobileCode
      if (guestCountry && guestCountry.length > 0) {
        guestCountryName = guestCountry[0].Name;
      }
    }
    return country ? country.id : guestCountryName;
  }

  getCountryById(mobileCode) {
    if (!mobileCode) {
      return null;
    }
    let country;
    country = this._settings.Countries.filter((country: CountryDTO) => country.CountryPhoneCode === mobileCode)[0];
    if (!country) {
      country = this._settings.Countries.filter((country: CountryDTO) => country.Id === mobileCode)[0];
    }
    return country.Name;
  }
  setFormValues(FirstName, LastName,AliasName, CountryId, PhoneNumber, EmailAddress) {
    var { firstName: FirstNameForm, LastName: LastNameForm,AliasName:AliasNameForm ,CountryId: mobileCodeForm, mobile: mobileForm, EmailAddress: emailForm } = this.dynamicForm.form.controls;
    FirstNameForm.setValue(FirstName);
    LastNameForm.setValue(LastName);
    AliasNameForm.setValue(AliasName);
    mobileCodeForm.setValue(CountryId);
    mobileForm.setValue(PhoneNumber);
    emailForm.setValue(EmailAddress);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.secondaryGuestConfig.forEach(config => {
      config.blurClick && config.blurClick.bind(null);
      config.cellClick && config.cellClick.bind(null);
      config.returnFunction && config.returnFunction.bind(null);
    });
  }

  mapOtherNotes() {
    const preferredTags = this.secondaryGuestConfig.filter((fieldConfig) => fieldConfig.name.includes('secondaryGuestPreferredTags'));
    this.secondaryContactDetails.Notes.forEach((note) => {
      preferredTags.forEach((fieldConfig) => {
        const filteredNotes = fieldConfig.options.find(data => data.Id === note.RelatedId);
        if (filteredNotes) {
          filteredNotes.setSelected = true;
          this.selectedSecondaryGuestPreferenceTags.push(filteredNotes);
          filteredNotes.noteId = note.Id;
          if (filteredNotes.Name.includes('Others')) {
            let otherNotesName = `otherNotes${filteredNotes.Id}`;
            if (filteredNotes.setSelected) {
              const otherNotes: FieldConfig = {
                name: otherNotesName,
                type: 'textarea',
                inputType: 'text',
                label: 'notes',
                showHint: true,
                class: 'secondary-guest-details__other-tags',
                charLength: 1000,
                value: note.Text,
                isHidden: false,
                disabled: true
              };
              const preferredTagsIndex = this.secondaryGuestConfig.findIndex(preferenceConfig => preferenceConfig.name == fieldConfig.name);
              this.secondaryGuestConfig.splice(preferredTagsIndex + 1, 0, otherNotes);
              this.dynamicForm.form.addControl(otherNotesName, new UntypedFormControl({ value: note.Text, disabled: true }));
            }
          }
        }
      })
    });
    this.secondaryGuestConfig = [...this.secondaryGuestConfig];
  }

  getOptions(contactNotes, categoryColor, isNewGuest): any {
    let configOptios = [];
    contactNotes.forEach((notes) => {
      configOptios.push({
        Id: notes.Id,
        Label: notes.Label,
        Name: notes.Text,
        Icon: notes.Icon,
        Value: '',
        color: categoryColor,
        isDisabled: !isNewGuest
      });
    })
    return configOptios;
  }

  deleteSecondaryGuestFromContact() {
    this.partyService.reservationFormGroup.markAsDirty();
    this.deleteSecondaryGuest.emit({ secondaryContactInfo: this.secondaryContactDetails, isFormValid: true });
  }

  editSecondaryGuestFields() {
    if (this.isEdit && !this.secondaryContactDetails.isNew) {
      this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe((formValues) => {
        this.mapSecondaryContactDetails(formValues);
        this.setNameValidators(formValues);
        this.checkReqPartyFieldsAnyOne(formValues);
      }));
      this.secondaryGuestConfig.forEach((secondGuestConfig) => {
        secondGuestConfig.disabled = false;
      });
      if (this.dynamicForm && this.dynamicForm.form) {
        Object.keys(this.dynamicForm.form.controls).forEach((key: string) => {
          if (key !== 'CountryId') {
            const abstractControl = this.dynamicForm.form.get(key);
            abstractControl.enable();
          }
        });
      }
      let secondaryGuestTags = this.secondaryGuestConfig.filter((guestConfig) => guestConfig.name.includes('secondaryGuestPreferredTags'));
      secondaryGuestTags.forEach((config) => {
        config.options.forEach((preferredTagOptions) => {
          preferredTagOptions.isDisabled = false;
        });
      });
      this.secondaryGuestConfig = [...this.secondaryGuestConfig];
    }
  }
  CheckMemberShip(membershipId) {
    this.subscriptions.add(this.apiService.wildSearchEngageMember(membershipId).subscribe(data => {
      let memberData = isArray(data.Payload) ? data.Payload[0] : data.Payload;
      this.memberForm.form.controls.memberId.setValue(memberData.CardNo);
      this.memberForm.form.controls.memberType.setValue(memberData.MembershipType);
      this.secondaryMemberStatus = memberData.MembershipStatus;
      if (this.secondaryContactDetails) {
        this.secondaryContactDetails.TrackMembershipNumber = memberData.CardNo;
      }
    }))
  }
  checkReqPartyFieldsAnyOne(data) {
    data = data ? data : (this.dynamicForm?.form.value ? this.dynamicForm?.form.value : null);
    const count = this.dfs.requiredPhoneNumberLength$;
    if (this._settings && this._settings.General.RequiredSecondaryGuestFields === RequiredPartyFields.Anyone && (this.SecondaryGuestFieldVisibility.PrimaryContacts || this.SecondaryGuestFieldVisibility.SecondaryContacts)) {
      if (data && (data.PhoneNumber || data.PhoneNumber2 || data.EmailAddress || data.EmailAddress2)) {
        this.dynamicForm.form.get('PhoneNumber').clearValidators();
        this.dynamicForm.form.get('PhoneNumber').updateValueAndValidity({ emitEvent: false });
        this.dynamicForm.form.get('PhoneNumber2').clearValidators();
        this.dynamicForm.form.get('PhoneNumber2').updateValueAndValidity({ emitEvent: false });
        this.dynamicForm.form.get('EmailAddress2').setValidators([Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]);
        this.dynamicForm.form.get('EmailAddress2').updateValueAndValidity({ emitEvent: false });
        this.dynamicForm.form.get('EmailAddress').setValidators([Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]);
        this.dynamicForm.form.get('EmailAddress').updateValueAndValidity({ emitEvent: false });
        if (this._settings.General.ValidateGuestPhoneNumber) {
          this.dynamicForm.form.get('PhoneNumber').setValidators([Validators.minLength(count), Validators.maxLength(count)]);
          this.dynamicForm.form.get('PhoneNumber').updateValueAndValidity({ emitEvent: false });
          this.dynamicForm.form.get('PhoneNumber2').setValidators([Validators.minLength(count), Validators.maxLength(count)]);
          this.dynamicForm.form.get('PhoneNumber2').updateValueAndValidity({ emitEvent: false });
        }
        return true;
      } else {
        if (this._settings.General.ValidateGuestPhoneNumber) {
          this.dynamicForm.form.get('PhoneNumber').setValidators([Validators.required, Validators.minLength(count), Validators.maxLength(count)]);
          this.dynamicForm.form.get('PhoneNumber').updateValueAndValidity({ emitEvent: false });
          this.dynamicForm.form.get('PhoneNumber2').setValidators([Validators.required, Validators.minLength(count), Validators.maxLength(count)]);
          this.dynamicForm.form.get('PhoneNumber2').updateValueAndValidity({ emitEvent: false });
        }
        this.dynamicForm.form.get('EmailAddress2').setValidators([Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]);
        this.dynamicForm.form.get('EmailAddress2').updateValueAndValidity({ emitEvent: false });
        this.dynamicForm.form.get('EmailAddress').setValidators([Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]);
        this.dynamicForm.form.get('EmailAddress').updateValueAndValidity({ emitEvent: false });
        return false;
      }
    }
    return true;
  }

  setNameValidators(data) {
    if (data && !data.FirstName && !data.LastName) {
      this.updateFormValidity(true, this.updateNameValidity, Validators.required);
    } else if (data && data.FirstName && data.LastName) {
      this.updateFormValidity(false, this.updateNameValidity, Validators.required);
    } else if (data && data.FirstName) {
      this.updateFormValidity(false, this.updateLastNameValidity);
    } else if (data && data.LastName) {
      this.updateFormValidity(false, this.updateFirstNameValidity);
    }
  }

  updateFormValidity(isError: boolean, callBack: (validators?: ValidatorFn) => void, validators?: ValidatorFn) {
    validators ? callBack(validators) : callBack();
  }

  updateLastNameValidity = () => {
    this.setValidators(this.dynamicForm.form.controls, 'LastName', this.dynamicForm.form, Validators.nullValidator);
  }

  updateFirstNameValidity = () => {
    this.setValidators(this.dynamicForm.form.controls, 'FirstName', this.dynamicForm.form, Validators.nullValidator);
  }

  updateNameValidity = (validators: ValidatorFn) => {
    const dynamicFormControls = this.dynamicForm.form;
    this.setValidators(dynamicFormControls.controls, 'FirstName', dynamicFormControls, validators);
    this.setValidators(dynamicFormControls.controls, 'LastName', dynamicFormControls, validators);
  }

  setValidators(allControls, formControl: string, formGroup: UntypedFormGroup, validators: ValidatorFn) {
    if (allControls) {
      Object.keys(allControls).forEach((control) => {
        if (control === formControl) {
          formGroup.get(control).setValidators([validators, Validators.pattern(this.dfs.HTMLPattern) , Validators.maxLength(100)]);
          formGroup.get(control).updateValueAndValidity({ emitEvent: false });
        }
      });
    }
  }
}
