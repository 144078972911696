<section class="retail-revenue-log-wrapper newVersionWrapper orphan-payments com-pad-12">
    <div [formGroup]="transcForm">
        <div class="header-filter-sect d-flex ">
            <div class="d-flex">
                <mat-form-field class="d-inline-block ct-pad-3 date-picker-width" appearance="legacy"
                    [floatLabel]="floatLabel">
                    <mat-label>{{captions.fromDate}}</mat-label>
                    <input [attr.automationId]="'Txt_orphanPayments_fromDate'" formControlName="fromDate" dateRestricter (dateChange)="fromDate($event)" class="LW14"
                        matInput [matDatepicker]="picker3" [placeholder]="captions.fromDate" name="date">
                    <mat-datepicker #picker3></mat-datepicker>
                    <i [attr.automationId]="'Icn_orphanPayments_fromDate'" aria-hidden="true" matSuffix class="icon-Calender" (click)="picker3.open()"></i>
                    <mat-error
                        *ngIf="transcForm.controls['fromDate'].hasError('matDatepickerParse')">{{captions.common.dateFormat}}</mat-error>
                    <mat-error
                        *ngIf="transcForm.controls['fromDate'].hasError('matDatepickerMin')">{{captions.common.minimum}}
                        {{captions.shop.fromDate}}</mat-error>
                    <mat-error
                        *ngIf="transcForm.controls['fromDate'].hasError('matDatepickerMax')">{{captions.common.maximum}}
                        {{captions.shop.fromDate}}</mat-error>
                </mat-form-field>
                <mat-form-field class="d-inline-block ct-pad-3 date-picker-width" appearance="legacy"
                    [floatLabel]="floatLabel">
                    <mat-label>{{captions.toDate}}</mat-label>
                    <input [attr.automationId]="'Txt_orphanPayments_toDate'" formControlName="toDate" [min]="fromDate" dateRestricter (dateChange)="toDate($event)"
                        class="LW14" matInput [matDatepicker]="picker2" [placeholder]="captions.toDate" name="date">
                    <mat-datepicker #picker2></mat-datepicker>
                    <i [attr.automationId]="'Icn_orphanPayments_toDate'" aria-hidden="true" matSuffix class="icon-Calender" (click)="picker2.open()"></i>
                    <mat-error
                        *ngIf="transcForm.controls['toDate'].hasError('matDatepickerParse')">{{captions.common.dateFormat}}</mat-error>
                    <mat-error
                        *ngIf="transcForm.controls['toDate'].hasError('matDatepickerMin')">{{captions.common.minimum}}
                        {{captions.shop.toDate}}</mat-error>
                    <mat-error
                        *ngIf="transcForm.controls['toDate'].hasError('matDatepickerMax')">{{captions.common.maximum}}
                        {{captions.shop.toDate}}</mat-error>
                </mat-form-field>
            </div>

        </div>
        <div class="advanced-search-sect">
            <span class="ag_mb-1">{{captions.AdvancedSearch}}</span>
            <div class="d-flex al-centre">
                <input [attr.automationId]="'Srch_orphanPayments_ticketNo'" matInput autocomplete="off" formControlName="searchByTicket" inputtype="reservedchar"
                    class="search-input" type="text" [placeholder]="captions.TicketNumber" (keydown.enter)="getTableData()"/>
                <!-- <input matInput autocomplete="off" formControlName="searchByClerkID" inputtype="reservedchar"
                    class="search-input" type="text" [placeholder]="captions.ClerkID" (keydown.enter)="getTableData()"/> -->
                <input [attr.automationId]="'Srch_orphanPayments_amount'" matInput autocomplete="off" formControlName="searchByAmount" inputtype="nonnegative,decimal,roundoff2"
                    class="search-input" type="text" [placeholder]="captions.Amount" (keydown.enter)="getTableData()"/>
                <app-button [attr.automationId]="'Btn_orphanPayments_search'" [buttontype]="searchButton" class="ag-pl-3 " (valueChange)="getTableData()"></app-button>
                <app-button [attr.automationId]="'Btn_orphanPayments_cancel'" [buttontype]="cancelButton" (valueChange)="resetSearch($event)"></app-button>
            </div>
        </div>
    </div>
    <div class="table-sect custom-height-fix w-100 ">
        <!-- COMMON VIRTUAL SCROLL GRID -->
        <ng-container *ngIf="!showLoader && originalData?.length > 0">
            <app-cdkvirtual [headerOptions]="headerOptions" [tableContent]="tableContent | async"
                [options]="tableOptions" [searchOptions]="searchText" [childTemplate]="childTemplate"
                (EmittedData)='tableAction($event)'>
            </app-cdkvirtual>
        </ng-container>
        <div *ngIf="!showLoader && originalData?.length == 0" class="no-data-found-msg">
            {{captions.TransactionLogScreen?.NoDataFoundLbl}}</div>
        <!-- SCREEN LOADER -->
        <ng-container *ngIf="showLoader">
            <div class="d-flex h-100 align-items-center">
                <div id="cover-spin-retail">
                    <div class="customspinnerimg">
                        <img src="./assets/images/agil_favicon.ico" style="width: 100%;">
                    </div>
                    <div id="default-message">{{loaderCaptions.lbl_processing}}</div>
                </div>
            </div>
        </ng-container>
    </div>
</section>
