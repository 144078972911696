import { Component, Input, OnInit ,AfterViewInit, QueryList, ViewChildren,OnDestroy, ViewEncapsulation} from '@angular/core';
import { PopupService } from '@app/popup-module/popup.service';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-reason-collection-popup',
  templateUrl: './reason-collection-popup.component.html',
  styleUrls: ['./reason-collection-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReasonCollectionPopupComponent implements OnInit,AfterViewInit,OnDestroy {
  @Input() data:any
  reasonConfig:FieldConfig[];
  predefinedReasons: any[]
  predefinedReasonConfig : FieldConfig[];
  freetext:any = '';
  subscriptions: Subscription = new Subscription();
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  constructor(private ps: PartyService, private popupService: PopupService , public translateService: TranslateService) { }
  
  

  ngOnInit(): void {
    this.predefinedReasons = [{id:1,value:this.translateService.instant('noShow')},{id:0,value:this.translateService.instant('Others')}]
    let inputData = this.data;
    this.freetext = inputData?.Reason || '';
    this.predefinedReasonConfig=[{
      type: 'select',
      name: 'predefinedreason',
      placeholder: 'Any Other Reason',
      label: '',
      value:inputData?.Reason ?  this.predefinedReasons[1].id : this.predefinedReasons[0].id ,
      class: '',
      options:this.predefinedReasons
    }];
    this.reasonConfig = [
      {
        type: 'textarea',
        name: 'otherreason',
        placeholder: 'Any Other Reason',
        label: '',
        rows: 3,
        inputType: 'text',
        charLength: 200,
        value: inputData?.Reason || '',
        class: 'reasoncollection__otherreasonform',
        textAreaAppearance:true,
        disabled:inputData?.Reason ? false : true
      }
    ];

    this.ps.ReasonFromPopUp = this.predefinedReasons[0].value;
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.components.first.form.valueChanges
      .subscribe((val: any) => {
        if(val.predefinedreason){
          this.components.last.form.controls['otherreason'].setValue('');
          this.components.last.form.controls['otherreason'].disable();
        this.ps.ReasonFromPopUp = this.predefinedReasons.filter(x => x.id == val.predefinedreason)?.values;
        }
        else{
          this.ps.ReasonFromPopUp = this.freetext;
          this.components.last.form.controls['otherreason'].enable()
          this.components.last.form.controls['otherreason'].setValue(this.freetext);
        }
        this.validateform();
      }));
    this.subscriptions.add(this.components.last.form.valueChanges.pipe(debounceTime(100),distinctUntilChanged())
      .subscribe((val: any) => {
        if(val.otherreason){
          this.freetext = val.otherreason;
          this.ps.ReasonFromPopUp = this.freetext;
        }
        this.validateform();
      }));
  }

  validateform(){
    const isValid = this.components.first.form.value.predefinedreason || this.components.last.form.value.otherreason;
    this.popupService.saveBtnEnable$.next(isValid);
  }

  ngOnDestroy(): void {
   if(this.subscriptions){
     this.subscriptions.unsubscribe();
   }
  }
}
