import { AlertType, AlertAction } from 'src/app/common/Models/common.models';
import { ButtonTypes } from './../../Models/common.models';
import { ButtonValue, HeaderInput, AgToggleConfig, InputActionPlaceHolder } from '../../Models/ag-models';
import { Component, OnInit, EventEmitter, Output, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
@Component({
  selector: 'app-input-header-action',
  templateUrl: './input-header-action.component.html',
  styleUrls: ['./input-header-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputHeaderActionComponent implements OnInit, OnDestroy {
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  ActionForm: UntypedFormGroup;
  captions: any;
  isUpdate: boolean;
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  updateCaption: any;
  @Input() inputFor;
  toggleInput: AgToggleConfig;
  code: string;
  name: string;
  active: string;
  errMessageCode: string;
  errMessageName: string;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @Input() validationType: string;
  @Input() inputPlaceholder: InputActionPlaceHolder;
  @Input() minValue: number;
  @Input() maxValue = 50;
  floatLabel: string;
  
  @Input('minLength')
  set minimumFieldLength(value:number){
    this.nameMinLength = value;
  }
  @Input('maxLength')
  set maximumFieldLength(value:number){
    this.nameMaxLength = value;
  }
  @Input('codeMinLength')
  set minimumCodeLength(value:number){
    this.codeMinLength = value;
  }
  @Input('noSpaceAllowed')
  set setNoSpaceAllowed(value:string){
    if(value)
      this.inputType='noprespace,notallowspace';
  }
  @Input('codeMaxLength')
  set maximumCodeLength(value:number){
    this.codeMaxLength = value;
  }
  @Input() cancelWarningMessage: string;
  @Input('clearInput')
  set clearFormInputs(value) {
    if (value) {
      this.clearForm();
    }
  }
  nameMinLength: number = 1;
  codeMinLength: number = 1;
  codeMaxLength: number = 8;
  nameMaxLength: number = 50;
  inputType: string = 'noprespace';
  @Input() showRequired: boolean; 

  @Input('disabled')
  set setDisabled(value: boolean) {
    if (value) {
      this.ActionForm.controls.code.disable();
      this.ActionForm.controls.name.disable();
      this.cancelButton.disabledproperty = value;
    } else {
      this.ActionForm.controls.code.enable();
      this.ActionForm.controls.name.enable();
      this.cancelButton.disabledproperty = value;
    }
  }


  @Input('editActionObj')
  set ActionObject(value: HeaderInput) {  
    this.isUpdate = false;
    if (value) {
      this.isUpdate = value.toggleShow;
      this.saveButton.label = this.captions.btn_update;
      this.ActionForm.patchValue(value);
    }
    if (this.isUpdate) {
      this.toggleInput = {
        group: this.ActionForm,
        horizontal: false,
        label: this.captions.tog_lbl_active,
        formControlName: 'isActive',
        disabled: false
      };
    }
  }


  @Input('inputPlaceholder')
  set setPlaceHolder(value: InputActionPlaceHolder) {
    this.code = value.code;
    this.name = value.name;
    this.errMessageCode = value.errorMessageCode;
    this.errMessageName = value.errorMessageName;
  }

  constructor(private formBuilder: UntypedFormBuilder, private _Localization: Localization, private _utilities: CommonUtilities) {
    this.captions = this._Localization.captions; 
    this.floatLabel = this._Localization.setFloatLabel;   
    this.ActionForm = this.formBuilder.group({
      code: ['', [Validators.required,  Validators.maxLength(this.codeMaxLength), Validators.minLength(this.codeMinLength)]],
      name: ['', [Validators.required,  Validators.maxLength(this.nameMaxLength), Validators.minLength(this.nameMinLength)]],
      isActive: true,
      id: 0,
      listOrder: 0
    });

    this.toggleInput = {
      group: this.ActionForm,
      horizontal: false,
      label: this.captions.tog_lbl_active,
      formControlName: 'isActive',
      disabled: false
    };

    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_add,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
  }

  ngOnInit() {
    this.ActionForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(() => {
      this.saveButton.disabledproperty = !(this.ActionForm.dirty && this.ActionForm.valid);
    });

    this.formReady.emit(this.ActionForm);
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }
  

  onSave(event) {
    this.saveButton.disabledproperty = true;
    this.save.emit(this.ActionForm.value);
   
  }

  onCancel(arg) {

    if (this.ActionForm.dirty) {
      const _cancelWarningMessage = this.cancelWarningMessage ? this.cancelWarningMessage : this.captions.lbl_unsavedData;
      this._utilities.showCommonAlert(_cancelWarningMessage, AlertType.Warning, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.leaveChanges();
        }
      });
    } else {
      this.leaveChanges();
    }


  }

  leaveChanges() {
    this.clearForm();
    this.cancel.emit(this.ActionForm.value);
  }

  private clearForm() {
    this.isUpdate = false;
    this.saveButton.label = this.captions.btn_add;
    this.ActionForm.reset({
      isActive: true,
      id: 0,
      listOrder: 0
    });
  } 

}
