import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { buttonTypes, ManualSlotSelection } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { SettingsDTO, SlotOutput } from '@models/RestaurantDTO';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-manual-slot',
  templateUrl: './manual-slot.component.html',
  styleUrls: ['./manual-slot.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManualSlotComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @ViewChild('manualSlotcontainer', { static: true }) slotDiv: ElementRef;
  manualSlotsGroup: any[] = [];
  addToStandByButton: ButtonValue;
  customSlotConfig: FieldConfig[];
  partySizeArray: any[];
  seatingArea: any;
  maxPartySizeAllowed = 50;
  addSlotButton: ButtonValue;
  settings: SettingsDTO;
  cancelSlotButton: ButtonValue;
  addCustomSlotButton: ButtonValue;
  disableSlotButton: ButtonValue;
  updateSlotButton: ButtonValue;
  selectedSlot: any;
  @ViewChild('sl', { static: true }) private sl: NgbPopover;
  @ViewChildren('es') private es: QueryList<NgbPopover>;
  selectedSlotDateTime: any;
  manualSlotSubcriptions = new Subscription();
  nodataImgUrl: string = urlConfig.noReservationsUrl;
  popoverPosition = "";
  subscriptions: Subscription = new Subscription();

  constructor(private cs: CacheService, public partyService: PartyService, private popupService: PopupService,
              private as: AppService, private ts: TranslateService ,  @Inject(MAT_DIALOG_DATA) public dialogData) {
    this.subscriptions.add(this.cs.settings.subscribe((settings) => {
      this.settings = settings;
    }));
  }

  ngOnInit() {
    let isStandbyDisabled;
    const partySize = this.partyService.reservationFormGroup.value.selectedSize;
    this.subscriptions.add(this.partyService.partySlots$.subscribe(data => {
      this.manualSlotsGroup = this.partyService.manualSlotsGroup.filter((slot) =>
                               slot.dateTime === this.data.selectedSlot.DateTime);
      if (this.manualSlotsGroup.length > 0) {
        isStandbyDisabled = this.manualSlotsGroup[0].slots.filter((slot) => !slot.IsDisabled).length > 0;
        this.sl.close();
        this.addToStandByButton.disbaledproperity = isStandbyDisabled;
      } else {
        if(this.dialogData?.service)
          this.dialogData.service.popupOutputEvent$.next({ actionName: ManualSlotSelection.disableSlot, slot: '' } as SlotOutput);
      }
    }));
    this.manualSlotsGroup = this.partyService.manualSlotsGroup.filter((slot) =>
                  slot.dateTime === this.data.selectedSlot.DateTime);
    this.selectedSlotDateTime = this.data.selectedSlot.DateTime;
    this.selectedSlot = this.data.selectedSlot;
    if (this.manualSlotsGroup.length > 0) {
      isStandbyDisabled = this.manualSlotsGroup[0].slots.filter((slot) => !slot.IsDisabled).length > 0;
    }
    this.addToStandByButton = {
      type: buttonTypes.actionSecondary, label: 'addToStandby',
      customclass: 'action-btn',
      disbaledproperity: isStandbyDisabled
    };
    this.addSlotButton = {
      type: buttonTypes.actionPrimary, label: 'addslot',
      customclass: 'action-btn', disbaledproperity: !this.data.showAllSlot
    };
    this.cancelSlotButton = {
      type: buttonTypes.actionSecondary, label: 'cancel',
      customclass: 'slot-popover__cancel'
    };
    this.addCustomSlotButton = {
      type: buttonTypes.actionPrimary, label: 'addText',
      customclass: 'slot-popover__add'
    };
    this.disableSlotButton = {
      type: buttonTypes.actionSecondary, label: 'disable',
      customclass: 'slot-popover__disable'
    };
    this.updateSlotButton = {
      type: buttonTypes.actionPrimary, label: 'updateSlot',
      customclass: 'slot-popover__updateSlot'
    };
    this.setCustomSlotFormConfig();
    this.manualSlotSubcriptions.add(this.as.minuteTimer$.subscribe(() => {
      let lockedSlots;
      if (this.manualSlotsGroup.length > 0) {
        lockedSlots = this.manualSlotsGroup[0].slots.filter((slot) => slot.IsLocked);
      }
      if (lockedSlots && lockedSlots.length > 0) {
        const restaurantTime = Utilities.Date(Utilities.getRestaurantDateTime(this.settings.General.DaylightDelta));
        lockedSlots.forEach(lockedSlot => {
          const lockedTime = Math.ceil((Utilities.Date(lockedSlot.LockExpiresAt).diff(restaurantTime, 'minutes')));
          if (lockedTime > 0) {
            lockedSlot.buttonInfo.label = `${this.ts.instant('lockFor')} ${lockedTime} mins`;
          } else {
            lockedSlot.IsLocked = false;
            lockedSlot.buttonInfo.disbaledproperity = false;
            lockedSlot.buttonInfo.label = lockedSlot.LimitedMinPartySize + ' - ' + lockedSlot.LimitedMaxPartySize + ' ' +
            this.getSeatingAreaNameById(lockedSlot.SeatingAreaId);
          }
        });

      }
    }));
  }


  getSeatingAreaNameById(id: number) {
    return (id > 0) ? this.settings.SeatingAreas.find(area => area.Id == id).Name : "Any Section";
  }

  setCustomSlotFormConfig() {
    this.setPartySize();

    this.customSlotConfig = [{
      type: 'select',
      label: 'minSize',
      name: 'minSize',
      options: this.partySizeArray,
      class: 'slot-popover__min-size',
      showErrorText: true,
      isTranslate: false,
      value: this.partySizeArray[1].id
    },
    {
      type: 'select',
      label: 'maxSize',
      name: 'maxSize',
      options: this.partySizeArray,
      class: 'slot-popover__max-size',
      showErrorText: true,
      isTranslate: false,
      value: this.partySizeArray[3].id
    },
    {
      type: 'select',
      label: 'seatingArea',
      name: 'seatingArea',
      options: this.seatingArea,
      class: 'slot-popover__seatingarea',
      showErrorText: true,
      isTranslate: false,
    },
    {
      type: 'switch',
      name: 'webReservable',
      inputType: 'text',
      label: 'webReservable',
      class: 'slot-popover__web-reservable',
      checked: true
    }
    ];
    this.setSeatingArea();
  }

  setPartySize() {
    this.partySizeArray = [];
    for (let i = 1; i <= this.maxPartySizeAllowed; i++) {
      this.partySizeArray.push({ id: i, value: i.toString() });
    }
  }

  setSeatingArea() {
    this.seatingArea = this.settings.SeatingAreas;
    this.seatingArea.forEach((area) => { if (area.Id === null) { area.Id = -1; } return area; });
    this.seatingArea.unshift(this.seatingArea.splice(this.seatingArea.findIndex(item => item.Id === -1), 1)[0]);
    this.customSlotConfig[2].options = this.seatingArea.map(x => ({ id: x.Id, value: x.Name }));
    this.customSlotConfig[2].value = this.customSlotConfig[2].options.filter(x => x.id == -1)[0].id;
  }

  addSlot() {
    this.sl.close();
  }

  cancelSlot() {
    this.sl.close();
  }

  disableSlot() {
    this.es.forEach((editPopover, index) => {
      if (editPopover) {
        editPopover.close();
      }
    });
  }

  updateSlot() {
    this.es.forEach((editPopover, index) => {
      if (editPopover) {
        editPopover.close();
      }
    });
  }

  addToStandBy(slot) {
    console.log(slot);
    if(this.dialogData?.service)
    this.dialogData?.service.popupOutputEvent$.next({ actionName: ManualSlotSelection.standBy, slot } as SlotOutput);
  }

  openSlot() {
    if (this.es) {
      this.es.forEach((editPopover) => {
        if (editPopover) {
          editPopover.close();
        }
      });
    }
    this.sl.open();
    this.selectedSlotDateTime = this.data.selectedSlot.DateTime;
  }

  openCustomSlots(popOverIndex, customSlot) {
    if (this.sl) {
      this.sl.close();
    }
    this.es.forEach((editPopover, index) => {
      if (editPopover) {
        editPopover.close();
      }
      if (popOverIndex === index) {
        editPopover.open();
        if (this.slotDiv.nativeElement.scrollWidth > this.slotDiv.nativeElement.offsetWidth) {
          this.popoverPosition = "bottom-right";
        }
        else {
          this.popoverPosition = "bottom-left";
        }
      }
    });
    this.selectedSlotDateTime = customSlot;
  }

  closeEditSlot() {
    this.es.forEach((editPopover, index) => {
      if (editPopover) {
        editPopover.close();
      }
    });
  }

  selectCustomSlot(selectedSlot) {
    if(this.dialogData?.service)
    this.dialogData?.service.popupOutputEvent$.next({ actionName: ManualSlotSelection.slotSelection, slot: selectedSlot } as SlotOutput);
  }

  closeSlotPopover(isAdd) {
    if (isAdd) {
      this.sl.close();
    } else {
      this.es.forEach((editPopover, index) => {
        if (editPopover) {
          editPopover.close();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    if (this.manualSlotSubcriptions) { this.manualSlotSubcriptions.unsubscribe(); }
  }
}
