<section class="newVersionWrapper signature-capture-wrapper com-pad-12 h-100 p-2">
    <div class="header-filter-sect d-flex bodyWrap">
        <form [formGroup]="signatureCapForm" class="d-flex">
            <mat-form-field class="d-inline-block pr-4 date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
               <mat-label>{{captions.fromDate}}</mat-label>
               <input formControlName="fromDate" dateRestricter (dateChange)="fromDate($event)" class="LW14" matInput
                 [matDatepicker]="picker3" [placeholder]="captions.fromDate" name="date" [attr.automationId]="'Txt_signatureCapture_fromDate'">
               <mat-datepicker #picker3></mat-datepicker>
               <i  aria-hidden="true" matSuffix class="icon-Calender" (click)="picker3.open()"></i>
               <mat-error *ngIf="signatureCapForm.controls['fromDate'].hasError('matDatepickerParse')">{{captions.common.dateFormat}}</mat-error>
               <mat-error *ngIf="signatureCapForm.controls['fromDate'].hasError('matDatepickerMin')">{{captions.common.minimum}} {{captions.shop.fromDate}}</mat-error>
               <mat-error *ngIf="signatureCapForm.controls['fromDate'].hasError('matDatepickerMax')">{{captions.common.maximum}} {{captions.shop.fromDate}}</mat-error>
             </mat-form-field>
             <mat-form-field class="d-inline-block date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
               <mat-label>{{captions.toDate}}</mat-label>
               <input formControlName="toDate" [min]="fromDate" dateRestricter (dateChange)="toDate($event)" class="LW14" matInput
                 [matDatepicker]="picker2" [placeholder]="captions.toDate" name="date" [attr.automationId]="'Txt_signatureCapture_toDate'">
               <mat-datepicker #picker2></mat-datepicker>
               <i  aria-hidden="true" matSuffix class="icon-Calender" (click)="picker2.open()"></i>
               <mat-error *ngIf="signatureCapForm.controls['toDate'].hasError('matDatepickerParse')">{{captions.common.dateFormat}}</mat-error>
               <mat-error *ngIf="signatureCapForm.controls['toDate'].hasError('matDatepickerMin')">{{captions.common.minimum}} {{captions.shop.toDate}}</mat-error>
               <mat-error *ngIf="signatureCapForm.controls['toDate'].hasError('matDatepickerMax')">{{captions.common.maximum}} {{captions.shop.toDate}}</mat-error>
             </mat-form-field>
        </form>
       </div>
       <div class="advanced-search-sect bodyWrap mt-2">
        <span class="ag_mb-1">{{captions.AdvancedSearch}}</span>
                <form [formGroup]="advanceForm" class="d-flex al-centre">
                    <input matInput autocomplete="off" formControlName="ticketNumber" class="search-input" type="text"
                    [placeholder]="captions.tbl_hdr_ticketNumber" [attr.automationId]="'Txt_signatureCapture_ticketNumber'" />
                    <input matInput autocomplete="off" formControlName="transactionType" class="search-input" type="text"
                    [placeholder]="captions.lbl_transactionType" [attr.automationId]="'Txt_signatureCapture_transactionType'"/>
                    <input matInput autocomplete="off" formControlName="user" class="search-input" type="text"
                    [placeholder]="captions.tbl_hdr_user" [attr.automationId]="'Txt_signatureCapture_user'"/>
                    <app-button [buttontype]="searchButton" class="ag-pl-3 " (valueChange)="search($event)" [attr.automationId]="'Btn_signatureCapture_search'"></app-button>
                    <app-button [buttontype]="cancelButton" (valueChange)="cancel($event)" [attr.automationId]="'Btn_signatureCapture_cancel'"></app-button>
                </form>
       </div>
       <div class="prop-actions d-flex">
            <marquee *ngIf = "isBatchProcessPending">{{batchInProgressMsg}}</marquee>
            <app-button [buttontype]="refreshButton" (valueChange)="refreshGrid($event)" [attr.automationId]="'Btn_signatureCapture_refresh'"></app-button>
            <app-button [buttontype]="retryButton" (valueChange)="retry($event)" [attr.automationId]="'Btn_signatureCapture_retry'"></app-button>
            <app-button [buttontype]="closeButton" (valueChange)="close($event)" [attr.automationId]="'Btn_signatureCapture_close'"></app-button>
        </div>
        <div class="table-sect w-100" [attr.automationId]="'Tbl_signatureCapture_signatureCaptureTable'">
            <app-cdkvirtual [headerOptions]="headerOptions" [tableContent]="tableContent | async" [options]="tableOptions"
            [searchOptions]="searchText"  [childTemplate]="childTemplate" (EmittedData)='tableAction($event)'>
           </app-cdkvirtual>
        </div>
<ng-template #childTemplate let-element="element" let-key="key" let-index="idx">
    <ng-container [ngSwitch]="key">
        <ng-container *ngSwitchCase="'errorMessage'">
            <div class="revenue-pop ag_display--inblock">
                <span>{{element['errorMessage']}} <i class="icon-information" [matTooltipClass]="'AgysMatCustTooltip'" [matTooltip]="element['errorMessage']"></i></span>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'Action'">
            <div class="room-block ag_display--inblock">
                <button class="custom-focus-btn custom-left-padding ag_pr--2 ag_cursor--pointer icon-reset"
                [ngClass]="{'ag_section--disable': isBatchProcessPending}" (click)="tableRetry(element,key)"
                [matTooltipClass]="'AgysMatCustTooltip'" [matTooltip]="captions.retry" [attr.automationId]="'Btn_signatureCapture_tableRetry'"></button>

                <button class="custom-focus-btn custom-left-padding ag_pr--2 ag_pl--2 ag_cursor--pointer icon-error-icon"
                [ngClass]="{'ag_section--disable': isBatchProcessPending}" (click)="tableClose(element,key)"
                [matTooltipClass]="'AgysMatCustTooltip'" [matTooltip]="captions.Close" [attr.automationId]="'Btn_signatureCapture_tableClose'"></button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'failedAmount'">
           <div class="room-block ag_display--inblock">
               <span>
                   {{element['failedAmount'] | Currency}}
                 </span>
           </div>
       </ng-container>
    </ng-container>
</ng-template>
</section>
