export enum RetailHosts {
  PMSManagerService,
  TenantManagement,
  RetailGateway,
  PayAgent,
  MsDeeplink,
  V1IGPosting,
  Report,
  V1Reservation,
  CommonGateway,
  V1RoomInventory,
  TaskService,
  V1InterfaceIntegration,
  SalesCateringService
}
export enum RetailRoutes {
  // Tasks
  GetAllTasks = 'TaskService/Task/GetAllTasks/{product}',

    //Get CEDS
  GetCEDSByTerminalId = 'Payment/GetCEDSByTerminalId/{terminalId}',
  //Settlement
  GetSettlementHistory = 'Transaction/GetSettlementHistoryList/outlet/{outletId}/{transactionDate}/{status}',
  CreateSettlementHistory = 'Transaction/CreateSettlementHistory',
  TryCloseTransaction = 'Checks/TryCloseTransaction/{transactionId}',
  GetFinancialBins = 'POS/Transaction/GetFinancialBins',
  GetPostingRecords = 'POS/Transaction/GetPostingRecords',
  //user
  GetBreakPoint = 'user/GetUserClaimsAsync/{roleID}?{bkpn:QueryString}',

  GetPostRecordsForResortFinance = 'POS/Transaction/BuildPostRecordsForResortFinance',
  PostCharge = 'PaymentService/FolioPosting',

  //RankCodeManagement
  GetAllRankCodeManagement = 'RetailManagementService/RankCodes',
  GetRankCodeManagement = 'RetailManagementService/RankCodes/{id}',
  GetRankCodeByCode = 'RetailManagementService/RankCodes/code/{code}',
  DeleteRankCodeManagement = 'RetailManagementService/RankCodes/{id}',
  CreateRankCodeManagement = 'RetailManagementService/RankCodes',
  UpdateRankCodeManagement = 'RetailManagementService/RankCodes',
  GetNextListOrder_RankCodeManagement = 'RetailManagementService/RankCodes/NextListOrder',

  //OfferCodeManagement
  GetAllOfferCodeManagement = 'RetailManagementService/OfferCodes',
  GetOfferCodeManagement = 'RetailManagementService/OfferCodes/{id}',
  DeleteOfferCodeManagement = 'RetailManagementService/OfferCodes/{id}',
  CreateOfferCodeManagement = 'RetailManagementService/OfferCodes',
  UpdateOfferCodeManagement = 'RetailManagementService/OfferCodes',
  GetNextListOrder_OfferCodeManagement = 'RetailManagementService/OfferCodes/NextListOrder',
  GetAllCompTemplateAuthorizer = 'RetailManagementService/CompTemplate/GetAllCompTemplateAuthorizer?compTemplateId={compTemplateId}',
  GetCompTemplateAuthorizerByAuthCode = 'RetailManagementService/CompTemplate/GetCompTemplateAuthorizerByAuthCode?compTemplateId={compTemplateId}&authorizerCode={authorizerCode}',
  GetPackages = 'RetailManagementService/Packages?includeInactive={includeInactive}',


  //CompTemplateSetup
  GetAllCompTemplateSetup = 'RetailManagementService/CompTemplate?product={product}',
  GetCompTemplateSetup = 'RetailManagementService/CompTemplate/{id}',
  DeleteCompTemplateSetup = 'RetailManagementService/CompTemplate/{id}',
  CreateCompTemplateSetup = 'RetailManagementService/CompTemplate',
  UpdateCompTemplateSetup = 'RetailManagementService/CompTemplate',
  GetNextListOrder_CompTemplateSetup = 'RetailManagementService/CompTemplate/NextListOrder',
  GetAllCompDepartments = 'RetailManagementService/CompTemplate/compStaffsAndDepartment',

  //PackageComponent
  GetAllPackageComponent = 'RetailManagementService/PackageComponents?includeInactive={includeInactive}',
  GetPackageComponent = 'RetailManagementService/PackageComponents/{id}',
  GetPackageComponentByCode = 'RetailManagementService/PackageComponents/Code/{code}',
  DeletePackageComponent = 'RetailManagementService/PackageComponents/{id}',
  CreatePackageComponent = 'RetailManagementService/PackageComponents',
  UpdatePackageComponent = 'RetailManagementService/PackageComponents/{updateChildEntity}',
  GetNextListOrder_PackageComponent = 'RetailManagementService/PackageComponents/NextListOrder',
  GetPackageComponentsByIdList = 'RetailManagementService/PackageComponents/GetPackageComponentsByIdList',

  //PackageComponentCategory
  GetAllPackageComponentCategory = 'RetailManagementService/PackageComponentCategories?includeInactive={includeInactive}',
  GetPackageComponentCategory = 'RetailManagementService/PackageComponentCategories/{id}',
  GetPackageComponentCategoryByCode = 'RetailManagementService/PackageComponentCategories/Code/{code}',
  DeletePackageComponentCategory = 'RetailManagementService/PackageComponentCategories/{id}',
  CreatePackageComponentCategory = 'RetailManagementService/PackageComponentCategories',
  UpdatePackageComponentCategory = 'RetailManagementService/PackageComponentCategories',
  GetNextListOrder_PackageComponentCategory = 'RetailManagementService/PackageComponentCategories/NextListOrder',

  RefundPartialPayments = 'Transaction/{transactionId}/returnpartialpayments/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}/amountToRefund/{amountToRefund}',
  

  //PackageClass
  GetAllPackageClass = 'RetailManagementService/PackageClasses?includeInactive={includeInactive}',
  GetPackageClass = 'RetailManagementService/PackageClasses/{id}',
  GetPackageClassByCode = 'RetailManagementService/PackageClasses/Code/{code}',
  DeletePackageClass = 'RetailManagementService/PackageClasses/{id}',
  CreatePackageClass = 'RetailManagementService/PackageClasses',
  UpdatePackageClass = 'RetailManagementService/PackageClasses',
  GetNextListOrder_PackageClass = 'RetailManagementService/PackageClasses/NextListOrder',


  //InventoryItems
  GetActiveQuickSale = 'quicksale',
  GetActiveCategories = 'Categories/active',
  GetShopItemByPagination = 'RetailItems/shop/items?outletId={outletId}&includeInactive={includeInactive}&pageStart={pageStart}&pageLength={pageLength}&sortBy={sortBy}&isSortByAscending={isSortByAscending}&searchText={searchText}&searchByBarcode={searchByBarcode}&quickSaleCategory={quickSaleCategory}&itemId={itemId}',
  GetRetailItemsByIdList = 'RetailItems/GetAllOutletsRetailItemsByIdList',
  AdvanceSearchFolioInventoryItems = 'RetailManagementService/RetailItems/GetFolioRetailItems',
  GetCategoryAndSubCategoryLink = 'SubCategories/categorySubCategoryLink',

  CreateSurcharge = 'RetailManagementService/PackageSeasonalSurchargeRates',
  UpdateSurcharge = 'RetailManagementService/PackageSeasonalSurchargeRates',
  GetSurchargesBasedOnFilter = 'RetailManagementService/PackageSeasonalSurchargeRates/GetSurchargesBasedOnFilter',
  GetSurchargesByPackageId = 'RetailManagementService/PackageSeasonalSurchargeRates/package/{packageId}',
  GetPackageSurChargesByPackageRoomTypeId = 'RetailManagementService/Packages/GetPackageSureChargesByPakcageRoomTypeId',
  GetPackagebyPackageCodeList ='RetailManagementService/Packages/GetPackagebyPackageCodeList/{includeInactive}',
  GetPackageServiceChargesByPackageIds = 'RetailManagementService/Packages/GetPackageServiceCharges',
  CreatePackagePlan = 'Packages',
  UpdatePackagePlan = 'RetailManagementService/Packages/{updateChildEntity}',
  GetPackagePlan = 'RetailManagementService/Packages?includeInactive={includeInactive}&includeRelatedData={includeRelatedData}&includeConfidential={includeConfidential}',  
  GetActivePackagesBasicDetails = 'RetailManagementService/Packages/GetActivePackagesBasicDetails/startDate/{startDate}/endDate/{endDate}',
  GetActivePackagesBasicDetailsByEndDate = 'RetailManagementService/Packages/GetActivePackagesBasicDetailsByEndDate/endDate/{endDate}',
  GetPackagePlanComponents = 'RetailManagementService/PackagePlanComponents?includeInactive={includeInactive}',
  DeletePackagePlan = 'RetailManagementService/Packages/{id}',
  GetPackageComponents = 'RetailManagementService/Packages/{id}',
  GetNextListOrder_Package = 'RetailManagementService/Packages/NextListOrder',
  GetPackagesByFilter = 'RetailManagementService/Packages/GetPackagesByFilter',
  GetPackagesForAvailabilty = 'RetailManagementService/PackageAvailability/GetPackagesForAvailabilty',
  GetAllPackagesBasicDetails ='RetailManagementService/Packages/GetAllPackagesBasicDetails?includeInactive={includeInactive}',

  CreatePackageBanding = 'RetailManagementService/PackageYieldByPlanClasses/CreateYieldBanding',
  GetPackageBandings = 'RetailManagementService/PackageYieldByPlanClasses/GetPackageBandingsBydate/startDate/{startDate}/endDate/{endDate}',
  DeletePackageBanding = 'RetailManagementService/PackageYieldByPlanClasses/deleteBanding/{date}',


  CreateYieldData = 'RetailManagementService/PackageYieldByPlanClasses/multiple',
  CreateYieldClasses = 'RetailManagementService/PackageYieldByPlanClasses/CreateYieldClasses',
  GetYieldData = 'RetailManagementService/PackageYieldByPlanClasses/GetResult',
  DeleteYieldData = 'RetailManagementService/PackageYieldByPlanClasses/{id}',
  DeleteYieldClass = 'RetailManagementService/PackageYieldByPlanClasses/deleteClass/{id}',
  updateYieldData = 'RetailManagementService/PackageYieldByPlanClasses/UpdateItems',
  updateSoldCount = 'RetailManagementService/PackageYieldByPlanClasses/UpdateSoldCount',
  updateYieldClass = 'RetailManagementService/PackageYieldByPlanClasses/UpdateYieldClasses',
  GetYieldClassess = 'RetailManagementService/PackageYieldByPlanClasses/GetYieldClasses',
  GetAllYieldData = 'RetailManagementService/PackageYieldByPlanClasses',

  GetAllTaxExemptCategory = 'RetailManagementService/TaxExemptCategories?includeInactive={includeInactive}',
  GetTaxExemptCategory = 'RetailManagementService/TaxExemptCategories/{id}',
  GetTaxExemptCategoryByCode = 'RetailManagementService/TaxExemptCategories/Code/{code}',
  DeleteTaxExemptCategory = 'RetailManagementService/TaxExemptCategories/{id}',
  CreateTaxExemptCategory = 'RetailManagementService/TaxExemptCategories',
  UpdateTaxExemptCategory = 'RetailManagementService/TaxExemptCategories',
  GetNextListOrder_TaxExemptCategory = 'RetailManagementService/TaxExemptCategories/NextListOrder',

  //Comp Department
  GetAllCompDepartment = 'RetailManagementService/CompDepartments?product={product}&includeInactive={includeInactive}',
  CreateCompDepartment = 'RetailManagementService/CompDepartments',
  UpdateCompDepartment = 'RetailManagementService/CompDepartments',
  GetCompDepartmentById = 'RetailManagementService/CompDepartments/{id}',
  DeleteCompDepartment = 'RetailManagementService/CompDepartments/{id}',
  GetNextListOrder_CompDept = 'RetailManagementService/CompDepartments/nextlistorder',

  //Staff Directory
  GetAllCompStaff = 'RetailManagementService/CompStaffs?includeInactive={includeInactive}',
  GetAllAuthorizer = 'RetailManagementService/CompStaffs/authorizer',
  CreateCompStaff = 'RetailManagementService/CompStaffs',
  UpdateCompStaff = 'RetailManagementService/CompStaffs',
  GetCompStaffById = 'RetailManagementService/CompStaffs/{id}',
  DeleteCompStaff = 'RetailManagementService/CompStaffs/{id}',
  GetNextListOrder_CompStaff = 'RetailManagementService/CompStaffs/nextlistorder',


  //StaffBillingProfile
  GetCompStaffList = 'RetailManagementService/CompStaffs/GetAllCompStaffWithAuthInfo?includeStaffInfo={includeStaffInfo}&includeInactive={includeInactive}',
  GetCompStaffBillingProfileList = 'RetailManagementService/CompStaffBillingProfile/GetAllCompStaffAuthInfoWithBilling',
  CreateCompStaffBilling = 'RetailManagementService/CompStaffBillingProfile',
  UpdateCompStaffBilling = 'RetailManagementService/CompStaffBillingProfile',
  CopyCompStaffBilling = 'RetailManagementService/CompStaffBillingProfile/Copy',
  GetCompStaffBillingDetails = 'RetailManagementService/CompStaffBillingProfile/GetStaffAuthInfoWithBillingsByCompAuthId?compAuthId={compAuthId}',

  //CompAccountingCmsConfiguration
  GetAllCompMasterConfigurations = 'RetailManagementService/CompAccountingConfigurations/GetAllCompMasterConfigurations',
  GetAllCompConfiguration = 'RetailManagementService/CompAccountingConfigurations',
  CreateCompConfiguration = 'RetailManagementService/CompAccountingConfigurations',
  

  //Reservation
  AdvanceSearchReservation = 'ReservationService/Reservation/AdvanceSearch',
  GetPackageReservationForYield = 'ReservationService/Reservation/GetPackageReservationForYield?startDate={startDate}&endDate={endDate}',
  CheckPackageInUseForStayRoomInfo = 'ReservationService/Reservation/IsPackageInUse/{packageId}',

  //Rental Item

  UpdateRentalItem = 'InventoryService/UpdateRentalItem',
  GetRentalItem = 'InventoryService/GetRentalItemsAggregate/sourceType/{sourceType}/sourceTypeId/{sourceTypeId}',
  GetRetailItemDetails = 'retailItems/query',
  GetRentalItemInventoryStatus = 'InventoryService/GetRentalItemInventoryStatus',
  GetRentalItemInventory = 'InventoryService/GetRentalItemInventory',

  // Letter Templates
  GetAllTemplatesByType = 'LetterTemplate?type={type}&includeInactive={includeInactive}',
  GetAllTemplatesForRetailDropdown = 'LetterTemplate/{includeInactive}',
  GetTemplateById = 'LetterTemplate/{type}/Id/{id}/{returnNextListOrder}',
  DeleteTemplate = 'LetterTemplate/{id}',
  CreateTemplate = 'LetterTemplate',
  UpdateTemplate = 'LetterTemplate',
  UpdateListOrder = 'LetterTemplate/UpdateListOrder',
  GetNextListOrder_Template = 'LetterTemplate/NextListOrder/{type}',
  GetTemplateTags = 'Tags/GetByTemplateType?TemplateType={templateType}',
  PrintLetter = 'LetterTemplate/PrintLetter?retailItemId={retailItemId}&templateType={type}&printType={printType}',

  //Property Configuration 
  ApplicationFormatConfiguration = "PropertyConfiguration/configurationName/{configurationName}/productId/{productId}",
  UpdatePropertyConfiguration = 'PropertyConfiguration',

  // Tenant Configuration
  TenantConfiguration = "TenantConfiguration/configurationName/{configurationName}",
  GetTenantConfig = "TenantConfiguration/GetTenantConfiguration",

  CreateTicketInfo = 'RetailManagementService/Adapter/CreateTicket',
  CreateTaxForMultiPackItems = 'RetailManagementService/Adapter/CreateTaxForMultiPackItems',

  //Surcharge Configuration
  GetAllSurchargeConfiguration = "SurchargeConfiguration/GetAllSurchargeConfiguration",
  GetSurchargeConfigurationByPaymentTypeIdList = "SurchargeConfiguration/GetSurchargeConfigurationByPaymentTypeIdList",
  GetSurchargeByTokenTransactionId = "PaymentService/Payment/GetSurchargeByTokenTransactionId",
  GetSurchargeConfigurationByPaymentTypeId = "SurchargeConfiguration/GetSurchargeConfigurationByPaymentTypeId/{paymentTypeId}",
  SaveSurchargeConfiguration = "SurchargeConfiguration/SaveSurchargeConfiguration",
  SaveSurchargeConfigurations = "SurchargeConfiguration/SaveSurchargeConfigurations",
  RemoveSurchargeConfiguration = "SurchargeConfiguration/RemoveSurchargeConfiguration",

  //translog
  CreateTransLog = "Payment/TransLog/transactionlog",

  //AuthCode Configuration
  
  GetPropertyReceiptConfiguration ="RetailManagementService/PropertyReceiptConfiguration",
  UpdatePropertyReceiptConfiguration ="RetailManagementService/PropertyReceiptConfiguration",
  CreatePropertyReceiptConfiguration ="RetailManagementService/PropertyReceiptConfiguration",

  //Post Room/Group Charges
   PostChargeToPMS = "POS/PMSPosting/PostChargeToPMS",
   PostChargeToPMSForResortFinance = "POS/PMSPosting/ResortFinancePostChargeToPMS",
   PMSCommunicatorPostChargeToPMS = "POS/PMSPosting/PostChargeToPMS/Sale",

   PMSCommunicationSenderRoomPost = "PaymentService/PMSPosting/Room/PostCharge",
   PMSCommunicationSenderGroupPost = "PaymentService/PMSPosting/Group/PostCharge",
   PMSCommunicationSenderRoomSale = "PaymentService/PMSPosting/Room/Sale",
   PMSCommunicationSenderGroupSale = "PaymentService/PMSPosting/Group/Sale",
   PMSCommunicationSenderRoomCredit = "PaymentService/PMSPosting/Room/Credit",
   PMSCommunicationSenderGroupCredit = "PaymentService/PMSPosting/Group/Credit",
   PMSCommunicationSenderHotelCompPost = "PaymentService/PMSPosting/PostHotelComp",

  //PM Service PMAgent Calls
  GetAllPayAgent = 'PaymentService/PMAgent/GetAllPayAgent/outletKey/{outletKey}',
  DeletePayAgent = 'PaymentService/PMAgent/DeletePayAgent/payAgentId/{payAgentId}',
  GetPayAgent = 'PaymentService/PMAgent/GetPayAgent/payAgentId/{payAgentId}',
  CreatePayAgent = 'PaymentService/PMAgent/AddPayAgent',
  UpdatePayAgent = 'PaymentService/PMAgent/UpdatePayAgent',
  DownloadPayAgentCertificate = 'PaymentService/PMAgent/DownLoadPMAgentCertificate/payAgentCertificateId/{payAgentCertificateId}',
  ApprovePayAgent = 'PaymentService/PMAgent/ApprovePayAgent/payAgentId/{payAgentId}',

  //PM Service PMSettler Calls
  GetAllSettler  = 'PaymentService/PMSettler/GetAllSettler/outletKey/{outletKey}',
  DeleteSettler  = 'PaymentService/PMSettler/DeleteSettler/SettlerId/{SettlerId}',
  GetSettler = 'PaymentService/PMSettler/GetSettler/settlerId/{settlerId}',
  CreateSettler = 'PaymentService/PMSettler/AddSettler',
  UpdateSettler = 'PaymentService/PMSettler/UpdateSettler',
  DownloadSettlerCertificate = 'PaymentService/PMSettler/DownLoadSettlerCertificate/settlerCertificateId/{settlerCertificateId}',
  ApproveSettler = 'PaymentService/PMSettler/ApproveSettler/settlerId/{settlerId}',
  GetPropertiesForSettler = 'PaymentService/PMSettler/GetPropertiesForSettler/SettlerId/{settlerId}/OutletKey/{outletKey}',
  //PM Service - PMOutlet calls
  GetPMOutlets = 'PaymentService/PMOutlet/GetOutlets',
  GetTenantPropertyOutletInfo = 'PaymentService/PMOutlet/GetTenantPropertyOutletInfo',
  AddPMOutlet = 'PaymentService/PMOutlet/AddOutlet',
  GetTenders = 'PaymentService/PMOutlet/GetTenders?ContextKey={contextKey}',
  GetTendersForPayAgentAndSettler = 'PaymentService/PMOutlet/GetTendersForAgentAndSettler/?ContextKey={contextKey}&OnlyEnabled={OnlyEnabled}',
  AddTenderConfig = 'PaymentService/PMOutlet/AddTenderConfig?ContextKey={contextKey}',
  RefreshConfig = 'PaymentService/PMOutlet/RefreshConfig',
  DeletePMOutlet = 'PaymentService/PMOutlet/DeleteOutlet',
  AddDeviceIdToSesssion = 'PaymentService/Payment/AddDeviceSessionMapping',
  DeleteSessionIdOnLogout = 'PaymentService/Payment/DeleteDeviceSessionMapping',

  GetAllOutlets = 'Outlets',
  //InventorySync Calls
  InventorySync = "RetailManagementService/InventorySync",
  InventoryStatus = "RetailManagementService/InventorySync/GetStatus",
  InventorySyncStatus = "RetailManagementService/InventorySync/GetInventorySyncSummaryRecords",

  CancelInventorySync = "RetailManagementService/InventorySync",
 
  CloseRevenuePostings ='POS/RevenuePosting/CloseRevenuePostings',
  //IntegrationOperationsLog
  CreateIntegrationOperationsLog = "PaymentService/PMAgent/CreateIntegrationOperationsLog",
  UpsertIntegrationOperationsLog = "PaymentService/PMAgent/UpsertIntegrationOperationsLog",

  //PMSLookup
   PMSLookup = "POS/PMSPosting/PMSLookup",
   PMSCommunicationSenderRoomLookUp = "PaymentService/PMSPosting/Room/Lookup",
   PMSCommunicationSenderGroupLookUp = "PaymentService/PMSPosting/Group/Lookup",
   PMSCommunicationSenderGetRooms = "PaymentService/PMSPosting/Room/GetHandles",
   PMSCommunicationSenderGetGroups = "PaymentService/PMSPosting/Group/GetHandles",
   PMSCommunicationSenderGetHotelComp = "PaymentService/PMSPosting/GetHotelComp",

  //City Ledger LookUp With Tender
  ARAccountLookupWithTenderId = "PaymentService/AccountsReceivable/LookupWithTender/ByName/{byname}/TenderId/{tenderId}?profitCenter={profitCenter}" ,
  //Signature Capture
  ReportString = "report/ReportString",
  AddTransactionSignature = "PointOfSaleService/TransactionSignature/AddTransactionSignature/{TransactionId}",
  UpdateTransactionSignature = "PointOfSaleService/TransactionSignature/UpdateTransactionSignature/{TransactionId}",
  PostReceiptToDM = "PointOfSaleService/DM/PostReceiptToDM/{TransactionId}",
  RetryPostReceiptToDM = "PointOfSaleService/DM/RetryPost",
  GetFailedDMPosting = "PointOfSaleService/DM/GetFailedDMPostingLog",
  CheckBatchProcess = "PointOfSaleService/DM/IsBatchProcessingPending",
  CloseFailedDMPosting = "PointOfSaleService/DM/CloseFailedDMPosting",
  FailedDMPostingCount = "PointOfSaleService/DM/GetFailedDMPostingCount",
  
  //ResortFinance PostType Mapping Routes
  GetPostTypeDetailsForResortFinance = "RetailManagementService/RevenuePosting/GetPostTypeMappingDetailsByPostingType/{postingType}",
    
  //General Ledger Mapping Routes
  GetMappingDetailsForGeneralLedger = "RetailManagementService/GeneralLedger/GetGeneralLedgerMappingDetails",
  AddUpdateGeneralLedgerMappingDetails = "RetailManagementService/GeneralLedger/AddUpdateGeneralLedgerMappingDetails",
  RemoveGeneralLedgerMappingDetails = "RetailManagementService/GeneralLedger/RemoveGeneralLedgerMappingDetails",

  //MultiProperty PostType Mapping Routes
  GetPostTypesByPropCode = "RetailManagementService/RevenuePosting/GetPostTypesByPropCode/propCode/{propCode}",
  GetMultiPropertyRevenuePostings = "RetailManagementService/RevenuePosting/GetPostTypeMappingDetails/propCode/{propCode}",
  SyncMultiPropertyPosting = "RetailManagementService/RevenuePosting/SyncMultiPropertyPMSPostTypes/propCode/{propCode}",
  //Orphan Payments
  GetOrphanPaymentRecords = "PaymentService/PaymentTransaction/GetOrphanPaymentRecords/OrphanPayments",
  CheckPMSCommunication = "RetailManagementService/PMSIntegrationHostConfiguration/CheckPMSCommunication",
  GetAllPMSIntegrationHostConfigurations = "RetailManagementService/PMSIntegrationHostConfiguration/GetAllPMSIntegrationHostConfigurations",
  SavePMSIntegrationHostConfiguration = "RetailManagementService/PMSIntegrationHostConfiguration/SavePMSIntegrationHostConfiguration",

  //Clients
  GetClientInfobyGuid = "Clients/guid/{guid}",

  //Transaction Email
  AddTransactionEmail = "PointOfSaleService/transaction/AddTransactionEmails/{transactionId}",
  GetTransactionEmails = "PointOfSaleService/Transaction/GetEmailsByTransactionId/{transactionId}",
  UpdateEmailsByTransactionId = "PointOfSaleService/Transaction/UpdateEmailsByTransactionId/{transactionId}",
  
  GetARAccountByFilter = 'AccountingService/ARCustomer/GetAccountsByFilter?searchText={searchText}&searchBy={searchBy}',
  GetSupportedPMAgentVersionByProperty= "Payment/GetPMAgentVersion/{propertyId}",
  
  // Payment API Verion 1.0 Routes
  RetailStoreToken = "Payment/StoreToken",
  FolioStoreToken = "FolioService/Payment/StoreToken",
  RetailSaleByToken = "Payment/SaleByToken/outletId/{outletId}",
  FolioSaleByToken = "FolioService/Payment/SaleByToken/outletId/{outletId}",
  RetailCreditByToken = "Payment/CreditByToken/outletId/{outletId}",
  FolioCreditByToken = "FolioService/Payment/CreditByToken/outletId/{outletId}",
  RetailValidatePay = "Payment/ValidatePay/outletId/{outletId}",
  FolioValidatePay = "FolioService/Payment/ValidatePay/outletId/{outletId}",
  RetailValidateGiftCard = "Payment/ValidateGiftCard/outletId/{outletId}",
  FolioValidateGiftCard = "FolioService/Payment/ValidateGiftCard/outletId/{outletId}",
  RetailValidateAuth = "Payment/ValidateAuth/outletId/{outletId}",
  FolioValidateAuth = "FolioService/Payment/ValidateAuth/outletId/{outletId}",
  RetailAuthByToken = "Payment/AuthByToken/outletId/{outletId}",
  FolioAuthByToken = "FolioService/Payment/AuthByToken/outletId/{outletId}",
  RetailGetCardInfo = 'Payment/GetCardInfoByTransaction/{tokenTransId}',
  FolioGetCardInfo = 'FolioService/Payment/GetCardInfoByTransaction/{tokenTransId}',
  GetResortFinanceFolioCheckZoomHtml = "FolioService/v2/PostFolio/GetFolioCheckZoomHtml",

  GetLinkedTaxDetailsById = "TaxConfiguration/GetLinkedTaxDetailsById/{id}",

  //Payment API 2.0 Routes
  BuildRGuestPayRequest = "PaymentService/V2/Payment/BuildRGuestPayRequest",
  ValidatePayV2 = "PaymentService/V2/Payment/ValidatePay/outletId/{outletId}",
  ValidateAuthV2 = "PaymentService/V2/Payment/ValidateAuth/outletId/{outletId}",
  RetailStoreTokenV2 = "PaymentService/V2/Payment/StoreToken",
  FolioStoreTokenV2 = "FolioService/V2/Payment/StoreToken",
  FolioValidateAuthV2 = "FolioService/V2/Payment/ValidateAuth/outletId/{outletId}",
  FolioValidatePayV2 = "FolioService/V2/Payment/ValidatePay/outletId/{outletId}",

  //Giftcard revamp routes
  GetGiftcardBalance = "PaymentService/Giftcard/GetBalance",
  RedeemGiftcard = "PaymentService/Giftcard/Sale",
  GiftcardCredit = "PaymentService/Giftcard/Credit",
  IssueGiftcard = "PaymentService/Giftcard/Issue",
  LoadGiftcard = "PaymentService/Giftcard/Load",
  GiftcardCashOut = "PaymentService/Giftcard/CashOut",
  GiftcardCashback = "PaymentService/Giftcard/CashBack",
  GiftcardAdvancedSearch = "PaymentService/Giftcard/SearchByFilters",

  // Wallet Routes
  RedeemWallet = "WalletPayment/RedeemWallet",
  VoidWallet = "Transaction/{transactionId}/VoidWalletPayments",

  //LinkedRetailItems
  CreateLinkedRetailItemsData = "PointOfSaleService/LinkedRetailItems",
  UpdateLinkedRetailItems = "PointOfSaleService/LinkedRetailItems/UpdateLinkedRetailItems/sourceType/{sourceType}/sourceTypeId/{sourceTypeId}",

  GetRetailItemByItemTypeForLookup = "retailitems/typeForLookup/{type}",

  //Discount
  GetActiveDiscountTypesByRules ="DiscountTypes/activeDiscountTypes",

  //CCTerminal

  IsDefaultCCTerminalExists = "CreditCardTerminals/IsDefaultCCTerminalExists",
  GetDefaultCCTerminal = "CreditCardTerminals/GetDefaultCCTerminal",
  GetActiveFolioInvoiceNumberForPayee = 'PaymentService/FolioPosting/GetCurrentFolioInvoiceNumber?sourceType={sourceType}&sourceTypeId={sourceTypeId}',

  //Appointment
  GetAppointmentByTransactionId = "Schedule/appointments/GetAppointmentsByTransactionId/transaction/{transactionId}",

  //Member
  MemberPayment = "Membership/MemberPayment",
  RedeemPoint = "Membership/RedeemPoint",
  GetMemberInfoByCardNo = "Membership/GetMemberByCardNo/{cardNo}/{scheduleDateTime}?corpId={corpId}",
  //FolioMember
  FolioMemberPayment = "FolioService/Membership/MemberPayment",
  FolioRedeemPoint = "FolioService/Membership/RedeemPoint",
  FolioGetMemberInfoByCardNo = "FolioService/Membership/GetMemberByCardNo/{cardNo}/{scheduleDateTime}?IsResortFinancePaymentFlow={isResortFinancePaymentFlow}",
  FolioGetMemberBucketMappingsDetails = "RetailManagement/FolioService/MemberBucket/GetMemberBucketMappingsDetails",
  FolioCreateMemberBucketMapping = "RetailManagement/FolioService/MemberBucket/AddMemberBucketMappingDetails",
  FolioDeleteMemberBucketMapping = "RetailManagement/FolioService/MemberBucket/RemoveMemberBucketMappingDetails",
  FolioGetAllCreditCardIssuerType =  "FolioService/Payment/GetAllCreditCardIssuerType",
  FolioGetAllPaymentMethod  = "FolioService/PaymentMethods?includeInactive={includeInactive}",

  // AutoRetailTransaction 
  AutoRetailTransaction = 'RetailInterface/InitiateAutoRetailTransaction',
  //POS V2
  VoidTransactionV2 = "PointOfSaleService/V2/transaction/voidtransaction/void",
  GetResortFinanceCardTokenReference = "PaymentService/Payment/GetResortFinanceCardTokenReference/guestCardTokenReferenceId/{guestCardTokenReferenceId}",
  RefundFailedPayment = "PointOfSaleService/V2/transaction/RefundFailedPayment/{transactionId}",
  SearchFailedRefund = "PointOfSaleService/V2/transaction/SearchFailedRefund/SearchRefundFailure",

  //Recalculcate Ticket
  RecalculateTicketWithTaxExempt = "RetailManagementService/Adapter/CalculateTicketForPaymentMethodTaxExempt",

  //ProxyCall
  ProxyCallToRGuestPay = "PaymentService/V2/Payment/ProxyCallToRGuestPay?ProxyPaymentMethodId={proxyPaymentMethod}",

  //GetSiteId
  GetSiteId = "inventory/api/GetSiteId",

  //EatecAsMaster
  GetInventoryMappingItems = "RetailManagementService/InventoryItemMapping/GetInventoryMappingItems?includeProcessed={includeProcessed}",
  InventoryItemMapping = "RetailManagementService/InventoryItemMapping",
  GetSaleDepletionLogs = "PointOfSaleService/SaleDepletionLog/GetSaleDepletionLogs",
  RetrySaleDepletion = "PointOfSaleService/SaleDepletionLog/RetrySaleDepletion",
  CancelSaleDepletionLogs = "PointOfSaleService/SaleDepletionLog/CancelSaleDepletionLogs",
  SaleDepletionBatchProcess = "PointOfSaleService/SaleDepletionLog/IsBatchProcessingPending",
  GetCategoriesAndTaxesByTenant = "RetailManagementService/InventoryItemMapping/InventoryItemMasterData",

  //Retail MultiPack
  GetMultiPacks = "PointOfSaleService/MultiPack/getMultipleItemMultipacks",
  RedeemMultiPacks = "PointOfSaleService/MultiPack/redeemMultiPack",
  ClientMultiPacksByTransactionIds = "PointOfSaleService/MultiPack/ClientMultiPacksByTransactionIds"
}

export enum FolioRoutes {
  CreateFolioDetails = "FolioService/FolioDetails",
  GetFolioDetailsBySourceTypeAndSourceTypeId = 'FolioService/FolioDetails/SourceType/{sourceType}/SourceTypeId/{id}',
  CreateFolio = "FolioService/V2/PostFolio/CreateFolio",
  CreateDefaultFolio = "FolioService/V2/PostFolio/CreateDefaultFolio",
  GetAllPostTypes = "FolioService/PostTypes",
  FolioLookup = "FolioService/V2/PostFolio/FolioLookup",
  FolioLookupByGuestDetails= "FolioService/V2/PostFolio/FolioLookupByGuest",
  GetAllCreditCardTerminal = "CreditCardTerminals/includeInactive/{includeInactive}",
  GetOutletsByProperty= "Outlets",
  GetOutletsCCTerminalAggregate = 'CreditCardTerminals/outlet-creditcard-info/{propertyId}',
  ViewReservation = 'ReservationService/Reservation/StaySummary?stayId={stayId}&isVatEnabled={isVatEnabled}',
  IsGroupMasterStayCheckedIn = 'ReservationService/GroupMaster/IsGroupMasterStayCheckedIn?bookingId={bookingId}',
  GetCheckDetailsByPosTransactionId = 'InternalIntegrationWrapper/FolioService/CheckZoom/GetCheckZoomByPosTransactionId?posTransactionId={posTransactionId}',
  GetFolioSettingByScreen = 'FolioService/Setting/{module}/{screen}',
  saveResortFinanceFolioPostDetail = "FolioService/ResortFinanceFolioPostDetail/SaveResortFinanceFolioPostDetail",
}


export enum MsGraphRoutes {
  Me = '/me',
  MeMessages = '/me/messages',
  MeEvents = '/me/events',
  MeTasks = '/me/outlook/tasks',
  MeContacts = '/me/contacts?$filter=createdDateTime ge {lastSyncTime} or lastModifiedDateTime ge {lastSyncTime} &$top=100',
  // MeContacts='/me/contacts?$top=100',
  MeDeltaContacts = '/me/contacts/delta',
  MeUpdateContacts = '/me/contacts',
  MeSendMail = '/me/sendMail',
  Batch = '/$batch'
}

export enum GoogleApiRoutes {
  MapApi = 'https://maps.google.com/maps/api/js?sensor=true&key={apiKey}&libraries=places&language={language}'
}

export enum PropertyApiRoutes {
  changePropertyNewTab = '{hostName}/setProperty?token={token}&propertyId={propertyId}&propertyName={propertyName}&routeParam={routeParam}',
  changePropertySameTab = '/setProperty'

}

export enum OutlookRoutes {
  GetAllTask = 'me/outlook/tasks',
  CreateTask = 'me/outlook/tasks',
  updateTask = 'me/outlook/tasks/{id}',
}
