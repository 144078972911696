import { ValidatorFn } from '@angular/forms';
import { buttonTypes, ReservationStatus } from '@constants/commonenums';
import { Many } from 'lodash';


export interface FieldConfig {
  disabled?: boolean;
  buttonType?: string;
  label?: string;
  name: string;
  options?: any[];
  autoOptions?: any[];
  placeholder?: string;
  type: string;
  validation?: ValidatorFn[];
  value?: any;
  class?: any;
  containerClass?: string;
  changeEvent?: any;
  hide?: boolean;
  showErrorText?: boolean;
  maxDate?: any;
  minDate?: any;
  autocomplete?: string;
  autocompleteOptions?: any[];
  inputType?: string;
  maxLengthHint?: boolean;
  maxLengthCount?: number;
  maxLengthText?: string;
  function?: any;
  panelClass?: string;
  allowDuplicate?: boolean;
  iClass?: string;
  hintText?: string;
  hintRight?: boolean;
  hint?: boolean;
  directive?: any;
  prefix?: string;
  inputClassName?: any;
  returnFunction?: (event,index) => void;
  cellClick?: (event, ctrlName?) => void;
  blurClick?: (event) => void;
  onClick?: (event) => void;
  hyperLinkClicked?: (event) => void;
  icon?: string;
  icon1?: string;
  charLength?: number;
  showHint?: boolean;
  showNumbers?: boolean;
  appearance?: boolean;
  showNavigation?: boolean;
  navigationIncrementer?: number;
  multipleChip?: boolean;
  addBtnConfig?: FieldConfig;
  errorMessage?: string;
  isTranslate?: boolean;
  isPhoneNumber?: boolean;
  id?: number;
  isDateTime?: boolean;
  checked?: boolean;
  min?: number;
  max?: number;
  step?: number;
  pattern?: any;
  formInputIcon?: string;
  showIconAlways?: boolean;
  partySize?: boolean;
  isTooltip?: boolean;
  isNewPhoneNumber?: boolean;
  isConciergeNumber?: boolean;
  onfocusOutClick?: (event, ctrlName?) => void;
  multiSelectvalue?: any[];
  rows?: number;
  textAreaAppearance?: any;
  isHidden?: boolean;
  showColor?:boolean;
  isReadOnly?: boolean;
  index?:Number;
  title?: string;
  autoCompleteWithId?:boolean;
  showRemove?: boolean;
  selectMultipleOptions?: boolean;
  disableErrorStateMatcher?: boolean;
  numberType?:string;
  selectAscendingSortOrder?: boolean;
  isStarSymbolRequired?: boolean;
  groups?: {
    name: string,
    options: {
      id: number, value: string, masterId: number
    }[]
  }[]
  isChipsEnabled?: boolean;
  multiChipValues?:any;
  orderFields?: {fields: string[], orders: Many<'asc' | 'desc'>};
  toolTipEnabled?: boolean;
  isRemoved?: boolean;
  addHyperlink?: boolean;
  hyperLinkText?: string;
  AllowEditForPastReservationsInDays?: number;
  highlightRequired?: boolean;
  enableClearDate?: boolean
}

export interface DropDownConfig {
  id: any;
  value: string | number;
  isDefault?: true;
}[];

export interface ButtonValue {
  label?: any;
  type: buttonTypes;
  disbaledproperity?: any;
  customclass?: string;
  icon?: string;
  actionName?: ReservationStatus;
  additionalText?: string;
  customStyle?: string;
  printId?: string;
  printStyle?: string;
  printerType?: string;
  menu?: any[];
  name?: string;
  id?:number;

}

export enum IconSizes{
  Large = 1, 
  Medium = 2,
  Small = 3
}
