import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Validators } from '@angular/forms';
import { CacheService } from '@app/core/services/cache.service';
import { PopupService } from '@app/popup-module/popup.service';
import { RejectStandByType } from '@app/shared/constants/commonenums';
import { RejectReasonTag } from '@app/shared/constants/globalConstants';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-reject-stanby',
  templateUrl: './reject-stanby.component.html',
  styleUrls: ['./reject-stanby.component.scss']
})
export class RejectStanbyComponent implements OnInit {
  reasonConfig: FieldConfig[];
  reasonTextConfig: FieldConfig[];
  subscriptions: Subscription = new Subscription();
  settings: any;
  RejectReasonList: any = [];
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;

  constructor(public ts: TranslateService, public ps: PopupService, public partyService: PartyService,
    public cs: CacheService) {
    this.subscriptions.add(this.cs.settings.subscribe(data => {
      this.settings = data;
      const reasonlist = this.settings.Categories.filter(x => x.Text === RejectReasonTag)[0];
      reasonlist?.SubCategories.filter(y => y.Text === RejectReasonTag).forEach(element => {
        this.RejectReasonList.push(
          element.PredefinedPartyNote.map(({Text}) => {
            return {id: Text, value: Text}
          })
        );
      });
    }));
    this.RejectReasonList = this.RejectReasonList.flat();
    this.reasonConfig = [{
      type: 'radio',
      name: 'ReasonForReject',
      placeholder: 'Select Reason Type',
      options: [{ id: RejectStandByType.Predefined, value: this.ts.instant('predefinedReason') }, { id: RejectStandByType.Custom, value: this.ts.instant('customReason') }],
      value: RejectStandByType.Predefined,
      class: 'cancel-activity-popup__cancel',
      appearance: true,
      showErrorText: true
    }];
    this.reasonTextConfig = [{
      type: 'select',
      label: 'ModifyReason',
      name: 'reasonText',
      value: this.RejectReasonList.length ? this.RejectReasonList[0].id : null,
      options: this.RejectReasonList,
      class: 'reject-options__input',
      showErrorText: true,
      isTranslate: false,
      validation: [Validators.required]
    }];

    this.partyService.ReasonForReject = this.RejectReasonList.length ? this.RejectReasonList[0].id: null;
    this.ps.saveBtnEnable$.next( !!this.RejectReasonList.length);
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.formChangeSubscribe();
    // }, 1000);
  }

  ngAfterViewInit(): void {
    this.formChangeSubscribe();
  }

  formChangeSubscribe() {
    this.components.first.form.valueChanges.subscribe(data => {
      if (data.ReasonForReject === 2) {
        this.reasonTextConfig[0] = {
          name: 'reasonText',
          type: 'textarea',
          rows: 2,
          textAreaAppearance: 'outline',
          class: 'reject-options__input',
          value: '...',
          validation: [Validators.required]
        }
        this.components.last.form.controls.reasonText.setValue('');
        this.ps.saveBtnEnable$.next(false);
      } else if (data.ReasonForReject === 1) {
        this.reasonTextConfig[0] = {
          type: 'select',
          label: 'ModifyReason',
          name: 'reasonText',
          value: this.RejectReasonList.length ? this.RejectReasonList[0].id : null,
          options: this.RejectReasonList,
          class: 'reject-options__input',
          showErrorText: true,
          isTranslate: false,
          validation: [Validators.required]
        }
        this.components.last.form.controls.reasonText.setValue(this.RejectReasonList.length ? this.RejectReasonList[0].id : null);
        this.ps.saveBtnEnable$.next( !!this.RejectReasonList.length);

      }
    });
    this.components.last.form.valueChanges.pipe(debounceTime(100),
      distinctUntilChanged()).subscribe(data => {
        if (data.reasonText) {
          this.partyService.ReasonForReject = data.reasonText;
          this.ps.saveBtnEnable$.next(true);
        } else {
          this.ps.saveBtnEnable$.next(false);
        }
      });

  }

}
