import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { DEFAULT_LANGUAGE_CODE, DEFAULT_LANGUAGE_ID } from '../constants/globalConstants';
import { AppService } from '@app/app.service';
import { DashboardFunctions } from '../utilities/dashboard-functions';

@Pipe({
  name: 'formatSettingsDate'
})
export class FormatSettingsDatePipe implements PipeTransform {
  constructor(private as: AppService,private dashboard: DashboardFunctions) {
  }

  transform(value: string | Date, format: string,DATE_MONTH?:string) {
    let localMoment = moment(new Date(value));
    let localeValue = this.dashboard.getLocaleCode();
    if(DATE_MONTH){
      let date_monthFormat;
      if(format.indexOf('YYYY') == 0){
        date_monthFormat =  (format).replace((format).substring(0,5),"")
      }else{
        let index = format.indexOf('YYYY')
        date_monthFormat = (format).replace((format).substring(index-1,index+4),"")
      }
     return localMoment?.locale(localeValue).format(date_monthFormat.toUpperCase());
      
    }else{
      return localMoment?.locale(localeValue).format(format.toUpperCase());
    }
  }

}
