<section class="original-settlement-info">
    <div class="subhdr-msg">{{ShopCaptions.ReturnToOriginalSettlementInfoDialog.SubHeader}}</div>
    <ng-container *ngIf="settlementHistory?.length > 0">
        <ul>
            <li *ngFor="let settlement of settlementHistory">
                <div class="settlement-row">
                    <div *ngIf="settlement.paymentReferenceComment" class="cash-label">{{settlement.paymentMethod}} | {{ settlement.paymentReferenceComment}}</div>
                    <div *ngIf="!settlement.paymentReferenceComment" class="cash-label">{{settlement.paymentMethod}}</div>
                    <div>{{settlement.amount | Currency}}</div>
                </div>
            </li>
        </ul>
    </ng-container>
    <div class="payment-footer">
        <button [attr.automationId]="'Btn_shopReturnSettlementInfo_ok'" mat-button class="mat-raised-button button--primary-save whitecolor LW14 ml-3 text-capital"
            (click)="closeDialog('ok')">{{localization.captions.common.OK}}</button>
        <button [attr.automationId]="'Btn_shopReturnSettlementInfo_cancel'" mat-button class="mat-raised-button button--primary-save whitecolor LW14 ml-3 text-capital"
            (click)="closeDialog('cancel')">{{localization.captions.common.Cancel}}</button>
    </div>
</section>