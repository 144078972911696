import { Pipe, PipeTransform } from '@angular/core';
import { PartyState } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { environment } from '@environments/environment';
import { SettingsDTO } from '@models/RestaurantDTO';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Pipe({ name: 'CancelorNoShowAction' })

export class CancelorNoShowActionPipe implements PipeTransform {
  _settings: SettingsDTO = {} as SettingsDTO;
  _restaurantTime: any;
  subscriptions: Subscription = new Subscription();
  constructor(public cs: CacheService) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
    this._restaurantTime = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
  }
  transform(DateTime: any, State: PartyState) {
    if (State == PartyState.Left) {
      return false;
    }
    var maxCancellableTime = moment(this._restaurantTime._d.setHours(0, 0, 0, 0))
    var minCancellableTime = moment(this._restaurantTime._d.setHours(0, 0, 0, 0)).subtract({ hours: environment.MaxTimeBackToNoShowOrCancelReservation });
    var readOnlyModeLimit = moment(this._restaurantTime._d.setHours(0, 0, 0, 0)).subtract({ hours: environment.MaxMinutesBackToEditOrSeatReservation });
    if (!(Utilities.parseDateString(DateTime) < new Date(readOnlyModeLimit.valueOf())))
      return true;
    else
      return ((new Date(minCancellableTime.valueOf()) <= Utilities.parseDateString(DateTime) && Utilities.parseDateString(DateTime) <= new Date(maxCancellableTime.valueOf())) && State == PartyState.Pending);
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
