import { UntypedFormGroup } from '@angular/forms';
import { GiftCardShopDetails } from '../shop/shop.modals';
import { ReturnWithTicketType } from './service/common-variables.service';
import { ReportAPIModel } from '../retail-reports/business/report.modals';
import { LetterTemplateType } from '../retail.modals';
import { TransactionInfoforCheckZoom } from './service/payment/payment-business.model';
import { RetailInterfaceSourceTypes } from './globalsContant';
import { ActivitySelectedProducts } from '@app/shared/services/retail/activities-retail-common';


export interface CommissionDetails {

  id?: number;
  itemId: number;
  categoryId: number;
  itemDescription: string;
  locationDescription: string;
  classId: number;
  flatAmount: any;
  percentage: any;
  threshold: any;
  isAfterDiscount: boolean;
  isTotalRevenue: boolean;
  isItemOnly: boolean;
  productId: number;
  outletId: number;
}


export interface CommissionDetailsUI {
  id?: number;
  itemId: number;
  categoryId: number;
  itemDescription: string;
  locationDescription: string;
  classId: number;
  flatAmount: any;
  percentage: any;
  threshold: any;
  isAfterDiscount: boolean;
  isTotalRevenue: boolean;
  isItemOnly: boolean;
  commissionon: string;
  outletId: number;
}

export interface RetailFunctionality {
  id: number;
  functionality: string;
  productId: number;
  isActive: boolean;
}

export interface IntegrationLog {
  id: number;
  Route: string;
  ScreenRoute: string;
  Request: string;
  Response: string;
  MachineId: number;
  MachineName: string;
  CorrelationId: string;
}

export interface popupConfig {
  operation: string;
  maxListOrder?: number;
  GridOperationType?: GridOperationType;
  fromScreen?: string;
}

export interface BaseResponse<T> {
  result: T;
  errorCode: number;
  errorDescription: string;
  successStatus: boolean;
  propertyId: number;
  outletId: number;
}

export enum GridOperationType {
  Create = 1,
  Edit,
  Delete,
  ListOrderDrag
}

export const enum SettingModule {
  SystemSetup = 'SYSTEMSETUP'
}

export const enum SettingScreen {
  TeeTime = 'TEETIMESETTING'
}

export namespace API {

  export interface Player {
    id: number;
    firstName: string;
    lastName: string;
    pronounced: string;
    customField1: string;
    customField2: string;
    customField3: string;
    customField4: string;
    customField5: string;
    playerCategoryId: number;
    playerAddress: PlayerAddress;
    contactInformation: ContactDetails[];
    paymentReferenceId: number;
    playerLinkId: string;
    confirmationNumber: string;
    guestId: string;
    bagNumber?: string;

  }
  export interface PlayerAddress {
    playerId: number;
    addressLine1: string;
  addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    country: string;
    zip: string;
  }

  export interface PlayerProfile {
    id: number;
    firstName: string;
    lastName: string;
    pronounce: string;
    playerProfileAddress: PlayerProfileAddress;
    playerProfileContactDetail: PlayerProfileContactDetails[];
    title: string;
    dateOfBirth: Date;

  }
  export interface PlayerProfileAddress {
    playerId: number;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    isPrivate: boolean;
  }

  export interface PlayerProfileContactDetails {
    playerId: number;
    type: number;
    value: string;
    name: string;
    isPrivate: boolean;
    isPrimary: boolean;
  }
}
export interface ContactDetails {
  id: number;
  type: number;
  value: string;
  isPrimary: boolean;
  isPrivateInfo: boolean;
  name: string;
  extension: string;
}

export namespace Tournament.UI {
  export interface TournamentInformation {
    id: number;
    allocationCode: number;
    cartFee: number;
    comments: string;
    contestPackage: number;
    course: number;
    entryFee: number;
    eventCode: string;
    eventName: string;
    eventStatus: string;
    foodAndBeveragePackage: number;
    greensFee: number;
    menTee: number;
    noOfPlayers: number;
    playFormat: number;
    playerType: number;
    playersPerGroup: number;
    rateType: number;
    selectDate: Date;
    selectTime: string;
    signUpFrom: Date;
    signUpTo: Date;
    womenTee: number;
    contactPlayerId: number;
  }


  export interface PlayerAddress {
    playerId: number;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    country: string;
    zip: string;
  }

  export interface ContactDetail {
    phoneType: any;
    playerId: number;
    contactTypeId: number;
    value: string;
  }

  export interface ContactInformation {
    id: number;
    firstName: string;
    lastName: string;
    pronounced: string;
    customField1: string;
    customField2: string;
    customField3: string;
    customField4: string;
    customField5: string;
    playerCategoryId: number;
    playerCategory: string;
    playerAddress: PlayerAddress;
    contactInformation: ContactDetails[];
    paymentReferenceId: number;
    playerLinkId: string;
    confirmationNumber?: string;
    balanceList?: BalanceList[];
    golfPoints?: GolfPoints[];
    cardStatus?: string,
    bagNumber?: string;
    playerType?: any;
    rateType?: any;
    isRoundRedeem?: boolean;
    membershipStatus?: string;
    photoUrl?:string;
    discountType?: string;
  }

  export interface GolfPoints {
    totalRounds: number;
    eligibleRounds: number;
    bucketName: string;
    bucketCode: string;
  }

  export interface BalanceList {
    AccrualPoint: number;
    AccrualCash: number;
    BucketName: string;
    BucketCode: string;
  }

  export interface Tournament {
    TournamentInformation: TournamentInformation;
    contact: ContactInformation;
  }

  export interface TournamentDetails {
    tournamentId: number
    eventDate: string;
    eventCode: string;
    eventName: string;
    course: string;
    allocationCode: string;
    tournamentStatus: string;
    eventOriginalDate: Date;
  }

  export class SearchFilter {
    FromDate: Date;
    ToDate: Date
  }

  export interface SearchData {
    TournamentId: number;
  }
}

export enum SearchType {
  Guest = 1,
  Player,
  GuestAndPlayer,
  GuestAndConfNo,
  ConfNo,
  Member
}

export interface SelectedProducts extends ActivitySelectedProducts{
  ItemId: number,
  ExternalPOSItemId: number,
  ItemDescription: string,
  ItemNumber?: string,
  ItemType?: number,
  ServiceId: number,
  SalesPrice: number,
  isEditDisabled?: boolean,
  isModificationRestricted?: boolean,
  ProductName: string,
  ProductPrice: number,
  Noofitems: number,
  Discount: number,
  DiscountTypeId: number,
  DiscountPercentage: number,
  category: number;
  Commission?: Commission[],
  Gratuity?: Gratuity[],
  ServiceCharge?: ServiceCharge[],
  isCommissionable?: boolean,
  isSettleTicket?: boolean,
  isGroupingKey: boolean,
  isPackagedItem: boolean,
  PackageItemId: number,
  MultiPack: boolean,
  ClientMultiPackId: number,
  PackageGroupId: number,
  LineNumber?: number;
  Tax?: number,
  LinkedTax?: number,
  BaseTax?: number,
  Vat?:number,
  isOpenPricedItem: boolean,
  transactionDetailLinkId?: number,
  id?: number,
  charges?: any,
  StaffTransactionDetail?: StaffTransactionDetail[]
  MaxTherapistForCommission?: number;
  CheckOutAppointmentId?: number;
  IsAppointmentNotCheckedOut?: boolean;
  addOnId?: number;
  scaledUnits?: number;
  unitOfMeasureId?: number;
  uom?: string;
  isReturn?: boolean;
  isTaxExempt?: boolean;
  retailItemType?: RetailItemType;
  payeeId?: number,
  playerName?: string;
  multipackExternalPOSItemId?: number;
  isIncludedInRound?: boolean;
  costPrice: number;
  marginPercentage: number;
  isRequireComments?: boolean;
  itemComments?: string;
  isRequestName?: boolean;
  isDepositOnly?: boolean;
  clientName?: string;
  clientId?: number;
  GiftCardTransactionItem?: GiftCardShopDetails;
  isGiftCardItem?: boolean;
  allowEarn: boolean;
  linkedRetailItemGuid?: string;
  returnType?: ReturnWithTicketType;
  isServiceChargeReturn?: boolean;
  isGratuityReturn?: boolean;
  returnLinkId?: number;
  discountComments?: string;
  discountReason?: number;
  isPartialReturn?: boolean;
  GroupingParentId?: number;
  GroupingUniqueIdentifier?: string;
  GroupingItemDescription?: string;
  netUnitPrice?: number;
  netPrice?: number;
  netUnitPriceWithoutDiscount?:number;
  totalAmount?: number;
  productDetails?: ResvRetailShopProductDetail[];
  multiPackTransactionDetailId?: number;
  selected?: boolean;
  roomIndex?: number;
  clientType?: number;
  clientLinkId?: string;
}

export interface ResvRetailShopProductDetail {
  startDate: Date | string;
  endDate: Date | string;
  quantity: number;
  hours: number;
  price: number;
  total: number;
  rentType: boolean;
  rentTypeLabel: string;
}

export interface Commission {
  id: any,
  StaffId: number,
  StaffType: string,
  Quantity?: number // for multi therapist commission for same item(not split commission)
  TemplateId?: number;
  SplitLevel?: number;
  SplitPercentage?: number;
}

export interface StaffTransactionDetail {
  StaffId: number
  StaffType: string
}

export enum RetailItemType {
  RetailItemRetailPOSOnly = 1,
  RetailItemAvailableForSpaPackages,
  SpaServices,
  SpaPackage,
  AppointmentAddon,
  Deposit,
  RentalItem,
  InternalGiftCard,
  ExternalGiftCard,
  RevenueItem,
  CustomFee
}

export interface Gratuity {
  Id: any,
  TransactionDetailId: number,
  TherapistId: any,
  Percentage: number,
  PercentageId: number,
  PercOrAmount: number; //Percentage - 1 , Amount - 2
  Amount: number;
  gratuity: number;
  StaffType?: any;
  GratuityTax?: number;
}

export interface ServiceCharge {
  Id: any,
  TransactionDetailId: number,
  TherapistId: any,
  Percentage: number,
  PercentageId: number,
  PercOrAmount: number
  Amount: number;
  ServiceCharge: number;
  StaffType?: any;
  ServiceChargeTax?: number;
}
export interface ScheduledTeeTimeUnPaidPlayer {
  id?: number;
  scheduledTeeTimeId: number;
  playerId: number;
  playerName: string;
  playerType: string;
  playerSlotPosition: number;
  scheduledDateTime: string;
  scheduledAPIDateTime?: string;
  course: string;
  rateTypeId: number;
  rateType: string;
  packageCode: string;
  walk: string;
  trail: string;
  holes: number;
  greenFee: number;
  cartFee: number;
  entryFee: number;
  cartFeeRetailItemId: number;
  greenFeeRetailItemId: number;
  entryFeeRetailItemId: number;
  tournamentId: number;
  otherItems: TournamentPackageItem[];
  cartFeeRetailItem: DomainRetailItem;
  greenFeeRetailItem: DomainRetailItem;
  entryFeeRetailItem: DomainRetailItem;
  deposits: Deposit[];
  checked?: boolean;
  isTournamentPlayersUnPaid?: boolean;
  ticketNumber?: string;
  transactionId?: number;
  playerCategoryId?: number;
  playerLinkId?: string;
  firstName?: string;
  lastName?: string;
  playerPosition?: number;
  teeTimeFormat?: number;
  confirmationNumber?: string;
  blockByUser?: string;
  isHold?: boolean;
  isReleaseAllow?: boolean;
  isNotDepositWithPendingSettlement?: boolean;
  multiPackTransactionDetailId?: number;
  multiPackRetailItem?: DomainRetailItem[];
  playerStatus?: PlayerPaymentstatus;
  greenFeeTax: number;
  cartFeeTax: number;
  entryFeeTax: number;
  corpId?: string;
  corpName?: string;
  courseListOrder?: number;
  courseId?: number;
  discountType?: string;
}

export interface ACESItemInfo {
  ItemGroupName: string,
  ItemGroupID?: number,
  ItemPrice?: number,
  Quantity?: number
}


export interface ACESCheckInfo {
  CheckNumber: string,
  AssociatedCheckNumber?: string,
  TicketNumber?: string,
  PaymentAmount: number,
  TaxAmount: number,
  Amount: number,
  ItemInfo: ACESItemInfo[]
}
export interface ACESTenderInfo {
  TenderType: string,
  Amount: number
}
export interface ACESPaymentInfo {
  TotalAmount?: number,
  TenderInfo: ACESTenderInfo[]
}

export interface ACESPayment {
  SourceCorpId: number,
  SourceSiteId: number,
  SourceSystemCode?: string,
  GuestCardNo: string,
  Pin?: number,
  CheckInfo: ACESCheckInfo,
  PaymentInfo: ACESPaymentInfo,
  NumberofRounds?: number,
  RateType?: string,
  PlayerType?: string,
  Course?: string,
  TerminalGroup?: string
  TransactionInfo?: TransactionInfoforCheckZoom,
  IsResortFinancePaymentFlow?: boolean
}


export interface ACESPaymentRecord {
  paymentTransactionId: number,
  transactionId: string,
  acesTransactionid: string,
  arAccountNumber: number
  memberId: string,
  tenderType: string,
  amount: number,
  paymentMethodType: number,
  memberPin: string,
  tenderId: number,
  outletId: number,
  checkNumber: string,
  ticketNumber: string,
  availableRounds: number,
  memberName: string,
  sourceCorpId: number
}


export interface Course {
  id: number;
  name: string;
}

export interface TournamentPackageItem {
  tournamentId: number;
  tournamentPackageId: number;
  tournamentComponentId: number;
  retailItemId: number;
  retailItemDetail: DomainRetailItem;
  price: number;
}

export interface Deposit {
  id?: number;
  playerId: number;
  amount: number;
  gratuity?: number;
  serviceCharge?: number;
  refundAmount?: number;
  depositTransactionId: number;
  refundTransactionId?: number;
  isVoided?: boolean;
}

export interface DomainRetailItem {
  retailItemDetail: RetailItem;
  retailItemTax: RetailItemTax[];
}

export interface RetailItemTax {
  id: string | number;
  retailItemId: string | number;
  taxName: string;
}

export interface RetailItem {
  id: string | number;
  itemNumber: string;
  itemDescription: string;
  salesPrice: number;
  category: number;
  categoryName: string;
  subCategory1Name: string;
  subCategory2Name: string;
  subCategory3Name: string;
  subCategory4Name: string;
  subCategory5Name: string;
  subCategory1: string;
  subCategory2: string;
  subCategory3: string;
  subCategory4: string;
  subCategory5: string;
  costPrice: number;
  barCode: string;
}

export interface Settings<T> {
  id: number;
  moduleName: string;
  screenName: string;
  configValue: T;
  defaultValue: string;
}

export interface TeeTimeConfig {
  rainCheckNote: string;
  ticketNote: string;
  requirePlayerType: boolean;
  requireRateType: boolean;
  showMemberSignOnTeeGrid: boolean;
  defaultToGraphicalTeeSheet: boolean;
  promptUserToPrintTeeTickets: boolean;
  autoPrintTeeTickets: boolean;
  requireTeeTimesToPaid: boolean;
  setPaidOnDayOfPlay?: boolean;
  requireCheckInCheckOut?: boolean;
  enableAutoCheck?: boolean;
  defaultOutletType?: number;
  allowPaymentInCheckIn?: boolean
  autoCheckInCheckOutZeroCharges?: boolean
}

export interface Outlet {
  id: number;
  externalPOSId?: number;
  outletName: string;
  outletNumber: number;
  isActive: boolean;
  terminalId?: number;
}

export enum AlertType {
  Success = 1,
  Warning = 2,
  Error = 3,
  WellDone = 4,
  Info = 5,
  AccessDenied = 6
}

export enum AlertAction {
  CONTINUE = 'CONTINUE',
  CANCEL = 'CANCEL',
  YES = 'YES',
  NO = 'NO',
  OK = 'OK'
}

export enum ButtonType {
  YesNo = 1,
  YesNoCancel = 2,
  OkCancel = 3,
  Ok = 4,
  SaveCancel = 5,
  Continue = 6,
  AddCancel = 7,
  ContinueCancel = 8,
  viewFailedCancel = 9
}

export interface PlayerTransaction {
  id: number;
  playerId: number;
  transactionId: number;
  ticketNumber: string;
  greenFeeTax: number;
  cartFeeTax: number;
  entryFeeTax: number;
  amountPaid: number;
  remainingDue: number;
  playerStatus: PlayerPaymentstatus;
  status: PlayerTransactionStatus;
  depositTransactionId?: number;
  isDepositRefundTransaction?:boolean;
  playerTransactionDetailReferenceId?: number;
}

export interface PlayerTransactionDetail {
  id: number;
  playerId: number;
  playerName: string;
  transactionId: number;
  transactionDetailId: number;
  playerTransactionId: number;
  playerStatus?: number;
  greenFeeTax: number;
  cartFeeTax: number;
  entryFeeTax: number;
}

export enum PlayerPaymentstatus {
  booked = 1,
  noShow = 2,
  cancelled = 4,
  paid = 8,
  unPaid = 16,
  refund = 32,
  checkIn = 64,
  checkOut = 128
}

export class Course {
  id: number;
  name: string;
  defaultOutletId: number;
  avgPlay9Holes: number;
  avgPlay18Holes: number;
  isActive: boolean = false;
}

export interface DefaultUserConfiguration {
  id?: number;
  userId: number;
  defaultOutletId?: number;
  defaultCourseId?: number;
  defaultPaymentDevice?: string;
  defaultDeviceName?: string;
  isIdtechSred: boolean;
  smallStickersPrinter?: string;
  hangingTicketsPrinter?: string;
}

export interface RainCheckIssue {
  id: number;
  issuedBy: string;
  issuedDate: Date;
  issuedDateDisplay?: string;
  playerId: number;
  playerName: string;
  greenFee: number;
  cartFee: number;
  greenFeeTax: number;
  cartFeeTax: number;
  holeNumber: number;
  rainCheckValue: number;
  teeTimeId: number;
  courseId: number | string;
  courseName: string;
  ticketNumber: number;
  outletName: string;
  isSettled: boolean;
  number: number;
}

export interface UserCourseConfiguration {
  id: number;
  userId: number;
  courseId: number;
}

export interface UserConfiguration {
  userGolfConfiguration: UserGolfConfiguration;
  userCourseConfiguration: UserCourseConfig[];

}

export interface UserGolfConfiguration {
  id: number;
  userId: number;
  daysOut: number;
}

export interface UserCourseConfig {
  id: number
  userId: number;
  courseId: number;
}

export interface AvailableDay {
  id: number;
  name: string;
  description: string;
  value: string;
  disabled?: boolean;
}

export interface RateSetupData {
  id: number;
  courseId: number;
  rateTypeId: number;
  startDate: string; // clarification
  endDate: string; // clarification
  startTime: string; // clarification
  endTime: string; // clarification
  dayOfWeek: string[];
  greenFee: number;
  cartFee: number;
  entryFee: number;
  overrideCourseTeeFee: boolean;
  greenFeeRetailItemId: number;
  cartFeeRetailItemId: number;
  entryFeeRetailItemId: number;
}

export interface RateType {
  id: number;
  type: string;
  guaranteeType: string;
  isActive: boolean;
  isSupressReceipt: boolean;
  listOrder: number;
}

export interface localizationJSON {
  Tournament: any;
  common: any;
  calendar: Calendar;
  home: any;
  teetime: any;
  guest: any;
  header: any;
  shop: any;
  lessons: any;
  lostfound: any;
  reports: any;
  settings: any;
  breakpoint: any;
  allocationblock: any;
  userConfig: any;
  teetimeIntervalConfig: any;
  tournamentformat: any;
  tournamentScore: any;
  subMenu: any;
  customField: any;
  tournamentContact: any;
  dashBoard: any;
  teeActions: any;
  contactTypes: any;
  dayend: any;
  lesson: any;
  lessonBooking: any;
  virtualTable: any;
  lostandfound: any;
  guestTable: any;
  tooltips: any;
  resolutionNotFound: any;
  GroupBookTeeTime: any;
  alertPopup: any;
  PlayerPaymentStatus: any
}

export interface Calendar {
  Sun: string;
  Mon: string;
  Tue: string;
  Wed: string;
  Thu: string;
  Fri: string;
  Sat: string;
  Sunday: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  January: string;
  February: string;
  March: string;
  April: string;
  May: string;
  June: string;
  July: string;
  August: string;
  September: string;
  October: string;
  November: string;
  December: string;
  Jan: string;
  Feb: string;
  Mar: string;
  Apr: string;
  Jun: string;
  Jul: string;
  Aug: string;
  Sep: string;
  Oct: string;
  Nov: string;
  Dec: string;
}

export interface TransactionDetailAggregate {
  playerTransactionDetails: PlayerTransactionDetail[];
  teeTicketDetail: TeeTicketDetail;
  rainCheckDetail: RainCheckIssue[]
}

export interface TeeTicketDetail {
  courseId: number;
  scheduleDateTime: Date;
  scheduledTeeTimeIds: number[];
}

export enum PlayerTransactionStatus {
  Pending = 1,
  Closed
}

export namespace POS {
  export interface RetailItem {
    retailItemDetail: RetailItemDetail;
    outletItem: OutletItem[];
    packagedItem: PackagedItem[];
    retailItemTax: RetailItemTax[];
  }

  export interface RetailItemDetail {
    id: number;
    externalPOSId: number;
    itemNumber: string;
    itemDescription: string;
    salesPrice: number;
    memberPrice?: number;
    suggestedPrice?: number;
    costPrice: number;
    marginPercentage: number;
    barcode: string;
    secondaryBarcode: string;
    category?: number;
    isCommissionable: boolean;
    isCommissionRequired: boolean;
    itemType: number;
    isGroupingKey: boolean;
    isScaledItem: boolean;
    unitOfMeasure?: number;
    isTeeTimeTrigger: boolean;
    isCartTrigger: boolean;
    isActive: boolean;
    isRequestName: boolean;
    isPMSPackageItem: boolean;
    isRequireComments: boolean;
    isOpenItem: boolean;
    isMultiPack: boolean;
    isGratuityAllowed: boolean;
    gratuity?: number;
    isServiceChargeAllowed: boolean;
    serviceCharge?: number;
    isPrintCartAgreement: boolean;
    isPrintClubAgreement: boolean;
    isPrintShoeAgreement: boolean;
    isPrintToCaddyShack: boolean;
    isPrintOnlyOnePerTansaction: boolean;
    isHangingTicket: boolean;
    isSmallTicket: boolean;
    linkedItemId?: number;
    multiStartDate: Date | string;
    multiEndDate: Date | string;
    multiExpDays?: number;
    multiSalesPrice?: number;
    multiMemberPrice?: number;
    multiCostPrice?: number;
    multiGratuity?: number;
    multiServiceCharge?: number;
    multiPerSale?: number;
    isMultiUnlimited: boolean;
    seasonalStartDate: Date | string;
    seasonalEndDate: Date | string;
    seasonalPrice?: number;
    seasonalMemberPrice?: number;
    useInventory: boolean;
    subCategory1?: number;
    subCategory2?: number;
    subCategory3?: number;
    subCategory4?: number;
    subCategory5?: number;
    allownEarn: boolean;
    disposableItem: boolean;
    perHourRentalRate: number;
    perDayRentalRate: number;
  }

  export interface OutletItem {
    retailItemId: number;
    outletId: number;
  }

  export interface PackagedItem {
    retailItemId: number;
    parentItemId: number;
    startDate: Date | string;
    endDate: Date | string;
    price: number;
    memberPrice: number;
  }

  export interface RetailItemTax {
    id: number;
    retailItemId: number;
    taxName: string;
  }
}

export interface AgAddressField {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface AgAddressConfig {
  className?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formArrayName?: string;
  formControlName?: string;
  placeHolder?: string;
  value?: string;
  disabled?: boolean;
  maxlength?: number;
}

export interface Addresscomponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GoogleAddressOutput {
  fullAddress?: Addresscomponent;
  city: string;
  state: string;
  country: string;
  zipCode: string;
}

export interface AgAddressFieldConfig {
  className?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formArrayName?: string;
  formControlName?: string;
  placeHolder?: string;
  value?: string;
  disabled?: boolean;
  maxlength?: number;
}

export interface AgContactConfig {
  typeControlName?: string;
  typeClass?: string;
  className?: string;
  extensionClass?: string;
  extnPlaceHolder?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formArrayName?: string;
  formControlName?: string;
  placeHolder?: string;
  value?: string | number;
  options?: any[];
  showSwitches?: boolean;
  isPrimaryName?: string;
  isPrivateName?: string;
  primarySwitchLabel?: string;
  privateSwitchLabel?: string;
  disabled?: boolean;
}

export enum ContactType {
  email = 'Email',
  phone = 'Phone'
}
export enum contactType {
  phone = 1,
  email = 2
}
export enum PhoneTypes {
  mobile = 1,
  home = 2,
  office = 3,
  fax = 4,
  others = 11
}

export enum MailTypes {
  personal = 9,
  office = 10,
}

export interface AgPhoneDetail {
  id: number;
  phoneType: number;
  phoneNumber: string;
  extension: string;
  isPrimary: boolean;
  isPrivate: boolean;
  disabled?: boolean;
}

export interface ItemSearchBaseResponse {
  id: number;
  externalPOSId: number;
  itemNumber: string;
  itemDescription: string;
}


export interface SpaReceiptData {
  transactionId: number;
  transactionDetailId: number;
  appointmentId: number;
  staffCode: string;
  serviceId: number;
  serviceName: string;
  isSpaPackage: boolean;
  packageId: number;
  packageName: string;
  retailItemId: number;
  clientName: string;
  amount: number;
  isAppointmentCheckout: boolean;
}

export interface ACESMemberPayment {
  SourceCorpId: number,
  SourceSiteId: number,
  SourceSystemCode?: string,
  ProfitCentre?: string,
  TerminalGroup?: string,
  TerminalId?: string,
  GuestCardNo: string,
  ServerEmpID: string,
  Pin: string,
  IsOffline: boolean,
  CheckInfo: MemberCheckInfo,
  PromoInfo: string,
  SiteID: number,
  RoleID: number,
  UserID: number,
  CorpID: number,
  StatusCode: number,
  SaveType: number,
  DeviceType: number,
  OutletInfo: ACESOutletInfo,
  PostingInfo: PostingInfo,
  IsResortFinancePaymentFlow?: boolean
}

export interface ACESOutletInfo {
  Id: number,
  Code: string,
  Description: string
}
export interface ACESFinancialbin {
  classId: string;
  typeId: number;
  amount: number;
}
export interface PostingInfo {
  PostingId: string,
  ClientId: string,
  PaymentMethodId: number,
  PostToPropertyId: number
}
export interface PostingInfo {
  PostingId: string,
  ClientId: string,
  PaymentMethodId: number,
  PostToPropertyId: number
}

export interface MemberCheckInfo {
  PaymentAmount: number,
  TipAmount: number,
  TaxAmount: number,
  Amount: number,
  TerminalGroup: string,
  ItemInfo: MemberItemInfo[],
  FinancialBins: ACESFinancialbin[]
}

export interface MemberItemInfo {
  GuestCardNo: string,
  TransactionRef: string,
  ProductClass: string,
  ProductClassID: number,
  ItemGroupName: string,
  RateType: string,
  CourseName: string,
  CourseCode: string,
  ItemGroupID: number,
  ItemId: number,
  ItemName: string,
  Quantity: number,
  Price: number,
  Holes: number,
  TeeTimeId: number,
  GuestName: string,
  PaymentInfo: MemberPaymentInfo
}

export interface MemberPaymentInfo {
  TotalAmount: number,
  TaxAmount: number,
  Gratuity: number,
  TenderInfo: MemberTenderInfo[]
}

export interface MemberTenderInfo {
  TenderType: string,
  Amount: number,
  ReferenceId: string
}

export interface PostTypeCompOrder {
  id: number;
  compOrder: number;
}

export interface PostType {
  id?: number;
  department?: number;
  listOrder?: number;
  category: number;
  compOrder?: number;
  postTypeNumber: number;
  postTypeName: string;
  isActive: boolean;
  isCredit: boolean;
  isDeposit: boolean;
  postTypeCatergoryId: number;
}

export interface PostTypeSearchParam {
  department: number[];
  isActive: boolean;
  isCredit: boolean;
  includeRelatedData: boolean;
}

export interface Department {
  id: number,
  code: string;
  name: string;
  listOrder: any;
  isActive: boolean;
}

export interface TariffCode {
  id: number,
  code: string;
  name: string;
  listOrder: any;
  isActive: boolean;
}

export interface Departments {
  id?: number;
  name: string;
  ListOrder?: number;
}
export interface ClientInfo {
  playerId: number[];
  appointmentId: number[];
  transactionId: number;
  reportQuery: ReportAPIModel;
}

export interface NotificationModel {
  transactionId: number;
  playerIds: number[];
  reportQuery: ReportAPIModel;
  action: string;
  emailId: any[];
  isRetailNotification: boolean;
  isDistributionListRequired: boolean;
}
export interface TemplateTags {
  title: string;
  tags: Tags[];
  showGroupTitle: boolean;
  titleCode: string;
}

export interface Tags {
  id: number;
  code: string;
  name: string;
  categoryCode: string;
  categoryName: string;
  entity?: string;
  field?: string;
  conversion?: string;
  tagCode: string;
  properties: TagProperty[];
}

export interface TagProperty {
  key: string;
  value: string;
}

export interface EditorInputs {
  formGroup: UntypedFormGroup;
  formControlName: string;
  showTags: boolean;
  showCollapse?: boolean;
  showSearch?: boolean;
  isViewOnly?: boolean;
}
export enum TagKeys {
  FontFamily = 'font-family',
  FontSize = 'font-size',
  FontStyle = 'font-style',
  FontWeight = 'font-weight',
  TextDecoration = 'text-decoration'
}
export interface PrintLetter {
  name: string,
  type: number,
  value: Uint8Array,
  templateId: number
}

export class RetailErrorConstants {
  public static POSTTYPES_UNMAPPED = -100001
}

export interface ClientInformation{
  clientId : number;
  guestId : string
}
export interface AutoRetailTransactionRequestModel {
  outletId: number;
  sourceType: RetailInterfaceSourceTypes;
  sourceId: number;
  autoRetailTransactionComments: string;
  autoRetailTransactionReason: string;
  autoRetailTransactionType: number;
  isTraceRequired: boolean | null;
  emailId?: string[];
  autoRetailTransactionRequest?: AutoRetailTransactionRequest;
  autoRetailTransactionDetails?: AutoRetailTransactionDetails;
  autoRetailPaymentDetails?: AutoRetailPaymentDetails;
}

export interface AutoRetailTransactionRequest {
  memberId: number | null;
  clerkId: number;
  saleType: string;
  autoRetailItems: AutoRetailItemDetails[];
  userId: number;
  guestGuid: string | null;
}


export interface AutoRetailItemDetails {
  retailItemId: number;
  guestId: number | null;
  lineNumber: number;
  guestGuId: string | null;
  itemDescription: string;
  retailItemPrice: number;
  quantity: number;
  isOpenItem: boolean;
  isReturn: boolean;
  IsRetained: boolean;  
  isMultiPackItem: boolean;
  clientMultiPackId: number;
  itemComment?: string;
  autoServiceChargeGratuity?: AutoServiceChargeGratuity[];
  Commission?: Commission[],
}

export interface AutoServiceChargeGratuity {
  gratuityAmount?: number | null;
  gratuityPercentage?: number | null;
  gratuityStaffID?: number | null;
  gratuityStaffName?: string;
  gratuityStaffType?: string;
  serviceChargeAmount?: number | null;
  serviceChargePercentage?: number | null;
  serviceChargeStaffID?: number | null;
  serviceChargeStaffName?: string;
  serviceChargeStaffType?: string;
}

export interface AutoRetailTransactionDetails {
  retailTicketNumber: number;
  transactionId: number;
  returnTransactionDetailIds: number[];
}

export interface AutoRetailPaymentDetails {
  autoRetailPaymentMethodIDs: number[];
  folioPostingDetail? :AutoFolioPostingPaymentDetails;
}

export interface AutoFolioPostingPaymentDetails {
  folioInvoiceNumber: string;
  resortFinanceSourceType: string;
  resortFinanceSourceTypeId: string;
  guestId: string;
  paymentMethodId: number;
}
export interface AutoRetailTransactionResponse {
  transactionId: number;
  retailTicketNumber: string;
  transactionDetails: AutoRetailTransactionDetailsResponse[];
  autoPaymentInfos: AutoPaymentInformation[];
  isAutoRetailTransactionSuccess: boolean;
  autoRetailTransactionStatus: number;
  productId: number;
  sourceType: string;
  sourceId: number;
  successMessage: string;
  autoRetailTransactionTrace: string[];
  errorMessage: string;
  errorCode: number;
  transactionData: TransactionData;
}


export interface TransactionData {
  id: number;
  retailTicketNumber: string;
}


export interface AutoRetailTransactionDetailsResponse {
  transactionDetailId: number;
  guestId: number | null;
  itemId: number;
  itemDescription: string;
  quantity: number;
  netUnitPrice: number;
  netPrice: number;
  totalAmount: number;
  tax: number;
  serviceCharge: number;
  gratuity: number;
  lineNumber: number;
}

export interface AutoPaymentInformation {
  paymentTypeId: number;
  paymentMethodName: string;
  paymentAmount: string;
  postIds: number[];
}

export interface TeeTimeTempHoldResult {
  id: number;
  courseId: number;
  scheduleDateTime: string;
  holeNumber: number | string;
  releaseDateTime: string;
  createdOn: string;
  createdBy: number;
  originalHoleNumber: string;
  playerId: number;
  userName?: string;
}
export interface BreakPointResult {
  isAllow: boolean;
  isViewOnly: boolean;
  breakPointNumber?: number;
}

export interface SaleDepletionSearchRequest {
  ticketNumber: string;
  startDate: string;
  endDate: string;
  clerkIdList: number[];
  outletId: number;
}

export interface EnhancedInventoryIntegrationLog {
  id: number;
  type: string;
  methodInfo: string;
  request: string;
  response: string;
  userId: number;
  createdDate: Date;
  updatedDate: Date;
  status: number;
  ticketNumber: string;
  transactionDateTime: Date;
  outletId: number;
  soldBy: number;
  transactionType: string
}

export interface UISaleDepletion {
  id: number;
  logTime: string;
  ticketNumber: number;
  errorMessagefullView: string;
  transactionType: string;
  user: string;
  Action: any;
  logDate: string;
  isCheckBoxDisabled?: boolean;
}