import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { AppService } from '@app/app.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { AvailabilityCriteria, AvailabilityType, BookingBehavior, buttonTypes, ClassType, ComponentTypes, DayOfWeek, LessonType, PartyState, PartyType, ReservationType, Status } from '@app/shared/constants/commonenums';
import { ActivitySlotRequestDTO } from '@app/shared/models/ActivitySlotRequestDTO';
import { SessionBookingDTO } from '@app/shared/models/SessionBookingDTO';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { AddonActivitiesMappingsDTO, AllocationTypeDTO, AssetBookingType, FloorPlanDTO, LayoutDTO, SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { LayoutFunctions } from '@utilities/layout-functions';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { FacadeService } from '@services/facade.service';
import { GuestBookService } from '@services/guestbook.service';
import { controllers } from 'chart.js';
import { AllowedSettingsPublish } from '@app/shared/models/SignalRDto';
import { controlSettings, popupDialogDimension } from '@app/shared/constants/globalConstants';
import { RoleType } from '@app/shared/models/LoginResultDTO';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { HostDTO } from '@app/shared/models/HostDTO';
import { EngageMemberByCardIdRequestDTO } from '@app/shared/models/EngageMemberByCardIdRequestDTO';
import { EngageMemberDetailDTO, EngageProfile } from '@app/shared/models/EngageMemberDetailDTO';


@Component({
  selector: 'activity-booking-form',
  templateUrl: './activity-booking-form.component.html',
  styleUrls: ['./activity-booking-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityBookingFormComponent implements OnInit, AfterViewInit, OnDestroy {

  lessonConfig: FieldConfig[];
  bookingFromDateConfig: FieldConfig[];
  bookingToDateConfig: FieldConfig[];
  locationConfig: FieldConfig[];
  instructorConfig: FieldConfig[];
  membershipDetailConfig: FieldConfig[];
  exclusiveBookingConfig: FieldConfig[];
  selectedMemberDetail : EngageMemberDetailDTO = null;
  bookingSizeArray: any = [];
  maxSizeAllowed: number = 60;
  minSizeAllowed: number = 1;
  locationList: any = [];
  instructorList: any = [];
  floorPlan: FloorPlanDTO[] = [];
  _settings: SettingsDTO;
  _layout: LayoutDTO = {} as LayoutDTO;
  subscriptions: Subscription = new Subscription();
  displaySlots: boolean = false;
  coverTypes = [];
  bookingTypes = [];
  hasMembershipValue: boolean = false;
  SessionSlots: any = null;
  SessionSlotsAvailableDates: any = null;
  ActivityList: any;
  Instructors: any[];
  selectedIndex: number = 0;
  validCoverTypes: boolean = true;
  coverTypeReqErr: boolean = false;
  bookingTypeReqErr: boolean = false;
  validBookingTypes: boolean = true;
  validGroupSize: boolean = true;
  isMultiSlotSelection: boolean = true;
  isSessionClass: boolean = true;
  AllowStandBy: boolean = false;
  AllowOverbook: boolean = false;
  invalidDate: boolean = false;
  lastRequestedObject: any = null;
  availableSessions: any[] = null;
  classLessonList: any = [];
  selectedClassSchedule: any = [];
  dayOfWeek = DayOfWeek;
  detailComponetData: any;
  isClassTypeUpdated: boolean = false;
  resetButton: ButtonValue;
  showCoverTypes: boolean = false;
  showBookingTypes: boolean = false;
  GrpSizeErrorMsg = '';
  availableAllocationCodes: AllocationTypeDTO[];
  mebershipUser: any = {};
  memberShipdetail: any = {};
  MemberDetailsArray: any = [];
  memberShipArr: any = [];
  savesessions: any = [];
  activitySelected: any;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  notFirstTime: boolean = false;
  refreshDialogRef: MatDialogRef<any>;

  public noOfSlotsSelected: number = 0;
  public sessionsUnavailable: any[];
  subscription: Subscription = new Subscription();
  HostRoleType: any;
  weeklyViewDate: any;
  classScheduleList: any[];
  validMinCoverType: boolean = true;
  minimumQuantityErrorMsg: string = '';
  bookPastReservations: boolean = false;
  showSalesContact: boolean = false;
  salesContactConfig: FieldConfig[] = [];
  @ViewChild('salesContactForm') salesContactForm: DynamicFormComponent
  memberSearchButton: ButtonValue;
  bookingTypeRequiredMsg: string = '';
  sizeMapping:any;
  isLocationGroupMapped: boolean = false;
  sessionIdFromTimeLine: number;
  exclusiveBookingInfoMsg:string = '';
  nonExclusiveBookingInfoMsg:string ='';
  isInitialCheck : boolean = true;
  isValidConfig: boolean = true;
  constructor(public facadeService: FacadeService, private cs: CacheService, @Inject(COMPONENTINPUT) public data, private dialog: MatDialog, private apiService: ApiService, public guestBookService: GuestBookService,
    public partyService: PartyService, private fb: UntypedFormBuilder, private ps: PopupService, private ts: TranslateService, private lf: LayoutFunctions,
    public appService: AppService, public toastrService: ToastrService, private dashboardFunctions: DashboardFunctions) {
    if (this.data) {
      if (this.data.Size) {
        this.maxSizeAllowed = this.data.Size < 60 ? 60 : this.data.Size;
      }
    }
    this.subscriptions.add(this.cs.settingsOperationName.subscribe(op => {
      if (this.notFirstTime && AllowedSettingsPublish[op] && AllowedSettingsPublish[AllowedSettingsPublish.SaveLesson].toString() === op.toString()) {
        this.showSettingsChangedInfo();
      }
      this.notFirstTime = true;
    }));
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.ActivityList = this._settings.SpecialMeals.filter(s => s.LessonType == LessonType.GroupLesson);
      this.ActivityList = _.orderBy(this.ActivityList, ['Name'], ['asc']);
      if (this.data !== undefined && this.data !== null && this.data) {
        if (this.data.SpecialMealId)
          this.activitySelected = this.ActivityList.find(activity => activity.Id == this.data.SpecialMealId);
        this.AllowStandBy = this.activitySelected.AllowStandBy;
        this.AllowOverbook = this.activitySelected.AllowOverbook;
        this.partyService.selectedSpecialMealId = this.data.SpecialMealId;
        this.maxSizeAllowed = this.activitySelected.MaxPartySize;
        this.minSizeAllowed = this.activitySelected.MinPartySize;
        this.showSalesContact = this.activitySelected?.TrackingofSalesPerson || false;
      }
      this.coverTypes = [...this._settings.CoverTypes];
      this.bookingTypes = [...this._settings.BookingTypes];
      this.isMultiSlotSelection = this._settings.PropertySetting[0].EnableMultiSlotSelection;
    }));
    this.subscriptions.add(cs.layout.subscribe(layt => {
      this._layout = layt;
    }));
    this.loadOptions();
  }

  ngOnInit() {
    this.partyService.sessionBookingDataSaveObj = null;
    this.sessionIdFromTimeLine = this.data?.SessionId || null;
    this.bookPastReservations = this.dashboardFunctions.checkBookPastDaysReservationsConfig();
    this.resetButton = {
      type: buttonTypes.actionPrimarySmall,
      label: 'resetSelection',
      disbaledproperity: false,
      customclass: 'action-bar__add-class-btn',
    };
    this.memberSearchButton = {
      type: buttonTypes.actionPrimarySmall,
      label: 'searchText',
      disbaledproperity: false,
      customclass: 'action-bar__add-class-btn'
    }
    this.weeklyViewDate = this.data?.weeklyViewDate;
    if (this.data && this.data.fromDetailComponent) {
      this.detailComponetData = { ...this.data };
      this.data = null;
    }
    this.partyService.customFieldValidation = [];
    this.lessonConfig = [
      {
        type: 'autocomplete',
        name: 'class',
        options: this.getLessonList(),
        value: this.data ? this.data.SpecialMealId : 0,
        class: 'activity-booking__class-name',
        showErrorText: true,
        isTranslate: false,
        validation: [Validators.required],
        disabled: this.data ? true : false,
        autoCompleteWithId: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        returnFunction: this.clearFormField.bind(this, 'class'),
      },
      {
        type: 'select',
        label: 'classType',
        name: 'classType',
        options: this.getLessonTypeList(),
        value: this.data ? this.getLessonType(this.data.SpecialMealId) : 0,
        class: 'activity-booking__class-type',
        showErrorText: true,
        isTranslate: false,
        validation: [Validators.required],
        disabled: true
      }
    ];
    var restaurantDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)
    this.bookingFromDateConfig = [
      {
        type: 'date',
        name: 'fromDate',
        inputType: 'text',
        class: 'field-90',
        value: this.getFromDate(restaurantDate),
        appearance: false,
        validation: [Validators.required],
        disabled: this.data && !this.dashboardFunctions.checkBookPastDaysReservationsConfig() ? (this.isPastDate(this.data.StartDate) && this.data.Id) || this.anyFirstDaySessionCheckedIn(this.data) : false,
        minDate: this.dashboardFunctions.getMinDateBasedonConfig(this.activitySelected, restaurantDate)
      }];
    this.bookingToDateConfig = [{
      type: 'date',
      name: 'toDate',
      inputType: 'text',
      class: 'field-90',
      value: this.getToDate(restaurantDate),
      appearance: false,
      minDate: this.dashboardFunctions.getMinDateBasedonConfig(this.activitySelected, restaurantDate)
    }];
    this.locationConfig = [{
      type: 'autocomplete',
      name: 'location',
      options: this.locationList,
      value: this.locationList[0].id,
      class: 'activity-booking__seating-type',
      showErrorText: true,
      isTranslate: false,
      validation: [Validators.required],
      autoCompleteWithId: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      returnFunction: this.clearFormField.bind(this, 'location'),
    }];
    this.instructorConfig = [{
      type: 'autocomplete',
      name: 'instructor',
      options: this.instructorList,
      value: 0,
      class: 'activity-booking__seating-type',
      showErrorText: true,
      isTranslate: false,
      validation: [Validators.required],
      autoCompleteWithId: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      returnFunction: this.clearFormField.bind(this, 'instructor'),
    }];
    this.membershipDetailConfig = [{
      type: 'autocomplete',
      name: 'membershipId',
      inputType: 'text',
      options: this.MemberDetailsArray,
      class: 'party-size',
      showErrorText: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      appearance: false,
      isTranslate: false,
      partySize: false,
      label: 'searchbyphoneandemail',
      cellClick: this.setSelectedGuest.bind(this),
      returnFunction: this.clearmembershipDetail.bind(this)
    }];
    this.salesContactConfig = [{
      type: 'autocomplete',
      name: 'salescontacts',
      options: [],
      class: 'activity-booking__seating-type',
      showErrorText: true,
      isTranslate: false,
      autoCompleteWithId: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      isChipsEnabled: true,
      multiChipValues: [],
      returnFunction: this.clearFormField.bind(this, 'salescontact'),
    }];
    this.exclusiveBookingConfig =[
      {
        type: 'checkbox',
        placeholder: this.ts.instant('exclusiveBooking'),
        value: this.data ? this.data?.IsExclusiveBooking : (this.cs.settings.value.SpecialMeals.find(meal => meal.Id == this.partyService.selectedSpecialMealId)?.AssetBookingType == AssetBookingType.ExclusiveBooking) ? true : false,
        name: 'IsExclusiveBooking',
     },
    ]
    this.HostRoleType = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostRoleType;
    this.partyService.bookingSize = (this.data && this.data.Size) ? this.data.Size : 1;

    if (this.data) {
      this.partyService.sessionBookingDataSaveObj = null;
      // this.setBookingData();
      this.locationConfig[0].options = this.getLocationOptions(this.data.SpecialMealId);
      this.instructorConfig[0].options = this.getInstructorOptions(this.data.SpecialMealId);
      let noOfLocationsWithoutAny = this.locationConfig[0].options.filter(option => option.id != -1).length;
      let defaultLocation = noOfLocationsWithoutAny === 1 && this.locationConfig[0].options.length === 2 ? this.locationConfig[0].options[1].id : - 1;
      this.locationConfig[0].value = this.data.TableIds.length == 1 ? this.locationConfig[0].options.find(table => table.id == this.data.TableIds[0]) ? this.data.TableIds[0] : defaultLocation : defaultLocation;
      let selectedInstructor = this.getInstructor();
      this.instructorConfig[0].value = this.instructorConfig[0].options.find(instructor => instructor.id == selectedInstructor?.ServerId) ? selectedInstructor.ServerId : -1;
      this.getCoverTypesForSelectedActivity(this.data.SpecialMealId);
      this.getBookingTypesForSelectedActivity(this.data.SpecialMealId);

      //Set Cover Values on Edit
      this.isSessionClass = this.getLessonType(this.data.SpecialMealId) == ClassType.Session;
      if (!this.isSessionClass) {
        this.bookingFromDateConfig[0].disabled = true;
        this.bookingToDateConfig[0].disabled = true;
      }
      this.isLocationGroupMapped = this.activitySelected?.ActivitySessions.filter(location => location.LocationGroupId)?.length > 0;
    }
    this.subscriptions.add(this.partyService.tabChangeEvent$.subscribe(value => {
      let components =  [ComponentTypes.EditActivityBooking , ComponentTypes.AddActivityBooking , ComponentTypes.AddOpenBooking , ComponentTypes.EditOpenBooking , ComponentTypes.AddPrivateLessonBooking , ComponentTypes.EditPrivateLessonBooking];
      let getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, this.partyService.selectedSpecialMealId);
      if (value?.index === 1 && components.includes(value?.from) ) {
        this.setBookingData();
        if (this.partyService.selectedGuest && this.partyService.selectedGuest.MembershipType) {
          setTimeout(() => {
            this.updateFormValidity();
          }, 100)
        } else {
          this.updateFormValidity();
        }
      } else if (value?.index === 0) {
        if (this.partyService.slotLockId) {
          this.subscriptions.add(this.partyService.unlockSessions(this.partyService.slotLockId).subscribe((slot) => {
            this.partyService.slotLockId = null;
          }));
        }
      this.ps.previousButtonEnabled$.next(true);
      this.updateFormValidity();
      }
      if(value?.index != 0)
      this.ps.previousButtonEnabled$.next(false);
    }));
    if (this.detailComponetData) {
      setTimeout(() => {
        this.setBookData();
      }, 100)
    }
  }


  getEditStartDate() {
    let propertyDate = new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    if (!this.data.Id) {
      if (new Date(this.data.StartDate) < propertyDate && this.getLessonType(this.data.SpecialMealId) == ClassType.Session) {
        return propertyDate
      }
    }
    return new Date(this.data.StartDate);
  }

  getEditEndDate() {
    let propertyDate = new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    if (!this.data.Id) {
      if (new Date(this.data.EndDate) < propertyDate && this.getLessonType(this.data.SpecialMealId) == ClassType.Session) {
        return propertyDate
      }
    }
    return new Date(this.data.EndDate);
  }

  setBookData() {
    let { fromDetailComponent, SpecialMealId, locationId, instructorId } = this.detailComponetData;
    if (fromDetailComponent) {
      if (SpecialMealId) {
        this.components.first.form.controls.class.setValue(SpecialMealId);
        this.detailComponetData.SpecialMealId = null;
        this.detailComponetData.fromDetailComponent = false;
      }
    }
  }

  setSelectedGuest() {
    const [memberdetail] = this.getFormComponent('membershipId');
    const seectedGuestValue = memberdetail.form.controls.membershipId.value;
    let selectedIndex = Number(seectedGuestValue.substring(0, 1));
    memberdetail.form.controls.membershipId.setValue('');
    this.hasMembershipValue = false;
    this.memberShipdetail = this.memberShipArr[selectedIndex - 1];
    let engageIntegrationData = this._settings.General.EngageIntegrationDTO;
    let obj: EngageMemberByCardIdRequestDTO = new EngageMemberByCardIdRequestDTO(this.memberShipdetail.CardNo, engageIntegrationData?.TenantId, engageIntegrationData?.SiteId);
    this.apiService.getEngageMemberByCardId(obj).subscribe((memberDetailResponse: EngageMemberDetailDTO) => {
     this.selectedMemberDetail = memberDetailResponse;
     this.membershipDetailConfig[0].options = [];
     this.setSelectedMembershipDetail(memberDetailResponse, this.data ? this.data.Contact : null)
     this.dashboardFunctions.scrollTop("memberInformation");
    })
  }

  getLessonList() {
    return this.ActivityList.filter(x => x.StatusCode === Status.Approved && this.isDateAppicable(x.StartDate, x.EndDate)).map(activity => {
      return {
        id: activity.Id,
        value: activity.Name
      }
    })
  }

  isDateAppicable(startdate, endDate): boolean {
    return Utilities.datetimeBetween(startdate, endDate, new Date(new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)).setHours(0, 0, 0, 0)))
      || !this.isPastDate(startdate)
  }

  slotChangeListners(event, dateIndex) {
    const [activity] = this.getFormComponent("class");


    if (activity && activity.form.controls.classType.value == ClassType.Class && event && event.checked) {
      this.displayClassSchedule(event.SessionGroupId);
      this.classLessonList.forEach(session => {
        if (session.SessionId != event.SessionId || session.SessionGroupId != event.SessionGroupId) {
          session.checked = false;
        }
      });

    }
    else if (activity && activity.form.controls.classType.value == ClassType.Class && event && !event.checked) {
      this.selectedClassSchedule = [];
    }
    else if (!this.isMultiSlotSelection && event.checked) {
      this.SessionSlotsAvailableDates.forEach((element, index) => {
        element.AvailableSessions.forEach(session => {
          if (dateIndex && dateIndex != index || session.SessionId != event.SessionId) {
            session.checked = false;
          }
        });
      });
    }
    if (activity.form.controls.classType.value == ClassType.Class) {
      //this.partyService.selectedSlot$.next(this.classLessonList?.filter(data => data.checked == true));
      var selectedSessionGroupId = this.classLessonList?.find(data => data.checked == true)?.SessionGroupId;
      this.partyService.selectedSlot$.next(this.classScheduleList?.flat()?.filter(data => data.SessionGroupId == selectedSessionGroupId));
    }
    else {
      this.partyService.selectedSlot$.next(this.SessionSlotsAvailableDates?.length ? this.fetchSelectedSessions(): []);
    }

    this.updateFormValidity();
  }

  displayClassSchedule(sessionGroupId) {
    let groupIdList: any[] = Utilities.uniqueBy(this.availableSessions, "SessionGroupId", "DayOfWeek", "SessionId");
    this.selectedClassSchedule = [];
    groupIdList = groupIdList.filter(group => group.SessionGroupId == sessionGroupId);
    groupIdList.forEach(groupDetail => {
      if (!this.selectedClassSchedule[groupDetail.DayOfWeek]) {
        this.selectedClassSchedule[groupDetail.DayOfWeek] = [];
      }
      let selectedItem = this.availableSessions.find(session => session.SessionGroupId == groupDetail.SessionGroupId && session.DayOfWeek == groupDetail.DayOfWeek && session.SessionId == groupDetail.SessionId);
      this.selectedClassSchedule[groupDetail.DayOfWeek].push(selectedItem);
    });
  }

  getLessonTypeList() {
    return [
      { id: ClassType.Session, value: this.ts.instant('BySession') },
      { id: ClassType.Class, value: this.ts.instant('ByClass') }
    ]
  }

  loadOptions() {
    this.floorPlan = this._layout.FloorPlans;
    let standAloneTables = this.floorPlan.filter(x => x.IsDefault == true)[0].StandaloneTables;
    standAloneTables = _.orderBy(standAloneTables, ['Name'], ['asc'])

    standAloneTables.forEach(table => {
      if (!table.IsTemplate)
        this.locationList.push({ id: table.Id, value: table.Name });
    });

    this._settings.Servers.forEach(server => {
      if (!server.IsTemplate)
        this.instructorList.push({ id: server.Id, value: server.Name + (server.LastName ? ' ' + server.LastName : '') });
    })

    // Instructors ordered by alphaNumeric
    const sortedArray = (servers) => {
      const sortByValue = (a, b) => a.value.localeCompare(b.value, 'en', { numeric: true });
      return servers.sort(sortByValue);
    };
    this.instructorList = sortedArray(this.instructorList);
  }

  ngAfterViewInit() {
    this.formChangeSubscribe();
    if (this.showSalesContact) {
      this.salesContactUsers();
    }
    if (this.data) {
      this.getSlotsByLocation(true);
      var activitySelected = this.ActivityList.find(activity => activity.Id == this.data.SpecialMealId);
      if (activitySelected && activitySelected.CustomFieldValidation) {
        this.partyService.customFieldValidation = activitySelected.CustomFieldValidation
      }
      this.partyService.reservationFormGroup.markAsDirty();
      this.partyService.activityChanges$.next(activitySelected);
    } else {
      if (!this.detailComponetData) {
        var [activity] = this.components;
        activity.form.controls.class.setValue(this.getLessonList().length ? this.setDefaultLessonbasedOnAvaiality() : '');
        this.partyService.activityChanges$.next(activity.form.controls.class.value);
      }
    }
    this.updateFormValidity();

  }

  setDefaultLessonbasedOnAvaiality() {
    let activitySession = this.ActivityList.filter(x => x.StatusCode === Status.Approved && this.isDateAppicable(x.StartDate, x.EndDate));
    if (activitySession && activitySession.length > 0) {
      let availableSessions = activitySession.filter((session) => (new Date(session.StartDate) <= this.appService.headerDate$.value) && new Date(session.EndDate) >= this.appService.headerDate$.value)
      return availableSessions && availableSessions.length > 0 ? availableSessions[0].Id : activitySession[0].Id;
    }
  }
  bindContactData() {
    if (this.data?.Contact.TrackMembershipNumber && this.data.Contact.MemberType) {
      this.hasMembershipValue = true;
      this.partyService.selectedGuest = this.data.Contact;
      const memberType = this._settings.AllocationType.find(allocation => allocation.AllocationName === this.data.Contact.MemberType);
      this.partyService.selectedGuest.memberShipId = this.data.TrackMembershipNumber;
      this.partyService.selectedGuest.MembershipType = memberType;
      this.partyService.selectedGuest.MemberActive = this.data.Contact.IsMemberActive;
    } else if (this.data.Contact?.TrackMembershipNumber && this._settings.General?.EngageIntegrationDTO?.IsEnabled) {
      this.CheckMemberShip(this.data.Contact.TrackMembershipNumber, this.data.Contact);
    } else {
      this.partyService.selectedGuest = this.data.Contact;
    }
  }

  onSlotUpdate() {
    this.updateFormValidity();
  }

  getSlotsForClass(requestedObject, bindEditContactData , showError:boolean = false) {
    requestedObject.PropertyId = Utilities.RestaurantId();
    let classid = this.components.filter(x => {
      let vals = x.form.get("classType");
      if (vals)
        return true;
    })[0].form.get("class").value
    let overAllAddons = Utilities.getAddons(this.cs.settings.value.Addons, classid);
    let Addons = Utilities.getMandatoryAddons(this.cs.settings.value.Addons, classid);
    this.subscriptions.add(this.apiService.GetClassSlots(requestedObject).subscribe(response => {
      if(this.isLocationGroupMapped){
        this.checkAssetNotAvailable(response  , requestedObject , bindEditContactData , showError);
      }
  
      let slots = response.Payload;
      var allocationCodes = new Set();
      if (this.data && this.data.BookedSessions) {
        if (!this.data.isConfirmedReservation) {
          this.updateSlot();
        } else {
          if (this.data && this.data.Type !== PartyType.StandBy) {
            this.data.BookedSessions = this.data.BookedSessions.filter(session => session.SessionType === PartyType.Reservation);
          }
        }
      }
      this.SessionSlotsAvailableDates = slots.length ? slots.map((date, index) => {
        date.AvailableSessions = date.AvailableSessions
          .map(slot => {
            if (slot.Availability && slot.Availability.AllocationTypeId) {
              allocationCodes.add(slot.Availability.AllocationTypeId);
            }
            slot.Start = date.AvailableDate.split("T")[0] + "T" + slot.Start;
            slot.End = date.AvailableDate.split("T")[0] + "T" + slot.End;
            slot.sortDate = new Date(slot.Start);
            slot.IsAddonAvailable = Utilities.filterSlotsbyAddon(this.cs.settings.value?.Addons, overAllAddons, Addons, slot.AddOnAvailability, this.getCoverTypesize(), classid);
            slot.SpecialMealId = classid;
            slot.isBlocked = slot.Availability.AvailabilityType == AvailabilityType.Blocked;
            slot.bookingSize = requestedObject.BookingSize;
            this.updateSlotBasedOnBookedSession(slot, date);
            return slot;
          })
          .filter(session => session.Availability?.AvailabilityType != AvailabilityType.SelectionCriteriaNotMatched);
        date.AvailableSessions = _.sortBy(date.AvailableSessions, "sortDate");

        return date;

      }) : null;
      if (this.SessionSlotsAvailableDates) {
        this.SessionSlotsAvailableDates = this.SessionSlotsAvailableDates.filter(slot => slot.AvailableSessions.length);
      }
      if (!this.isSessionClass) {
        this.availableSessions = [];
        this.classLessonList = [];
        this.classScheduleList = [];
        this.SessionSlotsAvailableDates.forEach(elm => {
          this.availableSessions = [...this.availableSessions, ...elm.AvailableSessions]
        })
        var groupIdList: any[] = Utilities.uniqueBy(this.availableSessions, "SessionGroupId");
        let availableSessions = _.orderBy(this.availableSessions, "End", "desc");
        groupIdList.forEach(group => {
          var selectedSession = availableSessions.find(session => session.SessionGroupId === +group.SessionGroupId && this.isBatchAvailable(+group.SessionGroupId, availableSessions));
          this.classScheduleList.push(availableSessions.filter(session => session.SessionGroupId === +group.SessionGroupId && this.isBatchAvailable(+group.SessionGroupId, availableSessions)));
          if (selectedSession) {
            selectedSession.checked = this.data && this.data.SessionGroupId ? selectedSession.SessionGroupId === this.data.SessionGroupId : false;
            if (selectedSession.checked) {
              selectedSession.isCheckedIn = this.data && this.data.State == PartyState.Seated;
              this.displayClassSchedule(selectedSession.SessionGroupId);
            }
            this.classLessonList.push(selectedSession);
          }
        });
        if (this.data && this.data.SessionGroupId) {
          var selecteditem = this.classLessonList.filter(lesson => lesson.SessionGroupId == this.data.SessionGroupId);
          this.slotChangeListners(selecteditem[0], null);
        }
        else {
          if (this.detailComponetData) {
            let index = _.findIndex(this.classLessonList, (classbooking) => { return moment(classbooking['Start']).format('hh.mm A') === moment(this.detailComponetData['startTime']).format('hh.mm A') && moment(classbooking['End']).format('hh.mm A') === moment(this.detailComponetData['endTime']).format('hh.mm A') });
            if (index >= 0 && this.classLessonList[index].IsAddonAvailable) {
              this.classLessonList[index].checked = true;
              this.slotChangeListners(this.classLessonList[index], null);
            }
          }
        }
        if (this.detailComponetData?.selectedSlot && this.classLessonList.length > 0 && (this.classLessonList.filter(data => data.Availability.AvailabilityType == AvailabilityType.OpenToAll).length > 0 || this.classLessonList.filter(data => data.Availability.AvailabilityType == AvailabilityType.OpenToSpecificMember).length > 0)) {
          let session = this.classLessonList.filter(data => (data.Availability.AvailabilityType == AvailabilityType.OpenToAll || data.Availability.AvailabilityType == AvailabilityType.OpenToSpecificMember))
          if (session?.length > 0) {
            session.map(data => {
              if (data.SessionId === this.detailComponetData.SessionId && data.IsAddonAvailable) {
                data.checked = true;
              }
            })
            let selectedSession = session.find(sessionItem => sessionItem.checked == true)
            if (selectedSession) {
              this.displayClassSchedule(selectedSession.SessionGroupId);
            }
            else {
              let index = _.findIndex(this.classLessonList, (classbooking) => { return moment(classbooking['Start']).format('hh.mm A') === moment(this.detailComponetData['startTime']).format('hh.mm A') && moment(classbooking['End']).format('hh.mm A') === moment(this.detailComponetData['endTime']).format('hh.mm A') });
              if (index > -1) {
                this.displayClassSchedule(this.classLessonList[index].SessionGroupId);
              }
            }

          }

        }
      }
      if (this.isSessionClass && this.detailComponetData?.selectedSlot && this.SessionSlotsAvailableDates?.length > 0 && this.SessionSlotsAvailableDates[0].AvailableSessions.length > 0) {
        let session = this.SessionSlotsAvailableDates[0].AvailableSessions.filter(data => (data.Availability.AvailabilityType == AvailabilityType.OpenToAll || data.Availability.AvailabilityType == AvailabilityType.OpenToSpecificMember || (data.Availability.AvailabilityType == AvailabilityType.SoldOut && data.Availability.AvailableStandBySlots > 0)))
        if (session?.length > 0) {
          session.map(data => {
            if (data.SessionId === this.detailComponetData.SessionId && data.IsAddonAvailable) {
              data.checked = true;
            }
          })
        }
      }
      if (this.isSessionClass && this.data?.Type == PartyType.StandBy && this.SessionSlotsAvailableDates?.length > 0 && this.SessionSlotsAvailableDates[0].AvailableSessions.length > 0) {
        let session = this.SessionSlotsAvailableDates[0].AvailableSessions.filter(data => (data.Availability.AvailabilityType == AvailabilityType.SoldOut && data.Availability.AvailableStandBySlots > 0))
        if (session?.length > 0) {
          session.map(data => {
            if (this.data?.BookedSessions.map(item => item.ActivitySessionId)?.includes(data.SessionId)) {
              data.checked = true;
            }
          })
        }
      }
      if (this.activitySelected?.ClassType == ClassType.Class) {
        var selectedSessionGroupId = this.classLessonList?.find(data => data.checked == true)?.SessionGroupId;
        this.partyService.selectedSlot$.next(this.classScheduleList?.flat()?.filter(data => data.SessionGroupId == selectedSessionGroupId));
      }
      else {
        this.partyService.selectedSlot$.next(this.SessionSlotsAvailableDates?.length ? this.fetchSelectedSessions() : []);
      }

      //  this.partyService.SessionSlotsAvailableDates = this.SessionSlotsAvailableDates;
      this.setAllocationLegend(allocationCodes);

      if (this.data && this.data.Contact && bindEditContactData) {
        this.bindContactData()
      }

      if (this.data && this.data.Contact && this.partyService.selectedGuest && (this.HostRoleType.Name != RoleType[RoleType.Manager] && !this.partyService.selectedGuest.MemberActive)) {
        this.unselectAllocationSelectedSlots();
      }

      if (this.activitySelected && this.activitySelected.IsForStandbyReservations && this.data && this.data.isConfirmedReservation && this.partyService.reservationType == ReservationType.PrivateLessonBooking) {
        this.setBookingData();
        this.partyService.updateContactOnly = true;
        this.ps.formValid$.next(
          {
            isFormValid: true,
            currentTab: 0,
            gotoNextTab: true,
            tabsToNavigateCount: 1,
            disablePrevious: true
          } as IFormValidDetails
        );
        this.data.disablePrevious = true;
        setTimeout(() => {
          this.ps.previousButtonEnabled$.next(true);
        }, 500);
      }

      this.updateFormValidity();
      this.displaySlots = true;
    }))
  }

  checkAssetNotAvailable(response  , requestedObject , bindEditContactData , showError:boolean=false){
    if (this.sessionIdFromTimeLine && this.isLocationGroupMapped && this.isSessionClass) {
      let selectedSession = response.Payload.map(x => x.AvailableSessions).flat();
      let assetNotAvailable = selectedSession.find(session => session.SessionId == this.sessionIdFromTimeLine && session.Availability.AvailabilityType == AvailabilityType.AssetNotFound);
      if (assetNotAvailable && this.isInitialCheck && !showError && requestedObject.IsExclusiveBooking) {
        this.exclusiveBookingInfoMsg = this.ts.instant('exclusiveBookingInfoMsg');
        this.lastRequestedObject.IsExclusiveBooking = false;
        this.getFormComponent("IsExclusiveBooking")?.[0]?.form.controls.IsExclusiveBooking.setValue(false, { emitEvent: false });
        requestedObject.IsExclusiveBooking = false;
        
        this.getSlotsForClass(requestedObject, bindEditContactData , true)
        return;
      } else if (this.isLocationGroupMapped && !requestedObject.IsExclusiveBooking && this.isInitialCheck && this.sessionIdFromTimeLine && assetNotAvailable) {
        this.nonExclusiveBookingInfoMsg = this.ts.instant('nonExclusiveBookingInfoMsg');
       
      } else if(!showError){  
        this.exclusiveBookingInfoMsg = null;
        this.nonExclusiveBookingInfoMsg = null;
      }
    } else {
      this.exclusiveBookingInfoMsg = null;
      this.nonExclusiveBookingInfoMsg = null;
    }
    this.isInitialCheck = false;
  }

  fetchSelectedSessions(){
    let selectedSessions =[]
    this.SessionSlotsAvailableDates?.forEach(sessionsForDate => {
      selectedSessions.push(...sessionsForDate.AvailableSessions?.filter(data => data.checked == true))
    })
    return selectedSessions;
  }
  setAllocationLegend(allocationCodes) {
    this.availableAllocationCodes = this._settings.AllocationType.filter(type => allocationCodes.has(type.Id));
    var propertyId = Utilities.RestaurantId();
    if (this.availableAllocationCodes && this.availableAllocationCodes.length) {
      this.availableAllocationCodes.forEach(code => {
        let { Color: { R, G, B, A } } = code.AllocationPropertyMappings.find(mapping => mapping.PropertyId === propertyId);
        if (R !== (undefined || null) && G !== (undefined || null) && B !== (undefined || null) && A !== (undefined || null)) {
          code.color = `rgba(${R}, ${G}, ${B}, ${A})`
        }
      });
    }
  }

  unselectAllocationSLots() {
    this.SessionSlotsAvailableDates.forEach(availableDate => {
      availableDate.AvailableSessions.forEach(session => {
        if (!this.data && session.checked && session.Availability?.AvailabilityType == AvailabilityType.OpenToSpecificMember) {
          session.checked = false;
        }
      })
    });
    this.updateFormValidity();
  }

  getStateOfBookedSession(date, sessionId): PartyState {
    return this.data.BookedSessions.find(session => moment(session.BookedDate).diff(moment(date), 'days') == 0 && session.ActivitySessionId == sessionId).SessionState;
  }

  updateSlotBasedOnBookedSession(slot, date) {
    let addonOverbooked = !!this.data?.BookingContactAddonItems?.filter(({ OverBooked }) => OverBooked);
    // slot.checked = this.data && this.data.BookedSessions && this.data.BookedSessions.length > 0 && (slot.Availability.AvailabilityType != AvailabilityType.SoldOut || (this.data?.OverBooked && this.data?.OverBooked === this.partyService.bookingSize)) && (slot.IsAddonAvailable || addonOverbooked) ? this.isSlotBooked(date.AvailableDate, slot.SessionId) : false;
    slot.checked = this.data && this.data.BookedSessions && this.data.BookedSessions.length > 0 && (slot.Availability.AvailabilityType != AvailabilityType.SoldOut || (this.data?.OverBooked)) && (slot.IsAddonAvailable || addonOverbooked) ? this.isSlotBooked(date.AvailableDate, slot.SessionId) : false;
    if(this.data?.Size == this.partyService?.bookingSize) {
      slot.OverBooked = slot.checked ? (this.data?.OverBooked || 0) : 0;
    }
    else {
      slot.OverBooked = slot.checked && (slot.Availability.AvailableSlots - this.partyService.bookingSize) < 0 ? Math.abs(slot.Availability.AvailableSlots - this.partyService.bookingSize) : 0;
    }
    // if (this.data?.OverBooked && this.data?.OverBooked !== this.partyService.bookingSize) {
    //   this.data.OverBooked = 0;
    //   slot.OverBooked = 0;
    // }
    if (slot.checked && slot.OverBooked) {
      slot.isOverbook = true;
      slot.isStandBy = false;
    } else if (slot.checked && this.data?.Type === PartyType.StandBy) {
      slot.isOverbook = false;
      slot.isStandBy = true;
    }
    if (slot.checked && this.data && this.data.BookedSessions && this.data.BookedSessions.length > 0 && this.getStateOfBookedSession(date.AvailableDate, slot.SessionId) == PartyState.Seated) {
      //slot.Availability.AvailabilityType = AvailabilityType.SelectionCriteriaNotMatched;
      slot.isCheckedIn = true
      slot.checked = false;
    }
    if (slot.checked && this.data && this.data.BookedSessions && this.data.BookedSessions.length > 0 && this.getStateOfBookedSession(date.AvailableDate, slot.SessionId) == PartyState.Left) {
      //slot.Availability.AvailabilityType = AvailabilityType.SelectionCriteriaNotMatched;
      slot.isCheckedOut = true;
      slot.checked = false;
    }
    if (this.data && this.data.BookedSessions && !this.data.isConfirmedReservation) {
      slot.isConfirmedReservation = this.updatesessionslot(slot.SessionId);
      slot.checked = slot.checked && slot.isConfirmedReservation ? false : slot.checked;
    }
  }

  isSlotBooked(date, sessionId): boolean {
    return this.data.BookedSessions.findIndex(session => moment(session.BookedDate).diff(moment(date), 'days') == 0 && session.ActivitySessionId == sessionId && session.SessionState != PartyState.Cancelled) > -1;
  }

  updatesessionslot(slot) {
    const selectedSlot = this.data.BookedSessions.filter(session => session.ActivitySessionId === slot);
    if (selectedSlot && selectedSlot.length) {
      return selectedSlot[0].isConfirmedStandby ? true : false
    } else {
      return false;
    }
  }


  getCoverTypesize(): number {
   // return 1;
    return this.sizeMapping.sizes.reduce((res, curr) => res + (+curr.Covers), 0);
  }



  updateSlot() {
    this.data.BookedSessions = this.data.BookedSessions.map(element => {
      if (element.SessionType === PartyType.Reservation && this.data.Type == PartyType.StandBy) {
        element.isConfirmedStandby = true;
      } else {
        element.isConfirmedStandby = false;

      }
      return element;
    });
  }
  getSlotsByLocation(bindEditContactData) {
    var [fromDate, toDate, location, activity, instructor, IsExclusiveBooking] = this.getFormComponent("fromDate", "toDate", "location", "class", "instructor" , 'IsExclusiveBooking');
    var bookingSize = this.getCoverTypesize();
    if (location && location.form.get("location").value
      && instructor && bookingSize) {
      this.partyService.selectedOpenBookingSlots = {};

      this.partyService.slots_holder = [];
      this.displaySlots = false;

      var locations = location.form.get("location").value == -1 ? [] : [location.form.get("location").value]
      var instructors = (instructor.form.get("instructor").value == -1 || instructor.form.get("instructor").value === '') ? [] : [instructor.form.get("instructor").value]
      if ((locations[0] && !(locations[0] > 0)) || (instructors[0] && !(instructors[0] > 0))) {
        return;
      }
      let _lockIdToIgnore = null;

      if(this.data?.CartId){
        if(this.data.bookingRatePlan.SlotLockResultDTO){
          _lockIdToIgnore = this.data.bookingRatePlan.SlotLockResultDTO?.SlotLockIdDTO?.Id;
        }else if(this.data.bookingRatePlan.SlotLockIdDTO){
          _lockIdToIgnore = this.data.bookingRatePlan.SlotLockIdDTO?.Id;
        }
      }
      let _isExclusiveBooking = IsExclusiveBooking?.value.IsExclusiveBooking || false;
      var requestedObject = {
        ActivityId: +activity.form.controls.class.value,
        AvailabilityCriteria: this.getAvailabilityCriteria(locations, instructors),
        StartDate: moment(fromDate.form.get("fromDate").value).format("YYYY-MM-DD"),
        EndDate: moment(toDate.form.get("toDate").value).format("YYYY-MM-DD"),
        PreferredLocationId: locations,
        PreferredStaffId: instructors,
        BookingSize: +bookingSize,
        ClassType: activity.form.controls.classType.value,
        reservationIdToIgnore: this.data ? this.data.Id : null,
        lockIdToIgnore: _lockIdToIgnore, 
        IsExclusiveBooking: _isExclusiveBooking
      } as ActivitySlotRequestDTO;
      if (JSON.stringify(requestedObject) != JSON.stringify(this.lastRequestedObject) && requestedObject.ActivityId > 0) {
        this.lastRequestedObject = { ...requestedObject };
        this.selectedClassSchedule = [];
        this.selectedIndex = 0;
        this.getSlotsForClass(requestedObject, bindEditContactData);
      } else {
        this.displaySlots = true;
      }
    }
  }

  navClick(moveDirection) {
    if ((moveDirection === 1 && this.selectedIndex === this.SessionSlotsAvailableDates.length - 1)
      || (moveDirection === -1 && this.selectedIndex === 0)) {
      return;
    }
    this.selectedIndex = this.selectedIndex + moveDirection;
  }


  setSlots(response, partySize?) {
    response.Payload.forEach(element => {
      element.OpenHours.forEach(openHour => {
        openHour.SlotsByTableId[0].Slots.forEach(slot => {
          if (!slot.PartyId) {
            if (slot.AllocationTypeId) {
              let { R, G, B, A } = this._settings.AllocationType.find(type => type.Id === slot.AllocationTypeId)['AllocationPropertyMappings'][0].Color;
              slot['color'] = `rgba(${R}, ${G}, ${B}, ${A})`;
              //let isAnyMember = this._settings.AllocationType.find(type => type.Id === slot.AllocationTypeId).AllocationName == 'AnyMembers';
              let isAnyMember = Utilities.IsMember(slot);
              slot.AllocationName = isAnyMember;
            }
            this.partyService.SlotIds += 1;
            slot.Id = slot.Id == 0 ? this.partyService.SlotIds : slot.Id;
            this.partyService.slots_holder.push(slot);
          }
        });
      });
    });
    this.displaySlots = true;
  }

  isValidSlotSelectedByType(): boolean{
    const alocationTypesSelected = this.getSlotsObj().map(slot => slot?.sessions.filter(session => session.Availability.AllocationTypeId).map(session => session.Availability.AllocationTypeId)).flat()
    return Utilities.validMemberTypesSelected(this._settings.AllocationType, alocationTypesSelected)
  }

  calculatePartySize() {
    this.bookingSizeArray = [];
    for (let i = 1; i <= this.maxSizeAllowed; i++) {
      this.bookingSizeArray.push({ id: i, value: i.toString() });
    }
    // this.bookingSizeArray.push({ id: this.maxSizeAllowed, value: this.maxSizeAllowed.toString() + "+" });

    return this.bookingSizeArray;
  }

  setMemberDetailsArray() {
    this.MemberDetailsArray = [];
    this.memberShipArr.forEach((element, index) => {
      this.MemberDetailsArray.push({
        id: index,
        value: this.getMemberOptionValue(element, index)
      })
    });
    this.membershipDetailConfig[0].options = this.MemberDetailsArray;
  }

  getMemberOptionValue(data, index) {
    return '' + (index + 1) + ' ' + data.FName + ' - ' + data.CardNo + ' - ' + data.Email + ' - ' + data.PhoneNumber
  }

  onSizeChange(){
    this.partyService.bookingSize = this.getCoverTypesize();
    // this.getSlotsByLocation(false);
    this.getSlotsByLocation(false);
    this.updateFormValidity();
  }

  formChangeSubscribe() {
    this.components.forEach((x, index) => {
      this.subscriptions.add(x.form?.valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(value => {
          if (value.class && !(value.class > 0)) {
            return;
          }
          if (value.class && this.ActivityList.find(activity => activity.Id == value.class)) {
            var activitySelected = this.ActivityList.find(activity => activity.Id == value.class);
            this.showSalesContact = activitySelected?.TrackingofSalesPerson || false
            if (this.showSalesContact) {
              this.salesContactUsers();
            }
            this.data = null;
            this.partyService.selectedSpecialMealId = activitySelected.Id;
            // this.partyService.addOrRemoveAddOn(this.partyService.selectedSpecialMealId)
            this.partyService.activityChanges$.next(activitySelected);
            if (activitySelected && activitySelected.CustomFieldValidation) {
              this.partyService.customFieldValidation = activitySelected.CustomFieldValidation
              this.partyService.isStandBy = activitySelected.IsForStandbyReservations;
            }
            this.maxSizeAllowed = activitySelected.MaxPartySize;
            this.minSizeAllowed = activitySelected.MinPartySize;
            if (this.isClassTypeUpdated) {
              this.isClassTypeUpdated = false;
              return;
            }
            if (!this.isClassTypeUpdated && activitySelected.ClassType != this.getFormComponent("class")[0].form.controls.classType.value) {
              this.getFormComponent("class") && this.getFormComponent("class")[0].form.controls.classType.setValue(activitySelected.ClassType);
              this.isClassTypeUpdated = true;
            }
            this.isSessionClass = activitySelected.ClassType === ClassType.Session;
            this.setExclusiveBooking(activitySelected);
            this.AllowStandBy = activitySelected.AllowStandBy;
            this.AllowOverbook = activitySelected.AllowOverbook;
            this.locationConfig[0].options = this.locationList.filter(location => {
              return !!(activitySelected.ActivitySessions.filter(session => !!(session.SessionLocationMappings.find(elm => elm.StandaloneTableId === location.id))).length)

            });
            this.instructorConfig[0].options = this.instructorList.filter(server => {
              return !!(activitySelected.ActivitySessions.filter(session => !!(session.SessionStaffMappings.find(elm => elm.ServerId === server.id))).length)

            });

            this.includeAny(this.locationConfig[0].options);
            this.includeAny(this.instructorConfig[0].options);
            var [activity, fromDate, toDate, location, instructor] = this.components;
            this.locationConfig[0].value = -1;
            this.instructorConfig[0].value = -1;
            if (this.detailComponetData && this.detailComponetData.locationId) {
              this.components['_results'][3].form.controls.location.setValue(this.detailComponetData.locationId);
              this.detailComponetData.locationId = null;
            } else {
              location.form.controls.location.setValue(this.locationConfig[0].options[0]?.id);
            }
            if (this.detailComponetData && this.detailComponetData.instructorId) {
              this.components['_results'][4].form.controls.instructor.setValue(this.detailComponetData.instructorId);
              this.detailComponetData.instructorId = null;
            }
            else {
              instructor.form.controls.instructor.setValue(this.instructorConfig[0].options[0]?.id);
            }
            if (activitySelected.ClassType == ClassType.Class) {
              this.getFormComponent("fromDate")[0].form.controls.fromDate.setValue(new Date((activitySelected.StartDate)));
              this.getFormComponent("toDate")[0].form.controls.toDate.setValue(new Date(activitySelected.EndDate));
              this.bookingFromDateConfig[0].disabled = true;
              this.bookingToDateConfig[0].disabled = true;
            } else {
              var restaurantDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)
              if (new Date(activitySelected.StartDate) > new Date(this.appService._headerDate)
                && new Date(activitySelected.StartDate) > restaurantDate) {
                this.getFormComponent("fromDate")[0].form.controls.fromDate.setValue(this.weeklyViewDate ? new Date(this.weeklyViewDate) : new Date(activitySelected.StartDate));
              } else if (new Date(this.appService.headerDate$.value) > restaurantDate) {
                this.getFormComponent("fromDate")[0].form.controls.fromDate.setValue(this.weeklyViewDate ? new Date(this.weeklyViewDate) : new Date(this.appService.headerDate$.value));
              }
              // else {
              //   this.getFormComponent("fromDate")[0].form.controls.fromDate.setValue(this.weeklyViewDate ? new Date(this.weeklyViewDate) : restaurantDate);
              // }
              // }
              if (this.bookPastReservations) {
                this.getFormComponent("fromDate")[0].form.controls.fromDate.setValue(this.weeklyViewDate ? new Date(this.weeklyViewDate) : (new Date(activitySelected.StartDate) > this.appService.headerDate$.value)?activitySelected.StartDate : this.appService.headerDate$.value);
                this.getFormComponent("toDate")[0].form.controls.toDate.setValue(this.weeklyViewDate ? new Date(this.weeklyViewDate) : (new Date(activitySelected.StartDate) > this.appService.headerDate$.value)?activitySelected.StartDate : this.appService.headerDate$.value);
              }else {
                let selectedDate = this.weeklyViewDate ? new Date(this.weeklyViewDate) : this.appService.headerDate$.value;
                selectedDate = restaurantDate > selectedDate ? restaurantDate : selectedDate;
                this.getFormComponent("fromDate")[0].form.controls.fromDate.setValue(new Date(activitySelected.StartDate) > selectedDate ? activitySelected.StartDate : selectedDate);
                this.getFormComponent("toDate")[0].form.controls.toDate.setValue((new Date(activitySelected.StartDate) > selectedDate )?activitySelected.StartDate : selectedDate);
              } 
              this.bookingFromDateConfig[0].minDate = !this.bookPastReservations ? new Date(activitySelected.StartDate) > restaurantDate ? new Date(activitySelected.StartDate) : restaurantDate : (this.dashboardFunctions.getMinDateBasedonConfig(activitySelected, restaurantDate));
              this.bookingToDateConfig[0].minDate = !this.bookPastReservations ? new Date(activitySelected.StartDate) > restaurantDate ? new Date(activitySelected.StartDate) : restaurantDate : (this.dashboardFunctions.getMinDateBasedonConfig(activitySelected, restaurantDate));
              this.getFormComponent("toDate")[0].form.controls.toDate.setValue((Utilities.controlValidate(controlSettings.Booking_Shows_CurrentDate, this.appService.PropertyType) ? this.getFormComponent("fromDate")[0].form.controls.fromDate.value : new Date(activitySelected.EndDate)))
              this.bookingFromDateConfig[0].maxDate = activitySelected.EndDate
              this.bookingToDateConfig[0].maxDate = activitySelected.EndDate;

              this.bookingFromDateConfig[0].disabled = false;
              this.bookingToDateConfig[0].disabled = false;
            }
            this.getCoverTypesForSelectedActivity(activitySelected.Id);
            this.getBookingTypesForSelectedActivity(activitySelected.Id);
            if (!activitySelected.IsPrepaymentRequired) {
              this.showActivityRatePlanInfo();
            }
          }
          if (activitySelected?.ClassType == ClassType.Session && Utilities.controlValidate(controlSettings.Booking_Shows_CurrentDate, this.appService.PropertyType) && value.fromDate) {
            this.getFormComponent("toDate")[0].form.controls.toDate.setValue(new Date(this.getFormComponent("fromDate")[0].form.controls.fromDate.value))
            this.bookingToDateConfig[0].disabled = true;
          }
          if (value.fromDate || value.toDate) {
            var frDateObj = this.getFormComponent("fromDate")[0].form.controls.fromDate.value;
            var toDateObj = this.getFormComponent("toDate")[0].form.controls.toDate.value;
            if (value.fromDate) {
              this.bookingToDateConfig[0].minDate = frDateObj;
            }
            this.invalidDate = Utilities.diffBetweenDates(toDateObj, frDateObj) < 0;
          }
          if (value.fromDate && (this.getFormComponent("toDate")[0].form.controls.toDate.value < value.fromDate)) {

            this.getFormComponent("toDate")[0].form.controls.toDate.setValue(new Date(this.getFormComponent("fromDate")[0].form.controls.fromDate.value));
          }
          if (Utilities.controlValidate(controlSettings.Booking_Shows_CurrentDate, this.appService.PropertyType) && value.fromDate && activitySelected?.ClassType == ClassType.Session) {
            this.getFormComponent("toDate")[0].form.controls.toDate.setValue(new Date(this.getFormComponent("fromDate")[0].form.controls.fromDate.value))

            this.bookingToDateConfig[0].disabled = true;

          }
          if (((value.location && this.locationList.find(element => element.id == value.location)) || value.location == -1 || value.fromDate || value.toDate
            || (value.instructor && this.instructorList.find(instructor => instructor.id == value.instructor)) || value.instructor == -1 || value.class || (value.IsExclusiveBooking != null && value.IsExclusiveBooking != undefined)) && !this.invalidDate && (this.getFormComponent("class")[0] && this.getFormComponent("class")[0].form.controls.class.value)) {
            this.getSlotsByLocation(false);
            this.isValidConfig = true;
          }
          else {
            let locationForm = this.getFormComponent('location')[0].form;
            let instructorForm = this.getFormComponent('instructor')[0].form;
            if (locationForm.invalid || (locationForm.value.location != -1 && !this.locationList.find(element => element.id == locationForm.value.location)) || instructorForm.invalid || (instructorForm.value.instructor != -1 && !this.instructorList.find(element => element.id == instructorForm.value.instructor))) {
              this.isValidConfig = false;
              this.toastrService.error(this.ts.instant('Select a valid', { name: this.ts.instant('location'), value: this.ts.instant('instructor') }), '', {
                timeOut: 3000
              });
            }
          }

          if (this.partyService.selectedGuest?.MembershipType && (value.membershipId == "") && !this.memberShipdetail) {
            this.memberSearchClick();
          }
          this.updateFormValidity();
          if (this.data && this.data.Contact && this.partyService.reservationFormGroup) {
            this.partyService.reservationFormGroup.markAsDirty();
            this.partyService.reservationFormGroup.updateValueAndValidity();
          }
        }));
    });
  }

  setExclusiveBooking(activitySelected){
    if(this.isSessionClass){
      this.isLocationGroupMapped = activitySelected.ActivitySessions.filter(location => location.LocationGroupId)?.length > 0;
      this.getFormComponent("IsExclusiveBooking")?.[0]?.form.controls.IsExclusiveBooking.setValue((activitySelected?.AssetBookingType == AssetBookingType.ExclusiveBooking) ? true : false , { emitEvent: false } );
    }else {
      this.isLocationGroupMapped = false;
      this.getFormComponent("IsExclusiveBooking")?.[0]?.form.controls.IsExclusiveBooking.setValue(false  , { emitEvent: false }); 
    }
  
  }

  removeDeletedTypes(typeConfig: FieldConfig[]): FieldConfig[]{
    return typeConfig.filter(_configType => {
      return _configType.value || _configType.isRemoved === false;        
    })
  }

  memberSearchClick() {
    let memberControl: DynamicFormComponent = this.components.last;
    let memberSearchText: string = this.getFormValue(memberControl, "membershipId");
    if (memberSearchText && memberSearchText.length) {
      this.memberSearchHandler(memberSearchText);
    }
  }

  memberSearchHandler(memberSearchText: string) {
    this.hasMembershipValue = memberSearchText.length > 0;
    this.memberShipdetail = {};
    this.partyService.selectedGuest = null;
    this.partyService.sessionBookingData = null;
    this.partyService.selectedMembershipDetail$.next(false);
    this.CheckMemberShip(memberSearchText, this.partyService.reservationFormGroup.value.selectedGuest ? this.partyService.reservationFormGroup.value.selectedGuest : null);
    this.unselectAllocationSelectedSlots();
  }


  getCoverTypesForSelectedActivity(activityId) {

    let activitySelected = this._settings.SpecialMeals.find(activity => activity.Id == activityId);
    this.coverTypes = activitySelected.CoverTypePrices.map(type => {
      return {
        Id: type.Id,
        Name: type.CoverTypeName,
        MinimumQuantity: type?.MinimumQuantity
      }
    });
  }

  getBookingTypesForSelectedActivity(activityId) {

    let activitySelected = this._settings.SpecialMeals.find(activity => activity.Id == activityId);
    this.bookingTypes = activitySelected.BookingTypeActivitiesMappings.map(type => {
      let activeType = this._settings.BookingTypes.find(_sBookingType => _sBookingType.Id === type.Id);
      return {
        Id: type.Id,
        Name: type.BookingTypeName || type.Name,
        Active: !!activeType
      }
    });
  }

  showActivityRatePlanInfo() {
    let message = this.ts.instant('noRatePlanApplicableForBooking');
    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: 'Rate Plan Info', showAlert: true
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: this.ts.instant('alert')
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: 'auto',
      data: {
        showAction: true,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.ActivityRatePlanInfo
      }
    });
  }


  CheckMemberShip(membershipId, editContactData) {
    this.selectedMemberDetail = null;
    this.subscriptions.add(this.apiService.wildSearchEngageMember(membershipId).subscribe(response => {
      this.memberShipArr = [];
      this.memberShipArr = response.Payload;
      this.MemberDetailsArray = [];
      this.memberShipArr.forEach((element, index) => {
        this.MemberDetailsArray.push({
          id: index,
          value: this.getMemberOptionValue(element, index)
        })
      });
      this.membershipDetailConfig[0].options = this.MemberDetailsArray;
      this.membershipDetailConfig[0].value = membershipId;
      if (this.MemberDetailsArray && this.MemberDetailsArray.length === 1) {
        this.components.last.form.controls['membershipId']?.setValue(this.MemberDetailsArray[0].value);
        this.setSelectedGuest();
      } else if (this.MemberDetailsArray && this.MemberDetailsArray.length > 1) {
        document.getElementById("memberDetail")?.querySelector("input")?.focus();
      }
    }));
  }

  setSelectedMembershipDetail(memberShip: EngageMemberDetailDTO, editContactData) {
    let memberShipdetail = memberShip.ProfileInfoGetByCardId.ProfileValue;
    var guestObject = {
      FirstName: memberShipdetail.FName,
      LastName: memberShipdetail.LName,
      EmailAddress: memberShipdetail?.EmailList[0]?.EmailId,
      PhoneNumber: memberShipdetail?.PhoneList[0]?.PhoneNumber,
      CountryId: this.partyService.getCountryId(memberShipdetail?.PhoneList[0]?.PhoneCode),
      PIILastUsedDate: null,
      Birthday: memberShipdetail.Dob,
      ContactCustomFields: [],
      Notes: [],
      VisitStats: [],
      MarketingOptedIn: null,
      MemberAllocationTypesIds : [],
      memberShipId: memberShipdetail.CardNo,
      RateType: memberShipdetail.RateType,
      MembershipType: memberShipdetail.MembershipType,
      MemberRateTypeAfterRoundsUsed : memberShipdetail.RateTypeAfterRoundsUsed,
      MemberRateTypeMembershipExpiry : memberShipdetail.RateTypeMembershipExpiry,
      MemberActive: !memberShipdetail.IsMembershipExpired
    }
    if (editContactData) {
      editContactData.memberShipId = memberShipdetail.CardNo;
      editContactData.MembershipType = memberShipdetail.MembershipType;
      editContactData.RateType = memberShipdetail.RateType;
      editContactData.MemberActive = memberShipdetail.MembershipStatus && memberShipdetail.MembershipStatus?.toLowerCase() === "active";
    }
    this.partyService.selectedGuest = editContactData ? editContactData : guestObject;
    if (this._settings.General.UseMembershipNumber) {
      this.partyService.selectedGuest.TrackMembershipNumber = memberShipdetail.CardNo; // to bind it in the guest form
    }
    if(memberShip.GolfPoints){
      this.partyService.selectedGuest['GolfPoints'] = memberShip.GolfPoints;
    }
    if (!this.partyService.selectedGuest.MemberActive) {
      this.unselectAllocationSelectedSlots();
    }
    const allocationTypes = this._settings.AllocationType
                            .filter(allocation => allocation.AllocationName === guestObject.RateType ||
                              allocation.AllocationName === guestObject.MemberRateTypeAfterRoundsUsed ||
                              allocation.AllocationName === guestObject.MemberRateTypeMembershipExpiry);
    if(this.partyService.selectedGuest && allocationTypes){
      this.partyService.selectedGuest.MemberAllocationTypesIds =  allocationTypes.map(a=>a.Id);
    } 

    this.partyService.selectedMembershipDetail$.next(true);
    this.updateFormValidity();
  }

  unselectAllocationSelectedSlots() {
    if (this.isSessionClass) {
      this.unselectAllocationSLots();
    }
    else {
      this.classLessonList.forEach(session => {
        if (!this.data && session.checked && session.Availability?.AvailabilityType == AvailabilityType.OpenToSpecificMember) {
          session.checked = false;
          this.selectedClassSchedule = [];
        }
      });

    }
    this.updateFormValidity();
  }

  isBatchAvailable(sessionGroupId, availableSessions): boolean {
    var sessionsByGroupId = availableSessions.filter(session => session.SessionGroupId === +sessionGroupId && session.Availability?.AvailabilityType != AvailabilityType.SelectionCriteriaNotMatched);
    //var sessionsByGroupIdOrderedByEnddate = _.orderBy(sessionsByGroupId, "End", "desc");
    if (sessionsByGroupId && sessionsByGroupId.length) {
      return new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)) < new Date(sessionsByGroupId[0].End)
    }
    else {
      return false;
    }

  }


  isStandByBookingSelected(groupId?) {
    if (groupId) {

      let groupIdList: any[] = Utilities.uniqueBy(this.availableSessions, "SessionGroupId", "DayOfWeek", "SessionId");
      this.selectedClassSchedule = [];
      groupIdList = groupIdList.filter(group => group.SessionGroupId == groupId);
      for (let i = 0; i < groupIdList.length; i++) {
        let groupDetail = groupIdList[i];
        let selectedItem = this.availableSessions.find(session => session.SessionGroupId == groupDetail.SessionGroupId && session.DayOfWeek == groupDetail.DayOfWeek && session.SessionId == groupDetail.SessionId);

        if (selectedItem.Availability.AvailabilityType === AvailabilityType.SoldOut) {
          return true;
        }
      }
    } else {
      for (let i = 0; i < this.SessionSlotsAvailableDates.length; i++) {
        let isStandByBookingSelected = this.SessionSlotsAvailableDates[i].AvailableSessions.find(session => session.checked && session.Availability.AvailabilityType === 2 && !session.OverBooked)
        if (isStandByBookingSelected) {
          return true;
        }
      }
      return false;
    }

  }

  applyToOtherDates(selectedDateIndex) {
    if (this.isMultiSlotSelection && this.isStandByBookingSelected()) {
      this.toastrService.show(this.ts.instant('MultiStandbySelectErrorInfoMessage'));
      return;
    }
    if (this.partyService.selectedGuest && this.partyService.selectedGuest.MembershipType) {
      var { MemberActive: memberActive, MembershipType: { Id: memberid } } = this.partyService.selectedGuest;
    }
    var selectedSessionIndexes = this.SessionSlotsAvailableDates[selectedDateIndex].AvailableSessions.map((session, index) => session.checked ? index : -1).filter(sessionIndex => sessionIndex != -1);
    this.sessionsUnavailable = [];
    var restaurantDateTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)
    if (!selectedSessionIndexes || !selectedSessionIndexes.length) {
      this.toastrService.warning(this.ts.instant("selectSessionsError"), "", { timeOut: 10000, closeButton: true });
      return;
    }
    this.SessionSlotsAvailableDates.forEach(selectedDate => {
      selectedDate.AvailableSessions = selectedDate.AvailableSessions.map(session => {
        session.checked = false;
        return session;
      })
      selectedSessionIndexes.forEach(index => {
        if (selectedDate.AvailableSessions[index]
          && (
            selectedDate.AvailableSessions[index].Availability?.AvailabilityType === AvailabilityType.OpenToAll
            || (selectedDate.AvailableSessions[index].Availability?.AvailabilityType === AvailabilityType.OpenToSpecificMember
              && ((selectedDate.AvailableSessions[index].Availability?.AllocationTypeId == memberid)
                || (Utilities.IsMember(selectedDate.AvailableSessions[index].Availability) && memberid)) && memberActive)
          )
        ) {
          if (!selectedDate.AvailableSessions[index].End || (new Date(selectedDate.AvailableSessions[index].End) > restaurantDateTime)) {
            selectedDate.AvailableSessions[index].checked = true;
          }
        } else {
          if (!this.sessionsUnavailable[index] || !this.sessionsUnavailable[index].dates) {
            this.sessionsUnavailable[index] = { session: this.ts.instant("Session") + " " + (index + 1) + " " + this.ts.instant("not available on") + " ", dates: "" }
          }
          this.sessionsUnavailable[index].dates += this.sessionsUnavailable[index].dates ? ", " + moment(selectedDate.AvailableDate).format("LL") : moment(selectedDate.AvailableDate).format("LL");
        }
      });
    });
    this.sessionsUnavailable = this.sessionsUnavailable.filter(item => item)
    if (!this.sessionsUnavailable || !this.sessionsUnavailable.length) {
      this.toastrService.success(this.ts.instant("Applied"), "", { timeOut: 3000, closeButton: true });
    } else {
      var errObj = {
        ErrorDetails: {
          Error: this.sessionsUnavailable.map(session => {
            return { msg: session.session + session.dates }
          })
        },
        ErrorType: true,
        dialogTitle: 'error'
      }
    }
  }

  resetAllSelected() {
    this.SessionSlotsAvailableDates.forEach(selectedDate => {
      selectedDate.AvailableSessions = selectedDate.AvailableSessions.map(session => {
        session.checked = false;
        return session;
      })
    });
    this.updateFormValidity();
  }

  isFormValid() {
    var isValid = true;
    isValid = this.components?.last?.form.valid && this.components.last.form.status !== 'DISABLED';
    let [locationForm, staffForm] = this.getFormComponent("location", "instructor");

    if((locationForm && locationForm.form.status !== 'VALID')
    || (staffForm && staffForm.form.status !== 'VALID')){
      isValid = false;
    }
    return isValid && this.sizeMapping?.valid;
  }

  istimeSlotsSelected() {
    if (!this.bookPastReservations) {
      const restaurantDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)
      return this.SessionSlotsAvailableDates && (this.SessionSlotsAvailableDates.filter(itm => {
        return itm.AvailableSessions.filter(session => session.checked && restaurantDate < new Date(session.End)).length > 0
      }).length > 0);
    }
    else {
      return this.SessionSlotsAvailableDates && (this.SessionSlotsAvailableDates.filter(itm => {
        return itm.AvailableSessions.filter(session => session.checked).length > 0
      }).length > 0);
    }
  }

  updateFormValidity() {
    if(this.ps.tabsActionData?.length > 0) {
    this.ps.tabsActionData[0].gotoNextTab = this.isFormValid() && this.isValidBookingSizeSelected() && this.istimeSlotsSelected() && this.isValidSlotSelectedByType() && this.isValidConfig;
   // this.ps.tabsActionData[this.ps.tabsActionData.length - 1].showAddToCart = false;
    this.ps.tabsActions$.next(this.ps.tabsActionData);
  }
  }

  addOnaAvailableForBooking() {
    return true;
  }

  isValidBookingSizeSelected(){
    return this.sizeMapping.valid;
  }

  setBookingData() {
    this.partyService.openBookingData = null;
    var [activity, fromDate, toDate, location, instructor, memberShipDetails , IsExclusiveBooking] = this.getFormComponent("class", "fromDate", "toDate", "location", "instructor", "memberShipDetails" , "IsExclusiveBooking");

    var locations = location.form.get("location").value == -1 ? [] : [location.form.get("location").value]
    var instructors = instructor.form.get("instructor").value == -1 ? [] : [instructor.form.get("instructor").value]
    let bookingSizeValue = this.sizeMapping.sizes.reduce((res, curr) => +curr.Covers + res, 0);
    this.partyService.bookingSize = bookingSizeValue;
    this.partyService.sessionBookingData = new SessionBookingDTO(
      this.data?.Id || null,
      this.getFormValue(activity, "class"),
      this.getFormValue(activity, "classType"),
      this.getFormValue(fromDate, "fromDate"),
      this.getFormValue(toDate, "toDate"),
      locations,
      locations.map(locationId => this.getLocationName(locationId)),
      instructors,
      bookingSizeValue,
      this.getFormValue(this.salesContactForm, 'salescontacts') || [],
      this.getFormValue(memberShipDetails, "memberShipDetails"),
      this.sizeMapping.sizes,
      [],
      this.getSlotsObj(),
      this.getSessionGroupId(),
      this.partyService.selectedSpecialMealId,
      null,
      this.getFormValue(IsExclusiveBooking, "IsExclusiveBooking")
    );

    if(this.data?.PageMethod){
      this.partyService.sessionBookingData.PageMethod = this.data.PageMethod;
    }
    
    let groupId = this.getSessionGroupId();
    this.partyService.sessionBookingData.AllowStandBy = this.isStandByBookingSelected(groupId);
    this.partyService.BookingBehaviour = BookingBehavior.ClassOrSession;

    if (this.partyService.sessionBookingData.ClassType == ClassType.Class) {
      let _classData = this.classLessonList?.find(({ checked }) => checked);
      this.partyService.sessionBookingData.OverBooked = _classData.isOverbook ? (_classData?.OverBooked || 0) : 0;
    } else if (this.partyService.sessionBookingData.Slots[0]?.sessions?.length) {
      let _sessionData = this.partyService.sessionBookingData.Slots[0]?.sessions[0];
      this.partyService.sessionBookingData.OverBooked = _sessionData?.isOverbook ? (_sessionData?.OverBooked || 0) : 0;
    }

    this.partyService.sessionBookingData.IsOverBook = this.partyService.sessionBookingData.Slots[0]?.sessions[0]?.isOverbook;

    this.partyService.sessionBookingData.SessionGroupName = this.getSessionGroupDetail(this.partyService.sessionBookingData.SessionGroupId, 'SessionGroupName');
    this.partyService.sessionBookingData.SessionGroupName = this.getSessionGroupDetail(this.partyService.sessionBookingData.SessionGroupId, 'OverBooked');
    this.partyService.sessionBookingData.AvailabilityCriteria = this.getAvailabilityCriteria(locations, instructors);
  }

  getLocationName(locationId) {
    return this.locationList.find(location => location.id == locationId).value;
  }

  calculateEndTime(startTimeStr, duration) {
    var startTime = new Date(startTimeStr);
    var startMins = startTime.getMinutes();
    startTime.setMinutes(startMins + duration);
    return startTime;
  }

  getFormValue(formObj, fieldName) {
    return formObj && formObj.form.get(fieldName)?.value;
  }

  getSlotsObj() {
    var resSlots = [];

    this.SessionSlotsAvailableDates.forEach(availableDate => {
      var sessionsSelected = availableDate.AvailableSessions
        .filter(session => session.checked);
      sessionsSelected.forEach(session => {
        if (session.Availability.AvailabilityType == AvailabilityType.OpenToSpecificMember) {
          this.partyService.savesessions = true;
        }
      });
      if (sessionsSelected.length) {
        resSlots.push({
          Date: availableDate.AvailableDate, SessionIds: sessionsSelected
            .map(session => session.SessionId), sessions: sessionsSelected
        })
      }
    });

    return resSlots;
  }

  getSessionGroupId() {
    if (!this.isSessionClass) {
      if (!this.selectedClassSchedule.length && this.data) {
        return this.data.SessionGroupId;
      } else if (this.selectedClassSchedule.length) {
        this.selectedClassSchedule.forEach(session => {
          session.forEach(element => {
            if (element.Availability.AvailabilityType == AvailabilityType.OpenToSpecificMember) {
              this.partyService.savesessions = true;
            }
          });
        });
        return this.selectedClassSchedule.find(item => item)[0].SessionGroupId;
      }
      else if (this.partyService.sessionBookingData?.SessionGroupId) {
        return this.partyService.sessionBookingData?.SessionGroupId;
      }
      let selectedGroup = this.classLessonList.find(group => group.checked);
      if (selectedGroup) {
        return selectedGroup.SessionGroupId;
      }
    }
    return null;
  }

  getSessionGroupDetail(groupId, prop) {
    return this.classLessonList.find(group => group.SessionGroupId === groupId)?.['prop'];
  }

  clearFormField(name, event) {
    var [formField] = this.getFormComponent(name);
    formField.form.controls[`${name}`].setValue("");
  }
  getFormComponent(...args) {
    return args.map(arg => this.components?.find(form => form && form.form ? form.form?.value.hasOwnProperty(arg) : form?.value.hasOwnProperty(arg)))
  }


  resetOnObenBookingTerminate() {
    this.partyService.selectedMembershipDetail$.next(false);
    if (!this.partyService.reservationFromGuestBook) {
      this.partyService.selectedGuest = null;
    }
    this.partyService.selectedOpenBookingSlots = {};
    this.partyService.sessionBookingData = null;
    this.partyService.slots_holder = [];
    this.partyService.SlotIds = 0;
    if (!Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)) {
      this.partyService.sessionBookingDataSaveObj = null;
    }
    //this.partyService.slotLockId = null;
  }

  clearmembershipDetail(event) {
    const [memberdetail] = this.getFormComponent('membershipId');
    memberdetail.form.controls.membershipId.setValue('');
  }

  getAvailabilityCriteria(locations, instructors) {
    if (!locations.length && !instructors.length) {
      return AvailabilityCriteria.None
    } else if (locations.length && !instructors.length) {
      return AvailabilityCriteria.IncludeLocation
    } else if (!locations.length && instructors.length) {
      return AvailabilityCriteria.IncludeInstructor
    } else {
      return AvailabilityCriteria.IncludeLocationAndInstructor
    }
  }

  isPastDate(date): boolean { // need to get the current restaurant date time
    return moment(date).diff(moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)), 'days') < 0
  }

  anyFirstDaySessionCheckedIn(party): boolean {
    return !!party.BookedSessions?.filter(s => s.BookedDate == party.StartDate && s.SessionState == PartyState.Seated).length;
  }

  getLessonType(activityId) {
    return this.ActivityList.find(activity => activity.Id == activityId).ClassType;
  }

  getInstructor() {
    var selectedActivity = this.ActivityList.filter(activity => activity.Id == this.data.SpecialMealId)[0];
    if (selectedActivity) {
      var sessionsWithStaffMappings = []
      if (this.data.SessionGroupId) {
        selectedActivity.ActivitySessions.forEach(session => {
          if (session.SessionGroupId == this.data.SessionGroupId) {
            session.SessionStaffMappings.forEach(staffMapping => sessionsWithStaffMappings.push(staffMapping))
          }
        });
        var unquieInstructor = _.uniqBy(sessionsWithStaffMappings, 'ServerId');
        return unquieInstructor && unquieInstructor.length == 1 ? unquieInstructor[0] : -1;
      }
      else if (this.data.BookedSessions && this.data.BookedSessions.length > 0) {

        var selectedSessionIds = this.data.BookedSessions.map(bookedSession => bookedSession.ActivitySessionId);
        selectedActivity.ActivitySessions.forEach(session => {
          if (selectedSessionIds.find(id => id == session.ActivitySessionId)) {
            session.SessionStaffMappings.forEach(staffMapping => sessionsWithStaffMappings.push(staffMapping))
          }
        });
        var unquieInstructor = _.uniqBy(sessionsWithStaffMappings, 'ServerId');
        return unquieInstructor && unquieInstructor.length == 1 ? unquieInstructor[0] : -1;
      }
    }
    return -1;
  }

  getLocationOptions(activityId) {
    var options = []
    var activitySelected = this.ActivityList.find(activity => activity.Id == activityId);
    options = this.locationList.filter(location => {
      return !!(activitySelected.ActivitySessions.filter(session => !!(session.SessionLocationMappings.find(elm => elm.StandaloneTableId === location.id))).length)
    });

    this.includeAny(options);
    return options;
  }

  getInstructorOptions(activityId) {
    var options = []
    var activitySelected = this.ActivityList.find(activity => activity.Id == activityId);
    options = this.instructorList.filter(server => {
      return !!(activitySelected.ActivitySessions.filter(session => !!(session.SessionStaffMappings.find(elm => elm.ServerId === server.id))).length)
    });
    this.includeAny(options);
    return options;
  }

  showSettingsChangedInfo() {
    const msg = this.ts.instant('settingsUpdated');
    const title = this.ts.instant('alert');
    this.refreshDialogRef = Utilities.showSettingsChangedInfo(msg, title, this.dialog);
    let settingUpdateInfo = this.ps.confirmedAction$.subscribe(val => {
      if (val == ComponentTypes.reservation) {
        this.ps.confirmAddToCartAction$?.next(null);
        this.ps.closeDialog$.next(true);
        setTimeout(() => {
          this.dashboardFunctions.createOrEditActivityBooking(false, null);
        }, 500)
        if (settingUpdateInfo) {
          settingUpdateInfo.unsubscribe();
        }
      }
    });
  }

  getFromDate(restaurantDate: Date) {
    if (this.weeklyViewDate)
      return this.weeklyViewDate;
    else if (this.data)
      return this.getEditStartDate();
    else if (this.dashboardFunctions.checkBookPastDaysReservationsConfig() || new Date(this.appService._headerDate) > restaurantDate)
      return this.appService._headerDate
    else
      return restaurantDate;
  }

  getToDate(restaurantDate: Date) {
    if (this.weeklyViewDate)
      return this.weeklyViewDate;
    else if (this.data)
      return this.getEditEndDate();
    else if (this.dashboardFunctions.checkBookPastDaysReservationsConfig() || new Date(this.appService._headerDate) > restaurantDate)
      return this.appService._headerDate
    else
      return restaurantDate;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.resetOnObenBookingTerminate();
    this.partyService.customFieldValidation = [];
  }

  includeAny(arr: any[]) {
    arr.unshift({ id: -1, value: this.ts.instant("Any") })
  }

  resetWarning() {
    this.sessionsUnavailable = null;
  }

  salesContactUsers() {
    let salesContact = this.dashboardFunctions.getSalesContractList(this.data);
    this.salesContactConfig[0].options = salesContact.contacts
    this.salesContactConfig[0].multiChipValues = salesContact.multiChip;
    if (this.data?.SalesContactIds) {
      this.salesContactForm?.form.get('salescontacts').setValue(this.data?.SalesContactIds);
    }
  }
}
