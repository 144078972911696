import { Pipe, PipeTransform } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';

@Pipe({
  name: 'loaddecimalvalue'
})
export class LoadDecimalValuePipe implements PipeTransform {
  constructor(public localization : RetailLocalization){}
  transform(val: string): any {
    if (val) {
      if (val.toString() != "0" || val.toString() != "") {
        let StrVal: string = val ? val.toString() : "";
        if (!StrVal.includes(this.localization.decimalSeparator)) {
          let noOfDecimalAllowed = sessionStorage.getItem('noOfDecimalDigits');
          if(noOfDecimalAllowed){
            let decimalInteger = parseInt(noOfDecimalAllowed);
            if(decimalInteger > 0){
              let decimalToAppend = '';
              for(let i=0;i<decimalInteger;i++){
                decimalToAppend += '0';
              }
              StrVal = StrVal + this.localization.decimalSeparator + decimalToAppend;  
            }
          } else{
            StrVal = StrVal + this.localization.decimalSeparator + "00"
          }
        }
        return StrVal;
      }
    }
  }

}
