import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { Time } from '@angular/common';
import { Localization } from 'src/app/common/localization/localization';
import { AgTimeConfig } from '../../Models/ag-models';
import moment from 'moment';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';
import { CompareKey } from '../../constants';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-ag-time-picker',
  templateUrl: './ag-time-picker.component.html',
  styleUrls: ['./ag-time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgTimePickerComponent implements OnInit {
  captions: any;
  className: string;
  errorMessage: string;
  floatLabel: string;
  form: UntypedFormGroup;
  formControlName: string;
  hidden = false;
  maxTime: string;
  minTime: string;
  defaultTime: string;
  placeHolder: string;
  value: string;
  isInvalid: any;
  customErrorMessage: string;
  isTimeRequired: boolean;
  automationId: string;
  @Output() timeChangeEvent = new EventEmitter();
  @Output() timepickerOpen = new EventEmitter();
  timeformat: number;
  inputTimeformat: number;
  timeRegex: RegExp = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s(AM|PM|am|pm)$/i;
  selectedTimeFreeInput: FormControl = new FormControl('');
  patternFormat: string;
  disabled:boolean = false;

  @ViewChild("timepicker") pickerFreeInput: NgxMaterialTimepickerComponent;
  @Input('disabled')
  set disableForm(value){
    this.disabled = value;
     if(value){
       this.form.controls[this.formControlName].disable();
     }
     else{
      this.form.controls[this.formControlName].enable();
     }
  }
  @Input('inputs')
  set inputOptions(value: AgTimeConfig) {
    if (value) {
      this.className = value.className ? value.className : '';
      this.floatLabel = this._Localization.setFloatLabel? this._Localization.setFloatLabel: value.floatLabel;
      this.errorMessage = value.errorMessage;
      this.form = value.form;
      this.formControlName = value.formControlName;
      this.hidden = value.hidden ? value.hidden : false;
      this.maxTime = value.maxTime;
      this.minTime = value.minTime;
      this.value = value.value;
      this.defaultTime = value.defaultTime ? value.defaultTime.toString() : this.minTime && this.minTime.toString();
      this.isTimeRequired = value.isTimeRequired ? value.isTimeRequired : false;
      this.placeHolder = value.placeHolder;
      this.customErrorMessage = value.customErrorMessage;
      this.inputTimeformat=value.timeFormat;
      this.automationId = value.automationId ? value.automationId : '';
      this.isInvalid = this.minTime ? (this.form.value[this.formControlName] &&
        this.checkInvalid(this.minTime, this.form.value[this.formControlName])) : false;
      let invalidError;
      let customErrorMsg;

      if (this.isInvalid) {
        invalidError = true;
      } else {
        invalidError = false;
      }

      if (this.customErrorMessage) {
        customErrorMsg = true;
      } else {
        customErrorMsg = false;
      }
      this.form.controls[this.formControlName].setErrors({
        invalid: invalidError,
        customError: customErrorMsg
      });
      if (invalidError || customErrorMsg) {
        this.form.controls[this.formControlName].markAsTouched();
      }
      if (!invalidError && !customErrorMsg) {
        this.form.controls[this.formControlName].updateValueAndValidity();
      }
    }
  }
  constructor(private _Localization: Localization, private utils: CommonUtilities) { }

  ngOnInit() {
    this.captions = this._Localization.captions;
    this.timeformat = this._Localization.time24formatFlag? this._Localization.getTimeFormat(): this.inputTimeformat?this.inputTimeformat: this._Localization.getTimeFormat();
    this.timeRegex = (this.timeformat == 12)? /^(0?[1-9]|1[0-2]):[0-5][0-9]\s(AM|PM|am|pm)$/i : /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    this.patternFormat = (this.timeformat == 12)? this.captions.ExpectedFormat12: this.captions.ExpectedFormat24;
  }

  ngAfterViewInit(){
    this.validateTimeInputs();
  }

  timeChanged() {
    this.timeChangeEvent.emit([this.form, this.formControlName]);
  }

  checkInvalid(minTime, formControlName) {
    const minimumTime = moment(minTime, 'h:mma');
    const maximumTime = moment(formControlName, 'h:mma');
    return maximumTime.isBefore(minimumTime);
  }

  timepickerOpened(event) {
    this.timepickerOpen.emit(event);
  }

  validateTimeInputs(){
    if(this.minTime && !this.maxTime){
      let minTimeValidity = this.validateMinTime();
      let isMinTimeValidated = (minTimeValidity === CompareKey.lesser || minTimeValidity === CompareKey.equal);
      this.validateTime(isMinTimeValidated);
    }
    else if(this.maxTime && !this.minTime){
      let maxTimeValidity = this.validateMaxTime();
      let isMaxTimeValidated = (maxTimeValidity === CompareKey.lesser || maxTimeValidity === CompareKey.equal);
      this.validateTime(isMaxTimeValidated);
    }
    else if(this.minTime && this.maxTime){
      let minTimeValidity = this.validateMinTime();
      let maxTimeValidity = this.validateMaxTime();
      let isMinTimeValidated = (minTimeValidity === CompareKey.lesser || minTimeValidity === CompareKey.equal);
      let isMaxTimeValidated = (maxTimeValidity === CompareKey.lesser || maxTimeValidity === CompareKey.equal);
      this.validateTime(isMinTimeValidated && isMaxTimeValidated);
    } 
    else{
      this.validateTime(true);
    }
  }

  validateMinTime(){
    return this.utils.ValidateTime(this.minTime, this.form.controls[this.formControlName].value);
  }

  validateMaxTime(){
    return this.utils.ValidateTime(this.form.controls[this.formControlName].value, this.maxTime);
  }


  selectedTimeFreeInputChanged(): void {
    this.form.controls[this.formControlName].markAsDirty();
    this.validateTimeInputs();
    this.timeChangeEvent.emit([this.form, this.formControlName]);
  }


  validateTime(isValid){
      if(isValid && this.pickerFreeInput){
        this.selectedTimeFreeInput.setValue(this.form.controls[this.formControlName].value);
        this.pickerFreeInput.defaultTime = this.form.controls[this.formControlName].value;
        this.pickerFreeInput.updateTime(this.form.controls[this.formControlName].value);
        if(!this.form.controls[this.formControlName].hasError('pattern') && !this.form.controls[this.formControlName].hasError('required')){
          this.form.controls[this.formControlName].setErrors(null);
        }
      } else {
        this.form.controls[this.formControlName].setErrors({invalid: true});
      }
  }

  updateTime(event: string): void {
    this.form.controls[this.formControlName].markAsDirty();
    this.form.controls[this.formControlName].setValue(event);
    this.timeChangeEvent.emit([this.form, this.formControlName]);
  }
}
