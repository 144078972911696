import { Component,  ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Localization } from '../../localization/localization';
import { CheckboxConfig, SearchWithCheckbox } from '../../Models/common.models';
import { SortOrderPipe } from '../../pipes/sort-order.pipe';

@Component({
  selector: 'app-search-with-checkbox',
  templateUrl: './search-with-checkbox.component.html',
  styleUrls: ['./search-with-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SearchWithCheckboxComponent  {
  captions: any;
  searchPlaceHolder: string;
  searchText = '';
  @Input() isViewOnly;
  showSelectAll;
  disabled = false;
  selectAllValue = '0';
  selectAll = false;
  itemGroupFormControlName = 'item';
  title: string;
  selectedTextLength: string;
  showSearch = true;
  selectedLength = 0;
  showClose = true;
  filteredDataArr: CheckboxConfig[] = [];
  newFilteredDataArr: CheckboxConfig[] = [];
  originalFilteredDataArr: CheckboxConfig[] = [];
  warningMessage: string;
  showWarningBar: boolean;
  sortPipe: SortOrderPipe;
  orderlySelectedItem: CheckboxConfig[] = [];


  @Input('inputs')
  set inputOptions(value: SearchWithCheckbox) {
    if (value) {
      this.originalFilteredDataArr = value.lists.slice(0, value.lists.length);
     
      this.newFilteredDataArr = value.lists.slice(0, value.lists.length);
      this.title = value.title;
      this.searchPlaceHolder = value.searchPlaceHolder;
      this.disabled =  value.disabled ? value.disabled : false;
      this.showSearch = value.showSearch ? value.showSearch : false;
      this.mapIntialData(value.itemList);
      this.selectedTextLength = this.getSelectedLength();
      this.warningMessage = value.warningMessage;
      this.showWarningBar = value.showWarningBar;
      this.showSelectAll = value.showSelectAll ? value.showSelectAll : '';
      this.callFilter();
    }
  }

  @Output() itemChange = new EventEmitter();
  @Output() selectedArrayCollection = new EventEmitter();
  constructor(private localization: Localization, private formbuilder: UntypedFormBuilder) {
    this.captions  = this.localization.captions;
    this.sortPipe = new SortOrderPipe();
  }

  mapIntialData(itemlists) {
    this.newFilteredDataArr.forEach(list => {
      const x = itemlists.find(item => item.value === list.value);
      list.checked =  x ? x.checked : false;
    });    
    this.filteredDataArr = this.newFilteredDataArr.slice(0, this.newFilteredDataArr.length);

  }

  searchValueChange(e: string) {
    this.searchText = e && e.trim().length > 0 ? e.trim().toLowerCase()  : '';
    this.callFilter();
    this.selectedTextLength = this.getSelectedLength();
  }

  callFilter() {
    const newArr = [];
    this.originalFilteredDataArr.forEach((x, index) => {      
      let searchVal = x.searchValue?x.searchValue:x.viewValue;
      const checkText = (searchVal.toLowerCase().indexOf(this.searchText) !== -1);
      if (checkText) {
        newArr.push(index);
      }
    });
    const newSearchArr = [];
    for (let dataLoop = 0; dataLoop <= (newArr.length - 1); dataLoop++) {
      const index = newArr[dataLoop];
      newSearchArr.push(this.originalFilteredDataArr[index]);
    }
    this.filteredDataArr = newSearchArr;
    this.getSelectedLength()
  }

  onSelectAllChange(e, checked) {
    this.filteredDataArr.forEach(y => {
      y.checked = !checked;
    });
    this.newFilteredDataArr.forEach(list => {
      const x = this.filteredDataArr.find(item => item.value === list.value);
      list.checked =  x ? x.checked : false;
    });
    if(!checked){
      this.orderlySelectedItem = [...this.newFilteredDataArr];
    } else {
      this.orderlySelectedItem = [];
    }
    
    this.selectedArrayCollection.emit(this.orderlySelectedItem);
    this.selectedTextLength = this.getSelectedLength();
    this.emitData();
  }

  onItemChange(e, item, i) {
    this.newFilteredDataArr.find(x => JSON.stringify(x) === JSON.stringify(item)).checked = !item.checked;
    if(item.checked){
      this.orderlySelectedItem.push(item);
      this.selectedArrayCollection.emit(this.orderlySelectedItem);
    } else {
      this.orderlySelectedItem.splice(this.orderlySelectedItem.findIndex(x=> x.value === item.value),1);
      this.selectedArrayCollection.emit(this.orderlySelectedItem);
    }
    this.selectedTextLength = this.getSelectedLength();
    this.emitData();
  }

  emitData() {
    const emitArr = [];
    this.newFilteredDataArr.forEach((data, index) => {
      if (data.checked) {
        emitArr.push(data);
      }
    });
    this.itemChange.emit(emitArr);
  }

  getSelectedLength() {
    let length = 0;
    if ( this.newFilteredDataArr) {
      this.newFilteredDataArr.forEach(x => {
        if (x.checked) {
          length++;
        }
      });
      this.selectAll = !this.filteredDataArr.some(x => !x.checked);
    }
    this.selectedLength = length;
    return this.localization.replacePlaceholders(this.captions.lbl_noSelected, ['number'], [length]);
     
  }

}
