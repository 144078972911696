import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { ApplyDiscountService } from './appply-discount.service';
import { DiscountReason, DiscountTypeDetails, ItemDiscount } from '../shop.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { ApplyDiscountInput } from '../../shared/service/common-variables.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { SystemConfiguration } from '../../retail.modals';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import * as GlobalConst from '../../shared/globalsContant';

@Component({
  selector: 'app-apply-discount',
  templateUrl: './apply-discount.component.html',
  styleUrls: ['./apply-discount.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApplyDiscountComponent implements OnInit {

  selectedType: number = 1;
  discountForm: UntypedFormGroup;
  // @Input() CategoryId: number;
  // @Input() SelectedDiscount: ItemDiscount;
  // @Input() miscSetting;


  // @Input() ApplyDiscountInput: ApplyDiscountInput;
  // @Output() discountEmitter: EventEmitter<ItemDiscount> = new EventEmitter();
  // @Output() closeEmitter: EventEmitter<any> = new EventEmitter();

  ApplyDiscountInput: ApplyDiscountInput;  
  CategoryId: number;
  SelectedDiscount: ItemDiscount;
  public discountList: DiscountTypeDetails[] = [];
  public discountReason: Promise<DiscountReason[]>;
  public captions: any = this.localization.captions.shop;
  public discountReasonSetting: SystemConfiguration;
  public discountCommentSetting: SystemConfiguration;
  reasonRequired: boolean = false;
  commentRequired: boolean = false;
  isPrecentageDisabled : boolean = false;
  customDiscountsAccess: boolean = false;
  itemName: string;
  itemPrice: number;
  showItemDetails:boolean = false;
  floatLabel: string;

  constructor(private fb: UntypedFormBuilder, public utils: RetailUtilities, public localization: RetailLocalization, private applyDiscountService: ApplyDiscountService, private dialogRef:MatDialogRef<ApplyDiscountComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  , private breakPoint: BreakPointAccess) {
    this.floatLabel = this.localization.setFloatLabel;
   }

  ngOnInit() {
    this.CategoryId=this.data?.CategoryId;
    this.SelectedDiscount=this.data?.discountInput?.selectedDiscount;
    this.showItemDetails = this.data?.discountInput?.showItemDetails;
    this.ApplyDiscountInput=this.data.discountInput
    this.itemName=this.data?.discountInput?.itemName;
    this.itemPrice=this.data?.discountInput?.itemPrice;
    this.checkBreakpoints();
    this.SetDiscountPopUp();
  }
  async checkBreakpoints(){    
    this.customDiscountsAccess = this.breakPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyCustomDiscount], false);
  }
  checkCustomDiscountControls(val){
    if(!this.customDiscountsAccess){
      this.breakPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyCustomDiscount], true);
    }
  }
  initializeForm(): void {
    this.discountForm = this.fb.group({
      type: 1,
      percentage: [{ value: '', disabled: !this.customDiscountsAccess }],
      discType: 0,
      amount: [0, Validators.required],
      comment: '',
      reason: 0
    }); 
  }

  // ngOnChanges() {
  //   this.ApplyDiscountInput = this.ApplyDiscountInput ? this.ApplyDiscountInput : {
  //     CategoryId: 0,
  //     SelectedDiscount: null
  //   };
  //   this.SetDiscountPopUp();
  // }

  SetDiscountPopUp() {
    this.initializeForm();
    this.selectedType = 1;
    this.DiscountList();
    this.DiscountReason();
    this.GetMiscSetting();
  }

  typeChange(arg) {
    this.selectedType = arg.value;
    this.discountForm.controls.amount.setValue("");
    this.discountForm.controls.percentage.setValue("");
    this.discountForm.controls.discType.setValue(0);
    if (this.selectedType == 1) {
      if (this.discountForm.controls.percentage.disabled) {
        this.discountForm.controls.percentage.enable();
      }
      this.DiscountList(true);
    }
    this.SetRequiredFields();
  }

  DiscountList(isChange: boolean = false) {

    if (this.ApplyDiscountInput.CategoryId === -1) {
      let discountTypes = this.applyDiscountService.getActiveDiscountTypes();
      discountTypes.then(res=>
        this.discountList =res.map(x => {
        return {
          discountTypeId: x.id,
          discountTypeName: x.type,
          discountValue: 0
        }
      }))
    }
    else {
      if(this.ApplyDiscountInput.CategoryId == 0 ){
        this.discountList = [];
      }
      else{
        this.applyDiscountService.getDiscountsForItem(this.ApplyDiscountInput.CategoryId, (this.ApplyDiscountInput.CategoryId == -1)).then(res=>{
          this.discountList = res.filter(x => x.discountValue > 0);
        })
      }      
    } 

    if (this.ApplyDiscountInput.SelectedDiscount && !isChange) {      
      if(this.ApplyDiscountInput.SelectedDiscount.discountId > 0){
        this.selectedType = 1;
        this.discountForm.controls.discType.setValue(this.ApplyDiscountInput.SelectedDiscount.discountId);
        this.discountForm.controls.percentage.disable();
      }
      else if(this.ApplyDiscountInput.SelectedDiscount.discountPercentage > 0){
        this.selectedType = 1;
        this.discountForm.controls.percentage.setValue(this.ApplyDiscountInput.SelectedDiscount.discountPercentage);
      }
      else if(this.ApplyDiscountInput.SelectedDiscount.discountAmount > 0){
        this.selectedType = 2;
        this.discountForm.controls.amount.setValue(this.ApplyDiscountInput.SelectedDiscount.discountAmount);
      }
      this.discountForm.controls.type.setValue(this.selectedType);
      this.discountForm.controls.reason.setValue(this.ApplyDiscountInput.SelectedDiscount.discountReason);
      this.discountForm.controls.comment.setValue(this.ApplyDiscountInput.SelectedDiscount.discountComment);
      this.SetRequiredFields();
    }
    else if (isChange) {
      this.discountForm.controls.amount.setValue("");
      this.discountForm.controls.percentage.setValue("");
      this.discountForm.controls.discType.setValue(0);
    }
  }

  DiscountReason(){
    this.discountReason = this.applyDiscountService.getActiveDiscountReasons().then(res=>{    
      return res && res.filter(x => x.isActive);
    })
  }

  GetMiscSetting(){
    let allSettings = this.applyDiscountService.getMiscSettings();
    allSettings.then(res=>{
    if(res && res.length > 0){
      this.discountReasonSetting = res.find(x => x.switch == GlobalConst.MiscellaneousSwitch.DISCOUNT_REASON_REQUIRED);
      this.discountCommentSetting = res.find(x => x.switch == GlobalConst.MiscellaneousSwitch.DISCOUNT_COMMENTS_REQUIRED);
    }
    this.SetRequiredFields();
  })
  }

  SetRequiredFields(){
    this.reasonRequired = false;
    if((this.discountReasonSetting && this.discountReasonSetting.value == '1')
    || (this.discountReasonSetting && this.discountReasonSetting.value == '2' && this.discountForm.controls.type.value == 1)
    || (this.discountReasonSetting && this.discountReasonSetting.value == '3' && this.discountForm.controls.type.value == 2)
    ){
      this.reasonRequired = true;
    }

    this.commentRequired = false;
    if((this.discountCommentSetting && this.discountCommentSetting.value == '1')
    || (this.discountCommentSetting && this.discountCommentSetting.value == '2' && this.discountForm.controls.type.value == 1)
    || (this.discountCommentSetting && this.discountCommentSetting.value == '3' && this.discountForm.controls.type.value == 2)
    ){
      this.commentRequired = true;
    }
  }

  apply() {
    const discountList: DiscountTypeDetails[] = <DiscountTypeDetails[]>this.discountList;
    const tempdiscountType = this.discountForm.controls.discType.value > 0 ? discountList.find(x => x.discountTypeId == this.discountForm.controls.discType.value) : null;
    const discountType = this.discountForm.controls.type.value == 1 ?  tempdiscountType : null;
    const itemDiscount: ItemDiscount = {
      itemId: 0,
      discountAmount: this.discountForm.controls.type.value == 1 ? 0 : this.localization.currencyToSQLFormat(this.discountForm.controls.amount.value),
      discountId: this.discountForm.controls.type.value == 1 ? this.discountForm.controls.discType.value : 0,
      discountPercentage: discountType ? discountType.discountValue : this.localization.currencyToSQLFormat(this.discountForm.controls.percentage.value),
      discountComment: this.discountForm.controls.comment.value,
      discountReason: this.discountForm.controls.reason.value
    };
    // this.discountEmitter.emit(itemDiscount);
    this.discountForm.reset({ type: 1 });

    this.dialogRef.close(itemDiscount)
  }

  close() {
    // this.closeEmitter.emit();
    this.discountForm.reset({ type: 1 });

    this.dialogRef.close()
  }
  
  oneOfControlRequired(...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      for (const aControl of controls) {
        if (!Validators.required(aControl)) {
          console.log(null);
          return null;
        }
      }
      return { oneOfRequired: true };
    };
  }

  discountTypeSelected(e){
    if(e.value > 0){
      this.discountForm.controls.percentage.setValue('');
      this.discountForm.controls.percentage.disable();
    }else{
      if(this.customDiscountsAccess ){
        this.discountForm.controls.percentage.enable();
      }
    }
  }

  onPercentageChange(e){
      if(this.discountForm.controls.percentage.value > 0){
        this.discountForm.controls.discType.setValue(0);
      }
  }

  isFormValid() : boolean{
    if(this.discountForm.controls.type.value == 1){
      if(this.discountForm.controls.percentage.value!=null)
      {
        return ((this.discountForm.controls.percentage.value.toString().trim() != '' && this.discountForm.controls.percentage.value.toString().trim() != '0') || this.discountForm.controls.discType.value > 0) 
        && (!this.reasonRequired || (this.reasonRequired && this.discountForm.controls.reason.value > 0))
        && (!this.commentRequired || (this.commentRequired && this.discountForm.controls.comment.value?.toString().trim() != ''));
      }
      else{return false;}
    }else{
      if(this.discountForm.controls.amount.value!=null)
      {
        return this.discountForm.controls.amount.value.toString().trim() != '' && this.discountForm.controls.amount.value.toString().trim() != '0'
        && (!this.reasonRequired || (this.reasonRequired && this.discountForm.controls.reason.value > 0))
        && (!this.commentRequired || (this.commentRequired && this.discountForm.controls.comment.value?.toString().trim() != ''));
      }
      else{return false;}
    }
  }
}
