<section class="Errorpop-container">
  <div class="pop-head">
    <label class="inner-header pl-4 text-uppercase">{{data.headername}}</label>
    <i  aria-hidden="true" *ngIf="data.closebool" class="material-icons inner-close float-md-right cursPointer" (click)="DialogClose('e')">close</i>
  </div>
  <div class="pop-contents">
  <form name="Error" autocomplete="off" [formGroup]="FormGrp">
  <mat-dialog-content>
  <div class="pt-3 pb-3" *ngIf ="data.Type == 'HTML'" [innerHTML]="datRec | safeHtml"></div>
  <div class="pt-3 pb-3" *ngIf ="data.Type != 'OPENPRICE' && data.Type != 'SCALEDITEM' && data.Type != 'BC' && data.Type != 'HTML'"  [innerHTML]="datRec"></div>
  <div class="pt-3 pb-3 openitem-price" *ngIf ="data.Type == 'OPENPRICE'">

    <div class="w-100 itemdetails">
      <div class="item LW14 text-uppercase" ><div>{{captions.ItemName}}</div><div>{{data.data.title}}</div></div>
      <div class="quantity LW14" [attr.LiteralID]="'Quantity'" ><div>{{captions.Quantity}}</div><div>{{data.quantity}}</div></div>
    </div>
      <mat-form-field [floatLabel]="floatLabel" class="LW14 d-block mt-4 mb-4 price">
      <input matInput placeholder="{{localization.captions.retailsetup.Price+' '+ '(' + localization.currencySymbol + ')'}}" formControlName="ShopPrice" CurrencyFormatter name="price"
      >
      </mat-form-field>
  </div>
  <div class="pt-3 pb-3 openitem-price" *ngIf="data.Type == 'SCALEDITEM'">
    <div class="w-100 itemdetails">
      <div class="item">
        <div class="LW12" [attr.LiteralID]="'ItemName'"  [attr.LiteralID]="'ItemName'" >{{captions.ItemName}}</div>
        <div class="LW16 item-color">{{data.data.title}}</div>
      </div>
      <div class="quantity">
        <div class="LW12" [attr.LiteralID]="'UnitofMeasure'" >{{captions.UnitofMeasure}}</div>
        <div class="LW16 item-color">{{data.quantity}}</div>
      </div>
    </div>
    <mat-form-field [floatLabel]="floatLabel" class="LW14 d-block mt-4 mb-4 price">
      <input  inputtype="onlyPositiveDecimal,roundoff2" LimitExceed numUpto='9999' matInput placeholder="{{captions.EnterUnit}}" formControlName="ScaledUnit" name="unit">
    </mat-form-field>
  </div>
  <mat-checkbox *ngIf="IsConfirmationPopup"  formControlName="HideDialog" [attr.LiteralID]="'HidethisDialog'" >{{captions.HidethisDialog}}</mat-checkbox>

  <div class="pt-3 pb-3" *ngIf="data.Type == 'BC'">
    <mat-form-field [floatLabel]="floatLabel" class="LW14 d-block mt-4 mb-4 price w-50">
      <input matInput placeholder="{{captions.EnterStartingLablePosition}}" formControlName="Barcodes" name="labelposition">
    </mat-form-field>
  </div>

</mat-dialog-content>
</form>
</div>
<mat-dialog-actions class="actions">
  <div class="pl-4" *ngIf="this.buttonType == 3">
    <button mat-button class="body-bgcolor whitecolor actionButton" (click)="DialogClose('ok')">{{captions.OK}}</button>
    <button mat-button  class="" (click)="DialogClose('cancel')">{{captions.CANCEL}}</button>
  </div>
  <div class="pl-4" *ngIf="this.buttonType == 1">
    <button mat-button class="body-bgcolor whitecolor actionButton" (click)="DialogClose('YES')">{{captions.Yes}}</button>
    <button mat-button  class=""  (click)="DialogClose('NO')">{{captions.No}}</button>
   
  </div>
  <div class="pl-4" *ngIf="this.buttonType == 2">
    <button mat-button class="body-bgcolor whitecolor actionButton" (click)="DialogClose('ok')">{{captions.Yes}}</button>
    <button mat-button  class=""  (click)="DialogClose('no')">{{captions.No}}</button>
    <button mat-button  class=""  (click)="DialogClose('cancel')">{{captions.Cancel}}</button>
  </div>
  <div class="pl-4" *ngIf="this.buttonType == 4">
    <button mat-button class="body-bgcolor whitecolor actionButton" (click)="DialogClose('ok')">{{captions.OK}}</button>
  </div>

  <div class="pl-4" *ngIf="this.buttonType == 5">
      <button mat-button class="actionButton" (click)="DialogClose('save')" [ngClass]="data.Type == 'OPENPRICE' && FormGrp.dirty && FormGrp.valid ? 'spa-primary-default-save' : 'spa-primary-disabled'">{{captions.Save}}</button>
      <button mat-button class=""  (click)="DialogClose('cancel')">{{captions.Cancel}}</button>
    </div>
    <div class="pl-4" *ngIf="this.buttonType == 6">
      <button mat-button class="body-bgcolor whitecolor actionButton" (click)="DialogClose('YES')">{{captions.Continue}}</button>
      <button mat-button  class=""  (click)="DialogClose('NO')">{{captions.CANCEL}}</button>
    </div>
    <div class="pl-4" *ngIf="this.buttonType == 7">
      <button mat-button (click)="DialogClose('Add')" [ngClass]="data.Type == 'SCALEDITEM' && FormGrp.dirty && FormGrp.valid ? 'spa-primary-default-save' : 'spa-primary-disabled'">{{captions.ADD}}</button>
      <button mat-button  class="spa-button-cancel"  (click)="DialogClose('NO')">{{captions.CANCEL}}</button>
    </div>
</mat-dialog-actions>
</section>



