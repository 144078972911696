import { Pipe, PipeTransform } from "@angular/core";
import { AppService } from "@app/app.service";
import { CacheService } from "@app/core/services/cache.service";

@Pipe({
    name: 'activityColor'
  })
  export class ActivityColorPipe implements PipeTransform {

    constructor(private cs: CacheService, private appService: AppService){
        
    }
  
    transform(categoryId): string {
      if(!this.cs.categoryData){
        this.cs.loadCategoryData();
      }
        return categoryId && categoryId > 0  && this.cs.categoryData[categoryId]?.style || {};
    }
  
  
  }

  export function RGBToHex(r,g,b) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
  
    if (r.length == 1)
      r = "0" + r;
    if (g.length == 1)
      g = "0" + g;
    if (b.length == 1)
      b = "0" + b;
  
    return "#" + r + g + b;
  }

  export function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  }