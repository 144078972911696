<div class="manual-slot">
  <div class="manual-slot__slot-buttons">
    <activities-app-button class="button-view" [buttontype]="addToStandByButton" (valueChange)="addToStandBy(selectedSlot)"></activities-app-button>
    <activities-app-button class="button-view" #sl="ngbPopover" [ngbPopover]="addslot" placement="bottom"
      [autoClose]="false" triggers="manual" (valueChange)="openSlot()" [buttontype]="addSlotButton"></activities-app-button>
  </div>
  <div class="manual-slot__custom-slots">
    <ng-scrollbar class="manual-slot__scrollbar">
      <div #manualSlotcontainer>
      <ul class="manual-slot__slot-group text-center" *ngIf="manualSlotsGroup?.length > 0 && manualSlotsGroup[0].slots?.length > 0">
        <li class="manual-slot__available-slots d-inline" *ngFor="let slot of manualSlotsGroup[0].slots;let j = index">
          <activities-app-button class="button-view" [buttontype]="slot.buttonInfo" (valueChange)="selectCustomSlot(slot)"></activities-app-button>
          <div class="manual-slot__edit-slot" [ngClass]="{'manual-slot__hide-slot': slot.IsLocked }">
            <span>
              <a [autoClose]="false" triggers="manual" #es="ngbPopover" (click)="openCustomSlots(j, slot)" [ngbPopover]="editSlot"
                 [placement]="popoverPosition">
                <i class="icon-edit" *ngIf="!slot.IsLocked"></i>
              </a>
            </span>
          </div>
        </li>
      </ul>
    </div>
      <div class="manual-slot__no-slots" *ngIf="manualSlotsGroup?.length > 0 && manualSlotsGroup[0].slots?.length == 0">
        <img class="manual-slot__nodata" src="{{nodataImgUrl}}" alt="">
        <span class="seat-no-content-found">{{'noDataFound' | translate}}</span>
      </div>
    </ng-scrollbar>
  </div>
  <ng-template #addslot>
    <app-new-slot [isAdd]="true" [isSlots]="false" (closeSlot)="closeSlotPopover(true)" [slotTime]="selectedSlotDateTime"></app-new-slot>
  </ng-template>
  <ng-template #editSlot>
    <app-new-slot [isAdd]="false" [isSlots]="false" (closeSlot)="closeSlotPopover(false)" [slotTime]="selectedSlotDateTime"></app-new-slot>
  </ng-template>
</div>
