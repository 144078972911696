import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserPropertyLoginModel, UserQuickIdLoginModel } from './quick-login.model';
import { MatDialogRef } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { CommonSharedPopupComponent } from '../common-popup/common-popup.component';
import { CommonService } from '../service/common.service';
import { DialogCloseObj } from '../globalsContant';
import { AuthenticationService } from '../../services/authentication.service';
import { BaseResponse } from 'src/app/common/Models/http.model';
import { QuickLoginBusiness } from './quick-login.business';
import { Product } from 'src/app/common/enums/shared-enums';

@Component({
  selector: 'app-quick-login',
  templateUrl: './quick-login.component.html',
  styleUrls: ['./quick-login.component.scss'],
  providers: [QuickLoginBusiness]
})
export class QuickLoginComponent implements OnInit, OnDestroy {
  @ViewChild('quickIdFocus') quickIdFocus: ElementRef;
  @ViewChild('userIdFocus') userIdFocus: ElementRef;
  captions: any;
  errorMessage: { userId: string; password: string; quickId: string; };
  quickLoginForm: UntypedFormGroup;
  hidePassword = false;
  hideQuickId = false;
  loginErrorMessage: string;
  userIdLoginErrorMessage: string;
  userId: number = null;
  quickIDError = false;
  userIDError = false;
  facadeUser: FacadeUser = null;
  radixDecimalBase = 10;
  errorTxt: string = "";
  useridSubscribe: any; quickidSubscribe: any; passwordSubscribe: any;
  breakPointNumber;
  isHideQuickId = false;
  isBreakPointAllowed = false;
  breakPointErrorText: string;
  quickIDBreakPointError = false;
  userIDBreakPointError = false;
  quickIDErrorTxt = '';
  userIDErrorTxt = '';
  userName: string;
  productId: number;
  isValid: boolean;
  hasMultipleBreakPoint: boolean;
  breakPoints: any[] = [];
  errorMessageCount: number;
  commonBpErrorMessage: number;
  @Output() notifyParent = new EventEmitter();
  $destroyed: ReplaySubject<any> = new ReplaySubject(1);
  floatLabel: string;
  constructor(private _localization: Localization,
    private Fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<CommonSharedPopupComponent>,
    private _commonService: CommonService,
    private quickLoginBusiness: QuickLoginBusiness) {
      this.floatLabel = this._localization.setFloatLabel;
    this.facadeUser = {
      jwt: '',
      username: '',
      sessionId: '',
      roleId: '',
      user:null
    };
  }
  ngAfterViewInit() {
    this.setFocus(this.quickLoginForm.controls.quicklogintype.value);
  }
  ngOnInit() {
    this.resetQuickIdDetails();
    this.captions = this._localization.captions.login;
    this.productId = parseInt(this._localization.GetPropertyInfo('ProductId'));
    this.isHideQuickId = this.productId === Product.PMS ? true : false;
    this.errorMessage = {
      userId: this.captions.enterUserId,
      password: this.captions.enterPassword,
      quickId: this.captions.enterQuickId
    };
    const component = this.dialogRef.componentInstance.data.componentDetails.popUpDetails;
    const componentInfo = component.bindData;

    this.breakPointNumber = componentInfo ? componentInfo.breakPointNumber : 0;
    this.hasMultipleBreakPoint = component?.hasMultipleBreakPoint;
    this.breakPoints = component?.multipleBreakPoints;
    this.commonBpErrorMessage = component?.commonBpErrorMessage;
    this.loginErrorMessage = this.captions.invalidQuickId;
    this.userIdLoginErrorMessage = this.captions.invaliduserIdLogin;
    this.quickLoginForm = this.Fb.group({
      quicklogintype: this.productId === Product.PMS ? 'userid' : 'quickid',
      userId: ['', Validators.required],
      password: ['', Validators.required],
      quickId: ['', Validators.required],
    });
    this.OnFormValueChanges();
    this.quickLoginForm.get('quicklogintype').valueChanges.subscribe(() => {
      this.setFocus(this.quickLoginForm.controls.quicklogintype.value)
    });
    this.quickLoginForm.statusChanges.pipe(takeUntil(this.$destroyed)).subscribe(() => {
      this.isValid = false;

      if (this.quickLoginForm.controls.quicklogintype.value == 'userid') {
        this.isValid = (this.quickLoginForm.controls.userId.value || '').trim().length > 0 &&
          (this.quickLoginForm.controls.password.value || '').trim().length > 0;
      } else {
        this.isValid = (this.quickLoginForm.controls.quickId.value || '').trim().length > 0;
      }

      this.notifyParent.emit(this.isValid);
    });

    this._commonService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe((x: DialogCloseObj) => {

      if (x.type === 'login') {
        this.quickIDBreakPointError = false;
        this.userIDBreakPointError = false;
        this.validateLogin().then(y => {
          if (y) {
            this.closePopUp();
          }
        });
      }
    });
  }
  setFocus(value: string) {
    if (value == 'userid') {
      setTimeout(() => {
        this.userIdFocus.nativeElement.focus();
      }, 1);
    }
    else {
      setTimeout(() => {
        this.quickIdFocus.nativeElement.focus();
      }, 1);
    }
  }
  OnFormValueChanges(): any {
    this.useridSubscribe = this.quickLoginForm.get('userId').valueChanges.subscribe(() => {
      this.userIDError = false;
      this.userIDBreakPointError = false;
    });
    this.quickidSubscribe = this.quickLoginForm.get('password').valueChanges.subscribe(() => {
      this.userIDError = false;
      this.userIDBreakPointError = false;
    });
    this.passwordSubscribe = this.quickLoginForm.get('quickId').valueChanges.subscribe(() => {
      this.quickIDError = false;
      this.quickIDBreakPointError = false;
    });
  }

  async validateLogin(): Promise<any> {
    if (this.quickLoginForm) {
      if (this.quickLoginForm.value.quicklogintype === 'userid') {
        await this.userNameLogin();
      }
      if (this.quickLoginForm && this.quickLoginForm.value.quicklogintype === 'quickid') {
        const res = await this.quickIdLogin();
      }
    }
  }

  async validateBreakPoint(): Promise<boolean> {
    let res = false;
    this.quickIDBreakPointError = false;
    this.userIDBreakPointError = false;
    if(this.hasMultipleBreakPoint)
    {
      this.errorMessageCount = 0;
      await this.validateMultipleBreakpoint().then(res=> {this.loginerror(res)});
      res = this.isBreakPointAllowed;
    }
    else if (this.breakPointNumber) {
      const breakPointAccess = await this.quickLoginBusiness.validateBreakPointAccess(this.breakPointNumber);
      res = this.isBreakPointAllowed = breakPointAccess;
      if (!res) {
        this.quickLoginForm.value.quicklogintype === 'quickid' ? this.quickIDBreakPointError = true : this.quickIDBreakPointError = false;
        this.quickLoginForm.value.quicklogintype === 'userid' ? this.userIDBreakPointError = true : this.userIDBreakPointError = false;
        this.getBreakPointErrorText();
      }
    } else {
      res = true;
    }
    return res;
  }
  async validateMultipleBreakpoint() : Promise<boolean>
  {
    let res = this.isBreakPointAllowed = true;
    for(let i=0; i < this.breakPoints?.length; i++)
    {
      let bp = this.breakPoints[i];
      if(!await this.quickLoginBusiness.validateBreakPointAccess(bp))
      {
        res = this.isBreakPointAllowed = false;
        if(this.errorMessageCount == 0)
        {
          this.getMultipleBreakPointErrorText(bp);
        }
        this.errorMessageCount++;
      }
      if(this.errorMessageCount > 1)
      {
        return res;
      }
    }
    return res;
  }
  
  loginerror(res)
  {
    if (!res) {
      this.quickLoginForm.value.quicklogintype === 'quickid' ? this.quickIDBreakPointError = true : this.quickIDBreakPointError = false;
      this.quickLoginForm.value.quicklogintype === 'userid' ? this.userIDBreakPointError = true : this.userIDBreakPointError = false;
      if(this.errorMessageCount > 1)
      {
        this.getMultipleBreakPointErrorText(this.commonBpErrorMessage);
      }
    }
  }

  async userNameLogin() {
    this.userId = parseInt(this.quickLoginForm.value.userId, this.radixDecimalBase);
    const userDetails: UserPropertyLoginModel = {
      UserName: this.quickLoginForm.value.userId,
      Password: this.quickLoginForm.value.password,
      PropertyId: parseInt(this._localization.GetPropertyInfo('PropertyId'), this.radixDecimalBase),
      ProductId: parseInt(this._localization.GetPropertyInfo('ProductId'), this.radixDecimalBase),
      TenantId: parseInt(this._localization.GetPropertyInfo('TenantId'), this.radixDecimalBase),
      TenantCode: '',
      PropertyCode: ''
    };
    await this.authService.userIdLogin(userDetails, this);

  }

  async quickIdLogin() {
    const userDetails: UserQuickIdLoginModel = {
      PropertyId: parseInt(this._localization.GetPropertyInfo('PropertyId'), this.radixDecimalBase),
      ProductId: parseInt(this._localization.GetPropertyInfo('ProductId'), this.radixDecimalBase),
      TenantId: parseInt(this._localization.GetPropertyInfo('TenantId'), this.radixDecimalBase),
      TimeZone: this._localization.GetPropertyInfo('TimeZone'),
      QuickId: this.quickLoginForm.value.quickId
    };
    await this.authService.quickIdLogin(userDetails, this);

  }

  async getSessionId(token: string) {
    const utcDate: Date = this._localization.getUTCDateTimeNow();
    const sessionData = {
      id: 0,
      userId: this.userId,
      startTime: this._localization.convertDateTimeToAPIDateTime(utcDate),
      propertyId: parseInt(this._localization.GetPropertyInfo('PropertyId'), this.radixDecimalBase),
      productId: parseInt(this._localization.GetPropertyInfo('ProductId'), this.radixDecimalBase),
      timeZone: this._localization.GetPropertyInfo('TimeZone'),
      token: token
    };
    await this.authService.CreateSession(sessionData, this);
  }

  SetErrorMessage(errorCode: number = 0) {
    this.errorTxt = errorCode > 0 ? this._localization.getError(errorCode) : '';
    this.quickLoginForm.value.quicklogintype === 'quickid' ? this.quickIDErrorTxt = this._localization.getError(errorCode) : this.quickIDErrorTxt = '';
    this.quickLoginForm.value.quicklogintype === 'userid' ? this.userIDErrorTxt = this._localization.getError(errorCode) : this.userIDErrorTxt = '';
  }

  async successCallback<T>(result: BaseResponse<T>, callDesc: string) {
    const propertyId = parseInt(this._localization.GetPropertyInfo('PropertyId'), this.radixDecimalBase);
    const productId = parseInt(this._localization.GetPropertyInfo('ProductId'), this.radixDecimalBase);
    if (callDesc === 'QuickLoginByUserId') { // username login
      const data = <any>result.result;
      if (!result.successStatus || data.userLoginInfo) {
        this.SetErrorMessage(result.errorCode);
        this.userIDError = true;
        return;
      }
      this.userIDError = false;
      if (data && data.user && data.user.id) {
        this.userId = data.user.id;
      }
      this.facadeUser.jwt = data.token;
      this.facadeUser.sessionId = data.sessionId;
      this.facadeUser.roleId = data.user.userPropertyAccesses.find(x => (x.propertyId == propertyId && x.productId == productId)).roleId;
      this.facadeUser.username = this.quickLoginForm.value.userId;

      if (!this.userIDError) {
        this.setQuickIdDetails();
        this.closePopUp();
      }
    }
    if (callDesc === 'QuickLoginByQuickId') { // quickid login
      const data = <any>result.result;
      if (!result.successStatus) {
        this.SetErrorMessage(result.errorCode);
        this.quickIDError = true;
        return;
      }
      this.quickIDError = false;

      this.userId = data.result.userId;
      this.facadeUser.jwt = data.result.userToken;
      this.facadeUser.roleId = data.user.userPropertyAccesses.find(x => (x.propertyId == propertyId && x.productId == productId)).roleId;
      this.facadeUser.username = data.user.userName;
      this.facadeUser.user = JSON.stringify(data.user);
      if (this.userId) {
        await this.getSessionId(data.result.userToken);
      } else {
        this.quickIDError = true;
      }


    }
    if (callDesc === 'CreateSession') { // create session after LoginByQuickId
      const data = <any>result;
      if (!result.successStatus) {
        this.SetErrorMessage(result.errorCode);
        this.quickIDError = true;
        return;
      }
      this.quickIDError = false;
      this.facadeUser.sessionId = data.result;
      if (!this.quickIDError) {
        this.setQuickIdDetails();
        this.closePopUp();
      }
    }
  }

  async errorCallback<T>(result: BaseResponse<T>, callDesc: string) {
    if (callDesc === 'QuickLoginByQuickId') {
      if (!result.successStatus) {
        this.SetErrorMessage(result.errorCode);
        this.quickIDError = true;
        return;
      }
    }

    if (callDesc === 'QuickLoginByUserId') {
      const data = <any>result.result;
      if (!result.successStatus || data.userLoginInfo) {
        this.SetErrorMessage(result.errorCode);
        this.userIDError = true;
        return;
      }
    }

  }

  resetQuickIdDetails() {
    sessionStorage.removeItem('quickIdUserSession');
    sessionStorage.removeItem('quickIdJwt');
    sessionStorage.removeItem('quickIdUser');
    sessionStorage.removeItem('quickIdRoleId');
  }

  setQuickIdDetails() {
    sessionStorage.setItem('quickIdUserSession', this.facadeUser.sessionId);
    sessionStorage.setItem('quickIdJwt', this.facadeUser.jwt);
    sessionStorage.setItem('quickIdUser', this.facadeUser.username);
    sessionStorage.setItem('quickIdRoleId', this.facadeUser.roleId);
    sessionStorage.setItem('quickUserDetails',this.facadeUser.user);
  }

  unsubscribeModel() {
    if (this.useridSubscribe) {
      this.useridSubscribe.unsubscribe();
    }
    if (this.passwordSubscribe) {
      this.passwordSubscribe.unsubscribe();
    }
    if (this.quickidSubscribe) {
      this.quickidSubscribe.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeModel();
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  getDialogResult(): QuickLoginDialogResult {
    return {
      isLoggedIn: this.facadeUser && this.facadeUser.jwt && this.facadeUser.sessionId && this.facadeUser.username ? true : false,
      facadeUser: this.facadeUser,
      quickIdError: this.quickIDError,
      userIDError: this.userIDError,
      userDetails: {
        breakPointAccess: this.isBreakPointAllowed,
        userId: this.userId,
        userName: this.facadeUser.username
      }
    };
  }

  private getBreakPointErrorText() {
    this.breakPointErrorText = this._localization.getError(this.breakPointNumber);
  }
  private getMultipleBreakPointErrorText(breakPointNumber) {
    this.breakPointErrorText = this._localization.getError(breakPointNumber);
  }

  closePopUp() {
    this.validateBreakPoint().then(resBreakPoint => {
      if (resBreakPoint) {
        this.dialogRef.close(this.getDialogResult());
      }
    });
  }
  saveOnEnterKeyDown(e: string) {
    if (this.isValid) {
      const dialogObj = {
        type: e.toLowerCase(),
        dialogRef: this.dialogRef
      };
      this._commonService.valueChange.emit(dialogObj);
    }
    else {
      if (this.quickLoginForm.controls.quicklogintype.value == 'userid') {
        this.quickLoginForm.controls['userId'].markAsTouched();
        this.quickLoginForm.controls['password'].markAsTouched();
      } else {
        this.quickLoginForm.controls['quickId'].markAsTouched();
      }

    }

  }

  closeOnEscapekeyDown() {
    this.dialogRef.close('close');
  }
}


export interface FacadeUser {
  sessionId: string;
  jwt: string;
  username: string;
  user: any;
  roleId: string;
}

export interface QuickLoginDialogResult {
  isLoggedIn: boolean;
  facadeUser: FacadeUser;
  quickIdError: boolean;
  userIDError: boolean;
  userDetails: {
    breakPointAccess: boolean,
    userId: number;
    userName: string;
  }
}
