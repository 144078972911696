<section [formGroup]="ItemFormGrp" autocomplete="off">
    <div class="mb-3">
        <label class="LW14">{{captions.FilterItem}}</label>
        <app-retail-toggle-switch class="d-block" [formControl]="ItemFormGrp.controls['filterItems']" (changeToggleEvent)=changeToggleEvent($event)>
        </app-retail-toggle-switch>

      </div>
<div *ngIf="ItemFormGrp.value.filterItems">
  <div [tooltipEnabler]="captions.ItemFilter">
    <mat-form-field class="form-group tooltip-enabler" [floatLabel]="floatLabel">
      <input matInput [title]="captions.ItemFilter" placeholder="{{captions.ItemFilter}}" class="" inputtype="numberWithSeparator" formControlName="searchbyitem" (input)="isValidText($event.target.value.length > 0)">
    </mat-form-field>
  </div>

  <app-retail-multi-select-dropdown *ngIf="clerks" [dropDownFilterData] = "clerks" [dropDownControlname] = "'clerks'" [dropDownName] = captions.Users (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>

  <div class="mb-3">
    <label class="LW14">{{inActiveText}}</label>
    <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleIncludeInactive($event[0])" [formControl]="ItemFormGrp.controls['includeInactiveusers']"></app-retail-toggle-switch>

  </div>
</div>

<div *ngIf="!ItemFormGrp.value.filterItems">



      <app-retail-multi-select-dropdown *ngIf="allCategoryGroups"  [dropDownFilterData] = "allCategoryGroups" [dropDownControlname] = "'reportcategory'" [dropDownName] = captions.ReportCategory (dropDownFrmControl) = "getFormControlValue($event)" (categoryGroup) = "getCategoryGroupValue($event)"></app-retail-multi-select-dropdown>

      <app-retail-category-component (formReady)="formInitialized($event)" [categoryReportGroup]="categoryGroupSelected"
      [fromJobSchedulerEdit] = "isFromEdit" [editValues] = "categoryComponentFilter"></app-retail-category-component>

      <app-retail-multi-select-dropdown *ngIf="clerks" [dropDownFilterData] = "clerks" [dropDownControlname] = "'clerks'" [dropDownName] = captions.Users (dropDownFrmControl) = "getFormControlValue($event)" (IsAnySelected)="IsSelectionValid($event)"></app-retail-multi-select-dropdown>
      <mat-error class="user-select" *ngIf="!validSelection">{{validSelectionErrMsg}} </mat-error>
      <div *ngIf="showMachineDropDown">
      <app-retail-multi-select-dropdown *ngIf="machineName" [dropDownFilterData] = "machineName" [dropDownControlname] = "'machineName'" [dropDownName] = captions.Machines (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
      </div>

          <div class="mb-3">
              <mat-radio-group [value]="0" class="report-radio-group" formControlName="itemFilter">
                  <mat-radio-button *ngFor="let data of radioFilter" class="report-radio-button" [value]="data.id">
                    {{data.name}}
                  </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="mb-3">
              <label class="LW14">{{inActiveText}}</label>
              <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleIncludeInactive($event[0])" [formControl]="ItemFormGrp.controls['includeInactiveusers']"></app-retail-toggle-switch>

            </div>
</div>
<div class="mb-3">
  <label class="LW14">{{captions.IncludeNotMapped}}</label>
  <app-retail-toggle-switch class="d-block" [formControl]="ItemFormGrp.controls['includeCategoryNotMapped']">
  </app-retail-toggle-switch>
  </div>
</section>
