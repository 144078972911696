import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService } from '@app/app.service';
import { LoaderService } from '@app/core/services/loader.service';
import { ITabOutputDetails } from '@app/settings/models/common.interface';
import { ComponentTypes, GuestBookTabs, Menu, RolesAndPermissionsType,SlottingType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { ContactDTO, CustomGuestField } from '@models/InputContact';
import { TabsModel } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { GuestBookService } from '@services/guestbook.service';
import _ from 'lodash';
import { ISubscription } from 'rxjs/Subscription';
import { GuestlistComponent } from './guestlist/guestlist.component';


@Component({
  selector: 'app-guestbook',
  templateUrl: './guestbook.component.html',
  styleUrls: ['./guestbook.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GuestbookComponent implements OnInit, AfterViewInit, OnDestroy {
  GuestBookTabs = GuestBookTabs;
  selectedGuestTab: GuestBookTabs;
  guestBookTabs: TabsModel;
  defaultTab: number;
  feedbackSubscription: ISubscription;
  guestSubscription: ISubscription;

  constructor(public guestBookService: GuestBookService, private appService: AppService, private ps: PopupService, private cs: CacheService,
              private cdf: ChangeDetectorRef,private ls:LoaderService) {
    appService.applySetting(Menu.GuestBook);
  }

  ngOnInit() {
    this.defaultTab = GuestBookTabs.AllGuests;
    this.guestBookService.pageSize = 25;
    this.guestBookService.pageNumber = 0;
    this.guestBookService.isRecent = this.defaultTab === GuestBookTabs.RecentGuests ? true : false;
    this.guestBookService.isVIP = this.defaultTab === GuestBookTabs.VipGuests ? true : false;
    this.guestBookService.isFavourite = this.defaultTab === GuestBookTabs.FavouriteGuests ? true : false;
    this.guestBookService.isFeedback = this.defaultTab === GuestBookTabs.Feedback ? true : false;
    this.guestBookService.searchString = '';
    this.guestBookService.currentGuestTab = this.defaultTab;
    this.guestBookService.isGuestDetailsVisible.next(false);
      this.guestBookService.feedbackLastDate=null;
      this.guestBookService.FeedbackFilterOption=0;
      this.guestBookService.feedbackSort=0;
      if (this.feedbackSubscription)
      this.feedbackSubscription.unsubscribe();
    this.guestBookService.isConfirmationCodeSearch = false;
    this.feedbackSubscription = this.guestBookService.Feedbackaction.subscribe(x => {
      this.loadContacts();
      if (!x) {
        this.ps.closeDialog$.next();
      }
    });
    //this.appService.selectedMenu = Labels[Labels.guestBookMenuText];
    this.selectedGuestTab = GuestBookTabs.AllGuests;
    this.guestBookTabs = {
      tabs: [{
        tabComponent: GuestlistComponent,
        tabLabel: 'guestBookAllGuestTabLabel',
      },
      {
        tabComponent: GuestlistComponent,
        tabLabel: 'guestBookVIPText',
      },
      {
        tabComponent: GuestlistComponent,
        tabLabel: 'guestBookFavouriteTabLabel'
      }
    ],
      default: true
    };
    if(this.appService.hasPermission(RolesAndPermissionsType.ViewFeedback)){
      this.guestBookTabs.tabs.push( {
        tabComponent: GuestlistComponent,
        tabLabel: 'guestBookFeedbackTabLabel',
    })
    }
    this.loadContacts();
    this.appService.datePickerDisabled.next(true);
    this.appService.seatingAreaFilterDisabled.next(true);
  }

  ngAfterViewInit() {
    this.ls.showMessage.next(false)
    this.cdf.detectChanges();
  }

  seletedTabChange(event) {
    switch (event.index) {
      case GuestBookTabs.AllGuests:
        this.selectedGuestTab = GuestBookTabs.AllGuests;
        break;
      case GuestBookTabs.RecentGuests:
        this.selectedGuestTab = GuestBookTabs.RecentGuests;
        break;
      case GuestBookTabs.VipGuests:
        this.selectedGuestTab = GuestBookTabs.VipGuests;
        break;
      case GuestBookTabs.FavouriteGuests:
        this.selectedGuestTab = GuestBookTabs.FavouriteGuests;
        break;
      case GuestBookTabs.Feedback:
      this.selectedGuestTab = GuestBookTabs.Feedback;
      break;
    }
  }

  tabChange(event) {
    if (event && event.index >= 0) {
      switch (event.index) {
        case 0:
          this.setTabDetails(false, false, false, false, event);
          break;
        case 1: //VIP
          this.setTabDetails(false, true, false, false, event);
          break;
        case 2: //Favorite
          this.setTabDetails(false, false, true, false, event);
          break;
        case 3: //Feedback
          this.setTabDetails(false, false, false, true, event);
          break;
      }

    }
  }

  setTabDetails(isRecent, isVIP, isFavourite, isFeedback, tabEvent) {
    this.guestBookService.page = 1;
    this.guestBookService.pageNumber = 0;
    this.guestBookService.isRecent = isRecent;
    this.guestBookService.isVIP = isVIP;
    this.guestBookService.isFavourite = isFavourite;
    this.guestBookService.isFeedback = isFeedback;
    this.guestBookService.currentGuestTab = tabEvent.index;
    this.guestBookService.searchString = '';
    this.guestBookService.guestListTabChange$.next(tabEvent);
    this.guestBookService.isGuestDetailsVisible.next(false);
    this.guestBookService.selectedContactIndex = null;

    if(isFeedback)
    {
      this.guestBookService.feedbackLastDate=null;
      this.guestBookService.FeedbackFilterOption=0;
      this.guestBookService.feedbackSort=0;
      this.guestBookService.contacts = [];
      this.guestBookService.allAvailableContacts.next([]);
      if(this.guestBookService._settings.FeedbackQuestionaries.length > 0)
      {

        this.loadContacts();
      }
    }else
    {
    this.loadContacts();
    }
  }

  loadContacts() {
    this.guestBookService.contacts = [];
    this.guestBookService.loadContacts();
  }

  submitGuest() {
    if (this.guestBookService.guestForm.valid) {
      this.guestBookService.createGuest(true, this.guestBookService.createContactDialogRef);
    }
  }

  updateGuest(guestDetails) {
    if (this.guestBookService.guestForm.valid) {
      this.guestBookService.updateGuest(true, guestDetails, this.guestBookService.createContactDialogRef);
    }
  }

  getCustomGuestFiledsId(guestDetails, customField: CustomGuestField): number {
    const contactCustomField = guestDetails.ContactCustomFields.filter((field) => field.CustomFieldId === customField.Id);
    let customFieldId = null;
    if (contactCustomField.length > 0) {
      customFieldId = contactCustomField[0].Id;
    }
    return customFieldId;
  }

  ngOnDestroy() {
    if (this.feedbackSubscription)
    this.feedbackSubscription.unsubscribe();
    if (this.guestSubscription) {
      this.guestSubscription.unsubscribe();
    }
  }
}
