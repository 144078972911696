import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports, OutletAndCategoryFilter } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from "../report-business.service";
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";

export class PurchaseOrders extends BaseReport {
    functionalities: { [key: string]: boolean } = {};
    // tslint:disable-next-line:max-line-length
    constructor(public reportFormGrp: UntypedFormGroup,
                public data: ReportDataService,
                public business: ReportBusinessService,
                private func: RetailFunctionalityBusiness,
                private propertyInfo: RetailPropertyInformation) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.PurchaseOrders,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: false
        };
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: false,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlet,
            inActiveToggle: false,
            pageBreakToggle: false,
            layout: 'PORTRAIT',
            allowFutureDate: false
        };
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;
        let reportcategoryCaption: string;
        let captions: string[] = [];
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let clerkCaption: string = customFilterData.clerks ? this.business.getFilterCaption(this.business.FilterDataSource["allVendors"],
            customFilterData.clerks.map(x => x.id)) : "";

        return [
            { "pStartDate": toApi(this.startDate) },
            { "pEndDate": toApi(this.endDate) },
            {
                "pOutlet": this.functionalities.ShowOutletSelectionFieldInVendorInventoryDetailReport ?
                    this.getFilterCaption : this.getDefaultOutletCaption
            },
            { "pPropertyName": this.propertyName },
            { 'pDate': this.printedDate },
            { 'pVendor': clerkCaption }];
    }


    protected formURIParams(): ReportParams[] {
        return [];
    }


    protected formFilters() {
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let toApi = this.localization.convertDateObjToAPIdate;
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        const tempOutletFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        let filter: Partial<OutletAndCategoryFilter> = {
            startDate: toApi(this.startDate),
            endDate: toApi(this.endDate),
            Supplier: customFilterData.clerks.map(o => o.description),
            Location: this.functionalities.ShowOutletSelectionFieldInVendorInventoryDetailReport ?
            this.selectedFilterOptions.map(x => x.description):[],
            PONumber:[],
            ReportCode: AllReports.PurchaseOrders
        };
        return JSON.stringify(filter);
    }
}


