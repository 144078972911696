import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { PopupService } from '@app/popup-module/popup.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { Subscription } from 'rxjs';
import { CalendarViewType } from '@app/shared/constants/commonenums';

@Component({
  selector: 'app-calendar-cell',
  templateUrl: './calendar-cell.component.html',
  styleUrls: ['./calendar-cell.component.scss']
})
export class CalendarCellComponent implements OnInit,OnDestroy {

  @Input('gridData') gridData;
  @Input('currentMonth') currentMonth: number;
  @Input('currentDate') currentDate: number;
  @Input('currentYear') currentYear: number;
  @Input('currentViewMode') currentViewMode: CalendarViewType;
  @Input('headerDate') headerDate;
  CalendarViewType = CalendarViewType;
  @Output() selectedDate = new EventEmitter();
  isSingleClick: Boolean = true; 
  subscriptions: Subscription = new Subscription();
  today: Date;

  constructor(private ps: PopupService,private as: AppService,private cs: CacheService) { 
    this.subscriptions.add(cs.state.subscribe(data => {
      this.today = Utilities.parseDateString(data.CurrentTime);
    }));
  }
  
  ngOnInit(): void {
  }
  
  selectedCalendarDate(date) {
    this.isSingleClick = true;
    setTimeout(() => {
      if(this.isSingleClick) {
        this.selectedDate.emit(date);
      }
    },250)
  }
  
  chosenHeaderDate(date) {
    this.isSingleClick = false;
    if(date.overviewData?.length){
    this.as.headerSelectedDate = new Date(date.overviewData[0].Date);
    this.ps.closeDialog$.next();
    }
  }
  
  ngOnDestroy(): void {
    if(this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
