import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { EmptyValueValidator } from '../../shared/Validators/EmptyValueValidator';
import { AlertAction } from '../../shared/shared.modal';
import { PropertyFeature, FeaturesConfigurations } from '../../shared/business/property-features.model';
import { PropertyFeaturesConfigurationService } from './property-feature-config.service';
import { MultiPropertyDefaultToggle, RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';
import { FeatureName } from '../../common/services/retail-property-information.service';
import { FeatureValue } from '../../shared/globalsContant';
import _ from 'lodash';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';

@Component({
  selector: 'app-payment-features-config',
  templateUrl: './payment-features-config.component.html',
  styleUrls: ['./payment-features-config.component.scss'],
  providers: [PropertyFeaturesConfigurationService, RetailFeatureFlagInformationService]
})
export class PaymentFeaturesConfigComponent implements OnInit {
  captions: any;
  featureList: PropertyFeature[];
  selectedFeature: PropertyFeature;
  paymentFeatureConfig: UntypedFormGroup;
  viewOnly = false;
  offerManagementFormGroup: UntypedFormGroup;
  multiPMSFormGroup: UntypedFormGroup;
  featureConfig: FeaturesConfigurations[] = [];
  featureConfigTemp: FeaturesConfigurations[] = [];
  isUserAuthorized: boolean = true;
  isViewOnly: boolean = false;
  isUpdateEnabled: boolean = false;
  ConfigPairs: any = [];
  floatLabel: string;
  featureNameConst = FeatureName;
  FeatureFormArrayMap = {
    [FeatureName.OfferManagementSystem]: "offer",
    [FeatureName.MultiPMSRoomGroupCharge]: "propertyList"
  }
  get currentFormArrayName() {
    return this.FeatureFormArrayMap[this.selectedFeature.featureName]
  }
  get currentFormGroup(): UntypedFormGroup {
    let currFormGrp;
    switch (this.selectedFeature.featureName) {
      case FeatureName.OfferManagementSystem:
        currFormGrp = this.offerManagementFormGroup;
        break;
      case FeatureName.MultiPMSRoomGroupCharge:
        currFormGrp = this.multiPMSFormGroup;
        break;
      default:
        currFormGrp = this.paymentFeatureConfig;
        break;
    }
    return currFormGrp;
  }

  constructor(
    private localization: RetailLocalization
    , private fb: UntypedFormBuilder
    , private utilities: RetailUtilities
    , private _rs: RetailSetupService
    , private propertyFeatureservice: PropertyFeaturesConfigurationService
    , private retailFeatureFlagInfo: RetailFeatureFlagInformationService
  ) {
    this.floatLabel = this.localization.setFloatLabel;
  }

  async ngOnInit() {
    this.captions = this.localization.captions.setting;
    this.featureList = [];
    this.InitilizeControls();    
    this.viewOnly = this._rs.retailSetupBreakPoints.find(bp => bp.breakPointNumber == myGlobals.RetailBreakPoint.VATConfiguration).view;
    this.utilities.ToggleLoader(true,this.captions.lbl_processing);
    await this.GetPropertyFeatures();
  }

  selectfeature(feature: PropertyFeature) {
    if (feature.id != this.selectedFeature.id) {
      if (this.currentFormGroup.dirty) {
        let dialogRef = this.utilities.openSaveChangedWarningPopup();
        dialogRef.afterClosed().subscribe(result => {
          if (result.toUpperCase() == AlertAction.NO) {
            return;
          } else {
            this.changeFeature(feature);
          }
        });
      } else {
        this.changeFeature(feature);
      }
    }
  }

  async GetPropertyFeatures() {
    let result = await this.propertyFeatureservice.getPropertyFeatures().finally(()=>{
      this.utilities.ToggleLoader(false);
    });
    if (result && result.length > 0 && result.some(x => x.isActive)) {
      this.featureList = result.filter(x => x.isActive);
      if (!this.selectedFeature) {
        this.changeFeature(this.featureList[0]);
      }
    }
  }

  async changeFeature(feature: PropertyFeature) {
    this.selectedFeature = feature;
    this.InitilizeControls();
    if (this.selectedFeature.featureName) {
      this.currentFormGroup.patchValue({
        ACTIVATE_INTERFACE: this.selectedFeature.isActive
      });
    }
    this.GetFeatureConfiguration(feature.isActive, feature);
  }

  async SaveAction() {
    this.utilities.ToggleLoader(true,this.captions.lbl_processing);
    const NonRegularFeatures = [FeatureName.OfferManagementSystem, FeatureName.MultiPMSRoomGroupCharge]
    if (NonRegularFeatures.includes(this.selectedFeature.featureName)) {
      this.saveFeatureGroup();
    }
    else {
      this.save();
    }
  }

  async save() {
    let configPairs = this.paymentFeatureConfig.get('ConfigPairs') as UntypedFormArray;
    let configValues: FeaturesConfigurations[] = configPairs.value.map(x => {
      return {
        configurationValue: x.gateway_value.trim(),
        configurationKey: x.gateway_key.trim(),
        propertyFeatureId: x.propertyFeatureId
      };
    });
    configValues = configValues.filter(c => c.configurationKey != '');
    await this.propertyFeatureservice.saveFeatureConfiguration(configValues, this.selectedFeature.moduleId).finally(()=>{
      this.utilities.ToggleLoader(false);
    });
    
    await this.GetFeatureConfiguration(this.paymentFeatureConfig.get('ACTIVATE_INTERFACE').value, this.selectedFeature).finally(()=>{
      this.utilities.ToggleLoader(false);
    });
    
    this.retailFeatureFlagInfo.RefreshConfig();
    this.paymentFeatureConfig.markAsPristine();
    this.isUpdateEnabled = false;
  }

  async saveFeatureGroup() {
    let configPairs = [];
    let configPairsTemp = this.currentFormGroup.controls[this.currentFormArrayName].value.map(x => x);
    const FeatureKeyMap = {
      [FeatureName.OfferManagementSystem]: FeatureValue.OfferManagementSystem,
      [FeatureName.MultiPMSRoomGroupCharge]: FeatureValue.Property
    }
    configPairsTemp.forEach(x => {
      configPairs.push(x.ConfigPairs);
      if (this.selectedFeature.featureName == FeatureName.MultiPMSRoomGroupCharge) {
        configPairs.push({
          gateway_index: x.ConfigPairs?.[0]?.gateway_index,
          gateway_key: MultiPropertyDefaultToggle.DefaultGroupCharge,
          gateway_value: x.defaultGroupCharge,
          propertyFeatureId: x.ConfigPairs?.[0]?.propertyFeatureId,
          gateway_arrayIndex: x.ConfigPairs?.[0]?.gateway_arrayIndex
        })
        configPairs.push({
          gateway_index: x.ConfigPairs?.[0]?.gateway_index,
          gateway_key: MultiPropertyDefaultToggle.DefaultRoomCharge,
          gateway_value: x.defaultRoomCharge,
          propertyFeatureId: x.ConfigPairs?.[0]?.propertyFeatureId,
          gateway_arrayIndex: x.ConfigPairs?.[0]?.gateway_arrayIndex
        })
      }
    });

    let configValues: FeaturesConfigurations[] = [];
    configPairs.sort(e => e.gateway_arrayIndex);
    const addConfigValue = (d) => {
      let c: FeaturesConfigurations = {
        configurationValue: String(d.gateway_value)?.trim(),
        configurationKey: FeatureKeyMap[this.selectedFeature?.featureName] + '.' + (d.gateway_arrayIndex + 1) + '.' + d.gateway_key?.trim(),
        propertyFeatureId: this.selectedFeature.id
      };
      configValues.push(c);
    }
    configPairs.forEach(x => {
      if (Array.isArray(x)) {
        x.forEach(d => { addConfigValue(d) })
      } else {
        addConfigValue(x)
      }
    });
    configValues = configValues.filter(c => c.configurationKey.split('.')[2] != '');
    await this.propertyFeatureservice.saveFeatureConfiguration(configValues, this.selectedFeature.moduleId).finally(()=>{
      this.utilities.ToggleLoader(false);
    });
  
    await this.GetFeatureConfiguration(this.currentFormGroup.get('ACTIVATE_INTERFACE').value, this.selectedFeature).finally(()=>{
      this.utilities.ToggleLoader(false);
    });
   
    this.retailFeatureFlagInfo.RefreshConfig();
    this.currentFormGroup.markAsPristine();
    this.isUpdateEnabled = false;
  }

  cancel() {
    this.GetFeatureConfiguration(this.selectedFeature.isActive, this.selectedFeature);
    if (this.selectedFeature.featureName) {
      this.currentFormGroup.patchValue({
        ACTIVATE_INTERFACE: this.selectedFeature.isActive
      });
      this.currentFormGroup.markAsPristine();
    }
    this.isUpdateEnabled = false;
  }

  async GetFeatureConfiguration(isActive: boolean, feature: PropertyFeature) {
    this.featureConfig = [];
    if (isActive) {
      let config = await this.propertyFeatureservice.getFeatureConfiguration(feature.id, feature.moduleId);
      if (feature.featureName == FeatureName.OfferManagementSystem || feature.featureName == FeatureName.MultiPMSRoomGroupCharge) {
        let offerKeyValues = [];
        config.forEach(obj => {
          offerKeyValues.push(obj.configurationKey.split('.')[1]);
        });
        let maxOfferValue = Math.max(...offerKeyValues);
        if (config && config.length > 0) {
          this.featureConfig = config;
          this.featureConfig.sort(e => Number(e.configurationKey.split('.')[1]));
          if (this.featureConfig && this.featureConfig.length > 0) {
            this.removeAllConfigPairs();
          }
          for (var i = 1; i <= maxOfferValue; i++) {
            this.featureConfigTemp = this.featureConfig.filter(c => Number(c.configurationKey.split('.')[1]) == i)
            this.featureConfigTemp.forEach(x => {
              let indexValue = x.configurationKey.split('.')[1];
              const configKey = x.configurationKey.replace(x.configurationKey.split('.')[0] + '.' + x.configurationKey.split('.')[1] + '.', '');
              if ((configKey == MultiPropertyDefaultToggle.DefaultGroupCharge || configKey == MultiPropertyDefaultToggle.DefaultRoomCharge) && feature.featureName == FeatureName.MultiPMSRoomGroupCharge) {
                //If Only one property is configured then toggles need to be enabled by default
                const configVal = maxOfferValue == 1 ? true : x.configurationValue.toLowerCase() == 'true';
                if (this.multiPMSFormGroup.controls?.propertyList?.get(String(Number(indexValue) - 1))) {
                  this.multiPMSFormGroup.controls.propertyList.get(String(Number(indexValue) - 1)).get(configKey)?.setValue(configVal);
                } else { //Which means there is no keys added so adding blank entry
                  this.onAddConfigPairFromFeatureGroup(this.currentFormGroup, this.currentFormArrayName, x.propertyFeatureId, "", "", x.propertyFeatureId, false, indexValue);
                }
              } else {
                this.onAddConfigPairFromFeatureGroup(this.currentFormGroup, this.currentFormArrayName, x.propertyFeatureId, configKey, x.configurationValue, x.propertyFeatureId, false, indexValue);
              }
            });
          }
        } else {
          let configPair = this.paymentFeatureConfig.get('ConfigPairs') as UntypedFormArray;
          if (configPair && configPair.length > 0) {
            configPair.controls[0].patchValue({ propertyFeatureId: this.selectedFeature.id });
          }
        }
      }
      else {
        if (config && config.length > 0) {
          this.featureConfig = config;
          if (this.featureConfig && this.featureConfig.length > 0) {
            this.removeAllConfigPairs();
          }
          this.featureConfig.forEach(x => {
            this.addConfigPairs(x.propertyFeatureId, x.configurationKey, x.configurationValue, x.propertyFeatureId);
          });
        } else {
          let configPair = this.paymentFeatureConfig.get('ConfigPairs') as UntypedFormArray;
          if (configPair && configPair.length > 0) {
            configPair.controls[0].patchValue({ propertyFeatureId: this.selectedFeature.id });
          }
        }
      }
    }
  }

  InitilizeControls() {

    this.paymentFeatureConfig = this.fb.group({
      ACTIVATE_INTERFACE: false,
      ConfigPairs: this.fb.array([this.createGatewayPairItem(0, '', '', 0)])
    });

    this.offerManagementFormGroup = this.fb.group({
      ACTIVATE_INTERFACE: false,
      offer: this.fb.array([this.createPaymentFeatureGroup(0, '', '', 0, 0)])
    });
    this.offerManagementFormGroup.markAsPristine();

    this.multiPMSFormGroup = this.fb.group({
      ACTIVATE_INTERFACE: false,
      propertyList: this.fb.array([this.createMultiPMSPaymentFeatureGroup(0, '', '', 0, 0, true)])
    });
    this.paymentFeatureConfig.markAsPristine();

    this.setValidators();
    this.isUpdateEnabled = false;
  }

  createPaymentFeatureGroup(_index: number, _key: any, _value: any, _id: number, _arrayIndex: number): UntypedFormGroup {
    if (this.selectedFeature?.featureName == FeatureName.MultiPMSRoomGroupCharge) {
      return this.createMultiPMSPaymentFeatureGroup(_index, _key, _value, _id, _arrayIndex);
    } else {
      return this.fb.group({
        ACTIVATE_INTERFACE: false,
        ConfigPairs: this.fb.array([this.createGatewayPairItemOfferManagement(_index, _key, _value, _id, _arrayIndex)])
      });
    }
  }

  createMultiPMSPaymentFeatureGroup(_index: number, _key: any, _value: any, _id: number, _arrayIndex: number, onInit = false): UntypedFormGroup {    
      return this.fb.group({
        ACTIVATE_INTERFACE: false,
        ConfigPairs: this.fb.array([this.createGatewayPairItemOfferManagement(_index, _key, _value, _id, _arrayIndex)]),
        defaultGroupCharge: onInit,
        defaultRoomCharge: onInit
      }); 
  }

  createGatewayPairItem(_index: number, _key: any, _value: any, _id: number): UntypedFormGroup {
    return this.fb.group({
      gateway_index: _index,
      gateway_key: _key,
      gateway_value: _value,
      propertyFeatureId: _id
    });
  }

  createGatewayPairItemOfferManagement(_index: number, _key: any, _value: any, _id: number, _arrayIndex: number): UntypedFormGroup {
    return this.fb.group({
      gateway_index: _index,
      gateway_key: _key,
      gateway_value: _value,
      propertyFeatureId: _id,
      gateway_arrayIndex: _arrayIndex
    });
  }

  addConfigPairs(_index: number, _key: any, _value: any, id: number, addedfromUI: boolean = false) {

    if (!this.isUserAuthorized || this.isViewOnly)
      return;

    if (!this.isControlValid('ConfigPairs'))
      return;

    this.ConfigPairs = this.paymentFeatureConfig.get('ConfigPairs') as UntypedFormArray;
    this.ConfigPairs.push(this.createGatewayPairItem(_index, _key, _value, id));
    if (addedfromUI) {
      this.enableKeyValuePairValidation();
    }
  }

  onAddConfigPairFromFeatureGroup(formGrp: UntypedFormGroup, arrayName: string, _index: number, _key: any, _value: any, id: number, addedfromUI: boolean = false, arrayIndex?) {
    if (!addedfromUI) {
      arrayIndex = arrayIndex - 1;
    }
    if (!this.isUserAuthorized || this.isViewOnly)
      return;

    if (!this.isFeatureGrpControlValid(formGrp, arrayName, 'ConfigPairs', arrayIndex))
      return;
    const controls = arrayIndex == null ? null : formGrp.get(arrayName).get(arrayIndex.toString());
    this.ConfigPairs = (controls == null ? formGrp.get(arrayName) as UntypedFormArray : formGrp.get(arrayName)['controls'][arrayIndex].controls.ConfigPairs as UntypedFormArray);
    this.ConfigPairs.push(controls == null ? this.createPaymentFeatureGroup(_index, _key, _value, id, arrayIndex)
      : this.createGatewayPairItemOfferManagement(_index, _key, _value, id, arrayIndex));
    if (addedfromUI) {
      this.enableKeyValuePairValidationOnDynamicGroup(formGrp, arrayName);
    }
  }

  removeConfigPairs(_index: any) {
    if (!this.isUserAuthorized || this.isViewOnly)
      return;

    this.ConfigPairs = this.paymentFeatureConfig.get('ConfigPairs') as UntypedFormArray || [];
    this.ConfigPairs.removeAt(_index);
    this.paymentFeatureConfig.markAsDirty();
    this.enableKeyValuePairValidation();
  }

  onRemoveConfigPairFromFeatureGroup(formGrp: UntypedFormGroup, arrayName: string, _index: any, arrayIndex?) {

    if (!this.isUserAuthorized || this.isViewOnly)
      return;

    this.ConfigPairs = (arrayIndex == null ? formGrp.get(arrayName) as UntypedFormArray : formGrp.get(arrayName)['controls'][arrayIndex].controls.ConfigPairs as UntypedFormArray);
    this.ConfigPairs.removeAt(_index);
    formGrp.markAsDirty();
    this.enableKeyValuePairValidationOnDynamicGroup(formGrp, arrayName);
  }

  removeAllConfigPairs() {
    if (this.selectedFeature.featureName == FeatureName.OfferManagementSystem) {
      this.ConfigPairs = this.offerManagementFormGroup.get('offer') as UntypedFormArray || [];
      while (this.ConfigPairs.length !== 0) {
        this.ConfigPairs.removeAt(0);
      }
    }
    else if (this.selectedFeature.featureName == FeatureName.MultiPMSRoomGroupCharge) {
      this.ConfigPairs = this.multiPMSFormGroup.get('propertyList') as UntypedFormArray || [];
      while (this.ConfigPairs.length !== 0) {
        this.ConfigPairs.removeAt(0);
      }
    }
    else {
      this.ConfigPairs = this.paymentFeatureConfig.get('ConfigPairs') as UntypedFormArray || [];
      while (this.ConfigPairs.length !== 0) {
        this.ConfigPairs.removeAt(0);
      }
    }
  }

  setValidators() {
    if (this.selectedFeature != undefined && this.selectedFeature.featureName) {
      const ValidateButtonState = (formGrp) => {
        if (!this.isUserAuthorized || this.isViewOnly)
          return;

        if (formGrp.valid && formGrp.dirty)
          this.isUpdateEnabled = true;
        else
          this.isUpdateEnabled = false;
      }

      this.paymentFeatureConfig.valueChanges.subscribe(() => { ValidateButtonState(this.paymentFeatureConfig) });
      this.offerManagementFormGroup.valueChanges.subscribe(() => { ValidateButtonState(this.offerManagementFormGroup) });
      this.multiPMSFormGroup.valueChanges.subscribe(() => { ValidateButtonState(this.multiPMSFormGroup) });
    }
  }

  clearValiators(control: UntypedFormControl) {
    control.setValidators(null);
    control.clearValidators();
    control.updateValueAndValidity();
  }

  enableKeyValuePairValidation() {
    this.toggleKeyValueValidation(false);

    if (this.paymentFeatureConfig.get("ACTIVATE_INTERFACE").value)
      this.toggleKeyValueValidation(true);

    this.paymentFeatureConfig.get("ConfigPairs").markAsDirty();
    let gatewayValues: UntypedFormArray = this.paymentFeatureConfig.get("ConfigPairs") as UntypedFormArray;
    gatewayValues.markAsDirty();
  }

  enableKeyValuePairValidationOnDynamicGroup(formGroup: UntypedFormGroup, formArrName) {
    this.toggleKeyValueValidationOnDynamicGroup(false, formGroup, formArrName);
    if (formGroup.get("ACTIVATE_INTERFACE").value)
      this.toggleKeyValueValidationOnDynamicGroup(true, formGroup, formArrName);
    let controle = formGroup.get(formArrName)['controls'];
    _.forEach(controle, (x) => {
      x.get("ConfigPairs").markAsDirty();
      let gatewayValues: UntypedFormArray = x.get("ConfigPairs") as UntypedFormArray;
      gatewayValues.markAsDirty();
    });
  }

  toggleKeyValueValidationOnDynamicGroup(status: boolean, fromGrp: UntypedFormGroup, formArrayName: string) {
    if (status) {
      let controle = fromGrp.controls[formArrayName]['controls'];
      _.forEach(controle, (x) => {
        let controle1 = x.controls.ConfigPairs.controls;
        _.forEach(controle1, (y, i: number) => {
          console.log(y.controls);
          y['controls']['gateway_key'].setValidators([Validators.required, EmptyValueValidator]);
          y['controls']['gateway_value'].setValidators([Validators.required, EmptyValueValidator]);

          if (i == 0) {
            y['controls']['gateway_key'].markAsTouched();
            y['controls']['gateway_value'].markAsTouched();
          }

          y['controls']['gateway_key'].updateValueAndValidity();
          y['controls']['gateway_value'].updateValueAndValidity();
        });
      });
    }
    else {
      let controle = fromGrp.controls[formArrayName]['controls'];
      _.forEach(controle, (x, index: number) => {
        let controle1 = x.controls.ConfigPairs.controls;
        _.forEach(controle1, (y) => {
          console.log(y.controls);
          this.clearValiators(y['controls']['gateway_key'] as UntypedFormControl);
          this.clearValiators(y['controls']['gateway_value'] as UntypedFormControl);
          let arrayIndexControl = y['controls']['gateway_arrayIndex'] as UntypedFormControl;
          arrayIndexControl.setValue(index);
          this.clearValiators(arrayIndexControl);
        });
      });
    }
  }

  updatesequence() {
    let controle = this.offerManagementFormGroup.controls.offer['controls'];
    _.forEach(controle, (x, index: number) => {
      let controle1 = x.controls.ConfigPairs.controls;
      _.forEach(controle1, (y) => {
        console.log(y.controls);
        this.clearValiators(y['controls']['gateway_key'] as UntypedFormControl);
        this.clearValiators(y['controls']['gateway_value'] as UntypedFormControl);
        let arrayIndexControl = y['controls']['gateway_arrayIndex'] as UntypedFormControl;
        arrayIndexControl.setValue(index);
        this.clearValiators(arrayIndexControl);
      });
    });
  }

  toggleKeyValueValidation(status: boolean) {
    if (status) {
      this.paymentFeatureConfig.get("ConfigPairs")['controls'].map((x: UntypedFormGroup, index: number) => {
        x['controls']['gateway_key'].setValidators([Validators.required, EmptyValueValidator]);
        x['controls']['gateway_value'].setValidators([Validators.required, EmptyValueValidator]);

        if (index == 0) {
          x['controls']['gateway_key'].markAsTouched();
          x['controls']['gateway_value'].markAsTouched();
        }

        x['controls']['gateway_key'].updateValueAndValidity();
        x['controls']['gateway_value'].updateValueAndValidity();
      });
    }
    else {
      this.paymentFeatureConfig.get("ConfigPairs")['controls'].map((x: UntypedFormGroup, index: number) => {
        this.clearValiators(x['controls']['gateway_key'] as UntypedFormControl);
        this.clearValiators(x['controls']['gateway_value'] as UntypedFormControl);
      });
    }
  }

  getErrorMessage(control: string, index: number) {
    if (control == "GatewayPairs") {
      if (index == 0 && this.paymentFeatureConfig.get("ConfigPairs")['controls'].length == 1) {
        return this.paymentFeatureConfig.get("ACTIVATE_INTERFACE").value ? this.captions.MissingKeyPairValue : '';
      }
    }
  }

  public isControlValid(controlname: string) {
    const invalid = [];
    const controls = this.paymentFeatureConfig.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid.indexOf(controlname) == -1;
  }

  isFeatureGrpControlValid(formGrp: UntypedFormGroup, formArrName, controlname: string, index) {
    const invalid = [];
    for (let i = 0; i < formGrp.value.length; i++) {
      const controls = formGrp.get(formArrName).get(i.toString());
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
    }
    return invalid.indexOf(controlname) == -1;
  }

  keyPress(event: any) {
    if (event.target.value.length >= 200) {
      event.preventDefault();
    }
  }

  changeGroupChargeDefault(e, formGrp: UntypedFormGroup, index) {
    console.log(e);
    let formInd = 0;
    while (formInd < this.multiPMSFormGroup.get("propertyList").value.length) {
      if (formInd != index)
        this.multiPMSFormGroup.controls.propertyList.get(String(formInd)).get(MultiPropertyDefaultToggle.DefaultGroupCharge).setValue(false);
      formInd++;
    }
    formGrp.controls.defaultGroupCharge.setValue(e[0]);
    this.multiPMSFormGroup.markAsDirty();
  }

  changeRoomChargeDefault(e, formGrp: UntypedFormGroup, index) {
    console.log(e);
    let formInd = 0;
    while (formInd < this.multiPMSFormGroup.get("propertyList").value.length) {
      if (formInd != index)
        this.multiPMSFormGroup.controls.propertyList.get(String(formInd)).get(MultiPropertyDefaultToggle.DefaultRoomCharge).setValue(false);
      formInd++;
    }
    formGrp.controls.defaultRoomCharge.setValue(e[0]);
    this.multiPMSFormGroup.markAsDirty();
  }

}
