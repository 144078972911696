import { Location } from '@angular/common';
import { Component, Input, OnChanges, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ButtonValue } from '@dynamicform/models/field-config.interface';

@Component({
  selector: 'activities-app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ButtonComponent implements OnChanges, AfterViewInit {
  @ViewChild('dynamicbutton', { read: true }) dynamicbutton: ElementRef;
  @Input() buttontype: ButtonValue;
  constructor(public _EL: ElementRef) { }
  @Output() valueChange = new EventEmitter();
  handleclick(e: ReturnButtonValue,divId?, from?,printerType?): void {
    if(from && from == 'chit-print' || ( from == 'ticket-print-section' && !printerType) ){
      this.print(divId);
    }
    else{
    this.valueChange.emit(e);
    }
  }
  print(divId) {
    var queries = document.querySelectorAll("#" + divId);
    var printWindow = window.open('', 'print', 'height=800,width=800,fullscreen=no,scrollbars=yes,titlebar=yes,menubar=yes');
    printWindow.document.write('<html><head>');
    printWindow.document.write('<style>' + this.buttontype.printStyle
+
      '</style>')

    printWindow.document.write('</head><body onload="window.print()" onmouseover="window.close()">');
    printWindow.document.write(queries[queries.length - 1].innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();

  }
  ngAfterViewInit() {
  }
  /**
   * @method function;
   * @function ngOnChanges;
   * @description change the input value
   */
  ngOnChanges() {
  }
}




export interface ReturnButtonValue {
  event: object;
  buttontype?: string;
  value?: number;
}
