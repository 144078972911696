import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@app/app.service';
import { buttonTypes } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { environment } from '@environments/environment';
import { SeatingAreaDTO, SettingsDTO } from '@models/RestaurantDTO';
import { WaitTimeDTO, waitTimeForRestaurant, WaitTimesDTO, WaitTimesRowView, WaitTimeView } from '@models/WaitTimesDTO';
import { TranslateService } from '@ngx-translate/core';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-waittime-report-component',
  templateUrl: './waittime-report-component.html',
  styleUrls: ['./waittime-report-component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WaitTimeReportComponent implements OnInit, OnDestroy {
  seatingAreaBtn: ButtonValue;
  restaurantBtn: ButtonValue;
  autoResetBtn: ButtonValue;
  saveChangesBtn: ButtonValue;
  cancelChangesBtn: ButtonValue;
  settings: SettingsDTO;
  waitTimes: WaitTimesDTO;
  waitTimesCopy: WaitTimesDTO;
  seatingAreas: SeatingAreaDTO[] = [];
  waitTimesRow: WaitTimesRowView[] = [];
  waitTimesRowForRestaurant: WaitTimesRowView[] = [];
  waitTimeFormGroup: UntypedFormGroup[] = [];
  list$: BehaviorSubject<WaitTimesRowView[]>;
  waitTimesDataSource:any;
  controls: UntypedFormArray;
  Columns: string[] = [];
  staticColumn: string[] = ['partysize'];
  displayedColumns: string[] = [];
  lastUpdated: Date;
  IsInManualWaitTimeMode: Boolean;
  isRestaurantMode: boolean = false;
  isFormDirty: boolean = false;
  waitTimeIncreaseStep: number = 5;
  seatingAreasCopy: SeatingAreaDTO[] = [];
  subscriptions: Subscription = new Subscription();
  isInternalCall: boolean;
  timeSinceLastUpdated: string;


  constructor(private ts: TranslateService, private cs: CacheService, private ps: PartyService, private as: AppService) {
   this.waitTimesDataSource = this.list$;

  }

  ngOnInit(): void {
    this.settingSubscription();
    this.loadConfig();
    this.getWaitTimes();
  }
  ngAfterViewInit() {
    this.subscriptions.add(this.as.minuteTimer$.subscribe(val => {
      this.updateTimeSinceLastUpdated(this.lastUpdated, Utilities.getRestaurantDateTime(this.settings.General.DaylightDelta));
      if (!this.isFormDirty) {
        this.getWaitTimes();
      }
    }));
  }
  updateTimeSinceLastUpdated(lastUpdated: Date, clockNow: Date) {
    const timeSpan = clockNow.getTime() - lastUpdated.getTime();
    if (Math.abs(timeSpan / (1000 * 60 * 60)) >= 1) {
      const hours = Math.floor(Math.abs(timeSpan / (1000 * 60 * 60))) + this.ts.instant("hrsShortText");
      this.timeSinceLastUpdated = `${this.ts.instant('updated')} ${hours} ${this.ts.instant('ago')}`;
    }
    else {
      const minutes = Math.floor(Math.abs(timeSpan / (1000 * 60))) + this.ts.instant("minsShortText");
      this.timeSinceLastUpdated = `${this.ts.instant('updated')} ${minutes} ${this.ts.instant('ago')}`;
    }

  }
  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
  settingSubscription() {
    this.subscriptions.add(this.cs.settings.subscribe((settings: SettingsDTO) => {
      this.settings = settings;
      this.seatingAreas = this.settings.SeatingAreas.filter(area => area.Id != -1);
      if (this.seatingAreas.length > 0 && this.seatingAreasCopy.length > 0) {
        const deletedSA = this.seatingAreasCopy.filter(this.comparer(this.seatingAreas));
        const addedSA = this.seatingAreas.filter(this.comparer(this.seatingAreasCopy));
        this.reloadWaitTime(deletedSA, addedSA);
      }
      this.seatingAreasCopy = _.cloneDeep(this.seatingAreas);
    }));
  }
  reloadWaitTime(deletedSA: SeatingAreaDTO[], addedSA: SeatingAreaDTO[]) {
    if (this.waitTimesCopy && (deletedSA.length > 0 || (deletedSA.length == 0 && addedSA.length == 0))) {
      this.updateWaitTimes(this.waitTimesCopy);
      this.isFormDirty = false;
      this.waitTimesRowForRestaurant = this.getWaitTimesForRestaurantMode();
    }
    else if (addedSA.length > 0) {
      this.getWaitTimes();
    }
  }
  comparer(array: SeatingAreaDTO[]) {
    return function (current) {
      return array.filter(function (data) {
        return data.Id == current.Id
      }).length == 0;
    }
  }
  loadConfig() {
    this.seatingAreaBtn = {
      label: this.ts.instant('byseatingarea'),
      type: buttonTypes.actionPrimary,
    };
    this.restaurantBtn = {
      label: this.ts.instant('forrestaurant'),
      customclass: 'filterButton',
      type: buttonTypes.actionPrimary,
    };
    this.autoResetBtn = {
      label: this.ts.instant('autoreset'),
      customclass: 'filterButton',
      type: buttonTypes.actionPrimary,
    };
    this.saveChangesBtn = {
      label: this.ts.instant('applywaittime'),
      customclass: 'filterButton',
      type: buttonTypes.actionPrimary,
    };
    this.cancelChangesBtn = {
      label: this.ts.instant('cancel'),
      customclass: 'cancelButton',
      type: buttonTypes.actionPrimary,
    };
  }
  getWaitTimes(isSetManualWaitTime?: boolean) {
    this.subscriptions.add(this.ps.getWaitTimesReport().subscribe(data => {
      this.waitTimes = data.Payload as WaitTimesDTO;
      this.waitTimesCopy = _.cloneDeep(this.waitTimes)
      this.updateWaitTimes(this.waitTimes);
      this.isFormDirty = false;
      if (isSetManualWaitTime)
        this.waitTimesRowForRestaurant = this.getWaitTimesForRestaurantMode();
    }));
  }
  updateWaitTimes(waitTimes: WaitTimesDTO) {
    this.lastUpdated = Utilities.getRestaurantDateTime(this.settings.General.DaylightDelta);
    this.IsInManualWaitTimeMode = waitTimes.AreManuallyOverriden;
    var waitTimesByPartySize = _.groupBy(waitTimes.WaitTimes, function (arr) {
      return arr.PartySize;
    })
    var rowViewModels: WaitTimesRowView[] = [];
    var maxPartySize = environment.MaxPartySizeForManualWaitTimeOverrides;
    for (var partySize = 1; partySize <= maxPartySize; partySize++) {
      var waitTimesBySeatingArea = waitTimesByPartySize[partySize];
      waitTimesBySeatingArea = waitTimesBySeatingArea ? waitTimesBySeatingArea : [];
      var rowViewModel: WaitTimesRowView = {
        PartySize: partySize,
        WaitTimes: []
      };
      this.seatingAreas.forEach(seatingArea => {
        var waitTime = waitTimesBySeatingArea.filter(w => w.SeatingAreaId == seatingArea.Id)[0];
        var waitTimeInMinutes = waitTime ? (waitTime.WaitTimeInMinutes == null ? -1 : waitTime.WaitTimeInMinutes) : -1;
        var viewModel: WaitTimeView = {
          SeatingAreaId: seatingArea.Id,
          WaitTimeInMinutes: waitTimeInMinutes >= 0 ? waitTimeInMinutes : 0,
          OriginalWaitTime: waitTimeInMinutes >= 0 ? waitTimeInMinutes : 0,
          IsDirty: false,
          ShowOriginalWaitTime: false,
          IsNaN: waitTimeInMinutes < 0,
          WasModified: false,
          OriginalWaitTimeString: (waitTimeInMinutes != null && waitTimeInMinutes >= 0) ? waitTimeInMinutes.toString() : "-"
        };
        rowViewModel.WaitTimes.push(viewModel);
      })
      rowViewModels.push(rowViewModel);
      this.waitTimesRow = rowViewModels;

    }
    this.bySectionMode();

  }
  bySectionMode() {
    this.isRestaurantMode = false;
    this.list$ = new BehaviorSubject(this.waitTimesRow);
    var SA = this.seatingAreas.map(function (a) { return a.Name });
    this.updateColumns(SA, false);
    this.waitTimesDataSource = this.list$;
    this.columnGrouping(false);
    this.restaurantBtn.disbaledproperity = false;
    this.seatingAreaBtn.disbaledproperity = true;
  }
  restaurantMode(isInteralCall: boolean) {
    if (!isInteralCall || this.waitTimesRowForRestaurant.length == 0) {
      this.waitTimesRowForRestaurant = this.getWaitTimesForRestaurantMode();
    }
    if (!isInteralCall) {
      this.isRestaurantMode = true;
      this.list$ = new BehaviorSubject(this.waitTimesRowForRestaurant);
      this.waitTimesDataSource = this.list$;
      this.updateColumns([], true);
      this.columnGrouping(true);
      this.restaurantBtn.disbaledproperity = true;
      this.seatingAreaBtn.disbaledproperity = false;
    }
  }
  getWaitTimesForRestaurantMode(): WaitTimesRowView[] {
    var collection: WaitTimesRowView[] = [];
    this.waitTimesRow.forEach(waittimerow => {
      var waitTimeOfRestaurant: waitTimeForRestaurant = this.getWaitTimeForRestaurant(waittimerow);
      var rowViewModel: WaitTimesRowView = {
        PartySize: waittimerow.PartySize,
        WaitTimes: []
      };
      var viewModel: WaitTimeView = {
        SeatingAreaId: -1,
        WaitTimeInMinutes: waitTimeOfRestaurant.minWaitTime >= 0 ? waitTimeOfRestaurant.minWaitTime : 0,
        OriginalWaitTime: waitTimeOfRestaurant.minOriginalTime >= 0 ? waitTimeOfRestaurant.minOriginalTime : 0,
        IsDirty: false,
        ShowOriginalWaitTime: waitTimeOfRestaurant.showOriginalWaitTime,
        IsNaN: waitTimeOfRestaurant.minWaitTime < 0,
        WasModified: false,
        OriginalWaitTimeString: waitTimeOfRestaurant.showOriginalWaitTime ? waitTimeOfRestaurant.minWaitTime + " " + "(" + this.getString(waitTimeOfRestaurant.minOriginalTime) + ")" : (waitTimeOfRestaurant.minOriginalTime != null && waitTimeOfRestaurant.minOriginalTime >= 0) ? waitTimeOfRestaurant.minOriginalTime.toString() : "-"
      }
      rowViewModel.WaitTimes.push(viewModel);
      collection.push(rowViewModel);
    })
    return collection;
  }
  getWaitTimeForRestaurant(waitTimeRow: WaitTimesRowView): waitTimeForRestaurant {
    var _minWaitTime: number = Number.MAX_VALUE;
    var _minOriginalTime: number = Number.MAX_VALUE;
    var _showOriginalWaitTime: boolean = false;
    for (var i = 0; i < waitTimeRow.WaitTimes.length; i++) {
      var waitTimeView = waitTimeRow.WaitTimes[i];
      if (waitTimeView.IsDirty) {
        _showOriginalWaitTime = true;
      }
      if (!waitTimeView.IsNaN) {
        if (waitTimeView.WaitTimeInMinutes < _minWaitTime)
          _minWaitTime = waitTimeView.WaitTimeInMinutes;
        if (waitTimeView.OriginalWaitTime < _minOriginalTime)
          _minOriginalTime = waitTimeView.OriginalWaitTime;
      }
    }
    if (_minWaitTime == Number.MAX_VALUE)
      _minWaitTime = -1;
    if (_minOriginalTime == Number.MAX_VALUE)
      _minOriginalTime = -1;
    var model: waitTimeForRestaurant = {
      minWaitTime: _minWaitTime,
      minOriginalTime: _minOriginalTime,
      showOriginalWaitTime: _showOriginalWaitTime
    }
    return model;
  }
  columnGrouping(forRestaurant: boolean) {
    this.waitTimeFormGroup = [];
    this.controls = new UntypedFormArray([]);
    var objArray = (forRestaurant) ? this.waitTimesRowForRestaurant : this.waitTimesRow;
    objArray.forEach(data => {
      let formGroup = new UntypedFormGroup({});
      data.WaitTimes.forEach(wt => {
        var waittimevalue = wt.WaitTimeInMinutes >= 0 ? wt.WaitTimeInMinutes : 0;
        if (forRestaurant)
          formGroup.addControl(this.ts.instant('allseatingarea'), new UntypedFormControl(waittimevalue))
        else
          formGroup.addControl(this.seatingAreas.filter(_sa => _sa.Id == wt.SeatingAreaId)[0].Name, new UntypedFormControl(waittimevalue))
      }, { updateOn: "blur" })
      this.waitTimeFormGroup.push(formGroup);
    })
    this.controls = new UntypedFormArray(this.waitTimeFormGroup);
  }
  updateColumns(SA: string[], isRestaurantMode: boolean) {
    this.Columns = (!isRestaurantMode) ? SA : [this.ts.instant('allseatingarea')];
    this.displayedColumns = this.staticColumn.concat(this.Columns);
  }
  updateField(index, colIndex, internalValue?) {
    this.isInternalCall = internalValue ? true : false;
    var field = (this.isRestaurantMode) ? this.ts.instant('allseatingarea') : this.seatingAreas[colIndex].Name;
    const control = this.getControl(index, colIndex);
    const value = internalValue >= 0 ? internalValue : control.value;
    if (control.valid) {
      if (this.isRestaurantMode) {
        this.updateForRestaurant(index, field, value);
        this.seatingAreas.forEach(sa => {
          this.update(index, sa.Name, value);
        })
      }
      else {
        this.update(index, field, value);
        this.waitTimesRowForRestaurant = this.getWaitTimesForRestaurantMode();
      }
    }
  }
  update(index: any, field: any, value: any) {
    value = Number(value) > 0 ? Number(value) : 0;
    this.waitTimesRow = this.waitTimesRow.map((e, i) => {
      if (index === i) {
        return {
          ...e,
          ...this.waitTimesRow[index].WaitTimes.filter(data => data.SeatingAreaId == this.seatingAreas.filter(sa => sa.Name == field)[0].Id)[0].WaitTimeInMinutes = (value >= 0 ? value : 0),
        }
      }
      return e;
    });
    this.isValueChanges(this.waitTimesRow, index, field, true);
  }
  updateForRestaurant(index: any, field: any, value: any) {
    if (this.waitTimesRowForRestaurant.length == 0)
      this.restaurantMode(true);
    this.waitTimesRowForRestaurant = this.waitTimesRowForRestaurant.map((e, i) => {
      if (index === i) {

        return {
          ...e,
          ...this.waitTimesRowForRestaurant[index].WaitTimes.filter(data => data)[0].WaitTimeInMinutes = (value >= 0 ? value : 0),
        }
      }
      return e;
    });
    this.isValueChanges(this.waitTimesRowForRestaurant, index, field, false);
  }
  isValueChanges(objArray: WaitTimesRowView[], index: number, field: string, isbySectionMode: boolean) {

    objArray[index].WaitTimes.forEach(waittime => {
      if (isbySectionMode) {
        if (waittime.SeatingAreaId == this.seatingAreas.filter(sa => sa.Name == field)[0].Id)
          this.applyValueChanges(waittime);
      }
      else
        this.applyValueChanges(waittime);
    });
  }
  applyValueChanges(waittime: WaitTimeView) {
    if (waittime.WaitTimeInMinutes != waittime.OriginalWaitTime) {
      waittime.IsDirty = true;
      this.isFormDirty = true;
      waittime.IsNaN = false;
      waittime.ShowOriginalWaitTime = true;
      waittime.WasModified = true;
      this.updateTimeSinceLastUpdated(this.lastUpdated, Utilities.getRestaurantDateTime(this.settings.General.DaylightDelta));
    }
    if ((!this.isInternalCall && !(waittime.WaitTimeInMinutes == waittime.OriginalWaitTime)) || this.isInternalCall)
      waittime.OriginalWaitTimeString = waittime.WaitTimeInMinutes + " " + "(" + this.getString(waittime.OriginalWaitTime) + ")";
  }
  getString(OriginalWaitTime: number) {
    return (OriginalWaitTime != null && OriginalWaitTime > 0) ? OriginalWaitTime.toString() : "-"
  }
  getControl(index, colIndex) {
    const fieldName = this.getFieldName(colIndex);
    return this.controls.at(index).get(fieldName) as UntypedFormControl
  }
  saveChanges() {
    var waitTimes: WaitTimeDTO[] = [];
    this.waitTimesRow.forEach(data => {
      data.WaitTimes.forEach(waittime => {
        if (waittime.IsDirty) {
          waitTimes.push({ PartySize: data.PartySize, SeatingAreaId: waittime.SeatingAreaId, WaitTimeInMinutes: waittime.WaitTimeInMinutes });
        }
      })
    });
    this.subscriptions.add(this.ps.setManualWaitTime(waitTimes).subscribe(data => {
      this.subscriptions.add(this.ps.resetRestaurantCache().subscribe(data => {
      this.getWaitTimes(true);
      })) 
    }));
  }
  cancelChanges() {
    this.updateWaitTimes(this.waitTimesCopy);
    this.isFormDirty = false;
    this.waitTimesRowForRestaurant = this.getWaitTimesForRestaurantMode();
  }
  resetWaitTime() {
    this.subscriptions.add(this.ps.resetManualWaitTime().subscribe(data => {
      this.subscriptions.add(this.ps.resetRestaurantCache().subscribe(data => {
        this.getWaitTimes();
      }))
    }));   
  }

  increaseOrDecreaseWaitTime(element: WaitTimesRowView, index: any, colIndex: any, action: number) {
    if (action == -1) {
      var decreaseValue = element.WaitTimes[colIndex].WaitTimeInMinutes - this.waitTimeIncreaseStep;
      var value = decreaseValue >= 0 ? decreaseValue : 0;
    }
    else if (action == 1) {
      var value = element.WaitTimes[colIndex].WaitTimeInMinutes + this.waitTimeIncreaseStep;
    }
    this.updateField(index, colIndex, value);
    const fieldName = this.getFieldName(colIndex);
    this.controls.at(index).get(fieldName).setValue(value);
  }
  increaseOrDecreaseWaitTimeBySection(colIndex: any, action: number) {
    var index = -1;
    const objArray: WaitTimesRowView[] = this.isRestaurantMode ? this.waitTimesRowForRestaurant : this.waitTimesRow;
    objArray.forEach(data => {
      index++;
      this.increaseOrDecreaseWaitTime(data, index, colIndex, action);
    })
  }
  getFieldName(colIndex): string {
    var fieldName = ' ';
    if (this.isRestaurantMode)
      return fieldName = this.ts.instant('allseatingarea');
    else
      return fieldName = this.seatingAreas[colIndex].Name;
  }
}
