import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '@app/app.service';

@Pipe({
  name: 'rolesAndPermission'
})
export class RolesAndPermissionPipe implements PipeTransform {
  constructor(private appService: AppService) { }
  transform(permissionKey: string): any {
    if(this.appService.permissionList && this.appService.permissionList!==null && this.appService.permissionList.length > 0) { 
        let permission = this.appService.permissionList.filter(permissionValue => permissionValue.Key == permissionKey);
        return permission.length && permission[0]?.Value;
  }
  }
}

@Pipe({
  name: 'hasPermissionCustom'
})
export class HasPermisssionCustomPipe implements PipeTransform {
  constructor(private appService: AppService) { }
  transform(permissionList, permissionKey: string): any {
    if(permissionList && permissionList!==null && permissionList.length > 0) { 
        let permission = permissionList.filter(permissionValue => permissionValue.Key == permissionKey);
        return permission.length && permission[0]?.Value;
  }
  }
}