<div class="cdk-custom-wrapper theme-based" >
  <section class="ag_display--flex align-baseline">

    <label *ngIf="(_options?.showTotalRecords && !_options?.enablePagination) || prodId==PMSProduct" class="total-count-label text mr-2 ag_font--bold">
      {{totalMsg}} </label>
    <span *ngIf="enableExportExcel" [matTooltip]="'Export to xlsx'" class="icon-import ag_cursor--pointer mb-2"
      [ngClass]="{'pointerevents-none opacity-spa':dataSource.data && dataSource.data.length == 0}"
      (click)="exportAsXLSX()"></span>
    <span *ngIf="(_options?.CDK_showConfigure)" class="ag_ml--auto">
      <span class="ag_display--inblock cdk_configuration">
        <div class="ag_font--bold ag_link ag_cursor--pointer" [popover]="CDK_configurePopover" popoverPlacement="bottom"
          [popoverOnHover]="false" [popoverCloseOnMouseOutside]="false" [popoverCloseOnClickOutside]="true">
          <i aria-hidden="true" class="icon-Settings ag_mr--2"></i>
          <span [attr.LiteralID]="'lbl_configure'">{{captions.lbl_configure}}</span>
        </div>
      </span>
    </span>
  </section>
  <cdk-virtual-scroll-viewport class="ag_table"  [itemSize]="itemSize"
    [ngStyle]="{'height': ( _options?.maxRecordCount)? ((_tableContent && _tableContent.length > _options?.maxRecordCount)? (_options?.maxRecordCount * 48 + dynamicHeight - paginationHeight +'px') : (_tableContent && _tableContent.length * 48 + dynamicHeight - paginationHeight +'px')) : 'calc(100% - '+ totalrecordHeight +'px)'}"
    [ngClass]="{'overflow-hidden': (_tableContent && _tableContent.length < 10)&&(prodId!==5)}">
    <table cdkScrollable class="table table_boundary" aria-describedby="dkvirtual-table" mat-table [dataSource]="dataSource"
      [multiTemplateDataRows]="true" cdkDropList [cdkDropListData]="dataSource" [cdkDropListDisabled]="dragDisabled"
      (cdkDragMoved)='recordMove($event)' (cdkDropListDropped)="drop($event)"
      [ngClass]="{'firstColumnFreeze': _options?.columnFreeze?.firstColumn, 'lastColumnFreeze': _options?.columnFreeze?.lastColumn, 'ag_pointer--none':IsEditModeEnabled && !inlineEditTriggered, 'drag-started': onDrag}">
      <ng-container [matColumnDef]="item.key" *ngFor="let item of _headerOptions; trackBy:trackByFn">
        <th scope='col' mat-header-cell *matHeaderCellDef="let headerElement;let last=last"
          [ngClass]="[_options?.isSubheader?'subheader-enabled':'',item.alignment? item.alignment: '',  [item.key,item.sortingKey].indexOf(sortingColoumnKey) !=-1 && (ordertype==sorTypeenum.desc || ordertype==sorTypeenum.asc)? 'sorted': '']"
          [ngStyle]="{'cursor':(item.sorting)? 'pointer':''}" class="maxwidth{{last?'lastClass':''}} "
          [attr.LiteralID]="item.displayNameId" [title]="item.displayName"
          (click)="item.sorting && (!checkvaluechanged!='') && sorting(item.sortingKey ? item.sortingKey : item.key,ordertype)">
          <span class='hdr-content'>
            <ng-container [ngSwitch]="item.hdrtemplateName">
              <ng-container *ngSwitchCase="hdrActionTypeenum.hdrCheckbox">
                <ng-container *ngIf="_options?.isHeaderCheckboxAllowed">
                  <mat-checkbox (change)="(!checkvaluechanged!='') && emitcheckAlldata($event, item.key)"
                    [disabled]="dataSource.data && dataSource.data.length == 0 || item.isCheckboxDisabled"
                    [checked]="dataSource.data|isAllSelected:item.key:isModified" *ngIf="(!checkvaluechanged!='')">
                    <span [attr.LiteralID]="item.displayNameId">{{item.displayName}}</span>
                  </mat-checkbox>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="hdrActionTypeenum.hdrAdd">
                <span [attr.LiteralID]="item.displayNameId">{{item.displayName}}</span>
                <span class="ag_pl--2" (click)='headerActionClick(hdrActionTypeenum.hdrAdd, item)'>
                  <span class="icon-group-add">
                    <span class="path1"></span><span class="path2"></span><span class="path3"></span>
                  </span>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="hdrActionTypeenum.hdrSave">
                <span class="icon-save" (click)='headerActionClick(hdrActionTypeenum.hdrSave, item)'></span>
              </ng-container>
              <ng-container *ngSwitchCase="hdrActionTypeenum.hdrFilter">
                <span [attr.LiteralID]="item.displayNameId">{{item.displayName}}</span>
                <span class="icon-filter" (click)='headerActionClick(hdrActionTypeenum.hdrFilter, item)'></span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container #headerText *ngTemplateOutlet="headerTextTemplate; context: {data:item.displayName,subdata:item.displaySubName}">
                </ng-container>
              </ng-container>
            </ng-container>
            <span class="sort-wrapper"
              *ngIf="item.sorting && (!checkvaluechanged!='') && (dataSource && dataSource.data && dataSource.data.length > 0)">
              <span
                *ngIf="( ([item.key,item.sortingKey].indexOf(sortingColoumnKey)) !=-1) && ordertype==sorTypeenum.asc">
                <span class="icon-filled-up-arrow1"></span>
              </span>
              <span
                *ngIf="( !(([item.key,item.sortingKey].indexOf(sortingColoumnKey)) !=-1) && (ordertype==sorTypeenum.desc || ordertype==sorTypeenum.asc))">
                <span class="icon-sort-arrow"></span>
              </span>
              <span
                *ngIf="( ([item.key,item.sortingKey].indexOf(sortingColoumnKey)) !=-1) && ordertype==sorTypeenum.desc">
                <span class="icon-filled-down-arrow"></span>
              </span>
            </span>
            <span *ngIf="item?.headerInfo" class="ag_ml--2 icon-information" [matTooltip]="item?.headerInfo"></span>


            <div *ngIf="item.subheaderName">
              {{item.subheaderName}}
            </div>
            &nbsp;
            <!--This is to avoid misalignment issue. Please dont remove-->
          </span>
        </th>
        <td mat-cell *matCellDef="let element; let idx = dataIndex" (click)="selectRow(element,item.templateName, idx, $event)"
          [ngClass]="[item.alignment? item.alignment : '' , clickedRowData === element[uniqueKey] && _options.isRowClick ? 'highlighted-row': '']">
          <div [ngSwitch]="item.templateName" [class]="item.templateName">

            <ng-container *ngSwitchCase="actionTypeenum.checkbox">


              <mat-checkbox class="cust-checkbox" (change)="emitcheckdata($event,element, item.key)"
                [checked]="element[item.key]" [disabled]="item.isCheckboxDisabled || element['isCheckboxDisabled']">
              </mat-checkbox>
            </ng-container>
            <ng-container *ngSwitchCase="actionTypeenum.radioButton">
              <mat-radio-group [name]="item.radioKey">
                <mat-radio-button class="cust-radioButton"
                  (change)="emitSelecteddata($event,element, item.radioKey, item.key)" [value]="element[item.radioKey]"
                  [checked]="element[item.key]" [disabled]="isViewMode || element[item.disabeRadiokeyName]">
                </mat-radio-button>
              </mat-radio-group>
            </ng-container>
            <ng-container *ngSwitchCase="actionTypeenum.link">
              <a class="ag_link"
                [ngClass]="{'ag_link--disabled': (element[item.key].isDisabled || element.isExternalEditDisabled || isViewMode)} "
                (click)="emitEditrowdata($event,element,item.key)">{{element[item.key]}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="actionTypeenum.icon">
              <span *ngFor='let icon of element[item.key]; trackBy:trackByFn' [ngClass]="icon">
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="actionTypeenum.toggle">
              <mat-slide-toggle disableRipple="true" [disabled]="element[item.key].isDisabled || isViewMode"
                (change)="toggleChange($event, element, item.key)" [checked]='element[item.key].value'
                [(ngModel)]='element[item.key].value'></mat-slide-toggle>
            </ng-container>
            <ng-container #menuBlock *ngSwitchCase="actionTypeenum.menu">
              <ng-container *ngTemplateOutlet="menu; context: {menuList:element.menuList,element:element}">
              </ng-container>
            </ng-container>
            <ng-container #actionBlock *ngSwitchCase="actionTypeenum.action">
              <ng-container
                *ngTemplateOutlet="actionLoop; context: { element:element, options: element.isEditable ? _options?.inlineActions: _options?.actions, idx: idx }">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="actionTypeenum.custom">
              <ng-container
                *ngTemplateOutlet="childTemplate; context: { element:element, key: item.key, idx: idx, data: _tableContent, maxDate: element[item.maxValue], minDate: element[item.minValue], headerOption : item}">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="actionTypeenum.numberInput">
              <ng-container
                *ngTemplateOutlet="this[actionTypeenum.numberInput]; context: {data: element,isDisabled: item.isDisabled, param: 'nonnegative,nodecimal', key: item.key, maxLength: item.maxValue}">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="actionTypeenum.decimalInput">
              <ng-container
                *ngTemplateOutlet="this[actionTypeenum.numberInput]; context: {data: element, param: 'onlynumber, decimal', key: item.key}">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="actionTypeenum.decimalInputMaxval">
              <ng-container
                *ngTemplateOutlet="this[actionTypeenum.decimalInputMaxval]; context: {data: element, param: 'preMaxdecimal', key: item.key, maxLength: item.maxValue}">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="actionTypeenum.currencyInput">
              <ng-container
                *ngTemplateOutlet="this[actionTypeenum.currencyInput]; context: {data: element,isDisabled: item.isDisabled, key: item.key, maxLength: item.maxValue, allowNegative: item.allowNegative}">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="actionTypeenum.dropdown">
              <select [(ngModel)]='element[item.key]' [disabled]="element.isDisabled? element.isDisabled: false" (change)='emitDropdownData($event, element, item.key)'>
                <option
                  *ngFor='let option of element[item.key + "Options"] ? element[item.key+"Options"] : item.inputs.selectOptions ; trackBy:trackByFn'
                  [value]='option.id'>
                  {{option.value}}</option>
              </select>
            </ng-container>

            <ng-container *ngSwitchCase="actionTypeenum.duration">
              <app-ag-duration [inputs]="item.duration" [duration]="element[item.key]"
                (durationEmit)="durationEmit($event,item.key,element)"></app-ag-duration>
            </ng-container>

            <ng-container *ngSwitchCase="actionTypeenum.alphaNumeric">
              <ng-container
                *ngTemplateOutlet="this[actionTypeenum.alphaNumeric]; context: {data: element,isDisabled: item.isDisabled, param: item.inputTypeDirective, key: item.key, maxLength: item.maxValue}">
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="actionTypeenum.datepicker">
              <ng-container
                *ngTemplateOutlet="this[actionTypeenum.datepicker]; context: {data: element, param: 'nonnegative,nodecimal', key: item.sortingKey, maxDate: element[item.maxValue], minDate: element[item.minValue]}">
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="actionTypeenum.columnWithNumber">
              <ng-container
                *ngTemplateOutlet="this[actionTypeenum.columnWithNumber]; context: {data: element, param: 'nonnegative,nodecimal', key: item.key, maxLength: item.maxValue}">
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <ng-container
                *ngTemplateOutlet="element.isEditable && item.isInlineEditable? this[item.inputType] :other; context: {data: element, key: item.key, align: item.alignment, sorting: item.sorting, maxLength:item.maxValue, param:item.inputTypeDirective, type: item.sortInputType}">
              </ng-container>
            </ng-container>
          </div>
        </td>
        <ng-container *ngIf="showFooter">
          <td mat-footer-cell *matFooterCellDef [ngClass]="[item.alignment? item.alignment : '']"><span
              [ngClass]="{'ag_mr--6': item?.alignment === aligment.right && item.sorting}">{{footer?footer[item.key]:''}}</span>
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr [style.height.px]="placeholderHeight" mat-row
        *matRowDef="let row; let index = index; columns: []; when: placeholderWhen"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let rowIdx = dataIndex" cdkDrag  (cdkDragStarted)="startDrag($event)"
        cdkDragBoundary='.table_boundary'
        [attr.data-test]="rowIdx" (click)="rowClick($event,row, rowIdx)"
        [cdkDragDisabled]="(this._options && this._options.isDragDisabled) || isDragInProgress" [cdkDragData]="row"
        [ngClass]="{'disabledrow': inlineEditTriggered&&!row.isEditable, 'hide_checkbox' : inlineEditTriggered&&row.isEditable, 'row_selected': (row[checkboxKey] || selectedRowId == row[uniqueKey]), 'highlight-row' : row.isHighlight}"></tr>
      <ng-container *ngIf="showFooter">
        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"
          [ngClass]="{'ag_display--none': (dataSource?.data?.length === 0)}"></tr>
      </ng-container>
    </table>
    <div *ngIf="(dataSource?.data?.length === 0) && _orgtableContent!=null &&  prodId !==5"
      class="ag_text--center ag_p--4 ag_height--100" #content id="no-data">
      <ng-container>
        <ng-content></ng-content>
        <span [attr.LiteralID]="'lbl_noDataFound'">{{ noDataLabel }}</span>
      </ng-container>
    </div>
    <div *ngIf="_orgtableContent==null  && prodId !==5"
    class="ag_text--center ag_p--4 ag_height--100" #content id="no-data">
    <ng-container *ngIf="prodId==6 || prodId==10 || prodId==5 || prodId==1 || prodId==3">
      <div class="no-data-img">
         <img src="./assets/images/SearchIllustration.png" alt="No Data">
         <div class="mt-2">{{ imageCaption }}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="prodId!==6 && prodId !==10"> <!-- temporary fix needs to add appropriate product images -->
      <ng-content></ng-content>
      <span [attr.LiteralID]="'lbl_noDataFound'">{{ noDataLabel }}</span>
    </ng-container>
    </div>

    <!--SNC Product cdk changes-->

    <div *ngIf="(dataSource?.data?.length === 0) && _orgtableContent!=null &&  prodId ==5"
      class="ag_text--center ag_p--4 ag_height--100" #content id="no-data">
      <ng-container>
        <ng-content></ng-content>
        <div class="no-data-img">
          <img src="./assets/images/SearchIllustration.png" alt="No Data">
        </div>
        <span [attr.LiteralID]="'lbl_noDataFound'">{{ captions.lbl_noDataFound }}</span>

      </ng-container>
    </div>


  </cdk-virtual-scroll-viewport>
  <mat-paginator [pageSizeOptions]="[5, 10, 20, 30, 50,100]" showFirstLastButtons (page)="pageChanged($event)" [pageSize]="pageSize"
  *ngIf="_options?.enablePagination"></mat-paginator>
  <app-ag-server-paginator *ngIf="_options?.enableServerPagination" [config]="serverPageConfig" [totalLength]="totalRecords" (serverPageEmit)="onServerPageEmit($event)"></app-ag-server-paginator>
</div>


<!----Template to print only the data-->
<ng-template #headerTextTemplate let-data="data" let-subdata="subdata">
  <span class='hdr-data ag_display--inblock'>{{data}}</span>
  <span  *ngIf="subdata" class='hdr-data ag_display--block'>{{subdata}}</span>
</ng-template>
<ng-template #other let-data="data" let-key="key" let-align="align" let-sorting="sorting" let-type="type">
  <span *ngIf="type!='currency'" matTooltipClass="tooltipClass" [ngClass]="{'ag_mr--6': align === aligment.right && sorting,'hightlightcell':checkKeyIsPresent(key,data?.highlightColumns)}" [matTooltip]="data[key]">{{data[key]}}</span>
  <span *ngIf="type=='currency'" matTooltipClass="tooltipClass" [ngClass]="{'ag_mr--6': align === aligment.right && sorting}" [matTooltip]="data[key]">{{data[key] | Currency : false}}</span>
</ng-template>

<!----Template to print only the data-->
<ng-template #numberInput let-data="data" let-isDisabled="isDisabled" let-directiveParam="param" let-key="key" let-maxlength='maxLength'
  let-isReadOnly='isReadOnly'>
  <input class='table-input alignRight'
    [ngClass]="{'disabled': data.isReadOnly || isDisabled, 'invalid-number':data[key] > maxlength }" [readOnly]='data.isReadOnly'
    [(ngModel)]="data[key]" [inputtype]='directiveParam' [maxlength]='maxlength'
    (blur)='emitTableData($event,data, key,editableFlag)' (keyup)='validateMaxValue($event, maxlength)'  (keydown.enter)="$event.preventDefault()">
</ng-template>
<ng-template #decimalInput let-data="data" let-key="key">
  <input class='table-input alignRight ' [(ngModel)]="data[key]" inputtype='onlynumber, decimal'
    (blur)='emitTableData($event,data, key)'  (keydown.enter)="$event.preventDefault()">
</ng-template>
<ng-template #decimalInputMaxval let-data="data" let-key="key" let-maxlength='maxLength'>
  <input class='table-input alignRight' [(ngModel)]="data[key]" inputtype='preMaxdecimal'
    [maxpredecimalValue]="maxlength" (blur)='emitTableData($event,data, key)'  (keydown.enter)="$event.preventDefault()">
</ng-template>
<ng-template #currencyInput let-data="data" let-directiveParam="param" let-key="key" let-isDisabled="isDisabled" let-maxlength='maxLength'
  let-isReadOnly='isReadOnly' let-allowNegative="allowNegative">
  <input class='table-input alignRight' [ngClass]="{'disabled': data.isReadOnly || isViewMode || isDisabled}"
    [readOnly]='data.isReadOnly' [(ngModel)]="data[key]" (blur)='emitTableData($event,data, key)' CurrencyFormatter
    [cAllowNegative]="allowNegative" [preDecimalmaxLength]="maxlength"  (keydown.enter)="$event.preventDefault()">
</ng-template>
<ng-template #alphaNumeric let-data="data" let-directiveParam="param" let-key="key" let-isDisabled="isDisabled" let-maxlength='maxLength'
  let-isReadOnly='isReadOnly'>
  <input class='table-input alignRight' [ngClass]="{'disabled': data.isReadOnly|| isViewMode || isDisabled}"
    [readOnly]='data.isReadOnly' [(ngModel)]="data[key]" [inputtype]='directiveParam' [maxlength]='maxlength'
    (blur)='emitTableData($event,data, key)'  (keydown.enter)="$event.preventDefault()">
</ng-template>
<ng-template #datepicker let-data="data" let-directiveParam="param" let-key="key" let-maxDate='maxDate'
  let-minDate='minDate' let-isReadOnly='isReadOnly'>
  <mat-form-field class="datepicker-container">
    <input matInput class="table-input alignCenter" dateRestricter [matDatepicker]="picker" [(ngModel)]="data[key]"
      [min]="minDate" [max]="maxDate" (dateChange)="emitTableData($event, data, key)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    
  </mat-form-field>
</ng-template>
<ng-template #columnWithNumber let-data="data" let-directiveParam="param" let-key="key" let-maxlength='maxLength'
  let-isReadOnly='isReadOnly'>
  <span>{{data[key][0]}}</span>
  <span *ngIf="data[key].length > 1" class="ag_link" [matTooltip]="data[key]" matTooltipClass="tooltipClass">
    {{ ' ' + '+' + (data[key].length - 1) }}
  </span>
</ng-template>

<!-- Actions Templates-->
<ng-template #comment let-x="rowValue" let-y='action' let-idx="idx">
  <button class="custom-focus-btn"> 
    <span class="ag_pr--2 ag_cursor--pointer icon-note "
      [ngClass]="[y.disabled || x.isRowDisabled?'ag_section--disable':'',x.taskNotes?'custom-note-icon':'']" (click)="emitCommentrowdata($event,x,y,idx)"
      [popover]="notesPopover" [popoverOnHover]="false" 
      [title]="y.tooltip?y.tooltip:captions.tooltip_comment"  (onShown)="filterPopover = true"></span>
  </button>
</ng-template>
<ng-template #done let-x="rowValue" let-y='action'>
  <button class="custom-focus-btn">
    <span class="ag_pr--2 ag_cursor--pointer icon-done" [ngClass]="{'ag_section--disable': y.disabled}"
      (click)="emitDonerowdata($event,x)" 
      [title]="captions.tooltip_done"></span>
  </button>
</ng-template>
<ng-template #cancel let-x="rowValue" let-y='action'>
  <button class="custom-focus-btn">
    <span class="ag_pr--2 ag_cursor--pointer icon-close" [ngClass]="{'ag_section--disable': y.disabled}"
      (click)="emitCancelrowdata($event,x)" 
      [title]="captions.tooltip_cancel"></span>
  </button>
</ng-template>
<ng-template #edit let-x="rowValue" let-y='action'>
    <button class="custom-focus-btn custom-left-padding ag_pr--2 ag_cursor--pointer icon-edit1"
      [ngClass]="{'ag_section--disable': y.disabled || x.isRowDisabled}" (click)="emitEditrowdata($event,x)"
       [title]="captions.tooltip_edit"></button>
</ng-template>
<ng-template #copy let-x="rowValue" let-y='action'>
    <button class="custom-focus-btn ag_pr--2 ag_cursor--pointer icon-copy"
      [ngClass]="{'ag_section--disable': y.disabled || x.isRowDisabled}" (click)="emitCopyrowdata($event,x)"
       [title]="captions.tooltip_copy"></button>
</ng-template>
<ng-template #replace let-x="rowValue" let-y='action'>
    <button class="custom-focus-btn ag_pr--2 ag_cursor--pointer icon-replace "
      [ngClass]="{'ag_section--disable': y.disabled || x.isRowDisabled}" (click)="emitReplacerowdata($event,x)"
       [title]="captions.tooltip_replace"></button>
</ng-template>
<ng-template #delete let-x="rowValue" let-y='action'>
    <button class="custom-focus-btn ag_pr--2 ag_cursor--pointer icon-delete"
      [ngClass]="{'ag_section--disable':  y.disabled || x.isRowDisabled || x.isDeleteDisabled}" (click)="emitDeleterowdata($event,x)"
       [title]="captions.tooltip_delete"></button>
</ng-template>
<ng-template #drag let-x="rowValue" let-y='action'>
  <span class="ag_pr--2 ag_cursor--pointer icon-drag-drop"
    [ngClass]="{'ag_section--disable':  y.disabled || x.isRowDisabled || isDragInProgress}"
     cdkDragHandle [title]="captions.tooltip_drag"
    (mousedown)="dragDisabled = false;"></span>
</ng-template>
<ng-template #userBlock let-x="rowValue" let-y='action'>
    <button class="custom-focus-btn ag_pr--2 ag_cursor--pointer"
      [ngClass]="{'restricted': x.isRestrictuserBlock , 'icon-active-user' : !x.blockedUser,  'icon-block-user' : x.blockedUser, 'ag_section--disable': y.disabled || x.isRowDisabled}"
      (click)="emitBlockrowdata($event,x)" 
      [title]="x.blockedUser ? this.captions.tooltip_unblock : this.captions.tooltip_block"></button>
</ng-template>
<ng-template #preview let-x="rowValue" let-y='action'>
    <button class="custom-focus-btn ag_cursor--pointer icon-preview" [ngClass]="{'ag_section--disable': y.disabled || x.isRowDisabled}"
       [title]="captions.tooltip_preview"
      (click)="emitPreviewrowdata($event,x)"></button>
</ng-template>
<ng-template #history let-x="rowValue" let-y='action'>
  
  <button class="custom-focus-btn ag_cursor--pointer ag_pr--2 icon-history"
      [ngClass]="{'ag_section--disable': y.disabled || x.isRowDisabled}" 
      [title]="captions.tooltip_history" (click)="emitHistoryrowdata($event,x)">
  </button>
</ng-template>
<ng-template #sync let-x="rowValue" let-y='action'>
    <button class="custom-focus-btn ag_cursor--pointer ag_pr--2 icon-sync"
      [ngClass]="{'ag_section--disable': y.disabled || x.isRowDisabled}" 
      [title]="captions.btn_sync" (click)="emitSyncrowdata($event,x)"></button>
</ng-template>
<ng-template #customAction let-x="rowValue" let-y='action'>
    <button class="custom-focus-btn ag_cursor--pointer ag_pr--2"
      [ngClass]="[(y.disabled || x.isRowDisabled)? 'ag_section--disable': '', y.customClass, x.isHidden ? 'ag_display--none' : '']"
       [title]="y.tooltip"
      (click)="emitCustomActionrowdata($event,x, y.customClass)"></button>
</ng-template>
<ng-template #folioAction let-x="rowValue" let-y='action'>
    <button class="custom-focus-btn ag_cursor--pointer ag_pr--2 icon-accounting"
      [ngClass]="[(y.disabled || x.isRowDisabled)? 'ag_section--disable': '', y.customClass]"
       [title]="y.tooltip ? y.tooltip:captions.tooltip_folioAction"
      (click)="emitFolioActionrowdata($event,x)"></button>
</ng-template>
<ng-template #download let-x="rowValue" let-y='action'>
  <button class="custom-focus-btn ag_pr--2 ag_cursor--pointer icon-Download---Grid"
    [ngClass]="[(y.disabled || x.isRowDisabled)? 'ag_section--disable': '', y.customClass]" (click)="emitDownloadrowdata($event,x)"
     [title]="captions.tooltip_download"></button>
</ng-template>
<ng-template #denialAction let-x="rowValue" let-y='action'>
  <button class="custom-focus-btn ag_pr--2 ag_cursor--pointer icon-Convert-to-Denied_Lost"
    [ngClass]="[(y.disabled || x.isRowDisabled || x?.isDenyLostDisabled )? 'ag_section--disable': '']" (click)="emitDenialActionrowdata($event,x)"
    [title]="captions.lbl_convertToDeniedLost"></button>
</ng-template>
<ng-template #reservationAction let-x="rowValue" let-y='action'>
  <button class="custom-focus-btn ag_pr--2 ag_cursor--pointer icon-Convert-To-Reservation"
    [ngClass]="[(y.disabled || x.isRowDisabled || x?.isReservationDisabled)? 'ag_section--disable': '']" (click)="emitResrvationActionrowdata($event,x)"
    [title]="captions.lbl_convertToReervation"></button>
</ng-template>
<!--Inline action template-->

<ng-template #actionLoop let-options="options" let-element="element" let-idx="idx">
  <ng-container *ngFor="let tabOpt of options; trackBy:trackByFn" [ngTemplateOutlet]="this[tabOpt.type]"
    [ngTemplateOutletContext]="{rowValue:element, action: tabOpt, idx: idx, menuList: element.menuList, element: element}">
  </ng-container>
</ng-template>

<!----Template For Options Menu-->
<ng-template #menu let-menuList="menuList" let-element="element">
    <button class="custom-focus-btn ag_pr--1 icon-more ag_cursor--pointer" [matMenuTriggerFor]="menuOptions"
      [ngClass]="{'ag_section--disable':  element.isRowDisabled || isViewMode}"
      [matMenuTriggerData]="{'menuList':menuList,'element':element}"></button>
</ng-template>

<mat-menu #menuOptions="matMenu">
  <ng-template let-menuList="menuList" let-element="element" matMenuContent>
    <button *ngFor="let x of menuList; trackBy:trackByFn" mat-menu-item [disabled]="x.isDisabled || isViewMode"
      (click)="menuOptionsClick($event,x,element)">{{x.menu}}</button>
  </ng-template>
</mat-menu>

<!--added for tab view alignmnet issue-->
<popover-content #notesPopover placement="bottom" class="notes-popover notes-popover-cdktable w-100 popover-content"
  [closeOnClickOutside]="true" [closeOnMouseOutside]="false">
  <ng-container *ngIf="_options?.commentKey">
    <form class="note-popover-body" #notesForm="ngForm" [formGroup]="commentsForm">
      <div class="note-popover-content">
        <mat-form-field [floatLabel]="floatLabel" class="txt-area-height ag_w--100">
          <textarea matInput rows="3"  name="notes" formControlName="comment" tabindex="-1"
            maxlength="500" #notes></textarea>
        </mat-form-field>
      </div>
      <div class="ag_footer--actions notes-footer">
        <app-button [buttontype]="actionButton" (valueChange)='notesPopover.hide();saveNote()'></app-button>
        <app-button [buttontype]="cancelButton" class="ag_ml--2" (valueChange)='notesPopover.hide();cancelComment()'>
        </app-button>
      </div>
    </form>
  </ng-container>
</popover-content>


<popover-content #CDK_configurePopover class="cdk_configure-popover popover-content cdkVirtTbl" title="" placement="auto"
  [animation]="true" *ngIf="(_options?.CDK_showConfigure)" [closeOnClickOutside]="true" container='body'>
  <ng-container cdkDropListGroup>
    <div class="configureCheckbox-wrapper">
      <div cdkDropList [cdkDropListData]="_configureHeaderOptions" (cdkDropListDropped)="onDrop($event)">
        <div *ngFor="let headerOption of _configureHeaderOptions;let i = index" class="pb-2">
          <div class="configureCheckbox-div ag_display--flex" *ngIf="headerOption.show" cdkDrag
            [cdkDragData]="headerOption" (cdkDragStarted)="onCdkDragStarted($event)"
            (cdkDragEnded)="onCdkDragEnded($event)">

            <mat-checkbox class="configureCheckbox pr-3" [ngClass]="{'ag_section--disable':headerOption.disabled}"
              (change)="configureOptionClick(_configureHeaderOptions, headerOption, i)"
              [disabled]="headerOption.disabled" [checked]="headerOption.checked">
              <span *cdkDragPreview>{{headerOption.displayName}}</span>
              <span [matTooltip]="headerOption.displayName">{{headerOption.displayName}}</span>
            </mat-checkbox>
            <span class="drag-span ag_ml--auto ag_cursor--pointer fntSz" cdkDragHandle>
              <i aria-hidden="true" class="icon-Drag"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <app-button [buttontype]="cdk_configureButtonType" (valueChange)='CDK_configurePopover.hide();onApplyClick()'>
  </app-button>
</popover-content>