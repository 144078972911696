import { LoginResultDTO } from '@app/shared/models/LoginResultDTO';
import { GuestBookService } from '@app/shared/services/guestbook.service';
import { RestaurantStateService } from '@app/shared/services/restaurant-state.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { ChangeAction, Menu, PartyType } from '@constants/commonenums';
import { ObjectChange, PropertyChange } from '@models/ChangeTrackingOperationResultDTO';
import { ContactDTO, CustomFieldsDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import _ from 'lodash';
import { CacheService } from '../services/cache.service';
import { Processor } from './processor';

export class contactcustomfieldsChangeProcessor implements Processor {

    private static instance: contactcustomfieldsChangeProcessor;
    private constructor(public partyService: PartyService, public stateService: RestaurantStateService, public guestBookService: GuestBookService,
        public cs: CacheService) {
    }

    public static Instance(ps: PartyService, rss: RestaurantStateService, gbs: GuestBookService, cs: CacheService): contactcustomfieldsChangeProcessor {
        if (!contactcustomfieldsChangeProcessor.instance) {
            contactcustomfieldsChangeProcessor.instance = new contactcustomfieldsChangeProcessor(ps, rss, gbs, cs);
        }

        return contactcustomfieldsChangeProcessor.instance;
    }

    Process(objectChange: ObjectChange, additionaldata: any, propertyId: number): void {
        let partiesList = this.partyService.partiesList;
        let standbyPartiesList = this.partyService.standbyPartiesList;
        if (propertyId !== Utilities.RestaurantId()) {
            partiesList = this.cs.propertySettings.value[propertyId].reservations
            standbyPartiesList = this.cs.propertySettings.value[propertyId].StandbyParties
        }
        partiesList = [...partiesList, ...standbyPartiesList];

        if (objectChange) {

            if (objectChange.Action === ChangeAction.Created) {
                const contactId = objectChange.PropertyChanges.find(property => property.PropertyName === 'ContactId').Value;
                if (partiesList && partiesList.length > 0) {
                    const parties = partiesList.filter((partyDetails) => {
                        if (partyDetails.Contact) return partyDetails.Contact.Id === contactId;
                    });
                    if (parties && parties.length > 0) {
                        const customFieldsDTO = new CustomFieldsDTO();
                        objectChange.PropertyChanges.forEach(property => {
                            const isObject = _.isObject(customFieldsDTO[property.PropertyName.replace('Internal', '')]);

                            if (Object.getOwnPropertyNames(customFieldsDTO).includes(property.PropertyName.replace('Internal', ''))) {
                                customFieldsDTO[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
                            }
                        });
                        parties.forEach(party => {
                            if (!party.Contact.ContactCustomFields) {
                                party.Contact.ContactCustomFields = [];
                            }
                            party.Contact.ContactCustomFields.push(customFieldsDTO)
                        });
                        // this.processContactChangeForPartiesInState(objectChange, contact);
                    }
                }
            }
            if (objectChange.Action === ChangeAction.Updated) {
                const updatedCGFId = objectChange.ObjectId;
                if (partiesList && partiesList.length > 0) {
                    const parties = partiesList.filter(party => {
                        if (party.Contact.ContactCustomFields) {
                            let ids = party.Contact.ContactCustomFields.map(field => field.Id);
                            return ids.includes(updatedCGFId)
                        } else return false;
                    });

                    if (parties && parties.length > 0) {
                        parties.forEach(party => {
                            const contactCustomFields: CustomFieldsDTO = party.Contact.ContactCustomFields.find(field => field.Id == updatedCGFId || field.CustomFieldId == updatedCGFId);
                            objectChange.PropertyChanges.forEach(property => {
                                if (Object.getOwnPropertyNames(contactCustomFields).includes(property.PropertyName.replace('Internal', ''))) {
                                    contactCustomFields[property.PropertyName.replace('Internal', '')] = property.Value;
                                }
                            });
                            party.Contact.ContactCustomFields[party.Contact.ContactCustomFields.indexOf(contactCustomFields)] = contactCustomFields;
                            partiesList[partiesList.indexOf(party)] = party;
                        });
                    }
                    if (propertyId == Utilities.RestaurantId()) {
                        let partyWhichContactChanged = parties && parties.length > 0 && this.partyService.selectedParty$.value && this.guestBookService._as.selectedMenuId != Menu.Tables ? parties.filter(p => p.Id == this.partyService.selectedParty$.value.Id)[0] : null;
                        if (partyWhichContactChanged) {
                            this.partyService.selectedParty$.value.Contact = { ...partyWhichContactChanged.Contact }
                            this.partyService.selectedParty$.next(this.partyService.selectedParty$.value);
                        }
                    }
                }
                let contact = this.partyService.contacts$.value;
                if (contact && contact.Id) {
                    const contactCustomFields: CustomFieldsDTO = contact.ContactCustomFields.find(addon => addon.Id == updatedCGFId);
                    // this.mapAdditionalProperties(additionaldata, contact);
                    if (contactCustomFields) {
                        objectChange.PropertyChanges.forEach(property => {
                            const isObject = _.isObject(contactCustomFields[property.PropertyName.replace('Internal', '')]);
                            //this.mapMissedProperties(property, contact);
                            if (Object.getOwnPropertyNames(contactCustomFields).includes(property.PropertyName.replace('Internal', ''))) {
                                contactCustomFields[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
                            }
                        });
                        contact.ContactCustomFields[contact.ContactCustomFields.indexOf(contactCustomFields)] = contactCustomFields;
                        this.partyService.contacts$.next(contact);
                        let contactIndex = this.partyService.contactList$?.value.findIndex(contact =>  contact.Id == this.partyService.contacts$?.value?.Id);
                        if(contactIndex >= 0){
                            let partyContacts = this.partyService.contactList$?.value;
                            partyContacts[contactIndex] = this.partyService.contacts$.value;
                            this.partyService.contactList$.next(partyContacts);
                        }
                    }
                }
                const _contact = this.partyService.contactList$.value.filter((contacts) => {
                    if (contacts) return contacts.ContactCustomFields?.find(x => x.Id == updatedCGFId || x?.CustomFieldId == updatedCGFId);
                });
                if (_contact && _contact.length > 0) {
                    const contactCustomFields = _contact[0].ContactCustomFields.find(field => field.Id == updatedCGFId || field?.CustomFieldId == updatedCGFId);
                    objectChange.PropertyChanges.forEach(property => {
                        const isObject = _.isObject(contactCustomFields[property.PropertyName.replace('Internal', '')]);
                        if (Object.getOwnPropertyNames(contactCustomFields).includes(property.PropertyName.replace('Internal', ''))) {
                            contactCustomFields[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
                        }
                    });
                    _contact[0].ContactCustomFields[_contact[0].ContactCustomFields.indexOf(contactCustomFields)] = contactCustomFields;
                    this.guestBookService.allAvailableContacts.next(this.partyService.contactList$.value);
                }
                // this.processContactChangeForPartiesInState(objectChange, additionaldata);
            }
            if (objectChange.Action === ChangeAction.Removed && partiesList.length > 0) {

                const removedCGFId = objectChange.ObjectId;
                if (partiesList && partiesList.length > 0) {

                    const parties = partiesList.filter(party => {
                        if (party.Contact.ContactCustomFields) {
                            let ids = party.Contact.ContactCustomFields.map(field => field.Id);
                            return ids.includes(removedCGFId)
                        } else return false;
                    });
                    if (parties && parties.length > 0) {
                        parties.forEach(party => {
                            party.Contact.ContactCustomFields = party.Contact.ContactCustomFields.filter(field => field.Id != removedCGFId || field?.CustomFieldId != removedCGFId);
                            partiesList[partiesList.indexOf(party)] = party;
                        });
                    }
                    if (propertyId == Utilities.RestaurantId()) {
                        let partyWhichContactChanged = parties && parties.length > 0 && this.partyService.selectedParty$.value && this.guestBookService._as.selectedMenuId != Menu.Tables ? parties.filter(p => p.Id == this.partyService.selectedParty$.value.Id)[0] : null;
                        if (partyWhichContactChanged) {
                            this.partyService.selectedParty$.value.Contact = { ...partyWhichContactChanged.Contact }
                            this.partyService.selectedParty$.next(this.partyService.selectedParty$.value);
                        }
                    }
                }
                let contact = this.partyService.contacts$.value;
                if (contact && contact.Id) {
                    contact.ContactCustomFields = contact.ContactCustomFields.filter(field => field.Id != removedCGFId || field.CustomFieldId != removedCGFId);

                    this.partyService.contacts$.next(contact);
                }
                let contactIndex = this.partyService.contactList$?.value.findIndex(contact =>  contact.Id == this.partyService.contacts$?.value?.Id);
                if(contactIndex >= 0){
                    let partyContacts = this.partyService.contactList$?.value;
                    partyContacts[contactIndex] = this.partyService.contacts$.value;
                    this.partyService.contactList$.next(partyContacts);
                }
                const _contact = this.partyService.contactList$.value.filter((contacts) => {

                    if (contacts) return contacts.ContactCustomFields.filter(x => x.Id === removedCGFId || x?.CustomFieldId === removedCGFId);
                });
                if (_contact && _contact.length > 0) {
                    _contact[0].ContactCustomFields = _contact[0].ContactCustomFields.filter(field => field.Id != removedCGFId || field?.CustomFieldId != removedCGFId);
                    this.guestBookService.allAvailableContacts.next(this.partyService.contactList$.value);
                }

            }
        }
        const partyList = _.uniqBy(_.cloneDeep(partiesList.filter(x => x.Type !== PartyType.StandBy)), 'Id');
        const standbyParties = _.uniqBy(_.cloneDeep(partiesList.filter(x => x.Type === PartyType.StandBy)), 'Id');
        if (propertyId == Utilities.RestaurantId()) {
            this.partyService.partiesList = partyList;
            this.partyService.standbyPartiesList = [...standbyParties];
            this.cs.propertySettings.value[propertyId].reservations = partyList;
            this.cs.propertySettings.value[propertyId].StandbyParties = [...standbyParties];
            this.partyService.StandbyParties$.next([...standbyParties]);
        } else {
            this.cs.propertySettings.value[propertyId].reservations = partyList;
            this.cs.propertySettings.value[propertyId].StandbyParties = [...standbyParties];
        }
    }
}
