import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { PayAgentService } from '../service/payagent.service';
import { Device, IDTech, GiftCardPMRequest, HttpResponseStatus, GiftcardIDTechHandle } from '../service/payment/payment-model';
import { CommonVariablesService } from '../service/common-variables.service';
import { RetailUtilities } from '../utilities/retail-utilities';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { MatDialogRef } from '@angular/material/dialog';
import { UserSessionConfiguration } from '../../retail.modals';
import { PaymentMethods, HandleResponse, HandleRequest, CardData, PaymentErrorCodes } from '../business/shared.modals';
import { RetailFeatureFlagInformationService } from '../service/retail.feature.flag.information.service';
import { RetailService } from '../../retail.service';
import { GiftCardBusiness, SearchScreen } from '../../sytem-config/gift-cards/gift-cards.business';
import { InternalGiftCardBusinessService } from '../business/Giftcard/Internal-Giftcard.business.service';
import { CaptureStatus } from '../business/Giftcard/IGiftcardActions';
import { GiftCardVendorType } from '../globalsContant';
import { NonPMAgentRequestHandler } from '../../payment/NonPMAgentRequestHandler';

@Component({
  selector: 'app-giftcard-swipe',
  templateUrl: './giftcard-swipe.component.html',
  styleUrls: ['./giftcard-swipe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GiftcardSwipeComponent implements OnInit {
  @Input() screenType: number;
  @Input() isThirdPartyGiftcard: boolean = false;
  @Input() isTopUp: boolean = false;
  @Input() userSessionConfiguration: UserSessionConfiguration;
  @Input() outletId = 0;
  @Input() isRefund: boolean = false;
  @Input() automationId : string = '';

  @Output() GiftCardInfo = new EventEmitter<any>();

  captions: any;
  availableDevices: Device[] = [];
  selectedDevice: Device = {
    name: '',
    handle: ''
  };
  EncryptedCardData: any;
  CurrentDevice: string;
  IDTechCardSwipePopupClosed: boolean;
  cardConnectDialog: MatDialogRef<any, any>;
  swipeTxt: string = "";
  currentTenderId: PaymentMethods;
  tempTenderId = 0;
  floatLabel: string;  

  get isOrderSummary() {
    return (this.screenType == SearchScreen.OrderSummary);
  }
  get currentOutletId() {
    return this.outletId > 0 ? this.outletId : this.defaultOutletId;
  }
  get defaultOutletId() {
    return (this.userSessionConfiguration && this.userSessionConfiguration.defaultOutletId > 0 ? this.userSessionConfiguration.defaultOutletId : 0);
  }
  get IsIssuance() {
    return (this.screenType == SearchScreen.IssuePopup || this.isRefund)
  }

  constructor(
    private _payAgentService: PayAgentService
    , private _shopService: CommonVariablesService
    , private _utils: RetailUtilities
    , private localization: RetailLocalization
    , private featureFlagInfo: RetailFeatureFlagInformationService
    , public _ams: RetailService
    , private giftcardbusiness: GiftCardBusiness
    , private internalGCBusiness: InternalGiftCardBusinessService
    , private _nonPMAgentHandler: NonPMAgentRequestHandler
  ) {
    this.captions = this.localization.captions.shop;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.swipeTxt = !this.isOrderSummary ? this.captions.GiftCard.swipe : "";
    this.GetDevices(false, true);
  }

  ngOnChanges(): void {
    this.currentTenderId = this.isThirdPartyGiftcard ? this.giftcardbusiness.ThirdPartyTenderByVendor : PaymentMethods.V1GiftCard;
    if (this.tempTenderId != 0 && this.tempTenderId != this.currentTenderId) {
      this.availableDevices = [];
      this.EncryptedCardData = "";
      this.GetDevices(false, false, true);
    }
    this.tempTenderId = this.currentTenderId;
  }

  ConnectDevice() {
    if (this.selectedDevice.name != '') {
      if (this.selectedDevice.name != GiftcardIDTechHandle.name) {
        if (this.isThirdPartyGiftcard) {
          if (this.featureFlagInfo.SkipPMAgent) {
            this.CaptureExternalGiftcard();
          } else {
            this.GetBalance(this.selectedDevice.handle);
          }
        } else {
          this.CaptureV1Giftcard();
        }
      } else {
        this.CaptureCardWithIDTechDevice();
      }
    }
  }

  CaptureCardWithIDTechDevice() {
    this.IDTechCardSwipePopupClosed = false;
    this.OpenDialog();
    setTimeout(() => {
      if (!this.EncryptedCardData && !this.IDTechCardSwipePopupClosed) {
        this.cardConnectDialog.close();
        this._utils.showError(this.localization.getError(10725));
      }
    }, 45000);
  }

  OpenDialog() {
    const popupMessage = this.captions.GiftCard.SwipeGiftCardMsg;
    const isIDTech: boolean = (this.selectedDevice.name == GiftcardIDTechHandle.name);
    const dataObj = { 'text': popupMessage, 'buttonname': isIDTech ? this.localization.captions.common.Close : '', 'headertext': '', 'isloaderenable': true, 'cardpayment': true, 'isHiddenFieldRequired': isIDTech };
    this.cardConnectDialog = this._utils.OpenCardSwipeDialog(dataObj, this.CloseDialog.bind(this));
  }

  CloseDialog(data: any): void {
    this.IDTechCardSwipePopupClosed = true;
    if (data && data.toLowerCase() != this.localization.captions.common.Close.toLowerCase() && this.selectedDevice.name == GiftcardIDTechHandle.name) {
      this.EncryptedCardData = data;
      if (this.isThirdPartyGiftcard) {
        if (this.featureFlagInfo.SkipPMAgent) {
          this.CaptureExternalGiftcard(true);
        } else {
          this.GetDevices(true);
        }        
      } else {
        this.CaptureV1Giftcard(true);
      }
    }
  }

  GetBalance(selectedHandle) {
    if (this.featureFlagInfo.ZoneOffset && selectedHandle) {
      this.OpenDialog();
      let RequestBody: GiftCardPMRequest = {
        handle: selectedHandle,
        Amount: {
          RequestAmount: "0.00"
        },
        InquirerInfo: {
          TenderId: String(this.currentTenderId),
          TerminalId: "0",
          OrderNumber: String(this._utils.GetRandomNumber(7)),
          Profitcenter: "0",
          ZoneOffset: this.featureFlagInfo.ZoneOffset
        }
      }
      let handles: any = this._payAgentService.GetBalance(RequestBody, this.currentOutletId);
      handles.then(async response => {
        this.cardConnectDialog.close();
        this._utils.ToggleLoader(true, this.captions.GiftCard.SearchLoaderMsgGiftCardBalanceFetch);
        const giftCardNumber = response.account.id;
        if (response && response.status.toLocaleLowerCase() === 'success') {
          await this.GetBalanceWithCardNumber(this.currentTenderId, giftCardNumber, this.ExtractTrackDetails(giftCardNumber, response.account.type));
        } else if (response.status.toLowerCase() == HttpResponseStatus.Failure && response.errorMessage.toLowerCase() == this.giftcardbusiness.cardStatusInactiveMsg.toLowerCase()) {
          await this.GetBalanceWithCardNumber(this.currentTenderId, giftCardNumber, this.ExtractTrackDetails(giftCardNumber, response.account.type));
        }
        else if (response.status.toLowerCase() == HttpResponseStatus.Failure && response.errorMessage.toLowerCase() == this.giftcardbusiness.pmAgentCardNotFoundMsg.toLowerCase()) {
          this._payAgentService.PaymentErrorPrompt(PaymentErrorCodes.CardNumberNotValid);
        }
        else {
          this.HandlePMAgentError('');
        }
      }).catch(err => {
        this.HandlePMAgentError(err);
      });
    }
  }

  ExtractTrackDetails(cardDetail: string, type: string): GiftCardData {
    let cardData: GiftCardData = {
      cardNumber: cardDetail,
      type: type
    };
    if (cardDetail) {
      try {
        const SEPARATOR = ",";
        const TRACK1_SEQ_INDICATOR = "Track1:"
        const TRACK2_SEQ_INDICATOR = "Track2:"
        const trackData = cardDetail.split(SEPARATOR);
        trackData.forEach(x => x = x.trim());
        if (cardDetail.includes(TRACK1_SEQ_INDICATOR) && cardDetail.includes(TRACK2_SEQ_INDICATOR) && trackData.length == 2) {
          const KEY_VAL_SEPARATOR = ":";
          const track1 = trackData[0].split(KEY_VAL_SEPARATOR)[1];
          const track2 = trackData[1].split(KEY_VAL_SEPARATOR)[1];
          cardData = {
            track1: track1,
            track2: track2,
            cardNumber: cardDetail,
            type: type
          }
        }
      } catch (error) {
        console.log("Error while extracting track details from card data", error)
      }
    }
    return cardData;
  }

  BuildCardData(otherGiftcardData) {
    let cardData: CardData = null;
    switch (this.giftcardbusiness.GiftcardVendorType) {
      // case GiftCardVendorType.Agilysys:
      //   cardData = {
      //     encryptedData: "",
      //     pin: 0 //1234 //Passing default PIN as 1234 to bypass rGuestPay Validation - Bug - 46060
      //   }
      //   break;
      case GiftCardVendorType.Shift4:
        cardData = {
          encryptedData: "",
          track1: otherGiftcardData?.track1,
          track2: otherGiftcardData?.track2
        }
        break;
      default: break;
    }
    return cardData;
  }

  async GetBalanceWithCardNumber(paymentMethod: PaymentMethods, GiftCardNumber, otherGiftcardData?) {
    this._utils.ToggleLoader(true, this.captions.SearchLoaderMsg);
    let RequestBody: HandleRequest = {
      tenderId: String(paymentMethod),
      inquiryInfo: {
        id: GiftCardNumber,
        type: otherGiftcardData?.type,
        cardData: this.isThirdPartyGiftcard ? this.BuildCardData(otherGiftcardData) : null
      }
    };

    let handles: any;
    if (this.featureFlagInfo.SkipPMAgent) {
      let request: GiftCardPMRequest = {
        handle: JSON.stringify({
          inquiryData: {
            id: GiftCardNumber,
            type: otherGiftcardData?.type,
            cardData: this.isThirdPartyGiftcard ? otherGiftcardData?.inquiryData?.cardData : null
          },
          id: GiftCardNumber
        }),
        Amount: {
          RequestAmount: "0.00"
        },
        InquirerInfo: {
          TenderId: String(paymentMethod),
          TerminalId: "0",
          OrderNumber: String(this._utils.GetRandomNumber(7)),
          Profitcenter: "0",
          ZoneOffset: this.featureFlagInfo.ZoneOffset
        }
      }
      handles = this._nonPMAgentHandler.GetBalance(request, this.currentOutletId);
    } else {
      handles = this._payAgentService.GetHandles(RequestBody, this.currentOutletId);
    }
    handles.then(async (response: HandleResponse) => {
      this.cardConnectDialog.close();
      this._utils.ToggleLoader(false);
      if (response && response.status.toLocaleLowerCase() === 'success') {
        response.tenderId = this.currentTenderId;
        response.isCardActive = true;
        response.isThirdParty = this.isThirdPartyGiftcard;
        response = this.ResetCardNumberIfShift4(response);
        this.GiftCardInfo.emit(response);
      } else if (!this.isTopUp && this.IsIssuance) {
        let handleRes: HandleResponse;
        if (!this.isThirdPartyGiftcard) {
          handleRes = {
            paymentHandle: null,
            isThirdParty: false,
            status: "",
            errorMessage: "",
            cardNumber: this.giftcardbusiness.IsShift4Giftcard ? "" : GiftCardNumber,
            isCardActive: false
          } as HandleResponse
        } else {
          handleRes = response;
          handleRes.tenderId = this.currentTenderId;
          handleRes.cardNumber = this.giftcardbusiness.IsShift4Giftcard ? "" : GiftCardNumber;
          handleRes.isCardActive = false;
          handleRes.isThirdParty = this.isThirdPartyGiftcard;
        }
        handleRes = this.ResetCardNumberIfShift4(handleRes);
        this.GiftCardInfo.emit(handleRes);
      }
      else {
        const errorMsg = !this.isThirdPartyGiftcard ? this.giftcardbusiness.GetV1GCErrorMsg(response.errorMessage) : response.errorMessage;
        this._utils.ShowError(this.localization.captions.common.Error, errorMsg);
      }
    }).catch(err => {
      this._utils.ToggleLoader(false);
      this.HandlePMAgentError(err);
    });
  }

  HandlePMAgentError(err) {
    this.cardConnectDialog.close();
    //Show Payment Error Prompt
    if (err.error && (err.error[0] != null && err.error[0].Code != null && err.error[0].Code != ""))
      this._payAgentService.PaymentErrorPrompt(err.error[0].Code);
    else
      return this._utils.showError(this.captions.PayAgentUnavailable);
  }

  async GetDevices(isFromIdTech: boolean = false, isOnLoadCall: boolean = false, isForcedLoad: boolean = false) {
    if (!this.isThirdPartyGiftcard) {
      this.GetDeviceListForV1Giftcard(isOnLoadCall, isForcedLoad);
      return;
    }
    if (this.availableDevices.length == 0 || isFromIdTech || isForcedLoad) {
      let RequestBody: HandleRequest;
      if (this.selectedDevice.name == GiftcardIDTechHandle.name && this.EncryptedCardData) {
        this._ams.loaderEnable.next(this.captions.GiftCard.SearchLoaderMsg);
        const tenderId = this.isThirdPartyGiftcard ? this.giftcardbusiness.ThirdPartyIdTechTenderByVendor : PaymentMethods.V1GiftCardIdTech;
        RequestBody = {
          tenderId: String(tenderId),
          inquiryInfo: {
            id: "",
            cardData: {
              encryptedData: JSON.stringify({
                deviceType: this.isThirdPartyGiftcard && this.giftcardbusiness.IsShift4Giftcard ? null : 'idtechkb',
                encryptedData: this.EncryptedCardData
              })
            }
          }
        }
      } else {
        RequestBody = {
          tenderId: String(this.currentTenderId)
        };
      }

      if ((this.userSessionConfiguration && (this.userSessionConfiguration.isIdtechSred || this.userSessionConfiguration.defaultPaymentDevice == IDTech.id)) && !this.availableDevices.includes(GiftcardIDTechHandle)) {
        this.availableDevices.push(GiftcardIDTechHandle);
      }

      const Handles: Promise<HandleResponse> = this._shopService.PaymentProcessor.GetHandles(RequestBody, this.currentOutletId);
      Handles.then(response => {
        setTimeout(() => {
          this._ams.loaderEnable.next('');
        }, 1000);
        if (response && response.status.toLocaleLowerCase() === 'success' && response.paymentHandle.length > 0) {
          if (isFromIdTech && this.selectedDevice.name == GiftcardIDTechHandle.name && this.EncryptedCardData) {            
            response.tenderId = this.isThirdPartyGiftcard ? this.giftcardbusiness.ThirdPartyIdTechTenderByVendor : PaymentMethods.V1GiftCardIdTech;
            response.isCardActive = true;
            response = this.ResetCardNumberIfShift4(response);
            this.GiftCardInfo.emit(response);
            return;
          } else {
            let availableIngenicoDevices = response.paymentHandle;
            availableIngenicoDevices.forEach(device => {
              if (this.availableDevices.filter(x => x.name == device.name).length == 0) {
                this.availableDevices.push(device);
              }
            });
          }
        } else if (!this.isTopUp && this.IsIssuance && this.isThirdPartyGiftcard &&
          response.status.toLowerCase() == HttpResponseStatus.Failure
          && (!this.giftcardbusiness.IsAgilysysGiftcard && (response.errorMessage.toLowerCase() == this.giftcardbusiness.cardStatusInactiveMsg.toLowerCase())
            || (this.giftcardbusiness.IsAgilysysGiftcard && this.giftcardbusiness.AgysGiftcard_CardNotRegistered.toLowerCase() == response.errorMessage.toLowerCase())
          )) {
          response.tenderId = this.isThirdPartyGiftcard ? this.giftcardbusiness.ThirdPartyIdTechTenderByVendor : PaymentMethods.V1GiftCardIdTech;
          response.cardNumber = response.paymentHandle[0] && response.paymentHandle[0].inquiryInfo && response.paymentHandle[0].inquiryInfo.id;
          response.isCardActive = false;
          response = this.ResetCardNumberIfShift4(response);
          this.GiftCardInfo.emit(response);
          return;
        } else {
          const noDeviceFoundMsg = "No devices/handles found";
          if (this.availableDevices.length == 0 || (response.errorMessage && response.errorMessage?.toLowerCase() != noDeviceFoundMsg.toLowerCase())) {
            this._utils.ShowErrorMessage(this.localization.captions.common.Error, response.errorMessage);
          }
          return;
        }
        this.SelectDefaultDevice();
      }).catch(err => {
        setTimeout(() => {
          this._ams.loaderEnable.next('');
        }, 1000);
        //Show Payment Error Prompt
        if (err.error && (err.error[0] != null && err.error[0].Code != null && err.error[0].Code != ""))
          this._payAgentService.PaymentErrorPrompt(err.error[0].Code);
        else {
          //return this._utils.showError(this.captions.PayAgentUnavailable);
        }
      });
    }
  }

  ResetCardNumberIfShift4(response: HandleResponse) {
    if (this.giftcardbusiness.IsShift4Giftcard && response && response.paymentHandle.length > 0) {
      response.cardNumber = "";
      response.paymentHandle[0].inquiryInfo.id = "";
      return response;
    } else
      return response;
  }

  GetDeviceListForV1Giftcard(isOnLoadCall = false, isForcedLoad = false) {
    if (this.availableDevices.length == 0 || isForcedLoad) {

      if ((this.userSessionConfiguration && (this.userSessionConfiguration.isIdtechSred || this.userSessionConfiguration.defaultPaymentDevice == IDTech.id)) && !this.availableDevices.includes(GiftcardIDTechHandle)) {
        this.availableDevices.push(GiftcardIDTechHandle);
      }

      let deviceListResponse = this.internalGCBusiness.GetDevices();
      deviceListResponse.then(deviceList => {
        if (deviceList && deviceList.length > 0) {
          let availableIngenicoDevices = deviceList;
          availableIngenicoDevices.forEach(device => {
            if (!this.availableDevices.some(x => x.name == device.name)) {
              this.availableDevices.push(device);
            }
          });
        }
        this.SelectDefaultDevice();
        if (!isOnLoadCall && this.availableDevices && this.availableDevices.length == 0) {
          this._utils.ShowError(this.localization.captions.common.Error, this.captions.GiftCard.NoDeviceForGCSwipe);
        }
      }).catch(err => {
        console.log(`Something went wrong while fetching device list err:${err}`);
        this.SelectDefaultDevice(); //Incase IDTech is default device, rGuest device unavailable is not a pbm
        if (!isOnLoadCall) {
          this._utils.showError(this.captions.PayAgentUnavailable);
        }
      });
    }
  }

  CaptureV1Giftcard(isIDTech = false) {
    if (this.selectedDevice && this.selectedDevice.deviceGuid || isIDTech) {
      if (!isIDTech) this.OpenDialog();
      let captureCardRes = this.internalGCBusiness.CaptureGiftCard(this.selectedDevice, isIDTech, this.EncryptedCardData, this.DeviceSwipeCallBack.bind(this));
      captureCardRes.then((response) => {
        if (response && response.status == CaptureStatus.Success) {
          this.GetBalanceWithCardNumber(this.currentTenderId, response.cardNumber);
        } else if (response && response.status == CaptureStatus.Failure) {
          this.cardConnectDialog.close();
          this._utils.showError(response.errorMsg);
        }
      }).catch((err) => {
        console.log(err);
        this._utils.showError(this.captions.PayAgentUnavailable);
      })
    }
  }

  CaptureExternalGiftcard(isIDTech = false){
    if (this.selectedDevice && this.selectedDevice.deviceGuid || isIDTech) {
      if (!isIDTech) this.OpenDialog();
      let captureCardRes = this._nonPMAgentHandler.CaptureGiftCard(this.selectedDevice.deviceGuid, this.currentTenderId, this.currentOutletId, isIDTech, this.giftcardbusiness.IsShift4Giftcard, this.EncryptedCardData, this.DeviceSwipeCallBack.bind(this));
      captureCardRes.then((response) => {
        if (response && response.status == CaptureStatus.Success) {
          let RequestBody: GiftCardPMRequest = {
            handle: JSON.stringify(response),
            Amount: {
              RequestAmount: "0.00"
            },
            InquirerInfo: {
              TenderId: isIDTech ? String(this.giftcardbusiness.ThirdPartyIdTechTenderByVendor) : String(this.currentTenderId),
              TerminalId: "0",
              OrderNumber: "",
              Profitcenter: "0",
              ZoneOffset: this.featureFlagInfo.ZoneOffset
            }
          }
          this._utils.ToggleLoader(true, this.captions.SearchLoaderMsg);
          let handles = this._nonPMAgentHandler.GetBalance(RequestBody, this.currentOutletId);
          handles.then((response: HandleResponse) => {
            this.cardConnectDialog.close();
            this._utils.ToggleLoader(false);
            if (response && response.status.toLocaleLowerCase() === 'success') {        
              response.tenderId = this.currentTenderId;
              response.isCardActive = true;
              response.isThirdParty = this.isThirdPartyGiftcard;
              response = this.ResetCardNumberIfShift4(response);
              this.GiftCardInfo.emit(response);
            } else if (!this.isTopUp && this.IsIssuance) {
              let handleRes: HandleResponse;
              if (!this.isThirdPartyGiftcard) {
                handleRes = {
                  paymentHandle: null,
                  isThirdParty: false,
                  status: "",
                  errorMessage: "",
                  cardNumber: this.giftcardbusiness.IsShift4Giftcard ? "" : response.cardNumber,
                  isCardActive: false
                } as HandleResponse
              } else {
                handleRes = response;
                handleRes.tenderId = this.currentTenderId;
                handleRes.cardNumber = this.giftcardbusiness.IsShift4Giftcard ? "" : response.cardNumber;
                handleRes.isCardActive = false;
                handleRes.isThirdParty = this.isThirdPartyGiftcard;
              }
              handleRes = this.ResetCardNumberIfShift4(handleRes);
              this.GiftCardInfo.emit(handleRes);
            }
            else {
              const errorMsg = !this.isThirdPartyGiftcard ? this.giftcardbusiness.GetV1GCErrorMsg(response.errorMessage) : response.errorMessage;
              this._utils.ShowError(this.localization.captions.common.Error, errorMsg);
            }
          }).catch(err => {
            this.HandlePMAgentError(err);
          });
        } else if (response && response.status == CaptureStatus.Failure) {
          this.cardConnectDialog.close();
          this._utils.showError(response.errorMsg);
        }
      }).catch((err) => {
        console.log(err);
        this._utils.showError(this.captions.PayAgentUnavailable);
      })
    }
  }

  DeviceSwipeCallBack(captureCardRes) {
    if (captureCardRes && captureCardRes.status == CaptureStatus.Success && captureCardRes.cardNumber) {
      this.GetBalanceWithCardNumber(this.currentTenderId, captureCardRes.cardNumber, captureCardRes);
    } else if (captureCardRes && captureCardRes.status == CaptureStatus.Failure) {
      this.cardConnectDialog.close();
      this._utils.showError(captureCardRes.errorMsg);
    } else {
      this.cardConnectDialog.close();
    }
  }

  SelectDefaultDevice() {
    if (this.userSessionConfiguration && this.userSessionConfiguration.defaultPaymentDevice
      && this.userSessionConfiguration.defaultPaymentDevice.toLowerCase() == IDTech.id) {
      this.SelectDevice(GiftcardIDTechHandle);
    } else {
      let defaultDevice = this.availableDevices.find(x => x.name == this.userSessionConfiguration.defaultDeviceName);
      if (defaultDevice) {
        this.SelectDevice(defaultDevice);
      }
    }
  }

  SelectDevice(deviceObj: Device) {
    console.log(deviceObj);
    this.selectedDevice = deviceObj;
    this.CurrentDevice = deviceObj.name;
  }

}

export interface GiftCardData {
  cardNumber: string;
  type?: string;
  track1?: string;
  track2?: string;
}