import { Component, Inject, OnInit, Type } from '@angular/core';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { ComponentTypes, PartyType, ReservationType, SlottingMode } from '@app/shared/constants/commonenums';
import { TranslateService } from '@ngx-translate/core';
import { Utilities } from '@utilities/utilities';
import { PartyService } from '@services/party.service';
import { AppService } from '@app/app.service';
import { CacheService } from '@core/services/cache.service';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { cloneDeep, isEqual, sortBy, uniqBy } from 'lodash';
import { SpecialMealConfig } from './specialmeal-config';
import { ReservationBookingShare } from '@app/popup-module/components/services/reservation-booking-data';
import { CoverTypePriceDTO, CoverTypeQuantityDTO, LayoutDTO, TableSuggestionMode } from '@app/shared/models/RestaurantDTO';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { TableSelectionComponent } from '../table-selection/table-selection.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';

@Component({
  selector: 'app-specialmeal-booking-form',
  templateUrl: './specialmeal-booking-form.component.html',
  styleUrls: ['./specialmeal-booking-form.component.scss']
})
export class SpecialmealBookingFormComponent extends SpecialMealConfig implements OnInit {

  subscriptions: Subscription = new Subscription();
  showCoverTypes: boolean = false;
  reservationData: ReservationBookingShare;
  preSelectedTableIds = [];
  suggestedTableIds: number[] = [];
  layoutSettings: LayoutDTO = {} as LayoutDTO;
  enableTableSelection: boolean = false;
  showSuggestedTables: boolean = false;
  coverTypes: CoverTypePriceDTO[];
  coverTypeQuantity: CoverTypeQuantityDTO[] = [];
  initialSlotLoad: boolean = false;
  showWarning: boolean = false;
  warningMessage: string;
  showTableConfirmation: boolean = false;
  allowBookingWithoutTable: boolean = false;
  selectedSlotMessage: string;
  suggestedTableName: any[];
  skipTableValidation: boolean = false;
  slotAvailable: boolean = false;
  standbySlotTime: any;
  manualSlot: any
  moveTabledialogRef: MatDialogRef<any>;
  



  constructor(protected ts: TranslateService, protected appService: AppService, protected cs: CacheService, protected partyService: PartyService, protected dashboardFunctions: DashboardFunctions, protected ps: PopupService, @Inject(COMPONENTINPUT) protected data, @Inject(MAT_DIALOG_DATA) public dialogData, public dialog: MatDialog) {
    super(ts, cs, partyService, appService, dashboardFunctions, ps, dialog);
    this.ps.reservationData = new ReservationBookingShare();
    this.subscriptions.add(this.cs.layout.subscribe(layout => {
      this.layoutSettings = layout;
    }));
  }

  ngOnInit() {
    this.mealData = this.data;
    this.partyService.isStandbySlotSelected = this.mealData?.Type == PartyType.StandBy;
    this.settings = this.cs.settings.value;
    this.specialMeals = this.settings.SpecialMeals;
    this.restaurantDate = Utilities.getRestaurantDateTime(this.settings.General.DaylightDelta);
    this.partyService.selectedSpecialMealId = this.mealData?.SpecialMealId > 0 ? this.mealData.SpecialMealId : null;
    this.ps.reservationData.selectedMeal = this.partyService.selectedSpecialMealId ? this.specialMeals.find(meal => meal.Id == this.partyService.selectedSpecialMealId) : null;
    this.showSalesContact = this.settings.PropertySetting && this.settings.PropertySetting[0]?.TrackingofSalesPerson;
    this.partyService.bookingSize = this.mealData?.Size || 2; // Default value for party size is 2
    this.tableSuggestionMode = this.settings.General.TableSuggestionMode;
    this.coverTypeQuantity = this.mealData?.CoverTypeQuantities;
    this.coverTypes = (this.mealData && this.mealData?.SpecialMealId) ? this.ps.reservationData.selectedMeal.CoverTypePrices : this.settings.CoverTypes?.filter(cover => cover.IsRegularReservation) || [];
    // this.skipTableValidation = this.tableSuggestionMode == TableSuggestionMode.Never || this.settings.General.SlottingMode == SlottingMode.Manual;
    this.standbySlotTime = this.mealData?.Type == PartyType.StandBy ? this.mealData.WishedTime : null;
    this.formConfig();
    this.getSlots(this.reservationDate, this.data?.Id);
    this.getLegends();
    this.loadButtonConfig();
  }

  ngAfterViewInit() {
    this.formValueChanges();
    if(this.ps.reservationData.selectedMeal) {
      this.setSpecialMealDate();
    }
    this.ps.tabsActions$.next(this.ps.tabsActionData);
    this.addOnForStandby(this.mealData?.Type == PartyType.StandBy );
    if (this.showSalesContact) {
      this.salesContactUsers();
    }
    this.setBookingDetails();
    this.tableSelection();
    this.getSlotAvailability();
    this.setEditReservationData();
    this.ps.warningAction$.subscribe((data: boolean) => {
      if (data) {
        if (!this.checkSlotAvailability()) {
          this.showSlotAvailabilityWarningMsg();
        } else {
          // this.showLocationWarning(data);
        }
      }

    });
    this.subscriptions.add(this.partyService.tabChange$.subscribe(value => {
      if (value != 0)
        this.ps.previousButtonEnabled$.next(false);
      if (value == 0) {
        this.updateFormValidity();
        this.ps.previousButtonEnabled$.next(true);
      }
    }))
    this.subscriptions.add(this.partyService.partySlots$.subscribe((data) => {
      this.updateFormValidity();
    }));
  }
  setEditReservationData() {
    if (this.mealData?.HotelId) {
      this.setUpConciergeDetails();
    }
   let config = this.getCoverTypeConfig(this.coverTypes);
   if(this.mealData && this.mealData?.CoverTypeQuantities?.length == 0  && this.coverTypes?.length > 0){
    config[0].value = this.mealData.Size;
   }
   this.coverTypeConfig = config;

    this.showCoverTypes = this.coverTypeConfig?.length > 0;
    if (!this.mealData && this.coverTypeConfig?.length > 0) {
      this.coverTypeConfig[0].value = this.partyService.bookingSize;
    }
    if (this.mealData?.ReservedFor) {
      this.partyService.reservationFormGroup?.get('selectedDate')?.setValue(Utilities.formateDateString(this.data.ReservedFor));
    }else{
      this.partyService.reservationFormGroup?.get('selectedDate')?.setValue(this.dateForm.value.date);
    }
    if (this.mealData?.TableIds?.length) {
      this.ps.reservationData.reservationRequest.TableIds = this.mealData?.TableIds;
      let tableName = Utilities.getTableNamesFromStandaloneTables(this.ps.reservationData.reservationRequest.TableIds, this.layoutSettings.FloorPlans).toString();
      this.buttonTertiary.disbaledproperity = false;
      this.buttonTertiary.label = this.ts.instant('waitListPopUpSelectTable') + ` : ${tableName}`;
      this.partyService.reservationFormGroup.get('selectedTable').setValue(this.ps.reservationData.reservationRequest.TableIds);
      this.partyService.tableSelected.next(this.ps.reservationData.reservationRequest.TableIds);
    }
    this.updateFormValidity();
  }
  getSlotAvailability() {
    this.subscriptions.add(this.partyService.slotAvailabilitySubject$.subscribe(async (slots) => {
      if (!(this.partyService.reservationType === ReservationType.Waitlist && !this.mealData) && !slots.Payload) {
        let tempTableIds = [];
        tempTableIds = this.partyService.reservationFormGroup.get('selectedTable').value;
        this.partyService.reservationFormGroup.get('selectedTable').setValue(null);
        this.buttonTertiary.label = this.ts.instant('selectTable');
        this.suggestedTableIds = [];
        this.ps.reservationData.reservationRequest.TableIds = [];
        this.setTableSuggestionMode(slots);
        this.setTableSuggestionBtnAndText(tempTableIds);
        this.setBookingDetails();
        if(!this.partyService.reservationFormGroup.value.selectedTime?.DateTime){
          this.buttonTertiary.disbaledproperity = true;
        }
      }
      if (this.manualSlot) {
        let manualSlot = this.partyService.slots_holder.find(slot => slot.Id == this.manualSlot?.Id);
        this.updateSlotDetails(manualSlot);
      }
      if (this.dialogData?.customSlot && !this.initialSlotLoad) {
        let customSlot = this.partyService.slots_holder.find(slot => slot.Id == this.dialogData?.customSlot?.Id);
        this.updateSlotDetails(customSlot);
      }
      if (slots.Payload && this.mealData && !this.initialSlotLoad) {
        this.initialSlotLoad = true;
        if (this.mealData?.Type == PartyType.StandBy) {
          this.updateSlotDetails(this.partyService.slots_holder.find(slot => slot.DateTime == this.standbySlotTime || null))
        } else {
          this.updateSlotDetails(this.partyService.reservedSlots.find(item => item.PartyId && item.PartyId == this.mealData.Id) || null)
        }
      } else if (!this.mealData) {
        this.initialSlotLoad = true;
      }
    }));
  }
  updateSlotDetails(slot) {
    this.ps.reservationData.selectedSlot = slot;
    if (slot) {
      this.partyService.selectedSlot$.next([this.ps.reservationData.selectedSlot]);
      this.checkSlotAvailability()
      this.setBookingDetails();
    }
  }
  formValueChanges() {
    this.specialMealForm?.form.controls.SpecialMealId.valueChanges.subscribe(SpecialMealId => {
      if (SpecialMealId) {
        this.partyService.selectedSpecialMealId = SpecialMealId > 0 ? SpecialMealId : null;
        let selectedMeal = this.ps.reservationData.selectedMeal = SpecialMealId > 0 ? this.specialMeals.find(meal => meal.Id == SpecialMealId) : null;
        this.partyService.bookingSize = selectedMeal?.MinPartySize || 2;
        this.coverTypeQuantity = [];
        this.setSpecialMealDate();
        this.bookingForm.form.get('SeatingAreaId').setValue((selectedMeal?.SeatingAreaId || -1), {emitEvent: false});
        this.setPartySize(1, selectedMeal?.MaxPartySize || 51, selectedMeal ? false : true);
        this.coverTypes = selectedMeal ? selectedMeal.CoverTypePrices : (this.settings.CoverTypes?.filter(cover => cover.IsRegularReservation) || []);
        let coverConfig = this.getCoverTypeConfig(this.coverTypes);
        this.showCoverTypes = coverConfig?.length > 0;
        if (coverConfig?.length > 0) {
          coverConfig[0].value = this.partyService.bookingSize;
        }
        this.coverTypeConfig = coverConfig;
        this.partyService.addOrRemoveAddOn(selectedMeal ? selectedMeal.Id : null);
        this.ps.tabsActions$.next(this.ps.tabsActionData);
        this.getSlots(this.dateForm.value.date);
        this.setBookingDetails();
      }
    });
    this.dateForm.form.controls.date.valueChanges.pipe(debounceTime(100)).subscribe(data => {
      if (data) {
        this.ps.reservationData.selectedDate = data;
        this.partyService.selectedSlot$.next(null);
        this.ps.reservationData.selectedSlot = null;
        if (this.specialMeals?.length) {
          let specialMeals = this.getSpecialMealList();
          this.specialMealConfig[0].options = specialMeals;
          if (!specialMeals.find(meal => meal.id == this.ps.reservationData.reservationRequest.SpecialMealId && this.partyService.selectedSpecialMealId > 0) && this.specialMealForm) {
            this.specialMealForm?.form.get('SpecialMealId').setValue(-1, { emitEvent: false });
          }
        }
        this.partyService.reservationFormGroup.get('selectedDate').setValue(data);
        this.getSlots(data);
        this.setBookingDetails();
      }
    });
    this.bookingForm.form.controls.SeatingTypeId.valueChanges.subscribe(data => {
      if (data)
        this.getSeatingEstimation();
    });
    this.partySizeForm.form.valueChanges.pipe(debounceTime(100)).subscribe(data => {
      if (+data?.size > 0) {
        this.ps.reservationData.reservationRequest.Size = this.partyService.bookingSize = data.size;
        this.getSeatingEstimation();
        this.partyService.reservationFormGroup.get('selectedSize').setValue(data.size);
        this.partyService.setSlots(this.partyService.slotsinTimeRange, data.size, this.dateForm.value.date);
        if (this.partyService.bookingSize > this.ps.reservationData?.selectedSlot?.LimitedMaxPartySize) {
          this.ps.reservationData.selectedSlot = null;
          this.partyService.selectedSpecialMeal = null;
          this.partyService.selectedSpecialMealId = null;
          this.specialMealForm?.form.get('SpecialMealId').setValue(-1, { emitEvent: false });
          this.partyService.reservationFormGroup.get('selectedTime').reset();
        }
        this.setBookingDetails();
        if(!this.partyService.reservationFormGroup.value.selectedTime?.DateTime){
          this.buttonTertiary.disbaledproperity = true;
        }
        this.addOnForStandby()
      }
      else {
        this.updateFormValidity();
      }
    });
    this.bookingForm.form.valueChanges.subscribe(data => {
      if (data) {
        this.setBookingDetails();
      }
    });
    this.coverTypeForm.form.valueChanges.pipe(debounceTime(100)).subscribe(data => {
      if ((data && Object.keys(data).length > 0) && (Object.keys(data).length == this.coverTypes?.length)) {
        this.coverTypeQuantity = this.setCoverTypesValues(data);
        if (this.coverTypeQuantity?.length == this.coverTypes.length) {
          this.partyService.bookingSize = _.sumBy(this.coverTypeQuantity, 'Covers');
          this.ps.reservationData.reservationRequest.Size = this.partyService.bookingSize;
          if (!this.initialSlotLoad) { this.partySizeForm.form.get('size').setValue(this.partyService.bookingSize, { emitEvent: false }); }
          else {
            this.partySizeForm.form.get('size').setValue(this.partyService.bookingSize, { emitEvent: true });
          }

          this.updateFormValidity();
        }
      }
    });
  }

  setCoverTypesValues(data) {
    let coverTypeQuantity: CoverTypeQuantityDTO[] = [];
      this.coverTypes?.forEach(cover => {
        coverTypeQuantity.push({ Id: this.mealData?.CoverTypeQuantities?.length ? this.getCoverTypeQuantityId(cover.Id) : 0, CoverTypeId: cover.Id, CoverTypeName: cover.CoverTypeName ? cover.CoverTypeName : cover['Name'], Covers: data[cover.Id], Price: cover.Price });
      })
      return coverTypeQuantity;
  }

  getCoverTypeQuantityId(Id){
    return this.mealData.CoverTypeQuantities.find(cover => cover.CoverTypeId == Id)?.Id || 0;
  }

  setBookingDetails() {
    let specialMeal = this.specialMealForm?.form.getRawValue();
    let partySize = this.partySizeForm.form.getRawValue();
    let others = this.bookingForm.form.getRawValue();
    let request = this.ps.reservationData?.reservationRequest;
    this.coverTypeQuantity = this.coverTypeQuantity?.filter(cover => cover.Covers) || [];
    if (request) {
      request.SpecialMealId = this.mealData?.SpecialMealId ? this.mealData?.SpecialMealId : specialMeal && specialMeal.SpecialMealId > 0 ? specialMeal.SpecialMealId : null;
      request.Size = Number(partySize.size);
      request.SeatingAreaId = others.SeatingAreaId > 0 ? others.SeatingAreaId : null;
      request.SeatingTypeId = others.SeatingTypeId > 0 ? others.SeatingTypeId : null;
      request.PagerNumber = others.PagerNumber;
      request.StatusId = others.StatusId > 0 ? others.StatusId : null;
      request.SalesContactIds = others.SalesContactIds?.length > 0 ? others.SalesContactIds : null;
      request.HostId = others.HostId;
      request.HotelId = others.HotelId;
      request.ConciergeId = others.ConciergeId;
      request.Type = PartyType.Reservation;
      request.SeatingTime = null;
      request.Id = this.mealData ? this.mealData.Id : null;
      request.CoverTypes = this.coverTypeQuantity;
      this.updateFormValidity();
    }

    // Values set for common methods
    if (this.partyService.reservationFormGroup) {
      this.partyService.reservationFormGroup.get('selectedSize').value
      this.partyService.reservationFormGroup.get('selectedArea').setValue({ Id: request.SeatingAreaId }, { emitEvent: false });
    }
  }

  checkSlotAvailability() {
    this.slotAvailable = this.dashboardFunctions.checkSpecialMealApplicableForSelectedSlot(this.ps.reservationData.reservationRequest);
    return this.slotAvailable;
  }
  onSlotUpdate(inputData: any) {
    let data = inputData.slotData;
    if (inputData.isManualSlot) {
      this.manualSlot = inputData.slotData;
      this.partyService.setSlots(this.partyService.slotsinTimeRange , this.partyService.bookingSize ,  this.dateForm?.value?.date);
      return;
    } else {
      this.manualSlot = null;
    }
    if (this.cs.settings.value.General.SlottingMode == SlottingMode.Auto && !this.partyService.isOverBookSlotSelected) {
      this.partyService.isStandbySlotSelected = data.IsOverRide;
    }
    this.checkSlotAvailability();
    const restaurantDateTime = Utilities.Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    if ((data.LockedAt == null || (restaurantDateTime > Utilities.Date(data.LockExpiresAt))) && !data.IsDisabled && this.partySizeForm.value.size > 0) {
      let isNextEnabled = true;
      if (!this.partyService.isEditData && !data.IsOverRide && !this.partyService.isStandbySlotSelected) {
        if (this.partyService.previousSelectedSlotId) {
          const maxPastTime = this.cs.settings.value.General.MaxPastTimeForReservationsInMinutes;
          const currentTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
          const past1hr = new Date(currentTime.valueOf() - (maxPastTime * 60000));
          const previousSlots = this.partyService.slots_holder.find((slot) => slot.Id === this.partyService.previousSelectedSlotId);
          if (previousSlots) {
            const slotTime = Utilities.parseDateString(previousSlots.DateTime);
            previousSlots.IsDisabled = slotTime >= past1hr ? false : true;
          }
        }
        this.partyService.previousSelectedSlotId = data.Id;
      }
      // this.partyService.isOverBookSlotSelected = data.IsOverRide && !this.data ? true : false;
      if ((this.partyService.reservationType == ReservationType.StandbyParties || this.partyService.reservationType == ReservationType.PrivateLessonBooking) && !data.IsOverRide && !this.partyService.isStandbySlotSelected) {
        this.partyService.standbyConversion = true;
      }
    }
    if (this.partyService.isStandbySlotSelected) {
      this.standbySlotTime = data?.DateTime;
    } else {
      this.standbySlotTime = null;
    }

    if(this.partyService.reservationType == ReservationType.Reservation){
      this.ps.dialogPopupTittle = this.partyService.isOverBookSlotSelected ? 'createreservationoverbookTitle' : (this.data?.Id) ? 'editReservation' : 'createreservationbuttontext';
    }
    if ((this.partyService.reservationType == ReservationType.StandbyParties) && this.mealData &&this.partyService.standbyConversion ) {
      this.ps.dialogPopupTittle = 'convertStandbyToReservation';
    }

    this.partyService.selectedSlot$.next([data]);
    this.ps.reservationData.selectedSlot = data;
    this.getSeatingEstimation();
    this.setBookingDetails();
    this.addOnForStandby();

    if (this.partyService.reservationType == ReservationType.Reservation && this.partyService.isStandbySlotSelected) {
      this.ps.dialogPopupTittle = this.mealData  ? 'reservationToStandbyTitle' : 'newStandbyTitle';
      this.ps.tabsActionData[this.ps.tabsActionData.length - 1].actionButtonText = 'saveToStandbyList';
      this.ps.tabsActions$.next(this.ps.tabsActionData);
    }else{
      this.ps.tabsActionData[this.ps.tabsActionData.length - 1].actionButtonText = this.mealData ? 'update' : 'createreservationbuttontext';
    }
  }

  addOnForStandby(isStandBy?: boolean) {
    if (this.partyService.isStandbySlotSelected && !this.partyService.isOverBookSlotSelected && this.ps.reservationData?.selectedSlot?.LimitedMaxPartySize <= this.partyService.bookingSize) {
      this.partyService.addOrRemoveAddOn(  null, this.partyService.isStandbySlotSelected);
      this.ps.tabsActions$.next(this.ps.tabsActionData);
    }
    else {
      this.partyService.addOrRemoveAddOn(this.partyService.selectedSpecialMealId , isStandBy);
      this.ps.tabsActions$.next(this.ps.tabsActionData);
    }
  }

  updateFormValidity() {
    let isValid = true;
    if (!this.partySizeForm?.value?.size) {
      this.warningMessage = this.ts.instant('covertTypeValidationMessage');
      isValid = false
    } else if (this.partySizeForm?.value?.size) {
      let selectedMeal = this.ps.reservationData?.selectedMeal;
      if (selectedMeal && (this.partySizeForm.value?.size < selectedMeal.MinPartySize || this.partySizeForm.value?.size > selectedMeal.MaxPartySize)) {
        const minsize = selectedMeal.MinPartySize;
        const maxsize = selectedMeal.MaxPartySize;
        let warningMessage
        this.ts.get('MisMatchGroupSizeWithClass', { minsize, maxsize }).subscribe(msgVal => warningMessage = msgVal);
        this.warningMessage = warningMessage;
        isValid = false
      } else {
        this.warningMessage = null;
        isValid = true;
      }
    }
    if (!this.ps.reservationData.selectedSlot 
      // || ( this.partyService.bookingSize > this.ps.reservationData?.selectedSlot?.LimitedMaxPartySize && this.mealData?.Type !== PartyType.StandBy && !this.partyService.isStandbySlotSelected && !this.partyService.isOverBookSlotSelected)
      ) {
      isValid = false
    }
    this.showWarning = !isValid && this.warningMessage !== null;
    isValid = isValid && this.ps.reservationData.selectedSlot !== null;
    this.buttonActions(isValid ? true : false);
  }
  buttonActions(isValid) {
    if (this.ps.tabsActionData?.length) {
      this.ps.tabsActionData[0].gotoNextTab = isValid;
      this.ps.tabsActionData[0].nextValidations = this.slotAvailable;
      // this.ps.tabsActionData[0].nextValidations = (this.skipTableValidation || this.ps.reservationData.reservationRequest?.TableIds?.length > 0) && this.slotAvailable;
    }
    this.ps.tabsActions$.next(this.ps.tabsActionData);
  }
  getSlots(selectedDate?: Date, partyIdToIgnore?: number) {
    let selectedMeal = this.ps.reservationData?.selectedMeal;
    this.partyService.getTimeDetails(null, this.partyService.bookingSize, selectedDate, selectedMeal?.Id, partyIdToIgnore);
  }

  setSpecialMealDate() {
    let index = this.dateConfig.findIndex(config => config.name == 'date');
    let selectedMeal = this.ps.reservationData?.selectedMeal;
    if (selectedMeal?.StartDate) {
      let selectedDate = (new Date(selectedMeal.StartDate) >= new Date(this.appService.headerDate$.value)) ? selectedMeal.StartDate : this.appService.headerDate$.value;
      selectedDate = selectedDate > this.restaurantDate ? selectedDate : this.restaurantDate
      this.dateForm.form.get('date').setValue(selectedDate, { emitEvent: true });
      this.dateConfig[index].minDate = this.restaurantDate > new Date(selectedMeal.StartDate) ? this.restaurantDate : new Date(selectedMeal.StartDate);
      this.dateConfig[index].maxDate = new Date(selectedMeal.EndDate);
    } else {
      let selectedDate = new Date(this.appService.headerDate$.value) >= this.restaurantDate ? this.appService.headerDate$.value : this.restaurantDate;
      this.dateForm.form.get('date').setValue(selectedDate, { emitEvent: true });
      this.dateConfig[index].minDate = new Date(this.restaurantDate);
      this.dateConfig[index].maxDate = null;
    }
  }
  setPartySize(minSize: number, maxSize: number, allowAddionalParty: boolean) {
    this.calculatePartySize(minSize, maxSize, allowAddionalParty);
    let index = this.partySizeConfig.findIndex(config => config.name == 'size');
    this.partySizeConfig[index].options = this.partySizeArray;
    this.partySizeForm.form.get('size').setValue(minSize, { emitEvent: false });
  }

  getSeatingEstimation() {
    let partySize = this.ps.reservationData.reservationRequest.Size;
    if (this.ps.reservationData.reservationRequest.Size && this.ps.reservationData.selectedSlot && this.partySizeForm.valid) {
      const partyDetails = this.getPartyDetailsForSeatingEstimate()
      // For standby request
      partyDetails.ReservedFor = this.partyService.isStandbyData(this.data) ? this.data.WishedTime : partyDetails.ReservedFor;
      if (partySize > 0) {
        this.partyService.getAutoWaitTime(partySize, partyDetails);
        this.buttonTertiary.disbaledproperity = false;
      }
    }
  }

  getPartyDetailsForSeatingEstimate() {
    let partyDetails: any = {};
    if (this.mealData) {
      partyDetails = cloneDeep(this.mealData);
      partyDetails.TableIds = [];
    }
    let details = this.bookingForm.form.getRawValue();
    partyDetails.SeatingAreaId = details.SeatingAreaId || null;
    partyDetails.SeatingTypeId = details.SeatingTypeId || null;
    let selectedTimeSlot = this.partyService.reservationFormGroup.get('selectedTime').value || this.ps.reservationData?.selectedSlot;
    partyDetails.ReservedFor = selectedTimeSlot ? selectedTimeSlot.DateTime : partyDetails.ReservedFor || null;
    partyDetails.IsReservation = true;
    partyDetails.Size = this.partySizeForm.value?.size;
    partyDetails.eventId = this.ps.reservationData?.selectedMeal?.Id || null;
    return partyDetails;
  }
  showSlotAvailabilityWarningMsg() {
    let errorMsg = '';
    const selectedSpecialMealName = this.specialMeals.find(meal => meal.Id == this.ps.reservationData.reservationRequest.SpecialMealId)?.Name;
    this.ts.get('SpecialMealNotApplicableMsg', { selectedSpecialMealName }).subscribe(msgName => errorMsg = msgName);
    this.dashboardFunctions.showErrorPopUp(errorMsg);
  }
  selectedTable() {
    this.preSelectedTableIds = this.ps.reservationData?.reservationRequest?.TableIds;
    this.partyService.reservationFormGroup.controls.selectedTable.setValue(this.ps.reservationData?.reservationRequest?.TableIds || []);
    let cancelledActionSubscription: ISubscription = null;
    let confirmActionSubscription: ISubscription = null;
    const componentDetails: ComponentDetails = {
      componentName: TableSelectionComponent,
      dimensionType: 'large',
      popupType: 'active',

      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: null
    };
    this.moveTabledialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      panelClass: 'preferred-table-panel',
      data: {
        service: this.ps,
        title: 'Select Table',
        update: 'Ok',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.table,
        restrictCloseDialog: true,
        back: false,
        standalone: true,
        selectionView: true
      }
    });
    confirmActionSubscription = this.ps.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.table) {
        let tableWithSpecialEvent = this.partyService.getSpecialEventAvailability(this.ps.reservationData.reservationRequest.TableIds);
        if(tableWithSpecialEvent?.length){
          this.specialEventValidation(tableWithSpecialEvent);
        }else{
          this.setSelectedTableData(true);
        }
      }
      this.setBookingDetails();
    });
    cancelledActionSubscription = this.ps.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.table) {
       this.setTableAfterCancelAction();
      };
    });

    this.subscriptions.add(this.moveTabledialogRef.afterClosed().subscribe(event => {
      confirmActionSubscription?.unsubscribe();
      cancelledActionSubscription?.unsubscribe();
    }));
  }
  setTableAfterCancelAction(){
    this.ps.reservationData.reservationRequest.TableIds = this.preSelectedTableIds;
    this.partyService.reservationFormGroup.controls.selectedTable.setValue(this.ps.reservationData?.reservationRequest?.TableIds);
    if (this.ps.reservationData.reservationRequest?.TableIds?.length > 0) {
      let tableName = Utilities.getTableNamesFromStandaloneTables(this.reservationData?.reservationRequest?.TableIds, this.layoutSettings.FloorPlans).toString();
      this.buttonTertiary.label = this.ts.instant('waitListPopUpSelectTable') + ` : ${tableName}`;
    }
    this.setBookingDetails();
  }
  setSelectedTableData(IgnoreValidation:boolean = false){
    if (this.ps.reservationData.reservationRequest.TableIds?.length > 0 && IgnoreValidation) {
      this.showSuggestedTables = false;
      let tableName = Utilities.getTableNamesFromStandaloneTables(this.ps.reservationData.reservationRequest.TableIds, this.layoutSettings.FloorPlans).toString();
      this.buttonTertiary.label = this.ts.instant('waitListPopUpSelectTable') + ` : ${tableName}`;
      this.showTableConfirmation = false;
      this.moveTabledialogRef?.close();
    }
    else {
      this.buttonTertiary.label = this.ts.instant('selectTable');
      this.showSuggestedTables = (this.tableSuggestionMode == TableSuggestionMode.Suggest && this.suggestedTableIds?.length) ? true : false;
      this.moveTabledialogRef?.close();
    }
  }

  specialEventValidation(tableWithSpecialEvent) {
    let dialogData = this.partyService.specialEventValidation(tableWithSpecialEvent);
    dialogData.data['service'] = this.ps;
    let dialogRef = this.dialog.open(AppPopupComponent , dialogData);
      let confirmSubscription = this.ps.confirmedAction$.subscribe((data) => {
        if (data && data == ComponentTypes.specialEventSelection) {
          this.setSelectedTableData(true)
          this.moveTabledialogRef?.close();
        }
      });

      let cancelSubscription = this.ps.cancelledAction$.subscribe((data) => {
        if (data && data.from == ComponentTypes.specialEventSelection && cancelSubscription) {
          this.setTableAfterCancelAction();
          this.setSelectedTableData(false)
        }
      });

      dialogRef.afterClosed().subscribe(() => {
        confirmSubscription?.unsubscribe();
        cancelSubscription?.unsubscribe();
        if (this.ps.isRestrictCloseDialog) {
          this.ps.restrictCloseDialog = true;
        }
      });
    }


  tableSelection() {
    this.subscriptions.add(this.partyService.tableSelected.subscribe(val => {
      if (val)
        this.ps.reservationData.reservationRequest.TableIds = val;
    }));
  }
  async setTableSuggestionBtnAndText(selectedTable) {
    let tempTableNames = [];
    let selectedTableIds = this.ps.reservationData.reservationRequest.TableIds
    if (this.suggestedTableIds.length > 0)
      tempTableNames = Utilities.getTableNamesFromStandaloneTables(this.suggestedTableIds, this.layoutSettings.FloorPlans);

    switch (this.tableSuggestionMode) {
      case TableSuggestionMode.SuggestAndAssign:
        if (selectedTableIds.length === 0 || (this.suggestedTableIds.length > 0 && !(isEqual(sortBy(this.suggestedTableIds), sortBy(selectedTableIds))))) {
          selectedTableIds = selectedTable?.length ? selectedTable : this.suggestedTableIds;
          this.partyService.reservationFormGroup.get('selectedTable').setValue(this.suggestedTableIds);
          this.ps.reservationData.reservationRequest.TableIds = this.suggestedTableIds;
          if (this.suggestedTableIds?.length) {
            let tableName = Utilities.getTableNamesFromStandaloneTables(this.suggestedTableIds, this.layoutSettings.FloorPlans);
            this.buttonTertiary.label = this.suggestedTableIds?.length > 1 ? this.ts.instant('waitListPopUpSelectTable') + ` : ${tableName}` : this.ts.instant('selectedTable') + ` : ${tableName}`;
          } else {
            this.buttonTertiary.label = this.ts.instant('selectTable');
          }

        }
        else if (!this.suggestedTableIds || this.suggestedTableIds.length == 0) {
          selectedTableIds = [];
          this.buttonTertiary.label = this.ts.instant('selectTable');
        }
        this.showSuggestedTables = false;
        break;

      case TableSuggestionMode.Suggest:
        if (selectedTableIds.length == 0 && (selectedTableIds.toString() != this.suggestedTableIds.toString())
          || (this.suggestedTableIds.length > 0 && !(isEqual(sortBy(this.suggestedTableIds), sortBy(selectedTableIds))))) {
          this.suggestedTableName = this.ts.instant('suggestedTable') + tempTableNames;
          this.showSuggestedTables = true;
        } else {
          this.buttonTertiary.label = this.ts.instant('selectTable');
          this.showSuggestedTables = false;
        }
        selectedTableIds = [];
        break;

      case TableSuggestionMode.Never:
      case TableSuggestionMode.NeverAssign:
        selectedTableIds = [];
        this.showSuggestedTables = false;
        this.buttonTertiary.label = this.ts.instant('selectTable');
        break;
    }
  }
  async assignSuggestedTable() {
    this.showSuggestedTables = false;
    this.showTableConfirmation = false;
    this.ps.reservationData.reservationRequest.TableIds = this.suggestedTableIds;
    let tableName = Utilities.getTableNamesFromStandaloneTables(this.suggestedTableIds, this.layoutSettings.FloorPlans);
    this.buttonTertiary.label = this.suggestedTableIds?.length > 1 ? this.ts.instant('waitListPopUpSelectTable') + ` : ${tableName}` : this.ts.instant('selectedTable') + ` : ${tableName}`;
    this.partyService.reservationFormGroup.get('selectedTable').setValue(this.suggestedTableIds);
    this.ps.reservationData.reservationRequest.TableIds = this.suggestedTableIds;
    this.preSelectedTableIds = this.suggestedTableIds;
    this.updateFormValidity();
  }
  setTableSuggestionMode(slots) {
    if (slots && slots.StandaloneTableIds && this.tableSuggestionMode === TableSuggestionMode.SuggestAndAssign) {
      this.suggestedTableIds = slots.StandaloneTableIds;
      this.partyService.reservationFormGroup.get('selectedTable').setValue(slots.StandaloneTableIds);
    } else if (slots && !slots.StandaloneTableIds && this.tableSuggestionMode === TableSuggestionMode.SuggestAndAssign) {
      this.partyService.reservationFormGroup.get('selectedTable').setValue(null);
      this.partyService.reservationFormGroup.controls.selectedTable.setValue(null);
    } else if (slots && slots.StandaloneTableIds && this.tableSuggestionMode === TableSuggestionMode.Suggest) {
      this.suggestedTableIds = slots.StandaloneTableIds;
    } else if (slots && !slots.StandaloneTableIds && this.tableSuggestionMode === TableSuggestionMode.Suggest) {
      this.partyService.reservationFormGroup.get('selectedTable').setValue(null);
      this.partyService.reservationFormGroup.controls.selectedTable.setValue(null);
    }
  }
  showLocationWarning(data: boolean) {
    if (!this.skipTableValidation && this.tableSuggestionMode !== TableSuggestionMode.SuggestAndAssign) {
      this.showTableConfirmation = true;
      this.warningMessage = this.ts.instant('noTablesAssignedValidation')
    }
  }
  confirmAction(value: boolean) {
    this.showTableConfirmation = false;
    this.warningMessage = null;
    this.skipTableValidation = value;
    this.buttonActions(value);
    if (value) {
      this.ps.nextEvent$.next(true);
    }
  }
  closeWarning() {
    this.showWarning = false;
  }
  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
    this.partyService.selectedSlot$.next(null);
    this.partyService.isStandbySlotSelected = false;
    // this.partyService.reservationFormGroup.get('selectedTime').setValue(null);
    // this.partyService.slots_holder = [];
  }
}
