<div class="retailCustomDataTable" id="table_{{SelectedSettingId}}" (window:resize)="viewCheckedFlag = false">
  <form class="w-100 h-100" [formGroup]="table">
      <div class="fixed-table-container" id="fixed-table-container" [ngClass]="{'noSort': bodyArray.length < 1}">
        <div class="w-100 position-absolute grid-table" id="SPACustomTable">
          <ng-scrollbar  >
            <table aria-describedby="" class="table mb-0 tbl-cellspacing">
              <thead>
                <tr id="SPAHeaderRow">
                  <th  scope='col' class="pr-3 noellipsis">
                    <div class="th-inner">
                      <mat-checkbox [disabled]="isReadOnly || ((bodyArray).length <= 0)"
                        (change)="RowSelect($event,bodyArray,'All')" formControlName="IsCheckAll"></mat-checkbox>
                    </div>
                  </th>
                  <th scope='col'  *ngFor="let hdrCnt of hdrArray;let hi=index" id="header{{hi}}"  [ngClass]="[hdrCnt.alignType=='right' ? 'text-right' : hdrCnt.alignType=='center' ? 'text-center':'text-left',hdrCnt.sortable != undefined ? (hdrCnt.sortable ? '': 'pointerevents-none') : '', isEditModeSet?'cursor-default':'']"
                  [class.cursordefault]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? false: true) : false"
                  [class.cursor]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? true: false) : true"  (click)="sortingFunc(hdrCnt.jsonkey,hi,'Frmtable', hdrCnt.sortcolumn, hdrCnt.sortcolumndatatype)">
                    <div class="th-inner" [class.font-weight-bold]="selectedDefaultHeader == hdrCnt.jsonkey && (bodyArray).length > 0 && hdrCnt.sortable != undefined ? hdrCnt.sortable : (selectedDefaultHeader == hdrCnt.jsonkey && bodyArray.length > 0)">
                      {{hdrCnt.title}}
                      <span *ngIf="hdrCnt.isPercentage">(%)</span>
                      <span class="pl-1" *ngIf="(hdrCnt.jsonkey != '' && hdrCnt.sortable == true) && ((bodyArray).length != 0)">
                          <i aria-hidden="true" id="sortArrow{{hi}}" *ngIf="hdrCnt.jsonkey != '' && hdrCnt.sortable != undefined ? hdrCnt.sortable : true && (bodyArray).length != 0"
                            [ngStyle]="{'opacity':selectedDefaultHeader == hdrCnt.jsonkey && !isEditModeSet ? '1' : '0.3' }"
                            [ngClass]="selectedDefaultHeader == hdrCnt.jsonkey && orderType == 'asc' ? 'icon-filled_up_arrow position-absolute deg0 IC6': selectedDefaultHeader == hdrCnt.jsonkey && orderType =='desc' ?
                        'icon-filled_up_arrow deg180 position-absolute IC6': 'icon-sortArrow sort-icon'">
                          </i>
                        </span>
                    </div>
                  </th>
                  <th  scope='col' class="text-right" *ngIf="!isRoleSetUpReadOnly && EnableActions" [ngClass]="{'column-sticky': sticky, 'minwidth177':DoneCancel, 'minwidth100':!DoneCancel}">
                    <div class="th-inner  ">{{captions.setting.Actions}}</div>
                  </th>
                </tr>
              </thead>
              <tbody class=" h-100" flex formArrayName="tablebody">
                <tr [ngClass]="{'onEdit':isEdit, 'editRow':editableRow == bodyCnt.id}"
                  id="{{bodyCnt.id}}" *ngFor="let bodyCnt of dataSource;let bi=index;"
                  [formGroupName]="start+bi">
                  <td class="pr-3 noellipsis">
                    <mat-checkbox (change)="RowSelect($event, bodyCnt, 'Single', start+bi)" [checked]="(SelectedData.indexOf(bodyCnt) != -1)"
                      [disabled]="isReadOnly"></mat-checkbox>
                  </td>
                  <td *ngFor="let hdrCnt of hdrArray;let hi=index" [ngClass]="[hdrCnt.alignType=='right' ? 'text-right' : 'hello',hdrCnt.alignType=='center' ? 'text-center' : 'text-left',hdrCnt.sortable == true ? 'pr30':'']"
                    class="table-data">
                    <span class="content d-inline-block" id="content">
                    <span *ngIf="(!hdrCnt.isEdit) && hdrCnt.datatype && hdrCnt.datatype == 'decimal'" class="textellipsis">
                        {{bodyCnt[hdrCnt.jsonkey] }}%
                      </span>
                      <span  *ngIf="(!hdrCnt.isEdit) && hdrCnt.datatype != 'decimal'" [ngClass]="{'anchor-like': hdrCnt.type == 'clickable'}" (click)="lblclick(bodyCnt, start+bi , hdrCnt.type)" class="textellipsis">
                        {{(SetColumnGridValue | setcolumnvalue : bodyCnt : hdrCnt.jsonkey)}}
                      </span>
                      <span  *ngIf="hdrCnt.isEdit">
                          <input  type="text" *ngIf="hdrCnt.isInput && hdrCnt.type == 'text'" class="input-field" [ngClass]="(bodyCnt[hdrCnt.jsonkey]) ? 'input-field' : hdrCnt.isRequired ? 'required' : ''"
                              [formControlName]="hdrCnt.jsonkey"  (input)="tableDataEdited($event,bodyCnt,hdrCnt.jsonkey,start+bi)">
                              <input  type="text" *ngIf="hdrCnt.isInput && hdrCnt.type == 'number'" id="{{hdrCnt.jsonkey}}{{start+bi}}"   RetailCurrencyFormatter [preDecimalLength]="maxLength" class="input-field"  [ngClass]="(bodyCnt[hdrCnt.jsonkey]) ? 'input-field' : hdrCnt.isRequired ? 'required' : ''"
                              [formControlName]="hdrCnt.jsonkey"  (change)="bodyCnt[hdrCnt.jsonkey] == 0 ?  LoadDecimalValue(0) : bodyCnt[hdrCnt.jsonkey] " (input)="fieldEdit($event,bodyCnt,hdrCnt.jsonkey,start+bi)" name="{{hdrCnt.jsonkey}}{{start+bi}}">
                          </span>
                  </span>
                </td>
              </tr>
              <tr *ngIf="(bodyArray).length == 0 && !isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1" class="text-center">
                  {{captions.common.NoDataFound}} </td>
              </tr>

              <tr *ngIf="isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1" class="text-center">{{captions.common.loadingData}}</td>
              </tr>
            </tbody>
          </table>
        </ng-scrollbar>
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20, 30, 50]" class="w-100" [showFirstLastButtons]="true" [pageSize]="pageSize" [length]="(bodyArray).length"
        (page)="handlePaginator($event)"></mat-paginator>
      </div>
    </div>
  </form>
</div>
