<section class="retail-code-setup retail-outlets-wrapper newVersionWrapper h-100" id="outlet">
    <app-table-search-header [attr.automationId]="'Srch_retailOutlets_search'" [searchHeaderOption]="searchHeaderOption" (searchChange)="searchChange($event)"
    (createType)="createEvent($event)">
 </app-table-search-header>

    <div class="retailGrid" id="retailOutletGrid">
        <app-retail-table [attr.automationId]="'Tbl_retailOutlets_retail'"  [customEditCancelled]="customEditCancelled"  [searchvalue]="searchText" [options]="tableoptions" (doneEvtEmitter)="Done($event)"
            (deleteEvt)="DeleteRecords($event)" [isOnEditDisableFunctionAvailable]="onEditDisableFunction" [isDeleteEnableForDefault]="true"
            (editEvt)="EditRecords($event)" [sncProductID]="sncProductID" [outletArr]="outlets"  [isEditModeSetFromParent]="isEditFlag" [isAddButtonDisable]="AddButtonDisable"></app-retail-table>
    </div>

</section>
