import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DynamicFormService } from '@dynamicform/service/dynamic-form.service';


@Directive({
  selector: 'input[phoneNumber]'
})
export class PhoneNumberFormatDirective {
  constructor(public ngControl: NgControl, private dfs: DynamicFormService) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    if (event) {
      const value = this.dfs.onInputChange(event, false);
      this.ngControl.valueAccessor.writeValue(value);
    }
  }
  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    const value = this.dfs.onInputChange(event.target.value, true);
    this.ngControl.valueAccessor.writeValue(value);
  }
}
