<div class="ag_display--flex delete-dep-alert">
    <app-dialog-header [title]="title" (closeDialog)="close($event)"></app-dialog-header>
    <section class="alert-content">
        <div class="subHeader">{{subHeader}}</div>
       
        <section class="msg">
            <div *ngFor="let item of deps;let i=index" class="ag_mb--1">
                {{item}}
            </div>
        </section>
    </section>
    <div class="ag_footer--actions">
        <ng-container [ngSwitch]="data?.buttonType">
            <ng-template [ngSwitchCase]="buttonType.Continue">
                <button mat-button class="ag_button--primary"
                    (click)="DialogClose('CONTINUE')">{{continueText}}</button>
            </ng-template>
            <ng-template [ngSwitchCase]="buttonType.ContinueCancel">
                <button mat-button class="ag_button--primary"
                    (click)="DialogClose('CONTINUE')">{{continueText}}</button>
                <button mat-button class="ag_button--secondary" (click)="DialogClose('CANCEL')">{{cancelText}}</button>
            </ng-template>
            <ng-template [ngSwitchCase]="buttonType.Ok">
                <button mat-button class="ag_button--primary" (click)="DialogClose('CONTINUE')">{{okText}}</button>
            </ng-template>
            <ng-template [ngSwitchCase]="buttonType.OkCancel">
                <button mat-button class="ag_button--primary" (click)="DialogClose('CONTINUE')">{{okText}}</button>
                <button mat-button class="ag_button--secondary" (click)="DialogClose('CANCEL')">{{cancelText}}</button>
            </ng-template>
            <ng-template [ngSwitchCase]="buttonType.YesNo">
                <button mat-button class="ag_button--primary" (click)="DialogClose('YES')">{{YES}}</button>
                <button mat-button class="ag_button--secondary" (click)="DialogClose('NO')">{{NO}}</button>
            </ng-template>
            <ng-template [ngSwitchCase]="buttonType.YesNoCancel">
                <button mat-button class="ag_button--primary" (click)="DialogClose('YES')">{{YES}}</button>
                <button mat-button class="ag_button--secondary" (click)="DialogClose('NO')">{{NO}}</button>
                <button mat-button class="ag_button--tertiary" (click)="DialogClose('CANCEL')">{{cancelText}}</button>
            </ng-template>
            <ng-template ngSwitchDefault>
                <button mat-button class="ag_button--primary" (click)="DialogClose('CONTINUE')">{{okText}}</button>
            </ng-template>
        </ng-container>

      
    </div>
</div>