import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { buttonTypes, ComponentTypes } from '@constants/commonenums';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { DynamicComponenReference, TabReference } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { CustomPopUpBussinessService } from './custom-popup.bussines.service';
import * as GlobalConst from '@app/shared/constants/globalConstants';
import { CacheService } from '@app/core/services/cache.service';
import { Utilities } from '@app/shared/utilities/utilities';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class CustomPopupComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('dynamicComponent', { read: ViewContainerRef }) dynamicComponent: ViewContainerRef;
  @ViewChild('dialogconatiner', { static: true }) dialogconatiner: ElementRef;
  input: any;
  value: boolean;
  componentName: any;
  enableCancel: boolean;
  text = 'next';
  title = 'seat-v2';
  buttonValueprimary: ButtonValue;
  buttonSecondary: ButtonValue;
  buttonValueNext: ButtonValue;
  buttonValueCancel: ButtonValue;
  showTitle = false;
  showFooter = true;
  dialogTitle: any;
  isBackDisabled = true;
  isBackAvailable = true;
  closeSubscription: ISubscription;
  nextSubscription: ISubscription;
  previousSubscription: ISubscription;
  saveSubscription: ISubscription;
  confirmedSubscription: ISubscription;
  cancelSubscription: ISubscription;
  subscriptions: Subscription = new Subscription();
  showClose = true;
  restaurantName: string;

  constructor(public dialogRef: MatDialogRef<CustomPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private componentFactoryResolver: ComponentFactoryResolver,
    public customPopUpBussinessService: CustomPopUpBussinessService,
    public ps: PopupService,
    private cdf: ChangeDetectorRef,
    private dialog: MatDialog,
    private cacheService: CacheService
  ) {
  }

  close(event) {
    if (this.dialogData.from === ComponentTypes.addSchedule
      || this.dialogData.from === ComponentTypes.EditRole
      || this.dialogData.from === ComponentTypes.Hosts
      || this.dialogData.from === ComponentTypes.advancedBlock
      || this.dialogData.from === ComponentTypes.editServer
      || this.dialogData.from === ComponentTypes.deleteServer
      || this.dialogData.from == ComponentTypes.CheckInRatePlanSummary
      || this.dialogData.from == ComponentTypes.CheckOutRatePlanSummary
      || this.dialogData.from == ComponentTypes.ChargeGuest
    ) {
      this.ps.restrictCloseDialog = false;
    }
    if (event == 0) {
      this.dialogRef.close();
    }
    if (event == 1 && !this.ps.restrictCloseDialog) {
      this.dialogRef.close(this.dialogData.from === ComponentTypes.AdditionalCharge ? event : null);
    }
    if (this.dialogData.componentDetails.popupInput && this.dialogData.componentDetails.popupInput[0] &&
      this.dialogData.componentDetails.popupInput[0].sendConfirmationEmail) {
      this.ps.cancelledAction$.next({ 'from': this.dialogData.from, 'value': event, 'popupInput': this.dialogData.componentDetails.popupInput[0] });
    } else {
      this.ps.cancelledAction$.next({ 'from': this.dialogData.from, 'value': event });
    }
  }

  ngOnInit() {
    this.buttonValueprimary = {
      type: buttonTypes.actionPrimary,
      label: this.dialogData.update,
      customclass: 'save-btn',
    };
    this.buttonSecondary = {
      type: buttonTypes.actionSecondary,
      label: this.dialogData.cancel,
      customclass: 'cancel-btn',
    };
    this.buttonValueNext = {
      type: buttonTypes.actionPrimary,
      label: '',
      customclass: 'next-btn',
      disbaledproperity: false
    };
    this.buttonValueCancel = {
      type: buttonTypes.actionSecondary,
      label: 'Previous',
      customclass: 'cancel-btn'
    };

    this.showFooter = this.dialogData.showFooter == undefined ? true : false;
    this.showClose = this.dialogData.showClose != undefined ? this.dialogData.showClose : true;
    this.subscriptions.add(this.ps.btnLabel.subscribe(x => {
      this.buttonValueNext.label = x;
      this.buttonValueNext.disbaledproperity = true;
    }));

    if(this.dialogData.showRestaurantName) {
      this.restaurantName = sessionStorage.getItem(`restaurantName${Utilities.getSessionStorageType()}`);
    }

    if (this.dialogData.from !== ComponentTypes.dataRetention) {
      // this.ps.btnLabel.emit('next');
    }
    switch (this.dialogData.from) {
      case ComponentTypes.addServer:
      case ComponentTypes.editServer:
      case ComponentTypes.server:
      case ComponentTypes.table:
      case ComponentTypes.resetPin:
        this.buttonValueprimary.disbaledproperity = true;
        break;
      case ComponentTypes.printChit:
        this.buttonValueprimary.type = buttonTypes.actionNgxPrint;
        this.buttonValueprimary.printId = 'Chit-Print_Print-Section'
        this.buttonValueprimary.printStyle = GlobalConst.chitPrintStyles;
        this.buttonValueprimary.additionalText = "chit-print";
        break;
      case ComponentTypes.imageGallery:
        this.buttonSecondary.disbaledproperity = true;
        this.buttonValueprimary.disbaledproperity = true;
        break;
      case ComponentTypes.TicketComponent:
        this.buttonValueprimary.type = buttonTypes.actionNgxPrint;
        this.buttonValueprimary.printId = 'ticket-print-section'
        this.buttonValueprimary.printStyle = GlobalConst.ticketPrintStyles;
        this.buttonValueprimary.additionalText = this.dialogData.printSectionId;
        this.buttonValueprimary.printerType = this.dialogData?.TicketType
        break;
    }
  }


  save(event) {
    if (!this.ps.restrictCloseDialog) {
      this.dialogRef.close(this.dialogData.from);
    }
    if (this.dialogData.componentDetails.popUpDetails.isStepper) {
      this.ps.nextEvent$.next(this.dialogData.from);
    } else {
      this.ps.confirmedAction$.next(this.dialogData.from);
    }
  }

  ngAfterViewInit() {
    this.nextSubscription = this.ps.nextBtnEnabled$.subscribe(val => {
      this.buttonValueNext.disbaledproperity = val;
    });
    this.previousSubscription = this.ps.previousButtonEnabled$.subscribe(val => {
      this.isBackDisabled = val;
      this.cdf.detectChanges();
    });
    this.closeSubscription = this.ps.closeDialog$.subscribe((closeAll) => {
      if (closeAll) {
        this.dialog?.closeAll();
      } else {
        this.dialogRef?.close()
      }
    });
    this.saveSubscription = this.ps.saveBtnEnable$.subscribe((data) => {
      this.buttonValueprimary.disbaledproperity = this.dialogData.componentDetails.notDisableOkButton ? false : !data;
    });
    this.cancelSubscription = this.ps.cancelBtnEnable$.subscribe((data) => {
      this.buttonSecondary.disbaledproperity = !data;
    });
    this.componentName = this.dialogData.componentDetails.componentName;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.componentName);
    const componentRef = this.dynamicComponent.createComponent(componentFactory);
    if (this.dialogData.componentDetails.tabs) {
      (componentRef.instance as TabReference).tabsConfig = this.dialogData.componentDetails.tabs;
      (componentRef.instance as TabReference).fromComponent = this.dialogData.from;
      (componentRef.instance as TabReference).fromCart = this.dialogData.isfromCart || false;

    } else {
      (componentRef.instance as DynamicComponenReference).data = this.dialogData.componentDetails.popupInput;
      this.dialogTitle = this.dialogData.componentDetails.popupTitle ? this.dialogData.componentDetails.popupTitle : '';
      this.isBackAvailable = this.dialogData.back;
      this.showTitle = this.dialogTitle ? true : false;
    }
    this.cdf.detectChanges();
  }

  next($event) {
    if (this.buttonValueNext.label === 'next') {
      this.ps.nextEvent$.next('next');
    } else if (this.buttonValueNext.label === 'save') {
      this.dialogRef.close();
      this.ps.confirmedAction$.next(this.buttonValueNext.label);
    }
  }

  previous($event) {
    this.ps.previousEvent.emit('previous');
    if (this.dialogData.componentDetails.popupType === 'tabs') {
      this.ps.previousEvent$.next(true);
    }
  }

  ngOnDestroy() {
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }
    if (this.previousSubscription) {
      this.previousSubscription.unsubscribe();
    }
    if (this.nextSubscription) {
      this.nextSubscription.unsubscribe();
    }
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    if(this.cancelSubscription){
      this.cancelSubscription.unsubscribe();
    }
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
