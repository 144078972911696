import { Pipe, PipeTransform } from '@angular/core';
import { PageMethod, SettingsDTO } from '../models/RestaurantDTO';
import moment from 'moment';
import { Utilities } from '../utilities/utilities';

@Pipe({
  name: 'pageBackgroundColor'
})
export class PageBackgroundColorPipe implements PipeTransform {

  transform(party, settings: SettingsDTO): string {
    let pageBackgroundColor = '#E8E8E8';
    if (party && party.PageMethod == PageMethod.Manual) {
      if (!party.LastPagedAt) {
        return pageBackgroundColor;
      }
      const lastPagedAt = moment(party.LastPagedAt);
      const time = moment(Utilities.getRestaurantDateTime(settings.General.DaylightDelta));
      let differenceTime = (settings.General.MinIntervalBetweenPartyPagesInMinutes * 60) - (time.diff(lastPagedAt, 'seconds'));
      if ((time.diff(lastPagedAt, 'minutes')) >= settings.General.MinIntervalBetweenPartyPagesInMinutes) {
        pageBackgroundColor = 'red';
        // if (this.pageTimer) {
        //   this.pageTimer.unsubscribe();
        //   this.subscriptions.remove(this.pageTimer);
        // }
      } else {
        pageBackgroundColor = 'green';
        // if (differenceTime > 0) {
        //   this.subscriptions.add(this.pageTimer);
        // }
      }
    }
    if (party && (party.PageMethod == PageMethod.Sms || party.PageMethod == PageMethod.Sms2 || party.PageMethod == PageMethod.Email || party.PageMethod == PageMethod.Email2)) {
      if (party && party.Messages && party.Messages.length > 0) {
        pageBackgroundColor = 'green';
      }
      if (party && party.Messages && party.Messages.length > 0 && party.Messages[party.Messages.length - 1].IsIncoming) {
        pageBackgroundColor = 'red';
      }
    }
    return pageBackgroundColor;
  }

}
