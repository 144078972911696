import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '@app/app.service';
import { PolicyDataType, PolicyType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { SettingsDTO, TSGeneralSettingsDTO } from '@models/RestaurantDTO';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-data-retention-policies',
  templateUrl: './data-retention-policies.component.html',
  styleUrls: ['./data-retention-policies.component.scss']
})
export class DataRetentionPoliciesComponent implements OnInit, OnDestroy {
  dataRetentionPolicy: any;
  currentUTCDate: any;
  currentDate: any;
  generalSettings: TSGeneralSettingsDTO;
  subscriptions: Subscription = new Subscription();

  constructor(public _as: AppService, public cs: CacheService) {
    this.subscriptions.add(cs.dataRetentionPolicy.subscribe(policy => {
      this.dataRetentionPolicy = policy;
    }));
  }

  ngOnInit() {
    this.generalSettings = this.cs.settings.value.General;
    if (this.dataRetentionPolicy.length > 0) {
      this.dataRetentionPolicy.forEach((item) => {
        if (item.PolicyType == PolicyType.Consent) {
          this.loadPolicies(item.PolicyDetails);
        }
      })
    }
  }

  loadPolicies(policyDetails) {
    policyDetails.forEach((policy) => {
      if (policy.DataType == PolicyDataType.Name)
        this.dataRetentionPolicy.nameRetentionPeriod = policy.RetentionInDays;
      else if (policy.DataType == PolicyDataType.EmailAddress)
        this.dataRetentionPolicy.emailRetentionPeriod = policy.RetentionInDays;
      else if (policy.DataType == PolicyDataType.PhoneNumber)
        this.dataRetentionPolicy.phoneRetentionPeriod = policy.RetentionInDays;
      else if (policy.DataType == PolicyDataType.Address)
        this.dataRetentionPolicy.addressRetentionPeriod = policy.RetentionInDays;
      else if (policy.DataType == PolicyDataType.GuestImage)
        this.dataRetentionPolicy.guestImageRetentionPeriod = policy.RetentionInDays;
      else if (policy.DataType == PolicyDataType.BirthdayAndAnniversary)
        this.dataRetentionPolicy.birthdayAndAnniversaryRetentionPeriod = policy.RetentionInDays;
      else if (policy.DataType == PolicyDataType.CustomGuestFields)
        this.dataRetentionPolicy.customFieldRetentionPeriod = policy.RetentionInDays;
    })
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
