import { Component, OnDestroy, OnInit } from '@angular/core';
import { CacheService } from '@core/services/cache.service';
import { SettingsDTO } from '@models/RestaurantDTO';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-restaurant-policies',
  templateUrl: './restaurant-policies.component.html',
  styleUrls: ['./restaurant-policies.component.scss']
})
export class RestaurantPoliciesComponent implements OnInit, OnDestroy {
  _settings: SettingsDTO = {} as SettingsDTO;
  restaurantPolicy: any = [];
  restMessage: any;
  subscriptions: Subscription = new Subscription();

  constructor(private cs: CacheService) {
  }

  ngOnInit() {
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.restaurantPolicy = sett.PropertySetting[0];
      const message = this.restaurantPolicy?.PreReservationMessage.split(/\n/ig);
      this.restMessage = message;
      if (message.length > 1) {
        this.restMessage = "";
        message.forEach(line => {
          this.restMessage += line + "<br/>";
        });
        document.getElementById('policies').innerHTML = this.restMessage;
      }
    }));
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
