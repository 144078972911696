import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivityBlockInfo, AdvanceBlockDataShare } from '@app/popup-module/components/services/advance-block.data';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { ActivityCustomizationAppliesTo, ActivityCustomizationType } from '@app/shared/models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { BlockingrulePopupservice } from '../../create-update-blocking-tab-layout/blockingrulepopup.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { ComponentTypes } from '@app/shared/constants/commonenums';
import moment from 'moment';


@Component({
  selector: 'app-date-range-for-block',
  templateUrl: './date-range-for-block.component.html',
  styleUrls: ['./date-range-for-block.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateRangeForBlockComponent implements OnInit  {
  inlineRange;
  applicationSelectConfig: FieldConfig[];
  reasonForBlockConfig: FieldConfig[];
  advBlockData: AdvanceBlockDataShare;
  @ViewChild('applicationSelectForm') applicationSelectForm: DynamicFormComponent;
  @ViewChild('reasonForm') reasonForm: DynamicFormComponent;
  selectedDateRange = { begin: new Date(), end: new Date() };
  constructor(private ts: TranslateService, private ps: PopupService, @Inject(COMPONENTINPUT) private inputData, private bps: BlockingrulePopupservice) {
    this.advBlockData = new AdvanceBlockDataShare();

  }
  

  ngOnInit(): void {
    this.setFieldConfig();
    if (this.inputData?.data) {
      this.selectedDateRange.begin = new Date(this.inputData.data.StartDate);
      this.selectedDateRange.end = new Date(this.inputData.data.EndDate);
    }
    this.bps.ps.tabChange$.subscribe(value => {
      if (value == 1) {
        this.ps.previousButtonEnabled$.next(false);
      }
      else {
        this.ps.previousButtonEnabled$.next(true);
      }
    });
    let confirmationAction = this.ps.confirmedAction$.subscribe(data => {
      confirmationAction.unsubscribe();
      cancelSubscription.unsubscribe();
      if (data.fromComponent == ComponentTypes.advancedBlock) {
        this.formRequestBodyForActivityBlock();
        this.bps.ps.blockSession(this.ps.advBlockData.blockActvityData).subscribe((response) => {
          const selectedtableNames = this.bps.getSortedTable();
          const msg = this.ts.instant('activityBlockMessage');
          let message = '';
          //   this.ts.get('activityBlockMessage', { selectedtableNames }).subscribe(msgVal => message = msgVal);
          this.ps.advBlockData = null;
          
          this.bps.ShowNotification(response, msg);
        });
      }
      else if(data.fromComponent == ComponentTypes.Unblock){
        this.formRequestBodyForActivityBlock();
        this.bps.ps.unblockActivity(this.ps.advBlockData.blockActvityData).subscribe((response) => {
          const selectedtableNames = this.bps.getSortedTable();
          const msg = this.ts.instant('unblockActivityMessage');
          let message = ''; this.ps.advBlockData = null;
          //   this.ts.get('unblockActivityMessage', { selectedtableNames }).subscribe(msgVal => message = msgVal);
          this.bps.ShowNotification(response, msg);
        });
      }
    })

    let cancelSubscription = this.ps.cancelledAction$.subscribe(data => {
      confirmationAction.unsubscribe();
      cancelSubscription.unsubscribe();
    })
  }

  formRequestBodyForActivityBlock() {
    this.ps.advBlockData.blockActvityData.PropertyId = Utilities._restaurantId;
    this.ps.advBlockData.blockActvityData.CustomizationType = ActivityCustomizationType.Block;
    this.ps.advBlockData.blockActvityData.ActionBy = Utilities.getHostId().toString();
    this.ps.advBlockData.blockActvityData.StartDate = moment(this.selectedDateRange.begin).format('YYYY-MM-DD');
    this.ps.advBlockData.blockActvityData.EndDate = moment(this.selectedDateRange.end).format('YYYY-MM-DD');
    let selectedSessionsForBlocking: ActivityBlockInfo[] = [];
    Object.keys(this.ps.advBlockData.selectedActivities).forEach(activity => {
      selectedSessionsForBlocking.push(
        {
          ActivitySessionIds: Object.keys(this.ps.advBlockData.selectedActivities[activity].Sessions).map(sessionId => +sessionId),
          CustomizationId: this.inputData?.data ? this.inputData.data.CustomizationId : null,
          ActivityId: +activity,
        }
      )
    });
    this.ps.advBlockData.blockActvityData.ActivityBlockInfos = selectedSessionsForBlocking;
  }

  inlineRangeChange(dateRange) {
    this.selectedDateRange = dateRange;
    if (!dateRange.end) {

      this.selectedDateRange.end = dateRange.begin;
    }

    // this.advBlockData.blockActvityData.StartDate = dateRange.begin;
    // this.advBlockData.blockActvityData.EndDate = dateRange.end;
  }


  getDateVal(waitDate) {

    let datec = Utilities.number_suffix(new Date(waitDate).getDate());
    return datec
  }

  setFieldConfig() {
    this.applicationSelectConfig = [
      {
        type: 'checkbox',
        placeholder: this.ts.instant('Reserve'),
        value: this.inputData?.data ? (this.inputData.data.AppliesTo == ActivityCustomizationAppliesTo.HostAppAndWidget || this.inputData.data.AppliesTo == ActivityCustomizationAppliesTo.HostApp) : true,
        class: 'host-selection',
        name: 'clientApplication',
      },
      {
        type: 'checkbox',
        placeholder: this.ts.instant('Online Widget'),
        value: this.inputData?.data ? (this.inputData.data.AppliesTo == ActivityCustomizationAppliesTo.HostAppAndWidget || this.inputData.data.AppliesTo == ActivityCustomizationAppliesTo.Widget) : true,
        class: 'widget-selection',
        name: 'widget',
      }];
    this.reasonForBlockConfig = [
      {
        type: 'textarea',
        name: 'blockingReason',
        placeholder: 'Enter Reason',
        label: 'Reason for blocking',
        rows: 2,
        inputType: 'text',
        class: 'reason',
        value: this.inputData?.data ? this.inputData.data.ActionReason : '',
        textAreaAppearance: 'outline'
      }
    ]
  }

  ngAfterViewInit() {
    this.ps.advBlockData.blockActvityData.AppliesTo = this.inputData?.data ? this.inputData.data.AppliesTo : ActivityCustomizationAppliesTo.HostAppAndWidget;
    this.reasonForm.form.valueChanges.subscribe(configData => {
      this.ps.advBlockData.blockActvityData.ActionReason = configData.blockingReason || '';
    });
    this.applicationSelectForm.form.valueChanges.subscribe(configData => {

      this.ps.advBlockData.blockActvityData.AppliesTo = this.getAppliesToValue(configData);
      this.checkState(this.ps.advBlockData.blockActvityData.AppliesTo)
    });
  }
  checkState(appliesTo) {
    this.ps.nextBtnEnabled$.next(appliesTo == undefined ? true : false)
  }


  getAppliesToValue(configData) {
    if (configData.widget && configData.clientApplication) {
      return ActivityCustomizationAppliesTo.HostAppAndWidget;
    }
    if (configData.widget) {
      return ActivityCustomizationAppliesTo.Widget;
    }
    if (configData.clientApplication) {
      return ActivityCustomizationAppliesTo.HostApp;
    }
  }

}
