
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Host, USER, THERAPIST, Product } from '../../shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { BaseResponse } from '../../shared/business/shared.modals';
import { UserDetails, UserRetailConfigurations, AssignCommissionStaffArrayUIModel, UserTherapist } from '../shop.modals';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { CommissionableUser } from './assign-commission.models';
import { CommonVariablesService } from '../../shared/service/common-variables.service';
import { RetailDataAwaiters } from '../../shared/events/awaiters/retail.data.awaiters';

@Injectable()
export class AssignCommissionService {
   
    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private _shopService: CommonVariablesService) {

    }

    async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
        try {
            return await this.http.CallApiAsync({
                host: domain,
                callDesc: route,
                method: callType,
                body: body,
                uriParams: uriParams,
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    async getCommissionableUsers(itemId: number): Promise<AssignCommissionStaffArrayUIModel[]> {
        let result: BaseResponse<CommissionableUser[]> = await this.InvokeServiceCallAsync('GetCommissionableUsers', Host.retailManagement, HttpMethod.Get, { itemId: itemId, productId: this._shopService.ProductId,outletId: this._shopService.SelectedOutletId });
        var staffs = result.result ? result.result : [];
        let commissionableStaffs: AssignCommissionStaffArrayUIModel[] = staffs.map(s => {
            return <AssignCommissionStaffArrayUIModel>{
                id: this.GetCustomStaffId(s.id, USER),
                staffId: _.cloneDeep(Number(s.id)),
                firstName: s.firstName,
                lastName: s.lastName,
                staffType: USER,
            }
        });
        return commissionableStaffs ? commissionableStaffs : [];
    }

    async GetEligibleStaffs(itemId: number): Promise<StaffInfo> {
        let result: StaffInfo;
        if (this._shopService.ProductId == Product.SPA) {
            result = await this.GetStaffInfo(itemId);
        }
        else {
            result = await this.GetUserInfo(itemId);
        }
        return result;
    }

    // Get eligible Staff information for Commission, Service charge & Gratuity
    async GetUserInfo(itemId: number): Promise<StaffInfo> {
        let [commissionableStaffs, allUsers, userConfigs] = await Promise.all(
            [
                this.getCommissionableUsers(itemId),
                this.getUserInfoByPropertyId(),
                this.getUserRetailConfigurationAsync()]);
        let staffInfo = this.GetGratuityServiceChargeStaff([], allUsers, userConfigs,[]);

        staffInfo.CommissionableStaff = commissionableStaffs;
        return staffInfo;
    }

    // Get eligible Staff information for Commission, Service charge & Gratuity
    async GetStaffInfo(itemId: number): Promise<StaffInfo> {
        let [allTherapists, commissionableStaffs, allUsers, userConfigs, userTherapists] = await Promise.all(
            [this.GetAllTherapist(),
            this.GetCommissionableStaffs(itemId),
            this.getUserInfoByPropertyId(),
            this.getUserRetailConfigurationAsync(),
            this.GetUserTherapists()]);
        let staffInfo = this.GetGratuityServiceChargeStaff(allTherapists, allUsers, userConfigs, userTherapists);
        commissionableStaffs.forEach(s => {
            s['staffId'] = _.cloneDeep(Number(s.id));
            s['id'] = this.GetCustomStaffId(s.id, s.staffType);
        });
        staffInfo.CommissionableStaff = commissionableStaffs;
        return staffInfo;
    }

    private async GetCommissionableStaffs(itemId: number): Promise<AssignCommissionStaffArrayUIModel[]> {
        let therapists: any;
        let uriParam = { itemId: itemId , productId: this._shopService.ProductId,outletId: this._shopService.SelectedOutletId};
        let apiResponse: BaseResponse<AssignCommissionStaffArrayUIModel[]> = await this.InvokeServiceCallAsync('GetCommissionableStaffs', Host.retailManagement, HttpMethod.Get, uriParam);
        if (apiResponse && apiResponse.successStatus && apiResponse.result) {
            therapists = apiResponse.result;
        }
        return therapists;
    }

    private async GetAllTherapist(): Promise<any> {
        try {
            return await RetailDataAwaiters.getActiveTherapists();
        } catch (e) {
            this.http.exceptionHandle(e);
        }

    }

    private async GetUserTherapists(): Promise<UserTherapist[]>{
        let userTherapist: UserTherapist[] = [];
        let apiResponse: BaseResponse<UserTherapist[]>= await this.InvokeServiceCallAsync('GetAllUserTherapist',Host.spaManagement,HttpMethod.Get);
        if(apiResponse && apiResponse.successStatus && apiResponse.result) {
            userTherapist = apiResponse.result;
        }
        return userTherapist;
    }

    private async getUserInfoByPropertyId(): Promise<UserDetails[]> {
        let users: UserDetails[] = [];
        let apiResponse: BaseResponse<UserDetails[]> = await this.InvokeServiceCallAsync('GetUserInfoByPropertyId', Host.authentication, HttpMethod.Get,
                                                       {propertyId: this.utils.GetPropertyInfo("PropertyId"),  productId : this._shopService.ProductId });
        if (apiResponse && apiResponse.successStatus && apiResponse.result) {
            users = apiResponse.result;
        }
        return users;
    }
    private async getUsersInfoAsync(): Promise<UserDetails[]> {
        let users: UserDetails[] = [];
        let apiResponse: BaseResponse<UserDetails[]> = await this.InvokeServiceCallAsync('GetAllUsersByPropertyId', Host.authentication, HttpMethod.Get,
                                                       {propertyId: this.utils.GetPropertyInfo("PropertyId"),  productId : this._shopService.ProductId });
        if (apiResponse && apiResponse.successStatus && apiResponse.result) {
            users = apiResponse.result;
        }
        return users;
    }

    private async getUserRetailConfigurationAsync(): Promise<UserRetailConfigurations[]> {
        let userConfigs: UserRetailConfigurations[] = [];
        let apiResponse: BaseResponse<UserRetailConfigurations[]> = await this.InvokeServiceCallAsync('GetAllUserRetailConfiguration', Host.retailManagement, HttpMethod.Get);
        if (apiResponse && apiResponse.successStatus && apiResponse.result) {
            userConfigs = apiResponse.result;
        }
        return userConfigs;
    }

    private GetGratuityServiceChargeStaff(allTherapists: any[], allUsers: UserDetails[], userConfig: UserRetailConfigurations[], userTherapists: UserTherapist[]): StaffInfo {

        let gratuityStaff: AssignCommissionStaffArrayUIModel[] = [];
        let serviceChargeStaff: AssignCommissionStaffArrayUIModel[] = [];
        let allStaffs: AssignCommissionStaffArrayUIModel[] = [];
        if (allUsers && userConfig) {
            allUsers.filter(r => r.isActive).forEach(u => {
                u['id'] = u.userId;
                let config = userConfig.find(c => c.userId == u.userId);
                if (!userTherapists.some(t => t.userId == u.userId)) {
                    if (config) {
                        if (config.allowGratuity) {
                            gratuityStaff.push(this.FormStaffObject(u, USER));
                        }
                        if (config.allowServiceCharge) {
                            serviceChargeStaff.push(this.FormStaffObject(u, USER));
                        }

                    }
                    allStaffs.push(this.FormStaffObject(u, USER));
                }
            });
        }
        if (allTherapists && allTherapists.length > 0) {
            for (let therapist of allTherapists) {
                if (therapist.allowGratuity) {
                    gratuityStaff.push(this.FormStaffObject(therapist, THERAPIST));
                }
                if (therapist.allowServiceCharge) {
                    serviceChargeStaff.push(this.FormStaffObject(therapist, THERAPIST));
                }
                allStaffs.push(this.FormStaffObject(therapist, THERAPIST));
            }
        }
        return {
            ServiceChargeStaff: serviceChargeStaff,
            GratuityStaff: gratuityStaff,
            AllStaffs: allStaffs,
            CommissionableStaff: null
        }; 
    }

    private FormStaffObject(staffData: any, staffType: string): AssignCommissionStaffArrayUIModel {
        return {
            id: this.GetCustomStaffId(staffData.id, staffType),
            staffId: staffData.id,
            firstName: staffData.firstName,
            lastName: staffData.lastName,
            staffType: staffType,
            staffLevel: 0,
            isStaffSelected: false,
            image: ''
        };
    }

    GetCustomStaffId(staffId: any, staffType: string): string {
        var customId = _.cloneDeep(staffId);
        if (staffType == THERAPIST) {
            customId = `T${staffId}`
        }
        else if (staffType == USER) {
            customId = `U${staffId}`
        }
        return customId;
    }
}

export interface StaffInfo {
    CommissionableStaff: AssignCommissionStaffArrayUIModel[];
    GratuityStaff: AssignCommissionStaffArrayUIModel[];
    ServiceChargeStaff: AssignCommissionStaffArrayUIModel[];
    AllStaffs: AssignCommissionStaffArrayUIModel[];
}
