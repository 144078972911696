<!--  -->
<div class="timeline">
  <div class="timeline__table-structure" [ngClass]="{'sidemenu-close': appService.closeNav}">
    <div class="timeline__grid-view">
      <div class=" timeline__timeline-header">

        <div class="timeline__controls">
          <activities-app-button *ngFor="let control of buttonControls" [buttontype]="control" (valueChange)="changeEvent(control.name)">
          </activities-app-button>
          <dynamic-form [config]="shiftConfig" #form="dynamicForm">
          </dynamic-form>
          <dynamic-form [config]="searchConfig" #searchConfigForm>
          </dynamic-form>
        </div>

        <div class="timeline__booking-data seat-tertiary-text">
          <span>{{reservations?.length}} {{'bookingsText' | translate}} |</span>
          <span class="timeline__data-reservation-length">{{coversCount}} {{'coversText' | translate}}</span>
        </div>

      </div>

      <div *ngIf="unassignedCounter > 0" class="timeline__timeline-unassigned seat-secondary-bg-color">

        <div class="timeline__controls">
          <label class="rs-mr-20 seat-header-two">{{'unassignedText' | translate}}</label>
        </div>

        <div class="timeline__unassigned-count">
          <!-- <activities-app-button [buttontype]="unassignedCount" [popover]="unassignedListPopover"></activities-app-button> -->
          <activities-app-button [buttontype]="unassignedCount"
          [ngbPopover]="unassignedListPopover" placement="bottom" [autoClose]="'outside'"></activities-app-button>
          <ng-template #unassignedListPopover>
            <div class="popOver-details p-2">
              <div class="popOver-details__header">
                {{'unAssignedParties' | translate}}
              </div>
              <div class="popOver-details__content">
                <div class="paid">
                  <table *ngFor="let unassigneParty of unassignedReservations; trackBy:trackById" style="width:100%;">
                    <tr (click)="unassignedPartySelected(unassigneParty)">
                      <td class="name">{{ unassigneParty?.Contact | formattedName }}&nbsp;</td>
                      <td class="size">{{ unassigneParty.Size }} | {{unassigneParty.SeatingTime | localizedDate: 'LT'}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- <popover-content #unassignedListPopover [closeOnClickOutside]="true" class="default">
            <div class="popOver-details">
              <div class="popOver-details__header">
                Unassigned Parties
              </div>
              <div class="popOver-details__content">
                <div class="paid">
                  <table *ngFor="let unassigneParty of unassignedReservations" style="width:100%;">
                    <tr (click)="unassignedPartySelected(unassigneParty)">
                      <td class="name">{{ unassigneParty?.Contact?.FirstName ? unassigneParty?.Contact?.FirstName : '' }} {{ unassigneParty?.Contact?.LastName ? unassigneParty?.Contact?.LastName : '' }}</td>
                      <td class="size">{{ unassigneParty.Size }} | {{unassigneParty.SeatingTime | localizedDate: 'LT'}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </popover-content> -->
        </div>
      </div>

      <div class="timeline__timeline-view" [ngClass]="{'vcart-container' : cs?.isIframeEnabled}" id="timeLineTable">
        <ng-scrollbar track="all">
          <table id="timlineTable" class="timeline__table-view">
            <thead>
              <tr class="timeline-grid-header">
                <th class="timeline__table-number seat-secondary-bg-color">
                  <div class="pl-2 pr-2 wid30"> {{'timelineTableHeaderText' | translate}}</div>
                </th>
                <th class="timeline__max-cover seat-secondary-bg-color">
                  <div class=" pl-2 pr-2">{{'timelineCoverHeaderText' | translate}} </div>
                </th>

                <th class="timeline__time-display">
                  <span class="timeline__time-display-time" [style.left]="leftShift" *ngIf="showTimeBar">{{currentTimeView}}</span>
                  <!-- *ngFor="let item of a" -->
                  <table class="timeline__time-display-table">
                    <tbody>
                      <th class="timeline__display-time timeline__table-header"
                        *ngFor="let timeValue of timeArrayGenerated">
                        {{timeValue | localizedDate:'LT'}}
                      </th>
                      <th class="timeline__display-time timeline__day-end">
                        <span class="timeline__day-end-text">
                          {{ (this.timelineShiftId > 0 ? 'timelineShiftEnd' :'timelineDayEnd' ) | translate}}</span>
                        <span></span>
                      </th>
                    </tbody>
                  </table>
                </th>

              </tr>
            </thead>

            <tbody class="timeline__table-content">
              <tr>
                <td class="timeline__table-content-header">
                  <div class="timeline__table-row">
                    <table class=" timeline__table-row-table">
                      <tbody>
                        <tr *ngFor="let reservation of currentReservations;let row=index; trackBy:trackById" class="TH_{{row}} timeline-grid-header"
                          [ngClass]="{'timeline__communal-row': reservation.IsCommunalTable}">
                          <td class="timeline__table-data " #tableCell
                            [ngStyle]="{'height': reservation.rowHeight ? (reservation.rowHeight + 'px') : ''}">
                            <div class="pl-2 pr-2 wid30 textEllips" matTooltipClass="tooltip-class"
                              title="{{ reservation.Name }}"> {{reservation.Name}}
                            </div>
                            <a *ngIf="reservation.IsCommunalTable && reservation.Id != 0" class="icon-communal"></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
                <td class="timeline__table-content-header timeline__table-content-size">
                  <div class="timeline__table-row">
                    <table class=" timeline__table-row-table">
                      <tbody>
                        <tr *ngFor="let reservation of currentReservations;let row=index; trackBy:trackById" class="TH_{{row}} timeline-grid-header" #tableCell
                          [ngClass]="{'timeline__communal-row': reservation.IsCommunalTable}" >
                          <td class="timeline__cover-data "
                          [ngStyle]="{'height': reservation.rowHeight ? (reservation.rowHeight + 'px') : ''}">
                            <div class=""> {{reservation.MaxPartySize}} </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>

                <ng-container #dataCell>
                  <div class="timeline__cell-view">
                    <div class="timeline__current-bar" *ngIf="showTimeBar">
                      <!-- <span class="timeline__date-bar">{{currentTimeView}}</span> -->
                    </div>
                    <table class="timeline__cell-table-view timeline-grid-content" id="table">
                      <tbody class="timeline__cell-table-body">
                        <tr *ngFor="let reservation of currentReservations;let curr= index; trackBy:trackById"
                          class="reservation_{{curr}} timeline__cell-table-body-row ">

                          <ng-container>
                            <div class="h-100 w-100" *ngFor="let TA of reservation.data;let dataIndex = index">
                              <div class="w-100 h-100">
                                <td class="timeline__display-cell"
                                  [ngStyle]="{'height': reservation.rowHeight ? (reservation.rowHeight + 'px') : ''}"
                                  *ngFor="let data of TA;let dataIndex1 = index;let lastcol =last" #tableCell>

                                  <div class="timeline__display-cell-container">
                                    <div *ngFor="let item of data.reservationData;let k=index;let lastcol =last"
                                      class="timeline__cell-division">
                                      <div class="timeline__cell-division-container" [ngClass]="{'timeline__first-cell':k == 0}">
                                        <span class="timeline__cell-division-container-text"
                                          *ngFor="let item1 of item.data | partyDataFilter: searchString;let dataIndex2 = index">
                                          <div matTooltipClass="tooltip-class"
                                            title="{{item1?.Contact?.FirstName ? item1?.Contact?.FirstName : '' }} {{item1?.Contact?.LastName ? item1?.Contact?.LastName : ''}}"
                                            [ngClass]="{'selected':item.selected}" *ngIf="item1?.reservationStatus == true"
                                            class="data-bar_{{k}} timeline__cell-division-container-text-view"
                                            [style.top]="item1?.cssTop + 'px'"
                                            [style.width]="item1 | reservationBarWidth : currentDayShifts : timeArrayGenerated  "
                                            [style.left]="item1 | reservationBarLeft"
                                            [style.background]="item1 | reservationBGColor : _settings">
                                            <div class="timeline__cell-data" (click)="elementSelected(item1,$event)"
                                              [ngClass]="{'timeline__added-icons': item1?.Contact?.IsVip || (item1?.Notes?.length > 0 || item1?.Contact?.Notes?.length > 0)}">
                                              <span class="icon-add-contact timeline__cell-text" *ngIf="showName"></span>
                                              <span class="timeline__cell-text-size"
                                                [ngClass]="{'timeline__cell-text-only-size':!showName}">{{item1.Size}}</span>
                                              <span *ngIf="item1?.TableIds?.length > 1" class="icon-link pad10"></span>
                                              <span *ngIf="showName" class="timeline__cell-text-name">
                                                {{ item1?.Contact | formattedName }} <ng-container *ngIf="item1?.Contact?.AliasName" > ({{item1?.Contact?.AliasName}})</ng-container>
                                              </span>
                                            </div>
                                            <div class="timeline__icon-view">
                                              <span class="icon-VIP timeline__vip-icon" *ngIf="item1?.Contact?.IsVip"></span>
                                              <span class="icon-exclamation timeline__notes-icon"
                                                *ngIf="(item1?.ContactNotes && item1?.ContactNotes?.length > 0) || (item1?.PartyNotes && item1?.PartyNotes?.length > 0)"
                                                [ngbPopover]="partyNotesPopover" autoClose="true" #timelinePartyNotes="ngbPopover"
                                                container="body" popoverClass="party-notes-popover"><span class="path1"></span><span
                                                  class="path2"></span><span class="path3"></span>
                                              </span>
                                              <ng-template #partyNotesPopover>
                                                <div>
                                                  <div class="reservation-scrollsize">
                                                    <div *ngIf="(item1?.PartyNotes && item1?.PartyNotes?.length > 0)"
                                                      class="reservation-notes">
                                                      <div class="reservation-notes__header seat-popover-header">
                                                        {{'reservationNotes' | translate}}
                                                      </div>
                                                      <div class="reservation-notes__content seat-popover-content">
                                                        <div class="reservation-notes__notes-details seat-border-color"
                                                          *ngFor="let notes of item1?.PartyNotes">
                                                          <span *ngIf="!notes.isSpecialMeal">{{notes.name}}</span>
                                                          <span *ngIf="notes.isSpecialMeal">
                                                            <span class="uppercase seat-secondary-text"  *ngIf="notes.type == partyNoteType.SpecialMeal">
                                                              {{'specialMealText' | translate }}:
                                                            </span>
                                                            <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.SpecialRequest">
                                                              {{'specialRequest' | translate }}:
                                                            </span>
                                                            <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.PromoCode">
                                                              {{_settings.PromoCodeLabel}}:
                                                            </span>
                                                            <span>
                                                              {{notes.name}}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div *ngIf="(item1?.ContactNotes && item1?.ContactNotes?.length > 0)"
                                                      class="reservation-notes">
                                                      <div class="reservation-notes__header seat-popover-header">
                                                        {{'guestNotesText' | translate}}
                                                      </div>
                                                      <div class="reservation-notes__content seat-popover-content">
                                                        <div class="reservation-notes__notes-details seat-border-color"
                                                          *ngFor="let notes of item1?.ContactNotes">
                                                          <span *ngIf="!notes.isSpecialMeal">{{notes.Text}}</span>
                                                          <span *ngIf="notes.isSpecialMeal">
                                                            <span class="uppercase">{{'specialMealText' | translate }}:
                                                            </span>{{notes.name}}</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </ng-template>
                                            </div>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td class="timeline__day-end">
                                </td>

                              </div>
                            </div>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </ng-scrollbar>
      </div>

      <div class="timeline__display-legends">
        <!-- <app-display-legend [legends]="displayLegends"></app-display-legend> -->
      </div>
    </div>

  </div>
  <!-- <sec-side-nav [inputData]="componentConfigs"></sec-side-nav>
   -->
  <!-- <app-dashboard [dashboardData]="dashboardData" (actions)="actions($event)"></app-dashboard> -->
  <app-dashboard [type]="ReservationType.Timeline" [dashboardData]="dashboardData" (actions)="actions($event)"
    (partyUnselectedEvent)="partyUnselectedEventHander()" (createReservation)="create($event)"></app-dashboard>
</div>



