<section class="gift-card-details" [formGroup]="cardReloadFormGroup">
  <ng-scrollbar >
    <div class="d-flex mb-4">
      <span class="w40 align-self-center imgstyle mr10 gift-card-logo">
        <i aria-hidden="true" class="icon-pay-gift-card giftcard-avatar"></i>
      </span>
      <div class="d-block textellipsis">
        <div class="pb-2 textellipsis d-flex align-items-center">
          <span class="font-weight-bold pr-2">{{giftCardDetails.CardNumber}}</span>
          <ng-container *ngIf="!isAgilysysGiftcard">
            <div class="card-status active whitecolor" *ngIf="isCardActive">{{captions.Active}}</div>
            <div class="card-status inactive whitecolor" *ngIf="!isCardActive">{{captions.Inactive}}</div>
          </ng-container>
        </div>
        <div class="textellipsis gift-card-holder">
          {{giftCardDetails.FirstName + ' ' + giftCardDetails.LastName }}</div>
      </div>
    </div>

    <div class="d-flex w-50">
      <div class="card-section mr-4">
        <label class="form-label-normal d-block">{{captions.AvailableBalance}}</label>
        <div class="mb-3 font-weight-bold">{{giftCardDetails.AvailableAmount  | Currency}}</div>
        <ng-container *ngIf="!isAgilysysGiftcard">
          <label class="form-label-normal d-block">{{captions.ExpiryDate}}</label>
          <div class="mb-3" >{{giftCardDetails.ExpiryDate}}</div>
        </ng-container>        
        <label class="form-label-normal d-block">{{captions.Vendor}}</label>
        <div class="mb-3"> {{VendorName}}</div>
      </div>

      <div class="reload-section">
        <mat-form-field [floatLabel]="floatLabel">
          <input [attr.automationId]="'Txt_giftCardDetails_reloadAmount'" matInput [placeholder]="reloadCaption" inputtype="nonnegative, decimal" autocomplete="false"
            formControlName="reloadamount">          
        </mat-form-field>

        <div class="d-flex" *ngIf="!isAgilysysGiftcard">
          <div class="w-50">
            <label class="form-label-normal d-block">{{captions.ExtendExpiryDate}}</label>
            <app-retail-toggle-switch [attr.automationId]="'Tog_giftCardDetails_extendExpiryDate'" [formControl]="cardReloadFormGroup?.controls['extendexpirydate']"
            (changeToggleEvent)="OnExtendExpiryDateToggle($event)" [(disableToggle)]="cardExpiryDate == captions.NA" ></app-retail-toggle-switch>
          </div>
          <div class="w-50" [ngClass]="cardReloadFormGroup?.controls['extendexpirydate'].value ? '' : 'button_invalid'">
            <label class="form-label-normal d-block">{{captions.ExpiryDate}}</label>
            <div class="mb-3">{{cardExpiryDate}}</div>
          </div>
        </div>

        <div class="mt-3">
          <button [attr.automationId]="'Btn_giftCardDetails_addAmount'" mat-raised-button class="secondary-default-btn mr-3 action-btn"
            (click)="AddAmountForSelectedcard()">{{captions.Add}}</button>
          <a [attr.automationId]="'Btn_giftCardDetails_resetAmount'" class="spa-tertiary-btn-default" (click)="ResetAmountForSelectedcard()">{{captions.Reset}}</a>
        </div>
      </div>
    </div>


    <div class="redemption-history-section mt-4 w-50">
      <div class="d-flex">
        <label class="form-label-primary">{{captions.RedemptionHistory}}</label>
        <i [attr.automationId]="'Icn_giftCardDetails_redeemHistory'" aria-hidden="true" class="icon-filter ml-auto" *ngIf="redeemHistoryYearData.size > 0" [popoverOnHover]="false" [popover]="redeemhistoryfilter"> </i>
        <popover-content #redeemhistoryfilter placement="bottom" class="redeem-history"
          [closeOnMouseOutside]="true">
            <div *ngFor="let year of redeemHistoryYearData; let i=index">
              <mat-checkbox attr.automationId='Chk_giftCardDetails_redeemHistoryYear_{{i}}' class="mr-2" (change)="filterRedemtionData($event, year)">{{year}}</mat-checkbox>
            </div>
        </popover-content>
      </div>
      <div class="mh150">
        <app-shop-tablevirtualscroll [headerOption]="headerOption" [bodyContentData]="filteredData"
          [tableOptions]="tableOptions">
        </app-shop-tablevirtualscroll>
      </div>
    </div>
  </ng-scrollbar>
</section>
