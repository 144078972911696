import { AfterViewInit, Directive, ElementRef, Input, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[tooltipEnabler]'
})
export class ToolTipEnablerDirective {
  constructor(private elementRef: ElementRef) { }

  @HostListener('mouseenter', ['$event']) onmouseover(event) {
      let node = this.elementRef.nativeElement.querySelector('.mat-form-field-label-wrapper');
    if(this.elementRef.nativeElement && node && this.elementRef.nativeElement.tooltipEnabler){
        node.setAttribute('title', this.elementRef.nativeElement.tooltipEnabler);
        node.style.pointerEvents = 'unset'; 
    }

  }
}