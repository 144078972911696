<section class="gift-cards-creation-form">
  <form class="container-section" [formGroup]="giftCardsFormGroup">
    <ng-scrollbar >
      <div class="mb-4" *ngIf="giftCardConfig[0].isTopUp || giftCardConfig[0].isFromBalanceScreen">
        <mat-form-field [floatLabel]="floatLabel">
          <mat-select [attr.automationId]="'Dd_retailGiftCardsForm_outlet'" formControlName="outlet" placeholder="{{localization.captions.shop.Outlet}}"
            (selectionChange)="outletChange($event)" required>
            <mat-option *ngFor="let option of Outlets; let i=index" [value]="option.subPropertyID">
              {{option.subPropertyName}}
            </mat-option>
          </mat-select>
          <mat-error class="">
            {{localization.captions.retailsetup.MissingOutlet}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="mb-4  ">
        <label class="form-label-normal d-block">{{giftCardCaptions.ThirdParty}}</label>
        <app-retail-toggle-switch [attr.automationId]="'Tog_retailGiftCardsForm_thirdParty'" [formControl]="giftCardsFormGroup?.controls['thirdparty']"></app-retail-toggle-switch>
      </div>

      <app-gift-cards-search [attr.automationId]="'Srch_retailGiftCardsForm_giftCardSearch'" [screenType]="SearchScreen.IssuePopup"
        [isThirdPartyGiftcard]="giftCardsFormGroup?.controls['thirdparty'].value" [outletId]="currentOutetId"
        (giftCardSearchResult)="HandleGiftCardData($event)" [isTopUp]="giftCardConfig[0].isTopUp"
        [inputCardNumber]="this.giftCardConfig[0].GiftCardNumber"></app-gift-cards-search>

      <div class="mb-4">
        <mat-form-field class="mr-4 w-60" [floatLabel]="floatLabel">
          <input matInput [attr.automationId]="'Txt_retailGiftCardsForm_enterAmount'" [placeholder]="giftCardCaptions.EnterAmount" CurrencyFormatter #giftcardAmt
            inputtype="nonnegative,decimal,roundoff2" (blur)="setAmountFieldsValidators()" formControlName="Amount"
            required>
        </mat-form-field>
      </div>

      <div class="d-flex mb-4" *ngIf="!isAgilysysGiftcard">
        <div class="w-25">
          <label class="form-label-normal d-block">{{giftCardCaptions.NeverExpire}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_retailGiftCardsForm_neverExpire'" [formControl]="giftCardsFormGroup?.controls['NeverExpire']"
            (changeToggleEvent)=OnNeverExpireToggle($event)></app-retail-toggle-switch>
        </div>
        <div class="w-75" *ngIf="!giftCardsFormGroup?.controls['NeverExpire'].value">
          <mat-form-field appearance="legacy" [floatLabel]="floatLabel" class="date-picker-width">
            <mat-label>{{giftCardCaptions.ExpiryDate}}</mat-label>
            <input matInput [attr.automationId]="'Txt_retailGiftCardsForm_expiryDate'" dateRestricter [matDatepicker]="picker" [placeholder]="placeHolderFormat"
              formControlName="ExpiryDate" [min]="startDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="giftCardsFormGroup.controls['ExpiryDate'].hasError('matDatepickerParse')">{{captionsLocalization.dateFormat}}</mat-error>
            <mat-error
              *ngIf="giftCardsFormGroup.controls['ExpiryDate'].hasError('required') && !giftCardsFormGroup.controls['ExpiryDate'].hasError('matDatepickerParse')">{{captionsLocalization.Missing}}
              {{giftCardCaptions.ExpiryDate}}</mat-error>
            <mat-error
              *ngIf="giftCardsFormGroup.controls['ExpiryDate'].hasError('matDatepickerMin')">{{captionsLocalization.minimum}}
              {{giftCardCaptions.ExpiryDate}}</mat-error>
            <mat-error
              *ngIf="giftCardsFormGroup.controls['ExpiryDate'].hasError('matDatepickerMax')">{{captionsLocalization.maximum}}
              {{giftCardCaptions.ExpiryDate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div [class.alignSingleRow]="AllowNameSearch">
        <div class="mb-4 w49">
          <mat-form-field class="w-100" [floatLabel]="floatLabel">
            <input matInput [attr.automationId]="'Txt_retailGiftCardsForm_firstName'" [placeholder]="giftCardCaptions.FirstNameOptional" formControlName="FirstName" [maxlength]="256">
          </mat-form-field>
        </div>
        <div class="w49">
          <mat-form-field class="w-100" [floatLabel]="floatLabel">
            <input matInput [attr.automationId]="'Txt_retailGiftCardsForm_lastName'" [placeholder]="giftCardCaptions.LastNameOptional" formControlName="LastName" [maxlength]="256">
          </mat-form-field>
        </div>
      </div>
      <ng-container *ngIf="AllowNameSearch">
        <div class="disclaimer"><i class="icon-info-icon"></i>{{giftCardCaptions.EmailPhoneNoRequiredDisclaimer}}</div>
        <div class="d-flex">
          <div class="email-field">
            <mat-form-field class="email-input" [floatLabel]="floatLabel">
              <input [attr.automationId]="'Txt_personalInformation_emailId'" matInput name="email" [maxlength]="75"
                inputtype="email" id="EmailInput" formControlName="EmailId" (blur)="setEmailPhoneValidators('EmailId',giftCardsFormGroup.controls['EmailId'].value)"
                [placeholder]="giftCardCaptions.GiftcardAdvancedSearchPopup.lbl_emailId">
              <mat-error class="mb-1"
                *ngIf="!giftCardsFormGroup.controls['EmailId'].valid && giftCardsFormGroup.controls['EmailId'].touched && giftCardsFormGroup.controls['EmailId'].value">
                {{localization.captions.retailsetup.InvalidEmailFormat}}
              </mat-error>
              <mat-error class="mb-1"
                *ngIf="!giftCardsFormGroup.controls['EmailId'].valid && giftCardsFormGroup.controls['EmailId'].touched && !giftCardsFormGroup.controls['EmailId'].value">
                {{localization.captions.retailsetup.MissingEmail}}
              </mat-error>
            </mat-form-field>
          </div>
          <span class="swipe-option-text">({{giftCardCaptions.OR}})</span>
          <div class="phoneNumber-field">
            <mat-form-field class="mr-2 LW14 countryCode" [floatLabel]="floatLabel">
              <span *ngIf="CountryCodeRef.value" class="position-absolute">+</span>
              <input [attr.automationId]="'Txt_personalInformation_countryCode'" matInput name="countryCode"
                [ngClass]="{'countryCodeInput': CountryCodeRef.value}" #CountryCodeRef [maxlength]="6"
                formControlName="countryCode"
                [placeholder]="giftCardCaptions.GiftcardAdvancedSearchPopup.lbl_countryCode">
              <mat-error class="mb-1"
                *ngIf="giftCardsFormGroup.controls['countryCode'].touched && !giftCardsFormGroup.controls['countryCode'].valid && !giftCardsFormGroup.controls['countryCode'].value">
                {{commonCaptions.Missing}} {{commonCaptions.countryCode}}
              </mat-error>
            </mat-form-field>
            <mat-form-field class=" LW14 phoneNumber-input" [floatLabel]="floatLabel">
              <input [attr.automationId]="'Txt_personalInformation_phoneNo'" matInput name="PhoneNumber" (blur)="setEmailPhoneValidators('PhoneNumber',giftCardsFormGroup.controls['PhoneNumber'].value)"
                [maxlength]="30" textmask="{{textmaskFormat}}" id="PhoneNumber" formControlName="PhoneNumber"
                [placeholder]="giftCardCaptions.GiftcardAdvancedSearchPopup.lbl_phoneNumber">
              <mat-error class="mb-1"
                *ngIf="giftCardsFormGroup.controls['PhoneNumber'].touched && !giftCardsFormGroup.controls['PhoneNumber'].valid && !giftCardsFormGroup.controls['PhoneNumber'].value">
                {{localization.captions.retailsetup.InvalidPhoneNumber}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </ng-scrollbar>
  </form>
  <mat-dialog-actions class="actions-section">
    <button mat-button [attr.automationId]="'Btn_retailGiftCardsForm_saveGiftCardDetails'" class="body-bgcolor whitecolor text-capital" (click)="SaveGiftCardDetails()"
      [ngClass]=" EnableBtn ? 'button_valid' : 'button_invalid'"
      [disabled]="!giftCardsFormGroup?.valid && !giftCardsFormGroup?.dirty"> {{captions.Add}}</button>
    <button mat-button [attr.automationId]="'Btn_retailGiftCardsForm_cancel'" class="LW14 ml-2 text-capital" (click)="OnCancel()">{{captions.Cancel}}</button>
  </mat-dialog-actions>
</section>