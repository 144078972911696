import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormCheckboxComponent implements OnInit {

  config: FieldConfig;
  group: UntypedFormGroup;
  constructor() { }

  ngOnInit() {
  }

  checkBoxChange(event: any): void {
    if (this.config.cellClick) {
      this.config.cellClick(event);
    }
  }

  onClickHyperLink(event){
    if(this.config.addHyperlink){
      event.stopPropagation();
      event.preventDefault();
        this.config.hyperLinkClicked(event);
    }
  }

  onIconClick(data,event){
    if(this.config.icon){
      this.config.onClick({data:data,event:event});
    }
  }
}
