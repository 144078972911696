import { EventEmitter, Injectable } from '@angular/core';
import { EformConfig, Product } from 'src/app/common/Models/common.models';
import { InternalIntegrationCommunication } from '../communication/services/internal-intergration-service';
import { CommonApiRoutes } from "../common-route";
import { Localization } from 'src/app/common/localization/localization';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DmTokenInfo, DmViewModel, DmIndexModel } from '../Models/data-magine.model';
import { GetEforms } from '../data-magine/data-magine-integration/data-magine-models';

@Injectable({
  providedIn: 'root'
})

export class EformConfigDataService {

  constructor(private v1InternalIntegration: InternalIntegrationCommunication, private _localization: Localization,
    private httpclient: HttpClient
  ) {
  }

  public async getAgilysysEformConfig(): Promise<EformConfig> {
    let productId = parseInt(this._localization.GetPropertyInfo('ProductId'));
    switch (productId) {
      case Product.PMS:
        const result = this.v1InternalIntegration.getPromise<EformConfig>(
          { route: CommonApiRoutes.GetEformConfig }, false);
        return result;
      default:
        return null;
    }
  }

  public async createAgilysysEformConfig(body: EformConfig): Promise<EformConfig> {
    let productId = parseInt(this._localization.GetPropertyInfo('ProductId'));
    switch (productId) {
      case Product.PMS:
        const result = this.v1InternalIntegration.postPromise<EformConfig>(
          { route: CommonApiRoutes.CreateEformConfig, body }, true);
        return result;
      default:
        return null;
    }


  }
  public async updateAgilysysEformConfig(body: EformConfig): Promise<EformConfig> {
    let productId = parseInt(this._localization.GetPropertyInfo('ProductId'));
    switch (productId) {
      case Product.PMS:
        const result = this.v1InternalIntegration.putPromise<EformConfig>(
          { route: CommonApiRoutes.UpdateEformConfig, body }, false);
        return result;
      default:
        return null;
    }
  }

  async getEformTokenInfo(url: string, userName: string, password: string): Promise<DmTokenInfo> {
    var res = await this.httpclient.post<DmTokenInfo>(url + CommonApiRoutes.DataMagineAuthLogin, { username: userName, password: password }).toPromise();
    if (res && res.StatusCode == 200 && res.Result && res.Result.access_token) {
        localStorage.setItem("eform_token", "Bearer " + res.Result.access_token);
    }
    return res;
  }

  public async getEformList(token: string, systemURL: string, tenantID: string, propertyID: string) {
    let header = new HttpHeaders({
        "x-api-version": "2.0",
        "Authorization": "Bearer"+" "+token
    });
    let url: string = systemURL + CommonApiRoutes.GetEformList;
    url = url.replace("{0}", tenantID).replace("{1}",propertyID);


    var res = await this.httpclient.get<GetEforms>(url, { headers: header }).toPromise<GetEforms>();
    return res;
  }

  public async getformsLinkURL(formID: string, token: string, systemURL: string, stayDetail: {confirmationNumber: string; stayID: string, isWebcheckIn: boolean} ) {
    let header = new HttpHeaders({
        "x-api-version": "2.0",
        "Authorization": "Bearer"+" "+token
    });
    let url: string = systemURL + CommonApiRoutes.GetEformLink;

    if(stayDetail.confirmationNumber && stayDetail.stayID) {
        url = `${url}?EFormId=${formID}&QueryParameters=encryptedConfirmation=${stayDetail.confirmationNumber}&isWebCheckin=${stayDetail.isWebcheckIn}&Resid=${stayDetail.stayID}`;
    } else {
        url = `${url}?EFormId=${formID}`;
    }
    var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
    return res;
  }

  public async searchEformDoc(baseUrl: string, body) {
    let url = baseUrl + CommonApiRoutes.SearchEformDoc;
    let header = new HttpHeaders({
        "Authorization": localStorage.getItem("eform_token"),
        "x-api-version": "2",
    });
    var res = await this.httpclient.post(url, body, { headers: header }).toPromise<any>();
    return res;
  }

  async exportEformDoc(baseUrl: string, id: string, exportInOriginalFormat: boolean) {
    let url = baseUrl + CommonApiRoutes.ExportEformDocs.replace("{ids}", id).replace("{exportInOriginalFormat}", exportInOriginalFormat.toString());
    let header = new HttpHeaders({
        "Authorization": localStorage.getItem("eform_token"),
        "x-api-version": "2",
    });

    let res = await Promise.all([this.httpclient.get(url, { headers: header, responseType: 'blob' as 'json' }).toPromise().then(
        (response: any) => {
            return response;
        }
    )]);
    return res[0];
  }

  async getAllEformViews(authUrl: string, systemUrl: string, userName: string, password: string): Promise<DmViewModel[]> {
    if (localStorage.getItem("eform_token") == null) {
        let tkn = await this.getEformTokenInfo(authUrl, userName, password);
        localStorage.setItem("eform_token", tkn.Result.access_token);
    }
    let url = systemUrl + CommonApiRoutes.GetAllEformViews;
    let header = new HttpHeaders({
        "Authorization": localStorage.getItem("eform_token"),
        "x-api-version": "2",
    });

    var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
    return res.result;
  }

  async getEformViewByViewName(baseUrl: string, viewName: string) {
    let url = baseUrl + CommonApiRoutes.GetEformViewByViewName.replace("{viewName}", viewName);
    let header = new HttpHeaders({
        "Authorization": localStorage.getItem("eform_token"),
        "x-api-version": "2",
    });

    var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
    return res.result;
  }

  async getAllEformIndexesByView(authUrl: string, systemUrl: string, viewName: string, userName: string, password: string): Promise<DmIndexModel[]> {
    if (localStorage.getItem("eform_token") == null) {
        let tkn = await this.getEformTokenInfo(authUrl, userName, password);
        localStorage.setItem("eform_token", tkn.Result.access_token);
    }
    let url = systemUrl + CommonApiRoutes.GetEformIndexesByViewName.replace("{viewName}", viewName);
    let header = new HttpHeaders({
        "Authorization": localStorage.getItem("eform_token"),
        // "x-api-version": "2",
    });

    var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
    return res.Result;
}
}
