import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-consent-message-popup',
  templateUrl: './consent-message-popup.component.html',
  styleUrls: ['./consent-message-popup.component.scss']
})
export class ConsentMessagePopupComponent implements OnInit {

  @Input() data: any;
  validationMessage: any[];
  dialogTitle: string;
  showAlert: boolean;

  constructor() { }

  ngOnInit(): void {
    this.validationMessage = this.data[0].validationListMessage;
    this.dialogTitle = this.data[0].dialogTitle;
    this.showAlert = this.data[0].showAlert;
  }

}
