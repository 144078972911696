import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'gridCellDataManipulator'
})
export class GridCellDataManipulatorPipe implements PipeTransform {

  constructor(private translate: TranslateService){

  }

  transform(value: any, type: GridDataType, isColumn: boolean = false, skipMassaging: boolean = false) {
    return this.getData(value, type, skipMassaging);
  }

  getData(value: string, gridDataType: GridDataType, skipMessaging: boolean) {
    switch (gridDataType) {
      case GridDataType.FreeTableByPartySize:
        if (skipMessaging) return value;
        return Number(value) == 0 ? "-" : value;
      case GridDataType.WaitTimeByPartySize:
        if (value != null && value != undefined && Number(value) != -1)
          return value + this.translate.instant("minsShortText");
        return "-";
      case GridDataType.AvailabilityByPartySize:
        if (value) return value.substring(0, value.indexOf("_"));
        return value;
      default:
        return value;
    }
  }
}

export enum GridDataType {
  FreeTableByPartySize,
  WaitTimeByPartySize,
  AvailabilityByPartySize
}
