import { Pipe, PipeTransform } from '@angular/core';
import { SettingsDTO } from '../models/RestaurantDTO';

@Pipe({
  name: 'partyStatusColor'
})
export class PartyStatusColorPipe implements PipeTransform {
  constructor() {
  }

  transform(party: any, settingsData: SettingsDTO, statusId?: number): any {
    if (party && party.StatusId) {
      const statusColor = settingsData.Statuses.filter(status => status.Id == party.StatusId)[0].Color;
      let fillColor = 'rgba(' + statusColor.R + ',' + statusColor.G + ',' + statusColor.B + ',' + statusColor.A + ')';
      return fillColor
    }

  }


}


