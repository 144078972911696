import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { SPAConfig } from '../../common/config/SPA-config';
import { ReportSelector, ReportControlConfig, DropDownData, CustomFields, ReportUIConfig, ReportAPIOptions, JasperReportDetails } from '../business/report.modals';
import { ReportControlBuilder } from '../business/reportControlBuilder';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { Host, Product } from '../../shared/globalsContant';
import { BaseResponse } from '../../shared/business/shared.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { ReplaySubject, Subscription } from 'rxjs';
import { BaseReport } from '../business/common/base-report';
import { ReportFactory } from '../business/common/report-factory';
import { ReportDataService } from '../data/report-data.services';
import { ReportBusinessService } from '../business/report-business.service';
import { RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { routingMenuService } from 'src/app/common/components/menu/menu.service';
import { AgDateConfig, AgTimeConfig, DropdownOptions } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { AllReports } from 'src/app/common/shared/retail.modals';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { PropertyService } from 'src/app/common/services/property.service';
import { stubTrue } from 'lodash';
import { PropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import { isUndefined } from 'util';
@Component({
  selector: 'app-retail-reportcontrols',
  templateUrl: './base-report.component.html',
  styleUrls: ['./base-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ReportDataService, ReportBusinessService, RetailFunctionalityBusiness]

})
export class RetailReportControl implements OnInit {

  @Input() reportGroup: string ;
  @Output() clickSave: EventEmitter<any> = new EventEmitter<any>();
  editableValue: any;
  // editableparametersflag : any;
  // editableparametersValue: any;
  AllowButton: boolean = true;
  retailReportDesc : any;
  disableReportName : boolean;
  isJasperReportEnabled: boolean = false;
	AllowJasperReport : boolean = false;
	propertyConfig : any;
	jasperReportDetails :JasperReportDetails

  @Input() edit: boolean;
  @Input('editValues') 
	set patchVal(value){
		if(value){
			this.editableValue = value.filters;    
      // this.editableparametersflag = value.params && value.params.length ? value?.params?.filter(x => x['PatchEditParam'])[0] 
      //                               : value.params?.PatchEditParam;
      // this.editableparametersValue = value?.params;
      this.retailReportDesc = value.reportName;
      if(value.filters)
      {
				this.AllowButton = false;
        this.disableReportName = true;
      }		
		}else{
      this.AllowButton = true;
      this.disableReportName = false;		
    }
	}
  private reportBuilder: ReportControlBuilder;
  reportSelector: ReportSelector[] = [];
  dropDownFilterData: DropDownData[] = []; 
  showDate = true;
  showEndDate = true;
  activeReportCode: string;
  activeReportConfigs: ReportControlConfig;
  activeReportLayout: string;
  reportsinput: UntypedFormGroup;
  drowDownFilterName = '';  
  captions: any;
  timelineOptions: DropdownOptions[];
  commonCaptions: any;
  reportName: any[];
  reportNameChild: any[] = [];
  outletOptions: any[] = [];
  locations: any[];
  staffMember: any[];
  colorCode: any[];
  reportNames: any[];
  reportNameData: any[];
  inActive = false;
  inActiveText: string;
  showHourlyRate = false;
  hideCorrectedValues = false;
  includeInhouseGratuitySC: boolean = true;
  includeInhouseGratuity: boolean = false;
  includeInhouseSC: boolean = true;
  radioSelectionSCList: { id: number; name: string; }[];
  radioSelectionGRList: { id: number; name: string; }[];
  radioSelectionGSCList: { id: number; name: string; }[];
  pageBreak = false;
  status = false;
  selectedReportConfig: ReportControlConfig;
  showFilterDropDown: boolean;    
  showHelperText =false;
  customFieldsValues: CustomFields[];
  Clients: any[] = [];
  SearchedClients: any[] = [];
  selectedClient = '';
  minEndDate: Date;
  maxDate: Date;
  infiniteDate: Date = new Date(8640000000000000);
  disableGenerateBtn: boolean;
  showMissingClientError: boolean;
  reportParams: ReportAPIOptions;
  validSelection: boolean = true;
  validSelectionErrMsg: string = '';
  textInputFilterValid: boolean = true;
  functionalities: { [key: string]: boolean } = {};
  //custom report filter region variables
  customReport: string;
  ShowOutletSelectionFieldInTopSpendersReport: boolean;
  ShowOutletSelectionFieldInInventoryAuditTrailReport: boolean;
  report: BaseReport;
  @ViewChild('reportControl') asideArrowElement: ElementRef;
  valuesSelected: any[] = [];
  reportSubscription: Subscription;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  selectedDropdownType: any;
  showTime = false;
  activeToggle: boolean;
  placeholderFormat: string;
  pleaeSelectoption = 'Please Select any';
  defaultOutlet: number;
  defaultMinDate = new Date('01/01/1900');
  routePathValue:any;
  startDateInputs: AgDateConfig;
  endDateInputs: AgDateConfig;
  invalidEndDate: boolean = false;
  invalidStartDate: boolean = false;
  floatLabel: string;
  timelineFrom:number | string;
  timelineTo:number | string;
  timelineCountFrom = 0;
  timelineCountTo = 0;
  isFromJobScheduler : boolean = false;
  @Input() hideSaveBtn?: boolean = true;
  reportCode: string;
  selectedData;
  productId: number;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  startTimeInput: AgTimeConfig;
  endTimeInput: AgTimeConfig;
  constructor(private spaConfig: SPAConfig, private fb: UntypedFormBuilder, private http: HttpServiceCall, public localization: RetailLocalization, private PropertyInfo: RetailPropertyInformation, private breakpoint: BreakPointAccess, private utils: RetailUtilities, private data: ReportDataService
    , public business: ReportBusinessService,private _cdr: ChangeDetectorRef, public retailFeature: RetailFeatureFlagInformationService,private func: RetailFunctionalityBusiness,private routingService: routingMenuService,
    private localize: Localization, private router: Router, private propertyService: PropertyService, private propertySettingsDataService: PropertySettingDataService) {
    PropertyInfo.SetDefaultDataOnLoad(false);
    this.reportBuilder = new ReportControlBuilder(this.localization, this.utils, retailFeature,PropertyInfo);
    this.floatLabel = this.localization.setFloatLabel;
    this.productId = parseInt(this.localize.GetPropertyInfo('ProductId'));
      this.router.events.pipe( 
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      ).subscribe(() => {
        this.routeToreport();
      });
      this.propertyConfig = this.PropertyInfo.GetPropertyConfiguration();
      if(this.propertyConfig!=null && this.propertyConfig['EnableJasperReports']!=null)
      {
        this.isJasperReportEnabled = String(JSON.parse(sessionStorage.propConfig).EnableJasperReports).trim().toLowerCase() === "true" ? true : false;
      }
  }
  async ngOnInit() {
    this.captions = this.localize.captions.reports;
    this.commonCaptions = this.localize.captions.common;
    this.isFromJobScheduler = this.localize.isFromJobScheduler;
    this.placeholderFormat = this.localization.inputDateFormat;
    this.defaultOutlet = this.PropertyInfo.GetDefaultOutlet();
    this.radioSelectionSCList = [{id: 0, name: this.captions.InHouseBoth}, {id: 1, name: this.captions.InHouseStaffOnly}, {id: 2, name: this.captions.InHouseSCOnly}];
    this.radioSelectionGRList = [{id: 0, name: this.captions.InHouseBoth}, {id: 1, name: this.captions.InHouseStaffOnly}, {id: 2, name: this.captions.InHouseGratuityOnly}];
    this.radioSelectionGSCList = [{id: 0, name: this.captions.InHouseBoth}, {id: 1, name: this.captions.InHouseStaffOnly}, {id: 2, name: this.captions.InHouseGratuitySCOnly}];
    this.reportsinput = this.fb.group({
      reportName: '',
      // startTime: '12:00 am',
      startTime : this.localize.getTime(this.localize.TimeToDate('12:00 am'), this.localize.getTimeFormat()),
      endTime : this.localize.getTime(this.localize.TimeToDate('11:59 pm'), this.localize.getTimeFormat()),
      // endTime: '11:59 pm',
      startDate: [this.PropertyInfo.CurrentDate],
      endDate: [this.PropertyInfo.CurrentDate],
      includeInactiveusers: false,
      hideCorrectedValuesToggle:false,
      includeInhouseGratuitySCToggle:true,
      includeInhouseGratuityToggle:false,
      includeInhouseSCToggle:false,
      pageBreakToggle: false,
      includeHourlyRate: false,
      reportNameChild: '',
      outletOptions: '',      
      clientTobeSelected: [''],
      customFilterFormGrp: new UntypedFormGroup({}),
      reportTypeSCSelection: 0,
      reportTypeGRSelection: 0,
      reportTypeGSCSelection: 0
    });
    if(this.isFromJobScheduler){
      this.reportsinput.addControl('timelineFrom', new UntypedFormControl());
      this.reportsinput.addControl('timelineCountFrom', new UntypedFormControl());
      this.reportsinput.addControl('timelineTo', new UntypedFormControl());
      this.reportsinput.addControl('timelineCountTo', new UntypedFormControl());
      this.setSchedulercontrols();
    }
    this.reportSelector = this.reportBuilder.reportSelector;
    var reportselector = this.reportSelector.filter(rs =>rs.group == this.reportGroup);
    this.reportSelector = reportselector;
    if(this.reportSelector[0]?.enableJasperReports && this.isJasperReportEnabled)			
				this.AllowJasperReport = true;	 

    this.spaConfig.load();
    // this.minEndDate = this.PropertyInfo.CurrentDate;
    this.startDateInputs = {
      className: 'ag_form-control--md ag_pr--0',
      form: this.reportsinput,
      formControlName: 'startDate',
      placeHolderId: 'StartDate',
      placeHolder: this.commonCaptions.StartDate
    }
    this.endDateInputs = {
      className: 'ag_form-control--md ag_pr--0',
      form: this.reportsinput,
      formControlName: 'endDate',
      placeHolderId: 'EndDate',
      placeHolder: this.commonCaptions.EndDate
    };
    this.startTimeInput = {
      form: this.reportsinput,
      formControlName: 'startTime',
      placeHolder: this.localization.captions.common.StartTime,
      placeHolderId: 'lbl_time',
      className: '',
      // isTimeRequired: true
    }
    this.endTimeInput = {
      form: this.reportsinput,
      formControlName: 'endTime',
      placeHolder: this.localization.captions.common.EndTime,
      placeHolderId: 'lbl_time',
      className: '',
      // minTime: this.reportsinput.controls.startTime.value
      // isTimeRequired: true
    }
    this.reportSelector = this.business.applyBreakPoints(this.reportSelector);
    this.functionalities = await this.func.getRetailFunctionality();
    if (!this.functionalities?.ShowOutletSelectionFieldInReturnedItemsReport && this.defaultOutlet === 0) {
      this.func.getDefaultOutlet().then(data => {
        this.defaultOutlet = data;
        if (this.defaultOutlet > 0) {
          this.PropertyInfo.SetDefaultOutlet(this.defaultOutlet);
        }
      });
    }
    this.routePathValue = this.routingService.selectedRoutingMenu;
    this.setOnPageLoadReport();
    this.startDateInputs.minDate = new Date('01/01/1900');
    this.startDateInputs = {...this.startDateInputs};
    this.endDateInputs.minDate = new Date(this.PropertyInfo.CurrentDate);
    this.endDateInputs = {...this.endDateInputs};
    if(this.reportGroup == 'POS') {
      this.retailFeature.GetMiscConfig().then( allowSurcharge => {
        if(!allowSurcharge) {
          this.reportSelector = this.reportSelector.filter(x => x.code != 'Surcharges');
        }
      })
    }
  }

  async routeToreport(){
    this.reportSelector = this.reportBuilder.reportSelector;
    var reportselector = this.reportSelector.filter(rs =>rs.group == this.reportGroup);
    this.reportSelector = reportselector;
    this.reportSelector = this.business.applyBreakPoints(this.reportSelector);
    this.functionalities = await this.func.getRetailFunctionality();
    if (!this.functionalities?.ShowOutletSelectionFieldInReturnedItemsReport && this.defaultOutlet === 0) {
      this.func.getDefaultOutlet().then(data => {
        this.defaultOutlet = data;
        if (this.defaultOutlet > 0) {
          this.PropertyInfo.SetDefaultOutlet(this.defaultOutlet);
        }
      });
    }
    this.routePathValue = this.routingService.selectedRoutingMenu;
  }

  ngDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
  setSchedulercontrols()
  {
    this.timelineOptions = this.localize.scheduleJobWildCards;
    this.reportsinput.controls['timelineFrom'].setValue(this.localize.scheduleJobWildCards[0].id);
    this.reportsinput.controls['timelineTo'].setValue(this.localize.scheduleJobWildCards[0].id);
    this.reportsinput.controls['timelineCountFrom'].setValue(0);
    this.reportsinput.controls['timelineCountTo'].setValue(0);  
  }
  ngOnDestroy() {
    if (this.reportSubscription) {
      this.reportSubscription.unsubscribe();
    }
  }


  //event from fast report component for disabling generate button when API call is in progress
  disableGenerateButtonEvent(IsEnable: boolean): void {
    this.disableGenerateBtn = IsEnable;
  }

  accessCheckAndAlert(reportSelected: ReportSelector): void {
    if (reportSelected?.IsAuthorized == false) {
        //this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[reportSelected.breakPointNumber]);
    }
  }

  OnFromDateValueChange(e: any): void {
    let startDate = this.reportsinput.controls.startDate.value;
    let endDate = this.reportsinput.controls.endDate.value;
    this.endDateInputs.minDate = new Date(startDate);
    this.endDateInputs = {...this.endDateInputs};
    this.reportsinput.controls.endDate.markAsUntouched;
    if(startDate == null)
    {
    this.startDateInputs.isDateRequired = true;
    this.startDateInputs.errorMessage = this.captions.startDateMissing;
    this.startDateInputs = {...this.startDateInputs};
    this.invalidEndDate = true;
    this.invalidStartDate = true;
    return;
    }
    if (startDate <= this.PropertyInfo.CurrentDate && startDate >= endDate) {
      this.reportsinput.controls.endDate.setValue(new Date(startDate));
    } 
    if((startDate > this.PropertyInfo.CurrentDate && endDate > this.PropertyInfo.CurrentDate) || (startDate <= this.PropertyInfo.CurrentDate && endDate > startDate))
    {
    this.invalidStartDate = true;
    this.invalidEndDate = true;
    }
    else
    {
    this.invalidStartDate = false;
    this.invalidEndDate = false;
    }
    // this.minEndDate = new Date(this.reportsinput.controls['startDate'].value);
  }

  onEndDateOpen(e){
    // this.minEndDate = new Date(this.reportsinput.controls['startDate'].value);
    this.endDateInputs.minDate = new Date(this.reportsinput.controls['startDate'].value);
    this.endDateInputs = {...this.endDateInputs};
    let startDate = this.reportsinput.controls.startDate.value;
    let endDate = this.reportsinput.controls.endDate.value;
    if(endDate == null)
    {
    this.endDateInputs.isDateRequired = true;
    this.endDateInputs.errorMessage = this.captions.endDateMissing;
    this.invalidEndDate = true;
    this.invalidStartDate = true;
    return;
    }
    if((startDate > this.PropertyInfo.CurrentDate && endDate > this.PropertyInfo.CurrentDate) || (startDate <= this.PropertyInfo.CurrentDate && endDate > startDate))
    {
    this.invalidEndDate = true;
    this.invalidStartDate = true;
  }
    else
    {
    this.invalidEndDate = false;
    this.invalidStartDate = false;
    }
  }

  setOnPageLoadReport(): void {
    let code: string;
    let initReport: ReportSelector;
    if(this.localize.isFromJobScheduler && this.edit)
    {
      // if(this.productId == Product.SPA){
      //   code = this.reportSelector.find(x => x.value === this.retailReportDesc).code
      // }else{
        code = this.reportSelector.find(x => x.code === this.retailReportDesc).code
      // }        
    } 
    else{
    var firstRetailReport;
    var value = this.routePathValue;
    if (this.reportGroup == 'APPOINTMENT') {
      code = 'AppointmentListing';
    } else if (this.reportGroup == 'CLIENT') {
      code = 'Itinerary';
      this.reportSubscription = this.reportsinput.controls['clientTobeSelected'].valueChanges.subscribe(result => {
        if (result.length > 2) {
          this.searchClient(result);
        }
      });
    } else if (this.reportGroup == 'POS') {
      firstRetailReport = this.reportSelector[0];
      code = firstRetailReport != null ? firstRetailReport.code : 'CommissionReport';
    } else if (this.reportGroup == 'RETAIL') {
      if (!this.localize.isFromJobScheduler) {
        if (this.routePathValue != undefined && this.routePathValue?.text != '' && this.routePathValue?.text != 'RETAIL') {
          firstRetailReport = this.reportSelector.find(x => x.value == value.text);
        } else {
          firstRetailReport = this.reportSelector.find(x => x.group === this.reportGroup);
        }
      }
      else
      {
      firstRetailReport = this.reportSelector[0];
      }
      code = firstRetailReport != null ? firstRetailReport.code : 'MultiPackItems';
    } else if (this.reportGroup == 'GIFTCARDS') {
      firstRetailReport = this.reportSelector[0];
      code = firstRetailReport != null ? firstRetailReport.code : 'GiftCardsRedeem';     
    }  else if (this.reportGroup == 'INVENTORYCONTROL') {
      code = 'PurchaseOrders'
    }else if (this.reportGroup == 'REVENUE') {
      code = 'Shift'
    }
  }
    this.reportCode = code;
    this.updateReportControls({ 'value': code });
    this.reportsinput.controls['reportName'].setValue(code);
    initReport = this.getActiveReport(code);
    this.accessCheckAndAlert(initReport);
  }
  getActiveReport(reportCode: string): ReportSelector {
    return this.reportSelector.filter(x => x.code === this.activeReportCode)[0];
  }


  asideArrow(e: any): void {
    this.status = !this.status;
  }

  searchClient(clientName?: string) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'clientSearch',
      method: HttpMethod.Get,
      uriParams: { name: encodeURIComponent(clientName) },
      showError: false,
      extraParams: []
    });
  }
  generateReport(e: any) {
    if (this.reportsinput.valid) {
      let activeReport: ReportSelector = this.getActiveReport(this.activeReportCode);
      if (activeReport && !activeReport.IsAuthorized) {
        this.accessCheckAndAlert(activeReport);
      }
      else {
        //validates drop down selected value
        if (this.showFilterDropDown && this.dropDownFilterData.length == 0) {
          let emptyDataMsg: string = this.localization.getError(-118);
          this.utils.ShowErrorMessage(this.localization.captions.common.Information, emptyDataMsg);
        } else {
          this.createReportAPIOptions();
        }
      }
    }
  }

	async loadReports()
	{		
    let activeReport: ReportSelector = this.getActiveReport(this.activeReportCode);
    let urlParameters: string = (activeReport.code == AllReports.CorrectVoid || activeReport.code == AllReports.StaffMemberSummary || activeReport.code == AllReports.SalesByCategory) ? "&StartDate=" + this.localization.convertDateObjToAPIdate(this.utils.PropertyInfo.CurrentDate) + "&EndDate=" + this.localization.convertDateObjToAPIdate(this.utils.PropertyInfo.CurrentDate) : "";
    urlParameters+= (activeReport.code == AllReports.CorrectVoid || activeReport.code == AllReports.InventoryOnHand || activeReport.code == AllReports.SalesByCategory)?"&currentUserId="+this.localization.GetsessionStorageValue('_userInfo', 'userId'):"";
    var jasper: JasperReportDetails = {
      reportCode: activeReport.code,
      isJaspersoftReport: this.AllowJasperReport,
      reportUrlPath: activeReport.reportUrlpath,
      reportUrlParameters: urlParameters
    };
		this.jasperReportDetails = jasper;
    const userRoleId = Number(this.utils.GetLocalStorageValue('_userInfo', 'roleId'));
    await this.propertyService.setJasperAttributes(userRoleId);
	}

  async generateReportUI(code: string) {
    const activeReportCode: string = code;
    try {
      let activeReport: ReportSelector = this.getActiveReport(activeReportCode);
      if(activeReport.enableJasperReports && this.isJasperReportEnabled)		
			this.AllowJasperReport = true;			
		else		
			this.AllowJasperReport = false;
      this.report = new ReportFactory(this.reportsinput, this.data, this.business, this.utils, this.PropertyInfo, this.func, this.localization,this.localize, this.retailFeature, this.propertySettingsDataService).Get(activeReportCode, this.editableValue);
      this.data.cancelOngoingCall();
      let config: ReportUIConfig = await this.report.getReportUIConfig();
      this.setUIControls(config, activeReport);
      this.PatchSchedulercontrols(config);
    } catch (error) {
      console.error(`Please Initialize ${code} Report`);
    }
  }

  private PatchSchedulercontrols(config: ReportUIConfig)
  {
    if(this.localize.isFromJobScheduler && this.edit)
   {
     let patchItem;
    
        patchItem =   this.editableValue;

        if(patchItem)
        {
            setTimeout(() => 
            {
              if(patchItem.fromWildCard != undefined)
                  this.reportsinput.controls.timelineFrom.patchValue(this.localize.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

              if(patchItem.fromIncrement != undefined)
                  this.reportsinput.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

              if(patchItem.toWildCard != undefined)
                  this.reportsinput.controls.timelineTo.patchValue(this.localize.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);

              if(patchItem.toIncrement != undefined)
                  this.reportsinput.controls.timelineCountTo.patchValue(patchItem.toIncrement);

              if (patchItem.startTime != undefined) 
                this.reportsinput.controls.startTime.patchValue(patchItem.startTime);
                  
              if (patchItem.endTime != undefined) 
                this.reportsinput.controls.endTime.patchValue(patchItem.endTime);
                  
              let PageBreak = patchItem.pageBreak != undefined ? patchItem.pageBreak : patchItem.pPageBreak;

              if (PageBreak != undefined) 
                this.reportsinput.controls.pageBreakToggle.patchValue(PageBreak);                                    

              if (patchItem.pIncludeInactiveUsers != undefined) 
                  this.reportsinput.controls.includeInactiveusers.patchValue(patchItem.pIncludeInactiveUsers); 

              if (patchItem.pHouseServiceChargePercentage != undefined || patchItem.pHouseGratuityPercentage != undefined) 
                  this.reportsinput.controls.includeInhouseGratuitySCToggle.patchValue(false); 

              if (patchItem.pHouseServiceChargePercentage != undefined) 
                this.reportsinput.controls.includeInhouseSCToggle.patchValue(false); 

              if (patchItem.pHouseGratuityPercentage != undefined) 
                this.reportsinput.controls.includeInhouseGratuityToggle.patchValue(false); 

               if (patchItem.pIncludeZeroCommSCGrat != undefined) 
                this.reportsinput.controls?.customFilterFormGrp?.get('includeZeroValue').patchValue(patchItem.pIncludeZeroCommSCGrat); 

              if(patchItem.OutletIds != undefined){

                config.dropDownFilters.then(res => {
                  let arr = res.filter((item) => {
                      return this.editableValue.OutletIds.find(x => x == item.id);
                  });
                  this.report.filterDataSource = arr;
                  this.reportsinput.controls['reportNameChild'].setValue(arr);   
                });        
              }  
              
              if(this.reportCode == AllReports.CommissionReport || this.reportCode == AllReports.ServiceCharge 
                || this.reportCode == AllReports.Gratuity || this.reportCode == AllReports.StaffMemberSummary  )            
              {
                
                let StaffIds = this.editableValue.staffs;
                if(StaffIds != undefined && StaffIds.length > 0)
                {
                  let arr = [];
                  config.dropDownFilters.then(res => {
                    this.report.filterDataSource = res;
                    arr = res.filter((item) => {
                        return StaffIds.find(x => x.staffId == item.id && x.staffType == item.staffType);
                    });

                    // this.dropDownFilterData = arr.map(data => {
                    //     return data = {
                    //       id: data.id, code: data.code, showInDropDown: true, description: data.description,
                    //       isActive: data.isActive, staffType: data.staffType
                    //     };
                    // });                  
                  
                    this.reportsinput.controls['reportNameChild'].setValue(arr);   
                    this.selectedData = arr;
                  });   
                }
              }

            }, 1);

        }   
    }
  }

  // private editParamsMapper() {
  //   if (this.editableparametersValue.length) {
  //     return {
  //       fromWildCard: this.editableparametersValue.filter(x => x['fromWildCard'])[0]['fromWildCard'],
  //       fromIncrement: this.editableparametersValue.filter(x => x['fromIncrement'])[0]['fromIncrement'],
  //       toWildCard: this.editableparametersValue.filter(x => x['toWildCard'])[0]['toWildCard'],
  //       toIncrement: this.editableparametersValue.filter(x => x['toIncrement'])[0]['toIncrement'],
  //       startTime: this.editableparametersValue.filter(x => x['pStartDate'])[0]['pStartDate'],
  //       endTime: this.editableparametersValue.filter(x => x['pEndDate'])[0]['pEndDate'],
  //       pIncludeInactiveUsers:this.editableparametersValue.filter(x => x['pIncludeInactiveUsers']).length>0?this.editableparametersValue.filter(x => x['pIncludeInactiveUsers'])[0]['pIncludeInactiveUsers']:false,
  //       pageBreak:this.editableparametersValue.filter(x => x['pPageBreak']).length>0?this.editableparametersValue.filter(x => x['pPageBreak'])[0]['pPageBreak']:false,
  //       pIncludeZeroCommSCGrat:this.editableparametersValue.filter(x => x['pIncludeZeroCommSCGrat']).length>0?this.editableparametersValue.filter(x => x['pIncludeZeroCommSCGrat'])[0]['pIncludeZeroCommSCGrat']:false
  //     }
  //   } else {
  //     return {
  //       fromWildCard: this.editableparametersValue.fromWildCard,
  //       fromIncrement: this.editableparametersValue.fromIncrement,
  //       toWildCard: this.editableparametersValue.toWildCard,
  //       toIncrement: this.editableparametersValue.toIncrement,
  //       startTime: this.editableparametersValue.pStartDate,
  //       endTime: this.editableparametersValue.pEndDate,
  //       pIncludeInactiveUsers:this.editableparametersValue.pIncludeInactiveUsers,
  //       pageBreak:this.editableparametersValue.pPageBreak,
  //       pIncludeZeroCommSCGrat:this.editableparametersValue?.pIncludeZeroCommSCGrat
  //     }
  //   }   
  // }
  private async setUIControls(config: ReportUIConfig, activeReport): Promise<void> {
    this.activeReportLayout = config.layout;
    this.inActive = config.inActiveToggle;
    this.pageBreak = config.pageBreakToggle;
    this.showDate = config.startDatePicker;
    this.showHourlyRate = config.includeHourlyRate; 
    this.hideCorrectedValues = config.hideCorrectedValues;
    this.includeInhouseGratuitySC = await config.includeInhouseGratuitySC;
    this.includeInhouseGratuity = await config.includeInhouseGratuity;
    this.includeInhouseSC = await config.includeInhouseSC;   
 

    if(this.reportGroup === 'INVENTORYCONTROL' && (this.customReport == 'InventoryVariance' || this.customReport == 'InventoryValuation') ){
      this.startDateInputs.placeHolder = this.commonCaptions.Date;
      this.showEndDate = false
    }
    else if(this.showDate){
      this.startDateInputs.placeHolder = this.commonCaptions.StartDate;
      this.showEndDate = true;
    }
    else {
      this.showEndDate = false;
    }
    this.showTime = config.timePicker;    
    this.showHelperText = config.showHelperText;
    this.inActiveText = config.inActiveToggleName ? config.inActiveToggleName : '';
    const tempDropDown = config.dropDownFilters ? true : false;
    this.showFilterDropDown = config.dropDownFilterName === this.localization.captions.reports.Outlets ? this.showOutletFilteredOptions(activeReport, config) : tempDropDown;
    this.drowDownFilterName = config.dropDownFilterName ? config.dropDownFilterName : '';
    this.dropDownFilterData = await config.dropDownFilters;   
    this.report.filterDataSource = this.dropDownFilterData;
    // this.maxDate = (typeof config.allowFutureDate !== 'undefined' && !config.allowFutureDate) ? this.PropertyInfo.CurrentDate : this.infiniteDate;
    this.startDateInputs.maxDate =  (typeof config.allowFutureDate !== 'undefined' && !config.allowFutureDate) ? this.PropertyInfo.CurrentDate : this.infiniteDate;
    this.endDateInputs.maxDate = (typeof config.allowFutureDate !== 'undefined' && !config.allowFutureDate) ? this.PropertyInfo.CurrentDate : this.infiniteDate;
    this.startDateInputs = {...this.startDateInputs};
    this.endDateInputs = {...this.endDateInputs};
    this.reportsinput.controls['outletOptions'].setValue(this.dropDownFilterData);    
    this.validateForm(this.reportsinput.valid);
  }

  showOutletFilteredOptions(code, config: ReportUIConfig): boolean {
    let showOutletField: boolean;
    switch (code) {
      case 'GiftCardsRedeem': showOutletField = this.functionalities.ShowOutletSelectionFieldInGiftCardRedeemReport ?? false; break;
      case 'GiftCardsIssued': showOutletField = this.functionalities.ShowOutletSelectionFieldInGiftIssuedReport ?? false; break;
      case 'ReturnedItems': showOutletField = this.functionalities.ShowOutletSelectionFieldInReturnedItemsReport ?? false; break;
      case 'InventoryTransfer': showOutletField = this.functionalities.ShowOutletSelectionFieldInInventoryTransferReport ?? false; break;
      case 'InventoryWash': showOutletField = this.functionalities.ShowOutletSelectionFieldInInventoryWashReport ?? false; break;
      case 'InventoryAudit': { this.ShowOutletSelectionFieldInInventoryAuditTrailReport = showOutletField = this.functionalities.ShowOutletSelectionFieldInInventoryAuditTrailReport ?? false; } break;
      case 'SalesByDiscountTypes': showOutletField = this.functionalities.ShowOutletSelectionFieldInSaleByDiscountTypeReport ?? false; break;
      case 'SalesBySubCategory': showOutletField = this.functionalities.ShowOutletSelectionFieldInSaleBySubCategoryReport ?? false; break;
      case 'SalesByCategory': showOutletField = this.functionalities.ShowOutletSelectionFieldInSaleByCategoryReport ?? false; break;
      case 'SaleByItem': showOutletField = this.functionalities.ShowOutletSelectionFieldInSaleByItemReport ?? false; break;
      case 'SalesByVendor': showOutletField = this.functionalities.ShowOutletSelectionFieldInSaleByVendorReport ?? false; break;
      case 'CorrectVoid': showOutletField = this.functionalities.ShowOutletSelectionFieldInSaleByCorrectOrVoidReport ?? false; break;
      case 'Shift': showOutletField = this.functionalities.ShowOutletSelectionFieldInShiftReport ?? false; break;
      case 'Transaction': showOutletField = this.functionalities.ShowOutletSelectionFieldInTransactionReport ?? false; break;
      case 'RevenueSummary': showOutletField = this.functionalities.ShowOutletSelectionFieldInRevenueSummaryReport ?? false; break;
      case 'TopSpenders': {
        showOutletField = this.ShowOutletSelectionFieldInTopSpendersReport =
          this.functionalities.ShowOutletSelectionFieldInTopSpendersReport ?? false;
      }
        break;
      case 'TaxExemptSales': showOutletField = this.functionalities.ShowOutletSelectionFieldInTaxExemptSalesReport ?? false; break;
      case 'SalesMix': showOutletField = this.functionalities.ShowOutletSelectionFieldInSalesMixReport ?? false; break;
      case 'InventoryReport': showOutletField = this.functionalities.ShowOutletSelectionFieldInventoryReport ?? false; break;
      case 'InventoryDetails': showOutletField = this.functionalities.ShowOutletSelectionFieldInInventoryDetailsReport ?? false; break;
      case 'InventorySummary': showOutletField = this.functionalities.ShowOutletSelectionFieldInInventorySummaryReport ?? false; break;
      case 'InventoryDetailVendor': showOutletField = this.functionalities.ShowOutletSelectionFieldInVendorInventoryDetailReport ??
        false;
        break;
      case 'InventoryVariance': showOutletField = this.functionalities.ShowOutletSelectionFieldInInventoryDetailsReport ?? false; break;
      case 'Receiving': showOutletField = this.functionalities.ShowOutletSelectionFieldInInventoryDetailsReport ?? false; break;
      case 'PurchaseOrders': showOutletField = this.functionalities.ShowOutletSelectionFieldInInventoryDetailsReport ?? false; break;
      case 'InventoryValuation': showOutletField = this.functionalities.ShowOutletSelectionFieldInInventoryDetailsReport ?? false; break;
      default: showOutletField = config.dropDownFilters ? true : false; break;
    }
    return showOutletField;
  }

  showMachineFilteredOptions(code, config: ReportUIConfig): boolean {
    let showMachineField: boolean;
    let isEnableMachineTransaction: boolean;
    isEnableMachineTransaction = this.PropertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;
    switch (code) {
      case 'SalesByCategory': showMachineField = isEnableMachineTransaction; break;
      case 'SaleByItem': showMachineField = isEnableMachineTransaction; break;
      case 'Shift': showMachineField = isEnableMachineTransaction; break;
      case 'Transaction': showMachineField = isEnableMachineTransaction; break;
      case 'TaxExemptSales': showMachineField = isEnableMachineTransaction; break;
      case 'TransactionDetail': showMachineField = isEnableMachineTransaction; break;
      default: showMachineField = false; break;
    }
    return showMachineField;
  }

  createReportAPIOptions(): void {
    try {
      const reportOptns: ReportAPIOptions = this.report.generateReportAPIOptions();
      this.reportParams = { ...reportOptns };
    } catch {
      console.error(`Please Initialize ${this.activeReportCode} Report`);
    }
  }


  updateReportControls(event: any) {
    this.refreshScreen();
    this.customReport = '';
    this._cdr.detectChanges();
    const _code: string = event.value;
    this.activeReportCode = _code;
    this.customReport = event.value;
    this.generateReportUI(_code);
  }


  private refreshScreen(): void {
    this.dropDownFilterData = [];  
    this.reportsinput.controls['includeInactiveusers'].setValue(false);    
    this.reportsinput.controls['includeHourlyRate'].setValue(false);
    this.reportsinput.controls['hideCorrectedValuesToggle'].setValue(false); 
    this.reportsinput.controls['includeInhouseGratuitySCToggle'].setValue(true);
    this.reportsinput.controls['includeInhouseGratuityToggle'].setValue(false);
    this.reportsinput.controls['includeInhouseSCToggle'].setValue(false);
    this.reportsinput.controls['pageBreakToggle'].setValue(false);
    this.reportsinput.controls['reportNameChild'].setValue('');
    this.reportsinput.controls['outletOptions'].setValue('');
    this.reportsinput.controls['reportTypeSCSelection'].setValue(0);
    this.reportsinput.controls['reportTypeGRSelection'].setValue(0);
    this.reportsinput.controls['reportTypeGSCSelection'].setValue(0);    
    this.reportsinput.setControl('customFilterFormGrp', this.fb.group({}));
    this.validSelection = true;
    // this.minEndDate = this.PropertyInfo.CurrentDate;
    this.reportsinput.controls['startDate'].setValue(this.PropertyInfo.CurrentDate);
    this.reportsinput.controls['endDate'].setValue(this.PropertyInfo.CurrentDate);
    this.textInputFilterValid = true;
    this.endDateInputs.minDate = '';
    this.endDateInputs = {...this.endDateInputs};
    // this.reportsinput.controls['startTime'].setValue('12:00 am');
    // this.reportsinput.controls['endTime'].setValue('11:59 pm');
    this.reportsinput.controls['startTime'].setValue(this.localize.getTime(this.localize.TimeToDate('12:00 am'), this.localize.getTimeFormat()));
    this.reportsinput.controls['endTime'].setValue(this.localize.getTime(this.localize.TimeToDate('11:59 pm'), this.localize.getTimeFormat()));
     
  }

  displayFn(client?: any): string {
    if (client) { return client.name; }
  }
  clientSelected(name: string) {
    this.showMissingClientError = false;
    this.selectedClient = name;
  }



  toggleIncludeInactive(IncludeInactiveToo: boolean) {
    this.activeToggle = IncludeInactiveToo;
    this.dropDownFilterData = this.dropDownFilterData.map(data => {
      if (IncludeInactiveToo) {
        return data = {
          id: data.id, code: data.code, showInDropDown: true, description: data.description,
          isActive: data.isActive, staffType: data.staffType
        };
      } else {
        return data = {
          id: data.id, code: data.code, showInDropDown: data.isActive, description: data.description,
          isActive: data.isActive, staffType: data.staffType
        };
      }
    });
    this.reportsinput.controls['reportNameChild'].setValue([0]);
  }


  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    const _result: any[] = <any>result.result;

  }


  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }

  formInitialized(event) {
    this.reportsinput.setControl('customFilterFormGrp', event);
    this.validateForm(event);
  }

  getFormControlValue(event) {    
      this.reportsinput.setControl('reportNameChild', event[1]);    
  }

  validateForm(validity: boolean) {
    this.disableGenerateBtn = !validity;
  }

  IsSelectionValid(validity: boolean) {
    this.validSelection = validity;
    this.showValidationErrorMessage(this.drowDownFilterName);
  }

  private showValidationErrorMessage(dropDownName: string): void {
    if (!this.validSelection) {
      const caption: any = this.pleaeSelectoption;
      this.validSelectionErrMsg = caption + ' ' + dropDownName;
    }
    else if (!this.textInputFilterValid) {
      const caption: any = this.localization.captions.reports.ItemNumberMissing;
      this.validSelectionErrMsg = caption;
    }
  }

  // isClosed(event) {
  //   document.getElementById('time-overlay').classList.remove('report-transform3dnone');
  //   if (this.utils.getTimeDifference(this.reportsinput.controls.startTime.value, this.reportsinput.controls.endTime.value, 'Min') < 0) {
  //     this.reportsinput.controls.endTime.setErrors({ invalid: true });
  //   }
  // }

  startTimeChange(eve) {
    this.endTimeInput.minTime = this.reportsinput.controls.startTime.value
  }

  openPicker(event) {
    document.getElementById('time-overlay').classList.add('report-transform3dnone');
  }

  customFilterBlockValidation(event: ValidationMessage) {
    if (!event.validity) {
      this.validSelection = event.validity;
      this.showValidationErrorMessage(event.dropDownName);
      this.validateForm(event.validity);
    }
    else {
      this.validSelection = true;
      this.validateForm(true);
    }
  }

  CustomFilterInputValidation(event: ValidationMessage) {
    if (!event.validity) {
      this.validSelection = event.validity;
      this.validSelectionErrMsg = event.dropDownName;
      this.validateForm(event.validity);
    }
    else {
      this.validSelection = true;
      this.validateForm(true);
    }
  }


  TextInputFilterBlockValidation(event: ValidationMessage) {
    if (!event.validity) {
      this.textInputFilterValid = false;
      this.showValidationErrorMessage(this.validSelection ? event.dropDownName : this.drowDownFilterName);

    }
    else {
      this.textInputFilterValid = true;
    }
  }

  handleclick(arg, reportName) {
    this.reportsinput.reset();
    if(this.isFromJobScheduler){
    this.setSchedulercontrols();
    }
    this.updateReportControls({ 'value': reportName });
    this.reportsinput.controls.reportName.setValue(reportName);
    this.disableGenerateButtonEvent(false);    
  }

  saveReportConfig() {
    let activeReport: ReportSelector;
		try {
			const reportAPIoptions = this.report.generateReportAPIOptions();
      activeReport = this.getActiveReport(this.activeReportCode);
			if (!(reportAPIoptions && reportAPIoptions.code)) {
				reportAPIoptions.code = activeReport.code;
			}	
      let retailReportConfig = 
      {
        retailReportAPIOptions : reportAPIoptions,
        retailReportSelector : this.reportSelector
      };
      this.clickSave.emit(retailReportConfig);
		} catch (error) {
			console.error(`ERROR IN ${activeReport.code} Report File. ERROR => formParams/filters ${error}`)
		}
	}

}

export interface ValidationMessage {
  dropDownName: string;
  validity: boolean;
}
