import { ChangeAction, Menu } from '@constants/commonenums';
import { ObjectChange, PropertyChange } from '@models/ChangeTrackingOperationResultDTO';
import { StandaloneTableDTO, TableServerDTO } from '@models/RestaurantDTO';
import { TablesService } from '@services/tables.service';
import _ from 'lodash';
import { CacheService } from '@core/services/cache.service';
import { Processor } from './processor';
import { AppService } from '@app/app.service';
import { Utilities } from '@app/shared/utilities/utilities';

export class StandaloneTableChangeProcessor implements Processor {

  private static instance: StandaloneTableChangeProcessor;
  private constructor(public tablesService: TablesService, public cs: CacheService, public as: AppService) {
  }

  public static Instance(ts: TablesService, cs: CacheService, as: AppService): StandaloneTableChangeProcessor {
    if (!StandaloneTableChangeProcessor.instance) {
      StandaloneTableChangeProcessor.instance = new StandaloneTableChangeProcessor(ts, cs, as);
    }

    return StandaloneTableChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: object, propertyId: number): void {
    let tablesList 
    if (propertyId == Utilities.RestaurantId()) {
      tablesList = this.tablesService.standAloneTables;
    }
    else {
      tablesList = this.cs.propertySettings.value[propertyId].standAloneTables
    }
    if (objectChange) {
      if (objectChange.Action === ChangeAction.Created) {
        const table = new StandaloneTableDTO();
        const availableTable = tablesList.filter(table => table.Id == objectChange.ObjectId);
        if (!availableTable) {
          objectChange.PropertyChanges.forEach(property => {
            const isObject = _.isObject(table[property.PropertyName.replace('Internal', '')]);
            if (Object.getOwnPropertyNames(table).includes(property.PropertyName.replace('Internal', ''))) {
              table[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
            }
          });

          tablesList.push(table);
          if(!this.cs.state.value.TableServerAssignments)
          {
            this.cs.state.value.TableServerAssignments = [];
          }
          var serverDto = new TableServerDTO();
          serverDto.StandaloneTableId = objectChange.ObjectId;
          this.cs.state.value.TableServerAssignments.push({...serverDto});
        }
        //this.cs.state.next(this.cs.state.value);
      }
      if (objectChange.Action === ChangeAction.Updated) {
        if (tablesList && tablesList.length > 0) {
          const tables = tablesList.filter((table) => table.Id === objectChange.ObjectId);
          if (tables && tables.length > 0) {
            tables.forEach(table => {
              objectChange.PropertyChanges.forEach(property => {
                const isObject = _.isObject(table[property.PropertyName.replace('Internal', '')]);
                if (Object.getOwnPropertyNames(table).includes(property.PropertyName.replace('Internal', ''))) {
                  table[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
                } else {
                  this.mapMissedProperties(property, table, objectChange.ObjectId);
                }
              });
              this.updateServersInAllLayoutsAndState(objectChange);
            });
          }
        }
        else{
            //if(this.as.selectedMenuId == Menu.FloorPlan){// commented to ensure changes in all screen
              this.updateServersInAllLayoutsAndState(objectChange);
           // }
        }

      this.cs.layout.next(this.cs.layout.value);
      //this.cs.state.next(this.cs.state.value); //layout component dependent on state subscription for re rendering
      }

      if (objectChange.Action === ChangeAction.Removed && tablesList.length > 0) {
        if (tablesList && tablesList.length > 0) {
          tablesList = tablesList.filter((table) => table.Id !== objectChange.ObjectId);
        }
      }

    }
    tablesList = _.uniqBy(tablesList, 'Id');
    this.tablesService.standAloneTables$.next([...tablesList]);
    this.cs.propertySettings.value[propertyId]['standAloneTables'] = [...tablesList]
    //this.tablesService.st = tablesList;
  }

  mapMissedProperties(property: PropertyChange, tableDetails: any, tableId: number) {
    if (property.PropertyName === 'ServerId') {
      tableDetails.ServerId = JSON.parse(property.Value);
    }
  }

  updateServersInAllLayoutsAndState(change: ObjectChange){
    let objType = change.PropertyChanges.filter(property => property.PropertyName.replace('Internal', '') == 'ServerId');
    if (objType && objType.length) {
      this.cs.layout.value.FloorPlans.forEach(layout => {
        layout.StandaloneTables.filter(x => x.Id == change.ObjectId).forEach(t => t.ServerId = JSON.parse(objType[0].Value));
      });
      if(this.cs.state.value.TableServerAssignments.find(ts=>ts.StandaloneTableId == change.ObjectId)){
        this.cs.state.value.TableServerAssignments.filter(ts=>ts.StandaloneTableId == change.ObjectId)[0].ServerId =  JSON.parse(objType[0].Value);
      }
      else {
        if(!this.cs.state.value.TableServerAssignments)
        {
          this.cs.state.value.TableServerAssignments = [];
        }
        var serverDto = new TableServerDTO();
        serverDto.StandaloneTableId = change.ObjectId;
        serverDto.ServerId = JSON.parse(objType[0].Value);
        this.cs.state.value.TableServerAssignments.push({...serverDto});
      }

    }
  }
}
