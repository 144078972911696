import { Injectable } from "@angular/core";
import { DropdownOptions, TableHeaderOptions } from 'src/app/common/Models/ag-models';
import { ActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { mailTemplate } from "./dm-eforms.model";
import { DataMagineIntegrationDataService } from "../../dataservices/data-magine-integration.data.service";
import { EformConfigDataService } from "../../dataservices/eform-config.data.service";
import { DmEformsDataService } from "../../dataservices/dmEforms.data.service";
import { DMConfigDataService } from '../../dataservices/datamagine-config.data.service';
import { Localization } from 'src/app/common/localization/localization';
import { Observable, of } from "rxjs";
import { DefaultLanguage, EventNotification } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { TemplateDataServices } from "../../dataservices/notification/template-data-service";
import { EformEmailTemplate, GuestContactInformation } from "../data-magine-integration/data-magine-models";
import { ReservationActionDataService } from "../../dataservices/reservation-action.data.service";
import { StayGuestOccupanyInfo } from "../../Models/stayguestoccupanyinfo.model";
import { DmDocument } from "../../Models/common.models";
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Injectable()
export class EformsBusiness {
  captions: any;
  constructor(private localization: Localization, private templateDataService: TemplateDataServices,
    private DMdataService: DataMagineIntegrationDataService, private DMConfigDataService: DMConfigDataService,
    private reservationactionService: ReservationActionDataService, private DmEformsDataService: DmEformsDataService,
    private eformDataService: EformConfigDataService, private utils: CommonUtilities) {
    this.captions = this.localization.captions;

  }

  selectFieldGenerator(className, form, formControlName, placeHolder, placeHolderId, selectOptions, defaultSelectededOptionValue, showRequired) {
    return {
      className,
      form,
      formControlName,
      placeHolder,
      placeHolderId,
      selectOptions,
      showRequired,
      defaultSelectededOptionValue
    };
  }
  getSelectContact(): DropdownOptions[] {
    return [{
      id: 1,
      value: 1,
      viewValue: "name1"
    },
    {
      id: 2,
      value: 2,
      viewValue: "name2"
    },
    {
      id: 3,
      value: 3,
      viewValue: "name3"
    }]
  }
  getSelectContact1(contacts: StayGuestOccupanyInfo[]): Observable<DropdownOptions[]> {
    const contactUIList = Array<DropdownOptions>();
    for (const val of contacts) {
      contactUIList.push({
        id: val.id,
        value: val,
        viewValue: `${val.firstName} ${val.lastName}`
      });
    }

    return of(contactUIList.sort((a, b) => a.viewValue.localeCompare(b.viewValue)));
  }

  getPrimaryContact(contacts: StayGuestOccupanyInfo[], stayId: number) {
    const primaryContact = contacts.filter(x => x.stayId === stayId)[0];
    return {
      id: primaryContact.id,
      value: primaryContact,
      viewValue: `${primaryContact.firstName} ${primaryContact.lastName}`
    };
  }

  getTableOptions() {
    return {
      defaultsortingColoumnKey: 'listOrder',
      defaultSortOrder: SorTypeEnum.asc,
      showTotalRecords: false,
      columnFreeze: {
        firstColumn: false,
        lastColumn: false
      },
      isDragDisabled: true,
      deleteMsgKey: 'name' // Change To the coresponding Name value here
    };
  }
  EmailHeaderGenerator(): TableHeaderOptions[] {
    return [
      {
        displayName: '',
        key: 'checked',
        searchable: true,
        sorting: false,
        sortingKey: '',
        templateName: ActionTypeEnum.radioButton,
        radioKey: 'code'
      },
      {
        displayName: this.captions.tbl_hdr_code,
        key: 'code',
        searchable: true,
        sorting: false,
        sortingKey: 'code',
      },
      {
        displayName: this.captions.tbl_hdr_emailTemplate,
        key: 'name',
        searchable: true,
        sorting: false,
        sortingKey: 'name',
      }
    ];
  }
  getData() {
    return [
      {
        id: "1",
        code: "Form1",
        checked: false
      },
      {
        id: "2",
        code: "Form2",
        checked: false
      },
      {
        id: "3",
        code: "Form3",
        checked: false
      },
      {
        id: "4",
        code: "Form4",
        checked: false
      },
      {
        id: "5",
        code: "Form5",
        checked: false
      },
      {
        id: "6",
        code: "Form6",
        checked: false
      },
      {
        id: "7",
        code: "Form7",
        checked: false
      },
      {
        id: "8",
        code: "Form8",
        checked: false
      },
      {
        id: "9",
        code: "Form9",
        checked: false
      },
      {
        id: "16",
        code: "Form10",
        checked: false
      },
      {
        id: "17",
        code: "Form11",
        checked: false
      },
      {
        id: "18",
        code: "Form12",
        checked: false
      },
      {
        id: "19",
        code: "Form13",
        checked: false
      }
    ]
  }
  getTableContent(): mailTemplate[] {
    return [{
      active: true,
      checked: true,
      code: "check1",
      id: 2,
      listOrder: 15,
      name: "check1",
      rcode: "check1",
      templateData: "",
      type: 1,
      value: "check1",
      default: false,
      max: 5
    },
    {
      active: true,
      checked: false,
      code: "check2",
      id: 2,
      listOrder: 15,
      name: "check2",
      rcode: "check2",
      templateData: "",
      type: 2,
      value: "check2",
      default: false,
      max: 2
    }]
  }

  // async generateTableData(type: number, includeInactive: boolean) {
  //   let apiModel: NotificationTemplate[] = await this.templateDataService.GetAllTemplates(1, includeInactive);
  //   apiModel = apiModel.filter(x => x.eventIds.indexOf(EventNotification.ConfirmationLetter) !== -1);
  //   return apiModel.map((x, index) => this.uiEditMapper(x, index));
  // }

  async generateTableData(type: number, includeInactive: boolean,preferredLanguageId :number =DefaultLanguage.Default) {
    let apiModel1 = await this.DmEformsDataService.getEformsTemplate(EventNotification.Eform,preferredLanguageId);
    return apiModel1.map((x, index) => this.uiEditMapper(x, index));
  }

  private uiEditMapper(template: EformEmailTemplate, index: number): mailTemplate {
    return {
      code: template.templateCode,
      type: template.typeId,
      rcode: template.templateCode,
      name: template.templateName,
      templateData: '',
      active: template.isActive,
      listOrder: template.listOrder,
      id: template.templateId,
      checked: index === 0,
      max: template.linksCount,
    } as mailTemplate;
  }

  public async getDMToken(authenticationUrl: string, clientID: string, clientSecret: string) {
    return await this.DMdataService.getDataMagineToken({ authURL: authenticationUrl, clientID, clientSecret });
  }

  public async getDMLoginToken(authenticationUrl: string, clientID: string, clientSecret: string) {
    return await this.DMdataService.getDMLoginToken( authenticationUrl, clientID, clientSecret );
  }

  public async getDataMagineLoginToken(authenticationUrl: string, clientID: string, clientSecret: string) {
    return await this.DMdataService.getDataMagineLoginToken( authenticationUrl, clientID, clientSecret );
  }

  public async getEformToken(authenticationUrl: string, username: string, password: string) {
    return await this.eformDataService.getEformTokenInfo(authenticationUrl, username, password);
  }

  public async getEformConfig() {
    let result = await this.eformDataService.getAgilysysEformConfig();
    if(result){
        result.password = result.password ? this.utils.decodePassword(result.password) : '';
    }
    return result;
  }

  public async getEformsDropDownList(token: string, DMEFormsListURL: string, tenantId: string, property: string) {
    let res: any[] = []
    let eforms = await this.DMdataService.getEforms(token, DMEFormsListURL, tenantId, property);
    if (eforms && eforms.errorMessage == null) {
      eforms.result.map(x => {
        res.push({
          id: x.eFormId,
          code: x.formBusiness,
          checked: false
        });
      });
    }
    return res;
  }

  public async getSaaSEformsDropDownList(token: string, systemURL: string, tenantId: string, property: string) {
    let res: any[] = []
    let eforms = await this.eformDataService.getEformList(token, systemURL, tenantId, property);
    if (eforms && eforms.errorMessage == null) {
      eforms.result.map(x => {
        res.push({
          id: x.eFormId,
          code: x.formBusiness,
          checked: false
        });
      });
    }
    return res;
  }


  public async getformsLinkURL(formID: string, token: string, DMFormLinkURL: string, stayDetail: { confirmationNumber: string; stayID: string, isWebcheckIn: boolean }) {
    return await this.DMdataService.getformsLinkURL(formID, token, DMFormLinkURL, stayDetail);
  }

  public async getSaaSformsLinkURL(formID: string, token: string, systemURL: string, stayDetail: { confirmationNumber: string; stayID: string, isWebcheckIn: boolean }) {
    return await this.eformDataService.getformsLinkURL(formID, token, systemURL, stayDetail);
  }

  public async getSpaformsLinkURL(formID: string, token: string, DMFormLinkURL: string, appointmentDetail: { appointmentId: number; guestId: string}) {
    return await this.DMdataService.getSpaformsLinkURL(formID, token, DMFormLinkURL, appointmentDetail);
  }

  public async getSubmittedEforms(serverUrl: string, token: string, body: DmDocument) {
    return await this.DMdataService.getSubmittedEforms(serverUrl, token, body);
  }
  public async getDMSubmittedEforms(serverUrl: string, token: string, body: DmDocument) {
    return await this.DMdataService.getDMSubmittedEforms(serverUrl, token, body);
  }

  public async getSubmittedEformsById(serverUrl: string, token: string, folderId: number, docId: number) {
    return await this.DMdataService.getSubmittedEformsById(serverUrl, token, folderId, docId);
  }
  public async getDMSubmittedEformsById(serverUrl: string, token: string, folderId: number, docId: number) {
    return await this.DMdataService.getDMSubmittedEformsById(serverUrl, token, folderId, docId);
  }

  public async getDMConfig() {
    return await this.DMConfigDataService.getDataMagineConfig();
  }

  public async getDmConfigSession() {
    return await this.DMConfigDataService.getDataMagineConfigSession();
  }

  async getStayContactInfo(stayId: number) {
    return await this.reservationactionService.getStayContactInfo(stayId);
  }

  async getGuestInformationById(stayId: number) {
    return await this.reservationactionService.getGuestInformationById(stayId);
  }

  async sendEmailRequest(request) {
    return await this.DmEformsDataService.SendEmail(request);
  }

  public async updateGuestContactDetails(request: GuestContactInformation[]) {
    return await this.DmEformsDataService.UpdateGuestContactDetails(request);
  }
}