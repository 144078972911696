import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core'; // STORAGE THE BACK ARRAY
import { ViewMoreServiceService } from './view-more-service.service';
import { SelectType } from '../globalsContant';


@Component({
  selector: 'app-retail-view-more',
  templateUrl: './view-more.component.html',
  styleUrls: ['./view-more.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailViewMoreComponent   {
  @Input() fromSection;
  @Input() displayDataDetails;
  @Input() disabledflag;

  @Output() clickEmit: EventEmitter<any> = new EventEmitter();
  getheight: NodeJS.Timer;
  enbdisdisabledflag: boolean;
  isPopoverEnable = false;

  constructor(public _ViewMoreServiceService:ViewMoreServiceService) { }


  ngOnChanges() {
    this.isPopoverEnable = false;
    if(this.displayDataDetails){
      this.enbdisdisabledflag = this.disabledflag=='undefined' ?   false : this.disabledflag;
      this.getheight = setInterval(() => {
        this.setPositions();
      }, 1);
    }
  }
  closeMoreWidow() {
    this._ViewMoreServiceService.showViewMore =false;
    this.isPopoverEnable = true;
  }

  emitOnClick(eve,belongtoSec,thisobj){ 
    if (this.displayDataDetails.type && this.displayDataDetails.type === SelectType.single) {
    this.displayDataDetails.selecteddisplaydataArr = thisobj[this.displayDataDetails.dispArrKey[0]];
    }
    this.clickEmit.emit([eve,belongtoSec,thisobj]);
  }

  setPositions() {

    if (document.getElementById('ViewMoreSecID' + this.fromSection)) {
      document.getElementById("ViewMoreSecID" + this.fromSection)['style'].width = this.displayDataDetails.width + "px";
      document.getElementById("ViewMoreSecID" + this.fromSection)['style'].height = this.displayDataDetails.height + "px";
      document.getElementById("ViewMoreSecID" + this.fromSection)['style'].top = this.displayDataDetails.top + "px";
      document.getElementById("ViewMoreSecID" + this.fromSection)['style'].left = this.displayDataDetails.left + "px";
      if(this.displayDataDetails.arrowposition > 0){
      document.getElementById("ViewMoreSecID" + this.fromSection).querySelectorAll('.arrow')[0]['style'].left =  (this.displayDataDetails.width - this.displayDataDetails.arrowposition) +'px';
      }
    }
    clearInterval(this.getheight);
    this.isPopoverEnable = true;
  }
}
