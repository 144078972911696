<section class="general-ledger__section ag_pl--6 ag_pt--6" [formGroup]="generalLedgerForm">

  <div>
    <ag-input [automationId]="'Txt_generalLedgerMapCrud_glAccountId'" class='ag_display--block ag_w--60'
      formControlName='glAccountId' [placeholder]="captions.lbl_glaccountcode" [maxlength]="6"></ag-input>
  </div>
  <div>
    <ag-input [automationId]="'Txt_generalLedgerMapCrud_departmentId'" class='ag_display--block ag_w--60'
      formControlName='departmentId' [placeholder]="captions.lbl_departmentcode" [maxlength]="10"></ag-input>
  </div>
  <div>
    <ag-input [automationId]="'Txt_generalLedgerMapCrud_businessUnit'" class='ag_display--block ag_w--60'
      formControlName='businessUnit' [placeholder]="captions.lbl_businessunit" [maxlength]="5"></ag-input>
  </div>
  <div class="ag_display--flex">
    <div class="">
      <label class="form-label-normal">{{captions.lbl_isActive}}</label>
      <app-retail-toggle-switch [attr.automationId]="'Txt_generalLedgerMapCrud_applyMapping'" class="d-block"
        (changeToggleEvent)="isActivechange($event[0])" formControlName='isActive'>
      </app-retail-toggle-switch>
    </div>
  </div>
</section>

<div mat-dialog-actions class="actions-div">
  <div class="pl-4">
    <button [attr.automationId]="'Btn_generalLedgerMapCrud_save'"
      [ngClass]="isValid && generalLedgerForm.valid && generalLedgerForm.dirty? 'button--primary-save' : 'button--disabled'"
      mat-button [disabled]="false" (click)="saveMappingType()">{{commonCaptions.Save}}</button>
    <button class="spa-button-cancel" mat-button (click)="onClick()">{{commonCaptions.Cancel}}</button>
  </div>
</div>