import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Observable, Subscription, ReplaySubject } from 'rxjs';
import { ButtonValue } from 'src/app/common/shared/retail.modals';
import { ActionMode } from 'src/app/common/shared/shared/enums/enums';
import {
  AgFieldConfig, AgToggleConfig, AgDateConfig, AgDropdownConfig,
  TableHeaderOptions, TableOptions, FloatingNavigation, AgInputFieldConfig, DropdownOptions
} from 'src/app/common/Models/ag-models';
import { CreateTaxTypeBusiness } from './create-tax-type.business';
import { TaxTypeBusiness } from '../tax-type.business';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';

import { cloneDeep } from 'lodash';
import { UI as TaxTypeUI, TaxBased } from '../tax-type.model';
import { AlertAction } from 'src/app/common/enums/shared-enums';
import { SearchWithCheckbox } from 'src/app/common/Models/common.models';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { TaxType } from 'src/app/common/shared/shared/setupConstants';
import { AlertType } from 'src/app/retail/shared/shared.modal';
import { ButtonType } from 'src/app/retail/shared/globalsContant';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { TaxTypeDataService } from 'src/app/retail/shared/service/taxtype.data.service';
import { TaxExemptCategoryDataService } from '../../../tax-exempt-category/tax-exempt-category.data.service';
import { API as TaxExemptCategoryAPI } from 'src/app/retail/retail-code-setup/tax-exempt-category/tax-exempt-category.model';

@Component({
  selector: 'pms-create-tax-type',
  templateUrl: './create-tax-type.component.html',
  styleUrls: ['./create-tax-type.component.scss'],
  providers: [CreateTaxTypeBusiness, TaxTypeBusiness, TaxTypeDataService, TaxExemptCategoryDataService],
  encapsulation: ViewEncapsulation.None
})
export class CreateTaxTypeComponent implements OnInit {
  taxTypeInputs; 
  @Output() formReady = new EventEmitter(); 
  id: number = 0;
  IsEditModeEnabledFlag = false;
  captions: any;
  listOrderInput: AgFieldConfig;
  toggleInput: AgToggleConfig;
  createTaxTypeForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  saveButton: ButtonValue;
  rangeCancelButton: ButtonValue;
  isCopy: boolean;
  message: string;
  changeInputValues: any = '';
  rangeMaintenanceChangeInputValues: any = '';
  formDisabledProperty = false;
  effectiveDaysInput: AgDateConfig;
  pstTaxInput: AgDropdownConfig;
  searchCheckBoxInput: SearchWithCheckbox;
  showClose = false;
  viewOnly: boolean;
  roomTypeInput: SearchWithCheckbox;
  items = [];
  existingItems = [];
  rangeMaintenance = [];
  createMode: boolean; 
  rangeFromInput: AgDateConfig;
  rangeToInput: AgDateConfig;
  taxPercentInput: AgFieldConfig;
  flatAmountInput: AgFieldConfig;
  newTaxPercentInput: AgFieldConfig;
  flatAmountInput1: AgFieldConfig;
  currency: string;
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  tableContent;
  originalData: Observable<any>;
  rangeMaintenanceForm: UntypedFormGroup;
  floatingInputs: FloatingNavigation[];
  taxTypeInput: AgInputFieldConfig;
  postTypeNumberInput: AgInputFieldConfig;
  changeDaysInput: AgFieldConfig;
  extractCodeInput: AgInputFieldConfig;
  newPostTypeNumberInput: AgInputFieldConfig;
  minDate: Date;
  isRangeEdited: boolean = false;
  keywordsMaxLength: number;
  referncekeywordsMaxLength: number;
  postTypes: DropdownOptions[];
  pstTaxes: DropdownOptions[];
  taxTypes: TaxTypeUI.TaxType[];
  subscription: Subscription;
  searchText: string;
  masterPostTypes: any[] = [];
  masterNewPostTypes: any[] = [];
  selectedData: any[] = [];
  selectedNewData: any[] = [];
  searchKey: string[] = ['id', 'name'];
  autoCompleteKeys: string[] = ['name'];
  selectedChipKey: string[] = ['name'];
  selectedNewChipKey: string[] = ['name'];
  isChipDisabled: boolean;
  taxPercentplaceholder: string;
  selectedValue: any = { id: 1, value: 'Percentage %' }
  customValue: any = [];
  taxTypeMaxLength: number;
  changeDaysMaxLength: number;
  taxPercentMinLength: number;
  taxPercentMaxLength: number;
  orFlatAmountMaxLength: number;
  newTaxPercentMaxLength: number;
  newPostTypeMaxLength: number;
  taxBasedList: { id: number; value: any; }[];
  newtaxBasedList: { id: number; value: any; }[];
  isAmount = false;
  isNewAmount = false;
  editPostTypeId: number;
  defaultRangeToDate: Date;
  duplicateTaxType: number;
  zeroTaxValue = 5047010;
  searchPostTypeLength: number;
  taxMinValue: number;
  listOrderMaxLength: number;
  listOrderMaxValue: number;
  setChipError: boolean;
  outOfRangeIndex: number = -1;
  addYears: number = 10;
  isPostTypeSelected: boolean = false;
  isValidPostType: boolean = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  maxValue: number = 999;
  isFocusSearchEmitRequired : boolean = true;
  headername: any;
  taxExemptCategory: Promise<{ id: number; viewValue: string; value: TaxExemptCategoryAPI.TaxExemptCategory; }[]>;
  floatLabel: string;
  isFromTaxPercentage = true;
  decimalMaxLength: number;
  taxMaxLength: Number;

  constructor(private localization: RetailLocalization, private business: CreateTaxTypeBusiness
    , private propertyInfo: RetailPropertyInformation
    , private taxBusiness: TaxTypeBusiness
    , private fb: UntypedFormBuilder, private utilities: RetailUtilities,
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<CreateTaxTypeComponent>) {
    this.captions = this.localization.captions;
    this.currency = this.localization.currencySymbol;
    this.taxTypeInputs = data.datarecord;
    this.headername = data.headername;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    if (this.taxTypeInputs) {
      if (this.taxTypeInputs.selectedRoomItem) {
        this.items = this.taxTypeInputs.selectedRoomItem;
        this.existingItems = cloneDeep(this.items);
      }
      this.GetPostTypes();
      this.defaultRangeToDate = this.localization.dateAdd.AddYears(this.propertyInfo.CurrentDate, this.addYears)
      this.taxExemptCategory = this.business.GetActiveTaxExemptCategories();
      this.taxBasedList = this.taxBusiness.getTaxBasedOptions();
      this.newtaxBasedList = this.taxBusiness.getNewTaxBasedOptions();
      this.getValidators();
      this.generateForm();
      this.buildRangeMaintenanceForm();
      this.initializeInputs();
      this.generateTableData();
      this.createMode = this.taxTypeInputs.mode === ActionMode.create;
      this.patchForm();
      if(this.propertyInfo.IsVATEnabled){
        this.flatAmountInput.disabled = true;
        this.flatAmountInput1.disabled = true;
      }
    }
    this.floatingInputs = [
      { displayName: this.captions.lbl_taxMaintenance, idName: 'taxMaintenance' },
      { displayName: this.captions.lbl_rangeMaintenance, idName: 'rangeMaintenance' }
    ];
  }

  async GetPostTypes() {
    if (this.taxTypeInputs.mode == ActionMode.create) {
      this.utilities.ToggleLoader(true);
      this.postTypes = await this.taxBusiness.getPostTypes();
      this.utilities.ToggleLoader(false);
    }
  }

  async patchForm() {
    this.taxTypes = await this.taxBusiness.getTaxType(true);
    this.pstTaxes = await this.taxBusiness.getPSTTax();
    this.pstTaxInput.selectOptions = Promise.resolve(this.pstTaxes);
    this.pstTaxInput = { ...this.pstTaxInput }
    if ((this.taxTypeInputs.mode === ActionMode.update || this.taxTypeInputs.mode === ActionMode.copy)
      && this.taxTypeInputs.form) {
      this.postTypes = this.taxTypeInputs.postTypes;
      this.taxTypes = this.taxTypes.filter(x => x.postTypeId != this.taxTypeInputs.form.taxMaintenance.postTypeId);
      this.buildFormArr(this.taxTypeInputs.selectedRoomItem);
      this.bindForm(this.taxTypeInputs.form.taxMaintenance, this.taxTypeInputs.selectedRoomItem);
      this.bindRangeMaintenanceForm(this.taxTypeInputs.form.rangeMaintenance);
      this.tableContent = this.taxTypeInputs.form.tableContent;
      this.rangeMaintenance = this.taxTypeInputs.form.tableContent;
      this.actionButton.disabledproperty = true;
    }
  }

  private generateForm() {
    this.createTaxTypeForm = this.fb.group({
      id: 0,
      postTypeId: ['', Validators.required],
      taxType: ['', Validators.required],
      postTypeNumber: '',
      changeDays: '',
      effectiveDays: [this.propertyInfo.CurrentDate],
      pstTax: '',
      extractCode: '',
      listOrder: '',
      isActive: true, 
      taxExemptCategories: '',
      taxTypeCheckList: new UntypedFormArray([]),
    });
    this.formReady.emit(this.createTaxTypeForm);
  }

  buildRangeMaintenanceForm() {
    this.rangeMaintenanceForm = this.fb.group({
      id: 0,
      rangeFrom: [this.propertyInfo.CurrentDate, Validators.required],
      rangeTo: [this.defaultRangeToDate, Validators.required],
      taxPercent: ['0', Validators.required],
      flatAmount: '',
      newTaxPercent: '0',
      flatAmount1: '',
      newPostTypeNumber: '',
      newPostTypeName: '',
      newPostTypeId: '',
      reference: '',
      taxBasedOn: this.taxBasedList[0].id,
      newTaxBasedOn: this.newtaxBasedList[0].id,
      isDisable: false,
    });
  }

  onItemChange(e) {
    this.createTaxTypeForm.markAsDirty();
    this.buildFormArr(e);
  }

  buildFormArr(lists) {
    const FormArrays = this.createTaxTypeForm.controls.taxTypeCheckList as UntypedFormArray;
    FormArrays.clear();
    lists.forEach((element) => {
      FormArrays.push(new UntypedFormControl(element));
    });
  }

  onfromDateChange(event) {
    this.rangeToInput.minDate = this.rangeMaintenanceForm.get('rangeFrom').value;
    this.rangeToInput = { ...this.rangeToInput };
    this.rangeMaintenanceForm.controls.rangeTo.markAsTouched();
  }

  private initializeInputs() {
    this.IsEditModeEnabledFlag = false;
    this.minDate = this.propertyInfo.CurrentDate;
    this.actionButton = {
      type: 'primary',
      label: this.taxTypeInputs.actionButton,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
    this.saveButton = {
      type: 'secondary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.rangeCancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
    this.taxTypeInput = {
      className: 'ag_form-control--lg',
      errorMessageId: 'err_taxType',
      errorMessage: this.captions.err_taxType,
      form: this.createTaxTypeForm,
      formControlName: 'taxType',
      placeHolderId: 'lbl_taxType',
      placeHolder: this.captions.lbl_taxType,
      maxlength: this.taxTypeMaxLength,
      showRequired: true,
      inputType: 'noprespace,nospecailchar'
    };
    this.postTypeNumberInput = {
      className: 'ag_form-control--sm',
      errorMessage: this.captions.err_postTypeNumber,
      form: this.createTaxTypeForm,
      formControlName: 'postTypeNumber',
      placeHolder: this.captions.lbl_postTypeNumber,
      showRequired: true,
      disabled: true
    };
    this.changeDaysInput = {
      className: 'ag_form-control--sm',
      form: this.createTaxTypeForm,
      formControlName: 'changeDays',
      placeHolderId: 'lbl_changeDays',
      placeHolder: this.captions.lbl_changeDays,
      maxlength: this.changeDaysMaxLength,
      isRequired: false
    };
    this.extractCodeInput = {
      className: 'ag_form-control--sm  ag_pr--0',
      form: this.createTaxTypeForm,
      formControlName: 'extractCode',
      placeHolderId: 'lbl_extractCode',
      placeHolder: this.captions.lbl_extractCode,
      maxlength: this.keywordsMaxLength,
      showRequired: false,
      inputType: 'noprespace,nospecailchar'
    };
    this.newPostTypeNumberInput = {
      className: 'ag_form-control--sm',
      form: this.rangeMaintenanceForm,
      formControlName: 'newPostTypeNumber',
      placeHolderId: 'lbl_newPostTypeNumber',
      placeHolder: this.captions.lbl_newPostTypeNumber,
      maxlength: this.newPostTypeMaxLength,
      showRequired: false

    };
      this.effectiveDaysInput = {
        className: 'ag_form-control--sm ag_pr--0',
        form: this.createTaxTypeForm,
        formControlName: 'effectiveDays',
      placeHolderId: 'lbl_effectiveDays',
        placeHolder: this.captions.lbl_effectiveDays,
        minDate: this.minDate
      };
    this.pstTaxInput = {
      className: 'ag_form-control--sm',
      form: this.createTaxTypeForm,
      formControlName: 'pstTax',
      placeHolderId: 'lbl_psttax',
      placeHolder: this.captions.lbl_psttax,
      selectOptions: Promise.resolve(this.pstTaxes),
      isFirstEmpty: true,
      automationId:"'Dd_createTaxType_pstTax'"
    };
    this.listOrderInput = {
      className: 'ag_form-control--sm',
      form: this.createTaxTypeForm,
      formControlName: 'listOrder',
      placeHolderId: 'lbl_listOrder',
      placeHolder: this.captions.lbl_listOrder,
      maxlength: this.listOrderMaxLength,
      maxValue: this.listOrderMaxValue,
      isRequired: false
    };
    this.searchCheckBoxInput = {
      searchPlaceHolder: this.captions.lbl_placeholder_searchByRoomType,
      title: this.captions.grp_title_roomTypes,
      itemList: this.items,
      disabled: false,
      showSearch: false,
      lists: this.taxTypeInputs.selectedRoomItem,
      showSelectAll: false
    };
    this.rangeFromInput = {
      className: 'ag_form-control--sm',
      form: this.rangeMaintenanceForm,
      formControlName: 'rangeFrom',
      placeHolderId: 'lbl_rangeFrom',
      placeHolder: this.captions.lbl_rangeFrom,
      isDateRequired: true,
      minDate: this.minDate
    };
    this.rangeToInput = {
      className: 'ag_form-control--sm',
      form: this.rangeMaintenanceForm,
      formControlName: 'rangeTo',
      placeHolderId: 'lbl_rangeTo',
      placeHolder: this.captions.lbl_rangeTo,
      isDateRequired: true,
      minDate: this.minDate
    };
    this.taxPercentInput = {
      className: 'ag_form-control--lg',
      form: this.rangeMaintenanceForm,
      formControlName: 'taxPercent',
      maxlength: this.taxPercentMaxLength,
      placeHolderId: 'lbl_taxPercent',
      placeHolder: this.captions.lbl_taxPercent,
      errorMessageId: 'err_missingTaxPercent',
      errorMessage: this.captions.err_missingTaxPercent,
      isRequired: true,
      minValue: this.taxMinValue,
      maxValue: this.maxValue
    };
    this.flatAmountInput = {
      className: 'ag_form-control--lg',
      form: this.rangeMaintenanceForm,
      formControlName: 'flatAmount',
      placeHolderId: 'lbl_flatAmount} (${this.currency})`',
      placeHolder: `${this.captions.lbl_flatAmount}`,
      maxlength: 10,
      errorMessageId: 'err_missingFlatAmount',
      errorMessage: this.captions.err_missingFlatAmount,
      isRequired: true,
      minValue: this.taxMinValue
    };
    this.newTaxPercentInput = {
      className: 'ag_form-control--lg',
      form: this.rangeMaintenanceForm,
      formControlName: 'newTaxPercent',
      placeHolderId: 'lbl_newTaxPercent',
      placeHolder: this.captions.lbl_newTaxPercent,
      isRequired: false,
      errorMessageId: 'err_missingNewTaxPercent',
      errorMessage: this.captions.err_missingNewTaxPercent,
      maxValue: this.maxValue
    };
    this.flatAmountInput1 = {
      className: 'ag_form-control--lg',
      form: this.rangeMaintenanceForm,
      formControlName: 'flatAmount1',
      placeHolderId: 'lbl_flatAmount} (${this.currency})`',
      placeHolder: `${this.captions.lbl_flatAmount}`,
      isRequired: false,
      maxlength: 10,
      errorMessageId: 'err_missingNewFlatAmount',
      errorMessage: this.captions.err_missingNewFlatAmount
    };
    this.taxMaxLength = TaxType.PREDECIMALMAXLENGTH;
    this.decimalMaxLength = TaxType.POSTDECIMALMAXLENGTH;
    let sessionValue = this.utilities.GetPropertyInfo("MaximumDecimalPlaces");
    if(Number(sessionValue) >= 0){
      this.decimalMaxLength = Number(sessionValue);
    }
    this.setListOrder();
  }
  async setListOrder() {
    if (this.taxTypeInputs.mode === ActionMode.create || this.taxTypeInputs.mode === ActionMode.copy) {
      this.createTaxTypeForm.patchValue({ 
      });
    }
  }
  private bindForm(data, room) {
    // map values to compare with actual form inputs
    this.changeInputValues = {
      id: data.id,
      taxType: data.taxType,
      postTypeNumber: data.postTypeNumber? data.postTypeNumber : '',
      changeDays: data.changeDays,
      effectiveDays: new Date(data.effectiveDays),
      pstTax: data.pstTax,
      extractCode: data.extractCode,
      listOrder: data.listOrder,
      isActive: data.isActive,
      taxExemptCategories: data.taxExemptCategories,
      taxTypeCheckList: room,
      postTypeId: data.postTypeId
    };
    this.createTaxTypeForm.patchValue({
      id: data.id,
      taxType: data.taxType,
      postTypeNumber: data.postTypeNumber? data.postTypeNumber: '',
      changeDays: data.changeDays,
      effectiveDays: new Date(data.effectiveDays),
      pstTax: data.pstTax,
      extractCode: data.extractCode,
      listOrder: data.listOrder,
      isActive: data.isActive,
      taxExemptCategories: data.taxExemptCategories,
      taxTypeCheckList: room
    });
    
    this.effectiveDaysInput.minDate = this.localization.getDate(data.effectiveDays);
    this.effectiveDaysInput = {...this.effectiveDaysInput};
    if (data.postTypeId > 0) {
      this.createTaxTypeForm.get('postTypeId').setValue(data.postTypeId);
      this.editPostTypeId = data.postTypeId;
      let postType = this.postTypes.filter(x => x.id == data.postTypeId);
      //If needed, Handle exception if the posttype got deleted
      if (postType.length > 0) {
        this.selectedData = [{ id: postType[0].id, name: `${postType[0].value} - ${postType[0].viewValue}` }];
        this.isValidPostType = true;
        this.isPostTypeSelected = true;
      }
    }
  }

  private bindRangeMaintenanceForm(data) {
    this.rangeMaintenanceChangeInputValues = {
      id: data.id,
      rangeFrom: this.localization.getDate(data.rangeFrom),
      rangeTo:  this.localization.getDate(data.rangeTo),
      taxPercent: data.taxPercent,
      flatAmount: data.flatAmount,
      newTaxPercent: data.newTaxPercent,
      flatAmount1: data.flatAmount1,
      newPostTypeId: data.newPostTypeId,
      reference: data.reference,
      taxBasedOn: data.taxBasedOn,
      newTaxBasedOn: data.newTaxBasedOn,
      isDisable: true,
      newPostTypeName: '',
      newPostTypeNumber: '',

    };
    this.rangeMaintenanceForm.patchValue({
      id: data.id,
      rangeFrom:  this.localization.getDate(data.rangeFrom),
      rangeTo:  this.localization.getDate(data.rangeTo),
      taxPercent: data.taxPercent,
      flatAmount: data.flatAmount,
      newTaxPercent: data.newTaxPercent,
      flatAmount1: data.flatAmount1,
      newPostTypeId: data.newPostTypeId,
      reference: data.reference,
      taxBasedOn: data.taxBasedOn,
      newTaxBasedOn: data.newTaxBasedOn,
      isDisable: true,
    });
    this.isAmount = data.taxBasedOn == TaxBased.TaxPercent ? false : true;
    this.isNewAmount = data.newTaxBasedOn == TaxBased.TaxPercent ? false : true;
    this.updateValidation(data.taxBasedOn);
    this.rangeFromInput.minDate = this.localization.getDate(data.rangeFrom);
    this.rangeFromInput.isDisabled = true;
    this.rangeFromInput = { ...this.rangeFromInput };

    if (data.newPostTypeId > 0) {
      this.rangeMaintenanceForm.get('newPostTypeId').setValue(data.newPostTypeId);
      let postType = this.postTypes.filter(x => x.id == data.newPostTypeId);
      //If needed, Handle exception if the posttype got deleted
      if (postType.length > 0) {
        this.selectedNewData = [{ id: postType[0].id, name: `${postType[0].value} - ${postType[0].viewValue}` }];
        this.rangeMaintenanceForm.get('newPostTypeNumber').setValue(postType[0].value);
        this.rangeMaintenanceForm.get('newPostTypeName').setValue(postType[0].viewValue);
      }
    }
  }

  updateValidation(value) {
    if (value === TaxBased.TaxPercent) {
      this.isAmount = false;
      this.rangeMaintenanceForm.controls.flatAmount.setValidators(null);
      this.rangeMaintenanceForm.controls.flatAmount.updateValueAndValidity();
      this.rangeMaintenanceForm.controls.taxPercent.setValidators([Validators.min(this.taxMinValue), Validators.required]);
      this.rangeMaintenanceForm.controls.taxPercent.setValidators([Validators.max(this.maxValue), Validators.required]);
      this.rangeMaintenanceForm.controls.taxPercent.updateValueAndValidity();
    } else {
      this.isAmount = true;
      this.rangeMaintenanceForm.controls.taxPercent.setValidators(null);
      this.rangeMaintenanceForm.controls.taxPercent.updateValueAndValidity();
      this.rangeMaintenanceForm.controls.flatAmount.setValidators([Validators.min(this.taxMinValue), Validators.required]);
      this.rangeMaintenanceForm.controls.flatAmount.updateValueAndValidity();
    }
  }

  formStatusChangeEvent(e) {
    if (this.taxTypeInputs.mode === ActionMode.copy) {
      this.actionButton.disabledproperty =  this.taxTypeInputs.isViewOnly ? true : !(e.formValid);
    } else {
      this.actionButton.disabledproperty =  this.taxTypeInputs.isViewOnly ? true : !(e.formValid && e.objectNotEqual);
      this.formDisabledProperty = !(e.formValid && e.objectNotEqual);
    }
  }

  formStatusChangeEventRangeMaintenance(e) {
    if (this.taxTypeInputs.mode === ActionMode.copy) {
      this.saveButton.disabledproperty = !(e.formValid);
    } else {
      this.saveButton.disabledproperty = !(e.formValid && e.objectNotEqual);
      this.formDisabledProperty = !(e.formValid && e.objectNotEqual);
    }
  }

  generateTableData() {
    this.headerOptions = this.taxBusiness.getHeaderOptionsCreate();
    this.tableOptions = this.taxBusiness.getTableOptions(true);
  }
  taxBasedRadioChange(e) {
    this.updateValidation(e.value);
  }

  newTaxBasedRadioChange(e) {
    if (e.value === TaxBased.TaxPercent) {
      this.isNewAmount = false;
    } else {
      this.isNewAmount = true;
    }
  }

  updateTableContent(data) { 
    const index = this.rangeMaintenance.findIndex(x => x.id == data.id);
    if (index === this.outOfRangeIndex) {
      const temptax = data.newTaxPercent ? data.newTaxPercent : 0;
      const tempTaxFlatAmount = data.flatAmount1 ? data.flatAmount1 : 0;
      this.rangeMaintenance.push(
        {
          id: Date.now(),
          fromDate: data.rangeFrom,
          toDate: data.rangeTo,
          localizedFromDate: this.localization.LocalizeDate(data.rangeFrom),
          localizedToDate: this.localization.LocalizeDate(data.rangeTo),
          taxBasedOn: data.taxBasedOn,
          taxPercent: this.isAmount ? 0 : data.taxPercent,
          taxFlatAmount: this.isAmount ? data.flatAmount : 0,
          newTaxBasedOn: data.newTaxBasedOn,
          newTaxPercent: this.isNewAmount ? 0 : temptax,
          newTaxFlatAmount: this.isNewAmount ? tempTaxFlatAmount : 0,
          newPostTypeNumber: data.newPostTypeNumber,
          newPostTypeId: data.newPostTypeId,
          newPostTypeName: data.newPostTypeName,
          taxReference: data.reference,
          isDisable: false
        });
    }
    else {
      this.rangeMaintenance[index].fromDate = data.rangeFrom;
      this.rangeMaintenance[index].toDate = data.rangeTo;
      this.rangeMaintenance[index].localizedFromDate = this.localization.LocalizeDate(data.rangeFrom);
      this.rangeMaintenance[index].localizedToDate = this.localization.LocalizeDate(data.rangeTo);
      this.rangeMaintenance[index].taxBasedOn = data.taxBasedOn;
      this.rangeMaintenance[index].taxPercent = this.isAmount ? 0 : data.taxPercent;
      this.rangeMaintenance[index].taxFlatAmount = this.isAmount ? data.flatAmount : 0;
      this.rangeMaintenance[index].newTaxBasedOn = data.newTaxBasedOn;
      const tempTaxPercentage = data.newTaxPercent ? data.newTaxPercent : 0;
      this.rangeMaintenance[index].newTaxPercent = this.isNewAmount ? 0 : tempTaxPercentage;
      const tempAmount = data.flatAmount1 ? data.flatAmount1 : 0;
      this.rangeMaintenance[index].newTaxFlatAmount = this.isNewAmount ? tempAmount : 0;
      this.rangeMaintenance[index].newPostTypeNumber = data.newPostTypeNumber;
      this.rangeMaintenance[index].newPostTypeId = data.newPostTypeId;
      this.rangeMaintenance[index].newPostTypeName = data.newPostTypeName;
      this.rangeMaintenance[index].taxReference = data.reference;
      this.rangeMaintenance[index].isDisable = data.isDisable;
    }
    this.rangeMaintenance.sort(function (a, b) {
      return a.fromDate - b.fromDate;
    });
    this.validateDateGap(); 
    this.tableContent = [...this.rangeMaintenance];
  }
 

  overlapDateRange(): boolean {
    const length = this.rangeMaintenance.length;
    for (let i = 0; i < length; i++) {
      if (this.rangeMaintenance[i].id != this.rangeMaintenanceForm.value.id) {
        if ((this.rangeMaintenanceForm.value.rangeFrom >= new Date(this.rangeMaintenance[i].fromDate) && this.rangeMaintenanceForm.value.rangeFrom <= new Date(this.rangeMaintenance[i].toDate))
        || (new Date(this.rangeMaintenance[i].fromDate) >= this.rangeMaintenanceForm.value.rangeFrom && new Date(this.rangeMaintenance[i].fromDate) <= this.rangeMaintenanceForm.value.rangeTo)
        || (new Date(this.rangeMaintenance[i].fromDate) >= this.rangeMaintenanceForm.value.rangeFrom && new Date(this.rangeMaintenance[i].fromDate) <= this.rangeMaintenanceForm.value.rangeTo)
        || (new Date(this.rangeMaintenance[i].toDate) >= this.rangeMaintenanceForm.value.rangeFrom && new Date(this.rangeMaintenance[i].toDate) <= this.rangeMaintenanceForm.value.rangeTo)
        ) {
          return false;
        }
      }
    }
    return true;
  }

  validateDateGap() {
    const length = this.rangeMaintenance.length - 1;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        const fromGap = this.utilities.AddDays(new Date(this.rangeMaintenance[i].toDate), 1);
        const toGap = this.utilities.AddDays(new Date(this.rangeMaintenance[i + 1].fromDate), -1);
        if (new Date(this.rangeMaintenance[i + 1].fromDate).getTime() != fromGap.getTime()) {
          this.utilities.showError(this.captions.err_dateGap + " " + this.localization.localizeDisplayDate(fromGap) + " - " +
            this.localization.localizeDisplayDate(toGap) + ". " + this.captions.err_taxDefinition);
          break;
        }
      }
    }
  }

  save(e) {
    if (this.overlapDateRange()) {
      this.updateTableContent(this.rangeMaintenanceForm.value);
      this.rangeMaintenanceForm.reset();
      this.loadDefaultValues();
      this.IsEditModeEnabledFlag = false;
      if (this.taxTypeInputs.mode === ActionMode.update) {
        this.actionButton.disabledproperty = this.isRangeEdited;
        this.formDisabledProperty = this.isRangeEdited;
      }
      this.selectedNewData = [];
    }
    else {
      this.utilities.showError(this.captions.err_overlapDateRange);
    }
  }

  cancel(e) {
    this.rangeMaintenanceForm.reset();
    this.loadDefaultValues();
    this.IsEditModeEnabledFlag = false;
    this.selectedNewData = [];
    this.onNewChipRemoveEvent(null);
  }
  loadDefaultValues() {
    this.rangeMaintenanceChangeInputValues = {
      rangeFrom: new Date(this.propertyInfo.CurrentDate),
      rangeTo: new Date(this.defaultRangeToDate),
      flatAmount: '',
      flatAmount1: '',
      taxBasedOn: this.taxBasedList[0].id,
      newTaxBasedOn: this.newtaxBasedList[0].id
    };
    this.rangeMaintenanceForm.patchValue({
      rangeFrom: new Date(this.propertyInfo.CurrentDate),
      rangeTo: new Date(this.defaultRangeToDate),
      flatAmount: '',
      flatAmount1: '',
      taxBasedOn: this.taxBasedList[0].id,
      newTaxBasedOn: this.newtaxBasedList[0].id
    });
    this.rangeMaintenanceForm.controls.flatAmount.setValidators(null);
    this.rangeMaintenanceForm.controls.flatAmount.updateValueAndValidity();
    this.isAmount = false;
    this.isNewAmount = false;
    this.rangeFromInput.minDate = this.propertyInfo.CurrentDate;
    this.rangeToInput.minDate = this.propertyInfo.CurrentDate;
    this.rangeFromInput.isDisabled = false;
    this.rangeFromInput = { ...this.rangeFromInput };
    this.rangeToInput = { ...this.rangeToInput };
    this.flatAmountInput = { ...this.flatAmountInput };
    this.flatAmountInput1 = { ...this.flatAmountInput1 };
  }

  showRangeMaintenancePopUp(): boolean {
    if (this.rangeMaintenance === undefined || this.rangeMaintenance.length == 0) {
      this.utilities.showError(this.captions.err_createTaxRange);
      return false;
    }
    let isExists = this.taxTypes.some(x => x.taxType.toLowerCase() == this.createTaxTypeForm.value.taxType.toLowerCase());
    if (isExists) {
      this.utilities.showError(this.captions.err_duplicateTaxType);
      return false;
    }
    if (this.createTaxTypeForm.value.postTypeId == "" || this.createTaxTypeForm.value.postTypeId == 0) {
      this.utilities.showError(this.captions.err_invalidPostType);
      return false;
    }
    return true;
  }

  onAction(eve) {
    const clickReturnValue = {
      from: this.taxTypeInputs.mode,
      formValues: this.createTaxTypeForm.value,
      rangeformValues: this.rangeMaintenance,
      taxTypeCheckBox: this.searchCheckBoxInput.lists
    };
    if (this.showRangeMaintenancePopUp()) {
      if (ActionMode.create === this.taxTypeInputs.mode) {
        this.taxBusiness.create(clickReturnValue).then(() => { 
          this.dialogRef.close(clickReturnValue);
        }).catch(ex => {
          if (ex.error && (this.duplicateTaxType === ex.error.errorCode || this.zeroTaxValue === ex.error.errorCode)) {
            const code: number = parseInt(ex.error.errorCode);
            const message: string = this.localization.getError(code);
            this.utilities.showError(message);
          } else {
            this.utilities.showError(ex.message);
          }
        });
      } else if (ActionMode.update === this.taxTypeInputs.mode) {
        this.taxBusiness.update(clickReturnValue).then(() => { 
          this.dialogRef.close(clickReturnValue);
        });
      }
    }
  }

  onCancel(eve) {
    const clickReturnValue = {
      from: ActionMode.cancel
    }; 
    this.dialogRef.close(clickReturnValue);
  }

  edit(event) {
    this.rangeMaintenanceChangeInputValues = {
      id: event.id,
      rangeFrom: new Date(event.fromDate),
      rangeTo: new Date(event.toDate),
      taxBasedOn: event.taxBasedOn,
      taxPercent: event.taxPercent,
      flatAmount: event.taxFlatAmount,
      newTaxBasedOn: event.newTaxBasedOn,
      newTaxPercent: event.newTaxPercent,
      flatAmount1: event.newTaxFlatAmount,
      newPostTypeNumber: event.newPostTypeNumber? event.newPostTypeNumber: '',
      newPostTypeId: event.newPostTypeId,
      newPostTypeName: event.newPostTypeName? event.newPostTypeName : '',
      reference: event.taxReference,
      isDisable: event.isDisable? event.isDisable: false
    };
    this.rangeMaintenanceForm.patchValue({
      id: event.id,
      rangeFrom: new Date(event.fromDate),
      rangeTo: new Date(event.toDate),
      taxBasedOn: event.taxBasedOn,
      taxPercent: event.taxPercent,
      flatAmount: event.taxFlatAmount,
      newTaxBasedOn: event.newTaxBasedOn,
      newTaxPercent: event.newTaxPercent,
      flatAmount1: event.newTaxFlatAmount,
      newPostTypeNumber: event.newPostTypeNumber? event.newPostTypeNumber: '',
      newPostTypeId: event.newPostTypeId,
      newPostTypeName: event.newPostTypeName? event.newPostTypeName : '',
      reference: event.taxReference,
      isDisable: event.isDisable? event.isDisable: false
    });
    this.isAmount = event.taxBasedOn == TaxBased.TaxPercent ? false : true;
    this.isNewAmount = event.newTaxBasedOn == TaxBased.TaxPercent ? false : true;
    this.updateValidation(event.taxBasedOn);
    this.rangeFromInput.minDate = this.localization.getDate(event.fromDate);
    this.rangeFromInput.isDisabled = event.isDisable;
    this.rangeFromInput = { ...this.rangeFromInput };

    if (event.newPostTypeId > 0) {
      this.rangeMaintenanceForm.get('newPostTypeId').setValue(event.newPostTypeId);
      let postType = this.postTypes.filter(x => x.id == event.newPostTypeId);
      if (postType.length > 0) {
        this.selectedNewData = [{ id: postType[0].id, name: `${postType[0].value} - ${postType[0].viewValue}` }];
        this.rangeMaintenanceForm.get('newPostTypeNumber').setValue(postType[0].value);
        this.rangeMaintenanceForm.get('newPostTypeName').setValue(postType[0].viewValue);
      }
    }
  }

  delete(event) {
    const index = this.rangeMaintenance.findIndex(x => x.id == event.id);
    if (index >= 0) {
      this.utilities.showAlert(this.captions.warn_deleteDataRange + " " + this.rangeMaintenance[index].fromDate + " - " + this.rangeMaintenance[index].toDate,
        AlertType.Warning, ButtonType.YesNo, async (res) => {
          if (res === AlertAction.YES) {
            this.rangeMaintenance.splice(index, 1);
            this.tableContent = [...this.rangeMaintenance];
            if (this.taxTypeInputs.mode === ActionMode.update) {
              this.actionButton.disabledproperty = false;
              this.createTaxTypeForm.markAsDirty();
            }
          }
        });
    }
  }

  tableAction(event) {
    if (event.fromType == FromTypeEnum.edit) {
      this.IsEditModeEnabledFlag = true;
      this.edit(event.Obj);
    }
    else if (event.fromType == FromTypeEnum.delete){
      this.delete(event.Obj);
    }
  }

  selectedChipDataEmit(event: { id: number, name: string }[]) {
    let postTypeNumber = '';
    let postTypeId = 0;
    let taxType = '';
    if (event && event.length > 0) {
      let isExists = this.taxTypes.some(x => x.postTypeId == event[0].id);
      if (isExists) {
        this.utilities.showAlert(this.captions.err_alreadyPostTypeTaxExists, AlertType.Error, ButtonType.Ok, (res) => {
          this.selectedData = [];
        });
        this.onChipRemoveEvent(event);
        this.isValidPostType = false;
        this.isPostTypeSelected = false;
      }
      else {
        postTypeId = event[0].id ? event[0].id : 0;
        postTypeNumber = event[0].name ? event[0].name.split('-')[0].trim() : '';
        taxType = event[0].name ? event[0].name.split('-')[1].trim() : '';
        this.actionButton.disabledproperty = false;
        this.createTaxTypeForm.markAsDirty();
        this.createTaxTypeForm.get('postTypeId').setValue(postTypeId);
        this.createTaxTypeForm.get('postTypeNumber').setValue(postTypeNumber);
        this.createTaxTypeForm.get('taxType').setValue(taxType);
        this.isValidPostType = true;
        this.isPostTypeSelected = true;
        this.createTaxTypeForm.controls.taxType.markAsTouched();
      }
    }
  }

  selectedNewChipDataEmit(event: { id: number, name: string }[]) {
    let postTypeNumber = '';
    let postTypeId = 0;
    let newPostTypeName = '';
    if (event && event.length > 0) {
      postTypeId = event[0].id ? event[0].id : 0;
      postTypeNumber = event[0].name ? event[0].name.split('-')[0].trim() : '';
      newPostTypeName = event[0].name ? event[0].name.split('-')[1].trim() : '';
      this.rangeMaintenanceForm.markAsDirty();
      this.rangeMaintenanceForm.get('newPostTypeId').setValue(postTypeId);
      this.rangeMaintenanceForm.get('newPostTypeNumber').setValue(postTypeNumber);
      this.rangeMaintenanceForm.get('newPostTypeName').setValue(newPostTypeName);
    }
  }

  async SearchNewTextHandler(arg) {
    this.searchNewMasterPostTypes(arg);
  }

  searchNewMasterPostTypes(pattern: string) {
    pattern = (pattern ? pattern.toLowerCase() : "").trim();
    if (this.postTypes && this.postTypes.length > 0) {
      this.masterNewPostTypes = this.postTypes && pattern ? this.postTypes.filter(x => x.viewValue.toLowerCase().includes(pattern) ||
        x.value.toString().toLowerCase().includes(pattern)).map(x => {
          return {
            id: x.id,
            name: `${x.value} - ${x.viewValue}`
          };
        }) : [];
    }
    else {
      this.utilities.showError(this.captions.err_searchMasterPostType);
      this.isFocusSearchEmitRequired = false;
    }
  }

  SearchTextHandler(arg) {
    this.searchMasterPostTypes(arg);
  }

  async searchMasterPostTypes(pattern: string) {
    pattern = (pattern ? pattern.toLowerCase() : "").trim();
    if (this.postTypes && this.postTypes.length > 0) {
      this.masterPostTypes = this.postTypes ? this.postTypes.filter(x => x.viewValue.toLowerCase().includes(pattern)
        || x.value.toString().toLowerCase().includes(pattern)).map(x => {
          return {
            id: x.id,
            name: `${x.value} - ${x.viewValue}`
          };
        }) : [];
    }
    else {
      this.utilities.showError(this.captions.err_searchMasterPostType);
      this.isFocusSearchEmitRequired = false;
    }
  }

  getValidators() {
    this.taxTypeMaxLength = TaxType.TAXTYPE_MAXLENGTH;
    this.changeDaysMaxLength = TaxType.CHANGEDAYS_MAXLENGTH;
    this.taxPercentMaxLength = TaxType.TAXPERCENT_MAXLENGTH;
    this.orFlatAmountMaxLength = TaxType.ORFLATAMOUNT_MAXLENGTH;
    this.newTaxPercentMaxLength = TaxType.NEWTAXPERCENT_MAXLENGTH;
    this.newPostTypeMaxLength = TaxType.NEWPOSTTYPE_MAXLENGTH;
    this.duplicateTaxType = TaxType.DUPLICATE_TAXTYPE_NAME;
    this.searchPostTypeLength = TaxType.SEARCH_POSTTYPE_MAXLENGTH;
    this.taxMinValue = TaxType.TAXPERCENT_MINVALUE;
    this.keywordsMaxLength = TaxType.KEYWORDS_MAXLENGTH;
    this.referncekeywordsMaxLength = TaxType.REFERNCEKEYWORDS_MAXLENGTH;
    this.listOrderMaxLength = TaxType.LISTORDER_MAXLENGTH;
    this.listOrderMaxValue = TaxType.LISTORDER_MAXVALUE;
  }

  onChipRemoveEvent(event) {
    this.isValidPostType = false;
    this.createTaxTypeForm.get('postTypeId').setValue('');
    if (this.taxTypeInputs.mode != ActionMode.update)
      this.createTaxTypeForm.get('taxType').setValue('');
    this.isPostTypeSelected = false;
  }

  onNewChipRemoveEvent(event) {
    this.rangeMaintenanceForm.get('newPostTypeId').setValue('');
    this.rangeMaintenanceForm.get('newPostTypeNumber').setValue('');
    this.rangeMaintenanceForm.get('newPostTypeName').setValue('');
  }

  close(e){
    this.onCancel(e)
  }
}
