import { Component, OnInit, Input } from '@angular/core';
import { popupConfig } from '../../../shared/business/shared.modals';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { SPAConfig } from 'src/app/retail/common/config/SPA-config';
import { MatDialogRef } from '@angular/material/dialog';
import { RetailOutletTerminalDataService } from '../retail-outlet-terminal-data.service';
import { OutletAPIModel, OutletTerminalUIMapper } from 'src/app/retail/retail.modals';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { AlertMessagePopupComponent } from 'src/app/retail/shared/alert-message-popup/alert-message-popup.component';
import { AlertAction } from 'src/app/retail/shared/shared.modal';
@Component({
  selector: 'app-create-outlet-terminal',
  templateUrl: './create-outlet-terminal.component.html',
  styleUrls: ['./create-outlet-terminal.component.scss'],
  providers: [RetailOutletTerminalDataService]
})
export class CreateOutletTerminalComponent implements OnInit {
  @Input() dataInput: any;
  @Input() popupConfigs: popupConfig;
  outletTerminalFormGrp: UntypedFormGroup;
  captions: any;
  clickbutton: any;
  allCaptions: any;
  activeOutlets: OutletAPIModel[] = [];
  isTerminalInUse: boolean;
  dialogRef$: MatDialogRef<AlertMessagePopupComponent, AlertAction>;
  floatLabel: string;

  constructor(private spaConfig: SPAConfig, private fb: UntypedFormBuilder, private localization: RetailLocalization,
    public dialogRef: MatDialogRef<CreateOutletTerminalComponent>, private retailOutletTerminalDataService: RetailOutletTerminalDataService, private utils: RetailUtilities) {
    this.allCaptions = this.spaConfig.captions;
    this.captions = this.allCaptions.retailsetup;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.clickbutton = this.popupConfigs.operation == 'create' ? this.allCaptions.setting.save : this.allCaptions.setting.update;
    this.outletTerminalFormGrp = this.fb.group({
      outlet: '',
      storeid: '',
      terminalname: '',
      terminalid: '',
      isactive: true,
      userid: '',
      password: '',
    });
    this.getOutlets();
  }

  async saveOutletTerminal(data) {
    let body : OutletTerminalUIMapper = this.formBodyData(data);
    let result : boolean;
    if(this.popupConfigs.operation == 'create'){
      result = await this.retailOutletTerminalDataService.createOutletTerminalConfiguration(body);
    }
    else{
      result = await this.retailOutletTerminalDataService.updateOutletTerminalConfiguration(body);
    }
    if(result){
      this.dialogRef.close(true);
    }
  }

  formBodyData(data) : OutletTerminalUIMapper {
    return {
      id: this.popupConfigs.operation == 'create' ? 0 : this.dataInput[0].id,
      storeId: data.storeid,
      terminalId: data.terminalid,
      terminalName: data.terminalname,
      isActive: data.isactive,
      retailUserId: data.userid,
      retailUserPassword: data.password,
      outletId: data.outlet,
      rRetailJWT: this.popupConfigs.operation == 'create' ? null : this.dataInput[0].rRetailJWT
    };
  }

  onCancel() {
    if (this.outletTerminalFormGrp.dirty) {
      this.dialogRef$ = this.utils.openSaveChangedWarningPopup(this.localization.captions.common.WarningMessage);
        this.dialogRef$.afterClosed().subscribe(result => {
          if (result === this.localization.captions.common.No) {
            return;
          } else {
            this.dialogRef.close(true);
          }
        });
    } else {
      this.dialogRef.close(true);
    }
  }

  async getOutlets(){
    this.activeOutlets = await this.retailOutletTerminalDataService.getActiveOutlets();
    if(this.popupConfigs.operation == 'edit'){
      this.populateEditData();
      this.isTerminalInUse = await this.retailOutletTerminalDataService.isTerminalInUse(this.dataInput[0].outletId, this.dataInput[0].terminalId);
    }
  }

  private populateEditData(){
    let data = this.dataInput[0];
    this.outletTerminalFormGrp.controls.outlet.setValue(data.outletId);
    this.outletTerminalFormGrp.controls.storeid.setValue(data.storeId);
    this.outletTerminalFormGrp.controls.terminalname.setValue(data.terminalName);
    this.outletTerminalFormGrp.controls.terminalid.setValue(data.terminalId);
    this.outletTerminalFormGrp.controls.isactive.setValue(data.isActive);
    this.outletTerminalFormGrp.controls.userid.setValue(data.retailUserId);
    this.outletTerminalFormGrp.controls.password.setValue(data.retailUserPassword);
  }

  OutletSelectionChange(value) {
    this.outletTerminalFormGrp.controls.storeid.setValue(value.storeId);
  }
}
