<section class="giftcards-info newVersionWrapper">
  <div class="giftcards-details">
    <ng-scrollbar >
      <form [formGroup]="giftcardsInfo" autocomplete="off" [ngClass]="viewOnly ? 'view-only' : ''">
        <div class="mb-3">
          <label class="form-label-normal">{{captions.ActivateGiftCard}}</label>
          <div>
            <app-retail-toggle-switch [attr.automationId]="'Txt_giftCard_activate'"
              formControlName="SET_ACTIVATE_GIFTCARD" (changeToggleEvent)="OnActivateChange()">
            </app-retail-toggle-switch>
          </div>
        </div>
        <div *ngIf='giftcardsInfo.value.SET_ACTIVATE_GIFTCARD'>
          <div class="d-flex">
            <div class="mb-3 mr-4 w-250px">
              <label class="form-label-normal">{{captions.AllowExpiration}}</label>
              <div>
                <app-retail-toggle-switch [attr.automationId]="'Txt_giftCard_allowExpiration'"
                  formControlName="SET_ALLOW_EXPIRATION"
                  (changeToggleEvent)="OnAllowExpirationChange($event)"></app-retail-toggle-switch>
              </div>
            </div>
            <div class="mb-3 mr-4">
              <mat-form-field class="w-250px"
                [ngClass]="giftcardsInfo.controls.SET_ALLOW_EXPIRATION.value ? 'button_valid' : 'button_invalid'"
                [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_giftCard_expirationDays'" type="number" matInput
                  [disabled]="!giftcardsInfo.controls.SET_ALLOW_EXPIRATION.value"
                  placeholder="{{captions.ExpireAfterDays}}" name="ExpireAfterDays" formControlName="expirationDays"
                  min="1" LimitExceed [numUpto]="9999" inputtype="nodecimal" (keydown)="markTouched()">
                <mat-error
                  *ngIf="giftcardsInfo.controls['SET_ALLOW_EXPIRATION'].value == true && giftcardsInfo.controls['expirationDays'].value <= 0">
                  {{captions.InvalidExpiryDays}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>          
          <div class="mb-3">
            <label class="form-label-primary mb-3 d-block">{{captions.GiftCardType}}</label>
            <mat-radio-group [attr.automationId]="'Rdo_giftCard_giftCardType'" formControlName="giftCardType">
              <mat-radio-button *ngFor="let type of giftCardType; let i = index" [value]=type.id class="mr-4 mb-2">
                {{type.name}}</mat-radio-button>
            </mat-radio-group>
          </div>

          <ng-container *ngIf="giftcardsInfo.controls.giftCardType.value != 4">
            <label class="form-label-primary mb-3 d-block">{{captions.V1GiftCard}}</label>
            <div class="url-textbox">
              <mat-form-field class="" [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_giftCard_payAgentUrl'" type="text" matInput
                  [placeholder]="captions.rGuestPayAgentURL" (input)="giftCardValueChange($event)"
                  formControlName="V1rGuestPayAgentURL">
              </mat-form-field>
            </div>

            <div class="url-textbox mb-3">
              <mat-form-field class="" [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_giftCard_payGatewayId'" type="text" matInput
                  [placeholder]="captions.rGuestPayGatewayID" (input)="giftCardValueChange($event)"
                  formControlName="V1rGuestPayGatewayID">
                <mat-error *ngIf="giftcardsInfo.controls['V1rGuestPayGatewayID'].invalid">
                  {{ getErrorMessage('V1rGuestPayGatewayID', 0) }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="mb-3">
              <label class="form-label-primary mb-3 d-block">{{captions.GatewayValues}}</label>
              <div
                *ngFor="let item of giftcardsInfo.get('V1GatewayPairs')['controls']; let i = index;let last=last;let first= first"
                formArrayName="V1GatewayPairs" class="w-100">
              <div class="align-items-center d-flex">
                <mat-form-field class="" [formGroupName]="i" [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_giftCard_configKey'" matInput name="key"
                    placeholder="{{captions.Key}}" (keypress)="keyPress($event)" [maxlength]="200"
                    formControlName="configKey">
                </mat-form-field>
                <mat-form-field class=" gateway-values ml-2" [formGroupName]="i" [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_giftCard_configValue'" matInput name="value"
                    placeholder="{{captions.Value}}" (keypress)="keyPress($event)" [maxlength]="200"
                    formControlName="configValue">
                </mat-form-field>
                <span class="icon-Minus" (click)="removeGatewayPairs(i, 'V1GatewayPairs')"
                  *ngIf="!(first && last)"></span>
                <span class="icon-Plus" (click)="addGatewayPairs(i, 'V1GatewayPairs','','')" *ngIf="last"></span>
              </div>
                <mat-error *ngIf="giftcardsInfo.controls['V1GatewayPairs']['controls'][i].invalid">
                  {{captions.MissingKeyPairValue}}
                </mat-error>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="giftcardsInfo.controls.giftCardType.value != 2">
            <label class="form-label-primary mb-3 d-block">{{captions.ThirdPartyGiftCard}}</label>
            <div class="url-textbox">
              <mat-form-field class="" [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_giftCard_thridPartyPayUrl'" type="text" matInput
                  placeholder="{{captions.rGuestPayAgentURL}}" name="ThirdpartyrGuestPayAgentURL"
                  (input)="externalGiftCardValueChange($event)" formControlName="ThirdpartyrGuestPayAgentURL">
              </mat-form-field>
            </div>

            <div class="url-textbox mb-3">
              <mat-form-field class="" [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_giftCard_thridPartyGatewayId'" type="text" matInput
                  placeholder="{{captions.rGuestPayGatewayID}}" name="ThirdpartyrGuestPayGatewayID"
                  (input)="externalGiftCardValueChange($event)" formControlName="ThirdpartyrGuestPayGatewayID">
                <mat-error *ngIf="giftcardsInfo.controls['ThirdpartyrGuestPayGatewayID'].invalid">
                  {{captions.MissingKeyPairValue}}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="service-charge">
              <label class="form-label-primary mb-3 d-block">{{captions.GatewayValues}}</label>
              <div
                *ngFor="let item of giftcardsInfo.get('ThirdpartyGatewayPairs')['controls']; let i = index;let last=last;let first= first"
                formArrayName="ThirdpartyGatewayPairs" class="w-100">
                <mat-form-field class="" [formGroupName]="i" [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_giftCard_configKey1'" matInput name="key"
                    placeholder="{{captions.Key}}" (keypress)="keyPress($event)" [maxlength]="200"
                    formControlName="configKey">
                </mat-form-field>
                <mat-form-field class=" gateway-values ml-2" [formGroupName]="i" [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_giftCard_configKey2'" matInput name="value"
                    placeholder="{{captions.Value}}" (keypress)="keyPress($event)" [maxlength]="200"
                    formControlName="configValue">
                </mat-form-field>
                <span class="icon-Minus" (click)="removeGatewayPairs(i, 'ThirdpartyGatewayPairs')"
                  *ngIf="!(first && last)"></span>
                <span class="icon-Plus" (click)="addGatewayPairs(i,'ThirdpartyGatewayPairs','','')" *ngIf="last"></span>
                <mat-error *ngIf="giftcardsInfo.controls['ThirdpartyGatewayPairs']['controls'][i].invalid">
                  {{ getErrorMessage('ThirdpartyGatewayPairs', i) }}
                </mat-error>
              </div>
            </div>
          </ng-container>

          <div class="mb-3">
            <label class="form-label-normal">{{captions.AllowCashback}}</label>
            <div>
              <app-retail-toggle-switch [attr.automationId]="'Txt_giftCard_allowCashback'"
                formControlName="ALLOW_CASHBACK" (changeToggleEvent)="OnActivateChange()">
              </app-retail-toggle-switch>
            </div>
          </div>

          <div class="mb-3 mr-4 w-250px">
            <label class="form-label-normal">{{captions.AllowSearchByGuestName}}</label>
            <div>
              <app-retail-toggle-switch [attr.automationId]="'Txt_giftCard_allowSearchByGuestName'" (changeToggleEvent)="OnActivateChange()"
                formControlName="AllowSearchByGuestName"></app-retail-toggle-switch>
            </div>
          </div>

          <div class="mt-4 w-50 ">
            <app-retail-search-items [attr.automationId]="'Srch_giftCard_linkedItem'" [retailItemType]="1"
              [RetailItemId]="giftcardsInfo.controls.linkedItem.value"
              [autocomplete]="giftcardsInfo.controls.selectedLinkedItem" (doneEvtEmitter)="LinkedItemEmit($event)">
            </app-retail-search-items>
          </div>
          <div class="mt-4">
            <div class="gift-card-custom-values">
              <label class="form-label-primary mb-3 d-block">{{captions.GiftCardCustomValues}}</label>
              <div
                *ngFor="let item of giftcardsInfo.get('GiftCardCustomValuePairs')['controls']; let i = index;let last=last;let first= first"
                formArrayName="GiftCardCustomValuePairs" class="w-100">
                <mat-form-field class="ml-2" [formGroupName]="i" [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_giftCard_amount'" matInput name="value"
                    placeholder="{{captions.Value}}" (keypress)="keyPress($event)" RetailCurrencyFormatter
                    formControlName="Amount" (keypress)="markTouched()">
                </mat-form-field>
                <span class="icon-Minus" (click)="removeDefaultValues(i, 'GiftCardCustomValuePairs')"
                  *ngIf="!(first && last)"></span>
                <span class="icon-Plus" (click)="addDefaultValues(i,'GiftCardCustomValuePairs', 0, null)"
                  *ngIf="last && i < 101"></span>
                <mat-error *ngIf="giftcardsInfo.controls['GiftCardCustomValuePairs']['controls'][i].invalid">
                  {{ captions.InvalidCustomValues }}
                </mat-error>
              </div>
            </div>
          </div>
        </div>
        <input [attr.automationId]="'Txt_giftCard_saveGiftCard'" type="submit" [disabled]="!enableSave"
          class="hidden-submit" tabindex="-1" (click)="SaveGiftCardConfiguration()" />
      </form>
    </ng-scrollbar>
  </div>

  <div class="prop-actions">
    <div>
      <button [attr.automationId]="'Btn_giftCard_saveGiftCard'"
        [ngClass]="enableSave ? 'spa-primary-default-save' : 'spa-primary-disabled'" class="LW14 save" mat-button
        (click)="SaveGiftCardConfiguration()">{{captions.save}}</button>
      <a class="LW14 text-default-color" (click)="CancelGiftCardConfiguration()">{{captions.cancel}}</a>
    </div>
  </div>

</section>