import { AfterViewInit, Component, ElementRef, OnInit, Pipe, PipeTransform, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';
import _ from 'lodash';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['form-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormSelectComponent implements Field, OnInit, AfterViewInit {
  config: FieldConfig;
  group: UntypedFormGroup;
  FormControlName: string;  
  options = [];
  showColor = false;
  valueChecked = false;
  isSelectAll: boolean;
  selectedPreviuosValues = [];
  @ViewChild('selectAll') selectAllCheckbox: ElementRef;
  constructor(private renderer: Renderer2, private showSelectedpipe: ShowSelectedOptionTitlePipe) {

  }
  ngOnInit() {
    if (this.config?.selectAscendingSortOrder) {
      this.config.options = _.orderBy(this.config?.options, this.config?.orderFields?.fields || ['value'], this.config?.orderFields?.orders || ['asc']);
    }
    this.showColor = this.config.showColor ? this.config.showColor : false;
    this.FormControlName = this.config.name;
    this.group?.controls[this.config.name]?.setValue(this.config.value);
    // Set highlightRequired as true to highlight the field required during initialization
    if(this.config.highlightRequired){   this.group?.controls[this.config.name]?.markAsTouched(); }

    this.config.title = this.showSelectedpipe?.transform(this.config.value, this.config.options);
  }

  ngAfterViewInit() {
    this.checkboxAllValueUpdate();
  }

  ngDoCheck() {
    if (this.config.value) {
      this.config.title = this.showSelectedpipe.transform(this.config.value, this.config.options);
      this.checkboxAllValueUpdate();
    }
    else {
      this.config.title = null;
    }
    if (this.showColor && !this.valueChecked) {
      let selectedValue = document.querySelectorAll('.select-color .mat-select-value-text');
      if (selectedValue.length > 0) {
        this.valueChecked = true;
        this.renderer.setStyle(selectedValue[0], 'color', this.config.options.filter(option => option.id == this.config.value)[0].color)
      }
    }
  }


  reset(event) {
    if (this.group.controls[this.config.name].value == "") {
      event.target.blur();
      this.group.controls[this.config.name].setValue(this.config.value)
    }
  }

  selectedOption(event) {
    this.config.title = this.showSelectedpipe.transform(event.value, this.config.options);
    if (this.showColor) {
      let selectedValue = document.querySelectorAll('.select-color .mat-select-value-text');
      if (selectedValue.length > 0) {
        this.renderer.setStyle(selectedValue[0], 'color', this.config.options.filter(option => option.id == event.value)[0].color)
      }
    }
    if (this.config.cellClick) {
      this.config.cellClick(event);
    }

    if (this.config.selectMultipleOptions) {
      const tbaId = this.getTBAId();
      let isTBASelected = this.selectedPreviuosValues.filter(x => x == tbaId).length ? true : false;
      if (event.value.length > 1 && event.value.filter(x => x == tbaId).length && this.selectedPreviuosValues != event.value) {
        const tbaIndex = event.value.findIndex(x => x == tbaId);
        if (isTBASelected && tbaIndex > -1) {
          // this.isTBASelected = false;
          event.value.splice(tbaIndex, 1);
        } else if (!isTBASelected && tbaIndex > -1) {
          // this.isTBASelected = true;
          event.value.push(tbaId);
          event.value.splice(0, event.value.length - 1);
        }
      }
      this.selectedPreviuosValues = event.value;
    }

    this.checkboxAllValueUpdate();
  }

  checkboxAllValueUpdate() {
    if (!this.selectAllCheckbox) return;
    if (this.config?.selectMultipleOptions && this.config?.options?.length == this.config?.value?.length) {
      this.isSelectAll = true;
      this.selectAllCheckbox['checked'] = true;
    } else if (this.config?.selectMultipleOptions) {
      this.isSelectAll = false;
      this.selectAllCheckbox['checked'] = false;
    }
  }

  toggleAllSelection(value) {
    if (this.config.selectMultipleOptions) {
      if (value) {
        this.config.value = this.config.options.filter(filterOption => !filterOption.IsTemplate).map(mapOption => mapOption.id);
        this.group.controls[this.config.name].setValue(this.config.value)
      } else {
        this.config.value = [];
        this.group.controls[this.config.name].setValue(this.config.value)
      }
      this.selectedPreviuosValues = this.config.value;
    }
  }
  onClick(event: Event) {
    if (this.config.onClick)
      this.config.onClick(event)
  }

  getTBAId() {
    return this.config.options?.find(x => x.IsTemplate)?.id || null;
  }

}

@Pipe({
  name: 'showSelectedOptionTitle'
})
export class ShowSelectedOptionTitlePipe implements PipeTransform {

  transform(optionId: number, options: any[]): any {
    let selectedOption = options.filter(option => option.id == optionId);
    if (selectedOption && selectedOption.length == 1 && selectedOption[0].value) {
      return selectedOption[0].value;
    }
    return '';
  }
}

