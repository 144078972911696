<div class="managerPIN-Msg">
  {{managerPINRequestMsg}}
</div>
<div class="permission-details__permission-table">
  <ng-scrollbar>
    <table mat-table [dataSource]="tableDataSource" matSort class="mat-elevation-z8">

      <ng-container matColumnDef="Name">
        <th class="rs-Roboto-Medium-16" mat-header-cell *matHeaderCellDef> {{requiredPermission}} </th>
        <td mat-cell *matCellDef="let tableDataSource">{{tableDataSource.Name}}</td>
      </ng-container>

      <ng-container matColumnDef="AvailabilityText">
        <th class="rs-Roboto-Medium-16" mat-header-cell *matHeaderCellDef> {{isGrantedToUser}} </th>
        <td mat-cell *matCellDef="let tableDataSource" [ngClass]="{'highlightText' : tableDataSource.IsNotAvailable}">
          {{tableDataSource.AvailabilityText}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
    </table>
  </ng-scrollbar>
</div>