import { Pipe, PipeTransform } from '@angular/core';
import { ReservationType } from '@constants/commonenums';
import { SettingsDTO } from '@models/RestaurantDTO';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';

@Pipe({
  name: 'sortReservations'
})
export class SortReservationsPipe implements PipeTransform {

  transform(parties: any, showByPartySize: boolean, type: ReservationType, daylightDelta: string): any {
    if (showByPartySize) {
      parties = _.orderBy(parties, ['Size','ReservedFor',function(party) { return party.Contact.FirstName ? party.Contact.FirstName : party.Contact.LastName; }], ['asc','asc','asc']);
    } else if (type === ReservationType.Reservation) {
      parties = _.orderBy(parties, ['ReservedFor',function(party) { return party.Contact.FirstName ? party.Contact.FirstName : party.Contact.LastName; }], ['asc','asc']);
    } else if (type === ReservationType.Waitlist) {
      parties = parties.sort((a, b) => {
        const timeA: any = new Date(a.ArrivedAt);
        const timeB: any = new Date(b.ArrivedAt);
        const currentTime: any = new Date(Utilities.getRestaurantDateTime(daylightDelta)).getTime();
        return Math.floor(timeA - currentTime) - Math.floor(timeB - currentTime);
      });
    }
    return parties;
  }

}
