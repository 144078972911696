import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ReservationType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ISubscription, Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-size-selection',
  templateUrl: './size-selection.component.html',
  styleUrls: ['./size-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SizeSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  defaultCountValue = { count: 2 };
  numberArray: any = [];
  sizeArray: any = [];
  leftArrowDisabled = true;
  rightArrowDisabled = false;
  totalbtnWidth: any;
  totalScrollWidth: any;
  availableBtnSpace: any;
  noShowIcon: boolean;
  btnWidth: number;
  config: FieldConfig[];
  selectedTableNumberUrl: any = 'assets/images/dining_seat_2.png';
  selectedIndex = 0;
  @ViewChild('buttonView', { static: true }) btnView: ElementRef;
  @Input() title: string;
  tabChangeSubscription: ISubscription;
  _settings: SettingsDTO = {} as SettingsDTO;
  subscriptions: Subscription = new Subscription();
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.partySizeWidth();
  }

  constructor(public translateService: TranslateService, public partyService: PartyService, @Inject(COMPONENTINPUT) private data,
    private ps: PopupService, private fb: UntypedFormBuilder, public cs: CacheService) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
    if (this.partyService.reservationType === ReservationType.Reservation) {
      Object.keys(this.partyService.reservationFormGroup.controls).forEach((control) => {
        if (control !== 'selectedSpecialMeal' && control !== 'selectedCoverTypes' && control !== 'selectedTable') {
          this.partyService.reservationFormGroup.get(control).setValidators(Validators.required);
          this.partyService.reservationFormGroup.get(control).updateValueAndValidity();
        }
      });
    } else if (this.partyService.reservationType === ReservationType.Waitlist) {
      this.partyService.reservationFormGroup = this.fb.group({
        selectedSize: ['', Validators.required],
        selectedArea: ['', Validators.required],
        selectedTime: ['', Validators.required],
        selectedGuest: ['', Validators.required]
      });
    }
  }

  ngOnInit() {
    this.defaultCountValue = this.data ? { count: this.data.Size } : { count: 2 };
    this.generateNumberArray();
    const maxPartySize: number = this._settings.General.MaxPartySize;

    this.config = [
      {
        type: 'input',
        name: 'customSize',
        inputType: 'text',
        label: 'customSizeText',
        class: 'reservation-size__custom-count',
        directive: 'numberOnly',
        charLength: maxPartySize.toString().length,
        validation: [Validators.max(maxPartySize), Validators.min(1)],
        showErrorText: true,
        errorMessage: "customSizeError"
        // blurClick: this.updateSize.bind(this)
      }
    ];
    this.reservationFormChange(this.defaultCountValue.count);
    if (this.data) {
      this.partyService.isEditData = true;
    } else {
      this.partyService.isEditData = false;
    }
  }

  ngAfterViewInit() {
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === SizeSelectionComponent);
    this.tabChangeSubscription = this.partyService.tabChange$.subscribe((data) => {
      if (data === this.selectedIndex) {
        if (this.dynamicForm.form) {
          this.ps.formValid$.next({
            isFormValid: this.dynamicForm.form.valid,
            currentTab: this.selectedIndex
          } as IFormValidDetails);
        } else {
          this.ps.formValid$.next({
            isFormValid: this.partyService.reservationFormGroup.value.selectedSize ? true : false,
            currentTab: this.selectedIndex
          } as IFormValidDetails);
        }
      }
    });
    this.subscriptions.add(this.dynamicForm.form.controls.customSize.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(val => {
      if (val) {
        this.customCount(val);
      } else if (val === '') {
        this.selectedCount(this.defaultCountValue);
      }
    }));
  }

  ngOnDestroy() {
    if (this.tabChangeSubscription) {
      this.tabChangeSubscription.unsubscribe();
    }
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    this.partyService.reservationFormGroup.reset();
    this.partyService.createReservationForm();
  }
  updateSize(event) {
    this.selectingCustomInputValue(event.currentTarget.value);
  }

  generateNumberArray() {
    for (let i = 1; i < 50; i++) {
      this.numberArray.push({ count: i, image_url: 'assets/images/dining_seat_' + i + '.png' });
    }
    // Shows party size from 1 through 14. values other than that will be accepted as custom size
    this.sizeArray = this.numberArray.slice(0, 50);
  }

  selectedCount(countValue) {
    this.reservationFormChange(countValue.count);
    this.partyService.setSlots(this.partyService.slotsinTimeRange, countValue.count);
    this.makeReservationFormDirty();
    this.dynamicForm.form.controls.customSize.reset();
  }

  partySizeWidth() {
    this.totalbtnWidth = this.btnView.nativeElement.offsetWidth;
    this.totalScrollWidth = this.btnView.nativeElement.scrollWidth;
    this.btnWidth = 53;
    this.availableBtnSpace = Math.floor(this.totalbtnWidth / 68);
    const ff = Math.floor(this.btnView.nativeElement.offsetWidth / this.availableBtnSpace);
    const navChildren = this.btnView.nativeElement.querySelectorAll('.time-btn');
    navChildren.forEach(element => {
      element.style.width = ff + 'px';
    });
    if (this.totalbtnWidth && this.totalbtnWidth > 0 && this.totalbtnWidth < this.totalScrollWidth) {
      this.noShowIcon = true;
    } else {
      this.noShowIcon = false;
    }
  }

  viewPrevMenu() {
    this.rightArrowDisabled = false;
    const element = this.btnView.nativeElement;
    const scrollValue = element.scrollLeft - this.btnWidth;
    element.scrollLeft = scrollValue;

    if (scrollValue <= 0) {
      this.leftArrowDisabled = true;
    }
  }

  viewNextMenu() {

    this.leftArrowDisabled = false;
    this.rightArrowDisabled = true;
    const element = this.btnView.nativeElement;
    const scrollValue = element.scrollLeft + this.btnWidth;
    const scrollWidth = element.scrollWidth;
    const offsetWidth = element.offsetWidth;
    element.scrollLeft = scrollValue;

    if ((scrollWidth - (scrollValue + offsetWidth)) > 0) {
      this.rightArrowDisabled = false;
    }

  }
  customCount(customValue) {
    this.reservationFormChange(customValue);
    this.partyService.setSlots(this.partyService.slotsinTimeRange, customValue);
    this.makeReservationFormDirty();
  }

  selectingCustomInputValue(value) {
    if (value) {
      // Method Call to set the slots in time tab if the size changes
      this.partyService.setSlots(this.partyService.slotsinTimeRange, value);
      this.reservationFormChange(value);
    }
  }

  reservationFormChange(partySize) {
    if (this.partyService.reservationFormGroup.get('selectedSize').value !== partySize) {
      this.partyService.reservationFormGroup.get('selectedSize').setValue(+partySize);
      // ToDO: Auto lock has to be triggered
      // if (this.partyService.reservationFormGroup.value.selectedTime && this.partyService.reservationType === ReservationType.Reservation &&
      //   !this.partyService.isEditData) {
      //   this.partyService.setLockTime(this.partyService.reservationFormGroup.value.selectedTime);
      // }
      if (this.partyService.reservationType === ReservationType.Waitlist || this.partyService.reservationType === ReservationType.Reservation || this.partyService.reservationType == ReservationType.StandbyParties) {
        const partyDetails = this.data ? this.data : { SeatingAreaId: this.partyService.reservationFormGroup.value.selectedArea.Id };
        // For standby request
        partyDetails.ReservedFor = this.partyService.isStandbyData(this.data) ? this.data.WishedTime : partyDetails.ReservedFor;
        this.partyService.getAutoWaitTime(partySize, partyDetails);
      }
    }
    this.changePartySizeImage(partySize);
    if (this.selectedIndex > -1) {
      partySize = +partySize;
      this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = partySize.toString();
    }

    if (this.dynamicForm.form) {
      this.ps.formValid$.next({
        isFormValid: this.dynamicForm.form.valid,
        currentTab: this.selectedIndex
      } as IFormValidDetails);
    } else {
      this.ps.formValid$.next({
        isFormValid: this.partyService.reservationFormGroup.value.selectedSize ? true : false,
        currentTab: this.selectedIndex
      } as IFormValidDetails);
    }
  }

  makeReservationFormDirty() {
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
  }

  changePartySizeImage(partySize: number) {
    const partySizeValue = +partySize;
    switch (partySizeValue) {
      case 1:
      case 4:
      case 5:
      case 6:
      case 7:
        this.selectedTableNumberUrl = 'assets/images/dining_seat_' + partySizeValue + '.svg';
        break;

      case 2:
      case 3:
        this.selectedTableNumberUrl = 'assets/images/dining_seat_' + partySizeValue + '.png';
        break;
      default:
        this.selectedTableNumberUrl = 'assets/images/dining_seat_7.svg';
        break;
    }
  }
}
