import {Component, Inject, OnInit, ViewEncapsulation, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { ButtonType } from '../globalsContant';
import { Localization } from 'src/app/common/localization/localization';
@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: 'dialogPopup-component.html',
    styleUrls: ['./dialogPopup-componenet.scss'],
    encapsulation:ViewEncapsulation.None,
    providers: []
  })
  export class DialogOverviewExampleDialog implements OnInit {
    public test:boolean = true;
    datRec:any;
    FormGrp: UntypedFormGroup;
    IsConfirmationPopup:boolean;
    buttonType : ButtonType;
    captions:any = this.localization.captions.common;
    price:number;
    ShopPricePrompt:boolean;
  floatLabel: string;

  constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public viewContainerRef: ViewContainerRef, private Form: UntypedFormBuilder, public localization:Localization){
      this.FormGrp =this.Form.group({
        HideDialog:false,
        Reason:'',
        ShopPrice :'',
        ScaledUnit: '',
        Barcodes: ''
      });
      this.floatLabel = this.localization.setFloatLabel;
     }

    DialogClose(result?:any): void {
      if (this.data.Type == "OPENPRICE") {
        this.dialogRef.close({res:result ,price :this.FormGrp.controls['ShopPrice'].value});
      }
      else if (this.data.Type == "SCALEDITEM") {
        this.dialogRef.close({res: result, unit: this.FormGrp.controls['ScaledUnit'].value})
      }
      else if (this.data.Type == "BC") {
        this.dialogRef.close({res: result, labelPosition: this.FormGrp.controls['Barcodes'].value})
      }
      else {
        this.dialogRef.close(result);
      }

    }


    ngOnInit(){
        this.datRec = this.data.datarecord;
        this.buttonType = this.data.buttonType ? this.data.buttonType : ButtonType.Ok;

        if(this.data.Type == "Confirm")
        {

          this.IsConfirmationPopup = true;
        }
        else
        {
          this.IsConfirmationPopup = false;
        }
    }

  }
