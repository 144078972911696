import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ButtonValue, ReturnButtonValue, SplitMoreAction } from 'src/app/common/Models/ag-models';
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent{
  @ViewChild('dynamicbutton', { read: true }) dynamicbutton: ElementRef;
  @Input() buttontype: ButtonValue;
  @Input() throttleTime: number;
  @Input() actions: SplitMoreAction[];
  automationId:string="";
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : '';
  }
  constructor(public _EL: ElementRef) { }


  @Output() valueChange = new EventEmitter();

  handleclick(e: ReturnButtonValue): void {
    this.valueChange.emit(e);
  }
  
  /**
   * @method function;
   * @function ngOnChanges;
   * @description change the input value
   */

  actionClicked(arg) {
    this.valueChange.emit(arg);
  }
}
