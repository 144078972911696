import { PartyType } from '@constants/commonenums';
import { PartyDTO, ReservationDTO } from '@models/InputContact';
import { StandByPartyDTO, WalkInDTO } from '@models/InputReservation';
import { Party } from '@models/Party';

export abstract class PartyMapper {
  public static mapPartyDTO(party: Party) {
    switch (party.Type) {
      case PartyType.Reservation: {
        const reservation = new ReservationDTO();
        this.mapCommonProperties(party, reservation);
        this.mapReservation(party, reservation);
        return reservation;
      }
      case PartyType.WalkIn: {
        const walkin = new WalkInDTO();
        this.mapCommonProperties(party, walkin);
        this.mapWalkIn(party, walkin);
        return walkin;
      }
      case PartyType.StandBy: {
        const standBy = new StandByPartyDTO();
        this.mapCommonProperties(party, standBy);
        this.mapStandBy(party, standBy);
        return standBy;
      }
    }
  }

  private static mapCommonProperties(party: Party, partyDTO: PartyDTO) {
    partyDTO.Id = party.Id;
    partyDTO.Contact.Id = party.ContactId;
    partyDTO.Type = party.Type;
    partyDTO.State = party.State;
    partyDTO.Size = party.Size;
    partyDTO.SeatingAreaId = party.SeatingAreaId;
    partyDTO.TableIds = party.TableIds;
    partyDTO.TableNames = party.TableNames;
    partyDTO.StaffIds = party.StaffIds;
    partyDTO.SeatingTime = party.SeatingTime;
    partyDTO.DepartureTime = party.DepartureTime;
    partyDTO.CreatedAt = party.CreatedAt;
    partyDTO.HostId = party.HostId;
    partyDTO.StatusId = party.StatusId;
    partyDTO.PartySourceId = party.PartySourceId;
    partyDTO.ConfirmationCode = party.ConfirmationCode;
    partyDTO.IsConfirmed = party.IsConfirmed;
    partyDTO.PagerNumber = party.PagerNumber;
    partyDTO.PageMethod = party.PageMethod;
    partyDTO.LastPagedAt = party.LastPagedAt;
    partyDTO.Covers = party.Covers;
    partyDTO.ChargeState = party.ChargeState;
    partyDTO.SeatingTypeId = party.SeatingTypeId;
    partyDTO.RoomNumber = party.RoomNumber;
    partyDTO.DepositPaid = party.DepositPaid;
    partyDTO.RatePlanAmount = party.RatePlanAmount;
    partyDTO.CreditCardId = party.CreditCardId;
    partyDTO.PrepaymentState = party.PrepaymentState;
    partyDTO.ServerId = party.ServerId;
    partyDTO.StartDate = party.StartDate;
    partyDTO.EndDate = party.EndDate;
    partyDTO.UniqueId = party.UniqueId;
    partyDTO.RestaurantId = party.RestaurantId;
    partyDTO.Charges = party.Charges;
    partyDTO.TaxAmount = party.TaxAmount;
    partyDTO.PackageId = party.PackageId;
    partyDTO.AddonItemAmount = party.AddonItemAmount;
    partyDTO.AddonItemTaxAmount = party.AddonItemTaxAmount;
    partyDTO.WaiverFormURL = party.WaiverFormURL;
    partyDTO.PackageDiscountAmount = party.PackageDiscountAmount;
    partyDTO.Comments = party.Comments;
    partyDTO.SalesContactIds = party.SalesContactIds;
    partyDTO.OverBooked = party.OverBooked;
    partyDTO.CancellationFeeAmount = party.CancellationFeeAmount;
    partyDTO.CommonPackageInfo = party.CommonPackageInfo;
    partyDTO.IsExclusiveBooking = party.IsExclusiveBooking;
  }

  private static mapReservation(party: Party, reservation: ReservationDTO) {
    reservation.ReservedFor = party.DateTime;
    reservation.SlotId = party.SlotId;
    reservation.SpecialMealId = party.SpecialMealId;
    reservation.IsCCAssociated = party.IsCCAssociated;
    reservation.CreditCardId = party.CreditCardId;
    reservation.HotelId = party.HotelId;
    reservation.ConciergeId = party.ConciergeId;
    reservation.SpecialMealAmount = party.SpecialMealAmount;
    reservation.PrepaymentState = party.PrepaymentState;
    reservation.ChargeState = party.ChargeState;
    reservation.NoShowFeeAmount = party.NoShowFeeAmount;
    reservation.ChargedAt = party.ChargedAt;
    reservation.RefundedAt = party.RefundedAt;
    reservation.CoverTypeQuantities = party.Covers;
    reservation.RatePlanAmount = party.RatePlanAmount;
    reservation.BookingId = party.BookingId;   
    reservation.SessionGroupId = party.SessionGroupId;
    reservation.NegotiatedAmount=party.NegotiatedAmount;
    reservation.UniqueId = party.UniqueId;
    reservation.ReferenceNumber = party.ReferenceNumber;
    reservation.CancellationFeeAmount = party.CancellationFeeAmount;
  }

  private static mapWalkIn(party: Party, walkin: WalkInDTO) {
    walkin.ArrivedAt = party.DateTime;
    walkin.QuotedSeatingTime = party.QuotedSeatingTime;
    walkin.IsSeatingTimeOverriden = party.IsSeatingTimeOverriden;
    walkin.Position = party.Position;
    walkin.ReferenceNumber = party.ReferenceNumber;
  }

  private static mapStandBy(party: Party, standBy: StandByPartyDTO) {
    standBy.WishedTime = party.DateTime;
    standBy.SpecialMealId = party.SpecialMealId;
    standBy.SessionGroupId = party.SessionGroupId;
    standBy.CoverTypeQuantities = party.Covers;
    standBy.BookingTypeQuantities = party.BookingTypeQuantities;
    standBy.NegotiatedAmount = party.NegotiatedAmount;
    standBy.ReferenceNumber = party.ReferenceNumber;    
  }
}
