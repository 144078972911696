<div class="schedule-area">
  <!-- <span class="reservation-select-label reservation-area__select-text rs-Roboto-Regular-24">{{'waitListPopUpSelectArea'|translate}}</span> -->
  <mat-error class="errorblink" *ngIf="(!(checkIspastDate()) && selectedFrequencyValue == TableBlockingRuleType.TheOnlyDay)"
   > <span class="icon-notification"></span>

   {{ (Reservationtypes==1 ? 'blockerrormsg' : 'unblockerrormsg')|translate}}</mat-error>
  <div class="schedule-area__data-container">

    <div class="schedule-area__seating" #areaContainer>
      <div class="schedule-area__seating-area" >
        <div id="1" class="schedule-area__eacharea"
          [ngClass]="{'schedule-area__selected-area':  selectedFrequencyValue == TableBlockingRuleType.TheOnlyDay}"
        (click)="setFrequencyValue(TableBlockingRuleType.TheOnlyDay)" >
          <div class="schedule-area__area-abbreviation">
           <span class="schedule-area__area-abbreviationsmall" [ngClass]="{'schedule-area__area-abbreviationsmallselected':  selectedFrequencyValue == TableBlockingRuleType.TheOnlyDay}" >1</span>
          </div>
          <label class="schedule-area__name seat-header-three rs-pt-10">{{selectedfrequencyDate | formatSettingsDate: cs.settings.value.General.DateFormat }} {{'only' | translate}}</label>
          <svg id="svg_{{selectedFrequencyValue}}"  class="schedule-area__checkmark position-absolute checkmarkhide" [ngClass]="[selectedFrequencyValue == TableBlockingRuleType.TheOnlyDay ? 'checkmarkvisble' : 'checkmarkhide']" viewBox="0 0 52 52">
            <circle class="schedule-area__checkmark-circle " cx="26" cy="26" r="25" fill="none" />
            <path class="schedule-area__checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
        </div>
      </div>
      <div class="schedule-area__seating-area" *ngIf="!IscustomFloor" >
        <div id="2" class="schedule-area__eacharea"
        [ngClass]="{'schedule-area__selected-area':  selectedFrequencyValue == TableBlockingRuleType.EveryWeekDay}"
        (click)="setFrequencyValue(TableBlockingRuleType.EveryWeekDay)" >
          <span class="schedule-area__area-abbreviation icon-repeat">
           </span>
          <label class="schedule-area__name seat-header-three rs-pt-10">{{'every' | translate}} {{getdayName(selectedfrequencyDate) | translate}} </label>
          <svg id="svg_{{selectedFrequencyValue}}"  class="schedule-area__checkmark position-absolute" [ngClass]="[selectedFrequencyValue == TableBlockingRuleType.EveryWeekDay ? 'checkmarkvisble' : 'checkmarkhide']" viewBox="0 0 52 52">
            <circle class="schedule-area__checkmark-circle " cx="26" cy="26" r="25" fill="none" />
            <path class="schedule-area__checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
        </div>
      </div>
      <div class="schedule-area__seating-area" *ngIf="!IscustomFloor" >
        <div id="3" class="schedule-area__eacharea"
        [ngClass]="{'schedule-area__selected-area':  selectedFrequencyValue == TableBlockingRuleType.EveryDay}"
        (click)="setFrequencyValue(TableBlockingRuleType.EveryDay)" >
        <span class="schedule-area__area-abbreviation icon-Group-595"></span>
          <label class="schedule-area__name seat-header-three rs-pt-10">{{'everyday' | translate}}</label>
          <svg id="svg_{{selectedFrequencyValue}}"  class="schedule-area__checkmark position-absolute" [ngClass]="[selectedFrequencyValue == TableBlockingRuleType.EveryDay ? 'checkmarkvisble' : 'checkmarkhide']" viewBox="0 0 52 52">
            <circle class="schedule-area__checkmark-circle " cx="26" cy="26" r="25" fill="none" />
            <path class="schedule-area__checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
        </div>
      </div>
    </div>
  </div>
</div>
