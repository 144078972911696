<div class="submodule-container letter-landing-wrapper">

    <app-table-search-header [searchHeaderOption]="searchHeaderOption" (searchChange)="searchChange($event)"
        (showInactive)="showInactiveToggler($event)" (createType)="createEvent()">
    </app-table-search-header>

    <div class="table-container">
        <!-- <app-cdkvirtual [IsEditModeEnabled]="IsEditModeEnabledFlag" [options]="options" [headerOptions]="headerOptions"
            [tableContent]="tableContent | async" [searchOptions]="searchText" (EmittedData)='tableAction($event)'
            [isViewMode]='isViewOnly'  [childTemplate]="childTemplate"></app-cdkvirtual> -->
            <!-- <app-tablevirtualscroll [hideDeletePopUp]=true [tableOptions]="options"  [headerOption]="headerOptions" [bodyContentData]="backupOriginalContent"
                [searchOptions]="searchText" (EmittedData)="tableAction($event)">
            </app-tablevirtualscroll> -->
            <app-retail-table [searchvalue]="searchText" [options]="options" (doneEvtEmitter)="Done($event)"
            (cancelEmitter)="Cancel($event)" (afterEditClose)="afterEditPopupClose($event)" 
            (dragDropEvt)="dragDrp($event)" (deleteEvt)="delete($event)" (editEvt)="edit($event)"
            (toggleEvtEmitter)="sliderChange($event)" [isOnEditDisableFunctionAvailable]="onEditDisableFunction"
            [isEditModeSetFromParent]="isEditFlag"></app-retail-table>
    </div>
    
</div>

<div *ngIf="letterCreationToggler">
    <app-create-modal [input]="createModalData" (clickEvent)="back($event)">
        <app-create-letter [createInputs]='createInputs' [crudInput]="crudActionInput" [isDefaultDisable]="isDefaultDisable"
            (handleClickEvent)="handleClick($event)" (formReady)='formReady($event)'>
        </app-create-letter>
    </app-create-modal>
</div>