import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { RadioTypes,BuildingRadioInput } from '../vat-configuration.model';
import { cloneDeep } from 'lodash';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';

@Component({
  selector: 'app-vat-config-department-table',
  templateUrl: './vat-config-department-table.component.html',
  styleUrls: ['./vat-config-department-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VatConfigDepartmentTableComponent{

  buildingList: BuildingRadioInput[] = [];
  roomsList = [];
  itemList: RadioTypes[] = [];
  captions: any;
  inputOption;
  selectedBuildingIndex = 0;
  allselected:boolean=false;
  IsallChecked:boolean=false;
  @Input() isViewOnly;
  searchVal: string;
  @Input('inputs')
  set inputOptions(value) {
    if (value) {

      if (value.buildingList && value.buildingList.length > 0 ) {
        this.buildingList = cloneDeep(value.buildingList);
        if (value.roomsList && value.roomsList.length > 0) {
          this.roomsList = cloneDeep(value.roomsList);

          if( value.roomsList && value.roomsList.length > 0)
          {
            this.buildingList.forEach((d,m)=>{
              if(d.isSelected) this.selectedBuildingIndex =m;});
            const room = this.roomsList[this.selectedBuildingIndex].selectedRooms.slice(0, this.roomsList[this.selectedBuildingIndex].selectedRooms.length);
            this.itemList = room && room.length > 0 ? room.slice(0, room.length) : [];
          }
          else
          this.itemList =  [];
        }
      }
    }
  }
  
  @Input('SearchValueChangeEmitter')
  set searchFunc(val)
  {
    this.searchVal=val;
    if(this.searchVal && this.searchVal!='' && this.searchVal.length>2)
    {
    this.searchTextChanged(this.searchVal);
  }
}

  @Output() BuildingRoomchange = new EventEmitter();

  constructor(private localization: RetailLocalization, private utils: RetailUtilities) {
    this.captions  = this.localization.captions;
  }
  onItemChange(e, item) {
    if(this.selectedBuildingIndex == -1){
      this.roomsList.forEach(x=>{
        let obj = x.selectedRooms.find(x=>x.value == item.value);
        if(obj) obj.checked=e.target.checked;
      })
    }else{
    this.itemList.find(x => x.value === item.value).checked = e.target.checked;
    this.roomsList[this.selectedBuildingIndex].selectedRooms = this.itemList;
    }
    this.BuildingRoomchange.emit(['roomType', cloneDeep(this.buildingList), cloneDeep(this.roomsList)]);
    this.IsallChecked = (this.itemList.length == (this.itemList.filter(x=>x.checked).length));
  }

  onBuildingChange(e, index) {
    this.allselected = false;
    this.selectedBuildingIndex = e.id;
    this.buildingList.forEach(x => {x.isSelected = (x.value === e.value) ? true : false; });
    const room = this.roomsList[index].selectedRooms.slice(0, this.roomsList[index].selectedRooms.length);
    this.itemList = room && room.length > 0 ? room.slice(0, room.length) : [];
    
    const filteredPostTypes = this.roomsList.filter((x)=>{
      if(x.departmentId==this.selectedBuildingIndex){
        x.selectedRooms = x.selectedRooms.filter(x=>x.departmentId == this.selectedBuildingIndex);
          return x};});

    this.itemList = filteredPostTypes && filteredPostTypes.length > 0 ? filteredPostTypes : [];

    this.BuildingRoomchange.emit(['building', cloneDeep(this.buildingList), cloneDeep(filteredPostTypes)]);
    this.IsallChecked = (this.itemList.length == (this.itemList.filter(x=>x.checked).length));
  }
  
  onallItem(e){
    this.IsallChecked = e.checked;
    this.itemList.forEach(x=>{
      x.checked = e.checked;
    })
    if(this.selectedBuildingIndex== -1){
      this.roomsList.forEach(x=>{
        x.selectedRooms.forEach(y=>{
          y.checked = e.checked;
        })
      })
    }else{
      this.roomsList[this.selectedBuildingIndex].selectedRooms = this.itemList;
    }
    this.BuildingRoomchange.emit(['roomType', cloneDeep(this.buildingList), cloneDeep(this.roomsList)]);
  }
  
  onAllBuilding(eve){
    this.allselected = true;
    this.selectedBuildingIndex = -1; 
    if (this.buildingList && this.buildingList.length>0 && this.roomsList && this.roomsList.length>0)
    {
    this.buildingList.forEach(x => {x.isSelected = false; });      
      this.itemList = this.roomsList;
    this.BuildingRoomchange.emit(['building', cloneDeep(this.buildingList), cloneDeep(this.roomsList)]);
    }
  }

  searchTextChanged(searchText:string)
  {
    this.allselected = true;
    this.selectedBuildingIndex = -1;
    this.buildingList.forEach(x => {x.isSelected = false; });   
    let unfilteredData = cloneDeep(this.roomsList);
    let filteredData = this.utils.SearchFilter(searchText, ['viewValue'], unfilteredData);
    let filteredSelectedRoomsData = this.utils.SearchFilter(searchText, ['postTypeName'], filteredData[0].selectedRooms);
    filteredData.forEach(data => {
      data.selectedRooms = filteredSelectedRoomsData;
    });
    this.itemList = filteredData;
    this.BuildingRoomchange.emit(['building', cloneDeep(this.buildingList), cloneDeep(this.itemList)]);
  }
}
