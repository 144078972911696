import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { CacheService } from '@core/services/cache.service';
import { AuthorizePaymentType } from '@constants/commonenums';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { SettingsDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import { PopupService } from '@popup-module/popup.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-refund-popup',
  templateUrl: './refund-popup.component.html',
  styleUrls: ['./refund-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RefundPopupComponent implements OnInit, AfterViewInit {


  subscriptions: Subscription = new Subscription();
  refundAmountConfig: FieldConfig[];
  _settings: SettingsDTO;
  OperationCurrency: string;
  refundOptions: any[] = [];
  @Input() data: any;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  AuthorizePaymentTypes = AuthorizePaymentType;

  constructor(private ps: PopupService, private cs: CacheService, public partyService: PartyService,
    private ts: TranslateService) {
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.OperationCurrency = this._settings.General.OperationCurrency;
    }));
  }

  ngOnInit() {
    this.data.RefundOption.forEach(element => {
      element.LoyaltyAccountNumber = element.LoyaltyAccountNumber != "null" ? element.LoyaltyAccountNumber : null;
      let refundAmountConfig: FieldConfig[] = [{
        type: 'input',
        name: 'refundAmountConfig',
        inputType: 'text',
        directive: 'DecimalNumbersOnly',
        value: this.data.RefundOption.length === 1 ? this.data.PaymentAmount : 0,
        prefix: this.OperationCurrency,
        max: element?.PaymentAmount,
        showErrorText: true,
        errorMessage: this.ts.instant('RefundAmountRequired'),
        validation: [Validators.required, Validators.max(element?.PaymentAmount)],
        disabled: this.data.RefundOption.length > 1 ? true : false
      }];
      let data;
      data = { ...element };
      data.RoomNumber = data.RoomNumber != "null" ? data.RoomNumber : null;
      data.BucketName = data.BucketName != "null" ? data.BucketName : null;
      let checked = this.data.RefundOption.length === 1;
      this.refundOptions.push({ checked, data, refundAmountConfig })

    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.components.forEach((x, index) => {
        this.subscriptions.add(x.form.valueChanges.subscribe(value => {
          var formValid = true;
          this.components['_results'].forEach(({ form }, changeindex) => {
            if (this.refundOptions[changeindex].checked && form.status === "INVALID") {
              formValid = false;
            }
          });
          if (this.isTotalAmountMatching() && formValid) {
            this.ps.saveBtnEnable$.next(true);
            this.setRefundData();

          } else {
            this.ps.saveBtnEnable$.next(false);
            if (this.isInputGreaterThanTotal()) {
              this.partyService.HandleResponse(this.ts.instant('RefundAlert'));
            }
          }
        }));
      });
      if (this.data.RefundOption.length === 1) {
        this.setRefundData();
        this.ps.saveBtnEnable$.next(true);
      } else {
        this.ps.saveBtnEnable$.next(false);
      }

    }, 100)
  }

  setRefundData() {
    this.partyService.refundData = this.components['_results'].map((res, resIndex) => {
      return {
        AuthorizeTransactionID: this.data.RefundOption[resIndex].AuthorizeTransactionID,
        Amount: +res.form.controls[res.config[0].name].value
      }
    }).filter(itm => itm.Amount);
  }

  selectType(checked, index) {
    var form = this.components['_results'][index].form;
    if (checked) {
      form.enable();
      this.isTotalAmountMatching() ? this.ps.saveBtnEnable$.next(true) : this.ps.saveBtnEnable$.next(false);
    } else {
      form.controls.refundAmountConfig.setValue(0);
      form.disable();
    }
  }

  isTotalAmountMatching() {
    var total = 0;
    this.components['_results'].forEach(element => {
      total += +element.form.controls.refundAmountConfig.value;
    });
    if (this.data.PaymentAmount) {
      return +total.toFixed(2) === +this.data.PaymentAmount.toFixed(2);
    } else {
      return +total.toFixed(2) === +this.data.OverpaymentAmount.toFixed(2);
    }

  }

  isInputGreaterThanTotal() {
    var total = 0;
    this.components['_results'].forEach(element => {
      total += +element.form.controls.refundAmountConfig.value;
    });
    if (this.data.PaymentAmount) {
      return +total.toFixed(2) > +this.data.PaymentAmount.toFixed(2);
    } else {
      return +total.toFixed(2) > +this.data.OverpaymentAmount.toFixed(2);
    }

  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
