import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { commonToggleSwitchComponent } from './common-toggle-switch.component';
import { UiSwitchModule } from 'ngx-ui-switch';



@NgModule({
  declarations: [commonToggleSwitchComponent],
  imports: [
    CommonModule,
    UiSwitchModule
  ],
  exports: [commonToggleSwitchComponent],
})
export class commonToggleSwitchModule { }
