<!-- <div class="dynamic-field form-select" [formGroup]="group">
        <label>{{ config.label }}</label>
            <select [formControlName]="config.name">
            <option value="">{{ config.placeholder }}</option>
            <option *ngFor="let option of config.options">
            {{ option }}
            </option>
        </select>
    </div> -->

<mat-form-field [formGroup]="group" [hideRequiredMarker]="!config.isStarSymbolRequired"
    [ngClass]="{'input-with-boundary':config.appearance,'read-only':config.isReadOnly}" [hidden]="config.isHidden"
    class="basic-select-dropdown {{config.class}}">
    <span class="tool-tip-title" matTooltip="{{config.label | translate}}" *ngIf="config.toolTipEnabled"> <mat-label
            class="select-label">{{config.label | translate}}</mat-label></span>
    <mat-label class="select-label" *ngIf="!config.toolTipEnabled">{{config.label | translate}}</mat-label>
    <mat-select (selectionChange)="selectedOption($event)" placeholder="{{config.placeholder | translate}}"
        [formControlName]="config.name" [(value)]="config.value" (click)="onClick($event)"
        attr.title="{{config.isTranslate ? (config.title | translate) : config.title}}" [attr.name]="config.name"
        disableOptionCentering='true' [ngClass]="{'select-color':showColor}" [multiple]="config.selectMultipleOptions"
        [panelClass]="config.panelClass">
        <div class="form-multi-select" *ngIf="config.selectMultipleOptions">
            <mat-checkbox #selectAll class="multi-select" (change)="toggleAllSelection($event.checked)"
                [checked]="isSelectAll"> {{'selectAllText' | translate}}</mat-checkbox>
        </div>
        <mat-option *ngFor="let option of config.options" [value]="option.id"
            title="{{config.isTranslate ? (option.value | translate) : (option.value)}}"
            [ngClass]="{'show-color-option':showColor, 'option-with-icon': option.isLocked}">

            <span *ngIf="config.isTranslate && !config.isTooltip && !showColor">{{ option.value | translate }}</span>
            <span *ngIf="!config.isTranslate && !config.isTooltip && !showColor">{{ option.value }}</span>
            <span class="rs-Roboto-Regular-16" *ngIf="config.isTooltip && !showColor" matTooltipClass="tooltip-class"
                title="{{option.value}}">{{ option.value }}</span>
            <div class="option-with-color" *ngIf="showColor">
                <div class="show-color" [ngStyle]="{'background-color':option.color}"></div>
                <span>{{ option.value }}</span>
            </div>
            <em *ngIf="option.isLocked" class="icon-padlock"></em>
        </mat-option>
    </mat-select>
    <mat-error
        *ngIf="group?.controls[FormControlName].hasError('required') && group?.controls[FormControlName].touched && config.showErrorText">
        {{config.errorMessage | translate}}
    </mat-error>
</mat-form-field>

<!-- <mat-form-field [formGroup]="group"  [ngClass]="config.class" class="basic-select-dropdown" *ngIf="config.showNumbers ">

            <mat-select placeholder="{{config.placeholder | translate}}"  [formControlName]="config.name" [(value)]="config.value" [attr.name]="config.name" disableOptionCentering= 'true' >
                <mat-option *ngFor="let option of config.options" [value]="option.id">
                    {{ option.value }}

                </mat-option>
            </mat-select>
            <mat-error *ngIf="group.controls[FormControlName].hasError('required') && config.showErrorText">
             Field Required
            </mat-error>
        </mat-form-field> -->

<!-- <mat-form-field [formGroup]="group"  [ngClass]="config.class" class="dropdown-boundary" *ngIf="!config.showNumbers && config.appearance">

                <mat-select placeholder="{{config.placeholder | translate}}"  [formControlName]="config.name" [(value)]="config.value" [attr.name]="config.name" disableOptionCentering= 'true' >
                    <mat-option *ngFor="let option of config.options" [value]="option.id">
                        {{ option.value }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="group.controls[FormControlName].hasError('required') && config.showErrorText">
                 Field Required
                </mat-error>
            </mat-form-field> -->