import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService } from '@app/app.service';
import { Menu } from '@constants/commonenums';

@Component({
  selector: 'app-dashboard-status',
  templateUrl: './dashboard-status.component.html',
  styleUrls: ['./dashboard-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardStatusComponent implements OnInit {

  @Input() dashboardStatus: any[];
  statusData: any = []
  header: string = "Status";
  menu_server: Menu = Menu.Servers;
  Menu = Menu;

  constructor(public _as: AppService) { }

  ngOnInit() {
  }
}
