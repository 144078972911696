<div class="cancel-session">
    <div class="location-info">
        <div class="text-muted"> {{activityData.Name}} | {{cancellationDate | formatSettingsDate:
            cs.settings.value.General.DateFormat}} | {{ sessionData.startTime | localizedDate : 'LT' }}
            - {{ sessionData.endTime | localizedDate : 'LT' }}</div>
    </div>

    <div class="alert alert-warning" role="alert">
        {{warningMessage}}
    </div>
    <div class="session-edit-customize-form-container">
        <label class="control-label">{{'CancelReason' | translate }}</label>
        <dynamic-form [config]="reasonConfig" #reasons></dynamic-form>
    </div>
</div>