import { Input, Output, EventEmitter, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ViewBy } from "@app/shared/constants/commonenums";
import { PartyDTO } from "@app/shared/models/InputContact";
import { SlotDTO } from "@app/shared/models/InputReservation";
import { Utilities } from "@app/shared/utilities/utilities";
import _ from "lodash";
import moment, { Moment } from "moment";

@Component({
  template: ''
})

export class SlotProcessor extends Utilities {

  @Input() timeArrayGenerated: Moment[];
  @Input() rowData: {
    Id: number;
    Name: string;
    CategoryId: number;
    BufferTimeInMinutes: number;
    OpenPrivateReservations: PartyDTO[];
    Sessions: any;
    StaffSchedule: any;
  };
  @Input() lastIndex: number;
  @Input() selectedViewId: number;
  @Input() OpenHours;
  @Input() TimeSlotUnitInMinutes: number;

  @Input() CurrentDateSelected: Date;
  @Input() switchAxis: boolean;

  @Input() noOfBookings: number;
  @Output('addBookingToSlot') addBookingToSlotEvent = new EventEmitter();
  @Output('activitySelected') activitySelectedEvent = new EventEmitter();
  @Output('promptBlockSession') promptBlockSessionEvent = new EventEmitter();
  @Output('modifySession') modifySessionEvent = new EventEmitter();
  @Output('cancelSession') cancelSessionEvent = new EventEmitter();
  @Output('blockStaff') blockStaffEvent = new EventEmitter();
  @Output('deleteStaffBlock') deleteStaffBlockEvent = new EventEmitter();

  allSessions: Group[];
  showExpandButton: boolean = false;
  expandMode: boolean = false;
  occupiedSlots: {
    [key: string]: boolean
  }

  constructor(protected dialog: MatDialog) { super(dialog); }


  setSessions() {
    let openSlots = this.getAllOpenBookingSlots(0, {});
    if (this.rowData) {
      let sessionReservations = this.getSessionsAndReservations();
      let slotsByReservation = this.getBufferSlots(this.rowData.BufferTimeInMinutes || 0, this.rowData.OpenPrivateReservations);
      let bufferSlots = slotsByReservation.filter(bSlot => bSlot.bufferSlot);
      let newOpenSlots = slotsByReservation.filter(bSlot => !bSlot.bufferSlot);
      let [grouppedSessions, showExpandButton] = this.setGroups([..._.orderBy([...sessionReservations, ...bufferSlots], ['State', 'StartTime']), ..._.orderBy([...openSlots, ...newOpenSlots], ['StartTime'])]);
      this.allSessions = grouppedSessions;
      this.showExpandButton = showExpandButton;
    } else {
      this.allSessions = this.getAllOpenBookingSlots(0, this.occupiedSlots);
    }

  }

  getSessionsAndReservations() {
    let allSessions = this.rowData.Sessions ? this.rowData.Sessions.filter(({ privateActivity }) => !privateActivity) : [];
    let OpenPrivateReservations = this.rowData.OpenPrivateReservations ? this.rowData.OpenPrivateReservations : [];
    let StaffSchediules = this.rowData.StaffSchedule ? this.rowData.StaffSchedule : [];
    return [...allSessions, ...OpenPrivateReservations, ...StaffSchediules];
  }

  getBufferSlots(bufferTime, reservations) {
    let bufferSlots = [];
    reservations.forEach(reservation => {

      //Before reservation Buffer
      let additionalSlotBeforeTime = new Date(reservation.StartTime);
      additionalSlotBeforeTime.setMinutes(additionalSlotBeforeTime.getMinutes() - bufferTime);
      if(this.timeArrayGenerated[1].diff(additionalSlotBeforeTime) <= 0){    
        let additionalSlotBefore = this.getOpenSlotDataObj(additionalSlotBeforeTime, bufferTime, true)[0];
        bufferSlots.push(additionalSlotBefore);
      }

      let additionalOpenSlotBeforeTime = new Date(additionalSlotBeforeTime);
      additionalOpenSlotBeforeTime.setMinutes(additionalOpenSlotBeforeTime.getMinutes() - this.TimeSlotUnitInMinutes - bufferTime);
      if(this.timeArrayGenerated[1].diff(additionalOpenSlotBeforeTime) <= 0){
        let additionalOpenSlotBefore = this.getOpenSlotDataObj(additionalOpenSlotBeforeTime, this.TimeSlotUnitInMinutes, false)[0];
        bufferSlots.push(additionalOpenSlotBefore);
      }

      //After Reservation Buffer
      let bufferSlot = this.getOpenSlotDataObj(new Date(reservation.EndTime), bufferTime, true)[0];
      bufferSlots.push(bufferSlot);

      //New Open Slot
      let additionalSlotTime = new Date(bufferSlot.DateTime.split('T')[0] + 'T' + bufferSlot.EndTime);
      let additionalSlot = this.getOpenSlotDataObj(additionalSlotTime, this.TimeSlotUnitInMinutes, false)[0];

      bufferSlots.push(additionalSlot);
    });
    return bufferSlots;
  }

  activitySelected(slot, instructor) {
    if(this.selectedViewId !== ViewBy.Instructor)
    this.activitySelectedEvent.emit([slot, instructor]);
  }

  addBookingToSlot(instructor, column, index) {
    this.addBookingToSlotEvent.emit([instructor, column, index])
  }

  promptBlockSession(activityData, item) {
    this.promptBlockSessionEvent.emit([activityData, item])
  }

  modifySession(activityData, item) {
    this.modifySessionEvent.emit([activityData, item])
  }
  cancelSession(activityData, item) {
    this.cancelSessionEvent.emit([activityData, item])
  }

  blockStaff(activityData,item) {
    this.blockStaffEvent.emit([activityData, item]);
  }

  deleteStaffBlock(activityData,item) {
    this.deleteStaffBlockEvent.emit([activityData, item]);
  }
  setGroups(allSessions): [Group[], false] {
    let groupItems: Group[] = [];
    let showExpandButton: boolean = false
    // allSessions = _.sortBy(allSessions, 'StartTime');
    allSessions.forEach(sessionItem => {

      if (!this.occupiedSlots) {
        this.occupiedSlots = {};
      }
      for (let idx = sessionItem.gridColumnStart; idx <= sessionItem.gridColumnEnd; idx++) {
        this.occupiedSlots[idx] = true;
      }

      let groupDetail = groupItems.find(groupItem => {
        return sessionItem.gridColumnStart === groupItem.gridColumnStart
          || sessionItem.gridColumnEnd === groupItem.gridColumnEnd
          || (sessionItem.gridColumnStart > groupItem.gridColumnStart && sessionItem.gridColumnStart < groupItem.gridColumnEnd)
          || (sessionItem.gridColumnStart <= groupItem.gridColumnStart && sessionItem.gridColumnEnd >= groupItem.gridColumnEnd)
          || (groupItem.gridColumnStart > sessionItem.gridColumnStart && groupItem.gridColumnStart < sessionItem.gridColumnEnd)
          || (groupItem.gridColumnStart <= sessionItem.gridColumnStart && groupItem.gridColumnEnd >= sessionItem.gridColumnEnd)
      })
      sessionItem.tempUniqValueData = [sessionItem.StartTime, sessionItem.Id].join(',');
      if (!groupDetail) {
        groupDetail = {
          gridColumnStart: sessionItem.gridColumnStart,
          gridColumnEnd: sessionItem.gridColumnEnd,
          items: [sessionItem],
          occupiedColumns: {},
          rows: 1,
          group: true,
          defaultMaxRowDisplay: 0
        }

        for (let col = sessionItem.gridColumnStart; col < sessionItem.gridColumnEnd; col++) {
          groupDetail.occupiedColumns["_" + col] = groupDetail.occupiedColumns["_" + col] ? groupDetail.occupiedColumns["_" + col] + 1 : 1;
        }

        groupItems.push(groupDetail);
      } else {

        if (!sessionItem.openBookingSlot || (sessionItem.openBookingSlot && !(groupDetail.items.find(groupItem => groupItem.bufferSlot || !groupItem.openBookingSlot || (groupItem.openBookingSlot && groupItem.gridColumnStart === sessionItem.gridColumnStart))))) {

          if (groupDetail.gridColumnStart > sessionItem.gridColumnStart) groupDetail.gridColumnStart = sessionItem.gridColumnStart;
          if (groupDetail.gridColumnEnd < sessionItem.gridColumnEnd) groupDetail.gridColumnEnd = sessionItem.gridColumnEnd;
          for (let col = sessionItem.gridColumnStart; col < sessionItem.gridColumnEnd; col++) {
            groupDetail.occupiedColumns["_" + col] = groupDetail.occupiedColumns["_" + col] ? groupDetail.occupiedColumns["_" + col] + 1 : 1;
            if (groupDetail.occupiedColumns["_" + col] > groupDetail.rows) {
              groupDetail.rows = groupDetail.occupiedColumns["_" + col];
            }
          }
          groupDetail.items.push(sessionItem);
          if (groupDetail.items.filter(groupItem => !groupItem.openBookingSlot).length > 1) {
            showExpandButton = true;
          }

        }
      }

    });


    return [groupItems, showExpandButton];
  }


  getSlotIndex([classHour = 0, classMinutes = 0], timeArrayGenerated) {
    return timeArrayGenerated.findIndex((time) => time.minutes() === +classMinutes && time.hours() === +classHour);
  }

  getAllOpenBookingSlots(bufferTime = 0, occupiedSlots = {}) {
    let openBookingSlots = [];
    let shiftArray = _.cloneDeep(this.OpenHours);
    let date = this.CurrentDateSelected;
    let dayOfWeek = moment.isMoment(date) ? date.day() : date.getDay();
    let allShifts = shiftArray.filter(shift => shift.DayOfWeek == dayOfWeek);
    const Slotval = this.TimeSlotUnitInMinutes;

    if (allShifts && allShifts.length) {
      allShifts.sort((a: any, b: any) => {
        let _a: any = new Date(a.EffectiveRange.Start);
        let _b: any = new Date(b.EffectiveRange.Start);
        return _a - _b;
      })

      let Startval = allShifts[0].EffectiveRange.Start.toString().split('Z')[0];
      Startval = this.formatDate(date) + 'T' + Startval.split('T')[1];
      let endDate = new Date(this.CurrentDateSelected);
      if (new Date(allShifts[allShifts.length - 1].EffectiveRange.End).getDate() > new Date(allShifts[0].EffectiveRange.Start).getDate()) {
        endDate.setDate(date.getDate() + 1)
      }
      let Endvalue = allShifts[allShifts.length - 1].EffectiveRange.End.toString().split('Z')[0];
      const Endval = new Date(this.formatDate(endDate) + 'T' + Endvalue.split('T')[1]);
      let Startloop = new Date(Startval);
      let estEndLoop = new Date(Startval);
       estEndLoop.setMinutes(estEndLoop.getMinutes() + Slotval );

      while (estEndLoop <= Endval) {
        let [slot, startloop] = this.getOpenSlotDataObj(Startloop, Slotval);
        Startloop = startloop;

        let availableSlot = true;
        if (slot.gridColumnStart == slot.gridColumnEnd - 1) {
          if (occupiedSlots[slot.gridColumnStart] && occupiedSlots[slot.gridColumnEnd]) {
            availableSlot = false;
          }
        } else {
          for (let idx = slot.gridColumnStart + 1; idx < slot.gridColumnEnd; idx++) {
            if (occupiedSlots[idx]) {
              availableSlot = false;
              break;
            }
          }
        }
        if (availableSlot) {
          openBookingSlots.push(slot);
        }

        //Buffer slots
        if (Startloop <= Endval && bufferTime) {
          let [slot, startloop] = this.getOpenSlotDataObj(Startloop, bufferTime, true);
          Startloop = startloop;
          openBookingSlots.push(slot);
        }

        estEndLoop = new Date(startloop);
        estEndLoop.setMinutes(estEndLoop.getMinutes() + Slotval );
      }

      openBookingSlots.sort((a: any, b: any) => {
        let _a: any = new Date(a.startTime);
        let _b: any = new Date(b.startTime);
        return _a - _b;
      });
    }

    return openBookingSlots
  }

  getOpenSlotDataObj(Startloop, Slotval, bufferSlot?) {
    let slotObj;
    const tempTimeSlot = Startloop;
    const slot: SlotDTO = <SlotDTO>{};
    let slotTime = Utilities.Date(Startloop);
    slot.DateTime = slotTime.format('YYYY-MM-DDTHH:mm:ss');
    let endTime = new Date(slot.DateTime);
    endTime.setMinutes(endTime.getMinutes() + Slotval);
    let gridColumnStart = this.getSlotIndex([slotTime.hours(), +slotTime.minutes()], this.timeArrayGenerated) + 1;
    let gridColumnEnd = this.getSlotIndex([endTime.getHours(), +endTime.getMinutes()], this.timeArrayGenerated) + 1;
    if (gridColumnEnd < gridColumnStart) {
      /** When Per slot time configuration is updated after some booking created */
      // let numOfSlot = ((endTime.getHours()*60) + endTime.getMinutes() - (slotTime.hours()*60) + slotTime.minutes()) / 5;
      // gridColumnEnd = gridColumnStart + numOfSlot;
      gridColumnEnd = this.timeArrayGenerated.length + 1;
    }
    slotObj = { StartTime: slotTime.format('HH:mm:ss'), EndTime: moment(endTime).format('HH:mm:ss'), bufferSlot, openBookingSlot: true, gridColumnStart, gridColumnEnd, DateTime: slot.DateTime ,endTime: endTime};
    const newMinites = new Date(tempTimeSlot).getMinutes() + Slotval;
    const newDate = tempTimeSlot.setMinutes(newMinites);
    Startloop = new Date(newDate);

    return [slotObj, Startloop]
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
}

interface Group {
  gridColumnStart: number;
  gridColumnEnd: number,
  items: any[],
  occupiedColumns: {
    [key: string]: number
  },
  rows: number,
  group: boolean,
  defaultMaxRowDisplay: number
}