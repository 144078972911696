<!--categories filter-->
<section [formGroup]="SalesByDiscountTypeFormGrp" autocomplete="off">
  <div>
    <app-retail-multi-select-dropdown *ngIf="allCategoryGroups" [dropDownFilterData] = "allCategoryGroups" [dropDownControlname] = "'reportcategory'" [dropDownName] = captions.ReportCategory (dropDownFrmControl) = "getFormControlValue($event)"  (categoryGroup) = "getCategoryGroupValue($event)"></app-retail-multi-select-dropdown>
  </div>

  <div>
    <app-retail-multi-select-dropdown *ngIf="categories" [dropDownFilterData] = "categories" [dropDownControlname] = "'category'" [dropDownName] = captions.Category (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>

  </div>

  <div> 

    <app-retail-multi-select-dropdown *ngIf="discountTypes" [dropDownFilterData] = "discountTypes" [dropDownControlname] = "'discounttype'" [dropDownName] = captions.Discounts (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
  </div>

  <div class="mb-3">
    <label class="LW14">{{this.localization.captions.reports.SummaryView}}</label>
    <app-retail-toggle-switch class="d-block" formControlName="summaryView"></app-retail-toggle-switch>
  </div>

</section>
