<div class="sheet">
  <table aria-describedby="" class="page" *ngFor="let page of job">
    <tr *ngFor="let row of page">
      <td *ngFor="let col of row">
        <div class="description">{{ col.itemDescription }}</div>
        <div class="barcode">
          <ngx-barcode [bc-element-type]="svg" [bc-value]="col.barcode" [bc-display-value]="true" [bc-format]="CODE128"
            [bc-font-size]="12" [bc-height]="18" [bc-text-position]="top" [bc-margin-top]="5" [bc-margin-bottom]="5"
            [bc-margin-left]="1" [bc-margin-right]="0"></ngx-barcode>
        </div>
        <div class="price" *ngIf="col.itemPrice > 0">{{ price }}: {{ col.itemPrice | Currency }}</div>
      </td>
    </tr>
  </table>
</div>