import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes, ReservationType } from '@constants/commonenums';
import { popupDialogDimension } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { DropDownConfig, FieldConfig } from '@dynamicform/models/field-config.interface';
import { SeatingAreaDTO, SeatingTypesDTO, SettingsDTO } from '@models/RestaurantDTO';
import { AllowedSettingsPublish } from '@models/SignalRDto';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { cloneDeep, uniqBy} from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-general-data',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GeneralDataComponent implements OnInit, AfterViewInit, OnDestroy {
  generalDateConfig: FieldConfig[];
  generalAreaConfig: FieldConfig[];
  generalTypeConfig: FieldConfig[];
  generalSizeConfig: FieldConfig[];
  seatingArea: SeatingAreaDTO[];
  seatingType: SeatingTypesDTO[];
  maxPartySizeAllowed = 51;
  selectedPartySize = 2;
  selectedArea = 'Any';
  partySize: any = [];
  partySizeArray: any = [];
  seatingAreaData: any;
  seatingTypeData: DropDownConfig[];
  selectedIndex: number;
  displayLegends: any;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  subscriptions: Subscription = new Subscription();
  notFirstTime: boolean;
  isReservationtypeReserve: boolean;
  refreshDialogRef: MatDialogRef<any>;
  nonRequiredControls : any[] = ['selectedSpecialMeal', 'selectedCoverTypes', 'selectedTable',
                                  'selectedHotelId', 'selectedConciergeId', 'selectedPagerNumber',
                                  'selectedPartyStatus', 'selectedBookingTypes','selectedSalesContactIds'];

  constructor(private cs: CacheService, @Inject(COMPONENTINPUT) private data, private dialog: MatDialog,
    public partyService: PartyService, private fb: UntypedFormBuilder, private ps: PopupService, private ts: TranslateService, public appService: AppService) {
    this.cs.settings.subscribe((settings) => {
      if (this.generalDateConfig && this.generalDateConfig.length) {
        const generalDateConfig = cloneDeep(this.generalDateConfig);
        this.generalDateConfig = [];
        this.generalDateConfig = [...generalDateConfig];
      }
    })
  }

  ngOnInit() {
    this.checkReservationOrWaitlist();
    this.displayLegends = [{ State: 9, name: this.ts.instant('availableForStandby') },
    { State: 8, name: this.ts.instant('partyStatusNotAvailableText') },
    { State: 7, name: this.ts.instant('partyStatusAvailableText') },
    { State: 11, name: this.ts.instant('mayBeOverbookedText') }];
    const maxPartySize: number = this.cs.settings.value.General.MaxPartySize;
    let reservationDate: any;
    let restaurantDate = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);    
    if (this.data) {
      switch(this.partyService.reservationType) {
        case ReservationType.Reservation:
          reservationDate = Utilities.formateDateString(this.data.ReservedFor);
          break;
        case ReservationType.StandbyParties:
          reservationDate = Utilities.formateDateString(this.data.WishedTime);
          break;
        case ReservationType.Waitlist:
          reservationDate = Utilities.formateDateString(this.data.ArrivedAt);
          break;
        default:
          reservationDate = restaurantDate;
      }
    } else {
      reservationDate = this.appService.headerDate$.value > restaurantDate ? this.appService.headerDate$.value : restaurantDate
    }
    if (this.partyService.reservationType === ReservationType.Reservation) {
      Object.keys(this.partyService.reservationFormGroup.controls).forEach((control:string) => {
        if (this.nonRequiredControls.findIndex(controlName => controlName == control) == -1) {
          this.partyService.reservationFormGroup.get(control).setValidators(Validators.required);
          this.partyService.reservationFormGroup.get(control).updateValueAndValidity();
        }
      });
      this.getSlots();
    } else if (this.partyService.reservationType === ReservationType.Waitlist) {
      this.partyService.reservationFormGroup = this.fb.group({
        selectedSize: ['', Validators.required],
        selectedArea: ['', Validators.required],
        selectedTime: ['', Validators.required],
        selectedDate: [''],
        selectedTable: [''],
        selectedSpecialMeal: [''],
        selectedGuest: ['', Validators.required],
        selectedCoverTypes: [''],
        selectedPagerNumber: [''],
        selectedType: ['', Validators.required],
        selectedPartyStatus: ['']
      });
    }
    else if (this.partyService.reservationType === ReservationType.StandbyParties) {
      Object.keys(this.partyService.reservationFormGroup.controls).forEach((control) => {
        if (control !== 'selectedSpecialMeal' && control !== 'selectedCoverTypes' && control !== 'selectedTable' && control !== 'selectedHotelId' && control !== 'selectedConciergeId' && control !== 'selectedPagerNumber'&& control !== 'selectedPartyStatus' && control !== 'selectedBookingTypes' &&  control !== 'selectedSalesContactIds') {
          this.partyService.reservationFormGroup.get(control).setValidators(Validators.required);
          this.partyService.reservationFormGroup.get(control).updateValueAndValidity();
        }
      });
      this.getSlots();
    }
    this.generalDateConfig = [
      {
        type: 'date',
        name: 'date',
        inputType: 'text',
        label: 'guestBookDetailsDOB',
        class: 'dob',
        value: reservationDate,
        appearance: false
      }];
    this.generalAreaConfig = [{
      type: 'select',
      label: 'seatingArea',
      name: 'seatingArea',
      options: this.seatingArea,
      class: 'seatingarea',
      showErrorText: true,
      isTranslate: false,
      cellClick: (event) => { this.setSeatingAreaBasedonType(event); }
    }];

    this.generalTypeConfig = [{
      type: 'select',
      label: this.ts.instant('seatingType'),
      name: 'seatingType',
      options: this.seatingType,
      class: 'seating-type',
      showErrorText: true,
      isTranslate: false
    }];
    this.generalSizeConfig =
      [{
        type: 'autocomplete',
        name: 'size',
        options: this.partySizeArray,
        directive: 'numberOnly',
        class: 'party-size',
        showErrorText: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        appearance: false,
        isTranslate: false,
        value: this.partySizeArray[0],
        partySize: true,
        charLength: maxPartySize.toString().length,
        cellClick: (event) => { this.cellClick(event) },
        returnFunction: (event) => { this.clearSize(event) },
        validation: [Validators.required, Validators.min(1)]
      }];

    this.setSeatingArea();
    this.setSeatingType();
    this.selectedPartySize = 2;
    this.selectedArea = 'Any';
    this.generalSizeConfig[0].options = this.calculatePartySize();
    this.generalSizeConfig[0].value = this.generalSizeConfig[0].options.filter(x => x.value == 2)[0].id;
    this.partyService.reservationFormGroup.get('selectedSize').setValue(this.generalSizeConfig[0].options.filter(x => x.value == 2)[0].value);
    if (this.partyService.reservationType === ReservationType.Waitlist && !this.data) { /*for edit getAutowaittime is called at the end of the method*/
      const partyDetails = this.data ? this.data : {
        SeatingAreaId: this.partyService.reservationFormGroup.value.selectedArea.Id,
        ReservedFor: this.partyService.reservationFormGroup.value.selectedTime.DateTime
      };
      this.partyService.getAutoWaitTime(this.partyService.reservationFormGroup.get('selectedSize').value, partyDetails);
    }
    else if (this.partyService.reservationType === ReservationType.StandbyParties) {
      if (this.data) {
        this.partyService.reservationFormGroup.get('selectedDate').setValue(Utilities.formateDateString(this.data.WishedTime));
        if (this.data.isPastReservation) {
          this.generalDateConfig[0].minDate = this.partyService.reservationFormGroup.value.selectedDate;
          this.generalDateConfig[0].maxDate = this.partyService.reservationFormGroup.value.selectedDate;
        }
      }
    }
    else if (this.partyService.reservationType === ReservationType.Reservation) {
      if (this.data) {
        this.partyService.reservationFormGroup.get('selectedDate').setValue(Utilities.formateDateString(this.data.ReservedFor));
        this.getSpecialMealEndDate();
        if (this.data.isPastReservation) {
          this.generalDateConfig[0].minDate = this.partyService.reservationFormGroup.value.selectedDate;
          this.generalDateConfig[0].maxDate = this.partyService.reservationFormGroup.value.selectedDate;
        }
      }
      else
        this.partyService.reservationFormGroup.get('selectedDate').setValue(reservationDate);
    }
    this.partyService.selectedSize = this.generalSizeConfig[0].options.filter(x => x.value == 2)[0].value;
    this.partyService.reservationFormGroup.get('selectedArea').setValue(this.generalAreaConfig[0].value);
    this.partyService.reservationFormGroup.get('selectedArea').setValue(-1);
    this.partyService.reservationFormGroup.get('selectedType').setValue(this.generalTypeConfig[0].value);
    this.partyService.reservationFormGroup.get('selectedType').setValue(-1);
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === GeneralDataComponent);

    if (this.data) {
      const partyDetails = cloneDeep(this.data)
      partyDetails.ReservedFor = this.partyService.reservationType == ReservationType.StandbyParties ? this.data.WishedTime : partyDetails.ReservedFor;
      this.partyService.getAutoWaitTime(this.data.Size, partyDetails);
    }
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.cs.settingsOperationName.subscribe(op => {
      if (this.notFirstTime && AllowedSettingsPublish[op]) {
        this.showSettingsChangedInfo();
        this.resetForm();
        this.setSeatingArea();
        this.setSeatingType();
      }
      this.notFirstTime = true;
    }));
    if (this.data) {
      if (this.partyService.reservationType === ReservationType.Reservation || this.partyService.reservationType === ReservationType.StandbyParties) {
        this.partyService.bookingSize = this.data.Size;
        this.partyService.reservationFormGroup.get('selectedSize').setValue(this.data.Size);
        this.partyService.reservationFormGroup.get('selectedArea').setValue(this.data.SeatingAreaId ? this.data.SeatingAreaId : -1);
      }
      switch(this.partyService.reservationType) {
        case ReservationType.Reservation:
          this.setDateByType(this.data.ReservedFor);
          break;
        case ReservationType.Waitlist:
          this.setDateByType(this.data.ArrivedAt);
          break;
        case ReservationType.StandbyParties:
          this.setDateByType(this.data.WishedTime);
          break;
      }
      this.components.forEach((x: DynamicFormComponent, index) => {
        if (index == 0) {
          x.form.get('size').setValue(this.data.Size);
          this.partyService.reservationFormGroup.get('selectedSize').setValue(this.data.Size);
        }
        if (this.partyService.reservationType === ReservationType.Waitlist && index == 1) {
          if (this.data.SeatingAreaId == null) {
            x.form.get('seatingArea').setValue(-1);
            this.partyService.reservationFormGroup.get('selectedArea').setValue(-1);
          } else {
            x.form.get('seatingArea').setValue(this.data.SeatingAreaId);
            this.partyService.reservationFormGroup.get('selectedArea').setValue(this.data.SeatingAreaId);
          }
        }
        if (this.partyService.reservationType === ReservationType.Reservation && index == 2) {
          if (this.data.SeatingAreaId == null) {
            x.form.get('seatingArea').setValue(-1);
            this.partyService.reservationFormGroup.get('selectedArea').setValue(-1);
          } else {
            x.form.get('seatingArea').setValue(this.data.SeatingAreaId);
            this.partyService.reservationFormGroup.get('selectedArea').setValue(this.data.SeatingAreaId);
          }
        }
        if ((this.partyService.reservationType === ReservationType.Reservation && index == 3) || (this.partyService.reservationType === ReservationType.Waitlist && index == 2)) {
          this.setSeatingAreaBasedonType(this.data.SeatingAreaId);
          if (this.data.SeatingTypeId == null) {
            x.form.get('seatingType').setValue(-1);
            this.partyService.reservationFormGroup.get('selectedType').setValue(-1);
          } else {
            x.form.get('seatingType').setValue(this.data.SeatingAreaId);
            this.partyService.reservationFormGroup.get('selectedType').setValue(this.data.SeatingTypeId);
          }
        }
      });
    }
    this.components.forEach((x, index) => {
      if (index == 0) {
        this.subscriptions.add(x.form.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(value => {
          if (+value.size > 0) {
            value.size = value.size.toString();
            value.size = (value.size.includes('+')) ? value.size.slice(0, -1) : value.size;
            this.reservationFormChange(value.size);
            this.partyService.isOverBookSlotSelected = false;
            this.partyService.standbyConversion = false;
            this.partyService.isStandbySlotSelected = false;
            this.partyService.reservationFormGroup.get('selectedSize').setValue(value.size);
            this.partyService.setSlots(this.partyService.slotsinTimeRange, value.size);
            this.partyService.selectedSize = +value.size;
            if (this.partyService.reservationType == ReservationType.StandbyParties) {
              this.partyService.reservationFormGroup.get('selectedTime').setValue('');
            }
          }
          else {
            this.partyService.reservationFormGroup.get('selectedSize').setValue('');
          }
          this.makeReservationFormDirty();
          this.updateFormValidity();
        }));
      }
      if (this.partyService.reservationType === ReservationType.Waitlist && index == 1) {
        this.subscriptions.add(x.form.valueChanges.subscribe(value => {
          let data = this.seatingArea.filter(x => x.Id == value.seatingArea)[0];
          this.partyService.reservationFormGroup.get('selectedTable').setValue('');
          this.generalTypeConfig[0].value = -1;
          let selectedTypeForm = this.components.filter((x, index) => index == 2)[0]
          selectedTypeForm.form.get('seatingType').setValue(-1, { emitEvent: false });
          this.setSelected(data);
          this.makeReservationFormDirty();
          this.updateFormValidity();
        }));
      }
      if (this.partyService.reservationType === ReservationType.Reservation && index == 1) {
        this.subscriptions.add(x.form.valueChanges.subscribe(value => {
          this.partyService.reservationFormGroup.get('selectedDate').setValue(value.date);
          this.partyService.reservationFormGroup.get('selectedTime').setValue('');
          if (this.partyService.reservationType === ReservationType.Reservation) {
            this.partyService.reservationFormGroup.get('selectedTime').setValue('');
            this.partyService.getTimeDetails(value.date);
          }
          this.makeReservationFormDirty();
          this.updateFormValidity();
        }));
      }
      if (this.partyService.reservationType === ReservationType.StandbyParties && index == 1) {
        this.subscriptions.add(x.form.valueChanges.subscribe(value => {
          this.partyService.reservationFormGroup.get('selectedDate').setValue(value.date);
          this.partyService.reservationFormGroup.get('selectedTime').setValue('');
          if (this.partyService.reservationType === ReservationType.StandbyParties) {
            this.partyService.reservationFormGroup.get('selectedTime').setValue('');
            this.partyService.getTimeDetails(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
          }
          this.makeReservationFormDirty();
          this.updateFormValidity();
        }));
      }
      if (this.partyService.reservationType === ReservationType.StandbyParties && index == 2) {
        this.subscriptions.add(x.form.valueChanges.subscribe(value => {
          let data = this.seatingArea.filter(x => x.Id == value.seatingArea)[0];
          this.partyService.reservationFormGroup.get('selectedTable').setValue('');
          this.generalTypeConfig[0].value = -1;
          let selectedTypeForm = this.components.filter((x, index) => index == 3)[0]
          selectedTypeForm.form.get('seatingType').setValue(-1, { emitEvent: false });
          this.setSelected(data);
          this.makeReservationFormDirty();
          this.updateFormValidity();
        }));
      }
      if (this.partyService.reservationType === ReservationType.Reservation && index == 2) {
        this.subscriptions.add(x.form.valueChanges.subscribe(value => {
          this.partyService.reservationFormGroup.get('selectedArea').setValue(value.seatingArea);
          let data = this.seatingArea.filter(x => x.Id == value.seatingArea)[0];
          this.partyService.reservationFormGroup.get('selectedTable').setValue('');
          this.generalTypeConfig[0].value = -1;
          let selectedTypeForm = this.components.filter((x, index) => index == 3)[0]
          selectedTypeForm.form.get('seatingType').setValue(-1, { emitEvent: false });
          this.setSelected(data);
          this.makeReservationFormDirty();
          this.updateFormValidity();
        }));
      }
      if (this.partyService.reservationType === ReservationType.Waitlist && index == 2) {
        this.subscriptions.add(x.form.valueChanges.subscribe(value => {
          this.partyService.reservationFormGroup.get('selectedType').setValue(value.seatingType);
          let data = this.seatingType.filter(x => x.Id == value.seatingType)[0];
          this.partyService.reservationFormGroup.get('selectedTable').setValue('');
          this.setSelectedSeatingType(data);
          this.makeReservationFormDirty();
          this.updateFormValidity();
        }));
      }
      if (index == 3) {
        this.subscriptions.add(x.form.valueChanges.subscribe(value => {
          this.partyService.reservationFormGroup.get('selectedType').setValue(value.seatingType);
          let data = this.seatingType.filter(x => x.Id == value.seatingType)[0];
          this.partyService.reservationFormGroup.get('selectedTable').setValue('');
          this.setSelectedSeatingType(data);
          this.makeReservationFormDirty();
          this.updateFormValidity();
        }));
      }
    });
    this.subscriptions.add(this.partyService.tabChange$.subscribe((data) => {
      if (data === this.selectedIndex) {
        this.ps.formValid$.next({
          isFormValid: this.partyService.reservationFormGroup.value.selectedTime ? true : false,
          currentTab: this.selectedIndex
        } as IFormValidDetails);
        var _selectedArea = this.partyService.reservationFormGroup.controls.selectedArea.value;
        var _selectedType = this.partyService.reservationFormGroup.controls.selectedType.value
        this.generalAreaConfig[0].value = _selectedArea && _selectedArea.Id ? _selectedArea.Id : _selectedArea;
        this.generalTypeConfig[0].value = _selectedType && _selectedType.Id ? _selectedType.Id : _selectedType;
      }
    }));
  }

  setDateByType(date: any) {
    this.partyService.reservationFormGroup.get('selectedDate').setValue(Utilities.formateDateString(date));
    this.generalDateConfig[0].value = Utilities.formateDateString(date);
  }

  checkReservationOrWaitlist() {
    this.isReservationtypeReserve = (this.partyService.reservationType === ReservationType.Reservation || this.partyService.reservationType === ReservationType.StandbyParties) ? true : false;
  }

  setSeatingArea() {
    this.seatingArea = this.cs.settings.value.SeatingAreas;
    this.seatingArea.forEach((area) => { if (area.Id === null) { area.Id = -1; } return area; });
    this.seatingArea.unshift(this.seatingArea.splice(this.seatingArea.findIndex(item => item.Id === -1), 1)[0]);
    this.generalAreaConfig[0].options = this.seatingArea.map(x => ({ id: x.Id, value: x.Name }));
    this.generalAreaConfig[0].value = this.generalAreaConfig[0].options.filter(x => x.id == -1)[0].id;
    this.seatingAreaData = this.seatingArea.map(x => ({ id: x.Id, value: x.Name }));
  }

  setSeatingType() {
    this.seatingType = this.cs.settings.value.SeatingTypes.filter(sType => sType.IsDeleted == false);
    this.seatingType.forEach((type) => { if (type.Id === null) { type.Id = -1; } return type; });
    this.seatingType.unshift(this.seatingType.splice(this.seatingType.findIndex(item => item.Id === -1), 1)[0]);
    this.seatingTypeData = this.seatingType.map(x => ({ id: x.Id, value: x.Description }));
    this.generalTypeConfig[0].options = cloneDeep(this.seatingTypeData);
    this.generalTypeConfig[0].value = -1;
  }

  setSeatingAreaBasedonType(event) {
    let value = isNaN(Number(event)) ? event.value : event;
    let seatingAreaMappings = this.cs.settings.value.SeatingTypeSeatingAreaMappings.filter(x => x.SeatingAreaId == value);
    if (value && value != -1) {
      let options = [];
      options.push(this.seatingTypeData.filter(x => x.id == -1)[0]);
      seatingAreaMappings.forEach(element => {
        let seatingTypes = this.seatingTypeData.filter(x => x.id == element.SeatingTypeId);
        if (seatingTypes && seatingTypes.length) {
          seatingTypes.forEach(seatingType => options.push(seatingType));
        }
      });
      this.generalTypeConfig[0].options = [];
      options = uniqBy(options, 'id');
      this.generalTypeConfig[0].options = options;
    } else {
      this.generalTypeConfig[0].options = cloneDeep(this.seatingTypeData);
    }
    this.generalTypeConfig[0].value = -1;
    this.partyService.reservationFormGroup.get('selectedType').setValue(this.generalTypeConfig[0].value);
  }

  getSlots() {
    if (this.data) {
      if (this.partyService.reservationType === ReservationType.Reservation)
        this.partyService.getTimeDetails(Utilities.formateDateString(this.data.ReservedFor), null, null, null, this.data.Id);
      else if (this.partyService.reservationType === ReservationType.Waitlist)
        this.partyService.getTimeDetails(Utilities.formateDateString(this.data.ArrivedAt));
      else if (this.partyService.reservationType === ReservationType.StandbyParties)
        this.partyService.getTimeDetails(Utilities.formateDateString(this.data.WishedTime));
    }
    else {
      let restaurantDate = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
      this.partyService.getTimeDetails(this.appService.headerDate$.value > restaurantDate ? this.appService.headerDate$.value : restaurantDate);
    }
  }

  calculatePartySize() {
    this.partySizeArray = [];
    for (let i = 1; i <= this.maxPartySizeAllowed; i++) {
      this.partySizeArray.push({ id: i, value: i.toString() });
    }
    this.partySizeArray[this.maxPartySizeAllowed - 1].value = this.partySizeArray[this.maxPartySizeAllowed - 1].value + '+';
    //this.partySize =  partySizeArray;this.
    return this.partySizeArray;
  }

  resetForm() {
    this.components.forEach((x, index) => {
      switch (index) {
        case 0:
          if (this.data) {
            x.form.get('size').setValue(this.data.Size);
            this.partyService.reservationFormGroup.get('selectedSize').setValue(this.data.Size);
          } else {
            x.form.get('size').setValue(this.partySizeArray[1].value);
            this.partyService.reservationFormGroup.get('selectedSize').setValue(this.generalSizeConfig[0].options.filter(x => x.value == 2)[0].value);
          }
          break;
        case 1:
          if (this.partyService.reservationType === ReservationType.Waitlist) {
            if (!this.data || this.data.SeatingAreaId == null) {
              x.form.get('seatingArea').setValue(-1);
              this.partyService.reservationFormGroup.get('selectedArea').setValue(-1);
            } else {
              x.form.get('seatingArea').setValue(this.data.SeatingAreaId);
              this.partyService.reservationFormGroup.get('selectedArea').setValue(this.data.SeatingAreaId);
            }
          } else if (this.partyService.reservationType === ReservationType.Reservation) {
            if (this.data) {
              x.form.get('date').setValue(Utilities.formateDateString(this.data.ReservedFor));
              this.partyService.reservationFormGroup.get('selectedDate').setValue(Utilities.formateDateString(this.data.ReservedFor));
              if (this.data.isPastReservation) {
                this.generalDateConfig[0].minDate = this.partyService.reservationFormGroup.value.selectedDate;
                this.generalDateConfig[0].maxDate = this.partyService.reservationFormGroup.value.selectedDate;
              }
              this.partyService.reservationFormGroup.get('selectedTime').setValue(this.partyService.slots_holder.filter((data) => data.DateTime === this.data.ReservedFor)[0]);
            }
            else {
              x.form.get('date').setValue(new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)));
              this.partyService.reservationFormGroup.get('selectedDate').setValue(new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)));
            }
          }
          break;
        case 2:
          if (!this.data || this.data.SeatingAreaId == null) {
            x.form.get('seatingArea') && x.form.get('seatingArea').setValue(-1);
            this.partyService.reservationFormGroup.get('selectedArea').setValue(-1);
          } else {
            x.form.get('seatingArea').setValue(this.data.SeatingAreaId);
            this.partyService.reservationFormGroup.get('selectedArea').setValue(this.data.SeatingAreaId);
          }
          break;

      }
    });
  }

  cellClick(event) {
    this.selectedPartySize = event.option.value;
  }

  setType(type: ReservationType) {
    this.partyService.reservationType = null;
    this.partyService.reservationType = type;
  }

  clearSize(event) {
    this.components.forEach((x: DynamicFormComponent, index) => {
      if (index === 0) {
        x.form.get('size').setValue('');
      }
    });
  }

  updateFormValidity() {
    this.ps.formValid$.next(
      {
        isFormValid: this.partyService.reservationFormGroup.value.selectedTime &&
          this.partyService.reservationFormGroup.value.selectedSize ? true : false,
        currentTab: this.selectedIndex
      } as IFormValidDetails
    );
  }

  reservationFormChange(partySize) {
    if (this.partyService.reservationFormGroup.get('selectedSize').value !== partySize) {
      let seatingareaval = this.partyService.reservationFormGroup.value.selectedArea ? this.partyService.reservationFormGroup.value.selectedArea.Id : -1;
      const partyDetails = this.getPartyDetailsForSeatingEstimate(this.seatingArea.filter(x => x.Id == seatingareaval)[0])
      // For standby request
      partyDetails.ReservedFor = this.partyService.isStandbyData(this.data) ? this.data.WishedTime : partyDetails.ReservedFor;
      if (partySize != "" && +partySize > 0) {
        this.partyService.getAutoWaitTime(partySize, partyDetails);
      }
    }
  }

  makeReservationFormDirty() {
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
  }

  setSelected(area: SeatingAreaDTO) {
    const defaultSelectedArea = this.partyService.reservationFormGroup.value.selectedArea;
    if (area.Id !== defaultSelectedArea.Id) {
      // ToDO: Auto lock has to be triggered
      // if (this.partyService.reservationFormGroup.value.selectedTime && this.partyService.reservationType === ReservationType.Reservation &&
      //   !this.partyService.isEditData) {
      //   this.partyService.setLockTime(this.partyService.reservationFormGroup.value.selectedTime);
      // }
      this.makeReservationFormDirty();
    }
    if ((this.partyService.reservationType === ReservationType.Waitlist || this.partyService.reservationType === ReservationType.Reservation || this.partyService.reservationType === ReservationType.StandbyParties) &&
      this.partyService.reservationFormGroup.value.selectedArea !== area && this.partyService.reservationFormGroup.value.selectedSize && +this.partyService.reservationFormGroup.value.selectedSize > 0) {
      const partyDetails = this.getPartyDetailsForSeatingEstimate(area);
      // For standby request
      partyDetails.ReservedFor = this.partyService.isStandbyData(this.data) ? this.data.WishedTime : partyDetails.ReservedFor;
      this.partyService.getAutoWaitTime(this.partyService.reservationFormGroup.get('selectedSize').value, partyDetails);
    }
    this.partyService.reservationFormGroup.get('selectedArea').setValue(area);
    // Filter Method Call to set the slots in time tab if the AREA changes
    this.partyService.setSlots(this.partyService.slotsinTimeRange);
  }

  setSelectedSeatingType(type: SeatingTypesDTO) {
    const defaultSelectedType = this.partyService.reservationFormGroup.value.selectedType;
    if (type.Id !== defaultSelectedType.Id) {
      this.makeReservationFormDirty();
    }
    if ((this.partyService.reservationType === ReservationType.Waitlist || this.partyService.reservationType === ReservationType.Reservation || this.partyService.reservationType === ReservationType.StandbyParties) &&
      this.partyService.reservationFormGroup.value.selectedType !== type && this.partyService.reservationFormGroup.value.selectedSize && +this.partyService.reservationFormGroup.value.selectedSize > 0) {
      const area = this.partyService.reservationFormGroup.value.selectedArea;
      const partyDetails = this.getPartyDetailsForSeatingEstimate(area, type);
      partyDetails.ReservedFor = this.partyService.isStandbyData(this.data) ? this.data.WishedTime : partyDetails.ReservedFor;
      this.partyService.getAutoWaitTime(this.partyService.reservationFormGroup.get('selectedSize').value, partyDetails);
    }
    this.partyService.reservationFormGroup.get('selectedType').setValue(type);
  }

  getSpecialMealEndDate() {
    if (this.data && this.data.SpecialMealId) {
      const specialMeals = cloneDeep(this.cs.settings.value.SpecialMeals);
      const selectedSpecialMeals = specialMeals.filter((meal) => meal.Id == this.data.SpecialMealId);
      if (selectedSpecialMeals.length > 0 && selectedSpecialMeals[0].StartDate && selectedSpecialMeals[0].EndDate) {
        this.generalDateConfig[0].maxDate = new Date(selectedSpecialMeals[0].EndDate);
        this.generalDateConfig[0].minDate = new Date(selectedSpecialMeals[0].StartDate);
      }
    }
  }

  getPartyDetailsForSeatingEstimate(area?: SeatingAreaDTO, type?: SeatingTypesDTO) {
    let partyDetails: any = {};
    if (this.data) {
      partyDetails = cloneDeep(this.data);
      partyDetails.TableIds = [];
    }
    let selectedTimeSlot = this.partyService.reservationFormGroup.get('selectedTime').value;
    if (area) {
      if (typeof (area) == "number")
        partyDetails.SeatingAreaId = area;
      else
        partyDetails.SeatingAreaId = area.Id;
    }
    if (type) {
      partyDetails.SeatingTypeId = type.Id;
    }
    if (selectedTimeSlot && this.partyService.reservationType === ReservationType.Reservation) {
      partyDetails.ReservedFor = selectedTimeSlot.DateTime;
    }
    partyDetails.IsReservation = this.partyService.reservationType === ReservationType.Reservation;
    partyDetails.Size = this.components['_results']?.[0].form.value.size || 1 ;
    return partyDetails;
  }

  ngOnDestroy() {
    this.generalSizeConfig.forEach(config => {
      config.cellClick && config.cellClick.bind(null);
      config.returnFunction && config.returnFunction.bind(null);
    });
    this.generalAreaConfig.forEach(config => {
      config.cellClick && config.cellClick.bind(null);
    });
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    this.partyService.totalWaitimeMinutes = 0;
  }

  showSettingsChangedInfo() {
    const showAlert = true;
    const msg = this.ts.instant('settingsUpdated');;
    const title = this.ts.instant('alert');
    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: title, showAlert
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };
    if (!this.refreshDialogRef || (this.refreshDialogRef && !this.refreshDialogRef.componentInstance)) {
      this.refreshDialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        height: popupDialogDimension.actionDialogHeight,
        width: popupDialogDimension.actionDialogWidth,
        data: {
          title,
          update: 'ok',
          componentDetails,
          from: ComponentTypes.reservation,
          back: false,
          standalone: true,
          showAlert: true,
          showAction: true
        }
      });
    }
  }
}
