<div class="selectdate pr-2">
    <div class="selectdate__header">
        <label class=" page-title">{{'selectedDate' | translate}}</label>
    </div>
    <div class="selectdate__date-container page-title  mt-1">
        {{dateSelected}}
    </div>
    <div class="selectdate__date-content">
        <ng-scrollbar>
            <div class="mb-3" *ngIf="data?.overviewData?.length && hasProperty(data?.overviewData[0],'Note')">
                <mat-accordion>
                    <mat-expansion-panel class="panel-content" [expanded]="hasProperty(data?.overviewData[0],'Note')">
                        <mat-expansion-panel-header class="item-mat-expansion-panel-header">
                            <div class=""><label class="font-weight-bold">{{'hostNotes' | translate}}</label></div>
                        </mat-expansion-panel-header>
                        <div class="selectdate__notes font-italic">
                            <div class="mb-1">
                                <label> {{data?.overviewData[0]?.Note?.Text}}</label>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="mb-3" 
            *ngIf="data?.overviewData?.length && data?.overviewData[0]?.TableBlockingRules?.length > 0">
                <mat-accordion>
                    <mat-expansion-panel class="panel-content" [expanded]="!hasProperty(data?.overviewData[0],'Note')">
                        <mat-expansion-panel-header class="item-mat-expansion-panel-header">
                            <div class=""><label class="font-weight-bold">{{'tableBlockingRules' | translate}}</label>
                            </div>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <div *ngFor="let rules of data?.overviewData[0]?.TableBlockingRules;last as isLast"
                                class="selectdate__notes mb-2">
                                <div class="mb-1">
                                    <label><span class="font-weight-bold">{{'tablesMenuText' | translate}}</span> :
                                        {{rules?.TableIds |
                                        tableName: standAloneTables}}</label>
                                </div>
                                <div class="mb-1" *ngIf="rules.Shifts">
                                    <label><span class="font-weight-bold">{{'shifts' | translate}}</span> :
                                        {{rules?.Shifts | shiftsName:
                                        rules.Frequency}}</label>
                                </div>
                                <div *ngIf="(!rules.Shifts || !rules.Shifts.length) && rules.EffectiveRange.length>0">
                                    <label><span class="font-weight-bold">{{'timeRange' | translate}}</span> :
                                        {{rules?.EffectiveRange[0].Start |
                                        timeFormat }} - {{rules?.EffectiveRange[0].End | timeFormat }}</label>
                                </div>
                                <div class="mb-1">
                                    <label><span class="font-weight-bold">{{'frequency' | translate}}</span> :
                                        {{rules?.Frequency | frequencies: weekDays}}</label>
                                </div>
                                <div class="mb-1">
                                    <label><span class="font-weight-bold">{{'starts' | translate}}</span> :
                                        <span>{{rules?.Frequency?.Type == 1 ?
                                            "---" : rules?.StartDate | formatSettingsDate:
                                            cacheService.settings.value.General.DateFormat}}</span></label>
                                </div>
                                <div class="mb-1">
                                    <label><span class="font-weight-bold">{{'ends' | translate}}</span> :
                                        <span>{{rules?.Frequency?.Type == 1 ?
                                            "---" : rules?.EndDate | formatSettingsDate:
                                            cacheService.settings.value.General.DateFormat}}</span></label>
                                </div>
                                <div *ngIf="!isLast" class="my-1"><mat-divider></mat-divider></div>
                            </div>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </ng-scrollbar>
    </div>
    <div class="select-buttons">
        <activities-app-button [buttontype]="selectButton" (click)="selectCurrentDate(data)"></activities-app-button>
    </div>
</div>