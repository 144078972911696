<div class="h-100">
    <form [formGroup]="markdownForm" class="markdown-form pt-3 pb-3">
      <section class="h-100 pl-4 pr-4">
        <div class="entry-div">
          <label for="expiryDate" class=" d-block LW12 expiry-label">{{captions.expiryDate}}</label>
          <div class="date-section mt-1">
              <div class="mr-3">
                <mat-form-field class="d-inline-block datepicker-form-field date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
                  <mat-label>{{common.StartDate}}</mat-label>
                  <input matInput [matDatepicker]="picker" dateRestricter placeholder={{placeholderFormat}} formControlName="startDate" required
                    autocomplete="off"> 
                  <mat-datepicker #picker></mat-datepicker> 
                  <i  aria-hidden="true" matSuffix class="icon-Calender cursor fs-xlarge position-relative d-inline-block" (click)="picker.open()"></i>
                  <mat-error *ngIf="markdownForm.controls['startDate'].hasError('matDatepickerParse')">{{common.dateFormat}}</mat-error>   
                  <mat-error *ngIf="(markdownForm.controls['startDate'].hasError('required') || markdownForm.controls['startDate'].hasError('incorrect')) && !markdownForm.controls['startDate'].hasError('matDatepickerParse')">{{common.Missing}} {{common.StartDate}}</mat-error>
                  <mat-error *ngIf="markdownForm.controls['startDate'].hasError('matDatepickerMin')">{{common.minimum}} {{common.StartDate}}</mat-error>
                  <mat-error *ngIf="markdownForm.controls['startDate'].hasError('matDatepickerMax')">{{common.maximum}} {{common.StartDate}}</mat-error>
                </mat-form-field>

              </div>
              <div class="">
                <mat-form-field class="d-inline-block datepicker-form-field date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
                  <mat-label>{{common.EndDate}}</mat-label>
                  <input matInput [matDatepicker]="picker1" dateRestricter placeholder={{placeholderFormat}}  formControlName="endDate" required
                    autocomplete="off"> 
                  <mat-datepicker #picker1></mat-datepicker> 
                  <i  aria-hidden="true" matSuffix class="icon-Calender cursor fs-xlarge position-relative d-inline-block" (click)="picker1.open()"></i>
                  <mat-error *ngIf="markdownForm.controls['endDate'].hasError('matDatepickerParse')">{{common.dateFormat}}</mat-error>   
                  <mat-error *ngIf="(markdownForm.controls['endDate'].hasError('required') || markdownForm.controls['endDate'].hasError('incorrect')) && !markdownForm.controls['endDate'].hasError('matDatepickerParse')">{{common.Missing}} {{common.EndDate}}</mat-error>
                  <mat-error *ngIf="markdownForm.controls['endDate'].hasError('matDatepickerMin')">{{common.minimum}} {{common.EndDate}}</mat-error>
                  <mat-error *ngIf="markdownForm.controls['endDate'].hasError('matDatepickerMax')">{{common.maximum}} {{common.EndDate}}</mat-error>
                </mat-form-field>

              </div>
            </div>
            <div class="discount-section">
        <mat-form-field class="d-block price-discount mb-2" [floatLabel]="floatLabel">
          <input matInput inputtype="nonnegative" placeholder={{captions.priceDiscount}}
            formControlName="discount" autocomplete="off" required> 
        </mat-form-field>
        <mat-form-field class="d-block member-price-discount mb-2" [floatLabel]="floatLabel">
          <input matInput inputtype="nonnegative" placeholder={{captions.memberPriceDiscount}} formControlName="discountMember" required
            autocomplete="off"> 
        </mat-form-field>
        </div>
        <div class="btn-section mt-2">
        <button class="float-left  calculate  text-capital" mat-raised-button
          (click)="calculate()" [ngClass]="markdownForm.valid && markdownForm.dirty  ? 'body-bgcolor whitecolor ' : 'button--disabled'" >{{captions.calculate}}</button>
        </div>
      </div>
        <div class="table-div mt-5">
        <app-retail-table  [options]="tableoptionsPopup" (RowSelectEmitter)="selectedRowPopup($event)" ></app-retail-table>
      </div>
      </section>
    </form>
    <div mat-dialog-actions class="pl-4 pr-4 actions-div">
      <div class="pop-button">
        <button type="button" (click)="saveInfo()" [ngClass]="markdownForm.valid && markdownForm.dirty  ? 'button--primary-save' : 'button--disabled'" mat-button color="primary" [disabled]="!markdownForm.valid || !markdownForm.dirty">{{btnStatus}}</button>
        <button type="button" class="ml-2 p-0" mat-button (click)="close()">{{captions.CANCEL}}</button>
      </div>
    </div>
  </div>
