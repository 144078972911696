import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CacheService } from "@app/core/services/cache.service";
import { ComponentDetails } from "@app/popup-module/models/popup.interface";
import { ConfirmationPopupComponent } from "@app/shared/components/confirmation-popup/confirmation-popup.component";
import { ComponentTypes, OperationResultState, PartyState, PartyType } from "@app/shared/constants/commonenums";
import { PageMethod } from "@app/shared/models/InputContact";
import { PredefinedPartyMessageDTO, SettingsDTO } from "@app/shared/models/RestaurantDTO";
import { PartyService } from "@app/shared/services/party.service";
import { Utilities } from "@app/shared/utilities/utilities";
import { environment } from "@environments/environment";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import moment from 'moment';
import { BehaviorSubject, interval, Subscription } from "rxjs";

@Component({
    selector: 'chat-info-container',
    templateUrl: './chat-info-container.component.html',
    styleUrls: ['./chat-info-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatInfoContainerComponent extends Utilities implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input() party;

    pageBackgroundColor: string;
    subscriptions: Subscription = new Subscription();
    _settings: SettingsDTO = {} as SettingsDTO;
    pageTimer: Subscription;
    _restaurantTime: any;
    PartyType = PartyType;
    PartyState = PartyState;
    isMobileChat = false;
    inputString = '';
    predefinedMsgs$ = new BehaviorSubject<PredefinedPartyMessageDTO[]>(null);
    newMessageSubscription: Subscription;

    @ViewChild('messageChat') private messageChat: NgbPopover;
    @ViewChild('emailChat') private emailChat: NgbPopover;

    constructor(public partyService: PartyService, public cs: CacheService,
        public dialog: MatDialog, public translateService: TranslateService) {
        super(dialog);
        this.subscriptions.add(this.cs.settings.subscribe(sett => {
            this._settings = sett;
            this.predefinedMsgs$.next(sett.PredefinedPartyMessages);
        }));
        this.subscriptions.add(this.partyService.Parties$.subscribe(parties => {
            if (parties) {
                if (this.partyService.selectedParty$.value && this.party && this.partyService.selectedParty$.value?.Id == this.party?.Id) {
                    // if (parties.find(p => p.Id == this.partyService.selectedParty$.value.Id)) {
                        this.setPageBackgroudColor();
                    // }
                }
            }
        }));
    }

    ngOnInit() {

    }

    ngOnChanges() {
        const lastPagedAt = moment(this.party.LastPagedAt);
        const time = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
        if (this.party.LastPagedAt) {
            let differenceTime = (this._settings.General.MinIntervalBetweenPartyPagesInMinutes * 60) - (time.diff(lastPagedAt, 'seconds'));
            if (differenceTime > 0) {
                this.pageTimer = interval(1000 * differenceTime).subscribe(() => {
                    this.setPageBackgroudColor();
                });
            }
        }
    }

    ngAfterViewInit() {
    }


    setPageBackgroudColor() {
        this.pageBackgroundColor = '';
        if (this.party && this.party.PageMethod == PageMethod.Manual) {
            if (!this.party.LastPagedAt) {
                return;
            }
            const lastPagedAt = moment(this.party.LastPagedAt);
            const time = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
            let differenceTime = (this._settings.General.MinIntervalBetweenPartyPagesInMinutes * 60) - (time.diff(lastPagedAt, 'seconds'));
            if ((time.diff(lastPagedAt, 'minutes')) >= this._settings.General.MinIntervalBetweenPartyPagesInMinutes) {
                this.pageBackgroundColor = 'red';
                if (this.pageTimer) {
                    this.pageTimer.unsubscribe();
                    this.subscriptions.remove(this.pageTimer);
                }
            } else {
                this.pageBackgroundColor = 'green';
                if (differenceTime > 0) {
                    this.subscriptions.add(this.pageTimer);
                }
            }
        }
        if (this.party && (this.party.PageMethod == PageMethod.Sms || this.party.PageMethod == PageMethod.Sms2 || this.party.PageMethod == PageMethod.Email || this.party.PageMethod == PageMethod.Email2)) {
            if (this.party && this.party.Messages && this.party.Messages !== null && this.party.Messages.length > 0) {
                this.pageBackgroundColor = 'green';
            }
            if (this.party && this.party.Messages && this.party.Messages !== null &&this.party.Messages.length > 0 && this.party.Messages[this.party.Messages.length - 1].IsIncoming) {
                this.pageBackgroundColor = 'red';
            }
        }
    }

    getPastReservationStatus(party) {
        this._restaurantTime = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
        var dt = moment(this._restaurantTime._d.setHours(0, 0, 0, 0))
        var VisibilityLimit = dt.subtract({ hours: environment.MaxMinutesBackToEditOrSeatReservation });
        if (Utilities.parseDateString(party.ReservedFor) >= new Date(VisibilityLimit.valueOf())) {
            return false;
        } else {
            return true;
        }
    }

    manualpage() {
        if (this.party.State != PartyState.Pending || this.party.PageMethod != PageMethod.Manual) {
            return;
        }
        const time = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
        if (!this.party.LastPagedAt || (time.diff(moment(this.party.LastPagedAt), 'minutes')) >= this._settings.General.MinIntervalBetweenPartyPagesInMinutes) {
            this.subscriptions.add(this.partyService.postPartyMessage(this.party.Id, this.party.PageMethod, null,null).subscribe((data) => {
                if (data.State == OperationResultState.Success) {
                    //this.setPageBackgroudColor();
                    this.closeMobileChat();
                }
            }));
        } else {
            this.showPagingError();
        }
    }

    showPagingError() {
        const popUpMessage = [{
            confirmationMessage: `${this.translateService.instant('manualPagingError')}`,
            dialogTitle: this.translateService.instant('Paging'),
            showAlert: true
        }];
        const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', popUpMessage,
            popUpMessage[0].dialogTitle);
        this.openCustomPopup(componentDetails, ComponentTypes.reservation, '450px', 'auto', true, '', 'Ok',
            'Cancel', true);
    }


    mobileMessages() {
        if(this.partyService.isChatPopoverOpened) return;
        this.isMobileChat = true;
        this.partyService.isChatPopoverOpened = true;
        this.inputString = 'messages';        
        this.newMessageSubscription = this.partyService.messageSent$.subscribe(msg => {
            this.closeMobileChat();
        });
        this.messageChat.open();
    }


    closeMobileChat() {
        if (this.isMobileChat && this.messageChat) {
            this.isMobileChat = false;
            this.partyService.isChatPopoverOpened = false;
            this.newMessageSubscription.unsubscribe();
            this.messageChat.close();
        } else if (this.emailChat) {
            this.partyService.isChatPopoverOpened = false;
            this.emailChat.close();
        }
    }

    emailMessages() {
        if(this.partyService.isChatPopoverOpened) return;
        this.inputString = 'messages';
        this.partyService.isChatPopoverOpened = true;
        this.emailChat.open();
    }

    ngOnDestroy() {
        this.closeMobileChat();
        this.partyService.isExistingContactQS = false;
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }
}