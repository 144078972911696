import { Injectable, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from '@app/app.service';
import { PermissionType } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { OTAEmailTemplatesDTO } from '@models/EmailTemplateDTO';
import { Host } from '@models/HostDTO';
import { DateShiftDTO } from '@models/InputReservation';
import { RoleType } from '@models/LoginResultDTO';
import { AppSettingsDTO, PendingChargePartyDTO, PermissionTypeHelper, ScheduleDTO } from '@models/RestaurantDTO';
import { AuditLogOperationOptions } from '@models/SimpleAuditLogItemDTO';
import { ActivityBlockingRuleLookUp, TableBlockingRuleDTO } from '@models/TableBlockingDTO';
import { TranslateService } from '@ngx-translate/core';
import { TabsModel } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { HttpService } from '@services/http.service';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class SettingsService implements OnDestroy {

  selectedShiftIds: Subject<any> = new Subject<any>();
  LoadedShift: DateShiftDTO[];
  SettingsTabDetails: TabsModel;
  blockingdataChange$ = new BehaviorSubject<TableBlockingRuleDTO[]>(null);
  partyGuestCharges = new BehaviorSubject<PendingChargePartyDTO[]>(null);
  partyGuestChargestableDataSource: any;
  permissionTypeHelper: PermissionTypeHelper[] = [];
  host: Host;
  isHostUpdated: any;
  guestChargeCount: number;
  subscriptions: Subscription = new Subscription();
  reload: boolean = false;
  roles: any = []
  rolesWithProperties: any = [];
  restaurants: any = [];
  PackageActivities: any = [];
  packagesActivityTempId = 1;
  packagesConfigurationForm: any;
  selectedPackage: any
  selectedMembership: any[] = []
  ImageData: string;
  ImageChanged: boolean
  customGuestFielValueChanges: Subject<any> = new Subject<any>();
  packageActivtyLimitValueChanges: Subject<any> = new Subject<any>();
  activitySessionList: any[] = [];
  addOnsMappingList: any[] = [];
  BannerImageData: string;
  BannerImageChanged: boolean;

  constructor(public httpService: HttpService, public appService: AppService, public popupService: PopupService, public ts: TranslateService,) { }


  getMilliSeconds(time){
    if(time)
    return Number(time?.split(':')[0]) * 60 * 60 * 1000 + Number(time?.split(':')[1]) * 60 * 1000;
  }
  scrollTop(id){
    let top = document.getElementById(id);
    if (top !== null) {
       top.scrollIntoView({ behavior: 'smooth' });
       top = null;
    }
  }

  GetShiftIds(localdate: Date, onlyWeeklyShifts: boolean, selectedShiftId?: string) {
    this.subscriptions.add(this.getShiftForDay(localdate, onlyWeeklyShifts, null).subscribe((response) => {
      let responsePayload = response.Payload;
      this.LoadedShift = [];
      responsePayload.forEach(element => {
        this.LoadedShift.push(element)
      });
      let shiftsids = responsePayload.map(function (item) { return item.Id; });
      this.selectedShiftIds.next(shiftsids);
    }));
  }

  getShiftForDay(localdate: Date, onlyWeeklyShifts: boolean, selectedShiftId?: string) {
    let dates = moment(localdate).format("YYYY-MM-DD");
    let getURL;
    if (!selectedShiftId) {
      getURL = `${urlConfig.getForDate}?restaurantId=${Utilities.RestaurantId()}&localDate=${dates}&onlyWeeklyShifts=${onlyWeeklyShifts}`;
    } else {
      getURL = `${urlConfig.getForDate}?restaurantId=${Utilities.RestaurantId()}&localDate=${dates}&onlyWeeklyShifts=${onlyWeeklyShifts}&selectedShiftId=${selectedShiftId}`;
    }
    return this.httpService.get(getURL);
  }

  SetQuickBlock(requestedPayload) {
    const getURL = `${urlConfig.createBlockingRule}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(getURL, requestedPayload);
  }
  SetUnblock(requestedPayload) {
    const getURL = `${urlConfig.unblockBlockingRule}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(getURL, requestedPayload);
  }
  GetRoles() {
    const getURL = `${urlConfig.getRolesURL}?restaurantId=${Utilities.RestaurantId()}&languageId=` + sessionStorage.getItem('languageId');
    return this.httpService.get(getURL)
    .pipe(
      map(response => {
        response.Payload.forEach(role => {
          if(Object.keys(RoleType).includes(role.Text)){
            role.DefaultRole = 0;
          }else{
            role.DefaultRole = 1;
          }
        });

        response.Payload = _.orderBy(response.Payload, ["DefaultRole", "Text"]);
        return response;      
    }));
  }
  
  getBlockedActivities() {
    const getURL = `${urlConfig.getActivitiesBlockData}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.get(getURL);
  }

  deleteBlockedActivities(requestedPayload) {
    const getURL = `${urlConfig.DeleteActivityBlock}?restaurantId=${Utilities.RestaurantId()}&blockId=`+requestedPayload.blockId;
    return this.httpService.post(getURL);
  }

  GetOutlets() {
    const getURL = `${urlConfig.getretailOutlets}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.get(getURL);
  }
  AddPermissions(roleType: RoleType, permissions: PermissionType[]) {
    const postURL = `${urlConfig.addPermissionURL}?restaurantId=${Utilities.RestaurantId()}&roleType=${roleType}`;
    return this.httpService.post(postURL, permissions);
  }
  RemovePermissions(roleType: RoleType, permissions: PermissionType[]) {
    const postURL = `${urlConfig.removePermissionURL}?restaurantId=${Utilities.RestaurantId()}&roleType=${roleType}`;
    return this.httpService.post(postURL, permissions);
  }
  GetBlocks() {
    const getURL = `${urlConfig.getBlockingRule}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.get(getURL);
  }
  GetTBRAuditLog(ruleId: number) {
    const getURL = `${urlConfig.getTBRAuditLogUrl}?restaurantId=${Utilities.RestaurantId()}&ruleId=${ruleId}&options=${AuditLogOperationOptions.Simplified}`;
    return this.httpService.get(getURL);
  }
  UpdateBlocks(requestedPayload) {
    const getURL = `${urlConfig.updateBlockingRule}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(getURL, requestedPayload);
  }
  DeleteBlocks(ruleId: number) {
    const getURL = `${urlConfig.deleteBlockingRule}?restaurantId=${Utilities.RestaurantId()}&ruleId=${ruleId}`;
    return this.httpService.post(getURL);
  }
  GetShiftStats() {
    const getURL = `${urlConfig.getShiftStats}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.get(getURL);
  }
  setGuestCharges() {
    this.partyGuestChargestableDataSource = new MatTableDataSource(this.partyGuestCharges.value.sort((a, b) => Utilities.parseDateString(a.PartyDateTime).getTime() - Utilities.parseDateString(b.PartyDateTime).getTime()));
    this.SettingsTabDetails.tabs[0].tabCounter = this.guestChargeCount = this.partyGuestCharges.value && this.partyGuestCharges.value.length > 0 ? this.partyGuestCharges.value.length : 0;
  }
  ResetAllTables() {
    let partyIds: string = '';
    const getURL = `${urlConfig.resetAllTables}?restaurantId=${Utilities.RestaurantId()}&onlyPartyIds=${partyIds}`;
    return this.httpService.post(getURL);
  }
  GetCoverReport(selectedDate: Date, ShiftId?: number) {
    let getURL = '';
    if (ShiftId)
      getURL = `${urlConfig.getCoverReport}?restaurantId=${this.appService.restaurantId}&localDate=${selectedDate}&shiftId=${ShiftId}`;
    else
      getURL = `${urlConfig.getCoverReport}?restaurantId=${this.appService.restaurantId}&localDate=${selectedDate}`;
    return this.httpService.get(getURL);
  }

  GetAllHosts() {
    const getURL = `${urlConfig.getHostsURL}?restaurantId=${this.appService.restaurantId}&languageId=` +  sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`);
    return this.httpService.get(getURL);
  }

  GetHostUser(hostid: number) {
    const getURL = `${urlConfig.getOneHostsURL}?restaurantId=${this.appService.restaurantId}&hostId=${hostid}`;
    return this.httpService.get(getURL);
  }

  GetAllOffers(restaurantId, merchantId, filterData){
    
    filterData.StartDate = filterData.StartDate ? moment(filterData.StartDate).format('YYYY-MM-DD') + "T00:00:00.000" : null;
    filterData.EndDate = filterData.EndDate ? moment(filterData.EndDate).format('YYYY-MM-DD') + "T23:59:59.999" : null;
    const getURL = `${urlConfig.getAllOffers}?propertyId=${restaurantId}&merchantId=${merchantId}`;
    return this.httpService.post(getURL, filterData);
  }
  GetEligibleOffer(merchantId, payload){
    const getURL = `${urlConfig.GetEligibleOffers}?propertyId=${Utilities.RestaurantId()}&merchantId=${merchantId}`;
    return this.httpService.post(getURL , payload);
  }

  GetOfferById(restaurantId, offerId){
    const getURL = `${urlConfig.getOfferByOfferId}?propertyId=${restaurantId}&offerId=${offerId}`;
    return this.httpService.get(getURL);
  }

  SaveOffer(restaurantId,offerDetails){
    const postURL = `${urlConfig.saveOffer}?propertyId=${restaurantId}`;
    return this.httpService.post(postURL, offerDetails);
  }

  
  EnableOffer(restaurantId, offerId, isActive,isDeleted:boolean=false){
    const postURL = `${urlConfig.enableOffer}?propertyId=${restaurantId}&offerId=${offerId}&isActive=${isActive}&isDeleted=${isDeleted}`;
    return this.httpService.post(postURL);
  }
  AddHost() {
    const postURL = `${urlConfig.createHostURL}?restaurantId=${this.appService.restaurantId}`;
    return this.httpService.post(postURL, this.host);
  }

  UpdateHost() {
    const postURL = `${urlConfig.updateHostURL}?restaurantId=${this.appService.restaurantId}`;
    return this.httpService.post(postURL, this.host);
  }

  RemoveHost(hostid: any) {
    const postURL = `${urlConfig.removeHostURL}?restaurantId=${this.appService.restaurantId}&hostId=${hostid}`;
    return this.httpService.post(postURL);
  }
  setPermissionTypeHelper() {
    if (this.appService.PropertyID == 3) {
      this.permissionTypeHelper.push({ Id: PermissionType.AddSlots, Name: "Add Slots" },
        { Id: PermissionType.RemoveSlots, Name: "Remove Slots" },
        { Id: PermissionType.EditSlots, Name: "Edit Slots" },
        { Id: PermissionType.AddReservations, Name: "Add Reservations" },
        { Id: PermissionType.EditReservations, Name: "Edit Reservations" },
        { Id: PermissionType.NoShowReservations, Name: "No-Show reservations" },
        { Id: PermissionType.CancelReservations, Name: "Cancel Reservations" },
        { Id: PermissionType.Overbook, Name: "Overbook" },
        { Id: PermissionType.AddToStandByList, Name: "Add to Standby List" },
        { Id: PermissionType.EditStandByParties, Name: "Edit Standby Parties" },
        { Id: PermissionType.RemoveStandByParties, Name: "Remove Standby Parties" },
        { Id: PermissionType.BookInNonWebReservableSlots, Name: "Book in Non Web Reservable or Disabled Slots" },
        { Id: PermissionType.BookInSlotsWithInappropriateSize, Name: "Book in Slots with Inappropriate Size" },
        { Id: PermissionType.PageParties, Name: "Page Parties" },
        { Id: PermissionType.SendFreeformPageMessages, Name: "Send Freeform Page Replies" },
        { Id: PermissionType.ManageTableBlockingRules, Name: "Manage Cabana/Pool Chair Blocking Rules" },
        { Id: PermissionType.BookOrSeatAtBlockedTables, Name: "Book/Seat at Cabana/Pool Chair(s)" },
        { Id: PermissionType.MoveSeatedParties, Name: "Move Seated Parties" },
        { Id: PermissionType.AddReservationwithNeverAssign, Name: "Manage Reservation with NeverAssign" })
    } else {
      this.permissionTypeHelper.push({ Id: PermissionType.AddSlots, Name: this.ts.instant("addSlots") },
        { Id: PermissionType.RemoveSlots, Name: this.ts.instant("removeSlots") },
        { Id: PermissionType.EditSlots, Name: this.ts.instant("editSlots") },
        { Id: PermissionType.AddReservations, Name: this.ts.instant("addReservations") },
        { Id: PermissionType.EditReservations, Name: this.ts.instant("editReservations") },
        { Id: PermissionType.NoShowReservations, Name: this.ts.instant("noShowReservations") },
        { Id: PermissionType.CancelReservations, Name: this.ts.instant("cancelReservationsRole") },
        { Id: PermissionType.Overbook, Name: this.ts.instant("overbook") },
        { Id: PermissionType.AddToStandByList, Name: this.ts.instant("addToStandByList") },
        { Id: PermissionType.EditStandByParties, Name: this.ts.instant("editStandByParties") },
        { Id: PermissionType.RemoveStandByParties, Name: this.ts.instant("removeStandByParties") },
        { Id: PermissionType.BookInNonWebReservableSlots, Name: this.ts.instant("Book in Non Web Reservable or Disabled Slots") },
        { Id: PermissionType.BookInSlotsWithInappropriateSize, Name: this.ts.instant("bookInSlotsWithInappropriateSize") },
        { Id: PermissionType.PageParties, Name: this.ts.instant("pageParties") },
        { Id: PermissionType.SendFreeformPageMessages, Name: this.ts.instant("sendFreeformPageMessages") },
        { Id: PermissionType.ManageTableBlockingRules, Name: this.ts.instant("manageTableBlockingRules") },
        { Id: PermissionType.BookOrSeatAtBlockedTables, Name: this.ts.instant("bookOrSeatAtBlockedTables") },
        { Id: PermissionType.MoveSeatedParties, Name: this.ts.instant("moveSeatedParties") },
        { Id: PermissionType.AddReservationwithNeverAssign, Name: this.ts.instant("AddReservationwithNeverAssign") })


    }

  }

  saveEmailTemplate(emailTemplate: OTAEmailTemplatesDTO) {
    console.log(emailTemplate);
    const postURL = `${urlConfig.saveEmailTemplateURL}?restaurantId=${this.appService.restaurantId}`;
    return this.httpService.post(postURL, emailTemplate);
  }

  getEmailTemplate() {
    const getURL = `${urlConfig.getEmailTemplateURL}?restaurantId=${this.appService.restaurantId}`;
    return this.httpService.get(getURL);
  }

  getRestaurantAuditLog(start, end) {
    return this.httpService.post(`${urlConfig.getRestaurantAuditLogURL}?restaurantId=${Utilities.RestaurantId()}`, { Start: start, End: end });
  }
  getUserRoles() {
    const getURL = `${urlConfig.getUserRoleURL}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.get(getURL);
  }

  addUserRole(data) {
    const postURL = `${urlConfig.addUserRoleURL}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, data);
  }

  updateUserRole(data) {
    const postURL = `${urlConfig.updateUserRoleURL}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, data);
  }

  deleteUserRole(roleId) {
    const postURL = `${urlConfig.deleteUserRoleURL}?restaurantId=${Utilities.RestaurantId()}&roleId=${roleId}`;
    return this.httpService.post(postURL);
  }

  clonePermissions(data) {
    const postURL = `${urlConfig.clonePermissinosURL}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, data);
  }
  saveCustomFieldsMapping(data) {
    const postURL = `${urlConfig.customGuestFieldMappingsURL}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, data);
  }

  getFinancialEffectForCancelledActivity(partyId, sessionCancel, cancelByStatus) {
    let data = {
      bookingsessionids: null
    }
    const cancelledURL = `${urlConfig.financialEffectForCancelledActivity}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&allsessionneedtocancel=${sessionCancel}&findCancelbyStatus=${cancelByStatus}`;
    return this.httpService.post(cancelledURL, data)
  }

  CreatePackage(restaurantId, data) {
    const postURL = `${urlConfig.savePackage}${restaurantId}`;
    return this.httpService.post(postURL, data);
  }
  SetPackageStatus(restaurantId, data) {
    const postURL = `${urlConfig.SetPackageStatus}${restaurantId}`;
    return this.httpService.post(postURL, data);
  }
  GetPackages(restaurantId, filterData) {
    filterData.TimeRange.Start = moment(filterData.TimeRange.Start).format('YYYY-MM-DD') + "T00:00:00.000";
    filterData.TimeRange.End = moment(filterData.TimeRange.End).format('YYYY-MM-DD') + "T23:59:59.999";
    const getURL = `${urlConfig.getPackageList}${restaurantId}`;
    return this.httpService.post(getURL, filterData);
  }
  GetPackageById(restaurantId, packageId) {
    const getURL = `${urlConfig.getPackageById}${restaurantId}&packageId=${packageId}`;
    return this.httpService.get(getURL);
  }
  UpdatePackage(restaurantId, packageId, data) {
    const postURL = `${urlConfig.savePackage}${restaurantId}&packageId=${packageId}`;
    return this.httpService.post(postURL, data);
  }
  RemovePackage(restaurantId, packageIds) {
    const postURL = `${urlConfig.removePackage}${restaurantId}`;
    return this.httpService.post(postURL, packageIds);
  }
  GetLessonByIdAndRestaurant(id) {
    const getUrl = `${urlConfig.GetLessonByIdAndRestaurant}?id=${id}&restaurantId=${Utilities.RestaurantId()}`
    return this.httpService.get(getUrl);
  }
  GetAvailableInstructorsByRestaurant(data) {
    const postURL = `${urlConfig.GetAvailableServersByRestaurant}?restaurantid=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, data);
  }
  SaveLesson(data) {
    const postURL = `${urlConfig.SaveLesson}?restaurantid=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, data);
  }
  SaveSessionGroups(data) {
    const postURL = `${urlConfig.SaveSessionGroups}?restaurantid=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, data);
  }
  GetLessonDetailsByRestaurant() {
    const getUrl = `${urlConfig.GetLessonDetailsByRestaurant}?restaurantId=${Utilities.RestaurantId()}`
    return this.httpService.get(getUrl);
  }
  RemoveLesson(id) {
    const postURL = `${urlConfig.RemoveLesson}?restaurantid=${Utilities.RestaurantId()}&lessonId=${id}`;
    return this.httpService.post(postURL);
  }
  CheckInstructorAvailability(payload) {
    const postURL = `${urlConfig.CheckInstructorAvailability}?restaurantid=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, payload);
  }
  GetActivityTemplates() {
    const getUrl = `${urlConfig.GetActivityTemplates}?restaurantId=${Utilities.RestaurantId()}`
    return this.httpService.get(getUrl);
  }
  getSpecialMealByIdAndRestaurant(id){
    const getUrl = `${urlConfig.GetSpecialMealByIdAndRestaurant}?id=${id}&restaurantId=${Utilities.RestaurantId()}`
    return this.httpService.get(getUrl);
  }
  getSpecialMealsByRestaurant(){
    const getUrl = `${urlConfig.GetSpecialMealsByRestaurant}?restaurantId=${Utilities.RestaurantId()}`
    return this.httpService.get(getUrl);
  }
  deleteSpecialMeal(id){
    const postURL = `${urlConfig.DeleteSpecialMeal}?restaurantid=${Utilities.RestaurantId()}&id=${id}`;
    return this.httpService.post(postURL);
  }
  SaveSpecialMeal(data) {
    const postURL = `${urlConfig.SaveSpecialMeal}?restaurantid=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, data);
  }
  getShiftAuditLog(date: Date) {
    const getUrl = `${urlConfig.GetShiftsForDateAuditLog}?restaurantId=${Utilities.RestaurantId()}&localDate=${Utilities.Date(date).format('YYYY-MM-DDTHH:mm:ss')}&options=0`;
    return this.httpService.get(getUrl);
  }
  getCurrentShiftAuditLog(date: Date, shiftId: number) {
    const getUrl = `${urlConfig.GetShiftAuditLog}?restaurantId=${Utilities.RestaurantId()}&shiftId=${shiftId}&localDate=${Utilities.Date(date).format('YYYY-MM-DDTHH:mm:ss')}&options=0`;
    return this.httpService.get(getUrl);
  }

  getPromoCodeAuditLog(payload){
    const postURL = `${urlConfig.getAuditLog}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, payload);
  }

  saveAppSettings(payload: AppSettingsDTO[],merchantId : number) {
    const url = `${urlConfig.AddOrModifyAppsettings}?propertyId=${Utilities.RestaurantId()}&merchantId=${merchantId}&environment=null`;
    return this.httpService.post(url, payload);
  }
  removeAppSettings(payload,merchantId) {
    const url = `${urlConfig.DeleteAppsettings}?restaurantId=${Utilities.RestaurantId()}&merchantId=${merchantId}&environment=null`;
    return this.httpService.post(url, payload);
  }

  getAppSettings(merchantId){
    return this.httpService.get(`${urlConfig.GetAppSettings}?propertyId=${Utilities.RestaurantId()}&merchantId=${merchantId}`);
  }
  getMerchantSettings(categoryName: string,merchantId: number) {
    return this.httpService.get(`${urlConfig.GetMerchantSettings}?propertyId=${Utilities.RestaurantId()}&categoryname=${categoryName}&merchantId=${merchantId}`);
  }
  setMerchantSettings(reqPayload) {
    return this.httpService.post(`${urlConfig.SetMerchantSettings}?propertyId=${Utilities.RestaurantId()}`,reqPayload);
  }
  getAdvancedSettingsAuditLog(reqPayload) {
    const postURL = `${urlConfig.getAuditLog}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, reqPayload);
  }
  saveSeatingAreas(payload){
    const url = `${urlConfig.SetSeatingAreas}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(url, payload);
  }
  getSeatingAreas(){
    return this.httpService.get(`${urlConfig.GetSeatingAreas}?restaurantId=${Utilities.RestaurantId()}`);
  }
  removeSeatingArea(seatingAreaId){
    const url = `${urlConfig.RemoveSeatingAreas}?restaurantId=${Utilities.RestaurantId()}&seatingAreaId=${seatingAreaId}`;
    return this.httpService.post(url);
  }
  saveLocationGroup(payload) {
    const url = `${urlConfig.saveLocationGroups}?propertyId=${Utilities.RestaurantId()}`;
    return this.httpService.post(url, payload);
  }
  getLocationGroups() {
    return this.httpService.get(`${urlConfig.getLocationGroups}?propertyId=${Utilities.RestaurantId()}`);
  }

  getAllBreakHourforStaffs() {
    return this.httpService.get(`${urlConfig.GetAllBreakorBlockHours}?propertyId=${Utilities.RestaurantId()}`);
  }
  
  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
