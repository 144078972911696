import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { CheckUserPermissionsComponent } from '@components/check-user-permissions/check-user-permissions.component';
import { ExplainUserPermissionsComponent } from '@components/explain-user-permissions/explain-user-permissions.component';
import { ComponentTypes } from '@constants/commonenums';
import { popupDialogDimension } from '@constants/globalConstants';
import { LoaderService } from '@core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { Subscription } from 'rxjs';

@Injectable()
export class UserPermissionFunctions extends Utilities implements OnDestroy {
  confSubscription: Subscription;

  constructor(dialog: MatDialog, public appService: AppService, translateService: TranslateService,
    public popupService: PopupService, public partyService: PartyService, public ls: LoaderService) {
    super(dialog, translateService);
  }
  ComponentType: ComponentTypes;
  pinSubscription: Subscription;
  cancelSubscription: Subscription;
  dialogRef: MatDialogRef<CustomPopupComponent>;
  dialogExplain: MatDialogRef<CustomPopupComponent>;
  failedAttempts: number = 0;
  subscriptions: Subscription = new Subscription();


  ShowUserPermissionpopup(result: any) {

    if (this.pinSubscription) {
      this.pinSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }

    const managerPinTitle: string = this.translateService.instant('managerPinTitle');
    const incorrectPinTitle: string = this.translateService.instant('incorrectPinTitle');
    const explainBtn: string = this.translateService.instant('explain');
    const cancelBtn: string = this.translateService.instant('cancel');

    const componentDetails = {
      componentName: CheckUserPermissionsComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    this.dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '350px',
      minHeight: '500px',
      height: '30%',
      data: {
        title: this.failedAttempts > 0 ? incorrectPinTitle : managerPinTitle,
        update: explainBtn,
        cancel: cancelBtn,
        componentDetails,
        from: ComponentTypes.ManagerPin,
        back: false,
        standalone: true,
        //showFooter: false
      }
    });
    this.popupService.restrictCloseDialog = true;

    this.confSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      if (val == ComponentTypes.ManagerPin) {
        this.popupService.restrictCloseDialog = false;

        const explainTitle: string = this.translateService.instant('managerPINExplainTitle');
        const componentInfo = Utilities.setComponentDetails(ExplainUserPermissionsComponent, 'small', 'action', result,
          explainTitle);
        this.dialogExplain = this.openCustomPopup(componentInfo, ComponentTypes.ExplainPermissions,
          popupDialogDimension.actionDialogWidth, '500px', true, '', 'Close', '', true);
        this.subscriptions.add(this.dialogExplain.afterClosed().subscribe(() => {
          this.popupService.restrictCloseDialog = true;
        }));
      }
    });

    this.subscriptions.add(this.dialogRef.afterClosed().subscribe(() => {
      if (this.confSubscription) {
        this.confSubscription.unsubscribe();
      }
      if (this.pinSubscription) {
        this.pinSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
    }));

    this.cancelSubscription = this.popupService.cancelledAction$.subscribe((val) => {
      if (val.from == ComponentTypes.ManagerPin) {
        this.failedAttempts = 0;
        this.dialogRef.close();
      }
    });

    this.pinSubscription = this.ls.managerPin$.subscribe(() => {
      this.failedAttempts++;
      this.popupService.restrictCloseDialog = false;
      this.dialogRef.close();
    });

  }

  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
