<section [formGroup]="form" class="email-receipt">
    <h1 class='ag_p--4 ag_m--0 ag_display--flex border-bottom' mat-dialog-title>{{captions.emailReceipt}}
        <i [attr.automationId]="'Icn_shopEmailReceipt_icancel'" class='icon-close ag_ml--auto ag_cursor--pointer' aria-hidden="true"
            (click)="onCancel()">&nbsp;</i>
    </h1>
    <div class="p-4 overflow-auto email-receipt-wrapper">
        <label class="ag_form-control">{{captions.guestEmail_address}}</label>
        <div formArrayName="mail"
        *ngFor="let email of form.get('mail')['controls']; let i= index; let first = first; let last=last">
        <div [formGroupName]="i" class="ag_display--flex align-items-baseline">
            <div class="ag_display--flex align-items-center">
                <mat-form-field [floatLabel]="floatLabel" class="w-400px pr-4 mb-1 mt-2">
                    <input matInput attr.automationId='Txt_shopEmailReceipt_emailValue{{i}}' formControlName="emailValue" >
                    <mat-error *ngIf="email.get('emailValue').hasError('required')">
                        {{captions.missingMail}}
                    </mat-error>
                    <mat-error *ngIf="email.get('emailValue').hasError('pattern')"   [attr.LiteralID]="errorMessageId">
                      {{captions.invalidMail}}
                    </mat-error>
                </mat-form-field>
                <span attr.automationId='Lbl_shopEmailReceipt_addEmail{{i}}' class="icon-Plus ag_cursor--pointer mb-3" [ngClass]="{'ag_section--disable':form.get('mail')['controls'].length==3}" (click)="addEmailItem()" *ngIf="first"></span>
                <span attr.automationId='Lbl_shopEmailReceipt_removeEmail{{i}}' class="icon-Minus mb-3" (click)="removeEmailItem(i)" *ngIf="!first"></span>
            </div>
        </div>
        </div>
    </div>
    <div mat-dialog-actions class='ag_footer--actions ag_display--flex pr-0 pl-0 pb-0 pt-1'>
        <app-button [attr.automationId]="'Btn_shopEmailReceipt_proceed'" class="pl-3" [buttontype]='proceedButton' (valueChange)='onAction()'></app-button>
        <app-button [attr.automationId]="'Btn_shopEmailReceipt_cancel'" class="" [buttontype]='cancelButton' (valueChange)='onCancel()'></app-button>
</div>
</section>
