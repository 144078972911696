import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AsideComponent } from './aside/aside.component';
import { TranslateModule } from "@ngx-translate/core";
import { NgScrollbarModule } from "ngx-scrollbar";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from '../material-module';
import { PopupModule } from '@app/popup-module/popup.module';
import { SharedModule } from '@app/shared/shared.module';
import { DayNotesComponent } from './header/day-notes/day-notes.component';
import { HelpComponent } from './help/help.component';
import { AboutComponent } from './help/about/about.component';
import { TrainingComponent } from './help/training/training.component';
import { WhatsNewComponent } from './help/whats-new/whats-new.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { POSLayoutComponent } from '@app/pos/layout/layout.component';
import { LayoutHeaderComponent } from '@app/pos/layout/layout-header/layout-header.component';
import { MatBadgeModule } from '@angular/material/badge';


@NgModule({
    declarations: [LayoutComponent, HeaderComponent, FooterComponent, AsideComponent, DayNotesComponent, HelpComponent, AboutComponent, TrainingComponent, WhatsNewComponent, LayoutHeaderComponent],
    imports: [
        CommonModule,
        RouterModule,
        NgScrollbarModule,
        TranslateModule,
        FormsModule,
        MaterialModule,
        PopupModule,
        SharedModule,
        MatBadgeModule
    ],
    exports: [LayoutComponent,HeaderComponent, AboutComponent, TrainingComponent, WhatsNewComponent]
})
export class LayoutModule { }
