import { Component, OnInit, Input } from "@angular/core";


@Component({
    selector: 'app-calendar-view',
    templateUrl: './calendar-view.component.html',
    styleUrls: ['./calendar-view.component.scss']
  })

export class CalendarViewComponent implements OnInit {
  @Input() data;
  dateObj;

  constructor() { }
  
  ngOnInit(): void {
  }

  dateSelected(event) {
    this.dateObj = event;
  }
    
}