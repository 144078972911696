import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
@Pipe({
  name: 'wildSearch'
})
export class WildSearchPipe implements PipeTransform {
  transform(array: any, searchText, fnc?): any {
      if(array && _.isArray(array) && searchText){
        return array.filter(obj => {
            let values = fnc? fnc(obj) : Object.values(obj);
            return values.find(val => val.includes(searchText.toLowerCase()));
        })
      }
      return array || [];
  }
}