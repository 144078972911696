import { Pipe, PipeTransform } from '@angular/core';
import { SettingsDTO } from '../models/RestaurantDTO';

@Pipe({
  name: 'coverTypeName'
})
export class CoverTypeNamePipe implements PipeTransform {

  transform(coverId: number, settings: SettingsDTO): string {
    let coverTypeName = '';
    if(coverId && settings) {
      let selectedCoverType = settings.CoverTypes.find(cover => cover.Id == coverId);
      if(selectedCoverType) {
        coverTypeName = selectedCoverType.Name;
      }
    }
    return coverTypeName;
  }

}
