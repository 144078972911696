import { Pipe, PipeTransform } from '@angular/core';
import { CacheService } from '@core/services/cache.service';

@Pipe({
  name: 'formattedTableName'
})
export class TablenameFormatPipe implements PipeTransform {
  constructor(private cs: CacheService) {
  }

  transform(tableIds) {
    let formattedTableName = "";
    if (tableIds && tableIds.length > 0) {
      tableIds.forEach(id => {
        if (this.getTableName(id)) {
          formattedTableName = formattedTableName + this.getTableName(id) + ',';
        }
      });
      return formattedTableName.slice(0, -1);
    }
    else {
      return "NA"
    }

  }

  getTableName(partyTableId: number) {
    let tableName = "";
    this.cs.layout.value.FloorPlans.forEach(plan => {
      if (plan.StandaloneTables.filter(table => table.Id == partyTableId)[0]) {
        tableName = plan.StandaloneTables.filter(table => table.Id == partyTableId)[0].Name;
      }
    });
    return tableName;
  }

}
