export const environment = {
  production: true,
  BaseUrl: "https://reserve.onagilysys.eu/coreapi/api",
  authority: "http://10.17.66.11:5100",
  redirect_uri: "https://reserve.onagilysys.eu/auth-callback",
  post_logout_redirect_uri: "https://reserve.onagilysys.eu",
  silent_redirect_uri: "https://reserve.onagilysys.eu/silent-refresh.html",
  ReportsApiUrl: 'https://reserve-reportsapi.onagilysys.eu/api/reports',
  signalR_AzureFunction_negotiate_url: 'https://reservesignalrhandler.onagilysys.eu/api/SignalRConnection',
  signalR_AzureFunction_group_url: 'https://reservesignalrhandler.onagilysys.eu/api',
  app_base_href:"",
  appInsights: {
    instrumentationKey: '316e7529-5a6c-455b-8dd2-76e832237e52'
  },
  DelayIntervalServerStatsRefresh : 30,
  UseStandaloneSignalrService: false,
  StandaloneSignalRUrl: "http://localhost:5001/notificationhub",
  AllowEditForPastReservationsInDays: 3,
  MaxMinutesBackToEditOrSeatReservation: 3,
  MaxTimeBackToNoShowOrCancelReservation: 43,
  MaxRestaurantsForDashboardData: 15,
  MaxPartySizeForManualWaitTimeOverrides: 20,
  IsProduction: true,
  SEAProduction: false,
  MerchantId: 3,
  fullStory: 'SRKVD',
  fullStoryLocation: 'fullStory-prodEMEA.json',
  StandaloneSignalrUserName: "StandaloneSingnalRUser",
  StandaloneSignalrPassword: "",    
  authorizeIframeResponseOrigin :'https://authorize.onagilysys.eu',
  rGuestPayIframeResponsOrigin:'https://pay.rguest.eu',
  FullStoryEnable : true,
  CommonGateway:"https://v1-prod.onagilysys.eu",
  TenantManagement:"https://v1-prod.onagilysys.eu/commongateway",
  RetailGateway:"https://v1-prod.onagilysys.eu/VisualOne/retailgateway",
  retailPOS:"https://v1-prod.onagilysys.eu/VisualOne/retailgateway",
  authentication:"https://v1-prod.onagilysys.eu/commongateway",
  retailManagement:"https://v1-prod.onagilysys.eu/VisualOne/retailgateway",
  payment:"https://v1-prod.onagilysys.eu/VisualOne/retailgateway",
  report:"https://v1-prod.onagilysys.eu/VisualOne/retailgateway",
  image:"https://v1-prod.onagilysys.eu/VisualOne/retailgateway",
  Common:"https://v1-prod.onagilysys.eu/VisualOne/retailgateway/Retail/Services/Notification",
  commission:"https://v1-prod.onagilysys.eu/VisualOne/retailgateway",

  trainingVideoLinks: {
    createReservation: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/Seat_How%20to%20make%20a%20reservation_0.1.mp4',
    createWaitlist: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20add%20a%20waitlist_v0.2.mp4',
    createContact: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20add%20a%20contact_v0.1.mp4',
    cancelReservation: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20cancel%20a%20reservation_v0.2.mp4',
    printChit: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20printchit%20reservations_v0.2.mp4',
    pageParty: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20send%20SMS_Email%20to%20a%20guest_v0.2.mp4',
    pageMultipleParties: '',
    viewAuditLog: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20view%20Audit%20Log%20of%20a%20transaction_v0.2.mp4',
    assignTable: '',
    viewStatistics: '',
    addStandby: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20add%20a%20standby%20reservation_v0.3.mp4',
    convertStandby: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20convert%20standby%20to%20a%20confirmed%20reservation_v0.2.mp4',
    viewStandby: '',
    noShow: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20no%20show%20a%20reservation_v0.2.mp4',
    quickSeat: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20a%20quick%20seat%20a%20party_v0.3.mp4',
    overrideSeatTime: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20override%20seating%20time_v0.2.mp4',
    seatParty: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20seat%20a%20party_v0.2.mp4',
    unseatParty: '',
    clearTable: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20Clear%20and%20Release%20Table_v0.2.mp4',
    setTableStatus: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20set%20table%20status_v0.2.mp4',
    moveParty: '',
    upcomingRes: '',
    checkBlock: '',
    checkTableAvail: '',
    quickBlock: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20quick%20block%20a%20table_v0.2.mp4',
    advBlock: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/Agilysys%20Seat%20Training_how%20to%20advance%20block%20a%20table_v0.2.mp4',
    unBlock: '',
    addServers: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20manage%20servers_v0.2.mp4',
    assignServers: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20assign_unassign%20servers%20from%20tables%20menu_v0.1.mp4',
    unassignServers: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20assign_unassign%20servers%20from%20tables%20menu_v0.1.mp4',
    createTabLayout: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20create%20a%20table%20layout_v0.2.mp4',
    createFloorPlan: '',
    customFloorPlan: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20create%20custom%20floorplan_v0.2.mp4',
    viewGuestHistory: '',
    otherResHistory: '',
    searchGuest: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20search%20a%20guest_v0.1.mp4',
    editContact: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20edit%20a%20contact_v0.1.mp4',
    deleteContact: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20delete%20a%20contact_v0.2.mp4',
    setPreferences: '',
    viewSpendHistory: '',
    setRoles: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20manage%20Roles%20and%20Permissions_v0.2.mp4',
    setPins: '',
    viewCoverReps: '',
    generateReps: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20generate%20reports_v0.2.mp4',
    scheduleReps: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/how%20to%20schedule%20reports_v0.2.mp4',
    prepaidRes: '',
    chargeNoshow: '',
    manageGuestCharge: '',
    refundPayment: '',
    addGuestNotes: '',
    addPartyNotes: '',
    bookSpecialMeal: '',
    switchRes: 'https://agysactivitiesqa.blob.core.windows.net/assets/TrainingVideos/How%20to%20switch%20restaurant%20and%20view%20availability%20in%20other%20restaurants_v0.2.mp4'
  },
  LifeTimeReportMerchantIds:[]
};
