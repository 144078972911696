<p class="textstyle">
      {{'thisTableTextline' | translate}} <span class="bold">{{'unavailableText' | translate}}</span> {{'forText' | translate}}<br/>
      • {{'usedintableSuggestionAlgorithmMsg' | translate}}<br/>
      • {{'usedinWebReservationsMsg' | translate}}<br/>
      • {{'usedinSlotGeneratingMsg' | translate}}<br/>
      
      <br/>
      
      {{'tableTextline' | translate}} <span class="bold">{{'availableText' | translate}} </span> {{'forText' | translate}}<br/>
      • {{'usedinManualAssignment' | translate}}<br/>
      • {{'usedinSeatingparties' | translate}}<br/>
      • {{'usedinWalkinsMsg' | translate}}<br/>
      
      <br/>
      
      {{'tableSettingInfo' | translate}}
  </p>