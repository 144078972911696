import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { CacheService } from '@app/core/services/cache.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { BookingBehavior, buttonTypes, ComponentTypes, FinancialEffectAction, OperationResultState, PartyState, PaymentMethod, RolesAndPermissionsType } from '@app/shared/constants/commonenums';
import { ButtonValue } from '@app/shared/dynamicform/models/field-config.interface';
import { BookingChargeType, SelectionType, SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { PartyService } from '@app/shared/services/party.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { DatePipe } from '@angular/common';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { PartyChargeState, PartyPrepaymentState } from '@app/shared/models/InputContact';
import { TranslateService } from '@ngx-translate/core';
import { round } from 'lodash';
import { seatRetailService } from '@app/shared/services/seatretail.service';
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
import { AppService } from '@app/app.service';
import { AlertType, ButtonType } from '@app/common/enums/shared-enums';
import { AuditableEntityType } from '@app/shared/models/SimpleAuditLogItemDTO';
import { AuditlogComponent } from '@app/shared/components/auditlog/auditlog.component';
import { popupDialogDimension } from '@app/shared/constants/globalConstants';
import { BookingDetail } from '../BookingDetail';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'open-booking-detail',
  templateUrl: './open-booking-detail.component.html',
  styleUrls: ['./open-booking-detail.component.scss']
})
export class OpenBookingDetailComponent extends BookingDetail implements OnInit, AfterViewInit, OnDestroy {

  @Input() data;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  @Output() cancelParty: EventEmitter<any> = new EventEmitter<any>();
  bookingDetail: any;
  public buttonControls: ButtonValue[];
  subscriptions: Subscription = new Subscription();
  locations: any[];
  showAll: any = {};
  additionalGuests: any[] = [];
  searchAdditionalGuestsText: string = '';
  PartyState = PartyState;
  party: any;
  dashboardStatus: any;
  warningInfoDialogRef: MatDialogRef<any>;
  financialPartyData: any;
  partydetail = {
    paid: false,
    notPaid: false,
    authorized: false,
    paymentdue: false
  };
  addonsForActivity: any = [];
  addonsForBooking: any = [];
  addonsForBookingPerLesson: any = [];
  showAddOnIcon = false;
  showAddOnDetails = false;
  addonsForActivitySelected = [];
  notesReservation: any = [];
  bookingPaymentMethod = PaymentMethod;
  availableOpenBookings: any[] = [];
  isRetailEnabled: boolean = false;
  noActionRequired: boolean = false;
  rolesAndPermissionsType = RolesAndPermissionsType;
  @ViewChild('addOnForBookingPopover') private addOnForBookingPopover: NgbPopover;
  partyWithAddon: any;

  constructor(public partyService: PartyService, public apiService: ApiService, public dashboardFunctions: DashboardFunctions, public cs: CacheService,
    protected dialog: MatDialog, private datePipe: DatePipe, public ts: TranslateService, public retailservice: seatRetailService, private utilities: CommonUtilities, public appService: AppService) {
    super(dialog, ts);
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
  }

  ngOnInit(): void {
    this.bookingDetail = this.data?.bookingDetail || null;
    if (this.data?.availableBookings?.length) {
      this.availableOpenBookings = this.data?.availableBookings;
    } else {
      this.availableOpenBookings = [this.bookingDetail];
    }
    this.noActionRequired = this.cs.isIframeEnabled; //When VCart is enabled, actions are not permitted
    this.setData();
    this.addonsForActivitySelected = Utilities.getAddons(this._settings.Addons, null)
    this.loadAddOnForActivity();
    this.isRetailEnabled = Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO);
    this.subscriptions.add(this.partyService.selectedParty$.subscribe(data => {
      if (data?.Id) {
        data.StartTime = new Date(data.SeatingTime);
        data.EndTime = new Date(data.DepartureTime);
        data.PackageName = this.cs.availablePackages.find(pack => pack.Id == data.PackageId)?.Name;
        data.checkInWithDues = this.partyService.validateAvailabilityForCheckInWithDues(data);
        this.bookingDetail = { ...data };

        const matchedBookingIndex = this.availableOpenBookings.findIndex(booking => booking.Id == data.Id);
        if(matchedBookingIndex > -1) {
          this.availableOpenBookings[matchedBookingIndex] = data;
        }
        this.setData();
      } else {
        this.onClose();
      }
    }));

    this.subscriptions.add(this.partyService.Parties$.subscribe(parties => {
      if (parties) {
        if (this.partyService.selectedParty$.value) {
          var party = parties.find(p => p.Id == this.partyService.selectedParty$.value.Id);
          if (party) {
            if (party.PackageId) {
              party.PackageName = this.cs.availablePackages.find(pack => pack.Id == party.PackageId)?.Name;
            }
            this.bookingDetail = { ...this.bookingDetail, ...party };
            this.availableOpenBookings = [this.bookingDetail];
            this.setData();
          } else {
            this.partyService.selectedParty$.next(null);
          }
        }
      }
    }));

    this.dashboardFunctions?.ts?.selectedTableBeforeAction$?.next(null);
  }
  setData() {
    this.setAvailableBookingStatus();
    this.staffAndLocationMapping();
    this.setMappedCustomGuestFields(-1);
  }
  setAvailableBookingStatus() {
    this.availableOpenBookings.map(data => {
      data.StartTime = new Date(data.SeatingTime);
      data.EndTime = new Date(data.DepartureTime);
      data.PackageName = this.cs.availablePackages.find(pack => pack.Id == data.PackageId)?.Name;
      data.checkInWithDues = this.partyService.validateAvailabilityForCheckInWithDues(data);

       //Set partyAmount 
       let bookingAmount = this.partyService.getBookingAmount(data);
       data['partyAmount'] = bookingAmount.partyAmount;

      return data;
    })
  }
  setStatusData() {
    const visitStatus = this.party.Contact.VisitStats && this.party.Contact.VisitStats.length > 0 ?
      this.party.Contact.VisitStats[0] : [];
    const statusArray = [
      {
        Name: 'lastVisited', Date: {
          day: visitStatus.LastVisitedAt ? moment(visitStatus.LastVisitedAt).format('DD') : '-',
          year: visitStatus.LastVisitedAt ? moment(visitStatus.LastVisitedAt).format('MMM YY') : '-'
        }
      },
      { Name: 'totalVisits', Count: visitStatus.NumberOfVisits ? visitStatus.NumberOfVisits : 0 },
      { Name: 'noShows', Count: visitStatus.NumberOfNoShows ? visitStatus.NumberOfNoShows : 0 },
      { Name: 'cancelled', Count: visitStatus.NumberOfCancellations ? visitStatus.NumberOfCancellations : 0 }];
    this.dashboardStatus = statusArray;
  }


  undoCheckIn(attendee){
    this.partyService.reinstateReservation(attendee);
  }
  ngAfterViewInit() {
    this.subscriptions.add(this.partyService.updateOpenBookingList$.subscribe(data => {
      if (data) {
        this.availableOpenBookings = data;
        this.setData();
      }
    }))
  }

  createOrEditOpenBooking(bookingDetail) {
    let party = this.partyService.Parties$.value.find(data => data.Id == bookingDetail?.Id) || bookingDetail;
    this.partyService.selectedParty$.next(party);
    if (this.partyService.selectedParty$.value.State == PartyState.Seated && !this.dashboardFunctions.checkEditCheckedInReservationsConfig() || (this.partyService.selectedParty$.value.State == PartyState.Left && !this.dashboardFunctions.checkBookPastDaysReservationsConfig())) {
      this.dashboardFunctions.editGuest(this.partyService.selectedParty$.value.Contact.Id, this.partyService.selectedParty$.value.Id)
    }
    else {
      if (this._settings.General.SpecialMealsCoverTypesEnabled) {
        this.subscriptions.add(this.partyService.getPartyInfo(this.partyService.selectedParty$.value.Id).subscribe(response => {
          if (response.Payload) {
            this.retailservice.oldReservation = _.cloneDeep(response.Payload);
            this.partyService.selectedParty$.value.CoverTypeQuantities = _.cloneDeep(response.Payload.CoverTypeQuantities)

            this.partyService.selectedParty$.value.BookingContacts = _.cloneDeep(response.Payload.BookingContacts)
            this.partyService.selectedParty$.value.BookingTypeQuantities = _.cloneDeep(response.Payload.BookingTypeQuantities)
            this.partyService.selectedParty$.value.Contact = _.cloneDeep(response.Payload.Contact);
            this.partyService.selectedParty$.value.BookingContactAddonItems = _.cloneDeep(response.Payload?.BookingContactAddonItems);
            this.partyService.selectedParty$.value.bookingRatePlan = response.Payload;
            this.partyService.selectedParty$.value.PaidAmount = response.Payload.PaidAmount;
            this.partyService.selectedParty$.value.RatePlan = response.Payload.RatePlan;
            this.partyService.selectedParty$.value.ShopItems = response.Payload.ShopItems;
            this.dashboardFunctions.createOrEditOpenBooking(true, this.partyService.selectedParty$.value);
          }
        }));
      }
      else {
        this.retailservice.oldReservation = _.cloneDeep(this.partyService.selectedParty$.value);
        this.dashboardFunctions.createOrEditOpenBooking(true, this.partyService.selectedParty$.value);
      }
    }
  }
  staffAndLocationMapping() {
    let partyIds: any[] = [];
    if (this.bookingDetail.TableNames) {
      this.locations = [...this.bookingDetail.TableNames];
    }
    this.loadAdditionalGuests(partyIds);
  }

  loadAdditionalGuests(partyIds: number[]) {
    this.subscriptions.add(this.partyService.Parties$.subscribe(parties => {
      this.party = parties.find(party => party.Id == this.bookingDetail.Id);
      if (this.party) {
        this.setStatusData();
      } else {
        this.onClose();
      }
      this.additionalGuests = this.party?.SecondaryContacts || [];
    }));
  }
  checkIn(bookingDetail) {
    this.partyService.selectedParty$.next(bookingDetail);
    this.dashboardFunctions.CheckIn(bookingDetail, true);
  }

  cancel(party) {
    this.partyService.selectedParty$.next(party);
    this.dashboardFunctions.cancelOpenBooking(party);
  }

  onClose() {
    this.close.emit();
  }




  checkOut(bookingDetail) {
    this.partyService.selectedParty$.next(bookingDetail);
  this.partyService.checkOutOpenOrPrivateBooking(bookingDetail);
  }

  checkInWithDues(bookingDetail) {
    if (Utilities.isUserHasAccessToRetail(this.appService.restaurantId)) {
      bookingDetail.FinancialEffectId = null;
      this.dashboardFunctions.CheckIn(bookingDetail, false, true);
    } else {
      this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
    }
    
  }

 

  moveToShopScreen() {
    this.partyService.currentPartyId = this.bookingDetail?.Id;
    if (Utilities.isUserHasAccessToRetail(this.appService.restaurantId)) {
      this.partyService.bookingDetailViewPopupDialogRef?.close();
      this.retailservice.SetShopItems([], null, null, this.bookingDetail?.Id, null,null, this.bookingDetail?.Contact?.Id,null,null,this.bookingDetail?.Contact?.TrackMembershipNumber)
    }
    else {
      this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
    }
  }


  updateAddOnDetails(activity) {
    this.partyWithAddon = activity;
  }

  loadAddOnForActivity() {
    this.showAddOnDetails = false;
    this.addonsForBookingPerLesson = [];
    this.addonsForActivity = [];
    this.addonsForActivitySelected = _.orderBy(this.addonsForActivitySelected, 'IsMandatory', 'desc')
    this.showAddOnIcon = true;
    this.addonsForBooking = this.addonsForActivitySelected;
    this.addonsForActivity = this.addonsForActivitySelected;
  }

  financialDetails(attendee) {
    this.financialPartyData = attendee;
    }

  reservationNotes(bookingDetail) {
    this.notesReservation = this.dashboardFunctions.getReservationNotes(bookingDetail, BookingBehavior.OpenBooking);
  }

  showWarningInfoPopUp(message, componentType, popupHeight, popupWidth) {

    let title = this.ts.instant('CheckinInfoText');
    let updateText = 'OK';
    let showAlert = true;

    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: this.ts.instant('CheckinInfoText'), showAlert: showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    this.warningInfoDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: popupWidth,
      height: popupHeight,
      data: {
        title,
        update: updateText,
        componentDetails,
        from: componentType,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: true,
      }
    });

  }

  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
