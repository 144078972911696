import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'accordion-sidebar',
  templateUrl: 'accordingsidebar.component.html',
  styleUrls: ['accordingsidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccordionComponent implements OnInit {
  @Input() header: any; //Header Pass the Current Array From ReservationComponent 
  public textletter;
  public buttonname: string = "More";
  public divheight: any;
  public lettercount: number = 60;
  @Input() componentDetails: any;
  constructor() {
    this.textletter = "I am building a website with CodeIgniter, I have various resources that I load with the base_url helper function like this";
  }



  ReadMore(p) {
    if (p == "More") {
      this.buttonname = "Less"
      this.divheight = "auto";
      this.lettercount = 5000;
    } else {
      this.buttonname = "More"
      this.divheight = "";
      this.lettercount = 60;
    }

  }
  ngOnInit() {

  }

}
