
<section class="gift-cards-payment-form">
  <form class="container-section" [formGroup]="giftCardsPaymentFormGroup">
    <div class="d-flex">
      <div class="w-50">
        <label class="form-label-normal">{{giftCardCaptions.GiftCardBalance}}</label>
        <div>{{balance  | Currency}}</div>
      </div>
      <mat-form-field class="w-50" [floatLabel]="floatLabel">
        <input [attr.automationId]="'Txt_shopGiftCardPaymentPopup_amount'" matInput placeholder="{{giftCardCaptions.AmountPaid}}" CurrencyFormatter
          inputtype="nonnegative,decimal,roundoff2" (keyup)="calculateBalance()" maxlength=15 required
          formControlName="amount">      
      </mat-form-field>
    </div>

    <div class="d-flex" *ngIf="cashBackEnabled">
      <div class="mb-4">
        <label class="form-label-normal d-block">{{giftCardCaptions.CashBack}}</label>
        <app-retail-toggle-switch [attr.automationId]="'Tog_shopGiftCardPaymentPopup_cashBack'" [formControl]="giftCardsPaymentFormGroup.controls['cashback']"
          (changeToggleEvent)=OnCashBackToggle($event)></app-retail-toggle-switch>
      </div>
      <div class="cashback-Amt" *ngIf="giftCardsPaymentFormGroup.controls['cashback'].value">
        <label class="form-label-normal">{{giftCardCaptions.CashbackAmount}}</label>
        <div>{{cashbackAmt  | Currency}}</div>
      </div>
    </div>

    <label class="form-label-normal">{{giftCardCaptions.UpdatedGiftCardBalance}}</label>
    <div>{{updatedBalance | Currency}}</div>

    </form>

    <mat-dialog-actions class="actions-section">
      <button [attr.automationId]="'Btn_shopGiftCardPaymentPopup_ok'" mat-button class="body-bgcolor whitecolor text-capital" (click)="SendGiftCardToPayment()"
        [ngClass]="giftCardsPaymentFormGroup.valid ? 'button_valid' : 'button_invalid'"
        [disabled]="!giftCardsPaymentFormGroup.valid"> {{captions.OK}}</button>
    </mat-dialog-actions>
    </section>
