import { Injectable } from "@angular/core";
import { RetailLocalization } from "../../../common/localization/retail-localization";
import * as _ from 'lodash';
import { HttpServiceCall, HttpMethod } from "../../../shared/service/http-call.service";
import { BaseResponse } from "../../../shared/business/shared.modals";
import { Host, Product, THERAPIST, USER } from "../../../shared/globalsContant";
import { RetailTransactionService } from "../../view-categories/retail-transactions/retail.transactions.service";
import { TransactionDetailWithPostedCommisisons, PostedCommissions, GivenCommissionAmountType, CommissionableStaff } from "../../shop.modals";
import { CommissionableUser } from '../../assign-commission-popup/assign-commission.models';
import { RetailUtilities } from "../../../shared/utilities/retail-utilities";
import { RetailDataAwaiters } from "../../../shared/events/awaiters/retail.data.awaiters";
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';

@Injectable()
export class TicketDetailsService {

    private captions: any = this.localization.captions.shop;
    constructor(private localization: RetailLocalization, private http: HttpServiceCall, public retailTransactionService: RetailTransactionService,
        private utils: RetailUtilities, private _shopService: CommonVariablesService) {

    }

    async getPostedCommissions(ticketNumber: string): Promise<PostedCommissions[]> {
        this.retailTransactionService.ClosedTransactions = this.retailTransactionService.ClosedTransactions ? this.retailTransactionService.ClosedTransactions : [];
        let transaction = this.retailTransactionService.ClosedTransactions.find(x => x.ticketNumber == ticketNumber);
        if (!transaction || !transaction.id) {
            return [];
        }
        let getTherapistTask = this.getAllTherapists();
        var transWithPostedCommisison: TransactionDetailWithPostedCommisisons[] = await this.getTransactionDetailsWithCommission(transaction.id);
        let allTherapist = await getTherapistTask;
        const postedCommissions: PostedCommissions[] = [];
        let lineNumber: number = 1;
        let therapistName = (stafftype: string, staffId) => {
            var therapist = '';
            if (allTherapist && allTherapist.length > 0 && stafftype == THERAPIST) {
                let matchingStaff = allTherapist.find(r => r.id == staffId);
                therapist = matchingStaff ? matchingStaff.firstName + ' ' + matchingStaff.lastName : ''
            }
            return therapist;
        }
        transWithPostedCommisison.forEach(x => {
            let selected = (x.isCommissionable || x.commissionAmount > 0 || x.commissionPercentage > 0) && !postedCommissions.some(y => y.radioSelected);
            postedCommissions.push({
                itemId: x.itemId,
                lineNumber: lineNumber,
                itemNumber: Number(x.itemNumber),
                itemDescription: x.itemDescription,
                isCommissionable: x.isCommissionable,
                radioDisabled: !x.isCommissionable,
                radioSelected: selected,
                quantity: x.quantity,
                staffName: x.staffName || therapistName(x.staffType, x.staffId),
                commissionAmount: x.commissionPercentage != 0 ? 0 : x.commissionAmount,
                commissionPercentage: x.commissionPercentage,
                amount: x.totalAmount,
                commissionType: x.commissionPercentage != 0 ? GivenCommissionAmountType.Percentage : GivenCommissionAmountType.Amount,
                staffId: x.staffId,
                staffType: x.staffType,
                eligibilityId: x.eligibilityId,
                transactionDetailId: x.transactionDetailId,
                radioToolTip: x.isCommissionable ? "" : this.captions.NonCommissionable,
                eligibleAmount: x.eligibleAmount,
                discount: x.discount,
                postedCommissionType: x.commissionType,
                outletId: x.outletId,
                netEligibleAmount: x.netEligibleAmount,
                netEligibleWithoutDiscount: x.netEligibleWithoutDiscount
            });
            lineNumber++;
        })

        return postedCommissions;
    }

    async getTransactionDetailsWithCommission(transactionId: string): Promise<TransactionDetailWithPostedCommisisons[]> {
        try {
            return await this.InvokeServiceCall("TransactionDetailWithPostedCommission", Host.retailPOS, HttpMethod.Get, { transactionId: transactionId });
        } catch (e) {
            this.http.exceptionHandle(e);
     }
    }

    async getAllTherapists(): Promise<any> {
        try {
            return await RetailDataAwaiters.getAllTherapists();
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }
    async InvokeServiceCall(CallDesc: string, host: Host, method: HttpMethod, uriParams?: any, body?: any): Promise<any> {
        var result: BaseResponse<any> = await this.http.CallApiAsync({
            callDesc: CallDesc,
            host: host,
            method: method,
            uriParams: uriParams,
            body: body
        });
        return result ? result.result : null;
    }

    async getCommissionableUsers(itemId: number) {
        let result: BaseResponse<CommissionableUser[]> = await this.InvokeServiceCall('GetCommissionableUsers', Host.retailManagement, HttpMethod.Get, { itemId: itemId, productId: this._shopService.ProductId,outletId:this._shopService.SelectedOutletId });
        var staffs = (result ? result : []) as CommissionableUser[];
        let commissionableStaffs: CommissionableStaff[] = staffs.map(s => {
            return <CommissionableStaff>{
                id: this.GetCustomStaffId(s.id, USER),
                staffId: Number(s.id),
                staffName: `${s.firstName} ${s.lastName}`,
                staffType: USER,
                commissionClass: s.commissionClassId
            }
        });
        return commissionableStaffs ? commissionableStaffs : [];
    }

    async getCommissionableStaffByItem(itemId: number, productId: number): Promise<CommissionableStaff[]> {
        let commissionableStaff: CommissionableStaff[] = [];
        if (productId == Product.SPA) {
            let therapist = await this.InvokeServiceCall("GetCommissionableStaffs", Host.retailManagement, HttpMethod.Get, { itemId: itemId, productId: this._shopService.ProductId,outletId:this._shopService.SelectedOutletId });
            therapist = therapist && therapist.length > 0 ? therapist : [];
            for (const element of therapist) {
                commissionableStaff.push({
                    staffId: element.id,
                    id: this.GetCustomStaffId(element.id, element.staffType),
                    staffName: `${element.firstName} ${element.lastName}`,
                    staffType: element.staffType,
                    commissionClass: element.commissionClassId ? element.commissionClassId : 0
                });
            }
        }
        else {
            commissionableStaff = await this.getCommissionableUsers(itemId);
        }
        if (commissionableStaff.length == 0) {
            commissionableStaff.push({
                id: '',
                staffName: '',
                staffType: null,
                commissionClass: 0,
                staffId: 0
            });
        }
        return commissionableStaff;
    }

    async getCommission(selectedStaffId: number, itemId: number, staffType: string, commissionClass: number, productId: number, outletId: number) {
        try {
            return await this.InvokeServiceCall("getCommissionForItem", Host.commission,
                HttpMethod.Get, { itemId: itemId, staffId: selectedStaffId, staffType: staffType, commissionClass: commissionClass, productId: productId, outletId: outletId });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    getHeader() {
        return [
            { "title": this.captions.ItemNumber, "jsonkey": "itemNumber", "alignType": "left" },
            { "title": this.captions.ItemDescription, "jsonkey": "itemDescription", "alignType": "left" },
            { "title": this.captions.Quantity, "jsonkey": "quantity", "alignType": "left" },
            { "title": this.captions.StaffName, "jsonkey": "staffName", "alignType": "left" },
            { "title": `${this.captions.Commission} (${this.localization.currencySymbol})`, "jsonkey": "commissionAmount", "alignType": "right", "datatype": "money" },
            { "title": `${this.captions.Commission} (%)`, "jsonkey": "commissionPercentage", "alignType": "right", "datatype": "percentage" },
            { "title": `${this.captions.Amount} (${this.localization.currencySymbol})`, "jsonkey": "amount", "alignType": "right", "datatype": "money" }];
    }

    async modifyOrAddCommission(isCreate: boolean, commissionObject: any) {
        let result = await this.InvokeServiceCall("AddOrModifyPostedCommission", Host.commission, HttpMethod.Put, { isCreate: isCreate }, commissionObject);
        console.log(result);
    }

    GetCustomStaffId(staffId: any, staffType: string): string {
        var customId = _.cloneDeep(staffId);
        if (staffType == THERAPIST) {
            customId = `T${staffId}`
        }
        else if (staffType == USER) {
            customId = `U${staffId}`
        }
        return customId;
    }

}
