import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { RetailPopupComponent } from '../retail-popup/retail-popup.component';
import {
  QuickSaleCategory, QuickSaleItem, 
  OutletSubProperty,
  RetailItemType,
  QuickSaleCategoryOutlets,
  QuickSaleCategoryChange
} from '../retail.modals';
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { BaseResponse } from '../shared/business/shared.modals';
import * as myGlobals from '../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { RetailLocalization } from '../common/localization/retail-localization';
import { Subscription } from 'rxjs';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { RetailFunctionalityBusiness } from '../shared/business/retail-functionality.business';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service'; 
import { ButtonType } from 'src/app/common/shared/shared/globalsContant';
import { AlertAction, AlertType } from '../shared/shared.modal';
import { Localization } from 'src/app/common/localization/localization';


@Component({
  selector: 'app-retail-quick-sale',
  templateUrl: './retail-quick-sale.component.html',
  styleUrls: ['./retail-quick-sale.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RetailFunctionalityBusiness]
})

export class RetailQuickSaleComponent implements OnInit, OnDestroy {
  quickSaleItems: any[] = [];
  category: any = [];
  count: any = [];
  searchText: string = '';
  categories: QuickSaleCategory[] = [];
  items: QuickSaleItem[] = [];
  dataToBind: any;
  captions: any;
  allCaptions: any;
  subscription: Subscription;
  IsViewOnly: boolean;
  outlets: OutletSubProperty[] = [];
  selectedOutletId: number = 0;
  retailItems = [];
  outletCategories: QuickSaleCategoryOutlets[] = [];
  defaultOutlet: number;
  public functionalities: { [key: string]: boolean} = {};
  floatLabel: string;
  floatLabelNever: string;
  constructor(public dialog: MatDialog,
              private _localization: Localization, 
            private _rs: RetailSetupService, 
     private http: HttpServiceCall, public PropertyInfo: RetailPropertyInformation,
              public _utilities: RetailUtilities, public localization: RetailLocalization, public func: RetailFunctionalityBusiness) {
                this.floatLabel = this.localization.setFloatLabel;
                this.floatLabelNever = this.localization.setFloatLabelNever;
  }


  ngOnInit() {
    this.captions = this.localization.captions.retailsetup;
    this.allCaptions = this.localization.captions;
    this.defaultOutlet = this.PropertyInfo.GetDefaultOutlet();
    this.LoadOutlets();
    this.IsViewOnly = this._rs.retailSetupBreakPoints.find(bp => bp.breakPointNumber == myGlobals.RetailBreakPoint.QuickSaleKeys).view;
    this._rs.tabLoaderEnable.next(false);
  }

  async drop(event) {
    const index = this.updateInActiveListOrder(event.previousIndex, event.currentIndex);
    moveItemInArray(this.category, event.previousIndex, event.currentIndex);
    var response = await this._rs.InvokeServiceCallAsync("CategoryOutletListOrderChange", myGlobals.Host.retailManagement, HttpMethod.Put, undefined, { outletId: this.selectedOutletId, fromOrder: index.from, toOrder: index.to});
    if (response && response.result) {
      this._rs.quickSaleItem = response.result.items;
      this._rs.quickSaleCategory = response.result.category;
      this.FormData();
    }
  }

  async dropInnerItems(event, item){
    const currentitemid = parseInt(event.container.id);
    const previousitemid = parseInt(event.previousContainer.id); 
    const index = this.updateListOrderWithCategory(event.previousIndex, event.currentIndex, currentitemid, previousitemid);
    if (currentitemid !== previousitemid ){
      const currentcategory = this.categories.find(category => category.id === currentitemid).quickSaleCategory;
      const previouscategory = this.categories.find(category => category.id === previousitemid).quickSaleCategory;
      const alert = this._localization.replacePlaceholders(this.captions.ConfirmationDragandDrop,
        ['item', 'previouscategory', 'tocategory'], [index.ItemName, previouscategory, currentcategory]);
      this._utilities.showAlert(alert, AlertType.Warning, ButtonType.YesNo, (o) => {
        if (o === AlertAction.YES) {
          const body: QuickSaleCategoryChange = {
            outletId: this.selectedOutletId,
              fromCategoryId: previousitemid,
              toCategoryId: currentitemid,
              quickSaleCategoryId: 0,
              fromOrder: index.from,
              toOrder: index.to
          };
          this.savecategorychange(body);
        }
      });
    }else{
      const fromOrder = this.quickSaleItems[event.previousIndex].listOrder;
      const toOrder = this.quickSaleItems[event.currentIndex].listOrder;
      moveItemInArray(this.quickSaleItems, event.previousIndex, event.currentIndex);
      this.savetoApi(currentitemid, index.from, index.to);
    }
    
  }
  savetoApi(currentitemid , fromOrder, toOrder){
    this._rs.InvokeServiceCallAsync("ItemListOrderChange", myGlobals.Host.retailManagement, HttpMethod.Put, undefined, { outletId: this.selectedOutletId, categoryId: currentitemid ,fromOrder: fromOrder, toOrder: toOrder}).then(response =>{
    if (response && response.result) {
      this._rs.quickSaleItem = response.result.items;
      this._rs.quickSaleCategory = response.result.category;
      this.FormData();
    }
  });
  }
  savecategorychange(body){
    this._rs.InvokeServiceCallAsync("CategoryItemAndListOrderChange", myGlobals.Host.retailManagement, HttpMethod.Put,body ).then(response =>{
    if (response && response.result) {
      this._rs.quickSaleItem = response.result.items;
      this._rs.quickSaleCategory = response.result.category;
      this.FormData();
    }
  });
  }
  async LoadOutlets() {
    this._utilities.ToggleLoaderWithMessage(true,this.localization.captions.lbl_processing)
    this.functionalities = await this.func.getRetailFunctionality();
    if (!this.functionalities?.ShowOutletFieldInQuickSaleSetupScreen && this.defaultOutlet === 0) {
      this.defaultOutlet = await this.func.getDefaultOutlet();
      if (this.defaultOutlet > 0 ) {
        this.PropertyInfo.SetDefaultOutlet(this.defaultOutlet);
      }
    }
    this.outlets = [];
    let apiResponse: BaseResponse<OutletSubProperty[]> = await this.InvokeServiceCallAsync('GetSubPropertyAccessByUser', myGlobals.Host.retailManagement, HttpMethod.Get, { userId: this._utilities.GetPropertyInfo("UserId") });
    if (apiResponse && apiResponse.successStatus && apiResponse.result && apiResponse.result.length > 0) {
      apiResponse.result.forEach(e => {
        if (e.isActive) {
          if (this.selectedOutletId === 0) {
            const tempSubPropertyId = this.defaultOutlet > 0 ? this.defaultOutlet : e.subPropertyID;
            this.selectedOutletId = this.functionalities?.ShowOutletFieldInQuickSaleSetupScreen ?  e.subPropertyID : tempSubPropertyId;
          }
          this.outlets.push(e);
        }
      });
      this.InvokeServiceCall("QuickSaleCategoryAndItems", myGlobals.Host.retailManagement, HttpMethod.Get);
    }
  }

  async InvokeServiceCallAsync(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  outletChange(event) {
    this.selectedOutletId = event.value;
    this.FormData();
  }

  getData(category, items) {
    this.categories = category;
    const allCategory = _.cloneDeep(this.categories);
    const quickSaleCategory: any = allCategory.map(x=>x.quickSaleCategoryOutlets)?.flat(); 
    this.quickSaleItems = items.filter(x => x.outletId ==this.selectedOutletId ).sort((a,b) => a.listOrder - b.listOrder );
    this.category = [];
    this.count = [];
    this.category = this.quickSaleItems.map(a => a.quickSaleCategoryId);
    var _this = this
    this.category.forEach(function (x) { _this.count[x] = (_this.count[x] || 0) + 1; });
    if(quickSaleCategory.length > 0) {
      const quickSaleCategoryOutlet: QuickSaleCategoryOutlets[] = quickSaleCategory.filter(x=>x.outletId == this.selectedOutletId);
      this.outletCategories = quickSaleCategoryOutlet.sort((a,b)  => a.listOrder - b.listOrder);
      this.category = this.outletCategories.map(x => x.quickSaleCategoryId );
    } 
  }

  getCategoryListOrder(quickSaleCategoryId: number): number {
    let category = this.categories.filter(r => {
      return r.id == quickSaleCategoryId
    });
    return category.length > 0 ? category[0].listOrder : 0;
  }

  async GetItems(): Promise<any> {
    let allItems: any[] = [];
    if (this._rs.quickSaleItem.length == 0) {
      return allItems;
    }
    let retailItemIds = this._rs.quickSaleItem.filter(r => r.outletId == this.selectedOutletId).map(r => r.itemId);
    if (!retailItemIds || retailItemIds.length == 0) {
      return allItems;
    }
    this.retailItems = await this._rs.getRetailItemsDetailedInfoByIds(retailItemIds);
    for (let saleItem of this._rs.quickSaleItem) {
      if (this.selectedOutletId == saleItem.outletId) {
        allItems.push(
          {
            'id': saleItem.itemId,
            'number': this.GetItemNumber(saleItem.itemId),
            'name': this.GetItemName(saleItem.itemId),
            'quickSaleCategoryId': saleItem.quickSaleCategoryId,
            'listOrder':saleItem.listOrder,
            'outletId':saleItem.outletId
          }
        );
      }
    }
    return allItems;
  }

  openDialog(title, templateName) {
    let dialogRef = this.dialog.open(RetailPopupComponent, {
      width: '60%',
      height: '80%',
      disableClose: true,
      data: { headername: title, closebool: true, templatename: templateName, automationId: 'retailquickSale' },
      hasBackdrop: true,
      panelClass: 'quickSaleItem'
    });
    this.subscription = dialogRef.afterClosed().subscribe(result => {
      this.FormData();
    });
  }

  searchItems(event) {
    let filteredItems: any[] = [];
    if (this.searchText.length > 0) {
      filteredItems = this.dataToBind['items'].filter(
        item => item.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
    else {
      filteredItems = this.dataToBind['items'];
    }
    this.getData(this.dataToBind['category'], filteredItems);
  }

  public GetCategoryName(id: number): string {
    let category = this.categories.filter(r => {
      return r.id == id
    });
    return category.length > 0 ? category[0].quickSaleCategory : '';
  }

  private GetItemName(id: number): string {
    let itemName = '';
    if (!this.retailItems || this.retailItems.length == 0) {
      return itemName;
    }
    let item = this.retailItems.find(r => r.id == id);
    if (item) {
      itemName = item.retailItemDetail.itemDescription;
    }
    return itemName;
  }

  private GetItemNumber(id: number): string {
    let itemNumber = '';
    if (!this.retailItems || this.retailItems.length == 0) {
      return itemNumber;
    }
    let item = this.retailItems.find(r => r.id == id);
    if (item) {
      itemNumber = item.retailItemDetail.itemNumber;
    }
    return itemNumber;
  }

  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "QuickSaleCategoryAndItems") {
      let itemList = <any>result.result;
      if (itemList != null) {
        this._rs.quickSaleItem = itemList.items;
        this._rs.quickSaleCategory = itemList.category;
        this.FormData();
      }
    }
    else if (callDesc == 'GetActiveRetailItems') {
      this._rs.shopItems = result ? this.getfilterRetailItems(result.result) : [];
      this.FormData();
    }
    this._utilities.ToggleLoaderWithMessage(false)
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
    this._utilities.ToggleLoaderWithMessage(false)
  }

  getfilterRetailItems(retailItemDetail): any {
    if (retailItemDetail) {
      return retailItemDetail.filter(o => o.itemType != RetailItemType.RevenueItem);
    }
    return [];
  }

  async FormData() {
    this.dataToBind = {
      'items': await this.GetItems(),
      'category': this._rs.quickSaleCategory
    };
    // Sorting based on name  
    this.dataToBind['items'] = this._utilities.sortObj(this.dataToBind['items'], 'name');
      this.getData(this.dataToBind['category'], this.dataToBind['items']);
  }

  async onDelete(event) {
    let item: QuickSaleItem [] = [{
      id: 0,
      isRemoved:true,
      itemId: event.id,
      outletId: this.selectedOutletId,
      quickSaleCategoryId: event.quickSaleCategoryId   
    }];    
    var response = await this._rs.InvokeServiceCallAsync("AddQuickSaleItem", myGlobals.Host.retailManagement, HttpMethod.Post, item, { outletId: this.selectedOutletId });
    if (response && response.result) {
      this._rs.quickSaleItem = response.result.items;
      this._rs.quickSaleCategory = response.result.category;
      this.FormData();
    }
  }

  updateInActiveListOrder(fromIndex:number, toIndex:number):{from: number, to: number} {
    return { 
      from: this.outletCategories.find(x=>x.quickSaleCategoryId == this.category[fromIndex]).listOrder, 
      to: this.outletCategories.find(x=>x.quickSaleCategoryId == this.category[toIndex]).listOrder 
    };
  }
  updateListOrderWithCategory(fromIndex: number, toIndex: number, fromCategory: number, toCategory: number) : {from: number, to: number,ItemName: string} {
    const fromCategoryList = this.quickSaleItems.filter(quickSaleItem => quickSaleItem.quickSaleCategoryId === toCategory);
    const toCategoryList =  this.quickSaleItems.filter(quickSaleItem => quickSaleItem.quickSaleCategoryId === fromCategory);
    let toindex = 0;
    if (toCategoryList.length === toIndex && toCategoryList.length !== 0 )
    {
      toindex = toCategoryList[toIndex - 1].listOrder + 1;
    }
    else if (toCategoryList.length > 0)
    {
      toindex = toCategoryList[toIndex].listOrder;
    }
    return {
      from: fromCategoryList[fromIndex].listOrder,
      to: toindex,
      ItemName: fromCategoryList[fromIndex].name
    };
  }
  getConnectedList(): any[] {
    return this.category.map(x => `${x}`);
  }
}



