import { AppService } from '@app/app.service';
import { ChangeAction, PartyType } from '@constants/commonenums';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { BookedSessionDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import { Session } from 'inspector';
import { Processor } from './processor';
import { Utilities } from '@utilities/utilities';

export class BookedSessionChangeProcessor implements Processor {

  private static instance: BookedSessionChangeProcessor;
  private constructor(public partyService: PartyService,public as: AppService) { }

  public static Instance(componentService: any = PartyService,_as: AppService): BookedSessionChangeProcessor {
    if (!BookedSessionChangeProcessor.instance) {
      BookedSessionChangeProcessor.instance = new BookedSessionChangeProcessor(componentService,_as);
    }

    return BookedSessionChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: object,propertyId: number): void {
    let partiesList;
    let IsStandbyParty: boolean = false;
    let selectedContactParties = this.partyService.SelectedContactParties$.value;
    if (this.checkStandby(objectChange,propertyId)) {
      IsStandbyParty = true;
      if(propertyId == Utilities.RestaurantId()) {
        partiesList =this.partyService.standbyPartiesList.length > 0 ? this.partyService.standbyPartiesList: this.partyService.StandbyParties$.value
      }
      else{
        partiesList = this.as._cs.propertySettings.value[propertyId].StandbyParties;
      }

    }
    else {
      IsStandbyParty = false;
      if(propertyId == Utilities.RestaurantId()) {
      partiesList = this.partyService.Parties$.value;
    }
    else{
      partiesList = this.as._cs.propertySettings.value[propertyId].reservations;
     }
    }
    if (objectChange) {
      if (objectChange.Action === ChangeAction.Created) {
        const createdSessionId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party = partiesList.filter(party => party.Id == partyId);
        if (party && party.length > 0) {
          this.pushNewBookedSessions(objectChange, party)
        }

        ////for parties in SelectedContactParties$
        const partyInContact = selectedContactParties.filter(party => party.Id == partyId);
        if (partyInContact && partyInContact.length > 0) {
          this.pushNewBookedSessions(objectChange, partyInContact)
        }
      }
      if (objectChange.Action === ChangeAction.Updated && partiesList.length > 0) {
        const updatedSessionId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party = partiesList.filter(party => party.Id == partyId);
        if (party && party.length > 0) {
          const index = party[0].BookedSessions.findIndex(session => session.Id == updatedSessionId);
          const bookedSessions: BookedSessionDTO = party[0].BookedSessions[index];
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(bookedSessions).includes(property.PropertyName.replace('Internal', ''))) {
              if(property.PropertyName.replace('Internal', '') == 'Locations'){
                bookedSessions[property.PropertyName.replace('Internal', '')] =JSON.parse(property.Value);
              }else{
                bookedSessions[property.PropertyName.replace('Internal', '')] = property.Value;
              }
            
            }
          });
        }
      }
      if (objectChange.Action === ChangeAction.Updated) {
        const updatedSessionId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party = partiesList.find(party => party.Id == partyId);
        if (party && party.length > 0) {
         this.updateBookedSessions(objectChange, updatedSessionId, party, partiesList);
        }

        ////for parties in SelectedContactParties$
        const partyInContact = selectedContactParties.find(party => party.Id == partyId);
        if (partyInContact && partyInContact.length > 0) {
         this.updateBookedSessions(objectChange, updatedSessionId, partyInContact, selectedContactParties);
        }
      }
      if (objectChange.Action === ChangeAction.Removed) {
        const removedSessionId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party = partiesList.find(party => party.Id == partyId);
        if (party) {
          this.removeBookedSessions(removedSessionId,party, partiesList )
        }

        ///for parties in SelectedContactParties$
        const partyInContact = selectedContactParties.find(party => party.Id == partyId);
        if (partyInContact) {
          this.removeBookedSessions(removedSessionId, partyInContact, selectedContactParties)
        }
      }
    }
    if(propertyId == Utilities.RestaurantId()){
    if (IsStandbyParty) {
      this.partyService.StandbyParties$.next(partiesList);
      this.as._cs.propertySettings.value[propertyId].StandbyParties = partiesList;
    }
    else {
      this.partyService.Parties$.next(partiesList);
      this.partyService.partiesList = partiesList;
      this.as._cs.propertySettings.value[propertyId].reservations = partiesList;
    }

    this.partyService.SelectedContactParties$.next(selectedContactParties);
  }else{
    if (IsStandbyParty){
      this.as._cs.propertySettings.value[propertyId].StandbyParties = partiesList;
    }
    else {
      this.as._cs.propertySettings.value[propertyId].reservations = partiesList;
    }
  }
  }
  checkStandby(change: ObjectChange,propertyId) {
    let objPartyId = change.PropertyChanges.filter(property => property.PropertyName.replace('Internal', '') == 'PartyId')[0].Value;
    let partyDetail = null;
    if(propertyId == Utilities.RestaurantId()) {
      partyDetail = this.partyService.StandbyParties$.value.find((party) => party.Id === objPartyId);
    }else{
      partyDetail = this.as._cs.propertySettings.value[propertyId].StandbyParties.filter((party) => party.Id === change.ObjectId);
    }
    if (partyDetail && partyDetail.Type == PartyType.StandBy) {
      return true;
    }
    return false;
  }

  pushNewBookedSessions(objectChange, party){
    const bookedSessions: BookedSessionDTO = new BookedSessionDTO();
    objectChange.PropertyChanges.forEach(property => {
      if (Object.getOwnPropertyNames(bookedSessions).includes(property.PropertyName.replace('Internal', ''))) {
        if(property.PropertyName.replace('Internal', '') == 'Locations'){
          bookedSessions[property.PropertyName.replace('Internal', '')] = JSON.parse(property.Value);
        }else {
          bookedSessions[property.PropertyName.replace('Internal', '')] = property.Value;
        }
       
      }
    });
    if (!party[0].BookedSessions) {
      party[0]['BookedSessions'] = [];

    }
    party[0].BookedSessions.push(bookedSessions)
  }

  updateBookedSessions(objectChange, updatedSessionId, party, partiesList){
    const bookedSessions: BookedSessionDTO = party.BookedSessions.find(session => session.Id == updatedSessionId);
    objectChange.PropertyChanges.forEach(property => {
      if (Object.getOwnPropertyNames(bookedSessions).includes(property.PropertyName.replace('Internal', ''))) {
        if(property.PropertyName.replace('Internal', '') == 'Locations'){
          bookedSessions[property.PropertyName.replace('Internal', '')] = JSON.parse(property.Value);
        }
        else{
          bookedSessions[property.PropertyName.replace('Internal', '')] = property.Value;
        }
       
      }
    });
    party.BookedSessions[party.BookedSessions.indexOf(bookedSessions)] = bookedSessions;
    partiesList[partiesList.indexOf(party)] = party;
  }

  removeBookedSessions(removedSessionId,party, partiesList ){
    const bookedSessions: BookedSessionDTO = party.BookedSessions.find(session => session.Id == removedSessionId);
    if (bookedSessions) {
      party.BookedSessions = party.BookedSessions.filter(session => session.Id != removedSessionId);
    }
    partiesList[partiesList.indexOf(party)] = party;
  }

}
