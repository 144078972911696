import { Component, OnInit, Output, EventEmitter, Input, ViewContainerRef } from '@angular/core';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { ReportDataService } from '../../../data/report-data.services';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { RetailReportControl, ValidationMessage } from '../../../basereport/base-report.component';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
@Component({
  selector: 'app-retail-inventory-audit',
  templateUrl: './inventory-audit.component.html',
  styleUrls: ['./inventory-audit.component.scss']
})
export class RetailInventoryAuditComponent implements OnInit {
  ShowOutletSelection: boolean;
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  functionalities: { [key: string]: boolean} = {};
  @Input('ShowOutletSelection')
  set showOutletSelection(value) {
    if (value) {
      this.IsValidSelection.emit(this.validateOutlets);
    }
  }
  public captions: any = this.localization.captions.reports;
  InventoryTransferFormGrp: UntypedFormGroup;
  validateOutlets: ValidationMessage = {
    dropDownName: this.localization.captions.reports.PleaseSelectAnyOutlet,
    validity: false
  };
  outlets: any[];
  selectedDropdownType: any;
  valuesSelected: any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  floatLabel: string;
  editPatchValue : any;
  isFromEdit = false;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, private dataservice: ReportDataService, public business: ReportBusinessService, private func: RetailFunctionalityBusiness
    , private container: ViewContainerRef) {
    this.floatLabel = this.localization.setFloatLabel;
   }

  ngOnInit() {
    this.InventoryTransferFormGrp = this.fb.group({
      outlets: [],
      searchbyitem: '',
      searchbydesc: '',
      searchbysupplier: '',
      searchbybarcode: '',
      searchbyotherscannercode: '',
      searchbysupplieritem: '',
      purchaseorder: '',
      includeinactiveitems: false,
      includenoninventoryitems: false,
      category: new UntypedFormGroup({}),
      defaultOutlets: [],
    });
    this.functionalities = this.func.getFunctionality();
    if(this.functionalities?.ShowOutletSelectionFieldInInventoryAuditTrailReport)
      this.ShowOutletSelection = true;
    else
      this.ShowOutletSelection = false;
    this.formReady.emit(this.InventoryTransferFormGrp);
    if (this.ShowOutletSelection) { 
      this.IsValidSelection.emit(this.validateOutlets);
    }
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        this.getOutlets();
      }
      if (this.localization.isFromJobScheduler && _parent.edit) {  
        this.isFromEdit = true;
        let patchItem =  this.editPatchValue;
    
        if(patchItem.outlets != undefined){
          this.dataservice.getAllOutletsByUser().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.UserIds.find(x => x == item.id);
            });
            this.outlets = arr;
            this.InventoryTransferFormGrp.controls.defaultOutlets.patchValue(this.outlets);     
          });
        } 

        if(patchItem.includeinactiveitems != undefined){
          this.InventoryTransferFormGrp.controls.includeinactiveitems.patchValue(patchItem.includeinactiveitems);  
        }
        if(patchItem.includenoninventoryitems != undefined){
          this.InventoryTransferFormGrp.controls.includenoninventoryitems.patchValue(patchItem.includenoninventoryitems);  
        }
        if(patchItem.purchaseorder != undefined){
          this.InventoryTransferFormGrp.controls.purchaseorder.patchValue(patchItem.purchaseorder);  
        }
        if(patchItem.searchbybarcode != undefined){
          this.InventoryTransferFormGrp.controls.searchbybarcode.patchValue(patchItem.searchbybarcode);  
        }
        if(patchItem.searchbydesc != undefined){
          this.InventoryTransferFormGrp.controls.searchbydesc.patchValue(patchItem.searchbydesc);  
        }
        if(patchItem.searchbyitem != undefined){
          this.InventoryTransferFormGrp.controls.searchbyitem.patchValue(patchItem.searchbyitem);  
        }
        if(patchItem.searchbyotherscannercode != undefined){
          this.InventoryTransferFormGrp.controls.searchbyotherscannercode.patchValue(patchItem.searchbyotherscannercode);  
        }
        if(patchItem.searchbysupplier != undefined){
          this.InventoryTransferFormGrp.controls.searchbysupplier.patchValue(patchItem.searchbysupplier);  
        }
        if(patchItem.searchbysupplieritem != undefined){
          this.InventoryTransferFormGrp.controls.searchbysupplieritem.patchValue(patchItem.searchbysupplieritem);  
        }
        }
    } 
  }

  async getOutlets() {
    //this.outlets = await this.dataservice.getAllOutlets();
    this.outlets = await this.dataservice.getAllOutletsByUser();
    this.InventoryTransferFormGrp.controls.defaultOutlets.setValue(this.outlets);
  }

  // filterDropDownSelected(event: MatSelectChange, data: DropDownData, allData: DropDownData[], dropDownType) {
  //   if (dropDownType !== this.selectedDropdownType) {
  //     this.valuesSelected = [];
  //   }
  //   if (data && data.description.toLowerCase() === 'all') {
  //     this.valuesSelected = this.business.toggleClickbtn(this.defaultData, allData, this.valuesSelected, this.defaultData);
  //   } else {
  //     this.valuesSelected = this.business.toggleClickbtn(data, allData, this.valuesSelected, this.defaultData);
  //   }
  //   this.InventoryTransferFormGrp.controls[dropDownType].setValue(this.valuesSelected);
  //   this.selectedDropdownType = dropDownType;
  // }

  formInitialized(form: UntypedFormGroup) {
    this.InventoryTransferFormGrp.setControl('category', form);
  }

  getFormControlValue(event) {
    if (event[0] === 'outlets') {
      this.InventoryTransferFormGrp.setControl('outlets', event[1]);
    }
  }

  validateOutlet(event, data: any): void {
    this.validateOutlets.validity = data !== null;
    this.IsValidSelection.emit(this.validateOutlets);
  }
}
