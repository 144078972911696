<div class="duration-ctrl ag_position--relative" [ngClass]="className">
    <form [formGroup]="durationForm" autocomplete="off">
        <mat-label class="ag_position--absolute label">{{label}}</mat-label>
        <div>
            <mat-form-field class="hour" [floatLabel]="floatLabelNever">
                <input matInput [placeholder]="captions.lbl_hour" inputtype="nonnegative,nodecimal" minValue="{{hourMin}}" maxValue="{{hourMax}}" durationFormatter
                    formControlName="hour" [required]="required" (blur)="durationBlur()" [maxLength]="2">
                    
            </mat-form-field> :
            <mat-form-field class="minute" [floatLabel]="floatLabelNever">
                <input matInput [placeholder]="captions.lbl_minute" inputtype="nonnegative,nodecimal" minValue="{{minuteMin}}" maxValue="{{minuteMax}}" durationFormatter
                    formControlName="minute" (blur)="durationBlur()" [maxLength]="2">
            </mat-form-field>
            <mat-error class="ag_position--absolute custom-err" *ngIf="durationForm.get('hour')?.errors?.exceededDuration || durationForm.get('hour')?.errors?.max ||
            durationForm.get('minute')?.errors?.exceededDuration || durationForm.get('minute')?.errors?.max">{{captions.err_maximum_value}}</mat-error>
        </div>
    </form>
</div>
