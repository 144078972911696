<mat-form-field [floatLabel]="floatLabel" [id]="'ag-automation-select-'+formControlName">
  <mat-select [attr.automationId]=automationId [formControl]="selectListControl" [disabled]="_disabled" (selectionChange)='matSelectionChange($event)'
    [multiple]="multiple" [placeholder]="_placeholder" [required]="_required" [attr.LiteralId]="formControlName" (openedChange)="openedChange($event)">
    <mat-select-trigger *ngIf="multiple">
      <span class="ag_display--flex" *ngIf="selectListControl.value?.length != _source?.length">
        <span class="first-option">{{selectListControl.value ? getname(selectListControl.value[0]) : ''}}</span>
        <span *ngIf="selectListControl.value?.length > 1" class="additional-selection-Count ag_pl--1">
          (+{{selectListControl.value.length - 1}} {{localization.captions.lbl_others}})
        </span>
      </span>
      <span *ngIf="selectListControl.value?.length == _source?.length">
        {{localization.captions.lbl_all}}
      </span>
    </mat-select-trigger>
    <div class="dropdown-search">
      <input *ngIf="dropdownWithSearch" placeholder="{{localization.captions.lbl_searchBy}} {{_placeholder}}" [(ngModel)]="inputSearch" [ngModelOptions]="{standalone: true}" (input)='filterOptions($event)' (keydown)="$event.stopPropagation()">
      <i *ngIf="dropdownWithSearch" [ngClass]="inputSearch ? 'icon-close ag_cursor--pointer' : 'icon-search'" (click)="dropDownSearchClear(inputSearch ? 'icon-close' : 'icon-search')"></i>
  </div>    
    <mat-checkbox *ngIf="isAll" class="mat-option all-multiselect w-100" (click)="$event.stopPropagation();"
      [disableRipple]="true" (change)="triggerAll($event)"
      [checked]="Selectedfilteredlistcount  == filteredOptions?.length">
      {{localization.captions.lbl_all}}
    </mat-checkbox>
    <mat-option *ngIf="showEmptyOption"></mat-option>
    <mat-option *ngFor='let option of filteredOptions' [value]="option.id" [disabled]="option.disabled"
    (click)="filterDropDownSelected($event, option, formControlName,matOption.selected)" #matOption>
      <span [matTooltip]="option.viewValue">{{option.viewValue}}</span>
    </mat-option>
  </mat-select>
  <mat-error *ngIf="selectListControl.hasError('required')">
    {{errorMessage}}
  </mat-error>
  <mat-error *ngIf="selectListControl.hasError('invalid')" [attr.LiteralID]="customErrorMessageId">
    {{customErrorMessage}}
  </mat-error>
</mat-form-field>
