import { Component, Inject, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';

import { BookingBehavior, buttonTypes, ClassType, ComponentTypes, PartyState, PartyType, RolesAndPermissionsType, ValidationMessageType } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { ActivityRatePlanRequest, BookingCharges, BookingTypeVariant, ChargeType, CommentType, GuestTypeVariant, RatePlanCalculationInfo, RatePlanVariants } from '@app/shared/models/ActivityRatePlanRequestDTO';
import { PartyPrepaymentState } from '@app/shared/models/InputContact';
import { BookingChargeType } from '@app/shared/models/RestaurantDTO';
import { TimeRangeDTO } from '@app/shared/models/TimeRangeDTO';
import { CurrencyDecimalFormater } from '@app/shared/pipes/currency-decimal.pipe';
import { RolesAndPermissionPipe } from '@app/shared/pipes/RolesAndPermission.pipe';
import { PartyService } from '@app/shared/services/party.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { TranslateService } from '@ngx-translate/core';
import _, { update } from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-rate-plan-summary',
  templateUrl: './rate-plan-summary.component.html',
  styleUrls: ['./rate-plan-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RatePlanSummaryComponent extends Utilities implements OnInit {
  @Input() selectedInfo: any;
  @Input() party;
  value: any;
  totalDepositAmount: number;
  showDepositAmount: boolean;
  subscriptions: Subscription = new Subscription();
  ratePlan: any;
  negotiationCutOff: any;
  taxAmount: any;
  totalServiceCharge: any;
  totalAddOnAmount: any;
  totalAddOnTaxAmount: any;
  uneditableTaxAmount: any;
  totalPayableAmount: any;
  remainingAmount: any;
  negotiationReason: any;
  coverTypes: any;
  bookingTypes: any;
  ratePlanValidationMsg: any;
  isTaxConfigured = false;
  isServiceConfigured = false;
  isRetailEnabledProperty = false;
  negotiationRatesConfig: FieldConfig[];
  recalculateConfig: FieldConfig[];
  totalAmountForBooking = 0;
  partySize: number;
  applyNegotiationBtn:ButtonValue;
  cancelNegotiationBtn:ButtonValue;
  firstCall = true;
  perItemEditable = false;
  rateNegotiateConfig: FieldConfig[];
  ratePlanOriginal:any;
  originalAdjustedMinRate: number = 0.00;
  adjustedMinRate: number = 0.00;
  activitiesAmount: number = 0.00;
  subtotal: number = 0.00;
  serviceCharge: number = 0.00;
  totalServiceChargeWithTax:  number = 0.00;
  totalAddonTaxOnServiceChargeAmount: number = 0.00;
  taxOnServiceCharge: number = 0.00;
  tax: number = 0.00;
  minRateTaxOnServiceChargeAmount:number = 0.00;
  grandTotal: number = 0.00;
  negotiatedDiscount: number = 0.00;
  applyNegotiationClicked: boolean = false;
  grandTotalValidated: boolean = false;
  ignoreNegotiation = false;
  additionalCharges = 0;
  partyData:any;
  prevSalePrice: any = {
    cover: {},
    bookingType: {},
    base: {},
    location: {},
    instructor: {},
    adjustedMinRate: 0,
    membership: {}
  }
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  @ViewChild('negotiateReasonForm') negotiateReasonForm: DynamicFormComponent;
  OperationCurrency: string;
  constructor(public dialog: MatDialog, public permissions: RolesAndPermissionPipe,private currencyDecimalFormater:CurrencyDecimalFormater,private partyService: PartyService, public translateService: TranslateService, public df: DashboardFunctions, private api: ApiService, @Inject(MAT_DIALOG_DATA) public data: any,private popupService:PopupService) {
    super(dialog);
    this.subscriptions.add(this.partyService.cs.settings.subscribe(sett => {
      this.isTaxConfigured = sett.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax)?.length > 0;
      this.isServiceConfigured = sett.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge)?.length > 0;
      this.isRetailEnabledProperty = Utilities.isRetailEnabledProperty(sett.General.RetailIntegrationDTO);
      this.OperationCurrency = this.partyService.cs.operationCurrency;
      this.coverTypes = [...sett.CoverTypes];
      this.bookingTypes = [...sett.BookingTypes];
    }));
  }



  ngOnInit(): void {
    this.partyData = !_.isEmpty(this.partyService.retailservice.oldReservation)? this.partyService.retailservice.oldReservation.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation[0]: null;
    this.setNegotiationConfig();
    this.data =_.cloneDeep(this.data);
    if (this.data?.financialData) {
      this.ratePlan = this.partyService.ratePlanForBooking = this.data.financialData?.RatePlanTotal;
      this.partyService.FinancialEffectId = this.data.financialData?.FinancialEffectId;
    }
    this.perItemEditable = this.permissions.transform(RolesAndPermissionsType?.NegotiateRates)
    if (this.data?.party) {
      this.partySize = this.data?.party.Size;
    }
    this.OperationCurrency = this.partyService.cs.operationCurrency;
    this.getRatePlanAndEnavbleGuest(this.partyService.sessionBookingData?.Slots)

  }
  formChangeSubscribe() {
    this.components.forEach((x, index) => {

      this.subscriptions.add(x.form?.valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(value => {
          this.formChangeHandler(value);
        }));

    })

    if(this.negotiateReasonForm?.form){
      this.subscriptions.add(this.negotiateReasonForm.form.valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(value => {
          this.formChangeHandler(value);
      }));
    }
  }

  formChangeHandler(value){
      this.enableNegotiationOption(value);
      this.addNegotiationReason(value);
      this.applyTaxForNegotiated(value);
      this.negotiateButtonStatus();
  }

  negotiateButtonStatus(){
    this.applyNegotiationBtn.disbaledproperity = this.negotiationReason != '' ? false : true;
}

  ngAfterViewInit(){
    this.formChangeSubscribe();
  }
  setNegotiationConfig() {
    
    this.applyNegotiationBtn = {
      type: buttonTypes.actionPrimarySmall,
      label: 'apply',
      disbaledproperity: true,
      customclass: 'action-bar__search-btn',
    }
    this.cancelNegotiationBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: 'reset',
      disbaledproperity: false,
      customclass: 'action-bar__search-btn',
    }
    this.negotiationRatesConfig = [{
      type: 'switch',
      name: 'negotiation',
      inputType: 'text',
      label: 'NegotiableRates',
      class: 'negotiation-rate',
      checked: false
    }
    ];
    this.recalculateConfig = [{
      type: 'switch',
      name: 'applyafterNegotiation',
      inputType: 'text',
      label: 'Recalculate',
      class: 'recalculate',
      checked: false
    }
    ];
    this.rateNegotiateConfig = [
      {
        name: 'reasonText',
        type: 'textarea',
        label: 'EnterText',
        rows: 2,
        inputType: 'text',
        showHint: true,
        charLength: 400,
        textAreaAppearance: 'outline',
        class: 'reject-options__input',
        value: this.setNegotiationReason(),
        validation:[Validators.required],
        disableErrorStateMatcher:true
      }]
  }

  getRatePlanAndEnavbleGuest(Slots) {
    this.totalDepositAmount = 0;
    this.showDepositAmount = false;
    this.originalAdjustedMinRate = 0.00;
    this.adjustedMinRate = 0.00;
    this.negotiatedDiscount = 0.00;
    this.activitiesAmount = 0.00;
   this.formRatePlanData();
  }
  addNegotiationReason(value){
    if (value[this.rateNegotiateConfig[0].name]) { 
      this.negotiationReason = value[this.rateNegotiateConfig[0].name];     
      this.data.party.Comments = this.negotiationReason;
      // this.partyService.rateNegotiationObject = {       
      //   Reason: value[this.rateNegotiateConfig[0].name].reasonText.find(reason => reason.commentType == CommentType.Negotiation)
      // }
    }
  }

  onBlur(){

  }

  setRatePlanCalculation(Slots,resetData?) {
 //   this.formData();
    var request = {
      Slots: [],
      ActivityId: this.data.party.SpecialMealId,
      GuestTypes:null,
      BookingTypes:null,
      TableIds: null,
      Size: null,
      SessionSlot: {
        Sessions: null,
        SessionGroupId: null
      },
      TrackMembershipNumber: null,
      MemberType: null,
      IsMemberActive: null,
      BookingBehavior: BookingBehavior.ClassOrSession,
      NegotiatedAmount: this.value?.NegotiatedAmount,
      SelectedAddOns: null,
      SeatingTypeIds: null,
      RatePlanTotalNegotiation: this.formData(resetData)
    } as ActivityRatePlanRequest;
    this.totalDepositAmount = 0;
    this.showDepositAmount = false;
    this.originalAdjustedMinRate = 0.00;
    this.adjustedMinRate = 0.00;
    this.negotiatedDiscount = 0.00;
    this.activitiesAmount = 0.00;
     this.subscriptions.add(this.api.GetRatePlanCalculated(request,resetData ? this.data.financialData.FinancialEffectId : (this.partyService.FinancialEffectId || null)).subscribe(response => {
      this.partyService.ratePlanSummaryState = this.grandTotalValidated = true;
      this.ignoreNegotiation = !this.applyNegotiationClicked && this.df.isBookingDataUpdated(request,this.data);
      
      if(this.firstCall){
        this.ratePlanOriginal = response.Payload;
      }
      this.partyService.ratePlanForBooking =this.ratePlan = response.Payload;
      this.ratePlan = response.Payload;
      this.formRatePlanData(resetData);
     }));


    // }));
  }

  formRatePlanData(resetData?){  

      if (this.ratePlan && this.ratePlan.RatePlanCalculations) {
        this.partyService.FinancialEffectId =  !resetData ? this.ratePlan.FinancialEffectId ? this.ratePlan.FinancialEffectId : this.data.financialData?.FinancialEffectId: this.data.financialData.FinancialEffectId;
        this.negotiationCutOff = this.ratePlan.TotalRatePlan;
        this.taxAmount = this.ratePlan.TotalTaxAmount;
        this.totalServiceCharge = _.cloneDeep(this.ratePlan.TotalServiceChargeAmount);
        this.totalServiceChargeWithTax = this.ratePlan.TotalTaxOnServiceChargeAmount;
        this.totalAddonTaxOnServiceChargeAmount = this.ratePlan.TotalAddonTaxOnServiceChargeAmount;
        this.minRateTaxOnServiceChargeAmount = this.ratePlan.MinRateAdjustment?.TaxOnServiceChargeAmount || 0;
        this.totalAddOnAmount = this.ratePlan.TotalAddonAmount;
        this.totalAddOnTaxAmount = this.ratePlan.TotalAddonTaxAmount;
        // let uneditableSlotRatePlan = this.getUneditableSlotsRatePlan();
        // if (uneditableSlotRatePlan && uneditableSlotRatePlan.length) {
        //   this.ratePlan.TotalRatePlan = this.ratePlan.TotalRatePlan + uneditableSlotRatePlan.map(r => r.TotalAmount).reduce((sum, current) => sum + current, 0);
        //   this.uneditableTaxAmount = uneditableSlotRatePlan.map(r => r.TaxAmount).reduce((sum, current) => sum + current, 0)
        //   this.taxAmount += this.uneditableTaxAmount;
        //   this.ratePlan.TotalTaxAmount = this.ratePlan.TotalTaxAmount + this.uneditableTaxAmount;
        //   this.negotiationCutOff += uneditableSlotRatePlan.filter(r => r.sessionNegotiation < 0).map(r => r.sessionNegotiation).reduce((sum, current) => sum + Math.abs(current), 0)
        //   uneditableSlotRatePlan = _.orderBy(uneditableSlotRatePlan, s => s.TimeRange.Start);
        //   this.ratePlan.RatePlanCalculations = [...uneditableSlotRatePlan, ...this.ratePlan.RatePlanCalculations];
        // }
        if (this.data && this.firstCall) {
          this.totalPayableAmount = this.data.party && (this.data.party.NegotiatedAmount || this.data.party.NegotiatedAmount == 0) ? this.ratePlan.TotalRatePlan + this.data.party.NegotiatedAmount
            : (this.data.NegotiatedAmount) ? this.ratePlan.TotalRatePlan + this.data.NegotiatedAmount : this.ratePlan.TotalRatePlan;
          this.remainingAmount = this.data.party && this.data.party.NegotiatedAmount ? this.data.party.NegotiatedAmount : this.ratePlan.OverAllNegotiation;
          this.negotiationReason = this.data ? this.df.negotiationReason(this.data?.party) : '';
          let ratePlanDetails = this.data && this.data.bookingRatePlan ? this.data.bookingRatePlan.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation : this.ratePlan.RatePlanCalculations; 
          this.ratePlan.RatePlanCalculations.forEach(item =>{
            if(item.AddonAttemptDetails?.length){
              let addOnItem = ratePlanDetails.find(data => data.Id == item.Id)?.AddonAttemptDetails;
              if(addOnItem)
              {
              addOnItem.forEach(element => {
                item.AddonAttemptDetails.filter(addOn => addOn.AddonId == element.AddonId).map(data =>{
                  data.NegotiatedAmount = element.NegotiatedAmount || 0;
                })
              });
            }
            }
           
          })
          
                 }
        else {
          this.totalPayableAmount = this.data.party?.NegotiatedAmount ? this.ratePlan.TotalRatePlan + this.data.party.NegotiatedAmount : this.ratePlan.TotalRatePlan;
          this.remainingAmount = this.data.party?.NegotiatedAmount ? this.data.party.NegotiatedAmount : this.ratePlan.OverAllNegotiation;
          this.negotiationReason = this.data ? this.df.negotiationReason(this.data?.party) : '';

        }
        if (this.ratePlan.TotalDepositAmount > 0 || this.data?.DepositPaid) {
          if (!this.data || !this.data.Id || this.data.PrepaymentState == PartyPrepaymentState.DepositCollected) {
            this.totalDepositAmount = this.data?.DepositPaid ? this.data.DepositPaid : this.ratePlan.TotalDepositAmount;
            this.showDepositAmount = true;
          }
        }
        let dataForSlotRatePlanCalc = {
          applyNegotiationClicked: this.applyNegotiationClicked,
          ignoreNegotiation: this.ignoreNegotiation,
          prevSalePrice: this.prevSalePrice
        }

        let calculatedRatePlan = this.df.formRatePlanSlotValue(this.ratePlan,this.partySize,this.data,this.firstCall,dataForSlotRatePlanCalc,this.partyData,true);
        this.ratePlan.SlotList = calculatedRatePlan.ratePlan;
        this.additionalCharges = calculatedRatePlan.additionalCharge;
        this.activitiesAmount = calculatedRatePlan.activitiesAmount;

        this.negotiatedDiscount = calculatedRatePlan.negotiatedDiscount;
        this.adjustedMinRate = calculatedRatePlan.adjustedMinRate;
      
      } else {
    //    this.ratePlan.SlotList = Slots;
      }
      this.ratePlan.MinRateAdjustment.Amount = this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount + (this.ratePlan.MinRateAdjustment.NegotiatedAmount || 0);
      this.originalAdjustedMinRate = this.adjustedMinRate;
      this.partyService.ratePlan$.next(this.ratePlan);
      this.totalAmountForBooking = (_.sumBy(this.ratePlan.SlotList, 'totalAddOnAmount') +_.sumBy(this.ratePlan.SlotList, 'totalAmount'));
      this.calculatePaymentDetails();
  }

  formData(resetData){
    let partyObject =  {
      BookingSize: this.partySize
    }
    if(this.partyService.ratePlanForBooking){
      this.partyService.getNegotiatedValueForBooking(partyObject);
    return  {
      Negotiation: null,
      MinRateNegotiatedAdjustment: this.ratePlan.MinRateAdjustment.Amount - this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount,
      applyNegotiationOnOtherCharges:[
        {             
          bookingChargeType : BookingChargeType.ServiceCharge,
          TotalAmount: this.partyService.ratePlanForBooking.TotalServiceCharge,
          NegotiatedAmount: null,
          UseAfterNegotiation:   this.partyService.negotiateServiceCharge
        }
      ],
      RatePlanCalculationInfoNegotiation: this.partyService.ratePlanForBooking?.RatePlanCalculations && !resetData ? this.partyService.ratePlanForBooking?.RatePlanCalculations : null
    }
  }
    else{
      return null;
    }
  }

  calculatePaymentDetails(){
    // this.activitiesAmount = this.ratePlan.TotalRatePlan + this.ratePlan.TotalAddonAmount + this.adjustedMinRate;
   // this.activitiesAmount = this.activitiesAmount - this.slotItemDiscount
    // this.subtotal = this.activitiesAmount - this.negotiatedDiscount;
    this.onNegotiate(true);
    this.serviceCharge = (this.totalServiceCharge || 0) + (this.ratePlan.TotalAddonServiceChargeAmount || 0) + (this.ratePlan.MinRateAdjustment?.ServiceChargeAmount || 0);
    this.tax = (this.taxAmount || 0) + (this.totalAddOnTaxAmount || 0) + (this.ratePlan.MinRateAdjustment?.TaxAmount || 0);
    this.taxOnServiceCharge = (this.totalServiceChargeWithTax || 0) + (this.totalAddonTaxOnServiceChargeAmount || 0) + (this.minRateTaxOnServiceChargeAmount || 0);
    this.grandTotal = this.subtotal + this.serviceCharge + this.tax + this.additionalCharges + this.taxOnServiceCharge;
    let guestSelected = this.partyService.reservationFormGroup.controls.selectedGuest.value;
    //this.changeFormState(guestSelected);
  }

  onNegotiate(forGrandTotal?){
    this.partyService.ratePlanSummaryState =  this.grandTotalValidated = forGrandTotal || false;
    this.popupService.restrictCloseDialog  = !this.partyService.ratePlanSummaryState  ? true : false;
 //   this.changeFormState(false);
    this.ratePlan.SlotList.forEach(slot => this.negotiateTotalSessionCharge(slot));
    this.subtotal = +this.ratePlan.SlotList.reduce((res , slot) => res + slot.negotiatedTotalSessionCharge, 0) + (this.ratePlan?.MinRateAdjustment?.Amount || 0);
    this.negotiatedDiscount = this.activitiesAmount - this.subtotal;
  }

  negotiateTotalSessionCharge(updatedSlot?){
    //+ updatedSlot.RatePlanVariants.TotalBaseRate
        if(updatedSlot){
          let baseRateValue = 0;
          let negotiatedTotalCoverValue = updatedSlot.coverTypeVariants && updatedSlot.coverTypeVariants.length ? updatedSlot.coverTypeVariants.reduce((res, cover) => res + ((cover.coverTypeValue) * cover.covers), 0): 0;
          let negotiatedTotalBookingTypeValue = updatedSlot.bookingTypeVariants && updatedSlot.bookingTypeVariants.length ? updatedSlot.bookingTypeVariants.reduce((res, bookingType) => res + ((bookingType.bookingTypeValue) * bookingType.bookingTypes), 0): 0;
        //  if(!updatedSlot.coverTypeVariants?.length && !updatedSlot.bookingTypeVariants?.length){
            baseRateValue = updatedSlot.sessionCharge;
          //}
          let negotiatedTotalAddonValue = updatedSlot.AddonAttemptDetails.reduce((res, addOn) => res + (addOn.perItemValue * addOn.Quantity), 0);
          let totalLocationVariant = +(updatedSlot.RatePlanVariants ? updatedSlot.RatePlanVariants.locationAmount : 0);
          let totalInstructorVariant = +(updatedSlot.RatePlanVariants ? updatedSlot.RatePlanVariants.instructorAmount : 0);
          let totalMembershipVariant = updatedSlot.membershipAmount || 0;
          updatedSlot.negotiatedTotalSessionCharge = +(totalLocationVariant + totalInstructorVariant + negotiatedTotalCoverValue + negotiatedTotalBookingTypeValue + negotiatedTotalAddonValue + baseRateValue + totalMembershipVariant);
    
        }
      }
  getUneditableSlotsRatePlan(): any[] { // to get the amount of past and confirmed Sessions
    let ratePlanCalculations = [];
    if (this.value && this.value.BookedSessions && this.value.BookedSessions.length) {
      //var neededSessions = this.value.BookedSessions.filter(s=>s.SessionState != PartyState.Cancelled && s.SessionState != PartyState.CancellationInprogress);
      this.value.BookedSessions.forEach(session => {
        let sessionCurrentState = session.SessionState == PartyState.Seated ? this.translateService.instant('checkedin') : session.SessionState == PartyState.Left ? this.translateService.instant('checkedout') : this.isPendingActiveSession(session) ? this.translateService.instant('expired') : this.isStandByConfirmedSession(this.value, session) ? this.translateService.instant('confirmed') : ''
        //if(session.SessionState == PartyState.Cancelled || session.SessionState == PartyState.CancellationInprogress || session.SessionState == PartyState.Seated || session.SessionState == PartyState.Left || this.isPendingActiveSession(session) || this.isStandByConfirmedSession(this.value, session)){
        if (sessionCurrentState !== '') {
          if (session.Charges && session.RatePlanAmount) {
            let ratePlan = this.getRatePlan(session);
            ratePlan.sessionState = sessionCurrentState;
            ratePlan.size = session.BookingSize;
            ratePlan.sessionNegotiation = session.NegotiatedAmount;
            ratePlan.TaxAmount = session.TaxAmount;
            ratePlan ? ratePlanCalculations.push(ratePlan) : '';
          }
        }
      });
    }
    return ratePlanCalculations;
  }

  isStandByConfirmedSession(party, session) {
    return session.SessionType == PartyType.Reservation && party.Type == PartyType.StandBy && session.SessionState == PartyState.Pending;
  }

  isPendingActiveSession(session) {
    let propertyCurrentDate = new Date(new Date(Utilities.getRestaurantDateTime(this.partyService.cs.settings.value.General.DaylightDelta)).setHours(0, 0, 0, 0));
    return new Date(session.BookedDate) < propertyCurrentDate && session.SessionState == PartyState.Pending;
  }

  getRatePlan(session): any {
    let activitySession = this.partyService.cs.settings.value.SpecialMeals.filter(activity => activity.Id == this.value.SpecialMealId)[0]?.ActivitySessions.filter(s => s.ActivitySessionId == session.ActivitySessionId)[0];
    if (activitySession) {
      let bookedDate = session.BookedDate;
      let sessionStartTime = session.BookedDate.split('T')[0] + 'T' + activitySession.StartTime;
      let sessionEndTime = session.BookedDate.split('T')[0] + 'T' + activitySession.EndTime;
      let timeRange = new TimeRangeDTO();
      timeRange.Start = new Date(sessionStartTime);
      timeRange.End = new Date(sessionEndTime);
      //let timeRange = {Start: sessionStartTime, End : sessionEndTime};
      let bookingCharges = _.groupBy(JSON.parse(session.Charges) as BookingCharges[], c => c.ChargeType);
      let ratePlanCalculation = new RatePlanCalculationInfo();
      ratePlanCalculation.RatePlanVariants = new RatePlanVariants();
      Object.keys(bookingCharges).forEach((chargeType) => {
        switch (Number(chargeType) as ChargeType) {
          case ChargeType.BasePrice:
            ratePlanCalculation.RatePlanVariants.TotalBaseRate = bookingCharges[chargeType].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
            break;
          case ChargeType.Location:
            ratePlanCalculation.RatePlanVariants.LocationVariant = bookingCharges[chargeType].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
            break;
          case ChargeType.Staff:
            ratePlanCalculation.RatePlanVariants.InstructorVariant = bookingCharges[chargeType].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
            break;
          case ChargeType.Membership:
            ratePlanCalculation.MemberShipVariant = bookingCharges[chargeType].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
            break;
          case ChargeType.CoverType:
            let guestTypeVariantList: GuestTypeVariant[] = [];
            let coverTypes = _.groupBy(bookingCharges[chargeType], c => c.EntityId);
            Object.keys(coverTypes).forEach(covertype => {
              //var coverSize = this.value.CoverTypeQuantities.filter(c=>c.CoverTypeId == covertype)[0].Covers;
              var guestTypeVariant = new GuestTypeVariant();
              guestTypeVariant.CoverTypeId = Number(covertype);
              guestTypeVariant.covers = coverTypes[covertype][0].Size;
              guestTypeVariant.Variant = coverTypes[covertype].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
              guestTypeVariantList.push(guestTypeVariant);
            });
            ratePlanCalculation.RatePlanVariants.GuestTypeVariant = guestTypeVariantList;
            break;
          case ChargeType.BookingType:
            let bookingTypeVariantList: BookingTypeVariant[] = [];
            let bookingTypes = _.groupBy(bookingCharges[chargeType], c => c.EntityId);
            Object.keys(bookingTypes).forEach(bookingType => {
              //var coverSize = this.value.BookingTypeQuantities.filter(c=>c.BookingTypeId == bookingType)[0].BookingTypes;
              let bookingTypeVariant = new BookingTypeVariant();
              bookingTypeVariant.bookingTypes = bookingTypes[bookingType][0].Size;
              bookingTypeVariant.BookingTypeId = Number(bookingType);
              bookingTypeVariant.Variant = bookingTypes[bookingType].map(c => Number(c.Price)).reduce((sum, value) => sum + value, 0);
              bookingTypeVariantList.push(bookingTypeVariant);
            });
            ratePlanCalculation.RatePlanVariants.BookingTypeVariant = bookingTypeVariantList;
            break;
        }
      });
      ratePlanCalculation.RatePlanVariants.TotalVariants = (ratePlanCalculation.RatePlanVariants.LocationVariant ? ratePlanCalculation.RatePlanVariants.LocationVariant : 0)
        + (ratePlanCalculation.RatePlanVariants.InstructorVariant ? ratePlanCalculation.RatePlanVariants.InstructorVariant : 0)
        + (ratePlanCalculation.RatePlanVariants.GuestTypeVariant ? ratePlanCalculation.RatePlanVariants.GuestTypeVariant.map(v => (v.Variant ? v.Variant : 0)).reduce((sum, value) => sum + value, 0) : 0)
        + (ratePlanCalculation.RatePlanVariants.BookingTypeVariant ? ratePlanCalculation.RatePlanVariants.BookingTypeVariant.map(v => (v.Variant ? v.Variant : 0)).reduce((sum, value) => sum + value, 0) : 0);
      ratePlanCalculation.TotalAmount = (ratePlanCalculation.RatePlanVariants.TotalBaseRate ? ratePlanCalculation.RatePlanVariants.TotalBaseRate : 0) + ratePlanCalculation.RatePlanVariants.TotalVariants;
      ratePlanCalculation.TimeRange = timeRange;
      return ratePlanCalculation;
    }
  }
  
  applyNegotiation(){
    this.firstCall = false
    this.popupService.restrictCloseDialog = false;
    this.setRatePlanCalculation(null);
  }

  setNegotiationReason(){
    let ratePlanNegotiateReason = '';
    if(this.data && this.data.party?.Comments){
      try{
        let commentsObj = JSON.parse(this.data.party.Comments);
        if(commentsObj){
          ratePlanNegotiateReason = commentsObj.find(comment => comment.commentType == CommentType.Negotiation)?.Comment || '';
        }
      }catch(error){
        ratePlanNegotiateReason = this.data.party.Comments || '';
      }
    }
    return ratePlanNegotiateReason;
  }

  cancelNegotiation(){
    this.totalServiceCharge = _.cloneDeep(this.ratePlan.TotalServiceChargeAmount);
    this.partyService.negotiateServiceCharge = false; 
    this.df.enabledNegotiation = false;    
    this.negotiationReason = '';
    this.rateNegotiateConfig[0].value = '';
    this.negotiateReasonForm.form.reset();
    if(this.data && this.data.bookingRatePlan?.Comments){
      this.negotiateReasonForm.form.controls?.reasonText?.setValue(this.setNegotiationReason());
    }
    else{
      this.negotiateReasonForm.form.controls?.reasonText?.setValue('');
    }
    //this.perItemEditable = false;
  //  this.negotiationConfigForm.form.controls.negotiation.setValue(false);
    this.setRatePlanCalculation(null,true);
  }


  calculateAmount(slot){
    if(slot && slot.AddonAttemptDetails && slot.AddonAttemptDetails.length){
      slot.totalAddOnAmount =  _.sumBy(slot.AddonAttemptDetails,'perItemValue');
     }  
     if(slot){
      slot.totalAmount =  (slot.coverTypeVariants && slot.coverTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['covers'] * o['coverTypeValue'] })  : slot.bookingTypeVariants && slot.bookingTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['bookingTypes'] * o['bookingTypeValue'] }) : (slot.totalBaseRate || 0))  ;
  
    }
    this.totalAmountForBooking = (_.sumBy(this.ratePlan.SlotList, 'totalAddOnAmount') +_.sumBy(this.ratePlan.SlotList, 'totalAmount'));
  }
  validateCharacters(evt){
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57)
    {
        evt.preventDefault();
    }
  }

  calculateValue(slot){
    // if(!this.firstCall){
    // this.partyService.ratePlanForNegotiation =_.cloneDeep(this.partyService.ratePlanForBooking);
    // }
      this.calculateAmount(slot);
  }

  enableNegotiationOption(value) {
    if (value[this.negotiationRatesConfig[0].name]) {
      this.perItemEditable = true;
      this.df.enabledNegotiation = true;
      
    }
    else if(value[this.negotiationRatesConfig[0].name] == false){
      this.perItemEditable = false;
      this.df.enabledNegotiation = false;
      this.editNegotiationAmount();
    }

  }
  applyTaxForNegotiated(value){
    if (value[this.recalculateConfig[0].name]) {
  //    this.calculateNegotiableRate();     
        this.partyService.negotiateServiceCharge = true; 
    }
    else if (value[this.recalculateConfig[0].name] == false){
      this.totalServiceCharge = _.cloneDeep(this.ratePlan.TotalServiceChargeAmount);
        this.partyService.negotiateServiceCharge = false; 
    }
   
  }
  editNegotiationAmount() {
    this.ratePlan = this.partyService.ratePlanForBooking = _.cloneDeep(this.ratePlanOriginal);
    this.ratePlan.SlotList = this.ratePlan?.RatePlanCalculations.map(slot => {
      slot.coverTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.GuestTypeVariant ? slot.RatePlanVariants.GuestTypeVariant.map(coverVariant => { 
              coverVariant.coverTypeValue =  _.cloneDeep(coverVariant.initialValue) 

         return coverVariant;
       }).filter(coverVariant => coverVariant.covers > 0) : [];
       
       slot.bookingTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.BookingTypeVariant ? slot.RatePlanVariants.BookingTypeVariant.map(bookingVariant => {
        bookingVariant.bookingTypeValue =  _.cloneDeep(bookingVariant.initialValue) 

        return bookingVariant;
         
       }).filter(bookingVariant => bookingVariant.bookingTypes > 0) : [];

       slot.AddonAttemptDetails = slot.AddonAttemptDetails ? slot.AddonAttemptDetails.map(addOnattempt => {
             addOnattempt.perItemValue =  _.cloneDeep(addOnattempt.TotalAmount)
         return addOnattempt;
       }) :[]
       slot.totalBaseRate = (slot.RatePlanVariants.TotalBaseRate + slot.RatePlanVariants.TotalVariants + slot.RatePlanVariants.TotalNegotiation )/ this.partySize;
       slot.totalAmount = (slot.RatePlanVariants.TotalBaseRate + slot.RatePlanVariants.TotalVariants + slot.RatePlanVariants.TotalNegotiation );
       if(slot && slot.AddonAttemptDetails && slot.AddonAttemptDetails.length){
        slot.totalAddOnAmount =  _.sumBy(slot.AddonAttemptDetails,'perItemValue');
       }  
       if(slot){
        slot.totalAmount =  (slot.coverTypeVariants && slot.coverTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['covers'] * o['coverTypeValue'] })  : slot.bookingTypeVariants && slot.bookingTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['bookingTypes'] * o['bookingTypeValue'] }) : (slot.totalBaseRate || 0))  ;    
      }
       return slot;
     });
  }

  ngOnDestroy(){
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.partyService.ratePlanForBooking = null;
    this.df.enabledNegotiation = false;
      this.partyService.negotiateServiceCharge = false;
  }

}
