import { Component, EventEmitter, Host, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { CoverReportsComponent } from '@app/settings/covereports/coverreports-component';
import { StandbyListComponent } from '@components/standby-list/standby-list.component';
import { WaitTimeReportComponent } from '@components/waittime-report/waittime-report-component';
import { AuditableEntityType, buttonTypes, ComponentTypes, LessonType, Menu, PartyState, PartyType, ReservationStatus, ReservationType, SlottingMode, SlotType } from '@constants/commonenums';
import { popupDialogDimension, controlSettings } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';
import { CancelorNoShowActionPipe } from '@pipes/Allow-CancelorNoShowAction.pipe';
import { EditOrSeatActionPipe } from '@pipes/Allow-EditorSeatAction.pipe';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { FacadeService } from '@services/facade.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { sum } from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { SlotsSectionService } from '../tablelist/slots/slots-section.service';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { AuditlogComponent } from '../auditlog/auditlog.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { PopupService } from '@app/popup-module/popup.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [SlotsSectionService],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent extends Utilities implements OnInit, OnChanges, OnDestroy {
  @Input() type: ReservationType;
  @Input() dashboardData: any;
  @Output() partyUnselectedEvent = new EventEmitter<any>();
  @Output() createReservation: EventEmitter<any> = new EventEmitter<any>();
  @Output() actions: EventEmitter<any> = new EventEmitter<any>();
  @Input() inputData: any;
  @Output() tabChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() tableTabsChangeEvent = new EventEmitter<any>(); 
  @Output() selectedCommunalParty = new EventEmitter<any>();
  @Input() fromTables:any;
  showDashBoardStatusCounts = false;
  messagesData: any = [];
  ReservationType = ReservationType;
  dashboardStatus = [];
  subscriptions: Subscription = new Subscription();
  coverReportBtn: ButtonValue;
  waitTimesReportBtn: ButtonValue;
  CoverReportDialog: MatDialogRef<CustomPopupComponent>;
  standbyBtn: ButtonValue;
  standbyDialog: MatDialogRef<CustomPopupComponent>;
  showStandbyCount: boolean = false;
  showCoverReportDiv: boolean;
  Menu = Menu;
  showServerLoad: boolean;
  isSlotsTab: boolean = false;
  _MESSAGES = MESSAGES;
  enableSlotsBtn: ButtonValue;
  disableSlotsBtn: ButtonValue;
  isFullScreen: boolean = false; 

  constructor(public _as: AppService, public _ps: PartyService, public _fs: FacadeService, private ts: TranslateService, public cs: CacheService, public popupService: PopupService,
    public allowPartyToSeat: EditOrSeatActionPipe, public allowPartyToCancel: CancelorNoShowActionPipe,@Host() private slotSection: SlotsSectionService, public dialog: MatDialog) {
    super(dialog);
  }

  ngOnInit() {
    this.subscriptions.add(this._ps.selectedParty$.subscribe(party => {
      this.setDashboardActionButtons();
    }));

    this.subscriptions.add(this._ps.Parties$.subscribe(parties => {
      if (parties) {
        if (this._ps.selectedParty$.value) {
          if (parties.find(p => p.Id == this._ps.selectedParty$.value.Id)) {
            this.setDashboardActionButtons();
          } else if(!this.fromTables) {
            this._ps.selectedParty$.next(null);
          }
        }
      }
      this.getDashBoardPartyStatusCounts();
    }));
    if (this._as.selectedMenuId == Menu.Reservation || this._as.selectedMenuId == Menu.WaitList || this._as.selectedMenuId == Menu.Tables) {
      this.coverReportBtn = {
        type: buttonTypes.actionPrimary,
        label: 'coverReportMenu',
        icon: 'icon-Cover-reportwhite',
        customclass: 'dashboard__coverreportAction'
      };
    }
    if (this._as.selectedMenuId == Menu.WaitList) {
      this.waitTimesReportBtn = {
        type: buttonTypes.actionPrimary,
        label: 'waittimestext',
        icon: 'icon-Cover-reportwhite',
        customclass: 'dashboard__waittimereportAction'
      };
    }

    if (this._as.selectedMenuId == Menu.Reservation || this._as.selectedMenuId == Menu.Tables) {
      this.standbyBtn = {
        type: buttonTypes.actionPrimary,
        label: 'standbyListMenu',
        icon: 'icon-ic_update_24px',
        customclass: 'dashboard__standbyAction'
      };
      this.showStandbyCount = true;
    }
    this.showCoverReportDiv = (this._as.selectedMenuId == Menu.Reservation || this._as.selectedMenuId == Menu.WaitList || this._as.selectedMenuId == Menu.Tables)
    this.showServerLoad = Utilities.controlValidate(controlSettings.Tables_SererLoad_Show , this._as.PropertyType)
    this.enableSlotsBtn = {
      type: buttonTypes.actionPrimarySmall,
      label: this._MESSAGES.CONFIG.enable,
      customclass: this._MESSAGES.LABELS['slots-view__enable-btn']
    };
    this.disableSlotsBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: this._MESSAGES.CONFIG.disable,
      customclass: this._MESSAGES.LABELS['slots-view__disable-btn']
    };
  }

  ngAfterViewInit() : void{ 
    this.subscriptions.add(this.slotSection.slotsActions$.subscribe(data => {
      if(data?.selectedSlots > 0){
        this.enableSlotsBtn.label = this.ts.instant(this._MESSAGES.CONFIG.enable) + `(${data?.selectedSlots})`;
        this.disableSlotsBtn.label = this.ts.instant(this._MESSAGES.CONFIG.disable) +`(${data?.selectedSlots})`;
      }
      else {
        this.enableSlotsBtn.label = this._MESSAGES.CONFIG.enable;
        this.disableSlotsBtn.label = this._MESSAGES.CONFIG.disable;
      }
    }))
  }
 
  ngOnChanges() {
    if (this.dashboardData) {
      this.setTimerValues(this.dashboardData.partyInfo)
      this.setDashboardActionButtons();
      this.messagesData = { party: this.dashboardData.partyInfo, messages: this.dashboardData.messages };
     this.dashboardData.fromTables = this.fromTables;
      this.dashboardData = { ...this.dashboardData };
     
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  setDashboardActionButtons() {
    if (!this._ps.selectedParty$.value) {
      switch (this._as.selectedMenuId) {
        case Menu.Reservation:
          this.dashboardData.actions = [{
            type: buttonTypes.actionPrimary, label: 'reservationCreateButtonText', icon: 'icon-open-book-2',
            actionName: ReservationStatus.Reservation, customclass: 'action-btn'
          }];
          break;
        case Menu.WaitList:
          this.dashboardData.actions = [{
            type: buttonTypes.actionPrimary, label: 'addWaitlistButton', icon: 'icon-ic_update_24px',
            actionName: ReservationStatus.Waitlist, customclass: 'action-btn'
          }];
          break;
      }
      return;
    }
    if (this.type !== ReservationType.TablesFullView && (this._ps.selectedParty$.value.State || this._ps.selectedParty$.value.State === 0)) {
      switch (this._ps.selectedParty$.value.State.toString()) {
        case 'arrived':
          this.dashboardData.actions = [{
            type: buttonTypes.actionPrimary, label: 'arrivedState', actionName: ReservationStatus.Arrived,
            customclass: 'action-btn'
          }];
          break;
        case '0':
          let CheckisServerAvailable = false;
          if (this._as.selectedMenuId == Menu.Tables) {
            if (!this._ps.selectedParty$.value.ServerId && this._ps.selectedParty$.value.State != PartyState.Seated)
              CheckisServerAvailable = true;
          }
          let _allowSeat = this.allowPartyToSeat.transform(this._ps.selectedParty$.value.ReservedFor, this._ps.selectedParty$.value.State);
          let _allowCancel = this.allowPartyToCancel.transform(this._ps.selectedParty$.value.ReservedFor, this._ps.selectedParty$.value.State);
          if (!this._ps.selectedParty$.value.IsCommunal && !CheckisServerAvailable) {
            if ((this._ps.selectedParty$.value.Type == PartyType.WalkIn && !this._ps.isPastReservation) || (this._ps.selectedParty$.value.Type == PartyType.Reservation && _allowSeat && _allowCancel)) {
              this.dashboardData.actions = [{
                type: buttonTypes.actionSecondary, label: 'cancel', actionName: ReservationStatus.Cancel,
                customclass: 'action-btn'
              },
              {
                type: buttonTypes.actionPrimary, label: 'seatpartybuttontext',
                actionName: ReservationStatus.SeatParty, customclass: 'action-btn'
              }];
            }
            else if (this._ps.selectedParty$.value.Type == PartyType.Reservation && !_allowSeat && _allowCancel) {
              this.dashboardData.actions = [{
                type: buttonTypes.actionPrimary, label: 'cancel', actionName: ReservationStatus.Cancel,
                customclass: 'action-btn'
              }]
            }
            else if (this._ps.selectedParty$.value.Type == PartyType.Reservation && _allowSeat && !_allowCancel) {
              this.dashboardData.actions = [{
                type: buttonTypes.actionPrimary, label: 'seatpartybuttontext',
                actionName: ReservationStatus.SeatParty, customclass: 'action-btn'
              }]
            }
          } else {
            if (this._as.selectedMenuId != Menu.Tables)
              this.dashboardData.actions = [];
          }
          break;
        case '1':   
          if (this._ps.selectedParty$.value.Contact.FirstName != "Multiple Parties") {
            this.dashboardData.actions = [
              {
                type: buttonTypes.actionSecondary, label: 'cleartablebuttontext',
                actionName: ReservationStatus.ClearTable, customclass: 'action-btn'
              }];
            if (this._ps.selectedParty$.value.Contact.FirstName || this._ps.selectedParty$.value.Contact.LastName) {
              this.dashboardData.actions.push({
                type: buttonTypes.actionPrimary, label: 'releasetablebuttontext',
                actionName: ReservationStatus.ReleaseTable, customclass: 'action-btn'
              })
            }
            else {
              this.dashboardData.actions.push({
                type: buttonTypes.actionPrimary, label: 'deleteText',
                actionName: ReservationStatus.DeleteParty, customclass: 'action-btn'
              });
            }
          
        }
          break;
        case '2':
          this.dashboardData.actions = [];
          break;
        case '3':
          this.dashboardData.actions = [{
            type: buttonTypes.actionPrimary, label: 'undoNoShowButtonText',
            actionName: ReservationStatus.UndoNoShow, customclass: 'action-btn'
          }];
          break;
        case '4':
          // this.dashboardData.actions = [{
          //   type: buttonTypes.actionPrimary, label: 'reopenbuttontext',
          //   actionName: ReservationStatus.Reopen, customclass: 'action-btn'
          // }];
          this.dashboardData.actions = [];
          break;
        case 'non operating hours':
          status = 'non-operating';
          break;
      }
    }
  }

  getDashBoardPartyStatusCounts() {
    const restaurantDateTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    switch (this._as.selectedMenuId) {
      case Menu.Reservation:
        if((moment(restaurantDateTime).format('YYYY-MM-DD')) == (moment(this.cs.headerDate).format('YYYY-MM-DD'))) {
          this.dashboardStatus = [{ Name: 'todayReservations', Count: this._ps.Parties$.value.length ? this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && (moment(p.SeatingTime).format('YYYY-MM-DD')) === (moment(this.cs.headerDate).format('YYYY-MM-DD'))).length : 0 },
            { Name: 'coversForToday', Count: this._ps.Parties$.value.length ? sum(this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State != PartyState.NoShowed && p.State !== PartyState.Cancelled && p.State != PartyState.Left && p.State !== PartyState.Seated && (moment(p.SeatingTime).format('YYYY-MM-DD')) === (moment(this.cs.headerDate).format('YYYY-MM-DD'))).map(p=>p.Size)) : 0 },
            { Name: 'activeReservations', Count: this._ps.Parties$.value.length ? this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State == PartyState.Pending).length: 0 },
            { Name: 'cancelledReservation', Count: this._ps.Parties$.value.length ? this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State == PartyState.Cancelled).length : 0 },
            { Name: 'noShowReservation', Count: this._ps.Parties$.value.length ? this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State == PartyState.NoShowed).length : 0 }];    
            if(this._ps.Parties$.value.length && sum(this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State == PartyState.Seated && (moment(p.SeatingTime).format('YYYY-MM-DD')) != (moment(this.cs.headerDate).format('YYYY-MM-DD'))).map(p=>p.Size)) > 0) {
              this.dashboardStatus.push({ Name: 'pastSeatedCoverstobeCleared', Count: this._ps.Parties$.value.length ? sum(this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State == PartyState.Seated && (moment(p.SeatingTime).format('YYYY-MM-DD')) != (moment(this.cs.headerDate).format('YYYY-MM-DD'))).map(p=>p.Size)) : 0 })
            }   
        }
        else {
          this.dashboardStatus = [{ Name: 'todayReservations', Count: this._ps.Parties$.value.length ? this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State !== PartyState.Seated).length : 0 },
            { Name: 'coversForToday', Count: this._ps.Parties$.value.length ? sum(this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State !== PartyState.Cancelled && p.State !== PartyState.NoShowed && p.State !== PartyState.Seated).map(p=>p.Size)) : 0 },
            { Name: 'activeReservations', Count: this._ps.Parties$.value.length ? this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State == PartyState.Pending).length: 0 },
            { Name: 'cancelledReservation', Count: this._ps.Parties$.value.length ? this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State == PartyState.Cancelled).length : 0 },
            { Name: 'noShowReservation', Count: this._ps.Parties$.value.length ? this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State == PartyState.NoShowed).length : 0 }];
            if(this._ps.Parties$.value.length && sum(this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && (moment(p.SeatingTime).format('YYYY-MM-DD')) != (moment(this.cs.headerDate).format('YYYY-MM-DD'))).map(p=>p.Size)) > 0) {
              this.dashboardStatus.push({ Name: 'pastSeatedCoverstobeCleared', Count: this._ps.Parties$.value.length ? sum(this._ps.Parties$.value.filter(p=>p.Type == PartyType.Reservation && p.State == PartyState.Seated && (moment(p.SeatingTime).format('YYYY-MM-DD')) != (moment(this.cs.headerDate).format('YYYY-MM-DD'))).map(p=>p.Size)) : 0 })
            }     
        }
        break;
      case Menu.WaitList:
        this.dashboardStatus = [
        { Name: 'totalWaitlistCount', Count: this._ps.Parties$.value.length ? this._ps.Parties$.value.filter(p=>p.Type == PartyType.WalkIn && (p.Contact?.FirstName || p.Contact?.LastName) && p.State == PartyState.Pending && (moment(p.ArrivedAt).format('YYYY-MM-DD')) == (moment(this.cs.headerDate).format('YYYY-MM-DD'))).length : 0 },
        { Name: 'totalWaitlistCovers', Count: this._ps.Parties$.value.length ? sum(this._ps.Parties$.value.filter(p=>p.Type == PartyType.WalkIn && (p.Contact?.FirstName || p.Contact?.LastName) && p.State == PartyState.Pending && (moment(p.ArrivedAt).format('YYYY-MM-DD')) == (moment(this.cs.headerDate).format('YYYY-MM-DD'))).map(p=>p.Size)) : 0 },
        { Name: 'activeWaitlist', Count: this._ps.Parties$.value.length ? this._ps.Parties$.value.filter(p=>p.Type == PartyType.WalkIn && (p.Contact?.FirstName || p.Contact?.LastName) && p.State == PartyState.Pending).length : 0 },
        { Name: 'activeWaitlistCovers', Count: this._ps.Parties$.value.length ? sum(this._ps.Parties$.value.filter(p=>p.Type == PartyType.WalkIn && (p.Contact?.FirstName || p.Contact?.LastName) && p.State == PartyState.Pending).map(p=>p.Size)) : 0 },
      ];
      if(this._ps.Parties$.value.length && sum(this._ps.Parties$.value.filter(p=>p.Type == PartyType.WalkIn && (p.Contact?.FirstName || p.Contact?.LastName) && p.State == PartyState.Seated && (moment(p.ArrivedAt).format('YYYY-MM-DD')) != (moment(this.cs.headerDate).format('YYYY-MM-DD'))).map(p=>p.Size)) > 0) {
        this.dashboardStatus.push({ Name: 'pastSeatedCoverstobeCleared', Count: this._ps.Parties$.value.length ? sum(this._ps.Parties$.value.filter(p=>p.Type == PartyType.WalkIn && (p.Contact?.FirstName || p.Contact?.LastName) && p.State == PartyState.Seated && (moment(p.ArrivedAt).format('YYYY-MM-DD')) != (moment(this.cs.headerDate).format('YYYY-MM-DD'))).map(p=>p.Size)) : 0 })
      }
        break;
      default:
    }
  }

  setTimerValues(party) {
    if (party) {
      if (party.State == PartyState.Seated) {
        party.progressBarValue = this.calculatePercentile(party);
        party.quotedTime = this.getQuotedTime(party);
        party.remainingTime = this.getRemainingTime(party);
      }
    }
  }

  getRemainingTime(party): number {
    let time: any;
    if (party.State === PartyState.Seated) {
      time = Utilities.parseDateString(party.DepartureTime);
    } else if (party.State !== PartyState.Seated) {
      time = (party.SeatingTime) ? Utilities.parseDateString(party.SeatingTime) : (party.ReservedFor) ?
        Utilities.parseDateString(party.ReservedFor) : Utilities.parseDateString(party.ArrivedAt);
    }
    return Math.floor((time - Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta).getTime()) / 1000);
  }

  getQuotedTime(party): number {
    if (party.State == PartyState.Seated) {
      return Math.round((new Date(moment(party.DepartureTime).valueOf()).getTime() -
        new Date(moment(party.SeatingTime).valueOf()).getTime()) / 1000);
    }
    else {
      return 0;
    }
  }

  getTotalSeatingTime(party): number {
    if (party.State == PartyState.Seated) {
      return Math.round((new Date(moment(party.DepartureTime).valueOf()).getTime() -
        new Date(moment(party.SeatingTime).valueOf()).getTime()) / 60000);
    }
    else {
      return 0;
    }
  }
  calculatePercentile(party) {
    let seatedTimeTill = Math.round((Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta).getTime() -
      new Date(moment(party.SeatingTime).valueOf()).getTime()) / 60000);
    return (seatedTimeTill / this.getTotalSeatingTime(party)) * 100;
  }

  partyUnselectedEventHander(party: any) {
    this.partyUnselectedEvent.emit(party);
  }

  tableTabsChange($event) {
    this.isSlotsTab = ($event == 3) ? true : false;
    this.tableTabsChangeEvent.emit($event);
  }

  selectedCommunalPartyInfo(party) {
    if (this.dashboardData) {
      this.dashboardData.partyInfo = party;
      this.selectedCommunalParty.emit(party);
    }
  }

  create(btn: ButtonValue) {
    this.createReservation.emit(btn);
  }

  closeSideNav() {
    this._as.closeNav = true;
  }

  tabChange(eve) {
    this.tabChangeEvent.emit(eve);
  }

  reservationActions(selectedAction: any) {
    this.actions.emit(selectedAction);
  }

  editTables(event) {
    this.actions.emit(event);
  }

  displayStandbyList() {
    const standbyTitle: string = this.ts.instant('standbyListPopupTitle');
    const componentDetails = {
      componentName: StandbyListComponent,
      dimensionType: 'small',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: '',
      popupTitle: standbyTitle
    }
    this.standbyDialog = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: popupDialogDimension.createDialogWidth,
      height: popupDialogDimension.createDialogHeight,
      data: {
        componentDetails,
        from: ComponentTypes.standby,
        back: true,
        standalone: true,
        showFooter: false
      }
    });
  }

  displayCoverReport() {
    const componentInfo = Utilities.setComponentDetails(CoverReportsComponent, '', 'action', '',
      this.ts.instant('coverReportMenu'));
    this.CoverReportDialog = this.openCustomPopup(componentInfo, ComponentTypes.ExplainPermissions,
      '75%', '90%', false, '', '', '', false);
  }
  displayWaitTimeReport() {
    const componentInfo = Utilities.setComponentDetails(WaitTimeReportComponent, '', 'action', '',
      this.ts.instant('waittimestext'));
    this.CoverReportDialog = this.openCustomPopup(componentInfo, ComponentTypes.ExplainPermissions,
      '75%', '90%', false, '', '', '', false);
  }

  enableFullScreen(): void {
    this.slotSection.isFullScreen = !this.cs.slotFullScreen;
    this.slotSection.selectedSlotsTab = true;
    this.cs.slotFullScreen = this.slotSection.isFullScreen;
  }
  showSlotAuditLog(): void {
    const showAlert = false;
    const title = this.ts.instant(this._MESSAGES.CONFIG.auditlog);
    const popUpMessage = [{
      dialogTitle: title, showAlert, type: AuditableEntityType.Shift, shiftId: this.cs.currentShiftId ? this.cs.currentShiftId : this.slotSection.currentShift ? this.slotSection.currentShift.Id : 0
    }];

    const componentDetails: ComponentDetails = {
      componentName: AuditlogComponent,
      dimensionType: this._MESSAGES.LABELS.large,
      popupType: this._MESSAGES.LABELS.action,
      popUpDetails: {
        isStepper: false,
        eventName: this._MESSAGES.LABELS.notifyParent
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };


    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: popupDialogDimension.createDialogHeight,
      width: popupDialogDimension.createDialogWidth,
      data: {
        title,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: false
      }
    });
  }
  disableEnableSlot(isDisableSlot: boolean): void {
    this.subscriptions.add(this.slotSection.filteredSlots$.subscribe(slots => {
      if(slots?.length) {
        slots.map(slot => {
          slot.Sections.map(section => {
            if(section.isChecked && this.cs.settings.value.General.SlottingMode == SlottingMode.Auto) { 
              this.slotSection.slotTimes.push(section.DateTime);
            }
            else if(section.isChecked && this.cs.settings.value.General.SlottingMode == SlottingMode.Manual){
              let slotTime = {
                DateTime: Utilities.Date(section.DateTime).format('YYYY-MM-DDTHH:mm:ss'),
                Id: section.Id,
                IsDefault: section.Type == SlotType.DefaultSlot
              };
              this.slotSection.slotTimes.push(slotTime);
            }
          })
        });
      }
    }))
    this.slotSection.selectedSlotCount = 0;
    this.slotSection.isEditEnabled = !this.slotSection.isEditEnabled;
    if(this.slotSection.slotTimes.length)
    this.slotSection.disableEnableSlot(this.cs.settings.value,this.slotSection.slotTimes,isDisableSlot);
  }
  setWebReservable(isWebReserve: boolean): void {
    const noText: string = this.ts.instant(this._MESSAGES.CONFIG.ok);
    const yesText: string = this.ts.instant(this._MESSAGES.CONFIG.cancel);

    let msg = ''
    this.ts.get(this._MESSAGES.CONFIG.confirmWebReserveDiableText, {isWebReserveDisable: isWebReserve ? this.ts.instant(this._MESSAGES.CONFIG.onText) : this.ts.instant(this._MESSAGES.CONFIG.offText)}).subscribe(data => msg = data);

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: this.ts.instant(this._MESSAGES.CONFIG.deleteTitle),
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: this._MESSAGES.LABELS.small,
      popupType: this._MESSAGES.LABELS.active,
      popUpDetails: {
        isStepper: false,
        eventName: this._MESSAGES.LABELS.notifyParent
      },
      popupInput: popUpMessage
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '280px',
      data: {
        title: popUpMessage[0].dialogTitle,
        update: noText,
        cancel: yesText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: false
      }
    });
    let confirmSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      this.slotSection.setWebReservableShift(this.slotSection.currentShift.Id,this._as.headerSelectedDate,isWebReserve);
    });

    let cancelSubscription = this.popupService.cancelledAction$.subscribe((val) => {
      this.slotSection.isWebReservableShift = !this.slotSection.isWebReservableShift;
    })

    this.subscriptions.add(dialogRef.afterClosed().subscribe(() => {
      if (confirmSubscription) {
        confirmSubscription.unsubscribe();
      }
      if(cancelSubscription) {
        cancelSubscription.unsubscribe();
      }
    }));
  }
}

const MESSAGES = {
  LABELS: {
    "slots-view__enable-btn": "slots-view__enable-btn",
    "slots-view__disable-btn": "slots-view__disable-btn",
    "status-switch": "status-switch",
    "action": "action",
    "active": "active",
    "notifyParent": "notifyParent",
    "large": "large",
    "small": "small",
  },
  CONFIG: {
    "parties": "parties",
    "covers": "covers",
    "enable": "enable",
    "disable": "disable",
    "WebReservations": "WebReservations",
    "deleteTitle": "deleteTitle",
    "auditlog": "auditlog",
    "cancel":"cancel",
    "ok": "ok",
    "confirmWebReserveDiableText": "confirmWebReserveDiableText",
    "onText": "onText",
    "offText": "offText",
  }
}