import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation  } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { RetailLocalization } from '../common/localization/retail-localization';

@Component({
  selector: 'app-retail-table-header',
  templateUrl: './retail-table-header.component.html',
  styleUrls: ['./retail-table-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailTableHeaderComponent implements OnInit {
  @Input() searchText: any;
  @Input() enableToggleButton: boolean;
  @Input() enableReceiptToggleButton: boolean;
  @Input() enableTerminal: boolean;
  @Input() enableMultiSelect: boolean;
  @Input() selectOptions: DropdownOptions[];
  @Input() headerOptions: any;
  @Input() isCancelButtonAvailable: boolean;
  @Input() isRoleSetUpReadOnly: boolean;
  @Input() isValidRoleName: boolean;
  @Input() isViewOnly: boolean;
  @Input('setupFormGroup') public setupFormGroup: UntypedFormGroup;
  @Input() maxInputLength: number = 40;
  @Input() minInputLength: number = 0;
  @Input() active: string;
  @Input() receiptToggleCaption: string;
  @Input() cancelButtonName: string;
  @Input() errorText: string;
  @Input() headerButtonName: string;
  @Input() headerName: string;
  @Input() searchPlaceholderValue: string;
  @Input() setupName: string;
  @Input() terminalID: string;
  @Input() terminalMissing: string;
  @Input() isEditFlag;
  @Input() inlineEditFlag;
  @Input() isAddButtonDisable = false;
  @Input() selectedOptionPlacholder: string;
  @Input() invalidGuidErrMsg: string;
  @Output() inputChange: EventEmitter<any> = new EventEmitter();
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  @Output() buttonCancelClick: EventEmitter<any> = new EventEmitter();
  @Output() searchInputChange: EventEmitter<any> = new EventEmitter();
  @Output() searchKeyPress: EventEmitter<any> = new EventEmitter();
  @Output() resetSearchValue: EventEmitter<any> = new EventEmitter();
  @Output() validToggleState: EventEmitter<any> = new EventEmitter();
  @Output() validSelectState: EventEmitter<any> = new EventEmitter();
  @Input() skipValidation: boolean = true;
  @Input() showValidationErrPopup: boolean = false;
  @Input() automationId: string = '';
  isSpecialCharAllowed: boolean;
  floatLabelNever: string;
  floatLabel: string;
  machinename :string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isGuidInputEnable :boolean;
  GuidName :any;

  isValidGuid :boolean=false;
  @Input('enableSpecialChar')
  set enableSpecialChar(val) {
    this.isSpecialCharAllowed = val? val:false;
  }
  defaultData:DropdownOptions;
  captions: any;
  minmumValidationErrMsg: string;

  constructor(private localization: RetailLocalization) {
    this.captions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnInit() {
    this.minmumValidationErrMsg = this.localization.replacePlaceholders(this.captions.AtleastXcharactersneedtobeentered,["noOfCharacters"],[this.minInputLength]);
    if(this.enableMultiSelect) {
      this.defaultData = {
        id: 0,
        value: 0,
        viewValue: this.captions.all
      };
    }
    this.setupFormGroup.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      let control=this.setupFormGroup.controls['setupCodeName'].value
      if (control && control.trim()!== '') {
        this.isValidRoleName = true;
      }
      else {
        this.isValidRoleName = false;
      }
    });
    if(this.setupName==this.localization.captions.retailsetup.machineName)
    {
      this.isGuidInputEnable=true;
      this.GuidName=this.localization.captions.retailsetup.MachineGuid;
    }else{
      this.isGuidInputEnable=false;
    }

  }

  ngOnChanges() {
    this.inlineEditFlag || this.isAddButtonDisable ? this.setupFormGroup.controls['setupCodeName'].disable() : this.setupFormGroup.controls['setupCodeName'].enable();
  }

  checkValid(event) {
    this.inputChange.emit(event);
  }

  checkValidState(event) {
    this.validToggleState.emit(event);
  }

  checkSelectState(event) {
    this.validSelectState.emit(event);
  }

  onButtonClick() {
    this.buttonClick.emit();
  }

  onButtonCancelClick(event) {
    this.buttonCancelClick.emit(event);
  }

  searchValue() {
    this.searchKeyPress.emit();
  }

  searchValueChanged(event) {
    this.searchInputChange.emit(event);
  }

  resetValue() {
    this.resetSearchValue.emit();
  }

  terminalChange(event) {
    this.checkSelectState(event);
  }

  OutletSelected(event) {
    this.checkSelectState(event);
  }

  compareSelect = (val1, val2) => {
    return val1 && val2 && val1.value === val2.value;
  }

  filterDropDownSelected(event: any,) {
    if(event.checked) {
      this.setupFormGroup.controls['selectedOptions'].setValue(this.selectOptions);
    } else {
      this.setupFormGroup.controls['selectedOptions'].setValue('');
    }
    this.checkSelectState(event);
  }

}
