<section class="cms-patron-selection">
    <div class="subhdr-msg">{{ShopCaptions.Wallet.WalletSelectionDialog.subheaderMsg}}</div>
    <form [formGroup]="walletCardsForm">
        <div class="player-list-cont">
            <div formControlName="walletCardSelection">
                <div class="payment-type-row d-flex display-column" >
                    <mat-checkbox attr.automationId='Chk_shopWalletCardSelection_walletInfo{{i}}' *ngFor="let walletInfo of walletCards;let i=index"
                    (change)="onChange($event, walletInfo)"
                     [value]="walletInfo.transactionPayment.id">
                     <div class="d-flex cms-player-type">
                        <div class="flx-grw">{{walletInfo.additionalInformation?.maskedCardNumber}} </div>
                        <div> - {{walletInfo.additionalInformation?.Amount | Currency}}</div>
                    </div>
                    </mat-checkbox>
                    
                </div>
            </div>
        </div>
        <div class="payment-footer">
            <button [attr.automationId]="'Btn_shopWalletCardSelection_ok'" mat-button
                [ngClass]="selectedWallet.length<=0 ? 'mat-raised-button whitecolor LW14 ml-3 text-capital button-ok' : 'mat-raised-button whitecolor LW14 ml-3 text-capital button-ok button-enable'"
                [disabled]="selectedWallet.length<=0"
                (click)="closeDialog()">{{localization.captions.common.OK}}</button>
        </div>
    </form>
</section>