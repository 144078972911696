import { Injectable } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';

@Injectable()
export class NotUploadedPopupService {
  captions: any;

  constructor(private localization: RetailLocalization) {
        this.captions = localization.captions.retailsetup;
  }

  getTableHeaderData() {
    return [
      {
        'name': 'Description',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'Item Number',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'Category',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'Unit of Measure',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'PRICE',
        'type': 'number',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'BARCODE',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'sub-cat 1',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'sub-cat 2',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'sub-cat 3',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'sub-cat 4',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'sub-cat 5',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'outlet #',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'Other Bar Code',
        'type': 'string',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'Commission Percent',
        'type': 'number',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'Cost',
        'type': 'number',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'Quantity',
        'type': 'number',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'Outlet Switch',
        'type': 'boolean',
        'isEditable': true,
        'ishidden': false
      },
      {
        'name': 'categoryId',
        'type': 'string',
        'isEditable': false,
        'ishidden': true
      },
      {
        'name': 'subCategory1Id',
        'type': 'string',
        'isEditable': false,
        'ishidden': true
      },
      {
        'name': 'unitOfMeasureId',
        'type': 'string',
        'isEditable': false,
        'ishidden': true
      },
      {
        'name': 'id',
        'type': 'string',
        'isEditable': false,
        'ishidden': true
      },
      {
        'name': 'errorMessage',
        'type': 'string',
        'isEditable': false,
        'ishidden': false
      }
    ];
  }

  getTableBodyData() {
return [
  {
    'Description': 'Cadbury11',
    'Item Number': '312',
    'Category': 'led',
    'Unit of Measure': 'Gram',
    'PRICE': '',
    'BARCODE': '333',
    'sub-cat 1': 'DarkChoco',
    'sub-cat 2': '',
    'sub-cat 3': '',
    'sub-cat 4': '',
    'sub-cat 5': '',
    'outlet #': 'REPRO1',
    'Other Bar Code': 'SECONDARYBARCODE',
    'Commission Percent': '',
    'Cost': '',
    'Quantity': '',
    'Outlet Switch': '0',
    'categoryId': 24,
    'subCategory1Id': 33,
    'unitOfMeasureId': 4,
    'id': 0,
    'errorMessage': 'ItemNumber Already Exists'
  }
];
}
}
