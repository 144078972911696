import { Injectable } from '@angular/core';

@Injectable()
export class CategoryListService {
    categories: Array<any> = [];
    subCategoryOne: Array<any> = [];
    subCategoryTwo: Array<any> = [];
    subCategoryThree: Array<any> = [];
    subcategoryOne(){
        return this.subCategoryOne = [
            {id: 1, 'name': 'Bag Tags', isSelected: false},
            {id: 2, 'name': 'Booms', isSelected: false},
            {id: 3, 'name': 'Divot Tools', isSelected: false},
            {id: 4, 'name': 'Drivers', isSelected: false},
            {id: 5, 'name': 'Goals', isSelected: false},
        ]
    }

    category(){
        return this.categories =  [
            {id: 1, name: 'Gift Card Sold', isSelected: false},
            {id: 2, name: 'Spa Body treatemet', isSelected: false},
            {id: 3, name: 'Spa day packages', isSelected: false},
            {id: 4, name: 'golf Foot Wear', isSelected: false},
            {id: 5, name: 'Goals', isSelected: true},
            {id: 6, name: 'Goals', isSelected: false},
            {id: 7, name: 'Goals', isSelected: false},
            {id: 8, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: true},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},
            {id: 9, name: 'Goals', isSelected: false},

        ]
    }

    subcategoryTwo(){
        return this.subCategoryTwo = [
            {id: 1, 'name': 'Bag Tags2', isSelected: false },
            {id: 2, 'name': 'Booms2', isSelected: false},
            {id: 3, 'name': 'Divot Tools2', isSelected: true},
            {id: 4, 'name': 'Drivers2', isSelected: false},
            {id: 5, 'name': 'Goals2', isSelected: false},
        ]
    }
    subcategoryThree(){
        return this.subCategoryThree = [
            {id: 1, 'name': 'Bag Tags33', isSelected: false},
            {id: 2, 'name': 'Booms', isSelected: false},
            {id: 3, 'name': 'Divot Tools3', isSelected: false},
            {id: 4, 'name': 'Drivers3', isSelected: true},
            {id: 5, 'name': 'Goals', isSelected: false},
        ]
    }

    subcategoryFour(){
        return [
            {id: 1, 'name': 'Bag Tags33', isSelected: false},
            {id: 2, 'name': 'Booms', isSelected: false},
            {id: 3, 'name': 'Divot Tools3', isSelected: false},
            {id: 4, 'name': 'Drivers3', isSelected: false},
            {id: 5, 'name': 'Goals', isSelected: false},
        ]
    }


    pimaryCategoryArrayDefined (e: any): any[] {
        switch (e) {
            case 1: {
               return this.subcategoryOne();
            }
            case 2: {
                return this.subcategoryTwo();
            }
            case 3: {
                return this.subcategoryThree();
            }
            case 4: {
                return this.subcategoryFour();
            }
            default: {
               //statements;
               break;
            }
         }
    }

    subCategoryOptions(){
        return [
            {id: 1, name: 'Subcategory 1'},
            {id: 2, name: 'Subcategory 2'},
            {id: 3, name: 'Subcategory 3'},
            {id: 4, name: 'Subcategory 4'},
            {id: 5, name: 'Sucategory 5'},
          ];
    }




}
