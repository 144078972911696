import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPopupComponent } from './components/custom-popup/custom-popup.component';
import { MaterialModule } from '@app/material-module';
import { ReservationTypeComponent } from '@app/shared/components/select-reservation-type/select-reservation-type.component';
import { SharedModule } from '@app/shared/shared.module';
import { AdDirective } from './directives/ad.directive';
import { ContactComponent } from '@app/shared/components/create-update-guest-tab-layout/definitions/contact/contact.component';
import { PersonalInfoComponent } from '@app/shared/components/create-update-guest-tab-layout/definitions/personal-info/personal-info.component';
import { SizeSelectionComponent } from '@app/shared/components/create-update-party-tab-layout/selections/size-selection/size-selection.component';
import { SeatingAreaSelectionComponent } from '@app/shared/components/create-update-party-tab-layout/selections/seating-area/seating-area-selection.component';
import { SpecialMealComponent } from '@app/shared/components/create-update-party-tab-layout/selections/special-meal/special-meal.component';
import { DateSelectionComponent } from '@app/shared/components/create-update-party-tab-layout/selections/date-selection/date-selection.component';
import { WaitTimeComponent } from '@app/shared/components/create-update-party-tab-layout/selections/wait-time/wait-time.component';
import { TableSelectionComponent } from '@app/shared/components/create-update-party-tab-layout/selections/table-selection/table-selection.component';
import { GuestSelectionComponent } from '@app/shared/components/create-update-party-tab-layout/selections/guest-selection/guest-selection.component';
import { PopupService } from './popup.service';
import { DataRetentionPoliciesComponent } from '@app/reservation/data-retention-policies/data-retention-policies.component';
import { ReservationModule } from '@app/reservation/reservation.module';
import { SelectBookingTypeComponent } from '@app/shared/components/select-booking-type/select-reservation-type.component';
import { AppPopupComponent } from './components/app-popup/app-popup.component';

@NgModule({
    declarations: [CustomPopupComponent,AppPopupComponent, AdDirective],
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        ReservationModule
    ],
    providers: [PopupService]
})
export class PopupModule { }
