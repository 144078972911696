<mat-form-field [ngClass]="customClass" [formGroup]="groupName" (click)="onControlClick()"
                [floatLabel]="floatLabel">
    <mat-label style="cursor:pointer" [attr.LiteralID]="placeHolderId">{{placeHolder}}</mat-label>
    <input matInput [percentinputtype]="inputType" [placeholder]="placeHolder" [formControlName]="controlName" [disabled]="disabled" [max]="maxValue" [min]="minValue" [maxpredecimalValue]="predecimalmaxlength" [required]="isRequired">
    <mat-error *ngIf="groupName?.controls[controlName]?.hasError('required')" [attr.LiteralID]="errorMessageId">
        {{errorMessage}}
    </mat-error>
    <mat-error *ngIf="groupName?.controls[controlName]?.hasError('min')">
        {{placeHolder}} {{captions.err_min}}
    </mat-error>
    <mat-error *ngIf="groupName?.controls[controlName]?.hasError('max')" [attr.LiteralID]="'err_max'" >
        {{captions.err_max}}
    </mat-error>
</mat-form-field>
