<div class="override-time">
  <div class="override-time__time-view">
    <div class="override-time__time-at-table">
      <div class="seat-secondary-text">{{'timeAtTable' | translate}}</div>
      <span class="seat-tertiary-text">{{(SeatedParty.quotedTime - SeatedParty.remainingTime)  | formatWaitTime}}
      </span>
    </div>
    <div>      
      <div class="seat-secondary-text">{{'remaining' | translate}}</div>
      <span class="seat-tertiary-text" *ngIf="(SeatedParty.remainingTime > 0)">
        {{SeatedParty.remainingTime | formatWaitTime}} </span>
      <span class="seat-tertiary-text" *ngIf="(0 > SeatedParty.remainingTime)"> 0{{'minsShortText' | translate}}
      </span>
    </div>
  </div>
  <div class="override-time__time-input">
    <div class="seat-secondary-text override-time__override-label">{{'overRideTime' | translate}}</div>
    <mat-form-field class="override-time__hours-view">      
      <mat-select disableOptionCentering [(value)]="Hours" placeholder="{{'hoursText' | translate}}">
        <mat-option *ngFor="let option of PossibleHours" [value]="option.name" (click)="setSelectedHour(option)">
          {{option.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="override-time__minutes-view">    
      <mat-select disableOptionCentering [(value)]="Minutes" placeholder="{{'minutes' | translate}}">
        <mat-option *ngFor="let option of PossibleMinutes" [value]="option.name" (click)="setSelectedMinute(option)">
          {{option.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>