import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  managerPin$ = new Subject<boolean>();
  userAction$ = new Subject<boolean>();
  resetPin$ = new Subject<any>();
  public isLoading = new BehaviorSubject(false);
  public showMessage = new BehaviorSubject(false);
  public loaderMessage = new BehaviorSubject('');
  public disableLoader = new BehaviorSubject(false);
  public loaderMessageForUrl = [
  //  Note : name must match with url-Config and text must match with translation file 
    { name: "getLayoutImagesURL", text: "loadingMessage" },
    { name: "getCartList", text: "loadingMessage" },
    { name: "loadReservationsURL", text: "loadingMessage" },
    { name: "loadRestaurantUrl", text: "loadingMessage" },
    { name: "logoutUrl", text: "logoutUrl" },
    { name: "updateReservationAttempt", text: "UpdateReservationAttempt" },
    { name: "lockCartForMultipleProperties" , text: "lockCartForMultipleProperties"},
    { name: "RegisterMultiplePropertyActivityReservationAttempts" , text: "RegisterMultiplePropertyActivityReservationAttempts"},
    { name: "GetRatePlanCalculatedURL" , text: "GetRatePlanCalculatedURL"},
    { name: "GetReservationAttemptState" , text: "GetReservationAttemptState"},
    { name: "updateSessionBookingURL" , text: "updateSessionBookingURL"},
    { name: "cancelRetailOrderDetails" , text: "cancelRetailOrderDetails"},
    { name: "bookCartForMultipleProperties" , text: "BookCartForMultipleProperties"},
    { name: "GetSlotsForActivityURL" , text: "GetSlotsForActivityURL"},
    { name: "GetSlotsForTablesURL" , text: "GetSlotsForTablesURL"},
    {name: "GetSlots" , text: "GetSlotsForTablesURL"},
    { name: "getExContactSuggestionUrl", text: "getExContactSuggestionUrl"},
    { name: "getguestInfoUrl", text: "getguestInfoUrl"},
    { name: "updateHostURL", text: "updateHostURL"},
    { name: "getHostsURL", text: "getHostsURL"},
    { name: "getContacts", text: "getContacts"},
    { name: "getRatePlanReCalculation" , text: "GetRatePlanCalculatedURL"},
  ]
  
  constructor() {
  }
}
