<div class="calendarheader page-title px-3">
    <div class="calendarheader__actions">
        <div class="action-content border-right-0" (click)="navigateCalendar(currentViewMode,0)"><span class="icon-left-arrow2"></span></div>
        <div class="action-content" (click)="navigateCalendar(currentViewMode,1)"><span class="icon-right-arrow2"></span></div>
        <div *ngIf="isShowToday && currentViewMode == CalendarViewType.Days"
            class="action-content border-left-0 today-content" (click)="getCurrentDate(headerDate)"><label>{{'todayText'| translate}}</label></div>
    </div>
    <div class="mx-auto" (click)="changeViewMode(currentViewMode)">
        <label [ngClass]="{'calendar-header' : currentViewMode != CalendarViewType.Years}">{{selectedData}}</label>
    </div>
</div>
<ng-container [ngSwitch]="currentViewMode">
    <!-- Calendar Grid -->
    <div *ngSwitchCase="CalendarViewType.Days" class="calendargrid px-3">
        <div *ngFor="let day of weekDays;first as firstDay; last as lastDay" class="calendargrid__week-days" [ngStyle]="{'border-right': !lastDay ? '0px': '', 'margin-right': lastDay ? '-1px' : ''}">
            {{day | translate}}
        </div>
        <div *ngFor="let calendarDate of calendarDays" class="calendargrid__week-date calendar-outline" [ngClass]="{'selected': calendarDate.isSelected, 'grid-muted': calendarDate.month != month}" [ngStyle]="{'height': calendarDays?.length > 40 ? '70px' : '85px'}">
            <app-calendar-cell  (selectedDate)="setSelectedDate($event,currentViewMode)" [headerDate]="headerDate" [gridData]="calendarDate" [currentMonth]="month" [currentDate]="date" [currentYear]="year" [currentViewMode]="currentViewMode"></app-calendar-cell>
        </div>
    </div>
    <!-- Months Grid -->
    <div *ngSwitchCase="CalendarViewType.Months" class="monthgrid px-3">
        <div *ngFor="let data of months" class="monthgrid__content calendar-outline" [ngClass]="{'selected': data.isSelected}">
            <app-calendar-cell  (selectedDate)="setSelectedDate($event,currentViewMode)" [headerDate]="headerDate" [gridData]="data" [currentMonth]="month" [currentDate]="date" [currentYear]="year" [currentViewMode]="currentViewMode"></app-calendar-cell>
        </div>
    </div>
    <!-- Year Grid -->
    <div *ngSwitchCase="CalendarViewType.Years" class="yeargrid px-3">
        <div *ngFor="let data of years" class="yeargrid__content calendar-outline" [ngClass]="{'selected': data.isSelected}">
            <app-calendar-cell  (selectedDate)="setSelectedDate($event,currentViewMode)" [headerDate]="headerDate" [gridData]="data" [currentMonth]="month" [currentDate]="date" [currentYear]="year" [currentViewMode]="currentViewMode"></app-calendar-cell>
        </div>
    </div>
</ng-container>