<div class="roomType-wrapper ag_mb--5" #comp>
    <div class="item-container">
        <div class="item-list">
            <div class="item-search"  *ngIf="showSearch">
                <div class="title_align">
                    <h4 class='ag_group--headers'>{{title}}</h4>
                    <p class="title"><span *ngIf="selectedLength > 0">({{selectedTextLength}})</span></p>
                </div>
                <div class="search_align">
                    <app-simple-search [placeholder]="searchPlaceHolder" (searchChange)="searchValueChange($event)"
                        [disabled]='isViewOnly'></app-simple-search>
                </div>
            </div>
            <app-warning-bar  *ngIf = "showWarningBar" (closeWarning)="closeWarning()" [message]="warningMessage" [showClose]="showClose">
            </app-warning-bar>
            <div class="item-checkbox-group">
                <ng-content></ng-content>
                <ng-container *ngIf="filteredDataArr && filteredDataArr.length > 0;let i = index;">
                   <div class="custom-check-wrapper" *ngIf="showSelectAll" >
                    <custom-checkbox [customCBxDisabled]="disabled"
                        [customCBxwrapperClass]="'item-checkbox'" [customCBxId]="i" [customCBxChecked]="selectAll"
                        [customCBxlabelText]="captions.lbl_selectAll"
                        (changeEvent)="onSelectAllChange($event,selectAll)" class="custom-checkbox">
                    </custom-checkbox>
                   </div> 
                    <ng-container *ngFor="let item of filteredDataArr ;let i = index">
                        <div class="custom-check-wrapper">  
                        <custom-checkbox [customCBxDisabled]="disabled" [customCBxChecked]="item.checked"
                            [customCBxId]="i" [customCBxwrapperClass]="'item-checkbox'"
                            [customCBxlabelText]="item.viewValue" (changeEvent)="onItemChange($event,item,i)" class="custom-checkbox">
                        </custom-checkbox>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="filteredDataArr && filteredDataArr.length == 0">
                    <div class="ag_w--100 ag_text--center noData" [attr.LiteralID]="'lbl_noDataFound'" >{{captions.lbl_noDataFound}}</div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
