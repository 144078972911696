<section class="retail-categories newVersionWrapper retailCategoryWrapper h-100 d-flex flex-column">
    <header class="d-flex flex-row">
        <div class="form-wrapper d-flex w-100" [formGroup]="retailCategoryform">
            <div class="d-flex flex-wrap form-field-wrapper pt-2 w-100">
                <mat-form-field class="pr-3" [floatLabel]="floatLabel">
                    <mat-select [attr.automationId]="'Dd_retailCategory_groupName'" [placeholder]="captions.categoryGroup" formControlName="categoryGroup">
                        <mat-option *ngFor="let group of ActiveCategoryGroups" [value]="group.id">{{group.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="pr-3" [floatLabel]="floatLabel">
                    <input [attr.automationId]="'Txt_retailCategory_categoryName'" matInput [placeholder]="captions.CategoryName" autocomplete="off" formControlName="categoryName" [maxlength]="25" required>
                    <mat-error *ngIf="retailCategoryform.controls['categoryName'].hasError('required') && !retailCategoryform.controls['categoryName'].valid">{{captions.MissingCategoryName}}</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="userRetailInterface" class="pr-2" [floatLabel]="floatLabel">
                    <input [attr.automationId]="'Txt_retailCategory_revenueCategory'" matInput [placeholder]="captions.RevenueCategoryId" autocomplete="off" formControlName="revenueCategoryIdFromExternalInterface" [maxlength]="25"
                           (keypress)="omit_special_char($event)" (blur)="checkRevenueCategoryId($event)" inputtype="onlynumber,nonnegative,nodecimal">
                    <mat-error *ngIf="!retailCategoryform.controls['revenueCategoryIdFromExternalInterface'].valid">{{captions.RevenueCategoryIdExists}}</mat-error>
                </mat-form-field>
                <div class="d-inline-flex pt-2 pr-3 flex-column">
                    <label class="form-label-normal toggle-label">{{captions.Eligiblefordiscount}}</label>
                    <app-retail-toggle-switch [attr.automationId]="'Txt_retailCategory_eligibleForDiscount'" [disableToggle]="inlineEditOn" class="toggle-switch" formControlName="eligibleForDiscount"></app-retail-toggle-switch>
                </div>
                <div class="d-inline-flex pt-2 pr-3 flex-column" *ngIf="_featureSwitch.AllowAccrualPoints">
                    <label class="form-label-normal toggle-label">{{captions.AllowEarn}}</label>
                    <app-retail-toggle-switch [attr.automationId]="'Txt_retailCategory_allowEarn'" [disableToggle]="inlineEditOn" class="toggle-switch" formControlName="allowEarn"></app-retail-toggle-switch>
                </div>
                <div class="d-inline-flex pt-2 pr-3 flex-column" *ngIf="isEditAction">
                    <label class="toggle-label form-label-normal">{{captions.Active}}</label>
                    <app-retail-toggle-switch [attr.automationId]="'Txt_retailCategory_active'" class="toggle-switch" [disableToggle]="inlineEditOn" formControlName="isActive"></app-retail-toggle-switch>
                </div>
                <div class="d-inline-flex pt-2 pr-3 flex-column" *ngIf="showInHouseGratuityToggle">
                    <label class="toggle-label form-label-normal">{{captions.lbl_EligibleForInhouseGratuity}}</label>
                    <app-retail-toggle-switch [attr.automationId]="'Txt_retailCategory_gratuity'" class="toggle-switch" [disableToggle]="inlineEditOn" formControlName="eligibleForInHouseGratuityCalculation"></app-retail-toggle-switch>
                </div>
                <div class="d-inline-flex pt-2 pr-3 flex-column" *ngIf="showInHouseSCToggle">
                    <label class="toggle-label form-label-normal">{{captions.lbl_EligibleForInhouseServiceCharge}}</label>
                    <app-retail-toggle-switch [attr.automationId]="'Txt_retailCategory_servicecharge'" class="toggle-switch" [disableToggle]="inlineEditOn" formControlName="eligibleForInHouseServiceChargeCalculation"></app-retail-toggle-switch>
                </div>



                <button [attr.automationId]="'Btn_retailCategory_add'" mat-raised-button type="button" class=" LW14 mr-2 mat-button button_height" [ngClass]="!IsViewOnly && retailCategoryform.valid && retailCategoryform.dirty? 'body-bgcolor whitecolor body-bordercolor': 'button--disabled'"
                        [disabled]="!retailCategoryform.valid || retailCategoryform.pristine" (click)="addCategory()">
                    {{actionText}}
                </button>

                <a *ngIf="isEditAction" class=" p-2 mr-3 LW14 cancelButton button_height cursor" (click)="resetValues()">{{captions.CANCEL}}</a>

                <!--RETAIL CATEGORY EXPORT BUTTON-->
                <button [attr.automationId]="'Btn_retailCategory_download'" *ngIf="Categories?.length > 0" mat-raised-button type="button" class="LW14 mr-2 mat-button button_height"
                        [ngClass]="!isEditAction? 'body-bgcolor whitecolor body-bordercolor': 'button--disabled'"
                        [disabled]="isEditAction"
                        (click)="DownloadList()">
                    {{captions.downloadCategoryList}}
                </button>

                <mat-form-field class="searchArea ml-auto spa-searchbox custSearchbox" [floatLabel]="floatLabelNever" [ngClass]="{'retailSearchWidth': true, 'd-none': isEditAction}" *ngIf="!isEditFlag">
                    <input [attr.automationId]="'Txt_retailCategory_search'" matInput autocomplete="off" class="search-input" type="text" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" [placeholder]="captions.Search">
                    <i aria-hidden="true" [ngClass]="searchText ? 'icon-Cancel' : 'icon-Search'" class="cursor" (click)="clearSearchText()"></i>
                </mat-form-field>
                <i aria-hidden="true" class="align-self-center icon-Search button_invalid ml-auto" *ngIf="isEditFlag"></i>



            </div>


        </div>

    </header>
    <div class="retailGrid">
        <app-retail-table [attr.automationId]="'Tbl_retailCategory_retail'" [options]="tableoptions" (dragDropEvt)="dragDrop($event)"
                          (deleteEvt)="DeleteRecords($event)" (editEvt)="EditRecords($event)" [searchvalue]="searchText" [customEditCancelled]="customEditCancelled"
                          (doneEvtEmitter)="Done($event)" [isOnEditDisableFunctionAvailable]="onEditDisableFunction"></app-retail-table>
    </div>
</section>
