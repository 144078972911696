<section class="addItemSec">
  <h1 class='ag_p--4 ag_m--0 ag_display--flex text-capital' mat-dialog-title>{{shopaddItemCaptions.headerName}}
    - {{data.products.ItemDescription}}
    <i [attr.automationId]="'Icn_shopAdditem_closePopup'" class='icon-close ag_ml--auto ag_cursor--pointer' aria-hidden="true"
      (click)="closePopUp(dialogCloseEnum.Close)">&nbsp;</i>
  </h1>
  <div class="Add-Item-Template-wrapper p-4" [formGroup]="shopInfo">
    <div class="status">
      <h4 class="ag_group--headers text-capital">{{shopaddItemCaptions.invStatus}}</h4>
      <p class="retail-available-label" *ngIf="data.products.ItemType===itemType.RetailItemRetailPOSOnly">{{shopaddItemCaptions.RetailAvailableQuantityLabel}}</p>
      <div class="status-wrapper">
        <table class='status-list' cellspacing="5">
          <thead>
            <tr>
              <th *ngIf="data.products.ItemType===itemType.RentalItem" class="LWB14">{{shopaddItemCaptions.total}}</th>
              <th class="LWB14">{{shopaddItemCaptions.inHouse}}</th>
              <th class="LWB14">{{shopaddItemCaptions.reservation}}</th>
              <th class="LWB14">{{shopaddItemCaptions.available}}</th>
              <th class="LWB14">{{shopaddItemCaptions.price}}{{currencySymbol}}
                <div *ngIf="data.products.ItemType===itemType.RentalItem">
                  ({{shopCaptions.lbl_daily}}/{{shopCaptions.lbl_hourly}})</div>
              </th>
            </tr>
          </thead>
          <tbody class="status-data h-100">
            <tr>
              <td colspan="5"></td>
            </tr>
            <ng-container *ngFor="let val of invStatus">
              <tr class='group-title'>
                <td colspan="3">
                  <h4 class="ag_group--headers">{{val.date}}</h4>
                </td>
              </tr>
              <tr class="pr-3">
                <td *ngFor="let price of val.values; let i =index;let last =last;">
                  <p class="status-box align-items-center" [ngClass]="[i == val.values.length-1?'ag_font--bold': '']"
                    [ngStyle]="{'background-color' : price.bgColor}">{{price.value}}</p>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="selectDetails">
      <h4 class="ag_group--headers text-capital">{{shopaddItemCaptions.selectDetails}}</h4>
      <div class="selectMain">
        <label class="LWB14 text-capital ag_mb--2">{{shopaddItemCaptions.setAll}}</label>
        <div class="d-flex justify-flx-start ag_display--flex-wrap">
          <div class="toggle-label align-items-center pt-1 pr-4 d-table">
            <label class="ag_form--label toggle-switch ag_mr--3">{{shopaddItemCaptions.setQuantity}}</label>
            <app-retail-toggle-switch [attr.automationId]="'Tog_shopAdditem_showQuantity'" (changeToggleEvent)=showQuantity($event)></app-retail-toggle-switch>
          </div>
          <ag-input [attr.automationId]="'Txt_shopAdditem_changeQuantity'" (change)='changeQuantity()' min={{quantityMinValue}} formControlName="quantitySelect" [disabled]='true' type='NUMBER'
            [placeholder]="shopaddItemCaptions.quantity" class="ag_form-control--1 ag_display--inblock">
          </ag-input>
          <div class="vl"></div>
          <div class="toggle-label align-items-center pt-1 pr-4 d-table">
            <label class="ag_form--label toggle-switch ag_mr--3">{{shopaddItemCaptions.setPrice}}</label>
            <app-retail-toggle-switch [attr.automationId]="'tog_shopAdditem_showPrice'" (changeToggleEvent)=showPrice($event)></app-retail-toggle-switch>
          </div>
          <ag-input [attr.automationId]="'Txt_shopAdditem_changePrice'" (change)='changePrice()' formControlName="priceSelect" [disabled]='true' type='Currency'
            [placeholder]="shopaddItemCaptions.price" class="ag_form-control--1 ag_display--inblock">
          </ag-input>
        </div>
      </div>
      <div class="selectDatesMain">
        <div class="align-center selectDates d-flex">
          <h4 class="ag_group--headers text-capital pt-4">{{shopaddItemCaptions.specificDates}}</h4>
          <mat-button-toggle-group [attr.automationId]="'Tog_shopAdditem_availabilityType'" name="fontStyle" aria-label="Font Style" formControlName="typeToggler"
            #group="matButtonToggleGroup" [value]="datesView">
            <mat-button-toggle value="list" [matTooltip]="shopaddItemCaptions.listView"
              (change)="toggleAvailabilityType($event.value)"><span class="icon-List-View"></span></mat-button-toggle>
            <mat-button-toggle value="card" [matTooltip]="shopaddItemCaptions.cardView"
              (change)="toggleAvailabilityType($event.value)"><span class="icon-Card-View"></span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <ng-container>
          <div class="ag_display--flex-wrap mainContainer">
            <div formArrayName="items" class="ag_mb--4 ag_w--100 ag_display--flex"
              *ngFor="let item of shopInfo.get('items')['controls']; let i = index;let first = first;let last = last">
              <div  [ngClass]="[shopInfo?.get('typeToggler').value === 'card'?'set-card-custom-width':'']" class="list ag_mr--5 d-inline-block"
                [ngStyle]="{'width': shopInfo?.get('typeToggler').value === 'list'? '': '504px'}">
                <label class="LWB14 ag_mb--2">{{shopaddItemCaptions.selection}} {{i+1}}</label>
                <ng-container *ngIf="!first">
                  <i attr.automationId='Icn_shopAdditem_removeItem{{i}}' class='icon-cancel ag_ml--auto ag_cursor--pointer float-right' aria-hidden="true"
                    (click)="removeItem($event,i)" *ngIf="shopInfo?.get('typeToggler').value === 'card'">&nbsp;</i>
                </ng-container>

                <div class="ag_display--flex-wrap" [formGroupName]="i">
                  <mat-form-field class="ag_form-control--1 d-inline-block date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
                    <mat-label>{{shopaddItemCaptions.sDate}}</mat-label>
                    <input attr.automationId='Txt_shopAdditem_startDate{{i}}' formControlName="startDate" (dateChange)='startDateChanged($event,item)' class="" matInput
                      [matDatepicker]="picker1" [min]="item.get('minStartDate').value"
                      [max]="item.get('maxStartDate').value" [placeholder]="placeHolderFormat" name="date">
                    <mat-datepicker #picker1></mat-datepicker>
                    <i aria-hidden="true" matSuffix class="icon-Calender" (click)="picker1.open()"></i>
                  </mat-form-field>
                  <mat-form-field class="ag_form-control--1 d-inline-block date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
                    <mat-label>{{shopaddItemCaptions.eDate}}</mat-label>
                    <input attr.automationId='Txt_shopAdditem_endDate{{i}}' formControlName="endDate" (dateChange)='endDateChanged($event,item)' class="" matInput [matDatepicker]="picker2"
                      [min]="item.get('minEndDate').value" [max]="item.get('maxEndDate').value"
                      [placeholder]="placeHolderFormat" name="date">
                    <mat-datepicker #picker2></mat-datepicker>
                    <i aria-hidden="true" matSuffix class="icon-Calender" (click)="picker2.open()"></i>
                  </mat-form-field>
                  <div *ngIf="data.products.ItemType===itemType.RentalItem"
                    class="ag_form-control--1 ag_display--inblock">
                    <label class="ag_form--label toggle-switch ag_mr--3">{{localization.captions.lbl_rentType}}</label>
                    <div class="ag_display--flex">
                      <app-retail-toggle-switch attr.automationId='Tog_shopAdditem_rentType{{i}}' formControlName="rentType" class="pr-3"
                        (changeToggleEvent)=switchRentType($event,item)>
                      </app-retail-toggle-switch>
                      <label
                        class="ag_form--label toggle-switch ag_mr--3">{{item.get('rentTypeLabel').value}}</label>
                    </div>
                  </div>
                  <ag-input attr.automationId='Txt_shopAdditem_hours{{i}}' *ngIf="item.get('rentType').value" formControlName="hours" type='NUMBER'
                    (change)='calculateTotal(item)' [placeholder]="localization.captions.lbl_hours"
                    class="ag_form-control--1 ag_display--inblock">
                  </ag-input>
                  <ag-input attr.automationId='Txt_shopAdditem_quantity{{i}}' formControlName="quantity"  min={{quantityMinValue}} type='NUMBER' [placeholder]="shopaddItemCaptions.quantity"
                    (change)='calculateTotal(item)' class="ag_form-control--1 ag_display--inblock"></ag-input>
                  <ag-input attr.automationId='Txt_shopAdditem_price{{i}}' class="ag_form-control--1 ag_display--inblock" formControlName="price" type='Currency'
                    (change)='calculateTotal(item)' [placeholder]="shopaddItemCaptions.price"></ag-input>
                  <ag-input attr.automationId='Icn_shopAdditem_total{{i}}' class="ag_form-control--1 ag_display--inblock" formControlName="total" type='Currency'
                    [placeholder]="shopaddItemCaptions.total" disabled="true"></ag-input>
                </div>
              </div>
              <div class="sign d-inline-block" *ngIf="shopInfo?.get('typeToggler').value === 'list'">
                <span attr.automationId='Lbl_shopAdditem_removeItem{{i}}' class="icon-Minus themecolor" *ngIf="!(first && last)" (click)="removeItem($event,i)"></span>
                <span attr.automationId='Lbl_shopAdditem_addItem{{i}}' class="icon-Plus themecolor" *ngIf="last" (click)="add($event,i);"></span>
              </div>
              <div class="plusCard" *ngIf="shopInfo?.get('typeToggler').value === 'card' && last">
                <div class="container">
                  <span attr.automationId='Lbl_shopAdditem_selectionNew{{i}}'class="icon-Plus themecolor" (click)="add($event,i);"></span><br>
                  <label class="LWB14">{{shopaddItemCaptions.selectionNew}}</label>
                </div>
              </div>
            </div>
           
          </div>

        </ng-container>
      </div>
    </div>

  </div>
</section>
<div class="ag_footer--actions">
  <app-button [attr.automationId]="'Btn_shopAdditem_onAdd'" [buttontype]="addButton" (valueChange)='onAddAction($event)'></app-button>
  <app-button [attr.automationId]="'Btn_shopAdditem_onCancel'" [buttontype]="cancelButton" (valueChange)='onCancel($event)'></app-button>
</div>
