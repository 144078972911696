export const JWT_TOKEN = '_jwt';
export const JWT_EXPIRE = 'jwtExpiryTime';
export const USER_SESSION = 'userSession';
export const REMEMBER_INFO = '_rememberInfo';
export const USER_INFO = '_userInfo';
export const USER_SETTINGS = 'userSettings';
export const PROPERTY_DATE = 'propertyDate';
export const PROPERTY_INFO = 'propertyInfo';
export const USE_RETAIL_INTERFACE = 'useRetailInterface';
export const VAT_ENABLED = 'VATEnabled';
export const PROPERTY_CONFIGURATION_SETTINGS = 'PROPERTYCONFIGURATION';
export const USERS_SESSSIONS_INFO = 'usersSessionsInfo';
export const FULL_STORY_ORG_ID = 'FullStoryOrgId'