import { Injectable } from "@angular/core";
import { MenuObj } from './menu.model';
import { CommonControllersRoutes } from 'src/app/common/communication/common-route';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';

@Injectable()
export class MenuSearchDataService {

  constructor(private _tenantMngmt: TenantManagementCommunication) {
  }

  async getProductMenus(): Promise<MenuObj[]> {
    try {
      return await this._tenantMngmt.getPromise({
        route: CommonControllersRoutes.GetMenus,
        uriParams: ""
      });
    } catch (e) {
      console.error(e);
      throw (e);
    }
  }

}
