import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';

@Component({
  selector: 'app-form-input-autocomplete',
  templateUrl: './form-input-autocomplete.component.html',
  styleUrls: ['./form-input-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormInputAutocompleteComponent implements Field, OnInit, OnDestroy {
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef<HTMLInputElement>;
  public FormControlName;
  config: FieldConfig;
  group: UntypedFormGroup;
  autocompleteOptions: any[];
  selectedOptions: any = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  originalData: any[];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  fruitCtrl = new UntypedFormControl();
  toHighlight: string = '';
  // @Output() valueChange: EventEmitter<any> = new EventEmitter();
  selectedData: any = [];
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoCompleteSelect: MatAutocompleteTrigger;

  constructor(public _eR: ElementRef, public renderer: Renderer2) {

  }


  inputEmiter(e) {
    if (e.target.value.length > 0) {
      this.toHighlight = e.target.value;
      /* this.autocompleteOptions = this.config.autocompleteOptions; */
      this.autocompleteOptions = this.originalData.filter(option => {
        return (
          option.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
        )
      });
      this.group.patchValue({ toPerson: '' })
    }
    else {
      this.autocompleteOptions = [];
    }

    //To open suggestion panel when autocomplete results are found
    if (this.autocompleteOptions.length > 1) {
      this.autoCompleteSelect.openPanel();
      console.log('Panel opened');
    }
  }

  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      // if ((value || '').trim()) {
      //   this.selectedOptions.push(value.trim());
      // }

      // Reset the input value
      if (input) {
        input.value = '';
      }
      this.group.patchValue({ toPerson: '' })
    }
  }

  ngOnInit() {
    this.FormControlName = this.config.name;
    this.originalData = this.config.autocompleteOptions;
    this.selectedOptions = this.config.value.length != 0 ? this.config.value.filter(Boolean) : [];
    this.selectedData = this.config.value.length != 0 ? this.config.value : [];
    //this.selectedOptions = this.config.value.split(",").length != 0 ? this.config.value.split(",").filter(Boolean) : [];
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let hoverData = event.option.viewValue;
    if (this.config.allowDuplicate) {
      this.addDatatochip(event);
    } else {
      const isDuplicate = this.selectedData.some((x: string) => x === hoverData);
      if (!isDuplicate) {
        this.selectedData.push(hoverData);
        this.addDatatochip(event);
      }
    }
  }

  addDatatochip(event) {
    this.toHighlight = "";
    this.selectedOptions.push(event.option.viewValue);
    this.autoCompleteInput.nativeElement.value = '';
    this.group.patchValue({ toPerson: '' });
  }

  ngDoCheck(): void {
    const panel = this.matAutocomplete.panel;
    if (panel && panel.nativeElement.parentElement) {
      panel.nativeElement.parentElement.classList.add(this.config.panelClass);
    }
  }

  remove(index): void {
    this._eR.nativeElement.getElementsByTagName('mat-chip')[index].remove();
    this.selectedOptions.splice(index, 1);
    this.selectedData.splice(index, 1);
    let selectedId = new Set(this.selectedData.map(({ id }) => id));
    this.autocompleteOptions = this.originalData.filter(x => x.name != this.selectedData.map(x => x));
  }
  removeLast(event): void {
    event.target.focus();
    if (event.which == 8 && event.target.value == "") {
      this._eR.nativeElement.getElementsByTagName('mat-chip').length;
      let matlistLength = this._eR.nativeElement.getElementsByTagName('mat-chip').length;
      if (matlistLength > 0) {
        this._eR.nativeElement.getElementsByTagName('mat-chip').item([matlistLength - 1]).remove();
        this.selectedOptions.splice((matlistLength - 1), 1);
        this.selectedData.splice((matlistLength - 1), 1);
      }

      this.autocompleteOptions = this.originalData.filter(x => x.name != this.selectedData.map(x => x));
      event.target.focus();
    }
    if (event.target.value == "") {
      this.autocompleteOptions = [];
    }
  }
  tapEvent(index) {
    this.removeInput();
    let newInput = this.renderer.createElement('input');
    this.renderer.setProperty(newInput, 'id', 'chipDummyInput');
    this.renderer.setProperty(newInput, 'type', 'text');
    this.renderer.setAttribute(newInput, 'autocomplete', 'off');
    this.renderer.appendChild(this._eR.nativeElement.getElementsByTagName('mat-chip')[index], newInput);
    this._eR.nativeElement.querySelector('#chipDummyInput').focus();
    this._eR.nativeElement.querySelector('#chipDummyInput').addEventListener('blur', (event) => this.removeInput());
  }
  removeInput() {
    let removeInputElement = this._eR.nativeElement.querySelectorAll('#chipDummyInput');
    if (removeInputElement.length > 0) {
      for (let a = 0; a < removeInputElement.length; a++) {
        setTimeout(() => {
          removeInputElement[a].remove();
        }, 1);
      }
    }
  }
  checkvalue() {
    if (this.selectedOptions.length > 0) {
      let checkOption = this.selectedOptions.filter(o1 => this.originalData.every(o2 => o1 != o2.name));
      if (checkOption.length > 0) {
        this.config.value = "";
        this.config.showErrorText = true;
        this.group.controls[this.FormControlName].setErrors({ 'incorrect': true, 'focused': false, 'required': true });
        this.group.controls[this.FormControlName].markAsTouched();
      }
      else {
        this.group.controls[this.FormControlName].setErrors(null);
        this.config.showErrorText = false;
        this.config.value = this.selectedOptions;
      }

    } else {
      this.config.showErrorText = true;
    }
  }

  ngOnDestroy() {
    let chipDummyInput = this._eR.nativeElement.querySelector('#chipDummyInput')
    if (chipDummyInput)
      chipDummyInput.removeEventListener('blur', () => this.removeInput());
  }






}







