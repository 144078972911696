import { AfterViewInit, Component, Input, OnDestroy, OnInit, Pipe, PipeTransform, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { buttonTypes, ContentView, PartiesSelectionCriteria, PartyNoteType, PartyState, ReservationType, SelectionType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { SettingsDTO, SettingType } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { ContactNameFilterPipe } from '@pipes/contactNameFilter.pipe';
import { EffectiveRangePartiesPipe } from '@pipes/effective-range-parties.pipe';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { cloneDeep, orderBy} from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chit-print',
  templateUrl: './chit-print.component.html',
  styleUrls: ['./chit-print.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChitPrintComponent implements OnInit, AfterViewInit, OnDestroy {

  buttonValueprimary: ButtonValue;
  buttonSecondary: ButtonValue;
  toggleConfig: FieldConfig[];
  pendingParties: any = [];
  ReservationType = ReservationType;
  isReservation: boolean;
  subscriptions: Subscription = new Subscription();
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  @Input() data: any;
  searchString: string = '';
  contentView: ContentView;
  ContentView = ContentView;
  PartiesSelectionCriteria = PartiesSelectionCriteria;
  SelectionType = SelectionType;
  showByPartySize = true;
  _settings: SettingsDTO = {} as SettingsDTO;
  seatedParties = [];
  showSeatedParties = false;
  selectedParties = [];
  includePartyNotes = false;
  includeAddons = false;
  includeGuestTypes = false;
  includeContactNotes = false;
  printOnEachPage = false;
  searchConfig: FieldConfig[];

  constructor(private _cs: CacheService, public _ps: PartyService, public _as: AppService,
              private searchStringFilter: ContactNameFilterPipe, private effectiveRangeFilter: EffectiveRangePartiesPipe,
              private _ts: TranslateService, private _pps: PopupService, private dashboardFunctions: DashboardFunctions) { }

  ngOnInit() {
    this.isReservation = (this.data.type === ReservationType.Reservation);
    this.contentView = this.data.contentView;
    this.pendingParties = cloneDeep(this._ps.Parties$.value.filter(res => res.State == PartyState.Pending));
    this.seatedParties = cloneDeep(this._ps.Parties$.value.filter(res => res.State == PartyState.Seated));
    this._ps.Parties$.value.length > 0 ? this._pps.saveBtnEnable$.next(true) : this._pps.saveBtnEnable$.next(false);
    this._settings = this._cs.settings.value;
    this.setUpSeatedPartiesConfig();
    this.setUpControlConfigs();
    this.includePartyNotes = this.enablePartyNotes();
    this.includeContactNotes = this.enableContactNotes();
    this.includeGuestTypes = this.enableGuestTypes();
    this.includeAddons = this.enableAddons();
  }

  ngAfterViewInit() {
    this.subscriptions.add(this._ps.Parties$.subscribe(val => {
      this.pendingParties = cloneDeep(val.filter(res => res.State == PartyState.Pending));
      this.seatedParties = cloneDeep(val.filter(res => res.State == PartyState.Seated));
      val.length > 0 ? this._pps.saveBtnEnable$.next(true) : this._pps.saveBtnEnable$.next(false);
    }));
    this.subscriptions.add(this._cs.settings.subscribe(sett => {
      this._settings = sett;
      this.setUpSeatedPartiesConfig();
    }));
    this.subscriptions.add(this.components.first.form.valueChanges.subscribe(val => {
      this.searchString = val.searchText;
    }));
    this.subscriptions.add(this.components.last.form.valueChanges.subscribe(val => {
      if (val.includeContactNotes || val.includePartyNotes || val.printOnEachPage || val.includeGuestTypes || val.includeAddons) {
        this.includeContactNotes = val.includeContactNotes;
        this.includePartyNotes = val.includePartyNotes;
        this.printOnEachPage = val.printOnEachPage;
        this.includeAddons = val.includeAddons;
        this.includeGuestTypes = val.includeGuestTypes;
      } else {
        this.includeContactNotes = false;
        this.includePartyNotes = false;
        this.printOnEachPage = false;
        this.includeAddons = false;
        this.includeGuestTypes = false;
      }
   
    }));
    this.setUpPrintBtn();
  }

  setUpSeatedPartiesConfig() {
    if (this.isReservation) {
      this.showSeatedParties = this._settings.General.ShowSeatedReservationsInLists;
    } else {
      this.showSeatedParties = this._settings.General.ShowSeatedWalkInsInLists;
    }
  }

  setUpControlConfigs() {
    this.buttonValueprimary = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Select All',
      customclass: 'chip-print__select-btn'
    }
    this.buttonSecondary = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Unselect All',
      customclass: 'chip-print__unselect-btn'
    }

    this.toggleConfig = [{
      type: 'switch',
      name: 'printOnEachPage',
      label: 'Print on each page',
      inputType: 'text',
      class: 'chit-print__switch',
      checked: false,
      isTranslate: true,
      value: false
    },
    {
      type: 'switch',
      name: 'includePartyNotes',
      label: 'Include notes',
      inputType: 'text',
      class: 'chit-print__switch',
      checked: this.enablePartyNotes(),
      isTranslate: true,
      value: this.enablePartyNotes()
    },
    {
      type: 'switch',
      name: 'includeContactNotes',
      label: 'Include Contact notes',
      inputType: 'text',
      class: 'chit-print__switch',
      checked: this.enableContactNotes(),
      isTranslate: true,
      value: this.enableContactNotes()
    },
    {
      type: 'switch',
      name: 'includeAddons',
      label: 'Include Addons',
      inputType: 'text',
      class: 'chit-print__switch',
      checked: this.enableAddons(),
      isTranslate: true,
      value: this.enableAddons()
    },
    {
      type: 'switch',
      name: 'includeGuestTypes',
      label: 'Include Guest Types',
      inputType: 'text',
      class: 'chit-print__switch',
      checked: this.enableGuestTypes(),
      isTranslate: true,
      value: this.enableGuestTypes()
    }];

    this.searchConfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this._ts.instant('searchByName'),
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: (event) => this.clearSearchField(event)
      }
    ];
  }

  clearSearchField(event) {
    this.searchString = '';
    this.components.first.form.get('searchText').setValue('');
  }

  partiesSelection(data, partyCriteria: PartiesSelectionCriteria, selectionType: SelectionType) {
    let tempPartiesArray = [];
    switch (partyCriteria) {
      case PartiesSelectionCriteria.allPartiesInShift:
        let effectiveRangeParties = this.effectiveRangeFilter.transform(this.pendingParties, data.EffectiveRange, ContentView.list);
        effectiveRangeParties = effectiveRangeParties.filter(res => res.State == PartyState.Pending);
        tempPartiesArray = this.searchStringFilter.transform(effectiveRangeParties, this.searchString);
        break;
      case PartiesSelectionCriteria.allSeatedParties:
        if (this.showSeatedParties) {
          tempPartiesArray = this.searchStringFilter.transform(data, this.searchString).filter(res => res.State == PartyState.Seated);

        }
        break;
      case PartiesSelectionCriteria.allParties:
        tempPartiesArray = this.searchStringFilter.transform(data, this.searchString).filter(res => res.State == PartyState.Pending);
        break;
    }

    if (selectionType == SelectionType.select) {
      tempPartiesArray.forEach(element => {
        element.selected = true;
      });
    }
    else if (selectionType == SelectionType.unselect) {
      tempPartiesArray.forEach(element => {
        element.selected = false;
      });
    }
    this.setUpSelectedParties();
  }

  selectData(client) {
    client.selected = !client.selected;
    this.setUpSelectedParties();
  }

  sortBySeatingTime(order: string) {
    if (order == 'asc') {
      this.seatedParties = orderBy(this.seatedParties, 'SeatingTime', 'asc');
    }
    else {
      this.seatedParties = orderBy(this.seatedParties, 'SeatingTime', 'desc');
    }
  }

  setUpSelectedParties() {
    this.selectedParties = [];
    let concatedParties = this.pendingParties.concat(this.seatedParties);
    let tempSelectedParties = cloneDeep(concatedParties.filter(party => (party.selected === true)));
    tempSelectedParties = this.isReservation ? orderBy(tempSelectedParties, 'ReservedFor', 'desc') : orderBy(tempSelectedParties, 'ArrivedAt', 'asc');
    this.selectedParties = tempSelectedParties;
    this.selectedParties.forEach(party => {
        party.isReservation = party.Type === ReservationType.Reservation;
      if (!party.partyNotes) {
        party.partyNotes = [];
        if (party.SpecialMealId) {
          const specialMealDetails = this._settings.SpecialMeals.filter((meal) => meal.Id === party.SpecialMealId);
          if (specialMealDetails.length > 0 && specialMealDetails[0].Name) {
            party.Notes.push({ id: party.Notes.length, name: specialMealDetails[0].Name, isSpecialMeal: true, type: PartyNoteType.SpecialMeal });
          }
        }
        if (party.Notes.length > 0) {
          let PredefinedPartyNotes = this.dashboardFunctions.getPredefinedPartyNotes();
          party.Notes.forEach((notes, index) => {
            if (notes.RelatedId) {
              const predefinedPartyNote = PredefinedPartyNotes.filter((note) => note.Id === notes.RelatedId);
              if (predefinedPartyNote.length > 0 && (notes.Type == PartyNoteType.PredefinedNote)) {
                party.partyNotes.push({ id: index, name: predefinedPartyNote[0].Text, isSpecialMeal: false });
              } else if (predefinedPartyNote.length > 0 && (notes.Type == PartyNoteType.FreeFormNote)) {
                party.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
              } else if (predefinedPartyNote.length > 0 && notes.Type == PartyNoteType.SpecialRequest) {
                party.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type });
              }
            } else if (notes.Type == PartyNoteType.FreeFormNote) {
              party.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
            } else if (notes.Type == PartyNoteType.PromoCode) {
              party.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type });
            }
          });
        }
      }
    })
    this._ps.chitPrintPartyData = this.selectedParties;
    this.setUpPrintBtn();
  }

  setUpPrintBtn() {
    this._pps.saveBtnEnable$.next(this.selectedParties.length > 0 ? true : false);
  }

  enablePartyNotes() {
    return this._settings?.MerchantSettings?.IncludeNotes?.SettingValue == SettingType.ENABLE ? true : false
  }

  enableContactNotes() {
    return this._settings?.MerchantSettings?.IncludeContactNotes?.SettingValue == SettingType.ENABLE ? true : false
  }

  enableGuestTypes() {
    return this._settings?.MerchantSettings?.IncludeCovers?.SettingValue == SettingType.ENABLE ? true : false
  }

  enableAddons() {
    return this._settings?.MerchantSettings?.IncludeAddOns?.SettingValue == SettingType.ENABLE ? true : false
  }

  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}

//Customised Pipe for PrintChit module
@Pipe({
  name: 'concatNotesFilter'
})
export class ConcatNotesFilterPipe implements PipeTransform {

  transform(notes: any[], isPartyNotes: boolean, isSpecialMeal?: boolean): string {

    if (notes.length == 0) {
      return "";
    }
    if (isSpecialMeal) {
      notes = notes.filter(note => note.isSpecialMeal == true)
    }
    return notes.map(e => isPartyNotes ? e.name : e.Text).join(", ");
  }
}
