<div [hidden]="true">
    <div id="Chit-Print_Print-Section" >
      <em class="icon-VIP" style="visibility: hidden;"></em>
      <div class="print-container__print-section">
        <div *ngFor="let party of selectedParties"
        [ngClass]="{'chit-print__print-eachPageEnabled': printOnEachPage,
                    'chit-print__print-eachPageDisabled': !printOnEachPage}">
          <div class="print-container__guest-info">
            <div>{{(party.Type === ReservationType.Reservation ? party.ReservedFor : party.ArrivedAt)| date:'dd-MM-yyyy hh:mm a'}} <em
                *ngIf="party?.Contact?.IsVip" class="icon-VIP"></em></div>
            <div>
              <span *ngIf="party?.Contact?.FirstName || party?.Contact?.LastName">{{party?.Contact?.FirstName}} {{party?.Contact?.LastName}},</span>
            </div>
            <p>{{'partyof' | translate}} {{party.Size}}</p>
            <div *ngIf="includeGuestTypes && party.CoverTypeQuantities?.length">
              (<span *ngFor="let cover of party.CoverTypeQuantities;let last = last"> {{cover.CoverTypeId | coverTypeName : _settings }} - {{cover.Covers}}
               <span *ngIf="!last">,</span>
              </span>
               )</div>
            <p *ngIf="party?.TableIds?.length > 0">Tables: {{party.TableIds | getTableNameFromId : standAloneTables}}</p>
            <p *ngIf="party?.TableIds?.length == 0">{{'noDesiredLocationText'| translate}}</p>
            <p>
              {{'visitsText' | translate}}
              {{party?.Contact?.VisitStats ? party?.Contact?.VisitStats[0].NumberOfVisits : '-'}},
              {{'noShowsText' | translate}}
              {{party?.Contact?.VisitStats ? party?.Contact?.VisitStats[0].NumberOfNoShows : '-'}}{{isReservation ? '': ','}}
              {{(isReservation ? '' : 'cancelsText') | translate}}
              {{ (isReservation ? '' : party?.Contact?.VisitStats ? party?.Contact?.VisitStats[0].NumberOfCancellations :
              '-')}}
            </p>
          </div>
          <div class="print-container__notes-section"
            *ngIf="(includeContactNotes  && party?.Contact?.Notes?.length > 0) || (includePartyNotes && party?.partyNotes?.length > 0)">
            <p *ngIf="includeContactNotes && party?.Contact?.Notes?.length > 0" class="print-container__print-notes">
              {{party.Contact.Notes | concatNotesFilter : false}}</p>
            <p *ngIf="includePartyNotes && party?.partyNotes?.length > 0" class="print-container__print-notes">{{
              (party.SpecialMealId ? 'specialMealField' : '') |
              translate}}
              {{party?.partyNotes | concatNotesFilter : true : party.SpecialMealId ? true : false}}</p>
          </div>
          <div *ngIf="includeAddons && party.BookingContactAddonItems?.length" class="chit-print__addons">
            <span>{{'addons' | translate}}</span>
            <div *ngFor="let addOn of party.BookingContactAddonItems">
             <span>{{addOn.AddonId | getAddonName : _settings}} - {{addOn.AddonCount}}</span>
            </div> 
           </div>
        </div>
      </div>
    </div>
    </div>
