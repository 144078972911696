import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShopComponenet } from './shop.component';
import { ViewCategoriesComponenet } from './view-categories/view-categories.component';
import { OrderSummaryComponenet } from './order-summary/order-summary.component';
import { ShopItemDetailsComponent } from './shop-common-components/shop-item-details/shop-item-details.component';
import { TicketDetailsComponent } from './shop-common-components/ticket-details/ticket-details.component';
import { RetailItemsComponent } from './view-categories/retail-items/retail-items.component';
import { RetailTransactionsComponent } from './view-categories/retail-transactions/retail-transactions.component';
import { RetailGiftCardsComponent } from './view-categories/retail-gift-cards/retail-gift-cards.component';
import { TransationsComponent } from './view-categories/transations/transations.component';
import { RetailTransactions, RetailBreakPoint } from '../shared/globalsContant';
import { RouteGuardService } from '../shared/service/route-gaurd.service';
import { UnpaidPlayersComponent } from './view-categories/unpaid-players/unpaid-players.component';
import { RetailRevenuePostingLogsComponent } from './view-categories/retail-revenue-posting-logs/retail-revenue-posting-logs.component';
import { RetailTransactionsLogComponent } from './view-categories/retail-transactions-log/retail-transactions-log.component';
import { OrphanPaymentsComponent } from './view-categories/orphan-payments/orphan-payments.component';
import { CanDeactivateShopGuard } from './guards/can-deactivate-shop.guard';
import { SignatureCaptureComponent } from './view-categories/signature-capture/signature-capture.component';
import { RefundLogComponent } from './view-categories/refund-log/refund-log.component';

const routes: Routes = [
  {
    path: 'viewshop',
    component: ShopComponenet,
    canActivate: [RouteGuardService],
    children: [
      { path: 'order', component: OrderSummaryComponenet },
      { path: 'shopItemDetails', component: ShopItemDetailsComponent },
      { path: 'ticketDetails/:selectedTicketNumber', component: TicketDetailsComponent },
      {
        path: '', component: ViewCategoriesComponenet,
        children: [
          { path: '', redirectTo: 'retailitems', pathMatch: 'full' },
          {
            path: 'retailitems', component: RetailItemsComponent,
            data: { frompage: 'RI', buyretailItem: ShopComponenet.getRandomDecimal(), redirectTo: '/shop/viewshop/retailtransactions', hasChild: false },
            canActivate: [RouteGuardService],canDeactivate: [CanDeactivateShopGuard]
          },
          {
            path: 'retailtransactions', component: TransationsComponent,
            data: { redirectTo: '/shop/viewshop/giftcards', hasChild: true },
            canActivate: [RouteGuardService],
            children: [
              { path: '', redirectTo: 'opentransactions', pathMatch: 'full' },
              {
                path: 'opentransactions', component: RetailTransactionsComponent, //canActivate: [RouteGuardService],
                data: { id: RetailTransactions.opentransactions, breakPointNumber: RetailBreakPoint.ReOpenTransaction, redirectTo: '/shop/viewshop/retailtransactions/correctvoid' }
              },

              {
                path: 'correctvoid', component: RetailTransactionsComponent,
                data: { id: RetailTransactions.correctvoid, redirectTo: '/shop/viewshop/retailtransactions/returnwithticket' }
              },

              {
                path: 'returnwithticket', component: RetailTransactionsComponent,
                data: { id: RetailTransactions.returnwithticket, redirectTo: '/shop/viewshop/retailtransactions/returnwithoutticket' }
              },

              {
                path: 'returnwithoutticket', component: RetailTransactionsComponent,
                data: { id: RetailTransactions.returnwithoutticket, redirectTo: '/shop/viewshop/retailtransactions/modifypostedcommission' }
              },

              {
                path: 'modifypostedcommission', component: RetailTransactionsComponent, canActivate: [RouteGuardService],
                data: { id: RetailTransactions.modifypostedcommission, breakPointNumber: RetailBreakPoint.ModifyPostedCommission, redirectTo: '/shop/viewshop/retailtransactions/reprintticket' }
              },

              {
                path: 'reprintticket', component: RetailTransactionsComponent, canActivate: [RouteGuardService],
                data: { id: RetailTransactions.reprintticket, breakPointNumber: RetailBreakPoint.ReprintTicket, redirectTo: '/shop/viewshop/retailtransactions/revenuepostingslog' }
              },
              {
                path: 'transactionslog', component: RetailTransactionsLogComponent, canActivate: [RouteGuardService]
              },
              {
                path: 'revenuepostingslog', component: RetailRevenuePostingLogsComponent, canActivate: [RouteGuardService],
                data: { id: RetailTransactions.RevenuePostingsLog, breakPointNumber: RetailBreakPoint.RevenuePostingsLog, redirectTo: '' }
              },
              {
                path: 'unsettledpayments', component: OrphanPaymentsComponent, canActivate: [RouteGuardService],
                data: { id: RetailTransactions.OrphanPayments, breakPointNumber: RetailBreakPoint.OrphanPayments, redirectTo: '' }
              },
              {
                path: 'datamaginereceiptlog', component: SignatureCaptureComponent, canActivate: [RouteGuardService],
                data: { id: RetailTransactions.DataMagineReceiptLog, breakPointNumber: RetailBreakPoint.DataMagineLog, redirectTo: '' }
              },
              {
                path: 'refundLog', component: RefundLogComponent, canActivate: [RouteGuardService],
                 data: { id: RetailTransactions.RefundLog, breakPointNumber: RetailBreakPoint.RefundFailure, redirectTo: '' }
              }
            ]
          },
          { path: 'giftcards', component: RetailGiftCardsComponent, data: { redirectTo: '', hasChild: false }, },
          {
            path: 'unpaidplayers', component: UnpaidPlayersComponent, canActivate: [RouteGuardService],
            data: { redirectTo: '', breakPointNumber: RetailBreakPoint.TeeTimePay, hasChild: false },
          }
        ]
      }
    ]
  },
  {
    path: 'viewshop/:globalslug',
    component: ShopComponenet,
    children: [
      { path: '', component: ViewCategoriesComponenet },
    ]
  },
  { path: '', redirectTo: 'viewshop', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule { }
