<mat-form-field [formGroup]="group" [hideRequiredMarker]="!config.isStarSymbolRequired"  [ngClass]="{'input-with-boundary':config.appearance}" class="multiple-select-dropdown {{config.class}}">
  <mat-label >{{config.label  | translate}}</mat-label>
  <mat-select  placeholder="{{config.placeholder | translate}}"  [formControlName]="config.name" [(value)]="config.multiSelectvalue" [attr.name]="config.name" disableOptionCentering= 'true' multiple>
      <mat-option *ngFor="let option of config.options" [value]="option.id" (click)=selectedOption()>
          <span *ngIf="config.isTranslate && !config.isTooltip">{{ option.value | translate }}</span>
          <span *ngIf="!config.isTranslate && !config.isTooltip">{{ option.value  }}</span>
          <span class="rs-Roboto-Regular-16" *ngIf="config.isTooltip" matTooltipClass="tooltip-class" title="{{option.value}}">{{ option.value  }}</span>
      </mat-option>
      <mat-option value="0" (click)="toggleAllSelection()">{{'All' | translate}}</mat-option>
  </mat-select>
  <mat-error *ngIf="group.controls[FormControlName].hasError('required') && config.showErrorText">
   Field Required
  </mat-error>
</mat-form-field>

<!-- (selectionChange)="selectedOption($event)" -->
