import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ReservationType } from '@app/shared/constants/commonenums';
import { StatusDTO } from '@app/shared/models/RestaurantDTO';
import { PartyService } from '@app/shared/services/party.service';
import _ from 'lodash';
import * as globals from '@constants/globalConstants';

@Component({
  selector: 'app-party-status-popup',
  templateUrl: './party-status-popup.component.html',
  styleUrls: ['./party-status-popup.component.scss']
})
export class PartyStatusPopupComponent implements OnChanges {
  @Input() party;
  @Output() partyStatusId = new EventEmitter();
  partyStatusBasedonType: StatusDTO[] = [];

  constructor(public partyService: PartyService) { }

  ngOnChanges(): void {
    this.partyStatusBasedonType = [];
    let defaultOption = {
      Id: -1,
      Name: 'None',
      AppliesToReservations: false,
      AppliesToWalkIns: false,
      AppliesToSeatingParties: false,
      Color: null,
      IsDefault: false
    }
    if(this.party.Type in globals.partyStatusBasedType) {
      this.partyStatusBasedonType = _.filter(this.partyService.cs.settings.value.Statuses, globals.partyStatusBasedType[this.party.Type]);
      this.partyStatusBasedonType = [defaultOption, ...this.partyStatusBasedonType];
    }
  }

  seatStatusChanged(status) {
    this.partyStatusId.emit(status?.Id != -1 ? status.Id : null);
  }

}
