<section class="redeem-multi-pack">
    <div class="popup-content-section">
        <div class="client-details-section">
            <div class="search-container">
                <div class="LW12 label-color">{{shopCaption.ClientDetails}}</div>
                <div class="radio-grp">
                    <mat-radio-group [attr.automationId]="'Rdo_redeemMultiPack_clientDetails'" class="golf__radio"
                        [(ngModel)]="clientSelect" (change)="clientTypesChange($event)"
                        [attr.automationId]="'Rdo_redeemMultiPack_clienttype'">
                        <mat-radio-button class="golf--form-radio-button" name="clienttype" [value]="type.id"
                            *ngFor="let type of clientTypes">{{type.type}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="selectedPayee != null">
                    <div class="selected-guest-details">
                        <div class="txtgray d-block" [title]="selectedPayee.name" *ngIf="selectedPayee.name">
                            <b>{{selectedPayee.name}}</b>
                        </div>
                        <div class="LW12 mb-2 email-view" [title]="selectedPayee.emailId" *ngIf="selectedPayee.emailId">
                            <i aria-hidden="true" class="client-icons icon-mail pl-1 pr-3"></i>{{selectedPayee.emailId}}
                        </div>
                        <div class="LW12" *ngIf="selectedPayee.phoneNumber" [title]="selectedPayee.phoneNumber">
                            <i aria-hidden="true"
                                class="client-icons icon-Phone pl-1 pr-3"></i>{{selectedPayee.phoneNumber}}
                        </div>
                    </div>
                </div>
                <div class="search-bar">
                    <retail-chips-autocomplete-example [Datalimit]="1" (messageEvent)="receiveMessage($event)"
                        (selectedEvent)="selectPayeeFromSearch($event)" (removedCompleteSearch)='removeFromSearchBar()'
                        (removedEvent)="removeFromSearchBar()"
                        [automationId]="'redeem-multi-pack'"></retail-chips-autocomplete-example>
                </div>
            </div>
        </div>
        <div class="no-multipacks-available-banner" *ngIf="allAvailableMultipacks.length == 0">
            <app-retail-no-data-found [isSearch]='false'></app-retail-no-data-found>
        </div>
        <div class="multipack-selection-section" *ngIf="allAvailableMultipacks.length > 0">
            <div class="d-flex titlelbl">
                <div class="section-header">{{captions.lbl_multiPackAvailable}}</div>
                <div class="ln-ht10 toggle-cont">
                    <div class="exp-lbl">{{captions.lbl_showExpired}}</div>
                    <app-retail-toggle-switch [attr.automationId]="'Txt_redeemMultiPack_showExpired'"
                        [formControl]="showExpired" class="toggle-switch"
                        (changeToggleEvent)="includeExpiredMultiPacks($event[0])">
                    </app-retail-toggle-switch>
                </div>
            </div>
            <div class="selected-container" *ngIf="multipackResult.length > 0">
                <div class="d-flex multipack-card" *ngFor="let multipack of multipackResult">
                    <div class="selection" [class.disabled]="multipack.isExpired"><i class="icon-confirmed"
                            [class.selected]="multipack.isSelected" (click)="SelectMultiPack(multipack)"></i></div>
                    <div class="d-flex multipack-info">
                        <div class="multipack-name">{{multipack.name}}</div>
                        <div>
                            <div>{{captions.lbl_soldTo}}</div>
                            <div><b>{{multipack.clientName}}</b></div>
                        </div>
                        <div>
                            <div>{{captions.lbl_soldOn}}</div>
                            <div><b>{{multipack.soldDate}}</b></div>
                        </div>
                        <div>
                            <div>{{captions.lbl_validUpto}}</div>
                            <div><b>{{multipack.expiryDate}}</b></div>
                        </div>
                        <div class="last-col">
                            <div>{{captions.lbl_remainingSessions}}</div>
                            <div *ngIf="multipack.remainingCount != -1"><b>{{multipack.remainingCount}}</b></div>
                            <div *ngIf="multipack.remainingCount == -1"><b>{{shopCaption.GiftCard.NA}}</b></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions class="actions-section">
        <button mat-button class="body-bgcolor whitecolor text-capital" (click)="RedeemMultiPack()"
            [ngClass]=" EnableBtn ? 'button_valid' : 'button_invalid'" [disabled]="!EnableBtn">
            {{captions.Redeem}}</button>
        <button mat-button class="LW14 ml-2 text-capital" (click)="OnCancel()">{{shopCaption.cancel}}</button>
    </mat-dialog-actions>
</section>