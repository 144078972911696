<div class="chit-print">
  <label class="seat-header-two" for="">{{'selectParties' | translate}}</label>
  <span class="chit-print__search-box">
    <dynamic-form [config]="searchConfig" #form="dynamicForm">
    </dynamic-form>
  </span>
  <div>
    <label class="chit-print__date-view">{{_as._headerDate | localizedDate : 'D MMMM, dddd'}}</label>
  </div>
  <div>
    <label *ngIf="isReservation">{{'reservationMenuText' | translate}}</label>
  </div>
  <div class="chit-print__parties-view seat-border-color">
    <ng-scrollbar>
      <div class="chit-print__party-data" *ngIf="(_ps.Parties$ | async)?.length > 0">
        <div *ngIf="isReservation">
          <div *ngFor="let shift of _ps.shifts$ | async ;let i= index">
            <div class="chit-print__control-section"
              *ngIf="(pendingParties | effectiveRangeParties: shift.EffectiveRange: ContentView.list | contactNameFilter: searchString).length > 0">
              <label>{{shift.Name}}</label>
              <activities-app-button [buttontype]="buttonSecondary"
                (click)='partiesSelection(shift, PartiesSelectionCriteria.allPartiesInShift, SelectionType.unselect)'
                class="chit-print__select-btn">
              </activities-app-button>
              <activities-app-button [buttontype]="buttonValueprimary"
                (click)='partiesSelection(shift, PartiesSelectionCriteria.allPartiesInShift, SelectionType.select)'
                class="chit-print__unselect-btn">
              </activities-app-button>
            </div>
            <div
              *ngFor="let item of pendingParties | effectiveRangeParties: shift.EffectiveRange: ContentView.list  | sortReservations : showByPartySize : ReservationType.Reservation : _settings | orderBy: 'ReservedFor' | groupBy: 'ReservedFor'| pairs ;let i= index;">
              <label *ngIf="(item[1] | contactNameFilter: searchString).length > 0"
                class="chit-print__time-display">{{item[0] | localizedDate:'LT'}}</label>
              <div class="chit-print__data-section" *ngIf="(item[1] | contactNameFilter: searchString).length > 0">
                <div class="chit-print__client-data seat-border-color" (click)="selectData(client)"
                  *ngFor="let client of item[1] | contactNameFilter: searchString "
                  [ngClass]="{'chit-print__selected-border': client.selected == true}">
                  <div class="chit-print__client-name" matTooltipClass="tooltip-class"
                    title="{{client?.Contact | formattedName}}">
                    <span *ngIf="client?.Contact?.FirstName">{{client?.Contact | formattedName}}</span>
                    <span *ngIf="!client?.Contact?.FirstName">{{client?.Contact | formattedName}}</span>
                  </div>
                  <div class="chit-print__client-covers">{{'coversText' | translate}} {{client.Size}}</div>
                  <svg id="svg" class="checkmark position-absolute" *ngIf="client.selected == true" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!isReservation">
          <div class="chit-print__control-section">
            <label>{{'waitListText' | translate}}</label>
            <activities-app-button [buttontype]="buttonSecondary"
              (click)='partiesSelection(pendingParties, PartiesSelectionCriteria.allParties, SelectionType.unselect)'
              class="chit-print__select-btn">
            </activities-app-button>
            <activities-app-button [buttontype]="buttonValueprimary"
              (click)='partiesSelection(pendingParties, PartiesSelectionCriteria.allParties, SelectionType.select)'
              class="chit-print__unselect-btn">
            </activities-app-button>
          </div>
          <div class="chit-print__data-section">
            <div class="chit-print__client-data seat-border-color" (click)="selectData(res)"
              *ngFor="let res of pendingParties | sortReservations : showByPartySize : ReservationType.Waitlist : _settings | contactNameFilter: searchString;let i= index"
              [ngClass]="{'chit-print__selected-border': res.selected == true}">
              <div class="chit-print__client-name" matTooltipClass="tooltip-class"
                title="{{res?.Contact | formattedName}}">
                <span *ngIf="res?.Contact?.FirstName">{{res?.Contact | formattedName}}</span>
                <span *ngIf="!res?.Contact?.FirstName">{{res?.Contact | formattedName}}</span>
              </div>
              <div class="chit-print__client-covers">{{'coversText' | translate}} {{res.Size}}</div>
              <svg id="svg" class="checkmark position-absolute" *ngIf="res.selected == true" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
            </div>
          </div>
        </div>
        <div *ngIf="showSeatedParties && seatedParties?.length">
          <div class="chit-print__control-section">
            <label>{{'seatedPartiesText' | translate}}</label>
            <span class="chit-print__sort-sec">
              <i class="icon-Sort-Up chit-print__sort-icon" (click)="sortBySeatingTime('asc')"></i>
              <i class="icon-Sort-Down chit-print__sort-icon" (click)="sortBySeatingTime('desc')"></i>
            </span>
            <activities-app-button [buttontype]="buttonSecondary"
              (click)='partiesSelection(seatedParties, PartiesSelectionCriteria.allSeatedParties, SelectionType.unselect)'
              class="chit-print__select-btn">
            </activities-app-button>
            <activities-app-button [buttontype]="buttonValueprimary"
              (click)='partiesSelection(seatedParties, PartiesSelectionCriteria.allSeatedParties, SelectionType.select)'
              class="chit-print__unselect-btn">
            </activities-app-button>
          </div>
          <div class="chit-print__data-section">
            <div class="chit-print__client-data seat-border-color" (click)="selectData(seatedParty)"
              *ngFor="let seatedParty of seatedParties | sortReservations : showByPartySize : isReservation ? ReservationType.Reservation : ReservationType.Waitlist : _settings | contactNameFilter: searchString"
              [ngClass]="{'chit-print__selected-border': seatedParty.selected == true}">
              <div class="chit-print__client-name" matTooltipClass="tooltip-class"
                title="{{seatedParty?.Contact | formattedName}}">
                <span *ngIf="seatedParty?.Contact?.FirstName">{{seatedParty?.Contact | formattedName}}
                  </span>
                <span *ngIf="!seatedParty?.Contact?.FirstName">{{seatedParty?.Contact | formattedName}}</span>
              </div>
              <div class="chit-print__client-covers">{{'coversText' | translate}} {{seatedParty.Size}}</div>
              <svg id="svg" class="checkmark position-absolute" *ngIf="seatedParty.selected == true"
                viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(_ps.Parties$ | async)?.length == 0" class="chit-print__empty-reservations">
        <img src="{{_ps.noReservationImgUrl}}" alt="">
        <span class="chit-print__empty-reservations-text">{{'noPartiesAvailable' | translate}}</span>
      </div>
    </ng-scrollbar>
  </div>
  <div class="chit-print__notes-view seat-border-color">
    <dynamic-form [config]="toggleConfig" #form="dynamicForm">
    </dynamic-form>
  </div>
  <app-print-container [selectedParties]="_ps.chitPrintPartyData" [printOnEachPage]="printOnEachPage"></app-print-container>
</div>
