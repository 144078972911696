import { Pipe, PipeTransform } from '@angular/core';
import { PartyPrepaymentState } from '../models/InputContact';
import { PartyState } from '../constants/commonenums';

@Pipe({
    name:'partyPaymentState'
})
export class PartyPaymentStatePipe implements PipeTransform {
    constructor(){}
    transform(attendee : any):any{
        let paymentState = attendee.PrepaymentState;
        let state = '';
        let dueState = [PartyPrepaymentState.PartialPrepayment ,PartyPrepaymentState.PaymentDuePending ];
        let paidState = [PartyPrepaymentState.DepositCollected , PartyPrepaymentState.Prepaid ];
        let refundState = [PartyPrepaymentState.Refunded, PartyPrepaymentState.CancellationFeeApplied];
        
        if((paymentState == PartyPrepaymentState.None 
                || (paymentState == PartyPrepaymentState.Refunded && attendee.State !== PartyState.NoShowed && attendee.State !== PartyState.Cancelled)) 
            &&  ((attendee.partyAmount < 0 || attendee.NegotiatedAmount < 0) && !attendee.PaidAmount)){
            state = 'Waived__Off';
        }else if(paymentState == PartyPrepaymentState.PrepaymentRequired && attendee.State !== PartyState.Cancelled){
            state = 'Not-Paid'
        }
        else if(paymentState == PartyPrepaymentState.None 
            || (paymentState == PartyPrepaymentState.PrepaymentRequired && attendee.State === PartyState.Cancelled)){
            state = 'NA'
        }else if(dueState.includes(paymentState)){
            state = 'Due'
        }else if(paidState.includes(paymentState)){
            state = 'Paid'
        }else if(refundState.includes(paymentState)){
            state = 'Refunded'
        }else if(paymentState == PartyPrepaymentState.RefundDuePending){
            state = 'Refund__pending'
        }
        return state;
    }
}