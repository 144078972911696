import { Component, OnInit, Input, ElementRef, ViewEncapsulation, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import * as myGlobals from '../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import * as _ from 'lodash';
import { RetailLocalization } from '../common/localization/retail-localization';
import { UserAlerts } from '../common/config/alerts-config';
import { AlertMessagePopupComponent } from '../shared/alert-message-popup/alert-message-popup.component';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';

@Component({
  selector: 'app-retail-table-inline-edit',
  templateUrl: './retail-table-inline-edit.component.html',
  styleUrls: ['./retail-table-inline-edit.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class RetailTableInlineEditComponent implements OnInit {
  @Input() options;
  @Input() isRoleSetUpReadOnly;
  @Input() EnableEdit : boolean []=[];
  @Input() searchString:string;
  @Output() afterEditClose: EventEmitter<any> = new EventEmitter();
  @Output() dragDropEvt: EventEmitter<any> = new EventEmitter();
  @Output() deleteEvt: EventEmitter<any> = new EventEmitter();
  @Output() editEvt: EventEmitter<any> = new EventEmitter();
  @Output() InActiveTherapistEvt: EventEmitter<any> = new EventEmitter();
  @Output() RowSelectEmitter: EventEmitter<any> = new EventEmitter();
  @Output() userActionEvt: EventEmitter<any> = new EventEmitter();
  @Output() CurrentRowSelectedEmitter: EventEmitter<any> = new EventEmitter();
  @Output() toggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() addClick: EventEmitter<any> = new EventEmitter();
  @Output() dropDownChange: EventEmitter<any> = new EventEmitter();
  @Output() printEvt: EventEmitter<any> = new EventEmitter();
  @Output() inactiveToggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() doneEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() userEditing: EventEmitter<any> = new EventEmitter();
  @Output() cancelEdit:EventEmitter<any>= new EventEmitter();
  @Output() LBLClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() radioClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openWaitlist: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('tableInput') tableInput: ElementRef;
  @Output() FieldEditEmitter: EventEmitter<any> = new EventEmitter();
  @Output() DeleteData: EventEmitter<any> = new EventEmitter();

  SelectedData: any = [];
  valueChanged:boolean=false;
  sortingColoumn: any;
  setinterscroll: any;
  editEvent: any;
  InActiveTherapistChkBoxEvt: any;
  orderTypearr: any = [];
  orderType: any = 'asc';
  hdrArray: any = [];
  bodyArray: any = [];
  selectedDefaultHeader: any;
  searchText: any;
  hdrkeyArray: any = [];
  originalHdrKeyArray: any =[];
  Sortable: any;
  highlight: any;
  NewerData: any;
  currentPage: any = 1;
  givenOrderType: any;
  EditMoreOption: any;
  defaulSortColumn: any;
  dropdownOptions: any = []
  captions: any = {};
  disableDelete: any;
  toggleDisplayText: any;
  editableRow: any;
  tempTableData: any;
  editRecordsArray: any = [];
  editingData:any;

  SelectRow: boolean = false;
  enableToggleButton: boolean = false;
  IfBooleanCheck:boolean = false;
  SetColumnGridValue:boolean = false;
  CheckInnerBoolValue:boolean = false;
  enableRowCheck:boolean = false;
  IsViewOnly:boolean=false;
  IsCheckAll: boolean = false;
  EnableActions: boolean;
  EnablePagination: boolean = true;
  ChkInactiveService: boolean = false;
  blnDraggable: boolean = true;
  editable: boolean = true;
  waitlistIcon:boolean = false;
  customHeader: boolean = false;
  sticky: boolean = false;
  DoneCancel: boolean = false;
  viewCheckedFlag: boolean = false;
  IsRetailCodeSetup:boolean;
  disableEditButton: boolean; //this boolean will prevent certain screens to show Edit button during view only break point applied.
  isValidRoleName: boolean = false;
  isEdit: boolean;
  isReadOnly: boolean = false;
  isDataLoading: boolean = false
  isCancelAvailable: boolean;

  SelectedSettingId: number = 1;
  inputLength: number;
  copyOfTableData:any=[];
  copyOfSearchData:any=[];
  copyOfData:any=[];
  PlaceHoldertext: string;
  overriddenSortColumn: string;
  sortColumnDataType: string;
  pageTitle: string;
  customHeaderButton: string;
  setupName: string;
  errorMessage: string;
  newObject:any;
  outlets: UntypedFormGroup;
  quicksale: UntypedFormGroup;
  measures: UntypedFormGroup;
  table: UntypedFormGroup;
  formGroupName: UntypedFormGroup;

  GridType = myGlobals.GridType;
  RetailTransactions = myGlobals.RetailTransactions;
  defaultMin = 1;
  defaultMax = 1;
  isEditModeSet: boolean=false;
  edittingIndex : any;
  _disableAction: boolean;
  maxLength = myGlobals.Maxlength.PERCENTAGE;
  totalMsg: string;
  ShowNoOfRecordInGrid = myGlobals.RetailFunctionalities.ShowNoOfRecordInGrid;
  functionalities: { [key: string]: boolean } = {};
  automationId: String = '';
  @Input('disableActions')
  set disableActions(value) {
    this._disableAction = value;
  }
  constructor( public dialog: MatDialog, private userAlerts:UserAlerts, public el: ElementRef,public fb: UntypedFormBuilder, public localization: RetailLocalization, private _cdRef:ChangeDetectorRef,private utilities:RetailUtilities, private func: RetailFunctionalityBusiness) {
    this.table = this.fb.group({
      IsCheckAll : false,
      tablebody: this.fb.array([this.fb.group({id:'',activetoggle:false, donecancel:false, category:""})])
    });
    this.outlets = this.fb.group({
      setupCodeName: ['', Validators.required],
      activetoggle: [false]
    });
    this.measures = this.fb.group({
      setupCodeName: ['', Validators.required],
      activetoggle: [false]
    });
    this.quicksale = this.fb.group({
      setupCodeName: ['', Validators.required],
      activetoggle: [false]
    });
    this.captions = this.localization.captions;
    this.customHeaderButton = this.localization.captions.setting.Add;
    this.enableCancelButton();
  }

  ngOnChanges() {
    this.editRecordsArray = [];
    this.viewCheckedFlag = false;
    this.table.value.tablebody = this.CreateTablerowFormGroup(this.options);
    this.tempTableData = this.options;
    this.IsCheckAll = false;
    if (typeof this.options == "undefined" || !this.options[0]) { this.isDataLoading = true; return; }
    this.isDataLoading = false;
    this.orderTypearr = [];
    this.defaulSortColumn = this.options[0] && this.options[0].Sortable;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = (this.selectedDefaultHeader && !this.options[0].isInitial) ? this.selectedDefaultHeader : temp;
    this.isReadOnly= this.options[0].IsReadOnly ? true : false;
    this.selectedDefaultHeader = this.Sortable;
    this.SelectRow = this.options[0].SelectRows;
    this.DoneCancel = this.options[0].DoneCancel;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    this.blnDraggable = this.options[0].TableDraggable;
    this.editable = this.options[0].Editable != undefined ? this.options[0].Editable : true;
    this.waitlistIcon = this.options[0].waitlistIcon != undefined ? this.options[0].waitlistIcon : false;
    this.SelectedSettingId = this.options[0].SelectedSettingId;
    this.sticky = this.options[0].sticky ? this.options[0].sticky: false;
    this.hdrArray = this.options[0].TableHdrData;
    this.originalHdrKeyArray = this.options[0].TableHdrData;
    this.bodyArray = [];
    this.bodyArray = this.options[0].TablebodyData;
    this.copyOfTableData=this.bodyArray;
    this.copyOfSearchData=this.bodyArray;
    this.automationId = this.options[0].automationId;
    if(this.searchString!=null &&  this.searchString.length>0){
      this.searchString=this.searchString.toLowerCase();
      this.bodyArray= this.copyOfSearchData.filter(value=>value.category_name.toLowerCase().includes(this.searchString));
      this.copyOfTableData=this.bodyArray;
    }else{
      this.bodyArray=this.copyOfSearchData;
      this.copyOfTableData=this.bodyArray;
    }
    this.SelectedData = []; 
    let index=0;
    this.EnableEdit=[];
    this.bodyArray.forEach(element => {
      this.EnableEdit.push(false);
      if (element.checked != null && element.checked)
        this.SelectedData.push(element);
      index++;
    });
    this.NewerData = this.options[0].NewData;
    this.NewerData = this.bodyArray.filter(o => {
      if (this.NewerData) {
        return ((o.code && o.code == this.NewerData.code) || (o.name && o.name == this.NewerData.name) || (o.addOnName && o.addOnName == this.NewerData.addOnName))
      }
    })
    if (this.NewerData[0]) {
      this.setinterscroll = setInterval(() => {
        const tempInterval = (this.NewerData[0] ? this.NewerData[0].id : '');
        let curiddata = this.NewerData ? tempInterval : '';
        this.autoscrolltocurrtime(curiddata);
      }, 500);

    }
    this.searchText = this.options[0].TableSearchText ? this.options[0].TableSearchText : this.searchText;
    this.hdrkeyArray = [];
    if(this.hdrArray){
      for (let item of this.hdrArray) {
        if (item.searchable != undefined ? item.searchable : true)
          this.hdrkeyArray.push(item.jsonkey);
      }
    }
    this.PlaceHoldertext = this.options[0].PlaceHoldertext;
    this.EnableActions = this.options[0].EnableActions;
    this.disableDelete = this.options[0].disableDelete;
    this.EnablePagination = this.options[0].pagination;
    this.ChkInactiveService = this.options[0].InactiveService;
    this.EditMoreOption = this.options[0].EditMoreOption;
    this.customHeader = this.options[0].customHeader;
    this.pageTitle = this.options[0].pageTitle;
    this.setCodeSetupValues();
    this.dropdownOptions = this.options[0].dropdownOptions;

    // Load Only active services
    if (this.ChkInactiveService)
      this.InactiveService({ checked: false });
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    let overriddenSortColumn = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumn : null;
    let SortColumnDataType = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumndatatype : null;
    this.sortingFunc(this.selectedDefaultHeader, this.sortingColoumn, 'change', overriddenSortColumn, SortColumnDataType);
    this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    setTimeout(this.customtablealignment.bind(this), 1);
    this.IsViewOnly = this.options[0].IsViewOnly;
    this.disableEditButton = this.options[0].disableEditButton;
    this.IsRetailCodeSetup = (this.options[0].ServiceId == 'quicksale'|| this.options[0].ServiceId == 'measures' ||this.options[0].ServiceId == 'outlets' );
    this.calwidthwithtimeout();
    if(this.functionalities[myGlobals.RetailFunctionalities.ShowNoOfRecordInGrid]){
      this.totalMsg = this.localization.replacePlaceholders(this.captions.lbl_totalRecordsFoundMsg, ['count'], [this.bodyArray?.length]);
    }
  }

  ngOnInit() {
    if (typeof this.options == "undefined" || !this.options[0]) { return; }

    this.defaulSortColumn = this.options[0].Sortable;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = this.selectedDefaultHeader ? this.selectedDefaultHeader : temp;
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    this.selectedDefaultHeader = this.Sortable;
    this.orderTypearr = [];
    this.inputLength = 250;
  }

  async ngAfterViewInit(){
    this.functionalities = await this.func.getRetailFunctionality();
    this.calwidthwithtimeout();
    this._cdRef.detectChanges();
  }

  ngAfterViewChecked() {
    if(!this.viewCheckedFlag) {
      this.viewCheckedFlag = true;
      this.calwidthwithtimeout();
      this.customtablealignment();
    }
    this._cdRef.detectChanges();
  }

  sortingFunc(dh, i, from, overriddenSortColumn, sortColumnDataType) {
    if(!this.isEditModeSet){
      if(this.editRecordsArray.length > 0) {
        let dialogRef = this.openAlert();
        dialogRef.afterClosed().subscribe(result => {
          if(result == 'Yes') {
            this.Cancel(this.editRecordsArray[0].data, this.editRecordsArray[0].index);
            this.editRecordsArray = [];
            this.sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from);
          }
        });
      }
      else {
        this.sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from);
      }
    }
  }

  openAlert () {
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.captions.common.Warning,  headerIcon: 'icon-warning-icon',  headerMessage: this.captions.common.saveChangesMessage , buttonName: this.captions.common.Yes, noButton: true, noButtonName: this.captions.common.No, type: 'message'
      },
      disableClose: true,
    }); 
  }

  onDragOver(event, ele, newarr) {
    event.preventDefault();
  }

  onItemDrop(event, ele, newarr) { 
    let draggeddata = event.dragData;
    let droppeddata = ele;
    let dragindex = newarr.indexOf(draggeddata);
    let dropindex = newarr.indexOf(droppeddata);
    let dragLstOrder: listOrder = draggeddata;
    let dropLstOrder: listOrder = droppeddata;
    this.dragDropEvt.emit([dragLstOrder.listOrder, dropLstOrder.listOrder, this.SelectedSettingId, this.InActiveTherapistChkBoxEvt]);
    newarr.splice(dragindex, 1);
    newarr.splice(dropindex, 0, draggeddata);
  }

  allowDrop(event, ele, newarr) {
    let draggeddata = event.dragData;
    let dragindex = newarr.indexOf(draggeddata);
    newarr.splice(dragindex, 1);
  }

  checkValid(event){
    if(event && event.target && event.target.value.trim()!== '')
    {
    this.isValidRoleName = true;
    }
    else
    {
      this.isValidRoleName = false;
    }
  }

  searchInputValue(event) {
    this.searchText = event;
    this.viewCheckedFlag = false;
  }

  CreateTablerowFormGroup(optionsData) {
    let bodyArr: any = this.table.get('tablebody') as UntypedFormArray;
    bodyArr.controls = [];
    bodyArr.value = [];

    if (optionsData.length>0) {
      for(let i = 0; i < optionsData[0].TablebodyData && optionsData[0].TablebodyData.length; i++) {
        let optionsValue = optionsData[0].TablebodyData[i];
        const tempActToggle = (optionsValue.active ? optionsValue.active : false);
        bodyArr.push(this.fb.group({
          id: optionsValue.id,
          activetoggle: optionsValue.isActive ? (optionsValue.isActive) : tempActToggle,
          donecancel: this.fb.control(false),
          category: this.fb.control(optionsValue.category),
          rowDisabled: this.editEvent && this.editEvent.id == optionsValue.id ? this.editEvent.rowDisabled : false
        }))
      }
    }
    return bodyArr;
  }

  setMatformWidth(myElement) {
    if (this.tableInput) {
        let minWidth = myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0]?myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0]['offsetWidth']: 300; //min-300 max-470
        minWidth += myElement.getElementsByClassName('search-container')[0]?myElement.getElementsByClassName('search-container')[0]['offsetWidth']: 0;
        minWidth += myElement.getElementsByClassName('table-toggle-switches')[0]?myElement.getElementsByClassName('table-toggle-switches')[0]['offsetWidth']: 0;
        minWidth += myElement.getElementsByClassName('custom-retail-inputs')[0]?myElement.getElementsByClassName('custom-retail-inputs')[0]['offsetWidth']: 0;
        return minWidth;
    }
  }

  calculateWidth() {
    Array.from(document.querySelectorAll('#SPACustomTable>ng-scrollbar>.ng-scrollbar-container>.ng-scrollbar-view>table')).forEach((table, index) => {
      if (table) {
        let tableHeight = table['offsetHeight'];
        let parentHeight = table.closest("#fixed-table-container")['offsetHeight'];
        if (parentHeight > tableHeight)
          table.closest("#SPACustomTable")['style']['height'] = tableHeight + 2 + 'px';
        else if (parentHeight < tableHeight)
          table.closest("#SPACustomTable")['style']['height'] = parentHeight + 'px';
      }
    })
    let searchClass = document.getElementsByClassName('retail-CustomDataTable');
    for (let i = 0; i < searchClass.length; i++) {
      let pageHeader = searchClass[i].getElementsByClassName('page-header')[0] ? searchClass[i].getElementsByClassName('page-header')[0]['offsetWidth'] : 0;
      let searchInput = searchClass[i].getElementsByClassName('searchpt')[0];
      if (pageHeader > 0) {
        pageHeader = pageHeader - this.setMatformWidth(searchClass[i]) - 60;
      }
      let inputLength = this.tableInput ? this.tableInput.nativeElement.getAttribute('data-placeholder').length : 1;
      let inputWidth = inputLength <= 30 ? inputLength * 10 : inputLength * 7.5 + 20;
      if (searchInput && pageHeader > 0) {
        searchInput['style'].width = (pageHeader > inputWidth) ? inputWidth + 'px' : pageHeader + 'px';
      }
    }
  }

  setCodeSetupValues() {
    let retailsetup = this.captions.retailsetup;
    switch(this.pageTitle) {
      case 'outlets':
        this.assignSetupValues(this.outlets, retailsetup.OutletName, retailsetup.MissingOutletName);
        break;
      case 'quicksale':
        this.assignSetupValues(this.quicksale, retailsetup.QuickSaleCategory, retailsetup.MissingQuickSaleCategory);
        break;
      case 'measures':
        this.assignSetupValues(this.measures, retailsetup.UnitName, retailsetup.MissingUnitName);
        break;
    }
  }

  assignSetupValues (formName: UntypedFormGroup, setupName: string, errorMessage: string) {
    this.formGroupName = formName;
    this.setupName = setupName;
    this.errorMessage = errorMessage;
  }

  customtablealignment() {
    let dropdown: any = [];
    let groupArray: any = {
      "outlets": {
        "outletName": "25",
        "terminalId": "25",
        "active": "25",
        "outletNumber": "25"
      },
      "quicksale": {
        "checkbox": "0",
        "desc": "30",
        "itemid": "20",
        "temp": "10",
        "category": "15"
      },
      "retail": {
        "category": "10"
      }
    };
    this.hdrArray.forEach(element => {
      groupArray.retail[element.jsonkey] = "10";
    });
    groupArray.retail["actions"] ="10";
    switch (this.SelectedSettingId) {
      case myGlobals.GridType.outlet:
        dropdown = (Object.values(groupArray.outlets));
        break;
      case myGlobals.GridType.retail:
        dropdown = (Object.values(groupArray.retail));
        break;
      case myGlobals.GridType.quickSale:
        dropdown = (Object.values(groupArray.quicksale));
        break;
    } 
    let overallgivenwidth = 0;
    let ga;
    if (dropdown) {
      let parentWidth = document.getElementById('SPACustomTable') && document.getElementById('SPACustomTable').offsetWidth;
      for (ga = 0; ga < dropdown.length; ga++) {
        if (document.getElementsByTagName('th')[ga]) {
          document.getElementsByTagName('th')[ga].style.width = Object.values(dropdown)[ga] + '%';
        }
        overallgivenwidth += Number(dropdown[ga]);
      }
      if (dropdown && this.bodyArray.length > 0) {
        let tablerow = document.getElementsByTagName('tr');
        for (let i = 1; i <= this.bodyArray.length; i++) {
          if (tablerow[i]) {
            for (let j = 0; j < dropdown.length; j++) {
              if (document.getElementsByTagName('tr')[i].cells[j]) {
                if (document.getElementsByTagName('tr')[i].cells[j].querySelectorAll("#content") && document.getElementsByTagName('tr')[i].cells[j].querySelectorAll("#content").length > 0) {
                  document.getElementsByTagName('tr')[i].cells[j].querySelectorAll("#content")[0]['style'].maxWidth = parentWidth * (Number(Object.values(dropdown)[j]) / 100) + 'px';
                }
              }
            }
          }
        }
      }
    }
  }

  setWidth(coloumncount, count, width, maxwidth) {
    document.getElementsByTagName('th')[coloumncount - count].style.width = width;
    document.getElementsByTagName('td')[coloumncount - count].style.maxWidth = maxwidth;
  }

  resetForm() {
    this.outlets.reset();
    this.measures.reset();
    this.quicksale.reset();
    this.customHeaderButton = this.localization.captions.setting.Add;
    this.enableCancelButton();
  }

  enableDoneCancel(index, rowData) {
    this.table.value.tablebody[index].donecancel = true;
    let data = { 'value': event, 'data': rowData }
    this.inactiveToggleEvtEmitter.emit(data);
    this.isValidRoleName = true;
  }

  Done(rowData, index){
    this.editRecordsArray = [];
    this.enableToggleButton = false;
    this.editEvent = {};
    let editedData=this.copyOfTableData[index];

      this.EnableEdit[index]=false;
      this.doneEvtEmitter.emit([editedData,index]);
      this.valueChanged=false;
      this.changeEditMode(false);

  }

  lblclick(rowData, index, clickable){
    if(clickable=='clickable') {
      this.LBLClickEvtEmitter.emit(rowData);
      return;
    }else{
      return false;
    }
  }

  Cancel(rowData, index){
    console.log('Temp Table data',this.tempTableData[0].TablebodyData.filter(x=>x.id == rowData.id)[0]);
    this.bodyArray[index]=this.editingData;
    this.EnableEdit[index]=false;
    this.valueChanged=false;
    this.userEditing.emit(false);
    this.changeEditMode(false);
  }

  onButtonClick() {
    let belonTo = this.options[0].ServiceId;
    let type = this.customHeaderButton;
    this.editEvent = {};
    this.editRecordsArray = [];
    let data = {}
    switch (belonTo) {
      case 'outlets': data = { 'value': this.outlets, 'type': type }
        break;
      case 'measures': data = { 'value': this.measures, 'type': type }
        break;
      case 'quicksale': data = { 'value': this.quicksale, 'type': type }
        break;
      default:data = { 'value': '', 'type': type }
    }
    this.enableToggleButton = false;
    this.addClick.emit(data); 
    this.resetForm();
  }

  onButtonCancelClick(event){
    if(document.getElementsByClassName('rowDisabled').length > 0){
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
    }
    this.enableToggleButton = false;
    this.resetForm();
  }

  RowSelect(event, SelectedRow, Frm) {
    if (Frm == 'All') {
      if (event.checked) {
        this.SelectedData = [];
        for (let item of SelectedRow) {
          this.SelectedData.push(item);
        }
      }
      else {
        this.SelectedData = _.difference(this.SelectedData, this.bodyArray);
      }
    }
    else {
      if (this.SelectedData.indexOf(SelectedRow) == -1) {
        this.SelectedData.push(SelectedRow);
      } else {
        this.SelectedData.splice(this.SelectedData.indexOf(SelectedRow), 1);
        let checkedRow = this.bodyArray.findIndex(item => item.id == SelectedRow.id);
        this.bodyArray[checkedRow].checked = false;
      }
      this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    }
    this.RowSelectEmitter.emit(this.SelectedData);
    this.CurrentRowSelectedEmitter.emit({ "event": event, "SelectedRow": SelectedRow, "From": Frm });
  }

  InactiveService(e) {
    if (e.checked) {
      this.bodyArray = [];
      this.bodyArray = this.options[0].TablebodyData;
    } else {
      let tempArr = [];
      this.bodyArray.forEach(row => {
        if (row.isActive)
          tempArr.push(row);
      });
      this.bodyArray = tempArr;
    }
  }

  openAlertPopup (currentIndex,editingIndex) {
    let dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.captions.common.Warning,  headerIcon: 'icon-warning-icon',  headerMessage: this.captions.common.saveChangesMessage , buttonName: this.captions.common.Yes, noButton: true, noButtonName: this.captions.common.No, type: 'message'
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result == 'Yes')
      {
        this.EnableEdit[editingIndex]=!this.EnableEdit[editingIndex];
        this.EnableEdit[currentIndex]=!this.EnableEdit[currentIndex];
      } 
    })
    this.valueChanged=false;
    return dialogRef;
  }
 
  LoadDecimalValue(val: any): string {
 
    let result: any = this.localization.localizeCurrency(val,false);
    return result;
  }

  sortHeader (dh, overriddenSortColumn, sortColumnDataType, i, from) {
    this.selectedDefaultHeader = dh;
    this.overriddenSortColumn = overriddenSortColumn;
    this.sortColumnDataType = sortColumnDataType;
    if (!this.options[0].isInitial && this.defaulSortColumn == this.selectedDefaultHeader && from == "change") {
      if (this.orderTypearr.length > 0) {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
      }
      else {
        this.orderTypearr.push(dh);
      }
    }
    else {
      if (this.givenOrderType == 'desc') {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.givenOrderType = '';
      } else {
        this.givenOrderType = '';
      }
      if (this.orderTypearr.indexOf(dh) == -1) {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.orderType = 'asc';
      } else {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
        this.orderType = 'desc';
      }
    }

    if (document.getElementById("sortArrow" + i)) {
      for (let i = 0; i < this.options[0].TableHdrData.length; i++) {
        this.sortArrowFunction(i, 'remove');
      }
      this.sortArrowFunction(i, 'add');
    }
  }

  sortArrowFunction(i, eventType) {
    let element = document.getElementById("sortArrow" + i);
    if (element)
      eventType == 'remove' ? element.classList.remove("IC6") : element.classList.add("IC6");
    let headerElement = document.getElementById("header" + i);
    if(headerElement)
      eventType == 'remove' ? headerElement.classList.remove("IC6") : headerElement.classList.add("IC6");
  }

  DeleteRecords(e) {
    this.userAlerts.showPrompt(myGlobals.PromptType.Delete, this.PopupCallback.bind(this), e)
    this.changeEditMode(false);
    this.DeleteData.emit(e);

  }

  PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == "ok") {
      this.deleteEvt.emit([extraParams, this.options[0].ServiceId, this.InActiveTherapistChkBoxEvt]);
      this.givenOrderType = this.orderType;
    }
  }

  autoscrolltocurrtime(curid) {
    if (document.getElementsByClassName('highlight').length > 0) {
      let curRow = curid.toString();
      let elms = document.getElementById(curRow);
      if (elms) {
        let scrolltoPos = (document.getElementById(curRow).offsetTop);
        let thPos = (document.getElementById('SPAHeaderRow').offsetHeight);
        document.getElementById("SPACustomTable").scrollTop = scrolltoPos - thPos;
        clearInterval(this.setinterscroll);
      }
    }
  }



  setCustomHeaderValues(setupFormGroupName: UntypedFormGroup, codeName: any, isActive: boolean, event: any) {
    setupFormGroupName.controls['setupCodeName'].setValue(codeName);
    setupFormGroupName.controls['activetoggle'].setValue(isActive);
    this.isValidRoleName = false;
    if (typeof event == 'object' &&  event.target.parentElement.parentElement.classList.contains("rowDisabled")) {
      setupFormGroupName.controls['setupCodeName'].setValue("");
      setupFormGroupName.controls['activetoggle'].setValue(false);
      this.customHeaderButton = this.localization.captions.setting.Add;
    }
  }

  enableCancelButton () {
    this.isCancelAvailable = this.customHeaderButton.toLowerCase() == this.localization.captions.setting.UPDATE.toLowerCase();
  }

  EditRecords(e, type, index?) {
    if (this.editEvt)
      if (this.SelectedSettingId == myGlobals.GridType.commission) {
        this.editEvt.emit([e, type, index]);
      } else {
        this.editEvt.emit([e, this.options[0].ServiceId, type]);
        this.givenOrderType = this.orderType;
      }
  }

  optionChange(e, belonTo, data) {
    // Needs to be moved to Retail table component
    if (this.GridType.quickSale == this.SelectedSettingId && e.value == 0){
      this.SelectedData =  this.SelectedData.filter(r=> { return r.id != data.id});
    }
    this.dropDownChange.emit([e, belonTo, data])
  }

  calwidthwithtimeout(){
    setTimeout(() => {
      this.calculateWidth();
      },1);
  }

  clearSearchText () {
      this.searchText = "";
  }

  fieldEdit(event, selectedRow, key,index) {
    this.copyOfTableData[index][key]=event.target.value ==0.00 || event.target.value == null ? this.LoadDecimalValue(0):event.target.value;
    this.valueChanged=true;
  }

  checkValidEdit(currentIndex){
    this.editingData=_.cloneDeep(this.bodyArray[currentIndex]); 
    this.EnableEdit[currentIndex]=!this.EnableEdit[currentIndex];
    this.userEditing.emit(true);
    this.changeEditMode(true);
  }

  changeEditMode(getEditModeFlag){
    this.isEditModeSet = getEditModeFlag;
}
}

export interface listOrder {
  listOrder: number;
}
