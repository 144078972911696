<div class="demo-tab-group__popover-header seat-grid-header">{{'accordionstatustext' | translate}}</div>
<mat-selection-list class="demo-tab-group__status-selection-list" [multiple]="false">
    <ng-scrollbar>
        <mat-list-option *ngFor="let status of partyStatusBasedonType" (click)="seatStatusChanged(status)">
            <div class="demo-tab-group__party-status-list">
                <div class="show-color"
                    [ngStyle]="{'background-color':status.Color ? 'rgba(' + status.Color.R + ',' + status.Color.G + ',' + status.Color.B + ',' + status.Color.A + ')':''}">
                </div>
                <span class="seat-secondary-text">{{ status.Name | translate}}</span>
            </div>
        </mat-list-option>
    </ng-scrollbar>
</mat-selection-list>