import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonValue, DropdownOptions, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ActionTypeEnum, FromTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { PaymentCommunication } from '../payment-communication.service';
import { RetailRoutes } from '../retail-route';
import { GiftcardDetails, GiftcardSearchFilters } from '../shared/service/payment/payment-model';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { AlertType } from '../shared/shared.modal';

@Component({
  selector: 'app-retail-advanced-search',
  templateUrl: './retail-advanced-search.component.html',
  styleUrls: ['./retail-advanced-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailAdvancedSearchComponent implements OnInit {
  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  tableContent: any;
  advanceSearchForm: UntypedFormGroup;
  cancelButton: ButtonValue;
  applyButton: ButtonValue;
  searchButton: ButtonValue;
  outletoptions: DropdownOptions[];
  selectedGiftcard: any;
  floatLabel: string;

  constructor(public _localization: RetailLocalization
    , public dialogRef: MatDialogRef<RetailAdvancedSearchComponent>
    , private fb: UntypedFormBuilder
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private _retailComm: PaymentCommunication
    , private _utils: RetailUtilities
  ) {
    this.captions = this._localization.captions.shop.GiftCard.GiftcardAdvancedSearchPopup;
    this.floatLabel = this._localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.formGenerator();
    this.headerOptions = this.getHeaderOptions();
    this.tableOptions = this.getTableOptions();
    this.tableContent = [];
  }

  formGenerator() {
    this.advanceSearchForm = this.fb.group({
      // outlet: '',
      firstName: '',
      lastName: '',
      emailId: '',
      phoneNumber: ''
    })
    this.cancelButton = {
      label: this.captions.lbl_cancel,
      type: 'tertiary'
    };
    this.applyButton = {
      label: this.captions.lbl_apply,
      type: 'primary',
      disabledproperty: true
    };
    this.searchButton = {
      label: this.captions.lbl_search,
      type: 'secondary',
      disabledproperty: true
    }
    
    this.advanceSearchForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      this.searchButton.disabledproperty = !(this.advanceSearchForm.valid && this.advanceSearchForm.dirty) ||
        (!this.advanceSearchForm.controls['emailId'].valid && this.advanceSearchForm.controls['emailId'].touched && this.advanceSearchForm.controls['emailId'].value);
      this.searchButton = { ...this.searchButton }
      this.applyButton.disabledproperty = !(this.tableContent.find(x => x.checked));
      this.applyButton = { ...this.applyButton };
    });
  }

  getTableOptions(): TableOptions {
    return {
      defaultSortOrder: SorTypeEnum.asc,
      defaultsortingColoumnKey: 'soldDate',
      isHeaderCheckboxAllowed: false,
      isRowClick: true,
      uniqueKey: 'giftCardNumber',
    }
  }  

  getHeaderOptions(): TableHeaderOptions[] {
    let headers: TableHeaderOptions[] = [
      {
        key: 'checked',
        displayName: '',
        templateName: ActionTypeEnum.radioButton,
      },      
      {
        key: 'giftCardNumber',
        displayName: this.captions.lbl_giftCardNumber,
        sorting: false,
        displayNameId: 'giftCardNumber',
      },
      {
        key: 'userName',
        displayName: this.captions.lbl_user,
        sorting: false,
        displayNameId: 'userName',
      },
      {
        key: 'emailId',
        displayName: this.captions.lbl_emailId,
        sorting: false,
        displayNameId: 'emailId',
      },
      {
        key: 'phoneNumber',
        displayName: this.captions.lbl_phoneNumber,
        sorting: false,
        displayNameId: 'phoneNumber',
      }     
    ];
    if (!this.data.isExternal) {
      headers = headers.concat([
        {
          key: 'soldDate',
          displayName: this.captions.lbl_soldDate,
          sorting: true,
          displayNameId: 'lbl_soldDate',
        }, {
          key: 'status',
          displayName: this.captions.lbl_status,
          sorting: false,
          displayNameId: 'status',
        },
        {
          key: 'availableBalance',
          displayName: this._localization.replacePlaceholders(this.captions.lbl_availableBalance, ['currencySymbol'], [this._localization.currencySymbol]),
          displayNameId: 'availableBalance',
          sorting: false,
        }
      ]);
    }
    return headers;
  }

  onRowClick(eve) {
    console.log(eve);
  }

  onTableAction(eve) {
    switch (eve.fromType) {
      case FromTypeEnum.radioButton:
        this.tableContent = eve.array;
        this.tableContent = [...this.tableContent];
        this.advanceSearchForm.markAsDirty();
        this.advanceSearchForm.updateValueAndValidity();
        this.selectedGiftcard = eve.Obj;
        break;
    }
  }

  async search() {
    //validate Email
    if (!this.advanceSearchForm.controls['emailId'].valid && this.advanceSearchForm.controls['emailId'].touched && this.advanceSearchForm.controls['emailId'].value) {
      return;
    }
    const searchFilters: GiftcardSearchFilters = {
      firstName: this.advanceSearchForm.controls.firstName.value,
      lastName: this.advanceSearchForm.controls.lastName.value,
      email: this.advanceSearchForm.controls.emailId.value,
      phoneNumber: this.advanceSearchForm.controls.phoneNumber.value,
      isExternal: this.data.isExternal
    };
    if (searchFilters.firstName.length >= 3 || searchFilters.lastName.length >= 3
      || searchFilters.email.length >= 3 || searchFilters.phoneNumber.length >= 3) {
      try {
        this._utils.ToggleLoader(true);
        const searchResults: GiftcardDetails[] = await this._retailComm.postPromise({
          route: RetailRoutes.GiftcardAdvancedSearch,
          body: searchFilters,
        }, false);
        console.log(searchResults);
        this._utils.ToggleLoader(false);
        this.tableContent = [];
        this.selectedGiftcard = null;
        if (searchResults && searchResults.length > 0) {
          const maskEmail = (email: string): string => {
            // Define a regex pattern to match email addresses
            const pattern: RegExp = /(\w{4})(\w*)(@\w+\.\w+)/;

            // Replace characters after the 4th character with asterisks
            const maskedEmail: string = email.replace(pattern, (match, p1, p2, p3) => p1 + '*'.repeat(p2.length) + p3);

            return maskedEmail;
          }

          const maskPhoneNumber = (phoneNumber: string): string => {
            // Define a regex pattern to match phone numbers with country code separated by '|'
            const pattern: RegExp = /(\d+\|)(\d*)(\d{4})/;

            // Replace characters before the last 4 digits with asterisks
            const maskedPhoneNumber: string = phoneNumber.replace(pattern, (match, p1, p2, p3) => p1 + '*'.repeat(p2.length) + p3);

            return maskedPhoneNumber;
          }
          searchResults.map(g => {
            const gcDetails = {
              checked: false,
              giftCardNumber: g.cardNumber,
              userName: g.firstName + " " + g.lastName,
              availableBalance: g.availableBalance ? this._localization.localizeCurrency(g.availableBalance, false) : "",
              status: g.status,
              emailId: g.email ? maskEmail(g.email) : "",
              phoneNumber: g.phoneNumber ? maskPhoneNumber(g.phoneNumber) : "",
              soldDate: g.soldDate ? this._localization.localizeDisplayDate(g.soldDate) : ""
            };
            this.tableContent.push(gcDetails);
          });
        } else{
          this._utils.showAlert(this._localization.captions.shop.TransactionLogScreen.NoDataFoundLbl, AlertType.Info);
        }
      } catch (error) {
        this._utils.ToggleLoader(false);
        this._utils.showAlert(this._localization.captions.shop.TransactionLogScreen.NoDataFoundLbl, AlertType.Info);
        console.log(error);
      }
    }    
  }

  apply() {
    console.log();
    this.dialogRef.close(this.selectedGiftcard);
  }
  close() {
    this.dialogRef.close('close');
  }

}

