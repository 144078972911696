<ng-container *ngFor="let group of groupedCategory">
  <div class="text-left">
    <span class="ag_display--inblock cart-category">{{group.category}}</span>
  </div>
  <table aria-describedby="" class="w-100" class="cart-table">
    <tbody>
      <ng-container *ngFor="let SelectedProduct of group.products">
        <ng-container *ngFor="let product of SelectedProduct.productDetails">
          <tr class="items-div">
            <td>
              <div class="textellipsis" matTooltip="{{SelectedProduct.ProductName}}">
                {{SelectedProduct.ProductName}}
              </div>
            </td>
            <td class="text-right">
              <label class="ag_LatoWebBold-12">{{localization.LocalizeDate(product.startDate)}} -
                {{localization.LocalizeDate(product.endDate)}}
              </label>
            </td>
          </tr>
          <tr class="items-div">
            <td>
              <div>
                {{product.quantity}} X
                <span *ngIf="SelectedProduct.ItemType === itemType.RentalItem && product.rentType">
                  {{product.hours}} {{captions.shop.addItems.hrs}} X
                </span>
                {{product.price | Currency}}
              </div>
            </td>
            <td class="text-right">
              <div class="textellipsis" matTooltip=" {{product.price}}">
                {{product.total | Currency}}
                <i [attr.automationId]="'Icn_retailCart_removeProduct'" aria-hidden="true" class="icon-Delete blckclr cursor" [ngClass]="{ 'button_invalid' : SelectedProduct.isModificationRestricted 
                && (!_shopservice.correctTransaction ||  !_shopservice.reOpenTransaction)}"
                  (click)="removeproduct(SelectedProduct)" matTooltip="{{localization.captions.shop.delete}}">
                </i>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</ng-container>

<div class="ag_display--flex cart-footer" *ngIf="totalValue">
  <span>{{captions.lbl_totalAmount}}</span>
  <span>{{totalValue | Currency}}</span>
</div>
