export class EngageMemberByCardIdRequestDTO{
        ScheduleTime: string = (new Date()).toISOString()
        Scheduleday: number = (new Date()).getDay()
        GuestCardNumber: string;
        userID: number = null;
        Info: number = 4;
        SourceSystemCode: string = "PMS"
        SourceCorpID: number;
        SourceSiteID: number;
        ProfitCentre: string = null;
        TerminalGroup: string = null;
        TerminalID: number = 0;
        ServerEmpID: number = 0;
        ProfileId: number = 0;
        OwnerProfileId: number = 0;
        Age: number = null;
        CourseId: number = null;
        IsInitialLoad: boolean = false;
        IsExternalMember: boolean = false;
        SiteID: number;
        RoleID: number = 0;
        UserID: number = 0;
        CorpID: number;
        StatusCode: number = 0;
        SaveType: number = 0;
        DeviceType: string = null;

        constructor(cardNo, corpId, siteId){
            this.GuestCardNumber = cardNo;
            this.SourceCorpID = corpId;
            this.SourceSiteID = siteId;
            this.SiteID = siteId;
            this.CorpID = corpId;
        }
}