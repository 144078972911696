<div *ngIf="currentViewMode == CalendarViewType.Days" class="daycell" [ngClass]="{'muted': gridData.month != currentMonth}"
    (click)="selectedCalendarDate(gridData)" (dblclick)="chosenHeaderDate(gridData)">
    <div class="daycell__date px-1 pt-1">
        <label
            [ngClass]="{'date-muted': gridData.month != currentMonth, 'current-date': (gridData.date == today.getDate() && gridData.month == today.getMonth() && gridData.year == today.getFullYear())}">{{gridData.date}}</label>
        <div *ngIf="gridData.overviewData.length && gridData.overviewData[0]?.TableBlockingRules?.length > 0">
            <span class="icon-Block"></span>
        </div>
    </div>
    <div class="daycell__content font-italic">
        <label *ngIf="gridData.overviewData.length">{{gridData.overviewData[0]?.Note?.Text}}</label>
    </div>
</div>
<div *ngIf="currentViewMode == CalendarViewType.Months" class="monthyearcell" (click)="selectedCalendarDate(gridData)">
    <div class="monthyearcell__content px-1 pt-1">
        <label class="calendar-label">{{gridData.months}}</label>
    </div>
</div>
<div *ngIf="currentViewMode == CalendarViewType.Years" class="monthyearcell" (click)="selectedCalendarDate(gridData)">
    <div class="monthyearcell__content px-1 pt-1">
        <label class="calendar-label">{{gridData.years}}</label>
    </div>
</div>