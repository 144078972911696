import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { DashboardService } from '@app/shared/services/dashboard.service';
import { AuditlogComponent } from '@components/auditlog/auditlog.component';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { AdditionalinfoComponent } from '@components/create-update-guest-tab-layout/definitions/additionalinfo/additionalinfo.component';
import { AddressComponent } from '@components/create-update-guest-tab-layout/definitions/address/address.component';
import { ContactComponent } from '@components/create-update-guest-tab-layout/definitions/contact/contact.component';
import { PersonalInfoComponent } from '@components/create-update-guest-tab-layout/definitions/personal-info/personal-info.component';
import { PreferencesComponent } from '@components/create-update-guest-tab-layout/definitions/preferences/preferences.component';
import { GuestSelectionComponent } from '@components/create-update-party-tab-layout/selections/guest-selection/guest-selection.component';
import { TableNotAvailableForReservationsComponent } from '@components/table-not-available-for-reservations/table-not-available-for-reservations.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { buttonTypes, ComponentTypes, DayOfWeek, Menu, OperationResultState, PartyNoteType, PartyState, PartyStatus, PartyType, PopupType, ReservationStatus, ReservationType, TableBlockBy, TableBlockingRuleFrequencyType, TableStatus } from '@constants/commonenums';
import * as globals from '@constants/globalConstants';
import { controlSettings, DEFAULT_LANGUAGE_CODE, popupDialogDimension } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { environment } from '@environments/environment';
import { ContactDTO, CoverTypeQuantityDTO, GuestTitle } from '@models/InputContact';
import { ContactHistoryItemDTO, CoverTypePriceDTO, PageMethod, PredefinedPartyMessageDTO, SeatingAreaDTO, ServerDTO, SettingsDTO, StandaloneTableDTO, StateDTO, StatusDTO, TableServerDTO } from '@models/RestaurantDTO';
import { AuditableEntityType } from '@models/SimpleAuditLogItemDTO';
import { TableBlockingRule, TableBlockingRuleDTO } from '@models/TableBlockingDTO';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails, TabConfig } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { FacadeService } from '@services/facade.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import { TablesService } from '@services/tables.service';
import { DashboardFunctions } from '@utilities/dashboard-functions';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';
import { uniq, uniqBy, cloneDeep} from 'lodash';
import moment from 'moment';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { ISubscription } from 'rxjs-compat/Subscription';
import * as GlobalConst from '@app/shared/constants/globalConstants';
import { debounceTime } from 'rxjs/operators';
import * as Util from '@app/shared/utilities/util-functions';
@Component({
  selector: 'app-dashboard-party-info',
  templateUrl: './dashboard-party-info.component.html',
  styleUrls: ['./dashboard-party-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardPartyInfoComponent extends Utilities implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() type: ReservationType;
  @Input() partyStatus: StatusDTO[] = [];
  @Input() dashboardData: any;
  @Input() fromTables: any;
  @Output() partyUnselectedEvent = new EventEmitter<any>();
  @Output() reservationActions = new EventEmitter<any>();
  @Output() selectedCommunalPartyInfo = new EventEmitter<any>();
  createContactDialogRef: MatDialogRef<any>;
  party: any;
  predefinedMsgs$ = new BehaviorSubject<PredefinedPartyMessageDTO[]>(null);
  @Output() statusChange = new EventEmitter<any>();
  @ViewChild('dataContainer') el: ElementRef;
  @ViewChild('messageChat') private messageChat: NgbPopover;
  @ViewChild('emailChat') private emailChat: NgbPopover;
  confirmationBtn: ButtonValue;
  SizeOkBtn: ButtonValue;
  showNavigation = false;
  PartyState = PartyState;
  PartyStatus = PartyStatus;
  PartyType = PartyType;
  TableStatus = TableStatus;
  PageMethod = PageMethod;
  setSizeConfig: FieldConfig[];
  partyNotes: any[] = [];
  @ViewChild('form') partySizeForm: DynamicFormComponent;
  @ViewChild('coverTypeDetails', { static: true }) coverTypeForm: DynamicFormComponent;
  animateloop: any;
  historyData: any;
  seatStatusValue: any;
  actionsData: any;
  tableActionsData: any;
  componentDetails: ComponentDetails;
  reservationColor = 'color';
  reservationType = 'status';
  fromType = ReservationType;
  standAloneTables: StandaloneTableDTO[];
  inputString = '';
  servers: ServerDTO[];
  serverTableAssignment: TableServerDTO[] = [];
  progressBarValue: number;
  _settings: SettingsDTO = {} as SettingsDTO;
  dashboardStatus = [];
  messagesData: any; //PartyMessageDTO[];
  pageBackgroundColor: string;
  subscriptions: Subscription = new Subscription();
  pageTimer: Subscription;
  isMobileChat = false;
  isPartySizeOpen;
  joinTableNames = '';
  Menu = Menu;
  _restaurantTime: any;
  showVisitStats = false;
  blockTableDetails: TableBlockingRule[] = [];
  allblockTableDetails: TableBlockingRule[] = [];
  selectedTableBlockDto: TableBlockingRuleDTO[] = [];
  blockUntilDetail: string;
  reseatbtn: ButtonValue;
  guestName = '';
  leftTableAgo: Number;
  showReseat = false;
  setPartySizeConfig: FieldConfig[];
  isPartysizeneed;
  contactNotes: any[] = [];
  remainingTime:any;
  partyNoteType = PartyNoteType;
  IsVisiblePropertyType: boolean;
  hide_moveActionIcon:boolean = false;
  hide_turnTimeIcon: boolean = false;
  hide_partySize: boolean = false;
  hide_serverName: boolean = false;
  hide_moveTable: boolean = false;
  showMoreSpan: boolean = false;
  disableMessagePopup:boolean = false;
  print : any[] = [];
  buttontype: ButtonValue;
  partyWithAddon:any;
  coverTypeConfig: FieldConfig[];
  coverTypes: CoverTypePriceDTO[];

  constructor(public _as: AppService, public dialog: MatDialog, private partyService: PartyService, private ps: PopupService,
              private cs: CacheService, private cdf: ChangeDetectorRef, public translateService: TranslateService,
              private fs: FacadeService, private gbs: GuestBookService, private dashboardFunctions: DashboardFunctions,
              private ts: TablesService) {
    super(dialog);
    if (this._as.selectedMenuId == Menu.Reservation || this._as.selectedMenuId == Menu.WaitList || this._as.selectedMenuId == Menu.TimeLine) {
      this.showVisitStats = true;
    }
    this.subscriptions.add(cs.settings.subscribe(settings => {
      this._settings = settings;
      this.predefinedMsgs$.next(settings.PredefinedPartyMessages);
      this.servers = this._settings.Servers;
    }));
  }

  ngOnInit() {
    this.confirmationBtn = {
      type: buttonTypes.actionSecondarySmall, label: 'unassignserverbuttontext',
      actionName: ReservationStatus.UnassignServer,
      customclass: 'action-btn'
    };
    this.SizeOkBtn = {
      type: buttonTypes.actionPrimarySmall, label: 'ok',
      actionName: ReservationStatus.ChangePartySize,
      customclass: 'action-btn'
    };
    // this.IsVisiblePropertyType = (Utilities.IspropertyTypeTheatre(this._as.PropertyType) || Utilities.IsActivitiesVenues(this._as.PropertyType));
    this.hide_partySize = Utilities.controlValidate(controlSettings.Location_partySize_hide, this._as.PropertyType);
    this.hide_serverName = Utilities.controlValidate(controlSettings.Location_serverName_hide, this._as.PropertyType);
    this.hide_moveTable = Utilities.controlValidate(controlSettings.Location_moveTable_hide, this._as.PropertyType);
    this.hide_moveActionIcon = Utilities.controlValidate(controlSettings.Location_MoveAction_Hide , this._as.PropertyType);
    this.hide_turnTimeIcon = Utilities.controlValidate(controlSettings.Location_TurnTime_Hide , this._as.PropertyType);
    let hide_editUpcomingParty = Utilities.controlValidate(controlSettings.Location_EditUpcomingParty_Hide, this._as.PropertyType);
    this.actionsData = globals.actionsIcons;
    if (hide_editUpcomingParty || this.cs.isIframeEnabled) {
      this.actionsData = this.actionsData.filter(icon => icon.id !== 'edit');
    }
    this.tableActionsData = globals.tableActionsIcons;
    this.setStandaloneTables();
    this.subscriptions.add(this.cs.state.subscribe((value: StateDTO) => {
      this.serverTableAssignment = value.TableServerAssignments;
    }));
    if (this._as.selectedMenuId == Menu.Tables) {
      this.reseatbtn = {
        type: buttonTypes.actionPrimary,
        label: 'reseat',
        customclass: 'reseatbutton'
      };
    }
    let config = this.getCoverTypeConfig();
    this.coverTypeConfig = config;
  }
 
  
  ngOnChanges() {
    this.party = this.partyService.selectedParty$.value;
    if (this._as.partyStatusList) {
      this._as.partyStatusList.forEach(party => {
        if (party.Id === this.party.StatusId) {
          this.seatStatusValue = party.Name;
        }
      });
    }
    this.startTimer();
    const lastPagedAt = moment(this.party.LastPagedAt);
    const time = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    if (this.party.LastPagedAt) {
      let differenceTime = (this._settings.General.MinIntervalBetweenPartyPagesInMinutes * 60) - (time.diff(lastPagedAt, 'seconds'));
      if (differenceTime > 0) {
        this.pageTimer = interval(1000 * differenceTime).subscribe(() => {
          this.setPageBackgroudColor();
        });
      }
    }
    this.partyService.setPopUpPosition$.next(true)
  }

  ngAfterViewInit() {
    this.getCoverTypeChanges();
    this.subscriptions.add(this.partyService.selectedParty$.subscribe((party) => {
      if (this.isPartySizeOpen) { this.isPartySizeOpen.close(); }
      this.party = party ? {...party} : party;
      if (this.party && !this.party.Contact.VisitStats && this.showVisitStats) {
        this.getContact();
      }
      this.showReseat = false;
      if (this.party && this._as.selectedMenuId == Menu.Tables && this.party.RecentlyLeftParty) {
        this.ShowReseatDataIfRequired(this.party.RecentlyLeftParty);
      }
      if (this.party && this.party.TableNames) {
        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })
        const tablenName = this.party.TableNames.sort();
        tablenName.sort((a, b) => collator.compare(a, b));
        this.joinTableNames = tablenName.join();
      }
      if (this.party) {
        this.loadSizeConfig(party);
        this.setPartyNotes(party);
        this.SetContactNotes(party)
        switch (this._as.selectedMenuId) {
          case Menu.Reservation:
          case Menu.WaitList:
            break;
          default:
        }
        this.setStatusData();
        this.setPageBackgroudColor();
        this.setBlockedTableDetails();
        this.setTimerValues(this.party);
      }
      if (this.messageChat) {
        this.messageChat.close();
      } else if (this.emailChat) {
        this.emailChat.close();
      }
      this.print = [this.party]
      this.partyService.setPopUpPosition$.next(true)
    }));
    this.subscriptions.add(this.cs.layout.subscribe(layt => {
      this.setStandaloneTables();
    }));
    this.subscriptions.add(this.partyService.selectedServer$.subscribe(serverUpdate => {
      if (serverUpdate) {
        const val = this.serverTableAssignment.findIndex(x => x.StandaloneTableId == serverUpdate.Id);
        if (val >= 0)
          this.serverTableAssignment[val].ServerId = serverUpdate.ServerId;
      }
    }));
    this.subscriptions.add(this.partyService.Parties$.subscribe(parties => {
      if (parties) {
        if (this.partyService.selectedParty$.value) {
          let selectedParty = parties.find(p => p.Id == this.partyService.selectedParty$.value.Id)
          if (selectedParty) {
            this.party = this.partyService.selectedParty$.value;
            this.setPageBackgroudColor();
          }
        }
      }
    }));
    if (this.el) {
      if (this.el.nativeElement.scrollWidth > this.el.nativeElement.offsetWidth) {
        this.showNavigation = true;
      } else {
        this.showNavigation = false;
      }
    }
    this.subscriptions.add(this._as.minuteTimer$.subscribe(val => {
      this.setTimerValues(this.partyService.selectedParty$.value);
      this.UpdateUnseatedTime();
      this.startTimer();
      this.partyService.setPopUpPosition$.next(true)
    }));
    this.cdf.detectChanges();
  }
  setCoverTypesValues(data) {
    let coverTypeQuantity: CoverTypeQuantityDTO[] = [];
    {
      this.coverTypes.forEach(cover => {
        coverTypeQuantity.push({ Id: 0, CoverTypeId: cover.Id, CoverTypeName: cover.CoverTypeName, Covers: data[cover.Id], Price: cover.Price });
      })
      return coverTypeQuantity;
    }
  }
  getCoverTypeChanges() {
    this.partyService.quickSeatCoverTypeQuantity = [];
    this.coverTypeForm?.form.valueChanges.pipe(debounceTime(100)).subscribe(data => {
      if ((data && Object.keys(data).length > 0) && (Object.keys(data).length == this.coverTypes?.length)) {
        this.setCoverTypeValues(data);
      }
    });
    if (this.coverTypeForm?.form?.value) {
      this.setCoverTypeValues(this.coverTypeForm?.form?.value);
    }
  }
  setCoverTypeValues(data) {
    let coverTypeQuantity = this.setCoverTypesValues(data);
    if (coverTypeQuantity?.length == this.coverTypes.length) {
      let size = _.sumBy(coverTypeQuantity, 'Covers');
      this.partySizeForm?.form.get('parysize').setValue(size, { emitEvent: false });
      this.partyService.quickSeatpartyselectedSize = size;
      this.partyService.quickSeatCoverTypeQuantity = coverTypeQuantity;
    }
  }
  setTimerValues(party) {
    if (party && party.State == PartyState.Seated) {
      party.remainingTime = this.getRemainingTime(party);
      party.progressBarValue = this.calculatePercentile();
      party.quotedTime = this.getQuotedTime(party);
      this.party = {...party};
    }
  }

  getQuotedTime(party): number {
    if (party.State == PartyState.Seated) {
      return Math.round((new Date(moment(party.DepartureTime).valueOf()).getTime() - new Date(moment(party.SeatingTime).valueOf()).getTime()) / 1000);
    }
    else {
      return 0;
    }
  }

  overrideTimeRemaining(party) {
    this.dashboardFunctions.overrideRemainingtime(party);
    if (this._as.selectedMenuId == Menu.Tables) {
      let overrideSubscription = this.partyService.overrideTimeAPISuccessEvent$.subscribe(() => {
        this.closeGuestInfoPanel();
        overrideSubscription.unsubscribe();
      })
    }
  }

  getRemainingTime(party): number {
    let time: any;
    if (party.State === PartyState.Seated) {
      time = Utilities.parseDateString(party.DepartureTime);
    } else if (party.State !== PartyState.Seated) {
      time = (party.SeatingTime) ? Utilities.parseDateString(party.SeatingTime) : (party.ReservedFor) ?
        Utilities.parseDateString(party.ReservedFor) : Utilities.parseDateString(party.ArrivedAt);
    }
    return Math.floor((time - Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta).getTime()) / 1000);
  }

  loadSizeConfig(party) {
    this.partyService.quickSeatpartyselectedSize = this.coverTypeConfig?.length ? this.partyService.quickSeatpartyselectedSize : party && party.MaxPartySize ? party.MaxPartySize : 2;
    this.setPartySizeConfig = [];
    this.isPartysizeneed = party && (party.State == PartyState.Open || party.State == PartyState.Pending || party.State == PartyState.Blocked) && party.isserverneed;
    const partySizeArray = this.calculatePartySize(50);
    this.setSizeConfig = [];
    this.setSizeConfig.push({
      type: 'select',
      name: 'size',
      label: 'Party Size',
      options: partySizeArray,
      showErrorText: true,
      appearance: true,
      class: 'set-size',
      value: party && party.Size ? party.Size : 2
    });
    this.setPartySizeConfig.push({
      type: 'select',
      name: 'parysize',
      label: 'Party Size',
      options: partySizeArray,
      showErrorText: true,
      appearance: true,
      class: 'partyset-size',
      value: this.partyService.quickSeatpartyselectedSize,
      cellClick: (event) => this.setSelectedShift(event)
    });
  }
  getCoverTypeConfig() {
    let _config: FieldConfig[] = [];
    let coverTypes = this.coverTypes = this._settings.CoverTypes?.filter(cover => cover.IsRegularReservation)
    coverTypes.sort((a, b) => (a.CoverTypeName || a.Name).localeCompare(b.CoverTypeName || b.Name)).forEach(coverType => {
      let activeType = this._settings.CoverTypes.find(_sCoverType => _sCoverType.Id === coverType.Id);

      if (activeType) {
        let field = {
          type: 'select',
          label: coverType.CoverTypeName ? coverType.CoverTypeName : coverType.Name,
          name: coverType.Id,
          value: 2,
          options: [{ id: 0, value: this.translateService.instant("0") }, ...this.calculatePartySize(50)],
          class: "basic-select",
          showErrorText: true,
          isTranslate: false,
          validation: [Validators.required],
          toolTipEnabled: true,
          inputType: 'number',
          isRemoved: !activeType
        } as FieldConfig;
        _config.push(field);
      }
    })

    return _config;
  }
  setSelectedShift(eve) {
    this.partyService.quickSeatpartyselectedSize = eve.value;
  }
  calculatePartySize(maxPartySizeAllowed) {
    const partySizeArray = [];
    for (let i = 1; i <= maxPartySizeAllowed; i++) {
      partySizeArray.push({ id: i, value: i.toString() });
    }
    return partySizeArray;
  }
  ngDoCheck(){
    this.partyService.setPopUpPosition$.next(true)
  }

  setBlockedTableDetails() {
    if (this.party.State == PartyState.Blocked) {
      this.selectedTableBlockDto = cloneDeep(this.ts.tableBlockingRules$.value
        .filter((rule) => rule.TableIds.some((Id) => Id == this.party.TableIds[0]))
        .map(rule => {
          let retrule = Object.assign({}, rule);
          return retrule;
        }));
      if (this.selectedTableBlockDto.length > 0) {
        this.setBlockedDetails();
      }
    }
  }

  setBlockedDetails() {
    this.allblockTableDetails = [];
    this.showMoreSpan = false;
    let blockTableDetail: TableBlockingRule
    this.selectedTableBlockDto.forEach(rule => {
      blockTableDetail = new TableBlockingRule();
      blockTableDetail.Shifts = (rule.BlockBy == TableBlockBy.ByShift) ? this.tableBlockedShifts(rule) : null;
      blockTableDetail.Frequency = this.tableBlockedFrequency(rule);
      blockTableDetail.StartDate = rule.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay ? "--" : Utilities.formateDateString(rule.StartDate).toDateString();
      blockTableDetail.EndDate = rule.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay ? "--" : Utilities.formateDateString(rule.EndDate).toDateString();
      blockTableDetail.AllocationType = rule.AllocationTypeId != null ? this._settings.AllocationType.filter(a=>a.Id == rule.AllocationTypeId)[0].AllocationName : this.translateService.instant("NA");
      blockTableDetail.BlockingReasion = (rule.BlockingReason != null && rule.BlockingReason != "") ? rule.BlockingReason : this.translateService.instant("NA");
      //this.blockTableDetails.push(blockTableDetail);
      this.allblockTableDetails.push(blockTableDetail);
    });
    this.blockTableDetails = [this.allblockTableDetails?.[0]];
    if(this.allblockTableDetails.length > 1){
      this.showMoreSpan = true;
    }
    this.blockUntilDetail = this.blockUntil(this.selectedTableBlockDto[this.selectedTableBlockDto.length - 1]);
  }

  showMoreData(){
    this.blockTableDetails = cloneDeep(this.allblockTableDetails);  
    this.showMoreSpan = !this.showMoreSpan;  
  }

  showLessData(){
    this.blockTableDetails = [cloneDeep(this.allblockTableDetails[0])];  
    this.showMoreSpan = !this.showMoreSpan;   
  }
  blockUntil(rule): string {
    if (rule.Frequency.Type == TableBlockingRuleFrequencyType.EveryDay) {
      return (moment(rule.EndDate)).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat);
    }
    else {
      if (rule.BlockBy == TableBlockBy.ByShift) {
        let Unitilshiftid = rule.Shifts[rule.Shifts.length - 1].Id;
        if (Unitilshiftid) {
          let Shift = this._settings.Shifts.find(sft => sft.Id == Unitilshiftid);
          let Shiftendhour = moment(Shift.EffectiveRange.End).locale(this.dashboardFunctions.getLocaleCode()).format('hh:mm A');
          if (rule.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay) {
            return Shiftendhour + " ( " + moment(rule.Frequency.Date).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat) + " " + this.translateService.instant("only") + " )";
          }
          else {
            return Shiftendhour + " (  " + this.translateService.instant('every') + " " + this.translateService.instant(DayOfWeek[rule.Frequency.DayOfWeek].toString()) + " )";
          }
        }
      }
      else{
        let minDate = "0001-01-01T";
        let time = minDate.concat(rule.EffectiveRange[0].End);
        let endhour = moment(time).locale(this.dashboardFunctions.getLocaleCode()).format('hh:mm A');
          if (rule.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay) {
            return endhour + " ( " + moment(rule.Frequency.Date).locale(this.dashboardFunctions.getLocaleCode()).format('MM/DD/YYYY')  + " " + this.translateService.instant("only") + " )";
          }
          else {
            return endhour + " (  " + this.translateService.instant('every') + " " + this.translateService.instant(DayOfWeek[rule.Frequency.DayOfWeek].toString()) + " )";
          }
      }
    }
  }

  tableBlockedShifts(rule): string {
    if (rule.Frequency.Type == TableBlockingRuleFrequencyType.EveryDay) {
      return this.translateService.instant('allShiftsText');
    }
    return rule.Shifts.map(function (shift) { return shift.Name; }).join(", ");
  }

  tableBlockedFrequency(rule): string {
    if (rule.Frequency.Type == TableBlockingRuleFrequencyType.EveryWeekDay) {
      return this.translateService.instant('every') + " " + this.translateService.instant(DayOfWeek[rule.Frequency.DayOfWeek].toString());
    } else if (rule.Frequency.Type == TableBlockingRuleFrequencyType.EveryDay) {
      return this.translateService.instant('everyday');
    }
    else {
      return moment(Utilities.formateDateString(rule.Frequency.Date)).locale(this.dashboardFunctions.getLocaleCode()).format(this.cs.settings.value.General.DateFormat) + " " + this.translateService.instant('only');
    }
  }

  setStandaloneTables() {
    this.standAloneTables = [];
    const floorPlan = this.cs.layout.value.FloorPlans;
    for (let i = 0; i < floorPlan.length; i++) {
      let Tables = floorPlan[i].StandaloneTables;
      Tables.forEach(element => {
        this.standAloneTables.push(element);
      });
    }
  }

  setStatusData() {
    const visitStatus = this.party.Contact.VisitStats && this.party.Contact.VisitStats.length > 0 ?
      this.party.Contact.VisitStats[0] : [];
    const statusArray = [{
      Name: 'lastVisited', Date: {
        day: visitStatus.LastVisitedAt ? moment(visitStatus.LastVisitedAt).format('DD') : '-',
        year: visitStatus.LastVisitedAt ? moment(visitStatus.LastVisitedAt)?.locale(this.dashboardFunctions.getLocaleCode()).format('MMM YY') : '-'
      }
    },
    { Name: 'totalVisits', Count: visitStatus.NumberOfVisits ? visitStatus.NumberOfVisits : 0 },
    { Name: 'noShows', Count: visitStatus.NumberOfNoShows ? visitStatus.NumberOfNoShows : 0 },
    { Name: 'cancelled', Count: visitStatus.NumberOfCancellations ? visitStatus.NumberOfCancellations : 0 }];
    this.dashboardStatus = statusArray;
  }

  setPartyNotes(party) {
    this.partyNotes = [];
    if (party) {
      if ((party.partyNotes && party.partyNotes.length > 0) || (party.Notes && party.Notes.length > 0)) {
        party.partyNotes = [];
      }
      if (party.SpecialMealId) {
        const specialMealDetails = this._settings.SpecialMeals.filter((meal) => meal.Id === party.SpecialMealId);
        if (specialMealDetails.length > 0 && specialMealDetails[0].Name) {
          this.partyNotes.push({ id: this.partyNotes.length, name: specialMealDetails[0].Name, isSpecialMeal: true, type: PartyNoteType.SpecialMeal });
          party.partyNotes.push({ id: this.partyNotes.length, name: specialMealDetails[0].Name, isSpecialMeal: true, type: PartyNoteType.SpecialMeal });
        }
      }
      if (party.Notes && party.Notes.length > 0) {
        party.Notes.forEach((notes, index) => {
          if (notes.RelatedId) {
            let PredefinedPartyNotes = this.dashboardFunctions.getPredefinedPartyNotes();
            const predefinedPartyNote = PredefinedPartyNotes.filter((note) => note.Id === notes.RelatedId);
            if (predefinedPartyNote.length > 0 && notes.Type == PartyNoteType.PredefinedNote ) {
              party.partyNotes.push({ id: index, name: predefinedPartyNote[0].Text, isSpecialMeal: false });
            } else if (predefinedPartyNote.length > 0 && (notes.Type == PartyNoteType.FreeFormNote)) {
              party.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
            } else if (predefinedPartyNote.length > 0 && notes.Type == PartyNoteType.SpecialRequest) {
              party.partyNotes.push({id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type});
            }
          }  else if (notes.Type == PartyNoteType.FreeFormNote) {
            party.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
          } else if (notes.Type == PartyNoteType.PromoCode) {
            party.partyNotes.push({ id: index, name: notes.Text, isSpecialMeal: true, type: notes.Type });
          }
        });
      }
    }
    if (party && party.partyNotes && party.partyNotes.length > 0) {
      this.partyNotes = [...party.partyNotes];
    }
    if (this.partyNotes.length == 0) {
      this.partyNotes = null;
    }
  }

  SetContactNotes(party: any) {
    this.contactNotes = [];
    if (party) {
      if (party.Contact && party.Contact.Notes && party.Contact.Notes.length > 0) {
        party.Contact.Notes.forEach((notes, index) => {
          if (notes.RelatedId) {
            this.contactNotes.push({ id: index, name: notes.Text, isSpecialMeal: false });
          }
        });
      }
    }
    if (this.contactNotes.length == 0) {
      this.contactNotes = null;
    }
  }

  getContact() {
    this.subscriptions.add(this.partyService.getContact(this.party.Contact.Id).subscribe(data => {
      this.party.Contact.VisitStats = [];
      this.party.Contact.VisitStats[0] = data.Payload.VisitStats[0];
      this.setStatusData();
    }));
  }

  getContactData() {
    this.subscriptions.add(this.partyService.getContact(this.party.Contact.Id).subscribe(data => {
      const visitStatus = data.Payload.VisitStats[0];
      const statusArray = [{
        Name: 'lastVisited', Date: {
          day: visitStatus.LastVisitedAt ? moment(visitStatus.LastVisitedAt).format('DD') : '-',
          year: visitStatus.LastVisitedAt ? moment(visitStatus.LastVisitedAt).format('MMM YY') : '-'
        }
      },
      { Name: 'totalVisits', Count: visitStatus.NumberOfVisits },
      { Name: 'noShows', Count: visitStatus.NumberOfNoShows },
      { Name: 'cancelled', Count: visitStatus.NumberOfCancellations }];
      this.dashboardStatus = statusArray;
    }));
  }
  selectedPartyInCommunal(party) {
    this.selectedCommunalPartyInfo.emit(party);
  }

  navigateLeft() {
    const element = this.el.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      element.scrollLeft = element.scrollLeft - 106;
    }
  }

  navigateRight() {
    const element = this.el.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      element.scrollLeft = element.scrollLeft + 106;
    }
  }

  manualpage() {
    if (this.party.State != PartyState.Pending || this.party.PageMethod != PageMethod.Manual) {
      return;
    }
    const time = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    if (!this.party.LastPagedAt || (time.diff(moment(this.party.LastPagedAt), 'minutes')) >= this._settings.General.MinIntervalBetweenPartyPagesInMinutes) {
      this.subscriptions.add(this.partyService.postPartyMessage(this.party.Id, this.party.PageMethod, null,null).subscribe((data) => {
        if (data.State == OperationResultState.Success) {
        }
      }));
    } else {
      this.showPagingError();
    }
  }

  setPageBackgroudColor() {
    this.pageBackgroundColor = '';
    if (this.party && (this.party.PageMethod == PageMethod.Manual || this.party.PageMethod == PageMethod.VoiceCall)) {
      if (!this.party.LastPagedAt) {
        return;
      }
      const lastPagedAt = moment(this.party.LastPagedAt);
      const time = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
      let differenceTime = (this._settings.General.MinIntervalBetweenPartyPagesInMinutes * 60) - (time.diff(lastPagedAt, 'seconds'));
      if ((time.diff(lastPagedAt, 'minutes')) >= this._settings.General.MinIntervalBetweenPartyPagesInMinutes) {
        this.pageBackgroundColor = 'red';
        if (this.pageTimer) {
          this.pageTimer.unsubscribe();
          this.subscriptions.remove(this.pageTimer);
        }
      } else {
        this.pageBackgroundColor = 'green';
        if (differenceTime > 0) {
          this.subscriptions.add(this.pageTimer);
        }
      }
    }
    if (this.party && (this.party.PageMethod == PageMethod.Sms || this.party.PageMethod == PageMethod.Sms2 || this.party.PageMethod == PageMethod.Email || this.party.PageMethod == PageMethod.Email2)) {
      if (this.party && this.party.Messages && this.party.Messages.length > 0) {
        this.pageBackgroundColor = 'green';
      }
      if (this.party && this.party.Messages && this.party.Messages.length > 0 && this.party.Messages[this.party.Messages.length - 1].IsIncoming) {
        this.pageBackgroundColor = 'red';
      }
    }
  }

  showPagingError() {
    const popUpMessage = [{
      confirmationMessage: `${this.translateService.instant('manualPagingError')}`,
      dialogTitle: this.translateService.instant('Paging'),
      showAlert: true
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', popUpMessage,
      popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.reservation, '450px', 'auto', true, '', 'Ok',
      'Cancel', true);
  }

  startTimer() {
    this.progressBarValue = this.calculatePercentile();
  }

  calculatePercentile() {
    const currentTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    if (!this.party || !this.party.SeatingTime) {
      return;
    }
    let seatedTimeTill = Math.round((currentTime.getTime() - new Date(this.party.SeatingTime).getTime()) / 60000);
    if (this.type === ReservationType.Waitlist && this.party.State !== PartyState.Seated) {
      seatedTimeTill = Math.round((currentTime.getTime() - new Date(this.party.ArrivedAt).getTime()) / 60000);
    }
    return (seatedTimeTill / this.getTotalSeatingTime()) * 100;
  }

  partyActionsTables(id, index) {
    this.reservationActions.emit({ id, index });
  }

  partyActions(data, index: number) {
    this.closeMobileChat();
    if (data.id == globals.history) {

      this.subscriptions.add(this.partyService.getHistoryUrl(this.party.Contact.Id, false).subscribe(data => {
        if (data.State == OperationResultState.Success) {
          this.historyData = data.Payload as ContactHistoryItemDTO;
          this.inputString = 'history';
        }
      }));
    }
    if(this.party && this.party.Notes && this.party.Notes.length > 0){
      this.party.Notes = uniqBy(this.party.Notes, "Id");
     }
    this.reservationActions.emit({ index, partyInfo: this.party});
  }

  closeGuestInfoPanel() {
    this.partyService.selectedParty$.next(null);
    this.partyUnselectedEvent.emit(this.party);
  }

  editParty() {
    this.dialog.open(CustomPopupComponent, {
      width: '500px',
      height: '370px',
      disableClose: true,
      data: { title: '.', update: 'SAVE', cancel: 'CANCEL', componentDetails: this.componentDetails, enableFooter: false }
    });
  }

  editGuest() {
    this.gbs.editGuest = true;
    this.subscriptions.add(this.fs.getContact(this.party.Contact.Id).subscribe(data => {
      if (data && data.Payload && (data.Payload.FirstName != null || this.party.Contact.LastName)) {
        this.fs.resetGuestForm();
        const tabcomponent: TabConfig[] = [{
          tabComponent: PersonalInfoComponent,
          enabledIcon: true,
          tabLabel: 'personalInfoTab',
          tabIcon: 'Path-503 ',
          isDisabled: false
        },
        {
          tabComponent: ContactComponent,
          enabledIcon: true,
          tabLabel: 'contactTab',
          tabIcon: 'Group-593',
          isDisabled: false
        },
        {
          tabComponent: AddressComponent,
          enabledIcon: true,
          tabLabel: 'addressTab',
          tabIcon: 'Group-593',
          isDisabled: false
        },
        {
          tabComponent: PreferencesComponent,
          enabledIcon: true,
          tabLabel: 'prefernencesTab',
          tabIcon: 'Group-601',
          isDisabled: false
        }
        ];

        if (this._settings.CustomGuestFields.length > 0) {
          tabcomponent.push({
            tabComponent: AdditionalinfoComponent,
            enabledIcon: true,
            tabLabel: 'additionalInfoTab',
            tabIcon: 'Group-602',
            isDisabled: false
          });
        }

        let tabsModal = {
          tabs: tabcomponent,
          default: true,
          componentInput: data.Payload,
          isNextButtonAvailable: true,
          isEditTab: true
        };
        this.fs.setGuestBookTabsModel(tabsModal);
        const tabComponentDetails: ComponentDetails = {
          componentName: TabsComponent,
          popupType: 'tabs',
          tabs: tabsModal,
          popUpDetails: {
            isStepper: true,
            eventName: 'notifyParent'
          }
        };

        this.createContactDialogRef = this.dialog.open(CustomPopupComponent, {
          disableClose: true,
          height: '85%',
          width: '65%',
          data: {
            title: 'Edit Guest',
            update: 'ok',
            cancel: 'cancel',
            componentDetails: tabComponentDetails,
            from: ComponentTypes.guestBook,
            back: true,
            standalone: true
          },
        });
        let confirmSubscription: ISubscription = this.ps.confirmedAction$.subscribe((tabOutputInfo) => {
          if (tabOutputInfo.label === 'save' && tabOutputInfo.isEdit && tabOutputInfo.fromComponent === ComponentTypes.guestBook) {
            this.fs.updateGuest(true, tabOutputInfo.inputData, this.createContactDialogRef);
          }
        });
        this.createContactDialogRef.afterClosed().subscribe(() => {
          if (confirmSubscription) { confirmSubscription.unsubscribe();}
        })
      }
      else {
        this.createTabsModalDetailsQuickSeat(false, false, true, null);
      }
    }));
  }

  createTabsModalDetailsQuickSeat(isDefault: boolean, isNext: boolean, isEdit: boolean, tabInputData: any) {
    this.partyService.tabsModal = {
      tabs: this.getQuickSeatComponentDetails(),
      default: isDefault,
      componentInput: tabInputData,
      isNextButtonAvailable: isNext,
      isEditTab: isEdit,
    };

    this.openDialogQuickSeat();
  }

  openDialogQuickSeat() {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '80%',
      width: '45%',
      data: {
        selectedPopupType: PopupType.Waitlist,
        title: this.translateService.instant('popuptitleseatguest'),
        update: 'ok',
        cancel: 'cancel',
        componentDetails: this.getQuickSeatTabDetails(),
        from: ComponentTypes.guestBook,
        back: true,
        standalone: true
      }
    });
    let confirmSubscription: ISubscription = this.ps.confirmedAction$.subscribe((tabOutputInfo) => {
      if (tabOutputInfo === ComponentTypes.guestBook) {
        if (this.partyService.isExistingContactQS) {
          const selectedguest = this.partyService.reservationFormGroup.value.selectedGuest;
          this.partyService.isExistingContactQS = false;
          this.partyService.changeContact(this.party.Id, selectedguest);
        }
        else {
          if (this.party.Contact.Id) {
            const selectedguest = this.partyService.reservationFormGroup.value.selectedGuest;
            if (selectedguest) {
              selectedguest.Id = this.party.Contact.Id;
              this.partyService.updateGuest(true, selectedguest);
            }
          }
        }
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (confirmSubscription) { confirmSubscription.unsubscribe();}
    })
  }

  getQuickSeatTabDetails() {
    return {
      componentName: TabsComponent,
      dimensionType: 'large',
      popupType: 'active',
      tabs: this.partyService.tabsModal,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
  }

  getQuickSeatComponentDetails() {
    return [
      {
        tabComponent: GuestSelectionComponent,
        enabledIcon: true,
        tabLabel: 'popUpGuest',
        tabHeader: 'popuptitleseatguest',
        tabIcon: 'Group-593',
        isDisabled: false
      }
    ];
  }


  getBackgroundColor(partyState: PartyState, partyStatus: number) {
    if (partyState == PartyState.Seated) {
      if (this.party.IsCommunal == undefined) {
        const statusColor = this._settings.Statuses.filter(x => x.Id == partyStatus)[0].Color;
        return 'rgba(' + statusColor.R + ',' + statusColor.G + ',' + statusColor.B + ',' + statusColor.A + ')';
      } else {
        return '#9CC62B';
      }
    } else {
      switch (partyState) {
        case 0:
          return '#5093E1';
        case 3:
          return '#000';
        case PartyState.Open:
          return '#A1A6B4';
        case PartyState.Blocked:
          return '#C1832E';
        default:
          return '#5093E1';
      }
    }
  }

  getBackgroundForReservation(statusId: number) {
    for (let i = 0; i < this._as.partyStatusList.length; i++) {
      if (statusId == this._as.partyStatusList[i].Id) {
        if (this._as.partyStatusList[i].Name == 'Fully Seated') {
          return 'rgb(156, 198, 43)';
        }
        const backgroundForReservation = 'rgb(' + this._as.partyStatusList[i].Color.R + ',' + this._as.partyStatusList[i].Color.G + ',' + this._as.partyStatusList[i].Color.B + ')';
        return backgroundForReservation;
      }
    }
  }
    getTotalSeatingTime(): number {
    if (this.party.State === PartyState.Seated) {
      return Math.round((new Date(this.party.DepartureTime).getTime() - new Date(this.party.SeatingTime).getTime()) / 60000);
    } else if (this.type == ReservationType.Waitlist) {
      return Math.round((new Date(this.party.SeatingTime).getTime() - new Date(this.party.ArrivedAt).getTime()) / 60000);
    } else {
      return 0;
    }
  }

  getContactName(): string {
    if ((this.party.State === PartyState.Seated) && (this.party.Contact.FirstName == null || this.party.Contact.FirstName == '') &&
      (this.party.Contact.LastName == null || this.party.Contact.LastName == '')) {
      return 'Unnamed';
    } else {
      const Title = this.party.Contact.GuestTitle > 0 ? GuestTitle[this.party.Contact.GuestTitle] : '';
      const FirstName = (this.party.Contact.FirstName) ? this.party.Contact.FirstName : ' ';
      const LastName = (this.party.Contact.LastName) ? this.party.Contact.LastName : ' ';
      return Title + ' ' + FirstName + ' ' + LastName;
    }
  }

  getPastReservationStatus(party) {
    this._restaurantTime = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    var dt = moment(this._restaurantTime._d.setHours(0, 0, 0, 0))
    var VisibilityLimit = dt.subtract({ hours: environment.MaxMinutesBackToEditOrSeatReservation });
    if (Utilities.parseDateString(party.ReservedFor) >= new Date(VisibilityLimit.valueOf())) {
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.closeMobileChat();
    this.partyService.isExistingContactQS = false;
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  mobileMessages() {
    this.isMobileChat = true;
    this.inputString = 'messages';
    this.disableMessagePopup = (this.getPastReservationStatus(this.party) && this.party.Type == 0) || this.party.State == PartyState.Seated || this.party.State == PartyState.Left ? true : false;
    this.messageChat.open();
  }

  closeMobileChat() {
    if (this.isMobileChat && this.messageChat) {
      this.isMobileChat = false;
      this.messageChat.close();
    } else if (this.emailChat) {
      this.emailChat.close();
    }
  }

  emailMessages() {
    this.inputString = 'messages';
    this.disableMessagePopup = (this.getPastReservationStatus(this.party) && this.party.Type == 0) || this.party.State == PartyState.Seated || this.party.State == PartyState.Left ? true : false;
    this.emailChat.open();
  }

  ShowTableInfo() {
    const componentDetails: ComponentDetails = {
      componentName: TableNotAvailableForReservationsComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: "",
      popupTitle: this.translateService.instant('informationText')
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '550px',
      height: '450px',
      data: {
        title: this.translateService.instant('informationText'),
        showAction: true,
        update: this.translateService.instant('closeText'),
        componentDetails,
        from: ComponentTypes.tablelayout,
      }
    });
  }

  partySizeupdate() {
    if (this.isPartySizeOpen)
      this.isPartySizeOpen.close();
    this.subscriptions.add(this.partyService.UpdateSeatingPartySize(this.party.Id, this.setSizeConfig[0].value).subscribe(() => {
    }));
  }

  partySizeChanged(ps) {
    this.isPartySizeOpen = ps;
  }

  showPartyAuditLog() {
    const showAlert = false;
    const title = this.translateService.instant('auditlog') + " - " + this.partyService.selectedParty$.value.ConfirmationCode;
    const popUpMessage = [{
      dialogTitle: title, showAlert, type: AuditableEntityType.Party, party: this.partyService.selectedParty$.value
    }];

    const componentDetails: ComponentDetails = {
      componentName: AuditlogComponent,
      dimensionType: 'large',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: popupDialogDimension.createDialogHeight,
      width: popupDialogDimension.createDialogWidth,
      data: {
        title,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: false
      }
    });
  }

  ShowReseatDataIfRequired(RecentlyLeftParty) {
    this.showReseat = false;
    if (this._as.selectedMenuId == Menu.Tables && RecentlyLeftParty && this.IsReseatApplicable(RecentlyLeftParty.DepartureTime)) {
      if (RecentlyLeftParty.Contact.FirstName && RecentlyLeftParty.Contact.LastName) {
        this.guestName = RecentlyLeftParty.Contact.FirstName + ' ' + RecentlyLeftParty.Contact.LastName
      }
      else if (RecentlyLeftParty.Contact.FirstName || RecentlyLeftParty.Contact.LastName) {
        this.guestName = RecentlyLeftParty.Contact.FirstName ? RecentlyLeftParty.Contact.FirstName : RecentlyLeftParty.Contact.LastName
      }
      else {
        this.guestName = this.translateService.instant('un_Named');
      }
      this.showReseat = true;
    }
  }

  UpdateUnseatedTime() {
    if (this._as.selectedMenuId == Menu.Tables && this.partyService.selectedParty$ && this.partyService.selectedParty$.value
      && this.party.RecentlyLeftParty && this.IsReseatApplicable(this.party.RecentlyLeftParty.DepartureTime)) {
      this.showReseat = true;
    }
    else {
      this.showReseat = false;
    }
  }

  IsReseatApplicable(departureTime) {
    let currentRestaurantTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    this.leftTableAgo = moment(currentRestaurantTime).diff(moment(departureTime), "minutes");
    return moment(currentRestaurantTime).diff(moment(departureTime), "minutes") <= this._settings.General.MaxMinutesToReseatPartyAfterLeaving
  }

  setVIP(contact: ContactDTO) {
    this.party.Contact.IsVip = !contact.IsVip;
    this.gbs.updateContactVIPStatus(this.party.Contact.Id, this.party.Contact.IsVip, this.party.Contact.IsFavorite);
  }

  printChit(divId) {
    Util.printChit(divId, GlobalConst.chitPrintStyles);
  }

  updateAddOnDetails(party) {
    this.partyWithAddon = party
  }
  
}


@Pipe({
  name: 'formatTime'
})
export class TimeFormat implements PipeTransform {
  transform(dateToBeChanged: any): any {
    let localMoment = moment(dateToBeChanged)
    if(sessionStorage.getItem(`languages${Utilities.getSessionStorageType()}`) && sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`)) {
      return localMoment.locale(JSON.parse(sessionStorage.getItem(`languages${Utilities.getSessionStorageType()}`)).find(lng => lng.Id ==  sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`))?.Code).format('LT');  
    }
    return localMoment.locale(DEFAULT_LANGUAGE_CODE.toString()).format('LT');
  }

}

@Pipe({
  name: 'tableNameSetter'
})
export class TableNameSetterPipe implements PipeTransform {
  transform(tableId: number, tablesArray: StandaloneTableDTO[]): any {
    const table = tablesArray.filter(x => x.Id == tableId)[0];
    return table.Name;
  }
}

@Pipe({
  name: 'serversSetter'
})
export class ServersSetterPipe implements PipeTransform {
  constructor(private ts: TranslateService) { }
  transform(tableId: number, serversArray: ServerDTO[], serverTableAssignmentArray: TableServerDTO[], type:
    ReservationType, party: any): any {
    const table = serverTableAssignmentArray.find(x => x.StandaloneTableId === tableId);
    const server = serversArray.find(x => table.ServerId === x.Id);
    return server ? server.Name : (type === ReservationType.Tables ? this.ts.instant('noserver') : '-');
  }
}
@Pipe({
  name: 'formatGuestName'
})
export class QuickSeatGuestNameFormatPipe implements PipeTransform {
  constructor(private ps: PartyService) { }
  transform(party: any): any {
    if ((party.State === PartyState.Seated) && (party.Contact.FirstName == null || party.Contact.FirstName == '') &&
      (party.Contact.LastName == null || party.Contact.LastName == '')) {
        if(party.Contact.Id){
          var selectedParty = this.ps.partiesList.filter(p => p.Id == party.Id)[0];
          if(selectedParty){
            if(!selectedParty.Contact.FirstName && !selectedParty.Contact.LastName){
              return 'Unnamed';
            }
            const Title = selectedParty.Contact.GuestTitle > 0 ? GuestTitle[selectedParty.Contact.GuestTitle] : '';
            const FirstName = (selectedParty.Contact.FirstName) ? selectedParty.Contact.FirstName : ' ';
            const LastName = (selectedParty.Contact.LastName) ? selectedParty.Contact.LastName : ' ';
            return Title + ' ' + FirstName + ' ' + LastName;
          }
        }
      return 'Unnamed';
    } else {
      const Title = party.Contact.GuestTitle > 0 ? GuestTitle[party.Contact.GuestTitle] : '';
      const FirstName = (party.Contact.FirstName) ? party.Contact.FirstName : ' ';
      const LastName = (party.Contact.LastName) ? party.Contact.LastName : ' ';
      return Title + ' ' + FirstName + ' ' + LastName;
    }
  }
}

@Pipe({
  name: 'seatingAreaName'
})
export class SeatingAreaNamePipe implements PipeTransform {
  constructor() { }
  transform(party: any, settings: SettingsDTO, standAloneTables: StandaloneTableDTO[]): any {
    if (party.SeatingAreaId) {
      return settings.SeatingAreas.filter(x => x.Id == party.SeatingAreaId)[0].Name;
    }
    const seatingarea = standAloneTables.filter((el) => {
      return party.TableIds.some((f) => {
        if (f === el.Id) {
          return el.SeatingArea;
        }
      });
    });
    if (seatingarea.length > 0) {
      let newval = uniq(seatingarea.map(item => { return item.SeatingArea; }));
      return newval.join();
    }
    return settings.SeatingAreas.filter(x => x.Id !== -1)[0].Name;
  }
}
