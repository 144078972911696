<div class="buildingRoomType-wrapper ag_mb--5 pt-4">
    <div class="building-container">
        <div class="building-list">
            <div class="building-title">{{captions.lbl_department}}</div>
            <div class="buildings-group">
                <p class="buildings"
                [ngClass]="{'selected':(allselected)}" (click)="onAllBuilding($event)">{{captions.lbl_all}}</p>
                <p *ngFor="let building of buildingList;let i = index;" class="buildings"
                    [ngClass]="{'selected':building.isSelected}" (click)="onBuildingChange(building,i)">
                    {{building.viewValue}}
                </p>
            </div>
        </div>
    </div>
    <div class="item-container">
        <div class="item-list">
            <div class="item-search">
                <div class="item-title">{{captions.lbl_postTypes}}</div>
            </div>
            <div class="item-checkbox-group" [ngClass]="{'noDataBlock': itemList && itemList.length == 0}">
                <ng-container  *ngIf="itemList && itemList.length != 0">
                    <custom-checkbox [customCBxChecked]="IsallChecked"
                        [customCBxwrapperClass]="'item-checkbox'"
                        [customCBxlabelText]="captions.lbl_all" (changeEvent)="onallItem($event)">
                    </custom-checkbox>
                </ng-container>

                <ng-container *ngFor="let item of itemList;let i = index">
                    <custom-checkbox [customCBxDisabled]="item.disabled" [customCBxChecked]="item.checked"
                        [customCBxId]="'roomTypeCheckbox' + i" [customCBxwrapperClass]="'item-checkbox'"
                        [customCBxlabelText]="item.viewValue" (changeEvent)="onItemChange($event,item)">
                    </custom-checkbox>
                </ng-container>
                <div class="ag_w--100 ag_text--center noData" *ngIf="itemList && itemList.length == 0"
                    [attr.LiteralID]="'lbl_noDataFound'">
                    {{captions.lbl_noDataFound}}</div>
            </div>
        </div>
    </div>
</div>
