import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'app-form-timepicker',
  templateUrl: './form-timepicker.component.html',
  styleUrls: ['./form-timepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormTimepickerComponent implements OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;
  constructor() { }

  ngOnInit(): void {
  }

  setTime(value){
    if (this.config.cellClick) {
      this.config.cellClick(value);
    }
  }

}
