import { Injectable, ViewContainerRef, inject } from '@angular/core';
import { ICartGroupItem, IUnlockCart } from '../interfaces/request';
import { POSApiService } from './api.service';
import { getExpireTime, groupBy } from './util.functions';
import { error } from 'console';
import { Subscription } from 'rxjs';
import { CartBookingSuccessComponent } from '../components/cart-booking-success/cart-booking-success.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WristbandMappingContainerComponent } from '../components/wristband-mapping-container/wristband-mapping-container.component';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  protected dialog = inject(MatDialog);
  protected router = inject(Router);

  protected apiService = inject(POSApiService);

  payableAmount: number = 0;
  cartGroupItems: ICartGroupItem[] | any[] | null = null;
  cartItems: any[] | null = [];

  expireTimeWatch = new Subscription();
  cartLockState: {
    [key: string | number]: 'Expired' | 'Availalble'
  } = {};

  constructor() { }

  removeCartItem(cartIds: number[]) {
    this.apiService.removeFromCart(cartIds).subscribe({
      next: data => {
        this.setCartDetails();
      }
    })
  }

  unlockAndRemoveCartItems(unlockCartItems: IUnlockCart[], cartIds: number[]) {
    this.apiService.unlockCart(unlockCartItems).subscribe(() => this.removeCartItem(cartIds), (err) => {
      console.error("Unlock Cart item Failed");
      this.removeCartItem(cartIds)
    })
  }

  setCartDetails() {
    this.apiService.getCartItems().subscribe((data) => {
      this.cartGroupItems = [];
      this.cartItems = []
      this.cartLockState = {};
      this.payableAmount = 0;
      this.cartItems = data;
      let _cartGroupItem = data ? groupBy(data, "CartGroupName") : [];
      _cartGroupItem.forEach((cartItems: any) => {
        let _cartDetail = cartItems[0];
        let _cartGroupInfo = this.getDetailFromCartName(_cartDetail.CartGroupName);
        let _cartGroup: ICartGroupItem = {
          GroupName: _cartDetail.CartGroupName,
          Name: _cartGroupInfo.Name,
          PropertyId: _cartDetail.PropertyId,
          Id: _cartGroupInfo.Id,
          BookingDate: _cartDetail.CartItemDetail.Sessions.Sessions[0].Date,
          Size: _cartDetail.CartItemDetail.Size,
          // CoverTypes: _cartDetail.CartItemDetail.CoverTypes,
          Contact: _cartDetail.CartItemDetail.Contact,
          GuestList: [],
          ImageURL: '',
          PackageActivities: [],
          PackageInfo: null,
          TotalPrice: this.getCartGroupPrice(cartItems),
          Items: cartItems,
          ExpireTime: _cartDetail.CartItemDetail.SlotLockResultDTO.ExpiresAtUTC,
          Type: _cartGroupInfo.Type
        }
        this.payableAmount += parseFloat(_cartGroup.TotalPrice.toFixed(2));
        this.cartGroupItems?.push(_cartGroup);
      })

      this.cartGroupItems.forEach((cartGroupItem: ICartGroupItem) => {
        let _checkExpiryTime: number = getExpireTime(cartGroupItem.ExpireTime);
        setTimeout(() => {
          this.checkCartExpireState(cartGroupItem.GroupName, cartGroupItem.ExpireTime)
        }, _checkExpiryTime);
      })

      this.calculateTotalPrice();
    }, error => {
      this.cartGroupItems = [];
      this.cartItems = []
      this.cartLockState = {};
    })
  }

  getDetailFromCartName(cartGroupName){
    let [Type, Id, Name ] = cartGroupName.split('::');
    return {Type, Id, Name}
  }

  calculateTotalPrice() {
    this.payableAmount = 0;
    this.cartGroupItems.forEach((cartGroupItem) => {
      if (this.cartLockState[cartGroupItem.GroupName] !== 'Expired') {
        this.payableAmount += parseFloat(cartGroupItem.TotalPrice.toFixed(2));
      }
    }, 0)
  }

  checkCartExpireState(cartGroupName: string, expireTime: string) {
    let _checkExpiryTime: number = getExpireTime(expireTime);;
    if (_checkExpiryTime <= 0) {
      this.cartLockState[cartGroupName] = 'Expired';
      this.calculateTotalPrice();
      return;
    }
    setTimeout(() => {
      this.checkCartExpireState(cartGroupName, expireTime)
    }, _checkExpiryTime);
  }

  getCartGroupPrice(cartItems): number {
    let _total = cartItems.reduce((lastRes, curr) => {
      let res = (curr.RatePlan.Total + lastRes).toFixed(2)
      return parseFloat(res);
    }, 0)

    return _total;
  }


  showCartBookingSuccess(viewContainerRef: ViewContainerRef, inputData: {cartBookingId: number, wristbandURL: string}): MatDialogRef<CartBookingSuccessComponent> {
    let dialogRef = this.dialog.open(CartBookingSuccessComponent, {
      width: '800px',
      minHeight: '300px',
      viewContainerRef: viewContainerRef,
      data: {}
    })

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.showWristbandContainer(viewContainerRef, inputData)
      } else {
        this.router.navigate(['pos/home']);
      }
    })

    return dialogRef;
  }

  showWristbandContainer(viewContainerRef: ViewContainerRef, {cartBookingId, wristbandURL}: {cartBookingId:  number, wristbandURL: string}) {

    let dialogRef = this.dialog.open(WristbandMappingContainerComponent, {
      width: '1000px',
      minHeight: '500px',
      viewContainerRef: viewContainerRef,
      data: { cartBookingId, wristbandURL }
    })

    dialogRef.afterClosed().subscribe(data => {
        this.router.navigate(['pos/home']);
    })
  }

}


