<section class="schedule-email-wrapper newVersionWrapper">
    <app-dialog-header title='{{captions.hdr_ScheduleEmail}}' (closeDialog)="closePopUp(DialogCloseEnum.Close)"></app-dialog-header>
    <form [formGroup]="scheduleEmailForm" class="form-wrapper pl-3 pt-3">
        <div>
            <div>
                <app-ag-date-picker [inputs]="startdateInputs" (datePickerChange)="onStartDateChanged($event)"></app-ag-date-picker>  
                <app-ag-date-picker [inputs]="enddateInputs" (datePickerChange)="onEndDateChanged($event)"></app-ag-date-picker>  
            </div>
            <div>
                <label class="ag_font--bold">{{captions.lbl_sendTo}}</label>
                <div>
                    <ag-dropdown class="ag_display--block" [placeholder]="captions.tbl_hdr_distributionList" formControlName="distributionList"
                    [source]="distributionList" [required]="true" [errorMessage]="captions.err_MissingDistributionList"></ag-dropdown>
                    <ag-dropdown class="ag_display--block" [placeholder]="captions.lbl_template" formControlName="template"
                    [source]="template" [required]="true" [errorMessage]="captions.err_MissingTemplate"></ag-dropdown>
                </div>
            </div>
            <div>
                <label class="ag_font--bold">{{captions.lbl_sendEmailBefore}}</label>
                <div class="ag_display--flex-wrap">
                    <!-- <app-ag-incremental [inputs]="hoursInput"></app-ag-incremental> -->
                    <mat-form-field [floatLabel]="floatLabel" class="pr-3">
                        <input matInput autocomplete="off" [type]="'number'"
                        min="0" inputtype="nonnegative" max="23" maxLength="2"
                            formControlName="hours" [placeholder]="captions.lbl_hours" numMaxlength required>
                        <mat-error *ngIf="scheduleEmailForm.controls['hours'].hasError('required')">{{captions.err_missing}} {{captions.lbl_hours}}</mat-error>
                    </mat-form-field>
                    <mat-form-field [floatLabel]="floatLabel" class="">
                        <input matInput autocomplete="off" [type]="'number'"
                        min="0" inputtype="nonnegative" max="59" maxLength="2"
                            formControlName="minutes" [placeholder]="captions.lbl_minutes" numMaxlength required>
                        <mat-error *ngIf="scheduleEmailForm.controls['minutes'].hasError('required')">{{captions.err_missing}} {{captions.lbl_minutes}}</mat-error>
                    </mat-form-field>
                    <!-- <app-ag-incremental [inputs]="minutesInput"></app-ag-incremental> -->
                </div>
            </div>
        </div>
        <div>
            <mat-checkbox class="mr-2" formControlName="successStatus" (change)="successStatus($event)">{{captions.lbl_enableSuccessStatusEmail}}</mat-checkbox>
            <div *ngIf="isSuccessStatusEnabled">
                <ag-dropdown class="ag_display--block" [placeholder]="captions.tbl_hdr_distributionList" formControlName="successdistributionList"
                [source]="successdistributionList" [required]="true" [errorMessage]="captions.err_MissingDistributionList"></ag-dropdown>
                <ag-dropdown class="ag_display--block" [placeholder]="captions.lbl_template" formControlName="successtemplate"
                [source]="successtemplate" [required]="true" [errorMessage]="captions.err_MissingTemplate"></ag-dropdown>
            </div>
        </div>
        <div>
            <mat-checkbox class="mr-2" formControlName="failureStatus" (change)="failureStatus($event)">{{captions.lbl_enableFailureStatusEmail}}</mat-checkbox>
            <div *ngIf="isFailureStatusEnabled">
                <ag-dropdown class="ag_display--block" [placeholder]="captions.tbl_hdr_distributionList" formControlName="failuredistributionList"
                [source]="failuredistributionList" [required]="true" [errorMessage]="captions.err_MissingDistributionList"></ag-dropdown>
                <ag-dropdown class="ag_display--block" [placeholder]="captions.lbl_template" formControlName="failuretemplate"
                [source]="failuretemplate" [required]="true" [errorMessage]="captions.err_MissingTemplate"></ag-dropdown>
            </div>
        </div>
    </form>
    <div class="ag_footer--actions ag_display--flex">
        <app-button [buttontype]='actionButton' (valueChange)='onAction($event)'></app-button>
        <app-button [buttontype]='cancelButton' class="pl-2" (valueChange)='onCancel($event)'></app-button>
    </div>
</section>