import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "./http-call.service";
import { Host } from "../globalsContant";

@Injectable()
export class ImageProcessorService {
    constructor(private http: HttpServiceCall) { }

     /**
     * @function GetAllImageByIds()
     * @Inputs referenceids, referencetype, successCallback, errorCallback, extraParams
     * @description get all image by Referencetype and ids
     */
    GetAllImagesByIds(referenceids: any, referencetype: string, isthumbnailonly: boolean, successCallback, errorCallback, extraParams) {
        if(!referenceids || referenceids.length == 0 ){
            return;
        }
        return this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: "GetAllImagesByIds",
            uriParams: { referencetype: referencetype, isthumbnailonly: isthumbnailonly },
            body: referenceids,
            method: HttpMethod.Put,
            showError: false,
            extraParams: extraParams
        });
    }


    /**
     * @function GetAllImageByReference()
     * @Inputs referenceids, referencetype, successCallback, errorCallback, extraParams
     * @description get all image by Referencetype and ids
     */
    GetAllImagesByReference(referenceids: any, referencetype: string, isthumbnailonly: boolean, successCallback, errorCallback, extraParams) {
        if(!referenceids || referenceids.length == 0 ){
            return;
        }
        return this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: "getAllImagesByReference",
            uriParams: { referencetype: referencetype, referenceids: referenceids, isthumbnailonly: isthumbnailonly },
            method: HttpMethod.Get,
            showError: false,
            extraParams: extraParams
        });
    }

    /**
     * @function GetImageByReference()
     * @Inputs referenceid, referencetype, successCallback, errorCallback, extraParams
     * @description get image by Referencetype and id
     */
    GetImagesByReference(referenceid: number, referencetype: string, successCallback, errorCallback, extraParams) {
        return this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: "getImagesByReference",
            uriParams: { referencetype: referencetype, referenceid: referenceid },
            method: HttpMethod.Get,
            showError: false,
            extraParams: extraParams
        });
    }

    DeleteImageByReference(referenceid: number, referencetype: string, successCallback, errorCallback, extraParams) {
        this.http.CallApiWithCallback({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: "deleteImagesByReference",
            uriParams: { referencetype: referencetype, referenceid: referenceid },
            method: HttpMethod.Post,
            showError: true,
            extraParams: extraParams
        })
    }

    /**
     * @function updateImage
     * @param imageId
     * @param imageData
     * @param successCallback
     * @param errorCallback
     * @param extraParams
     * @description Saves image of client/therapist
     */
    updateImage(imageId, imageData, successCallback, errorCallback, extraParams) {
        this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: "updateImageById",
            method: HttpMethod.Put,
            uriParams: { id: imageId },
            body: imageData,
            showError: true,
            extraParams: []
        });
    }

    /**
     * @function SaveImage
     * @param imageData
     * @param successCallback
     * @param errorCallback
     * @param extraParams
     * @description Saves image of client/therapist
     */
    saveImage(imageData, successCallback, errorCallback, extraParams) {
        this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: "saveImage",
            method: HttpMethod.Post,
            body: imageData,
            showError: true,
            extraParams: extraParams
        });
    }

}