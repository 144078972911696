import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn, UntypedFormArray,  UntypedFormControl } from '@angular/forms';

@Directive({
    selector: '[duplicateCheck]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DuplicateCheckDirective, multi: true }]
})
export class DuplicateCheckDirective implements Validator {
    @Input('duplicateCheck') controlName: string;
    @Input('currentIndex') index: number;

    validate(control: AbstractControl): { [key: string]: any } | null {
        return this.controlName ? uniqueValidator(this.controlName, this.index)(control)
            : null;
    }
}

export function uniqueValidator(controlName: string, idx): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const array = (control.parent.parent as UntypedFormArray);
        const controlValues = array.value;
        const clearErrorIdx = controlValues.findIndex((x, i) => x[controlName] === controlValues[idx][controlName] && i !== idx);
        const clearControl = array.controls[clearErrorIdx];
        if (clearErrorIdx > -1 && clearControl) {
            const existingDuplicate = clearControl.get(controlName) as UntypedFormControl;
            let errorCount = 0;
            if (existingDuplicate.errors) {
                errorCount += Object.keys(existingDuplicate.errors).length;
            }
            // Clears only the duplicate error from the control
            if (existingDuplicate.hasError('duplicate')) {
                if (errorCount > 1) {
                    delete existingDuplicate.errors['duplicate'];
                }
                else {
                    existingDuplicate.setErrors(null);
                }
            }
        }
        const isDuplicate = controlValues.find((x, i) => {
            const previousValue = x[controlName];
            if (previousValue && control.value) {
                if (typeof (previousValue) === 'string' && typeof (control.value) === 'string') {
                    return previousValue.toLowerCase() === control.value.toLowerCase() && i !== idx;
                } else {
                    return previousValue === control.value && i !== idx;
                }
            }
            return false;
        });

        return isDuplicate ? { duplicate: { value: control.value } } : null;
    };
}

export function  uniquephoneValidator(prevControl:string,controlName: string, idx): ValidatorFn { 
    return (control: AbstractControl): { [key: string]: any } | null => {
        const array = (control.parent.parent as UntypedFormArray);
        const controlValues = array.value;
        const clearErrorIdx = controlValues.findIndex((x, i) => ((x[prevControl] + x[controlName]) === (controlValues[idx][prevControl] + controlValues[idx][controlName])) && i !== idx);
        const clearControl = array.controls[clearErrorIdx];
        if (clearErrorIdx > -1 && clearControl) {
            const existingDuplicate = clearControl.get(controlName) as UntypedFormControl;
            let errorCount = 0;
            if (existingDuplicate.errors) {
                errorCount += Object.keys(existingDuplicate.errors).length;
            }
            // Clears only the duplicate error from the control
            if (existingDuplicate.hasError('duplicate')) {
                if (errorCount > 1) {
                    delete existingDuplicate.errors['duplicate'];
                }
                else {
                    existingDuplicate.setErrors(null);
                }
            }
        }
        const isDuplicate = controlValues.find((x, i) => {
            const previousValue = x[prevControl] + x[controlName];           
            const currvalue =  controlValues[idx][prevControl] + control.value;
            if (previousValue && currvalue) {
                if (typeof (previousValue) === 'string' && typeof (currvalue) === 'string') {
                    return previousValue.toLowerCase() === currvalue.toLowerCase() && i !== idx;
                } else {
                    return previousValue === currvalue && i !== idx;
                }
            }
            return false;
        });

        return isDuplicate ? { duplicate: { value: control.value } } : null;
    };
}
