<div class="profile-select">
    <div class="profile-select__form-container">
        <dynamic-form class="profile-select__searchbycgp"  [config]="guestConfig" #form="dynamicForm"></dynamic-form>
    </div>
    <div class="profile-select__actions">
        <activities-app-button [buttontype]="buttonSearch" (valueChange)='search($event)'></activities-app-button>
    </div>   
    <div class="profile-select__table">
         
    <table class="profile-select__table-view" [ngClass]="(guestContacts.length > 0) ? '' : 'd-none'">
        <thead class="profile-select__table-view-header">
          <tr class="seat-grid-header seat-border-color">
            <th class="p-3 ">
              {{'guestNameText' | translate}}
            </th>
            <th class="p-3">
              {{'guestEmailText' | translate}}
            </th>
            <th class="p-3">
              {{'guestBookDetailsPhone' | translate}}
            </th>
            <th class="p-3" *ngIf="isMembershipAvailable">
              {{_settings.General.MembershipNumberName}}
            </th>
          </tr>
        </thead>
        <tbody class="profile-select__table-view-body ">
          <tr *ngFor="let item of guestContacts" class="seat-border-color seat-grid-content" (click)="getGuestDetails(item )">
            <td class="p-3">
              {{item.FirstName}} {{item.LastName}}
            </td>
            <td class="p-3">
              <span *ngIf="item.EmailAddress"> {{item.EmailAddress}}</span>
              <span *ngIf="!item.EmailAddress">NA</span>
            </td>
            <td class="p-3">
              <span *ngIf="item.PhoneNumber">{{item.PhoneNumber | phoneNumberSelector1 : item.PhoneNumber2: item.CountryPhoneCode : item.CountryPhoneCode2 :searchValue }}</span>
              <span *ngIf="!item.PhoneNumber">NA</span>
            </td>
            <td class="p-3" *ngIf="isMembershipAvailable">
              <span *ngIf="item.TrackMembershipNumber">{{item.TrackMembershipNumber}}</span>
              <span *ngIf="!item.TrackMembershipNumber">NA</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</div>