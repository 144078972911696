import { TableBlockingRuleFrequencyType, DayOfWeek, TableBlockBy } from '../constants/commonenums';
import { ActivityCustomizationsDTO, NamedEntityDTO } from './RestaurantDTO';
import { newTimeRangeDTO } from './TimeRangeDTO';

export class TableBlockingRuleDTO {
    Id: number;
    Frequency: TableBlockingRuleFrequencyDTO;
    Shifts: NamedEntityDTO[];
    StartDate: Date;
    EndDate: Date;
    TableIds: number[];
    FloorPlan: string;
    AllocationTypeId?: number;
    BlockingReason: string;
    BlockBy: TableBlockBy;
    DayOfWeek: DayOfWeek;
    EffectiveRange?: newTimeRangeDTO[];
    ActivitySessionId?: number;
    DayName?: string;
  }
  
  
  export class TableBlockingRule {
    Frequency: string;
    Shifts: string;
    StartDate: string;
    EndDate: string;
    AllocationType : string;
    BlockingReasion : string;
  }
  
  export class TableBlockingRuleFrequencyDTO {
    Type: TableBlockingRuleFrequencyType;
    DayOfWeek: DayOfWeek;
    Date: Date;
  }

  export class ActivityBlockingRuleLookUp{
    ActivityBlockingRule: {[key: number]: ActivityCustomizationsDTO}
  }