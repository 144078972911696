import { Pipe, PipeTransform } from '@angular/core';
import { ContentView } from '@constants/commonenums';
import { ReservationDTO } from '@models/InputContact';
import { Utilities } from '@utilities/utilities';

@Pipe({
  name: 'effectiveRangeParties'
})
export class EffectiveRangePartiesPipe implements PipeTransform {

  transform(allParties: any, range: any, viewType: ContentView, reservationForDate?: Date): any {
    // console.log(allParties, range);
    let effectiveStartDate: Date;
    let effectiveEndDate: Date;
    if (typeof range.Start === 'string' && typeof range.End === 'string') {
      effectiveStartDate = range.Start.includes('Z') ? Utilities.parseDateString(range.Start.split('Z')[0]) :
        Utilities.parseDateString(range.Start);
      effectiveEndDate = range.End.includes('Z') ? Utilities.parseDateString(range.End.split('Z')[0]) :
        Utilities.parseDateString(range.End);
    } else {
      effectiveStartDate = Utilities.parseDateString(range.Start);
      effectiveEndDate = Utilities.parseDateString(range.End);
    }
    if (viewType === ContentView.grid) {
      const reservationDate = Utilities.parseDateString(allParties.ReservedFor ? allParties.ReservedFor : allParties.ArrivedAt);
      return (reservationDate >= effectiveStartDate) && (reservationDate <= effectiveEndDate);
    } else if (viewType === ContentView.list) {
      allParties = allParties.filter((party: ReservationDTO) => {
        const reservationDate = Utilities.parseDateString(party.ReservedFor ? party.ReservedFor : party.ArrivedAt);
        return (reservationDate >= effectiveStartDate) && (reservationDate <= effectiveEndDate);
      });
      return allParties;
    }
  }

}
