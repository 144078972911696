

export namespace API {

  export class GuestDisplayModel {
    guestId?: string;
    lastName: string;
    firstName: string;
    initial: string;
    title: string;
    /**
     *
     */
    constructor() {
      this.guestId = '';
        this.lastName = '';
        this.firstName = '';
        this.initial = '';
        this.title = '';
    }
  }

  export class GuestPreference {
    guestId: number;
    notificationMethod: number;
    isNotifyStaffOnArrival: boolean;
    isNotifyVIPArrival: boolean;
    guestSpecialRequirementId: number;
    preferredLanguageId: number;
    isRequestSmoking: boolean;
    preferenceList: [
      {
        sourceType: number;
        sourceRefId: number;
        preferenceId: number;
        id: number;
      }
    ];
    id: number;

    /**
     *
     */
    constructor() {
      this.guestId = 0;
      this.notificationMethod = 0;
      this.isNotifyStaffOnArrival = false;
      this.isNotifyVIPArrival = false;
      this.guestSpecialRequirementId = 0;
      this.preferredLanguageId = 0;
      this.isRequestSmoking = false;
      this.preferenceList = [
        {
          sourceType: 0,
          sourceRefId: 0,
          preferenceId: 0,
          id: 0,
        }
      ];
      this.id = 0;
    }
  }

  export class PmsGuest {
    guestId?: string;
    isCondoOwner: boolean;
    imageReferenceId?: string;
    vipTypeId: number;
    guestTypeId: number;
    originId: number;
    segment1Id: number;
    segment2Id: number;
    marketChannelId: number;
    guestPreference: GuestPreference;
    accountNumber: number;
    isRegFlagComment: boolean;
    comment: string;
    arAccount: string;
    paymentTypeId: number;
    paymentTransactionId: number;
    createdDTM?: Date;
    createdBy?: number;
    modifiedDTM?: Date;
    modifiedBy?: number;
    id: number;

    constructor() {
      this.guestId = "";
      this.imageReferenceId = '00000000-0000-0000-0000-000000000000';
      this.isCondoOwner = false;
      this.vipTypeId = 0;
      this.guestTypeId = 0;
      this.originId = 0;
      this.segment1Id = 0;
      this.segment2Id = 0;
      this.marketChannelId = 0;
      this.guestPreference = new GuestPreference();
      this.accountNumber = 0;
      this.isRegFlagComment = false;
      this.comment = "";
      this.arAccount = "";
      this.paymentTypeId = 0;
      this.paymentTransactionId = 0;
      this.createdDTM = new Date;
      this.createdBy = 0;
      this.modifiedDTM = new Date;
      this.modifiedBy = 0;
      this.id = 0;
    }
  }
  export interface Guest {
    guestId?: string;
    lastChangeId?: string;
    pmsGuest: PmsGuest;
    lastName: string;
    firstName: string;
    initial: string;
    title: string;
    companyName: string;
    alias: string;
    pronounced: string;
    guestProfileAddress: [
      {
        addressLine1: string,
        addressLine2: string,
        addressLine3: string,
        city: string,
        state: string,
        country: string,
        county: string,
        postalCode: string,
        isPrivate: boolean,
        platformAddressUuid?: string;
      }
    ];
    guestProfileContact: GuestProfileContact[];
    dateOfBirth?: Date,
    fax: string,
    gender: string,
    jobTitle: string,
    nationality: string,
    passportNumber: string,
    socialSecurityNumber: string,
    driversLicense: string,
    patronId: string,
    rank: string,
    clubCardNumber: string,
    memberNumber: string,
    memberFamilyType: string,
    primaryMembership: string,
    id: number,
    anniversaryDate?: Date,
    paymentMethodId: number,
    name?: string,
    guestProfileCreditCardDetail: guestCreditCardDetail[];
    isSynced:boolean;
    outlookContactId:string;
  
  }

  export interface guestCreditCardDetail {
    tokenTransId: number,
    isActive: boolean,
    isPrimary: boolean;
  }


  export interface guestpreference {
    sourceType: number;
    sourceRefId: number;
    preferenceId: number;
    id: number;
  }


  export interface GuestSearchFields {
    lastName: string;
    firstName: string;
    cityName: string;
    stateCode: string;
    postalCode: string;
    stateName: string;
    county: string;
    countryName: string;
    nationality: string;
    phone: string;
    socialSecurityNumber: string;
    emailAddress: string;
    driversLicense: string;
    playerId: string;
    memberTypeId: number;
    includeInactive: boolean;
    PMSGuestSearchCriteria: PMSSearchCriteria;
  }
  export interface ContactRelationshipMaintenance {
    id: number,
    code: string;
    name: string;
    listOrder: any;
    isRouteLocalCalls: any;
    isActive: boolean;
  }
  export interface GuestAdditionalSearchCriteria {
    companyName: string;
    guestID: string;
    clubMember: string;
    memberNumber: string;
    memberType: string;
    memberCategory: string;
    ownerRoomNo: string;
    ownerContractNo: string;
    ownerARNo: string;
    createdOn: Date;
    updatedOn: Date;
  }

  export interface PMSSearchCriteria {
    guestTypeId: number;
    originId: number;
    segment1Id: number;
    segment2Id: number;
    marketChannelId: number;
    aRNumber: string;
    accountNumber: number;
  }
  export interface GuestHistory {
    guestActivityHistories: GuestActivityHistory[];
  }
  export interface GuestActivityHistory {
    activityType: string;
    date: Date;
    course?: string;
    status: string;
    service: string;
    appointment: Date;
  }

  export interface GuestStayHistory {
    confirmationNumber: string;
    propertyId: number;
    arrivalDate: Date;
    checkInTime: Date;
    departureDate: Date;
    checkOutTime: Date;    
    roomType: number;
    tariff: number;
    roomNumber: string;
    rate: number;
    roomRevenue: number;
    stayId: number;
    totalRevenue: number;    
    totalHours: number;

  }

  export interface GuestContactDetails {
    id: number;
    guestId: string;
    title: string;
    firstName: string;
    lastName: string;
    middleName?:string;
    alias: string;
    companyName: string;
    jobTitle?:string;
    addresses: [
      {
        addressLine1: string,
        addressLine2: string,
        addressLine3: string,
        city: string,
        state: string,
        country: string,
        county: string,
        postalCode: string,
        isPrivate: boolean,
        platformAddressUuid?: string;
      }
    ];
    contactDetails: GuestProfileContact[];
    birthDate?: Date,
    contactRelationshipId: number;
    isSynced?:boolean;
    outlookContactId?:string;
    modified?:Date;
  }
  export interface ContactRelationshipMaintenance {
    id: number,
    code: string;
    name: string;
    listOrder: any;
    isRouteLocalCalls: any;
    isActive: boolean;
  }


  export interface GuestContact {
    id: number;
    primaryGuestId: string;
    contactGuestId: string;
    contactRelationshipId: number;
  }

  export interface OutlookContact {
    givenName?: string;
    surName?: string;
    title?: string;
    jobTitle?: string;
    companyName?: string;
    emailAddresses?: emailAddress[];
    businessAddress?: OutlookAddress;
    homePhones?: string[];
    mobilePhone?: string;
    businessPhones?: string[];
    id?: string;
    createdDateTime?: string;
    lastModifiedDateTime?: string;
    birthday?: string;


  }
  export interface OutlookAddress {
    street?: string;
    city?: string;
    state?: string;
    countryOrRegion?: string;
    postalCode?: string;
  }
  export interface emailAddress {
    address: string;
    name: string;
  }
  export interface SyncHistory {
    id: number;
    syncFeature: number;
    syncTime: string;
    originId: string;
    deltaLink: string;
  } 

}

export namespace PMSinput {
    export interface PMScontacts {
    contacts: API.GuestContactDetails[];
    syncHistory: API.SyncHistory;
  }

  export interface GuestContactSync {
    guestId :string;
    outlookdataId:string;
    syncHistory: API.SyncHistory;
  }
}

export namespace UI {
  export interface Guest {
    lastChangeId: string;
    guestId?: string;
    url?: string;
    imageId?: number;
    imageReferenceId?: string;
    pmsGuest: {
      guestId?: string,
      isCondoOwner: boolean,
      vipTypeId: number,
      guestTypeId: number,
      originId: number,
      segment1Id: number,
      segment2Id: number,
      marketChannelId: number,
      guestPreference: {
        guestId: number,
        notificationMethod: number,
        isNotifyStaffOnArrival: boolean,
        isNotifyVIPArrival: boolean,
        guestSpecialRequirementId: number,
        preferredLanguageId: number,
        isRequestSmoking: boolean,
        preferenceList: [
          {
            sourceType: number,
            sourceRefId: number,
            preferenceId: number,
            id: number
          }
        ],
        id: number
      },
      accountNumber: number,
      isRegFlagComment: boolean,
      comment: string,
      arAccount: string,
      paymentTypeId: number,
      paymentTransactionId: number,
      createdDTM?: Date,
      createdBy?: number,
      modifiedDTM?: Date,
      modifiedBy?: number,
      id: number
    };
    lastName: string;
    firstName: string;
    initial: string;
    title: string;
    companyName: string;
    alias: string;
    pronounced: string;
    isSynced?:boolean;
    outlookContactId?:string;
    guestProfileAddress: [
      {
        addressLine1: string,
        addressLine2: string,
        addressLine3: string,
        city: string,
        state: string,
        country: string,
        county: string,
        zipCode: string,
        isPrivate: boolean
      }
    ];
    guestProfileContact: GuestProfileContact[];
    dateOfBirth?: Date;
    fax: string;
    gender: string;
    jobTitle: string;
    nationality: string;
    passportNumber: string;
    socialSecurityNumber: string;
    driversLicense: string;
    patronId: string;
    rank: string;
    clubCardNumber: string;
    memberNumber: string;
    memberFamilyType: string;
    primaryMembership: string;
    id: number;
    anniversaryDate: Date;
    paymentMethodId: number;
    guestProfileCreditCardDetail: guestCreditCardDetail[];
 
  }

  export interface guestCreditCardDetail {
    tokenTransId: number,
    isActive: boolean,
    isPrimary: boolean;
  }

  export interface Phone {
    id: number;
    phoneType: number;
    phoneNumber: string;
    extension: string;
    countryCode: string;
    isPrimary: boolean;
    isPrivate: boolean;
    disabled?: boolean;    
    phoneTypeValue?: string;
  }
  export interface Email {
    contactType: string;
    emailType: number;
    emailLabel: string;
    emailId: string;
    primaryEmail: boolean;
    privateInformtionEmail: boolean;
    platformContactUuid?: string;
  }



  export interface GuestSearchGridRecord {
    guestGuid: string;
    id: number;
    lastName: string;
    firstName: string;
    initial: string;
    title: string;
    address: string;
    emailId: string;
    phoneNumber: string;
    guestType: string;
    guestTypeId: number;
    marketChannelId: number;
    originId: number;
    segment1Id: number;
    segment2Id: number;
    city: string;
    state: string;
    postalCode: string;
    stateName: string;
    county: string;
    country: string;
    nationality: string;
    company: string;
    clubNumber: string;
    aRNumber: string;
    memberNumber: string;
    memberType: string;
    memberCategory: string;
    selected?: boolean;
    orderId: number;
    patronId: string;
  }
  export interface GuestSearch {
    id: string | number,
    firstName: string,
    lastName: string,
    initial: string,
    title: string,
    name: string;
    userName: string;
    guestId: string;
    address: string;
    phoneNumber: string;
    cellNumber: string;
    workNumber: string;
    recapPhoneNumber: string;
    mailId: string;
    age: string;
    guestType: number,
    origin: number,
    segment1: number,
    segment2: number,
    marketChannel: number,
    vipTypeId: number,
    preferences: { id: number, name: string }[];
    isRegFlagComment: boolean,
    patronId: string,
    comment: string,
    guestPreference: {
      guestId: number,
      notificationMethod: number,
      isNotifyStaffOnArrival: boolean,
      isNotifyVIPArrival: boolean,
      guestSpecialRequirementId: number,
      guestSpecialRequirementName: string,
      preferredLanguageId: number,
      isRequestSmoking: boolean,
      preferenceList: guestpreference[],
      id: number
    },
    guestProfileCreditCardDetail:
    {
      tokenTransId: number,
      isActive: boolean,
      isPrimary: boolean;
    }[],
    paymentMethodId: number;
  }

  export interface guestpreference {
    sourceType: number;
    sourceRefId: number;
    preferenceId: number;
    id: number;
  }

  export enum Gender {
    MALE = 'M',
    FEMALE = 'F',
    Male = 'Male',
    Female = 'Female'
  }

  export const enum AdditionalSearchFields {
    COMPANY_NAME = 1,
    GUEST_ID = 2,
    CLUB_MEMBER = 3,
    MEMBER_NUMBER = 4,
    MEMBER_TYPE = 5,
    MEMBER_CATEGORY = 6,
    OWNER_ROOM_NO = 7,
    OWNER_CONTRACT_NO = 8,
    OWNER_AR_NO = 9,
    CREATED_ON = 10,
    UPDATED_ON = 11
  }

  // Advanced Search fields
  export interface GuestSearchFields {
    id: number;
    lastName: string;
    firstName: string;
    guestTypeId: number;
    originId: number;
    segment1Id: number;
    segment2Id: number;
    marketChannelId: number;
    cityName: string;
    stateCode: string;
    postalCode: string;
    stateName: string;
    county: string;
    countryName: string;
    nationality: string;
    phone: string;
    socialSecurityNumber: string;
    aRNumber: string;
    accountNumber: string;
    emailAddress: string;
    driversLicense: string;
    playerId: string;
    memberTypeId: number;
    includeInactive: boolean;
    additionalSearch?: GuestAdditionalSearchInfo;
  }

  export interface GuestContactSearchGridRecord {
    guestId: string;
    id: number;
    lastName: string;
    firstName: string;
    contactRelationshipId: number;
    alias: string;
    companyName: string;
    title: string;
    birthday: Date;
    address: string;
    city: string;
    state: string;
    county: string;
    country: string;
    postalCode: string;
    phoneNumber: string;
    emailId: string;
    fax: string;
  }


  export interface GuestAdditionalSearchInfo {
    companyName: string;
    guestID: string;
    clubMember: string;
    memberNumber: string;
    memberType: string;
    memberCategory: string;
    ownerRoomNo: string;
    ownerContractNo: string;
    ownerARNo: string;
    createdOn: Date;
    updatedOn: Date;
  }

  export interface ContactRelationshipMaintenance {
    id: number,
    code: string;
    name: string;
    listOrder: any;
    isRouteLocalCalls: any;
    isActive: boolean;
  }


  export interface GuestContact {
    id: number;
    name: string;
    guestId: string;
    relationship: string;
    generalInfo: GuestContactInfo[];
    addressInfo: GuestContactInfo[];
    phoneInfo: GuestContactInfo[];
    contactRelationshipId: number;
  }

  export interface GuestContactInfo {
    type: string;
    displayName: string;
    displayvalue: string;
    count?: number;
    list?: any[];
  }
  export interface GuestPhoneNumbers {
    primary?: string;
    home: string[];
    office: string[];
    mobile: string[];
    count?: number
  }
  export interface GuestEmailIds {

    primary?: string;
    personal: string[];
    work: string[];
    count?: number
  }




  export interface GuestStayHistory {
    confirmationNumber: string;
    property: string;    
    arrivalDate: Date;
    roomNumber: string;
    checkInTime?: Date;
    departureDate?: Date;
    checkOutDate?: Date;
    checkOutTime?: Date;
    tariff: string;
    rate: number;
    roomRevenue: number;
    totalRevenue: number;
    building: string;
    surveyPercentScore: number;   
    totalHours: number;
    getNights(): number;
  }

  export class GuestStayHistoryClass implements GuestStayHistory {
    confirmationNumber: string;
    property: string;    
    arrivalDate: Date;
    roomNumber: string;
    checkInTime?: Date;
    departureDate?: Date;
    checkOutDate?: Date;
    checkOutTime?: Date;
    tariff: string;
    rate: number;
    roomRevenue: number;
    totalRevenue: number;
    building: string;
    surveyPercentScore: number;
    stayId: number;    
    totalHours: number;

    public getNights() {
      return this.getDateDiff(this.departureDate, this.arrivalDate);
    }

    private getDateDiff(date1: Date, date2: Date): number {
      var diff = Math.abs(new Date(date1).getTime() - new Date(date2).getTime());
      return Math.ceil(diff / (1000 * 3600 * 24));      
    }

  }

  export interface GuestSpaHistory {
    date: Date;
    service: string;
    appointment: Date;
    status: string;
  }

  export interface GuestGolfHistory {
    date: Date;
    course: string;
    time: Date;
    cartNo: string;
    ticketNo: string;
    outlet: string;
    caddy: string;
    status: string;
  }
}


export interface GuestProfileContact {
  playerId?: number;
  id?:number;
  type: number;
  name: string;
  description: string;
  value: string;
  isPrivate: boolean;
  isPrimary: boolean;
  platformContactUuid?: string;
}

export interface ContactPhoneType {
  id?: number;
  description?: string;
  type?: string;
}
export interface ContactEmailType {
  id?: number;
  description?: string;
  type?: string;
}

export enum StayStatus {
  None=0,
  Reserved = 1,
  Denied = 2,
  Lost = 3,
  CheckedIn = 4,
  CheckedOut = 5,
  Cancelled = 6,
  Waitlisted = 7,
  Walked = 8,
  NoShow = 9,
  History = 10,
  ArrivedNotCheckIn = 11
}

export enum ObjectType {
  Date = 1,
  Dropdown,
  Text,
  Comment
}