<app-dialog-header [title]="title" (closeDialog)="close()"></app-dialog-header>

<div class="cart-authorize-wrapper ag_container--padding-md">
   <div class="ag_mb--2">
     <mat-form-field [floatLabel]="floatLabel" class="first-container">
        <input  [(ngModel)]="email" matInput (ngModelChange)="onEmailTyped($event)" placeholder="Email">
     </mat-form-field>
   </div> 
   <div class="table-wrpper">
     <ng-container *ngIf="tableData.length > 0">
      <table>
        <tr>
          <th>{{captions.lbl_name}}</th>
          <th>{{captions.lbl_email}}</th>
        </tr>
        <tr class="ag_cursor--pointer" *ngFor="let x of tableData" [ngClass]="[x.selected?'selected':'']" (click)="onSelect(x)">
          <td>{{x.name}}</td>
          <td>{{x.email}}</td>
     
        </tr>
      </table>
     </ng-container>
    <ng-container *ngIf="tableData.length==0">
      {{captions.lbl_VcartPaymentlinkEmailNoData}}
    </ng-container>
   </div> 

</div>

<app-dialog-footer [buttonObj]="buttonObj" (cancel)="close()" (save)="save($event)"></app-dialog-footer>
