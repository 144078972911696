import { throwError as observableThrowError, Observable, ReplaySubject } from 'rxjs';
import { catchError, finalize ,tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { allowedUrl,reservationApiUrl } from '../globalsContant';





@Injectable()
export class loadingInterceptor implements HttpInterceptor, OnDestroy {
  public count: number = 0;
  public statusCount: number = 0;
  public isLoaderRequired: boolean;
  public blockLoader = [];
  public showLoader: boolean = true;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public router: Router) { }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    try {
      let checkLoaderrequired=sessionStorage.getItem('isLoaderrequired');
      this.showLoader = (this.blockLoader.indexOf(req.url) != -1);
      this.isLoaderRequired = req.headers.get('isLoaderRequired') == 'true';
      const loadingContainer = document.getElementById('cover-spin');
      const ApiEndUrl = req.url.split('/').pop().toString().toLowerCase();
      const AllowedUrl = (allowedUrl.indexOf(ApiEndUrl) !== -1);

      //for versa reservation loader
      const resurlsList= ['ReservationService/GuestInformation/guestIds','ReservationService/UserConfiguration'];
      const customMessageIndex= resurlsList.findIndex(x=>req.url.toLowerCase().includes(x.toLowerCase()));
      if (AllowedUrl || this.isLoaderRequired) {
        // this.ToggleLoaderInterceptor(true);
        switch(customMessageIndex) {
          case reservationApiUrl.GuestId:
            this.ToggleLoaderWithMessageInterceptor(true,'Retrieving Guest Details..')
            break;
          case reservationApiUrl.ueserConfig:
            this.ToggleLoaderWithMessageInterceptor(true,'Reading User Config..')
            break;
          default:
            this.ToggleLoaderInterceptor(true)
        }

  
      }
      if(this.count < 0){
        this.count = 0;
      }
      this.count++;

      return next.handle(req).pipe(tap((event: any) => {
        if (event.status === 200) {
          this.statusCount++;
          if (this.count === this.statusCount) {
            if (loadingContainer != null) {
               this.ToggleLoaderInterceptor(false);
              this.ToggleLoaderWithMessageInterceptor(false,'')
            }
          }
        }
      },
        (err: any) => {
          this.statusCount++;
          if (this.count === this.statusCount) {
            if (loadingContainer != null) {
              this.ToggleLoaderInterceptor(false);
              this.ToggleLoaderWithMessageInterceptor(false,'')
            }
          }
        }),
        catchError(err => {

          if (loadingContainer != null) {
            this.ToggleLoaderInterceptor(false);
            this.ToggleLoaderWithMessageInterceptor(false,'')
          }
          return observableThrowError(err);
        }), finalize(() =>{
          this.count--;
          if(this.count <= 0 && !checkLoaderrequired){
            this.ToggleLoaderInterceptor(false);
            this.ToggleLoaderWithMessageInterceptor(false,'')
          }
        }));

    }
    catch (ex) {
      console.log("Spinner issue caught -> ", ex);
    }
  }

  ToggleLoaderInterceptor(loader) {
    const loadingContainer = document.getElementById('cover-spin');
    const loadingContainerCustom = document.getElementById('custom-cover-spin');
    if (loadingContainer) {
        if (loader && loadingContainerCustom.style.display=='none') {
            loadingContainer.style.display = 'block';
            document.getElementsByTagName('body')[0].classList.remove("backdrop-loading-off");
            document.getElementsByTagName('body')[0].classList.add("backdrop-loading-on");
        } else {
            loadingContainer.style.display = 'none';
            document.getElementsByTagName('body')[0].classList.remove("backdrop-loading-on");
            document.getElementsByTagName('body')[0].classList.add("backdrop-loading-off");
        }
    }
} 


ToggleLoaderWithMessageInterceptor(loader, loaderMessage = '') {
  const loadingContainer = document.getElementById('custom-cover-spin');
  const loadingContainerMessage = document.getElementById('custom-cover-message');
  const loadingContainerCover = document.getElementById('cover-spin');
  if (loadingContainer ) {
      if (loader && loadingContainerCover.style.display=='none') {
          loadingContainer.style.display = 'block';
          document.getElementsByTagName('body')[0].classList.remove("backdrop-loading-off");
          document.getElementsByTagName('body')[0].classList.add("backdrop-loading-on");
      } else {
          loadingContainer.style.display = 'none';
          document.getElementsByTagName('body')[0].classList.remove("backdrop-loading-on");
          document.getElementsByTagName('body')[0].classList.add("backdrop-loading-off");
      }
      if (loaderMessage) {
          loadingContainerMessage.innerText = loaderMessage;
      } else {
          loadingContainerMessage.innerText = 'Processing...';
      }
   
  }
}

}
