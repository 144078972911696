import { Component, Input, OnInit } from '@angular/core';
import { PopupService } from '@popup-module/popup.service';

@Component({
  selector: 'app-table-not-available-for-reservations',
  templateUrl: './table-not-available-for-reservations.component.html',
  styleUrls: ['./table-not-available-for-reservations.component.scss']
})
export class TableNotAvailableForReservationsComponent implements OnInit {
  @Input() data: any;
  constructor(private ps: PopupService) { }

  ngOnInit() {
  }
  close(eve) {
    this.ps.closeDialog$.next();
  }
  submit() {
  }

}

