
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@app/core/extensions/register-routes';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

//if (environment.production) { //commented the code for fixing console errors other than change detection error
  enableProdMode();
  if(window){
    window.console['customLog'] = window.console.log;

    window['enableConsoleLog'] = function(){
      window.console.log = window.console['customLog'];
    }
    window.console.log = function(){};
  }
//}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
