import { Pipe, PipeTransform } from "@angular/core";
import { AppService } from "@app/app.service";

@Pipe({ name: 'partySourceOTA' })

export class PartySourceOTAPipe implements PipeTransform {
  // tslint:disable-next-line: variable-name
  constructor(private _as: AppService) {

  }

  transform(partySourceId) {
    return this._as.getPartySourceName(partySourceId);
  }
}