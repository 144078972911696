import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { LetterSetupBusiness } from '../letter-setup.business';
import { CreateLetterInputs, LetterLandingInputs, LetterTableContent, LetterTemplateType } from '../letter.model';

@Component({
  selector: 'app-sale-chit',
  templateUrl: './sale-chit.component.html',
  styleUrls: ['./sale-chit.component.scss'],
  providers: [LetterSetupBusiness]
})
export class SaleChitComponent implements OnInit {
  childForm: UntypedFormGroup;
  captions: any;
  createInputs: CreateLetterInputs;
  isViewOnly: boolean;
  landingPageInputs: LetterLandingInputs;
  originalData: LetterTableContent[];
  showInactiveToggle: boolean;
  constructor(private localization: Localization
    ,private _letterBusiness : LetterSetupBusiness) { }
  ngOnInit(): void {
    this.captions = this.localization.captions.retailsetup;
    this.refreshGrid();
    this.landingPageInputs = {
      searchPlaceholder: this.captions.lbl_searchByLetterCodeLetterName,
      createBtnLabel: this.captions.btn_createletter,
      tableOptions: this.getTableOptions(false)
    };
    this.createInputs = {
      letterType: LetterTemplateType.RetailSaleChit,
      codePlaceholder: this.captions.lbl_LetterCode,
      namePlaceholder: this.captions.lbl_LetterName,
      createTitle: this.captions.pg_title_createSaleChitLetter,
      editTitle: this.captions.pg_title_editSaleChitLetter,
      editorLabel: this.captions.lbl_Letter
    };   
  }

  async refreshGrid() {
    this.originalData = await this._letterBusiness.getTemplates(LetterTemplateType.RetailSaleChit, this.showInactiveToggle);
  }
  onToggleChange(event) {
    this.showInactiveToggle = event;
    this.refreshGrid();
  }

  getTableOptions(isViewOnly: boolean) {
    return [
      {
        TableHdrData: this.GetTableHeader(),
        TablebodyData: this.originalData,
        EnableActions: true,
        TableDraggable: true,
        IsViewOnly: isViewOnly,
        Sortable: "listOrder",
      }
    ];
   } 
   GetTableHeader(): any {
    return [{ "title":this.captions.tbl_hdr_letterCode, "jsonkey": "code", "sortable":true },
    { "title": this.captions.tbl_hdr_letterName, "jsonkey": "name", "sortable":false },
    { "title":this.captions.tbl_hdr_listOrder, "jsonkey": "listOrder", "alignType": "right", "sortable":true },
    { "title": this.captions.active, "jsonkey": "active", "sortable": true, "type": "toggle" },
    { "title": this.captions.default, "jsonkey": "isInActive", "sortable": true ,"type": "toggle"}];
  }
}
