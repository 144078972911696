import { Component, ContentChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { filter, switchMapTo, take } from 'rxjs/operators';
import { EditModeDirective } from './edit-mode.directive';
import { ViewModeDirective } from './view-mode.directive';

@Component({
  selector: 'editable',
  templateUrl: './editable.component.html'
})
export class EditableComponent {
  @ContentChild(ViewModeDirective, {static: true}) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective, {static: true}) editModeTpl: EditModeDirective;
  @Output() update = new EventEmitter();

  editMode = new Subject();
  editMode$ = this.editMode.asObservable();
  subscriptions: Subscription = new Subscription();

  mode: 'view' | 'edit' = 'view';


  constructor(private host: ElementRef) {
  }

  ngOnInit() {
    this.viewModeHandler();
    this.editModeHandler();
  }

  toViewMode() {
    this.update.next();
    this.mode = 'view';
  }

  private get element() {
    return this.host.nativeElement;
  }

  private viewModeHandler() {
    this.subscriptions.add(fromEvent(this.element, 'dblclick').pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.mode = 'edit';
      this.editMode.next(true);
    }));
  }

  private editModeHandler() {
    const clickOutside$ = fromEvent(document, 'click').pipe(
      filter(({ target }) => this.element.contains(target) === false),
      take(1)
    )

    this.subscriptions.add(this.editMode$.pipe(
      switchMapTo(clickOutside$),
      untilDestroyed(this)
    ).subscribe(event => this.toViewMode()));
  }

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }

  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

}
