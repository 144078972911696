<div class="reservationTimes-popup" style="height:100%">

  <div style="width:100%;height:100%">
    <ng-scrollbar track="vertical">
      <div style="text-align:center;font-size: 18px;font-family: Roboto-Light;font-weight: bold;padding:5px" *ngFor="let time of timesList;let i=index">
          {{time}}
          <div  style="border:1px solid #00000040;width: 220px;margin-left: 13px;margin-right: 10px;"></div>
      </div>
    </ng-scrollbar>
  </div>

</div>