import { Injectable } from '@angular/core';
import { LessonType, PartyState, ShapeTypeEnum } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { TableShapeType } from '@models/RestaurantDTO';
import { Utilities } from '@utilities/utilities';
import { fabric } from 'fabric';
import _ from 'lodash';
import moment from 'moment';
import { back, controlSettings } from '../constants/globalConstants';
import { LayoutConfig } from '../models/global.interface';
import { DomSanitizer } from '@angular/platform-browser';import { TablesService } from '../services/tables.service';
import { PartyService } from '../services/party.service';
import { IconSizes } from '../dynamicform/models/field-config.interface';
import IconSize from '@data/icon-size.json';
@Injectable()
export class LayoutFunctions {
  propertyChanges = ['Walls', 'Shapes', 'Labels'];
  specialEventName : string;
  specialEventLeft: string;
  specialEventTop: string;
  layoutIconSize:IconSizes = IconSizes.Medium;
  public constructor(public cs: CacheService,private ts: TablesService) {
    this.layoutIconSize  =  JSON.parse(localStorage.getItem('tableIconSize')) || IconSizes.Medium;
  }


  drawRectangleSeat(obj, that, colorData) {
    const color = '#141414';
    const objectWidth = obj.width;
    const objectHeight = obj.height;
    let setArray;
    const partySize = obj.MaxPartySize > 6 ? 6 : obj.MaxPartySize;
    switch (partySize) {
      case 1:
        setArray = ['left'];
        break;
      case 2:
        setArray = ['left', 'right'];
        break;
      case 3:
        setArray = ['left', 'top', 'right'];
        break;
      case 4:
        setArray = ['left', 'top', 'right', 'bottom'];
        break;
      case 5:
        setArray = ['left', 'top-right', 'top-left', 'right', 'bottom'];
        break;
      case 6:
        setArray = ['left', 'top-right', 'top-left', 'right', 'bottom-left', 'bottom-right'];
        break;
      default:
        break;
    }

    return this.drawSeat(obj, setArray, this, color);
  }

  drawEllipseSeat(obj, that, color) {
    const objectWidth = obj.width;
    const objectHeight = obj.height;
    let setArray;
    const partySize = obj.MaxPartySize > 6 ? 6 : obj.MaxPartySize;
    setArray = ['top', 'bottom'];
    const radius = obj.radius;
    const seat = obj.MaxPartySize > 6 ? 6 : obj.MaxPartySize;
    const myTotal = seat * 8;
    var startAngle = Math.PI * 2 * ((myTotal / seat) / myTotal);

    return this.curvedSeat(obj.MaxPartySize > 6 ? 6 : obj.MaxPartySize, radius, color, 'green', this, obj, startAngle, color);
  }

  blockingRuleChanges(canvasObjects, layoutConfig, layoutData, blockingRule, canvas?) {
    let blockingRuleRemoval = _.differenceBy(layoutData.blockingRules, layoutConfig.blockingRules, 'Id');
    let blockingRuleAddedOrChanged = _.differenceWith(layoutConfig.blockingRules, layoutData.blockingRules, _.isEqual);

    if (layoutData.blockingRules && layoutData.blockingRules.length > 0) {
      let bRulesTobeRemoved = [];
      bRulesTobeRemoved = layoutData.blockingRules.filter(bR => blockingRuleAddedOrChanged.map(bA => bA.Id)?.find(x => x == bR.Id));
      blockingRuleRemoval = [...blockingRuleRemoval, ...bRulesTobeRemoved];
    }

    if (blockingRuleRemoval.length > 0) {
      blockingRuleRemoval.forEach(element => {
        element['TableIds'].forEach(table => {
          const index = layoutData.blockingRules.findIndex(x => x.Id == element['Id']);
          blockingRule.splice(index, 1);
          const object = canvasObjects.find(x => x.Id == table)
          if(object){
          object._objects = object._objects.filter(x => x.name != 'block');
          if (object && object.Party && object.Party.State != 1) {
            object.Party.State = PartyState.Open;
          }
          object.addWithUpdate();
        }
        });
      });
    }

    if (blockingRuleAddedOrChanged.length > 0) {
      blockingRule = [];
      blockingRuleAddedOrChanged.forEach(element => {
        element['TableIds'].forEach(table => {
          blockingRule.push(table);
        });
      });
      blockingRule = [...new Set([...blockingRule])];
      blockingRule.forEach((table) => {
        const tableData = layoutConfig.floor[0].StandaloneTables.filter(x => x.Id == table)[0];
        if (tableData && canvas) {
          const object = canvasObjects.filter(x => x.Id == table);
          if (object && object.length && object[0].Party.State != 1) {
            object[0].Party.State = PartyState.Blocked;
          }
          this.setTableIcons(tableData, canvasObjects, canvas, blockingRule);
        }
      })
    }
  };

  drawSeat(obj, seatArray, that, color) {
    const tableObj = [];
    const strokeWidth = 4;
    // tableObj.push(obj);
    const scale = obj._objects.filter(x => x.name == 'shape')[0].scaleX;
    const shape = obj._objects.filter(x => x.name == 'shape')[0]
    seatArray.forEach(element => {
      switch (element) {
        case 'top': {
          const ff = this.rectSeat(obj.left - (obj.width / 4), (obj.top - (obj.height / 2) - 6), obj.left + (obj.width / 4), (obj.top - (obj.height / 2) - 6), scale, color);
          tableObj.push(ff);
          break;
        }

        case 'left': {
          const ff = this.rectSeat((obj.left - (obj.width / 2) - 4), (obj.top - (obj.height / 4) - 4), (obj.left - (obj.width / 2) - 4), (obj.top + obj.height / 4), scale, color);
          tableObj.push(ff);
          break;
        }

        case 'right': {
          const ff = this.rectSeat((obj.left + (obj.width / 2) - 1), (obj.top - (obj.height / 4) - 4), (obj.left + (obj.width / 2) - 1), (obj.top + obj.height / 4), scale, color);
          tableObj.push(ff);
          break;

        }

        case 'bottom': {
          const ff = this.rectSeat((obj.left - (obj.width / 4)), (obj.top + (obj.height / 2)), obj.left + (obj.width / 4), (obj.top + (obj.height / 2)), scale, color);
          tableObj.push(ff);
          break;

        }

        case 'top-right': {
          const ff = this.rectSeat(obj.left + (obj.width / 8), (obj.top - (obj.height / 2) - 6), obj.left + (obj.width / 2) - (obj.width / 8), (obj.top - (obj.height / 2) - 6), scale, color);
          tableObj.push(ff);
          break;
        }

        case 'top-left': {
          const ff = this.rectSeat(obj.left - (obj.width / 2) + (obj.width / 8), (obj.top - (obj.height / 2) - 6), obj.left - (obj.width / 8), (obj.top - (obj.height / 2) - 6), scale, color);
          tableObj.push(ff);
          break;
        }

        case 'bottom-right': {
          const ff = this.rectSeat(obj.left + (obj.width / 8), (obj.top + (obj.height / 2)), obj.left + (obj.width / 2) - (obj.width / 8), (obj.top + (obj.height / 2)), scale, color);
          tableObj.push(ff);
          break;
        }

        case 'bottom-left': {
          const ff = this.rectSeat(obj.left - (obj.width / 2) + (obj.width / 8), (obj.top + (obj.height / 2)), obj.left - (obj.width / 8), (obj.top + (obj.height / 2)), scale, color);
          tableObj.push(ff);
          break;
        }
        case 'top-right': {
          const ff = this.rectSeat(obj.left + (obj.width / 2) + (obj.width / 8), (obj.top - 6), obj.left + (obj.width) - (obj.width / 8), (obj.top - 6), scale, color);
          tableObj.push(ff);
          break;
        }

        case 'top-left': {
          const ff = this.rectSeat(obj.left + (obj.width / 8), (obj.top - 6), obj.left + (obj.width / 2) - (obj.width / 8), (obj.top - 6), scale, color);
          tableObj.push(ff);
          break;
        }

        case 'bottom-right': {
          const ff = this.rectSeat(obj.left + (obj.width / 2) + (obj.width / 8), (obj.top + (obj.height)), obj.left + (obj.width) - (obj.width / 8), (obj.top + (obj.height)), scale, color);
          tableObj.push(ff);
          break;
        }

        case 'bottom-left': {
          const ff = this.rectSeat(obj.left + (obj.width / 8), (obj.top + (obj.height)), obj.left + (obj.width / 2) - (obj.width / 8), (obj.top + (obj.height)), scale, color);
          tableObj.push(ff);
          break;
        }
      }

    });
    return tableObj;
  }

  drawSeatEllipse(obj, seatArray, that, color) {
    const tableObj = [];
    const strokeWidth = 4;
    const scale = obj._objects.filter(x => x.name == 'shape')[0].scaleX;
    const shape = obj._objects.filter(x => x.name == 'shape')[0];
    const radius = obj.radius;
    const seat = obj.MaxPartySize > 6 ? 6 : obj.MaxPartySize;
    const myTotal = seat * 8;
    var startAngle = Math.PI * 2 * ((myTotal / seat) / myTotal);

    seatArray.forEach(element => {
      switch (element) {
        case 'top': {
          const ff = this.curvedSeat(obj.MaxPartySize > 6 ? 6 : obj.MaxPartySize, radius, color, 'green', this, obj, startAngle, color);
          tableObj.push(ff);
          break;
        }

        case 'bottom': {
          const ff = this.curvedSeat(obj.MaxPartySize > 6 ? 6 : obj.MaxPartySize, radius, color, 'green', this, obj, startAngle, color);
          tableObj.push(ff);
          break;
        }

      }
      startAngle += Math.PI * 2 * ((myTotal / seat) / myTotal);

    });
    return tableObj;
  }
  rectSeat(x1, y1, x2, y2, scale, color) {
    scale = 1;
    const rectangleSeatObj = new fabric.Line([x1, y1, x2, y2], {
      stroke: color,
      strokeWidth: (5),
      //strokeLineCap: 'round'
    });
    rectangleSeatObj.set({
      name: 'seat'
    });
    return rectangleSeatObj;
  }

  curvedSeat(seat, radius, seatColor, tblFillColor, that, obj, startAngle, color?) {
    const seatWidth = seat * 25;
    const lastend = 0;
    const myTotal = seat * 8;
    const tableObj1 = [];
    const strokeColor = '';
    const table = obj._objects.filter(x => x.name == 'shape')[0];
    const radiusAssigned = ((table.width * table.scaleX) / 2) + (6.5);
    const circleSeat = new fabric.Ellipse({
      left: obj.Left > 14 ? obj.Left : 14,
      top: obj.Top > 14 ? obj.Top : 14,
      height: (obj.Height - 16) < 60 ? (60 + 3) : obj.MaxPartySize > 2 ? obj.Height - 16 + 3 : obj.Height + 3,
      width: (obj.Width - 16) < 60 ? (60 + 3) : obj.Width - 16 + 3,
      fill: color,
      stroke: strokeColor,
      strokeWidth: 5
    });

    circleSeat.set({
      name: 'seat',
    });

    return circleSeat;
  }

  drawCircleSeat(obj, that, colorData) {
    const color = '#000000';
    const seatWidth = 5 * 5;
    const radius = obj.radius;
    return this.roundSeatDraw(obj.MaxPartySize > 6 ? 6 : obj.MaxPartySize, radius, color, 'green', this, obj, color); // green,blue
  }

  roundSeatDraw(seat, radius, seatColor, tblFillColor, that, obj, color?) {
    const seatWidth = seat * 25;
    const lastend = 0;
    const myTotal = seat * 8;
    let startAngle = 0;
    const tableObj1 = [];
    const strokeColor = '';
    const table = obj._objects.filter(x => x.name == 'shape')[0];
    for (let i = 0; i < seat; i++) {
      const circleSeat = new fabric.Circle({
        radius: ((table.width * table.scaleX) / 2) + 3,
        startAngle: startAngle - ((Math.PI * 2) / myTotal),
        endAngle: startAngle + ((Math.PI * 2) / myTotal),
        stroke: seatColor,
        strokeWidth: (5),
        fill: '',
        top: obj.top,
        left: obj.left,
        originX: 'center',
        originY: 'center',
      });

      circleSeat.set({
        name: 'seat',
      });

      startAngle += Math.PI * 2 * ((myTotal / seat) / myTotal);
      tableObj1.push(circleSeat);
    }
    return tableObj1;
  }

  hexToRGB(hex, alpha) {

    var r = parseInt(hex.slice(1, 3), 16) - 12,
      g = parseInt(hex.slice(3, 5), 16) - 17,
      b = parseInt(hex.slice(5, 7), 16) - 22;

    if (alpha) {
      return "rgba(" + (r) + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  mapServerToTable(element, object, that, servers) {

    let serverName = '';
    let serverColor = '';
    if (element.ServerId) {
      element.selectable = true;
      const serverval = servers.filter(sval => {
        if (sval.Id === element.ServerId) {
          return sval;
        }
      });
      if (serverval && serverval.length > 0) {
        if(!serverval[0].LastName){
        const serverNameArray = serverval[0].Name.split(' ');
        serverNameArray.forEach(name => {
          serverName = serverName + name.charAt(0);
        });
        // serverName = serverval[0].Name; Server full name is not required for current release (July 29 2022)
      }else{
        serverName = (serverval[0].Name ? serverval[0].Name.charAt(0) : '')  + (serverval[0].LastName ? serverval[0].LastName.charAt(0) : '');
        // serverName = serverval[0].Name; Server full name is not required for current release (July 29 2022)
      }
        serverName = `${serverName.toUpperCase()}`;
        serverColor = serverval[0].color;
      }
    }
    if (serverName) {
      const serverCircle = new fabric.Circle({
        radius: 10,
        fill: serverColor ? serverColor : '#1DA664',
        scaleY: 1,
        originX: 'center',
        originY: 'center',  
      });
      const text = new fabric.Text(serverName.toString(), {
        fontSize: 10,
        originX: 'center',
        originY: 'center',
        fill: 'white',
        fontWeight: 'bold',
      });
      const adjustedVlaue = element.MaxPartySize > 2 ? 7 : 0;
      let leftvaltoadd = 0;
      let topvaltoadd = 0;
      if (object.type == 1 || object.type == ShapeTypeEnum.Circle) {
        leftvaltoadd = 15;
        topvaltoadd = 5;
      } else if ((object.type == TableShapeType.RectangleBasic || object.type == ShapeTypeEnum.BasicRectangle) ) {
        leftvaltoadd = 18;
        topvaltoadd = 10;
      } else if (object.type == TableShapeType.CircleBasic || object.type == ShapeTypeEnum.BasicCircle) {
        leftvaltoadd = 20;
        topvaltoadd = 0;
      } else if (object.type == TableShapeType.Pin || object.type == ShapeTypeEnum.Pin) {
        leftvaltoadd = 12;
        topvaltoadd = 15;
        if ((element.Height == 20 || element.Width == 20) && element.IsCommunalTable) {
          console.log(element);
          leftvaltoadd = 4;
        }
      }
      
      const group = new fabric.Group([serverCircle, text], {
        name: 'serverCircle',
        top: ((element.ShapeType == 0) ? (that.groupedObject.top) - (that.groupedObject.height / 2) - (serverCircle.radius * 2) : that.groupedObject.top - (that.groupedObject.height / 2)),
        left: ((element.ShapeType == 0) ? that.groupedObject.left + ((that.groupedObject.width * that.groupedObject.scaleX) / 2) - (serverCircle.radius) : that.groupedObject.left + (that.groupedObject.width / 2)) - leftvaltoadd
      });
      if(element.Price){
        group.top = ((element.ShapeType == 0) ? (that.groupedObject.top) - (that.groupedObject.height  / 5) - (serverCircle.radius * 2) : that.groupedObject.top - (that.groupedObject.height / 3))
     }

     group.scaleX = group.scaleX * IconSize[IconSizes[this.layoutIconSize]].Server;
     group.scaleY =   group.scaleY *IconSize[IconSizes[this.layoutIconSize]].Server;
      that.groupedObject.addWithUpdate(group);
    }
  }

  setTableIcons(table, objs, canvas, blockingRule, isTableSelection = false) {
    const that = this;
    let IconLeft = 0;
    let IconTop = 0;
    if (table && table.UpcomingReservations && table.UpcomingReservations.length > 0) {
      let count = 0;
      const holdTimeBeyondReservationInMinutes = this.cs.settings.value.General.HoldTimeBeyondReservationInMinutes;
      const currentRestaurantTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
      table.UpcomingReservations.forEach((reservation) => {
        if (moment(currentRestaurantTime) < moment(reservation.ReservedFor) || moment(currentRestaurantTime).diff(moment(reservation.ReservedFor), "minutes") < holdTimeBeyondReservationInMinutes) {
          count++;
        }
      });
      if (count > 0 && table.ShapeType == 0) {
        IconLeft = table.UpcomingReservations.length > 0 ? 20 : 0;
        IconTop = table.UpcomingReservations.length > 0 ? 5 : 0;
      }
    }
    objs.forEach(object => {
      object.set({
        lockMovementX: true,
        lockMovementY: true,
        hasBorders: false,

      })
      if (object.Id == table.Id) {

        const selectedObjectTable = object._objects.filter(x => x.name == "shape")[0];
        const numberCircle = object._objects.filter(x => x.name == 'textCircle')[0];
        let serverAvailable = false;
        if (table.ServerId) {
          serverAvailable = true;
        }
        if (table.Id) {
          const tableIndex = blockingRule.findIndex(x => x == table.Id);
          if (tableIndex != -1) {
            this.setBlockIcon(selectedObjectTable, object, serverAvailable, canvas,table);

          }
        }
        if(table.EventId){
          this.setSpecialMealIcon(selectedObjectTable, object, serverAvailable, canvas,table);
        }
        if (isTableSelection && table.Id && table.IsBlocked) {
         this.setBlockIcon(selectedObjectTable, object, serverAvailable, canvas,table);
        }
        if (object.Party && object.Party.State == 1 && !object.IsCommunalTable) {
          if (object.Party.Contact.IsVip) {
            this.setVIPIcon(selectedObjectTable, object, serverAvailable, canvas, table)
          }
          if (object.Party.Contact.IsFavorite) {
            const myobj = _.cloneDeep(this.cs.favIcon);
            if (myobj) {
              myobj.set({
                top: (selectedObjectTable.type == ShapeTypeEnum.Rectangle) ? object.top + (myobj.height / 2) + ((selectedObjectTable.height * selectedObjectTable.scaleY) / 2) : object.top + 3,
                left: (selectedObjectTable.type == ShapeTypeEnum.Rectangle) ? object.left + (myobj.width / 2) - ((selectedObjectTable.width * selectedObjectTable.scaleX) / 2) - 3 : object.left - ((selectedObjectTable.width * selectedObjectTable.scaleX) / 2) - (myobj.width / 2),
                originX: 'center',
                originY: 'center',
                height: 25,
                width: 25,
                fill: '#663399',
              });
              myobj.set({
                name: 'fav'
              });
              myobj.scaleX = myobj.scaleX * IconSize[IconSizes[this.layoutIconSize]].Fav;
              myobj.scaleY =   myobj.scaleY *IconSize[IconSizes[this.layoutIconSize]].Fav;
              object.addWithUpdate(myobj);
            }
            canvas.renderAll();
          }
          if (_.get(object, ['Party', 'Notes'], []).length || _.get(object, ['Party', 'Contact', 'Notes'], []).length) {
            this.setPartyNotesIcon(selectedObjectTable, object, serverAvailable, canvas, table);
          }
          if (object.Party && object.Party.State == 1 && _.get(object, ['Party', 'TableIds'], []).length > 1 && !object.IsCommunalTable) { // object.Party.TableIds.length > 1
            this.setJoinedLayer(object, canvas)
          }
        }
    }
    });
  }

  setPartyNotesIcon(selectedObjectTable, object, serverAvailable, canvas,table){
    const myobj = _.cloneDeep(this.cs.partyNotesWithoutSpecialMealIcon);
    let topValue = 0;
    let leftValue = 0
    let serverMapped = object._objects.filter(obj=>obj.name == 'serverCircle').length > 0
    switch(selectedObjectTable.type){
      case ShapeTypeEnum.Rectangle :
      case ShapeTypeEnum.BasicRectangle:
        topValue = (object.top) - ((table.Height)/8) +(myobj.height) + 10
        leftValue = (object.left)  + ((table.Width)/2.3) - (myobj.width) + 10;
        if (serverMapped) {
          let serverObjects = object._objects.filter(obj=>obj.name == 'serverCircle')[0];
          topValue = (object.top) - ((object.height*object.scaleY)/8) +(serverObjects.height * serverObjects.scaleY) + (myobj.height/3) + 10;
          leftValue = (object.left)  + ((object.width*object.scaleX)/2.3) - (serverObjects.width * serverObjects.scaleX) - (myobj.width/3) + 10;
          if(table.Price){
            topValue = (object.top) - ((object.height*object.scaleY)/8) +(serverObjects.height * serverObjects.scaleY) + (myobj.height/2) + 10;
          }
        }
        break;

      case ShapeTypeEnum.Circle :
      case ShapeTypeEnum.BasicCircle:
      case ShapeTypeEnum.Ellipse:
        topValue = object.top ;
        leftValue = object.left + ((object.width * object.scaleX) / 2) -(10*object.scaleX) - (myobj.width/2) ;
        break;
      case ShapeTypeEnum.Image:
        topValue = (object.top) - ((table.Height)/2) +(myobj.height)
        leftValue = (object.left)  + ((table.Width)/2) - (myobj.width);
        if (serverMapped) {
          let serverObjects = object._objects.filter(obj=>obj.name == 'serverCircle')[0];
          topValue = (object.top) - ((object.height*object.scaleY)/2)  + (myobj.height);
          leftValue = (object.left)  + ((object.width*object.scaleX)/2) - (serverObjects.width * serverObjects.scaleX) - (myobj.width/2)
          if(table.Price){
            topValue = (object.top) - ((object.height*object.scaleY)/3)  + (myobj.height);
          }
        }
        break;
      case ShapeTypeEnum.Pin:
        topValue = (object.top + 7) + ((table.Height*object.scaleY)/2) -(myobj.height/2)
        leftValue = object.left + 5;
        break;
    }
    if (myobj) {
      myobj.set({
        top:topValue,
        left:leftValue,
        originY: 'center',
        originX: 'center',
        height: 50,
        width: 50,
        fill: '#bf1b1d',
        stroke: '#ebc606',
      });
      myobj.set({
        name: 'notes'
      });
      myobj.scaleX = myobj.scaleX * IconSize[IconSizes[this.layoutIconSize]].Notes;
      myobj.scaleY =   myobj.scaleY *IconSize[IconSizes[this.layoutIconSize]].Notes;
      object.addWithUpdate(myobj);
    }
    canvas.renderAll();
  }

  setBlockIcon(selectedObjectTable, object, serverAvailable, canvas,table) {
    const myobj = _.cloneDeep(this.cs.blockIcon);
    let topValue = 0;
    let leftValue = 0
    let serverMapped = object._objects.filter(obj=>obj.name == 'serverCircle').length > 0
  switch(selectedObjectTable.type){
      case ShapeTypeEnum.Rectangle :
      case ShapeTypeEnum.BasicRectangle:
        topValue = (object.top) + ((table.Height*object.scaleY)/2) -(myobj.height/2)
        leftValue = (object.left)  + ((table.Width*object.scaleX)/2) - (myobj.width/2);
        if (serverMapped) {
          topValue = topValue + (14*object.scaleY) -(myobj.height/2);
          leftValue = leftValue -(myobj.width/2)
        }
        break;
      case ShapeTypeEnum.Circle :
      case ShapeTypeEnum.BasicCircle:
      case ShapeTypeEnum.Ellipse:
          topValue = (object.top) + ((table.Height*object.scaleY)/2) -(myobj.height/2)
          leftValue = object.left ;
        break;
      case ShapeTypeEnum.Image:
        topValue = (object.top) + ((table.Height*object.scaleY)/2) -(myobj.height/2)
        leftValue = (object.left)  + ((table.Width*object.scaleX)/2) - (myobj.width/2);
        if (serverMapped) {
          let serverObjects = object._objects.filter(obj=>obj.name == 'serverCircle')[0];
          topValue = (object.top) + ((table.Height*object.scaleY)/2)-(myobj.height/2) ;
          leftValue = (object.left)  + ((table.Width*object.scaleX)/2)  - (myobj.width)
        }
        break;
      case ShapeTypeEnum.Pin:
        topValue = (object.top - 5) + ((table.Height*object.scaleY)/2) -(myobj.height/2)
        leftValue = object.left + 15;
        break;
    }
    if (myobj) {
      myobj.set({
        top: topValue + 3,
        left: leftValue,
        fill: "#ff0000",
        originX: 'center',
        originY: 'center',
        stroke: '#FFFFFF',
        strokeWidth: 0.8
      });
      myobj.set({
        name: 'block',
        angle: object.RotationAngle
      });
      myobj.scaleX = myobj.scaleX * IconSize[IconSizes[this.layoutIconSize]].Block;
      myobj.scaleY =   myobj.scaleY *IconSize[IconSizes[this.layoutIconSize]].Block;
   
      object.addWithUpdate(myobj);
    }
    canvas.renderAll();
  }

  setSpecialMealIcon(selectedObjectTable, object, serverAvailable, canvas,table){
    const myobj = _.cloneDeep(this.cs.specialMealIcon);
    let topValue = 0;
    let leftValue = 0
    let serverMapped = object._objects.filter(obj=>obj.name == 'serverCircle').length > 0
    switch(selectedObjectTable.type){
      case ShapeTypeEnum.Rectangle :
        topValue = (object.top);
        leftValue = (object.left + ((object.width*object.scaleX)/2));
        break;
      case ShapeTypeEnum.BasicRectangle:
        topValue = (object.top) ;
        leftValue = (object.left + ((object.width*object.scaleX)/2) + (object.scaleX * 10) );
        break;
      case ShapeTypeEnum.Circle :
        topValue = (object.top);
        leftValue = (object.left + ((object.width*object.scaleX)/2) + (object.scaleX * 5));
        break;
      case ShapeTypeEnum.BasicCircle:
      case ShapeTypeEnum.Ellipse:
        topValue = (object.top);
        leftValue = (object.left + ((object.width*object.scaleX)/2) + (object.scaleX * 10));
        break;
      case ShapeTypeEnum.Image:
        topValue = (object.top);
        leftValue = (object.left + ((object.width*object.scaleX)/2) + (object.scaleX * 10));
        break;
      case ShapeTypeEnum.Pin:
        topValue = (object.top);
        leftValue = (object.left + ((object.width*object.scaleX)/2) + (object.scaleX * 5));
        break;
    }
    if (serverMapped) {
      let serverObjects = object._objects.filter(obj=>obj.name == 'serverCircle')[0];
      topValue = topValue + ((serverObjects.height * serverObjects.scaleY) /3)
      leftValue = leftValue - ((serverObjects.width * serverObjects.scaleX) / 3)
    }
    if (myobj) {
      myobj.set({
        top:topValue,
        left:leftValue,
        originY: 'top',
        originX: 'right',
        height: 10,
        width: 10,
        fill: '#8C1AF6',
        stroke: '#8C1AF6',
        strokeWidth: 1
      });
      myobj.set({
        name: `specialMeal${table.EventId}`
      });
      myobj.scaleToWidth(IconSize[IconSizes[this.layoutIconSize]].SpecialEvents);
      myobj.scaleToHeight(IconSize[IconSizes[this.layoutIconSize]].SpecialEvents);
      object.addWithUpdate(myobj);
    }

    object.on("mouseover", (event) => {
      if (event.target.EventId) {
        this.specialEventName = this.cs.settings.value.SpecialMeals.find(meal => meal.Id == event.target.EventId)?.Name;
        this.specialEventLeft = (Math.round(event.e.x)).toString() + 'px'
        this.specialEventTop = (Math.round(event.e.y)).toString() + 'px'
      }
    });
    object.on("mouseout", (event) => {
      this.specialEventName = null;
    });
   canvas.renderAll();
  }

  setVIPIcon(selectedObjectTable, object, serverAvailable, canvas,table){
    const myobj = _.cloneDeep(this.cs.vipIcon);
    let serverMapped = object._objects.filter(obj=>obj.name == 'serverCircle').length > 0;
    let topValue = 0;
    let leftValue = 0
    switch(selectedObjectTable.type){
      case ShapeTypeEnum.Rectangle :
      case ShapeTypeEnum.BasicRectangle:
        topValue = (object.top) - ((table.Height)/1.2) +(myobj.height)
        leftValue = (object.left)  - ((table.Width)/1.3) + (myobj.width);
        if (serverMapped) {
          let serverObjects = object._objects.filter(obj=>obj.name == 'serverCircle')[0];
          topValue = (object.top) - ((object.height*object.scaleY)/1.2) +(serverObjects.height * serverObjects.scaleY) + (myobj.height);
          leftValue = (object.left)  - ((object.width*object.scaleX)/1.3) + (serverObjects.width * serverObjects.scaleX)
        }
        break;
      case ShapeTypeEnum.Circle :
      case ShapeTypeEnum.BasicCircle:
      case ShapeTypeEnum.Ellipse:
          topValue = (object.top) - ((object.height*object.scaleY)/2) + (20*object.scaleY);
          leftValue = object.left ;
        break;
      case ShapeTypeEnum.Image:
        topValue = (object.top) - ((table.Height)/2) +(myobj.height)
        leftValue = (object.left)  - ((table.Width)/2) + (myobj.width);
        if (serverMapped) {
          let serverObjects = object._objects.filter(obj=>obj.name == 'serverCircle')[0];
          topValue = (object.top) - ((object.height*object.scaleY)/2)  + (myobj.height);
          leftValue = (object.left)  - ((object.width*object.scaleX)/2) +(myobj.width)
        }
        break;
      case ShapeTypeEnum.Pin:
        topValue = (object.top + 7) + ((table.Height*object.scaleY)/2) -(myobj.height/2)
        leftValue = object.left + 5;
        break;
    }
    if (myobj) {
      myobj.set({
        top:topValue,
        left:leftValue,
        originX: 'center',
        originY: 'center',
        height: 25,
        width: 25,
        fill: '#eb8808',
        scaleX: 1.3,
        scaleY: 1.3,
        stroke: '#000',
        strokeWidth: 1
      });
      myobj.set({
        name: 'vip'
      });
      myobj.scaleX = myobj.scaleX * IconSize[IconSizes[this.layoutIconSize]].VIP;
      myobj.scaleY =   myobj.scaleY *IconSize[IconSizes[this.layoutIconSize]].VIP;
      object.addWithUpdate(myobj);
    }

  }

  setJoinedLayer(object, canvas) {
    let IsServerBackgroundAvailable = JSON.parse(localStorage.getItem('IsServerBackgroundAvailable'));
    if(IsServerBackgroundAvailable){
      let otherJoinedTables = _.get(object, ['Party', 'TableIds'], []).filter(data => data != object.Id);
      otherJoinedTables.forEach(element => {
        let linkedObject = canvas._objects.find(joinedTable => joinedTable.Id == element);
        var line = new fabric.Line([object.left + 5, object.top + 20, linkedObject?.left + 5, linkedObject?.top + 20], {
          stroke: '#2896fc',
          name: 'joinedLayer_' + object.Id,
          hasControls: false,
          strokeWidth: 3,
          lockMovementX: true,
          lockMovementY: true,
          selectable: false,
        });
      let isJoinedLayerInCanvas = canvas._objects.filter(canvasObj => canvasObj.name === line.name).length > 0;
      if (isJoinedLayerInCanvas) {
        canvas._objects.filter(canvasObj => canvasObj.name === line.name).forEach(element => {
          canvas.remove(element)
        });
      }
      canvas.add(line);
  
    });
    }else{
      const rectangle = new fabric.Rect({
        height: (object.height * object.scaleY) + 30,
        width: (object.width * object.scaleX) + 30,
        fill: object.Party.Color,
        rx: 5,
        ry: 5,
        name: 'joinedLayer_' + object.Id,
        hasControls: false,
        lockMovementX: true,
        lockMovementY: true,
        selectable: false
      });
      rectangle.set({
        left: object.left - (rectangle.width / 2),
        top: object.top - (rectangle.height / 2)
      });
      let isJoinedLayerInCanvas = canvas._objects.filter(canvasObj => canvasObj.name === rectangle.name).length > 0;
      if (isJoinedLayerInCanvas) {
        canvas._objects.filter(canvasObj => canvasObj.name === rectangle.name).forEach(element => {
          canvas.remove(element)
        });
      }
      canvas.add(rectangle);
      rectangle.moveTo(1);
  
    }
    const myobj = _.cloneDeep(this.cs.linkIcon);
    let tableNumberObject = object._objects.filter(x => x.name == "tableNumber");
    if (myobj && tableNumberObject && tableNumberObject.length) {
      myobj.set({
        top: object.top - (object.height / 2) + 5,
        left: object.left - (object.width / 2) + 5,
        scaleX: 0.5,
        scaleY: 0.5,
        fill: tableNumberObject[0].fill,
        hasControls: false,
        lockMovementX: true,
        lockMovementY: true,
        selectable: false
      });
      myobj.set({
        name: 'join_' + object.Id
      });
      let isLinkIconInCanvas = canvas._objects.filter(canvasObj => canvasObj.name === myobj.name).length > 0;
      if (isLinkIconInCanvas) {
        canvas._objects.filter(canvasObj => canvasObj.name === myobj.name).forEach(element => {
          canvas.remove(element)
        });
      }
      myobj.scaleX = myobj.scaleX * IconSize[IconSizes[this.layoutIconSize]].Link;
      myobj.scaleY =   myobj.scaleY *IconSize[IconSizes[this.layoutIconSize]].Link;
      canvas.add(myobj);
      canvas.sendToBack(myobj)
      myobj.moveTo(1);
    }
    canvas.renderAll();
  }

  getStatusColor(element, reservationData, settingsData) {
    let fillColor = '#f2f2f2';
    const textColor = '#444444';
    let partyColor = 'black';
    let stroke = '#3B4532';
    let numberColor = '#4f483e';
    let partyData = null;

    const currentRestaurantTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    const holdTimeBeforeReservationInMinutes = this.cs.settings.value.General.HoldTimeBeforeReservationInMinutes;
    const holdTimeBeyondReservationInMinutes = this.cs.settings.value.General.HoldTimeBeyondReservationInMinutes;

    if (!element.IsCommunalTable) {
      if (reservationData && reservationData.reservations && reservationData.reservations.length > 0) {
        reservationData.reservations.forEach(data => {
          let randomRValue = Math.round(crypto.getRandomValues(new Uint8Array(1))[0] / (100) * 255);
          let randomGValue = Math.round(crypto.getRandomValues(new Uint8Array(1))[0] / (100) * 255);
          let randomBValue = Math.round(crypto.getRandomValues(new Uint8Array(1))[0] / (100) * 255)
          data.Color = 'rgba(' + (randomRValue > 255 ? randomRValue - 255 : randomRValue) + ','
            + (randomGValue > 255 ? randomGValue - 255 : randomGValue) + ','
            + (randomBValue > 255 ? randomBValue - 255 : randomBValue) + ',' + 0.2 + ')';
          const index = data.TableIds.findIndex(x => x == element.Id);
          if (index != -1 && !Utilities.isLessonBooking(data, this.cs.settings.value.PropertyType, this.cs.settings.value.SpecialMeals)) {

            switch (data.State) {
              case 0:
                const isResevationTimeElapsed = moment(data.ReservedFor) < moment(currentRestaurantTime);
                const holdReservation = isResevationTimeElapsed ?
                  moment(currentRestaurantTime).diff(moment(data.ReservedFor), "minutes") < holdTimeBeyondReservationInMinutes : moment(data.ReservedFor).diff(moment(currentRestaurantTime), "minutes") <= holdTimeBeforeReservationInMinutes
                if (partyData == null && holdReservation) {
                  fillColor = '#5093E1';
                  partyData = data;
                  stroke = 'transparent'
                }
                break;
              case 1:
                if (data && data.StatusId) {
                  const statusColor = settingsData.Statuses.filter(status => status.Id == data.StatusId)[0]?.Color;
                  if (statusColor) {
                    fillColor = 'rgba(' + statusColor.R + ',' + statusColor.G + ',' + statusColor.B + ',' + statusColor.A + ')';
                  } else {
                    fillColor = '#5093E1';
                  }
                  partyData = data;
                  stroke = 'transparent'
                  break;
                }
            }
          }
        });
      }
    }
    else {
      partyColor = 'black';
      const communalReservations = [];
      reservationData?.reservations.forEach((reservation) => {
        if (reservation.TableIds.includes(element.Id) && !Utilities.isLessonBooking(reservation, this.cs.settings.value.PropertyType, this.cs.settings.value.SpecialMeals)) {
          communalReservations.push(reservation);
        }
      });
      const seatedCommunalParties = communalReservations.filter(x => x.State == PartyState.Seated)
      const reservedCommunalParties = communalReservations.filter(party => {
        let showparty = false;
        if (party.State == PartyState.Pending) {
          let holdTimeBeyondReservationInMinutes = this.cs.settings.value.General.HoldTimeBeyondReservationInMinutes;
          let holdTimeBeforeReservationInMinutes = this.cs.settings.value.General.HoldTimeBeforeReservationInMinutes;
          let currentRestaurantTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
          let isResevationTimeElapsed = moment(party.ReservedFor) < moment(currentRestaurantTime);
          let holdReservation = isResevationTimeElapsed ?
            moment(currentRestaurantTime).diff(moment(party.ReservedFor), "minutes") < holdTimeBeyondReservationInMinutes : moment(party.ReservedFor).diff(moment(currentRestaurantTime), "minutes") <= holdTimeBeforeReservationInMinutes;
          if (holdReservation) {
            showparty = true;
          }
        }
        return showparty;
      })
      let state;
      if (seatedCommunalParties.length > 0) {
        state = PartyState.Seated;
      } else if (reservedCommunalParties.length > 0) {
        state = PartyState.Pending;
      }
      else {  //if (communalReservations.length == 0) We may have reservations which doesnt fall on the 1hr time peroid to show which is also a open table
        state = 3;
      }
      switch (state) {
        case 0:
          fillColor = '#5093E1';
          numberColor = '#5093E1';
          partyData = null;
          partyColor = '#fff';
          if (seatedCommunalParties.length == 0) {
            if (reservedCommunalParties.length == 1) {
              partyData = reservedCommunalParties[0];
            }
            else {
              const party = { State: null, TableIds: [], Contact: { FirstName: '', PhoneNumber: '' }, Size: 0, SeatingAreaId: -1, MaxPartySize: 0, MinPartySize: 0 };
              party.State = PartyState.Pending;
              party.Contact.FirstName = 'Multiple Parties';
              party.Contact.PhoneNumber = '';
              party.SeatingAreaId = settingsData.SeatingAreas.filter(x => x.Id !== -1)[0].Id;
              let partyCount = 0;
              reservedCommunalParties.forEach(x => {
                partyCount = partyCount + x.Size;
              })
              party.Size = partyCount;
              partyData = party;
              partyData.TableIds = [element.Id];
              partyData.ServerId = element.ServerId;
              partyData.IsCommunal = true;
            }
          }
          break;
        case 1:
          fillColor = '#9CC62B';
          numberColor = '#9CC62B';

          partyColor = '#fff';
          if (seatedCommunalParties.length == 1) {
            partyData = seatedCommunalParties[0];
            const statusColor = settingsData.Statuses.filter(status => status.Id == seatedCommunalParties[0].StatusId)[0].Color;
            fillColor = 'rgba(' + statusColor.R + ',' + statusColor.G + ',' + statusColor.B + ',' + statusColor.A + ')';
          }
          else {
            const party = { State: null, TableIds: [], Contact: { FirstName: '', PhoneNumber: '' }, SeatingAreaId: -1, Size: 0, MaxPartySize: 0, MinPartySize: 0 };
            party.State = PartyState.Seated;
            party.Contact.FirstName = 'Multiple Parties';
            party.Contact.PhoneNumber = '';
            party.SeatingAreaId = settingsData.SeatingAreas.filter(x => x.Id !== -1)[0].Id;
            let partyCount = 0;
            seatedCommunalParties.forEach(x => {
              partyCount = partyCount + x.Size;
            })
            party.Size = partyCount
            partyData = party;
            partyData.TableIds.push(element.Id);
            partyData.IsCommunal = true;
            if (partyCount >= element.MaxPartySize) {
              fillColor = 'red';
            }
          }
          break;

        case 3:
          fillColor = '#4f483e';
          numberColor = '#fff';
          partyData = null;

          break;
      }
    }
    return ({ fillColor, textColor, partyData, partyColor, stroke, numberColor });
  }

  checkTableIsOccupied(element, reservationData) {
    let isPartySeatedInTable = false;
    let seatedTablesCount = 0;
    if (reservationData && reservationData.reservations && reservationData.reservations.length > 0) {
      reservationData.reservations.forEach(data => {
        const index =  data.TableIds.findIndex(x => x == element.Id);
        if (index != -1) {
          switch (data.State) {
            case PartyState.Pending:
              break;
            case PartyState.Seated:
              seatedTablesCount = seatedTablesCount + 1;
              break;
          }
        }
      })
    }
    if (seatedTablesCount > 0) {
      isPartySeatedInTable = true;
      return isPartySeatedInTable;
    }
    return isPartySeatedInTable;
  }

  dataChanges(canvas, layoutConfig, layoutData) {
    const changedObjects = [];
    this.propertyChanges.forEach(val => {
      const changedData = _.isEqual(_.omit(layoutConfig.floor[0][val], ['id', 'name']), _.omit(layoutData.floor[0][val], ['id', 'name']));
      if (!changedData) {
        let availableData;
        switch (val) {
          case 'Labels':
            availableData = canvas.getObjects().filter(x => x.name == 'label');
            availableData.forEach(element => {
              canvas.remove(element)
            });
            layoutConfig.floor[0].Labels.forEach((element, index) => {
              this.addLabel(element, index, canvas, changedObjects);
            });
            break;
          case 'Walls':
            availableData = canvas.getObjects().filter(x => x.name == 'wall');
            availableData.forEach(element => {
              canvas.remove(element)
            });
            layoutConfig.floor[0].Walls.forEach((element, index) => {
              this.addWall(element, canvas, changedObjects);
            });
            break;
          case 'Shapes':
            availableData = canvas.getObjects().filter(x => x.name == 'area');
            availableData.forEach(element => {
              canvas.remove(element)
            });
            layoutConfig.floor[0].Shapes.forEach((element, index) => {
              this.addShape(element, canvas, changedObjects);
            });
            break;
        }
      }
    });
    canvas.renderAll();
    return changedObjects;
  }
  addLabel(obj, index, canvas, changedObjects: any[]) {
    obj.id = index;
    obj.name = 'label';
    const label = new fabric.Text(obj.Text.toString(), {
      fontFamily: 'Roboto Light',
      left: obj.Left,
      top: obj.Top,
      fontSize: 20,
      fontWeight: 'bold',
      fill: '#0000008A',
      data: index,
      name: 'label',
      selectable: false,
      angle: obj.Angle,
    });
    changedObjects.push(label);
    canvas.add(label);
    canvas.renderAll();
  }

  addShape(element, canvas, changedObjects: any[]) {
    const shape = new fabric.Rect({
      left: element.Left,
      top: element.Top,
      height: element.Height,
      width: element.Width,
      stroke: '#3B4532',
      strokeWidth: 3,
      strokeDashArray: [8, 8],
      name: 'area',
      fill: '',
      selectable: false
    });
    changedObjects.push(shape);
    canvas.add(shape)
    canvas.sendToBack(shape);
    canvas.renderAll();
  }

  addWall(element, canvas, changedObjects: any[]) {
    const linesArray = [];
    element.Points.forEach(point => {
      linesArray.push({ x: point.X, y: point.Y })
    });

    const wall = new fabric.Polyline(linesArray, {
      stroke: '#3B4532',
      fill: 'transparent',
      strokeWidth: 2,
      selectable: false,
      name: 'wall',
    });
    changedObjects.push(wall);
    canvas.add(wall)
    canvas.sendToBack(wall);
    canvas.renderAll();
  }

  serverUnAssignedLines(object, data?) {
let serverUnAssignedLineLeft, serverUnAssignedLineRight;
    if (object.type == 0 || object.type == ShapeTypeEnum.Rectangle) {
      serverUnAssignedLineLeft = new fabric.Line([object.top, object.top, object.top + object.width - 3, object.top + object.height - 3], {
        stroke: 'red',
        name: 'serverLines',
        strokeWidth: 3,
        top: object.top,
        left: object.left
      });
      serverUnAssignedLineRight = new fabric.Line([object.top - 2, object.top, object.top - object.width, object.top + object.height - 3], {
        stroke: 'red',
        name: 'serverLines',

        strokeWidth: 3,
        strokeLineCap: 'rounded',
        top: object.top,
        left: object.left
      });
    } else if (object.type == ShapeTypeEnum.Ellipse) {
      const t = Math.tan(45 / 360 * Math.PI);
      const px = object.rx * (1 - t ** 2) / (1 + t ** 2),
        py = object.ry * 2 * t / (1 + t ** 2);
      const newval = px > py ? py : px;
      let newvalz = newval;
      if (object.width > object.height) {
        newvalz = px < py ? py : px;
      }
      serverUnAssignedLineLeft = new fabric.Line([object.top + newvalz / 2, object.top + newval / 2, object.top + object.width - newvalz / 2, object.top + object.height - newval / 2], {
        stroke: 'red',
        name: 'serverLines',
        strokeWidth: 3,
        top: data ? object.top - (object.height / 2) : object.top + newval / 2,
        left: data ? object.left - (object.width / 2) : object.left + newvalz / 2
      });
      serverUnAssignedLineRight = new fabric.Line([object.top + newvalz / 2, object.top + newval / 2, object.top - object.width / 2, object.top + object.height - newval / 2], {
        stroke: 'red',
        name: 'serverLines',
        strokeWidth: 3,
        strokeLineCap: 'rounded',
        top: data ? object.top - (object.height / 2) : object.top + newval / 2,
        left: data ? object.left - (object.width / 2) : object.left + newvalz / 2
      });
    } else if (object.type == 1 || object.type == ShapeTypeEnum.Circle) {
      serverUnAssignedLineLeft = new fabric.Line([object.left + (object.width * object.scaleX / 2), object.top, object.left + (object.width * object.scaleX / 2), object.top + (object.height * object.scaleY)], {
        stroke: 'red',
        name: 'serverLines',
        strokeWidth: 3,
        angle: 45,
        originY: 'center',
        originX: 'center',
        centeredRotation: true
      });
      serverUnAssignedLineRight = new fabric.Line([object.left, object.top + (object.height * object.scaleY / 2), object.left + (object.width * object.scaleX), object.top + (object.height * object.scaleY / 2)], {
        stroke: 'red',
        name: 'serverLines',
        angle: 45,
        strokeWidth: 3,
        strokeLineCap: 'rounded',
        centeredRotation: true,
        originY: 'center',
        originX: 'center'
      });
    } else if (object.type == 2 || object.type == ShapeTypeEnum.Pin) {
      serverUnAssignedLineLeft = new fabric.Line([object.left + (object.width * object.scaleX / 2), object.top, object.left + (object.width * object.scaleX / 2), object.top + (object.height * object.scaleY)], {
        stroke: 'red',
        name: 'serverLines',
        strokeWidth: 3,
        angle: 45,
        originY: 'center',
        originX: 'center',
        centeredRotation: true
      });
      serverUnAssignedLineRight = new fabric.Line([object.left, object.top + (object.height * object.scaleY / 2), object.left + (object.width * object.scaleX), object.top + (object.height * object.scaleY / 2)], {
        stroke: 'red',
        name: 'serverLines',
        angle: 45,
        strokeWidth: 3,
        strokeLineCap: 'rounded',
        centeredRotation: true,
        originY: 'center',
        originX: 'center'
      });
    } else if (object.type == 3 || object.type == ShapeTypeEnum.BasicRectangle) {
      serverUnAssignedLineLeft = new fabric.Line([object.top, object.top, object.top + object.width - 3, object.top + object.height - 3], {
        stroke: 'red',
        name: 'serverLines',
        strokeWidth: 3,
        top: object.top,
        left: object.left
      });
      serverUnAssignedLineRight = new fabric.Line([object.top - 2, object.top, object.top - object.width, object.top + object.height - 3], {
        stroke: 'red',
        name: 'serverLines',

        strokeWidth: 3,
        strokeLineCap: 'rounded',
        top: object.top,
        left: object.left
      });
    } else if (object.type == 4 || object.type == ShapeTypeEnum.BasicCircle) {
      serverUnAssignedLineLeft = new fabric.Line([object.left + (object.width * object.scaleX / 2), object.top, object.left + (object.width * object.scaleX / 2), object.top + (object.height * object.scaleY)], {
        stroke: 'red',
        name: 'serverLines',
        strokeWidth: 3,
        angle: 45,
        originY: 'center',
        originX: 'center',
        centeredRotation: true
      });
      serverUnAssignedLineRight = new fabric.Line([object.left, object.top + (object.height * object.scaleY / 2), object.left + (object.width * object.scaleX), object.top + (object.height * object.scaleY / 2)], {
        stroke: 'red',
        name: 'serverLines',
        angle: 45,
        strokeWidth: 3,
        strokeLineCap: 'rounded',
        centeredRotation: true,
        originY: 'center',
        originX: 'center'
      });
    } else if (object.type == TableShapeType.Image || object.type == ShapeTypeEnum.Image) {
      serverUnAssignedLineLeft = new fabric.Line([object.top, object.top, object.top + object.imgWidth - 3, object.top + object.imgHeight - 3], {
        stroke: 'red',
        name: 'serverLines',
        strokeWidth: 3,
        top: object.top,
        left: object.left
      });
      serverUnAssignedLineRight = new fabric.Line([object.top - 2, object.top, object.top - object.imgWidth, object.top + object.imgHeight - 3], {
        stroke: 'red',
        name: 'serverLines',
        strokeWidth: 3,
        strokeLineCap: 'rounded',
        top: object.top,
        left: object.left
      });
    }
    return { leftLine: serverUnAssignedLineLeft, rightLine: serverUnAssignedLineRight }

  }

  tableChanges(layoutConfig: LayoutConfig, layoutData, canvas, cachedTables = [], changedServerIds = []) {
    const unAvailabelTableArray = [];
    const deletedTables = _.differenceBy(layoutData.floor[0].StandaloneTables, layoutConfig.floor[0].StandaloneTables, 'Id');
    deletedTables.forEach(deletedTable => {
      canvas.remove(canvas.getObjects().filter(x => x.Id == deletedTable['Id'])[0])
    });
    const unAvailableTables = _.differenceWith(layoutConfig.floor[0].StandaloneTables, layoutData.floor[0].StandaloneTables, _.isEqual);
    const reservationChange = _.differenceBy(layoutConfig.reservations, layoutData.reservations, 'Id');
    const reservationRemoval = _.differenceBy(layoutData.reservations, layoutConfig.reservations, 'Id');
    if (reservationChange && reservationChange.length) {
      reservationChange.forEach((res) => {
        res['TableIds'].forEach(tableId => {
          let dataChangedTable = layoutConfig.floor[0].StandaloneTables.filter(stanTable => stanTable.Id == tableId);
          if (dataChangedTable && dataChangedTable.length > 0) {
            unAvailableTables.push(...dataChangedTable);
          }
        })
      });
    }
    if (reservationRemoval && reservationRemoval.length) {
      reservationRemoval.forEach((res) => {
        res['TableIds'].forEach(tableId => {
          let dataChangedTable = layoutConfig.floor[0].StandaloneTables.filter(stanTable => stanTable.Id == tableId);
          if (dataChangedTable && dataChangedTable.length > 0) {
            unAvailableTables.push(...dataChangedTable);
          }
        })
      });
    }
    const changedReservations = _.differenceWith(layoutConfig.reservations, layoutData.reservations, _.isEqual);
    if (changedReservations && changedReservations.length > 0) {
      changedReservations.forEach(res => {
        res.TableIds.forEach(tableId => {
          let dataChangedTable = layoutConfig.floor[0].StandaloneTables.filter(stanTable => stanTable.Id == tableId);
          if (dataChangedTable && dataChangedTable.length > 0) {
            unAvailableTables.push(...dataChangedTable);
          }
        })

      })
    }
    if (cachedTables.length > 0) {
      cachedTables.forEach(cachedTable => {
        let dataChangedTable = layoutConfig.floor[0].StandaloneTables.filter(stanTable => stanTable.Id == cachedTable);
        if (dataChangedTable && dataChangedTable.length > 0) {
          unAvailableTables.push(...dataChangedTable);
        }
      })
    }
    if (changedServerIds && changedServerIds.length > 0) {
      changedServerIds.forEach(cachedServer => {
        let dataChangedTable = layoutConfig.floor[0].StandaloneTables.filter(stanTable => stanTable.ServerId == cachedServer);
        if (dataChangedTable && dataChangedTable.length > 0) {
          unAvailableTables.push(...dataChangedTable);
        }
      })
    }
    // const unAvailableTables = _.isEqual(_.omit(layoutConfig.floor[0].StandaloneTables, ['Id']),_.omit(layoutData.floor[0].StandaloneTables, ['Id']))
    unAvailableTables.forEach(unAvailableTable => {
      const data = canvas.getObjects().filter(x => x.Id == unAvailableTable.Id)[0];
      if (data) {
        canvas.remove(data);
        if (data.Party && data.Party.TableIds.length > 1) {
          data.Party.TableIds.forEach(table => {
            const joinedTables = canvas.getObjects().filter(x => {
              if (x.name) {
                return x.name.includes(table);
              }
            });
            if (joinedTables && joinedTables.length) {
              joinedTables.forEach(table => {
                canvas.remove(table);
              })
            }
          });
        }
        unAvailabelTableArray.push(unAvailableTable);
      } else {
        unAvailabelTableArray.push(unAvailableTable);
      }
    });
    return _.uniqBy(unAvailabelTableArray, 'Id');
  }

  setTableData(element, canvas) {
    if (canvas) {
     const selectedObj = canvas.getObjects().filter(x => x.Id == element.Id)[0];

      if (selectedObj && selectedObj._objects) {
        if ((element && element.ServerId == null)) {
          const serverLines = selectedObj._objects.filter(x => x.name == 'serverLines')
          serverLines.forEach(line => {
            line.set('opacity', 1);
          });

        }
        else {
          const serverLines = selectedObj._objects.filter(x => x.name == 'serverLines')
          serverLines.forEach(line => {
            line.set('opacity', 0);
          });
        }
        selectedObj.addWithUpdate();
      }
    }

  }

  createNewArea(obj, shape, clone?) {
    const newArea = {
      Color: { A: 1, R: 0, G: 0, B: 0 },
      FloorPlanViewId: null,
      Left: clone ? obj.left + 20 : obj.left,
      Top: clone ? obj.top + 20 : obj.top,
      Rotation: 0,
      ShapeType: 0,
      Width: obj.width,
      Height: obj.height,
      id: shape['id'] + 1,
      name: 'area'
    };
    return newArea;
  }
  createNewWall(obj, shape, clone?) {
    const newWall = {
      Color: { A: 1, R: 255, G: 0, B: 0 },
      FloorPlanViewId: null,
      Left: clone ? obj.left + 20 : obj.left,
      Top: clone ? obj.top + 20 : obj.top,
      Rotation: 0,
      ShapeType: 0,
      Width: obj.width,
      Height: obj.height,
      id: shape['id'] + 1,
      name: 'wall'
    };
    return newWall;
  }

  createNewLabel(obj, shape, clone?) {
    const newLabel = {
      Color: { A: 1, R: 255, G: 0, B: 0 },
      FloorPlanViewId: null,
      Left: clone ? obj.left + 20 : obj.left,
      Top: clone ? obj.top + 20 : obj.top,
      Rotation: 0,
      ShapeType: 0,
      Width: obj.width,
      Height: obj.height,
      id: shape['id'] + 1,
      name: 'label'
    };
    return newLabel;
  }

  createTableNumberCircle(element, layoutConfig, selectedLayout) {
    const statusColorData = element && selectedLayout != 0 ? this.getStatusColor(element, layoutConfig, this.cs.settings.value) : { stroke: '#3B4532', fillColor: element && element.IsCommunalTable ? '#ededed' : 'white', numberColor: '#c2c2c2', textColor: 'gray', partyData: null, partyColor: 'black' };

    return new fabric.Circle({
      fill: statusColorData.textColor,
      radius: 15,
      top: element.top,
      left: element.left,
      name: 'textCircle',
      originX: 'center',
      originY: 'center',
      objectCaching: false
    });

  }

  createTableNumberText(element, partySizeText, layoutConfig, selectedLayout) {
    const statusColorData = element && selectedLayout != 0 ? this.getStatusColor(element, layoutConfig, this.cs.settings.value) : { stroke: '#3B4532', fillColor: element && element.IsCommunalTable ? '#ededed' : 'white', numberColor: '#c2c2c2', textColor: 'gray', partyData: null, partyColor: 'black' };

    return new fabric.Text(partySizeText, {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 'bold',
      fill: statusColorData.partyColor,
      originX: 'center',
      originY: 'center',
      name: 'text',
      top: element.top,
      left: element.left,
      objectCaching: false
    });
  }

  createPartSizeNumber(element, layoutConfig, selectedLayout, tableNumberText, numberCircle) {
    const statusColorData = element && selectedLayout != 0 ? this.getStatusColor(element, layoutConfig, this.cs.settings.value) : { stroke: '#3B4532', fillColor: element && element.IsCommunalTable ? '#ededed' : 'white', numberColor: '#c2c2c2', textColor: 'gray', partyData: null, partyColor: 'black' };

    return new fabric.Text(tableNumberText + '\n', {
      textAlign: 'center',
      fontSize: 14,
      fill: statusColorData.textColor,
      originX: 'center',
      originY: 'center',
      name: 'tableNumber',
      top: numberCircle.top,
      left: numberCircle.left,
      fontWeight: 'bold',
      objectCaching: false,
      lineHeight: 3

    });
  }

  checkTableiscircle(object) {
    const checkdiff = object.Width - object.Height;
    return Math.abs(checkdiff) < 15 ? ShapeTypeEnum.Circle : ShapeTypeEnum.Ellipse;
  }

  findminObject(tables?, labels?, walls?, shapes?) {
    const newtables = _.cloneDeep(tables.map(obj => ({ ...obj, tempname: 'table' })));
    const newlabels = _.cloneDeep(labels.map(obj => ({ ...obj, tempname: 'label' })));
    const newwalls = _.cloneDeep(walls.map(obj => ({ ...obj, tempname: 'wall' })));
    const newshapes = _.cloneDeep(shapes.map(obj => ({ ...obj, tempname: 'area' })));

    const allObjects = _.cloneDeep(newtables.concat(newlabels, newwalls, newshapes));
    allObjects.forEach(element => {
      if (element.tempname == 'wall') {
        const minx = _.minBy(element.Points, (e) => { return e['X']; });
        const miny = _.minBy(element.Points, (e) => { return e['Y']; });
        element.totalminDataHeight = miny['Y'];
        element.totalminDataWidth = minx['X'];
      }
      else if (element.tempname == 'label') {
        const findstringlength = element.Text.length;
        let getwidth = 2;
        let getHeight = 2;
        const angles = element.Angle;
        if ((angles >= 0 && angles <= 20) || (angles >= 160 && angles <= 200) || (angles >= 340 && angles <= 360)) {
          getwidth = findstringlength / 2;
        } else {
          getHeight = findstringlength / 2;
        }
        element.totalminDataHeight = element.Top - (getHeight);
        element.totalminDataWidth = element.Left - (getwidth);
      }
      else if (element.tempname == 'table') {
        element.totalminDataHeight = element.Top - ((element.Height / 2) + 14);
        element.totalminDataWidth = element.Left - ((element.Width / 2) + 14);
      } else if (element.tempname == 'area') {
        element.totalminDataHeight = element.Top;
        element.totalminDataWidth = element.Left;
      } else {
        element.totalminDataHeight = element.Top - element.Height;
        element.totalminDataWidth = element.Left - element.Width;
      }
    });

    return allObjects;

  }

  findmaxObject(tables?, labels?, walls?, shapes?) {
    const afternewtables = _.cloneDeep(tables.map(obj => ({ ...obj, tempname: 'table' })));
    const afternewlabels = _.cloneDeep(labels.map(obj => ({ ...obj, tempname: 'label' })));
    const afternewwalls = _.cloneDeep(walls.map(obj => ({ ...obj, tempname: 'wall' })));
    const afternewshapes = _.cloneDeep(shapes.map(obj => ({ ...obj, tempname: 'area' })));

    const allObjects = _.cloneDeep(afternewtables.concat(afternewlabels, afternewwalls, afternewshapes));
    allObjects.forEach(element => {
      if (element.tempname == 'wall') {
        const maxx = _.maxBy(element.Points, (e) => { return e['X']; });
        const maxy = _.maxBy(element.Points, (e) => { return e['Y']; });
        element.totalDataHeight = maxy['Y'] + 10;
        element.totalDataWidth = maxx['X'] + 10;
      }
      else if (element.tempname == 'label') {
        const findstringlength = element.Text.length;
        let getwidth = 11;
        let getHeight = 11;
        const angles = element.Angle;
        if ((angles >= 0 && angles <= 20) || (angles >= 160 && angles <= 200) || (angles >= 340 && angles <= 360)) {
          getwidth = findstringlength * 10.2 / 2;
        } else {
          getHeight = findstringlength * 10.2 / 2;
        }
        element.totalDataHeight = getHeight + element.Top;
        element.totalDataWidth = getwidth + element.Left;
      }
      else if (element.tempname == 'table') {
        element.totalDataHeight = (element.Height) + element.Top;
        element.totalDataWidth = (element.Width) + element.Left;
      } else if (element.tempname == 'area') {
        element.totalDataHeight = element.Height + element.Top + 15;
        element.totalDataWidth = element.Width + element.Left + 15;
      } else {
        element.totalDataHeight = element.Height / 2 + element.Top;
        element.totalDataWidth = element.Width / 2 + element.Left;
      }

    });

    return allObjects;
  }

  scaleObjects(canvas, scaleFactorX, scaleFactorY, scaleFactor, BasedOn?, isImage?) {
    const objects = canvas.getObjects();
    for (const i in objects) {
      this.scaleObject(objects[i], scaleFactorX, scaleFactorY, scaleFactor, BasedOn);
    }
    canvas.renderAll();
    canvas.calcOffset();
  }

  scaleObject(object, scaleFactorX, scaleFactorY, scaleFactor, BasedOn?) {
    const scaleX = object.scaleX;
    const scaleY = object.scaleY;
    const left = object.left;
    const top = object.top;
    const shapetype = object.name == 'area';
    let scalefactorneed = false;
    if (object._objects) {
      let shape = object._objects.filter(x => x.name == 'shape');
      if (shape && shape.length) {
        let shapetypetable = shape[0].get('type');
        scalefactorneed = false;
        if (!shapetype && shapetypetable == ShapeTypeEnum.Rectangle && BasedOn == undefined) {
          scalefactorneed = this.getbigRectangle(object.width, object.height);
        }
      }
    }
    const tempScaleX = scaleX * (shapetype || scalefactorneed ? scaleFactorX : scaleFactor);
    const tempScaleY = scaleY * (shapetype || scalefactorneed ? scaleFactorY : scaleFactor);
    const tempLeft = left * scaleFactorX;
    const tempTop = top * scaleFactorY;
    if (object.points) {

      object.scaleX = scaleFactorX;
      object.scaleY = scaleFactorY;
      object.left = tempLeft;
      object.top = tempTop;
    } else {
      object.scaleX = tempScaleX;
      object.scaleY = tempScaleY;
      object.left = tempLeft;
      object.top = tempTop;
    }
    object.setCoords();
  }
  getbigRectangle(Width, Height) {
    const checkdiff = Width < Height;
    let finalval = Height / Width;
    if (!checkdiff) {
      finalval = Width / Height;
    }
    return Math.abs(finalval) > (1.25);
  }
}
