<div class="standbylist-details__standbylist-table">
  <div class="standbylist-details__container">
    <ng-scrollbar>
      <table mat-table [dataSource]="tableDataSource" matSort matSortDirection="desc" matSortDisableClear
        (matSortChange)="onSortData($event)" class="mat-elevation-z8">

        <ng-container matColumnDef="PartyName">
          <th class="seat-grid-header seat-border-color" mat-sort-header mat-header-cell *matHeaderCellDef
            arrowPosition="before">
            {{'partyName' | translate}} </th>
          <td class="seat-grid-content seat-border-color" mat-cell *matCellDef="let tableDataSource">
            <div class="standbylist-details__iconColumn">
              <span class="width-30">
                <span class="icon-VIP" *ngIf="tableDataSource?.Contact?.IsVip"></span>
                <span class="icon-exclamation"
                  *ngIf="tableDataSource?.Notes?.length > 0 || tableDataSource?.Contact?.Notes?.length > 0">
                  <span class="path1"></span><span class="path2"></span><span class="path3"></span>
                </span>
              </span>
              <span class="fullName" (click)="editStandbyParty(tableDataSource)">{{tableDataSource?.Contact?.FirstName}}
                {{tableDataSource?.Contact?.LastName}}
                <span *ngIf="_as.OTASourceId.includes(tableDataSource.PartySourceId)" class="party-ota-icon"
                  matTooltipClass="tooltip-class" title="{{tableDataSource.PartySourceId | partySourceOTA}}">
                </span>
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="PartySize">
          <th class="seat-grid-header seat-border-color" mat-sort-header mat-header-cell *matHeaderCellDef
            arrowPosition="before">
            {{'partySize' | translate}} </th>
          <td class="seat-grid-content seat-border-color" mat-cell *matCellDef="let tableDataSource">
            {{tableDataSource.Size}} </td>
        </ng-container>

        <ng-container matColumnDef="SeatingArea">
          <th class="seat-grid-header seat-border-color" mat-sort-header mat-header-cell *matHeaderCellDef
            arrowPosition="before">
            {{'seatingArea' | translate}} </th>
          <td class="seat-grid-content seat-border-color" mat-cell *matCellDef="let tableDataSource">
            {{tableDataSource.SeatingAreaId | getSeatingAreaName : _settings}} </td>
        </ng-container>

        <ng-container matColumnDef="PagePreference">
          <th class="seat-grid-header seat-border-color" mat-header-cell *matHeaderCellDef>
            {{'pagePreference' | translate}} </th>
          <td class="seat-grid-content seat-border-color" mat-cell *matCellDef="let tableDataSource">
            <div
              *ngIf="(tableDataSource.PageMethod == PageMethod.Email || tableDataSource.PageMethod == PageMethod.Email2)"
              class="icon-envelope"></div>
            <div
              *ngIf="(tableDataSource.PageMethod == PageMethod.Sms || tableDataSource.PageMethod == PageMethod.Sms2 || tableDataSource.PageMethod == PageMethod.SMSProxyRelay)"
              class="icon-SMS"></div>
            <div *ngIf="tableDataSource.PageMethod == PageMethod.Manual" class="icon-Path-506"></div>
            <div
              *ngIf="(tableDataSource.PageMethod == PageMethod.VoiceCall || tableDataSource.PageMethod == PageMethod.VoiceCall2)"
              class="icon-ic_stay_current_portrait_24px"></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="CreatedDate">
          <th class="seat-grid-header seat-border-color" mat-sort-header mat-header-cell *matHeaderCellDef
            arrowPosition="before">
            {{'createdDateText' | translate}}</th>
          <td class="seat-grid-content seat-border-color" mat-cell *matCellDef="let tableDataSource">
            {{tableDataSource.CreatedAt | formatSettingsDate: _settings.General.DateFormat}} </td>
        </ng-container>

        <ng-container matColumnDef="Time">
          <th class="seat-grid-header seat-border-color" mat-header-cell *matHeaderCellDef> {{'timeText' | translate}}
          </th>
          <td class="seat-grid-content seat-border-color" mat-cell *matCellDef="let tableDataSource">
            {{tableDataSource.CreatedAt | localizedDate: 'LT'}} </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th class="seat-grid-header seat-border-color" mat-header-cell *matHeaderCellDef> {{'actionText' | translate}}
          </th>
          <td class="seat-grid-content seat-icon-color seat-border-color" mat-cell *matCellDef="let tableDataSource" >
            <a [ngClass]="{'disable-delete' : disableStandbyDelete}" (click)="removeStandbyParty(tableDataSource)"><i class="icon-ic_delete_24px"></i></a>
          </td>
        </ng-container>

        <ng-container matColumnDef="timeHeader">
          <td mat-cell colspan=5 class="standbylist-details__groupedHeader seat-border-color seat-header-four"
            *matCellDef="let group">
            {{group.wishedGroupTime | localizedDate: 'LT' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="covers">
          <td mat-cell colspan=2 class="standbylist-details__groupedHeader seat-border-color seat-header-four"
            *matCellDef="let group">
            {{group.parties}} {{'partyText' | translate}}, {{group.covers}} {{'coversText' | translate}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: headerColumns; when: isGroup" class="seat-secondary-bg-color"> </tr>
      </table>
      <div class="standbylist-details__no-standbyparties" *ngIf="tableDataSource?.data?.length == 0">
        <img src="{{noStanbyPartyUrl}}" alt="" class="standbylist-details__no-standbyparties-img">
        <span class="standbylist-details__no-standbyparties-text">{{'noStandbyPartiesFound' | translate}}</span>
      </div>
    </ng-scrollbar>
  </div>
</div>