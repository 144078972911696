import { Component, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import * as _ from "lodash";
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { RetailReportControl } from '../../../basereport/base-report.component';
@Component({
  selector: 'app-retail-sales-by-category',
  templateUrl: './sales-by-category.component.html',
  styleUrls: ['./sales-by-category.component.scss']
})
export class RetailSalesByCategoryComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() validateUser = new EventEmitter<boolean>();
  CategoryFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  registers: any;
  clerks: any[];
  machineName: DropDownData[] = [];
  selectedDropdownType = '';
  valuesSelected: any[];
  allCategoryGroups: any[];
  categoryGroupSelected: any[] = [];
  showMachineDropDown: boolean = false;
  includeZeroMemberCharge: boolean;
  functionalities: { [key: string]: boolean } = {};
  editPatchValue: any;
  isFromEdit = false;
  validSelection: boolean = true;
  validSelectionErrMsg:string;
  categoryComponentFilter: any;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, private func: RetailFunctionalityBusiness, private dataService: ReportDataService, private propertyInfo: RetailPropertyInformation
    , private container: ViewContainerRef) {

  }
  async ngOnInit() {
    this.patchValues();
    this.showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;
    this.radioFilter = [{ id: 0, name: this.captions.DetailByOutlet }, { id: 1, name: this.captions.SummaryByOutlet }];
    this.CategoryFormGrp = this.fb.group({
      reportcategory: new UntypedFormControl(),
      register: new UntypedFormControl(),
      clerks: new UntypedFormControl(),
      machineName: new UntypedFormControl([this.defaultData]),
      itemFilter: 0,
      includeZeroMemberChargeToggle: false,
      includeCategoryNotMapped: true
    });
    this.functionalities = await this.func.getRetailFunctionality();
    this.includeZeroMemberCharge = this.functionalities.ShowZeroMemberChargeInSaleByCategoryReport;
    this.formReady.emit(this.CategoryFormGrp);
    if (!this.isFromEdit) {
      this.onLoad();
    }
  }
  private async patchValues() {
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;

      if (this.localization.isFromJobScheduler && _parent.edit) {
        this.isFromEdit = true;
        let patchItem = this.editPatchValue;
        if (patchItem.ClerkIds != undefined) {
          this.dataService.GetAllUsersByPropertyId().then(res => {
            let arr = res.filter((item) => {
              return this.editPatchValue.ClerkIds.find(x => x == item.id);
            });
            this.clerks = arr;
          });
        }

        let allcategories: any[] = await this.dataService.GetAllCategories();
        this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();
        if (patchItem.CategoryIds != undefined && patchItem.CategoryIds?.length > 0) {
          let categoryGroupIds: any[] = allcategories.filter(x => this.editPatchValue.CategoryIds.includes(x.id)).map(x => x.categoryGroup);
          this.allCategoryGroups = this.allCategoryGroups.filter(x => categoryGroupIds.includes(x.id));
          this.categoryGroupSelected = this.allCategoryGroups;
        }
        if (patchItem.ReportGroupId != undefined && patchItem.ReportGroupId?.length > 0) {
          this.allCategoryGroups = this.allCategoryGroups.filter(x => patchItem.ReportGroupId.includes(x.id));
          this.categoryGroupSelected = this.allCategoryGroups;
        }
        if (patchItem.categoryIds == undefined || patchItem.categoryIds?.length == 0) {
          patchItem.CategoryIds = allcategories.filter(x => this.allCategoryGroups.map(x => x.id).includes(x.categoryGroup)).map(x => x.id);
        }
        if (patchItem.MachineNameIds != undefined) {
          this.dataService.GetMachineName().then(res => {
            let arr = res.filter((item) => {
              return this.editPatchValue.MachineNameIds.find(x => x == item.id);
            });
            this.machineName = arr;
          });
        }

        this.categoryComponentFilter =
        {
          category: patchItem.CategoryIds,
          subCategory1: patchItem.SubCategory1Ids,
          subCategory2: patchItem.SubCategory2Ids,
          subCategory3: patchItem.SubCategory3Ids,
          subCategory4: patchItem.SubCategory4Ids,
          subCategory5: patchItem.SubCategory5Ids
        };
      }
    }
  }
  private async onLoad() {
    this.clerks = await this.dataService.GetAllUsersByPropertyId();
    this.machineName = await this.dataService.GetMachineName();
    this.clerks = _.orderBy(this.clerks, [user => user.description.toLowerCase()], 'asc');
    this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();

    this.business.FilterDataSource['allCategoryGroups'] = this.allCategoryGroups;
    this.business.FilterDataSource["allMachineNames"] = this.machineName;
    this.business.FilterDataSource['clerks'] = this.clerks;
  }

  formInitialized(form: UntypedFormGroup) {
    this.CategoryFormGrp.setControl('category', form);
  }


  getFormControlValue(event) {
    if (event[0] === 'reportcategory') {
      this.CategoryFormGrp.setControl('reportcategory', event[1]);
    } else if (event[0] === 'clerks') {
      this.CategoryFormGrp.setControl('clerks', event[1]);
    }
    else if (event[0] === 'machineName') {
      this.CategoryFormGrp.setControl('machineName', event[1]);
    }
  }

  getCategoryGroupValue(event) {
    this.categoryGroupSelected = [...event];
  }

  IsSelectionValid(validity: boolean) {
    this.validSelection = validity;
    this.validateUser.emit(validity);
    if (!validity) {
      const caption: any = this.localization.captions.reports.PleaseSelectAtleastOneOption;
      this.validSelectionErrMsg = this.localization.replacePlaceholders(caption, ["option"], [this.captions.User]);
    }

  }
}
