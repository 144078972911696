<!--categories filter-->
<section [formGroup]="InventoryReportFormGrp" autocomplete="off">
  <div class="mb-3" *ngIf ="!isFromJobScheduler">
    <mat-form-field class="full-width ag_mt--4 date-picker-width" [floatLabel]="floatLabel">
      <input matInput formControlName="startDate"
          [matDatepicker]="startDatePicker" dateRestricter
          (dateChange)="startDateChanged($event, 'startDate', 'endDate')"
          [placeholder]="captions.lbl_startDate" readonly>
      <mat-datepicker-toggle matSuffix [for]="startDatePicker">
          <i  aria-hidden="true" class="icon-calendar" matDatepickerToggleIcon></i>
      </mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="full-width date-picker-width" [floatLabel]="floatLabel">
        <input matInput formControlName="endDate" [min]="minEndDate" dateRestricter [max]="maxEndDate"
            [matDatepicker]="endDatePicker" [placeholder]="captions.lbl_endDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker">
            <i  aria-hidden="true" class="icon-calendar" matDatepickerToggleIcon></i>
        </mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div *ngIf="isFromJobScheduler">
    <div class="ag_display--flex">
      <mat-form-field class="full-width" [floatLabel]="floatLabel" >
        <mat-select [id]="'ag-automation-selection-timeline'" [formControlName]="'timelineFrom'" 
          [attr.LiteralID]="'timelineFrom'" [placeholder]="captions.lbl_startDate">
          <mat-option *ngFor="let item of timelineOptions" [value]="item.id" [matTooltip]="item.viewValue">
            {{item.viewValue}}</mat-option>
        </mat-select>
      </mat-form-field>
        <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--33 pr-3"class="ag-ml-1">
        <input matInput [formControlName]="'timelineCountFrom'" autocomplete="off" [type]="'number'">
      </mat-form-field>
    </div>
    <div class="display-center">
      <mat-form-field class="full-width" [floatLabel]="floatLabel">
        <mat-select [id]="'ag-automation-selection-timeline'" [formControlName]="'timelineTo'"
          [attr.LiteralID]="'timelineTo'" [placeholder]="captions.lbl_endDate">
          <mat-option *ngFor="let item of timelineOptions" [value]="item.id" [matTooltip]="item.viewValue">
            {{item.viewValue}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--33 pr-3" class="ag-ml-1">
        <input matInput [formControlName]="'timelineCountTo'" autocomplete="off" [type]="'number'">
      </mat-form-field>
    </div>
  </div>
    <app-retail-category-component (formReady)="formInitialized($event)" (IsValidSelection)="IsSelectionValid($event)"
    [fromJobSchedulerEdit] = "isFromEdit" [editValues] = "categoryComponentFilter"></app-retail-category-component>
    <!--category filter End-->  
    <div>
      <mat-radio-group class="report-radio-group" formControlName="itemFilter">
          <mat-radio-button *ngFor="let data of radioFilter" class="report-radio-button mr-3" [value]="data.id">
            {{data.name}}
          </mat-radio-button>
        </mat-radio-group>
  
    </div>
  
  </section>
  