import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IPartiesOrSection, SlotsSectionService } from '../slots-section.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { PartyState, SlottingMode } from '@app/shared/constants/commonenums';
import { PartyService } from '@app/shared/services/party.service';
import { PredefinedPartyMessageDTO } from '@app/shared/models/RestaurantDTO';
import { PageMethod } from '@app/shared/models/InputContact';
import { PopupService } from '@popup-module/popup.service';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-slots-section',
  templateUrl: './slots-section.component.html',
  styleUrls: ['./slots-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SlotsSectionComponent implements OnInit,OnDestroy {

  @Input() data: IPartiesOrSection;
  @Input() dateTime: string;
  @Input() partyDetails?: any;
  @Input() isEdit?: boolean;
  subscriptions: Subscription = new Subscription();
  SlottingMode = SlottingMode;
  @ViewChild('editSlotPopup') private editSlotPopup: NgbPopover;
  @ViewChildren('messageChat') private messageChat: QueryList<NgbPopover>;
  @ViewChildren('emailChat') private emailChat: QueryList<NgbPopover>;
  pageTimer: Subscription;
  PartyState = PartyState;
  PageMethod = PageMethod;
  isMobileChat:boolean = false;
  inputString:string = '';
  disableMessagePopup:boolean = false;
  predefinedMsgs$ = new BehaviorSubject<PredefinedPartyMessageDTO[]>(null);
  editOrDisableConfig: FieldConfig[];
  _MESSAGES = MESSAGES;
  
  constructor(public cs: CacheService,public slotService: SlotsSectionService,public ps: PartyService,public popupService: PopupService) { }

  ngOnInit(): void {
    this.editOrDisableConfig = [{
      type: 'checkbox',
      name: 'enableDisaleSlot',
      value: this.data?.isChecked || false,
      class: this._MESSAGES.LABELS['slots-section__disable-slot'],
      cellClick: (event) => { this.changeDisableSlot(event) }
    }];
  }

  ngOnChanges(): void {
    this.slotService.setSlotsDisabled(this.data,this.dateTime,this.cs.settings.value);
    if (this.partyDetails) {
      this.partyDetails.partyNotes =[];
      this.partyDetails.partyNotes = this.slotService.getPartyNotes(this.partyDetails, this.cs.settings.value);
      this.slotService.setPageBackgroudColor(this.partyDetails,this.cs.settings.value,this.pageTimer);
    }
  }

  changeDisableSlot(event: any): void {
    this.data.isChecked = event.checked;
    if(event.checked) {
      this.slotService.selectedSlotCount = this.slotService.selectedSlotCount + 1;
    }
    else {
      this.slotService.selectedSlotCount = this.slotService.selectedSlotCount - 1;
    }
    this.slotService.slotsActions$.next({selectedSlots: this.slotService.selectedSlotCount});
  }

  triggerPartyStatusPopup(popover: NgbPopover, event: any): void {
    if (this.partyDetails.State != PartyState.Seated && !this.data?.isExpired) {
      popover.open();
    }
    event.stopPropagation();
  }

  openEditSlotPopup(): void {
    if(this.slotService.existingeditPopup) {
      this.slotService.existingeditPopup.close();
      this.slotService.existingeditPopup = null;
    }
        this.editSlotPopup.open();
        this.slotService.existingeditPopup = this.editSlotPopup;
      }

  closeEditSlotPopup(): void {
      if (this.editSlotPopup) {
        this.editSlotPopup.close();
      }
  }

  partyNotesPopup(event: any,partyNotes: NgbPopover) {
    if(!this.data?.isExpired) {
      partyNotes.open();
    }
    event.stopPropagation();
  }

  emailMessages(popover: NgbPopover,event: any):void {
    this.openMessagePopup(popover,event);
  }

  mobileMessages(popover: NgbPopover,event: any):void {
    this.isMobileChat = true;
    this.openMessagePopup(popover,event);
  }

  openMessagePopup(popover: NgbPopover,event: any):void {
    if(!this.data?.isExpired) {
    this.predefinedMsgs$.next(this.cs.settings.value.PredefinedPartyMessages);
    this.inputString = this._MESSAGES.LABELS.messages;
    this.disableMessagePopup = (this.slotService.getPastReservationStatus(this.partyDetails,this.cs.settings.value) && this.partyDetails?.Type == 0) || this.partyDetails?.State == PartyState.Seated || this.partyDetails?.State == PartyState.Left ? true : false;
    popover.open();
  }
  event.stopPropagation();
  }

  closeMobileChat():void {
    if (this.isMobileChat && this.messageChat) {
      this.isMobileChat = false;
      this.messageChat.forEach((chatPopup, index) => {
        if (chatPopup) {
          chatPopup.close();
        }
      });
    } else if (this.emailChat) {
      this.emailChat.forEach((chatPopup, index) => {
        if (chatPopup) {
          chatPopup.close();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if(this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

const MESSAGES = {
  LABELS: {
    "slots-section__disable-slot": "slots-section__disable-slot",
    "messages" : "messages"  
  },
  CONFIG: {
    "reservationNotes": "reservationNotes"
  }
}