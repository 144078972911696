import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { popupConfig } from '../../shared/business/shared.modals';
import { Subscription } from 'rxjs';
import { SettingDialogPopupComponent } from '../../shared/setting-dialog-popup/setting-dialog-popup.component';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailOutletTerminalDataService } from './retail-outlet-terminal-data.service';
import { OutletTerminalUIMapper } from "../../retail.modals";
@Component({
  selector: 'app-retail-outlet-terminal',
  templateUrl: './retail-outlet-terminal.component.html',
  styleUrls: ['./retail-outlet-terminal.component.scss'],
  providers: [RetailOutletTerminalDataService],
  encapsulation: ViewEncapsulation.None
})
export class RetailOutletTerminalComponent implements OnInit {
  tableoptions: any;
  tabledata:OutletTerminalUIMapper[];

  EditedtableOptions: any[];
  searchPlaceholderText: string;
  filteredData: any = [];
  filteredDataByOutlet: any[];
  captions: any = this.localization.captions.retailsetup;
  outletId: number;
  IsViewOnly = false;
  subscription: Subscription;
  settingDialogSubscription: Subscription;
  EditCaption: string;
  onEditDisableFunction = true;
  disableTableRows: boolean;
  constructor(private dialog: MatDialog, private retailService: RetailSetupService, public localization: RetailLocalization
    , private propertyInfo: RetailPropertyInformation, private utils: RetailUtilities, private retailOutletTerminalService: RetailOutletTerminalDataService) {
  }

  ngOnInit() {
    this.searchPlaceholderText = `${this.captions.Outlet}, ${this.captions.StoreID}, ${this.captions.TerminalName}, ${this.captions.TerminalId}`;
    this.getAllOutletTerminal();
    const getviewOnly = this.retailService.retailSetupBreakPoints.find(bp => bp.breakPointNumber === myGlobals.RetailBreakPoint.StoreTerminal);
    this.IsViewOnly = getviewOnly && getviewOnly.view;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.settingDialogSubscription) {
      this.settingDialogSubscription.unsubscribe();
    }
  }

  async getAllOutletTerminal() {
    this.tabledata = await this.retailOutletTerminalService.getStoreTerminals();
    this.BindTableData();
  }

  BindTableData() {
    this.tableoptions = [{
      TableHdrData: this.GetHeader(),
      TablebodyData: this.tabledata,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext:  this.searchPlaceholderText,
      EnableActions: true,
      SelectRows: false,
      SelectedSettingId: myGlobals.GridType.outletTerminal,
      Searchable: myGlobals.GridType.customfield,
      EditMoreOption: false,
      Sortable: 'storeId',
      TableId: myGlobals.GridType.outletTerminal,
      disableDelete: false,
      showToggle: false,
      IsViewOnly: this.IsViewOnly,
      disableEditButton: this.IsViewOnly
    }];

    this.EditedtableOptions = _.cloneDeep(this.tableoptions);
    this.filteredData = this.tabledata;
    this.filteredDataByOutlet = this.tabledata;
  }

  async DeleteRecords(event) {
    let result: boolean = await this.retailOutletTerminalService.deleteOutletTerminalConfiguration(event[0].id);
    if(result){
      this.getAllOutletTerminal();
    }
  }

  EditRecords(data) {
    this.openDialog('Edit', data);
  }

  inactiveSliderChange(event: any) {
    this.IsViewOnly =  true;
  }

  openDialog(preTit: any, data?: any): any {
    let popupConfiguration: popupConfig;
    if (preTit === 'Edit') {
      this.EditCaption = this.localization.captions.common.Edit;
      popupConfiguration = {
        operation: 'edit',
        GridOperationType: myGlobals.GridOperationType.Edit
      };
    } else {
      popupConfiguration = {
        operation: 'create',
        GridOperationType: myGlobals.GridOperationType.Create
      };
    }
    const afterClosed = true;
    return this.triggerPopup(preTit, popupConfiguration, afterClosed, data);
  }


  triggerPopup(preTit: any, popupConfiguration: any, afterClosed = true, data: any): any {
    const Dialogtitle = this.captions.NewTerminal;
    const DialogTemplate = 'OT';
    this.disableTableRows = false;
    const dialogRef = this.dialog.open(SettingDialogPopupComponent, {
      width: '50%',
      maxWidth: '1000px;',
      height: '575px',
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: { headername: (preTit == 'Edit' ? this.EditCaption : preTit) + '  ' + Dialogtitle, closebool: true, templatename: DialogTemplate, popupConfig: popupConfiguration, datarecord: preTit == 'Edit' ? data : '' },
      disableClose: true
    });

    if (afterClosed) {
      this.settingDialogSubscription = dialogRef.afterClosed().subscribe(result => {
        this.disableTableRows = true;
          this.getAllOutletTerminal();
        if (document.getElementsByClassName('rowDisabled') && document.getElementsByClassName('rowDisabled').length > 0) {
          document.getElementsByClassName('rowDisabled')[0].classList.remove('highlight');
          document.getElementsByClassName('rowDisabled')[0].classList.remove('rowDisabled');
        }
      });
    }
    return dialogRef;
  }

  private GetHeader() {
    return [{ 'title': this.captions.Outlet, 'jsonkey': 'outletName', 'sortcolumndatatype': 'string', 'searchable': true, 'sortable': true },
    { 'title': this.captions.StoreID, 'jsonkey': 'storeId', 'searchable': true, 'sortable': true },
    { 'title': this.captions.TerminalName, 'jsonkey': 'terminalName', 'searchable': true, 'sortable': true},
    { 'title': this.captions.TerminalId, 'jsonkey': 'terminalId', 'searchable': true, 'sortable': true},
    { 'title': this.captions.Active, 'jsonkey': 'isActive', 'searchable': false, 'sortable': true, 'type': 'toggle'}
    ]; 
  }

  async inlineEditDone(data){
     await this.retailOutletTerminalService.updateOutletTerminalConfiguration(data);
    this.getAllOutletTerminal();
    this.IsViewOnly =  false;
  }

  inlineEditCancel(data){
    this.getAllOutletTerminal();
    this.IsViewOnly =  false;
  }
}
