import { Injectable } from "@angular/core";
import { PropertyApiRoutes } from "../../common-route";
import { TenantManagementCommunication } from "../../communication/services/tenantmanagement-communication-service";
import { API, Tenant, VCartConfiguration } from '../../Models/property-information.model';

@Injectable({
    providedIn: 'root'
})
export class PropertyInformationDataService {

    constructor(private _authenticationCommunication: TenantManagementCommunication) {
    }


    public getPropertyInformation(propertyId: number): Promise<API.PropertyInformation> {
        return this._authenticationCommunication.getPromise({
            route: PropertyApiRoutes.GetProperty, uriParams: { id: propertyId }
        },false,false,true);        
    }

    public updatePropertyInformation(value: API.PropertyInformation): Promise<boolean> {
        return this._authenticationCommunication.postPromise({
            route: PropertyApiRoutes.UpdateProperty, body: value
        });       
    }

    public GetLoginDetailsByToken(token: string): Promise<string> {
        const response: Promise<string> = this._authenticationCommunication.postPromise({
            route: PropertyApiRoutes.GetLoginDetailsByToken,
            body: { token: token}
        });
        return response;
    }

    public getAllLanguages(): Promise<API.Language[]>{
        return this._authenticationCommunication.getPromise({
            route: PropertyApiRoutes.GetAllLanguages
        });        
    }

    public GetPropertiesInformationByUserIdAndProductId(userId: number | string, productId: number | string): Promise<API.PropertyDetails[]> {
        return this._authenticationCommunication.getPromise({
            route: PropertyApiRoutes.GetPropertiesInformationByUserIdAndProductId, uriParams: { userId: userId, productId: productId }
        });       
    }

    public GetLoginToken(sessionId: string, propertyId: number, productId: number|string): Promise<string> {
        return this._authenticationCommunication.getPromise({
            route: PropertyApiRoutes.GetLoginToken,
            uriParams: {
                sessionId: sessionId,
                propertyId: propertyId,
                productId: productId
            }
        });      
    }

    public GetTenantByTenantId(tenantId:number) : Promise<Tenant>
    {
        return this._authenticationCommunication.getPromise({
            route: PropertyApiRoutes.GetTenantByTenantId, uriParams: { tenantId }
        });  
    }

    public GetVCartConfiguration(tenantId: number): Promise<VCartConfiguration<any>> {
        return this._authenticationCommunication.getPromise({
            route: PropertyApiRoutes.GetVCartConfiguration, uriParams: { tenantId }
        }, false);
    }

}