import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyDecimalFormatter'
})
export class CurrencyDecimalFormater implements PipeTransform {

  transform(number: number): any {
    if(isNaN(number)){
      number = 0;
    }
   return Number(number).toFixed(2)
  }
}