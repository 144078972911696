import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { AdditionalinfoComponent } from '@components/create-update-guest-tab-layout/definitions/additionalinfo/additionalinfo.component';
import { AddressComponent } from '@components/create-update-guest-tab-layout/definitions/address/address.component';
import { ContactComponent } from '@components/create-update-guest-tab-layout/definitions/contact/contact.component';
import { PersonalInfoComponent } from '@components/create-update-guest-tab-layout/definitions/personal-info/personal-info.component';
import { PreferencesComponent } from '@components/create-update-guest-tab-layout/definitions/preferences/preferences.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { buttonTypes, ComponentTypes, GuestBookTabs } from '@constants/commonenums';
import * as globals from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { ContactDTO } from '@models/InputContact';
import { ServerDTO, SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails, TabConfig } from '@popup-module/models/popup.interface';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { GuestBookService } from '@services/guestbook.service';
import * as _ from 'lodash';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { RoleType } from '@models/LoginResultDTO';
import { ITabOutputDetails } from '@app/settings/models/common.interface';
import FeedbackOptions from '@data/feedbackFilterOptions.json';
import { Utilities } from '@app/shared/utilities/utilities';
import { contactsSort } from '@constants/globalConstants';
import { CustomTabsComponent } from '@app/shared/components/custom-tabs/custom-tabs.component';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { GuestItineraryComponent } from '@app/shared/components/guest-itinerary/guest-itinerary.component';
import { PartyService } from '@app/shared/services/party.service';


@Component({
  selector: 'app-guestlist',
  templateUrl: './guestlist.component.html',
  styleUrls: ['./guestlist.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GuestlistComponent implements OnInit, AfterViewInit, OnDestroy {
  alphabets: any = [];
  @Input() contacts: ContactDTO[];
  @Input() TabName: GuestBookTabs;
  hoveredAlphabet: string;
  allContacts = false;
  checkedContancts: any;
  selectedContactId: number;
  showExtraClass = true;
  selectedAlphabet: string;
  addContactBtn: ButtonValue;
  searchBtn: ButtonValue;
  searchconfig: FieldConfig[];
  showByConfig: FieldConfig[];
  sortByConfig: FieldConfig[];
  selectedAll = false;
  dataToBeSearched: string;
  position = 'left';
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  @ViewChild(PreferencesComponent) preferencesComponent: PreferencesComponent;
  @ViewChild('searchForm') searchByForm: DynamicFormComponent;
  guestBookSubscription: ISubscription;
  guestDetailsVisibleSubscription: ISubscription;
  guestTabChangeSubscription: ISubscription;
  currentTabIndex: GuestBookTabs;
  isSearch = false;
  cancelSubscription: ISubscription;
  confirmSubscription: ISubscription;
  feedbackSubscription: ISubscription;
  subscriptions: Subscription = new Subscription();
  _settings: SettingsDTO = {} as SettingsDTO;
  HostRoleType: any;
  RoleType = RoleType;
  feedbackOptions: any;
  feedbackSortcount=0;
  searchByConfig: FieldConfig[];

  constructor(public guestBookService: GuestBookService, public appService: AppService, public dialog: MatDialog, private popupService: PopupService,private partyService : PartyService,
    @Inject(COMPONENTINPUT) private data: ContactDTO[], private translateService: TranslateService, private cs: CacheService) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
    this.feedbackOptions = FeedbackOptions;
  }

  ngOnInit() {
    this.feedbackSortcount=0;
    this.guestBookService.sortBy = contactsSort.filter(x => x.id == 'LastName')[0].id;
    this.genCharArray('A', 'Z');
    this.allContacts = false;
    this.checkedContancts = [];
    this.addContactBtn = {
      type: buttonTypes.actionPrimary,
      label: 'guestBookaddContactText',
      disbaledproperity: false,
      customclass: 'guestlist__add-contact-btn',
      icon: 'icon-Group-741'
    };
    this.searchBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: 'searchText',
      disbaledproperity: true,
      customclass: 'guestlist__search-btn',
    };
    this.searchconfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.translateService.instant("searchContactText"),
        class: 'guestlist__search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this),
        blurClick: this.blurClick.bind(this),
      }
    ];
    this.sortByConfig = [
      {
        type: 'select',
        name: 'sortBy',
        placeholder: '',
        options: globals.contactsSort.map(item => {
          item.value = this.translateService.instant(''+item.value)
          return item;
        }),
        class: 'guestlist__sort-by',
        showErrorText: true,
        appearance: true,
        isTranslate: true,
        value: this.guestBookService.sortBy
      }
    ];
    this.searchByConfig = [
      {
        type: 'select',
        name: 'searchBy',
        placeholder: '',
        options: globals.contactsSearch.map(item => {
          item.value = this.translateService.instant(''+item.value)
          return item;
        }),
        class: 'guestlist__search-by',
        showErrorText: true,
        appearance: true,
        isTranslate: true,
        value: globals.contactsSearch[0].id
      }
    ];
    this.showByConfig = [
      {
        type: 'select',
        name: 'showBy',
        class: 'guestlist__sort-by',
        value: globals.itemsPerPage[1].id,
        showErrorText: true,
        appearance: true,
        options: globals.itemsPerPage,
        isTranslate: false
      }
    ];
    this.HostRoleType = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostRoleType;
  }

  ngOnDestroy() {
    this.guestBookService.page = 1;
    this.feedbackSortcount=0;
    if (this.guestBookSubscription) {
      this.guestBookSubscription.unsubscribe();
    }
    if (this.guestDetailsVisibleSubscription) {
      this.guestDetailsVisibleSubscription.unsubscribe();
    }
    if (this.guestTabChangeSubscription) {
      this.guestTabChangeSubscription.unsubscribe();
    }
    this.guestBookService.editGuest = false;
    if (this.cancelSubscription)
      this.cancelSubscription.unsubscribe();
    if (this.confirmSubscription)
      this.confirmSubscription.unsubscribe();
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    if (this.feedbackSubscription)
      this.feedbackSubscription.unsubscribe();
  }


  clearSearch(event) {
    this.components.first.form.get('searchText').setValue('');
    if (this.isSearch) {
      this.searchData(false);
    }
  }

  blurClick(e) {
    if (e.keyCode == 13) {
      this.searchData(true);
    }
  }
  ngAfterViewInit() {
    this.subscriptions.add(this.components.first.form.valueChanges.subscribe(val => {
      if (val.searchText.length >= 2) {
        this.searchBtn.disbaledproperity = false;
      } else {
        this.searchBtn.disbaledproperity = true;
      }
      this.dataToBeSearched = val.searchText;
    }));


    this.subscriptions.add(this.components.last.form.valueChanges.subscribe(val => {
      const filteredOption = globals.itemsPerPage.filter(x => x.id === val.showBy);
      this.guestBookService.pageSize = filteredOption[0].value;
      this.guestBookService.pageNumber = 0;
      this.loadContacts();
    }));
    if(this.searchByForm.form){
      this.isConfirmationCodeSearch(this.searchByForm.form.value);
    }
    this.subscriptions.add(this.searchByForm.form.valueChanges.subscribe(val => {
      if(val.searchBy) {
        this.isConfirmationCodeSearch(val.searchBy);
      }
    }));

    this.components.forEach((element, index) => {
      if (index === 1) {
        this.subscriptions.add(element.form.valueChanges.subscribe(val => {
          this.guestBookService.pageNumber = 0;
          this.guestBookService.sortBy = val.sortBy.replace(/\s/g, '');
          this.paginateTable(1);
        }));
      }
    });
    this.guestDetailsVisibleSubscription = this.guestBookService.isGuestDetailsVisible.subscribe(val => {
      if (val) {

        Promise.resolve().then(() => {
          this.setButtonDetails('', 'icon-search', 'guestlist__search-btn-small', buttonTypes.actionPrimarySmall, '',
          buttonTypes.actionPrimarySmall);
          this.setCustomClass('guestlist__search-width', 0, this.searchconfig, true)
          this.setCustomClass('guestlist__sortby-width', 0, this.sortByConfig, true);
        });
      } else {
          Promise.resolve().then(() => {
            this.setButtonDetails('searchText', '', 'guestlist__search-btn', buttonTypes.actionSecondarySmall,
            'guestBookaddContactText', buttonTypes.actionPrimary);
            this.setCustomClass('guestlist__search-width', 0, this.searchconfig, false)
            this.setCustomClass('guestlist__sortby-width', 0, this.sortByConfig, false);
          });
      }
    });
    this.guestTabChangeSubscription = this.guestBookService.guestListTabChange$.subscribe((val) => {
      this.feedbackOptions = _.cloneDeep(FeedbackOptions);
      this.feedbackSortcount=0;
      if (this.isSearch && this.dataToBeSearched && this.currentTabIndex === val.index) {
        this.guestBookService.searchString = this.dataToBeSearched;
      }
    });
  }

  setButtonDetails(label: string, icon: string, customclass: string, type: buttonTypes, contactButton: string,
    contactButtonType: buttonTypes) {
    this.searchBtn.label = label;
    this.searchBtn.icon = icon;
    this.searchBtn.customclass = customclass;
    this.searchBtn.type = type;
    this.addContactBtn.label = contactButton;
    this.addContactBtn.type = contactButtonType;
  }

  setCustomClass(customClass, index, config, add) {
    if (add) {
      config[index].class = config[0].class + ' ' + customClass;
    } else {
      const splitString = config[index].class.split(' ');
      let classToBeAdded = '';
      splitString.forEach(string => {
        if (string != customClass) {
          classToBeAdded = classToBeAdded + string + ' ';
        }
      });
      config[index].class = classToBeAdded;
    }
  }

  searchData(value) {
    if(!value) {
      this.guestBookService.isConfirmationCodeSearch = value;
    }
    else {
      if(this.searchByForm.form.value.searchBy) this.isConfirmationCodeSearch(this.searchByForm.form.value.searchBy)
    }
    this.isSearch = value;
    this.currentTabIndex = this.guestBookService.currentGuestTab;
    this.guestBookService.isGuestDetailsVisible.next(false);
    this.guestBookService.selectedContactIndex = null;
    this.guestBookService.contacts = [];
    this.guestBookService.searchString = this.components.first.form.controls.searchText.value;
    this.guestBookService.pageNumber = 0;
    this.checkedContancts = this.checkedContancts.length == 1 ? [] : this.checkedContancts;
    this.loadContacts();
  }


  /* function to generate alphabets */
  genCharArray(charA, charZ) {
    this.alphabets = [];
    this.alphabets.push('All');
    let i = charA.charCodeAt(0);
    const j = charZ.charCodeAt(0);
    for (; i <= j; ++i) {
      this.alphabets.push(String.fromCharCode(i));
    }
  }

  hoveredElement(item) {
    this.hoveredAlphabet = item;
  }

  favClick(contact: ContactDTO) {
    const index = this.guestBookService.contacts.findIndex((data: ContactDTO) => data.Id === contact.Id);
    this.guestBookService.contacts[index].IsFavorite = !this.guestBookService.contacts[index].IsFavorite;
    this.guestBookService.updateContactListStatus(index);
  }
  feedbackClick(contact: ContactDTO) {
    const index = this.guestBookService.contacts.findIndex((data: ContactDTO) => data.Id === contact.Id);
    this.guestBookService.contacts[index].IsFeedback = !this.guestBookService.contacts[index].IsFeedback;
    this.guestBookService.updateContactListStatus(index);
  }
  vipClick(contact: ContactDTO) {
    const index = this.guestBookService.contacts.findIndex((data: ContactDTO) => data.Id === contact.Id);
    this.guestBookService.contacts[index].IsVip = !this.guestBookService.contacts[index].IsVip;
    this.guestBookService.updateContactListStatus(index);
  }

  loadContacts() {
    this.guestBookService.contacts = [];
    this.guestBookService.loadContacts();
  }


  getItinerary(contact: ContactDTO){
    
    const componentDetails = {
      componentName: GuestItineraryComponent,
      dimensionType: 'small',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: contact,
      popupTitle: this.translateService.instant('Guest Itinerary')
    }
    this.partyService.standbyDialog = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: globals.popupDialogDimension.guestItineraryactionDialogMaxWidth,
      height: "700px",
      data: {
        componentDetails,
        from: ComponentTypes.GuestItinerary,
        back: true,
        standalone: true,
        showFooter: false
      }
    });

  }

  onScrollDown() {
    this.guestBookService.pageNumber++;
    this.guestBookService.loadContacts();
  }

  sortBy(e) {
  }

  paginateTable(e) {
    this.allContacts = false;
    this.guestBookService.pageNumber = (e - 1);
    this.guestBookService.contacts = [];
    this.guestBookService.loadContacts();
  }

  showGuestDetails(index, contactId) {
    // On selecting another contact the existing guest Detail window should be destroyed and re create again
    // this.guestBookService.selectedContactIndex = index;
    // if (this.guestBookService.isGuestDetailsVisible.getValue() === true) {
    //   this.guestBookService.isGuestDetailsVisible.next(false);
    // }
    // this.dividePage = true;
    // this.selectedRow = item;
    this.selectedContactId = contactId;
    // this.guestBookService.contactId = contactId;
    this.guestBookService.getGuest(contactId, false, true);
    // this.setButtonDetails('', 'icon-search', 'guestlist__search-btn-small', buttonTypes.actionPrimarySmall, '',
    //                       buttonTypes.actionPrimarySmall);
  }

  selectContact(index, item, value) {
    item.checked = value.checked;
    if (value.checked) {
      this.checkedContancts.push(item.Id);
      if (this.checkedContancts.length === this.guestBookService.allAvailableContacts.value.length && this.HostRoleType == RoleType.Manager) {
        this.allContacts = true;
      }
    } else {
      this.allContacts = false;
      const selectedIndex = this.checkedContancts.findIndex(x => x === item.Id);
      this.checkedContancts.splice(selectedIndex, 1);
    }
  }

  selectAllContact(value) {
    if (value.checked) {
      this.checkedContancts = [];
      const contacts = _.cloneDeep(this.guestBookService.allAvailableContacts);
      this.checkedContancts = contacts.value.map(a => a.Id);

    } else {
      this.checkedContancts = [];
    }
  }

  editGuest() {
    this.guestBookService.editGuest = true;
    this.guestBookService.getGuest(this.checkedContancts[0], true, false);
    this.guestBookService.guestForm.reset();
    this.guestBookSubscription = this.guestBookService.dataAvailable.subscribe(val => {
      if (val) {
        this.createTabsModal(true, true, true, this.guestBookService.contactDetails);
        this.openDialog('Edit Guest');
      }
    });
  }

  addnewGuest() {
    this.guestBookService.editGuest = false;
    this.guestBookService.getServersList();
    this.guestBookService.guestForm.reset();
    this.createTabsModal(true, true, false, null);
    this.openDialog(this.translateService.instant('popUpTitleaddnewguest'));
  }

  createTabsModal(isDefault: boolean, isNext: boolean, isEdit: boolean, componentInput: any) {
    this.guestBookService.tabsModal = {
      tabs: this.getGuestTabs(),
      default: isDefault,
      componentInput,
      isNextButtonAvailable: isNext,
      isEditTab: isEdit,
    };
  }

  getGuestTabs() {
    const tabComponent: TabConfig[] = [
      {
        tabComponent: PersonalInfoComponent,
        enabledIcon: true,
        tabLabel: 'personalInfoTab',
        tabIcon: 'Path-503 ',
        isDisabled: false
      },
      {
        tabComponent: ContactComponent,
        enabledIcon: true,
        tabLabel: 'contactTab',
        tabIcon: 'Group-593',
        isDisabled: !this.guestBookService.editGuest
      },
      {
        tabComponent: AddressComponent,
        enabledIcon: true,
        tabLabel: 'addressTab',
        tabIcon: 'Group-593',
        isDisabled: !this.guestBookService.editGuest
      },
      {
        tabComponent: PreferencesComponent,
        enabledIcon: true,
        tabLabel: 'prefernencesTab',
        tabIcon: 'Group-601',
        isDisabled: !this.guestBookService.editGuest
      }
    ] as TabConfig[];
    if (this._settings.CustomGuestFields.length > 0 ){
      tabComponent.push({
        tabComponent: AdditionalinfoComponent,
        enabledIcon: true,
        tabLabel: 'additionalInfoTab',
        tabIcon: 'Group-602',
        isDisabled: !this.guestBookService.editGuest
      });
    }
    return tabComponent;
  }

  getTabComponentDetails() {
    return {
      componentName: CustomTabsComponent,
      popupType: 'tabs',
      tabs: this.guestBookService.tabsModal,
      popUpDetails: {
        isStepper: true,
        eventName: 'notifyParent'
      }
    };
  }

  openDialog(popupTitle: string) {
    this.guestBookService.createContactDialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      height: '90%',
      width: '90%',
      maxWidth: '70vw',
      data: {
        service:this.popupService,
        title: popupTitle,
        update: 'ok',
        cancel: 'cancel',
        componentDetails: this.getTabComponentDetails(),
        from: ComponentTypes.guestBook,
        back: true,
        standalone: true
      },
    });

    let guestSubscription = this.popupService.confirmedAction$.subscribe((tabOutputInfo: ITabOutputDetails) => {
      if (tabOutputInfo && tabOutputInfo.isEdit && tabOutputInfo.fromComponent === ComponentTypes.guestBook) {
        this.updateGuest(tabOutputInfo.inputData);
      } else if (tabOutputInfo && tabOutputInfo.fromComponent === ComponentTypes.guestBook) {
        this.submitGuest();
      }
      guestSubscription?.unsubscribe();
    });
    let cancelSubscription = this.popupService.cancelledAction$.subscribe(val =>{
      if(val){
        guestSubscription?.unsubscribe();
        cancelSubscription?.unsubscribe();
      }
    })

    this.subscriptions.add(this.guestBookService.createContactDialogRef.afterClosed().subscribe(result => {
      if (this.guestBookSubscription) {
        this.guestBookSubscription.unsubscribe();
      }
      this.guestBookService.selectedServers = {} as ServerDTO;
      this.guestBookService.selectedTables = '';
      guestSubscription?.unsubscribe();
      cancelSubscription?.unsubscribe();
    }));
  }

  updateGuest(guestDetails) {
    if (this.guestBookService.guestForm.valid) {
      this.guestBookService.updateGuest(true, guestDetails, this.guestBookService.createContactDialogRef);
    }
  }

  submitGuest() {
    if (this.guestBookService.guestForm.valid) {
      this.guestBookService.createGuest(true, this.guestBookService.createContactDialogRef);
    }
  }

  deleteContact() {
    let confirmDeleteContact = false;
    let cancelText = 'No';
    let title = this.translateService.instant('alert');
    let updateText = 'Yes';
    let showAlert = false;
    let msg = this.translateService.instant('contactCancellationMessage');
    if (this.checkedContancts.length > 0) {
      const popUpMessage = [{
        confirmationMessage: msg, dialogTitle: 'confirm', showAlert
      }];

      const componentDetails: ComponentDetails = {
        componentName: ConfirmationPopupComponent,
        dimensionType: 'small',
        popupType: 'active',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: popUpMessage,
        popupTitle: popUpMessage[0].dialogTitle
      };

      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '600px',
        height: '350px',
        data: {
          title,
          update: updateText,
          cancel: cancelText,
          componentDetails,
          from: ComponentTypes.guestlist,
          back: false,
          standalone: true,
          showAlert: false
        }
      });
      let confirmedActionSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.guestlist) {
          confirmDeleteContact = true;
        }
      });
      let cancelledActionSubscription = this.popupService.cancelledAction$.subscribe(val => {
        if (val.from === ComponentTypes.guestlist) {
          confirmDeleteContact = false;;
        }
      });
      this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
        if (confirmDeleteContact) {
          this.guestBookService.deleteContacts(this.checkedContancts);
          this.guestBookService.isGuestDetailsVisible.next(false);
          this.checkedContancts = [];
        }
         if(confirmedActionSubscription){
          confirmedActionSubscription.unsubscribe();
         }
         if(cancelledActionSubscription){
          cancelledActionSubscription.unsubscribe();
         }
      }));
    }
  }

  filterContactByAlphabet(alphabet) {
    this.selectedAlphabet = alphabet;
  }

  sortByFeedback(e) {
    this.feedbackSortcount = this.feedbackSortcount+1;
    if(this.feedbackSortcount > 2)
   {
      this.feedbackSortcount=0;
   }
   this.guestBookService.feedbackSort = this.feedbackSortcount;
   this.loadContacts();
  }
  propertySelectionChanged(item) {

    item.isSelected = !item.isSelected;
    this.guestBookService.FeedbackFilterOption = 0;
    this.guestBookService.feedbackLastDate = null;
    let values = this.feedbackOptions.filter(x => x.isSelected == true);
    let valclosed=values.filter(x => x.id == 1);
    let valunread=values.filter(x => x.id == 3);
    if (valclosed.length >0 && valunread.length>0) {
      this.guestBookService.FeedbackFilterOption = 3;
    } else {

      if (valclosed.length >0) {
        this.guestBookService.FeedbackFilterOption = 1;
      }
      if (valunread.length >0) {
        this.guestBookService.FeedbackFilterOption = 2;
      }
    }
    let dateval=values.filter(x => x.id == 2);
    if(dateval.length >0)
    {
          let getTodatdate=Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
        let lastdates= new Date(getTodatdate.setDate(getTodatdate.getDate()- 30));
        this.guestBookService.feedbackLastDate=lastdates;
    }
    this.loadContacts();
  }

  isConfirmationCodeSearch(value){
    if(value == "confirmationCode"){
      this.guestBookService.isConfirmationCodeSearch = true;
      this.searchconfig[0].label = this.translateService.instant("guestBookSearchByText") + " " + this.translateService.instant("confirmationCode");
      }
      else{
        this.guestBookService.isConfirmationCodeSearch = false;
        this.searchconfig[0].label = this.translateService.instant("searchContactText");
      }
  }


}

