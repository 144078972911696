<div class="member-selection-dialog-container" id="member-selection-popup">
  <div mat-dialog-title class="dialog-container__header">
    <span class="dialog-container__header--title">{{shopCaption.MemberSelectionPopup.lbl_header}}</span>
    <span class="dialog-container__header--close icon-Cancel" (click)="close()"></span>
  </div>
  <div class="dialog-container__content popup-content">
    <form [formGroup]="memberSelectionForm" autocomplete="off">
      <section class="search-section d-flex acctSearchForm">
        <div class="acct-search">
          <mat-form-field class="memberSearchInput" appearance="outline">
            <input formControlName="memberSearch" autocomplete="off" matInput #memberSearchEle
              [placeholder]="shopCaption.MemberSelectionPopup.searchPlaceHolder" [matAutocomplete]="auto"
              #trigger="matAutocompleteTrigger"
              (keydown.enter)="memberSearch(memberSelectionForm.controls['memberSearch'].value)">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let member of memberSearchResult" (click)="chooseMemberPayee(member, $event, null)"
                [value]="member.memberName">
                {{ member.memberName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="icon-section">
            <i aria-hidden="true" class="icon-search icon-Search"
              (click)="memberSearch(memberSelectionForm.controls['memberSearch'].value)"></i>
          </div>
        </div>
        <div class="d-flex sub-header payment-sec">
          <div class="payment-details">{{shopCaption.Total}}: {{popupInput.totalAmount| Currency}} |
            {{shopCaption.lbl_AmountTendered}} : {{amountEntered| Currency}} |
            {{shopCaption.RemainingDue}} : {{remainingAmount| Currency}}</div>
        </div>
      </section>
      <section class="member-item-drag-parent" cdkDropListGroup>
        <div class="member-list-section">
          <div class="sub-header">{{shopCaption.MemberSelectionPopup.lbl_subhdr}}</div>
          <div class="member-list-container" #memberList>
            <div class="member-content-wrap"
              *ngFor="let member of memberSelectionForm.get('memberSelectionArr')['controls'];let i = index;"
              formArrayName="memberSelectionArr" cdkDropList [cdkDropListConnectedTo]="dragDropConnectedList"
              [cdkDropListData]="popupInput?.members[i].associatedItems"
              (cdkDropListDropped)="drop($event,popupInput?.members[i], member)" id="member-sec-{{i}}">
              <div class="member-card"
                [class.disabled]="popupInput.members[i]?.disabled || (popupInput?.members[i]?.membershipStatus != 'ACTIVE' || popupInput?.members[i]?.cardStatus != 'ACTIVE')" >
                <div class="member-card-content-section">
                  <div class="d-flex">
                    <div class="checkbox" [formGroupName]="i">
                      <mat-checkbox class="base-check-box pr-1" labelPosition="before"
                        formControlName="memberSelection"
                        (change)="selectMember(popupInput?.members[i], $event, member)">
                      </mat-checkbox>
                    </div>
                    <div class="d-flex member-num">
                      <i class="icon-Member-new"></i>
                      <div *ngIf="!popupInput?.members[i]?.selectedFromSearch">{{i+1}}</div>
                    </div>
                    <div class="member-name">{{popupInput?.members[i]?.memberName}}</div>
                    <div class="delete-member" *ngIf="popupInput?.members[i]?.selectedFromSearch"
                      (click)="removeMember(popupInput?.members[i],i)">
                      <i class="icon-Delete"></i>
                    </div>
                  </div>
                  <div *ngIf="showAvailableRounds" class="available-round-lbl">{{shopCaption.MemberSelectionPopup.availableRounds}} :
                    <b>{{popupInput?.members[i]?.availableRounds}}</b>
                  </div>
                  <div>
                    <div class="guest-grp" *ngIf="availableGuestMember.length > 0">
                      <ng-container *ngFor="let guest of availableGuestMember">
                        <div class="d-flex guest-box" *ngIf="guest.playerId != popupInput?.members[i]?.playerId"
                          (click)="selectGuest(guest,popupInput.members[i], member)" [class.greenBox]="guest.selected&&guest.selectedMemberId == popupInput.members[i]?.memberCardNumber 
                          && guest.selectedPlayerId == popupInput.members[i]?.playerId">
                          <i class="icon-confirmed1" [class.selected]="guest.selected&&guest.selectedMemberId == popupInput.members[i]?.memberCardNumber
                            && guest.selectedPlayerId == popupInput.members[i]?.playerId"></i>
                          <i [ngClass]="guest.isMember?'icon-Member-new':'icon-userid'"></i>
                          <div [matTooltip]="guest.guestName">{{guest.guestName}}</div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="input-container d-flex">
                      <div class="input-section">
                        <mat-form-field [formGroupName]="i" [floatLabel]="floatLabel" class="input-field box_layout"
                          appearance="legacy">
                          <mat-label>{{shopCaption.MemberSelectionPopup.Amount}}</mat-label>
                          <input formControlName="memberAmount" autocomplete="off" matInput RetailCurrencyFormatter
                            (focus)="setMaxAmountValidator(member)" (input)="calculateRemaining()"
                            (blur)="checkValidator(member)" required>
                          <mat-error *ngIf="member['controls'].memberAmount.hasError('max')" class="member-amt-error">
                            {{shopCaption.MemberSelectionPopup.amountExceedOverallTotal}}</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="input-section" *ngIf="IsPinRequired">
                        <div [formGroupName]="i">
                          <mat-form-field [floatLabel]="floatLabel" class="input-field box_layout" appearance="legacy">
                            <mat-label>{{shopCaption.MemberSelectionPopup.Pin}}<sup class="span-error">*</sup>
                            </mat-label>
                            <input matInput #memberPinField autocomplete="off" type="password"
                              formControlName="memberPin" numMaxlength minlength="4" maxlength="4">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="popupInput?.members[i].associatedItems?.length > 1" class="remove-all"
                      (click)="removeAllItemsFromMember(popupInput?.members[i],member)">
                      {{shopCaption.MemberSelectionPopup.RemoveAll}}</div>
                    <div class="item-associated-list" *ngIf="popupInput?.members[i].associatedItems?.length > 0"
                      cdkDropList [cdkDropListData]="popupInput?.members[i].associatedItems"
                      [cdkDropListConnectedTo]="dragDropConnectedList"
                      (cdkDropListDropped)="drop($event,popupInput?.members[i], member)">
                      <ng-container *ngFor="let item of popupInput?.members[i].associatedItems;let itemInd = index;">
                        <div (cdkDragStarted)="onMemberItemDragStart($event,item,popupInput?.members[i],member)"
                          (cdkDragEnded)="onMemberItemDragEnd($event)" cdkDrag class="d-flex item-info">
                          <div class="item-name">{{item.ItemDescription}}</div>
                          <div class="item-price">{{item.SalesPrice | Currency}}</div>
                          <div (click)="removeItemFromMember(popupInput?.members[i],itemInd, item, member)">
                            <i class="icon-Cancel"></i>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item-list-section" *ngIf="retailItems?.length > 0">
          <div class="sub-header">{{shopCaption.MemberSelectionPopup.RetailItems}}</div>
          <div class="d-flex selection-btn"  [class.disabled]="popupInput?.allowAmountTender">
            <div class="select-cont" (click)="selectAllItems()">
              <div><i class="icon-confirmed" aria-hidden="true" [ngClass]="{'selected-icon': IsAllItemsSelected}"></i>
              </div>
              <div>{{shopCaption.MemberSelectionPopup.selectAll}}</div>
            </div>
            <div class="select-cont" (click)="deSelectAllItems()">
              <div><i class="icon-confirmed" aria-hidden="true" [ngClass]="{'selected-icon': !IsAllItemsSelected}"></i>
              </div>
              <div>{{shopCaption.MemberSelectionPopup.deselectAll}}</div>
            </div>
          </div>
          <section [class.disabled]="popupInput?.allowAmountTender"
            [cdkDropListDisabled]="popupInput?.allowAmountTender" id="retailItemsList" class="item-list"
            #retailItemsList="cdkDropList" cdkDropList [cdkDropListData]="retailItems"
            [ngClass]="isDragging? 'dragging-on':''">
            <div class="d-flex item-info" cdkDrag *ngFor="let item of retailItems;let i=index"
              (cdkDragStarted)="onDragStart($event, item)" (cdkDragEnded)="onDragDropped($event)"
              [ngClass]="item.selected && !isdragDisabled? 'clicked': ''" (click)="selectItems(item)">
              <div class="item-name">{{item.ItemDescription}}</div>
              <div class="item-price">{{item.SalesPrice | Currency}}</div>
            </div>
          </section>
        </div>
      </section>
    </form>
  </div>
  <mat-dialog-actions class="dialog-container__actions">
    <div>
      <button [disabled]="!enableSave" mat-button [ngClass]="{'whitecolor': enableSave}"
        class="ag_button--primary actions__save" appDisableDoubleClick (click)="pay()">{{shopCaption.Pay}}</button>
      <button mat-button class="actions__cancel" appDisableDoubleClick (click)="close()">{{shopCaption.cancel}}</button>
    </div>
  </mat-dialog-actions>
</div>