import { Injectable } from "@angular/core";
import { PrintManagerDataService } from "src/app/common/dataservices/printmanager.data.services";
import { PrintDocumentInput } from "src/app/common/Models/common.models";
import { PrintTemplates } from "src/app/common/shared/shared/service/print-templates";

@Injectable()
export class SaleChitService {
    constructor(private printManager:PrintManagerDataService) {
    }
    public printSaleChit(url : string,saleChitPrinter : PrintDocumentInput): void {
        this.printManager.Print(url,saleChitPrinter);
    }
}
