<div class="respos-mdl-ctr respos-box" [class.respos__hide-footer]="!showFooter">
    <div class="respos-mdl-ctr__header respos-box-fill3 respos-h4">
        <ng-content select=[title-icon]>
        </ng-content>
        {{title}}
        <span class="icon-respos-close respos-medium-icon ml-auto c-pointer" (click)="cancelClick()"></span>
    </div>
    <div class="respos-mdl-ctr__content">
        <ng-content select=[content]>        
        </ng-content>
    </div>
    <div class="respos-mdl-ctr__footer" *ngIf="showFooter">
        <button class="respos-btn respos-btn-basic" *ngIf="showCancel" (click)="cancelClick()">
            {{cancelLabel}}
        </button>
        <button class="respos-btn respos-btn-fill" *ngIf="showSubmit" (click)="submitClick()">
            {{saveLabel}}
        </button>
        <ng-content select="[footer]"></ng-content>
    </div>
</div>