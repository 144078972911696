import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { buttonTypes } from '@app/shared/constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { FullContactDTO } from '@models/InputContact';
import { SettingsDTO } from '@models/RestaurantDTO';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import _ from 'lodash';
import { ISubscription, Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddressComponent implements OnInit, AfterViewInit, OnDestroy {
  contactDetailsForm: UntypedFormGroup;
  config: FieldConfig[];
  selectedIndex: number;
  guestBookControls: string[];
  mobileRequired = false;
  countryCodes: { id: string; value: string; countryCode: string; }[];
  _settings: SettingsDTO = {} as SettingsDTO;
  addAddress: ButtonValue;
  deleteAddress: ButtonValue;
  addresses = [];
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  addressesFormGroup: UntypedFormGroup;
  testBool = false;
  countryOptions = [];
  
  subscriptions: Subscription = new Subscription();
  constructor(public fb: UntypedFormBuilder, private gbs: GuestBookService, private ps: PopupService,
    @Inject(COMPONENTINPUT) private data: FullContactDTO, private partyService: PartyService, public cs: CacheService) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
  }

  ngOnInit() {
    this.loadCountryOptions();
    this.config = [
      {
        type: 'input',
        name: 'id',
        class: 'contact-info__id'
      },
      {
        type: 'input',
        name: 'name',
        inputType: 'text',
        label: 'nameLabel',
        class: 'contact-info__country'
      },
      {
        type: 'input',
        name: 'addressLine1',
        inputType: 'text',
        label: 'address1Text',
        class: 'contact-info__address-line1'
      },
      {
        type: 'input',
        name: 'addressLine2',
        inputType: 'text',
        label: 'address2Text',
        class: 'contact-info__address-line2'
      },
      {
        type: 'input',
        name: 'zipcode',
        inputType: 'text',
        label: 'addressZipText',
        class: 'contact-info__pincode'
      },
      {
        type: 'input',
        name: 'city',
        inputType: 'text',
        label: 'addressCityText',
        class: 'contact-info__city'
      },
      {
        type: 'select',
        label: 'addressCountryText',
        name: 'country',
        placeholder: 'country',
        options: this.countryOptions,
        value : 0, 
        class: 'contact-info__country',
      },
    ];
    this.addressesFormGroup = this.fb.group({
      addresses: this.fb.array([])
    });
    if(this.gbs.guestForm.get('contactAddressDetailsForm')){
      this.gbs.guestForm.removeControl('contactAddressDetailsForm');
    }
    this.gbs.guestForm.addControl('contactAddressDetailsForm', this.addressesFormGroup);
    this.testBool = true
    this.addAddress = {
      type: buttonTypes.actionSecondarySmall,
      label: 'addAddress',
      customclass: 'multipaging__unselect-btn',
      disbaledproperity: true
    }
    this.deleteAddress = {
      type: buttonTypes.actionSecondarySmall,
      label: 'deleteAddress',
      customclass: 'multipaging__unselect-btn'
    }
  }

  ngAfterViewInit() {
    this.selectedIndex = this.gbs.tabsModal.tabs.findIndex(x => x.tabComponent === AddressComponent);
    this.subscriptions.add(this.partyService.tabChange$.subscribe((data) => {
      if (data === this.selectedIndex) {
        if (this.ps.tabsActionData?.length) {
          this.ps.tabsActionData[this.selectedIndex].gotoNextTab = true;
        }
        this.ps.tabsActions$.next(this.ps.tabsActionData);
      }
    }));
    if (this.data && this.data.ContactAddress?.length > 0) {
      this.data.ContactAddress.forEach(item => {
        //this.addresses.push('address' + (this.addresses.length + 1));
        this.addresses.push({id: this.addresses.length , config: _.cloneDeep(this.config)});
      });
      setTimeout(() => {
        this.setUpControls();
        this.setUpFormValidity();
        this.checkAndEnableAddAddress();
      }, 1);
    } else {
      this.addNewAddress(false);
    }
    if(this.ps.tabsActionData?.length){
      this.ps.tabsActionData[this.selectedIndex].gotoNextTab = this.data ? true : false;
    }
  }

  setUpFormValidity() {
    this.components.forEach((element, index) => {
      this.subscriptions.add(element.form.valueChanges.subscribe(val => {
        this.checkAndEnableAddAddress();
        if (element.form.dirty) {
          this.gbs.guestForm.get('contactAddressDetailsForm').markAsDirty();
          this.gbs.guestForm.markAsDirty();
        }
      }));
    });
  }

  setUpControls() {
    let addressesList = this.components.toArray();
    let addressFormGroup = this.gbs.guestForm.get('contactAddressDetailsForm') as UntypedFormGroup;
    let addressFormArray = addressFormGroup.controls.addresses as UntypedFormArray;
    addressFormArray.controls.length = 0;
    this.data.ContactAddress?.forEach((item, index) => {
      addressesList[index].form.patchValue({
        id: item.Id,
        name: item.Name,
        addressLine1: item.Street1,
        addressLine2: item.Street2,
        zipcode: item.PostalCode,
        city: item.City,
        country: this.gbs.getCountryId(item.Country)
      })

      addressFormArray.controls.push(addressesList[index].form);
    })
  }

  addNewAddress(checkFormValid) {
    //this.addresses.push('address' + (this.addresses.length + 1));
    if(checkFormValid && !this.isAllFormValid()){
      return;
    }
    this.addresses.push({id: this.addresses.length , config: _.cloneDeep(this.config)});
    setTimeout(() => {
      let addressFormGroup = this.gbs.guestForm.get('contactAddressDetailsForm') as UntypedFormGroup;
      let addressFormArray = addressFormGroup.controls.addresses as UntypedFormArray
      addressFormArray.controls.push(this.components.last.form);
      this.setUpFormValidity();
      this.checkAndEnableAddAddress();
    }, 1);
  }

  isAllFormValid():boolean{
    let isvalid = true;
    let formArray = 
    this.components.toArray().forEach(form=>{
      const contactAddressesdetail = form.value;
      if (!contactAddressesdetail.addressLine1  && !(contactAddressesdetail.addressLine1 && contactAddressesdetail.addressLine1.trim())
        && !contactAddressesdetail.addressLine2 && !(contactAddressesdetail.addressLine2 && contactAddressesdetail.addressLine2.trim())
        && !contactAddressesdetail.zipcode && !(contactAddressesdetail.zipcode && contactAddressesdetail.zipcode.trim())
        && !contactAddressesdetail.city && !(contactAddressesdetail.city && contactAddressesdetail.city.trim())
        && !(contactAddressesdetail.country && contactAddressesdetail.country > 0)){
        isvalid= false;
        return;
      }
    });
    return isvalid;
  }

  checkAndEnableAddAddress(){
    if(this.isAllFormValid()){
      this.addAddress.disbaledproperity = false;
    }
    else{
      this.addAddress.disbaledproperity = true;
    }
  }

  deleteContactAddress(index) {
    this.addresses.splice(index, 1);
    this.components.toArray().splice(index, 1);
    let addressFormGroup = this.gbs.guestForm.get('contactAddressDetailsForm') as UntypedFormGroup;
    let addressFormArray = addressFormGroup.controls.addresses as UntypedFormArray
    addressFormArray.controls.splice(index, 1);
    this.setUpFormValidity();
    if (this.data) {
      this.gbs.guestForm.get('contactAddressDetailsForm').markAsDirty();
      this.gbs.guestForm.markAsDirty();
    }
    setTimeout(() => {
      this.checkAndEnableAddAddress();
    }, 1);
  }

  loadCountryOptions(){
    this.countryOptions = [];
    this.countryOptions = [{id:0 , value: "None"}, ...this._settings.Countries.map(country=> {return { id: country.Id, value: country.Name}})];
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}