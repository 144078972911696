<div class="server-selection">

  <div class="server-selection__header">
    <span class="server-selection__searching">
      <dynamic-form [config]="searchConfig" #form="dynamicForm" >
      </dynamic-form>
    </span> 
    <span class="pop-up-label server-selection__available-server seat-header-two">{{'avilableServerText' | translate}}</span>
    <span class="error-message" *ngIf="showError">{{'maxServers' | translate}}</span>
  </div>
  <div class="server-selection__server-info ">
    <ng-scrollbar>
      <div  class="server-selection__server-container">
        <div class=" mt-4 mr-4 server-selection__server-div" *ngFor="let server of this.serverData;let i=index"
          [ngClass]="{'selected-border':selectedIndex == server.Id || selectedServerArray.indexOf(server.Id) != -1}" (click)="selectedServer(server)">
          <div class="float-left">
            <img src="assets/images/user_image.jpg" class="profile-image" alt="profile_pic">
          </div>
          <div class="float-left text-justify server-selection__server-name-view">
            <div class=" server-selection__server-name ">{{server.Name}}</div>
            <div *ngIf="server.StandaloneTables && server?.StandaloneTables?.length > 0" class="table-count pt-1">
              {{server?.StandaloneTables?.length}} {{ server?.StandaloneTables?.length === 1 ? ('popUpTable' | translate) : ('tablesMenuText' | translate) }}
            </div>
            <div *ngIf="server?.StandaloneTables && server?.StandaloneTables?.length == 0" class="table-count pt-1">{{'servercirclecomponentlabel3' | translate}}</div>
            <div  class="server-selection__server-seated">{{'seatedState' | translate}} :
              <span [style.background]="getBackGroundColorSeated(server.Duration)">
                {{server?.LastStatusUpdatedAt}}
              </span>
            </div>
            <div  class="server-selection__server-name">{{'load' | translate}} :
              <span [style.background]="getBackGroundColorLoad(server.Load)">
                {{server?.Load}}%
              </span>
            </div>
          </div>
          <svg *ngIf="selectedServerArray.indexOf(server.Id) != -1" class="checkmark server-selection__selected-tick" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>

        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>