<section class="post-type-setup" id="posttype">
  <div class="custom-retail-inputs page-header">
    <div class="d-flex flex-wrap align-items-baseline">
      <label class="page--header font-bold text-uppercase">{{captions.ResortFinancePostTypeMappingRevenuePostCodeDetails}}</label>
      <div class="d-flex pl-4">
        <mat-form-field [floatLabel]="floatLabel">
          <mat-select [attr.automationId]="'Dd_postTypeMapping_description'" (selectionChange)="postTypeChange($event)" [(value)]="selectedPostTypeValue">
            <mat-option [value]="option.id" *ngFor="let option of postTypeValues">
              {{option.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="ml-4" *ngIf="IsVersaFolioSameProperty">
        <app-button [buttontype]='proceedButton' (valueChange)='copyAllFromPostTypeMapping()'></app-button>
      </div>
      <div class="d-flex ml-auto width-class custSearchbox">
        <mat-form-field class="searchArea spa-searchbox align-self-end" [floatLabel]="floatLabelNever">
          <input [attr.automationId]="'Txt_postTypeMapping_search'" matInput autocomplete="off" class="search-input" [(ngModel)]="searchText"
            [ngModelOptions]="{standalone: true}" type="text" placeholder="{{searchPlaceholderValue}}"
            [maxlength]="maxInputLength" value="" (keypress)="searchValue($event)">
          <i aria-hidden="true"  [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor" (click)="resetValue()"></i>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="post-type-grid">
    <app-retail-table [attr.automationId]="'Tbl_postTypeMapping_postType'" [searchvalue]="searchText" [options]="tableoptions" class="h-100" [isViewOnly]="IsViewOnly"
      (DataClickEvtEmitter)="rowDataClicked($event)">
    </app-retail-table>
  </div>
</section>
<div mat-dialog-actions class="actions-div">
  <div class="pl-4">
    <button [attr.automationId]="'Btn_postTypeMapping_save'" [ngClass]="isValid ? 'button--primary-save' : 'button--disabled'" mat-button [disabled]="isDisabled"
      (click)="savePostTypeMappings($event)">{{commonCaptions.Save}}</button>
    <button [attr.automationId]="'Btn_postTypeMapping_cancel'" class="spa-button-cancel" mat-button (click)="cancelPostTypeMappings()">{{commonCaptions.Cancel}}</button>
  </div>
</div>
