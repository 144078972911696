<section class="retail-code-setup newVersionWrapper retail-discount-types h-100" id="discountType">
  <div class="retailGrid h-100">
    <button [attr.automationId]="'Btn_retailDiscountTypes_createDiscount'" [ngClass]="IsViewOnly ? 'button_invalidbgcolor button--disabled' : 'body-bgcolor whitecolor'"
        class="float-right mr-2 createNewBtn text-capital" mat-raised-button
        (click)="openDialog()" [disabled]="IsViewOnly">{{captions.CreateDiscount}}</button>
      <app-retail-table [attr.automationId]="'Tbl_retailDiscountTypes_retailDiscount'" [options]="tableoptions" (dragDropEvt)="dragDrop($event)"
           (deleteEvt)="DeleteRecords($event)" (addClick)="addDiscountType($event)" (editEvt)="EditRecords($event)"
          (toggleEvtEmitter)="sliderChange($event)" (doneEvtEmitter)="Done($event)"></app-retail-table>
  </div>
</section>
