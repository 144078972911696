import { LoginResultDTO } from '@app/shared/models/LoginResultDTO';
import { GuestBookService } from '@app/shared/services/guestbook.service';
import { RestaurantStateService } from '@app/shared/services/restaurant-state.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { ChangeAction, Menu, PartyType } from '@constants/commonenums';
import { ObjectChange, PropertyChange } from '@models/ChangeTrackingOperationResultDTO';
import { ContactDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import _ from 'lodash';
import { CacheService } from '../services/cache.service';
import { Processor } from './processor';

export class ContactChangeProcessor implements Processor {

  private static instance: ContactChangeProcessor;
  private constructor(public partyService: PartyService, public stateService : RestaurantStateService, public guestBookService : GuestBookService,
                      public cs: CacheService) {
  }

  public static Instance(ps: PartyService,rss: RestaurantStateService,gbs:GuestBookService, cs: CacheService): ContactChangeProcessor {
    if (!ContactChangeProcessor.instance) {
      ContactChangeProcessor.instance = new ContactChangeProcessor(ps,rss,gbs, cs);
    }

    return ContactChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: any, propertyId: number): void {
    let partiesList = this.partyService.partiesList;
    let standbyPartiesList = this.partyService.standbyPartiesList;
    if (propertyId !== Utilities.RestaurantId()) { 
      partiesList = this.cs.propertySettings.value[propertyId].reservations
      standbyPartiesList = this.cs.propertySettings.value[propertyId].StandbyParties
    }
    partiesList = [...partiesList, ...standbyPartiesList];
    if (objectChange) {
      if (objectChange.Action === ChangeAction.Created) {
        if (partiesList && partiesList.length > 0) {
          const parties = partiesList.filter((partyDetails) => {
            if (partyDetails.Contact) return partyDetails.Contact.Id === objectChange.ObjectId;
          });
          if (parties && parties.length > 0) {
            const contact = new ContactDTO();
            objectChange.PropertyChanges.forEach(property => {
              const isObject = _.isObject(contact[property.PropertyName.replace('Internal', '')]);
              if (property.PropertyName === 'CachedNotesInternal') {
                contact.Notes = JSON.parse(property.Value);
              }
              if (Object.getOwnPropertyNames(contact).includes(property.PropertyName.replace('Internal', ''))) {
                contact[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
              }
            });
            parties.forEach(party => party.Contact = {...party.Contact, ...contact});
            this.processContactChangeForPartiesInState(objectChange, contact);
          }
        }
      }
      if (objectChange.Action === ChangeAction.Updated) {
        if (partiesList && partiesList.length > 0) {
          const parties = partiesList.filter((partyDetails) => {
            if (partyDetails.Contact) return partyDetails.Contact.Id === objectChange.ObjectId;
          });
          if (parties && parties.length > 0 && additionaldata) {
            parties.forEach(party => {
              if (additionaldata.VisitStats && additionaldata.VisitStats.length > 0) {
                party.Contact = {...party.Contact, ...additionaldata} as ContactDTO;
              } else {
                if (_.get(party, 'Contact.VisitStats', [])?.length) {
                  let visitStats = party.Contact.VisitStats;
                  let additionalContactData = additionaldata.find(data => data.Id === objectChange.ObjectId);
                  if (additionalContactData) {
                    party.Contact = {...party.Contact, ...additionalContactData} as ContactDTO;
                  }
                  party.Contact.VisitStats = visitStats;
                } else {
                  let additionalContactData = additionaldata.find(data => data.Id === objectChange.ObjectId);
                  if (additionalContactData) {
                    parties.forEach(party => party.Contact = {...party.Contact, ...additionalContactData} as ContactDTO);
                  }
                }
              }
            });
          }
          if (parties && parties.length > 0) {
            parties.forEach(party => {
              objectChange.PropertyChanges.forEach(property => {
                const isObject = _.isObject(party.Contact[property.PropertyName.replace('Internal', '')]);
                if (Object.getOwnPropertyNames(party.Contact).includes(property.PropertyName.replace('Internal', ''))) {
                  party.Contact[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
                }
              });
            });
          }
          if (propertyId == Utilities.RestaurantId()) {
            let partyWhichContactChanged = parties && parties.length > 0 && this.partyService.selectedParty$.value && this.guestBookService._as.selectedMenuId != Menu.Tables ? parties.filter(p => p.Id == this.partyService.selectedParty$.value.Id)[0] : null;
            if (partyWhichContactChanged) {
              this.partyService.selectedParty$.value.Contact = { ...this.partyService.selectedParty$.value.Contact, ...partyWhichContactChanged.Contact }
              this.partyService.selectedParty$.next(this.partyService.selectedParty$.value);
            }
          }
        }
        let contact = this.partyService.contacts$.value;
        if (contact && objectChange && contact.Id === objectChange.ObjectId) {
          this.mapAdditionalProperties(additionaldata, contact);
          objectChange.PropertyChanges.forEach(property => {
            const isObject = _.isObject(contact[property.PropertyName.replace('Internal', '')]);
            this.mapMissedProperties(property, contact);
            if (Object.getOwnPropertyNames(contact).includes(property.PropertyName.replace('Internal', ''))) {
              contact[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
            }
          });
          this.partyService.contacts$.next(contact);
        }
        const _contact = this.partyService.contactList$.value.filter((contacts) => {
          if (contacts) return contacts.Id === objectChange.ObjectId;
        });
          if (_contact && _contact.length > 0) {
            objectChange.PropertyChanges.forEach(property => {
              const isObject = _.isObject(_contact[0][property.PropertyName.replace('Internal', '')]);
              if (Object.getOwnPropertyNames(_contact[0]).includes(property.PropertyName.replace('Internal', ''))) {
                _contact[0][property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
              }
            });
            this.guestBookService.allAvailableContacts.next(this.partyService.contactList$.value);
          }
       this.processContactChangeForPartiesInState(objectChange, additionaldata);
      }
      if (objectChange.Action === ChangeAction.Removed && partiesList.length > 0) {
      }
    }
    const partyList = _.uniqBy(_.cloneDeep(partiesList.filter(x => x.Type !== PartyType.StandBy)), 'Id');
    const standbyParties = _.uniqBy(_.cloneDeep(partiesList.filter(x => x.Type === PartyType.StandBy)), 'Id');
    if (propertyId == Utilities.RestaurantId()) { 
      this.partyService.partiesList = partyList;
      this.partyService.standbyPartiesList = [...standbyParties];
      this.cs.propertySettings.value[propertyId].reservations = partyList;
      this.cs.propertySettings.value[propertyId].StandbyParties = [...standbyParties];
      this.partyService.StandbyParties$.next([...standbyParties]);
    } else {
      this.cs.propertySettings.value[propertyId].reservations = partyList;
      this.cs.propertySettings.value[propertyId].StandbyParties = [...standbyParties];
    }
  }
  mapAdditionalProperties(additionaldata: object, contact: any) {
    for (let property in additionaldata){
      if(property == 'CountryId'){
        contact.CountryId = additionaldata[property];
      }
      if(property == 'CountryId2'){
        contact.CountryId2 = additionaldata[property];
      }
      if(property == 'EmailAddress'){
        contact.EmailAddress = additionaldata[property];
      }
      if(property == 'EmailAddress2'){
        contact.EmailAddress2 = additionaldata[property];
      }
      if(property == 'PhoneNumber'){
        contact.PhoneNumber = additionaldata[property];
      }
      if(property == 'PhoneNumber2'){
        contact.PhoneNumber2 = additionaldata[property];
      }
      if(property.includes('Notes')){
        if(additionaldata[property] && additionaldata[property].length > 0)
          contact.Notes = additionaldata[property];
      }
    }
  }
  mapMissedProperties(property: PropertyChange, contactDetails: any) {
    if (property.PropertyName.includes('Internal')) {
      property.PropertyName = property.PropertyName.replace('Internal', '');
    }
    if (property.PropertyName === 'Birthday') {
      contactDetails.Birthday = property.Value;
    }
    if (property.PropertyName === 'Anniversary') {
      contactDetails.Anniversary = property.Value;
    }
    if (property.PropertyName === 'CountryId') {
      contactDetails.CountryId = property.Value;
    }
    if (property.PropertyName === 'CountryId2') {
      contactDetails.CountryId2 = property.Value;
    }
    if (property.PropertyName === 'EmailAddress') {
      contactDetails.EmailAddress = property.Value;
    }
    if (property.PropertyName === 'EmailAddress2') {
      contactDetails.EmailAddress2 = property.Value;
    }
    if (property.PropertyName === 'PhoneNumber') {
      contactDetails.PhoneNumber = property.Value;
    }
    if (property.PropertyName === 'PhoneNumber2') {
      contactDetails.PhoneNumber2 = property.Value; //CachedNotesInternal
    }
  }

  processContactChangeForPartiesInState(change:ObjectChange, additionaldata){
    let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    loginResult.RestaurantsAvailableForLogin.forEach(property => {
      this.stateService.processContactChange(change, additionaldata, property.Id);
    })
  }
}
