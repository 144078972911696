import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '@dynamicform/models/error-matcher';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';
import { DynamicFormService } from '@dynamicform/service/dynamic-form.service';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['form-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormInputComponent implements Field, OnInit, AfterViewInit {
  // @Output() valueChange EventEmitter<any> = new EventEmitter<any>()
  constructor(private dfs: DynamicFormService, private cdf: ChangeDetectorRef) { }
  matcher = new MyErrorStateMatcher();
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  public FormControlName;
  config: FieldConfig;
  group: UntypedFormGroup;
  onlyNumber: any;
  numberDivisibleOfFive: any;
  digitOnly: any;
  numbersDotOnly: any;
  decimalNumbersOnly: any;
  isPhoneNumber = false;
  isAlphaNumeric: any;
  isNewPhoneNumber: boolean;
  prefix: string;

  inputEmiter() {
    /*  */
    this.valueChange.emit('test');
  }

  ngOnInit() {
    this.FormControlName = this.config.name;
    this.onlyNumber = this.config.directive && this.config.directive === 'numberOnly';
    this.digitOnly = this.config.directive && this.config.directive === 'digitOnly';
    this.numbersDotOnly = this.config.directive && this.config.directive === 'numbersDotOnly';
    this.decimalNumbersOnly =this.config.directive && this.config.directive === 'DecimalNumbersOnly';
    this.isPhoneNumber = this.config.isPhoneNumber ? this.config.isPhoneNumber : false;
    this.isAlphaNumeric = this.config.directive && this.config.directive === 'alphaNumeric';
    this.numberDivisibleOfFive = this.config.directive && this.config.directive === 'numberDivisibleOfFive';
    this.prefix = this.config.prefix || null;
    if (this.config.isConciergeNumber) {
      this.isNewPhoneNumber = this.config.isNewPhoneNumber;
    }
  }

  ngAfterViewInit() {
    this.cdf.detectChanges();
  }

  onKeyup(event) {
    if(this.FormControlName == 'UserName' && event.which != 32 ) {
      this.group.controls[this.FormControlName].setValue(event.target.value);
    }
    else if(this.FormControlName == 'UserName' && event.which == 32){
      this.group.controls[this.FormControlName].setValue(event.target.value.trim(''));
    }
    if (this.config.blurClick) {
      this.config.blurClick(event);
    }
  }
 
  iconClick(event, ctrlName?) {
    if (this.config.cellClick) {
      this.config.cellClick(event, ctrlName);
    }
  }

  addNewPhoneNumber(action,index) {
    if (this.config.returnFunction) {
      this.config.returnFunction(action,index);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  onFocusOut(event, ctrlName?) {
    if (this.config.onfocusOutClick) {
      this.config.onfocusOutClick(event, ctrlName);
    }
  }
}
