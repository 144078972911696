import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { ExceptionPopupComponent } from '@components/exception-popup/exception-popup.component';
import { ComponentTypes, OperationResultState, ValidationMessageType } from '@constants/commonenums';
import { popupDialogDimension } from '@constants/globalConstants';
import { OperationResultDTO } from '@models/ChangeTrackingOperationResultDTO';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { UserPermissionFunctions } from '@utilities/user-permission-functions';
import { Utilities } from '@utilities/utilities';
import { Subscription } from 'rxjs';
import { CacheService } from '@core/services/cache.service';
import { UserWarningActions } from './userWarningAction';

@Injectable()
export class ResultProcesserFunction extends Utilities implements OnDestroy {
  confirmationSubscription: Subscription;
  subscriptions: Subscription = new Subscription();
  ComponentType: ComponentTypes;
  IgnoreValidationErrors = false;
  IgnoreAllErrors = false;

  constructor(dialog: MatDialog, public appService: AppService, translateService: TranslateService,
    public popupService: PopupService, @Inject(UserPermissionFunctions) public userPermission: UserPermissionFunctions,@Inject(UserWarningActions) public userAction :  UserWarningActions, public ps: PartyService, public cs: CacheService) {
    super(dialog, translateService);
  }



  PayloadProcesser(result: OperationResultDTO, state? , handleWarning:boolean = false) {

    switch (result.State) {
      case OperationResultState.Success:
        this.OperationSucceeded(result, state);
        break;

      case OperationResultState.ServerException:
        this.OnOperationCompletedWithServerError(result, state);
        break;

      case OperationResultState.ValidationErrors:
        this.OnOperationCompletedWithValidationErrors(result, state);
        break;

      case OperationResultState.InsufficientPermissions:
        this.OnOperationCompletedWithInsufficientPermissions(result, state);
        break;

      case OperationResultState.AccessDenied:
        this.OnOperationCompletedWithAccessDenied(result, state);
        break;

      case OperationResultState.ConsentMessages:
        if(handleWarning){
          this.OnOperationCompletedWithWarningAction(result, state);
        }else{
          this.OperationSucceeded(result, state);
        }
        break;

      case OperationResultState.Logout:
        this.Logout();
        break;
    }

    if (!(result.State == OperationResultState.InsufficientPermissions)) {
      return this.HandleError(result , handleWarning);
    }
    else {
      return false;
    }
  }



  OperationSucceeded(result: OperationResultDTO, state?) {

  }
  OnOperationCompletedWithServerError(result: OperationResultDTO, state?) {
    this.ShowNotification(result);
  }
  OnOperationCompletedWithValidationErrors(result: OperationResultDTO, state?) {
    this.ShowNotification(result);
  }
  OnOperationCompletedWithInsufficientPermissions(result: OperationResultDTO, state?) {
    if (Utilities.AnyVenue(this.cs.settings.value.PropertyType)) {
      this.userPermission.ShowUserPermissionpopup(result);
    }
    if (Utilities.IsActivitiesVenues(this.cs.settings.value.PropertyType)) {
      this.ShowNotification(result, true);
    }
  }
  OnOperationCompletedWithWarningAction(result: OperationResultDTO, state?){
    this.userAction.ShowUserActionPopup(result);
  }
  OnOperationCompletedWithAccessDenied(result: OperationResultDTO, state?) {

    this.ShowNotification(result, true);
  }


  Logout() {
    localStorage.removeItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`);
    sessionStorage.removeItem(`MerchantKey${Utilities.getSessionStorageType()}`)
    Utilities._restaurantId = null;
  }

  ShowNotification(response, AccessDenied?: boolean, serverDown?: boolean) {
    const ErrorMessages = this.getErrorMessage(response, AccessDenied, serverDown);
    if (ErrorMessages) {
      const componentInfo = Utilities.setComponentDetails(ExceptionPopupComponent, 'small', 'action', ErrorMessages,
        ErrorMessages[0].dialogTitle);
      const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.resultprocess,
        popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, false, '', '', '', false);
      this.confirmationSubscription = this.popupService.confirmedAction$.subscribe(() => {
        dialogRef.close();
      });
      this.subscriptions.add(dialogRef.afterClosed().subscribe((dialogCloseData) => {
        if (this.confirmationSubscription) { this.confirmationSubscription.unsubscribe(); }
      }));
    }
  }

  public getErrorMessage(data: any, AccessDenied: boolean, serverDown?: boolean) {
    const Erros: any = [];
    let ErrosMessages: any;
    let errorTitle = this.translateService.instant('errorTitle');
    let warningTitle = this.translateService.instant('warningTitle');
    if (!AccessDenied) {
      if (data.ExceptionMessage) {
        ErrosMessages = { ErrorMessage: data.ExceptionMessage };
        Erros.push({ ErrorDetails: ErrosMessages, dialogTitle: errorTitle, ErrorType: true });
      }
      else if (data.ValidationMessages && data.ValidationMessages.length > 0) {
        let errorMsg = [];
        let warningMsg = [];
        data.ValidationMessages.forEach(element => {
          if (element.Type == ValidationMessageType.Error) {
            errorMsg.push({ msg: element.Message });
          }
          // else {
          //   warningMsg.push({ msg: element.Message });
          // }
        });
        ErrosMessages = { ErrorMessage: this.translateService.instant('validationMessages'), Error: errorMsg, Warning: warningMsg };
        Erros.push({ ErrorDetails: ErrosMessages, dialogTitle: errorMsg && errorMsg.length > 0 ? errorTitle : warningTitle, ErrorType: errorMsg.length > 0 ? true : false });
      }
      else if (data.RequiredPermissions || data.MissingPermissions) {
        let missingPermissionmessage = this.translateService.instant('missingPermission');
        ErrosMessages = { ErrorMessage: missingPermissionmessage };
        Erros.push({ ErrorDetails: ErrosMessages, dialogTitle: errorTitle, ErrorType: true });
      }
      else {
        let commonmsg;
        if (serverDown) {
          commonmsg = this.translateService.instant('serverDown');
        } else {
          commonmsg = this.translateService.instant('commonErrorMsg');
          if (!navigator.onLine) {
            commonmsg = this.translateService.instant('internetconnection');
          }
        }
        if (this.cs && (this.cs.IGServerCallInOpenCheck || this.cs.IGServerCallInMoveCheck)) {
          return (this.cs.IGServerCallInOpenCheck) ? this.cs.HandleResponse(this.translateService.instant('OpenCheckErrorMsg')) : this.cs.HandleResponse(this.translateService.instant('MoveCheckErrorMsg'));
        }

        if (data?.status == 404) {
          commonmsg = this.translateService.instant('ERROR404');
        } else if (data?.status == 401) {
          commonmsg = this.translateService.instant('ERROR401');
        }
        else if (data?.status == 500) {
          commonmsg = this.translateService.instant('ERROR500');
        }
        else if (data?.status == 503) {
          commonmsg = this.translateService.instant('ERROR503');
        }
        /* if(data.message)
         {
           commonmsg=data.message;
         }else{
           if(data)
           {
             commonmsg=data;
           }
         } */
        ErrosMessages = { ErrorMessage: commonmsg };
        Erros.push({ ErrorDetails: ErrosMessages, dialogTitle: errorTitle, ErrorType: true });
      }
    } else {
      let accessDeniedmessage = this.translateService.instant('accessDenied');
      ErrosMessages = { ErrorMessage: accessDeniedmessage };
      Erros.push({ ErrorDetails: ErrosMessages, dialogTitle: errorTitle, ErrorType: true });
    }
    return Erros;
  }

  HandleError(result , handleWarning) {
    this.userPermission.failedAttempts = 0;
    if (this.IgnoreAllErrors || (result && result.ValidationErrors && result.State == OperationResultState.ValidationErrors && this.IgnoreValidationErrors)  || ((result?.State == OperationResultState.ConsentMessages && result.ValidationMessages?.length ) && !handleWarning) ) {
      return true;
    }
    return false;
  }

  OnOperationCompleted() {
    this.userPermission.failedAttempts = 0;
  }
  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
