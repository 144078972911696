import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailReportControl } from '../../../basereport/base-report.component';
@Component({
  selector: 'app-retail-correct-void',
  templateUrl: './correct-void.component.html',
  styleUrls: ['./correct-void.component.scss']
})
export class RetailCorrectVoidComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  CorrectVoidFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  @Input() outlets: any;
  selectedDropdownType = '';
  valuesSelected: any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  users: DropDownData[];
  inActiveText = '';
  editPatchValue : any;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, private dataService: ReportDataService
    ,private container: ViewContainerRef) {
    this.inActiveText = this.captions.IncludeInactiveUsers;
   }

  ngOnInit() {
  this.CorrectVoidFormGrp = this.fb.group({
    users: new UntypedFormControl([]),
    includeInactiveusers: false
  });
    this.formReady.emit(this.CorrectVoidFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit) {  
        let patchItem =  this.editPatchValue;

        if(patchItem)
        {
          this.CorrectVoidFormGrp.controls['includeInactiveusers'].patchValue(patchItem.includeInactiveusers);         
    
        if(patchItem.UserIds != undefined){
          this.dataService.GetAllUsersByPropertyId().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.UserIds.find(x => x == item.id);
            });
            this.users = arr;     
          });
        }
      } 
        }
    } 
  }

  private async onLoad() {
    this.users = await this.dataService.GetAllUsersByPropertyId();
    this.users = this.business.toggleIncludeInactive(false,this.users);
    this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown);
  }

  toggleIncludeInactive(IncludeInactiveToo: boolean) {
    this.users = this.business.toggleIncludeInactive(IncludeInactiveToo,this.users);
    this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown);
    this.CorrectVoidFormGrp.controls['users'].setValue([this.users]);
  }
 

  getFormControlValue(event) {
    if (event[0] === 'users') {
    this.CorrectVoidFormGrp.setControl('users', event[1]);
    }
  }
}
