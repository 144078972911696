<section>
  <div>
    <app-gift-cards-search [attr.automationId]="'Srch_shopGiftCardPaymentSection_handleGiftCard'" [screenType]="SearchScreen.OrderSummary" [outletId]="currentOutletId"
      [inputCardNumber]="cardNumberReset" [isThirdPartyGiftcard]="isThirdPartyGiftcard" [isRefund]="isRefund"
      (giftCardSearchResult)="HandleGiftCardData($event)">
    </app-gift-cards-search>
    <ng-content></ng-content>
  </div>
  <mat-radio-group [attr.automationId]="'Rdo_shopGiftCardPaymentSection_giftcardData'" class="golf__radio" aria-labelledby="example-radio-group-label" [(ngModel)]="GiftCardSelectedData"
    [ngModelOptions]="{standalone: true}">
    <div *ngFor="let giftcard of giftCardsSearchedData;let i = index;"
      [class.disabled]="giftcard.amount == 0 && !isRefund" class="gift-card-transaction">
      <ng-container *ngIf="!giftcard.isSettled">
        <mat-radio-button [value]="giftcard.cardNumber" (click)="SelectGiftCard(giftcard)"
          class="golf--form-radio-button gift-card-radio">
        </mat-radio-button>
        <div class="gift-card-details-section">
          <div class="gift-card-details">
            <div class="LWB14 textellipsis mb-2">{{giftcard.maskedCardNumber}}</div>
            <ng-container *ngIf="giftcard?.isCardActive">
              <div class="mt3 d-flex">
                <span class="w-50 gift-card-details-data textellipsis" [matTooltip]="captions.Name">{{captions.Name}}</span>
                <span class="w-50 gift-card-details-data textellipsis"
                  [matTooltip]="giftcard.firstName+' '+giftcard.lastName">{{giftcard.firstName}}
                  {{giftcard.lastName}}</span>
              </div>
            
              <div class="mt3 d-flex" *ngIf="!isAgilysysGiftcard">
                <span class="w-50 gift-card-details-data textellipsis"
                  [matTooltip]="captions.ExpiryDate">{{captions.ExpiryDate}}</span>
                <span class="w-50 gift-card-details-data textellipsis"
                  [matTooltip]="giftcard.locExpiryDate">{{giftcard.locExpiryDate}}</span>
              </div>
            </ng-container>

            <div class="mt3 d-flex">
              <span class="w-50 gift-card-details-data textellipsis"
                [matTooltip]="captions.Vendor">{{captions.Vendor}}</span>
              <span class="w-50 gift-card-details-data textellipsis"
                [matTooltip]="giftcard.vendor">{{giftcard.vendor}}</span>
            </div>

          </div>
          <div class="gift-card-payment-details">
            <div class="gift-card-payment-details-inner">
              <div class="LWB16">{{giftcard.amount | Currency}}</div>
              <div class="LW12 mt-1">{{captions.AvailableAmount}}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-radio-group>
</section>