import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {  Host } from '../shared/globalsContant';
import { RetailLocalization } from '../common/localization/retail-localization';
import { HttpMethod, HttpServiceCall } from '../shared/service/http-call.service';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { BaseResponse, Vendor, VendorType, VendorUI, VendorContactInformation, Phone, Email, Vendors, VendorsAPI } from './retail-vendor-setup.modals';
import { ContactType } from '../shared/shared.modal';

@Injectable({
  providedIn: 'root'
})
export class VendorSetupService {

  constructor(private dialog: MatDialog, private localization: RetailLocalization,
              private http: HttpServiceCall, private utils: RetailUtilities) { }


  public async createVendor(body: VendorUI, listOrder: number): Promise<any> {
    try {
      let apiModel = this.MapToApiVendor(body, listOrder);
      return await this.InvokeHttpCalls<VendorsAPI[]>(Host.retailManagement, "CreateVendor", HttpMethod.Post, apiModel);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async updateVendor(body: VendorUI, listOrder: number): Promise<any> {
    try {
      let apiModel = this.MapToApiVendor(body, listOrder);
      return await this.InvokeHttpCalls<VendorsAPI[]>(Host.retailManagement, "UpdateVendor", HttpMethod.Put, apiModel);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  public async deleteVendor(id: number): Promise<any> {
    try {

      return await this.InvokeHttpCalls<VendorsAPI[]>(Host.retailManagement, "DeleteVendor", HttpMethod.Delete, undefined, { id: id });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async getAllVendors(): Promise<Vendors[]> {
    try {
      const vendors = await this.InvokeHttpCalls<VendorsAPI[]>(Host.retailManagement, "GetVendors", HttpMethod.Get);
      return this.mapToGridUI(vendors);
    } catch (e) {
      this.http.exceptionHandle(e);
      }
    }

  public async getNextVendorCode(): Promise<string> {
    try {
      return await this.InvokeHttpCalls<string>(Host.retailManagement, "GetVendorCode", HttpMethod.Get, undefined, { id: 0 });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async getAllVendorTypes(): Promise<VendorType[]> {
    try {
      return await this.InvokeHttpCalls<VendorType[]>(Host.retailManagement, "GetVendorTypes", HttpMethod.Get);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public mapToGridUI(vendorAPI: VendorsAPI[]) {
    const vendorDataUI: Vendors[] = vendorAPI.map(c => {
      return {
        id: c.id,
        vendorRepresentative: `${c.representativeFname} ${c.representativeLname}`,
        active: c.isActive,
        vendorType: c.vendorTypeId,
        vendorCode: c.code,
        vendorName: c.name,
        noOfItemsAssgined: c.noOfItemsAssgined,
        primaryphone: this.getPrimaryDetail(c.contactInformation),
        listOrder: c.listOrder,
        comments: c.comments,
        postal_code: c.postalCode,
        city: c.city,
        country: c.country,
        state: c.state,
        lastName: c.representativeLname,
        firstName: c.representativeFname,
        terms: c.terms,
        accountPayable: c.accountPayableNumber,
        address: this.MapAddressDetail(c),
        email: this.MapEmailInfo(c.contactInformation),
        phone: this.MapPhoneInfo(c.contactInformation),
        vendorsAssignedItem : c.vendorsAssignedItem,
      };
    });
    return vendorDataUI.sort( x => x.listOrder);
  }

  //UI mapper
  public MapAddressDetail(model: Vendor) {
    const address = [];
    if (model.addressLine1) {
      address.push({  addressDetails : model.addressLine1});
    }
    if (model.addressLine2) {
      address.push({  addressDetails : model.addressLine2});
    }
    if (model.addressLine3) {
      address.push({  addressDetails : model.addressLine3});
    }
    return address;
  }

  public getPrimaryDetail(contacts: VendorContactInformation[]): string {
    const Type = [1 , 2 , 3];
    contacts = contacts.filter(x => Type.includes(x.contactType));
    const Index = contacts.filter(x => Type.includes(x.contactType)).findIndex(x => x.isPrimary);
    if (Index !== -1) {
        return this.utils.viewPhoneNumberFormatWithPipe(contacts[Index].value);
      }
      else {
        return contacts && contacts.length > 0 ? this.utils.viewPhoneNumberFormatWithPipe(contacts[0].value) : '' ;
      }
  }

  MapEmailInfo(contacts: VendorContactInformation[]): Email[]{
    const emailType = [9, 10];
    const email: Email[] = [];
    const contactList = contacts.filter( c => emailType.includes(c.contactType));
    contactList.forEach(x => {
        email.push({
          id : x.id,
          contactType: ContactType.email,
          emailId: x.value,
          emailType: x.contactType,
          isSynced: false,
          primaryEmail: x.isPrimary,
          privateInformtionEmail: x.isPrivateInfo,
      });
    });
    return email;
  }

  MapPhoneInfo(contacts: VendorContactInformation[]): Phone[]{
    const phoneType = [1, 2, 3];
    const phone : Phone[] = [];
    const contactList = contacts.filter( c => phoneType.includes(c.contactType) );
    contactList.forEach(x => {
      phone.push({
        privateInformtionPhone: x.isPrivateInfo,
        primaryPhone: x.isPrimary,
        phoneType: x.contactType,
        phoneNumber: this.utils.getPhoneNumberFromValue(x.contactType,x.value),
        isSynced: false,
        extension: this.utils.getExtensionFromValue(x.contactType, x.value),
        countryCode: this.utils.getCountryCodeFromValue(x.contactType, x.value),
        contactType: ContactType.phone,
        id: x.id
     });
    });
    return phone;
    }

  //API Mapper
   private MapToApiVendor(model: VendorUI, listOrder: number) : Vendor{
    return {
      id: model.id ? model.id : 0,
      code : model.vendorCode,
      name : model.vendorName ,
      isActive : model.active,
      vendorTypeId : model.vendorType,
      terms : Number(model.terms),
      listOrder : listOrder,
      accountPayableNumber  : model.accountPayable,
      representativeFname : model.firstName,
      representativeLname : model.lastName,
      comments : model.comments,
      addressLine1  : model.address && model.address.length > 0 ? model.address[0].addressDetails : "",
      addressLine2 : model.address && model.address.length > 1  ? model.address[1].addressDetails : "",
      addressLine3 : model.address && model.address.length > 2 ? model.address[2].addressDetails : "",
      city : model.city,
      state : model.state,
      country : model.country,
      postalCode : model.postal_code,
      contactInformation: this.MapContactInformation(model)
    } as Vendor;
   }

   MapContactInformation(model: VendorUI): VendorContactInformation[]
   {
   const contactList: VendorContactInformation[] = [];
   if (model.phone) {
      model.phone.forEach(x => {
        if (x.phoneType && x.phoneNumber) {
          contactList.push({
            id: x.id ? x.id : 0,
            vendorId: model.id ? model.id : 0,
            value: this.utils.buildPhoneNumber(x.phoneType, x.extension, x.phoneNumber, x.countryCode),
            isPrimary: x.primaryPhone ? x.primaryPhone : false,
            isPrivateInfo: x.privateInformtionPhone ? x.privateInformtionPhone : false,
            contactType: x.phoneType
          });
        }
      });
    }

   if (model.email) {
      model.email.forEach(x => {
        if (x.emailType && x.emailId) {
          contactList.push({
            id: x.id ? x.id : 0,
            vendorId: model.id ? model.id : 0,
            value: x.emailId,
            contactType: x.emailType,
            isPrimary: x.primaryEmail ? x.primaryEmail : false,
            isPrivateInfo: x.privateInformtionEmail ? x.privateInformtionEmail : false
          });
        }
      });
    }
   return contactList;
   }

    private async InvokeHttpCalls<T>(host: Host, callDesc: string, callType: HttpMethod, body?: Vendor, uRIParams?: any): Promise<T> {
      try
      {
          const response: BaseResponse<T> = await this.http.CallApiAsync<T>({
              callDesc: callDesc,
              host: host,
              method: callType,
              body: body,
              uriParams: uRIParams
          });

          return response.result;
      }
      catch (ex) {

      }
  }

}
