import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { formTypes } from '../../enums/shared-enums';
import { TableSearchHeader, ButtonValue, AgToggleConfig, AgDateConfig, SplitMoreAction } from '../../Models/ag-models';
import { Localization } from "src/app/common/localization/localization";

@Component({
  selector: 'app-table-search-header',
  templateUrl: './table-search-header.component.html',
  styleUrls: ['./table-search-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TableSearchHeaderComponent {
  captions: any = this.localization.captions;
  actionButton: ButtonValue;
  actionAllButton: ButtonValue;
  hideAdvaceSearch: boolean;
  searchForm: UntypedFormGroup;
  headerOption: TableSearchHeader;
  switchInput: AgToggleConfig;
  simplesearchInput: string;
  fromModule: boolean = false;
  IsRadioSearch: boolean = false;
  isClearSearchText: boolean = true;
  @Input() noResizeSimplesearch: boolean;
  @Input() menuActions: SplitMoreAction[];
  @Input() menuButtonActions: SplitMoreAction[];
  @Output() searchChange = new EventEmitter();
  @Output() showAdvancedSearchChange = new EventEmitter();
  @Output() showInactive = new EventEmitter();
  @Output() createType = new EventEmitter();
  @Output() createActionType = new EventEmitter();
  @Output() advanceSearchAccountEmit = new EventEmitter();
  @Output() advanceSearchContactEmit = new EventEmitter();
  @Output() searchButtonClicked = new EventEmitter();
  @Output() includeHistoryChecked = new EventEmitter();
  @Input() isEditMode: boolean;
  @Input() showAdvancedSearch: boolean;
  @Input() showLinks: boolean;
  @Input() showSearchDates: boolean;
  @Input() searchStartDateInput: AgDateConfig;
  @Input() searchEndDateInput: AgDateConfig;
  @Input() showsearchDatesButton: boolean;
  @Input() searchDatesButton: ButtonValue;
  @Output() searchDateChange = new EventEmitter();
  @Output() simpleDateSearch = new EventEmitter();
  @Output() printActionEmit = new EventEmitter();
  @Output() searchSelectionChange = new EventEmitter();

  @Output() linksClickEmit = new EventEmitter();
  fromType: boolean = false;
  toType: boolean = false;
  radioselectiondataobj: any;
  includeHistory : boolean = false;
  bookingSearch : boolean = false;
  filterStatus : boolean = false;
  automationId : string;
  minSearchTextChar: number;
  @Input('isClearSearchText')
  set clearSearchTextField(value: boolean) {
    this.isClearSearchText = false;
    if (value) {
      this.isClearSearchText = true;
      this.clearSearchText();
    }
  }

  @Input('searchText')
  set searchText(value: string) {
    this.simplesearchInput = value;
  }

  @Input('fromModule')
  set fromModuleValue(value: boolean) {
    this.fromModule = value;
  }

  @Input('fromAccountContactModule')
  set fromAccountContactModuleValue(value) {
    if (value == formTypes.fromType) {
      this.fromType = true;
    }
    else {
      this.toType = true;
    }
  }

  @Input('searchHeaderOption')
  set setBtnText(value) {
    if (value) {
      this.headerOption = value;
      this.actionButton = {
        type: this.headerOption.buttonType ? this.headerOption.buttonType : 'primary',
        label: this.headerOption.createBtnLabel,
        disabledproperty: this.headerOption.createBtnDisabled
      };
      this.actionAllButton = {
        type: this.headerOption.buttonType ? this.headerOption.buttonType : 'primary',
        label: this.headerOption.beforeBtnLabel,
        disabledproperty: this.headerOption.beforeButtonDisabled
      };
      this.automationId = value.automationId ? value.automationId : '';
      this.hideAdvaceSearch = this.headerOption.hideAdvanceSearch ? this.headerOption.hideAdvanceSearch : false;

      this.switchInput = {
        group: this.searchForm,
        formControlName: 'showInactive',
        label: this.headerOption.toggleLabel,
        disabled: this.headerOption.toggleDisabled,
        labelID: this.headerOption.toggleLabelID
      };
    }
  }

  @Input('radioSelectiondata')
  set setradioSelection(value) {
    if (value) {
      this.IsRadioSearch = true;
      this.radioselectiondataobj = value;
    }
  }

  @Input('bookingSearch')
    set setBookingSearch(value) {
      if (value) {
       this.bookingSearch = true;
    }
  }

  @Input('includeHistory')
    set setIncludeHistory(value) {
      if (value) {
       this.includeHistory = true;
    }
  }

  @Input('minSearchTextchar')
  set setMinChar(value) {
    if(value) {
      this.minSearchTextChar = value;
    }
  }

  @Input('toggleAction')
  set SetToggleAction(value) {
    if (value._isScalar) {
      this.searchForm.get("showInactive").setValue(true);      
    }else{
      this.searchForm.get("showInactive").setValue(false);
    }
  }
  @Input('filterStatus')
  set setfilterStatus(value) {
    if (value) {
      this.filterStatus = true;
    }
  }

  constructor(private form: UntypedFormBuilder, private localization: Localization) {
    this.searchForm = this.form.group({
      showInactive: false
    });
  }

  // Event Handlers
  searchValueChange($event) {
    this.simplesearchInput = $event;
    if(!this.bookingSearch)
      this.searchChange.emit($event);
  }

  searchTextChange($event) {
    this.searchChange.emit(this.simplesearchInput);
  }

  toggleChange(arg) {
    this.showInactive.emit(arg);
  }

  handleClick(arg) {
    this.createType.emit(arg);
  }

  handleAllClick(e) {
    this.createActionType.emit(e);
  }

  toggleAdvancedSearch() {
    if (this.fromType) {
      this.showAdvancedSearch = false;
      this.advanceSearchAccountEmit.emit(this.showAdvancedSearch);
    }
    else if (this.toType) {
      this.showAdvancedSearch = false;
      this.advanceSearchContactEmit.emit(this.showAdvancedSearch);
    }
    else {
      this.showAdvancedSearch = false;
      this.showAdvancedSearchChange.emit(this.showAdvancedSearch);
    }
  }

  linksClick() {
    this.linksClickEmit.emit(this.showLinks);
  }

  clearSearchText() {
    this.simplesearchInput = '';
  }

  onSearchDateChange(e) {
    this.searchDateChange.emit(e);
  }

  onSearchDates(e) {
    this.simpleDateSearch.emit(e);
  }

  printAction(e) {
    this.printActionEmit.emit(e);
  }

  handleSearchButtonClick(e) {
    this.searchButtonClicked.emit(e);
  }
  searchSelectionChangeEmitter(e) {
    this.searchSelectionChange.emit(e);
  }
  checkboxClicked(e) {
    this.includeHistoryChecked.emit(e);
  }

}
