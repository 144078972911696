import { Component, OnInit } from '@angular/core';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { menuTypes } from 'src/app/retail/retail.modals';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';

@Component({
  selector: 'app-transations',
  templateUrl: './transations.component.html',
  styleUrls: ['./transations.component.scss']
})
export class TransationsComponent implements OnInit {
  menuList: any;
  menuType = menuTypes;
  captions: any;
  constructor(
    private routeDataService: RouteLoaderService
    , private PropertyInfo: RetailPropertyInformation
    , private localization: RetailLocalization
    , private _ss: CommonVariablesService
  ) {
    this.captions = this.localization.captions.shop;
  }

  ngOnInit() {
    const value = this.routeDataService.GetChildMenu('/shop/viewshop/retailtransactions');
    value.linkedElement.map(res => {
      if (res && res.routePath === '/shop/viewshop/retailtransactions/returnwithticket') {
        res.visibility = !this.PropertyInfo.UseRetailInterface;
        if (this.PropertyInfo.UseRetailInterface && (this._ss.isFromRefund && this._ss.RefundTicketTransactionIDs.length > 0)) {
          res.text = this.captions.RefundTicket;
          res.visibility = true;
        }
      } else if (this.PropertyInfo.UseRetailInterface && res && res.routePath === '/shop/viewshop/retailtransactions/returnwithoutticket') {
        res.text = res.text.replace('without', '');
      }
    });
    this.menuList = {
      menu: value.linkedElement,
      menuType: value.linkedElement[0].menuAlignment
    }
  }
}
