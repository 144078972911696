<div [formGroup]="group" class='dynamic-radio' [hidden]="config.hide">

  
  <mat-label class="radio-label">{{config.label}}</mat-label> 
  <mat-radio-group [formControlName]="config.name"  [attr.name]="config.name">  
  <mat-radio-button class="radio-button" [checked]="option.id == config.value" *ngFor="let option of config.options" [value]='option.id' [disabled]="option.disable" (change)="radioChange($event)" >    
    <span class="{{config.class}}">{{option.value}}</span>
  </mat-radio-button>
</mat-radio-group>

</div>
