import { AbstractControl } from '@angular/forms';

export function durationValidator(control: AbstractControl) {
    if (control.parent) {
        const min = control.parent.get('minute').value;
        const hour = control.parent.get('hour').value;
        const total = hour * 60 + Number(min);
        if (total > 1440) {
            return { exceededDuration: true };
        }
    }
    return null;
}