<div class="dialog-container">
    <div class="pop-head">
        <label class="inner-header pl-4 text-uppercase">{{captions.lbl_advanceSearch}}</label>
        <i aria-hidden="true" class="material-icons inner-close float-md-right cursPointer" (click)="close()">close</i>
    </div>
    <div class="pop-contents ag_container--padding-md" [formGroup]="advanceSearchForm">
        <div class="ag_display--flex-wrap align-items-baseline">
            <!-- <ag-dropdown class="outlet ag_form-control--xs" [placeholder]="captions.lbl_outlet"  formControlName="outlet" [source]="outletoptions">
        </ag-dropdown> -->
            <ag-input class='ag_display--inblock ag_form-control--xs width20' formControlName='firstName' (keydown.enter)="search(); $event.preventDefault()"
                [maxlength]="256" [placeholder]='captions.lbl_firstName'></ag-input>
            <ag-input class='ag_display--inblock ag_form-control--xs width20' formControlName='lastName' (keydown.enter)="search(); $event.preventDefault()"
                [maxlength]="256" [placeholder]='captions.lbl_lastName'></ag-input>
            <!-- <ag-input class='ag_display--inblock ag_form-control--xs width30' formControlName='emailId' [maxlength]="75"
                [placeholder]='captions.lbl_emailId'></ag-input> -->
            <mat-form-field class="email-input ag_form-control--xs width30" [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_personalInformation_emailId'" matInput name="email" [maxlength]="75" (keydown.enter)="search(); $event.preventDefault()"
                    inputtype="email" id="EmailInput" formControlName="emailId" [placeholder]="captions.lbl_emailId">
                <mat-error class="mb-1"
                    *ngIf="!advanceSearchForm.controls['emailId'].valid && advanceSearchForm.controls['emailId'].touched && advanceSearchForm.controls['emailId'].value">
                    {{_localization.captions.retailsetup.InvalidEmailFormat}}
                </mat-error>                
            </mat-form-field>
            <!-- <ag-input class='ag_display--inblock ag_form-control--xs' formControlName='countryCode'
                [placeholder]='captions.lbl_countryCode'></ag-input> -->
            <ag-input class='ag_display--inblock ag_form-control--xs width20' formControlName='phoneNumber' (keydown.enter)="search(); $event.preventDefault()"
                [maxlength]="30" [placeholder]='captions.lbl_phoneNumber'></ag-input>
            <app-button [buttontype]='searchButton' [class.disabled]="!advanceSearchForm.controls['emailId'].valid && advanceSearchForm.controls['emailId'].touched && advanceSearchForm.controls['emailId'].value" (valueChange)='search()'></app-button>
        </div>
        <ng-container *ngIf="tableContent.length == 0">
            <div class="no-data-img">
                <img src="./assets/images/SearchIllustration.png" alt="No Data">
            </div>
        </ng-container>
        <div class="table-container p-0 h-100" *ngIf="tableContent.length > 0">
            <app-cdkvirtual [headerOptions]="headerOptions" [tableContent]="tableContent" [options]="tableOptions"
                (rowClickEmit)='onRowClick($event)' (EmittedData)='onTableAction($event)'>
            </app-cdkvirtual>
        </div>
    </div>
    <mat-dialog-actions class="actions pl-3">
        <app-button [buttontype]='applyButton'  (valueChange)='apply()'></app-button>
        <app-button [buttontype]='cancelButton' (valueChange)='close()'></app-button>
    </mat-dialog-actions>
</div>