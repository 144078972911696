import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negtiationRateFormatter'
})
export class NegotiationRateFormater implements PipeTransform {

  transform(number: any): any {
       
   return Math.abs(number).toFixed(2)
  }
}