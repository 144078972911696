import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { AllowedSettingsPublish } from '@app/shared/models/SignalRDto';
import { SettingsService } from '@app/shared/services/settings.service';
import { LayoutFunctions } from '@app/shared/utilities/layout-functions';
import { ComponentTypes, LayoutUpdateStatus, PartyState, PartyType, ReservationType, TableLayoutConfig } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { LayoutConfig } from '@models/global.interface';
import { TableSuggestionMode } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { TablesService } from '@services/tables.service';
import { Utilities } from '@utilities/utilities';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { ISubscription, Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-table-selection',
  templateUrl: './table-selection.component.html',
  styleUrls: ['./table-selection.component.scss'],
})

export class TableSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
  displayLegends: any = [];
  config: FieldConfig[];
  floorPlan: any = [];
  showMessage = false;
  layoutConfiguration: LayoutConfig;
  intialSet = true;
  @Input() data: any;
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  selectedIndex: number;
  tableSubscription: ISubscription;
  isWaitTimeChange = false;
  updatedSelectedTableNames = [];
  subscriptions: Subscription = new Subscription();
  StandaloneTables: any = [];

  constructor(public partyService: PartyService, private ps: PopupService, public translateService: TranslateService,
              private cs: CacheService, private ts: TablesService, private ss: SettingsService, private lf: LayoutFunctions) {
    // this.lf.loadIcons();
    this.getReservations();
    this.layoutChanges();
    this.stateChanges();
  }

  ngOnInit() {
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === TableSelectionComponent);
    this.displayLegends = [{ State: 1, name: 'Reserved' }, { State: 5, name: 'Blocked' }];
    this.floorPlan = this.cs.layout.value.FloorPlans;
    const floorPlanArray = [];
    this.floorPlan.forEach(element => {
      if (element.IsDefault) {
        floorPlanArray.push({ id: element.Id, value: element.Name });
      }
    });
    this.config = [
      {
        type: 'select',
        name: 'floorPlan',
        options: floorPlanArray,
        class: 'login__language-selection',
        showErrorText: true,
        appearance: true,
        isTranslate: true,
        value: floorPlanArray[0].id
      }
    ];
    if (this.partyService.tabsModal) {
      this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === TableSelectionComponent);
    }
    this.setData();
    this.subscriptions.add(this.partyService.slotAvailabilitySubject$.subscribe((slots) => {
      if (slots && slots.StandaloneTableIds && this.cs.settings.value.General.TableSuggestionMode === TableSuggestionMode.SuggestAndAssign) {
        this.updatedSelectedTableNames = Utilities.getTableNamesFromStandaloneTables(slots.StandaloneTableIds,
          this.cs.layout.value.FloorPlans);
        this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = this.updatedSelectedTableNames.toString();
        this.partyService.reservationFormGroup.get('selectedTable').setValue(slots.StandaloneTableIds);
        this.partyService.reservationFormGroup.controls.selectedTable.setValue(slots.StandaloneTableIds);
      } else if (slots && !slots.StandaloneTableIds && this.cs.settings.value.General.TableSuggestionMode === TableSuggestionMode.SuggestAndAssign) {
        this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = 'Table(s)';
        this.partyService.reservationFormGroup.get('selectedTable').setValue(null);
        this.partyService.reservationFormGroup.controls.selectedTable.setValue(null);
      } else if (slots && slots.StandaloneTableIds && this.cs.settings.value.General.TableSuggestionMode === TableSuggestionMode.Suggest) {
        this.partyService.reservationFormGroup.controls.selectedTable.setValue(slots.StandaloneTableIds);
      }
    }));
  }

  setData() {
    this.subscriptions.add(this.partyService.getStandaloneTablesFutureStateAllFloorTables().subscribe(tables => {
      if (tables) {
        const ShiftId = this.currentShiftID(this.partyService.reservationFormGroup.get('selectedDate').value || this.data?.date);
        const updatedTable = this.UpdateTableStatus(tables, ShiftId);
        this.layoutConfiguration = updatedTable;
        this.partyService.layoutCofiguartion = this.layoutConfiguration;
        this.intialSet = false;
      }
    }));
  }

  getReservations() {
    this.subscriptions.add(this.partyService.Parties$.subscribe((data) => {
      if (this.layoutConfiguration)
      this.setLayoutConfig();
    }));
  }

  setLayoutConfig() {
    if (this.partyService.Parties$.value && this.ts.tableBlockingRules$.value && this.cs.layout.value && this.cs.settings.value) {
      let isUpdateFloorPlan = AllowedSettingsPublish[this.cs.settingsOperationName.value] == AllowedSettingsPublish['UpdateFloorPlan'];
      let blockval = this.ts.getBlockingRulesFortheDate(new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)));
      let shiftId = this.currentShiftID(new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)));
      let pendingReservations = this.partyService.Parties$.value.filter(x => x.State == PartyState.Pending && x.Type == PartyType.Reservation).concat(this.layoutConfiguration.reservations.filter(x => x.State == PartyState.Pending && x.Type == PartyType.Reservation));
      pendingReservations = uniqBy(pendingReservations, 'Id');
      let seatedParties = this.cs.state.value.SeatingParties.filter(x => x.State == PartyState.Seated)
      let CurrentReservations = (pendingReservations).concat(seatedParties.filter(x => (x.Type == PartyType.Reservation || (x.Type == PartyType.WalkIn && x.State == PartyState.Seated))));
      this.layoutConfiguration = {
        from: TableLayoutConfig.reservationTableSelection,
        tables: this.floorPlan[0].StandaloneTables,
        reservations: CurrentReservations,
        blockingRules: this.ts.getBlockingRulesForShift(blockval,shiftId),
        floor: this.floorPlan,
        type: (this.intialSet || isUpdateFloorPlan) ? LayoutUpdateStatus.New : LayoutUpdateStatus.Update
      };
      this.cs.settingsOperationName.next(null);
      this.ts.standAloneTables = this.floorPlan[0].StandaloneTables;
      this.layoutConfiguration = { ...this.layoutConfiguration };
      this.partyService.layoutCofiguartion = this.layoutConfiguration;
    }
  }

  layoutChanges() {
    this.subscriptions.add(this.cs.layout.subscribe(layt => {
      this.floorPlan = this.cs.layout.value.FloorPlans;
      this.floorPlan = this.currentFloorPlan();
      this.StandaloneTables = this.floorPlan[0].StandaloneTables;
    }));
  }

  stateChanges() {
    this.subscriptions.add(this.cs.state.subscribe(layt => {
      this.floorPlan = this.cs.layout.value.FloorPlans;
      this.floorPlan = this.currentFloorPlan();
      this.StandaloneTables = this.floorPlan[0].StandaloneTables;
      if (!this.partyService.isMoveTableAPITriggered && !this.intialSet)
      this.setLayoutConfig();
    }));
  }

  ngAfterViewInit() {
    // this.ps.saveBtnEnable$.next(true);
    this.subscriptions.add(this.partyService.tabChange$.subscribe((data) => {
      if (data === this.selectedIndex) {
        if (this.partyService.reservationFormGroup.get('selectedTable').value) {
          this.layoutConfiguration.selectedValues = this.partyService.reservationFormGroup.value.selectedTable;
        }
        this.ps.formValid$.next(
          {
            isFormValid: true,
            currentTab: this.selectedIndex
          } as IFormValidDetails);
      }
    }));
    this.tableSubscription = this.partyService.tableSelected.subscribe(val => {
      this.partyService.reservationFormGroup.controls.selectedTable.setValue(val);
      this.updatedSelectedTableNames = Utilities.getTableNamesFromStandaloneTables(val, this.cs.layout.value.FloorPlans);
      this.makeReservationFormDirty();
      this.ps.formValid$.next(
        { isFormValid: true, currentTab: this.selectedIndex } as IFormValidDetails);
      
      if (val && !val.includes(undefined) && val.length) {
        this.ps.saveBtnEnable$.next(true);
      }
      else {
        this.ps.saveBtnEnable$.next(false);
      }
    
    });
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedTime').valueChanges.subscribe(() => {
      this.isWaitTimeChange = true;
      this.partyService.layoutCofiguartion.tables = [];
    }));
    this.subscriptions.add(this.ss.blockingdataChange$.subscribe(val => {
      let selectedDate;
      if (this.partyService.reservationType === ReservationType.Waitlist) {
        selectedDate = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
      } else {
        selectedDate = new Date(this.partyService.reservationFormGroup.get('selectedTime').value.DateTime || this.data?.date);
      }
      let blockval = this.ts.getBlockingRulesFortheDate(selectedDate);
      if (this.layoutConfiguration) {
        let shiftId = this.currentShiftID(this.partyService.reservationFormGroup.get('selectedDate').value);
        this.layoutConfiguration.blockingRules = this.ts.getBlockingRulesForShift(blockval, shiftId);
        this.layoutConfiguration = {...this.layoutConfiguration};
      }
    }));
  }

  currentFloorPlan() {
    let customFloorPlan = false;
    let currentdate = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    let CustomFloorPlanDeatils = Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, currentdate);
    if (CustomFloorPlanDeatils && CustomFloorPlanDeatils.length > 0 && CustomFloorPlanDeatils[0].Dates) {
      let getShiftForDate = CustomFloorPlanDeatils
        .filter((element) =>
          element.Dates.some((subElement) => subElement.ShiftId != null && subElement.ShiftId > 0))
        .map(element => {
          let newElt = Object.assign({}, element);
          return newElt.Dates.filter(subElement => subElement.ShiftId != null && subElement.ShiftId > 0);
        });
      if (getShiftForDate.length > 0) {
        customFloorPlan = true;
      }

    }
    let TodayShifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, currentdate);
    let curentshift = 0;
    if (customFloorPlan) {
      let currentdateval = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
      let selecteddate = new Date(currentdateval.setHours(0, 0, 0, 0)).getTime();
      let CurrentDateTime = new Date(new Date(currentdate).setHours(0, 0, 0, 0));
      if (CurrentDateTime.getTime() == selecteddate) {
        let CurrentShift = TodayShifts.filter(Shift => {
          if (Utilities.datetimeBetween(Shift.EffectiveRange.Start, Shift.EffectiveRange.End, currentdate)) {
            return true;
          }
        });
        if (CurrentShift && CurrentShift.length > 0) {
          curentshift = CurrentShift[0].Id;
        } else {
          let PrevShift = TodayShifts.filter(Shift => {
            if (Utilities.diffBetweenDateTime(Shift.EffectiveRange.End, currentdate) < 0) {
              return true;
            }
          });

          if (PrevShift && PrevShift.length > 0) {
            let maxtime = Math.max.apply(Math, PrevShift.map(function (o) { return new Date(Utilities.formateDateString(o.EffectiveRange.End)); }))
            let maxHour = new Date(maxtime).getHours() + (new Date(maxtime).getMinutes() / 60);
            let maxshift = PrevShift.filter(Shift => {
              let Shiftendhour = Utilities.formateDateString(Shift.EffectiveRange.End).getHours() + (Utilities.formateDateString(Shift.EffectiveRange.End).getMinutes() / 60);
              if (Number(Shiftendhour) == Number(maxHour)) {
                return true;
              }
            });
            if (maxshift && maxshift.length > 0) {
              curentshift = maxshift[0].Id;
            }
            else {
              curentshift = PrevShift[0].Id;
            }

          } else {
            curentshift = TodayShifts[0].Id;
          }

        }

      } else if (new Date(CurrentDateTime.setDate(CurrentDateTime.getDate() - 1)).getTime() == selecteddate) {
        let CurrentShift = TodayShifts.filter(Shift => {
          if (Utilities.datetimeBetween(Shift.EffectiveRange.Start, Shift.EffectiveRange.End, currentdate)) {
            return true;
          }
        });

        if (CurrentShift != null && CurrentShift.length > 0) {
          curentshift = CurrentShift[0].Id;
        }
        else {
          curentshift = TodayShifts[0].Id;
        }
      } else {
        curentshift = TodayShifts[0].Id;
      }

    } else {
      curentshift = 0;
    }

    return Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, currentdate, curentshift);
  }

  UpdateTableStatus(fututreTableStates, selectedShift) {
    let selectedDate;
    if (this.partyService.reservationType === ReservationType.Waitlist) {
      selectedDate = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    } else {
      selectedDate = new Date(this.partyService.reservationFormGroup.get('selectedTime').value.DateTime || this.data?.date);
    }
    const previousDate = moment(new Date(selectedDate)).add(-1, 'day').toDate();
    let yesterdayShifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, previousDate);
    yesterdayShifts.forEach(shift => {
      if (Utilities.datetimeBetween(shift.EffectiveRange.Start, shift.EffectiveRange.End, selectedDate)) {
        selectedShift = shift.Id;
        selectedDate = previousDate;
      }
    });
    let standaloneTables;
    const SpecificShiftCustomFloorPlan = Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, selectedDate, selectedShift);
    this.loadFloorPlans(SpecificShiftCustomFloorPlan[0].FloorPlanViews);
    standaloneTables = SpecificShiftCustomFloorPlan[0].StandaloneTables;
    const StandaloneTablesWithStates = [];
    for (let i = 0; i < standaloneTables.length; i++) {
      StandaloneTablesWithStates.push({
        ...standaloneTables[i],
        ...(fututreTableStates.find((state) => state.StandaloneTableId === standaloneTables[i].Id))
      }
      );
    }
    const reservations = this.partyService.setReservations(fututreTableStates);
    var shiftId = this.currentShiftID(this.partyService.reservationFormGroup.get('selectedDate').value || this.data?.date);
    let isUpdateFloorPlan = AllowedSettingsPublish[this.cs.settingsOperationName.value] == AllowedSettingsPublish['UpdateFloorPlan'];
    this.layoutConfiguration = {
      from: TableLayoutConfig.reservationTableSelection,
      tables: StandaloneTablesWithStates,
      reservations,
      blockingRules: this.ts.getBlockingRulesForShift(this.ts.getBlockingRulesFortheDate(selectedDate), shiftId),
      floor: SpecificShiftCustomFloorPlan,
      selectedValues: (this.data && this.data.TableIds) ? this.data.TableIds : this.data,
      type: (this.intialSet || isUpdateFloorPlan) ? LayoutUpdateStatus.New :
      (this.partyService.layoutCofiguartion.tables && this.partyService.layoutCofiguartion.tables.length > 0 ?
         LayoutUpdateStatus.Update : LayoutUpdateStatus.New)
    };
    this.cs.settingsOperationName.next(null);
    this.partyService.layoutCofiguartion = this.layoutConfiguration;
    return this.layoutConfiguration;
  }

  loadFloorPlans(FloorPlanViews) {
    let floorPlanOptions = Utilities.getFloorPlan(FloorPlanViews,this.translateService);
    floorPlanOptions = floorPlanOptions.map(option => {
      option.value = this.translateService.instant(option.value)
      return option;
    });
    this.config = [];
    this.config.push({
      type: 'select',
      name: 'floorPlan',
      options: floorPlanOptions,
      class: 'login__language-selection',
      showErrorText: true,
      appearance: true,
      isTranslate: true,
      label: 'unblockbuttontext',
      placeholder: 'Floor',
      value: floorPlanOptions[0].id,
      cellClick: (event) => this.setSelectedFloorPlan(event)
    }
    );
  }
  setSelectedFloorPlan(eve) {
    this.ts.selectedFloorPlanPopup.next(eve.value);
    this.layoutConfiguration.selectedValues = this.partyService.reservationFormGroup.value.selectedTable;
  }
  makeReservationFormDirty() {
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
  }
  currentShiftID(date) {
    let selecteddate;
    if (this.partyService.reservationType === ReservationType.Waitlist) {
      selecteddate = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    } else {
      selecteddate = new Date(this.partyService.reservationFormGroup.get('selectedTime').value.DateTime || this.data?.date);
    }
    const selectedShift = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, new Date(date));
    const CurrentShift = selectedShift.filter(Shift => {
      if (Utilities.datetimeBetween(Shift.EffectiveRange.Start, Shift.EffectiveRange.End, selecteddate)) {
        return true;
      }
    });
    if (CurrentShift && CurrentShift.length > 0) {
      return CurrentShift[0].Id;
    }
    return 0;
  }


  ngOnDestroy() {
    this.partyService.layoutCofiguartion = { from: TableLayoutConfig.reservationTableSelection };
    if (this.tableSubscription) {
      this.tableSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
