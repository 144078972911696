<div class="table-selection">
  <div class="table-selection__header">
    <div  class="reservation-select-label table-selection__select-label">
      <span class="table-selection__view-label seat-text-fields" *ngIf="updatedSelectedTableNames.length">{{'waitListPopUpSelectTable'|translate}} - {{updatedSelectedTableNames}}</span>
      <!-- <span *ngIf="showMessage" class="error-message">Choose maximum of 3 servers</span> -->
    </div>
    <div class="table-selection__view-legends">
      <label for="">{{'floorText' | translate}}</label>
      <dynamic-form [config]="config" #form="dynamicForm" >
      </dynamic-form>
      <!-- <app-display-legend [legends]="displayLegends"></app-display-legend> -->
    </div>
  </div>
  <div class="table-selection__canvas-section" *ngIf="partyService.layoutCofiguartion?.tables?.length > 0">
    <app-layout-view [layoutConfig]="layoutConfiguration"></app-layout-view>
  </div>
</div>