<div id="loaderitem" class="loaderitem" *ngIf="(loading$ | async) || (showMessage$ | async)">


</div>
<!-- <div class="loader-content" *ngIf="loading$ | async" >

        <div class="loader-wrap">
            <div class="loader">
                <span class="loader-item"></span>
                <span class="loader-item"></span>
                <span class="loader-item"></span>
                <span class="loader-item"></span>
                <span class="loader-item"></span>
                <span class="loader-item"></span>
                <span class="loader-item"></span>
                <span class="loader-item"></span>
                <span class="loader-item"></span>
            </div>

        </div>




    </div> -->
<!-- <div class="loader-content" *ngIf="loading$ | async" >
        <span class="icon-Page-loading-2 loader" ><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span></span>
    </div> -->
<div class="loader-content" *ngIf="(loading$ | async) && !(disableLoader$ | async) && !(showMessage$ | async)">
    <!-- *ngIf="loading$ | async" -->
    <!-- <div class="loading bar">  
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
       </div> -->
    <!-- <img src="assets/images/spinner-svg.svg"> -->
    <!-- <img class="loader-content__seat-loader" src="assets/images/Page-loading.svg"> -->
    <!-- <img class="loader-content__seat-loader" alt="" src="assets/images/seat-loader.svg"> -->
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <div id="loader-message-cover">
        <!-- Remove translation pipe for performance reasons -->
        <div id="loader-message">{{'loadingMessage' | translate}}</div>
    </div>
</div>
<div *ngIf="!loading$ | async">
    <div id="cover-spin"></div>
    <div id="custom-cover-spin">
        <div id="custom-cover-message"></div>
    </div>
</div>
<div *ngIf="showMessage$ | async" class="loader-content">
    <!-- <div id="cover-spin"></div> -->
    <!-- <img class="loader-content__seat-loader" alt="" src="assets/images/seat-loader.svg"> -->
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <div id="loader-message-cover">
        <div id="loader-message">{{loaderMessage | translate}}</div>
    </div>
</div>