import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,Validators } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { DynamicFormService } from '@app/shared/dynamicform/service/dynamic-form.service';
@Component({
  exportAs: 'dynamicForm',
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['dynamic-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicFormComponent implements OnChanges, OnInit, OnDestroy {

  @Input() config: FieldConfig[] = [];
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;

  get controls() { return this.config.filter(({ type }) => type !== 'button'); }
  get changes() { return this.form?.valueChanges; }
  get valid() { return this.form?.valid; }
  get value() { return this.form?.value; }

  constructor(private fb: UntypedFormBuilder , private dynamicFormService:DynamicFormService) { }

  ngOnInit() {
    this.form = this.createGroup();
  }
  inputEmiter() {
    /*  */

  }

  ngOnDestroy() {
    this.config = [];
  }

  ngOnChanges() {
    if (this.form) {
      const controls = Object.keys(this.form.controls);
      const configControls = this.controls.map((item) => item.name);

      controls
        .filter((control) => !configControls.includes(control))
        .forEach((control) => this.form.removeControl(control));

      configControls
        .filter((control) => !controls.includes(control))
        .forEach((name) => {
          const config = this.config.find((control) => control.name === name);
          if (config) {
            this.form.addControl(name, this.createControl(config));
          }
        });

    }
  }

  createGroup() {
    const group = this.fb.group({});
    // if(this.er){
    //   this.er.controls.forEach(control => group.addControl(control.name, this.createControl(control)));
    // }
    // else{
    this.controls.forEach(control => group.addControl(control.name, this.createControl(control)));
    //  }
    return group;
  }

  createControl(config: FieldConfig) {
    if((config.inputType == 'text' || config.type =='input' || config.type == "textarea") && config.name != "emailTemplate"){
    if(config.validation ){
      config.validation.push(Validators.pattern(this.dynamicFormService.HTMLPattern));
    }else{
      config.validation = [Validators.pattern(this.dynamicFormService.HTMLPattern)];
    }   
  } 
    const { disabled, validation, value } = config;
    return this.fb.control({ disabled, value }, validation);
  }

  handleSubmit(event: Event) {
    /*  */
    event.preventDefault();
    event.stopPropagation();
    // this.submit.emit(this.value);
    if (this.form.valid) {
      this.submit.emit(this.form.value);
    } else {
      this.validateAllFormFields(this.form);

      for (let key in this.form.controls) {
        let value = this.form.controls[key];
        if (value.status == 'INVALID') {
          document.getElementsByName(key)[0].scrollIntoView({ behavior: 'smooth' });
        }
      }
      this.submit.emit(this.form.value);
    }
  }
  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  setDisabled(name: string, disable: boolean) {
    if (this.form.controls[name]) {
      const method = disable ? 'disable' : 'enable';
      this.form.controls[name][method]();
      return;
    }

    this.config = this.config.map((item) => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }

  setValue(name: string, value: any) {
    this.form.controls[name].setValue(value, { emitEvent: true });
  }
}
