<!-- <section class="guestDetails"> -->
<!-- view a particular Guest details -->
<div class="guestdetails__main">

  <ng-scrollbar track="all">
    <div class="w-100 userprofile__view-user">
      <button class="icon-Group-591 rounded-icon userprofile__action"
              (click)="closeGuestDetails()"></button>
      <button  [ngClass]="{'userprofile__disabled-icon': HostRoleType.Name != RoleType[RoleType.Manager]}" class="icon-ic_delete_24px rounded-icon userprofile__action" (click)="deleteGuest()"></button>
      <button class="icon-edit rounded-icon userprofile__action" (click)="editGuest()"></button>
    </div>
    <div class="userprofile" [ngClass]="{'userprofile__view-profile': !_gbs.isGuestDetailsVisible.getValue()}">    
      <div class="userprofile__profile-pic">
        <!-- Guest profile image -->
        <div class="float-left position-relative userprofile__profile-image" *ngIf="profileImage != ''">
          <img [src]="profileImage" alt="profile_pic"
              class="userprofile__user-image">
          <!------------------ Commented and must show when we get the no. of notifications -------------------->
          <!-- <span class="userprofile__notification rs-Roboto-Regular-12" *ngIf="totalVisits > 0">{{totalVisits}}</span> -->
        </div>
        <div *ngIf="profileImage == ''" class="userprofile__guest-initial" [ngStyle]="{'background-color': '' | randomColor}">{{contactDetails | guestNameFormatter}}</div>
  
      <div class="float-left userprofile__details">
  
        <!-- Guest name and favourite,vip selection -->
        <div class="userprofile__first-row">
          <span class="float-left mb-0 seat-panel-header userprofile__client-name"
                title="{{contactDetails.FirstName}} {{contactDetails.LastName}}">
            {{contactDetails.GuestTitle | guestTitlePipe}}
            {{contactDetails.FirstName}}
            {{contactDetails.LastName}}
          </span>
          <mat-chip-list class="float-left pl-2 userprofile__action-chips">
            <mat-chip class="icon-Favourites userprofile__chip-selection seat-icon-color seat-border-color"
                      [ngClass]="{'userprofile__favSelected':contactDetails.IsFavorite}" (click)="favClick()">
              <span class="seat-labels">{{'guestBookFavouriteWord'| translate}}</span>
            </mat-chip>
            <mat-chip class="icon-VIP userprofile__chip-selection seat-icon-color seat-border-color"
                      [ngClass]="{'userprofile__vipSelected':contactDetails.IsVip}" (click)="vipClick()">
              <span class="seat-labels">{{'guestBookVIPText'|translate}}</span>
            </mat-chip>
          </mat-chip-list>
        </div>
  
        <!-- Guest email,phone number -->
        <div class="userprofile__alias-name seat-secondary-text" *ngIf="contactDetails?.AliasName">{{'guestBookDetailsAliasName' | translate}} :  {{contactDetails?.AliasName}}</div>
        <div class="userprofile__email seat-secondary-text">{{contactDetails.EmailAddress}}</div>
        <div class="userprofile__phonenumber seat-secondary-text">
          {{contactDetails.PhoneNumber | phoneNumberFormatter: contactDetails.CountryId}}
        </div>
  
        <!-- Guest communication ways -->
        <!-- <div class="userprofile__common-div">
          <activities-app-button class="rs-mr-10" [buttontype]="emailButton"></activities-app-button>
          <activities-app-button [buttontype]="smsButton"></activities-app-button>
         <activities-app-button class="rs-mr-10" [buttontype]="callButton"></activities-app-button>
        </div> -->
      </div>
    </div>
    <div class="userprofile__spend-details seat-body-text">
        <div>{{'totalSpent' | translate}}</div>
        <div class="seat-reservation-status">
          {{this.cs.settings.value.General.OperationCurrency}} {{contactDetails.VisitStats[0].TotalSpend}}
        </div>
        <div class="userprofile__average-spent-data">
          {{'average' | translate}} : {{this.cs.settings.value.General.OperationCurrency}} {{contactDetails.VisitStats[0].AverageSpend}}
        </div>
    </div>
    <div class="userprofile__canvas">
      <canvas id="canvas-graph" class="pt-3 pb-3" #mainChart="base-chart" baseChart [data]="chartData" [labels]="chartLabels"
              [options]="chartOptions" [chartType]="pieChartType" [colors]="lineChartColors"></canvas>
      <div class="userprofile__chart-text">
        <span class="d-block userprofile__total-count seat-panel-header">{{totalVisits}}</span>
        <span class="d-block userprofile__total-text seat-labels">{{'guestBookTotalVisitText'|translate}}</span>
      </div>
    </div>
  </div>
  <!-- More details of the Guest in tab -->
  <mat-tab-group class="customerdetails float-left seat-primary-bg-color" [@.disabled]="true" [disableRipple]="true" [selectedIndex]="0" (selectedTabChange)="seletedTabChange($event)">
    <!-- Guest primary information tab -->
    <mat-tab label="{{'primaryInfo' | translate}}">
        
        <table class="customerdetails__personal-info seat-secondary-text">
          <tbody>
            <tr>
              <td class="p-3 customerdetails__detail-name seat-tertiary-text">{{'guestBookDetailsFN' | translate}}</td>
              <td class="p-3">{{contactDetails.FirstName ? contactDetails.FirstName : 'NA' | translate}}</td>
            </tr>
            <tr>
              <td class="p-3 customerdetails__detail-name seat-tertiary-text">{{'guestBookDetailsLN' | translate}}</td>
              <td class="p-3">{{contactDetails.LastName ? contactDetails.LastName: 'NA' | translate}}</td>
            </tr>
            <tr>
              <td class="p-3 customerdetails__detail-name seat-tertiary-text">{{'guestBookDetailsAliasName' | translate}}</td>
              <td class="p-3">{{contactDetails.AliasName ? contactDetails.AliasName: 'NA' | translate}}</td>
            </tr>
            <tr>
              <td class="p-3 customerdetails__detail-name seat-tertiary-text">{{'guestBookDetailsEmail' | translate}}</td>
              <td class="p-3">{{contactDetails.EmailAddress ? contactDetails.EmailAddress: 'NA' | translate}}</td>
            </tr>
            <tr>
              <td class="p-3 customerdetails__detail-name seat-tertiary-text">{{'secondaryEmailLabel' | translate}}</td>
              <td class="p-3">{{contactDetails.EmailAddress2 ? contactDetails.EmailAddress2: 'NA' | translate}}</td>
            </tr>
            <tr>
              <td class="p-3 customerdetails__detail-name seat-tertiary-text">{{'guestBookDetailsMobile' | translate}}</td>
              <td class="p-3">
                {{ contactDetails.PhoneNumber ? ( contactDetails.PhoneNumber | phoneNumberFormatter: contactDetails.CountryId) : 'NA' | translate}}
              </td>
            </tr>
            <tr>
              <td class="p-3 customerdetails__detail-name">{{'secondaryPhoneLabel' | translate}}</td>
              <td class="p-3">
                {{ contactDetails.PhoneNumber2 ? ( contactDetails.PhoneNumber2 | phoneNumberFormatter: contactDetails.CountryId2) : 'NA' | translate}}
              </td>
            </tr>
            <tr *ngIf="isMembershipAvailable">
              <td class="p-3 customerdetails__detail-name seat-tertiary-text">{{_settings.General.MembershipNumberName}}</td>
              <td class="p-3">
                {{ contactDetails.TrackMembershipNumber ?  contactDetails.TrackMembershipNumber : 'NA' | translate}}
              </td>
            </tr>
            <tr>
              <td class="p-3 customerdetails__detail-name seat-tertiary-text">{{'guestBookDetailsDOB' | translate}}</td>
              <td class="p-3">{{contactDetails.Birthday ? (contactDetails.Birthday | formatSettingsDate: cs.settings.value.General.DateFormat ) : 'NA' | translate}}</td>
            </tr>
            <!-- <tr>
              <td class="p-3 customerdetails__detail-name">{{'guestBookDetailsMaritalStatus' | translate}}</td>
              <td class="p-3">{{contactDetails.personalInfo.maritalStatus}}</td>
            </tr> -->
            <tr>
              <td class="p-3 customerdetails__detail-name seat-tertiary-text">{{'guestBookDetailsAnniversary' | translate}}</td>
              <td class="p-3">
                {{ contactDetails.Anniversary ? (contactDetails.Anniversary |  formatSettingsDate: cs.settings.value.General.DateFormat ) : 'NA' | translate}}
              </td>
            </tr>
            <tr>
              <td class="p-3 customerdetails__detail-name seat-tertiary-text">{{'guestBookVIPText' | translate}}</td>
              <td class="p-3">{{contactDetails.IsVip ? contactDetails.IsVip ? 'Yes' : 'No': 'NA' | translate}}</td>
            </tr>
            <!-- <tr>
              <td class="p-3 customerdetails__detail-name">{{'guestBookDetailsInternalNotes' | translate}}</td>
              <td class="p-3">{{contactDetails.additionalInfo.internalNotes}}</td>
            </tr> -->
            <tr>
              <td class="p-3 customerdetails__detail-name seat-tertiary-text" valign="top">{{'guestBookDetailsAddress' | translate}}</td>
              <td class="p-3">
                <span *ngIf="contactDetails?.ContactAddress?.length > 0">
                  <span *ngFor="let x of contactDetails.ContactAddress">
                    <u *ngIf="x.Name">{{x.Name}}</u>
                    <u *ngIf="!x.Name">{{contactDetails.FirstName}} {{contactDetails.LastName}}</u>
                    <p *ngIf="x.Street1 || x.Street2">{{x.Street1}} {{x.Street2}}</p>
                    <p *ngIf="x.City || x.State">{{x.City}} {{x.State}}</p>
                    <p *ngIf="x.Country || x.PostalCode">{{x.Country}} {{x.PostalCode}}</p>
                  </span>
                </span>
                <span *ngIf="!contactDetails.ContactAddress || contactDetails?.ContactAddress?.length == 0">{{'NA' | translate}}</span>
              </td>
            </tr>
          </tbody>
        </table>
        
    </mat-tab>
  
    <!-- Guest recenct activity tab -->
    <mat-tab label="{{'Activity' | translate}}">
      <div class="customerdetails__user-activity">
        <ng-template mat-tab-label>
          <span>{{'userActivityText' | translate}}</span>
          <span *ngIf="(guestOngoingReservation.asObservable() | async)?.length > 0 || (guestFutureReservation.asObservable() | async)?.length > 0" class="customerdetails__activity-status"></span>
          <img src="assets/images/Refresh_icon.svg" alt="" *ngIf="showActivitiesAcrossMerchant && selectedIndex == 1" class="customerdetails__refresh-img" matTooltipClass="tooltip-class" title="{{'syncReservations' | translate}}" (click)="getContact()" />
        </ng-template>
        <div class="customerdetails__tables">
            <div class="customerdetails__reservation-tables rs-mt-10">
              <div class="customerdetails__all-reservations rs-mr-16">
                <a *ngIf="isViewAllReservations" class="customerdetails__view-all"
                   (click)="viewAllReservations(false)">{{'viewAllReservations' | translate}}</a>
                <!-- <div class="customerdetails__flex" *ngIf="isViewAllReservations">
                  <a class="customerdetails__all">{{'allReservations' | translate}}</a>
                  <a class="customerdetails__go-back" (click)="viewAllReservations(false)">{{'goBack' | translate}}</a>
                </div> -->
              </div>
              <!-- matList for Ongoing activities -->
              <div class="customerdetails__reservations customerdetails__on-going seat-tertiary-text" *ngIf="!activityProperty && (guestOngoingReservation.asObservable() | async)?.length > 0">
                <div class="customerdetails__mat-list-header">
                <label for="personal_preference"
                       class="customerdetails__mat-list-label customerdetails__ongoing">{{'guestBookOngoingText' | translate}}</label>
                <label for="personal_preference"
                       class="customerdetails__mat-list-label customerdetails__ongoing">{{'OutletName' | translate}}</label>
                <label for="personal_preference"
                       class="customerdetails__mat-list-label customerdetails__cover">{{'coversText' | translate}}</label>
                <label for="personal_preference"
                       class="customerdetails__mat-list-label customerdetails__table">{{'guestBookTableText' | translate}}</label>
                       </div>
                <!-- <label for="personal_preference"
                  class="customerdetails__mat-list-label customerdetails__table">{{'accordionstatustext' | translate}}</label> -->
                <mat-list class="pt-0 customerdetails__matlist" *ngFor="let item of (guestOngoingReservation.asObservable() | async)">
                  <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color customerdetails__seated">
                    <div class="customerdetails__matlist-item-1 seat-secondary-text">
                      {{ (item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate:'LT'}}
                      <span>{{(item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate: cs.settings.value.General.DateFormat }}</span>
                    </div>
                    <div class="customerdetails__matlist-item-6 seat-secondary-text"  matTooltipClass="tooltip-class" title="{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}">{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}</div>
                    <div class="customerdetails__matlist-item-2 seat-secondary-text" >{{item.Size}}</div>
                    <div class="customerdetails__matlist-item-3 seat-secondary-text" matTooltipClass="tooltip-class" title="{{item.TableNames}}">{{item.TableNames}}</div>
                    <!-- <div class="customerdetails__matlist-item-4">{{item.PartyStatus}}</div> -->
                    <!-- Spinner for Waiting time -->
                <div class="float-right text-center customerdetails__spinner">
                  <mat-progress-spinner class="customerdetails__spinner-full-width"    [diameter]="45" [value]="100">
                  </mat-progress-spinner>
                  <mat-progress-spinner class="customerdetails__spinner-required-width" [ngClass]="{
                    'first-percent' : item.progressBarValue < 50,
  'second-percent': item.progressBarValue >= 50,
  'third-percent': item.progressBarValue  >= 80}"  [diameter]="45" mode="determinate" [value]="item.progressBarValue">
                  </mat-progress-spinner>
  
  
                  <span *ngIf="(item.remainingTime > 0)" class="customerdetails__total-mins rs-Roboto-Regular-8">{{ item.remainingTime | formatWaitTime }}</span>
                  <span *ngIf="( 0 > item.remainingTime)" class="customerdetails__total-mins rs-Roboto-Regular-8">0m</span>
                </div>
                  </mat-list-item>
  
                  <div class="rs-mr-10 customerdetails__mat-icons">
                    <button class="icon-Auditlog2 rounded-icon customerdetails__actions" title="{{'auditlog' | translate}}" (click)="showPartyAuditLog(item)"></button>
                  </div>
                </mat-list>
  
              </div>
                <div *ngIf="!activityProperty && ((guestFutureReservation.asObservable() | async)?.length != 0 || (guestPastReservation.asObservable() | async)?.length != 0 ||
                    (guestWalkin.asObservable() | async)?.length != 0 || (guestPastWalkins.asObservable() | async)?.length != 0 || (guestStandbyList.asObservable() | async)?.length != 0) ">
                <!-- matList for upcoming activities -->
                <div class="customerdetails__reservations customerdetails__upcoming seat-tertiary-text">
                  <div class="customerdetails__mat-list-header">
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__ongoing">{{'guestBookUpcomingText' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__ongoing">{{'OutletName' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__cover">{{'coversText' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__table">{{'guestBookTableText' | translate}}</label>
                         <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__spent"></label>
                  </div>
                  <!-- <label for="personal_preference"
                    class="customerdetails__mat-list-label customerdetails__table">{{'accordionstatustext' | translate}}</label> -->
                  <div *ngIf="(guestFutureReservation.asObservable() | async)?.length > 0">
                    <mat-list class="pt-0 customerdetails__matlist" *ngFor="let item of (guestFutureReservation.asObservable() | async)">
                      <mat-list-item class="mt-1 mb-1 rs-Roboto-Medium-14 customerdetails__list-item seat-secondary-bg-color">
                        <div class="customerdetails__matlist-item-1 seat-secondary-text">
                          {{ (item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate:'LT'}}
                          <span>{{ (item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate: cs.settings.value.General.DateFormat }}</span>
                        </div>
                        <div class="customerdetails__matlist-item-6 seat-secondary-text" matTooltipClass="tooltip-class"  title="{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}">{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}</div>
                        <div class="customerdetails__matlist-item-2 seat-secondary-text" >{{item.Size}}</div>
                        <div class="customerdetails__matlist-item-3 seat-secondary-text" matTooltipClass="tooltip-class" title="{{item.TableNames}}">{{item.TableNames}}</div>
                        <!-- <div class="customerdetails__matlist-item-4">{{item.PartyStatus}}</div> -->
                        <span *ngIf="_as.OTASourceId.includes(item.PartySourceId)" class="party-ota-icon"></span>
                        <div *ngIf="item.CheckIconVisibility" class="customerdetails__matlist-item-4">
                          <img src="assets/images/Cheque_Icon.svg" class="chequeIcon" (click)="ShowOrderDetails(item)" />
                        </div>
                      </mat-list-item>
                      <div class="rs-mr-10 rs-ml-10 customerdetails__mat-icons">
                        <button class="icon-addons rounded-icon customerdetails__actions" *ngIf="item.BookingContactAddonItems?.length"
                          [autoClose]="'outside'" container="body" placement="auto" #addOnForBookingPopover="ngbPopover" container="body"
                          triggers="manual" [ngbPopover]="addOnForBooking"
                          (click)="partyService.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(item)"
                          title="{{'addons' | translate}}"></button>
                        <button class="icon-Auditlog2 rounded-icon customerdetails__actions" title="{{'auditlog' | translate}}" (click)="showPartyAuditLog(item)"></button>
                        <button class="icon-refund rounded-icon customerdetails__actions" *ngIf=showPaymentButton(item) (click)="ActivityPaymentClick(item)"></button>
                        <button class="icon-edit rounded-icon customerdetails__actions" *ngIf="_as.restaurantId == item.RestaurantId" (click)="EditReservation(item)"></button>
                        <button class="icon-Group-591 rounded-icon"
                                [ngStyle]="{'pointer-events': item.State == PartyState.NoShowed ? 'none': ''}" [disabled]="item.State == PartyState.NoShowed"
                                (click)="cancelParty(item)" *ngIf="_as.restaurantId == item.RestaurantId"></button>
                      </div>
                    </mat-list>
                  </div>
                  <mat-list class="pt-0 customerdetails__matlist" *ngIf="!(guestFutureReservation.asObservable() | async) || (guestFutureReservation.asObservable() | async)?.length == 0">
                    <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color customerdetails__no-reservations">
                      <span class="customerdetails__empty-reservations seat-no-content-found">{{ 'noUpcomingReservations' | translate }}</span>
                    </mat-list-item>
                  </mat-list>
                </div>
                <!-- matList for recent activities -->
                <div class="customerdetails__reservations customerdetails__recent seat-tertiary-text">
                  <div class="customerdetails__mat-list-header">
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__ongoing">{{'guestPastReservations' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__ongoing">{{'OutletName' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__cover">{{'coversText' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__table">{{'guestBookTableText' | translate}}</label>
                  <label for="personal_preference"
                  class="customerdetails__mat-list-label customerdetails__spent">{{'spent' | translate}}</label>
                  </div>
                  <!-- <label for="personal_preference"
                    class="customerdetails__mat-list-label customerdetails__table">{{'accordionstatustext' | translate}}</label> -->
                  <div *ngIf="(guestPastReservation.asObservable() | async)?.length > 0">
                    <mat-list class="pt-0 customerdetails__matlist" *ngFor="let item of (guestPastReservation.asObservable() | async)">
                      <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color">
                        <div class="customerdetails__matlist-item-1 seat-secondary-text">
                          {{(item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate:'LT'}}
                          <span>{{(item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate: cs.settings.value.General.DateFormat }}</span>
                        </div>
                        <div class="customerdetails__matlist-item-6 seat-secondary-text"  matTooltipClass="tooltip-class" title="{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}">{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}</div>
                        <div class="customerdetails__matlist-item-2 seat-secondary-text" >{{item.Size}}</div>
                        <div class="customerdetails__matlist-item-3 seat-secondary-text" matTooltipClass="tooltip-class" title="{{item.TableNames}}">{{item.TableNames}}</div>
                        <!-- <div class="customerdetails__matlist-item-4">{{item.PartyStatus}}</div> -->
                        <div *ngIf="item.OrderPrice" class="seat-secondary-text customerdetails__matlist-item-5 customerdetails__word-break-class-new" matTooltipClass="tooltip-class" title="{{item.OperationCurrency}} {{item.OrderPrice | number:'1.2-2' }}">
                          {{item.OperationCurrency}}{{item.OrderPrice | number:'1.2-2' }}
                        </div>
                        <div *ngIf="item.CheckIconVisibility" class="customerdetails__matlist-item-4">
                          <img src="assets/images/Cheque_Icon.svg" class="chequeIcon" (click)="ShowOrderDetails(item)" />
                        </div>
  
                      </mat-list-item>
                      <div class="rs-mr-10 rs-ml-10 customerdetails__mat-icons">
                        <button class="icon-addons rounded-icon customerdetails__actions" *ngIf="item.BookingContactAddonItems?.length"
                        [autoClose]="'outside'" container="body" placement="auto" #addOnForBookingPopover="ngbPopover" container="body"
                        triggers="manual" [ngbPopover]="addOnForBooking"
                        (click)="partyService.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(item)"
                        title="{{'addons' | translate}}"></button>
                        <button class="icon-Auditlog2 rounded-icon customerdetails__actions" title="{{'auditlog' | translate}}" (click)="showPartyAuditLog(item)"></button>
                        <button class="icon-refund rounded-icon customerdetails__actions" *ngIf=showPaymentButton(item) (click)="ActivityPaymentClick(item)"></button>
                        <button *ngIf="showEditForPastReservation(item) && _as.restaurantId == item.RestaurantId" class="icon-edit rounded-icon customerdetails__actions" (click)="EditReservation(item,true)"></button>
                          <span *ngIf="item && item.Feedbacks && item.Feedbacks.length">
                            <img *ngIf="(  RolesAndPermissionsType?.ViewFeedback | rolesAndPermission)"  src="assets/images/feedback_icon.svg" class="customerdetails__feedback-icon" [ngClass]="{'feedback-taken' : item.Feedbacks[0]?.StatusCode == 3, 'feedback-todo': item.Feedbacks[0]?.StatusCode != 3}" (click)="ShowFeedbackDetails(contactDetails, guestPastReservation.asObservable(),item)"/>
                          </span>
                      </div>
                    </mat-list>
                  </div>
                  <mat-list class="pt-0 customerdetails__matlist" *ngIf="(guestPastReservation.asObservable() | async)?.length == 0">
                    <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color customerdetails__no-reservations">
                      <span class="customerdetails__empty-reservations seat-no-content-found">{{ 'noRecentVisits' | translate }}</span>
                    </mat-list-item>
                  </mat-list>
                </div>
                <!-- matList for Stanbylist -->
              <div class="customerdetails__reservations customerdetails__walkins seat-tertiary-text">
                <div class="customerdetails__mat-list-header">
                <label for="personal_preference"
                       class="customerdetails__mat-list-label customerdetails__ongoing">{{'standbyListPopupTitle' | translate}}</label>
                       <label for="personal_preference"
                       class="customerdetails__mat-list-label customerdetails__ongoing">{{'OutletName' | translate}}</label>
                <label for="personal_preference"
                       class="customerdetails__mat-list-label customerdetails__cover">{{'coversText' | translate}}</label>
                <label for="personal_preference"
                       class="customerdetails__mat-list-label customerdetails__table">{{'guestBookTableText' | translate}}</label>
                       <label for="personal_preference"
                class="customerdetails__mat-list-label customerdetails__spent"></label>
                </div>
                <div *ngIf="(guestStandbyList.asObservable() | async)?.length > 0">
                  <mat-list class="pt-0 customerdetails__matlist" *ngFor="let item of (guestStandbyList.asObservable() | async)">
                    <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color">
                      <div class="customerdetails__matlist-item-1 seat-secondary-text">
                        {{(item.WishedTime ? item.WishedTime : item.ArrivedAt) | localizedDate:'LT'}}
                        <span>{{(item.WishedTime ? item.WishedTime : item.ArrivedAt) | localizedDate: cs.settings.value.General.DateFormat }}</span>
                      </div>
                      <div class="customerdetails__matlist-item-6 seat-secondary-text" matTooltipClass="tooltip-class"  title="{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}">{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}</div>
                      <div class="customerdetails__matlist-item-2 seat-secondary-text" >{{item.Size}}</div>
                      <div class="customerdetails__matlist-item-3 seat-secondary-text" matTooltipClass="tooltip-class" title="{{item.TableNames}}">{{item.TableNames}}</div>
                      <!-- <div class="customerdetails__matlist-item-4">{{item.PartyStatus}}</div> -->
                      <div *ngIf="item.CheckIconVisibility" class="customerdetails__matlist-item-4">
                        <img src="../../../../assets/images/Cheque_Icon.svg" class="chequeIcon" (click)="ShowOrderDetails(item)" />
                      </div>
                    </mat-list-item>
                    <div class="rs-mr-10 rs-ml-10 customerdetails__mat-icons">
                      <button class="icon-edit rounded-icon customerdetails__actions"  *ngIf="_as.restaurantId == item.RestaurantId" (click)="Editstandby(item)"></button>
                      <button class="icon-Group-591 rounded-icon"
                              [ngStyle]="{'pointer-events': item.State == PartyState.NoShowed ? 'none': ''}" [disabled]="item.State == PartyState.NoShowed"
                              (click)="cancelParty(item)" *ngIf="_as.restaurantId == item.RestaurantId"></button>
                    </div>
                  </mat-list>
                </div>
                <mat-list class="pt-0 customerdetails__matlist" *ngIf="(guestStandbyList.asObservable() | async)?.length == 0">
                  <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color customerdetails__no-reservations">
                    <span class="customerdetails__empty-reservations seat-no-content-found">{{ 'noStandby' | translate }}</span>
                  </mat-list-item>
                </mat-list>
              </div>
                <!-- matList for Walkins -->
                <div class="customerdetails__reservations customerdetails__walkins seat-tertiary-text">
                  <div class="customerdetails__mat-list-header">
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__ongoing">{{'guestWalkins' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__ongoing">{{'OutletName' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__cover">{{'coversText' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__table">{{'guestBookTableText' | translate}}</label>
                         <label for="personal_preference"
                  class="customerdetails__mat-list-label customerdetails__spent"></label>
                  </div>
                  <!-- <label for="personal_preference"
                    class="customerdetails__mat-list-label customerdetails__table">{{'accordionstatustext' | translate}}</label> -->
                  <div *ngIf="(guestWalkin.asObservable() | async)?.length > 0">
                    <mat-list class="pt-0 customerdetails__matlist" *ngFor="let item of (guestWalkin.asObservable() | async)">
                      <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color">
                        <div class="customerdetails__matlist-item-1 seat-secondary-text">
                          {{(item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate:'LT'}}
                          <span>{{(item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate: cs.settings.value.General.DateFormat }}</span>
                        </div>
                        <div class="customerdetails__matlist-item-6 seat-secondary-text" matTooltipClass="tooltip-class"  title="{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}">{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}</div>
                        <div class="customerdetails__matlist-item-2 seat-secondary-text" >{{item.Size}}</div>
                        <div class="customerdetails__matlist-item-3 seat-secondary-text" matTooltipClass="tooltip-class" title="{{item.TableNames}}">{{item.TableNames}}</div>
                        <!-- <div class="customerdetails__matlist-item-4">{{item.PartyStatus}}</div> -->
                        <div *ngIf="item.CheckIconVisibility" class="customerdetails__matlist-item-4">
                          <img src="assets/images/Cheque_Icon.svg" class="chequeIcon" (click)="ShowOrderDetails(item)" />
                        </div>
                      </mat-list-item>
                      <div class="rs-mr-10 rs-ml-15 customerdetails__mat-icons">
                        <button class="icon-addons rounded-icon customerdetails__actions" *ngIf="item.BookingContactAddonItems?.length"
                        [autoClose]="'outside'" container="body" placement="auto" #addOnForBookingPopover="ngbPopover" container="body"
                        triggers="manual" [ngbPopover]="addOnForBooking"
                        (click)="partyService.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(item)"
                        title="{{'addons' | translate}}"></button>
                        <button class="icon-Auditlog2 rounded-icon customerdetails__actions" title="{{'auditlog' | translate}}" (click)="showPartyAuditLog(item)"></button>
                        <button class="icon-refund rounded-icon customerdetails__actions" *ngIf=showPaymentButton(item) (click)="ActivityPaymentClick(item)"></button>
                        <button class="icon-edit rounded-icon customerdetails__actions"  *ngIf="_as.restaurantId == item.RestaurantId" (click)="EditWaitlist(item)"></button>
                        <button class="icon-Group-591 rounded-icon"
                                [ngStyle]="{'pointer-events': item.State == PartyState.NoShowed ? 'none': ''}" [disabled]="item.State == PartyState.NoShowed"
                                (click)="cancelParty(item)" *ngIf="_as.restaurantId == item.RestaurantId"></button>
                                  <span *ngIf="item && item.Feedbacks && item.Feedbacks.length">
                                    <img *ngIf="(RolesAndPermissionsType?.ViewFeedback | rolesAndPermission)" alt="" src="assets/images/feedback_icon.svg" class="customerdetails__feedback-icon" [ngClass]="{'feedback-taken' : item.Feedbacks[0]?.StatusCode == 3, 'feedback-todo': item.Feedbacks[0]?.StatusCode != 3}" (click)="ShowFeedbackDetails(contactDetails, null,item)"/>
                                  </span>
                      </div>
                    </mat-list>
                  </div>
                  <mat-list class="pt-0 customerdetails__matlist" *ngIf="(guestWalkin.asObservable() | async)?.length == 0">
                    <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color customerdetails__no-reservations">
                      <span class="customerdetails__empty-reservations seat-no-content-found">{{ 'noWalkins' | translate }}</span>
                    </mat-list-item>
                  </mat-list>
                </div>
                <!-- matList for PastWalkins -->
                <div class="customerdetails__reservations customerdetails__past-walkins seat-tertiary-text">
                  <div class="customerdetails__mat-list-header">
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__ongoing-datetime">{{'guestPastWalkins' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__ongoing">{{'OutletName' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__cover">{{'coversText' | translate}}</label>
                  <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__table">{{'guestBookTableText' | translate}}</label>
                         <label for="personal_preference"
                         class="customerdetails__mat-list-label customerdetails__spent">{{'spent' | translate}}</label>
                        </div>
                  <!-- <label for="personal_preference"
                    class="customerdetails__mat-list-label customerdetails__table">{{'accordionstatustext' | translate}}</label> -->
                  <div *ngIf="(guestPastWalkins | async)?.length > 0">
                    <mat-list class="pt-0 customerdetails__matlist" *ngFor="let item of (guestPastWalkins | async)">
                      <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color">
                        <div class="customerdetails__matlist-item-1 seat-secondary-text">
                          {{(item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate:'LT'}}
                          <span>{{(item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate: cs.settings.value.General.DateFormat }}</span>
                        </div>
                        <div class="customerdetails__matlist-item-6 seat-secondary-text" matTooltipClass="tooltip-class"  title="{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}">{{item.RestaurantName ? item.RestaurantName : getRestaurantNameFromId(item.RestaurantId)}}</div>
                        <div class="customerdetails__matlist-item-2 seat-secondary-text">{{item.Size}}</div>
                        <div class="customerdetails__matlist-item-3 seat-secondary-text" matTooltipClass="tooltip-class" title="{{item.TableNames}}">{{item.TableNames}}</div>
                        <!-- <div class="customerdetails__matlist-item-4">{{item.PartyStatus}}</div> -->
                        <!-- {{item | json}} -->
                        <div *ngIf="item.OrderPrice" class="seat-secondary-text customerdetails__matlist-item-5 customerdetails__word-break-class-new" matTooltipClass="tooltip-class" title="{{item.OperationCurrency}}{{item.OrderPrice | number:'1.2-2' }}">
                          {{item.OperationCurrency}}{{item.OrderPrice | number:'1.2-2'}}
                        </div>
                        <div *ngIf="item.CheckIconVisibility" class="customerdetails__matlist-item-4">
                          <img src="assets/images/Cheque_Icon.svg" class="chequeIcon" (click)="ShowOrderDetails(item)" />
                        </div>
                      </mat-list-item>
                      <div class="rs-mr-10 rs-ml-10 customerdetails__mat-icons">
                        <button class="icon-addons rounded-icon customerdetails__actions" *ngIf="item.BookingContactAddonItems?.length"
                        [autoClose]="'outside'" container="body" placement="auto" #addOnForBookingPopover="ngbPopover" container="body"
                        triggers="manual" [ngbPopover]="addOnForBooking"
                        (click)="partyService.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(item)"
                        title="{{'addons' | translate}}"></button>
                        <button class="icon-Auditlog2 rounded-icon customerdetails__actions" title="{{'auditlog' | translate}}" (click)="showPartyAuditLog(item)"></button>
                        <button class="icon-refund rounded-icon customerdetails__actions" *ngIf=showPaymentButton(item) (click)="ActivityPaymentClick(item)"></button>
                        <button *ngIf="showEditForPastReservation(item) && _as.restaurantId == item.RestaurantId" class="icon-edit rounded-icon customerdetails__actions" (click)="EditWaitlist(item,true)"></button>
                          <span *ngIf="item && item.Feedbacks && item.Feedbacks.length">
                            <img *ngIf="( RolesAndPermissionsType?.ViewFeedback | rolesAndPermission)" src="assets/images/feedback_icon.svg" class="customerdetails__feedback-icon" [ngClass]="{'feedback-taken' : item.Feedbacks[0]?.StatusCode == 3, 'feedback-todo': item.Feedbacks[0]?.StatusCode != 3}" (click)="ShowFeedbackDetails(contactDetails,null,item)"/>
                          </span>
                        </div>
                      </mat-list>
                    </div>
                    <mat-list class="pt-0 customerdetails__matlist" *ngIf="(guestPastWalkins.asObservable() | async)?.length == 0">
                      <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color customerdetails__no-reservations">
                        <span class="customerdetails__empty-reservations seat-no-content-found">{{ 'noPastWalkins' | translate }}</span>
                      </mat-list-item>
                    </mat-list>
                  </div>
                </div>
                <div *ngIf="activityProperty && ((guestFutureReservation.asObservable() | async)?.length != 0 || (guestOngoingReservation.asObservable() | async)?.length != 0 || (guestPastReservation.asObservable() | async)?.length != 0)">
                  <!--Ongoing Activities-->
                  <div class="customerdetails__activity customerdetails__reservations customerdetails__upcoming seat-tertiary-text">
                    <label for="personal_preference"
                          class="customerdetails__mat-list-label customerdetails__ongoing ">{{'OngoingActvities' | translate}}</label>
                  </div>
                  <div *ngIf="(guestOngoingReservation.asObservable() | async)?.length > 0">
                    <mat-list class="pt-0 customerdetails__matlist" *ngFor="let item of (guestOngoingReservation.asObservable() | async)">
                      <mat-list-item class="mt-1 mb-1 rs-Roboto-Medium-14 activity seat-secondary-bg-color">
                        <div class="activity__name">
                          {{!item.SpecialMeal ? 'Open Booking' : item.SpecialMealName}}
                          {{item.Size ? ', ' : ''}}&nbsp;
                          <span *ngIf="item.Size" class="icon icon-add-contact"></span>
                          {{item.Size || ''}}
                        </div>
                        <div class="activity__time">
                          <span *ngIf="item.SpecialMeal && item.SpecialMeal.ClassType === classType.Class">
                            {{item.SpecialMeal.StartDate | localizedDate: cs.settings.value.General.DateFormat }} - {{item.SpecialMeal.EndDate | localizedDate: cs.settings.value.General.DateFormat }}
                          </span>
                          <span *ngIf="item.SpecialMeal && item.SpecialMeal.ClassType === classType.Session">
                            {{item.BookedSessions[0].BookedDate | localizedDate: cs.settings.value.General.DateFormat }} {{" " + item.SeatingTime | localizedDate:'LT'}} - {{item.DepartureTime | localizedDate:'LT'}}
                          </span>
                          <span *ngIf="item.SpecialMeal && item.SpecialMeal.ClassType === classType.Class">
                            | {{!item.SpecialMeal.GroupName ? (item | getGroupName : item) : item.SpecialMeal.GroupName}}
                          </span>
                          <span *ngIf="item.SpecialMeal && item.SpecialMeal.ClassType === classType.Session">
                            | {{'Numberofsessions' | translate}} : {{item.BookedSessions.length}}
                          </span>
                          <span *ngIf="!item.SpecialMeal">
                           {{ item.ReservedFor | localizedDate: cs.settings.value.General.DateFormat}} <span class="ml-2">{{ item.ReservedFor | localizedDate:'LT'}} - {{item.DepartureTime | localizedDate:'LT'}}</span>
                          </span>
                        </div>
                        <div class="activity__location" *ngIf="item.TableNames && item.TableNames.length">
                          <span class="icon icon-placeholder"></span>
                          {{item.TableNames[0]}}  <span class="activity__more-locations" *ngIf="item.TableNames.length > 1">+{{item.TableNames.length - 1}}</span>
                        </div>
                        <div class="activity__actions" [class.fullWidth]="!item.TableNames.length">
                          <!-- <div class="activity__edit" (click)="viewOrEditBooking(item)">{{'view' | translate}} / {{'edit' | translate}}</div> -->
                          <div class="activity__audit" (click)="showPartyAuditLog(item)">    
                            <!-- <span style="margin: 0 20px;">|</span> -->
                            {{'Audit' | translate}}
                          </div>
                          <div *ngIf="item.WaiverFormURL != null" class="activity__audit" (click)="showPartyWaiverForm(item)">    
                            <span style="margin: 0 20px;">|</span>
                            {{'waiverForm' | translate}}
                          </div>
                          <div class="activity__payment">
                            <ng-container *ngIf="(item | partyPaymentState) as paymentState">
                              <span style="margin: 0 10px;" *ngIf="paymentState!=='NA'">|</span>
                              <span *ngIf="paymentState!=='NA'"
                                class="activity__payment--label payment-{{paymentState}}"
                                [autoClose]="'outside'" container="body"
                                placement="auto" [ngbPopover]="financialStatusPopover" (click)="financialDetails(item)">
                                {{ paymentState | translate }}
                              </span>
                            </ng-container>
                            <span style="margin: 0 15px;">|</span>
                            <div class="user-card__status user-card__status--{{item.State}}">
                              {{'Status_'+PartyState[item.State]
                              | translate}}</div>
                          </div>
                          <div class="activity__feedback" *ngIf="item && item.Feedbacks && item.Feedbacks.length">
                            <span *ngIf="(  RolesAndPermissionsType?.ViewFeedback | rolesAndPermission)" style="margin: 0 20px;" >|</span>
                            <span>
                              <img  *ngIf="(  RolesAndPermissionsType?.ViewFeedback | rolesAndPermission)" src="assets/images/feedback_icon.svg" class="customerdetails__feedback-icon" [ngClass]="{'feedback-taken' : item.Feedbacks[0]?.StatusCode == 3, 'feedback-todo': item.Feedbacks[0]?.StatusCode != 3}" (click)="ShowFeedbackDetails(contactDetails, guestPastReservation.asObservable(),item)"/>
                            </span>
                          </div>
                          <div class="activity__feedback" *ngIf="(item | showRecurringIcon : item)">
                            <span style="margin: 0 20px;" >|</span>
                            <span>
                              <img  src="assets/images/Recurring.svg"/>
                            </span>
                          </div>
                          <div *ngIf="item.CheckIconVisibility" class="customerdetails__matlist-item-4">
                            <img src="assets/images/Cheque_Icon.svg" class="chequeIcon" (click)="ShowOrderDetails(item)" />
                          </div>
                        </div>
                      </mat-list-item>
                    </mat-list>
                  </div>
                  <mat-list class="pt-0 customerdetails__matlist" *ngIf="!(guestOngoingReservation.asObservable() | async) || (guestOngoingReservation.asObservable() | async)?.length == 0">
                    <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color customerdetails__no-reservations">
                      <span class="customerdetails__empty-reservations seat-no-content-found">{{ 'noOngoingActivities' | translate }}</span>
                    </mat-list-item>
                  </mat-list>
                  <!--Future Activities-->
                  <div class="customerdetails__activity customerdetails__reservations customerdetails__upcoming seat-tertiary-text">
                    <label for="personal_preference"
                          class="customerdetails__mat-list-label customerdetails__ongoing ">{{'UpcomingActivities' | translate}}</label>
                  </div>
                  <div *ngIf="(guestFutureReservation.asObservable() | async)?.length > 0">
                    <mat-list class="pt-0 customerdetails__matlist" *ngFor="let item of (guestFutureReservation.asObservable() | async)">
                      <mat-list-item class="mt-1 mb-1 rs-Roboto-Medium-14 activity seat-secondary-bg-color">
                        <div class="activity__name">
                          {{!item.SpecialMeal ? 'Open Booking' : item.SpecialMealName}}
                          {{item.Size ? ', ' : ''}}&nbsp;
                          <span *ngIf="item.Size" class="icon icon-add-contact"></span>
                          {{item.Size || ''}}
                        </div>
                        <div class="activity__time">
                          <span *ngIf="item.SpecialMeal">
                            {{item.StartDate | localizedDate: cs.settings.value.General.DateFormat }} - {{item.EndDate | localizedDate: cs.settings.value.General.DateFormat }}
                          </span>
                          <span *ngIf="item.SpecialMeal && item.SpecialMeal.ClassType === classType.Class">
                            | {{!item.SpecialMeal.GroupName ? (item | getGroupName : item) : item.SpecialMeal.GroupName}}
                          </span>
                          <span *ngIf="item.SpecialMeal && item.SpecialMeal.ClassType === classType.Session">
                            | {{'Numberofsessions' | translate}} : {{item.BookedSessions.length}}
                          </span>
                          <span *ngIf="!item.SpecialMeal">
                            {{item.ReservedFor | localizedDate: cs.settings.value.General.DateFormat }} <span class="ml-2">{{item.ReservedFor | localizedDate:'LT'}} - {{item.DepartureTime | localizedDate:'LT'}}</span>
                          </span>
                        </div>
                        <div class="activity__location" *ngIf="item.TableNames && item.TableNames.length">
                          <span class="icon icon-placeholder"></span>
                          {{item.TableNames[0]}}  <span class="activity__more-locations" *ngIf="item.TableNames.length > 1">+{{item.TableNames.length - 1}}</span>
                        </div>
                        <div class="activity__actions" [class.fullWidth]="!item.TableNames.length">
                          <div class="activity__edit" (click)="viewOrEditBooking(item)">{{'edit' | translate}}</div>
                          <div class="activity__audit" (click)="showPartyAuditLog(item)">    
                            <span style="margin: 0 20px;">|</span>
                            {{'Audit' | translate}}
                          </div>
                          <div *ngIf="item.WaiverFormURL != null" class="activity__audit" (click)="showPartyWaiverForm(item)">    
                            <span style="margin: 0 20px;">|</span>
                            {{'waiverForm' | translate}}
                          </div>
                          <div class="activity__payment">
                            <ng-container *ngIf="(item | partyPaymentState) as paymentState">
                              <span style="margin: 0 10px;" *ngIf="paymentState!=='NA'">|</span>
                              <span *ngIf="paymentState!=='NA'"
                                class="activity__payment--label payment-{{paymentState}}"
                                [autoClose]="'outside'" container="body"
                                placement="auto" [ngbPopover]="financialStatusPopover" (click)="financialDetails(item)">
                                {{ paymentState | translate }}
                              </span>
                            </ng-container>
                            <span style="margin: 0 15px;">|</span>
                            <div class="user-card__status user-card__status--{{item.State}}">
                              {{'Status_'+PartyState[item.State]
                              | translate}}</div>
                          </div>
                          <div class="activity__feedback" *ngIf="item && item.Feedbacks && item.Feedbacks.length">
                            <span *ngIf="(  RolesAndPermissionsType?.ViewFeedback | rolesAndPermission) " style="margin: 0 20px;">|</span>
                            <span>
                              <img  *ngIf="(  RolesAndPermissionsType?.ViewFeedback | rolesAndPermission)" src="assets/images/feedback_icon.svg" class="customerdetails__feedback-icon" [ngClass]="{'feedback-taken' : item.Feedbacks[0]?.StatusCode == 3, 'feedback-todo': item.Feedbacks[0]?.StatusCode != 3}" (click)="ShowFeedbackDetails(contactDetails, guestPastReservation.asObservable(),item)"/>
                            </span>
                          </div>
                          <div class="activity__feedback" *ngIf="(item | showRecurringIcon : item)">
                            <span style="margin: 0 20px;" >|</span>
                            <span>
                              <img  src="assets/images/Recurring.svg"/>
                            </span>
                          </div>
                          <div *ngIf="item.CheckIconVisibility" class="customerdetails__matlist-item-4">
                            <img src="assets/images/Cheque_Icon.svg" class="chequeIcon" (click)="ShowOrderDetails(item)" />
                          </div>
                        </div>
                      </mat-list-item>
                    </mat-list>
                  </div>
                  <mat-list class="pt-0 customerdetails__matlist" *ngIf="!(guestFutureReservation.asObservable() | async) || (guestFutureReservation.asObservable() | async)?.length == 0">
                    <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color customerdetails__no-reservations">
                      <span class="customerdetails__empty-reservations seat-no-content-found">{{ 'noUpcomingActivities' | translate }}</span>
                    </mat-list-item>
                  </mat-list>
                  <!--Past Activities-->
                  <div class="customerdetails__activity customerdetails__reservations customerdetails__upcoming seat-tertiary-text">
                    <label for="personal_preference"
                          class="customerdetails__mat-list-label customerdetails__ongoing ">{{'PastActivities' | translate}}</label>
                  </div>
                  <div *ngIf="(guestPastReservation.asObservable() | async)?.length > 0">
                    <mat-list class="pt-0 customerdetails__matlist" *ngFor="let item of (guestPastReservation.asObservable() | async)">
                      <mat-list-item class="mt-1 mb-1 rs-Roboto-Medium-14 activity seat-secondary-bg-color">
                        <div class="activity__name">
                          {{!item.SpecialMeal ? 'Open Booking' : item.SpecialMealName}}
                          {{item.Size ? ', ' : ''}}&nbsp;
                          <span *ngIf="item.Size" class="icon icon-add-contact"></span>
                          {{item.Size || ''}}
                        </div>
                        <div class="activity__time">
                          <span *ngIf="item.SpecialMeal">
                            {{item.StartDate | localizedDate: cs.settings.value.General.DateFormat }} - {{item.EndDate | localizedDate: cs.settings.value.General.DateFormat }}
                          </span>
                          <span *ngIf="item.SpecialMeal && item.SpecialMeal.ClassType === classType.Class">
                            | {{!item.SpecialMeal.GroupName ? (item | getGroupName : item) : item.SpecialMeal.GroupName}}
                          </span>
                          <span *ngIf="item.SpecialMeal && item.SpecialMeal.ClassType === classType.Session">
                            | {{'Numberofsessions' | translate}} : {{item.BookedSessions.length}}
                          </span>
                          <span *ngIf="!item.SpecialMeal">
                           {{item.ReservedFor | localizedDate: cs.settings.value.General.DateFormat}} <span class="ml-2">{{ item.ReservedFor | localizedDate:'LT'}} - {{item.DepartureTime | localizedDate:'LT'}}</span>
                          </span>
                        </div>
                        <div class="activity__location" *ngIf="item.TableNames && item.TableNames.length">
                          <span class="icon icon-placeholder"></span>
                          {{item.TableNames[0]}}  <span class="activity__more-locations" *ngIf="item.TableNames.length > 1">+{{item.TableNames.length - 1}}</span>
                        </div>
                        <div class="activity__actions" [class.fullWidth]="!item.TableNames.length">
                          <!-- <div class="activity__edit" (click)="viewOrEditBooking(item)">{{'edit' | translate}}</div> -->
                          <div class="activity__audit" (click)="showPartyAuditLog(item)">    
                            <!-- <span style="margin: 0 20px;">|</span> -->
                            {{'Audit' | translate}}
                          </div>
                          <div *ngIf="item.WaiverFormURL != null" class="activity__audit" (click)="showPartyWaiverForm(item)">    
                            <span style="margin: 0 20px;">|</span>
                            {{'waiverForm' | translate}}
                          </div>
                          <div class="activity__payment">
                            <ng-container *ngIf="(item | partyPaymentState) as paymentState">
                              <span style="margin: 0 10px;" *ngIf="paymentState!=='NA'">|</span>
                              <span *ngIf="paymentState!=='NA'"
                                class="activity__payment--label payment-{{paymentState}}"
                                [autoClose]="'outside'" container="body"
                                placement="auto" [ngbPopover]="financialStatusPopover" (click)="financialDetails(item)">
                                {{ paymentState | translate }}
                              </span>
                            </ng-container>
                            <span style="margin: 0 15px;">|</span>
                            <div class="user-card__status user-card__status--{{item.State}}">
                              {{'Status_'+PartyState[item.State]
                              | translate}}</div>
                          </div>
                          <div class="activity__feedback" *ngIf="item && item.Feedbacks && item.Feedbacks.length">
                            <span *ngIf="(  RolesAndPermissionsType?.ViewFeedback | rolesAndPermission) " style="margin: 0 20px;">|</span>
                            <span *ngIf="(  RolesAndPermissionsType?.ViewFeedback | rolesAndPermission)">
                              <img  src="assets/images/feedback_icon.svg" class="customerdetails__feedback-icon" [ngClass]="{'feedback-taken' : item.Feedbacks[0]?.StatusCode == 3, 'feedback-todo': item.Feedbacks[0]?.StatusCode != 3}" (click)="ShowFeedbackDetails(contactDetails, guestPastReservation.asObservable(),item)"/>
                            </span>
                          </div>
                          <div class="activity__feedback" *ngIf="(item | showRecurringIcon : item)">
                            <span style="margin: 0 20px;" >|</span>
                            <span>
                              <img  src="assets/images/Recurring.svg"/>
                            </span>
                          </div>
                          <div *ngIf="item.CheckIconVisibility" class="customerdetails__matlist-item-4">
                            <img src="assets/images/Cheque_Icon.svg" class="chequeIcon" (click)="ShowOrderDetails(item)" />
                          </div>
                        </div>
                      </mat-list-item>
                    </mat-list>
                  </div>
                  <mat-list class="pt-0 customerdetails__matlist" *ngIf="!(guestPastReservation.asObservable() | async) || (guestPastReservation.asObservable() | async)?.length == 0">
                    <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color customerdetails__no-reservations">
                      <span class="customerdetails__empty-reservations seat-no-content-found">{{ 'noPastActivities' | translate }}</span>
                    </mat-list-item>
                  </mat-list>
                </div>
                <div class="customerdetails__no-activities seat-no-content-found" *ngIf="(guestFutureReservation.asObservable() | async)?.length == 0 && (guestPastReservation.asObservable() | async)?.length == 0 &&
                  (guestWalkin.asObservable() | async)?.length == 0 && (guestPastWalkins.asObservable() | async)?.length == 0 && (guestOngoingReservation.asObservable() | async)?.length == 0">
                  {{'noReservationsAvailable' | translate}}
                </div>
              </div>
              <!-- </div> -->
              <!-- </div> -->
            
        </div>
      </div>
    </mat-tab>
  
      <!-- Guest tags tab -->
      <mat-tab label="{{'guestNotesText' | translate}}">
        <div class="customerdetails__tags rs-pt-15" *ngIf="tagsList?.length > 0 || customTagsList?.length">
          <!-- <mat-chip class="customerdetails__tags-list m-2 rs-Roboto-Regular-14" *ngFor="let tag of tagsList;let i=index" (click)="selectedMatChip(tag)"
            [ngClass]="{'customerdetails__selected-tag': tag.setSelected}">
            <span class="{{tag.icon}} pr-1"></span><span class="rs-Roboto-Regular-16">{{tag.name | uppercase}}</span>
            </mat-chip> -->
            <!-- <dynamic-form [config]="chipConfig" #form="dynamicForm" >
            </dynamic-form> --><!--Commented for user story #11622-->
              <div *ngIf="isCustomTagsAvailable">
                <span class="seat-header-three">{{'freeNoteText' | translate}}</span>
                <div>
                  <div *ngFor="let note of customTagsList"
                    class="customerdetails__tags-view seat-secondary-bg-color seat-tertiary-text">
                    {{note.Text}}
                  </div>
                </div>
              </div>
              <div *ngFor="let notesCategory of tagsList">
                <span class="seat-header-three" *ngIf="notesCategory?.notes?.length > 0">{{notesCategory.Category}}</span>
                <div>
                  <div *ngFor="let note of notesCategory.notes"
                    class="customerdetails__tags-view seat-secondary-bg-color seat-tertiary-text">
                    <i class="icon-{{note.Icon ? note.Icon : 'Default'}} rs-mr-5"></i>
                    {{note.Name}}
                  </div>
                </div>
              </div>
          </div>
          <div class="customerdetails__guest-tags" *ngIf="tagsList?.length == 0 && customTagsList?.length == 0">
            <span class="customerdetails__empty-tags seat-no-content-found">{{'noGuestNotes' | translate}}</span>
          </div>
        </mat-tab>
  
    <!-- Guest preferences tab -->
    <mat-tab label="{{'preferences' | translate}}">
        <div class="customerdetails__guest-preference">
          <label for="personal_preference"
                 class="customerdetails__mat-list-label seat-tertiary-text customerdetails__preference">{{'guestBookPersonalPreferenceText' | translate}}</label>
          <div>
            <mat-list class="pt-0 customerdetails__matlist">
              <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color float-left">
                <div class="w-100">
                  <div class="float-left w-50 seat-tertiary-text">{{'guestBookTableNumberText' | translate}}</div>
                  <div class="float-left w-50 customerdetails__word-break-class seat-secondary-text"
                       [ngClass]="{'customerdetails__text-black': !preferredTableName }">{{preferredTableName ? preferredTableName : ( 'nill' | translate)}}</div>
                </div>
              </mat-list-item>
              <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color float-left">
                <div class="w-100">
                  <div class="float-left w-50 seat-tertiary-text">{{"guestBookServerText" | translate}}</div>
                  <div class="float-left w-50 customerdetails__word-break-class seat-secondary-text"
                       [ngClass]="{'customerdetails__text-black': !preferredServerName }">{{ preferredServerName ? preferredServerName : ('nill' | translate)}}</div>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
  
          <label for="commn_preference"
                 class="customerdetails__mat-list-label seat-tertiary-text customerdetails__preference">{{'guestBookCommunicationPreferenceText' | translate}}</label>
          <mat-list class="pt-0 customerdetails__matlist mb-2" *ngFor="let preferredCommunication of communicationList;let i=index;trackBy: trackByFn">
            <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color">
              <div class="w-100 customerdetails__communication-list">
                <div class="float-left customerdetails__communication-preference seat-tertiary-text">{{preferredCommunication.Name | translate}}</div>
                <!-- <label class="customerdetails__switch">
                  <input type="checkbox" name="loginkeeping" id="loginkeeping" class="customerdetails__switch-toggle"
                         [disabled]="preferredCommunication.isDisabled" [checked]="preferredCommunication.setSelected"
                         (click)="communicationSelected(preferredCommunication)">
                  <span class="customerdetails__slider customerdetails__round"
                        [ngClass]="[preferredCommunication.setSelected == true ? 'customerdetails__bg-green': 'customerdetails__bg-red']">
                  </span>
                </label> -->
                <div class="customerdetails__toggleSwitch" (click)="communicationSelected(preferredCommunication)">
                <dynamic-form [config]="preferredCommunication | switchInputFormater:'toggleSwitch': preferredCommunication.setSelected" #form="dynamicForm">
                </dynamic-form>
                </div>
              </div>
            </mat-list-item>
          </mat-list>
  
          <label for="personal_preference" *ngIf="customFieldsList?.length > 0"
                 class="customerdetails__mat-list-label seat-tertiary-text customerdetails__preference">{{'guestBookCustomFields' | translate}}</label>
          <div>
            <mat-list class="pt-0 customerdetails__matlist">
              <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color float-left" *ngFor="let customField of customFieldsList">
                <div class="w-100">
                  <div class="float-left w-50 seat-tertiary-text">{{customField.FieldName}}</div>
                  <div class="float-left w-50 customerdetails__word-break-class seat-secondary-text"
                       [ngClass]="{'customerdetails__text-black': !preferredTableName }">{{customField.FieldValue ? customField.FieldValue : ( 'nill' | translate)}}</div>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
    </mat-tab>
  
    <!-- Billing -->
    <mat-tab label="{{'billing' | translate}}" *ngIf="!activityProperty">
      <div class="customerdetails__billing">
          <div class="rs-mt-16 rs-mr-10">
            <div class="customerdetails__bill-labels seat-tertiary-text">
              <label>{{'covers' | translate}}</label>
              <label>{{'popUpDate' | translate}}</label>
              <label>{{'timeText' | translate}}</label>
              <label>{{'charges' | translate}}</label>
              <label>{{'accordionstatustext' | translate}}</label>
              <div *ngIf="billingPartyList?.length > 0">
                <mat-list class="pt-0 customerdetails__matlist" *ngFor="let item of billingPartyList">
                  <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color">
                    <div class="customerdetails__matlist-item-1 seat-secondary-text matlist-item-1-billing">
                      <img *ngIf="item.SpecialMealBadgeVisibility == true" src="assets/images/Seat_Exclamation.svg" style="height: 17px; width: 15px; cursor: pointer;" (click)="ShowSpecialMeal(item)" />
                      {{item.Size}}
                    </div>
                    <div class="customerdetails__matlist-item-2 seat-secondary-text">{{(item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate: cs.settings.value.General.DateFormat}}</div>
                    <div class="customerdetails__matlist-item-3 seat-secondary-text">{{ (item.ReservedFor ? item.ReservedFor : item.ArrivedAt) | localizedDate:'LT'}}</div>
                    <div class="customerdetails__matlist-item-4 seat-secondary-text" *ngIf="item.PriceAmount">{{item.OperationCurrency}}{{ item.PriceAmount.toFixed(2)}}</div>
                    <div class="customerdetails__matlist-item-4 seat-secondary-text" *ngIf="item.PriceAmount==null"></div>
                    <div class="customerdetails__matlist-item-5 seat-secondary-text">{{item.PartyStatus}}</div>
                  </mat-list-item>
                  <div class="rs-mr-10 rs-ml-10 customerdetails__mat-icons">
                    <button class="icon-Auditlog2 rounded-icon customerdetails__actions" title="{{'auditlog' | translate}}" (click)="showPartyAuditLog(item)"></button>
                    <button class="icon-refund rounded-icon customerdetails__actions" (click)="PaymentIconClick(item)"></button>
                  </div>
                </mat-list>
              </div>
              <mat-list class="pt-0 customerdetails__matlist" *ngIf="billingPartyList?.length == 0">
                <mat-list-item class="mt-1 mb-1 customerdetails__list-item seat-secondary-bg-color customerdetails__no-reservations">
                  <span class="customerdetails__empty-reservations seat-no-content-found">{{ 'noReservations' | translate }}</span>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
      </div>
    </mat-tab>
  
    <!-- Commented for Future reference -->
    <!-- Guest chat history tab -->
    <!-- <mat-tab label="Chat History">
      <div class="customerdetails__chat-history">
          <ul class="customerdetails__chat-details"
            *ngIf="partymessagesTypePhone?.length > 0 ||  partymessagesTypeEmail?.length > 0 || partymessagesTypesms?.length > 0">
  
            <li class="customerdetails__timeline-inverted" *ngIf="partymessagesTypePhone?.length > 0">
              <div class="customerdetails__timeline-badge">
                <i class="icon-ic_call_24px"></i>
                <span class="customerdetails__call-notifications">
                  <span class="customerdetails__total-missed-calls">4</span>
                </span>
              </div>
              <div class="customerdetails__timeline-panel">
                <div class="customerdetails__timeline-heading">
                  <h4 class="customerdetails__timeline-title">{{'missedCallText' | translate}}</h4>
                </div>
                <div class="customerdetails__timeline-body mt-2">
                  <div class="customerdetails__timeline-heading" *ngFor="let phone of partymessagesTypePhone">
                    <span><small class="customerdetails__phone-number-display"><i
                          class="glyphicon gliphicon-time"></i>{{_as.formatPhoneNumber(phone.phoneNumber)}}</small></span>
                    <span class="float-right customerdetails__timeline_date rs-Roboto-Regular-12">{{phone.CreatedAt | localizedDate: 'D MMM yyy'}}</span>
                  </div>
                </div>
              </div>
            </li>
  
            <li class="customerdetails__timeline-inverted" *ngIf="partymessagesTypeEmail?.length > 0">
              <div class="customerdetails__timeline-badge "><i class=" icon-ic_mail_outline_24px"></i></div>
              <div class="customerdetails__timeline-panel">
                <div class="customerdetails__timeline-heading">
                  <h4 class="customerdetails__timeline-title">{{'emailMessageText' | translate}}</h4>
                </div>
                <div class="customerdetails__mat-list" *ngFor="let email of partymessagesTypeEmail">
                  <div *ngFor="let chatDetails of email.chatHistory" class="customerdetails__message-history">
                    <div class="customerdetails__timeline-body mt-2 customerdetails__gray_bg" *ngIf="chatDetails.IsIncoming == true">
                      <p>
                        {{chatDetails.Text}}
                      </p>
                    </div>
                    <div class="customerdetails__timeline-body mt-2 customerdetails__blue_bg" *ngIf="chatDetails.IsIncoming == false">
                      <p class="customerdetails__text-message">
                        {{chatDetails.Text}}
                      </p>
                    </div>
                    <i class="icon-Path-501 customerdetails__down-arrow" *ngIf="chatDetails.IsIncoming == true"></i>
                    <i class="icon-Path-502 customerdetails__up-arrow" *ngIf="chatDetails.IsIncoming == false"></i>
                    <div class="rs-Roboto-Regular-12">{{chatDetails.CreatedAt | localizedDate:'LT'}}</div>
                  </div>
                  <span class="customerdetails__message-date rs-Roboto-Regular-12">{{email.chatDate | localizedDate: 'D MMM yyy'}}</span>
                </div>
  
              </div>
            </li>
  
            <li class="customerdetails__timeline-inverted" *ngIf="partymessagesTypesms?.length > 0">
              <div class="customerdetails__timeline-badge "><i class="icon-ic_chat_bubble_outline_24px2"></i></div>
              <div class="customerdetails__timeline-panel">
                <div class="customerdetails__timeline-heading button-row">
                  <h4 class="customerdetails__timeline-title">{{'smsMessageText' | translate}}</h4>
                </div>
                <div class="customerdetails__mat-list" *ngFor="let sms of partymessagesTypesms">
                  <div *ngFor="let chatmsgs of sms.data" class="customerdetails__message-history">
                    <div class="customerdetails__timeline-body mt-2 customerdetails__gray_bg" *ngIf="chatmsgs.IsIncoming == true">
                      <p class="customerdetails__text-message">
                        {{chatmsgs.Text}}
                      </p>
                    </div>
                    <div class="customerdetails__timeline-body mt-2 customerdetails__green_bg" *ngIf="chatmsgs.IsIncoming == false">
                      <p class="customerdetails__text-message">
                        {{chatmsgs.Text}}
                      </p>
                    </div>
                    <i class="icon-Path-501 customerdetails__down-arrow" *ngIf="chatmsgs.IsIncoming == true"></i>
                    <i class="icon-Path-502 customerdetails__up-arrow" *ngIf="chatmsgs.IsIncoming == false"></i>
                    <div class="rs-Roboto-Regular-12">{{chatmsgs.CreatedAt | localizedDate:'LT'}}</div>
                  </div>
                  <span class="customerdetails__message-date rs-Roboto-Regular-12">{{sms.chatDate | localizedDate: 'D MMM yyy'}}</span>
                </div>
              </div>
            </li>
          </ul>
          <ul class="customerdetails__no-chats rs-Roboto-Medium-14 rs-mt-15"
            *ngIf="partymessagesTypePhone?.length == 0 && partymessagesTypeEmail?.length == 0 && partymessagesTypesms?.length == 0">
            <li>
              <span>{{'noChats' | translate}}</span>
            </li>
          </ul>
      </div>
    </mat-tab> -->
  </mat-tab-group>
  <!-- </section> -->
  </ng-scrollbar>
</div>
  <div class="guestdetails__create-reservation">
    <activities-app-button [buttontype]="reservationButton" (valueChange)="actionOnClick()"></activities-app-button>
  </div>

  <div>
    <ng-template #financialStatusPopover>
      <app-financial-status-popup [partyData]="financialPartyData"></app-financial-status-popup>
    </ng-template>
  </div>


<!-- Add-on Popover -->
<ng-template #addOnForBooking>
  <app-add-on-popover [party]="partyWithAddon"></app-add-on-popover>
 </ng-template>