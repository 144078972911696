
import { ActionTypeEnum, aligment, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { UI, API, CGtablecontent } from './combine-guest-ui-model';
import { UI as GuestUI } from './guest-model';
import { Localization } from '../../localization/localization';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { AlertAction } from 'src/app/common/shared/shared.modal';


export abstract class combineGuestRecordBusiness {
    captions: any;
    contactTypes: any;   
   
    isViewOnly: boolean;

    constructor(public localization: Localization
    ) {
        this.captions = this.localization.captions;

    }

    getTableOptions() {
        return {
            defaultsortingColoumnKey: 'firstName',
            defaultSortOrder: SorTypeEnum.asc,
            showTotalRecords: false,
            columnFreeze: {
                firstColumn: true,
                lastColumn: false
            }
        };
    }

   getUserAccess (){   
     return true;
   }

   public async combineGuestRecords (primarydata, secondarydata):Promise<boolean> {
    return false;
}

  public folioPostUpdate(primarydata, secondarydata) {
    return null;
  }


  public async getGuests(searchCriteria: UI.GuestSearchFields): Promise<CGtablecontent[]> {    return [];  }

  public async getGuestsforEmptyFilter(filterName: string): Promise<CGtablecontent[]> {    return [];  }

  public uiGridMapper(GuestAPIModel: API.Guest, seg1: any[] ,
                       seg2: any[], org: any[], guesttype: any[]) {
                         //uiGridMapper
  }

  public getEmaiId(guestProfileContact: any) {
    return null;
  }
  public getPhoneNo(guestphoneContact: any) {
    return null;
  }
  getPhoneType() {
    return null;
  }
  getEmailType() {
    return null;
  }

  public apiMapperGuestSearchFields(GuestSearchFieldsUIModel: UI.GuestSearchFields): API.GuestSearchFields {

    return {
      lastName: GuestSearchFieldsUIModel.lastName,
      firstName: GuestSearchFieldsUIModel.firstName,
      cityName: GuestSearchFieldsUIModel.cityName,
      stateCode: GuestSearchFieldsUIModel.stateCode,
      postalCode: GuestSearchFieldsUIModel.postalCode,
      stateName: GuestSearchFieldsUIModel.stateName,
      county: GuestSearchFieldsUIModel.county,
      countryName: GuestSearchFieldsUIModel.countryName,
      nationality: GuestSearchFieldsUIModel.nationality,
      phone: GuestSearchFieldsUIModel.phone,
      socialSecurityNumber: GuestSearchFieldsUIModel.socialSecurityNumber,
      emailAddress: GuestSearchFieldsUIModel.emailAddress,
      driversLicense: GuestSearchFieldsUIModel.driversLicense,
      playerId: GuestSearchFieldsUIModel.playerId,
      memberTypeId: GuestSearchFieldsUIModel.memberTypeId,
      includeInactive: false,
      title:GuestSearchFieldsUIModel.title,
      address:GuestSearchFieldsUIModel.address,
      isFilterApplied:GuestSearchFieldsUIModel.isFilterApplied,
      PMSGuestSearchCriteria: this.mapPMSSearchCriteria(GuestSearchFieldsUIModel),
      AdditionalSearchCriteria: this.mapAdditionalSearchCriteria(GuestSearchFieldsUIModel)
    } as API.GuestSearchFields;
  }

  mapAdditionalSearchCriteria(GuestSearchFieldsUIModel: UI.GuestSearchFields) {
    return {
      companyName: GuestSearchFieldsUIModel.additionalSearch.companyName,
      guestID: GuestSearchFieldsUIModel.additionalSearch.guestID,
      clubMember: GuestSearchFieldsUIModel.additionalSearch.clubMember,
      memberNumber: GuestSearchFieldsUIModel.additionalSearch.memberNumber,
      memberType: GuestSearchFieldsUIModel.additionalSearch.memberType,
      memberCategory: GuestSearchFieldsUIModel.additionalSearch.memberCategory,
      ownerRoomNo: GuestSearchFieldsUIModel.additionalSearch.ownerRoomNo,
      ownerContractNo: GuestSearchFieldsUIModel.additionalSearch.ownerContractNo,
      ownerARNo: GuestSearchFieldsUIModel.additionalSearch.ownerARNo,
      createdOn: GuestSearchFieldsUIModel.additionalSearch.createdOn,
      updatedOn: GuestSearchFieldsUIModel.additionalSearch.updatedOn
    } as API.GuestAdditionalSearchCriteria;
  }

  mapPMSSearchCriteria(GuestSearchFieldsUIModel: UI.GuestSearchFields) {
     /**
   * mapPMSSearchCriteria
   */
  }

  headerGenerator() {
    return [
      {
        displayName: '',
        key: 'selected',
        searchable: false,
        sorting: false,
        templateName: ActionTypeEnum.radioButton,
        radioKey: 'id'
      },
      {
      displayNameId: 'tbl_hdr_lastName',
        displayName: this.captions.tbl_hdr_lastName,
        key: 'lastname',
        searchable: false,
        sorting: true,
        sortingKey: 'lastname',
      },
      {
      displayNameId: 'tbl_hdr_firstName',
        displayName: this.captions.tbl_hdr_firstName,
        key: 'firstname',
        searchable: false,
        sorting: true,
        sortingKey: 'firstname',
      },
      {
      displayNameId: 'tbl_hdr_title',
        displayName: this.captions.tbl_hdr_title,
        key: 'title',
        searchable: false,
        sorting: true,
        sortingKey: 'title',
      },
     
      {
      displayNameId: 'tbl_hdr_address',
        displayName: this.captions.tbl_hdr_address,
        key: 'address',
        searchable: false,
        sorting: true,
        sortingKey: 'address',
      },
      {
      displayNameId: 'tbl_hdr_postalCode',
        displayName: this.captions.tbl_hdr_postalCode,
        key: 'postalcode',
        searchable: false,
        sorting: true,
        sortingKey: 'postalcode',
        alignment: aligment.right,
      },
      {
      displayNameId: 'tbl_hdr_city',
        displayName: this.captions.tbl_hdr_city,
        key: 'city',
        searchable: false,
        sorting: true,
        sortingKey: 'city',
      },
      {
      displayNameId: 'tbl_hdr_country',
        displayName: this.captions.tbl_hdr_county,
        key: 'county',
        searchable: false,
        sorting: true,
        sortingKey: 'county',
      },
      {
      displayNameId: 'lbl_phoneNumber',
        displayName: this.captions.lbl_phoneNumber,
        key: 'phonenumber',
        searchable: false,
        sorting: true,
        sortingKey: 'phonenumber',
      },
      {
      displayNameId: 'lbl_emailID',
        displayName: this.captions.lbl_emailID,
        key: 'email',
        searchable: false,
        sorting: true,
        sortingKey: 'email',
      },
     
      {
      displayNameId: 'lbl_origin',
        displayName: this.captions.lbl_origin,
        key: 'origin',
        searchable: false,
        sorting: true,
        sortingKey: 'origin',
      },
     
      {
      displayNameId: 'tbl_hdr_state',
        displayName: this.captions.tbl_hdr_state,
        key: 'state',
        searchable: false,
        sorting: true,
        sortingKey: 'state',
      },
      {
      displayNameId: 'lbl_country',
        displayName: this.captions.lbl_country,
        key: 'country',
        searchable: false,
        sorting: true,
        sortingKey: 'country',
      },
      {
      displayNameId: 'lbl_clubMember',
        displayName: this.captions.lbl_clubMember,
        key: 'clubnumber',
        searchable: false,
        sorting: true,
        sortingKey: 'clubnumber',
      },
      {
      displayNameId: 'lbl_memberNumber',
        displayName: this.captions.lbl_memberNumber,
        key: 'membernumber',
        searchable: false,
        sorting: true,
        sortingKey: 'membernumber',
      },
      {
      displayNameId: 'lbl_patronId',
        displayName: this.captions.lbl_patronId,
        key: 'patronid',
        searchable: false,
        sorting: true,
        sortingKey: 'patronid',
      }
    ];
  }
  getConfigureDataList(hdrObj) {
    const objColllist = [];
    hdrObj.forEach(element => {
      if (element.displayName !== '') {
        const obj = {
          show: true,
          disabled: false,
          checked: false,
          displayName: element.displayName,
          key: element.key
        };
        objColllist.push(obj);
      }
    });
    return objColllist;
  }

 getGuestInformationByGuid(Guid: string) : Promise<GuestUI.Guest>{
   return new Promise(null);
  } 
  async combineGuestInfo(guestInfo){
    // combineGuestInfo
  }
  public uiMapper_GuestInformation(GuestAPIModel: API.Guest): GuestUI.Guest {
    const guestItem: GuestUI.Guest = {} as GuestUI.Guest;
    Object.assign(guestItem, GuestAPIModel);

    return guestItem;
  }
  
  

    /**
* Alert popup to show 'Warning' , 'Error' , 'Success'
* @param {string} message  - Content
* @param {AlertType} type  - Type of Popup
* @param {ButtonType} [btnType=ButtonType.Ok] - Button Actions Type( by default 'Ok')
* @param {(result: string, extraParams?: any[]) => void} [callback] - CallBack ( optional )
* @returns - Dialog Reference of the modal with Result of enum AlertAction
* @memberof Utilities
*/
  public showAlert(message: string, type: AlertType, btnType: ButtonType = ButtonType.Ok,
    callback?: (result: AlertAction, extraParams?: any[]) => void, extraParams?: any[], headerText?: string,
    additionalInfo?: { message: string, class: string }, moreOptionsLabel?: string, moreOptions?: any) {

   return null;
  }
}