import { Component, Input, OnInit, ViewChild, ViewEncapsulation, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { cloneDeep } from 'lodash';
import * as DecoupledEditor from 'src/app/common/ckeditor5-build-decoupled-document-master';
import Base64UploaderPlugin from './Base64Upload';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TagKeys, TemplateTags } from 'src/app/retail/shared/shared.modal';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditorComponent implements OnInit, OnDestroy {
  @Input() maxCharacters;
  @Input() customFontSize;
  @Input() customToolBar;
  @ViewChild('editor', { static: false }) editor: CKEditorComponent;
  public config = {
    toolbar: [
      'heading', 'bold', 'italic', 'underline', 'strikethrough', 'fontfamily',
      'fontsize', 'fontcolor', 'fontbackgroundcolor', 'alignment',
      'numberedList', 'bulletedList', 'outdent', 'indent', 'link', 'insertTable', 'imageUpload', 'undo', 'redo','htmlEmbed'],
    extraPlugins: [Base64UploaderPlugin],
    fontColor: {
      colors: this.utils.getColors()
    },
    fontBackgroundColor: {
      colors: this.utils.getColors()
    },
    table: {
      tableProperties: {
        borderColors: this.utils.getColors(),
        backgroundColors: this.utils.getColors()
      },
      tableCellProperties: {
        borderColors:this.utils.getColors(),
        backgroundColors: this.utils.getColors()
      }
    },
    fontFamily: {
      options: [
        'default',
        'Arial, Helvetica, sans-serif',
        'Courier New, Courier, monospace',
        'Georgia, serif',
        'Lucida Sans Unicode, Lucida Grande, sans-serif',
        'Tahoma, Geneva, sans-serif',
        'Trebuchet MS, Helvetica, sans-serif',
        'Verdana, Geneva, sans-serif'
      ]
    },
    fontSize: {
      options: this.utils.getFontSize(),
      supportAllValues: true
    }
  };
  public Editor = DecoupledEditor;
  formGroup: UntypedFormGroup;
  formControlName: string;
  showTagsSection: boolean;
  showCollapse: boolean;
  tags: TemplateTags[];
  isViewOnly: boolean;
  editorValue = '';
  instance: any;
  icon = '';
  captions: any;
  originalTags: TemplateTags[];
  wordPlugin: any;
  exceededCount = 0;
  maxLimitMsg: string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  showSearch: boolean;
  @Output() myOutput = new EventEmitter();
  myContent:any; 

  @Input('input')
  set myInputs(value) {
    this.formGroup = value.formGroup;
    this.formControlName = value.formControlName;
    this.showTagsSection = value.showTags;
    this.showCollapse = value.showCollapse ? value.showCollapse : false;
    this.isViewOnly = value.isViewOnly;
    this.showSearch = value.showSearch;
    if (this.formGroup) {
      const ctrl = this.formGroup.get(this.formControlName);
      this.formGroup.get(this.formControlName).valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
        if (this.maxCharacters && this.wordPlugin && this.wordPlugin.characters > this.maxCharacters) {
          this.exceededCount = this.wordPlugin.characters - this.maxCharacters;
          this.maxLimitMsg = this.localization.replacePlaceholders(this.captions.errMaxCharacterExceeded, ['actual', 'exceededCount'], [this.maxCharacters, this.exceededCount]);
          ctrl.setErrors({ limitExceeded: true });
        } else {
          ctrl.setErrors(null);
        }
      });
    }
  }

  @Input('templateTags')
  set tagInputs(value: TemplateTags[]) {
    this.tags = value;
    this.originalTags = value;
  }
  expandPanel = false;

  constructor(private localization: Localization,private utils : CommonUtilities) {
    this.captions = this.localization.captions;
  }

  ngOnInit() {
    if (this.customToolBar) {
      this.config.toolbar = this.customToolBar;
    }
    if (this.customFontSize) {
      this.config.fontSize = {
        options: this.customFontSize,
        supportAllValues: true
      };
    }
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  setInstance(editor) {
    this.instance = editor;
    this.wordPlugin = editor.plugins.has('WordCount') ? editor.plugins.get('WordCount') : null;
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  elementClick(arg) {

    const properties = arg.properties;

    let fontFamily = '';
    let fontSize = '';
    let fontStyle = '';
    let fontWeight = '';
    let textDecoration = '';

    const currState = this.getPreviousState();

    if (properties && properties.length > 0) {
      const family = properties.find(x => x.key.toLowerCase() === TagKeys.FontFamily);
      fontFamily = family ? family.key.toLowerCase() + ':' + family.value + ';' : ' ';

      const size = properties.find(x => x.key.toLowerCase() === TagKeys.FontSize);
      fontSize = size ? size.key.toLowerCase() + ':' + size.value + 'px;' : ' ';

      const style = properties.find(x => x.key.toLowerCase() === TagKeys.FontStyle);
      fontStyle = style ? style.key.toLowerCase() + ':' + style.value + ';' : ' ';

      const weight = properties.find(x => x.key.toLowerCase() === TagKeys.FontWeight);
      fontWeight = weight ? weight.key.toLowerCase() + ':' + weight.value + ';' : ' ';

      const decoration = properties.find(x => x.key.toLowerCase() === TagKeys.TextDecoration);
      textDecoration = decoration ? decoration.key.toLowerCase() + ':' + decoration.value : ' ';

      const temp = '<p><span style="' + fontSize + fontFamily + fontStyle + fontWeight + textDecoration + '">' + arg.code + '</span>&nbsp;' + currState + '</p>';
      const viewFragment = this.editor.editorInstance.data.processor.toView(temp);
      const modelFragment = this.editor.editorInstance.data.toModel(viewFragment);
      this.editor.editorInstance.model.insertContent(modelFragment);
    } else {
      const appendData = arg.code;
      const selection = this.editor.editorInstance.model.document.selection;
      const range = selection.getFirstRange();
      this.editor.editorInstance.model.change(writer => {
        writer.insert(appendData, range.start);
      });
    }
  }

  closed() {
    this.icon = '';
  }

  opened() {
    this.icon = 'up';
  }

  searchValueChange(e) {
    const data = cloneDeep(this.originalTags);
    const filteredData = data && data.map(x => {
      x.tags = x.tags && x.tags.filter(tags => {
        return tags && tags.name && tags.name.toLowerCase().includes(e.trim().toLowerCase());
      });
      return x;
    });
    this.tags = filteredData;
    this.icon = '';
    this.expandPanel = this.showCollapse;
  }

  private getPreviousState() {
    const commands = this.instance.commands;
    const prop = {
      bold: commands.get('bold') ? commands.get('bold').value : false,
      italic: commands.get('italic') ? commands.get('italic').value : false,
      fontsize: commands.get('fontSize').value ? commands.get('fontSize').value : '14px',
      fontfamily: commands.get('fontFamily').value ? commands.get('fontFamily').value : 'LatoRegular',
      underline: commands.get('underline') ? commands.get('underline').value : false,
      strikethrough: commands.get('strikethrough') ? commands.get('strikethrough').value : false,
      background: commands.get('fontBackgroundColor').value ? commands.get('fontBackgroundColor').value : '#fff',
      color: commands.get('fontColor').value
    };
    let styleState = `font-family:${prop.fontfamily}; font-size: ${prop.fontsize}; background-color: ${prop.background};`;
    if (prop.bold) {
      styleState = styleState.concat('font-weight: bold;');
    }
    if (prop.italic) {
      styleState = styleState.concat('font-style: italic;');
    }
    if (prop.strikethrough || prop.underline) {
      styleState = styleState.concat(`text-decoration:${prop.strikethrough ? 'line-through' : ''} ${prop.underline ? 'underline' : ''};`);
    }
    if (prop.color) {
      styleState = styleState.concat(`color:${prop.color};`);
    }
    const temp = '&nbsp;<span style="' + styleState + '">&nbsp;</span>';
    return temp;
  }

  getColors() {
    return [{
      color: '#000000',
      label: 'Black'
    },
    {
      color: '#4d4d4d',
      label: 'Dim grey'
    },
    {
      color: '#999999',
      label: 'Grey'
    },
    {
      color: '#e6e6e6',
      label: 'Light grey'
    },
    {
      color: '#ffffff',
      label: 'White',
      hasBorder: true
    },
    {
      color: '#e64c4c',
      label: 'Red'
    },
    {
      color: '#e6994c',
      label: 'Orange'
    },
    {
      color: '#e6e64c',
      label: 'Yellow'
    },
    {
      color: '#99e64c',
      label: 'Light green'
    },
    {
      color: '#4ce64c',
      label: 'Green'
    },
    {
      color: '#4ce699',
      label: 'Aquamarine'
    },
    {
      color: '#4ce6e6',
      label: 'Turquoise'
    },
    {
      color: '#4c99e6',
      label: 'Light blue'
    },
    {
      color: '#4c4ce6',
      label: 'Blue'
    },
    {
      color: '#994ce6',
      label: 'Purple'
    }];
  }

  getFontSize() {
    return [
      8
      , 9
      , 10
      , 11
      , 12
      , 14
      , 16
      , 18
      , 20
      , 22
      , 24
      , 26
      , 28
      , 36
      , 48
      , 72
    ];
  }
  ckClick(){  
    this.myOutput.emit(this.myContent);  
 } 
}
