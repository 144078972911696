<div class="reservation-size">
  <span class="reservation-size__select-text reservation-select-label rs-Roboto-Regular-24">{{'waitListPopUpSelectSize'|translate}}</span>
  <img src="{{selectedTableNumberUrl}}" alt="" class=" reservation-size__table-image">
  <div class="reservation-size__available-size">
    <div #buttonView class="reservation-size__predefined-size">
      <button mat-button *ngFor="let count of sizeArray" class="reservation-size__view-size"
        (click)="selectedCount(count)"
        [ngClass]="{'reservation-size__selected-view':count.count == partyService.reservationFormGroup.value.selectedSize}">{{count.count}}</button>
    </div>
  </div>
  <dynamic-form [config]="config" #form="dynamicForm">
  </dynamic-form>
</div>