<div class="consent-message-popup">
    <div *ngIf="showAlert" class="consent-message-popup__show-alert">
        <i class="icon-warning seat-icon-color alert-icon"></i>
      </div>
    <div class="consent-message-popup__validation" *ngIf="validationMessage?.length">
        <ng-scrollbar>
            <div class="consent-message-popup__validation-message" *ngFor="let validation of validationMessage">
                <span class="message-header">{{validation.Message}}</span>
                <ng-container *ngIf="validation.Details?.length">
                    <ul class="consent-message-popup__validation-list">
                        <li class="message-details" *ngFor="let details of validation.Details">{{details}}</li>
                    </ul>
                </ng-container>
            </div>
        </ng-scrollbar>
    </div>
</div>
