import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { RetailLocalization } from './retail-localization';

@Pipe({
  name: 'localizeDate'
})
export class LocalizeDatePipe implements PipeTransform {
  constructor(private localization:RetailLocalization){

  }
  transform(value: any): any { 
    return moment(value).format(this.localization.inputDateFormat);
  }

}
