import { Injectable } from '@angular/core';
import { DayEndDateInfo, NightAuditConfig, NightAuditSchedule } from './night-audit.model';
import { PropertyInformation } from 'src/app/common/shared/services/property-information.service';
import { Product } from '../enums/shared-enums';
import { Host } from 'src/app/common/shared/shared/globalsContant';
import { BaseResponse, HttpMethod } from 'src/app/common/Models/http.model';
import { HttpServiceCall } from '../shared/shared/service/http-call.service';
import { NotificationConfigurationService } from '../templates/notification-configuration/notification-configuration.service';
import { TemplateDataServices } from '../dataservices/notification/template-data-service';
import { TemplateType } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Injectable()
export class NightAuditBusiness {

  constructor(private _propertyInformation: PropertyInformation, private http: HttpServiceCall, private notificationService: NotificationConfigurationService, private templateservice: TemplateDataServices, private utilities: CommonUtilities) {

  }

  public getDates(): DayEndDateInfo {
    const systemDate = this._propertyInformation.CurrentDate;
    systemDate.setDate(systemDate.getDate() + 1);

    return {
      currentDate: this._propertyInformation.CurrentDate,
      systemDate
    };
  }

  async getDistrubitionandTemplateData() {
    this.utilities.ToggleLoader(true);
    let templateData = this.templateservice.GetAllTemplates(TemplateType.Email, false);
    let distributionData = this.notificationService.getAllDistributionByProductId();
    const result = await Promise.all([templateData, distributionData]);
    this.utilities.ToggleLoader(false);
    return result;
  }

  async SaveNightAuditConfig(nightauditConfig: NightAuditConfig, productId: number): Promise<any> {
    switch (productId) {
      case Product.SPA:
        return (await this.InvokeServiceCallAsync("NightAudit", Host.schedule, nightauditConfig.id > 0 ? HttpMethod.Put : HttpMethod.Post, null, nightauditConfig)).result;
      case Product.GOLF:
        return (await this.InvokeServiceCallAsync("NightAuditConfig", Host.golfSchedule, nightauditConfig.id > 0 ? HttpMethod.Put : HttpMethod.Post, null, nightauditConfig)).result;
      case Product.RETAIL:
        return (await this.InvokeServiceCallAsync("NightAuditConfiguration", Host.retailPOS, nightauditConfig.id > 0 ? HttpMethod.Put : HttpMethod.Post, null, nightauditConfig)).result;
    }

  }

  async SaveNightAuditScheduleConfig(nightAuditSchedule: NightAuditSchedule, productId: number): Promise<any> {
    switch (productId) {
      case Product.SPA:
        return (await this.InvokeServiceCallAsync("NightAuditSchedule", Host.schedule, nightAuditSchedule.id > 0 ? HttpMethod.Put : HttpMethod.Post, null, nightAuditSchedule)).result;
      case Product.GOLF:
        return (await this.InvokeServiceCallAsync("NightAuditSchedule", Host.golfSchedule, nightAuditSchedule.id > 0 ? HttpMethod.Put : HttpMethod.Post, null, nightAuditSchedule)).result;
      case Product.RETAIL:
        return (await this.InvokeServiceCallAsync("NightAuditScheduleConfiguration", Host.retailPOS, nightAuditSchedule.id > 0 ? HttpMethod.Put : HttpMethod.Post, null, nightAuditSchedule)).result;
    }

  }

  async RemoveNightAuditScheduleConfig(scheduleId: number, productId: number): Promise<any> {
    switch (productId) {
      case Product.SPA:
        return (await this.InvokeServiceCallAsync("RemoveNightAuditSchedule", Host.schedule, HttpMethod.Delete, { scheduleId: scheduleId }, null)).result;
      case Product.GOLF:
        return (await this.InvokeServiceCallAsync("DeleteNightAuditScheduleConfigById", Host.golfSchedule, HttpMethod.Delete, { id: scheduleId }, null)).result;
      case Product.RETAIL:
        return (await this.InvokeServiceCallAsync("DeleteNightAuditScheduleConfigurationById", Host.retailPOS, HttpMethod.Delete, { id: scheduleId }, null)).result;
    }

  }

  async RemoveNightAuditConfig(configId: Number, productId: number): Promise<any> {
    switch (productId) {
      case Product.SPA:
        return (await this.InvokeServiceCallAsync("RemoveNightAudit", Host.schedule, HttpMethod.Delete, { configId: configId }, null)).result;
      case Product.GOLF:
        return (await this.InvokeServiceCallAsync("DeleteNightAuditConfigById", Host.golfSchedule, HttpMethod.Delete, { id: configId }, null)).result;
      case Product.RETAIL:
        return (await this.InvokeServiceCallAsync("DeleteNightAuditConfigurationById", Host.retailPOS, HttpMethod.Delete, { id: configId }, null)).result;
    }
  }

  async GetNightAuditConfig(productId: number): Promise<NightAuditConfig> {
    switch (productId) {
      case Product.SPA:
        return (await this.InvokeServiceCallAsync("NightAudit", Host.schedule, HttpMethod.Get, null, null)).result;
      case Product.GOLF:
        return (await this.InvokeServiceCallAsync("NightAuditConfig", Host.golfSchedule, HttpMethod.Get, null, null)).result;
      case Product.RETAIL:
        return (await this.InvokeServiceCallAsync("NightAuditConfiguration", Host.retailPOS, HttpMethod.Get, null, null)).result;
    }

  }

  async GetNightAuditSchedule(productId: number): Promise<NightAuditSchedule> {
    switch (productId) {
      case Product.SPA:
        return (await this.InvokeServiceCallAsync("NightAuditSchedule", Host.schedule, HttpMethod.Get, null, null)).result;
      case Product.GOLF:
        return (await this.InvokeServiceCallAsync("NightAuditSchedule", Host.golfSchedule, HttpMethod.Get, null, null)).result;
      case Product.RETAIL:
        return (await this.InvokeServiceCallAsync("NightAuditScheduleConfiguration", Host.retailPOS, HttpMethod.Get, null, null)).result;
    }

  }

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

}