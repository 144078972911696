<section class="transaction-detail-report" [formGroup]="AccrualFormGrp" autocomplete="off">
    <app-retail-multi-select-dropdown *ngIf="outletData" [dropDownFilterData]="outletData"
        [dropDownControlname]="'Outlets'" [dropDownName]="captions.Outlets" (IsAnySelected)="IsSelectionValid($event,'Outlets')"
        (dropDownFrmControl)="getFormControlValue($event)">
    </app-retail-multi-select-dropdown>
    <mat-error *ngIf="validSelection">
        {{validSelectionErrMsg}}
    </mat-error>
    <app-retail-multi-select-dropdown *ngIf="userData" [dropDownFilterData]="userData" [dropDownControlname]="'Users'"
        [dropDownName]="captions.Users" (dropDownFrmControl)="getFormControlValue($event)" (IsAnySelected)="IsSelectionValid($event,'Users')">
    </app-retail-multi-select-dropdown>
    <mat-error *ngIf="validSelectionUsers">
        {{validSelectionErrMsgUsers}}
    </mat-error>
    <div class="mb-3">
        <label class="LW14">{{captions.IncludeInActiveUsers}}</label>
        <app-retail-toggle-switch class="d-block" [formControl]="AccrualFormGrp.controls['IncludeInActiveUsers']">
        </app-retail-toggle-switch>
    </div>

    <div class="mb-3">
        <mat-form-field class="w-100 h-25" [floatLabel]="floatLabel">
            <input [placeholder]="searchByCaption" aria-label="Number" matInput
                [formControl]="AccrualFormGrp.controls['clientTobeSelected']" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn">
                <mat-option *ngFor="let client of Clients" [value]="client">
                    {{client.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>    
</section>