import { Component, OnInit , ViewEncapsulation} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SaveButtonObj } from 'src/app/common/Models/ag-models';
import { UI } from '../../Models/property-information.model';
import { UI as PropertyUI} from '../../Models/property-settings.model';
import { Router } from '@angular/router';
import { ChangePropertyBusiness } from './change-property.business';
import { Localization } from '../../localization/localization';
import { DialogCloseEnum } from '../../enums/shared-enums';


@Component({
  selector: 'app-change-property',
  templateUrl: './change-property.component.html',
  styleUrls: ['./change-property.component.scss'],
  providers: [ChangePropertyBusiness],
  encapsulation: ViewEncapsulation.None
})
export class ChangePropertyComponent implements OnInit {
  captions: any;
  DialogCloseOptionEnum = DialogCloseEnum;
  buttonObj: SaveButtonObj;
  searchText: string;
  token: string;
  reduceHeightAutoComplete = "reduceHeightAutoComplete";
  propertyList: UI.PropertyDetails[];
  options: UI.PropertyDetails[];
  isFocused: boolean = true;
  selectedProperty: UI.PropertyDetails;
  propertyDetails$: Promise<UI.PropertyDetails[]>;
  propertyConfigDetails: PropertyUI.PropertyConfiguration[];
  constructor(
    private localiztion: Localization,
    public dialogRef: MatDialogRef<ChangePropertyComponent>,
    public changePropertyBusiness: ChangePropertyBusiness,
    private router: Router
  ) { }

  async ngOnInit() {
    this.captions = this.localiztion.captions;
    this.buttonObj = {
      customSaveText: this.captions.btn_proceed,
      disabled: true,
      isEdit: false
    };
    this.propertyDetails$ = this.changePropertyBusiness.GetPropertyInfo();
    this.propertyConfigDetails = await this.changePropertyBusiness.GetPropertyConfigurationInfo();
    this.options = await this.propertyDetails$;
    this.propertyList = await this.propertyDetails$;
  }

  closeDialog(res) {
    this.dialogRef.close(res);
  }

  private disableProceedButton(property: boolean) {
    this.buttonObj.disabled = property;
    this.buttonObj = { ...this.buttonObj };
  }

  async searchValueChange(e) {
    this.searchText = e;
    this.selectedProperty = null;
    this.disableProceedButton(true);
    if(e.length>2){

      this.propertyList = await this.propertyDetails$.then(result =>
        result.filter(x => x.propertyName.toLowerCase().includes(this.searchText.toLowerCase())));
    }else{
      this.propertyList = await this.propertyDetails$;
    }
    
  }

  clearText() {
    this.searchText = '';
    this.selectedProperty = null;
    this.disableProceedButton(true);
  }

  

  optionSelected(e) {
    this.disableProceedButton(true);
    this.selectedProperty = e;
    this.searchText = this.selectedProperty.propertyName;
    this.disableProceedButton(false);
  }

  async changeProperty() {
    this.disableProceedButton(true);
    const selectedPropertyConfigDetails = this.propertyConfigDetails.find(x => x.propertyId === this.selectedProperty.id);
    if (!selectedPropertyConfigDetails ||
        !selectedPropertyConfigDetails.hostName ||
        selectedPropertyConfigDetails.hostName === window.location.origin) {
      this.token = '';
      selectedPropertyConfigDetails.hostName = window.location.origin;
    } else {
      this.token = await this.changePropertyBusiness.GetTokenForSelectedProperty(this.selectedProperty);
    }
    this.dialogRef.close(true);
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    this.changePropertyBusiness.openNewProperty(selectedPropertyConfigDetails.hostName, this.token, this.selectedProperty, queryParams);
  }

  cancel(event) {
    this.dialogRef.close(event);
  }

}
