<div class="rate-plan-view">
  <!-- [ngClass]="{'disabled': data?.addPrivateLessonBooking && data?.isConfirmedReservation}"  -->
  <div class="booking-summary">
    <div *ngIf="restaurantPolicy" class="rate-plan__policy-table">
      <label class="rate-plan__selected-tables seat-secondary-text">
        {{
        restaurantPolicy?.PreReservationMessageTitle }} </label> <span
        class="rate-plan__icon-exclamation icon-warning-info-icon" (click)="ShowRestPolicyDetailsClick()"></span>
    </div>
    <ng-container class="rate-plan__summary-accordian" *ngIf="bookingData">
      <!-- {{'bookingSummary' | translate}} -->
      <div [title]="activityName" class="rate-plan__item__value activity-label seat-header-three p-2 pl-3">{{bookingData.ActivityId |
        getActivityName :
        _settings }}</div>
      <section class="rate-plan__summary-details">


        <div class="rate-plan__item__label">{{'location' | translate}}</div>
        <ng-container *ngFor="let item of bookingData?.LocationName">
          <div class="rate-plan__item__value seat-header-four"> {{item}}
          </div>
        </ng-container>
        <ng-container *ngIf="!bookingData?.LocationName.length">
          <div class="rate-plan__item__value seat-header-four"> {{'Any'| translate}}
          </div>
        </ng-container>
        <div class="rate-plan__item__label" *ngIf="(isSessionClass || showAsset) && assetDetails">{{'Asset' | translate}}</div>
        <div class="rate-plan__item__value seat-header-four" *ngIf="(isSessionClass || showAsset) && assetDetails">{{assetDetails}} </div>
        <div class="rate-plan__item__label">{{'date' | translate}}</div>
        <!-- <ng-container *ngIf="isSessionClass"> -->
        <!-- <div class="rate-plan__item__value seat-header-four" *ngIf="bookingData.Slots.length == 1 ">
          {{bookingData.FromDate | formatSettingsDate:
          cs.settings.value.General.DateFormat}} </div> -->
        <div class="rate-plan__item__datefieldvalue seat-header-four">
          {{bookingData.FromDate | formatSettingsDate:
          cs.settings.value.General.DateFormat}} - 
          {{bookingData.ToDate |
          formatSettingsDate:
          cs.settings.value.General.DateFormat}}</div>
        <!-- </ng-container>
        <ng-container *ngIf="!isSessionClass">
          <div class="rate-plan__item__value">
            {{bookingData.Slots[0].Date | formatSettingsDate:
            cs.settings.value.General.DateFormat}} </div>
        </ng-container> -->


        <div class="rate-plan__item__label">{{'bookingSize' | translate}}</div>
        <div class="rate-plan__item__value seat-header-four">{{bookingData?.BookingSize}}
        </div>

        <!-- <ng-container *ngIf="ratePlan">
                  <div class="rate-plan__item__label">{{'timeSlots' | translate}}</div>
                  <ng-container>
                    ew  <span
                          *ngFor="let slot of bookingData.SlotData | slice:0:(showAllSlots ? bookingData.SlotData?.length : 4 ); let index = index;" class="seat-header-four">
                          <span *ngIf="isSessionClass"> {{slot.TimeRange.Start | localizedDate: 'DD MMMM, LT'}}
                              -
                              {{slot.TimeRange.End | localizedDate:'LT'}}{{bookingData.Slots[index+1] && ','}}
                              &nbsp;</span> </span> <span
                          *ngFor="let slot of bookingData.SlotData; let index = index;"> <span
                              *ngIf="!isSessionClass"> <span class="batch-name" *ngIf="bookingData.SessionGroupName">
                                  {{bookingData.SessionGroupName}},
                              </span> {{slot.TimeRange.Start | localizedDate: 'DD MMMM'}}
                              - {{slot.TimeRange.End | localizedDate: 'DD MMMM'}} &nbsp; </span> </span>
                      <div class="rate-plan__slots-show-action" *ngIf="bookingData.SlotData?.length > 4"
                          (click)="showAllSlots = !showAllSlots"> {{showAllSlots ? 'Show Less' :
                          '+'+(bookingData.SlotData?.length-4)+' '+'more'}}</div>
                  </ng-container>
              </ng-container>
              <ng-container class="rate-plan__item__value" *ngIf="ratePlan && !ratePlan.RatePlanCalculations">
                re  <span *ngFor="let slot of bookingData.SlotData; let index = index;"> <span
                          *ngIf="isSessionClass">{{slot.LocalTime | localizedDate: 'DD MMMM, LT'}}
                          -
                          {{slot.EndTime | localizedDate:'LT'}}{{bookingData.Slots[index+1] && ','}}</span>
                      <span *ngIf="!isSessionClass"> <span class="batch-name" *ngIf="bookingData.SessionGroupName">
                              {{bookingData.SessionGroupName}},
                          </span> {{slot.LocalTime | localizedDate: 'DD MMMM'}}
                          - {{slot.EndTime | localizedDate: 'DD MMMM'}}}}
                      </span> &nbsp; </span> <span> {{slot.TimeRange.Start | localizedDate: 'DD MMMM' }} -
                      {{slot.TimeRange.End |
                      date:'dd MMMM'}}
                  </span>
              </ng-container> -->

        <ng-container class="rate-plan__item  rate-plan__item--slots" *ngIf="(this.partyService.BookingBehaviour === bookingBehavior.ClassOrSession &&  partyService.sessionBookingData.ClassType != classType.Class)  || partyService.BookingBehaviour == bookingBehavior.OpenBooking || partyService.BookingBehaviour == bookingBehavior.PrivateLesson">
          <div class="rate-plan__item__label">{{'timeSlots' | translate}}</div>
          <div class="rate-plan__item__value seat-header-four">
            <span
              *ngFor="let slot of bookingData.Slots | slice:0:(showAllSlots ? bookingData.Slots.length : 4 ); let index = index;">
              <div *ngIf="slot.Time && slot.EndTime"> {{slot.Time | localizedDate: 'LT'}}
                -
                {{slot.EndTime | localizedDate:'LT'}}</div>
                <ng-container *ngIf="slot?.sessions?.length">
                <span
                *ngFor="let sessionItem of slot.sessions | slice:0:(showAllSlots ? slot.sessions.length : 4 ); let index = index;">
                <div class="pb-1"> {{sessionItem.Start | localizedDate:  'LT'}}
                  -
                  {{sessionItem.End | localizedDate: 'LT'}}</div>
              </span>
            </ng-container>
            </span>

            <div class="rate-plan__slots-show-action" *ngIf="bookingData.Slots.length > 4"
              (click)="showAllSlots = !showAllSlots">
              {{showAllSlots ? 'Show Less' : '+'+(bookingData.Slots.length-4)+' '+'more'}}
            </div>
          </div>


        </ng-container>

        <!-- <ng-container class="rate-plan__item  rate-plan__item--slots" *ngIf="!ratePlan">
          <div class="rate-plan__item__label">{{'timeSlots' | translate}}</div>

          <div class="rate-plan__item__value"> <span *ngIf="!isSessionClass"> <span class="batch-name"
                *ngIf="bookingData.SessionGroupName">
                {{bookingData.SessionGroupName}},
              </span> {{bookingData.FromDate | localizedDate: 'DD MMMM'}} -
              {{bookingData.ToDate | localizedDate: 'DD MMMM'}}
            </span> <span
              *ngFor="let slot of bookingData.Slots | slice:0:(showAllSlots ? bookingData.Slots.length : 4 ); let index = index;">
              <span *ngIf="isSessionClass"> {{slot.sessions[0].Start | localizedDate: 'DD MMMM, LT'}}
                -
                {{slot.sessions[0].End | localizedDate:'LT'}}{{bookingData.Slots[index+1] &&
                ','}} &nbsp;</span> </span>
            <div class="rate-plan__slots-show-action" *ngIf="bookingData.Slots.length > 4"
              (click)="showAllSlots = !showAllSlots">
              {{showAllSlots ? 'Show Less' : '+'+(bookingData.Slots.length-4)+' '+'more'}}
            </div>
          </div>

        </ng-container> -->
      </section>
      <!-- 
        <mat-accordion class="p-10">
          <mat-expansion-panel [expanded]="false" class="rate-plan__summary-accordian--panel">
            <mat-expansion-panel-header>
              <mat-panel-title
                class="rate-plan__section-title rate-plan__section-title--accordian">
              
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rate-plan__content">
              <div class="rate-plan__item rate-plan__item--name">
                <div class="rate-plan__item__label">{{'activityName' | translate}}</div>
                
              </div>
              <div class="rate-plan__item rate-plan__item--location">
             
              </div>
              <div class="rate-plan__item rate-plan__item--date">

              </div>


            </div>
          </mat-expansion-panel>
        </mat-accordion> -->
    </ng-container>
    <div class="mt-10">
      <dynamic-form [config]="communicationPrefConfig" #communicationPrefConfigForm>
      </dynamic-form>
    </div>
    <div class="guest-data__party-notes pt-2" [ngClass]="{'disable':partyService.tryUndoNoShowReservation}">
      <dynamic-form [config]="config" #form="dynamicForm">
      </dynamic-form>
    </div>
  </div>
  <div *ngIf="bookingData" class="summary-details">
    <app-rate-plan [bookingData]="bookingData" [invokeRatePlan]="invokeRatePlan.asObservable()" (IsPackageBroken)="setPackageBrokenData($event)"></app-rate-plan>
    <div class="apply-promocode" *ngIf="this._ps.ratePlanObject">
    <app-apply-promo-code [promoCodeEligibleProperty]="promoCodeEligibleProperty" (promoApplied)="promoCodeApplied($event)"></app-apply-promo-code>
  </div>
  </div>
  <div class="payments-section">
    <app-common-booking-payments (setPayment)="setPartyPaymentType($event)"
      (setRetailTokenId)="setRetailCardTokenId($event)" [selectedIndex]="2"></app-common-booking-payments>
  </div>
</div>