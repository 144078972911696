import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '@app/app.service';
import { LegendConfig } from '@models/global.interface';

@Component({
  selector: 'app-display-legend',
  templateUrl: './display-legend.component.html',
  styleUrls: ['./display-legend.component.scss']
})
export class DisplayLegendComponent implements OnInit {
  @Input() legends: LegendConfig[];
  @Input() isColumn: boolean;
  constructor(public _as: AppService) { }

  ngOnInit() {
  }


}
