import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { CacheService } from '@app/core/services/cache.service';
import { BookingBehavior, ClassType, ComponentTypes, PartyNoteType, PartyType, SlottingType, ValidationMessageType, buttonTypes } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { ActivityRatePlanRequest, RatePlanRequest } from '@app/shared/models/ActivityRatePlanRequestDTO';
import { PartyNoteDTO } from '@app/shared/models/InputContact';
import { SessionBookingDTO } from '@app/shared/models/SessionBookingDTO';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { map } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OpenBookingSummaryComponent } from '../create-update-party-tab-layout/selections/open-booking-summary/open-booking-summary.component';
import { Category, SettingsDTO, TaxType } from '@app/shared/models/RestaurantDTO';
import { PartyNotes } from '@app/shared/models/global.interface';
import { FormChipService } from '@app/shared/dynamicform/components/form-chip/form-chip.service';
import { AppService } from '@app/app.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { RestaurantPoliciesComponent } from '../create-update-party-tab-layout/selections/restaurant-policies/restaurant-policies.component';
import { PopupService } from '@app/popup-module/popup.service';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { ITabOutputDetails } from '@app/settings/models/common.interface';
import { LockSessionRequestDTO } from '@app/shared/models/ActivityLockSessionDTO';
import moment from 'moment';
import { PaymentItemType } from '@app/shared/models/RatePlanSummary.model';
import { ApplyDiscount } from '@app/shared/models/PromoCodeDTO';


@Component({
    selector: 'app-rate-plan',
    templateUrl: './rate-plan.component.html',
    styleUrls: ['./rate-plan.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RatePlanComponent extends Utilities implements OnInit, OnDestroy {
    @Input() bookingData;
    @Input() enableEdit = true;
    applyDiscount= ApplyDiscount;
    subscriptions: Subscription = new Subscription();
    ratePlanSummary: any;
    originalRatePlan: any;
    currencySymbol: string = '';
    rateNegotiateConfig: FieldConfig[];
    recalculateConfig: FieldConfig[];
    applyNegotiationBtn: ButtonValue;
    cancelNegotiationBtn: ButtonValue;
    removeOfferCode = false;
    @Input() invokeRatePlan: Observable<any>;
    @Input() fromComponent: any;
    restaurantPolicy: any;
    config: FieldConfig[] = [];
    includeOthers = false;
    othersId: number[] = [];
    appliedOfferCode = null;
    customPartyNotes: any[] = [];
    partyNotes: PartyNotes[] = [];
    categories: Category[] = [];
    _settings: SettingsDTO;
    showSCDetails = false;
    showNegDis = false;
    showTaxDetails = false;
    @ViewChild('negotiateReasonForm') negotiateReasonForm: DynamicFormComponent;
    @ViewChild('recalculateForm') reCalculate: DynamicFormComponent;
    selectedPartyPreferenceTags: any[] = [];
    tabsConfig: any;
    diningProperty = false;
    taxType = TaxType.TAX
    paymentItemType = PaymentItemType;
    existingRatePlanSummary: any;
    originalShopItems: any[] = [];
  @Output() IsPackageBroken = new EventEmitter<boolean>();
    //  ratePlanSummary: Observable<any>;
    constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public dialogData, public _as: AppService, public partyService: PartyService, public cs: CacheService, private _ts: TranslateService, private _api: ApiService, private _ps: PartyService, private formChipService: FormChipService, private ps: PopupService) {
        super(dialog);
        this.subscriptions.add(this.cs.settings.subscribe(sett => {
            this._settings = sett;
            this.taxType = sett.PropertySetting[0].TaxType;
           // this.taxType = sett.PropertySetting.TaxType || TaxType.TAX
            // // this.isTaxConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax)?.length > 0;
            // // this.isServiceConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge)?.length > 0;
            // // this.isRetailEnabledProperty = Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO);
            // // this.OperationCurrency = this._settings.General.OperationCurrency;
            // // this.coverTypes = [...this._settings.CoverTypes];
            // // this.bookingTypes = [...this._settings.BookingTypes];
            // // this.partyService.isNegotiationEnabled = this._as.hasPermission(RolesAndPermissionsType.NegotiateRates);
            // if (this._settings.PropertySetting.length > 0 && (this._settings.PropertySetting[0].PreReservationMessage && this._settings.PropertySetting[0].PreReservationMessageTitle)) {
            //     this.restaurantPolicy = this._settings.PropertySetting[0];
            // }
            // this.categories = this._settings.Categories.filter(category => category.Text != "Reasons");
            // if (dialogData && this._as.OTASourceId.includes(dialogData.PartySourceId)) {
            //     this.includeOthers = true;
            // }
            // if (dialogData && this.dialogData.Notes && this.dialogData.Notes.length) {
            //     this.othersId = this.dialogData.Notes.filter(x => x.Type == PartyNoteType.FreeFormNote).map(({ RelatedId }) => RelatedId);
            // }
            // this.partyNotes = Utilities.getRestaurantPredefinedPartyNotes(this.categories, this.includeOthers, this.othersId);

        }));
    }

    ngOnInit(): void {
        this.currencySymbol = this.cs.settings.value.General.OperationCurrency;
        this.loadConfig();

        if (this.dialogData && this.dialogData.bookingData) {
            this._ps.ratePlanObject = this.dialogData.bookingData.financialData;
            this.ratePlanSummary = this.dialogData.bookingData.financialData.RatePlan;
            this.originalRatePlan = _.cloneDeep(this.ratePlanSummary);
            this.originalShopItems = _.cloneDeep(this.dialogData.bookingData.financialData.ShopItems);
            this.existingRatePlanSummary = _.cloneDeep(this.ratePlanSummary);
            this.partyService.ratePlanSummaryState = true;
            if(this.ratePlanSummary.Discount)
            this.appliedOfferCode = {OfferCode: this.ratePlanSummary.Discounts?.[0]?.ChargeName, ApplyDiscount: this.ratePlanSummary.Discounts?.[0]?.ApplyBeforeTax == true ?  ApplyDiscount.BeforeTax : ApplyDiscount.AfterTax, OfferId: this.ratePlanSummary.Discounts?.[0]?.ChargeId}
        }
        if(this.dialogData?.componentDetails?.tabs?.componentInput?.RatePlan?.Discounts?.length){
            let offer =null;
           offer =  this.dialogData?.componentDetails?.tabs?.componentInput?.RatePlan?.Discounts?.[0];
           if(offer)
           this.appliedOfferCode = {OfferCode: offer.ChargeName, ApplyDiscount: offer.ApplyBeforeTax == true ?  ApplyDiscount.BeforeTax : ApplyDiscount.AfterTax, OfferId: offer.ChargeId}
        }
        if(this.dialogData?.componentDetails?.tabs?.componentInput?.isCartEdit){
            this.appliedOfferCode = {OfferCode: this.dialogData.componentDetails.tabs.componentInput?.bookingRatePlan?.rateSummary?.OfferCode, ApplyDiscount: 0}
        }

        this.invokeRatePlan?.subscribe(data => {
           
            if (data) {

                if (this.partyService.BookingBehaviour == BookingBehavior.ClassOrSession && (!this.partyService.addToCartRequestObj?.Slot?.LockId || !this.partyService.addToCartRequestObj?.Sessions?.LockId)) {
                    this.partyService.addToCartRequestObj.Slot.LockId = this.partyService.lockData?.SlotLockIdDTO?.Id || null;
                    this.partyService.addToCartRequestObj.Sessions.LockId = this.partyService.lockData?.SlotLockIdDTO?.Id || null;
                }
                else {
                    this.partyService.addToCartRequestObj?.Slots?.map((slot) => {
                        slot.LockId = this.partyService.lockData?.SlotLockIdDTO?.Id || null;
                    })
                }
                this.diningProperty = this.cs.settings.value.PropertySetting[0]?.SlottingType == SlottingType.Dining;
                this.getRatePlanForActivity();
            }
        })
    }


    getRatePlanForActivity() {
        
        let ratePlanRequest = this.formRatePlanRequestDTO(this.bookingData,this.appliedOfferCode?.OfferCode);
        this._ps.currentRatePlanRequest = ratePlanRequest;
        if (this.diningProperty || !this.dialogData.componentDetails.tabs.componentInput?.RatePlan || this.isBookingDataUpdated(ratePlanRequest, this.dialogData.componentDetails.tabs.componentInput) || this.removeOfferCode) {
            this._api.GetRatePlanCalculated(ratePlanRequest).subscribe(data => {
                if(data.Payload){
                this.intializeRatePlan(data.Payload)
                if(data.Payload?.RatePlan?.Discount){
                    this.appliedOfferCode = {OfferCode: data.Payload?.RatePlan?.Discounts?.[0].ChargeName, ApplyDiscount: data.Payload?.RatePlan?.Discounts?.[0].ApplyBeforeTax == true ?  ApplyDiscount.BeforeTax : ApplyDiscount.AfterTax, OfferId: data.Payload?.RatePlan?.Discounts?.[0].ChargeId}
                }
                else {
                    this.appliedOfferCode = null;
                }
                this.removeOfferCode = false;
                if(data.Payload.IsPackageBroken){
                    this.IsPackageBroken.emit(true);
                  

                    this.partyService.showWarningInfoPopUp(this.partyService.getPackageName(this.bookingData?.PackageDetails?.PackageId , 'packageBookingUpdateConfirmation'), ComponentTypes.packageBrokenWarning, '350px', '500px', []);
                    let confirmationSubscription = this.ps.confirmedAction$.subscribe(data => {
                        if (data.from === ComponentTypes.packageBrokenWarning) {
                          this.partyService.warningInfoDialogRef.close();
                        }
                      });
                      let cancelledSubscription = this.ps.cancelledAction$.subscribe(data =>{
                          if(data.from == ComponentTypes.packageBrokenWarning){
                           this.ps.tabsActionData[this.ps.tabsActionData.length - 1].gotoNextTab = false;   
                           this.ps.tabsActions$.next(this.ps.tabsActionData);  
                          }
                      })
                        this.partyService.warningInfoDialogRef.afterClosed().subscribe(event => {
                      confirmationSubscription.unsubscribe();
                    });
                   }

                   else{
                    this.IsPackageBroken.emit(false);
                   }
                }
            })
        }
        else {
            this.intializeRatePlan(this.dialogData.componentDetails.tabs.componentInput)
        }
    }

    intializeRatePlan(ratePlan, offerCode?) {
        this._ps.ratePlanObject = ratePlan;
        if(ratePlan && ratePlan.RatePlan){
        this.ratePlanSummary = ratePlan.RatePlan;
        if (this.partyService.addToCartRequestObj) {
            this.partyService.addToCartRequestObj.rateSummary = {
                Amt: this.ratePlanSummary.SubTotal,
                TaxAmt: this.ratePlanSummary.Tax,
                SCAmt: this.ratePlanSummary.ServiceCharge,
                TaxOnSCAmt: this.ratePlanSummary.TaxOnServiceChargeAmount,
                VAT: this.ratePlanSummary.VAT,
                VATSCAmt: this.ratePlanSummary.VATOnServiceChargeAmount,
                Discount: this.ratePlanSummary.Discount,
                OfferCode: this.appliedOfferCode?.OfferCode || null

            }

        }
        this.originalRatePlan = _.cloneDeep(this.ratePlanSummary);
        this.originalShopItems = _.cloneDeep(ratePlan.ShopItems);
        this.existingRatePlanSummary = _.cloneDeep(this.ratePlanSummary);
        this.setConfigChanges();
    }
    }
    isBookingDataUpdated(request, data) {
        let isGuestTypeChanged = false;
        let isBookingTypeChanged = false;
        let isSessionChanged = false;
        let isAddonChanged = false;
        let bookingSizeChanged = false;
        let isContactChanged = false;
        let isDateChanged = false;
        let isLocationChanged = false;
        let isStaffChanged = false;
        let isExclusiveBookingChanged = false;

        if (!data) {
            return false;
        }
        if (request.CoverTypes?.length && data.CoverTypeQuantities.length) {
            isGuestTypeChanged = request.CoverTypes.filter((reqGuestType) => {

                let savedGuestType = data.CoverTypeQuantities.find(srcGuestType => {
                    if(srcGuestType.CoverTypeId && srcGuestType.BookingTypeId){
                        return srcGuestType.CoverTypeId === reqGuestType.CoverTypeId && srcGuestType.BookingTypeId === reqGuestType.BookingTypeId;
                    }
                    else if (srcGuestType.CoverTypeId) {
                        return srcGuestType.CoverTypeId === reqGuestType.CoverTypeId;
                    }
                    else if (srcGuestType.BookingTypeId) {
                        return srcGuestType.BookingTypeId === reqGuestType.BookingTypeId;
                    }
                    else{
                        return srcGuestType.Covers === reqGuestType.Covers
                    }
                })
                return !!((savedGuestType && savedGuestType.Covers) !== reqGuestType.Covers);
            }).length
        }


        if(request.coverTypes?.length){
            bookingSizeChanged =  _.sumBy(request.coverTypes,'Covers') != request.Size
        }
        if (!request.CoverTypes?.length && !request.BookingTypes?.length) {
            bookingSizeChanged = data.Size != +request.Size;
        }
        if (request.BookingBehavior == BookingBehavior.ClassOrSession) {
            if (data.BookedSessions) {
                isSessionChanged = !(data.BookedSessions.length === request.SessionSlot.Sessions[0].SessionIds.length
                    && data.BookedSessions.filter(bookedSession =>
                        request.SessionSlot.Sessions[0].SessionIds.includes(bookedSession.ActivitySessionId)).length)
            } else if (data.SessionGroupId && request.SessionSlot) {
                isSessionChanged = data.SessionGroupId !== request.SessionSlot.SessionGroupId;
            }
        }
        else if (request.BookingBehavior == BookingBehavior.OpenBooking || request.BookingBehavior == BookingBehavior.PrivateLesson) {

            if (data.SeatingTime) {
                let durationOfActivity = moment.duration(moment(data.DepartureTime, 'YYYY/MM/DD HH:mm')
                    .diff(moment(data.SeatingTime, 'YYYY/MM/DD HH:mm'))).asMinutes();
                isSessionChanged = (data.SeatingTime != request.Slots[0].Time) || (durationOfActivity != request.Slots[0].DurationInMinutes)
            } else if (data.Slots && data.Slots[0]) {
                isSessionChanged = (data.Slots[0].Time != request.Slots[0].Time) || (data.Slots[0].DurationInMinutes != request.Slots[0].DurationInMinutes)
            } else {
                isSessionChanged = false;
            }

        }


        isAddonChanged = !!data.BookingContactAddonItems && (!(_.flatMap(request.BookingContacts, 'Addons').filter((reqAddon) => {
            let savedAddon = data.BookingContactAddonItems.find(srcAddon => srcAddon.AddonId === reqAddon.AddonId);
            return savedAddon && savedAddon.AddonCount === reqAddon.Quantity
        }).length === data.BookingContactAddonItems.length) || _.flatMap(request.BookingContacts, 'Addons').length !== data.BookingContactAddonItems.length);
        
        isDateChanged = !( moment(moment(request.StartDate).format('YYYY-MM-DD')).isSame(moment(data.StartDate).format('YYYY-MM-DD')) && moment(moment(request.EndDate).format('YYYY-MM-DD')).isSame(moment(data.EndDate).format('YYYY-MM-DD')));

        isContactChanged = (data.BookingContacts.length != request.BookingContacts.length) || _.differenceBy(request.BookingContacts, data.BookingContacts, 'ContactId').length > 0;

        isDateChanged = !( moment(moment(request.StartDate).format('YYYY-MM-DD')).isSame(moment(data.StartDate).format('YYYY-MM-DD')) && moment(moment(request.EndDate).format('YYYY-MM-DD')).isSame(moment(data.EndDate).format('YYYY-MM-DD')));

        if(request.BookingBehavior ==  BookingBehavior.OpenBooking || request.BookingBehavior ==  BookingBehavior.PrivateLesson )
            isLocationChanged = !(_.isEqual(data.TableIds, request.TableIds));
    
            
            if(request.BookingBehavior ==  BookingBehavior.PrivateLesson )
            isStaffChanged = !(_.isEqual(data.StaffIds, request.InstructorIds));


        isExclusiveBookingChanged = data.IsExclusiveBooking !== request.IsExclusiveBooking;
        /**Pending */
        //isGroupClassUpdated
        return !!isGuestTypeChanged || isSessionChanged || isAddonChanged || bookingSizeChanged || isContactChanged || isDateChanged || isLocationChanged || isStaffChanged || isExclusiveBookingChanged;
    }
    formRatePlanRequestDTO(bookingData, offerCode?) {
        return {
            Slots: bookingData.BookingBehavior == BookingBehavior.ClassOrSession ? [] : bookingData.Slots,
            SessionSlot: 
                 bookingData.BookingBehavior == BookingBehavior.ClassOrSession ? {
                    Sessions:  bookingData.SessionGroupId ? null : bookingData.Slots,
                    SessionGroupId:  bookingData.SessionGroupId,
                    OverBooked: bookingData?.OverBooked || 0
                  } : {},
            Size: bookingData.BookingSize,
            TableIds: bookingData.BookingBehavior == BookingBehavior.ClassOrSession ? [] : bookingData.Location?.flat(),
            CoverTypes: bookingData.CoverTypes,
            ActivityId: bookingData.ActivityId,
            FinancialEffectId: null,
            BookingBehavior: bookingData.BookingBehavior,
            StartDate: bookingData.FromDate,
            EndDate: bookingData.ToDate,
            BookingContacts: bookingData.BookingContacts,
            RatePlan: null,
            AddOns: bookingData.SelectedAddOns,
            InstructorIds: bookingData.StaffId,
            PartyId: bookingData?.BookingId ? bookingData.BookingId : null,
            PackageDetails: bookingData?.PackageDetails || null,
            IsExclusiveBooking: bookingData.IsExclusiveBooking || false,
            PromoCode:  offerCode ?  offerCode :   (this.appliedOfferCode?.OfferCode ||null),
            ClearDiscount: this.removeOfferCode || null
        };

    }

    applyChanges() {
        let ratePlanRequest = { PromoCode: this.appliedOfferCode?.OfferCode ||null , RatePlan: this.ratePlanSummary, ActivityId: 0, ReservationId: this.dialogData ? this.dialogData.bookingData?.party?.Id : this.bookingData ? this.bookingData?.BookingId : null };
        ratePlanRequest.RatePlan.Reservations.forEach(reservation => {
            reservation.Sessions.forEach(session => {
                session.GuestPrices.forEach(guestPrice => {
                    guestPrice.Items.forEach(item => {
                        item.PayableUnitPrice = +item.PayableUnitPrice;
                    });
                });
            });
        });

        ratePlanRequest.RatePlan.ServiceChargeRecalculate = this.reCalculate?.form.value.reCalculateServiceCharge || false;
        ratePlanRequest.RatePlan.ReasonForNegotiation = this.negotiateReasonForm.form.value.reasonText;

        ratePlanRequest.ActivityId = this.dialogData?.bookingData ? (this.dialogData?.bookingData.party.SpecialMealId || null) : (this.bookingData.ActivityId || null);

        this._api.getRatePlanReCalculation(ratePlanRequest).subscribe(dialogData => {
            this._ps.ratePlanObject = dialogData.Payload;
            this.ratePlanSummary = dialogData.Payload.RatePlan;
            //this._ps.currentRatePlanRequest = ratePlanRequest;
            if (this.partyService.addToCartRequestObj) {
                this.partyService.addToCartRequestObj.rateSummary = {
                    Amt: this.ratePlanSummary.SubTotal,
                    TaxAmt: this.ratePlanSummary.Tax,
                    SCAmt: this.ratePlanSummary.ServiceCharge,
                    TaxOnSCAmt: this.ratePlanSummary.TaxOnServiceChargeAmount,
                    VAT: this.ratePlanSummary.VAT,
                    VATSCAmt: this.ratePlanSummary.VATOnServiceChargeAmount,
                    Discount: this.ratePlanSummary.Discount,
                    OfferCode: this.ratePlanSummary?.Discount ?  this.appliedOfferCode?.OfferCode : null
                }
            }
            this.existingRatePlanSummary = _.cloneDeep(this.ratePlanSummary)
            if(!this.ratePlanSummary.Discount && this.appliedOfferCode?.OfferCode){
                this.appliedOfferCode = null;
                this.partyService.promoCodeApplied$.next(null);
                this.removeOfferCode = true;
            }
            this.setConfigChanges();
            this.formChangeSubscribe();
        })

    }

    formChangeSubscribe() {
        if (this.negotiateReasonForm) {
            this.negotiateReasonForm.form.valueChanges.subscribe(reasonText => {
                this.setConfigChanges();
            })
        }
    }

    removeOffer(){
        this.appliedOfferCode = null;
        this.partyService.promoCodeApplied$.next(null);
        this.removeOfferCode = true;
        this.getRatePlanForActivity();
    }

    discardChanges() {
        this.applyNegotiationBtn.disbaledproperity = true;
        this.negotiateReasonForm.form.controls.reasonText.setValue('');
        this.ratePlanSummary = this.partyService.ratePlanObject.RatePlan = _.cloneDeep(this.originalRatePlan);
        this.partyService.ratePlanObject.ShopItems = _.cloneDeep(this.originalShopItems);
        this.calculateRateSummary();
        this.partyService.ratePlanSummaryState = true;
        this.ps.restrictCloseDialog = false;
    }

    calculateRateSummary() {
        if (this.partyService.addToCartRequestObj) {
            this.partyService.addToCartRequestObj.rateSummary = {
                Amt: this.ratePlanSummary.SubTotal,
                TaxAmt: this.ratePlanSummary.Tax,
                SCAmt: this.ratePlanSummary.ServiceCharge,
                TaxOnSCAmt: this.ratePlanSummary.TaxOnServiceChargeAmount,
                VAT: this.ratePlanSummary.VAT,
                VATSCAmt: this.ratePlanSummary.VATOnServiceChargeAmount,
                Discount: this.ratePlanSummary.Discount,
                OfferCode: this.ratePlanSummary?.Discount ?  this.appliedOfferCode?.OfferCode : null
            }
        }
    }

    ngAfterViewInit() {
        this.subscriptions.add(this.partyService.promoCodeApplied$.subscribe(data => {
            if(data){
            
            this.appliedOfferCode = data.offer;
            this.intializeRatePlan(data.payload);
        }
        }));
    }



    setConfigChanges() {
        this.partyService.ratePlanSummaryState = _.isEqual(this.ratePlanSummary, this.existingRatePlanSummary);
        if(this.dialogData.bookingData?.fromCheckInOrCheckout &&  !this.partyService.ratePlanSummaryState){
            this.ps.restrictCloseDialog = true;
        }else if(this.dialogData.bookingData?.fromCheckInOrCheckout &&  this.partyService.ratePlanSummaryState){
            this.ps.restrictCloseDialog = false;
        }
        if(this.ratePlanSummary?.ReasonForNegotiation != this.negotiateReasonForm?.form?.value?.reasonText) {
            this.ratePlanSummary.ReasonForNegotiation = this.negotiateReasonForm?.form?.value?.reasonText;
        }
        this.applyNegotiationBtn.disbaledproperity = this.negotiateReasonForm?.form?.value?.reasonText == '' || (JSON.stringify(this.ratePlanSummary) == JSON.stringify(this.existingRatePlanSummary))
    }

    loadConfig() {

        this.applyNegotiationBtn = {
            type: buttonTypes.actionPrimarySmall,
            label: 'apply',
            disbaledproperity: this.negotiateReasonForm ? this.negotiateReasonForm.form.value && this.ratePlanSummary != this.existingRatePlanSummary ? false : true : true,
            customclass: 'rate-plan__apply',
        }
        this.cancelNegotiationBtn = {
            type: buttonTypes.actionSecondarySmall,
            label: 'reset',
            disbaledproperity: false,
            customclass: 'rate-plan__cancel',
        }
        this.recalculateConfig = [{
            type: 'switch',
            name: 'reCalculateServiceCharge',
            inputType: 'text',
            label: '',
            class: 'rate-plan__recalculate seat-grid-content',
            checked: false,
            value: this.dialogData?.bookingData?.financialData?.RatePlan?.ServiceChargeRecalculate || this.dialogData?.componentDetails?.tabs?.componentInput?.RatePlan?.ServiceChargeRecalculate || false
        }
        ];
        this.rateNegotiateConfig = [
            {
                name: 'reasonText',
                type: 'textarea',
                rows: 1,
                inputType: 'text',
                class: 'w-100 rateplan__reason',
                value: this.dialogData?.componentDetails?.tabs?.componentInput?.RatePlan?.ReasonForNegotiation ||  this.dialogData?.bookingData?.financialData?.RatePlan?.ReasonForNegotiation || '',
                validation: [Validators.required],
                disableErrorStateMatcher: true,
                blurClick: this.setConfigChanges.bind(this)
            }]
    }

    ShowRestPolicyDetailsClick() {
        this.ShowRestPolicyDetails(this.restaurantPolicy);
    }

    ShowRestPolicyDetails(restaurantPolicy) {
        const componentDetails: ComponentDetails = {
            componentName: RestaurantPoliciesComponent,
            popupType: 'action',
            popUpDetails: {
                isStepper: false,
                eventName: 'notifyParent'
            },
            popupTitle: restaurantPolicy.PreReservationMessageTitle
        };
        const dialogRef = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            height: '60%',
            width: '40%',
            data: {
                title: restaurantPolicy.PreReservationMessageTitle,
                update: 'ok',
                componentDetails,
                from: ComponentTypes.restaurantPolicies,
                back: false,
                standalone: true,
                showAction: true
            },
        });

    }

    ngOnDestroy(): void {
        this.appliedOfferCode = null;
        this.partyService.currentRatePlanRequest = null;
        this.partyService.promoCodeApplied$.next(null);
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
       
    }

}


@Pipe({
    name: 'getActivityName'
})
export class SpecialMealNamePipe implements PipeTransform {
    constructor(private ts: TranslateService) { }
    transform(specialMealId, settings): any {
        return settings.SpecialMeals.find(meal => meal.Id == specialMealId)?.Name || this.ts.instant('OpenBooking');
    }
}
