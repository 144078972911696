<div class="additionalCharge" *ngIf = "additionalChargeObj">
    <div class="additionalCharge__paymentInfo seat-text-fields" *ngIf="additionalChargeObj.AdditionalCharge > 0">
        <span>{{ 'additionalCharge' | translate }}: {{operationCurrency}}{{additionalChargeObj.AdditionalCharge | currencyDecimalFormatter}} </span>
    </div>
    <div class="additionalCharge__paymentInfo seat-text-fields" *ngIf="additionalChargeObj.TaxAmount > 0">
        <span>{{ 'tax' | translate }}: {{operationCurrency}}{{additionalChargeObj.TaxAmount | currencyDecimalFormatter}} </span>
    </div>
    <div class="additionalCharge__paymentInfo seat-text-fields" *ngIf="additionalChargeObj.TaxAmount > 0">
        <span>{{ 'findTotal' | translate }}: {{operationCurrency}}{{(additionalChargeObj.AdditionalCharge + additionalChargeObj.TaxAmount) | currencyDecimalFormatter}} </span>
    </div>
    <div class="additionalCharge__paymentOfflineInfo seat-text-fields" *ngIf="SplitPaymentMessage">
        <span>{{SplitPaymentMessage}} </span>
    </div>
    <div class="additionalCharge__paymentOfflineInfo seat-text-fields" *ngIf="!additionalChargeObj.IframeUrl">
        <span>{{ 'SorryForPaymentOffline' | translate }} </span>
    </div>
    <div class="additionalCharge__paymentIframe" *ngIf="additionalChargeObj.IframeUrl">
        <app-iframe-container [iframe]="additionalChargeObj.IframeUrl" [SaveBookingObj]="additionalChargeObj"
            (ReservationAttemptId)="additionalPaymentTransaction($event)" [componentType]="fromComponent">
        </app-iframe-container>
    </div>
</div>


