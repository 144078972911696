
import { Injectable } from '@angular/core';
import { API } from 'src/app/common/data-magine/data-magine-doc/data-magine-doc.model';
import { InternalIntegrationCommunication } from '../communication/services/internal-intergration-service';
import { CommonApiRoutes } from "../common-route";

@Injectable({
    providedIn: 'root'
})
export class DmDocTypesDataService {

    constructor(private v1InternalIntegration: InternalIntegrationCommunication) {
    }

    public getAllDmDocTypes(IncludeInActive: boolean = false): Promise<API.DmDocTypes[]> {
        const result = this.v1InternalIntegration.getPromise<API.DmDocTypes[]>(
            { route: CommonApiRoutes.GetAllDmDocTypes, uriParams: { IncludeInActive } }, false);
        return result;
    }
    public getDmDocTypes(id: number): Promise<API.DmDocTypes> {
        const result = this.v1InternalIntegration.getPromise<API.DmDocTypes>(
            { route: CommonApiRoutes.GetDmDocTypes, uriParams: { id } }, false);
        return result;
    }
    public createDmDocTypes(body: API.DmDocTypes): Promise<API.DmDocTypes[]> {
        const result = this.v1InternalIntegration.postPromise<API.DmDocTypes[]>(
            { route: CommonApiRoutes.CreateDmDocTypes, body }, true);
        return result;
    }
    public updateDmDocTypes(body: API.DmDocTypes): Promise<API.DmDocTypes[]> {
        const result = this.v1InternalIntegration.putPromise<API.DmDocTypes[]>(
            { route: CommonApiRoutes.UpdateDmDocTypes, body }, false);
        return result;
    }
    public deleteDmDocTypes(_id: number): Promise<boolean> {
        const result = this.v1InternalIntegration.deletePromise<boolean>(
            { route: CommonApiRoutes.DeleteDmDocTypes, uriParams: { id: _id } }, false);
        return result;
    }

    public async getNextListOrder(): Promise<number> {
        const result: number = await this.v1InternalIntegration.getPromise<number>(
            { route: CommonApiRoutes.GetNextListOrder_DmDocTypes });
        return result;
    }
}

