import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VendorAssign, VendorHeaderOption, VendorInfo } from './add-vendors-popup.model';
import { selecttypeenum, FromTypeenum } from 'src/app/retail/shared/virtual-scroller/virtual-scroller.model';
import {  ReplaySubject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import {  takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { Vendor, BaseResponse } from 'src/app/retail/retail-vendor-setup/retail-vendor-setup.modals';
import { Host } from 'src/app/retail/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/retail/shared/service/http-call.service';

@Component({
  selector: 'app-add-vendors-popup',
  templateUrl: './add-vendors-popup.component.html',
  styleUrls: ['./add-vendors-popup.component.scss']
})
export class AddVendorsPopupComponent implements OnInit {
  filterData: any = [];
  tableoptions: any;
  captions: any;
  headerOption: VendorHeaderOption[];
  bodyContentdata: VendorAssign[] = [];
  tableOptions: { selectType: selecttypeenum; dragDisabled: boolean; defaultsortingColoumnKey: string; key: string };
  searchControl = new UntypedFormControl();
  searchoptions: any[] = [];
  tableContent: any[] = [];
  filteredOptions: Promise<any[]>;
  isInputAvailable: boolean = false;
  isSaveDisabled: boolean = true;
  isRemoveDisabled: boolean = true;
  checkedRows: VendorAssign[];
  deletedItems: VendorAssign[];
  updatedItems: VendorAssign[];
  createdItems: VendorAssign[];
  selectedVendorIds: number[] = [];
  vendors: any[] = [];
  searchText: string;
  isViewMode: boolean;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedVendorId: number = 0;
  floatLabel: string;
  floatLabelNever: any;
  @ViewChild('tableComponent', { static: true }) virtualScroller;

  constructor(private _localization: Localization, @Inject(MAT_DIALOG_DATA) public data: any,private http: HttpServiceCall,
              private dialogRef: MatDialogRef<any>) {
      this.captions = this._localization.captions.retailsetup;
      this.floatLabel = this._localization.setFloatLabel;
      this.floatLabelNever = this._localization.setFloatLabelNever;
    }

  ngOnInit() {
    this.pageInitializations();
    const bodyContentdata: any[] = this.data.popupConfig.data ? this.data.popupConfig.data : [];
    this.selectedVendorIds = bodyContentdata && bodyContentdata.map(o => o.vendorId);
    this.GetVendors().then(x => {
      this.vendors = x?x.filter(y=>y.isActive == true):x;
      this.tableContent = this.mapToGridUI(this.vendors);
      if (bodyContentdata?.length) {
        this.selectedVendorId = this.selectedVendorIds[0];
        const index = this.tableContent.findIndex(v => v.vendorId === this.selectedVendorId);
        this.tableContent[index] = bodyContentdata.find(v => v.vendorId === this.selectedVendorId);
        this.tableContent[index].checked = true;
      }
      this.bodyContentdata = this.tableContent ? this.tableContent : [];
    });
    this.searchControl.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(value => {
      //this.filteredOptions = this._filter(value);
      this.searchText = value;
    });
  }
  ngOnDestroy() {
    if (this.$destroyed) {
      this.$destroyed.next(true);
      this.$destroyed.complete();
    }
  }

  pageInitializations(){
    this.tableOptions = {
      selectType: selecttypeenum.radiobutton,
      dragDisabled: true,
      defaultsortingColoumnKey: 'vendorCode',
      key: 'vendorId'
    };

    this.headerOption = [
      {
        key: 'vendorCode',
        description: this.captions.VendorCode,
        alignment: 'textLeft',
        sorting: true,
        searchable: true
      }, {
        key: 'vendorName',
        description: this.captions.VendorName,
        alignment: 'textLeft',
        sorting: true,
        searchable: true
      }, {
        key: 'supplierItemNumber',
        description: this.captions.SuppliarItemNumber,
        alignment: 'textCenter',
        templatecontainer: {
          templateName: 'inputTemplate',
        },
        sorting: false,
        searchable: false,
        maxlength: 25
      }, {
        key: 'qoh',
        description: this.captions.Qoh,
        alignment: 'textCenter',
        templatecontainer: {
          templateName: 'inputNumberTemplate',
        },
        sorting: false,
        searchable: false,
        min: 1,
        max: 100000
      }, {
        key: 'leadDays',
        description: this.captions.LeadDays,
        alignment: 'textCenter',
        templatecontainer: {
          templateName: 'inputNumberTemplate',
        },
        sorting: false,
        searchable: false,
        min: 1,
        max: 365
      }, {
        key: 'minimumOrder',
        description: this.captions.MinimumOrder,
        alignment: 'textCenter',
        templatecontainer: {
          templateName: 'inputNumberTemplate',
        },
        sorting: false,
        searchable: false,
        min: 1,
        max: 10000
      }, {
        key: 'unitQty',
        description: this.captions.UnitQty,
        alignment: 'textCenter',
        templatecontainer: {
          templateName: 'inputNumberTemplate',
        },
        sorting: false,
        searchable: false,
        min: 1,
        max: 10000
      }];
  }

  async _filter(value: string) {
    this.filterData = [];
    value = value ? value.toString() : "";
    value = value.trim();
    const filterValue = value.toLowerCase();

    if (value.length >= 3) {
      this.searchoptions = this.mapToGridUI(this.vendors);
      this.searchoptions = this.searchoptions && this.searchoptions.length &&
      this.searchoptions.filter(o => !this.selectedVendorIds.includes(o.vendorId));
    }

    else if (value.length <= 2) {
      this.filterData = [];
      this.searchoptions = [];
    }

    return this.searchoptions && this.searchoptions.length > 0 ?
    this.searchoptions.filter(option => ((option['vendorName'].toLowerCase().indexOf(filterValue) > -1)
    || (option['vendorCode'].toLowerCase().indexOf(filterValue) === 0))) : [];
  }

  onOptionChange(e) {
    const selectedItem = e.option.value;
    selectedItem['status'] = 'create';
    this.bodyContentdata.push(selectedItem);
    this.bodyContentdata = [...this.bodyContentdata];
    this.searchControl.setValue('');
    this.isSaveDisabled = false;
  }

  clearSearch(e) {
    this.searchControl.setValue('');
    this.searchText = '';
  }

  emitteddatavalue(e) {
    this.isSaveDisabled = false;
    this.checkedRows = e.checkedData;
    this.bodyContentdata.forEach((x) => {
      if (e.Obj.vendorId != x.vendorId) {
        x.checked = false;
      }
      else {
        x.checked = true;
      }
    });
    this.bodyContentdata = [...this.bodyContentdata];
    this.selectedVendorId = this.bodyContentdata.find(x => x.checked).vendorId;
    this.selectedVendorIds = this.bodyContentdata && this.bodyContentdata.map(o => o.vendorId);
    this.isRemoveDisabled = (this.checkedRows.length > 0) ? false : true;
    if (e.fromType === FromTypeenum.input){
      const itemEdited = _.cloneDeep(e.Obj);
      if (!e.Obj.status){
        this.updatedItems = itemEdited;
        this.updatedItems['status'] = 'updated';
      }
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onRemove(e) {
    let itemsChecked = _.cloneDeep(this.checkedRows);
    _.remove(this.bodyContentdata, (el) => {
      return itemsChecked && itemsChecked.some(selected => selected['vendorId'] === el['vendorId']);
    });
    this.deletedItems = itemsChecked.filter(x => (!x.status) || (x.status !== 'create'));
    this.deletedItems.forEach(element => {
      element.status = 'deleted';
    });
    this.selectedVendorIds = this.bodyContentdata && this.bodyContentdata.map(o => o.vendorId);
    console.log("Deleted Items List", this.deletedItems);
    this.isRemoveDisabled = true;
    this.virtualScroller.checkeddata = [];
  }

  onSave(e) {
    this.createdItems = this.bodyContentdata.filter(x => x.status === 'create');
    this.dialogRef.close(this.bodyContentdata.filter(x => x.checked));
  }

  onCancel(e) {
    this.dialogRef.close();
  }

  async SearchVendors(value: string): Promise<Vendor[]> {
    try {
      return await this.InvokeHttpCalls<Vendor[]>(Host.retailManagement, "SearchVendors", HttpMethod.Get, undefined, { pattern: value });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async GetVendors(): Promise<Vendor[]> {
    try {
      return await this.InvokeHttpCalls<Vendor[]>(Host.retailManagement, 'GetVendorInfo', HttpMethod.Put, undefined);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  switchNoVendors(event) {
    this.isViewMode = event.checked;
    if (event && event.checked) {
      const index = this.bodyContentdata.findIndex(v => v.checked);
      this.bodyContentdata[index].checked = !this.isViewMode;
    }
    else {
      if (this.selectedVendorId > 0) {
        this.bodyContentdata.find(v => v.vendorId === this.selectedVendorId).checked = !this.isViewMode;
      }
    }
    this.bodyContentdata = this.bodyContentdata ? this.bodyContentdata : [];
    this.isSaveDisabled = false;
  }

  public mapToGridUI(vendorAPI: Vendor[]) {
    if (vendorAPI && vendorAPI.length > 0){
      const vendorDataUI: VendorAssign[] = vendorAPI.map(c => {
        return {
          id: 0,
          vendorId : c.id,
          retailItemId : 0,
          listOrder : c.listOrder,
          vendorCode : c.code,
          vendorName : c.name,
          supplierItemNumber: '',
          qoh: 0,
          leadDays: 0,
          minimumOrder: 0,
          unitQty: 0,
          checked: false
        };
      });
      return vendorDataUI.sort(x => x.listOrder);
    }
    else{
      return [];
    }
  }

  private async InvokeHttpCalls<T>(host: Host, callDesc: string, callType: HttpMethod, body?: Vendor, uRIParams?: any): Promise<T> {
    try 
    {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: host,
            method: callType,
            body: body,
            uriParams: uRIParams
        });
        return response.result;
    }
    catch (ex) {
        return null;
    }
}
}
