import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { PartyService } from '@app/shared/services/party.service';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes } from '@constants/commonenums';
import { dateNavigation } from '@constants/globalConstants';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import moment from 'moment';
import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '@app/core/services/cache.service';
import { SettingType } from '@app/shared/models/RestaurantDTO';
@Component({
  selector: 'app-form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['form-date.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, deps:[PartyService], useFactory: (partyService) => partyService.getDateFormats()},
  ],
})
export class FormdateComponent implements Field, OnInit, OnDestroy {
  public FormControlName;
  config: FieldConfig;
  group: UntypedFormGroup;
  selectedDate = null;
  _minDate: Date;
  enableClearDate = false;
  today = new Date();
  hideTodayButton: boolean = false;
  subscriptions: Subscription = new Subscription();
  showDayName:boolean = false;
  constructor(private dialog: MatDialog, private translateService: TranslateService, private cs: CacheService) {
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      if(sett?.MerchantSettings?.ShowDayNameinTimeline?.SettingValue == SettingType.ENABLE) {
        this.showDayName = true;
      }
      else {
        this.showDayName = false;
      }
    }));
   }

  ngOnInit() {
    this.FormControlName = this.config.name;
    this.enableClearDate = this.config.enableClearDate || false;
    this._minDate = this.config.minDate ? new Date(this.config.minDate.setHours(0, 0, 0, 0)) : null;
    this.subscriptions.add(this.group.controls[this.config.name].valueChanges.subscribe(updatedDate => {
      this.onDateChange(new Date(updatedDate));
    }));
    this.onDateChange(this.group.controls[this.config.name].value);
  }
  onDateChange(updatedDate) {
    const isJSDate = moment.isDate(updatedDate);
    if(this.group.controls[this.config.name].value)
    this.selectedDate = new Date(this.group.controls[this.config.name].value);
    if (!isJSDate) {
      updatedDate = moment(updatedDate).toDate();
    }
    this.hideTodayButton = updatedDate.getDate() === this.today.getDate()
      && updatedDate.getMonth() === this.today.getMonth()
      && updatedDate.getFullYear() === this.today.getFullYear();
  }

  navigate(event, state) {
  
    const day = new Date(this.group.controls[this.config.name].value);
    const nextDay = state === 'now' ? this.today : new Date(day);
    if (state !== 'now') {
      if (state == dateNavigation.previous) {
        nextDay.setDate(day.getDate() - (this.config.navigationIncrementer || 1));
      }
      else {
        nextDay.setDate(day.getDate() + (this.config.navigationIncrementer || 1));
      }
    }
    if (nextDay < this._minDate) {//restrict for past reservations > 72hours

      if(this.config.isReadOnly){
        event.preventDefault();
        event.stopPropagation();
       }

      let message = this.translateService.instant('minDateValidationMessage', {hours: this.config.AllowEditForPastReservationsInDays * 24});
      const popUpMessage = [{
        confirmationMessage: message, dialogTitle: 'confirm', showAlert: true
      }];
      const componentDetails: ComponentDetails = {
        componentName: ConfirmationPopupComponent,
        popupType: 'action',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: popUpMessage,
        popupTitle: 'Alert'
      };
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '450px',
        height: 'auto',
        data: {
          showAction: true,
          update: 'ok',
          componentDetails,
          from: ComponentTypes.reservation
        }
      });
    }
    else {
      this.group.controls[this.config.name].setValue(nextDay);
    }

  }
  clearDateSelection(event){
    this.group.controls[this.config.name].setValue(undefined);
    this.selectedDate = null;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
