import { Localization } from 'src/app/common/localization/localization';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AgFieldConfig } from '../../Models/ag-models';
import { AgMenuEditComponent } from '../ag-menu-edit/ag-menu-edit.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-ag-percentage',
  templateUrl: './ag-percentage.component.html'
})
export class AgPercentageComponent implements OnInit {
  customClass: string;
  errorMessage: string;
  groupName: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  disabled?: boolean;
  maxValue: number | string;
  minValue: number | string;
  isRequired: boolean;
    captions: any;
    floatLabel: string;
    inputType: string;
  placeHolderId: string;
  errorMessageId: string;
  automationId: string;
  predecimalmaxlength: number;

  @Input('inputs')
  set inputOptions(value: AgFieldConfig) {
    if(value) {
      this.customClass = value.className;
      this.errorMessage = value.errorMessage;
      this.groupName = value.form;
      this.controlName = value.formControlName;
      this.placeHolder = value.placeHolder;
      this.value = value.value;
      this.disabled = value.disabled;
      this.maxValue = value.maxValue;
        this.minValue = value.minValue;
        this.predecimalmaxlength = value.predecimalmaxlength;
        this.floatLabel = this.localization.setFloatLabel? this.localization.setFloatLabel: (value.floatLabel ? value.floatLabel : '');
        this.inputType = value.inputType ? value.inputType : 'nonnegative,decimal,validPercentage';
        this.isRequired = value.isRequired;
      this.errorMessageId = value.errorMessageId;
      this.placeHolderId = value.placeHolderId;
      this.automationId = value.automationId ? value.automationId: '';
      const validatorList = [];
      if (this.minValue) {
        validatorList.push(Validators.min(this.minValue));
      }
      if (this.maxValue) {
        validatorList.push(Validators.max(this.maxValue));
      }
        if (this.isRequired) {
            validatorList.push(Validators.required);
        }
        if (validatorList.length) {
            this.groupName.controls[value.formControlName].setValidators(validatorList);         
        }
      if (this.disabled) {
        this.groupName.controls[value.formControlName].disable();
      } else {
        this.groupName.controls[value.formControlName].enable();
      }
    }
   
  }

  constructor(private localization: Localization, public dialog: MatDialog) { }

  ngOnInit() {
    this.captions = this.localization.captions;
    }

  onControlClick() {
    if (document.designMode == 'on') {
      this.dialog.open(AgMenuEditComponent, {
        width: '700px',
        height: '700px',
        data: {
          oldPlaceHolder: this.placeHolder,
          oldErrorMessage: this.errorMessage,
        },
        disableClose: true
      }).afterClosed().subscribe(result => {
        if (result) {
          this.placeHolder = result.newplaceholder;
          this.errorMessage = result.newErrorMessage;
        }
      });
    }
  }
}
