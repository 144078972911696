<section class="retail-code-setup newVersionWrapper retail-credit-card-terminal-wrapper h-100" id="creditcard" *ngIf="!useRetailInterface">
  <app-table-search-header [attr.automationId]="'Srch_retailCreditCardTerminal_retailCreditcard'" [searchHeaderOption]="searchHeaderOption" (searchChange)="searchChange($event)"
    (showInactive)="showInactiveToggler($event)" (createType)="createEvent($event)">
  </app-table-search-header>

  <div class="retailGrid">
    <app-retail-table [attr.automationId]="'Tbl_retailCreditCardTerminal_retailCreditcard'" [searchvalue]="searchText" [options]="tableoptions" (doneEvtEmitter)="Done($event)"
      (cancelEmitter)="Cancel($event)" (afterEditClose)="afterEditPopupClose($event)" (dragDropEvt)="dragDrop($event)"
      (deleteEvt)="deleteCCTerminal($event)" (addClick)="addOutlet($event)" (editEvt)="EditRecords($event)"
      (toggleEvtEmitter)="sliderChange($event)" [isOnEditDisableFunctionAvailable]="onEditDisableFunction" [isDeleteEnableForDefault]="true"
      [isEditModeSetFromParent]="isEditFlag"></app-retail-table>
  </div>
</section>
