import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PricingCategory, PricingType, SelectionType } from "@app/shared/models/RestaurantDTO";
import { ReservationAddonsService } from "./reservation-addons.service";

@Component({
    selector: 'reservation-guest-addons',
    templateUrl: './guest-addons.component.html',
    styleUrls: ['./guest-addons.component.scss']
})
export class ReservationGuestAddonsComponent implements OnInit{
    PricingCategory = PricingCategory;
    PricingType = PricingType;
    @Output() addonsChange = new EventEmitter<any>();
    @Input() addons: any[];
    // @Output() validGuestAddonQuantitiyChange = new EventEmitter<boolean>();
    // @Input() validGuestAddonQuantitiy: boolean;
    
    constructor(public reservationAddonsService: ReservationAddonsService){}

    ngOnInit(): void {
        this.addons.forEach(addonItem => {
            this.addonUpdate(addonItem, false);
        });
        this.addonsChange.emit(this.addons);

        // this.updateGuestAddonValidity();        
    }

    addonUpdate(addOnItem, undateGuestAddonValidity = true){
        this.reservationAddonsService.validPerGuestAddonQuantity(addOnItem);
        if(undateGuestAddonValidity){
            this.addonsChange.emit(this.addons);
            // this.updateGuestAddonValidity();
        }
        
    }
    
    // updateGuestAddonValidity(){
    //     let isValidGuestAddonsSelected = !this.addons.find(guestAddon => this.reservationAddonsService.allAddons[guestAddon.Id].SelectionType === SelectionType.PerGuest && !guestAddon.Valid)
    //     this.validGuestAddonQuantitiyChange.emit(isValidGuestAddonsSelected);
    // }

    increaseQuantity(addOnItem){
        this.reservationAddonsService.increaseAddOn(addOnItem)
        this.addonUpdate(addOnItem);
    }
    reduceQuantity(addOnItem){
        this.reservationAddonsService.decreaseAddOn(addOnItem)
        this.addonUpdate(addOnItem);
    }
}