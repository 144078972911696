import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, Input, OnInit, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { ClientLabel } from '../../retail.modals';
import { RetailService } from '../../retail.service';
import { RetailDataAwaiters } from '../events/awaiters/retail.data.awaiters';
import { ClientType, DefaultGUID, Product } from '../globalsContant';
import { RetailUtilities } from '../utilities/retail-utilities';
import { PayeeInfo } from '../business/shared.modals';
@Component({
  selector: 'retail-chips-autocomplete-example',
  templateUrl: 'multiplesearch.component.html',
  styleUrls: ['multiplesearch.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class RetailChipsAutocompleteExample implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  change = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  searchCtrl = new UntypedFormControl();
  searchResults: Observable<any[]>;
  expandBool: boolean = false;
  searchValue = '';
  initialValue: number;
  requestUid = '';
  timer = null;

  @ViewChild('dataInput') dataInput: ElementRef;
  @Input() DatainputArray: any;
  @Input() DefaultData: any;
  @Input() Datalimit: any;
  @Input() Disabled: boolean;
  @Input() clearInputOnAddClient: number;
  @Input() automationId : string = '';
  @Output() messageEvent = new EventEmitter<any>();
  @Output() selectedEvent = new EventEmitter<any>();
  @Output() removedEvent = new EventEmitter<any>();
  @Output() removedCompleteSearch = new EventEmitter<any>();

  public inputIcons: any = false;
  captions: any = this.localization.captions.bookAppointment;
  allCaptions: any = this.localization.captions;
  guestProfileId: any;
  productId: number;
  showProfileId: boolean;
  floatLabelNever: string;
  constructor(public _as: RetailService, public localization: RetailLocalization, private util: RetailUtilities) {
    this._as.isClientCompleted = this._as.isClientCompleted ? this._as.isClientCompleted : false;
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.showProfileId = (this.productId == Product.SNC);
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }


  sendMessage(e: any) {
    this.inputIcons = true;
    let inputValue = this.dataInput.nativeElement.value;
    if (inputValue.length >= 3) {
      this.requestUid = Date.now() + "" + this.util.getRandomDecimal() * 10000;
      if (this.timer) {
        clearTimeout(this.timer); //cancel the previous timer.
        this.timer = null;
      }
      this.timer = setTimeout(this.emitEvent.bind(this), 1000, this.dataInput.nativeElement.value, this.requestUid);
    } else {
      this._as.clientsearchArray = [];
      this.inputIcons = false;
    }
  }

  emitEvent(searchValue, requestUid) {
    this.messageEvent.emit({ searchValue: searchValue, requestUid: requestUid })
  }

  add(event: MatChipInputEvent): void {
    this._as.clientsearchArray = [];
    const input = event.input;
    const value = event.value;
    // Reset the input value
    if (input) {
      input.value = value;
    }

    this.searchCtrl.setValue(null);
  }
  /***
   * @function remove
   * @input Params <Array>
   * OutPut Array
   * splice the Array
   */

  remove(clientId: number): void {
    if (this.Disabled) {
      return;
    }
    this._as.isClientCompleted = false;

    this._as.clientsearchArray = [];
    if (this._as.labelRecords.length == 1) {
      this.inputIcons = false;
    }
    if (this._as.labelRecords.length == 2) {
      this._as.multipleRecords = false;
    }
    if (clientId != 0) {
      this._as.labelRecords = this._as.labelRecords.filter(r => r.Id != clientId);
      this._as.recordsArray = this._as.recordsArray.filter(r => r.id != clientId);
      this._as.multiClientInfo = this._as.multiClientInfo.filter(r => r.id != clientId);
    }
    if (this._as.labelRecords.length > 0) {
      this._as.isClientCompleted = true;
    }
    this.removedEvent.emit(clientId);
  }

  /***
   * @function deleteaallRecords
   * @input Params <Array>
   * OutPut Array
   * Delete all Records form the Array
   */

  deleteaallRecords() {

    this.dataInput.nativeElement.value = '';
    this._as.clientsearchArray = [];
    // this.inputIcons = true;
    this.inputIcons = false;
    this._as.labelRecords = [];
    this._as.recordsArray = [];
    this._as.multiClientInfo = [];
    this.removedEvent.emit();
    this.removedCompleteSearch.emit();
  }

  async createPlayer(guestObj: any) {
    let guestData = [];
    if (this.productId === Product.GOLF) {
      guestData = this._as.guestInfo.find(client => client.playerLinkId === guestObj.guestProfileId);
    } else if (this.productId === Product.SPA) {
        if(this._as.clientSelect == 1){
          guestData = this._as.guestInfo.find(client => client.playerLinkId === guestObj.guestProfileId);
        }else{
          guestData = this._as.guestInfo.find(client => client.clientDetail.guestId === guestObj.guestProfileId);
        }      
    } else if (this.productId === Product.RETAIL || this.productId === Product.PMS) {
      if (guestObj.playerCategoryId == 1) {
        guestData = this._as.guestInfo.find(client => client.guestId === guestObj.guestProfileId);
      } else if (guestObj.playerCategoryId == 3) {
        guestData = this._as.guestInfo.find(client => client.playerLinkId === guestObj.guestProfileId);
      }
    }
    await RetailDataAwaiters.CreatePlayer(guestData, this.setPlayerId.bind(this));
    guestObj.id = this._as.payeedId;
    return this._as.payeedId;
  }
  async GetGuestByPlatformGuestGuid(platformGuestGuid : string) : Promise<PayeeInfo>
  {
      return await RetailDataAwaiters.GetGuestByPlatformGuestGuid(platformGuestGuid)
  }
  async updateGuestProfile(guestId: string, playerId: number) {
    await RetailDataAwaiters.updateGuestProfile(guestId, playerId);
  }

  setPlayerId(id,guestid?,guestDetail?) {
    let clientDetail = guestDetail || this._as.clientsearchArray.find(client => client.guestProfileId === this.guestProfileId);
    clientDetail.guestId = guestid;
    this._as.payeedId = id;
    var clientData: ClientLabel = {
      Id: id,
      Name: clientDetail.name
    };
    this._as.labelRecords.push(clientData);
    if (this._as.labelRecords.length > 0) {
      this._as.isClientCompleted = true;
    }
    this._as.memberGuestId =  guestid;
  }

  /***
 * @function selected
 * @input Params <Array>
 * OutPut Array
 * Selected the Particular object form the Listing Array
 */
  async selected(event: MatAutocompleteSelectedEvent) {
    this._as.isClientCompleted = false;
   // let clientDetail;
    // if (event && event.option && event.option.value && (!event.option.value.guestProfileId || event.option.value.guestProfileId == DefaultGUID) && (event.option.value.platformGuestUuid && event.option.value.platformGuestUuid != DefaultGUID))
    // {
    //   let platformClientDetail = await this.GetGuestByPlatformGuestGuid(event.option.value.platformGuestUuid);
    //   this.setGuestGuidByProduct(platformClientDetail);
    //   clientDetail = this._as.clientsearchArray.find(client => client.guestProfileId === platformClientDetail.guestProfileId);
    // }
    // else
    // {
    //   clientDetail = this._as.clientsearchArray.find(client => client.guestProfileId === event.option.value.guestProfileId);
    // }
    let clientDetail = event.option.value;
    this.guestProfileId = clientDetail.guestProfileId;
    const guestId = clientDetail.id;
    if (guestId == 0) {
      let playerId = await this.createPlayer(clientDetail);
      if (clientDetail.playerCategoryId != 3)
        await this.updateGuestProfile(this.guestProfileId, playerId);
    } else {
      this.setPlayerId(guestId,null,clientDetail);
    }
    this.dataInput.nativeElement.value = "";
    this.searchCtrl.setValue(null);
    this._as.recordsArray.push(clientDetail);
    this._as.clientsearchArray = [];
    clientDetail.guestId =  this._as.memberGuestId ;
    clientDetail.guesttype = this._as.memberGuestId? ClientType.Member : ClientType.Guest;
    this.selectedEvent.emit(clientDetail);
  }
  setGuestGuidByProduct(payeeInfo : PayeeInfo)
  {
    let guestData;
    let clientDetail = this._as.clientsearchArray.find(client => client.platformGuestUuid === payeeInfo.platformGuestUuid);
    if (clientDetail) {
      clientDetail.guestProfileId = payeeInfo.guestProfileId;
      clientDetail.id = payeeInfo.id;
      clientDetail.playerLinkId = payeeInfo.guestProfileId;
    }
    if (this.productId === Product.GOLF) {
      guestData = this._as.guestInfo.find(client => client.platformUuId === payeeInfo.platformGuestUuid);
      if (guestData)
      {
        guestData.id = payeeInfo.id;
        guestData.playerLinkId = payeeInfo.guestProfileId;
      }
    } else if (this.productId === Product.SPA) {
      guestData = this._as.guestInfo.find(client => client.clientDetail.platformGuestUuid === payeeInfo.platformGuestUuid);
      if (guestData) {
        guestData.clientDetail.guestId = payeeInfo.guestProfileId;
        guestData.id = payeeInfo.id;
      }
    } else if (this.productId === Product.RETAIL || this.productId === Product.PMS) {
      if (payeeInfo.playerCategoryId == 1) {
        guestData = this._as.guestInfo.find(client => client.platformGuestUuid === payeeInfo.platformGuestUuid);
        if (guestData) {
          guestData.guestId = payeeInfo.guestProfileId;
          guestData.id = payeeInfo.id;
        }
      }
    }
  }
  expand() {
    this.expandBool = true;
  }

  clearInput() {
    if (this.dataInput.nativeElement.value.length <= 2) {
      this._as.clientsearchArray = [];
    }
  }

  ngOnInit() {
    this.initialValue = this.clearInputOnAddClient;
  }

  ngOnChanges() {
    if (this.initialValue != this.clearInputOnAddClient) {
      this.dataInput.nativeElement.value = '';
      this.initialValue = this.clearInputOnAddClient;
    }
  }
}

