import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { RadioOptions } from 'src/app/common/Models/ag-models';
import { EventSchedulerInterval } from '../event-scheduler.model';

@Injectable()
export class NotificationScheduleBusiness {
  captions: any;

  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */
  constructor(private localization: Localization) {
    this.captions = this.localization.captions.ReportSchedular;
  }

  getEventOptionsList(): RadioOptions[] {
    return [
      {
        id: 1,
        value: this.captions.lbl_recurrenceAutoSchedule,
        viewValue: this.captions.lbl_recurrenceAutoSchedule
      },
      {
        id: 2,
        value: this.captions.lbl_runOnDemand,
        viewValue: this.captions.lbl_runOnDemand
      }
    ];
  }

  getDaysArray() {
    return this.localization.getDaysArray();
  }

  getEndOptionsList(): RadioOptions[] {
    return [
      {
        id: 1,
        value: this.captions.lbl_onThisDay,
        viewValue: this.captions.lbl_onThisDay
      },
      {
        id: 2,
        value: this.captions.lbl_noEndDate,
        viewValue: this.captions.lbl_noEndDate
      }
    ];
  }

  getMonthDayOptionsList(): RadioOptions[] {
    return [
      {
        id: 1,
        value: this.captions.lbl_day,
        viewValue: this.captions.lbl_day
      },
      {
        id: 2,
        value: this.captions.lbl_first,
        viewValue: this.captions.lbl_first
      },
      {
        id: 3,
        value: this.captions.lbl_last,
        viewValue: this.captions.lbl_last
      }
    ];
  }

  generateScheduleButtons(): any[] {
    return [
      {
        id: EventSchedulerInterval.Daily,
        value: EventSchedulerInterval.Daily.toString(),
        viewValue: this.captions.lbl_daily,
        isSelected: false
      },
      {
        id: EventSchedulerInterval.Weekly,
        value: EventSchedulerInterval.Weekly.toString(),
        viewValue: this.captions.lbl_weekly,
        isSelected: false
      },
      {
        id: EventSchedulerInterval.Monthly,
        value: EventSchedulerInterval.Monthly.toString(),
        viewValue: this.captions.lbl_monthly,
        isSelected: false
      },
      {
        id: EventSchedulerInterval.Yearly,
        value: EventSchedulerInterval.Yearly.toString(),
        viewValue: this.captions.lbl_yearly,
        isSelected: false
      }
    ];
  }

}
