import { Injectable } from '@angular/core';
import { EngageMemberByCardIdRequestDTO } from '@app/shared/models/EngageMemberByCardIdRequestDTO';
import { urlConfig } from '@constants/url-config';
import { HttpService } from '@services/http.service';
import { Utilities } from '@utilities/utilities';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
}
)
export class ApiService {

  constructor(public _http: HttpService) {
  }
  // GetRatePlanCalculated(Slots, GuestTypes, location, Size, BookingBehavior,MemberType,IsMemberActive) {
  //   return this._http.post(`${urlConfig.GetRatePlanCalculatedURL}?restaurantId=${Utilities.RestaurantId()}&options=0`, {
  //     Slots,
  //     GuestTypes,
  //     TableIds: [location],
  //     Size,
  //     BookingBehavior,
  //     MemberType,
  //     IsMemberActive,
  //   })
  // }
  GetRatePlanCalculated(request,FinancialEffectId? , ignoreBookingValidation:boolean = false) {
    return this._http.post(`${urlConfig.GetRatePlanCalculatedURL}?restaurantId=${Utilities.RestaurantId()}&options=0&financialEffectId=${FinancialEffectId}&ignoreValidation=${ignoreBookingValidation}`, request)
  }

  GetTaxCalculated(request) {
    return this._http.post(`${urlConfig.GetTaxCalculatedURL}?restaurantId=${Utilities.RestaurantId()}`, request)
  }

  GetTaxCalculatedByProperty(request, propertyId, isFromPackage = false) {
    if (isFromPackage)
      return this._http.post(`${urlConfig.GetTaxCalculatedURL}?restaurantId=${propertyId}&isFromPackage=${isFromPackage}`, request, false, propertyId);
    else
      return this._http.post(`${urlConfig.GetTaxCalculatedURL}?restaurantId=${propertyId}`, request, false, propertyId);
  }

  ApplyPackage(payload){
    return this._http.post(`${urlConfig.ApplyPackage}`, payload)
  }
  ClearPackage(payload){
    return this._http.post(`${urlConfig.ClearPackage}`, payload)  
  }

  lockTables(slots, bypassLock = false) {
    return this._http.post(`${urlConfig.lockTablesURL}?restaurantId=${Utilities.RestaurantId()}&bypassLock=${bypassLock}`, slots)
  }

  wildSearchEngageMember(searchTerm) {
    return this._http.post(`${urlConfig.WildSearchEngageMemberURL}?restaurantId=${Utilities.RestaurantId()}&searchTerm=${searchTerm}`)
  }

  getEngageMemberByCardId(req: EngageMemberByCardIdRequestDTO){
    return this._http.post(`${urlConfig.GetEngageMemberByCardId}?restaurantId=${Utilities.RestaurantId()}`, req).pipe(map(res => res.Payload))
  }


  getSlots(locations, date): Observable<any> {
    return this._http.post(`${urlConfig.GetSlotsForTablesURL}?restaurantId=${Utilities.RestaurantId()}&options=0&options=0`, {
      TableIds: locations,
      TimeRange: {
        Start: date + "T00:00:00.000",
        End: date + "T23:59:59.999"
      }
    })
  }

  GetClassSlots(req): Observable<any> {
    return this._http.post(`${urlConfig.GetSlotsForActivityURL}?restaurantId=${req.PropertyId}&options=0`, req);
  }

  // GetSessionRatePlanCalculated(request) {
  //   return this._http.post(`${urlConfig.GetRatePlanCalculatedURL}?restaurantId=${Utilities.RestaurantId()}&options=0`, request)
  // }

  lockSessions(reqObj, bypassLock = false) {
    return this._http.post(`${urlConfig.lockSessionsURL}?restaurantId=${Utilities.RestaurantId()}&bypassLock=${bypassLock}`, reqObj)
  }

  checkIn(partyId, activityId, bookedSessionId?) {
    return this._http.post(`${urlConfig.CheckInActivity}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&activityId=${activityId}&bookedSessionId=${bookedSessionId}`, null)
  }

  checkOut(partyId, activityId, bookedSessionId?) {
    return this._http.post(`${urlConfig.CheckOutActivity}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&activityId=${activityId}&bookedSessionId=${bookedSessionId}`, null)
  }

  cancelClassActivity(dateTime, activityid, ignoreWarnings) {
    return this._http.post(`${urlConfig.CancelClassActivity}?restaurantId=${Utilities.RestaurantId()}&cancellationattemptedon=${dateTime}&activityid=${activityid}&ignoreWarnings=${ignoreWarnings}`, null)
  }
  
  getRatePlanReCalculation(request,FinancialEffectId?,ignoreValidation:boolean=false){
    return this._http.post(`${urlConfig.getRatePlanReCalculation}?restaurantId=${Utilities.RestaurantId()}&options=0&financialEffectId=${FinancialEffectId}&ignoreValidation=${ignoreValidation}`, request)
  }

  calculateDiscount(request){
    return this._http.post(`${urlConfig.CalculateDiscount}?propertyId=${Utilities.RestaurantId()}`, request)
  }

  cancelSessionActivity(dateTime, sessionid, cancelAll, ignoreWarnings) {
    return this._http.post(`${urlConfig.CancelSessionActivity}?restaurantId=${Utilities.RestaurantId()}&cancellationattemptedon=${dateTime}&sessionid=${sessionid}&cancelentiresessions=${cancelAll}&ignoreWarnings=${ignoreWarnings}`, null)
  }

  undoCheckIn(reservationId,activityId,bookedSessionId){
    return this._http.post(`${urlConfig.UndoCheckInActivity}?restaurantId=${Utilities.RestaurantId()}&reservationId=${reservationId}&activityId=${activityId}&bookedSessionId=${bookedSessionId}`,null)
  }

  ReAssignAsset(assetPayload){
    return this._http.post(`${urlConfig.ReAssignAsset}?propertyId=${Utilities.RestaurantId()}`,assetPayload)
  }
}
