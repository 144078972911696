import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';
import { MyErrorStateMatcher } from '@dynamicform/models/error-matcher';
import { FormTextAreaService } from './form-textarea.service';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormTextareaComponent implements Field, OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;
  FormControlName: string;
  textRows;
  hintLabel : string;
  matcher = new MyErrorStateMatcher();
  constructor(private formTextAreaService: FormTextAreaService, private ts:TranslateService) { }

  ngOnInit() {
    this.FormControlName = this.config.name;
    this.textRows = this.config.rows ? this.config.rows : 1;
    this.hintLabel = this.config.charLength ? this.ts.instant('Max') + ' ' +  this.config.charLength + ' ' + this.ts.instant('charactersText') : '';
  }

  remove(data){
    this.formTextAreaService.formTextAreaChange$.next(data);
  }

  onKeyup(event) {
    if (this.config.blurClick) {
      this.config.blurClick(event);
    }
  }
}
