

// export interface BlockActvityDataDTO{
//   blockedDateRange:Date
//   reasonForBlocking: string;
//   applicationSelected: string[];
// }

import { BlockActivityType } from "@app/shared/constants/commonenums";
import { ActivityCustomizationAppliesTo } from "@app/shared/models/RestaurantDTO";



export class BlockActvityDataDTO{
  PropertyId: number;
  CustomizationType: ActivityCustomizationType;
  StartDate: string;
  EndDate: string;
  ActionReason: string;
  ActionBy: string;
  ActivityBlockInfos: ActivityBlockInfo[];
  AppliesTo: ActivityCustomizationAppliesTo;
}
export class ActivityBlockInfo {
  ActivitySessionIds: number[];
  CustomizationId: number;
  ActivityId: number;
}

export enum ActivityCustomizationType {
  Block = 1
}


export class AdvanceBlockDataShare {

  selectedActivities: {
    [key: string]: {
      selectedSessionCount: number;
      Sessions: {
        [key: number]: boolean;
      }
    }
  } = {};
  blockActvityData: BlockActvityDataDTO = new BlockActvityDataDTO();
  constructor() { }

}
