<section class="menu-search">
  <section class="menuSearchWrapper">
    <section class="menuSearch-input">
      <form [formGroup]="menuSearchForm">
        <mat-form-field [floatLabel]="floatLabelNever" class="menu-searchField">
          <input autofocus #menuSearchText type="text" matInput formControlName="searchGroup" required
            [matAutocomplete]="autoGroup" (input)="filterGroup(menuSearchText.value)">
          <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="valueMapper">
            <mat-option *ngIf="(searchGroupOptions)?.length == 0 && menuSearchText.value.length >= 3">
              {{this.captions.NoMatchesFound}}
            </mat-option>
            <mat-optgroup class="menu-search-optGroup" *ngFor="let group of searchGroupOptions"
              [label]="this.captions.Menus">
              <mat-option *ngFor="let data of group.dataCollection" [value]="data.id"
                (onSelectionChange)="linkClicked(group.title,data,$event)">
                <span *ngIf="data.parentValue">{{data.parentValue}} <span class="menu-arrow">></span> </span>
                 <span> {{data.value}}</span>
                <i  aria-hidden="true" *ngIf="isDesignAuthor" class="icon-Edit ag_float--right ag_mt--10" (click)="edit(data)"></i>
                <i  aria-hidden="true" *ngIf = "data?.isReportUrl" class="icon-reports ag_float--right ag_mt--10"></i>
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </section>
  </section>