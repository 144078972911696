import { Component, EventEmitter, OnInit, Output, ViewChild ,Input} from '@angular/core';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { PartyService } from '@app/shared/services/party.service';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { PaymentMethod, PaymentType } from '@constants/commonenums';
import { TranslateService } from '@ngx-translate/core';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { CacheService } from '@app/core/services/cache.service';
import { RetailPropertyInformation } from '@app/retail/common/services/retail-property-information.service';

@Component({
  selector: 'app-common-booking-payments',
  templateUrl: './common-booking-payments.component.html',
  styleUrls: ['./common-booking-payments.component.scss']
})
export class CommonBookingPaymentsComponent implements OnInit {
  paymentConfig:FieldConfig[] = [];
  @ViewChild('paymentType', { static: true }) paymentTypeForm: DynamicFormComponent;
  @Output() setPayment: EventEmitter<any> = new EventEmitter<any>();
  @Output() setRetailTokenId: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedIndex?:any;
  PaymentReferenceID = 0;
  enableAuthorize : boolean;
  pmAgentEnable: boolean = true; 
  constructor(public partyService: PartyService,public ts: TranslateService , private cs : CacheService,private rb:RetailPropertyInformation) { }

  ngOnInit(){
    this.pmAgentEnable = sessionStorage.getItem("installedPMAgentVersion") != 'VERSION NOT DETECTED' ? true : false
    this.intializePaymentConfig();
    this.partyService.bookingPaymentType = PaymentMethod.Prepayment;
    this.partyService.RetailCardTokenId = null;
    this.partyService.IsSkipPayment = false;
  }
  ngAfterViewInit(){
    this.paymentTypeForm.form.valueChanges.subscribe((changes) => {
      if(changes){
        if(changes.PaymentMethod == PaymentMethod.Authorize || changes.PaymentMethod == PaymentMethod.SkipPayment){
          this.partyService.IsSkipPayment = true;
        }else{
          this.partyService.IsSkipPayment = false;
        }
        this.partyService.bookingPaymentType = changes.PaymentMethod;
        if(changes.PaymentMethod == PaymentMethod.Authorize){
          this.enableAuthorize = true; 
        }else{
          this.enableAuthorize  = false;
      //    this.partyService.RetailCardTokenId = null;
        }
        this.setPayment.emit( this.partyService.bookingPaymentType);
        this.partyService.paymentMethodChanges$.next(true);
      }
    })
    setTimeout(() => {
      this.setPayment.emit( this.partyService.bookingPaymentType);
      this.partyService.paymentMethodChanges$.next(true);
    }, 500)
  }
  intializePaymentConfig() {
    let paymentOptions = [];
    paymentOptions.push({ id: PaymentMethod.Prepayment, value: this.ts.instant('prepayment') });
    //  paymentOptions.push({ id: PaymentMethod.PartialPayment, value: this.ts.instant('PartialPayment') }),
    if (!this.cs.isIframeEnabled) {
      paymentOptions.push({ id: PaymentMethod.Authorize, value: this.ts.instant('AuthorizeandPayLater'), disable: !this.pmAgentEnable && !this.rb.SkipPMAgent })
    }
    paymentOptions.push({ id: PaymentMethod.SkipPayment, value: this.ts.instant('SkipPayment') })
    this.paymentConfig = [
      {
        type: 'radio',
        name: 'PaymentMethod',
        placeholder: '',
        options: paymentOptions,
        value: PaymentMethod.Prepayment,
        class: 'activity-headlines__session-selection',
        disabled: false,
      }
    ];
  }
  
  SaveReferenceId(tokenId){
    if(tokenId){
      this.PaymentReferenceID = tokenId;
      this.partyService.RetailCardTokenId = tokenId;
      this.setRetailTokenId.emit(tokenId);
      this.partyService.paymentMethodChanges$.next(true);
    }
 
  }
  removeDetails(event){
    this.PaymentReferenceID = 0;
    this.setRetailTokenId.emit(null);
    this.partyService.paymentMethodChanges$.next(true);
  }
}
