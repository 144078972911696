import { Component, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailReportControl } from '../../../basereport/base-report.component';
@Component({
  selector: 'app-retail-taxexempt-sales',
  templateUrl: './taxexempt-sales.component.html',
  styleUrls: ['./taxexempt-sales.component.scss']
})
export class RetailTaxExemptSalesComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  taxexemptFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  outlets: any;
  allCategoryGroups: any[];
  categories: any[];
  machineName: DropDownData[] = [];
  allCategories: any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  valuesSelected: DropDownData[] = [];
  selectedDropdownType = '';
  showMachineDropDown: boolean = false; 
  
editPatchValue : any;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, private dataService: ReportDataService, private propertyInfo: RetailPropertyInformation
    ,private container: ViewContainerRef) { }

  ngOnInit() {
    this.showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;    
    this.taxexemptFormGrp = this.fb.group({
      reportcategory: new UntypedFormControl([]),
      category: new UntypedFormControl([]),
      machineName: new UntypedFormControl([this.defaultData]),
      includePaymentTaxExempt: false
    });
    this.formReady.emit(this.taxexemptFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit) {  
        let patchItem =  this.editPatchValue;
        
        if(patchItem.CategoryIds != undefined){
          this.dataService.GetAllCategories().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.CategoryIds.find(x => x == item.id);
            });
            this.categories = arr;
            this.dataService.GetAllCategoryGroups().then(res => {
              let arr = res.filter((item) => {
                return this.categories.find(x => x.categoryGroup == item.id);
            });
            this.allCategoryGroups  = arr; 
          });
        });
      }

        if(patchItem.MachineNameIds != undefined){
          this.dataService.GetMachineName().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.MachineNameIds.find(x => x == item.id);
            });
            this.machineName = arr;     
          });
        }   
        }
    } 
  }

  private async onLoad() {
    let allCatGroup = await this.dataService.GetAllCategoryGroups();
    this.machineName = await this.dataService.GetMachineName();
    if (allCatGroup && Array.isArray(allCatGroup))
      this.allCategoryGroups = allCatGroup.filter(x => x.isActive);
    let catGroup = await this.dataService.GetAllCategories();
    if (catGroup && Array.isArray(catGroup)) {
      this.allCategories = catGroup;
      this.categories = catGroup.filter(x => x.isActive);
    }
    this.business.FilterDataSource["allCategoryGroups"] = this.allCategoryGroups;
    this.business.FilterDataSource["categories"] = this.categories;
    this.business.FilterDataSource["allMachineNames"] = this.machineName;
  }

  getFormControlValue(event) {
    if (event[0] === 'reportcategory') {
      this.taxexemptFormGrp.setControl('reportcategory', event[1]);
    } else if (event[0] === 'category') {
      this.taxexemptFormGrp.setControl('category', event[1]);
    }
    else if (event[0] === 'machineName') {
      this.taxexemptFormGrp.setControl('machineName', event[1]);
      }
  }

  getCategoryGroupValue(selectedCategoryGroupArray) {
    let filteredCategory = this.allCategories.filter(element => selectedCategoryGroupArray.map(r => r.id).includes(element.categoryGroup));
    this.categories = filteredCategory;
  }
}



