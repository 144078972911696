import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { Product } from 'src/app/retail/shared/globalsContant';
import { ValidationMessage } from '../../../basereport/base-report.component';
import { RetailPropertyInformation } from '../../../../common/services/retail-property-information.service';
@Component({
  selector: 'app-inventory-on-hand',
  templateUrl: './inventory-on-hand.component.html',
  styleUrls: ['./inventory-on-hand.component.scss']
})
export class InventoryOnHandComponent implements OnInit {
  inventoryOnHandFormGrp: UntypedFormGroup;
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  public captions: any = this.localization.captions.reports;
  productId: number;
  hideDisposableItem: boolean;
  radioFilter: { id: number; name: string; }[];
  disposableItemsValue: boolean;

  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, private PropertyInfo: RetailPropertyInformation) { 
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.hideDisposableItem = this.productId == Product.PMS ? false : true;
  }

  ngOnInit(): void {
    this.radioFilter = [{id: 0, name: this.captions.AllActiveitems}, {id: 1, name: this.captions.SuppressZeroQtyItems}];
    this.inventoryOnHandFormGrp = this.fb.group({
      lostCostMethod: false,
      categoryView: false,
      itemFilter: 0,
      category: new UntypedFormGroup({}),
      DisposableItems: this.disposableItemsValue == null ? false : this.disposableItemsValue
    });
    this.formReady.emit(this.inventoryOnHandFormGrp);
  }
  toggleDisposableItems(event){
    this.disposableItemsValue = event;
  }

  formInitialized(form: UntypedFormGroup) {
    this.inventoryOnHandFormGrp.setControl('category', form);
  }


  IsSelectionValid(validationObject: ValidationMessage) {
    this.IsValidSelection.emit({ dropDownName: validationObject.dropDownName, validity: validationObject.validity });
  }
}
