<mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass" [formGroup]="groupName" (click)="onControlClick()"> 
    <mat-label *ngIf="placeHolder" style="cursor:pointer" [attr.LiteralID]="placeHolderId">{{placeHolder +' ('+localization.currencySymbol+')'}}</mat-label>
    <input matInput CurrencyFormatter [attr.automationId]="automationId" [placeholder]="placeHolder" [formControlName]="controlName"
           [cAllowNegative]="allowNegative" [preDecimalmaxLength]='maxDecimalLength' [maxVal]="maxValue" [minVal]="minValue"
           [required]='showRequired'  [fieldUpdate]="fieldUpdate" [readonly]="readOnly" (input)="inputChanged($event)" (blur)="onInputBlurChange($event)">
    <mat-error *ngIf="groupName.get(controlName).hasError('required')">
        {{errorMessage}}
    </mat-error>
    <mat-error *ngIf="groupName.get(controlName).hasError('max')">
        {{maxValueErrorMessage}}
    </mat-error>
    <mat-error *ngIf="groupName.get(controlName).hasError('min')">
        {{minValueErrorMessage}}
    </mat-error>
</mat-form-field>
