import { Component, Input, OnInit, Pipe, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CacheService } from '@app/core/services/cache.service';
import { PartyService } from '@app/shared/services/party.service';
import { Subscription } from 'rxjs/Subscription';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Utilities } from '@app/shared/utilities/utilities';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { buttonTypes, ClassType, ComponentTypes, Operations, PartyState, PartyType } from '@app/shared/constants/commonenums';
import { PopupService } from '@app/popup-module/popup.service';
import { round, uniqBy } from 'lodash';
import { AppService } from '@app/app.service';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
//import { SatDatepickerRangeValue } from 'saturn-datepicker';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import _ from 'lodash';
import { CurrencyDecimalFormater } from '@app/shared/pipes/currency-decimal.pipe';

@Component({
    selector: 'app-booked-packages',
    templateUrl: './booked-packages.component.html',
    styleUrls: ['./booked-packages.component.scss']
})
export class BookedPackagesComponent extends Utilities implements OnInit {

    displayColumns: string[] = ["guestName", "packageName", "BookingDate", "ConfirmationCode", "Price"];
    @ViewChildren('form') components: QueryList<DynamicFormComponent>;
    tableDataSource: MatTableDataSource<{}>;
    packages = [];
    packageBookings = [];
    subscriptions: Subscription = new Subscription;
    warningInfoDialogRef: any;
    showBookedActivities: boolean = false;
    bookingDetails: any = {};
    datePickerconfig: FieldConfig[];
    showTodayButton: boolean;
    buttonToday: ButtonValue;
    cancelBtn: ButtonValue;
    searchconfig: FieldConfig[];
    searchText: string = '';
    _settings: SettingsDTO = {} as SettingsDTO;
    tableData: any[] = [];
    dateRangeForm: UntypedFormGroup;
    selectedPackags : any;


    constructor(public dialog: MatDialog, public partyService?: PartyService, public cs?: CacheService, public ts?: TranslateService, public popupService?: PopupService, public appService?: AppService, public fb?: UntypedFormBuilder , public currencyFormatter? : CurrencyDecimalFormater) {
        super(dialog);
        this.subscriptions.add(cs.settings.subscribe(settings => {
            this._settings = settings;
        }));
    }
    ngOnInit() {
        let Start = new Date(this.appService.headerDate$.value);
        let EndDate = new Date(this.appService.headerDate$.value);
        // let End = new Date(EndDate.setDate(EndDate.getDate() + 1))
        this.dateRangeForm = this.fb.group({
            filterDate: [{ begin: Start, end: EndDate }]
        });
        this.loadConfig();
        this.getPackageList(Start, EndDate);
    }
    ngAfterViewInit() {
        this.subscriptions.add(this.dateRangeForm.valueChanges.subscribe(data => {
            if (data.filterDate) {
                this.getPackageList(data.filterDate.begin, data.filterDate.end)
            }
        }));
        this.subscriptions.add(this.components.last.form.valueChanges.subscribe(data => {
            this.filterByText(data.searchText);
        }));
    }
    loadConfig() {
        this.searchconfig = [
            {
                type: 'input',
                name: 'searchText',
                label: this.ts.instant('searchText'),
                class: 'package-activities__search-text',
                showErrorText: true,
                appearance: true,
                icon: 'icon-search',
                icon1: 'icon-Group-591',
                cellClick: this.clearSearchField.bind(this)
            }
        ];
        this.buttonToday = {
            type: buttonTypes.actionSecondarySmall,
            label: 'Today',
            customclass: 'headercontainer__today-btn',
        };
        this.cancelBtn = {
            label: 'Cancel', type: buttonTypes.actionSecondarySmallRed, customclass: 'class-detail__actions__btn',
        }
    }
    navigateToToday() {
        this.components.first.form.controls.reservationDate.setValue(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    }
    getPackageList(Start?, End?) {
        this.subscriptions.add(this.partyService.GetAllBookedPackageActivities(this.cs.settings.value.PropertySetting[0].RestaurantId, moment(Start).format('YYYY-MM-DD'), moment(End).format('YYYY-MM-DD')).subscribe(data => {
            if (data.Payload) {
                this.packageBookings = _.cloneDeep(data.Payload);
                this.packages = data.Payload;
                this.setTableDataSource();
            }
        }));
    }
    setTableDataSource() {
        let tableData = [];
        this.packages.forEach(item => {
            let primaryContact = _.sortBy(item.Activities, 'Id')[0].Contact;
            let data = {
                guestName: (primaryContact.FirstName ? primaryContact.FirstName : '') + " " + (primaryContact.LastName ? primaryContact.LastName : ''),
                packageName: item.PackageName,
                BookingDate: moment(new Date(item.Date)).format(this.cs.settings.value.General.DateFormat),
                ConfirmationCode: item.PackageConfirmationId,
                Price: item.Activities?.length > 0 ? this.getTotalAmount(item.Activities) : 0,
                Id: item.Id
            }
            tableData.push(data);
        })
        this.tableData = tableData;
        this.tableDataSource = new MatTableDataSource(tableData)
    }
    getTotalAmount(Activities) {
        let totalAmount = 0;
        Activities.forEach(attendee => {
            totalAmount = totalAmount + (attendee.GrandTotal || 0 )
        })
        return this.currencyFormatter.transform(totalAmount);
    }
    cancelPackage(data) {
        let activities = this.packageBookings.find(item => item.PackageConfirmationId === data.ConfirmationCode)?.Activities;

        if (data) {
            let msg = this.ts.instant('cancelPackageConfirmation');
            this.showWarningInfoPopUp(msg, ComponentTypes.cancelPackageConfirmation, '250px', '450px', this.ts.instant('cancelPackage'))
            let confirmInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
                if (val === ComponentTypes.cancelPackageConfirmation) {
                    this.subscriptions.add(this.partyService.CancelAllBookedPackageActivities(this.cs.settings.value.PropertySetting[0].RestaurantId, data.ConfirmationCode, activities[0]?.PackageId).subscribe(responce => {
                        console.log(responce);
                        this.partyService.Operations = Operations.cancel;
                        let transaction = responce.Payload[`${activities[0].Id}`]
                        let ReturnItem = [];
                        Object.keys(responce.Payload).forEach(key => {
                            if (responce.Payload[key]) {
                                ReturnItem.push(responce.Payload[key].ReturnItem);
                            }
                        })
                        if (ReturnItem?.length) {
                            ReturnItem = ReturnItem.flat()
                            transaction.ReturnItem = ReturnItem.filter(items => items.IsChecked);
                            this.partyService.RetailCanceloperation(null, transaction, null, null, activities);
                            setTimeout(() => {
                                if (this.partyService.packageListPopup) {
                                    this.partyService.packageListPopup.close();
                                }
                            }, 500)
                        } else {
                            this.showBookedActivities = false;
                            this.openConfirmationDialog();
                            this.getPackageList();
                        }
                    }))
                    confirmInPopUpConfirmationSubscription.unsubscribe();
                }
            });
            let confirmInPopUpCancellationSubscription = this.popupService.cancelledAction$.subscribe(val => {
                confirmInPopUpCancellationSubscription.unsubscribe();
                confirmInPopUpConfirmationSubscription.unsubscribe();
            });
        }
    }
    showWarningInfoPopUp(message, componentType, popupHeight, popupWidth, title) {

        let cancelText = 'No';
        // let title = 'Confirm Check-in';
        //let msg = 'No card details collected to capture payment amount. Are you sure to checkin the attendee?'
        let noShowSet = false;
        let confirmCheckin = false;
        let updateText = 'Yes';
        let showAlert = false;
        let noShowFeePopUp = false;

        const popUpMessage = [{
            confirmationMessage: message, dialogTitle: 'confirm', showAlert: showAlert
        }];

        const componentDetails: ComponentDetails = {
            componentName: ConfirmationPopupComponent,
            dimensionType: 'small',
            popupType: 'active',
            popUpDetails: {
                isStepper: false,
                eventName: 'notifyParent'
            },
            popupInput: popUpMessage,
            popupTitle: popUpMessage[0].dialogTitle
        };

        this.warningInfoDialogRef = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            width: popupWidth,
            height: popupHeight,
            data: {
                title,
                update: updateText,
                cancel: cancelText,
                componentDetails,
                from: componentType,
                back: false,
                standalone: true,
                showAlert: true
            }
        });
    }
    viewActivities(packageItem) {
        this.showBookedActivities = true;
        let bookings = [];
        let selectedPackags = this.selectedPackags =  this.packageBookings.find(pack => pack.PackageConfirmationId == packageItem.ConfirmationCode);
        this.bookingDetails.GuestName = (selectedPackags.FirstName ? selectedPackags.FirstName : '') + " " + (selectedPackags.LastName ? selectedPackags.LastName : '')
        this.bookingDetails.PackageName = selectedPackags.PackageName,
            this.bookingDetails.PackagePrice = selectedPackags.Activities ? this.getTotalAmount(selectedPackags.Activities) : 0,
            this.bookingDetails.Date = moment(new Date(selectedPackags.Date)).format(this.cs.settings.value.General.DateFormat);
        this.bookingDetails.ConfirmationCode = packageItem.ConfirmationCode;

        selectedPackags['Activities'].forEach(pack => {
            let activity = {
                activityName: pack.SpecialMealId ? this.cs.specialMealListForMerchant.find(x => x.Id == pack.SpecialMealId)?.Name : this.ts.instant('openBooking'),
                bookingSize: pack.Size ? pack.Size : 0,
                PaidAmount: round(Number(pack.RatePlanAmount + (pack.NegotiatedAmount == undefined ? 0 : pack.NegotiatedAmount) + (pack.PackageDiscountAmount || 0) + (pack.TaxAmount || 0) + (pack.AddonItemAmount || 0) + (pack.AddonItemTaxAmount || 0)), 2)
            }
            bookings.push(activity);
        })
        this.bookingDetails.bookings = bookings;
    }
    onSortData(sort: Sort) {
        console.log(sort)
        this.tableData = _.orderBy(this.tableData, [sort.active], [sort.direction == 'asc' ? 'asc' : 'desc']);
        this.tableDataSource = new MatTableDataSource(this.tableData);
    }
    filterByText(text) {
        if (text !== '') {
            this.tableDataSource = new MatTableDataSource(this.tableData.filter(data => data.packageName.toLowerCase().includes(text.toLowerCase()) || data.guestName.toLowerCase().includes(text.toLowerCase()) || data.ConfirmationCode.toLowerCase().includes(text.toLowerCase())))
        } else {
            this.tableDataSource = new MatTableDataSource(this.tableData)
        }
    }
    clearSearchField() {
        this.components.last.form.get('searchText').setValue('');
        this.tableDataSource = new MatTableDataSource(this.tableData);
    }
    openConfirmationDialog() {
        const popUpMessage = [{
            confirmationMessage: this.ts.instant("packageCancelledMessage"),
            dialogTitle: null,
        }];
        let okbutton = this.ts.instant('ok');
        const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
            popUpMessage, popUpMessage[0].dialogTitle);
        let dialogRef = this.openCustomPopup(componentDetails, ComponentTypes.packageCancelledMessage, '400px', '400px', true, '', okbutton,
            '', true);
        let popupSubscription = this.popupService.confirmedAction$.subscribe(val => {
            // popupSubscription.unsubscribe();
        })
        popupSubscription = this.subscriptions.add(dialogRef.afterClosed().subscribe(val => {
            if (val == ComponentTypes.packageCancelledMessage) {
                popupSubscription.unsubscribe();
            }
        }));
    }
}
