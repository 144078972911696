<section class="retail-code-setup newVersionWrapper machine-name-wrapper h-100" id="machinename">
  <div class="machineName h-100">
    <div class="retailGrid h-100">
      <app-retail-table [options]="tableoptions" (dragDropEvt)="dragDrop($event)"
           (deleteEvt)="DeleteRecords($event)" (addClick)="addMachineName($event)" (editEvt)="EditRecords($event)" [isViewOnly]="isViewOnly"
          (toggleEvtEmitter)="sliderChange($event)" (doneEvtEmitter)="Done($event)"  [isOnEditDisableFunctionAvailable]="onEditDisableFunction"></app-retail-table>
  </div>
  </div>

</section>
