<!--categories filter-->
<section [formGroup]="InventoryDetailFormGrp" autocomplete="off">

    <app-retail-category-component (formReady)="formInitialized($event)" (IsValidSelection)="IsSelectionValid($event)"></app-retail-category-component>
    <!--category filter End-->
    <div class="mb-3">
      <label class="LW14">{{captions.UseLastCostMethod}}</label>
      <app-retail-toggle-switch class="d-block" formControlName="lostCostMethod"></app-retail-toggle-switch>
    </div>

    <div class="mb-3" *ngIf = "!this.hideDisposableItem">
      <label class="LW14">{{captions.DisposableItems}}</label>
      <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleDisposableItems($event[0])" formControlName="DisposableItems"></app-retail-toggle-switch>
    </div>
 

    <div class="mb-3"> 
      <mat-radio-group [value]="0" class="report-radio-group" formControlName="itemFilter">
          <mat-radio-button *ngFor="let data of radioFilter" class="report-radio-button" [value]="data.id">
            {{data.name}}
          </mat-radio-button>
        </mat-radio-group>

    </div>

  </section>
