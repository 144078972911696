import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[numberDivisibleOfFive]'
})
export class NumberDivisibleOfFiveDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/^[+-][01][0-4][:][012345]?[05]$/, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
