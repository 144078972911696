import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { RestaurantStateService } from '@app/shared/services/restaurant-state.service';
import { CacheService } from '@core/services/cache.service';
import { PartyService } from '@services/party.service';
import { SettingsService } from '@services/settings.service';
import { TablesService } from '@services/tables.service';
import { ToastrService } from 'ngx-toastr';
import { AutoServerAreasChangeProcessor } from './autoserver-areas-processor';
import { BookedSessionChangeProcessor } from './bookedsession-change-processor';
import { ContactChangeProcessor } from './contact-change-processor';
import { PartyChangeProcessor } from './party-change-processor';
import { PartyMessageChangeProcessor } from './partymessage-change-processor';
import { PartyNoteChangeProcessor } from './partynote-change-processor';
import { ObjectType, Processor } from './processor';
import { SecondaryContactChangeProcessor } from './secondaryContact-change-processor';
import { SlotChangeProcessor } from './slot-change-processor';
import { StandaloneTableChangeProcessor } from './standalonetable-change-processor';
import { TableBlockingRuleChangeProcessor } from './tableblockingrule-change-processor';
import { TableBlockingRuleShiftChangeProcessor } from './tableblockingrule-shift-change-processor';
import { TableBlockingRuleTableChangeProcessor } from './tableblockingrule-table-change-processor';
import { GuestBookService } from '@app/shared/services/guestbook.service';
import { AutoServerAreasDTOMapper } from '@models/mappers/AutoServerAreasDTOMapper';
import { PartyContactAddonChangeProcessor } from './party-contact-addon-change-processor';
import { PartyBookingAmountsChangeProcessor } from './party-booking-amounts-change-processor';
import { contactcustomfieldsChangeProcessor } from './contactcustomfields-processor';
import { ActiivtyBlockingRuleChangeProcessor } from './activityblockingrule-change-processor';
import { PartyCoverTypeChangeProcessor } from './party-cover-type-change-processor';


@Injectable()
export class ProcessorBuilder {

  constructor(public ps: PartyService, public as: AppService, public cs: CacheService, public ts: TablesService, public ss: SettingsService, public _ts: ToastrService, public rss: RestaurantStateService, public gbs: GuestBookService) { }

  getProcessor(objecttype: ObjectType): Processor {
    switch (objecttype) {
      case ObjectType.Party:
        return PartyChangeProcessor.Instance(this.ps, this.as, this.ss, this._ts, this.rss);
      case ObjectType.PartyNote:
        return PartyNoteChangeProcessor.Instance(this.ps, this.rss, this.as);
      case ObjectType.PartyMessage:
        return PartyMessageChangeProcessor.Instance(this.ps, this.cs);
      case ObjectType.Contact:
        return ContactChangeProcessor.Instance(this.ps, this.rss, this.gbs, this.cs);
      case ObjectType.ManualSlot:
        return SlotChangeProcessor.Instance(this.ps, this.as);
      case ObjectType.StandaloneTable:
        return StandaloneTableChangeProcessor.Instance(this.ts, this.cs, this.as);
      case ObjectType.TableBlockingRule:
        return TableBlockingRuleChangeProcessor.Instance(this.cs, this.ts, this.ps);
      case ObjectType.TableBlockingRuleShift:
        return TableBlockingRuleShiftChangeProcessor.Instance(this.cs, this.ts);
      case ObjectType.TableBlockingRuleTable:
        return TableBlockingRuleTableChangeProcessor.Instance(this.cs, this.ts);
      case ObjectType.AutoServerArea:
        return AutoServerAreasChangeProcessor.Instance(this.cs);
      case ObjectType.BookedSessions:
        return BookedSessionChangeProcessor.Instance(this.ps, this.as);
      case ObjectType.SecondaryContact:
        return SecondaryContactChangeProcessor.Instance(this.ps, this.as);
      case ObjectType.BookingContactAddonItem:
        return PartyContactAddonChangeProcessor.Instance(this.ps, this.cs)
        case ObjectType.CoverTypeQuantity:
          return PartyCoverTypeChangeProcessor.Instance(this.ps, this.cs)
      case ObjectType.BookingAmount:
        return PartyBookingAmountsChangeProcessor.Instance(this.ps, this.cs)
      case ObjectType.ContactCustomFields:
        return contactcustomfieldsChangeProcessor.Instance(this.ps, this.rss, this.gbs, this.cs);
      case ObjectType.ActivityCustomizations:
          return ActiivtyBlockingRuleChangeProcessor.Instance(this.cs, this.ps);
      default:
        return PartyChangeProcessor.Instance(this.ps, this.as, this.ss, this._ts, this.rss);
    }
  }
}
