import { Injectable } from '@angular/core';
import { CommonUtilities,Product } from 'src/app/common/shared/shared/utilities/common-utilities';
import { UserAccessDataService } from './useraccess.data.service';
import { AlertType, ButtonTypes } from 'src/app/common/Models/common.models';
import { AppService } from 'src/app/common/app-service';
import { Localization } from '../../localization/localization';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { MenuObj,LogUserMenuAccess } from '../../shared/shared/components/menu-search/menu.model';

@Injectable(
    { providedIn: 'root' }
)
export class UserAccessBusiness {
    private readonly ProductID: number;
    private tmpMenus: MenuObj[] = [];
    private path:string;
    constructor(private userAccessDataService: UserAccessDataService
        , public _utilities: CommonUtilities
        , public localization: Localization
        , private _appservice: AppService, private _loader: RouteLoaderService) { 
            this.ProductID = this._appservice.productId;
        }
    public async getUserAccess(breakPointNumber: number, showUserMessage: boolean = true, callBack?: any): Promise<UserAccessModel.BreakPointResult> {
        const result = await this.userAccessDataService.getUserAccess(breakPointNumber);
        let bpMessage = this.localization.captions[breakPointNumber];
        if (bpMessage === undefined) { bpMessage = this.localization.captions.breakpoint[breakPointNumber]; } // Temporary_Fix
        if (!result.isAllow && !result.isViewOnly && showUserMessage) {
            this.showBreakPointPopup(bpMessage, callBack);
        }

        return result;
    }

    public showBreakPointPopup(functionName?: string, callBack?: any) {
        let message: string;
        message = `${this.localization.captions.common.BreakPointAccessDeniedMsg}
             <br><br>${this.localization.captions.common.Breakpoint}: ${functionName}`;
        if (this.ProductID == Product.SPA)
            this._utilities.showAccessError(this.localization.captions.common.AccessDenied, message, ButtonTypes.Ok);
        else        
            this._utilities.showCommonAlert(message, AlertType.AccessDenied, ButtonTypes.Ok, callBack);
    }


    public showBreakPointError(breakpoint: number): void {
        let message: string = this.localization.captions[breakpoint.toString()];
        if (message === undefined) { message = this.localization.captions.breakpoint[breakpoint.toString()]; } // For Spa, Golf and Retail products, will be refactored while moving localization to DB
        this.showBreakPointPopup(message);
    }


    public getUserAccesses(breakpoints: number[]): Promise<UserAccessModel.BreakPointResult[]> {
        return this.userAccessDataService.getUserAccesses(breakpoints);
    }

    public async logUserMenuAccess(menuPath: string,screenName:string,accessDate:Date): Promise<boolean> {
        const menus: MenuObj[] = await this._loader.currentSettings;
        this.tmpMenus = [];
        let positionsToSplit:number[]=[];
        for(var i=0;i<=menuPath.length-1;i++)
        {
            if(menuPath[i]==="/" && i > 0)
            {
                positionsToSplit.push(i);
            }
        }
        this.path="";
        this.GetMenuObjByPath(menus, menuPath,positionsToSplit);
        const menuObj: MenuObj = this.tmpMenus[0];


        if(menuObj && menuObj !== null) {
           

            let logUserMenuAccess:LogUserMenuAccess={

                availableMenu:menuObj,
                routePath:screenName,
                accessDate:accessDate

            }
            this.userAccessDataService.logUserMenuAccess(logUserMenuAccess);
        } 
        return true;
    }

    private GetMenuObjByPath(linkElements: MenuObj[], menuPath: string,positionsToSplit:number[]) {

        let tmpMenu:MenuObj;
        if (linkElements && linkElements.length > 0) {
            linkElements.forEach(element => {
              
                if(this.path.length < this.SplitPath(element,menuPath,positionsToSplit).length)
                {
                    this.path=element.routePath;
                    this.tmpMenus[0]=element;
                }

                if (element.linkedElement && element.linkedElement.length > 0) {
                    this.GetMenuObjByPath(element.linkedElement, menuPath,positionsToSplit);
                }
            })

        }            
    }

    private SplitPath(linkElement: MenuObj, menuPath: string,positionsToSplit:number[])
    {
        
        let relativePath:string="";

        if(menuPath.substring(0).toLowerCase() === linkElement.routePath?.toLowerCase())
        {
            relativePath=linkElement.routePath;
        }
        else
        {
          if(positionsToSplit.length > 0)
         {
            positionsToSplit.forEach(x => {

                if(menuPath.substring(0,x).toLowerCase() === linkElement.routePath?.toLowerCase())
                {
                    relativePath=linkElement.routePath;
                }
            })

         }
        }

        return relativePath;
    }
}
