<div class="cover-report">
  <div class="cover-report__header">
    <div class="cover-report__date">
      <dynamic-form [config]="generalDateConfig" #form="dynamicForm">
      </dynamic-form>
    </div>
    <div class="shift-dropdown">
      <label class="form-helper-text">{{'shiftText'|translate}}:</label>
      <dynamic-form *ngIf="shifts?.length > 0" [config]="shiftConfig" #form="dynamicForm"></dynamic-form>
      <label class="seat-text-fields" *ngIf="shifts?.length == 0">{{ 'restaurantclosedtext' | translate }}</label>
    </div>
    <div class="refresh-img-aligment">
      <img src="assets/images/Refresh_icon.svg" class="refresh-img" (click)="loadCoverReport()" />
    </div>
  </div>
  <div class="cover-report__statsGrid">
    <div class="statsGridMainDiv">
      <div class="statsGridBody">
        <div class="partiesCountDiv2 totalcount-color seat-primary-text" [ngClass]="{'totalCount-hide':hide_walkins}">
          <div class="partiesCount">
            <Label class=" cellValue1">{{totalCount}}</Label>
          </div>
          <div class="partiesCountTable">
            <label class="cellHeader1">{{ 'TotalCount' | translate }}</label>
          </div>
        </div>
        <div class="partiesCountDivBorder form-helper-text" [ngClass]="{'partiesCount-hide':hide_walkins}">
          <div class="partiesCount">
            <Label class="cellValue2">{{reservationCount}}</Label>
          </div>
          <div class="partiesCountTable">
            <label class="cellHeader2">{{ 'ReservationCount' | translate }}</label>
          </div>
        </div>
        <div class="partiesCountDiv2 form-helper-text" *ngIf="!hide_walkins">
          <div class="partiesCount">
            <Label class="cellValue2">{{walkInCount}}</Label>
          </div>
          <div class="partiesCountTable">
            <label class="cellHeader2">{{ 'WalkinCount' | translate }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="statsGridMainDiv">
      <div class="statsGridBody">
        <div class="partiesCountDiv2 totalcovers-color seat-primary-text" [ngClass]="{'totalCount-hide':hide_walkins}">
          <div class="partiesCount">
            <Label class="cellValue1">{{totalCovers}}</Label>
          </div>
          <div class="partiesCountTable">
            <label class="cellHeader1">{{ 'TotalCovers' | translate }}</label>
          </div>
        </div>
        <div class="partiesCountDivBorder form-helper-text" [ngClass]="{'partiesCount-hide':hide_walkins}">
          <div class="partiesCount">
            <Label class="cellValue2">{{reservationCovers}}</Label>
          </div>
          <div class="partiesCountTable">
            <label class="cellHeader2">{{ 'ReservationCovers' | translate }}</label>
          </div>
        </div>
        <div class="partiesCountDiv2 form-helper-text" *ngIf="!hide_walkins">
          <div class="partiesCount">
            <Label class="cellValue2">{{walkInCovers}}</Label>
          </div>
          <div class="partiesCountTable">
            <label class="cellHeader2">{{ 'WalkinCovers' | translate }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="statusLabelDiv">
      <table>
        <tr>
          <td>
            <span class="roundSpan reservation-color">
            </span>
          </td>
          <td>
            <label class="seat-tertiary-text">{{'reservationMenuText' | translate}}</label>
          </td>
        </tr>
        <tr *ngIf="!hide_walkins">
          <td>
            <span class="roundSpan walkin-color">
            </span>
          </td>
          <td>
            <label class="seat-tertiary-text">{{'guestWalkins' | translate}}</label>
          </td>
        </tr>
        <tr>
          <td>
            <span class="roundSpan totalcount-color">
            </span>
          </td>
          <td>
            <label class="seat-tertiary-text">{{'TotalCount' | translate}}</label>
          </td>
        </tr>
        <tr>
          <td>
            <span class="roundSpan totalcovers-color">
            </span>
          </td>
          <td>
            <label class="seat-tertiary-text">{{'TotalCovers' | translate}}</label>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="cover-report__standbylist-table">
    <ng-scrollbar>
      <table mat-table [dataSource]="coverReportDataSource" matSort matSortDirection="desc" matSortDisableClear
        (matSortChange)="onSortData($event)" class="mat-elevation-z8">
        <ng-container matColumnDef="Time">
          <th class="seat-grid-header" mat-sort-header mat-header-cell *matHeaderCellDef arrowPosition="before">
            {{'timeText' | translate}} </th>
          <td class="seat-grid-content" mat-cell *matCellDef="let element">
            {{element.Slot.DateTime | localizedDate:'LT'}}</td>
        </ng-container>
        <ng-container matColumnDef="Covers">
          <th class="seat-grid-header" style="padding-right: 350px !important;" mat-header-cell *matHeaderCellDef>
            {{'covers' | translate}} </th>
          <td class="seat-grid-content" mat-cell *matCellDef="let element">
            <span *ngIf='element?.Covers?.length > 0'>
              <span *ngFor="let cover of element.Covers; let i =index" class="covers-span"
                [ngStyle]="cover.Type == 0?{'background-color':'royalblue'} : {'background-color': 'darkorange'}">
                <img src="assets/images/User-28px(White).svg" />
                {{cover.CoverSize}}
              </span>
            </span>
            <span *ngIf='element?.Covers?.length == 0'>
              {{'noCovers' | translate}}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Count">
          <th class="seat-grid-header" mat-sort-header mat-header-cell *matHeaderCellDef arrowPosition="before">
            {{'countText' | translate}} </th>
          <td class="seat-grid-content" mat-cell *matCellDef="let element">
            <span class="count-text" *ngIf='element.Count > 0'
              [style.background]="element.Count | getCoverColor : maxCover">
              {{element.Count}}
            </span>
            <span class="count-column-padding" *ngIf='element.Count == 0'>
              {{element.Count}}
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="Columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: Columns;"></tr>
      </table>
    </ng-scrollbar>
  </div>
</div>