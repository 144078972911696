import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { MultilingualService } from '@app/core/services/multilingual.service';
import moment from 'moment';
import { DEFAULT_LANGUAGE_CODE } from '../constants/globalConstants';
import { Utilities } from '../utilities/utilities';

@Pipe({
  name: 'localizedDate',
})

export class LocalizedDatePipe implements PipeTransform {
  constructor(
    private ms: MultilingualService,
    private datePipe: DatePipe
  ) {}

  transform(value: any, formatDate: string = 'mediumDate'): any {
    if (value){

      let localMoment = moment(value);
      let languagesAvailable = JSON.parse(sessionStorage.getItem(`languages${Utilities.getSessionStorageType()}`));
      let languageCode =languagesAvailable ? Utilities.findLocale(languagesAvailable) : DEFAULT_LANGUAGE_CODE;
      return localMoment.locale(languageCode).format(formatDate);  
    }
  }


}
  