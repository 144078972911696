import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'guestNameFormatter' })

export class GuestNameFormatPipe implements PipeTransform {
  transform(contact) {
    let initial = "";
    if (contact.FirstName && contact.FirstName != "")
      initial = contact.FirstName[0];
    if (contact.LastName && contact.LastName != "")
      initial = initial + contact.LastName[0];
      if(contact.Name && contact.Name != ""){
        initial = "";
        let nameArray = contact.Name.split(' ');
        for( let index = 0; index < nameArray.length; index++ ) {
          initial = initial + nameArray[index].charAt(0) 
      }      
      }
    if (initial == '((')
      return "CLR";

    else
      return initial.toUpperCase();
  }
} 


@Pipe({ name: 'capitalizeFirstCharInText' })

export class CapitalizeFirstCharacterInText implements PipeTransform {
  transform(name: string) {
    let capitalizedText = '';
      if(name) {
        return capitalizedText = name[0]?.toUpperCase() + name?.substring(1)
      }
      return capitalizedText;

  }
} 