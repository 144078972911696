import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'app-cart-authorize',
  templateUrl: './cart-authorize.component.html',
  styleUrls: ['./cart-authorize.component.scss']
})
export class CartAuthorizeComponent implements OnInit {

  captions;
  buttonObj: { customSaveText: any; disabled: boolean; isEdit: boolean; };
  confirmationNumber: any;
  email:string;
  tableData: { id:number;name: string; email: string; selected: boolean }[];floatLabel: string;
  selectedObj: any;
  typedEmail: string;
  title: string = '';

  constructor(public dialog: MatDialog,
    private localization: Localization,
    private dialogRef: MatDialogRef<CartAuthorizeComponent>,
    @Inject(MAT_DIALOG_DATA) data: any)
  {
    this.captions = this.localization.captions;
    this.floatLabel=this.localization.setFloatLabel;
    this.tableData = data?.tableData;
    this.title = data?.title?.toUpperCase();
  }

  ngOnInit(): void {
    this.buttonObj = {
      customSaveText: this.captions.btn_send,
      disabled: true,
      isEdit: false
    };
  }

  close() {
    this.dialogRef.close(false)
  }

   save(e) {
    if(!this.selectedObj){
      this.selectedObj = {email : this.typedEmail}
    }
    this.dialogRef.close(this.selectedObj);
   }

  onSelect(obj) {
    if (obj && obj.email && obj.email.length > 0 && this.validateEmail(obj.email)) {
      this.buttonObj.disabled = false;
      obj.selected = true;
    } else {
      this.buttonObj.disabled = true;
    }
    this.buttonObj = { ...this.buttonObj };
    this.tableData.forEach(x => {
      x.selected = false
    });
    this.selectedObj = obj
    this.email= obj.email;
  }
  onEmailTyped(event) {
    if (event && event.length > 0 && this.validateEmail(event)) {
      this.buttonObj.disabled = false;
    } else {
      this.buttonObj.disabled = true;
    }
    this.typedEmail = event;
    this.buttonObj = { ...this.buttonObj };
  }
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
