<section class="post-type-mapping__section">
  <div *ngIf = 'showOutletDetails' class="header__section">
    <div class="header__section--area">
      <div class="header__section--details">
        <div class="d-table pl-3">
        <div class="mb-2 d-table-row"><span class="header__key d-table-cell">{{captions.Category}}</span><span
            class="header__data d-table-cell"><span class="header__key--colon"> : </span>{{headerData.category}}</span></div>
        <div class="d-table-row"><span class="header__key d-table-cell">{{captions.Outlet}}</span><span
            class="header__data d-table-cell">
            <span class="header__key--colon"> : </span>{{selectedColumnValue}}</span></div>
      </div>
    </div>  
      <button *ngIf = "!isResortFinance" mat-raised-button class="sync__btn" (click)="TriggerSync()">
        <i  aria-hidden="true" class="icon-sync pr-1"></i>
        {{captions.Sync}}
      </button>
    </div>
  </div>

  <div class="table__section">
    <mat-form-field class="searchArea spa-searchbox w-100 pt-2" [floatLabel]="floatLabelNever">
      <input [attr.automationId]="'Txt_postTypeMappingCrud_search'" matInput autocomplete="off" class="search-input" [(ngModel)]="searchText"
        [ngModelOptions]="{standalone: true}" type="text" placeholder="{{searchPlaceholderValue}}"
        [maxlength]="maxInputLength" value="" (keypress)="searchValue($event)">
      <i  aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor" (click)="resetValue()"></i>
    </mat-form-field>
    <div class="searchArea">
      <mat-checkbox [attr.automationId]="'Chk_postTypeMappingCrud_noPosting'" (change)="switchNoPosting($event)" [disabled]="!tableoptions || tableoptions.length == 0">{{captions.NoPosting}}</mat-checkbox>
    </div>
    <div class="retailGrid">
      <app-retail-table [attr.automationId]="'Tbl_postTypeMappingCrud_search'" [searchvalue]="searchText" [options]="tableoptions"
        (radioClickEvtEmitter)="radiobuttonClicked($event)"></app-retail-table>
    </div>
  </div>
</section>

<div mat-dialog-actions class="actions-div">
  <div class="pl-4">
    <button [attr.automationId]="'Btn_postTypeMappingCrud_apply'" [ngClass]="isValid ? 'button--primary-save' : 'button--disabled'" mat-button [disabled]="isDisabled"
      (click)="savePostType()">{{commonCaptions.Apply}}</button>
      <button [attr.automationId]="'Btn_postTypeMappingCrud_applyAll'" [ngClass]="isValid ? 'button--primary-save' : 'button--disabled'" mat-button [disabled]="isDisabled"
      (click)="savePostTypeToAll()">{{applyToAllButtonCaption}}</button>
    <button [attr.automationId]="'Btn_postTypeMappingCrud_cancel'" class="spa-button-cancel" mat-button (click)="onNoClick()">{{commonCaptions.Cancel}}</button>
  </div>
</div>
