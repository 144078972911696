<section class="shipping-content">
  <div class="shipping-client">
    <div class="client-details">
    <label class="LW14 uppercase">Client</label>
    <ul class="LW14">
        <li>John Smith</li>
        <li>6146, Honey bluff parkWay</li>
        <li>Calder, Michcay</li>
        <li>United States</li>
        <li>(555) 229 0000</li>
    </ul>
    </div>
    <div class="stay-details">
    <label class="LW14 uppercase">Stay Details</label>
        <ul class="LW14">
            <li>Hilton Hotels</li>
            <li>Room No: 121</li>
            <li>Stay From 10/10/2018 to 10/10/2018</li>
        </ul>
    </div>

  </div>
  <div class="shipping-delivery">
        <label class="LW14 uppercase">Deliver to</label>
</div>
<div class="shipping-inforamtion">
        <label class="LW14 uppercase">Shipment Information</label>
</div>
</section>
<section class="shipping-footer">
  
</section>