import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  public FormControlName;
  config: FieldConfig;
  group: UntypedFormGroup;

  constructor() { }

  ngOnInit() {
    this.FormControlName = this.config.name;
  }

}
