import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { Outlet, SubPropertyModel, OutelTerminalHeaderDropDown, CCTerminal, OutletsCRUD, Translog, OutletUIMapper, Product } from '../../retail.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailOutletsDataService } from './retail-outlets-data.service';
import { RetailCreditCardTerminalDataService } from '../retail-credit-card-terminal/retail-credit-card-terminal-data.service';
import { RetailOutletsBusinessService } from './retail-outlets-business';

import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { SubPropertyDataService } from './subproperty-data.service';
import * as _ from 'lodash';
import { HttpMethod } from '../../shared/service/http-call.service';
import { TransactionStatus } from '../../shared/service/common-variables.service';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { TableSearchHeader } from 'src/app/common/Models/ag-models';
import { CreateReatilOutletsComponent } from './create-reatil-outlets/create-reatil-outlets.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonTypes } from 'src/app/common/Models/common.models';
import { ButtonType } from '../../shared/globalsContant';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-retail-outlets',
  templateUrl: './retail-outlets.component.html',
  styleUrls: ['./retail-outlets.component.scss'],
  providers: [RetailOutletsDataService, RetailOutletsBusinessService, RetailCreditCardTerminalDataService, SubPropertyDataService, RetailFunctionalityBusiness],
  encapsulation: ViewEncapsulation.None
})

export class RetailOutletsComponent implements OnInit {

  tableoptions: any[];
  searchHeaderOption: TableSearchHeader;
  captions: any;
  terminals: OutelTerminalHeaderDropDown[] = []
  currIndex: any;
  IsViewOnly: boolean;
  onEditDisableFunction: boolean = true;
  AddButtonDisable: boolean = false;
  private data: OutletsCRUD;
  commonCaptions: any;
  outlets:any;
  sncProductID:boolean;
  private _products: Product[] = [];
  retailFunctionalities: { [key: string]: string};
  EditCaption: string;
  searchText: any;
  isEditFlag:boolean=false
  activeRecord: Outlet;
  customEditCancelled: boolean;
  outletsForGrid: any;

  constructor(public localization: RetailLocalization, private utils: RetailUtilities, private propertyConfig: RetailPropertyInformation,
    private retailService: RetailSetupService, private dataWithTerminalLink: RetailOutletsDataService,
    private ccData: RetailCreditCardTerminalDataService, private business: RetailOutletsBusinessService,
    private dataWithoutTerminalLink: SubPropertyDataService,
    private retailFunctionalityBusiness: RetailFunctionalityBusiness,private dialog: MatDialog) {

    if (this.propertyConfig.UseRetailInterface) {
      this.data = this.dataWithoutTerminalLink;
    } else {
      this.data = this.dataWithTerminalLink;
    }

  }

  async ngOnInit() {
    this.captions = this.localization.captions.retailsetup;
    this.commonCaptions = this.localization.captions.common;
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(bp => bp.breakPointNumber == myGlobals.RetailBreakPoint.OutletSetup).view;
    this.retailFunctionalities = await this.retailFunctionalityBusiness.getRetailFunctionality();
    await this.onPageLoad();
    this.sncProductID = (Number(this.utils.GetPropertyInfo("ProductId")) == myGlobals.Product.SNC);
    this.setSearchHeaderOption(this.captions.createOutlet, this.captions.lbl_searchOutletPlaceholder);
  }

  async onPageLoad() {
   await this.getOutlets();
  }

  async getOutlets(){
    const cCTerminals: CCTerminal[] = await this.ccData.getAllCCTerminal();
    this.terminals = this.propertyConfig.UseRetailInterface ? [] : this.business.getTerminals(cCTerminals);
    const outlets = await this.data.getOutlets();
    this.BindToGrid(outlets);
  }

  setSearchHeaderOption(createBtnLabel, searchPlaceHolder) {
    this.searchHeaderOption = {
      createBtnLabel,
      searchPlaceHolder,
      showInactive: false,
      toggleDisabled: false,
      createBtnDisabled: this.IsViewOnly,
      hasHeaderAction: false,
      hideActionBtn: false
    };
  }

  createEvent(e)
  {
    let crudActionInput = {
      mode: myGlobals.GridOperationType.Create,
      actionButton: this.captions.btn_create,
      form: undefined,
    };
    this.openDialog(myGlobals.GridOperationType.Create, crudActionInput);
  }

  searchChange(e)
  {
    this.searchText = e;
  }

  openDialog(preTit: any, data?: any): any {
    this.customEditCancelled=false;
    this.EditCaption = (preTit == myGlobals.GridOperationType.Edit) ? this.localization.captions.common.EDIT : this.localization.captions.common.pg_title_create;
    let afterClosed = true;
    return this.triggerPopup(afterClosed, data);
  }


  triggerPopup(afterClosed = true, data: any): any {
    const Dialogtitle = this.captions.pg_title_outlet;
    const dialogRef = this.dialog.open(CreateReatilOutletsComponent, {
      width: '60%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: {
        headername: this.EditCaption + '  ' + Dialogtitle,
        closebool: true,
        isViewOnly: this.IsViewOnly,
        datarecord: data
      },
      disableClose: true
    }).afterClosed().subscribe(x => {
      this.isEditFlag = false;
      this.customEditCancelled=true;
      if (x.from === 1){
        this.performSave(x);
      }
      else{
        this.UpdateOutlet(x);
      }
    });

    return dialogRef;
  }

  async performSave(form: { formValues: any }) {
    try {
      const outlet: SubPropertyModel = {
        subPropertyID: 0,
        isActive: true,
        subPropertyName: form.formValues.OutletName,
        subPropertyCode: form.formValues.OutletName,
        CCTerminalId:  form.formValues.terminalId == null ? "" : form.formValues.terminalId,
        propertyID: Number(this.utils.GetPropertyInfo('PropertyId')),
        autoReceipt: form.formValues.AutoPrintReceipts || false,
        profitCenter: form.formValues.profitCenter,
        isDefault: form.formValues.default,
        includeOutletAddress: form.formValues.includeOutletAddress,
        outletAddresses: this.MapAddress(form),
        outletContacts: this.MapContactInformation(form)
      };
      let outletsDs: Outlet[] = [];
      const existingOutlets: Outlet[] = this.tableoptions[0].TablebodyData;
      const minErr: boolean = this.business.checkForMinValidation(outlet);
      let translogBody: Translog;

      if (!minErr || !this.business.ValidateOutlet(existingOutlets, outlet)){
        this.BindToGrid(existingOutlets);
        return;
      }
      outletsDs = await this.data.createOutlet(outlet);
      outlet.subPropertyID = outletsDs.find(r => r.outletName.toLowerCase() === outlet.subPropertyName.toLowerCase()).id;
      translogBody = this.business.GetTranslogBody(myGlobals.RetailCodeTranslogType.RETAILCODECREATE,
          myGlobals.RetailCodeTranslogDescription.OUTLETCREATED, outlet, null);

      if (translogBody) {
        this.retailService.InvokeServiceCallAsync('OutletTransLog', myGlobals.Host.retailManagement, HttpMethod.Post, translogBody, '');
      }
      this.setDefaultOutlet();
      this.utils.showCommonAlert('', AlertType.Success, ButtonTypes.Ok, (res) => {
        this.BindToGrid(outletsDs);
      });
    }catch (error) {
      console.error(error);
      this.BindToGrid(this.tableoptions[0].TablebodyData);
    }
  }

  MapContactInformation(form: { formValues: any }){
    let OutletContact : any = []
    form.formValues.phone.forEach(x=>{
      if (x.phoneType && x.phoneNumber){
        OutletContact.push({
          contactTypeId :  x.phoneType != "" && x.phoneType != null ? x.phoneType : "",
          number: this.utils.buildPhoneNumber(x.phoneType, x.extension, x.phoneNumber, x.countryCode),
        });
      }
    });
    return OutletContact;
  }
  MapAddress(form: { formValues: any }){
    return [{
      zip: form.formValues.postalCode,
      state: form.formValues.state,
      city: form.formValues.city,
      country: form.formValues.country,
      address1: (form.formValues.address as any[]).length > 0 ? form.formValues.address[0]['addressDetails']: "",
      address2: (<any[]>form.formValues.address).length > 1 ? form.formValues.address[1]['addressDetails'] : "",
      address3: (form.formValues.address as any[]).length > 2 ? form.formValues.address[2]['addressDetails'] : ""
    }];
  }

  setDefaultOutlet()
  {
    let defaultOutlet = this.propertyConfig.GetDefaultOutlet();
      if (defaultOutlet > 0 ) {
       this.propertyConfig.SetDefaultOutlet(defaultOutlet);
      }
  }

  async UpdateOutlet(data: any) {
    try {
      const outlet: SubPropertyModel = {
        subPropertyID: 0,
        isActive: true,
        subPropertyName: data.formValues.OutletName,
        subPropertyCode: data.formValues.OutletName,
        CCTerminalId: data.formValues.terminalId == null ? "" : data.formValues.terminalId,
        propertyID: Number(this.utils.GetPropertyInfo('PropertyId')),
        autoReceipt: data.formValues.AutoPrintReceipts || false,
        profitCenter: data.formValues.profitCenter,
        isDefault: data.formValues.default,
        includeOutletAddress: data.formValues.includeOutletAddress,
        outletAddresses : this.MapAddress(data),
        outletContacts : this.MapContactInformation(data)
      };

      let outletsDs: Outlet[] = [];
      const existingOutlets: Outlet[] = this.tableoptions[0].TablebodyData;
      const minErr: boolean = this.business.checkForMinValidation(outlet);
      let translogBody: Translog;
      // Update
      if (!await this.isValidForInActive(existingOutlets[this.currIndex].id, data.formValues.active)) {
        existingOutlets[this.currIndex].isActive = true;
        this.BindToGrid(existingOutlets);
        return;
      }
      outlet.subPropertyID = existingOutlets[this.currIndex].id;
      outlet.isActive = data.formValues.active;
      outlet.terminalLinkId = existingOutlets[this.currIndex].outletTerminalId;
      if (!minErr || !this.business.ValidateOutlet(existingOutlets, outlet)) {
         this.BindToGrid(existingOutlets);
         return;
     }
      outletsDs = await this.data.updateOutlet(outlet);
      translogBody = this.business.GetTranslogBody(myGlobals.RetailCodeTranslogType.RETAILCODEEDIT,
         myGlobals.RetailCodeTranslogDescription.OUTLETMODIFIED, outlet, existingOutlets[this.currIndex]);

      if (translogBody) {
        this.retailService.InvokeServiceCallAsync('OutletTransLog', myGlobals.Host.retailManagement, HttpMethod.Post, translogBody, '');
      }
      this.setDefaultOutlet();
      this.utils.showCommonAlert('', AlertType.Success, ButtonTypes.Ok, (res) => {
        this.BindToGrid(outletsDs);
      });
    }catch (error) {
      console.log(error);
      this.BindToGrid(this.tableoptions[0].TablebodyData);
    }
  }


  async Done(event: Outlet) {
    if (!event.isActive && event.isDefault) {
      this.utils.showAlert(this.captions.alert_defaultOutletInactive, AlertType.Warning, ButtonType.Ok, res =>{
        this.BindToGrid(this.outletsForGrid);
      });
      return;
    }
    if (!await this.isValidForInActive(event.id, event.isActive)) {
      event.isActive = true;
      return;
    }
    let outletBdy: SubPropertyModel = {
      subPropertyID: event.id,
      isActive: event.isActive,
      subPropertyName: event.outletName,
      CCTerminalId: event.CCTerminalId,
      propertyID: Number(this.utils.GetPropertyInfo('PropertyId')),
      terminalLinkId: event.outletTerminalId,
      autoReceipt: event.autoReceipt,
      isDefault: event.isDefault,
      profitCenter: event.profitCenter,
      includeOutletAddress: event.includeOutletAddress,
      outletAddresses:[{
        address1: event.address1,
        address2: event.address2,
        address3: event.address3,
        city: event.city,
        country: event.country,
        state: event.state,
        zip: event.zip
      }]
    }
    let outletsDs = await this.data.updateOutlet(outletBdy);
    this.BindToGrid(outletsDs);
    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == event.id);
    let existingOutlet: Outlet = _.cloneDeep(this.tableoptions[0].TablebodyData[this.currIndex]);
    let translogBody = this.business.GetTranslogBody(myGlobals.RetailCodeTranslogType.RETAILCODEEDIT, myGlobals.RetailCodeTranslogDescription.OUTLETMODIFIED, outletBdy, existingOutlet);
    if (translogBody) {
      this.retailService.InvokeServiceCallAsync('OutletTransLog', myGlobals.Host.retailManagement, HttpMethod.Post, translogBody, '');
    }
  }

  private async isValidForInActive(outletId: number, newStatus: boolean): Promise<boolean> {
    let isValidForChange = true;
    if (newStatus === true) {
      return isValidForChange;
    }

    let params = { status: TransactionStatus.OPEN, outletId: outletId, transactionDate: this.localization.ConvertDateToISODateTime(this.propertyConfig.CurrentDate), IncludeVoid: false };
    var apiResponse = await this.retailService.InvokeServiceCallAsync('GetTransactionsForDay', myGlobals.Host.retailPOS, HttpMethod.Get, null, params);
    if (apiResponse && apiResponse.result && apiResponse.result.length > 0) {
      isValidForChange = false;
      this.utils.ShowErrorMessage(this.commonCaptions.Error, this.captions.OutletInactiveNotAllowed, myGlobals.ButtonType.Ok);
    }
    return isValidForChange;
  }


  EditRecords(data?: any, type?: any) {
    this.isEditFlag = true;
    this.activeRecord = data[0];
    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == data[0].id);
    let crudActionInput = {
      mode: myGlobals.GridOperationType.Edit,
      actionButton: this.captions.UPDATE,
      form: {
        OutletName: this.tableoptions[0].TablebodyData[this.currIndex].outletName,
        profitCenter: this.tableoptions[0].TablebodyData[this.currIndex].profitCenter,
        terminalId: this.tableoptions[0].TablebodyData[this.currIndex].CCTerminalId,
        AutoPrintReceipts:this.tableoptions[0].TablebodyData[this.currIndex].autoReceipt,
        active: this.tableoptions[0].TablebodyData[this.currIndex].isActive,
        default: this.tableoptions[0].TablebodyData[this.currIndex].isDefault,
        id: this.tableoptions[0].TablebodyData[this.currIndex].id,
        includeOutletAddress: this.tableoptions[0].TablebodyData[this.currIndex].includeOutletAddress,
        outletAddresses: this.tableoptions[0].TablebodyData[this.currIndex].outletAddresses,
        outletContacts: this.tableoptions[0].TablebodyData[this.currIndex].outletContacts
      },
    };
    this.openDialog(myGlobals.GridOperationType.Edit, crudActionInput);

  }

  async DeleteRecords(event: any) {
    let outlet: Outlet = <Outlet>event[0];
    let subP: SubPropertyModel = {
      propertyID: Number(this.utils.GetPropertyInfo('PropertyId')),
      subPropertyID: outlet.id,
      isActive: outlet.isActive,
      subPropertyName: outlet.outletName,
      CCTerminalId: outlet.CCTerminalId,
      terminalLinkId: outlet.outletTerminalId,
      autoReceipt: outlet.autoReceipt,
      profitCenter: outlet.profitCenter,
      isDefault: outlet.isDefault,
      includeOutletAddress: outlet.includeOutletAddress,
      outletAddresses:[{
        address1: outlet.address1,
        address2: outlet.address2,
        address3: outlet.address3,
        city: outlet.city,
        country: outlet.country,
        state: outlet.state,
        zip: outlet.zip
      }]
    }
    if(subP.isDefault)
    {
      this.utils.showCommonAlert(this.captions.alert_DefaultOutletDelete, AlertType.Warning, ButtonTypes.Ok, async (res) => {});
    }
    else
    {
      this.DeleteOutlet(subP,outlet);
    }
  }

  private async DeleteOutlet(subP:SubPropertyModel,outlet:Outlet)
  {
    if (await this.data.validateOutletDelete(subP.subPropertyID)) {
      const outletDs = await this.data.deleteOutlet(subP);
      let translogBody = this.business.GetTranslogBody(myGlobals.RetailCodeTranslogType.RETAILCODEDELETE, myGlobals.RetailCodeTranslogDescription.OUTLETDELETED, null, outlet);
      if (translogBody) {
        this.retailService.InvokeServiceCallAsync('OutletTransLog', myGlobals.Host.retailManagement, HttpMethod.Post, translogBody, '');
      }
      this.BindToGrid(outletDs);
    } else {
      const errMsg = this.localization.getError(10719);
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
    }
  }
  private async BindToGrid(outlets?: OutletUIMapper[]) {
    this.outletsForGrid = cloneDeep(outlets);
    this._products = await this.retailService.InvokeAPICalls(myGlobals.Host.authentication, "GetStandAloneProducts", HttpMethod.Get);
    if (this._products && this._products.length > 0) {
      outlets.forEach(res => {
        const product = this._products.find(x => x.id == res.productId);
        if (product) {
          res.productName = product.productName;
        }
        //res.profileCentreId = "Test";
      });
    }
    this.outlets = outlets;
    if (outlets && outlets.length && Number(this.utils.GetPropertyInfo('ProductId')) === myGlobals.Product.SNC) {
      this.AddButtonDisable = this.outlets.some(x => x.productId === myGlobals.Product.SNC);
    }
    else {
      this.AddButtonDisable = false;
    }
    this.searchHeaderOption.createBtnDisabled=this.AddButtonDisable||this.IsViewOnly;
    this.searchHeaderOption={...this.searchHeaderOption};
    this.tableoptions = [
      {
        TableHdrData: this.getHeaderOptions(),
        TablebodyData: outlets,
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.Search,
        EnableActions: true,
        SelectRows: true,
        IsCommission: true,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.outlet,
        Sortable: "outletNumber",
        TableId: myGlobals.GridType.outlet,
        disableDelete: false,
        customHeader: true,
        pageTitle: 'outlets',
        ServiceId: 'outlets',
        headerOptions: this.terminals,
        IsViewOnly: this.IsViewOnly,
        enableTerminal: !this.propertyConfig.UseRetailInterface,
        enableReceiptToggleButton: true,
        receiptToggleCaption: this.captions.AutoPrintReceipts,
        automationId: 'retailOutlets'
      }
    ];

  }

  private getHeaderOptions() {

    let header: any[] = [
      { "title": this.captions.OutletNumber, "jsonkey": "outletNumber", "alignType": "right", "sortable": true, "searchable": false },
      { "title": this.captions.OutletName, "jsonkey": "outletName", "sortable": true,"alignType": "left", "searchable": true }];

    if (this.retailFunctionalities.ShowTerminal) {
      header.push({ "title": this.captions.terminalId, "jsonkey": "CCTerminalId", "sortable": true, "searchable": false , "alignType": "right"},
                  { "title": "Profit Center", "jsonkey": "profitCenter", "sortable": true, "searchable": true , "alignType": "right"}
      );
    }

    header = [...header, ...[
    { "title": this.captions.ProductName, "jsonkey": "productName", "alignType": "left", "sortable": true, "searchable": false },
    { "title": this.captions.AutoPrintReceipts, "jsonkey": "autoReceipt", "type": "toggle", "sortable": true, "searchable": false },
    { "title": this.captions.Active, "jsonkey": "isActive", "type": "toggle", "sortable": true, "searchable": false }
    ]];

    if (this.propertyConfig.UseRetailInterface) {
      header = [
        { "title": this.captions.OutletNumber, "jsonkey": "outletNumber", "alignType": "right", "sortable": true, "searchable": false },
        { "title": this.captions.OutletName, "jsonkey": "outletName", "sortable": true, "searchable": true },
      { "title": this.captions.ProductName, "jsonkey": "productName", "alignType": "left", "sortable": true, "searchable": false },
      { "title": this.captions.Active, "jsonkey": "isActive", "type": "toggle", "sortable": true, "searchable": false }];
    }

    return header;
  }
}
