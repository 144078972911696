import { Injectable } from '@angular/core';
import { DashboardFunctions } from '../utilities/dashboard-functions';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor(private dashboardFunction: DashboardFunctions) { }

  public defaultLocale(): string {
    return this.dashboardFunction.getLocaleCode();
  }
}
