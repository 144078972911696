<section class="sales-mix-report" [formGroup]="SalesMixFormGrp" autocomplete="off">
  <app-retail-multi-select-dropdown *ngIf="users" [dropDownFilterData]="users" [dropDownControlname]="'users'"
    [dropDownName]="captions.Users" (dropDownFrmControl)="getFormControlValue($event)"
    (IsAnySelected)="IsSelectionValid($event)"></app-retail-multi-select-dropdown>
  <mat-error class="user-select" *ngIf="!validSelection"> 
    {{validSelectionErrMsg}}
  </mat-error>
  <div class="mb-3">
    <label class="LW14">{{inActiveText}}</label>
    <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleIncludeInactive($event[0])"
      [formControl]="SalesMixFormGrp.controls['includeInactiveusers']"></app-retail-toggle-switch>
  </div>
  <div class="mb-3">
    <label class="LW14">{{captions.includeReturnWOT}}</label>
    <app-retail-toggle-switch class="d-block" formControlName="toggleIncludeReturnWithoutTicket"></app-retail-toggle-switch>
  </div>

</section>