import { BookingBehavior } from "../constants/commonenums";
import { RatePlanSummaryDTO } from "./RatePlanSummary.model";
import { AddOnDetailsDto, BookingChargeType } from "./RestaurantDTO";
import { TimeRangeDTO } from "./TimeRangeDTO";

export class ActivityRatePlanRequest{
        Slots: any[];
        ActivityId: number;
        GuestTypes: GuestType[];
        TableIds: number[];
        Size: number;
        SessionSlot: SessionSlot;
        BookingBehavior: BookingBehavior;
        NegotiatedAmount?: number;
        MemberType: string;
        IsMemberActive: boolean;
        SeatingTypeIds: number[];
        RateType?: string;
        RateCode?: string;
}

export class RatePlanRequest{
    Slots: any[];
    ActivityId: number;
    GuestTypes: GuestType[];
    TableIds: number[];
    Size: number;
    SessionSlot: SessionSlot;
    BookingBehavior: BookingBehavior;
    payments: RatePlanSummaryDTO
}

// public List<RatePlanTimeRangeDTO> Slots { get; set; }
// public SessionSlot SessionSlot { get; set; }


// public List<int> TableIds { get; set; }
// public List<int> InstructorIds { get; set; }
// public List<CoverTypeQuantityDTO> GuestTypes { get; set; }
// public string MemberType { get; set; }
// public bool IsMemberActive { get; set; }
// public int? ActivityId { get; set; }
// public int? FinancialEffectId { get; set; }
// public BookingBehavior BookingBehavior { get; set; }
// public DateTime? StartDate { get; set; }
// public DateTime? EndDate { get; set; }
// public decimal? NegotiatedAmount { get; set; }
// public RatePlanTotalNegotiation RatePlanTotalNegotiation { get; set; }
// public List<AddOnRequest> SelectedAddOns { get; set; }
// public string RateCode { get; set; }
// public string RateType { get; set; }
// public int? SeatingAreaId { get; set; }
// public List<UpdatedBookingContactDTO> BookingContacts { get; set; }
//public ReservationPayment payments { get; set; }

export class GuestType{
    CoverTypeId: number;
    covers: number;
}

export class SessionSlot{
    Sessions: Session[];
    SessionGroupId: any;
}

export class Session{
    Date: string;
    SessionIds: number[];
}

export class BookingCharges{
    ChargeType: ChargeType;
    EntityId?:number;
    Price?: number;
    NegotiatedPrice?: number;
    Size: number;
}

export enum ChargeType{
    BasePrice = 0,
        Location,
        Staff,
        CoverType,
        BookingType,
        Membership,
        AdditionalRate
}

export class RatePlanCalculationInfo{
    TimeRange: TimeRangeDTO;
    RatePlanVariants :RatePlanVariants;
    MemberShipVariant: number;
    SeatingTypeId:number;
    TotalAmount: number;
    TotalAmountWithMemberShipVariant:number;
}

export class RatePlanVariants{
  NegotiatedAmount: number;
    GuestTypeVariant:GuestTypeVariant[];
    BookingTypeVariant:BookingTypeVariant[];
    TotalBaseRate:number;
    TotalVariants:number;
    LocationVariant:number;
    InstructorVariant:number;
}

export class GuestTypeVariant {
    CoverTypeId: number;
    Variant: number;
    covers: number;
  NegotiatedAmount: number;
}

export class BookingTypeVariant {
    BookingTypeId: number;
    Variant: number;
    bookingTypes: number;
  NegotiatedAmount: number;
}

export class RatePlanTotalNegotiation
 {
 public Negotiation: number;
public RatePlanCalculationInfoNegotiation : RatePlanCalculationInfoNegotiation;
 } 
 
 export class RatePlanCalculationInfoNegotiation
 {
   public Id :number;
   public TimeRange:Date;   
   public RatePlanVariants :RatePlanVariants 
   public  NegotiationLesson: number;
   public  NegotiationAddon: number;
   public AddonAttemptDetails  : any;
   OverallBookingTotalNegotiatedAmount: number;
   OverallAddonTotalNegotiatedAmount:number;
   OverallSessionNegotiatedAmount: number;
}


 export class ApplyNegotiationOnOtherCharges
 {
 public bookingChargeType: BookingChargeType;
 public OldAmount: number;
 public NewAmount: number;
 public UseAfterNegotiation:number;
}

export enum CommentType{
    None,
    Cancellation,
    Negotiation
}