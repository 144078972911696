<ng-container [ngSwitch]="levelMenu">
    <!-- header -->
    <div class="wrapper-container" [ngClass]="{'innerContainer': levelMenu == menuType.vertical}">
        <div
            [ngClass]="{'menuLowerlevel-container': levelMenu == menuType.vertical,'menu-container' : levelMenu == menuType.initial,'menuSecondary-container':levelMenu ==menuType.horizontal}">
            <div *ngSwitchCase="menuType.initial">
                <div class="header-background" (window:resize)="bindHeaderData()">
                    <div [class]="logoVar">
                        <a class="logo-anchor" routerLink="/home">
                            <img [src]="logoSrc" class="ag-image" alt="logoName" />
                        </a>
                    </div>
                    <div class="menu-section">

                        <ng-container *ngIf="searchOpen">
                            <ng-content select="app-global-search"></ng-content>
                        </ng-container>
                        <ng-container *ngIf="menusearchOpen">
                            <app-menu-search class="menu-search" (OnMenuOptionSelected)="MenuOptionSelected($event)"
                                (onMenuSearch)="onMenuSearch($event)"></app-menu-search>
                        </ng-container>
                        <ng-container *ngIf="!searchOpen && !menusearchOpen">
                            <ng-container *ngTemplateOutlet="horizontalMenu;context: { a:menuItems,b:moreListItem}">
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="profile-section ag_display--flex">
                        <app-literals-edit></app-literals-edit>
                        <button class="searchClass buttonStyle cursor" *ngIf="_literalUpdate.isDesignAuthor"
                            (click)="download()">
                            <i  aria-hidden="true" >
                                <span class="material-icons">cloud_download</span>
                            </i>
                        </button>
                        <button *ngIf="!disableGlobalSearch" class="searchClass buttonStyle cursor"
                            [matTooltip]="captions.search" (click)="openGlobalSearch($event,searchOpen)">
                            <i  aria-hidden="true"  class="icon-search-class">
                                <span class="icon-Circle"></span>
                                <span class="icon-Searchbar" *ngIf="!searchOpen"></span>
                                <span class="icon-Cancel" *ngIf="searchOpen"></span>
                            </i>
                        </button>

                        <button *ngIf="!disableMenuSearch" class="menuClass buttonStyle cursor"
                            [matTooltip]="captions.menuSearch" (click)="openMenuSearch()">
                            <i  aria-hidden="true"  class="icon-search-class">
                                <span class="icon-Circle" [ngClass]="{'dark': !menusearchOpen }"></span>
                                <span class="icon-menu-search" *ngIf="!menusearchOpen"></span>
                                <span class="icon-Cancel" *ngIf="menusearchOpen"></span>
                            </i>
                        </button>

                        <button *ngIf="!disableOutlookConfig" class="notifyClass buttonStyle cursor" matTooltip="{{this.msgTxt}}"
                            (click)="signInSignOut()">
                            <i  aria-hidden="true" class="icon-user-class">
                                <span class="icon-Circle" [ngClass]="{'dark': !authenticated}"></span>
                                <span class="icon-user" *ngIf="!authenticated"></span>
                                <span class="icon-active-user" *ngIf="authenticated"></span>
                            </i>
                        </button>
                        <button *ngIf="showJasperSoftServerMenu" class="notifyClass buttonStyle cursor" matTooltip="{{this.openJaspersoftServer}}"
                        (click)="openJasperSoftServerLink()">
                        <i  aria-hidden="true" class="icon-user-class">
                           <span class="icon-Circle"></span>
                             <span class="icon-jaspersoft"></span>
                        </i>
                    </button>
                        <button *ngIf="!disableNotification && prodId !=6" class="notifyClass buttonStyle cursor custom-notify-relative" [popover]="notificationPopOver"  popoverPlacement="bottom" [popoverOnHover]="false" matTooltip="{{this.captions.common.lbl_notify}}" >
                            <i [matTooltip]="captions.Notification" class="icon-notify icon-notification"></i>
                            <span *ngIf = "notificationCount > 0" class="count">{{notificationCount}}</span>
                        </button>
                        <button *ngIf="!disableNotification && prodId ==6" class="notifyClass buttonStyle cursor custom-notify-relative" matTooltip="{{this.captions.common.lbl_notify}}" (click)="notificationAlert()" >
                            <i class="icon-notify icon-notification"></i>
                            <span *ngIf = "notificationCount > 0" class="count">{{notificationCount}}</span>
                        </button>
                    <ng-container *ngIf="isVcartEnabled">
                            <button [matTooltip]="activatedCart" (click)="isShopcartopen=!isShopcartopen"  (onHidden)="oncloseCartPopOver()" *ngIf="productId=='6'" class="notifyClass buttonStyle cursor custom-notify-relative"
                            [popover]="cartPopOver" popoverPlacement="bottom" [popoverOnHover]="false">
                            <i class="icon-notify icon-shop-cart"></i>
                            <span class="count cart-counts">{{cartCount}}</span>
                        </button>
                        </ng-container>
                        <button *ngIf="!disableHelpConfig" [popover]="helpPopover" [popoverOnHover]="false"
                            popoverPlacement="bottom" class="notifyClass buttonStyle cursor"
                            matTooltip="{{this.captions.helpQuestion}}">
                            <i  aria-hidden="true" class="icon-user-class">
                               
                                <span class="icon-help_question"></span>
                            </i>

                        </button>
                       <!-- ---notification-popover--- -->
<popover-content *ngIf = "notificationCount > 0" #notificationPopOver placement="bottom" class="user-popover" [animation]="true"
[closeOnClickOutside]="true" id="logOutPopOverID">

<div class="custom-popover textCenter">
    <div *ngFor="let n of notificationInfo">
        <a class="logout-style" (click)="notificationPopOver.hide();routeTransc(n.id)">{{n.message}}</a>
    </div>
    
</div>
</popover-content>
<!-- ---cart-popover--- -->
<popover-content #cartPopOver placement="bottom" class="user-popover" [animation]="true"
[closeOnClickOutside]="true" id="cartPopOverID">

<div class="custom-popover cartPopOver-wrapper ">
        <!-- <div class="scroller-sect">
        <div *ngFor="let x of cartItems">

            <div class="ag_display--flex-wrap">
                <span [matTooltip]="x.itemInfo.name" class="cart-label">
                    {{x.itemInfo.name}}
                </span>
                <span class="cart-val">
                    <span class="cart-val-currency" [matTooltip]="x.itemInfo.price">
                        {{x.itemInfo.price | Currency: true}}
                    </span>
                    <i (click)="deleteCartItem(x)"
                        class="ag_cursor--pointer ag_ml--1 icon-delete"></i>
                </span>
                <p class="ag_w--100">{{buildCartDescription(x)}}</p>
            </div>
        </div>
        <div class="emptyCartDiv">
            <span class="emptyCartSpan" *ngIf="cartItems.length == 0">Cart is empty</span>
        </div>
    </div>

    <div class="foot-sect-chekout">
        <app-button [buttontype]='checkoutButton'
            (valueChange)='cartPopOver.hide();oncheckoutAction()'></app-button>
    </div> -->
     
    <div class="scroller-sect">
       <ng-container>
            <i class="icon-close ag_cursor--pointer" (click)="cartPopOver.hide();oncloseCartPopOver()"></i>
            <app-vcart (CartCountModified)="cartCountModified($event)"></app-vcart>
       </ng-container>
    </div>

</div>

</popover-content>
                        <popover-content #helpPopover placement="bottom" class="user-popover" [animation]="true"
                            [closeOnClickOutside]="true" id="helpPopoverID" container='body'>
                            <div class="custom-popover-profile text-align-center">
                                <a class="logout-style"
                                    (click)="helpPopover.hide();iconClick()">{{this.captions.helpQuestion}}</a>
                            </div>
                            <hr />
                            <ng-container *ngIf="logViewer">
                            <div  class="custom-popover-profile text-align-center">
                                <a class="logout-style"
                                    (click)="helpPopover.hide();openLogViewer()">{{this.captions.logViewer}}</a>
                            </div>
                            <hr /></ng-container>
                            <div class="custom-popover-profile text-align-center">
                                <a class="logout-style"
                                    (click)="helpPopover.hide();openAboutDialog()">{{this.captions.about}}</a>
                            </div>

                        </popover-content>
                        <div class="user-details ag_float--right" [popover]="logOutPopOver" [popoverOnHover]="false"
                            popoverPlacement="bottom" (click)="userPop(logOutPopOver)" [popoverOnHover]="false">
                            <div class="d-flex float-right cursor">
                                <div class="ag_text--uppercase initial-box ">{{userText}}</div>
                                <div class="detail-section">
                                    <label class="d-block user-name ag_text--uppercase text-ellipsis cursor"
                                        matTooltip="{{firstName}} {{lastName}}">{{firstName}} {{lastName}}</label>
                                    <label
                                        class="d-block user-role white-opacity-color ag_text--uppercase cursor text-ellipsis"
                                        [matTooltip]="userRole">{{userRole}}</label>
                                </div>
                                <div class="arrow-icon cursor">
                                    <i  aria-hidden="true" class="material-icons">expand_more</i>
                                    <popover-content #logOutPopOver placement="bottom" class="user-popover"
                                        [animation]="true" [closeOnClickOutside]="true" id="logOutPopOverID"
                                        container='body'>                                       
                                        <div class="custom-popover-profile" *ngIf="isChangePropertyEnabled"
                                            (click)="changeProperty($event)">
                                            <a class="logout-style">{{captions.lbl_changeProperty}}</a>
                                        </div>
                                        <div class="custom-popover-profile" (click)="logout()">
                                            <a class="logout-style">{{captions.logOut}}</a>
                                        </div>
                                    </popover-content>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="menuType.combo">
                <div class="subnav-wrapper">
                    <div class="underline-hide">
                        <mat-form-field [floatLabel]="floatLabel" class="menu-field">
                            <mat-select (selectionChange)="navigateTo($event)" [(value)]="selectedItem" [attr.LiteralID]="'lbl_menuSelect'"
                                [compareWith]="compareSelect" [disableOptionCentering]="true">
                                <ng-container *ngFor="let item of menuList.menu">
                                    <mat-option  class="fontChange" [value]="item" *ngIf="item.visibility">
                                        <span >{{ item.text }}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="menuType.horizontal">
                <div class="header">
                    <nav mat-tab-nav-bar>
                        <p *ngFor="let link of menuList.menu" class="ag_mb--0">
                            <a mat-tab-link *ngIf="link.visibility" [routerLink]="link.routePath" (click)="
                            this._routingMenuService.setselectedRoutingMenu(link)" routerLinkActive
                                #rla="routerLinkActive" class="tab-view-list" [active]="rla.isActive"
                                [state]="{ onModuleChange: true, ShowPopup: !link.linkedElement?.length}">
                                {{link.text}}
                            </a>
                        </p>
                    </nav>
                </div>
            </div>
            <div *ngSwitchCase="menuType.vertical">
                <ng-container *ngTemplateOutlet="verticalMenu;context: {$implicit:menuList.menu }">
                </ng-container>
            </div>
        </div>
        <div
            [ngClass]="{'router-container': levelMenu == menuType.vertical,'other-container':levelMenu == menuType.initial,'secondary-router-container':levelMenu == menuType.horizontal,'tertiary-container':levelMenu==menuType.combo}">
            <router-outlet></router-outlet>
        </div>
    </div>

</ng-container>


<!-- horizontalMenu -->
<ng-template #horizontalMenu let-menu="a" let-more="b">
    <div class="nav-section float-left">
        <ng-container>
            <nav class="navItem">
                <ul class="nav" #navBar id="navBar">
                    <ng-container *ngFor="let menus of menu">
                        <li class="text" [id]="menus.elementID" *ngIf="menus.visibility">
                            <ng-container *ngTemplateOutlet="directMenu;context: { $implicit: menus, type : positionOptions[3] }">
                            </ng-container>
                        </li>
                    </ng-container>

                    <li *ngIf="more && more.length>0">
                        <!-- <a class="cursor ag_display--flex moreDPtext" [popover]="headerPopover" [popoverOnHover]="false" popoverPlacement="bottom" [matTooltip]="_routingMenuService.moreselectedText?.text" routerLinkActive="active-link" 
                            [popoverOnHover]="false" >
                             <i  aria-hidden="true" class="{{_routingMenuService.moreselectedText?.imgPath}} mr-2"> </i> {{_routingMenuService.moreselectedText?.text}} 
                            <i  aria-hidden="true" class="icon-filled-more ml-2" ></i>
                            {{moreTextName}}  
                        </a> -->
                        <a class="cursor" [popover]="headerPopover" [popoverOnHover]="false" popoverPlacement="bottom"
                            [popoverOnHover]="false">
                            <i  aria-hidden="true" class="icon-filled-more"></i>
                            {{moreTextName}}
                        </a>
                        <popover-content #headerPopover placement="bottom" class="header-popover custom-header-pop-style" [animation]="true"
                            [closeOnClickOutside]="true" id="HeaderPopover">
                            <div class="custom-popover" id="popover-class">
                                <ul>
                                    <ng-container *ngFor="let menu of more">
                                    <li *ngIf="menu.visibility">
                                        <a routerLinkActive="active-link"
                                            routerLink="{{menu.routePath}}" (click)="this._routingMenuService.setselectedRoutingMenu(menu,menu.text)" [matTooltip]="menu.text"
                                            [matTooltipPosition]="position.value"
                                            href="javascript:void(0)" (click)="headerPopover.hide()">
                                            <span class="icon-wrapper w25px"> <i  aria-hidden="true" class="{{menu.imgPath}}"> </i> </span>
                                            {{menu.text}}
                                        </a>
                                    </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </popover-content>
                    </li>
                </ul>
            </nav>
        </ng-container>
    </div>
</ng-template>

<!-- directMenu -->
<ng-template #directMenu let-menuShowingArr let-stateObj="stateObj" let-type="type">
    <ng-container>
        <a class="ag_text-overflow--hidden" href="javascript:void(0)" *ngIf="menuShowingArr.visibility"
            routerLinkActive="active-link" [routerLink]="menuShowingArr.routePath" (click)="
            this._routingMenuService.setselectedRoutingMenu(menuShowingArr)"
            [ngClass]="{'menuHide' : menuShowingArr.disable}" [state]="stateObj">
            <i  aria-hidden="true" *ngIf="menuShowingArr.imgPath" class="{{menuShowingArr.imgPath}}"></i>
            <div *ngIf="levelMenu == menuType.vertical" class="ag_display--inblock" routerLinkActive="arrow-section border-left-color">
            </div>
            <span class="ag_pl--3" [matTooltipPosition]="type" [matTooltip]="menuShowingArr.text">{{menuShowingArr.text}}</span>
        </a>
    </ng-container>
</ng-template>

<!-- subMenu -->
<ng-template #subMenu let-menu>
    <i  aria-hidden="true" class="{{menu.imgPath}}"></i>
    <a *ngIf="menu.visibility" [ngClass]="{'menuHide' : menu.disable}" [matMenuTriggerFor]="basic">{{menu.text}}</a>
    <mat-menu #basic="matMenu">
        <div *ngFor="let m of menu.linkedElement">

            <button *ngIf="m.linkedElement && m.visibility" mat-menu-item [matMenuTriggerFor]="subMenu"
                [ngClass]="{'menuHide' : !m.disable}"> <i  aria-hidden="true" class="{{m.imgPath}}"></i>{{m.text}}</button>
            <button *ngIf="!m.linkedElement && m.visibility" mat-menu-item [ngClass]="{'menuHide' : !m.disable}"><i aria-hidden="true" 
                    class="{{m.imgPath}}"></i>{{m.text}}</button>
            <mat-menu #subMenu="matMenu">
                <div *ngFor="let ms of m.linkedElement">
                    <button *ngIf="ms.linkedElement && ms.visibility" mat-menu-item [matMenuTriggerFor]="nestedMenu"
                        [ngClass]="{'menuHide' : !ms.disable}"><i aria-hidden="true" class="{{ms.imgPath}}"></i>{{ms.text}}</button>
                    <button *ngIf="!ms.linkedElement && ms.visibility" mat-menu-item
                        [ngClass]="{'menuHide' : !ms.disable}"><i  aria-hidden="true" class="{{ms.imgPath}}"></i>{{ms.text}}</button>
                    <mat-menu #nestedMenu="matMenu">
                        <div *ngFor="let mse of ms.linkedElement">
                            <button *ngIf="mse.visibility" mat-menu-item [ngClass]="{'menuHide' : !mse.disable}"><i
                                aria-hidden="true"      class="{{mse.imgPath}}"></i>{{mse.text}}</button>
                        </div>
                    </mat-menu>
                </div>
            </mat-menu>
        </div>
    </mat-menu>
</ng-template>

<!-- verticalMenu -->

<ng-template #verticalMenu let-verticalList>
    <div class="vertical-header">
        <aside class="nav-section">
            <ng-container>
                <nav class="navItem">
                    <ul class="nav" #navBar id="navBar">
                        <ng-container *ngFor="let menu of verticalList">
                            <li class="text" *ngIf="menu.visibility">
                                <ng-container
                                    *ngTemplateOutlet="menu.linkedElement?.length>0 ? subMenu: directMenu;context: { $implicit: menu, stateObj: {ShowPopup: true}, type : positionOptions[0] }">
                                </ng-container>
                            </li>
                        </ng-container>
                    </ul>
                </nav>
            </ng-container>
        </aside>
    </div>
</ng-template>