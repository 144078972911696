import { Injectable, } from "@angular/core";
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { SubPropertyDataService } from 'src/app/retail/retail-code-setup/retail-outlets/subproperty-data.service';
import { CaddyShackService } from './caddyshack.service';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { CommonVariablesService } from '../../shared/service/common-variables.service';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { AllReports, ReportAPIOptions, ReportParams } from '../../retail-reports/business/report.modals';
import { BaseResponse, Transaction } from "../../shared/business/shared.modals";
import { HttpServiceCall, HttpMethod } from "../../shared/service/http-call.service";
import { Host, ReceiptType } from "../../shared/globalsContant";
import { PrintInformationType } from "src/app/common/Models/printer-default-configuration.model";

@Injectable()
export class CaddyShackBusinessService {


    private selectedOutletName: string;

    constructor(private readonly reportService: CaddyShackService, private localization: RetailLocalization,
        private http: HttpServiceCall, private shopService: CommonVariablesService, private utils: RetailUtilities,
        private PropertyInfo: RetailPropertyInformation, private _retailService: RetailSharedVariableService, private subPropService: SubPropertyDataService) {
    }

    public async GetUserOutletName(selectedOutletId: number) {
        var allOutlets: any = await this.subPropService.GetUserOutlets();
        return allOutlets.find(x => x.id == selectedOutletId).outletName;
    }
    

    public async GenerateCaddyShack(ticketNumber: string, selectedOutletId: number, receiptType: ReceiptType, transactionId: number, voidReason?: string, callBack?: any,caddyshackComment?:string) {
        await this.GetUserOutletName(selectedOutletId).then((o) => {
            this.selectedOutletName = o;
        });                                                                                                                                                      
        let transactionDetails = null;
        if (receiptType == ReceiptType.void) {
            await this.getTransactionDetail(transactionId).then(resp => {
                transactionDetails = resp && resp.transactionData;       
            });
        }       
        let clerkName = this.utils.GetPropertyInfo("userName");
        const UserId = Number(this.localization.GetsessionStorageValue('_userInfo', 'userId'));
        if (receiptType == ReceiptType.reprint && UserId && transactionDetails && transactionDetails.clerkId != UserId) {
            const response: any[] = await this.getClerkDetail(transactionDetails.clerkId);
            if (response && response.length > 0) { clerkName = response[0] && response[0].userName; }
        }
        let options: ReportAPIOptions = this.formReportAPIOptions(ticketNumber, receiptType, transactionId,  voidReason, clerkName,caddyshackComment);
        let caddyShackPrinter = await this.utils.getPrinterConfigurationToPrint(PrintInformationType.CaddyShack);
        this.reportService.printCaddyShack("PDF", options, callBack,caddyShackPrinter);

    }

  

    public async getTransactionDetail(transactionId: number): Promise<Transaction> {
        let response: BaseResponse<Transaction> = await this.http.CallApiAsync<Transaction>({
            callDesc: "GetTransactionInfoById",
            host: Host.retailPOS,
            method: HttpMethod.Get,
            uriParams: { transactionId: transactionId }
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    public async getClerkDetail(clerkId: number): Promise<any> {
        let response: BaseResponse<Transaction> = await this.http.CallApiAsync<Transaction>({
            callDesc: "GetAllUsersbyUserIds",
            host: Host.authentication,
            method: HttpMethod.Get,
            uriParams: { tenantId: this.utils.GetPropertyInfo('TenantId') },
            withQueryString: "ids",
            queryString: { key: "ids", value: [clerkId] }
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

   

    private formReportAPIOptions(ticketNumber: string, receiptType: ReceiptType, transactionId: number, voidReason?: string, clerkName?: string,caddyshackComment? :string): ReportAPIOptions {
        return {
            code: AllReports.CaddyShack,
            params: this.formReportParams(ticketNumber,transactionId, receiptType == ReceiptType.void?voidReason:"",clerkName,caddyshackComment),
            URIParams: this.formURIParams(transactionId),
            filters: {},
            format: 'PDF',
            pageBreak: true
        }; 
    }

    private formReportParams(
        ticketNumber: string,
        transactionId : number
        , voidReason?: string
        , clerkName?: string
        , caddyshackComment? : string,
       
    ): ReportParams[] {
        return [{ 'pPrintedData': this.localization.ConvertDateToISODateTime(this.PropertyInfo.CurrentDTTM) },      
        { 'pOutletData': this.selectedOutletName },
        { 'pTicketData': ticketNumber },
        { 'pClerk': clerkName },        
        { 'pVoidReason': voidReason ? voidReason : "" },
        {'pCaddyShackComment':caddyshackComment ? caddyshackComment :"" },
        { 'pTransactionId':transactionId},
        { 'pPropertyName':this.localization.GetPropertyInfo("PropertyName")}]
       
    }

    private formURIParams(transactionId: number): ReportParams[] {
        return [{ 'transactionId': transactionId }];
    }

  
    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
    }
}
