import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';


@Component({
  selector: 'reset-button',
  templateUrl: './form-reset-button.component.html',
  styleUrls: ['./form-reset-button.component.scss']
})
export class ResetbuttonComponent implements OnInit, Field {
  public FormControlName;
  config: FieldConfig;
  group: UntypedFormGroup;

  constructor() { }

  ngOnInit() {
    this.FormControlName = this.config.name;
  }

}
