<ng-scrollbar>
  <div class="privatelesson-booking-summary">
    <div class="privatelesson-booking-summary__booking-detail" [ngStyle]="{'padding-bottom': enableGuestComponent ? '40px' : '10px'}">
      <div *ngIf="restaurantPolicyAvailable && restaurantPolicy" class="privatelesson-booking-summary__policy-table">
        <label class="privatelesson-booking-summary__selected-tables seat-secondary-text"> {{ restaurantPolicy?.PreReservationMessageTitle }} </label>
        <span class="privatelesson-booking-summary__icon-exclamation icon-warning-info-icon" (click)="ShowRestPolicyDetailsClick()"></span>
      </div>
      <div class="privatelesson-booking-summary__summary-accordian" *ngIf="partyService.privateLessonBookingData">
        <mat-accordion class="p-10">
          <mat-expansion-panel [expanded]="false" class="privatelesson-booking-summary__summary-accordian--panel">
            <mat-expansion-panel-header>
              <mat-panel-title class="privatelesson-booking-summary__section-title privatelesson-booking-summary__section-title--accordian">
                {{'bookingSummary' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="privatelesson-booking-summary__content">
              <div class="privatelesson-booking-summary__item privatelesson-booking-summary__item--name">
                <div class="privatelesson-booking-summary__item__label">{{'instructor' | translate}}</div>
                <div class="openbooking-summary__item__value">{{instructor?.Name}} {{instructor?.LastName || ''}}</div>
              </div>
              <div class="privatelesson-booking-summary__item privatelesson-booking-summary__item--location">
                <div class="privatelesson-booking-summary__item__label">{{'location' | translate}}</div>
                <div class="openbooking-summary__item__value">
                  {{partyService.privateLessonBookingData.LocationName}}
                </div>
              </div>
              <div class="privatelesson-booking-summary__item privatelesson-booking-summary__item--date">
                <div class="privatelesson-booking-summary__item__label">{{'date' | translate}}</div>
                <div class="openbooking-summary__item__value">
                  {{partyService.privateLessonBookingData.FromDate | formatSettingsDate: cs.settings.value.General.DateFormat }} <span class="text-small">
                    {{
(partyService.privateLessonBookingData.Slots[partyService.privateLessonBookingData.Slots.length
                    -1]?.LocalTime | formatSettingsDate: cs.settings.value.General.DateFormat) !== (partyService.privateLessonBookingData.FromDate | formatSettingsDate: cs.settings.value.General.DateFormat )? '(Extended Shift)' : ''
                    }}
                  </span>
                </div>
              </div>
              <div class="privatelesson-booking-summary__item  privatelesson-booking-summary__item--booking-size">
                <div class="privatelesson-booking-summary__item__label">{{'bookingSize' | translate}}</div>
                <div class="openbooking-summary__item__value">
                  {{partyService.privateLessonBookingData?.BookingSize}}
                </div>
              </div>
              <div class="privatelesson-booking-summary__item  privatelesson-booking-summary__item--slots"
                   *ngIf="ratePlan">
                <div class="privatelesson-booking-summary__item__label">{{'timeSlots' | translate}}</div>
                <div class="privatelesson-booking-summary__item__value"
                     *ngIf="ratePlan.RatePlanCalculations">
                  <span *ngFor="let slot of ratePlan.SlotList | slice:0:(showAllSlots ? partyService.privateLessonBookingData.Slots.length : 4 ); let index = index;">
                    {{
slot.TimeRange.Start
                    | localizedDate:'LT'
                    }}
                    - {{slot.TimeRange.End | localizedDate:'LT'}}{{
partyService.privateLessonBookingData.Slots[index+1] &&
                    ','
                    }}
                    &nbsp;
                  </span>
                </div>
                <div class="privatelesson-booking-summary__item__value" *ngIf="!ratePlan.RatePlanCalculations && ratePlan.SlotList">
                  <span *ngFor="let slot of ratePlan.SlotList | slice:0:(showAllSlots ? partyService.privateLessonBookingData.Slots.length : 4 ); let index = index;">
                    {{
slot?.LocalTime
                    | localizedDate:'LT'
                    }}
                    - {{slot.EndTime | localizedDate:'LT'}}{{partyService.privateLessonBookingData.Slots[index+1] && ','}}
                    &nbsp;
                  </span>
                </div>
                <div class="privatelesson-booking-summary__slots-show-action"
                     *ngIf="partyService.privateLessonBookingData.Slots.length > 4" (click)="showAllSlots = !showAllSlots">
                  {{showAllSlots ? 'Show Less' : '+'+(partyService.privateLessonBookingData.Slots.length-4)+' '+'more'}}
                </div>

              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="privatelesson-booking-summary__rate-plan"
           *ngIf="ratePlan && (ratePlan.TotalRatePlan || ratePlan.TotalAddonAmount) && ratePlan.RatePlanCalculations">
        <div class="privatelesson-booking-summary__section-title">
          {{'Rates' | translate}}
          <!-- <dynamic-form [config]="negotiationRatesConfig" #form="dynamicForm">
          </dynamic-form> -->
        </div>
        <mat-accordion *ngFor="let slot of ratePlan.SlotList; let index= index;">
          <mat-expansion-panel [expanded]="index == 0"
                               class="privatelesson-booking-summary__summary-accordian--panel privatelesson-booking-summary__summary-accordian--rate-plan">
            <mat-expansion-panel-header>
              <mat-panel-title class="privatelesson-booking-summary__section-title--accordian">
                {{slot.TimeRange.Start | localizedDate:'LT'}} - {{slot.TimeRange.End | localizedDate:'LT'}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- <app-rate-plan-summary [selectedInfo] = "ratePlan"></app-rate-plan-summary> -->
            <!-- Table Header -->
            <!-- Table Header -->
            <div class="py-1 price-table-header d-flex flex-row seat-header-four">
              <div class="p-0 col-lg-3 col-3">
                <div class="activity-description-bolder">{{'Description' | translate}}</div>
              </div>
              <div class="p-0 col-lg-1 col-1 d-flex justify-content-center">
                <div class="activity-description-bolder">{{'Qty' | translate}}</div>
              </div>
              <div class="p-0 col-lg-2 col-2 d-flex justify-content-end text-align-right">
                <div class="activity-description-bolder">{{'PrevSalePrice' | translate}}</div>
              </div>
              <div class="p-0 col-lg-2 col-2 d-flex justify-content-end text-align-right">
                <div class="activity-description-bolder">{{'OriginalAmount' | translate}}</div>
              </div>
              <div class="p-0 col-lg-2 col-2 d-flex justify-content-end">
                <div class="activity-description-bolder">{{'SalePrice' | translate}}</div>
              </div>
              <div class="p-0 col-lg-2 col-3 d-flex justify-content-end">
                <div class="activity-description-bolder">{{'ItemTotal' | translate}}</div>
              </div>
            </div>
            <div *ngIf="slot.TotalAmount <= 0 && slot.TotalAddOnAmount <=0; else ratePlanDivisions">
              <span style="font-size: 12px;color: red;">{{'noRatePlanApplicable' | translate}}</span>
            </div>
            <ng-template #ratePlanDivisions>
              <ng-container>
                <div class="privatelesson-booking-summary__rate-row py-1 price-table-header d-flex flex-row"
                     *ngIf="slot.RatePlanVariants && slot.RatePlanVariants.TotalBaseRate">
                  <div class="privatelesson-booking-summary__rate-label p-0 col-lg-3 col-3 d-flex ">
                    <span class="privatelesson-booking-summary__rate-label--main">{{'TotalBaseRate' | translate}}</span>
                  </div>
                  <div class="privatelesson-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
                    <span class="privatelesson-booking-summary__rate-label--main">{{this.partyService.privateLessonBookingData.BookingSize}}</span>
                  </div>
                  <div class="privatelesson-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">{{OperationCurrency}} {{slot.basePrevSalePrice | currencyDecimalFormatter}}</div>
                  <div class="privatelesson-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">
                    {{OperationCurrency}} {{
                slot.sessionChargeinitialValue |
                currencyDecimalFormatter
                    }}
                  </div>
                  <div *ngIf="!perItemEditable" class="private-booking-summary__rate">
                    {{OperationCurrency}} {{
                slot.sessionCharge |
                currencyDecimalFormatter
                    }}
                  </div>
                  <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end hg-ft"
                       *ngIf="perItemEditable">
                    <!-- <input class="activity-description-bolder privatelesson-booking-summary__editable-input-currency"
                           [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>

                    <input ngxNumberFormat="#,###,###.##" [allowNegative]="true" (blur)="onBlur($event)"
                           class="activity-description-bolder privatelesson-booking-summary__editable-input" type='text' min="1"
                           step="0.10" [(ngModel)]="slot.sessionCharge" (keyup)="onNegotiate()"
                           [ngClass]="{'pointer-active': perItemEditable}"
                           value=" slot.sessionCharge | currencyDecimalFormatter"> -->
                           {{OperationCurrency}} {{
                            (slot.sessionCharge) |
                                            currencyDecimalFormatter
                                                }}
                  </div>
                  <div class="privatelesson-booking-summary__rate p-0 col-lg-2 col-2 d-flex justify-content-end">
                    {{OperationCurrency}} {{
(slot.sessionCharge) |
                currencyDecimalFormatter
                    }}
                  </div>
                </div>
              </ng-container>

              <div class="privatelesson-booking-summary__rate-row py-1 price-table-header d-flex flex-row"
                   *ngIf="slot && slot.RatePlanVariants?.LocationVariant">
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-3 col-3 d-flex ">
                  <span class="privatelesson-booking-summary__rate-label">
                    {{'LocationVariant' | translate}} <span class="sub-text">
                      ({{'forTotalOf' | translate}} {{
slot.size ? slot.size :
              partyService.privateLessonBookingData?.BookingSize
                      }}
                    </span>
                  </span>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
                  <span class="privatelesson-booking-summary__rate-label--main">{{this.partyService.privateLessonBookingData.BookingSize}}</span>
                </div>
                <div class="privatelesson-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">{{OperationCurrency}} {{slot.prevLocationSalePrice | currencyDecimalFormatter}}</div>
                <div class="privatelesson-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">
                  {{OperationCurrency}} {{
            slot.RatePlanVariants?.LocationVariant |
            currencyDecimalFormatter
                  }}
                </div>
                <div *ngIf="!perItemEditable" class="privatelesson-booking-summary__rate">
                  {{OperationCurrency}} {{
            slot.RatePlanVariants?.LocationVariant |
            currencyDecimalFormatter
                  }}
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end hg-ft"
                     *ngIf="perItemEditable">
                  <input class="activity-description-bolder privatelesson-booking-summary__editable-input-currency"
                         [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>

                  <input ngxNumberFormat="#,###,###.##" [allowNegative]="true" (blur)="onBlur($event)"
                         class="activity-description-bolder privatelesson-booking-summary__editable-input" type='text' min="1"
                         step="0.10" [(ngModel)]="slot.RatePlanVariants.locationAmount" (keyup)="onNegotiate()"
                         [ngClass]="{'pointer-active': perItemEditable}">
                </div>
                <div class="privatelesson-booking-summary__rate p-0 col-lg-2 col-2 d-flex justify-content-end">
                  {{OperationCurrency}} {{
(slot.RatePlanVariants?.locationAmount) | currencyDecimalFormatter
                  }}
                </div>
              </div>

              <!-- Instructor Variant -->
              <div class="privatelesson-booking-summary__rate-row py-1 price-table-header d-flex flex-row"
                   *ngIf="slot && slot.RatePlanVariants?.InstructorVariant">
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-3 col-3 d-flex ">
                  <span class="privatelesson-booking-summary__rate-label">
                    {{'InstructorVariant' | translate}} <span class="sub-text">
                      ({{'forTotalOf' | translate}} {{
slot.size ? slot.size :
            partyService.privateLessonBookingData?.BookingSize
                      }}
                    </span>
                  </span>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
                  <span class="privatelesson-booking-summary__rate-label--main">{{this.partyService.privateLessonBookingData.BookingSize}}</span>
                </div>
                <div class="privatelesson-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">{{OperationCurrency}} {{slot.prevInstructorSalePrice | currencyDecimalFormatter}}</div>
                <div class="privatelesson-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">
                  {{OperationCurrency}} {{
          slot.RatePlanVariants?.InstructorVariant |
          currencyDecimalFormatter
                  }}
                </div>
                <div *ngIf="!perItemEditable" class="privatelesson-booking-summary__rate">
                  {{OperationCurrency}} {{
          slot.RatePlanVariants?.InstructorVariant |
          currencyDecimalFormatter
                  }}
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end hg-ft"
                     *ngIf="perItemEditable">
                  <input class="activity-description-bolder privatelesson-booking-summary__editable-input-currency"
                         [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>

                  <input ngxNumberFormat="#,###,###.##" [allowNegative]="true" (blur)="onBlur($event)"
                         class="activity-description-bolder privatelesson-booking-summary__editable-input" type='text' min="1"
                         step="0.10" [(ngModel)]="slot.RatePlanVariants.instructorAmount" (keyup)="onNegotiate()"
                         [ngClass]="{'pointer-active': perItemEditable}">
                </div>
                <div class="privatelesson-booking-summary__rate p-0 col-lg-2 col-2 d-flex justify-content-end">
                  {{OperationCurrency}} {{
(slot.RatePlanVariants?.instructorAmount) | currencyDecimalFormatter
                  }}
                </div>
              </div>


              <!-- Cover Type-->
              <div class="privatelesson-booking-summary__rate-row"
                   *ngIf="slot.coverTypeVariants && slot.coverTypeVariants.length">
                <div class="privatelesson-booking-summary__rate-label">
                  <span class="privatelesson-booking-summary__rate-label sub-label">{{'coverType' | translate}} </span>
                </div>
              </div>
              <div class="privatelesson-booking-summary__rate-row py-1 price-table-header d-flex flex-row ml-2 "
                   *ngFor="let variant of slot.coverTypeVariants">
                <div class="privatelesson-booking-summary__rate-label d-flex  p-0 col-lg-3 col-3">
                  <div class="activity-description-bolder">{{variant.CoverTypeName}}</div>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
                  <div class="activity-description-bolder"> {{variant.covers}} </div>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                  <span class="privatelesson-booking-summary__rate-label--main text-align-right">{{OperationCurrency}}  {{variant.prevSalePrice | currencyDecimalFormatter}}</span>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                  <span class="privatelesson-booking-summary__rate-label--main text-align-right">{{OperationCurrency}} {{variant.initialValue }}</span>
                </div>
                <div *ngIf="!perItemEditable" class="privatelesson-booking-summary__rate">
                  {{OperationCurrency}}
                  {{
(variant.coverTypeValue
                          )
                  }}
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
                     *ngIf="perItemEditable">
                  <input ngxNumberFormat="#,###,###.##" [allowNegative]="true" (blur)="onBlur($event)"
                         class="activity-description-bolder privatelesson-booking-summary__editable-input-currency"
                         [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>

                  <input ngxNumberFormat="#,###,###.##" [allowNegative]="true"
                         class="activity-description-bolder privatelesson-booking-summary__editable-input" type='text' min="1"
                         step="0.10" [(ngModel)]="variant.coverTypeValue" (keyup)="onNegotiate()"
                         [ngClass]="{'pointer-active': perItemEditable}"
                         value="{{variant.Price/variant.covers |
                              currencyDecimalFormatter}}">
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                  <div class="activity-description-bolder">
                    {{OperationCurrency}} {{
(variant.coverTypeValue * variant.covers
                          ) |
                            currencyDecimalFormatter
                    }}
                  </div>
                </div>
              </div>


              <!-- Booking Types -->
              <ng-container>
                <div class="privatelesson-booking-summary__rate-row"
                     *ngIf="slot.bookingTypeVariants && slot.bookingTypeVariants.length">
                  <div class="privatelesson-booking-summary__rate-label">
                    <span class="privatelesson-booking-summary__rate-label sub-label">{{'bookingType' | translate}} </span>
                  </div>
                </div>
                <div class="privatelesson-booking-summary__rate-row py-1 price-table-header d-flex flex-row ml-2"
                     *ngFor="let variant of slot.bookingTypeVariants">
                  <div class="privatelesson-booking-summary__rate-label d-flex  p-0 col-lg-3 col-3">
                    <div class="activity-description-bolder">{{variant.BookingTypeName}}</div>
                  </div>
                  <div class="privatelesson-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
                    <div class="activity-description-bolder"> {{variant.bookingTypes}} </div>
                  </div>
                  <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                    <span class="privatelesson-booking-summary__rate-label--main text-align-right">{{OperationCurrency}}  {{variant.prevSalePrice | currencyDecimalFormatter}}</span>
                  </div>
                  <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                    <span class="privatelesson-booking-summary__rate-label--main text-align-right">
                      {{OperationCurrency}} {{
variant.initialValue|
                      currencyDecimalFormatter
                      }}
                    </span>
                  </div>
                  <div *ngIf="!perItemEditable" class="privatelesson-booking-summary__rate">
                    {{OperationCurrency}}
                    {{
(variant.bookingTypeValue)|
                  currencyDecimalFormatter
                    }}
                  </div>
                  <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
                       *ngIf="perItemEditable">
                    <input class="activity-description-bolder privatelesson-booking-summary__editable-input-currency"
                           [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>
                    <input ngxNumberFormat="#,###,###.##" [allowNegative]="true" (blur)="onBlur($event)"
                           class="activity-description-bolder privatelesson-booking-summary__editable-input" type='text' min="1"
                           step="0.10" [(ngModel)]="variant.bookingTypeValue" (keyup)="onNegotiate()" value="{{variant.Price / variant.bookingTypes}}"
                           [ngClass]="{'pointer-active': perItemEditable}">
                  </div>
                  <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                    <div class="activity-description-bolder">
                      {{OperationCurrency}}  {{
(variant.bookingTypeValue * variant.bookingTypes
                    ) |
                      currencyDecimalFormatter
                      }}
                    </div>
                  </div>
                </div>
              </ng-container>








              <!-- Addon Attempt-->
              <div class="privatelesson-booking-summary__rate-row"
                   *ngIf="slot.AddonAttemptDetails && slot.AddonAttemptDetails.length">
                <div class="privatelesson-booking-summary__rate-label">
                  <span class="privatelesson-booking-summary__rate-label sub-label">{{ 'AddonTypes' | translate }}</span>
                </div>
              </div>
              <div class="privatelesson-booking-summary__rate-row py-1 price-table-header d-flex flex-row ml-2 "
                   *ngFor="let addOn of slot.AddonAttemptDetails">
                <div class="privatelesson-booking-summary__rate-label d-flex  p-0 col-lg-3 col-3">
                  <div class="activity-description-bolder">{{addOn.AddonId | getAddonName : _settings}}</div>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
                  <div class="activity-description-bolder"> {{addOn.Quantity}}</div>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end text-align-right">
                  <span class="privatelesson-booking-summary__rate-label--main ">{{OperationCurrency}} {{addOn.prevSalePrice | currencyDecimalFormatter}}</span>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end text-align-right">
                  <span class="privatelesson-booking-summary__rate-label--main ">{{OperationCurrency}} {{addOn.initialValue }}</span>
                </div>
                <div *ngIf="!perItemEditable" class="privatelesson-booking-summary__rate">
                  {{OperationCurrency}}
                  {{(addOn.perItemValue) }}
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
                     *ngIf="perItemEditable">
                  <input class="activity-description-bolder privatelesson-booking-summary__editable-input-currency"
                         [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>

                  <input ngxNumberFormat="#,###,###.##" [allowNegative]="true"
                         class="activity-description-bolder privatelesson-booking-summary__editable-input" type='text' min="1"
                         step="0.10" [(ngModel)]="addOn.perItemValue" (keyup)="onNegotiate()"
                         [ngClass]="{'pointer-active': perItemEditable}" (blur)="onBlur($event)"
                         value="{{((addOn.TotalAmount) / addOn.Quantity) |  currencyDecimalFormatter : '0.00'}}">
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                  <div class="activity-description-bolder">
                    {{OperationCurrency}} {{
(addOn.perItemValue * addOn.Quantity) |
                  currencyDecimalFormatter : '0.00'
                    }}
                  </div>
                </div>
              </div>

              <div class="privatelesson-booking-summary__rate-row" *ngIf="slot.DepositAmount">
                <div class="privatelesson-booking-summary__rate-label">
                  <span class="privatelesson-booking-summary__rate-label--main">{{'depositValue' | translate}}</span>
                </div>
                <div class="privatelesson-booking-summary__rate">
                  {{OperationCurrency}} {{
slot.DepositAmount |
                currencyDecimalFormatter
                  }}
                </div>
              </div>
              <!-- <div class="privatelesson-booking-summary__rate-row py-1 price-table-header d-flex flex-row"
                *ngIf="totalAddOnAmount">
                <div class="privatelesson-booking-summary__rate-label d-flex  p-0 col-lg-6 col-4">
                  <div class="activity-description-bolder">{{'addOnCharges' | translate}}</div>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-1 col-2 d-flex justify-content-center">
                  <div class="activity-description-bolder"> </div>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-3 col-3 d-flex justify-content-end"> </div>
                <div *ngIf="!sessionEditable" class="privatelesson-booking-summary__rate">{{OperationCurrency}} {{
                  totaladdonCharges | currencyDecimalFormatter}}</div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-3 d-flex justify-content-end"
                  *ngIf="sessionEditable"> <input
                    class="activity-description-bolder privatelesson-booking-summary__editable-input-currency"
                    [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>
                    <input
                    class="privatelesson-booking-summary__rate privatelesson-booking-summary__editable-input" type='number' min="1"
                    step="0.01" [ngClass]="{'pointer-active': sessionEditable}" [(ngModel)]="slot.totalAddOnAmount"
                    value="{{ (slot.totalAddOnAmount || 0) | currencyDecimalFormatter }}"> </div>
              </div> -->
              <!-- MemberShip Variants-->

              <div class="privatelesson-booking-summary__rate-row py-1 price-table-header d-flex flex-row"
                   *ngIf="slot && slot.MemberShipVariant">
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-6 col-6 d-flex ">
                  <span class="privatelesson-booking-summary__rate-label--main">{{'MemberShipVariant' | translate}}</span>
                </div>
                <!-- <div class="privatelesson-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
                  <span
                    class="privatelesson-booking-summary__rate-label--main">{{this.partyService.sessionBookingData.BookingSize}}</span>
                </div> -->
                <!-- <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-center">
                  <span
                    class="privatelesson-booking-summary__rate-label--main"></span>
                </div> -->
                <div class="privatelesson-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">
                  {{OperationCurrency}} {{
                                    slot.MemberShipVariant |
                                    currencyDecimalFormatter
                  }}
                </div>
                <div *ngIf="!perItemEditable" class="privatelesson-booking-summary__rate">
                  {{OperationCurrency}} {{
                                    slot.MemberShipVariant |
                                    currencyDecimalFormatter
                  }}
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
                     *ngIf="perItemEditable">
                  <input class="activity-description-bolder privatelesson-booking-summary__editable-input-currency"
                         [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>

                  <input ngxNumberFormat="#,###,###.##" [allowNegative]="true" (blur)="onBlur($event)"
                         class="activity-description-bolder privatelesson-booking-summary__editable-input" type='number' min="1"
                         step="0.10" [(ngModel)]="slot.membershipAmount" (keyup)="onNegotiate()"
                         [ngClass]="{'pointer-active': perItemEditable}"
                         value=" slot.membershipAmount | currencyDecimalFormatter">
                </div>
                <div class="privatelesson-booking-summary__rate p-0 col-lg-2 col-2 d-flex justify-content-end">
                  {{OperationCurrency}} {{
(slot.membershipAmount) |
                                    currencyDecimalFormatter
                  }}
                </div>
              </div>

              <!-- Session Charges -->
              <!-- Session Charges -->
              <div class="privatelesson-booking-summary__rate-row py-1 price-table-header d-flex flex-row"
                   *ngIf="slot.TotalAmount">
                <div class="privatelesson-booking-summary__rate-label d-flex  p-0 col-lg-6 col-6">
                  <div class="activity-description-bolder">{{'TotalSessionCharges' | translate}}</div>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end text-align-right">
                  <span class="privatelesson-booking-summary__rate-label--main ">
                    {{OperationCurrency}} {{
                    (slot.totalSessionRetailCharge || 0) | currencyDecimalFormatter
                    }}
                  </span>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-center">
                  <div class="activity-description-bolder">  </div>
                </div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                  <div class="activity-description-bolder">
                    {{OperationCurrency}} {{
                  (slot.negotiatedTotalSessionCharge || 0) | currencyDecimalFormatter
                    }}
                  </div>
                </div>
                <div *ngIf="!sessionEditable" class="privatelesson-booking-summary__rate"></div>
                <div class="privatelesson-booking-summary__rate-label p-0 col-lg-2 col-3 d-flex justify-content-end"
                     *ngIf="sessionEditable">
                  <input class="activity-description-bolder privatelesson-booking-summary__editable-input-currency"
                         [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> <input matInput [(ngModel)]="slot.totalSessionCharge" (keydown)="test($event,slot)"
                                                                                                                        class="privatelesson-booking-summary__rate privatelesson-booking-summary__editable-input" type='text' min="1"
                                                                                                                        step="0.10" (keyup)="calculateValue(slot)" (keypress)=validateCharacters($event)
                                                                                                                        [ngClass]="{'pointer-active': sessionEditable}" ngxNumberFormat="#,###,###.##" [allowNegative]="true"
                                                                                                                        [value]="(slot.totalBaseRate || 0)">
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="min-rate-adj">
          <div class="min-rate-label">
            {{'MinRateAdjustment' | translate}}
          </div>
          <div class="min-rate-adj-val">
            <input ngxNumberFormat="#,###,###.##" [allowNegative]="true" class="privatelesson-booking-summary__editable-input pointer-active" type='text' min="1" step="0.01"
                   [(ngModel)]="ratePlan.MinRateAdjustment.Amount" (change)="calculatePaymentDetails()" />
                    </div>
        </div>
        <div class="row negotiation-label">
          <div class="col-lg-6 col-md-6">
            <span class="privatelesson-booking-summary__rate-label">
              {{
'totalValue' |
              translate
              }}
            </span>
          </div>
          <div class="col-lg-6 col-md-5  text-right">
            {{OperationCurrency}} {{
 activitiesAmount
            | currencyDecimalFormatter : '0.00'
            }}
          </div>
        </div>

        <div class="privatelesson-booking-summary__rate-row privatelesson-booking-summary__total  negotiation-label">
          <div class="privatelesson-booking-summary__rate-label">
            <span class="privatelesson-booking-summary__rate-label">{{'negotiateValue' | translate}}</span>
          </div>
          <div class="privatelesson-booking-summary__rate">
            {{OperationCurrency}} {{negotiatedDiscount | currencyDecimalFormatter: '0.00' }}
          </div>

        </div>


        <div class="privatelesson-booking-summary__rate-row privatelesson-booking-summary__total" *ngIf="totalDepositAmount && showDepositAmount">
          <div class="privatelesson-booking-summary__rate-label">
            <span class="privatelesson-booking-summary__rate-label">{{'totalDepositValue' | translate}}</span>
          </div>
          <div class="privatelesson-booking-summary__rate">
            {{OperationCurrency}} {{
totalDepositAmount |
            currencyDecimalFormatter
            }}
          </div>

        </div>

        <!-- <div class="privatelesson-booking-summary__rate-row privatelesson-booking-summary__total">
          <div class="privatelesson-booking-summary__rate-label">
            <span class="privatelesson-booking-summary__rate-label"> {{'findTotal' | translate}}</span>
          </div>
          <div class="privatelesson-booking-summary__rate">
            <span class="icon-information" *ngIf="negotiationReason && negotiationReason !== ''"
            matTooltipClass="tooltip-class" title="{{negotiationReason}}"></span>
            {{OperationCurrency}} {{(totalPayableAmount ? (totalPayableAmount | currencyDecimalFormatter) : '0.00') }}
            <span title="{{'negotiateAmountMessage' | translate}}" class="icon-ic_create_24px edit-rates privatelesson-booking-summary__disabled-icon" *ngIf="data?.isConfirmedReservation"></span>
            <span class="icon-ic_create_24px edit-rates" *ngIf="partyService.isNegotiationEnabled && !data?.isConfirmedReservation" (click)="editNegotiationAmount()"></span>
          </div>
        </div> -->


        <div *ngIf="!isTaxConfigured && !isRetailEnabledProperty">
          <div class="row negotiation-label">
            <div class="col-lg-6 col-md-6">
              <span class="privatelesson-booking-summary__rate-label"> {{'findTotal' | translate}}</span>
            </div>
            <div class="col-lg-6 col-md-5  text-right">
              <!-- <span class="icon-information" *ngIf="negotiationReason && negotiationReason !== ''"
                    matTooltipClass="tooltip-class" title="{{negotiationReason}}"></span> -->
              {{OperationCurrency}} {{(totalPayableAmount || totalAddOnAmount ? ((totalPayableAmount || 0) +(totalAddOnAmount || 0) + ((totalServiceCharge || 0) + (ratePlan.TotalAddonServiceChargeAmount || 0)) | currencyDecimalFormatter) : '0.00') }}
              <span title="{{'negotiateAmountMessage' | translate}}" class="icon-ic_create_24px edit-rates disabled-icon" *ngIf="data?.disablePrevious"></span>
              <span class="icon-ic_create_24px edit-rates" *ngIf="partyService.isNegotiationEnabled && !data?.disablePrevious" (click)="editNegotiationAmount()"></span>
            </div>
          </div>
        </div>

        <div *ngIf="isTaxConfigured || isRetailEnabledProperty || isServiceConfigured">
          <div class="row negotiation-label">
            <div class="col-lg-6 col-md-6">
              <span class="privatelesson-booking-summary__rate-label">
                {{
                'subTotal' |
                translate
                }}
              </span>
            </div>
            <div class="col-lg-6 col-md-5  text-right">
              <!-- <span class="icon-information" *ngIf="negotiationReason && negotiationReason !== ''" matTooltipClass="tooltip-class"
                title="{{negotiationReason}}"></span> -->
              <span *ngIf="!subTotalEditable">
                {{OperationCurrency}} {{
                subtotal |
                negtiationRateFormatter : '0.00'
                }}
              </span>
              <input class="activity-description-bolder privatelesson-booking-summary__editable-input-currency"
                *ngIf="subTotalEditable" [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>
              <input class="privatelesson-booking-summary__editable-input" [ngClass]="{'pointer-active': subTotalEditable}"
                type='number' min="1" step="0.01" *ngIf="subTotalEditable" [(ngModel)]="subTotal"
                value="{{(totalPayableAmount || totalAddOnAmount ? ((totalPayableAmount || 0) + (totalAddOnAmount || 0)  | currencyDecimalFormatter) :  '0.00') }}">
              <span title="{{'negotiateAmountMessage' | translate}}" class="icon-ic_create_24px edit-rates disabled-icon"
                *ngIf="data?.disablePrevious"></span>
              <!-- <span class="icon-ic_create_24px edit-rates"                *ngIf="partyService.isNegotiationEnabled && !data?.disablePrevious" // take this for update                (click)="editNegotiationAmount()"></span> -->
            </div>
          </div>
          <div class="row negotiation-label" *ngIf="isServiceConfigured">
            <div class="col-lg-8 col-md-8 d-flex">
              <span class="">{{'serviceChargesWithPlural' | translate}}</span>
              <dynamic-form [config]="recalculateConfig" #form="dynamicForm"> </dynamic-form>
            </div>
            <div class="col-lg-4 col-md-4  text-right">
              {{OperationCurrency}}
              {{serviceCharge | negtiationRateFormatter  }}
            </div>
          </div>
          <div class="row negotiation-label" *ngIf="isServiceConfigured">
            <div class="col-lg-6 col-md-6">
              <span class="activity-booking-summary__rate-label">{{'serviceChargesTaxWithPlural' | translate}}</span>
            </div>
            <div class="col-lg-6 col-md-5  text-right">
              {{OperationCurrency}}
              {{((taxOnServiceCharge) | negtiationRateFormatter)  }}
            </div>
          </div>

        </div>

        <div class="row negotiation-label">
          <div class="col-lg-6 col-md-6">
            <span class="privatelesson-booking-summary__rate-label">{{'tax' | translate}}</span>
          </div>
          <div class="col-lg-6 col-md-5  text-right">
            {{OperationCurrency}} {{ tax | currencyDecimalFormatter: '0.00' }}
          </div>
        </div>

        <div class="row negotiation-label">
          <div class="col-lg-6 col-md-6">
            <span class="privatelesson-booking-summary__rate-label">
              {{
'findTotal' |
                translate
              }}
            </span>
          </div>
          <div class="col-lg-6 col-md-5  text-right">
            {{OperationCurrency}} {{
 grandTotal | currencyDecimalFormatter :
              '0.00'
            }}
          </div>
        </div>

          <div class="sub-label p-2">{{'negotiation' | translate}}</div>
          <dynamic-form [config]="rateNegotiateConfig" #negotiateReasonForm="dynamicForm">
          </dynamic-form>
          <div class="privatelesson-booking-summary__button-section pb-2">
            <activities-app-button [buttontype]="applyNegotiationBtn" (valueChange)='applyNegotiation()'></activities-app-button>
            <activities-app-button [buttontype]="cancelNegotiationBtn" (valueChange)='cancelNegotiation()'></activities-app-button>
          </div>

      </div>

      <div class="guest-data__party-notes" [ngClass]="{'disable':partyService.tryUndoNoShowReservation}">
        <dynamic-form [config]="config" #form="dynamicForm">
        </dynamic-form>
      </div>

    </div>
    <div class="privatelesson-booking-summary__guest-details" *ngIf="partyService.privateLessonBookingData && showGuestDetails"
         [hidden]="!enableGuestComponent">
      <app-guest-selection [selectedIndex]="selectedIndex"></app-guest-selection>
    </div>
  </div>

</ng-scrollbar>
<div [hidden]="cs.isIframeEnabled" *ngIf="ratePlan && (ratePlan.TotalRatePlan || ratePlan.TotalAddonAmount) && ratePlan.RatePlanCalculations">
  <app-common-booking-payments (setPayment)="setPartyPaymentType($event)" (setRetailTokenId)="setRetailCardTokenId($event)" [selectedIndex]="selectedIndex"></app-common-booking-payments>
</div>
