import { Pipe, PipeTransform } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject, Subscription, timer } from 'rxjs';
import { SnackBarType } from 'src/app/common/shared/shared/enums/enums';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { IcartDataService } from 'src/app/common/dataservices/icart.data.service';
import { Localization } from 'src/app/common/localization/localization';
import moment from 'moment';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'minuteconverter' })
export class minuteconverterPipe implements PipeTransform {

  captions: any;
  showAert: boolean;
  constructor(private dialog: MatDialog,
    private _commonUtils: CommonUtilities,
    private _icartDataService: IcartDataService,
    private _localization: Localization) {

    this.captions = this._localization.captions;
  }
  subject = new ReplaySubject<number>(2);
  _subscription: Subscription;
  transform(value: number, obj) {
    if (value == -1)
      return '';

    let totalSeconds = Number(value);

  //   // 👇️ get remainder of seconds
  //  const seconds = totalSeconds % 60;

  //   // 👇️ get number of full minutes
  //  const minutes = Math.floor(totalSeconds / 60);

  //   // 👇️ get number of days 
  //   const hours = Math.floor(minutes / 60);

  //   // 👇️ get number of hours 
  //   const days = Math.floor(hours / 24);



  //   function padTo2Digits(num) {
  //     return num.toString().padStart(2, '0');
  //   }

  //   //  format as MM:SS

  //   if(days)
  //   {
  //     let result = `${padTo2Digits(days)}d ${padTo2Digits(hours)}h ${padTo2Digits(minutes)}m ${padTo2Digits(seconds)}s`;
  //     return result;
  //   }else if(!days && hours){
  //     let result = `${padTo2Digits(hours)}h ${padTo2Digits(minutes)}m ${padTo2Digits(seconds)}s`;
  //     return result;
  //   }else if(!days && !hours)
  //   {
  //     let result = `${padTo2Digits(minutes)}m ${padTo2Digits(seconds)}s`;
  //     return result;
  //   }


   // Convert milliseconds to seconds

   // Calculate days, hours, minutes, and seconds
   const days = Math.floor(totalSeconds / (24 * 60 * 60));
   totalSeconds %= (24 * 60 * 60);

   const hours = Math.floor(totalSeconds / (60 * 60));
   totalSeconds %= (60 * 60);

   const minutes = Math.floor(totalSeconds / 60);
   const seconds = Math.floor(totalSeconds % 60);
   
       if(days)
    {
      let result = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      return result;
    }else if(!days && hours){
      let result = `${hours}h ${minutes}m ${seconds}s`;
      return result;
    }else if(!days && !hours)
    {
      let result = `${minutes}m ${seconds}s`;
      return result;
    }


       //alter
       const expireAlertMins = 2;
       if (totalSeconds <= expireAlertMins * 60) {
         if (totalSeconds == expireAlertMins * 60) {
           obj.isStatusred = true;
           const toastMsg = this._localization.replacePlaceholders(this.captions.toast_cartExpireWarning, ['confirmationId', 'min'], [obj.confirmationId, expireAlertMins]);
           this._commonUtils.showToastMessage(toastMsg, SnackBarType.Error);
   
         }
       } else {
         obj.isStatusred = false;
       }


    
   



   





  }
}