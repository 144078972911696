<section class="pop-container">
    <div class="pop-head border-top">
      <label class="inner-header pl-4 LW20">{{data.headername | uppercase}}</label>
      <i  aria-hidden="true" class="material-icons inner-close IC30 float-md-right cursPointer" (click)="onNoClick()">close</i>
    </div>
    <div class="pop-contents">
        <div class="vendor-container h-100" id="customVendorContainer">
            <app-retail-table [options]="tableoptions"></app-retail-table>
        </div>
    </div>
  </section>
  


