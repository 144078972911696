import { Component, Input, OnInit } from '@angular/core';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { SideNavService } from '@services/sidenav.service';
import { ISubscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-tables-view',
  templateUrl: './tables-view.component.html',
  styleUrls: ['./tables-view.component.scss']
})
export class TablesViewComponent implements OnInit {
  dashboardMessages: any = [];
  @Input() selectEnabled: boolean;
  config: FieldConfig[];
  clearSubscription: ISubscription;
  constructor(public sn: SideNavService) { }

  ngOnInit() {
    this.config = [{
      type: 'checkbox',
      name: 'language',
      placeholder: 'selectLanguage',
      class: 'login__language-selection',
      showErrorText: true
    }];
    this.dashboardMessages = [{ id: 1, name: 'John Smith', Text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', status: 'just now', viewed: true },
    { id: 2, name: 'Adam Smith', Text: 'and more recently with desktop publishing software ', status: '2 mins ago', viewed: true },
    { id: 2, name: 'Adam Smith', Text: 'and more recently with desktop publishing software ', status: '2 mins ago', viewed: false }];

    this.clearSubscription = this.sn.clearData.subscribe(val => {
      if (val === 1) {
        while (this.dashboardMessages.length > 0) {
          this.dashboardMessages.splice(this.dashboardMessages.length - 1, 1);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.clearSubscription) {
      this.clearSubscription.unsubscribe();
    }
  }

}
