import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { CoverTypeQuantityDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { GuestDataComponent } from '../guest-data/guest-data.component';
import _, { groupBy} from "lodash";
@Component({
  selector: 'app-special-meal',
  templateUrl: './special-meal.component.html',
  styleUrls: ['./special-meal.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class SpecialMealComponent implements OnInit, AfterViewInit, OnDestroy {
  config: FieldConfig[] = [];
  selectedIndex = 0;
  tabChangeSubscription: ISubscription;
  tabSubscription: ISubscription;
  filteredOptions: Observable<any>;
  specialMealEnabled = false;
  specialMealControl: UntypedFormControl;
  TotalAmount = 0;
  specialMealName: string;
  specialMeals: any[] = [];
  coverTypes: any = [];
  partySize: any = 0;
  totalCovers = 0;
  CoverTypeQuantity: CoverTypeQuantityDTO[] = [];
  restaurantCoverTypeValues: any = [];
  isReadOnly = false;
  isEditReservation = false;
  noShowFeeText: string;
  showNoFeeText = false;
  nullNoShowFeeAmount = false;
  noSpecialMealText: string;
  specialMealSelectedisPrepaid = false;
  count = 0;
  tempCoverTypes: any[] =[];
  subscriptions: Subscription = new Subscription();
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;

  constructor(private partyService: PartyService, private ps: PopupService, private ts: TranslateService,
              private cdf: ChangeDetectorRef, @Inject(COMPONENTINPUT) private data, private cs: CacheService) {
    this.specialMealControl = new UntypedFormControl();
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this.setSpecialMeals(false);
    }));
  }

  ngOnInit() {
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === SpecialMealComponent);
    this.partySize = this.partyService.reservationFormGroup.get('selectedSize');
    this.totalCovers = this.partySize.value;
    this.setSpecialMeals();
    this.specialMealEnabled = this.specialMeals.length > 0 ? true : false;
    this.noSpecialMealText = this.ts.instant('noSpecialMeals');
    this.getTotalAmount();
    this.partyService.selectedSpecialMealId = -1;
    if (this.data) {
      this.isEditReservation = true;
      this.totalCovers = 0;
      this.partyService.selectedSpecialMealId = this.data.SpecialMealId ? this.data.SpecialMealId : -1;
      this.partyService.reservationFormGroup.get('selectedSpecialMeal').setValue(this.data.SpecialMealId);
      this.getSpecialMealName(this.partyService.reservationFormGroup.get('selectedSpecialMeal').value);
      this.isSpecialMealDeleted(this.specialMealName);
      const selectedSpecialMeal = this.cs.settings.value.SpecialMeals.filter((meal) => meal.Id === this.data.SpecialMealId);
      let isCoverTypeQuantities = false;
      if (selectedSpecialMeal.length > 0 && this.data.CoverTypeQuantities) {
        isCoverTypeQuantities = selectedSpecialMeal[0].CoverTypePrices && selectedSpecialMeal[0].CoverTypePrices.length > 0;
      }
      if (this.cs.settings.value.General.SpecialMealsCoverTypesEnabled && this.data.SpecialMealId && this.data.SpecialMealId > 0 && isCoverTypeQuantities) {
        this.subscriptions.add(this.partyService.getPartyInfo(this.data.Id).subscribe((data) => {
          if (data && data.Payload) {
            const partyData = data.Payload;
            this.data.CoverTypeQuantities = partyData.CoverTypeQuantities;
            if (this.data.CoverTypeQuantities) {
              this.setCoverTypesForEditReservation(this.data.CoverTypeQuantities, selectedSpecialMeal[0].CoverTypePrices);
              this.partyService.reservationFormGroup.get('selectedCoverTypes').setValue(this.data.CoverTypeQuantities);
              if (this.specialMealEnabled) {
                if (this.coverTypes.length > 0) {
                  this.coverTypes.forEach(element => {
                    this.restaurantCoverTypeValues.forEach(e => {
                      if (e.Id === element.CoverTypeId) {
                        element.Price = e.Price;
                        this.totalCovers = this.totalCovers + element.Covers;
                      }
                    });
                  });
                }
              }
              this.getTotalAmount();
            }
          }
        }));
      }
      else{
        if (selectedSpecialMeal.length > 0) {
          if (selectedSpecialMeal[0].Price && selectedSpecialMeal[0].IsPrepaymentRequired) {
            this.partyService.selectedSpecialMealPrice.next(this.cs.settings.value.SpecialMeals.filter(meal => meal.Id == this.data.SpecialMealId)[0].Price * this.partySize.value);
          }
        }
      }
    }

    if (this.data) {
      this.specialMealControl.setValue(this.specialMealName);
      this.isReadOnly = true;
      this.showNoFeeText = !(this.data.SpecialMealAmount && this.data.SpecialMealAmount > 0);
      if (this.data.SpecialMealAmount && this.data.SpecialMealAmount > 0) {
        this.showNoFeeText = false;
      } else {
        this.showNoFeeText = (this.data.CreditCardId && this.data.CreditCardId > 0) ? true : false;
      }
    } else {
      this.filteredOptions = this.specialMealControl.valueChanges
        .pipe(
          startWith(''),
          map(value => value ? this._filter(value) : this.specialMeals)
        );
    }

    if (this.data) {
      this.ps.formValid$.next(
        {
          isFormValid: true,
          currentTab: this.selectedIndex
        } as IFormValidDetails);

    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.specialMeals.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
  }

  setCoverTypesForEditReservation(editData, coverTypePrices) {
    let tempCoverTypes = [];
    coverTypePrices.forEach(element => {
      let covertypeIndex = editData.findIndex(item => item.CoverTypeId == element.Id);

      if (covertypeIndex != -1) {
        tempCoverTypes.push({ Id: editData[covertypeIndex].Id, CoverTypeId: editData[covertypeIndex].CoverTypeId, CoverTypeName: editData[covertypeIndex].CoverTypeName, Covers: editData[covertypeIndex].Covers, Price: editData[covertypeIndex].Price });
      }
      else {
        tempCoverTypes.push({ Id: 0, CoverTypeId: element.Id, CoverTypeName: element.CoverTypeName, Covers: 0, Price: 0 });
      }
    });
    this.coverTypes = tempCoverTypes;
    this.tempCoverTypes = cloneDeep(tempCoverTypes);
  }

  setData(data) {
    let selectedMeal = this.specialMeals.filter(x => x.Id == data.value)[0];
    this.overrideValues(selectedMeal);
  }

  ngAfterViewInit() {
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === GuestDataComponent);
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedTime').valueChanges.subscribe(() => {
      this.count++;
      if (!this.data) {
        this.specialMealControl.setValue(this.ts.instant('None'));
        this.coverTypes = [];
        this.setSpecialMeals();
      }
    }));
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedArea').valueChanges.subscribe(() => this.count++));
    this.cdf.detectChanges();
  }

  setSpecialMeals(reset: boolean = true) {
    this.specialMeals = cloneDeep(this.cs.settings.value.SpecialMeals);
    const seatingArea = this.partyService.reservationFormGroup.get('selectedArea').value;
    const selectedSlot = this.partyService.reservationFormGroup.get('selectedTime').value;
    const selectedDate=this.partyService.reservationFormGroup.get('selectedDate').value;
    const mealsWithNoDate = this.specialMeals.filter(meal => !meal.StartDate && !meal.EndDate);
    let dateTime = "";
    if (this.data && this.data.ReservedFor) {
      dateTime = this.data.ReservedFor;
    }
    else if (selectedSlot && selectedSlot.DateTime) {
      dateTime = selectedSlot.DateTime;
    } 
    let mealWithSpecifiedDate=[];
    if(dateTime)
    {    
    mealWithSpecifiedDate = this.specialMeals.filter(meal => {
      if (meal.StartDate && meal.EndDate) {
        if (meal.ActivitySessions && meal.ActivitySessions.length > 0 && !meal.ShiftId) {
          let _date ;
          let _endDate = new Date(meal.EndDate) || new Date(selectedDate);
          let mealActivitySessions=meal.ActivitySessions.filter(x=>x.Dayofweek == new Date(selectedDate).getDay());
          if(mealActivitySessions.length > 0)
          {     
            let stimeold=mealActivitySessions[0].StartTime.split(':');
            let etimeold=mealActivitySessions[0].EndTime.split(':');
            let currentstime = new Date(new Date().setHours(Number(stimeold[0]), Number(stimeold[1]), Number(stimeold[2])));
            let currentetime = new Date(new Date().setHours(Number(etimeold[0]), Number(etimeold[1]), Number(etimeold[2])));
            let _sTime = new Date ('1/1/1999 ' + mealActivitySessions[0].StartTime);
            let _eTime = new Date ('1/1/1999 ' + mealActivitySessions[0].EndTime);
            let _dateTimeFormatted =  new Date(dateTime);
              let _cTime = new Date(new Date ('1/1/1999 ').setHours(_dateTimeFormatted.getHours(),_dateTimeFormatted.getMinutes(),_dateTimeFormatted.getSeconds()));
            if(currentstime.getTime() > currentetime.getTime())
            {             
              _endDate.setDate(_endDate.getDate() + 1);
              _date = (moment(_endDate).format('YYYY-MM-DDTHH:mm:ss')).toString();
              _eTime.setDate(_eTime.getDate() + 1);
              if(_dateTimeFormatted.getDate() > new Date(selectedDate).getDate()){
                _cTime.setDate(_cTime.getDate() + 1);
             }
            }
            let sDate = new Date(new Date(meal.StartDate));
            let eDate = _date ? _date : _endDate;
          let cDate=new Date(new Date(dateTime).setHours(0, 0, 0, 0));
          if ((mealActivitySessions.length > 0) && Utilities.datetimeBetween(sDate, eDate, cDate) && Utilities.datetimeInBetween(_sTime, _eTime, _cTime)) {           
              return true;
          }else{
            return false;
          }
          } else {

            return false;
          }
        } else {
          var timerange = Utilities.getMealShiftDate(this.cs.settings.value, meal.StartDate, meal.EndDate, meal.ShiftId);
          if (dateTime && timerange[0] && timerange[1]) {
            return Utilities.datetimeBetween(timerange[0], timerange[1], new Date(dateTime));
          }
        }
      }
    });
    }
    this.specialMeals = [...mealsWithNoDate, ...mealWithSpecifiedDate];
    this.specialMeals.push({ Id: null, Name: this.ts.instant('None') });
    if (this.partyService.reservationFormGroup.value.selectedSpecialMeal) {
      if (reset || !this.specialMeals.find(s => s.Id == this.partyService.reservationFormGroup.value.selectedSpecialMeal)) {
        this.specialMealControl.setValue(this.ts.instant('None'));
        this.overrideValues(this.specialMeals.filter(meal => meal.id === 0));
      }
    } else {
      this.specialMealControl.setValue(this.ts.instant('None'));
    }
  }

  isSpecialMealDeleted(MealName) {
    if (MealName === '') {
      this.noSpecialMealText = this.ts.instant('specialMealNA');
      this.specialMealEnabled = false;
      this.coverTypes = [];
    }
  }

  overrideValues(specialMealSelected) {
    this.coverTypes = [];
    let totAmount = 0;
    this.specialMealSelectedisPrepaid = specialMealSelected.IsPrepaymentRequired;
    if (specialMealSelected.CoverTypePrices && specialMealSelected.CoverTypePrices.length > 0) {
      specialMealSelected.CoverTypePrices.forEach(element => {
        this.coverTypes.push({ Id: element.Id, CoverTypeName: element.CoverTypeName, Covers: 0, Price: element.Price });
      });
      this.coverTypes[0].Covers = this.partySize.value;
      this.totalCovers = this.partySize.value;
      totAmount = this.coverTypes[0].Covers * this.coverTypes[0].Price;
    }
    this.showNoFeeText = (specialMealSelected.IsPrepaymentRequired || totAmount > 0) ? false : true;
    this.TotalAmount = totAmount;
    //this.partyService.selectedSpecialMealPrice.next(this.TotalAmount); as it is invoked again from the next line calculation of rateplan is wrong
    this.setSpecialMealData(specialMealSelected.Id);
    this.partyService.selectedSpecialMealId = specialMealSelected.Id;
    return this.coverTypes;
  }

  setSpecialMealData(specialMealId) {
    this.reservationFormChange(specialMealId);
    this.makeReservationFormDirty();
  }

  clearFunction() {
    this.specialMealControl.setValue('');
    this.coverTypes = [];
  }

  reservationFormChange(specialMealId) {
    var selectedMeal = this.specialMeals.filter(meal => meal.Id == specialMealId)[0];
    this.partyService.selectedSpecialMealId = specialMealId;
    if (this.partyService.reservationFormGroup.get('selectedSpecialMeal').value !== specialMealId) {
      this.partyService.reservationFormGroup.get('selectedSpecialMeal').setValue(specialMealId);
      this.partyService.selectedSpecialMealName.next(selectedMeal.Name);
    }
    if (selectedMeal.Price && selectedMeal.IsPrepaymentRequired) {
      this.partyService.selectedSpecialMealPrice.next(this.specialMeals.filter(meal => meal.Id == specialMealId)[0].Price * this.partySize.value);
    }
    else if (!specialMealId || (!selectedMeal.IsPrepaymentRequired) || (!selectedMeal.Price && selectedMeal.CoverTypePrices.length == 0)) {
      this.partyService.selectedSpecialMealPrice.next(0);
    }
    if(this.coverTypes?.length ) {
      this.setCoverTypesValues();
    }
   }

  makeReservationFormDirty() {
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
  }

  getSpecialMealName(mealId) {
    this.specialMealName = '';
    if (mealId === '' || mealId == null) {
      this.specialMealName = this.ts.instant('None');
    } else {
      this.cs.settings.value.SpecialMeals.forEach(element => {
        if (element.Id === mealId) {
          this.restaurantCoverTypeValues = element.CoverTypePrices;
          this.specialMealName = element.Name;
          this.specialMealSelectedisPrepaid = element.IsPrepaymentRequired;
        }
      });
    }
    if (this.specialMealName == '') {
      this.specialMealName = this.ts.instant('None');
    }
    this.partyService.selectedSpecialMealName.next(this.specialMealName);
  }

  setCoverTypesValues() {
    this.CoverTypeQuantity = [];
    if (this.isEditReservation && this.restaurantCoverTypeValues.length > 0) {
      this.coverTypes.forEach((element,index) => {
        this.CoverTypeQuantity.push(
          { Id: element.Id, CoverTypeId: element.CoverTypeId, CoverTypeName: element.CoverTypeName, Covers: element.Covers, Price: element.Price });
          this.tempCoverTypes[index].Price = element.Price;
      });
      this.partyService.reservationFormGroup.get('selectedCoverTypes').setValue(this.CoverTypeQuantity);

    } else {
      this.coverTypes.forEach(element => {
        this.CoverTypeQuantity.push({ Id: 0, CoverTypeId: element.Id, CoverTypeName: element.CoverTypeName, Covers: element.Covers, Price: element.Price });
      });
      this.partyService.reservationFormGroup.get('selectedCoverTypes').setValue(this.CoverTypeQuantity);
    }
    this.getTotalAmount();
  }

  formatCurrency(coverTypePricing) {
    return this.cs.settings.value.General.OperationCurrency + coverTypePricing.toFixed(2);
  }

  increaseCoverType(coverTypeSelected) {
    for (const cover of this.coverTypes) {
      if (!this.isEditReservation ? (cover.Id === coverTypeSelected.Id) : (coverTypeSelected.CoverTypeId === cover.CoverTypeId)) {
        if (this.totalCovers < this.partySize.value) {
          cover.Covers = Number(coverTypeSelected.Covers) + 1;
          this.totalCovers++;
        }
      }
    }
    this.getTotalAmount();
    this.setCoverTypesValues();
    const isCovervalueChange =  _.differenceWith(this.tempCoverTypes, this.coverTypes,  _.isEqual).length > 0;
    const contact = this.partyService.reservationFormGroup.get('selectedGuest').value;
    this.ps.formValid$.next(
      { isFormValid: (this.totalCovers == this.partySize.value && contact != null ? true : false ) &&  ((this.tempCoverTypes && this.tempCoverTypes.length) ? isCovervalueChange : true ) , currentTab: this.selectedIndex  } as IFormValidDetails);
    return this.coverTypes;
  }

  decreaseCoverType(coverTypeSelected) {
    for (const cover of this.coverTypes) {
      if ((!this.isEditReservation ? (cover.Id === coverTypeSelected.Id) : (coverTypeSelected.CoverTypeId === cover.CoverTypeId)) && cover.Covers > 0) {
        cover.Covers = Number(coverTypeSelected.Covers) - 1;
        this.totalCovers--;
      }
    }
    this.getTotalAmount();
    this.setCoverTypesValues();
    const isCovervalueChange =  _.differenceWith(this.tempCoverTypes, this.coverTypes,  _.isEqual).length > 0;
    const contact = this.partyService.reservationFormGroup.get('selectedGuest').value;
    this.ps.formValid$.next(
      { isFormValid: (this.totalCovers == this.partySize.value && contact != null  ? true : false) && ((this.tempCoverTypes && this.tempCoverTypes.length ? isCovervalueChange : true) ) , currentTab: this.selectedIndex } as IFormValidDetails);
    return this.coverTypes;
  }

  getTotalAmount() {
    this.TotalAmount = 0;
    for (const cover of this.coverTypes) {
      if (cover.Covers > 0) {
        this.TotalAmount = this.TotalAmount + (cover.Covers * cover.Price);
      }
    }
    this.TotalAmount > 0 ? this.showNoFeeText = false : this.showNoFeeText = true;
    this.specialMealSelectedisPrepaid ? this.showNoFeeText = false : this.showNoFeeText = true;
    this.specialMealSelectedisPrepaid ? this.partyService.selectedSpecialMealPrice.next(this.TotalAmount) : this.partyService.selectedSpecialMealPrice.next(0);
  }


  ngOnDestroy() {
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
