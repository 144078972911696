import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AppService } from '@app/app.service';
import { SlottingMode, buttonTypes } from '@app/shared/constants/commonenums';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SlotsSectionService } from './slots-section.service';
import { CacheService } from '@app/core/services/cache.service';
import { Subscription } from 'rxjs';
import { SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { PopupService } from '@popup-module/popup.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-slots',
  templateUrl: './slots.component.html',
  styleUrls: ['./slots.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SlotsComponent implements OnInit {
  @Output('refreshSlots') refreshSlots = new EventEmitter<boolean>();
  subscription: Subscription = new Subscription();
  settings: SettingsDTO;
  SlottingMode = SlottingMode;
  isFilterEnabled: boolean = false;
  editBtn: ButtonValue;
  cancelBtn: ButtonValue;
  partySizeConfig: FieldConfig[] =[];
  maxPartySizeAllowed = 51;
  partySizeArray: any[] = [];
  @ViewChild('filterMenuPopover') private filterMenuPopover: NgbPopover;
  @ViewChildren('addSlotPopup') private addSlotPopup: QueryList<NgbPopover>;
  slotDateTime: string;
  hideFilters: any[] = [];
  _MESSAGES = MESSAGES;

  constructor(public as: AppService, public ts: TranslateService,public slotService: SlotsSectionService,public cs:CacheService,public popupService: PopupService,public dashboardFunctions: DashboardFunctions,protected dialog: MatDialog) { 
    this.setFilter();
    this.subscription.add(this.cs.settings.subscribe(sett => {
      this.settings = sett;
    }))
    this.subscription.add(this.as.headerSelectedDate$.subscribe((date) => {
      this.resetFilter()
    }));
  }

  ngOnInit(): void {
    this.editBtn = {
      type: buttonTypes.actionPrimarySmall,
      label: this._MESSAGES.CONFIG.edit,
      customclass: this._MESSAGES.LABELS['slots-view__edit-btn']
    }
    this.cancelBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: this._MESSAGES.CONFIG.cancel,
      customclass: this._MESSAGES.LABELS['slots-view__cancel-btn'],
    }
  }

  ngAfterViewInit(): void {
    this.loadFilteConfig();
  }

  loadFilteConfig(): void {
    this.partySizeConfig = [{
      type: 'select',
      name: 'partySize',
      label: this.ts.instant(this._MESSAGES.CONFIG.partySize),
      options: this.getPartySize(),
      appearance: false,
      isTranslate: true,
      disableErrorStateMatcher: true,
      class: this._MESSAGES.LABELS['w-100'],
      value: this.slotService.slotFilters.partySize,
      cellClick: (event) => this.setPartySizeFilter(event)
    }];
    this.hideFilters = [
      {name: this._MESSAGES.CONFIG.hideFilled, isActive: this.slotService.slotFilters.hideFilled || false},
      {name: this._MESSAGES.CONFIG.hidePast, isActive: this.slotService.slotFilters.hidePast || false},
      {name: this._MESSAGES.CONFIG.hideDisabled, isActive: this.slotService.slotFilters.hideDisabled || false}];
  }

  getPartySize(): any[] {
    this.partySizeArray = [];
    for (let i = 1; i <= this.maxPartySizeAllowed; i++) {
      this.partySizeArray.push({ id: i, value: i.toString() });
    }
    this.partySizeArray[this.maxPartySizeAllowed - 1].value = this.partySizeArray[this.maxPartySizeAllowed - 1].value + '+';
    return this.partySizeArray;
  }

  refreshSlotsData(): void {
    this.slotService.selectedSlotCount = 0;
    this.slotService.slotsActions$.next({selectedSlots: this.slotService.selectedSlotCount});
    this.refreshSlots.emit(true);
  }

  enableDisableSlot(): void {
    this.slotService.isEditEnabled = !this.slotService.isEditEnabled;
    this.slotService.slotTimes = [];
    this.slotService.selectedSlotCount = 0;
    this.slotService.slotsActions$.next({selectedSlots: this.slotService.selectedSlotCount});
  }

  changeEvent(index: number): void {
    this.hideFilters[index].isActive = !this.hideFilters[index].isActive;
    switch (index) {
      case 0:
        this.slotService.slotFilters.hideFilled = this.hideFilters[0].isActive;
        break;
      case 1:
        this.slotService.slotFilters.hidePast = this.hideFilters[1].isActive;
        break;
      case 2:
        this.slotService.slotFilters.hideDisabled = this.hideFilters[2].isActive;
        break;
    }
    this.setFilter();
  }

  setPartySizeFilter(event: any): void {
    this.slotService.slotFilters.partySize = event.value;
    this.setFilter();
  }

  setFilter(): void {
    this.isFilterEnabled = false
    if (this.slotService.slotFilters.hideDisabled || this.slotService.slotFilters.hidePast || this.slotService.slotFilters.hideFilled || this.slotService.slotFilters.partySize > 0) {
      this.isFilterEnabled = true;
    }
    this.slotService.filterSlots(this.slotService.slotFilters);
  }

  resetFilter(): void {
    this.slotService.slotFilters = { partySize: 0, hideFilled: false, hidePast: false, hideDisabled: false };
    this.loadFilteConfig();
    this.setFilter();
  }

  closeFilter(): void {
    this.filterMenuPopover.close();
  }

  openSlotPopup(dateTime: string): void {
    this.slotDateTime = dateTime;
    this.addSlotPopup.forEach((addPopover, index) => {
      if (addPopover) {
        addPopover.close();
      }
      if(addPopover.popoverClass.includes(dateTime)){
      addPopover.open();
      }
    });
  }

  closeSlotPopover(): void {
    this.addSlotPopup.forEach((addPopover, index) => {
      if (addPopover) {
        addPopover.close();
      }
    });
  }

}

const MESSAGES = {
  LABELS: {
    "slots-view__edit-btn": "slots-view__edit-btn",
    "slots-view__cancel-btn": "slots-view__cancel-btn",
    "slots-view__enable-btn": "slots-view__enable-btn",
    "slots-view__disable-btn": "slots-view__disable-btn",
    "status-switch": "status-switch",
    "w-100": "w-100",
    "small": "small",
    "active": "active",
    "notifyParent": "notifyParent",
    "large": "large"
  },
  CONFIG: {
    "filterText": "filterText",
    "filterOnText": "filterOnText",
    "filterOffText": "filterOffText",
    "noSlotsAvailable": "noSlotsAvailable",
    "addToStandby": "addToStandby",
    "addSlots": "addSlots",
    "parties": "parties",
    "covers": "covers",
    "resetFilter": "resetFilter",
    "edit": "edit",
    "cancel":"cancel",
    "enable": "enable",
    "disable": "disable",
    "WebReservations": "WebReservations",
    "partySize": "partySize",
    "hideFilled": "hideFilled",
    "hidePast": "hidePast",
    "hideDisabled": "hideDisabled",
    "ok": "ok",
    "confirmWebReserveDiableText": "confirmWebReserveDiableText",
    "onText": "onText",
    "offText": "offText",
    "deleteTitle": "deleteTitle",
    "auditlog": "auditlog"
  }
}
