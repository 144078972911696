import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";
import { NgScrollbarModule } from "ngx-scrollbar";
import { FormsModule } from "@angular/forms";
import { PopupModule } from '@app/popup-module/popup.module';
import { SharedModule } from '@app/shared/shared.module';
import { WidgetComponent } from './widget.component';
import { MaterialModule } from '@app/material-module';
import { TimelineModule } from '@app/timeline/timeline.module';
import { LayoutModule } from '@app/layout/layout.module';
import { ActivitiesTimelineModule } from '@app/activities-timeline/activities-timeline.module';
import { TablesModule } from '@app/tables/tables.module';




@NgModule({
  declarations: [WidgetComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgScrollbarModule,
    TranslateModule,
    FormsModule,
    MaterialModule,
    PopupModule,   
    TimelineModule,
    LayoutModule,
    SharedModule,
    ActivitiesTimelineModule  
  ],
  exports:[WidgetComponent],
  entryComponents: [WidgetComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class WidgetModule { }
