import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "../../shared/service/http-call.service";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { OutletTerminalUIMapper, OutletAPIModel } from "../../retail.modals";
import { BaseResponse } from "../../shared/business/shared.modals";
import { Host } from "../../shared/globalsContant";
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';

@Injectable()
export class RetailOutletTerminalDataService {

  constructor(private http: HttpServiceCall, private utils: RetailUtilities, private localization: RetailLocalization, private propertyInfo: RetailPropertyInformation) { }

  public async getStoreTerminals(): Promise<OutletTerminalUIMapper[]> {
    let result: any = await this.InvokeOutletTerminalConfigurationCalls<any>(Host.retailManagement, "GetOutletTerminals", HttpMethod.Get, undefined, { }) || [];
    let outlets: OutletAPIModel[] = await this.getAllOutlets();
    let taxConfigs: OutletTerminalUIMapper[] = []
    result.forEach(element => {
        let outlet = outlets.filter(x => x.subPropertyID == element.outletId)[0];
        taxConfigs.push({
            id : element.id,
            outletId: element.outletId,
            outletName: outlet && outlet.subPropertyName ? outlet.subPropertyName : '',
            storeId: element.storeId,
            isActive: element.isActive,
            terminalId: element.terminalId,
            terminalName: element.terminalName,
            retailUserId: element.retailUserId,
            retailUserPassword: element.retailUserPassword,
            rRetailJWT: element.rRetailJWT
        })
    });
      return taxConfigs;
  }

    public async getAllOutlets(): Promise<OutletAPIModel[]> {
        try {
            return await this.InvokeOutletTerminalConfigurationCalls<OutletAPIModel[]>(Host.retailManagement, "GetOutletsByProperty", HttpMethod.Get, undefined, {}) || [];
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

  public async getActiveOutlets(): Promise<OutletAPIModel[]> {
    let result: OutletAPIModel[] = await this.InvokeOutletTerminalConfigurationCalls<OutletAPIModel[]>(Host.retailManagement, "GetOutletsByProperty", HttpMethod.Get, undefined, { }) || [];
    result = result.filter(x => x.isActive);
    return result;
  }

  public async createOutletTerminalConfiguration(body: OutletTerminalUIMapper): Promise<boolean> {
      let result = await this.InvokeOutletTerminalConfigurationCalls<number>(Host.retailManagement, "CreateOutletTerminal", HttpMethod.Post, body);
      return result > 0;
  }

  public async updateOutletTerminalConfiguration(body: OutletTerminalUIMapper): Promise<boolean> {
      let result = await this.InvokeOutletTerminalConfigurationCalls<boolean>(Host.retailManagement, "UpdateOutletTerminal", HttpMethod.Put, body, { });
      return result || false;
  }

    public async deleteOutletTerminalConfiguration(id: number): Promise<boolean> {
        try {
            return await this.InvokeOutletTerminalConfigurationCalls<boolean>(Host.retailManagement, "DeleteOutletTerminal", HttpMethod.Delete, undefined, { id: id });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async isTerminalInUse(outletId: number, terminalId: number): Promise<boolean> {
        try {
            return await this.InvokeOutletTerminalConfigurationCalls<boolean>(Host.retailManagement, "CheckIsTerminalInUse", HttpMethod.Get, undefined, { outletId: outletId, terminalId: terminalId });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

  private showError(errorCode: number) {
      let errMsg = this.localization.getError(errorCode);
  }


  private async InvokeOutletTerminalConfigurationCalls<T>(host: Host, callDesc: string, callType: HttpMethod, body?: OutletTerminalUIMapper, uRIParams?: any): Promise<T> {

      try {
          let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
              callDesc: callDesc,
              host: host,
              method: callType,
              body: body,
              uriParams: uRIParams
          });

          return response.result;
      }
      catch (ex) {
          if(ex.error && !ex.error.successStatus){
            this.showError(ex.error.errorCode)
          }
      }
  }


}
