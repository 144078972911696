<div class="dialog-container">
    <div mat-dialog-title class="dialog-container__header">
      <span class="dialog-container__header--title">{{data.title}}</span>
      <span class="dialog-container__header--close icon-Cancel" (click)="close()"></span>
    </div>
    <div class="dialog-container__content">
      <ng-container #vc></ng-container>
    </div>
    <mat-dialog-actions class="dialog-container__actions">
      <div>
        <button [disabled]="!enableSave" mat-flat-button color="primary" [ngClass]="{'whitecolor': enableSave}"class="actions__save" appDisableDoubleClick (click)="save(data.actionType)"
        >{{data.update}}</button>
        <button mat-button class="actions__cancel" appDisableDoubleClick (click)="close()">{{data.cancel}}</button>
      </div>
    </mat-dialog-actions>
  </div>