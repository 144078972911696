import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import * as _ from "lodash";
import { CommonVariablesService, Commission, SelectedProducts, ServiceChargeGratuityEnum, Gratuity, ServiceCharge } from '../../shared/service/common-variables.service';
import { BaseResponse, CommissionTemplate, CommissionTemplateSplit, ShopItem } from '../../shared/business/shared.modals';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import * as GlobalConst from '../../shared/globalsContant';
import { StaffTransactionDetail, ApplyCommissionServGrat, AssignCommissionStaffArrayUIModel } from '../shop.modals';
import { AssignCommissionService, StaffInfo } from './assign-commission-popup.service';
import { RetailDataAwaiters } from '../../shared/events/awaiters/retail.data.awaiters';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { AlertType } from '../../shared/shared.modal';
import { ButtonType } from 'src/app/common/enums/shared-enums';

@Component({
  selector: 'app-assign-commission-popup',
  templateUrl: './assign-commission-popup.component.html',
  styleUrls: ['./assign-commission-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssignCommissionPopupComponent implements OnInit {
  pageEdited: boolean = false;
  selectedstaffArr: any = [];
  GS_AddedStaff: any = [{ 'belongto': 1, 'NumofStaffAdded': [], 'selectedstaffArr': [] }, { 'belongto': 2, 'NumofStaffAdded': [], 'selectedstaffArr': [], 'value': 0, 'isPercentage': false }];
  commissionOptions: any;
  defaultCommission: string;
  staffArray: AssignCommissionStaffArrayUIModel[] = [];
  staffLevel: any;
  totalStaffSelected: number;
  splitOptions: any;
  selectedCommission: any = '';
  gratuityOptions: any;
  selectedGratuity: any = {};
  itemValues: ItemValue[] = [];
  itemName: string;
  selectedPrecent: any = [];
  customValue: any = [{ id: 1, value: 'Precentage %' }]
  selectedValue: any = [{ id: 1, value: 'Precentage %' }]
  presetPercentage: any = [];
  percentValue: any = '0';
  customValueplaceholder: string;
  selectedStaff: any = [];
  requiredStaff: Number = 0;
  gratuityStaff: any = [{ 'belongto': 1, 'gratuityStaffs': [] }, { 'belongto': 2, 'gratuityStaffs': [] }]; //gratuityStaff: number = 0;//'Jannedt Daily'; 1- Service Gratuity 2 - service Charges
  gratuityStaffName: string[] = []; 
  chosenGratuity: any = [];
  chosenServiceCharge: any = [];
  chosenCommission: any = [];
  ispercentValueDisabled: boolean = false;
  saveValues: ApplyCommissionServGrat;
  splitTemplate: CommissionTemplate[] = []
  selectedTemplate: CommissionTemplate = null;
  splitLevels: CommissionTemplateSplit[] = [];
  selectedSplitLevels: any[] = [];
  isduplicateLevelSelected: boolean = false;
  editCommissionData: Commission[] = [];
  AllShopItems: ShopItem[] = [];
  product: any;
  isCommissionAllowed: boolean;
  ApplyCommissionAccess: boolean = true;
  GratuityAccess: boolean = true;
  originalAutoGratuityPercentage: any;
  originalAutoServiceChargePercentage: any;
  gratuityStaffList: any[] = [];
  serviceStaffList: any[] = [];
  gratuityServiceChargeMaxlength: number;
  focusIndex = -1;
  isGratuityOnNet: boolean = false;
  isServiceChargeOnNet:boolean = false;
  isVatEnabled:boolean = false;
  grossValue: number = 0;
  defaultTherapistId: string;
  isCommissionRequired: boolean = false;

  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;
  selectedCommissionStaff: string[] = [];
  previousTherapistSelected: string[] = [];
  previousSelectedItemTherapist: string[] = [];
  IsEdit: boolean = false;
  selectedSplit = 10
  MaxCommissionStaffToBeSelected: number = 1;
  allTherapistList: any[] = []; 
  disableServiceChargeGratuity = false;
  selectedRetailItem = [];
  captions: any;
  quantity: number;
  floatLabel: string;
  PreSelectTherapistsOnSettle: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpServiceCall,
    public dialogRef: MatDialogRef<AssignCommissionPopupComponent>,
    public localization: RetailLocalization, public _shopservice: CommonVariablesService, private utils: RetailUtilities,
    private BPoint: BreakPointAccess, private popupService: AssignCommissionService, private el: ElementRef, private propertyInfo: RetailPropertyInformation) {
      this.floatLabel = this.localization.setFloatLabel;
  }

  HandleTabClick(tab: MatTab, tabGroup: MatTabGroup, id: number): boolean {
    if (id === 0 && !this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyCommission], false) ||
      (id === 0 && this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.EditCommission], false))) {
      this.ApplyCommissionAccess = false;
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }
    this.GratuityAccess = true;
    const gratuityAccess = this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyGratuity], false);
    const serviceChargeAccess = this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyServiceCharge], false);
    if (id === 1 && (!this.IsEdit && !gratuityAccess) &&
      (!this.IsEdit && !serviceChargeAccess)) {
      const gratuityBreakPoint = this.localization.captions.breakpoint[GlobalConst.RetailBreakPoint.ApplyGratuity];
      const serviceBreakPoint = this.localization.captions.breakpoint[GlobalConst.RetailBreakPoint.ApplyServiceCharge];
      const message = `${this.localization.captions.common.BreakPointAccessDeniedMsg}
             <br><br>${this.localization.captions.common.Breakpoint}: ${gratuityBreakPoint} & ${serviceBreakPoint}`;
      this.utils.ShowErrorMessage(this.localization.captions.common.AccessDenied, message, GlobalConst.ButtonType.Ok);
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }
    return true && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
  }

  async ngOnInit() {
    this.captions = this.localization.captions.shop;
    this.saveValues = {
      commission: [],
      Gratuity: [{ Id: 0, TransactionDetailId: 0, TherapistId: 0, Amount: 0, Percentage: 0, PercentageId: 0, PercOrAmount: 0, gratuity: 0 }],
      ServiceCharge: [{ Id: 0, TransactionDetailId: 0, TherapistId: 0, Amount: 0, Percentage: 0, PercentageId: 0, PercOrAmount: 0, ServiceCharge: 0 }]
    };
    this.previousTherapistSelected = this.data.data[2] ? this.data.data[2] : [];
    this.previousSelectedItemTherapist = this.data.data[3] ? this.data.data[3] : [];
    this.previousTherapistSelected = this.previousTherapistSelected.filter((v, i, a) => a.indexOf(v) === i);
    this.defaultTherapistId = this.previousTherapistSelected[0];
    this.IsEdit = this.data.isEdit ? this.data.data[4] : false;
    this.PreSelectTherapistsOnSettle = this.data.data[5];
    this.isVatEnabled = this.propertyInfo.IsVATEnabled;

    this.tabGroup._handleClick = this.HandleTabClick.bind(this); 
    let ItemData = this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail : this.data.data[0];
    let itemId = this.data.isEdit ? ItemData[0].ItemId : ItemData.id;
    this.isCommissionAllowed = this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.isCommissionable : this.data.data[0].isCommissionable;
    if(this.data.from == "retailItem"){
      this.isCommissionRequired = this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.isCommissionRequired : false;
    } else {
      this.isCommissionRequired = this.data.data[0][0] ? this.data.data[0][0].isCommissionRequired : false;
    }
    this.commissionOptions = [{ key: 'CC', value: this.localization.captions.shop.ClerkForCommission }, { key: 'SC', value: this.localization.captions.shop.SplitCommission }];
    this.defaultCommission = this.commissionOptions[0].key;
    this.totalStaffSelected = 0;
    this.gratuityOptions = [{ id: 1, value: this.localization.captions.shop.Gratuity }, { id: 2, value: this.localization.captions.shop.ServiceCharges }];
    this.customValueplaceholder = `${this.localization.captions.shop.Value} (%)`;
    this.selectedGratuity = _.cloneDeep(this.gratuityOptions[0]);
    if (!this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyGratuity], false) &&
      !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyServiceCharge], false)) {
      this.selectedGratuity = [];
    } else if (!this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyGratuity], false) ||
      !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.EditGratuity], false)) {
      this.selectedGratuity = _.cloneDeep(this.gratuityOptions[1]);
    }
    this.presetPercentage = [{ id: 1, value: 5 },
    { id: 2, value: 10 },
    { id: 3, value: 15 },
    { id: 4, value: 20 },
    { id: 4, value: 25 },
    { id: 4, value: 30 }];

    if (!this.data.isEdit) {
      let itemValue = this.data.ProductPrice * this.data.Quantity;
      this.grossValue = itemValue;
      this.quantity = this.data.Quantity;
      let autoGratuityPercentage = this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.gratuity : this.data.data[0].gratuity;
      let autoServiceChargePercentage = this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.serviceCharge : this.data.data[0].serviceCharge;
      this.itemValues = [{
        item: this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.itemDescription : this.data.data[0].itemDescription,
        type: this.selectedGratuity.value,
        itemValue: itemValue - (this.data.data[0].retailItemDetail.Discount ? this.data.data[0].retailItemDetail.Discount : 0),
        gratuity: this.getDefaultAutoGratuity(autoGratuityPercentage, itemValue),
        serviceCharge: this.getDefaultAutoServiceCharge(autoServiceChargePercentage, itemValue),
        isReturn: this.data.isReturn,
        netAmount: this.data.netAmount,
        netUnitPriceWithoutDiscount: this.data.netUnitPriceWithoutDiscount,
        netPriceWithoutDiscount:  this.data.netUnitPriceWithoutDiscount  * this.data.Quantity,
        itemDisplayValue: itemValue
      }];

      this.saveValues.Gratuity = this.itemValues[0].gratuity;
      this.saveValues.ServiceCharge = this.itemValues[0].serviceCharge;
    }

    this.customValue = [{ id: 1, value: this.localization.captions.shop.Percentage }, 
           { id: 2, value: this.localization.captions.shop.Amount + ' ' + this.localization.currencySymbol }]


    if (this.data.isEdit) {
      const editDataArr = this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail : this.data.data[0];
      if (editDataArr && editDataArr.length > 0) {      
        const editData = editDataArr[0];
        this.grossValue = editData.ProductPrice * this.data.data[1];
        this.quantity = Number(this.data.data[1]);
        this.itemValues = [
          {
            item: editData.ProductName,
            type: this.gratuityOptions[0].value,
            itemValue: editData.ProductPrice * this.data.data[1] - editData.Discount,
            gratuity: editData.Gratuity ? editData.Gratuity : [],
            serviceCharge: editData.ServiceCharge ? editData.ServiceCharge : [],
            isReturn: editData.isReturn,
            netAmount: this.data.netAmount,
            netUnitPriceWithoutDiscount: this.data.netUnitPriceWithoutDiscount,
            netPriceWithoutDiscount: this.data.netUnitPriceWithoutDiscount * this.data.data[1],
            itemDisplayValue:  this.grossValue
          }];

        if (editData.Commission && editData.Commission.length > 0) {
          this.editCommissionData = editData.Commission;
          if (editData.Commission.some(r => r.TemplateId && r.TemplateId > 0)) {
            this.MaxCommissionStaffToBeSelected = this.data.MaxTherapistTobeSelected ? this.data.MaxTherapistTobeSelected : 1;
            this.defaultCommission = 'SC';
            this.splitTemplate = this._shopservice.commissionTemplate;
            this.selectedTemplate = this._shopservice.commissionTemplate.filter(r => { return r.id == editData.Commission[0].TemplateId })[0];
            this.updateCommission();
          }
          else {
            this.MaxCommissionStaffToBeSelected = this.data.MaxTherapistTobeSelected ? this.data.MaxTherapistTobeSelected : 1;
          }
          this.selectedCommissionStaff = editData.Commission[0] ? editData.Commission.map(r => r.id ? r.id : 0) : [];
          this.saveValues.commission = editData.Commission;
        }

        if (editData.ServiceCharge) {
          this.saveValues.ServiceCharge = _.cloneDeep(editData.ServiceCharge);
        }

        if (editData.Gratuity) {
          this.saveValues.Gratuity = _.cloneDeep(editData.Gratuity);
        }
      }
      this.SetSelectedValues(this.selectedGratuity.id);
    }

    // Setting item values as 'zero' if discount is applied more than actual price
    if (!this.itemValues[0].isReturn && this.itemValues[0].itemValue < 0) {
      this.itemValues[0].itemValue = 0;
      this.itemValues[0].netAmount = 0;
    }
    this.disableServiceChargeGratuity = this.itemValues[0].isReturn;
    this.GetStaffDetailsForItem()
    this.GratuityAccess = true;
    this.itemName = this.itemValues[0].item;
    if (!this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyCommission]) ||
      (this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.EditCommission]))) {
      this.ApplyCommissionAccess = false;
    }

    const response = await this._shopservice.getRetailItemsDetailedInfoByIds([itemId]);
    if (response) { this.selectedRetailItem = response; }
    setTimeout(() => {
      const selectedInput = this.el.nativeElement.querySelector('#therapist-commission-' + 0);
       selectedInput?.focus(); 
    }, 500); 

    
    this.isServiceChargeOnNet = await this._shopservice.IsServiceChargeOnNet();
    this.isGratuityOnNet = await this._shopservice.IsGratuityOnNet();
  }

  getDefaultAutoServiceCharge(autoPercentage: number, itemValue: number): ServiceCharge[] {
    let serviceCharge: ServiceCharge[] = [];
    serviceCharge.push({
      Id: 0,
      TransactionDetailId: 0,
      TherapistId: 0,
      Amount: 0,
      Percentage: autoPercentage,
      PercentageId: 0,
      PercOrAmount: 1,
      ServiceCharge: Number((autoPercentage / 100 * itemValue).customToFixed())
    });
    return serviceCharge
  }

  getDefaultAutoGratuity(autoPercentage: number, itemValue: number): Gratuity[] {
    let gratuity: Gratuity[] = [];
    gratuity.push({
      Id: 0,
      TransactionDetailId: 0,
      TherapistId: 0,
      Amount: 0,
      Percentage: autoPercentage,
      PercentageId: 0,
      PercOrAmount: 1,
      gratuity: Number((autoPercentage / 100 * itemValue).customToFixed())
    });
    return gratuity
  }

  async GetStaffDetailsForItem() {
    let ItemData = this.data.data[0];
    let itemId = this.data.isEdit ? ItemData[0].ItemId : ItemData.id;
    if (!itemId || itemId == 0) {
      return;
    }
    let staffInfo: StaffInfo = await this.popupService.GetEligibleStaffs(itemId);
    if (staffInfo) {
      this.allTherapistList = staffInfo.AllStaffs;
      this.BuildCommissionableStaffInfo(staffInfo.CommissionableStaff);
      this.BuildServiceGratuityStaffInfo(staffInfo);
    }
  }

  BuildCommissionableStaffInfo(staffList: AssignCommissionStaffArrayUIModel[]) {
    for (let staff of staffList) {
      let splitlevel = (this.data.isEdit && this.defaultCommission == 'SC') ? this.GetSplitIdByStaffId(staff.id) : '';
      this.staffArray.push({
        id: staff.id,
        staffId: staff.staffId,
        firstName: staff.firstName,
        lastName: staff.lastName,
        image: '',
        staffLevel: splitlevel,
        isStaffSelected: false,
        staffType: staff.staffType
      });
    }
    this.getTherapistImages(this.staffArray);

  }

  FormStaffInfo(staff: AssignCommissionStaffArrayUIModel, splitLevel) {
    return {
      id: staff.id,
      staffId: staff.staffId,
      firstName: staff.firstName,
      lastName: staff.lastName,
      image: '',
      staffLevel: splitLevel,
      isStaffSelected: this.selectedCommissionStaff.some(r => r == staff.id),
      staffType: staff.staffType
    } as AssignCommissionStaffArrayUIModel;
  }

  BuildServiceGratuityStaffInfo(staffInfo: StaffInfo) { 
    for (let therapist of staffInfo.GratuityStaff) {
      var splitlevel = (this.data.isEdit && this.defaultCommission == 'SC') ? this.GetSplitIdByStaffId(therapist.id) : '';
      this.gratuityStaffList.push(this.FormStaffInfo(therapist, splitlevel));
    }
    for (let therapist of staffInfo.ServiceChargeStaff) {
      var splitlevel = (this.data.isEdit && this.defaultCommission == 'SC') ? this.GetSplitIdByStaffId(therapist.id) : '';
      this.serviceStaffList.push(this.FormStaffInfo(therapist, splitlevel));
    }

    this.GS_AddedStaff[0].NumofStaffAdded.push({ 'index': 1, 'staffArray': this.gratuityStaffList, 'selectedIndexStaff': '', 'value': 0, 'isPercentage': true });
    this.GS_AddedStaff[1].NumofStaffAdded.push({ 'index': 1, 'staffArray': this.serviceStaffList, 'selectedIndexStaff': '', 'value': 0, 'isPercentage': true });

    if (this.data.isEdit) {
      this.BindGratuitySCOnEdit();
    } else {
      if (this.itemValues[0].gratuity[0].gratuity > 0) {
        this.GS_AddedStaff[0].NumofStaffAdded[0].value = this.localization.localizeCurrency(this.itemValues[0].gratuity[0].Percentage, false);
      }
      if (this.itemValues[0].serviceCharge[0].ServiceCharge > 0) {
        this.GS_AddedStaff[1].NumofStaffAdded[0].value = this.localization.localizeCurrency(this.itemValues[0].serviceCharge[0].Percentage, false);
      }
    }

    /*Select already selectd therapist for the item */
    if (this.data.isEdit || this.previousSelectedItemTherapist && this.previousSelectedItemTherapist.length > 0 && this.previousTherapistSelected && this.previousTherapistSelected.length <= 1) {
      this.selectMultipleStaff(this.previousSelectedItemTherapist);
    }
    else {
      /*  If a staff is already associated with one or more items previously added to the transaction,
      that staff members name will be displayed here, and their name will also be ticked
      */
      let selectTherapist = this.previousTherapistSelected && this.previousTherapistSelected.length == 1 ? this.previousTherapistSelected : null;
      selectTherapist ? this.selectMultipleStaff(selectTherapist) : this.selectStaff(null);
    } 
    if(this.staffArray.length>1){
    this.staffArray.sort((a,b)=>(a.isStaffSelected>b.isStaffSelected)? -1 : 1);
  }
  }

  // Close the pop-up
  onCancelClick = () => {
    this.dialogRef.close();
  }

  getAllItems() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetShopItems",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }



  //Save the commission details
  save() {
    if(this.tabGroup.selectedIndex == 1){
      if(this.isCommissionRequired && this.staffArray.length == 0) {
        this.utils.showAlert(this.captions.err_noCommision, AlertType.Warning, ButtonType.Ok).afterClosed().subscribe(() => {
          this.tabGroup.selectedIndex =0;
        });;
        return;
      } else if(this.isCommissionRequired && this.staffArray.length>0 && this.selectedStaff.length == 0) {
        this.utils.showAlert(this.captions.err_selectTherapistUser, AlertType.Warning, ButtonType.Ok).afterClosed().subscribe(() => {
          this.tabGroup.selectedIndex =0;
        });;
        return;
      }
    }
    let ItemData = this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail : this.data.data[0];
    let itemId = this.data.isEdit ? ItemData[0].ItemId : ItemData.id;
    let selitem = this.selectedRetailItem;
    let itemStaffs: StaffTransactionDetail[];
    this.SetGratuityServiceChargeValue(this.itemValues[0].isReturn);
    if (this.saveValues.commission && this.saveValues.commission.length > 0)
      itemStaffs = this.saveValues.commission.map(o => { return { StaffId: o.StaffId, StaffType: o.StaffType } });
    if (this._shopservice.selectedProducts.some(product => product.ItemId == itemId) && this.data.isEdit) {
      let item = ItemData[0];
      item.Gratuity = this.saveValues.Gratuity ? _.cloneDeep(this.saveValues.Gratuity) : null;
      item.ServiceCharge = this.saveValues.ServiceCharge ? _.cloneDeep(this.saveValues.ServiceCharge) : null;

      if (this.data.isEdit && this.saveValues.commission && this.saveValues.commission.length > 0) {
        item.Commission = [];
      }

      if (this.saveValues.commission.length > 0) {
        item.Commission = item.Commission.concat(_.cloneDeep(this.saveValues.commission))
        item.StaffTransactionDetail = itemStaffs
      }
      if (this._shopservice.selectedRetainedRetailProducts.length > 0) { // Return with ticket
        this._shopservice.selectedExchangeRetailProducts = this._shopservice.selectedProducts;
      }
    }
    else {
      let isReturnMode = this.data.isReturn;
      let salesPriceChecker = (this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.salesPrice : this.data.data[0].salesPrice);
      let productPriceChecker = (this.data.ProductPrice ? this.data.ProductPrice : salesPriceChecker);
      let itemValue = this.data.data[4] ? this.data.data[4] : productPriceChecker;

      var itemDiscount = !isReturnMode ? this._shopservice.SelectedItemDiscount.find(x => x.itemId == itemId) : null;
      var packageGroupId = this.data.data[7];
      if (packageGroupId > 0 && this._shopservice.ItemEligibleForDiscount[selitem[0].retailItemDetail.category] && !isReturnMode) {
        let parentPackageItem = this._shopservice.selectedProducts.find(x => x.PackageGroupId == packageGroupId && !x.isPackagedItem && x.isGroupingKey);
        itemDiscount = this._shopservice.SelectedItemDiscount.find(x => x.itemId == parentPackageItem.ItemId);
      }
      let discount: number = 0;
      if (itemDiscount && !isReturnMode) {
        discount = itemDiscount.discountPercentage > 0 ? this.utils.RoundOff2DecimalPlaces(this.data.data[1] * itemValue * itemDiscount.discountPercentage / 100) : itemDiscount.discountAmount;
      }

      let item: SelectedProducts = {
        ExternalPOSItemId: this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.externalPOSId : this.data.data[0].externalPOSId,
        ItemDescription: this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.itemDescription : this.data.data[0].itemDescription,
        ItemId: itemId,
        ItemType: this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.itemType : this.data.data[0].itemType,
        retailItemType: this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.itemType : this.data.data[0].itemType,
        SalesPrice: this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.salesPrice : this.data.data[0].salesPrice,
        ServiceId: 0,
        ProductName: this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.itemDescription : this.data.data[0].itemDescription,
        ProductPrice: isReturnMode ? itemValue * -1 : itemValue,
        Noofitems: this.data.data[1],
        Discount: discount,
        DiscountTypeId: itemDiscount ? itemDiscount.discountId : 0,
        DiscountPercentage: (itemDiscount && itemDiscount.discountPercentage > 0) ? itemDiscount.discountPercentage : 0,
        Gratuity: this.saveValues.Gratuity ? _.cloneDeep(this.saveValues.Gratuity) : null,
        ServiceCharge: this.saveValues.ServiceCharge ? _.cloneDeep(this.saveValues.ServiceCharge) : null,
        Commission: _.cloneDeep(this.saveValues.commission),
        isCommissionable: selitem[0].retailItemDetail.isCommissionable,
        isCommissionRequired: selitem[0].retailItemDetail.isCommissionRequired,
        isGroupingKey: selitem[0].retailItemDetail.isGroupingKey,
        isPackagedItem: this.data.data[5] ? this.data.data[5] : false,
        PackageItemId: this.data.data[6] ? this.data.data[6] : 0,
        MultiPack: selitem[0].retailItemDetail.isMultiPack,
        ClientMultiPackId: 0,
        PackageGroupId: this.data.data[7] > 0 ? this.data.data[7] : 0,
        isOpenPricedItem: true,
        StaffTransactionDetail: itemStaffs,
        scaledUnits: this.data.scaledUnit,
        unitOfMeasureId: this.data.unitOfMeasureId,
        uom: this.data.uom,
        isReturn: isReturnMode,
        category: selitem[0].retailItemDetail.category,
        LineNumber: this._shopservice.GetNextLineNumber(),
        itemComments: this.data.itemComments,
        isRequestName: this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.isRequestName : this.data.data[0].isRequestName,
        costPrice: this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.costPrice : this.data.data[0].costPrice,
        marginPercentage : this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.costPrice : this.data.data[0].costPrice,
        allowEarn: this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail.allowEarn : this.data.data[0].allowEarn,
        discountComments: itemDiscount ? itemDiscount.discountComment : '',
        discountReason: itemDiscount ? itemDiscount.discountReason : 0,
        GroupingParentId: this.data.data[8] ? this.data.data[8] : 0,
        GroupingUniqueIdentifier: this.data.data[9] ? this.data.data[9] : null,
        GroupingItemDescription: this.data.data[10]  ? this.data.data[10] : ''
      };
      this._shopservice.selectedProducts.push(item);
      this._shopservice.selectedProducts =[...this._shopservice.selectedProducts];


    }
    this.dialogRef.close("SAVE");
  }

  SetGratuityServiceChargeValue(isReturn: boolean) {
    for (let gratuity of this.saveValues.Gratuity) {
      gratuity.gratuity = this.GetItemValue(gratuity.gratuity, isReturn);
    }
    for (let serviceCharge of this.saveValues.ServiceCharge) {
      serviceCharge.ServiceCharge = this.GetItemValue(serviceCharge.ServiceCharge, isReturn);
    }
  }

  GetItemValue(value: number, isReturn: boolean) {
    if (value) {
      value = Number(value);
    }
    if (isReturn && value > 0) {
      value *= -1;
    }
    return value;
  }

  async staffSelected(e, wholedata, index) {

    let alreadySelectedStaffId = '';
    if (wholedata.selectedIndexStaff) {
      alreadySelectedStaffId = wholedata.selectedIndexStaff.id;

    }
    let SG_selectedstaffArr = this.GS_AddedStaff.find(x => x.belongto == this.selectedGratuity.id).selectedstaffArr;
    let GSstaffArray = this.selectedGratuity.id == 1 ? this.gratuityStaffList : this.serviceStaffList;
    if (wholedata.selectedIndexStaff) {
      let removeindex = SG_selectedstaffArr.findIndex(x => x.id == wholedata.selectedIndexStaff.id);
      SG_selectedstaffArr.splice(removeindex, 1);
      SG_selectedstaffArr.push(e.value);
    } else {
      SG_selectedstaffArr.push(e.value);
    }

    let SG_stafftobeadded = this.GS_AddedStaff.find(x => x.belongto == this.selectedGratuity.id).NumofStaffAdded;
    SG_stafftobeadded.forEach(x => {
      if (x.index == wholedata.index) {
        x['selectedIndexStaff'] = e.value;
      } else {
        let differenceinSelectedarr = SG_selectedstaffArr.filter(SA => SA.id != x.selectedIndexStaff.id); // SA = Selected Array
        x.staffArray = _.difference(GSstaffArray, differenceinSelectedarr);
      }
    });

    var updatedStaffList = this.GS_AddedStaff.find(r => r.belongto == this.selectedGratuity.id).NumofStaffAdded;
    await this.updateSaveValues(updatedStaffList, this.selectedGratuity.id);
    const selectedInput = this.el.nativeElement.querySelector('#therapist-commission-' + index);
    selectedInput.focus();
  }

  selectedTabChange(event){
    let setindex;
    if(event.index==1){
      if(this.data.isEdit){
          setindex = 0;
      } else{
        setindex = this.GS_AddedStaff[1].NumofStaffAdded.length-1;
      }
      const selectedInput = this.el.nativeElement.querySelector('#therapist-commission-' + setindex);
      selectedInput.focus();
    }
  }

  async updateSaveValues(updatedStaffList, valueType: ServiceChargeGratuityEnum) {
    let itemPriceValue = await this.GetItemValueForCharges(this.itemValues[0], this.selectedGratuity.id);
    if (valueType == ServiceChargeGratuityEnum.Gratuity) {
      this.saveValues.Gratuity = [];
      for (let data of updatedStaffList) {
        let deLocalizedValue = this.localization.currencyToSQLFormat(data.value);
        this.saveValues.Gratuity.push({
          Id: data.selectedIndexStaff.id,
          TherapistId: data.selectedIndexStaff.staffId,
          StaffType: data.selectedIndexStaff.staffType,
          Percentage: data.isPercentage ? deLocalizedValue : 0,
          gratuity: data.isPercentage ? Number(((deLocalizedValue * itemPriceValue) / 100).customToFixed()) : deLocalizedValue,
          Amount: data.isPercentage ? Number(((deLocalizedValue * itemPriceValue) / 100).customToFixed()) : deLocalizedValue,
          PercOrAmount: data.isPercentage ? 1 : 2,
          TransactionDetailId: 0,
          PercentageId: 0
        });
      }
      this.itemValues[0].gratuity = this.saveValues.Gratuity;
    } else if (valueType == ServiceChargeGratuityEnum.ServiceCharge) {
      this.saveValues.ServiceCharge = [];
      for (let data of updatedStaffList) {
        let deLocalizedValue = this.localization.currencyToSQLFormat(data.value);
        this.saveValues.ServiceCharge.push({
          Id: data.selectedIndexStaff.id,
          TherapistId: data.selectedIndexStaff.staffId,
          StaffType: data.selectedIndexStaff.staffType,
          Percentage: data.isPercentage ? deLocalizedValue : 0,
          ServiceCharge: data.isPercentage ? Number(((deLocalizedValue * itemPriceValue) / 100).customToFixed()) : deLocalizedValue,
          Amount: data.isPercentage ? Number(((deLocalizedValue * itemPriceValue) / 100).customToFixed()) : deLocalizedValue,
          PercOrAmount: data.isPercentage ? 1 : 2,
          TransactionDetailId: 0,
          PercentageId: 0
        });
      }
      this.itemValues[0].serviceCharge = this.saveValues.ServiceCharge;
    }
  }
 
  private async GetItemValueForCharges(itemValue: ItemValue, chargeType: ServiceChargeGratuityEnum): Promise<number> {
    try {
      const price: number = itemValue.netUnitPriceWithoutDiscount && itemValue.netUnitPriceWithoutDiscount * this.quantity  || 0;
      return await this._shopservice.GetItemValueForCharges(itemValue.itemValue, itemValue.netAmount, price, chargeType);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
 

  /**
   * Selects the staff from the array
   * @param staff - staff object
   */
  selectStaff(staff: any) {
    let commissionStaffArr: any[] = this.staffArray && this.staffArray.length > 0 ? this.staffArray : [];
    if (!commissionStaffArr || commissionStaffArr.length == 0) return;
    if (staff && this.defaultCommission == 'CC') {
      this.SelectStaffForCommission(staff);
    }
    else if (staff && this.defaultCommission == 'SC') {
      this.SelectStaffForSplitCommission(staff);
    }
    /* Selected StaffId List */
    this.selectedStaff = commissionStaffArr.filter(x => x.isStaffSelected)
    let selectedStaffId: number[] = this.selectedStaff.map(x => x.id);
    /* Selected Staff Names */
    this.gratuityStaffName = this.selectedStaff.map(a => `${a.firstName} ${a.lastName}`);

    /* Staff Names Selected in other transactions */
    let products: any[] = this._shopservice.selectedProducts.map(x => { return { LineNumber: x.LineNumber, StaffID: x.Commission ? x.Commission.map(t => t.id) : [] } });
    var thisRef = this;

    if (this.IsEdit) {
      this.allTherapistList.forEach(x => {
        products.forEach(item => {
          if (item.LineNumber != thisRef.data.data[0].map(a => a.LineNumber) && item.StaffID.includes(x.id) && !selectedStaffId.includes(x.id)) {
            if (!thisRef.gratuityStaffName.find(name => name.includes(`${x.firstName} ${x.lastName}`)))
              thisRef.gratuityStaffName.push(`${x.firstName} ${x.lastName}`);
          }
        })
      });
    }
    else {
      this.allTherapistList.forEach(x => {
        products.forEach(item => {
          if (item.StaffID.includes(x.id) && !selectedStaffId.includes(x.id)) {
            if (!thisRef.gratuityStaffName.find(name => name.includes(`${x.firstName} ${x.lastName}`)))
              thisRef.gratuityStaffName.push(`${x.firstName} ${x.lastName}`);
          }
        })
      });
    }


    /* Commission */
    this.saveValues.commission = [];
    this.selectedStaff.forEach(x => {
      this.saveValues.commission.push({
        id: x.id,
        StaffId: x.staffId,
        StaffType: x.staffType,
        TemplateId: this.defaultCommission == 'SC' ? this.selectedTemplate?.id : 0,
        SplitLevel: x.staffLevel,
        SplitPercentage: this.GetSplitPercentbyId(x.staffLevel),
        Quantity: Number(this.data.data[1])
      });
    }, this);
  }

  SelectStaffForCommission(staff: any) {
    let selectedCount: number = this.staffArray.filter(x => x.isStaffSelected).length;
    if (selectedCount === this.MaxCommissionStaffToBeSelected && this.MaxCommissionStaffToBeSelected === 1) {
      let value = staff.isStaffSelected;
      this.staffArray.forEach(x => x.isStaffSelected = false);
      staff.isStaffSelected = !value;
      return;
    }
    if (selectedCount === this.MaxCommissionStaffToBeSelected && this.MaxCommissionStaffToBeSelected > 1) {
      if (staff.isStaffSelected) staff.isStaffSelected = !staff.isStaffSelected;
      return;
    }
    staff.isStaffSelected = !staff.isStaffSelected;
  }

  SelectStaffForSplitCommission(staff: any) {
    staff.isStaffSelected = !staff.isStaffSelected;
  }

  selectMultipleStaff(staffId: string[]) {
    if (staffId && staffId.length > 0 && this.staffArray && this.staffArray.length > 0)
      this.staffArray.forEach(x => { if (staffId.includes(x.id)) { this.selectStaff(x) } });
  }

  SelectDefaultTherapist() {
    let defaultStaff = this.staffArray.find(staff => staff.id === this.defaultTherapistId);
    this.editModeDirty.commissionDirty = true;
    if (defaultStaff && this.previousTherapistSelected.length === 1) {
      this.selectStaff(defaultStaff)
    }
    if (this.previousTherapistSelected.length > 1 && this.MaxCommissionStaffToBeSelected === this.previousTherapistSelected.length) {
      this.selectMultipleStaff(this.previousTherapistSelected);
    }

  }

  async chooseGratuity(event, type, value) {
    switch (type) {
      case 'gratuity':
        if (event && value.id === 2 && !this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyServiceCharge]) ||
          (event && value.id === 2 && this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.EditServiceCharge]))) {
          return;
        }
        if (value.id === 2 && !this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyServiceCharge], false) ||
          (value.id === 2 && this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.EditServiceCharge], false))) {
          return;
        }
        if (event && value.id === 1 && !this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyGratuity]) ||
          (event && value.id === 1 && this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.EditGratuity]))) {
          this.GratuityAccess = false;
          return;
        }
        if (value.id === 1 && !this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyGratuity], false) ||
          (value.id === 1 && this.IsEdit && !this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.EditGratuity], false))) {
          this.GratuityAccess = false;
          return;
        }
        this.GratuityAccess = true;
        this.itemValues[0].type = value.value;
        this.SetSelectedValues(value.id);
        this.selectedGratuity = _.cloneDeep(value);

        let setindex;
        setTimeout(() => {
          if(this.selectedGratuity.id==1){
            setindex = this.GS_AddedStaff[0].NumofStaffAdded.length-1;
          } else{
            setindex = this.GS_AddedStaff[1].NumofStaffAdded.length-1;
          }
          if (this.data.isEdit) {
            this.el.nativeElement.querySelector('#therapist-commission-' + 0).focus();
          } else {
            this.el.nativeElement.querySelector('#therapist-commission-' + setindex).focus();
          }
        } ,1);
       
        break;

      case 'preset':      
        this.selectedPrecent = _.cloneDeep(value);
        let itemPriceValue = 0;
        if (this.selectedGratuity.id == 1) {
          itemPriceValue = await this.GetItemValueForCharges(this.itemValues[0], ServiceChargeGratuityEnum.Gratuity);
          let selectedtherapistArray = this.GS_AddedStaff.find(x => x.belongto == this.selectedGratuity.id).selectedstaffArr;
          selectedtherapistArray.value = value;
          if (this.focusIndex > -1) {
            this.GS_AddedStaff.find(x => x.belongto == this.selectedGratuity.id).NumofStaffAdded[this.focusIndex].value = this.localization.localizeCurrency(value.value, false);
            this.CalculateAmount(ServiceChargeGratuityEnum.Gratuity);
          }
        }
        else if (this.selectedGratuity.id == 2) {
          itemPriceValue = await this.GetItemValueForCharges(this.itemValues[0], ServiceChargeGratuityEnum.ServiceCharge);
          if (this.focusIndex > -1) {
            this.GS_AddedStaff.find(x => x.belongto == this.selectedGratuity.id).NumofStaffAdded[this.focusIndex].value = this.localization.localizeCurrency(value.value, false);
            this.CalculateAmount(ServiceChargeGratuityEnum.ServiceCharge);
          }
        }
        this.selectedValue = [];
        this.percentValue = '0';
        this.ispercentValueDisabled = true;
        
        break;
      case 'customvalue':
 
        break;
    }
  }

  commissionChange() {
    this.selectedTemplate = null;
    this.ResetSelectedStaff();
    if (this.defaultCommission == 'SC' && this.splitTemplate.length == 0) {
      this.InvokeServiceCall('GetCommissionTemplate', GlobalConst.Host.commission, HttpMethod.Get, { productId: this._shopservice.ProductId });
    }
    this.gratuityStaffName = (this.staffArray.filter(staff => staff.isStaffSelected).map(x => x.firstName + ' ' + x.lastName));
  }

  ResetSelectedStaff() {
    _.forEach(this.staffArray, (staff, index) => {
        this.selectedStaff = [];
        staff.staffLevel = '';
        staff.isStaffSelected = false;
    });  
    this.saveValues.commission = [];
    if(this.defaultTherapistId){
      this.SelectDefaultTherapist();
    }
  }
  editModeDirty: {
    commissionDirty: boolean,
    serviceChargeDirty: boolean,
    gratuityDirty: boolean
  } = {
      commissionDirty: false,
      gratuityDirty: false,
      serviceChargeDirty: false
    }
  isAddDisabled() { 
 
    if (!this.data.isEdit) {
      this.editModeDirty.commissionDirty = true;
      this.editModeDirty.serviceChargeDirty = true;
      this.editModeDirty.gratuityDirty = true;
    }

    if (this.tabGroup.selectedIndex == 0) {
      if (this.defaultCommission == 'SC') {
        return !this.editModeDirty.commissionDirty || this.selectedTemplate == null || this.selectedStaff.length != this.requiredStaff || this.selectedStaff.some(r => r.staffLevel == '') || this.isduplicateLevelSelected;
      } else if (this.defaultCommission == 'CC') {
        return !this.editModeDirty.commissionDirty || this.selectedStaff.length == 0;
      }
    } else if (this.tabGroup.selectedIndex == 1) {
      if (this.selectedGratuity.id == 1) {
        return !this.editModeDirty.gratuityDirty || !this.isDataValidForSave(this.selectedGratuity.id)
      }
      else {
        return !this.editModeDirty.serviceChargeDirty || !this.isDataValidForSave(this.selectedGratuity.id)
      }
    }

  }

  isDataValidForSave(valueType: ServiceChargeGratuityEnum): boolean {
    let isValidForSave = true;
    if (valueType == ServiceChargeGratuityEnum.Gratuity) {
      if (this.saveValues.Gratuity && this.saveValues.Gratuity.length == 1 && (this.saveValues.Gratuity[0].TherapistId == 0 || this.saveValues.Gratuity[0].TherapistId == undefined || this.saveValues.Gratuity[0].gratuity == 0 || this.saveValues.Gratuity[0].gratuity == undefined)) {
        return false;
      }
      for (let grat of this.saveValues.Gratuity) {
        if (!(grat.TherapistId == 0 && grat.gratuity == 0) && (grat.TherapistId == 0 || grat.TherapistId == undefined || grat.gratuity == 0 || grat.gratuity == undefined)) {
          isValidForSave = false;
          break;
        }
      }
    }
    else if (valueType == ServiceChargeGratuityEnum.ServiceCharge) {
      if (this.saveValues.ServiceCharge && this.saveValues.ServiceCharge.length == 1 && (this.saveValues.ServiceCharge[0].TherapistId == 0 || this.saveValues.ServiceCharge[0].TherapistId == undefined || this.saveValues.ServiceCharge[0].ServiceCharge == 0 || this.saveValues.ServiceCharge[0].ServiceCharge == undefined)) {
        return false;
      }
      for (let sc of this.saveValues.ServiceCharge) {
        if (!(sc.TherapistId == 0 && sc.ServiceCharge == 0) && (sc.TherapistId == 0 || sc.TherapistId == undefined|| sc.ServiceCharge == 0 || sc.ServiceCharge == undefined)) {
          isValidForSave = false;
          break;
        }
      }
    }
    return isValidForSave;
  }

  ShowPrice(actualValue: number, isReturnedItem: boolean): string {

    const serviceChargeType = 'Service Charges';
    const gratuity = 'Gratuity';
    if(this.itemValues[0].type.toLowerCase() == serviceChargeType.toLowerCase())
    {
      actualValue = this._shopservice.isExcludeDiscOnServiceCharge ? actualValue : this.itemValues[0].itemValue; //this.itemValues[0].itemValue includes discount
    }
    else if(this.itemValues[0].type.toLowerCase() == gratuity.toLowerCase())
    {
      actualValue = this._shopservice.isExcludeDiscOnGratuity ? actualValue : this.itemValues[0].itemValue; //this.itemValues[0].itemValue includes discount
    }

    if (isReturnedItem && actualValue < 0) {
      actualValue *= -1;
    }
    let price = this.localization.localizeCurrency(actualValue, false);
    if (isReturnedItem && actualValue != 0) {
      price = `(${price})`;
    }
    return price;
  }

  displayGratScPrice(valueType: ServiceChargeGratuityEnum) {
    let price = 0;
    if (valueType === ServiceChargeGratuityEnum.Gratuity) {
      for (const grat of this.itemValues[0].gratuity) {
        const deLocalizedValue = grat.Percentage;
        if (deLocalizedValue && deLocalizedValue > 0) {
          if (this.propertyInfo.UseRetailInterface) {
            const itemPriceValue = this._shopservice.isExcludeDiscOnGratuity ? this.itemValues[0].netAmount : this.itemValues[0].itemValue;
            price = itemPriceValue ? Number(((deLocalizedValue * itemPriceValue) / 100).customToFixed()) : deLocalizedValue;
          } else {
           let lineItemValueWithDiscount =  this.isVatEnabled && this.isGratuityOnNet ? this.itemValues[0].netAmount : this.itemValues[0].itemValue;
           let lineItemValueWithoutDiscount = this.isVatEnabled && this.isGratuityOnNet  ?  this.itemValues[0].netPriceWithoutDiscount : this.grossValue;
           const itemPriceValue = this._shopservice.isExcludeDiscOnGratuity ? lineItemValueWithoutDiscount : lineItemValueWithDiscount;         
            price = itemPriceValue ? price + Number(((deLocalizedValue * itemPriceValue) / 100).customToFixed()) : deLocalizedValue;
          }
        } else {
          price = price + grat.gratuity;
        }
      }
    } else if (valueType === ServiceChargeGratuityEnum.ServiceCharge) {
      for (const sc of this.itemValues[0].serviceCharge) {
        const deLocalizedValue = sc.Percentage;
        if (deLocalizedValue && deLocalizedValue > 0) {
          if (this.propertyInfo.UseRetailInterface) {
            const itemPriceValue = this._shopservice.isExcludeDiscOnServiceCharge ? this.itemValues[0].netAmount :this.itemValues[0].itemValue;
            price = itemPriceValue ? Number(((deLocalizedValue * itemPriceValue) / 100).customToFixed()) : deLocalizedValue;
          } else {
            let lineItemValueWithDiscount =  this.isVatEnabled && this.isServiceChargeOnNet ? this.itemValues[0].netAmount : this.itemValues[0].itemValue;
            let lineItemValueWithoutDiscount =  this.isVatEnabled && this.isServiceChargeOnNet ?  this.itemValues[0].netPriceWithoutDiscount : this.grossValue;
            const itemPriceValue = this._shopservice.isExcludeDiscOnServiceCharge ? lineItemValueWithoutDiscount : lineItemValueWithDiscount;             
            price = itemPriceValue ? price + Number(((deLocalizedValue * itemPriceValue) / 100).customToFixed()) : deLocalizedValue;
          }
        } else {
          price = price + sc.ServiceCharge;
        }
      }
    }
    return this.localization.localizeCurrency(price, false);
  }

  PopupResult(result, extraparams) {
    this.staffArray.forEach(element => {
      if (element.id == extraparams[0]) {
        element.staffLevel = '';
      }
    });
  }

  SplitLevelChange(event, staffId) {
    let splitId = event.value;
    let selectedLevels = this.selectedStaff.filter(r => {
      return r.isStaffSelected && r.id != staffId
    }).map(r => r.staffLevel);
    this.isduplicateLevelSelected = selectedLevels.some(x => x == splitId);
    if (this.isduplicateLevelSelected) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.captions.shop.SplitLevelAlreadySelected, GlobalConst.ButtonType.Ok, this.PopupResult.bind(this), [staffId = staffId]);
    }
    else {
      this.selectedStaff.forEach(element => {
        if (element.id == staffId) {
          element.staffLevel = splitId;
        }
      });
 

      this.saveValues.commission.forEach(r => {
        if (r.id == staffId) {
          r.SplitLevel = splitId;
          r.SplitPercentage = this.GetSplitPercentbyId(splitId);
        }
      });
    }
  }

  GetSplitPercentbyId(splitId: number): number {
    let percent = this.splitLevels?.filter(r => {
      return r.id == splitId
    })[0]
    return percent ? percent.percentage : 0;
  }

  updateCommission() {

    this.splitLevels = this.selectedTemplate.splitDetail;
    this.requiredStaff = this.splitLevels.length;
    this.ResetSelectedStaff();
  }

  GetSplitIdByStaffId(staffId: string): number {
    let splitId: number = 0;
    splitId = this.editCommissionData.filter(r => { return r.id == staffId }).map(r => r.SplitLevel)[0];
    return splitId;
  }

  BindGratuitySCOnEdit() {
    let editDataArr = this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail : this.data.data[0];
    if (editDataArr && editDataArr.length > 0) {
      let selectedStaffIds = [];
      let editData = editDataArr[0];
      if (editData.Gratuity && editData.Gratuity.length > 0) {
        selectedStaffIds = this.saveValues.Gratuity.map(r => r.Id);
        let gs_newarr = this.gratuityStaffList.filter(x => selectedStaffIds.indexOf(x.id) != -1);
        this.GS_AddedStaff[0] = { 'belongto': 1, 'NumofStaffAdded': [], 'selectedstaffArr': gs_newarr };
        for (let x = 0; x < this.saveValues.Gratuity.length; x++) {
          let selectedStaff = gs_newarr.find(r => r.id == this.saveValues.Gratuity[x].Id);
          this.GS_AddedStaff[0]['NumofStaffAdded'].push(
            {
              'index': x,
              'staffArray': this.gratuityStaffList,
              'selectedIndexStaff': selectedStaff,
              'value': this.saveValues.Gratuity[x].Percentage > 0 ? this.localization.localizeCurrency(this.saveValues.Gratuity[x].Percentage, false) : this.localization.localizeCurrency(this.saveValues.Gratuity[x].gratuity, false),
              'isPercentage': this.saveValues.Gratuity[x].gratuity != 0 && this.saveValues.Gratuity[x].Percentage == 0 ? false : true
            });
        }
      } else if (this.PreSelectTherapistsOnSettle.length > 0) {
        selectedStaffIds = this.PreSelectTherapistsOnSettle;
        let gs_newarr = this.gratuityStaffList.filter(x => selectedStaffIds.indexOf(x.id) != -1);
        this.GS_AddedStaff[0] = { 'belongto': 1, 'NumofStaffAdded': [], 'selectedstaffArr': gs_newarr };
        for (let x = 0; x < selectedStaffIds.length; x++) {
          let selectedStaff = gs_newarr.find(r => r.id == selectedStaffIds[x]);
          this.GS_AddedStaff[0]['NumofStaffAdded'].push(
            {
              'index': x,
              'staffArray': this.gratuityStaffList,
              'selectedIndexStaff': selectedStaff,
              'value': this.localization.localizeCurrency(0),
              'isPercentage': true
            });
        }
      } else {
        this.setStaffDetails(selectedStaffIds, editData, 0, this.gratuityStaffList, 1);
      }
      if (editData.ServiceCharge && editData.ServiceCharge.length > 0) {
        selectedStaffIds = this.saveValues.ServiceCharge.map(r => r.Id);
        let newarr = this.serviceStaffList.filter(x => selectedStaffIds.indexOf(x.id) != -1);
        this.GS_AddedStaff[1] = { 'belongto': 2, 'NumofStaffAdded': [], 'selectedstaffArr': newarr };
        for (let x = 0; x < this.saveValues.ServiceCharge.length; x++) {
          let selectedStaffSc = newarr.find(r => r.id == this.saveValues.ServiceCharge[x].Id);
          this.GS_AddedStaff[1]['NumofStaffAdded'].push(
            {
              'index': x,
              'staffArray': this.serviceStaffList,
              'selectedIndexStaff': selectedStaffSc,
              'value': this.saveValues.ServiceCharge[x].Percentage > 0 ? this.localization.localizeCurrency(this.saveValues.ServiceCharge[x].Percentage, false) : this.localization.localizeCurrency(this.saveValues.ServiceCharge[x].ServiceCharge, false),
              'isPercentage': this.saveValues.ServiceCharge[x].ServiceCharge != 0 && this.saveValues.ServiceCharge[x].Percentage == 0 ? false : true
            });
        }
      } else if (this.PreSelectTherapistsOnSettle?.length > 0) {
        selectedStaffIds = this.PreSelectTherapistsOnSettle;
        let newarr = this.serviceStaffList.filter(x => selectedStaffIds.indexOf(x.id) != -1);
        this.GS_AddedStaff[1] = { 'belongto': 2, 'NumofStaffAdded': [], 'selectedstaffArr': newarr };
        for (let x = 0; x < selectedStaffIds.length; x++) {
          let selectedStaffSc = newarr.find(r => r.id == selectedStaffIds[x]);
          this.GS_AddedStaff[1]['NumofStaffAdded'].push(
            {
              'index': x,
              'staffArray': this.serviceStaffList,
              'selectedIndexStaff': selectedStaffSc,
              'value': this.localization.localizeCurrency(0),
              'isPercentage': true
            });
        }
      } else {
        this.setStaffDetails(selectedStaffIds, editData, 1, this.serviceStaffList, 2);
      }
    }
  }

  setStaffDetails(selectedStaffIds: any[],editData: any,index:number,staffList: any,belong:number){
    selectedStaffIds = editData.StaffTransactionDetail?.map(r => r.StaffId);
        let gs_newarr = (selectedStaffIds != undefined && selectedStaffIds.length > 0)?staffList.filter(x => selectedStaffIds.indexOf(x.staffId) != -1):[];
        if(gs_newarr.length > 0 && this.GS_AddedStaff != undefined && this.GS_AddedStaff.length>index)
        this.GS_AddedStaff[index] = { 'belongto': belong, 'NumofStaffAdded': [], 'selectedstaffArr': gs_newarr };
        for (let x = 0; x < gs_newarr.length ; x++) {
          let selectedStaff = gs_newarr[x];
          this.GS_AddedStaff[index]['NumofStaffAdded'].push(
            {
              'index': x,
              'staffArray': staffList,
              'selectedIndexStaff': selectedStaff,
              'value': this.localization.localizeCurrency(0, false),
              'isPercentage': true
            });
        }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetCommissionTemplate") {
      this.splitTemplate = result.result ? <any>result.result : [];
      this._shopservice.commissionTemplate = this.splitTemplate;
      if (this.data.isEdit) {
        let editDataArr = this.data.data[0].retailItemDetail ? this.data.data[0].retailItemDetail : this.data.data[0];
        let editData = editDataArr ? editDataArr[0] : []
        if (!editData || editData.length == 0) return;
        this.selectedTemplate = this._shopservice.commissionTemplate.filter(r => { return r.id == editData.Commission[0].TemplateId })[0];
        this.splitLevels = this.selectedTemplate?.splitDetail;
        for (const element of this.saveValues.commission) {
          if (element.SplitLevel > 0) {
            element.SplitPercentage = this.GetSplitPercentbyId(element.SplitLevel);
          }
        }
      }
    }
    else if (callDesc == "GetShopItems") {
      this.AllShopItems = result.result ? <any>result.result : [];
      this._shopservice.selectedProducts.forEach(x => {
        if (x.ServiceId > 0) {
          let item = this.AllShopItems.filter(a => { return a.id == x.ItemId });
          if (item && item.length > 0) {
            x.ExternalPOSItemId = item[0].retailItemDetail.externalPOSId;
            x.ItemDescription = item[0].retailItemDetail.itemDescription;
            x.ProductName = item[0].retailItemDetail.itemDescription;
          }
        }
      });
    }
  }
  
  mapImg(arr, imgArr) {
    return _.map(arr, x => {
      let therapistImg = _.find(imgArr, img => img.referenceId == x.staffId && img.referenceType == x.staffType);
      x['imgObj'] = therapistImg;
      return x;
    })
  }
  async getTherapistImages(staffDetails: AssignCommissionStaffArrayUIModel[]) {
    if (!staffDetails || staffDetails.length == 0) {
      return;
    }
    let therapistIds = staffDetails.filter(r => r.staffType == GlobalConst.THERAPIST).map(r => r.staffId);
    if (therapistIds && therapistIds.length > 0) {
      let images = await RetailDataAwaiters.getTherapistImage(therapistIds);
      this.staffArray = this.mapImg(this.staffArray, images);
    }
  }

  errorCallback() {
    console.error('error');
  }


  SetSelectedValues(selectedId) {
    if (selectedId == 1) {
      this.itemValues[0].gratuity = this.saveValues.Gratuity;
    }
    else {
      this.itemValues[0].serviceCharge = this.saveValues.ServiceCharge;
    }
  }

  InvokeServiceCall(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }
  addremove(type, staffdata, selectedCharges) {
    let SG_selectedstaffArr = this.GS_AddedStaff.find(x => x.belongto == this.selectedGratuity.id).selectedstaffArr;
    let index = staffdata.index;
    let indStaff = staffdata.staffArray;
    let alreadyselectedSatff = staffdata.selectedIndexStaff;
    this.focusIndex = -1;
    setTimeout(() => {
      const setindex = this.GS_AddedStaff[selectedCharges-1].NumofStaffAdded.length-1;
      const selectedInput = this.el.nativeElement.querySelector('#therapist-commission-' + setindex);
       selectedInput.focus(); 
    }, 1); 
    if (type == 'A') {
      let NewArr = _.difference(this.selectedGratuity.id == 1 ? this.gratuityStaffList : this.serviceStaffList, SG_selectedstaffArr);
      let SG_NumofStaffAdded = this.GS_AddedStaff.find(x => x.belongto == selectedCharges).NumofStaffAdded;
      SG_NumofStaffAdded.push({ 'index': index + 1, 'staffArray': NewArr, 'selectedIndexStaff': '', 'value': 0, isPercentage: true });
      this.focusIndex = index + 1;
    }
    if (type == 'R') {
      let SG_NumofStaffAdded = this.GS_AddedStaff.find(x => x.belongto == selectedCharges).NumofStaffAdded;
      let removeindex = SG_NumofStaffAdded.findIndex(x => x.index == index);
      SG_NumofStaffAdded.splice(removeindex, 1);
      if (alreadyselectedSatff) {
        this.GS_AddedStaff.find(x => x.belongto == this.selectedGratuity.id).selectedstaffArr = SG_selectedstaffArr.filter(x => { return x.id != alreadyselectedSatff.id });
        SG_NumofStaffAdded.filter(x => {
          x.staffArray.push(alreadyselectedSatff);
        })
      }
      this.updateSaveValues(SG_NumofStaffAdded, this.selectedGratuity.id);
      this.CalculateAmount(this.selectedGratuity.id);
    }
  }
  stopdefaultClick(e) {
    if (this.disableServiceChargeGratuity) {
      e.preventDefault();
    }
  }
 
  async CalculateAmount(type: ServiceChargeGratuityEnum) {
    let typeData = this.GS_AddedStaff.find(r => r.belongto == type);
    await this.updateSaveValues(typeData.NumofStaffAdded, type);

    if (type == ServiceChargeGratuityEnum.Gratuity) {
      this.editModeDirty.gratuityDirty = true;
    } else if (type == ServiceChargeGratuityEnum.ServiceCharge) {
      this.editModeDirty.serviceChargeDirty = true;
    }
    this.isAddDisabled();
  }

  setFocusIndex(index: number) {
    this.focusIndex = index;
  }
  togglebuttonVal(type: ServiceChargeGratuityEnum, index: number) {
    this.focusIndex = index;
    let typeRow = this.GS_AddedStaff.find(r => r.belongto == type);
    if (typeRow) {
      let rowValue = typeRow.NumofStaffAdded[index];
      rowValue.value = 0;
      if (rowValue.isPercentage) {
        rowValue.isPercentage = false;
        this.gratuityServiceChargeMaxlength = GlobalConst.Maxlength.FLATAMOUNT;
      }
      else {
        rowValue.isPercentage = true;
        this.gratuityServiceChargeMaxlength = GlobalConst.Maxlength.PERCENTAGE;
      }
    }
    this.CalculateAmount(type);
  }
  public valueSymbol(isPercentage: boolean) {
    return isPercentage ? '%' : this.localization.currencySymbol;
  }
  public get enumType(): typeof ServiceChargeGratuityEnum {
    return ServiceChargeGratuityEnum;
  }
}

export interface ItemValue {
  item: string;
  type: string
  itemValue: number;
  gratuity: Gratuity[];
  serviceCharge: ServiceCharge[];
  isReturn: boolean;
  netAmount: number;
  netUnitPriceWithoutDiscount?:number;
  netPriceWithoutDiscount?:number;
  itemDisplayValue?: number;
}

