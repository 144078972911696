import { Component, OnInit } from '@angular/core';
import { menuTypes } from 'src/app/common/components/menu/menu.constant';
import { RetailDataAwaiters } from '../shared/events/awaiters/retail.data.awaiters';

@Component({
  selector: 'app-payment-manager',
  templateUrl: './payment-manager.component.html',
  styleUrls: ['./payment-manager.component.scss']
})
export class PaymentManagerComponent implements OnInit {
  menuList: any;
  menuType = menuTypes;
  codeRoute: any;
  constructor() { }

  ngOnInit(): void {
    this.codeRoute = RetailDataAwaiters.GetChildMenu('/settings/retailsetup/paymentmanager', menuTypes.vertical);
    this.menuList = {
      menu: this.codeRoute.linkedElement,
      menuType : this.codeRoute.linkedElement[0].menuAlignment
    };
  }

}
