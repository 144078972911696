<div class="guest-select">
  <!-- <div class="toggle-button platform-guest pt-2" [hidden]="searchType !== 1 || !hasCGPSearch">
    <label class="switch-control__switch-label guest-form-label pr-2">{{ 'PlatformGuestsearch' | translate}}</label>
    <label class="switch"  >
        <input class="switch-input" type="checkbox" (change)="platFormToggleChange()" [(ngModel)]="platformGuestSearchEnable"  />
        <span class="slider round"></span>
    </label>
</div> -->
    <div class="guest-select__search-field">
      <dynamic-form [config]="configGuestSearchBy" #configGuestSearchByForm></dynamic-form>
      <div class="guest-select__actions">
        <dynamic-form [config]="configGuestSearch" #configGuestSearchForm></dynamic-form>
        <activities-app-button *ngIf="!autoSearchEnabled" [buttontype]="buttonSearch"
          (valueChange)='searchGuest($event)'></activities-app-button>
      </div>
    </div>
      
    
      <div class="guest-select__table">
          <!-- <table class="guest-select__table-view ">
            <thead class="guest-select__table-view-header">
              <tr class="seat-grid-header seat-border-color">
                <th class="p-3 ">
                  {{'guestNameText' | translate}}
                </th>
                <th class="p-3">
                  {{'guestEmailText' | translate}}
                </th>
                <th class="p-3">
                  {{'guestBookDetailsPhone' | translate}}
                </th>
                <th class="p-3" *ngIf="isMembershipAvailable">
                  {{_settings.General.MembershipNumberName}}
                </th>
              </tr>
            </thead>
            <tbody class="guest-select__table-view-body ">
              <tr *ngFor="let item of guestContacts" class="guest-select__guest-row" (click)="getGuestDetails(item.Id)">
                <td class="p-3">
                  {{item.FirstName}} {{item.LastName}}
                </td>
                <td class="p-3">
                  <span *ngIf="item.EmailAddress"> {{item.EmailAddress}}</span>
                  <span *ngIf="!item.EmailAddress">NA</span>
                </td>
                <td class="p-3">
                  <span *ngIf="item.PhoneNumber">{{item.PhoneNumber | phoneNumberSelector1 : item.PhoneNumber2: item.CountryPhoneCode : item.CountryPhoneCode2 :searchValue }}</span>
                  <span *ngIf="!item.PhoneNumber">NA</span>
                </td>
                <td class="p-3" *ngIf="membershipAvailable">
                  <span *ngIf="item.TrackMembershipNumber">{{item.TrackMembershipNumber}}</span>
                  <span *ngIf="!item.TrackMembershipNumber">NA</span>
                </td>
              </tr>
            </tbody>
          </table> -->
          <ng-container *ngIf="searchType != 4 && !isStayConfirmationEnabled && !isPMSSearchEnabled && !isRoomNumberSearchEnabled">
            <li *ngFor="let item of guestContacts" class="guest-card" (click)="getGuestDetails(item.Id,false,item)"  >
              <div class="guest-card__name seat-grid-header">{{item.FirstName}} {{item.LastName}}</div>
              <div class="guest-card__phone" *ngIf="item.modifiedPhone">{{item.modifiedPhone}}</div>
              <div class="guest-card__email" *ngIf="item.modifiedEmail">{{item.modifiedEmail}}</div>
              <hr class="mt-2 mb-2" *ngIf="item.TrackMembershipNumber || item.MembershipType">
              <div class="guest-card__membership" *ngIf="item.TrackMembershipNumber">
                {{_settings.General.MembershipNumberName}}: {{item.TrackMembershipNumber}}
              </div>
            </li>
          </ng-container>
          <ng-container *ngIf="searchType == 4 && memberShipArr.length">
            <li *ngFor="let item of memberShipArr" class="guest-card" (click)="getMemberDetails(item)" >
              <div class="guest-card__name seat-grid-header">{{item.FName}} {{item.LName}}</div>
              <div class="guest-card__phone" *ngIf="item.PhoneNumber">{{item.PhoneNumber}}</div>
              <div class="guest-card__email" *ngIf="item.Email">{{item.Email}}</div>
              <hr class="mt-2 mb-2" *ngIf="item.CardNo || item.MembershipType">
              <div class="guest-card__membership" *ngIf="_merchantSetting.ShowEngageMemberShipId.SettingValue == settingType.ENABLE">
                <i class="icon-card"></i><span class="pl-1">{{item.CardNo ? item.CardNo :('NA' | translate)}}</span>
              </div>
              <div class="guest-card__membership" *ngIf="_merchantSetting.ShowEngageMemberShipType.SettingValue == settingType.ENABLE">
                <i class="icon-member"></i><span class="pl-1">{{item.MembershipType? item.MembershipType :('NA' | translate)}}</span>
              </div>
            </li>
          </ng-container>
          <ng-container *ngIf="isStayConfirmationEnabled || isPMSSearchEnabled || isRoomNumberSearchEnabled">
            <li *ngFor="let item of guestContacts" class="guest-card" (click)="getStayContact(item)"  >
              <div class="guest-card__name seat-grid-header">{{item.Title}} {{item.FirstName}} {{item.LastName}}</div>
              <div class="guest-card__phone" *ngIf="item.PhoneNumber">{{item.PhoneNumber}}</div>
              <div class="guest-card__email" *ngIf="item.EmailAddress">{{item.EmailAddress}}</div>
              
             
                <app-search-based-guest-data [guestData]="item"></app-search-based-guest-data>

                  

            </li>
          </ng-container>
          <li *ngIf="!guestContacts?.length && !memberShipArr?.length"  class="guest-card mt-4" >
            {{'noDataFound' | translate}}
          </li>
      </div>
</div>