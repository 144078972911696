import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import {  RetailItemType } from '../retail.modals';
import {SelectedProducts, Ticket,ServiceChargeGratuity as svcg } from '../shared/service/common-variables.service';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { Commission, DiscountModel, ServiceChargeGratuity, ShopItem, StaffTransactionDetail, TransactionContract, TransactionData } from './transaction-engine-model';
import { TransactionEngineService } from './transaction-engine-service';
import * as _ from 'lodash';
import { RetailLocalization } from '../common/localization/retail-localization';
import { Product } from '../shared/globalsContant';
import { Injectable } from '@angular/core';
import { RetailShopItem } from '../shop/shop.modals';

@Injectable({
  providedIn: 'root'
})
export class TransactionEngineBusiness {
  constructor(private transactionService: TransactionEngineService,  private utils: RetailUtilities ,
              private propertyInfo: RetailPropertyInformation, private localization: RetailLocalization
            ) {}

  public async CreateTaxforMultipack(outletId: number , selectedProducts: SelectedProducts[], isSettleOpenTransaction: boolean = false) {
    const request = this.FormTicket(outletId, selectedProducts, isSettleOpenTransaction);
    let ticket = await this.transactionService.CreateTaxForMultiPackItems(request);
    this.MapNetAmounts(ticket,selectedProducts);
    return ticket;
  }

  public async CreateTicket(outletId: number , selectedProducts: SelectedProducts[], isSettleOpenTransaction: boolean = false) {
    const request = this.FormTicket(outletId, selectedProducts, isSettleOpenTransaction);
    let ticket = await this.transactionService.CreateTicket(request);
    this.MapNetAmounts(ticket,selectedProducts);
    return ticket;
  }

  MapNetAmounts(ticket:Ticket,selectedProducts: SelectedProducts[])
	{
		if(ticket)
		{
			ticket?.lineItems?.forEach(itm => {
				var product = selectedProducts.find(p => p.LineNumber == itm.index);
				if(product)
				{
					product.netPrice = itm.netPrice ;
					product.netUnitPrice = itm.netUnitPrice ;
					product.netUnitPriceWithoutDiscount = itm.netUnitPriceWithoutDiscount;
				}			
			})
		}		
	}
  public CreateTicketForItem(outletId: number , selectedProducts: RetailShopItem[]) {
    const request = this.FormTicketFromShopItem(outletId, selectedProducts);
    return this.transactionService.CreateTicket(request);
  }

  public FormTicket(outletId: number , selectedProducts: SelectedProducts[], isSettleOpenTransaction: boolean = false) {
    const shopItems: ShopItem[] = [];
    let lineNumber = 1;
    let index = 0;
    let totalGratuity = 0;
    let totalSc = 0;

    selectedProducts.forEach(item => {
      const servCharge: ServiceChargeGratuity[] = [];
      const commisionInfo: Commission[] = [];
      item.multiPackTransactionDetailId = item.multiPackTransactionDetailId  ? item.multiPackTransactionDetailId : 0;
      // Map Gratuity
      if (item.Gratuity && item.Gratuity.length > 0 && item.multiPackTransactionDetailId == 0) {
        const appliedGratuity = item.Gratuity.filter(g => g.gratuity !== 0);
        const allGratuities: ServiceChargeGratuity[] = [];
        appliedGratuity.forEach(grat => {
          allGratuities.push({
            date: this.utils.convertDateFormat(this.propertyInfo.CurrentDate),
            therapistId: grat.TherapistId,
            itemId: item.ItemId ? item.ItemId : 0,
            serviceChargePercent: 0,
            gratuityPercent: grat.Percentage ? grat.Percentage : 0,
            serviceCharge: 0,
            gratuity: grat.gratuity,
            id: 0,
            transactionDetailId: grat.TransactionDetailId ? grat.TransactionDetailId : 0,
            totalGratuity: 0,
            totalServiceCharge: 0,
            staffType:
            item.ItemType !== RetailItemType.RevenueItem ? grat.StaffType : 'USER',
            serviceChargeGratuityTaxDetails:grat.externalTax ?grat.externalTax:null,
          });
          totalGratuity += grat.gratuity;
        });
        allGratuities.forEach((r) => (r.totalGratuity = totalGratuity));
        servCharge.push(...allGratuities);
      }

      // Map Service Charge
      if (item.ServiceCharge && item.ServiceCharge.length > 0 && item.multiPackTransactionDetailId == 0) {
        const appliedServiceCharges = item.ServiceCharge.filter(g => g.ServiceCharge !== 0);
        const allServiceCharges: ServiceChargeGratuity[] = [];
        appliedServiceCharges.forEach(sc => {
          allServiceCharges.push({
            date: this.utils.convertDateFormat(this.propertyInfo.CurrentDate),
            therapistId: sc.TherapistId,
            itemId: item.ItemId ? item.ItemId : 0,
            serviceChargePercent: sc.Percentage ? sc.Percentage : 0,
            gratuityPercent: 0,
            serviceCharge: sc.ServiceCharge,
            gratuity: 0,
            id: 0,
            transactionDetailId: sc.TransactionDetailId ? sc.TransactionDetailId : 0,
            totalGratuity: 0,
            totalServiceCharge: 0,
            staffType: item.ItemType !== RetailItemType.RevenueItem ? sc.StaffType : 'USER',
            serviceChargeGratuityTaxDetails:sc.externalTax?sc.externalTax:null,
          });
          totalSc = sc.ServiceCharge;
        });
        allServiceCharges.forEach((r) => (r.totalServiceCharge = totalSc));
        servCharge.push(...allServiceCharges);
      }

      // Map Discount
      const discount: DiscountModel = {
        index,
        isPercentage: item.DiscountPercentage > 0,
        value: item.DiscountPercentage > 0 ? item.DiscountPercentage : item.Discount,
      };

      // Map Staff Info
      const staffTransactionInfo: StaffTransactionDetail[] = [];
      if (item.StaffTransactionDetail && item.StaffTransactionDetail.length > 0) {
        item.StaffTransactionDetail.forEach (s => {
          staffTransactionInfo.push({
            staffId : s.StaffId,
            staffType : s.StaffType
          });
      });
      }

      // Map Commission
      if (item.Commission && item.Commission.length > 0) {
        item.Commission.forEach(c => {
          commisionInfo.push({
            id : 0,
            staffType : c.StaffType,
            staffId : c.StaffId,
            templateId : c.TemplateId,
            itemId : item.ItemId,
            splitPercentage: c.SplitPercentage,
            quantity : item.Noofitems,

          });
        });
      }

      const lineItem: ShopItem = {
        itemId: item.ItemId,
        itemDescription: item.ItemDescription,
        itemType: item.ItemType,
        externalPOSItemId: item.ExternalPOSItemId,
        quantitySold: item.Noofitems,
        unitPrice: item.ProductPrice,
        memberUnitPrice:item.MemberPrice ? item.MemberPrice: 0,
        discount: item.Discount,
        discountTypeId: item.DiscountTypeId,
        discountPercentage: item.DiscountPercentage,
        staffTransactionDetail: staffTransactionInfo,
        lineNumber: item.isGroupingKey && !item.isPackagedItem ? 0 : lineNumber,
        tax: 0,
        taxExemptIds: item.taxExemptIds !=null ? item.taxExemptIds : null,
        totalAmount: item.Noofitems * item.ProductPrice,
        outletId,
        isGroupingKey: item.isGroupingKey,
        isPackagedItem: item.isPackagedItem,
        packageItemId: item.PackageItemId,
        isMultiPack: item.MultiPack,
        isMultiPackRedeem: item.MultiPack,
        isModificationRestricted: item.isModificationRestricted ? item.isModificationRestricted : false,
        clientMultiPackId: item.ClientMultiPackId,
        packageGroupId: item.PackageGroupId,
        isOpenPricedItem: item.isOpenPricedItem,
        id: isSettleOpenTransaction ? item.id : 0,
        isTaxExempt: false, //item.IsTaxExempt, TODO
        discountModel: discount,
        itemComments: item.itemComments,
        costPrice: item.costPrice,
        marginPercentage: item.marginPercentage,
        allowEarn: item.allowEarn,
        discountComments: item.discountComments,
        discountReason: item.discountReason,
        groupingParentId: item.GroupingParentId ? item.GroupingParentId : 0,
        groupingUniqueIdentifier: item.GroupingUniqueIdentifier ? item.GroupingUniqueIdentifier : null,
        groupingItemDescription: item.GroupingItemDescription ? item.GroupingItemDescription : '',
        serviceChargeGratuity: servCharge,
        commission: commisionInfo,
        multiPackTransactionDetailId: item.multiPackTransactionDetailId ? item.multiPackTransactionDetailId  : 0
      };

      shopItems.push(lineItem);

      if (!item.isGroupingKey || item.isPackagedItem) {
        index = index + 1;
        lineNumber = lineNumber + 1;
      }

    });

    const request: TransactionData = {
      id: 0,
      transactionType: 'Retail',
      transactionDate: this.utils.convertDateFormat(this.propertyInfo.CurrentDTTM),
      clerkId: 1,
      totalPrice: 0,
      totalTax: 0,
      totalAmount: 0,
      guestId: 0,
      memberId: 0,
      comment: '',
      stayId: 0,
     // isTaxExempt: false,
      isVoided: false,
      taxExemptIds : null,
      shopItems,
      outletId,
      machineNameId: Number(this.propertyInfo.GetPropertyInfoByKey('ProductId')) === Product.PMS ? 0 : this.localization.GetMachineId(),
      ticketNumber: '',
      productId: Number(this.propertyInfo.GetPropertyInfoByKey('ProductId')),
      //terminalId: this._shopService.SelectedTerminalId,
      patronId: '',
      guestGuid: null
    };

    return request;
  }

  MapServiceChargeGratuity(serviceChargeGratuity: svcg[]):ServiceChargeGratuity[]
  {
    let svcgList:ServiceChargeGratuity[] = [];    
    serviceChargeGratuity.forEach(x => {
      let svcgrat:ServiceChargeGratuity = 
      {
        gratuity:x.Gratuity,
        totalGratuity:x.TotalGratuity,
        gratuityPercent:x.GratuityPercent,
        serviceCharge:x.ServiceCharge,
        serviceChargePercent:x.ServiceChargePercent,
        totalServiceCharge:x.TotalServiceCharge,
        therapistId:x.TherapistId,
        transactionDetailId:x.TransactionDetailId,
        staffType:x.StaffType,
        itemId: x.ItemId,
        date : x.Date,
        subPropertyId : 0,
        propertyId : 0,
        id : x.Id      
      }
      svcgList.push(svcgrat);
    })
    return svcgList;
  }
  public FormTicketFromShopItem(outletId: number , shopItems: RetailShopItem[]): TransactionData {
    const shopItemInfo: ShopItem[] = [];
    shopItems?.forEach((shopItem,i) => {  
      const lineItem: ShopItem = {
        itemId: shopItem.ItemId,
        itemType: shopItem.ItemType,
        itemDescription: shopItem.ItemDescription,
        externalPOSItemId: shopItem.ExternalPOSItemId,
        quantitySold: shopItem.QuantitySold,
        unitPrice: shopItem.UnitPrice,
        memberUnitPrice: 0,
        discount: 0,
        discountTypeId: 0,
        discountPercentage: 0,
        staffTransactionDetail: [],
        lineNumber: i+1,
        tax: 0,
        totalAmount: shopItem.TotalAmount,
        outletId: outletId,
        isGroupingKey: false,
        isPackagedItem: false,
        packageItemId: 0,
        isMultiPackRedeem: false,
        isMultiPack: false,
        isModificationRestricted: false,
        clientMultiPackId: 0,
        packageGroupId: 0,
        isOpenPricedItem: false,
        id: 0,
        isTaxExempt: false,
        discountModel: shopItem.discountModel,
        costPrice: shopItem.costPrice,
        marginPercentage: shopItem.marginPercentage,
        allowEarn: shopItem.allowEarn,
        discountComments: '',
        discountReason: 0,
        itemComments: '',
        groupingParentId: shopItem?.GroupingParentId,
        groupingUniqueIdentifier: shopItem?.GroupingUniqueIdentifier,
        groupingItemDescription: shopItem?.GroupingItemDescription,
        serviceChargeGratuity:shopItem?.ServiceChargeGratuity?.length??0 > 0 ? this.MapServiceChargeGratuity(shopItem.ServiceChargeGratuity) : []
      };
      shopItemInfo.push(lineItem);
    });
 
    const request: TransactionData = {
      id: 0,
      transactionType: 'Retail',
      transactionDate: this.utils.convertDateFormat(this.propertyInfo.CurrentDTTM),
      clerkId: 1,
      totalPrice: 0,
      totalTax: 0,
      totalAmount: 0,
      guestId: 0,
      memberId: 0,
      comment: '',
      stayId: 0,
     // isTaxExempt: false,
      isVoided: false,
      shopItems: shopItemInfo,
      outletId,
      machineNameId: Number(this.propertyInfo.GetPropertyInfoByKey('ProductId')) === Product.PMS ? 0 : this.localization.GetMachineId(),
      ticketNumber: '',
      productId: Number(this.propertyInfo.GetPropertyInfoByKey('ProductId')),
      patronId: '',
      guestGuid: null
    };
    return request;
  }
}
