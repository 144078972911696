import { Component, OnInit } from '@angular/core';
import { TableSearchHeader, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { Observable, of, Subscription } from 'rxjs';
import { TaxTypeBusiness } from './tax-type.business';
import { TaxBased, UI as TaxTypeUI } from './tax-type.model';
import { map } from 'rxjs/operators';
import { ActionMode, SorTypeEnum } from 'src/app/common/shared/shared/enums/enums';
import { UntypedFormGroup } from '@angular/forms';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { AlertAction } from 'src/app/common/shared/shared.modal'; 
import { TableActions } from 'src/app/common/enums/shared-enums';
import { RevenueTypeDataService } from "src/app/retail/shared/service/revenuetype.data.service";
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { AlertType } from 'src/app/retail/shared/shared.modal';
import { ButtonType } from 'src/app/retail/shared/globalsContant';
import { MatDialog } from '@angular/material/dialog';
import { CreateTaxTypeComponent } from './create-tax-type/create-tax-type.component';
import { TaxTypeDataService } from 'src/app/retail/shared/service/taxtype.data.service';

@Component({
  selector: 'pms-tax-type',
  templateUrl: './tax-type.component.html',
  styleUrls: ['./tax-type.component.scss'],
  providers: [TaxTypeBusiness, RevenueTypeDataService, TaxTypeDataService]
})
export class TaxTypeComponent implements OnInit {
  searchHeaderOption: TableSearchHeader;
  captions: any;
  IsEditModeEnabledFlag = false;
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  originalData: Observable<any[]>;
  tableContent: Observable<any[]>;
  showInactiveTog = false;
  searchText = '';
  taxTypeToggler: boolean;
  taxTypeInput;
  createModalData: createModal;
  createTaxTypeForm: UntypedFormGroup;
  rangeMaintenanceForm: UntypedFormGroup;
  copyMode = false;
  isViewOnly: boolean;
  taxes: TaxTypeUI.TaxType[];
  postTypes: any[];
  EditCaption: any;
  dialogSubscription: Subscription;
  
  constructor(private localization: RetailLocalization, private business: TaxTypeBusiness, private utilities: RetailUtilities,
    private dialog: MatDialog) {
    this.captions = this.localization.captions;
  }

  ngOnInit() { 
    this.initializeInputs();
  }
 
  public setTableOptions() {
    this.tableOptions = {
      actions: [
        {
          type: TableActions.edit,
          disabled: false
        }, {
          type: TableActions.delete,
          disabled: this.isViewOnly
        }, {
          type: TableActions.drag,
          disabled: this.isViewOnly
        }
      ],
      defaultsortingColoumnKey: 'listOrder',      
      showTotalRecords: true,
      defaultSortOrder: SorTypeEnum.asc,
      columnFreeze: {
        firstColumn: true,
        lastColumn: true
      },
      isDragDisabled: this.isViewOnly,
      showDeleteMsg: true,
      deleteMsgKey: 'taxType'
    };
  }

  async getTableData() {
    this.utilities.ToggleLoader(true,this.captions.lbl_processing);
    var gridData = await this.business.getGridTaxType(true).finally(()=>{
      this.utilities.ToggleLoader(false);
    });
   
    this.originalData = of(gridData);
    if (!this.showInactiveTog) {
      this.tableContent = this.originalData && this.originalData.pipe(map(x => x.filter(t => t.isActive.value)));
    } else {
      this.tableContent = this.originalData;
    }
  }

  private initializeInputs() {
    this.getTableData();
    this.IsEditModeEnabledFlag = false;
    this.setTableOptions();
    this.headerOptions = this.business.getHeaderOptions();
    this.searchHeaderOption = {
      createBtnLabel: this.captions.btn_createTaxType,
      searchPlaceHolder: this.captions.lbl_searchByTaxType,
      toggleLabel: this.captions.tog_lbl_showInactive,
      showInactive: true,
      toggleDisabled: false,
      createBtnDisabled: false,
      hasHeaderAction: false,
      advancedSearch: this.captions.lbl_advancedSearch,
      searchDisabled: false,
      maxLength: 12
    };
  }

  create(eve) { 
    this.taxTypeInput = {
      mode: ActionMode.create,
      actionButton: this.captions.btn_create,
      form: undefined,
      selectedRoomItem: this.business.getItemArray(),
    }; 
    this.openDialog(ActionMode.create, this.taxTypeInput);
  }

  searchChange(e) {
    this.searchText = e;
  }

  showInactiveToggler(e) {
    if (e.checked) {
      this.showInactiveTog = true;
      this.tableContent = this.originalData;
    } else {
      this.showInactiveTog = false;
      this.tableContent = this.originalData && this.originalData.pipe(map(x => x.filter(t => t.isActive.value)));
    }
  }

  tableAction(event) {
    switch (event.fromType) {
      case FromTypeEnum.edit:
        this.IsEditModeEnabledFlag = true;
        this.edit(event);
        break;
      case FromTypeEnum.delete:
        this.delete(event.Obj);
        break;
      case FromTypeEnum.dragdrop:
        if (event.fromType === 'dragdrop' && event.Obj.dragdata.listOrder !== event.Obj.dropdata.listOrder) {
          event.Obj.dragdata.listOrder = event.Obj.dropdata.listOrder;
          this.business.updateTaxTypeFromGrid(event.Obj.dragdata).then(() => this.getTableData());
        } else {
          this.getTableData();
        }
        break;
      case FromTypeEnum.switch:
        this.business.updateTaxTypeFromGrid(event.Obj).then(response => {
          this.IsEditModeEnabledFlag = false;
          this.getTableData();
        });
        break;
    }
  }

  delete(data) {
    this.utilities.ToggleLoader(true,this.captions.lbl_processing);
    this.business.delete(data.id).then(response => {
      this.getTableData();
    });
    this.utilities.ToggleLoader(false);
  }

  edit(e) {
    this.utilities.ToggleLoader(true,this.captions.lbl_processing);
    Promise.all([this.business.getTaxTypeById(e.Obj.id), this.business.getPostTypes()]).then(result=>{
    this.postTypes = result[1];
    const taxTypeData = this.business.UITaxMapper(result[0], result[1]);
    this.taxTypeToggler = true;
    const taxMaintenanceObj = {
      id: taxTypeData.id,
      taxType: taxTypeData.taxType,
      postTypeId: taxTypeData.postTypeId, 
      changeDays: taxTypeData.exemptDays,
      effectiveDays: taxTypeData.effectiveDate,
      pstTax: taxTypeData.pSTCodeId,
      extractCode: taxTypeData.extractCode,
      listOrder: e.Obj.listOrder,
      isActive: taxTypeData.isActive.value,
      taxExemptCategories: taxTypeData.taxExemptCategory
    };
    const rangeMaintenanceObj = {
      id: taxTypeData.taxMaintenances ? taxTypeData.taxMaintenances[0].id : 0,
      rangeFrom: taxTypeData.taxMaintenances ? taxTypeData.taxMaintenances[0].fromDate : '',
      rangeTo: taxTypeData.taxMaintenances ? taxTypeData.taxMaintenances[0].toDate : '',
      taxBasedOn: taxTypeData.taxMaintenances ? taxTypeData.taxMaintenances[0].taxBasedOn : TaxBased.TaxPercent,
      taxPercent: taxTypeData.taxMaintenances ? taxTypeData.taxMaintenances[0].taxPercent : 0,
      flatAmount: taxTypeData.taxMaintenances ? taxTypeData.taxMaintenances[0].taxFlatAmount : 0,
      newTaxBasedOn: taxTypeData.taxMaintenances ? taxTypeData.taxMaintenances[0].newTaxBasedOn : TaxBased.TaxPercent,
      newTaxPercent: taxTypeData.taxMaintenances ? taxTypeData.taxMaintenances[0].newTaxPercent : 0,
      flatAmount1: taxTypeData.taxMaintenances ? taxTypeData.taxMaintenances[0].newTaxFlatAmount : 0,
      newPostTypeId: taxTypeData.taxMaintenances ? taxTypeData.taxMaintenances[0].newPostTypeId : '',
      reference: taxTypeData.taxMaintenances ? taxTypeData.taxMaintenances[0].taxReference : ''
    };
    const editObj = {
      taxMaintenance: taxMaintenanceObj,
      rangeMaintenance: rangeMaintenanceObj,
      tableContent: taxTypeData.taxMaintenances
    };
    this.taxTypeInput = {
      mode: ActionMode.update,
      actionButton: this.captions.btn_update,
      isEdit: true,
      form: editObj,
      selectedRoomItem: this.business.getSelectedItemArray(taxTypeData),
      postTypes: this.postTypes,
      isViewOnly: this.isViewOnly
    };
    this.createModalData = {
      title: this.captions.pg_title_editTaxType
    };

    this.openDialog(ActionMode.update, this.taxTypeInput);
  });
  this.utilities.ToggleLoader(false);
  }

  back(e) {
    if ((this.createTaxTypeForm && this.createTaxTypeForm.dirty) ||
      (this.rangeMaintenanceForm && this.rangeMaintenanceForm.dirty)) {
        if(!this.isViewOnly)
        {
      this.utilities.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.backEmit(e);
        }
      });
    }
    else {
      this.backEmit(e);
      
    }
    } else {
      this.backEmit(e);
    }
  }

  backEmit(e) {
    if (this.createTaxTypeForm) {
      this.createTaxTypeForm.reset();
    }
    if (this.rangeMaintenanceForm) {
      this.rangeMaintenanceForm.reset();
    }
    this.taxTypeToggler = false;
    this.IsEditModeEnabledFlag = false;
  }

  formReady(event) {
    this.createTaxTypeForm = event;
  }

  rangeMaintenanceFormReady(event) {
    this.rangeMaintenanceForm = event;
  }

  handleClick(event) {
    switch (event.from) {
      case ActionMode.create:
      case ActionMode.update:
        this.getTableData();
        this.backEmit(event);
        break;
      case ActionMode.cancel:
        this.back(event);
        break;
    }
  }

  openDialog(preTit: any, data?: any): any {
    this.EditCaption = (preTit == ActionMode.update) ? this.localization.captions.common.EDIT : this.localization.captions.common.pg_title_create;
    let afterClosed = true;
    return this.triggerPopup(afterClosed, data);
  }


  triggerPopup(afterClosed = true, dataInfo: any): any {
    const Dialogtitle = this.captions.pg_title_createTaxType;
    let data = {
      headername: this.EditCaption + '  ' + Dialogtitle,
      closebool: true,
      isViewOnly: this.isViewOnly,
      datarecord: dataInfo
    };
    const dialogRef = this.dialog.open(CreateTaxTypeComponent, {
      width: '90%',
      maxWidth: "1024px",
      height: "90%",
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data,
      disableClose: true
    });

    if (afterClosed) {
      this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.handleClick(result);
        }
      });
    }
    return dialogRef;
  }
}