import { Component, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { RetailReportControl } from '../../../basereport/base-report.component';
@Component({
  selector: 'app-retail-revenue-summary',
  templateUrl: './revenue-summary.component.html',
  styleUrls: ['./revenue-summary.component.scss']
})
export class RetailRevenueSummaryComponent implements OnInit {
  RevenueSummaryFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  selectedDropdownType: string = '';
  valuesSelected: any[];
  users: any[];
  editPatchValue : any;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, private dataService: ReportDataService
    ,private container: ViewContainerRef) { }

  ngOnInit() {
    this.RevenueSummaryFormGrp = this.fb.group({
      users: new UntypedFormControl([this.defaultData])
    });
    this.formReady.emit(this.RevenueSummaryFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit) {  
        let patchItem =  this.editPatchValue;
    
        if(patchItem.UserIds != undefined){
          this.dataService.GetAllUsersByPropertyId().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.UserIds.find(x => x == item.id);
            });
            this.users = arr;     
          });
        } 
        }
    } 
  }

  private async onLoad() {
    this.users = await this.dataService.GetAllUsersByPropertyId();
    this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown);
  }

  getFormControlValue(event) {
    if (event[0] === 'users') {
    this.RevenueSummaryFormGrp.setControl('users', event[1]);
    }
  }
}
