<!-- seat status and sttaus time display -->
<div class="reservation-status ">
        <div class="reservation-status__reservation-details">
          <h3 class="reservation-status__reservation-tag" >rew</h3>
          <div class="reservation-status__reservation-timeline">
            <span class="reservation-status__time-status">Late</span>
            <span class="icon-clock reservation-status__icon "></span>
            <span class="reservation-status__time">12 Min ago</span>
          </div>
        </div>
  
       
        <!-- Spinner for Waiting time -->
        <div class="reservation-status__time-indicator" >
        <mat-progress-spinner
        class="reservation-status__total-time "       
        [diameter]="50"        
        [value]="100">
        </mat-progress-spinner>
        <mat-progress-spinner
        class="reservation-status__time-loader"       
        [diameter]="50"
        [value]="20">
        </mat-progress-spinner>  
        <span class="total-mins">120 mins</span>
                              
        </div>      
        
      </div>
      <!-- <client-section></client-section> -->