import { Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { CacheService } from '@app/core/services/cache.service';
import { OperationResultState, buttonTypes } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { Field } from '@app/shared/dynamicform/models/field.interface';
import { PromoCodeDTO } from '@app/shared/models/PromoCodeDTO';
import { PartyService } from '@app/shared/services/party.service';
import { SettingsService } from '@app/shared/services/settings.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { TranslateService } from '@ngx-translate/core';
import { FeeType } from '../../models/PromoCodeDTO';
import { uniq } from 'lodash';
import { I } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-apply-promo-code',
  templateUrl: './apply-promo-code.component.html',
  styleUrls: ['./apply-promo-code.component.scss'],encapsulation:ViewEncapsulation.None
})
export class ApplyPromoCodeComponent extends Utilities implements OnInit {
  @Output('promoApplied') promoApplied = new EventEmitter();
  @Input() disableToggle?: boolean = false;
  @Input() isDining: boolean = false;
  @Input() promoCodeEligibleProperty:number[] = [];
  @ViewChild('searchConfigForm', { static: false }) searchConfigForm: DynamicFormComponent;
searchConfig: FieldConfig[];
searchButton:ButtonValue;
searchText: string=''
availableOffers:PromoCodeDTO[] =[];
applyButton: ButtonValue;
showHeight = false;
feeType = FeeType;
filteredData={
  Status: 0,
  TimeRange: {
    Start: new Date(),
    End:   new Date()
  }
}
  constructor(dialog: MatDialog,private ts:TranslateService,private ps:PartyService,private ss:SettingsService,public cs:CacheService,private api:ApiService) { 
    super(dialog);
  }

  ngOnInit(): void {
    this.loadConfig();
    this.getOffers();
  }

ngAfterViewInit(): void {
  this.ps.reservationFormGroup.controls.selectedGuest.valueChanges.subscribe(guestData => { 
    if (guestData && this.isDining){
      this.applyButton.disbaledproperity = false;
    }
   })
}

  loadConfig(){
    this.searchConfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.ts.instant('Type Promo Code'),
        class: 'search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this)
      }];
      this.searchButton = {
        type: buttonTypes.actionSecondarySmall,
        customclass: 'next-btn',
        label: 'search',
        disbaledproperity: false
      };
      this.applyButton = {
        type: buttonTypes.actionPrimarySmall,
        customclass: 'apply-btn',
        label: 'apply',
        disbaledproperity: this.isDining ? ((this.ps.reservationFormGroup.get('selectedGuest').value?.Id) ? false: true) : false
      }
  }


  clearSearch(){
    this.searchText = '';
    this.searchConfigForm.form.reset();
  }
  
  applyPromoCode(offer){

    this.promoApplied.emit(offer);
    //this.ps.promoApplies()
  }


  getOffers(){
    if(this.promoCodeEligibleProperty?.length){
      let payload = {
        PropertyIds : uniq(this.promoCodeEligibleProperty)
      }
      this.ss.GetEligibleOffer(this.cs.settings.value.General.MerchantId, payload).subscribe(response => {
        this.setOfferData(response);
      })
    }else{
      this.ss.GetAllOffers(Utilities.RestaurantId(), this.cs.settings.value.General.MerchantId, this.filteredData).subscribe(response => {
        this.setOfferData(response);
      });
    }
  }

  setOfferData(response){
    this.availableOffers = response.Payload;
    this.availableOffers.forEach((availableOffer) =>{
    availableOffer['showHeight'] = false});
    if(this.ps.currentRatePlanRequest?.PromoCode){
      //this.promoApplied.emit(this.availableOffers.find(data => data.OfferCode == this.ps.currentRatePlanRequest?.PromoCode))
    }
  }
  searchPromoCode(text){
    this.searchText = this.searchConfigForm.value.searchText;
    //data.packageName.toLowerCase().includes(text.toLowerCase()) || data.packageId.toLowerCase().includes(text.toLowerCase())
//    if (text !== '') {
    //   this.tableDataSource = new MatTableDataSource(this.tableData.filter(data => data['code'].toLowerCase().includes(text.toLowerCase()) || data['label'].toLowerCase().includes(text.toLowerCase())))
    // } else {
    //   this.tableDataSource = new MatTableDataSource(this.tableData)
    // }
  }

  
  
}


@Pipe({
  name: 'searchPipe'
})
export class SearchTextPipe implements PipeTransform {

  transform(offers,searchText): any 
  {
    if(searchText)
    return offers.filter(offer => offer.OfferCode?.toLowerCase().includes(searchText?.toLowerCase()) || offer.OfferCodeLabel?.toLowerCase().includes(searchText?.toLowerCase()));
else
return offers;
  }
}

@Pipe({
  name: 'parseJSON'
})
export class FetchArrayPipe implements PipeTransform {

  transform(string): any 
  {
    if(string){
      const parser = new DOMParser();


return parser.parseFromString(string, "text/html");
    }
 // return JSON.parse(string);
  }
}
