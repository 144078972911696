import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormArray, FormControl } from "@angular/forms";
import { AvailabilityType, buttonTypes, PartyState, PartyType, RolesAndPermissionsType } from "@app/shared/constants/commonenums";
import { Utilities } from "@app/shared/utilities/utilities";
import { RoleType } from '@app/shared/models/LoginResultDTO';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { DashboardFunctions } from "@app/shared/utilities/dashboard-functions";
import { AppService } from "@app/app.service";
import { ButtonValue } from "@app/shared/dynamicform/models/field-config.interface";

@Component({
    selector: 'activity-session-slots',
    templateUrl: './activity-session-slots.component.html',
    styleUrls: ['./activity-session-slots.component.scss']
})
export class ActivitySessionSlotsComponent implements OnInit, OnChanges {

    @Input()
    SessionSlots;

    @Input()
    isMultiSlotSelection;

    @Input()
    MembershipIds;

    @Input()
    MemberActive

    @Input()
    AllocationType;

    @Input()
    settings;

    @Input()
    isEdit;

    @Input()
    selectedDate;

    @Input() SessionSlotsForAllDates;

    @Output() slotClick: EventEmitter<any> = new EventEmitter<any>();

    @Input() AllowStandBy: boolean;

    @Input() 
    AllowOverbook: boolean;

    @Input() bookPastReservations: boolean;

    intialized: boolean;
    HostRoleType: any;

    overbookButton: ButtonValue;
    addToStandByButton: ButtonValue;

    _AvailabilityType = AvailabilityType;

    constructor(private toastrService: ToastrService, private ts: TranslateService, private df: DashboardFunctions, private appService: AppService) { }

    ngOnInit() {
        this.setSlots();
        let checkedsessionSlot: any = this.SessionSlots.find(x => x.checked) ?? this.SessionSlots[0];
        this.slotClick.emit(checkedsessionSlot);
        this.HostRoleType = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostRoleType;
        this.slotStatusSet();
        this.initFormArray();

        this.overbookButton = {
            type: buttonTypes.actionPrimary,
            label: 'partyStatusOverrideTimeText',
            customclass: 'action-btn'
        };
        this.addToStandByButton = {
            type: buttonTypes.actionSecondary,
            label: 'addToStandby',
            customclass: 'action-btn'
        };
    }

    isStandByBookingSelected() {
        if (this.SessionSlots.find(({ SessionGroupId }) => SessionGroupId)) {
            return !!this.SessionSlots.find(({ Availability, checked }) => checked && Availability.AvailabilityType === AvailabilityType.SoldOut)
        }
        else {
            for (let i = 0; i < this.SessionSlotsForAllDates.length; i++) {
                let isStandByBookingSelected = this.SessionSlotsForAllDates[i].AvailableSessions.find(session => session.checked && session.Availability.AvailabilityType === AvailabilityType.SoldOut)
                if (isStandByBookingSelected) {
                    return true;
                }
            }
            return false;
        }
    }

    isSessionSelected() {
        if (this.SessionSlots.find(({ SessionGroupId }) => SessionGroupId)) {
            return !!this.SessionSlots.find(({ Availability, checked }) => checked)
        }
        else {
            for (let i = 0; i < this.SessionSlotsForAllDates.length; i++) {
                let isSessionSelected = this.SessionSlotsForAllDates[i].AvailableSessions.find(session => session.checked)
                if (isSessionSelected) {
                    return true;
                }
            }
            return false;
        }
    }


    setSlots() {
        if (this.SessionSlotsForAllDates && this.SessionSlotsForAllDates.length > 0) {
            let sessionForCurrentDate = this.SessionSlotsForAllDates.filter(session => session.AvailableDate == this.selectedDate);
            if (sessionForCurrentDate.length > 0) {
                this.SessionSlots = sessionForCurrentDate[0].AvailableSessions;
            }
        }
        this.SessionSlots = this.SessionSlots.filter(session => session.Availability?.AvailabilityType != AvailabilityType.SelectionCriteriaNotMatched);

    }

    initFormArray() {
        this.intialized = true;
    }

    onSlotClick(event, session) {
        if (!session.checked && this.isMultiSlotSelection && this.isStandByBookingSelected()) {
            this.toastrService.show(this.ts.instant('MultiStandbySelectErrorInfoMessage'))
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        if ((this.AllowStandBy || this.AllowOverbook) && !session.checked && (session.Availability?.AvailabilityType === AvailabilityType.SoldOut || !session.IsAddonAvailable) || 
        (this.SessionSlots.filter(slot => slot.isCheckedIn).length > 0 && !this.isMultiSlotSelection && this.isEdit)) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

    }

    onSlotSelectSuccess(session) {
        this.slotClick.emit(session);
        if (this.isMultiSlotSelection) {
            this.disableOverlapingSessions();
        }
    }

    slotStatusSet() {
        const restaurantDate = Utilities.getRestaurantDateTime(this.settings.General.DaylightDelta)
        this.SessionSlots.forEach(session => {
            session.disabled = false;
            if (session.Availability?.AvailabilityType === AvailabilityType.SoldOut && !session.Availability.AvailableStandBySlots && !this.AllowOverbook) {
                session['stateClass'] = "Soldout";
                session.disabled = true;
            }
            else if (session.Availability?.AvailabilityType === AvailabilityType.SoldOut
                && ((this.AllowStandBy && session.Availability.AvailableStandBySlots && session.IsAddonAvailable) || this.AllowOverbook)) {
                session['stateClass'] = "SoldoutStandBy";
                session.checked = this.AllowOverbook && !this.isEdit ? false : session.checked;
                session.IsForStandbyReservations = true;
            }
            if (session.isCheckedOut) {
                session['stateClass'] = "CheckedOut";
                session.checked = false;
                session.disabled = true;
            }
            if (session.isBlocked) {
                session['stateClass'] = "blocked";
                if(!this.isEdit || (this.isEdit && !session.checked)){
                    session.checked = false;
                    session.disabled = true;
                }
            }
            if(session.Availability?.AvailabilityType === AvailabilityType.Cancelled){
                session['stateClass'] = "Cancelled";
                session.checked = false;
                session.disabled = true;
            }

            if (session.Availability?.AllocationTypeId) { 
                const { R, G, B, A } = this.AllocationType.find(type => type.Id === session.Availability?.AllocationTypeId)['AllocationPropertyMappings'][0].Color;
                session['color'] = `rgba(${R}, ${G}, ${B}, ${A})`;
            }

            let hasStandByBookPersmission = this.AllowStandBy && (this.HostRoleType.Name === RoleType[RoleType.Manager]
                || (!this.isEdit && this.appService.hasPermission(RolesAndPermissionsType.AddToStandByList))
                || (this.isEdit && this.appService.hasPermission(RolesAndPermissionsType.EditStandByParties)));

            let hasOverbookPersmission = this.AllowOverbook && (this.HostRoleType.Name === RoleType[RoleType.Manager]
                || this.appService.hasPermission(RolesAndPermissionsType.Overbook));

            if(!hasOverbookPersmission){
                this.AllowOverbook = false;
            }

            if ((!this.isSessionAvailable(session) || !session.IsAddonAvailable) && !hasStandByBookPersmission && !hasOverbookPersmission) {
                session.disabled = true;
            }
            if (!this.isEdit && session.disabled) {
                session.checked = false;
            }
            if (session.isConfirmedReservation) {
                session.disabled = true;
                session.checked = false;
                session['stateClass'] = "ConfirmedStandby";
            }
            if (restaurantDate > new Date(session.End)) {
                if (!this.df.checkBookPastDaysReservationsConfig()) {
                    session.disabled = true;
                    session.checked = false;
                }
                // else{
                // session.disabled = false;
                // }
                session['stateClass'] = "Completed";
            }
            if (session.isCheckedIn) {
                session.disabled = true;
                session.checked = false;
                session['stateClass'] = "CheckedIn";
            }
            if (this.isEdit && this.df.checkEditCheckedInReservationsConfig() && session.isCheckedIn && session.Availability?.AvailabilityType !== AvailabilityType.Cancelled) {
                session.checked = true;
            }
            if (this.SessionSlots.filter(slot => slot.isCheckedIn).length > 0 && !this.isMultiSlotSelection && this.isEdit) {
                session['stateClass'] = "NonEditable";
            }
            if(session.Availability?.AvailabilityType === AvailabilityType.AssetNotFound){
                session['stateClass'] = "AssertNotFound";
                session.checked = false;
                session.disabled = true;
            }

        });
        this.disableOverlapingSessions();
    }


     /**
     * “The manager has the ability to access all the slots.
     * A reservationist can only book slots that are designated for specific member rate types.
     */
    isSessionAvailable(session):boolean{
        return this.HostRoleType.Name === RoleType[RoleType.Manager] ||
                (session.Availability?.AvailabilityType === AvailabilityType.OpenToAll
                    || (session.Availability?.AvailabilityType === AvailabilityType.OpenToSpecificMember
                        && ((session.Availability?.AllocationTypeId && this.MembershipIds?.some(s=>s ==  session.Availability?.AllocationTypeId))
                            || Utilities.IsMember(session.Availability))));
    }

    soldOutSessionSelection(session, type: 'overbook' | 'standby'): void{
        if(this.isMultiSlotSelection && this.isSessionSelected()){
            this.toastrService.show(this.ts.instant('MultiStandbySelectErrorInfoMessage'));
            return;
        }
        session.checked = true;
        if(type === 'overbook'){
            let overbooked = session.bookingSize ? session.bookingSize - session.Availability.AvailableSlots : 0;
            session.OverBooked = overbooked < 0 ? 0 : overbooked;
            session.isOverbook = true;
            session.isStandBy = false;
        }else{
            session.isOverbook = false;
            session.isStandBy = true;
        }
        this.onSlotSelectSuccess(session);
    }

    disableOverlapingSessions() {
        var selectedSlots = this.SessionSlots.filter(slot => slot.checked);
        this.SessionSlots.forEach(session => {
            if (!session.checked && this.isMultiSlotSelection) {
                session.disabledOverlaped = this.isOverlap(selectedSlots, session);
            }
        });
    }

    isBetween(start, end, value) {
        return value > start && value < end;
    }

    isOverlap(selectedSlots, slot): boolean {
        var slotStart = new Date(slot.Start);
        var slotEnd = new Date(slot.End);

        var intersectingSlots = selectedSlots.filter(selectedSlot => {
            let selectedSlotStart = new Date(selectedSlot.Start);
            let selectedSlotEnd = new Date(selectedSlot.End);

            if ( // is Same Start Or End
                slotStart.getTime() === selectedSlotStart.getTime() ||
                slotEnd.getTime() === selectedSlotEnd.getTime() ||
                // is slot falls between selectedSlot
                this.isBetween(selectedSlotStart, selectedSlotEnd, slotStart) ||
                this.isBetween(selectedSlotStart, selectedSlotEnd, slotEnd) ||
                // is selectedSlot falls between slot
                this.isBetween(slotStart, slotEnd, selectedSlotStart) ||
                this.isBetween(slotStart, slotEnd, selectedSlotEnd)) {
                return true;
            }

            return false;
        });
        return intersectingSlots.length;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setSlots();
        this.HostRoleType = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostRoleType;
        this.slotStatusSet();

    }
}
