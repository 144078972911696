<section class="header-input">
  <form [formGroup]="ActionForm" autocomplete="off">
   
    <mat-form-field [floatLabel]="floatLabel" class="ag_form-control" [ngClass]="{ 'read-only' : isUpdate}">
      <input matInput formControlName="code" [inputtype]="inputType" [placeholder]="code" [required]='showRequired' [minlength]="codeMinLength"
        [maxlength]="codeMaxLength"  [readonly]='isUpdate' >
        <mat-error class="ag_text-overflow--hidden" *ngIf="ActionForm.get('code').hasError('required')" [matTooltip]="errMessageCode">{{errMessageCode}}</mat-error>
        <mat-error *ngIf="ActionForm.get('code').hasError('minlength')">{{captions.err_min_length}}</mat-error>
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabel" class="ag_form-control">
      <input matInput formControlName="name" inputtype="noprespace" nowhitespace [placeholder]="name" [required]='showRequired' [minlength]="nameMinLength"
        [maxlength]="nameMaxLength">
        <mat-error class="ag_text-overflow--hidden" *ngIf="ActionForm.get('name').hasError('required')" [matTooltip]="errMessageName">{{errMessageName}}</mat-error>
        <mat-error *ngIf="ActionForm.get('name').hasError('minlength')" [attr.LiteralID]="'err_min_length'"  [attr.LiteralID]="'err_min_length'" >{{captions.err_min_length}}</mat-error>
    </mat-form-field>
  </form>

  <span *ngIf="isUpdate">
    <app-ag-toggle [toggleInputs]="toggleInput">
    </app-ag-toggle>
  </span>
  <div  class="ag_display--flex">
    <app-button class="ag_form-control" [buttontype]="saveButton" (valueChange)='onSave($event)' class="app-button"></app-button>
    <app-button class="ag_form-control" [buttontype]="cancelButton" class="ag-ml-2" (valueChange)='onCancel($event)' class="app-button">
    </app-button>
  </div>
</section>
