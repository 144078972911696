import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { Localization } from "src/app/common/localization/localization";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogCloseEnum } from "src/app/common/enums/shared-enums";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonValue, AgFieldConfig, AgTimeConfig } from 'src/app/common/Models/ag-models';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import moment from 'moment';
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";
import { Host } from '../../globalsContant';


@Component({
  selector: 'app-create-custom-fee-popup',
  templateUrl: './create-custom-fee-popup.component.html',
  styleUrls: ['./create-custom-fee-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateCustomFeePopupComponent implements OnInit {
  captions: any;
  saveBtn: ButtonValue;
  cancelButton: ButtonValue;
  customFeeForm: UntypedFormGroup;
  floatLabel:string;
  commonCaptions: any;
  isStartDateTouched = false;
  isEndDateTouched = false;
  availableDaysArr: any = [];
  selectedDaysArr: any[] = [];
  DaysArr: any = this.utilities.getShortWeekArrayLocaleSorted();
  placeholderFormat: string;
  daysArrayVar = false;
  minStartDate: Date = this.PropertyInfo.CurrentDate;
  minEndDate: Date = this.PropertyInfo.CurrentDate;
  isAmount: string = "false";
  percentageMaxLength = 100;
  nameMaxLength = 25;
  currentDate: Date;
  timeFormat: number;
  currentTime: any;
  linkedItemList: any[] = [];
  customFeeRetailItems: any[] = [];
  selectedFromList: any = [];
  searchKey = [];
  autoCompleteKeys: string[] = ['name'];
  selectedChipKey: string[] = ['name'];
  selectedNewChipKey: string[] = ['name'];
  searchPlaceholder: string = '';
  currentCustomFeeConfig : CustomFeeAPIModel;
  timeformat: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  headerTitle: string;
  selectedItem: any;
  startTimeInputs: AgTimeConfig;
  endTimeInputs: AgTimeConfig;


  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
    public localization: Localization,
    private dialogRef: MatDialogRef<CreateCustomFeePopupComponent>,
    private fb: UntypedFormBuilder,
    private PropertyInfo: CommonPropertyInformation,
    private utilities: CommonUtilities,
    private http: HttpServiceCall,
  ) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.commonCaptions = this.localization.captions.common;
    this.placeholderFormat = this.localization.inputDateFormat;
  }

  ngOnInit(): void {
    this.saveBtn = {
      label: this.captions.btn_save,
      type: 'primary',
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
    this.searchPlaceholder = `${this.captions.lbl_item_number} - ${this.captions.lbl_item_description}`;
    this.headerTitle = this.dialogData.mode == modes.create ? this.captions.lbl_new_fee : this.captions.lbl_edit_fee
    this.customFeeForm = this.fb.group({
      id: 0,
      fee: ['',  Validators.required],
      isAmount: [this.isAmount, Validators.required],
      amount: ['', Validators.required],
      startDate: [''],
      endDate: [''],
      startTime: [''],
      endTime: [''],
      availableDays: '',
      linkedItemId: 0
    });
    this.startTimeInputs = {
      form: this.customFeeForm,
      formControlName: 'startTime',
      placeHolder: this.captions.lbl_startTime,
      placeHolderId: 'lbl_startTime',
      className: 'timerWidth date-picker-width ag_w--100'
    }
    this.endTimeInputs = {
      form: this.customFeeForm,
      formControlName: 'endTime',
      placeHolder: this.captions.lbl_endTime,
      placeHolderId: 'lbl_endTime',
      className: 'timerWidth date-picker-width ag_w--100',
      minTime: this.customFeeForm.controls.startTime.value
    }
    this.GetRetailItems();
    if(this.dialogData.mode == modes.edit)
    {
      this.currentCustomFeeConfig = this.dialogData.data;
      this.selectedDaysArr = this.reverseMapForDays(this.currentCustomFeeConfig.availableDays);
      this.mapFormValues(this.currentCustomFeeConfig);
      this.setAvailableDays()
    }
    else if(this.dialogData.mode == modes.create)
    {
      this.timeformat = this.localization.getTimeFormat();
      this.currentTime = this.localization.LocalizeTime(this.PropertyInfo.CurrentDate);
      this.customFeeForm.controls.startTime.setValue(this.currentTime);
      this.customFeeForm.controls.endTime.setValue(this.currentTime);
  
      const startDate: any = this.PropertyInfo.CurrentDate;
      startDate.setHours(0, 0, 0, 0);
      this.customFeeForm.controls.startDate.setValue(startDate);
      this.customFeeForm.controls.endDate.setValue(startDate);
      this.minEndDate = this.customFeeForm.controls.startDate.value;
      this.selectedDaysArr = this.utilities.getToggleAllFilter(this.DaysArr, this.selectedDaysArr);
      this.CheckAvailableDays();
    }
    this.customFeeForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.saveButtonEnable();
    });
  }

  starttimeChange(e){
    this.endTimeInputs.minTime = this.customFeeForm.controls.startTime.value;
    this.endTimeInputs = {...this.endTimeInputs}
  }
  reverseMapForDays(x) {
    let res:any[] = [];
    if ((Days.Sunday & x) == Days.Sunday)
      res.push(this.DaysArr[1]);
     if ((Days.Monday & x) == Days.Monday)
      res.push(this.DaysArr[2]);
     if ((Days.Tuesday & x) == Days.Tuesday)
      res.push(this.DaysArr[3]);
     if ((Days.Wednesday & x) == Days.Wednesday)
      res.push(this.DaysArr[4]);
     if ((Days.Thursday & x) == Days.Thursday)
      res.push(this.DaysArr[5]);
     if ((Days.Friday & x) == Days.Friday)
      res.push(this.DaysArr[6]);
     if ((Days.Saturday & x) == Days.Saturday)
      res.push(this.DaysArr[7]);
    return res;
  }

  mapFormValues(result: CustomFeeAPIModel) {
    let formValues = {
      fee: result.name,
      isAmount: !result.isPercentage,
      amount: result.value,
      startDate: new Date(result.startTime),
      endDate: new Date(result.endTime),
      startTime: this.localization.getTime(this.localization.ConvertStringDateTimeToDate(result.startTime, this.localization.LocalizeTime(result.startTime)), this.timeformat),
      endTime: this.localization.getTime(this.localization.ConvertStringDateTimeToDate(result.endTime, this.localization.LocalizeTime(result.endTime)), this.timeformat),
      availableDays: result.availableDays,
      id: result.id,
      linkedItemId: result.linkedItemId
    }
    this.customFeeForm.patchValue(formValues);
  }

  save() {
    console.log(this.selectedDaysArr);
    let selectedDays = this.selectedDaysArr.filter(x => x.id != 7).map(x=>x.id);
    let days: Days
    selectedDays.forEach(x=>{
      days = days | this.daysMapper(Number(x))
    })
    let startDate = this.customFeeForm.controls.startDate.value;
    let endDate = this.customFeeForm.controls.endDate.value;
    let startTime = this.customFeeForm.controls.startTime.value;
    let endTime = this.customFeeForm.controls.endTime.value;
    let result: CustomFeeAPIModel = {
      name: this.customFeeForm.controls.fee.value,
      id: this.customFeeForm.controls.id.value,
      isDeleted: false,
      isPercentage: !this.customFeeForm.controls.isAmount.value,
      value: this.customFeeForm.controls.amount.value,
      startTime: this.timeDateMapper(this.localization.convertDateObjToAPIdate(this.localization.delocalizeDisplayDate(startDate)), startTime ? startTime : "00:00"),
      endTime: this.timeDateMapper(this.localization.convertDateObjToAPIdate(this.localization.delocalizeDisplayDate(endDate)), endTime ? endTime : "23:59"),
      availableDays: days,
      linkedItemId: this.customFeeForm.controls.linkedItemId.value
    }
    this.dialogRef.close({ from: DialogCloseEnum.Action, form: '', data: result });
  }

  daysMapper(dayId: any) {
    switch (dayId) {
      case 0:
        return Days.Sunday
      case 1:
        return Days.Monday
      case 2:
        return Days.Tuesday
      case 3:
        return Days.Wednesday
      case 4:
        return Days.Thursday
      case 5:
        return Days.Friday
      case 6:
        return Days.Saturday
    }
  }

  timeDateMapper(date, time) {
    let time_calc = this.localization.TimeToDate(time);
    let res = this.localization.AddTimeToDate(date, time_calc);
    return this.localization.convertDateTimeToAPIDateTime(res);
  }

  onCancel() {
    this.dialogRef.close({from:DialogCloseEnum.Close, form: '' });
  }

  saveButtonEnable() {
    if (this.customFeeForm.dirty && this.customFeeForm.controls.fee.valid && this.customFeeForm.controls.amount.valid && this.customFeeForm.controls.linkedItemId.value!=0) {
      this.saveBtn.disabledproperty = false;
    }
    else {
      this.saveBtn.disabledproperty = true;
    }
    this.saveBtn = { ...this.saveBtn };
  }

  CheckAvailableDays(fromControl?: string) {
    if(fromControl && fromControl === 'START'){
      this.startDateChanged();
    }
    if(fromControl === 'START')
    {
      this.isStartDateTouched=true
    }
    if(fromControl === 'END')
    {
      this.isEndDateTouched=true
    }
    this.setAvailableDays();
    this.toggleAllFilter(fromControl === 'END'? true : false);
  }

  setAvailableDays()
  {
    const strt = moment(this.localization.ConvertDateToISODate(this.utilities.getDate(this.customFeeForm.controls['startDate'].value)));
    const end = moment(this.localization.ConvertDateToISODate(this.utilities.getDate(this.customFeeForm.controls['endDate'].value)));
    this.availableDaysArr = this.WeekArray(strt, end, this.captions.lbl_all_Days);
    this.RemoveUnavailabileDays(); 
  }

    // Generate Week Array starts
    WeekArray(strt, end, allDayCaption: string) {
      const aweek = [];
      let row2, nxtday;
      const noOfDayDisp = end.diff(strt, 'days');
      aweek.push(allDayCaption);
      for (let i = 0; i <= noOfDayDisp; i++) {
          if (i === 0) {
              row2 = strt.format('ddd');
              if (aweek.indexOf(row2) === -1) { aweek.push(row2); }
          } else {
              nxtday = strt.add(1, 'day');
              row2 = nxtday.format('ddd');
              if (aweek.indexOf(row2) === -1) { aweek.push(row2); }
          }
      }
      return aweek;
  }

  startDateChanged() {
    if(this.customFeeForm.controls.startDate.value === null){
      this.customFeeForm.controls.endDate.setValue(null);
      this.minEndDate = this.PropertyInfo.CurrentDate;
    }
    else{
      if (this.isStartDateExceedsEndDate()) {
        this.customFeeForm.controls.endDate.setValue(this.customFeeForm.controls.startDate.value);
      }
      this.minEndDate = this.customFeeForm.controls.startDate.value;
    }
  }

  GetRetailItems() {
    let result = this.http.CallApiWithCallback<any>({
      host: Host.retailManagement,
      callDesc: "GetRetailItemByItemType",
      method: HttpMethod.Get,
      error: this.showError.bind(this),
      success: this.successCallBack.bind(this),
      extraParams:[],
      uriParams: {
        type: 11
      }
    });
  }

  showError() {
    console.log('error');
  }

  successCallBack(result) {
    this.linkedItemList = result.result;
    this.customFeeRetailItems = result.result.map(x => {
      return {
        id: x.id,
        name: x.retailItemDetail.itemDescription
      }
    });
    if (this.dialogData.mode == modes.edit && this.customFeeRetailItems?.find(y => y.id == this.currentCustomFeeConfig.linkedItemId)) {
      this.selectedFromList = [this.customFeeRetailItems?.find(y => y.id == this.currentCustomFeeConfig.linkedItemId)]
    }
  } 
  isStartDateExceedsEndDate(): boolean {
    const startDate = this.customFeeForm.controls.startDate.value;
    const endDate = this.customFeeForm.controls.endDate.value;
    return this.resetTime(startDate) > this.resetTime(endDate);
  }


  resetTime(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  DateChanged(event) {
    this.isStartDateTouched = event === "START";
    this.isStartDateTouched = event === "END";
  }

  RemoveUnavailabileDays() {
    for (let i = 0; i < this.selectedDaysArr.length; i++) {
      if (!this.availableDaysArr.includes(this.selectedDaysArr[i].name)) {
        this.selectedDaysArr.splice(i, 1);
      }
    }
  }


  PP(ga, gv) {
    if (this.isExist(ga, gv) === -1) {
      ga.push(gv);
      if (ga.length === this.availableDaysArr.length - 1) {
        this.selectedDaysArr = [];
        for (let i = 0; i < this.DaysArr.length; i++) {
          for (let j = 0; j < this.availableDaysArr.length; j++) {
            if (this.DaysArr[i].name === this.availableDaysArr[j]) {
              this.selectedDaysArr.push(this.DaysArr[i]);
            }
          }
        }
      }
    } else {
      ga.splice(this.isExist(ga, gv), 1);
      if (ga.length === this.availableDaysArr.length - 1) {
        const obj = ga.find(x => x.id === 7);
        if (obj) {
          ga.splice(obj, 1);
        }
      }
    }
    this.daysArrayVar = !this.daysArrayVar;
  }

  toggleAllFilter(isDisable: boolean = true) {
      this.selectedDaysArr = [];
      for (let i = 0; i < this.DaysArr.length; i++) {
        for (let j = 0; j < this.availableDaysArr.length; j++) {
          if (this.DaysArr[i].name === this.availableDaysArr[j]) {
            this.selectedDaysArr.push(this.DaysArr[i]);
          }
        }
      }
  }

  toggleButtonClick = ($event, frm, Driven) => {
    if (Driven.id == 7) {
      this.toggleAllFilter();
      return;
    }
    if (frm.toLowerCase() === 'day') {
      this.RemoveUnavailabileDays();
      this.PP(this.selectedDaysArr, Driven);
    } else {
      this.ButtonSelect(this.selectedDaysArr, Driven);
    }
    this.customFeeForm.markAsDirty();
    this.saveButtonEnable();
  }


  ButtonSelect(Arr, value, setFlag: boolean = true) {
    Arr.splice(0, 1);
    Arr.push(value);
    if (setFlag) {
      this.customFeeForm.markAsDirty();
    }
  }

  isExist(coll, obj) {
    let index = -1;
    if (coll) {
      index = coll.findIndex(x => x.id === obj.id);
    }
    return index;
  }


  selectionChange(e) {
    this.isAmount = e.value;
    this.customFeeForm.get('isAmount').setValue(this.isAmount == "true");
    this.customFeeForm.get('amount').setValue(0.00);
    this.customFeeForm.markAsDirty();
    this.saveButtonEnable();

  }


  onStartTimeSet(e) {
    this.customFeeForm.controls.startTime.markAsDirty();
    this.customFeeForm.controls.startTime.setValue(this.localization.LocalizeTime(this.utilities.TimeToPropertyDateTime(e)));
  }

  onEndTimeSet(e) {
    this.customFeeForm.controls.endTime.markAsDirty();
    this.customFeeForm.controls.endTime.setValue(this.localization.LocalizeTime(this.utilities.TimeToPropertyDateTime(e)));
  }
  isClosed(event) {}

  openPicker(event) {
    document.getElementById('time-overlay').classList.add('transform3dnone');
  }

  receiveMessage($event) {
  }


  selectedChipDataEmit(event: { id: number, name: string }[]) {
      if (event) {
        this.selectedItem = event[0];
        this.customFeeForm.controls.linkedItemId.setValue(this.selectedItem.id);
        this.customFeeForm.markAsDirty();
        this.saveButtonEnable();
      }
  }

  SearchTextHandler(arg) {
    const searchInput = arg.toLowerCase().trim();
    if (searchInput && searchInput.length >= 1) {
      if (this.linkedItemList && this.linkedItemList.length > 0) {
        this.customFeeRetailItems = this.linkedItemList.filter(x => x.retailItemDetail.itemDescription.toLowerCase().includes(searchInput))
        .map(x => {
            return {
              id: x.id,
              name: x.retailItemDetail.itemDescription
            };
          });
      }
      else
      this.customFeeRetailItems = [];
    }
  }

  onChipRemoveEvent(event) {
    this.customFeeForm.get('linkedItemId').setValue(0);
    this.customFeeForm.markAsDirty();
    this.saveButtonEnable();
  }
}


import { Pipe, PipeTransform } from '@angular/core';
import { CustomFeeAPIModel, Days, modes } from '../custom-fee.model';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { HttpMethod, HttpServiceCall } from '../../service/http-call.service';
import { cloneDeep } from 'lodash';

@Pipe({
    name: 'isexistpipe'
})
export class IsExistPipe implements PipeTransform {
    transform(value: boolean, coll, obj): number {
        return coll && coll.some(x => ((x.id || x.id === 0) ? x.id : x) === obj.id);
    }
}


