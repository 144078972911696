<div class="messages-view" *ngFor="let msg of dashboardMessages" [ngClass]="{'messages-view__unread-bg':msg.viewed}">
  <dynamic-form *ngIf ="selectEnabled" [config]="config" #form="dynamicForm" (submit)="login($event)">
  </dynamic-form>
  <div class="messages-view__details">
  <div class="messages-view__header">
    <span class="intials-view" *ngIf="!msg.IsIncoming">YOU</span>
    <!-- <span *ngIf="msg.IsIncoming">{{dashboardData.Contact.FirstName | slice:0:1}}{{dashboardData.Contact.FirstName | slice:0:1}}</span> -->
  </div>
  <!-- <div class="msg-text"> -->

    <div class="messages-view__sender-view">
        <label class="messages-view__sender-name">{{msg.name}}</label>
        <i class="icon-Group-591 messages-view__close-message"></i>
      </div>
      <span class="messages-view__msg-text">{{msg.Text}} </span>
      <div class="messages-view__msg-actions">
        <!-- <button class="">rwerew</button>
        <button class="">werewr</button> -->
        
        <activities-app-button [buttontype]="buttonValueprimary" (click)='save($event)'></activities-app-button>
        <activities-app-button [buttontype]="buttonSecondary" (click)='close($event)'></activities-app-button>
        <!--<activities-app-button></activities-app-button>
        <activities-app-button></activities-app-button> 
        <activities-app-button [buttontype]="holdBtn" (click)='close($event)'></activities-app-button> -->
        <!-- <activities-app-button [buttontype]="cancelBtn" (click)='close($event)'></activities-app-button> -->
      </div>
      <label class="messages-view__msg-timing">{{msg.status}}</label>
</div>
    

  <!-- </div> -->
</div>









