import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material-module';
import { ReservationRoutingModule } from '@app/reservation/reservation-routing.module';
import { ExpandPanelWithShiftPipe, ReservationComponent, ShowTableAvailabilityPipe } from '@app/reservation/reservation.component';
import { SharedModule } from '@shared/shared.module';
import { NgPipesModule } from 'ngx-pipes';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { DataRetentionPoliciesComponent } from './data-retention-policies/data-retention-policies.component';
import { MultiPagingComponent } from './multi-paging/multi-paging.component';

@NgModule({
    declarations: [ReservationComponent, MultiPagingComponent, DataRetentionPoliciesComponent, ExpandPanelWithShiftPipe, ShowTableAvailabilityPipe],
    imports: [
        CommonModule,
        //RouterModule,
        ReservationRoutingModule,
        MaterialModule,
        NgScrollbarModule,
        SharedModule,
        NgPipesModule,
        VirtualScrollerModule
    ]
})
export class ReservationModule { }
