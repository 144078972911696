<section [formGroup]="dropDownFrmGrp" class="multiple-select-dropdown">
  <mat-form-field [floatLabel]="floatLabel">
    <mat-select [placeholder]="dropDownName" name="dropDownName" [formControlName]="dropDownControlname" multiple>
      <mat-checkbox class="mat-option reports-multiselect" (click)="$event.stopPropagation();" [disableRipple]="true"
        (change)="filterDropDownSelected($event, defaultData, dropDownFilterData, dropDownControlname)"
        [checked]="dropDownFrmGrp.controls[dropDownControlname].value.length  == dropDownFilterData.length">
        <span
          [ngClass]="{'text-color':dropDownFrmGrp.controls[dropDownControlname].value.length  == dropDownFilterData.length}">{{defaultData.description}}</span>
      </mat-checkbox>
      <ng-container *ngFor="let data of dropDownFilterData ">
        <mat-option class="multiselect" *ngIf="data.showInDropDown"
          (click)="filterDropDownSelected($event, data, dropDownFilterData, dropDownControlname)" [value]="data">
          {{data.description}}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</section>
