<lib-dialog-container [title]="data.title | translate" [showSubmit]="false" [showCancel]="false">
    <span title-icon class="icon-respos-help respos-ptx-color respos-medium-icon">
    </span>
    <div content class="respos-stp-mdl-ctr__content">
        {{data.message}}
    </div>
    <div footer class="respos-stp-mdl-ctr__footer">
        <button class="respos-btn respos-btn-basic" (click)="cancel()">
            {{data.cancelLabel}}
        </button>
        <button class="respos-btn respos-btn-fill" (click)="confirm()">
            {{data.saveLabel}}
        </button>
    </div>
</lib-dialog-container>