<div class="reservation-timeslot">
  <div class="reservation-timeslot__legend-details"
    [ngClass]="{'reservation-timeslot__waitlist-legend': !isReservationtypeReserve}">
    <div class="reservation-timeslot__selected-time">
      <span *ngIf="!isReservationtypeReserve && !isOpenBooking"
        class="reservation-select-label seat-panel-header">{{'PopUpSelectWaitTime'|translate}}</span>
      <span *ngIf="isOpenBooking"
        class="reservation-timeslot__slot-time seat-panel-header">{{'selectTimeSlot'|translate}}</span>
    </div>
    <div class="reservation-timeslot__shift-selection">
      <div class="reservation-timeslot__all-slots" *ngIf="isReservationtypeReserve" [hidden]="isOpenBooking">
        <dynamic-form [config]="config" #form="dynamicForm"></dynamic-form>
      </div>
    </div>
  </div>
  <div class="rs-mt-10 reservation-timeslot__timeslotAuto">
    <span *ngIf="!isReservationtypeReserve && timeArray[0] == ('auto' | translate)">
      <activities-app-button class="reservation-timeslot__autoButton" (click)="selectedTime(timeArray[0], true)" [buttontype]="timeArray[0] | buttonInputFormater :
    (timeArray[0] == partyService.reservationFormGroup.value.selectedTime ? buttonType.actionPrimary : buttonType.actionSecondary ):'reservation-timeslot__time_btn':(data && data.isPastReservation):
    (timeArray[0] == autoText ? seatTime : '') "> </activities-app-button>
    </span>
    <span class="reservation-timeslot__slot-type seat-tertiary-text" *ngIf="!isReservationtypeReserve">{{slotType}}</span>
    <span class="reservation-timeslot__wait-time d-block seat-header-two"
      *ngIf="partyService.totalWaitimeMinutes < 180 && !isReservationtypeReserve">{{selectedSlot}}</span>
    <span class="reservation-timeslot__wait-time d-block seat-header-two"
      *ngIf="!isReservationtypeReserve && partyService.totalWaitimeMinutes >= 180">{{partyService.totalWaitimeMinutes*
      60 | customWaitTime}}</span>
  </div>
  <!-- Waitlist time Selectiom -->
  <div class="reservation-timeslot__waitlist" *ngIf="!isReservationtypeReserve">

    <ng-scrollbar>
      <!-- Removed temporarily -->
      <!-- <div class="reservation-timeslot__seat text-center">
        <button mat-button class="reservation-timeslot__seat-now w-50" (click)="seatNow()" [disabled]="true">
          {{'partyStatusSeatText' | translate}}
        </button>
      </div>
      <div class="mt-4 mb-2 reservation-timeslot__time-divider">
        <h5><span>{{'orText' | translate}}</span></h5>
      </div> -->
      <div class="reservation-timeslot__slots-list">
        <ul class="m-auto text-center reservation-timeslot__button-slots p-0">
          <ng-container *ngFor="let time of timeArray; let i=index">
            <li *ngIf="i != 0 && i != timeArray?.length-1" class="d-inline">
              <activities-app-button (click)="selectedTime(time)" [buttontype]="time |  buttonInputFormater :
                (time == partyService.reservationFormGroup.value.selectedTime ? buttonType.actionPrimary : buttonType.actionSecondary ):'reservation-timeslot__time_btn':(data && data.isPastReservation):
                (time == autoText ? seatTime : '') "> </activities-app-button>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="reservation-timeslot__custom-wait-time">
        <dynamic-form [config]="customWaitTimeHoursConfig" #form="dynamicForm"></dynamic-form>
        <dynamic-form [config]="customWaitTimeMinutesConfig" #form="dynamicForm"></dynamic-form>
      </div>
    </ng-scrollbar>
  </div>

  <!-- Reservation Time selection -->
  <div class="reservation-timeslot__slotsDiv" [ngClass]="{'reservation-timeslot__slot-height': selectedSlot}"
    *ngIf="isReservationtypeReserve">
    <!-- <ng-scrollbar> -->
    <div *ngIf="(!partyService.allAvailableSlots || partyService.allAvailableSlots.length === 0)">
      {{'noSlotsAvailable' | translate}}
    </div>
    <ul *ngIf="cs.settings.value.General.SlottingMode == SlottingMode.Auto && !isOpenBooking"
      class="m-auto text-center reservation-timeslot__button-slots p-0">
      <li *ngFor="let item of partyService.allAvailableSlots;let i= index" class="d-inline">
        <div
          [ngClass]="{'selectedBtn': (item.DateTime == partyService.reservationFormGroup.value.selectedTime?.DateTime)}">
          <activities-app-button (valueChange)="(!item.IsOverRide)? selectedTimeReservation(item) : null" #autoslot="ngbPopover"
            popoverClass="overbookslots" (mouseenter)="openOverbookSlots(i)" (click)="openOverbookSlots(i)"
            data-container="body" triggers="manual" placement="auto" container="body"
            [ngbPopover]="(cs.settings.value.General.SlottingMode == SlottingMode.Auto && (item.IsOverRide && !item.IsDisabled)) ? disabledSlotActionPopup : null"
            [buttontype]="item.DateTime | slotTimeFormatter | buttonInputFormater :
              (item.DateTime == partyService.reservationFormGroup.value.selectedTime?.DateTime ? (tableSuggestionMode == TableSuggestionMode.NeverAssign && !item.IsDisabled) ? buttonType.actionPrimaryOverbook :  buttonType.actionPrimary : (tableSuggestionMode == TableSuggestionMode.NeverAssign && !item.IsDisabled) ? buttonType.actionSecondaryOverbook : buttonType.actionSecondary) :
                ((item.IsOverRide && !item.IsDisabled) ?  'reservation-timeslot__standbyButton': 'reservation-timeslot__time_btn'): (item.IsOverRide || item.IsDisabled || isPastReservation) : null">
          </activities-app-button>
          <ng-template #disabledSlotActionPopup>

            <activities-app-button [buttontype]="overbookButton" (valueChange)="overrideValues(item)"></activities-app-button>
            <activities-app-button [buttontype]="addToStandByButton" (valueChange)="addToStandBy(item)"></activities-app-button>
          </ng-template>
        </div>
      </li>
    </ul>
    <ul *ngIf="cs.settings.value.General.SlottingMode == SlottingMode.Auto && isOpenBooking"
      class="m-auto text-center reservation-timeslot__button-slots p-0">
      <li *ngFor="let item of partyService.allAvailableSlots;let i= index" class="d-inline slot-list">
        <div>
          <!-- [ngClass]="{'selectedBtn': (item.DateTime == partyService.reservationFormGroup.value.selectedTime?.DateTime)}"> -->
          <activities-app-button (valueChange)="SelectBookingReservationSlots(item,$event)" #autoslot="ngbPopover"
            (mouseenter)="openOverbookSlots(i)" container="body" placement="auto" popoverClass="overbookslots" 
            [ngbPopover]="( !partyService.selectedOpenBookingSlots[item.Id] && (item.IsOverbookAvailable || item.IsStandByBookingSlot || (cs.settings.value.General.SlottingMode == SlottingMode.Auto && (item.IsOverRide && !item.IsDisabled)))  ) ? disabledSlotActionPopup : null"
            [buttontype]="item.DateTime | slotTimeFormatter | buttonInputFormater :
              (partyService.selectedOpenBookingSlots[item.Id] ? ( (item?.IsStandByBookingSlot || item?.IsOverbookAvailable) ? buttonType.actionFullOrange : buttonType.actionPrimary) : ((item?.IsStandByBookingSlot || item?.IsOverbookAvailable) ? buttonType.actionSecondaryOrange : buttonType.actionSecondary)) :

              ((item.IsOverRide && !item.IsDisabled) ? 'reservation-timeslot__standbyButton': 'reservation-timeslot__time_btn'):
              (item.IsOverRide || item.IsDisabled ||  !((isOpenBooking && RolesAndPermissionsType.Bookatblockedlocation | rolesAndPermission ) || !item.AllocationTypeId || (partyService.selectedGuest && partyService.selectedGuest.MemberActive && ((item.AllocationTypeId == partyService.selectedGuest.MembershipType?.Id) || (item.AllocationName == 'AnyMembers' && partyService.selectedGuest.MembershipType?.Id) ))) || isPastReservation) : null : item.color">
          </activities-app-button>
          <ng-template #disabledSlotActionPopup>
            <activities-app-button *ngIf="item.IsOverbookAvailable " [buttontype]="overbookButton"
              (valueChange)="overrideValues(item,true,i)"></activities-app-button>
            <activities-app-button *ngIf="item.IsStandByBookingSlot" [buttontype]="addToStandByButton" (valueChange)="addToStandBy(item, true)"></activities-app-button>
          </ng-template>
        </div>
      </li>
    </ul>

    <ul *ngIf="cs.settings.value.General.SlottingMode == SlottingMode.Manual"
      class="m-auto text-center reservation-timeslot__button-slots p-0">
      <li *ngFor="let item of uniqueManualSlots;let i= index" class="d-inline">
        <div
          [ngClass]="{'selectedBtn': item.DateTime == partyService.reservationFormGroup.value.selectedTime?.DateTime}">
          <activities-app-button
            [buttontype]="item.DateTime | slotTimeFormatter | buttonInputFormater : 
                (item.DateTime == partyService.reservationFormGroup.value.selectedTime?.DateTime ? (tableSuggestionMode == TableSuggestionMode.NeverAssign  && !item.IsDisabled) ? buttonType.actionPrimaryOverbook : buttonType.actionPrimary : (tableSuggestionMode == TableSuggestionMode.NeverAssign && !item.IsDisabled) ? buttonType.actionSecondaryOverbook : buttonType.actionSecondary) :
                        ((item.slotCount <= 0 && !item.IsDisabled) ? 'reservation-timeslot__standbyButton' : 'reservation-timeslot__time_btn') : item.buttonInfo.disbaledproperity : null"
            (valueChange)="openManualSlot(i, item)"></activities-app-button>

        </div>
      </li>
    </ul>
    <!-- </ng-scrollbar> -->
  </div>
</div>
