import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reservationStatusColor'
})
export class ReservationStatusColorPipe implements PipeTransform {

  transform(item: any, value: any): any {
    let status;
    switch (item.State) {
      case 0:
        status = '#5093E1'; // reserved
        break;
      case 1:
        status = '#9CC62B'; // seated
        break;
      case 2:
        status = '#000000'; //noshow
        break;
      case 3:
        status = '#A1A6B4'; //cancelled
        break;
      case 4:
        status = '#A1A6B4';//cancelled
        break;
      case 5:
        status = '#C1832E'; //blocked
        break;
      case 6:
        status = '#000000'; //blocked
        break;
      case 7:
        status = '#1DA664'; // available
        break;
      case 8:
        status = '#99999bbf'; // Not available
        break;
      case 9:
        status = '#EB995B'; // standby
        break;
      case 10:
        status = '#e81212'; //filled
        break;
      case 11:
        status = '#ac4f99'; //overbook
        break;
    }
    return status;
    // if (!value) return  items;
    // if (value == '' || value == null) return [];
    // return items.filter(e => e.toLowerCase().indexOf(value) > -1 );
  }

}
