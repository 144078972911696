import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { HeadlineActivityType } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Validators } from '@angular/forms';
import { PopupService } from '@app/popup-module/popup.service';
import moment from 'moment';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';

@Component({
  selector: 'app-activity-headlines',
  templateUrl: './activity-headlines.component.html',
  styleUrls: ['./activity-headlines.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityHeadlinesComponent implements OnInit {
  headlinesConfig: FieldConfig[];
  @Input() data: any;
  activityData: any;
  @ViewChild('headlinesForm', { static: true }) headlinesForm: DynamicFormComponent;
  deleteMessage :string;
  isPublicHeadline :boolean = false;

  constructor(private ts:TranslateService, private partyService:PartyService,private datePipe: DatePipe, public ps: PopupService, public dashboardFunctions: DashboardFunctions) { }

  ngOnInit(): void {
    this.setConfig();
    this.activityData = this.data?.[0].activityDetails;
    this.isPublicHeadline = this.data?.[0].noteType == 'Public' ? true : false,
    console.log(this.data);
  }

  ngAfterViewInit(){
    this.partyService.headlinesForm = this.headlinesForm.form;
    this.ps.saveBtnEnable$.next(this.headlinesForm.form.valid && (this.headlinesForm.form.dirty || this.data?.[0].isCancel));
    if(this.data?.[0].headlines && this.data?.[0].isCancel == false){
      this.headlinesForm.form.controls.activityHeadlines.setValue(this.data[0].headlines.Description);
    }
    this.headlinesForm.form.valueChanges.subscribe(data =>{
      this.ps.saveBtnEnable$.next(this.headlinesForm.form.valid);
    })
  }

  setConfig(): void {
    this.headlinesConfig = []; 
    if(!this.data?.[0].isCancel){
      this.headlinesConfig = [
        {
          type: 'textarea',
          name: 'activityHeadlines',
          placeholder: this.ts.instant('enterNotesText'),
          label: this.ts.instant('enterNotesText'),
          rows: 3,
          inputType: 'text',
          textAreaAppearance: true,
          class: "activity-headlines__notes-section",
          validation: [Validators.required],
          disableErrorStateMatcher: true
        }]
    }else{ 
        let start  = moment(this.data?.[0].activityDetails.startTime).locale(this.dashboardFunctions.getLocaleCode()).format('hh:mm a');
        let end = moment(this.data?.[0].activityDetails.endTime).locale(this.dashboardFunctions.getLocaleCode()).format('hh:mm a');
        let activityName = this.data?.[0].activityDetails.Name
        this.ts.get('deleteHeadLineConfirmationMessage',{activityName,start,end}).subscribe(msgVal =>{
          this.deleteMessage = msgVal;
        });
    }
    this.headlinesConfig.push( {
        type: 'radio', 
        name: 'headlineType',
        placeholder: '',
        options: [
          { id: HeadlineActivityType.OnlyThis, value: this.ts.instant('allowOneSession') },
          { id: HeadlineActivityType.AllRemaining, value: this.ts.instant('allowAllSession') }
        ],
        value: (this.data[0].headlines && this.data[0].headlines.ApplyForAllSessions) ? HeadlineActivityType.AllRemaining : HeadlineActivityType.OnlyThis,
        class: 'activity-headlines__session-selection',
      }
    )
  }


}
