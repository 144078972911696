import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { buttonTypes } from '@constants/commonenums';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { PopupService } from '@popup-module/popup.service';

@Component({
  selector: 'app-reservation-times-popup',
  templateUrl: './reservation-times-popup.component.html',
  styleUrls: ['./reservation-times-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReservationTimesPopupComponent implements OnInit {
  public timesList: any[];
  public dialogTitle: string;
  hideTitle: boolean;
  @Input() data: any;
  buttonValueOk: ButtonValue;
  isInitial = true;

  constructor(private ps: PopupService) {

  }

  ngOnInit() {
    this.timesList = this.data;
    this.dialogTitle = "Times";
    this.buttonValueOk = {
      type: buttonTypes.actionSecondary,
      label: 'ok',
    };
  }
  close(eve) {
    this.ps.closeDialog$.next();
  }
  submit() {
  }

}
