import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RetailLocalization } from '../../common/localization/retail-localization';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { BaseResponse, RetailCategoryExcelModel } from '../../shared/business/shared.modals';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';
import { CSVGenerator } from '../../shared/business/CSV-Generator.service';
import { FinancialBinType } from '../../shared/business/FinancialBin-business';
import { PropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import { MiscellaneousSwitch } from '../../shared/globalsContant';

@Component({
    selector: 'app-retail-categories',
    templateUrl: './retail-categories.component.html',
    styleUrls: ['./retail-categories.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RetailCategoriesComponent implements OnInit {

    tableoptions: any[];
    currIndex: any;
    IsViewOnly: boolean;
    type: any;
    retailCategoryform: UntypedFormGroup;
    searchText = '';
    isEditAction = false;
    actionText = ""
    CategoryGroups: any;
    Categories: any;
    editRow: any;
    captions: any;
    customEditCancelled: boolean;
    isViewOnly = false;
    onEditDisableFunction: boolean = true;
    // isEditFlag is used commonly toggle searchbar on edit and inline edit
    isEditFlag: boolean = false;
    // inlineEditOn is used to disable the form fields, update, cancel buttons on inline edit
    inlineEditOn: boolean = false;
    catGrpName: any;
    userRetailInterface: boolean = false;
    ActiveCategoryGroups: any;
    categoryElements: any[];
    floatLabel: string;
    floatLabelNever: string;
    showInHouseGratuityToggle = false;
    showInHouseSCToggle = false;
    constructor(
        private retailSetup: RetailSetupService
        , public _featureSwitch: RetailFeatureFlagInformationService
        , private propertyInfo: RetailPropertyInformation
        , private fb: UntypedFormBuilder
        , private localization: RetailLocalization
        , private http: HttpServiceCall
        , private utils: RetailUtilities
        , private _csvGenerator: CSVGenerator
        , private propertySettingsDataService: PropertySettingDataService
    ) {
        this.captions = this.localization.captions.retailsetup;
        this.actionText = this.captions.ADD;
        this.userRetailInterface = this.propertyInfo.UseRetailInterface;
        this.floatLabel = this.localization.setFloatLabel;
        this.floatLabelNever = this.localization.setFloatLabelNever;
        this.retailCategoryform = fb.group({
            categoryGroup: '',
            categoryName: ['', [Validators.required, ]],
            eligibleForDiscount: false,
            isActive: false,
            allowEarn: false,
            revenueCategoryIdFromExternalInterface: '',
            eligibleForInHouseGratuityCalculation: false,
            eligibleForInHouseServiceChargeCalculation: false
        })
    }

    ngOnInit() {
        this.GetAllCategoryGroups();
        this.GetAllActiveCategoryGroups();

        this.CheckForInHouseCalculationConfig();
        
        this.IsViewOnly = this.retailSetup.retailSetupBreakPoints.find(bp => bp.breakPointNumber == myGlobals.RetailBreakPoint.RetailCategories).view;

        if (this.IsViewOnly) {
            this.utils.disableControls(this.retailCategoryform);
        }
    }

    async CheckForInHouseCalculationConfig() {
        let houseServiceChargePercentage: string = "0.00";
        let houseGratuityPercentage: string = "0.00";
        this.showInHouseGratuityToggle = false;
        this.showInHouseSCToggle = false;

        let miscSettings = await this.propertySettingsDataService.GetMiscConfigurationSetting();
        let houseServiceChargePercentageSettingsValue = miscSettings.find(f => f.switch == MiscellaneousSwitch.HOUSE_SERVICECHARGE_PERCENTAGE);
        if(houseServiceChargePercentageSettingsValue){
            houseServiceChargePercentage = String(houseServiceChargePercentageSettingsValue.value);
            if(Number(houseServiceChargePercentage) > 0)
                this.showInHouseSCToggle = true;
        }
        let houseGratuityPercentageSettingsValue = miscSettings.find(f => f.switch == MiscellaneousSwitch.HOUSE_GRATUITY_PERCENTAGE);
        if(houseGratuityPercentageSettingsValue){
            houseGratuityPercentage = String(houseGratuityPercentageSettingsValue.value);
            if(Number(houseGratuityPercentage) > 0)
                this.showInHouseGratuityToggle = true;
        }
    }

    checkForMinValidation(categoryName): boolean {
        let valid: boolean = true;
        if ((categoryName.trim().length < 2)) {
            let errMsg: string = this.localization.getError(10712);
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
            valid = false;
        }
        return valid;
    }
    addCategory() {
            if (this.isEditAction) {
                let newCategoryObj = {
                    "name": this.retailCategoryform.controls['categoryName'].value.trim(),
                    "retailCategoryGroupId": this.retailCategoryform.controls['categoryGroup'].value ? this.retailCategoryform.controls['categoryGroup'].value : 0,
                    "isActive": this.retailCategoryform.controls['isActive'].value,
                    "listOrder": this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
                    "eligibleForDiscount": this.retailCategoryform.controls['eligibleForDiscount'].value,
                    "eligibleForInHouseGratuityCalculation": this.retailCategoryform.controls['eligibleForInHouseGratuityCalculation'].value,
                    "eligibleForInHouseServiceChargeCalculation": this.retailCategoryform.controls['eligibleForInHouseServiceChargeCalculation'].value,
                    "allowEarn": this.retailCategoryform.controls['allowEarn'].value,
                    "revenueCategoryIdFromExternalInterface": this.retailCategoryform.controls['revenueCategoryIdFromExternalInterface'].value ? Number(this.retailCategoryform.controls['revenueCategoryIdFromExternalInterface'].value) : 0,
                }
                this.http.CallApiWithCallback({
                    host: myGlobals.Host.retailManagement,
                    success: this.successCallback.bind(this),
                    error: this.errorCallback.bind(this),
                    callDesc: "UpdateCategory",
                    uriParams: { id: this.tableoptions[0].TablebodyData[this.currIndex].id },
                    method: HttpMethod.Put,
                    body: newCategoryObj,
                    showError: true,
                    extraParams: []
                });
            }
            else {

                let listOrderArray = this.tableoptions && this.tableoptions[0].TablebodyData && this.tableoptions[0].TablebodyData.map(e => e.listOrder);
                let maxListOrder = listOrderArray && listOrderArray.length > 0 ? Math.max(...listOrderArray) : 0;
                console.log(this.retailCategoryform.value);
                let newCategoryObj = {
                    "name": this.retailCategoryform.controls['categoryName'].value.trim(),
                    "retailCategoryGroupId": this.retailCategoryform.controls['categoryGroup'].value ? this.retailCategoryform.controls['categoryGroup'].value : 0,
                    "isActive": true,
                    "listOrder": maxListOrder + 1,
                    "eligibleForDiscount": this.retailCategoryform.controls['eligibleForDiscount'].value ? this.retailCategoryform.controls['eligibleForDiscount'].value : false,
                    "eligibleForInHouseGratuityCalculation": this.retailCategoryform.controls['eligibleForInHouseGratuityCalculation'].value ? this.retailCategoryform.controls['eligibleForInHouseGratuityCalculation'].value : false,
                    "eligibleForInHouseServiceChargeCalculation": this.retailCategoryform.controls['eligibleForInHouseServiceChargeCalculation'].value ? this.retailCategoryform.controls['eligibleForInHouseServiceChargeCalculation'].value : false,
                    "allowEarn": this.retailCategoryform.controls['allowEarn'].value ? this.retailCategoryform.controls['allowEarn'].value : false.valueOf,
                    "revenueCategoryIdFromExternalInterface": this.retailCategoryform.controls['revenueCategoryIdFromExternalInterface'].value ? Number(this.retailCategoryform.controls['revenueCategoryIdFromExternalInterface'].value) : 0,
                };

                this.http.CallApiWithCallback({
                    host: myGlobals.Host.retailManagement,
                    success: this.successCallback.bind(this),
                    error: this.errorCallback.bind(this),
                    callDesc: "CreateCategory",
                    method: HttpMethod.Post,
                    body: newCategoryObj,
                    showError: true,
                    extraParams: []
                });
            }
    }
    EditRecords(data?: any) {
        console.log(data[0]);
        this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == data[0].id);
        this.isEditAction = true;
        this.actionText = this.captions.UPDATE;
        this.retailCategoryform.patchValue(data[0]);
        this.editRow = data[0];
        this.customEditCancelled = false;
        this.isEditFlag = true;
    }
    resetValues() {
        this.actionText = this.captions.ADD;
        this.isEditAction = false;
        this.retailCategoryform.reset();
        this.customEditCancelled = true;
        this.isEditFlag = false;
    }
    DeleteRecords(event: any) {

        const id = 1;
        this.http.CallApiWithCallback({
            host: myGlobals.Host.retailManagement,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: "DeleteCategory",
            uriParams: { id: event[0].id },
            method: HttpMethod.Delete,
            showError: true,
            extraParams: []
        });
    }

    dragDrop(e: any) {
        const dragIndex = e[0];
        const dropIndex = e[1];
        const settingBelongsTo = e[2];
        let uriParam: any = dragIndex.toString() + "/" + dropIndex.toString();
        this.http.CallApiWithCallback({
            host: myGlobals.Host.retailManagement,
            success: this.successCallback.bind(this),
            error: this.errorCallback,
            callDesc: "CategoryDragDrop",
            uriParams: uriParam,
            method: HttpMethod.Patch,
            showError: true,
            extraParams: settingBelongsTo
        });
        console.log('dropped', e);
    }

    Done(event) {
        console.log(event);
        this.isEditFlag = false;
        this.inlineEditOn = false;
        this.enableFormElements(this.inlineEditOn);
        this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == event.id);
        const categoryGroupObj = {
            "name": this.tableoptions[0].TablebodyData[this.currIndex].categoryName,
            "id": this.tableoptions[0].TablebodyData[this.currIndex].id,
            "isActive": this.tableoptions[0].TablebodyData[this.currIndex].isActive,
            "listOrder": this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
            "eligibleForDiscount": this.tableoptions[0].TablebodyData[this.currIndex].eligibleForDiscount,
            "eligibleForInHouseGratuityCalculation": this.tableoptions[0].TablebodyData[this.currIndex].eligibleForInHouseGratuityCalculation,
            "eligibleForInHouseServiceChargeCalculation": this.tableoptions[0].TablebodyData[this.currIndex].eligibleForInHouseServiceChargeCalculation,
            "retailCategoryGroupId": this.tableoptions[0].TablebodyData[this.currIndex].categoryGroup,
            "allowEarn": this.tableoptions[0].TablebodyData[this.currIndex].allowEarn,
            "revenueCategoryIdFromExternalInterface": this.tableoptions[0].TablebodyData[this.currIndex].revenueCategoryIdFromExternalInterface,
        };
        this.http.CallApiWithCallback({
            host: myGlobals.Host.retailManagement,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: "UpdateCategory",
            uriParams: { id: this.tableoptions[0].TablebodyData[this.currIndex].id },
            method: HttpMethod.Put,
            body: categoryGroupObj,
            showError: true,
            extraParams: []
        });
    }

    clearSearchText() {
        this.searchText = "";
    }

    GetAllActiveCategoryGroups() {
        this.http.CallApiWithCallback<any>({
            host: myGlobals.Host.retailManagement,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: "GetAllActiveCategoryGroups",
            method: HttpMethod.Get,
            showError: true,
            uriParams: { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
            extraParams: []
        });
    }

    GetAllCategoryGroups() {
        this.http.CallApiWithCallback<any>({
            host: myGlobals.Host.retailManagement,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: "GetAllCategoryGroups",
            method: HttpMethod.Get,
            showError: true,
            uriParams: { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
            extraParams: []
        });
    }

    GetAllCategories() {
        this.http.CallApiWithCallback<any>({
            host: myGlobals.Host.retailManagement,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: "GetAllCategories",
            method: HttpMethod.Get,
            showError: true,
            uriParams: { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
            extraParams: []
        });
    }

    successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        this.utils.ToggleLoaderWithMessage(true,this.captions.lbl_processing)
        if (callDesc == "GetAllActiveCategoryGroups") {
            let response = <any>result.result;
            this.ActiveCategoryGroups = [];
            response.forEach(element => {
                let CategoryGroup: any = {
                    id: element.id,
                    name: element.name
                }
                this.ActiveCategoryGroups.push(CategoryGroup);
            });
            this.ActiveCategoryGroups.unshift({ id: 0, description: '' });
            this.GetAllCategories();
        }
        if (callDesc == "GetAllCategoryGroups") {
            let response = <any>result.result;
            this.CategoryGroups = [];
            response.forEach(element => {
                let CategoryGroup: any = {
                    id: element.id,
                    name: element.name
                }
                this.CategoryGroups.push(CategoryGroup);
            });
        }
        else if (callDesc == "GetAllCategories" || "CreateCategory" || "UpdateCategory" || "DeleteCategory") {
            let response = <any>result.result;
            this.Categories = [];
            response.forEach(element => {
                this.catGrpName = this.CategoryGroups && this.CategoryGroups.filter(x => x.id == element.retailCategoryGroupId);
                let Category: any = {
                    id: element.id,
                    categoryName: element.name,
                    categoryGroup: element.retailCategoryGroupId,
                    categoryGroupName: this.catGrpName.length > 0 ? this.catGrpName[0].name : "",
                    isActive: element.isActive,
                    listOrder: element.listOrder,
                    eligibleForDiscount: element.eligibleForDiscount,
                    eligibleForInHouseGratuityCalculation: element.eligibleForInHouseGratuityCalculation,
                    eligibleForInHouseServiceChargeCalculation: element.eligibleForInHouseServiceChargeCalculation,
                    allowEarn: element.allowEarn,
                    revenueCategoryIdFromExternalInterface: element.revenueCategoryIdFromExternalInterface
                }
                this.Categories.push(Category);
                if (this.Categories)
                    this.categoryElements = this.Categories;
            });        
            this.bindToGrid();
            if(callDesc === "DeleteCategory")
            this.categoryElements=this.Categories;
        }
        this.utils.ToggleLoader(false);
    }

    errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        console.error(error.result);
    }

    checkRevenueCategoryId(event) {
        var value = Number(this.retailCategoryform.controls['revenueCategoryIdFromExternalInterface'].value);
        if(this.categoryElements != [] && this.categoryElements != undefined)
        {
        var result = this.categoryElements.find(x => x.revenueCategoryIdFromExternalInterface === value);
        if (result && result.revenueCategoryIdFromExternalInterface != 0) {
            this.retailCategoryform.controls['revenueCategoryIdFromExternalInterface'].setErrors({ invalid: true });

        }
        }
    }

    omit_special_char(event) {
        var k;
        k = event.charCode;
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }

    sliderChange(event) {
        this.isEditFlag = true;
        this.inlineEditOn = true;
        this.disableFormElements(this.inlineEditOn);
    }

    Cancel(event) {
        this.isEditFlag = event;
        this.inlineEditOn = false;
        this.enableFormElements(this.inlineEditOn);
    }

    disableFormElements(isEditFlag) {
        this.retailCategoryform.controls['categoryGroup'].disable();
        this.retailCategoryform.controls['categoryName'].disable();
    }

    enableFormElements(isEditFlag) {
        this.retailCategoryform.controls['categoryGroup'].enable();
        this.retailCategoryform.controls['categoryName'].enable();
    }
    bindToGrid() {
            this.tableoptions = [
                {
                    TableHdrData: this.getHeaderOptions(),
                    TablebodyData: this.Categories,
                    pagination: false,
                    sortable: true,
                    CustomColumn: true,
                    PlaceHoldertext: this.captions.Search,
                    EnableActions: true,
                    SelectRows: true,
                    IsCommission: true,
                    Searchable: false,
                    EditMoreOption: false,
                    Sortable: "listOrder",
                    TableId: myGlobals.GridType.retailCategories,
                    SelectedSettingId: myGlobals.GridType.retailCategories,
                    disableDelete: false,
                    customHeader: false,
                    pageTitle: 'RetailCategories',
                    ServiceId: 'RetailCategories',
                    TableDraggable: true,
                    IsViewOnly: this.IsViewOnly,
                    automationId: 'retailCategories'
                }
            ];

        this.resetValues();
    }

    private getHeaderOptions() {

        let header: any[] = [
            { "title": this.captions.category, "jsonkey": "categoryName", 'alignType': 'left', "sortable": true },
            { "title": this.captions.categoryGroup, "jsonkey": "categoryGroupName", 'alignType': 'left', "sortable": true, "searchable": false }];

        if (this.propertyInfo.UseRetailInterface) {
            header.push(
                { "title": this.captions.RevenueCategoryId, "jsonkey": "revenueCategoryIdFromExternalInterface", 'alignType': 'right', "sortable": true, "searchable": false },
            );
        }

        header = [...header, ...[
            { "title": this.captions.ListOrder, "jsonkey": "listOrder", 'alignType': 'right', "sortable": true, "searchable": false },
            { "title": this.captions.Active, "jsonkey": "isActive", 'alignType': 'left', "sortable": true, "type": "toggle" },
            { "title": this.captions.Eligiblefordiscount, "jsonkey": "eligibleForDiscount", 'alignType': 'left', "sortable": true, "type": "toggle" }
        ]];

        if(this.showInHouseGratuityToggle)
        {
            header = [...header, ...[
                { "title": this.captions.lbl_EligibleForInhouseGratuity, "jsonkey": "eligibleForInHouseGratuityCalculation", 'alignType': 'left', "sortable": true, "type": "toggle" }
            ]];
        }

        if(this.showInHouseSCToggle)
        {
            header = [...header, ...[
                { "title": this.captions.lbl_EligibleForInhouseServiceCharge, "jsonkey": "eligibleForInHouseServiceChargeCalculation", 'alignType': 'left', "sortable": true, "type": "toggle" }
            ]];
        }

        if (this._featureSwitch.AllowAccrualPoints) {
            header.push(
                { "title": this.captions.AllowEarn, "jsonkey": "allowEarn", 'alignType': 'left', "sortable": true, "type": "toggle" },
            );
        }

        return header;
    }


    DownloadList() {
        if (this.Categories?.length > 0) {
            const fileName = `${this.propertyInfo.GetPropertyInfoByKey("PropertyName")}_RetailCategoryList`;
            let categoryExportData: RetailCategoryExcelModel[] = [];
            const binTypes = [
                FinancialBinType.Sales
                , FinancialBinType.Discount
                , FinancialBinType.Tax
                , FinancialBinType.Gratuity
                , FinancialBinType.ServiceCharge
            ]
            const binNameTypeMap = {
                [FinancialBinType.Sales]: "Sales",
                [FinancialBinType.Discount]: "Discount",
                [FinancialBinType.Tax]: "Tax",
                [FinancialBinType.Gratuity]: "Gratuity",
                [FinancialBinType.ServiceCharge]: "ServiceCharge",
            }
            this.Categories.map(c => {
                binTypes.map(b => {
                    const categorydata: RetailCategoryExcelModel = {
                        TypeId: c.id,
                        TypeName: c.categoryName,
                        ClassId: binNameTypeMap[b]
                    };
                    categoryExportData.push(categorydata);
                });
            })
            this._csvGenerator.downloadFile(categoryExportData, fileName);
        }
    }
}
