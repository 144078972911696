export class TimeRangeDTO {
    public Start: Date;
    public End: Date;
    public Reason: string;
    //public RestaurantId?: number;
}

export class ReportTimeRangeDTO {
    public Start: string;
    public End: string;
}

export class newTimeRangeDTO {
    public Start: string;
    public End: string;
    public Reason?: string;
    //public RestaurantId?: number;
}
export class TimeLineDTO{
    public Payload: PayloadDTO;
}
export class PayloadDTO {
    public Date: string;
    public Shifts: ShiftDTO[];


}[];
export class ShiftDTO {
    public Reservations:ReservationsDTO;
}[];

export class ReservationsDTO {
    public State: string;
    public TableNames: string[];
    public SeatingTime: string;
    public DepartureTime: string;

}