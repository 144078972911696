import { Pipe, PipeTransform } from '@angular/core';
import { PartyState } from '@constants/commonenums';
import { PartyService } from '@services/party.service';

@Pipe({
  name: 'partyStatusFilterPipe'
})
export class PartyStatusFilterPipePipe implements PipeTransform {

  constructor(public ps: PartyService) {
  }

  transform(allParties: any, isNoShowSelected: boolean, isSeatedSelected: boolean, isReservedSelected: boolean, isClearedSelected: boolean, isPartyrefreshed: number, isCancelledSelected?:boolean): any {
    return allParties.filter(party => {
      let showParty = false;
      if (isNoShowSelected && party.State == PartyState.NoShowed) {
        showParty = true;
      }
      if (isSeatedSelected && party.State == PartyState.Seated) {
        showParty = true;
      }
      if (isReservedSelected && party.State == PartyState.Pending) {
        showParty = true;
      }
      if (isClearedSelected && party.State == PartyState.Left) {
        showParty = true;
      }
      if(isCancelledSelected && party.State == PartyState.Cancelled){
        showParty = true;
      }
      if (!showParty && this.ps.selectedParty$.value && this.ps.selectedParty$.value.Id == party.Id) {
        this.ps.selectedParty$.next(null);
      }
      return showParty;
    });
  }

}
