<section class="retailDiscount retailDiscountWrapper newVersionWrapper">
      <div class="header">
        <div class="leftSection pl-4">
          <mat-form-field class="pt-4 spa-searchbox custSearchbox" [floatLabel]="floatLabelNever">
            <input [attr.automationId]="'Txt_retailDiscountConfiguration_search'" matInput autocomplete="off" class="serachBoxWidth" [(ngModel)]="searchText" type="text" placeholder="{{captions.SearchByCategory}}" [disabled]="userEditing" >
            <i  aria-hidden="true" *ngIf="searchText==''" class="icon-Search" [ngClass]="userEditing?'disableIcon':''" (click)="searchText='';"></i>
            <i  aria-hidden="true" *ngIf="searchText != '' " class="icon-Cancel" [ngClass]="userEditing?'disableIcon':''" (click)="searchText='';"></i>
          </mat-form-field>
        </div>
        <div class="rightSection" [ngClass]="IsViewOnly ? 'button_invalid' : ''" >
          <mat-form-field class="selectPadding selectWidth" [floatLabel]="floatLabel">
            <mat-select [attr.automationId]="'Dd_retailDiscountConfiguration_selected'" [placeholder]="captions.Select"  [disabled]="userEditing || !(discountConfigData.length>0)" [(ngModel)]="selectedFieldID" id="selectHeader" (selectionChange)="selectedField($event.value)">
                <mat-option *ngFor='let Header of header' [value]='Header'>{{Header.type}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="ml-4 spa-input" [floatLabel]="floatLabelNever">
            <input [attr.automationId]="'Txt_retailDiscountConfiguration_applyValue'" matInput autocomplete="off" class="serachBoxWidth" type="text"  value="" [disabled]="userEditing" [(ngModel)]="applyValue" RetailCurrencyFormatter [preDecimalLength]="maxLength" (input)="checkValid()" >
          </mat-form-field>
          <button [attr.automationId]="'Btn_retailDiscountConfiguration_apply'" mat-raised-button id="Apply"  [ngClass]="!applyValid || userEditing ?'disableClass':''" class="ml-4 mr-2 body-bordercolor LW14 text-capital sec-btn" (click)="applyFunc()">{{localization.captions.setting.Apply}}</button>
          <div class="emptyDiv"></div>
          <button [attr.automationId]="'Btn_retailDiscountConfiguration_update'" mat-raised-button id="Update"  [ngClass]="updateDisable?'disableClass':''"  class="ml-2 mr-4 btn btn-default buttonLineHeight body-bgcolor whitecolor LW14 text-capital sec-btn" (click) = "UpdateAppliedValues()">{{localization.captions.common.UPDATE}}</button>
        </div>

      </div>
      <div class="dataList">
            <app-retail-table-inline-edit [EnableEdit]="editData" [disableActions]="disableEditButton" [options]="tableoptions" [searchString]="searchText" (DeleteData)="deletedRecord($event)" (userEditing)="userEdit($event)"  (doneEvtEmitter)="Done($event)"></app-retail-table-inline-edit>
      </div>

</section>
