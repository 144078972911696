import { HeartBeatRequestDto } from '@models/SignalRDto';

declare function postMessage(message: any): void;

export function sendHeartBeat(input: HeartBeatRequestDto) {

  enum HttpVerbs {
    GET, POST, PUT, DELETE
  }

  let message: HeartBeatRequestDto = input;
  console.log("SignalR message has been received by Heartbeat handler worker Service !")

  if (message.CommonParams.RestaurantId == message.CommonParams.RestaurantId) {
    const baseUrl: string = message.CommonParams.BaseUrl + "?restaurantid=" + message.CommonParams.RestaurantId + '&deviceId=' + message.DeviceId;
    Update(baseUrl, message, GetCallback);
  }

  function PostMessage(change: any, updatedSettings: any) {
    var response = {
      ChangeSet: change,
      UpdatedSettings: updatedSettings,
    };

    postMessage(response);
  }

  /****** CALLS API */
  function Update(baseUrl: string, message: HeartBeatRequestDto, GetCallback: any): any {
    return callWebApi(baseUrl, HttpVerbs.POST, message, GetCallback, null);
  }


  function GetCallback(data): void {
    console.log("Web worker is about to post message !");
    PostMessage(data, null);
  }

  function callWebApi(url: string, verb: HttpVerbs, message: HeartBeatRequestDto, callback: any, data: any): void {

    let xhr = new XMLHttpRequest();

    xhr.onload = function () {
      let data = JSON.parse(xhr.responseText);
      callback(data);
    }

    xhr.onerror = function () {
      alert("Error while calling Web API");
    }

    let httpVerb: string;
    switch (verb) {
      case HttpVerbs.GET:
        httpVerb = "GET";
        break;
      case HttpVerbs.POST:
        httpVerb = "POST";
        break;
      case HttpVerbs.PUT:
        httpVerb = "PUT";
        break;
      case HttpVerbs.DELETE:
        httpVerb = "DELETE";
        break;
    }

    xhr.open(httpVerb, url);
    xhr.setRequestHeader('x-ts-client-uid', message.CommonParams.ClientUid)
    xhr.setRequestHeader('x-ts-merchant-auth-key', message.CommonParams.MerchantKey)
    xhr.setRequestHeader('x-ts-restaurant-api-key', message.CommonParams.RestaurantApiKey)
    xhr.setRequestHeader('x-ts-host-id', message.CommonParams.HostId)
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("ngsw-bypass","");

    if (data == null) {
      xhr.send();
    }
    else {
      xhr.send(JSON.stringify(data));
    }
  }
}
