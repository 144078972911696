import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '@app/app.service';
import { StandaloneTableDTO } from '../models/RestaurantDTO';

@Pipe({
  name: 'excludeLocationGroup'
})
export class ExcludeLocationGroupPipe implements PipeTransform {
  constructor() { }
  transform(locations: StandaloneTableDTO[] = []): any {
    return locations?.filter(location => !location.LocationGroupId || location.IsTemplate);
  }
}
