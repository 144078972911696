<div class="filter-content">
  <div class="filter-content__options">
    <span class="filter-content__filterMsg seat-tertiary-text" *ngIf="isMessageAvailable">{{'displayFilterErrortext' | translate}}</span>
    <ul class="p-0 m-0 filter-content__unstyled-list">
      <li (click)="propertySelectionChanged(item)" *ngFor="let item of displayOptions" [ngClass]="item.class">
        <svg *ngIf="!item.isSelected" class="checkmark" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="gray" />
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
        <svg *ngIf="item.isSelected" class="checkmark" viewBox="0 0 52 52">
          <circle class="checkmark__circle checkmark__selected" cx="26" cy="26" r="25" fill="none" />
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
        <span class="pl-2 seat-secondary-text">{{item.value | translate}}</span>
      </li>
    </ul>
  </div>
</div>