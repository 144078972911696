<div class="date-range-selection ">
  <div class="date-range-selection__date-details">
    <div class="date-range-selection__date-view"  >
    <div  style="height: 80%;">
      <span
      class="date-range-selection__select-text reservation-select-label seat-panel-header">{{'reservationSelectDate'| translate}}</span>
      <div *ngIf="inlineRange" >
     <!--  <span class="date-range-selection__select-day">{{inlineRange.begin | localizedDate: 'dddd'}}</span> -->
      <span class="date-range-selection__select-date seat-header-two">{{inlineRange.begin | localizedDate: 'MMM DD'}} {{getDateVal(inlineRange.begin) | lowercase }}</span>
      <span class="date-range-selection__select-day seat-header-three" >{{'toText' | translate}}</span>
      <!-- <span class="date-range-selection__select-day">{{inlineRange.end | localizedDate: 'dddd'}}</span> -->
      <span class="date-range-selection__select-date seat-header-two">{{inlineRange.end | localizedDate: 'MMM DD'}} {{getDateVal(inlineRange.end) | lowercase }}</span>
    </div>
    </div>
    <div>

    </div>
    </div>
    <div class="date-range-selection__calendar-view">
     <mat-error class="errorblink seat-error-messages" *ngIf="inlineRange && !dateiserror"> <span class="icon-notification"></span> {{'endDateError' | translate}}</mat-error>
     <div>
        <!-- <sat-calendar [rangeMode]="true" (dateRangesChange)="inlineRangeChange($event)" [minDate]='' [maxDate]='maxdate' [beginDate]='inlineRange?.begin' [startAt]='startdate' [endDate]='inlineRange?.end' [selected]="selectedDate">
        </sat-calendar> -->
        <app-date-selection-with-range (dateRangesChange)="inlineRangeChange($event)" [selected]="inlineRange"></app-date-selection-with-range>
      </div>
      <mat-error class="date-range-selection__error seat-error-messages"> {{'dateRangeSelection' | translate}}</mat-error>
    </div>
  </div>
</div>