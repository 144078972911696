import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PopupService } from '@app/popup-module/popup.service';
import { BookingGuestType, BookingGuestTypeConfig } from '@app/shared/constants/globalConstants';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-booking-type-configuration',
  templateUrl: './booking-type-configuration.component.html',
  styleUrls: ['./booking-type-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingTypeConfigurationComponent implements OnInit {
  configuration: FieldConfig[];
  bookingGuestType = BookingGuestType;
  @Input() data; 
  @ViewChild('bookingTypeSetting', { static: true }) bookingTypeSetting: DynamicFormComponent;
  constructor(private popupService: PopupService,public dialogRef: MatDialogRef<BookingTypeConfigurationComponent>,private ts:TranslateService) { }

  ngOnInit(): void {
    this.loadBookingTypeSettingConfig();

  this.popupService.cancelledAction$.subscribe(cancelled => {
    this.dialogRef.close({ action: "cancelled" });
})
this.popupService.confirmedAction$.subscribe(confirmed => {
    this.dialogRef.close({ action: "confirmed", data: this.bookingTypeSetting?.form?.value });
})
  }

  loadBookingTypeSettingConfig() {
    let data = this.data.bookingType;
    this.configuration = [
      {
        type: 'select',
        name: 'BookingGuestType',
        label: 'categoryText',
        options: BookingGuestTypeConfig,
        class: '',
        showErrorText: true,
        appearance: false,
        isTranslate: true,
        value: data ? data.BookingGuestType: BookingGuestType.None,
        cellClick: this.updateCategorySetting.bind(this),
        isReadOnly: true
      },
      {
        type: 'checkbox',
        placeholder: this.ts.instant('isDefault'),
        class: "",
        value: data ? data.IsDefault : false,
        name: 'IsDefault',
      },
      {
        type: 'input',
        name: 'AdvanceDays',
        inputType: 'text',
        label: 'daysInAdvance',
        class:"pt-3 advance-days",
        value: data ? data.AdvanceDays: '',
        directive : 'digitOnly'
      }
      // {
      //    type: 'textarea',
      //    name: 'Description',
      //    label: this.isTemplate ? this.ts.instant('TemplateDescription') : this.ts.instant('Description'),
      //    inputType: 'text',
      //    showHint: true,
      //    charLength: 1000,
      //    value: this.mealData?.Description || '',
      //    appearance: false,
      //    class: 'activity-description'
      // },
     
    ]
    if(data){
      this.updateCategorySetting({value:data?.BookingGuestType})
    }

  }

  updateCategorySetting(category){
    if(category.value == BookingGuestType.HotelGuest){
      let stayRange = 
        
          {
            type: 'checkbox',
            placeholder: this.ts.instant('bookWithStay'),
            value: this.data.bookingType ? this.data.bookingType.IsWithinStayRange : false,
            name: 'IsWithinStayRange',
            class: "pt-2",
            icon: "icon-help_question pl-1",
            hintText: this.ts.instant('stayTextHelper'),
            hint: true
          };
        
      
    
    this.configuration.splice(2,0,stayRange)
  }
  else{
    let stayRangeIndex = this.configuration.findIndex(config=> config.name == 'IsWithinStayRange')
    if(stayRangeIndex != -1)
    this.configuration.splice(stayRangeIndex,1)
  }
  }
}
