import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { RetailLocalization } from '../../../common/localization/retail-localization';
import { RetailPropertyInformation } from '../../../common/services/retail-property-information.service';
import { RetailService } from '../../../retail.service';
import { BaseResponse, PaymentMethods } from '../../../shared/business/shared.modals';
import { RetailDataAwaiters } from '../../../shared/events/awaiters/retail.data.awaiters';
import { retailPublisher } from '../../../shared/events/pubsub/retail.publishers';
import * as myGlobals from '../../../shared/globalsContant';
import { CommonVariablesService, SelectedProducts, LineItem, ServiceChargeGratuity, ReturnWithTicketType } from '../../../shared/service/common-variables.service';
import { HttpMethod, HttpServiceCall } from '../../../shared/service/http-call.service';
import { GuestStayDetail, ArAccount, LookUpInput, PaymentHistory, PaymentDetails } from '../../../shared/service/payment/payment-business.model';
import { RedirectToModules, RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { ReceiptBusinessService } from '../../receipt/business/receipt-business.service';
import { ShopBussinessService } from '../../shop-business.service';
import { RetailShopItem, ReturnTicketData, ReturnItemTotal, ReturnWithTicketData } from '../../shop.modals';
import { RetailTransactionService } from '../../view-categories/retail-transactions/retail.transactions.service';
import { TicketChangeEventModel, RetailEventParameters, RetailEventType } from '../../../shared/events/event.model';
import { CMSPlayerInfo, Voucher } from 'src/app/common/Models/common.models';
import * as _ from 'lodash';
import { GiftCardBalance, GiftcardSearchResult, ReversalRequestParams } from 'src/app/retail/shared/service/payment/payment-model';
import { MatDialog } from '@angular/material/dialog';
import { PaymentMethod, RetailItemType } from 'src/app/retail/retail.modals';
import { AlertAction, AlertType } from 'src/app/retail/shared/shared.modal';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { ReplaySubject } from 'rxjs';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { ShopDialogPopUp } from '../../shop-dialog-popup/shop-dialog-popup.component';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { CardPaymentMethods, GiftcardIDTechMethodMapper, GiftcardIDTechMethods, GiftCardMethods, MemberShipMethods, PaymentOptionType, SurchargePaymentMethods } from 'src/app/retail/payment/PaymentFlowHelpers';
import { V1GiftCardMethods } from 'src/app/retail/payment/PaymentFlowHelpers';
import { GiftCardBusiness } from 'src/app/retail/sytem-config/gift-cards/gift-cards.business';
import { SurchargeConfigurationService } from 'src/app/retail/sytem-config/data-service/surcharge-configuration.data.service';
import { ButtonType, CardIssuerIconMap, CreditCardIssuerConfig, CreditCardIssuerType } from '../../../shared/globalsContant';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { FolioBusiness } from 'src/app/retail/Folio/folio-business';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { PlayerCategory } from 'src/app/common/enums/shared-enums';
import { NegativeValueValidator } from 'src/app/retail/shared/Validators/NegativeValueValidator';

@Component({
  selector: 'app-shop-serviceitem-summary',
  templateUrl: './shop-serviceitem-summary.component.html',
  styleUrls: ['./shop-serviceitem-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShopServiceitemSummaryComponent implements OnInit {
  OriginalAmount: number = 0;
  paymentType: any;
  disabledRadiobool: boolean = false;
  defaultSelectchecked: number = 0;
  paymentMethodType: Array<any> = [
    { id: 1, name: this.localization.captions.shop.fullPayment, disabled: this._sbs.disabledRadiobool },
    { id: 2, name: this.localization.captions.shop.multiPayment, disabled: this._sbs.disabledRadiobool }
  ];
  returnTransitionDetails: UntypedFormGroup;
  OriginalSettlementToggleEnabled: boolean = false;
  cashPaymenHandleData: any = null;
  isPaymentCommentRequired: boolean = false;
  isEnablePaymentComment: boolean = false;
  paymentHistoryArray: Array<any>;
  deviceType: Array<any>;
  roomDetailsArray: Array<any>;
  public clientinfoArray: any = [];
  clientInfo: any;
  selectedClient: any;
  giftcardBalance: Array<GiftCardBalance> = [];
  public config: SwiperConfigInterface = {
    slidesPerView: 4,
    spaceBetween: 12,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  };
  paymentComments: string = "";
  PayMethodConst: any = PaymentMethods;
  noShowIcon: boolean;
  leftArrowDisabled: boolean = true;
  rightArrowDisabled: boolean = false;
  totalbtnWidth: any;
  totalScrollWidth: any;
  availableBtnSpace: any;
  CMSForm: UntypedFormGroup;
  commentDisable: boolean = false;
  quickLoginPopupEnabled: boolean = false;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  prevSettlementInfo: PaymentHistory[] = [];
  IsProceedReturn : boolean = false;

  Itemtotal: ReturnItemTotal = {
    retainedItemSubTotal: 0.00,
    retainedItemTax: 0.00,
    retainedItemGratuityTax: 0.00,
    retainedItemServiceChargeTax: 0.00,
    retainedItemGratuity :0.00,
    retainedItemServiceCharge:0.00,
    retainedItemDiscount: 0.00,
    returnedItemGratuity:0.00,
    returnedItemServiceCharge: 0.00,
    returnedItemSubtotal: 0.00,
    returnedItemDiscount: 0.00,
    returnedItemTax: 0.00,
    returnedItemGratuityTax:0.00,
    returnedItemServiceChargeTax: 0.00,
    exchangedItemGratuity:0.00,
    exchangedItemServiceCharge:0.00,
    exchangedItemSubtotal: 0.00,
    exchangedItemTax: 0.00,
    exchangedItemGratuityTax: 0.00,
    exchangedItemServiceChargeTax: 0.00,
    exchangedItemDiscount: 0.00,
    retainedItemVat:0.00,
    returnedItemVat:0.00,
    returnedItemTaxOnTax: 0.00,
    retainedItemTaxOnTax: 0.00,
    exchangedItemTaxOnTax: 0.00
  };
  floatLabelNever: string;
  CreateRetailItemResponse: any; // Activities - retail integration changes, CreateRetailItemResponse is added for the update booking scenario
  public get IsRefund() {
    return this._shopservice.finalAmount < 0;
  }
  public get ClientName() {
    if (this.clientInfo && this.clientInfo.clientDetail) {
      return `${this.clientInfo.clientDetail.firstName} ${this.clientInfo.clientDetail.lastName}`
    }
    else if (this.selectedClient && this.selectedClient.name) {
      return this.selectedClient.name
    }
    else {
      return '';
    }
  }
  @ViewChild('buttonView') btnView: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculatePaymentsWidth();
  }
  @ViewChild('returnReason') returnReasonElement: ElementRef;
  MissingAmountTenderMsg: string = '';
  giftcardCaptions: any = this.localization.captions.shop.GiftCard;
  selectedGiftCard: GiftcardSearchResult;
  accountLookupComponentInput: LookUpInput = {
    arAccountName: '',
    arAccountNumber: '',
    isCustomUIRequired: false,
    selectedPaymentMethod: this._sbs?.selectedpayment?.paymentTypeId,
    profitCenter: Number(this._sbs?.profitCenter??'0')
  };
  hidePassword = false;
  get tenderId() {
    return this._sbs.selectedpayment && this._sbs.selectedpayment.paymentTypeId;
  }
  get AllowPaymentReversal() {
    return (this._sbs.partialPayment && this._sbs.paymentText != this.localization.captions.shop.CloseTransaction && !this._sbs.IsRefundFlow);
  }
  get ShowSettlementOption() {
    return (!this.PropertyInfo.UseRetailInterface && this._sbs.ShowPaymentMethods && this._shopservice.finalAmount != 0)
  }
  get IsRefundStarted() {
    if(this._sbs.ReturnWithTicketResponse !=null && this._sbs.SettlementHistory.some(x => !x.isReversed)){
      return true;
    }    
  }

  get IsRefundToOriginalSettlement() {
    return (this.returnTransitionDetails.controls.refundToOriginal.value && this.IsEnableRefundToOriginalSettlement);
  }

  get IsEnableRefundToOriginalSettlement() {
    return (this._sbs.IsCompleteReturn && this._sbs.IsRefundFlow) ||            // For Full Return Refund Flows Any Payment Methods.
      (this._sbs.IsCompleteReturn && this.IsZeroMemberChargesOnTeeTime) ||      // For Full Return Only For Zero Member Charge From Tee Time.
      (this.isMultipleMemberTransaction) ||                                     // Explicity For Multi Member Transaction.
      this.IsPartialCreditCardRefund;
  }

  get IsPartialCreditCardRefund() {
    return (!this._sbs.IsCompleteReturn && this._sbs.IsRefundFlow &&            // Explicity For Card/Card Clone Partial Refund.
      this._shopservice.finalAmount < 0 &&
      this._sbs.paymentHistoryDetailsForTransaction &&
      this._sbs.paymentHistoryDetailsForTransaction.every(s => s.amount > 0 &&
        (this.utils.GetOriginalTenderId(s.paymentMethodId, s.parentTenderId) === PaymentMethods.CreditCard ||
          this.utils.GetOriginalTenderId(s.paymentMethodId, s.parentTenderId) === PaymentMethods.IDTECH))
    )
  }

  get isMultipleMemberTransaction() {
    return  this._sbs.scheduledTeeTimeIds &&  this._sbs.scheduledTeeTimeIds.length > 0 && this._sbs.isMultipleMemberTransaction &&
    this._sbs.paymentHistoryDetailsForTransaction.some(s => (s.paymentMethodId === PaymentMethods.ARPost));
  }

  get IsZeroMemberChargesOnTeeTime(){
    return (this._shopservice.isFromRefund || (this._shopservice.finalAmount === 0 && this._sbs.paymentHistoryDetailsForTransaction
      && this._sbs.paymentHistoryDetailsForTransaction.every(s => s.amount === 0 && (s.paymentMethodId === PaymentMethods.ARPost
        || s.paymentMethodId === PaymentMethods.RedeemPoint))
      && this._shopservice.selectedclient && this._shopservice.selectedclient.playerCategoryId === PlayerCategory.Member &&
      this._sbs.scheduledTeeTimeIds &&  this._sbs.scheduledTeeTimeIds.length > 0));
  }

  get IsPartialRefundOriginalSettlement() {
    return (this._sbs.IsRefundFlow && ((!this._sbs.IsCompleteReturn && this._sbs.paymentHistoryDetailsForTransaction
      && this._sbs.paymentHistoryDetailsForTransaction.some(s => s.amount > 0
        && (this.utils.GetOriginalTenderId(s.paymentMethodId, s.parentTenderId) === PaymentMethods.CreditCard ||
          this.utils.GetOriginalTenderId(s.paymentMethodId, s.parentTenderId) === PaymentMethods.IDTECH)))));
  }

  get IsProceedBtnEnabled() { return this.EnableProcessButton(); }
  get IsRefundAsGiftcardFlow() {
    return this._sbs.isGiftCardSelected && this._sbs.IsRefundFlow &&
      (V1GiftCardMethods.includes(this._sbs.selectedPaymentMethod) ? !this._sbs.selectedDeviceHandle : this._sbs.selectedDeviceHandle != null)
  }
  creditCardMethods = [PaymentMethods.CreditCard, PaymentMethods.IDTECH];
  amounttenderedField: ElementRef;
  @ViewChild("amounttenderedField", { static: false }) set content(content: ElementRef) {
    if (content) { this.amounttenderedField = content; }
  }
  get AmountDueMsg() {
    return this._sbs.IsRefundFlow ?
      this.localization.captions.shop.Refund
      : this.localization.captions.shop.Collect
  }
  get AmountPaidMsg() {
    return this._sbs.IsRefundFlow ?
      this.localization.captions.shop.GiftCard.AmountRefunded
      : this.localization.captions.shop.GiftCard.AmountPaid
  }
  get BlockPartialRefund() {
    return (!this._sbs.IsCompleteReturn && this._sbs.paymentHistoryDetailsForTransaction?.filter(s => s.amount > 0 && 
      (this.utils.GetOriginalTenderId(s.paymentMethodId, s.parentTenderId) === PaymentMethods.CreditCard || 
       this.utils.GetOriginalTenderId(s.paymentMethodId, s.parentTenderId) === PaymentMethods.IDTECH))?.length > 1)
  }
  cmsSearchField: ElementRef;
  @ViewChild("cmsSearchField", { static: false }) set searchField(content: ElementRef) {
    if (content) { this.cmsSearchField = content; }
  }

  walletInputField: ElementRef;
	@ViewChild("walletInputField", { static: false }) set inputField(content: ElementRef) {
		if (content) { this.walletInputField = content; }
	}

  get IsCMSRequirePin() { return this._retailFeatureFlagInfo.CMSRequirePin }

  get CMSPin() {
    return this.IsCMSRequirePin ? this.CMSForm?.controls?.cmsPin?.value : ""
  }

	get IsMemberRefund() { return (this.IsRefund && MemberShipMethods.includes(this._sbs.selectedpayment?.paymentTypeId)) }

  public get IsCompOrOfferRedemption() {
		return (this._sbs.selectedPaymentMethod == PaymentMethods.CompRedemption || this._sbs.selectedPaymentMethod == PaymentMethods.OfferRedemption)
	}
  @ViewChild('SCpopOverTarget') SCpopOverTarget;
	@ViewChild('surchargePopover') surchargePopover;
  @Output() SurchargeUpdated = new EventEmitter();
  @Output() TicketRecalculated = new EventEmitter();
  get ShowSurchargeSelection() {
		return (this._surchargeConfigService?.IsSurchargesEnabled
			&& CardPaymentMethods.includes(this._sbs.selectedPaymentMethod)
			&& this.cardIssuerTypes.some(x => x.isActive)
			&& !this._sbs.IsRefundFlow)
	}

  get IsSurchargeApplicableMethod() {
		return (this._surchargeConfigService?.IsSurchargesEnabled
			&& SurchargePaymentMethods.includes(this._sbs.selectedPaymentMethod)
			&& this.cardIssuerTypes.some(x => x.isActive)
			&& !this._sbs.IsRefundFlow)
	}

	get SurchargeEnabled() { return this._surchargeConfigService?.IsSurchargesEnabled }

	get AmountEntered(){
		return this.localization.currencyToSQLFormat(
			this.returnTransitionDetails.controls.amounttendered.value
		)
	}
	get AmtForSurchargeCalc() {
		return this.isSurchargeApplied && this.amountBeforeSurcharge > 0 ? this.amountBeforeSurcharge :
			this.remainingAmountBeforeSurcharge > 0 ? this.remainingAmountBeforeSurcharge :
				this._sbs.remainingAmount;
	}  
	get PrevSurchargeTotal() {
		let prevSurcharges = 0;
		if (this._sbs.SettlementHistory?.length > 0) {
			this._sbs.SettlementHistory?.filter(x => !x.isReversed)?.map(x => prevSurcharges += x.surcharge);
		}
		return prevSurcharges;
	}
	public get TaxExemptByPaymentApplied() : number{
		var res = Number((this._shopservice.OriginalTicket?.checkData?.totalTax - this._shopservice.Ticket?.checkData?.totalTax).customToFixed());
		return res;
	}
	
  cardIssuerTypes: CreditCardIssuerConfig[] = [];
	isSurchargeApplied: boolean = false;
	isSurchargedCollected: boolean = false;
	selectedIssuerType: CreditCardIssuerConfig;
	surchargeAmount = 0;
	totalSurcharge = 0;
	remainingAmountBeforeSurcharge = 0;
	issuerTypeChange = false;
  amountBeforeSurcharge = 0;
  floatLabel: string;
  isVatEnabled:boolean = false;
  postTofolio : string;
	folioSection : boolean = false;
	postfolioData : any;
	postToFolioInfo: any;
  taxExemptCaption: string;

  constructor(
    public localization: RetailLocalization,
    public dialog: MatDialog,
    public _shopservice: CommonVariablesService,
    private utils: RetailUtilities,
    private http: HttpServiceCall,
    public PropertyInfo: RetailPropertyInformation,
    private transactionService: RetailTransactionService,
    public fb: UntypedFormBuilder,
    public _sbs: ShopBussinessService,
		public _retailService: RetailSharedVariableService,
    public _ams: RetailService,
    private receiptService: ReceiptBusinessService,
    private commonUtils: QuickLoginUtilities,
    private _retailFeatureFlagInfo: RetailFeatureFlagInformationService,
    private func: RetailFunctionalityBusiness,
    private _giftcardBusiness: GiftCardBusiness
    , private _surchargeConfigService: SurchargeConfigurationService,
    private _folioBusiness: FolioBusiness
  ) {
    this.isVatEnabled = PropertyInfo.IsVATEnabled;
    this._sbs.selectedpayment = {
      id: 0,
      paymentTypeId: 0,
      parentTypeId: 0,
      allowEarn: false
    };
    this.CMSForm = this.fb.group({
      cmsSearch: '',
      cmsPaymentSelection: '',
      voucherSelection: '',
      cmsPin: ['', [Validators.minLength(4), Validators.maxLength(10)]]
    });
    this.selectedClient = this._shopservice.selectedclient;
    this._giftcardBusiness.IssuanceProgressCompleted.pipe(takeUntil(this.$destroyed)).subscribe((issuanceStateObj) => {
      if (issuanceStateObj?.transactionId && issuanceStateObj.retailTicketNumber) {
        setTimeout(() => {
          this._sbs.TriggerGiftcardTopup(issuanceStateObj.transactionId, issuanceStateObj.retailTicketNumber, this);
        }, 1000);
      }
    });

    this._giftcardBusiness.GiftcardActionsCompleted.pipe(takeUntil(this.$destroyed)).subscribe((issuanceStateObj) => {
      if (issuanceStateObj)
        this._sbs.PrintReceipts(this, issuanceStateObj.createItemRes);
      // Based on switch redirecting to res module after refund          
      if (this._shopservice.allowAutoCloseTransaction) {
        this._sbs.PerformCloseTransaction();
      }
    });

    this._sbs.patchRemainingAmt.pipe(takeUntil(this.$destroyed)).subscribe((remainingAmount) => {
      this.PatchRemainingAmountAndHighlight(remainingAmount, true);      
    });

    this._sbs.returnItemsListModified.pipe(takeUntil(this.$destroyed)).subscribe(() => {
      if (this._shopservice.defaultRefundToOriginal && this._shopservice.defaultRefundToOriginal === true && this.IsEnableRefundToOriginalSettlement) {
        setTimeout(() => {
          this._sbs.returnOriginalPaymentOnly = true;
          this.returnTransitionDetails.controls.refundToOriginal.setValue(true);
          this.OnRefundToOriginalToggle(true);
        }, 100);
      }
    });
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  SelectedPaymentMethodEquals(method: PaymentMethods) {
		return (this._sbs.selectedPaymentMethod === method)
	}

  transcation = [
    { value: '1', viewValue: 'Sales Person' },
    { value: '2', viewValue: 'Sales Merchant' },
    { value: '3', viewValue: 'Sales Leader' }
  ];

  /**
   * @method Function
   * @function selectpaymentMethod
   * @param e <obj>
   * @type any
   * @description select payment type of shoping
   * @return none
   * @change active the select class compare to id
   *
   */
  selectpaymentMethod(e: any, isOnLoad = false) {

    this.isPaymentCommentRequired = e.requireComments;
    this.paymentComments = "";
    this.returnTransitionDetails.controls.paymentComment.setValue("");
    this.isEnablePaymentComment = !(e.isDefault || e.isCloned);
    this._sbs.selectpaymentMethod(e, this, isOnLoad);
    this.setARAccountLookUpInputs();
  }

  setARAccountLookUpInputs(){    
    this.accountLookupComponentInput = {
      arAccountName: '',
      arAccountNumber: '',
      isCustomUIRequired: false,
      selectedPaymentMethod: this._sbs?.selectedpayment?.paymentTypeId,  
      profitCenter: Number(this._sbs?.profitCenter??'0')
    };
    this.accountLookupComponentInput = {...this.accountLookupComponentInput};
  }

  /**
   * @function roomSelected
   * @param <obj>
   * @description select the room type  of cash payment
   */

  roomSelected(e: any) {
    this._sbs.ifPaymentChoose = true;
    this._sbs.paymentText = this.localization.captions.shop.Add;
  }

  /**
 * @method function
 * @function tenderedAmount();
 * @input params <null>
 * @output <obj>
 * @description tendered
 *
 *

 */
  returnTenderedAmount(isUserEvent = true) {
    if (this._sbs.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption)) {
      this._sbs.selectedDeviceHandle = '';
      this.CMSForm.patchValue({ cmsPaymentSelection: '' });
    }
      this._sbs.tenderedAmount(this);
    this.issuerTypeChange = !isUserEvent;
    setTimeout(() => {
      if (this.IsSurchargeApplicableMethod && !this.issuerTypeChange) {
        this.amountBeforeSurcharge = this.AmountEntered;
        this.ApplySurcharge(this._sbs.getAmountPaid(this.returnTransitionDetails));
      }
    }, 500);
  }



  selectDevice(e: any) {
    this._sbs.RetrnPaymentSelectDevice(e, this.returnTransitionDetails);
  }

  PaymentOption(e: any) {
    this._sbs.PaymentOption(e, this.returnTransitionDetails);
  }

  PatchRemainingAmountAndHighlight(remainingAmount: number, clearCurrentValBeforePatch = false) {
    if (clearCurrentValBeforePatch) { this.returnTransitionDetails.controls.amounttendered.setValue('', { emitEvent: false }); }
    const amountEntered = this.returnTransitionDetails.controls.amounttendered.value;
    //If value already present then retain same value
    if (Math.abs(amountEntered) != 0 && Math.abs(amountEntered) != Math.abs(this._sbs.remainingAmount)) {
      remainingAmount = amountEntered;
    }
    this.returnTransitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(remainingAmount, false), { emitEvent: false });
    this.returnTransitionDetails.controls.amounttendered.markAsTouched();
    setTimeout(() => {
      if (this.amounttenderedField?.nativeElement) {
        this._sbs.tenderedAmount(this);
        this.amounttenderedField?.nativeElement?.select();
        this.amounttenderedField?.nativeElement?.focus();
        this._sbs.EnableCloseTranBtn = true;
      }
    }, 100);
  }

  async ngOnInit() {
    this.returnTransitionDetails = this.fb.group({
      'paymentType': [PaymentOptionType.MultiPayment, Validators.required],
      'amountpaid': [(this._shopservice.finalAmount < 0) ? this._shopservice.finalAmount * -1 : this._shopservice.finalAmount],
      'amounttendered': ['', [Validators.max((this._shopservice.finalAmount < 0) ? this._shopservice.finalAmount * -1 : this._shopservice.finalAmount), Validators.required]],
      'cardtype': [{ value: '', disabled: true }],
      'cardnumber': [{ value: '', disabled: true }],
      'transactionnumber': [{ value: '', disabled: true }],
      'returnReason': ['', Validators.required],
      'paymentComment': [''],
      'zipcode': [],
      'selectedDevice': ['', [Validators.required]],
      'selectedTerminal': [],
      'roomnumber': ['', [Validators.required]],
      'selectedGuest': [],
      'receiptComment': [this._shopservice.ReceiptComment],
      'refundToOriginal': false,
      activityRoomSelection: '',
      creditcardType: 0,
      'walletInput':['', Validators.required]
    });
    await this.SetLinkedTransaction();
    await this.SetTransactionHistory();
    this.func.getRetailFunctionality().then(async (res) => {
      this._sbs.functionalities = res;
      if (this._sbs.IsFolioPaymentMethod) {
        await this._sbs.GetCCTerminal();
        await this._sbs.GetCCConfiguration(this.returnTransitionDetails);
      }
      await this._sbs.GetUserSessionConfiguration();
      await this._sbs.GetAllPaymentMethods(this);
      await this.GetPayeeDetails(this._shopservice.selectedPayeeId);
      if (this._shopservice.settleRWTTransaction)
        await this.setTaxExemptionFromSettlementHistory();
      else
        await this._sbs.exemptTaxForSelectedPaymentMethod(this);
      this._sbs.currentAmountTendered = this._sbs.remainingAmount;
    });

    let timeOut = setTimeout(() => {
      clearTimeout(timeOut);
      this.calculatePaymentsWidth()
    }, 1);
    this.deviceType = [];
    this.paymentHistoryArray = [];
    if (this._shopservice.selectedclient && this._shopservice.selectedclient.guestProfileId != undefined &&
      (this._shopservice.selectedclient.playerCategoryId === PlayerCategory.Member || this._shopservice.selectedclient.guesttype == myGlobals.ClientType.Member)) {
       this._shopservice.memberCardNumber = this._shopservice.selectedclient.guestProfileId;
      await this._shopservice.GetRetailItemDetails(this._shopservice.selectedExchangeRetailProducts);
      this._shopservice.selectedExchangeRetailProducts.forEach(product => {
        let currentItem = this._shopservice.SelectedRetailItemDetails.find((x) => x.id == product.ItemId);
        const tempProductPrice = currentItem.retailItemDetail.isOpenItem ? product.ProductPrice :
          currentItem.retailItemDetail.memberPrice;
        product.ProductPrice = currentItem.retailItemDetail.isScaledItem ?
          this.localization.currencyToSQLFormat(String(product.scaledUnits)) * currentItem.retailItemDetail.memberPrice : tempProductPrice;
        product.Discount = product.DiscountPercentage > 0 ? product.ProductPrice * product.DiscountPercentage * 0.01 : product.Discount;
      });
    }
    if (this._sbs.returnOriginalPaymentOnly && this._sbs.IsRefundFlow && this._sbs.paymentMethods && this._sbs.paymentMethods.length > 1) {
      this.paymentMethodType[0].disabled = this._sbs.returnOriginalPaymentOnly;
      this._sbs.PaymentOption({ id: PaymentOptionType.MultiPayment }, this.returnTransitionDetails);
    }
    this._surchargeConfigService.GetSurchargeSwitchConfig().then(res => {
			this.GetSurchargeConfig();
		});
    this._sbs.refreshTotal.subscribe(x => {
      if (this.IsSurchargeApplicableMethod && this.isSurchargeApplied) {
        this.amountBeforeSurcharge = this.AmountEntered;
        this.ApplySurcharge(this._sbs.getAmountPaid(this.returnTransitionDetails));
      }
      this.PatchRemainingAmountAndHighlight(this._sbs.remainingAmount, true);
    });
    this.taxExemptCaption = this.localization.captions.shop.lbl_TaxExemptByPaymentMethod;
    this._sbs.isTaxExempted.subscribe(value => {
      this._shopservice.isTaxExempted = value;
      if (value) {
        this._shopservice.Ticket = _.cloneDeep(this._shopservice.OriginalTicket);
        this._sbs.isRefreshShopServiceItems.emit(true);
        this._sbs.taxExemptCalcTriggered = false;
      }
      else {
        this.selectpaymentMethod(this._sbs.selectedpayment, true);
      }
    });
  }

  async setTaxExemptionFromSettlementHistory()
  {
    if (this._sbs.SettlementHistory.length > 0 && !this._sbs.IsRefundFlow && this._sbs.IsShopScreen) {
			let total = 0;
			this._sbs.SettlementHistory.forEach(x => {
				let paymentMethod = this._sbs.paymentMethods.find(y => y.paymentMethod == x.paymentMethod);
				if (paymentMethod.isAutoRemoveTax) {
					total += Number(x.amount.customToFixed());
				}
			});
      if (total > 0 && await this._shopservice.exemptTaxForComp(total)) {
        this._shopservice.TempTicket = _.cloneDeep(this._shopservice.Ticket);
        await this._sbs.isRefreshShopServiceItems.emit(true);
        this.PatchRemainingAmountAndHighlight(this._sbs.remainingAmount, true);
        this._shopservice.isTaxRecalculated = true;
        this._shopservice.remainingAmountAfterPayment = this._sbs.currentAmountTendered = this._sbs.remainingAmount;
        this._shopservice.totalTaxExemptRatio = Number((total / this._shopservice.totalAmountWithoutTax).toFixed(2))
      }
    }
  }

  async SetLinkedTransaction() {
    if (this._shopservice.selectedTransaction && this._shopservice.selectedTransaction.transactionLinkId > 0 && (this._sbs.LinkedTransaction == null || this._sbs.LinkedTransaction.id != this._shopservice.selectedTransaction.transactionLinkId)) {
      this._sbs.LinkedTransaction = await this._sbs.getTransactionDetail(this._shopservice.selectedTransaction.transactionLinkId);
    }
  }

  async SetTransactionHistory() {
    if (this._shopservice?.selectedTransaction) {
      this._sbs.paymentHistoryDetailsForTransaction = await this._sbs.GetPaymentHistory(this._shopservice.selectedTransaction.id, true);
    }
  }

  UpdatButtonLablesBasedOnFundFlow() {
    const tempPaymentText = this._sbs.IsRefundFlow ? this.localization.captions.shop.ReturnPayment : this.localization.captions.shop.Pay;
    this._sbs.paymentText = (this.PropertyInfo.UseRetailInterface) ? this.localization.captions.shop.SENDFORPAYMENT : tempPaymentText;
    this.MissingAmountTenderMsg = this._sbs.IsRefundFlow ? this.localization.captions.shop.MissingRefundAmount : this.localization.captions.shop.MissingAmountTenderMsg;
  }

  CheckRoomNumber() {
    this._sbs.CheckRoomNumber(this.returnTransitionDetails);
  }

  async ngAfterViewInit() {
    this.CalculateTotal();
    this.returnTransitionDetails = this.fb.group({
      'paymentType': [PaymentOptionType.MultiPayment, Validators.required],
      'amountpaid': [(this._shopservice.finalAmount < 0) ? this._shopservice.finalAmount * -1 : this._shopservice.finalAmount],
      'amounttendered': ['', [Validators.max((this._shopservice.finalAmount < 0) ? this._shopservice.finalAmount * -1 : this._shopservice.finalAmount), Validators.required]],
      'cardtype': [{ value: '', disabled: true }],
      'cardnumber': [{ value: '', disabled: true }],
      'transactionnumber': [{ value: '', disabled: true }],
      'paymentComment': [''],
      'returnReason': ['', Validators.required],
      'zipcode': [],
      'selectedDevice': ['', [Validators.required]],
      'roomnumber': ['', [Validators.required]],
      'selectedGuest': [],
      'selectedTerminal' : [],
      'receiptComment': [this._shopservice.ReceiptComment],
      'refundToOriginal': this._sbs.returnOriginalPaymentOnly,
      activityRoomSelection: '',
      creditcardType: 0,
      'walletInput':['', Validators.required]
    });
    this._sbs.returnTransitionDetails = this.returnTransitionDetails;
    this._sbs.isIGRetail = this.PropertyInfo.UseRetailInterface; //await this._sbs.isRetailInterface();
    this.returnTransitionDetails.get('returnReason').valueChanges.subscribe(r => {
      if (r && !this._sbs.ReturnWithTicketResponse) { this._sbs.paymentText = this._sbs.IsRefundFlow ? (this.PropertyInfo.UseRetailInterface ? this.localization.captions.shop.SENDFORPAYMENT : this._sbs.oCaptions.shop.Refund) : this._sbs.oCaptions.shop.Pay; }
    });
    this._sbs.remainingAmount = this._shopservice.finalAmount;
    if (this._shopservice.finalAmount < 0) { // New Refund Flow
      this._sbs.ShowTenderField = false;
    }
    if (this._sbs.returnOriginalPaymentOnly && this._sbs.IsRefundFlow && this._sbs.paymentMethods && this._sbs.paymentMethods.length > 1) {
      this.returnTransitionDetails.controls.paymentType.setValue(PaymentOptionType.MultiPayment);
      this.paymentMethodType[0].disabled = this._sbs.returnOriginalPaymentOnly;
      this._sbs.PaymentOption({ id: PaymentOptionType.MultiPayment }, this.returnTransitionDetails);
    }
    this.DisableFullPaymentForSettleflow();
    this.UpdatButtonLablesBasedOnFundFlow();
    //Switch to Multiple
    this.PaymentOption(this.paymentMethodType[1]);
    this.PatchRemainingAmountAndHighlight(this._shopservice.finalAmount, true);
    this._sbs.currentAmountTendered = this._sbs.remainingAmount;
    this.prefillReturnReasonIfRequired();
    this.SubscribeAmountTenderChange();
  }  

  SubscribeAmountTenderChange() {
    this.returnTransitionDetails.controls.amounttendered.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.$destroyed)).subscribe(async val => {
        this.exemptTaxAndUpdateAmountTendered(val);
        if (this.IsSurchargeApplicableMethod && val && !this.issuerTypeChange)
          this.ApplySurcharge(this._sbs.getAmountPaid(this.returnTransitionDetails));
      });
  }

  async exemptTaxAndUpdateAmountTendered(val) {
    let AmountTendered = this.localization.currencyToSQLFormat(val);
    if (AmountTendered > 0 && this._sbs.currentAmountTendered != AmountTendered) {
      this._shopservice.PayButtonDisable.emit(true);
      await this._sbs.exemptTaxForSelectedPaymentMethod(this);
      this._sbs.currentAmountTendered = AmountTendered;
      if (AmountTendered < this._shopservice.totalAmountWithoutTax) {
        setTimeout(() => {
          this.returnTransitionDetails.controls.amounttendered.setValidators([
            Validators.max(this._shopservice.remainingAmount),
            Validators.required,
            NegativeValueValidator(this)
          ]);
          this.PatchRemainingAmountAndHighlight(AmountTendered, true);
          setTimeout(() => {
            this._shopservice.PayButtonDisable.emit(false);
          }, 100);
          this._ams.loaderEnable.next("");
        }, 400);
      }
    }
  }

  DisableFullPaymentForSettleflow() {
    if (this._shopservice.settleRWTTransaction && this._sbs.SettlementHistory.length > 0) {
      this._sbs.CalculateSettledAmount(this.returnTransitionDetails);
      this._sbs.PaymentOption({ id: PaymentOptionType.MultiPayment }, this.returnTransitionDetails);
      this.returnTransitionDetails.controls.paymentType.patchValue(PaymentOptionType.MultiPayment);
      this.paymentMethodType[0].disabled = true;
      this._sbs.ShowPaymentMethods = true;
      this._sbs.ShowTenderField = (Math.abs(this._sbs.remainingAmount) > 0);
      this.returnTransitionDetails.controls.paymentType.markAsTouched();
      this.returnTransitionDetails.controls.paymentType.updateValueAndValidity();
    }
  }

  selectCMSPaymentType(playerInfo, event) {
    if (this._retailFeatureFlagInfo.SkipPMAgentCMS) {
      this._sbs.selectedCMSType = this._sbs.cmsPaymentSelectType(this._sbs.getAmountPaid(this.returnTransitionDetails), playerInfo, event, this.CMSForm)
      this._sbs.EnableCloseTranBtn = (this._sbs.selectedCMSType != null);
    }
    else {
      this._sbs.selectedDeviceHandle = this._sbs.selectCMSPaymentType(this._sbs.getAmountPaid(this.returnTransitionDetails), playerInfo, event, this.CMSForm);
      this._sbs.EnableCloseTranBtn = (this._sbs.selectedDeviceHandle != "");
    }
  }

  selectVoucher(voucher: Voucher) {
    if (voucher) {
      this._sbs.selectedVoucher = voucher;
      let saleAmt = this._sbs.remainingAmount < voucher.voucherAmount ? this._sbs.remainingAmount : voucher.voucherAmount;
      this.returnTransitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(saleAmt));
      this._sbs.EnableCloseTranBtn = true;
    }
  }

  SelectGiftCard(selectedGiftCard: GiftcardSearchResult) {
    selectedGiftCard.paymentMethodId = this._sbs.selectedpayment.id;
    this._sbs.selectedGiftCard = this.selectedGiftCard = selectedGiftCard;
    if (selectedGiftCard && selectedGiftCard.handle) {
      this._sbs.selectedDeviceHandle = selectedGiftCard.handle;
      this._sbs.selectedpayment.paymentTypeId = selectedGiftCard.tenderId;
      this._sbs.selectedpayment.paymentTypeEqualant = GiftcardIDTechMethods.includes(selectedGiftCard.tenderId) ?
        GiftcardIDTechMethodMapper[selectedGiftCard.tenderId] : selectedGiftCard.tenderId;
      const amtTendered = this.localization.currencyToSQLFormat(this.returnTransitionDetails.controls.amounttendered.value);
      //Updating Amount tendered whichever the lesser value
      if (selectedGiftCard.amount < amtTendered)
        this.returnTransitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(selectedGiftCard.amount));
    } else {
      this._sbs.selectedDeviceHandle = '';
    }
  }

  SelectAccount(selectedARAccount: ArAccount) {
    if (selectedARAccount) {
      this._sbs.selectedARAccount = selectedARAccount;
      this._shopservice.arAccountName = selectedARAccount.accountName;
      this._shopservice.arAccountNumber = selectedARAccount.accountNumber;
    } else {
      this._sbs.selectedARAccount = null;
      this._shopservice.arAccountName = this._shopservice.arAccountNumber = "";
    }
  }

  SelectGuestRoom(room) {
    if (room && room.handle) {
      this._sbs.selectedDeviceHandle = room.handle;
      this._sbs.selectedGuestRoom = room;
      this._sbs.EnableCloseTranBtn = true;
      this._sbs.ifPaymentChoose = true;
    } else {
      this._sbs.ifPaymentChoose = false;
      this._sbs.selectedDeviceHandle = "";
      this._sbs.selectedGuestRoom = null;
    }
  }

  selectCompSlip(event) {
    this._sbs.selectedCMSPlayer = { PlayerName: '', PatronId: '' };
    this._sbs.cmsPlayerInfo = null;
    this._sbs.selectedVoucher = null;
    if (event && Array.isArray(event) && event.length == 2 && event[0] && event[1]) {
      const playerInfo: CMSPlayerInfo = event[0];
      const voucher: Voucher = event[1];
      this.selectVoucher(voucher);
      this._sbs.cmsPlayerInfo = playerInfo;
      this._sbs.selectedCMSPlayer = {
        PlayerName: `${playerInfo.personalDetails.firstName} ${playerInfo.personalDetails.lastName}`,
        PatronId: playerInfo.playerID
      };
    }
  }

  calculatePaymentsWidth() {
    if (this.btnView) {
      this.totalbtnWidth = this.btnView.nativeElement.offsetWidth;
      this.totalScrollWidth = this.btnView.nativeElement.scrollWidth;
      this.availableBtnSpace = Math.floor(this.totalbtnWidth / 68);
      let ff = Math.floor(this.btnView.nativeElement.offsetWidth / this.availableBtnSpace);
      let navChildren = this.btnView.nativeElement.querySelectorAll('.time-btn');
      navChildren.forEach(element => {
        element.style.width = ff + 'px';
      });
    }

    if (this.totalbtnWidth && this.totalbtnWidth > 0 && this.totalbtnWidth > this.totalScrollWidth) {
      this.noShowIcon = true;
    }
    else {
      this.noShowIcon = false;
    }
  }

  CalculateTotal() {
   
    if (this._shopservice.returnWithticketItems.some(x => x.ItemType == RetailItemType.RevenueItem) ||
      this._shopservice.selectedReturnedRetailProducts.some(x => x.ItemType == RetailItemType.RevenueItem) ||
      this._shopservice.selectedExchangeRetailProducts.some(x => x.ItemType == RetailItemType.RevenueItem) ||
      this._shopservice.selectedRetainedRetailProducts.some(x => x.ItemType == RetailItemType.RevenueItem)
    ) {
      this._sbs.IsRevenueItemIncluded = true;
      RetailDataAwaiters.GetSetBeoChecks(this._shopservice.transactionId);
    }
    this._shopservice.IsbeoItemReturned = this._shopservice.selectedReturnedRetailProducts.some(r => r.ItemType && r.ItemType == RetailItemType.RevenueItem || r.ItemType == RetailItemType.Deposit);
    this._shopservice.returnWithticketItems.forEach(element => {
      this.OriginalAmount += this.CalculateItemSubTotal(element)
    });
  
    if (this._shopservice.selectedMultiPackExchangedProducts.length > 0) {
      let multipackRedeemamount = 0;
      this._shopservice.selectedMultiPackExchangedProducts.forEach(element => {
        multipackRedeemamount += element.totalAmount;
      });
      this.OriginalAmount -= multipackRedeemamount;
      this._shopservice.returnItemTotal -= multipackRedeemamount;
    }
    this._shopservice.finalAmount = Number((this._shopservice.exchangedItemTotal - this._shopservice.returnItemTotal).customToFixed());
    this._sbs.setOriginalTotalAmount(this._shopservice.finalAmount);
  }

  CalculateItemSubTotal(item: SelectedProducts, isForExchangeItem:boolean = false, includeTax: boolean = true, isRecalculateTax = true): number {

    if(isForExchangeItem && !this.PropertyInfo.UseRetailInterface && this._shopservice.Ticket)
    {
      var itemFromCheck = this._shopservice.Ticket.lineItems.find(lineItem => lineItem.itemId == item.ItemId && lineItem.index == item.LineNumber);
      let itemPrice = 0
      if(itemFromCheck) {
        let gratuity = itemFromCheck.gratuity;
        let servCharge = itemFromCheck.serviceCharge;
        itemPrice = itemFromCheck.unitPrice * itemFromCheck.quantity;
        if (includeTax) {
          itemPrice += this.PropertyInfo.IsVATEnabled ? 0 : this.CalculateTax(item, isRecalculateTax);
        }
        if (itemPrice > 0) {
          itemPrice = itemPrice - ((itemFromCheck.discount || 0) > itemPrice ? itemPrice : itemFromCheck.discount);
        }
        itemPrice  += gratuity + servCharge;
      }
            return Number(itemPrice.customToFixed());
    }
    else
    {
      let originalItem = this._shopservice.returnWithticketItems.filter(rw => {
        return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber
      })[0];
      if(!originalItem)
      {
        originalItem = item;
      }
  
      let gratuity = item.Gratuity && item.Gratuity.length > 0 ? originalItem.Gratuity.map(x => x.gratuity).reduce((a, b) => a + b, 0) : 0;
      let gratTax = item.Gratuity && item.Gratuity.length > 0 ? originalItem.Gratuity.map(x => x.GratuityTax).reduce((a, b) => a + b, 0) : 0;
      let servCharge = item.ServiceCharge && item.ServiceCharge.length > 0 ? originalItem.ServiceCharge.map(x => x.ServiceCharge).reduce((a, b) => a + b, 0) : 0;
      let servTax = this.isVatEnabled ? 0 : item.ServiceCharge && item.ServiceCharge.length > 0 ? originalItem.ServiceCharge.map(x => x.ServiceChargeTax).reduce((a, b) => a + b, 0) : 0;
      
      let itemPrice = item.ProductPrice * item.Noofitems;  
     
  
      let discount = originalItem ? originalItem.Discount : item.Discount;
      if (originalItem && originalItem.Noofitems != item.Noofitems) { // Returning partial quantity
        let originalQuantity: number = Number(originalItem.Noofitems);
        let perUnitGratuity = gratuity / originalQuantity;
        let perUnitGratuityTax = gratTax / originalQuantity;
        let perUnitServiceChargeTax = servTax / originalQuantity;
        let perUnitServCharge = servCharge / originalQuantity;
        gratuity = Number((perUnitGratuity * item.Noofitems).customToFixed());
        servCharge = Number((perUnitServCharge * item.Noofitems).customToFixed());
        gratTax = Number((perUnitGratuityTax * item.Noofitems).customToFixed());
        servTax = Number((perUnitServiceChargeTax * item.Noofitems).customToFixed());
        let perUnitDiscount = discount / originalQuantity;
        discount = this.utils.MidPointRoundOffTwo(perUnitDiscount * item.Noofitems);
        if (discount * Number(originalItem.Noofitems) != originalItem.Discount) {
          discount = item.Discount;
        }
      }
      item.Discount = discount;
      itemPrice = itemPrice - discount > 0 ? itemPrice - discount : 0;
      servCharge = servCharge + servTax;
      gratuity = gratuity + gratTax;
      if (includeTax) {
        itemPrice += this.PropertyInfo.IsVATEnabled ? 0 : this.CalculateTax(item, isRecalculateTax);
      }      
      if (item.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY) {
        return Number(servCharge.customToFixed());
      }
      if (item.returnType == ReturnWithTicketType.RETURNGRATUITYONLY) {
        return Number(gratuity.customToFixed());
      }
  
      if (item.returnType == ReturnWithTicketType.RETAINITEMONLY) {
        return Number(itemPrice.customToFixed());
      }
      else if (item.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE && item.ServiceCharge.length > 0) {
        itemPrice += servCharge;
        return Number(itemPrice.customToFixed());
      }
      else if (item.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY && item.Gratuity.length > 0) {
        itemPrice += gratuity;
        return Number(itemPrice.customToFixed());
      }
      else {
        itemPrice += gratuity + servCharge;
        return Number(itemPrice.customToFixed());
      }
    }
  }

  CalculateTax(item: SelectedProducts, isRecalculateTax = true) {
    let itemTax = item.Tax ? item.Tax : 0;
    let originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id == rw.id
    })[0];
    if (originalItem && isRecalculateTax) {
      let originalQantity: number = Number(originalItem.Noofitems);
      let perUnitTax = (originalItem.Tax ? originalItem.Tax : 0) / originalQantity;
      itemTax = (perUnitTax * item.Noofitems);
    }
    if (originalItem && item.isPartialReturn && item.returnType <= ReturnWithTicketType.RETAINITEMONLY && item.returnType >= ReturnWithTicketType.RETAINITEMANDSERVICECHARGE) {
      let originalQantity: number = Number(originalItem.Noofitems);
      let perUnitTax = (originalItem.Tax ? originalItem.Tax : 0) / originalQantity;
      itemTax = (perUnitTax * item.Noofitems);
    }
    return itemTax;
  }

  SelectRoom(roomObj: GuestStayDetail) {
    console.log(roomObj);
    if (roomObj) {
      this.returnTransitionDetails.controls.roomnumber.setValue(roomObj.roomNo);
      this._sbs.SelectedRoomNo = roomObj.roomNo;
    }
  }

  Cancel() {
    this.GotoRetailTransaction(true);
  }

  GotoRetailTransaction(isCancelPressEvent = false) {
    const settleRWTTransFlow = (this._shopservice.settleRWTTransaction && this._sbs.IsRefundFlow && this._sbs.SettlementHistory.every(x => x.isPrefilled));
    if (!this._sbs.SelectedPaymentMethodEquals(PaymentMethods.PendingSettlement) && !settleRWTTransFlow && !this._sbs.ValidateTransactionCancellation(this._sbs.SettlementHistory
      , this.FormReversalParam()
      , this.CancelAllPaymentCallback.bind(this)
      , true
    )) { return }
    if (!settleRWTTransFlow && this._sbs.ReturnWithTicketResponse != null && this._sbs.SettlementHistory.length>0 && !this._sbs.SelectedPaymentMethodEquals(PaymentMethods.PendingSettlement)  ) {
      this.utils.showError(this.localization.captions.shop.CancelNotAllowedSettlementInProgress);
      return;
    }
    this._ams.paymentProcessing = false;
    this._shopservice.returnTicket.dropdownState = myGlobals.RetailTransactions.returnwithticket;
    this._shopservice.returnTicket.fromReturnTicket = true;
    this._shopservice.GoToRetailTransaction = true;
    this.DiscardCreatedCheck();
    const refundTranIdTemp = _.cloneDeep(this._shopservice.RefundTicketTransactionIDs);
    const isFromRefund = _.cloneDeep(this._shopservice.isFromRefund);
    if (isCancelPressEvent) {
      const isGolfTeeTimeRefund = (this._shopservice.ProductId == myGlobals.Product.GOLF && this._shopservice.transactionId && this._shopservice.isFromRefund);
      if (isGolfTeeTimeRefund && this._shopservice.RefundTicketTransactionIDs.length == 0) {
        this._sbs.TeeTimeRedirection(isCancelPressEvent);
      } else {
        const multiTicketRefundCase = (isGolfTeeTimeRefund && this._shopservice.RefundTicketTransactionIDs.length > 0);
        let redirectPath = this.PropertyInfo.UseRetailInterface && !multiTicketRefundCase ? RedirectToModules.RetailTransactions : RedirectToModules.ReturnWithTicket;
        this.utils.RedirectTo(redirectPath);
      }
    }
    this.ResetShopObject();
    this._shopservice.isFromRefund = isFromRefund;
    this._shopservice.RefundTicketTransactionIDs = refundTranIdTemp;
  }

  CancelAllPaymentCallback(result) {
    if (result) {
      this._sbs.SettlementHistory.forEach(x => {
        if (!x.isReversed) {
          this._sbs.CheckAndReversePayment(x, this._sbs);
          x.isReversed = true;
        }
      });
      this._sbs.tempSettlementHistory = [...this._sbs.SettlementHistory];
      this._sbs.ShowSettlemtHist = this._sbs.SettlementHistory.some(x => !x.isReversed);
      if(!this._sbs.ShowSettlemtHist){
				this._shopservice.TempTicket = this._shopservice.Ticket = _.cloneDeep(this._shopservice.OriginalTicket);
        this._sbs.isRefreshShopServiceItems.emit(true);		
				this.resetTaxExemptObjects();
			}
      this._sbs.CalculateSettledAmount(this.returnTransitionDetails);
      this._sbs.AlterSettlementHistory();
    }
  }

  resetTaxExemptObjects() {
		this._shopservice.totalTaxExemptRatio = 0;
		this._shopservice.taxExemptRatio = 0;
    this._shopservice.isTaxRecalculated = false;
		this._shopservice.remainingAmountAfterPayment = this._shopservice.finalAmount;
	}

  FormReversalParam() {
    let params: ReversalRequestParams = null;
    if (this._sbs.ReturnWithTicketResponse && this._sbs.ReturnWithTicketResponse.transactionData) {
      const checkData = JSON.parse(this._sbs.ReturnWithTicketResponse.transactionData.checkData);
      params = {
        transactionId: this._sbs.ReturnWithTicketResponse && this._sbs.ReturnWithTicketResponse.transactionData.id,
        ticketNumber: checkData && checkData.CheckHandle.CheckNumber,
        outletId: this._shopservice.SelectedOutletId,
        checkHandleGuid: this._sbs.ReturnWithTicketResponse.transactionData.checkHandleGuid,
        terminalId: checkData && checkData.Terminal.Id
      }
    }
    return params;
  }

  ReversePayment(settlement: PaymentHistory) {
    console.log(settlement);
    if (settlement) {
      this._sbs.OpenPaymentReverseConfirmationDialog(settlement
        , this.FormReversalParam()
        , this.ReversePaymentCallBack.bind(this));
    }
  }

  ReversePaymentCallBack(settlement: PaymentHistory) {
    if (settlement) {
      console.log(settlement);
      settlement.isReversed = true;
      this._sbs.ShowSettlemtHist = this._sbs.SettlementHistory.some(x => !x.isReversed);
      this._sbs.tempSettlementHistory = [...this._sbs.SettlementHistory];
      if (!this._sbs.ShowSettlemtHist) {
				this._shopservice.TempTicket = this._shopservice.Ticket = _.cloneDeep(this._shopservice.OriginalTicket);
        this._sbs.isRefreshShopServiceItems.emit(true);		
				this.resetTaxExemptObjects();
			}
			let isAutoRemoveTax = settlement.paymentMethodInfo?.isAutoRemoveTax ?? this._sbs.paymentMethods.find(x => x.paymentTypeId == settlement.paymentMethodId).isAutoRemoveTax
			if (isAutoRemoveTax) {
				let settlementTaxExemptRatio = Number((settlement.amount / this._shopservice.totalAmountWithoutTax).toFixed(2));
				this._shopservice.totalTaxExemptRatio -= settlementTaxExemptRatio;
				this._shopservice.remainingAmountAfterPayment += settlement.amount;
				this._shopservice.exemptTaxForComp(0).then(x => {
          if(x)
          {
            this._sbs.taxExemptCalcTriggered = true;
            this._sbs.isRefreshShopServiceItems.emit(true);		
            setTimeout(() => {
              this._sbs.CalculateSettledAmount(this.returnTransitionDetails);
              this._sbs.CheckAndReversePayment(settlement, this);
              this.PatchRemainingAmountAndHighlight(this._sbs.remainingAmount, true);
              this._sbs.currentAmountTendered = this._sbs.remainingAmount;
            }, 200)
          }
        });
			}
      this._sbs.CalculateSettledAmount(this.returnTransitionDetails);
      this._sbs.CheckAndReversePayment(settlement, this._sbs);
    }
    this._sbs.AlterSettlementHistory();
  }

  async DiscardCreatedCheck() {
    if (this.PropertyInfo.UseRetailInterface && this._shopservice.Ticket) {
      this.transactionService.DiscardCheck(this._shopservice.Ticket.checkData);
      this._shopservice.Ticket = null;
    }
  }
  SetExchangedItemsTax() {
    this.Itemtotal.exchangedItemTax = this._shopservice.Ticket.checkData.totalTax;
    let index = 1;
    if (this.PropertyInfo.UseRetailInterface) {
      this._shopservice.selectedExchangeRetailProducts.forEach(r => {
        if (!r.isGroupingKey) {
          const resultItem = this._shopservice.Ticket.lineItems.find(
            i => i.externalPOSItemId === r.ExternalPOSItemId &&
              i.index === index);
          if (resultItem) {
            r.Tax = resultItem.tax;
            r.Discount = resultItem.discount;
          }
          index++;
        }
      });
    }
    else {
      this._shopservice.selectedExchangeRetailProducts.forEach(r => {
        if (!r.isGroupingKey) {
          const resultItem = this._shopservice.Ticket.lineItems.find(
            i => i.itemId === r.ItemId &&
              i.index === index);
          if (resultItem) {
            r.Tax = resultItem.tax;
            r.Discount = resultItem.discount;            
          }
          index++;
        }
      });
    }
  }

  ModifyReturnRetainExchangeItems(): ReturnWithTicketData {
    let returnData: ReturnWithTicketData = {
      newItem: [],
      retainedItem: [],
      returnedItem: []
    };
    let item: SelectedProducts;
    if (this._shopservice.selectedReturnedRetailProducts.length > 0) {
      let map = {};
      this._shopservice.selectedReturnedRetailProducts.forEach(x => {
        if (x.returnType > ReturnWithTicketType.Default) {
          item = _.cloneDeep(x);
          if (x.returnType == ReturnWithTicketType.RETURNGRATUITYONLY) {
            item.id = 0;
            item.returnType = ReturnWithTicketType.RETURNGRATUITYONLY;
            item.Gratuity = [];
            item.returnLinkId = x.id;
            if (!map[x.id]) {

              let originalItem = this._shopservice.returnWithticketItems.find(y => y.id == x.id);
              let originalQuantity = originalItem?.Noofitems ?? 1;
              if (originalQuantity > item.Noofitems) {
                let retainedDiscount = Number(((originalItem.Discount / originalQuantity) * item.Noofitems).customToFixed());
                let retainedTax = Number(((originalItem.Tax / originalQuantity) * item.Noofitems).customToFixed());
                x.Tax = retainedTax;
                item.Discount = retainedDiscount;
                x.Discount = retainedDiscount;
                let retainQtyPrice = Number((originalItem.ProductPrice * item.Noofitems).customToFixed());
                item.SalesPrice = x.SalesPrice = retainQtyPrice;
                item.originalQuantity = originalItem.Noofitems;
              }
              originalItem.ServiceCharge.forEach(y => {
                y.ServiceCharge = Number(((y.ServiceCharge / originalQuantity) * item.Noofitems).customToFixed());
              })
              returnData.newItem.push(item);
              returnData.returnedItem.push(this.createReturnItemForReturnGratuityAndSc(x));
            }
            else {
              let data = returnData.newItem.find(y => y.returnLinkId == x.id);
              data.ServiceCharge = data.Gratuity = [];
              data.returnType = ReturnWithTicketType.RETURNSERVICECHARGEANDGRATUITY;
            }
            map[x.id] = true;
          }
          if (x.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY) {
            item.id = 0;
            item.returnType = ReturnWithTicketType.RETURNSERVICECHARGEONLY;
            item.ServiceCharge = [];
            item.returnLinkId = x.id;
            if (!map[x.id]) {
              let originalItem = this._shopservice.returnWithticketItems.find(y => y.id == x.id);
              let originalQuantity = originalItem?.Noofitems ?? 1;
              if (originalQuantity > item.Noofitems) {
                let retainedDiscount = Number(((originalItem.Discount / originalQuantity) * item.Noofitems).customToFixed());
                let retainedTax = Number(((originalItem.Tax / originalQuantity) * item.Noofitems).customToFixed());
                item.Discount = retainedDiscount;
                x.Discount = retainedDiscount;
                x.Tax = retainedTax;
                let retainQtyPrice = Number((originalItem.ProductPrice * item.Noofitems).customToFixed());
                item.SalesPrice = x.SalesPrice = retainQtyPrice;
                item.originalQuantity = originalItem.Noofitems;
              }
              originalItem.Gratuity.forEach(y => {
                y.gratuity = Number(((y.gratuity / originalQuantity) * item.Noofitems).customToFixed());
              })
              returnData.newItem.push(item);
              returnData.returnedItem.push(this.createReturnItemForReturnGratuityAndSc(x));
            }
            else {
              let data = returnData.newItem.find(y => y.returnLinkId == x.id);
              data.ServiceCharge = data.Gratuity = [];
              data.returnType = ReturnWithTicketType.RETURNSERVICECHARGEANDGRATUITY;
            }
            map[x.id] = true;
          }
        }
      })
    }
    return returnData;
  }

  private createReturnItemForReturnGratuityAndSc(item) {
    let returnMockItem = _.cloneDeep(item);
    returnMockItem.returnType = ReturnWithTicketType.Default;
    return returnMockItem;
  }

  async InitiateTransactionEvent() {
    this.returnTransitionDetails.controls.amounttendered.updateValueAndValidity();
    if (!this.EnableProcessButton()) { return; }
    if (!this._sbs.selectedpayment.isDefault && this._sbs.paymentText !== this.localization.captions.shop.CloseTransaction) {
      this.paymentComments = this.returnTransitionDetails.controls.paymentComment.value;
    }
    else {
      this.paymentComments = '';
    }

    if (this._sbs.paymentText !== this.localization.captions.shop.CloseTransaction) {
      const property = await this._shopservice.GetPropertySettings();
      const isSystemDateEqual = this.utils.ValidateDatesAreEqual(this.utils.getDate(property.propertyDate), this.PropertyInfo.CurrentDate);
      if (!isSystemDateEqual) {
        this.utils.showAlert(this.localization.getError(-4701), AlertType.Info, myGlobals.ButtonType.YesNo, async (res) => {
          if (res === AlertAction.YES) {
            this.InitiateTransaction();
          }
          else { return; }
        });
      } else {
        this.InitiateTransaction();
      }
    }
    else {
      this.InitiateTransaction();
    }
  }

  async InitiateTransaction() {

    let quickIdConfig = this.PropertyInfo.getQuickIdConfig;
    if (!this.quickLoginPopupEnabled) {
      if (quickIdConfig && quickIdConfig.QUICKID_RETAILTRANSACTIONS) {
        const quickLoginDialogRef = this.commonUtils.QuickLogin();
        quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {

          if (quickLoginDialogResult.isLoggedIn) {
            if (this.IsEnableRefundToOriginalSettlement && this.returnTransitionDetails.controls.refundToOriginal.value && this._sbs.paymentText !== this.localization.captions.shop.CloseTransaction) {
              this.ShowReturnSettlementInfoDialog();
            }
            else {
              this.ProceedTransaction();
            }
            this._sbs.quickIdUser = sessionStorage.getItem('quickIdUser');
            this.quickLoginPopupEnabled = true;
          }
        });
      }
      else {
        if (this.IsEnableRefundToOriginalSettlement && this.returnTransitionDetails.controls.refundToOriginal.value && this._sbs.paymentText !== this.localization.captions.shop.CloseTransaction) {
          this.ShowReturnSettlementInfoDialog();
        } else {
          this.ProceedTransaction();
        }
        this.quickLoginPopupEnabled = true;
      }
    }
    else {
      if (this.IsEnableRefundToOriginalSettlement && this.returnTransitionDetails.controls.refundToOriginal.value && this._sbs.paymentText !== this.localization.captions.shop.CloseTransaction) {
        this.ShowReturnSettlementInfoDialog();
      } else {
        this.ProceedTransaction();
      }
      this.quickLoginPopupEnabled = true;
    }

  }

  CheckReturnReason() {
    this.returnTransitionDetails.controls.returnReason.updateValueAndValidity();
    if (this.returnTransitionDetails.controls.returnReason.invalid) {
      this.returnTransitionDetails.controls.returnReason.markAsTouched();
      this.returnReasonElement.nativeElement.focus();
      return false;
    }
    return true;
  }

  ProceedTransaction() {
    if(this._sbs.validateCreditLimitForRoomGroupCharge && this.AmountEntered > 0){
			if(this.AmountEntered > this._sbs.selectedGuestRoom.creditLimit) {
				this.utils.showAlert(this.localization.getError(110060), AlertType.Error, ButtonType.Ok);
				return;
			}
		}
    if (!this.EnableProcessButton()) { return; }
    if (this._shopservice.selectedPayeeId == 0 && this._shopservice.selectedProducts &&
      this._shopservice.selectedProducts.filter(x => x.isRequestName).length > 0 && this._sbs.paymentText !== this.localization.captions.shop.CloseTransaction) {
      this.utils.showAlert(this.localization.captions.shop.RequiredGuest, AlertType.Error, myGlobals.ButtonType.Ok);
      return;
    }
    this.selectedClient = this._shopservice.selectedclient;
    if (!this.CheckReturnReason()) return;

    const receiptComment = this.returnTransitionDetails.controls.receiptComment.value;
    if (!this.PropertyInfo.UseRetailInterface) {
      if (((this._sbs.GatewayConfiguration && this._sbs.GatewayConfiguration.isAVSActive
        && (this._sbs.selectedPaymentMethod === PaymentMethods.CreditCard
          || this._sbs.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile))))
        && this.returnTransitionDetails.controls.zipcode.value == '') {
        this.returnTransitionDetails.controls.zipcode.updateValueAndValidity();
        this.returnTransitionDetails.controls.zipcode.markAsTouched();
        return;
      }
    }

    if (this.IsNoTherapistAssigned(this._shopservice.selectedExchangeRetailProducts)) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.NoTherapistAssociated);
      return;
    }

    if (!this._sbs.CheckIfGuestIsSelected(this.returnTransitionDetails)) {
      return;
    }
    this._sbs.CalculateSettledAmount(this.returnTransitionDetails);
    if ((Math.abs(this._shopservice.finalAmount) == Math.abs(this._sbs.SettledAmt) || this.IsPartialRefundOriginalSettlement) && this._sbs.paymentText == this.localization.captions.shop.CloseTransaction) { // Refund
      // Refund happened
      let dataObj = {};
      if (this._sbs.IsRefundFlow) {
        this.DiscardCreatedCheck();
        const paymentCloseText = this.localization.captions.shop.RefundSuccessMsg;
        dataObj = { 'text': paymentCloseText, 'buttonname': this.localization.captions.common.Close, 'headertext': this.localization.captions.shop.Refund, 'icon': 'icon-success-icon' }
      } else {
        const paymentCloseText = this.localization.replacePlaceholders(this.localization.captions.shop.TicketCreationSuccess, ["checkNumber"], [this._sbs.ReturnWithTicketResponse.transactionData.retailTicketNumber]);
        dataObj = { 'text': paymentCloseText, 'buttonname': this.localization.captions.common.Close, 'headertext': this.localization.captions.shop.PaymentComplete, 'icon': 'icon-success-icon' }
      }

      this._sbs.roomOpenDialog(dataObj);
      this._sbs.HandlePostReturnRedirection();
      this.ResetShopObject();
      this._ams.paymentProcessing = false;
      this._shopservice.GoToRetailTransaction = true;
      this._shopservice.CalculateReturnWithTicketCommission(this._sbs.ReturnWithTicketResponse.transactionData.id, this.utils.convertDateFormat(this.PropertyInfo.CurrentDate));
      return;
    }

    //If Refund not started and for Payment methods which have confirmation popups
    if (!this.PropertyInfo.UseRetailInterface) {
      if (this._sbs.remainingAmount == this._shopservice.finalAmount
        && this._sbs.SettlementHistory && this._sbs.SettlementHistory.length == 0) {

        //CMS and Adyen Gateway CC Refund has confirmation popup in Refunds too
        if (this._sbs.IsRefundFlow) {
          this._sbs.ConfirmationPopupShown = true;
          if (this._sbs.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption) || this._sbs.IsOfferOrCompSlipPaymentMethod) {
            this._sbs.PerformCMSRefundFlow(this, this.returnTransitionDetails);
            return;
          }
          if (this._sbs.isAdyenWithAllEntryMode() || this._sbs.IsRefundToCOF) {
            this._sbs.OpenPaymentDialog(this, this.returnTransitionDetails);
            return;
          }
        } else { // Collect Mode
          /*
            Before triggering ReturnWithTicket user can cancel it via the confirmation popups
            So Showing the confirmation popup
          */
          if (!this._sbs.ReturnWithTicketResponse && this._sbs.MethodsWhichHasPaymentConfirmationPopup.includes(this._sbs.selectedPaymentMethod) ||
            this._sbs.isAdyenWithAllEntryMode()) {
            this._sbs.OpenPaymentDialog(this, this.returnTransitionDetails);
            return;
          }
        }
      }
    }

    if (!this._sbs.ReturnWithTicketResponse) {
      if (this.IsMemberRefund && !this._sbs.CheckIfFullMemberRefund(this.returnTransitionDetails)) {
        this._sbs.OpenPaymentDialog(this, this.returnTransitionDetails);
      }
      else {
        this.ReturnwithTicket();
      }
    }
    else if (this._sbs.ReturnWithTicketResponse && this.IsMemberRefund) {
      if (!this._sbs.CheckIfFullMemberRefund(this.returnTransitionDetails)) {
        this.IsProceedReturn = true;
        this._sbs.OpenPaymentDialog(this, this.returnTransitionDetails);
      }
      else {
        this._sbs.MakeSale(this, this.returnTransitionDetails);
      }
    }
    else {
      if(this._shopservice.finalAmount < 0 && !this.IsRefundToOriginalSettlement){
        this._sbs.MakeSale(this, this.returnTransitionDetails);
      }
      else{
        this.TenderRetainData();
      }
      
      this.isEnablePaymentComment = false;
      this.returnTransitionDetails.controls.paymentComment.setValue("");
    }
  }

  RefundToOriginalSettlements() {
    if (!this._sbs.ReturnWithTicketResponse || !this._sbs.ReturnWithTicketResponse.transactionData) { return; }
    this._sbs._isAPICallInProgress = true;
    this._ams.loaderEnable.next(this.localization.captions.shop.RefundToOriginalSettlementsInProgress);
    const transactionData = this._sbs.ReturnWithTicketResponse && this._sbs.ReturnWithTicketResponse.transactionData;
    const uriParams = {
      transactionId: transactionData.id,
      ticketNumber: transactionData.retailTicketNumber,
      outletId: this._shopservice.SelectedOutletId,
      checkHandleGuid: transactionData.checkHandleGuid,
      terminalId: transactionData.terminalId
    };
    this.InvokeServiceCall('RefundAllPayments', HttpMethod.Post, uriParams, '', '', false);
  }

  PartialRefundToOriginalSettlements() {
    if (!this._sbs.ReturnWithTicketResponse || !this._sbs.ReturnWithTicketResponse.transactionData) { return; }
    this._sbs._isAPICallInProgress = true;
    this._ams.loaderEnable.next(this.localization.captions.shop.RefundToOriginalSettlementsInProgress);
    const transactionData = this._sbs.ReturnWithTicketResponse && this._sbs.ReturnWithTicketResponse.transactionData;
    const uriParams = {
      transactionId: transactionData.id,
      ticketNumber: transactionData.ticketNumber,
      outletId: this._shopservice.SelectedOutletId,
      checkHandleGuid: transactionData.checkHandleGuid,
      terminalId: transactionData.terminalId,
      amountToRefund: this._sbs.getSaleAmt(true)
    };
    this.InvokeServiceCall(RetailRoutes.RefundPartialPayments, HttpMethod.Post, uriParams, '', '', false);
  }

  printReceipt(result) {
    let transactionId: number = result.id;
    let ticketNumber: string = result.transactionData.ticketNumber;



    RetailDataAwaiters.PrintAgreement(transactionId);
    this.receiptService.GenerateReceipt(ticketNumber, this._shopservice.SelectedOutletId,
      myGlobals.ReceiptType.sales, transactionId, this._shopservice.memberCardNumber.toString(), this.ClientName);
  }

  ReturnwithTicket() {
    let param: any = { transactionId: this._shopservice.transactionId, productId: this._shopservice.ProductId };
    let returnWithServiceOrGratuity: ReturnWithTicketData = this.ModifyReturnRetainExchangeItems();
    let body: ReturnTicketData = {
      reason: this.returnTransitionDetails.controls.returnReason.value,
      receiptComment: this.returnTransitionDetails.controls.receiptComment.value,
      retainedItem: this.BuildAPIShopItemData([...this._shopservice.selectedRetainedRetailProducts, ...returnWithServiceOrGratuity.retainedItem]),
      returnedItem: this.BuildAPIShopItemData([...this._shopservice.selectedReturnedRetailProducts, ...returnWithServiceOrGratuity.returnedItem]),
      newItem: this.BuildAPIShopItemData([...this._shopservice.selectedExchangeRetailProducts, ...returnWithServiceOrGratuity.newItem], true),
      guestId: this._shopservice.selectedPayeeId,
      MachineNameId: this._shopservice.ProductId == myGlobals.Product.PMS ? 0 : this.localization.GetMachineId(),
      emailId: this._retailService.manualEmailId
    };
    if (this._shopservice.selectedReturnedRetailProducts.some(x => x.returnType > ReturnWithTicketType.Default)) {
      body.returnedItem.forEach(x => {
        let serviceData = this._shopservice.selectedReturnedRetailProducts.filter(y => y.id == x.id);
        if (serviceData && serviceData.length == 1) {
          x.returnType = serviceData[0].returnType;
        }
        if (serviceData && serviceData.length == 2) {
          x.returnType = ReturnWithTicketType.RETURNSERVICECHARGEANDGRATUITY;
        }
      })
    }
    if (this._shopservice.selectedMultiPackExchangedProducts.length > 0 && body && body.newItem) {
      let ln = 0;
      if (body.retainedItem.length > 0) {
        ln = ln + body.retainedItem.length;
      }
      ln = ln + body.newItem.length + 1;
      this._shopservice.selectedMultiPackExchangedProducts.forEach(x => {
        x.LineNumber = ln;
        ln = ln + 1;
      });
      let multipackItems = this.BuildAPIShopItemData(this._shopservice.selectedMultiPackExchangedProducts, true);
      if (multipackItems.length > 0)
        body.newItem = body.newItem.concat(multipackItems);

    }
    //Retaining the Giftcard items for future transactions, since Giftcard items can't be returned/viewed in screen.
    if (this._shopservice.retainedGiftCards && this._shopservice.retainedGiftCards.length > 0) {
      let additionalItems = this.BuildAPIShopItemData(this._shopservice.retainedGiftCards);
      body.retainedItem = body.retainedItem.concat(additionalItems);
    }


    this.DiscardCreatedCheck();
    console.log("Return with  --> ", body);
    this.InvokeServiceCall('RetunWithticket', HttpMethod.Put, param, body);

  }

  private BuildAPIShopItemData(item: SelectedProducts[], isNewItem: boolean = false): RetailShopItem[] {
    let shopItems: RetailShopItem[] = [];
    let LineNumber: number = 1;
    let matchingLineItem: LineItem;
    for (const element of item) {
      if (element.isGroupingKey && !element.isPackagedItem) continue;
      if (!isNewItem && element.returnType > ReturnWithTicketType.Default) continue;
      var servCharge = [];
      let totalGratuity = 0;
      let totalSc = 0;
      if (element.Gratuity && element.Gratuity.length > 0) {
        var allGratuities: ServiceChargeGratuity[] = [];
        for (const grat of element.Gratuity) {
          if (grat.gratuity > 0) {
            let gratTax = element?.Gratuity.reduce((a,b) => a + b.GratuityTax,0);
            allGratuities.push({
              Date: this.utils.convertDateFormat(this.PropertyInfo.CurrentDate),
              TherapistId: grat.TherapistId,
              ItemId: element.ItemId ? element.ItemId : 0,
              ServiceChargePercent: 0,
              GratuityPercent: grat.Percentage ? grat.Percentage : 0,
              ServiceCharge: 0,
              Gratuity: grat.gratuity,
              Id: 0,
              TransactionDetailId: grat.TransactionDetailId ? grat.TransactionDetailId : 0,
              TotalGratuity: 0,
              TotalServiceCharge: 0,
              StaffType: element.ItemType != RetailItemType.RevenueItem ? grat.StaffType : "USER",
              GratuityTax:  gratTax ? gratTax : 0
            });
            totalGratuity += grat.gratuity;
          }
        }
        allGratuities.forEach(r => r.TotalGratuity = totalGratuity);
        servCharge.push(...allGratuities);
      }

      if (element.ServiceCharge && element.ServiceCharge.length > 0) {
        var allServiceCharges: ServiceChargeGratuity[] = [];
        for (const sc of element.ServiceCharge) {
          if (sc.ServiceCharge > 0) {
            let servTax = element?.ServiceCharge.reduce((a,b) => a + b.ServiceChargeTax,0);
            allServiceCharges.push({
              Date: this.utils.convertDateFormat(this.PropertyInfo.CurrentDate),
              TherapistId: sc.TherapistId,
              ItemId: element.ItemId ? element.ItemId : 0,
              ServiceChargePercent: sc.Percentage ? sc.Percentage : 0,
              GratuityPercent: 0,
              ServiceCharge: sc.ServiceCharge,
              Gratuity: 0,
              Id: 0,
              TransactionDetailId: sc.TransactionDetailId ? sc.TransactionDetailId : 0,
              TotalGratuity: 0,
              TotalServiceCharge: 0,
              StaffType: element.ItemType != RetailItemType.RevenueItem ? sc.StaffType : "USER",
              ServiceChargeTax: servTax ? servTax : 0
            });
            totalSc += sc.ServiceCharge;
          }
        }
        allServiceCharges.forEach(r => r.TotalServiceCharge = totalSc);
        servCharge.push(...allServiceCharges);
      }

      if (isNewItem && this._shopservice.Ticket && this._shopservice.Ticket.lineItems && this._shopservice.Ticket.lineItems.length > 0) {
        matchingLineItem = this.PropertyInfo.UseRetailInterface ? this._shopservice.Ticket.lineItems.
          find(r => r.externalPOSItemId === element.ExternalPOSItemId && r.index === LineNumber) :
          this._shopservice.Ticket.lineItems.
            find(r => r.itemId === element.ItemId && r.index === LineNumber);
      }
      const tempUnitPrice = element.netUnitPrice ? element.netUnitPrice : Number(element.ProductPrice.customToFixed());
      const tempSaleItem = element.SalesPrice ? Number(element.SalesPrice.customToFixed()) : 0;
      const tempMatchingLineItem = element.netPrice ? element.netPrice : tempSaleItem;
      const tempUnitPriceWithoutDiscount = element.netUnitPriceWithoutDiscount ? element.netUnitPriceWithoutDiscount :
        Number(element.ProductPrice.customToFixed());
      const obj: RetailShopItem = {
        id: element.id ? element.id : 0,
        ItemId: element.ItemId,
        ItemType: element.ItemType,
        ItemDescription: element.ItemDescription,
        ExternalPOSItemId: element.ExternalPOSItemId,
        QuantitySold: element.Noofitems,
        UnitPrice: element.ProductPrice ? Number(element.ProductPrice.customToFixed()) : 0,
        Discount: element.Discount,
        DiscountTypeId: element.DiscountTypeId,
        DiscountPercentage: element.DiscountPercentage,
        StaffTransactionDetail: element.StaffTransactionDetail,
        LineNumber: element.LineNumber ? element.LineNumber : LineNumber,
        Tax: element.Tax ? Number(element.Tax.customToFixed()) : 0,
        BaseTax: element.BaseTax ? Number(element.BaseTax.customToFixed()) : 0,
        LinkedTax: element.LinkedTax ? Number(element.LinkedTax.customToFixed()) : 0,
        TotalAmount: element.SalesPrice ? Number(element.SalesPrice.customToFixed()) : 0,
        OutletId: this._shopservice.SelectedOutletId,
        IsGroupingKey: element.isGroupingKey,
        IsModificationRestricted: element.isModificationRestricted ? element.isModificationRestricted : false,
        IsPackagedItem: false, // element.isPackagedItem, TO DO ::
        PackageItemId: element.PackageItemId ? element.PackageItemId : 0,
        IsMultiPackRedeem: element.MultiPack,
        ClientMultiPackId: element.ClientMultiPackId,
        PackageGroupId: element.PackageGroupId,
        IsOpenPricedItem: element.isOpenPricedItem,
        transactionDetailLinkId: element.transactionDetailLinkId ? element.transactionDetailLinkId : 0,
        unitOfMeasureId: element.unitOfMeasureId ? element.unitOfMeasureId : 0,
        scaledUnit: element.scaledUnits ? Number(element.scaledUnits) : 0,
        IsTaxExempt: element.isTaxExempt ? element.isTaxExempt : false,
        discountModel: {
          index: 0,
          isPercentage: element.DiscountPercentage > 0,
          value: element.DiscountPercentage > 0 ? element.DiscountPercentage : element.Discount
        },
        netUnitPrice: matchingLineItem ? matchingLineItem.netUnitPrice : tempUnitPrice,
        netPrice: matchingLineItem ? matchingLineItem.netPrice : tempMatchingLineItem,
        netUnitPriceWithoutDiscount: matchingLineItem ? matchingLineItem.netUnitPriceWithoutDiscount : tempUnitPriceWithoutDiscount,
        itemComments: element.itemComments,
        costPrice: element.costPrice,
        marginPercentage: element.marginPercentage,
        allowEarn: element.allowEarn,
        discountComments: element.discountComments,
        discountReason: element.discountReason,
        returnType: element.returnType,
        returnLinkId: element.returnLinkId,
        originalQuantity: element.originalQuantity
      }

      if (element.Commission && element.Commission.length > 0) {
        element.Commission.forEach(c => c.id = 0);
        obj.Commission = element.Commission
      }

      if (servCharge) {
        obj.ServiceChargeGratuity = servCharge;
      }

      if (element.isGiftCardItem) {
        let giftcardObj = _.cloneDeep(element.GiftCardTransactionItem);
        giftcardObj.handleInfo = typeof (giftcardObj.handleInfo) == "string" ? giftcardObj.handleInfo : JSON.stringify(giftcardObj.handleInfo);
        obj.GiftCardTransactionItem = giftcardObj;
        //Updating item description if it doesn't have card number
        if (giftcardObj?.cardNumber && element.ItemDescription && !element.ItemDescription?.includes(giftcardObj.cardNumber)) {
          element.ItemDescription = `${element.ItemDescription} ${giftcardObj.cardNumber}`;
        }
      }

      shopItems.push(obj);
      LineNumber++;
    }
    return shopItems;
  }


  InvokeServiceCall(route: string, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any, showError: boolean = true) {
    this._sbs._isAPICallInProgress = true;
    this._ams.loaderEnable.next(this.localization.captions.shop.TransactionInprogressMsg);
    this.http.CallApiWithCallback<any>({
      host: myGlobals.Host.retailPOS,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: showError,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  TenderRetainData() {
    if (this._sbs.selectedPaymentMethod === PaymentMethods.PendingSettlement) {
      if (this.PropertyInfo.UseRetailInterface) {
        this.transactionService.SaveTicket(this._sbs.ReturnWithTicketResponse.transactionData.ticketNumber,
          this._sbs.ReturnWithTicketResponse.transactionData.checkHandleGuid,
          Number(this._shopservice.SelectedOutletId));
      }
      this.GotoRetailTransaction(true);
      return;
    }
    if (this._shopservice.finalAmount > 0 && this._sbs.paymentText !== this.localization.captions.shop.CloseTransaction) { // Collect
      this._sbs.MakeSale(this, this.returnTransitionDetails);
    } else if (this.IsRefundToOriginalSettlement) {
      if (this._sbs.IsCompleteReturn) {
        this.RefundToOriginalSettlements();
      }
      else {
        this.PartialRefundToOriginalSettlements();
      }
    } else if (this._shopservice.finalAmount === 0) {
      this._sbs.CheckSettlementStatusAndUpdateButtons(this);
    }
    // var uriParams = {
    //   transactionId: this._sbs.ReturnWithTicketResponse.transactionData.id,
    //   ticketNumber: this._sbs.ReturnWithTicketResponse.transactionData.ticketNumber,
    //   outletId: this._shopservice.SelectedOutletId,
    //   checkHandleGuid: this._sbs.ReturnWithTicketResponse.transactionData.checkHandleGuid,
    //   terminalId: this._shopservice.SelectedTerminalId
    // };
    // this.InvokeServiceCall('TenderRetainedData', HttpMethod.Post, uriParams, !this._sbs.IsRefundFlow);
  }

  RefundSale(PaymentReferenceId) {
    // TODO check 
    const uriParams = {
      transactionId: this._sbs.ReturnWithTicketResponse.transactionData.id,
      ticketNumber: this._sbs.ReturnWithTicketResponse.transactionData.ticketNumber,
      outletId: this._shopservice.SelectedOutletId,
      checkHandleGuid: this.PropertyInfo.UseRetailInterface ?
        this._sbs.ReturnWithTicketResponse.transactionData.checkHandleGuid : this.utils.generateGUID(),
      tenderId: this._sbs.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile) ?
        PaymentMethods.CreditCard : this._sbs.selectedpayment.paymentTypeId,
      paymentReferenceId: PaymentReferenceId,
      terminalId: this._shopservice.SelectedTerminalId
    };
    let SaleAmount: number = 0;
    if (this._sbs.partialPayment) {
      SaleAmount = this.localization.currencyToSQLFormat(this.returnTransitionDetails.controls.amounttendered.value);
      this._sbs.ShowSettlemtHist = true;
    } else {
      SaleAmount = Number(this._shopservice.finalAmount.customToFixed());
    }
    let body = {
      refundAmount: -SaleAmount,
      allowEarn: this._sbs.selectedpayment.allowEarn,
      tenderId: this._sbs.selectedpayment?.paymentTypeId??0,
      parentTenderId: this._sbs.selectedpayment?.parentTypeId??0,
      paymentMethodId: this._sbs.selectedpayment?.id??0,
    };
    this.InvokeServiceCall('Refund', HttpMethod.Post, uriParams, body);
  }

  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any) {
    this._sbs._isAPICallInProgress = false;
    this._ams.loaderEnable.next("");
    switch (callDesc) {
      case "RetunWithticket":
        this.isEnablePaymentComment = false;
        this.returnTransitionDetails.controls.paymentComment.setValue("");
        let response = <any>result.result;
        this.CreateRetailItemResponse = response; // Activities - retail integration changes
        console.log("ress", response);
        this._sbs.ReturnWithTicketResponse = response;
        let eventData: RetailEventParameters<TicketChangeEventModel> =
        {
          data: {
            oldTransactionId: this._shopservice.transactionId,
            transactionId: response.id,
            transactionDetail: response.transactionDetails
          },
          eventType: RetailEventType.ReturnWithTicket
        };
        retailPublisher.publishEvent(eventData);
        if (this.PropertyInfo.UseRetailInterface) {
          await this._sbs.PerformPostReturnOperation(this._shopservice.selectedReturnedRetailProducts, this._shopservice.selectedRetainedRetailProducts);
          this._sbs.HandlePostReturnRedirection();
          this.GotoRetailTransaction();
          return;
        }
        this._ams.paymentProcessing = true;
        if (this._shopservice.finalAmount < 0 && !this.IsRefundToOriginalSettlement) {
          this._sbs.MakeSale(this, this.returnTransitionDetails);
          return;
        } else {
          this.TenderRetainData();
        }


        if (this._sbs.partialPayment && this._sbs.SettledAmt != this._shopservice.finalAmount) {
          return;
        }
        else {
          this._shopservice.selectedPayeeId = 0;
        }
        break;
      case "TenderRetainedData":
        if (this.PropertyInfo.UseRetailInterface && this._sbs.SelectedPaymentMethodEquals(PaymentMethods.PendingSettlement)) {
          this.transactionService.SaveTicket(this._sbs.ReturnWithTicketResponse.transactionData.ticketNumber,
            this._sbs.ReturnWithTicketResponse.transactionData.checkHandleGuid,
            Number(this._shopservice.SelectedOutletId));
          this.GotoRetailTransaction(true);
          return;
        }
        let TenderRetainDataresponse = <any>result.result;
        if (this._shopservice.finalAmount > 0 && this._sbs.paymentText != this.localization.captions.shop.CloseTransaction) { // Collect
          this._sbs.MakeSale(this, this.returnTransitionDetails);
        } else if (this.IsRefundToOriginalSettlement) {
          if (this._sbs.IsCompleteReturn) {
            this.RefundToOriginalSettlements();
          }
          else {
            this.PartialRefundToOriginalSettlements();
          }
        } else if (this._shopservice.finalAmount == 0) {
          this._sbs.CheckSettlementStatusAndUpdateButtons(this);
        }
        break;
      case "Refund":
        let RefundSaleresponse = <any>result.result;
        this.isEnablePaymentComment = false;
        this.returnTransitionDetails.controls.paymentComment.setValue("");
        if(RefundSaleresponse)
        {
          this._sbs.CheckSettlementStatusAndUpdateButtons(this);
        }
        if (this._sbs.paymentText == this.localization.captions.shop.CloseTransaction) {
          this.TenderRetainData();
        }
        break;
      case "RefundAllPayments":
        this._sbs._isAPICallInProgress = false;
        this._ams.loaderEnable.next("");
        const refundRes = result.result;
        if (refundRes) {
          this._sbs.SettledAmt = this._sbs.remainingAmount;
          this._sbs.CheckSettlementStatusAndUpdateButtons(this);
        }
        break;      
      case RetailRoutes.RefundPartialPayments:
        this._sbs._isAPICallInProgress = false;
        this._ams.loaderEnable.next("");
        const PartialrefundRes = result.result;
        if (PartialrefundRes) {
          this._sbs.SettledAmt = this._shopservice.finalAmount;
          this._sbs.CheckSettlementStatusAndUpdateButtons(this);
        }
        break;
        case "CreateSettlementHistory":
          // Activities - retail integration changes
          RetailDataAwaiters.ProcessPartyTransaction(this,); // Activities - retail integration changes
          break;
      default:
        break;
    }
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]) {
    if (callDesc == "RefundAllPayments" || callDesc == RetailRoutes.RefundPartialPayments) {
      if(error?.errorCode == -5 || error?.errorCode == -6 || error?.errorCode == -7 || error?.errorCode == -8){
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, error?.errorDescription);
      }
      else{
        const errorMsg = this.localization.getUnexpectedErrorMessage();
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorMsg);
      }          
    }
    this._sbs._isAPICallInProgress = false;
    this._ams.loaderEnable.next("");     
  }

  async GetPaymentHistory(transactionId: number) {
    this._sbs.PrevSettlementHistory = await this._sbs.GetPaymentHistory(transactionId);
  }

  ResetShopObject() {
    this._shopservice.selectedExchangeRetailProducts = [];
    this._shopservice.selectedTransactionRetailProducts = [];
    this._shopservice.selectedReturnedRetailProducts = [];
    this._shopservice.selectedExchangeRetailProducts = [];
    this._shopservice.selectedRetainedRetailProducts = [];
    this._shopservice.returnWithticketItems = [];
    this._shopservice.retainedGiftCards = [];
    this._shopservice.selectedPayeeId = 0;
    this._shopservice.selectedclient = null;
    this._shopservice.memberCardNumber = "0";
    this._ams.labelRecords = [];
    this._ams.clientsearchArray = [];
    this._shopservice.isReturnWithoutTicket = false;
    this._shopservice.returnItemTotal = 0;
    this._shopservice.retainedItemTotal = 0;
    this._shopservice.exchangedItemTotal = 0;
    this._shopservice.finalAmount = 0;
    this._shopservice.selectedProducts = [];
    this._shopservice.selectedRetailItem = null;
    this._shopservice.isFromRefund = false;
    this._shopservice.destroy();
    this._sbs.isCardTransactionFromRtrnTkt = false;
    this._shopservice.selectedResvProducts = [];
    this._shopservice.taxExemptRatio = 0;
    this._shopservice.totalTaxExemptRatio = 0;
    this._shopservice.totalAmountWithoutTax = 0;
    this._shopservice.remainingAmount = 0;
    this._shopservice.remainingAmountAfterPayment = 0;
  }

  searchCMSPlayer() {
    this._sbs.searchCMSPlayer(this);
  }

  viewPrevMenu() {
    this.rightArrowDisabled = false;
    let element = this.btnView.nativeElement;
    let scrollValue = element.scrollLeft - 68;
    element.scrollLeft = scrollValue;

    if (scrollValue <= 0) {
      this.leftArrowDisabled = true;
    }
  }

  viewNextMenu() {

    this.leftArrowDisabled = false;
    this.rightArrowDisabled = true;
    let element = this.btnView.nativeElement;
    let scrollValue = element.scrollLeft + 68;
    let scrollWidth = element.scrollWidth;
    let offsetWidth = element.offsetWidth;
    element.scrollLeft = scrollValue;

    if ((scrollWidth - (scrollValue + offsetWidth)) > 0) {
      this.rightArrowDisabled = false;
    }
  }

  SelectGuest(guestObj) {
    this._sbs.selectedDeviceHandle = guestObj.handle;
  }

  IsNoTherapistAssigned(products: SelectedProducts[]): boolean {
    let isNoTherapist: boolean = false;
    for (let element of products) {
      if (element.Gratuity && element.Gratuity.length > 0) {
        for (let gr of element.Gratuity) {
          if (gr.gratuity > 0 && !gr.TherapistId) {
            isNoTherapist = true;
            break;
          }
        }
      }
      if (isNoTherapist) break;
      if (element.ServiceCharge && element.ServiceCharge.length > 0) {
        for (let sc of element.ServiceCharge) {
          if (sc.ServiceCharge > 0 && !sc.TherapistId) {
            isNoTherapist = true;
            break;
          }
        }
      }
    }
    return isNoTherapist;
  }

  EnableProcessButton(): boolean {
    let enable = false;

    if(this.returnTransitionDetails.controls.returnReason.invalid){ return false; };

    //If Refund to Original settlement is enabled, no need for further validation
    if (this.IsRefundToOriginalSettlement) { return true; }

    //If the payment text is close transaction, then by default, the button should be enabled.
    if (this._sbs.paymentText && this._sbs.paymentText.trim().toLowerCase() == this.localization.captions.shop.CloseTransaction.trim().toLowerCase()) {
      return true;
    }
    let validateRoomCharge = this._sbs.IsRoomOrGroupCharge ? this._sbs.selectedDeviceHandle != '' && this._sbs.selectedGuestRoom != null
      : true;

    let validateHotelComp = this._sbs.IsHotelCompPayment ? this._sbs.selectedDeviceHandle != '' && this._sbs.selectedGuestRoom != null
      : true;

    let validateCreditCardMethods = this.creditCardMethods.includes(this._sbs.selectedPaymentMethod) ?
      this.returnTransitionDetails.controls.selectedDevice.valid : this.returnTransitionDetails.invalid;
    let validateAmtTenderedField = this._sbs.partialPayment ? (
      (((this.returnTransitionDetails.controls.amounttendered.enabled && this.returnTransitionDetails.controls.amounttendered.valid)
        || this.returnTransitionDetails.controls.amounttendered.disabled)
        && this._sbs.EnableCloseTranBtn) || (this._sbs.remainingAmount == 0 && this.returnTransitionDetails.controls.amounttendered.valid)) : this.returnTransitionDetails.invalid;
    let isZipcodevalid = (this.creditCardMethods.includes(this._sbs.selectedPaymentMethod) && this._sbs.GatewayConfiguration
      && this._sbs.GatewayConfiguration.isAVSActive) ? this.returnTransitionDetails.controls.zipcode.valid : true;
    let disableChecker = (this.returnTransitionDetails.controls.amounttendered.disabled || !this._sbs.partialPayment || (this._sbs.remainingAmount == 0) ? true : this.returnTransitionDetails.controls.amounttendered.valid);
    let validateComp = !this._sbs.IsRefundFlow && this._sbs.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption) ? ((this._sbs.selectedDeviceHandle != '' || this._sbs.selectedCMSType != null) && this.CMSForm.controls['cmsPaymentSelection'].value != '') :
      disableChecker;
    let validateOffer = !this._sbs.IsRefundFlow && this._sbs.SelectedPaymentMethodEquals(PaymentMethods.OfferRedemption) ? (this._sbs.selectedVoucher != null && this.CMSForm.controls['voucherSelection'].value != '') : true;
    let validateCompSlip = !this._sbs.IsRefundFlow && this._sbs.SelectedPaymentMethodEquals(PaymentMethods.CompSlipRedemption) ? (this._sbs.selectedVoucher != null && this._sbs.selectedCMSPlayer != null) : true;
    let validateGiftcard = this._sbs.isGiftCardSelected ? (this._sbs.selectedDeviceHandle != '' || this.IsRefundAsGiftcardFlow) && this._sbs.selectedGiftCard != null : true;
    let validateArAcct = this._sbs.SelectedPaymentMethodEquals(PaymentMethods.ARAcctPost) ? this._sbs.selectedARAccount != null : true;
    let validatePaymentComment = (this.returnTransitionDetails.controls.paymentComment.valid || !this.isPaymentCommentRequired);
    let validateCMSPin = this.IsCompOrOfferRedemption && this.IsCMSRequirePin ? (!this.CMSForm.controls.cmsPin.value || this.CMSForm.controls.cmsPin.valid) : true;
    let validateWallet = !this._sbs.IsRefundFlow && this._sbs.SelectedPaymentMethodEquals(PaymentMethods.Wallet) ? (this.returnTransitionDetails.controls.walletInput.value != 0 && this.returnTransitionDetails.controls.walletInput.valid)	: true;
    let isShopTicketAvailable = (this._shopservice?.selectedExchangeRetailProducts?.length??0 > 0) ? this._shopservice?.Ticket != null : true;

    enable = !this._sbs._isAPICallInProgress && (this._sbs.ifPaymentChoose || this.PropertyInfo.UseRetailInterface) && this._shopservice.selectedReturnedRetailProducts.length > 0
      && isZipcodevalid
      && validateCreditCardMethods
      && validateRoomCharge
      && validateHotelComp
      && validateComp
      && validateOffer
      && validateCMSPin
      && validateCompSlip
      && validateGiftcard
      && validateArAcct
      && validateAmtTenderedField
      && validatePaymentComment
      && validateWallet
      && isShopTicketAvailable

    return enable;
  }

  get validateRoomCharge() {
    let enablebtn = true;
    if (this._sbs.SelectedPaymentMethodEquals(PaymentMethods.RoomCharge)) {
      if (this._sbs.GuestAccounts && this._sbs.GuestAccounts == 0) {
        enablebtn = this.returnTransitionDetails.controls.roomnumber.valid;
      } else {
        enablebtn = this.returnTransitionDetails.controls.roomnumber.valid && this.returnTransitionDetails.controls.selectedGuest.valid;
      }
    }
    return enablebtn;
  }

  async GetPayeeDetails(payeeId: number) {
    if (!payeeId) {
      return;
    }
    let payeeInfo = await RetailDataAwaiters.getPayeeInfo(payeeId);
    if (payeeInfo) {
      this._sbs.CheckForCardOnFile(payeeInfo);
      if (this._ams.clientSelect != 1 && !this.PropertyInfo.UseRetailInterface && payeeInfo.guestProfileId && payeeInfo.guestProfileId != myGlobals.DefaultGUID)
        this._sbs.roomDetailsArray = await this._shopservice.GetAllRoomsForGuest(payeeInfo.guestProfileId);
      // TO Auto select the room retrieved from Activity result
      if (this._sbs.roomDetailsArray && this._sbs.roomDetailsArray?.length == 1) {
        this.returnTransitionDetails.controls.activityRoomSelection.setValue(this._sbs.roomDetailsArray[0].roomNo);
        this.SelectRoom(this._sbs.roomDetailsArray[0]);
      } else {
        this.returnTransitionDetails.controls.activityRoomSelection.setValue("");
        this._sbs.SelectedRoomNo = "";
      }
    }
  }

  OnRefundToOriginalToggle(event) {
    console.log(event);
    if (this.returnTransitionDetails.controls.refundToOriginal.value) {
      if (!this.ValidateOfferAndCompSettlementForReversal()) { if (event?.target) event.preventDefault(); return; }
      if (!this.ValidatePartialRefundConfig()) { if (event?.target) event.preventDefault(); return; }
      this.OriginalSettlementToggleEnabled = true;
      if (this.BlockPartialRefund) {
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.PartialRefundWithMultiplePaymentError);
        this.returnTransitionDetails.controls.refundToOriginal.setValue(false);
        this._sbs.returnOriginalPaymentOnly = false;
      }
    }
    else {
      this._sbs.returnOriginalPaymentOnly = false;
      this.OriginalSettlementToggleEnabled = false;
    }
    this._sbs.ResetSelectedPaymentMethod();
  }

  ValidatePartialRefundConfig() {
    let allowedToProceed = true;
    if (this.IsPartialCreditCardRefund && this._sbs.GatewayConfiguration?.restrictPartialRefund) {
      allowedToProceed = false;
      setTimeout(() => {
        this.returnTransitionDetails.controls.refundToOriginal.setValue(false);  
      }, 500);      
      this._sbs.returnOriginalPaymentOnly = false;
      this.utils.ShowErrorMessage(this.localization.captions.common.Warning,
        this.localization.captions.shop?.PartialRefundIsNotAllowed, myGlobals.ButtonType.Ok)
    }
    return allowedToProceed;
  }

  ValidateOfferAndCompSettlementForReversal() {
    const transPayments = this._sbs.TransactionPaymentsAggregate;
    let allowedToProceed = true;
    if (transPayments && transPayments.length > 0) {
      const compExistsAndNotSupported = (transPayments.some(t => Number(t.transactionPayment.paymentMethod) == PaymentMethods.CompRedemption) && !this._retailFeatureFlagInfo.AllowCompRedemptionVoid);
      const offerExistsAndNotSupported = (transPayments.some(t => Number(t.transactionPayment.paymentMethod) == PaymentMethods.OfferRedemption && !this._retailFeatureFlagInfo.ValidateOfferRedemptionVoid(t.additionalInformation.VendorType)));
      if (offerExistsAndNotSupported || compExistsAndNotSupported) {
        allowedToProceed = false;
        this.returnTransitionDetails.controls.refundToOriginal.setValue(false);
        this.returnTransitionDetails.controls.refundToOriginal.markAsTouched();
        const errorMsg = offerExistsAndNotSupported ? this.localization.captions.shop.CMS.OfferRefundNotAllowed :
          this.localization.captions.shop.CMS.CompRefundNotAllowed
        this.utils.ShowErrorMessage(this.localization.captions.common.Warning, errorMsg, myGlobals.ButtonType.Ok)
      }
    }
    return allowedToProceed;
  }

  async ShowReturnSettlementInfoDialog() {
    if (!this.CheckReturnReason()) return;
    if (this.prevSettlementInfo && this.prevSettlementInfo.length == 0) {
      await this.GetOriginalSettlementInfo();
    }
    const dialogRef = this.dialog.open(ShopDialogPopUp, {
      width: '500px',
      height: 'auto',
      maxHeight: '700px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        isEdit: false,
        headername: this.localization.captions.shop.ReturnToOriginalSettlementInfoDialog.Header,
        closebool: true,
        templatename: 'RTOS',
        data: this.prevSettlementInfo
      },
      panelClass: 'shop-payment'
    });

    dialogRef.afterClosed().subscribe(info => {
      if (info == 'ok') {
        this.ProceedTransaction();
      }
    });
  }

  private async GetOriginalSettlementInfo() {
    if (!this._sbs.TransactionPayments || this._sbs.TransactionPayments.length == 0) { return; }
    this.prevSettlementInfo = [];
    const originalSettlementIds = this._sbs.TransactionPayments.map(x => x.paymentReferenceId);
    const paymentTransactionDetails: PaymentDetails[] = await this._sbs.GetPaymentTransactionDetails(originalSettlementIds);
    console.log(paymentTransactionDetails);
    if (paymentTransactionDetails && paymentTransactionDetails.length > 0 && this._sbs.paymentHistoryDetailsForTransaction.length > 0) {
      paymentTransactionDetails.forEach(paymentTrans => {
        let settlement: PaymentHistory = {
          amount: this.IsPartialRefundOriginalSettlement ? Math.abs(this._shopservice.finalAmount) : paymentTrans.amount,
          paymentMethod: this._sbs.FormSettlementHistoryLabel(paymentTrans, this._sbs.paymentMethods),
          parentTenderId: paymentTrans?.parentTenderId,
          paymentReferenceComment: paymentTrans.referenceComment,
          paymentMethodId: paymentTrans.paymentMethodId,
          cardNumber: paymentTrans.cardNumber,
          entryMode: paymentTrans.entryMode,
          issuerType: paymentTrans.issuerType,
          isPrefilled: true,
          paymentMethodInfo: this._sbs.selectedpayment,
          paymentMethodMasterId: paymentTrans.paymentMethodMasterId,
          
        }
        this.prevSettlementInfo.push(settlement);
      });
      console.log(this.prevSettlementInfo);
    }
  }

  ngOnDestroy() {
    this.commonUtils.resetQuickIdDetails();
    this._retailService.manualEmailId = [];
    this.$destroyed.next(true);
		this.$destroyed.complete();
  }

  async changeTerminal(terminal) {
    console.log(terminal);
    let CEDS_Response: any = await this.http.CallApiAsync<any>({
      host: myGlobals.Host.payment,
      callDesc: RetailRoutes.GetCEDSByTerminalId,
      method: HttpMethod.Get,
      uriParams: { terminalId: terminal.terminalId }
    });
    this._sbs.CEDS_ByTerminal = CEDS_Response.result;
    if (this._sbs.IsFolioPaymentMethod)
      this._sbs.GetHandles(this.returnTransitionDetails, this);
  }

  proceedForAdyenGatewayPayment(promptForPreferenceOverride) {
    this._sbs.proceedForAdyenGatewayPayment(this, promptForPreferenceOverride)
  }

  checkCurrentPaymentSelection(paymentMethod: PaymentMethod) {
    return (paymentMethod.id == this._sbs.selectedpayment?.id
      && (paymentMethod.paymentTypeId == this._sbs.selectedpayment?.paymentTypeId || paymentMethod.paymentTypeId == this._sbs.selectedpayment?.paymentTypeEqualant))
  }
  focusCMSSearch() {
    if (this.cmsSearchField?.nativeElement) {
      this.cmsSearchField?.nativeElement.focus();
    }
  }

  focusWalletInput() {
		if (this.walletInputField?.nativeElement) {
			this.walletInputField?.nativeElement.focus();
		}
	}

  resetPlayerPin() {
    if (this.IsCMSRequirePin) {
      this.CMSForm?.controls?.cmsPin?.setValue('');
    }
  }
  prefillReturnReasonIfRequired() {
    if (this._shopservice.settleRWTTransaction && this._shopservice.returnReason) {
      this.returnTransitionDetails.controls.returnReason.setValue(this._shopservice.returnReason);
    }
  }
  showSurchargePopover(e){
		let popOverTargetPosition = this.SCpopOverTarget.nativeElement.getBoundingClientRect();
		let windowWidth = window.innerWidth;
		let popOverWidth = this.surchargePopover.element.nativeElement.childNodes[0]?.offsetWidth ?
			this.surchargePopover.element.nativeElement.childNodes[0]?.offsetWidth
			: this.surchargePopover.element.nativeElement.childNodes[1]?.offsetWidth;
		let popOverHeight = this.surchargePopover.element.nativeElement.childNodes[0]?.offsetHeight ?
			this.surchargePopover.element.nativeElement.childNodes[0]?.offsetHeight
			: this.surchargePopover.element.nativeElement.childNodes[1]?.offsetHeight;
		let offsetRight = 0;
		let targetLeft = popOverTargetPosition.left;
		offsetRight = windowWidth;
		let checkRightPosition = offsetRight - popOverWidth;

		if (targetLeft < popOverWidth / 2) {
			e.content.left = 0;
			let calcArrow = 20;
			this.checkArrowTimeout(calcArrow, 0);
		} else if (targetLeft > checkRightPosition) {
			e.content.left = checkRightPosition - 50;
			let calcArrow =
				(windowWidth - popOverTargetPosition.left) / popOverWidth -
				popOverTargetPosition.width / 2 / popOverWidth;
			if (calcArrow * 100 < 10) {
				this.checkArrowTimeout((1 - popOverTargetPosition.width / 2 / popOverWidth) * 100, 0);
			} else {
				this.checkArrowTimeout((1 - calcArrow) * 100, 0);
			}
		}

		let windowHeight = window.innerHeight;
		let targetTopPosition = document.getElementById('surcharge-info').getBoundingClientRect().top;
		if (windowHeight - targetTopPosition < popOverHeight || windowHeight - targetTopPosition < 40) {
			if (popOverHeight < 40) {
				popOverHeight = 40;
			}
			e.content.top = targetTopPosition - popOverHeight - 10; //10 -span height
			e.content.effectivePlacement = 'top in';
		} else {
			e.content.top = targetTopPosition + 10; //10 -span height
			e.content.effectivePlacement = 'bottom in';
		}
	}

  checkArrowTimeout(arrowPosition, arrIndex) {
		let checkArrow = setTimeout(() => {
			let className = 'order-summary-popover';
			let popOverArrow = document
				.getElementsByClassName(className)
			[arrIndex].querySelectorAll('.arrow')[0] as HTMLElement;
			if (popOverArrow) {
				popOverArrow.style.left = arrowPosition + '%';
				clearTimeout(checkArrow);
			}
		}, 0);
	}
  
  async GetSurchargeConfig() {
    try {
      const surchargeConfig = await this._surchargeConfigService.GetSurchargeConfigurationWithCreditCardIssuer(PaymentMethods.CreditCard);
				this.cardIssuerTypes = [];			
				surchargeConfig.map(issuerType => {
				if (issuerType?.isActive) {
					this.cardIssuerTypes.push
						({
							name: issuerType?.creditCardIssuerType?.displayName,
							type: issuerType?.creditCardIssuerType?.issuerType,
							isPercentage: issuerType.isPercentage,
							surchargeValue: issuerType.value,
							isActive: issuerType.isActive,
							checked: false
						})
				}
			});
    } catch (error) {
      console.log(error);
    }
  }

  selectcardIssuer(cardIssuerConf: CreditCardIssuerConfig) {
    this.cardIssuerTypes.filter(x => x.type != cardIssuerConf.type).map(x => x.checked = false);
    cardIssuerConf.checked = !cardIssuerConf.checked;
    this.selectedIssuerType = null;
    if (cardIssuerConf.checked && cardIssuerConf?.isActive) {
      this.issuerTypeChange = true;
      this.selectedIssuerType = cardIssuerConf;
      this.ApplySurcharge(this.AmtForSurchargeCalc);
    } else {
      this.ResetSurchargeFlags();
    }
  }

  ApplySurcharge(paymentAmount: number) {
    if (!paymentAmount || !this.SurchargeEnabled || this._sbs.IsRefundFlow || !this.selectedIssuerType) return;
    this._ams.loaderEnable.next(this._sbs.ShopCaptions.lbl_ApplyingSurchargeLoaderMsg);
    this.remainingAmountBeforeSurcharge = this._sbs.RoundOffTwo(this._sbs.remainingAmount - this.surchargeAmount);
    const surchargeAmt = !this.selectedIssuerType?.isPercentage ? this.selectedIssuerType.surchargeValue
      : this._sbs.RoundOffTwo(paymentAmount * (this.selectedIssuerType.surchargeValue / 100));
    setTimeout(() => {
      this.surchargeAmount = surchargeAmt;
      this._shopservice.finalAmount = this._sbs.RoundOffTwo(this._sbs.OriginalTotalAmount + this.PrevSurchargeTotal + surchargeAmt);
      this._sbs.remainingAmount = this._sbs.RoundOffTwo(this.remainingAmountBeforeSurcharge + surchargeAmt);
      this.returnTransitionDetails.controls.amounttendered.setValidators([
        Validators.max(this._sbs.remainingAmount),
        Validators.required
      ]);
      this.isSurchargeApplied = true;
      this.SurchargeUpdated.emit(this._sbs.RoundOffTwo(this.PrevSurchargeTotal + surchargeAmt));
      this.PatchRemainingAmountAndHighlight(this._sbs.RoundOffTwo(paymentAmount + surchargeAmt), true);
      this._ams.loaderEnable.next("");
    }, 1000);
  }

  RemoveSurcharge(surchargeAmt: number, isReversal = false) {
    if ((!this.isSurchargedCollected && this.isSurchargeApplied && this.surchargeAmount > 0) || isReversal) {
      this._ams.loaderEnable.next(this._sbs.ShopCaptions.lbl_RemovingSurchargeLoaderMsg);
      this._shopservice.finalAmount = this._sbs.RoundOffTwo(this._shopservice.finalAmount - surchargeAmt);
      this._sbs.remainingAmount = this._sbs.RoundOffTwo(this._sbs.remainingAmount - surchargeAmt);
      this.returnTransitionDetails.controls.amounttendered.setValidators([
        Validators.max(this._sbs.remainingAmount),
        Validators.required
      ]);
      this.isSurchargeApplied = false;
      this.SurchargeUpdated.emit(this._sbs.RoundOffTwo(this.PrevSurchargeTotal));
      this.PatchRemainingAmountAndHighlight(this._sbs.remainingAmount, true);
      setTimeout(() => {
        this._ams.loaderEnable.next("");
      }, 500);
    }
  }

  ResetSurchargeFlags() {
    this.RemoveSurcharge(this.surchargeAmount);
    this.selectedIssuerType = null;
    this.isSurchargeApplied = this.isSurchargedCollected = false;
    this.surchargeAmount = this.remainingAmountBeforeSurcharge = 0;
    this.cardIssuerTypes.map(c => c.checked = false);
    this.returnTransitionDetails.controls.creditcardType.setValue('');
  }
  private setTerminalDefault(): void {
    if (!this._sbs.terminalList || this._sbs.terminalList.length === 0) {
        return; // No terminals available, unable to set default
    }

    const activeTerminal = this._sbs.terminalList.find(terminal => terminal.isActive);

    if (activeTerminal && this._sbs.terminalList.length === 1) {
        const selectedTerminalId = activeTerminal.terminalId;        
        this.returnTransitionDetails.get('selectedTerminal').setValue(selectedTerminalId);
        this.changeTerminal({ terminalId: selectedTerminalId });
        return; // Default terminal set successfully
    }

    return; // Default terminal not set
  }

}