<div class="activity-booking">

  <div class="activity-booking__form-main">
    <ng-scrollbar>
      <div class="activity-booking__class-name">
        <label class="activity-booking__data-header seat-labels" for=""> {{'ClassName' | translate}}</label>
        <dynamic-form [config]="lessonConfig" #form="dynamicForm">
        </dynamic-form>
      </div>

      <div class="activity-booking__date">
        <label class="activity-booking__data-header seat-labels" for="">{{'fromDate' | translate}}</label>
        <dynamic-form [config]="bookingFromDateConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="activity-booking__date">
        <label class="activity-booking__data-header seat-labels" for="">{{'toDate' | translate}}</label>
        <dynamic-form [config]="bookingToDateConfig" #form="dynamicForm">
        </dynamic-form>
        <span class="custom-error fl-left" *ngIf="invalidDate">{{'InvalidDateRange' | translate}}</span>
      </div>
      <div class="activity-booking__location">
        <label class="activity-booking__data-header seat-labels" for=""> {{'location' | translate}}</label>
        <dynamic-form [config]="locationConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="activity-booking__location">
        <label class="activity-booking__data-header seat-labels" for=""> {{'instructor' | translate}}</label>
        <dynamic-form [config]="instructorConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="activity-booking__exclusive" [hidden]="!isSessionClass || !isLocationGroupMapped">
        <dynamic-form [config]="exclusiveBookingConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      
      <div>
        <size-mapping [coverTypes]="coverTypes" [bookingTypes]="bookingTypes" [minSize]="minSizeAllowed" [maxSize]="maxSizeAllowed" [(size)]="sizeMapping" (sizeChange)="onSizeChange()" [dataCovers]="data?.CoverTypeQuantities || []"  [dataSize]="data?.Size"></size-mapping>
      </div>
      <div class="activity-booking__sales-contact" [style.height]="!showSalesContact ? '0' : 'auto'"
        [hidden]="!showSalesContact">
        <label class="activity-booking__data-header seat-labels" for="">{{'salesContact' | translate}}</label>
        <dynamic-form [config]="salesContactConfig" #salesContactForm="dynamicForm">
        </dynamic-form>
      </div>

    </ng-scrollbar>
  </div>
  <div class="activity-booking__slot-container" *ngIf="displaySlots && SessionSlotsAvailableDates && SessionSlotsAvailableDates.length > 0">
    <div class="activity-booking__warnings" *ngIf="sessionsUnavailable && sessionsUnavailable.length">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" class="activity-booking__warnings-accordian">
          <mat-expansion-panel-header>
            <mat-panel-title class="activity-booking__warnings-title">
              {{'Warnings' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li class="activity-booking__warning-item" *ngFor="let session of sessionsUnavailable">
              {{session.session}} {{session.dates}}
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
      <span class="activity-booking__warning-close icon-Group-591" (click)="resetWarning()"></span>
    </div>

    <div class="legend">
      <ng-container class="states" *ngIf="!isSessionClass || (isSessionClass 
        && SessionSlotsAvailableDates && SessionSlotsAvailableDates.length 
        && SessionSlotsAvailableDates[selectedIndex].AvailableSessions 
        && SessionSlotsAvailableDates[selectedIndex].AvailableSessions.length)">
        <div class="states__name confirmedStandby" *ngIf="data && !data.isConfirmedReservation">{{'Confirmed' |
          translate}}
        </div>
        <div class="states__name soldout">{{'Soldout' | translate}}</div>
        <div class="states__name checkedin">{{'checkedin' | translate}}</div>    
        <div class="states__name checkedout">{{'checkedout'| translate}}</div>
        <div class="states__name completed">{{'expired' | translate}}</div>
        <div class="states__name cancelled">{{'Cancelled' | translate}}</div>
        <div class="states__name blocked">{{'partyStatusBlockedText' | translate}}</div>
        <div class="states__name soldoutStandBy" *ngIf="AllowStandBy">{{'StandBy' | translate}} / {{'Overbook' | translate}}</div>
        <div class="states__name AssertNotFound" *ngIf="isLocationGroupMapped">{{'AssetNotFound' | translate}}</div>
      </ng-container>
      <ng-container class="allocation-types" *ngFor="let allocType of availableAllocationCodes">
        <div class="allocation-type" [ngStyle]="{'border-color':allocType.color, 'color': allocType.color}">
          {{allocType.AllocationName}}</div>
      </ng-container>
    </div>
    <div class="activity-booking__slot-date" *ngIf="isSessionClass 
      && SessionSlotsAvailableDates && SessionSlotsAvailableDates.length 
      && SessionSlotsAvailableDates[selectedIndex].AvailableSessions 
      && SessionSlotsAvailableDates[selectedIndex].AvailableSessions.length">
      <span class="icon icon-Path-490" [class.disabled]="selectedIndex === 0" (click)="navClick(-1)"></span>
      {{SessionSlotsAvailableDates[selectedIndex].AvailableDate | localizedDate : 'DD MMMM, ddd'}}
      <span class="icon icon-left-arrow1" [class.disabled]="SessionSlotsAvailableDates && selectedIndex === (SessionSlotsAvailableDates.length - 1)"
        (click)="navClick(1)"></span>
      <div class="activity-booking__apply-to-others" *ngIf="SessionSlotsAvailableDates && SessionSlotsAvailableDates.length > 1 && !isMultiSlotSelection"(click)="applyToOtherDates(selectedIndex)">
        {{'Apply to all available dates' | translate}}
      </div>
      <div class="activity-booking__reset-button">
        <activities-app-button [buttontype]="resetButton" (valueChange)='resetAllSelected()'></activities-app-button>
      </div>
    </div>
    <div class="alert alert-primary mb-2" role="alert" *ngIf="exclusiveBookingInfoMsg">
      <span class="icon-info"><span class="path1"></span><span class="path2"></span></span>  {{exclusiveBookingInfoMsg}}
    </div>
    <div class="alert alert-primary mb-2" role="alert" *ngIf="nonExclusiveBookingInfoMsg">
      <span class="icon-info"><span class="path1"></span><span class="path2"></span></span>  {{nonExclusiveBookingInfoMsg}}
    </div>
    <div class="activity-booking__select-slot-label" *ngIf="!isSessionClass">
      {{'SelectSlots' | translate}}
    </div>
    <ng-container *ngIf="isSessionClass && SessionSlotsAvailableDates.length > 0">
        <activity-session-slots [SessionSlots]="test" [SessionSlotsForAllDates]="SessionSlotsAvailableDates"
        [isMultiSlotSelection]="isMultiSlotSelection" (slotClick)="slotChangeListners($event, index)"
        [MembershipIds]="partyService.selectedGuest?.MemberAllocationTypesIds" [selectedDate]="SessionSlotsAvailableDates[selectedIndex].AvailableDate"
        [MemberActive]="partyService.selectedGuest?.MemberActive" [AllocationType]="_settings.AllocationType"
        [settings]="_settings" [isEdit]="!!this.data" [bookPastReservations]="bookPastReservations" [AllowStandBy]="AllowStandBy" [AllowOverbook]="AllowOverbook">
      </activity-session-slots>
    </ng-container>
    <activity-session-slots *ngIf="!isSessionClass && classLessonList && classLessonList.length"
      [SessionSlots]="classLessonList" [isMultiSlotSelection]="isMultiSlotSelection"
      (slotClick)="slotChangeListners($event, index)" [MembershipIds]="partyService.selectedGuest?.MemberAllocationTypesIds"
      [MemberActive]="partyService.selectedGuest?.MemberActive" [AllocationType]="_settings.AllocationType"
      [settings]="_settings" [isEdit]="!!this.data" [AllowStandBy]="AllowStandBy" [AllowOverbook]="AllowOverbook">
    </activity-session-slots>
    <div class="selected-class-schedule"
      *ngIf="!isSessionClass && selectedClassSchedule && selectedClassSchedule.length">
      <ng-container *ngFor="let selectedDay of selectedClassSchedule; let index = index;">
        <div class="selected-class-schedule__row" *ngIf="selectedDay">
          <div class="selected-class-schedule__day">
            {{dayOfWeek[index] | translate}}
          </div>
          <ul class="selected-class-schedule__session-period-list">
            <li class="selected-class-schedule__session-period-item"
              *ngFor="let slot of selectedDay | sortByDatePipe:'Start';">
              <span>{{slot.Start | localizedDate:'LT'}} - {{slot.End | localizedDate:'LT'}}</span>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>


  <div class="activity-booking__slot-container" *ngIf="(displaySlots && !SessionSlotsAvailableDates) || (SessionSlotsAvailableDates && SessionSlotsAvailableDates.length == 0)">
    <div class="activity-booking__no-slots">{{"NoSlotsMessage" | translate}}</div>
  </div>

</div>