import { Component, OnInit, Input, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { SPAConfig } from '../../common/config/SPA-config';
import { EmptyValueValidator } from '../../shared/Validators/EmptyValueValidator';
import { popupConfig } from '../../shared/business/shared.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from "lodash";
import { HttpServiceCall } from '../../shared/service/http-call.service'; 
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { LinkedTax, TaxConfiguration } from '../../retail.modals'; 
import { RetailTaxesDataService } from '../retail-taxes/retail-taxes-data.service';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { DatePipe } from '@angular/common';
import { AlertType } from '../../shared/shared.modal';
import { ButtonType } from '../../shared/globalsContant';

@Component({
  selector: 'app-retail-create-new-tax',
  templateUrl: './retail-create-new-tax.component.html',
  styleUrls: ['./retail-create-new-tax.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailCreateNewTaxComponent implements OnInit {

  clickbutton: any;
  @Input() dataInput: any;
  @Input() popupConfigs: popupConfig;
  @Input() existingTaxConfigs: any;
  FormGrp: UntypedFormGroup;
  textCaptions: any;

  captions: any = this.spaConfig.captions;
  isViewOnly = true;
  isFromTaxPercentage: boolean = false
  isFromTaxOnTaxPercentage: boolean = false
  customValue: any = [];
  selectedValue: any = { id: 1, value: 'Precentage %' }
  selectedValueforTaxOnTax:number = 0;
  presetPercentage: any = [];
  percentValue: any = '0';
  percentValueForTaxOnTax: any = '0';
  customValueplaceholder: string;
  customValueplaceholderForLinkedTax: string;
  customFieldDesc: any = [];
  customFieldValues: any = [];
  customFieldError: any = {};
  filter_startDate: any;
  filter_endDate: any;
  filter_text: any;
  ispercentValueDisabled: boolean = true;
  ispercentValueDisabledForTaxOnTax: boolean = true;
  minFromDate: any;
  minToDate: any;
  taxMaxLength = 0;
  taxOnTaxMaxLength = 0;
  isTaxonTaxEnabled = false;
  editModeDirty: {
    commissionDirty: boolean,
    serviceChargeDirty: boolean,
    gratuityDirty: boolean
  } = {
      commissionDirty: false,
      gratuityDirty: false,
      serviceChargeDirty: false
    };
  isVATEnabled: boolean;
  decimalMaxLength: number = 2;
  taxOnTaxdecimalMaxLength: number = 2;
  propertyDecimalConfig: number = 2;
  placeholderFormat: string;
  captionsCommon: any;
  settingCaptions:any;
  floatLabel: string;
  propDigits: number = 2;
  hasTaxOnTax: boolean = false;
  isRevenueToPropertyAllowed: boolean = false;
  propertyConfig : any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RetailCreateNewTaxComponent>,
    private Form: UntypedFormBuilder,
    private spaConfig: SPAConfig,
    public localization: RetailLocalization
    , private http: HttpServiceCall, private utils: RetailUtilities
    , private retailsTaxesDataService: RetailTaxesDataService
    , public propertyInformation: RetailPropertyInformation,
    private datePipe:DatePipe) {
    this.isVATEnabled = propertyInformation.IsVATEnabled;
    this.captionsCommon = this.localization.captions.common;
    this.settingCaptions = this.localization.captions.setting;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {    
    this.propertyConfig = this.propertyInformation.GetPropertyConfiguration();
    if (this.propertyConfig != null && this.propertyConfig['AllowTaxAsRevenueToProperty'] != null) {
      this.isRevenueToPropertyAllowed = this.propertyConfig['AllowTaxAsRevenueToProperty'].toLowerCase() == "true" ? true : false;
    }
    this.textCaptions = this.localization.captions.retailsetup;
    this.placeholderFormat = this.localization.inputDateFormat;
    this.FormGrp = this.Form.group({
      outlet: ['', [Validators.required, EmptyValueValidator]],
      taxname: ['', [Validators.required, EmptyValueValidator, Validators.maxLength(25)]],
      taxAmount: ['0', [Validators.required, EmptyValueValidator]],
      startDate: ['', [Validators.required, EmptyValueValidator]],
      endDate: ['', [Validators.required, EmptyValueValidator]],
      allowTaxOnServiceCharge: false,
      allowTaxOnGratuity: false,
      isDefaultTax : false,
      isTaxOnTax: false,
      taxOnTaxName: '',
      taxOnTaxAmount: '0',
      isTaxRevenueToProperty: false,
      isTaxOnTaxRevenueToProperty: false,
      allowRemovalForTaxOnTax: false,
      allowRemoval: false
    }, { validator: this.checkDates });

    if(this.isVATEnabled){
      this.FormGrp.controls.outlet.validator = null;
    }

    this.customFieldError = {
      MissingOutlet: this.textCaptions.MissingOutlet,
      MissingTaxName: this.textCaptions.MissingTaxName,
      InvalidTaxName: this.textCaptions.InvalidTaxName,
      MissingValues: this.textCaptions.MissingValues,
      MissingStartDate: this.textCaptions.MissingStartDate,
      MissingEndDate: this.textCaptions.MissingEndDate,
      InvalidStartDate: this.textCaptions.InvalidStartDate,
      MissingTaxOnTaxName: this.textCaptions.MissingTaxOnTaxName,
      InvalidTaxOnTaxName: this.textCaptions.InvalidTaxOnTaxName
    };

    this.customFieldDesc = [
      this.textCaptions.Outlet,
      this.textCaptions.TaxName,
      this.textCaptions.taxIn,
      this.textCaptions.TaxOnTaxName
     ];    

    this.customFieldValues[0] = [];
    this.propDigits = parseInt(sessionStorage.getItem('noOfDecimalDigits'));
    let sessionValue = this.utils.GetPropertyInfo("MaxDecimalPlaces");
    if(Number(sessionValue) >= 0){
      this.propertyDecimalConfig = Number(sessionValue);
    }
    

    if (this.dataInput && this.dataInput.length > 0 && this.dataInput[0].isAmount) {
      this.selectedValue = { id: 2, value: this.localization.captions.shop.Amount + ' ' + this.localization.currencySymbol };
      this.customValueplaceholder = `${this.localization.captions.shop.Value} (${this.localization.currencySymbol})`;
      this.taxMaxLength = 12;
      this.decimalMaxLength = this.propDigits;
      this.isFromTaxPercentage = false
    }
    else {
      this.selectedValue = { id: 1, value: this.localization.captions.shop.Percentage };
      this.customValueplaceholder = `${this.localization.captions.shop.Value} (%)`;     
      this.taxMaxLength = 3;
      this.decimalMaxLength = this.propertyDecimalConfig;
      this.isFromTaxPercentage = true
    }

    if (this.dataInput && this.dataInput.length > 0 && this.dataInput[0].linkedTaxes.length > 0 && this.dataInput[0].linkedTaxes[0].isFlatAmount) {
      this.selectedValueforTaxOnTax = 1;
      this.customValueplaceholderForLinkedTax = `${this.localization.captions.shop.Value} (${this.localization.currencySymbol})`;
      this.taxMaxLength = 12;
      this.taxOnTaxdecimalMaxLength = this.propDigits;
      this.isFromTaxOnTaxPercentage = false
    }
    else
    {
      this.selectedValueforTaxOnTax = 0;
      this.customValueplaceholderForLinkedTax = `${this.localization.captions.shop.Value} (%)`;
      this.taxOnTaxMaxLength = 3;
      this.taxOnTaxdecimalMaxLength = this.propertyDecimalConfig;
      this.isFromTaxOnTaxPercentage = true;
    }
    if (this.dataInput && this.dataInput.length > 0 && this.dataInput[0].linkedTaxes.length > 0 )
    {
      this.FormGrp.controls['taxOnTaxName'].setValidators([Validators.required, EmptyValueValidator, Validators.maxLength(25)]);	
      this.FormGrp.controls['taxOnTaxAmount'].setValidators([Validators.required, EmptyValueValidator]);	
      this.hasTaxOnTax = true;
    }
    this.customValue = [
      { id: 1, value: this.localization.captions.shop.Percentage },
      { id: 2, value: this.localization.captions.shop.Amount + ' ' + this.localization.currencySymbol }];

    if (this.popupConfigs.operation == "create") {
      this.minFromDate = this.propertyInformation.CurrentDate;
      this.clickbutton = this.captions.setting.save;
    } else if (this.popupConfigs.operation == "edit") {
      this.clickbutton = this.captions.setting.update;
      this.isTaxonTaxEnabled = this.dataInput[0].hasTaxOnTax;
      this.FormGrp.setValue({
        outlet: this.dataInput[0].outletId,
        taxname: this.dataInput[0].taxName,
        taxAmount: this.dataInput[0].taxAmount,
        startDate: this.localization.delocalizeDisplayDate(this.dataInput[0].startDate),
        endDate: this.localization.delocalizeDisplayDate(this.dataInput[0].endDate),
        allowTaxOnServiceCharge: this.dataInput[0].allowTaxOnServiceCharge,
        allowTaxOnGratuity: this.dataInput[0].allowTaxOnGratuity,
        isDefaultTax: this.dataInput[0].isDefaultTax,
        isTaxOnTax: this.dataInput[0]?.hasTaxOnTax,
        taxOnTaxName: this.dataInput[0].hasTaxOnTax && this.dataInput[0].linkedTaxes[0] ? this.dataInput[0].linkedTaxes[0].linkedTaxName : "",
        taxOnTaxAmount:this.dataInput[0].hasTaxOnTax && this.dataInput[0].linkedTaxes[0] ? this.dataInput[0].linkedTaxes[0].linkedTaxValue : 0,
        isTaxRevenueToProperty: this.dataInput[0].isTaxRevenueToProperty ?? false,
        isTaxOnTaxRevenueToProperty: this.dataInput[0].hasTaxOnTax && this.dataInput[0].linkedTaxes[0] ? this.dataInput[0].linkedTaxes[0].isLinkedTaxRevenueToProperty : false,
        allowRemoval: this.dataInput[0].allowRemovalForPaymentMethod,
        allowRemovalForTaxOnTax: this.dataInput[0].hasTaxOnTax && this.dataInput[0].linkedTaxes[0] ? this.dataInput[0].linkedTaxes[0].allowRemovalForPaymentMethod : false
      });

      this.minFromDate = this.localization.delocalizeDisplayDate(this.dataInput[0].startDate) < this.propertyInformation.CurrentDate ? this.localization.delocalizeDisplayDate(this.dataInput[0].startDate) : this.propertyInformation.CurrentDate;
      this.minToDate = this.FormGrp.get("startDate").value;
    }
    this.FormGrp.get("startDate").valueChanges.subscribe(val => {
      this.minToDate = val;
    });
    if (!this.isVATEnabled) {
      this.getOutlets();
    }
  }

  onStartDateChange($event) {
    let todate: Date;
    todate = this.FormGrp.controls["endDate"].value;
    if (todate < $event.value) {
      this.FormGrp.controls["endDate"].setValue($event.value);
    }
  }

  checkDates(group: UntypedFormGroup) {
    if (group.controls.endDate.value < group.controls.startDate.value) {
      return { notValid: true }
    }
    return null;
  }

  checkTaxValue(control: AbstractControl) {
    if (control.value || control.value == '0') {
      return { invalid: true };
    }
    return null;
  }

  public valueSymbol(isPercentage: boolean) {
    return isPercentage ? '%' : this.localization.currencySymbol;
  }

  validateTaxOnTaxSetting(controlName: string, event) {
    if (this.FormGrp.get("isTaxOnTax").value && event[0]) {
      const toggleLabel = controlName == "allowTaxOnServiceCharge" ? this.settingCaptions.AllowTaxOnServiceCharge
        : this.settingCaptions.AllowTaxOnGratuity;
      const Msg = this.localization.replacePlaceholders(this.settingCaptions.SC_GT_ValidationMsg
        , ['toggleLabel']
        , [toggleLabel]);
      setTimeout(() => { this.FormGrp.controls[controlName].setValue(false); }, 500);
      this.utils.showAlert(Msg, AlertType.Info, ButtonType.Ok);
      return;
    }

    if (this.FormGrp.get("isTaxRevenueToProperty").value && event[0]) {
      const toggleLabel = controlName == "allowTaxOnServiceCharge" ? this.settingCaptions.AllowTaxOnServiceCharge
        : this.settingCaptions.AllowTaxOnGratuity;
      const Msg = this.localization.replacePlaceholders(this.settingCaptions.SC_GT_TaxRevenueToProperty_ValidationMsg
        , ['toggleLabel']
        , [toggleLabel]);
      setTimeout(() => { this.FormGrp.controls[controlName].setValue(false); }, 500);
      this.utils.showAlert(Msg, AlertType.Info, ButtonType.Ok);
      return;
    }
  }

  EnableTaxRevenueToProperty(e:any)
  {
    if(e[0])
    {
      const allowTaxOnServiceCharge = this.FormGrp.get("allowTaxOnServiceCharge").value;
      const allowTaxOnGratuity = this.FormGrp.get("allowTaxOnGratuity").value;
      if (allowTaxOnServiceCharge || allowTaxOnGratuity) {
        const toggleLabel = allowTaxOnServiceCharge && allowTaxOnGratuity ? this.settingCaptions.AllowTaxOnGratuityServiceCharge
          : allowTaxOnServiceCharge ? this.settingCaptions.AllowTaxOnServiceCharge : this.settingCaptions.AllowTaxOnGratuity;
        const Msg = this.localization.replacePlaceholders(this.settingCaptions.TaxRevenueToPropertyValidationMsg
          , ['toggleLabel']
          , [toggleLabel]);
        this.utils.showAlert(Msg, AlertType.Info, ButtonType.Ok);        
        setTimeout(() => { 
          this.FormGrp.controls.isTaxRevenueToProperty.setValue(false);
        }, 500);
        return;
      }
    }
  }

  chargeTaxonTax(e:any)
  {
    this.isTaxonTaxEnabled = e[0];
    if(e[0])
    {
      const allowTaxOnServiceCharge = this.FormGrp.get("allowTaxOnServiceCharge").value;
      const allowTaxOnGratuity = this.FormGrp.get("allowTaxOnGratuity").value;
      if (allowTaxOnServiceCharge || allowTaxOnGratuity) {
        const toggleLabel = allowTaxOnServiceCharge && allowTaxOnGratuity ? this.settingCaptions.AllowTaxOnGratuityServiceCharge
          : allowTaxOnServiceCharge ? this.settingCaptions.AllowTaxOnServiceCharge : this.settingCaptions.AllowTaxOnGratuity;
        const Msg = this.localization.replacePlaceholders(this.settingCaptions.TaxOnTaxValidationMsg
          , ['toggleLabel']
          , [toggleLabel]);
        this.utils.showAlert(Msg, AlertType.Info, ButtonType.Ok);        
        setTimeout(() => { 
          this.FormGrp.controls.isTaxOnTax.setValue(false);
          this.isTaxonTaxEnabled = false;
          this.removeValidator('taxOnTaxName');
          this.removeValidator('taxOnTaxAmount');
        }, 500);
        return;
      }
      this.FormGrp.controls['taxOnTaxName'].setValidators([Validators.required, EmptyValueValidator, Validators.maxLength(25)]);	
      this.FormGrp.controls['taxOnTaxAmount'].setValidators([Validators.required, EmptyValueValidator]);	
    }
    else
    {    
      this.removeValidator('taxOnTaxName');
      this.removeValidator('taxOnTaxAmount');
    }
  }

  removeValidator(controlName) {
    this.FormGrp.controls[controlName].clearValidators();
    this.FormGrp.controls[controlName].updateValueAndValidity();
    this.FormGrp.controls[controlName].markAsDirty();
  }

  getErrorMessage(controlName: string) {
    switch (controlName) {
      case 'outlet':
        return this.FormGrp.controls['outlet'].hasError('required') ? this.customFieldError.MissingOutlet : '';

      case 'taxname':
        const tempTaxName = (this.FormGrp.controls['taxname'].hasError('maxlength') ? this.customFieldError.InvalidTaxName : '');
        return this.FormGrp.controls['taxname'].hasError('required') ? this.customFieldError.MissingTaxName : tempTaxName;

      case 'taxAmount':
        return this.FormGrp.controls['taxAmount'].hasError('required') ? this.customFieldError.MissingValues : '';

      case 'startDate':
        return this.FormGrp.controls['startDate'].hasError('required') ? this.customFieldError.MissingStartDate : '';

      case 'endDate':
        return this.FormGrp.controls['endDate'].hasError('required') ? this.customFieldError.MissingEndDate : '';

        case 'taxOnTaxName':
        const temptaxOnTaxName = (this.FormGrp.controls['taxOnTaxName'].hasError('maxlength') ? this.customFieldError.InvalidTaxOnTaxName : '');
        return this.FormGrp.controls['taxOnTaxName'].hasError('required') ? this.customFieldError.MissingTaxOnTaxName : temptaxOnTaxName;

      case 'taxOnTaxAmount':
        return this.FormGrp.controls['taxOnTaxAmount'].hasError('required') ? this.customFieldError.MissingValues : '';

      default:
        return '';

    }
  }

  async getOutlets() {
    this.customFieldValues[0] = await this.retailsTaxesDataService.getOutlets(Number(this.utils.GetPropertyInfo('PropertyId'))) || [];
    let outletId = this.FormGrp.get("outlet").value;
    if (this.customFieldValues[0].filter(r => r.id == outletId).length == 0) this.FormGrp.get("outlet").setValue('');
  }

  buildLinkedTax(e:any, isActive:boolean = true):LinkedTax[]
  {
    let linkedTaxes: LinkedTax[] = [];
    linkedTaxes.push({
      id: this.dataInput[0]?.linkedTaxes && this.dataInput[0]?.linkedTaxes.length > 0 && this.dataInput[0].linkedTaxes[0].id > 0 ? this.dataInput[0].linkedTaxes[0].id : 0,
      linkedTaxName: e.taxOnTaxName,
      linkedTaxValue:isNaN(e.taxOnTaxAmount) ? this.localization.currencyToSQLFormat(e.taxOnTaxAmount) : e.taxOnTaxAmount,
      isFlatAmount:this.selectedValueforTaxOnTax == 1,
      isActive: isActive,
      isLinkedTaxRevenueToProperty: e.isTaxOnTaxRevenueToProperty,
      allowRemovalForPaymentMethod: e.allowRemovalForTaxOnTax,
      linkedTaxUniqueId: this.dataInput[0]?.linkedTaxes && this.dataInput[0]?.linkedTaxes.length > 0 && this.dataInput[0].linkedTaxes[0].id > 0 && this.dataInput[0].linkedTaxes[0].linkedTaxUniqueId > 0 ? this.dataInput[0].linkedTaxes[0].linkedTaxUniqueId : 0
    })
    return linkedTaxes;
  }

  async saveTax(e) {
    const selectedStartDate = this.localization.delocalizeDisplayDate(e.startDate);
    const selectedEndDate = this.localization.delocalizeDisplayDate(e.endDate);
    const currentTaxId = this.popupConfigs.operation == "create"? 0 : this.dataInput[0].id;
    let existingDefaultTax = false;
    let existingDefaultTaxName = "";
    this.existingTaxConfigs.forEach(f => {
      const gridRowTaxStartDate = this.localization.delocalizeDisplayDate(f.startDate);
      const gridRowTaxEndDate = this.localization.delocalizeDisplayDate(f.endDate);
      if(f.isDefaultTax && (gridRowTaxStartDate  <= selectedEndDate) && (selectedStartDate <= gridRowTaxEndDate) && f.id != currentTaxId)
      {
        existingDefaultTax = true;
        existingDefaultTaxName = f.taxName.trim();
      }
    });
    
    if(!(existingDefaultTax && e.isDefaultTax && this.isVATEnabled)){
        if (this.popupConfigs.operation == "create") {
          let taxAmoutValue = e.taxAmount;    
          
          let taxConfig: TaxConfiguration = {
            id: 0,
            outletId: e.outlet ? e.outlet : 0,
            taxName: e.taxname.trim(),
            isAmount: this.selectedValue.id == 2,
            taxAmount: isNaN(taxAmoutValue) ? this.localization.currencyToSQLFormat(taxAmoutValue) : taxAmoutValue,
            startDate: this.localization.convertDateObjToAPIdate(e.startDate),
            endDate: this.localization.convertDateObjToAPIdate(e.endDate),
            allowTaxOnGratuity: e.allowTaxOnGratuity,
            allowTaxOnServiceCharge:e.allowTaxOnServiceCharge,
            isDefaultTax:e.isDefaultTax,
            hasTaxOnTax: e.isTaxOnTax,
            linkedTaxes:e.isTaxOnTax ? this.buildLinkedTax(e) : [],
            isTaxRevenueToProperty: e.isTaxRevenueToProperty,
            taxUniqueId: 0,
            allowRemovalForPaymentMethod: e.allowRemoval          
          };
    
          if (await this.retailsTaxesDataService.createTaxConfiguration(taxConfig))
            this.dialogRef.close(true);
    
        } else if (this.popupConfigs.operation == "edit") {
    
          let taxAmoutValue = e.taxAmount;
          let linkedTax = [];        
          let taxConfig: TaxConfiguration = {
            id: this.dataInput[0].id,
            outletId: e.outlet ? e.outlet : 0,
            taxName: e.taxname.trim(),
            isAmount: this.selectedValue.id == 2,
            taxAmount: isNaN(taxAmoutValue) ? this.localization.currencyToSQLFormat(taxAmoutValue) : taxAmoutValue,
            startDate: this.localization.convertDateObjToAPIdate(e.startDate),
            endDate: this.localization.convertDateObjToAPIdate(e.endDate),
            allowTaxOnGratuity: e.allowTaxOnGratuity,
            allowTaxOnServiceCharge:e.allowTaxOnServiceCharge,
            isDefaultTax:e.isDefaultTax,
            hasTaxOnTax: e.isTaxOnTax,
            linkedTaxes:e.isTaxOnTax ? this.buildLinkedTax(e) : this.hasTaxOnTax ? this.buildLinkedTax(e,false) : [],
            isTaxRevenueToProperty: e.isTaxRevenueToProperty,
            taxUniqueId: this.dataInput[0].taxUniqueId ?? 0,
            allowRemovalForPaymentMethod: e.allowRemoval              
          };
    
          if (await this.retailsTaxesDataService.updateTaxConfiguration(taxConfig))
            this.dialogRef.close(true);
        }
    }   
    else{
       this.utils.ShowError(this.settingCaptions.Error,this.localization.replacePlaceholders(this.settingCaptions.DefaultTaxAlreadyExistForVatProperty, ["TaxName"],[existingDefaultTaxName]));
    }    
  }

  onNoClick() {
    this.dialogRef.close();
  }

  linkedTaxValueType=(event) => {
    console.log(event)
    this.selectedValueforTaxOnTax = event.value;
    if (event.value == 0) {
      this.customValueplaceholderForLinkedTax = `${this.localization.captions.shop.Value} (%)`;
      this.taxOnTaxMaxLength = 3;
      this.taxOnTaxdecimalMaxLength = this.propertyDecimalConfig;
      this.isFromTaxPercentage = true
    }
    else if (event.value == 1) {
      this.customValueplaceholderForLinkedTax = `${this.localization.captions.shop.Value} (${this.localization.currencySymbol})`;
      this.taxOnTaxMaxLength = 12;
      this.taxOnTaxdecimalMaxLength = this.propDigits;      
      this.isFromTaxOnTaxPercentage = false
    }
    this.FormGrp.controls.taxOnTaxAmount.setValue('0');
    this.percentValue = '0';
    this.ispercentValueDisabled = false;
    this.FormGrp.markAsDirty();
  }
  

  chooseValue = (event, value) => {
    this.selectedValue = _.cloneDeep(value);
    if (value.value == this.localization.captions.shop.Percentage) {
      this.customValueplaceholder = `${this.localization.captions.shop.Value} (%)`;
      this.taxMaxLength = 3;
      this.decimalMaxLength = this.propertyDecimalConfig;
      this.isFromTaxPercentage = true
    }
    else if (value.value.split(' ')[0] == this.localization.captions.shop.Amount) {
      this.customValueplaceholder = `${this.localization.captions.shop.Value} (${this.localization.currencySymbol})`;
      this.taxMaxLength = 12;
      this.decimalMaxLength = this.propDigits;      
      this.isFromTaxPercentage = false
    }
    this.FormGrp.controls.taxAmount.setValue('0');
    this.percentValue = '0';
    this.ispercentValueDisabled = false;
    this.FormGrp.markAsDirty();
  }
 

  convertFromLocalizedCurrency(value: any) {
    if (!value || value == "")
      return;
    value = this.localization.currencyToSQLFormat(value);
    value = this.localization.localizeCurrency(value, false);
    return value;
  }

}

