import { AppService } from '@app/app.service';
import { ChangeAction } from '@constants/commonenums';
import { ObjectChange, PropertyChange } from '@models/ChangeTrackingOperationResultDTO';
import { ManualSlot } from '@models/ManualSlotDTO';
import { ManualSlotMapper } from '@models/mappers/ManualSlotDTOMapper';
import { PartyService } from '@services/party.service';
import _ from 'lodash';
import { Processor } from './processor';

export class SlotChangeProcessor implements Processor {
  private static instance: SlotChangeProcessor;
  private constructor(public partyService: PartyService, public as: AppService) {
  }

  public static Instance(ps: PartyService, _as: AppService): SlotChangeProcessor {
    if (!SlotChangeProcessor.instance) {
      SlotChangeProcessor.instance = new SlotChangeProcessor(ps, _as);
    }

    return SlotChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: any, propertyId: number): void {
    let slotList = this.partyService.slots_holder;
    let defaultSlotToRemove = null;
    if (!slotList) {
      return;
    }
    if (objectChange) {
      if (objectChange.Action === ChangeAction.Created) {
        const slot = new ManualSlot();
        objectChange.PropertyChanges.forEach(property => {
          const isObject = _.isObject(slot[property.PropertyName]);
          if (Object.getOwnPropertyNames(slot).includes(property.PropertyName)) {
            slot[property.PropertyName] = isObject ? JSON.parse(property.Value) : property.Value;
          }
          if (property.PropertyName == 'DefaultSlotId')
          {
            defaultSlotToRemove = property.Value;
          }
        });
        this.partyService.slots_holder = this.partyService.slots_holder.filter(item => item.Id != defaultSlotToRemove);
        let slotDto = ManualSlotMapper.map(slot);
        this.partyService.slots_holder.push({ ...slotDto });
        if (this.partyService.reservationFormGroup.value.selectedTime.Id === defaultSlotToRemove) {
          this.partyService.reservationFormGroup.controls.selectedTime.setValue(slotDto);
        }
        if (this.partyService.previousSelectedSlotId === defaultSlotToRemove) {
          this.partyService.previousSelectedSlotId = slotDto.Id;
        }
        this.partyService.partySlots$.next(true);
      }
      if (objectChange.Action === ChangeAction.Updated) {
        if (objectChange && slotList && slotList.length > 0) {
          const slotDetail = slotList.find((slot) => slot.Id === objectChange.ObjectId);
          if (slotDetail) {
            objectChange.PropertyChanges.forEach(property => {
              const isObject = _.isObject(slotDetail[property.PropertyName.replace('Internal', '')]);
              this.mapMissedProperties(property, slotDetail);
              if (Object.getOwnPropertyNames(slotDetail).includes(property.PropertyName.replace('Internal', ''))) {
                slotDetail[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
              }
            });
            if (slotDetail.IsLocked && slotDetail.LockExpiresAt == null && slotDetail.LockedAt == null) {
              slotDetail.IsLocked = false;
            }
            this.partyService.partySlots$.next(true);
          }
          let partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId');
          if (objectChange && partyId && partyId.Value) {
            let party = this.partyService.Parties$.value.find((party) => party.Id === partyId.Value);
            party.SlotId = objectChange.ObjectId;
          }
        }
      }
      if (objectChange.Action === ChangeAction.Removed) {
        slotList = slotList.filter(slot => slot.Id !== objectChange.ObjectId);
        this.partyService.partySlots$.next(true);
      }
    }
  }

  mapMissedProperties(property: PropertyChange, slotDetail: any) {
    if (property.PropertyName.includes('Internal')) {
      property.PropertyName = property.PropertyName.replace('Internal', '');
    }
    if (property.PropertyName === "MaxPartySize") {
      slotDetail.OriginalMaxPartySize = JSON.parse(property.Value);
      slotDetail.LimitedMaxPartySize = JSON.parse(property.Value);
    }
    if (property.PropertyName === "MinPartySize") {
      slotDetail.OriginalMinPartySize = JSON.parse(property.Value);
      slotDetail.LimitedMinPartySize = JSON.parse(property.Value);
    }
    if (property.PropertyName === "IsDeleted") {
      slotDetail.IsDisabled = JSON.parse(property.Value);
    }
    if (property.PropertyName === "Type") {
      slotDetail.ManualSlotType = JSON.parse(property.Value);
    }
  }
}
