import { Component, Input, OnInit } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { SettingType, SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-based-guest-data',
  templateUrl: './search-based-guest-data.component.html',
  styleUrls: ['./search-based-guest-data.component.scss']
})
export class SearchBasedGuestDataComponent implements OnInit {
  _settings:SettingsDTO;
  _merchantSetting: any;
  subscription: Subscription = new Subscription();
  settingType = SettingType;
  @Input() guestData: any;

  constructor(public cs:CacheService) {
    this.subscription.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this._merchantSetting = this._settings.MerchantSettings;
      
    }));
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
