 
export namespace UI {

    export interface TaxGrid {
        id: number;
        taxType: string;
        postTypeNumber: string;
        listOrder: number;
        isActive: {
            value: boolean;
            disabled?: boolean;
        };
    }

    export interface TaxType {
        id: number;
        taxType: string;
        postTypeNumber: number;
        listOrder: number;
        isActive: {
            value: boolean;
            disabled?: boolean;
        };
        postTypeId: number;
        exemptDays: number;
        effectiveDate: Date | string;
        isTaxable: boolean;
        isPostWithRoom: boolean;
        isSpecializedUse: boolean;
        isShowOnSplits: boolean;
        isGSTTax: boolean;
        isOccupancyTax: boolean;
        isOCCTaxNoOfRooms: boolean;
        isPostTaxToFirstFolio: boolean;
        pSTCodeId: any;
        taxExemptCategory: number;
        extractCode: string;
        taxMaintenances: TaxMaintenance[];
        excludeFlatTaxOnZeroCharge: boolean;
    }

    export interface TaxMaintenance {
        id: number;
        taxId: number;
        fromDate: Date | string;
        toDate: Date | string;
        localizedFromDate?: string;
        localizedToDate?: string;
        taxBasedOn: number;
        taxPercent: any;
        taxFlatAmount: any;
        newTaxBasedOn: number;
        newTaxPercent: any;
        newTaxFlatAmount: any;
        newPostTypeNumber: any;
        newPostTypeId: number;
        taxReference: string;
        taxDept: string;
        taxPType: string;
        taxRound: number;
        gaffile: number;
        isDisable: boolean;        
    }
}

export namespace API {
    export interface Tax {
        id: number;
        taxType: string;
        postTypeId: number;
        exemptDays: number;
        effectiveDate: Date | string;
        isTaxable: boolean;
        isPostWithRoom: boolean;
        isSpecializedUse: boolean;
        isShowOnSplits: boolean;
        isGSTTax: boolean;
        isOccupancyTax: boolean;
        isOCCTaxNoOfRooms: boolean;
        isPostTaxToFirstFolio: boolean;
        pstCodeId: number;
        taxExemptCategoryId: number;
        extractCode: string;
        listOrder: number;
        isActive: boolean;
        outletId: number;
        taxMaintenances: TaxMaintenance[];
        excludeFlatTaxOnZeroCharge: boolean;
    }
    export interface GridTax extends Tax {
        postTypeNumber: number;
    }
}
export interface TaxMaintenance {
    id: number;
    taxId: number;
    fromDate: Date | string;
    toDate: Date | string;
    isTaxAmount: boolean;
    taxAmount: number;
    isNewTaxAmount: boolean;
    newTaxAmount: number;
    newPostTypeId: number;
    taxReference: string;
    taxDept: string;
    taxPType: string;
    taxRound: number;
    gaffile: number;
}

export enum TaxTypeOptions {
    Taxable,
    OccTax,
    PostWithRoom,
    OccTaxNoOfRooms,
    SpecializedUse,
    PostFirstFolioInRooms,
    GSTTax,
    ShowOnSplits,
    ExcludeFlatTaxOnZeroCharge
}

export enum TaxBased {
    TaxPercent = 1,
    TaxAmount
}

export interface PackageComponentTaxInfo{
    postComponentsCharges: PostComponentCharges[]
    grandTotalAmount : number;
    grandTotaltax : number;
}

export interface PostComponentCharges {
     postTypeId : number;
     componentId : number;
     dayId : number;
     postAmount : number;
     postTax : number;
     total : number;
     isRoomPostType ? : boolean;
}