import { Pipe, PipeTransform } from '@angular/core';
import { CacheService } from '@core/services/cache.service';

@Pipe({
  name: 'PartySourceIconPipe'
 })
 export class PartySourceIconPipe implements PipeTransform{ 
   constructor(public cs: CacheService) { }
   transform(party){
      party.SourceIcon = this.cs.settings?.value?.PartySources?.find(x => x.Id == party?.PartySourceId)
     if(party.SourceIcon?.ExternalId && party.SourceIcon?.Id) {
      return {
        Name: party.SourceIcon.Name,
        Id: party.SourceIcon.Id,
        iconclass:`icon-reserve_${party.SourceIcon.ExternalId}`
      };
    } else
       return {
        Name: party.SourceIcon?.Name,
        Id: null,
        iconclass:"icon-reserve_host"
      };
    }   
   }
