<section class="quickSale newVersionWrapper" >
    <div class='pageHeader'>
      <div class="header-elements">
        <mat-form-field class="pt-4 spa-searchbox custSearchbox" [floatLabel]="floatLabelNever">
          <input [attr.automationId]="'Txt_retailQuickSale_search'" matInput autocomplete="off" class="searchbox-width" [(ngModel)]="searchText" type="text" placeholder="{{captions.ItemName}}"
            value=""  (keyup)="searchItems($event)">
          <i  aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" (click)="searchItems(searchText='');"></i>
        </mat-form-field>

        <mat-form-field *ngIf="this.functionalities['ShowOutletFieldInQuickSaleSetupScreen'] || false" class="pl-4" [floatLabel]="floatLabel">
          <mat-select [attr.automationId]="'Dd_retailQuickSale_subPropertyName'" placeholder="{{captions.outlets}}" (selectionChange)="outletChange($event)" [(value)]="selectedOutletId">
            <mat-option [value]="outOption.subPropertyID" *ngFor="let outOption of outlets">
              {{outOption.subPropertyName}}</mat-option>
          </mat-select>
      </mat-form-field>
      </div>

          <div class="btn-group float-right">
              <button [attr.automationId] ="'Btn_retailQuickSale_addQuickSaleItems'" mat-raised-button class="body-bgcolor whitecolor body-bordercolor p-2 search_button_width button-height mt-1" [ngClass]="{'button_invalid': IsViewOnly}" (click)="openDialog(captions.AddQuickSaleItems,'QS')"
                  >{{captions.AddQuickSaleItems}}</button>
          </div>
    </div>
    <div class='LW14 text-center p-2' *ngIf='quickSaleItems.length == 0'>{{captions.NoItemsAddedToQuickSale}}</div>
    <div class='items'>
        <div id="vertical_drag_wrapper" cdkScrollable cdkDropList [cdkDropListData]="category" class='mb-0 h-100 category-container' (cdkDropListDropped)="drop($event)">
            <div cdkDropListGroup cdkDrag *ngFor = "let item of category;" class="d-flex flex-wrap item-container" >
                <span class='LW14 text-capitalize'>
                    {{GetCategoryName(item)}}&nbsp;({{count[item]}})
                    <i  aria-hidden="true" class="icon-DragAndDrop icon-drag-drop movecursor draggable" cdkDragHandle matTooltip="{{allCaptions.common.Drag}}"></i>
                </span>
                <div class="ag_display--flex overflow-auto w-100" id={{item}} cdkDropList [cdkDropListData]="quickSaleItems" cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropInnerItems($event, item)" [cdkDropListConnectedTo]="getConnectedList()">
                    <ng-container *ngFor = "let retailItem of quickSaleItems; let i = index" >
                    <div cdkDrag class="quicksale-wrapper" *ngIf = 'item == retailItem.quickSaleCategoryId'>
                        <div class='quickSaleItem LW12' >
                            <span class='itemCode'>{{retailItem.number}}</span>
                            <span class='itemName' [matTooltip]='retailItem.name' matTooltipClass='custom-tooltip'>{{retailItem.name}}</span>
                            <i [attr.automationId] = "'Icn_retailQuickSale_DeleteRetailItem'" aria-hidden="true"  class="icon-Delete ag_cursor--pointer" [matTooltip]="allCaptions.tooltip_delete" matTooltipClass='custom-tooltip' (click)="onDelete(retailItem)"></i>
                        </div>
                    </div>
                </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>
