import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup  } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { Outlet } from '../../retail.modals';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { BaseResponse, popupConfig } from '../../shared/business/shared.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { AlertType } from '../../shared/shared.modal';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';

@Component({
  selector: 'app-retail-discount-reason',
  templateUrl: './retail-discount-reason.component.html',
  styleUrls: ['./retail-discount-reason.component.scss']
})
export class RetailDiscountReasonComponent implements OnInit {

  FormGrp: UntypedFormGroup;
  Outlets: Outlet[] = [];
  selectedPackageComponent: any[] = [];
  tableoptions: any[];
  captions: any;
  currIndex: any;
  deletedIndex: any;
  IsViewOnly: boolean;
  allDiscountReason: any;
  maxListOrder: number;
  @Input() popupConfigs: popupConfig;
  @Input() DragEvent;
  onEditDisableFunction:boolean = true;
  
  constructor(private Form: UntypedFormBuilder, private http: HttpServiceCall, private retailService: RetailSetupService,
     public localization: RetailLocalization, private utils: RetailUtilities) {
  }

  ngOnInit() {
    this.FormGrp = this.Form.group({
      TerminalID: []
    });
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.DiscountReason).view;
    this.captions = this.localization.captions.retailsetup;
    this.getallDiscountReason();
  }


 checkForMinValidation(discountReason): boolean {
    let valid: boolean = true;
    if ((discountReason.value.controls.setupCodeName.value.trim().length < 3)) {
        let errMsg:string = this.localization.getError(100713);
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
        valid = false;
    }
    return valid;
}

  dragDrop(event: any) {
    const dragIndex = event[0];
    const dropIndex = event[1];

    var currentIndex = this.tableoptions[0].TablebodyData[dragIndex - 1];
    var droppedIndex = this.tableoptions[0].TablebodyData[dropIndex - 1];

    const discountReasonObj = {
      Id : currentIndex.id,
      IsActive: currentIndex.isActive,
      Description: currentIndex.discountreason,
      ListOrder: droppedIndex.listOrder,
    };

    this.http.CallApiWithCallback({
      host: myGlobals.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      callDesc: "UpdateDiscountReason",
      method: HttpMethod.Put,
      body: discountReasonObj,
      showError: true,
      extraParams: []
    });

    this.getallDiscountReason();
  }

  async Done(event: any) {

    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == event.id);
    
    const discountReasonObj = {
      Id : this.tableoptions[0].TablebodyData[this.currIndex].id,
      IsActive: this.tableoptions[0].TablebodyData[this.currIndex].isActive,
      Description: this.tableoptions[0].TablebodyData[this.currIndex].discountreason,
      ListOrder: this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
    };
     this.http.CallApiWithCallback({
       host: myGlobals.Host.retailManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "UpdateDiscountReason",
        uriParams: {id : this.tableoptions[0].TablebodyData[this.currIndex].id},
        method: HttpMethod.Put,
        body: discountReasonObj,
        showError: true,
        extraParams: []
    });

    this.getallDiscountReason();
  }

  addDiscountReason(data: any) {

    let listOrderArray = this.tableoptions && this.tableoptions[0].TablebodyData && this.tableoptions[0].TablebodyData.map(e=> e.listOrder);
    let maxListOrder = listOrderArray && listOrderArray.length > 0 ? Math.max(...listOrderArray) : 0;

    if (data.type.toLowerCase() == this.localization.captions.setting.Add.toLowerCase() && this.checkForMinValidation(data)) {

      const discountReasonObj = {
        IsActive: true,
        Description: data.value.controls.setupCodeName.value.trim(),
        ListOrder: maxListOrder + 1,
      };

      this.http.CallApiWithCallback({
        host: myGlobals.Host.retailManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "CreateDiscountReason",
        method: HttpMethod.Post,
        body: discountReasonObj,
        showError: true,
        extraParams: []
      });
     
    } 
    
    else if (data.type.toLowerCase() == this.localization.captions.setting.update.toLowerCase() && this.checkForMinValidation(data)) {

      const discountReasonObj = {
        Id:this.tableoptions[0].TablebodyData[this.currIndex].id,
        IsActive: data.value.controls.activetoggle.value,
        Description: data.value.controls.setupCodeName.value.trim(),
        ListOrder: this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
      };

      this.http.CallApiWithCallback({
        host: myGlobals.Host.retailManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "UpdateDiscountReason",
        method: HttpMethod.Put,
        body: discountReasonObj,
        showError: true,
        extraParams: []
      });
      
    }
    this.getallDiscountReason();

    return true;
  }

  EditRecords(data?: any, type?: any) {
    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == data[0].id);
  }

  DeleteRecords(event: any) {
      let errorMsg = this.captions.DiscountReasonDeletionConfirmmsg;
      this.deletedIndex = event[0];
      errorMsg = this.localization.replacePlaceholders(errorMsg, ["Discount Reason"], [event[0].discountreason])
    this.utils.showAlert(errorMsg, AlertType.Warning, myGlobals.ButtonType.YesNo, this.PopupCallback.bind(this), event[0]);
    
    }
  
  async PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == "yes") {
      this.InvokeServiceCall('DeleteDiscountReason', myGlobals.Host.retailManagement, HttpMethod.Delete, { id: this.deletedIndex.id });
    }
  }

  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if ('GetDiscountReason' || 'DeleteDiscountReason') {
      let response = <any>result.result;
      this.allDiscountReason = [];
      response.forEach(element => {
        let discountReason: any = {
          id: element.id,
          discountreason: element.description,
          isActive: element.isActive,
          listOrder: element.listOrder
        }
        this.allDiscountReason.push(discountReason);
      });
      this.BindToGrid();
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
  console.error(error.result);
  }

  private getallDiscountReason(){
     this.http.CallApiWithCallback<any>({
       host: myGlobals.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetDiscountReason",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }


  private async BindToGrid() {

    this.tableoptions = [
      {
        TableHdrData: [{ 'title': this.captions.DiscountReason, 'jsonkey': 'discountreason', 'alignType': 'left', 'sortable': true },
        { 'title': this.captions.Active, 'jsonkey': 'isActive', 'alignType': 'left', 'type': 'toggle', 'sortable': true },
        { 'title': this.captions.ListOrder, 'jsonkey': 'listOrder', 'alignType': 'right', 'sortable': true, "searchable": false }],
        TablebodyData: this.allDiscountReason,
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.Search,
        EnableActions: true,
        SelectRows: true,
        IsCommission: true,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.discountReason,
        Sortable: 'listOrder',
        TableId: myGlobals.GridType.discountReason,
        disableDelete: false,
        customHeader: true,
        pageTitle: 'discountReason',
        ServiceId: 'discountReason',
        IsViewOnly: this.IsViewOnly,
        TableDraggable: true,
        automationId: 'retailDiscountReason'
      }
    ];
  }

}
