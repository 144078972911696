import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[DecimalNumbersOnly]'
})
export class DecimalDirective {
  private regex: RegExp = new RegExp(/^(\d*\.)?\d+$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'Delete'];
  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1 || event.ctrlKey) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (event.key === '.') {
      next = next.concat('0');
    }
    if (next && !String(next).match(this.regex) || event.key === '-') {
      event.preventDefault();
    }
  }
}
