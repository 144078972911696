import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NotifierBar } from './note.model';


@Component({
  selector: 'app-common-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent {
  @Input() noteMessage: NotifierBar;
  @Output() removeNotification = new EventEmitter();

  removeMessage(_$event){
    this.removeNotification.emit(false);
  }

}
