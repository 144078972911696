<div id="cust-header-id">
  <app-dialog-header [title]="captions.APPLYDISCOUNT" (closeDialog)="close()"></app-dialog-header>
</div>

<div class="discount-wrapper">
  <form class="discount-popover-body" #dicountForm="ngForm" autocomplete="off" [formGroup]="discountForm">
    <div *ngIf="showItemDetails" class="custom-item-details">
      <div class="lft-set">
        <label class="LW12 pt-2 pb-2">{{captions.ItemName}}</label>
        <p [matTooltip]="itemName">{{itemName}}</p>
      </div>
      <div class="right-set">
        <label class="LW12 pt-2 pb-2">{{captions.ItemPrice}}</label>
        <p [matTooltip]="itemPrice">{{(itemPrice | Currency)}}</p>
      </div>
    
    </div>
    <label class="LW12 pt-2 pb-2">{{captions.Discount}}</label>
    <mat-radio-group [attr.automationId]="'Rdo_shopApplyDiscount_discount'" [disabled]="!customDiscountsAccess" placeholder="Discount Type" class="d-block golf__radio pb-2" formControlName="type" (change)="typeChange($event)">
      <mat-radio-button [value]="1" class="pr-3 golf--form-radio-button">{{captions.PercentageWithoutSymbol}}</mat-radio-button>
      <mat-radio-button [value]="2" (click)="checkCustomDiscountControls($event)" class="golf--form-radio-button">{{captions.FlatAmount}}</mat-radio-button>
    </mat-radio-group>
    <div class="discount-popover-content">
    <div *ngIf="selectedType == 1">
     <div class="custom-set-display-fl">
      <mat-form-field class="d-block pr-3 pt-2 w-50" (click)="checkCustomDiscountControls($event)" [floatLabel]="floatLabel">
        <input RetailCurrencyFormatter [attr.automationId]="'Txt_shopApplyDiscount_percentageChange'" type="text" (blur)="onPercentageChange($event)" matInput formControlName="percentage" [preDecimalLength]="3" [postDecimalLength]="2" placeholder="{{captions.Value}} (%)">
      </mat-form-field>
      <mat-form-field class="pt-2 w-50 pr-3 w-50" [floatLabel]="floatLabel">
        <mat-label>{{captions.DiscountPercentage}}</mat-label>
        <mat-select [attr.automationId]="'Dd_shopApplyDiscount_discountPercentage'" formControlName="discType" (selectionChange)="discountTypeSelected($event)" [disabled]="discountForm.controls.percentage.value > 0">
          <mat-option></mat-option>
          <mat-option *ngFor="let discount of discountList" [value]="discount.discountTypeId" 
          matTooltipClass="tooltipClass" matTooltip= "{{discount.discountTypeName}} {{ this.ApplyDiscountInput.CategoryId == -1 ? '' : (' - ' + discount.discountValue
          + '%')}}">
            {{discount.discountTypeName}} {{ this.ApplyDiscountInput.CategoryId == -1 ? '' : (' - ' + discount.discountValue
            + '%')}}
          </mat-option>
        </mat-select>
      </mat-form-field>
     </div> 
   
    </div>

    <div class="custom-set-display-fl">
      <mat-form-field *ngIf="selectedType == 2" class="d-block w-50 pt-2 pr-3" [floatLabel]="floatLabel">
        <input [attr.automationId]="'Txt_shopApplyDiscount_amount'" type="text" matInput formControlName="amount" placeholder="{{captions.Value}} ({{localization.currencySymbol}})" RetailCurrencyFormatter required>
        <mat-error>Missing {{captions.Value}} ({{localization.currencySymbol}})</mat-error>
      </mat-form-field>
      <mat-form-field class="d-block pt-2 w-50 pr-3" [floatLabel]="floatLabel">
        <input [attr.automationId]="'Txt_shopApplyDiscount_comment'" type="text" matInput maxlength="50" formControlName="comment" placeholder="{{captions.DiscountComment}}" [required] ="commentRequired">
        <mat-error *ngIf="commentRequired">Missing {{captions.DiscountComment}}</mat-error>
      </mat-form-field>
      <mat-form-field class="pt-2 w-50 pr-3" [floatLabel]="floatLabel">
        <mat-label>{{captions.DiscountReason}} <span> </span></mat-label>
        <mat-select [attr.automationId]="'Dd_shopApplyDiscount_reason'" formControlName="reason" [required] ="reasonRequired">
          <mat-option></mat-option>
          <mat-option *ngFor="let discreason of discountReason | async" [value]="discreason.id">
            {{discreason.description}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reasonRequired">Missing {{captions.DiscountReason}}</mat-error>
      </mat-form-field>
    </div>
 
  </div>
  
  </form>
</div>
<div class="discount-popover-footer pt-1">
  <button mat-button [attr.automationId]="'Btn_shopApplyDiscount_apply'" class="" [disabled]="!isFormValid()"
    [ngClass]="!isFormValid() ? 'button--disabled' : 'button--primary-save'"
    (click)="apply()">{{captions.Apply}}</button>
        <button mat-button [attr.automationId]="'Btn_shopApplyDiscount_cancel'" (click)="close()" class="text-capital ml-2">{{captions.Cancel}}</button>
</div>

