import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { AllowedSettingsPublish } from '@app/shared/models/SignalRDto';
import { LayoutUpdateStatus, PartyState, PartyType, TableBlockBy, TableLayoutConfig } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { LayoutConfig } from '@models/global.interface';
import { LayoutDTO, ShiftDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { SettingsService } from '@services/settings.service';
import { TablesService } from '@services/tables.service';
import { LayoutFunctions } from '@utilities/layout-functions';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';
import { ISubscription, Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-blocking-table-selection',
  templateUrl: './blocking-table-selection.component.html',
  styleUrls: ['./blocking-table-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockingTableSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
  private canvas: any;
  displayLegends: any = [];
  config: Array<FieldConfig> = [];
  Shiftconfig: Array<FieldConfig> = [];
  floorPlan: any = [];
  inputTables: any = [];
  showMessage = false;
  layoutConfiguration: LayoutConfig;
  @Input() data: UntypedFormControl;
  //@ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  tabSubscription: ISubscription;
  selectedIndex: number;
  isCustomFloorAvailable = false;
  isShiftNeedToShow = false;
  selectedShift: number;
  selectedDate: Date;
  selectedTableFloor = false;
  selectedTable;
  initial: boolean = true;
  editData: { isEdit: boolean; selectedTables: any; };
  tableSubscription: ISubscription;
  isWaitTimeChange = false;
  CustomFloorPlan: any = [];
  isdateChanged = true;
  pageInitialize = true;
  selectedFlootplanId;
  isInitialLoad: boolean = true;
  isFirstLoad = true;
  _layout: LayoutDTO = {} as LayoutDTO;
  subscriptions: Subscription = new Subscription();
  updatedSelectedTableNames: any[] = [];
  constructor(public partyService: PartyService, public dialog: MatDialog, private ps: PopupService, private appService: AppService, private translateService: TranslateService,
    @Inject(COMPONENTINPUT) private inputData, private ss: SettingsService, private cs: CacheService, private cdf: ChangeDetectorRef, private ts: TablesService, private lf: LayoutFunctions) {
    this.subscriptions.add(cs.settings.subscribe(settings => {
      if (!this.pageInitialize) {
        this.CheckLayoutForDate(this.partyService.reservationFormGroup.value.selectedDate);
        this.setLayoutConfig();
        if (this.partyService.reservationFormGroup.value.selectedTable) {
          this.layoutConfiguration.selectedValues = this.partyService.reservationFormGroup.value.selectedTable;
        }
      }
      this.pageInitialize = false;
    }));
    this.subscriptions.add(cs.layout.subscribe(layt => {
      this._layout = layt;
    }));
    this.subscriptions.add(cs.state.subscribe(state => {
      if (!this.initial) {
        this.setLayoutConfig();
      }
    }));
    this.partyChanges();
  //  this.lf.loadIcons();
  }

  setLayoutConfig() {
    if (this.partyService.Parties$.value && this.ts.tableBlockingRules$.value && this.cs.layout.value && this.cs.settings.value) {
      let dates = new Date(this.partyService.reservationFormGroup.value.selectedDate);
      let shifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, dates);
      let shiftId = this.currentShiftID(shifts);
      let isUpdateFloorPlan = AllowedSettingsPublish[this.cs.settingsOperationName.value] == AllowedSettingsPublish['UpdateFloorPlan'];
      let pendingReservations = this.partyService.Parties$.value.filter(x => x.State == PartyState.Pending && x.Type == PartyType.Reservation);
      let seatedParties = this.partyService.Parties$.value.filter(x => x.State == PartyState.Seated)
      let CurrentReservations = (pendingReservations).concat(seatedParties.filter(x => (x.Type == PartyType.Reservation || (x.Type == PartyType.WalkIn && x.State == PartyState.Seated))));
      let blockval = this.ts.getBlockingRulesFortheDate(new Date( this.partyService.reservationFormGroup.get('selectedDate').value));
      let blockingRules = this.ts.getBlockingRulesForShift(blockval, this.ts.shiftSelectedForBlockingRule);
      this.layoutConfiguration = {
        from: TableLayoutConfig.reservationTableSelection,
        tables: this.floorPlan[0].StandaloneTables,
        reservations: CurrentReservations,
        blockingRules: blockingRules,
        floor: this.floorPlan,
        type: (this.initial || isUpdateFloorPlan) ? LayoutUpdateStatus.New : LayoutUpdateStatus.Update,
        isAdvanceBlock: true
      };
      this.cs.settingsOperationName.next(null);
      this.ts.standAloneTables = this.floorPlan[0].StandaloneTables;
      this.layoutConfiguration = { ...this.layoutConfiguration };
      this.partyService.layoutCofiguartion = this.layoutConfiguration;
    }
  }

  partyChanges() {
    this.subscriptions.add(this.partyService.Parties$.subscribe((data) => {
      if (this.layoutConfiguration)
      this.setLayoutConfig();
    }));
  }

  ngOnInit() {
    this.selectedDate = this.partyService.reservationFormGroup.value.selectedDate;
    this.displayLegends = [{ State: 1, name: 'Reserved' }, { State: 5, name: 'Blocked' }];
    this.config = [];
    this.Shiftconfig = [];
    // this.loadFloors();
    if (this.partyService.tabsModal) {
      this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === BlockingTableSelectionComponent);
    }
    this.CheckLayoutForDate(this.partyService.reservationFormGroup.value.selectedDate);
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedDate').valueChanges.subscribe((val) => {
      this.isdateChanged = true;
      this.selectedTable = null;
      this.selectedShift = null;
      this.partyService.layoutCofiguartion.tables = [];
      this.CheckLayoutForDate(val);
    }));
  }

  loadFloors() {
    this.floorPlan = this.cs.layout.value.FloorPlans;
    const floorPlanArray = [];
    this.floorPlan.forEach(element => {
      if (element.IsDefault) {
        floorPlanArray.push({ id: element.Id, value: element.Name });
      }
    });
    this.layoutConfiguration = {
      from: TableLayoutConfig.reservationTableSelection,
      tables: this.floorPlan.filter(x => x.IsDefault == true)[0].StandaloneTables,
      selectedValues: this.data,
      reservations: [],
      blockingRules: this.ts.getBlockingRulesForShift(this.ts.tableBlockingRules$.value, this.ts.shiftSelectedForBlockingRule),
      isAdvanceBlock: true
    };
    this.config.push({
      type: 'select',
      name: 'floorPlan',
      options: floorPlanArray,
      class: 'login__language-selection',
      showErrorText: true,
      appearance: true,
      isTranslate: true,
      label: 'unblockbuttontext',
      placeholder: 'Floor',
      value: floorPlanArray[0].id,
      cellClick: this.setSelectedFloorPlan.bind(this)
    }
    );

    if (this.inputData) {
      // this.getLayout();
      if (this.isFirstLoad) {
        this.isdateChanged = false;
        let Shiftbval = this.selectedShift;
        let dates = new Date(this.partyService.reservationFormGroup.value.selectedDate);
        let shifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, dates);
        let currentshift = this.currentShiftID(shifts);
        if (shifts && currentshift) {
          Shiftbval = this.inputData && this.inputData.Shifts ? this.inputData.Shifts[0].Id :
          (this.selectedShift && this.selectedShift > 0) ? this.selectedShift : currentshift ? currentshift : shifts[0].Id;
        }
        let SpecificShiftCustomFloorPlan = Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, dates, Shiftbval);
        let istableavailble = SpecificShiftCustomFloorPlan[0].StandaloneTables.filter((el) => {
          return this.inputData.TableIds.some((f) => {
            return f == el.Id;
          })
        });
        if (istableavailble && istableavailble.length > 0) {
          this.selectedTable = this.inputData.TableIds;
        }
        if (SpecificShiftCustomFloorPlan && SpecificShiftCustomFloorPlan.length > 0 && SpecificShiftCustomFloorPlan[0].Dates) {
          this.selectedTableFloor = true;
        }
        else {
          this.selectedTableFloor = false;
        }
        this.isFirstLoad = false;
        this.reservationFormChange();
        setTimeout(() => {
          var value = this.partyService.reservationFormGroup.get('selectedBlockingType').value;
          if(value == TableBlockBy.ByTimeRange){
            let fromTime = this.partyService.reservationFormGroup.get("selectedFromTime").value != '' ? this.partyService.reservationFormGroup.get("selectedFromTime").value.split(':') : '';
            let toTime = this.partyService.reservationFormGroup.get("selectedToTime").value != '' ? this.partyService.reservationFormGroup.get("selectedToTime").value.split(':') : '';
            let fromValue = '';let toValue = '';
            if (fromTime != '' && toTime != '') {
              fromValue = fromTime[0] + ':' + fromTime[1];
              toValue = toTime[0] + ':' + toTime[1];
              if(this.partyService.tabsModal.tabs[3]?.tabLabel)
              this.partyService.tabsModal.tabs[3].tabLabel = fromValue + '-' + toValue;
            }
            this.ps.formValid$.next(
              {
                isFormValid: true,
                currentTab: this.selectedIndex
              } as IFormValidDetails);
          } else {
            if(this.partyService.tabsModal.tabs[3]?.tabLabel)
            this.partyService.tabsModal.tabs[3].tabLabel = "Shifts";
          }

          // this.partyService.reservationFormGroup.get('selectedDate').updateValueAndValidity({ onlySelf: false, emitEvent: true })
        },0)
      }
    }
  }

  getShifts(date) {

    if (this.isShiftNeedToShow) {
      let dates = new Date(date);
      let shifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, dates);
      // this.ss.getShiftForDay(dates, true, null).subscribe((response) => {
      this.loadShifts(shifts);
      // });
    } else {
      this.loadShifts();
    }

  }

  loadShifts(Shifts?) {
    this.config = [];
    this.Shiftconfig = [];
    if (Shifts) {
      let datas = [];
      Shifts.forEach(element => {
        datas.push({ id: element.Id, value: element.Name });
      });
      let currentshift = this.currentShiftID(Shifts);
      if (this.isdateChanged) {
        this.selectedShift = this.selectedShift && this.selectedShift > 0 ? this.selectedShift : currentshift ? currentshift : datas[0].id;
      } else {
        this.selectedShift = this.inputData && this.inputData.Shifts ? this.inputData.Shifts[0].Id :
          (this.selectedShift && this.selectedShift > 0) ? this.selectedShift : currentshift ? currentshift : datas[0].id;
      }
      this.ts.shiftSelectedForBlockingRule = this.selectedShift;
      if (this.isShiftNeedToShow) {
        this.Shiftconfig.push({
          type: 'select',
          name: 'shift',
          options: datas,
          class: 'login__language-selection',
          appearance: true,
          isTranslate: false,
          value: this.selectedShift,
          placeholder: 'shift',
          label: 'unblockbuttontext'
        });
      }
    }
    this.loadFloors();
  }

  currentShiftID(TodayShifts: ShiftDTO[]) {
    let curentshift = 0;
    if (this.isCustomFloorAvailable) {
      let currentdate = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
      let selecteddate = new Date(this.appService._headerDate.setHours(0, 0, 0, 0)).getTime();
      let CurrentDateTime = new Date(new Date(currentdate).setHours(0, 0, 0, 0));
      if (CurrentDateTime.getTime() == selecteddate) {
        let CurrentShift = TodayShifts.filter(Shift => {
          if (Utilities.datetimeBetween(Shift.EffectiveRange.Start, Shift.EffectiveRange.End, currentdate)) {
            return true;
          }
        });
        if (CurrentShift && CurrentShift.length > 0) {
          curentshift = CurrentShift[0].Id;
        } else {
          let PrevShift = TodayShifts.filter(Shift => {
            if (Utilities.diffBetweenDateTime(Shift.EffectiveRange.End, currentdate) < 0) {
              return true;
            }
          });

          if (PrevShift && PrevShift.length > 0) {
            let maxtime = Math.max.apply(Math, PrevShift.map(function (o) { return new Date(Utilities.formateDateString(o.EffectiveRange.End)); }))
            let maxHour = new Date(maxtime).getHours() + (new Date(maxtime).getMinutes() / 60);
            let maxshift = PrevShift.filter(Shift => {
              let Shiftendhour = Utilities.formateDateString(Shift.EffectiveRange.End).getHours() + (Utilities.formateDateString(Shift.EffectiveRange.End).getMinutes() / 60);
              if (Number(Shiftendhour) == Number(maxHour)) {
                return true;
              }
            });
            if (maxshift && maxshift.length > 0) {
              curentshift = maxshift[0].Id;
            }
            else {
              curentshift = PrevShift[0].Id;
            }

          } else {
            curentshift = TodayShifts[0].Id;
          }

        }

      } else if (new Date(CurrentDateTime.setDate(CurrentDateTime.getDate() - 1)).getTime() == selecteddate) {
        let CurrentShift = TodayShifts.filter(Shift => {
          if (Utilities.datetimeBetween(Shift.EffectiveRange.Start, Shift.EffectiveRange.End, currentdate)) {
            return true;
          }
        });

        if (CurrentShift != null && CurrentShift.length > 0) {
          curentshift = CurrentShift[0].Id;
        }
        else {
          curentshift = TodayShifts[0].Id;
        }
      } else {
        curentshift = TodayShifts[0].Id;
      }

    } else {
      curentshift = 0;
    }

    return curentshift;
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.tabSubscription = this.partyService.tabChange$.subscribe((data) => {
      if (data === this.selectedIndex) {
        if (this.inputData && this.isInitialLoad) {
          this.isInitialLoad = false;
          this.getLayout();
          this.isdateChanged = false;
        }
        if (this.inputData && (!this.selectedTable)) {
          this.editData = { isEdit: true, selectedTables: this.inputData.TableIds };
        }
        if (!this.isShiftNeedToShow) {
          if (this.config.length > 1)
            this.config.splice(1, 1);
        }
        else {

          if (this.components.length > 0) {
            this.subscriptions.add(this.components.first.form.valueChanges.subscribe(val => {
              if (val.shift) {
                if (this.selectedShift && val.shift != this.selectedShift) {
                  this.selectedShift = val.shift;
                  this.ts.shiftSelectedForBlockingRule = this.selectedShift;
                  this.selectedTable = null;
                  this.partyService.highlightSelectedTables = [];
                  this.getLayout();
                }
              }
            }));
          }
        }
        if (this.isdateChanged) {
          this.getLayout();
          this.isdateChanged = false;
        }
        if (this.components.length > 0) {
          this.subscriptions.add(this.components.last.form.valueChanges.subscribe(val => {
            if (val.floorPlan) {
            }
          }));
        }
        if (this.partyService.reservationFormGroup.value.selectedTable) {
          this.layoutConfiguration.selectedValues = this.partyService.reservationFormGroup.value.selectedTable;
        }

        this.reservationFormChange();
      }
    }));
    this.tableSubscription = this.partyService.tableSelected.subscribe(val => {
      this.selectedTable = val;
      this.updatedSelectedTableNames = Utilities.getTableNamesFromStandaloneTables(val, this._layout.FloorPlans);
      this.reservationFormChange();
      this.makeReservationFormDirty();
    });
    this.subscriptions.add(this.ss.blockingdataChange$.subscribe(val => {
      let blockval = this.ts.getBlockingRulesFortheDate(new Date( this.partyService.reservationFormGroup.get('selectedDate').value));
      if (this.layoutConfiguration) {
        this.layoutConfiguration.blockingRules = this.ts.getBlockingRulesForShift(blockval, this.ts.shiftSelectedForBlockingRule),
        this.layoutConfiguration = {...this.layoutConfiguration};
      }
    }));
    this.cdf.detectChanges();
  }

  getLayout() {
    let date = this.getParseDateWithTime(this.partyService.reservationFormGroup.controls.selectedDate.value);
    this.subscriptions.add(this.partyService.getStandaloneTablesFutureStateAllFloorTables(date, 2, -1).subscribe(tables => {
      if (tables) {
        let updatedTable = this.UpdateTableStatus(tables);
        if (this.isdateChanged) {
          this.layoutConfiguration.type = LayoutUpdateStatus.New
        }
        else {
          this.layoutConfiguration.type = LayoutUpdateStatus.New
        }
        this.initial = false;
        this.layoutConfiguration = { ...updatedTable };
        this.partyService.layoutCofiguartion = this.layoutConfiguration;
      }
    }));
  }

  UpdateTableStatus(fututreTableStates) {
    this.selectedTableFloor = false;
    let selectedDate = new Date(this.partyService.reservationFormGroup.controls.selectedDate.value);
    let standaloneTables;
    let SpecificShiftCustomFloorPlan = Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, selectedDate, this.selectedShift);
    if (this.selectedFlootplanId && JSON.stringify(SpecificShiftCustomFloorPlan[0].StandaloneTables) != JSON.stringify(this.selectedFlootplanId)) {
      this.selectedTable = null;
    }
    this.selectedFlootplanId = SpecificShiftCustomFloorPlan[0].StandaloneTables;
    standaloneTables = SpecificShiftCustomFloorPlan[0].StandaloneTables;
    if (SpecificShiftCustomFloorPlan.length > 0 && SpecificShiftCustomFloorPlan[0].Dates) {
      this.selectedTableFloor = true;
    }
    else {
      this.selectedTableFloor = false;
    }
    this.loadFloorPlans(SpecificShiftCustomFloorPlan[0].FloorPlanViews);
    const StandaloneTablesWithStates = [];
    for (let i = 0; i < standaloneTables.length; i++) {
      if (this.inputData && this.inputData.TableIds) {
        //if(standaloneTables[i].Id == this.inputData.TableIds)
        let istableavailble = this.inputData.TableIds.filter(data => {
          return data == standaloneTables[i].Id
        })
        if (istableavailble && istableavailble.length > 0) {
          this.selectedTable = this.inputData.TableIds;
        }
      }
      StandaloneTablesWithStates.push({
        ...standaloneTables[i],
        ...(fututreTableStates.find((state) => state.StandaloneTableId === standaloneTables[i].Id))
      }
      );
    }
    const reservations = this.partyService.setReservations(fututreTableStates);
    let blockingRules = this.ts.getBlockingRulesFortheDate(new Date( this.partyService.reservationFormGroup.get('selectedDate').value));
    this.layoutConfiguration = {
      from: TableLayoutConfig.reservationTableSelection,
      tables: StandaloneTablesWithStates,
      reservations,
      blockingRules: this.ts.getBlockingRulesForShift(blockingRules, this.ts.shiftSelectedForBlockingRule),
      floor: SpecificShiftCustomFloorPlan,
      isAdvanceBlock: true
    };
    this.reservationFormChange();
    return this.layoutConfiguration;
  }

  loadFloorPlans(FloorPlanViews) {
    let floorPlanOptions = Utilities.getFloorPlan(FloorPlanViews,this.translateService);
    floorPlanOptions = floorPlanOptions.map(option => {
      option.value = this.translateService.instant(option.value)
      return option;
    });
    this.config = [];
    this.config.push({
      type: 'select',
      name: 'floorPlan',
      options: floorPlanOptions,
      class: 'login__language-selection',
      showErrorText: true,
      appearance: true,
      isTranslate: true,
      label: 'unblockbuttontext',
      placeholder: 'Floor',
      value: floorPlanOptions[0].id,
      cellClick: this.setSelectedFloorPlan.bind(this)
    }
    );
  }

  setSelectedFloorPlan(eve) {
    this.layoutConfiguration.selectedValues = this.partyService.reservationFormGroup.value.selectedTable;
    this.ts.selectedFloorPlanPopup.next(eve.value);

  }

  CheckLayoutForDate(selecteddate) {
    let selectedDate = new Date(selecteddate).setHours(0, 0, 0, 0);
    this.CustomFloorPlan = Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, selecteddate);
    if (this.CustomFloorPlan && this.CustomFloorPlan.length > 0 && this.CustomFloorPlan[0].Dates) {
      this.isCustomFloorAvailable = true;
      let getShiftForDate = this.CustomFloorPlan
        .filter((element) =>
          element.Dates.some((subElement) => new Date(subElement.Date).setHours(0, 0, 0, 0) === selectedDate && subElement.ShiftId != null))
        .map(element => {
          let newElt = Object.assign({}, element);
          return newElt.Dates.filter(subElement => new Date(subElement.Date).setHours(0, 0, 0, 0) === selectedDate && subElement.ShiftId != null);
        });

      if (getShiftForDate.length > 0) {
        this.isShiftNeedToShow = true;
      } else {
        this.isShiftNeedToShow = false;
      }

    } else {
      this.isCustomFloorAvailable = false;
      this.isShiftNeedToShow = false;
      if (this.config.length > 1)
        this.config.splice(1, 1);
    }
    this.getShifts(selecteddate);
  }

  getParseDateWithTime(date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
  }

  throwError(eve) {
    if (eve) {
      this.showMessage = true;
    } else {
      this.showMessage = false;
    }
  }

  makeReservationFormDirty() {
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.partyService.layoutCofiguartion = { from: TableLayoutConfig.reservationTableSelection };
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
    if (this.tableSubscription) {
      this.tableSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  reservationFormChange() {
    let crntSelectedVal = this.partyService.reservationFormGroup.value.selectedTable;
    if (JSON.stringify(this.selectedTable) !== JSON.stringify(crntSelectedVal)) {
      this.partyService.reservationFormGroup.get('selectedTable').setValue(this.selectedTable);
    }
    if (this.selectedTableFloor != this.partyService.reservationFormGroup.value.selectedTableFloor) {
      this.partyService.reservationFormGroup.get('selectedTableFloor').setValue(this.selectedTableFloor);
    }
    if (this.selectedShift != this.partyService.reservationFormGroup.value.selectedTableShift) {
      this.partyService.reservationFormGroup.get('selectedTableShift').setValue(this.selectedShift);
    }
    this.ps.formValid$.next(
      {
        isFormValid: this.partyService.reservationFormGroup.get('selectedTable').value && this.partyService.reservationFormGroup.get('selectedTable').value.length > 0 ? true : false,
        currentTab: this.selectedIndex
      } as IFormValidDetails);
  }
}
