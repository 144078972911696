<section class="ticketDetailSection h-100">
  <form [formGroup]="ticketDetailsForm" class="h-100">
    <div class="ticket-header LW20 d-table">
      <span class="cellMidAlign">
        <i [attr.automationId]="'Icn_shopTicketDetails_gotoTransactionPage'" aria-hidden="true" class="icon-left-arrow pr-2" (click)="GotoTransactionPage()"></i>
      </span>
      <span class="cellMidAlign">{{selectedTicketNumber}}</span>
    </div>
    <div class="TD_custheight">
      <div class="TD_leftPane">
        <label class="ticketLabel">{{captions.TicketDetails}}</label>
        <app-shop-table [attr.automationId]="'Tbl_shopTicketDetails_ticketDetails'" [options]="tableoptions" (radioClickEvtEmitter)="radiobuttonClicked($event)"></app-shop-table>
      </div>
      <div class="TD_rightPane" [ngClass]="{'disabledSection': !this._shopservice.rowdata}">
        <div class="staff-name">
          <label>{{captions.StaffName}}</label>
          <mat-form-field class="w-100" [floatLabel]="floatLabel">
            <mat-select [attr.automationId]="'Dd_shopTicketDetails_staffName'" (selectionChange)="SelectStaffForCommission(staffName.value)" formControlName="staffName" name="staffName" #staffName required>
              <mat-option *ngFor="let staff of commissionableStaff" [value]="staff.id">
                {{ staff.staffName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="return-reason mt-3">
          <label>{{captions.TotalCommission}}</label>
          <div class="display_style_toggle  toggle-section ">
            <mat-button-toggle-group [attr.automationId]="'Tog_shopTicketDetails_commissionType'" class="margin_top_9 LW14 mb-3 " name="commissiontype"
              formControlName="commissiontype">
              <mat-button-toggle class=" border_right toggle-btn" [value]="this.GivenCommissionAmountTypeEnum.Percentage" #perc (click)="percentClick()">
                {{captions.Percentage}}
              </mat-button-toggle>
              <mat-button-toggle class=" border_right toggle-btn" [value]="this.GivenCommissionAmountTypeEnum.Amount" #amt (click)="amountClick()">
                {{captions.Amount}} {{localization.currencySymbol}}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="ticket-values mt-4">
          <label class="LWB14">{{captions.Value}} <span class="percent"
              *ngIf="ticketDetailsForm['controls'].commissiontype.value == this.GivenCommissionAmountTypeEnum.Percentage || ticketDetailsForm['controls'].commissiontype.value == this.GivenCommissionAmountTypeEnum.Percentage">(%)</span> <span class="dollar"
              *ngIf="ticketDetailsForm['controls'].commissiontype.value == this.GivenCommissionAmountTypeEnum.Amount || ticketDetailsForm['controls'].commissiontype.value ==this.GivenCommissionAmountTypeEnum.Amount">({{localization.currencySymbol}})</span></label>
          <mat-form-field class="w-100" [floatLabel]="floatLabel">
            <input [attr.automationId]="'Txt_shopTicketDetails_commissionValue'" matInput RetailCurrencyFormatter [preDecimalLength] ="TicketMaxLength" name="commissionvalue"
              formControlName="commissionvalue" #commissionvalue required>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <div class="pop-button pl-3">
        <button [attr.automationId]="'Btn_shopTicketDetails_saveCommission'" [ngClass]="enableSave ? 'button--primary-save' : 'button--disabled'" mat-button
          [disabled]="!enableSave" (click)="this.SaveCmmission()"
          class="saveButton">{{this.captions.Save}}</button>
        <button [attr.automationId]="'Btn_shopTicketDetails_cancel'" class="spa-button-cancel" mat-button (click)="onNoClick()">{{this.captions.Cancel}}</button>
      </div>
    </div>
  </form>
</section>
