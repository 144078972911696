<div class="activity-booking-summary__rate-plan">
  <!-- <app-rate-plan-summary></app-rate-plan-summary> -->
  <div class="activity-booking-summary__section-title"> {{'Rates' | translate}}
    <!-- <ng-container *ngIf="">
    <dynamic-form [config]="negotiationRatesConfig" #form="dynamicForm">
    </dynamic-form>
  </ng-container> -->
  </div> <!-- <form class="personal_container  p-4 h-100" [formGroup]="FormGrp" autocomplete="off"> -->
    
  <mat-accordion *ngFor="let slot of ratePlan.SlotList; let index= index;">
    <mat-expansion-panel [expanded]="index == 0"
      class="activity-booking-summary__summary-accordian--panel activity-booking-summary__summary-accordian--rate-plan">
      <mat-expansion-panel-header>
        <mat-panel-title class="activity-booking-summary__section-title--accordian"> <span *ngIf="isSessionClass">
            {{slot.TimeRange.Start | localizedDate: 'DD MMMM, LT' }} - {{slot.TimeRange.End | localizedDate:'LT'}}
          </span>
          <span *ngIf="!isSessionClass">
            {{slot.TimeRange.Start | localizedDate: 'DD MMMM' }} - {{slot.TimeRange.End | localizedDate: 'DD MMMM'}}
          </span>
          <span *ngIf="slot.sessionState">({{slot.sessionState | translate}})</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

              <!-- Table Header -->
              <div class="py-1 price-table-header d-flex flex-row seat-header-four">
                <div class="p-0 col-lg-3 col-3">
                  <div class="activity-description-bolder">{{'Description' | translate}}</div>
                </div>
                <div class="p-0 col-lg-1 col-1 d-flex justify-content-center">
                  <div class="activity-description-bolder">{{'Qty' | translate}}</div>
                </div>
                <div class="p-0 col-lg-2 col-2 d-flex justify-content-end text-align-right">
                  <div class="activity-description-bolder">{{'PrevSalePrice' | translate}}</div>
                </div>
                <div class="p-0 col-lg-2 col-2 d-flex justify-content-end text-align-right">
                  <div class="activity-description-bolder">{{'OriginalAmount' | translate}}</div>
                </div>
                <div class="p-0 col-lg-2 col-2 d-flex justify-content-end">
                  <div class="activity-description-bolder">{{'SalePrice' | translate}}</div>
                </div>
                <div class="p-0 col-lg-2 col-2 d-flex justify-content-end">
                  <div class="activity-description-bolder">{{'ItemTotal' | translate}}</div>
                </div>
              </div>

              <!-- Base Rate -->
              <ng-container
              >
              <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row"
                *ngIf="slot.RatePlanVariants && slot.RatePlanVariants.TotalBaseRate">
                <div class="activity-booking-summary__rate-label p-0 col-lg-3 col-3 d-flex "> <span
                    class="activity-booking-summary__rate-label--main">{{'TotalBaseRate' | translate}}</span> 
                  </div>
                <div class="activity-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
                  <span
                    class="activity-booking-summary__rate-label--main">{{this.partySize}}</span>
                </div>
                <div  class="activity-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">{{OperationCurrency}} {{slot.basePrevSalePrice | currencyDecimalFormatter }}</div>
                <div  class="activity-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">{{OperationCurrency}} {{
                  slot.sessionChargeinitialValue |
                  currencyDecimalFormatter}}</div>
                <div *ngIf="!perItemEditable" class="activity-booking-summary__rate">{{OperationCurrency}} {{
                  slot.sessionCharge |
                  currencyDecimalFormatter}}</div>
                <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
                  *ngIf="perItemEditable">
                   <!-- <input
                    class="activity-description-bolder activity-booking-summary__editable-input-currency"
                    [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> 
                    
                    <input ngxNumberFormat="#,###,###.##" [allowNegative]="true"  (blur)="onBlur($event)"
                    class="activity-description-bolder activity-booking-summary__editable-input" type='text' min="1"
                    step="0.10" [(ngModel)]="slot.sessionCharge"  (keyup)="onNegotiate()" 
                     [ngClass]="{'pointer-active': perItemEditable}"
                    value=" slot.sessionCharge | currencyDecimalFormatter" >  -->
                    {{OperationCurrency}} {{
                      (slot.sessionCharge) |
                                      currencyDecimalFormatter
                                          }}
                  </div>
                <div class="activity-booking-summary__rate p-0 col-lg-2 col-2 d-flex justify-content-end">
                  {{OperationCurrency}} {{(slot.sessionCharge) |
                  currencyDecimalFormatter}}</div>
              </div>
            </ng-container>


      <!-- Location Variant -->
      <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row ml-2 "
          *ngIf="slot.RatePlanVariants && slot.RatePlanVariants.OriginalLocationVariant">
            <div class="activity-booking-summary__rate-label d-flex  p-0 col-lg-4 col-4">
              <div class="activity-description-bolder">
                {{'LocationVariant' | translate}} 
                <span class="sub-text">({{'forTotalOf' | translate}} {{slot.size ? slot.size :
                    partyService.sessionBookingData?.BookingSize}}
                    )</span>
                </div>
            </div>
            <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-center">
              <div class="activity-description-bolder">{{OperationCurrency}} {{slot.prevLocationSalePrice | currencyDecimalFormatter}} </div>
            </div>
            <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
              <span
                class="activity-booking-summary__rate-label--main text-align-right">{{OperationCurrency}}  {{(slot.RatePlanVariants.OriginalLocationVariant * this.partySize) | currencyDecimalFormatter}}</span>
            </div>  
            <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
              *ngIf="perItemEditable"> 
              <input DecimalNumbersOnly  (blur)="onBlur($event)"
                class="activity-description-bolder activity-booking-summary__editable-input-currency"
                [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>
              
                <input ngxNumberFormat="#,###,###.##" [allowNegative]="true"
                class="activity-description-bolder activity-booking-summary__editable-input" type='text' min="1"
                step="0.10" [(ngModel)]="slot.RatePlanVariants.locationAmount"  (keyup)="onNegotiate()" 
                [ngClass]="{'pointer-active': perItemEditable}">
            </div>
            <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
              <div class="activity-description-bolder">{{OperationCurrency}} {{slot.RatePlanVariants.locationAmount |
                currencyDecimalFormatter}}</div>
            </div>
          </div> 

                <!-- Instructor Variant -->
            <!-- Instructor Variant-->

            <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row ml-2 "
            *ngIf="slot.RatePlanVariants && slot.RatePlanVariants.OriginalInstructorVariant">
              <div class="activity-booking-summary__rate-label d-flex  p-0 col-lg-4 col-4">
                <div class="activity-description-bolder">
                  {{'InstructorVariant' | translate}}
                  <span class="sub-text">({{'forTotalOf' | translate}} {{slot.size ? slot.size :
                      partyService.sessionBookingData?.BookingSize}}
                      )</span>
                  </div>
              </div>
              <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-center">
                <div class="activity-description-bolder">{{OperationCurrency}} {{slot.prevInstructorSalePrice | currencyDecimalFormatter}} </div>
              </div>
              <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                <span
                  class="activity-booking-summary__rate-label--main text-align-right">{{OperationCurrency}}  {{(slot.RatePlanVariants.OriginalInstructorVariant * this.partySize) | currencyDecimalFormatter}}</span>
              </div>  
              <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
                *ngIf="perItemEditable"> 
                <input DecimalNumbersOnly  (blur)="onBlur($event)"
                  class="activity-description-bolder activity-booking-summary__editable-input-currency"
                  [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>
                  
                  <input ngxNumberFormat="#,###,###.##" [allowNegative]="true"
                  class="activity-description-bolder activity-booking-summary__editable-input" type='text' min="1"
                  step="0.10" [(ngModel)]="slot.RatePlanVariants.instructorAmount" (keyup)="onNegotiate()" 
                  [ngClass]="{'pointer-active': perItemEditable}">
              </div>
              <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                <div class="activity-description-bolder">{{OperationCurrency}} {{slot.RatePlanVariants.instructorAmount |
                  currencyDecimalFormatter}}</div>
              </div>
            </div> 
      
                      <!-- Instructor Variant -->
            <!-- Instructor Variant-->
<!-- 
            <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row ml-2 "
            *ngIf="slot.RatePlanVariants && slot.RatePlanVariants.OriginalInstructorVariant">
              <div class="activity-booking-summary__rate-label d-flex  p-0 col-lg-4 col-4">
                <div class="activity-description-bolder">
                  {{'InstructorVariant' | translate}}
                  <span class="sub-text">({{'forTotalOf' | translate}} {{slot.size ? slot.size :
                      partyService.sessionBookingData?.BookingSize}}
                      )</span>
                  </div>
              </div>
              <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-center">
                <div class="activity-description-bolder">{{OperationCurrency}} {{slot.prevInstructorSalePrice | currencyDecimalFormatter}} </div>
              </div>
              <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                <span
                  class="activity-booking-summary__rate-label--main text-align-right">{{OperationCurrency}}  {{(slot.RatePlanVariants.OriginalInstructorVariant * this.partySize) | currencyDecimalFormatter}}</span>
              </div>  
              <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
                *ngIf="perItemEditable"> 
                <input DecimalNumbersOnly  (blur)="onBlur($event)"
                  class="activity-description-bolder activity-booking-summary__editable-input-currency"
                  [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>
                  
                  <input ngxNumberFormat="#,###,###.##" [allowNegative]="true"
                  class="activity-description-bolder activity-booking-summary__editable-input" type='text' min="1"
                  step="0.10" [(ngModel)]="slot.RatePlanVariants.instructorAmount" (keyup)="onNegotiate()" 
                  [ngClass]="{'pointer-active': perItemEditable}">
              </div>
              <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
                <div class="activity-description-bolder">{{OperationCurrency}} {{slot.RatePlanVariants.instructorAmount |
                  currencyDecimalFormatter}}</div>
              </div>
            </div>  -->
      <!-- Booking Types -->
      <!-- <ng-container>
        <div class="activity-booking-summary__rate-row"
          *ngIf="slot.bookingTypeVariants && slot.bookingTypeVariants.length">
          <div class="activity-booking-summary__rate-label"> <span
              class="activity-booking-summary__rate-label sub-label">{{'bookingType' | translate}} </span> </div>
        </div>
        <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row ml-2"
          *ngFor="let variant of slot.bookingTypeVariants">
          <div class="activity-booking-summary__rate-label d-flex  p-0 col-lg-3 col-3">
            <div class="activity-description-bolder">{{variant.BookingTypeName}}</div>
          </div>
          <div class="activity-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
            <div class="activity-description-bolder"> {{variant.bookingTypes}} </div>
          </div>
          <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
            <span
              class="activity-booking-summary__rate-label--main text-align-right">-</span>
          </div>
          <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
            <span
              class="activity-booking-summary__rate-label--main text-align-right">{{OperationCurrency}} {{variant.initialValue|
                currencyDecimalFormatter}}</span>
          </div>
          <div *ngIf="!perItemEditable" class="activity-booking-summary__rate">{{OperationCurrency}}
            {{(variant.bookingTypeValue)|
            currencyDecimalFormatter}}</div>
          <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
            *ngIf="!perItemEditable"> <input
              class="activity-description-bolder activity-booking-summary__editable-input-currency"
              [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> 
              <input ngxNumberFormat="#,###,###.##" [allowNegative]="true"  (blur)="onBlur($event)"
              class="activity-description-bolder activity-booking-summary__editable-input" type='text' min="1"
              step="0.10" [(ngModel)]="variant.bookingTypeValue"  (keyup)="onNegotiate()"  value="{{variant.Price / variant.bookingTypes}}"
               [ngClass]="{'pointer-active': perItemEditable}"> </div>
          <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
            <div class="activity-description-bolder">{{OperationCurrency}}  {{(variant.bookingTypeValue * variant.bookingTypes  
              ) |
                currencyDecimalFormatter}}</div>
          </div>
        </div>
      </ng-container>
       -->
      
      <!-- Cover Type-->
      <div class="activity-booking-summary__rate-row"
        *ngIf="slot.coverTypeVariants && slot.coverTypeVariants.length">
        <div class="activity-booking-summary__rate-label"> <span
            class="activity-booking-summary__rate-label sub-label">{{'coverType' | translate}} </span> </div>
      </div>
      <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row ml-2 "
        *ngFor="let variant of slot.coverTypeVariants">
        <div class="activity-booking-summary__rate-label d-flex  p-0 col-lg-3 col-3">
          <div class="activity-description-bolder">{{variant.CoverTypeName}}</div>
        </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
          <div class="activity-description-bolder"> {{variant.covers}} </div>
        </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
          <span
            class="activity-booking-summary__rate-label--main text-align-right">{{OperationCurrency}} {{variant.prevSalePrice | currencyDecimalFormatter}}</span>
        </div> 
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
          <span
            class="activity-booking-summary__rate-label--main text-align-right">{{OperationCurrency}} {{variant.initialValue }}</span>
        </div>             
        <div *ngIf="!perItemEditable" class="activity-booking-summary__rate">{{OperationCurrency}}
          {{(variant.coverTypeValue
          ) }}</div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
          *ngIf="perItemEditable"> 
          <input DecimalNumbersOnly  (blur)="onBlur($event)"
            class="activity-description-bolder activity-booking-summary__editable-input-currency"
            [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> 
            <input ngxNumberFormat="#,###,###.##" [allowNegative]="true" 
            class="activity-description-bolder activity-booking-summary__editable-input" type='text' min="1"
            step="0.10" [(ngModel)]="variant.coverTypeValue" (keyup)="onNegotiate()"  
            [ngClass]="{'pointer-active': perItemEditable}"
            value="{{variant.Price/variant.covers |
              currencyDecimalFormatter}}">
        </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
          <div class="activity-description-bolder">{{OperationCurrency}} {{(variant.coverTypeValue * variant.covers  
          ) |
            currencyDecimalFormatter}}</div>
        </div>
      </div>
      <!-- Booking Types -->
      <ng-container >
        <div class="activity-booking-summary__rate-row"
          *ngIf="slot.bookingTypeVariants && slot.bookingTypeVariants.length">
          <div class="activity-booking-summary__rate-label"> <span
              class="activity-booking-summary__rate-label sub-label">{{'bookingType' | translate}} </span> </div>
        </div>
        <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row ml-2"
          *ngFor="let variant of slot.bookingTypeVariants">
          <div class="activity-booking-summary__rate-label d-flex  p-0 col-lg-3 col-3">
            <div class="activity-description-bolder">{{variant.BookingTypeName}}</div>
          </div>
          <div class="activity-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
            <div class="activity-description-bolder"> {{variant.bookingTypes}} </div>
          </div>
          <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex text-align-right justify-content-end">
            <div class="activity-description-bolder">{{OperationCurrency}}  {{variant.prevSalePrice | currencyDecimalFormatter}} </div>
          </div>
          <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
            <span
              class="activity-booking-summary__rate-label--main text-align-right">{{OperationCurrency}} {{variant.initialValue|
                currencyDecimalFormatter}}</span>
          </div>
          <div *ngIf="!perItemEditable" class="activity-booking-summary__rate">{{OperationCurrency}}
            {{(variant.bookingTypeValue)|
            currencyDecimalFormatter}}</div>
          <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
            *ngIf="perItemEditable"> 
            <input
              class="activity-description-bolder activity-booking-summary__editable-input-currency"
              [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> 
              <input ngxNumberFormat="#,###,###.##" [allowNegative]="true"  (blur)="onBlur($event)"
              class="activity-description-bolder activity-booking-summary__editable-input" type='text' min="1"
              step="0.10" [(ngModel)]="variant.bookingTypeValue" (keyup)="onNegotiate()" value="{{variant.Price / variant.bookingTypes}}"
               [ngClass]="{'pointer-active': perItemEditable}"> </div>
          <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
            <div class="activity-description-bolder">{{OperationCurrency}}  {{(variant.bookingTypeValue * variant.bookingTypes  
              ) |
                currencyDecimalFormatter}}</div>
          </div>
        </div>
      </ng-container>

      <!-- Addon Attempt-->
      <div class="activity-booking-summary__rate-row"
        *ngIf="slot.AddonAttemptDetails && slot.AddonAttemptDetails.length">
        <div class="activity-booking-summary__rate-label">
          <span class="activity-booking-summary__rate-label sub-label">{{ 'AddonTypes' | translate }}</span>
        </div>
      </div>
      <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row ml-2 "
        *ngFor="let addOn of slot.AddonAttemptDetails">
        <div class="activity-booking-summary__rate-label d-flex  p-0 col-lg-3 col-3">
          <div class="activity-description-bolder">{{addOn.AddonId | getAddonName : partyService?.cs?.settings?.value}}</div>
        </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
          <div class="activity-description-bolder"> {{addOn.Quantity}}</div>
        </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end text-align-right">
          <span
            class="activity-booking-summary__rate-label--main ">{{OperationCurrency}} {{addOn.prevSalePrice | currencyDecimalFormatter}}</span>
        </div> 
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end text-align-right">
          <span
            class="activity-booking-summary__rate-label--main ">{{OperationCurrency}} {{addOn.initialValue }}</span>
        </div>    
        <div *ngIf="!perItemEditable" class="activity-booking-summary__rate">{{OperationCurrency}}
          {{(addOn.perItemValue) }}</div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
          *ngIf="perItemEditable"> 
          <input
            class="activity-description-bolder activity-booking-summary__editable-input-currency"
            [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled>

             <input ngxNumberFormat="#,###,###.##" [allowNegative]="true"
            class="activity-description-bolder activity-booking-summary__editable-input" type='text' min="1"
            step="0.10" [(ngModel)]="addOn.perItemValue"  (keyup)="onNegotiate()" 
             [ngClass]="{'pointer-active': perItemEditable}"  (blur)="onBlur($event)"
            value="{{((addOn.TotalAmount) / addOn.Quantity) |  currencyDecimalFormatter : '0.00'}}"> </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
          <div class="activity-description-bolder">{{OperationCurrency}} {{(addOn.perItemValue * addOn.Quantity) |
            currencyDecimalFormatter : '0.00'}}</div>
        </div>
      </div>


      <!-- Session Charges -->
      <!-- <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row"
        *ngIf="slot.TotalAmount">
        <div class="activity-booking-summary__rate-label d-flex  p-0 col-lg-6 col-4">
          <div class="activity-description-bolder">{{'TotalSessionCharge' | translate}}</div>
        </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-1 col-2 d-flex justify-content-center">
          <div class="activity-description-bolder"> </div>
        </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-3 col-3 d-flex justify-content-end"> </div>
        <div *ngIf="!sessionEditable" class="activity-booking-summary__rate">{{OperationCurrency}} {{
          (slot.totalSessionCharge || 0) | currencyDecimalFormatter}}</div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-3 d-flex justify-content-end"
          *ngIf="sessionEditable"> <input
            class="activity-description-bolder activity-booking-summary__editable-input-currency"
            [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> <input
            matInput [(ngModel)]="slot.totalSessionCharge" (keydown)="test($event,slot)"
            class="activity-booking-summary__rate activity-booking-summary__editable-input" type='text' min="1"
            step="0.10" (keyup)="calculateValue(slot)" (keypress)=validateCharacters($event)
            [ngClass]="{'pointer-active': sessionEditable}" ngxNumberFormat="#,###,###.##" [allowNegative]="true"
            [value]="(slot.totalBaseRate || 0)"> </div>
      </div> -->
      <!--Addon Charges-->
      <!-- <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row"
        *ngIf="slot.totaladdonCharges">
        <div class="activity-booking-summary__rate-label d-flex  p-0 col-lg-6 col-4">
          <div class="activity-description-bolder">{{'addOnCharges' | translate}}</div>
        </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-1 col-2 d-flex justify-content-center">
          <div class="activity-description-bolder"> </div>
        </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-3 col-3 d-flex justify-content-end"> </div>
        <div *ngIf="!sessionEditable" class="activity-booking-summary__rate">{{OperationCurrency}} {{
          (slot.totaladdonCharges || 0) | currencyDecimalFormatter}}</div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-3 d-flex justify-content-end"
          *ngIf="sessionEditable"> <input
            class="activity-description-bolder activity-booking-summary__editable-input-currency"
            [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> <input
            class="activity-booking-summary__rate activity-booking-summary__editable-input" type='text' min="1"
            step="0.10" [ngClass]="{'pointer-active': sessionEditable}" [(ngModel)]="slot.totalAddOnAmount"
            [value]="(slot.totalAddOnAmount || 0)" ngxNumberFormat="#,###,###.##" [allowNegative]="true"> </div>
      </div> -->


                                        <!-- MemberShip Variants-->

                                        <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row"
                                        *ngIf="slot && slot.MemberShipVariant">
                                        <div class="activity-booking-summary__rate-label p-0 col-lg-4 col-4 d-flex "> <span
                                            class="activity-booking-summary__rate-label--main">{{'MemberShipVariant' | translate}}</span> 
                                          </div>
                                        <!-- <div class="activity-booking-summary__rate-label p-0 col-lg-1 col-1 d-flex justify-content-center">
                                          <span
                                            class="activity-booking-summary__rate-label--main">{{this.partyService.sessionBookingData.BookingSize}}</span>
                                        </div> -->
                                        <!-- <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-center">
                                          <span
                                            class="activity-booking-summary__rate-label--main"></span>
                                        </div> -->
                                        <div  class="activity-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">{{OperationCurrency}} {{
                                          slot.prevMembershipSalePrice |
                                          currencyDecimalFormatter}}</div>
                                        <div  class="activity-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right"></div>
                                        <div *ngIf="!perItemEditable" class="activity-booking-summary__rate">{{OperationCurrency}} {{
                                          slot.MemberShipVariant |
                                          currencyDecimalFormatter}}</div>
                                        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
                                          *ngIf="perItemEditable">
                                           <input
                                            class="activity-description-bolder activity-booking-summary__editable-input-currency"
                                            [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> 
                                            
                                            <input ngxNumberFormat="#,###,###.##" [allowNegative]="true"  (blur)="onBlur($event)"
                                            class="activity-description-bolder activity-booking-summary__editable-input" type='text' min="1"
                                            step="0.10" [(ngModel)]="slot.membershipAmount" (keyup)="onNegotiate()"
                                             [ngClass]="{'pointer-active': perItemEditable}"
                                            value=" slot.membershipAmount | currencyDecimalFormatter" > </div>
                                        <div class="activity-booking-summary__rate p-0 col-lg-2 col-2 d-flex justify-content-end">
                                          {{OperationCurrency}} {{(slot.membershipAmount) |
                                          currencyDecimalFormatter}}</div>
                                      </div>
      <!-- Total Session Charges(Strategy Rates + Addons) -->
      <div class="activity-booking-summary__rate-row py-1 price-table-header d-flex flex-row"
              *ngIf="slot.TotalAmount">
        <div class="activity-booking-summary__rate-label d-flex  p-0 col-lg-6 col-6">
          <div class="activity-description-bolder">{{'TotalSessionCharges' | translate}}</div>
        </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end text-align-right">
          <span
            class="activity-booking-summary__rate-label--main ">{{OperationCurrency}} {{
              (slot.totalSessionRetailCharge || 0) | currencyDecimalFormatter}}</span>
        </div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-center">
          <div class="activity-description-bolder">  </div>
        </div>    
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end">
          <div class="activity-description-bolder">{{OperationCurrency}} {{
            (slot.negotiatedTotalSessionCharge || 0) | currencyDecimalFormatter}} </div>
        </div>
        <div *ngIf="!sessionEditable" class="activity-booking-summary__rate"></div>
        <div class="activity-booking-summary__rate-label p-0 col-lg-2 col-3 d-flex justify-content-end"
          *ngIf="sessionEditable"> <input
            class="activity-description-bolder activity-booking-summary__editable-input-currency"
            [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> <input
            matInput [(ngModel)]="slot.totalSessionCharge" (keydown)="test($event,slot)"
            class="activity-booking-summary__rate activity-booking-summary__editable-input" type='text' min="1"
            step="0.10" (keyup)="calculateValue(slot)" (keypress)=validateCharacters($event)
            [ngClass]="{'pointer-active': sessionEditable}" ngxNumberFormat="#,###,###.##" [allowNegative]="true"
            [value]="(slot.totalBaseRate || 0)"> </div>
      </div>

       <!-- Deposit Amount-->
      <div class="activity-booking-summary__rate-row" *ngIf="slot.DepositAmount">
        <div class="activity-booking-summary__rate-label"> <span
            class="activity-booking-summary__rate-label--main">{{'depositValue' | translate}}</span> </div>
        <div class="activity-booking-summary__rate">{{OperationCurrency}} {{slot.DepositAmount |
          currencyDecimalFormatter}}</div>
      </div>
    </mat-expansion-panel>
  </mat-accordion> <!-- Total Value -->
  <div class="min-rate-adj" *ngIf="ratePlan.MinRateAdjustment.MinRateAdjustmentAmount">
    <div class="min-rate-label">
      {{'MinRateAdjustment' | translate}}
    </div>
    <div class="min-rate-adj-val">
      <input ngxNumberFormat="#,###,###.##" [allowNegative]="true" class="activity-booking-summary__editable-input pointer-active" type='text' min="1" step="0.01"
      [(ngModel)]="ratePlan.MinRateAdjustment.Amount" (change)="calculatePaymentDetails()"/>
      <!-- <input type="text" [(ngModel)]="adjustedMinRate" 
      value="{{(totalPayableAmount || totalAddOnAmount ? ((totalPayableAmount || 0) + (totalAddOnAmount || 0)  | currencyDecimalFormatter) :  '0.00') }}"> -->
    </div>
  </div>
  <div class="row negotiation-label">
    <div class="col-lg-6 col-md-6"> <span class="activity-booking-summary__rate-label">{{'totalValue' |
        translate}}</span> </div>
    <div class="col-lg-6 col-md-5  text-right"> {{OperationCurrency}} {{ activitiesAmount 
       | currencyDecimalFormatter : '0.00' }}</div>
  </div>
  <!--Negotiation Value -->
  <div class="row negotiation-label">
    <div class="col-lg-6 col-md-6"> <span class="activity-booking-summary__rate-label">{{'negotiateValue' |
        translate}}</span> </div>
    <div class="col-lg-6 col-md-5  text-right">{{OperationCurrency}} {{negotiatedDiscount | currencyDecimalFormatter: '0.00' }}
    </div>
  </div>
  <!--Total Deposit Value -->
  <div class="row negotiation-label" *ngIf="totalDepositAmount && showDepositAmount">
    <div class="col-lg-6 col-md-6"> <span class="activity-booking-summary__rate-label">{{'totalDepositValue' |
        translate}}</span> </div>
    <div class="col-lg-6 col-md-5  text-right">{{OperationCurrency}} {{totalDepositAmount |
      currencyDecimalFormatter}}
    </div>
  </div> <!-- Grand Total-->
  <div *ngIf="!isTaxConfigured && !isRetailEnabledProperty">
    <div class="row negotiation-label">
      <div class="col-lg-6 col-md-6"> <span class="activity-booking-summary__rate-label"> {{'findTotal' |
          translate}}</span> </div>
      <div class="col-lg-6 col-md-5  text-right"> <span class="icon-information"
          *ngIf="negotiationReason && negotiationReason !== ''" matTooltipClass="tooltip-class"
          title="{{negotiationReason}}"></span> {{OperationCurrency}} {{(totalAmountForBooking || 0) +
        (ratePlan.OverAllNegotiation || 0) +(slot?.totalAddOnAmount ? slot.totalAddOnAmount : 0) |
        currencyDecimalFormatter : '0.00' }}
        <span title="{{'negotiateAmountMessage' | translate}}"
          class="icon-ic_create_24px edit-rates disabled-icon" *ngIf="data?.disablePrevious"></span> <span
          class="icon-ic_create_24px edit-rates"
          *ngIf="partyService.isNegotiationEnabled && !data?.disablePrevious"
          (click)="editNegotiationAmount()"></span>
      </div>
    </div>
  </div>
  <div *ngIf="isTaxConfigured || isRetailEnabledProperty || isServiceConfigured">
    <div class="row negotiation-label">
      <div class="col-lg-6 col-md-6"> <span class="activity-booking-summary__rate-label"> {{'subTotal' |
          translate}}</span> </div>
      <div class="col-lg-6 col-md-5  text-right"> <span class="icon-information"
          *ngIf="negotiationReason && negotiationReason !== ''" matTooltipClass="tooltip-class"
          title="{{negotiationReason}}"></span> <span
          *ngIf="!subTotalEditable">{{OperationCurrency}} {{ subtotal  |
          negtiationRateFormatter : '0.00' }}</span>
           <input
          class="activity-description-bolder activity-booking-summary__editable-input-currency"
          *ngIf="subTotalEditable" [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}"
          disabled> 
          <input class="activity-booking-summary__editable-input"
          [ngClass]="{'pointer-active': subTotalEditable}" type='number' min="1" step="0.10"
          *ngIf="subTotalEditable" [(ngModel)]="subTotal" 
          value="{{(totalPayableAmount || totalAddOnAmount ? ((totalPayableAmount || 0) + (totalAddOnAmount || 0)  | currencyDecimalFormatter) :  '0.00') }}">
        <span title="{{'negotiateAmountMessage' | translate}}"
          class="icon-ic_create_24px edit-rates disabled-icon" *ngIf="data?.disablePrevious"></span>
        <!-- <span class="icon-ic_create_24px edit-rates"                *ngIf="partyService.isNegotiationEnabled && !data?.disablePrevious" // take this for update                (click)="editNegotiationAmount()"></span> -->
      </div>
    </div>
    <div class="row negotiation-label" *ngIf="additionalCharges > 0 ">
      <div class="col-lg-6 col-md-6">
        <span class="open-booking-summary__rate-label">{{'additionalCharge' | translate}}</span>
      </div>
      <div class="col-lg-6 col-md-5  text-right">{{OperationCurrency}} {{additionalCharges | currencyDecimalFormatter: '0.00' }}</div>
    </div>
    <div class="row negotiation-label" *ngIf="isServiceConfigured">
      <div class="col-lg-8 col-md-8 d-flex"> <span class="">{{'serviceChargesWithPlural' | translate}}</span>
        <dynamic-form [config]="recalculateConfig" #form="dynamicForm"> </dynamic-form>
      </div>
      <div class="col-lg-4 col-md-4  text-right">{{OperationCurrency}}
        {{ serviceCharge | negtiationRateFormatter }}
      </div>
    </div>
    <div class="row negotiation-label" *ngIf="isServiceConfigured">
      <div class="col-lg-6 col-md-6">
        <span class="activity-booking-summary__rate-label">{{'serviceChargesTaxWithPlural' | translate}}</span>
      </div>
      <div class="col-lg-6 col-md-5  text-right">
        {{OperationCurrency}}
        {{(taxOnServiceCharge || 0) | negtiationRateFormatter  }}
      </div>
    </div>
    <div class="row negotiation-label">
      <div class="col-lg-6 col-md-6"> <span class="activity-booking-summary__rate-label">{{'tax' |
          translate}}</span> </div>
      <div class="col-lg-6 col-md-5  text-right">{{OperationCurrency}} {{ tax | currencyDecimalFormatter }}
      </div>
    </div>
    <div class="row negotiation-label">
      <div class="col-lg-6 col-md-6"> <span class="activity-booking-summary__rate-label">{{'findTotal' |
          translate}}</span> </div>
      <div class="col-lg-6 col-md-5  text-right"> {{OperationCurrency}}{{ grandTotal | negtiationRateFormatter :
        '0.00' }}
      </div>
    </div>
  </div> <!-- </form> -->
 
    <div class="sub-label p-2">{{'negotiation' | translate}}</div>
  <dynamic-form [config]="rateNegotiateConfig" #negotiateReasonForm="dynamicForm">
  </dynamic-form>
  <div class="activity-booking-summary__button-section pb-2">
    <activities-app-button  class="mr-3" [buttontype]="applyNegotiationBtn" (valueChange)='applyNegotiation()'></activities-app-button>
    <activities-app-button [buttontype]="cancelNegotiationBtn" (valueChange)='cancelNegotiation()'></activities-app-button>
  </div>

</div>