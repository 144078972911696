import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomQueryAPI , AdhocReportAPI } from 'src/app/common/shared/shared/event-scheduler/event-scheduler.model';
import { CommonApiRoutes } from '../common-route';
import { CommonGatewayCommunication } from '../communication/services/commongateway-communication.service';
import { API  } from 'src/app/common/generic-extract/generic-extract-model';
import { API as SFTPApi } from 'src/app/common/Models/sftp.model';

@Injectable({
    providedIn: 'root'
})
export class QueryBuilderDataService {

    private endPoint: string;

    constructor(private commonGatewayCommunication: CommonGatewayCommunication) {
        this.endPoint = environment["CommonGateway"];
    }

    public async getAllCustomQueryGroups(): Promise<CustomQueryAPI.CustomQueryGroup[]> {
        let result = this.commonGatewayCommunication.getPromise<CustomQueryAPI.CustomQueryGroup[]>(
            { route: CommonApiRoutes.GetCustomQueryGroups }, false);
        return result;
    }

    public async getAllAdhocViewReports(): Promise<AdhocReportAPI.AdhocViewReportPathDeatils[]> {
        let result = this.commonGatewayCommunication.getPromise<AdhocReportAPI.AdhocViewReportPathDeatils[]>(
            { route: CommonApiRoutes.GetAlladhocReportsPath }, false);
        return result;
    }

    public getEasyQueryUrl(): string {
        return this.endPoint.concat("/", CommonApiRoutes.EQEndpoint);
    }


    public DownloadExtract(body:any, modelId : string): Promise<any>{
        let result = this.commonGatewayCommunication.postEasyQueryPromise<any>(
           {route: CommonApiRoutes.FetchEasyQuery, uriParams: { queryModel : modelId } ,body}, false);
        return result;
      }

    public async UpdateExtractedData(body:API.CustomQueryExtractData): Promise<string>{
        let result = await this.commonGatewayCommunication.putPromise<string>(
           {route: CommonApiRoutes.UpdateExtractedData, body}, false);
        return result;
      }


    public getSFTPConfiguration(): Promise<SFTPApi.SFTPConnection[]> {
        let result = this.commonGatewayCommunication.getPromise<SFTPApi.SFTPConnection[]>(
            { route: CommonApiRoutes.GetSFTPConfigLink}, true);
        return result;
      }

      public getSFTPConfigurationById(d : number): Promise<SFTPApi.SFTPConnection> {
        let result = this.commonGatewayCommunication.getPromise<SFTPApi.SFTPConnection>(
            { route: CommonApiRoutes.GetSFTPConfigLinkById}, true);
        return result;
      }

}
