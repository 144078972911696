import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from '../../business/report-business.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailFeatureFlagInformationService } from "src/app/retail/shared/service/retail.feature.flag.information.service";
export class InventorySummary extends BaseReport {
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, private func: RetailFunctionalityBusiness,
        public propertyInfo: RetailPropertyInformation, public retailLocalization : RetailLocalization,private featureFlag: RetailFeatureFlagInformationService) {
        super(reportFormGrp);
    }
    functionalities: { [key: string]: boolean} = {};

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.InventorySummary,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: false,
            endDatePicker: false,
            inActiveToggleName: this.captions.IncludeInactiveUsers,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: "PORTRAIT"
        }; 
    }

    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let inventoryDetailCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let radioFilterId: number = this.reportFormGrp.controls['customFilterFormGrp'].value.itemFilter;

        let categoryFilter: string[] = this.business.getSelectedCategoryCaptions(inventoryDetailCustomFilterData);
        return [{ "isSuppressZeroQuantity": radioFilterId === 1 ? "true" : "false" },
        { "isLastCostMethod": inventoryDetailCustomFilterData.lostCostMethod },
        { "pPageBreak": this.pageBreakToggle },
        { "pSuppressZeroQty": radioFilterId === 1 ? "true" : "false" },
        { "pCategoryOnly": inventoryDetailCustomFilterData.categoryView},
        { "pOutletFilter": this.functionalities.ShowOutletSelectionFieldInInventorySummaryReport ? this.getFilterCaption : this.getDefaultOutletCaption},
        { "pLastCostMethod": inventoryDetailCustomFilterData.lostCostMethod },
        { "pDisposableItems": inventoryDetailCustomFilterData.DisposableItems },
        { "pSubCategory1": categoryFilter[1] },
        { "pSubCategory2": categoryFilter[2] },
        { "pSubCategory3": categoryFilter[3] },
        { "pSubCategory4": categoryFilter[4] },
        { "pSubCategory5": categoryFilter[5] },
        { "pCategoryFilter": categoryFilter[0] },
        { "pPropertyName": this.propertyName },
        { "pDate" : this.printedDate },
        {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInInventorySummaryReport ? true : false },
        {"pIsEatecEnabled":this.featureFlag.IsEatecEnabled==true ? "true" : "false"} ,
        { "pPropertyDate": this.localization.convertDateObjToAPIdate(this.propertyInfo.CurrentDTTM)}

        ];
    }

    protected formURIParams(): ReportParams[] {
        return [];
    }

    protected formFilters() {

        let inventoryDetailCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        const tempOutFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        let radioFilterId: number = inventoryDetailCustomFilterData.itemFilter;
        if(this.retailLocalization.isFromJobScheduler){
            return {
                categoryIds: this.fillFilter(inventoryDetailCustomFilterData.category[0]),
                subCategory1Ids: this.fillFilter(inventoryDetailCustomFilterData.category[1]),
                subCategory2Ids: this.fillFilter(inventoryDetailCustomFilterData.category[2]),
                subCategory3Ids: this.fillFilter(inventoryDetailCustomFilterData.category[3]),
                subCategory4Ids: this.fillFilter(inventoryDetailCustomFilterData.category[4]),
                subCategory5Ids: this.fillFilter(inventoryDetailCustomFilterData.category[5]),
                OutletIds: this.functionalities.ShowOutletSelectionFieldInInventorySummaryReport ? this.selectedFilterOptions.map(x => x.id) :
                    tempOutFalse,
                useInventory: true,
                itemFilter: radioFilterId === 1 ? "true" : "false",
                lostCostMethod: inventoryDetailCustomFilterData.lostCostMethod,
                pageBreak: this.pageBreakToggle,
                categoryView: inventoryDetailCustomFilterData.categoryView,
                DisposableItems: inventoryDetailCustomFilterData.DisposableItems
            };
        }
        return {
            categoryIds: this.fillFilter(inventoryDetailCustomFilterData.category[0]),
            subCategory1Ids: this.fillFilter(inventoryDetailCustomFilterData.category[1]),
            subCategory2Ids: this.fillFilter(inventoryDetailCustomFilterData.category[2]),
            subCategory3Ids: this.fillFilter(inventoryDetailCustomFilterData.category[3]),
            subCategory4Ids: this.fillFilter(inventoryDetailCustomFilterData.category[4]),
            subCategory5Ids: this.fillFilter(inventoryDetailCustomFilterData.category[5]),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInInventorySummaryReport ? this.selectedFilterOptions.map(x => x.id) :
                tempOutFalse,
            useInventory: true
        };
    }

    fillFilter(customFilterData: any): number[] {
        let ids: number[] = customFilterData.selectedCat.length === 0 ? [] : customFilterData.selectedCat.map(x => x.id);
        if(customFilterData.selectedCat.length === customFilterData.catArr.length && customFilterData.catArr.length > 0){
            ids =[];
        }
        return ids;
    }
}


