import { Component, ViewEncapsulation } from "@angular/core";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { MatDialog } from "@angular/material/dialog";
import { GiftCardBusiness, SearchScreen } from 'src/app/retail/sytem-config/gift-cards/gift-cards.business';
import { IssueGiftCardPopupModel, GiftCardTypeNames } from 'src/app/retail/shared/service/payment/payment-model';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { Router } from '@angular/router';
import { GiftCardInfo, HandleResponse } from 'src/app/retail/shared/business/shared.modals';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import * as _ from 'lodash';
import { RetailService } from 'src/app/retail/retail.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';

@Component({
  selector: "app-retail-gift-cards",
  templateUrl: "./retail-gift-cards.component.html",
  styleUrls: ["./retail-gift-cards.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class RetailGiftCardsComponent  {
  selectedDevice = "";
  captions: any;
  searchedGiftCardDetails: GiftCardInfo;
  recentSearchResults: {
    id: number;
    CardNumber: number;
    AvailableAmount: number;
  }[];
  SearchScreen = SearchScreen;

  constructor(
    private localization: RetailLocalization
    , public dialog: MatDialog
    , private giftCardBusiness: GiftCardBusiness
    , private shopService: CommonVariablesService
    , private router: Router
    , private _utils: RetailUtilities
    , private _ams: RetailService
    , private _propertyInformation: RetailPropertyInformation
  ) {
    this.captions = this.localization.captions.shop.GiftCard;
  }

 
  CreateGiftCard() {
      let giftCardData: IssueGiftCardPopupModel = { Amount: 0, openItem: true, HandleInfo: "", isFromBalanceScreen: true };
    this.giftCardBusiness.ShowIssueGiftCardModal(giftCardData, this.ProceedToOrderSummary.bind(this));
  }

  ProceedToOrderSummary(popupData) {
    let giftCardItem = this.giftCardBusiness.FormProductObj(popupData);
    if (giftCardItem) {
      this.shopService.selectedProducts = [giftCardItem];
      this.shopService.isFromGiftCard = true;
      this.router.navigate(['/shop/viewshop/order']);
    }
  }

  HandleGiftCardData(giftCardData: HandleResponse) {
    if(giftCardData && giftCardData.status.toLowerCase() == 'success'){
      let cardNumber = giftCardData.paymentHandle[0].inquiryInfo.id;  
      this.getRedemptionHistory(cardNumber, giftCardData);
    }
  }

  async getRedemptionHistory(giftCardNumber: string, giftCardData: HandleResponse){
    let result = await this.giftCardBusiness.getGiftCardPaymentRecords(giftCardNumber);
    if(giftCardData.isThirdParty){
      result = result.filter(x => [GiftCardTypeNames.Shift4, GiftCardTypeNames.Vantiv, GiftCardTypeNames.Givex, GiftCardTypeNames.Agilysys].includes(x.type));
    }else{
      result = result.filter(x => x.type == GiftCardTypeNames.V1GiftCard);
    }
    result = _.orderBy(result, 'paymentTransactionId', 'desc');
    this.searchedGiftCardDetails = {
      CardNumber: giftCardNumber.replace(/.(?=.{4})/g, '*') ,
      FirstName: result && result[0] && result[0].firstName ? result[0].firstName : '',
      LastName: result && result[0] && result[0].lastName ? result[0].lastName : '',
      Email: giftCardData.email,
      PhoneNumber: giftCardData.phoneNumber,
      CardType: result && result[0] && result[0].type ? result[0].type : '',
      ExpiryDate:  result && result[0] && result[0].expiryDate ? this.localization.LocalizeDate(result[0].expiryDate): this.captions.NA,
      AvailableAmount: giftCardData.paymentHandle[0].balance,
      HandleInfo: giftCardData,
      redemptionHistory: await this.mapRedemptionHistoryToUI(result)
    };
  }

  async mapRedemptionHistoryToUI(result: any){
    result = result.filter(x => x.isRedeemed && x.propertyId == this._propertyInformation.PropertyId && !x.isVoided);
    if(result && result.length > 0){  
      let paymentIds = result.map(x => x.paymentTransactionId);
      let ticketDetail = await this.giftCardBusiness.getPaymentTicketDetail(paymentIds)
      let availablePaymentReferences = ticketDetail.map(x => x.paymentTransactionId);
      result = result.filter(x => availablePaymentReferences.includes(x.paymentTransactionId));
      return result.map(x => { return {
        sno:0,
        ticketno: ticketDetail && ticketDetail.length > 0 ? ticketDetail.filter(y => y.paymentTransactionId == x.paymentTransactionId)[0].ticketNumber : '',
        date: this.localization.LocalizeDate(x.redemptionDate),
        amount: this.localization.localizeCurrency(x.amount)
      }});
    }else{
      return [];
    }
  }
}