import { AutoServerArea, AutoServerAreaDTO, ColorDTO, PointDTO } from '@models/RestaurantDTO';

export abstract class AutoServerAreasDTOMapper {
  public static map(autoServerArea: AutoServerArea) {
    const autoServerAreaDTO = new AutoServerAreaDTO();
    this.mapToDTO(autoServerArea, autoServerAreaDTO);
    return autoServerAreaDTO;
  }

  private static mapToDTO(from: AutoServerArea, to: AutoServerAreaDTO) {
    to.Id = from.Id;
    to.ServerId = from.ServerId;
    to.FloorPlanViewId = from.FloorPlanViewId;
    to.LabelWidth = from.LabelWidth;
    //to.RestaurantId = from.RestaurantId;
    to.Color = new ColorDTO();
    to.Color.A = from.ColorAInternal;
    to.Color.R = from.ColorRInternal;
    to.Color.G = from.ColorGInternal;
    to.Color.B = from.ColorBInternal;
    to.LabelOrigin = new PointDTO();
    to.LabelOrigin.X = from.LabelXInternal;
    to.LabelOrigin.Y = from.LabelYInternal;
    var obj: PointDTO[] = JSON.parse(from.PointsInternal);
    to.Points = obj;
  }
}
