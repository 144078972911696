<ng-scrollbar>
  <div class="activity-booking-summary">
    <div class="activity-booking-summary__booking-detail">
      <div class="activity-booking-summary__summary-accordian" *ngIf="partyService.sessionBookingData">
        <mat-accordion class="p-10">
          <mat-expansion-panel [expanded]="true" class="activity-booking-summary__summary-accordian--panel">
            <mat-expansion-panel-header>
              <mat-panel-title class="activity-booking-summary__section-title activity-booking-summary__section-title--accordian">
                {{'bookingSummary' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="activity-booking-summary__content">
              <div class="activity-booking-summary__item activity-booking-summary__item--name">
                <div class="activity-booking-summary__item__label">{{'activityName' | translate}}</div>
                <div [title]="activityName" class="activity-booking-summary__item__value">
                  {{activityName}}
                </div>
              </div>
              <div class="activity-booking-summary__item activity-booking-summary__item--location">
                <div class="activity-booking-summary__item__label">{{'location' | translate}}</div>
                <div class="activity-booking-summary__item__value">
                  {{
partyService.sessionBookingData.LocationName &&
                                    partyService.sessionBookingData.LocationName.length
                                    ? partyService.sessionBookingData.LocationName[0]: 'Any'
                  }}
                </div>
              </div>
              <div class="activity-booking-summary__item activity-booking-summary__item--date">
                <div class="activity-booking-summary__item__label">{{'date' | translate}}</div>
                <div *ngIf="isSessionClass">
                  <div class="activity-booking-summary__item__value"
                       *ngIf="partyService.sessionBookingData.Slots.length == 1 ">
                    {{partyService.sessionBookingData.Slots[0].Date | formatSettingsDate: cs.settings.value.General.DateFormat}}
                  </div>
                  <div class="activity-booking-summary__item__datefieldvalue"
                       *ngIf="partyService.sessionBookingData.Slots.length > 1 ">
                    {{partyService.sessionBookingData.Slots[0].Date | formatSettingsDate: cs.settings.value.General.DateFormat}} {{
'toText'
                                        | translate
                    }}
                    {{
partyService.sessionBookingData.Slots[partyService.sessionBookingData.Slots.length
                                        - 1].Date |
                                        date: 'dd/MMM/yy'
                    }}
                  </div>
                </div>
                <div *ngIf="!isSessionClass">
                  <div class="activity-booking-summary__item__datefieldvalue">
                    {{partyService.sessionBookingData.FromDate | formatSettingsDate: cs.settings.value.General.DateFormat}} {{
'toText' |
                                        translate
                    }}
                    {{partyService.sessionBookingData.ToDate | formatSettingsDate: cs.settings.value.General.DateFormat}}
                  </div>
                </div>
              </div>
              <div class="activity-booking-summary__item  activity-booking-summary__item--booking-size">
                <div class="activity-booking-summary__item__label">{{'bookingSize' | translate}}</div>
                <div class="activity-booking-summary__item__value">
                  {{partyService.sessionBookingData?.BookingSize}}
                </div>
              </div>
              <div class="activity-booking-summary__item  activity-booking-summary__item--slots"
                   *ngIf="ratePlan">
                <div class="activity-booking-summary__item__label">{{'timeSlots' | translate}}</div>
                <div class="activity-booking-summary__item__value"
                     *ngIf="ratePlan.RatePlanCalculations">

                  <span *ngFor="let slot of ratePlan.SlotList | slice:0:(showAllSlots ? ratePlan.SlotList.length : 4 ); let index = index;">
                    <span *ngIf="isSessionClass">
                      {{slot.TimeRange.Start | localizedDate: 'DD MMMM, LT'}}
                      -
                      {{
slot.TimeRange.End | localizedDate: 'LT'
                      }}{{partyService.sessionBookingData.Slots[index+1] && ','}}
                      &nbsp;
                    </span>
                  </span>
                  <span *ngFor="let slot of ratePlan.SlotList  | slice:0:(showAllSlots ? ratePlan?.SlotList?.length : 4 ); let index = index;">
                    <span *ngIf="!isSessionClass">
                      <span class="batch-name"
                            *ngIf="partyService.sessionBookingData.SessionGroupName">
                        {{partyService.sessionBookingData.SessionGroupName}},
                      </span>
                      {{slot.TimeRange.Start | localizedDate: 'DD MMMM'}}
                      - {{slot.TimeRange.End | localizedDate: 'DD MMMM'}} &nbsp;
                    </span>
                  </span>
                  <div class="activity-booking-summary__slots-show-action"
                       *ngIf="ratePlan.SlotList.length > 4" (click)="showAllSlots = !showAllSlots">
                    {{showAllSlots ? 'Show Less' : '+'+(ratePlan.SlotList.length-4)+' '+'more'}}
                  </div>

                </div>
                <div class="activity-booking-summary__item__value"
                     *ngIf="ratePlan && !ratePlan.RatePlanCalculations">

                  <span *ngFor="let slot of ratePlan.SlotList; let index = index;">
                    <span *ngIf="isSessionClass">
                      {{slot.LocalTime | localizedDate: 'DD MMMM, LT'}}
                      -
                      {{
slot.EndTime | localizedDate: 'LT'
                      }}{{partyService.sessionBookingData.Slots[index+1] && ','}}
                    </span>
                    <span *ngIf="!isSessionClass">
                      <span class="batch-name"
                            *ngIf="partyService.sessionBookingData.SessionGroupName">
                        {{partyService.sessionBookingData.SessionGroupName}},
                      </span>
                      {{slot.LocalTime | localizedDate: 'DD MMMM'}}
                      - {{slot.EndTime | localizedDate: 'DD MMMM'}}}}
                    </span>
                    &nbsp;
                  </span>
                  <span>
                    {{slot.TimeRange.Start | localizedDate: 'DD MMMM' }} - {{
slot.TimeRange.End | localizedDate:'DD
                                        MMMM'
                    }}
                  </span>
                </div>
              </div>
              <div class="activity-booking-summary__item  activity-booking-summary__item--slots"
                   *ngIf="!ratePlan">
                <div class="activity-booking-summary__item__label">{{'timeSlots' | translate}}</div>
                <div>
                  <div class="activity-booking-summary__item__value">
                    <span *ngIf="!isSessionClass">
                      <span class="batch-name"
                            *ngIf="partyService.sessionBookingData.SessionGroupName">
                        {{partyService.sessionBookingData.SessionGroupName}},
                      </span>
                      {{partyService.sessionBookingData.FromDate | localizedDate: 'DD MMMM'}} -
                      {{partyService.sessionBookingData.ToDate | localizedDate: 'DD MMMM'}}
                    </span>
                    <span *ngFor="let slot of partyService.sessionBookingData.Slots | slice:0:(showAllSlots ? partyService.sessionBookingData.Slots.length : 4 ); let index = index;">
                      <span *ngIf="isSessionClass">
                        {{slot.sessions[0].Start | localizedDate: 'DD MMMM, LT'}}
                        -
                        {{
slot.sessions[0].End | localizedDate: 'LT'
                        }}{{
partyService.sessionBookingData.Slots[index+1] &&
                                                ','
                        }} &nbsp;
                      </span>
                    </span>
                    <div class="activity-booking-summary__slots-show-action"
                         *ngIf="partyService.sessionBookingData.Slots.length > 4"
                         (click)="showAllSlots = !showAllSlots">
                      {{
showAllSlots ? 'Show Less' :
                                            '+'+(partyService.sessionBookingData.Slots.length-4)+' '+'more'
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div class="activity-booking-summary__summary-accordian" *ngIf="partyService.selectedGuest">
        <mat-accordion class="p-10">
          <mat-expansion-panel [expanded]="true" class="activity-booking-summary__summary-accordian--panel">
            <mat-expansion-panel-header>
              <mat-panel-title class="activity-booking-summary__section-title activity-booking-summary__section-title--accordian">
                {{'GuestDetails' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="activity-booking-summary__content activity-booking-summary__column-gap">
              <div class="activity-booking-summary__item activity-booking-summary__item--location">
                <div class="activity-booking-summary__item__label">{{'FirstName' | translate}}</div>
                <div class="activity-booking-summary__item__value">
                  {{partyService.selectedGuest?.FirstName}}
                </div>
              </div>
              <div class="activity-booking-summary__item activity-booking-summary__item--location">
                <div class="activity-booking-summary__item__label">{{'LastName' | translate}}</div>
                <div class="activity-booking-summary__item__value">
                  {{partyService.selectedGuest?.LastName}}
                </div>
              </div>
              <div class="activity-booking-summary__item activity-booking-summary__item--name">
                <div class="activity-booking-summary__item__label">{{'Email' | translate}}</div>
                <div class="activity-booking-summary__item__value">
                  {{partyService.selectedGuest?.EmailAddress}}
                </div>
              </div>
              <div class="activity-booking-summary__item activity-booking-summary__item--name">
                <div class="activity-booking-summary__item__label">{{'Mobile' | translate}}</div>
                <div class="activity-booking-summary__item__value">
                  {{partyService.selectedGuest?.PhoneNumber}}
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="activity-booking-summary__rate-plan" *ngIf="ratePlan && ratePlan.RatePlanCalculations">
        <div class="activity-booking-summary__section-title">
          {{'Rates' | translate}}
        </div>
        <mat-accordion *ngFor="let slot of ratePlan.SlotList; let index= index;">
          <mat-expansion-panel [expanded]="index == 0"
                               class="activity-booking-summary__summary-accordian--panel activity-booking-summary__summary-accordian--rate-plan">
            <mat-expansion-panel-header>
              <mat-panel-title class="activity-booking-summary__section-title--accordian">
                <span *ngIf="isSessionClass">
                  {{slot.TimeRange.Start | localizedDate: 'DD MMMM, LT' }} - {{
slot.TimeRange.End |
                                    date:'hh:mm a'
                  }}
                </span>
                <span *ngIf="!isSessionClass">
                  {{slot.TimeRange.Start | localizedDate: 'DD MMMM' }} - {{slot.TimeRange.End | localizedDate: 'DD MMMM'}}
                </span>
                <span *ngIf="slot.sessionState">({{slot.sessionState | translate}})</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="activity-booking-summary__rate-row"
                 *ngIf="slot.RatePlanVariants && slot.RatePlanVariants.TotalBaseRate">
              <div class="activity-booking-summary__rate-label">
                <span class="activity-booking-summary__rate-label--main">
                  {{
'TotalBaseRate' |
                                    translate
                  }}
                </span>
              </div>
              <div class="activity-booking-summary__rate">
                {{OperationCurrency}}
                {{
slot.RatePlanVariants.TotalBaseRate |
                                currencyDecimalFormatter
                }}
              </div>
            </div>
            <div class="activity-booking-summary__rate-row"
                 *ngIf="slot.RatePlanVariants && slot.RatePlanVariants.LocationVariant">
              <div class="activity-booking-summary__rate-label">
                <span class="activity-booking-summary__rate-label">
                  {{'LocationVariant' | translate}}
                  <span class="sub-text">
                    ({{'forTotalOf' | translate}}
                    {{slot.size ? slot.size : partyService.sessionBookingData?.BookingSize}}
                    )
                  </span>
                </span>
              </div>
              <div class="activity-booking-summary__rate">
                {{OperationCurrency}}
                {{
slot.RatePlanVariants.LocationVariant
                                | currencyDecimalFormatter
                }}
              </div>
            </div>
            <div class="activity-booking-summary__rate-row"
                 *ngIf="slot.RatePlanVariants && slot.RatePlanVariants.InstructorVariant">
              <div class="activity-booking-summary__rate-label">
                <span class="activity-booking-summary__rate-label">
                  {{
'InstructorVariant' |
                                    translate
                  }}<span class="sub-text">
                    ({{'forTotalOf' | translate}}
                    {{slot.size ? slot.size : partyService.sessionBookingData?.BookingSize}}
                    )
                  </span>
                </span>
              </div>
              <div class="activity-booking-summary__rate">
                {{OperationCurrency}}
                {{slot.RatePlanVariants.InstructorVariant | currencyDecimalFormatter}}
              </div>
            </div>
            <div class="activity-booking-summary__rate-row"
                 *ngIf="slot.bookingTypeVariants && slot.bookingTypeVariants.length">
              <div class="activity-booking-summary__rate-label">
                <span class="activity-booking-summary__rate-label sub-label">
                  {{
'bookingType' |
                                    translate
                  }}
                </span>
              </div>
            </div>

            <div class="activity-booking-summary__rate-row"
                 *ngFor="let variant of slot.bookingTypeVariants">
              <div class="activity-booking-summary__rate-label" *ngIf="variant.Variant">
                <span class="activity-booking-summary__rate-label--main">{{variant.BookingTypeName}}</span>
                <span class="activity-booking-summary__rate-label--sub">{{variant.bookingTypes}}</span>
              </div>
              <div class="activity-booking-summary__rate">
                {{OperationCurrency}} {{
variant.Variant |
                                currencyDecimalFormatter
                }}
              </div>
            </div>
            <div class="activity-booking-summary__rate-row"
                 *ngIf="slot.coverTypeVariants && slot.coverTypeVariants.length">
              <div class="activity-booking-summary__rate-label">
                <span class="activity-booking-summary__rate-label sub-label">
                  {{'coverType' | translate}}
                </span>
              </div>
            </div>
            <div class="activity-booking-summary__rate-row" *ngFor="let variant of slot.coverTypeVariants">
              <div class="activity-booking-summary__rate-label" *ngIf="variant.Variant">
                <span class="activity-booking-summary__rate-label--main">{{variant.CoverTypeName}}</span>
                <span class="activity-booking-summary__rate-label--sub">{{variant.covers}}</span>
              </div>
              <div class="activity-booking-summary__rate">
                {{OperationCurrency}} {{
variant.Variant |
                                currencyDecimalFormatter
                }}
              </div>
            </div>

            <div class="activity-booking-summary__rate-row" *ngIf="slot && slot.MemberShipVariant">
              <div class="activity-booking-summary__rate-label">
                <span class="activity-booking-summary__rate-label--main">
                  {{
'MemberShipVariant' |
                                    translate
                  }}
                </span>
              </div>
              <div class="activity-booking-summary__rate">
                {{OperationCurrency}} {{
slot.MemberShipVariant |
                                currencyDecimalFormatter
                }}
              </div>
            </div>
            <div class="activity-booking-summary__rate-row" *ngIf="slot.TotalAmount">
              <div class="activity-booking-summary__rate-label">
                <span class="activity-booking-summary__rate-label--main">
                  {{
'TotalSessionCharge' |
                                    translate
                  }}
                </span>
              </div>
              <div class="activity-booking-summary__rate">
                {{OperationCurrency}} {{
(slot.TotalAmount || 0) + (slot.TotalAddOnAmount) |
                                currencyDecimalFormatter
                }}
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="activity-booking-summary__rate-row activity-booking-summary__total">
          <div class="activity-booking-summary__rate-label">
            <span class="activity-booking-summary__rate-label">{{'total' | translate}}</span>
          </div>
          <div class="activity-booking-summary__rate">
            {{OperationCurrency}} {{
(ratePlan.TotalRatePlan ?
                        ((ratePlan.TotalRatePlan || 0) + (ratePlan.TotalAddonAmount || 0) | currencyDecimalFormatter) : '0.00')
            }}
          </div>
        </div>
        <div class="activity-booking-summary__rate-row activity-booking-summary__total negotiation-label m-0 pt-0" *ngIf="isServiceConfigured">
          <div class="activity-booking-summary__rate-label">
            <span class="activity-booking-summary__rate-label">{{'serviceChargesWithPlural' | translate}}</span>
          </div>

          <div class="activity-booking-summary__rate">
            {{OperationCurrency}} {{
(ratePlan.TotalServiceChargeAmount ?
                        ((ratePlan.TotalServiceChargeAmount || 0) + (ratePlan.TotalAddonServiceChargeAmount || 0) | currencyDecimalFormatter) : '0.00')
            }}
          </div>
        </div>
        <div class="row negotiation-label" *ngIf="isServiceConfigured">
          <div class="col-lg-6 col-md-6">
            <span class="activity-booking-summary__rate-label">{{'serviceChargesTaxWithPlural' | translate}}</span>
          </div>
          <div class="col-lg-6 col-md-5  text-right">
            {{OperationCurrency}}
            {{(((ratePlan.TotalTaxOnServiceChargeAmount || 0) + (ratePlan.TotalAddonTaxOnServiceChargeAmount || 0)) | negtiationRateFormatter)  }}
          </div>
        </div>
        <div class="activity-booking-summary__rate-row activity-booking-summary__total negotiation-label m-0 pt-0" *ngIf="isTaxConfigured">
          <div class="activity-booking-summary__rate-label">
            <span class="activity-booking-summary__rate-label">{{'tax' | translate}}</span>
          </div>
          <div class="activity-booking-summary__rate">
            {{OperationCurrency}} {{
(ratePlan.TotalTaxAmount ?
                        ((ratePlan.TotalTaxAmount || 0) + (ratePlan.TotalAddonTaxAmount || 0) | currencyDecimalFormatter) : '0.00')
            }}
          </div>
        </div>
        <div class="row negotiation-label">
          <div class="col-lg-6 col-md-6">
            <span class="activity-booking-summary__rate-label">{{'negotiateValue' | translate}}</span>
          </div>
          <div class="col-lg-6 col-md-5  text-right">
            {{
(!partyService.sessionBookingDataSaveObj.NegotiatedAmount
                        || partyService.sessionBookingDataSaveObj.NegotiatedAmount >= 0 ? '+ ' : (partyService.sessionBookingDataSaveObj.NegotiatedAmount == 0 ? '': '-'))
            }} {{OperationCurrency}}
            {{
(partyService.sessionBookingDataSaveObj.NegotiatedAmount
                        ? (partyService.sessionBookingDataSaveObj.NegotiatedAmount | negtiationRateFormatter) :
                        '0.00')
            }}
          </div>
        </div>
        <div class="row negotiation-label">
          <div class="col-lg-6 col-md-6">
            <span class="activity-booking-summary__rate-label"> {{'findTotal' | translate}}</span>
          </div>
          <div class="col-lg-6 col-md-5  text-right">
            <span class="icon-information"
                  *ngIf="partyService.sessionBookingDataSaveObj.Comments && partyService.sessionBookingDataSaveObj.Comments !== ''"
                  matTooltipClass="tooltip-class"
                  title="{{partyService.sessionBookingDataSaveObj.Comments}}"></span>
            {{OperationCurrency}} {{
(partyService.sessionBookingDataSaveObj.TotalPayable ?
                        ((partyService.sessionBookingDataSaveObj.TotalPayable + (ratePlan.TotalTaxAmount || 0) + (ratePlan.TotalAddonTaxAmount || 0)) + ((ratePlan.TotalServiceChargeAmount || 0) + (ratePlan.TotalAddonServiceChargeAmount || 0)) | currencyDecimalFormatter) :
                        '0.00')
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="activity-booking-summary__guest-details" *ngIf="partyService.sessionBookingDataSaveObj">
      <app-iframe-container [iframe]="partyService.editIframeUrl"
                            [SaveBookingObj]="partyService.sessionBookingDataSaveObj"
                            (ReservationAttemptId)="setReservationData($event)" [componentType]="fromComponent">
      </app-iframe-container>
    </div>
  </div>
</ng-scrollbar>
