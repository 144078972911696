import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CacheService } from '@app/core/services/cache.service';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { ComponentTypes } from '@app/shared/constants/commonenums';
import { BookingChargeType } from '@app/shared/models/RestaurantDTO';
import { FacadeService } from '@app/shared/services/facade.service';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activity-payment-summary',
  templateUrl: './activity-payment-summary.component.html',
  styleUrls: ['./activity-payment-summary.component.scss']
})
export class ActivityPaymentSummaryComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  ratePlan: any;
  OperationCurrency: any;
  fromComponent = ComponentTypes.EditActivityBooking;
  isTaxConfigured = false;
  isServiceConfigured = false;

  constructor(public dialog: MatDialog, public partyService: PartyService, @Inject(COMPONENTINPUT) public data, public translateService: TranslateService,
    public facadeService: FacadeService, public popupService: PopupService, public cs: CacheService) {
    this.fromComponent = this.partyService.isfrombuynow ? ComponentTypes.AddActivityBooking : ComponentTypes.EditActivityBooking;
    this.subscriptions.add(this.partyService.tabChange$.subscribe(value => {
      if (value === 2) {
        this.ratePlan = this.partyService.ratePlan$.value;
  }
}));
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this.OperationCurrency = sett.General.OperationCurrency;
      this.isTaxConfigured = sett.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax)?.length > 0 ;
      this.isServiceConfigured = sett.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge)?.length > 0 ;
    }));
  }


  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  setReservationData(value) {
    this.partyService.sessionBookingDataSaveObj.ReservationAttemptId = value;
    if (this.fromComponent == ComponentTypes.AddActivityBooking && this.partyService.isfrombuynow) {
      this.partyService.reservationConfirm();
    }
  }
}
