import { EventEmitter, Injectable } from '@angular/core';
import { GiftCardsService } from './gift-cards.data.service';
import { GiftCardConfiguration, IssueGiftCardPopupModel, GiftCardTransactionItem, ValidatePayResponse, GiftcardSearchResult, HandleRequest, CashBackRequest, GiftCardTransactionStatus, GatewayConfiguration, GiftCardPMRequest } from '../../shared/service/payment/payment-model';
import { ShopDialogPopUp } from '../../shop/shop-dialog-popup/shop-dialog-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectedProducts, CommonVariablesService } from '../../shared/service/common-variables.service';
import { POS } from '../../shared/shared.modal';
import { PaymentMethods, HandleResponse, GiftCardInfo, PaymentErrorCodes } from '../../shared/business/shared.modals';
import { RetailFeatureFlagInformationService, SearchTypes } from '../../shared/service/retail.feature.flag.information.service';

import { PayAgentService } from '../../shared/service/payagent.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import * as GlobalConst from '../../shared/globalsContant';
import { ValidatePayRequest, HttpResponseStatus, GiftCardPaymentRecord } from '../../shared/service/payment/payment-business.model';
import { enterCommentPrompt, GiftCardShopDetails } from '../../shop/shop.modals';
import { ShopServiceitemSummaryComponent } from '../../shop/shop-common-components/shop-serviceitem-summary/shop-serviceitem-summary.component';
import * as _ from 'lodash';
import { RetailService } from '../../retail.service';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { InternalGiftCardBusinessService } from '../../shared/business/Giftcard/Internal-Giftcard.business.service';
import { ExternalGiftcardBusinessService } from '../../shared/business/Giftcard/External-Giftcard.business.service';
import { rGuestBaseService } from '../../shared/service/base.service';
import { DialogOverviewExampleDialog } from '../../shared/dialog-popup/dialogPopup-componenet';
import { PaymentComponent } from '../../payment/payment.component';
import { CreditCardConfigurationService } from '../credit-cards/credit-card.service';
import { AlertMessagePopupComponent } from '../../shared/alert-message-popup/alert-message-popup.component';
import { ActionMode } from 'src/app/common/enums/shared-enums';
import { NonPMAgentRequestHandler } from '../../payment/NonPMAgentRequestHandler';
import { GiftCardGuestDetails } from '../../shared/business/Giftcard/IGiftcardActions';
import { RetailRoutes } from '../../retail-route';
import { PaymentCommunication } from '../../payment-communication.service';
import { Router } from '@angular/router';
@Injectable()
export class GiftCardBusiness extends rGuestBaseService {
    _giftCardConfig: GiftCardConfiguration;
    private _giftCardRetailItem: POS.RetailItem;
    private _useGiftCardInterface: boolean = false;
    private _isConfigLoaded: boolean = false;
    private GIFTCARD_SWIPE_TIMEOUT_CONFIG_KEY = "WaitForResponseSecs";
    private GIFTCARD_SWIPE_POOLING_CONFIG_KEY = "responsePoolingSecs";
    private v1giftcardsMethods = [PaymentMethods.V1GiftCard, PaymentMethods.V1GiftCardIdTech];
    captions: any;
    private progressMsg = new EventEmitter<string>();
    private progressPercent = new EventEmitter<number>();
    private transactionInProgressDialogRef: MatDialogRef<AlertMessagePopupComponent>;

    IssuanceProgressCompleted = new EventEmitter<{ transactionId: number, retailTicketNumber: string }>();
    GiftcardActionsCompleted = new EventEmitter<{ createItemRes: any }>();

    public get IsGiftCardConfigLoaded() {
        return this._isConfigLoaded;
    }

    public get UseGiftcardInterface() {
        return this._useGiftCardInterface;
    }

    private _giftcardType: GlobalConst.GiftCardType;
    public get GiftcardType() {
        return this._giftcardType;
    }

    private _cashBackCallRequired: boolean = false;
    public get CashBackCallRequired() {
        return this._cashBackCallRequired;
    }

    public get GiftcardVendorType(): GlobalConst.GiftCardVendorType {
        return this.VendorTypeMapper[this.featureFlagInfo?.GatewayType?.trim().toLowerCase()];
    }

    public get GiftcardVendorName() {
        return this.featureFlagInfo.GatewayType;
    }

    public get ThirdPartyTenderByVendor() {
        return this.GiftcardVendorType === GlobalConst.GiftCardVendorType.Agilysys ? PaymentMethods.AgilysysGiftCard : PaymentMethods.ExternalGiftCard;
    }

    public get ThirdPartyIdTechTenderByVendor() {
        return this.GiftcardVendorType === GlobalConst.GiftCardVendorType.Agilysys ? PaymentMethods.AgilysysGiftCardIdTech : PaymentMethods.ExternalGiftCardIdTech;
    }

    public get IsAgilysysGiftcard() {
        return (this.GiftcardVendorType === GlobalConst.GiftCardVendorType.Agilysys);
    }

    public get IsShift4Giftcard() {
        return (this.GiftcardVendorType === GlobalConst.GiftCardVendorType.Shift4);
    }

    private GiftcardActionProcessor(giftcardType) {
        return (giftcardType == GlobalConst.GiftCardType.V1GiftCard ? this._internalGCBusiness : this._externalGCBusiness);
    }

    private VendorTypeMapper = {
        "givex": GlobalConst.GiftCardVendorType.Givex,
        "shift4": GlobalConst.GiftCardVendorType.Shift4,
        "vantiv": GlobalConst.GiftCardVendorType.Vantiv,
        "agilysys": GlobalConst.GiftCardVendorType.Agilysys,
        "svs": GlobalConst.GiftCardVendorType.SVS,
    }

    loadGiftcardHistory: any[] = [];
    unLoadGiftcardHistory: any[] = [];
    issueGiftcardHistory: any[] = [];
    giftCardBalanceList: any[] = [];
    GiftcardPopupData: any;
    pmAgentCardNotFoundMsg = 'The card could not be identified from the data.';
    cardStatusInactiveMsg = "The processor reports that the card is not active.";
    AgysGiftcard_CardNotRegistered = "An attempt was made to use a card that has not been registered.";

    constructor(
        private _giftCardDataService: GiftCardsService
        , private featureFlagInfo: RetailFeatureFlagInformationService
        , private _payAgentService: PayAgentService
        , public _ss: CommonVariablesService
        , private localization: RetailLocalization
        , private http: HttpServiceCall
        , private utils: RetailUtilities
        , public dialog: MatDialog
        , private _retailService: RetailService
        , private _propertyInfo: RetailPropertyInformation
        , private _internalGCBusiness: InternalGiftCardBusinessService
        , private _externalGCBusiness: ExternalGiftcardBusinessService
        , private creditCardConfigurationService: CreditCardConfigurationService
        , private _nonPMAgentHandler: NonPMAgentRequestHandler
        , private _retailComm: PaymentCommunication
        , private _router: Router
    ) {
        super();
        this.captions = this.localization.captions.shop.GiftCard;
        this.featureFlagInfo.RefreshConfigTriggered.subscribe(() => {
            if (!_propertyInfo.UseRetailInterface && !this._isConfigLoaded)
                this.GetGiftCardConfig();
        });
        if (!_propertyInfo.UseRetailInterface && !this._isConfigLoaded)
            this.GetGiftCardConfig();
    }

    reset(): void {
        this._giftCardConfig = null;
        this._isConfigLoaded = false;
        this._useGiftCardInterface = false;
        this._giftcardType = 0;
        this._cashBackCallRequired = false;
    }

    /**
     * @description Retrieves the Giftcard configuration and Retains the values for further validations and Usages across retail
     */
    async GetGiftCardConfig() {
        let config = await this._giftCardDataService.getGiftCardConfiguration();
        if (config) {
            this._useGiftCardInterface = config.activateGiftCardInterface;
            if (config.activateGiftCardInterface && config.retailItemId) {
                this._giftCardRetailItem = await this._giftCardDataService.GetRetailItemById(config.retailItemId);
            }
            this._giftCardConfig = config;
            this._giftcardType = config.giftCardType;
            this._ss.UpdateGiftTabStatus.emit(config.activateGiftCardInterface);
            this._isConfigLoaded = true;
            if (this._giftcardType == GlobalConst.GiftCardType.Both || this._giftcardType == GlobalConst.GiftCardType.V1GiftCard) {
                let response: GatewayConfiguration[] = await this.creditCardConfigurationService.getCreditCardConfiguration({ type: this._giftcardType });
                const v1GCConfig = response.find(x => x.type == GlobalConst.GiftCardType.V1GiftCard);
                const timeOutConfig = v1GCConfig?.gatewayValues?.find(x => x.configKey.toLowerCase() == this.GIFTCARD_SWIPE_TIMEOUT_CONFIG_KEY.toLowerCase());
                const poolingConfig = v1GCConfig?.gatewayValues?.find(x => x.configKey.toLowerCase() == this.GIFTCARD_SWIPE_POOLING_CONFIG_KEY.toLowerCase());
                this.featureFlagInfo.v1GiftcardSwipeTimeout = (timeOutConfig && timeOutConfig?.configValue) ? Number(timeOutConfig?.configValue) : this.featureFlagInfo.v1GiftcardSwipeTimeout;
                this.featureFlagInfo.v1GiftcardPoolingTime = (poolingConfig && poolingConfig?.configValue) ? Number(poolingConfig?.configValue) : this.featureFlagInfo.v1GiftcardPoolingTime;
            }
        }
        return config;
    }

    async CreateOrUpdateGiftCardConfig(giftCardConfig: GiftCardConfiguration) {
        try {
            return await this._giftCardDataService.createOrUpdateGiftCardConfiguration(giftCardConfig);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    /**
     * @description Populates the default values in the Giftcard Issue Popup
     * @param retailItems 
     */
    PopulateDefaultGiftCardValues(retailItems: any[]) {
        if (this._giftCardConfig && this._giftCardConfig.activateGiftCardInterface && retailItems) {
            const giftCardRetailItem = retailItems.find(x => x.retailItemDetail.id === this._giftCardConfig.retailItemId);
            if (giftCardRetailItem) {
                giftCardRetailItem.retailItemDetail.isGiftCardItem = true;
                giftCardRetailItem.retailItemDetail.isGiftCardCustomValueItem = false;
                this._giftCardConfig.giftCardDefaultValues.forEach(x => {
                    const defaultGiftItem = JSON.parse(JSON.stringify(giftCardRetailItem));
                    defaultGiftItem.retailItemDetail.salesPrice = x.amount;
                    defaultGiftItem.retailItemDetail.isGiftCardItem = true;
                    defaultGiftItem.retailItemDetail.isGiftCardCustomValueItem = true;
                    if (!retailItems.some(t => t.retailItemDetail.isGiftCardItem
                        && t.retailItemDetail.salesPrice === defaultGiftItem.retailItemDetail.salesPrice
                        && t.retailItemDetail.isGiftCardCustomValueItem)) {
                        retailItems.push(defaultGiftItem);
                    }
                });
            }
        }
        return retailItems;
    }

    AddCardNumberToProductName(product: SelectedProducts, item) {
        let productName = "";
        if (product) {
            const tempProductName = product.ProductName.includes(product.GiftCardTransactionItem?.cardNumber) ? product.ProductName :
                `${product.ProductName} ${product.GiftCardTransactionItem?.cardNumber}`;
            productName = product.isGiftCardItem && product.GiftCardTransactionItem ? tempProductName : product.ProductName;
        }
        return productName;
    }

    /**
     * @description Displays the Giftcard Issue popup with the prefilled values if configured
     * @param data 
     * @param callback 
     * @param extraParams 
     */
    ShowIssueGiftCardModal(data: IssueGiftCardPopupModel, callback: Function, extraParams?: any): MatDialogRef<any, any> {
        const dialogRef = this.dialog.open(ShopDialogPopUp, {
            width: !this._giftCardConfig.allowSearchByGuestName ? "35%" : "50%",
            height: !this._giftCardConfig.allowSearchByGuestName ? "80%" : "90%",
            maxHeight: "700px",
            maxWidth: "900px",
            disableClose: true,
            hasBackdrop: true,
            data: {
                headername: "Gift Card",
                closebool: true,
                templatename: "GC",
                data: [data, this._giftCardConfig]
            },
            panelClass: "small-popup"
        });
        data.isRequestName = this._giftCardRetailItem.retailItemDetail.isRequestName;
        const subscription = dialogRef.afterClosed().subscribe((x) => {
            x.isRequestName = data?.isRequestName;
            if (this._giftCardRetailItem.retailItemDetail.isRequireComments) {
                const enterCommentDialogRef = this.dialog.open(DialogOverviewExampleDialog, {
                    height: 'auto',
                    width: '750px',
                    data: {
                        Type: 'ENTERCOMMENT',
                        headername: this.localization.captions.shop.EnterComment,
                        data: {
                            itemDescription: this._giftCardRetailItem.retailItemDetail.itemDescription
                        },
                        quantity: 1,
                        closebool: true,
                        buttonType: GlobalConst.ButtonType.SaveCancel

                    },
                    panelClass: 'small-popup',
                    disableClose: true,
                    hasBackdrop: true
                });
                enterCommentDialogRef.afterClosed().subscribe((result: enterCommentPrompt) => {
                    if (result.res == GlobalConst.ButtonOptions.Save && result.comment) {
                        x.itemComments = result.comment;
                        if (callback) {
                            callback(x, extraParams);
                        }

                    }
                })
            }
            else {
                if (callback) {
                    callback(x, extraParams);
                }
                if (subscription) {
                    subscription.unsubscribe();
                }
            }
        });
        return dialogRef;
    }

    /**
     * @description Returns an object matching the SelectedProduct interface with the values from params     
     * @param giftCardData IssueGiftCardPopupModel
     * @returns SelectedProducts
     */
    FormProductObj(giftCardData: IssueGiftCardPopupModel): SelectedProducts {
        if (this._giftCardRetailItem && giftCardData) {
            return {
                payeeId: 0,
                ItemId: this._giftCardRetailItem.retailItemDetail.id,
                ItemType: this._giftCardRetailItem.retailItemDetail.itemType,
                ExternalPOSItemId: this._giftCardRetailItem.retailItemDetail.externalPOSId,
                ItemDescription: `${this._giftCardRetailItem.retailItemDetail.itemDescription} ${giftCardData.GiftCardNumber}`,
                ProductName: `${this._giftCardRetailItem.retailItemDetail.itemDescription} ${giftCardData.GiftCardNumber}`,
                ServiceId: 0,
                ProductPrice: giftCardData.Amount,
                SalesPrice: giftCardData.Amount,
                Noofitems: 1,
                Discount: 0,
                DiscountPercentage: 0,
                DiscountTypeId: 0,
                isEditDisabled: true,
                isDeleteDisabled: true,
                isGroupingKey: this._giftCardRetailItem.retailItemDetail.isGroupingKey,
                PackageItemId: 0,
                MultiPack: this._giftCardRetailItem.retailItemDetail.isMultiPack,
                ClientMultiPackId: 0,
                PackageGroupId: 0,
                isOpenPricedItem: this._giftCardRetailItem.retailItemDetail.isOpenItem,
                category: this._giftCardRetailItem.retailItemDetail.category,
                LineNumber: 0,
                isModificationRestricted: true,
                isGiftCardItem: true,
                GiftCardTransactionItem: this.FormGiftCardShopDetail(giftCardData),
                itemComments: giftCardData.itemComments,
                isRequestName: giftCardData.isRequestName,
                discountComments: '',
                discountReason: 0
            } as SelectedProducts;
        }
    }

    /**
     * @description Returns the GiftCardPaymentRecord object for validatePay Requests
     * @param giftCardData 
     * @param redemptionAmount 
     * @param isUnload 
     */
    ConstructGiftcardPaymentRecord(giftCardData: GiftCardTransactionItem | GiftcardSearchResult, redemptionAmount: number) {
        if (giftCardData) {
            return {
                amount: redemptionAmount ? redemptionAmount : giftCardData.amount,
                type: giftCardData.cardType,
                firstName: giftCardData.firstName,
                lastName: giftCardData.lastName,
                cardNumber: giftCardData.cardNumber,
                email: giftCardData.email,
                phoneNumber: giftCardData.phoneNumber,
                expiryDate:
                    giftCardData.expiryDate &&
                        giftCardData.expiryDate != this.captions.NA &&
                        !(
                            giftCardData.cardType == GlobalConst.GiftCardType.ExternalGiftCard &&
                            this.IsAgilysysGiftcard
                        )
                        ? this.localization.ConvertDateToISODateTime(
                            this.localization.getDate(giftCardData.expiryDate)
                        )
                        : null,
            } as GiftCardPaymentRecord;
        }
    }

    /**
     * @description Returns the GiftCardShopDetails from the popup data
     * @param cardData 
     */
    FormGiftCardShopDetail(cardData: IssueGiftCardPopupModel): GiftCardShopDetails {
        const handleInfo: HandleResponse = cardData.HandleInfo as any;
        handleInfo.email = cardData?.EmailId;
        handleInfo.phoneNumber = cardData?.PhoneNumber;
        return {
            cardType: cardData.thirdparty ? GlobalConst.GiftCardType.ExternalGiftCard : GlobalConst.GiftCardType.V1GiftCard,
            amount: cardData.Amount,
            firstName: cardData.FirstName,
            lastName: cardData.LastName,
            email: cardData.EmailId,
            phoneNumber: cardData.PhoneNumber,
            cardNumber: cardData.GiftCardNumber,
            expiryDate: cardData.thirdparty && this.IsAgilysysGiftcard ? null : cardData.ExpiryDate,
            handleInfo: cardData.HandleInfo,
            transactionType: !handleInfo.isCardActive ? GlobalConst.GiftCardTransactionType.Issue : GlobalConst.GiftCardTransactionType.Load,
            isIssue: !handleInfo.isCardActive
        }
    }

    /**
     * @description Performs the Giftcard Redemption from Sale and Return flows
     * @param compScope 
     * @param saleAmount 
     * @param isReturnScreen 
     * @param returnTransitionDetails 
     */
    PerformGiftCardRedemption(compScope, saleAmount, isReturnScreen: boolean = false, returnTransitionDetails?) {
        if (compScope) {
            const HandleGiftcardRedemption = () => {
                if (isReturnScreen) {
                    let returnScreenScope: ShopServiceitemSummaryComponent = compScope;
                    const switchToMulti: boolean = (returnScreenScope.selectedGiftCard.amount < returnScreenScope._sbs.remainingAmount);
                    returnScreenScope._sbs.ConfirmationPopupShown = true;
                    returnScreenScope._sbs.MakeSale(compScope, returnTransitionDetails);
                    if (switchToMulti) {
                        returnScreenScope._sbs.SwitchToMultiPayment({ amount: { authorisedAmount: saleAmount } }, returnTransitionDetails, true);
                    }
                } else {
                    let saleScreenScope: PaymentComponent = compScope;
                    const switchToMulti: boolean = (saleScreenScope.selectedGiftCard.amount < saleScreenScope.remainingAmount);
                    compScope.ConfirmationPopupShown = true;
                    saleScreenScope._pblh.MakeSale();
                    if (switchToMulti) {
                        saleScreenScope._pblh.SwitchToMultiPayment({ amount: { authorisedAmount: saleAmount } }, true);
                    }
                }
            }
            // Proceed with the sale amount if cashback is not required
            if (!this._giftCardConfig.allowCashback) {
                HandleGiftcardRedemption();
            } else { // Show dialog only if Cashback toggle is enabled
                const dialogRef = compScope.dialog.open(ShopDialogPopUp, {
                    width: '500px',
                    height: '355px',
                    maxHeight: '700px',
                    disableClose: true,
                    hasBackdrop: true,
                    data: {
                        isEdit: false,
                        headername: compScope.giftcardCaptions.popupHeader,
                        closebool: true,
                        templatename: 'GiftCardPayment',
                        data: {
                            balance: compScope.selectedGiftCard.amount,
                            saleAmount: saleAmount
                        }
                    },
                    panelClass: 'shop-payment'
                });

                dialogRef.afterClosed().pipe().subscribe((x) => {
                    if (x && compScope) {
                        HandleGiftcardRedemption();
                        this.GiftcardPopupData = x;
                        this._cashBackCallRequired = (this.GiftcardPopupData.cashback && this.GiftcardPopupData.cashbackAmt > 0);
                    }
                });
            }
        }
    }

    /**
     * @description Performs the Loading of Giftcards invloved in a transaction
     * @param transactionId 
     */
    async PerformGiftCardLoad(transactionId: number, retailTicketNumber: string, createRetailitemResponse: any) {
        if (transactionId) {

            let giftcardtransactionItems: GiftCardTransactionItem[] = await this.GetGiftcardItemsByActionType(transactionId, GlobalConst.GiftCardTransactionType.Load);
            if (giftcardtransactionItems && giftcardtransactionItems.length > 0) {
                this.ShowTransactionInprogressDialog(GlobalConst.GiftCardTransactionType.Load);
                this.loadGiftcardHistory = [];

                this.LoadCardHelper(giftcardtransactionItems[0], giftcardtransactionItems, retailTicketNumber, createRetailitemResponse);
            }
        }
    }

    /**
     * @description This is a helper method used for Giftcard Load
     */
    private LoadCardHelper(giftcardItem, giftcardtransactionItems, retailTicketNumber, createRetailitemResponse) {
        try {
            const tenderId = this.GetTenderIdByCardType(giftcardItem.cardType);
            const HandleValidateGCResponse = (resp) => {
                if (resp && resp.status) {
                    let pMPayResponse: ValidatePayResponse = resp;
                    let isUpdateSuccess = this.UpdateGiftCardTransactionSuccess(giftcardItem.id, pMPayResponse.transactionId, GlobalConst.GiftCardTransactionType.Load);
                    isUpdateSuccess.then((updateResponse) => {
                        this.ProceedNextCard(giftcardtransactionItems, giftcardItem, updateResponse.result, '', retailTicketNumber, createRetailitemResponse);
                    }).catch(() => { this.ProceedNextCard(giftcardtransactionItems, giftcardItem, false, "UpdateGiftCardLoadSuccess", retailTicketNumber, createRetailitemResponse); });
                }
            }
            if (this.featureFlagInfo.SkipPMAgent && giftcardItem.cardType != GlobalConst.GiftCardType.V1GiftCard) {
                const cardHandle: HandleResponse = JSON.parse(giftcardItem.handleInfo);
                let request: GiftCardPMRequest = {
                    handle: cardHandle.paymentHandle[0]?.handle,
                    Amount: {
                        RequestAmount: giftcardItem.amount
                    },
                    InquirerInfo: {
                        TenderId: String(tenderId),
                        TerminalId: "0",
                        OrderNumber: retailTicketNumber,
                        Profitcenter: "0",
                        ZoneOffset: this.featureFlagInfo.ZoneOffset
                    },
                    PaymentDetails: {
                        firstName: giftcardItem.firstName,
                        lastName: giftcardItem.lastName,
                        email: cardHandle.email,
                        phoneNumber: cardHandle.phoneNumber
                    } as any
                }
                let validateGCResponse = this._nonPMAgentHandler.LoadCard(request, this._ss.SelectedOutletId);
                validateGCResponse.then((resp) => {
                    HandleValidateGCResponse(resp);
                }).catch(() => { this.ProceedNextCard(giftcardtransactionItems, giftcardItem, false, "ValidateGiftcardLoadRequest", retailTicketNumber, createRetailitemResponse); });
            } else {
                let loadResponse = this.LoadGiftCard(giftcardItem, tenderId, retailTicketNumber);
                loadResponse.then((response) => {
                    if (this.IsRequestSuccess(response)) {
                        let validateRes = this.ValidateGiftcardRequest(giftcardItem, tenderId, response, GlobalConst.GiftCardTransactionType.Load, retailTicketNumber);
                        validateRes.then((resp) => {
                            HandleValidateGCResponse(resp.result);
                        }).catch(() => { this.ProceedNextCard(giftcardtransactionItems, giftcardItem, false, "ValidateGiftcardLoadRequest", retailTicketNumber, createRetailitemResponse); });
                    } else {
                        this.HandleError(response, tenderId);
                    }
                }).catch((err) => {
                    const errorCode = err?.error[0]?.Code;
                    const gatewayErrorMsg = err?.error[0]?.Message;
                    const GiftcardNotIssuedError = "Card Not Issued"; //Error message mentioned in Pay Docs
                    //Fallback mechanism for fresh cards
                    if ((errorCode && errorCode == PaymentErrorCodes.CardNotIssued)
                        || (gatewayErrorMsg && gatewayErrorMsg?.toLowerCase() == GiftcardNotIssuedError.toLowerCase())) {
                        this.TryIssueCardOnLoadFailure(giftcardItem, giftcardtransactionItems, retailTicketNumber, createRetailitemResponse);
                    } else {
                        this.ProceedNextCard(giftcardtransactionItems, giftcardItem, false, "Load", retailTicketNumber, createRetailitemResponse);
                        this.HandlePMAgentError(err);
                    }
                });
            }
        } catch (error) {
            console.log("load gift card failure:" + error);
        }
    }

    /**
     * @description Internal method to trigger the loading of next giftcard
     * @param giftcardtransactionItems 
     * @param giftcardItem 
     * @param updatedStatus 
     * @param processLevel 
     */
    private ProceedNextCard(giftcardtransactionItems, giftcardItem: GiftCardTransactionItem, updatedStatus: boolean, processLevel: string = "", retailTicketNumber: string, createRetailitemResponse: object) {
        this.loadGiftcardHistory.push({
            cardNumber: giftcardItem.cardNumber,
            loadSucceeded: updatedStatus,
            process: processLevel,
            amount: this.localization.localizeCurrency(giftcardItem.amount, true),
            type: giftcardItem.cardType
        });
        this.CheckOverallLoadStatusAndShowDialog(this.loadGiftcardHistory, giftcardtransactionItems, giftcardItem, retailTicketNumber, createRetailitemResponse);
    }

    /**
     * @description This method calculates whether the load process is completed and displays popup accordingly
     * @param loadGiftcardHistory 
     * @param giftcardtransactionItems 
     * @param currentProcessedItem 
     */
    private CheckOverallLoadStatusAndShowDialog(loadGiftcardHistory, giftcardtransactionItems: GiftCardTransactionItem[], currentProcessedItem, retailTicketNumber, createRetailitemResponse) {
        if (loadGiftcardHistory.length == giftcardtransactionItems.length) {
            if (loadGiftcardHistory.every(x => x.loadSucceeded === true)) {
                let createItemRes = createRetailitemResponse;
                this.ShowTransactionSuccessDialog(GlobalConst.GiftCardTransactionType.Load);
                this.GiftcardActionsCompleted.emit({
                    createItemRes
                });
            } else {
                this.ShowFailureDialog(GlobalConst.GiftCardTransactionType.Load, this.FormGiftcardFailureMsg(loadGiftcardHistory, GlobalConst.GiftCardTransactionType.Load));
                console.table(loadGiftcardHistory);
            }
        } else {
            if (currentProcessedItem) {
                let giftcardItem = giftcardtransactionItems[giftcardtransactionItems.indexOf(currentProcessedItem) + 1];
                this.LoadCardHelper(giftcardItem, giftcardtransactionItems, retailTicketNumber, createRetailitemResponse);
            }
        }
    }

    private ShowTransactionInprogressDialog(transType: GlobalConst.GiftCardTransactionType) {
        this.dialog.closeAll();
        const captionMap = {
            [GlobalConst.GiftCardTransactionType.CashBack]: this.localization.captions.shop.GiftCard.CashBackInprogressMsg,
            [GlobalConst.GiftCardTransactionType.Load]: this.localization.captions.shop.GiftCard.TopUpInprogressMsg,
            [GlobalConst.GiftCardTransactionType.Issue]: this.localization.captions.shop.GiftCard.IssueInprogressMsg
        }
        const Popupmessage = captionMap[transType];
        const dataObj = {
            text: Popupmessage
            , buttonname: ''
            , headertext: ''
            , isloaderenable: (transType != GlobalConst.GiftCardTransactionType.Issue)
            , showProgress: (transType == GlobalConst.GiftCardTransactionType.Issue)
            , progressPercent: this.progressPercent
            , progressMsg: this.progressMsg
        };
        this.transactionInProgressDialogRef = this.utils.roomOpenDialog(dataObj);
    }

    private ShowTransactionSuccessDialog(transType: GlobalConst.GiftCardTransactionType) {
        if (this.transactionInProgressDialogRef) this.transactionInProgressDialogRef.close();
        const captionMap = {
            [GlobalConst.GiftCardTransactionType.CashBack]: this.localization.captions.shop.GiftCard.CashbackSuccessMsg,
            [GlobalConst.GiftCardTransactionType.Load]: this.localization.captions.shop.GiftCard.TopupSuccessMsg,
            [GlobalConst.GiftCardTransactionType.Issue]: this.localization.captions.shop.GiftCard.IssueSuccessMsg
        }
        const dataObj = {
            text: captionMap[transType],
            buttonname: this.localization.captions.common.okay,
            headertext: this.localization.captions.shop.GiftCard.GiftCard,
            icon: 'icon-success-icon'
        };
        this.utils.roomOpenDialog(dataObj);
    }

    private ShowFailureDialog(failedProcess: GlobalConst.GiftCardTransactionType, cardNumber: string) {
        const errorMsg = failedProcess == GlobalConst.GiftCardTransactionType.Issue ?
            this.localization.captions.shop.GiftCard.IssueCardFailureMsg :
            this.localization.captions.shop.GiftCard.LoadCardFailureMsg;
        this.dialog.closeAll();
        this.utils.ShowErrorMessage(
            this.localization.captions.common.Error,
            this.localization.replacePlaceholders(
                errorMsg,
                ['cardNumber'],
                [cardNumber]
            )
        );
    }

    /**
     * @description Retrieves the Giftcarditems based on the Action type
     * @param transactionId 
     */
    async GetGiftcardItemsByActionType(transactionId: number, transType: GlobalConst.GiftCardTransactionType): Promise<GiftCardTransactionItem[]> {
        const callDescMap = {
            [GlobalConst.GiftCardTransactionType.Issue]: "GetGiftcardItemsForIssue",
            [GlobalConst.GiftCardTransactionType.Load]: "GetGiftcardItemsForLoad",
            [GlobalConst.GiftCardTransactionType.UnLoad]: "GetGiftCardItemsForUnLoad",
        }
        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.retailPOS,
            callDesc: callDescMap[transType],
            method: HttpMethod.Get,
            uriParams: { transactionId: transactionId }
        });
        if (response && response.result) {
            let giftcardItems: GiftCardTransactionItem[] = response.result;
            console.log({ giftcardItems });
            return giftcardItems;
        }
    }

    /**
     * @description Retrieves the giftcard items involved in a transaction
     */
    async GetGiftcardItems(transactionId: number): Promise<GiftCardTransactionItem[]> {
        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.retailPOS,
            callDesc: "GetGiftcardItems",
            method: HttpMethod.Get,
            uriParams: { transactionId: transactionId }
        });
        if (response && response.result) {
            let giftcardItems: GiftCardTransactionItem[] = response.result;
            console.log({ giftcardItems });
            return giftcardItems;
        }
    }

    /**
     * @description Updates the status of Giftcard load process in POS
     * @param transactionItemId 
     * @param paymentReferenceId 
     * @param isUnload 
     */
    private async UpdateGiftCardTransactionSuccess(transactionItemId: number, paymentReferenceId, type: GlobalConst.GiftCardTransactionType): Promise<any> {
        if (transactionItemId && paymentReferenceId) {
            const TypeMap = {
                [GlobalConst.GiftCardTransactionType.Issue]: "UpdateGiftCardIssueSuccess",
                [GlobalConst.GiftCardTransactionType.Load]: "UpdateGiftCardLoadSuccess",
                [GlobalConst.GiftCardTransactionType.UnLoad]: "UpdateGiftCardUnLoadSuccess",
            }
            return this.http.CallApiAsync<any>({
                host: GlobalConst.Host.retailPOS,
                callDesc: TypeMap[type],
                method: HttpMethod.Put,
                uriParams: { giftcardTransactionItemId: transactionItemId, paymentReferenceId: paymentReferenceId }
            });
        }
    }

    /**
     * @description Communicates with PMAgent for loading a giftcard
     * @param giftcardTransactionItem 
     * @param tenderId 
     */
    async LoadGiftCard(giftcardTransactionItem: GiftCardTransactionItem, tenderId: PaymentMethods, retailTicketNumber: string) {
        const cardHandle: HandleResponse = JSON.parse(giftcardTransactionItem.handleInfo);
        return this.GiftcardActionProcessor(
            giftcardTransactionItem.cardType).RechargeGiftCard(giftcardTransactionItem.cardNumber
                , this.GetTicketNumber(giftcardTransactionItem.cardType, retailTicketNumber)
                , giftcardTransactionItem.amount
                , extractHandle(cardHandle)
                , tenderId
            );
    }

    private HandlePMAgentError(err) {
        //Show Payment Error Prompt
        if (err.error && (err.error[0] != null && err.error[0].Code != null && err.error[0].Code != ""))
            this._payAgentService.PaymentErrorPrompt(err.error[0].Code);
        else
            return this.utils.showError(this.localization.captions.shop.PayAgentUnavailable);
    }

    /**
     * @description Returns the ValidatePayRequest object for Load and UnLoad Process
     * @param giftcardTransactionItem 
     * @param tenderId 
     * @param pmAgentResponse 
     * @param isUnload 
     */
    private FormValidateGiftcardRequestBody(
        giftcardTransactionItem: GiftCardTransactionItem
        , tenderId: PaymentMethods
        , loadIssueResponse
        , type: GlobalConst.GiftCardTransactionType
        , retailTicketNumber: string) {        
        return {
            paymentMethodId: giftcardTransactionItem.PaymentMethodId,
            tenderId: tenderId,
            parentTenderId: 0,
            payAgentResponse: this.v1giftcardsMethods.includes(tenderId)
                ? null
                : {
                    payAgentID: loadIssueResponse.payAgentId,
                    status: loadIssueResponse.status,
                    transactionDetails: loadIssueResponse.transactionDetails,
                    transactionKey: loadIssueResponse.transactionKey,
                    errorMessage: loadIssueResponse.errorMessage,
                },
            cardInfo: null,
            amount: giftcardTransactionItem.amount,
            surcharge: 0,
            aCESPaymentRecord: null,
            cMSPaymentRecord: null,
            giftcardPaymentRecord: {
                amount: giftcardTransactionItem.amount,
                type: giftcardTransactionItem.cardType,
                firstName: giftcardTransactionItem.firstName,
                lastName: giftcardTransactionItem.lastName,
                email: giftcardTransactionItem?.email,
                phoneNumber: giftcardTransactionItem?.phoneNumber,
                cardNumber: giftcardTransactionItem.cardNumber,
                expiryDate:
                    giftcardTransactionItem.expiryDate &&
                        giftcardTransactionItem.expiryDate != this.captions.NA &&
                        !(
                            giftcardTransactionItem.cardType == GlobalConst.GiftCardType.ExternalGiftCard &&
                            this.IsAgilysysGiftcard
                        )
                        ? this.localization.ConvertDateToISODateTime(
                            this.localization.getDate(
                                giftcardTransactionItem.expiryDate
                            )
                        )
                        : null,
                isVoided: type === GlobalConst.GiftCardTransactionType.UnLoad,
                isIssued: type === GlobalConst.GiftCardTransactionType.Issue,
                isLoaded: type === GlobalConst.GiftCardTransactionType.Load,
                referenceId:
                    this.v1giftcardsMethods.includes(tenderId) &&
                        loadIssueResponse &&
                        loadIssueResponse.result
                        ? loadIssueResponse.result.invoiceId
                        : "",
            },
            aRPostingPaymentRecord: null,
            retailTicketNumber: retailTicketNumber,
            machineId: this.localization.GetMachineId(),
            hostName: loadIssueResponse?.hostName,
            agentVersion: loadIssueResponse?.version,
            ipAddress: loadIssueResponse?.ipAddress,
            guestGuid: null,
            searchType: SearchTypes.None
        };
    }

    /**
     * @description Validates the LoadRequest with Payment Manager throught Payment MS
     * @param giftcardTransactionItem 
     * @param tenderId 
     * @param loadCardResponse 
     */
    ValidateGiftcardRequest(giftcardTransactionItem: GiftCardTransactionItem, tenderId: PaymentMethods, loadCardResponse, transType: GlobalConst.GiftCardTransactionType, retailTicketNumber: string) {
        let validatePayReq: ValidatePayRequest = this.FormValidateGiftcardRequestBody(giftcardTransactionItem, tenderId, loadCardResponse, transType, retailTicketNumber);
        let validatePayResponse: any = this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "ValidateGiftCard",
            method: HttpMethod.Post,
            body: validatePayReq,
            uriParams: { outletId: this._ss.SelectedOutletId },
            showError: true
        });
        console.log(validatePayResponse.result);
        return validatePayResponse;
    }

    /**
     * @description Retrieves the GiftCardPaymentRecords for Redemption history
     * @param cardNumber 
     */
    async getGiftCardPaymentRecords(cardNumber: string) {
        if (!cardNumber) return [];
        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "GetGiftCardPaymentRecords",
            method: HttpMethod.Get,
            uriParams: { giftcardNumber: cardNumber }
        });
        if (response && response.result) {
            return response.result;
        }
    }

    /**
     * @description Retrieves the most recent Payment record for a Giftcard
     * @param cardNumber 
     * @param tenderId 
     */
    async GetLatestPaymentRecordDetailByCardNumber(cardNumber: string, tenderId: PaymentMethods): Promise<GiftCardPaymentRecord> {
        if (!cardNumber) return null;
        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "GetLatestPaymentRecordDetailByCardNumber",
            method: HttpMethod.Get,
            uriParams: { cardNumber: cardNumber, tenderId: tenderId }
        });
        if (response && response.result) {
            return response.result;
        }
    }

    /**
     * @description Retrieves the Ticket detail for a giftcard Transaction
     * @param paymentReferenceIds 
     */
    async getPaymentTicketDetail(paymentReferenceIds) {
        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.retailPOS,
            callDesc: "GetPaymentTicketDetail",
            method: HttpMethod.Put,
            body: paymentReferenceIds
        });
        if (response && response.result) {
            return response.result;
        }
    }

    /**
     * @description Calculates the whether the UnLoad can be done or not
     * @param transactionId 
     * @returns bool
     */
    async CanUnloadGiftCard(transactionId: number, retailTicketNumber: string) {        
        if (transactionId) {
            this._ss.giftCardListToUnload = [];
            let giftcardtransactionItems: GiftCardTransactionItem[] = await this.GetGiftcardItemsByActionType(transactionId, GlobalConst.GiftCardTransactionType.UnLoad);
            if (giftcardtransactionItems && giftcardtransactionItems.length > 0) {
                this.giftCardBalanceList = [];
                let getBalanceTasks: Promise<any>[] = [];
                this._retailService.loaderEnable.next(this.localization.captions.common.loadingData);
                // Load reversal check for Agilysys Giftcard
                if (!this.featureFlagInfo.SupportLoadReversal && giftcardtransactionItems.some(x => x.cardType == GlobalConst.GiftCardType.ExternalGiftCard && !x.isIssue)) {
                    const errorMsg = this.localization.replacePlaceholders(
                        this.captions.LoadReversalNotSupportedMsg
                        , ["vendorName"]
                        , [this.GiftcardVendorName]);
                    this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorMsg, GlobalConst.ButtonType.Ok);
                    this._retailService.loaderEnable.next('');
                    return false;
                }
                giftcardtransactionItems.forEach(g => getBalanceTasks.push(this.GetBalance(g)));
                let taskCompletion = await Promise.all(getBalanceTasks);
                this._retailService.loaderEnable.next('');
                if (taskCompletion && taskCompletion.length > 0) {
                    let failedTask = taskCompletion.filter(x => x.status.toLowerCase() === HttpResponseStatus.Failure);
                    if (failedTask && failedTask.length > 0) {
                        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.CanNotVoidTheTransaction, GlobalConst.ButtonType.Ok);
                        return false;
                    }
                    taskCompletion.forEach(element => {
                        const giftcardItem = giftcardtransactionItems.find(g => g.cardNumber == element.paymentHandle[0].inquiryInfo.id);
                        giftcardItem.handleFromGetHandles = element.paymentHandle[0].handle;
                        this.giftCardBalanceList.push({
                            cardNumber: element.paymentHandle[0].inquiryInfo.id,
                            balance: element.paymentHandle[0].balance,
                            loadStatus: giftcardItem && giftcardItem.status,
                            loadedAmount: giftcardtransactionItems.filter(x => x.cardNumber == element.paymentHandle[0].inquiryInfo.id)[0].amount
                        })
                    });
                    if (!this.giftCardBalanceList || (this.giftCardBalanceList.length > 0 && this.giftCardBalanceList.some(x => x.balance < x.loadedAmount && x.loadStatus == GiftCardTransactionStatus.TryUnLoad))) {
                        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.CanNotVoidTheTransaction, GlobalConst.ButtonType.Ok);
                        return false;
                    } else {
                        giftcardtransactionItems.map(g => g.retailTicketNumber = retailTicketNumber);
                        this._ss.giftCardListToUnload = giftcardtransactionItems;
                    }
                }
            }
            return true;
        }
    }

    /**
     * @description Performs the giftcard unload
     */
    PerformGiftCardUnLoad() {
        let cardList = _.cloneDeep(this._ss.giftCardListToUnload);
        if (cardList && cardList.length > 0) {
            this.unLoadGiftcardHistory = [];
            this.UnLoadCardHelper(cardList[0], cardList);
        }
    }


    /**
     * @description Helper method used in UnLoad Process
     */
    private UnLoadCardHelper(giftcardItem: GiftCardTransactionItem, giftcardtransactionItems) {
        try {
            //Unload only the successfully loaded cards
            if (giftcardItem.status != GiftCardTransactionStatus.TryUnLoad ||
                (giftcardItem.isIssue && giftcardItem.cardType == GlobalConst.GiftCardType.ExternalGiftCard)) {
                this.ProceedNextCardForUnLoad(giftcardtransactionItems, giftcardItem);
                return;
            }

            const tenderId = this.GetTenderIdByCardType(giftcardItem.cardType);
            const retailTicketNumber = giftcardItem && giftcardItem[0] && giftcardItem[0].retailTicketNumber;
            let saleResponse = this.PerformUnLoad(giftcardItem, tenderId);
            saleResponse.then((response) => {
                if (this.IsRequestSuccess(response)) {
                    let validateRes = this.ValidatePayForUnLoad(giftcardItem, tenderId, response, retailTicketNumber);
                    validateRes.then((resp) => {
                        if (resp.result && resp.result.status) {
                            let pMPayResponse: ValidatePayResponse = resp.result;
                            let isUpdateSuccess = this.UpdateGiftCardTransactionSuccess(giftcardItem.id, pMPayResponse.transactionId, GlobalConst.GiftCardTransactionType.UnLoad);
                            isUpdateSuccess.then((updateResponse) => {
                                if (updateResponse.result) {
                                    this.ProceedNextCardForUnLoad(giftcardtransactionItems, giftcardItem);
                                }
                            });
                        }
                    });
                }
            }).catch((err) => {
                this.HandlePMAgentError(err);
            });
        } catch (error) {
            console.log("load gift card failure:" + error);
        }
    }

    /**
     * @description Determines whether UnLoad is completed and process the next card
     * @param giftcardtransactionItems 
     * @param currentProcessedItem 
     */
    private ProceedNextCardForUnLoad(giftcardtransactionItems: GiftCardTransactionItem[], currentProcessedItem: GiftCardTransactionItem) {
        this.unLoadGiftcardHistory.push({
            cardNumber: currentProcessedItem.cardNumber,
            unLoadSucceeded: true
        });
        if (currentProcessedItem && this.unLoadGiftcardHistory.length < giftcardtransactionItems.length) {
            let giftcardItem = giftcardtransactionItems[giftcardtransactionItems.indexOf(currentProcessedItem) + 1];
            this.UnLoadCardHelper(giftcardItem, giftcardtransactionItems);
        }
    }

    /**
     * @description Communicates with PMAgent to the giftcard balance
     * @param giftcardTransactionItem 
     */
    async GetBalance(giftcardTransactionItem: GiftCardTransactionItem): Promise<any> {
        const tenderId = this.GetTenderIdByCardType(giftcardTransactionItem.cardType);
        let RequestBody: HandleRequest = {
            tenderId: tenderId.toString(),
            inquiryInfo: {
                id: giftcardTransactionItem.cardNumber
            }
        };
        if (this.featureFlagInfo.SkipPMAgent) {
            let request: GiftCardPMRequest = {
                handle: JSON.stringify({
                    id: giftcardTransactionItem.cardNumber,
                    inquiryData: {
                        id: giftcardTransactionItem.cardNumber,
                        cardData: null
                    }
                }),
                Amount: {
                    RequestAmount: "0.00"
                },
                InquirerInfo: {
                    TenderId: String(tenderId),
                    TerminalId: "0",
                    OrderNumber: String(this.utils.GetRandomNumber(7)),
                    Profitcenter: "0",
                    ZoneOffset: this.featureFlagInfo.ZoneOffset
                }
            }
            return this._nonPMAgentHandler.GetBalance(request, this._ss.SelectedOutletId);
        } else {
            return this._payAgentService.GetHandles(RequestBody, this._ss.SelectedOutletId);
        }
    }

    /**
     * @description Performs Sale to reduce the giftcard balance or Return a giftcard is it is Issued while Voiding a Transaction
     * @param giftcardTransactionItem 
     * @param tenderId 
     */
    PerformUnLoad(giftcardTransactionItem: GiftCardTransactionItem, tenderId: PaymentMethods) {
        const cardHandle: HandleResponse = JSON.parse(giftcardTransactionItem.handleInfo);
        if (this.v1giftcardsMethods.includes(tenderId) && giftcardTransactionItem.isIssue) {
            return this._internalGCBusiness.ReturnGiftCard(
                giftcardTransactionItem.cardNumber
                , giftcardTransactionItem.amount
            );
        } else {
            return this.GiftcardActionProcessor(giftcardTransactionItem.cardType).ReverseRecharge(
                giftcardTransactionItem.cardNumber
                , this.GetTicketNumber(giftcardTransactionItem.cardType, giftcardTransactionItem.retailTicketNumber)
                , giftcardTransactionItem.amount
                , giftcardTransactionItem.paymentReferenceId
                , giftcardTransactionItem.handleFromGetHandles ? giftcardTransactionItem.handleFromGetHandles : extractHandle(cardHandle)
                , tenderId
            );
        }
    }


    /**
     * @description Validates the Sale performed while UnLoading a card
     * @param giftcardTransactionItem 
     * @param tenderId 
     * @param saleResponse 
     */
    private ValidatePayForUnLoad(giftcardTransactionItem: GiftCardTransactionItem, tenderId: PaymentMethods, saleResponse, retailTicketNumber) {
        if (this._ss.SelectedOutletId) {
            let validatePayReq: ValidatePayRequest = this.FormValidateGiftcardRequestBody(giftcardTransactionItem, tenderId, saleResponse, GlobalConst.GiftCardTransactionType.UnLoad, retailTicketNumber);
            let validatePayResponse: any = this.http.CallApiAsync<any>({
                host: GlobalConst.Host.payment,
                callDesc: this.v1giftcardsMethods.includes(tenderId) ? "ValidateGiftCard" : "ValidatePay",
                method: HttpMethod.Post,
                body: validatePayReq,
                uriParams: { outletId: this._ss.SelectedOutletId },
                showError: true
            });
            console.log(validatePayResponse.result);
            return validatePayResponse;
        }
    }

    /**
     * @description Performs the Giftcard cashback sale
     * @param selectedGiftCard 
     */
    PerformGiftCardCashBack(selectedGiftCard: GiftcardSearchResult, retailTicketNumber: string, additionalInputsFromFolio?) {
        if (this.GiftcardPopupData && this._cashBackCallRequired && selectedGiftCard) {
            const cashBackAmt: number = this.GiftcardPopupData.cashbackAmt;
            const tenderId = this.GetTenderIdByCardType(selectedGiftCard.cardType);
            //Reset the objects
            this.GiftcardPopupData = null;
            this._cashBackCallRequired = false;            
            this.ShowTransactionInprogressDialog(GlobalConst.GiftCardTransactionType.CashBack);
            if (this.featureFlagInfo.SkipPMAgent) {
                const request = {
                    handle: selectedGiftCard.handle,
                    amount: {
                        requestAmount: Number(cashBackAmt.customToFixed())
                    },
                    inquirerInfo: {
                        tenderId: String(tenderId),
                        terminalId: "0",
                        orderNumber: retailTicketNumber,
                        profitCenter: "0",
                        zoneOffset: this.featureFlagInfo.ZoneOffset,
                        id: selectedGiftCard.cardNumber,
                        employeeId: "0",
                        customer: "0",
                        isPartialTenderAllowed: true,
                        zipcode: selectedGiftCard.zipcode ?? '',
                        clientId: "",
                        postingId: " - #" + retailTicketNumber,
                        store: String(this._ss.SelectedOutletId),
                        stayId: additionalInputsFromFolio?.sourceTypeId ? additionalInputsFromFolio.sourceTypeId?.toString() : ""                        
                    },
                    paymentDetails: {
                        firstName: selectedGiftCard.firstName ?? "",
                        lastName: selectedGiftCard.lastName ?? "",
                        email: selectedGiftCard.email ?? "",
                        phoneNumber: selectedGiftCard.phoneNumber ?? "",
                        roomNumber: additionalInputsFromFolio?.roomNumber ?? "",
                        screenRoute: this._router.url ?? "",
                        sourceType: additionalInputsFromFolio?.sourceType ?? "",
						sourceTypeId: additionalInputsFromFolio?.sourceTypeId ?? "",
						folioNumber: additionalInputsFromFolio?.folioNumber ?? "",
                        postId: additionalInputsFromFolio?.postId ?? ""
                    }
                };
                const validateGiftCardCashbackResponse = this._retailComm.postPromise<ValidatePayResponse>({
                    route: RetailRoutes.GiftcardCashback,
                    body: request
                }, false);
                validateGiftCardCashbackResponse
                    .then((response) => {
                        let validatePayResult: ValidatePayResponse = response;
                        if (validatePayResult.status) {
                            this.ShowTransactionSuccessDialog(GlobalConst.GiftCardTransactionType.CashBack);
                        }
                    })
                    .catch((error) => {
                        console.log('Validate Pay Response:' + error.error);
                    });
            } else {
                let cashBackResponse = this.GiftcardActionProcessor(selectedGiftCard.cardType).PerformCashback(
                    selectedGiftCard.cardNumber
                    , this.GetTicketNumber(selectedGiftCard.cardType, retailTicketNumber)
                    , cashBackAmt
                    , selectedGiftCard.handle
                    , tenderId
                    , selectedGiftCard.zipcode
                );
                cashBackResponse.then((response) => {
                    if (this.IsRequestSuccess(response)) {
                        this.ValidateGiftCardCashback(response, cashBackAmt, selectedGiftCard, retailTicketNumber);
                    } else {
                        this.HandleError(response, tenderId);
                    }
                }).catch(err => {
                    this.dialog.closeAll();
                    this.HandlePMAgentError(err);
                });
            }            
        }
    }

    /**
     * @description Validates the Giftcard cashback request with Payment manager through Payment MS
     * @param pmAgentResponse 
     * @param cashBackAmt 
     * @param selectedGiftCard 
     */
    private ValidateGiftCardCashback(pmAgentResponse, cashBackAmt, selectedGiftCard: GiftcardSearchResult, retailTicketNumber) {
        if (this._ss.SelectedOutletId && selectedGiftCard) {
            console.log(selectedGiftCard.handle);
            let validateGiftCardCashbackReq: CashBackRequest = {
                paymentMethodId: selectedGiftCard.paymentMethodId,
                tenderId: selectedGiftCard.tenderId,
                payAgentResponse: this.v1giftcardsMethods.includes(selectedGiftCard.tenderId) ? null : {
                    payAgentID: pmAgentResponse.payAgentId,
                    status: pmAgentResponse.status,
                    transactionDetails: pmAgentResponse.transactionDetails,
                    transactionKey: pmAgentResponse.transactionKey,
                    errorMessage: pmAgentResponse.errorMessage
                },
                giftCardCashBackPaymentRecord: {
                    cardNumber: selectedGiftCard.cardNumber,
                    expiryDate: selectedGiftCard.expiryDate,
                    firstName: selectedGiftCard.firstName,
                    lastName: selectedGiftCard.lastName,
                    email: selectedGiftCard.email,
                    phoneNumber: selectedGiftCard.phoneNumber,
                    type: selectedGiftCard.cardType,
                    cashBackAmount: cashBackAmt
                },
                retailTicketNumber: retailTicketNumber,
                machineId: this.localization.GetMachineId(),
                hostName: pmAgentResponse?.hostName,
                ipAddress: pmAgentResponse?.ipAddress,
                agentVersion: pmAgentResponse?.agentVersion
            };
            let validateGiftCardCashbackResponse: any = this.http.CallApiAsync<any>({
                host: GlobalConst.Host.payment,
                callDesc: "ValidateGiftCardCashback",
                method: HttpMethod.Post,
                body: validateGiftCardCashbackReq,
                uriParams: { outletId: this._ss.SelectedOutletId },
                showError: true
            });
            console.log(validateGiftCardCashbackResponse.result);

            validateGiftCardCashbackResponse
                .then((response) => {
                    let validatePayResult: ValidatePayResponse = response.result;
                    if (validatePayResult.status) {
                        this.ShowTransactionSuccessDialog(GlobalConst.GiftCardTransactionType.CashBack);
                    }
                })
                .catch((error) => {
                    console.log('Validate Pay Response:' + error.error);
                });
        }
    }


    /**
     * @description Performs the Loading of Giftcards invloved in a transaction
     * @param transactionId 
     */
    async PerformGiftCardIssue(transactionId: number, retailTicketNumber: string) {
        if (transactionId) {
            let giftcardtransactionItems: GiftCardTransactionItem[] = await this.GetGiftcardItemsByActionType(transactionId, GlobalConst.GiftCardTransactionType.Issue);
            if (giftcardtransactionItems && giftcardtransactionItems.length > 0) {
                this.ShowTransactionInprogressDialog(GlobalConst.GiftCardTransactionType.Issue);
                this.issueGiftcardHistory = [];
                this.progressMsg.emit(this.localization.replacePlaceholders(
                    this.captions.ProgressMsg,
                    ["currentIndex", "overAllCount"],
                    [1, giftcardtransactionItems.length]));
                this.IssueCardHelper(giftcardtransactionItems[0], giftcardtransactionItems, retailTicketNumber, transactionId);
            }
        }
    }

    /**
     * @description This is a helper method used for Giftcard Issue
     */
    private IssueCardHelper(giftcardItem: GiftCardTransactionItem, giftcardtransactionItems, retailTicketNumber: string, transactionId: number) {
        try {
            const cardHandle: HandleResponse = JSON.parse(giftcardItem.handleInfo);
            const tenderId = this.GetTenderIdByCardType(giftcardItem.cardType);
            const HandleValidateGCResponse = (resp) => {
                if (resp && resp.status) {
                    let pMPayResponse: ValidatePayResponse = resp;
                    let isUpdateSuccess = this.UpdateGiftCardTransactionSuccess(giftcardItem.id, pMPayResponse.transactionId, GlobalConst.GiftCardTransactionType.Issue);
                    isUpdateSuccess.then((updateResponse) => {
                        this.ProceedNextCardForIssue(giftcardtransactionItems, giftcardItem, updateResponse?.result, '', retailTicketNumber, transactionId);
                    }).catch((err) => { console.log(err); this.ProceedNextCardForIssue(giftcardtransactionItems, giftcardItem, false, "UpdateGiftCardIssueSuccess", retailTicketNumber, transactionId); });
                }
            }
            const guestDetails: GiftCardGuestDetails = {
                firstName: giftcardItem.firstName,
                lastName: giftcardItem.lastName,
                email: cardHandle.email ?? "",
                phoneNumber: cardHandle.phoneNumber ?? ""                
            }
            giftcardItem.email = guestDetails.email;
            giftcardItem.phoneNumber = guestDetails.phoneNumber;
            if (this.featureFlagInfo.SkipPMAgent && giftcardItem.cardType != GlobalConst.GiftCardType.V1GiftCard) {
                const cardHandle: HandleResponse = JSON.parse(giftcardItem.handleInfo);
                let request: GiftCardPMRequest = {
                    handle: cardHandle.paymentHandle[0]?.handle,
                    Amount: {
                        RequestAmount: String(giftcardItem.amount)
                    },
                    InquirerInfo: {
                        TenderId: String(tenderId),
                        TerminalId: "0",
                        OrderNumber: retailTicketNumber,
                        Profitcenter: "0",
                        ZoneOffset: this.featureFlagInfo.ZoneOffset
                    },
                    isCardActive: cardHandle.isCardActive,
                    PaymentDetails: guestDetails as any
                }
                let validateGCResponse = this._nonPMAgentHandler.IssueCard(request, this._ss.SelectedOutletId);
                validateGCResponse.then((resp) => {
                    HandleValidateGCResponse(resp);
                }).catch(() => { this.ProceedNextCardForIssue(giftcardtransactionItems, giftcardItem, false, "ValidateGiftcardRequest", retailTicketNumber, transactionId); });
            } else {
                let loadResponse = this.GiftcardActionProcessor(giftcardItem.cardType).IssueGiftCard(
                    giftcardItem.cardNumber
                    , this.GetTicketNumber(giftcardItem.cardType, retailTicketNumber)
                    , giftcardItem.amount
                    , guestDetails
                    , extractHandle(cardHandle)
                    , tenderId
                    , cardHandle.isCardActive
                );
                loadResponse.then((response) => {
                    if (this.IsRequestSuccess(response)) {
                        let validateRes = this.ValidateGiftcardRequest(giftcardItem, tenderId, response, GlobalConst.GiftCardTransactionType.Issue, retailTicketNumber);
                        validateRes.then((resp) => {
                            HandleValidateGCResponse(resp.result);
                        }).catch(() => { this.ProceedNextCardForIssue(giftcardtransactionItems, giftcardItem, false, "ValidateGiftcardRequest", retailTicketNumber, transactionId); });
                    } else {
                        this.ProceedNextCardForIssue(giftcardtransactionItems, giftcardItem, false, '', retailTicketNumber, transactionId);
                    }
                }).catch((err) => {
                    this.ProceedNextCardForIssue(giftcardtransactionItems, giftcardItem, false, "Issue", retailTicketNumber, transactionId);
                    this.HandlePMAgentError(err);
                });
            }
        } catch (error) {
            console.log("Issue gift card failure:" + error);
        }
    }

    /**
     * @description This is a helper method used for Giftcard Issue if the Load API returns "Card Not Issued" error
     */
    private TryIssueCardOnLoadFailure(giftcardItem: GiftCardTransactionItem, giftcardtransactionItems, retailTicketNumber: string, createRetailitemResponse: object) {
        try {
            const cardHandle: HandleResponse = JSON.parse(giftcardItem.handleInfo);
            const tenderId = this.GetTenderIdByCardType(giftcardItem.cardType);
            const guestDetails: GiftCardGuestDetails = {
                firstName: giftcardItem.firstName,
                lastName: giftcardItem.lastName,
                email: cardHandle.email ?? "",
                phoneNumber: cardHandle.phoneNumber ?? ""
            }
            let loadResponse = this.GiftcardActionProcessor(giftcardItem.cardType).IssueGiftCard(
                giftcardItem.cardNumber
                , this.GetTicketNumber(giftcardItem.cardType, retailTicketNumber)
                , giftcardItem.amount
                , guestDetails
                , extractHandle(cardHandle)
                , tenderId
                , false
            );
            loadResponse.then((response) => {
                if (this.IsRequestSuccess(response)) {
                    let validateRes = this.ValidateGiftcardRequest(giftcardItem, tenderId, response, GlobalConst.GiftCardTransactionType.Issue, retailTicketNumber);
                    validateRes.then((resp) => {
                        if (resp.result && resp.result.status) {
                            let pMPayResponse: ValidatePayResponse = resp.result;
                            let isUpdateSuccess = this.UpdateGiftCardTransactionSuccess(giftcardItem.id, pMPayResponse.transactionId, GlobalConst.GiftCardTransactionType.Issue);
                            isUpdateSuccess.then((updateResponse) => {
                                this.ProceedNextCard(giftcardtransactionItems, giftcardItem, updateResponse?.result, '', retailTicketNumber, createRetailitemResponse);
                            }).catch((err) => { console.log(err); this.ProceedNextCard(giftcardtransactionItems, giftcardItem, false, "UpdateGiftCardIssueSuccess", retailTicketNumber, createRetailitemResponse); });
                        }
                    }).catch(() => { this.ProceedNextCard(giftcardtransactionItems, giftcardItem, false, "ValidateGiftcardRequest", retailTicketNumber, createRetailitemResponse); });
                } else {
                    this.ProceedNextCard(giftcardtransactionItems, giftcardItem, false, '', retailTicketNumber, createRetailitemResponse);
                }
            }).catch((err) => {
                this.ProceedNextCard(giftcardtransactionItems, giftcardItem, false, "Issue", retailTicketNumber, createRetailitemResponse);
                this.HandlePMAgentError(err);
            });
        } catch (error) {
            console.log("Issue gift card failure:" + error);
        }
    }

    /**
     * @description Internal method to trigger the loading of next giftcard
     * @param giftcardtransactionItems 
     * @param giftcardItem 
     * @param updatedStatus 
     * @param processLevel 
     */
    private ProceedNextCardForIssue(giftcardtransactionItems, giftcardItem: GiftCardTransactionItem, updatedStatus: boolean, processLevel: string = "", retailTicketNumber: string, transactionId: number) {
        this.issueGiftcardHistory.push({
            cardNumber: giftcardItem.cardNumber,
            issueSucceeded: updatedStatus,
            process: processLevel,
            amount: this.localization.localizeCurrency(giftcardItem.amount, true),
            type: giftcardItem.cardType
        });
        this.CheckOverallIssueStatusAndShowDialog(this.issueGiftcardHistory, giftcardtransactionItems, giftcardItem, retailTicketNumber, transactionId);
    }

    /**
     * @description This method calculates whether the load process is completed and displays popup accordingly
     * @param loadGiftcardHistory 
     * @param giftcardtransactionItems 
     * @param currentProcessedItem 
     */
    private CheckOverallIssueStatusAndShowDialog(issueGiftcardHistory, giftcardtransactionItems: GiftCardTransactionItem[], currentProcessedItem, retailTicketNumber, transactionId) {
        this.progressMsg.emit(this.localization.replacePlaceholders(
            this.captions.ProgressMsg,
            ["currentIndex", "overAllCount"],
            [issueGiftcardHistory.length, giftcardtransactionItems.length]));
        this.progressPercent.emit((Number(issueGiftcardHistory.length / giftcardtransactionItems.length) * 100) + 7);
        if (issueGiftcardHistory.length == giftcardtransactionItems.length) {
            this.IssuanceProgressCompleted.emit({
                retailTicketNumber: retailTicketNumber,
                transactionId: transactionId
            });
            if (issueGiftcardHistory.every(x => x.issueSucceeded === true) && transactionId > 0) {
                this.ShowTransactionSuccessDialog(GlobalConst.GiftCardTransactionType.Issue);
            } else {
                this.ShowFailureDialog(GlobalConst.GiftCardTransactionType.Issue, this.FormGiftcardFailureMsg(issueGiftcardHistory, GlobalConst.GiftCardTransactionType.Issue));
                console.table(issueGiftcardHistory);
            }
        } else {
            if (currentProcessedItem) {
                let giftcardItem = giftcardtransactionItems[giftcardtransactionItems.indexOf(currentProcessedItem) + 1];
                this.IssueCardHelper(giftcardItem, giftcardtransactionItems, retailTicketNumber, transactionId);
            }
        }
    }

    private FormGiftcardFailureMsg(giftcardHistory, action: GlobalConst.GiftCardTransactionType) {
        let failedV1Cards = [], failedExternalCards = [], failureMsg = ''
            , flag = action == GlobalConst.GiftCardTransactionType.Issue ? 'issueSucceeded' : 'loadSucceeded';
        giftcardHistory.map(x => {
            if (!x[flag]) {
                if (x.type == GlobalConst.GiftCardType.V1GiftCard) {
                    failedV1Cards.push(`<li>${x.cardNumber} | ${x.amount}</li>`);
                } else {
                    failedExternalCards.push(`<li>${x.cardNumber} | ${x.amount}</li>`);
                }
            }
        });
        if (failedV1Cards.length > 0) {
            failureMsg += `${this.localization.captions.setting.V1}: <ul> ${failedV1Cards.join('')} </ul>`
        }
        if (failedExternalCards.length > 0) {
            failureMsg += `${this.GiftcardVendorName}: </br><ul> ${failedExternalCards.join('')} </ul>`
        }
        return failureMsg;
    }

    /**
     * @description Performs Giftcard recharge for Giftcards which doesn't have Credit API Support by PMAgent, 
     *    If the given card is not Activated, it will issue the
     * @param scope scope of the component which invokes this method
     * @param compScope scope of the component which invokes this method
     * @param creditAmount amount to be recharged to Giftcard
     * @param isReturnScreen set this as true if invoked from RWT screen 
     */
    PerformGiftCardCredit($scope, compScope, creditAmount: number, selectedGiftCard: GiftcardSearchResult, isReturnScreen: boolean = false) {
        if ($scope && compScope && selectedGiftCard) {
            const tenderId = this.GetTenderIdByCardType(selectedGiftCard?.cardType);
            const giftcardAction = selectedGiftCard?.isCardActive ? GlobalConst.GiftCardTransactionType.Load : GlobalConst.GiftCardTransactionType.Issue;
            let actionRes;
            let retailTicketNumber = !isReturnScreen ? compScope["CreateRetailItemResponse"]?.transactionData?.retailTicketNumber
                : $scope["ReturnWithTicketResponse"]?.transactionData?.retailTicketNumber;
            retailTicketNumber = retailTicketNumber ? retailTicketNumber : "0";
            this.ShowTransactionInprogressDialog(giftcardAction);
            const guestDetails: GiftCardGuestDetails = {
                firstName: selectedGiftCard.firstName,
                lastName: selectedGiftCard.lastName,
                email: selectedGiftCard.email ?? "",
                phoneNumber: selectedGiftCard.phoneNumber ?? ""
            }
            if (this.featureFlagInfo.SkipPMAgent && selectedGiftCard?.cardType != GlobalConst.GiftCardType.V1GiftCard) {
                let giftcarddetails: any = selectedGiftCard;
                giftcarddetails.handleInfo = selectedGiftCard.handle;
                const cardHandle: HandleResponse = JSON.parse(giftcarddetails.handleInfo);
                let request: GiftCardPMRequest;
                let validateGCResponse: Promise<ValidatePayResponse>;
                if (selectedGiftCard?.isCardActive) {
                    request = {
                        handle: selectedGiftCard.handle,
                        Amount: {
                            RequestAmount: String(Math.abs(creditAmount))
                        },
                        InquirerInfo: {
                            TenderId: String(tenderId),
                            TerminalId: "0",
                            OrderNumber: retailTicketNumber,
                            Profitcenter: "0",
                            ZoneOffset: this.featureFlagInfo.ZoneOffset
                        },
                        PaymentDetails: {
                            firstName: selectedGiftCard.firstName,
                            lastName: selectedGiftCard.lastName,
                            email: cardHandle.email,
                            phoneNumber: cardHandle.phoneNumber
                        } as any
                    }
                    validateGCResponse = this._nonPMAgentHandler.LoadCard(request, this._ss.SelectedOutletId);
                } else {
                    request = {
                        handle: selectedGiftCard.handle,
                        Amount: {
                            RequestAmount: String(Math.abs(creditAmount))
                        },
                        InquirerInfo: {
                            TenderId: String(tenderId),
                            TerminalId: "0",
                            OrderNumber: retailTicketNumber,
                            Profitcenter: "0",
                            ZoneOffset: this.featureFlagInfo.ZoneOffset
                        },
                        isCardActive: cardHandle.isCardActive,
                        PaymentDetails: guestDetails as any
                    }
                    validateGCResponse = this._nonPMAgentHandler.IssueCard(request, this._ss.SelectedOutletId);
                }

                validateGCResponse.then((resp) => {
                    this.HandlePostGiftcardCredit(creditAmount, selectedGiftCard, resp, compScope, $scope, isReturnScreen);
                }).catch((error) => {
                    $scope._isAPICallInProgress = false;
                    console.log('Validate Pay Response:' + error.error);
                    $scope.HandlePostSaleFailure(error);
                });
            } else {
                if (selectedGiftCard?.isCardActive) {
                    actionRes = this.GiftcardActionProcessor(selectedGiftCard?.cardType).RechargeGiftCard(
                        selectedGiftCard?.cardNumber
                        , this.GetTicketNumber(selectedGiftCard.cardType, retailTicketNumber)
                        , Math.abs(creditAmount)
                        , selectedGiftCard?.handle
                        , tenderId)
                } else {
                    actionRes = this.GiftcardActionProcessor(selectedGiftCard?.cardType).IssueGiftCard(
                        selectedGiftCard?.cardNumber
                        , this.GetTicketNumber(selectedGiftCard.cardType, retailTicketNumber)
                        , Math.abs(creditAmount)
                        , guestDetails
                        , selectedGiftCard?.handle
                        , tenderId)
                }

                actionRes.then((response) => {
                    if (this.IsRequestSuccess(response)) {
                        let giftcarddetails: any = selectedGiftCard;
                        giftcarddetails.handleInfo = selectedGiftCard.handle;
                        if (!selectedGiftCard.isCardActive) {
                            selectedGiftCard.amount = selectedGiftCard.UpdatedBalance = Math.abs(creditAmount);
                            giftcarddetails = selectedGiftCard;
                        } else {
                            giftcarddetails = _.cloneDeep(selectedGiftCard);
                            giftcarddetails.amount = creditAmount;
                        }
                        let validateGCResponse = this.ValidateGiftcardRequest(
                            giftcarddetails as any,
                            tenderId,
                            response,
                            giftcardAction,
                            retailTicketNumber);
                        validateGCResponse.then((resp) => {
                            this.HandlePostGiftcardCredit(creditAmount, selectedGiftCard, resp.result, compScope, $scope, isReturnScreen);
                        }).catch((error) => {
                            $scope._isAPICallInProgress = false;
                            console.log('Validate Pay Response:' + error.error);
                            $scope.HandlePostSaleFailure(error);
                        });
                    } else {
                        $scope._isAPICallInProgress = false;
                        this.HandleError(response, tenderId);
                    }
                }).catch((err) => {
                    console.log(err);
                    $scope._isAPICallInProgress = false;
                    $scope.utils.ShowError(
                        $scope.localization.captions.common.Error,
                        $scope.utils.replacePlaceholders(
                            $scope.ShopCaptions.PaymentTransactionFailureMsg,
                            ['paymentmethod'],
                            [$scope.ShopCaptions.paymentMethods[$scope.selectedpayment.paymentTypeId]]
                        )
                    );
                });
            }
        }
    }

    HandlePostGiftcardCredit(creditAmount, selectedGiftCard, resp, compScope, $scope, isReturnScreen) {
        let validatePayResult: ValidatePayResponse = resp;
        let isFolioPosting = $scope?.IsFolioPosting ? $scope?.IsFolioPosting : false;
        if (validatePayResult.status && !isFolioPosting) {
            if (isReturnScreen) {
                $scope._isAPICallInProgress = false;
                $scope.AddSettlemtAndShowDialog("", compScope, validatePayResult,);
                compScope.RefundSale(validatePayResult.transactionId);
            } else {
                $scope.HandlePayRequest(validatePayResult, "");
            }
        } else if (isFolioPosting) {
            this.dialog.closeAll();
            const clickReturnValue = {
                from: ActionMode.create,
                formValues: compScope.transitionDetails.getRawValue(),
                form: compScope.transitionDetails,
                transactionId: validatePayResult.transactionId,
                maskedCardNumber: selectedGiftCard.maskedCardNumber,//SaleResult && SaleResult.account && SaleResult.account.id,
                totalAuthorizedAmount: validatePayResult.paymentManagerResponse && validatePayResult.paymentManagerResponse.amount,
                checkReponse: null,
                selectedPayment: compScope.selectedpayment,
                saleAmount: creditAmount,
                arAccountNumber: '',
                logType: "gift card top up"
            };
            $scope?.$scope?.TransactionCompleted.emit(clickReturnValue);
            return;
        }
    }

    /**
     * @description Activates or Deactivates the Agilysys Giftcard
     * @param giftcarddetails 
     * @param cardStatusToBeUpdated 
     */
    PerformGiftcardActivationDeactivation(giftcarddetails: GiftCardInfo, cardStatusToBeUpdated: GlobalConst.GiftCardStatus) {
        if (this.IsAgilysysGiftcard && giftcarddetails && giftcarddetails.HandleInfo) {
            if (cardStatusToBeUpdated === GlobalConst.GiftCardStatus.Active) {
                this._externalGCBusiness.ActivateGiftCard(
                    giftcarddetails.CardNumber
                    , giftcarddetails.HandleInfo.paymentHandle[0].handle
                    , giftcarddetails.HandleInfo.tenderId
                );
            }
        }
    }

    IsRequestSuccess(response): boolean {
        const NO_ERROR = 0;
        return response && ((response.result && response.result.returnCode == NO_ERROR)
            || (response.status && response.status.toLocaleLowerCase() == HttpResponseStatus.Success));
    }

    GetTenderIdByCardType(cardType: GlobalConst.GiftCardType) {
        return Number(cardType) == GlobalConst.GiftCardType.ExternalGiftCard ? this.ThirdPartyTenderByVendor : PaymentMethods.V1GiftCard;
    }

    HandleError(errorResponse, tenderId) {
        console.log({ errorResponse });
        this.dialog.closeAll();
        if (this.v1giftcardsMethods.includes(tenderId) && errorResponse) {
            const errorMsg = errorResponse.result && errorResponse.result.message;
            return this.utils.showError(this.GetV1GCErrorMsg(errorMsg, errorResponse?.result?.returnCode));
        } else {
            this.HandlePMAgentError(errorResponse);
        }
    }

    GetV1GCErrorMsg(errorMsg, errorCode?) {
        let properMsg = errorMsg;
        if (errorMsg) {
            const tempMsg = errorMsg.toLowerCase().replace(/\s/g, "");
            if (this.localization.captions.shop.GiftCard.V1GCErrorMessages[tempMsg]) {
                properMsg = this.localization.captions.shop.GiftCard.V1GCErrorMessages[tempMsg];
            }
        }
        if (!errorMsg && errorCode) {
            properMsg = String(GlobalConst.V1GiftcardErrorCodes[errorCode]);
        }
        return properMsg;
    }

    GetTicketNumber(cardType: GlobalConst.GiftCardType, retailTicketNumber: string) {
        return cardType == GlobalConst.GiftCardType.ExternalGiftCard && this.IsAgilysysGiftcard
            ? retailTicketNumber : String(this.utils.GetRandomNumber(7))
    }

}

export function hasValue(obj) { return (typeof (obj) != 'undefined') }

export function extractHandle(cardHandle) { return (cardHandle && cardHandle.paymentHandle != null) ? cardHandle.paymentHandle[0] && cardHandle.paymentHandle[0].handle : ""; }

export const SearchScreen = {
    IssuePopup: 1,
    BalanceCheck: 2,
    OrderSummary: 3
}