import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReservationComponent } from './reservation.component';

const ReservationRoutes: Routes = [
    {
        path: '',
        component: ReservationComponent,
        //children: [
        //    {
        //        path: 'ss',
        //        component: ReservationComponent

        //    },
        //]
    }
]

@NgModule({
    imports: [
    RouterModule.forChild(ReservationRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: []
})

export class ReservationRoutingModule {

}
