<section class="payment-feature-config newVersionWrapper">
    <div class="config-options">
        <ul class="LW14">
            <li *ngFor="let option of featureList" id={{option.id}} (click)="selectfeature(option)"
                [ngClass]="{'selected': option.id == selectedFeature?.id}">
                <div *ngIf="option.id == selectedFeature?.id" class="arrow-section border-left-color"></div>
                <span class="" [ngClass]="{'pl-2': option.id != selectedFeature?.id}">
                    {{(captions.RetailFeature &&
                    captions.RetailFeature[option.featureName])?captions.RetailFeature[option.featureName]:option.featureName}}
                </span>
            </li>
        </ul>
    </div>
    <div class="feature-edit-cont">
        <section class="creditcard-info">
            <div class="creditcard-details"
                *ngIf="selectedFeature?.featureName != featureNameConst.OfferManagementSystem && selectedFeature?.featureName != featureNameConst.MultiPMSRoomGroupCharge">
                <ng-scrollbar >
                    <form [formGroup]="paymentFeatureConfig" autocomplete="off" [ngClass]="viewOnly ? 'view-only' : ''">

                        <ng-container *ngIf=" paymentFeatureConfig.get('ACTIVATE_INTERFACE').value">
                            <h5 class="LWB16 mb-3 ">{{captions.ConfigValues}}</h5>
                            <div *ngFor="let item of paymentFeatureConfig.get('ConfigPairs')['controls']; let i = index;let last=last;let first= first"
                                formArrayName="ConfigPairs" class="w-100">
                                <div class="align-items-center d-flex">
                                <mat-form-field class="" [formGroupName]="i" [floatLabel]="floatLabel">
                                    <input [attr.automationId]="'Txt_paymentFeaturesConfig_gatewayKey'" [matTooltipClass]="'AgysMatCustTooltip'"
                                        matTooltip="{{item.controls.gateway_key.value}}" matInput name="key"
                                        placeholder="{{captions.Key}}" (keypress)="keyPress($event)" [maxlength]="200"
                                        formControlName="gateway_key">
                                </mat-form-field>
                                <mat-form-field class=" gateway-values ml-2" [formGroupName]="i"
                                    [floatLabel]="floatLabel">
                                    <input  [attr.automationId]="'Txt_paymentFeaturesConfig_gatewayValue'" [matTooltipClass]="'AgysMatCustTooltip'"
                                        matTooltip="{{item.controls.gateway_value.value}}" matInput name="value"
                                        placeholder="{{captions.Value}}" (keypress)="keyPress($event)" [maxlength]="200"
                                        formControlName="gateway_value">
                                </mat-form-field>
                                <span class="icon-Minus themecolor" (click)="removeConfigPairs(i)"
                                    *ngIf="!(first && last)"></span>
                                <span class="icon-Plus themecolor"
                                    (click)="addConfigPairs(i,'','',selectedFeature?.id,true)" *ngIf="last"></span>
                                </div>
                                <mat-error *ngIf="paymentFeatureConfig.controls['ConfigPairs']['controls'][i].invalid">
                                    {{ getErrorMessage('ConfigPairs', i) }}
                                </mat-error>
                            </div>
                        </ng-container>
                        <input [attr.automationId]="'Txt_paymentFeaturesConfig_submit'" type="submit" [disabled]="!isUpdateEnabled" class="hidden-submit" tabindex="-1"
                            (click)="SaveAction()" />
                    </form>
                </ng-scrollbar>
            </div>
            <div class="creditcard-details"
                *ngIf="selectedFeature?.featureName == featureNameConst.OfferManagementSystem"
                [formGroup]="offerManagementFormGroup">
                <ng-scrollbar >
                    <div [ngClass]="viewOnly ? 'view-only' : ''">
                        <ng-container
                            *ngFor="let offer of offerManagementFormGroup.get('offer')['controls']; let index = index; let last=last;let first= first"
                            formArrayName="offer">
                            <div class="w-100">
                                <div class="management-section">
                                    <h3 class="font-bold mb-3 ">Offer Management System {{index + 1}}</h3>
                                    <ng-container [formGroupName]="index">
                                        <h5 class="LWB16 mb-3 ">{{captions.ConfigValues}}</h5>
                                        <div *ngFor="let item of offer.get('ConfigPairs')['controls']; let i = index;let last=last;let first= first"
                                            formArrayName="ConfigPairs" class="">
                                            <div class="align-items-center d-flex">
                                            <mat-form-field class="gateway-valuesmgt ml-2" [formGroupName]="i"
                                                [floatLabel]="floatLabel">
                                                <input [matTooltipClass]="'AgysMatCustTooltip'" [attr.automationId]="'Txt_paymentFeaturesConfig_gatewayKey1'"
                                                    matTooltip="{{item.controls.gateway_key.value}}" matInput name="key"
                                                    placeholder="{{captions.Key}}" (keypress)="keyPress($event)"
                                                    [maxlength]="200" formControlName="gateway_key">
                                            </mat-form-field>
                                            <mat-form-field class=" gateway-valuesmgt ml-2" [formGroupName]="i"
                                                [floatLabel]="floatLabel">
                                                <input [matTooltipClass]="'AgysMatCustTooltip'" [attr.automationId]="'Txt_paymentFeaturesConfig_gatewayValue'"
                                                    matTooltip="{{item.controls.gateway_value.value}}" matInput
                                                    name="value" placeholder="{{captions.Value}}"
                                                    (keypress)="keyPress($event)" [maxlength]="200"
                                                    formControlName="gateway_value">
                                            </mat-form-field>
                                            <span class="icon-Minus themecolor"
                                                (click)="onRemoveConfigPairFromFeatureGroup(offerManagementFormGroup,'offer',i, index)"
                                                *ngIf="!(first && last)"></span>
                                            <span class="icon-Plus themecolor"
                                                (click)="onAddConfigPairFromFeatureGroup(offerManagementFormGroup,'offer',i,'','',selectedFeature?.id,true, index)"
                                                *ngIf="last"></span>
                                            </div>
                                            <mat-error *ngIf="offer.controls['ConfigPairs']['controls'][i].invalid">
                                                {{ getErrorMessage('ConfigPairs', i) }}
                                            </mat-error>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="d-inline-block">
                                    <span class="icon-Minus themecolor"
                                        (click)="onRemoveConfigPairFromFeatureGroup(offerManagementFormGroup,'offer',index, null)"
                                        *ngIf="!(first && last)"></span>
                                    <span class="icon-Plus themecolor"
                                        (click)="onAddConfigPairFromFeatureGroup(offerManagementFormGroup,'offer',index,'','',selectedFeature?.id,true, index+1)"
                                        *ngIf="last"></span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-scrollbar>
            </div>
            <div class="creditcard-details"
                *ngIf="selectedFeature?.featureName == featureNameConst.MultiPMSRoomGroupCharge"
                [formGroup]="multiPMSFormGroup">
                <ng-scrollbar >
                    <div [ngClass]="viewOnly ? 'view-only' : ''">
                        <ng-container
                            *ngFor="let prop of multiPMSFormGroup.get('propertyList')['controls']; let index = index; let last=last;let first= first"
                            formArrayName="propertyList">
                            <div class="w-100">
                                <div class="management-section" [formGroupName]="index">
                                    <div class="d-flex">
                                        <div>
                                            <h3 class="font-bold mb-3 ">{{localization.captions.lbl_Property}} {{index +1}}</h3>
                                        </div>
                                        <div class="togggle-grp d-flex">
                                            <div class="toggle-cont d-flex">
                                                <div>{{captions.defaultGroupCharge}}</div>
                                                <app-retail-toggle-switch [attr.automationId]="'Txt_paymentFeaturesConfig_defaultGrpCharge'"
                                                    formControlName="defaultGroupCharge"
                                                    (changeToggleEvent)="changeGroupChargeDefault($event, prop, index)"
                                                    class="toggle-switch">
                                                </app-retail-toggle-switch>
                                            </div>
                                            <div class="toggle-cont d-flex">
                                                <div>{{captions.defaultRoomCharge}}</div>
                                                <app-retail-toggle-switch [attr.automationId]="'Txt_paymentFeaturesConfig_defaultRoomCharge'"
                                                    formControlName="defaultRoomCharge"
                                                    (changeToggleEvent)="changeRoomChargeDefault($event, prop, index)"
                                                    class="toggle-switch">
                                                </app-retail-toggle-switch>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container>
                                        <h5 class="LWB16 mb-3 ">{{captions.ConfigValues}}</h5>
                                        <div *ngFor="let item of prop.get('ConfigPairs')['controls']; let i = index;let last=last;let first= first"
                                            formArrayName="ConfigPairs" class="">
                                            <mat-form-field class="gateway-valuesmgt ml-2" [formGroupName]="i"
                                                [floatLabel]="floatLabel">
                                                <input [matTooltipClass]="'AgysMatCustTooltip'" [attr.automationId]="'Txt_paymentFeaturesConfig_gateway_key1'"
                                                    matTooltip="{{item.controls.gateway_key.value}}" matInput name="key"
                                                    placeholder="{{captions.Key}}" (keypress)="keyPress($event)"
                                                    [maxlength]="200" formControlName="gateway_key">
                                            </mat-form-field>
                                            <mat-form-field class=" gateway-valuesmgt ml-2" [formGroupName]="i"
                                                [floatLabel]="floatLabel">
                                                <input [matTooltipClass]="'AgysMatCustTooltip'"
                                                    matTooltip="{{item.controls.gateway_value.value}}" matInput
                                                    name="value" placeholder="{{captions.Value}}"
                                                    (keypress)="keyPress($event)" [maxlength]="200" [attr.automationId]="'Txt_paymentFeaturesConfig_gatewayValue2'"
                                                    formControlName="gateway_value">
                                            </mat-form-field>
                                            <span class="icon-Minus themecolor"
                                                (click)="onRemoveConfigPairFromFeatureGroup(multiPMSFormGroup,'propertyList',i, index)"
                                                *ngIf="!(first && last)"></span>
                                            <span class="icon-Plus themecolor"
                                                (click)="onAddConfigPairFromFeatureGroup(multiPMSFormGroup,'propertyList',i,'','',selectedFeature?.id,true, index)"
                                                *ngIf="last"></span>
                                            <mat-error *ngIf="prop.controls['ConfigPairs']['controls'][i].invalid">
                                                {{ getErrorMessage('ConfigPairs', i) }}
                                            </mat-error>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="d-inline-block">
                                    <span class="icon-Minus themecolor"
                                        (click)="onRemoveConfigPairFromFeatureGroup(multiPMSFormGroup,'propertyList',index, null)"
                                        *ngIf="!(first && last)"></span>
                                    <span class="icon-Plus themecolor"
                                        (click)="onAddConfigPairFromFeatureGroup(multiPMSFormGroup,'propertyList',index,'','',selectedFeature?.id,true, index+1)"
                                        *ngIf="last"></span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-scrollbar>
            </div>
            <div class="prop-actions">
                <div>
                    <button [ngClass]="isUpdateEnabled ? 'spa-primary-default-save' : 'spa-primary-disabled'"
                        [disabled]="!isUpdateEnabled" class="LW14 save" mat-button [attr.automationId]="'Btn_paymentFeaturesConfig_save'"
                        (click)="SaveAction()">{{captions.save}}</button>
                    <a class="LW14 text-default-color" (click)="cancel()">{{captions.cancel}}</a>
                </div>
            </div>
        </section>
    </div>
</section>
