import { Component, OnInit, Input } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';

@Component({
  selector: 'app-retail-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss']
})
export class RetailNoDataFoundComponent implements OnInit {
@Input() isSearch;
@Input() schedule;
@Input() title;
@Input() text;
captions: any;
  constructor(private _Localization: RetailLocalization) { }

  ngOnInit() {
    this.captions = this._Localization.captions.common;
  }

}
