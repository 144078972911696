<div class="retail-CustomDataTable" id="table" (window:resize)="viewCheckedFlag = false">
  <form class="w-100 h-100" [formGroup]="table">
    <div class="page-header" id="pageHeader" (window:resize)="calculateWidth()">
      <!--Added the following for Retail Pages-->
      <div *ngIf="customHeader" class="custom-retail-inputs">
        <app-retail-table-header [headerName]="pageTitle" [setupFormGroup]="formGroupName" [isViewOnly]="IsViewOnly"
          [maxInputLength]="inputLength" [setupName]="setupName" [errorText]="errorMessage" [enableToggleButton]="enableToggleButton"
          [active]="captions.setting.Active" [isValidRoleName]="isValidRoleName" (buttonClick)="onButtonClick()"
          [headerButtonName]="customHeaderButton" [isCancelButtonAvailable]="isCancelAvailable" (inputChange)="checkValid($event)"
          (validToggleState)="isValidRoleName = true;" (buttonCancelClick)="onButtonCancelClick($event)" [searchText]="searchText"
          [cancelButtonName]="captions.retailsetup.CANCEL" [searchPlaceholderValue]="PlaceHoldertext" (searchInputChange)="searchInputValue($event)"
          (searchKeyPress)="currentPage=1" (resetSearchValue)="clearSearchText()">
        </app-retail-table-header>
      </div>
      <!--- end -->
    </div>
    <div class="fixed-table-container" id="sub-category-table-container" [ngClass]="{'noSort': bodyArray.length < 1}">
      <div class="w-100 position-absolute grid-table" id="SPACustomTable" >
          <table aria-describedby="" class="table mb-0 tbl-cellspacing" id="table_{{setId}}">
            <thead>
              <tr id="SPAHeaderRow">
                <th scope='col'  *ngIf="EditMoreOption && SelectRow" class="pr-3 table-checkbox noellipsis">
                  <div class="th-inner">
                    <mat-checkbox [disabled]="isReadOnly || ((bodyArray | filterPipe: searchText : hdrkeyArray).length <= 0)"
                      (change)="RowSelect($event,bodyArray,'All')" formControlName="IsCheckAll"></mat-checkbox>
                  </div>
                </th>
                <th scope='col'  *ngFor="let hdrCnt of hdrArray;let hi=index" id="header{{hi}}" [ngClass]="[hdrCnt.alignType=='right' ? 'text-right' : hdrCnt.alignType=='center' ? 'text-center':'',hdrCnt.sortable != undefined ? (hdrCnt.sortable ? '': 'pointerevents-none') : '']"
                  [class.cursordefault]="isEditModeSet ? true : hdrCnt.sortable != undefined ? (hdrCnt.sortable ? false: true) : false"
                  [class.cursor]="isEditModeSet ? false : hdrCnt.sortable != undefined ? (hdrCnt.sortable ? true: false) : true" (click)="sortingFunc(hdrCnt.jsonkey,hi,'Frmtable', hdrCnt.sortcolumn, hdrCnt.sortcolumndatatype)">
                  <div class="th-inner" [class.font-weight-bold]="selectedDefaultHeader == hdrCnt.jsonkey && (bodyArray | filterPipe: searchPipeText : hdrkeyArray).length > 0 && hdrCnt.sortable != undefined ? hdrCnt.sortable : (selectedDefaultHeader == hdrCnt.jsonkey && bodyArray.length > 0)">
                    {{hdrCnt.title}}
                    <span class="pl-1" *ngIf="hdrCnt.jsonkey != ''">
                      <i  aria-hidden="true" id="sortArrow{{hi}}" *ngIf="(hdrCnt.sortable != undefined ? hdrCnt.sortable : true) && ((bodyArray | filterPipe: searchPipeText : hdrkeyArray).length > 0)"
                        [ngStyle]="{'opacity':selectedDefaultHeader == hdrCnt.jsonkey  && !isEditModeSet  ? '1' : '0.3' }"
                        [ngClass]="selectedDefaultHeader == hdrCnt.jsonkey && orderType == 'asc' ? 'icon-filled_up_arrow position-absolute deg0 IC6': selectedDefaultHeader == hdrCnt.jsonkey && orderType =='desc' ?
                            'icon-filled_up_arrow deg180 position-absolute IC6': 'icon-sortArrow sort-icon'">
                      </i>
                    </span>
                  </div>
                </th>
                <th  scope='col' style="width: 25px;" *ngIf="SelectedSettingId != GridType.retail"></th>
                <th scope='col'  *ngIf="!isRoleSetUpReadOnly && EnableActions" [ngClass]="{'column-sticky': sticky, 'minwidth177':DoneCancel, 'minwidth100':!DoneCancel}">
                  <div class="th-inner  ">{{captions.setting.Actions}}</div>
                </th>
              </tr>
            </thead>
            <tbody class=" h-100" flex formArrayName="tablebody">
              <tr draggable [dragEnabled]="blnDraggable" [dragData]="bodyCnt" [dragHandle]="'.draggable'" [dropEnabled]="blnDraggable" droppable (OnDragOver)="onDragOver($event,bodyCnt,bodyArray)" (onDrag)="onDrag($event,bodyCnt,bodyArray)"
              (onDrop)="onItemDrop($event,bodyCnt,bodyArray)" [ngClass]="{'highlight' :((NewerData[0] === bodyCnt) || (SelectedData === bodyCnt)), 'onEdit':isEdit, 'editRow':editableRow == bodyCnt.id, 'rowDisabled': (enableRowCheck | enablerow : bi : options : editEvent)}"
                id="{{bodyCnt.id}}" *ngFor="let bodyCnt of (bodyArray | filterPipe: searchPipeText : hdrkeyArray | sortPipe : orderType : selectedDefaultHeader : overriddenSortColumn : sortColumnDataType);let bi=index;"
                [formGroupName]="bi">
                <td *ngIf="EditMoreOption && SelectRow && GridType.quickSale == SelectedSettingId" class="pr-3 table-checkbox noellipsis">
                  <mat-checkbox (change)="RowSelect($event,bodyCnt,'Single')" [checked]="(SelectedData.indexOf(bodyCnt) != -1)"
                    [disabled]="isReadOnly"></mat-checkbox>
                </td>
                <td *ngFor="let hdrCnt of hdrArray;let hi=index" [ngClass]="{ 'text-right': hdrCnt.alignType=='right','text-center': hdrCnt.alignType=='center', 'pr30': hdrCnt.sortable}"
                  class="table-data">
                  <span class="content d-inline-block textellipsis" id="content" [matTooltipDisabled]="bodyCnt[hdrCnt.jsonkey] ? hdrCnt.jsonkey == 'inactive' || hdrCnt.jsonkey == 'active'|| (hdrCnt.type && hdrCnt.type == 'toggle') || (bodyCnt[hdrCnt.jsonkey].toString()).length < 40 : true"
                    matTooltip="{{hdrCnt.datatype && hdrCnt.datatype == 'money' ? localization.localizeCurrency(bodyCnt[hdrCnt.jsonkey], false):bodyCnt[hdrCnt.jsonkey]}}"
                    matTooltipClass="tooltipClass">
                    <div class="" [ngClass]="IsViewOnly || isRoleSetUpReadOnly || hdrCnt.jsonkey == 'isInActive' ? 'button_invalid' : ''"
                      *ngIf="hdrCnt.jsonkey == 'inactive' || hdrCnt.jsonkey == 'active' || (hdrCnt.type && hdrCnt.type == 'toggle')">
                      
                      <ui-switch [attr.automationId]="'Tog_retailSubCategory_isActive'" class="LW14 switch-toggle" [(ngModel)]="bodyCnt[hdrCnt.jsonkey]" [ngModelOptions]="{standalone: true}" [disabled]="isEditModeSet && edittedIndex != bi"  size="small" checkedLabel={{localization.captions.common.Yes}} uncheckedLabel={{localization.captions.common.No}} (change)="showInactiveRoles(bodyCnt[hdrCnt.jsonkey], bodyCnt, bi)" [name]="toggleDisplayText"></ui-switch>
                    </div>
                    <!-- For quick sale items -->
                    <span *ngIf="hdrCnt.jsonkey == 'category' && SelectedSettingId == GridType.quickSale">
                      <mat-form-field [ngClass]="{hidePlaceHolder: bodyCnt.category != ''}" [floatLabel]="floatLabel">
                        <mat-select (selectionChange)="optionChange($event, SelectedSettingId,bodyCnt)" [(value)]="bodyCnt[hdrCnt.jsonkey]">
                          <mat-option [value]="option.viewValue" *ngFor="let option of dropdownOptions">
                            {{option.viewValue}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>
                    <span *ngIf="!(CheckInnerBoolValue | checkinnerbool : bodyCnt[hdrCnt.jsonkey])">
                      <span *ngIf="hdrCnt.datatype && hdrCnt.datatype == 'money' && !(IfBooleanCheck | ifboolean : bodyCnt[hdrCnt.jsonkey])">
                        {{bodyCnt[hdrCnt.jsonkey] | Currency}}
                      </span>
                      <span *ngIf="!hdrCnt.datatype && !(IfBooleanCheck | ifboolean : bodyCnt[hdrCnt.jsonkey]) && hdrCnt.jsonkey != 'printQty' && hdrCnt.jsonkey != 'category'">
                        <span [ngClass]="{'anchor-like': hdrCnt.type == 'clickable'}" (click)="lblclick(bodyCnt, bi , hdrCnt.type)">
                          {{(SetColumnGridValue | setcolumnvalue : bodyCnt : hdrCnt.jsonkey)}}
                        </span>
                      </span>
                    </span>
                  </span>
                </td>
                <td style="width: 25px" *ngIf="SelectedSettingId != GridType.retail"></td>
                <td *ngIf="!isRoleSetUpReadOnly && EnableActions && !(SelectedSettingId == GridType.password)"
                  [ngClass]="{'column-sticky': sticky,'minwidth177':DoneCancel}" class="noellipsis">
                    <!-- Icon Edit -->
                 
                  <i  [attr.automationId]="'Icn_retailSubCategory_edit'" aria-hidden="true" class="icon-Edit cursor" [ngClass]="(IsViewOnly && IsRetailCodeSetup)||disableEditButton || isEditModeSet ? 'button_invalid' : ''"
                    [class.cursor]="!isEditModeSet" [class.cursordefault]="isEditModeSet"
                    *ngIf="editable && !table.value.tablebody[bi].donecancel" matTooltip="{{captions.common.Edit}}"
                    (click)="editRow($event,bodyCnt,'edit',options[0].ServiceId)" ></i>
                    <!-- Icon Delete -->
                  <i   [attr.automationId]="'Icn_retailSubCategory_delete'" aria-hidden="true" *ngIf="!disableDelete && !table.value.tablebody[bi].donecancel" class="icon-Delete cursor"
                    [class.cursor]="!isEditModeSet" [class.cursordefault]="isEditModeSet"
                    [ngClass]="IsViewOnly || isEditModeSet ? 'button_invalid' : ''" matTooltip="{{captions.common.Delete}}" (click)="DeleteRecords(bodyCnt)"></i>
                  <i  [attr.automationId]="'Icn_retailSubCategory_drag'" aria-hidden="true" class="icon-DragAndDrop movecursor draggable" [ngClass]="{'button_invalid': IsViewOnly || isEditModeSet}" [class.dragcursor]="!isEditModeSet"
                    [class.cursordefault]="isEditModeSet" *ngIf="blnDraggable && !table.value.tablebody[bi].donecancel" matTooltip="{{captions.common.Drag}}"></i>
                  <span *ngIf="table.value.tablebody[bi].donecancel" class="done" (click)="Done(bodyCnt, bi)">
                    <i  [attr.automationId]="'Icn_retailSubCategory_done'" aria-hidden="true" class="icon-done"></i>{{captions.common.cDone}}</span>
                  <span *ngIf="table.value.tablebody[bi].donecancel" class="cancel" (click)="Cancel(bodyCnt, bi,setId)">
                    <i  [attr.automationId]="'Icn_retailSubCategory_cancel'" aria-hidden="true" class="icon-Cancel"></i>{{captions.common.cCancel}}</span>
                </td>
              </tr>
              <tr *ngIf="(bodyArray | filterPipe: searchPipeText : hdrkeyArray).length == 0">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+2" class="text-center">
                  {{captions.common.NoDataFound}} </td>
              </tr>
            </tbody>
          </table>
      </div>
    </div> 
  </form>
</div>