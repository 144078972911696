<div class="dashboard-header" *ngIf="showDashboardHeader">
  <div class="dashboard-header__header-name seat-panel-header">{{menuName | translate}}</div>
  <div class="dashboard-header__data-container">
    <span role="button" aria-pressed="false" *ngIf="( dashboardHeaderCounts$.asObservable() | async)?.length > 3" (click)="navigateLeft()" class="icon-Path-490 dashboard-header__navigation-controls seat-icon-color"></span>
    <div #dataContainer class="dashboard-header__data-details">
      <div class="dashboard-header__data-view" *ngFor="let i of dashboardHeaderCounts$.asObservable() | async ;let j=index">
        <div class="dashboard-header__data-count">
          <span [ngStyle]="{'background-color': i.bgColor, 'border-color': i.borderColor}"
                class="dashboard-header__count-text">{{i.Count}}</span>
        </div>
        <label class="dashboard-header__data-name seat-tertiary-text" for="occupied_value" title="{{i.Name | translate}}">{{i.Name | translate}}</label>
      </div>
    </div>
    <span role="button" aria-pressed="false" *ngIf=" ( dashboardHeaderCounts$.asObservable() | async)?.length > 3" (click)="navigateRight()" class="icon-left-arrow1 dashboard-header__navigation-controls seat-icon-color"></span>
  </div>
</div>
