import { Pipe, PipeTransform } from "@angular/core";
import { SettingsDTO } from "../models/RestaurantDTO";
import { IPartiesOrSection } from "../components/tablelist/slots/slots-section.service";
import { Utilities } from "../utilities/utilities";
import { PartyNoteType } from "../constants/commonenums";
import moment from "moment";
import { AppService } from "@app/app.service";
import { CacheService } from "@app/core/services/cache.service";
import { GuestTitle } from "../models/InputContact";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'timeSlot'
})

export class TimeSlotsPipe implements PipeTransform {
    transform(slots: IPartiesOrSection): string[] {
        let slotDateTime = [];
        for (let key in slots) {
            slotDateTime.push(key);
        }
        return slotDateTime;
    }
}

@Pipe({
    name: 'slotSeatingArea'
})

export class SlotSeatingAreaPipe implements PipeTransform {
    transform(slots: IPartiesOrSection, settings: SettingsDTO): string {
        let seatingAreaName = ''
        if(slots?.SeatingAreaId) {
            seatingAreaName = settings?.SeatingAreas?.find(area => area.Id == slots.SeatingAreaId).Name || '';
        }
        else {
            seatingAreaName = 'anySectionText';
        }
        return seatingAreaName;
    }
}

@Pipe({
    name: 'expiredSlot'
})

export class ExpiredSlotsPipe implements PipeTransform {
    transform(dateTime: string, settings: SettingsDTO): boolean {
        const maxPastTime = settings.General.MaxPastTimeForReservationsInMinutes;
        const currentTime = Utilities.getRestaurantDateTime(settings.General.DaylightDelta);
        let minutes = settings.PropertySetting[0].ShowOngoingSlotsTill ? (settings.PropertySetting[0].ShowOngoingSlotsTill * 1000) : 60000;
        const past1hr = new Date(currentTime.valueOf() - (maxPastTime * minutes));
        const slotTime = Utilities.parseDateString(dateTime);
        return slotTime >= past1hr;
    }
}

@Pipe({ name: 'formatName' })

export class FormatNamePipe implements PipeTransform {
    constructor(private ts: TranslateService){}
  transform(contact) {
    let contactName = ''
    if (!contact?.FirstName && !contact?.LastName) {
     contactName = this.ts.instant('unnamed');
    }
    else {
     contactName = (contact?.GuestTitle > 0 ? GuestTitle[contact?.GuestTitle] : '') + ' ' + (contact?.FirstName ? this.translateName(contact.FirstName) : '') + ' ' + (contact?.LastName ? this.translateName(contact.LastName) : '');
    }
    if(contactName?.length > 12) {
        contactName = (contact?.IsVip ? contactName?.substring(0, 12) : contactName?.substring(0, 14) )+ '..';
    }
    return contactName;
  }

  translateName(contactName: string): string {
    if(contactName.includes('(Cleared)')){ return this.ts.instant(contactName);}
    else {return contactName};
  }
}

@Pipe({
    name: 'partyNoteType'
})

export class PartyNoteTypePipe implements PipeTransform {
    transform(type: number, settings: SettingsDTO): string {
        let partynoteType = '';
        if (type == PartyNoteType.SpecialMeal) {
            partynoteType = 'specialMealText';
        }
        else if (type == PartyNoteType.SpecialRequest) {
            partynoteType = 'specialRequest';
        }
        else if (type == PartyNoteType.PromoCode) {
            partynoteType = settings.PromoCodeLabel;
        }
        return partynoteType;
    }
}
@Pipe({
    name: 'slotDateFormat'
})

export class SlotDateFormatPipe implements PipeTransform {
    constructor(private cs: CacheService, private as: AppService) {
    }
    transform(value: any): string {
        let dateTime = '';
        moment(new Date(value)).format(this.cs.settings.value.General.DateFormat.toUpperCase());
        if(moment(new Date(value)).format(this.cs.settings.value.General.DateFormat.toUpperCase()) > moment(new Date(this.as.headerSelectedDate)).format(this.cs.settings.value.General.DateFormat.toUpperCase())) {
            dateTime = moment(new Date(value)).format(this.cs.settings.value.General.DateFormat.toUpperCase());
        }
        return dateTime;
    }

}