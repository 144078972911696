    <!-- <div class="dynamic-field form-button" [formGroup]="group">
    <button mat-raised-button [disabled]="config.disabled" type="submit" [ngClass]="config.class" >
        {{ config.label }}
    </button>
    </div> -->

    <div [ngSwitch]="config.buttonType" class="custom-button">
        <button class="golfbutton" [ngClass]="config.class"  [disabled]="config.disabled" type="submit" [ngClass]="config.class" >{{config.label | translate }}</button>
        <!-- <button class="golfbutton golf--secondarygreen" [ngClass]="buttontype.customclass" #dynamicbutton  *ngSwitchCase="'secondary'" [disabled]='buttontype.disbaledproperity' mat-stroked-button color="primary">{{buttontype.label | translate}}</button>
        <a class="golfbutton"  [ngClass]="buttontype.customclass" #dynamicbutton *ngSwitchCase="'tertiary'" [disabled]='buttontype.disbaledproperity' mat-button routerLink="." color="primary" (click)="handleclick($event)">{{buttontype.label | translate}}</a> -->
    </div>
    
    