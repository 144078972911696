<div class="contact-list-view">
  <div class="contact-list-view__server-data">
    <!-- <ng-scrollbar> -->
      <div class="contact-list-view__server-list" [ngClass]="{'selected-card':  selectedIndex && item.Id == selectedIndex.Id}" *ngFor="let item of inputData;let i=index" (click)="selectedCard(item)">
        <div class="display_div pt-1">
          <div class="guestlist__basic-info">
            <div class="guestlist__guest-data" >            
            <div class="guestlist__image-view">
              <img class="guestlist__profile-image" *ngIf="item?.Contact?.Photo" src="assets/images/user_image.jpg" alt="profile_pic">
              <span class="guestlist__profile-intials seat-border-color" *ngIf="!item?.Contact?.Photo">{{item | contactNamePipeInitialsPipe}}</span>
            </div>
            <div class="guestlist__guest-details">
              <div class="guestlist__guest-name seat-header-three">{{item | contactNamePipe}}</div>
              <div class="guestlist__guest-data">
                <span class="guestlist__guest-covers seat-secondary-text">{{item.Size}} {{'coversText' | translate}} </span>&nbsp;
                <span class="icon-clock reservation-status__icon seat-border-color seat-icon-color"></span>&nbsp;
                <span class="guestlist__guest-reservedfor seat-secondary-text" *ngIf="item.State == partyState.Pending && item.IsCommunal == undefined">{{item.ReservedFor | formatTime}}</span>
                <span class="guestlist__guest-reservedfor seat-secondary-text" *ngIf="item.State == partyState.Seated  && item.IsCommunal == undefined">{{item.SeatingTime | formatTime}}</span>
                <span class="guestlist__guest-reservedfor seat-secondary-text" *ngIf="item.State == partyState.NoShowed  && item.IsCommunal == undefined">{{item.NoShowedAt | formatTime}}</span>
              </div>
            </div>
          </div>
            <div class="guestlist__time-indicator" (click)="overrideTimeRemaining($event,item)"
            >
            <span
              class="guestlist__override-icon icon-edit"></span>
            <mat-progress-spinner class="guestlist__total-time" [diameter]="50" [value]="100">
            </mat-progress-spinner>
            <mat-progress-spinner [ngClass]="{
                                  'first-percent' : item.progressBarValue < 50,
                'second-percent': item.progressBarValue >= 50,
                'third-percent': item.progressBarValue  >= 80}" class="guestlist__time-loader" mode="determinate"
              [diameter]="50" [value]="progressBarValue">
            </mat-progress-spinner>
            <span *ngIf="(item.remainingTime > 0)" cli class="guestlist__total-mins seat-labels">
              {{ item.remainingTime | formatWaitTime }}
            </span>
            <span *ngIf="(0 > item.remainingTime)" cli class="guestlist__total-mins seat-labels">
              0m
            </span>
          </div>
            </div>
          </div>
        </div>
    <!-- </ng-scrollbar> -->
  </div>
</div>
