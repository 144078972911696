import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ContactHistoryItemDTO, OrderItem } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import moment from 'moment';
import { CacheService } from "@app/core/services/cache.service";

@Component({
  selector: 'app-orderdetails',
  templateUrl: './orderdetails.component.html',
  styleUrls: ['./orderdetails.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderDetailsComponent implements OnInit {
  orderDetails: ContactHistoryItemDTO;
  tableDataSource: MatTableDataSource<OrderItem>;
  tableColumns: string[] = ['item', 'quantity', 'price', 'total'];
  constructor(private ps: PartyService, public cs: CacheService) {
  }
  ngOnInit(): void {
    this.orderDetails = [...this.ps.orderDetails][0]
    this.setTableDataSource();
  }
  setTableDataSource() {
    this.tableDataSource = new MatTableDataSource(this.orderDetails.OrderCheckInfo.OrderItems);
  }
  getTurnTime(): string {
    if (this.orderDetails.OrderCheckInfo.TurnTime == null) {
      return "Seated";
    }
    var timeSpan: any = moment.duration(this.orderDetails.OrderCheckInfo.TurnTime);
    if (timeSpan != null) {
      if (Math.abs(timeSpan / (1000 * 60 * 60)) >= 1) {
        var TotalHours = Math.floor(Math.abs(timeSpan / (1000 * 60 * 60)));
      }
      if (Math.abs(timeSpan / (1000 * 60)) >= 1) {
        var TotalMinutes = Math.floor(Math.abs(timeSpan / (1000 * 60)));
      }
      if (TotalMinutes && TotalMinutes < 60) {
        return `${TotalMinutes}m`;
      }
      if (TotalHours && TotalMinutes) {
        return `${TotalHours}h ${TotalMinutes % 60}m`;
      }
    }
  }

  ngOnDestroy() {
    this.ps.orderDetails = [];
  }

}
