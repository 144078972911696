import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer } from '@angular/forms';
import { Product } from 'src/app/retail/shared/globalsContant';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { ValidationMessage } from '../../../basereport/base-report.component';

@Component({
  selector: 'app-retail-inventory-details',
  templateUrl: './inventory-details.component.html',
  styleUrls: ['./inventory-details.component.scss']
})
export class RetailInventoryDetailsComponent implements OnInit {
  InventoryDetailFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[]; 
  @Output() formReady = new EventEmitter();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  hideDisposableItem: boolean;
  productId: number;
  disposableItemsValue: boolean;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, private controlContainer: ControlContainer) {
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.hideDisposableItem = this.productId == Product.PMS ? false : true;
   }

  ngOnInit() {
    this.radioFilter = [{ id: 0, name: this.captions.AllActiveitems }, { id: 1, name: this.captions.SuppressZeroQtyItems }];
    
    this.InventoryDetailFormGrp = this.fb.group({
      lostCostMethod: false,
      itemFilter: 0,
      categoryView: false,
      category: new UntypedFormGroup({}),
      DisposableItems: this.disposableItemsValue == null ? false : this.disposableItemsValue
    });
    this.formReady.emit(this.InventoryDetailFormGrp);
  }
 
  formInitialized(form: UntypedFormGroup) {
    this.InventoryDetailFormGrp.setControl('category', form);
  }


  toggleDisposableItems(event){
    this.disposableItemsValue = event;
  }

  IsSelectionValid(validationObject: ValidationMessage) {
    this.IsValidSelection.emit({ dropDownName: validationObject.dropDownName, validity: validationObject.validity });
  }
}
