import { Injectable } from '@angular/core';
import { IGiftcardActions, GiftCardCaptureResponse, CaptureStatus, GiftCardGuestDetails } from './IGiftcardActions';
import { rGuestPayBaseService, DeviceStatus } from './rGuestPay-base.service';
import { CapturedCardResponse } from './rGuestPayRequestModel';
import { Device, V1GiftCardBaseRequest } from '../../service/payment/payment-model';
import { CommonVariablesService } from '../../service/common-variables.service';
import { HttpServiceCall, HttpMethod } from '../../service/http-call.service';
import * as GlobalConst from '../../globalsContant';
import { RetailUtilities } from '../../utilities/retail-utilities';
import { Localization } from 'src/app/common/localization/localization';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentMethods } from '../shared.modals';
import { RetailFeatureFlagInformationService } from '../../service/retail.feature.flag.information.service';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
@Injectable()
export class InternalGiftCardBusinessService implements IGiftcardActions {

    constructor(
        private _rGuestPayService: rGuestPayBaseService
        , private _ss: CommonVariablesService
        , private localization: Localization
        , private http: HttpServiceCall
        , private utils: RetailUtilities
        , private featureInfo: RetailFeatureFlagInformationService
    ) { }
    
    /**
     * @description Retrieves list of devices from RGuestPay service
     */
    async GetDevices(): Promise<Device[]> {
        try {
            let deviceList = await this._rGuestPayService.GetDeviceList();
            if (deviceList && deviceList.devices) {
                let availableDevice = deviceList.devices.filter(x => x.deviceStatus == DeviceStatus.Available);
                return availableDevice && availableDevice.map(x => { return { name: x.description, deviceGuid: x.deviceGuid } as Device });
            }
        } catch (error) {
            console.log(`Error occurred while fetching device list from rGuestPay err: ${error}`);
            return [];
        }
    }
    

    async CaptureGiftCard(selectedDevice: Device, isIDTech: boolean, encryptedData?: string, callBackFn?): Promise<GiftCardCaptureResponse> {
        if (!isIDTech) {
            const mapToCardCaptureResponse = (rGuestRes: CapturedCardResponse) => {
                if (rGuestRes && rGuestRes.cardData) {
                    return {
                        cardNumber: rGuestRes?.cardData?.accountNumber ? rGuestRes?.cardData?.accountNumber :
                            this.ExtractCardNumber(rGuestRes.cardData.track1),
                        handle: "",
                        status: CaptureStatus.Success
                    } as GiftCardCaptureResponse
                }
            };
            const selectedDeviceGuid = selectedDevice.deviceGuid;
            const noCardDataAvailable = 9207;
            let rguestErrorMsg  = "";
            let rguestStatus    = 0;
            let rguestException = false;
            let stopPooling = false;
            let res = this._rGuestPayService.LightUpTheDevice(selectedDeviceGuid);
            res.then(async (result) => {
                //Read the card Info and Update the device state
                for (let timeout = this.featureInfo.v1GiftcardPoolingTime; (timeout <= this.featureInfo.v1GiftcardSwipeTimeout && !stopPooling ); timeout = timeout + this.featureInfo.v1GiftcardPoolingTime){
                    await timer(this.featureInfo.v1GiftcardPoolingTime).pipe(take(1)).toPromise();
                    await this._rGuestPayService.GetSwippedCardInfo(selectedDeviceGuid).then(
                        (response) => {
                            if (response && response.cardData) {
                                rguestErrorMsg = "";
                                rguestStatus   = CaptureStatus.Success;
                                callBackFn && callBackFn(mapToCardCaptureResponse(response));
                                stopPooling = true;
                            } else {
                                let failedRes: any = response;
                                rguestErrorMsg = failedRes.message;
                                rguestStatus   = CaptureStatus.Failure;
                                if(failedRes.code != noCardDataAvailable){
                                    stopPooling   = true;
                                    rguestException = true;
                                    callBackFn && callBackFn({ cardNumber: "", handle: "", status: CaptureStatus.Failure, errorMsg: failedRes.message } as GiftCardCaptureResponse);
                                }
                            }
                        }
                    ).catch((errResponse: HttpErrorResponse) => {
                        this._rGuestPayService.GetDeviceInfo(selectedDeviceGuid).then((device) => {
                            if (device.deviceInfo.deviceStatus != DeviceStatus.Available) {
                                this._rGuestPayService.SetDeviceToStandByMode(selectedDeviceGuid);
                            }
                        });
                        stopPooling = true;
                        rguestException = true;
                        callBackFn && callBackFn({ cardNumber: "", handle: "", status: CaptureStatus.Failure, errorMsg: errResponse.error.message } as GiftCardCaptureResponse);
                    });
                }
                if(!rguestException){
                    this._rGuestPayService.SetDeviceToStandByMode(selectedDeviceGuid);
                    if(rguestStatus ==  CaptureStatus.Failure && rguestErrorMsg){                        
                        callBackFn && callBackFn({ cardNumber: "", handle: "", status: CaptureStatus.Failure, errorMsg: rguestErrorMsg } as GiftCardCaptureResponse);
                    }
                }
            });
        } else {
            return this.ProcessIDTechCardData(encryptedData);
        }
    }

    ExtractCardNumber(encryptedData) {
        const startingSeqIdentifier = "B";
        const endingSeqIdentifier = "^";
        let giftcardNumber = ""
        if (encryptedData && encryptedData.includes(startingSeqIdentifier) && encryptedData.includes(endingSeqIdentifier)) {
            let cardInfo = encryptedData.split(startingSeqIdentifier)[1].split(endingSeqIdentifier);
            giftcardNumber = cardInfo[0];
        }
        return giftcardNumber;
    }

    private ProcessIDTechCardData(encryptedData: string) {
        return { cardNumber: this.ExtractCardNumber(encryptedData), handle: "", status : CaptureStatus.Success } as GiftCardCaptureResponse;
    }

    PerformCashback(giftcardNumber: string, retailTicketNumber: string, cashBackAmt: number) {
        const cashBackReq: V1GiftCardBaseRequest = this.ConstructBaseRequest(giftcardNumber, cashBackAmt);
        return this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "RedeemV1GiftCard",
            method: HttpMethod.Post,
            body: cashBackReq,
            showError: true
        }); 
    }

    private ConstructBaseRequest(cardNumber: string, amount: number, guestDetails?: GiftCardGuestDetails) {
        return {
            cardNumber: cardNumber,
            cashierId: Number(this.localization.GetPropertyInfo("UserId")),
            cashierName: this.localization.GetPropertyInfo("userName"),
            rechargeValue: amount,
            firstName: guestDetails?.firstName ?? "",
            lastName: guestDetails?.lastName ?? "",
            email: guestDetails?.email ?? "",
            phoneNumber: guestDetails?.phoneNumber ?? ""
        } as V1GiftCardBaseRequest;
    }

    RechargeGiftCard(cardNumber: string, retailTicketNumber: string, amount: number) {
        const rechargeReq: V1GiftCardBaseRequest = this.ConstructBaseRequest(cardNumber, amount);
        let rechargeGCResponse: any = this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "RechargeV1Giftcard",
            method: HttpMethod.Post,
            body: rechargeReq,
            showError: true
        });
        console.log(rechargeGCResponse.result);
        return rechargeGCResponse;
    }

    IssueGiftCard(cardNumber: string, retailTicketNumber: string, amount: number, guestDetails: GiftCardGuestDetails) {
        const issueReq: V1GiftCardBaseRequest = this.ConstructBaseRequest(cardNumber, amount, guestDetails);
        let rechargeGCResponse: any = this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "IssueV1GiftCard",
            method: HttpMethod.Post,
            body: issueReq,
            showError: true
        });
        console.log(rechargeGCResponse.result);
        return rechargeGCResponse;
    }

    ReverseRecharge(giftcardNumber: string, retailTicketNumber: string, amount: number, transId: number, handle?: string, tendedId?: PaymentMethods) {
        const issueReq: V1GiftCardBaseRequest = this.ConstructBaseRequest(giftcardNumber, amount);
        let rechargeGCResponse: any = this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "ReverseRechargeV1GiftCard",
            method: HttpMethod.Post,
            body: issueReq,
            uriParams: { paymentTransactionId: transId },
            showError: true
        });
        console.log(rechargeGCResponse.result);
        return rechargeGCResponse;
    }

    ReturnGiftCard(giftcardNumber: string, amount: number, handle?: any) {
        const issueReq: V1GiftCardBaseRequest = this.ConstructBaseRequest(giftcardNumber, amount);
        let rechargeGCResponse: any = this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "ReturnV1GiftCard",
            method: HttpMethod.Post,
            body: issueReq,
            showError: true
        });
        console.log(rechargeGCResponse.result);
        return rechargeGCResponse;
    }

}