import { Pipe, PipeTransform } from '@angular/core';
import { DynamicFormService } from '@dynamicform/service/dynamic-form.service';
import { Utilities } from '@utilities/utilities';
import { CountryDTO, SettingsDTO } from '@models/RestaurantDTO';
import { CacheService } from '@core/services/cache.service';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'phoneNumberFormatter'
})
export class PhoneNumberFormatterPipe implements PipeTransform {
  countryCodes: CountryDTO[] = [];
  defaultRestaurantCountryCode: string;
  _settings: SettingsDTO = {} as SettingsDTO;
  subscriptions: Subscription = new Subscription();
  constructor(private dfs: DynamicFormService, public cs: CacheService) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
      this.defaultRestaurantCountryCode = Utilities.getDefaultCountryCode(this._settings);
      this.countryCodes = this._settings.Countries as CountryDTO[];
    }));
  }

  transform(phoneNumber: any, countryId: any, countryCode?: any): any {
    let formattedNumber = '';
    if (phoneNumber) {
      if (typeof (countryId) == "number" || !isNaN(countryId) || countryCode) {

        if (countryCode) {
          this.dfs.selectedCountryCode = countryCode;
          formattedNumber = `${this.dfs.selectedCountryCode} ${this.formatPhone(phoneNumber, false)}`;
        }
        else {
          countryId = +countryId;
          this.dfs.selectedCountryId = countryId;
          this.dfs.selectedCountryCode = countryId ? this.countryCodes.filter((country) => country.Id === countryId)[0].CountryPhoneCode :
            this.defaultRestaurantCountryCode;
          formattedNumber = `${this.dfs.selectedCountryCode} ${this.dfs.onInputChange(phoneNumber, false)}`;
        }
      } else {
        this.dfs.selectedCountryCode = '';
        formattedNumber = `${this.dfs.selectedCountryCode} ${this.formatPhone(phoneNumber, false)}`;
      }
    }
    return formattedNumber;
  }
  formatPhone(phoneNumber: any, arg1: boolean) {
    return phoneNumber.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
