<div class="message-section">
  <mat-accordion>
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header class="seat-border-color">
        <mat-panel-title>
          {{'messagesText' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="message-section__messges-view"
        [ngClass]="{'message-section__messages-height' : dashboardMessages?.length == 0}">
        <ng-scrollbar track="vertical" class="message-section__scrollbar">
          <div *ngIf="dashboardMessages" class="message-container">
            <div *ngIf="dashboardMessages?.length > 0">
              <div class="button-row" *ngFor="let msg of dashboardMessages">
                <div class="contact-name">
                  <span class="intials-view seat-panel-header seat-border-color" *ngIf="!msg.IsIncoming">{{'you' | translate}}</span>
                  <span class="intials-view"
                        *ngIf="msg.IsIncoming">{{party?.Contact?.FirstName | slice:0:1}}{{party?.Contact?.LastName | slice:0:1}}</span>
                </div>
                <label class="contact-message seat-secondary-text">
                  <span class="seat-tertiary-text" *ngIf="!msg.IsIncoming">{{'you' | translate}}</span>
                  <span *ngIf="!msg.IsIncoming"> {{'sentMsg' | translate}}</span>
                  <span class="seat-tertiary-text" *ngIf="msg.IsIncoming">{{party?.Contact?.FirstName}}</span>
                  <span *ngIf="msg.IsIncoming"> {{'replyMessage' | translate}}</span>
                </label>
                <span class="message-details seat-tertiary-text">{{msg.Text | stipMsg}}</span>
                <label class="message-timings seat-tertiary-text"><span class="icon-ic_update_24px m-2 seat-icon-color"></span>{{msg.CreatedAt | formatSettingsDate: settings.General.DateFormat}} {{msg.CreatedAt | localizedDate: 'LT'}}</label>
              </div>
            </div>
            <div class="no-msg-found seat-no-content-found" *ngIf="dashboardMessages?.length == 0">
              {{'noMsgsFound' | translate}}
            </div>
          </div>
        </ng-scrollbar>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
