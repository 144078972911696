import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { RetailroutingModule } from './retail-routing.module';
import { RetailComponent } from './retail.component';
import { CodeSetupComponent } from './retail-code-setup/retail-code-setup.component';
import { RetailChildSetupComponent } from './retail-child-setup/retail-child-setup.component'
import { RetailOutletsComponent } from './retail-code-setup/retail-outlets/retail-outlets.component';
import { RetailUnitOfMeasureComponent } from './retail-code-setup/retail-unit-of-measure/retail-unit-of-measure.component';
import { RetailQuickSaleComponent } from './retail-quick-sale/retail-quick-sale.component';
import { RetailSetupComponent } from './retail-setup/retail-setup.component';
import { SeasonalPriceComponent } from './retail-setup/seasonal-price/seasonal-price.component';
import { MultiPackComponent, multiPackSave, CheckForValue } from './retail-setup/multi-pack/multi-pack.component';
import { PackagedItemsComponent, GetOutletNamePipe } from './retail-setup/packaged-items/packaged-items.component';
import { InventoryComponent } from './retail-setup/inventory/inventory.component';
import { OtherInformationComponent } from './retail-setup/other-information/other-information.component';
import { GeneralComponent } from './retail-setup/general/general.component';
import { RetailQuickSalePopupComponent } from './retail-quick-sale-popup/retail-quick-sale-popup.component';
import { RetailCommissionTemplateComponent } from './retail-commission-setup/retail-commission-setup.component';
import { RetailAddCommissionPopupComponent } from './retail-add-commission-popup/retail-add-commission-popup.component';
import { RetailPopupComponent } from './retail-popup/retail-popup.component';
import { RetailTableComponent } from './retail-table/retail-table.component';
import { RetailQuickSaleCategoriesComponent } from './retail-code-setup/retail-quick-sale-categories/retail-quick-sale-categories.component';
import { RetailTableHeaderComponent } from './retail-table-header/retail-table-header.component';
import { RetailCreditCardTerminalComponent } from './retail-code-setup/retail-credit-card-terminal/retail-credit-card-terminal.component';
import { RetailCategoryGroupComponent } from './retail-code-setup/retail-category-group/retail-category-group.component';
import { RetailCategoriesComponent } from './retail-code-setup/retail-categories/retail-categories.component';
import { RetailSubCategoriesComponent } from './retail-code-setup/retail-sub-categories/retail-sub-categories.component';
import { RetailDiscountTypesComponent } from './retail-code-setup/retail-discount-types/retail-discount-types.component';
import { RetailTaxesComponent } from './retail-code-setup/retail-taxes/retail-taxes.component';
import { CategoryLinkingComponent } from './category-linking/category-linking.component';
import { CategoryListService } from './category-linking/category-bussines.service';
import { RetailPaymentMethodComponent } from './retail-payment-method/retail-payment-method.component';
import { RetailMailOrderSourceComponent } from './retail-mail-order-source/retail-mail-order-source.component';
import { RetailDiscountConfigurationComponent } from './retail-discount-configuration/retail-discount-configuration.component';

import { SubCategoryTableComponent } from './retail-code-setup/retail-sub-categories/sub-category-table/sub-category-table.component';
import { AddTransferInventoryPopupComponent } from './add-transfer-inventory-popup/add-transfer-inventory-popup.component';
import { MultipleToSingleComponent } from './add-transfer-inventory-popup/multiple-to-single/multiple-to-single.component';
import { SingleToMultipleComponent } from './add-transfer-inventory-popup/single-to-multiple/single-to-multiple.component';
import { ScheduledMarkdownComponent } from './scheduled-markdown/scheduled-markdown.component';
import { ScheduledMarkdownPopupComponent } from './scheduled-markdown/scheduled-markdown-popup/scheduled-markdown-popup.component';
import { CarriersComponent } from './carriers/carriers.component';
import { RetailSuppliersComponent } from './retail-code-setup/retail-suppliers/retail-suppliers.component';
import { RetailCreateNewSupplierComponent } from './retail-code-setup/retail-create-new-supplier/retail-create-new-supplier.component';
import { RetailSupplierDetailsComponent } from './retail-code-setup/retail-supplier-details/retail-supplier-details.component';
import { RetailTaxesDataService } from './retail-code-setup/retail-taxes/retail-taxes-data.service';
import { LocalizedDecimalValuePipe } from './retail-table-inline-edit/localized-decimal-value.pipe';
import { RetailPrintLabelsComponent } from './retail-print-labels/retail-print-labels.component';
import { RetailSetupTableComponent } from './retail-child-setup/retail-setup-table/retail-setup-table.component';
import { AuthGuardService } from './common/services/auth-guard.service';
import { RetailServiceRegistry } from './shared/service/base.service';
import { HttpServiceCall } from './shared/service/http-call.service';
import { SPAConfig } from './common/config/SPA-config';
import { RetailLocalization } from './common/localization/retail-localization';
import { RetailPropertyInformation } from './common/services/retail-property-information.service';
import { SessionManagerService } from './common/services/session-manager.service';
import { MoreSectionServiceService } from './shared/more-section/more-section-service.service';
import { BreakPointAccess } from './shared/service/breakpoint.service';
import { ReceiptService } from './shop/receipt/business/receipt.service';
import { ImageProcessorService } from './shared/service/image-processor-service';
import { PayAgentService } from './shared/service/payagent.service';
import { UserMachineConfigurationService } from './common/services/user-machine-configuration.service';
import { UserAlerts } from './common/config/alerts-config';
import { CustomCurrencyPipe } from './common/localization/currency.pipe';
import { NgDragDropModule } from 'ng-drag-drop';
import { ShopModule } from './shop/shop.module';
import { RetailChipsAutocompleteExample } from './shared/multiplesearch/multiplesearch.component';
import { BarcodePrintService } from './retail-print/barcode-print.service';
import { ZebraPrintService } from './retail-print/zebra-print.service';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { CommonService } from './shared/service/common-service.service';
import { ViewMoreServiceService } from './shared/view-more/view-more-service.service';
import { RetailSharedModule } from './shared/retail-shared.module';
import { RetailReportModule } from './retail-reports/reports.module';
import { RetailSetupService } from './retail-setup/retail-setup.service';
import { RetailToggleSwitchModule } from './retail-toggle-switch/retail-toggle-switch.module';
import { InventoryService } from './inventory/inventory.service';
import { ReadUploadedFile } from './shared/utilities/readcsvfile';
import { RetailChildSetupBusiness } from './retail-child-setup/retail-child-setup.business';
import { CreditCardsComponent } from './sytem-config/credit-cards/credit-cards.component';
import { VatComponent } from './sytem-config/vat-configuration/vat-configuration.component';
import { PaymentFeaturesConfigComponent } from './sytem-config/payment-features-config/payment-features-config.component';
import { RetailOutletTerminalComponent } from './retail-code-setup/retail-outlet-terminal/retail-outlet-terminal.component';
import { GiftCardsComponent } from './sytem-config/gift-cards/gift-cards.component';
import { RetailSearchItemsComponent } from './shared/search-items/search-items.component';
import { CommissionSetupModule } from './commission-setup/commission-setup.module';
// import { RouteGuardService } from './shared/service/route-gaurd.service';
import { RetailVendorSetupComponent } from './retail-vendor-setup/retail-vendor-setup.component';
import { ItemAssignedComponent } from './retail-vendor-setup/item-assigned/item-assigned.component';
import { RetailVendorTypesComponent } from './retail-code-setup/retail-vendor-types/retail-vendor-types.component';
import { AddVendorsPopupComponent } from './retail-setup/inventory/add-vendors-popup/add-vendors-popup.component';
import { EatecModule } from './eatec/eatec.module';
import { RetailChildSetupCSVValidator } from './retail-child-setup/retail-child-setup.validator';
import { PostTypeMappingComponent } from './retail-code-setup/post-type-mapping/post-type-mapping.component';
import { PostTypeMappingCrudComponent } from './retail-code-setup/post-type-mapping/post-type-mapping-crud/post-type-mapping-crud.component';
import { PostTypeBusiness } from './retail-code-setup/post-type-mapping/post-type-mapping.business';
import { CreateCreditCardTerminalComponent } from './retail-code-setup/retail-credit-card-terminal/create-credit-card-terminal/create-credit-card-terminal.component';
import { RetailCommunication } from './retailcommunication.service';
import { CreatePaymentMethodComponent } from './retail-payment-method/create-payment-method/create-payment-method.component';
import { TaxTypeComponent } from './retail-code-setup/retail-taxes/tax-type/tax-type.component';
import { CreateTaxTypeComponent } from './retail-code-setup/retail-taxes/tax-type/create-tax-type/create-tax-type.component';
import { MiscellaneousComponent } from './sytem-config/miscellaneous/miscellaneous.component';
import { RetailFunctionalityBusiness } from './shared/business/retail-functionality.business';
import { RetailFunctionalityService } from './shared/service/retail-functionality.service';
import { RoomTypeAssignmentComponent } from './retail-setup/room-type-assignment/room-type-assignment.component';
import { RetailDiscountReasonComponent } from './retail-code-setup/retail-discount-reason/retail-discount-reason.component';
import { RetailMachineNameComponent } from './retail-code-setup/machine-name/machine-name.component';
import { TaxExemptCategoryComponent } from './retail-code-setup/tax-exempt-category/tax-exempt-category.component';
import { CreateCashEqivalentPaymentMethodComponent } from './retail-payment-method/create-cash-eqivalent-payment-method/create-cash-eqivalent-payment-method.component';
import { ArCustomerSearchComponent } from './component/ar-customer-search/ar-customer-search.component';
import { CartAgreementComponent } from './letter-setup/cart-agreement/cart-agreement.component';
import { ClubAgreementComponent } from './letter-setup/club-agreement/club-agreement.component';
import { ShoeAgreementComponent } from './letter-setup/shoe-agreement/shoe-agreement.component';
import { LetterSetupComponent } from './letter-setup/letter-setup.component';
import { CommonSharedModule } from '../common/shared/shared/shared.module';
import { CreateLetterComponent } from './letter-setup/create-letter/create-letter.component';
import { LetterLandingComponent } from './letter-setup/letter-landing/letter-landing.component';
import { SaleChitComponent } from './letter-setup/sale-chit/sale-chit.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CreateReatilOutletsComponent } from './retail-code-setup/retail-outlets/create-reatil-outlets/create-reatil-outlets.component';
import { RouteGuardService } from './shared/service/route-gaurd.service';
import { EditorComponent } from './letter-setup/editor/editor.component';
import { DuplicateRetailItemDirective } from './retail-setup/duplicate-validator';
import { DatePipe } from '@angular/common';
import { PaymentCardOnFileSelectComponent } from './payment/payment-card-on-file-select/payment-card-on-file-select.component';
import { VatConfigDepartmentTableComponent } from './sytem-config/vat-configuration/vat-config-department-table/vat-config-department-table.component';
import { MemberAccrualMappingComponent } from './retail-code-setup/member-accrual-mapping/member-accrual-mapping.component';
import { MemberAccrualMapCrudComponent } from './retail-code-setup/member-accrual-mapping/member-accrual-map-crud/member-accrual-map-crud.component';
import { PaymentManagerModule } from './payment-manager/payment-manager.module';
import { EnahancedInventoryMasterSyncComponent } from './enahanced-inventory-master-sync/enahanced-inventory-master-sync.component';
import { UseInventoryPopupComponent } from './enahanced-inventory-master-sync/use-inventory-popup/use-inventory-popup.component';
import { GuestbookModule } from '@app/guestbook/guestbook.module';
import { MaterialModule } from './material-module';
import { ConfigureDateTimeComponent } from './retail-code-setup/configure-date-time/configure-date-time.component';
import { ConfigureDateTimeBusiness } from './retail-code-setup/configure-date-time/configure-date-time.business';
import { ResortFinancePostTypeMappingComponent } from './retail-code-setup/resort-finance-post-type-mapping/resort-finance-post-type-mapping.component';
import { ResortFinancePostTypeMappingCrudComponent } from './retail-code-setup/resort-finance-post-type-mapping/resort-finance-post-type-mapping-crud/resort-finance-post-type-mapping-crud.component';
import { GeneralLedgerMappingComponent } from './retail-code-setup/general-ledger-mapping/general-ledger-mapping.component';
import { GeneralLedgerMappingCrudComponent } from './retail-code-setup/general-ledger-mapping/general-ledger-mapping-crud/general-ledger-mapping-crud.component';
import { PmsIntegrationSetupComponent } from './pms-integration-setup/pms-integration-setup.component';
import { PmsIntegrationSetupWrapperModalComponent } from './pms-integration-setup-wrapper-modal/pms-integration-setup-wrapper-modal.component';
import { ExternalPostTypeMappingComponent } from './external-post-type-mapping/external-post-type-mapping.component';
import { ExternalPmsPostTypeMappingComponent } from './retail-code-setup/external-pms-post-type-mapping/external-pms-post-type-mapping.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RetailroutingModule,
        SharedModule,
        NgDragDropModule.forRoot(),
        ShopModule,
        NgScrollbarModule,
        RetailReportModule,
        RetailToggleSwitchModule,
        CommissionSetupModule,
        EatecModule,
        PaymentManagerModule,
        CommonSharedModule,
        RetailSharedModule,
        CKEditorModule,
	GuestbookModule,
        MaterialModule,
        CKEditorModule
    ],
    declarations: [
        PmsIntegrationSetupComponent,
        VatConfigDepartmentTableComponent,
        RetailComponent,
        CodeSetupComponent,
        RetailChildSetupComponent,
        RetailOutletsComponent,
        RetailUnitOfMeasureComponent,
        RetailQuickSaleComponent,
        RetailSetupComponent,
        SeasonalPriceComponent,
        MultiPackComponent,
        PackagedItemsComponent,
        InventoryComponent,
        OtherInformationComponent,
        GeneralComponent,
        RetailQuickSalePopupComponent,
        RetailCommissionTemplateComponent,
        RetailAddCommissionPopupComponent,
        RetailPopupComponent,
        RetailTableComponent,
        RetailQuickSaleCategoriesComponent,
        multiPackSave,
        CheckForValue,
        RetailTableHeaderComponent,
        RetailCreditCardTerminalComponent,
        RetailCategoryGroupComponent,
        RetailCategoriesComponent,
        RetailSubCategoriesComponent,
        RetailDiscountTypesComponent,
        RetailMachineNameComponent,
        RetailTaxesComponent,
        CategoryLinkingComponent,
        RetailTableHeaderComponent,
        RetailPaymentMethodComponent,
        RetailMailOrderSourceComponent,
        RetailDiscountConfigurationComponent,
        CarriersComponent,
        RetailSuppliersComponent,
        ScheduledMarkdownComponent,
        ScheduledMarkdownPopupComponent,
        RetailCreateNewSupplierComponent,
        RetailSupplierDetailsComponent,
        AddTransferInventoryPopupComponent,
        MultipleToSingleComponent,
        SingleToMultipleComponent,
        SubCategoryTableComponent,
        LocalizedDecimalValuePipe,
        RetailPrintLabelsComponent,
        RetailSetupTableComponent,
        GetOutletNamePipe,
        CreditCardsComponent,
        VatComponent,
        PaymentFeaturesConfigComponent,
        RetailOutletTerminalComponent,
        GiftCardsComponent,
        RetailSearchItemsComponent,
        RetailVendorSetupComponent,
        ItemAssignedComponent,
        RetailVendorTypesComponent,
        AddVendorsPopupComponent,
        PostTypeMappingComponent,
        PostTypeMappingCrudComponent,
        ResortFinancePostTypeMappingComponent,
        ResortFinancePostTypeMappingCrudComponent,
        CreateCreditCardTerminalComponent,
        CreatePaymentMethodComponent,
        TaxTypeComponent,
        CreateTaxTypeComponent,
        MiscellaneousComponent,
        RoomTypeAssignmentComponent,
        TaxExemptCategoryComponent,
        RetailDiscountReasonComponent,
        CreateCashEqivalentPaymentMethodComponent,
        ArCustomerSearchComponent,
        ClubAgreementComponent,
        CartAgreementComponent,
        LetterSetupComponent,
        ShoeAgreementComponent,
        LetterLandingComponent,
        CreateLetterComponent,
        SaleChitComponent,
        CreateReatilOutletsComponent,
        EditorComponent,
        DuplicateRetailItemDirective,
        PaymentCardOnFileSelectComponent,
        MemberAccrualMappingComponent,
        MemberAccrualMapCrudComponent,
        EnahancedInventoryMasterSyncComponent,
        UseInventoryPopupComponent,
        ConfigureDateTimeComponent,
        GeneralLedgerMappingComponent,
        GeneralLedgerMappingCrudComponent,
        PmsIntegrationSetupWrapperModalComponent,
        ExternalPostTypeMappingComponent,
        ExternalPmsPostTypeMappingComponent
    ],
    exports: [RetailComponent,
        RetailSetupComponent,
        RetailQuickSalePopupComponent,
        RetailTableComponent,
        multiPackSave,
        CheckForValue,
        CategoryLinkingComponent,
        RetailCreateNewSupplierComponent,
        RetailSupplierDetailsComponent,
        ShopModule,
        RetailChipsAutocompleteExample,
        NgScrollbarModule,
        InventoryComponent,
        CreditCardsComponent,
        VatComponent,
        PaymentFeaturesConfigComponent,
        RetailSearchItemsComponent,
        EatecModule,
        MiscellaneousComponent,
        LetterLandingComponent,
        CreateLetterComponent,
        EditorComponent,
        DuplicateRetailItemDirective,
        EnahancedInventoryMasterSyncComponent,
        PmsIntegrationSetupComponent,
    ],
    entryComponents: [PmsIntegrationSetupWrapperModalComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [RetailSetupService, CategoryListService, RetailTaxesDataService, BarcodePrintService, ZebraPrintService, CustomCurrencyPipe, CommonService, ViewMoreServiceService,
        InventoryService, ReadUploadedFile, RetailChildSetupBusiness, RetailChildSetupCSVValidator, RetailCommunication,
        RetailLocalization, SessionManagerService, BreakPointAccess, UserAlerts, UserMachineConfigurationService, ImageProcessorService, ReceiptService, MoreSectionServiceService, RetailPropertyInformation, AuthGuardService, RetailServiceRegistry, SPAConfig, HttpServiceCall, RouteGuardService, PostTypeBusiness, RetailFunctionalityBusiness,
         RetailFunctionalityService, DatePipe,ConfigureDateTimeBusiness]
})
export class RetailModule { }
