import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivitiesTimelineMaiComponent } from './components/activities-timeline-main.component';

const ActivitiesTimelineEntryRoutes: Routes = [
  {
    path: '',
    component: ActivitiesTimelineMaiComponent,
    children: [
      {
        path: '',
        component: ActivitiesTimelineMaiComponent

      },
    ]
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(ActivitiesTimelineEntryRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class ActivitiesTimelineRoutingModule {

}










