
import { Injectable } from "@angular/core";

import { AppointmentCancel, DepositAPIModel } from "@app/common/shared/shared/business/shared.modals";
import { RetailEventHandler } from "@app/common/shared/shared/service/retailevent-handler";
import { CancelDepositEventModel, DepositEventModel, RetailEventParameters, TicketChangeEventModel, VoidEventModel } from "@app/retail/shared/events/event.model";

@Injectable({
    providedIn: "root"
})
export class SubscriptionHandler {
    constructor(private _handleRetailEvents: RetailEventHandler) {

    }

    public async handleVoid(eventParams: RetailEventParameters<VoidEventModel>) {
        const data: VoidEventModel = eventParams.data;
        if (data.isDeposit) {
            this._handleRetailEvents.RevertDeposit(data.transactionId);
        }
        this._handleRetailEvents.UndoAppointment(data.transactionId);
    }

    public async  handleReOpen(eventParams: RetailEventParameters<TicketChangeEventModel>) {
        let data = eventParams.data;
        this._handleRetailEvents.UpdateAppointmentTransaction(data.oldTransactionId, data.transactionId, data.transactionDetail);
    }

    public async handleCancelDeposit(eventParams: RetailEventParameters<CancelDepositEventModel[]>) {
        let deposit: CancelDepositEventModel[] = eventParams.data;
        if (!deposit || deposit.length == 0) {
            return;
        }
        let cancelAppointment: AppointmentCancel[] = [];
        deposit.forEach(r => {
            cancelAppointment.push({
                id: r.id,
                cancelComments: r.cancelComments,
                cancelFee: r.cancelFee,
                cancelReason: r.cancelReason,
                depositRefundAmount: r.depositRefundAmount
            })
        });
        this._handleRetailEvents.CancelAppointmentDeposit(deposit[0].transactionId, cancelAppointment);
    }

    public async handleDeposit(eventParams: RetailEventParameters<DepositEventModel[]>) {
        let deposit: DepositEventModel[] = eventParams.data;
        if (!deposit || deposit.length == 0) {
            return;
        }
        let depositModel: DepositAPIModel[] = [];
        deposit.forEach(r => {
            depositModel.push(
                {
                    id: r.id,
                    appointmentId: r.typeId,
                    amount: r.amount,
                    serviceCharge: r.serviceCharge,
                    gratuity: r.gratuity,
                    isVoided: false,
                    depositTransactionId: r.depositTransactionId,
                    refundAmount: 0,
                    refundTransactionId: 0
                }
            )
        });
        this._handleRetailEvents.AddDeposit(depositModel);
    }
}
