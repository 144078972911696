import { Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertType, ButtonType } from '../shared.modal';
import { RetailLocalization } from '../../common/localization/retail-localization';


@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent implements OnInit {
  continueText = this._Localization.captions.alertPopup.continue;
  cancelText = this._Localization.captions.alertPopup.cancel;
  YES = this._Localization.captions.alertPopup.yes;
  NO = this._Localization.captions.alertPopup.no;
  okText = this._Localization.captions.alertPopup.okay;
  viewFailedText = this._Localization.captions.alertPopup.viewFailedUnprocessed;
  success = this._Localization.captions.alertPopup.success;
  warning = this._Localization.captions.alertPopup.warning;
  error = this._Localization.captions.alertPopup.error;
  wellDone = this._Localization.captions.alertPopup.welldone;
  info = this._Localization.captions.alertPopup.info;
  accessDenied = this._Localization.captions.alertPopup.accessDenined;
  public alertType = AlertType;
  public buttonType = ButtonType;
  constructor(public dialogRef: MatDialogRef<AlertPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private _Localization:RetailLocalization) {

  }

  DialogClose(result?: any): void {
    this.dialogRef.close(result);
  }

  ngOnInit() {
    if (this._Localization.captions.alertPopup) {
      this.continueText = this._Localization.captions.alertPopup.continue;
      this.cancelText = this._Localization.captions.alertPopup.cancel;
      this.YES = this._Localization.captions.alertPopup.yes;
      this.NO = this._Localization.captions.alertPopup.no;
      this.okText = this._Localization.captions.alertPopup.okay;
      this.success = this._Localization.captions.alertPopup.success;
      this.warning = this._Localization.captions.alertPopup.warning;
      this.error = this._Localization.captions.alertPopup.error;
      this.wellDone = this._Localization.captions.alertPopup.welldone;
      this.info = this._Localization.captions.alertPopup.info;
      this.accessDenied = this._Localization.captions.alertPopup.accessDenined;
    }
  }


}
