<section class="createTaxPopUp h-100">
  <form [formGroup]="FormGrp" autocomplete="off" class=" taxForm">
    <mat-dialog-content>
      <div class="" *ngIf="!isVATEnabled">
        <mat-form-field class="taxFormField" [floatLabel]="floatLabel">
          <mat-select [attr.automationId]="'Dd_retailCreateNew_customField'" [placeholder]="customFieldDesc[0]"
            name="outlet" formControlName="outlet" required>
            <mat-option [value]="value.id" *ngFor="let value of customFieldValues[0]">{{value.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="FormGrp.controls['outlet'].invalid">
            {{ getErrorMessage("outlet") }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="pt-3 ag_display--flex">
        <mat-form-field class="taxFormField ag_pr--8" [floatLabel]="floatLabel">
          <input [attr.automationId]="'Txt_retailCreateNew_taxName'" matInput [placeholder]="customFieldDesc[1]"
            name="taxname" formControlName="taxname" inputtype="nospecailchar" required />
          <mat-error *ngIf="FormGrp.controls['taxname'].invalid">
            {{ getErrorMessage('taxname') }}
          </mat-error>
        </mat-form-field>
        <div *ngIf="!isVATEnabled" class="ag_display--flex ">
          <div class="ag_mb--3 ag_pr--8">
            <div *ngIf="isRevenueToPropertyAllowed">
              <label class="LW12 pr-2 active-label label-color form-label-normal ag_display--block">{{settingCaptions.taxRevenueToProperty}}</label>
              <app-retail-toggle-switch
                (changeToggleEvent)="EnableTaxRevenueToProperty($event)"
                [attr.automationId]="'Txt_retailCreateNew_isTaxRevenueToProperty'" formControlName="isTaxRevenueToProperty"
                class="toggle-switch">
              </app-retail-toggle-switch>
            </div>
          </div>
          <div class="ag_mb--3 ag_pr--8">
            <div>
              <label class="LW12 pr-2 active-label label-color form-label-normal ag_display--block">{{settingCaptions.AllowTaxOnServiceCharge}}</label>
              <app-retail-toggle-switch
                (changeToggleEvent)="validateTaxOnTaxSetting('allowTaxOnServiceCharge',$event)"
                [attr.automationId]="'Txt_retailCreateNew_allowTaxOnServiceCharge'" formControlName="allowTaxOnServiceCharge"
                class="toggle-switch">
              </app-retail-toggle-switch>
            </div>
          </div>
          <div class="ag_mb--3 ag_pr--8">
            <div>
              <label class="LW12 pr-2 active-label label-color form-label-normal ag_display--block">{{settingCaptions.AllowTaxOnGratuity}}</label>
              <app-retail-toggle-switch (changeToggleEvent)="validateTaxOnTaxSetting('allowTaxOnGratuity',$event)"
                [attr.automationId]="'Txt_retailCreateNew_allowTaxOnGratuity1'"
                [attr.automationId]="'Txt_retailCReateNew_allowTaxOnGratuity'" formControlName="allowTaxOnGratuity"
                class="toggle-switch"></app-retail-toggle-switch>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-3 ">
        <label for="taxIn" class="LW12 blckClr">{{customFieldDesc[2]}}</label>
        <div class='customvalue'>
          <div class="custom-group">
            <div class="toggleButton">
              <button type="button" [attr.automationId]="'Btn_retailCreateNew_value'" mat-raised-button
                *ngFor="let value of customValue;let i =index" class="LW14" [ngClass]="[selectedValue.id == value.id?'body-bgcolor whitecolor body-bordercolor':'body-bordercolor LW14 ' ,
                  i==0 ? '':'ml-0',
                  value.id == 1 ? 'percentButton' : 'AmtButton'  ]" [disabled]="isVATEnabled" [value]="value.id"
                (click)="chooseValue($event,value)">{{value.value}}</button>
            </div>

            <mat-form-field class="pt-4 taxFormField" [floatLabel]="floatLabel">
              <input [attr.automationId]="'Txt_retailCreateNew_taxAmount'" matInput
                [isFromTaxPercentage]="isFromTaxPercentage" RetailCurrencyFormatter
                [postDecimalLengthForTaxes]="decimalMaxLength" [preDecimalLength]="taxMaxLength" name="taxAmount"
                formControlName="taxAmount" type='text' placeholder={{customValueplaceholder}}
                [disabled]="ispercentValueDisabled" />
              <mat-error *ngIf="FormGrp.controls['taxAmount'].invalid">
                {{ getErrorMessage('taxAmount') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!--Start Date-->
      <div class="pt-3 taxFormField">
        <div class="d-inline-block pr-4">
          <mat-form-field class="date-input date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
            <mat-label>{{textCaptions.StartDate}}</mat-label>
            <input [attr.automationId]="'Txt_retailCreateNew_startDate'" matInput [min]="minFromDate" dateRestricter
              [matDatepicker]="startDate" placeholder="{{placeholderFormat}}" class="picker1"
              formControlName="startDate" (dateChange)="onStartDateChange($event)" required>
            <mat-datepicker #startDate></mat-datepicker>
            <i aria-hidden="true" matSuffix class="icon-Calender" (click)="startDate.open()"></i>

            <mat-error
              *ngIf="FormGrp.controls['startDate'].hasError('matDatepickerParse')">{{captionsCommon.dateFormat}}</mat-error>
            <mat-error
              *ngIf="(FormGrp.controls['startDate'].hasError('required') || FormGrp.controls['startDate'].hasError('incorrect')) && !FormGrp.controls['startDate'].hasError('matDatepickerParse')">{{
              getErrorMessage('startDate')}}</mat-error>
            <mat-error *ngIf="FormGrp.controls['startDate'].hasError('matDatepickerMin')">{{captionsCommon.minimum}}
              {{captions.StartDate}}</mat-error>
            <mat-error *ngIf="FormGrp.controls['startDate'].hasError('matDatepickerMax')">{{captionsCommon.maximum}}
              {{captions.StartDate}}</mat-error>
          </mat-form-field>
        </div>

        <!--End Date-->

        <div class="d-inline-block ">
          <mat-form-field class="date-input date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
            <mat-label>{{textCaptions.EndDate}}</mat-label>
            <input [attr.automationId]="'Txt_retailCreateNew_endDate'" matInput [min]="minToDate" dateRestricter
              [matDatepicker]="endDate" placeholder="{{placeholderFormat}}" class="picker2" formControlName="endDate"
              required>
            <mat-datepicker #endDate></mat-datepicker>
            <i aria-hidden="true" matSuffix class="icon-Calender" (click)="endDate.open()"></i>

            <mat-error
              *ngIf="FormGrp.controls['endDate'].hasError('matDatepickerParse')">{{captionsCommon.dateFormat}}</mat-error>
            <mat-error
              *ngIf="(FormGrp.controls['endDate'].hasError('required') || FormGrp.controls['endDate'].hasError('incorrect')) && !FormGrp.controls['endDate'].hasError('matDatepickerParse')">{{getErrorMessage('endDate')}}</mat-error>
            <mat-error *ngIf="FormGrp.controls['endDate'].hasError('matDatepickerMin')">{{captionsCommon.minimum}}
              {{captions.EndDate}}</mat-error>
            <mat-error *ngIf="FormGrp.controls['endDate'].hasError('matDatepickerMax')">{{captionsCommon.maximum}}
              {{captions.EndDate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- Tax on Tax -->
      <div [ngClass]="[isTaxonTaxEnabled?'whole-wrapper':'']">
        <div class="pt-3 ag_display--flex" *ngIf="!isVATEnabled">
          <div class="ag_mb--3 ag_pr--8">
            <label class="LW12 pr-2 active-label label-color form-label-normal tax-on-tax-lbl ag_display--block">{{settingCaptions.taxOnTax}}</label>
            <app-retail-toggle-switch (changeToggleEvent)="chargeTaxonTax($event)"
              formControlName="isTaxOnTax" class="toggle-switch"></app-retail-toggle-switch>
          </div>
          <div class="ag_display--flex" *ngIf="isTaxonTaxEnabled">
            <mat-form-field class="taxFormField ag_pr--8" [floatLabel]="floatLabel">
              <input [attr.automationId]="'Txt_retailCreateNew_taxName'" matInput [placeholder]="customFieldDesc[3]"
                name="taxOnTaxName" formControlName="taxOnTaxName" inputtype="nospecailchar" required />
              <mat-error *ngIf="FormGrp.controls['taxOnTaxName'].invalid">
                {{ getErrorMessage('taxOnTaxName') }}
              </mat-error>
            </mat-form-field>
          
            <div>
              <label for="taxIn" class="LW12 blckClr taxInLabel">{{customFieldDesc[2]}}</label>
              <div class='customvalue'>
                <div class="custom-group">
                  <mat-button-toggle-group class="tax-on-tax-type" (change)="linkedTaxValueType($event)"
                    [value]="selectedValueforTaxOnTax">
                    <mat-button-toggle [value]="0">{{valueSymbol(true)}}</mat-button-toggle>
                    <mat-button-toggle [value]="1">{{valueSymbol(false)}}</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
            <div>
              <mat-form-field class="pd-left pt-4 taxFormField" [floatLabel]="floatLabel">
                <input matInput [isFromTaxPercentage]="isFromTaxOnTaxPercentage" RetailCurrencyFormatter
                  [postDecimalLengthForTaxes]="taxOnTaxdecimalMaxLength" [preDecimalLength]="taxOnTaxMaxLength"
                  name="taxOnTaxAmount" formControlName="taxOnTaxAmount" type='text'
                  placeholder={{customValueplaceholderForLinkedTax}} [disabled]="ispercentValueDisabledForTaxOnTax" required/>
                <mat-error *ngIf="FormGrp.controls['taxOnTaxAmount'].invalid">
                  {{ getErrorMessage('taxOnTaxAmount') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
        <div *ngIf="isRevenueToPropertyAllowed && isTaxonTaxEnabled" class="ag_pr--8">
          <label class="LW12 pr-2 active-label label-color form-label-normal tax-on-tax-lbl ag_display--block">{{settingCaptions.linkedTaxRevenueToProperty}}</label>
          <app-retail-toggle-switch formControlName="isTaxOnTaxRevenueToProperty" class="toggle-switch"></app-retail-toggle-switch>
        </div>
        <div class="ag_pr--8" *ngIf="isTaxonTaxEnabled">
          <label class="LW12 pr-2 active-label label-color form-label-normal tax-on-tax-lbl ag_display--block">{{settingCaptions.allowRemovalForPaymentMethodTaxOnTax}}</label>
          <app-retail-toggle-switch (changeToggleEvent)="enableAllowTaxExempt($event)"
            formControlName="allowRemovalForTaxOnTax" class="toggle-switch"></app-retail-toggle-switch>
        </div>
        </div> 
  
      </div>


     <div class="pt-3 taxFormField">
      <div class="ag_mb--3 ag_pr--8">
        <label class="LW12 pr-2 active-label label-color form-label-normal ag_display--block">{{settingCaptions.DefaultTaxToAllRetailItems}}</label>
        <app-retail-toggle-switch [attr.automationId]="'Txt_retailCreateNew_isDefaultTax'"
          formControlName="isDefaultTax" class="toggle-switch">
        </app-retail-toggle-switch>
      </div>
    </div>

      <div class="pt-3 taxFormField" >
        <div class="ag_mb--3 ag_pr--8">
          <label class="LW12 pr-2 active-label label-color form-label-normal ag_display--block">{{settingCaptions.AllowRemovalForPaymentMethod}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Txt_retailCreateNew_allowRemoval'"
            formControlName="allowRemoval" class="toggle-switch">
          </app-retail-toggle-switch>
        </div>
      </div>
    </mat-dialog-content>
  </form>
  <div mat-dialog-actions class="actions-div">
    <div class="pop-button pl-4">
      <button [attr.automationId]="'Btn_retailCreateNew_click'"
        [ngClass]="( FormGrp.valid && FormGrp.dirty ) ? 'button--primary-save' : 'button--disabled'" mat-button
        [disabled]="!FormGrp.valid && FormGrp.dirty" (click)="saveTax(FormGrp.value)">{{clickbutton}}</button>
      <button [attr.automationId]="'Btn_retailCreateNew_cancel'" class="spa-button-cancel" mat-button
        (click)="onNoClick()">{{captions.setting.cancel}}</button>
    </div>
  </div>
</section>
