import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogHeaderComponent {

  @Input() title: string;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Input() count:string="";
  automationId:string;
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : '';
  }

  @Input('count')
  set setCount(value){
    if (value) {
       this.count=value?value:'';
    }
  }

  close() {
    this.closeDialog.emit();
  }
}
