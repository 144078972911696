<mat-accordion #accordion="matAccordion" [multi]="true" class="addon-collapse">
    <mat-expansion-panel class="addon__container">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="title">
                    {{CategoryAddon?.Text}}
                </div>
                <mat-checkbox (change)="setMandatoryAddon($event)" [checked]="IsCategoryLevelMandatory" (click)="$event.stopPropagation();"> {{'IsMandatory'
                    | translate}} </mat-checkbox>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="add-ons">
            <form [formGroup]="addonsFromGroup">
                <table class="table" [hidden]="addonsFromGroup?.controls?.addon_Rows?.controls?.length == 0">
                    <thead>
                        <th>{{'SNo' | translate}}</th>
                        <th>
                            <mat-checkbox [checked]="IsAllAddonsSelect" (change)="selectAllItems($event)"></mat-checkbox>
                        </th>
                        <th class="addons_itemName">{{'ItemName' | translate}}</th>
                        <th>{{'IsMandatory' | translate}}</th>
                        <th>{{'show' | translate}}</th>
                        <th>{{'WebReservations' | translate}}</th>
                        <th *ngIf="displayCRSOption">{{'CRS' | translate}}</th>
                        <th>{{'type' | translate}}</th>
                        <th>{{'inventory' | translate}}</th>
                        <th class="addons_minmax">{{'MinQty' | translate}}</th>
                        <th class="addons_minmax">{{'MaxQty' | translate}}</th>
                    </thead>
                    <tbody formArrayName="addon_Rows" class="form-input" *ngIf="addonsFromGroup">
                        <tr *ngFor="let itemrow of addonsFromGroup?.controls?.addon_Rows?.controls; let i=index;let l=last"
                            [formGroupName]="i">
                            <td>{{i+1}}</td>
                            <td>
                                <div class="form-input">
                                    <mat-checkbox formControlName="IsSelected"></mat-checkbox>
                                </div>
                            </td>
                            <td class="addons_itemName">{{itemrow?.value?.AddOnName}}</td>
                            <td>
                                <mat-checkbox formControlName="IsMandatory" [disabled]="IsCategoryLevelMandatory">
                                </mat-checkbox>
                            </td>
                            <td>
                                <mat-checkbox formControlName="IsVisible"></mat-checkbox>
                            </td>
                            <td>
                                <mat-checkbox formControlName="AvailableForWebReservation"
                                    [disabled]="IsCategoryLevelMandatory"></mat-checkbox>
                            </td>
                            <td *ngIf="displayCRSOption"> 
                                <mat-checkbox formControlName="AvailableForCRSReservation"
                                    [disabled]="IsCategoryLevelMandatory"></mat-checkbox>
                            </td>
                            <td>{{itemrow?.value?.addOnType}}</td>
                            <td>{{itemrow?.value?.Inventory}}</td>
                            <td class="addons_minmax">
                                <mat-form-field class="basic-input-field ">
                                    <input formControlName="MinQuantity" type="number" min=0 [readonly]="itemrow?.value?.SelectionType == 3 || itemrow?.value?.SelectionType == 1"
                                        matInput >
                                </mat-form-field>
                            </td>
                            <td class="addons_minmax">
                                <mat-form-field class="basic-input-field ">
                                    <input formControlName="MaxQuantity" type="number" min=0 [readonly]="itemrow?.value?.SelectionType == 3" (change)="validateAddonQuantity($event)" matInput>
                                </mat-form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="no-data" *ngIf="addonsFromGroup?.controls?.addon_Rows?.controls?.length == 0">{{'noAddonFound' | translate}}</div>
            </form>
        </div>
    </mat-expansion-panel>
</mat-accordion>