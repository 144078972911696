import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { RetailLocalization } from '../common/localization/retail-localization';
import * as _ from 'lodash'
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { BaseResponse } from '../shared/business/shared.modals';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { RetailService } from '../retail.service';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { RetailRoutes } from 'src/app/retail/retail-route';
@Component({
  selector: 'app-retail-discount-configuration',
  templateUrl: './retail-discount-configuration.component.html',
  styleUrls: ['./retail-discount-configuration.component.scss']
})
export class RetailDiscountConfigurationComponent implements OnInit {

  searchText: string = "";
  captions: any;
  header: any = [];
  applyValue: any = '0.00';
  updateDisable: boolean = true;
  selectedFieldName: string;
  selectedFieldID: number = undefined;
  tableoptions: any = [];
  copyOfDiscountConfig: any = [];
  fieldSelected: boolean = false;
  valueApplied: boolean = false;
  userEditing: boolean = false;
  IsViewOnly: boolean;
  discountConfigData: any = [];
  selecteddiscountType: any[] = [];
  disableEditButton: boolean = false;
  editData = false;
  applyValid: boolean = false;
  maxLength = myGlobals.Maxlength.PERCENTAGE;
  floatLabel: string;
  floatLabelNever: string;

  constructor(public _utilities: RetailUtilities,public localization: RetailLocalization, private http: HttpServiceCall, private retailService: RetailSetupService, private business: RetailService ) {
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnInit() {
    this.captions = this.localization.captions.retailsetup;
    this.InvokeServiceCall("GetActiveDiscountTypes", myGlobals.Host.retailManagement, HttpMethod.Get);
    this.InvokeServiceCall("GetDiscountConfiguration", myGlobals.Host.retailManagement, HttpMethod.Get);
    this.applyValue = this.LoadDecimalValue(this.applyValue);
    let getviewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals. RetailBreakPoint.DiscountConfiguration);
    this.IsViewOnly = getviewOnly && getviewOnly.view;
    this.retailService.tabLoaderEnable.next(false);
  }

  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this._utilities.ToggleLoaderWithMessage(true,this.localization.captions.lbl_processing)
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  //Edited Data
  Done(event) { 
    let updateArr = [];
    Object.keys(event[0]).forEach((prop, index) => {
      if ('details' + prop.replace("details", "") == prop) {
        event[0][prop]['discountvalue'] = this.localization.currencyToSQLFormat(event[0][prop.replace("details", "")]);
        updateArr.push(event[0][prop]);
      }
    }); 
    this.InvokeServiceCall("UpdateDiscountConfiguration", myGlobals.Host.retailManagement, HttpMethod.Put, { id: event[0].id }, updateArr);
    this.userEditing = false;
  }
 

  LoadDecimalValue(val: any): string { 
    return this.localization.localizeCurrency(val,false);
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetActiveDiscountTypes") {
      let response = <any>result.result;
      this.header = response; 
    }
    if (callDesc == "GetDiscountConfiguration" || callDesc == "UpdateDiscountConfiguration" || callDesc == "ResetDiscountConfiguration") {
      this.discountConfigData = [];
      let response = <any>result.result; 

      let data;
      response.forEach(element => {
        data = {
          'id': element.retailCategoryId,
          'category_name': element.retailCategoryName,
        }
        element.discountTypeDetails && element.discountTypeDetails.forEach((header, index) => {
          data[header.discountTypeName] = this.localization.localizeCurrency(header.discountValue,false);
          data['details' + header.discountTypeName] = {
            'discountTypeId': header.discountTypeId,
            'discountvalue': this.localization.localizeCurrency(header.discountValue,false),
            'retailCategoryId': element.retailCategoryId,
            'discountName': header.discountTypeName
          }
        });
        this.discountConfigData.push(data);
      });
      this.copyOfDiscountConfig = _.cloneDeep(this.discountConfigData);
      this.BindTableData(this.discountConfigData);
    }
    this._utilities.ToggleLoaderWithMessage(false)
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
    this._utilities.ToggleLoaderWithMessage(false)
  }
 
  selectedField(event) {
    let filterData = this.header.filter(x => x.id == event.id);
    this.selectedFieldName = filterData[0].type;
    this.fieldSelected = true;
    this.checkValid();
  } 
  applyFunc() {
    const value: number = this.localization.currencyToSQLFormat(this.applyValue);
    if (value > 100) {
      let errorMessage = this.localization.replacePlaceholders(this.captions.MaximumDiscountValue, ['MaxNumber'], [this.localization.localizeCurrency('100.00', false)]);
      this.business.openErrorDialog(errorMessage, this.localization.captions.common.Error, this.localization.captions.common.Error);
      this.applyValue = this.localization.localizeCurrency('0.00', false);
      return;
    }
    this.updateDisable = false;
    let indexvalue = this.selecteddiscountType.findIndex(x => x.discountDetails == this.selectedFieldID['id']);
    if (indexvalue == -1) {
      let newObj = {
        'discountDetails': this.selectedFieldID,
        'discountTypeId': this.selectedFieldID['id'],
        'discountvalue':  this.applyValue
      }
      this.selecteddiscountType.push(newObj);
    } else {
      this.selecteddiscountType[indexvalue]['discountvalue'] = this.applyValue;
    } 
    this.selecteddiscountType.forEach(SDT => {
      let typename = SDT.discountDetails['type'];
      this.discountConfigData.forEach(DC => {
        DC['details' + typename].discountvalue = SDT.discountvalue;
        DC[typename] = SDT.discountvalue;
      });
    });
    this.disableEditButton = true;
    this.BindTableData(this.discountConfigData);
  }

  UpdateAppliedValues() { 
    this.selecteddiscountType.forEach(x=>{
      x.discountvalue =  this.localization.currencyToSQLFormat(x.discountvalue);
    });
    console.log("this.selecteddiscountType",this.selecteddiscountType);
    this.InvokeServiceCall("ApplyAndUpdateDiscountConfiguration", myGlobals.Host.retailManagement, HttpMethod.Put, undefined, this.selecteddiscountType);
    this.updateDisable = true;
    this.selectedFieldID = undefined;
    this.applyValue = this.localization.localizeCurrency('0.00', false);
    this.fieldSelected = false;
    this.selecteddiscountType = [];

    this.applyValid = false;
    this.disableEditButton = false;
    this.BindTableData(this.discountConfigData);
  }
  resetValues() {
    this.updateDisable = true;
    this.selectedFieldID = undefined;
    this.applyValue = this.localization.localizeCurrency('0.00', false);
    this.fieldSelected = false;
    this.selecteddiscountType = [];
    this.disableEditButton = false;
    this.BindTableData(this.discountConfigData);
  }
  //Delete data
  deletedRecord(event: any) {
    let updateArr = [];
    Object.keys(event).forEach((prop, index) => {
      if ('details' + prop.replace("details", "") == prop) {
        event[prop]['discountvalue'] = 0;
        updateArr.push(event[prop]);
      }
    });

    this.InvokeServiceCall("ResetDiscountConfiguration", myGlobals.Host.retailManagement, HttpMethod.Put, { id: event.id }, updateArr);
    this.userEditing = false;
  }

  userEdit(event) {
    this.userEditing = event;
  }

  editCancelled(event) { 
    this.userEditing = false;
  }

  BindTableData(data) {
    let newhdr = [{ "title": this.captions.Category, "jsonkey": "category_name", "searchable": false, "alignType": "left", "sortable": true, "isInput": false, "isEdit": false,"isPercentage":false }];
    this.header.forEach(element => {
      let temphdr = {
        "title": element.type,
        "jsonkey": element.type,
        "alignType": "right",
        "searchable": false,
        "sortable": true,
        "type": "number",
        "isInput": true,
        "isEdit": true,
        "datatype": "decimal",
          "sortcolumndatatype": "decimal",
          "isPercentage": true
      }
      newhdr.push(temphdr);
    });
    this.tableoptions = [{
      TableHdrData: newhdr,
      TablebodyData: data,
      pagination: false,
      sortable: true,
      CustomColumn: false,
      PlaceHoldertext: this.captions.SearchByCategory,
      EnableActions: this.header.length > 0 ? true : false,
      SelectRows: false,
      SelectedSettingId: myGlobals.GridType.retail,
      Searchable: false,
      EditMoreOption: false,
      Sortable: "category_name",
      TableId: myGlobals.GridType.retail,
      disableDelete: false,
      showToggle: false,
      IsViewOnly: this.IsViewOnly,
      disableEditButton: this.disableEditButton,
      automationId: 'retailDiscountConfiguration'
    }];
  }
 

  checkValid() {
    if (this.selectedFieldID && this.applyValue) {
      this.applyValid = true;
    } else {
      this.applyValid = false;
    }
  }
}

