import { CommonApiRoutes } from 'src/app/common/common-route';
import { Injectable } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpMethod, HttpServiceCall } from '../shared/service/http-call.service';
import { CommonUtilities } from '../shared/utilities/common-utilities';
import { Host } from '../shared/globalsContant';
import { MatDialog } from '@angular/material/dialog';
import { BaseResponse } from '../shared/business/shared.modals';
import moment from "moment";
import { SessionManagerService } from './session-manager.service';
import { rGuestBaseService, ServiceRegistry } from '../shared/service/base.service';
import { UserPropertyLoginModel, UserQuickIdLoginModel } from '../shared/quick-login/quick-login.model';

@Injectable()
export class AuthenticationService extends rGuestBaseService {

  scope: string = "Spa"
  state: string = Date.now() + "" + this.utils.getRandomDecimal();
  tokenKey: string = "a5smm_utoken"
  propertyKey: string = "propertyInfo"
  url: string = "";
  tenantId: any = 1;
  locations: any[];
  propertyValues: any[];
  userSessionId: string = "userSession";

  constructor(private serviceRegistry: ServiceRegistry, private router: Router, public http: HttpServiceCall, private utils: CommonUtilities,
    
    public dialogRef: MatDialog, private route: ActivatedRoute
    , public sessionManagerService: SessionManagerService
  ) {
    super();
  }

  reset(): void {
    // reset
   }

  async login(username, password, login: any) {   
   
    await this.ServerLogin(username, password, login);
   
    return true;
  }
  ngOnInit() {
    // grab the current username
    this.tenantId = this.route.snapshot.queryParamMap.get('TenantId');
  }

  CheckPasswordExists(userName, password, confirmpassword: any) {
    this.CheckPassword(userName, password, confirmpassword);
  }

  SavePassword(userId, password, confirmpassword: any) {
    this.SavePasswordAsync(userId, password, confirmpassword)
  }

  VerifyPassword(userName, password, confirmpassword: any) {
    this.VerifyPasswordAsync(userName, password, confirmpassword);
  }

  PasswordSetting(confirmpassword: any) {
    this.PasswordSettingAsync(confirmpassword);
  }

  logout() {
    let UserName = this.utils.GetPropertyInfo("userName");
    this.ServerLogOut(UserName);
    this.UpdateSession();
    this.router.navigate(['login']);
    this.sessionManagerService.logout();
    this.serviceRegistry.resetAllServiceData();
   
  }

  getToken() {
    return this.sessionManagerService.getToken();
  }

  setToken() {
    this.sessionManagerService.setToken();
  }

  getAccessToken() {
    return this.sessionManagerService.getAccessToken();
  }

  isAuthenticated() {
    this.sessionManagerService.isAuthenticated();
  }

  async getEatecToken() {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.authentication,
        callDesc: CommonApiRoutes.EatecToken,
        method: HttpMethod.Post,
        showError: true
      }, false, true);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async getEngageToken() {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.authentication,
        callDesc: CommonApiRoutes.AcesToken,
        method: HttpMethod.Post,
        showError: true
      }, false, true);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async quickIdLogin(quickLoginData:UserQuickIdLoginModel,login:any)
  { 
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: login.successCallback.bind(login),
      error: login.errorCallback.bind(login),
      callDesc: "QuickLoginByQuickId",
      method: HttpMethod.Post,
      body: quickLoginData,
      uriParams: '',
      showError: false,
      extraParams: [false]
    });
  }

  async userIdLogin(userDetails:UserPropertyLoginModel,login:any)
  { 
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: login.successCallback.bind(login),
      error: login.errorCallback.bind(login),
      callDesc: "QuickLoginByUserId",
      method: HttpMethod.Post,
      body: userDetails,
      uriParams: '',
      showError: false,
      extraParams: [false]
    });
  }

  UpdateSession() {
    let sessionData = {
      isActive: false,
      endTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };

    var sessionId = sessionStorage.getItem(this.userSessionId) ? Number(sessionStorage.getItem(this.userSessionId)) : 0;
    if (sessionId == 0) {
      return;
    }

    // Update User session as inactive
    this.InvokeServiceCallAsync("UpdateSession", Host.authentication, HttpMethod.Put, { id: sessionId }, sessionData);

    // Remove appointment lock if locked by current session
    this.InvokeServiceCallAsync("ReleaseAppointmentLock", Host.schedule, HttpMethod.Put, { sessionId: sessionId }, []);
  }

  async ServerLogin(username: string, password: string, login: any) {
    this.tenantId = this.route.snapshot.queryParamMap.get('TenantId') != null ? this.route.snapshot.queryParamMap.get('TenantId') : 1;
    let _userModel: any = {
      UserName: username, Password: password, TenantId: this.tenantId,ProductId:1
    }
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: login.successCallback.bind(login),
      error: login.errorCallback.bind(login),
      callDesc: "Login",
      method: HttpMethod.Post,
      body: _userModel,
      uriParams: { Username: username, Password: password, TenantId: this.tenantId },
      showError: false,
      extraParams: [false]
    });
  }
  ServerLogOut(username: string) {
    this.tenantId = this.utils.GetPropertyInfo('TenantId');
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "LogOut",
      method: HttpMethod.Post,
      uriParams: { Username: username, TenantId: this.tenantId, PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
      showError: false,
      extraParams: [false]
    });
  }


  CheckPassword(userId: Int32Array, password: string, confirmpassword: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: confirmpassword.successCallback.bind(confirmpassword),
      error: confirmpassword.errorCallback.bind(confirmpassword),
      callDesc: "CheckPassword",
      method: HttpMethod.Get,
      uriParams: { UserId: userId, NewPassword: encodeURIComponent(password), TenantId: this.tenantId },
      showError: false,
      extraParams: [false]
    }, true);
  }

  VerifyPasswordAsync(userId: Int32Array, password: string, confirmpassword: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: confirmpassword.successCallback.bind(confirmpassword),
      error: confirmpassword.errorCallback.bind(confirmpassword),
      callDesc: "VerifyPassword",
      method: HttpMethod.Get,
      uriParams: { UserId: userId, NewPassword: encodeURIComponent(password), TenantId: this.tenantId },
      showError: false,
      extraParams: [false]
    });
  }
  PasswordSettingAsync(confirmpassword: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: confirmpassword.successCallback.bind(confirmpassword),
      error: confirmpassword.errorCallback.bind(confirmpassword),
      callDesc: "PasswordSetting",
      method: HttpMethod.Get,
      uriParams: { TenantId: this.tenantId },
      showError: false,
      extraParams: [false]
    },true);
  }

  SavePasswordAsync(userId: Int32Array, password: string, confirmpassword: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: confirmpassword.successCallback.bind(confirmpassword),
      error: confirmpassword.errorCallback.bind(confirmpassword),
      callDesc: "SavePassword",
      method: HttpMethod.Post,

      uriParams: { UserId: userId, NewPassword: encodeURIComponent(password), TenantId: this.tenantId, PropertyId: this.utils.GetPropertyInfo('PropertyId') },
      showError: false,
      extraParams: [false]
    }, true);
  }

  async CreateSession(sessionData, login: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: login.successCallback.bind(login),
      error: login.errorCallback.bind(login),
      callDesc: "CreateSession",
      body: sessionData,
      method: HttpMethod.Post,
      showError: false,
      extraParams: []
    });
  }

  async AuthorizeBySession(request: any) {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.authentication,
        body: request,
        callDesc: CommonApiRoutes.AuthorizeBySession,
        method: HttpMethod.Post,
        showError: true
      }, false, true);
    }catch (e) {
      console.log(e);
      return null;
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "LogOut") {
      //LogOut
    }

    if (callDesc == "UpdateSession") {
      console.log("Session Updated")
    }
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {

    if (callDesc == "LogOut") {
       //LogOut
    }
  }

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

public UpdateQuickIdSession()
{
  let sessionData = {
    isActive: false,
    endTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
  };
  const sessionId:string = sessionStorage.getItem('quickIdUserSession');
  if (!sessionId) {
    return;
  }
   this.http.CallApiWithCallback<any>({
    host: Host.authentication,
    success: this.successCallback.bind(this),
    error: this.errorCallback.bind(this),
    callDesc: "UpdateSession",
    body: sessionData,
    method: HttpMethod.Put,
    showError: false,
    extraParams: [],
    uriParams : { sessionId: sessionId },
  });
    sessionStorage.removeItem('quickIdUserSession');
    sessionStorage.removeItem('quickIdJwt');
    sessionStorage.removeItem('quickIdUser');
    sessionStorage.removeItem('quickIdRoleId');
}

}

