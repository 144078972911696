
import { Injectable } from "@angular/core";
import { DataAwaiterService } from "./shared/services/awaiters/data.awaiter.service";
import { RetailEventSubscriber } from "./shared/services/retail-subscriber/retail.event.subscriber";

@Injectable()
export class AppBusiness {
    constructor(private _retailEventSubscriber: RetailEventSubscriber
        , private _setDataAwaiters: DataAwaiterService
    ) {
    }
}

export const applyMixins = (baseClass: any, extendedClasses: any[]) => {
    extendedClasses.forEach(extendedClass => {
        Object.getOwnPropertyNames(extendedClass.prototype).forEach(name => {
            Object.defineProperty(
                baseClass.prototype,
                name,
                Object.getOwnPropertyDescriptor(extendedClass.prototype, name) || Object.create(null)
            )
        })
    })
}