import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { FormTextAreaService } from '@app/shared/dynamicform/components/form-textarea/form-textarea.service';
import { PartyNotes } from '@app/shared/models/global.interface';
import { RatePlanSummary } from '@app/shared/models/RatePlanDTO';
import { AllowedSettingsPublish } from '@app/shared/models/SignalRDto';
import { LayoutFunctions } from '@app/shared/utilities/layout-functions';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { RestaurantPoliciesComponent } from '@components/create-update-party-tab-layout/selections/restaurant-policies/restaurant-policies.component';
import { buttonTypes, ComponentTypes, ConciergeTrackingType, OperationResultState, PartyNoteType, ReservationType, SlottingMode } from '@constants/commonenums';
import { popupDialogDimension } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { FormChipService } from '@dynamicform/components/form-chip/form-chip.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { DynamicFormService } from '@dynamicform/service/dynamic-form.service';
import { BookingChargeType, Category, LayoutDTO, NamedEntityDTO, PartyNoteDTO, SettingsDTO, TableSuggestionMode } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { FacadeService } from '@services/facade.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { cloneDeep, uniqBy, sortBy, isEqual, get} from 'lodash';
import moment from 'moment';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { HotelConciergeListComponent } from '../hotel-concierge-list/hotel-concierge-list.component';
import { TableSelectionComponent } from '../table-selection/table-selection.component';
import _ from 'lodash';
import { HostDTO } from '@app/shared/models/HostDTO';
import { SettingsService } from '@app/shared/services/settings.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';

@Component({
  selector: 'app-guest-data',
  templateUrl: './guest-data.component.html',
  styleUrls: ['./guest-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GuestDataComponent implements OnInit, OnDestroy {
  selectTableConfig: FieldConfig[];
  coverTypeConfig: FieldConfig[];
  hotelConFig: FieldConfig[];
  pagerConfig: FieldConfig[];
  partyStatusConfig: FieldConfig[];
  tablesText: any = '';
  selectedIndex: number;
  selectedTables: string[] = [];
  selectedTableIds = [];
  preSelectedTableIds = [];
  selectedPartyStatus: number;
  subscriptions: Subscription = new Subscription();
  tableSuggestionMode: any;
  _settings: SettingsDTO = {} as SettingsDTO;
  layoutSettings: LayoutDTO = {} as LayoutDTO;
  suggestedTableIds: number[] = [];
  showSuggestedTables = false;
  buttonTertiary: ButtonValue;
  selectedDateText = '';
  config: FieldConfig[] = [];
  mobileConfig: FieldConfig[] = [];
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @ViewChild('mobileNumberForm') mobileNumberForm: DynamicFormComponent;
  @ViewChild('partyStatusForm') partyStatusForm: DynamicFormComponent;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  @ViewChild('salesContactForm') salesContactForm: DynamicFormComponent;
  selectedPartyPreferenceTags: any[] = [];
  defaultHost: any;
  hosts: NamedEntityDTO[] = [];
  selectedHost: any;
  ReservationType = ReservationType;
  selectedAreaName: string = "";
  selectedSeatingType: string = "";
  isNewlyandFirstSelectedTable = true;
  notFirstTime: boolean;
  dynamicFormInitialValues: any;
  firstFormInitialValues: any;
  lastFormInitialValues: any;
  formInitialValues: any = [];
  restaurantPolicyAvailable: Boolean = false;
  restaurantPolicy: any = [];
  hotelConciergeConfig: FieldConfig[];
  ConciergeTrackingType = ConciergeTrackingType;
  conciergeTrackingList;
  hotelName = this.translateService.instant('hotelLbl');
  conciergeName = this.translateService.instant('conciergeLbl');
  partyNotes: PartyNotes[] = [];
  categories: Category[] = [];
  showConcierge = false;
  holdFormValidation = false;
  isFirstTime = true;
  defaultTableText = '';
  RatePlanAmount: number = 0;
  selectedSpecialMealName: string;
  selectedSpecialMealAmount: number = 0;
  totalAmount: number = 0;
  ratePlanSummary: RatePlanSummary[] = [];
  selectedSpecialMealTotalAmount: number = 0;
  includeOthers = false;
  othersId : number[] = [];
  customPartyNotes: any[] = [];
  taxAmount: number =0;
  serviceChargeAmount: number = 0;
  taxOnServiceChargeAmount: number = 0;
  showSalesContact:boolean = false;
  users: HostDTO[] = []
  salesContactConfig: FieldConfig[];
  numberToBeFixed = 2;

  constructor(public dialog: MatDialog, public partyService: PartyService, private cs: CacheService, private ps: PopupService, @Inject(COMPONENTINPUT) public data, public translateService: TranslateService,
    public facadeService: FacadeService, private formChipService: FormChipService, private dfs: DynamicFormService, public _as: AppService,
    private formTextAreaService: FormTextAreaService, private lf: LayoutFunctions,private settingsService: SettingsService,private dashboardFunctions: DashboardFunctions) {
      this.subscriptions.add(this.cs.settingsOperationName.subscribe(op => {
        if (this.notFirstTime && AllowedSettingsPublish[op]) {
          this.resetForm();
          this.goToPrevTab(true);
        }
        this.notFirstTime = true;
      }));
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      if (this._settings.PropertySetting && this._settings.PropertySetting[0]?.TrackingofSalesPerson) {
        this.showSalesContact = true;
      }
      this.categories = this._settings.Categories.filter(category => category.Text != "Reasons");
      if (data && this._as.OTASourceId.includes(data.PartySourceId))
      {
        this.includeOthers = true;
      }
      if(data)
      {
        this.othersId = this.data.Notes.filter(x => x.Type == PartyNoteType.FreeFormNote).map(({ RelatedId }) => RelatedId);
      }
      this.partyNotes = Utilities.getRestaurantPredefinedPartyNotes(this.categories, this.includeOthers, this.othersId);
      this.tableSuggestionMode = this._settings.General.TableSuggestionMode;
      this.notFirstTime = true;
      if (this._settings.PropertySetting.length > 0 && (this._settings.PropertySetting[0].PreReservationMessage && this._settings.PropertySetting[0].PreReservationMessageTitle)) {
        this.restaurantPolicyAvailable = true;
        this.restaurantPolicy = this._settings.PropertySetting[0];
      }
    }));
    this.subscriptions.add(this.cs.layout.subscribe(layout => {
      this.layoutSettings = layout;
    }));
  }


  ngOnInit() {
    this.setDefaultTabletext();
    this.tablesText = this.getTablesText();
    this.setFormConfig();
    this.setEditData();
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === GuestDataComponent);
    if(this.showSalesContact) {
      this.salesContactUsers();
    }
  }

  ngAfterViewInit() {
    this.addSubscriptions();
    this.loadHosts();

    if (!this.holdFormValidation) {
      this.dynamicFormChange();
      this.components.forEach((x, index) => {
        this.formInitialValues.push({ formIndex: index, values: x.value });
      });
    }
  }

  addSubscriptions() {
    this.subscriptions.add(this.partyService.tableSelected.subscribe(val => {
      if (this.isNewlyandFirstSelectedTable) {
        this.preSelectedTableIds = this.selectedTableIds;
      }
      this.selectedTableIds = val;
      this.isNewlyandFirstSelectedTable = false;
    }));

    this.subscriptions.add(this.partyService.reservationFormGroup.valueChanges.subscribe(value => {      
      switch (this.partyService.reservationType) {
        case ReservationType.Reservation:
          this.partyService.isStandbySlotSelected ? this.ps.confirmationDialog = null : this.setConfirmationDialogByType(value);
          break;
        case ReservationType.StandbyParties:
          this.partyService.standbyConversion ? this.setConfirmationDialogByType(value) : this.ps.confirmationDialog = null;
          break;
        default:
          break;
      }
    }));

    this.subscriptions.add(this.partyService.tabChange$.subscribe((data) => {
      if (data === this.selectedIndex) {
        switch (this.partyService.reservationType) {
          case ReservationType.Reservation:
            this.partyService.isStandbySlotSelected ? this.removeConciergePhnNumCtrl('mobile', true) : this.retainConciergePhoneNumber();
            break;
          case ReservationType.StandbyParties:
            this.partyService.standbyConversion ? this.retainConciergePhoneNumber() : this.removeConciergePhnNumCtrl('mobile', true);
            break;
          default:
            break;
        }
        this.updateFormValidity();
        let selectedDate, selectedSlot;
        let selectedArea = this.partyService.reservationFormGroup.get('selectedArea').value;
        const areaName = this.getSeatingAreaName(selectedArea);
        if (!areaName || areaName == "")
          this.selectedAreaName = this.translateService.instant('anySeatingArea');
        else
          this.selectedAreaName = areaName;

        let selectedSeatingType = this.partyService.reservationFormGroup.get('selectedType').value;
        const seatingType = this.getSeatingType(selectedSeatingType);
        if (!seatingType || seatingType == "") {
          this.selectedSeatingType = this.translateService.instant('anySeatingType');
        }
        else {
          this.selectedSeatingType = seatingType;
        }
        let selectedTable = this.partyService.reservationFormGroup.get('selectedTable').value;
        if (!selectedTable || selectedTable == '') {
          this.tablesText = this.translateService.instant('AssignTables');
          this.selectedTableIds = [];
        }
        if (this.partyService.reservationType === ReservationType.Waitlist) {
          // selectedDate = moment(this.partyService.reservationFormGroup.get('selectedDate').value).format('MMM D, YYYY') + ' ';
          selectedSlot = this.partyService.reservationFormGroup.value.selectedTime;
          selectedSlot != 'Auto' && selectedSlot != 'Default' ? selectedSlot = this.translateService.instant('Manual')+' - ' + selectedSlot : selectedSlot;
          if (selectedSlot == 'Auto') {
            if (this.partyService.autoWaitTime.SeatingTime) {
              //selectedSlot = 'Auto - Immediate Seating';
              const time = new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
              const totalWaitMins = Utilities.diffBetweenDatesInMinutes(new Date(this.partyService.autoWaitTime.SeatingTime), time); //differenceInMinutes(this.partyService.autoWaitTime.SeatingTime, time);
              selectedSlot = totalWaitMins <= 0 ? selectedSlot = this.translateService.instant('auto')+' - ' + this.translateService.instant('Immediate Seating') :
                `${this.translateService.instant('autoTime')} ${totalWaitMins} ${this.translateService.instant('mins')}`;
            }
            else selectedSlot = this.translateService.instant('auto')+' - ' + this.translateService.instant("Can't Estimate");
          }
          this.selectedDateText = selectedSlot;
        }
        if (this.partyService.reservationType === ReservationType.Reservation || this.partyService.reservationType === ReservationType.StandbyParties ||
          this.partyService.reservationType === ReservationType.PrivateLessonBooking) {
          // selectedDate = moment(this.partyService.reservationFormGroup.get('selectedDate').value).format('MMM D, YYYY') + ' ';
          selectedDate = moment(this.partyService.reservationFormGroup.value.selectedTime.DateTime)?.locale(this.dashboardFunctions.getLocaleCode()).format('MMM D, YYYY') + ' ';
          selectedSlot = moment(this.partyService.reservationFormGroup.value.selectedTime.DateTime)?.locale(this.dashboardFunctions.getLocaleCode()).format('hh:mm A');
          this.selectedDateText = selectedDate + selectedSlot;
        }
      }
    }));

    this.subscriptions.add(this.partyService.slotAvailabilitySubject$.subscribe(async (slots) => {
      if (!(this.partyService.reservationType === ReservationType.Waitlist && !this.data) && !slots.Payload) {
        let tempTableIds = [];
        tempTableIds = this.partyService.reservationFormGroup.get('selectedTable').value;
        this.tablesText = '';
        this.setDefaultTabletext();
        this.partyService.reservationFormGroup.get('selectedTable').setValue(null);
        this.showSuggestedTables = false;
        this.suggestedTableIds = [];
        this.selectedTableIds = [];
        this.setTableSuggestionMode(slots);
        this.setTableSuggestionBtnAndText(tempTableIds);

        if (tempTableIds && tempTableIds.length > 0 && this.data && this.isFirstTime) {
          this.partyService.reservationFormGroup.get('selectedTable').setValue(tempTableIds);
          this.tablesText = tempTableIds.length > 0 ? Utilities.getTableNamesFromStandaloneTables(tempTableIds, this.layoutSettings.FloorPlans) : this.defaultTableText;
          this.selectedTables = this.tablesText;
          this.selectedTableIds = cloneDeep(tempTableIds);
          this.showSuggestedTables = false;
        }

        if (this.suggestedTableIds.length === 0) {
          this.showSuggestedTables = false
        }
        if (!this.isFirstTime) {
          await this.getRatePlanAmount(this.selectedTableIds);
          if (this.partyService.reservationFormGroup.value.selectedCoverTypes && this.partyService.reservationFormGroup.value.selectedCoverTypes.length > 0)
            this.selectedSpecialMealTotalAmount = this.selectedSpecialMealAmount;
          else {
            if (this.partyService.reservationFormGroup.value.selectedSpecialMeal)
              this.selectedSpecialMealTotalAmount = this._settings.SpecialMeals.filter(sm => sm.Id == this.partyService.reservationFormGroup.value.selectedSpecialMeal)[0].Price * this.partyService.selectedSize;
              this.selectedSpecialMealAmount = this.selectedSpecialMealTotalAmount;
            }
          this.totalAmount = this.formatNumber((this.selectedSpecialMealTotalAmount + this.RatePlanAmount ),this.numberToBeFixed) ;
          let selectedmeal = this._settings.SpecialMeals.filter(sm => sm.Id == this.partyService.reservationFormGroup.value.selectedSpecialMeal)[0];
          this.taxAmount  = this.formatNumber(Utilities.getTax(this.cs.settings.value.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax), this._as.restaurantId,this.selectedSpecialMealTotalAmount,this.RatePlanAmount,selectedmeal, this._settings.General.DaylightDelta),this.numberToBeFixed)
          
          var serviceCharge = Utilities.getServiceChargeWithServiceChargeTaxAmount(this.cs.settings.value.BookingCharges,this.totalAmount, this._as.restaurantId,selectedmeal, this._settings.General.DaylightDelta )
          this.serviceChargeAmount =  this.formatNumber(serviceCharge?.ServiceChargeAmount,this.numberToBeFixed);
          this.taxOnServiceChargeAmount = this.formatNumber(serviceCharge?.ServiceChargeTaxAmount,this.numberToBeFixed);    
        }
        this.isFirstTime = false;
      }
    }));

    this.subscriptions.add(this.partyService.selectedSpecialMealName.subscribe(val => {
      this.selectedSpecialMealName = val;
    }));
    this.subscriptions.add(this.partyService.selectedSpecialMealPrice.subscribe(async val => {
      this.selectedSpecialMealAmount = val;
      this.RatePlanAmount = (this.data && this.data.RatePlanAmount) ? this.data.RatePlanAmount : await this.getRatePlanAmount(this.selectedTableIds);
      this.selectedSpecialMealTotalAmount = this.selectedSpecialMealAmount;
      this.totalAmount = this.formatNumber((this.selectedSpecialMealTotalAmount + this.RatePlanAmount ),this.numberToBeFixed) ;
      let selectedmeal = this._settings.SpecialMeals.filter(sm => sm.Id == this.partyService.reservationFormGroup.value.selectedSpecialMeal)[0];
      
      this.taxAmount  = this.formatNumber(Utilities.getTax(this.cs.settings.value.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax), this._as.restaurantId,this.selectedSpecialMealTotalAmount,this.RatePlanAmount,selectedmeal, this._settings.General.DaylightDelta),this.numberToBeFixed)
      var serviceCharge = Utilities.getServiceChargeWithServiceChargeTaxAmount(this.cs.settings.value.BookingCharges,this.totalAmount, this._as.restaurantId,selectedmeal, this._settings.General.DaylightDelta )
      this.serviceChargeAmount =  this.formatNumber(serviceCharge?.ServiceChargeAmount,this.numberToBeFixed);
      this.taxOnServiceChargeAmount = this.formatNumber(serviceCharge?.ServiceChargeTaxAmount,this.numberToBeFixed);
    
    }));
  }

  getSeatingAreaName(seatingAreaValue?: any) {
    if (!seatingAreaValue) { return ""; }
    let seatingAreaId = seatingAreaValue.Id ? seatingAreaValue.Id : seatingAreaValue;
    let selectedSeatingArea = this._settings.SeatingAreas.filter(area => area.Id == seatingAreaId);
    return selectedSeatingArea.length > 0 ? selectedSeatingArea[0].Name : "";
  }

  getSeatingType(seatingTypeValue?: any) {
    if (!seatingTypeValue) { return ""; }
    let seatingTypeId = seatingTypeValue.Id ? seatingTypeValue.Id : seatingTypeValue;
    let selectedSeatingType = this._settings.SeatingTypes.filter(type => type.Id == seatingTypeId);
    return selectedSeatingType.length > 0 ? selectedSeatingType[0].Description : "";
  }
  
  formatNumber(n, fixed){
    return ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);
  }

  setFormConfig() {
    this.selectTableConfig = [
      {
        type: 'select',
        label: 'seatingArea',
        name: 'seatingArea',
        options: [{ id: 1, value: 1 }],
        class: '',
        showErrorText: true,
        isTranslate: false,
        cellClick: (event) => { this.selectedTable(event) }
      }
    ];
    this.buttonTertiary = {
      type: buttonTypes.actionSecondary
    };
    this.pagerConfig = [{
      type: 'input',
      name: 'pagerNumber',
      inputType: 'text',
      label: 'pager',
      class: 'guest-data__pager',
      value: this.setPagerNumber()
    }];

    this.partyStatusConfig = [{
      type: 'select',
      name: 'partyStatus',
      options: this.setPartyStatusOptions(),
      label: this.translateService.instant('partyStatus'),
      class: 'guest-data__party-status',
      showColor: true,
      cellClick: (event) => this.setPartyStatus(event),
      value:  this.data && this.data.StatusId ? this.data.StatusId : -1
    }];

    this.salesContactConfig = [{
      type: 'autocomplete',
      name: 'salescontacts',
      options: [],
      class: 'activity-booking__seating-type',
      showErrorText: true,
      isTranslate: false,
      autoCompleteWithId: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      isChipsEnabled: true,
      multiChipValues: [],
    }];

    this.bindGuestPartyNotes();
  }

  setPartyStatusOptions(){
    let options =[];
    if(ReservationType.Reservation == this.partyService.reservationType){
      options = [{id: -1,value:this.translateService.instant('none'),color:null},...this._settings.Statuses.filter(status => status.AppliesToReservations).map(status=>({id:status.Id,value:status.Name,color:'rgba(' + status.Color.R + ',' + status.Color.G + ',' + status.Color.B + ',' + status.Color.A + ')'}))]
    }
    else if(ReservationType.Waitlist == this.partyService.reservationType){
      options = [{id: -1,value:this.translateService.instant('none'),color:null},...this._settings.Statuses.filter(status => status.AppliesToWalkIns).map(status=>({id:status.Id,value:status.Name,color:'rgba(' + status.Color.R + ',' + status.Color.G + ',' + status.Color.B + ',' + status.Color.A + ')'}))]
    }
    return options;
  }

  bindGuestPartyNotes() {
    this.config = [];
    this.partyNotes.forEach((partyNote, index) => {
      const tag = {
        type: 'chip',
        name: `preferredPartyTags${index}`,
        inputType: 'text',
        label: partyNote.CategoryName,
        class: 'guest-view__preferred-tags-edit', // Do not modify the className, there is a dependency for editGuest details.
        options: this.getOptions(partyNote.partyNotes, partyNote.Color),
        multipleChip: true,
        multipleOther: true
      }
      this.config.push(tag);
    })
    if (get(this.data, ['Notes'], []).length) {
      this.customPartyNotes = this.data.Notes.filter(note => !note.RelatedId && note.Type == PartyNoteType.FreeFormNote);
      if (this.customPartyNotes && this.customPartyNotes.length) {
        const tag = {
          type: 'chip',
          name: `partyfreeTags`,
          inputType: 'text',
          label: 'freeNoteText',
          class: 'guest-view__free-tags-edit',
          options: this.getFreeTextOptions(),
          multipleChip: true,
          multipleOther: true
        }
        this.config.unshift(tag);
      }
    }

  }

  getFreeTextOptions() {
    let freeTextOptions = [];
    let freeNotes = this.data.Notes.filter(notes => !notes.RelatedId);
    freeNotes.forEach((notes) => {
      freeTextOptions.push({
        Id: notes.Id,
        Label: notes.Label,
        Name: notes.Text,
        Icon: 'None',
        Value: ''
      })
    })
    return freeTextOptions;
  }

  getOptions(partyNotes, categoryColor): any {
    let configOptios = [];
    partyNotes.forEach((notes) => {
      configOptios.push({
        Id: notes.Id,
        Label: notes.Label,
        Name: notes.Text,
        Icon: notes.Icon,
        Value: '',
        color: categoryColor
      });
    })
    return configOptios;
  }

  clearField(ev) {
    this.dynamicForm.form.get('searchText').reset();
  }

  setEditData() {
    if (this.data && this.data.TableNames && this.data.TableNames.length > 0) {
      this.setTableIds();
      this.data.TableNames.forEach(names => {
        this.selectedTables.push(names);
      });
    }
    else if (this.data && this.data.TableIds.length > 0) {
      this.setTableIds();
      let tablenames = Utilities.getTableNamesFromStandaloneTables(this.selectedTableIds, this.layoutSettings.FloorPlans);
      if (tablenames.length > 0) {
        this.selectedTables = tablenames;
      }
    }
    if (this.data && this.data.Notes && this.data.Notes.length > 0) {
      let filteredNotes = this.data.Notes.filter(_note => _note.Type == PartyNoteType.SpecialMeal || _note.Type == PartyNoteType.PromoCode);
      if (filteredNotes && filteredNotes.length > 0) {
        filteredNotes.forEach(element => {
          this.partyService.partyNotes.push(element);
        });
      }
    }
    if (this.data && this.data.ConciergeId) {
      this.holdFormValidation = true;
      this.subscriptions.add(this.partyService.getConcierge(this.data.ConciergeId).subscribe(data => {
        this.setUpConcierge(data.Payload);
        this.components.forEach((x, index) => {
          this.formInitialValues.push({ formIndex: index, values: x.value });
        });
        this.dynamicFormChange();
        this.holdFormValidation = false;
      }));
    }
    if (this.data && this.data.SeatingTypeId) {
      this.partyService.reservationFormGroup.get('selectedType').setValue(this.data.SeatingTypeId);
    }
    if (this.data && this.data.RatePlanAmount) {
      if (this.selectedTableIds && this.selectedTableIds.length == 1) {
        const seatingTypeId = Utilities.getSeatingTypeFromStandaloneTables(this.selectedTableIds[0], this.layoutSettings.FloorPlans);
        let selectedSeatingType = this._settings.SeatingTypes.filter(type => type.Id == seatingTypeId);
        const seatingTypeName = selectedSeatingType.length > 0 ? selectedSeatingType[0].Description : "";
        const _RP: RatePlanSummary = {
          SeatingTypeId: seatingTypeId,
          SeatingType: seatingTypeName,
          RatePlanAmount: this.data.RatePlanAmount,
          SeatCount: 1
        };
        this.ratePlanSummary.push(_RP);
      }
      else if (this.selectedTableIds && this.selectedTableIds.length > 1) {
        const _RP: RatePlanSummary = {
          SeatingTypeId: null,
          SeatingType: "Seating Charges",
          RatePlanAmount: this.data.RatePlanAmount,
          SeatCount: this.selectedTableIds.length
        };
        this.ratePlanSummary.push(_RP);
      }
      this.RatePlanAmount = this.data.RatePlanAmount;
      this.selectedSpecialMealTotalAmount = this.selectedSpecialMealAmount;
      this.totalAmount = this.selectedSpecialMealTotalAmount + this.RatePlanAmount;
    }
    if (this.data && this.data.Notes && this.data.Notes.length > 0) {
      this.mapPartyNotes();
      if (this.customPartyNotes.length) {
        this.mapCustomPartyNotes();
      }
    }
    if(this.data && this.data.StatusId){
      this.partyService.reservationFormGroup.get("selectedPartyStatus").setValue(this.data.StatusId, { emitEvent: false });
    }

  }

  mapPartyNotes() {
    const preferredTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('preferredPartyTags'));
    this.data.Notes.forEach((note) => {
      preferredTags.forEach((fieldConfig) => {
        if (note.Type == PartyNoteType.FreeFormNote || note.Type == PartyNoteType.PredefinedNote || note.Type == PartyNoteType.SpecialRequest) {
          const filteredNotes = fieldConfig.options.find(data => data.Id === note.RelatedId);
          if (filteredNotes) {
            filteredNotes.setSelected = true;
            filteredNotes.noteId = note.Id;
            if (filteredNotes.Name.includes('Others')) {
              let otherNotesName = `otherNotes${filteredNotes.Id}`;
              if (filteredNotes.setSelected) {
                const otherNotes: FieldConfig = {
                  name: otherNotesName + '$multinotes' + note.Id,
                  type: 'textarea',
                  inputType: 'text',
                  label: 'notes',
                  showHint: true,
                  class: 'guest-data__party-other-tags',
                  charLength: 1000,
                  value: note.Text,
                  showRemove: true
                };
                const preferredTagsIndex = this.config.findIndex(preferenceConfig => preferenceConfig.name == fieldConfig.name);
                this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
              }
            }
            this.partyService.partyNotes.push(note);
          }
        }
      })
    });
    this.config = [...this.config];
  }

  mapCustomPartyNotes() {
    const freeTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('partyfreeTags'));
    this.data.Notes.forEach(note => {
      freeTags.forEach(fieldConfig => {
        const filteredNotes = fieldConfig.options.find(data => data.Id === note.Id);
        if (filteredNotes) {
          filteredNotes.setSelected = true;
          this.partyService.partyNotes.push(note);
        }
      })
    });
  }

  getSlotDate() {
    if (this.partyService.reservationFormGroup.value.selectedTime) {
      const selectedDate = moment(this.partyService.reservationFormGroup.value.selectedTime.DateTime)?.locale(this.dashboardFunctions.getLocaleCode()).format('MMM D, YYYY') + ' ';
      const selectedSlot = moment(this.partyService.reservationFormGroup.value.selectedTime.DateTime)?.locale(this.dashboardFunctions.getLocaleCode()).format('hh:mm A');
      return selectedDate + selectedSlot;
    }
  }

  getCovers() {
    let totalCovers = 0;
    this.partyService.reservationFormGroup.get('selectedCoverTypes').value.forEach((covers) => {
      if (covers.Covers) {
        totalCovers = totalCovers + Number(covers.Covers);
      }
    });
    return totalCovers.toString();
  }

  getTablesText() {
    if (this.selectedTables && this.selectedTables.length > 0) {
      return this.selectedTables.toString();
    } else {
      return this.defaultTableText;
    }
  }

  setTableSuggestionMode(slots) {
    if (slots && slots.StandaloneTableIds && this.tableSuggestionMode === TableSuggestionMode.SuggestAndAssign) {
      this.suggestedTableIds = slots.StandaloneTableIds;
      this.partyService.reservationFormGroup.get('selectedTable').setValue(slots.StandaloneTableIds);
      this.tablesText = Utilities.getTableNamesFromStandaloneTables(slots.StandaloneTableIds, this.layoutSettings.FloorPlans);
    } else if (slots && !slots.StandaloneTableIds && this.tableSuggestionMode === TableSuggestionMode.SuggestAndAssign) {
      this.partyService.reservationFormGroup.get('selectedTable').setValue(null);
      this.partyService.reservationFormGroup.controls.selectedTable.setValue(null);
    } else if (slots && slots.StandaloneTableIds && this.tableSuggestionMode === TableSuggestionMode.Suggest) {
      this.suggestedTableIds = slots.StandaloneTableIds;
    } else if (slots && !slots.StandaloneTableIds && this.tableSuggestionMode === TableSuggestionMode.Suggest) {
      this.partyService.reservationFormGroup.get('selectedTable').setValue(null);
      this.partyService.reservationFormGroup.controls.selectedTable.setValue(null);
    }
  }

  async setTableSuggestionBtnAndText(selectedTable) {
    let tempTableNames = [];
    if (this.suggestedTableIds.length > 0)
      tempTableNames = Utilities.getTableNamesFromStandaloneTables(this.suggestedTableIds, this.layoutSettings.FloorPlans);

    switch (this.tableSuggestionMode) {
      case TableSuggestionMode.SuggestAndAssign:
        if (this.selectedTableIds.length === 0 || (this.suggestedTableIds.length > 0 && !(isEqual(sortBy(this.suggestedTableIds), sortBy(this.selectedTableIds))))) {
          this.tablesText = tempTableNames;
          this.selectedTableIds = selectedTable?.length ? selectedTable : this.suggestedTableIds;
          this.selectedTables = this.tablesText;
          this.partyService.reservationFormGroup.get('selectedTable').setValue(this.suggestedTableIds);
          this.isFirstTime ? await this.getRatePlanAmount(this.selectedTableIds) : '';
          this.selectedSpecialMealTotalAmount = this.selectedSpecialMealAmount;
          this.totalAmount = this.formatNumber((this.selectedSpecialMealTotalAmount + this.RatePlanAmount ),this.numberToBeFixed) ;
          let selectedmeal = this._settings.SpecialMeals.filter(sm => sm.Id == this.partyService.reservationFormGroup.value.selectedSpecialMeal)[0];
          
          this.taxAmount =  this.formatNumber(Utilities.getTax(this.cs.settings.value.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax), this._as.restaurantId,this.selectedSpecialMealTotalAmount,this.RatePlanAmount,selectedmeal, this._settings.General.DaylightDelta),this.numberToBeFixed)
         
          var serviceCharge = Utilities.getServiceChargeWithServiceChargeTaxAmount(this.cs.settings.value.BookingCharges,this.totalAmount, this._as.restaurantId,selectedmeal, this._settings.General.DaylightDelta )
          this.serviceChargeAmount =  this.formatNumber(serviceCharge?.ServiceChargeAmount,this.numberToBeFixed);
          this.taxOnServiceChargeAmount = this.formatNumber(serviceCharge?.ServiceChargeTaxAmount,this.numberToBeFixed);    
          this.SetSeatingAreaAndType(this.selectedTableIds);
        }
        else if (!this.suggestedTableIds || this.suggestedTableIds.length == 0) {
          this.selectedTableIds = [];
          this.selectedTables = [];
          this.tablesText = this.defaultTableText;
        }
        this.showSuggestedTables = false;
        break;

      case TableSuggestionMode.Suggest:
        if (this.selectedTableIds.length == 0 && (this.selectedTableIds.toString() != this.suggestedTableIds.toString())
          || (this.suggestedTableIds.length > 0 && !(isEqual(sortBy(this.suggestedTableIds), sortBy(this.selectedTableIds))))) {
          this.showSuggestedTables = true;
          let tempBtnName = this.translateService.instant('suggestedTable') + tempTableNames;
          this.buttonTertiary.label = tempBtnName;
        } else {
          this.showSuggestedTables = false;
        }
        this.selectedTableIds = [];
        this.selectedTables = [];
        this.tablesText = this.defaultTableText;
        break;

      case TableSuggestionMode.Never:
      case TableSuggestionMode.NeverAssign: 
        this.showSuggestedTables = false;
        this.selectedTableIds = [];
        this.selectedTables = [];
        this.tablesText = this.defaultTableText;
        break;
    }

    if (!this.tablesText.toString()) {
      this.tablesText = this.getTablesText();
    }
  }

  async assignSuggestedTable(event) {
    this.showSuggestedTables = false;
    this.tablesText = Utilities.getTableNamesFromStandaloneTables(this.suggestedTableIds, this.layoutSettings.FloorPlans);
    this.partyService.reservationFormGroup.get('selectedTable').setValue(this.suggestedTableIds);
    this.selectedTableIds = this.suggestedTableIds;
    this.preSelectedTableIds = this.suggestedTableIds;
    this.selectedTables = Utilities.getTableNamesFromStandaloneTables(this.suggestedTableIds, this.layoutSettings.FloorPlans);
    await this.getRatePlanAmount(this.selectedTableIds);
    this.selectedSpecialMealTotalAmount = this.selectedSpecialMealAmount;
    this.totalAmount = this.formatNumber((this.selectedSpecialMealTotalAmount + this.RatePlanAmount ),this.numberToBeFixed) ;
    let selectedmeal = this._settings.SpecialMeals.filter(sm => sm.Id == this.partyService.reservationFormGroup.value.selectedSpecialMeal)[0];
    
    this.taxAmount =  this.formatNumber(Utilities.getTax(this.cs.settings.value.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax), this._as.restaurantId,this.selectedSpecialMealTotalAmount,this.RatePlanAmount,selectedmeal, this._settings.General.DaylightDelta),this.numberToBeFixed)
   
    var serviceCharge = Utilities.getServiceChargeWithServiceChargeTaxAmount(this.cs.settings.value.BookingCharges,this.totalAmount, this._as.restaurantId,selectedmeal, this._settings.General.DaylightDelta )
    this.serviceChargeAmount =  this.formatNumber(serviceCharge?.ServiceChargeAmount,this.numberToBeFixed);
    this.taxOnServiceChargeAmount = this.formatNumber(serviceCharge?.ServiceChargeTaxAmount,this.numberToBeFixed);    

    
    this.SetSeatingAreaAndType(this.selectedTableIds);
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
    this.updateFormValidity();
  }

  selectedTable(event) {
    let clonedInputData = cloneDeep(this.data);
    this.isNewlyandFirstSelectedTable = true;
    let cancelledActionSubscription: ISubscription = null;
    let confirmActionSubscription: ISubscription = null;

    if (this.selectedTableIds.length > 0) {
      this.partyService.reservationFormGroup.get('selectedTable').setValue(this.selectedTableIds);
    }
    clonedInputData ? clonedInputData.TableIds = this.selectedTableIds : clonedInputData;
    const componentDetails: ComponentDetails = {
      componentName: TableSelectionComponent,
      dimensionType: 'large',
      popupType: 'active',

      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: clonedInputData
    };
    const moveTabledialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      panelClass: 'preferred-table-panel',
      data: {
        title: this.translateService.instant('tableSelect'),
        update: 'ok',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.table,
        back: false,
        standalone: true,
        selectionView: true
      }
    });
    confirmActionSubscription = this.ps.confirmedAction$.subscribe(async val => {
      if (val === ComponentTypes.table) {
        if (this.selectedTableIds.length > 0) {
          this.tablesText = Utilities.getTableNamesFromStandaloneTables(this.selectedTableIds, this.layoutSettings.FloorPlans).toString();
          this.SetSeatingAreaAndType(this.selectedTableIds);
          moveTabledialogRef?.close();
        }
        else {
          this.tablesText = this.defaultTableText;
          this.selectedAreaName =this.translateService.instant('anySeatingArea');
          this.partyService.reservationFormGroup.controls.selectedArea.setValue(-1);
          this.selectedSeatingType = this.translateService.instant('anySeatingType');
          this.partyService.reservationFormGroup.get('selectedType').setValue(-1);
          moveTabledialogRef?.close();
        }
        this.partyService.reservationFormGroup.controls.selectedTable.setValue(this.selectedTableIds);
      }
      await this.getRatePlanAmount(this.selectedTableIds);
      // Commented the below line for bug 15223
      //this.selectedSpecialMealTotalAmount = this.selectedSpecialMealAmount;
      this.totalAmount = this.formatNumber((this.selectedSpecialMealTotalAmount + this.RatePlanAmount ),this.numberToBeFixed) ;
      let selectedmeal = this._settings.SpecialMeals.filter(sm => sm.Id == this.partyService.reservationFormGroup.value.selectedSpecialMeal)[0];
      
      this.taxAmount =  this.formatNumber(Utilities.getTax(this.cs.settings.value.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax), this._as.restaurantId,this.selectedSpecialMealTotalAmount,this.RatePlanAmount,selectedmeal, this._settings.General.DaylightDelta),this.numberToBeFixed)
     
      var serviceCharge = Utilities.getServiceChargeWithServiceChargeTaxAmount(this.cs.settings.value.BookingCharges,this.totalAmount, this._as.restaurantId,selectedmeal, this._settings.General.DaylightDelta )
      this.serviceChargeAmount =  this.formatNumber(serviceCharge?.ServiceChargeAmount,this.numberToBeFixed);
      this.taxOnServiceChargeAmount = this.formatNumber(serviceCharge?.ServiceChargeTaxAmount,this.numberToBeFixed);    

    
    });

    cancelledActionSubscription = this.ps.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.table) {
        this.selectedTableIds = this.preSelectedTableIds;
        this.partyService.reservationFormGroup.controls.selectedTable.setValue(this.selectedTableIds);
        if (this.selectedTableIds.length > 0) {
          this.tablesText = Utilities.getTableNamesFromStandaloneTables(this.selectedTableIds, this.layoutSettings.FloorPlans).toString();
        } else {
          this.tablesText = this.defaultTableText;
        }
      }
    });

    this.subscriptions.add(moveTabledialogRef.afterClosed().subscribe(event => {
      if (confirmActionSubscription) { confirmActionSubscription.unsubscribe();}
      if (cancelledActionSubscription) { cancelledActionSubscription.unsubscribe();}
      this.updateFormValidity();
      if (this.selectedTableIds.length == 0) {
        const partyDetails = {
          Id: this.data ? this.data.Id : null,
          CreatedAt: this.data ? this.data.CreatedAt : null,
          SeatingAreaId: this.partyService.reservationFormGroup.value.selectedArea.Id ? this.partyService.reservationFormGroup.value.selectedArea.Id : this.partyService.reservationFormGroup.value.selectedArea,
          ReservedFor: this.partyService.reservationFormGroup.value.selectedTime.DateTime,
          ArrivedAt: this.partyService.reservationType == ReservationType.Waitlist ? this.data.ArrivedAt : null
        };
        if ((this.partyService.reservationType == ReservationType.StandbyParties || this.partyService.reservationType == ReservationType.PrivateLessonBooking) && this.data) {
          partyDetails.ReservedFor = this.data.WishedTime;
        }
        this.partyService.getAutoWaitTime(this.partyService.reservationFormGroup.get('selectedSize').value, partyDetails);
      }
      else {
        //this.setTableSuggestionBtnAndText(); // just assign the table here itself
        this.partyService.reservationFormGroup.get('selectedTable').setValue(this.selectedTableIds)
        this.tablesText = Utilities.getTableNamesFromStandaloneTables(this.selectedTableIds, this.layoutSettings.FloorPlans);
        this.selectedTables = this.tablesText;
        this.showSuggestedTables = false;
      }
    }));

  }

  SetSeatingAreaAndType(TablesIds: any[]) {
    const StandaloneTables = Utilities.getRestaurantTables(this.layoutSettings.FloorPlans);
    if (TablesIds.length == 1) {
      const tempTable = StandaloneTables.filter(table => table.Id === TablesIds[0]);
      let areaName = this.getSeatingAreaName(tempTable[0].SeatingAreaId);
      if (!areaName || areaName == "") {
        this.selectedAreaName =this.translateService.instant('anySeatingArea');
        this.partyService.reservationFormGroup.controls.selectedArea.setValue(-1);
      }
      else {
        this.selectedAreaName = areaName;
        this.partyService.reservationFormGroup.controls.selectedArea.setValue(tempTable[0].SeatingAreaId);
      }
      const seatingType = this.getSeatingType(tempTable[0].SeatingTypeId);
      if (!seatingType || seatingType == "") {
        this.selectedSeatingType = this.translateService.instant('anySeatingType');
        this.partyService.reservationFormGroup.get('selectedType').setValue(-1);
      }
      else {
        this.selectedSeatingType = seatingType;
        this.partyService.reservationFormGroup.get('selectedType').setValue(tempTable[0].SeatingTypeId);
      }
    }
    else {
      let selectedTableDetails = StandaloneTables.filter(f => TablesIds.includes(f.Id));
      if (uniqBy(selectedTableDetails, 'SeatingTypeId').length == 1) {
        const seatingType = this.getSeatingType(uniqBy(selectedTableDetails, 'SeatingTypeId')[0].SeatingTypeId);
        if (!seatingType || seatingType == "") {
          this.selectedSeatingType = this.translateService.instant('anySeatingType');
          this.partyService.reservationFormGroup.get('selectedType').setValue(-1);
        }
        else {
          this.selectedSeatingType = seatingType;
          this.partyService.reservationFormGroup.get('selectedType').setValue(uniqBy(selectedTableDetails, 'SeatingTypeId')[0].SeatingTypeId);
        }
      }
      else {
        this.selectedSeatingType =this.translateService.instant('anySeatingType');
      }
      if (uniqBy(selectedTableDetails, 'SeatingAreaId').length == 1) {

        let areaName = this.getSeatingAreaName(uniqBy(selectedTableDetails, 'SeatingAreaId')[0].SeatingAreaId);
        if (!areaName || areaName == "") {
          this.selectedAreaName = this.translateService.instant('anySeatingArea');
          this.partyService.reservationFormGroup.get('selectedArea').setValue(-1);
        }
        else {
          this.selectedAreaName = areaName;
          this.partyService.reservationFormGroup.get('selectedArea').setValue(uniqBy(selectedTableDetails, 'SeatingAreaId')[0].SeatingAreaId);

        }
      }
      else {
        this.selectedAreaName = this.translateService.instant('anySeatingArea');
      }

    }
  }

  goToPrevTab(skip: boolean = false) {
    this.ps.formValid$.next(
      {
        isFormValid: this.partyService.reservationFormGroup.value.selectedTime || skip ? true : false,
        currentTab: this.selectedIndex - 1,
        gotoPreviousTab: true
      } as IFormValidDetails
    );
  }

  updateFormValidity() {
    let isForValid = true;
    if (this.partyService.reservationFormGroup.get('selectedSpecialMeal').value != null) {
      if (this.partyService.reservationFormGroup.get('selectedCoverTypes').value && this.partyService.reservationFormGroup.get('selectedCoverTypes').value.length) {
        isForValid = this.getCovers() === this.partyService.reservationFormGroup.get('selectedSize').value.toString() ? true : false;
      }
      else {
        isForValid = true;
      }
    }
    else {
      isForValid = true;
    }

    this.ps.formValid$.next({
      isFormValid: isForValid && this.partyService.reservationFormGroup.valid && this.partyService.reservationFormGroup.dirty ? true : false,
      currentTab: this.selectedIndex
    } as IFormValidDetails);
  }

  confirmationDialog(isNoTableConfirmation, isSecondaryGuestLengthError = false) {
    const popUpMessage = [{
      confirmationMessage: this.translateService.instant(isNoTableConfirmation ? 'noTablesAssignedValidation' :
                            isSecondaryGuestLengthError ? 'secondaryGuestError' : 'conciergeValidation'),
      dialogTitle: isNoTableConfirmation ? 'deleteTitle' : 'Alert',
      width: isNoTableConfirmation ? '500px' : 'auto',
      minHeight: isNoTableConfirmation ? popupDialogDimension.actionDialogMinHeight : 'auto',
      showAlert: isNoTableConfirmation ? false : true,
      update: this.translateService.instant('ok'),
      cancel: this.translateService.instant('cancel')
    }];
    const confirmationcomponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: isNoTableConfirmation ? 'active' : 'static',
      dimensionType: 'small',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
    };
    this.ps.confirmationDialog = confirmationcomponentDetails;
  }

  setPagerNumber() {
    let pagerNumber = "";
    if (this.data && this.data.PagerNumber) {
      this.bindPagerNumber(this.data.PagerNumber);
      pagerNumber = this.data.PagerNumber;
    }
    return pagerNumber;
  }

  setInternalPartyNotes() {
    let InternalPartyNote = '';
    let filteredPartyNotes = [];
    if (this.data && this.data.Notes && this.data.Notes.length > 0) {
      filteredPartyNotes = this.data.Notes.filter(_note => _note.Type == PartyNoteType.FreeFormNote);
    }

    if (filteredPartyNotes && filteredPartyNotes.length > 0) {
      this.partyService.partyNotes = [];
      filteredPartyNotes.forEach((note) => {
        if (note.RelatedId === null) {
          InternalPartyNote = note.Text;
        }
        this.partyService.partyNotes.push(note);
      });
    }
    return InternalPartyNote;
  }
  setPredefinedPartyTags() {
    let _predrfinedPartyNotes = null;
    let filteredPartyNotes = [];
    if (this.data && this.data.Notes && this.data.Notes.length > 0) {
      filteredPartyNotes = this.data.Notes.filter(_note => _note.Type == PartyNoteType.PredefinedNote);
    }
    if (filteredPartyNotes && filteredPartyNotes.length > 0) {
      _predrfinedPartyNotes = filteredPartyNotes;
    }
    const predefinedTagsList = this.facadeService.getPredefinedPartyTags();
    const sortedpredefinedTagsList = [];
    const predefinedPartyIDs = [];
    if (_predrfinedPartyNotes && _predrfinedPartyNotes.length > 0) {
      this.partyService.partyNotes = this.partyService.partyNotes.filter(notes => notes.Type == PartyNoteType.FreeFormNote);
      _predrfinedPartyNotes.forEach(note => {
        predefinedPartyIDs.push(note.RelatedId);
        this.partyService.partyNotes.push(note);
      });
    }
    predefinedTagsList.forEach(element => {
      if (predefinedPartyIDs.includes(element.id)) {
        element.setSelected = true;
      }
      sortedpredefinedTagsList.push({
        Id: element.id, Name: element.name,
        Icon: element.icon, Value: '', setSelected: element.setSelected
      });
    });
    return sortedpredefinedTagsList;
  }

  dynamicFormChange() {
    this.components.forEach((x, index) => {
      if (index === 0) {
        this.subscriptions.add(x.form.valueChanges.subscribe(data => {
          if (typeof data.pagerNumber !== undefined || data.pagerNumber !== null || data.pagerNumber !== '') {
            this.bindPagerNumber(data.pagerNumber);
            this.partyService.reservationFormGroup.markAsDirty();
            this.partyService.reservationFormGroup.updateValueAndValidity();
            this.updateFormValidity();
          }
        }));
      }
      if (index === 1) {
        this.subscriptions.add(x.form.valueChanges.subscribe(data => {
          this.bindInternalPartyNotes(data);
        }));
      }
    });
    this.subscriptions.add(this.salesContactForm.form.valueChanges.subscribe(data => {
      if(data.salescontacts) {
        this.partyService.reservationFormGroup.controls?.selectedSalesContactIds?.setValue(data.salescontacts);
        this.partyService.reservationFormGroup.markAsDirty();
        this.partyService.reservationFormGroup.updateValueAndValidity();
      }
    }));
    this.formChipsChange();
    this.textAreaSubscription();
  }

  bindPagerNumber(pagerNumber) {
    // this.partyService.reservationFormGroup.controls.selectedPagerNumber.setValue(pagerNumber);
    this.partyService.partyPagerNumber = pagerNumber;
  }

  bindInternalPartyNotes(data: any) {
    for (let preferenceProperty in data) {
      if (preferenceProperty.includes('otherNotes')) {
        const otherNotesInConfig = this.config.find(configProp => configProp.name == preferenceProperty);
        if (otherNotesInConfig && !otherNotesInConfig.isHidden) {
          const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
          partyNotes.Text = data[preferenceProperty] || '';
          let mulNoteArr = preferenceProperty.split('$multinotes');
          let addnlId = null;
          if(mulNoteArr.length > 1){
            addnlId = mulNoteArr[1];
          }
          const preferencePropertyId = preferenceProperty.match(/(\d+)/);
          if (preferencePropertyId) {
            partyNotes.RelatedId = Number(preferencePropertyId[0]);
            partyNotes.addnlId = addnlId;
            const preferredOtherTags = this.partyService.partyNotes.find((note) => note.RelatedId === partyNotes.RelatedId && note.addnlId == partyNotes.addnlId);
            if (preferredOtherTags) {
              partyNotes.Id = preferredOtherTags.Id ? preferredOtherTags.Id : null;
              partyNotes.Type = PartyNoteType.FreeFormNote;
            } else {
              partyNotes.Id = null;
              partyNotes.Type = PartyNoteType.FreeFormNote;
            }
          }
          this.partyService.partyNotes = [
            ...this.partyService.partyNotes.filter(note => !note.addnlId && note.RelatedId != partyNotes.RelatedId),
            ...this.partyService.partyNotes.filter(note => note.addnlId && !(note.RelatedId == partyNotes.RelatedId && note.addnlId == partyNotes.addnlId))
          ];
          this.partyService.partyNotes.push({ ...partyNotes });
        }
      }
    }
    if (!this.holdFormValidation) {
      this.partyService.reservationFormGroup.markAsDirty();
      this.partyService.reservationFormGroup.updateValueAndValidity();
    }
    this.updateFormValidity();
  }

  textAreaSubscription(){
    this.subscriptions.add(this.formTextAreaService.formTextAreaChange$.subscribe(data => {
      let index = this.config.findIndex(itm => itm.name === data.name)
      if(index != -1){
        this.config.splice(index, 1);
        let [relatedTag, uniqId] = data.name.split('$multinotes');
        let length = this.config.filter(itm => itm.name.includes(relatedTag)).length;
        if(!length && this.config[index - 1]){
          let othersIndex = this.config[index - 1].options.findIndex(option => option.Name === 'Others')
          this.config[index - 1].options[othersIndex].setSelected = false;
        }
        let partyNoteIndex = this.partyService.partyNotes.findIndex(note => note.Id == uniqId || (note.RelatedId == relatedTag.match(/(\d+)/)[0] && note.addnlId == uniqId));
        this.partyService.partyNotes.splice(partyNoteIndex, 1);
        this.partyService.reservationFormGroup.markAsDirty();
        this.partyService.reservationFormGroup.updateValueAndValidity();
        this.updateFormValidity();
      }
    }))
  }

  formChipsChange() {
    this.subscriptions.add(this.formChipService.formChipChange$.subscribe((selectedChips) => {
      if (selectedChips.name === 'partyfreeTags') {
        selectedChips.data.forEach((tags) => {
          if (!tags.setSelected) {
            const index = this.partyService.partyNotes.findIndex((note) => note.Id === tags.Id);
            if (index > -1) {
              this.partyService.partyNotes = this.partyService.partyNotes.filter(note => note.Id != tags.Id);
            }
          } else {
            const index = this.partyService.partyNotes.findIndex((note) => note.Id === tags.Id);
            if (index == -1) {
              const freeTextNotes: PartyNoteDTO = {} as PartyNoteDTO;
              freeTextNotes.Id = tags.Id;
              freeTextNotes.Text = tags.Name;
              freeTextNotes.Type = PartyNoteType.FreeFormNote;
              this.partyService.partyNotes.push(freeTextNotes);
            }
          }
        });
      } else if (this.config.filter((fieldConfig) => fieldConfig.name == selectedChips.name).length > 0) {
        const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
        this.selectedPartyPreferenceTags = selectedChips.data;
        const preferredTagsIndex = this.config.findIndex(fieldConfig => fieldConfig.name == selectedChips.name);
        const otherTags = selectedChips.data.filter(chip => chip.Name == 'Others');
        if (otherTags && otherTags.length > 0) {
          let otherNotesName = `otherNotes${otherTags[0].Id}`;
          let isOtherNoteAvailable = this.config.filter((config) => config.name.includes(otherNotesName)).length;
          const otherNotesControl = this.components.last.config.filter((config) => config.name.includes(otherNotesName));
          if ((selectedChips.isOthersAdd || !isOtherNoteAvailable) && otherTags.length > 0 && otherTags[0].setSelected) {
            let currDate = new Date();
            const otherNotes: FieldConfig = {
              name: otherNotesName + '$multinotes' + currDate.getTime(),
              type: 'textarea',
              inputType: 'text',
              label: 'notes',
              showHint: true,
              class: 'guest-data__party-other-tags',
              charLength: 1000,
              isHidden: false,
              showRemove: true
            }
            this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
          } else if (isOtherNoteAvailable) {
            if (otherTags[0].setSelected) {
              otherNotesControl.forEach(control => {
                control.isHidden = false;
              });
              const otherNotes = this.partyService.partyNotes.find((note) => note.RelatedId == otherTags[0].Id);
              if (!otherNotes) {
                const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
                let editPartyNote = null;
                if (this.data) {
                  editPartyNote = this.data.Notes.find(note => note.RelatedId == otherTags[0].Id);
                  if (editPartyNote) {
                    this.partyService.partyNotes.push(editPartyNote);
                  }
                } else {
                  partyNotes.Id = null;
                  partyNotes.Type = PartyNoteType.FreeFormNote;
                  partyNotes.RelatedId = otherTags[0].Id;
                  partyNotes.Text = this.components.last.form.value[otherNotesName];
                  this.partyService.partyNotes.push(partyNotes);
                }
              }
              // this.partyService.partyNotes = this.partyService.partyNotes.filter((note) => note.RelatedId != otherTags[0].Id)
            } else {
              otherNotesControl.forEach(control => {
                control.isHidden = true;
              });
              this.partyService.partyNotes = this.partyService.partyNotes.filter((note) => note.RelatedId != otherTags[0].Id)
            }
            // this.config.splice(preferredTagsIndex + 1, 1);

          }
        }
        this.config = [...this.config];
        this.selectedPartyPreferenceTags.forEach((_partyNotes) => {
          if (_partyNotes.Name !== 'Others') {
            const preferredData = this.partyService.partyNotes.filter((note) => note.RelatedId === _partyNotes.Id)[0];
            if (_partyNotes.setSelected && !preferredData) {
              partyNotes.RelatedId = _partyNotes.Id;
              partyNotes.Text = _partyNotes.Name;
              partyNotes.Id = _partyNotes.noteId ? _partyNotes.noteId : null;
              partyNotes.Type = PartyNoteType.PredefinedNote;
              this.partyService.partyNotes.push(partyNotes);
            } else if (!_partyNotes.setSelected) {
              const index = this.partyService.partyNotes.findIndex((note) => note.RelatedId === _partyNotes.Id);
              if (index > -1) {
                this.partyService.partyNotes.splice(index, 1);
              }
            }
          }
        });
      }
      this.partyService.reservationFormGroup.markAsDirty();
      this.partyService.reservationFormGroup.updateValueAndValidity();
      this.updateFormValidity();
    }));
  }
  loadHosts() {
    this._settings.Hosts.forEach(host => {
      this.hosts.push(host);
    });
    if (this.data && this.data.HostId) {
      let savedhost = this.hosts.filter(_host => _host.Id == this.data.HostId);
      this.defaultHost = savedhost[0].Name;
      this.partyService.reservationHost = savedhost[0];
    }
    else if (this.data) {
      this.defaultHost = '';
      this.partyService.reservationHost = '';
    }
    else {
      if (this.hosts && this.hosts.length > 0) {
        var filteredhost = this.hosts.filter(_host => _host.Id == JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostId);
      }
      if (filteredhost) {
        this.defaultHost = filteredhost[0].Name;
        this.partyService.reservationHost = filteredhost[0];
      } else {
        this.defaultHost = this.hosts[0].Name;
        this.partyService.reservationHost = this.hosts[0];
      }
    }
  }
  setSelectedHost(option) {
    this.partyService.reservationHost = option;
    this.partyService.reservationFormGroup.markAsDirty();
    this.updateFormValidity();

  }
  resetForm() {
    this.components.forEach((item, index) => {
      this.formInitialValues.forEach((formValues, formindex) => {
        if (index === formindex) {
          item.form.reset(formValues);
        }
      });
    });
    this.loadHosts();
    this.partyService.partyNotes = [];
    this.bindGuestPartyNotes();
    if (this.data && this.data.Notes && this.data.Notes.length > 0) {
      this.mapPartyNotes();
    }
    this.components.forEach((item, index) => {
      this.formInitialValues.forEach((formValues, formIndex) => {
        if (index === formIndex) {
          item.form.patchValue(formValues.values)
        }
      })
    })
  }

  setPartyStatus(data){
    this.partyService.reservationFormGroup.controls.selectedPartyStatus.setValue(data.value);
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
    this.updateFormValidity();
  }

  ShowRestPolicyDetails() {
    const componentDetails: ComponentDetails = {
      componentName: RestaurantPoliciesComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupTitle: this.restaurantPolicy.PreReservationMessageTitle
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '60%',
      width: '40%',
      data: {
        title: this.restaurantPolicy.PreReservationMessageTitle,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.restaurantPolicies,
        back: false,
        standalone: true,
        showAction: true
      },
    });

  }

  setUpConcierge(conciergeDetails) {
    let conciergeData = conciergeDetails;
    this.hotelName = conciergeData.Hotel.Name;
    this.conciergeName = conciergeData.Name;
    this.partyService.reservationFormGroup.get("selectedHotelId").setValue(conciergeData.HotelId, { emitEvent: false });
    this.partyService.reservationFormGroup.get("selectedConciergeId").setValue(conciergeData.Id, { emitEvent: false });
    this.addConciergePhnNumCtrl(true, 'mobile');
    this.setUpConciergePhnNumCtrl(conciergeData.PhoneNumbers);
    this.showConcierge = true;
  }

  getConciergeTrackingList(type: ConciergeTrackingType) {
    this.conciergeTrackingList = { conciergeTrackingType: type, response: [] };
    let hotelId = this.partyService.reservationFormGroup.value.selectedHotelId ? this.partyService.reservationFormGroup.value.selectedHotelId : null;
    this.subscriptions.add(this.partyService.getConciergeTrackingList(type, hotelId).subscribe(data => {
      if (data.Payload) {
        this.conciergeTrackingList.response = data.Payload;
        this.hotelConciergePopUp(this.conciergeTrackingList);
      }
    }));
  }

  hotelConciergePopUp(conciergeTrackingList) {
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(HotelConciergeListComponent, 'small', 'active',
      conciergeTrackingList, '');

    const conciergePopUpRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '300px',
      autoFocus: false,
      data: {
        title: this.translateService.instant(conciergeTrackingList.conciergeTrackingType == ConciergeTrackingType.Hotel ? "editHotel" : "editConcierge"),
        showFooter: false,
        componentDetails,
        from: ComponentTypes.hotelConciergeList,
        standalone: true,
        back: false
      }
    });
    this.partyService.conciergeTrackingDialogRef = conciergePopUpRef;
    this.subscriptions.add(conciergePopUpRef.afterClosed().subscribe((item) => {
      if (item) {
        this.setupFormFieldValues(this.conciergeTrackingList.conciergeTrackingType, item);
      }
    }));
  }

  setupFormFieldValues(conciergeTrackingType, selectedItem) {

    switch (conciergeTrackingType) {
      case ConciergeTrackingType.Hotel:
        this.hotelName = selectedItem.Name
        this.showConcierge = true;
        this.removeConciergeTrackingItem(ConciergeTrackingType.Concierge);
        break;
      case ConciergeTrackingType.Concierge:
        this.conciergeName = selectedItem.Name;
        this.removeConciergePhnNumCtrl('mobile', true);
        this.getConciergePhnNumber(selectedItem.Id);
        break;
    }
  }

  getConciergePhnNumber(conciergeId) {
    this.subscriptions.add(this.partyService.getConciergePhoneNumber(conciergeId).subscribe(data => {
      if (data.Payload && data.State == OperationResultState.Success) {
        this.addConciergePhnNumCtrl(true, 'mobile');
        this.setUpConciergePhnNumCtrl(data.Payload);
      }
    }));
  }

  addConciergePhnNumCtrl(isDefault, formCtrlName) {
    this.mobileConfig.push({
      type: 'input',
      name: formCtrlName,
      inputType: 'text',
      label: 'Phone Number',
      class: 'guest-data__mobile',
      directive: 'numberOnly',
      isPhoneNumber: true,
      icon: 'icon-add',
      icon1: 'icon-Group-591',
      isNewPhoneNumber: isDefault ? true : false,
      isConciergeNumber: true,
      cellClick: isDefault ? null : (event, control) => this.deleteConciergePhoneNumber(event, control),
      onfocusOutClick: isDefault ? null : (event, control) => this.updateConciergePhoneNumber(event, control),
      returnFunction: isDefault ? (event) => this.addConciergePhoneNumber(event) : null
    });

    if (formCtrlName != 'mobile')
      this.moveItem(formCtrlName);
    this.mobileNumberForm.form.addControl(formCtrlName, new UntypedFormControl());
    this.subscriptions.add(this.mobileNumberForm.form.valueChanges.subscribe(data => {
      this.partyService.reservationFormGroup.updateValueAndValidity();
      this.updateFormValidity();
    }));
  }

  setUpConciergePhnNumCtrl(conciergeDetails) {
    conciergeDetails.forEach(item => {
      let formCtrlName = 'mobile_' + item.Id;
      this.addConciergePhnNumCtrl(false, formCtrlName);
      this.mobileNumberForm.form.get(formCtrlName).setValue(item.PhoneNumber, { emitEvent: false });
      this.mobileNumberForm.form.get('mobile').setValue("", { emitEvent: false });
    });
  }

  removeConciergeTrackingItem(type: ConciergeTrackingType) {

    if (type == ConciergeTrackingType.Hotel) {
      this.partyService.reservationFormGroup.get('selectedHotelId').setValue("");
      this.partyService.reservationFormGroup.get('selectedConciergeId').setValue("");
      this.hotelName = this.translateService.instant('hotelLbl');
      this.conciergeName = this.translateService.instant('conciergeLbl');
      this.showConcierge = false;
      this.removeConciergePhnNumCtrl('mobile', true);
    }
    this.partyService.reservationFormGroup.get('selectedConciergeId').setValue("");
    this.conciergeName = this.translateService.instant('conciergeLbl');
    this.removeConciergePhnNumCtrl('mobile', true);
    this.partyService.reservationFormGroup.updateValueAndValidity();
    this.partyService.reservationFormGroup.markAsDirty();
  }

  removeConciergePhnNumCtrl(matchString, removeAllPhnCtrls) {
    this.mobileConfig = this.mobileConfig.filter(con => removeAllPhnCtrls ? !con.name.includes(matchString) : con.name != matchString);
  }

  addConciergePhoneNumber(event) {
    if (this.mobileNumberForm.form.get('mobile').value) {
      this.subscriptions.add(this.partyService.addConciergePhoneNumber(this.buildConciergePhoneNumberRequest('mobile')).subscribe(data => {
        if (data.Payload && data.State == OperationResultState.Success) {
          let formCtrlName = 'mobile_' + data.Payload;
          this.addConciergePhnNumCtrl(false, formCtrlName);
          this.mobileNumberForm.form.get(formCtrlName).setValue(this.mobileNumberForm.form.get('mobile').value);
          this.mobileNumberForm.form.get('mobile').setValue("");
        }
      }));
    }
  }

  deleteConciergePhoneNumber(event, ctrlName) {
    let conciergephoneId = +ctrlName.split('_')[1];
    this.subscriptions.add(this.partyService.deleteConciergePhoneNumber(conciergephoneId).subscribe(data => {
      if (data.State == OperationResultState.Success) {
        this.removeConciergePhnNumCtrl(ctrlName, false);
      }
    }));
  }

  updateConciergePhoneNumber(event, ctrlName) {
    if (this.mobileNumberForm.form.get(ctrlName).value) {
      let conciergephoneId = +ctrlName.split('_')[1];
      this.partyService.updateConciergePhoneNumber(this.buildConciergePhoneNumberRequest(ctrlName, conciergephoneId)).subscribe(data => { });
    }
  }

  buildConciergePhoneNumberRequest(formCtrlName, phoneId?) {
    let newPhnNumber = this.mobileNumberForm.form.get(formCtrlName).value;
    let conciergeId = this.partyService.reservationFormGroup.get('selectedConciergeId').value;
    const request = {
      Id: phoneId ? phoneId : null,
      ConciergeId: conciergeId,
      PhoneNumber: newPhnNumber
    }
    return request;
  }

  moveItem(formControlName) { //swapping the array elements to maintain the order of the multiple mobile number fields
    let tempLastAddedValue = this.mobileConfig.splice(this.mobileConfig.findIndex(con => con.name == formControlName), 1)[0];
    this.mobileConfig.splice(this.mobileConfig.findIndex(con => con.name == 'mobile'), 0, tempLastAddedValue);
  }

  setTableIds() {
    this.selectedTableIds = this.data.TableIds;
    this.preSelectedTableIds = this.data.TableIds;
    this.partyService.reservationFormGroup.get('selectedTable').setValue(this.selectedTableIds);
  }
  setConfirmationDialogByType(value: any) {
    let noTables = false;
    if ((value.selectedTable && value.selectedTable.length > 0) || this._settings.General.SlottingMode == SlottingMode.Manual) {
      noTables = false
      this.ps.confirmationDialog = null;
      this.selectedDateText = this.getSlotDate();
    }
    else {
      noTables = true;
      this.confirmationDialog(true);
    }
    if (value.selectedHotelId && !value.selectedConciergeId) {
      this.confirmationDialog(false);
    }
    else {
      noTables ? '' : this.ps.confirmationDialog = null;
    }
    if (value.selectedGuest && value.selectedGuest.SecondaryContactsList && value.selectedGuest.SecondaryContactsList.length > 0 && parseInt(value.selectedSize)) {
      value.selectedGuest.SecondaryContactsList.length + 1 > parseInt(value.selectedSize) ?
          this.confirmationDialog(false, true) : this.ps.confirmationDialog = null;
    }
  }

  setDefaultTabletext() {
    if (this.tableSuggestionMode == TableSuggestionMode.SuggestAndAssign || this._settings.General.SlottingMode == SlottingMode.Auto) {
      this.defaultTableText = this.translateService.instant('AssignTables');
    } else {
      this.defaultTableText = this.translateService.instant('anyTableText');
    }
  }

  retainConciergePhoneNumber() {
    let conciergeId = this.partyService.reservationFormGroup.get('selectedConciergeId').value;
    if (conciergeId) {
      this.removeConciergePhnNumCtrl('mobile', true);
      this.getConciergePhnNumber(conciergeId);
    }
  }

  async getRatePlanAmount(selectedTableIds: any[]): Promise<number> {
    this.ratePlanSummary = [];
    this.RatePlanAmount = 0; // resetting when no table selected, api will not be called
    if (selectedTableIds.length > 0) {
      let ratePlanAmount = 0;
      let response  = await this.partyService.SetRatePlan(selectedTableIds, null , this.data);
      this.ratePlanSummary = []; // once the response comes, needs to reset the data from first
      this.RatePlanAmount = 0;
      selectedTableIds.forEach( tableId => {
        const seatingTypeId = Utilities.getSeatingTypeFromStandaloneTables(tableId, this.layoutSettings.FloorPlans);
        let selectedSeatingType = this._settings.SeatingTypes.filter(type => type.Id == seatingTypeId);
        const seatingTypeName = selectedSeatingType.length > 0 ? selectedSeatingType[0].Description : "";
        const tableIds: any[] = [tableId];
        if(response != null && response.Payload != null && response.Payload.RatePlanCalculations){
          if(response.Payload.RatePlanCalculations.length > 0){
            ratePlanAmount = response.Payload.RatePlanCalculations.filter(rp => rp.SeatingTypeId == seatingTypeId)[0].TotalAmount;
          }
        }

        if (this.ratePlanSummary.length > 0 && this.ratePlanSummary.filter(rp => rp.SeatingTypeId == seatingTypeId).length > 0) {
          let singleRatePlanAmount = (this.ratePlanSummary.filter(rp => rp.SeatingTypeId == seatingTypeId)[0].RatePlanAmount / this.ratePlanSummary.filter(rp => rp.SeatingTypeId == seatingTypeId)[0].SeatCount);
          this.ratePlanSummary.filter(rp => rp.SeatingTypeId == seatingTypeId)[0].SeatCount += 1;
          this.ratePlanSummary.filter(rp => rp.SeatingTypeId == seatingTypeId)[0].RatePlanAmount = (singleRatePlanAmount * this.ratePlanSummary.filter(rp => rp.SeatingTypeId == seatingTypeId)[0].SeatCount);
        }
        else {
          const _RP: RatePlanSummary = {
            SeatingTypeId: seatingTypeId,
            SeatingType: seatingTypeName,
            RatePlanAmount: ratePlanAmount,
            SeatCount: 1
          };
          this.ratePlanSummary.push(_RP);
        }
      });
      this.RatePlanAmount = isNaN(this.RatePlanAmount) ? 0 : this.RatePlanAmount;
      this.ratePlanSummary.forEach(sum => {
        this.RatePlanAmount += sum.RatePlanAmount;
      });
    }
    // if (this.data)
    //   this.data.RatePlanAmount = this.RatePlanAmount;
    return this.RatePlanAmount;
  }

  salesContactUsers() {
    this.subscriptions.add(this.settingsService.GetAllHosts().subscribe((details)=> {
      if(details.Payload) {
        this.users = details.Payload.Hosts;
        this.salesContactConfig[0].options = [...this.users?.map(user => {
          return {
            id: user.Id, 
            value: user.Name,
            phoneNumber: user.Servers && user.Servers.length ? user.Servers[0].PhoneNumber : '',
            email: user.Servers && user.Servers.length? user.Servers[0].EmailAddress : '',
            firstName: user.Servers && user.Servers.length ? user.Servers[0].Name : '',
            lastName: user.Servers && user.Servers.length ? user.Servers[0].LastName : '',
          }
        })];
        this.salesContactConfig[0].multiChipValues = this.salesContactConfig[0].options?.filter(o => this.data?.SalesContactIds?.some((id) => o.id === id));
        if(this.data?.SalesContactIds) {
          this.salesContactForm.form.get('salescontacts').setValue(this.data?.SalesContactIds);
        }
        this.partyService.reservationFormGroup?.markAsDirty();
        this.partyService.reservationFormGroup?.updateValueAndValidity();
      }
    }));
}

  ngOnDestroy() {
    this.mobileConfig.forEach(config => {
      config.blurClick && config.blurClick.bind(null);
      config.cellClick && config.cellClick.bind(null);
      config.returnFunction && config.returnFunction.bind(null);
      config.onfocusOutClick && config.onfocusOutClick.bind(null);
    });
    this.partyStatusConfig.forEach(config => {
      config.cellClick && config.cellClick.bind(null);
    })
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    this.partyService.reservationFormGroup.reset('', {emitEvent: false});
    this.ps.confirmationDialog = null;
    this.partyService.createReservationForm();
    this.partyService.totalWaitimeMinutes = 0;
    this.partyService.conciergeTrackingDialogRef = null;
    this.partyService.partyPagerNumber = '';
    this.partyService.tryUndoNoShowReservation = false;
  }
}
