import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CacheService } from '@app/core/services/cache.service';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { ComponentTypes } from '@app/shared/constants/commonenums';
import { BookingChargeType } from '@app/shared/models/RestaurantDTO';
import { FacadeService } from '@app/shared/services/facade.service';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-privatelesson-payment-summary',
  templateUrl: './privatelesson-payment-summary.component.html',
  styleUrls: ['./privatelesson-payment-summary.component.scss']
})
export class PrivatelessonPaymentSummaryComponent implements OnInit, OnDestroy {
  OperationCurrency: any;
  isTaxConfigured = false;
  isServiceConfigured = false;
  subscriptions: Subscription = new Subscription();
  ratePlan: any;
  fromComponent = ComponentTypes.EditPrivateLessonBooking;
  selectedGuestData: any;
  constructor(public dialog: MatDialog, public partyService: PartyService, @Inject(COMPONENTINPUT) public data, public translateService: TranslateService,
    public facadeService: FacadeService, public popupService: PopupService, public cs: CacheService) {
    this.subscriptions.add(this.partyService.ratePlan$.subscribe(data => {
      this.ratePlan = data;
    }));
    this.fromComponent = this.partyService.isfrombuynow ? ComponentTypes.AddPrivateLessonBooking : ComponentTypes.EditPrivateLessonBooking;
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this.OperationCurrency = sett.General.OperationCurrency;
      this.isTaxConfigured =  sett.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax)?.length > 0 ;
      this.isServiceConfigured = sett.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge)?.length > 0 ;
    }));
  }


  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  setReservationData(value) {
    this.partyService.privateLessonBookingDataSaveObj.ReservationAttemptId = value;
    if (this.fromComponent == ComponentTypes.AddPrivateLessonBooking && this.partyService.isfrombuynow)
      this.partyService.reservationConfirm();

  }
}
