<div class='ag_text-editor ag_display--flex'>
    <form [formGroup]="formGroup" [ngClass]="{'ag_w--75 ag_display--flex': showTagsSection}">
        <!-- <quill-editor [formControlName]="formControlName"></quill-editor> -->
        <ckeditor #editor [config]='config' [editor]="Editor" [formControlName]="formControlName"
            (ready)="setInstance($event)" [(ngModel)] ="myContent" (change)="ckClick();"></ckeditor>
        <mat-error class="ag_pt--2" *ngIf="formGroup.get(formControlName).hasError('limitExceeded')">{{maxLimitMsg}}
        </mat-error>
    </form>
    <div class='tags-section ag_w--25' *ngIf='showTagsSection'>
        <div *ngIf='!showCollapse'>
            <div *ngIf='showSearch' class="searchDiv">
                <h4 class='ag_group--headers'>{{captions.lbl_tags}}</h4>
                <app-simple-search [placeholder]="captions.lbl_searchByTag" (searchChange)="searchValueChange($event)">
                </app-simple-search>
            </div>
            <div class='tags-content'>
                <div class='tags-div ag_m--2 ag_mb--5' [ngClass]="{'tags-border':tagObj.showGroupTitle}" *ngFor='let tagObj of tags'>
                    <h4 *ngIf='tagObj.showGroupTitle' class='ag_form--label ag_mb--0 tag-title' [matTooltip]="tagObj.title">
                        {{captions.letterTags[tagObj.titleCode]}}</h4>
                    <div class='tags-container ag_pt--2'>
                        <button mat-button href="javascript:void(0)" (click)="elementClick(tag)"
                            class='ag_link ag_display--inblock ag_m--2 ag_ml--0'
                            [ngClass]="{'ag_link--disabled ': isViewOnly}"
                            *ngFor='let tag of tagObj.tags'>{{captions.letterTags[tag.tagCode]}}</button>
                    </div>
                </div>
            </div>
            
        </div>
        <div *ngIf='showCollapse' class="showCollapse">
            <div class="searchDiv">
                <h4 class='ag_group--headers' [attr.LiteralID]="'lbl_tags'"  [attr.LiteralID]="'lbl_tags'" >{{captions.lbl_tags}}</h4>
                <app-simple-search [placeholder]="captions.lbl_searchByTag" (searchChange)="searchValueChange($event)">
                </app-simple-search>
            </div>
            <div class='tags-div' *ngFor='let tagObj of tags'>
                <mat-accordion>
                    <mat-expansion-panel [expanded]="expandPanel" [hideToggle]="true" (closed)="closed()"
                        (opened)="opened()">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h4 class='ag_form--label ag_mb--0 tag-title' [matTooltip]="tagObj.title">
                                    {{tagObj.title}}</h4>
                                <span class="icon-dropdown" [ngClass]="icon">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class='tags-container ag_pt--2'>
                            <button mat-button href="javascript:void(0)" (click)="elementClick(tag)"
                                class='ag_link ag_display--inblock ag_m--2 ag_ml--0'
                                *ngFor='let tag of tagObj.tags'>{{captions.letterTags[tag.tagCode]}}</button>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>
