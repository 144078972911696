import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Pipe, PipeTransform, OnDestroy, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RetailSetupService } from '../retail-setup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { HttpMethod } from '../../shared/service/http-call.service';
import { Host, RetailBreakPoint, ButtonType, Maxlength, Product } from '../../shared/globalsContant';
import { BaseResponse } from '../../shared/business/shared.modals';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailDataAwaiters } from '../../shared/events/awaiters/retail.data.awaiters';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import * as _ from 'lodash';

@Component({
  selector: 'app-multi-pack',
  templateUrl: './multi-pack.component.html',
  styleUrls: ['./multi-pack.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiPackComponent implements OnInit, OnDestroy {
  public selectedVal: string = "dateofsale"; //Toggle button default value
  public linkedValue: number = 1;
  public datesaleLabel: string;
  public expirationLabel: string;
  public persale: boolean = false;
  public dayBool: boolean = true;
  public multiPackSavePipe: boolean = false;
  public checkValuePipe: boolean = false;
  checkValue: CheckForValue;
  multiPackSave: multiPackSave;
  captions: any;
  minFromValue: any;
  minToValue: any;
  errMissing: string
  multipPackChanges: any;
  public selectedOutletMgrArr: any = [];
  validateBtn: boolean = false;
  validateBtnSubscription: Subscription;
  availableItem_Outlet: any = [];
  selectedLinkedItem: any = [];
  linkedCategory = [];
  effectiveDateSubscription: Subscription;
  multipackArraySubscription: Subscription;
  functionalities: { [key: string]: boolean } = {};
  isArray = true;

  linkedItems = [];
  linkItemCaptions: { label: string; placeholder: string; error: string;};
  @ViewChild('elementToFocus') _input: ElementRef;
  multipackForm: UntypedFormGroup;
  popTemplate: any;
  outletSubscription: Subscription;
  outlets: any = [];
  isPerSaleMandatory: boolean = false;
  @Input() isEdit;
  IsViewOnly: boolean = false;
  PercentageMaxLength = Maxlength.PERCENTAGE;
  searchTextChanged = new Subject<string>();
  notifier: Subject<void> = new Subject<void>();
  searchFilter: any[];
  placeHolderFormat: string;
  captionsCommon: any;
  arrayAddr: UntypedFormArray;
  isMultipleLinkedItem: boolean = false;
  productId : number
  searchedIndex: any;
  floatLabel: string;
  @Input() parentForm;

  constructor(public _retailService: RetailSetupService, private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<RetailPopupComponent>,
    public localization: RetailLocalization, private PropertyInfo: RetailPropertyInformation, private utils: RetailUtilities,
    private func: RetailFunctionalityBusiness) {
    this.captionsCommon = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
    // Get ProductFunctionality :
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      if (this.functionalities.ShowMultipleLinkedRetailItemForMultipack) {
        this.isMultipleLinkedItem = true;
      }
    });
    this._retailService.multipackFormGrpEmitter.subscribe(val => {
      let multipackArr = this.multipackForm.get('multipackArr').value;
      this.isPerSaleMandatory = this.multipackForm.get('unlimited').value === false;
      if (multipackArr && multipackArr.length > 0) {
        this.selectedLinkedItem = this.selectedLinkedItem.concat(multipackArr.filter(y => y.itemId).map(x => x.itemId));
        
      }

    });

  }

  ngAfterViewInit() {
    this.multipackForm.get('specificstartdate').disable();
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    if (this.productId === Product.GOLF) {
      this.multipackForm.get('item').clearValidators();
      this.multipackForm.controls.item.setErrors(null);
      this.multipackForm.updateValueAndValidity();
    }
    else if (!this.isMultipleLinkedItem) {
      const temparr = this.multipackForm.get('multipackArr') as UntypedFormArray;
      temparr.controls.forEach(element => {
        element['controls'].item.clearValidators()
        element['controls'].item.setErrors(null)
      });
    }
  }

  ngOnInit() {

    this.placeHolderFormat = this.localization.inputDateFormat;
    this.captions = this.localization.captions.retailsetup;
    this.errMissing = this.captions.MissingPerSale;
    this.datesaleLabel = this.captions.DateofSale;
    this.expirationLabel = this.captions.ExpirationDays;
    this.linkItemCaptions = {
      label: this.captions.LinkedItem,
      placeholder: this.captions.ItemNumberDescription,
      error: this.captions.MissingLinkedItem,
    };
    this.minFromValue = this.minToValue = this.PropertyInfo.CurrentDate;
    this.multipackForm = this._retailService.multipackFormGrp;
    this.outlets = this._retailService.outlets;
    const fromDate = this.multipackForm.controls.specificstartdate.value;
    this.validatePerSale(this.multipackForm.controls.unlimited.value);
    this.validateBtnSubscription = this._retailService.validateBtn.subscribe(isValid => {
      this.validateBtn = isValid &&
        this.isMultipleLinkedItem ? this.multipackForm.controls.multipackArr?.value?.every(x => x.itemId != '')
        : this.multipackForm.controls.itemId.value != '';
    });
    this.IsViewOnly = this._retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == RetailBreakPoint.ItemSetup).view;
    this.linkedCategory = this._retailService.categories;
    this.multipackForm.controls.category.setValue(this.multipackForm.controls.category.value);
    if (fromDate) {
      this.minToValue = this.utils.getDate(fromDate);
    }

    this.onChanges();

    if (this.isArray) {
      this.multipackForm.get('persale').clearValidators()
      this.multipackForm.controls.persale.setErrors(null);
    }

    this.searchTextChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(async (searchtxt) => this.searchbyInputAfterDebounce(searchtxt));

      if(this.parentForm){
        this.parentForm.addControl('multipackFormGroup', this.multipackForm)
      }
  }

  // Handles onchange event of the form
  onChanges(): void {
    this.effectiveDateSubscription = this.multipackForm.get('specificstartdate').valueChanges.subscribe(val => {
      this._retailService.minEndDate = val;
    });
  }

  onPerSaleChange() {
    if (this.multipackForm.controls.persale.value <= 0) {
      this.multipackForm.controls.persale.setValidators([Validators.required]);
      this.multipackForm.controls.persale.setErrors({ invalid: true });
      this.validateBtn = false;
    }
    else if (this.isMultipleLinkedItem) {
      this.CalcMultipleItemSalesPrice();
    }
    else {
      this.CalcSalesPrice();
    }
    this.formValueChanges();
  }

  ngOnDestroy() {
    if (this.multipPackChanges) {
      this.multipPackChanges.unsubscribe();
    }

    if (this.outletSubscription) {
      this.outletSubscription.unsubscribe();
    }

    if (this.effectiveDateSubscription) {
      this.effectiveDateSubscription.unsubscribe();
    }

    if (this.validateBtnSubscription) {
      this.validateBtnSubscription.unsubscribe();
    }

    this.isPerSaleMandatory = false;
  }

  /**
   * @function saleChange()
   * @prams datestartValue
   * @description If cahnge the "Start Date" button in DOM label will change on Next cloumn, Value from formcontrol check the value assign label text asper condition
   */

  saleChange = () => {
    let salechange = this.multipackForm.controls.dateofsalestart.value;
    salechange == "specific" ? this.multipackForm.get('specificstartdate').enable() : this.multipackForm.get('specificstartdate').disable();
    this.datesaleLabel = (salechange == "dateofsale") ? this.captions.DateofSale : this.captions.SpecificDate;
    salechange == "dateofsale" ? this.multipackForm.controls.specificstartdate.setValue('') : this.multipackForm.controls.specificstartdate.setValue(this.PropertyInfo.CurrentDate);
    this._retailService.minStartDate = this._retailService.minEndDate = this.PropertyInfo.CurrentDate;
  }

  /**
  * @function saleexpired()
  * @prams datestartValue
  * @description If cahnge the "Start Date" button in DOM label will change on Next cloumn, Value from formcontrol check the value assign label text asper condition
  */

  saleexpired = () => {
    const saleexpire = this.multipackForm.controls.dateofexpireend.value;
    if (saleexpire === "expiration") {
      this.expirationLabel = this.captions.ExpirationDays;
      this.dayBool = true;
      this.multipackForm.controls.expireenddate.setValue('');
    }
    else {
      this.expirationLabel = this.captions.SpecificDate;
      this.dayBool = false;
      this.multipackForm.controls.expiryDays.setValue('');
      this.multipackForm.controls.expireenddate.setValue(this.PropertyInfo.CurrentDate);
    }
    this._retailService.minStartDate = this._retailService.minEndDate = this.PropertyInfo.CurrentDate;
  }



  /**
 * @function toogleChange()
 * @prams unlimited boolean
 * @description If cahnge the toogle button value "per-sale" enabled As per condition based
 */
  validatePerSale(selectionValue) {
    if (selectionValue) {
      this.ResetMultiPackList();
    }

    if (!selectionValue && (this.multipackForm.controls['persale'].value <= 0)) {
      this.isPerSaleMandatory = true;
      this.multipackForm.controls.persale.setValidators([Validators.required]);
      this.multipackForm.controls.persale.setErrors({ invalid: true });
      this.multipackForm.controls.persale.setValue('');
    }
    else {
      this.isPerSaleMandatory = false;
      this.multipackForm.controls.persale.setValidators([]);
      this.CalcSalesPrice();
    }
    this.formValueChanges();
  }

  ResetMultiPackList() {
    this.multipackForm.controls.salesprice.setValue('');
    this.multipackForm.controls.memberprice.setValue('');
    this.multipackForm.controls.cost.setValue('');
    this.multipackForm.controls.service.setValue('');
    this.multipackForm.controls.gratutity.setValue('');
    this.multipackForm.controls.persale.setValue('');
  }

  categoryChange(arg: string, i) {
    let result = _.cloneDeep(this.multipackForm.controls.multipackArr.value);
    result.forEach(x => {
      this.selectedLinkedItem.splice(i,1, 0);
      this.multipackForm.controls.multipackArr['controls'][i].controls.item.setValue('');
    });
  }

  onCategoryChange(arg: string) {    
    this.multipackForm.controls.item.setValue('');
  }

  CalcSalesPrice() {
    if (this.multipackForm.controls.persale.value > 0 && this.multipackForm.controls.salesprice.value) {

      let salesprice = this.localization.currencyToSQLFormat(this.multipackForm.controls.salesprice.value);
      let service = this.localization.currencyToSQLFormat(this.multipackForm.controls.service.value);
      let memberprice = this.localization.currencyToSQLFormat(this.multipackForm.controls.memberprice.value);
      let gratutity = this.localization.currencyToSQLFormat(this.multipackForm.controls.gratutity.value);
      let costprice = this.localization.currencyToSQLFormat(this.multipackForm.controls.cost.value);

      let totalsalePrice: any;
      let totalMemberPrice: any;
      let totalCostPrice: any;


      if (this.functionalities[GlobalConst.RetailFunctionalities.ApplyRevisedMultiPackLogic]) {
        totalsalePrice = Number(salesprice) + this.utils.MidPointRoundOffTwo((Number(salesprice) * Number(service / 100))) +
        this.utils.MidPointRoundOffTwo((Number(salesprice) * Number(gratutity / 100)));

        totalMemberPrice = Number(memberprice) + this.utils.MidPointRoundOffTwo(Number(memberprice) * Number(service / 100)) +
        this.utils.MidPointRoundOffTwo((Number(memberprice) * Number(gratutity / 100)));

        totalCostPrice = Number(costprice) + this.utils.MidPointRoundOffTwo((Number(costprice) * Number(service / 100))) +
        this.utils.MidPointRoundOffTwo( (Number(costprice) * Number(gratutity / 100)));

      }
      else {
        totalsalePrice = Number(salesprice);
        totalMemberPrice = Number(memberprice);
        totalCostPrice = Number(costprice);
      }

      let parValue = this.multipackForm.controls.persale.value;
      let multiPackSalePrice = (totalsalePrice * parValue);
      let multiPackMemberPrice = (totalMemberPrice * parValue);
      let multiPackCostPrice = (totalCostPrice * parValue);
      let localizedCurrency = this.localization.localizeCurrency(multiPackSalePrice, false);
      let localizedMemberCurrency = this.localization.localizeCurrency(multiPackMemberPrice, false);
      let localizedCostCurrency = this.localization.localizeCurrency(multiPackCostPrice, false);
      this._retailService.GeneralFormGrp.controls.salesprice.setValue(localizedCurrency);
      this._retailService.GeneralFormGrp.controls.memberprice.setValue(localizedMemberCurrency);
      this._retailService.GeneralFormGrp.controls.curitemcost.setValue(localizedCostCurrency);
      const marginPercent = this.CalcMarginPercent(localizedCurrency, localizedCostCurrency);
      const localizedMarginPercent = this.localization.localizeCurrency(marginPercent, false);
      this._retailService.GeneralFormGrp.controls.marginpercent.setValue(localizedMarginPercent);


    }
  }

  CalcMarginPercent(salesPrice: string, costPrice: string) {
    const SalesPrice = salesPrice ? this.localization.currencyToSQLFormat(salesPrice) : 0;
    const CostPrice = costPrice ? this.localization.currencyToSQLFormat(costPrice) : 0;
    const result = ( (Number(SalesPrice.toFixed(2)) - Number(CostPrice.toFixed(2))) /  Number(SalesPrice.toFixed(2))) * 100;
    return !isFinite(result) ? 0 : Number(result.toFixed(2));
  }

  CalcMultipleItemSalesPrice() {

    if (this.multipackForm.controls.multipackArr.value.length > 0 && this.multipackForm.controls.persale.value > 0) {
      let result = _.cloneDeep(this.multipackForm.controls.multipackArr.value);
      let totalsalePrice: any;
      let totalMemberPrice: any;
      let totalCostPrice: any;
      result.forEach(x => {
        x.salesprice = this.localization.currencyToSQLFormat(x.salesprice);
        x.service = this.localization.currencyToSQLFormat(x.service);
        x.gratutity = this.localization.currencyToSQLFormat(x.gratutity);
        x.memberprice = this.localization.currencyToSQLFormat(x.memberprice);
        x.cost = this.localization.currencyToSQLFormat(x.cost);
      });
      if (this.functionalities[GlobalConst.RetailFunctionalities.ApplyRevisedMultiPackLogic]) {
        totalsalePrice = result.map(x => Number(x.salesprice) + this.utils.MidPointRoundOffTwo(Number(x.salesprice) * Number(x.service / 100)) +
        this.utils.MidPointRoundOffTwo(Number(x.salesprice) * Number(x.gratutity / 100))).reduce((a, b) => a + b, 0);

        totalMemberPrice = result.map(x => Number(x.memberprice) + this.utils.MidPointRoundOffTwo(Number(x.memberprice) * Number(x.service / 100)) +
        this.utils.MidPointRoundOffTwo(Number(x.memberprice) * Number(x.gratutity / 100))).reduce((a, b) => a + b, 0);

        totalCostPrice = result.map(x => Number(x.cost) + this.utils.MidPointRoundOffTwo(Number(x.cost) * Number(x.service / 100)) +
        this.utils.MidPointRoundOffTwo(Number(x.cost) * Number(x.gratutity / 100))).reduce((a, b) => a + b, 0);

      }
      else {
        totalsalePrice = result.map(x => Number(x.salesprice)).reduce((a, b) => a + b, 0);
        totalMemberPrice = result.map(x => Number(x.memberprice)).reduce((a, b) => a + b, 0);
        totalCostPrice = result.map(x => Number(x.costprice)).reduce((a, b) => a + b, 0);
      }

      let parValue = this.multipackForm.controls.persale.value;
      let multiPackSalePrice = (totalsalePrice * parValue);
      let multiPackMemberPrice = (totalMemberPrice * parValue);
      let multiPackCostPrice = (totalCostPrice * parValue);
      let localizedCurrency = this.localization.localizeCurrency(multiPackSalePrice, false);
      let localizedMemberCurrency = this.localization.localizeCurrency(multiPackMemberPrice, false);
      let localizedCostCurrency = this.localization.localizeCurrency(multiPackCostPrice, false);
      this._retailService.GeneralFormGrp.controls.salesprice.setValue(localizedCurrency);
      this._retailService.GeneralFormGrp.controls.memberprice.setValue(localizedMemberCurrency);
      this._retailService.GeneralFormGrp.controls.curitemcost.setValue(localizedCostCurrency);
      //this._retailService.calculateItemPrice(this.multipackForm.controls.salesprice.value, 'SP')
    }

  }

  toogleClick(e) {


    this.validateBtn = (!this.multipackForm.controls.unlimited.value && (this.multipackForm.controls['persale'].value <= 0)) ? true : false;
    this.validatePerSale(e[0]);


  }

  _openCalendar() {
    setTimeout(() => this._input.nativeElement.focus());
  }

  close() {
    this.dialogRef.close();
  }

  GetOutletName(outletId): string {
    let outletName: string = "";
    if (this.outlets && this.outlets.length > 0) {
      let outlet = this.outlets.find(x => x.id == outletId);
      outletName = outlet && outlet.name ? outlet.name : "";
    }
    return outletName;
  }

  OnItemChange(data: any) {
    if (data) {
      if (this.isMultipleLinkedItem) {
        const temparr = this.multipackForm.get('multipackArr') as UntypedFormArray;
        const temparr2 = temparr.value;
        temparr2.at(data.index).itemId = data.data;
        this.multipackForm.controls.multipackArr.setValue(temparr2);
        this.selectedLinkedItem.splice(data.index, 0, data.data);
      }
      this.multipackForm.controls.itemId.setValue(data);
    } else {
      if (this.isMultipleLinkedItem) {
        const temparr = this.multipackForm.get('multipackArr') as UntypedFormArray;
        let element = temparr.value[this.searchedIndex];
        if (element) {
          const removedItemId = element.itemId;
          this.multipackForm.controls['multipackArr']['controls'][this.searchedIndex]?.controls?.itemId?.setValue('');
          if (removedItemId > 0) {
            let removedItemIndex = this.selectedLinkedItem.indexOf(removedItemId);
            if (removedItemIndex >= 0) {
              this.selectedLinkedItem.splice(removedItemIndex, 1);
            }
          }
        }
      }
      this.multipackForm.controls.itemId.setValue('');
    }
    this.searchFilter = [];
  }


  expiryDaysClick() {
    const saleexpire = this.multipackForm.controls.dateofexpireend.value;
    let expiryday = this.multipackForm.controls.expiryDays.value;
    if ((expiryday == 0 || expiryday == null) && (saleexpire === "expiration")) {
      return true;
    } else {
      return false;
    }

  }

  async Save() {
    console.log("multipackForm", this.multipackForm.value);

    if (this.expiryDaysClick()) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.getError(-1002), ButtonType.Ok);
      return
    }

    this._retailService.ValidateUpdate();
    if (!this.validateBtn) {
      return;
    }
    if (!this.isEdit) {
      this.validateBtn = false;
    }
    let itemId = 0;
    const updateBody = this._retailService.FormBodyData(this.isEdit, this.PropertyInfo.UseRetailInterface);
    let response;
    if (this.isEdit) {
      response = await this._retailService.InvokeServiceCallAsync('UpdateRetailItem', Host.retailManagement, HttpMethod.Put, updateBody, { id: updateBody.retailItem.retailItemDetail.id });
      if (response) {
        this._retailService.updateRetailItemImage(updateBody.retailItem.retailItemDetail.id);
        itemId = updateBody.retailItem.retailItemDetail.id;
      }
    } else {
      response = await this._retailService.InvokeServiceCallAsync('CreateRetailItem', Host.retailManagement, HttpMethod.Post, updateBody);
      if (response) {
        this._retailService.saveImage(response.result);
        itemId = response.result;
      }
    }
    if (this._retailService.showRetailSetupRoomTypesAssignmentTab) {
      RetailDataAwaiters.createOrUpdateRoomTypeAssignment(updateBody.retailItem.roomTypeAssignmentRetailItemMapping, itemId)
    }
    if (response && response.result) {
      this.dialogRef.close(updateBody);
    }

  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "UpdateRetailItem") {
      if (result.result) {
        this.dialogRef.close();
      }
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }

  OnFromDateValueChange(e) {
    let toDate = this.multipackForm.controls.expireenddate.value;
    if (toDate) {
      if (this.utils.getDate(toDate) < this.utils.getDate(e.value)) {
        this.multipackForm.controls.expireenddate.setValue(this.utils.getDate(e.value));
      }
    }
    this.minToValue = this.utils.getDate(e.value);
  }

  OnToDateValueChange(e) {

    let fromDate = this.multipackForm.controls.specificstartdate.value;
    if (fromDate) {
      if (this.utils.getDate(fromDate) >= this.utils.getDate(e.value)) {
        this.multipackForm.controls.expireenddate.setValue(this.utils.getDate(fromDate));
      }
    }
  }

  validateMultipackSave = () => {
    let multiPack = this.multipackForm.controls;
    let isUnlimited = multiPack['unlimited'].value;
    let isDateOfSale = multiPack.dateofsalestart.value == "dateofsale"
    let salesPrice = this.localization.currencyToSQLFormat(multiPack['salesprice'].value)
    return (multiPack.expiryDays.value && multiPack.expiryDays.value === 0 ||
      multiPack.expiryDays.value && multiPack.expiryDays.value > 0 ||
      multiPack.expiryDays.value) && (isDateOfSale || multiPack.specificstartdate.value) &&
      multiPack.item.value && multiPack.item.value > 0 && ((Number(multiPack['persale'].value) != 0 && salesPrice > 0) || isUnlimited);
  }

  formValueChanges() {
    this.multipPackChanges = this.multipackForm.valueChanges.subscribe(data => {
      this._retailService.ValidateUpdate();
    }
    )
  }

  stopdefaultClick(e) {
    if (this.isEdit && this.IsViewOnly) {
      e.preventDefault();
    }
  }

  searchTextEmit(arg: string, i) {
    this.searchTextChanged.next(arg);
    this.searchedIndex = i;
  }
  LinkedItemEmit(event) {
    this.OnItemChange(event);
  }

  async searchbyInputAfterDebounce(searchtxt: string): Promise<void> {

    if (!searchtxt) {
      this.OnItemChange(searchtxt);
      return;
    }

    const outletId = this._retailService.selectedOutletMgrArr.value.map(x => x.id);

    if (searchtxt.length < 3 || outletId.length <= 0) {
      return;
    }

    this.cancelOngoingCall();
    this.notifier = new Subject<void>();

    this.searchFilter = [];

    try {
      let categoryId=0
      if(this.productId == Product.GOLF){
        let categoryValue = this.multipackForm.get('multipackArr').value
        categoryId = categoryValue[this.searchedIndex]?.category ? categoryValue[this.searchedIndex]?.category : 0;
      }else{
        categoryId = this.multipackForm.get('category').value?Number(this.multipackForm.get('category').value):0;
      }
      const response = await this._retailService.cancellableObservalble(
        this.notifier,
        'SearchRetailItemsByType',
        Host.retailManagement,
        HttpMethod.Put,
        {
          itemType: -1,
          searchKey: searchtxt,
          outletId,
          categoryId
        },
        undefined
      ).toPromise();

      let result = response.result.retailItems;
      if (this.isMultipleLinkedItem && this.selectedLinkedItem.length > 0) {
        //const itemIds = new Set(result.map(({ id }) => id));
        this.selectedLinkedItem.map(y => {
          const defaultDataIdx = result.findIndex(x => x.id === y);
          if (defaultDataIdx >= 0) {
            result.splice(defaultDataIdx, 1);
          }
        });
      }

      this.searchFilter = response.result.retailItems;
    } catch(err) {
      console.log(err);
    }
  }

  cancelOngoingCall() {
    this.notifier.next();
    this.notifier.complete();
  }

  addItem(i) {
    this.arrayAddr = this.multipackForm.get('multipackArr') as UntypedFormArray;
    let data = this.fb.group({
      category: '',
      item: ['',Validators.required],
      itemId: '',
      description: '',
      salesprice: '',
      memberprice: '',
      cost: '',
      service: '',
      gratutity: '',
      //persale: ['',Validators.required],
    })
    this.arrayAddr.push(data);
    //this.setPersalevalue()
  }

  removeItem(i) {
    this.arrayAddr = this.multipackForm.get('multipackArr') as UntypedFormArray;
    this.arrayAddr.removeAt(i);
    this.CalcMultipleItemSalesPrice();
  }

}


/** Pipe Implementation **/
@Pipe({
  name: 'multiPackSave',
})
export class multiPackSave implements PipeTransform {
  constructor(public _retailService: RetailSetupService, public localization: RetailLocalization) {

  }

  transform(value: any, formdata: any) {
    let isUnlimited = formdata.unlimited;
    let isDateOfSale = formdata.dateofsalestart == "dateofsale";
    let salesPrice = this.localization.currencyToSQLFormat(formdata.salesprice)
    return (formdata.expiryDays === 0 || formdata.expiryDays > 0 || formdata.expireenddate) && (isDateOfSale || formdata.specificstartdate) && formdata.item > 0 && ((Number(formdata.persale) != 0 && salesPrice > 0) || isUnlimited)
  }
}



@Pipe({
  name: 'CheckForValue',
})
export class CheckForValue implements PipeTransform {
  constructor(public _retailService: RetailSetupService, public localization: RetailLocalization) {

  }
  transform(value: any, formdata: any) {
    return Number(formdata.persale) <= 0 && !formdata.unlimited;
  }
}




