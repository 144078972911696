import { Component, OnInit, ViewEncapsulation,  OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ReportOptions, ReportTab, } from '../business/report.modals';
import {  MatTab, MatTabHeader } from '@angular/material/tabs';
import { SPAConfig } from '../../common/config/SPA-config';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { ReportBreakPoint } from '../../shared/globalsContant';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'view-retail-reports',
  templateUrl: './view-reports.component.html',
  styleUrls: ['./view-reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailViewReportsComponent implements OnInit, OnDestroy {
  @Output() clickSave: EventEmitter<any> = new EventEmitter<any>();
	@Output() clickEditvalues: EventEmitter<any> = new EventEmitter();
  @Input() edit: boolean;
  editableValue: any;
  @Input('editValues') 
	set patchVal(value){
		if(value){
			this.editableValue = value;
		}
	}
  reportType: any;
  subscription$: Subscription;
  constructor(public spaConfig: SPAConfig, private breakpoint: BreakPointAccess, private route: ActivatedRoute
    , private localization : RetailLocalization, private localize: Localization) {
  }
  htmlContent: any;
  selectedTabIndex: number = 0;
  selectedIndex: number = 0;
  reportOption: ReportOptions;
  get tabs() {
    return ReportTab;
  }
  @Input('reportGroupName')
	set reportGroupName(value: any) {
    if(typeof(value) === "string")
    {    
      this.reportType = value?.toLowerCase();
    }
    else
    {
      if(value.value.toLowerCase() == 'commissiongratuity')
      {
        this.reportType = "commission"; 
      }
      else
      {
        this.reportType = value.value?.toLowerCase(); 
      } 
    } 
  }
  ngOnInit() { 
    if(!this.localize.isFromJobScheduler)
    {
    const idx = this.route.pathFromRoot.length - 1;
    if (idx > 0) {
      this.subscription$ = this.route.pathFromRoot[idx].data.subscribe(data => {
          this.reportType = data.type;
      });
    }
  }
  }

  handleTabChange(tab?: MatTab, tabHeader?: MatTabHeader, idx?: number) {
    if (idx == this.tabs.TransactionLog && !this.checkForTransLogTabAccess()) {
      return;
    }
    this.selectedTabIndex = idx;
    this.selectedIndex = idx;
    return true;
  }

  checkForTransLogTabAccess(): boolean {
    return this.breakpoint.CheckForAccess([ReportBreakPoint.TransactionLog]);
  }

  ngOnDestroy(): void {
    if(!this.localize.isFromJobScheduler)
    {
    this.subscription$.unsubscribe();
    }
  }
  saveReportConfig(e)
  {
    this.clickSave.emit(e);
  }
}

