import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'app-pms-integration-setup-wrapper-modal',
  templateUrl: './pms-integration-setup-wrapper-modal.component.html',
  styleUrls: ['./pms-integration-setup-wrapper-modal.component.scss']
})
export class PmsIntegrationSetupWrapperModalComponent implements OnInit,OnDestroy {
  captions;
  intgrationsetupValues: { formIndex: any; toggleVal: any; postTypeMappingJson: any };

  constructor(private localization: Localization, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<PmsIntegrationSetupWrapperModalComponent>) {
    this.captions = this.localization.captions;
  }

  ngOnInit() {
    this.intgrationsetupValues = {
      formIndex: this.data?.formIndex,
      toggleVal: this.data?.toggleVal,
      postTypeMappingJson: this.data?.postTypeMappingJson
    }
  }

  ngOnDestroy(): void {
    
  }

  close() {
    this.dialog.close();
  }

  tableDataemit(e) {
    this.dialog.close(e);
  }

}
