import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CancelReasonComponent } from '@components/cancel-reason/cancel-reason.component';
import { buttonTypes } from '@constants/commonenums';
import { popupDialogDimension } from '@constants/globalConstants';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { SideNavService } from '@services/sidenav.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reservation-view',
  templateUrl: './reservation-view.component.html',
  styleUrls: ['./reservation-view.component.scss']
})
export class ReservationViewComponent implements OnInit, OnDestroy {
  dashboardMessages: any = [];
  componentDetails: ComponentDetails;
  holdBtn: any;
  cancelBtn: any;
  config: FieldConfig[];
  @Input() selectEnabled: boolean;
  buttonValueprimary: ButtonValue;
  buttonSecondary: ButtonValue;
  subscriptions: Subscription = new Subscription();

  constructor(public dialog: MatDialog, public sn: SideNavService) { }

  ngOnInit() {
    this.config = [{
      type: 'checkbox',
      name: 'language',
      placeholder: 'selectLanguage',
      class: 'login__language-selection',
      showErrorText: true
    }];
    this.dashboardMessages = [{ id: 1, name: 'John Smith', Text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', status: 'just now', viewed: true },
    { id: 2, name: 'Adam Smith', Text: 'and more recently with desktop publishing software ', status: '2 mins ago', viewed: true },
    { id: 2, name: 'Adam Smith', Text: 'and more recently with desktop publishing software ', status: '2 mins ago', viewed: false }];

    this.subscriptions.add(this.sn.clearData.subscribe(val => {
      if (val === 2) {
        while (this.dashboardMessages.length > 0) {
          this.dashboardMessages.splice(this.dashboardMessages.length - 1, 1);
        }
      }
    }));

    this.buttonValueprimary = {
      type: buttonTypes.actionPrimary,
      label: 'dsd',
      customclass: 'message-view__hold-btn'
    }
    this.buttonSecondary = {
      type: buttonTypes.actionSecondarySmall,
      label: 'wewqe',
      customclass: 'message-view__cancel-btn'
    }
  }

  cancelReservation() {
    //let componentDetails: ComponentDetails;
    //this.viewAllEvent.emit('close');
    this.componentDetails = {
      componentName: CancelReasonComponent,
      popupType: '',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };

    this.holdBtn = {
      type: 'secondary',
      label: 'rwerwe'

    }
    // this.cancelBtn = {
    //   type: 'tertiary-btn',
    //   label: 'CANCEL',

    // }

    this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: popupDialogDimension.actionDialogHeight,
      width: popupDialogDimension.actionDialogWidth,
      backdropClass: 'backdropBackground',
      data: {
        title: 'Add popup', update: 'SAVE', cancel: 'CANCEL', componentDetails: this.componentDetails
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
