import { Injectable, } from "@angular/core";
import { PrintInformationType } from "src/app/common/Models/printer-default-configuration.model";
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";
import { BaseResponse, LetterTemplateType, RetailItem } from "../../retail.modals";
import { Host } from "../../shared/globalsContant";
import { TemplateDataService } from "../../shared/service/data- services/template.data.service";
import { TenantConfigurationDataService } from "../../shared/service/data- services/tenantConfiguration.data.service";
import { HttpMethod, HttpServiceCall } from "../../shared/service/http-call.service";
import { SaleChitService } from "./salechit.service";

@Injectable()
export class SaleChitBusinessService {


    constructor(private readonly saleChitService: SaleChitService,private tenantConfig:TenantConfigurationDataService,
        private _templateDataService: TemplateDataService, private http: HttpServiceCall,private utilities : CommonUtilities
    ) {
    }

    public getRetailSaleChit(transactionId, RetailItemId: number[], isRePrint, printType) {
        let ids :number [] =[];
        ids.push(transactionId);
        let jsonData = {
            transactionId: ids
        };
        if (isRePrint) {
            this.printSaleChit(jsonData, 0, printType);
        }
        else if (RetailItemId.length > 0) {
            this.printSaleChit(jsonData, 0, printType);
        }

    }
    private async printSaleChit(jsonData, RetailItemId: number, printType) {
        const setting = JSON.parse(sessionStorage.getItem('GOLFSETTING'));
        let url = setting.printerManagerURI ;
        let saleChitPrinter = 	await this.utilities.getPrinterConfigurationToPrint(PrintInformationType.CartAgreement);

        let printLetter= this._templateDataService.PrintLetter(jsonData, RetailItemId, LetterTemplateType.RetailSaleChit, printType);
        this.saleChitService.printSaleChit(url,saleChitPrinter);
    }
}
