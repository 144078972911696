import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from "lodash";
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { popupConfig } from '../../shared/business/shared.modals';
import { Subscription } from 'rxjs';
import { SettingDialogPopupComponent } from '../../shared/setting-dialog-popup/setting-dialog-popup.component';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';

import { RetailTaxesDataService } from './retail-taxes-data.service';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';

@Component({
  selector: 'app-retail-taxes',
  templateUrl: './retail-taxes.component.html',
  styleUrls: ['./retail-taxes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailTaxesComponent implements OnInit {
  tableoptions: any;


  tabledata: any[] = [];

  EditedtableOptions: any[];
  searchPlaceholderText: string;
  filteredData: any = [];
  filteredDataByOutlet: any[];
  captions: any = this.localization.captions.retailsetup;
  outletId: number;
  IsViewOnly: boolean;
  subscription: Subscription;
  settingDialogSubscription: Subscription;
  EditCaption: string;

  constructor(private dialog: MatDialog, private retailService: RetailSetupService, public localization: RetailLocalization
    , private propertyInfo: RetailPropertyInformation, private utils: RetailUtilities, private retailsTaxesDataService: RetailTaxesDataService) {
  }

  ngOnInit() {
    this.getAllTaxes();
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(bp => bp.breakPointNumber == myGlobals.RetailBreakPoint.Taxconfiguration).view;
    if (this.propertyInfo.IsVATEnabled) {
      this.searchPlaceholderText = this.captions.TaxName;
    }
    else {
      this.searchPlaceholderText = `${this.captions.Outlet}, ${this.captions.TaxName}`;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.settingDialogSubscription) {
      this.settingDialogSubscription.unsubscribe();
    }
  }

  async getAllTaxes() {
    this.tabledata = await this.retailsTaxesDataService.getAllTaxConfiguration();
    this.BindTableData();
  }

  BindTableData() {
    this.tableoptions = [{
      TableHdrData: this.GetHeader(),
      TablebodyData: this.tabledata,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext:  this.searchPlaceholderText,
      EnableActions: true,
      SelectRows: false,
      SelectedSettingId: myGlobals.GridType.taxes,
      Searchable: myGlobals.GridType.customfield,
      EditMoreOption: true,
      Sortable: "outletName",
      TableId: myGlobals.GridType.taxes,
      disableDelete: false,
      showToggle: false,
      IsViewOnly: this.IsViewOnly,
      disableEditButton: this.IsViewOnly,
      automationid: 'retailTaxes'
    }];

    this.EditedtableOptions = _.cloneDeep(this.tableoptions);
    this.filteredData = this.tabledata;
    this.filteredDataByOutlet = this.tabledata;
  }

  EditRecords(data) {
    this.openDialog('Edit', data);
  }

  async DeleteRecords(event) {
    await this.retailsTaxesDataService.deleteTaxConfiguration(event[0].id);
    this.getAllTaxes();
  }

  openDialog(preTit: any, data?: any): any {
    let popupConfiguration: popupConfig;
    if (preTit == "Edit") {
      this.EditCaption = this.localization.captions.common.Edit
      popupConfiguration = {
        operation: "edit",
        GridOperationType: myGlobals.GridOperationType.Edit
      };
    } else {
      popupConfiguration = {
        operation: "create",
        GridOperationType: myGlobals.GridOperationType.Create
      };
    }
    let afterClosed = true;
    return this.triggerPopup(preTit, popupConfiguration, afterClosed, data);
  }


  triggerPopup(preTit: any, popupConfiguration: any, afterClosed = true, data: any): any {
    const Dialogtitle = this.captions.NewTax;
    const DialogTemplate = 'NT';
    const dialogRef = this.dialog.open(SettingDialogPopupComponent, {
      width: '90%',
      maxWidth: '935px',
      height: "90%",
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: { headername: (preTit == "Edit" ? this.EditCaption : preTit) + '  ' + Dialogtitle, closebool: true, templatename: DialogTemplate, popupConfig: popupConfiguration, datarecord: preTit == "Edit" ? data : '', tabledata:this.tabledata },
      disableClose: true
    });

    if (afterClosed) {
      this.settingDialogSubscription = dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getAllTaxes();
        }
        if (document.getElementsByClassName("rowDisabled") && document.getElementsByClassName("rowDisabled").length > 0) {
          document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
          document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
        }
      });
    }
    return dialogRef;
  }

  private GetHeader() {
    var TableHdrData = [{ "title": this.captions.Outlet, "jsonkey": "outletName", "sortcolumndatatype": "string", "searchable": true, "sortable": true },
    { "title": this.captions.TaxName, "jsonkey": "taxName", "searchable": true, "sortable": true },
    { "title": this.captions.TaxAmount, "jsonkey": "taxAmountFormatted", "searchable": false, "sortable": true, "sortcolumn": "taxAmount", "alignType": "right", "sortcolumndatatype": "number", "groupSort": true, "groupSortType": [false, true], "groupSortJsonKey": "isAmount" },
    { "title": this.captions.dummy, "jsonkey": "dummy", "searchable": false, "sortable": false },
    { "title": this.captions.StartDate, "jsonkey": "startDate", "searchable": false, "sortable": true },
    { "title": this.captions.EndDate, "jsonkey": "endDate", "searchable": false, "sortable": true }
    ];
    if (this.propertyInfo.IsVATEnabled) {
      TableHdrData = TableHdrData.filter(r => r.jsonkey != 'outletName');
    }
    return TableHdrData;
  }
}
