<app-dialog-header [title]="title" (closeDialog)="close()"></app-dialog-header>
<div [formGroup]="cartGuestForm" class="hgt-setter ag_container--padding-md ag_pb--0">
  <div class="ag_w--90 can-guest-search-wrapper">
    <mat-radio-group class="ag_display--inblock ag_mb--5" [attr.automationId]="'Rdo_cartGuestSearch_selectedGuestType'"
      [value]="selectedGuestType" (change)="GuestSearchTypeChange($event)">
      <mat-radio-button class="mr-3 ag_mb--2"
        *ngFor="let guestSeacrhType of GuestSeacrhTypes" [value]="guestSeacrhType.id">
        {{guestSeacrhType?.description}}
      </mat-radio-button>
    </mat-radio-group>
    <div class="ag_form-control--sm">
      <app-ag-toggle *ngIf="platFormExtendedSearchRequired" class="toggle"
          [toggleInputs]="platformSearchInput" (valueChange)="platformGuestSearch($event)">
      </app-ag-toggle>
  </div>
  <div class="ag_display--flex align-items-center custom-guest-chip">
    <app-chip-search [searchMaxCharLength]="50" [allData]="guestList" [selectedData]="selectedFromGuest" [searchKey]="searchKey"
      [autoCompleteKeys]="autocompleteKeys" [selectedChipKey]="selectedChipKey"
      (selectedChipDataEmit)="selectedChipDataEmit($event)" (searchTextEmit)="SearchTextHandler($event)"
      (onChipRemove)="OnChipRemove($event)" [placeholderText]="searchPlaceholder"></app-chip-search>
      <a [attr.automationId]="'Lbl_cartGuestSearch_createnewGuest'" class="ag_link ag_ml--4" (click)="CreateNewGuestClicked($event)">
        {{captions.lbl_createnewGuest}}</a>
    </div>
</div>
  <div class="fields-container p-0">
    <ag-date formControlName="travelStartDate" required [placeholder]="captions.lbl_travelStartDate"
      [minDate]="minStartDate"  class="ag_position--relative" [automationId] = "'Txt_cartGuestSearch_travelStartDate'"></ag-date>
    <ag-input formControlName="nights" (change)='formValueChanged($event)' automationId="'Txt_cartGuestSearch_nights'" (input)="formValueChanged($event)"
      type='NUMBER' required [placeholder]="captions.lbl_nights" class="nights-field" [min]='0' [max]="maxNoNights">
    </ag-input>
    <ag-input class="ag_position--relative" automationId="'Txt_cartGuestSearch_packageCode'" formControlName="packageCode" [placeholder]="captions.lbl_pkgCode">
    </ag-input>
  </div>
</div>
<app-dialog-footer [buttonObj]="buttonObj" (cancel)="close()" (save)="save($event)"></app-dialog-footer>