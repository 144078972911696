import { Injectable } from '@angular/core';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { CartItineraryUI, CartPayload, CartWindowMessage, CartWindowMessageType, GuestInfo } from './vcart.modal';
import { uniqBy } from 'lodash';
import { Localization } from 'src/app/common/localization/localization';
import { AlertType, AlertAction, ButtonType } from 'src/app/common/enums/shared-enums';
import { PropertyInformationDataService } from 'src/app/common/dataservices/authentication/property-information.data.service';
import { ButtonTypes } from 'src/app/common/Models/common.models';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';
@Injectable(
    {
        providedIn: 'root'
    }
)
export class CartUtilities {
    captions: any;

    vCartEnabled: string = null; // default value has to be null, based on this inital call will be made to set the config value
    vcartEnabledForTenant: string = null;
    constructor(private utils: CommonUtilities, 
        private localization: Localization, 
        private tenantService: PropertyInformationDataService,
        private userAccessBusiness:UserAccessBusiness) {
        this.captions = this.localization.captions;
    }

    // This method will return the active cart itinerary info from the session storage
    getActiveCartItinerary() {
        var selectedCart: CartItineraryUI;
        var cartItineraryList = sessionStorage.getItem('CartItineraryList');
        if (cartItineraryList) {
            var cartItineraries: CartItineraryUI[] = JSON.parse(cartItineraryList);
            selectedCart = cartItineraries.find(o => o.expanded);
        }
        return selectedCart;
    }
    
    async getUserAccessForVCart(): Promise<boolean>{
        let isAllow : boolean = false;
        try{
        isAllow = (await this.userAccessBusiness.getUserAccess(UserAccessBreakPoints.VCART, false)).isAllow;
        }catch(e)
        {

        }
        return isAllow;
    }

    async getUserAccessForVCartViewAllItineraries(): Promise<boolean>{
        let isAllow : boolean = false;
        try{
         isAllow = (await this.userAccessBusiness.getUserAccess(UserAccessBreakPoints.VCARTVIEWALLITINERARIES, false)).isAllow;
        }catch(e)
        {

        }
        return isAllow;
    }

    async checkIfAnyActivityPresentInCart(startDate: string, endDate: string, guestIds: string[], showAlertMessage: boolean = true): Promise<boolean> {
        let presentInCart = false;
        let itinerary = this.getActiveCartItinerary();
        if (itinerary && itinerary.cartDetails.length > 0) {
            for (let i = 0; i < itinerary.cartDetails.length; i++) {
                const cartDetail = itinerary.cartDetails[i];
                let cartPayload: CartPayload = cartDetail.cartPayload;
                if (cartPayload && cartPayload.productCode != "VERSA") {
                    if ((this.localization.getDate(cartPayload.startDate).getTime() >= this.localization.getDate(startDate).getTime() &&
                        this.localization.getDate(cartPayload.startDate).getTime() <= this.localization.getDate(endDate).getTime())
                    ) {

                        const existingGuestsInTheCart = cartPayload.guestInfos.map(o => o.guestId).filter((element) => { return guestIds.includes(element) });
                        if (existingGuestsInTheCart && existingGuestsInTheCart.length) {
                            presentInCart = true;
                            if (showAlertMessage) {
                                const guestInfo = cartPayload.guestInfos.find(o => o.guestId == existingGuestsInTheCart[0]);
                                let warnMessage = this.localization.replacePlaceholders(this.captions.warn_guestHasActivityOnSameTime, ['guestName', 'activityName'], [guestInfo.lastName + ', ' + guestInfo.firstName, cartPayload.description]);
                                const dialogRef = this.utils.showCommonAlert(warnMessage, AlertType.Warning, ButtonTypes.YesNo, null);
                                const res = await dialogRef.afterClosed().toPromise();
                                presentInCart = res === AlertAction.YES ? false : true;
                            }
                            break;
                        }
                    }
                }
            }
        }        
        return presentInCart;
    }

    // This will return all distinct guests from the active Cart Itinerary 
    getCartGuests(): GuestInfo[] {
        var guests: GuestInfo[] = [];
        var cartItineraryList = sessionStorage.getItem('CartItineraryList');
        var cartItineraries: CartItineraryUI[] = [];
        if (cartItineraryList) 
            cartItineraries = JSON.parse(cartItineraryList);
        
        if (cartItineraryList && cartItineraries.length > 0) {
            var selectedCart: CartItineraryUI;
            var cartItineraries: CartItineraryUI[] = JSON.parse(cartItineraryList);
            selectedCart = cartItineraries.find(o => o.expanded);
            if (selectedCart && selectedCart.cartDetails && selectedCart.cartDetails.length) {
                var guestDetails = selectedCart.cartDetails?.flatMap(cd => cd.cartPayload?.guestInfos).filter(o => o.guestId != '00000000-0000-0000-0000-000000000000' || o.platformGuestUuid != '00000000-0000-0000-0000-000000000000');
                guests = guestDetails?.map(x => {
                    return {
                        platformGuestUuid: x.platformGuestUuid,
                        guestId: x.guestId,
                        firstName: x.firstName,
                        lastName: x.lastName,
                        email: x.email,
                        phone: x.phone
                    }
                });
                 // guests = uniqBy(guests, 'guestId');
            }
            if (!guests.find(o => o.guestId == selectedCart.primaryGuestId)) {
                if(selectedCart.primaryGuestId != '00000000-0000-0000-0000-000000000000')
                    guests.push({
                        firstName: selectedCart.primaryGuestFirstName,
                        lastName: selectedCart.primaryGuestLastName,
                        guestId: selectedCart.primaryGuestId,
                        platformGuestUuid: selectedCart.primaryGuestUuId,
                        phone: [],
                        email: []
                    });
            }
        }
        return guests;
    }
    // This method will push the messages from CART window to all the product windows
    pushMessageToChildWindows(type: CartWindowMessageType, data?: any) {
        var messageObject: CartWindowMessage = {
            channel: 'VCART',
            sender: 'PARENT',
            productId: parseInt(this.utils.GetPropertyInfo('ProductId')),
            type: type,
            data: data
        }
        const message = JSON.stringify(messageObject);
        const childWindows = this.getChildWindows();
        childWindows.forEach(cw => {
            cw.postMessage(message, '*');
        });
        window.postMessage(message, '*');
    }

    // This method will push the messages from Embedded product to the CART window
    pushMessageToParentWindow(type: CartWindowMessageType, data?: any) {
        var messageObject: CartWindowMessage = {
            channel: 'VCART',
            sender: 'EMBEDDED_PRODUCT',
            productId: parseInt(this.utils.GetPropertyInfo('ProductId')),
            type: type,
            data: data
        }
        const message = JSON.stringify(messageObject);
        if (this.isEmbed()) {
            window.parent.postMessage(message, '*');
        }
        else {
            window.postMessage(message, '*');
        }
    }

    // This will set embed flag as 1 and will be consumed everywhere to see if the application is embedded in to an iframe
    setEmbedFlag(): void {
        sessionStorage.setItem("embed", '1');
    }

    // This method returns true when the application embeded into an application as an Add-On Iframe to support V-Cart
    isEmbed(): boolean {
        // const value = sessionStorage.getItem("embed");
        return (window.self !== window.top);
    }

    // This method will set the no-header class to the body element to hide the Menus when the application is embed as an Add-On Iframe
    setEmbedScreenUIClasses() {
        var body = document.getElementsByTagName('Body')[0];
        if (body)
            body.classList.add("no-header")
    }

    getChildWindows(): Window[] {
        var childWindow: Window[] = [];
        const iframes = document.querySelectorAll('iframe');
        iframes.forEach(iframe => {
            childWindow.push(iframe.contentWindow);
        });
        return childWindow;
    }

    // This will enable/disable the loader inside the vcart component
    ToggleLoader(loader, loaderMessage: string = null) {
        const loadingContainer = document.getElementById('vcart-cover-spin');
        const loadingContainerMessage = document.getElementById('vcart-custom-cover-message');
        if (loadingContainer) {
            if (loader) {
                loadingContainer.style.display = 'flex';
            }
            else {
                loadingContainer.style.display = 'none';
            }
            if (loaderMessage) {
                loadingContainerMessage.innerText = loaderMessage;
            } else {
                loadingContainerMessage.innerText = this.captions.lbl_processing;
            }
        }
    }

    getCartItineraryById(id, itineraryList?: CartItineraryUI[]) {
        let cartItineraryList = itineraryList;
        let cartItinerary;
        if (cartItineraryList) {
            cartItineraryList = JSON.parse(sessionStorage.getItem('CartItineraryList'));
            cartItinerary = cartItineraryList.find(x => x.id == id);
        }
        return cartItinerary;
    }
    
    publishBroadcastMessage()
    {
        this.pushMessageToChildWindows(CartWindowMessageType.CartDataBroadcast, this.getCartItineraryList());
    }

    publishCartItinerarySelected()
    {
        this.pushMessageToChildWindows(CartWindowMessageType.CartItinerarySelected, this.getActiveCartItinerary());
    }

    getCartItineraryList(): CartItineraryUI[] {
        let cartItineraryList: CartItineraryUI[] = [];
        let itineraryList = sessionStorage.getItem("CartItineraryList");
        if (itineraryList && itineraryList.length > 0) {
            cartItineraryList = JSON.parse(itineraryList);
        }
        return cartItineraryList;
    }

    showErrorAlert(message: string = null) {
        if (!message) {
            this.utils.showAlert(this.captions.PMUnexpectedError, AlertType.Error);
        }
        else {
            this.utils.showAlert(message, AlertType.Error);
        }
    }

    formatName(fName, lName) {
        if (fName && lName && lName.trim().length > 0 && fName.trim().length > 0) {
          return fName + " , " + lName;
        }
        else if (fName && fName.trim().length > 0)
          return fName;
        else if (lName && lName.trim().length > 0)
          return lName;
        else
          return '';
      }

    async isVcartEnabledForTenant() {
        if (this.vcartEnabledForTenant == null) { // Call has not made to Tenant Service to fetch the settings | Singleton
            try {
                const tenantId = this.localization.GetsessionStorageValue('propertyInfo', 'TenantId');
                const config = await this.tenantService.GetVCartConfiguration(Number(tenantId));
                if (config && config.configValue) {
                    if (config.configValue.enableVCart && (config.configValue.enableVCart == '1'
                        || config.configValue.enableVCart.toLowerCase() == 'true')) {
                        this.vcartEnabledForTenant = '1';
                    }
                }            
                if(this.vcartEnabledForTenant != '1')
                    this.vcartEnabledForTenant = '0';
            } catch(e) { this.vcartEnabledForTenant == null }
        }
        return this.vcartEnabledForTenant == '1' ? true : false;
    }
 
    async isVcartEnabled() {
        if (this.vCartEnabled == null) { // Call has not made to Tenant Service to fetch the settings | Singleton
            try {
                const hasVcartAccess = await this.getUserAccessForVCart();
                if(hasVcartAccess) {
                if (this.isVcartEnabledForTenant()) {
                        this.vCartEnabled = '1';                    
                }
            }
                if(this.vCartEnabled != '1')
                    this.vCartEnabled = '0';
            } catch(e) { this.vCartEnabled == null }
        }
        return this.vCartEnabled == '1' ? true : false;
    }

    // Returns all the Tenant level Configurations set for V Cart
    async getVCartConfigurations(){
        try{
            const tenantId = this.localization.GetsessionStorageValue('propertyInfo', 'TenantId');
            const config = await this.tenantService.GetVCartConfiguration(Number(tenantId));
            if(config && config.configValue) 
            return config;
        } 
        catch(e) {
            console.log(e);        
        }
    }
    
     // this function is to hide the overlay displayed behind the vcart window when it is expanded
    hideExpandVcartoverlay()
    {
        var checkOverlay = document.getElementsByTagName('body')[0].classList.contains("vcart-overlay")
        if (checkOverlay) {
          document.getElementsByTagName('body')[0].classList.remove('vcart-overlay');
        }
    }
}
