<!-- <div class="clientStateSection mt-3">
        <button mat-button class="act_btn_name clientState roboto_light pr-1 pl-1" *ngFor = "let item of tableTabService.temp_seat_details.clientState" [ngClass]="{'act_btn_bg':item.status == 'Y'}">
        {{item.name}}
        </button>
      </div>
    </div> -->

<div class="clientStateSection ">
    <!-- <button mat-button class="act_btn_name clientState roboto_light pr-1 font_color_gray" *ngFor="let StatusName of SeatStatus" [ngClass]="{act_btn_bg: StatusName.clicked }">
        {{ StatusName.name }}
    </button> -->
   
    <activities-app-button class="btn-section" id="btn-{{Status.Id}}"
     [buttontype]="Status.Name |  buttonInputFormater : buttonType.actionSecondarySmall :'btn-section':false"  *ngFor="let Status of SeatStatus" (click)="seatStatusChanged(Status)"></activities-app-button>
</div>