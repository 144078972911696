import { Directive, HostListener, OnInit, OnDestroy } from '@angular/core';

@Directive({
    selector: '[dateRestricter]'
})
export class DateRestricterDirective implements OnInit, OnDestroy {
    private regex: RegExp = new RegExp(/[0-9+#.]/);
    
    ngOnInit() {
    }

    ngOnDestroy() {

    }
    @HostListener('keypress', ['$event']) onKeyDown(event)  {
        const e = event as KeyboardEvent;        
        if (!event.key.match(/[0-9-/.]/)) {
            e.preventDefault();
        }
    }
    @HostListener('paste', ['$event']) onPaste(event) {
        debugger
    }
}
