import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { AppService } from '@app/app.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { ActivityRatePlanRequest, CommentType } from '@app/shared/models/ActivityRatePlanRequestDTO';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { BookingBehavior, ComponentTypes, PartyNoteType, RolesAndPermissionsType, ValidationMessageType, PartyType, PaymentMethod, buttonTypes, SingleDayActivityType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { FormChipService } from '@dynamicform/components/form-chip/form-chip.service';
import { DynamicFormService } from '@dynamicform/service/dynamic-form.service';
import { BookingChargeType, Category, PartyNoteDTO, SettingsDTO, TaxableItem } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { FacadeService } from '@services/facade.service';
import { PartyService } from '@services/party.service';
import moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { RateNegotiationComponent } from '@app/shared/components/rate-negotiation/rate-negotiation.component';
import { CurrencyDecimalFormater } from '@app/shared/pipes/currency-decimal.pipe';
import { PartyNotes } from '@app/shared/models/global.interface';
import * as _ from 'lodash';
import { RolesAndPermissionPipe } from '@app/shared/pipes/RolesAndPermission.pipe';
import { PartyPrepaymentState } from '@app/shared/models/InputContact';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { PackageDTO, PackageShortDTO } from '@app/shared/models/PackageDTO';

@Component({
  selector: 'open-booking-summary',
  templateUrl: './open-booking-summary.component.html',
  styleUrls: ['./open-booking-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OpenBookingSummaryComponent extends Utilities implements OnInit, OnDestroy {

  selectedIndex: number;
  intialized: boolean = false;
  subscriptions: Subscription = new Subscription();
  ratePlan: any;
  coverTypes = [];
  bookingTypes: any = [];
  lockResponse;
  _settings: SettingsDTO;
  showAllSlots: boolean = false;
  OperationCurrency: string;
  enableGuestComponent: boolean = false;
  ratePlanValidationMsg = '';
  remainingAmount = 0;
  totalPayableAmount = 0;
  totalAddOnAmount = 0;
  totalAddOnTaxAmount = 0;
  negotiationReason: any = '';
  today: number = Date.now();
  showGuestDetails: boolean = false;
  restaurantPolicyAvailable: Boolean = false;
  restaurantPolicy: any = [];
  config: FieldConfig[] = [];
  includeOthers = false;
  othersId: number[] = [];
  customPartyNotes: any[] = [];
  partyNotes: PartyNotes[] = [];
  categories: Category[] = [];
  taxAmount = 0;
  selectedPartyPreferenceTags: any[] = [];
  isTaxConfigured = false;
  isRetailEnabledProperty = false;
  selectedAddOns: any;
  totalServiceCharge: any;
  totalServiceChargeWithTax:number =0;
  isServiceConfigured = false;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  @ViewChild('negotiateReasonForm') negotiateReasonForm: DynamicFormComponent;
  isPaymentScreen = false;
  selectedGuestData: any;
  fromComponent: any;
  negotiationRatesConfig: FieldConfig[];
  recalculateConfig: FieldConfig[];
  perItemEditable = false;
  sessionEditable = false;
  subTotalEditable = false;
  totalAmountForBooking = 0;
  applyNegotiationBtn:ButtonValue;
  cancelNegotiationBtn:ButtonValue;
  rateNegotiateConfig: FieldConfig[];
  firstCall = true;
  ratePlanOriginal:any ;
  originalAdjustedMinRate: number = 0.00;
  adjustedMinRate: number = 0.00;
  activitiesAmount: number = 0.00;
  subtotal: number = 0.00;
  serviceCharge: number = 0.00;
  taxOnServiceCharge: number = 0.00;
  tax: number = 0.00;
  minRateTaxOnServiceChargeAmount:number = 0.00;
  grandTotal: number = 0.00;
  totalAddonTaxOnServiceChargeAmount: number = 0.00;
  negotiatedDiscount: number = 0.00;
  totalDepositAmount = 0;
  showDepositAmount = false;
  applyNegotiationClicked: boolean = false;
  grandTotalValidated: boolean = false;
  negotiationCutOff = 0;
  partyData:any;
  prevSalePrice: any = {
    cover: {},
    bookingType: {},
    base: {},
    location: {},
    instructor: {},
    adjustedMinRate: 0,
    membership: {}
  }
  @ViewChild('negotiationConfigForm', { static: true }) negotiationConfigForm: DynamicFormComponent;
  @ViewChild('paymentType', { static: true }) paymentTypeForm: DynamicFormComponent;
  constructor(public dialog: MatDialog, public partyService: PartyService, private cs: CacheService, private ps: PopupService, @Inject(COMPONENTINPUT) public data, public translateService: TranslateService,
  private currencyDecimalFormater:CurrencyDecimalFormater,public permissions: RolesAndPermissionPipe,public facadeService: FacadeService, private formChipService: FormChipService, private dfs: DynamicFormService, public _as: AppService, private api: ApiService, private popupService: PopupService, public dashboardData: DashboardFunctions) {
    super(dialog);
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.isTaxConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax)?.length > 0;
      this.isServiceConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge)?.length > 0;
      this.isRetailEnabledProperty = Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO);
      this.OperationCurrency = this._settings.General.OperationCurrency;
      this.coverTypes = [...this._settings.CoverTypes];
      this.bookingTypes = [...this._settings.BookingTypes];
      this.partyService.isNegotiationEnabled = this._as.hasPermission(RolesAndPermissionsType.NegotiateRates);
      if (this._settings.PropertySetting.length > 0 && (this._settings.PropertySetting[0].PreReservationMessage && this._settings.PropertySetting[0].PreReservationMessageTitle)) {
        this.restaurantPolicyAvailable = true;
        this.restaurantPolicy = this._settings.PropertySetting[0];
      }
      this.categories = this._settings.Categories.filter(category => category.Text != "Reasons");
      if (data && this._as.OTASourceId.includes(data.PartySourceId)) {
        this.includeOthers = true;
      }
      if (data && this.data.Notes && this.data.Notes.length) {
        this.othersId = this.data.Notes.filter(x => x.Type == PartyNoteType.FreeFormNote).map(({ RelatedId }) => RelatedId);
      }
      this.partyNotes = Utilities.getRestaurantPredefinedPartyNotes(this.categories, this.includeOthers, this.othersId);

    }));
    this.subscriptions.add(this.partyService.tabChange$.subscribe(value => {
      let getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, null);
      if ((value === 1 && (!getAddOns || getAddOns?.length == 0 || getAddOns?.filter(addOn => addOn.IsVisible == true).length == 0))|| (value == 2 && getAddOns?.length > 0 && getAddOns?.filter(addOn => addOn.IsVisible == true).length > 0)) {
        this.isPaymentScreen = true;
        this.intialize();
        this.showGuestDetails = true;
        this.selectedGuestData = this.partyService.reservationFormGroup.value.selectedGuest;
        console.log(this.selectedGuestData);
      } else {
        this.isPaymentScreen = false;
        var guest = this.partyService.reservationFormGroup.get("selectedGuest").value;
        if (!this.partyService.selectedGuest && !guest) {
          this.showGuestDetails = false;
        }
      }
    }));
  }


  ngOnInit() {
    this.partyData = !_.isEmpty(this.partyService.retailservice.oldReservation)? this.partyService.retailservice.oldReservation.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation[0]: null;
    this.subscriptions.add(this.partyService.openNextPopUp$.subscribe(data => {
      if (data) {
        this.showWarningPopup(this.ratePlanValidationMsg);
        this.partyService.operationType = false
      }
    }));
    this.perItemEditable = this.permissions.transform(RolesAndPermissionsType?.NegotiateRates)
    this.setNegotiationConfig();
    // this.subscriptions.add(this.partyService.tabChange$.subscribe(value => {
    //   let getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, null);
    //   if ((value === 1 && (!getAddOns || getAddOns?.length == 0 || getAddOns?.filter(addOn => addOn.IsVisible == true).length == 0))|| (value == 2 && getAddOns?.length > 0 && getAddOns?.filter(addOn => addOn.IsVisible == true).length > 0)) {     
    //     this.intialize();
    //     this.showGuestDetails = true;
    //   } else {
    //     var guest = this.partyService.reservationFormGroup.get("selectedGuest").value;
    //     if (!this.partyService.selectedGuest && !guest) {
    //       this.showGuestDetails = false;
    //     }
    //   }
    // }));
    // this.subscriptions.add(this.partyService.tabChange$.subscribe(value => {
    //   let getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, null);
    //   if ((value === 1 && (!getAddOns || getAddOns?.length == 0 || getAddOns?.filter(addOn => addOn.IsVisible == true).length == 0)) || (value == 2 && getAddOns?.length > 0 && getAddOns?.filter(addOn => addOn.IsVisible == true).length > 0)) {
    //     this.intialize();
    //     this.showGuestDetails = true;
    //   } else {
    //     var guest = this.partyService.reservationFormGroup.get("selectedGuest").value;
    //     if (!this.partyService.selectedGuest && !guest) {
    //       this.showGuestDetails = false;
    //     }
    //   }
    // }));

  }

  setNegotiationConfig() {
    this.negotiationRatesConfig = [{
      type: 'switch',
      name: 'negotiation',
      inputType: 'text',
      label: 'NegotiableRates',
      class: 'negotiation-rate',
      checked: false
    }
    ];
    this.recalculateConfig = [{
      type: 'switch',
      name: 'applyafterNegotiation',
      inputType: 'text',
      label: 'Recalculate',
      class: 'recalculate',
      checked: false
    }
    ];
    this.rateNegotiateConfig = [
      {
        name: 'reasonText',
        type: 'textarea',
        label: 'EnterText',
        rows: 2,
        inputType: 'text',
        showHint: true,
        charLength: 400,
        textAreaAppearance: 'outline',
        class: 'reject-options__input',
        value: this.setNegotiationReason(),
        validation:[Validators.required],
        disableErrorStateMatcher:true
      }]

    this.applyNegotiationBtn = {
      type: buttonTypes.actionPrimarySmall,
      label: 'apply',
      disbaledproperity: this.negotiationReason == '',
      customclass: 'action-bar__search-btn',
    }
    this.cancelNegotiationBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: 'reset',
      disbaledproperity: false,
      customclass: 'action-bar__search-btn',
    }
    
  }

  intialize() {
    this.firstCall = true;
    this.partyService.ratePlanForBooking = this.ratePlan =null;
    if (this.data && this.data.Contact) {
      this.partyService.selectedGuest = this.data.Contact;
      this.fromComponent = ComponentTypes.EditOpenBooking
    }

    if (this.data && this.data.Notes && this.data.Notes.length > 0) {
      //  let filteredNotes = this.data.Notes.filter(_note => _note.Type == PartyNoteType.SpecialMeal);
      let filteredNotes = this.data.Notes;
      if (filteredNotes && filteredNotes.length > 0) {
        filteredNotes.forEach(element => {
          this.partyService.partyNotes.push(element);
        });
      }
    }
    this.bindGuestPartyNotes();
    if (this.data && this.data.Notes && this.data.Notes.length > 0) {
      this.partyService.partyNotes = [];
      this.mapPartyNotes();
      if (this.customPartyNotes.length) {
        this.mapCustomPartyNotes();
      }
    }
    this.selectedAddOns = this.dashboardData.getSelectedAddOns()
    this.formChipsChange();
    this.formChangeSubscribe();
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === OpenBookingSummaryComponent);
    this.lockTables(!!this.data?.CartId);

  }

  lockTables(bypassLock) {
    this.partyService.BookingBehaviour = BookingBehavior.OpenBooking;
    var { Slots, CoverTypes, BookingTypes, Location, BookingSize } = this.partyService.openBookingData;
    var slots = Slots.map(slot => {
      return {
        LocalTime: slot.LocalTime,
        PartySize: BookingSize,
        OverBooked: this.partyService.openBookingData?.OverBooked ? BookingSize : 0,
        SeatingAreaId: null,
        SeatingTypeId: null,
        IsCommunalTable: false,
        FromWebsite: true,
        DurationInMinutes: slot.Duration,
        ReservationIdToIgnore: this.data?.Id,
        TableIds: [Location],
        AddOns: this.dashboardData.getSelectedAddOns(),
        ExpireAt: null,
        lockIdToIgnore: this.data?.bookingRatePlan?.SlotLockResultDTO?.[slot.LocalTime]?.SlotLockIdDTO?.Id
      }
    });
    this.enableGuestComponent = false;
    this.changeFormState(false);
    if (slots.length && !this.checkStandByReservations()) {
      if(!bypassLock){
        this.subscriptions.add(this.api.lockTables(slots).subscribe(response => {
          this.lockResponse = response.Payload;
          const lockData = Object.values(this.lockResponse) as any[];
          this.partyService.lockData = response.Payload;
          this.partyService.isSlotLocked = true;
          this.partyService.slotLockIds = lockData?.map(x => {
            if (x?.SlotLockIdDTO?.Id) {
              return x?.SlotLockIdDTO?.Id;
            }
            else {
              return 0;
            }
          });
  
        }));
      }
      this.setRatePlanCalculation(Slots, CoverTypes, BookingTypes, Location, BookingSize);
    } else {
      if (this.checkStandByReservations())
        this.setRatePlanCalculation(Slots, CoverTypes, BookingTypes, Location, BookingSize);
    }

  }

  setRatePlanCalculation(Slots, GuestTypes, BookingTypes, Location, BookingSize, Payload?,resetData?) {
    //this.lockResponse = Payload;
    var request = {
      Slots: Slots,
      ActivityId: null,
      GuestTypes,
      BookingTypes,
      TableIds: [Location],
      Size: BookingSize,
      SessionSlot: null,
      TrackMembershipNumber:  this.partyService.selectedGuest?.TrackMembershipNumber,
      MemberType: this.partyService.selectedGuest?.MembershipType,
      IsMemberActive: this.partyService.selectedGuest?.MemberActive,
      RateType:  this.partyService.selectMemberRatePlane(
        {
          ActivityId: null, 
          Slots: Slots, 
          Location : Location,
          sessionGroupId : null
        }),
      RateCode: this.partyService.selectedGuest?.RateType,
      BookingBehavior: BookingBehavior.OpenBooking,
      SelectedAddOns: this.dashboardData.getSelectedAddOns(),
      SeatingTypeIds: null,
     // Overbooked :  availableMandatoryAddOn.Overbooked = addOnQuantity - availableMandatoryAddOn.AvailableQuantity;
     RatePlanTotalNegotiation: this.data && this.data.bookingRatePlan?.RatePlanTotalNegotiation && (this.firstCall || resetData) ? this.data.bookingRatePlan?.RatePlanTotalNegotiation :  this.formData(resetData)
    } as ActivityRatePlanRequest;
    if(this.dashboardData.isBookingDataUpdated(request,this.data)){
      request['RatePlanTotalNegotiation'] = this.formData(resetData)
    }
    this.totalDepositAmount = 0;
    this.showDepositAmount = false;
    this.originalAdjustedMinRate = 0.00;
    this.adjustedMinRate = 0.00;
    this.negotiatedDiscount = 0.00;
    this.activitiesAmount = 0.00;
    if( this.ratePlan && !this.applyNegotiationClicked){
      this.ratePlan.FinancialEffectId = null;
    }
    this.subscriptions.add(this.api.GetRatePlanCalculated(request,(this.ratePlan?.FinancialEffectId || null)).subscribe(response => {
      this.grandTotalValidated = true;
      let ignoreNegotiation = !this.applyNegotiationClicked && this.dashboardData.isBookingDataUpdated(request,this.data);

      if(this.partyService.openBookingDataSaveObj){
        this.partyService.openBookingDataSaveObj.summaryState = 'valid';
      }

      
      if(this.firstCall){
        this.ratePlanOriginal = response.Payload;
      }
      this.partyService.ratePlanForBooking =this.ratePlan = response.Payload;
      if (this.ratePlan && this.ratePlan.RatePlanCalculations) {
        this.partyService.FinancialEffectId = this.ratePlan.FinancialEffectId;
        this.negotiationCutOff = this.ratePlan.TotalRatePlan;
        this.taxAmount = this.ratePlan.TotalTaxAmount;
        this.totalServiceCharge = _.cloneDeep(this.ratePlan.TotalServiceChargeAmount);
        this.totalServiceChargeWithTax = this.ratePlan.TotalTaxOnServiceChargeAmount;
        this.totalAddonTaxOnServiceChargeAmount = this.ratePlan.TotalAddonTaxOnServiceChargeAmount;
        this.minRateTaxOnServiceChargeAmount = this.ratePlan.MinRateAdjustment?.TaxOnServiceChargeAmount || 0;
        this.totalAddOnAmount = this.ratePlan.TotalAddonAmount;
        this.totalAddOnTaxAmount = this.ratePlan.TotalAddonTaxAmount;
        // let uneditableSlotRatePlan = this.getUneditableSlotsRatePlan();
        // if (uneditableSlotRatePlan && uneditableSlotRatePlan.length) {
        //   this.ratePlan.TotalRatePlan = this.ratePlan.TotalRatePlan + uneditableSlotRatePlan.map(r => r.TotalAmount).reduce((sum, current) => sum + current, 0);
        //   this.uneditableTaxAmount = uneditableSlotRatePlan.map(r => r.TaxAmount).reduce((sum, current) => sum + current, 0)
        //   this.taxAmount += this.uneditableTaxAmount;
        //   this.ratePlan.TotalTaxAmount = this.ratePlan.TotalTaxAmount + this.uneditableTaxAmount;
        //   this.negotiationCutOff += uneditableSlotRatePlan.filter(r => r.sessionNegotiation < 0).map(r => r.sessionNegotiation).reduce((sum, current) => sum + Math.abs(current), 0)
        //   uneditableSlotRatePlan = _.orderBy(uneditableSlotRatePlan, s => s.TimeRange.Start);
        //   this.ratePlan.RatePlanCalculations = [...uneditableSlotRatePlan, ...this.ratePlan.RatePlanCalculations];
        // }
        if (this.data && this.firstCall) {
          this.totalPayableAmount = this.partyService.openBookingDataSaveObj && (this.partyService.openBookingDataSaveObj.NegotiatedAmount || this.partyService.openBookingDataSaveObj.NegotiatedAmount == 0) ? this.ratePlan.TotalRatePlan + this.partyService.openBookingDataSaveObj.NegotiatedAmount
            : (this.data.NegotiatedAmount) ? this.ratePlan.TotalRatePlan + this.data.NegotiatedAmount : this.ratePlan.TotalRatePlan;
          this.remainingAmount = this.partyService.openBookingDataSaveObj && this.partyService.openBookingDataSaveObj.NegotiatedAmount ? this.partyService.openBookingDataSaveObj.NegotiatedAmount : this.ratePlan.OverAllNegotiation;
          this.negotiationReason = this.dashboardData.negotiationReason(this.data,this.partyService.openBookingDataSaveObj);
          let ratePlanDetails = this.data && this.data.bookingRatePlan ? this.data.bookingRatePlan.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation : this.ratePlan.RatePlanCalculations; 
          this.ratePlan.RatePlanCalculations.forEach(item =>{
            if(item.AddonAttemptDetails?.length){
              let addOnItem = ratePlanDetails.find(data => data.Id == item.Id)?.AddonAttemptDetails;
              if(addOnItem)
              {
              addOnItem.forEach(element => {
                item.AddonAttemptDetails.filter(addOn => addOn.AddonId == element.AddonId).map(data =>{
                  data.NegotiatedAmount = element.NegotiatedAmount || 0;
                })
              });
            }
            }
           
          })
          
                 }
        else {
          this.totalPayableAmount = this.partyService.openBookingDataSaveObj?.NegotiatedAmount ? this.ratePlan.TotalRatePlan + this.partyService.openBookingDataSaveObj.NegotiatedAmount : this.ratePlan.TotalRatePlan;
          this.remainingAmount = this.partyService.openBookingDataSaveObj?.NegotiatedAmount ? this.partyService.openBookingDataSaveObj.NegotiatedAmount : this.ratePlan.OverAllNegotiation;
          this.negotiationReason = this.dashboardData.negotiationReason(this.data,this.partyService.openBookingDataSaveObj);

        }
        if (this.ratePlan.TotalDepositAmount > 0 || this.data?.DepositPaid) {
          if (!this.data || !this.data.Id || this.data.PrepaymentState == PartyPrepaymentState.DepositCollected) {
            this.totalDepositAmount = this.data?.DepositPaid ? this.data.DepositPaid : this.ratePlan.TotalDepositAmount;
            this.showDepositAmount = true;
          }
        }
        let dataForSlotRatePlanCalc = {
          applyNegotiationClicked: this.applyNegotiationClicked,
          ignoreNegotiation: ignoreNegotiation,
          prevSalePrice: this.prevSalePrice
        }
        let calculatedRatePlan = this.dashboardData.formRatePlanSlotValue(this.ratePlan,this.partyService.openBookingData.BookingSize,this.data,this.firstCall,dataForSlotRatePlanCalc,this.data && this.data.bookingRatePlan ? this.partyData : null);
        this.ratePlan.SlotList = calculatedRatePlan.ratePlan;
        
        this.activitiesAmount = calculatedRatePlan.activitiesAmount;

        this.negotiatedDiscount = calculatedRatePlan.negotiatedDiscount;
      //   this.ratePlan.SlotList = this.ratePlan?.RatePlanCalculations.map(slot => {
      //    // if(this.data){
      //     slot.activitiesAmount = 0.00;
      //     slot.slotItemDiscount = 0.00;          
      //     slot.sessionCharge = 0.00;          
      //     let ratePlanDetails= this.data && this.data.bookingRatePlan && this.firstCall ? this.data.bookingRatePlan?.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation:this.ratePlan.RatePlanCalculations;
      //     let slotItem  = ratePlanDetails.find(data => data.TimeRange.Start == slot.TimeRange.Start);
      //     slot.totalSessionCharge = 0;
      //     if((!slot.RatePlanVariants.GuestTypeVariant || slot.RatePlanVariants.GuestTypeVariant.length == 0) && (!slot.RatePlanVariants.BookingTypeVariant || slot.RatePlanVariants.BookingTypeVariant.length == 0)){
      //       slot.sessionBaseVariant =(slot.RatePlanVariants.OriginalBaseRate) 
      //       + (slot.RatePlanVariants.OriginalInstructorVariant ) 
      //       + (slot.RatePlanVariants.OriginalLocationVariant );
        
      //       slot.sessionCharge = (slot.sessionBaseVariant + (slot.RatePlanVariants.TotalNegotiation || 0)) * this.partyService.openBookingData.BookingSize;
      //       slot.sessionChargeinitialValue = slot.sessionBaseVariant * this.partyService.openBookingData.BookingSize;
          
      //       slot.totalSessionCharge += slot.sessionChargeinitialValue;
      //      // slot.activitiesAmount+= slot.totalSessionCharge;
      //       this.negotiatedDiscount += slot.sessionChargeinitialValue - slot.sessionCharge;
  
      //       slot.activitiesAmount +=  slot.sessionChargeinitialValue;
            
      //     }

      //     slot.coverTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.GuestTypeVariant ? slot.RatePlanVariants.GuestTypeVariant.map(coverVariant => {
      //       coverVariant.adjustedMinRate = 0;
      //       if(slotItem && slotItem.GuestTypeVariant?.length){
      //         slotItem.GuestTypeVariant.forEach(element => {
      //           slot.GuestTypeVariant.filter(guest => guest.CoverTypeId == element.CoverTypeId).map(data =>{
      //             data.NegotiatedAmount = element.NegotiatedAmount || 0;
      //           })
      //         });
      //       }
      //       coverVariant.CoverTypeName = this.coverTypes.find(coverType => coverType.Id == coverVariant.CoverTypeId).Name;
      //                coverVariant.Price = (((slot.RatePlanVariants.TotalBaseRate / this.partyService.openBookingData.BookingSize) +
      //       (slot.RatePlanVariants.InstructorVariant / this.partyService.openBookingData.BookingSize) +
      //       (slot.RatePlanVariants.LocationVariant / this.partyService.openBookingData.BookingSize)) *
      //       coverVariant.covers) + coverVariant.Variant;
      //       coverVariant.initialValue =  this.currencyDecimalFormater.transform(((coverVariant.Price ) / coverVariant.covers)) ;
      //       coverVariant.coverTypeValue =  this.currencyDecimalFormater.transform(((coverVariant.Price + (coverVariant.NegotiatedAmount || 0)) / coverVariant.covers));

      //       coverVariant.calculatesBaseVariant = (slot.RatePlanVariants.OriginalBaseRate) 
      //       + (slot.RatePlanVariants.OriginalInstructorVariant ) 
      //       + (coverVariant.OriginalVariant ) 
      //       + (slot.RatePlanVariants.OriginalLocationVariant );

      //       coverVariant.negotiatedBaseRate = coverVariant.coverTypeValue;

      //       coverVariant.itemTotal = coverVariant.negotiatedBaseRate * coverVariant.covers;
      //       coverVariant.originalItemValue = coverVariant.calculatesBaseVariant * coverVariant.covers;
      //       coverVariant.itemDiscount = coverVariant.originalItemValue - coverVariant.itemTotal;
              
      //       slot.totalSessionCharge += coverVariant.originalItemValue;
      //       slot.slotItemDiscount += coverVariant.itemDiscount;
      //       this.negotiatedDiscount += coverVariant.itemDiscount;

      //       if(coverVariant.MinimumCoverTypeId && coverVariant.CoverTypeId != coverVariant.MinimumCoverTypeId){
      //         let minCoverTypeData = slotItem.RatePlanVariants?.GuestTypeVariant.find(guestTypeVariant => guestTypeVariant.CoverTypeId === coverVariant.MinimumCoverTypeId);
      //         if(minCoverTypeData && minCoverTypeData.covers < 1){
      //           coverVariant.adjustedMinRate += minCoverTypeData.OriginalVariant - coverVariant.OriginalVariant;
      //           this.adjustedMinRate += coverVariant.adjustedMinRate;
      //         }
      //       }

      //       slot.activitiesAmount += coverVariant.originalItemValue + coverVariant.adjustedMinRate;

      //       return coverVariant;
      //     }).filter(coverVariant => coverVariant.covers > 0) : [];
      //     // if(slotItem && slotItem.RatePlanVariants && slotItem.RatePlanVariants.GuestTypeVariant?.length){
      //     //   slotItem.RatePlanVariants?.GuestTypeVariant.forEach(currGuestTypeVariantItem => {

      //     //     if(currGuestTypeVariantItem.MinimumCoverTypeId && currGuestTypeVariantItem.CoverTypeId != currGuestTypeVariantItem.MinimumCoverTypeId){
      //     //         let minCoverTypeData = slotItem.RatePlanVariants?.GuestTypeVariant.find(guestTypeVariant => guestTypeVariant.CoverTypeId === currGuestTypeVariantItem.MinimumCoverTypeId);
      //     //         if(minCoverTypeData && minCoverTypeData.covers < 1){
      //     //           this.adjustedMinRate += minCoverTypeData.OriginalVariant - currGuestTypeVariantItem.Variant;
      //     //         }
      //     //     }

      //     //   });
      //     // }

      //     slot.bookingTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.BookingTypeVariant ? slot.RatePlanVariants.BookingTypeVariant.map(bookingVariant => {
      //       bookingVariant.adjustedMinRate = 0.00;
      //       let ratePlanDetails= this.data && this.data.bookingRatePlan && this.firstCall ? this.data.bookingRatePlan?.RatePlanTotalNegotiation.RatePlanCalculationInfoNegotiation:this.ratePlan.RatePlanCalculations;
      //  //     let slotItem  = ratePlanDetails.find(data => data.Id == slot.Id);
      //       if(slotItem && slotItem.BookingTypeVariant?.length){
      //         slotItem.BookingTypeVariant.forEach(element => {
      //           slot.BookingTypeVariant.filter(guest => guest.BookingTypeId == element.BookingTypeId).map(data =>{
      //             data.NegotiatedAmount = element.NegotiatedAmount || 0;
      //           })
      //         });
      //       }
      //       bookingVariant.BookingTypeName = this.bookingTypes.find(bookingType => bookingType.Id == bookingVariant.BookingTypeId).Name;
      //                 bookingVariant.Price = (((slot.RatePlanVariants.TotalBaseRate / this.partyService.openBookingData.BookingSize) +
      //       (slot.RatePlanVariants.InstructorVariant / this.partyService.openBookingData.BookingSize) +
      //       (slot.RatePlanVariants.LocationVariant / this.partyService.openBookingData.BookingSize)) *
      //       bookingVariant.bookingTypes) + bookingVariant.Variant;
      //       bookingVariant.initialValue =  this.currencyDecimalFormater.transform(((bookingVariant.Price )/ bookingVariant.bookingTypes));
      //       bookingVariant.bookingTypeValue = this.currencyDecimalFormater.transform(((bookingVariant.Price  + (bookingVariant.NegotiatedAmount || 0))/ bookingVariant.bookingTypes));

      //       bookingVariant.calculatesBaseVariant = (slot.RatePlanVariants.OriginalBaseRate) 
      //       + (slot.RatePlanVariants.OriginalInstructorVariant ) 
      //       + (bookingVariant.OriginalVariant ) 
      //       + (slot.RatePlanVariants.OriginalLocationVariant );

      //       bookingVariant.negotiatedBaseRate = bookingVariant.bookingTypeValue;

      //       bookingVariant.itemTotal = bookingVariant.negotiatedBaseRate * bookingVariant.bookingTypes;
      //       bookingVariant.originalItemValue = bookingVariant.calculatesBaseVariant * bookingVariant.bookingTypes;
      //       bookingVariant.itemDiscount = bookingVariant.originalItemValue - bookingVariant.itemTotal;
              
      //       slot.totalSessionCharge += bookingVariant.originalItemValue;
      //       slot.slotItemDiscount += bookingVariant.itemDiscount;
      //       this.negotiatedDiscount += bookingVariant.itemDiscount;
      //       slot.activitiesAmount += bookingVariant.originalItemValue;
      //       return bookingVariant;
      //     }).filter(bookingVariant => bookingVariant.bookingTypes > 0) : [];
      //     slot.totaladdonCharges = 0.00;
      //     slot.AddonAttemptDetails = slot.AddonAttemptDetails ? slot.AddonAttemptDetails.map(addOnattempt => {
      //       if(slotItem && slotItem.AddonAttemptDetails.length && this.firstCall){
      //         slotItem.AddonAttemptDetails.forEach(element => {
      //           slot.AddonAttemptDetails.filter(addOn => addOn.AddonId == element.AddonId).map(data =>{
      //             data.NegotiatedAmount = element.NegotiatedAmount || 0;
      //           })
      //         });
      //       }
      //       addOnattempt.perItemValue = this.currencyDecimalFormater.transform(((addOnattempt.TotalAmount + (addOnattempt.NegotiatedAmount || 0)) / addOnattempt.Quantity));
      //       addOnattempt.initialValue = this.currencyDecimalFormater.transform(((addOnattempt.TotalAmount) / addOnattempt.Quantity));
        
      //       addOnattempt.negotiatedBaseRate = addOnattempt.perItemValue;

      //       addOnattempt.itemTotal = addOnattempt.negotiatedBaseRate * addOnattempt.Quantity;
      //       addOnattempt.originalItemValue = addOnattempt.initialValue * addOnattempt.Quantity;
      //       addOnattempt.itemDiscount = addOnattempt.originalItemValue - addOnattempt.itemTotal;
              
      //       slot.totaladdonCharges += addOnattempt.originalItemValue;
      //       slot.slotItemDiscount += addOnattempt.itemDiscount;
      //       this.negotiatedDiscount += addOnattempt.itemDiscount;             
      //       return addOnattempt;
      //     }) :[]

      //     //this.ratePlan.RatePlanVariants.TotalBaseRate * this.partyService.openBookingData.BookingSize
      //     // slot.totalBaseRate = slot.RatePlanVariants.OriginalBaseRate * this.partyService.openBookingData.BookingSize;
      //     // slot.totalSessionCharge = (slot.OverallSessionNegotiatedAmount || 0) + slot.TotalAmount;
      //   //  slot.totalAddOnAmount = slot.TotalAddOnAmount + slot.OverallAddonTotalNegotiatedAmount;
      //     slot.activitiesAmount += slot.totaladdonCharges;
      //     this.activitiesAmount += slot.activitiesAmount;
      //     return slot;
      //   });
      this.adjustedMinRate = calculatedRatePlan.adjustedMinRate;
      this.ratePlan.MinRateAdjustment.Amount = this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount + (this.ratePlan.MinRateAdjustment.NegotiatedAmount || 0);
        this.originalAdjustedMinRate = this.adjustedMinRate;
        this.setAdjustedMinRate();
      } else {
        this.ratePlan.SlotList = Slots;
      }
      this.partyService.ratePlan$.next(this.ratePlan);
    
      this.enableGuestComponent = true;
      if (this.partyService.reservationFormGroup.controls.selectedGuest.value) {
        this.changeFormState(true);
      } else {
        this.changeFormState(false);
      }
      if (!this.ratePlan.TotalRatePlan || (this.ratePlan.RatePlanCalculations.filter(slot => !slot.TotalAmount).length)) {
        this.ratePlanValidationMsg = this.translateService.instant('noRatePlanMessage');

        if ((!this.data || (this.data && !this.data.Contact))) {
          this.ratePlanValidationInfo();
        }
        else {
          if (this.partyService.isGuestComponentLoaded) {
            this.ratePlanValidationInfo();
          }
        }
      }
      this.createSaveRequestObject();
      if (this.ratePlan.SlotList && this.ratePlan.SlotList.length > 0) {
        this.ratePlan.SlotList.sort((a, b) => Utilities.parseDateString(a.TimeRange.Start).getTime() - Utilities.parseDateString(b.TimeRange.Start).getTime());
      }
      if (this.partyService.openBookingData.Slots && this.partyService.openBookingData.Slots.length > 0) {
        this.partyService.openBookingData.Slots.sort((a, b) => Utilities.parseDateString(a.LocalTime).getTime() - Utilities.parseDateString(b.LocalTime).getTime());
      }
      if ((this.data && this.data.NegotiatedAmount) || (!this.data && this.remainingAmount)) {
        this.calculateNegotiableRate();
      }
      this.totalAmountForBooking = _.cloneDeep(this.ratePlan.TotalRatePlan + this.ratePlan.TotalAddonAmount);
      this.formChangeSubscribe();
      this.calculatePaymentDetails();
      this.setRateSummary();
      this.applyNegotiationClicked = false;
    }));
  
  }

  setRateSummary(){
    this.partyService.openBookingDataSaveObj.rateSummary = {
      Amt: this.subtotal,
      TaxAmt: this.tax,
      SCAmt: this.serviceCharge,
      TaxOnSCAmt: this.taxOnServiceCharge
    }
  }
  calculatePaymentDetails(){
    // this.activitiesAmount = this.ratePlan.TotalRatePlan + this.ratePlan.TotalAddonAmount + this.adjustedMinRate;
   // this.activitiesAmount = this.activitiesAmount - this.slotItemDiscount
    // this.subtotal = this.activitiesAmount - this.negotiatedDiscount;
    this.onNegotiate(true);
    this.serviceCharge = (this.totalServiceCharge || 0) + (this.ratePlan.TotalAddonServiceChargeAmount || 0) + (this.ratePlan.MinRateAdjustment.ServiceChargeAmount || 0);
    this.tax = (this.taxAmount || 0) + (this.totalAddOnTaxAmount || 0) + (this.ratePlan.MinRateAdjustment.TaxAmount || 0);
    this.taxOnServiceCharge = (this.totalServiceChargeWithTax || 0) + (this.totalAddonTaxOnServiceChargeAmount || 0) + (this.minRateTaxOnServiceChargeAmount || 0);
    this.grandTotal = this.subtotal + this.serviceCharge + this.tax + this.taxOnServiceCharge;
    let guestSelected = this.partyService.reservationFormGroup.controls.selectedGuest.value;
    this.changeFormState(guestSelected);
  }

  onNegotiate(forGrandTotal?){
    this.partyService.openBookingDataSaveObj.summaryState = forGrandTotal ? 'valid' : 'invalid';
    this.grandTotalValidated = forGrandTotal || false;
    this.changeFormState(this.partyService.reservationFormGroup.controls.selectedGuest.value);
    this.ratePlan.SlotList.forEach(slot => this.negotiateTotalSessionCharge(slot));
    this.subtotal = +this.ratePlan.SlotList.reduce((res , slot) => res + slot.negotiatedTotalSessionCharge, 0) + (this.ratePlan?.MinRateAdjustment?.Amount || 0);
    this.negotiatedDiscount = this.activitiesAmount - this.subtotal;
  }

  negotiateTotalSessionCharge(updatedSlot?){
    //+ updatedSlot.RatePlanVariants.TotalBaseRate
        if(updatedSlot){
          let baseRateValue = 0;
          let negotiatedTotalCoverValue = updatedSlot.coverTypeVariants && updatedSlot.coverTypeVariants.length ? updatedSlot.coverTypeVariants.reduce((res, cover) => res + ((cover.coverTypeValue) * cover.covers), 0): 0;
          let negotiatedTotalBookingTypeValue = updatedSlot.bookingTypeVariants && updatedSlot.bookingTypeVariants.length ? updatedSlot.bookingTypeVariants.reduce((res, bookingType) => res + ((bookingType.bookingTypeValue) * bookingType.bookingTypes), 0): 0;
          //if(!updatedSlot.coverTypeVariants?.length && !updatedSlot.bookingTypeVariants?.length){
            baseRateValue = updatedSlot.sessionCharge;;
          //}
          let negotiatedTotalAddonValue = updatedSlot.AddonAttemptDetails.reduce((res, addOn) => res + (addOn.perItemValue * addOn.Quantity), 0);
          let totalLocationVariant = +(updatedSlot.RatePlanVariants ? (updatedSlot.RatePlanVariants.locationAmount || 0) : 0);
          let totalInstructorVariant = +(updatedSlot.RatePlanVariants ? (updatedSlot.RatePlanVariants.instructorAmount || 0):0);
          let totalMembershipVariant = updatedSlot.membershipAmount || 0;
      updatedSlot.negotiatedTotalSessionCharge = +(totalLocationVariant + totalInstructorVariant + negotiatedTotalCoverValue + negotiatedTotalBookingTypeValue + negotiatedTotalAddonValue + baseRateValue + totalMembershipVariant);
        }
      }

  formData(resetData){
    
    if(this.partyService.ratePlanForBooking){
      this.partyService.getNegotiatedValueForBooking(this.partyService.openBookingData);
    return  {
      Negotiation: null,
      MinRateNegotiatedAdjustment: this.ratePlan.MinRateAdjustment.Amount - this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount,
      applyNegotiationOnOtherCharges:[
        {             
          bookingChargeType : BookingChargeType.ServiceCharge,
          TotalAmount: this.partyService.ratePlanForBooking.TotalServiceCharge,
          NegotiatedAmount: null,
          UseAfterNegotiation: this.dashboardData.negotiateServiceCharge
        }
      ],
      RatePlanCalculationInfoNegotiation: this.partyService.ratePlanForBooking?.RatePlanCalculations && !resetData ? this.partyService.ratePlanForBooking?.RatePlanCalculations : null
    }
  }
    else{
      return null;
    }
  }

  ratePlanValidationInfo(){
    const _dialogRef = this.showConfirmationPopup('Alert', this.ratePlanValidationMsg, 'active', true, 'Proceed', 'Cancel');
    _dialogRef.afterClosed().subscribe((dataValue) => {
      if(dataValue){
        this.partyService.openBookingDialogRef ? this.partyService.openBookingDialogRef.close() : '';
        this.partyService.openBookingDialogRef ? this.partyService.openBookingDialogRef = null : '';
        this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef.close() : '';
        this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef = null : '';
      }
      
      this.ratePlanValidationMsg = '';
    });
  }

  showConfirmationPopup(title, message, popupType: 'action' | 'active' | '', showAlert, saveBtnLabel, cancelBtnLabel){
          
    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: title, showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType,
      dimensionType: 'small',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };
    const _dialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      width: '550px',
      height: '350px',
      data: {
        title,
        update: saveBtnLabel,
        cancel: cancelBtnLabel,
        componentDetails,
        from: null,
        back: false,
        standalone: true,
        showAction: true
      },
    });
    return _dialogRef;
  }

  showWarningPopup(message) {
    if (message) {
      let dontContinueBooking = false

      const popUpMessage = [{
        confirmationMessage: message, dialogTitle: 'Alert', showAlert: true
      }];

      const componentDetails: ComponentDetails = {
        componentName: ConfirmationPopupComponent,
        dimensionType: 'small',
        popupType: 'active',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: popUpMessage,
        popupTitle: popUpMessage[0].dialogTitle
      };

      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '550px',
        height: '350px',
        data: {
          title: this.translateService.instant('alert'),
          update: 'Yes',
          cancel: 'No',
          componentDetails,
          from: ComponentTypes.RatePlanWarning,
          back: false,
          standalone: true,
          showAlert: true
        }
      });
      this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
        if (dontContinueBooking) {
          this.partyService.openBookingDialogRef ? this.partyService.openBookingDialogRef.close() : '';
          this.partyService.openBookingDialogRef ? this.partyService.openBookingDialogRef = null : '';
          this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef.close() : '';
          this.partyService.reservationDialogRef ? this.partyService.reservationDialogRef = null : '';
        }
        if (cancelPopUpConfirmSubscription) {
          cancelPopUpConfirmSubscription.unsubscribe();
        }
        this.ratePlanValidationMsg = '';
      }));
      let cancelPopUpConfirmSubscription = this.popupService.cancelledAction$.subscribe(val => {
        if (val.from === ComponentTypes.RatePlanWarning) {
          dontContinueBooking = true;
        }
      });
    }
    // this.ps.nextBtnEnabled$.next(false);
  }

  enableGuestFormComponent() {
    this.createSaveRequestObject();
    this.enableGuestComponent = true;
    if (this.partyService.reservationFormGroup.controls.selectedGuest.value) {
      this.changeFormState(true);
    } else {
      this.changeFormState(false);
    }
  }
  ngAfterViewInit() {
    this.ps.previousButtonEnabled$.next(true);
    if (this.data && this.data.Notes && this.data.Notes.length > 0) {
      let filteredNotes = this.data.Notes.filter(_note => _note.Type == PartyNoteType.SpecialMeal);
      if (filteredNotes && filteredNotes.length > 0) {
        filteredNotes.forEach(element => {
          this.partyService.partyNotes.push(element);
        });
      }
    }
    this.formChipsChange();
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.partyService.isGuestComponentLoaded = false;
    this.partyService.partyNotes = [];
    this.partyService.ratePlanForBooking = null;
    this.dashboardData.enabledNegotiation = false;
    this.dashboardData.negotiateServiceCharge = false;
  }

  createSaveRequestObject() {
    var bookingData = this.partyService.openBookingData;
    this.partyService.openBookingDataSaveObj = {
      "Id": this.data?.addOpenBooking ? null : (this.data && this.data.Id) ? this.data.Id : null,
      "Size": bookingData.BookingSize,
      "RemindAboutVisit": false,
      "RemindAboutVisitEmail": false,
      "AgreedWithTermsOfUse": true,
      "Contact": {},
      "Slots": this.getRequestObjectSlots(),
      "PartyId":this.data?.addOpenBooking ? null : (this.data && this.data.Id) ? this.data.Id : null,
      "SeatingAreaId": null,
      "SeatingTypeId": null,
      "ConfirmationMessageOption": 0,
      "Notes": this.partyService.partyNotes,
      "TableIds": [this.partyService.openBookingData.Location],
      "CoverTypes": this.partyService.openBookingData.CoverTypes,
      "BookingTypes": this.partyService.openBookingData.BookingTypes,
      "IsCommunalTable": false,
      "FromWebsite": true,
      "Language": 0,
      "ReservationAttemptId": null,
      "waiverFormTransactionId": null,
      "SecondaryContactsList": null,
      "BookingBehavior": BookingBehavior.OpenBooking,
      "StartDate": new Date(this.partyService.openBookingData.FromDate).toDateString(),
      "EndDate": new Date(this.partyService.openBookingData.ToDate).toDateString(),
      "NegotiatedAmount": this.ratePlan?.TotalRatePlan > 0 ? this.remainingAmount : 0,
      "Comments": this.negotiationReason,
      "AttemptSlots": this.getAttemptSlotObj(this.partyService.openBookingData.Slots),
      "TotalPayable": (this.totalPayableAmount || 0) + (this.totalAddOnAmount || 0),
      "TaxAmount": (this.taxAmount || 0) + (this.totalAddOnTaxAmount || 0),
      "PackageConfirmationId": (this.data && this.data.PackageConfirmationId) ? this.data.PackageConfirmationId : null,
      "PackageId": (this.data && this.data.PackageId) ? this.data.PackageId : null,
      "IsForStandbyReservations": this.checkStandByReservations(),
      "Type": this.checkStandByReservations() ? PartyType.StandBy : PartyType.Reservation,
      "HostId": this.data?.Id ? this.data.HostId : JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostId,
      "FinancialEffectId":this.partyService.FinancialEffectId,
      "RetailCardTokenId":this.partyService.RetailCardTokenId,
      "PaymentMethod":this.partyService.bookingPaymentType,
      "summaryState": this.partyService.openBookingDataSaveObj?.summaryState,
      "SalesContactIds": bookingData?.SalesContactIds,
      "PackageDetails":  this.data?.PackageId ? this.getDiscountPercentForCartItem(this.data?.PackageId , null) : null,
      OverBooked: bookingData?.OverBooked,
      MinRateNegotiatedAdjustment:  this.ratePlan?.MinRateAdjustment?.Amount - this.ratePlan?.MinRateAdjustment?.MinRateAdjustmentAmount,
    }
  }

  getDiscountPercentForCartItem(PackageId:number , activityId : number) {
    let selectedPackage = this.cs.availablePackages.find(item => item.Id === PackageId) as PackageDTO;
    let packageDetails = {
      PackageId: selectedPackage.Id,
      PackageName: selectedPackage.Name,
      PackageDiscount: selectedPackage?.DiscountPercent || null,

    } as PackageShortDTO;
    if (selectedPackage.SingleDayActivityType == SingleDayActivityType.SpecificActivity) {
      packageDetails.PackageDiscount = selectedPackage.PackageActivities.find(item => (item.ActivityId == activityId) || (!item.ActivityId))?.DiscountPercent;
    }
    return packageDetails;
  }
  setPartyPaymentType(data){
    if(data && this.partyService.openBookingDataSaveObj){
      this.partyService.bookingPaymentType = data;
      this.partyService.openBookingDataSaveObj.PaymentMethod = data;
    }
  }
  setRetailCardTokenId(data){
    if(data && this.partyService.openBookingDataSaveObj){
      this.partyService.RetailCardTokenId = data;
      this.partyService.openBookingDataSaveObj.RetailCardTokenId = data;
    }
  }
  checkStandByReservations() {
    if (this.partyService.selectedOpenBookingSlots && Object.values(this.partyService.selectedOpenBookingSlots).filter(value => value)?.length == 1) {
      let isStandBy = false
      Object.keys(this.partyService.selectedOpenBookingSlots).forEach(key => {
        if (this.partyService.selectedOpenBookingSlots[key]) {
          isStandBy = (this.partyService.slots_holder.find(item => item.Id == key).IsStandByBookingSlot) && !this.partyService.isOverBookSlotSelected ? true : false;
        }
      })
      return isStandBy;
    } else {
      return false;
    }
  }
  getRequestObjectSlots() {
    var bookingData = this.partyService.openBookingData;

    return bookingData.Slots.map((slot, index) => {
      return {
        Time: slot.LocalTime,
        LockId: (this.lockResponse) ? this.lockResponse[slot.LocalTime]?.SlotLockIdDTO?.Id : null,
        Type: 3,
        DurationInMinutes: slot.Duration,
        OverBooked: this.partyService.isOverBookSlotSelected ? this.partyService.openBookingData.OverBooked : null
      }
    })
  }
  changeFormState(enable) {
    this.ps.formValid$.next(
      {
        isFormValid: enable && this.totalPayableAmount >= 0,
        currentTab: 1
      } as IFormValidDetails
    );
  }
  // editNegotiationAmount() {
  //   this.partyService.editRates = true;
  //   this.partyService.negotiableRatesChanged = true;
  //   this.partyService.rateNegotiationObject = {
  //     Total: this.ratePlan?.TotalRatePlan,
  //     Payable: this.totalPayableAmount,
  //     Reason: this.negotiationReason
  //   }
  //   this.openRatenegotiationpopup();
  // }

  formChangeSubscribe() {
    this.components.forEach((x, index) => {
      this.subscriptions.add(x.form?.valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(value => {
          this.formChangeHandler(value);
        }));
    })
    if(this.negotiateReasonForm?.form){
      this.subscriptions.add(this.negotiateReasonForm.form.valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(value => {
          this.formChangeHandler(value);
      }));
    }
  }

  formChangeHandler(value){
    this.bindInternalPartyNotes(value);
    this.enableGuestFormComponent();
    this.enableNegotiationOption(value);
    this.applyTaxForNegotiated(value);
    this.addNegotiationReason(value);
  }


  addNegotiationReason(value){
    if (value[this.rateNegotiateConfig[0].name]) { 
      this.negotiationReason = value[this.rateNegotiateConfig[0].name];  
      this.partyService.openBookingDataSaveObj.Comments = this.negotiationReason;
      this.applyNegotiationBtn.disbaledproperity = this.negotiationReason == '' ? true : false;
      // this.partyService.rateNegotiationObject = {       
      //   Reason: value[this.rateNegotiateConfig[0].name].reasonText.find(reason => reason.commentType == CommentType.Negotiation)
      // }
    }
  }

  enableNegotiationOption(value) {
    if (value[this.negotiationRatesConfig[0].name]) {
      this.perItemEditable = true;
      this.dashboardData.enabledNegotiation = true;
   //   this.editNegotiationAmount();
    }
    else if(value[this.negotiationRatesConfig[0].name] == false){
      this.perItemEditable = false;
      this.dashboardData.enabledNegotiation = false;
      this.editNegotiationAmount();
    }

  }


  editNegotiationAmount() {
    this.ratePlan = this.partyService.ratePlanForBooking = _.cloneDeep(this.ratePlanOriginal);
    this.ratePlan.SlotList = this.ratePlan?.RatePlanCalculations.map(slot => {
      slot.coverTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.GuestTypeVariant ? slot.RatePlanVariants.GuestTypeVariant.map(coverVariant => { 
              coverVariant.coverTypeValue =  _.cloneDeep(coverVariant.initialValue) 

         return coverVariant;
       }).filter(coverVariant => coverVariant.covers > 0) : [];
       
       slot.bookingTypeVariants = slot.RatePlanVariants && slot.RatePlanVariants.BookingTypeVariant ? slot.RatePlanVariants.BookingTypeVariant.map(bookingVariant => {
        bookingVariant.bookingTypeValue =  _.cloneDeep(bookingVariant.initialValue) 

        return bookingVariant;
         
       }).filter(bookingVariant => bookingVariant.bookingTypes > 0) : [];

       slot.AddonAttemptDetails = slot.AddonAttemptDetails ? slot.AddonAttemptDetails.map(addOnattempt => {
             addOnattempt.perItemValue =  _.cloneDeep(addOnattempt.TotalAmount)
         return addOnattempt;
       }) :[]
       slot.totalBaseRate = (slot.RatePlanVariants.TotalBaseRate + slot.RatePlanVariants.TotalVariants + slot.RatePlanVariants.TotalNegotiation )/ this.partyService.openBookingData.BookingSize;
       slot.totalAmount = (slot.RatePlanVariants.TotalBaseRate + slot.RatePlanVariants.TotalVariants + slot.RatePlanVariants.TotalNegotiation );
       if(slot && slot.AddonAttemptDetails && slot.AddonAttemptDetails.length){
        slot.totalAddOnAmount =  _.sumBy(slot.AddonAttemptDetails,'perItemValue');
       }  
       if(slot){
        slot.totalAmount =  (slot.coverTypeVariants && slot.coverTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['covers'] * o['coverTypeValue'] })  : slot.bookingTypeVariants && slot.bookingTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['bookingTypes'] * o['bookingTypeValue'] }) : (slot.totalBaseRate || 0))  ;    
      }
       return slot;
     });
  }
  applyTaxForNegotiated(value){

    if (value.hasOwnProperty(this.recalculateConfig[0].name)) {
      this.applyNegotiationBtn.disbaledproperity = false;
      this.dashboardData.negotiateServiceCharge = value[this.recalculateConfig[0].name]; 
    }
    else{
      this.totalServiceCharge = _.cloneDeep(this.ratePlan?.TotalServiceChargeAmount ?  this.ratePlan?.TotalServiceChargeAmount : 0);
    }
  }


  applyNegotiation(){
    this.applyNegotiationClicked = true;
    this.applyNegotiationBtn.disbaledproperity = true;
    this.firstCall = false;
    this.setRateplanCalculationObject();
  }

  setRateplanCalculationObject(resetData?){
    let { Slots, CoverTypes, BookingTypes, Location, BookingSize } = this.partyService.openBookingData;
    this.setRatePlanCalculation(Slots, CoverTypes, BookingTypes, Location, BookingSize,null,resetData);
  }

  cancelNegotiation(){
    this.totalServiceCharge = _.cloneDeep(this.ratePlan.TotalServiceChargeAmount);
    this.dashboardData.negotiateServiceCharge = false; 
    this.dashboardData.enabledNegotiation = false;    
    this.negotiationReason = '';
    this.negotiateReasonForm.form.reset();
    if(this.data && this.data.bookingRatePlan?.Comments){
      this.negotiateReasonForm.form.controls?.reasonText?.setValue(this.setNegotiationReason());
    }
    else{
      this.negotiateReasonForm.form.controls?.reasonText?.setValue('');
    }
    //this.perItemEditable = false;
    // this.editNegotiationAmount();
    this.setRateplanCalculationObject(true);
   // this.negotiationConfigForm.form.controls.negotiation.setValue(false)
  }

  setNegotiationReason(){
    let ratePlanNegotiateReason = '';
    if(this.data && this.data.bookingRatePlan?.Comments){
      try{
        let commentsObj = JSON.parse(this.data.bookingRatePlan.Comments);
        if(commentsObj){
          ratePlanNegotiateReason = commentsObj.find(comment => comment.commentType == CommentType.Negotiation)?.Comment || '';
        }
      }catch(error){
        ratePlanNegotiateReason = this.data.bookingRatePlan.Comments || '';
      }
    }
    return ratePlanNegotiateReason;
  }
  


  openRatenegotiationpopup() {
    const componentDetails: ComponentDetails = {
      componentName: RateNegotiationComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: '',
      popupTitle: 'Reason for Negotiation',
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '260px',
      data: {
        title: 'Reason for Negotiation',
        update: 'Proceed',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.RateNegotitaion,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    let reasonforNegotiationpopupconfirmaction = this.popupService.confirmedAction$.subscribe(val => {


      if (val === ComponentTypes.RateNegotitaion) {
        this.totalPayableAmount = Number((this.partyService.rateNegotiationObject.Payable / 1).toFixed(2));

        if (this.ratePlan && this.partyService.isfrombuynow && (this.partyService.openBookingDataSaveObj.TotalPayable != this.totalPayableAmount)) {
          this.partyService.isfromBuyNowAmountNegotiated = true;
        }

        this.remainingAmount = Number((this.partyService.rateNegotiationObject.Payable - this.ratePlan.TotalRatePlan).toFixed(2));
        this.negotiationReason = this.partyService.rateNegotiationObject.Reason;
        this.partyService.openBookingDataSaveObj.NegotiatedAmount = this.remainingAmount;
        this.partyService.openBookingDataSaveObj.Comments = this.partyService.rateNegotiationObject.Reason;
        this.partyService.openBookingDataSaveObj.TotalPayable = this.totalPayableAmount;

        this.calculateNegotiableRate();
        if (this.partyService.isfrombuynow && this.partyService.isfromBuyNowAmountNegotiated) {
          this.partyService.proceedCard(this.partyService.isfromBuyNowCartId);
        }
      }
    });

    dialogRef.afterClosed().subscribe(val => {
      if (reasonforNegotiationpopupconfirmaction) {
        reasonforNegotiationpopupconfirmaction.unsubscribe();
      }

    });
  }

  calculateNegotiableRate() {
    var i = 0;
    var total = 0;
    var negotiationAmountOfSlots = 0;
    var taxableItems = [];
    if (this.partyService.openBookingDataSaveObj.Slots && this.partyService.openBookingDataSaveObj.Slots.length) {
      this.partyService.openBookingDataSaveObj.Slots.map(slot => {
        if (slot.NegotiatedAmount) {
          slot.NegotiatedAmount = 0;
        }
      });
    }

    this.partyService.openBookingDataSaveObj.Slots.forEach(slot => {
      slot.index = i;
      var totalRatePlanAmountForSlot = this.ratePlan.SlotList.find(slt => slt.TimeRange?.Start === slot.Time);
      if (totalRatePlanAmountForSlot && totalRatePlanAmountForSlot.TotalAmount) {
        totalRatePlanAmountForSlot.index = slot.index;
        if (i < this.partyService.openBookingDataSaveObj.Slots.length - 1) {

          slot.NegotiatedAmount = ((this.totalPayableAmount != 0) ? ((this.totalPayableAmount) / this.ratePlan.TotalRatePlan) * totalRatePlanAmountForSlot.TotalAmount : 0);
          total += Number(slot.NegotiatedAmount);
          slot.NegotiatedAmount -= totalRatePlanAmountForSlot.TotalAmount;
          slot.NegotiatedAmount = Number(slot.NegotiatedAmount.toFixed(2));
        }
        else {
          this.partyService.openBookingDataSaveObj.Slots.forEach(s => {
            if (s.NegotiatedAmount)
              negotiationAmountOfSlots += s.NegotiatedAmount;
          })
          var totalNegotiatedAmount = Number((this.ratePlan.TotalRatePlan - this.totalPayableAmount).toFixed(2));
          slot.NegotiatedAmount = -(Number(totalNegotiatedAmount + negotiationAmountOfSlots));
        }
        slot.NegotiatedAmount = Number(slot?.NegotiatedAmount);
        var taxItem = new TaxableItem();
        taxItem.ActivityId = (this.partyService.openBookingDataSaveObj?.SpecialMealId || null);
        taxItem.Index = slot?.index;
        taxItem.StartDate = this.partyService.openBookingDataSaveObj?.StartDate;
        taxItem.EndDate = this.partyService.openBookingDataSaveObj?.EndDate;
        taxItem.RatePlanAmount = totalRatePlanAmountForSlot?.TotalAmount + (slot?.NegotiatedAmount || 0);
        taxableItems.push(taxItem)
      }
      i++;
    });
    this.calculateTax(taxableItems);
  }

  calculateTax(taxableItems) {
    this.api.GetTaxCalculated(taxableItems).subscribe(data => {
      let newTax = 0;let serviceCharge = 0;let serviceChargeWithTax =0;
      if (data && data.Payload) {
        var taxresponse = data.Payload as TaxableItem[];
        this.ratePlan.SlotList.forEach(slot => {
          slot.TaxAmount = (taxresponse.find(t => t.Index == slot.index)?.TaxAmount || 0);
          slot.ServiceChargeAmount = (taxresponse.find(t => t.Index == slot.index)?.ServiceChargeAmount || 0);
          newTax = newTax + slot.TaxAmount;
          serviceCharge = serviceCharge + slot.ServiceChargeAmount;
          serviceChargeWithTax = serviceChargeWithTax + slot.serviceChargeWithTax;
        });
        this
        this.ratePlan.TotalTaxAmount = this.taxAmount = (newTax);
        this.ratePlan.TotalServiceChargeAmount = this.totalServiceCharge = (serviceCharge);
        this.ratePlan.TotalTaxOnServiceChargeAmount = this.totalServiceChargeWithTax = (serviceChargeWithTax);
      }

    })

  }


  getAttemptSlotObj(timeslots) {
    return timeslots.map((slot) => {
      return {
        LocalTime: slot.LocalTime,
        Duration: slot.Duration,
        ReservationAttemptId: null
      }
    })
  }

  ShowRestPolicyDetailsClick() {
    this.ShowRestPolicyDetails(this.restaurantPolicy);
  }
  bindGuestPartyNotes() {
    this.config = [];
    this.partyNotes.forEach((partyNote, index) => {
      const tag = {
        type: 'chip',
        name: `preferredPartyTags${index}`,
        inputType: 'text',
        label: partyNote.CategoryName,
        class: 'guest-view__preferred-tags-edit', // Do not modify the className, there is a dependency for editGuest details.
        options: this.getOptions(partyNote.partyNotes, partyNote.Color),
        multipleChip: true
      }
      this.config.push(tag);
    })
    if (_.get(this.data, ['Notes'], []).length) {
      this.customPartyNotes = this.data.Notes.filter(note => !note.RelatedId && note.Type == PartyNoteType.FreeFormNote);
      if (this.customPartyNotes && this.customPartyNotes.length) {
        const tag = {
          type: 'chip',
          name: `partyfreeTags`,
          inputType: 'text',
          label: 'freeNoteText',
          class: 'guest-view__free-tags-edit',
          options: this.getFreeTextOptions(),
          multipleChip: true
        }
        this.config.unshift(tag);
      }
    }

  }

  getFreeTextOptions() {
    let freeTextOptions = [];
    let freeNotes = this.data.Notes.filter(notes => !notes.RelatedId);
    freeNotes.forEach((notes) => {
      freeTextOptions.push({
        Id: notes.Id,
        Name: notes.Text,
        Icon: 'None',
        Value: ''
      })
    })
    return freeTextOptions;
  }

  getOptions(partyNotes, categoryColor): any {
    let configOptios = [];
    partyNotes.forEach((notes) => {
      configOptios.push({
        Id: notes.Id,
        Name: notes.Text,
        Icon: notes.Icon,
        Value: '',
        color: categoryColor
      });
    })
    return configOptios;
  }

  formChipsChange() {
    this.subscriptions.add(this.formChipService.formChipChange$.subscribe((selectedChips) => {
      if (selectedChips.name === 'partyfreeTags') {
        selectedChips.data.forEach((tags) => {
          if (!tags.setSelected) {
            const index = this.partyService.partyNotes.findIndex((note) => note.Id === tags.Id);
            if (index > -1) {
              this.partyService.partyNotes = this.partyService.partyNotes.filter(note => note.Id != tags.Id);
            }
          } else {
            const index = this.partyService.partyNotes.findIndex((note) => note.Id === tags.Id);
            if (index == -1) {
              const freeTextNotes: PartyNoteDTO = {} as PartyNoteDTO;
              freeTextNotes.Id = tags.Id;
              freeTextNotes.Text = tags.Name;
              freeTextNotes.Type = PartyNoteType.FreeFormNote;
              this.partyService.partyNotes.push(freeTextNotes);
            }
          }
        });
      } else if (this.config.filter((fieldConfig) => fieldConfig.name == selectedChips.name).length > 0) {
        const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
        this.selectedPartyPreferenceTags = selectedChips.data;
        const preferredTagsIndex = this.config.findIndex(fieldConfig => fieldConfig.name == selectedChips.name);
        const otherTags = selectedChips.data.filter(chip => chip.Name == 'Others');
        if (otherTags && otherTags.length > 0) {
          let otherNotesName = `otherNotes${otherTags[0].Id}`;
          let isOtherNoteAvailable = this.config.filter((config) => config.name == otherNotesName).length;
          const otherNotesControl = this.components.last.config.filter((config) => config.name == otherNotesName);
          if (!isOtherNoteAvailable && otherTags.length > 0 && otherTags[0].setSelected) {
            const otherNotes: FieldConfig = {
              name: otherNotesName,
              type: 'textarea',
              inputType: 'text',
              label: 'notes',
              showHint: true,
              class: 'guest-data__party-other-tags',
              charLength: 1000,
              isHidden: false
            }
            this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
          } else if (isOtherNoteAvailable) {
            if (otherTags[0].setSelected) {
              otherNotesControl[0].isHidden = false;
              const otherNotes = this.partyService.partyNotes.find((note) => note.RelatedId == otherTags[0].Id);
              if (!otherNotes) {
                const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
                let editPartyNote = null;
                if (this.data && this.data.Notes?.length) {
                  editPartyNote = this.data.Notes.find(note => note.RelatedId == otherTags[0].Id);
                  if (editPartyNote) {
                    this.partyService.partyNotes.push(editPartyNote);
                  }
                } else {
                  partyNotes.Id = null;
                  partyNotes.Type = PartyNoteType.FreeFormNote;
                  partyNotes.RelatedId = otherTags[0].Id;
                  partyNotes.Text = this.components.last.form.value[otherNotesName];
                  this.partyService.partyNotes.push(partyNotes);
                }
              }
              // this.partyService.partyNotes = this.partyService.partyNotes.filter((note) => note.RelatedId != otherTags[0].Id)
            } else {
              otherNotesControl[0].isHidden = true;
              this.partyService.partyNotes = this.partyService.partyNotes.filter((note) => note.RelatedId != otherTags[0].Id)
            }
            // this.config.splice(preferredTagsIndex + 1, 1);

          }
        }
        this.config = [...this.config];
        this.selectedPartyPreferenceTags.forEach((_partyNotes) => {
          if (_partyNotes.Name !== 'Others') {
            const preferredData = this.partyService.partyNotes.filter((note) => note.RelatedId === _partyNotes.Id)[0];
            if (_partyNotes.setSelected && !preferredData) {
              partyNotes.RelatedId = _partyNotes.Id;
              partyNotes.Text = _partyNotes.Name;
              partyNotes.Id = _partyNotes.noteId ? _partyNotes.noteId : null;
              partyNotes.Type = PartyNoteType.PredefinedNote;
              this.partyService.partyNotes.push(partyNotes);
            } else if (!_partyNotes.setSelected) {
              const index = this.partyService.partyNotes.findIndex((note) => note.RelatedId === _partyNotes.Id);
              if (index > -1) {
                this.partyService.partyNotes.splice(index, 1);
              }
            }
          }
        });
      }
      this.partyService.reservationFormGroup.markAsDirty();
      this.enableGuestFormComponent();
    }));

  }

  bindInternalPartyNotes(data: any) {
    for (let preferenceProperty in data) {
      if (preferenceProperty.includes('otherNotes')) {
        const otherNotesInConfig = this.config.find(configProp => configProp.name == preferenceProperty);
        if (otherNotesInConfig && !otherNotesInConfig.isHidden) {
          const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
          partyNotes.Text = data[preferenceProperty];
          const preferencePropertyId = preferenceProperty.match(/(\d+)/);
          if (preferencePropertyId) {
            partyNotes.RelatedId = Number(preferencePropertyId[0]);
            const preferredOtherTags = this.partyService.partyNotes.filter((note) => note.RelatedId === partyNotes.RelatedId);
            if (preferredOtherTags && preferredOtherTags.length > 0) {
              partyNotes.Id = preferredOtherTags[0].Id ? preferredOtherTags[0].Id : null;
              partyNotes.Type = PartyNoteType.FreeFormNote;
            } else {
              partyNotes.Id = null;
              partyNotes.Type = PartyNoteType.FreeFormNote;
            }
          }
          this.partyService.partyNotes = this.partyService.partyNotes.filter(note => note.RelatedId != partyNotes.RelatedId);
          this.partyService.partyNotes.push({ ...partyNotes });
        }
      }
    }
    //this.changeFormState(true)
    // if (!this.holdFormValidation) {
    //   this.partyService.reservationFormGroup.markAsDirty();
    //   this.partyService.reservationFormGroup.updateValueAndValidity();
    // }
    // this.updateFormValidity();
  }

  mapPartyNotes() {
    const preferredTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('preferredPartyTags'));
    this.data.Notes.forEach((note) => {
      preferredTags.forEach((fieldConfig) => {
        if (note.Type == PartyNoteType.FreeFormNote || note.Type == PartyNoteType.PredefinedNote || note.Type == PartyNoteType.SpecialRequest) {
          const filteredNotes = fieldConfig.options.find(data => data.Id === note.RelatedId);
          if (filteredNotes) {
            filteredNotes.setSelected = true;
            filteredNotes.noteId = note.Id;
            if (filteredNotes.Name.includes('Others')) {
              let otherNotesName = `otherNotes${filteredNotes.Id}`;
              if (filteredNotes.setSelected) {
                const otherNotes: FieldConfig = {
                  name: otherNotesName,
                  type: 'textarea',
                  inputType: 'text',
                  label: 'notes',
                  showHint: true,
                  class: 'guest-data__party-other-tags',
                  charLength: 1000,
                  value: note.Text
                };
                const preferredTagsIndex = this.config.findIndex(preferenceConfig => preferenceConfig.name == fieldConfig.name);
                this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
              }
            }
            this.partyService.partyNotes.push(note);
          }
        }
      })
    });
    this.config = [...this.config];
  }

  mapCustomPartyNotes() {
    const freeTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('partyfreeTags'));
    this.data.Notes.forEach(note => {
      freeTags.forEach(fieldConfig => {
        const filteredNotes = fieldConfig.options.find(data => data.Id === note.Id);
        if (filteredNotes) {
          filteredNotes.setSelected = true;
          this.partyService.partyNotes.push(note);
        }
      })
    });
  }

  validateCharacters(evt){
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57)
    {
        evt.preventDefault();
    }
  }

  calculateValue(slot){
    this.applyNegotiationBtn.disbaledproperity = false;
      this.calculateAmount(slot);
  }

  calculateAmount(slot){
    // if(slot && slot.AddonAttemptDetails && slot.AddonAttemptDetails.length){
    //   slot.totalAddOnAmount =  _.sumBy(slot.AddonAttemptDetails,item => Number(item['perItemValue']));
    //  }  
    //  if(slot){
    //   slot.totalAmount =  (slot.coverTypeVariants && slot.coverTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['covers'] * o['coverTypeValue'] })  : slot.bookingTypeVariants && slot.bookingTypeVariants.length ? _.sumBy(slot.coverTypeVariants, function(o) { return o['bookingTypes'] * o['bookingTypeValue'] }) : (slot.totalBaseRate || 0))  ;
  
    // }
    // this.totalAmountForBooking = (_.sumBy(this.ratePlan.SlotList, 'totalAddOnAmount') +_.sumBy(this.ratePlan.SlotList, 'totalAmount'));
  }
  onBlur(eve){
    eve.target.value = Number(eve.target.value).toFixed(2);
    }
    setAdjustedMinRate(){
      this.partyService.openBookingData.MinRateAdjustment = this.adjustedMinRate;
      if(this.partyService.openBookingDataSaveObj){
        this.partyService.openBookingDataSaveObj.MinRateNegotiatedAdjustment = this.ratePlan.MinRateAdjustment.Amount - this.ratePlan.MinRateAdjustment.MinRateAdjustmentAmount;
      }
    }


}
