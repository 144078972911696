import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from '@app/app.service';
import { controlSettings } from '@app/shared/constants/globalConstants';
import { PartyType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { CoverReport, CoverReportDetails, CoverReportDTO, CoversandType, DashboardLegends } from '@models/CoverReportDTO';
import { DateShiftDTO, SlotDTO } from '@models/InputReservation';
import { TimeRangeDTO } from '@models/TimeRangeDTO';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@services/settings.service';
import { Utilities } from '@utilities/utilities';
import { cloneDeep } from 'lodash';
import _remove from 'lodash/remove';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-coverreports-component',
  templateUrl: './coverreports-component.html',
  styleUrls: ['./coverreports-component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CoverReportsComponent implements OnInit, OnDestroy {
  formattedDate: any = new Date();
  shiftConfig: FieldConfig[];
  sortingData: CoverReportDetails[];
  shifts: DateShiftDTO[];
  allSlots: any[] = [];
  coverReportDataSource = new MatTableDataSource<CoverReport>();
  Columns: string[] = ['Time', 'Covers', 'Count'];
  CoverReport: CoverReport[] = [];
  expandedElement: CoverReport | null;
  headerColumns: string[] = ['ShiftHeader'];
  // shiftsGroup: GroupDate[] = [];
  finalList: (CoverReport)[] = [];
  coverReportData: CoverReportDTO;
  reservationCount: number;
  walkInCount: number;
  totalCount: number;
  reservationCovers: number;
  walkInCovers: number;
  totalCovers: number;
  maxCover: number = 0;
  generalDateConfig: FieldConfig[];
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  selectedDate: Date;
  dashboardLegendsList: DashboardLegends[] = [];
  selectedShiftId: number;
  subscriptions: Subscription = new Subscription();
  hide_walkins: boolean = false;

  constructor(public dialog: MatDialog, private ss: SettingsService, private datePipe: DatePipe,
    private cs: CacheService, private ts: TranslateService, public _as: AppService) {
    this.selectedDate = this._as.headerSelectedDate;
      this.cs.settings.subscribe((settings) => {
      if (this.generalDateConfig && this.generalDateConfig.length) {
        const generalDateConfig = cloneDeep(this.generalDateConfig);
        this.generalDateConfig = [];
        this.generalDateConfig = [...generalDateConfig];
      }
    })
  }

  ngOnInit(): void {
    this.loadConfig();
    this.loadCoverReport();
    this.hide_walkins = Utilities.controlValidate(controlSettings.CoverReport_WalkIn_Hide , this._as.PropertyType);
  }
  loadConfig() {
    this.shiftConfig = [
      {
        type: 'select',
        label: 'Select Shift',
        name: 'shift',
        placeholder: 'Shift',
        options: [],
        class: 'reservation-timeslot__shift-selection',
        showErrorText: true,
        appearance: true,
        value: -1,
        cellClick: this.setSelectedShift.bind(this)
      }];
    this.generalDateConfig = [
      {
        type: 'date',
        name: 'date',
        inputType: 'text',
        class: 'report-datepicker',
        value: this.selectedDate,
        appearance: false
      }];
  }
  loadCoverReport() {
    this.setShift();
    this.getCoverReport();
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe(value => {
      if (this.selectedDate !== value.date) {
        this.selectedShiftId = 0;
      }
      this.selectedDate = value.date;
      this.loadConfig();
      this.loadCoverReport();
    }));
  }
  setShift() {
    this.shifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, this.selectedDate);
    this.pushShifts();
  }
  pushShifts() {
    if (this.shiftConfig) {
      this.shiftConfig.filter(_config => _config.name == 'shift')[0].options = [];
      let val = [];
      if (this.shifts && this.shifts.length > 0) {
        this.shiftConfig.filter(_config => _config.name == 'shift')[0].options.push({ id: -1, value: this.ts.instant('allShiftsText') });
        val.push({ id: -1, value: this.ts.instant('allShiftsText') });
      }
      else {
        _remove(this.shiftConfig, x => x.name === 'shift');
        return;
      }
      const mappedOptions = this.shifts.map(x => ({ id: x.Id, value: x.Name }));
      mappedOptions.forEach(x => {
        this.shiftConfig.filter(_config => _config.name == 'shift')[0].options.push(x);
        val.push(x);
      });
      this.shiftConfig.filter(_config => _config.name == 'shift')[0].value = val[0].id;

    }
  }
  setSelectedShift(eve) {
    let shifts = (eve.value != -1) ? this.shifts.filter(shift => shift.Id == eve.value) : null;
    if (shifts) {
      this.selectedShiftId = shifts[0].Id;
      this.formattedDate = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd');
      this.subscriptions.add(this.ss.GetCoverReport(this.formattedDate, this.selectedShiftId).subscribe(data => {
        this.coverReportData = data.Payload as CoverReportDTO;
        this.LoadDataSource(this.selectedShiftId);
        this.dashboardCountDetails();
      }));
    }
    else {
      this.selectedShiftId = 0;
      this.getCoverReport();
    }

  }
  getCoverReport() {
    this.formattedDate = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd');
    this.subscriptions.add(this.ss.GetCoverReport(this.formattedDate).subscribe(data => {
      this.coverReportData = data.Payload as CoverReportDTO;
      if (this.coverReportData.TimeRanges.length > 0) {
        this.generateSlots(this.coverReportData.TimeRanges[0].Start.toString(), this.coverReportData.TimeRanges[0].End.toString());
        if (this.selectedShiftId > 0)
          this.LoadDataSource(this.selectedShiftId);
        else
          this.LoadDataSource();
      }
      else {
        this.CoverReport = [];
        this.coverReportDataSource = new MatTableDataSource<CoverReport>();
      }
      this.dashboardCountDetails();
    }));
  }
  dashboardCountDetails() {
    let coverCount: number = 0;
    this.reservationCount = 0; this.reservationCovers = 0; this.walkInCount = 0; this.walkInCovers = 0; this.totalCount = 0; this.totalCovers = 0;
    this.sortingData.forEach(data => {
      data.Covers.forEach(cover => {
        if (cover.Type == PartyType.Reservation) {
          this.reservationCount = this.reservationCount + 1;
          this.reservationCovers = this.reservationCovers + cover.CoverSize;
        }
        else if (cover.Type == PartyType.WalkIn) {
          this.walkInCount = this.walkInCount + 1;
          this.walkInCovers = this.walkInCovers + cover.CoverSize;
        }
      })
    });
    this.totalCount = this.reservationCount + this.walkInCount;
    this.totalCovers = this.reservationCovers + this.walkInCovers;
  }

  generateSlots(start: string, end: string) {
    this.CoverReport = [];
    let isSlotAdded = false;
    const Slotmode = this.cs.settings.value.General.SlottingMode;
    const Slotval = this.cs.settings.value.General.TimeSlotUnitInMinutes;
    const currentTime = this.selectedDate;
    const timerange: TimeRangeDTO = {} as TimeRangeDTO;
    //const date = currentTime.getDay(); //commented for #31868 - this const value is never used
    const Startval = start.split('Z')[0];
    const Endval = new Date(end.split('Z')[0]);
    let Startloop = new Date(Startval);
    while (Startloop <= Endval) {
      const tempTimeSlot = Startloop;
      const slot: SlotDTO = <SlotDTO>{};
      const slotTime = Utilities.Date(Startloop);
      slot.DateTime = slotTime.format('YYYY-MM-DDTHH:mm:ss');
      this.allSlots.push(slot)
      isSlotAdded = true
      const newMinites = new Date(tempTimeSlot).getMinutes() + Slotval;
      const newDate = tempTimeSlot.setMinutes(newMinites);
      Startloop = new Date(newDate);
    }
    this.CoverReport.push({
      SlotTime: this.allSlots,
    })
    this.allSlots = [];

  }
  LoadDataSource(shiftId?) {
    const slots: CoverReport[] = [];
    const shiftsGroupInternal: DateShiftDTO[] = [];
    let covers: CoversandType[] = [];
    this.sortingData = [];
    let coverCount: number = 0;
    let coversOnly: number[] = [];
    if (shiftId) {
      const filteredShift = this.shifts.filter(shift => shift.Id == shiftId);
      if (filteredShift && filteredShift.length > 0) {
        this.generateSlots(filteredShift[0].EffectiveRange.Start.toString(), filteredShift[0].EffectiveRange.End.toString())
      }
    }

    this.CoverReport.forEach((report: CoverReport) => {
      report.SlotTime.forEach(slot => {
        if (this.coverReportData.Parties.length > 0) {
          let coverdata = this.coverReportData.Parties.filter(data => this.getTime(data.Time) == this.getTime(slot.DateTime));
          coverdata.forEach(cover => {
            covers.push({ CoverSize: cover.Size, Type: cover.Type });
            coverCount = coverCount + cover.Size;
            coversOnly.push(coverCount);
          });
        }
        slots.push({ Slot: slot, Covers: covers, Count: coverCount });
        this.sortingData.push({ Slot: slot, Covers: covers, Count: coverCount });
        covers = [];
        coverCount = 0;
      })

    });
    if (coversOnly.length > 0)
      this.maxCover = coversOnly.reduce((a, b) => Math.max(a, b));
    this.coverReportDataSource = new MatTableDataSource<CoverReport>(slots);
  }
  getTime(date): number {
    return new Date(date).getMinutes() + new Date(date).getHours() * 60
  }

  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

  onSortData(sort: Sort) {
    this.finalList = [];
    // this.shiftsGroup.sort((a, b) => a.Shift.toLocaleString().localeCompare(b.Shift.toLocaleString()));
    let data = this.sortingData;
    if (sort.active && sort.direction !== '') {
      data = data.sort((p1: CoverReportDetails, p2: CoverReportDetails) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case this.ts.instant('timeText'):
            return this.compare(p1.Slot.DateTime, p2.Slot.DateTime, isAsc);
          case this.ts.instant('countText'):
            return this.compare(p1.Count, p2.Count, isAsc);
          default: return 0;
        }
      });
      this.finalList.push(...data);
    }
    this.coverReportDataSource = new MatTableDataSource(this.finalList);
  }
  // isGroup(index, item): boolean {
  //   return item.Shift;
  // }
  private compare(data1, data2, isAsc) {
    return (data1 < data2 ? -1 : 1) * (isAsc ? 1 : -1);
  }
}

@Pipe({
  name: 'getCoverColor'
})
export class GetCovorColorPipe implements PipeTransform {
  transform(cover: number, max: number): any {
    let percent = (cover / max) * 100;
    if (percent < 50) { return 'yellowgreen'; }
    if (percent >= 50 && percent <= 80) { return 'orange'; }
    if (percent >= 80) { return 'red'; }
  }
}
