import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  JWT_EXPIRE, JWT_TOKEN, USER_INFO, USER_SESSION, PROPERTY_INFO, PROPERTY_DATE, PROPERTY_CONFIGURATION_SETTINGS,
  FULL_STORY_ORG_ID
} from '@app/app-constants';
import { AppService } from '@app/common/app-service';
import { TokenLoginModel } from '@app/common/external-request/pms-request.model';
import { FeatureName, UserSessionConfiguration } from '@app/common/shared/retail.modals';
import { CommonPropertyInformation } from '@app/common/shared/services/common-property-information.service';
import { SessionManagerService } from '@app/common/shared/services/session-manager.service';
import { MachineName, UserMachineInfo } from '@app/common/shared/shared.modal';
import { BaseResponse } from '@app/common/shared/shared/business/shared.modals';
import { HttpMethod, HttpServiceCall } from '@app/common/shared/shared/service/http-call.service';
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
import { RetailStandaloneLocalization } from '@app/core/localization/retailStandalone-localization';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { PopupService } from '@app/popup-module/popup.service';
import { SPAConfig } from '@app/retail/common/config/SPA-config';
import { RetailLocalization } from '@app/retail/common/localization/retail-localization';
import { UserMachineConfigurationService } from '@app/retail/common/services/user-machine-configuration.service';
import { RetailSharedVariableService } from '@app/retail/shared/retail.shared.variable.service';
import { PayAgentService } from '@app/retail/shared/service/payagent.service';
import { ApmService } from '@elastic/apm-rum-angular';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { ElasticApm, Host, Product, SELECTION_ON_LOGIN, TRANSACTION_BY_MACHINENAME } from '../../common/shared/shared/globalsContant';
import { ConfirmationPopupComponent } from '../components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes, LoginOperation } from '../constants/commonenums';
import { PropertySettingDataService as RetailPropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import { PropertySettingDataService } from '@app/shared/data-services/authentication/propertysetting.data.service';
import { PropertyInformation } from '@app/core/services/property-information.service';
import { API } from '../models/property-settings.model';
import { TranslateFn } from '@angular/localize/init';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { RetailRoutes } from '@app/core/extensions/retail-route';
import { LoginCommunicationService } from '@app/core/login-communication.service';
import { CryptoUtility } from '@app/core/utilities/crypto.utility';
import { PropertyFeaturesConfigurationService } from '@app/retail/sytem-config/payment-features-config/property-feature-config.service';
import { ConfigKeys, RetailFeatureFlagInformationService } from '@app/retail/shared/service/retail.feature.flag.information.service';
import { RetailPropertyInformation } from '@app/retail/common/services/retail-property-information.service';
import { takeUntil } from 'rxjs/operators';
import { DefaultBucketName } from '@app/retail/shared/globalsContant';
import { Utilities } from '../utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class RetailLoginService {
  userSessionId: string = "userSession";
  userInfo: any;
  propertyValues: any[];
  multipleLocations: any = [{
    id: 1, name: "Alpha Spa"
  }];
  userName: string;
  password: string;
  customerId: number;
  tenantCode: string = "";
  propertyKey: string = "propertyInfo";
  captions: any;
  loginSuccess: boolean = true;
  propertyInformation: any;
  tenantId: any = 1;
  propertyDateKey: string = "propertyDate";
  userKey: string = "_userInfo";
  apmElasticService: any;
  siteId: number;
  userMachineInfo: UserMachineInfo;
  propertyMachineCollection: MachineName[] = [];
  selectedMachines$ = new Subject<MachineName[]>();
  singleMachine$ = new BehaviorSubject<MachineName[]>(null);
  login$ = new Subject<number>();
  defaultMachine: number;
  userProperties: any = [];
  multipleProperties: any = [
    {
      id: 1,
      name: 'Agilysys'
    }
  ];
  custId: any;
  userIdDir = 'capitalise,notallowspace,nospecailchar';
  showCustomerID = false;
  //Machine Name
  isMachineNameEnabled: boolean;
  isPromptOnLoginEnabled: boolean;
  defaultMachineId: number = 0;
  machineNames = [];
  isSwitchProperty = false;
  isSilientLogin = false;
  key: string;
  iv: string;
  
  // Encrypt format for retail EncLogin details 
  uTempData : string = 'UUVkNUpFUmxYaVJBUjNra1JHVmVKQT09IFJVRXdPVEExUlVJM05VSTNSRGxDT1E9PSBmQT09IE1UVkJOalF5T1RORU5qTTRNVGxDT1E9PSBKRjVsUkNSNVIwQWtYbVZFSkhsSFFBPT0='


  constructor(public localization: RetailLocalization, public spaconfig: SPAConfig, public sessionManagerService: SessionManagerService,
    private route: ActivatedRoute, private utils: CommonUtilities, public http: HttpServiceCall,
    private userSessionConfig: UserMachineConfigurationService,
    private retailSharedService: RetailSharedVariableService,
    private propertyInfo: PropertyInformation,
    private localize: RetailStandaloneLocalization,
    private payAgentService: PayAgentService,
    private PropertySettingService: PropertySettingDataService,
    private _featureFlagService: RetailFeatureFlagInformationService
    , private _propertyFeatureService: PropertyFeaturesConfigurationService
    , private _propertyInfo: RetailPropertyInformation,
    private ts: TranslateService, private loginService: LoginCommunicationService,
    private retailPropertySettingDataService: RetailPropertySettingDataService,
    @Inject(ApmService) apmService: ApmService,
    protected dialog: MatDialog,
    private crypto: CryptoUtility) {
    this.apmElasticService = apmService;
  }

  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): Promise<void> {
    if (callDesc == "Login") {
      this.login$.next(LoginOperation.Inprogress);
      const data = <any>result.result;
      if (data.loginDuration) {
        sessionStorage.setItem('loginDuration', data.loginDuration);
        this.successCallBack(data);
      }
      await this.setUserData(data);
    } else if (callDesc == "CreateSession") {
      var response = <any>result.result;
      sessionStorage.setItem(this.userSessionId, response);
     
      this.setUserInformation();
      this.retailSharedService.usersession.next(response);
      await this.setpropertyvalues();
      await this.SetUserSessionConfiguration(this.userInfo.userId);
      //   this.setMachineDetails();

      this.OtherRetailInitialization();
    }
    else if (callDesc == "GetMachineNamesConfigurationInfo") {
      this.login$.next(LoginOperation.Completed);
      let datas = <any>result.result;
      let datass = datas as UserMachineInfo;
      this.userMachineInfo = datass;
      let userMachineInfos = datass.userPropertiesMachineInfo.map(res => {
        if (res.machineNames) {
          res.machineNames = _.orderBy(res.machineNames, 'listOrder', 'asc');
          return res;
        }
      });
      
      this.userMachineInfo.userPropertiesMachineInfo = userMachineInfos;
      this.setMachineInfos();
      // Selecting property by default when there is only one property configured for tenant
     
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "LogOut") {
    }
    this.login$.next(LoginOperation.Failed);
    if (callDesc == "Login" && error && error.errorCode) {
      if (!this.isSilientLogin) {
        if (this.isSwitchProperty) {
          let message = this.ts.instant('retailErrorMsg');
          this.OpenInfoPopUp(message, ComponentTypes.login, '300', '300', this.ts.instant('alert'));
          this.isSwitchProperty = false;
        } else {
          let message = "Retail Error -" + this.localization.getError(Number(error.errorCode))
          this.OpenInfoPopUp(message, ComponentTypes.login, '300', '300', this.ts.instant('alert'))
        }

      }
      this.isSilientLogin = false;
    }
  }
  RefreshConfig(isFromPropertyChangeEvent : boolean = false){
    if (!sessionStorage.getItem("giftCardConfiguration") || isFromPropertyChangeEvent) {
      this._propertyFeatureService.GetGiftCardConfiguration().then((config) => {
        this._propertyInfo.SetGiftCardConfiguration(config);
      });
    }
    this.OtherRetailInitialization();
  }


  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  OtherRetailInitialization() {
    if (!sessionStorage.getItem("giftCardConfiguration")) {
      this._propertyFeatureService.GetGiftCardConfiguration().then((config) => {
        this._propertyInfo.SetGiftCardConfiguration(config);
      });
    }
    this._featureFlagService.RefreshConfig();
    this._featureFlagService.propFeature.pipe(takeUntil(this.destroyed$)).subscribe((propertyFeatures) => {
      if (!sessionStorage.getItem('EIURI')) {
        const eatecFeature  = propertyFeatures && propertyFeatures.find(x => x.featureName === FeatureName.EnhancedInventory);
        if (eatecFeature != null && eatecFeature.isActive) {
          sessionStorage.setItem('isEatecEnabled', 'true');
          this._propertyFeatureService.getFeatureConfiguration(eatecFeature.id, eatecFeature.moduleId).then((featureconfigurations) => {
            if (featureconfigurations != null && featureconfigurations.length > 0) {
              //this.isEatecEnabled = true;
              const eatecUser = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EatecTenantUser);
              const uri = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EatecURI);
              if (eatecUser && eatecUser.configurationValue && uri && uri.configurationValue) {
                this._propertyInfo.SetEatecRI( uri.configurationValue);
              }else{
                this._propertyInfo.SetEatecRI('');
              }
            }else{
              this._propertyInfo.SetEatecRI('');
            }
          });
        } else {
          //this.isEatecEnabled = false;
          sessionStorage.setItem('isEatecEnabled', 'false');
          this._propertyInfo.SetEatecRI('');
        }
      }
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    });
  
    if (!sessionStorage.getItem("memberConfiguration")) {
      var configValue = "";
      let featureNames = ["ACES Membership"];
      this._propertyFeatureService.GetFeatureConfigurations(featureNames).then((featureconfigurations) => {
      if (featureconfigurations != null) {
        let uri = featureconfigurations.find(f => f.configurationKey == ConfigKeys.Member.LoyaltyURI);
        let loyaltyServiceURI = featureconfigurations.find(f => f.configurationKey == ConfigKeys.Member.LoyaltyServiceURI);
        let loyaltyTokenRequestInfo = featureconfigurations.find(f => f.configurationKey == ConfigKeys.Member.LoyaltyTokenRequestInfo);
        let enforceMemberPayment = featureconfigurations.find(f => f.configurationKey == ConfigKeys.Member.EnforceMemberPayment);
        let displayCreditBookBalance = featureconfigurations.find(f => f.configurationKey == ConfigKeys.Member.DisplayCreditBookBalance);
        const allowTenderAmountOverrideForMember = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.AllowTenderAmountOverrideForMember);
        const memberBucketName = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.MemberBucketName);
        const creditBookBucketName = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.CreditBookBucketName);
        const requirePin = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.RequirePin);
        const enableMultipleMember = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.EnableMultipleMember)
        let loyalty = {
          enableMultipleMember: (enableMultipleMember?.configurationValue?.trim()) || "false",
          loyaltyURI: uri && uri.configurationValue ? uri.configurationValue : "",
          loyaltyServiceURI: loyaltyServiceURI && loyaltyServiceURI.configurationValue ? loyaltyServiceURI.configurationValue : "",
          loyaltyTokenRequestInfo: loyaltyTokenRequestInfo && loyaltyTokenRequestInfo.configurationValue ? loyaltyTokenRequestInfo.configurationValue : "",
          enforceMemberPayment: (enforceMemberPayment && enforceMemberPayment.configurationValue) || "false",
          displayCreditBookBalance : (displayCreditBookBalance && displayCreditBookBalance.configurationValue) || "false",
          allowTenderAmountOverrideForMember: (allowTenderAmountOverrideForMember?.configurationValue) || "false",
          memberBucketName: (memberBucketName?.configurationValue?.trim()) || DefaultBucketName.memberBucketName,
          creditBookBucketName: (creditBookBucketName?.configurationValue?.trim()) || DefaultBucketName.creditBookBucketName,
          requirePin: (requirePin?.configurationValue?.trim()) || "false",
          showCurrentRoundsOnReprint : featureconfigurations.find(f => f.configurationKey ===
            ConfigKeys.Member.ShowCurrentRoundsOnReprint)?.configurationValue || 'false'
        }
        this._propertyInfo.SetMemberConfiguration(loyalty)
      }
      else {
        this._propertyInfo.SetMemberConfiguration("")
      }
    });
  }
    this._propertyFeatureService.GetMiscConfig();
}


  OpenInfoPopUp(message, componentType, popupHeight, popupWidth, title) {
    let updateText = 'Ok';
    let showAlert = true;


    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: title, showAlert: showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    let dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: popupWidth,
      height: popupHeight,
      data: {
        title,
        update: updateText,
        componentDetails,
        from: componentType,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: true,
      }
    });


  }


  async validateCredentials(credentials) {
    const serviceParams = {
      route: RetailRoutes.Login,
      uriParams: '',
      header: '',
      body: credentials,
      showError: true,
      baseResponse: true
    };

    //   if (!this.loginSuccess) 
    {
      // Validate credentials
      if (this.key && this.iv) {
        serviceParams.body.Password = this.crypto.EncryptString(credentials.Password, this.key, this.iv);
        serviceParams.route = RetailRoutes.LoginEncrypted;
      }
      const loginDetails = await this.loginService.makePostCall(serviceParams);

      if (loginDetails.successStatus) {
        this.userName = credentials.UserName;
        await this.successCallBack(loginDetails);

      } else {
        this.login$.next(LoginOperation.Failed);
        if (loginDetails.errorCode == 5001) {

        } else {
          this.utils.showError(loginDetails.errorDescription);
        }
      }
    }
  }

  setJwtTimer(tokenDuration: number) {
    let currentDateTime = new Date();
    let jwtExpiryTime = new Date(currentDateTime.getTime() + tokenDuration * 1000);
    sessionStorage.setItem(JWT_EXPIRE, jwtExpiryTime.toString());
  }

  async setUserData(data, fromPms = false) {
    this.userInfo = data.userLoginInfo;
    //sessionStorage.setItem(JWT_TOKEN, data.token);
    this.SetUserLanguageToProperties(data)
    if (this.userInfo.isNewUser === true) {
    } else if (this.userInfo.isPasswordExpired === true) {
    } else {
      this.setToken();
      this.propertyValues = data.userProperties;
      this.multipleLocations = this.propertyValues.map(x => { return { id: x.propertyCode, name: x.propertyName, propertyId: x.propertyId } });
      let siteLocation = this.multipleLocations.filter(location => location.propertyId == this.siteId);
      const credentials = {
        UserName: this.userName, Password: this.password,
        TenantId: this.customerId != null ? this.customerId : 0, location: siteLocation.length ? siteLocation[0] : null,
        ProductId: Product.SPA, TenantCode: this.tenantCode
      };
      if (this.propertyValues.length == 1) {
        {
          let resp = this.ValidateCredentials(credentials, fromPms);
        }
      } else {
        let resp = this.ValidateCredentials(credentials, fromPms);
      }
    }
  }

  retailIntilaize() {
    this.captionGenerator();


  }


  async getCustomerId() {
    const serviceParams = {
      route: RetailRoutes.EnvironmentConfig,
      uriParams: '',
      header: '',
      body: '',
      showError: true,
      baseResponse: true
    };
   /* await this.loginService.makeGetCall(serviceParams).then((res: any) => {
      this.custId = res ? res.result : 0;
      if (this.custId == '0') {
        this.userIdDir = 'capitalise,notallowspace';
        this.showCustomerID = true;
        //this.setVal();
        //this.OnFormValueChanges();
      } else {
        this.userIdDir = 'capitalise,notallowspace,nospecailchar';
        this.showCustomerID = false;
        //this.removeVal();
      }
    }); */

    let customerresponse:any=await this.loginService.makeGetCall(serviceParams);
    this.custId = customerresponse ? customerresponse.result : 0;
    if (this.custId == '0') {
      this.userIdDir = 'capitalise,notallowspace';
      this.showCustomerID = true;
      //this.setVal();
      //this.OnFormValueChanges();
    } else {
      this.userIdDir = 'capitalise,notallowspace,nospecailchar';
      this.showCustomerID = false;
      //this.removeVal();
    }
  }

  async setEncryptKey() {
    let serviceParamsForKey = {
      route: RetailRoutes.GetEncryptKey,
      uriParams: '',
      header: '',
      body: '',
      showError: false,
      baseResponse: true
    };
/*    await this.loginService.makeGetCall<any>(serviceParamsForKey, false).then(encryptKey => {
      if (encryptKey && encryptKey.result) {
        this.key = encryptKey.result.key;
        this.iv = encryptKey.result.iv;
      }
    });     */
   //let encryptKey= await this.loginService.makeGetCall<any>(serviceParamsForKey, false);
  //  if (encryptKey && encryptKey.result) {
  //   this.key = encryptKey.result.key;
  //   this.iv = encryptKey.result.iv;
  // }
  this.key = this.GetUTempData(3);
  this.iv  = this.GetUTempData(1);
}


GetUTempData(priority:number) : string {
  let res = atob(atob(this.uTempData)
            .split(' ')[priority]);
            
  return res;
}

  SetUserLanguageToProperties(resultData: any) {
    let userLanguageCode = resultData != null && resultData.userLoginInfo != null && resultData.userLoginInfo.languageCode != "" ? resultData.userLoginInfo.languageCode : 'en-US';
    let PropertyValues = "UserLanguage=" + userLanguageCode;
    sessionStorage.setItem(this.propertyKey, PropertyValues);
    this.localization.SetLocaleBasedProperties();
    this.captions = this.localization.captions.login
    this.spaconfig.load();
  }

  setToken() {
    this.sessionManagerService.setToken();
  }

  async ValidateCredentials(credentials, isFromPMS: boolean = false) {
    let tenantCode = "";
    this.userName = credentials.UserName;
    this.password = credentials.Password;
    this.userName = this.userName;
    if (this.userName.includes('@')) {
      this.userName = this.userName.substring(0, this.userName.indexOf('@'));
      this.tenantCode = this.userName.substring(this.userName.indexOf('@') + 1);
    }
    if (credentials.userid != "" && !isNaN(this.customerId) && credentials.password != "") {
      await this.ServerLoginwithTeant(this.userName, this.password, this.customerId, tenantCode, this);
    } else if (credentials.userid != "" && credentials.password != "" && credentials.location != "" && !this.loginSuccess) {
      if (isFromPMS) {
        const pmsData = this.route.snapshot.data.loginData as TokenLoginModel;
      }
      let result = this.propertyValues.find(item => item.propertyCode === credentials.location.id);
      this.propertyInformation = result;
      //await this.CreateUserSession(result, isFromPMS,);
      await this.setMachineInfo(result.propertyId)


    }
    else if (credentials.userid != "" && credentials.password != "" && credentials.location != "") {
      let result = this.propertyValues.find(item => item.propertyCode === credentials.location.id);
      this.propertyInformation = result;
      // await this.CreateUserSession(result, isFromPMS,);
      await this.setMachineInfo(result.propertyId)

    }
  }

  async CreateUserSession(sessionInfo, isFromPMS) {
    const utcDate: Date = this.localization.getUTCDateTimeNow();
    let sessionData = {
      id: 0,
      userId: Number(sessionInfo["userId"]),
      startTime: this.localization.ConvertDateToISODateTime(utcDate),
      propertyId: Number(sessionInfo['propertyId']),
      productId: Number(sessionInfo['productId']),
      timeZone: sessionInfo['timeZone'],
      userToken: sessionStorage.getItem("_jwt")
    };
    await this.CreateSession(sessionData, null);
  }

  private async successCallBack(loginDetails: any) {
    sessionStorage.setItem(JWT_TOKEN, loginDetails.result.token);
    this.userName = loginDetails.result.userLoginInfo.userName;
    this.userInfo = loginDetails.result.userLoginInfo;
    const id = loginDetails.result.userLoginInfo.tenantId;
    this.setUserInfo(loginDetails);
    if (loginDetails.result.userLoginInfo.isNewUser === true) {
      // this.setPassword = true;
      this.tenantId = Number(id);
      const content = { title: 'SETUP PASSWORD', userName: this.userName, tenantId: this.tenantId };
      //this.setUpPassword(content, true);
    } else if (loginDetails.result.userLoginInfo.isPasswordExpired === true) {
      const content = { title: 'CHANGE PASSWORD', userName: this.userName, tenantId: this.tenantId };
      // this.setUpPassword(content, false);
    } else {
      this.propertyValues = loginDetails.result.userProperties;
      this.captionGenerator();
      this.loginSuccess = !this.loginSuccess;
      this.multipleProperties = this.propertyValues.map(x => ({
        id: x.propertyCode,
        name: x.propertyName
      }));

      this.getMachineInfo(this.userInfo.userId, Product.RETAIL, this.propertyValues.map(x => x.propertyId));
      //await this.setpropertyvalues();
    }

   // this.login$.next(LoginOperation.Inprogress);

  }

  getMachineInfo(userId: number, productId: number, propertyIds: number[]) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetMachineNamesConfigurationInfo",
      method: HttpMethod.Put,
      body: propertyIds,
      uriParams: { userId, productId },
      showError: false,
      extraParams: [false]
    });
  }

  setMachineInfos() {
    // Selecting property by default when there is only one property configured for tenant
    this.multipleLocations = this.propertyValues.map(x => { return { id: x.propertyCode, name: x.propertyName, propertyId: x.propertyId } });
    let siteLocation = this.multipleLocations.filter(location => location.propertyId == this.siteId);
    const location = siteLocation.length ? siteLocation[0] : null;
    let result = this.propertyValues.find(item => item.propertyCode === location?.id);
    this.propertyInformation = result;
    this.userMachineInfo.userPropertiesMachineInfo = this.userMachineInfo.userPropertiesMachineInfo;
    // Selecting property by default when there is only one property configured for tenant
    if (this.multipleProperties.length == 1) {
      this.setMachineInfo(this.propertyValues[0].propertyId);
      this.setMachineDetails()
    }
  }

  private resetMachineNameInfo() {
    this.isMachineNameEnabled = false;
    this.isPromptOnLoginEnabled = false;
    this.defaultMachineId = 0;
    this.machineNames = [];
  }

  private async setMachineInfo(propertyId: number) {
    this.resetMachineNameInfo();
    const userMachinePropertyInfo = this.userMachineInfo?.userPropertiesMachineInfo.find(x => x.propertyId == propertyId);
    if (userMachinePropertyInfo) {
      const miscConfiguration = userMachinePropertyInfo.miscConfiguration;
      // TRANSACTION_BY_MACHINENAME
      this.isMachineNameEnabled = miscConfiguration.enableTransactionByMachineName;
      // SELECTION_ON_LOGIN
      this.isPromptOnLoginEnabled = miscConfiguration.promptOnLogin;
      if (miscConfiguration.printerManagerURI) {
        this.localize.SetPrinterManagerURI(miscConfiguration.printerManagerURI);
      }
      if (this.isMachineNameEnabled) {
        this.defaultMachineId = userMachinePropertyInfo.userDefault.defaultMachineId;
        this.machineNames = userMachinePropertyInfo.machineNames.map(x => {
          return {
            id: x.id,
            name: x.name
          }
        });
        if (this.isPromptOnLoginEnabled && this.machineNames.length > 0) {
          //  this.loginForms.controls['machineName'].setValue('');
        }
      }
      const machineName = this.machineNames.find(x => x.id == this.defaultMachineId);
      this.defaultMachineId = machineName ? machineName.id : 0;
      if (this.defaultMachineId) {
        //this.loginForms.controls['machineName'].setValue(machineName);
      }
    }
  }
  async setUserInfo(loginDetails) {
    this.userProperties = loginDetails.result.userProperties ? loginDetails.result.userProperties : {};
    const language = loginDetails.result.userLoginInfo && loginDetails.result.userLoginInfo.languageCode;
    const userInfo = `userId=${loginDetails.result.userLoginInfo.userId};
                      userName=${loginDetails.result.userLoginInfo.userName};
                      firstName=${loginDetails.result.userLoginInfo.firstName};
                      lastName=${loginDetails.result.userLoginInfo.lastName};
                      roleId=${this.userProperties ? this.userProperties[0].roleId : 1};
                      roleName=${this.userProperties ? this.userProperties[0].roleName : ''};
                      language=${language};                     
                    `;
    sessionStorage.setItem(USER_INFO, userInfo);
  }

  captionGenerator() {
    this.captions = this.localize.getCaptions();
  }

  async CreateSession(sessionData, login: any) {

    
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "CreateSession",
      body: sessionData,
      method: HttpMethod.Post,
      showError: false,
      extraParams: []
    });
  }

  async ServerLoginwithTeant(username: string, password: string, customerId: number, tenantCode, login: any) {
    this.tenantId = customerId;
    sessionStorage.setItem('Tenantid', customerId.toString());
    let _userModel: any = {
      UserName: username, Password: password, TenantId: this.tenantId, ProductId: 1, TenantCode: tenantCode
    }
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: login.successCallback.bind(login),
      error: login.errorCallback.bind(login),
      callDesc: "Login",
      method: HttpMethod.Post,
      body: _userModel,
      uriParams: { Username: username, Password: password, TenantId: this.tenantId, TenantCode: tenantCode },
      showError: false,
      extraParams: [false]
    });
  }

  async setpropertyvalues() {
    const Selectedproperty = this.propertyInformation;
    let LanguageCode = this.userInfo != null && this.userInfo.languageCode != "" ? this.userInfo.languageCode : Selectedproperty["languageCode"];
   let userLanguageCode = Utilities.getLanguageCodeForRetail();
    let maxDecimalPlace = Selectedproperty["maximumDecimalPlaces"] ? Selectedproperty["maximumDecimalPlaces"] : 2;
    let PropertyValues = "Language=" + userLanguageCode + "; PropertyCode=" + Selectedproperty["propertyCode"] +
      "; SubPropertyCode=" + Selectedproperty["subPropertyCode"] + "; Currency=" + Selectedproperty["currencyCode"] + "; TenantId=" + Selectedproperty["tenantId"] + "; userName=" + this.userName
      + "; UserId=" + Selectedproperty["userId"] + "; RoleId=" + Selectedproperty["roleId"] + "; PropertyId=" + Selectedproperty['propertyId'] + "; SubPropertyId=" + Selectedproperty['subpropertyId'] + "; PlatformTenantId=" + Selectedproperty['platformTenantId'] + "; PropertyDate=" + Selectedproperty['propertyDate']
      + "; TimeZone=" + Selectedproperty['timeZone'] + "; LogOffAfter=" + Selectedproperty['logOffAfter'] + "; AutoLogOff=" + Selectedproperty['autoLogOff'] + "; PropertyName=" + Selectedproperty['propertyName']
      + "; UserLanguage=" + userLanguageCode + "; ProductId=" + Selectedproperty['productId'] + "; MaxDecimalPlaces=" + maxDecimalPlace;
    sessionStorage.setItem(this.propertyKey, PropertyValues);
    sessionStorage.setItem(this.propertyDateKey, Selectedproperty['propertyDate']);
    await this.SetPropertyInfo(Selectedproperty);
    this.localization.SetLocaleBasedProperties();
    this.spaconfig.load();
  }

  setUserInformation() {
    let result = this.userInfo;
    let UserValues = "firstName=" + result["firstName"] + "; lastName=" + + result["lastName"] + "; userId=" + result["userId"] + "; userName=" + result["userName"] + `;roleId=${this.propertyInformation['roleId']}` + `;roleName=${this.propertyInformation['roleName']}`;
    sessionStorage.setItem(this.userKey, UserValues);
  }

  async SetUserSessionConfiguration(userId: number) {
    let userSessionConfig = await this.userSessionConfig.getUserSessionConfiguration(userId);
    if (userSessionConfig) {
      let userSessionConfigValues =
        ` id=${userSessionConfig.id};
          userId=${userSessionConfig.userId};
          defaultOutletId=${userSessionConfig.defaultOutletId};
          defaultPaymentId=${userSessionConfig.defaultPaymentId};
          defaultTerminalId=${userSessionConfig.defaultTerminalId};
          defaultCourseId=${userSessionConfig.defaultCourseId};
          defaultPaymentDevice=${userSessionConfig.defaultPaymentDevice};
          defaultDeviceName=${userSessionConfig.defaultDeviceName};
          isIdtechSred=${userSessionConfig.isIdtechSred};
          hangingTicketsPrinter=${userSessionConfig.hangingTicketsPrinter};
          smallStickersPrinter=${userSessionConfig.smallStickersPrinter};
          propertyId=${userSessionConfig.propertyId};
    `;

      sessionStorage.setItem(this.userSessionConfig.userSessionConfigKey, JSON.stringify(userSessionConfig));
      // Set Retail Shop service - outlet dropdown value
      this.retailSharedService.SelectedOutletId = userSessionConfig.defaultOutletId;
      this.retailSharedService.SelectedTerminalId = userSessionConfig.defaultTerminalId;
    }
  }

  async setMachineDetails() {


    //this.userMachineInfo = await this.PropertySettingService.GetMachineNamesAndConfigurationSetting(this.userInfo.userId, [this.siteId]);
    // let userMachinePropertyInfo = this.userMachineInfo.userPropertiesMachineInfo.find(property => property.propertyId === this.siteId);
    // const retailPropertyInfo = userMachinePropertyInfo.settings;
    // TRANSACTION_BY_MACHINENAME
    /*   const retailMachineConfig = retailPropertyInfo.find(x=> x.switch == TRANSACTION_BY_MACHINENAME);
      let isMachineNameEnabled;
      if (retailMachineConfig) {
        isMachineNameEnabled = retailMachineConfig.value == 'true';
      } */
    // SELECTION_ON_LOGIN
    /*  const retailPromptConfig = retailPropertyInfo.find(x=> x.switch == SELECTION_ON_LOGIN);
     let isPromptOnLoginEnabled;
     if (retailPromptConfig) {
       isPromptOnLoginEnabled = retailPromptConfig.value == 'true';
     } */
    if (this.machineNames && this.isMachineNameEnabled) {
      //this.defaultMachine = userMachinePropertyInfo.defaultMachineId;
      this.propertyMachineCollection = this.machineNames;
      if (this.propertyMachineCollection.length > 1 && this.isPromptOnLoginEnabled) {
        this.selectedMachines$.next(this.propertyMachineCollection);
      } else {
        if (this.propertyMachineCollection && this.propertyMachineCollection[0] && this.isPromptOnLoginEnabled) {
          this.storeMachineInfo(this.propertyMachineCollection[0]);
          this.singleMachine$.next(this.propertyMachineCollection);
        } else if (this.defaultMachine && !this.isPromptOnLoginEnabled) {
          let machineDetails = this.machineNames.find(machine => machine.id == this.defaultMachine);
          this.storeMachineInfo({ id: machineDetails.id, name: machineDetails.name });
        
          if (this.isSwitchProperty) {
            this.selectedMachines$.next([]);
          } 
          else{
            this.singleMachine$.next([]);
          }
        } else {
          this.storeMachineInfo({ id: 0, name: '' });
          if (this.isSwitchProperty) {
            this.selectedMachines$.next([]);
          } 
          else{
            this.singleMachine$.next([]);
         }
        }
      }
    } else {
      this.storeMachineInfo({ id: 0, name: '' });
      if (this.isSwitchProperty) {
        this.selectedMachines$.next([]);
      } 
      else{
        this.singleMachine$.next([]);
      }
    }
  }


  storeMachineInfo(machineInfo) {
    this.localization.SetMachineId(machineInfo.id);
    this.localization.SetMachineName(machineInfo.name);
    const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
    this.setJwtTimer(tokenDuration);
  }

  async SetPropertyInfo(result: any) {
    this.retailSharedService.propertyDate = this.propertyInfo.CurrentDate;
    const propertyId: number = Number(result.propertyId);
    this.propertyInfo.SetPropertyDate(result.propertyDate);
    this.propertyInfo.SetPropertyId(propertyId);
    await this.SetPropertyConfiguration();
    await this.SetPropertyApiConfiguration();
    if (!this.propertyInfo.UseRetailInterface) {
      await this.SetPaymentConfiguration(propertyId);
    }
  }

  async SetPropertyConfiguration() {
    let propertityConfig = await this.PropertySettingService.getAllPropertySetting(this.propertyInfo.PropertyId);
    this.propertyInfo.SetPropertySetting(propertityConfig);
    this.retailSharedService.useRetailInterface = this.propertyInfo.UseRetailInterface;
    // let propertyConfig = await this.PropertySettingService.getPropConfig();
    // this.SetElasticApm(propertyConfig);
    //this.propertyInfo.SetPropertyConfiguration(propertyConfig);
  }

  async SetPaymentConfiguration(propertyId: number) {
    let propertyPaymentConfig = await this.PropertySettingService.GetPaymentConfigurationByProperty(propertyId);
    this.propertyInfo.SetPaymentConfiguration(propertyPaymentConfig);
    this.GetSupportedPMAgentVersionByPropertyID(propertyId);
  }

  async GetSupportedPMAgentVersionByPropertyID(propertyId: number) {
    const supportedPmAgentVersion = await this.PropertySettingService.GetSupportedPMAgentVersionByPropertyID(propertyId);
    this.propertyInfo.SetSupportedPMAgentVersion(supportedPmAgentVersion);
    this.payAgentService.ValidatePayAgentVersion();
  }

  async SetPropertyApiConfiguration() {
    const propertityConfig = await this.PropertySettingService.GetAllPropertyConfigurationSettings({
      configurationName: PROPERTY_CONFIGURATION_SETTINGS,
      propertyId: this.propertyInfo.PropertyId,
      productId: 0
    } as API.PropertyConfigurationSettings<any>);
    if ((propertityConfig != null) && (Object.keys(propertityConfig.configValue).length > 0)) {
      this.propertyInfo.SetPropertyConfiguration(propertityConfig);
      //this.SetFullStory(propertityConfig);   
    }
  }


  SetElasticApm(propertyConfig: any) {
    if (propertyConfig.configValue != undefined && propertyConfig.configValue[ElasticApm.UseApmServer] == 'true') {
      if (propertyConfig.configValue[ElasticApm.ApmServerUrl] != undefined && propertyConfig.configValue[ElasticApm.DistributedTracingOrigins] != undefined) {
        const apm = this.apmElasticService.init({
          serviceName: ElasticApm.ServiceName,
          serverUrl: propertyConfig.configValue[ElasticApm.ApmServerUrl],
          distributedTracingOrigins: propertyConfig.configValue[ElasticApm.DistributedTracingOrigins].split(',')
        })
        apm.setUserContext({
          'username': this.userInfo.userName,
          'id': this.propertyInfo.PropertyId //PropertyId is set in user Id field of Kibana for Filtering
        });
      }
    }
  }
}
