<ng-container *ngIf="!UseRetailInterface">
  <h6 class="form--headers" *ngIf="displayHeader">{{captions.paymentInfoHeader}}</h6>
  <div class="capture-card-container">
    <mat-form-field class="ag_form-control--sm device-type " [floatLabel]="floatLabel">
      <mat-select [placeholder]="captions.SelectDevice" name="device" [(ngModel)]="CurrentDevice"
        [disabled]="disableCardCapture" (click)="GetDevices()" id="ag-automation-select-caputurecard-device"
        [required]="cardRequired" attr.automationId='Dd_{{automation}}_device'>
        <mat-option *ngFor="let device of availableDevices" (click)="SelectDevice(device)" [value]="device.name">
          {{device.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="connect-btn  ag-button ag_button--secondary" [disabled]="disableCardCapture" mat-stroked-button
      (click)="onPaymentTransactionClick()" attr.automationId='Btn_{{automation}}_connect'>
      <i aria-hidden="true" class="icon-device"></i>{{captions.connect}}</button>
  </div>
  <div *ngIf="showPreferredCardEntryBanner">
    <div class="preferred-selection-link">{{preferredInputSelectedBanner}}
      <span attr.automationId='Lbl_{{automation}}_clickHere' class="link" (click)="proceedForAdyenGatewayPayment(true)"> {{captions.clickHere}}</span>
      <span> {{captions.ToChange}}</span>
    </div>
  </div>
  <div class="saved-card ag-mb-3" *ngIf="displayCardInfo != null && !hideCardDetail">
    <label for="" class="form-label-normal" [attr.LiteralID]="'savedCardDetail'">{{captions.savedCardDetail}} </label>
    <div class="card-details-container">
      <div class="saved-card-details">
        <span>{{displayCardInfo?.cardNumber}}</span>
        <span *ngIf="IsExpireDateVisible">{{displayCardInfo?.cardExpiration}}</span>
        <span>
          <app-retail-card-type [type]="displayCardInfo?.issuerType"></app-retail-card-type>
        </span>
      </div>
      <div *ngIf="!IsResortFinanceFolioAuthFlow && showCardRemovableIcon" attr.automationId='Icn_{{automation}}_delete' class="card-remove" [class.disabled]="!isCardRemovable" (click)="removeCardDetails()">
        <i class="icon-Delete cursor"></i>
      </div>
    </div>
  </div>
</ng-container>