
export class RatePlanDTO {
    Id: number;
    RestaurantId: number;
    Description: string;
    Abbreviation: string;
    BaseRate: string;
    ShiftId: number;
    StartDate?: Date;
    EndDate?: Date;
    IsDeleted: boolean;
    CreatedAt: Date;
    UpdatedAt: Date;
}

export enum PricingStrategy {
    PerPerson,
    PerReservation
}

export enum RatePlanCalculator {
    NonDuration,
    Duration
}

export class RatePlanSeatingTypeMapping {
    Id: number;
    RatePlanId: number;
    SeatingTypeId: number;
    DayOfWeek?: number;
    BaseRateModifier: string;
    CreatedAt: Date;
    UpdatedAt: Date;
}
export class RatePlanSummary {
    SeatingTypeId: number;
    SeatingType: string;
    RatePlanAmount: number;
    SeatCount: number;
}