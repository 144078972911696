import { Processor } from './processor';
import { ObjectChange } from '@app/shared/models/ChangeTrackingOperationResultDTO';
import { ChangeAction, TableBlockingRuleFrequencyType } from '@app/shared/constants/commonenums';
import { CacheService } from '@app/core/services/cache.service';
import { TablesService } from '@app/shared/services/tables.service';
import { TableBlockingRuleDTO } from '@app/shared/models/TableBlockingDTO';
import { PartyService } from '@app/shared/services/party.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { SettingsService } from '@app/shared/services/settings.service';
import { ActivityCustomizationsDTO } from '@app/shared/models/RestaurantDTO';
import { isObject } from 'lodash';

export class ActiivtyBlockingRuleChangeProcessor implements Processor {
  private static instance: ActiivtyBlockingRuleChangeProcessor;
  private constructor(public cs: CacheService, public ps: PartyService) {
  }

  public static Instance(_cs: CacheService, _ps: PartyService): ActiivtyBlockingRuleChangeProcessor {
    if (!ActiivtyBlockingRuleChangeProcessor.instance) {
        ActiivtyBlockingRuleChangeProcessor.instance = new ActiivtyBlockingRuleChangeProcessor(_cs, _ps);
    }
    return ActiivtyBlockingRuleChangeProcessor.instance;
  }

  Process(objectChangeRule: ObjectChange, additiondata: object, propertyId: number): void {
   
        let activityBlockingLookUp: {[key: number]: ActivityCustomizationsDTO} = {};
        if (propertyId == Utilities.RestaurantId()) {
          if(this.ps.activityBlockingChange$.value?.ActivityBlockingRule){
            activityBlockingLookUp = this.ps.activityBlockingChange$.value?.ActivityBlockingRule;
          }
        }
        else{
          if(this.cs.propertySettings.value[propertyId]['activityBlockingRules']){
            activityBlockingLookUp = this.cs.propertySettings.value[propertyId]['activityBlockingRules'];
          }
        }

    if (objectChangeRule.Action === ChangeAction.Created) {
        let blockedActivity = new ActivityCustomizationsDTO();
        this.updateRule(blockedActivity, objectChangeRule);
        activityBlockingLookUp[objectChangeRule.ObjectId] = blockedActivity;
    }
    else if (objectChangeRule.Action === ChangeAction.Removed) {
       delete activityBlockingLookUp[objectChangeRule.ObjectId];
    }
    else if (objectChangeRule.Action === ChangeAction.Updated) {
        let blockedActivity = activityBlockingLookUp[objectChangeRule.ObjectId];
        this.updateRule(blockedActivity, objectChangeRule);
        activityBlockingLookUp[objectChangeRule.ObjectId] = blockedActivity;
    
    }
    this.refreshActivityBlockingRules(activityBlockingLookUp, propertyId);
  }
  updateRule(updatedRule, objectChange) {
    objectChange.PropertyChanges.forEach(property => {
      if (property.PropertyName == 'Id') {
        updatedRule.CustomizationId = property.Value;
      }
      else if(property.PropertyName == 'ActivitySessionIds'){
        updatedRule.ActivitySessionIds = JSON.parse(property.Value);
      }
      else {
          updatedRule[property.PropertyName.replace('Internal', '')] =  property.Value;
        }     
    });
  }

  refreshActivityBlockingRules(activityBlockingLookUp: {[key: number]: ActivityCustomizationsDTO}, propertyId:number){
    if(propertyId == Utilities.RestaurantId()){
    var activityBlockingLookUpInfo = this.ps.activityBlockingChange$.value;
    activityBlockingLookUpInfo.ActivityBlockingRule = activityBlockingLookUp;
    this.ps.activityBlockingChange$.next(activityBlockingLookUpInfo);
    this.cs.propertySettings.value[propertyId]['activityBlockingRules'] = activityBlockingLookUpInfo;
    }
    else{
      if(this.cs.propertySettings.value[propertyId]['activityBlockingRules']){
        this.cs.propertySettings.value[propertyId]['activityBlockingRules'].ActivityBlockingRule = activityBlockingLookUp;
      }
    }
  }

}