import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { buttonTypes } from '@constants/commonenums';
import { LoaderService } from '@core/services/loader.service';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { OperationResultDTO } from '@models/ChangeTrackingOperationResultDTO';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';

@Component({
  selector: 'app-check-user-permissions',
  templateUrl: './check-user-permissions.component.html',
  styleUrls: ['./check-user-permissions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckUserPermissionsComponent extends Utilities implements OnInit {
  userPinval: number = null;
  ishasValue = false;
  ithaserror = false;
  explainBtn: ButtonValue;
  cancelBtn: ButtonValue;
  @Input() data: OperationResultDTO;
  constructor(public partyService: PartyService, private ps: PopupService,
    private tranlateService: TranslateService, public dialog: MatDialog, private as: AppService,
    private ls: LoaderService) {
    super(dialog);

  }

  ngOnInit() {
    this.explainBtn = {
      label: 'Explain',
      type: buttonTypes.actionPrimary,
      disbaledproperity: false,
      customclass: 'quick-seat'
    };

    this.cancelBtn = {
      label: 'cancel',
      type: buttonTypes.actionSecondary,
      disbaledproperity: false,
      customclass: 'quick-seat'
    };
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.ithaserror = true;
      return false;
    }
    this.ithaserror = false;
    return true;

  }

  checkEnterval(event) {
    let chk = this.userPinval;
    if (this.userPinval != null) {
      this.ishasValue = true;
    }
    if (isNaN(chk)) {
      // this.emptyval();
      this.ithaserror = true;
      return false;
    } else {
      this.ithaserror = false;
      this.isUserPINComplete();
      return true;
    }
  }

  numberpress(val) {
    if (!this.userPinval || this.userPinval.toString().length < 4) {
      this.ishasValue = true;
      this.ithaserror = false;
      this.userPinval = this.userPinval != null ? this.userPinval.toString() + val.toString() : val;
      this.isUserPINComplete();
    }
  }

  isUserPINComplete() {
    if (this.userPinval && this.userPinval.toString().length == 4) {
      this.as.isdisconnectedbyuser = true;
      Utilities.setImpersonateHost(this.userPinval.toString());
      this.ls.managerPin$.next(true);
    }
  }

  emptyval() {
    this.userPinval = null;
    this.ishasValue = false;
    this.ithaserror = false;
  }

  removelastval() {
    let str = this.userPinval != null ? this.userPinval.toString() : " ";
    str = str.substring(0, str.length - 1);
    this.userPinval = str ? parseInt(str) : null;
    if (this.userPinval) {
      this.ishasValue = true;
    } else {
      this.ishasValue = false;
    }
  }

  ngOnDestroy() {
    this.ps.restrictCloseDialog = false;
    Utilities.setImpersonateHost(null);
  }
}
