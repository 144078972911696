import { Utilities } from '@app/shared/utilities/utilities';
import { ChangeAction } from '@constants/commonenums';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { ReservationDTO } from '@models/InputContact';
import { BookingAmountsDTO, BookingContactAddonItemDTO, PartyMessageDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import _, { uniqBy } from 'lodash';
import { CacheService } from '../services/cache.service';
import { Processor } from './processor';

export class PartyBookingAmountsChangeProcessor implements Processor {

  private static instance: PartyBookingAmountsChangeProcessor;
  private constructor(public partyService: PartyService, public cs: CacheService) { }

  public static Instance(partyService: any = PartyService, cs: CacheService): PartyBookingAmountsChangeProcessor {
    if (!PartyBookingAmountsChangeProcessor.instance) {
        PartyBookingAmountsChangeProcessor.instance = new PartyBookingAmountsChangeProcessor(partyService, cs);
    }

    return PartyBookingAmountsChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: object, propertyId: number): void {
    let partiesList =[];
    let standBy = false;
    if (objectChange) {
      const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
      if(this.partyService.StandbyParties$.value.filter(party =>party.Id == partyId).length > 0){
        partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.StandbyParties$.value : this.cs.propertySettings.value[propertyId].StandbyParties;
        standBy = true;       
      }
      else{
        partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.Parties$.value : this.cs.propertySettings.value[propertyId].reservations;
        standBy= false;
      }
      if (objectChange.Action === ChangeAction.Created) {
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
        const party = partiesList.filter(party => party.Id == partyId);
        
        if (party && party.length) {
            if(!party[0].BookingAmounts){
                party[0].BookingAmounts = [];
            }
          const partyBookingAmount: BookingAmountsDTO = new BookingAmountsDTO();
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyBookingAmount).includes(property.PropertyName.replace('Internal', ''))) {
              partyBookingAmount[property.PropertyName.replace('Internal', '')] = property.Value;
              if (property.PropertyName == "Taxes" && property.Value) {
                partyBookingAmount["BookingChargeTaxAmount"] = _.sumBy(JSON.parse(property.Value), "Price");
              }
            }
          });
          party[0].BookingAmounts.push(partyBookingAmount)
          party[0].BookingAmounts = uniqBy(party[0].BookingAmounts, 'Id');
        }
      }
   
      if (objectChange.Action === ChangeAction.Updated) {
        const updatedPartyBookingId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party: ReservationDTO = partiesList.find(party => party.Id == partyId);
        if (party) {
            const partyBookingAmount: BookingAmountsDTO = party.BookingAmounts.find(addon => addon.Id == updatedPartyBookingId);
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyBookingAmount).includes(property.PropertyName.replace('Internal', ''))) {
              partyBookingAmount[property.PropertyName.replace('Internal', '')] = property.Value;
              if (property.PropertyName == "Taxes" && property.Value) {
                partyBookingAmount["BookingChargeTaxAmount"] = _.sumBy(JSON.parse(property.Value), "Price");
              }
            }
          });
          party.BookingAmounts[party.BookingAmounts.indexOf(partyBookingAmount)] = partyBookingAmount;
          partiesList[partiesList.indexOf(party)] = party;
        }
      }
      if (objectChange.Action === ChangeAction.Removed) {
        const removedPartyBookingId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party: ReservationDTO = partiesList.find(party => party.Id == partyId);
        // Cancelled reservation has to be removed from the list and from the screen
        if (party) {
            const partyBookingAmount: BookingAmountsDTO = party.BookingAmounts.find(addon => addon.Id == removedPartyBookingId);
          if (partyBookingAmount) {
            party.BookingAmounts = party.BookingAmounts.filter(addon => addon.Id != removedPartyBookingId);
          }
          partiesList[partiesList.indexOf(party)] = party;
        }
      }
    }
    partiesList = _.uniqBy(partiesList, 'Id');
    if (propertyId == Utilities.RestaurantId()) {
      if(standBy) {
        this.partyService.StandbyParties$.next(partiesList);
        this.partyService.standbyPartiesList = partiesList;
        this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      } else {
        this.partyService.Parties$.next(partiesList);
        this.partyService.partiesList = partiesList;
        this.cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    } else {
      if (standBy) {
        this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      } else {
         this.cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    }
  }
}
