import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuestbookComponent } from '@app/guestbook/guestbook.component';

const GuestbookRoutes: Routes = [
    {
    path: '',
    component: GuestbookComponent,
    }
];

@NgModule({
    imports: [
    RouterModule.forChild(GuestbookRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: []
})

export class GuestbookRoutingModule {

}
