<div class="activity-table">
<div class="config-section p-2">
    <div class="search">
    <dynamic-form [config]="config" #configForm></dynamic-form>
</div>
</div>
<div *ngIf="dataSource" class="activity-list">
    <ng-scrollbar>
         <table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" matSort class="mat-elevation-z8 activity-table-view">
             <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
                 <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ col | translate}} </th>
                 <ng-container *ngIf="col != 'Action' && col != 'Status'">
                     <td mat-cell *matCellDef="let element"> {{ element[col] }} </td>
                 </ng-container>
                 <ng-container *ngIf="col == 'Status'">
                     <td class="seat-grid-content" mat-cell *matCellDef="let element">
                         <dynamic-form [config]="element[col]" #form></dynamic-form>
                     </td>
                 </ng-container>
                 <ng-container *ngIf="col == 'Action'">
                     <td class="seat-grid-content" mat-cell *matCellDef="let element">
                        <activities-app-button [buttontype]="applyButton" (valueChange)='applyTemplatesData(element)'></activities-app-button>
                     </td>
                 </ng-container>
             </ng-container>


             <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="activity-table__header seat-grid-header">
             </tr>
             <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
         </table>
     </ng-scrollbar>
 </div>
</div>

<!-- <div class="template mt-4">
    <div *ngIf="templates?.length > 0" class="template__list">
        <ng-container *ngFor="let template of templates">
            <div class="template__item">
                <div class="template__lable">
                    <div class="title">
                        {{ template.Name }}
                    </div>
                    <div class="code">
                        <div class="types">
                            <div class="sub-title"> {{'TemplateCode' | translate}}</div>
                            {{ template.TemplateCode ? template.TemplateCode : 'NA' }}
                        </div>
                        <div class="types">
                            <div class="sub-title">
                                {{'activityType' | translate}}
                            </div>
                            {{template.ClassType ?  template.ClassType : 'NA' }}
                        </div>
                    </div>
                    <div class="code">
                        <div class="types">
                            <div class="sub-title"> {{'reservationtype' | translate}}</div>
                            {{ template.reservationtype ?  template.reservationtype : 'NA' }}
                        </div>
                        <div class="types">
                            <div class="sub-title">
                                {{'PaymentType' | translate}}
                            </div>
                            {{template.PaymentType ? template.PaymentType : 'NA'}}
                        </div>
                    </div>
                    <div class="description">
                        {{ template.Description ? template.Description : 'NA' }}
                    </div>
                </div>
                <div class="actions">
                    <activities-app-button [buttontype]="applyButton" (valueChange)='applyTemplatesData(template)'></activities-app-button>
                </div>
            </div>
        </ng-container>
    </div>
 
    
</div>

<div class="no-template">
    <div class="no-data-found" *ngIf="templates?.length == 0"> {{'noDataFound' | translate}}</div>
</div> -->