<section class="folio-payment-popup h-100">
   <div class="post-to-folio-wrapper p-3" [formGroup]='form'>
    <div *ngIf="folioTypeSearch">
        <h4 class='LWB16'>{{captions.lbl_Selected_Folio}}</h4>
            <div class="defaultFolio_banner ag_display--flex p-3 mb-4 justify-content-between align-items-center">
                    <div>
                        <label>{{selectedFolio.sourceType == addOnMember ? captions.memberName : captions.guestName}}</label>
                        <span>{{selectedGuest?.name}}</span>
                    </div>
                    <div>
                        <label>{{captions.lbl_folio_number}}</label>
                        <a [attr.automationId]="'Lbl_shopFolioDialogPopup_folioNumber'" class="ag_link ag_cursor--pointer" (click)="viewFolioInNewTab()">{{folioInvoiceNumber}}</a>
                    </div>
                    <div>
                        <label>{{captions.lbl_overallFolio_balance}}</label>
                        <span>{{overAllBalance}}</span>
                    </div>
                    <i [attr.automationId]="'Icn_shopFolioDialogPopup_closeFolio'" class='icon-close ag_cursor--pointer' aria-hidden="true"
            (click)="closeFolio()">&nbsp;</i>
            </div>
        <mat-radio-group class='ag_form-control--2' formControlName="folio">
            <mat-radio-button attr.automationId='Rdo_shopFolioDialogPopup_folioChange{{i}}' class='ag_display--inblock  ag_form--radio-button pr-5' [value]='opt.name'
                *ngFor="let opt of folios; let i=index;" (change)="folioChange(opt,i)" [checked]="opt.default">
                {{opt.name}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="mt-1" *ngIf="!folioTypeSearch">
        <mat-radio-group [attr.automationId]="'Rdo_shopFolioDialogPopup_folioType'" class='ag_form-control--2' formControlName="type" (change)="typeChange($event)">
            <mat-radio-button class='ag_display--inblock  ag_form--radio-button pr-5' value="folio">
                {{captions.lbl_folio_number}}
            </mat-radio-button>
            <mat-radio-button class='ag_display--inblock  ag_form--radio-button pr-5' value="guest">
                {{captions.guestName}}
            </mat-radio-button>
            <mat-radio-button class='ag_display--inblock  ag_form--radio-button pr-5' value="member" *ngIf = "isFolioEngageTurnedOn">
                {{captions.memberName}}
            </mat-radio-button>
        </mat-radio-group>
        <div class="notification-message ag_mt--3" *ngIf = "noteFlag"> 
            <app-common-note [noteMessage]="noteBar"></app-common-note>
        </div>
        <app-chip-search #chipSearch [attr.automationId]="'Srch_shopFolioDialogPopup_chipSearch'" class="ag_form-control--sm" [maxChips]="1" [showMoreData]="'true'"  [placeholderText]="SearchString"
        [allData]="allData" [selectedData]="selectedTagData" [searchKey]="tagSearchKey"
        [autoCompleteKeys]="autoCompleteKeys" [searchMaxCharLength]="searchGuestMaxLength"
        [selectedChipKey]="selectedGuestTagKey" (selectedChipDataEmit)="selectedTagEmit($event)"
        (onChipRemove)="onGuestChipRemoveEvent($event)" (searchTextEmit)="tagSearchTextEmit($event)"
         (emitOnBlur)="onBlur($event)">
      </app-chip-search>
    </div>
   </div>
   <div mat-dialog-actions class='footer-actions ag_display--flex ag_pl--4'>
        <app-button [attr.automationId]="'Btn_shopFolioDialogPopup_proceedButton'" [buttontype]='proceedButton' (valueChange)='onAction()'></app-button>
        <app-button [attr.automationId]="'Btn_shopFolioDialogPopup_onCancel'" class="pl-4" [buttontype]='cancelButton' (valueChange)='onCancel()'></app-button>
    </div>
</section>
