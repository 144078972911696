import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableBlockingRuleFrequencyType } from '../constants/commonenums';

@Pipe({
    name: 'tableName'
  })
  export class TableNamePipe implements PipeTransform {
    transform(tableIds, tablesArray): any {
      const myArrayFiltered = tablesArray?.filter(tables => {
        return tableIds?.some(ids => {
          return ids === tables?.Id;
        });
      });
      const tableName = myArrayFiltered?.map(data => " " + data?.Name).toString();
      return tableName;
    }
  }
  
  
  @Pipe({
    name: 'shiftsName'
  })
  export class ShiftsNamePipe implements PipeTransform {
    constructor(private transService: TranslateService) {}
    transform(shifts, frequency): any {
      let shiftName = this.transService.instant('allShiftsText');
      let everyDayShift = false;
      if (frequency.Type == TableBlockingRuleFrequencyType.EveryDay) {
        everyDayShift = true;
      }
      if (!everyDayShift && shifts) {
        shiftName = shifts?.map(data => " " + data?.Name).toString();
      }
      return shiftName;
    }
  }
  
  @Pipe({
    name: 'frequencies'
  })
  export class FrequencyPipe implements PipeTransform {
    constructor(private ts: TranslateService, private datePipe: DatePipe) {}
    transform(frequency,weekDay): any {
      var result = '';
      switch (frequency?.Type) {
        case TableBlockingRuleFrequencyType.EveryWeekDay:
          {
            return result = this.ts.instant('every') + ' ' + weekDay[frequency?.DayOfWeek];
          }
        case TableBlockingRuleFrequencyType.TheOnlyDay:
          {
            let date = new Date(frequency?.Date);
            return result = this.datePipe.transform(date, "dd-MM-yyyy") + " " + this.ts.instant('only');
          }
        case TableBlockingRuleFrequencyType.EveryDay:
          {
            return result = this.ts.instant('everyday');
          }
  
      }
    }
  }