<section class="newVersionWrapper retail-gift-card-wrapper retail-gift-card h-100">
  <form class="retail-form-section h-100">
    <div class="header-container d-flex align-items-center">
      <app-gift-cards-search [attr.automationId]="'Srch_retailGiftCard_search'"  class="app-gift-cards-search" [screenType]="SearchScreen.BalanceCheck" (giftCardSearchResult)="HandleGiftCardData($event)"></app-gift-cards-search>
      <button [attr.automationId]="'Btn_retailGiftCard_issueGift'" mat-raised-button class="issue-btn ml-auto secondary-default-btn"
        (click)="CreateGiftCard()">{{captions.IssueGiftCard}}</button>
    </div>

    <div class="body-container">
      <ng-container *ngIf="searchedGiftCardDetails">
        <app-gift-card-details [giftCardDetails]="searchedGiftCardDetails"></app-gift-card-details>
      </ng-container>
    </div>
  </form>
</section>
