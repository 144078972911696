export namespace API {
  export interface MachinePrinterConfiguration {
    id: number;
    machineId: number;
    printerName?: string;
   // productId : number
    defaultNoofCopies: number;
    printInformationType: PrintInformationType;
  }
}
export enum PrintInformationType {
  Recepit=0,
  CartAgreement = 1,
  ClubAgreement = 2 ,
  ShoeAgreement = 3 ,
  RetailSaleChit = 4,
  CaddyShack = 5,
  HangingTicket = 6 ,
  SmallSticker = 7,
  Report =8,
  TeeTicket = 9
}

export interface Options {
  key: any;
  label: any;
  ischecked?: boolean;
  value?: any;
}
export namespace UI {
  export interface MachinePrinterConfiguration {
    machineId: number;
    machineName : string;
    printerArr: PrintInfoType[];
  }
  export interface PrintInfoType {
    id?: number,
    printerName?: string,
    printInformationType: PrintInformationType,
    label: string,
    selectedValue?: Options,
    defaultNoofCopies: number;
  }
}