<div class="activity-creation">
    <div class="activity-container sidemenu">
        <div class="list-menu">
            <div class="list-item" [ngClass]="{'active' : activeMenuId == item.id}" (click)="scrollTop(item.id)"
                *ngFor="let item of sideMenuLists">
                {{item.name | translate}} <span class="icon-next"></span>
            </div>
        </div>
    </div>
    <div class="activity-container main-menu">
        <section class="activity-actions">
            <div class="title" *ngIf="isTemplate"> {{ isEdit ? ('UpdateTemplate' | translate) : ('CreateTemplate' |
                translate)}} <span *ngIf="activityName">- ( {{activityName }})</span></div>
            <div class="title" *ngIf="!isTemplate"> {{ isEdit ? ('UpdateActivity' | translate) : ('CreateActivity' |
                translate)}} <span *ngIf="activityName">- ( {{ activityName }} )</span></div>

            <div class="actions">
                <span *ngIf="requiredFieldValidation" class="seat-error-messages pt-1"> {{'requiredFieldValidation' |
                    translate}}</span>
                <activities-app-button *ngIf="!isTemplate && !isEdit" [buttontype]="templateButton"
                    (valueChange)='chooseTemplates()'></activities-app-button>
                <activities-app-button [buttontype]=" cancelButton" (valueChange)='cancelActivity()'></activities-app-button>
                <activities-app-button [buttontype]="saveButton" (valueChange)='createSaveRequest()'></activities-app-button>
            </div>
        </section>
        <ng-scrollbar>
            <section class="basic-setup" id="1" (mouseenter)="setSelectedMenu(1)">
                <div class="seat-header-three"> {{'BasicSetup' | translate}}</div>
                <div class="row lesson-details">
                    <div class="col-lg-9 col-md-9">
                        <dynamic-form [config]="classLessonDetailsConfig" #lessonDetails></dynamic-form>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <div class="packages__image-upload">
                            <app-upload-photo (setPhoto)="setPhoto($event)" [imageUrl]="ss.ImageData"
                                [guestPhoto]="false">
                            </app-upload-photo>
                        </div>
                    </div>
                </div>
                <div class="reservation-setup">
                    <div class="row">
                        <div class="col-lg-9 col-md-9">
                            <dynamic-form [config]="basicSetupConfig" #basicSetup></dynamic-form>
                        </div>
                    </div>
                </div>

                <div class="activity-setup" id="2">
                    <div class="row activity-strength">
                        <div class="col-lg-9 col-md-9">
                            <div class="sub-title"> {{'reservationSetup' |
                                translate}}</div>
                            <dynamic-form [config]="activitySettingConfig" #activitySetting></dynamic-form>
                            <div>
                                <div class="sub-title"> {{'reservationtype' |
                                    translate}}</div>
                                <dynamic-form [config]="IsForStandbyReservationsConfig" #form="dynamicForm">
                                </dynamic-form>
                                <div [hidden]="IsForStandbyReservations">
                                    <div class="standby-options">
                                        <dynamic-form [config]="AllowOverbookConfig" #form="dynamicForm">
                                        </dynamic-form>
                                    </div>
                                    <div class="standby-options">
                                        <dynamic-form [config]="AllowStandByConfig" #form="dynamicForm">
                                        </dynamic-form>
                                    </div>
                                    <div [hidden]="!AllowStandBy" class="standby-options">
                                        <dynamic-form [config]="AutoConfirmStandbyConfig" #form="dynamicForm">
                                        </dynamic-form>
                                    </div>
                                </div>
                                <div [hidden]="!(IsForStandbyReservations || AllowStandBy)">
                                    <dynamic-form [config]="MaxStandByStrengthConfig" #form="dynamicForm">
                                    </dynamic-form>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="guest-container">
                                <div class="sub-title">
                                    {{'GuestTypes' | translate}}
                                </div>
                                <div class="guest-list">
                                    <div class="no-data-found"
                                        *ngIf="guestTypeFormGroup?.controls?.guestType_Rows?.value?.length == 0">
                                        {{'noDataFound' | translate}}
                                    </div>
                                    <!-- <dynamic-form [config]="guestTypeConfig" #guestType></dynamic-form> -->
                                    <div [formGroup]="guestTypeFormGroup">
                                        <div formArrayName="guestType_Rows" class="guestType__body form-input"
                                            *ngIf="guestTypeFormGroup">
                                            <div class="guestType__content"
                                                *ngFor="let items of guestTypeFormGroup?.controls?.guestType_Rows?.controls; let i=index;"
                                                [formGroupName]="i">
                                                <div>
                                                    <mat-checkbox class="chip-control__checkbox"
                                                        formControlName="isMapped">
                                                        <span class="form-helper-text">{{items?.value.Name}}</span>
                                                    </mat-checkbox>
                                                </div>
                                                <div>
                                                    <mat-form-field class="basic-input-field">
                                                        <mat-label>{{'minQuantity' | translate}}</mat-label>
                                                        <input type="number" min=0 formControlName="MinimumQuantity" numbersOnly
                                                            (change)="validateMinimumQuantity($event)" matInput />
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="guest-container">
                                <div class="sub-title">
                                    {{'BookingTypes' | translate}}
                                </div>
                                <div class="guest-list">
                                    <div class="no-data-found" *ngIf="bookingTypeConfig?.length == 0"> {{'noDataFound' |
                                        translate}}</div>
                                    <dynamic-form [config]="bookingTypeConfig" #bookingType></dynamic-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="calender-details" id="3" (mouseenter)="setSelectedMenu(3)">
                <div class="seat-header-three"> {{'CalendarDetails' | translate}}
                </div>
                <div class="row">
                    <div class="col-lg-10 col-md-10">
                        <div class="batch-group" [hidden]="classType !== 1">
                            <div class="batch-header">
                                <div class="sub-title">
                                    {{'GroupNames' | translate}}
                                </div>
                                <ng-container *ngIf="batchFormGroup">
                                    <div class="text-right"
                                        *ngIf="batchFormGroup?.controls?.batch_Rows?.controls?.length > 0">
                                        <!-- <activities-app-button [buttontype]="saveBatchButton" (valueChange)='SaveSessionGroups()'>
                                        </activities-app-button> -->
                                    </div>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="batchFormGroup">
                                <div class="text-center"
                                    *ngIf="batchFormGroup && batchFormGroup?.controls?.batch_Rows?.controls?.length == 0">
                                    <activities-app-button [buttontype]="addBatchButton" (valueChange)='addBatchName()'>
                                    </activities-app-button>
                                </div>
                            </ng-container>


                            <div [formGroup]="batchFormGroup">
                                <div formArrayName="batch_Rows" class="form-input" *ngIf="batchFormGroup">
                                    <div *ngFor="let itemrow of batchFormGroup?.controls?.batch_Rows?.controls; let i=index;let l=last"
                                        [formGroupName]="i" class="batch_row">
                                        <mat-form-field class="basic-input-field">
                                            <mat-label> {{'GroupName' | translate}} </mat-label>
                                            <input formControlName="Name" (blur)="SaveSessionGroups()" matInput>
                                        </mat-form-field>
                                        <mat-form-field class="basic-input-field allocation-text">
                                            <mat-label>{{ 'allocationReasonText' | translate }}</mat-label>
                                            <mat-select formControlName="SelectedAllocationId"
                                                (change)="SaveSessionGroups()">
                                                <mat-option *ngFor="let allocation of allocationList"
                                                    [value]="allocation.id">
                                                    {{allocation.value}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div class="batch-actions">
                                            <i class="icon-Delete cursor" (click)="removeGroup(i)"></i>
                                            <span class="icon-Plus" (click)="addBatchName()"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="calender-template">
                    <dynamic-form [config]="calendarDetailsConfig" #calendarDetails></dynamic-form>
                </div>
                <div class="activity-sessions">
                    <div class="day-actions">
                    <div class="day-row days">
                        <div class="tabs"
                            [ngClass]="{'active' : day.id == activeDayOfWeek , 'available' : !day.available}"
                            *ngFor="let day of sessionAvailableDaysOfWeek" (click)="setActiveDayOfWeek(day)">
                            {{ day.dayName | translate}} <span class="lesson-indicator"
                                [hidden]="!day.isSessionAvailable || !day.available"></span>
                        </div>

                    </div>
                    <div class="day-row btn-actions">
                        <div class="actions">
                            <div triggers="manual" [autoClose]="'outside'"
                                [placement]="['right-center', 'bottom', 'left-center' , 'auto']"
                                [ngbPopover]="cloneTodaysTemplate" #popOver="ngbPopover"
                                (click)="openClonePopover(popOver)" class="btn"
                                [ngClass]="{'disable-clone' : disableCloneButton}">
                                <span class="icon-clone"></span> {{'cloneText' | translate}}
                            </div>
                            <div class="btn" (click)="addNewSesionTimeRange(activeDayOfWeek)"
                                [ngClass]="{'timerange-disabled' : disableAddTimeRangeButon}">
                                {{'AddTimeRange' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="day-row">
                        <div class="open-hours" *ngIf="openHours ; else noConfigure">
                            <div class="alert alert-primary" matTooltip="{{openHours}}" ><span class="icon-clock1"></span> {{openHours}}</div>
                        </div>
                        <ng-template #noConfigure>
                            <div class="alert alert-primary"><span class="icon-clock1"></span> {{'noOpenHoursConfigured' | translate}}</div>
                        </ng-template>
                    </div>
                </div>
                    <div *ngIf="warningMessgaes" class="alert alert-warning" role="alert">
                        {{warningMessgaes}} <ng-container *ngIf="InvalidSessionDays && InvalidSessionDays.length > 0"> (
                            <span *ngFor="let day of InvalidSessionDays"> {{day | translate}} </span> )</ng-container>
                    </div>
                    <div class="row p-1" [ngClass]="{'private-booking' : classType == 2}">
                        <ng-container *ngFor="let day of sessionAvailableDaysOfWeek">
                            <ng-container *ngFor="let item of day?.sessions ;  index as i">
                                <div class="p-0 session-list"
                                    [ngClass]=" (classType == 2 ) ? 'col-lg-9 col-md-9' : 'col-lg-12 col-md-12' "
                                    [hidden]="item?.Dayofweek !== activeDayOfWeek">
                                    <div class="unique-sessions" [ngClass]="{'fade-session' : item?.StatusCode == statusMaster.Cancelled}">
                                        <!-- <div class="add-session" (valueChange)="addNewSesionTimeRange(activeDayOfWeek)">
                                        <span class="icon-add3"></span>
                                    </div> -->
                                        <div class="delete-session"> <i *ngIf="!item?.IsBooked && item?.StatusCode !== statusMaster.Cancelled" [ngClass]="{'cursor-initial': item.SessionDate}"
                                                (click)="deleteSession(item)" class="icon-Delete cursor"></i>
                                            <span class="icon-info" *ngIf="item?.IsBooked"
                                                matTooltip="{{'bookedSessionWarningMessage' | translate}}"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                            <span class="icon-Plus"
                                                *ngIf="day?.sessions?.length -1 == i && classType !== 2"
                                                (click)="addNewSesionTimeRange(activeDayOfWeek)"></span>
                                        </div>

                                        <app-session-mapping [sessionData]="item" [locations]="locationList"
                                            [staffs]="staffList" [allocationList]="allocationList"
                                            [calendarDetails]="calendarDetailsForm" [classType]="classType"
                                            [sessionGroups]="sessionGroups" [isTemplate]="isTemplate"
                                            (sessionChange)="sessionChanges($event)" [locationGroup]="locationGroup">
                                        </app-session-mapping>

                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="day?.sessions.length == 0 && day?.id == activeDayOfWeek" class="no-session">
                                {{'nosessionWarningMsg' |
                                translate}}</div>
                        </ng-container>
                    </div>
                </div>
            </section>
            <section class="add-ons" id="4" (mouseenter)="setSelectedMenu(4)">
                <div class="seat-header-three"> {{'addons' | translate}}</div>
                <div class="selection">
                    <div class="row">
                        <div class="col-lg-9 col-md-9" [hidden]="!addonsList?.length">
                            <dynamic-form [config]="addonDetailsConfig" #addonDetails></dynamic-form>
                        </div>
                    </div>
                </div>
                <div *ngIf="addonsList?.length ; else no_addon">    
                    <div class="div" *ngFor="let addon of addonsList">
                        <div *ngIf="addon?.SubCategories.length > 0">
                            <app-add-on-mapping [CategoryAddon]="addon" [PartySize]="activitySettingForm.value"
                                [isEdit]="isEdit" [isTemplateUsed]="appService?.isTemplateUsed"
                                [updateAddonSize]="updateAddonSize">
                            </app-add-on-mapping>
                        </div>
                    </div>
                </div>
                <ng-template class="col-lg-6 col-md-6" #no_addon>
                    <div class="no-rateplan" >
                        {{'noAddonsProperty' | translate}}
                    </div>
                </ng-template>
            </section>
            <section id="5" (mouseenter)="setSelectedMenu(5)">
                <div class="seat-header-three"> {{'PaymentDetails' | translate}}
                </div>
                <div class="div">
                    <div class="row">
                        <div class="col-lg-9 col-md-9">
                            <div class="payment-config">
                                <dynamic-form [config]="paymentAvailableConfig" #form="dynamicForm">
                                </dynamic-form>
                                <dynamic-form [config]="serviceChargeConfig"
                                    [hidden]="!IsPrepaymentRequire || !isServiceChargeAvailable" #form="dynamicForm">
                                </dynamic-form>
                                <dynamic-form [config]="taxConfig"
                                    [hidden]="!IsPrepaymentRequire || isRetailEnabledProperty " #form="dynamicForm">
                                </dynamic-form>
                                <dynamic-form [config]="paymentDetailsConfig"
                                    [hidden]="!IsPrepaymentRequire" #paymentDetails>
                                </dynamic-form>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="no-rateplan" *ngIf="!IsPrepaymentRequire">
                                {{'noPaymentInvolved' | translate}}
                            </div>

                            <div [hidden]="!IsPrepaymentRequire">
                                <div class="payment-details" [hidden]="PrePaymentModeType !== 2">
                                    <div class="differ-payment">
                                        <dynamic-form [config]="diferredConfig" #diferredDetails></dynamic-form>
                                    </div>
                                </div>
                                <div class="payment-details" [hidden]="PrePaymentModeType !== 3">
                                    <div class="payment-setup">
                                        <dynamic-form [config]="collectDepositConfig" #collectDepositDetails>
                                        </dynamic-form>
                                    </div>
                                    <div class="payment-setup" [hidden]="PrePaymentModeType !== 3">
                                        <div class="payment-collection">
                                            <div class="mb-4">
                                                {{'collectPayment' | translate}}
                                            </div>
                                            <dynamic-form [config]="checkinCheckOutConfig" #checkinCheckOutDetils>
                                            </dynamic-form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="no-rateplan" *ngIf="IsPrepaymentRequire && classType == 2 && this.paymentDetailsForm.form.value.RatePlanId == -1">
                                {{'defaultPrivateRatePlan' | translate}} 
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <section id="6" (mouseenter)="setSelectedMenu(6)" [hidden]="!IsPrepaymentRequire">
                <div class="seat-header-three"> {{'CancellationDetails' | translate}}
                </div>
                <div class="w-80">
                    <dynamic-form [config]="cancellationConfig" #cancellationDetails></dynamic-form>
                </div>
            </section>
            <section id="7" (mouseenter)="setSelectedMenu(7)" class="guest-details">
                <div class="seat-header-three"> {{'guestSettings' | translate}}
                </div>
                <dynamic-form [config]="AllowAdditionalGuestConfig" #form="dynamicForm">
                </dynamic-form>
                <div class="guest-settings">
                    <div class="sub-title">
                        {{'SecondaryGuestSettings' | translate}}
                    </div>
                    <dynamic-form [config]="guestSettingsConfig" #guestSettings></dynamic-form>
                </div>
            </section>

            <!-- Online Reservation Settings -->
            <section id="8" (mouseenter)="setSelectedMenu(8)" class="guest-details online-reservation-settings">
                
                <div class="seat-header-three"> {{'onlineReservationSettings' | translate}}
                </div>

                <dynamic-form [config]="OnlineReservationConfig" #OnlineReservation>
                </dynamic-form>

            </section>

            <section id="9" class="additional-settings" (mouseenter)="setSelectedMenu(9)">
                <div class="seat-header-three"> {{'additionalSettings' | translate}}</div>
                <div class="row">
                    <div class="col-lg-8 col-md-8">
                        <dynamic-form [config]="additionalSettingsConfig" #additionalSettings></dynamic-form>
                        <dynamic-form [config]="slotSettingConfig" #slotSetting></dynamic-form>
                    </div>
                    <div class="col-lg-4 col-md-4 pl-0">
                        <div class="ticket-print">
                            <dynamic-form [config]="ticketPrintConfig" #ticketPrint></dynamic-form>
                        </div>
                        <div class="rafting">
                            <dynamic-form [config]="raftingConfig" #rafting></dynamic-form>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="sub-title">
                            {{'additionalEmployee' | translate}}
                        </div>
                        <div class="additional-employee">
                            <dynamic-form [config]="additionalEmployeeConfig" #additionalEmployee></dynamic-form>
                        </div>
                    </div>
                </div>
            </section>
        </ng-scrollbar>
    </div>
</div>


<div class="cloneToDays">
    <ng-template #cloneTodaysTemplate>
        <div class="cloneToDays__days">
            <div class="cloneToDays__selectAll">
                <mat-checkbox [(ngModel)]="allSelected" (change)="selectAllDays($event.checked)">{{'selectAll' |
                    translate}}</mat-checkbox>
            </div>
            <ng-container *ngFor="let day of days; let index = index;">
                <input hidden="true" type="checkbox" [(ngModel)]="day.selected" [id]="'day_'+day.day"
                    (change)="cloneDaySelect(day.selected)" />
                <label class="cloneToDays__day" [for]="'day_'+day.day">
                    {{day.day | translate}}
                </label>
            </ng-container>
            <div class="actions">
                <activities-app-button class="cloneToDays__apply-btn" [buttontype]="applyCloneButton"
                    (valueChange)="applyClone(days)">
                </activities-app-button>
                <activities-app-button class="cloneToDays__apply-btn" [buttontype]="cancelButton" (valueChange)="cancelClone()">
                </activities-app-button>
            </div>

        </div>
    </ng-template>
</div>