import { Pipe, PipeTransform } from '@angular/core';
import { GridDataType } from './grid-cell-data-manipulator.pipe';

@Pipe({
  name: 'percentageColorConverter'
})
export class PercentageColorConverterPipe implements PipeTransform {
  public colorGreen: number[] = [40, 167, 69];
  public colorOrange: number[] = [255, 193, 7];
  public colorRed: number[] = [220, 53, 69];
  transform(value: any, griddatatype: GridDataType, isPercentageColumn: boolean = false) {
    switch (griddatatype) {
      case GridDataType.FreeTableByPartySize:
        return this.getColor(value / 100);
      case GridDataType.WaitTimeByPartySize:
        return !isPercentageColumn ? this.getColor(value) : "#ffffff";
      //return this.getColor(value);
      default:
        return "#ffffff";
    }

  }

  getColor(percent: number) {
    if (percent < 0.0) {
      percent = 0.0;
    }
    if (percent > 1.0) {
      percent = 1.0;
    }
    return percent <= 0.5
      ? this.getMixedColor(this.colorGreen, this.colorOrange, percent * 2)
      : this.getMixedColor(this.colorOrange, this.colorRed, (percent - 0.5) * 2);
  }

  getMixedColor(colorstart: number[], colorend: number[], percentage: number) {
    let r = Math.floor(colorstart[0] * (1 - percentage) + colorend[0] * percentage);
    let g = Math.floor(colorstart[1] * (1 - percentage) + colorend[1] * percentage);
    let b = Math.floor(colorstart[2] * (1 - percentage) + colorend[2] * percentage);
    return this.rgbToHex(r, g, b);
  }

  rgbToHex(r: number, g: number, b: number) {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  componentToHex(c: number) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

}
