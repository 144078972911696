import { Directive, EventEmitter, Injectable, InjectionToken, OnDestroy, Output } from '@angular/core';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataShareWrapper } from './components/services/data-share-wrapper';
import { IFormValidDetails } from '@app/settings/models/common.interface';


export const COMPONENTINPUT = new InjectionToken<any>('componentInput');

@Directive()
@Injectable({
  providedIn: 'root'
})
export class PopupService extends DataShareWrapper implements OnDestroy {
  @Output() confirmedAction = new EventEmitter();

  @Output() previousEvent = new EventEmitter();
  @Output() btnLabel = new EventEmitter();
  nextEvent$: Subject<any> = new Subject<any>();
  nextBtnEnabled$: Subject<any> = new Subject<any>();
  previousButtonEnabled$: Subject<any> = new Subject<any>();
  previousEvent$: Subject<any> = new Subject<any>();
  formValid$: Subject<any> = new Subject<any>();
  closeDialog$: Subject<any> = new Subject<any>();
  confirmedAction$: Subject<any> = new Subject<any>();
  warningAction$: Subject<boolean> = new Subject<boolean>();
  addToCartAction$: Subject<any> = new Subject<any>();
  confirmAddToCartAction$: Subject<any> = new Subject<any>();
  cancelledAction$: Subject<any> = new Subject<any>();
  saveBtnEnable$: Subject<any> = new Subject<any>();
  cancelBtnEnable$: Subject<any> = new Subject<any>();
  confirmationDialog: ComponentDetails;
  dialogPopupTittle: string;
  additionalInfo: any;
  restrictCloseDialog = false;
  isRestrictCloseDialog = false;
  popupOutputEvent$: Subject<any> = new Subject<any>();
  clonePermissionConfirmed: boolean = false;
  enableSaveBtnAuthorise$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tabsActions$: Subject<any> = new Subject<any>();
  tabsActionData:IFormValidDetails[] = [];
  changedTabIndex: number = 0;

  constructor() {
    super();
   }
  ngOnDestroy() {
    this.nextEvent$.unsubscribe();
    this.previousEvent.unsubscribe();
    this.btnLabel.unsubscribe();
  }

}
