export enum Menu {
  Reservation = 0,
  WaitList = 1,
  Tables = 2,
  GuestBook = 3,
  TimeLine = 4,
  Servers = 5,
  FloorPlan = 6,
  Reports = 7,
  Settings = 8,
  Switch = 10,
  ActivitiesTimeline = 11,
  Shop = 12,
  BookingCentral = 13,
  POS = 14
}

export enum ReservationType {
  Reservation = 0,
  Waitlist = 1,
  Server = 2,
  Tables = 3,
  Blockingrule = 4,
  Unblock = 5,
  StandbyParties = 6,
  Timeline = 7,
  OpenBooking = 8,
  ActivityBooking = 9,
  PrivateLessonBooking = 10,
  TheatreBooking = 11,
  TablesFullView = 12,
  ActivityBlock = 13
}

export enum ReservationStatus {
  Reservation = 0,
  Arrived = 1,
  SeatParty = 2,
  ReleaseTable = 3,
  Reopen = 4,
  Cancel = 5,
  Waitlist = 6,
  UndoNoShow = 7,
  ClearTable = 8,
  AssignServer = 9,
  UnassignServer = 10,
  Blocked = 11,
  Unblock = 12,
  AdvancedBlock = 13,
  DeleteParty = 14,
  ChangePartySize = 15,
  QuickSeat = 16,
  Reseat = 17
}

export enum EditorStatus {
  DeleteTable = 0,
  CloneTable = 1,
  NewJoinedTables = 2,
  AddJoinTables = 3,
  CancelJoinTables = 4,
  AddNewFloor = 5,
  AssignTables = 6,
  UpdateJoinTable = 7,
  UploadImages = 8
}

export enum ModuleName {
  Reservations = 0,
  Waitlists = 1,
  Tables = 2,
  Guestbook = 3,
  Timeline = 4,
  Servers = 5,
  Settings = 6,
  Restaurant = 7
}

export enum ShapeTypeEnum {
  Rectangle = 'rect',
  Circle = 'circle',
  Text = 'text',
  Area = 'area',
  Wall = 'wall',
  Ellipse = 'ellipse',
  BasicRectangle = "brect",
  BasicCircle = 'bcircle',
  Pin = 'pin',
  Image = 'image'
}

export enum TableStatus {
  Open = 0,
  Blocked = 1,
  Reserved = 2,
  Arrived = 3,
  Cancelled = 4,
  OnHold = 5,
  Seated = 6,
  'No Show' = 7,
  'Checked-Out' = 8,
  None = 9
}
export enum TableBlockBy {
  ByShift,
  ByTimeRange,
  ByDay
}

export enum LayoutUpdateStatus {
  New = 0,
  Update = 1
}
export enum TableBlockingRuleFrequencyType {
  EveryWeekDay = 0,
  TheOnlyDay = 1,
  EveryDay = 2,
}

export enum ReservationTabs {
  Size = 0,
  Area = 1,
  Date = 2,
  Time = 3,
  Table = 4,
  Guest = 5
}

export enum WaitlistTabs {
  Size = 0,
  Area = 1,
  Time = 2,
  Table = 3,
  Guest = 4
}

export enum Language {
  en = 0
}

export enum Loacle {
  'en-US' = 0
}

export enum SeatingRegion {
  Inside = 0,
  Outside = 1,
  Anywhere = 2
}

export enum PartyStatus {
  'Fully Seated' = 0,
  'Has Drinks' = 1,
  'Entreed' = 2,
  'Paid' = 3,
  'Bussing' = 4,
  'Struggling' = 5,
  'Waiting' = 6
}

export enum Actions {
  'Seat Party' = 0,
  'Arrived' = 1,
  'Release Table' = 2,
  'Create Reservation' = 3,
  'Create Waitlist' = 4,
  'Cancel Reservation' = 5,
  'Cancel Waitlist' = 6,
  Cancel = 7,
  'Re Open' = 8,
  'Edit Reservation' = 9,
  'Assign Server' = 10,
  'Clear Shift' = 11,
  'Unblock' = 12,
  'Switch Server' = 13,
  'Edit Guest' = 14,
  'Create Guest' = 15,
  'ViewGuest' = 16,
  'GetReservation' = 17,
  'GetRestaurantList' = 18,
  'Assign Preferred Table' = 19,
}

export enum GuestInfoIcons {
  Manual = 0,
  SMS = 1,
  Email = 2,
  RecentHistory = 3
}

export enum GuestBookTabs {
  AllGuests = 0,
  RecentGuests = 1,
  VipGuests = 2,
  FavouriteGuests = 3,
  Feedback = 4

}
export enum InstrctorTabs {
  AllInstrctors = 0,
  InstrctorScheduling = 1
}




export enum RecordDisplay {
  Single = 0,
  All = 1
}

export enum DashboardType {
  reservation = 0,
  waitlist = 1,
  tables = 2,
  guestbook = 3,
  timeline = 4,
  settings = 5,
  login = 6
}

export enum MessageStatus {
  delivered = 0,
  pending = 1
}

export enum Labels {
  createreservationbuttontext = 0,
  arrivedbuttontext = 1,
  seatpartybuttontext = 2,
  reopenbuttontext = 3,
  releasetablebuttontext = 4,
  reservationcirclecomponenttitle = 5,
  reservationcirclecomponentlabel1 = 6,
  reservationcirclecomponentlabel2 = 7,
  reservationcirclecomponentlabel3 = 8,
  waitlistcirclecomponentlabel1 = 9,
  waitlistcirclecomponentlabel2 = 10,
  waitlistcirclecomponentlabel3 = 11,
  prevtext = 12,
  nexttext = 13,
  finishtext = 14,
  cancel = 15,
  back = 16,
  ok = 17,
  waitlistcirclecomponenttitle = 18,
  createwaitlistbuttontext = 19,
  reservationfortoday = 20,
  totalcoversfortoday = 21,
  reservationsforthisweek = 22,
  activereservations = 23,
  cancelledreservations = 24,
  accordionstatustext = 25,
  accordionmessagetext = 26,
  accordionmoretext = 27,
  accordionlesstext = 28,
  assignserverbuttontext = 29,
  clearshiftbuttontext = 30,
  servercirclecomponenttitle = 31,
  servercirclecomponentlabel1 = 32,
  servercirclecomponentlabel2 = 33,
  servercirclecomponentlabel3 = 34,
  unblockbuttontext = 35,
  assignserverselection = 36,
  switcherverselection = 37,
  reservationconfirmedconfirmationtext = 38,
  waitlistcreatedconfirmationtext = 39,
  partyseatedconfirmationtext = 40,
  switchserverconfirmationtext = 41,
  serverassignedconfirmationtext = 42,
  reservationcancelled = 43,
  waitlistcancelled = 44,
  reservationMenuText = 45,
  waitListMenuText = 46,
  guestBookMenuText = 47,
  reservationPopUpTime = 48,
  waitListPopUpWaitTime = 49,
  popUpSize = 50,
  popUpArea = 51,
  popUpTable = 52,
  popUpGuest = 53,
  popUpDate = 54,
  reservationupdatedconfirmationtext = 55,
  error = 56,
  popUpTitleaddnewguest = 56,
  popUpTitleSeatTable = 57,
  popuptitleseattime = 58,
  contactaddedconfirmationtext = 59,
  contactupdatedconfirmationtext = 60,
  popUpTitleeditguest = 61,
  notext = 62,
  yestext = 63,
  selectRestaurantErrText = 64,
  defaultWaitTime = 65,
  slotTimeAM = 66,
  slotTimePM = 67,
  partyStatusSeatText = 68,
  waitSessionMorning = 69,
  waitSessionEvening = 70,
  contactdeletedconfirmationtext = 71,
  waitlistupdatedconfirmationtext = 72,
  unserverassignedconfirmationtext = 73,
  quickblockconfirmationtext = 74,
  SyncServerSuccessMsg = 75,
  SyncServerFailedMsg = 76,
  SyncServerNotFoundMsg = 77,
  SyncServerCommunicationErrorMSg = 78,
  tablesMenuText = 79,
  timeLineMenuText = 80,
  serverMenuText = 81,
  floorPlanMenuText = 82,
  reportsMenuText = 83,
  settingsMenuText = 84,
  OpenCheckErrorMsg = 85,
  MoveCheckErrorMsg = 86,
  reservationremovedfromCart = 87,
  reservationaddedfromCart = 88,
  reservationupdatedtoCart = 89
}

export enum PartyNoteType {
  FreeFormNote,
  PredefinedNote,
  SpecialRequest,
  PromoCode,
  SpecialMeal,
}

export enum PartyType {
  Reservation,
  WalkIn,
  StandBy
}

export enum PartyTemplateType {
  Create,
  Edit,
  Cancel
}

export enum HistoryPartyState {
  Pending,
  Seated,
  Left,
  NoShowed,
  Cancelled,
  Late,
  Missed,
}

export enum UpdatedPartySlotType {
  AutoSlot,
  ManualActualSlot,
  ManualDefaultSlot,
  OverbookingSlot,
  AutodetectSlot,
  NoSlot,
}

export enum SlottingMode {
  Manual,
  Auto
}
export enum SlottingType {
  Dining,
  NonDining
}


export enum ValidationMessageType {
  Error = 0,
  Warning = 1,
  ConsentMessages = 2,
}


export class GuestBookingValidationDTO
{
    ContactId: number
    SearchType: number
    BookingTypeId: number
    BookingStartDate: string;
    BookingEndDate: string;
    StayStartdate: Date;
    StayEnddate: Date;
    ActivityId: number
    ValidateStayInfo: boolean
}
// export enum PopupType {
//     CreateGuest = 0,
//     EditGuest = 1,
//     CreateParty = 2,
//     EditParty = 3,
//     Server = 4,
//     Table=5,
// }

export enum PopupType {
  Reservation = 0,
  Waitlist = 1,
  Guest = 2,
  Server = 3,
  Table = 4,
  ReportScheduler = 5,
  Booking = 6,
  ActivityBooking = 7
}
export enum GuestTabs {
  PersonalInfo = 0,
  Contact = 1,
  Preferences = 2,
  AdditionalInfo = 3
}

export enum ResponseType {
  Success = 0,
  Failed = 1
}

export enum ContainerType {
  Status = 0,
  Message = 1
}

export enum ContentView {
  grid = 0,
  list = 1,
  print = 2,
  msg = 3
}
export enum TableTabs {
  Reservation = 0,
  WaitLists = 1,
  Seated = 2,
  Slots = 3
}
export enum GetSlotsOperationOptions {
  None = 0,
  FullReservations = 1,
  TableBlockingRules = 2,
  Full = FullReservations | TableBlockingRules
}
export enum buttonTypes {
  primaryGreen = 'primary-green',
  primaryRed = 'primary-red',
  actionPrimary = 'action-primary',
  actionPrimarySmall = 'action-primary-small',
  secondary = 'secondary',
  actionSecondary = 'action-secondary',
  actionSecondarySmall = 'action-secondary-small',
  roundedBtn = 'rounded-btn',
  roundedBtnSmall = 'rounded-btn-small',
  actionSecondarySmallRed = 'action-secondary-small-red',
  printAction = 'action-primary-print',
  actionSecondaryOrange = 'action-secondary-small-orange',
  actionNgxPrint = 'action-primary-print-ngxPrint',
  actionFullOrange = 'action-secondary-fully-orange',
  actionMultiPrimarySmall = 'action-multi-primary-small',
  actionPrimaryOverbook = 'action-primary-overbook',
  actionSecondaryOverbook = 'action-secondary-overbook'

}

export enum GetReservationsOperationOptions {
  None = 0,
  Reservations = 1,
  StandbyParties = 2,
  TableBlockingRules = 4,
  WaitLists = 8,
  AllSeatedParties = 16,
  Full = Reservations | WaitLists | StandbyParties | TableBlockingRules,
  AllSeatedPartiesWithTableBlockingRules = AllSeatedParties | TableBlockingRules,
  Both = Reservations | StandbyParties
}

export enum ComponentTypes {
  guestBook,
  reservation,
  dailyEstimates,
  weeklyEstimate,
  server,
  table,
  guestBookService,
  timeline,
  waitlist,
  seatParty,
  addServer,
  dataRetention,
  switchServer,
  quickBlock,
  Unblock,
  quickSeat,
  CancelReservation,
  newFloorPlanAdd,
  newFloorPlanEdit,
  saveChangesFloor,
  communalTableConversion,
  nonCommunalTableConversion,
  deleteTable,
  guestCharges,
  reservationCreation,
  billingOverPayment,
  billingRefundPayment,
  advancedBlock,
  deleteblockingrule,
  commonconfirmmessage,
  moveParty,
  deleteServer,
  editServer,
  login,
  roles,
  resultprocess,
  deleteFloorPlan,
  MaxPartyQuickseat,
  PartyCreation,
  reportScheduler,
  forcerun,
  ManagerPin,
  ExplainPermissions,
  tablelayout,
  Hosts,
  printChit,
  specialMealCoverType,
  restaurantPolicies,
  CreateReservation,
  EditReservation,
  CreateWaitList,
  EditWaitList,
  switchProperty,
  specialValidation,
  standby,
  hotelConciergeList,
  editStandby,
  removeStandby,
  guestBookInfo,
  layoutEditorAlert,
  guestlist,
  maxTablesExceedAlert,
  ReservationTimesPopup,
  deleteFloorPlanImage,
  floorPlanImage,
  imageGallery,
  cancelOTAValidation,
  notification,
  resetPin,
  clearShift,
  assignServer,
  CancelDashboardReservation,
  addSchedule,
  AddOpenBooking,
  EditOpenBooking,
  AddPrivateLessonBooking,
  EditPrivateLessonBooking,
  AddActivityBooking,
  EditActivityBooking,
  addEvent,
  CancelActivityBooking,
  feedback,
  CheckinSession,
  CheckoutSession,
  ActivityRatePlanInfo,
  RatePlanWarning,
  ConfirmBooking,
  RejectBooking,
  ExistngReservationWarning,
  ConvertStandBytoReservationActivity,
  RateNegotitaion,
  AlertNegotiation,
  AlertWrongNegotiation,
  ClonePermission,
  UserRoles,
  EditRole,
  RejectPrivateLessonBooking,
  CreateCartBooking,
  CartItemsDisplayComponent,
  AdditionalCharge,
  RoleTypeAccess,
  TicketComponent,
  InstructorWarningMessage,
  addEditPackage,
  cloneCustomFields,
  AlertMessage,
  AcceptOverbooking,
  CalendarViewComponent,
  minDateInfoMessage,
  packageConfirmation,
  bookedPackages,
  cancelPackageConfirmation,
  deletePackageConfirmation,
  applyPackageConfirmation,
  packageCancelledMessage,
  saveHeadlines,
  cancelHeadlines,
  activityCreateConfirmation,
  templateSelection,
  headlineUpdateWarningMessage,
  BlockSessionActivity,
  UnblockSessionActivity,
  ActivitiesQRCode,
  CheckInRatePlanSummary,
  CheckOutRatePlanSummary,
  BasicSetupConfirmation,
  CheckinSessionWithEdit,
  ModifySession,
  ModifySessionConfirmation,
  ChargeGuest,
  actionCharged,
  negAlert,
  specialMealCreateConfirmation,
  specialMealBooking,
  locationWaring,
  deleteApplnChanges,
  createEditreservation,
  cancelIndividualSession,
  specialEventSelection,
  seatingAreaDeleteConfirmation,
  seatingAreaSuccessConfirmation,
  deleteOffer,
  saveOffer,
  packageBrokenWarning,
  promoCodeAuditLog,
  ConsentMessagesWarning,
  BlockStaff,
  bookingTypeValidation,
  GuestItinerary
}

export enum SlotType {
  Time,
  ActualSlot,
  DefaultSlot
}

export enum ManualSlotType {
  FromDefaultSlot,
  BrandNewSlot,
  EditedFromDefaultSlot
}

export enum LoadRestaurantOptions {
  None = 0,
  Layout = 1,
  Settings = 2,
  State = 4,
  PendingChargePartiesCount = 8,
  DataRetentionPolicy = 16,
  Full = Layout | Settings | State | DataRetentionPolicy
}

export enum NotificationMessageType {
  Success = 0,
  Error = 1,
  Info = 2,
  warning = 3
}

export enum ActionType {
  Created,
  Updated,
  Removed,
  None
}

export enum ChangeAction {
  Created,
  Updated,
  Removed,
  None
}

export enum PermissionType {
  AddSlots = 1,
  RemoveSlots,
  EditSlots,
  AddReservations,
  EditReservations,
  Overbook,
  AddToStandByList,
  EditStandByParties,
  BookInNonWebReservableSlots,
  BookInSlotsWithInappropriateSize,
  PageParties,
  SendFreeformPageMessages,
  CancelReservations,
  NoShowReservations,
  RemoveStandByParties,
  ManageTableBlockingRules,
  BookOrSeatAtBlockedTables,
  ChangeRestaurantSettings,
  MoveSeatedParties,
  AddReservationwithNeverAssign
}

export enum OperationResultState {
  Success,
  ValidationErrors,
  ServerException,
  InsufficientPermissions,
  AccessDenied,
  Logout,
  ConsentMessages
}
export enum OperationType {
  Connection = 0,
  Reservation = 1,
  Waitlist = 2,
  SeatingParties = 3,
  Settings = 4
}

export enum PolicyType {
  Standard = 1,
  Consent = 2
}

export enum PolicyDataType {
  Name = 1,
  EmailAddress = 2,
  PhoneNumber = 3,
  Address = 4,
  GuestImage = 5,
  BirthdayAndAnniversary = 6,
  CustomGuestFields = 7
}


export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export enum HttpStatusCode {
  Continue = 100,
  SwitchingProtocols = 101,
  OK = 200,
  Created = 201,
  Accepted = 202,
  NonAuthoritativeInformation = 203,
  NoContent = 204,
  ResetContent = 205,
  PartialContent = 206,
  MultipleChoices = 300,
  Ambiguous = 300,
  MovedPermanently = 301,
  Moved = 301,
  Found = 302,
  Redirect = 302,
  SeeOther = 303,
  RedirectMethod = 303,
  NotModified = 304,
  UseProxy = 305,
  Unused = 306,
  TemporaryRedirect = 307,
  RedirectKeepVerb = 307,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  RequestEntityTooLarge = 413,
  RequestUriTooLong = 414,
  UnsupportedMediaType = 415,
  RequestedRangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  UpgradeRequired = 426,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HttpVersionNotSupported = 505
}
export enum OperationForTimelineRefresh {
  // BookActivityFromHostApp = 198,
  // BookActivityFromWebsite = 199,
  // BookMulptipleActivityFromHostApp = 200,
  // BookMultipleActivityFromWebsite = 201,
  // ClassOrSessionBookingFromHostApp = 219,
  // ClassOrSessionBookingFromWebsite = 220,
  // UpdateClassOrSessionBookingFromHostApp = 221,
  // UpdateClassOrSessionBookingFromWebsite = 222,
  // OpenBookingFromHostApp = 223,
  // OpenBookingFromWebsite = 224,
  // UpdateOpenBookingFromHostApp = 225,
  // UpdateOpenBookingFromWebsite = 226,
  SetOpenHours = 227,
  CloneOpenHours = 228,
  SaveLesson = 229,
  DeleteLesson = 230,
  // ClassOrSessionCheckIn = 231,
  // ClassOrSessionCheckOut = 232,
  // CancelClassOrSession = 233,
  // OpenBookingCheckIn = 235,
  // OpenBookingCheckOut = 236,
  // CancelOpenBooking = 237,
  // CancelSession = 217,
  // CancelActivity = 218,
  // StandByClassOrSessionBookingFromHostApp = 238,
  // ConvertStandByActivityToReservation = 239,
  // UpdateStandByActivityOperation = 240,
  // ConvertStandBytoReservationActivity = 241,
  // RejectPrivateLessonOperation = 242,
  // StandByClassOrSessionBookingFromWebsite = 243,
  // CancelStandByClassOrSessionBookingFromWebsite = 244,
  // StandByPrivateLessonHostOperation = 255,
  // StandByPrivateLessonWebsiteOperation = 256,
  // SingleCartBookingOperation = 257
}

export enum OperationForCartRefresh {
  SingleCartBookingOperation = 257
}

export enum AllowedOperationServerStatiticsRefresh {
  CompletePartyVisit = 29,
  MoveSeatParty = 30,
  UnseatParty = 36,
  CompleteAllPartiesVisits = 42,
  SetServers = 80,
  UpdateServer = 81,
  AssignTableServers = 105,
  SynchServers = 114,
  ReseatParty = 120,
  MoveParty = 121,
  SeatParty = 122,
  CreateWalkIn = 141,
  BlockAllTables = 147,
  BlockAllServerTables = 149,
  ReassignTableServers = 150,
  CreateServer = 172,
  RemoveServer = 173,
  CreateSchedule = 214,
  RemoveSchedule = 215,
  UpdateSchedule = 216,
}

export enum ChangedEntityType {
  NoChange = 0,
  ChangeSet = 1,
  Settings = 2,
  Layout = 3,
  State = 4,
  DataRetentionPolicy = 5,
  StateAndChange = 6,
  SwitchFloorPlan = 7,
  FloorPlanImage = 8,
  SettingsAndLayout = 9,
  Users = 10,
  RefreshCart = 11,
  ActivityTimeLine = 12,
  ServerStatistics = 13,
  RefreshPackage = 15,

  SettingsFull = 100, //To handle in signalR
  SettingsFullAndChange = 101,
  SettingsAndLayoutAndChange = 102,
  LayoutAndChange = 103,
  SettingsAndChange = 104,


}

export enum OperationStatus {
  Success = 0,
  Error = 1,
  Info = 2,
  warning = 3
}

export enum UpdatedEntityName {
  SpecialMeals = 0,
  PredefinedContactNotes = 1,
  PredefinedPartyMessages = 2,
  PredefinedPartyNotes = 3,
  LayoutBackgroundImage = 4,
  SeatingAreas = 5,
  SeatingTypes = 6,
  Servers = 7,
  Statuses = 8,
  None = 9,
  Reservation = 10,
  Waitlist = 11,
  SeatingParties = 12,
  SeatingTypeSeatingAreaMappings = 13,
}
export enum GetPartiesFilter {
  All,
  OnlyWithCreditCards
}
export enum GetPartyOptions {
  None = 0,
  WithContact = 1,
  WithNotes = 2,
  WithCreditCardInfo = 4,
  WithHostInfo = 8,
  WithCoverTypeQuantities = 16,
  WithCRSUserInfo = 32,
  WithSpecialMealName = 64,
  WithOverpaymentAmount = 128,
  WithContactCustomFields = 256,
  WithTables = 257,
  Full = WithContact | WithNotes | WithCreditCardInfo | WithHostInfo | WithCoverTypeQuantities | WithCRSUserInfo | WithSpecialMealName | WithOverpaymentAmount | WithContactCustomFields | WithTables,
  ForBilling = WithHostInfo | WithCRSUserInfo | WithOverpaymentAmount | WithSpecialMealName | WithCreditCardInfo,
}

export enum PartyState {
  Pending,
  Seated,
  Left,
  NoShowed,
  Cancelled,
  CancellationInprogress,
  Confirmed,
  CheckedIn,
  CheckedOut,
  Open, // not available in API
  Blocked // not available in API
}
export enum RolesAndPermissionsType {
  ViewFeedback = 'ViewFeedback',
  Bookatblockedlocation = 'BookOrSeatAtBlockedTables',
  NegotiateRates = "NegotiableRates",
  Generatereports = 'GenerateReports',
  ScheduleReports = 'ScheduleReports',
  CreateActivity = 'CreateActivity',
  ModifyActivity = 'ModifyActivity',
  CancelActivity = 'CancelActivity',
  EditCheckedInReservations = 'EditChecked-inReservations',
  BookOrEditPastReservations = 'Book/EditPastReservations',
  AddToStandByList = 'AddToStandByList',
  EditStandByParties = 'EditStandByParties',
  Overbook = 'Overbook',
  ManageActivityBlocks ='ManageActivityBlocks',
  UndoCheckIn = 'UndoCheck-in',
  EditReservations = 'EditReservations'
}

export enum CancelActivityType {
  OnlyThis = 1,
  AllRemaining = 2
}

export enum BlockActivityType {
  OnlyThis = 1,
  AllRemaining = 2
}

export enum HeadlineActivityType {
  OnlyThis = 1,
  AllRemaining = 2
}

export enum RejectStandByType {
  Predefined = 1,
  Custom = 2
}

export enum FinancialEffectType {
  NotSupported,
  NoPaymentsInvolved,
  NoEffect,
  NewReservationAttempt,
  NoFullRefund,
  FullRefund,
  NoFullRefundAndNewReservationAttempt,
  FullRefundAndNewReservationAttempt,
  PartialCharge,
  PartialRefund,
  NoShowFeeUpdated,
  Authorize,
  AuthorizeUpdated,
  ChargeCancellationLater,
  FullRefundAndNoShowFee
}


export enum SignalRState {
  ConnectionRequested = 0,
  Connected = 1,
  DisconnectionRequested = 2,
  Disconnected = 3,
  ConnectionFailed = 4,
  ReadyToConnect = 5,
  DisconnectionFailed = 6
}

export enum TableLayoutConfig {
  servers,
  tables,
  preferredTables,
  reservationTableSelection,
  blockingRule
}

export enum ReservationEmailNotificationType {
  Created,
  Updated,
  Cancelled
}

export enum ICountNotificationMenus {
  reservationMenuText,
  waitListMenuText
}

export enum ConciergeTrackingType {
  Hotel,
  Concierge
}

export enum PartiesSelectionCriteria {
  allPartiesInShift,
  allSeatedParties,
  allParties
}

export enum SelectionType {
  select,
  unselect
}

export enum AutomaticRefundState {
  NotApplicable,
  Succeeeded,
  Failed
}

export enum ManualSlotSelection {
  slotSelection,
  overbook,
  standBy,
  disableSlot
}
export enum BookingBehavior {
  OpenBooking,
  ClassOrSession,
  PrivateLesson
}

export enum ClassType {
  Session = 0,
  Class = 1,
  Duration = 2
}
export enum LessonType {
  GroupLesson = 0,
  PrivateLesson = 1,
  specialMeal = 2
}
export enum PaymentType {
  FlatFee,
  Percentage
}

export enum PromoCodeTaxType{
  BeforeTax = 0,
  AfterTax
}
export enum ViewBy {
  Instructor,
  Lessons,
  location,
  Shows,
  Layouts
}

export enum ViewPeriod {
  Day = 'day',
  Week = 'week'
}

export enum AvailabilityCriteria {
  None = 0,
  IncludeLocation = 1,
  IncludeInstructor = 2,
  IncludeLocationAndInstructor = 3,
}

export enum AvailabilityType {
  OpenToAll,
  OpenToSpecificMember,
  SoldOut,
  SelectionCriteriaNotMatched,
  Standby,
  Blocked,
  Cancelled,
  AssetNotFound
}

export enum Status {
  New = 0,
  Read = 1,
  Closed = 2,
  ActionTaken=3,
  Approved = 10,
  Inactive = 90,
  SoftDelete = 95,
  MarkedForPurge = 99,
  Cancelled = 11,
  Processed = 12,
}

export enum PricingBy {

  Session = 0,
  Class = 1,
  Duration = 2
}

export enum PropertyType {
  Restaurant = "Restaurant",
  Auditorium = "Auditorium",
  Cabana = "Cabana",
  MovieTheatre = "MovieTheatre",
  TennisCenter = "TennisCenter",
  General = "General"
}


export enum AuthorizePaymentType {
  Authorize_Only = 1, //(Authorize from CC)
  Payment_Only = 2, //(Charge/Sale from CC)
  Post_To_RoomCharge = 3,
  Recurring_Payment = 4, //(Schedule)
  Loyalty_Point = 5,
  AuthStripe = 6, //(Gpay/Apple pay)
  Authorize_Document = 7, //(Waiver)
  Capture_Payment = 8
}

export enum SecondaryGuestSearchType {
  Default = 1,
  Member = 2,
  CommonProfile = 3,
  RoomNo = 4
}

export enum ReservationSourceType {
  TableSaver,
  WebSite,
  Importer,
  CRS
}

export enum PaymentGateways {
  FreedomPay,
  rGuestPay,
  AuthorizePay,
  RetailPay = 4,
  IntegratedPayment = AuthorizePay | RetailPay
}

export enum PaymentStates {
  PaymentNotRequired,
  PaymentPending,
  Authorized,
  ChargeCaptured
}


export enum UserOperationsTypes {
  CreateRole = "CreateRole",
  UpdateRole = "UpdateRole",
  DeleteRole = "DeleteRole",
  SetRole = "SetRole",
  SetRoles = "SetRoles",
  CreateHost = "CreateHost",
  UpdateHost = "UpdateHost",
  RemoveHost = "RemoveHost",
  AddPermissions = "AddPermissions",
  RemovePermissions = "RemovePermissions",
  ClonePermission = "ClonePermission",
  Hosts = 'Hosts',
}

export enum propertyOperationType {
  LoadSettings = 1,
  GetImages,
  TableBlockingRules,
  Reservations,
  RestaurantNote,
  ActivityBlockingRules
}

export enum Operations {
  create = 1,
  update = 2,
  cancel = 3,
  checkIn = 4,
  checkOut = 5,
  charge = 6
}

export enum LoginOperation {
  Inprogress = 1,
  Failed = 2,
  Completed
}
export enum ActivityType {
  SessionBooking = 0, // no of sessions
  PrivateBooking = 1, // duration
  OpenBooking = 2, // duration, name of property
  SpecialMeal = 3, // cover types
  ClassBooking = 4 // no of sessions
}


export enum PackageActivityType {
  CreditLimit = 0,
  ActivityLimit = 1
}
export enum PackageType {
  SingleDay = 0,
  MultiDay = 1
}
export enum SingleDayActivityType {
  AnyActivity = 0,
  SpecificActivity = 1
}
export enum GuestFieldValidationType {
  Min,
  Max,
  Equal,
  NotEqual,
  NotNull,
  Exists
}
export enum GuestFieldMetrics {
  None = "None",
  Liters = "Liters (L)",
  Kiloliters = "Kiloliters (KL)",
  Milliliters = "Milliliters (ML)",
  Gallon = "Gallon (gal)",
  Grams = "Grams (g)",
  Kilograms = "Kilograms (Kg)",
  Centimeters = "Centimeters (CM)",
  Millimeters = "Millimeters (MM)",
  Meters = "Meters (M)"
}

export enum CalendarViewType {
  Days,
  Months,
  Years
}

export enum BlockType {
  HardBlock = 0,
  SoftBlock = 1
}
export enum StatusFilterOption {
  Active = 0,
  InActive = 1,
  All = 2
}
export enum PackageStatusDto {
  PackageId,
  IsActive
}

export enum AttributeType {
  PublicHeadlines = 1,
  PrivateHeadlines = 2
}
export enum PaymentMethod {
  None = 0,
  Prepayment = 1,
  PartialPayment = 2,
  Authorize = 3,
  SkipPayment = 4
}
export enum FinancialEffectAction {
  Update,
  CheckIn,
  CheckOut,
  Cancel
}

export enum AuditableEntityType {
  Party,
  Contact,
  ManualSlot,
  DefaultSlot,
  Shift,
  AutoSlot,
  TableBlockingRule,
  BillingPlan,
  BookedSession,
  BookedAddon,
  SecondaryContact,
  ContactCustomGuestField,
  Activity,
  Rateplan,
  Addon,
  ActivityCustomizations,
  Offer,
  AdvancedSettings
}

export enum AuditableActionType {
  Create,
  Convert,
  Update,
  Remove,
  Cancel,
  NoShow,
  UndoNoShow,
  Seat,
  Unseat,
  Reseat,
  CompleteVisit,
  AssignTables,
  Move,
  SetStatus,
  OverrideDepartureTime,
  ChangePosition,
  SetPhoto,
  RemovePhoto,
  ChangeContact,
  OpenedSlotCheckSuccess,
  Merge,
  Confirm,
  Enable,
  Disable,
  SetWebReservable,
  SetNotWebReservable,
  ChargeNoShowFee,
  IgnoreNoShowFee,
  RefundNoShowFee,
  RefundSpecialMeal,
  ChargeSpecialMeal,
  AuthorizeSpecialMeal,
  IgnoreSpecialMealFee,
  RetailPayment,
  ActivityHeadLinesUpdated,
  ActivityHeadLinesRemoved,
  ActivityBlockUpdated
}

export enum CategoryType {
  None = -1,
  ContactNotes,
  PartyNotes,
  Addons,
  Activity,
}
export enum TimeLineAvailablityShowTypes {
  AvailableCapacityByTotalCapacity = 0,
  BookedCapacityByTotalCapacity = 1,
  BookedCapacityByAvailableCapacity = 2,
}
export enum filterType {
  StatusCode = 'status',
  ClassType = 'classType',
  StandbyReservations = 'reservationType',
  PrePaymentMode = 'paymentMode',
}

export enum BlockDialogType {
  block = 1,
  unblock = 2
}

export enum VCart_Products
    {
        None = 0,
        SPA = 1,
        Retail = 2,
        Golf = 3,
        Common = 4,
        SalesNCatering = 5,
        PMS = 6,
        Tenant = 7,
        EnhancedInventory = 8,
        HouseKeeping = 9,
        Accounting = 10,
        Folio = 11,
        Activity = 12,
        Dining = 13
    }
    export enum InvoiceLevel {
      PrimaryGuest,
      IndividualGuest
    }
    
    export enum VCartActivityType{
      Activity='NonDining',
      Dining='Dining'
    }

export enum ReportMenuTabs {
  Reports = 1,
  Scheduler = 3
}
export enum RatePlanPricingTypes
{
    None,
    OpenBooking,
    PrivateLesson,
    Both
}

export enum StaffScheduleType {
  OffShift = 1,
  BreakOrBlock = 2
}

export enum BlockView {
  Location = "1",
  Activity = "2",
  Staff = "3"
}