import { Pipe, PipeTransform } from "@angular/core";
import { GuestTitle } from "../models/InputContact";

@Pipe({
    name: 'guestTitlePipe'
  })
  export class GuestTitlePipe implements PipeTransform {
  
    transform(title: number): any {
      return (title > 0) ? GuestTitle[title] : "";
    }
  }