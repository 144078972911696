<a *ngIf="config.showNavigation" href="javascript:void(0)" class="left-arrow"><i class="icon-Path-490 "></i></a>
<!-- Only Month and Date -->
<mat-form-field class="basic-date-picker {{ config.class}}" [hidden]="config.isHidden" [hideRequiredMarker]="!config.isStarSymbolRequired"
  [ngClass]="{'input-with-boundary':config.appearance}" [formGroup]="group">
  <mat-label class="select-label">{{ config.label | translate}}</mat-label>
  <input matInput [matDatepicker]="picker" (focus)="picker.open()" (click)="picker.open()"  readonly
    [formControlName]="config.name" [attr.name]="config.name" [max]="config.maxDate" [min]="config.minDate">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [calendarHeaderComponent]="monthHeader"></mat-datepicker>
  <mat-error
    *ngIf="group.controls[FormControlName].hasError('required') && group.controls[FormControlName].touched && config.showErrorText">
    Field is required
  </mat-error>
</mat-form-field>