import { Injectable, Output, EventEmitter, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CustomPopUpBussinessService {
  private renderer: Renderer2;
  constructor(public rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);

  }
  createpopupDimension(dialogContainer: any, dialogData: any): void {
    const getDimension = this.createDimension(dialogContainer, dialogData);
    if (getDimension.width !== 0) {
      const cdkElement = dialogContainer.nativeElement.parentNode.parentElement.parentElement;
      this.renderer.setStyle(cdkElement, 'min-height', `${getDimension.height}%`);
      this.renderer.setStyle(cdkElement, 'min-width', `${getDimension.width}%`);
    }
  }
  createDimension(dialogContainer: any, dialogData: any) {
    const setDimension = { width: 50, height: 50 }
    switch (dialogData.componentDetails.dimensionType) {
      case 'small': {
        setDimension.width = 10;
        setDimension.height = 10;
        break;
      }
      case 'medium': {
        setDimension.width = 10;
        setDimension.height = 10;
        break;
      }
      case 'large': {
        setDimension.width = 10;
        setDimension.height = 10;
        break;
      }
      default: {
        setDimension.width = 0;
        setDimension.height = 0;
      }
    }
    return setDimension;
  }

}
