import { Component, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailReportControl, ValidationMessage } from '../../../basereport/base-report.component';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';

@Component({
  selector: 'app-retail-sale-by-item',
  templateUrl: './sale-by-item.component.html',
  styleUrls: ['./sale-by-item.component.scss']
})
export class RetailSaleByItemComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidTextInput = new EventEmitter<ValidationMessage>();
  @Output() validateUser = new EventEmitter<boolean>();
  ItemFormGrp: UntypedFormGroup;
  ShiftFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  inActiveText = this.captions.IncludeInactiveUsers;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  selectedDropdownType = '';
  valuesSelected: any[];
  allCategoryGroups: any[];
  clerks: DropDownData[] = [];
  machineName: DropDownData[] = [];
  registers: any;
  categoryGroupSelected : any[] = [];
  showMachineDropDown: boolean = false;
  floatLabel: string;
  editPatchValue: any;
  isFromEdit = false;
  categoryComponentFilter: any;
  validSelection: boolean = true;
  validSelectionErrMsg:string;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, private dataService: ReportDataService, private propertyInfo: RetailPropertyInformation
    , private container: ViewContainerRef) { 
    this.floatLabel = this.localization.setFloatLabel;  
  }

   ngOnInit() {
    this.showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;    
    this.radioFilter = [{ id: 0, name: this.captions.DetailByOutlet }, { id: 1, name: this.captions.SummaryByOutlet }];
    this.ItemFormGrp = this.fb.group({
      filterItems: false,
      searchbyitem: '',
      includeInactiveusers: false,
      registers: new UntypedFormControl([this.defaultData]),
      clerks: new UntypedFormControl([this.defaultData]),
      machineName: new UntypedFormControl([this.defaultData]),
      itemFilter: 0,
      includeCategoryNotMapped: true
    });
    this.formReady.emit(this.ItemFormGrp);
    this.patchValues();
    if(!this.isFromEdit)
    {
    this.onLoad();
    }
    this.isValidText(true);
  }
  private async patchValues() {
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;

      if (this.localization.isFromJobScheduler && _parent.edit) {
        this.isFromEdit = true;
        let patchItem = this.editPatchValue;
        if (patchItem.ClerkIds != undefined) {
          this.dataService.GetAllUsersByPropertyId().then(res => {
            let arr = res.filter((item) => {
              return this.editPatchValue.ClerkIds.find(x => x == item.id);
            });
            this.clerks = arr;
          });
        }

        let allcategories: any[] = await this.dataService.GetAllCategories();
        this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();
        if (patchItem.CategoryIds != undefined && patchItem.CategoryIds?.length > 0) {
          let categoryGroupIds: any[] = allcategories.filter(x => this.editPatchValue.CategoryIds.includes(x.id)).map(x => x.categoryGroup);
          this.allCategoryGroups = this.allCategoryGroups.filter(x => categoryGroupIds.includes(x.id));
          this.categoryGroupSelected = this.allCategoryGroups;
        }
        if (patchItem.ReportGroupId != undefined && patchItem.ReportGroupId?.length > 0) {
          this.allCategoryGroups = this.allCategoryGroups.filter(x => patchItem.ReportGroupId.includes(x.id));
          this.categoryGroupSelected = this.allCategoryGroups;
        }
        if (patchItem.categoryIds == undefined || patchItem.categoryIds?.length == 0) {
          patchItem.CategoryIds = allcategories.filter(x => this.allCategoryGroups.map(x => x.id).includes(x.categoryGroup)).map(x => x.id);
        }

        this.categoryComponentFilter =
        {
          category: patchItem.CategoryIds,
          subCategory1: patchItem.SubCategory1Ids,
          subCategory2: patchItem.SubCategory2Ids,
          subCategory3: patchItem.SubCategory3Ids,
          subCategory4: patchItem.SubCategory4Ids,
          subCategory5: patchItem.SubCategory5Ids
        };

        if (patchItem.MachineNameIds != undefined && patchItem.MachineNameIds?.length > 0) {
          this.dataService.GetMachineName().then(res => {
            let arr = res.filter((item) => {
              return this.editPatchValue.MachineNameIds.find(x => x == item.id);
            });
            this.machineName = arr;
          });
        }
        if (patchItem.FilterItems != undefined) {
          this.ItemFormGrp.controls.filterItems.patchValue(patchItem.FilterItems);
        }
        if (patchItem.SelectedItemIds != undefined && patchItem.SelectedItemIds?.length > 0) {
          this.ItemFormGrp.controls.searchbyitem.patchValue(patchItem.SelectedItemIds.toString());
        }
      }
    }
  }
  ngOnDestroy() {
    this.isValidText(true);
  }

  private async onLoad() {
    this.clerks = await this.dataService.GetAllUsersByPropertyId();
    this.machineName = await this.dataService.GetMachineName();
    this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();
    this.business.FilterDataSource["allClerks"] = this.clerks;
    this.business.FilterDataSource["allCategoryGroups"] = this.allCategoryGroups;
    this.business.FilterDataSource["allMachineNames"] = this.machineName;
    this.clerks = this.business.toggleIncludeInactive(false, this.clerks);
  }

  toggleIncludeInactive(IncludeInactiveToo: boolean) {
    this.clerks = this.business.toggleIncludeInactive(IncludeInactiveToo, this.clerks);
    this.business.FilterDataSource["allClerks"] = this.clerks;
    this.ItemFormGrp.controls['clerks'].setValue([this.clerks]);
  }

  formInitialized(form: UntypedFormGroup) {
    this.ItemFormGrp.setControl('category', form);
  }

  changeToggleEvent(event) {
    if (event[0]) {
      this.ItemFormGrp = this.fb.group({
        filterItems: true,
        searchbyitem: '',
        registers: new UntypedFormControl([this.defaultData]),
        clerks: new UntypedFormControl([this.defaultData]),
        itemFilter: 0
      });
      this.isValidText(false);
    } else {
      this.ItemFormGrp = this.fb.group({
        filterItems: false,
        reportcategory: new UntypedFormControl([this.defaultData]),
        registers: new UntypedFormControl([this.defaultData]),
        clerks: new UntypedFormControl([this.defaultData]),
        category: new UntypedFormGroup({}),
        itemFilter: 0
      });
      this.isValidText(true);
    }
    this.formReady.emit(this.ItemFormGrp);
  }
 
  getFormControlValue(event) {
    if (event[0] === 'registers') {
      this.ItemFormGrp.setControl('registers', event[1]);
    } else if (event[0] === 'clerks') {
      this.ItemFormGrp.setControl('clerks', event[1]);
      } else if (event[0] === 'reportcategory') {
        this.ItemFormGrp.setControl('reportcategory', event[1]);
        }
        else if (event[0] === 'machineName') {
          this.ItemFormGrp.setControl('machineName', event[1]);
          }
  }

  isValidText(inputValue: boolean) {
    this.IsValidTextInput.emit({ dropDownName: "TextField", validity: inputValue });
  }

  getCategoryGroupValue(event) {
    this.categoryGroupSelected = [...event];
  }
  IsSelectionValid(validity: boolean) {
    this.validSelection = validity;
    this.validateUser.emit(validity);
    if (!validity) {
      const caption: any = this.localization.captions.reports.PleaseSelectAtleastOneOption;
      this.validSelectionErrMsg = this.localization.replacePlaceholders(caption, ["option"], [this.captions.User]);
    }}
}
