import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RguestCommunication } from '../common/rguest-communication';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { Localization } from '../../localization/localization';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { AppService } from '../../app-service';

@Injectable({ providedIn: 'root' })
export class JasperSynchronizeCommunication extends RguestCommunication {

  constructor(httpclient: HttpClient, localization: Localization, utilities: CommonUtilities, PropertyInfo: CommonPropertyInformation, appService: AppService) {
    super(environment["JasperReport"], httpclient, localization, utilities, PropertyInfo, appService);
  }
}

