import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "../shared/service/http-call.service";
import { RetailUtilities } from "../shared/utilities/retail-utilities";
import { RetailLocalization } from "../common/localization/retail-localization";
import { CCTerminal, BaseResponse } from "../retail.modals";
import { Host } from "../shared/globalsContant";
import { UntypedFormGroup } from "@angular/forms";



@Injectable()
export class AddTransferDataService {
    multipltosingleFG: UntypedFormGroup;
    singletomultipleFG: UntypedFormGroup;
    selectedRowTable: any;
    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private localization: RetailLocalization) { }


    public async getOutletsCCTerminal(): Promise<CCTerminal[]> {
        try {
            return await this.invokeCCTerminalCalls<CCTerminal[]>("GetOutletsByProperty", HttpMethod.Get);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage("Error", errMsg);
    }

    private async invokeCCTerminalCalls<T>(callDesc: string, callType: HttpMethod, body?: CCTerminal, uRIParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: Host.retailManagement,
            method: callType,
            body: body,
            uriParams: uRIParams
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }


}
