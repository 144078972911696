import { Component, Inject, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { partyNotesConfig } from './partynotes-config';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { Subject, Subscription } from 'rxjs';
import { LayoutDTO, PartyEmailSendBehavior, PartyNoteType, SettingsDTO, TableSuggestionMode } from '@app/shared/models/RestaurantDTO';
import { Utilities } from '@app/shared/utilities/utilities';
import { CacheService } from '@app/core/services/cache.service';
import { AppService } from '@app/app.service';
import { ITabOutputDetails } from '@app/settings/models/common.interface';
import { BookingBehavior, ComponentTypes, Labels, OperationResultState, PartyState, PartyType, ReservationEmailNotificationType, ReservationType, SlottingMode, UpdatedPartySlotType } from '@app/shared/constants/commonenums';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdatedPartySlotDTO, UpdatedReservationDTO, UpdatedStandbyPartyDTO } from '@app/shared/models/InputReservation';
import { ReservationDTO } from '@app/shared/models/InputContact';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { RestaurantPoliciesComponent } from '../restaurant-policies/restaurant-policies.component';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { ISubscription } from 'rxjs-compat/Subscription';
import { ActivityRatePlanRequest, GuestType } from '@app/shared/models/ActivityRatePlanRequestDTO';
import { FormChipService } from '@app/shared/dynamicform/components/form-chip/form-chip.service';
import { GetAddonNamePipe } from '@pipes/addon-name.pipe';
import * as eng_data from '@assets/i18n/en.json';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { FormTextAreaService } from '@app/shared/dynamicform/components/form-textarea/form-textarea.service';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { BookingTypeHandler } from '@app/shared/utilities/BookingTypeHandler';
import moment from 'moment';

@Component({
  selector: 'app-specialmeal-booking-summary',
  templateUrl: './specialmeal-booking-summary.component.html',
  styleUrls: ['./specialmeal-booking-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpecialmealBookingSummaryComponent extends partyNotesConfig implements OnInit {

  subscriptions: Subscription = new Subscription();
  settings: SettingsDTO;
  reservationData: any;
  bookingDetails: UpdatedReservationDTO;
  mealData: ReservationDTO;
  restaurantPolicyAvailable: Boolean = false;
  restaurantPolicy: any = [];
  confirmedAction: ISubscription;
  cancelAction: ISubscription;
  ratePlanDetails: any;
  operationCurrency: string;
  layoutSettings: LayoutDTO = {} as LayoutDTO;
  en_data = eng_data;
  skipValidation: boolean = false;
  bookingData: any;
  invokeRatePlan: Subject<any> = new Subject<any>();
  promoCodeEligibleProperty: number[] = [];

  constructor(@Inject(COMPONENTINPUT) private data, public dialogRef: MatDialogRef<SpecialmealBookingSummaryComponent>, protected cs: CacheService, protected as: AppService, private ps: PopupService, protected partyService: PartyService, protected ts: TranslateService, public dialog: MatDialog, private df: DashboardFunctions, protected formChipService: FormChipService, private getAddonName: GetAddonNamePipe, protected formTextAreaService: FormTextAreaService, private _api: ApiService, private bh: BookingTypeHandler) {
    super(cs, as, partyService, formChipService, formTextAreaService);
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this.settings = sett;
      this.operationCurrency = this.settings.General.OperationCurrency;
      this.categories = this.settings.Categories.filter(category => category.Text != "Reasons");
      if (data && this.as.OTASourceId.includes(data.PartySourceId)) {
        this.includeOthers = true;
      }
      if (data) {
        this.othersId = this.data.Notes.filter(x => x.Type == PartyNoteType.FreeFormNote).map(({ RelatedId }) => RelatedId);
      }
      this.partyNotes = Utilities.getRestaurantPredefinedPartyNotes(this.categories, this.includeOthers, this.othersId);
    }));
    this.subscriptions.add(this.cs.layout.subscribe(layout => {
      this.layoutSettings = layout;
    }));

  }

  ngOnInit(): void {
    this.mealData = this.data;
    this.bookingDetails = this.ps.reservationData.reservationRequest;
    this.skipValidation = this.settings.General.TableSuggestionMode == TableSuggestionMode.Never || this.settings.General.SlottingMode == SlottingMode.Manual;
    this.promoCodeEligibleProperty = [Utilities.RestaurantId()];
    this.bindRestaurantPolicy();
    this.bindGuestPartyNotes();

  }

  promoCodeApplied(offer) {
    if (this.partyService.ratePlanObject && this.partyService.ratePlanObject.RatePlan?.Reservations[0]?.Sessions[0]?.GuestPrices?.length)
      this.partyService.ratePlanObject.RatePlan.Reservations[0].Sessions[0].GuestPrices[0]['GuestId'] = this.partyService.reservationFormGroup.get('selectedGuest').value?.Id
    let requestPayload = {
      CartIds: null,
      "PromoCode": offer.OfferCode,
      "RatePlans": [{
        "RatePlan": this.partyService.ratePlanObject.RatePlan,
        "PropertyId": Utilities.RestaurantId()
      }],
      PartyId: this.data?.Id || null
    };

    this.subscriptions.add(this._api.calculateDiscount(requestPayload).subscribe(data => {
      if (data.State == OperationResultState.Success) {
        this.partyService.promoCodeApplied$.next(
          {
            'payload': data.Payload.RatePlans[0],
            'offer': offer

          })
      }
    }))
  }
  ngAfterViewInit() {
    this.formValueChanges();
    this.formChipsChange();
    this.textAreaSubscription();
    this.confirmedAction = this.ps.confirmedAction$.subscribe((tabOutput: any) => {
      if (tabOutput.fromComponent === ComponentTypes.specialMealBooking) {
        this.createSaveRequest();
      } else if (tabOutput == ComponentTypes.locationWaring) {
        this.skipValidation = true;
        this.createSaveRequest();
      }
    })
    this.cancelAction = this.ps.cancelledAction$.subscribe((tabOutput) => {
      this.cancelAction?.unsubscribe();
      if (tabOutput?.from == ComponentTypes.specialMealBooking) {
        if (this.partyService.slotLockId) {
          this.partyService.unlockSlot(this.partyService.slotLockId);
        }
        this.partyService.reservationFormGroup.get('selectedTime').setValue('');
      }      
    })
    this.subscriptions.add(this.partyService.tabChange$.subscribe(value => {
      let getAddOns;
      if (!this.partyService.isStandbySlotSelected) {
        if ((this.partyService.reservationType == ReservationType.OpenBooking || this.partyService.reservationType == ReservationType.Reservation) && this.partyService.selectedSlot$?.value?.length) {
          getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, this.partyService.selectedSpecialMealId, this.partyService.selectedSlot$?.value[0].DateTime);
        } else {
          getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, this.partyService.selectedSpecialMealId);
        }
      }
      if ((value === 1 && (!getAddOns || getAddOns?.length == 0 || getAddOns?.filter(addOn => addOn.IsVisible == true).length == 0)) || (value == 2 && getAddOns?.length > 0 && getAddOns?.filter(addOn => addOn.IsVisible == true).length > 0)) {
        if (this.cs.isIframeEnabled) {
          this.ps.tabsActionData[this.ps.tabsActionData.length - 1].actionButtonText = this.mealData ? 'UpdateCart' : 'AddToCart';
        }
        if (this.partyService.reservationFormGroup.value.selectedGuest) {
          let guest = this.partyService.reservationFormGroup.value.selectedGuest;
          guest.SearchType = guest.SearchType ? guest.SearchType : guest.MemberInfo?.MemberId ? 4 : 1;

          let payload = [];
          payload.push(this.bh.formValidationDTO(guest, this.partyService.reservationFormGroup.value.selectedTime.DateTime, this.partyService.reservationFormGroup.value.selectedTime.DateTime))
          if(guest?.SecondaryContactsList?.length){
           guest.SecondaryContactsList.forEach(secondaryGuest =>{
            payload.push(this.bh.formValidationDTO(secondaryGuest, this.partyService.reservationFormGroup.value.selectedTime.DateTime, this.partyService.reservationFormGroup.value.selectedTime.DateTime,null,true))
           }) 
          }
          this.bh.validateBookingTypeForGuest(payload);
          let subscription = this.bh.proceedValidation.subscribe(data =>{   
            subscription.unsubscribe();
             if(data.state == 'invalid'){
              this.ps.previousEvent$.next(true)
            }
          })

        }

        this.ps.tabsActions$.next(this.ps.tabsActionData);
        if (!this.partyService.isStandbySlotSelected && !this.mealData) {
          this.setLockTime();
        }
        if (!this.partyService.isStandbySlotSelected) {
          this.getRatePlanAmount();
          setTimeout(() => {
            if(this.mealData)
            this.invokeRatePlan.next(true)
          }, 100);
        }

      }
      this.bookingDetails = this.ps.reservationData.reservationRequest;
      this.reservationData = {
        specialMealName: this.bookingDetails.SpecialMealId ? this.settings.SpecialMeals.filter(element => element.Id === this.bookingDetails.SpecialMealId)[0]?.Name : null,
        partySize: this.bookingDetails.Size,
        reservationDate: this.ps.reservationData.selectedSlot?.DateTime || this.as.headerDate$.value,
        reservationTime: new Date(this.ps.reservationData.selectedSlot?.DateTime) || null,
        seatingArea: this.bookingDetails.SeatingAreaId ? this.settings.SeatingAreas.filter(element => element.Id === this.bookingDetails.SeatingAreaId)[0]?.Name : this.ts.instant('Any'),
        seatingType: this.bookingDetails.SeatingTypeId ? this.settings.SeatingTypes.filter(element => element.Id === this.bookingDetails.SeatingTypeId)[0]?.Description : this.ts.instant('Any'),
        tables: this.bookingDetails.TableIds?.length ? Utilities.getTableNamesFromStandaloneTables(this.bookingDetails.TableIds, this.layoutSettings.FloorPlans) : this.ts.instant('NA'),
        host: this.bookingDetails.HostId ? this.settings.Hosts.filter(element => element.Id === this.bookingDetails.HostId)[0]?.Name : '',
        pager: this.bookingDetails.PagerNumber || this.ts.instant('NA'),
        partyStatus: this.bookingDetails.StatusId ? this.partyService.cs.settings.value?.Statuses.filter(status => status.Id == this.bookingDetails.StatusId)[0]?.Name : this.ts.instant('NA'),
        conceirge: ''
      }

    }));
    if (this.mealData?.Notes?.length) {
      this.partyService.partyNotes = [];
      this.mapPartyNotes();
    }
  }
  ratePlanRequest: any;
  getRatePlanAmount() {

    let details = this.ps.reservationData.reservationRequest;
    var DateTime = Utilities.Date(new Date(this.partyService.reservationFormGroup?.value?.selectedTime.DateTime || this.ps.reservationData?.selectedSlot?.DateTime)).format('YYYY-MM-DDTHH:mm:ss')
    var selectedTime = _.cloneDeep(DateTime);
    var slots = {
      "LocalTime": selectedTime
    }
    var slot = [];
    slot.push(slots);
    this.ratePlanRequest = {
      Slots: slot,
      BookingSize: details.Size,
      Location: details.TableIds,
      CoverTypes: details.CoverTypes,
      ActivityId: details.SpecialMealId,
      FinancialEffectId: null,
      BookingBehavior: BookingBehavior.OpenBooking,
      FromDate: null,
      ToDate: null,
      BookingContacts: [],
      RatePlan: null,
      AddOns: this.df.getSelectedAddOns(),
      SelectedAddOns: this.df.getSelectedAddOns(),
      BookingId: details.Id
    }
    // var request = {
    //   Slots: slot,
    //   ActivityId: details.SpecialMealId || null,
    //   GuestTypes: this.coverTypeRatePlanMapping(details.CoverTypes),
    //   TableIds: details.TableIds,
    //   Size: details.Size,
    //   SessionSlot: null,
    //   TrackMembershipNumber: null,
    //   MemberType: null,
    //   IsMemberActive: null,
    //   BookingBehavior: null,
    //   SeatingTypeIds: null,
    //   SelectedAddOns: this.df.getSelectedAddOns()
    // } as ActivityRatePlanRequest;
    //   this.partyService.api.GetRatePlanCalculated(request, null).subscribe(data => {
    //     if (data?.Payload)
    //     this.bookingData = data.Payload;
    //  //     this.mapRatePlanData(data.Payload);
    //   })

    return this.ratePlanRequest;
  }
  showLocationWarning() {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant('noTablesAssignedValidation'), dialogTitle: 'confirm', showAlert: false
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '350px',
      data: {
        service: this.ps,
        title: 'deleteTitle',
        update: this.ts.instant('ok'),
        cancel: this.ts.instant('cancel'),
        componentDetails,
        from: ComponentTypes.locationWaring,
        back: false,
        standalone: true,
        showAlert: true
      }
    });
  }
  coverTypeRatePlanMapping(coverTypes) {
    if (coverTypes?.length) {
      return coverTypes.map(cover => { return { CoverTypeId: cover.CoverTypeId, covers: cover.Covers } }) as GuestType[]
    } else {
      return null;
    }
  }
  mapRatePlanData(details) {
    let sharedData = this.ps.reservationData;
    let ratePlan = details.RatePlanCalculations[0];
    let types = details.SpecialMealVariant ? details.SpecialMealVariant.GuestTypeVariants : ratePlan.RatePlanVariants?.GuestTypeVariant;
    let guestVariant = this.mapGuestTypeVariant(types, ratePlan, details.SpecialMealVariant ? true : false)
    let ratePlanVariant = {
      tableVariant: this.mapTableVariants(ratePlan.RatePlanVariants?.LocationVariant, ratePlan.RatePlanVariants?.GuestTypeVariant?.length > 0, ratePlan),
      guestTypeVariant: guestVariant,
      addonVariant: this.mapAddonVariants(ratePlan.AddonAttemptDetails),
      showSpecialMeal: !(details.SpecialMealVariant?.GuestTypeVariants?.length > 0 || guestVariant?.length),
      specialMealName: sharedData.selectedMeal?.Name || null,
      size: sharedData.reservationRequest.Size,
      SpecialMealAmount: details.SpecialMealVariant ? details.SpecialMealVariant?.SpecialMealAmount : 0,
      tax: (details.TotalTaxAmount || 0) + (details.TotalAddonTaxAmount || 0),
      serviceCharge: (details.TotalServiceChargeAmount || 0) + (details.TotalAddonServiceChargeAmount || 0),
      taxOnServiceCharge: (details.TotalTaxOnServiceChargeAmount || 0) + (details.TotalAddonTaxOnServiceChargeAmount || 0),
      total: details.TotalPartyAmount
    };
    this.ratePlanDetails = ratePlanVariant;
  }


  mapAddonVariants(addonVariant) {
    let addons = [];
    if (addonVariant) {
      addonVariant.map(addon => {
        let variant = {
          Name: this.getAddonName.transform(addon.AddonId, this.settings),
          OriginalVariant: addon.PricePerItem,
          TotalVariant: addon.TotalAmount,
          Count: addon.Quantity
        }
        addons.push(variant);
      })
    }
    return addons;
  }
  mapGuestTypeVariant(guestTypeVariant, ratePlan, skipRatePlan: boolean = false) {
    let guestTypes = [];
    if (guestTypeVariant) {
      guestTypeVariant.map(guestType => {
        if (guestType.Variant !== null && guestType.covers) {
          let guest = {
            Name: this.settings.CoverTypes.find(cover => cover.Id === guestType.CoverTypeId)?.Name,
            OriginalVariant: skipRatePlan ? guestType.OriginalVariant : (ratePlan.RatePlanVariants.OriginalBaseRate + guestType.OriginalVariant),
            TotalVariant: skipRatePlan ? (guestType.OriginalVariant * guestType.covers) : (ratePlan.RatePlanVariants.OriginalBaseRate + guestType.OriginalVariant) * guestType.covers,
            Count: guestType.covers
          }
          guestTypes.push(guest)
        }
      })
    }
    return guestTypes;
  }
  mapTableVariants(locationVariant, guestVariantAvailable: boolean, ratePlan) {
    let locations = [];
    if (locationVariant) {
      locationVariant.map(locationVariant => {
        let location = {
          Name: this.settings.SeatingTypes.find(s => s.Id === locationVariant.SeatingTypeId)?.Description,
          OriginalVariant: guestVariantAvailable ? locationVariant.OriginalVariant : (ratePlan.RatePlanVariants.OriginalBaseRate + locationVariant.OriginalVariant),
          TotalVariant: guestVariantAvailable ? (locationVariant.OriginalVariant * locationVariant.Count) : (ratePlan.RatePlanVariants.OriginalBaseRate + locationVariant.OriginalVariant) * locationVariant.Count,
          Count: locationVariant.Count
        }
        locations.push(location)
      })
    }
    return locations;
  }
  bindRestaurantPolicy() {
    if (this.settings.PropertySetting.length > 0 && (this.settings.PropertySetting[0].PreReservationMessage && this.settings.PropertySetting[0].PreReservationMessageTitle)) {
      this.restaurantPolicyAvailable = true;
      this.restaurantPolicy = this.settings.PropertySetting[0];
    }
  }
  setLockTime() {
    let data = this.ps.reservationData.selectedSlot;
    this.partyService.slotMode = this.settings.General.SlottingMode;
    const partySize = this.bookingDetails.Size;
    let SeatingAreaId = this.bookingDetails?.SeatingAreaId > 0 ? this.bookingDetails.SeatingAreaId : null;
    const lockIdToIgnore = this.partyService.slotLockId ? this.partyService.slotLockId : null;
    if (this.partyService.slotMode === SlottingMode.Auto) {
      this.subscriptions.add(this.partyService.getAutoSlotLockTime(data.DateTime, partySize, SeatingAreaId, lockIdToIgnore).subscribe((slot) => {
        this.partyService.slotLockId = slot.Payload.SlotLockIdDTO?.Id;
        this.partyService.slotUnlockTimerFunc();
        this.invokeRatePlan.next(true)
        return true;
      }));
    } else if (this.partyService.slotMode === SlottingMode.Manual) {
      this.subscriptions.add(this.partyService.getManualSlotTime(partySize, data.DateTime, data.Id, data.Type, lockIdToIgnore).subscribe((slot) => {
        this.partyService.slotLockId = slot.Payload.SlotLockIdDTO?.Id;
        this.partyService.slotUnlockTimerFunc();
        this.invokeRatePlan.next(true)
        return true;
      }));
    }
    return;
  }

  formValueChanges() {
    this.components.forEach((x) => {
      this.subscriptions.add(x.form.valueChanges.subscribe(data => {
        this.bindInternalPartyNotes(data);
      }));
    })
  }
  createSaveRequest() {
    let request = this.ps.reservationData.reservationRequest;
    if (!request.TableIds?.length && !this.skipValidation && this.settings.General.TableSuggestionMode !== TableSuggestionMode.SuggestAndAssign) {
      this.showLocationWarning();
      return;
    }
    let guestData = this.partyService.reservationFormGroup.value.selectedGuest || this.partyService.selectGuestSearch$.value;
    request.Contact = guestData;
    request.RoomNumber = guestData.RoomNumber || null;
    request.BookingContacts = guestData?.SecondaryContactsList;
    request.Contact.ConsentPolicyId = this.partyService.dataRetentionPolicyChecked ? this.partyService.consentPolicyId : null
    request.Notes = this.partyService.partyNotes;
    request.PageMethod = this.partyService.partyPageMethod;
    request.TableIds = request.TableIds || [];
    request.LanguageId = this.as.selectedLanguage;
    request.AddOns = this.df.getSelectedAddOns();
    request.PromoCode = this.partyService.promoCodeApplied$.value?.offer?.OfferCode;
    request.AdditionalBookingInfo = this.bh.validationErrors || [];
    const slot: UpdatedPartySlotDTO = {} as UpdatedPartySlotDTO;
    this.setSlot(request, slot, this.mealData);
    request.Slot = slot;
    if (request.CoverTypes?.length) {
      request.Size = _.sumBy(request.CoverTypes, 'Covers');
    }
    if (this.partyService.tryUndoNoShowReservation && this.mealData) {
      this.partyService.undoNoShowReservation(request.Id, request.Slot, this.dialogRef);
    } else if (this.partyService.isStandbySlotSelected || this.partyService.standbyConversion) {
      if (this.mealData) {
        if (this.partyService.standbyConversion) {
          request.Type = PartyType.Reservation;
          this.convertStandbyToReservation(request, this.ps.reservationData.selectedSlot?.DateTime, request.Id, this.dialogRef);
        } else if (this.mealData?.Type == PartyType.Reservation && this.partyService.isStandbySlotSelected) {
          this.df.showReservationToStandbyConfirmationForAdvance(request, this.mealData, this.dialogRef);
        }
        else {
          request.Type = PartyType.StandBy;
          request['WishedTime'] = this.ps.reservationData.selectedSlot?.DateTime;
          this.partyService.updateStandbyParty(request, this.dialogRef);
        }
      } else {
        request.Type = PartyType.StandBy;
        request['WishedTime'] = this.ps.reservationData.selectedSlot?.DateTime;
        this.createStandbyReservation(request);
      }
    }
    else {
      if (this.cs.isIframeEnabled && !this.mealData) {
        this.partyService.addBookingToCart(request, true, this.ps.reservationData.selectedSlot?.DateTime, this.dialogRef, false, null)
      } else if (this.cs.isIframeEnabled && this.mealData) {
        this.partyService.updateCart(request, this.partyService.editCartId, true, this.ps.reservationData.selectedSlot?.DateTime, this.dialogRef, null);
      } else if (!this.cs.isIframeEnabled && this.mealData) {
        this.updateReservation(request);
      } else {
        this.createReservation(request, null, this.dialogRef);
      }
    }
    if (this.partyService.reservationDialogRef) {
      this.partyService.reservationDialogRef.close();
      this.partyService.reservationDialogRef = null;
    }
  }


  showReservationToStandbyConfirmation(request, editData: any, dialogRef: any) {
    const popUpMessage = [{
      confirmationMessage: `${this.ts.instant('reservationToStandbyConfirmationMsg')}`,
      dialogTitle: this.ts.instant('reservationToStandbyConfirmation'),
      showAlert: false
    }];

    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'active', popUpMessage);
    let convertDialog = this.df.openCustomPopup(componentInfo, ComponentTypes.standby,
      '450px', '300px', false, popUpMessage[0].dialogTitle, 'confirm', 'cancel', true);
    let convertConfSubscription = this.ps.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.standby) {
        request.Type = PartyType.StandBy;
        this.partyService.convertReservationToStandby(request, request.Id, dialogRef);
      }
    });
    this.subscriptions.add(convertDialog.afterClosed().subscribe(() => {
      if (convertConfSubscription) {
        convertConfSubscription.unsubscribe();
      }
    }));
  }

  convertStandbyToReservation(request, seatingTime, partyId?, dialogRef?) {
    this.subscriptions.add(this.partyService.convertDiningStandbyToReservation(request, partyId).subscribe(data => {
      if (data) {
        this.confirmedAction?.unsubscribe();
        dialogRef?.close();
        data.notDisableOkButton = true;
        const slotMode = this.cs.settings.value.General.SlottingMode;
        if (this.partyService.slotLockId) {
          if (slotMode === SlottingMode.Auto) {
            this.subscriptions.add(this.partyService.autoSlotUnlock(this.partyService.slotLockId).subscribe((slot) => {
              this.partyService.slotLockId = null;
              this.partyService.openPurchaseForm(data, seatingTime);
              this.partyService.reservationFormGroup.reset();
            }));
          } else if (slotMode === SlottingMode.Manual) {
            this.subscriptions.add(this.partyService.manualSlotUnlock(this.partyService.slotLockId).subscribe((slot) => {
              this.partyService.slotLockId = null;
              this.partyService.openPurchaseForm(data, seatingTime);
              this.partyService.reservationFormGroup.reset();
            }));
          }
        } else {
          this.partyService.openPurchaseForm(data, seatingTime);
        }
      }
    }
    ));
  }
  createReservation(request, seatingTime, dialogRef?: MatDialogRef<any>) {
    this.subscriptions.add(this.partyService.createDiningReservation(request).subscribe(data => {
      if (data) {
        if (this.partyService.slotLockId) {
          this.partyService.slotLockId = null;
        }
        clearTimeout(this.partyService.slotUnlockTimer);
        this.partyService.openPurchaseForm(data, seatingTime);
        this.confirmedAction?.unsubscribe();
        this.dialogRef?.close();
      }
    }))
  }
  updateStandbyParty(request) {
    this.subscriptions.add(this.partyService.updateDiningStandbyParty(request).subscribe(data => {
      this.confirmedAction?.unsubscribe();
      this.dialogRef?.close();
      this.partyService.openPurchaseForm(data, null);
    }, err => { }));
  }
  createStandbyReservation(request: any) {
    this.subscriptions.add(this.partyService.createDiningStandbyParty(request).subscribe(data => {
      this.confirmedAction?.unsubscribe();
      this.dialogRef?.close();
    }, err => { }));

  }
  updateReservation(request, reservationDate?) {

    this.subscriptions.add(this.partyService.financialEffectForUpdatedReservation(request).subscribe(data => {
      if (data.ValidationMessages.length > 0) {
        this.partyService.openConfirmationDialog(data, data.ValidationMessages[0].Message, reservationDate);
      }
      const specialMeals = this.cs.settings.value.SpecialMeals.filter(x => x.Id == request.SpecialMealId)[0];
      let isDeferredPaymentMode = false;
      let isWebReservation = false;
      let includenoshow = false;
      let specialMealFee = false;
      let needConfirmationBeforeUpdaing = false;
      if (request && request.PartySourceId) {
        isWebReservation = this.partyService.isWebReservation(request.PartySourceId);
      }
      if (specialMeals != null && specialMeals.IsPrepaymentRequired && !specialMeals.ChargeOnBooking) {
        isDeferredPaymentMode = true;
        includenoshow = false;
        specialMealFee = true;
      }

      if (data && data.State === OperationResultState.Success) {
        const updateConfirmationUpdateReservationPrice = ((data.Payload.PaymentTarget == 1 || data.Payload.PaymentTarget == 3 || data.Payload.PaymentTarget == 4) && data.Payload.TotalAmount != 0) ? data.Payload.TotalAmount : null;
        const PaymentAmount = data.Payload.PaymentAmount != null ? data.Payload.PaymentAmount : null;
        const RefundOption = data.Payload;
        const showUpdatedPaymentPopUp = false;

        let textLabel = '';
        switch (data.Payload.PartyPaymentType) {

          case 0: // NotSupported
            textLabel = this.ts.instant('NotSupported') + this.ts.instant('ReservationBetweenLocationPrePaidNotSupported');
            return;
          case 1: // NoPaymentsInvolved
            needConfirmationBeforeUpdaing = false;
            // No messaging in this case
            break;
          case 2: // NoEffect
            needConfirmationBeforeUpdaing = true;
            textLabel = this.ts.instant('NoChargeOrRefundForThisReservation');
            break;
          case 3:
          case 8: // PartialCharge //Done

            needConfirmationBeforeUpdaing = true;
            if (data.Payload.IsCreditCardNeeded) {
              textLabel = this.ts.instant('reservationUpdatedWithSeatingTypeRequiredPayment') + this.cs.operationCurrency + data.Payload.PaymentAmount.toFixed(2) + '. ' + this.ts.instant('enterCreditCard');
            }
            else {
              textLabel = this.ts.instant('CardUsedForTheOriginalBookingWillCharged') + this.cs.operationCurrency + data.Payload.PaymentAmount.toFixed(2) + '.';
            }
            break;
          case 9: // PartialRefund //Done
          case 14:
          case 5:
            needConfirmationBeforeUpdaing = true;
            textLabel = this.ts.instant('Refundrequestof') + this.cs.operationCurrency + PaymentAmount.toFixed(2) + this.ts.instant('hasbeenprocessed') + '. ';
            //textLabel = this.en_data.CardUsedForOriginalReservationWillBeRefunded + this.cs.operationCurrency + PaymentAmount.toFixed(2) + '. ' + this.en_data.SeveralBusinessDays;
            break;
          case 10: // NoShowFeeUpdated //Done
            needConfirmationBeforeUpdaing = false;
            break;
          case 11: // Authorize //Done
            textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
            break;
          case 12: // Authorize updated //Done
            needConfirmationBeforeUpdaing = true;
            textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
            break;
        }

        if (needConfirmationBeforeUpdaing) {
          this.partyService.showUpdatedPaymentConfirmationPopUp(request, textLabel, reservationDate, this.dialogRef, ComponentTypes.reservation, RefundOption);
        } else {
          this.subscriptions.add(this.partyService.updateDiningReservation(request).subscribe(data => {
            this.partyService.IsUpdateReservation = true;
            if (data.Payload && data.Payload.EmailAddress && this.cs.settings.value.General.HostUpdateEmailSendBehavior == PartyEmailSendBehavior.Prompt) {
              this.partyService.showEmailNotification(data, ReservationEmailNotificationType.Updated);
            }
            this.partyService.openConfirmationDialog(data, this.ts.instant(Labels[Labels.reservationupdatedconfirmationtext]), reservationDate, null, ComponentTypes.PartyCreation);
            if (data.Payload && data.Payload.PurchaseForm) {
              Utilities.openPurchaseForm(data.Payload.PurchaseForm.Url);
            }
            this.dialogRef?.close();
          }));
        }
      }
    })
    );
  }
  setSlot(request: any, slot: UpdatedPartySlotDTO, editData?: ReservationDTO) {
    const slottingMode = this.cs.settings.value.General.SlottingMode;
    const slotType = slottingMode == SlottingMode.Auto ? UpdatedPartySlotType.AutoSlot : UpdatedPartySlotType.ManualActualSlot;
    let editSlotorNewSlotId = (editData && editData.SlotId) ? editData.SlotId : null;
    if (editSlotorNewSlotId) {
      if (this.partyService.reservationFormGroup.value.selectedTime.Id == editSlotorNewSlotId) {
        editSlotorNewSlotId = editSlotorNewSlotId;
        slot.Type = slotType;
      }
      else {
        editSlotorNewSlotId = this.ps.reservationData.selectedSlot.Id;
        slot.Type = this.ps.reservationData.selectedSlot.Type;
      }
    }
    else {
      editSlotorNewSlotId = this.ps.reservationData.selectedSlot.Id;
      slot.Type = this.ps.reservationData.selectedSlot.Type;
    }
    // for Past Reservation
    if (editData && editData.State == PartyState.Left && editSlotorNewSlotId) {
      editSlotorNewSlotId = editSlotorNewSlotId;
    }
    slot.Time = this.ps.reservationData.selectedSlot.DateTime;
    slot.IsTimeInUtc = false;
    slot.Id = editSlotorNewSlotId;
    slot.LockId = this.partyService.slotLockId ? this.partyService.slotLockId : null;
    console.log(slot);
  }


  ShowRestPolicyDetails() {
    const componentDetails: ComponentDetails = {
      componentName: RestaurantPoliciesComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupTitle: this.restaurantPolicy.PreReservationMessageTitle
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '60%',
      width: '40%',
      data: {
        title: this.restaurantPolicy.PreReservationMessageTitle,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.restaurantPolicies,
        back: false,
        standalone: true,
        showAction: true
      },
    });
  }
  ngOnDestroy() {
    if (this.partyService.reservationFormGroup?.get('selectedTime')?.value) {
      this.partyService.reservationFormGroup.get('selectedTime').setValue('');
    }
    this.confirmedAction?.unsubscribe();
    this.cancelAction?.unsubscribe();
    this.subscriptions?.unsubscribe();
    this.partyService.partyNotes = [];
    this.partyService.promoCodeApplied$.next(null);
  }
} 
