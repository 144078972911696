import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { buttonTypes, ComponentTypes } from '@constants/commonenums';
import { EmailAddress } from '@constants/globalConstants';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { PopupService } from '@popup-module/popup.service';

@Component({
  selector: 'app-exception-popup',
  templateUrl: './exception-popup.component.html',
  styleUrls: ['./exception-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExceptionPopupComponent implements OnInit {
  sendBtn: ButtonValue;
  cancelBtn: ButtonValue;
  copyBtn: ButtonValue;
  @Input() data: any;
  public Error: any;
  public ErrorMessage = [];
  public WarningMessage = [];
  public dialogTitle: string;
  overallMsg = "";
  public confirmationPopup: boolean = false;
  constructor(private ps: PopupService , private cs: CacheService) { }

  ngOnInit() {
    this.sendBtn = {
      label: 'guestBookSendEmail',
      type: buttonTypes.actionSecondary,
      disbaledproperity: false,
      customclass: 'app-exception__sendmail'
    };

    this.cancelBtn = {
      label: 'ok',
      type: buttonTypes.actionPrimary,
      disbaledproperity: false,
      customclass: 'app-exception__okbutton'
    };

    this.copyBtn = {
      label: 'copyerror',
      type: buttonTypes.actionPrimary,
      disbaledproperity: false,
      customclass: 'app-exception__copyerror'
    };

    this.dialogTitle = this.data[0].dialogTitle;
    this.Error = this.data[0].ErrorDetails;
    this.confirmationPopup = this.data[0].ConfirmationPopup;
    this.overallMsg = this.Error.ErrorMessage;
    if (this.Error.Error) {
      this.ErrorMessage = this.Error.Error;
      this.ErrorMessage.forEach(val => {
        this.overallMsg += val.msg;
      })

    }
    if (this.Error.Warning) {
      this.WarningMessage = this.Error.Warning;
      this.overallMsg += "(Warning)";
      this.WarningMessage.forEach(val => {
        this.overallMsg += val.msg;
      })
    }



  }



  msgsbody: string;
  mailText: string = "";
  mailSubject: string = "Error in " + sessionStorage.getItem(`restaurantName_${Utilities.getSessionStorageType()}`);


  sendEmailMsg() {
    this.msgsbody = "";
    this.msgsbody = "Hi Team,    \n";
    this.msgsbody = this.msgsbody + "\n" + this.overallMsg;
    this.mailSubject = sessionStorage.getItem(`restaurantName_${Utilities.getSessionStorageType()}`) ? this.mailSubject : "Error";
    this.mailText = "mailto:" + EmailAddress + "?subject=" + this.mailSubject + " &body=" + this.msgsbody;
    window.location.href = this.mailText;
  }

  copyMsg() {
    var aux = document.createElement("input");
    aux.setAttribute("value", this.overallMsg);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
  }
  closepopup() {
    this.ps.confirmedAction$.next(ComponentTypes.resultprocess);
  }
}
