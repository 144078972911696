<ng-container>
    <app-dialog-header [title]="dataInput?.headername" (closeDialog)="close($event)"></app-dialog-header>
</ng-container>

<div class="create-outlet-wrapper">
    <div class="ag_modal--form-height">
        <div class="ag_form_wrapper--md">
            <form class="CreditCardTerminal-formwrapper" [formGroup]="form" autocomplete="off">
              <section class="ag_display--flex">
                <div class="width-420px">
                  <div>
                    <mat-form-field class="ag_form-control--md" [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_createRetailOutlets_outletName'" matInput [minlength]="minInputLength" [placeholder]="captions.OutletName" formControlName="OutletName">
                        <mat-error *ngIf="form.controls['OutletName'].hasError('required')">
                            {{captions.MissingOutletName}}
                        </mat-error>
                        <mat-error
                        *ngIf="form.controls['OutletName'].errors?.minlength">
                          {{captions.Atleast2charactersneedtobeentered}}
                      </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field  class="float-left ag_form-control--md" [floatLabel]="floatLabel">
                        <mat-select [attr.automationId]="'Dd_createRetailOutlets_terminalId'" [placeholder]="captions.TerminalId" formControlName="terminalId"
                            (selectionChange)="terminalChange($event)">
                            <mat-option [value]=''></mat-option>
                            <mat-option *ngFor='let x of terminals' [value]='x.id'>{{x.id}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="ag_form-control--md" [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_createRetailOutlets_profitCenter'"  min="1" maxLength="24" inputtype="nonnegative,nodecimal" matInput [placeholder]="captions.profitCentreId"
                                            formControlName="profitCenter">
                        <mat-error *ngIf="form.controls['profitCenter'].hasError('required')">
                            {{captions.MissingOutletName}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="ag_mb--3">
                    <div><label class="LW12 pr-2 active-label label-color form-label-normal">{{captions.AutoPrintReceipts}}</label></div>
                    <app-retail-toggle-switch [attr.automationId]="'Txt_createRetailOutlets_autoPrint'" formControlName="AutoPrintReceipts"
                        (changeToggleEvent)="checkValidState($event)" class="toggle-switch"></app-retail-toggle-switch>
                </div>
                <div class="ag_mb--3">
                    <div><label class="LW12 pr-2 active-label label-color form-label-normal">{{captions.Active}}</label></div>
                    <app-retail-toggle-switch [attr.automationId]="'Txt_createRetailOutlets_active'" formControlName="active"
                         class="toggle-switch"></app-retail-toggle-switch>
                </div>
                <div *ngIf= "ShowDefaultToggle" class="ag_mb--3">
                    <div><label class="LW12 pr-2 active-label label-color form-label-normal">{{captions.lbl_isDefault}}</label></div>
                    <app-retail-toggle-switch [attr.automationId]="'Txt_createRetailOutlets_active'" formControlName="default"
                         class="toggle-switch"></app-retail-toggle-switch>
                </div>
                </div>



                <div *ngIf ="productId == golfProductId || productId == retailProductId" class="width-420px">
                    <div class="ag_mb--3">
                      <div><label class="LW12 pr-2 active-label label-color form-label-normal">{{captions.includeOutletAddressInReceipt}}</label></div>
                      <app-retail-toggle-switch [attr.automationId]="'Txt_createRetailOutlets_toggle'"
                      formControlName="includeOutletAddress" [name]="false"
                      class="toggle-switch"></app-retail-toggle-switch>

                    </div>


                  <div *ngIf="this.form.controls.includeOutletAddress.value">
                    <h4 class="golf-form--headers"></h4>
                    <div class="position--relative">
                      <app-agys-address [attr.automationId]="'Txt_createRetailOutlet_address'" [inputs]="addressInput" [values]="addressValue" [isViewOnly]="isViewOnly">
                      </app-agys-address>

                    </div>
                    <div>
                      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
                        <input type="text" matInput [attr.automationId]="'Txt_createRetailOutlet_postalCode'" [placeholder]="captions.PostalCode" name="Postalcode" golfinputtype="nospecailchar"
                          formControlName="postalCode" [maxlength]="25">
                      </mat-form-field>


                      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
                        <input type="text" matInput [attr.automationId]="'Txt_createRetailOutlet_city'" [placeholder]="captions.city" name="City" formControlName="city"
                          golfinputtype="textonly,nospecailchar,noprespace" [maxlength]="100">
                      </mat-form-field>


                    </div>
                    <div>
                      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
                        <input type="text" matInput [attr.automationId]="'Txt_createRetailOutlet_state'" [placeholder]="captions.state" name="State" formControlName="state"
                          golfinputtype="textonly,nospecailchar,noprespace" [maxlength]="75">
                      </mat-form-field>


                      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
                               <input matInput
                               formControlName="country"
                               [attr.automationId]="'Txt_createRetailOutlet_country'"
                               [matAutocomplete]="auto"
                               type="text"
                               [placeholder]="captions.country"
                               golfinputtype="textonly,nospecailchar,noprespace"
                               [maxlength]="100"
                               autocomplete="off"
                               name="Country">
                        <!-- <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor="let country of filteredCountries | async" [value]="country.CountryName" [matTooltip]="country.CountryName">
                            <span>{{country.CountryName}}</span>
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="!propertyInfo.get('country').value && propertyInfo.get('country').hasError('required')">
                          {{captions.missingCountryName}}
                        </mat-error>
                        <mat-error *ngIf="propertyInfo.get('country').value && propertyInfo.get('country').hasError('invalid')">
                          {{captions.invalidCountryName}}
                        </mat-error> -->
                      </mat-form-field>

                      <h4 class="golf-form--headers">{{captions.contactDetails}}</h4>

                      <div class="position--relative">
                        <app-ag-phone-number [attr.automationId]="'Txt_createRetailOutlets_phoneNo'"  [inputs]="phoneInputs" [values]="phoneInfo" [isViewOnly]="false" [isCountryCodeRequired]="true">
                        </app-ag-phone-number>
                      </div>
                    </div>
                  </div>
                  </div>
              </section>




            </form>
        </div>
    </div>
    <div class="create-outlet-footer ag_footer--actions">
        <app-button [attr.automationId]="'Btn_createRetailOutlets_action'" [buttontype]="actionButton" (valueChange)='onAction($event)'></app-button>
        <app-button [attr.automationId]="'Btn_createRetailOutlets_cancel'" [buttontype]="cancelButton" class="ag-ml-2" (valueChange)='onCancel($event)'></app-button>
    </div>
</div>
