<section class="commissionTemplate newVersionWrapper retailCommisionWrapper">
    <ng-scrollbar >
        <div class="commissionGrid h-100">
            <button [attr.automationId]="'Btn_retailCommissionSetup_createNewTemplate'" class="body-bgcolor float-right mat-button mr-30 text-capital whitecolor set-button-style" [ngClass]="{'button_invalid': IsViewOnly}"
                (click)="openDialog('NEW')">{{captions.CreateNewTemplate}}</button>
            <app-retail-table [attr.automationId]="'Tbl_retailCommissionSetup_retailCommission'" [options]="tableoptions"
                (deleteEvt)="DeleteRecords($event)"  (editEvt)="EditTemplate($event)" ></app-retail-table>
        </div>
    </ng-scrollbar>
</section>
