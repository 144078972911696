import { Component, QueryList, ViewChildren } from "@angular/core";
import { AppService } from "@app/app.service";
import { CacheService } from "@app/core/services/cache.service";
import { FormChipService } from "@app/shared/dynamicform/components/form-chip/form-chip.service";
import { FormTextAreaService } from "@app/shared/dynamicform/components/form-textarea/form-textarea.service";
import { DynamicFormComponent } from "@app/shared/dynamicform/dynamic-form/dynamic-form.component";
import { FieldConfig } from "@app/shared/dynamicform/models/field-config.interface";
import { PartyDTO, PartyNoteDTO, PartyNoteType } from "@app/shared/models/InputContact";
import { Category } from "@app/shared/models/RestaurantDTO";
import { PartyNotes } from "@app/shared/models/global.interface";
import { PartyService } from "@app/shared/services/party.service";
import { get } from "lodash";
import { Subscription } from "rxjs";

@Component({
    template: ''
})

export class partyNotesConfig {
    config: FieldConfig[];
    mealData: PartyDTO;
    categories: Category[];
    includeOthers: boolean = false;
    othersId: number[] = [];
    partyNotes: PartyNotes[] = [];
    customPartyNotes: any[] = [];
    selectedPartyPreferenceTags: any[] = [];
    subscriptions: Subscription = new Subscription();
    @ViewChildren('form') components: QueryList<DynamicFormComponent>;

    constructor(protected cs: CacheService, protected as: AppService, protected partyService: PartyService, protected formChipService: FormChipService,protected formTextAreaService: FormTextAreaService) { }

    bindGuestPartyNotes() {
        let config = [];
        this.partyNotes.forEach((partyNote, index) => {
            const tag = {
                type: 'chip',
                name: `preferredPartyTags${index}`,
                inputType: 'text',
                label: partyNote.CategoryName,
                class: 'guest-view__preferred-tags-edit',
                options: this.getOptions(partyNote.partyNotes, partyNote.Color),
                multipleChip: true,
                multipleOther: true
            }
            config.push(tag);
        })
        if (get(this.mealData, ['Notes'], []).length) {
            this.customPartyNotes = this.mealData.Notes.filter(note => !note.RelatedId && note.Type == PartyNoteType.FreeFormNote);
            if (this.customPartyNotes && this.customPartyNotes.length) {
                const tag = {
                    type: 'chip',
                    name: `partyfreeTags`,
                    inputType: 'text',
                    label: 'freeNoteText',
                    class: 'guest-view__free-tags-edit',
                    options: this.getFreeTextOptions(),
                    multipleChip: true,
                    multipleOther: true
                }
                config.unshift(tag);
            }
        }
        this.config = config;
    }
    mapPartyNotes() {
        const preferredTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('preferredPartyTags'));
        this.mealData.Notes.forEach((note) => {
          preferredTags.forEach((fieldConfig) => {
            if (note.Type == PartyNoteType.FreeFormNote || note.Type == PartyNoteType.PredefinedNote || note.Type == PartyNoteType.SpecialRequest) {
              const filteredNotes = fieldConfig.options.find(data => data.Id === note.RelatedId);
              if (filteredNotes) {
                filteredNotes.setSelected = true;
                filteredNotes.noteId = note.Id;
                if (filteredNotes.Name.includes('Others')) {
                  let otherNotesName = `otherNotes${filteredNotes.Id}`;
                  if (filteredNotes.setSelected) {
                    const otherNotes: FieldConfig = {
                      name: otherNotesName + '$multinotes' + note.Id,
                      type: 'textarea',
                      inputType: 'text',
                      label: 'notes',
                      showHint: true,
                      class: 'specialmeal-booking-summary__party-other-tags',
                      charLength: 1000,
                      value: note.Text,
                      showRemove: true
                    };
                    const preferredTagsIndex = this.config.findIndex(preferenceConfig => preferenceConfig.name == fieldConfig.name);
                    this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
                  }
                }
                this.partyService.partyNotes.push(note);
              }
            }
          })
        });
        this.config = [...this.config];
      }
    
    getFreeTextOptions() {
        let freeTextOptions = [];
        let freeNotes = this.mealData.Notes.filter(notes => !notes.RelatedId);
        freeNotes.forEach((notes) => {
            freeTextOptions.push({
                Id: notes.Id,
                Name: notes.Text,
                Icon: 'None',
                Value: ''
            })
        })
        return freeTextOptions;
    }

    getOptions(partyNotes, categoryColor): any {
        let configOptios = [];
        partyNotes.forEach((notes) => {
            configOptios.push({
                Id: notes.Id,
                Name: notes.Text,
                Icon: notes.Icon,
                Value: '',
                color: categoryColor
            });
        })
        return configOptios;
    }
    bindInternalPartyNotes(data: any) {
        for (let preferenceProperty in data) {
            if (preferenceProperty.includes('otherNotes')) {
                const otherNotesInConfig = this.config.find(configProp => configProp.name == preferenceProperty);
                if (otherNotesInConfig && !otherNotesInConfig.isHidden) {
                    const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
                    partyNotes.Text = data[preferenceProperty] || '';
                    let mulNoteArr = preferenceProperty.split('$multinotes');
                    let addnlId = null;
                    if (mulNoteArr.length > 1) {
                        addnlId = mulNoteArr[1];
                    }
                    const preferencePropertyId = preferenceProperty.match(/(\d+)/);
                    if (preferencePropertyId) {
                        partyNotes.RelatedId = Number(preferencePropertyId[0]);
                        partyNotes.addnlId = addnlId;
                        const preferredOtherTags = this.partyService.partyNotes.find((note) => note.RelatedId === partyNotes.RelatedId && note.addnlId == partyNotes.addnlId);
                        if (preferredOtherTags) {
                            partyNotes.Id = preferredOtherTags.Id ? preferredOtherTags.Id : null;
                            partyNotes.Type = PartyNoteType.FreeFormNote;
                        } else {
                            partyNotes.Id = null;
                            partyNotes.Type = PartyNoteType.FreeFormNote;
                        }
                    }
                    this.partyService.partyNotes = [
                        ...this.partyService.partyNotes.filter(note => !note.addnlId && note.RelatedId != partyNotes.RelatedId),
                        ...this.partyService.partyNotes.filter(note => note.addnlId && !(note.RelatedId == partyNotes.RelatedId && note.addnlId == partyNotes.addnlId))
                    ];
                    this.partyService.partyNotes.push({ ...partyNotes });
                }
            }
        }
    }
    formChipsChange() {
        this.subscriptions.add(this.formChipService.formChipChange$.subscribe((selectedChips) => {
            if (selectedChips.name === 'partyfreeTags') {
              selectedChips.data.forEach((tags) => {
                if (!tags.setSelected) {
                  const index = this.partyService.partyNotes.findIndex((note) => note.Id === tags.Id);
                  if (index > -1) {
                    this.partyService.partyNotes = this.partyService.partyNotes.filter(note => note.Id != tags.Id);
                  }
                } else {
                  const index = this.partyService.partyNotes.findIndex((note) => note.Id === tags.Id);
                  if (index == -1) {
                    const freeTextNotes: PartyNoteDTO = {} as PartyNoteDTO;
                    freeTextNotes.Id = tags.Id;
                    freeTextNotes.Text = tags.Name;
                    freeTextNotes.Type = PartyNoteType.FreeFormNote;
                    this.partyService.partyNotes.push(freeTextNotes);
                  }
                }
              });
            } else if (this.config.filter((fieldConfig) => fieldConfig.name == selectedChips.name).length > 0) {
              const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
              this.selectedPartyPreferenceTags = selectedChips.data;
              const preferredTagsIndex = this.config.findIndex(fieldConfig => fieldConfig.name == selectedChips.name);
              const otherTags = selectedChips.data.filter(chip => chip.Name == 'Others');
              if (otherTags && otherTags.length > 0) {
                let otherNotesName = `otherNotes${otherTags[0].Id}`;
                let isOtherNoteAvailable = this.config.filter((config) => config.name.includes(otherNotesName)).length;
                const otherNotesControl = this.components.last.config.filter((config) => config.name.includes(otherNotesName));
                if ((selectedChips.isOthersAdd || !isOtherNoteAvailable) && otherTags.length > 0 && otherTags[0].setSelected) {
                  let currDate = new Date();
                  const otherNotes: FieldConfig = {
                    name: otherNotesName + '$multinotes' + currDate.getTime(),
                    type: 'textarea',
                    inputType: 'text',
                    label: 'notes',
                    showHint: true,
                    class: 'guest-data__party-other-tags w-100 mb-3',
                    charLength: 1000,
                    isHidden: false,
                    showRemove: true
                  }
                  this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
                } else if (isOtherNoteAvailable) {
                  if (otherTags[0].setSelected) {
                    otherNotesControl.forEach(control => {
                      control.isHidden = false;
                    });
                    const otherNotes = this.partyService.partyNotes.find((note) => note.RelatedId == otherTags[0].Id);
                    if (!otherNotes) {
                      const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
                      let editPartyNote = null;
                      if (this.mealData) {
                        editPartyNote = this.mealData.Notes.find(note => note.RelatedId == otherTags[0].Id);
                        if (editPartyNote) {
                          this.partyService.partyNotes.push(editPartyNote);
                        }
                      } else {
                        partyNotes.Id = null;
                        partyNotes.Type = PartyNoteType.FreeFormNote;
                        partyNotes.RelatedId = otherTags[0].Id;
                        partyNotes.Text = this.components.last.form.value[otherNotesName];
                        this.partyService.partyNotes.push(partyNotes);
                      }
                    }
                    // this.partyService.partyNotes = this.partyService.partyNotes.filter((note) => note.RelatedId != otherTags[0].Id)
                  } else {
                    otherNotesControl.forEach(control => {
                      control.isHidden = true;
                    });
                    this.partyService.partyNotes = this.partyService.partyNotes.filter((note) => note.RelatedId != otherTags[0].Id)
                  }
                  // this.config.splice(preferredTagsIndex + 1, 1);
      
                }
              }
              this.config = [...this.config];
              this.selectedPartyPreferenceTags.forEach((_partyNotes) => {
                if (_partyNotes.Name !== 'Others') {
                  const preferredData = this.partyService.partyNotes.filter((note) => note.RelatedId === _partyNotes.Id)[0];
                  if (_partyNotes.setSelected && !preferredData) {
                    partyNotes.RelatedId = _partyNotes.Id;
                    partyNotes.Text = _partyNotes.Name;
                    partyNotes.Id = _partyNotes.noteId ? _partyNotes.noteId : null;
                    partyNotes.Type = PartyNoteType.PredefinedNote;
                    this.partyService.partyNotes.push(partyNotes);
                  } else if (!_partyNotes.setSelected) {
                    const index = this.partyService.partyNotes.findIndex((note) => note.RelatedId === _partyNotes.Id);
                    if (index > -1) {
                      this.partyService.partyNotes.splice(index, 1);
                    }
                  }
                }
              });
            }
            this.partyService.reservationFormGroup.markAsDirty();
            this.partyService.reservationFormGroup.updateValueAndValidity();
           // this.updateFormValidity();
          }));

    }

    textAreaSubscription(){
        this.subscriptions.add(this.formTextAreaService.formTextAreaChange$.subscribe(data => {
          let index = this.config.findIndex(itm => itm.name === data.name)
          if(index != -1){
            this.config.splice(index, 1);
            let [relatedTag, uniqId] = data.name.split('$multinotes');
            let length = this.config.filter(itm => itm.name.includes(relatedTag)).length;
            if(!length && this.config[index - 1]){
              let othersIndex = this.config[index - 1].options.findIndex(option => option.Name === 'Others')
              this.config[index - 1].options[othersIndex].setSelected = false;
            }
            let partyNoteIndex = this.partyService.partyNotes.findIndex(note => note.Id == uniqId || (note.RelatedId == relatedTag.match(/(\d+)/)[0] && note.addnlId == uniqId));
            this.partyService.partyNotes.splice(partyNoteIndex, 1);
            this.partyService.reservationFormGroup.markAsDirty();
            this.partyService.reservationFormGroup.updateValueAndValidity();
          }
        }))
      }
}