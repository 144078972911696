<div class="chip-control" [ngClass]="config.class" [hidden]="config.isHidden">
  <label class="chip-control__label seat-tertiary-text" *ngIf="config?.options?.length > 0"
    for="">{{config.label | translate}}</label>
  <mat-chip-list class="chip-control__chip-view" selectable>
    <mat-chip
      [ngStyle]="{'border-color': chipItem.color, 'color': chipItem.setSelected ? '' : chipItem.color,
          'background-color': chipItem.setSelected ? chipItem.color : '' }"
      *ngFor="let chipItem of config.options;let i=index" [title]="chipItem.Value" [disabled]="chipItem.isDisabled"
      class="chip-control__chips" (click)="selectedChip(i)"
      [ngClass]="{'chip-control__selected-chip':chipItem.setSelected, 'chip-control__others-selected': config.multipleOther && chipItem.setSelected && 'OTHERS' === (chipItem.Name | uppercase)}">
      <div matTooltipClass="tooltip-class" title="{{chipItem.toolTipData}}" *ngIf="chipItem.Icon !== 'None'">
        <i [ngStyle]="{'color': chipItem.setSelected ? '' : chipItem.color}"
          class="chip-control__chip-icon icon-{{chipItem.Icon ? chipItem.Icon : 'Default'}}"></i>&nbsp;
        <span>{{chipItem.Label || chipItem.Name}}</span>
        <span class="additional-notes" *ngIf="config.multipleOther && chipItem.setSelected && 'OTHERS' === (chipItem.Name | uppercase)" [ngStyle]="{'backgroundColor': chipItem.color}" (click)="additionalNotesClick($event)">+</span>
      </div>
      <div matTooltipClass="tooltip-class" title="{{chipItem.toolTipData}}" *ngIf="chipItem.Icon == 'None'">
        <span>{{chipItem.Label || chipItem.Name}}</span>
      </div>
    </mat-chip>
  </mat-chip-list>
</div>