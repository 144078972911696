
<div class="tabDetails">
<div class="tabDetails__view tabDetails__user-visible float-left" [ngClass]="{'tabDetails__changeWidthClass':guestBookService.isGuestDetailsVisible.getValue()}">
  <app-tabs [tabsConfig]="guestBookTabs" (tabIndexChange)="tabChange($event)"></app-tabs>
</div>

<!-- View particular guest details -->
<div class="viewUserIntial float-left" [ngClass]="{'viewUserIntial__viewUser':guestBookService.isGuestDetailsVisible.getValue()}" >
  <app-guestdetails *ngIf="guestBookService.isGuestDetailsVisible.getValue()" [contactDetails]=""></app-guestdetails>
</div>
</div>
