import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { ComponentTypes } from '@app/shared/constants/commonenums';
import { PartyPaymentResult } from '@app/shared/models/InputContact';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import { isBuffer } from 'lodash';
import { CurrencyDecimalFormater } from '@app/shared/pipes/currency-decimal.pipe';


@Component({
  selector: 'app-additional-rate-payment-iframe',
  templateUrl: './additional-rate-payment-iframe.component.html',
  styleUrls: ['./additional-rate-payment-iframe.component.scss']
})
export class AdditionalRatePaymentIframeComponent implements OnInit {
  @Input() data:any;
  additionalChargeObj:any;
  fromComponent:ComponentTypes;
  operationCurrency:string='';
  SplitPayment=false;
  SplitPaymentMessage = '';
  partyPaymentId:number=null; // in case of split payment we need the party payment Id to update the same transaction row.
  constructor(public partyService:PartyService, public ts:TranslateService ) { 
    this.fromComponent = ComponentTypes.AdditionalCharge;

  }

  ngOnInit(): void {
    this.additionalChargeObj = this.data;
    this.fromComponent = ComponentTypes.AdditionalCharge;
    this.operationCurrency = this.partyService.cs.settings.value.General.OperationCurrency;
  }

  additionalPaymentTransaction(dto){
    dto.PartyPaymentId = this.partyPaymentId;
    this.partyService.AdditionalChargeParty(this.additionalChargeObj.PartyId,true, dto).subscribe(response => {
      if(response.Payload && response.Payload.PendingAmount > 0){
        this.SplitPaymentMessage = "Your current payment of " + this.operationCurrency + response.Payload.PaymentAmount.toFixed(2) + " is processed successfully! Please proceed to pay pending amount " + this.operationCurrency + response.Payload.PendingAmount.toFixed(2) + "."
        this.additionalChargeObj.IframeUrl = response.Payload.IframeUrl;
        //this.additionalChargeObj.AdditionalCharge = response.Payload.TotalAmount.toFixed(2);
        this.partyPaymentId = response.Payload.PartyPaymentId;
      }
      else if (response.Payload && response.Payload.PaymentResult && response.Payload.PaymentResult == PartyPaymentResult.Success) {
        this.partyService.additionalChargePaymentIframeDialogRef ? this.partyService.additionalChargePaymentIframeDialogRef.close() : '';
        this.partyService.additionalChargePaymentIframeDialogRef = null;
        this.partyService.openConfirmationDialog(response, "An additional charge of " + this.operationCurrency + response.Payload.TotalAmount.toFixed(2) + " has been successfully charged for the party", null);
      }
    });
  }

}
