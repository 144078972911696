
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Inject, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Localization } from "src/app/common/localization/localization";
@Component({
  selector: 'app-alert-message-popup',
  templateUrl: './alert-message-popup.component.html',
  styleUrls: ['./alert-message-popup.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CommonAlertMessagePopupComponent implements OnInit {
  noButton: boolean = false;
  IDTHiddenField:string;
  dataform:UntypedFormGroup;
  @ViewChild('cardfield') cardfield: ElementRef;
  inputSubscription$: any;
  cardEntryModeGif: string;
  spinnerClass: string;
  floatLabel: string;
  captions: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialogRef<CommonAlertMessagePopupComponent>,private localization: Localization) {

    this.noButton = data.noButton ? data.noButton : false;
    this.floatLabel = this.localization.setFloatLabel;
    this.captions = this.localization.captions;
   }

  ngOnInit() {
    this.dataform= new UntypedFormGroup({
      carddata:new UntypedFormControl('')
      })
      this.inputSubscription$ = this.dataform.valueChanges.pipe(debounceTime(1000))
      .subscribe(val => {
        this.dialog.close(val.carddata);
      });
    this.cardEntryModeGif = this.data && this.data.isManualEntry ? './assets/images/CardKeyIn.gif' : './assets/images/Cardpayment.gif';
    this.spinnerClass = this.data && this.data.isManualEntry ? 'spinner-image-key-in' : 'spinner-image';
  }

  ngAfterViewInit() {
    if (this.data.isHiddenFieldRequired &&  this.cardfield) {
      this.cardfield.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.inputSubscription$.unsubscribe();
  }

  DialogClose = (result) => {
    this.dialog.close(result);
  }
}
