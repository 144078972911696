<div class="retailCustomDataTable retail-CustomDataTable-theme newVersionWrapper" id="table_{{SelectedSettingId}}">
    <div class="page-header w-100 d-table" [ngClass]="SelectedSettingId == 8 || SelectedSettingId == 1 || SelectedSettingId == 9? 'removeHeight':''">
    <div  class="float-left d-inline-flex align-items-center h-100 retail-data">
        <mat-form-field class="selectOutlet mr-3" *ngIf="showOutlet" [floatLabel]="floatLabel">
          <mat-select [attr.automationId]="'Dd_shopRetailTable_selectOutlet'" id="selectOutlet" #selectOutlet [disabled]="showOptionsArray?.restrictDataFilterChange" [(ngModel)]="selectedOutlet" (selectionChange)="outletChange($event)" placeholder="{{captions.shop.Outlet}}" >
              <mat-option *ngFor="let outlet of outletArray" [value]="outlet.id" >
                  {{ outlet.value }}
              </mat-option>
          </mat-select>
        </mat-form-field>

          <div class="mr-3 selectOutlet" *ngIf="SelectedSettingId == 2">
              <mat-form-field class="d-inline-block datepicker-form-field date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
                <mat-label>{{captions.shop.fromDate}}</mat-label>
                <input [attr.automationId]="'Txt_shopRetailTable_fromDate'" matInput #fromDateModel="ngModel" [matDatepicker]="picker" dateRestricter [placeholder]="placeholderFormat" [(ngModel)]="fromDate"  (dateChange)="onDateChange('startDate',$event)"
                  autocomplete="off">
                <mat-datepicker #picker></mat-datepicker>
                <i [attr.automationId]="'Icn_shopRetailTable_fromDatePicker'" aria-hidden="true" matSuffix class="icon-Calender cursor fs-xlarge position-relative d-inline-block" (click)="picker.open()"></i>
                <mat-error *ngIf="fromDateModel.hasError('matDatepickerParse')">{{captions.common.dateFormat}}</mat-error>   
                <mat-error *ngIf="fromDateModel.hasError('matDatepickerMin')">{{captions.common.minimum}} {{captions.shop.fromDate}}</mat-error>
                <mat-error *ngIf="fromDateModel.hasError('matDatepickerMax')">{{captions.common.maximum}} {{captions.shop.fromDate}}</mat-error>
              </mat-form-field>

            </div>
             <div  *ngIf="SelectedSettingId == 3 || SelectedSettingId == 4" class="pl-2 pr-2 h-50 static-date">
              <label class="LW14 textColorGray d-block form-label-normal label">{{captions.shop.date}}</label>
              <span class="LW14 blckClr">{{openTransactionDate}}</span>
            </div>
            <div *ngIf="SelectedSettingId == 6 && !showOptionsArray?.restrictDataFilterChange" class="transDate">
              <mat-form-field class="d-inline-block datepicker-form-field date-picker-width" [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_shopRetailTable_transactionDate'" matInput  dateRestricter
                  [matDatepicker]="picker" 
                  [max]="ProperyInfo.CurrentDate" 
                  placeholder="{{captions.shop.date}}"
                  [disabled]="showOptionsArray?.restrictDataFilterChange" 
                  [(ngModel)]="transactionDate"
                  (dateChange)="onDateChange('transactionDate',$event)" autocomplete="off">
                <mat-datepicker #picker></mat-datepicker>
                <i aria-hidden="true" [attr.automationId]="'Icn_shopRetailTable_transactionDatePicker'" matSuffix class="icon-Calender cursor fs-xlarge position-relative d-inline-block" (click)="picker.open()"></i>
              </mat-form-field>
            </div>

            <div class="mr-3 selectOutlet" *ngIf="SelectedSettingId == 2">
                <mat-form-field class="d-inline-block datepicker-form-field date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
                  <mat-label>{{captions.shop.toDate}}</mat-label>
                  <input [attr.automationId]="'Txt_shopRetailTable_toDate'" matInput #toDateModel="ngModel" dateRestricter [matDatepicker]="topicker" [(ngModel)]="toDate" [placeholder]="placeholderFormat"  (dateChange)="onDateChange('endDate',$event)"
                    autocomplete="off" [min]="fromDate"> 
                  <mat-datepicker #topicker></mat-datepicker>
                  <i [attr.automationId]="'Icn_shopRetailTable_toDatePicker'" aria-hidden="true" matSuffix class="icon-Calender cursor fs-xlarge position-relative d-inline-block" (click)="topicker.open()"></i>
                  <mat-error *ngIf="toDateModel.hasError('matDatepickerParse')">{{captions.common.dateFormat}}</mat-error>   
                  <mat-error *ngIf="toDateModel.hasError('matDatepickerMin')">{{captions.common.minimum}} {{captions.shop.toDate}}</mat-error>
                  <mat-error *ngIf="toDateModel.hasError('matDatepickerMax')">{{captions.common.maximum}} {{captions.shop.toDate}}</mat-error>
                </mat-form-field>

              </div>
      </div>
    <div *ngIf="(options ? options[0].Searchable : false) && SelectedSettingId != 2  && !showAdvancedSearch" class="p10  d-flex align-items-center  h-100 search-container">
      
      <input [attr.automationId]="'Txt_shopRetailTable_searchText'" matInput 
      #tableInput
        autocomplete="off" 
        [(ngModel)]="searchText" 
        type="text" 
        placeholder="{{captions.common.SearchBy}} {{PlaceHoldertext}}"
        value="" 
        (keypress)="currentPage=1" 
        class="searchbox search-input-{{SelectedSettingId}}"
        />

      <i [attr.automationId]="'Icn_shopRetailTable_searchText'" aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="search-icon" (click)="searchText='';"></i>
      
    </div>

    
  
  </div>
    <app-reprint-advanced-search *ngIf="showAdvancedSearch" [serviceID]="serviceID" [outlet]="this.selectedOutlet" 
    [isLoading]="isAdvancedSearchLoading" (search)="onAdvancedSearchClick($event)" (close)="onAdvancedSearchClose()">      
    </app-reprint-advanced-search>
    <div class="fixed-table-container {{tableDivClass}}" [ngClass]="{'noSort': bodyArray.length < 1,'showOutlets':showOutlet}" #fixedTable>
      <label *ngIf="functionalities[ShowNoOfRecordInGrid]" class="ag_w--100 total-count-label text mr-2">
        {{totalMsg}} </label>
      <div class="fixed-table-container-inner">
        <div class="w-100 position-absolute grid-table" id="SPACustomTableShop">
          <ng-scrollbar  >
 
            <table aria-describedby="" class="retailtable">
              <thead>
                <tr id="SPAHeaderRow">
                 
                  <th  scope='col' *ngIf="RadiobuttonOption" class="cursorDefault radio-button"></th>
                  <th  scope='col' *ngFor="let hdrCnt of hdrArray;let hi=index" id="header{{hi}}" [ngClass]="hdrCnt.alignType=='right' ? 'text-right' : hdrCnt.alignType=='center' ? 'text-center':''"
                    (click)="sortingFunc(hdrCnt.jsonkey,hi,'Frmtable', hdrCnt.sortcolumn, hdrCnt.sortcolumndatatype)">
                    <div class="th-inner" [class.LWB14]="selectedDefaultHeader == hdrCnt.jsonkey">
                      {{hdrCnt.title}}
                      <span class="pl-1">
                        <i  aria-hidden="true" id="sortArrow{{hi}}" *ngIf="hdrCnt.jsonkey != '' && hdrCnt.jsonkey != 'appointmentId' && (bodyArray | filterPipe: searchText : hdrkeyArray).length != 0" [ngStyle]="{'opacity':selectedDefaultHeader == hdrCnt.jsonkey ? '1' : '0.3' }"
                        [ngClass]="selectedDefaultHeader == hdrCnt.jsonkey && orderType == 'asc' ? 'icon-filled_up_arrow position-absolute deg0 IC6': selectedDefaultHeader == hdrCnt.jsonkey && orderType =='desc' ?
                        'icon-filled_up_arrow deg180 position-absolute IC6': 'icon-sortArrow sort-icon'">
                        </i>
                      </span>
                    </div>
                  </th>
                  <th  scope='col' style="width: 25px;cursor: default;" *ngIf="SelectedSettingId != GridType.retail"></th>
                  
                  <th  scope='col' *ngIf="(EditMoreOption || SelectRow) && (SelectedSettingId != GridType.retail) && (bodyArray | filterPipe: searchText : hdrkeyArray).length != 0" class="pr-3"
                    style="cursor: default;">
                    
                  </th>
                </tr>
              </thead>
              <tbody class=" h-100" flex>
                <tr [ngClass]="((NewerData[0] === bodyCnt) || (SelectedData === bodyCnt)) ? 'highlight' : ''" (click)="rowClick($event, bodyCnt, bi)"
                  id="{{bodyCnt.id}}" *ngFor="let bodyCnt of (bodyArray | filterPipe: searchText : hdrkeyArray | sortPipe : orderType : selectedDefaultHeader : overriddenSortColumn : sortColumnDataType);let bi=index;trackBy: trackByFn">

                  <td *ngIf="RadiobuttonOption">
                    <mat-radio-group attr.automationId='Rdo_shopRetailTable_radioButtonGroup{{bi}}' class="example-radio-group golf__radio" name="radioButtonGrp">
                      <mat-radio-button [title]="bodyCnt['radioToolTip']" [disabled]="bodyCnt.radioDisabled"
                      [ngClass]="bodyCnt.radioDisabled ? 'button_invalid' : 'button_valid'" class="example-radio-button golf--form-radio-button" [checked]="bodyCnt.radioSelected === true" [value]="bodyCnt.radioSelected" (click)="radioClick(bodyCnt,bi)">
                      </mat-radio-button>
                    </mat-radio-group>
                  </td>
 
                  <td *ngFor="let hdrCnt of hdrArray;let hi=index" [ngClass]="[hdrCnt.alignType=='right' ? 'text-right pr26' : 'hello',hdrCnt.alignType=='center' ? 'text-center' : '']">

                      <span attr.automationId='Lbl_shopRetailTable_tooltipElement{{bi}}' [matTooltipDisabled] = "bodyCnt[hdrCnt.jsonkey] ? hdrCnt.jsonkey == 'inactive' || hdrCnt.jsonkey == 'active'|| (hdrCnt.type && hdrCnt.type == 'toggle') || bodyCnt[hdrCnt.jsonkey].length < 40 : true" matTooltip="{{bodyCnt[hdrCnt.jsonkey]}}" matTooltipClass="tooltipClass">
                   
                        <div class="toogle-switch-big d-inline-flex ml-2" *ngIf="hdrCnt.jsonkey == 'inactive' || hdrCnt.jsonkey == 'active'">
                            <label class="switch">
                              <input attr.automationId='Txt_shopRetailTable_toggleDisplayText{{bi}}' class="switch-input" type="checkbox" [name]="toggleDisplayText" (change)="sliderChange(bodyCnt[hdrCnt.jsonkey],bodyCnt)" [ngModel]="bodyCnt[hdrCnt.jsonkey]"/>
                              <span class="switch-label " [attr.data-on]="localization.captions.common.Yes" [attr.data-off]="localization.captions.common.No"></span>
                              <span class="switch-handle"></span>
                            </label>
                          </div>
                    <span *ngIf="hdrCnt.jsonkey == 'services'">
                      <span *ngFor="let service of bodyCnt[hdrCnt.jsonkey]; let isLast=index">
                        <span *ngIf="service.selectService == true">{{service.serviceName}}{{isLast == bodyCnt[hdrCnt.jsonkey].length -1 ? '' : ', '}}</span>
                      </span>
                    </span>
                    
                    <span *ngIf="!CheckInnerBool(bodyCnt[hdrCnt.jsonkey])">
                      <span *ngIf="hdrCnt.datatype && hdrCnt.datatype == 'decimal' && !IfBoolean(bodyCnt[hdrCnt.jsonkey])">
                        {{(bodyCnt[hdrCnt.jsonkey] | loaddecimalvalue)}}
                      </span>
                      <span *ngIf="hdrCnt.datatype && hdrCnt.datatype == 'money' && !IfBoolean(bodyCnt[hdrCnt.jsonkey])">
                        {{SelectedSettingId == RetailTransactions.modifypostedcommission && bodyCnt[hdrCnt.jsonkey] == 0 ? '-' : (bodyCnt[hdrCnt.jsonkey] | Currency)}}
                      </span>
                      <span *ngIf="hdrCnt.datatype && hdrCnt.datatype == 'percentage' && !IfBoolean(bodyCnt[hdrCnt.jsonkey])">
                        {{SelectedSettingId == RetailTransactions.modifypostedcommission && bodyCnt[hdrCnt.jsonkey] == 0 ? '-' : bodyCnt[hdrCnt.jsonkey]}}{{SelectedSettingId == RetailTransactions.modifypostedcommission ? '' : '%'}}
                      </span>
                      
                      <span *ngIf="!hdrCnt.datatype && !IfBoolean(bodyCnt[hdrCnt.jsonkey]) && hdrCnt.jsonkey != 'printQty' && !hdrCnt.type && hdrCnt.jsonkey != 'appointmentId'">
                        {{SetColumnGridValue| setcolumnvalue :bodyCnt:hdrCnt.jsonkey}}
                        <label class="label-green-round" *ngIf="hdrCnt.jsonkey == 'name' && bodyCnt.isActive == true"></label>
                      </span>
                      <span *ngIf="hdrCnt.type == 'clickable'" class="anchor-like" (click)="lblclick(bodyCnt, hi, hdrCnt.type)">
                        {{bodyCnt[hdrCnt.jsonkey]}}
                      </span>
                    </span>
                  </span>
                  <span *ngIf="hdrCnt.jsonkey === 'cardNumber'" class="revenue-pop">
                    <span class="">{{bodyCnt[hdrCnt.jsonkey]?bodyCnt[hdrCnt.jsonkey][0]:''}}
                      <span class="plus-color" *ngIf="bodyCnt[hdrCnt.jsonkey].length>1"
                       [matMenuTriggerFor]="menuOpt"> {{'+' +  (bodyCnt[hdrCnt.jsonkey].length - 1) + captions.setting.More }}</span>
                    </span>
                    <!-- <popover-content #creditcard class="transc-pop" placement="bottom" class="ag_w--100"
                    [closeOnClickOutside]="true" [closeOnMouseOutside]="false">
                    <div >
                        <h6>{{captions.shop.AdditionalCardsUsed}}</h6>
                        <span>{{bodyCnt[hdrCnt.jsonkey].slice(1)}}</span>
                    </div>
                   </popover-content> -->

                   <mat-menu #menuOpt="matMenu">
                    <div class="card-popover">
                      <h6>{{captions.shop.AdditionalCardsUsed}}</h6>
                      <div class="card-popover-height">
                        <ng-container *ngFor="let x of bodyCnt[hdrCnt.jsonkey];let f=first;">
                          <span *ngIf="!f" class="d-block pb-1">{{x}}</span>  
                        </ng-container>
                      </div>
                    </div>
                  </mat-menu>

                  </span>
                  </td>
                  <td style="width: 25px" *ngIf="SelectedSettingId != GridType.retail"></td>
                  
                  <td *ngIf="EditMoreOption && (SelectedSettingId != GridType.retail)" class="pr-3 noellipsis">
                    <i attr.automationId='Icn_shopRetailTable_openTransactions{{bi}}' aria-hidden="true" class="icon-More rotate cursor" *ngIf="SelectedSettingId == RetailTransactions.opentransactions" title="{{this.captions.shop.More}}" [matMenuTriggerFor]="menuTransaction" [matMenuTriggerData]="{enableClose: bodyCnt.enableCloseTransaction}" (click)="setCurrentRowInfo(bodyCnt)"></i>
                    <i attr.automationId='Icn_shopRetailTable_correctVoid{{bi}}' aria-hidden="true" class="icon-More rotate cursor" *ngIf="SelectedSettingId == RetailTransactions.correctvoid  && !bodyCnt.IsActivitesInvolved" title="{{this.captions.shop.More}}" [matMenuTriggerFor]="bodyCnt.IsActivitesInvolved ? null : menuCorrectVoid" (click)="setCurrentRowInfo(bodyCnt)"></i>
                    <i  attr.automationId='Icn_shopRetailTable_correctVoid{{bi}}' aria-hidden="true" class="icon-More rotate cursor-default" *ngIf="SelectedSettingId == RetailTransactions.correctvoid && bodyCnt.IsActivitesInvolved" matTooltip="This transaction cannot be void or corrected. Please cancel the activity associated with this transaction."></i>
                    <i attr.automationId='Icn_shopRetailTable_shopMore{{bi}}' aria-hidden="true" class="icon-More rotate cursor"  *ngIf="SelectedSettingId == 2" [ngClass]="IsViewOnly? 'button_invalid' : ''" title="{{this.captions.shop.More}}" [matMenuTriggerFor]="reprintTicket" [matMenuTriggerData]="{guestId: bodyCnt.guestId}" (click)="setCurrentRowInfo(bodyCnt)"></i>
                    <i attr.automationId='Icn_shopRetailTable_shopMoreReturnWithTicke{{bi}}' aria-hidden="true" class="icon-More rotate cursor" *ngIf="SelectedSettingId == RetailTransactions.returnwithticket" [ngClass]="{'button_invalid':!bodyCnt.isTransactionDetailAvailable}" title="{{this.captions.shop.More}}" [matMenuTriggerFor]="menuReturnWithTicket" (click)="setCurrentRowInfo(bodyCnt)"></i>

                  </td>
                </tr>
                <tr *ngIf="(bodyArray | filterPipe: searchText : hdrkeyArray).length == 0 && !isDataLoading">
                  <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1" class="text-center"> {{captions.common.NoDataFound}} </td>
                </tr>
                <tr *ngIf="isDataLoading">
                  <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1" class="text-center">{{captions.common.loadingData}}</td>
                </tr>
              </tbody>
            </table>
           
          </ng-scrollbar>
        </div>
      </div>
    </div>
     
      <mat-menu #reprintTicket="matMenu" class="matMenu">
        <ng-template matMenuContent let-guestId="guestId">
          <button [attr.automationId]="'Btn_shopRetailTable_reprintOption'" mat-menu-item (click)="editRow($event, RetailTransactions.reprintticket ,options[0].ServiceId, RetailTransactions.reprintticket)">
            <span class="LW14">{{captions.shop.reprint}}</span>
          </button>
          <!-- <button [disabled]="!guestId" *ngIf="currentproductId == retailProduct" mat-menu-item (click)="editRow($event,'notify',options[0].ServiceId, captions.shop.notify)" >
            <span class="LW14">{{ captions.shop.notify }}</span>
          </button> -->
          <button [attr.automationId]="'Btn_shopRetailTable_emailOnDemandProduct'" *ngIf="isEmailOnDemandProduct" mat-menu-item (click)="openEmailReceipt();">
            <span class="LW14">{{ captions.shop.lbl_emailReceipt }}</span>
          </button>
        </ng-template>
      </mat-menu>
  
      <mat-menu #menuTransaction="matMenu" class="matMenu" xPosition="before">
        <ng-template matMenuContent let-enableClose="enableClose">
          <button attr.automationId='Btn_shopRetailTable_openTransaction{{i}}' mat-menu-item (click)="editRow($event,'new',options[0].ServiceId,transaction)" [disabled]="IsViewOnly"
          *ngFor="let transaction of openTransactions; let i=index">
            <span class="LW14">{{transaction}}</span>
          </button>
          <button [attr.automationId]="'Btn_shopRetailTable_openTransactionClose'" *ngIf="!useRetailInterface" mat-menu-item (click)="editRow($event,'new',options[0].ServiceId, captions.shop.Close)" >
            <span class="LW14">{{ captions.common.Close }}</span>
          </button>
        </ng-template>
      </mat-menu>
      <!-- CORRECT/VOID -->
      <mat-menu #menuCorrectVoid="matMenu" class="matMenu" xPosition="before">
        <button attr.automationId='Btn_shopRetailTable_correctVoid{{i}}' mat-menu-item (click)="editRow($event,'new',options[0].ServiceId,option)"
        *ngFor="let option of correctVoid; let i=index" >
          <span class="LW14">{{option}}</span>
        </button>
      </mat-menu>
      <!-- RETURN WITH TICKET -->
      <mat-menu #menuReturnWithTicket="matMenu" class="matMenu" xPosition="before">
        <button attr.automationId='Btn_shopRetailTable_returnWithTicket{{i}}' mat-menu-item (click)="editRow($event,'new',options[0].ServiceId,ticket)"
        *ngFor="let ticket of returnWithTicket; let i=index" >
          <span class="LW14">{{ticket}}</span>
        </button>
      </mat-menu>  
  </div>
