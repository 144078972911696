<div class="h-100 pb-3">
<ng-scrollbar>
<div class="userprofile__profile-pic" *ngIf="data[0].type == AuditableEntityType.Party">
  <!--<div *ngIf="logItems[0].EntityType == AuditableEntityType.Party">-->
  <!-- Guest profile image -->
  <div *ngIf="contactDetails.FirstName || contactDetails.LastName">
    <div class="float-left position-relative userprofile__profile-image" *ngIf="profileImage != ''">
      <img [src]="profileImage" alt="profile_pic" class="userprofile__user-image">
    </div>
    <div *ngIf="profileImage == ''" class="guest-initial" [ngStyle]="{'background-color': '' | randomColor}">{{contactDetails | guestNameFormatter}}</div>
  </div>
  <div class="float-left userprofile__details">
    <!-- Guest name & Party Type -->
    <div class="userprofile__first-row">
      <span class="float-left mb-0 userprofile__client-name" *ngIf="contactDetails.FirstName || contactDetails.LastName" title="{{contactDetails.FirstName}} {{contactDetails.LastName}}">
        {{contactDetails.FirstName}}
        {{contactDetails.LastName}}
      </span>
      <span class="float-left mb-0 rs-Roboto-Light-24 userprofile__client-name" *ngIf="!contactDetails.FirstName && !contactDetails.LastName">
        {{'unnamed' | translate}}
      </span>
    </div>
    <div class="userprofile__partyType rs-Roboto-Light-16" *ngIf="party.Type == PartyType.Reservation">{{'reservationcirclecomponenttitle' | translate }}</div>
    <div class="userprofile__partyType rs-Roboto-Light-16" *ngIf="party.Type == PartyType.WalkIn">{{'walkin' | translate }}</div>
  </div>
  <div class="float-left">
    <span *ngIf="showParty;else showGroup" class="userprofile__partyType rs-Roboto-Light-16">
      <i class="icon-icon seat-icon-color"></i>
      {{'popUpSize' | translate }} {{party.Size}}
    </span>
    <ng-template #showGroup>
      <span class="userprofile__partyType rs-Roboto-Light-16">
        {{'bookingSize' | translate }}: {{party.Size}}
      </span>
    </ng-template>
    <div class="userprofile__partyType rs-Roboto-Light-16" *ngIf="isDining && party.Type == PartyType.Reservation">{{party.ReservedFor | formatSettingsDate: cs.settings.value.General.DateFormat}} / {{party.ReservedFor | localizedDate: 'LTS'}}</div>
    <div class="userprofile__partyType rs-Roboto-Light-16" *ngIf="party.Type == PartyType.WalkIn">{{party.ArrivedAt | formatSettingsDate: cs.settings.value.General.DateFormat}} / {{ party.ArrivedAt | localizedDate: 'LTS'}}</div>
    <div class="userprofile__partyType rs-Roboto-Light-16" *ngIf="!isDining && party.Type == PartyType.Reservation">{{sessionReservedFor | formatSettingsDate: cs.settings.value.General.DateFormat}} <span *ngIf="displayTime">/ {{sessionReservedFor | localizedDate: 'LTS'}}</span></div>
  </div>
  <!--</div>-->
</div>
<div class="header" *ngIf="data[0].type != AuditableEntityType.Party && data[0].type != AuditableEntityType.Activity && data[0].type != AuditableEntityType.ActivityCustomizations && data[0].type != AuditableEntityType.AdvancedSettings">
  <div *ngIf="data[0].type == AuditableEntityType.TableBlockingRule">
    {{'tableblockingrulefor' | translate}}
    <span *ngIf="rule.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay">{{ rule.Frequency.Date | formatSettingsDate: cs.settings.value.General.DateFormat }}</span>
    <span *ngIf="rule.Frequency.Type == TableBlockingRuleFrequencyType.EveryWeekDay">{{'every' | translate}} {{ days[rule.Frequency.DayOfWeek] }}</span>
    <span *ngIf="rule.Frequency.Type == TableBlockingRuleFrequencyType.EveryDay">{{'everyday' | translate }}</span>
  </div>
  <div *ngIf="data[0].type == AuditableEntityType.ManualSlot">
    {{'manualslotfor' | translate}} {{slot.DateTime | formatSettingsDate: cs.settings.value.General.DateFormat}} / {{slot.DateTime | localizedDate: 'LTS'}}
  </div>
  <div *ngIf="data[0].type == AuditableEntityType.Shift">
    {{'allShiftFor' | translate}} {{appService.headerSelectedDate | formatSettingsDate: cs.settings.value.General.DateFormat}}
  </div>
</div>
<div class="auditlog__row-section">
  <!-- <ng-scrollbar> -->
    <div class="auditlog__item">
      <mat-accordion>
        <div class="auditlog__row-section__row" *ngFor="let item of logItems">
          <!--<div class="">{{item.Action}}</div>-->
          <mat-expansion-panel [hideToggle]="!item.Details || item?.Details?.length == 0">
            <mat-expansion-panel-header class="item-mat-expansion-panel-header" [collapsedHeight]="'54px'" [expandedHeight]="'64px'">
              <div class="auditlog__header__actiontype" [ngClass]="{'redcolortext': (item.ActionType == AuditableActionType.NoShow || item.ActionType== AuditableActionType.Cancel || item.ActionType== AuditableActionType.Remove || item.ActionType== AuditableActionType.SetNotWebReservable || item.ActionType == AuditableActionType.Disable)}">
                <span *ngIf="(data[0].type == AuditableEntityType.Shift);else otherView">{{item.Description}}</span><ng-template #otherView><span>{{item.Action | translate}}</span></ng-template></div>
              <div class="auditlog__header__created">{{item.CreatedAt | formatSettingsDate: cs.settings.value.General.DateFormat}} / {{item.CreatedAt | localizedDate: 'LTS'}}</div>
              <div class=""><span><i class="icon-user"></i><span *ngIf="(data[0].type == AuditableEntityType.Shift);else userView"> {{item.Actor.Name}}</span><ng-template #userView><span> {{item.User}}</span></ng-template></span></div>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="auditlog__panel-body" *ngIf="item.EntityType == AuditableEntityType.Party && item.ActionType == AuditableActionType.Create">

                <div class="auditlog__table-info">
                  <div class="auditlog__header">{{'tableInformations' | translate}}</div>
                  <div class="auditlog__table-body">
                    <div class="auditlog__table-row" *ngFor="let detail of item.Details | auditlogItemFilter: AuditLogItemDetailType.Table">
                      <div class="auditlog__table-item-name" title="{{detail.Name}}">{{detail.Name | translate}}</div>
                      <div class="auditlog__table-item-value" [ngStyle]="{'white-space':(formatText.indexOf(detail.Name) == -1) ? 'pre-wrap' : 'break-spaces'}" title="{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}">{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}</div>
                      
                    </div>
                  </div>
                </div>
                <div class="auditlog__personal-info" *ngIf="showPersonalTable">
                  <div class="auditlog__header">{{'personalDetails' | translate}}</div>
                  <div class="auditlog__table-body">
                    <div class="auditlog__table-row" *ngFor="let detail of item.Details | auditlogItemFilter: AuditLogItemDetailType.Personal">
                      <div class="auditlog__table-item-name" title="{{detail.Name}}">{{detail.Name}}</div>
                      <div class="auditlog__table-item-value" [ngStyle]="{'white-space':(formatText.indexOf(detail.Name) == -1) ? 'pre-wrap' : 'break-spaces'}" title="{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}">{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}</div>
                    </div>
                  </div>
                </div>
                <div class="auditlog__other-info">
                  <div class="auditlog__header">{{'otherDetails' | translate}}</div>
                  <div class="auditlog__table-body">
                    <div class="auditlog__table-row" *ngFor="let detail of item.Details | auditlogItemFilter: AuditLogItemDetailType.Other">
                      <ng-container *ngIf="hasProperty(detail,'Value');">
                        <div class="auditlog__table-item-name" title="{{detail.Name}}">{{detail.Name}}</div>
                        <div class="auditlog__table-item-value" [ngStyle]="{'white-space':(formatText.indexOf(detail.Name) == -1) ? 'pre-wrap' : 'break-spaces'}" title="{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}">{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="auditlog__payment-info" *ngIf="showPaymentTable">
                  <div class="auditlog__header">{{'paymentDetails' | translate}}</div>
                  <div class="auditlog__table-body">
                    <div class="auditlog__table-row" *ngFor="let detail of item.Details | auditlogItemFilter: AuditLogItemDetailType.Payment">
                      <div class="auditlog__table-item-name" title="{{detail.Name}}">{{detail.Name}}</div>
                      <div class="auditlog__table-item-value" [ngStyle]="{'white-space': detail.Name == 'Negotiated Amounts' ? 'pre-wrap' : 'break-spaces'}" title="{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}">{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}</div>
                      <!-- <div >{{formatTextPipe(detail.Name,detail.Value)}}</div> -->
                    </div>
                  </div>
                </div>

              </div>

              <ng-container *ngIf="item.EntityType == AuditableEntityType.Party && item.ActionType == AuditableActionType.Create">
                <ng-container *ngFor="let detail of item.Details">
                  <div class="my-2" *ngFor="let entity of detail.EntityDetails">
                     <mat-expansion-panel [hideToggle]="!entity.EntityDetails || entity.EntityDetails?.length == 0">
                      <mat-expansion-panel-header class="item-mat-expansion-panel-header" [collapsedHeight]="'54px'"
                        [expandedHeight]="'64px'">
                        <div class="auditlog__header__actiontype">{{entity.Name | translate | titlecase}} - {{AuditableActionType[entity.EntityActionType]}}
                        </div>
                      </mat-expansion-panel-header>
                      <ng-template matExpansionPanelContent>
                        <div class="auditlog__panel-body">
                        
                          <div *ngIf="(entity.EntityDetails | auditlogItemFilter: AuditLogItemDetailType.Session).length > 0" class="auditlog__session-info">
                            <div class="auditlog__header">{{'sessionDetails' | translate}}</div>
                            <div  class="auditlog__table-body">
                              <div class="auditlog__table-row"
                                *ngFor="let session of entity.EntityDetails | auditlogItemFilter: AuditLogItemDetailType.Session">
                                <div class="auditlog__table-item-name" title="{{session.Name}}">{{session.Name}}</div>
                                <div class="auditlog__table-item-value" [ngStyle]="{'white-space':(formatText.indexOf(detail.Name) == -1) ? 'pre-wrap' : 'break-spaces'}" title="{{session.Value | sessionMultiValuePipe : session.Name : delimiterAuditLogValue : formatText }}">{{session.Value | sessionMultiValuePipe : session.Name : delimiterAuditLogValue : formatText }}</div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="(entity.EntityDetails | auditlogItemFilter: AuditLogItemDetailType.Payment).length > 0"  class="auditlog__payment-info">
                            <div class="auditlog__header">{{'paymentDetails' | translate}}</div>
                            <div class="auditlog__table-body">
                              <div class="auditlog__table-row"
                                *ngFor="let session of entity.EntityDetails | auditlogItemFilter: AuditLogItemDetailType.Payment">
                                <div class="auditlog__table-item-name" title="{{session.Name}}">{{session.Name}}</div>
                                <div class="auditlog__table-item-value" [ngStyle]="{'white-space':(formatText.indexOf(detail.Name) == -1) ? 'pre-wrap' : 'break-spaces'}" title="{{session.Value | sessionMultiValuePipe : session.Name : delimiterAuditLogValue : formatText }}">{{session.Value | sessionMultiValuePipe : session.Name : delimiterAuditLogValue : formatText }}</div>
                              </div>
                            </div>
                          </div>
                        
                        </div>
                      </ng-template>
                  </mat-expansion-panel>
                  </div>
                </ng-container>
              </ng-container>

              <div class="auditlog__panel-body" *ngIf="item.EntityType == AuditableEntityType.Activity || item.EntityType == AuditableEntityType.ActivityCustomizations || item.EntityType == AuditableEntityType.Offer || item.EntityType == AuditableEntityType.StaffBlockorBreakHours">
                <div class="auditlog__update-table" *ngIf="item?.Details?.length > 0">
                  <div class="auditlog__update-header-row">
                    <div class="auditlog__update-header">{{'details' | translate}}</div>
                    <div class="auditlog__update-header" *ngIf="item.ActionType != AuditableActionType.Create;else createValue">{{'updatedValue' | translate}}</div>
                    <ng-template #createValue>
                      <div class="auditlog__update-header">{{'value' | translate}}</div>
                    </ng-template>
                    <div class="auditlog__update-header" *ngIf="item.ActionType != AuditableActionType.Create">{{'originalValue' | translate}}</div>
                  </div>
                  <div class="auditlog__update-table__body">
                    <ng-container *ngFor="let detail of item.Details">
                    <div class="auditlog__table-row" *ngIf="hasProperty(detail,'Value') && detail?.Value">
                      <div class="auditlog__update-table__item-name" title="{{detail.Name}}">{{detail.Name | translate}}</div>
                        <div class="auditlog__update-table__item-value" [ngStyle]="{'white-space':(formatText.indexOf(detail.Name) == -1) ? 'pre-wrap' : 'break-spaces'}" title="{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}">{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}</div>                      
                        <div class="auditlog__update-table__item-value" [ngStyle]="{'white-space':(formatText.indexOf(detail.Name) == -1) ? 'pre-wrap' : 'break-spaces'}" *ngIf="item.ActionType != AuditableActionType.Create " title="{{detail.OriginalValue | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}">{{detail.OriginalValue | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}</div>
                      </div>

                      <div class="auditlog__table-row" *ngIf="hasProperty(detail,'ListValue');">
                        <div class="auditlog__update-table__item-name" title="{{detail.Name}}">{{detail.Name | translate}}</div>
                        <div class="childList" *ngIf="detail.ListValue?.length">
                          <div *ngFor="let childList of detail.ListValue">{{childList}}</div>
                        </div>
                        <div class="childList" *ngIf="detail.OriginalListValue?.length">
                          <div *ngFor="let childList of detail.OriginalListValue">{{childList}}</div>
                        </div>
                      </div>

                    </ng-container>
                    <ng-container *ngFor="let detail of item.Details">
                      <div *ngIf="detail.EntityDetails?.length" class="auditlog__table-row-accordian">
                        <mat-accordion class="example-headers-align" >
                          <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <div class="auditlog__update-table__item-name" title="{{detail.Name}}">{{detail.Name | translate}}</div>
                            </mat-expansion-panel-header>
                            <div class="auditlog__panel-body mr-2 mb-2" *ngIf="detail?.EntityDetails?.length > 0">
                              <div class="auditlog__update-table">
                                 <div class="auditlog__update-header-row">
                                  <div class="auditlog__update-header">{{'details' | translate}}</div>
                                  <div class="auditlog__update-header" *ngIf="item.ActionType != AuditableActionType.Create;else createValue">{{'updatedValue' |
                                    translate}}</div>
                                    <ng-template #createValue>
                                      <div class="auditlog__update-header">{{'value' | translate}}</div>
                                    </ng-template>
                                  <div class="auditlog__update-header" *ngIf="item.ActionType != AuditableActionType.Create">{{'originalValue' | translate}}</div>
                                </div>
                                <div class="auditlog__update-table__body" *ngFor="let data of detail.EntityDetails;let i = index">
                                  <div class="auditlog__table-row">
                                    <div class="auditlog__update-table__item-name" title="{{data.Name}}">{{data.Name}}</div>
                                    <div class="auditlog__update-table__item-value">
                                      <div *ngFor="let updatedValue of data.ListValue">
                                        {{updatedValue}}
                                      </div>
                                    </div>
                                    <div class="auditlog__update-table__item-value">
                                      <div *ngFor="let originalValue of data.OriginalListValue">
                                        {{originalValue}}
                                      </div>
                                    </div>
                                  </div>
                                  <div *ngIf="i != detail.EntityDetails?.length-1" class="my-1"><mat-divider></mat-divider></div>
                                </div>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    
                  </ng-container>
                  </div>
                </div>
              </div>

              <div class="auditlog__panel-body" *ngIf="((item.EntityType == AuditableEntityType.Party || item.EntityType == AuditableEntityType.Contact || item.EntityType == AuditableEntityType.AdvancedSettings) && item.ActionType != AuditableActionType.Create)">

                <div class="auditlog__update-table" *ngIf="item?.Details?.length > 0">
                  <div class="auditlog__update-header-row">
                    <div class="auditlog__update-header">{{'details' | translate}}</div>
                    <div class="auditlog__update-header" *ngIf="item.ActionType != AuditableActionType.Create">{{'updatedValue' | translate}}</div>
                    <div class="auditlog__update-header">{{'originalValue' | translate}}</div>
                  </div>
                  <div class="auditlog__update-table__body">
                    <ng-container *ngFor="let detail of item.Details">
                      <div class="auditlog__table-row" *ngIf="!detail.EntityDetails?.length">
                        <div class="auditlog__update-table__item-name" title="{{detail.Name}}">{{detail.Name | translate}}</div>
                        <div class="auditlog__update-table__item-value" [ngStyle]="{'white-space':(formatText.indexOf(detail.Name) == -1) ? 'pre-wrap' : 'break-spaces'}" *ngIf="item.ActionType != AuditableActionType.Create" title="{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}">{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}</div>
                        <div class="auditlog__update-table__item-value" [ngStyle]="{'white-space':(formatText.indexOf(detail.Name) == -1) ? 'pre-wrap' : 'break-spaces'}" title="{{detail.OriginalValue | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}">{{detail.OriginalValue | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}</div>
                      </div>
                    </ng-container>
                    <ng-container *ngFor="let detail of item.Details">
                      <div *ngIf="detail.EntityDetails?.length" class="auditlog__table-row-accordian">
                        <mat-accordion class="example-headers-align" >
                          <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <div class="auditlog__update-table__item-name" title="{{detail.Name}}">{{detail.Name | translate}}</div>
                            </mat-expansion-panel-header>
                            <div class="auditlog__panel-body mr-2 mb-2" *ngIf="detail?.EntityDetails?.length > 0">
                              <div class="auditlog__update-table">
                                <div class="auditlog__update-header-row">
                                  <div class="auditlog__update-header">{{'Session' | translate}}</div>
                                  <div class="auditlog__update-header">{{'actionType' | translate}}</div>
                                  <div class="auditlog__update-header">{{'details' | translate}}</div>
                                  <div class="auditlog__update-header" *ngIf="item.ActionType != AuditableActionType.Create">{{'updatedValue' |
                                    translate}}</div>
                                  <div class="auditlog__update-header">{{'originalValue' | translate}}</div>
                                </div>
                                <div class="auditlog__update-table__body" *ngFor="let session of detail.EntityDetails;let i = index">
                                  <div class="auditlog__table-row" *ngFor="let detail of session?.EntityDetails;first as isFirst">
                                    <div class="auditlog__update-table__item-name" title="{{session.Name}}">{{isFirst ? session.Name : ''}}</div>
                                    <div class="auditlog__update-table__item-name" title="{{AuditableActionType[session.EntityActionType]}}">
                                      {{isFirst ? AuditableActionType[session.EntityActionType] : ''}}</div>
                                    <div class="auditlog__update-table__item-name" title="{{detail.Name}}">{{detail.Name}}</div>
                                    <div class="auditlog__update-table__item-value" [ngStyle]="{'white-space':(formatText.indexOf(detail.Name) == -1) ? 'pre-wrap' : 'break-spaces'}" *ngIf="item.ActionType != AuditableActionType.Create" title="{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}">{{detail.Value | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}</div>
                                    <div class="auditlog__update-table__item-value" [ngStyle]="{'white-space':(formatText.indexOf(detail.Name) == -1) ? 'pre-wrap' : 'break-spaces'}" title="{{detail.OriginalValue | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}">{{detail.OriginalValue | sessionMultiValuePipe : detail.Name : delimiterAuditLogValue : formatText }}</div>
                                  </div>
                                  <div *ngIf="i != detail.EntityDetails?.length-1" class="my-1"><mat-divider></mat-divider></div>
                                </div>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    
                  </ng-container>
                  </div>
                </div>

              </div>
            </ng-template>
          </mat-expansion-panel>

        </div>
        <div class="auditlog__nodata" *ngIf="!logItems || logItems?.length == 0">
          <img class="auditlog__nodata" src="{{nodataImgUrl}}" alt="">
          <span class="">{{'noDataFound' | translate}}</span>
        </div>
      </mat-accordion>
    </div>
  <!-- </ng-scrollbar> -->
</div>
</ng-scrollbar>
</div>
