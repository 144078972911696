import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, OnInit, Type, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AutoSyncComponent } from '@dynamicform/components/auto-sync/auto-sync.component';
import { ContentComponent } from '@dynamicform/components/content/content.component';
import { FormAddButtonComponent } from '@dynamicform/components/form-add-button/form-add-button.component';
import { FormAutocompleteComponent } from '@dynamicform/components/form-autocomplete/form-autocomplete.component';
import { FormButtonComponent } from '@dynamicform/components/form-button/form-button.component';
import { FormCheckboxComponent } from '@dynamicform/components/form-checkbox/form-checkbox.component';
import { FormChipComponent } from '@dynamicform/components/form-chip/form-chip.component';
import { FormdateComponent } from '@dynamicform/components/form-date/form-date.component';
import { FormInputAutocompleteComponent } from '@dynamicform/components/form-input-autocomplete/form-input-autocomplete.component';
import { FormInputComponent } from '@dynamicform/components/form-input/form-input.component';
import { FormMonthDatePickerComponent } from '@dynamicform/components/form-month-date-picker/form-month-date-picker.component';
import { FormMultiSelectComponent } from '@dynamicform/components/form-multi-select/form-multi-select.component';
import { FormNumberComponent } from '@dynamicform/components/form-number/form-number.component';
import { FormRadioComponent } from '@dynamicform/components/form-radio/form-radio.component';
import { ResetbuttonComponent } from '@dynamicform/components/form-reset-button/form-reset-button.component';
import { FormSelectComponent } from '@dynamicform/components/form-select/form-select.component';
import { FormSwitchComponent } from '@dynamicform/components/form-switch/form-switch.component';
import { FormTextareaComponent } from '@dynamicform/components/form-textarea/form-textarea.component';
import { FormtoggleComponent } from '@dynamicform/components/form-toggle/form-toggle.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';
import { FormGroupSelectComponent } from '../form-group-select/form-group-select.component';
import { FormSliderComponent } from '../form-slider/form-slider.component';
import { FormTimepickerComponent } from '../form-timepicker/form-timepicker.component';



const components: { [type: string]: Type<Field> } = {
  button: FormButtonComponent,
  input: FormInputComponent,
  select: FormSelectComponent,
  toggle: FormtoggleComponent,
  switch: FormSwitchComponent,
  date: FormdateComponent,
  textarea: FormTextareaComponent,
  number: FormNumberComponent,
  reset: ResetbuttonComponent,
  inputAutoComplete: FormInputAutocompleteComponent,
  autocomplete: FormAutocompleteComponent,
  manualSync: AutoSyncComponent,
  content: ContentComponent,
  radio: FormRadioComponent,
  chip: FormChipComponent,
  checkbox: FormCheckboxComponent,
  addBtn: FormAddButtonComponent,
  monthPicker: FormMonthDatePickerComponent,
  multiple: FormMultiSelectComponent,
  slider: FormSliderComponent,
  timepicker: FormTimepickerComponent,
  groupSelect: FormGroupSelectComponent
};

@Directive({
  selector: '[dynamicField]'
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
  @Input()
  config: FieldConfig;

  @Input()
  group: UntypedFormGroup;

  component: ComponentRef<Field>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {
  }

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
    }
  }

  ngOnInit() {
    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }
    const component = this.resolver.resolveComponentFactory<Field>(components[this.config.type]);
    this.component = this.container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
