import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewServerComponent } from '@app/servers/new-server/new-server.component';
import { IFormValidDetails, ITabOutputDetails } from '@app/settings/models/common.interface';
import { BookingBehavior, buttonTypes, ComponentTypes, ReservationType } from '@constants/commonenums';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { LayoutConfig } from '@models/global.interface';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { TabConfig, TabsModel } from '@popup-module/models/popup.interface';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { BehaviorSubject } from 'rxjs';
import { ThemeService } from 'ng2-charts';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { ComponentDetails} from '@popup-module/models/popup.interface';
import { ActivitiesVenue } from '@app/shared/constants/globalConstants';
import { AppService } from '@app/app.service';
import { Utilities } from '@utilities/utilities';
import { CacheService } from "@app/core/services/cache.service";
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TabsComponent extends Utilities implements OnInit, OnChanges, OnDestroy {
  @ViewChild('comp', { read: ViewContainerRef }) dynamicComponent: ViewContainerRef;
  @Input() tabsConfig: TabsModel;
  @Input() fromComponent: any;
  @Input() selectedTabIndex: any;
  @Output() tabIndexChange = new EventEmitter();
  @Input() fromCart: any;
  dynamicComponentInjector: Injector;
  reservationType = ReservationType;
  currentTabIndex = 0;
  tabIndex = 0;
  buttonValueNext: ButtonValue;
  buttonValueAddCart: ButtonValue;
  buttonValueBuyItem: ButtonValue
  buttonValueCancel: ButtonValue;
  buttonValueClose: ButtonValue;
  btnLabelSubscription: ISubscription;
  nextButtonSubscription: ISubscription;
  previousSubscription: ISubscription;
  formValidSubscription: ISubscription;
  confirmedSubscription: ISubscription;
  cancelSubscription: ISubscription;
  paymentSubscription: ISubscription;
  layoutCofiguartion: LayoutConfig;
  componentTypes = ComponentTypes;
  slotSubscription: any;
  isDialogButtonsAvailable = false;
  isNextButtonAvailable = false;
  isAddCartButtonAvailable = false;
  isBuyItemButtonAvailable = false
  isHeaderAvailable = true;
  isPreviousButtonAvailable = true;
  isCancelButtonAvailable = false;
  fromBackButton: boolean = true;
  isShowConfirmbtn: boolean = true;
  subscriptions: Subscription = new Subscription();
  freezeTab: boolean;
  showAddtoCartPopover = true;
  warningInfoDialogRef: MatDialogRef<any>;

  set dynamicComponentData(componentInput) {
    this.dynamicComponentInjector = Injector.create([
      { provide: COMPONENTINPUT, useValue: componentInput }
    ], this.tabInjector);
  }
  changedTabIndex = 0;

  constructor(public partyService: PartyService, private componentRef: ComponentFactoryResolver, private ps: PopupService, public _as: AppService,
    private tabInjector: Injector, public dialog: MatDialog, public dialogRef: MatDialogRef<NewServerComponent>, private ts: TranslateService, private changeDetector: ChangeDetectorRef,public cs: CacheService, private utilities: CommonUtilities) {
      super(dialog);       
    }

  /**
   * @param $event;
   * @function seletedTabChange($event)
   * @description On Select the change Event Function
   */

  seletedTabChange(event) {
    this.tabsConfig.buttonText = null;
    if (this.partyService.isOverBookSlotSelected) { 
      this.ps.dialogPopupTittle = 'createreservationoverbookTitle'; 
    }
    else{
      this.ps.dialogPopupTittle = this.tabsConfig.tabs[event.index].tabHeader;
    }
    if ((this.partyService.reservationType == ReservationType.StandbyParties || this.partyService.reservationType == ReservationType.PrivateLessonBooking) && this.tabsConfig.isEditTab && this.partyService.standbyConversion) {
      this.ps.dialogPopupTittle = 'convertStandbyToReservation';
    }
    if (this.partyService.reservationType == ReservationType.Reservation && this.partyService.isStandbySlotSelected) {
      this.ps.dialogPopupTittle = this.tabsConfig.isEditTab ? 'reservationToStandbyTitle' : 'newStandbyTitle';
      this.tabsConfig.buttonText = 'saveToStandbyList';
    }
     this.changedTabIndex = event.index;
    this.setLabel(event.index);
    this.toggleDialogButtons();

    this.tabIndexChange.emit(event);
    this.partyService.tabChange$.next(event.index);
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  setLabel(tabIndex) {
    if (tabIndex == (this.tabsConfig.tabs.length - 1)) {
      if (!this.tabsConfig.isDirectBooking)
      {
        if (this.fromComponent === ComponentTypes.AddActivityBooking || this.fromComponent === ComponentTypes.AddOpenBooking ||
          this.fromComponent === ComponentTypes.CreateReservation || this.fromComponent === ComponentTypes.AddPrivateLessonBooking) {
          this.isAddCartButtonAvailable = this.checkActivityProperty();
          this.isBuyItemButtonAvailable=this.isAddCartButtonAvailable ;
          this.isShowConfirmbtn = !this.isAddCartButtonAvailable;
        }
        if ((this.fromComponent === ComponentTypes.EditActivityBooking || this.fromComponent === ComponentTypes.EditOpenBooking || this.fromComponent === ComponentTypes.EditPrivateLessonBooking) && this.fromCart) {
          this.isShowConfirmbtn = false;
          this.showAddtoCartPopover = false;
          this.buttonValueAddCart.label = this.ts.instant('UpdateCart')
          this.isAddCartButtonAvailable = true;
          this.isBuyItemButtonAvailable=false;
        }
    }
      if (this.tabsConfig.buttonText) {
        this.ps.btnLabel.emit(this.tabsConfig.buttonText);
      } else {
        switch (this.fromComponent) {
          case ComponentTypes.CreateReservation:
            this.ps.btnLabel.emit(this.cs.isIframeEnabled ? 'DiningAddToCart' : 'reservationCreateButtonText');
            break;
          case ComponentTypes.CreateWaitList:
            this.ps.btnLabel.emit('waitListCreateButtonText');
            break;
          case ComponentTypes.EditReservation:
          case ComponentTypes.EditWaitList:
            this.ps.btnLabel.emit(this.cs.isIframeEnabled ? 'DiningUpdateCart' : 'update');
            break;
          case ComponentTypes.editStandby:
            this.partyService.standbyConversion ? this.ps.btnLabel.emit('convertToReservation') : this.ps.btnLabel.emit('save');
            this.buttonValueClose.label = 'removeBtnText';
            break;
            case ComponentTypes.editServer:
              this.toggleDialogButtons();
              break;
          default:
            this.ps.btnLabel.emit('save');
            break;
        }
      }
    } else {
      this.isAddCartButtonAvailable = false;
      this.isBuyItemButtonAvailable = false;
      this.isShowConfirmbtn = true;
      this.ps.btnLabel.emit('guestBookNextText');
    }
  }

  tabNavigation() {
    this.nextButtonSubscription = this.ps.nextBtnEnabled$.subscribe((data) => {
      this.buttonValueNext.disbaledproperity = data;
      this.buttonValueAddCart.disbaledproperity = data;
      this.buttonValueBuyItem.disbaledproperity = data;
    });
    this.previousSubscription = this.ps.previousButtonEnabled$.subscribe((data) => {
      this.buttonValueCancel.disbaledproperity = data;
      this.ps.enableSaveBtnAuthorise$.next(false);
    });
    this.formValidSubscription = this.ps.formValid$.subscribe((data: IFormValidDetails) => {
      if (data.isFormValid && (data.currentTab + 1 < this.tabsConfig.tabs.length) && !this.freezeTab) {
        this.tabsConfig.tabs[data.currentTab + 1].isDisabled = false;
        if (data.currentTab === 0) {
          this.isNextButtonAvailable = true;
          if (this.fromComponent == ComponentTypes.EditWaitList || this.fromComponent == ComponentTypes.EditReservation || this.fromComponent == ComponentTypes.editStandby) {
            this.isCancelButtonAvailable = true;
          }
        }
        if (data.gotoNextTab) {
          let count = 1;
          if (this.slotSubscription) {
            this.slotSubscription.unsubscribe();
          }
          this.tabsConfig.tabs.forEach((tab: TabConfig, index) => {
            if (data.disablePrevious && index <= data.currentTab) {
              tab.isDisabled = true;
            } else {
              tab.isDisabled = false;
            }
            if (index >= data.currentTab && index !== this.tabsConfig.tabs.length - 1 && count <= data.tabsToNavigateCount) {
              count++;
              console.log(count);
              this.next();
            }
          });

          if (data.disablePrevious) {
            this.freezeTab = true;
            this.tabsConfig.tabs[data.gotoNextTab ? data.currentTab : data.currentTab - 1].isDisabled = true;
            this.ps.previousButtonEnabled$.next(true);
          }
        }
        else if (data.gotoPreviousTab) {
          this.fromBackButton = false;
          this.previous();
        }
      } else if(!this.freezeTab){
        if (data.currentTab === 0) {
          this.isNextButtonAvailable = false;
        }
        this.tabsConfig.tabs.forEach((tab: TabConfig, index) => {
          if (index !== data.currentTab && index > data.currentTab) {
            tab.isDisabled = true;
          }
        });
      }
      this.ps.nextBtnEnabled$.next(!data.isFormValid);
      const previousValue = this.changedTabIndex === 0 || this.freezeTab  ? true : false;
      this.ps.previousButtonEnabled$.next(previousValue);
    });
  }

  ngOnChanges() {
    if ((this.selectedTabIndex || this.selectedTabIndex === 0) && typeof this.selectedTabIndex !== 'object' 
    && !isNaN(this.selectedTabIndex)){
this.tabIndex = this.selectedTabIndex;
}
}

  ngOnInit() {
    this.ps.dialogPopupTittle = this.tabsConfig.tabs[0].tabHeader;
    this.dynamicComponentData = this.tabsConfig.componentInput;

   this.paymentSubscription=this.ps.nextEvent$.subscribe((data)=>{
    this.tabIndex = this.changedTabIndex + 1;
   });

    if (this.tabsConfig.tabValue) {
      this.tabsConfig.tabs[0].tabLabel = this.partyService.reservationFormGroup.value.selectedSize;
    }
    this.tabIndexChange.emit(0);
    if (this.tabsConfig.isNextButtonAvailable) {
      this.buttonValueNext = {
        type: buttonTypes.actionPrimary,
        label: this.ps.btnLabel,
        customclass: 'next-btn',
        disbaledproperity: this.ps.nextBtnEnabled$
      };
      this.buttonValueAddCart = {
        type: buttonTypes.actionPrimary,
        label: this.ts.instant('AddToCart'),
        customclass: 'next-btn',
        disbaledproperity: this.ps.nextBtnEnabled$
      }
      
      this.buttonValueBuyItem = {
        type: buttonTypes.actionSecondary,
        label: this.ts.instant('BookNow'),
        customclass: 'next-btn',
        disbaledproperity: this.ps.nextBtnEnabled$
      }

      this.buttonValueCancel = {
        type: buttonTypes.actionSecondary,
        label: 'Previous',
        customclass: 'cancel-btn',
        disbaledproperity: this.ps.previousButtonEnabled$
      };
      this.buttonValueClose = {
        type: buttonTypes.actionSecondary,
        label: 'Cancel',
        customclass: 'cancel-btn'
      };
      this.subscriptions.add(this.ps.btnLabel.subscribe(x => {
        this.buttonValueNext.label = x;
      }));
      this.ps.btnLabel.emit('guestBookNextText');
      this.tabNavigation();
    }
    this.subscriptions.add(this.ps.previousEvent$.subscribe(() => {
      this.fromBackButton = false;
      this.previous();
    }));
    switch (this.fromComponent) {
      case ComponentTypes.CreateWaitList:
      case ComponentTypes.CreateReservation:
      case ComponentTypes.EditReservation:
      case ComponentTypes.EditWaitList:
      case ComponentTypes.editStandby:
        this.isDialogButtonsAvailable = true;
        this.isHeaderAvailable = false;
        break;
        case ComponentTypes.editServer:
          this.toggleDialogButtons();
          break;

      default:
        this.isHeaderAvailable = true;
    }
  }

  toggleDialogButtons() {
    if (this.fromComponent !== ComponentTypes.AddOpenBooking
      && this.fromComponent !== ComponentTypes.EditOpenBooking
      && this.fromComponent !== ComponentTypes.AddActivityBooking
      && this.fromComponent !== ComponentTypes.EditActivityBooking
      && this.fromComponent !== ComponentTypes.AddPrivateLessonBooking
      && this.fromComponent !== ComponentTypes.EditPrivateLessonBooking
      && this.fromComponent !== ComponentTypes.advancedBlock
      && this.fromComponent !== ComponentTypes.specialMealBooking) {
      this.isDialogButtonsAvailable = true;
      this.isCancelButtonAvailable = true;
    }else{
      this.partyService.bookingsCount$.next(true)
    }
    this.isNextButtonAvailable = true;
    if (this.buttonValueNext) {
      this.buttonValueNext.disbaledproperity = false;
    }
    if (this.buttonValueNext != undefined) {
      this.buttonValueNext.disbaledproperity = false;
    }
    if (this.tabsConfig.tabs[this.tabIndex].tabLabel === 'schedulingInstructor') {
      this.tabsConfig.isNextButtonAvailable = false;
    }
    else if (this.tabsConfig.tabs[this.tabIndex].tabLabel === 'instructor') {
      this.tabsConfig.isNextButtonAvailable = true;
      this.ps.btnLabel.emit('save');
      this.nextButtonSubscription = this.ps.nextBtnEnabled$.subscribe((data) => {
        this.buttonValueNext.disbaledproperity = data;
      });
    }
  }

  checkActivityProperty(): boolean{
    return ActivitiesVenue.find(x=>x == this._as.PropertyType) ? true : false;
  }

  ngOnDestroy() {
    if (this.btnLabelSubscription) {
      this.btnLabelSubscription.unsubscribe();
    }
    if (this.formValidSubscription) {
      this.formValidSubscription.unsubscribe();
    }
    if (this.nextButtonSubscription) {
      this.nextButtonSubscription.unsubscribe();
    }
    if (this.previousSubscription) {
      this.previousSubscription.unsubscribe();
    }
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    if (this.paymentSubscription) { this.paymentSubscription.unsubscribe(); }
    
  }

  addToCart(val, cartGroupData) {
    if(val && Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)){
      if(Utilities.isUserHasAccessToRetail(this._as.restaurantId) || !this.validateTotalAmount() || this.partyService.IsSkipPayment){
        this.buyAndAddToValidation(cartGroupData,val)
      }else{
        const popUpMessage = [{
          confirmationMessage: this.ts.instant("userPermissionMsg"),
          dialogTitle: this.ts.instant('authFailed')
        }];
        let okbutton = this.ts.instant('ok');
        const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
          popUpMessage, popUpMessage[0].dialogTitle);
        this.openCustomPopup(componentDetails, ComponentTypes.RoleTypeAccess, '450px', 'auto', true, '', okbutton,
          '', true);
      }
    }else{
      this.buyAndAddToValidation(cartGroupData,val)
    }    
  }

  onCartGroupSelect(cartGroupData){
    this.addToCart(false, cartGroupData)
  }
  validateTotalAmount(){
    if( this.partyService.BookingBehaviour === BookingBehavior.ClassOrSession){
        return this.partyService.sessionBookingDataSaveObj && this.partyService.sessionBookingDataSaveObj.TotalPayable > 0
    }else if(this.partyService.BookingBehaviour === BookingBehavior.PrivateLesson){
        return this.partyService.privateLessonBookingDataSaveObj && this.partyService.privateLessonBookingDataSaveObj.TotalPayable > 0 
    }else if(this.partyService.BookingBehaviour === BookingBehavior.OpenBooking){
        return this.partyService.openBookingDataSaveObj && this.partyService.openBookingDataSaveObj.TotalPayable > 0
    }
  }
  buyAndAddToValidation(cartGroupData,val){
    this.ps.confirmAddToCartAction$.next({
      inputData: this.tabsConfig.componentInput,
      outputData: cartGroupData,
      fromComponent: this.fromComponent,
      isEdit: val
    } as ITabOutputDetails);
    if(val)
    {
     this.buttonValueBuyItem.disbaledproperity=true;  
     this.buttonValueAddCart.disbaledproperity=true;   
     //this.next();
    }
  }
  next() {
    this.fromBackButton = true;
    if (this.buttonValueNext.label === 'save' || this.buttonValueNext.label === 'reservationCreateButtonText' ||
     this.buttonValueNext.label === 'DiningAddToCart' || this.buttonValueNext.label === 'DiningUpdateCart' ||
      this.buttonValueNext.label === 'waitListCreateButtonText' || this.buttonValueNext.label === 'update' ||
      this.buttonValueNext.label === this.tabsConfig.buttonText || this.buttonValueNext.label === 'convertToReservation') {
      if (!this.ps.confirmationDialog) {
        if (this.fromComponent !== ComponentTypes.CreateReservation && this.fromComponent !== ComponentTypes.CreateWaitList
          && this.fromComponent !== ComponentTypes.EditReservation && this.fromComponent !== ComponentTypes.EditWaitList
          && this.fromComponent !== ComponentTypes.EditOpenBooking && this.fromComponent !== ComponentTypes.AddOpenBooking
          && this.fromComponent !== ComponentTypes.editStandby && this.fromComponent !== ComponentTypes.guestBook
          && this.fromComponent !== ComponentTypes.EditActivityBooking && this.fromComponent !== ComponentTypes.AddActivityBooking
          && this.fromComponent !== ComponentTypes.EditPrivateLessonBooking && this.fromComponent !== ComponentTypes.AddPrivateLessonBooking
          && this.fromComponent !== ComponentTypes.editServer) {
          this.ps.closeDialog$.next();
        }
        else if (this.fromComponent === ComponentTypes.editServer) {
          this.dialogRef.close(ComponentTypes.editServer);
        }

        this.ps.nextBtnEnabled$.next(true);
        this.ps.confirmedAction$.next({
          label: this.buttonValueNext.label,
          isEdit: this.tabsConfig.isEditTab,
          inputData: this.tabsConfig.componentInput,
          fromComponent: this.fromComponent
        } as ITabOutputDetails);
      } else {
        this.openConfirmationDialog(this.ps.confirmationDialog);
      }
    } else {
      this.tabIndex = null;
      this.changeDetector.detectChanges();
      if (this.changedTabIndex < this.tabsConfig.tabs.length - 1) {
        this.tabIndex = this.changedTabIndex + 1;
        this.setLabel(this.tabIndex);
        if ((this.partyService.reservationType === ReservationType.StandbyParties || this.partyService.reservationType == ReservationType.PrivateLessonBooking) && this.tabsConfig.isEditTab) {
          this.buttonValueClose.label = 'removeBtnText';
        }
      }
    }
    console.log(this.tabIndex);
  }

  previous() {
    if (this.changedTabIndex > 0) {
      this.tabIndex = null;
      this.changeDetector.detectChanges();
      this.tabIndex = this.changedTabIndex - 1;
      this.setLabel(this.tabIndex);
      this.buttonValueClose.label = 'Cancel';
    }
    console.log(this.tabIndex);
  }

  createOpenBooking() {
    var guestData = this.partyService.reservationFormGroup.value.selectedGuest;
    this.partyService.openBookingDataSaveObj.Contact = { ...guestData };
    this.partyService.openBookingDataSaveObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;
    this.partyService.createOpenBooking(this.partyService.openBookingDataSaveObj, false);
  }

  updateOpenBooking() {
    var guestData = this.partyService.reservationFormGroup.value.selectedGuest;
    this.partyService.openBookingDataSaveObj.Contact = { ...guestData };
    this.partyService.openBookingDataSaveObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;
    this.partyService.openBookingDataSaveObj.Slot = this.partyService.openBookingDataSaveObj.Slots[0];
    this.partyService.updateOpenBooking(this.partyService.openBookingDataSaveObj, false);
  }

  createSessionBooking() {
    var guestData = this.partyService.reservationFormGroup.value.selectedGuest;
    guestData.MemberType = guestData.MembershipType?.AllocationName;
    guestData.TrackMembershipNumber = guestData.memberShipId;
    this.partyService.sessionBookingDataSaveObj.PageMethod = guestData.PreferredPageMethod;
    guestData.IsMemberActive = guestData.MemberActive;
    this.partyService.sessionBookingDataSaveObj.Contact = { ...guestData };
    this.partyService.sessionBookingDataSaveObj.SecondaryContactsList = guestData.SecondaryContactsList ? [...guestData.SecondaryContactsList] : null;
    this.partyService.createSessionBooking(this.partyService.sessionBookingDataSaveObj, false);
  }

  cancel() {
    if (this.changedTabIndex > 0) {
      if ((this.partyService.reservationType == ReservationType.StandbyParties || this.partyService.reservationType == ReservationType.PrivateLessonBooking) && this.tabsConfig.isEditTab && this.buttonValueClose.label == 'removeBtnText' && this.fromBackButton) {
        this.ps.cancelledAction$.next({
          label: this.buttonValueNext.label,
          isEdit: this.tabsConfig.isEditTab,
          inputData: this.tabsConfig.componentInput,
          fromComponent: this.fromComponent
        } as ITabOutputDetails);
        return;
      }
    }
    if (this.fromComponent === ComponentTypes.editServer
      || this.fromComponent === ComponentTypes.AddActivityBooking
      || this.fromComponent === ComponentTypes.EditActivityBooking
      || this.fromComponent === ComponentTypes.AddOpenBooking
      || this.fromComponent === ComponentTypes.EditOpenBooking
      || this.fromComponent === ComponentTypes.AddPrivateLessonBooking
      || this.fromComponent === ComponentTypes.EditPrivateLessonBooking
      || this.fromComponent === ComponentTypes.guestBook
      ) {
      this.cancelConfirmationDialog();
    } else if (this.fromComponent === ComponentTypes.CreateReservation
      || this.fromComponent === ComponentTypes.CreateWaitList
      || this.fromComponent === ComponentTypes.EditReservation
      || this.fromComponent === ComponentTypes.EditWaitList
      || this.fromComponent === ComponentTypes.advancedBlock) {
      this.dialogRef.close();
    }
    else {
      this.ps.cancelledAction$.next(ComponentTypes.editServer);
    }
    if(this.fromComponent !== ComponentTypes.guestBook){
      this.ps.closeDialog$.next();
    }
  }

  openConfirmationDialog(componentDetails) {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: componentDetails.popupInput[0].height,
      width: componentDetails.popupInput[0].width,

      data: {
        title: componentDetails.popupInput[0].dialogTitle,
        update: componentDetails.popupInput[0].update,
        cancel: componentDetails.popupInput[0].cancel,
        componentDetails,
        back: false,
        from: ComponentTypes.commonconfirmmessage,
        standalone: true
      }
    });
    this.confirmedSubscription = this.ps.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.commonconfirmmessage) {
        this.setLabel(this.changedTabIndex);
        this.ps.confirmationDialog = null;
        this.next();
      }
    });
    this.cancelSubscription = this.ps.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.commonconfirmmessage) {
        this.setLabel(this.changedTabIndex);
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.confirmedSubscription) {
        this.confirmedSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
    }); 
  }

  cancelConfirmationDialog() {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant('UnSavedDataAlert'),
      dialogTitle: this.ts.instant('warningTitle'),
      width: '500px',
      height: '300px',
      update: this.ts.instant('ok'),
      cancel: this.ts.instant('cancel'),
    }];
    let componentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'active',
      dimensionType: 'small',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };
    const dialogReff = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: componentDetails.popupInput[0].height,
      width: componentDetails.popupInput[0].width,

      data: {
        title: componentDetails.popupInput[0].dialogTitle,
        update: componentDetails.popupInput[0].update,
        cancel: componentDetails.popupInput[0].cancel,
        componentDetails,
        back: false,
        from: ComponentTypes.commonconfirmmessage,
        standalone: true
      }
    });
    this.confirmedSubscription = this.ps.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.commonconfirmmessage) {
        this.dialogRef.close();
      }
    });

    this.cancelSubscription = this.ps.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.commonconfirmmessage) {
        dialogReff.close();
      }
    });

    dialogReff.afterClosed().subscribe(() => {
      if (this.confirmedSubscription) {
        this.confirmedSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
    });	

  }

}
