export enum searchtitleenum {
    MENUSEARCH = 'Menus'
}

export interface MenuSearchModel {
    title: MenuSearchTitle;
    dataCollection: MenuSearchData[];
}

export interface MenuSearchTitle {
    name: searchtitleenum;
}

export interface MenuSearchData {
    id: number;
    value?: string;
    productID: number;
    routePath: string;
    breakPointNo: number;
    menuPosition:string;
    parentValue?:string;
    textID?: number | string;
    EntityElementID:number;
    isReportUrl?: boolean;
}

