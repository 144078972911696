import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NotUploadedPopupService } from './not-uploaded-popup.service';
import { GridType } from '../globalsContant';
import { RetailLocalization } from '../../common/localization/retail-localization';
@Component({
  selector: 'app-not-uploaded-popup',
  templateUrl: './not-uploaded-popup.component.html',
  styleUrls: ['./not-uploaded-popup.component.scss'],
  providers: [NotUploadedPopupService]
})
export class NotUploadedPopupComponent implements OnInit {
  FormGrp: UntypedFormGroup;
  saveButton = '';
  cancelButton = '';
  tableoptions: {};
  editData = true;
  disableEditButton = false;
  searchText = '';
  enableSave = false;
  selectedData: any;
  retailCaptions: any;
  NotProcessedCount: number = 0;
  ProcessedCount: number = 0;
  FailedCount: number = 0;
  constructor(
    public dialogRef: MatDialogRef<NotUploadedPopupComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private _NotUploadedPopupService: NotUploadedPopupService,
     private fb: UntypedFormBuilder,
     private localization: RetailLocalization
     ) {
       this.saveButton = this.localization.captions.common.UPLOAD;
       this.cancelButton = this.localization.captions.common.CANCEL;
       this.retailCaptions = this.localization.captions.retailsetup;
       if(this.data && this.data.extraParams[2]){
        this.NotProcessedCount = this.data.extraParams[2].NotProcessed;
        this.ProcessedCount = this.data.extraParams[2].Processed;
        this.FailedCount = this.data.extraParams[2].Failed;
       }
     }

  ngOnInit() {
    this.FormGrp = this.fb.group({

    });
    this.BindTableData(this.data.extraParams[1]);
  }

  DialogClose(result?: any): void {
    this.dialogRef.close(result);
  }

  BindTableData(data) {
    const hdrData = [];
    const headerData = this.data.extraParams[0];
    headerData.forEach(element => {
      if (!element.ishidden) {
      const temphdr = {
        'title': element.title,
        'jsonkey': element.name,
        'alignType': 'left',
        'searchable': false,
        'sortable': false,
        'type': element.type === 'number' ? 'number' : 'text',
        'isInput': true,
        'isEdit': element.isEditable ? true : false,
        'datatype': 'string',
        'sortcolumndatatype': 'string',
        'isPercentage': false,
        'isRequired': element.required ? true : false
      };
      hdrData.push(temphdr);
    }
    });
    this.tableoptions = [{
      TableHdrData: hdrData,
      TablebodyData: data,
      pagination: false,
      sortable: true,
      CustomColumn: false,
      PlaceHoldertext: '',
      EnableActions: false,
      SelectRows: false,
      SelectedSettingId: GridType.inputEditTable,
      Searchable: false,
      EditMoreOption: false,
      Sortable: 'category_name',
      TableId: GridType.inputEditTable,
      disableDelete: false,
      showToggle: false,
      IsViewOnly: false,
      disableEditButton: this.disableEditButton
    }];
  }

  EditRecords(event) {
    this.EnableSave(event);
    this.tableoptions[0].TablebodyData = event;
    this.selectedData = event;
  }

  EnableSaveAction(event) {
    this.selectedData = event;
    this.EnableSave(event);
  }

  EnableSave(event) {
    if(event.length > 0 ) {
      this.enableSave = true;
      } else {
      this.enableSave = false;
      }
  }

  Save() {
    this.DialogClose(this.data.extraParams[1]);
  }

  Cancel() {
    this.DialogClose(false);
  }
}
