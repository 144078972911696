<!-- <div class="switch-control" [ngClass]="config.class" [formGroup]="group">
  <label class="switch-control__switch-label guest-form-label">{{config.label | translate}}</label>
  <mat-slide-toggle class="switch-control__switch" [color]="color" [checked]="checked" [disabled]="disabled" [formControlName]="config.name">
  </mat-slide-toggle>
</div> -->
<div [ngClass]="config.class">
  <label class="switch-control__switch-label guest-form-label">{{config.label | translate}}</label>
  <div class="toggle-button" [formGroup]="group">
    <label class="switch">
      <!-- {{config.name}} -->
      <input  class="switch-input" type="checkbox" [checked]="checked" [formControlName]="config.name" *ngIf="config.changeEvent" (change)="toggleChange($event)"/>
      <input  class="switch-input" type="checkbox" [checked]="checked" [formControlName]="config.name" *ngIf="!config.changeEvent"/>
      <span class="slider round"></span>
    </label>
  </div>
</div>