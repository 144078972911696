import { Component, OnInit, EventEmitter, Output, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { DropDownData } from '../../business/report.modals';
import { ReportDataService } from '../../data/report-data.services';
import { ReportBusinessService } from '../../business/report-business.service';
import * as _ from 'lodash' ;
import { RetailReportControl } from '../../basereport/base-report.component';

@Component({
  selector: 'app-gift-card-redeem',
  templateUrl: './gift-card-redeem.component.html',
  styleUrls: ['./gift-card-redeem.component.scss']
})
export class GiftCardRedeemComponent implements OnInit {
  giftCardRedeemFormGrp: UntypedFormGroup;
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  public captions: any = this.localization.captions.reports;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  users: DropDownData[];
  floatLabel: string;
  editPatchValue : any;
  constructor(private fb: UntypedFormBuilder, private localization: RetailLocalization, private dataService: ReportDataService, 
    public business: ReportBusinessService,private container: ViewContainerRef ) { 
      this.floatLabel = this.localization.setFloatLabel;
    }

  ngOnInit() {
    this.giftCardRedeemFormGrp = this.fb.group({
      cardnumber: new UntypedFormControl(),
      users: new UntypedFormControl([]),
      includeInactiveclerks: false
    });
    this.formReady.emit(this.giftCardRedeemFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit) {  
        let patchItem =  this.editPatchValue;
        
        if(patchItem.UserIds != undefined){
          this.dataService.GetAllUsersByPropertyId().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.UserIds.find(x => x == item.id);
            });
            this.users = arr;     
          });
        } 
        if(patchItem.CardNumber != undefined){
          this.giftCardRedeemFormGrp.controls['cardnumber'].patchValue(patchItem.CardNumber)
        } 
        }
    } 
  }

  private async onLoad() {
    this.users = await this.dataService.GetAllUsersByPropertyId();
    this.users= _.orderBy(this.users, [user => user.description.toLowerCase()], 'asc');
    this.users = this.business.toggleIncludeInactive(false,this.users);
    this.business.FilterDataSource['users'] = this.users;
  }

  toggleIncludeInactive(IncludeInactiveToo: boolean) {
    this.users = this.business.toggleIncludeInactive(IncludeInactiveToo,this.users);
    this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown);
    this.giftCardRedeemFormGrp.controls['users'].setValue([this.users]);
  }

  getFormControlValue(event) {
    if (event[0] === 'users') {
    this.giftCardRedeemFormGrp.setControl('users', event[1]);
    }
  }

}
