import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';

@Component({
  selector: 'app-form-toggle',
  templateUrl: './form-toggle.component.html',
  styleUrls: ['form-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormtoggleComponent implements Field, OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;
  buttonToggleForm: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
  }
}
