import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { buttonTypes, Status } from '@app/shared/constants/commonenums';
import { TranslateService } from '@ngx-translate/core';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { SettingsService } from '@app/shared/services/settings.service';
import { ActivityCreateFunctions } from '@app/shared/utilities/activity-create-functions';
import _ from 'lodash';
import { FormatSettingsDatePipe } from '@app/shared/pipes/format-settings-date.pipe';
import { Subscription } from 'rxjs/Subscription';
import { SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { CacheService } from '@app/core/services/cache.service';
import { MatTableDataSource } from '@angular/material/table';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { Sort } from '@angular/material/sort';
import { AppService } from '@app/app.service';


@Component({
  selector: 'app-template-selection',
  templateUrl: './template-selection.component.html',
  styleUrls: ['./template-selection.component.scss']
})
export class TemplateSelectionComponent implements OnInit {
  @Input() data;
  tableData: { header: string[]; body: any[]; };
  dataSource;
  displayedColumns;
  activeTemplates: any[] = [];
  templates: any[] = [];
  applyButton: ButtonValue;
  settings: SettingsDTO;
  config: FieldConfig[];
  subscriptions: Subscription = new Subscription();
  sortedData: any;
  @ViewChild('configForm', { static: true }) configForm: DynamicFormComponent;

  constructor(public ts: TranslateService, public ss: SettingsService, private activityCreateFunctions: ActivityCreateFunctions, private fs: FormatSettingsDatePipe, private cs: CacheService, public appService: AppService) {
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this.settings = sett;
    }));
  }

  ngOnInit() {
    this.activeTemplates = this.data?.filter(activity => activity.StatusCode == Status.Approved)
    this.activeTemplates = _.orderBy(this.activeTemplates, ['Name'], ['asc']);
    this.loadTableData();
    this.loadConfig();
  }
  loadTableData() {
    let tableHeader = ["TemplateCode", "templateName", "GroupSizeRange", "activityType", "reservationtype", "PaymentType", "Action"];
    let tableBody = [];
    this.activeTemplates.map(data =>
      tableBody.push({
        "TemplateCode": data.ActivityCode || '-',
        "templateName": data.Name,
        //"DateRange": this.fs.transform(data.StartDate, this.settings.General.DateFormat) + " to " + this.fs.transform(data.EndDate, this.settings.General.DateFormat),
        "GroupSizeRange": data.MinPartySize + " - " + data.MaxPartySize,
        "activityType": this.activityCreateFunctions.getActivityType(data.ClassType),
        "reservationtype": data.IsForStandbyReservations ? this.ts.instant('standby') : this.ts.instant('confirmed'),
        "PaymentType": this.activityCreateFunctions.getPrePaymentMode(data.PrePaymentMode),
        "ActivityID": data.Id,
        "date": new Date(data.StartDate)
      }))

    this.tableData = {
      header: tableHeader,
      body: tableBody
    }
    this.displayedColumns = this.tableData.header;
    this.dataSource = new MatTableDataSource(this.tableData.body);
  }
  loadConfig() {
    this.applyButton = {
      type: buttonTypes.actionPrimarySmall,
      label: this.ts.instant('useTemplate'),
      disbaledproperity: false,
      customclass: 'action-bar__add-class-btn',
    };
    this.config = [
      {
        type: 'input',
        name: 'searchText',
        label: this.ts.instant('SearchTemplate'),
        class: 'search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this),
        blurClick: this.applyFilter.bind(this)
      }];
  }
  applyTemplatesData(template) {
    if (template){
      this.appService.isTemplateUsed = true;
      this.appService.templateSelectionChange.next({ id: template.ActivityID, isEdit: false });
      this.activityCreateFunctions.templateSelectionDialog.close();
    }

  }
  clearSearch(data) {
    this.configForm.form.controls.searchText.setValue('');
    this.dataSource.filter = "";
  }
  applyFilter(date) {
    const filterValue = date.currentTarget.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  sortData(sort: Sort) {
    const data = this.tableData.body.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return compare(a[sort.active], b[sort.active], isAsc);
    });
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

