<section class="pop-container">
  <div class="pop-head" [ngClass]="(data?.templatename != 'RS') ? 'border-top': '' ">
    <label class="inner-header pl-4">{{data.headername | uppercase}}</label>
    <i aria-hidden="true" *ngIf="data.closebool" class="material-icons inner-close float-md-right cursPointer"
      (click)="onNoClick()">close</i>
  </div>
  <div class="pop-contents" [ngSwitch]="data?.templatename">
    <ng-template [ngSwitchCase]="'CT'">
      <app-retail-add-commission-popup [dataInput]="datRec" [automationId]="automationId"
        [popupConfigs]="popupConfigs"></app-retail-add-commission-popup>
    </ng-template>
    <ng-template [ngSwitchCase]="'RS'">
      <app-retail-setup [dataInput]="datRec" [popupConfigs]="popupConfigs"></app-retail-setup>
    </ng-template>
    <ng-template [ngSwitchCase]="'QS'">
      <app-retail-quick-sale-popup [dataInput]="datRec" [automationId]="automationId"></app-retail-quick-sale-popup>
    </ng-template>
    <ng-template [ngSwitchCase]="'TI'">
      <app-add-transfer-inventory-popup [dataInput]="datRec"
        [automationId]="automationId"></app-add-transfer-inventory-popup>
    </ng-template>
    <ng-template [ngSwitchCase]="'SM'">
      <app-scheduled-markdown-popup [dataInput]="datRec" [automationId]="automationId"></app-scheduled-markdown-popup>
    </ng-template>
    <ng-template [ngSwitchCase]="'NS'">
      <app-retail-create-new-supplier [dataInput]="datRec" [popupConfigs]="popupConfigs"
        [automationId]="automationId"></app-retail-create-new-supplier>
    </ng-template>
    <ng-template [ngSwitchCase]="'SD'">
      <app-retail-supplier-details [dataInput]="tableData" [automationId]="automationId"></app-retail-supplier-details>
    </ng-template>
    <ng-template [ngSwitchCase]="'PTM'">
      <app-post-type-mapping-crud [dataInput]="datRec" [automationId]="automationId" [LinkType]="LinkType"
        [isMultiplePmsPropertyEnabled]="isMultiplePmsPropertyEnabled"
        [pmsPropCode]="pmsPropCode"></app-post-type-mapping-crud>
    </ng-template>
    <ng-template [ngSwitchCase]="'RPTM'">
      <app-resort-finance-post-type-mapping-crud [dataInput]="datRec" [automationId]="automationId"
        [LinkType]="LinkType" [isMultiplePmsPropertyEnabled]="isMultiplePmsPropertyEnabled"
        [pmsPropCode]="pmsPropCode"></app-resort-finance-post-type-mapping-crud>
    </ng-template>
    <ng-template [ngSwitchCase]="'MAP'">
      <app-member-accrual-map-crud [dataInput]="datRec" [automationId]="automationId"
        [LinkType]="LinkType"></app-member-accrual-map-crud>
    </ng-template>
    <ng-template [ngSwitchCase]="'GLM'">
      <app-general-ledger-mapping-crud [dataInput]="datRec" [automationId]="automationId"
        [LinkType]="LinkType"></app-general-ledger-mapping-crud>
    </ng-template>
    <ng-template [ngSwitchCase]="'BC'">
      <input attr.automationId='Txt_{{automationId}}_labePosition' matInput placeholder="Enter Starting Label Position">
    </ng-template>
    <ng-template [ngSwitchCase]="'EPTM'">
      <app-external-pms-post-type-mapping [dataInput]="datRec" [automationId]="automationId"
        [LinkType]="LinkType" [PostType]="PostType"></app-external-pms-post-type-mapping>
    </ng-template>
  </div>
</section>