<div class="chat-box" *ngIf="inputString == 'messages'">
  <div class="chat-box__chat-header">
    <span class="seat-popover-header">{{'messagesText' | translate }}</span>
    <i class="chat-box__header-close icon-Group-591" (click)="closeChat()"></i>
  </div>
  <div class="chat-box__message-container seat-popover-content">
    <div class="chat-box__party-messages" id="messages-view">
      <div class="chat-box__conversation" *ngFor="let conversation of chatMessages">
        <div class="chat-box__chat-messages">
          <div [ngClass]="conversation.IsIncoming ? 'chat-box__sms-from' : 'chat-box__sms'">
          <label class="message-timings seat-labels" [ngClass]="conversation.IsIncoming ? 'incoming-message' : 'sent-message'">
            <span class="icon-ic_update_24px ml-2 mr-1" [ngClass]="conversation.IsIncoming ? 'incoming-message' : 'sent-message'"></span>
            {{conversation.CreatedAt | formatSettingsDate: ps.cs.settings.value.General.DateFormat}} {{conversation.CreatedAt | localizedDate: 'LT'}}</label>
            <p class="receive-message" [ngClass]="{'float-sent': !conversation.IsIncoming}">{{conversation.value | stipMsg}}</p></div>
        </div>
      </div>
      <div class="chat-box__conversation" *ngIf="disableMessagePopup && chatMessages?.length == 0">
        <div class="chat-box__chat-messages">
          <p class="no-msg-found">{{'noMsgsFound' | translate}}</p>
        </div>
      </div>
    </div>
    <div *ngIf="messageOptions && messageOptions?.length > 0 && !disableMessagePopup && AllowBasedOnSmsProvider" class="chat-box__custom-message-radio">
      <span class="rs-Roboto-Regular-14">{{'customMsgText' | translate }}</span>
      <i class="icon-radio-unchecked chat-box__custom-icon"
        [ngClass]="showCustomMessage && !disableMessagePopup ? 'chat-box__custom-enable' : 'chat-box__custom-disable'"
        (click)="disableMessagePopup ? false : showCustommessage($event)"></i>
    </div>
    <div [ngClass]="{'chat-box__disable-message-container': disableMessagePopup }" class="chat-box__sms-action-area" [matTooltip]="'pagingDisabledOnSeated' | translate"
    [matTooltipDisabled]="!disableMessagePopup">
      <div class="chat-box__chat-area" [class.chat-box__no-template-configured]="!AllowBasedOnSmsProvider && !messageOptions?.length">
        <dynamic-form [config]="config" #form="dynamicForm">
        </dynamic-form>
        <div *ngIf="!AllowBasedOnSmsProvider && !messageOptions?.length">{{'noMessageTemplateConfiguredText' | translate}}</div>
      </div>
      <a class="chat-box__send" *ngIf="messageOptions?.length || AllowBasedOnSmsProvider" [ngClass]="{'chat-box__disable':  !(dynamicForm && (dynamicForm?.value?.messageArea?.length > 0 || dynamicForm?.value?.predefinedMsg)) || disableMessagePopup }" (click)="disableMessagePopup ? false : sendMessage()">{{'btnSendText' | translate}}</a>
    </div>
  </div>
</div>

<div class="chat-box" *ngIf="inputString == 'history'">
  <div class="chat-box__chat-header seat-popover-header">
    {{'guestbookRecentHistoryText' | translate}}
  </div>
  <div class="chat-box__history-view seat-popover-content">
    <div *ngFor="let history of historyData" class="chat-box__history-data">
      <span class="icon-Group-595 "></span>
      <div class="">
        <span style="display: block;">{{history.DateTime | formatSettingsDate: ps.cs.settings.value.General.DateFormat }} / {{history.SeatingTime | formatTime}} - {{history.DepartureTime |formatTime}}</span>
        <span style="display: block">{{history.PartySize}} {{'seats' | translate}} </span>
      </div>
      <span [ngStyle]="{'color': history.PartyState | reservationStatus: 'color'}">{{history.PartyState | reservationStatus: 'historyStatus' |translate}}</span>
    </div>
  </div>
</div>
