<ng-scrollbar>
    <div class="open-booking-summary">
        <div class="open-booking-summary__booking-detail">
            <div class="open-booking-summary__summary-accordian" *ngIf="partyService.openBookingData">
                <mat-accordion class="p-10">
                    <mat-expansion-panel [expanded]="true" class="open-booking-summary__summary-accordian--panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title
                                class="open-booking-summary__section-title open-booking-summary__section-title--accordian">
                                {{'bookingSummary' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="open-booking-summary__content">
                            <div class="open-booking-summary__item open-booking-summary__item--name">
                                <div class="open-booking-summary__item__label">{{'activityName' | translate}}</div>
                                <div class="openbooking-summary__item__value">{{'OpenBooking' | translate}}</div>
                            </div>
                            <div class="open-booking-summary__item open-booking-summary__item--location">
                                <div class="open-booking-summary__item__label">{{'location' | translate}}</div>
                                <div class="openbooking-summary__item__value">
                                    {{partyService.openBookingData.LocationName}}</div>
                            </div>
                            <div class="open-booking-summary__item open-booking-summary__item--date">
                                <div class="open-booking-summary__item__label">{{'date' | translate}}</div>
                                <div class="openbooking-summary__item__value">
                                    {{partyService.openBookingData.FromDate | formatSettingsDate: cs.settings.value.General.DateFormat}} <span
                                        class="text-small">
                                        {{(partyService.openBookingData.Slots[partyService.openBookingData.Slots.length
                                        -1].LocalTime | formatSettingsDate: cs.settings.value.General.DateFormat) !== (partyService.openBookingData.FromDate | formatSettingsDate: cs.settings.value.General.DateFormat )? '(Extended
                                        Shift)' : '' }}</span></div>
                            </div>
                            <div class="open-booking-summary__item  open-booking-summary__item--booking-size">
                                <div class="open-booking-summary__item__label">{{'bookingSize' | translate}}</div>
                                <div class="openbooking-summary__item__value">
                                    {{partyService.openBookingData?.BookingSize}}</div>
                            </div>
                            <div class="open-booking-summary__item  open-booking-summary__item--slots" *ngIf="ratePlan">
                                <div class="open-booking-summary__item__label">{{'timeSlots' | translate}}</div>
                                <div class="open-booking-summary__item__value"
                                    *ngIf="ratePlan.RatePlanCalculations && ratePlan.TotalRatePlan"><span
                                        *ngFor="let slot of ratePlan.SlotList | slice:0:(showAllSlots ? partyService.openBookingData.Slots.length : 4 ); let index = index;">{{slot.TimeRange.Start
                                        | localizedDate:'LT'}}
                                        - {{slot.TimeRange.End | localizedDate: 'LT'}}{{partyService.openBookingData.Slots[index+1] && ','}}
                                        &nbsp; </span></div>
                                <div class="open-booking-summary__item__value" *ngIf="!ratePlan.TotalRatePlan"><span
                                        *ngFor="let slot of ratePlan.SlotList | slice:0:(showAllSlots ? partyService.openBookingData.Slots.length : 4 ); let index = index;">{{slot.TimeRange.Start
                                        | localizedDate:'LT'}}
                                        - {{slot.TimeRange.End | localizedDate: 'LT'}}{{partyService.openBookingData.Slots[index+1] && ','}}
                                        &nbsp; </span></div>
                                <div class="open-booking-summary__slots-show-action"
                                    *ngIf="partyService.openBookingData.Slots.length > 4"
                                    (click)="showAllSlots = !showAllSlots">
                                    {{showAllSlots ? 'Show Less' : '+'+(partyService.openBookingData.Slots.length-4)+'
                                    '+'more'}}</div>

                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="open-booking-summary__summary-accordian" *ngIf="selectedGuest">
                <mat-accordion class="p-10">
                    <mat-expansion-panel [expanded]="true" class="open-booking-summary__summary-accordian--panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title
                                class="open-booking-summary__section-title open-booking-summary__section-title--accordian">
                                {{'GuestDetails' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="open-booking-summary__content open-booking-summary__column-gap">
                            <div class="open-booking-summary__item open-booking-summary__item--location">
                                <div class="open-booking-summary__item__label">{{'FirstName' | translate}}</div>
                                <div class="open-booking-summary__item__value">
                                    {{selectedGuest?.FirstName}}
                                </div>
                            </div>
                            <div class="open-booking-summary__item open-booking-summary__item--location">
                                <div class="open-booking-summary__item__label">{{'LastName' | translate}}</div>
                                <div class="open-booking-summary__item__value">
                                    {{selectedGuest?.LastName}}
                                </div>
                            </div>
                            <div class="open-booking-summary__item open-booking-summary__item----name">
                                <div class="open-booking-summary__item__label">{{'Email' | translate}}</div>
                                <div class="open-booking-summary__item__value">
                                    {{selectedGuestt?.EmailAddress}}
                                </div>
                            </div>
                            <div class="open-booking-summary__item open-booking-summary__item----name">
                                <div class="open-booking-summary__item__label">{{'Mobile' | translate}}</div>
                                <div class="open-booking-summary__item__value">
                                    {{selectedGuest?.PhoneNumber}}
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="open-booking-summary__rate-plan"
                *ngIf="ratePlan && ratePlan.TotalRatePlan && ratePlan.RatePlanCalculations">
                <div class="open-booking-summary__section-title">
                    {{'Rates' | translate}}
                </div>
                <mat-accordion *ngFor="let slot of ratePlan.SlotList; let index= index;">
                    <mat-expansion-panel [expanded]="index == 0"
                        class="open-booking-summary__summary-accordian--panel open-booking-summary__summary-accordian--rate-plan">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="open-booking-summary__section-title--accordian">
                                {{slot.TimeRange.Start | localizedDate:'LT'}} - {{slot.TimeRange.End | localizedDate:'LT'}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="open-booking-summary__rate-row" *ngIf="slot.RatePlanVariants.TotalBaseRate">
                            <div class="open-booking-summary__rate-label">
                                <span class="open-booking-summary__rate-label--main">{{'TotalBaseRate' |
                                    translate}}</span>
                            </div>
                            <div class="open-booking-summary__rate">{{OperationCurrency}}
                                {{slot.RatePlanVariants.TotalBaseRate | currencyDecimalFormatter}}</div>
                        </div>
                        <div class="open-booking-summary__rate-row" *ngIf="slot.RatePlanVariants.LocationVariant">
                            <div class="open-booking-summary__rate-label">
                                <span class="open-booking-summary__rate-label">{{'LocationVariant' | translate}} <span
                                        class="sub-text">({{'forTotalOf' | translate}}
                                        {{partyService.openBookingData?.BookingSize}}
                                        )</span>
                                </span>
                            </div>
                            <div class="open-booking-summary__rate">{{OperationCurrency}}
                                {{slot.RatePlanVariants.LocationVariant | currencyDecimalFormatter}}</div>
                        </div>
                        <div class="open-booking-summary__rate-row"
                            *ngIf="slot.bookingTypeVariants && slot.bookingTypeVariants.length">
                            <div class="open-booking-summary__rate-label">
                                <span class="open-booking-summary__rate-label sub-label">{{'bookingType' | translate}}
                                </span>
                            </div>
                        </div>

                        <div class="open-booking-summary__rate-row" *ngFor="let variant of slot.bookingTypeVariants">
                            <div class="open-booking-summary__rate-label" *ngIf="variant.Variant">
                                <span class="open-booking-summary__rate-label--main">{{variant.BookingTypeName}}</span>
                                <span class="open-booking-summary__rate-label--sub">{{variant.bookingTypes}}</span>
                            </div>
                            <div class="open-booking-summary__rate">{{OperationCurrency}} {{variant.Variant |
                                currencyDecimalFormatter}}</div>
                        </div>
                        <div class="open-booking-summary__rate-row"
                            *ngIf="slot.coverTypeVariants && slot.coverTypeVariants.length">
                            <div class="open-booking-summary__rate-label">
                                <span class="open-booking-summary__rate-label sub-label">{{'coverType' | translate}}
                                </span>
                            </div>
                        </div>
                        <div class="open-booking-summary__rate-row" *ngFor="let variant of slot.coverTypeVariants">
                            <div class="open-booking-summary__rate-label" *ngIf="variant.Variant">
                                <span class="open-booking-summary__rate-label--main">{{variant.CoverTypeName}}</span>
                                <span class="open-booking-summary__rate-label--sub">{{variant.covers}}</span>
                            </div>
                            <div class="open-booking-summary__rate">{{OperationCurrency}} {{variant.Variant |
                                currencyDecimalFormatter}}</div>
                        </div>

                        <div class="open-booking-summary__rate-row" *ngIf="slot.MemberShipVariant">
                            <div class="open-booking-summary__rate-label">
                                <span class="open-booking-summary__rate-label--main">{{'MemberShipVariant' |
                                    translate}}</span>
                            </div>
                            <div class="open-booking-summary__rate">{{OperationCurrency}} {{slot.MemberShipVariant |
                                currencyDecimalFormatter}}</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div class="open-booking-summary__rate-row open-booking-summary__total">
                    <div class="open-booking-summary__rate-label">
                        <span class="open-booking-summary__rate-label">{{'total' | translate}}</span>
                    </div>
                    <div class="open-booking-summary__rate">{{OperationCurrency}} {{(ratePlan.TotalRatePlan || ratePlan.TotalAddonAmount ?
                        ((ratePlan.TotalRatePlan || 0) + (ratePlan.TotalAddonAmount || 0) | currencyDecimalFormatter) : '0.00') }}
                    </div>
                </div>
                <div class="open-booking-summary__rate-row open-booking-summary__total negotiation-label m-0 pt-0" *ngIf="isServiceConfigured">
                    <div class="open-booking-summary__rate-label">
                        <span class="open-booking-summary__rate-label">{{'serviceChargesWithPlural' | translate}}</span>
                    </div>
                    <div class="open-booking-summary__rate">{{OperationCurrency}} {{(ratePlan.TotalServiceChargeAmount ?
                        ((ratePlan.TotalServiceChargeAmount || 0) + (ratePlan.TotalAddonServiceChargeAmount || 0) | currencyDecimalFormatter) : '0.00') }}
                    </div>
                </div>
                <div class="row negotiation-label" *ngIf="isServiceConfigured">
                    <div class="col-lg-6 col-md-6">
                      <span class="open-booking-summary__rate-label">{{'serviceChargesTaxWithPlural' | translate}}</span>
                    </div>
                    <div class="col-lg-6 col-md-5  text-right">{{OperationCurrency}}
                      {{(((ratePlan.TotalTaxOnServiceChargeAmount || 0) + (ratePlan.TotalAddonTaxOnServiceChargeAmount || 0)) | currencyDecimalFormatter)  }}
          </div>
                  </div>
                  

                <div class="open-booking-summary__rate-row open-booking-summary__total negotiation-label m-0 pt-0" *ngIf="isTaxConfigured">
                    <div class="open-booking-summary__rate-label">
                        <span class="open-booking-summary__rate-label">{{'tax' | translate}}</span>
                    </div>
                    <div class="open-booking-summary__rate">{{OperationCurrency}} {{(ratePlan.TotalTaxAmount || ratePlan.TotalAddonTaxAmount ?
                        ((ratePlan.TotalTaxAmount || 0) + (ratePlan.TotalAddonTaxAmount || 0) | currencyDecimalFormatter) : '0.00') }}
                    </div>
                </div>
                <div class="row negotiation-label">
                    <div class="col-lg-6 col-md-6">
                        <span class="activity-booking-summary__rate-label">{{'negotiateValue' | translate}}</span>
                    </div>
                    <div class="col-lg-6 col-md-5  text-right">
                        {{(!partyService.openBookingDataSaveObj?.NegotiatedAmount
                        || partyService.openBookingDataSaveObj?.NegotiatedAmount >= 0 ? '+ ' :
                        (partyService.openBookingDataSaveObj?.NegotiatedAmount == 0 ?'' : '-'))}} {{OperationCurrency}}
                        {{(partyService.openBookingDataSaveObj?.NegotiatedAmount
                        ? (partyService.openBookingDataSaveObj?.NegotiatedAmount | negtiationRateFormatter) :
                        '0.00') }}
                    </div>
                </div>
                <div class="row negotiation-label">
                    <div class="col-lg-6 col-md-6">
                        <span class="activity-booking-summary__rate-label"> {{'findTotal' | translate}}</span>
                    </div>
                    <div class="col-lg-6 col-md-5  text-right">
                        <span class="icon-information"
                            *ngIf="partyService.openBookingDataSaveObj?.Comments && partyService.openBookingDataSaveObj?.Comments !== ''"
                            matTooltipClass="tooltip-class"
                            title="{{partyService.openBookingDataSaveObj?.Comments}}"></span>
                        {{OperationCurrency}} {{(partyService.openBookingDataSaveObj?.TotalPayable ?
                        ((partyService.openBookingDataSaveObj?.TotalPayable + (ratePlan?.TotalTaxAmount || 0) + (ratePlan?.TotalAddonTaxAmount || 0)) +((ratePlan?.TotalServiceChargeAmount || 0) + (ratePlan?.TotalAddonServiceChargeAmount || 0)) | currencyDecimalFormatter) :
                        '0.00') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="open-booking-summary__guest-details" *ngIf="partyService.openBookingDataSaveObj">
            <app-iframe-container [iframe]="partyService.editIframeUrl"
                [SaveBookingObj]="partyService.openBookingDataSaveObj"
                (ReservationAttemptId)="setReservationData($event)" [componentType]="fromComponent">
            </app-iframe-container>
        </div>
    </div>
</ng-scrollbar>
