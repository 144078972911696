<section class="payment-popup">
    <form [formGroup]="cashPayment">
      <div class="payment-amount">
        <mat-form-field class="payment-tendered" [floatLabel]="floatLabel">
          <input [attr.automationId]="'Txt_shopPayment_amounttoBePaid'" matInput formControlName="amountpaid" placeholder="{{this.captions.amountToBePaid}}" value="{{datainput.totalamount}}" required>
        </mat-form-field>
        <mat-form-field >
          <input [attr.automationId]="'Txt_shopPayment_cashReceived'" matInput #cashReceived placeholder="{{this.captions.cashReceived}}" CurrencyFormatter formControlName="cashreceived" (keyup)="receivedCash()" required>
          <mat-error *ngIf="fullPaymentValidateBoolean && cashPayment.controls['cashreceived'].valid">Fill the fullpayment</mat-error>
        </mat-form-field>
    </div>
    <div class="payment-due-client">
       <label class="payment-due-label LW18 blckClr">{{this.captions.remainingChange}}</label>
       <span  class="LW18 blckClr">{{cashBalance | showPaymentAmount: cashBalance : true : 0 : true }}</span>
    </div>
    <div class="payment-footer">
            <button [attr.automationId]="'Btn_shopPayment_ok'" [ngClass]="(cashPayment.valid && fullPaymentValidateBoolean) || this.isZeroAmount ? 'button--primary-save' : 'button--disabled'" [disabled]="cashPayment.invalid && !this.isZeroAmount" mat-button class="mat-raised-button whitecolor LW14 ml-3 text-capital" (click)="closeDialog()">{{this.captions.OK}}</button>
    </div>
    </form>
  </section>
