
<div [ngSwitch]="buttontype.type" class="custom-button clear">
    <button attr.automationId='Btn_{{automationId}}_{{buttontype?.label}}' class="golfbutton" [ngClass]="buttontype.customclass" #dynamicbutton *ngSwitchCase="'primary'" [disabled]='buttontype.disabledproperty' mat-flat-button color="primary" (click)="handleclick($event)">{{ buttontype.label | translate }}</button>
    <button attr.automationId='Btn_{{automationId}}_{{buttontype?.label}}' class="golfbutton golf--secondarygreen" [ngClass]="buttontype.customclass" #dynamicbutton  *ngSwitchCase="'secondary'" [disabled]='buttontype.disabledproperty' mat-stroked-button (click)="handleclick($event)">{{buttontype.label | translate}}</button>
    <a attr.automationId='Btn_{{automationId}}_{{buttontype?.label}}' class="golfbutton"  [ngClass]="buttontype.customclass" #dynamicbutton *ngSwitchCase="'tertiary'" [disabled]='buttontype.disabledproperty' mat-button  href="javascript:void(0)" (click)="handleclick($event)">{{buttontype.label | translate}}</a>

    <button attr.automationId='Btn_{{automationId}}_{{buttontype?.label}}' class="golfbutton golf--secondarygreen" [ngClass]="buttontype.customclass" #dynamicbutton  *ngSwitchCase="'multiselect'" [disabled]='buttontype.disabledproperty' mat-stroked-button color="primary">{{buttontype.label | translate}}</button>
</div>




