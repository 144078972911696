<section class="member-mapping__section ag_pl--6 ag_pt--6" [formGroup]="memberaccrualmapform">

    <div>
        <ag-input [automationId]="'Txt_memberAccrualMapCrud_bucketCode'" class='ag_display--block ag_w--60' formControlName='bucketcode'
                [placeholder]="captions.lbl_bucketcode" [maxlength]="100" ></ag-input>
    </div>
    <div>
        <ag-input [automationId]="'Txt_memberAccrualMapCrud_bucketName'" class='ag_display--block ag_w--60' formControlName='bucketname'
                [placeholder]="captions.lbl_bucketname" [maxlength]="100" ></ag-input>
    </div>
    <!-- <div class="yes-col ag_ml--auto ag_display--flex ag_mt--2" >
      <app-toggle-switch (changeToggleEvent)="applyToAll($event[0])"></app-toggle-switch>
      <label class="LW12 d-block form-label-normal ag_mt--1 ag_pl--5">{{captions.lbl_applyforall}}</label>
    </div> -->
    <div class="ag_display--flex">
      <div class="">
        <label class="form-label-normal">{{captions.lbl_applyForAllPaymentMethodsForTheSameOutlet}}</label>
        <app-retail-toggle-switch [attr.automationId]="'Txt_memberAccrualMapCrud_allPaymentApply'" class="d-block" (changeToggleEvent)="applyForAllPaymentMethodsForTheSameOutlet($event[0])" formControlName='applyForAllPaymentMethodsForTheSameOutlet'>
        </app-retail-toggle-switch>
      </div>
    </div>
    <div class="ag_display--flex" *ngIf = !isFromResortFinance>
      <div class="mt-2">
        <label class="form-label-normal">{{captions.lbl_applyForOutletsForTheSamePaymentMethod}}</label>
        <app-retail-toggle-switch [attr.automationId]="'Txt_memberAccrualMapCrud_applyOutlets'" class="d-block" (changeToggleEvent)="applyForOutletsForTheSamePaymentMethod($event[0])" formControlName='applyForOutletsForTheSamePaymentMethod'>
        </app-retail-toggle-switch>
      </div>
    </div>
  </section>

  <div mat-dialog-actions class="actions-div">
    <div class="pl-4">
      <button [attr.automationId]="'Btn_memberAccrualMapCrud_save'" [ngClass]="isValid && memberaccrualmapform.valid && memberaccrualmapform.dirty? 'button--primary-save' : 'button--disabled'" mat-button [disabled]="false"
        (click)="saveMappingType()">{{commonCaptions.Save}}</button>
      <button class="spa-button-cancel" mat-button (click)="onClick()">{{commonCaptions.Cancel}}</button>
    </div>
  </div>
