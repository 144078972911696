import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { LoaderService } from '@app/core/services/loader.service';
import { PopupService } from '@app/popup-module/popup.service';
import { buttonTypes } from '@app/shared/constants/commonenums';
import { ButtonValue } from '@app/shared/dynamicform/models/field-config.interface';
import { OperationResultDTO } from '@app/shared/models/ChangeTrackingOperationResultDTO';
import { Host } from '@app/shared/models/HostDTO';
import { PartyService } from '@app/shared/services/party.service';
import { SettingsService } from '@app/shared/services/settings.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-pin',
  templateUrl: './reset-pin.component.html',
  styleUrls: ['./reset-pin.component.scss']
})
export class ResetPinComponent extends Utilities implements OnInit {
  userPinval: number = null;
  ishasValue = false;
  ithaserror = false;
  explainBtn: ButtonValue;
  cancelBtn: ButtonValue;
  @Input() data: Host;
  errorValue = '';
  constructor(public partyService: PartyService, private ps: PopupService,
    public dialog: MatDialog, private as: AppService,private ls: LoaderService, private ts: TranslateService ) {
    super(dialog);

  }

  ngOnInit() {
    this.explainBtn = {
      label: 'Reset',
      type: buttonTypes.actionPrimary,
      disbaledproperity: true,
      customclass: 'quick-seat'
    };

    this.cancelBtn = {
      label: 'cancel',
      type: buttonTypes.actionSecondary,
      disbaledproperity: false,
      customclass: 'quick-seat'
    };
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.ithaserror = true;
      this.errorValue = this.ts.instant('enterNumericValueError');
      return false;
    }
    this.ithaserror = false;
    this.errorValue = '';
    return true;

  }

  checkEnterval(event) {
    let chk = this.userPinval;
    if (this.userPinval != null) {
      this.ishasValue = true;
    }
    if (isNaN(chk)) {
      // this.emptyval();
      this.ithaserror = true;
      this.errorValue = this.ts.instant('enterNumericValueError');
      return false;
    } else {
      this.ithaserror = false;
      this.errorValue = '';
      this.isUserPINComplete();
      return true;
    }
  }

  numberpress(val) {
    if (!this.userPinval || this.userPinval.toString().length < 4) {
      this.ishasValue = true;
      this.ithaserror = false;
      this.errorValue = '';
      this.userPinval = this.userPinval != null ? this.userPinval.toString() + val.toString() : val;
      this.isUserPINComplete();
    }
  }

  isUserPINComplete() {
    if (this.userPinval.toString() == this.data.PinCode) {
      this.ithaserror = true;
      this.errorValue = this.ts.instant('passwordError');
      this.ps.saveBtnEnable$.next(false);
    } else if (this.userPinval && this.userPinval.toString().length == 4) {
      this.as.isdisconnectedbyuser = true;
      this.ps.saveBtnEnable$.next(true);
      this.ls.resetPin$.next(this.userPinval);
    }
  }

  emptyval() {
    this.userPinval = null;
    this.ishasValue = false;
    this.ithaserror = false;
    this.errorValue = '';
  }

  removelastval() {
    let str = this.userPinval != null ? this.userPinval.toString() : " ";
    str = str.substring(0, str.length - 1);
    this.userPinval = str ? parseInt(str) : null;
    if (this.userPinval) {
      this.ishasValue = true;
    } else {
      this.ishasValue = false;
    }
  }

  ngOnDestroy() {
    this.ps.restrictCloseDialog = false;
  }

}
