import { CommonModule, TitleCasePipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckIconVisibilityPipe } from '@app/guestbook/guestdetails/guestdetails.component';
import { MaterialModule } from '@app/material-module';
import { CoverReportsComponent, GetCovorColorPipe } from '@app/settings/covereports/coverreports-component';
import { AccordionComponent } from '@components/accordingsidebar/accordingsidebar.component';
import { AuditlogComponent } from '@components/auditlog/auditlog.component';
import { ButtonComponent } from '@components/button/button.component';
import { CancelReasonComponent } from '@components/cancel-reason/cancel-reason.component';
import { ChatViewComponent } from '@components/chat-view/chat-view.component';
import { CheckUserPermissionsComponent } from '@components/check-user-permissions/check-user-permissions.component';
import { ChitPrintComponent, ConcatNotesFilterPipe } from '@components/chit-print/chit-print.component';
import { CircleComponent } from '@components/circlecomponent/circle.component';
import { ClientSectionComponent } from '@components/client-section/client-section.component';
import { ConfirmationMessagesPopupComponent } from '@components/confirmation-messages-popup/confirmation-messages-popup.component';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { AdvanceBlockComponent } from '@components/create-update-blocking-tab-layout/advance-block/advance-block.component';
import { BlockingDateSelectionComponent } from '@components/create-update-blocking-tab-layout/blocking-date-selection/blocking-date-selection.component';
import { BlockingTableSelectionComponent } from '@components/create-update-blocking-tab-layout/blocking-table-selection/blocking-table-selection.component';
import { BlockingrulePopupservice } from '@components/create-update-blocking-tab-layout/blockingrulepopup.service';
import { DateRangeSelectionComponent } from '@components/create-update-blocking-tab-layout/date-range-selection/date-range-selection.component';
import { ScheduleSelectionComponent } from '@components/create-update-blocking-tab-layout/schedule-selection/schedule-selection.component';
import { ShiftSelectionComponent } from '@components/create-update-blocking-tab-layout/shift-selection/shift-selection.component';
import { AdditionalinfoComponent } from '@components/create-update-guest-tab-layout/definitions/additionalinfo/additionalinfo.component';
import { AddressComponent } from '@components/create-update-guest-tab-layout/definitions/address/address.component';
import { ContactComponent } from '@components/create-update-guest-tab-layout/definitions/contact/contact.component';
import { PersonalInfoComponent } from '@components/create-update-guest-tab-layout/definitions/personal-info/personal-info.component';
import { PreferencesComponent } from '@components/create-update-guest-tab-layout/definitions/preferences/preferences.component';
import { DateSelectionComponent } from '@components/create-update-party-tab-layout/selections/date-selection/date-selection.component';
import { GeneralDataComponent } from '@components/create-update-party-tab-layout/selections/general-data/general-data.component';
import { GuestDataComponent } from '@components/create-update-party-tab-layout/selections/guest-data/guest-data.component';
import { EmailSelectorPipe, GuestSelectionComponent, phoneNumberSelectorPipe } from '@components/create-update-party-tab-layout/selections/guest-selection/guest-selection.component';
import { ViewSecondaryGuestDetailsComponent } from '@components/create-update-party-tab-layout/selections/guest-selection/view-secondary-guest-details/view-secondary-guest-details.component';
import { ViewSelectedGuestComponent } from '@components/create-update-party-tab-layout/selections/guest-selection/view-selected-guest/view-selected-guest.component';
import { HotelConciergeListComponent } from '@components/create-update-party-tab-layout/selections/hotel-concierge-list/hotel-concierge-list.component';
import { ManualSlotComponent } from '@components/create-update-party-tab-layout/selections/manual-slot/manual-slot.component';
import { NewSlotComponent } from '@components/create-update-party-tab-layout/selections/new-slot/new-slot.component';
import { RestaurantPoliciesComponent } from '@components/create-update-party-tab-layout/selections/restaurant-policies/restaurant-policies.component';
import { SeatingAreaSelectionComponent } from '@components/create-update-party-tab-layout/selections/seating-area/seating-area-selection.component';
import { SizeSelectionComponent } from '@components/create-update-party-tab-layout/selections/size-selection/size-selection.component';
import { SpecialMealComponent } from '@components/create-update-party-tab-layout/selections/special-meal/special-meal.component';
import { TableSelectionComponent } from '@components/create-update-party-tab-layout/selections/table-selection/table-selection.component';
import { CustomWaitTimePipe, WaitTimeComponent } from '@components/create-update-party-tab-layout/selections/wait-time/wait-time.component';
import { ContactListComponent, ContactNamePipe, ContactNamePipeInitialsPipe } from '@components/dashboard/contact-list/contact-list.component';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { EditorFloorsComponent } from '@components/dashboard/editor-view/editor-floors/editor-floors.component';
import { EditorJoinedTablesComponent, GetNamePipe } from '@components/dashboard/editor-view/editor-joined-tables/editor-joined-tables.component';
import { EditorLayoutComponent } from '@components/dashboard/editor-view/editor-layout/editor-layout.component';
import { EditorTablesComponent } from '@components/dashboard/editor-view/editor-tables/editor-tables.component';
import { EditorViewComponent } from '@components/dashboard/editor-view/editor-view.component';
import { ImageActionsComponent } from '@components/dashboard/editor-view/image-actions/image-actions.component';
import { ImageGalleryComponent } from '@components/dashboard/editor-view/image-gallery/image-gallery.component';
import { DashboardHeaderComponent } from '@components/dashboard/header/dashboard-header.component';
import { DashboardMessageComponent, MsgStripPipe } from '@components/dashboard/message/dashboard-message.component';
import { DashboardPartyInfoComponent, QuickSeatGuestNameFormatPipe, ServersSetterPipe, TableNameSetterPipe, TimeFormat, SeatingAreaNamePipe } from '@components/dashboard/party-info/dashboard-party-info.component';
import { ServerViewComponent, ShowCountPipe } from '@components/dashboard/server-view/server-view.component';
import { DashboardStatusComponent } from '@components/dashboard/status/dashboard-status.component';
import { DisplayLegendComponent } from '@components/display-legend/display-legend.component';
import { DropdownbuttonComponent } from '@components/dropdownbutton/dropdownbutton.component';
import { EditModeDirective } from '@components/editable/edit-mode.directive';
import { EditableComponent } from '@components/editable/editable.component';
import { ViewModeDirective } from '@components/editable/view-mode.directive';
import { ExceptionPopupComponent } from '@components/exception-popup/exception-popup.component';
import { ExplainUserPermissionsComponent } from '@components/explain-user-permissions/explain-user-permissions.component';
import { FilterContentComponent } from '@components/filter-content/filter-content.component';
import { GuestViewComponent } from '@components/guest-view/guest-view.component';
import { LayoutEditorComponent } from '@components/layout-editor/layout-editor.component';
import { LayoutViewComponent } from '@components/layout-view/layout-view.component';
import { MessageStatusComponent } from '@components/message-status/message-status.component';
import { MultiplePartiesComponent } from '@components/multiple-parties/multiple-parties.component';
import { NewFloorPlanComponent } from '@components/new-floor-plan/new-floor-plan.component';
import { MessagesViewComponent } from '@components/notification-view/messages-view/messages-view.component';
import { NotificationViewComponent } from '@components/notification-view/notification-view.component';
import { ReservationViewComponent } from '@components/notification-view/reservation-view/reservation-view.component';
import { TablesViewComponent } from '@components/notification-view/tables-view/tables-view.component';
import { OverrideRemainingTimeComponent } from '@components/override-remaining-time/override-remainingtime';
import { CommunicationIconPipe, FormatWaitTimePipe, NegativeNumberFormatPipe, PartiesgridComponent } from '@components/parties/grid/partiesgrid.component';
import { PartieslistComponent } from '@components/parties/list/partieslist.component';
import { PreferredTableComponent } from '@components/preferred-table/preferred-table.component';
import { ReservationChangeConfirmComponent } from '@components/reservation-change-confirm/reservation-change-confirm.component';
import { ReservationTimesPopupComponent } from '@components/reservation-times-popup/reservation-times-popup.component';
import { SeatStatusComponent } from '@components/seatstatus/seatstatus.component';
import { ReservationTypeComponent } from '@components/select-reservation-type/select-reservation-type.component';
import { SelectServerComponent } from '@components/select-server/select-server.component';
import { SelectionPopUpComponent } from '@components/selectionpopup/selection-popup.component';
import { StandbyListComponent } from '@components/standby-list/standby-list.component';
import { TableDetailsListComponent } from '@components/table-details-list/table-details-list.component';
import { TableDetailsComponent } from '@components/table-details/table-details.component';
import { TableNotAvailableForReservationsComponent } from '@components/table-not-available-for-reservations/table-not-available-for-reservations.component';
import { QuotedTimeFormatterPipe, RemainingTimeFormatterPipe, TableListComponent } from '@components/tablelist/tablelistComponent';
import { TablesLayoutViewComponent } from '@components/tables-layout-view/tables-layout-view.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { UploadPhotoComponent } from '@components/upload-photo/upload-photo.component';
import { WaitTimeReportComponent } from '@components/waittime-report/waittime-report-component';
import { AlphaNumericDirective } from '@directives/alpha-numeric.directive';
import { BlockPasteDirective } from '@directives/block-paste.directive';
import { DigitOnlyDirective } from '@directives/digit-only.directive';
import { DisableControlDirective } from '@directives/disable-control.directive';
import { NumDotOnlyDirective } from '@directives/num-dot-only.directive';
import { NummaxLength } from '@directives/num-maxlength.directive';
import { NumberOnlyDirective } from '@directives/num-only.directive';
import { NumberDivisibleOfFiveDirective } from '@directives/num-divisible-of-five.directive';
import { PhoneNumberFormatDirective } from '@directives/phone-number-format.directive';
import { RequiredLabelDirective } from '@directives/required-label.directive';
import { ShowellipsisTextDirective } from '@directives/showellipsis-text.directive';
import { ViewmoreDirective } from '@directives/view-more.directive';
import { AutoSyncComponent } from '@dynamicform/components/auto-sync/auto-sync.component';
import { ContentComponent } from '@dynamicform/components/content/content.component';
import { DynamicFieldDirective } from '@dynamicform/components/dynamic-field/dynamic-field.directive';
import { FormAddButtonComponent } from '@dynamicform/components/form-add-button/form-add-button.component';
import { FormAutocompleteComponent } from '@dynamicform/components/form-autocomplete/form-autocomplete.component';
import { FormButtonComponent } from '@dynamicform/components/form-button/form-button.component';
import { FormCheckboxComponent } from '@dynamicform/components/form-checkbox/form-checkbox.component';
import { FormChipListComponent } from '@dynamicform/components/form-chip-list/form-chip-list.component';
import { FormChipComponent } from '@dynamicform/components/form-chip/form-chip.component';
import { FormdateComponent } from '@dynamicform/components/form-date/form-date.component';
import { FormInputAutocompleteComponent } from '@dynamicform/components/form-input-autocomplete/form-input-autocomplete.component';
import { FormInputComponent } from '@dynamicform/components/form-input/form-input.component';
import { FormMonthDatePickerComponent } from '@dynamicform/components/form-month-date-picker/form-month-date-picker.component';
import { MonthHeaderComponent } from '@dynamicform/components/form-month-date-picker/month-header/month-header.component';
import { FormMultiSelectComponent } from '@dynamicform/components/form-multi-select/form-multi-select.component';
import { FormNumberComponent } from '@dynamicform/components/form-number/form-number.component';
import { FormRadioComponent } from '@dynamicform/components/form-radio/form-radio.component';
import { ResetbuttonComponent } from '@dynamicform/components/form-reset-button/form-reset-button.component';
import { FormSelectComponent, ShowSelectedOptionTitlePipe } from '@dynamicform/components/form-select/form-select.component';
import { FormSliderComponent } from '@dynamicform/components/form-slider/form-slider.component';
import { FormSwitchComponent } from '@dynamicform/components/form-switch/form-switch.component';
import { FormTextareaComponent } from '@dynamicform/components/form-textarea/form-textarea.component';
import { FormtoggleComponent } from '@dynamicform/components/form-toggle/form-toggle.component';
import { LabelComponent } from '@dynamicform/components/label/label.component';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { DynamicFormService } from '@dynamicform/service/dynamic-form.service';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CancelorNoShowActionPipe } from '@pipes/Allow-CancelorNoShowAction.pipe';
import { EditOrSeatActionPipe } from '@pipes/Allow-EditorSeatAction.pipe';
import { AuditlogItemFilterPipe, SessionMultiValuePipe, FormatStringPipe } from '@pipes/auditlogItem-filter.pipe';
import { BackgroundColorFormatterPipe } from '@pipes/background-color-formatter.pipe';
import { ButtonInputFormater } from '@pipes/button-input-formatter.pipe';
import { ContactNameFilterPipe } from '@pipes/contactNameFilter.pipe';
import { DateFormatPipe } from '@pipes/date-format.pipe';
import { formatDatePipe } from '@pipes/dateformat.pipe';
import { EffectiveRangePartiesPipe } from '@pipes/effective-range-parties.pipe';
import { FilterDataPipe } from '@pipes/filter-data.pipe';
import { FindIndex } from '@pipes/findIndex.pipe';
import { FormattedDigitPipe } from '@pipes/formatted-digit.pipe';
import { GetSeatingAreaNamePipe } from '@pipes/get-seatingarea-name.pipe';
import { GridCellDataManipulatorPipe } from '@pipes/grid-cell-data-manipulator.pipe';
import { GuestNameFormatPipe, CapitalizeFirstCharacterInText } from '@pipes/guestname-initial-formatter.pipe';
import { GuestTitlePipe } from '@pipes/GuestTitle.pipe';
import { HeaderNamePipe } from '@pipes/header-name.pipe';
import { HeaderSeatingAreaFilterPipe } from '@pipes/header-seating-area-filter.pipe';
import { InitialFormatterPipe } from '@pipes/initial-formatter.pipe';
import { NameFormatPipe } from './pipes/name-format.pipe';
import { PageIconPipe } from '@pipes/page-icon.pipe';
import { PartyStatusFilterPipePipe } from '@pipes/party-status-filter-pipe.pipe';
import { PartyDataFilterPipe } from '@pipes/partyDataFilter.pipe';
import { PartySourceOTAPipe } from '@pipes/PartySourceOTA.pipe';
import { PercentageColorConverterPipe } from '@pipes/percentage-color-converter.pipe';
import { PhoneNumberFormatterPipe } from '@pipes/phone-number-formatter.pipe';
import { GetRandomColorPipe } from '@pipes/random-color.pipe';
import { ReservationNotesPipe } from '@pipes/reservation-notes.pipe';
import { ReservationStatusColorPipe } from '@pipes/reservation-status-color.pipe';
import { ReservationBookingStatusPipe, ReservationStatusPipe } from '@pipes/reservation-status.pipe';
import { SeatedStatusColorPipe } from '@pipes/seated-status-color.pipe';
import { SlotTimeFormatter } from '@pipes/slot-time-formatter.pipe';
import { SortReservationsPipe } from '@pipes/sort-reservations.pipe';
import { StatusIndicatorPipe } from '@pipes/status-indicator.pipe';
import { SwitchInputFormater } from '@pipes/switch-input-pipe';
import { TablenameFormatPipe } from '@pipes/tablename-format.pipe';
import { TimeLapsePipe } from '@pipes/time-lapse.pipe';
import { HasPermisssionCustomPipe, RolesAndPermissionPipe } from '@pipes/RolesAndPermission.pipe';
import { PartyPaymentStatePipe } from './pipes/PartyPaymentState.pipe';
import { customGuestFieldSelectorPipe } from '@pipes/custom-guest-fieldSelector';
import { DashboardFunctions } from '@utilities/dashboard-functions';
import { LayoutFunctions } from '@utilities/layout-functions';
import { LoginFunction } from '@utilities/login-function';
import { ResultProcesserFunction } from '@utilities/result-processer';
import { UserPermissionFunctions } from '@utilities/user-permission-functions';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  PerfectScrollbarConfigInterface, PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { NgPipesModule } from 'ngx-pipes';
import { NgxPrintModule } from 'ngx-print';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { CancelActivityPopupComponent } from './components/cancel-activity-popup/cancel-activity-popup.component';
import { ActivityBookingFormComponent } from './components/create-update-party-tab-layout/selections/activity-booking-form/activity-booking-form.component';
import { ActivityBookingSummaryComponent, GetAddonAmountPipe, NumberDirective } from './components/create-update-party-tab-layout/selections/activity-booking-summary/activity-booking-summary.component';
import { ActivitySessionSlotsComponent } from './components/create-update-party-tab-layout/selections/activity-session-slots/activity-session-slots.component';
import { OpenBookingFormComponent } from './components/create-update-party-tab-layout/selections/open-booking-form/open-booking-form.component';
import { OpenBookingSummaryComponent } from './components/create-update-party-tab-layout/selections/open-booking-summary/open-booking-summary.component';
import { RefundPopupComponent } from '@components/refund-popup/refund-popup.component';
import { SelectBookingTypeComponent } from './components/select-booking-type/select-reservation-type.component';
import { FormTimepickerComponent } from './dynamicform/components/form-timepicker/form-timepicker.component';
import { AttendeesFilter } from './pipes/Attendees-Filter.pipe';
import { CurrencyDecimalFormater } from './pipes/currency-decimal.pipe';
import { SortByDatePipe } from './pipes/sortByDate.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { AvailableCartItem, CartItemsDisplayComponent, GetSelectedPipe, SearchDataGroupPipe, ShowExpiryTime } from './components/cart-items-display/cart-items-display.component';
import { DecimalDirective } from './directives/decimal.directive';
import { ArrayJoinPipe, StandbyListingComponent } from './components/standby-listing/standby-listing.component';
import { RejectStanbyComponent } from './components/reject-stanby/reject-stanby.component';
import { GetGroupNamePipe, ShowRecurringIconPipe } from '@app/guestbook/guestdetails/guestdetails.component';
import { ShiftsSelectedDirective, OrderOpenHoursPipe } from '@app/servers/weekday-shift/weekday-shift.component';
import { FilterByExpertiseLevel } from '@app/servers/instructors-calendar-view/instructors-calendar-view.component';
import { RateNegotiationComponent } from './components/rate-negotiation/rate-negotiation.component';
import { MemberSelectComponent } from './components/create-update-party-tab-layout/selections/member-select/member-select.component';
import { GuestSelectComponent, phoneNumberSelectorPipe1 } from './components/create-update-party-tab-layout/selections/guest-select/guest-select.component';
import { PrivateLessonBookingFormComponent } from './components/create-update-party-tab-layout/selections/privatelesson-booking-form/privatelesson-booking-form.component';
import { PrivateLessonBookingSummaryComponent } from './components/create-update-party-tab-layout/selections/privatelesson-booking-summary/privatelesson-booking-summary.component';
import { ReasonCollectionPopupComponent } from './components/reason-collection-popup/reason-collection-popup.component';
import { ProfileSelectComponent } from './components/create-update-party-tab-layout/selections/profile-select/profile-select.component';
import { NegotiationRateFormater } from './pipes/negotiation-rate.pipe';
import { IframeContainerComponent } from './components/iframe-container/iframe-container.component';
import { ActivityPaymentSummaryComponent } from './components/activity-payment-summary/activity-payment-summary.component';
import { OpenPaymentSummaryComponent } from './components/open-payment-summary/open-payment-summary.component';
import { PrivatelessonPaymentSummaryComponent } from './components/privatelesson-payment-summary/privatelesson-payment-summary.component';
import { ResetPinComponent } from './components/reset-pin/reset-pin.component';
import { DisplayTablelistDataComponent } from './components/tablelist/display-tablelist-data/display-tablelist-data.component';
import { PartyStatusColorPipe } from './pipes/party-status-color.pipe';
import { ReservedTableStatusColorPipe } from './pipes/reserved-table-status-color.pipe';
import { PageBackgroundColorPipe } from './pipes/page-background-color.pipe';
import { AdditionalRatePaymentIframeComponent } from './components/additional-rate-payment-iframe/additional-rate-payment-iframe.component';
import { CommonSharedModule } from '@app/common/shared/shared/shared.module';
import { NotifyPopupComponent } from './components/notify-popup/notify-popup.component';
import { ClientPopupComponent } from '../client/client-popup/client-popup.component';
import { ClientDataService } from './data-services/client.data.service';
import { RetailPosCommunication } from './communication/services/retailpos.service';
import { RetailManagementCommunication } from './communication/services/retailmanagement.service';
import { NotificationDataService } from './data-services/notification.data.service';
import { AboutComponent } from './components/about/about.component';
import { RetailSharedModule } from '@app/retail/shared/retail-shared.module';
import { ArrayGeneratorPipe, CheckPrintTicketEnabledPipe, TicketComponent } from './components/ticket/ticket.component';
import { QRCodeModule } from 'angular2-qrcode';
import { TheatreBookingFormComponent } from './components/create-update-party-tab-layout/selections/theatre-booking-form/theatre-booking-form.component';
import { WildSearchPipe } from './pipes/wildSearch';
import { NgxPrintElementModule } from 'ngx-print-element';
import { FormatSettingsDatePipe } from './pipes/format-settings-date.pipe';
import { customGuestFieldConfigPipe } from '../settings/custom-guest-fields/custom-guest-field-validations/custom-guest-field-validations.component';
import { ServerstatisticsComponent } from './components/serverstatistics/serverstatistics.component'
import { PackageBookingComponent } from './components/package-booking/package-booking.component'
import { AddOnForBookingComponent, GetCategoryDataPipe } from './components/add-on-for-booking/add-on-for-booking.component';
import { BookedPackagesComponent } from './components/booked-packages/booked-packages.component';
import { GetAddonNamePipe } from './pipes/addon-name.pipe'; 
import { GetSnackBarMessagesPipe } from './pipes/snackbar-messages.pipe'
import { ActivityHeadlinesComponent } from './components/activity-headlines/activity-headlines/activity-headlines.component';
import { ActivityCreationComponent } from './components/activity-creation/activity-creation.component';
import { BasicSetupComponent } from './components/activity-creation/basic-setup/basic-setup.component';
import { ActivityCreateFunctions } from './utilities/activity-create-functions';
import { SessionMappingComponent } from './components/activity-creation/session-mapping/session-mapping.component';
import { ActivityTableComponent } from './components/activity-table/activity-table.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { AddOnMappingComponent } from './components/activity-creation/add-on-mapping/add-on-mapping.component';
import { ActivityTemplatesComponent } from './components/activity-templates/activity-templates.component';
import { TemplateSelectionComponent } from './components/activity-templates/template-selection/template-selection.component';
import { BlockSessionComponent } from './components/block-session/block-session/block-session.component';
import { CommonBookingPaymentsComponent } from './components/common-booking-payments/common-booking-payments.component';
import { RatePlanSummaryComponent } from './components/rate-plan-summary/rate-plan-summary.component';
import { RatePlanTemplateComponent } from './components/create-update-party-tab-layout/selections/rate-plan-template/rate-plan-template.component';
import { NgxNumberFormatModule } from 'ngx-number-format';
import { DateSelectionWithRangeComponent } from './components/date-selection-with-range/date-selection-with-range.component';
import { OpenBookingDetailComponent } from '@app/activities-timeline/components/open-booking-detail/open-booking-detail.component';
import { FormGroupSelectComponent } from './dynamicform/components/form-group-select/form-group-select.component';
import { ExpandRowDirective } from "@directives/expand-row.directive";

import { ModifySessionComponent } from './components/modify-session/modify-session.component';
import { ChatInfoContainerComponent } from '@app/activities-timeline/components/chat-info-container/chat-info-container.component';
import { PackageInformationComponent } from './components/package-information/package-information.component';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { CalendarComponent } from './components/calendar-view/calendar/calendar.component';
import { CalendarCellComponent } from './components/calendar-view/calendar-cell/calendar-cell.component';
import { SelectDateComponent } from './components/calendar-view/select-date/select-date.component'
import { FrequencyPipe, ShiftsNamePipe, TableNamePipe } from './pipes/calendar-select-date.pipe';
import { PartyGuestFieldMapping } from '@app/activities-timeline/pipes/guest-field-mapping.pipe';
import { CommonFilterComponent } from './components/common-filter/common-filter.component';
import { SearchDataPipe, SearchPartyByContactPipe } from './pipes/search-data.pipe';
import { PartyStatusPopupComponent } from './components/party-status-popup/party-status-popup.component';
import { PartySourceIconPipe } from './pipes/party-source-icons.pipe';
import { ButtonEnablePipe, SearchGroupPipe, SelectCartGroup } from './components/select-cart-group/select-cart-group.component';
import { SlotsComponent } from './components/tablelist/slots/slots.component';
import { TimeSlotsPipe,SlotSeatingAreaPipe,ExpiredSlotsPipe,PartyNoteTypePipe,SlotDateFormatPipe,FormatNamePipe } from './pipes/time-slots.pipe';
import { SlotsSectionComponent } from './components/tablelist/slots/slots-section/slots-section.component';
import { ActivityAdvanceBlockSessionComponent, ShowPastFilterPipe } from './components/block-activity/SessionSelection/session-selection.component';
import { DateRangeForBlockComponent } from './components/block-activity/date-range-for-block/date-range-for-block.component';
import { PrintContainerComponent,GetTableName } from './components/print-container/print-container.component';
import { SpecialmealBookingSummaryComponent } from './components/create-update-party-tab-layout/selections/specialmeal-booking-summary/specialmeal-booking-summary.component';
import { SpecialmealBookingFormComponent } from './components/create-update-party-tab-layout/selections/specialmeal-booking-form/specialmeal-booking-form.component';
import { CustomTabsComponent } from './components/custom-tabs/custom-tabs.component'
import { LocalizedDatePipe } from './pipes/localize-date.pipe';
import { AddOnPopoverComponent } from './components/add-on-popover/add-on-popover.component';
import { OnlyReservationPipe } from '@app/reservation/reservation.component';
import { CRSUpdateConfirmationComponent } from '@app/crs/crs-update-confirmation/crs-update-confirmation.component';
import { CRSPaymentHandlerComponent } from '@app/crs/crs-payment-handler/crs-payment-handler.component';
import { CRSReservationCard } from '@app/crs/crs-reservation-card/crs-reservation-card.component';
import { MemberInformationComponent } from './components/member-information/member-information.component';
import { EnableMemberSlotPipe } from './pipes/enable-member-slot.pipe';
import { PaymentConfirmationPopupComponent } from './components/payment-confirmation-popup/payment-confirmation-popup.component';
import { ReservationGuestsComponent } from './components/create-update-party-tab-layout/selections/reservation-guests/reservation-guests.component';
import { GuestSearchComponent } from './components/create-update-party-tab-layout/selections/guest-search/guest-search.component';
import { SizeMappingComponent } from './components/create-update-party-tab-layout/selections/size-mapping/size-mapping.component';
import { GroupByPipe } from './pipes/groupBy.pipe';
import { ReservationGuestAddonsComponent } from './components/create-update-party-tab-layout/selections/reservation-guests/reservation-addons/guest-addons.component';
import { ReservationSummaryComponent } from './components/create-update-party-tab-layout/selections/reservation-summary/reservation-summary.component';
import { RatePlanComponent, SpecialMealNamePipe } from './components/rate-plan/rate-plan.component';
import { FinancialStatusPopupComponent } from './components/financial-status-popup/financial-status-popup.component';
import { BookingTypeConfigurationComponent } from './components/activity-creation/booking-type-configuration/booking-type-configuration.component';
import { CancelSessionPopupComponent } from './components/cancel-session-popup/cancel-session-popup.component';
import { CustomerRatingPipe } from './pipes/customer-rating.pipe';
import { ApplyPromoCodeComponent, SearchTextPipe,FetchArrayPipe } from './components/apply-promo-code/apply-promo-code.component';
import { ExcludeLocationGroupPipe } from './pipes/ExcludeLocationGroup.pipe';
import { UserWarningActions } from './utilities/userWarningAction';
import { BlockStaffComponent } from './components/block-staff/block-staff.component';
import { LoginContainerComponent } from '@app/core/login/login-container/login-container.component';
import { SearchBasedGuestDataComponent } from './components/search-based-guest-data/search-based-guest-data.component';
import { OskInputDirective } from '@app/pos/keyboard/osk-input.directive';
import { KeyboardKeyDirective } from '@app/pos/keyboard/keyboard-key.directive';
import { KeyboardComponent } from '@app/pos/keyboard/keyboard.component';
import { ModuleClassDirective } from './directives/module-class.directive';
import { CoverTypeNamePipe } from './pipes/cover-type-name.pipe';
import { BookingTypeHandler } from './utilities/BookingTypeHandler';
import { ConsentMessagePopupComponent } from './components/consent-message-popup/consent-message-popup.component';
import { WeekDayPipe } from './pipes/week-day.pipe';

import { GuestItineraryComponent } from './components/guest-itinerary/guest-itinerary.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  minScrollbarLength: 200
};

@NgModule({
  declarations: [
    AboutComponent,
    ButtonComponent,
    phoneNumberSelectorPipe1,
    RefundPopupComponent,
    ReservationTypeComponent,
    SelectBookingTypeComponent,
    SelectionPopUpComponent,
    SizeSelectionComponent,
    SeatingAreaSelectionComponent,
    WaitTimeComponent,
    DateSelectionComponent,
    TableSelectionComponent,
    GuestSelectionComponent,
    AdditionalinfoComponent,
    ContactComponent,
    PreferencesComponent,
    PersonalInfoComponent,
    PartieslistComponent, PartiesgridComponent,
    DashboardComponent,
    DashboardHeaderComponent,
    DashboardStatusComponent,
    DashboardMessageComponent,
    DashboardPartyInfoComponent,
    DynamicFormComponent,
    LoginContainerComponent,
    DynamicFieldDirective,
    PhoneNumberFormatDirective,
    FormButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormGroupSelectComponent,
    ShowSelectedOptionTitlePipe,
    FormtoggleComponent,
    FormdateComponent,
    FormTextareaComponent,
    ResetbuttonComponent,
    FormInputAutocompleteComponent,
    FormNumberComponent,
    FormAutocompleteComponent,
    AutoSyncComponent,
    ContentComponent,
    FormRadioComponent,
    BlockPasteDirective,
    NumberOnlyDirective,
    NumberDivisibleOfFiveDirective,
    RequiredLabelDirective,
    DigitOnlyDirective,
    ViewmoreDirective,
    NummaxLength,
    NumDotOnlyDirective,
    MessageStatusComponent,
    TableListComponent,
    ServerViewComponent,
    ClientSectionComponent,
    SeatStatusComponent,
    CircleComponent,
    AccordionComponent,
    DisplayLegendComponent,
    ReservationStatusPipe,
    ReservationBookingStatusPipe,
    ReservationStatusColorPipe,
    SeatedStatusColorPipe,
    FindIndex,
    ButtonInputFormater,
    SlotTimeFormatter,
    MultiplePartiesComponent,
    LabelComponent,
    FormSwitchComponent,
    FormChipComponent,
    FormChipListComponent,
    TimeLapsePipe,
    StatusIndicatorPipe,
    TimeFormat,
    NotificationViewComponent,
    MessagesViewComponent,
    TabsComponent,
    TablesViewComponent,
    SpecialMealComponent,
    ReservationViewComponent,
    CancelReasonComponent,
    FormCheckboxComponent,
    DateFormatPipe,
    CurrencyDecimalFormater,
    ArrayGeneratorPipe,
    CheckPrintTicketEnabledPipe,
    NegotiationRateFormater,
    TimeFormatPipe,
    WildSearchPipe,
    ArrayJoinPipe,
    FormAddButtonComponent,
    SelectServerComponent,
    LayoutViewComponent,
    TableDetailsComponent,
    TableDetailsListComponent,
    ShowCountPipe,
    MonthHeaderComponent,
    FormMonthDatePickerComponent,
    ConfirmationPopupComponent,
    GuestViewComponent,
    FilterDataPipe,
    formatDatePipe,
    AlphaNumericDirective,
    ChatViewComponent,
    ViewSelectedGuestComponent,
    NegativeNumberFormatPipe,
    NameFormatPipe,
    PageIconPipe,
    AttendeesFilter,
    FormatWaitTimePipe,
    PreferredTableComponent,
    ReservationChangeConfirmComponent,
    TableNameSetterPipe,
    ServersSetterPipe,
    GetNamePipe,
    EditorViewComponent,
    EditorFloorsComponent,
    EditorTablesComponent,
    EditorJoinedTablesComponent,
    LayoutEditorComponent,
    UploadPhotoComponent,
    InitialFormatterPipe,
    ContactListComponent,
    DropdownbuttonComponent,
    PhoneNumberFormatterPipe,
    NewFloorPlanComponent,
    SwitchInputFormater,
    DisableControlDirective,
    ScheduleSelectionComponent,
    ShiftSelectionComponent,
    DateRangeSelectionComponent,
    BlockingDateSelectionComponent,
    BlockingTableSelectionComponent,
    EffectiveRangePartiesPipe,
    QuotedTimeFormatterPipe,
    RemainingTimeFormatterPipe,
    ContactNamePipe,
    ContactNamePipeInitialsPipe,
    CheckUserPermissionsComponent,
    HeaderNamePipe,
    MsgStripPipe,
    ExceptionPopupComponent,
    GuestNameFormatPipe,
    GetRandomColorPipe,
    TablesLayoutViewComponent,
    GeneralDataComponent,
    GuestDataComponent,
    OpenBookingFormComponent,
    OpenBookingSummaryComponent,
    PrivateLessonBookingFormComponent,
    PrivateLessonBookingSummaryComponent,
    ActivityBookingSummaryComponent,
    NumberDirective,
    GetAddonAmountPipe,
    ActivityBookingFormComponent,
    ReservationNotesPipe,
    ReservationNotesPipe,
    TablenameFormatPipe,
    BackgroundColorFormatterPipe,
    CommunicationIconPipe,
    FormattedDigitPipe,
    SortReservationsPipe,
    EditOrSeatActionPipe,
    ExplainUserPermissionsComponent,
    OverrideRemainingTimeComponent,
    CustomWaitTimePipe,
    CancelorNoShowActionPipe,
    ReservationTimesPopupComponent,
    ReservationTimesPopupComponent,
    ShowellipsisTextDirective,
    StandbyListComponent,
    PartyStatusFilterPipePipe,
    AuditlogComponent,
    AuditlogItemFilterPipe,
    ChitPrintComponent,
    ShowellipsisTextDirective,
    ContactNameFilterPipe,
    PartyDataFilterPipe,
    ConcatNotesFilterPipe,
    PercentageColorConverterPipe,
    GridCellDataManipulatorPipe,
    MultiplePartiesComponent,
    FormMultiSelectComponent,
    CoverReportsComponent,
    GetCovorColorPipe,
    RestaurantPoliciesComponent,
    WaitTimeReportComponent,
    EditableComponent,
    ViewModeDirective,
    EditModeDirective,
    HotelConciergeListComponent,
    TableNotAvailableForReservationsComponent,
    GetSeatingAreaNamePipe,
    ManualSlotComponent,
    NewSlotComponent,
    FilterContentComponent,
    HeaderSeatingAreaFilterPipe,
    FormSliderComponent,
    EditorLayoutComponent,
    ImageGalleryComponent,
    ImageActionsComponent,
    ConfirmationMessagesPopupComponent,
    GuestTitlePipe,
    AddressComponent,
    PartySourceOTAPipe,
    ViewSecondaryGuestDetailsComponent,
    AdvanceBlockComponent,
    FormTimepickerComponent,
    CancelActivityPopupComponent,
    ActivityHeadlinesComponent,
    AdvanceBlockComponent,
    ActivitySessionSlotsComponent,
    SortByDatePipe,
    CartItemsDisplayComponent,
    DecimalDirective,
    StandbyListingComponent,
    RejectStanbyComponent,
    ShowRecurringIconPipe,
    GetGroupNamePipe,
    ShiftsSelectedDirective,
    OrderOpenHoursPipe,
    FilterByExpertiseLevel,
    RateNegotiationComponent,
    MemberSelectComponent,
    GuestSelectComponent,
    ReasonCollectionPopupComponent,
    ProfileSelectComponent,
    RolesAndPermissionPipe,
    HasPermisssionCustomPipe,
    customGuestFieldSelectorPipe,
    IframeContainerComponent,
    ActivityPaymentSummaryComponent,
    OpenPaymentSummaryComponent,
    PrivatelessonPaymentSummaryComponent,
    PartyStatusColorPipe,
    ReservedTableStatusColorPipe,
    ResetPinComponent,
    CheckIconVisibilityPipe,
    DisplayTablelistDataComponent,
    QuickSeatGuestNameFormatPipe,
    EmailSelectorPipe,
    phoneNumberSelectorPipe,
    SeatingAreaNamePipe,
    RefundPopupComponent,
    PageBackgroundColorPipe,
    OnlyReservationPipe,
    AdditionalRatePaymentIframeComponent,
    NotifyPopupComponent,
    ClientPopupComponent,
    TicketComponent,
    TheatreBookingFormComponent,
    EmailSelectorPipe,
    phoneNumberSelectorPipe,
    FormatSettingsDatePipe,
    customGuestFieldConfigPipe,
    ServerstatisticsComponent,
    AddOnForBookingComponent,
    GetCategoryDataPipe,
    PackageBookingComponent,
    BookedPackagesComponent,
    GetAddonNamePipe,
    GetSnackBarMessagesPipe,
    ActivityHeadlinesComponent,
    ActivityCreationComponent,
    BasicSetupComponent,
    SessionMappingComponent,
    ActivityTableComponent,
    AddOnMappingComponent,
    QrCodeComponent,
    ActivityTemplatesComponent,
    TemplateSelectionComponent,
    BlockSessionComponent,
    SessionMultiValuePipe,
    FormatStringPipe,
    CommonBookingPaymentsComponent,
    RatePlanSummaryComponent,
    RatePlanTemplateComponent,
    PartyPaymentStatePipe,
    OpenBookingDetailComponent,
    ExpandRowDirective,
    PackageInformationComponent,
    DateSelectionWithRangeComponent,
    ModifySessionComponent,
    ChatInfoContainerComponent,
    CalendarViewComponent,
    CalendarComponent,
    CalendarCellComponent,
    SelectDateComponent,
    ShiftsNamePipe,
    TableNamePipe,
    FrequencyPipe,
    PartyGuestFieldMapping,
    CommonFilterComponent,
    SearchDataPipe,
    CapitalizeFirstCharacterInText,
    PartyStatusPopupComponent,
    CapitalizeFirstCharacterInText,
    PartyStatusPopupComponent,
    SlotsComponent,
    TimeSlotsPipe,
    SlotsSectionComponent,
    SlotSeatingAreaPipe,
    ExpiredSlotsPipe,
    PartyNoteTypePipe,
    SlotDateFormatPipe,
    FormatNamePipe,
    ActivityAdvanceBlockSessionComponent,
    SearchDataGroupPipe,
    SearchPartyByContactPipe,
    DateRangeForBlockComponent,
    ShowPastFilterPipe,
    SpecialmealBookingSummaryComponent,
    SpecialmealBookingFormComponent,
    SelectCartGroup,
    SearchGroupPipe,
    ShowExpiryTime,
    GetSelectedPipe,
    AvailableCartItem,
    PartyStatusPopupComponent,
    PrintContainerComponent,
    PartySourceIconPipe,
    CustomTabsComponent,
    LocalizedDatePipe,
    AddOnPopoverComponent,
    LocalizedDatePipe,
    CRSUpdateConfirmationComponent,
    CRSPaymentHandlerComponent,
    CRSReservationCard,
    MemberInformationComponent,
    EnableMemberSlotPipe,
    PaymentConfirmationPopupComponent,
    ReservationGuestsComponent,
    GuestSearchComponent,
    SizeMappingComponent,
    ReservationGuestAddonsComponent,
    ReservationSummaryComponent,
    GroupByPipe,
    RatePlanComponent,
    SpecialMealNamePipe,
    FinancialStatusPopupComponent,
    BookingTypeConfigurationComponent,
    CancelSessionPopupComponent,
    ButtonEnablePipe,
    CustomerRatingPipe,
    ApplyPromoCodeComponent,
    SearchTextPipe,
    GuestItineraryComponent,
    FetchArrayPipe,
    ButtonEnablePipe,
    ExcludeLocationGroupPipe,
    ButtonEnablePipe,
    BlockStaffComponent,
    SearchBasedGuestDataComponent,
    OskInputDirective,
    ModuleClassDirective,
    CoverTypeNamePipe,
    ConsentMessagePopupComponent,
    WeekDayPipe,
    GetTableName
  ],

  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    // LoaderModule,
    NgScrollbarModule,
    NgbPaginationModule,
    NgbModule,
    VirtualScrollerModule,
    NgPipesModule,
    NgxPrintModule,
    PerfectScrollbarModule,
    NgxMaterialTimepickerModule,
    RetailSharedModule,
    CommonSharedModule,
    QRCodeModule,
    NgxPrintElementModule,
    NgxNumberFormatModule,
  ],
  providers: [
    NgxImageCompressService,
    DynamicFormService,
    SlotTimeFormatter,
    InitialFormatterPipe,
    DashboardFunctions,
    BookingTypeHandler,
    LayoutFunctions,
    BlockingrulePopupservice,
    EffectiveRangePartiesPipe,
    TitleCasePipe,
    ResultProcesserFunction,
    UserPermissionFunctions,
    UserWarningActions,
    ContactNamePipe,
    ContactNamePipeInitialsPipe,
    PartyStatusFilterPipePipe,
    ContactNameFilterPipe,
    PartyDataFilterPipe,
    ConcatNotesFilterPipe,
    //ProcessorBuilder,
    PercentageColorConverterPipe,
    GridCellDataManipulatorPipe,
    AuditlogItemFilterPipe,
    LoginFunction,
    HeaderSeatingAreaFilterPipe,
    TimeFormatPipe,
    ArrayJoinPipe,
    ShowSelectedOptionTitlePipe,
    RolesAndPermissionPipe,
    HasPermisssionCustomPipe,
    customGuestFieldSelectorPipe,
    PartyPaymentStatePipe,
    OnlyReservationPipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ClientDataService,
    RetailPosCommunication,
    RetailManagementCommunication,
    NotificationDataService,
    CurrencyDecimalFormater,
    PhoneNumberFormatterPipe,
    EmailSelectorPipe,
    phoneNumberSelectorPipe,
    PartyStatusColorPipe,
    ActivityCreateFunctions,
    FormatSettingsDatePipe,
    SessionMultiValuePipe,
    FormatStringPipe,
    PartyGuestFieldMapping,
    PartyGuestFieldMapping,        
    CapitalizeFirstCharacterInText,
    SearchDataPipe,
    CapitalizeFirstCharacterInText,
    ExpiredSlotsPipe,
    PartyNoteTypePipe,
    SlotDateFormatPipe,
    PartyGuestFieldMapping,        
    CapitalizeFirstCharacterInText,
    AvailableCartItem,
    PartySourceIconPipe,
    PartyGuestFieldMapping,
    SearchDataPipe,
    GetSnackBarMessagesPipe,
    SearchDataGroupPipe,
    SearchDataPipe,
    AvailableCartItem,
    GetAddonNamePipe,
    SearchPartyByContactPipe,
    AvailableCartItem,
    LocalizedDatePipe,
    ButtonEnablePipe,
    CustomerRatingPipe,
    ExcludeLocationGroupPipe,
  ],
  exports: [
    AboutComponent,
    TranslateModule,
    PartiesgridComponent,
    PartieslistComponent,
    DashboardComponent,
    ButtonComponent,
    RefundPopupComponent,
    DynamicFormComponent,
    LoginContainerComponent,
    DynamicFieldDirective,
    PhoneNumberFormatDirective,
    FormButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormGroupSelectComponent,
    ShowSelectedOptionTitlePipe,
    FormtoggleComponent,
    FormChipComponent,
    FormSwitchComponent,
    FormdateComponent,
    FormTextareaComponent,
    ResetbuttonComponent,
    FormInputAutocompleteComponent,
    FormNumberComponent,
    FormAutocompleteComponent,
    AutoSyncComponent,
    ContentComponent,
    FormRadioComponent,
    BlockPasteDirective,
    NumberOnlyDirective,
    NumberDivisibleOfFiveDirective,
    RequiredLabelDirective,
    DigitOnlyDirective,
    ViewmoreDirective,
    NummaxLength,
    NumDotOnlyDirective,
    MessageStatusComponent,
    TableListComponent,
    ServerViewComponent,
    ClientSectionComponent,
    SeatStatusComponent,
    CircleComponent,
    AccordionComponent,
    DisplayLegendComponent,
    ReservationStatusPipe,
    ReservationBookingStatusPipe,
    ReservationStatusColorPipe,
    SeatedStatusColorPipe,
    FindIndex,
    ButtonInputFormater,
    SlotTimeFormatter,
    MultiplePartiesComponent,
    TimeLapsePipe,
    StatusIndicatorPipe,
    TabsComponent,
    NotificationViewComponent,
    MessagesViewComponent,
    TablesViewComponent,
    TableSelectionComponent,
    ReservationViewComponent,
    CancelReasonComponent,
    FormCheckboxComponent,
    DateFormatPipe,
    CurrencyDecimalFormater,
    NegotiationRateFormater,
    TimeFormatPipe,
    ArrayJoinPipe,
    NgbPaginationModule,
    FormAddButtonComponent,
    LayoutViewComponent,
    TableDetailsComponent,
    TableDetailsListComponent,
    ChatViewComponent,
    NegativeNumberFormatPipe,
    FormatWaitTimePipe,
    ReservationChangeConfirmComponent,
    TableNameSetterPipe,
    ServersSetterPipe,
    DashboardHeaderComponent,
    GetNamePipe,
    UploadPhotoComponent,
    InitialFormatterPipe,
    DropdownbuttonComponent,
    NgScrollbarModule,
    LayoutEditorComponent,
    PhoneNumberFormatterPipe,
    NewFloorPlanComponent,
    SwitchInputFormater,
    EffectiveRangePartiesPipe,
    ContactNamePipe,
    ContactNamePipeInitialsPipe,
    CheckUserPermissionsComponent,
    HeaderNamePipe,
    NgbModule,
    GuestNameFormatPipe,
    GetRandomColorPipe,
    ReservationNotesPipe,
    FormattedDigitPipe,
    SortReservationsPipe,
    EditOrSeatActionPipe,
    CustomWaitTimePipe,
    CancelorNoShowActionPipe,
    NgPipesModule,
    AuditlogComponent,
    PartyStatusFilterPipePipe,
    ContactNameFilterPipe,
    PartyDataFilterPipe,
    ContactNamePipe,
    ConcatNotesFilterPipe,
    PercentageColorConverterPipe,
    GridCellDataManipulatorPipe,
    FormMultiSelectComponent,
    AuditlogComponent,
    AuditlogItemFilterPipe,
    GetCovorColorPipe,
    EditModeDirective,
    GetSeatingAreaNamePipe,
    FilterContentComponent,
    HeaderSeatingAreaFilterPipe,
    FormSliderComponent,
    GuestTitlePipe,
    TablesLayoutViewComponent,
    FormTimepickerComponent,
    NgxMaterialTimepickerModule,
    GuestSelectionComponent,
    CancelActivityPopupComponent,
    ActivityHeadlinesComponent,
    CartItemsDisplayComponent,
    AttendeesFilter,
    SortByDatePipe,
    DecimalDirective,
    ShowRecurringIconPipe,
    GetGroupNamePipe,
    ShiftsSelectedDirective,
    OrderOpenHoursPipe,
    FilterByExpertiseLevel,
    RateNegotiationComponent,
    ReasonCollectionPopupComponent,
    RolesAndPermissionPipe,
    HasPermisssionCustomPipe,
    customGuestFieldSelectorPipe,
    PartyStatusColorPipe,
    ReservedTableStatusColorPipe,
    CheckIconVisibilityPipe,
    QuickSeatGuestNameFormatPipe,
    EmailSelectorPipe,
    phoneNumberSelectorPipe,
    SeatingAreaNamePipe,
    RefundPopupComponent,
    PackageInformationComponent,
    QRCodeModule,
    NgxPrintElementModule,
    FormatSettingsDatePipe,
    customGuestFieldConfigPipe,
    AddOnForBookingComponent,
    GetAddonNamePipe,
    GetSnackBarMessagesPipe,
    WildSearchPipe,
    BasicSetupComponent,
    QrCodeComponent,
    BlockSessionComponent,
    BookedPackagesComponent,
    PartyPaymentStatePipe,
    ExpandRowDirective,
    DateSelectionWithRangeComponent,
    ChatInfoContainerComponent,
    CalendarViewComponent,
    CalendarComponent,
    CalendarCellComponent,
    SelectDateComponent,
    CapitalizeFirstCharacterInText,
    ExpiredSlotsPipe,
    PartyNoteTypePipe,
    SlotDateFormatPipe,
    SessionMultiValuePipe,
    FormatStringPipe,
    PartyGuestFieldMapping,
    SearchDataPipe,
    CapitalizeFirstCharacterInText,
    PartySourceIconPipe,
    ActivityAdvanceBlockSessionComponent,
    SearchDataPipe,
    AddOnMappingComponent,
    ActivityTableComponent,
    SearchDataGroupPipe,
    SearchDataPipe,
    PrintContainerComponent,
    SearchPartyByContactPipe,
    SearchDataPipe,
    CustomTabsComponent,
    LocalizedDatePipe,
    AddOnPopoverComponent,
    LocalizedDatePipe,
    OnlyReservationPipe,
    FilterDataPipe,
    CRSUpdateConfirmationComponent,
    CRSPaymentHandlerComponent,
    CRSReservationCard,
    OpenBookingDetailComponent,
    NameFormatPipe,
    BackgroundColorFormatterPipe,
    RatePlanComponent,
    SpecialMealNamePipe,
    FinancialStatusPopupComponent,
    CancelSessionPopupComponent,
    ExcludeLocationGroupPipe,
    SearchBasedGuestDataComponent,
    CustomerRatingPipe,
    OskInputDirective,
    ModuleClassDirective,
    WeekDayPipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  entryComponents: [
    AboutComponent,
    SelectionPopUpComponent,
    FormInputComponent,
    FormSelectComponent,
    FormGroupSelectComponent,
    FormButtonComponent,
    FormtoggleComponent,
    FormSwitchComponent,
    FormTextareaComponent,
    FormNumberComponent,
    FormdateComponent,
    FormMonthDatePickerComponent,
    MonthHeaderComponent,
    FormChipComponent,
    ResetbuttonComponent,
    FormInputAutocompleteComponent,
    FormAutocompleteComponent,
    AutoSyncComponent,
    ContentComponent,
    FormRadioComponent,
    CircleComponent,
    SeatStatusComponent,
    AccordionComponent,
    MultiplePartiesComponent,
    SizeSelectionComponent,
    SeatingAreaSelectionComponent,
    WaitTimeComponent,
    DateSelectionComponent,
    TableSelectionComponent,
    GuestSelectionComponent,
    ViewSelectedGuestComponent,
    PersonalInfoComponent,
    AdditionalinfoComponent,
    ContactComponent,
    PreferencesComponent,
    TabsComponent,
    MessagesViewComponent,
    TablesViewComponent,
    ReservationViewComponent,
    CancelReasonComponent,
    FormCheckboxComponent,
    FormAddButtonComponent,
    SelectServerComponent,
    LayoutViewComponent,
    TableDetailsComponent,
    TableDetailsListComponent,
    ConfirmationPopupComponent,
    PreferredTableComponent,
    ReservationChangeConfirmComponent,
    EditorViewComponent,
    EditorFloorsComponent,
    EditorTablesComponent,
    EditorJoinedTablesComponent,
    LayoutEditorComponent,
    NewFloorPlanComponent,
    ScheduleSelectionComponent,
    ShiftSelectionComponent,
    DateRangeSelectionComponent,
    BlockingDateSelectionComponent,
    BlockingTableSelectionComponent,
    CheckUserPermissionsComponent,
    ExceptionPopupComponent,
    TablesLayoutViewComponent,
    GeneralDataComponent,
    GuestDataComponent,
    OpenBookingFormComponent,
    OpenBookingSummaryComponent,
    PrivateLessonBookingFormComponent,
    PrivateLessonBookingSummaryComponent,
    ActivityBookingSummaryComponent,
    ActivityBookingFormComponent,
    ActivityPaymentSummaryComponent,
    ReservationTimesPopupComponent,
    ExplainUserPermissionsComponent,
    OverrideRemainingTimeComponent,
    ReservationTimesPopupComponent,
    StandbyListComponent,
    FormMultiSelectComponent,
    AuditlogComponent,
    CoverReportsComponent,
    RestaurantPoliciesComponent,
    WaitTimeReportComponent,
    EditableComponent,
    RestaurantPoliciesComponent,
    TableNotAvailableForReservationsComponent,
    ManualSlotComponent,
    FormSliderComponent,
    EditorLayoutComponent,
    ImageActionsComponent,
    ConfirmationMessagesPopupComponent,
    AddressComponent,
    FormTimepickerComponent,
    AdvanceBlockComponent,
    CancelActivityPopupComponent,
    ActivityHeadlinesComponent,
    RefundPopupComponent,
    CartItemsDisplayComponent,
    StandbyListingComponent,
    RejectStanbyComponent,
    RateNegotiationComponent,
    ReasonCollectionPopupComponent,
    OpenPaymentSummaryComponent,
    PrivatelessonPaymentSummaryComponent,
    ResetPinComponent,
    RefundPopupComponent,
    AdditionalRatePaymentIframeComponent,
    IframeContainerComponent,
    NotifyPopupComponent,
    ClientPopupComponent,
    TicketComponent,
    TheatreBookingFormComponent,
    ServerstatisticsComponent,
    AddOnForBookingComponent,
    BookedPackagesComponent,
    BlockSessionComponent,
    BookedPackagesComponent,
    BasicSetupComponent,
    QrCodeComponent,
    BookedPackagesComponent,
    TemplateSelectionComponent,
    RatePlanSummaryComponent,
    OpenBookingDetailComponent,
    ModifySessionComponent,
    CalendarViewComponent,
    CalendarComponent,
    CalendarCellComponent,
    SelectDateComponent,
    ActivityAdvanceBlockSessionComponent,
    DateRangeForBlockComponent,
    SpecialmealBookingFormComponent,
    SpecialmealBookingSummaryComponent,
      CalendarViewComponent,
      CalendarComponent,
      CalendarCellComponent,
      SelectDateComponent,
      ActivityAdvanceBlockSessionComponent,
      DateRangeForBlockComponent,
      SelectCartGroup,
      PrintContainerComponent,
      CustomTabsComponent,
      AddOnPopoverComponent,
      CRSUpdateConfirmationComponent,
      CRSPaymentHandlerComponent,
      CRSReservationCard,
      ReservationGuestsComponent,
      GuestSearchComponent,
      SizeMappingComponent,
      ReservationGuestAddonsComponent,
      ReservationSummaryComponent,
      GroupByPipe,
      CancelSessionPopupComponent
  ]
})
export class SharedModule { }
