import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { VCartBusiness } from '../vcart/vcart.business';
import { debounceTime, distinctUntilChanged, mergeMap, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { SEARCH_DEBOUNCE_TIME, SEARCH_TEXT_LENGTH } from 'src/app/common/shared/shared/setupConstants';
import { SearchParameters, GuestSearchTypes, CartWindowMessageType } from '../vcart/vcart.modal';
import { ContactDetail } from 'src/app/common/data-magine/data-magine-integration/data-magine-models';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VCartConfiguration } from 'src/app/common/Models/property-information.model';
import { AgToggleConfig } from 'src/app/common/Models/ag-models';
import { Guid } from 'guid-typescript';
import { API as guestAPI, UI as guestUI } from 'src/app/common/components/combine-guest-records/guest-model';
import { CartUtilities } from '../vcart/cart.utilities';
import { CommonUtilities } from '../../../shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-cart-guest-search',
  templateUrl: './cart-guest-search.component.html',
  styleUrls: ['./cart-guest-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CartGuestSearchComponent implements OnInit {
  guestSearchText: string;
  guestSearchSubscription: Subscription;
  guestSearchTextChanged = new Subject<{searchType: Number, searchInput: string}>();  
  public cancellationNotifier: Subject<void> = new Subject<void>();
  buttonObj: { customSaveText: string; disabled: boolean; isEdit: boolean; automationId: string};
  minStartDate: Date;
  cartComments: string = '';
  title: string = '';
  captions: any;
  floatLabel: string;
  comments: string;
  allGuests: any;
  isSelected: boolean = false;
  guestId: string;
  stateData: {guestId: String, packageCode: String, arrivalDate: String, nights: String};
  selectedGuest: any;
  guestList: any[] = [];
  searchKey = ['firstName', 'lastName'];
  autocompleteKeys = ['firstName', 'lastName', 'personalEMail', 'officeEMail', 'cellPhone', 'homePhone', 'workPhone'];
  selectedChipKey = ['firstName', 'lastName'];
  selectedFromGuest: any = [];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  GuestSeacrhTypes: { id: GuestSearchTypes; name: string; description: string; }[];
  cartGuestForm: UntypedFormGroup;
  selectedGuestType = 0;
  searchPlaceholder: string = '';
  maxNoNights: number = 999;
  defaultNoNights: number = 3;
  vCartConfiguration : VCartConfiguration<any>;
  platFormExtendedSearchRequired : boolean = false
  platformSearchInput: AgToggleConfig;
  isPlatformGuestSearch: boolean = false;

  constructor(public dialog: MatDialog,
    private localization: Localization,
    private dialogRef: MatDialogRef<CartGuestSearchComponent>,
    private cd: ChangeDetectorRef,
    private vCartBusiness: VCartBusiness,
    private propInfo: CommonPropertyInformation,
    private fb: UntypedFormBuilder,
    private cartUtilities: CartUtilities,
    private utils: CommonUtilities,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.title = data?.title;
    this.comments = data?.existingComment
    if(data.inputs)
      this.stateData = data.inputs; 

    this.vCartConfiguration = data.config;
    if(this.vCartConfiguration && this.vCartConfiguration.configValue && this.vCartConfiguration.configValue.vcartMaxNoOfNights)
    {
      let maxNightsConfigValue: any=  this.vCartConfiguration.configValue.vcartMaxNoOfNights.toString().trim();
      if(!isNaN(maxNightsConfigValue))
      {
        this.maxNoNights = Number(maxNightsConfigValue);
      }
      let defaultNightsConfigValue: any=  this.vCartConfiguration.configValue.vcartDefaultNoOfNights.toString().trim();
      if(!isNaN(defaultNightsConfigValue))
      {
        this.defaultNoNights = Number(defaultNightsConfigValue);
      }
    }    
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
  ngOnInit(): void {
    this.platFormExtendedSearchRequired = this.localization.IsPlatformGuestSearchConfigured();
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.cartGuestForm = this.fb.group({
      nights: this.defaultNoNights,
      packageCode: '',
      travelStartDate: this.localization.getDate(this.propInfo.CurrentDate),
      platformGuestSearchTog: ''
    })
    this.minStartDate = this.localization.getDate(this.propInfo.CurrentDate);
    this.searchPlaceholder = this.captions.searchByFullName;
    this.buttonObj = {
      customSaveText: this.captions.lbl_CreateItinerary,
      disabled: true,
      isEdit: false,
      automationId: 'cartGuestSearch'
    };

    this.platformSearchInput = {
      group: this.cartGuestForm,
      horizontal: false,
      label: 'Platform Guest search',
      formControlName: 'platformGuestSearchTog',
      disabled: false,
      automationId: 'cartGuestSearch'
    };

    this.guestSearchTextChanged.pipe(
      debounceTime(SEARCH_DEBOUNCE_TIME),
      mergeMap(source => this.searchGuest(source))
    ).pipe(takeUntil(this.destroyed$)).subscribe();
    this.GuestSeacrhTypes = this.getGuestSearchTypes()

    if(this.stateData)
    {
      this.cartGuestForm.controls.nights.setValue(this.stateData.nights);
    var travelStartDate = this.localization.getDate(this.stateData.arrivalDate)
      this.cartGuestForm.controls.travelStartDate.setValue(travelStartDate);
      this.cartGuestForm.controls.packageCode.setValue(this.stateData.packageCode);
      
      if(this.stateData.guestId && this.stateData.guestId.length >0){
      
      this.utils.ToggleLoader(true);
      this.vCartBusiness.GetGuestByGuestId(this.stateData.guestId).then(result=>{
        if(result && result.guests && result.guests.length > 0)
        {
          this.allGuests = result.guests;
          this.selectedFromGuest = result.guests;
          this.selectedFromGuest = [...this.selectedFromGuest];
          this.allGuests = [...result.guests];
          this.selectedGuest = result.guests[0];
          this.formValueChanged(null);
        }
      }).finally(()=>
      {
        this.utils.ToggleLoader(false);
      });    
    }
    
    }
    
  }
  platformGuestSearch(e){
    this.isPlatformGuestSearch = e.checked;
    this.cartGuestForm.controls.platformGuestSearchTog.setValue(e.checked);
    this.cartGuestForm.controls.platformGuestSearchTog.updateValueAndValidity();
  }


  async searchGuest(source) {
    if (source && source.searchInput.length > 0) {
      let searchKeyAndValue = this.formSearchParamsForGuest(source.searchType, source.searchInput);
      this.cancelPendingSearchRequests();
      let result = await this.vCartBusiness.SearchGuest(searchKeyAndValue[1], searchKeyAndValue[0], this.cancellationNotifier, this.isPlatformGuestSearch);
      if (result && result['guests']) {

        let guests = await this.uiMapper_Guest(result['guests']);
        this.guestList = guests;
        this.cd.detectChanges();
      }
    }
  }

  formValueChanged(event) {
    var nights = this.cartGuestForm.controls['nights'].value;
    if (nights != undefined && nights >= 0 && this.selectedGuest != null) {
      this.setButtonDisabled(false);
    } else {
      this.setButtonDisabled(true);
    }
  }

  CreateNewGuestClicked(e) {
    var stateData : {guestId: String, packageCode: String, arrivalDate: String, nights: String} =
    {
        guestId: this.guestId ? this.guestId: "",
        nights: this.cartGuestForm.controls['nights'].value,
        arrivalDate: this.cartGuestForm.controls['travelStartDate'].value,
        packageCode: this.cartGuestForm.controls['packageCode'].value
    }

    this.cartUtilities.pushMessageToChildWindows(CartWindowMessageType.NewGuestCreateSelected,stateData);
    this.close();
  }
  
  formSearchParamsForGuest(searchType : Number, searchInput: string) : [SearchParameters[],string]{
    if(searchType == GuestSearchTypes.firstName){
      return [[SearchParameters.FIRSTNAME],searchInput]
    }
    if(searchType == GuestSearchTypes.fullname){
      return [[SearchParameters.NAME], searchInput]
    }
    if(searchType == GuestSearchTypes.lastName){
      return [[SearchParameters.LASTNAME],searchInput]
    }
    if(searchType == GuestSearchTypes.email){
      return [[SearchParameters.EMAIL],searchInput]
    }
    if(searchType == GuestSearchTypes.phoneNumber){
      return [[SearchParameters.PHONE],searchInput]
    }
  }

  protected cancelPendingSearchRequests(): void {
    if (this.cancellationNotifier) {
      this.cancellationNotifier.next();
      this.cancellationNotifier.complete();
      this.cancellationNotifier.unsubscribe();
    }
    this.cancellationNotifier = new Subject<void>();
  }

  close() {
    this.dialogRef.close(null)
  }
  save(event) {
    if (this.selectedGuest) {
      //const startDate: Date = this.localization.getDate(this.cartGuestForm.controls['travelStartDate'].value);
      // let endDate: Date = this.localization.getDate(this.cartGuestForm.controls['travelStartDate'].value);
      // endDate.setDate(startDate + this.cartGuestForm.controls['nights'].value);
      this.dialogRef.close({
        guest: this.selectedGuest,
        nights: this.cartGuestForm.controls['nights'].value,
        startDate: this.cartGuestForm.controls['travelStartDate'].value,
        packageCode: this.cartGuestForm.controls['packageCode'].value
      });
    }
  }
  selectedGuestEmit(event) {
    this.guestSelectedEvent(event);
    if (event && event.length > 0) {
      this.guestId = event[0].guestId;
    }
    else {
      this.guestId = ' ';
    }
  }
  guestSelectedEvent(guest) {
    this.isSelected = true;
    let guestId = '';
    if (guest) {
      guestId = guest[0].guestId ? guest[0].guestId : '';
    }
    this.allGuests = [];
    this.cd.detectChanges();
  }
  SearchTextHandler(arg) {
    const searchInput = arg.trim();
    if (searchInput && searchInput.length >= 1) {
      this.guestSearchTextChanged.next({searchType: this.selectedGuestType, searchInput: searchInput});      
    }
  }
  OnChipRemove(arg) {
    this.guestList = [];
    this.selectedGuest = null;
    this.setButtonDisabled(true);
  }
  async selectedChipDataEmit(arg) {
    if (arg && (arg[0].firstName || arg[0].lastName)) {
      this.selectedGuest = arg[0];
      if (this.selectedGuest.id == Guid.EMPTY && this.isPlatformGuestSearch && this.selectedGuest.platformGuestUuid != Guid.EMPTY)
      {
        let guestInfo : guestUI.Guest = await this.vCartBusiness.getGuestInformationByPlatformGuid(this.selectedGuest.platformGuestUuid);
        this.selectedGuest.id = guestInfo.guestId;
      }
      var nights = this.cartGuestForm.controls['nights'].value;
      if (nights != undefined && nights >= 0)
        this.setButtonDisabled(false);
    }
  }
  setButtonDisabled(isDisabled: boolean) {
    this.buttonObj.disabled = isDisabled;
    this.buttonObj = { ...this.buttonObj };
  }
  private async uiMapper_Guest(apiGuestModels) {
    let UIGuest = [];
    if (apiGuestModels) {
      UIGuest.push(apiGuestModels.map(
        guest => {
          return {
            firstName: guest.firstName ?? '',
            lastName: guest.lastName ?? '',
            middleName: guest.middleName ?? '',
            personalEMail: this.getContactInfo(guest.contactDetails, 9),
            officeEMail: this.getContactInfo(guest.contactDetails, 10),
            cellPhone: this.getContactInfo(guest.contactDetails, 1),
            homePhone: this.getContactInfo(guest.contactDetails, 2),
            workPhone: this.getContactInfo(guest.contactDetails, 3),
            id: guest.id ?? '',
            sex: guest.sex ?? '',
            birthDate: guest.birthDate ?? '',
            contactDetails: guest.contactDetails ?? [],
            details: guest.details ?? [],
            platformGuestUuid: guest.platformGuestUuid ?? '00000000-0000-0000-0000-000000000000',
            platformRevUuid: guest.platformRevUuid ?? '00000000-0000-0000-0000-000000000000',
            platformBussinessCardUuid: guest.platformBussinessCardUuid ?? '00000000-0000-0000-0000-000000000000',
            platformBussinessCardRevUuid: guest.platformBussinessCardRevUuid ?? '00000000-0000-0000-0000-000000000000',
            membershipDetails: guest.membershipDetails ?? [],
            loyaltyDetails: guest.loyaltyDetails ?? [],
            isSoftDeleted: guest.isSoftDeleted ?? ''
          }
        }
      ));
    }
    return UIGuest[0];
  }
  private getGuestSearchTypes() {
    return [
      { id: GuestSearchTypes.fullname, name: 'fullName', description: this.captions.lbl_fullName },
      { id: GuestSearchTypes.firstName, name: 'firstName', description: this.captions.lbl_firstName },
      { id: GuestSearchTypes.lastName, name: 'lastName', description: this.captions.lbl_lastName },
      { id: GuestSearchTypes.email, name: 'email', description: this.captions.lbl_emailId },
      { id: GuestSearchTypes.phoneNumber, name: 'phoneNumber', description: this.captions.lbl_phoneNumber }
    ];
  }
  private getContactInfo(contactDetails: ContactDetail[], type: number): string {
    let contactDetail = '';
    if (contactDetails && contactDetails.length) {
      let emailContacts = contactDetails.filter(o => o.type == type);
      if (emailContacts.length > 0) {
        switch (type) {
          case 1:
            contactDetail = this.captions.lbl_cellPhone;
            break;
          case 2:
            contactDetail = this.captions.lbl_homePhone;
            break;
          case 3:
            contactDetail = this.captions.lbl_workPhone;
            break;
          case 9:
            contactDetail = this.captions.lbl_personalEmail;
            break;
          case 10:
            contactDetail = this.captions.lbl_officeEmail;
            break;

          default:
            return '';
        }
        const primary = emailContacts.find(o => o.isPrimary);
        if (primary) {
          return contactDetail = contactDetail + ': ' + primary.value;
        } else {
          return contactDetail = contactDetail + ': ' + emailContacts[0].value;
        }
      }
    }
    return contactDetail;
  }

  GuestSearchTypeChange(event) {
    this.selectedGuestType = event.value;
    this.clearPlayerSearchInput();
    this.setPlaceHolderForExistingPlayer();
  }
  private setPlaceHolderForExistingPlayer() {
    switch (this.selectedGuestType) {
      case GuestSearchTypes.firstName:
        this.searchPlaceholder = this.captions.searchByFirstName;
        break;
      case GuestSearchTypes.fullname:
        this.searchPlaceholder = this.captions.searchByFullName;
        break;
      case GuestSearchTypes.lastName:
        this.searchPlaceholder = this.captions.searchByLastName;
        break;
      case GuestSearchTypes.email:
        this.searchPlaceholder = this.captions.searchByEmail;
        break;
      case GuestSearchTypes.phoneNumber:
        this.searchPlaceholder = this.captions.searchByPhoneNumber;
        break;
    }
  }
  private clearPlayerSearchInput() {
    this.OnChipRemove(null);
  }

}