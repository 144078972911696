import { Component, OnDestroy, OnInit, Pipe, PipeTransform, ViewEncapsulation } from '@angular/core';
import { CacheService } from '@core/services/cache.service';
import { PartyMessageDTO, SettingsDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import { Subscription } from 'rxjs/Subscription';
@Component({
  selector: 'app-dashboard-message',
  templateUrl: './dashboard-message.component.html',
  styleUrls: ['./dashboard-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardMessageComponent implements OnInit, OnDestroy {

  party: any;
  dashboardMessages: PartyMessageDTO[];
  settings: SettingsDTO;
  subscriptions: Subscription = new Subscription();
  constructor(private cs: CacheService, public ps: PartyService) {
    this.subscriptions.add(cs.settings.subscribe((settings: SettingsDTO) => {
      this.settings = settings;
    }));
    this.subscriptions.add(this.ps.selectedParty$.subscribe(party => {
      if (party) {
        this.party = party;
      }
      this.dashboardMessages = (this.party && this.party.Messages) ? this.party.Messages : [];
    }));
  }

  ngOnInit() {
    this.dashboardMessages = (this.party && this.party.Messages) ? this.party.Messages : [];
  }

  ngOnChanges() {
    this.dashboardMessages = (this.party && this.party.Messages) ? this.party.Messages : [];
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}

@Pipe({ name: 'stipMsg' })

export class MsgStripPipe implements PipeTransform {
  _messageTextToStrip = "(brought to you by Agilysys Reserve)";
  transform(msg) {
    return msg.replace(this._messageTextToStrip, "");
  }
}
