<app-create-modal [input]="createModalData" (clickEvent)="onCancel($event)">
    <section class="guestcombineModel h-100">
        <section class="infosec">
            <div class="ag_p--3">
                <label class="ag_form--label" [attr.LiteralID]="'lbl_arrivalDate'">
                    {{captions.lbl_selectedPrimaryGuest}}
                </label>
                <span [matTooltip]="member">
                    {{member}}
                </span>
            </div>
        </section>
        <section class="steppersection">
            <mat-horizontal-stepper #stepper>
                <mat-step label="{{captions.stp_hdr_sourcesearchcriteria}}">
                    <div class="slidderContectSec">
                        <ag-source-search-criteria [inputs]='combinemenberInputs'
                            (combinemenberOutputs)='output($event)'></ag-source-search-criteria>
                    </div>
                    <div class="ag_display--flex">
                        <app-button [attr.automationId]="'Btn_CombineMember_cancel'" class='d-inline-block' [buttontype]="cancelBTN"
                            (valueChange)='steppercancel($event)'>
                        </app-button>
                        <app-button [attr.automationId]="'Btn_CombineMember_next'" class='d-inline-block ag_ml--auto' [buttontype]="nextBTN"
                            (valueChange)='steppernext($event,stepper)'>
                        </app-button>
                    </div>
                </mat-step>
                <mat-step label="{{captions.stp_hdr_setvaluetocombine}}">
                    <ng-container *ngIf="enableVTC">
                        <div class="slidderContectSec">
                            <ag-value-to-combine [inputs]="setvaluetocombineInputs" (VTCcombineEmitter)="AVTC_output($event)"></ag-value-to-combine>
                        </div>
                        <div class="ag_display--flex">
                            <app-button [attr.automationId]="'Btn_CombineMember_cancel'" class='d-inline-block' [buttontype]="cancelBTN"
                                (valueChange)='steppercancel($event)'>
                            </app-button>
                            <span class="ag_ml--auto">
                                <app-button [attr.automationId]="'Btn_CombineMember_previous'" class='d-inline-block' [buttontype]="previoustBTN"
                                    (valueChange)='stepperprevious($event,stepper)'>
                                </app-button>
                                <app-button [attr.automationId]="'Btn_CombineMember_combine'" class='d-inline-block' [buttontype]="combineBTN"
                                    (valueChange)='steppercombine($event)'>
                                </app-button>
                            </span>
                        </div>
                    </ng-container>
                </mat-step>
             
            </mat-horizontal-stepper>
        </section>
    </section>
</app-create-modal>
