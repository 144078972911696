import { Pipe, PipeTransform } from '@angular/core';
import { ReservationDTO } from '@models/InputContact';

@Pipe({
  name: 'contactNameFilter'
})
export class ContactNameFilterPipe implements PipeTransform { 

  transform(reservations: ReservationDTO[], searchString: string): any[] {

    if (reservations.length == 0 || !searchString) {
      return reservations;
    }
    return reservations.filter(res =>
      ((res.Contact.FirstName && res.Contact.FirstName.toLowerCase().includes(searchString.toLowerCase())) ||
      (res.Contact.LastName && res.Contact.LastName.toLowerCase().includes(searchString.toLowerCase()))) || (res.Contact.FirstName && res.Contact.LastName && (this.getConcatedName(res.Contact.FirstName, res.Contact.LastName).includes(searchString.toLowerCase())))
    );
  }

  getConcatedName(firstName? : string, LastName? : string){
    if(firstName && LastName){
      return firstName.toLowerCase() + ' ' + LastName.toLowerCase();
    }
    return '';
  }
}

