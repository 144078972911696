import { ActionMode } from 'src/app/common/shared/shared/enums/enums';

export interface LetterLandingInputs {
  searchPlaceholder: string;
  createBtnLabel: string;
  headerOptions?: any[];
  tableOptions: any[];
}

export const enum TenantConfigurations {
  TenantConfiguration = 'TENANTCONFIGURATION'
}
export interface LetterTableContent {
  id: number;
  code: string;
  name: string;
  type : LetterTemplateType
  listOrder: number;
  active:  boolean;
  isInActive: boolean;
  templateData: string;
}

export interface CreateLetterInputs {
  letterType: LetterTemplateType;
  codePlaceholder: string;
  createTitle: string;
  editorLabel: string;
  editTitle: string;
  namePlaceholder: string;
}

export enum LetterTemplateType {
  CartAgreement =1,
  ClubAgreement,
  ShoeAgreement ,
  RetailSaleChit
}

export interface LetterCrudActionInput {
  mode: ActionMode;
  actionButton: string;
  form: LetterForm;
  listOrder: number;
  isCopy?: boolean;
  code?: string;
  isViewOnly?: boolean;
}

export interface LetterForm {
  code: string;
  name: string;
  listOrder: number;
  active: boolean;
  templateData: string;
}

// export interface LetterSetupUI {
//     code: string;
//     type: number;
//     name: string;
//     templateData: string;
//     active: boolean;
//     listOrder: number;
//     version: string;
//     id: number;
// }

// export interface LetterTable {
//     code: string;
//     name: string;
//     listOrder: number;
//     isActive: toggleButtonModel;
//     version: string;
//     id: number;
//     presentation: string;
// }
