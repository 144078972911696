import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { selecttypeenum } from "../../unpaid-players/unpaid-players.component";
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { GiftCardBusiness } from 'src/app/retail/sytem-config/gift-cards/gift-cards.business';
import { RedemptionHistoryUI, GiftCardTypeNames, IssueGiftCardPopupModel } from 'src/app/retail/shared/service/payment/payment-model';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { Router } from '@angular/router';
import { AlertType } from 'src/app/retail/shared/shared.modal';
import { ButtonOptions, ButtonType, GiftCardStatus } from 'src/app/retail/shared/globalsContant';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { GiftCardInfo } from "src/app/retail/shared/business/shared.modals";

@Component({
  selector: "app-gift-card-details",
  templateUrl: "./gift-card-details.component.html",
  styleUrls: ["./gift-card-details.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class GiftCardDetailsComponent implements OnInit {
  @Input() giftCardDetails: GiftCardInfo;
  captions: any;
  currencySymbol: string;
  cardReloadFormGroup: UntypedFormGroup;
  headerOption: {
    key: string;
    description: any;
    alignment: string;
    sorting: boolean;
    searchable: boolean;
  }[];

  tableOptions: { selectType: selecttypeenum } = {
    selectType: selecttypeenum.none
  };
  redeemHistoryYearData = new Set();
  filteredData: RedemptionHistoryUI[] = [];
  SelectedData = [];
  cardExpiryDate;
  GiftCardTypeNames = GiftCardTypeNames;
  isCardActive: boolean = false;
  reloadCaption: string = "";
  get VendorName() {
    return this.giftCardDetails?.HandleInfo?.isThirdParty ? this._giftCardBussiness.GiftcardVendorName : this.captions.VendorName["1"]
  }
  get isAgilysysGiftcard(){
    return (this.giftCardDetails.HandleInfo.isThirdParty && this._giftCardBussiness.IsAgilysysGiftcard)
  }
  get CardActiveToggleState() { return this.cardReloadFormGroup.controls['cardStatus'].value }
  floatLabel: string;


  constructor(private localization: RetailLocalization
    , private fb: UntypedFormBuilder
    , private _utils: RetailUtilities
    , private _giftCardBussiness: GiftCardBusiness
    , private shopService: CommonVariablesService
    , private _propertyInfo: RetailPropertyInformation
    , private router: Router) {
    this.captions = this.localization.captions.shop.GiftCard;
    this.currencySymbol = this.localization.currencySymbol;
    this.cardReloadFormGroup = this.fb.group({
      reloadamount: [this.localization.localizeCurrency(0, false), Validators.required],
      extendexpirydate: [{ value: false, disabled: false }],
      cardStatus: [{ value: true, disabled: false }]
    });
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.cardReloadFormGroup = this.fb.group({
      reloadamount: [this.localization.localizeCurrency(0, false), Validators.required],
      extendexpirydate: [{ value: false, disabled: this._giftCardBussiness._giftCardConfig && !this._giftCardBussiness._giftCardConfig.allowExpiration }],
      cardStatus: [{ value: true, disabled: false }]
    });

    this.headerOption = [
      {
        key: "sno",
        description: this.captions.SNO,
        alignment: "textRight",
        sorting: false,
        searchable: false
      },
      {
        key: "",
        description: '',
        alignment: "textLeft",
        sorting: false,
        searchable: false
      },
      {
        key: "ticketno",
        description: this.captions.TicketNo,
        alignment: "textLeft",
        sorting: false,
        searchable: false
      },
      {
        key: "date",
        description: this.captions.Date,
        alignment: "textLeft",
        sorting: false,
        searchable: false
      },
      {
        key: "amount",
        description: this.captions.Amount,
        alignment: "textRight",
        sorting: false,
        searchable: false
      },
      {
        key: "",
        description: '',
        alignment: "textLeft",
        sorting: false,
        searchable: false
      }
    ];

    this.reloadCaption = this.localization.replacePlaceholders(this.captions.ReloadAmount, ["currenySymbol"], [this.currencySymbol]);

    this.tableOptions = {
      selectType: selecttypeenum.none
    };
    this.shopService.isFromRefund = false;
    this.shopService.RefundTicketTransactionIDs = [];
  }

  ngOnChanges(){
    this.redeemHistoryYearData = new Set()
    this.SelectedData = [];
    let yearValues: number[] = this.giftCardDetails.redemptionHistory.map(x => this._utils.getFullYear(x.date));
    yearValues.sort((a,b) => 0 - (a > b ? 1 : -1));
    this.redeemHistoryYearData = new Set(yearValues);
    this.filteredData = this.giftCardDetails.redemptionHistory;
    this.filteredData = this.generateSno(this.filteredData);
    this.cardExpiryDate = this.giftCardDetails.ExpiryDate;
    if (this.giftCardDetails.ExpiryDate != this.captions.NA) {
      this.isCardActive = this.localization.DeLocalizeShortDate(this.cardExpiryDate) >= this._propertyInfo.CurrentDate ? true : false;
    } else {
      this.isCardActive = true;
    }
    this.ResetAmountForSelectedcard();
  }

  OnExtendExpiryDateToggle(event) {
    if (this.giftCardDetails.ExpiryDate == this.captions.NA || this.isAgilysysGiftcard) {
      return;
    }

    let value = this.cardReloadFormGroup.controls.extendexpirydate.value;
    if(!value){      
      this.cardExpiryDate = this.localization.LocalizeDate(this._utils.AddDays(this._propertyInfo.CurrentDate, this._giftCardBussiness._giftCardConfig.expireDays));
    }else{
      this.cardExpiryDate = this.giftCardDetails.ExpiryDate;
    }
  }

  AddAmountForSelectedcard() {    
    let reloadAmount = this.localization.currencyToSQLFormat(this.cardReloadFormGroup.controls.reloadamount.value);
    if (reloadAmount > 0) {
      if (this.giftCardDetails.ExpiryDate && this.giftCardDetails.ExpiryDate != this.captions.NA && !this.isAgilysysGiftcard
        && this.localization.DeLocalizeShortDate(String(this.giftCardDetails.ExpiryDate)) < this._propertyInfo.CurrentDate) {
        this._utils.showAlert(this.captions.CardExpired, AlertType.Warning, ButtonType.Ok);
        return;
      }
      let topupData: IssueGiftCardPopupModel = {
        thirdparty: this.giftCardDetails.HandleInfo.isThirdParty,
        GiftCardNumber: this.giftCardDetails.CardNumber,
        Amount: reloadAmount,
        FirstName: this.giftCardDetails.FirstName,
        LastName: this.giftCardDetails.LastName,
        EmailId: this.giftCardDetails.Email,
        PhoneNumber: this.giftCardDetails.PhoneNumber,
        openItem: false,
        ExpiryDate:
          this.cardExpiryDate == this.captions.NA ||
          (this.giftCardDetails.HandleInfo.isThirdParty &&
            this._giftCardBussiness.IsAgilysysGiftcard)
            ? null
            : this.localization.ConvertDateToISODateTime(this.cardExpiryDate),
        HandleInfo: this.giftCardDetails.HandleInfo,
        isTopUp: true,
        isFromBalanceScreen: true,
      };
      this._giftCardBussiness.ShowIssueGiftCardModal(topupData, this.ProceedToOrderSummary.bind(this));
    }
    
  }

  ProceedToOrderSummary(topupData){
    let giftCardItem = this._giftCardBussiness.FormProductObj(topupData);
    if (giftCardItem) {
      this.shopService.selectedProducts = [giftCardItem];
      this.shopService.isFromGiftCard = true;
      this.router.navigate(['/shop/viewshop/order']);
    }
  }

  ResetAmountForSelectedcard() {
    if (this.cardReloadFormGroup) {
      this.cardReloadFormGroup.controls.extendexpirydate.setValue(false);
      this.cardReloadFormGroup.controls.reloadamount.setValue(this.localization.localizeCurrency(0, false));
      this.cardExpiryDate = this.giftCardDetails.ExpiryDate;  
    }    
  }

  filterRedemtionData(event, selected) {
    if (this.SelectedData.indexOf(selected) === -1) {
      this.SelectedData.push(selected);
    } else {
      this.SelectedData.splice(this.SelectedData.indexOf(selected), 1);
    }
    if (this.SelectedData && this.SelectedData.length > 0) {
      this.filteredData = this.giftCardDetails.redemptionHistory.filter(x => this.SelectedData.includes(this._utils.getFullYear(x.date)));
    } else {
      this.filteredData = this.giftCardDetails.redemptionHistory;
    }
    this.filteredData = this.generateSno(this.filteredData);
  }

  generateSno(filteredData) {
      return filteredData.map((x, i) => { x.sno = i + 1; return x; });
  }

  OnCardStatusChange(event) {
    //Show confirmation
    console.log(event);    
    const confirmationMsg = this.localization.replacePlaceholders(
      this.captions.CardStatusChangeConfirmationMsg,
      ['cardStatus'],
      [event[0] ? this.captions.Active : this.captions.Inactive]
    );
    this._utils.ShowErrorMessage(
      this.localization.captions.common.Warning,
      confirmationMsg,
      ButtonType.YesNo,
      this.UpdateCardStatus.bind(this),
      event[0]
    );
  }

  UpdateCardStatus(result: string, toggleState){
    if (result.toLowerCase() == ButtonOptions.Yes.toLowerCase()) {
      const cardStatusToBeUpdated = toggleState ? GiftCardStatus.Active : GiftCardStatus.InActive;
      this._giftCardBussiness.PerformGiftcardActivationDeactivation(this.giftCardDetails, cardStatusToBeUpdated);
		} else {
      //revert the toggle state
      this.cardReloadFormGroup.controls['cardStatus'].setValue(!toggleState);
    }
  }

}
