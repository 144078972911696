import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AgAddressFieldConfig, GoogleAddressOutput, AgContactConfig, ContactType, AgPhoneDetail } from '../../shared/shared.modal';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { Subscription, ReplaySubject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VendorSetupService } from '../retail-vendor-setup.service';
import { VendorType, VendorUI, Email, Phone } from '../retail-vendor-setup.modals';
import { takeUntil } from 'rxjs/operators';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { startWith } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-create-retail-vendor-setup',
  templateUrl: './create-retail-vendor-setup.component.html',
  styleUrls: ['./create-retail-vendor-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateRetailVendorSetupComponent implements OnInit {

  vendorForm: UntypedFormGroup;
  addressInput: AgAddressFieldConfig;
  addressValue: string[];
  mailInputs: AgContactConfig;
  mailInfo: [];
  phoneInputs: AgContactConfig;
  phoneInfo: AgPhoneDetail[];
  captions: any;
  isSaveDisabled: boolean = false;
  private formSubscriber: Subscription = new Subscription();
  vendorCodeMinLengthError: string;
  vendorCodeMaxLengthError: string;
  vendorTypes: VendorType[] = [];
  buttonText: string;
  isEditFlag: boolean = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  formData: any;
  listOrder: number;
  MinLength: number = 3;
  MaximumPostalLengthError: string ;
  floatLabel: string;


 public isViewOnly: boolean;
 filteredCountries: Observable<any>;
  constructor(private fb: UntypedFormBuilder, private localization: RetailLocalization,
              @Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<any>
  ,           private vendorSetupService: VendorSetupService, private utils: RetailUtilities, private httpClient: HttpClient) {
    this.floatLabel = this.localization.setFloatLabel;
   }

  ngOnInit() {
    this.captions = this.localization.captions.vendors;
    this.formGenerator();
    this.pageInitializations();
    this.MaximumPostalLengthError = this.localization.
    replacePlaceholders(this.captions.AtleastXcharactersneedtobeentered, ['noOfCharacters'], [4]);
    this.utils.geCountriesJSON().then(res => {
    this.filteredCountries = this.vendorForm.controls.country.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      map((country: string) => country ? this.FilterCountry(country) : [])
    );
    this.vendorForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (this.vendorForm.controls['city'].value) {
        this.vendorForm.controls.country.setErrors({ required: true });
      } else {
        this.vendorForm.controls.country.setErrors(null);
      }
      if (this.vendorForm.controls['country'].value &&
        !this.utils.FilterCountryValueFromData(this.vendorForm.controls['country'].value)) {
        this.vendorForm.controls.country.setErrors({ invalid: true });
      } else if ((this.vendorForm.controls['city'].value &&
        this.utils.FilterCountryValueFromData(this.vendorForm.controls['country'].value) &&
        this.vendorForm.controls['country'].value) || (!this.vendorForm.controls['city'].value &&
          !this.vendorForm.controls['country'].value)) {
        this.vendorForm.controls.country.setErrors(null);
      }
      this.vendorForm.controls['country'].markAsTouched();
  });
});
}

  fileExists(url) {
    if (url){
        const req = new XMLHttpRequest();
        req.open('GET', url, false);
        req.send();
        return req.status === 200;
    } else {
        return false;
    }
}

  FilterCountry(value: string) {
    const filterValue = value.toLowerCase();
    return this.utils.countryDetails.filter(state => state.CountryName.toLowerCase().indexOf(filterValue) === 0);
  }

  pageInitializations() {
    this.vendorForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      this.formData = x;
    });

    this.formSubscriber = this.vendorForm.statusChanges.subscribe((x: any) => {
      this.isSaveDisabled = true;
      // save button validation
      if (this.vendorForm.valid && this.vendorForm.dirty) {
        this.isSaveDisabled = false;
      }
    });

    if (this.data.popupConfig.listOrder){
      this.listOrder = this.data.popupConfig.listOrder;
    }

    if (this.data.popupConfig.isViewOnly){
      this.isViewOnly = this.data.popupConfig.isViewOnly;
      this.utils.disableControls(this.vendorForm);
    }

    this.buttonText = this.captions.lbl_saveText;


    this.addressInput = {
      className: 'ag_form-control--lg',
      form: this.vendorForm,
      formControlName: 'addressDetails',
      placeHolder: this.captions.lbl_address,
      disabled: false,
      maxlength : 50
    };
    this.phoneInputs = {
      form: this.vendorForm,
      formArrayName: 'phone',
      showSwitches: true,
      primarySwitchLabel: this.captions.lbl_primary,
      privateSwitchLabel: this.captions.lbl_private,
      disabled: false
    };
    this.mailInputs = {
      form: this.vendorForm,
      formArrayName: 'email',
      showSwitches: true,
      privateSwitchLabel: this.captions.lbl_PrivateEmail,
      primarySwitchLabel: this.captions.lbl_PrimaryEmail,
      disabled: false
    };

    if (this.data.popupConfig.operation === 'edit') {
      this.isEditFlag = true;
      this.addressValue = this.data.popupConfig.data.address;
      this.phoneInfo = this.data.popupConfig.data.phone;
      this.mailInfo = this.data.popupConfig.data.email;
      this.addressInput.disabled = this.isViewOnly;
      this.phoneInputs.disabled = this.isViewOnly;
      this.mailInputs.disabled = this.isViewOnly;
      this.addressInput = { ...this.addressInput };
      this.phoneInputs = { ...this.phoneInputs };
      this.mailInputs = { ...this.mailInputs };
      this.vendorForm.patchValue(this.data.popupConfig.data);
      this.vendorForm.controls.accountPayable.disable();
      this.vendorForm.controls.vendorCode.disable();
      this.vendorForm.controls.autoGenerateNumber.disable();
      this.buttonText = this.captions.lbl_updateText;
    }

    if (this.data.popupConfig.vendorTypes){
      this.vendorTypes = this.data.popupConfig.vendorTypes;
      this.vendorTypes.unshift({
        id : 0,
        code : '',
        description: '',
        isActive: true,
        listOrder: 0
      });
    }
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    if (this.formSubscriber) {
      this.formSubscriber.unsubscribe();
    }
  }

  formGenerator() {
    this.vendorForm = this.fb.group({
      id: 0,
      autoGenerateNumber: false,
      vendorType: 0,
      vendorCode: ['', [Validators.required, Validators.pattern('^([a-zA-Z0-9]){3,15}$')]],
      accountPayable: '',
      vendorName: ['', [Validators.required, Validators.pattern('^.{3,50}$')]],
      active: true,
      firstName: ['', [ Validators.pattern('^([a-zA-Z0-9 ]){3,50}$')]],
      lastName: ['', [Validators.pattern('^([a-zA-Z0-9 ]){3,50}$')]],
      address: this.fb.array([
        this.fb.group({
          addressDetails: ''
        })
      ]),
      listOrder: '',
      postal_code: ['', [ Validators.pattern('^.{4,12}$')]],
      state: ['', [Validators.pattern('^.{2,25}$')]],
      city: ['', [Validators.pattern('^.{1,25}$')]],
      country: ['', [ Validators.pattern('^.{2,25}$')]],
      phone: this.fb.array([
        this.fb.group({
          id: 0,
          phoneType: '',
          contactType: ContactType.phone,
          countryCode: '',
          extension: '',
          phoneNumber: '',
          primaryPhone: false,
          privateInformtionPhone: false,
          isSynced: false
        })
      ]),
      email: this.fb.array([
        this.fb.group({
          id: 0,
          emailType: '',
          contactType: ContactType.email,
          emailId: '',
          primaryEmail: false,
          privateInformtionEmail: false,
          isSynced: false
        })
      ]),
      comments: [''],
      terms: [0]
    });
  }

  async toggleChange(e) {
    if (e[0]) {
      let autogeneratedNumber = await this.vendorSetupService.getNextVendorCode();
      this.vendorForm.controls.vendorCode.setValue(autogeneratedNumber);
      this.vendorForm.controls.vendorCode.disable();
    }
    else {
      this.vendorForm.controls.vendorCode.setValue('');
      this.vendorForm.controls.vendorCode.enable();
    }
  }

  checkForVendorType(vendorType): boolean {
    let valid: boolean = true;
    if ((vendorType && this.vendorTypes.findIndex(x => x.id == vendorType) == -1)) {
        const errMsg: string = this.captions.err_missing_vendorType;
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
        valid = false;
    }
    return valid;
  }

  addresschange(address: GoogleAddressOutput): void {
    if (this.vendorForm && address) {
      this.vendorForm.controls.state.setValue(address.state);
      this.vendorForm.controls.city.setValue(address.city);
      this.vendorForm.controls.country.setValue(address.country);
      this.vendorForm.controls.postal_code.setValue(address.zipCode);
    }
  }

  onSave(e) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.submitVendorSetupForm().then(x => {
      if (x){
        this.dialogRef.close(x);
      }
    });
    this.utils.ToggleLoader(false);
  }

  async submitVendorSetupForm() {
    try {
      const Vendor = this.vendorForm.getRawValue() as VendorUI;
      Vendor.country = this.FilterCountryValueFromData(Vendor.country);
      if (!this.isEditFlag) {
        this.isSaveDisabled = true;
          return await this.vendorSetupService.createVendor(Vendor, this.listOrder);
      } else {
          return await this.vendorSetupService.updateVendor(Vendor, this.listOrder);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  validateContacts(email: Email[], phone: Phone[]): boolean {
  let isEmailExists = false;
  let isphoneExists = false;

  if (email && email.length > 0){
    isEmailExists = email.filter(x => x.emailType && x.emailId) && email.filter(x => x.emailType && x.emailId).length > 0;
  }

  if (phone && phone.length > 0){
    isphoneExists = phone.filter(x => x.phoneType && x.phoneNumber) && phone.filter(x => x.phoneType && x.phoneNumber).length > 0;
  }

  if (!isphoneExists){
    const errMsg: string = this.localization.getError(75009);
    this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
    return;
  }

  if (!isEmailExists){
    const errMsg: string = this.localization.getError(75010);
    this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
    return;
  }
  return (isEmailExists && isphoneExists);
  }

  onCancel(e) {
    this.dialogRef.close();
  }

  vendorNameOnBlurMethod()
  {
    this.vendorForm.controls.vendorName.setValue(this.vendorForm.controls.vendorName.value.trim());
  }

  FilterCountryValueFromData(country) {
    let countryValue = '';
    if (country && this.utils.FilterCountryByName(country, this.utils.countryDetails).length) {
        countryValue = country;
    } else {
        countryValue = '';
    }
    return countryValue;
}
}
