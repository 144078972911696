  <div class="app-exception">

    <div class="app-exception__container">
     
        <!-- <div style=""> -->
          <!-- <ng-scrollbar track="all"> -->
          <div class="app-exception__show-alert">
            <i class="icon-warning"></i>
          </div>
          <mat-error class="app-exception__error">
            {{Error.ErrorMessage}}
          </mat-error>
          <ul class="rs-mt-15" *ngIf="ErrorMessage && ErrorMessage.length > 0">
            <li class="seat-error-messages app-exception__error-list" *ngFor="let errormsg of ErrorMessage">
              <mat-error class="app-exception__error-msg">
                {{errormsg.msg}}
              </mat-error>
            </li>
          </ul>
          <div *ngIf="WarningMessage && WarningMessage.length > 0">
            <span>{{'warningTitle' | translate}}</span>
            <ul>
              <li class="seat-error-messages app-exception__error-list" *ngFor="let wngmsg of WarningMessage">
                <mat-error class="app-exception__error-msg">
                  {{wngmsg.msg}}
                </mat-error>
              </li>
            </ul>
          </div>
        <!-- </ng-scrollbar> -->
      <!-- </div> -->
     
    </div>
    <div *ngIf="!confirmationPopup" class="app-exception__footer">
      <div id="leftbox">
        <activities-app-button [buttontype]="sendBtn" (click)="sendEmailMsg()"></activities-app-button>
      </div>
      <div id="middlebox">
        <activities-app-button [buttontype]="copyBtn" (click)="copyMsg()"></activities-app-button>
      </div>
    </div>
    <div *ngIf="confirmationPopup" class="app-exception__footer">
      <div id="leftbox">
        <activities-app-button [buttontype]="cancelBtn" (click)="closepopup()"></activities-app-button>
      </div>
    </div>
    
  </div>
