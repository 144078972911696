<div class="addon__panel-view">
    <div *ngFor="let addOnItem of addons" class="addon__addon-section"
        [ngStyle]="{'border-bottom-color':reservationAddonsService.allAddons[addOnItem.Id] | getCategoryData : reservationAddonsService._settings.Categories :'Color' }">
        <ng-container *ngIf="reservationAddonsService.allAddons[addOnItem.Id].IsVisible" class="addon__card-details">
            <div class="addon__left">
                <span class="addon__category"
                    [ngStyle]="{'color':reservationAddonsService.allAddons[addOnItem.Id] | getCategoryData : reservationAddonsService._settings.Categories :'Color' }">
                    {{reservationAddonsService.allAddons[addOnItem.Id] | getCategoryData :
                    reservationAddonsService._settings.Categories :'Text'}}</span>
                <div class="addon__name">
                    <span class="addon_name_formatted"
                        [title]="reservationAddonsService.allAddons[addOnItem.Id].AddonName">
                        {{reservationAddonsService.allAddons[addOnItem.Id].AddonName}}</span>
                    <span *ngIf="reservationAddonsService.allAddons[addOnItem.Id].IsMandatory"
                        style=" color:read;font-weight: bold;color: red;font-size: 20px;"> *</span>
                </div>
                <div class="addon__price">
                    <div>
                        {{reservationAddonsService._settings.General.OperationCurrency}}{{reservationAddonsService.allAddons[addOnItem.Id].Price
                        | number : '1.0-2'}}
                        <ng-container
                            *ngIf="reservationAddonsService.allAddons[addOnItem.Id].PricingCategory == PricingCategory.ByDuration">
                            <span class="addon__price-by"
                                *ngIf="reservationAddonsService.allAddons[addOnItem.Id].PricingType == PricingType.ByHour">
                                {{'For' | translate}} {{
                                reservationAddonsService.allAddons[addOnItem.Id].AddOnDetails[0].Duration }}
                                {{'hours' |
                                translate}}
                            </span>
                            <span class="addon__price-by"
                                *ngIf="reservationAddonsService.allAddons[addOnItem.Id].PricingType == PricingType.ByMinutes">
                                {{'For' | translate}} {{
                                reservationAddonsService.allAddons[addOnItem.Id].AddOnDetails[0].Duration }}
                                {{'minute'
                                | translate}}
                            </span>
                        </ng-container>
                        <span class="addon__price-by"
                            *ngIf="reservationAddonsService.allAddons[addOnItem.Id].PricingCategory == PricingCategory.BySession">
                            {{'perSession' |
                            translate}}</span>
                    </div>

                </div>
            </div>
            <div class="addon__right">
                <div class="addon__quantity-section">
                    <span class="icon-minus-outline" (click)="reduceQuantity(addOnItem)"></span>
                    <input [(ngModel)]="addOnItem.Quantity" type="text" (input)="addonUpdate(addOnItem)"
                        (paste)="validateAddOnsQtyOnChange(item)" numbersOnly (focusout)="addonUpdate(item)"
                        matInput>
                    <!-- (keypress)="isNumberKey($event)" -->
                    <span class="icon-add-solid" (click)="increaseQuantity(addOnItem)"></span>
                </div>
                <div class="addon__availablility">

                    <label *ngIf="!reservationAddonsService.allAddons[addOnItem.Id].IsUnlimited" class="" for="">
                        <span
                            [ngClass]="{'addon__available': reservationAddonsService.allAddons[addOnItem.Id].AvailableQuantity, 'addon__soldout' : !reservationAddonsService.allAddons[addOnItem.Id].AvailableQuantity }">{{reservationAddonsService.allAddons[addOnItem.Id].AvailableQuantity}}</span>
                        {{'of' | translate}} {{reservationAddonsService.allAddons[addOnItem.Id].OverAllQuantity}}
                    </label>
                    <label class="addon__unlimited"
                        *ngIf="reservationAddonsService.allAddons[addOnItem.Id].IsUnlimited">
                        {{'UnlimitedInventory' | translate}}
                    </label>
                    <!-- <span class="addon__overbooked" *ngIf="reservationAddonsService.allAddons[addOnItem.Id].Overbooked">{{'Overbooked' | translate}}: {{reservationAddonsService.allAddons[addOnItem.Id].Overbooked}}</span> -->
                </div>
            </div>
            <span class="error-icon" *ngIf="!addOnItem.Valid"
                [matTooltip]="'MinMaxQuantityInfoText' | translate : {minQuantity: reservationAddonsService.allAddons[addOnItem.Id].MinQuantity, maxQuantity: reservationAddonsService.allAddons[addOnItem.Id].MaxQuantity}">
                <i class="icon-exclamation aside__menu-warning"><i class="path1"></i><i class="path2"></i><i
                        class="path3"></i></i>
            </span>
        </ng-container>
    </div>
</div>