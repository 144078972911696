import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CreateCustomFeePopupComponent } from './create-custom-fee-popup/create-custom-fee-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { ButtonValue, AgFieldConfig, AgDateConfig, DropdownOptions, TableOptions, TableHeaderOptions, AgDropdownConfig } from 'src/app/common/Models/ag-models';
import { Localization } from "src/app/common/localization/localization";
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PropertyInformation } from '../../services/property-information.service';
import { CustomFeeBusiness } from './custom-fee.business';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { CommonUtilities } from '../utilities/common-utilities';
import { HttpMethod, HttpServiceCall } from '../service/http-call.service';
import { Host } from '../globalsContant';
import { BaseResponse } from '../../retail.modals';
import { CustomFeeAPIModel, modes } from './custom-fee.model';
import { CommonApiRoutes } from 'src/app/common/common-route';
import { of } from 'rxjs';


@Component({
  selector: 'app-custom-fee',
  templateUrl: './custom-fee.component.html',
  styleUrls: ['./custom-fee.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CustomFeeBusiness]
})
export class CustomFeeComponent implements OnInit {
  createButton: ButtonValue;
  applyButton: ButtonValue;
  captions: any;
  customFeeForm : UntypedFormGroup;
  fromDateInputs: AgDateConfig;
  toDateInputs: AgDateConfig;
  feeOptions: DropdownOptions[];
  IsEditModeEnabled = false;
  options: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent: any = [];
  IsEditModeEnabledFlag = false;
  isAdditionalFeeEnabled: boolean = false
  customFeeConfigSetting: any;
  customFeeConfigs: CustomFeeAPIModel[] = [];
  timeformat: number;
  feeInput: AgDropdownConfig;
  searchText: string;

  constructor(public dialog: MatDialog,
    private localization: Localization,       
    private propertyInformation: PropertyInformation,
    private fb: UntypedFormBuilder,
    private business: CustomFeeBusiness, 
    private utils: CommonUtilities,
    private http: HttpServiceCall,
    ) {
    this.captions = this.localization.captions;

   }

  ngOnInit(): void {
    this.GetCustomFeeConfigurationSetting();
    this.intialize();
  }

  async intialize() {
    this.timeformat = this.localization.getTimeFormat();
    this.customFeeForm = this.fb.group({
      requireAdditionalFee: '',
      fromDate: this.propertyInformation.CurrentDate,
      toDate: this.propertyInformation.CurrentDate,
      fee: ''
    });
    await this.generateTable();
    this.fromDateInputs = {
      className: 'ag_form-control',
      form: this.customFeeForm,
      formControlName: 'fromDate',
      placeHolder: this.captions.lbl_fromDateRange,
      automationId: 'customFee'
    };
    this.toDateInputs = {
      className: 'ag_form-control',
      form: this.customFeeForm,
      formControlName: 'toDate',
      placeHolder: this.captions.lbl_toDateRange,
      minDate: this.customFeeForm.controls.fromDate.value,
      automationId: 'customFee'
    };
    this.createButton = {
      type: 'primary',
      label: this.captions.btn_createFee,
      disabledproperty: false
    };
    this.applyButton = {
      type: 'primary',
      label: this.captions.btn_apply,
      disabledproperty: false
    };
  }
  setDropDownOptions()
  {
    this.feeInput = {
      form: this.customFeeForm,
      formControlName: 'fee',
      placeHolder: this.captions.lbl_fee,
      selectOptions: of(this.feeOptions),
      isMultiSelect: true,
      isAll: true,
      showRequired:false,
      className: 'ag_form-control ag_display--inblock',
      disabled: false,
    };
  }
  async generateTable() {
    this.headerOptions = this.business.GetTableHeader();
    this.options = this.business.GetTableOptions();
  }

  async loadDropDownOptions() {
    await this.InvokeServiceCall(CommonApiRoutes.GetCustomFeeConfiguration, HttpMethod.Get, Host.retailManagement, [], [], true);
  }

  onStartDateChanged(eve)
  {
    if(this.customFeeForm.controls.fromDate.value === null){
      this.customFeeForm.controls.toDate.setValue(null);
      this.toDateInputs.minDate = this.propertyInformation.CurrentDate;
    }
    else{
      if (this.isfromDateExceedstoDate()) {
        this.customFeeForm.controls.toDate.setValue(this.customFeeForm.controls.fromDate.value);
      }
      this.toDateInputs.minDate = this.customFeeForm.controls.fromDate.value;
    }
    this.toDateInputs = {...this.toDateInputs};
  }

  isfromDateExceedstoDate(): boolean {
    const fromDate = this.customFeeForm.controls.fromDate.value;
    const toDate = this.customFeeForm.controls.toDate.value;
    return this.resetTime(fromDate) > this.resetTime(toDate);
  }

  resetTime(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  getCustomFeeDataByFilters() {
    let body = {
      startDate: this.localization.convertDateTimeToAPIDateTime(this.customFeeForm.controls.fromDate.getRawValue()),
      endDate: this.localization.convertDateTimeToAPIDateTime(this.customFeeForm.controls.toDate.getRawValue()),
    }
    this.InvokeServiceCall(CommonApiRoutes.GetCustomFeeConfigByFilters, HttpMethod.Post, Host.retailManagement, body, [], true);
  }

  errorCallBack<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
      this.showError(error.errorCode);
  }

  successCallBack<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == CommonApiRoutes.GetCustomFeeConfigByFilters || callDesc == CommonApiRoutes.UpdateCustomFeeConfiguration || callDesc == CommonApiRoutes.CreateCustomFeeConfiguration) {
      let response = <any>result.result;
      this.customFeeConfigs = [];
      response.forEach(element => {
        let customFee: CustomFeeAPIModel = {
          id: element.id,
          availableDays: element.availableDays,
          endTime: element.endTime,
          startTime: element.startTime,
          isPercentage: element.isPercentage,
          linkedItemId: element.linkedItemId,
          name: element.name,
          value: element.value,

        }
        this.customFeeConfigs.push(customFee);
      });
      this.bindDataToTable();
      this.utils.ToggleLoader(false);
    }
    else if (callDesc == "GetConfiguration") {
      this.customFeeConfigSetting = result.result;
      let requireCustomFee = (this.customFeeConfigSetting && JSON.parse(this.customFeeConfigSetting.value) && this.customFeeConfigSetting.isActive) ? true : false;
      this.customFeeForm.controls['requireAdditionalFee'].setValue(requireCustomFee);
      this.isAdditionalFeeEnabled = requireCustomFee;
      console.log(this.customFeeConfigSetting);
    }
    else if (callDesc == CommonApiRoutes.DeleteCustomFeeConfiguration) {
      this.getCustomFeeDataByFilters();
    }
  }
  bindDataToTable()
  {
    let res = [];
    this.customFeeConfigs.map(x=>{
      let startTime = this.localization.getTime(this.localization.ConvertStringDateTimeToDate(x.startTime, this.localization.LocalizeTime(x.startTime)), this.timeformat)
      let endTime = this.localization.getTime(this.localization.ConvertStringDateTimeToDate(x.endTime, this.localization.LocalizeTime(x.endTime)), this.timeformat)
      let val: any = {
        fee : x.name,
        dateRange: this.localization.LocalizeShortDate(new Date(x.startTime)) + " - " + this.localization.LocalizeShortDate(new Date(x.endTime)),
        time: startTime + " - " +endTime,
        price: x.value,
        id : x.id
      }
      res.push(val);
    })
    this.tableContent = res;
  }

  openDialog(mode: any, editData?:any) {
    console.log(mode)
    this.dialog.open(CreateCustomFeePopupComponent, {
      height: 'auto',
      width: '54%',
      hasBackdrop: true,
      panelClass: 'small-popup',
      disableClose: true,
      data: {
        data: this.customFeeConfigs.find(x => x.id == editData?.id)??[],
        mode: mode,
      }
    }).afterClosed().subscribe(res => {
      if (res.from === DialogCloseEnum.Action) {
        this.IsEditModeEnabledFlag = false;
        this.tableContent = [];
        res.data.filter = {
          startDate: this.localization.convertDateTimeToAPIDateTime(this.customFeeForm.controls.fromDate.getRawValue()),
          endDate: this.localization.convertDateTimeToAPIDateTime(this.customFeeForm.controls.toDate.getRawValue()),
        }
        if (mode == modes.create) {
          this.InvokeServiceCall(CommonApiRoutes.CreateCustomFeeConfiguration, HttpMethod.Post, Host.retailManagement, res.data, [], true);
        }
        else if (mode == modes.edit) {
          this.InvokeServiceCall(CommonApiRoutes.UpdateCustomFeeConfiguration, HttpMethod.Put, Host.retailManagement, res.data, [], true);
        }
      }
      else if (res.from === DialogCloseEnum.Close || res.from === DialogCloseEnum.Cancel)
      {
        this.tableContent = [...this.tableContent]
      }
    });
  }

  onToggleChange(eve) { }

  sliderChange(eve) {
    let body = {
      id: this.customFeeConfigSetting.id,
      moduleId: this.customFeeConfigSetting.moduleId,
      switch: this.customFeeConfigSetting.switch,
      value: eve
    }
    this.UpdateSettings([body]).then(res => {
      this.isAdditionalFeeEnabled = eve;
    });
  }

  editEvent(e) {
    console.log(e);
    this.openDialog(modes.edit,e);
  }

  tableAction(eve) {
    switch (eve.fromType) {
      case FromTypeEnum.edit:
        this.IsEditModeEnabledFlag = true;
        this.editEvent(eve.Obj);
        break;
      case FromTypeEnum.delete:
        this.deleteEvent(eve.Obj);
        break;
    }
  }
  async deleteEvent(customFee: CustomFeeAPIModel)
  {
    try {
      await this.InvokeServiceCall(CommonApiRoutes.DeleteCustomFeeConfiguration, HttpMethod.Delete, Host.retailManagement, undefined, { id:customFee.id }, true);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  public async GetCustomFeeConfigurationSetting(): Promise<any> {
    try {
      await this.InvokeServiceCall("GetConfiguration", HttpMethod.Get, Host.retailManagement, undefined, { module: 3, Switch: 'REQUIRE_CUSTOM_FEE' }, true);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  private async InvokeServiceCall<T>(callDesc: string, callType: HttpMethod, host: Host, body?: any, uRIParams?: any, isLoaderRequired: boolean = false) {
       this.http.CallApiWithCallback<T>({
      host: host,
      callDesc: callDesc,
      method: callType,
      showError: false,
      error: await this.errorCallBack.bind(this),
      success: await this.successCallBack.bind(this),
      uriParams: uRIParams,
      body: body,
      extraParams: []
    }, false);
  }

  private showError(errorCode: number) {
    let errMsg = this.localization.getError(errorCode);
    this.utils.ShowError("Error", errMsg);
  }

  public async UpdateSettings(body: any[]) {
    try {
        return await this.InvokeServiceCall("UpdateSetting", HttpMethod.Put, Host.retailManagement, body, [], true);
    } catch (e) {
        this.http.exceptionHandle(e);
    }
  }

  searchValueChange(eve) {
    this.searchText = eve;
  }
}
