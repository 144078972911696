import { ButtonTypes, ButtonType } from 'src/app/common/enums/shared-enums';
import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { createModal } from 'src/app/common/components/create-modal/create-model';
import { TableSearchHeader} from 'src/app/common/Models/ag-models';
import { ActionMode } from 'src/app/common/shared/shared/enums/enums';
import { AlertAction, AlertType } from 'src/app/common/Models/common.models';
import { LetterLandingBusiness } from './letter-landing.business';
import { TemplateServiceCommunication } from 'src/app/common/templates/templates.service';
import { FacadeService } from 'src/app/common/services/facade.service';
import { UserAccessService } from 'src/app/common/services/user-access.service';
import { MatDialog } from '@angular/material/dialog';
import { LetterSetupBusiness } from '../../letter-setup/letter-setup.business';
import { CreateLetterInputs, LetterCrudActionInput, LetterLandingInputs, LetterTableContent } from '../../letter-setup/letter.model';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { UI } from '../../letter-setup/template.model';
import { TemplateDataService } from '../../shared/service/data- services/template.data.service';
@Component({
  selector: 'app-letter-landing',
  templateUrl: './letter-landing.component.html',
  styleUrls: ['./letter-landing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [LetterSetupBusiness, LetterLandingBusiness, TemplateServiceCommunication,TemplateDataService,
              UserAccessService, FacadeService]

})
export class LetterLandingComponent implements OnInit {
  @Input() landingInputs: LetterLandingInputs;
  @Input() createInputs: CreateLetterInputs;
  @Input('landingTableContent')
  set tableDataContent(value: LetterTableContent[]) {
    this.tableContent = value;
    this.backupOriginalContent = value;
    this.refreshGrid();
  }
  @Output() inActiveToggleChange = new EventEmitter();
  captions: any;
  commonCaption:any;
  createForm: UntypedFormGroup;
  createModalData: createModal;
  crudActionInput: LetterCrudActionInput;
  headerOptions;
  IsEditModeEnabledFlag: boolean;
  isViewOnly: boolean;
  letterCreationToggler: boolean;
  options;
  searchHeaderOption: TableSearchHeader;
  searchText: string;
  tableContent: LetterTableContent[];
  backupOriginalContent: LetterTableContent[];
  showInactives: boolean;
  isDefaultTemplateExist: boolean;
  tableContentSubscription: Subscription;
  isDefaultDisable : boolean;
  // isEditFlag is used commonly toggle searchbar on edit and inline edit
  isEditFlag: boolean = false;
  // inlineEditOn is used to disable the form fields, update, cancel buttons on inline edit
  inlineEditOn: boolean = false;
  onEditDisableFunction: boolean = true;
  constructor(private utilities: RetailUtilities, private settingsLettersBusiness: LetterSetupBusiness,
    public facadeService: FacadeService, private _letterLandingBusiness: LetterLandingBusiness, private dialog: MatDialog,
     private _templateServiceCommunication: TemplateServiceCommunication) { }

  ngOnInit(): void {
    this.captions = this.settingsLettersBusiness.captions;
    this.commonCaption=this._letterLandingBusiness.captions.common;
    this.searchHeaderOption = {
      searchPlaceHolder: this.landingInputs.searchPlaceholder,
      createBtnLabel: this.landingInputs.createBtnLabel,
      showInactive: true,
      toggleLabel: this.captions.tog_lbl_showInactive
    };

    this.options= this.landingInputs.tableOptions;
    this.showInactives = false;
    this.validateUserAccess();
  }

  searchChange(event) {
    this.searchText = event;
  }

  showInactiveToggler(event) {
    this.showInactives = event.checked;
    this.inActiveToggleChange.emit(event.checked);
  }

    // On Inline Active Toggle Change
    sliderChange(event) {
      this.inlineEditOn = true;
    }

  async createEvent(actionMode?, temp?: UI.Template, isCopyFlag?) {
    this.createModalData = {
      title: this.createInputs.createTitle
    };

    this.crudActionInput = {
      mode: actionMode ? ActionMode.copy : ActionMode.create,
      actionButton: this.captions.btn_create,
      form: actionMode === ActionMode.copy ? temp : undefined,
       listOrder: temp ? temp.listOrder : await this.settingsLettersBusiness.getNextListOrder(this.createInputs.letterType),
      isCopy: isCopyFlag,
      code: temp ? temp.name : '',
      isViewOnly: this.isViewOnly,
    };
    this.letterCreationToggler = true;
    this.isDefaultDisable = this.tableContent.length == 0 ;
  }

  back(event) {
    if (this.createForm.dirty) {
      this.utilities.showCommonAlert(this.captions.warn_datalost, AlertType.Warning, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.backEmit();
        }
      });
    }
    else {
      this.backEmit();
    }

  }

  backEmit() {
    this.createForm.reset();
    this.IsEditModeEnabledFlag = false;
    this.letterCreationToggler = false;
    this.isEditFlag=false;
  }

  edit(e) {
    this.isEditFlag = true;
    this.settingsLettersBusiness.getEditData(this.createInputs.letterType, e[0].id).then(x => {
      this.isDefaultDisable=x.default;
      this.updateEvent(x);
    });
  }

  delete(e) {
    if(!e[0].isInActive)
    {
      this.utilities.showAlert(this.captions.letterDeleteComfirmation,AlertType.Warning,ButtonType.YesNo,
        (res) => {
          if (res === AlertAction.YES) {
            this.settingsLettersBusiness.deleteTemplate(e[0].id).then(x => {
              this.getTableContent(this.createInputs.letterType);
            });
          }
        }
        );
    }
    
  }

  dragDrp(e) {   
    const dragIndex = e[0];
    const dropIndex = e[1];
    let currentIndex = this.options[0].TablebodyData[dragIndex - 1];
    let droppedIndex = this.options[0].TablebodyData[dropIndex - 1];

    this.settingsLettersBusiness.updateListOrder(currentIndex.id, droppedIndex.listOrder).then(x => {
      this.getTableContent(this.createInputs.letterType);
    });
  }

  afterEditPopupClose(e) {

  }

  async Done(body) {
    this.isEditFlag = false;
    this.inlineEditOn = false;
    this.createLetter(ActionMode.update,body);
  }

  Cancel(event) {
    this.isEditFlag = event;
    this.inlineEditOn = false;
  }
 
  async handleToggleChange(event) {

    event.Obj.active = event.Obj.active.value;
    event.Obj.default = event.Obj.default.value;

    switch (event.toggleKey) {
      case 'active':
        this.createLetter(ActionMode.update, event.Obj);
        break;
      case 'default':
        //Default Toggle Update
        if (event.Obj.default) {
          this.validateDefaultTemplate(event.Obj);
          if (this.isDefaultTemplateExist) {
            this._templateServiceCommunication.showConfirmationPopup(this.showConfirmationSuccessCallback.bind(this, event.Obj, ActionMode.update), this.showConfirmationErrorCallback.bind(this, event.Obj, ActionMode.update));
          }
          else {
            this.createLetter(ActionMode.update, event.Obj);
          }
        }
        else {
          this.createLetter(ActionMode.update, event.Obj);
        }

        break;
    }
  }
  handleClick(event) {
    switch (event.from) {
      case ActionMode.create:
      case ActionMode.copy:
        if (event.formValues.default) {
          this.validateDefaultTemplate(event.formValues);
          if (this.isDefaultTemplateExist) {
            this._templateServiceCommunication.showConfirmationPopup(this.showConfirmationSuccessCallback.bind(this, event.formValues, ActionMode.create), this.showConfirmationErrorCallback.bind(this, event.formValues, ActionMode.create));
          }
          else {
            this.createLetter(ActionMode.create, event.formValues);
          }
        }
        else {
          this.createLetter(ActionMode.create, event.formValues);
        }

        break;
      case ActionMode.update:
        if (event.formValues.default) {
          this.validateDefaultTemplate(event.formValues);
          if (this.isDefaultTemplateExist) {
            this._templateServiceCommunication.showConfirmationPopup(this.showConfirmationSuccessCallback.bind(this, event.formValues, ActionMode.update), this.showConfirmationErrorCallback.bind(this, event.formValues, ActionMode.update));
          }
          else {
            this.createLetter(ActionMode.update, event.formValues);
          }

        }
        else {
          this.createLetter(ActionMode.update, event.formValues);
        }


        break;
      case ActionMode.cancel:
        this.back(event);
        break;
    }

  }
  getListOrder(data) {
    if (data && data.length) {
      return Math.max.apply(Math, data.map(x => x.listOrder)) + 1;
    } else {
      return 1;
    }
  }
  formReady(event) {
    this.createForm = event;
  }

  createLetter(iscreate: ActionMode, template: UI.Template) {
    if (iscreate === ActionMode.create) {
      if (template.templateData) {
        this.settingsLettersBusiness.create(template).then(x => {
          this.getTableContent(this.createInputs.letterType);
          this.backEmit();
        });
      }
      else {
         this.utilities.showError(this.captions.templateEmptyErrorMessage);
      }
    } else {
      this.settingsLettersBusiness.update(template).then(x => {
        this.getTableContent(this.createInputs.letterType);
        this.backEmit();
      });
    }
  }

  updateEvent(formData: UI.Template) {
    this.letterCreationToggler = true;
    this.IsEditModeEnabledFlag = true;
    this.createModalData = {
      title: this.createInputs.editTitle
    };

    this.crudActionInput = {
      mode: ActionMode.update,
      actionButton: this.captions.btn_update,
      form: formData,
      listOrder: formData.listOrder,
      isViewOnly: this.isViewOnly || formData.default
    };
  }

  getTableContent(type) {
     this.settingsLettersBusiness.getTemplates(type, this.showInactives)
      .then(value => {
        this.options ? this.options[0].TablebodyData = value : null;
        this.backupOriginalContent = value;
        this.refreshGrid();
      });
  }
  refreshGrid() {
    //this.utilities.ToggleLoader(this.settingsSharedService.enableLoader);
     if (this.showInactives) {
      this.options ? this.options[0].TablebodyData = [...this.backupOriginalContent.map(x => x)]: null;
    } else {
      this.options ? this.options[0].TablebodyData = [...this.backupOriginalContent.filter(t => t.active)]: null;
    }
    //  this.tableContentSubscription = this.tableContent.subscribe(x => {
    //    this.utilities.ToggleLoader(false);
    //  });
    if(this.options)
    this.options= [...this.options];
     if (this.tableContentSubscription) {
       this.tableContentSubscription.unsubscribe();
     }
  }

  async validateUserAccess() {
    const brekPointNumber = this.settingsLettersBusiness.getBreakpointNumber(this.createInputs.letterType);
    const userAccess = this.facadeService.getUserAccess(brekPointNumber, true);
    userAccess.then(value => {
      this.isViewOnly = value.isViewOnly;
      this.options = this.landingInputs.tableOptions;
      this.options[0].IsViewOnly=this.isViewOnly;
      this.searchHeaderOption.createBtnDisabled = this.isViewOnly;
      this.searchHeaderOption = { ...this.searchHeaderOption };
    });
  }
  validateDefaultTemplate(event) {
    this.isDefaultTemplateExist = false;
       if (this.backupOriginalContent.length > 0) {
        this.backupOriginalContent.forEach(x => {
           if (x.id != event.id && x.isInActive) {
             this.isDefaultTemplateExist = true;
           }
         });

       }
       else {
         this.isDefaultTemplateExist = false;
       }

  }
  async showConfirmationSuccessCallback(res, mode) {
    this.createLetter(mode, res);
  }
  async showConfirmationErrorCallback(res) {


  }
}
