<div class="reservation">
  <div class="reservation__table-structure" id="table_structure">
    <div class="reservation__row-header">
      <div class="reservation__row-filters">
        <span [hidden]="!IsCurrentDate">
          <dynamic-form [config]="config" #form="dynamicForm">
          </dynamic-form>
        </span>
        <div class="reservation__sort-by">
        <label class="reservation__sort-by-label seat-tertiary-text">{{'sortByText' | translate}}</label>
        <dynamic-form [config]="sortByConfig" #form="dynamicForm">
        </dynamic-form>
        </div>
        <div class="reservation__search-by-view">
        <dynamic-form [config]="searchConfig" #searchConfigForm>
        </dynamic-form>
      </div>
        <div class="display_filter">
          <a [ngbPopover]="myPopover" placement="bottom" [autoClose]="'outside'">
            <span class="icon-Filter"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
          </a>
          <ng-template #myPopover>
            <app-filter-content [displayOptions]="displayOptions" [isMessageAvailable]="true" (optionSelection)="propertySelectionChanged($event)"></app-filter-content>
          </ng-template>
        </div>
        <div class="display_filter p-0">
          <a [ngbPopover]="settingsPopover" placement="bottom" [autoClose]="'outside'"  popoverClass="settings-popover">
            <span class="icon-settings settings-menu"></span>
          </a>
          <ng-template #settingsPopover>
            <app-filter-content [displayOptions]="settingOptions" [isMessageAvailable]="false" (optionSelection)="applySetting($event)"></app-filter-content>
          </ng-template>
        </div>
      </div>
      <div class="reservation__activities">
        <a class="reservation__menu-icon rectangular-icon icon-printChit"
          (click)="printChit(ContentView.print); tooltip.toggle()"
          #tooltip="matTooltip" matTooltipClass="tooltip-class" matTooltip="{{'printChit' | translate}}">
        </a>
        <a class="icon-multi-paging_lg reservation__menu-icon rectangular-icon"
          (click)="multiPaging(); tooltip.toggle()" [ngClass]="{'disablefill icon-multi-paging_lg_disable': isPastDate }"
          #tooltip="matTooltip" matTooltipClass="tooltip-class" matTooltip="{{'multiplePaging' | translate}}"></a>
        <a [ngClass]="{reservation__actives: contentView == ContentView.grid}"
          class=" icon-Group-572 reservation__menu-icon rectangular-icon" (click)="changeView(ContentView.grid)"
          #tooltip="matTooltip" matTooltipClass="tooltip-class" matTooltip="{{'gridView' | translate}}"></a>
        <a [ngClass]="{reservation__actives: contentView == ContentView.list}"
          class="reservation__menu-icon rectangular-icon icon-Group-573" (click)="changeView(ContentView.list)"
          #tooltip="matTooltip" matTooltipClass="tooltip-class" matTooltip="{{'listView' | translate}}">
        </a>
      </div>
    </div>
    <div class="reservation__row-section">
      <ng-scrollbar class="reservation-scrollbar">
        <div class="reservation__breakfast reservation__shift">
          <div *ngIf="viewBy == reservationsViewBy.Today && (partyService.Parties$ | async | onlyReservation)?.length > 0">
            <mat-accordion multi #shiftAccordian>
              <div *ngFor="let shift of partyService.shifts$ | async;let i = index;trackBy:trackByFn">
                <div *ngIf="!showByPartySize">
                  <mat-expansion-panel [expanded]="expandAll || (shift.EffectiveRange | expandPanelWithShift : generalSettings.General.DaylightDelta) || (partyService.shifts$ | async)?.length == 1"
                    (opened)="shift.panelOpenState = true" (closed)="shift.panelOpenState = false">

                    <mat-expansion-panel-header class="shift-mat-expansion-panel-header" [collapsedHeight]="'54px'"
                      [expandedHeight]="'64px'">
                      <div class="reservation__list-padding">
                        <span class="reservation__shift-header">
                          <span class="reservation__food_time seat-section-header" id="food_time-{{shift.Name}}">{{shift.Name}}</span>
                          <!-- <label>{{tableAvailability | showTableAvailability:shift.Id }}</label> -->
                          <i [ngClass]="shift.panelOpenState?'icon-Path-507':'icon-left-arrow1'"
                            class="reservation__expansion-icon seat-icon-color"></i>
                          <div class="reservation__expansion-line seat-border-color" id="divider-{{shift.Name}}"></div>
                          <a class="icon-icon reservation__icon-table" title="{{'tableView' | translate}}" (click)="navigateToTablesOrTimeline(0, shift)"></a>
                          <a class="icon-Mask-Group-9 reservation__icon-timeline" title="{{'timelineView' | translate}}" (click)="navigateToTablesOrTimeline(1, shift)"></a>
                        </span>
                      </div>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                      <div class="reservation__panel-body" *ngIf="contentView == ContentView.grid && shift.panelOpenState">
                        <app-partiesgrid style="width: 100%;"
                          [generalSettings]="generalSettings"
                          [panelState]="shift.panelOpenState"
                          [showCreatedDate] = "showCreatedDate"
                          [parties]="partyService.Parties$ | async | onlyReservation | effectiveRangeParties: shift.EffectiveRange:ContentView.list | sortReservations : showByPartySize : ReservationType.Reservation : generalSettings.General.DaylightDelta | partyStatusFilterPipe:isNoShowSelected:isSeatedSelected:isReservedSelected:isClearedSelected:partyRefreshing:isCancelledSelected|headerSeatingAreaFilter:partyService.currentSeatingAreaId | partyDataFilter: searchString"
                          [type]="ReservationType.Reservation" [index]="i" [isNoShowSelected]="isNoShowSelected"></app-partiesgrid>
                      </div>
                      <div *ngIf="contentView == ContentView.list && shift.panelOpenState">
                        <app-partieslist style="width: 100%;"
                        [generalSettings]="generalSettings"  
                        [panelState]="shift.panelOpenState"
                          [showCreatedDate] = "showCreatedDate"
                          [parties]="partyService.Parties$ | async | onlyReservation | effectiveRangeParties: shift.EffectiveRange:ContentView.list | sortReservations :showByPartySize : ReservationType.Reservation : generalSettings.General.DaylightDelta | partyStatusFilterPipe:isNoShowSelected:isSeatedSelected:isReservedSelected:isClearedSelected:partyRefreshing:isCancelledSelected|headerSeatingAreaFilter:partyService.currentSeatingAreaId | partyDataFilter: searchString"
                          [type]="ReservationType.Reservation" [index]="i" [isNoShowSelected]="isNoShowSelected">
                        </app-partieslist>
                      </div>
                    </ng-template>
                  </mat-expansion-panel>
                </div>
              </div>
            </mat-accordion>
          </div>
          <div *ngIf="viewBy == reservationsViewBy.Week && (partyService.Parties$ | async | onlyReservation )?.length > 0">
            <mat-accordion>
              <div *ngFor="let party of this.parties; let i = index;">
                <div *ngIf="!showByPartySize">
                  <mat-expansion-panel [expanded]="party.Shift.EffectiveRange | expandPanelWithShift : generalSettings.General.DaylightDelta"
                    (opened)="party.panelOpenState = true" (closed)="party.panelOpenState = false">
                    <mat-expansion-panel-header class="shift-mat-expansion-panel-header" [collapsedHeight]="'54px'"
                      [expandedHeight]="'64px'">
                      <div class="reservation__list-padding">
                        <span class="reservation__shift-header">
                          <span class="reservation__food_time">{{party.Shift.Name}}</span>
                          <i [ngClass]="party.panelOpenState?'icon-Path-507':'icon-left-arrow1'"
                            class="reservation__expansion-icon"></i>
                          <div class="reservation__expansion-line"></div>
                        </span>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="reservation__panel-body" *ngIf="contentView == ContentView.grid && party.panelOpenState">
                      <app-partiesgrid style="width: 100%;"
                        [generalSettings]="generalSettings"
                        [panelState]="party.panelOpenState"
                        [showCreatedDate] = "showCreatedDate"
                        [parties]="partyService.Parties$ | async| onlyReservation | effectiveRangeParties: party.Shift.EffectiveRange:ContentView.list | sortReservations : showByPartySize : ReservationType.Reservation : generalSettings.General.DaylightDelta | partyStatusFilterPipe:isNoShowSelected:isSeatedSelected:isReservedSelected:isClearedSelected:isCancelledSelected:partyRefreshing:isCancelledSelected|headerSeatingAreaFilter:partyService.currentSeatingAreaId | partyDataFilter: searchString"
                        [index]="i" [type]="ReservationType.Reservation" [isNoShowSelected]="isNoShowSelected"></app-partiesgrid>
                    </div>
                    <div *ngIf="contentView == ContentView.list && party.panelOpenState">
                      <div *ngIf="(partyService.Parties$ | async | onlyReservation)?.length > 0">
                        <app-partieslist style="width: 100%;"
                          [panelState]="party.panelOpenState"
                          [showCreatedDate] = "showCreatedDate"
                          [parties]="partyService.Parties$ | async| onlyReservation | effectiveRangeParties: party.Shift.EffectiveRange:ContentView.list | sortReservations : showByPartySize : ReservationType.Reservation : generalSettings.General.DaylightDelta | partyStatusFilterPipe:isNoShowSelected:isSeatedSelected:isReservedSelected:isClearedSelected:isCancelledSelected:partyRefreshing:isCancelledSelected|headerSeatingAreaFilter:partyService.currentSeatingAreaId | partyDataFilter: searchString"
                          [type]="ReservationType.Reservation" [index]="i">
                        </app-partieslist>
                      </div>
                      <div class="reservation__no-reservations" *ngIf="(partyService.Parties$ | async|onlyReservation)?.length == 0">
                        <img src="{{reservationUrl}}" alt="" class="reservation__no-reservations-img">
                        <span class="reservation__no-reservations-text">{{'noReservationsFound' | translate}}</span>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </div>
              </div>
            </mat-accordion>
          </div>
          <div class="reservation__by-party" *ngIf="showByPartySize">
            <div class="reservation__by-party-grid"
              *ngIf="contentView == ContentView.grid && (partyService.Parties$ | async | onlyReservation)?.length > 0">
              <app-partiesgrid style="width: 100%;" (actionEvent)="create($event)" [index]="0"
                [panelState]="true"
                [generalSettings]="generalSettings"
                [showCreatedDate] = "showCreatedDate"
                [parties]="partyService.Parties$ | async |onlyReservation| effectiveRangeParties: headerDateRange : ContentView.list | sortReservations : showByPartySize : ReservationType.Reservation : generalSettings.General.DaylightDelta | partyStatusFilterPipe:isNoShowSelected:isSeatedSelected:isReservedSelected:isClearedSelected:partyRefreshing:isCancelledSelected|headerSeatingAreaFilter:partyService.currentSeatingAreaId | partyDataFilter: searchString"
                [type]="ReservationType.Reservation" [isNoShowSelected]="isNoShowSelected">
              </app-partiesgrid>
            </div>
            <div class="reservation__by-party-list"
              *ngIf="contentView == ContentView.list && (partyService.Parties$ | async | onlyReservation)?.length > 0">
              <app-partieslist style="width: 100%;"
                [panelState]="true"
                [showCreatedDate] = "showCreatedDate"
                [parties]="partyService.Parties$ | async | onlyReservation | effectiveRangeParties: headerDateRange : ContentView.list | sortReservations : showByPartySize : ReservationType.Reservation : generalSettings.General.DaylightDelta | partyStatusFilterPipe:isNoShowSelected:isSeatedSelected:isReservedSelected:isClearedSelected:partyRefreshing:isCancelledSelected|headerSeatingAreaFilter:partyService.currentSeatingAreaId | partyDataFilter: searchString"
                [index]="0" [type]="ReservationType.Reservation">
              </app-partieslist>
            </div>
            <div *ngIf="(partyService.Parties$ |  async | onlyReservation)?.length == 0" class="reservation__empty-reservations seat-no-content-found">
              <img src="{{partyService.noReservationImgUrl}}" alt="">
              <span class="reservation__empty-reservations-text">{{'noReservationsFound' | translate}}</span>
            </div>
          </div>
        </div>
        <div *ngIf="(partyService.Parties$ | async | onlyReservation)?.length == 0" class="reservation__empty-reservations seat-no-content-found">
          <img src="{{partyService.noReservationImgUrl}}" alt="">
          <span class="reservation__empty-reservations-text">{{'noReservationsFound' | translate}}</span>
        </div>
      </ng-scrollbar>
    </div>

    <!-- <div *ngIf="_as.showLegends" class="reservation__display-legends">
      <app-display-legend [legends]="displayLegends"></app-display-legend>
    </div> -->
  </div>
  <div class="reservation__party-dashboard">
    <app-dashboard [type]="ReservationType.Reservation" [dashboardData]="dashboardData" (actions)="actions($event)"
      (createReservation)="create($event)"></app-dashboard>
  </div>
</div>

<app-print-container [selectedParties]="partyService.chitPrintPartyData" ></app-print-container>
