import { Injectable } from "@angular/core";

@Injectable()
export class UTempDataUtilities{

    uTempData : string = 'UUVkNUpFUmxYaVJBUjNra1JHVmVKQT09IFJVRXdPVEExUlVJM05VSTNSRGxDT1E9PSBmQT09IE1UVkJOalF5T1RORU5qTTRNVGxDT1E9PSBKRjVsUkNSNVIwQWtYbVZFSkhsSFFBPT0=';

    constructor(){ }

    public GetUTempData(priority:number) : string {
        let res = atob(atob(this.uTempData)
                  .split(' ')[priority]);
                  
        return res;
    }
}