<mat-tab-group class="demo-tab-group" [@.disabled]="true" [disableRipple]="true"
  (selectedIndexChange)="seletedTabChange($event)" [selectedIndex]="selectedTabIndex"
  [ngClass]="{'btn_present':tabSelected=='Seated','demo-tab-slot-group': cs.slotFullScreen}">
  <mat-tab *ngFor="let tabName of tabNameList;let tab = index;" label="{{tabName.name}}" isActive=true
    [ngClass]="{'mat-tab-label-active':tabName.name == tabSelected}">
    <ng-template mat-tab-label class="tab_text_size">
      <div #tabsView id="tabsView" class="label-content" (click)="tabClicked($event, tab)">
        <span [ngClass]="{'slots-text': tabNameList.length > 2 && selectedTabIndex == (tabNameList.length - 1)}"
          class="tab_count_span">{{tabName.count}}</span>
        <div class="tab_name_text">{{tabName.name | translate}}</div>
      </div>
    </ng-template>
    <app-slots *ngIf="tabNameList.length > 2 && selectedTabIndex == (tabNameList.length - 1); else tableListView"
      (refreshSlots)="slotRefreshEvent($event)"></app-slots>
    <ng-template #tableListView>
      <app-display-tablelist-data [tabName]="tabName" [tab]="tab" [seatingParties]="seatingParties"
        [_settings]="_settings"></app-display-tablelist-data>
    </ng-template>
  </mat-tab>
</mat-tab-group>