<section [formGroup]="ItemFormGrp" autocomplete="off">
    
      <app-retail-multi-select-dropdown *ngIf="clerks" [dropDownFilterData] = "clerks" 
      [dropDownControlname] = "'clerks'" [dropDownName] = captions.Vendor (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
     
      <app-retail-category-component (formReady)="formInitialized($event)"  [fromJobSchedulerEdit] = "isFromEdit" [editValues] = "categoryComponentFilter" 
      [categoryReportGroup]="categoryGroupSelected"> </app-retail-category-component>
      
      <div class="mb-3">
        <label class="LW14">{{captions.NewPageAfterEachOutlet}}</label>
        <app-retail-toggle-switch class="d-block" (changeToggleEvent)="changeToggleEvent($event[0])" [formControl]="IsNewPageAfterEachOutlet"></app-retail-toggle-switch>
      </div>    
      <div class="mb-3">
        <label class="LW14">{{captions.DetailView}}</label>
        <app-retail-toggle-switch class="d-block" (changeToggleEvent)="detailToggleView($event[0])" [formControl]="IsDetailView"></app-retail-toggle-switch>
      </div>
</section>
