import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OperationResultDTO } from '@models/ChangeTrackingOperationResultDTO';
import { MissingPermissionDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@services/settings.service';

@Component({
  selector: 'app-explain-user-permissions',
  templateUrl: './explain-user-permissions.component.html',
  styleUrls: ['./explain-user-permissions.component.scss']
})
export class ExplainUserPermissionsComponent implements OnInit {
  @Input() data: OperationResultDTO;
  viewModels: MissingPermissionDTO[] = [];
  tableDataSource: MatTableDataSource<MissingPermissionDTO>;
  tableColumns: string[] = ['Name', 'AvailabilityText'];
  managerPINRequestMsg: string;
  requiredPermission: string;
  isGrantedToUser: string;
  constructor(public ss: SettingsService, public translateService: TranslateService) { }

  ngOnInit() {
    this.managerPINRequestMsg = this.translateService.instant('managerPINMessage');
    this.requiredPermission = this.translateService.instant('requiredPermission');
    this.isGrantedToUser = this.translateService.instant('isGrantedToUser');
    this.ss.setPermissionTypeHelper();
    this.setPermissionList();
  }

  setPermissionList() {

    const noText: string = this.translateService.instant('notext');
    const yesText: string = this.translateService.instant('yestext');

    for (const required of this.data.RequiredPermissions) {
      if (this.ss.permissionTypeHelper.filter(x => x.Id == required).length > 0) {
        const viewModel = new MissingPermissionDTO(this.ss.permissionTypeHelper.filter(x => x.Id == required)[0].Name, false, yesText);
        if (this.data.MissingPermissions.indexOf(required) != -1) {
          viewModel.IsNotAvailable = true;
          viewModel.AvailabilityText = noText;
        }
        this.viewModels.push(viewModel);
      }
    }
    this.tableDataSource = new MatTableDataSource(this.viewModels);
  }
}
