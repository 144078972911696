import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { dateNavigation } from '@constants/globalConstants';
import { CalendarViewType } from '@app/shared/constants/commonenums';
import { DatesOverviewDTO } from '@app/shared/models/DatesOverviewDTO';
import { TimeRangeDTO } from "@app/shared/models/TimeRangeDTO";
import { PartyService } from "@app/shared/services/party.service";
import { Subscription } from 'rxjs';
import moment from 'moment';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit,OnDestroy {
  @Input('currentDate') headerDate;
  @Output() selectDate = new EventEmitter();
  weekDays = ['weekDay0', 'weekDay1', 'weekDay2', 'weekDay3', 'weekDay4', 'weekDay5', 'weekDay6'];
  calendarDays: any = [];
  selectedData: string;
  date:number;
  month: number;
  year: number;
  currentViewMode = CalendarViewType.Days;
  CalendarViewType = CalendarViewType;
  monthArray: any = [];
  months: any;
  years: any;
  subscription:Subscription = new Subscription();
  calendarItems: DatesOverviewDTO[]  = [];
  isShowToday: boolean = false;

  constructor(private partyService: PartyService,private dashboardFunction: DashboardFunctions) { }

  ngOnInit(): void {
    this.headerDate = new Date(this.headerDate);
    this.getAllMonths();
    this.getCurrentDate(this.headerDate);
  }
  
  getAllMonths() {
    this.monthArray = moment.localeData(this.dashboardFunction.getLocaleCode()).months();
    this.months = this.monthArray?.map(month => {
      return {
        isSelected: false,
        months: month
      }
    });
  }

  getCalendarDatesOverview(start,end) {
    const timerange: TimeRangeDTO = {} as TimeRangeDTO;
    timerange.Start = start;
    timerange.End = end;
    this.subscription.add(this.partyService.getDatesOverview(timerange).subscribe((data) => {
         this.calendarItems = (data?.Payload) ? data?.Payload as DatesOverviewDTO[] : [];
        this.calendarItems?.map((data,index) => {
          let overviewDate = new Date(data.Date);
          if(this.calendarDays?.length && this.calendarDays[index].date == overviewDate.getDate() && this.calendarDays[index].month == overviewDate.getMonth() && this.calendarDays[index].year == overviewDate.getFullYear()) {
            this.calendarDays[index].overviewData.push(data);
          }
          if(this.calendarDays?.length && this.calendarDays[index].isSelected) {
            this.selectDate.emit(this.calendarDays[index]);
          }
        })
    }));
  }

  getCurrentDate(date) {
    this.date = date.getDate();
    this.month = date.getMonth();
    this.year = date.getFullYear();
    if(this.headerDate.getMonth() != this.month || this.headerDate.getFullYear() != this.year) {
      this.isShowToday = true;
    }
    else {
      this.isShowToday = false;
    }
    this.selectedData = this.monthArray[this.month] + ", " + this.year;
    this.getDaysArray(this.month,this.year);
    if(this.calendarDays?.length) {
    var firstDay = new Date(this.calendarDays[0].year, this.calendarDays[0].month, this.calendarDays[0].date);
    var lastDay = new Date(this.calendarDays[this.calendarDays?.length - 1].year,this.calendarDays[this.calendarDays?.length - 1].month,this.calendarDays[this.calendarDays?.length - 1].date);
    this.getCalendarDatesOverview(firstDay,lastDay);
    }
  }

  getDaysArray(monthIndex,year){
    this.calendarDays = this.getAllDays(monthIndex,year);
    this.calendarDays?.map(days => {
      if(days.date == this.headerDate.getDate() && days.month == this.headerDate.getMonth() && days.year == this.headerDate.getFullYear()) {
        days.isSelected = true;
      }
    })
    if(this.calendarDays?.length) {
      let firstDayIndex = this.weekDays.findIndex(days => days == this.calendarDays[0].day);
      let lastDayIndex = this.weekDays.findIndex(days => days == this.calendarDays[this.calendarDays?.length -1].day);
      if(firstDayIndex > 0) {
        if(monthIndex == 0) {
          this.getPreviousDays(11,year - 1,firstDayIndex);
        }
        else {
          this.getPreviousDays(monthIndex-1,year,firstDayIndex);
        }
      }
      if(lastDayIndex < this.weekDays.length) {
        let nextValue = (this.weekDays.length - 1) - lastDayIndex;
        if(monthIndex == 11) {
          this.getNextDays(0,year+1,nextValue);
        }
        else {
          this.getNextDays(monthIndex+1,year,nextValue)
        }
      }
    }
  }

  getPreviousDays(month,year,previousDays) {
    let previous = this.getAllDays(month,year); 
    previous = previous?.slice(previous?.length - previousDays);
    this.calendarDays = [...previous,...this.calendarDays];
  }

  getNextDays(month,year,nextDays) {
    let next = this.getAllDays(month,year);
    next = next?.slice(0,nextDays)
    this.calendarDays = this.calendarDays.concat(next);
  }

  getAllDays(monthIndex,year) {
    var date = new Date(year, monthIndex, 1);
    var result = [];
    while (date.getMonth() == monthIndex) {
      let dataObj = {
        date: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
        isSelected: false,
        day: this.weekDays[date.getDay()],
        overviewData: []
      }
      result.push(dataObj);
      date.setDate(date.getDate() + 1);
    }
    return result;
  }

  getPreviousMonth() {
    if(this.month == 0) {
      this.month = this.months?.length - 1;
      this.year--;
      this.getCurrentDate(new Date(this.year,this.month,1))
    }
    else {
      this.month--;
      this.getCurrentDate(new Date(this.year,this.month,1))
    }
  }

  getNextMonth() {
    if(this.month == this.months?.length - 1) {
      this.month = 0;
      this.year++;
      this.getCurrentDate(new Date(this.year,this.month,1))
    }
    else {
      this.month++;
      this.getCurrentDate(new Date(this.year,this.month,1))
    }
  }

  setSelectedDate(dataObj,viewMode) {
    switch (viewMode) {
      case CalendarViewType.Days:
        {
          this.calendarDays?.map(days => {
            if(days.date == dataObj.date && days.month == dataObj.month && dataObj.month == this.month && days.year == dataObj.year) {
              days.isSelected = true;
            }
            else if(dataObj.month != this.month) {
              this.getCurrentDate(new Date(dataObj.year, dataObj.month, dataObj.date));
              this.calendarDays?.map(days => {
                if(days.date ==dataObj.date) {
                  days.isSelected = true;
                }
                else {
                  days.isSelected = false;
                }
              })
            }
            else {
              days.isSelected = false;
            }
          });
          this.selectDate.emit(dataObj);
        }
        break;
      case CalendarViewType.Months:
        {
          this.months?.map(data => {
            if(data.months == dataObj.months) {
              data.isSelected = true;
            }
            else {
              data.isSelected = false;
            }
          })
          let monthIndex = this.months?.findIndex(data => data.isSelected);
          this.getCurrentDate(new Date(this.year, monthIndex, 1));
          this.months?.map(data => data.isSelected = false);
          this.currentViewMode = CalendarViewType.Days;
        }
        break;
      case CalendarViewType.Years:
        {
          this.years?.map(data => {
            if(data.years == dataObj.years) {
              data.isSelected = true;
            }
            else {
              data.isSelected = false;
            }
          });
          this.year = dataObj.years;
          this.selectedData = this.year.toString();
          this.currentViewMode = CalendarViewType.Months;
        }
        break;
    }
  }

  changeViewMode(mode) {
    switch (mode) {
      case CalendarViewType.Days:
        {
        this.currentViewMode = CalendarViewType.Months;
        this.months.map(data =>{
          if(this.monthArray[this.month] == data.months) {
            data.isSelected = true;
          }
        })
        this.selectedData = this.year.toString();
      }
        break;
      case CalendarViewType.Months:
        {
        this.currentViewMode = CalendarViewType.Years;
        this.getCurrentDecadeYears(new Date(this.year,this.month,this.date));
      }
        break;
    }
  }

  navigateCalendar(mode,navigateState) {
    switch (mode) {
      case CalendarViewType.Days:
        {
          if(navigateState == dateNavigation.previous) {
            this.getPreviousMonth();
          }
          else {
            this.getNextMonth();
          }
        }
        break;
      case CalendarViewType.Months:
        {
          if(navigateState == dateNavigation.previous) {
            this.year--;
            this.selectedData = this.year.toString();
          }
          else {
            this.year++;
            this.selectedData = this.year.toString();
          }
        }
        break;
      case CalendarViewType.Years:
        {
          if(navigateState == dateNavigation.previous) {
            this.getPreviousDecadeYears();
          }
          else {
            this.getNextDecadeYears();
          }
        }
        break;
      
    }
    
  }

  getCurrentDecadeYears(now) {
    const round = (n, to) => n - n % to;
    const start = new Date(round(now.getFullYear(), 10), 0, 1);
    const end = new Date(round(now.getFullYear(), 10) + 10, 0, 1);
    end.setDate(end.getDate() - 1);
    console.log(`${start}\n${end}`);
    this.selectedData = start.getFullYear() + "-" + end.getFullYear();
    var result = [];
    for(var i=start.getFullYear(); i<=end.getFullYear();i++) {
      result.push(i);
    }
    if(result?.length) {
      let startYear = result[0] - 1;
      let endYear = result[result.length - 1] + 1;
      result = [startYear].concat(result);
      result.push(endYear);
    }
    this.years = result?.map(year => {
      return {
        years: year,
        isSelected: false
      }
    });
    this.years?.map(data => {
      if(data.years == this.year) {
        data.isSelected = true;
      }
    });
  }

  getPreviousDecadeYears() {
    if(this.years?.length)
    this.getCurrentDecadeYears(new Date(this.years[0].years,this.month,this.date));
  }

  getNextDecadeYears() {
    if(this.years?.length)
    this.getCurrentDecadeYears(new Date(this.years[this.years?.length - 1].years,this.month,this.date));
  }

  ngOnDestroy(): void {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }
}
