  <div class="party-list">
  <div class="party-list__list-view" *ngIf="parties?.length > 0">
    <div class="party-list__row-seatline-register" id="partyTable">
      <!-- <ng-scrollbar track="all"> -->
      <div id="parties-list-scroll-{{index}}" class="party-list__scroll-block">
        <virtual-scroller #scroll [items]="parties" id="parties-list-{{index}}">
          <table class="table party-list__table-data" id="table-list">
            <thead #header>
              <tr class="seat-grid-header">
                <th class="party-list__table-header" scope="col"></th>
                <th class="party-list__table-header" *ngIf="type == ReservationType.Reservation">
                  {{'reservationTimeText' | translate}}
                </th>
                <th class="party-list__table-header" *ngIf="type == ReservationType.Waitlist">{{'arraivedTimeText' | translate}}
                </th>
                <th class="party-list__table-header " scope="col">{{'tableNoText' | translate}}</th>
                <!-- <th class="party-list__table-header " scope="col" *ngIf="((type != ReservationType.Waitlist)&&(hasRoomNUmberSearch == true))">
                  {{'roomNoText' | translate}}</th> -->
                <th class="party-list__table-header" scope="col">{{'statusText' | translate}}</th>
                <th class="party-list__table-header" scope="col">{{'guestNameText' | translate}}</th>
                <th class="party-list__table-header" scope="col" *ngIf="((type != ReservationType.Waitlist)&&(hasRoomNUmberSearch == true))">{{'StayInfo' | translate}}</th>
                <th class="party-list__table-header" scope="col">{{'contactText' | translate}}</th>
                <th *ngIf="type == ReservationType.Reservation && showCreatedDate" class="party-list__table-header" scope="col">{{'createdAt' | translate}}</th>
                <th class="party-list__table-header" scope="col">{{'coversText' | translate}}</th>
                <th class="party-list__table-header" scope="col">{{'guestNotesText' | translate}}</th>
                <th class="party-list__table-header" scope="col">{{'reservationNotes' | translate}}</th>
                <th class="party-list__table-header" scope="col">{{'addons' | translate}}</th>
                <th class="party-list__table-header" scope="col">{{'pagePreference' | translate}}</th>
                <th class="party-list__table-header party-list__action-text" scope="col">{{'actionText' | translate}}</th>
              </tr>
            </thead>
            <tbody class="party-list__tbody" #container>
              <tr *ngFor="let party of scroll.viewPortItems;let i=index" class="party-{{party.Id}} party-list__row  seat-grid-content"
                (click)="selectParty(party, $event)"
                [ngClass]="{'seat-primary-bg-color' : selectedParty && (party.Id == selectedParty.Id)}">
                <td *ngIf="party | PartySourceIconPipe as IconInfo"> 
                  <span class="icon-class {{IconInfo.iconclass}}" [matTooltip]="IconInfo.Name | translate"></span>
                </td>
                <td *ngIf="type == ReservationType.Reservation" class="party-list__reserved">
                  {{party.ReservedFor | localizedDate: 'LT'}} / {{party.ReservedFor | localizedDate: 'D MMM, ddd'}}</td>
                <td class="party-list__arrived" *ngIf="type == ReservationType.Waitlist">{{party.ArrivedAt | localizedDate: 'LT'}} /
                  {{party.ArrivedAt | localizedDate: 'D MMM, ddd'}}</td>
                <td class="party-list__table-name" matTooltipClass="tooltip-class"
                  title="{{party.TableIds | formattedTableName}}">
                  {{party.TableIds | formattedTableName}}
                </td>
                <!-- <td class="party-list__room-number" *ngIf="((type != ReservationType.Waitlist)&&(hasRoomNUmberSearch == true))">
                  {{party.RoomNumber != null ? party.RoomNumber : 'NA'}}</td> -->
                <td *ngIf="type == ReservationType.Reservation" class="party-list__reserved-status">
                  {{party.State | reservationStatus: 'status' |translate}}
                </td>
                <td *ngIf="type == ReservationType.Waitlist" class="party-list__reserved-status">
                  {{party.State | reservationStatus: 'status' |translate}}</td>
                <td class="party-list__party-name" matTooltipClass="tooltip-class"
                  title="{{party.Contact | formattedName}}">
                  <div>{{party.Contact | formattedName}}  <span *ngIf="_as.OTASourceId.includes(party.PartySourceId)" class="party-ota-icon"></span></div>
                  <div class="alias-name" *ngIf="party?.Contact?.AliasName"> {{'guestBookDetailsAliasName' | translate }}: {{party?.Contact?.AliasName}}</div>
                </td>
                
                <td class="party-list__stayInfo"  *ngIf="((type != ReservationType.Waitlist)&&(hasRoomNUmberSearch == true))">
                  <div style="cursor: pointer;" [autoClose]="'outside'" container="body" [ngbPopover]="party.CommonPackageInfo?.length > 5 && packageInformation" placement="bottom" (click)="dashboardFunctions.openPackage(party)">
                    <p [class.hasPackage]="party.CommonPackageInfo?.length > 5">
                      <ng-container *ngIf="party.RoomNumber; else noRoom">
                        {{'RoomNo' | translate}} : {{ party.RoomNumber || ''}}
                      </ng-container>
                      <ng-template #noRoom>
                        {{'NA' | translate}}
                      </ng-template>
                    <!-- <u *ngIf="party.CommonPackageInfo && party.CommonPackageInfo.length > 5" >
                      {{'RoomNo' | translate}} : {{ party.RoomNumber }}
                    </u> -->
                  </div>
                  
                </td>

                  <td class="party-list__contact-phone">{{party?.Contact?.PhoneNumber | phoneNumberFormatter: party?.Contact?.CountryId}}
                </td>
                <td *ngIf="type == ReservationType.Reservation && showCreatedDate" class="party-list__reserved">
                  {{party.CreatedAt | localizedDate: 'LT'}} / {{party.CreatedAt | localizedDate: 'D MMM, ddd'}}</td>
                <td class="party-list__party-size"><span class="class-status" [ngStyle]="{'background-color':(party | partyStatusColor : _settings : party.StatusId ),'color': (party | partyStatusColor : _settings : party.StatusId ) ? 'white':'inherit'}"#partyStatusPopup="ngbPopover" popoverClass="party-status-popover"
                  (click)="triggerPartyStatusPopup(partyStatusPopup,party)"
                  triggers="manual" [ngbPopover]="partyStatus" placement="auto" [autoClose]="'outside'" container="body">{{party.Size}}</span></td>
                <td class="party-list__contact-notes">
                  <!--Todo-->
                  <span class="parties-list__contact-notes-spacing" *ngFor="let item of party?.Contact?.Notes;let i= index">
                    <i (click)="tooltip.toggle()" #tooltip="matTooltip" matTooltipClass="tooltip-class" matTooltip="{{item.Text}}"
                      *ngIf="i < 4" class="icon-{{item.Icon ? item.Icon : 'Default'}} seat-icon-color rs-pr-3"></i>
                  </span>
                  <span class="parties-list__horizontal-dots-icon seat-triple-dots" [autoClose]="'outside'"
                    *ngIf="party?.Contact?.Notes?.length > 4" container="body" [ngbPopover]="GuestNotesPopover"
                    popoverClass="guest-notes-scroll" placement="auto" (click)="mapGuestNotes(party?.Contact?.Notes)">
                    <span class="icon-Repeat-Grid-39"></span>
                  </span>
                  <span *ngIf="party?.Contact?.Notes?.length == 0">
                    -
                  </span>
                </td>
                <td class="party-list__party-notes">
                  <span class="icon-exclamation rs-fs-24" *ngIf="party?.SpecialMealId || party?.Notes?.length > 0"
                    [autoClose]="'outside'" container="body" placement="auto" [ngbPopover]="partyNotesPopover"
                    popoverClass="reservation-notes-popover"
                    (click)="mapPartyNotes(party)"><span class="path1"></span><span class="path2"></span><span
                      class="path3"></span></span>

                  <span *ngIf="!party?.SpecialMealId && party?.Notes?.length === 0">
                    -
                  </span>
                </td>
                <td class="party-list__party-addon">
                  <div   *ngIf="party.BookingContactAddonItems?.length" class="parties-grid__party-addon text-center" >
                    <span class="icon icon-addons mr-0" [autoClose]="'outside'" container="body" placement="auto" #addOnForBookingPopover="ngbPopover"
                    container="body" triggers="manual" [ngbPopover]="addOnForBooking" 
                    (click)="_ps.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(party)">
                    </span>
                </div>

                  <span  *ngIf="party.BookingContactAddonItems?.length == 0">
                    -
                  </span>
                </td>
                <td class="party-list__communication-preferences">
                  <div class="party-list__communicationprefeicons">
                    <div class="party-list__parentdiv rs-fs-16">
                      <span *ngIf="party.PageMethod == 0" class="seat-icon-color" [class]="party.PageMethod | pageIcon "
                      [ngStyle]="{'background-color':party.pageBackgroundColor ? party.pageBackgroundColor : '#E8E8E8', 'border-radius': '4px', 'color': party.pageBackgroundColor ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}"></span>
                      <span *ngIf="party.PageMethod != 0" [class]="party.PageMethod | pageIcon" 
                      [ngStyle]="{'background-color':party.pageBackgroundColor ? party.pageBackgroundColor : '#E8E8E8', 'border-radius': '4px', 'color': party.pageBackgroundColor ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}"
                        ></span>
                    </div>
                    <!-- <div class="parentdiv">
                      <span class="icon-envelope2 msg_font" style="padding-left: 8px">{{party.Messages}}</span>
                    </div> -->
                  </div>
                </td>
                <td class="party-list__actions">
                  <div class="party-list__icon-row" *ngIf="party.State !== PartyState.Seated">
                    <button class="icon-Group-587 party-list__round-icon rounded-icon"
                      [ngClass]="{'party-list__icon-green-color': !(party.State == PartyState.NoShowed || party.State == PartyState.Seated),
                      'party-list__round-icon-disabled': (party.ReservedFor && !(party.ReservedFor | EditOrSeatAction: party.State)) || party.State == PartyState.NoShowed || party.State == PartyState.Seated || party.State == PartyState.Left || party.State == PartyState.Cancelled}"
                      [disabled]="(party.ReservedFor && !(party.ReservedFor | EditOrSeatAction: party.State)) || party.State == PartyState.NoShowed || party.State == PartyState.Seated || party.State == PartyState.Left || party.State == PartyState.Cancelled"
                      (click)="seatParty(party)">
                      <span class="path1"></span>
                    </button>
                    <button class="icon-Group-591 party-list__hoverCancel party-list__round-icon rounded-icon"
                      [ngClass]="{'party-list__disable-events party-list__hoverCancel-disabled': (party.ReservedFor && !(party.ReservedFor | CancelorNoShowAction: party.State)) || party.State == PartyState.NoShowed || party.State == PartyState.Seated || party.State == PartyState.Left || party.State == PartyState.Cancelled}"
                      [disabled]="(party.ReservedFor && !(party.ReservedFor | CancelorNoShowAction: party.State)) || party.State == PartyState.NoShowed || party.State == PartyState.Seated || party.State == PartyState.Left || party.State == PartyState.Cancelled"
                      (click)="cancelParty(party)"></button>
                  </div>
                  <div class="party-list__spinner_row" *ngIf="party.State == PartyState.Seated">
                    <!-- Spinner for Waiting time -->
                    <div class="float-right text-center  party-list__progress-spinner">
                      <mat-progress-spinner [ngClass]="{
                              'first-percent': party.progressBarValue < 50,
                              'second-percent': party.progressBarValue >= 50,
                              'third-percent': party.progressBarValue  >= 80}" class="party-list__spinner_required_width"
                        [color]="warn" [diameter]="55" [value]="party.progressBarValue">
                      </mat-progress-spinner>
                      <mat-progress-spinner class="party-list__spinner_full_width" [color]="primary" [diameter]="55" [value]="100">
                      </mat-progress-spinner>
                      <span *ngIf="(party.remainingTime > 0)"
                        class="party-list__spent_mins">{{ ( party.remainingTime)  | formatWaitTime }}</span>
                      <span *ngIf="( 0 > party.remainingTime)" class="party-list__spent_mins">0m</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </virtual-scroller>
      </div>
      <!-- </ng-scrollbar> -->
    </div>
    <div class="party-list__popover-party-notes">
      <ng-template #partyNotesPopover>
        <div>
          <!-- <ng-scrollbar> -->
          <div class="party-list__scrollSize">
            <div class="reservation-notes">
              <div class="reservation-notes__header seat-popover-header">
                {{'reservationNotes' | translate}}
              </div>
              <div class="reservation-notes__content seat-popover-content">
                <div class="reservation-notes__notes-details seat-border-color"
                  *ngFor="let notes of partyWithNotes | reservationNotes : _settings">
                  <span *ngIf="!notes.isSpecialMeal">{{notes.name}}</span>
                  <span *ngIf="notes.isSpecialMeal">
                    <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.SpecialMeal">
                      {{'specialMealText' | translate }}:
                    </span>
                    <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.SpecialRequest">
                      {{'specialRequest' | translate }}:
                    </span>
                    <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.PromoCode">
                      {{_settings.PromoCodeLabel}}:
                    </span>
                    <span>{{notes.name}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- </ng-scrollbar> -->
        </div>
      </ng-template>
    </div>
    <div class="party-list__guest-notes">
      <ng-template #GuestNotesPopover>
        <div>
          <!-- <ng-scrollbar> -->
          <div class="party-list__scrollSize">
            <div class="reservation-notes">
              <div class="reservation-notes__header seat-popover-header">
                {{'guestNotes' | translate}}
              </div>
              <div class="reservation-notes__content seat-popover-content">
                <div class="reservation-notes__notes-details seat-border-color" *ngFor="let notes of guestNotes">
                  <span class="seat-secondary-text">{{notes}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- </ng-scrollbar> -->
        </div>
      </ng-template>
    </div>
    <div class="demo-tab-group__party-status">
      <ng-template #partyStatus>
        <app-party-status-popup [party]="selectedStatusParty" (partyStatusId)="seatStatusChanged($event)"></app-party-status-popup>
      </ng-template>
    </div>
  </div>
  <div *ngIf="parties?.length == 0" class="party-list__no-reservations">
    <img src="{{reservationUrl}}" alt="" class="party-list__no-reservations-img">
    <span *ngIf="_as.selectedMenuId == Menu.Reservation" class="party-list__no-reservations-text">{{'noReservationsFound' | translate}}</span>
    <span *ngIf="_as.selectedMenuId == Menu.WaitList" class="party-list__no-reservations-text">{{'noWaitlistsFound' | translate}}</span>
  </div>
</div>

<ng-template #packageInformation>
  <app-package-information [packageInfo]="dashboardFunctions.currentPackageInfo"></app-package-information>
</ng-template>

<!-- Add-on Popover -->
<ng-template #addOnForBooking>
  <app-add-on-popover [party]="partyWithAddon"></app-add-on-popover>
 </ng-template>