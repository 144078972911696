import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import * as myGlobals from '../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { RetailPopupComponent } from '../retail-popup/retail-popup.component';
import { QuickSaleCategory, QuickSaleItem, QuickSale, OutletSubProperty, RetailItemType, ButtonValue } from '../retail.modals';
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { BaseResponse } from '../shared/business/shared.modals';
import { RetailLocalization } from '../common/localization/retail-localization';
import * as _ from 'lodash';
import { FilterPipe } from '../shared/pipes/filter-pipe.pipe';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { RetailFunctionalityBusiness } from '../shared/business/retail-functionality.business';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';


@Component({
  selector: 'app-retail-quick-sale-popup',
  templateUrl: './retail-quick-sale-popup.component.html',
  styleUrls: ['./retail-quick-sale-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailQuickSalePopupComponent implements OnInit, AfterViewInit, OnDestroy {
  isModified: boolean = false;
  actionButton: ButtonValue;
  tableoptions: any[];
  originalData: any[];
  selectedData: any = [];
  categories: QuickSaleCategory[] = [];
  dropdownValue: any[] = [];
  unfiltereddropdownValue: any[] = [];
  existingItems: QuickSaleItem[] = [];
  itemList: QuickSale;
  quickSaleItemList: any[] = [];
  retailItems: any[] = [];
  captions: any;
  PlaceHoldertext: string;
  filterPipe: FilterPipe;
  filteredData: any[];
  outlets: OutletSubProperty[] = [];
  SearchText: any = '';
  selectedOutlet: string;
  selectedOutletId: number;
  isLoading = false;
  notifier: Subject<void> = new Subject<void>();
  pageStart = 0;
  pageLength = 20;
  sortBy = 1;
  isSortByAscending = true;
  selectedCopy: any = [];
  @Input() automationId: string='';
  @ViewChild('searchInput', { static: true }) input: ElementRef;
  subscription: Subscription;
  removedItems: any = [];
  defaultOutlet: number ;
  public functionalities: { [key: string]: boolean} = {};
  floatLabel: string;
  floatLabelNever: string;
  constructor(private dialogRef: MatDialogRef<RetailPopupComponent>, private _rs: RetailSetupService, public _utilities: RetailUtilities, private http: HttpServiceCall, 
              public localization: RetailLocalization, public func: RetailFunctionalityBusiness, public PropertyInfo: RetailPropertyInformation) {
    this.filterPipe = new FilterPipe()
    this.captions = this.localization.captions.retailsetup;
    this.PlaceHoldertext = `${this.localization.captions.common.SearchBy} ${this.captions.ItemNum}, ${this.captions.Description}, ${this.captions.Category}`;
    this.defaultOutlet = this.PropertyInfo.GetDefaultOutlet();
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnInit() {
    this.LoadOutlets();
    this.actionButton = {
      type: 'primary',
      label: this.captions.AddQuickSale,
      disabledproperty: true
    };
  }
  ngAfterViewInit(): void {
    const terms$ = fromEvent<any>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      );
    this.subscription = terms$
      .subscribe(
        criterion => {
          this.SearchText = this.input.nativeElement.value;
          this.Search(this.input.nativeElement.value);
        }
      );
  }
  async LoadOutlets() {
    this.outlets = []; 
    this.functionalities = await this.func.getRetailFunctionality();
    let apiResponse: BaseResponse<OutletSubProperty[]> = await this.InvokeServiceCallAsync('GetSubPropertyAccessByUser', myGlobals.Host.retailManagement, HttpMethod.Get, { userId: this._utilities.GetPropertyInfo("UserId") });
    if (apiResponse && apiResponse.successStatus && apiResponse.result && apiResponse.result.length > 0) {
      const defaultOutlet = apiResponse.result.find(x => x.subPropertyID === this.defaultOutlet);
      apiResponse.result.forEach(e => {
        if (e.isActive) {
          if (!this.selectedOutlet) {
            const tempSubPropertyName = defaultOutlet ? defaultOutlet.subPropertyName : e.subPropertyName;
            this.selectedOutlet = this.functionalities['ShowOutletFieldInQuickSaleSetupScreen'] ? e.subPropertyName : tempSubPropertyName ;
            const tempSubPropertyId = defaultOutlet ? defaultOutlet.subPropertyID : e.subPropertyID;
            this.selectedOutletId = this.functionalities['ShowOutletFieldInQuickSaleSetupScreen'] ? e.subPropertyID : tempSubPropertyId;
          }
          this.outlets.push(e);

        }
      });
      this.InvokeServiceCall("QuickSaleCategoryAndItems", myGlobals.Host.retailManagement, HttpMethod.Get);
    }
  }

  async InvokeServiceCallAsync(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  CurrentRow(eData) {
    if (eData.From == "All" && !eData.event.checked) {
      this.removedItems = [];
      this.removedItems = this.originalData;
    }
    else if (eData.From == "All" && eData.event.checked) {
      this.removedItems = [];
    }
    else {
      let eIndex = this.originalData.findIndex(y => y.id == eData.SelectedRow.id);
      if (eData && !eData.SelectedRow.checked && eIndex > -1) {
        this.removedItems.push(eData.SelectedRow);
      }
      else if (this.removedItems && this.removedItems.length > 0) {
        let index = this.removedItems.findIndex(y => y.id == eData.SelectedRow.id);
        this.removedItems.splice(index, 1);
      }
      console.log(this.removedItems);
    }
  }
  RowSelected(data) {
    if (data.length < 1) {

      if (this.filteredData) {
        _.remove(this.selectedData, (el) => {
          return this.filteredData && this.filteredData.some(selected => selected['id'] === el['id']);
        })
      } else if (!this.filteredData) {
        this.selectedData = [];
      }
      this.quickSaleItemList.forEach(x => {
        const isExist = this.filteredData && this.filteredData.some(selected => selected['id'] === x['id'])
        if (isExist) {
          x['checked'] = false;
        }
      })
    }
    else {
      this.selectedData = [];
      data.forEach(x => {
        const isExist = this.selectedData.some(selected => selected['id'] === x['id'])
        if (!isExist) {
          this.selectedData.push(x)
        }
      })
      this.quickSaleItemList.forEach(x => {
        x['checked'] = false;
        const isExist = data.some(selected => selected['id'] === x['id'])
        if (isExist) {
          x['checked'] = true;
        }
        if (!isExist && x['checked']) {
          this.selectedData.push(x);
        }
      })
      this.selectedCopy = _.cloneDeep(this.selectedData);
    }

    this.ValidateAddQuickSale();
  }

  outletChange(event) {
 

    this.selectedOutlet = event.value;
    this.selectedOutletId = this.outlets.find(x => x.subPropertyName == this.selectedOutlet) && this.outlets.find(x => x.subPropertyName == this.selectedOutlet).subPropertyID;
    this.loadRetailItems();
    this.filterCategoryforOutlet();
    this.Search(this.input.nativeElement.value);
    this.isModified = false;
    this.actionButton.disabledproperty=true;
    this.actionButton={...this.actionButton};
  }

  filterCategoryforOutlet(){
    this.dropdownValue = this.unfiltereddropdownValue;
    this.dropdownValue = this.dropdownValue.filter(x => x.outlets.length === 0 || x.outlets.includes(this.selectedOutletId));

  }

  UpdateCategory(data) {

    let dataIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.itemNumber == data[2].itemNumber);
    let optionIndex = this.tableoptions[0].dropdownOptions.findIndex(item => item.viewValue == data[0].value);
    this.tableoptions[0].TablebodyData[dataIndex].category = this.tableoptions[0].dropdownOptions[optionIndex].viewValue;
    this.quickSaleItemList[dataIndex].category = this.tableoptions[0].dropdownOptions[optionIndex].viewValue;
    this.tableoptions[0].TablebodyData[dataIndex].checkbox = this.tableoptions[0].dropdownOptions[optionIndex].id == 0;
   
    this.selectedCopy.forEach(x => {
      if (x['id'] === data[2].id) x['category'] = this.tableoptions[0].dropdownOptions[optionIndex].viewValue;
    });
    this.ValidateAddQuickSale();
  }

  addToQuickSale() {
    let body: QuickSaleItem[] = [];
    for (let result of this.selectedData) {
      if (this.selectedData.length > 0) {
        let optionIndex = this.tableoptions[0].dropdownOptions.findIndex(data => data.viewValue == result.category);
        let outletoptionIndex = this.tableoptions[0].outletDropDownOptions.findIndex(res => res.subPropertyName == this.selectedOutlet);
        let item: QuickSaleItem = {
          id: 0,
          itemId: result.id,
          quickSaleCategoryId: this.tableoptions[0].dropdownOptions[optionIndex].id,
          outletId: this.tableoptions[0].outletDropDownOptions[outletoptionIndex].subPropertyID,
          isRemoved: false
        };
        if (item.quickSaleCategoryId > 0) {
          body.push(item);
        }
      }
    }
    this.InvokeServiceCall("AddQuickSaleItem", myGlobals.Host.retailManagement, HttpMethod.Post, { outletId: this.selectedOutletId }, body);
    
  }

  close() {
    this.dialogRef.close();
  }
  Clear() {
    this.input.nativeElement.value = '';
    this.SearchText = '';
    this.Search('');
  }
  Search(searchText: any) {
    this.cancelOngoingCall();
    this.pageStart = 0;
    this.GetRetailItems(searchText);
    this.filteredData = this.filterPipe.transform(this.quickSaleItemList, searchText, ["desc", "itemNumber", "category"]);
    this.RefreshGrid(this.filteredData);
  }
  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }
  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]) {
    if (callDesc == "QuickSaleCategoryAndItems") {
      this.itemList = <any>result.result;
      if (this.itemList != null) {
        this.dropdownValue.push(
          {
            "id": 0, "viewValue": this.localization.captions.common.Select, "outlets":[]
          });
        this.categories = this.itemList.category;
        this.existingItems = this.itemList.items;
        for (let cat of this.categories) {
          this.dropdownValue.push(
            {
              "id": cat.id, "viewValue": cat.quickSaleCategory,
              "outlets": cat.quickSaleCategoryOutlets.map(x => x.outletId)
            });
        }
        this.unfiltereddropdownValue = this.dropdownValue;
      }
      this.loadRetailItems();
      this.filterCategoryforOutlet();
    }

    else if (callDesc == 'AddQuickSaleItem') {

      let itemList = <any>result.result;
      let removableItem = await this.removeFromQuickSale();
      if (removableItem && removableItem.length > 0) {
        var response = await this._rs.InvokeServiceCallAsync("AddQuickSaleItem", myGlobals.Host.retailManagement, HttpMethod.Post, removableItem, { outletId: this.selectedOutletId })
        if (response && response.result) {
          itemList = response.result;
        }
      }

      if (itemList != null) {
        this._rs.quickSaleItem = itemList.items;
        this._rs.quickSaleCategory = itemList.category;
      }
      this._rs.shopItems = this.retailItems;
      this.close();
    }
  }

  getfilterRetailItems(retailItemDetail): any {
    if (retailItemDetail) {
      return retailItemDetail.filter(o => o.itemType != RetailItemType.RevenueItem);
    }
    return [];
  }

  buildTableData(more: boolean = false) {
    this.quickSaleItemList = [];
    this.selectedData = [];
    if (this.retailItems != null && this.retailItems.length > 0) {
      for (let retailData of this.retailItems) {
        let existingCategoryId = this.GetSelectedCategory(retailData.id);
        let existingCategory = this.dropdownValue.find(res => res.id == existingCategoryId ) ;
        if(!existingCategory){
          existingCategory = this.dropdownValue.find(data => data.id == 0 );
        }
        let existingCategoryName = existingCategory.viewValue;

        let existingOutletId = this.GetSelectedOutlet(retailData.id);
        let existingOutletName = this.outlets.find(t => t.subPropertyID == existingOutletId) && this.outlets.find(o => o.subPropertyID == existingOutletId).subPropertyName;

        let item = {
          'id': retailData.id,
          'desc': retailData.itemDescription.trim(),
          itemNumber: retailData.itemNumber,
          'category': existingOutletName == this.selectedOutlet ? existingCategoryName : this.localization.captions.common.Select,
          'checkbox': existingCategoryId == 0,
          'outlet': existingOutletName,
          'checked': this.existingItems.find(s => s.itemId == retailData.id && existingOutletName == this.selectedOutlet) ? true : false
        };
        if (retailData.outletIds.find(x => x == this.selectedOutletId)) {
          this.quickSaleItemList.push(item);
          if (item.checked && item.outlet == this.selectedOutlet) {
            this.selectedData.push(item);
          }
        }
      }
      if (more) this.checkSelectedBeforeRefresh();
      else this.selectedCopy = [];
      this.originalData = _.cloneDeep(this.selectedData);
    }
    this.RefreshGrid(this.quickSaleItemList);
  }

  private checkSelectedBeforeRefresh() {
    if (this.selectedData.length != this.selectedCopy.length) {
      this.selectedCopy.forEach(element => {
        let exists = this.selectedData && this.selectedData.some(x => x['id'] === element['id']);
        if (!exists) {
          this.selectedData.push(element);
          let index = this.quickSaleItemList.findIndex(q => q['id'] == element['id']);
          this.quickSaleItemList[index].checked = true;
          this.quickSaleItemList[index].category = element['category'];
        }
      });
    }
  }
  private GetSelectedCategory(itemId: number): number {

    let item = this.existingItems.filter(r => {
      return r.itemId == itemId && r.outletId == this.selectedOutletId
    });
    return item.length > 0 ? item[0].quickSaleCategoryId : 0;
  }

  private GetSelectedOutlet(itemId: number): number {
    let item = this.existingItems.filter(r => {
      return r.itemId == itemId && r.outletId == this.selectedOutletId
    });
    return item.length > 0 ? item[0].outletId : 0;
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }

  private RefreshGrid(quickSaleItems: any) {
    quickSaleItems = this._utilities.sortObj(quickSaleItems, 'desc');
    this.tableoptions = [
      {
        TableHdrData: [
          { "title": this.captions.Description, "jsonkey": "desc" },
          { "title": this.captions.ItemNum, "jsonkey": "itemNumber", "alignType": "right" },
          { "title": '', "jsonkey": "" },
          { "title": this.captions.Category, "jsonkey": "category" }],
        TablebodyData: quickSaleItems,
        pagination: false,
        sortable: false,
        CustomColumn: true,
        PlaceHoldertext: `${this.captions.ItemNum}, ${this.captions.Description}, ${this.captions.Category}`,
        EnableActions: false,
        SelectRows: true,
        IsCommission: true, 
        Searchable: false,
        EditMoreOption: true,
        SelectedSettingId: myGlobals.GridType.quickSale,
        Sortable: false,
        TableId: myGlobals.GridType.quickSale,
        disableDelete: false,
        SelectRow: true,
        SelectOnlyRow: true,
        dropdownOptions: this.dropdownValue,
        outletDropDownOptions: this.outlets,
        bufferCount: 10,
        automationId: 'retailQuickSale'
      }
    ];

  }

  private ValidateAddQuickSale() {
    this.isModified = !_.isEqual(_.sortBy(this.originalData, ['id']), _.sortBy(this.selectedData, ['id'])) && (_.every(this.selectedData, (x) => x.category != this.localization.captions.common.Select) && _.every(this.selectedData, (x) => x.outlet != this.localization.captions.common.Select));
    this.actionButton.disabledproperty=!this.isModified || (this.selectedData &&this.selectedData.length==0);
    this.actionButton={...this.actionButton};
  }

  async loadRetailItems(searchText: string = '', more: boolean = false) {
    try {
      this.isLoading = true;
      this.notifier = new Subject<void>();
      const response = await this.http.cancellableObservalble({
        callDesc: 'GetItemByPagination',
        host: myGlobals.Host.retailManagement,
        method: HttpMethod.Get,
        body: undefined,
        uriParams: {
          outletId: this.selectedOutletId,
          includeInactive: false,
          pageStart: this.pageStart,
          pageLength: this.pageLength,
          sortBy: this.sortBy,
          isSortByAscending: this.isSortByAscending,
          searchText: this.SearchText,
          category: '',
          subcategory1: '',
          subcategory2: '',
          subcategory3: '',
          subcategory4: '',
          subcategory5: ''
        }
      }, this.notifier).toPromise();
      if (response) {
        let moreItems = response.result ? this.getfilterRetailItems(response.result) : [];
        if (searchText) this.retailItems = moreItems;
        else this.retailItems = [].concat(this.retailItems ? this.retailItems : [], moreItems);
        this.buildTableData(more);
        this.pageStart += this.pageLength;
      }
    }
    catch (ex) {
      console.error(ex);
    } finally {
      this.isLoading = false;
    }
  }

  async GetRetailItems(searchText: string = '') {
    if (this.selectedOutletId) {
      this.pageStart = 0;
      this.retailItems = [];
      await this.loadRetailItems(searchText);
    }
  }

  cancelOngoingCall() {
    this.notifier.next();
    this.notifier.complete();
  }

  async virtualScrollHandler(arg: { viewPortItems: string | any[]; }) {
    if (arg.viewPortItems && arg.viewPortItems.length && !this.isLoading && this.pageStart <= this.retailItems.length) {
      await this.loadRetailItems('', true);
    }
  }

  async removeFromQuickSale(): Promise<QuickSaleItem[]> {
    let body: QuickSaleItem[] = [];
    for (let itm of this.removedItems) {
      if (this.removedItems.length > 0) {
        let optionIndex = this.tableoptions[0].dropdownOptions.findIndex(r => r.viewValue == itm.category);
        let outletoptionIndex = this.tableoptions[0].outletDropDownOptions.findIndex(res => res.subPropertyName == this.selectedOutlet);
        let item: QuickSaleItem = {
          id: 0,
          itemId: itm.id,
          quickSaleCategoryId: this.tableoptions[0].dropdownOptions[optionIndex].id,
          outletId: this.tableoptions[0].outletDropDownOptions[outletoptionIndex].subPropertyID,
          isRemoved: true
        };
        if (item.quickSaleCategoryId > 0 && body.findIndex(x => x.itemId == item.itemId) < 0) {
          body.push(item);
        }
      }
    }
    return body;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

