import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getAddonName'
})
export class GetAddonNamePipe implements PipeTransform {
  transform(addOnId: any, settings:any): any {

   return (settings.Addons.find(addOnItem => addOnItem.AddonId == addOnId)?.AddonName || '')

  }
}
