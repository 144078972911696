import { Status } from "../constants/commonenums";

export class PromoCodeDTO{
     Id: number;
 MerchantId: number;
 PromoCodeLabel: string;
 OfferCode: string;
 ApplyDiscount: number;
  Description: string;
 OfferType: OfferType;
 RedemptionLimit: number;
 GuestRedemptionLimit: number;
 ValidFrom: Date;
 ValidTo: Date;
 MinPurchase: number;
 MaxPurchase: number;
 AppliesTo: AppliesTo;
 ConsiderLowestPriceItem: boolean;
 TermsAndConditions: string;
 InternalComments: string;
 AvailableForWebReservation: boolean;
 DiscountType: FeeType;
 DiscountValue: number;
 IncludeAddonforDiscount: AddonDiscount;
 StatusCode: Status;
 ActionBy: string;
 MinimumNumberOfActivities: number;
 DiscountLimit: number;
 IsActive: boolean;
 ActivityStartDate: Date;
 ActivityEndDate: Date;
 IsGuestRedemptionUnlimited: boolean;
 IsRedemptionLimitUnlimited: boolean;
OfferPropertyMapping: OfferPropertyMappingDTO[];
OfferRule: OfferRuleDTO[];
}

export enum FeeType
{
     FlatFee = 0,
     Percentage
}
 
export enum ApplyDiscount
{
     BeforeTax = 0,
     AfterTax
}

export enum AddonDiscount
{
     NA = 0,
     Addon = 1
}
 
export enum AppliesTo
{
     All = 0,
     Specific
}
 
export enum OfferType
{
     PromoCode = 0, 
     GiftCoupons
}

export class PromoCodeRequestDTO{
    
}

export class OfferRuleDTO
{
    Id : number;
    OfferId: number;
    ActivityId: number;
    ActivityType: ActivityBookingType;
    PropertyId: number;
    ActivityParams: string;
    SelectedIds: [];
}

export enum ActivityBookingType {
    AnyActivity = 0,
    SpecificActivity = 1
  }

  export class OfferPropertyMappingDTO
{
     Id: number;
     OfferId: number;
     PropertyId: number;
}

export enum IncludeAddonforDiscount {
    NA = 0,
    Addon = 1
}


export const ActivityTypeName  =[{id:0,value:'SessionBooking'}, {id:1,value:'PrivateBooking'}, {id:2,value:'OpenBooking'}, {id:4,value:'ClassBooking'} ];

 export const SpecialMealName =[{id:3,value:'SpecialMeal'},{id:2,value:'OpenBooking'}] 