import { Injectable } from '@angular/core';
import { API } from '../../retail-code-setup/retail-taxes/tax-type/tax-type.model';
import { BaseResponse } from '../../retail.modals';
import { Host } from '../globalsContant';
import { HttpMethod, HttpServiceCall } from './http-call.service';

@Injectable()
export class TaxTypeDataService {

    constructor(private http: HttpServiceCall) {
    }

    public async getAllPostTypes(includeInactive: boolean) {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "GetAllPostType",
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: { includeInactive }
        });

        return response.result;
    }

    public async getAllTaxTypes(IncludeInActive: boolean = false): Promise<API.Tax[]> {

        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "GetAllTaxTypes",
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: { IncludeInactive: IncludeInActive }
        });

        return response.result;
    }

    public async getAggregatedTaxTypes(IncludeInActive: boolean = true): Promise<any[]> {

        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "GetAggregatedTax",
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: { IncludeInactive: IncludeInActive }
        });

        return response.result;
    }

    public async getAllPSTCodes(IncludeInActive: boolean = false): Promise<any[]> {

        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "GetAllPSTCode",
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: { IncludeInactive: IncludeInActive }
        });

        return response.result;
    }

    public async getTaxById(id: number) {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "GetTaxType",
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: { id }
        });

        return response.result;
    }

    public async createTaxType(TaxType: API.Tax) {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "CreateTaxType",
            host: Host.folio,
            method: HttpMethod.Post,
            body: TaxType,
            uriParams: undefined
        });

        return response.result;
    }

    public async updateTaxTypes(TaxType: API.Tax) {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "UpdateTaxType",
            host: Host.folio,
            method: HttpMethod.Put,
            body: TaxType,
            uriParams: undefined
        });
        return response.result;
    }

    public async updateTaxTypesFromGrid(TaxType: API.Tax) {

        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "UpdateTaxFromGrid",
            host: Host.folio,
            method: HttpMethod.Put,
            body: TaxType,
            uriParams: undefined
        });

        return response.result;
    }

    public async deleteTaxType(id: number) {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "DeleteTaxType",
            host: Host.folio,
            method: HttpMethod.Delete,
            body: undefined,
            uriParams: { id }
        });

        return response.result;
    }

    public async getNextListOrder_TaxType(): Promise<number> {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "GetNextListOrder_ComtrolTaxType",
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: undefined
        });

        return response.result;
    }
}

