
<!-- <ng-container *ngIf="showList; then selectActivity; else weekview">
</ng-container>

<ng-template #selectActivity>

</ng-template> -->

<!-- <ng-template #weekview> -->
    <div class="main-header">

        <activities-app-button class="btn-add" *ngIf="!inputData.data" [buttontype]="addActivityButton"
        [ngbPopover]="activitiesList" [autoClose]="'outside'" (click)="addActivityClick()"></activities-app-button>
    </div>
    <div class="view-container" >
        <div class="header">
            <div class="column">{{'Activity' | translate}}</div>
            <div class="column" *ngFor="let day of headerData">
                {{day.day}}
            </div>
        </div>
        <div class="content" *ngFor="let activity of calenderViewData">
            <div class="column activity">
                <!-- <ng-container *ngIf="(activity.CategoryId | activityColor) as categoryStyle"> -->
                    <div class="userprofile__instructor-initial">                    
                        <!-- [ngStyle]="{'backgroundColor': categoryStyle?.backgroundColor}" -->
                        {{activity | guestNameFormatter}}
                    </div>
                <!-- </ng-container> -->
                <!-- [category-indicator]="rowData.CategoryId" -->
                <span class="userprofile__instructor-name" [title]="activity.Name" >
                    {{activity.Name}}</span>
                <span class="selected-count" [class.noSessionSelected]="!advBlockData.selectedActivities[activity.Id]?.selectedSessionCount">{{advBlockData.selectedActivities[activity.Id]?.selectedSessionCount || 0}} {{ ('Sessions' | translate) }}</span>
                <span class="icon-ic_delete_24px delete-icon-cls" (click)="removeActivity(activity.Id)" [matTooltip]="'remove' | translate"></span>
            </div>
            <ng-container *ngFor="let day of activity.Days">
                <div class="column" *ngIf="day" [style.gridColumn]="day?.GridColumn">
                    <div class="column__session seat-label" *ngFor="let session of day.Sessions" [class.selected]="advBlockData.selectedActivities[activity.Id]?.Sessions[session.ActivitySessionId]" 
                    (click)="sessionClick(session, activity)">
                    <div class="column__session-date" *ngIf="session.Date">{{session.Date | localizedDate: 'DD MMM yyyy'}}</div>
                        {{session.StartTime | localizedDate:'LT'}} - {{session.EndTime | localizedDate:'LT'}}
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="!calenderViewData?.length" class="no-data-text">
            {{'No Activity selected' | translate}}
        </div>
    </div>
<!-- </ng-template> -->

<ng-template #activitiesList >
    <div class="activities in-popover">
            
        <div class="search-container">
            <mat-form-field class="search-input-field basic-input-field input-with-boundary" >
                <input matInput [placeholder]="'searchByName' | translate" [(ngModel)]="searchText">
                <i *ngIf="!searchText" class="view-icon icon-search seat-icon-color"></i>
                <i *ngIf="searchText" class="view-icon icon-Group-591 seat-icon-color clickable"
                (click)="searchText=''"></i>
            </mat-form-field>
           
        </div>      
        <div class="filter-options">
            <div [ngClass]="config.class">
                <label class="switch-control__switch-label guest-form-label">{{config.label | translate}}</label>
             
                  <label class="toggle-switch">
                    <!-- {{config.name}} -->
                    <input  class="switch-input" type="checkbox"  [(ngModel)]="showPast" (change)="showPastReservations($event)"/>
                                       <span class="slider round"></span>
                  </label>
                
              </div>
              <a  class="font-bold select-all" (click)="selectAllActivities()" *ngIf="!selectedAll">{{ 'selectAll' | translate}}</a>
              <a  class="font-bold select-all" (click)="unSelectAllActivities()" *ngIf="selectedAll">{{ 'unselectAll' | translate}}</a>
        </div>
        <ul class="activities__list">
            <li class="activities__item" *ngFor="let activity of (allActivities | showPastFilterPipe : showPast : cacheSevice.settings.value |searchData: searchText)">
                <div class="activities__item-name pr-2">{{activity.Name}}</div>
                <activities-app-button *ngIf="!advBlockData.selectedActivities[activity.Id]" [buttontype]="addToBlockButton" (click)="addActivity(activity.Id)"></activities-app-button>
                <activities-app-button *ngIf="advBlockData.selectedActivities[activity.Id]" [buttontype]="selectedButton" (click)="removeActivity(activity.Id)"></activities-app-button>
                 <!-- (click)="removeActivity(activity.Id)" -->
             </li>
        </ul>
    </div>
</ng-template> 