import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { Field } from '@dynamicform/models/field.interface';
import { MonthHeaderComponent } from './month-header/month-header.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MMM',
  },
  display: {
    dateInput: 'DD MMM',
    monthYearLabel: 'MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-form-month-date-picker',
  templateUrl: './form-month-date-picker.component.html',
  styleUrls: ['./form-month-date-picker.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  }, { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class FormMonthDatePickerComponent implements Field, OnInit {
  monthHeader = MonthHeaderComponent;
  FormControlName: any;
  config: FieldConfig;
  group: UntypedFormGroup;
  constructor() { }

  ngOnInit() {
    this.FormControlName = this.config.name;
  }

}
