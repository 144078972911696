<div class="payment-options">
  <dynamic-form [config]="paymentConfig" #paymentType></dynamic-form>
  <div *ngIf="enableAuthorize" style="margin-left: auto;margin-right: 10px;">
    <div style="width: 100%;">
      <app-retail-capture-card style="float:right;"
      [PaymentReferenceID]="PaymentReferenceID"  (removeDetails)="removeDetails($event)"
      [isShowPMAgentValidationMessage]="false"
      (NewPaymentReferenceID)="SaveReferenceId($event)">
       </app-retail-capture-card>
    </div>
  </div>
</div>
