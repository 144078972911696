<section id="edit-menu-container">

<div class="header">
    <h4 [attr.literalID]="'hdr_editMenu'" [attr.LiteralID]="'hdr_editMenu'" >{{captions.hdr_editMenu}}</h4>
    <i  aria-hidden="true" class="icon-close ml--auto" (click)="Cancel()"></i>
</div>
    

<div class="edit-wrapper" >
  <form [formGroup]="editMenuForm" autocomplete="off" class="container-padding">
    <div class="first-container"  >
        <label class="label_Value" [attr.literalID]="'lbl_originalValue'" [attr.LiteralID]="'lbl_originalValue'" >
          {{captions.lbl_originalValue}}
        </label>
        <span class="form_value">
            {{originalValue}}
        </span>
    </div>
    <div>
        <mat-form-field [floatLabel]="floatLabel"  class="first-container">
            <input matInput formControlName="newValue"
            placeholder="{{captions.lbl_newValue}}" required>
            <mat-error *ngIf="editMenuForm.controls['newValue'].hasError('required')" [attr.literalID]="'err_missingNewValue'" [attr.LiteralID]="'err_missingNewValue'" >
                {{captions.err_missingNewValue}}</mat-error>
        </mat-form-field>
    </div> 

  </form>
</div>

<div class="footer">
    <button mat-button class="ml-4 spa-primary-btn-default" [disabled]="isDisabled" type="button" (click)="SaveLiteral()">{{captions.btn_update}}</button>
    <button mat-button class="ml-2 text_capitalize LW14" type="button" (click)="Cancel()">{{captions.btn_cancel}}</button>
</div>

</section>
