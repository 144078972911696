<section class="quickSalePopup">
  <mat-dialog-content class=' mt-0'>
    <mat-form-field class="pr-3 d-inline-block spa-searchbox w300 custSearchbox ag_form-control--2" [floatLabel]="floatLabelNever">
      <input attr.automationId = 'Srch_{{automationId}}_quickSaleSearch' #searchInput type="text" class="LW14 search-box-width" [placeholder]="PlaceHoldertext" matInput autocomplete="off"
        [(ngModel)]="SearchText">
      <i  attr.automationId = 'Icn_{{automationId}}_clear' aria-hidden="true" [ngClass]="!SearchText ? 'icon-Search' : 'icon-Cancel'" class="cursor" (click)="Clear()"></i>
    </mat-form-field>
    <mat-form-field *ngIf="functionalities['ShowOutletFieldInQuickSaleSetupScreen'] || false" [floatLabel]="floatLabel">
      <mat-select attr.automationId = 'Dd_{{automationId}}_outlets' placeholder="{{captions.outlets}}" (selectionChange)="outletChange($event)" [(value)]="selectedOutlet">
        <mat-option [value]="outOption.subPropertyName" *ngFor="let outOption of outlets">
          {{outOption.subPropertyName}}</mat-option>
      </mat-select>
     
  </mat-form-field>
    <div class="table-container">
      <app-retail-table attr.automationId = 'Tbl_{{automationId}}_retailQuickSale' [options]="tableoptions" (CurrentRowSelectedEmitter)="CurrentRow($event)" (RowSelectEmitter)="RowSelected($event)" (dropDownChange)='UpdateCategory($event)'
      (scrollEmitter)="virtualScrollHandler($event)"></app-retail-table>
    </div>
    <div class="res-progress-bar" *ngIf="isLoading">
      <mat-progress-bar  mode="indeterminate"></mat-progress-bar>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="pr-5 ">
    <div class="ag_display--flex ml-4 mr-4">
        <app-button attr.automationId = 'Btn_{{automationId}}_addtoQuickSale' [buttontype]="actionButton" (valueChange)="addToQuickSale()">
        </app-button> 
      <button attr.automationId = 'Btn_{{automationId}}_close' class="LW14" mat-button (click)="close()">{{captions.CANCEL}}</button>
    </div>
  </mat-dialog-actions>
</section>