<section class="vat vat-configuration-wrapper newVersionWrapper h-100">

  <!-- <form autocomplete="off" [formGroup]="VatForm" class="h-100"> -->
  <div autocomplete="off" class="h-100" class="form" id="time-overlay">
    <ng-scrollbar >
      <div [formGroup]="VatForm" [ngClass]="viewOnly ? 'view-only' : ''">
        <label class="form-label-normal">{{captions.enableVat}}</label>
        <app-retail-toggle-switch [attr.automationId]="'Txt_vatConfiguration_enableVat'" class="d-block mb-2" formControlName="enableVat"
          (changeToggleEvent)="enableVatConfig($event[0])"></app-retail-toggle-switch>
        <div *ngIf='VatForm.value.enableVat'>
          <mat-form-field class="" [floatLabel]="floatLabel">
            <input [attr.automationId]="'Txt_vatConfiguration_vatNo'" type="text" matInput inputtype="capitalise,nospecailchar" maxlength="25"
              [placeholder]="captions.vatNumber" formControlName="vatNumber" required>
            <mat-error *ngIf="VatForm.controls.vatNumber.hasError('required')">{{captions.missingVatNumber}}
            </mat-error>
          </mat-form-field>
          <div *ngIf="functionalities.ShowNoTaxExempt">
            <label class="form-label-normal">{{captions.NoTaxExempt}}</label>
            <app-retail-toggle-switch [attr.automationId]="'Txt_vatConfiguration_noTaxExempt'" class="d-block mb-2" formControlName="notaxexempt"></app-retail-toggle-switch>
          </div>
          <hr />
          <div class="service-charge mb-3">
            <label class="form-label-primary mb-3 d-block">{{captions.serviceCharge}}</label>
            <mat-radio-group [attr.automationId]="'Rdo_vatConfiguration_serviceCharge'" formControlName="serviceCharge">
              <mat-radio-button *ngFor="let serviceCharge of serviceCharges; let i = index" [value]=serviceCharge.id
                class="mr-4 mb-2">{{serviceCharge.name}}</mat-radio-button>
            </mat-radio-group>
            <div class='d-block'>
              <div class='d-inline-block mr-4'>
                <label class="form-label-normal">{{captions.includeVat}}</label>
                <app-retail-toggle-switch class="d-block mb-2" formControlName="includeVat" [attr.automationId]="'Txt_vatConfiguration_includeVat'"
                  (changeToggleEvent)="includeServiceChargeVAT()"></app-retail-toggle-switch>
              </div>
              <div class='d-inline-block' *ngIf='VatForm.value.includeVat'>
                <mat-form-field class="" [floatLabel]="floatLabel">
                  <mat-select [attr.automationId]="'Dd_vatConfiguration_vat'" formControlName="vat" multiple [placeholder]="captions.vat">
                    <mat-option [value]="vat.id" *ngFor="let vat of vatData">{{vat.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

          </div>
          <hr />
          <div class="gratuity mb-2">
            <label class="form-label-primary d-block mb-3">{{captions.gratuity}}</label>
            <mat-radio-group [attr.automationId]="'Rdo_vatConfiguration_gratuity'" formControlName="gratuity">
              <mat-radio-button *ngFor="let gratuityItem of gratuity; let i = index" [value]=gratuityItem.id
                class="mr-4 mb-2">{{gratuityItem.name}}</mat-radio-button>
            </mat-radio-group>
          </div>
          <hr />
          <div class="commission mb-2">
            <label class="form-label-primary d-block mb-3">{{captions.commission}}</label>
            <div class='mb-2'>
              <label class="form-label-secondary textellipsis d-block">{{captions.item}}</label>
              <mat-radio-group formControlName="itemComission"  [attr.automationId]="'Rdo_vatConfiguration_item'">
                <mat-radio-button *ngFor="let comissionItem of commission; let i = index" [value]=comissionItem.id
                  class="mr-4 mb-2">{{comissionItem.name}}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <label class="form-label-secondary textellipsis d-block">{{captions.revenue}}</label>
              <mat-radio-group formControlName="revenueComission"  [attr.automationId]="'Rdo_vatConfiguration_revenue'">
                <mat-radio-button *ngFor="let comissionItem of commission; let i = index" [value]=comissionItem.id
                  class="mr-4 mb-2">{{comissionItem.name}}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <!-- <hr /> -->
        </div>
        <hr />
      </div>


      <div [formGroup]="taxForm" *ngIf='VatForm.value.enableVat && showLongTermVATConfig'>
        <label class="form-label-normal">{{captions.lbl_longTermVatConfig}}</label>
        <div class="ag_display--flex" *ngIf="showLongTermBaseTax">
          <mat-form-field class="ag_form-control--33" [floatLabel]="floatLabel">
            <mat-select  [attr.automationId]="'Dd_vatConfiguration_baseTax'" formControlName="baseTax" [placeholder]="captions.baseTax" required
              (selectionChange)="baseTaxTypeSelected($event)">
              <mat-option *ngFor="let tax of taxList" [value]="tax.id">{{tax.taxType}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="taxForm.controls.baseTax.hasError('required')">
              {{captions.BaseTaxRequired}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="ag_display--flex">
          <mat-form-field class="ag_form-control--33" [floatLabel]="floatLabel">
            <input type="text" matInput inputtype="nospecailchar" maxlength="25" [placeholder]="captions.taxName"
              formControlName="taxName" required  [attr.automationId]="'Txt_vatConfiguration_taxName'">
            <mat-error *ngIf="taxForm.controls.taxName.hasError('required')">{{captions.missingTaxNumber}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="ag_form-control--33" [floatLabel]="floatLabel">
            <input type="number" matInput [step]="1" formControlName="taxChange" inputtype='nonnegative,nodecimal'
              [placeholder]="captions.taxChange" min="0" max="999" [attr.automationId]="'Txt_vatConfiguration_taxChange'"
              oninput="this.value =!!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : 0">
          </mat-form-field>
        </div>
        <mat-form-field class="ag_form-control--66 txt-area-height" [floatLabel]="floatLabel">
          <textarea  [automationId]="'Ta_vatConfiguration_comments'"  matInput [placeholder]="captions.lbl_comments" name="keywords" inputtype="noprespace"
            [maxLength]="254" formControlName="comments"></textarea>
        </mat-form-field>
        <hr />
        <h4 class="ag_group--headers">{{taxCaptions.lbl_rangeMaintenance}}</h4>
        <div formArrayName="taxFormArray">
          <div *ngFor="let tax of taxForm.get('taxFormArray')['controls']; let i=index; let first=first"
            [formGroupName]="i">
            <div class="ag_display--flex">
              <div class="ag_w--15">
                <ag-date [automationId]="'Txt_vatConfiguration_startDate'" formControlName="startDate" [placeholder]="captions.startDate" [minDate]="minStartDate"
                  [maxDate]="maxStartDate" (dateChange)='dateControlChange(1,tax)' class="ag_form-control"></ag-date>
              </div>
              <div class="ag_w--15 ml-4">
                <ag-date [automationId]="'Txt_vatConfiguration_endDate'" [placeholder]="captions.endDate" formControlName="endDate" [minDate]="minEndDate"
                  [maxDate]="maxEndDate" (dateChange)='dateControlChange(2,tax)' class="ag_form-control">
                </ag-date>
              </div>
              <mat-form-field class="pl-4" [floatLabel]="floatLabel">
                <mat-select [attr.automationId]="'Dd_vatConfiguration_deptName'"  formControlName="dept" [placeholder]="captions.department">
                  <mat-option *ngFor="let dept of departmentForRange" [value]="dept.id">{{dept.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <ag-input class="pl-4" [automationId]="'Txt_vatConfiguration_minPrice'" formControlName="minPrice" type="CURRENCY"
                [placeholder]="captions.minimumPrice"></ag-input>
              <ag-input class="pl-4" [automationId]="'Txt_vatConfiguration_taxPercent'" formControlName="taxPercent" type="PERCENTAGE"
                [placeholder]="captions.taxPercentage"></ag-input>
              <span class="icon-plus pt-3" (click)="addtaxArray(i)" *ngIf="first"></span>
              <span class="icon-Minus pt-3" (click)="removetaxArray(i)" *ngIf="!first"></span>
            </div>
          </div>
        </div>
        <hr />

        <h4 class="ag_group--headers">{{captions.postType}}</h4>
        <div class="ag_display--flex">
          <app-simple-search [attr.automationId]="'Srch_vatConfiguration_search'" class="searchbox" [value]="searchText" [placeholder]="captions.lbl_search"
            (searchChange)="searchValueChange($event)">
          </app-simple-search>
        </div>
        <app-vat-config-department-table [SearchValueChangeEmitter]="searchText" [inputs]="buildingRoomTypeInput"
          (BuildingRoomchange)="onBuildingRoomchange($event)" [isViewOnly]='true'></app-vat-config-department-table>
      </div>
    </ng-scrollbar>
  </div>


  <div class="actions">
    <div>
      <button class="LW14 save" mat-button (click)="save()" [attr.automationId]="'Btn_vatConfiguration_save'"
        [ngClass]="enableSave ? 'spa-primary-default-save' : 'spa-primary-disabled'">{{captions.save}}</button>
      <a class="LW14 text-default-color" (click)="cancel()">{{captions.cancel}}</a>
    </div>
  </div>
  <input [attr.automationId]="'Txt_vatConfiguration_save'" type="submit" [disabled]="!enableSave" class="hidden-submit" tabindex="-1" (click)="save()" />
  <!-- </form> -->
</section>
