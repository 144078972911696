import { Injectable } from '@angular/core';
import { Localization } from '../../localization/localization';

import { Events, Distribution, NotificationEventTemplate, EventNotificationGroup, EventConfiguration, EventNotification, GuestCategory } from './notification-configuration.model';
import { CommonCommunication } from '../../communication/services/common-communication-services';
import { CommonControllersRoutes } from '../../communication/common-route';

@Injectable({
  providedIn: 'root'
})
export class NotificationConfigurationService {
  productId : string;
  eventCaptions: any;
  constructor(private localization: Localization, private _commonCommunication: CommonCommunication) {
    this.productId = this.localization.GetPropertyInfo('ProductId');
    this.eventCaptions =this.localization.captions.settings.events;   
   }

  exceptionHandle(ex: any) {
    console.error(ex);
    throw ex;
  }

  async getAllEventsByProductId(): Promise<Events[]> {
     let result:Events[] = await this._commonCommunication.getPromise({
          route: CommonControllersRoutes.GetAllEventsByProductId,
          uriParams: { productId: this.productId!=null?this.productId: this.localization.GetPropertyInfo('ProductId') }
        });
        result.forEach(element => {
          let eventname = EventNotification[element.id];
          element.eventName = this.eventCaptions[eventname];   
          if (!element.eventName && element.id == EventNotification.RetailProductRetailReceipts) {
            element.eventName = this.eventCaptions.RetailReceipts;
        }         
      });
      return result;
  } 

  async getAllDistributionByProductId(): Promise<Distribution[]> {
    try {
      return await this._commonCommunication.getPromise({
        route: CommonControllersRoutes.GetDistributionList,
        uriParams: { productId: this.productId!=null?this.productId: this.localization.GetPropertyInfo('ProductId')}
      });
    } catch (e) {
      this.exceptionHandle(e);
    }
  }
  
  async getGuestCategoryByProductId(): Promise<GuestCategory[]> {
    try {
      return await this._commonCommunication.getPromise({
        route: CommonControllersRoutes.GetGuestCategory,
        uriParams: { productId:this.productId!=null?this.productId: this.localization.GetPropertyInfo('ProductId')}
      },false);
    } catch (e) {
      this.exceptionHandle(e);
    }
  } 

  async GetTemplateDetailsByEventId(eventId : number): Promise<NotificationEventTemplate[]> {
    try {
      return await this._commonCommunication.getPromise({
        route: CommonControllersRoutes.GetTemplateDetailsByEventId,
        uriParams: { eventId: eventId }
      });
    } catch (e) {
      this.exceptionHandle(e);
    }
  } 

  async GetEventNotificationGroupByProduct(): Promise<EventNotificationGroup[]> {
    try {
      return await this._commonCommunication.getPromise({
        route: CommonControllersRoutes.GetEventNotificationGroupByProduct,
        uriParams: { productId: this.productId!=null?this.productId: this.localization.GetPropertyInfo('ProductId')}
      });
    } catch (e) {
      this.exceptionHandle(e);
    }
  } 

  async GetEventNotificationGroupByEventId(eventId : number): Promise<EventNotificationGroup[]> {
    try {
      return await this._commonCommunication.getPromise({
        route: CommonControllersRoutes.GetEventNotificationGroupByEventId,
        uriParams: { eventId: eventId }
      });
    } catch (e) {
      this.exceptionHandle(e);
    }
  }
  
  async SaveEventConfiguration(eventConfiguration : EventConfiguration[]): Promise<boolean> {
    try {
      return await this._commonCommunication.postPromise({
        route: CommonControllersRoutes.SaveEventConfiguration,
        body: eventConfiguration
      }, true);
    } catch (e) {
      this.exceptionHandle(e);
    }
  } 

  async SaveDmEormConfiguration(eventConfiguration : EventConfiguration[]): Promise<boolean> {
    try {
      return await this._commonCommunication.postPromise({
        route: CommonControllersRoutes.SaveEventConfiguration,
        body: eventConfiguration
      }, true);
    } catch (e) {
      this.exceptionHandle(e);
    }
  } 
}
