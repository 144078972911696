<header class="respos-header respos-box-fill-primary">
    <div class="respos-header__left">
        <img src="./assets/images/Agilysys_Reserve_logo_fullwhite.png" [alt]="'Rserve POS'" (click)="goToListing()">
    </div>
    <div class="respos-header__right">
        <button class="respos-btn respos-btn-outline-r respos-header__keyboard" (click)="enableDisableKeyboard()">
            <span class="respos-icon-only icon-respos-keyboard" *ngIf="keyboardService.enableKeyboard"></span>
            <span class="respos-icon-only icon-respos-keyboard-off" *ngIf="!keyboardService.enableKeyboard"></span>
        </button>
        <button class="respos-btn respos-btn-outline-r" (click)="goToHome()">
            <span class="respos-icon icon-respos-home"></span> {{'Home' | translate}}
        </button>
        <button class="respos-btn respos-btn-outline-r" [matBadge]="cartService.cartGroupItems?.length" [matBadgeHidden]="!cartService.cartItems?.length" matBadgeOverlap="true" matBadgeColor="accent" (click)="goTocart()">
            <span class="respos-icon-only icon-respos-shopping_cart "></span>
        </button>
        <div class="respos-header__user-profile c-pointer" [matMenuTriggerFor]="menu">
            <span class="icon-respos-person respos-box-outline-r respos-header__avatar"></span>
            <!-- <span class="respos-header__user-name">{{userName}}</span> -->
        </div>
    </div>

    <mat-menu #menu="matMenu">
        <ul class="respos-header__user-menu">
            <!-- <li class="respos-ft c-pointer" [hidden]="true">
                <div lib-input-switch [label]="'Dark Mode' | translate" [model]="darkMode" (modelChange)="changeTheme($event)"></div>
            </li> -->
            <li class="respos-ft c-pointer" (click)="exitPOS()">{{'Exit POS' | translate}}</li>
            <li class="respos-ft c-pointer" (click)="logout()">{{'Logout' | translate}}</li>
        </ul>
    </mat-menu>
</header>