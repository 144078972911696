import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { loginState } from '@app/shared/models/SignalRDto';
import { SignalrService } from '@app/shared/services/signalr.service';
import { ComponentTypes, ICountNotificationMenus, Menu, PropertyType, RolesAndPermissionsType } from '@constants/commonenums';
import sideMenu from '@data/sidemenu.json';
import sidemenuforhost from '@data/sidemenuforhost.json';
import { LoginResultDTO, RestaurantAvailableForLoginDTO, RoleType } from '@models/LoginResultDTO';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import sidemenuforTennisProperty from '@data/sidemenuforTennisProperty.json';
import sidemenuforTheatreProperty from '@data/sidemenuforTheatreProperty.json';
import { Utilities } from '@app/shared/utilities/utilities';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { MatDialog } from '@angular/material/dialog';
import { PartyService } from '@app/shared/services/party.service';
import { CacheService } from '@app/core/services/cache.service';
import * as globals from '@constants/globalConstants';
import { AlertType, ButtonType } from '@app/common/enums/shared-enums';
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
import { LoginFunction } from '../../shared/utilities/login-function';
import { RolesAndPermissionPipe } from '@app/shared/pipes/RolesAndPermission.pipe';
import { RetailBussinessService } from '@app/shared/retail.bussiness';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AsideComponent extends Utilities implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  @ViewChild('sideMenu') sideMenu: ElementRef;
  public showScroll = false;
  public now: Date = new Date();
  public menuNames: any;
  menuHeight: string = '0px';
  smallTabScreen: number = 1279;
  countNotificationMenus = ICountNotificationMenus;
  asideHeight: any;
  asideScrollHeight: any;
  availableBtnSpace: any;
  disableUpArrow: boolean;
  disableDownArrow: boolean;
  propertyType: any;


  constructor(public _as: AppService, dialog: MatDialog, public ts: TranslateService,
    private router: Router, private renderer: Renderer2, private signalr: SignalrService, public cs: CacheService, private utilities: CommonUtilities, public ls: LoginFunction, private partyService: PartyService, private rolesAndPermission: RolesAndPermissionPipe,private rb: RetailBussinessService) {
    super(dialog);
    this.subscriptions.add(this._as._cs.state.subscribe((state) => {
      this._as.waitlistMenuCount = state.WalkIns.length;
      this.propertyType = _.cloneDeep(this._as.PropertyType);
    }));
    
  }

  /***
  * @function calculateHeight
  * @input Params nil
  * To enable or disable scrolling based on height
  */

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setSidePanel();
  }

  ngOnInit() {
    this.signalr.init();
    this.menuNames = this.filterMenuBasedOnProperty(sideMenu);
    this.menuNames.menus.forEach((menu) => {
      const formattedURL = this.router.url.includes('settings') ? this.router.url.slice(1, 9).toString() :
        this.router.url.slice(1).toString();
      if (formattedURL === menu.link) {
        this._as.selectedMenuId = menu.id;
        this._as.oldMenuId = menu.id;
      }
    });

  }

  ngAfterViewInit() {
    this.subscriptions.add(this._as.propertyChanged.subscribe(data =>{
      if(data){
      this.propertyType = this._as.PropertyType;
      this.menuNames = this.filterMenuBasedOnProperty(sideMenu);
      this.menuNames.menus.forEach((menu) => {
        const formattedURL = this.router.url.includes('settings') ? this.router.url.slice(1, 9).toString() :
          this.router.url.slice(1).toString();
        if (formattedURL === menu.link) {
          this._as.selectedMenuId = menu.id;
          this._as.oldMenuId = menu.id;
        }
      });
      this.calculateHeight();
      this._as.refreshURL = null;
      this.menuNames.menus.map((menu) => {
        if(this.router.url.split('/')[1] == menu.link) {
          this._as.refreshURL = this.router.url;
        }
      });
      this.ls.navigateToReservation();
      sessionStorage.removeItem('section');
      if(this.router.url === '/activities-timeline'){
        this._as.updateActivitiesTimeline.next(this.propertyType);
      }
    }
    }));
    this.calculateHeight();
    this.subscriptions.add(this.signalr.hubConnectionId$.subscribe(() => {
      this.signalr.switchScreen(null, this._as.selectedMenuId, loginState.login)
    }));
  }

  navigate(event, menuItem) {
    const hostRole = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    this.partyService.redirectPath = '';
    this.cs.slotFullScreen = false;
    if(this._as.showCreateActivity){
      this._as.showCreateActivity = false;
    }
    if (event.currentTarget.text == 'Logout') {
      this.logout();
    }
    else if (event.currentTarget.text.toLowerCase() == 'shop' && (!Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO) || !Utilities.isUserHasAccessToRetail(this._as.restaurantId))) {
      event.preventDefault();
      this.roleTypeAccessMessagePop();
      event.stopPropagation();
      return;
    }
    else {      
      if(event.currentTarget.text.toLowerCase() == 'shop'){
        this.rb.resetRetailVariables();
        this.rb.destroyObjects();
      }
      this.router.navigate(['./' + menuItem.link])
    }

  }

  roleTypeAccessMessagePop() {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant("userPermissionMsg"),
      dialogTitle: this.ts.instant('authFailed')
    }];
    let okbutton = this.ts.instant('ok');
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
      popUpMessage, popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.RoleTypeAccess, '450px', 'auto', true, '', okbutton,
      '', true);
  }

  setSelectedMenu(menuId: number) {
    if (!this._as.isCartPaymentInProgress) {
      this._as.showCartItem = false;

    }
    const oldScreenId = this._as.oldMenuId;
    this._as.selectedMenuId = menuId;
    this._as.showHelpScreen = false;
    // Condition added to ensure user not clicking the same menu
    if (this.signalr.selectedMenuId != this._as.selectedMenuId) {
      if (this.signalr) { // Getting latest revision
        // GetLatestRevision API is converted into webWorker
        this.signalr.getLatestRevision();
      }
      if (this.signalr.selectedMenuId >= 0) {
        this.signalr.switchScreen(oldScreenId, this._as.selectedMenuId, loginState.screenChange)
      }
    }
    this._as.triggerCurrentMenu$.next(menuId);
    this.partyService.TotalCartBookingObj?.forEach(cartItem => {
      cartItem.selected = false;
    });
  }
  calculateHeight() {
    this.asideHeight = document.getElementsByClassName('aside')[0].scrollHeight;
    this.setSidePanel();
    this.asideScrollHeight = this.sideMenu.nativeElement.scrollHeight;
    if (this.asideHeight > this.asideScrollHeight) {
      this.showScroll = true;
    } else {
      this.showScroll = false;

    }
  }

  setSidePanel() {
    let asideMenuElement = document.getElementsByClassName('aside__menu-list')[0] as HTMLElement;
    this.menuHeight = (asideMenuElement.offsetHeight) / (this.menuNames.menus.length - 1) + 'px';
  }

  viewPrevMenu() {
    // this.disableDownArrow = false;
    const element = this.sideMenu.nativeElement;
    const scrollValue = element.scrollTop - (106);
    element.scrollTop = scrollValue;

    if (scrollValue <= 0) {
      this.disableUpArrow = true;
      element.scrollTop = 0;
    } else {
      element.scrollTop = scrollValue;
    }
  }

  viewNextMenu() {
    // this.disableUpArrow = false;
    // this.disableDownArrow = true;
    const element = this.sideMenu.nativeElement;
    const scrollValue = element.scrollTop + (106);
    const scrollTop = element.scrollTop;
    const offsetHeight = element.offsetHeight;
    element.scrollTop = scrollValue;
    if (scrollTop + offsetHeight >= element.scrollHeight) {
      this.disableDownArrow = true;
    } else {
      element.scrollTop = scrollValue;
    }
  }

  filterMenuBasedOnProperty(sidemenu: any) {
   const restaurantsAvilable = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).RestaurantsAvailableForLogin;    
   const hostRole = restaurantsAvilable.find(restaurant => restaurant.Id == Utilities.RestaurantId())
    let fullMenuForManager = _.cloneDeep(sidemenu);
    let fullMenuForHost = _.cloneDeep(sidemenuforhost);
    let fullmenuforTennisProperty = _.cloneDeep(sidemenuforTennisProperty);
    let fullmenuforTheatreProperty = _.cloneDeep(sidemenuforTheatreProperty);
    /*  if (!Utilities.isRetailEnabledProperty(this._as._cs.settings.value.General.RetailIntegrationDTO)) {
       fullMenuForManager.menus = sidemenu.menus.filter(menu => menu.name !== 'shop');
       fullmenuforTennisProperty.menus = sidemenuforTennisProperty.menus.filter(menu => menu.name !== 'shop');
     } */
    if (Utilities.AnyVenue(this.propertyType)) {
      if(Utilities.IspropertyOfTypeTheatre(this._as.PropertyType)){
          this.menuNames = this.filterBasedOnRole(fullmenuforTheatreProperty, restaurantsAvilable)
      }else{
        if (hostRole.HostRoleType && hostRole.HostRoleType.Name === RoleType[RoleType.Manager]) {
          this.menuNames = this.filterBasedOnRole(fullMenuForManager, restaurantsAvilable)
        } else {
          this.menuNames = this.filterBasedOnRole(fullMenuForHost, restaurantsAvilable);
        }
      }
    }
    else {
      if (hostRole.HostRoleType && (hostRole.HostRoleType.Name === RoleType[RoleType.Host] || hostRole.HostRoleType.Name === RoleType[RoleType.Reservationist])) {
        fullmenuforTennisProperty.menus = fullmenuforTennisProperty.menus.filter(m => m.id == Menu.ActivitiesTimeline || m.id == Menu.Tables || m.id == Menu.GuestBook || m.id === Menu.Servers || m.id === Menu.Settings || m.id === Menu.Reports || m.id === Menu.Shop || m.id == Menu.Switch);
        if (fullmenuforTennisProperty.menus)
          this.menuNames = fullmenuforTennisProperty;
          this.menuNames = this.filterBasedOnRole(fullmenuforTennisProperty, restaurantsAvilable);
      } else {
        this.menuNames = this.filterBasedOnRole(fullmenuforTennisProperty, restaurantsAvilable  ); //sidemenuforTennisProperty;
      }
    }
    let isRetailEnabledProperty = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${Utilities.RestaurantId()}_restaurantSettings`))?.General.RetailIntegrationDTO.IsEnabled;
    if (!isRetailEnabledProperty) {
      this.menuNames.menus = this.menuNames.menus.filter(menu => menu.name !== 'shop');
    }

    let diningPropsAvailable : any = restaurantsAvilable.filter(x => Utilities.controlValidate(globals.controlSettings.SideMenu_CRS_VISIBLE, x.PropertyType));

    // || sessionStorage.getItem('IsMerchantUser') !== 'true'
    if(!diningPropsAvailable || !diningPropsAvailable.length){
      this.menuNames.menus = this.menuNames.menus.filter(menu => menu.name !== 'crsMenuText');
    }
    
    if(Utilities.controlValidate(globals.controlSettings.BookingCentral_Menu_Hide,this.propertyType)) {
      this.menuNames.menus = this.menuNames.menus.filter(menu => menu.name !== 'crsMenuText');
    }

    if(Utilities.controlValidate(globals.controlSettings.Hide_Reports_By_Permission, this.propertyType) && this.menuNames.menus.find(menu => menu.id === Menu.Reports) && hostRole.HostRoleType.Name !== RoleType[RoleType.Manager] && !this.rolesAndPermission.transform(RolesAndPermissionsType.Generatereports) && !this.rolesAndPermission.transform(RolesAndPermissionsType.ScheduleReports)){
      this.menuNames.menus = this.menuNames.menus.filter(menu => menu.id !== Menu.Reports)
    }

    return this.menuNames;
  }

  filterBasedOnRole(menu: any, restaurantAvailable:RestaurantAvailableForLoginDTO[]) {
    let diningPropsAvailable : any = restaurantAvailable.filter(x => !Utilities.controlValidate(globals.controlSettings.SideMenu_SwitchOption_Hide, x.PropertyType));
    if (!diningPropsAvailable || diningPropsAvailable.length <= 1 || Utilities.controlValidate(globals.controlSettings.SideMenu_SwitchOption_Hide, this._as.PropertyType)) {
      let indexOfSwitchProp = menu.menus.indexOf(menu.menus.filter(m => m.id == Menu.Switch)[0]);
      if (indexOfSwitchProp != -1)
        menu.menus.splice(indexOfSwitchProp, 1);
    }
    return menu;
  }
  

  logout(){
    if(this._as.showCartItem){
      this._as.showCartItem = false;
    }
    this.cs.locationListForMerchant = [];
    this.cs.LoadMultiplePropertiesServiceInit = false;
    sessionStorage.removeItem('section');
    this._as.logout();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

}
