<!-- 
   <mat-accordion>
    <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header class="">
    <mat-panel-title>
      {{header}}
    </mat-panel-title>
    </mat-expansion-panel-header>
 
        <div  class="message-container">
          
                <div class="msg-name">
                  <button disabled>KS</button>
                </div>
                <div class="msg-text">
                    <label><span>katherine</span> Send a message</label>
                    <p [style.height]="divheight">{{textletter | slice:0: lettercount }} <span *ngIf="textletter.length > 60" (click)="ReadMore(buttonname)" class="icon-arrow-down2 float-right">...{{buttonname}}</span></p>
                    <label class="m-2"><span class="icon-ic_access_time_24px m-2"></span>2 min ago</label>
                </div>                     
        </div>
    </mat-expansion-panel>
    </mat-accordion> -->

    <mat-accordion>
            <mat-expansion-panel *ngFor="let component of componentDetails">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Personal data
                </mat-panel-title>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
            <!-- <ng-container *ngComponentOutlet = "component.componentName; injector:component.input"></ng-container> -->
          </mat-accordion>