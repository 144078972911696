import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '@app/app.service';

@Pipe({ name: 'pageIcon' })

export class PageIconPipe implements PipeTransform {
  constructor(private as: AppService) { }
  transform(pageMethod: number): any {
    if (this.as.communicationList.filter(x => x.id == pageMethod)[0].icon != undefined) {
      return this.as.communicationList.filter(x => x.id == pageMethod)[0].icon;
    }
  }

}
