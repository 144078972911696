import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MailTypes, ContactType, AgContactConfig } from '../shared.modal';
import { RetailLocalization } from '../../common/localization/retail-localization';

@Component({
  selector: 'app-agys-mail-id',
  templateUrl: './agys-mail-id.component.html',
  styleUrls: ['./agys-mail-id.component.scss']
})
export class AgysMailIdComponent implements OnInit {
  customClass: string;
  errorMessage: string;
  contactForm: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  options: { id: number, description: string, type: string }[];
  optionClass: string;
  typeControlName: string;
  arrayName: string;
  showSwitches: boolean;
  mailTypes = MailTypes;
  captions: any;
  primarySwitchName: string;
  privateSwitchName: string;
  duplicateError: string;
  maxLength = 75;
  primaryLabel: string;
  privateLabel: string;
  isEmailDisabled:boolean;
  floatLabel: string;

  @Input('inputs')
  set inputOptions(value: AgContactConfig) {
    this.errorMessage = value.errorMessage ? value.errorMessage : this.captions.err_invalidEmailFormat;
    this.placeHolder = value.placeHolder ? value.placeHolder : this.captions.lbl_email;
    this.controlName = value.formControlName ? value.formControlName : 'emailId';
    this.typeControlName = value.typeControlName ? value.typeControlName : 'emailType';
    this.customClass = value.className ? value.className : 'ag_form-control--md';
    this.optionClass = value.typeClass ? value.typeClass : 'ag_form-control--xs';
    this.showSwitches = value.showSwitches;
    this.primaryLabel = value.primarySwitchLabel ? value.primarySwitchLabel : this.captions.lbl_PrimaryEmail;
    this.privateLabel = value.privateSwitchLabel ? value.privateSwitchLabel : this.captions.lbl_PrivateInformation;
    this.isEmailDisabled = value.disabled ? value.disabled : false;
    if (this.showSwitches) {
      this.primarySwitchName = value.isPrimaryName ? value.isPrimaryName : 'primaryEmail';
      this.privateSwitchName = value.isPrivateName ? value.isPrivateName : 'privateInformtionEmail';
    }
    this.contactForm = value.form;
    this.arrayName = value.formArrayName;

    if (!this.arrayName) {
      this.contactForm.get(this.controlName).disable();
    } else {
      const email = this.contactForm.get(this.arrayName) as UntypedFormArray;
      email.controls.forEach((obj) => {
        if (!obj.value[this.typeControlName] && obj.get(this.controlName)) {
          obj.get(this.controlName).disable();
        }
      });
    }
  }

  @Input('valuePatched')
  set ControlState(value: boolean) {
    if (!this.arrayName && !this.isViewOnly && this.contactForm.get(this.controlName).value) {
      this.contactForm.get(this.controlName).enable();
    } else {
      this.contactForm.get(this.controlName).disable();
    }
  }

  @Input('values')
  set SetFormArray(value) {
    if (value && this.arrayName) {
      this.patchOrAdd(value);
      this.mailFieldDisabler(this.isEmailDisabled);
    }
  }

  @Input() isViewOnly = false;

  constructor(private fb: UntypedFormBuilder, private localization: RetailLocalization) {
    this.captions = this.localization.captions.common;
    this.duplicateError = this.captions.err_duplicateEmail;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.options = this.getMailOptions();
  }

  ngOnChanges(){
    if(this.arrayName){
      this.mailFieldDisabler(this.contactForm.get(this.arrayName).disabled);
    }
  }

  mailFieldDisabler(isDisable){
    let mailField = this.contactForm.get(this.arrayName);
    if(isDisable){
      this.isEmailDisabled = true;
      mailField['controls'].forEach(element => {
        element.disable();
      });
    }
  }

  // Dynamic Email field addition Logic
  addEmailArray(index, emailLabel, emailid, isprimary?: boolean, isPrivate?: boolean, isSynced?:boolean): UntypedFormGroup {
    const currentForm = this.fb.group({
      contactType: ContactType.email,
      id: index,
      isSynced: isSynced
    });
    currentForm.addControl(this.typeControlName, new UntypedFormControl(emailLabel));
    currentForm.addControl(this.controlName, new UntypedFormControl({ value: emailid, disabled: !emailLabel }));
    if (this.showSwitches) {
      currentForm.addControl(this.primarySwitchName, new UntypedFormControl(isprimary));
      currentForm.addControl(this.privateSwitchName, new UntypedFormControl(isPrivate));
    }
    return currentForm;
  }
  addEmailItem(index, emailLabel: any, emailid: any, primaryEmail, privateInformtionEmail, isSynced): void {
    if (!this.isViewOnly) {
      const email = this.contactForm.get(this.arrayName) as UntypedFormArray;
      email.push(this.addEmailArray(index, emailLabel, emailid, primaryEmail, privateInformtionEmail, isSynced));
    }
  }

  removeEmailItem(index: number): void {
    if (!this.isViewOnly) {
      const email = this.contactForm.get(this.arrayName) as UntypedFormArray;
      this.contactForm.markAsDirty();
      email.removeAt(index);
    }
  }

  togglePrimaryContact(formGroupName: number) {
    const arr = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const ctrls = arr.controls.filter((x, idx) => idx !== formGroupName);
    ctrls.forEach(x => {
      const grp = x as UntypedFormGroup;
      grp.controls[this.primarySwitchName].setValue(false);
    });
  }

  typeChange(formGroup: UntypedFormGroup) {
    formGroup.get(this.controlName).enable();
  }

  private patchOrAdd(value) {
    const mail = this.contactForm.get(this.arrayName) as UntypedFormArray;
    value.forEach((obj, idx) => {
      const group = mail.controls[idx] as UntypedFormGroup;
      if (group) {
        if (!group.get('id')) {
          group.addControl('id', new UntypedFormControl());
        }
        if (!group.get('contactType')) {
          group.addControl('contactType', new UntypedFormControl(ContactType.email));
        }
        if (obj && obj[this.typeControlName] && !this.isViewOnly) {
          group.get(this.controlName).enable();
        }
        group.patchValue(obj);
      } else {
        const id = obj ? obj.id : '';
        const emailType = obj ? obj[this.typeControlName] : '';
        const emailId = obj ? obj[this.controlName] : '';
        const primaryEmail = obj ? obj.primaryEmail : false;
        const privateInformtionEmail = obj ? obj.privateInformtionEmail : false;
        const isSynced = obj? obj.isSynced: false;
        this.addEmailItem(id, emailType, emailId, primaryEmail, privateInformtionEmail, isSynced);
      }
    });
  }

  private getMailOptions() {
    return [
      { id: this.mailTypes.office, description: this.captions.drp_txt_office, type: ContactType.email },
      { id: this.mailTypes.personal, description: this.captions.drp_txt_personal, type: ContactType.email }
    ];
  }

}
