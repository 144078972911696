import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SettingsService } from '@app/shared/services/settings.service';
import { PricingCategory, SelectionType } from '@app/shared/models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppService } from '@app/app.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { controlSettings } from '@app/shared/constants/globalConstants';

@Component({
  selector: 'app-add-on-mapping',
  templateUrl: './add-on-mapping.component.html',
  styleUrls: ['./add-on-mapping.component.scss']
})
export class AddOnMappingComponent implements OnInit, OnDestroy {
  addonsFromGroup: UntypedFormGroup;
  @Input() CategoryAddon;
  @Input() PartySize;
  @Input() isEdit;
  @Input() isTemplateUsed;
  @Input() updateAddonSize;
  IsCategoryLevelMandatory: boolean = false;
  IsAllAddonsSelect: boolean = false;
  subscriptions: Subscription = new Subscription();
  displayCRSOption = false;
  constructor(private _fb: UntypedFormBuilder, private ss: SettingsService , private ts: TranslateService, private as : AppService) { }

  ngOnInit() {
    this.addOnConfig()
    this.addOnMappings();
    this.displayCRSOption = Utilities.controlValidate(controlSettings.Addon_For_CRS, this.as.PropertyType);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.PartySize.currentValue) {
      let size = changes.PartySize.currentValue
      if ((size?.MaxPartySize || size?.MinPartySize ) && this.addonsFromGroup && this.updateAddonSize) {
        this.addonsFromGroup.controls.addon_Rows['controls'].forEach(x => {
          if (x.value && x.value.SelectionType == SelectionType.PerGuest) {
            x.controls.MinQuantity.setValue(size?.MinPartySize, { emitEvent: false });
            x.controls.MaxQuantity.setValue(size?.MaxPartySize, { emitEvent: true });
          }
        })
      }
    }
  }
  addOnMappings() {
    if (this.CategoryAddon) {
      let addonList = this.CategoryAddon.SubCategories.map(addon => { return addon.AddOns }).flat();
      addonList.forEach(addon => {
        if (addon) {
          let addonMapping = addon.AddonActivitiesMappings?.find(type => type.AddonId == addon.AddonId);
          let minQuantity = 1;
          let maxQuantity = 1;
          if(!this.IsCategoryLevelMandatory && addonMapping.IsCategoryLevelMandatory){
            this.IsCategoryLevelMandatory = addonMapping.IsCategoryLevelMandatory;
          }
          if (addon.SelectionType == SelectionType.PerLesson) {
            minQuantity = 1;
            maxQuantity = 1;
          } else if (addon.SelectionType == SelectionType.PerGuest) {
            minQuantity = this.PartySize?.MinPartySize || 1;
            maxQuantity = this.PartySize?.MaxPartySize || 1;
          } else if (addon.SelectionType == SelectionType.PerBooking) {
            minQuantity = 1;
            maxQuantity = addon.AddOnDetails[0].Quantity;
          }
          if (addonMapping) {
            this.addonformArr.push(this.initAddonRows(addon, addonMapping, { minSize: minQuantity, maxSize: maxQuantity }));
          }
        }
      })
    }

  }

  ngAfterViewInit() {
    this.addonsFromGroup.controls.addon_Rows['controls'].forEach(x => {
     this.subscriptions.add( x.valueChanges.subscribe(data => {
        let IsCategoryLevelMandatory = this.IsCategoryLevelMandatory;
        if (data) {
          let addonMapping = this.ss.addOnsMappingList.map(category => {
            if (category.Id == data.CategoryId) {
              category.SubCategories.map(subCategory => {
                if (subCategory.Id == data.SubCategoryId) {
                  subCategory.AddOns.map(addOn => {
                    if (addOn.AddonId == data.AddonId) {
                      addOn.AddonActivitiesMappings.map(mapping => {
                        if (mapping.AddonId == data.AddonId) {
                          mapping.IsMandatory = data.IsMandatory;
                          mapping.IsCategoryLevelMandatory = IsCategoryLevelMandatory;
                          mapping.IsVisible = data.IsVisible;
                          mapping.MinQuantity = data.MinQuantity;
                          mapping.MaxQuantity = data.MaxQuantity;
                          mapping.IsSelected = data.IsSelected;
                          mapping.AvailableForWebReservation = data.AvailableForWebReservation;
                          mapping.AvailableForCRSReservation = data.AvailableForCRSReservation;
                        }
                        return mapping
                      })
                    }
                    return addOn;
                  })
                }
                return subCategory
              })
            }
            return category
          }
          )
          this.ss.addOnsMappingList = addonMapping;
        }
        if(this.addonsFromGroup.controls.addon_Rows['controls'].length == this.addonsFromGroup.controls.addon_Rows['controls'].filter(form => form.value.IsSelected)?.length) {
          this.IsAllAddonsSelect = true
        }
        else {
          this.IsAllAddonsSelect = false
        }
      }))
    })
  }
  addOnConfig() {
    this.addonsFromGroup = this._fb.group({
      addon_Rows: this._fb.array([])
    })
  }
  get addonformArr() {
    return this.addonsFromGroup.get("addon_Rows") as UntypedFormArray;
  }
  initAddonRows(addon, mappings, size) {
    return this._fb.group({
      AddonId: mappings.AddonId,
      ActivityId: mappings.ActivityId,
      AvailableForWebReservation: mappings.AvailableForWebReservation,
      AvailableForCRSReservation: mappings.AvailableForCRSReservation,
      IsMandatory: mappings.IsMandatory,
      IsCategoryLevelMandatory: mappings.isCategoryLevelMandatory,
      IsVisible: (this.isEdit || this.isTemplateUsed ) ? mappings.IsVisible  :  true,
      MinQuantity:(addon.AddOnDetails[0]?.IsUnlimited && mappings.MinQuantity == 0 && !this.isEdit  ) ? 1 : (mappings.MinQuantity || size.minSize),
      MaxQuantity:(addon.AddOnDetails[0]?.IsUnlimited && mappings.MaxQuantity == 0 && !this.isEdit  ) ? 1 : (mappings.MaxQuantity || size.maxSize),
      ConsiderForAvailability: mappings.ConsiderForAvailability,
      IsSelected: mappings.IsSelected,
      AddOnName: addon.AddonName,
      SubCategoryId: addon.SubCategoryId,
      CategoryId: addon.CategoryId,
      addOnType: this.getAddOnType(addon.SelectionType),
      SelectionType: addon.SelectionType,
      Inventory: addon.AddOnDetails[0].IsUnlimited ? this.ts.instant('UnlimitedInventory') : addon.AddOnDetails[0].Quantity
    })
  }

  getAddOnType(selectionType) {
    let addonType = '';
    if(selectionType == SelectionType.PerLesson) {
      addonType = this.ts.instant('PerLesson');
    }
    else if (selectionType == SelectionType.PerBooking) {
      addonType = this.ts.instant('PerBooking');
    }
    else if(selectionType == SelectionType.PerGuest) {
      addonType = this.ts.instant('PerGuest');
    }
    return addonType;
  }
  setMandatoryAddon(event) {
    this.IsCategoryLevelMandatory = event.checked;
    this.addonsFromGroup.controls.addon_Rows['controls'].forEach(x => {
      // x.controls.IsMandatory.setValue(event.checked, { emitEvent: false });
      x.controls.AvailableForWebReservation.setValue(event.checked, { emitEvent: true });
      x.controls.AvailableForCRSReservation.setValue(event.checked,{ emitEvent: true });
    }
    )
  }
  selectAllItems(event) {
    this.addonsFromGroup.controls.addon_Rows['controls'].forEach(x => {
      x.controls.IsVisible.setValue(event.checked, { emitEvent: false });
      x.controls.IsSelected.setValue(event.checked, { emitEvent: true });
    }
    )
    if(event)
    this.IsAllAddonsSelect = event.checked;
  }
  validateAddonQuantity(event){
    if(Number(event.target.value)  <= Number(this.PartySize?.MaxPartySize)  || event.target.value == ''){
      event.target.value = this.PartySize?.MaxPartySize
    }
  }

  ngOnDestroy(): void {
    if(this.subscriptions){
      this.subscriptions.unsubscribe();
    }
  }
}
