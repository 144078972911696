import { TitleCasePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { DataRetentionPoliciesComponent } from '@app/reservation/data-retention-policies/data-retention-policies.component';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ContactNotes } from '@app/shared/models/global.interface';
import { buttonTypes, ComponentTypes, PaymentMethod, ReservationType, SlottingType } from '@constants/commonenums';
import { controlSettings, fieldType, preferences } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { FormChipService } from '@dynamicform/components/form-chip/form-chip.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { DynamicFormService } from '@dynamicform/service/dynamic-form.service';
import { ContactNoteDTO, ContactVisitStatsDTO, CountryDTO, CustomFieldsDTO, FullContactDTO, GuestTitle, PageMethods, TitleList } from '@models/InputContact';
import { Category, DataRetentionPolicy, NullableIdNamedEntityDTO, PageMethod, RequiredPartyFields, SecondaryContactDTO, SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { FacadeService } from '@services/facade.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import _, { cloneDeep } from 'lodash';
import _remove from 'lodash/remove';
import moment from 'moment';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Subscription } from 'rxjs/Subscription';
import * as globals from '@constants/globalConstants';
import { CustomGuestFields } from '../CustomGuestField';
import { Utilities } from '@utilities/utilities';
import { CapitalizeFirstCharacterInText } from '@app/shared/pipes/guestname-initial-formatter.pipe';
import { FormTextAreaService } from '@app/shared/dynamicform/components/form-textarea/form-textarea.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-view-selected-guest',
  templateUrl: './view-selected-guest.component.html',
  styleUrls: ['./view-selected-guest.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewSelectedGuestComponent extends CustomGuestFields implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() guestDetails: FullContactDTO;
  @Input() tabIndex: number;
  @Input() isEdit: boolean;
  @Input() fromCart: boolean;
  @Input() isAddNewGuest: boolean;
  @Input() partySourceId: number;
  @Input() partyId: number;
  @Input() partyEmailAndPhoneInfo;
  @Output() guestChange: EventEmitter<any> = new EventEmitter();
  @Output() cartGuestEdit : EventEmitter<any> = new EventEmitter();
  @ViewChild('guestDetailsForm') dynamicForm: DynamicFormComponent;
  @ViewChild('retentionForm') retentionForm: DynamicFormComponent;
  guestSecondary: ButtonValue;
  guestSecondaryEdit: ButtonValue;
  addSecondaryGuestButton: ButtonValue;

  retentionConfig: FieldConfig[] = [];
  countryCodes: { id: string; value: string; countryCode: string; countryId: number}[];
  tagsList: any;
  selectedPreferenceTags: any[] = [];
  dataRetentionPolicy: any = [];
  retentionCheckboxVisibility = false;
  existingReservation = false;
  defaultPartyPageMethod: PageMethod;
  guestLastVisitedDate: Date;
  _retentionPolicy: DataRetentionPolicy[] = [] as DataRetentionPolicy[];
  contactGuestFields = [];
  formUpdationCount = 0;
  isErrorMessageAvailable = true;
  selectedPartyPreferenceTags: any[] = [];
  communicationTagList: any[] = [];
  guestLastTable: string;
  guestLastServer: string;
  isMembershipAvailable = false;
  inputSearchString: any;
  showSwap = false;
  notFirstTime: boolean;
  file: any;
  vipStatus: boolean = false;
  isStatsInCurrentRest: boolean = false;
  isStatsInOtherRest: boolean = false;
  statsInCurrentRest: ContactVisitStatsDTO;
  statsInOtherRests: ContactVisitStatsDTO[] = [];
  subscriptions: Subscription = new Subscription();
  guestNotes: ContactNotes[] = [];
  categories: Category[] = [];
  selectedNotesByCategory: any;
  expansionPanel = {};
  isSecondaryGuestDetailsValid = true;
  secondaryContacts: SecondaryContactDTO[] = [];
  isPartySourceRestWebSite = false;
  othersId: number[] = [];
  phoneNumberLength: number;
  mobileNumberLength: number;
  isFreeTextTagAvailable: boolean = false;
  freeTextNotes: ContactNoteDTO[] = [];
  previousContactCustomField: any[] = []
  editDefaultCommn = false;
  AllowAddingSecondaryGuest: boolean;
  showAliasName: boolean;

  constructor(public facadeService: FacadeService, private dfs: DynamicFormService, private formChipService: FormChipService, private formTextAreaService: FormTextAreaService,
    public partyService: PartyService, private cdf: ChangeDetectorRef, private popupService: PopupService, public gbs: GuestBookService,
    private ts: TranslateService, private dialog: MatDialog, private appService: AppService, public cs: CacheService, private titleCasePipe: TitleCasePipe,
    private imageCompress: NgxImageCompressService, public capitalizeText: CapitalizeFirstCharacterInText) {
    super(partyService, appService.PropertyType)
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
      if (sett.customGuestFieldsMappingValidations) {
        this.customGuestFieldsMappingValidations = sett.customGuestFieldsMappingValidations;
      }
      this.categories = this._settings.Categories.filter(category => category.Text != "Reasons");//added after confirmation
      this.isMembershipAvailable = this._settings.General.UseMembershipNumber;
      if (this.notFirstTime) {
        if (this.isEditGuest) {
          this.selectGuestNotes();
        } else {
          this.loadGuestConfig();
        }
      }
      this.notFirstTime = true;
      this.editDefaultCommn = sett.General.DefaultPageMethod == PageMethods.None;
    }));
    this.subscriptions.add(partyService.bookingsCount$.subscribe(data => {
      if (data) {
        this.changeBookingCount()
      }
    }));
    this.subscriptions.add(cs.dataRetentionPolicy.subscribe(policy => {
      this._retentionPolicy = policy;
    }));
  }

  ngOnInit() {
    
    if(this.partyService.reservationType == ReservationType.Waitlist){
      this.partyService.bookingSize = +this.partyService.reservationFormGroup.controls.selectedSize.value;
    }
    
    if (this.guestDetails && this.guestDetails.Notes) {
      this.isFreeTextTagAvailable = !!(this.guestDetails.Notes.filter(note => !note.RelatedId).length);
      this.freeTextNotes = cloneDeep(this.guestDetails.Notes.filter(note => !note.RelatedId));      
    }
    this.dfs.requiredPhoneNumberLength$ = null;
    this._settings.PartySources.filter(partySource => partySource.Id === this.partySourceId).length > 0 ?
      this.isPartySourceRestWebSite = true : this.isPartySourceRestWebSite = false;
    if (this.guestDetails?.SecondaryContactsList && this.guestDetails.SecondaryContactsList.length > 0) {
      this.secondaryContacts = this.guestDetails.SecondaryContactsList.filter(sc=>sc.ContactId != this.guestDetails.Id);
    }
    if (this.partyService.isNewGuestFromReservation) {
      this.isAddGuest = true;
      this.partyService.isExistingContactQS = false;
    }
    else {
      this.partyService.isExistingContactQS = true;
    }
    this.setAdditionalGuestSetting();
    const retentionPolicy = this._retentionPolicy;
    if (this._settings.General.IsGDPREnabled && retentionPolicy) {
      this.dataRetentionPolicy = retentionPolicy;
      this.partyService.consentPolicyId = this.dataRetentionPolicy && this.dataRetentionPolicy.length > 1 ? this.dataRetentionPolicy[1].Id : null;
      this.dataRetentionPolicy[1].PolicyDetails.forEach((policy) => {
        const retentionDays = policy.RetentionInDays;
        if (retentionDays > 0 && (!this.isEdit || this.fromCart)) {
          this.retentionCheckboxVisibility = true;
        }
      });
    }
    this.existingReservation = false;
    if (!this.isAddGuest && this.partyService.selectedParty$.value) {
      this.existingReservation = true;
    }
    if (this._settings.General) {
      this.defaultPartyPageMethod = this._settings.General.DefaultPageMethod;
    }
    this.countryCodes = Utilities.getCountryCodes(this._settings);
    this.guestSecondary = {
      type: buttonTypes.actionSecondarySmall,
      label: 'changeGuestText',
      customclass: 'reservation-guest__change-guest-btn'
    };
    this.guestSecondaryEdit = {
      type: buttonTypes.actionSecondarySmall,
      label: 'editGuest',
      customclass: 'reservation-guest__change-guest-btn'
    };
    let addSecondaryGuestButtonDisabled = true;

  if (this.secondaryContacts.length > 0 || this.AllowAddingSecondaryGuest) {
      addSecondaryGuestButtonDisabled = !(this.AllowAddingSecondaryGuest && this.partyService.bookingSize > this.secondaryContacts.length + 1);
    }
    this.addSecondaryGuestButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'addGuest',
      customclass: 'reservation-guest__change-guest-btn',
      disbaledproperity: addSecondaryGuestButtonDisabled
    }
    this.showAliasName =   JSON.parse(sessionStorage.getItem('ShowAliasName'));
    this.loadGuestConfig();
    this.guestStats();
  }

  guestStats() {
    if (this.guestDetails && this.guestDetails.VisitStats) {
      this.statsInCurrentRest = this.guestDetails.VisitStats.find((x: any) => x.RestaurantId === this.appService.restaurantId);
      this.isStatsInCurrentRest = this.statsInCurrentRest && (this.statsInCurrentRest.NumberOfCancellations != 0 || this.statsInCurrentRest.NumberOfNoShows != 0 || this.statsInCurrentRest.NumberOfVisits != 0);
      this.statsInOtherRests = this.guestDetails.VisitStats.filter((x: any) => x.RestaurantId !== this.appService.restaurantId);
      this.statsInOtherRests = this.statsInOtherRests.filter(
        (stat, i, arr) => arr.findIndex(t => t.RestaurantId === stat.RestaurantId) === i
      );
      this.isStatsInOtherRest = this.statsInOtherRests && (this.statsInOtherRests.length > 0);
      this.statsInOtherRests = _.uniqBy(this.statsInOtherRests, (history) => {
        return history.NumberOfCancellations && history.NumberOfNoShows && history.NumberOfVisits && history.TotalSpend;
      })
    }
  }

  loadGuestConfig() {
    this.setFormConfig();
    if (!this.isMembershipAvailable) {
      _remove(this.guestConfig, x => x.name === 'membershipCode');
      _remove(this.guestConfig, x => x.name === 'membershipname');
    }
    else if (this.checkMemberTypeVisibility()) {
      _remove(this.guestConfig, x => x.name === 'membershipname');
    }
    this.setCustomFields(false);
    if (!this.isAddGuest && this.dynamicForm && this.dynamicForm.form) {
      this.isEditGuest ? this.mapOtherNotes() : this.selectGuestNotes();
    }
    // retention policy must always be the last feild
    if (this.retentionCheckboxVisibility && (this.isAddNewGuest || this.fromCart)) {
      this.retentionConfig = [{
        type: 'checkbox',
        placeholder: this.ts.instant('dataRetentionPolicyLabel'),
        value: this.fromCart ? !!this.guestDetails.ConsentPolicyId : false,
        name: 'dataRetentionPolicy',
        class: 'dataRetentionCheckBox',
        addHyperlink: true,
        hyperLinkText: this.ts.instant('dataRetentionPolicy'),
        hyperLinkClicked:(event) => this.ShowPolicyDetails(),
      }];
    } else if (this.retentionCheckboxVisibility && this.isEditGuest) {
      this.guestConfig = this.guestConfig.filter(item => item.name != "dataRetentionPolicy");
      this.retentionConfig = [];
    }
  }

  ngAfterViewInit() {
    const countries = this._settings.Countries;
    if (Object.keys(this.guestDetails).length !== 0 && (this.guestDetails?.FirstName || this.guestDetails?.LastName)) {
      if (this.guestDetails.VisitStats) {
        const visitStatDetails = this.guestDetails.VisitStats.filter((visitDetails) =>
          visitDetails.RestaurantId === this.appService.restaurantId);

        if (visitStatDetails.length > 0) {
          this.guestLastVisitedDate = visitStatDetails[0].LastVisitedAt;
          this.guestLastTable = visitStatDetails[0].LastTableNames;
          this.guestLastServer = visitStatDetails[0].LastServerName;
        }
      }

      const { FirstName, LastName, AliasName, PhoneNumber, PhoneNumber2, EmailAddress, TrackMembershipNumber, CountryPhoneCode, CountryId, IsVip, Birthday, Anniversary, Photo, GuestTitle, EmailAddress2, CountryPhoneCode2, CountryId2 } = this.guestDetails;
      const Membershipname = this.guestDetails.MembershipType?.AllocationName;
      let countryPhoneCode: CountryDTO;
      let countryPhoneCode2: CountryDTO;
      let Uniquecountry = CountryId ? CountryId : CountryPhoneCode;
      let Uniquecountry2 = CountryId2 ? CountryId2 : CountryPhoneCode2;

      if (Uniquecountry) {
        countryPhoneCode = countries.filter((country: CountryDTO) => country.CountryPhoneCode === Uniquecountry)[0];
        if (!countryPhoneCode)
          countryPhoneCode = (this._settings.Countries as CountryDTO[]).filter((country: CountryDTO) => {
            return country.Id == Uniquecountry
          })[0];
      }

      if (Uniquecountry2) {
        countryPhoneCode2 = (this._settings.Countries as CountryDTO[]).filter((country: CountryDTO) => {
          return country.CountryPhoneCode === Uniquecountry2
        })[0];
        if (!countryPhoneCode2)
          countryPhoneCode2 = (this._settings.Countries as CountryDTO[]).filter((country: CountryDTO) => {
            return country.Id == Uniquecountry2
          })[0];
      }
      TrackMembershipNumber ? this.makeMemberShipVisible(true) : this.makeMemberShipVisible(false);
      this.dfs.selectedCountryCode = (countryPhoneCode && countryPhoneCode.CountryPhoneCode) || this.dfs.selectedCountryCode;
      this.dfs.selectedCountryId = (countryPhoneCode && countryPhoneCode.Id) || this.dfs.selectedCountryId;
      let notes = [];
      if (this.guestDetails.Notes) {
        notes = this.guestDetails.Notes.length > 0 && this.guestDetails.Notes.filter((note) => note.RelatedId === null);
      }
      //this.selectedNotesByCategory = Utilities.mapNotesByCategory(this.guestNotes, this.guestDetails.Notes);
      this.selectGuestNotes();
      this.mapCustomFieldsData();
      this.dynamicFormChange(countries, notes);
      this.dynamicForm.form.patchValue({
        firstName: this.capitalizeText.transform(FirstName),
        lastName:this.capitalizeText.transform( LastName),
        aliasName: this.capitalizeText.transform(AliasName),
        mobileCode: countryPhoneCode ? countryPhoneCode.Name : this.guestConfig.filter(config => config.name == "mobileCode")[0].value,
        secondaryMobileCode: countryPhoneCode2 ? countryPhoneCode2.Name : this.guestConfig.filter(config => config.name == "secondaryMobileCode")[0].value,
        mobile: PhoneNumber,
        phone: PhoneNumber2,
        email: EmailAddress,
        secondaryEmail: EmailAddress2,
        membershipCode: TrackMembershipNumber,
        membershipname: Membershipname,
        dob: Birthday,
        anniversary: Anniversary,
        internalNotes: notes.length > 0 ? notes[0].Text : '',
        guestTitle: GuestTitle        
      });
      this.vipStatus = IsVip;

      this.dynamicForm.form.addControl('photo', new UntypedFormControl(''));
      this.dynamicForm.form.addControl('vipStatus', new UntypedFormControl(IsVip));

      this.dynamicForm.form.addControl('shouldUpdatePhoto', new UntypedFormControl(false));
      if (this.retentionCheckboxVisibility) {
        this.dynamicForm.form.get('dataRetentionPolicy').setValue(false);
      }
      this.mobileNumberValidation();
      this.phoneNumberValidation();
      this.cdf.detectChanges();
    } else {
      this.guestDetails = new FullContactDTO();
      if(!this.editDefaultCommn)
      this.guestDetails.PreferredPageMethod = Utilities.mapDefaultPageMethodForProperty(this.cs.settings.value.General.DefaultPageMethod);
      this.guestDetails.Notes = [];
      this.guestDetails.ContactCustomFields = [];
      this.dynamicFormChange(countries);

      if (this.gbs.searchStringForNewGuest) {
        this.inputSearchString = _.cloneDeep(this.gbs.searchStringForNewGuest);
        let tempPhoneNumber = this.getPhoneNumberFromSearchString(this.inputSearchString);
        let tempEmail = this.getEmailFromSearchString(this.inputSearchString);
        let tempMemberShipNumber = this.getTrackMembershipNumberFromSearchString(this.inputSearchString);
        let firstNameString: string = "";
        let lastNameString: string = "";
        this.inputSearchString = this.inputSearchString.trim();
        const incomingStringSplit: [string] = this.inputSearchString.split(/\s+/);

        if (incomingStringSplit.length == 1) {
          lastNameString = incomingStringSplit[0];
        }
        else if (incomingStringSplit.length > 1) {
          firstNameString = incomingStringSplit[0];
          incomingStringSplit.shift();
          lastNameString = incomingStringSplit.join(' ');
        }

        if ((firstNameString || lastNameString) && !this.partyService.reservationFormGroup.dirty) {
          this.partyService.reservationFormGroup.markAsDirty();
        }
        this.dynamicForm.form.patchValue({
          firstName: this.capitalizeText.transform(firstNameString),
          lastName: this.capitalizeText.transform(lastNameString),
          mobile: tempPhoneNumber,
          email: tempEmail,
          membershipCode: tempMemberShipNumber,
          mobileCode: this.guestConfig.filter(config => config.name == "mobileCode")[0].value,
          secondaryMobileCode: this.guestConfig.filter(config => config.name == "secondaryMobileCode")[0].value,
          });
        if (tempPhoneNumber) {
          this.mobileNumberValidation();
        }
      } else {
        this.dynamicForm?.form.patchValue({
          mobileCode: this.guestConfig.filter(config => config.name == "mobileCode")[0].value,
          secondaryMobileCode: this.guestConfig.filter(config => config.name == "secondaryMobileCode")[0].value,
        });
      }
      this.processNameValidity(this.dynamicForm?.form.value);
      this.popupService.saveBtnEnable$.next(this.partyService.reservationFormGroup.value.selectedGuest);
    }
    this.subscriptions.add(this.partyService.reservationFormGroup.valueChanges.subscribe(val => {
      let options = this.guestConfig.length > 0 ? this.guestConfig.filter(config => config.name == "pageMethod")[0].options : [];
      if (options) {
        options.forEach(element => {
          element.toolTipData = (element.Id == PageMethod.Manual && element.setSelected && val.selectedPagerNumber != '') ?
            (element.toolTipData = this.ts.instant('pageParty') + ': ' + val.selectedPagerNumber) : '';
        });
      }

    
      if(this.partyService.reservationType == ReservationType.Waitlist){
        this.partyService.bookingSize = +this.partyService.reservationFormGroup.controls.selectedSize.value;
      }
      
     if (val.selectedSize && this.isEdit && (this.guestDetails.SecondaryContactsList && this.guestDetails.SecondaryContactsList.length > 0 || this.AllowAddingSecondaryGuest)) {
        this.addSecondaryGuestButton.disbaledproperity = !(this.AllowAddingSecondaryGuest && (parseInt(val.selectedSize) > this.guestDetails.SecondaryContactsList.length + 1));
      }
    }));
    this.showSwap = !this.dynamicForm.value.firstName && !this.dynamicForm.value.lastName ? false : true;
    if (this.retentionForm && this.retentionForm.form) {
      this.subscriptions.add(this.retentionForm.form.valueChanges.subscribe((data) => {
        this.partyService.dataRetentionPolicyChecked = data.dataRetentionPolicy;
      }));
    }
    this.subscriptions.add(this.partyService.selectGuestSearch$.subscribe(data => {
      if (data) {
        this.partyService.reservationFormGroup.get('selectedGuest').setValue(data);
        if (this.dynamicForm && !this.dynamicForm.form.valid && !this.isEditGuest && !this.isAddGuest) {
          this.dynamicForm.form.markAllAsTouched();
          this.editGuest();
        }
      }
    }))
    this.partyService.reservationFormGroup.controls.selectedSpecialMeal.valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).subscribe(data => {
      this.setCustomFieldBasedonActivity();
      this.setAdditionalGuestSetting();
    });
    this.subscriptions.add(this.partyService.activityChanges$.subscribe(activity => {
      if (this.isEditGuest || this.isAddGuest) {
        this.setCustomFieldBasedonActivity();
      }
      this.setAdditionalGuestSetting();
      this.partyService.activityChanges$?.subscribe();
    }))
    this.partyService.paymentMethodChanges$.subscribe((data) => {
      if (data)
        this.enableBookAction();
    })
    this.subscriptions.add(this.partyService.tabChange$.subscribe(data =>{
      if (this.secondaryContacts.length > 0 || this.AllowAddingSecondaryGuest) {
        this.addSecondaryGuestButton.disbaledproperity = !(this.AllowAddingSecondaryGuest && this.partyService.bookingSize > this.secondaryContacts.length + 1);
      }
    }));
  }
  setAdditionalGuestSetting() {
    if (this.partyService.reservationType == ReservationType.OpenBooking || this.partyService.reservationType == ReservationType.Reservation || this.partyService.reservationType == ReservationType.Waitlist || this.partyService.reservationType == ReservationType.StandbyParties) {
      this.AllowAddingSecondaryGuest = this._settings?.PropertySetting[0]?.AllowAddingSecondaryGuest;
      this.getGuestFieldVisibilityMapping();
    } else {
      this.AllowAddingSecondaryGuest = this._settings.SpecialMeals.find(meal => meal.Id == this.partyService.selectedSpecialMealId)?.AllowAddingSecondaryGuest;
      this.getGuestFieldVisibilityMapping(this.partyService.selectedSpecialMealId);
    }
    if (this.AllowAddingSecondaryGuest) {
      let fields = Object.entries(this.SecondaryGuestFieldVisibility);
      this.AllowAddingSecondaryGuest = fields?.find(field => field[1] == true) ? true : false;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    const Changed: SimpleChange = changes.guestDetails;
    if (Changed && changes.guestDetails) {
      if (changes.guestDetails.currentValue) {
        this.guestDetails = changes.guestDetails.currentValue;
      }
      if (this.guestDetails?.ContactCustomFields) {
        this.previousContactCustomField = cloneDeep(this.guestDetails.ContactCustomFields)
      }
      if (this.guestDetails?.SecondaryContactsList) {
        this.secondaryContacts = this.guestDetails.SecondaryContactsList.filter(sc=>sc.ContactId != this.guestDetails.Id)
        this.changeBookingCount();
      };
    }
  }

  mapOtherNotes() {
    const preferredTags = this.guestConfig.filter((fieldConfig) => fieldConfig.name.includes('preferredTags'));
    this.guestDetails.Notes.forEach((note) => {
      preferredTags.forEach((fieldConfig) => {
        const filteredNotes = fieldConfig.options.find(data => data.Id === note.RelatedId);
        if (filteredNotes) {
          let currNoteId;
          if (!filteredNotes.noteId) {
            filteredNotes.setSelected = true;
            filteredNotes.noteId = currNoteId = note.Id;
          } else {
            if (!filteredNotes.extraNotesIds) filteredNotes.extraNotesIds = [];
            currNoteId = note.Id;
            filteredNotes.extraNotesIds.push(note.Id);
          }
          if (filteredNotes.Name.includes('Others')) {
            let otherNotesName = `otherNotes${filteredNotes.Id}`;
            if (filteredNotes.setSelected) {
              const otherNotes: FieldConfig = {
                name: otherNotesName + '$multinotes' + note.Id,
                type: 'textarea',
                inputType: 'text',
                label: 'notes',
                showHint: true,
                class: 'guest-view__other-tags',
                charLength: 1000,
                value: note.Text,
                showRemove: true
              };
              if(note.Id) {
              const preferredTagsIndex = this.guestConfig.findIndex(preferenceConfig => preferenceConfig.name == fieldConfig.name);
              // this.guestConfig = this.guestConfig.filter((fieldConfig) => fieldConfig.name == otherNotesName);
              this.guestConfig.splice(preferredTagsIndex + 1, 0, otherNotes);
              this.dynamicForm.form.addControl(otherNotesName, new UntypedFormControl(note.Text));
              }
            }
          }
        }
      })
    });
    this.guestConfig = [...this.guestConfig];
  }

  setswapWidth() {
    let elmnt = document.getElementsByClassName("guest-view__first-name")[0];
    let width = 0;
    if (elmnt) {
      width = elmnt.clientWidth;
    } else {
      elmnt = document.getElementsByClassName("guest-view__first-name-edit")[0];
      if (elmnt)
        width = elmnt.clientWidth - 30;
    }

    return width;
  }
  makeMemberShipVisible(visiblity: boolean) {
    let tempGuestConfig = this.guestConfig.filter(x => x.name === 'membershipCode');

    if (tempGuestConfig.length > 0) {

      if (visiblity) {

        if (tempGuestConfig[0].class.includes('-edit')) {
          tempGuestConfig[0].class = tempGuestConfig[0].class.slice(0, -5);
        }
        else {
          tempGuestConfig[0].class = 'guest-view__memberCode';
        }
      }
      else {

        if (!(tempGuestConfig[0].class.includes('-edit'))) {
          tempGuestConfig[0].class = 'guest-view__memberCode-edit';
        }
      }
    }
  }

  getPhoneNumberFromSearchString(searchString) {
    let phoneNumber = null;
    let matchResult = searchString.match(/(^|\s)([\s0-9()-]){2,}($|\s)/);
    phoneNumber = matchResult ? matchResult[0].trim() : null;

    if (matchResult) {
      this.inputSearchString = searchString.replace(matchResult[0], ' ');
    }
    return phoneNumber;
  }

  getEmailFromSearchString(searchString) {
    let email = null;
    let matchResult = searchString.match(/[^@\s]*@[^@\s]*/);
    email = matchResult ? matchResult[0] : null;

    if (matchResult) {
      this.inputSearchString = searchString.replace(matchResult[0], ' ');
    }
    return email;
  }

  getTrackMembershipNumberFromSearchString(searchString) {
    let memberShipId = null;
    let matchResult = searchString.match(/[\S]*[^a-zA-Z\s][\S]*/);
    memberShipId = matchResult ? matchResult[0] : null;

    if (matchResult) {
      this.inputSearchString = searchString.replace(matchResult[0], ' ');
    }
    return memberShipId;
  }

  selectGuestNotes() {
    this.selectedPreferenceTags = [];
    const preferredTags = this.guestConfig.filter(config => config.name.includes("preferredTags"));
    this.guestDetails.Notes?.forEach((note) => {
      preferredTags.forEach((tags) => {
        const filteredNotes = tags.options.find((data) => data.Id === note.RelatedId);
        if (filteredNotes) {
          filteredNotes.setSelected = true;
          this.selectedPreferenceTags.push(filteredNotes);
        }
      });
    });
    if (this.isFreeTextTagAvailable) {
      const freeTags = this.guestConfig.filter(config => config.name == 'freeTags');
      if (freeTags && freeTags.length) {
        this.freeTextNotes.forEach((freeNote) => {
          const filteredFreeNotes = freeTags[0].options.find((data) => data.Id === freeNote.Id);
          if (filteredFreeNotes) {
            filteredFreeNotes.setSelected = true;
          }
        });
      }
    }

    this.partyService.selectedMemberGuestPreferences?.forEach(preferences => {
      preferredTags.forEach((tags) => {
        const filteredNotes = tags.options.find((data) => data.Id === preferences.key || data.Text === preferences.value);
        if (filteredNotes) {
          filteredNotes.setSelected = true;
          this.selectedPreferenceTags.push(filteredNotes);
        }
      });
    });
  }
  setCustomFieldBasedonActivity() {
    if (this.guestConfig) {
      this.specialMealSelectAfterGuestEdit = true;
      this.guestConfig = this.guestConfig.filter(field => !field.name.includes('customField'))
      this.setCustomFields(false)
      if (this.guestConfig.filter(field => field.name.includes('customField'))?.length > 0) {
        setTimeout(() => {
          this.mapCustomFieldsData();
        }, 1000)
        this.partyService.reservationFormGroup.updateValueAndValidity();
      }
    }
    setTimeout(() => {
      this.validateFormGroup();
    }, 2000)
  }

  mapCustomFieldsData() {
    this.contactGuestFields = this.specialMealSelectAfterGuestEdit ? this.previousContactCustomField : this.guestDetails.ContactCustomFields;
    this.guestConfig.forEach((data, index) => {
      if (data.name.includes('customField')) {
        const customFieldValue = this.customFields.filter((field) => field.Id === data.id);
        const contactCustomField = this.contactGuestFields && this.contactGuestFields.
          filter((contact) => contact.CustomFieldId === customFieldValue[0].Id);
        if (customFieldValue[0].FieldType === fieldType[0] && this.dynamicForm.form.get(data.name)) {
          this.dynamicForm.form.get(data.name).setValue(
            (contactCustomField && contactCustomField.length > 0) ? contactCustomField[0].CustomFieldValue : '');
        } else {
          if (contactCustomField && contactCustomField.length > 0 && this.dynamicForm.form.get(data.name)) {
            const customField = this.dropDownFieldOptions.filter(dropDownField => dropDownField.dropDownCustomFieldId == customFieldValue[0].Id);
            let customFieldOptionArr = [];
            if (customField.length > 0) {
              customFieldOptionArr = customField[0].options.filter(option => option.value == contactCustomField[0].CustomFieldValue);
              this.dynamicForm.form.get(data.name).setValue(customFieldOptionArr.length > 0 ? customFieldOptionArr[0].id : '');
            } else {
              this.dynamicForm.form.get(data.name).setValue('');
            }
          }
          else
            if (this.dynamicForm.form.get(data.name)) {
              this.dynamicForm.form.get(data.name).setValue('');
            }
        }
      }
    });
  }

  dynamicFormChange(countries: CountryDTO[], notes?: ContactNoteDTO[]) {
    this.dynamicForm?.form.get('mobile')?.valueChanges.subscribe(mobile => {
      this.guestDetails.PhoneNumber = mobile;
      if (this.dynamicForm.form.get('mobile').dirty && this.isAddGuest && this.editDefaultCommn) {
        let contactPreference = {
          isValid: false,
          pageMethod: PageMethod.Sms
        }
        if (this.dynamicForm.form.get('mobile').valid && this.dynamicForm.form.get('mobile').value != "") {
          contactPreference.isValid = true;
          this.setPagePreference(contactPreference);
        } else {
          contactPreference.isValid = true;
          contactPreference.pageMethod = this.dynamicForm.form.get('email').value != "" && this.dynamicForm.form.get('email').valid ? PageMethod.Email : PageMethod.Manual;
          this.setPagePreference(contactPreference);
        }
      }
    });
    this.dynamicForm.form.get('mobileCode').valueChanges.subscribe(mobileCode => {
      let selectedCountry = countries.filter(country => country.Name === mobileCode);
      if (selectedCountry && selectedCountry.length > 0) {
        this.guestDetails.CountryId = selectedCountry[0].Id;
      }
    })
    this.dynamicForm.form.get('phone').valueChanges.subscribe(phone => {
      this.guestDetails.PhoneNumber2 = phone;
    });
    this.dynamicForm.form.get('secondaryMobileCode').valueChanges.subscribe(secondaryMobileCode => {
      let selectedCountry2 = countries.filter(country => country.Name === secondaryMobileCode);
      if (selectedCountry2 && selectedCountry2.length > 0) {
        this.guestDetails.CountryId2 = selectedCountry2[0].Id;
      }
    })
    this.dynamicForm.form.get('firstName').valueChanges.subscribe(firstName => {
      this.guestDetails.FirstName = this.capitalizeText.transform(firstName);
      const pipedfirstName = this.capitalizeText.transform(firstName);
      if (firstName !== pipedfirstName)
        this.dynamicForm.form.patchValue({
          firstName: pipedfirstName
        }, { emitEvent: false });
    })
    this.dynamicForm.form.get('lastName').valueChanges.subscribe(lastName => {
      this.guestDetails.LastName = this.capitalizeText.transform(lastName);
      const pipedLastName = this.capitalizeText.transform(lastName);
      if (lastName !== pipedLastName)
        this.dynamicForm.form.patchValue({
          lastName: pipedLastName
        }, { emitEvent: false });
    })
    this.dynamicForm.form.get('aliasName').valueChanges.subscribe(aliasName => {
      this.guestDetails.AliasName  = this.capitalizeText.transform(aliasName);
      const pipedAliasName = this.capitalizeText.transform(aliasName);
      if (pipedAliasName !== pipedAliasName)
        this.dynamicForm.form.patchValue({
          aliasName: pipedAliasName
        }, { emitEvent: false });
    })
    this.dynamicForm.form.get('email').valueChanges.subscribe(email => {
      this.guestDetails.EmailAddress = email;
      if (this.dynamicForm.form.get('email').dirty && this.isAddGuest && this.editDefaultCommn) {
        let contactPreference = {
          isValid: false,
          pageMethod: PageMethod.Email
        }
        if (!this.guestDetails.PreferredPageMethod || this.guestDetails.PreferredPageMethod != PageMethod.Sms) {
          if (this.dynamicForm.form.get('email').valid && this.dynamicForm.form.get('email').value != "") {
            contactPreference.isValid = true;
            this.setPagePreference(contactPreference);
          } else {
            contactPreference.isValid = true;
            contactPreference.pageMethod = PageMethod.Manual;
            this.setPagePreference(contactPreference);
          }
        }
      }
    })
    this.dynamicForm.form.get('secondaryEmail').valueChanges.subscribe(secondaryEmail => {
      this.guestDetails.EmailAddress2 = secondaryEmail;
    })
    this.dynamicForm.form.get('membershipCode') && this.dynamicForm.form.get('membershipCode').valueChanges.subscribe(membershipCode => {
      this.guestDetails.TrackMembershipNumber = membershipCode;
    })
    this.dynamicForm.form.get('guestTitle').valueChanges.subscribe(guestTitle => {
      this.guestDetails.GuestTitle = guestTitle;
    })
    this.dynamicForm.form.get('dataRetentionPolicy') && this.dynamicForm.form.get('dataRetentionPolicy').valueChanges.subscribe(dataRetentionPolicy => {
      if (dataRetentionPolicy && dataRetentionPolicy === true) {
        this.partyService.dataRetentionPolicyChecked = true;
      } else {
        this.partyService.dataRetentionPolicyChecked = false;
      }
    })
    this.dynamicForm.form.valueChanges.subscribe(data => {
      this.guestDetails.Birthday =  data.dob ? moment(data.dob).format('YYYY-MM-DD') + "T00:00:00.000" : "" ;
      this.guestDetails.Anniversary =  data.anniversary ? moment(data.anniversary).format('YYYY-MM-DD') + "T00:00:00.000" : "" ;
      this.bindContactFields(data);
      if ((notes && notes.length) || data.internalNotes || this.guestDetails.Notes) {
        this.bindInternalNotes(data, notes);
      }
      if (this.dynamicForm.form.valid) {
        this.validateFormGroup(data);
      } else {
        this.processNameValidity(this.dynamicForm.form.value);
        this.checkReqPartyFieldsAnyOne(data);
        const modifiedGuestDetails = { ...this.guestDetails };
        this.partyService.reservationFormGroup.get('selectedGuest').setValue(this.dynamicForm.form.valid ? modifiedGuestDetails : null);
        this.enableBookAction(data);
      }
    });
    this.formChipsChange();
    this.textAreaSubscription();
  }
  enableBookAction(data?: any) {
    const isReservationFormValid = this.checkReqPartyFieldsAnyOne(data) && this.partyService.reservationFormGroup.valid && this.isSecondaryGuestDetailsValid &&  this.dynamicForm?.form?.valid;
    let AuthorizePaymentValidation = (this.partyService.bookingPaymentType !== PaymentMethod.Authorize ? true : (this.partyService.RetailCardTokenId ? true : false));
    this.popupService.saveBtnEnable$.next(isReservationFormValid && this.partyService.reservationFormGroup.value.selectedGuest);
    this.popupService.formValid$.next(
      {
        isFormValid: isReservationFormValid && (this.partyService.reservationFormGroup.value.selectedGuest ? true : false) && this.isCoverTypeValid() && AuthorizePaymentValidation,
        currentTab: this.tabIndex
      } as IFormValidDetails);

      
          //Action subscribe to new Tabs component 
    if (this.popupService.tabsActionData?.length) {
      this.popupService.tabsActionData[this.popupService.tabsActionData.length - 1].gotoNextTab = isReservationFormValid && ((this.partyService.reservationFormGroup.value.selectedGuest || this.guestDetails) ? true : false) && this.isCoverTypeValid() && AuthorizePaymentValidation
      this.popupService.tabsActions$.next(this.popupService.tabsActionData);
    }
  }

  updateFormValidity(isError: boolean, callBack: (validators?: ValidatorFn) => void, validators?: ValidatorFn) {
    this.isErrorMessageAvailable = isError;
    this.formUpdationCount = this.formUpdationCount + 1;
    if (this.formUpdationCount === 1) {
      validators ? callBack(validators) : callBack();
    }
  }

  updateLastNameValidity = () => {
    this.setValidators(this.dynamicForm.form.controls, 'lastName', this.dynamicForm.form, Validators.nullValidator);

    const personalForm = this.partyService.reservationFormGroup.get('selectedGuest') as UntypedFormGroup;
    this.setValidators(personalForm.controls, 'lastName', personalForm, Validators.nullValidator);
    this.formUpdationCount = 0;
  }

  updateFirstNameValidity = () => {
    this.setValidators(this.dynamicForm.form.controls, 'firstName', this.dynamicForm.form, Validators.nullValidator);

    const personalForm = this.partyService.reservationFormGroup.get('selectedGuest') as UntypedFormGroup;
    this.setValidators(personalForm.controls, 'firstName', personalForm, Validators.nullValidator);
    this.formUpdationCount = 0;
  }

  updateNameValidity = (validators: ValidatorFn) => {
    const dynamicFormControls = this.dynamicForm.form;
    this.setValidators(dynamicFormControls.controls, 'firstName', dynamicFormControls, validators);
    this.setValidators(dynamicFormControls.controls, 'lastName', dynamicFormControls, validators);

    const personalForm = this.partyService.reservationFormGroup.get('selectedGuest') as UntypedFormGroup;
    this.setValidators(personalForm.controls, 'firstName', personalForm, validators);
    this.setValidators(personalForm.controls, 'lastName', personalForm, validators);
    this.formUpdationCount = 0;
  }

  setValidators(allControls, formControl: string, formGroup: UntypedFormGroup, validators: ValidatorFn) {
    if (allControls) {
      Object.keys(allControls).forEach((control) => {
        if (control === formControl) {
          formGroup.get(control).setValidators([validators, Validators.pattern(this.dfs.HTMLPattern) , Validators.maxLength(100)]);
          formGroup.get(control).updateValueAndValidity({ emitEvent: false });
        }
      });
    }
  }

  checkReqPartyFieldsAnyOne(data) {
    data = data ? data : (this.dynamicForm?.form.value ? this.dynamicForm?.form.value : null);
    if (this._settings && this._settings.General.RequiredPartyFields === RequiredPartyFields.Anyone) {
      if (data && (data.mobile || data.phone || data.secondaryEmail || data.email)) {
        this.dynamicForm?.form?.get('mobile').clearValidators();
        this.dynamicForm?.form?.get('mobile').updateValueAndValidity({ emitEvent: false });
        this.dynamicForm?.form?.get('phone').clearValidators();
        this.dynamicForm?.form?.get('phone').updateValueAndValidity({ emitEvent: false });
        this.dynamicForm?.form?.get('secondaryEmail').setValidators([Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]);
        this.dynamicForm?.form?.get('secondaryEmail').updateValueAndValidity({ emitEvent: false });
        this.dynamicForm?.form?.get('email').setValidators([Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]);
        this.dynamicForm?.form?.get('email').updateValueAndValidity({ emitEvent: false });
        if (this._settings.General.ValidateGuestPhoneNumber) {
          this.dynamicForm?.form?.get('mobile').setValidators([Validators.minLength(this.mobileNumberLength), Validators.maxLength(this.mobileNumberLength)]);
          this.dynamicForm?.form?.get('mobile').updateValueAndValidity({ emitEvent: false });
          this.dynamicForm?.form?.get('phone').setValidators([Validators.minLength(this.phoneNumberLength), Validators.maxLength(this.phoneNumberLength)]);
          this.dynamicForm?.form?.get('phone').updateValueAndValidity({ emitEvent: false });
        }
        return true;
      } else {
        if (this._settings.General.ValidateGuestPhoneNumber) {
          this.dynamicForm?.form?.get('mobile').setValidators([Validators.required, Validators.minLength(this.mobileNumberLength), Validators.maxLength(this.mobileNumberLength)]);
          this.dynamicForm?.form?.get('mobile').updateValueAndValidity({ emitEvent: false });
          this.dynamicForm?.form?.get('phone').setValidators([Validators.required, Validators.minLength(this.phoneNumberLength), Validators.maxLength(this.phoneNumberLength)]);
          this.dynamicForm?.form?.get('phone').updateValueAndValidity({ emitEvent: false });
        }
        this.dynamicForm?.form?.get('secondaryEmail').setValidators([Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]);
        this.dynamicForm?.form?.get('secondaryEmail').updateValueAndValidity({ emitEvent: false });
        this.dynamicForm?.form?.get('email').setValidators([Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]);
        this.dynamicForm?.form?.get('email').updateValueAndValidity({ emitEvent: false });
        return false;
      }
    }
    return true;
  }

  validateFormGroup(data?) {
    if (this.dynamicForm?.form.valid && this.dynamicForm?.form.dirty) {
      const modifiedGuestDetails = { ...this.guestDetails };
      this.partyService.reservationFormGroup.get('selectedGuest').setValue(modifiedGuestDetails);
      this.partyService.reservationFormGroup.markAsDirty();
      this.partyService.reservationFormGroup.updateValueAndValidity();
    } else if (this.dynamicForm?.form.valid) {
      const modifiedGuestDetails = { ...this.guestDetails };
      this.partyService.reservationFormGroup.get('selectedGuest').setValue(modifiedGuestDetails);
    } else {
      this.checkReqPartyFieldsAnyOne(data);
      const modifiedGuestDetails = { ...this.guestDetails };
      if(this.dynamicForm?.form)
      this.partyService.reservationFormGroup.get('selectedGuest').setValue(this.dynamicForm?.form.valid ? modifiedGuestDetails : null);
    }
    this.enableBookAction(data);
    if (this.dynamicForm && !this.dynamicForm?.form.valid && !this.isEditGuest && !this.isAddGuest) {
      this.dynamicForm.form.markAllAsTouched();
      this.editGuest();
    }
  }

  processNameValidity(data) {
    if (data && !data.firstName && !data.lastName) {
      this.updateFormValidity(true, this.updateNameValidity, Validators.required);
      this.showSwap = false;
    } else if (data && data.firstName && data.lastName) {
      this.updateFormValidity(false, this.updateNameValidity, Validators.required);
      this.showSwap = true;
    } else if (data && data.firstName) {
      this.updateFormValidity(false, this.updateLastNameValidity);
      this.showSwap = true;
    } else if (data && data.lastName) {
      this.updateFormValidity(false, this.updateFirstNameValidity);
      this.showSwap = true;
    }
  }
  validateSecondaryGuest() {
    this.isSecondaryGuestDetailsValid = this.secondaryContacts.filter(secondaryContact => secondaryContact.isFormValid).length == this.secondaryContacts.length;
    if (this.dynamicForm?.form.valid) {
      const modifiedGuestDetails = { ...this.guestDetails };
      this.partyService.reservationFormGroup.get('selectedGuest').setValue(modifiedGuestDetails);
      this.enableBookAction();
    }
  }

  isCoverTypeValid(): boolean {
    let totalCovers = 0;
    let iscovertypeValid;
    if (this.partyService.reservationFormGroup.value.selectedSpecialMeal) {
      if (this.partyService.reservationFormGroup.value.selectedCoverTypes && this.partyService.reservationFormGroup.value.selectedCoverTypes.length > 0) {
        this.partyService.reservationFormGroup.value.selectedCoverTypes.forEach((covers) => {
          if (covers.Covers) {
            totalCovers = totalCovers + Number(covers.Covers);
          }
        });
        iscovertypeValid = (this.partyService.reservationFormGroup.value.selectedSize == totalCovers) ? true : false;
      }
      else {
        iscovertypeValid = true;
      }
    }
    else {
      iscovertypeValid = true;
    }
    return iscovertypeValid;
  }

  bindInternalNotes(data, notes?: ContactNoteDTO[]) {
    const internalNotes: ContactNoteDTO = {} as ContactNoteDTO;
    if (notes && notes.length > 0) {
      this.guestDetails.Notes.forEach((customNote) => {
        if (customNote.Id === notes[0].Id) {
          customNote.Text = data.internalNotes;
        }
      });
    } else if (data.internalNotes) {
      internalNotes.Text = data.internalNotes;
      if (this.guestDetails.Notes?.length > 0) {
        const guestNote = this.guestDetails.Notes?.filter(note => !note.RelatedId)[0];
        if (guestNote) {
          guestNote.Text = data.internalNotes;
        }
        else {
          internalNotes.RelatedId = null;
          internalNotes.Text = data.internalNotes;
          this.guestDetails.Notes?.push(internalNotes);
        }
      } else {
        this.guestDetails.Notes?.push(internalNotes);
      }
    }
    // else {
    //   if (this.guestDetails.Notes.length > 0) {
    //     const guestNote = this.guestDetails.Notes.filter(note => !note.RelatedId)[0];
    //     if (guestNote) {
    //       guestNote.Text = "";
    //     }
    //   }
    // }
    let tempNotesArr = _.cloneDeep(this.guestDetails.Notes);
    for (let preferenceProperty in data) {
      if (preferenceProperty.includes('otherNotes')) {
        const otherNotesInConfig = this.guestConfig.find(configProp => configProp.name == preferenceProperty);
        if (otherNotesInConfig && !otherNotesInConfig.isHidden) {
          const otherNote: ContactNoteDTO = {} as ContactNoteDTO;
          otherNote.Text = data[preferenceProperty];
          if (otherNote.Text) {
            const preferencePropertyId = preferenceProperty.match(/(\d+)/);
            const noteId = Number(preferenceProperty.substring((preferenceProperty.lastIndexOf('_') + 1), preferenceProperty.length)) || null;
            if (preferencePropertyId) {
              otherNote.RelatedId = Number(preferencePropertyId[0]);
              const preferredOtherTags = this.selectedPreferenceTags.filter((note) => note.Id === otherNote.RelatedId && (!noteId || noteId == note.noteId || note.extraNotesIds?.findIndex(x => x == noteId) > -1));
              if (preferredOtherTags && preferredOtherTags.length > 0) {
                otherNote.Id = noteId || null;
              }
            }
            const noteIndex = tempNotesArr.findIndex(note => ( noteId && note.Id == noteId)|| (!note.Id && note.RelatedId == otherNote.RelatedId));
            if (noteIndex > -1) tempNotesArr.splice(noteIndex, 1);
            tempNotesArr.push({ ...otherNote });
            let allNotes = this.guestConfig.filter(configProp => configProp.name.includes(preferenceProperty.split("$multinotes")[0]));
            let nonRelatedNotes = this.guestDetails.Notes.filter(note => note.RelatedId != otherNote.RelatedId);
            this.guestDetails.Notes = [...nonRelatedNotes, ...allNotes.map(note => {
              // let id = allNotes[0].name.split("$multinotes")?.[0].match(/(\d+)/)[0] || 0;
              return {
                Id: null,
                RelatedId: otherNote.RelatedId,
                Text: data[note.name]
              }
            })] as any;
          } else {
            this.guestDetails.Notes = this.guestDetails.Notes.filter(note => note.RelatedId != otherNote.RelatedId);
          }
        }
      }
    }
  }
  bindContactFields(data) {
    this.guestDetails.ContactCustomFields = this.formCustomFieldRequestPayload(data, this.guestDetails.Id, this.contactGuestFields, this.previousContactCustomField)
  }

  textAreaSubscription(){
    this.subscriptions.add(this.formTextAreaService.formTextAreaChange$.subscribe(data => {
      let index = this.guestConfig.findIndex(itm => itm.name === data.name)
      if(index != -1){
        this.guestConfig.splice(index, 1);
        let [relatedTag, uniqId] = data.name.split('$multinotes');
        let length = this.guestConfig.filter(itm => itm.name.includes(relatedTag)).length;
        if(!length && this.guestConfig[index - 1] && this.guestConfig[index - 1].options){
          let othersIndex = this.guestConfig[index - 1].options.findIndex(option => option.Name === 'Others')
          this.guestConfig[index - 1].options[othersIndex].setSelected = false;
        }
        let partyNoteIndex = this.guestDetails.Notes.findIndex(note => note.Id == uniqId || (note.RelatedId == relatedTag.match(/(\d+)/)[0]));
        // this.guestDetails.Notes.splice(partyNoteIndex, 1);

        let notes = [];
        this.guestConfig.forEach(config => {
          if(config.name.includes("preferredTags")){
            config.options.filter(option => option.setSelected && option.Name !== 'Others').forEach(option => {
              notes.push({
                Id: null,
                RelatedId: option.Id,
                Text: option.Name
              })
            })
          }
          else if(config.name.includes("otherNotes")){
              
            notes.push({
                Id: null,
                RelatedId: +config.name.split("$multinotes")[0].match(/(\d+)/)[0],
                Text: config.value
              })
          }
        })
        this.guestDetails.Notes = notes;
        this.validateFormGroup();
      }
    }))
  }

  formChipsChange() {
    this.subscriptions.add(this.formChipService.formChipChange$.subscribe((selectedChips) => {
      const contactNotesPreNotes: ContactNoteDTO = {} as ContactNoteDTO;
      if (selectedChips.name === this.guestConfig.filter(config => config.name == "pageMethod")[0].name) {
        if (this.partyService.partyPageMethod == null)
          this.partyService.partyPageMethod = this.defaultPartyPageMethod;
        selectedChips.data.forEach((_prefferedCommunication) => {
          const pageMethod = (this.partyService.partyPageMethod === _prefferedCommunication.Id);
          if (_prefferedCommunication.setSelected && !pageMethod) {
            this.partyService.partyPageMethod = PageMethod[PageMethod[_prefferedCommunication.Id]];
            this.guestDetails.PreferredPageMethod = PageMethod[PageMethod[_prefferedCommunication.Id]];
          }
        });
      } else if (selectedChips.name === 'freeTags') {
        selectedChips.data.forEach((tags) => {
          if (!tags.setSelected) {
            const index = this.guestDetails.Notes.findIndex((note) => note.Id === tags.Id);
            if (index > -1) {
              this.guestDetails.Notes.splice(index, 1);
            }
          } else {
            const index = this.guestDetails.Notes.findIndex((note) => note.Id === tags.Id);
            if (index == -1) {
              const freeTextNotes: ContactNoteDTO = {} as ContactNoteDTO;
              freeTextNotes.Id = tags.Id;
              freeTextNotes.Text = tags.Name;
              this.guestDetails.Notes.push(freeTextNotes);
            }
          }
        });
      } else if (this.guestConfig.filter((fieldConfig) => fieldConfig.name == selectedChips.name).length > 0) {
        this.selectedPreferenceTags = selectedChips.data;
        const preferredTagsIndex = this.guestConfig.findIndex(fieldConfig => fieldConfig.name == selectedChips.name);
        const otherTags = selectedChips.data.filter(chip => chip.Name == 'Others');
        if (otherTags && otherTags.length > 0) {
          let otherNotesName = `otherNotes${otherTags[0].Id}`;
          let isOtherNoteAvailable = this.guestConfig.filter((config) => config.name.includes(otherNotesName)).length;
          const otherNotesControl = this.dynamicForm.config.filter((config) => config.name.includes(otherNotesName));
          if ((selectedChips.isOthersAdd || !isOtherNoteAvailable) && otherTags.length > 0 && otherTags[0].setSelected) {
            let currDate = new Date();
            const otherNotes: FieldConfig = {
              name: otherNotesName + '$multinotes' + currDate.getTime(),
              type: 'textarea',
              inputType: 'text',
              label: 'notes',
              showHint: true,
              class: 'guest-view__other-tags',
              charLength: 1000,
              isHidden: false,
              showRemove: true
            }
            this.guestConfig.splice(preferredTagsIndex + 1, 0, otherNotes);
            
            this.dynamicForm.form.addControl(otherNotesName, new UntypedFormControl());
          } else if (isOtherNoteAvailable) {
            if (otherTags[0].setSelected) {
              otherNotesControl[0].isHidden = false;
              const otherNotes = this.guestDetails.Notes.find((note) => note.RelatedId == otherTags[0].Id);
              if (!otherNotes) {
                const otherNote: ContactNoteDTO = {} as ContactNoteDTO;
                const preferredOtherTags = this.selectedPreferenceTags.filter((note) => note.Id === otherTags[0].Id);
                if (preferredOtherTags && preferredOtherTags.length > 0) {
                  otherNote.Id = preferredOtherTags[0].noteId ? preferredOtherTags[0].noteId : new Date().getTime();
                }
                otherNote.RelatedId = otherTags[0].Id;
                otherNote.Text = this.dynamicForm.form.value[otherNotesName];
                otherNote.Id = null;
                this.guestDetails.Notes.push(otherNote);
              }
              // const otherNotesText = this.dynamicForm.form.get(otherNotesName).value;
            } else {
              // this.guestConfig[preferredTagsIndex + 1].isHidden = true;

              otherNotesControl.forEach(control => {
                control.isHidden = true;
              });

              this.guestDetails.Notes = this.guestDetails.Notes.filter((note) => note.RelatedId !== otherTags[0].Id)
            }
            // this.guestConfig.splice(preferredTagsIndex + 1, 1);
            // this.dynamicForm.form.removeControl(otherNotesName);
          }
        }
        this.guestConfig = [...this.guestConfig];
        const contactNotesPreNotes: ContactNoteDTO = {} as ContactNoteDTO;
        this.selectedPreferenceTags.forEach((preferredTags) => {
          if (preferredTags.Name !== 'Others') {
            const preferredData = this.guestDetails.Notes.filter((note) => note.RelatedId === preferredTags.Id)[0];
            if (preferredTags.setSelected && !preferredData) {
              contactNotesPreNotes.RelatedId = preferredTags.Id;
              contactNotesPreNotes.Text = preferredTags.Name;
              contactNotesPreNotes.Id = preferredTags.noteId ? preferredTags.noteId : null;
              contactNotesPreNotes.Icon = preferredTags.Icon;
              this.guestDetails.Notes.push(contactNotesPreNotes);
            } else if (!preferredTags.setSelected) {
              const index = this.guestDetails.Notes.findIndex((note) => note.RelatedId === preferredTags.Id);
              if (index > -1) {
                this.guestDetails.Notes.splice(index, 1);
              }
            }
          }
        });
      }
      this.validateFormGroup();
    }));
  }

  setFormConfig() {
    let defaultCountryCode, minMobileLength;
    if (!this.isEditGuest || !this.dfs.selectedCountryCode || this.partyService.isNewGuestFromReservation) {
      defaultCountryCode = Utilities.getDefaultCountry(this.countryCodes, this._settings);
      minMobileLength = Utilities.getMobileNumMaxLength(defaultCountryCode.countryCode);
    }
    else {
      defaultCountryCode = this.countryCodes.filter((x) => x.countryCode == this.dfs.selectedCountryCode)[0];
      minMobileLength = Utilities.getMobileNumMaxLength(defaultCountryCode.countryCode);
    }
    if (!this.isEditGuest) {
      this.guestConfig = [
        {
          type: 'select',
          name: 'guestTitle',
          inputType: 'number',
          label: 'GuestTitle',
          class: 'guest-view__guest-title',
          options: this.getGuestTitles(),
          value: this.getGuestTitles()[0].id,
        },
        {
          type: 'input',
          name: 'firstName',
          inputType: 'text',
          label: 'guestBookDetailsFN',
          class: 'guest-view__firstName', // Do not modify the className, there is a dependency for editGuest details.
          validation: [Validators.required , Validators.maxLength(100)],
          showErrorText: true,
          // errorMessage : 'guestBookDetailsFNError'

        },
        {
          type: 'input',
          name: 'lastName',
          inputType: 'text',
          label: 'guestBookDetailsLN',
          class: 'guest-view__lastName', // Do not modify the className, there is a dependency for editGuest details.
          validation: [Validators.required , Validators.maxLength(100)],
          showErrorText: true,
          containerClass: 'guest-view__lastName-container'
          // errorMessage : 'guestBookDetailsLNError'
        },
        {
          type: 'input',
          name: 'aliasName',
          inputType: 'text',
          label: 'guestBookDetailsAliasName',
          class: 'guest-view__aliasName', 
          showErrorText: true,
          isHidden: this.showAliasName ? false : true,
          validation: [Validators.maxLength(100)],
          // errorMessage : 'guestBookDetailsLNError'
        },
        
        {
          type: 'select',
          name: 'mobileCode',
          inputType: 'number',
          label: 'primaryCodeLbl',
          class: 'guest-view__mobile-code-edit', // Do not modify the className, there is a dependency for editGuest details.
          options: this.countryCodes,
          cellClick: (event) => this.countryCodeChange(true, event),
          value: defaultCountryCode.id
        },
        {
          type: 'input',
          name: 'mobile',
          inputType: 'text',
          label: 'guestBookDetailsMobile',
          class: 'guest-view__mobile-edit', // Do not modify the className, there is a dependency for editGuest details.
          directive: 'numberOnly',
          // validation: [Validators.minLength(minMobileLength)], minlength has to be included
          showErrorText: true,
          isPhoneNumber: true,
          blurClick: (event) => this.mobileNumberValidation(event)
        },
        {
          type: 'select',
          name: 'secondaryMobileCode',
          inputType: 'number',
          label: 'secondaryCodeLbl',
          class: 'guest-view__secondary-mobile-code-edit',
          options: this.countryCodes,
          cellClick: (event) => this.countryCodeChange.bind(false, event),
          value: defaultCountryCode.id
        },
        {
          type: 'input',
          name: 'phone',
          inputType: 'text',
          label: 'secondaryPhoneLabel',
          directive: 'numberOnly',
          class: 'guest-view__phone-edit', // Do not modify the className, there is a dependency for editGuest details.
          showErrorText: true,
          isPhoneNumber: true,
          blurClick: (event) => this.phoneNumberValidation(event)
        },
        {
          type: 'input',
          name: 'email',
          inputType: 'text',
          label: 'guestBookDetailsEmail',
          class: 'guest-view__email-edit', // Do not modify the className, there is a dependency for editGuest details.
          validation: [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')],
          showErrorText: true
        },
        {
          type: 'input',
          name: 'secondaryEmail',
          inputType: 'text',
          label: 'secondaryEmailLabel',
          class: 'guest-view__secondary-email-edit', // Do not modify the className, there is a dependency for editGuest details.
          validation: [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')],
          showErrorText: true,
        },
        {
          type: 'input',
          name: 'membershipCode',
          inputType: 'text',
          label: this._settings.General.MembershipNumberName,
          class: 'guest-view__memberCode-edit', // Do not modify the className, there is a dependency for editGuest details.

        },
        {
          type: 'input',
          name: 'membershipname',
          inputType: 'text',
          label: 'MembershipType',
          class: 'guest-view__memberCode-edit', // Do not modify the className, there is a dependency for editGuest details.
          disabled: true
        },
        {
          type: 'date',
          name: 'dob',
          inputType: 'text',
          label: 'guestBookDetailsDOB',
          class: 'guest-view__dob-edit', // Do not modify the className, there is a dependency for editGuest details.
          maxDate: new Date()
        },
        {
          type: 'date',
          name: 'anniversary',
          inputType: 'text',
          label: 'guestBookDetailsAnniversary',
          class: 'guest-view__anniversary-edit', // Do not modify the className, there is a dependency for editGuest details.
          maxDate: new Date()
        },
        {
          type: 'chip',
          name: 'pageMethod',
          inputType: 'text',
          label: 'preferredCommunication',
          class: 'guest-view__preferred-tags-edit', // Do not modify the className, there is a dependency for editGuest details.
          options: this.setCommunicationList(),
          multipleChip: false
        }
      ];
      if (this.guestDetails?.Notes) {
        this.othersId = this.guestDetails.Notes.map(({ RelatedId }) => RelatedId);
      }
      this.guestNotes = Utilities.getRestaurantPredefinedContactNotes(this.categories, this.othersId);
      const notesIndex = this.guestConfig.findIndex(config => config.name == 'preferredCommunication');
      this.guestNotes.forEach((guestNote, index) => {
        const tag = {
          type: 'chip',
          name: `preferredTags${index}`,
          inputType: 'text',
          label: guestNote.CategoryName,
          class: 'guest-view__preferred-tags-edit', // Do not modify the className, there is a dependency for editGuest details.
          options: this.getOptions(guestNote.contactNotes, guestNote.Color),
          multipleChip: true,
          multipleOther: true
        }
        this.guestConfig.splice(notesIndex, 0, tag);
      });
      if (this.isFreeTextTagAvailable) {
        const preferredTagsIndex = this.guestConfig.findIndex(config => config.name === 'preferredTags0');
        const tag = {
          type: 'chip',
          name: `freeTags`,
          inputType: 'text',
          label: 'freeNoteText',
          class: 'guest-view__free-tags-edit', // Do not modify the className, there is a dependency for editGuest details.
          options: this.getFreeTextOptions(),
          multipleChip: true
        }
        this.guestConfig.splice(preferredTagsIndex, 0, tag);
      }
      if (this.isAddGuest) {
        this.guestConfig.forEach((config: FieldConfig) => {
          if (config.class.includes('-edit')) {
            config.class = config.class.slice(0, -5);
            if (config.name == 'membershipCode' && this.checkMemberCodeEditability()) {
              config.class = config.class + " " + "guest-view__show-membership"
            }
          }
        });
        this.setPointerEventForMembershipNumber();
      }
    } else {
      this.guestConfig.forEach((config: FieldConfig) => {
        if (config.class.includes('-edit')) {
          config.class = config.class.slice(0, -5);
          if (config.name == 'membershipCode' && this.checkMemberCodeEditability()) {
            config.class = config.class + " " + "guest-view__show-membership"
          }
        }
      });
    }
    this.dfs.selectedCountryCode = defaultCountryCode.countryCode;
    this.dfs.selectedCountryId = defaultCountryCode.countryId;
    this.setRequiredFields(minMobileLength);
  }

  getFreeTextOptions() {
    let freeTextOptions = [];
    let freeNotes = cloneDeep(this.guestDetails.Notes.filter(notes => !notes.RelatedId));
    freeNotes.forEach((notes) => {
      freeTextOptions.push({
        Id: notes.Id,
        Label: notes.Label,
        Name: notes.Text,
        Icon: 'None',
        Value: ''
      })
    })
    return freeTextOptions;
  }



  checkMemberTypeVisibility() {
    if ((Utilities.controlValidate(globals.controlSettings.MembershipType_Hide, this.appService.PropertyType))) {
      return true;
    }
    return false;
  }

  getOptions(contactNotes, categoryColor): any {
    let configOptios = [];
    contactNotes.forEach((notes) => {
      configOptios.push({
        Id: notes.Id,
        Label: notes.Label,
        Name: notes.Text,
        Icon: notes.Icon,
        Value: '',
        color: categoryColor
      });
    })
    return configOptios;
  }
  setPagePreference(value, propertyPreferredCommn?) {
    if (value && value.isValid) {
      if (propertyPreferredCommn != undefined && this.isAddNewGuest) {
        value.pageMethod = Utilities.mapDefaultPageMethodForProperty(propertyPreferredCommn);
      }
      this.guestConfig.filter(config => config.name == "pageMethod")[0].options = this.setCommunicationList(true, value);
      this.partyService.partyPageMethod = value.pageMethod;
      this.guestDetails.PreferredPageMethod = value.pageMethod;
    }
    else {
      this.guestConfig.filter(config => config.name == "pageMethod")[0].options = this.setCommunicationList(true, value);
      if (this.guestDetails && this.guestDetails.PreferredPageMethod)
        this.partyService.partyPageMethod = this.guestDetails.PreferredPageMethod;
      else {
        this.partyService.partyPageMethod = this._settings.General.DefaultPageMethod;
        this.guestDetails.PreferredPageMethod = this._settings.General.DefaultPageMethod;
      }
    }
  }
  setRequiredFields(mobileNumMinLength: number) {
    if (this._settings) {
      let contactPreference = {
        isValid: true,
        pageMethod: PageMethod.Manual
      }

      const result = this.requireFliedValidation(this._settings.General.RequiredPartyFields, this.guestConfig, contactPreference);
      this.guestConfig = result.fieldConfig;
      contactPreference = result.contactPreference;
      if (this.isEdit) {
        contactPreference = {
          isValid: true,
          pageMethod: this.partyService.partyPageMethod != undefined ? this.partyService.partyPageMethod : this.guestDetails.PreferredPageMethod
        }
      } else if (!this.isAddGuest && this.guestDetails && this.guestDetails.PreferredPageMethod) {
        contactPreference = {
          isValid: true,
          pageMethod: this.partyService.partyPageMethod != undefined ? this.partyService.partyPageMethod : this.guestDetails.PreferredPageMethod
        }
      }
      this.setPagePreference(contactPreference, this._settings.General.DefaultPageMethod != PageMethods.None ? this._settings.General.DefaultPageMethod : null);
    }
    // this.dfs.requiredPhoneNumberLength$ = mobileNumMinLength;
  }





  setCommunicationList(onChange?: any, value?: any) {
    let sortedTagsList = [];
    if (!onChange) {
      // this.partyService.partyPageMethod = null;
      this.communicationTagList = this.facadeService.getCommunicationList();
      let pageMethod = this.defaultPartyPageMethod;
      if (this.isEdit) {
        let party = this.partyService.selectedParty$.value;
        if (party) {
          if (party.PageMethod >= 0) {
            pageMethod = party.PageMethod;
            this.partyService.partyPageMethod = party.PageMethod;
          } else if (party.Bookings && party.Bookings.length > 0) {
            pageMethod = party.Bookings[0].PageMethod;
            this.partyService.partyPageMethod = party.Bookings[0].PageMethod;
          }
          else if (this.partyService.partiesList && this.partyService.partiesList.length > 0 && this.partyService.partyPageMethod && this.partyService.partyPageMethod == null) {
            pageMethod = this.partyService.partiesList[0].PageMethod;
            this.partyService.partyPageMethod = this.partyService.partiesList[0].PageMethod;
          }
        }
      }
      else {
        if (this.guestDetails && this.guestDetails.PreferredPageMethod) {
          pageMethod = this.guestDetails.PreferredPageMethod;
          this.partyService.partyPageMethod = this.guestDetails.PreferredPageMethod;
        }
      }
      this.communicationTagList.forEach(element => {
        let tooltipData = '';
        if (element.Id === pageMethod) {
          element.setSelected = true;
        }

        if (this.partyService.partyPagerNumber != '' && element.Id == PageMethod.Manual && element.setSelected) {
          tooltipData = this.ts.instant('pageParty') + ': ' + this.partyService.partyPagerNumber;
        }

        sortedTagsList.push({ Id: element.Id, Name: element.Name, Icon: element.Icon, Value: '', setSelected: element.setSelected, toolTipData: tooltipData });
      });
    }
    else {
      if (this.communicationTagList) {
        this.communicationTagList.forEach(element => {
          if (value && value.isValid) {
            if (element.Id === value.pageMethod) {
              element.setSelected = true;
            }
            else {
              element.setSelected = false;
            }
          }
          else {
            if (element.Id === PageMethod.Manual) {
              element.setSelected = true;
            }
            else {
              element.setSelected = false;
            }
          }
          sortedTagsList.push({ Id: element.Id, Name: element.Name, Icon: element.Icon, Value: '', setSelected: element.setSelected });
        });
      }
    }
    if (Utilities.controlValidate(controlSettings.BookingGuest_SecondaryContact_Hide, this.appService.PropertyType)) {
      sortedTagsList = sortedTagsList.filter(tag => tag.Id !== PageMethod.Sms2 && tag.Id !== PageMethod.Email2 && tag.Id !== PageMethod.VoiceCall2)
    }
    return sortedTagsList;
  }
  changeGuest() {
    this.isEditGuest = false;
    this.partyService.dataRetentionPolicyChecked = false;
    this.guestChange.emit();
  }
  editGuest() {
    this.isEditGuest = true;
    if (this.isEditGuest) {
      this.processNameValidity(this.dynamicForm?.form.value);
    }
    this.loadGuestConfig();
    if (this.isMembershipAvailable && this.guestDetails.memberShipId && this._settings.PropertySetting[0]?.SlottingType == SlottingType.NonDining) {
      this.dynamicForm.form.controls.membershipCode.disable();
    }
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
    this.setPointerEventForMembershipNumber();
    this.setCustomFieldBasedonActivity();
    this.cartGuestEdit.emit(true);
  }
  viewHistory() {
    this.isAddNewGuest = false;
    this.isEditGuest = false;
  }


  countryCodeChange(isPrimaryNumber, event) {
    let contactPreference = {
      isValid: false,
      pageMethod: PageMethod.Sms
    }
    this.setUpCountryCodeForMobileOrPhone(isPrimaryNumber ? true : false);

    if (isPrimaryNumber) {
      this.dynamicForm.form.controls.mobile.enable({ emitEvent: false });
      const mobileValue = this.dynamicForm.form.get('mobile').value;
      let mobileNumber: any;
      mobileNumber = this.dfs.onInputChange(mobileValue ? mobileValue : event.value, false);
      if (this.dynamicForm.form.get('mobile').value && this.dynamicForm.form.get('mobile').value.length > 0) {
        this.dynamicForm.form.get('mobile').setValue(mobileNumber);
      }
    }
    else {
      this.dynamicForm.form.controls.phone.enable();
      const phoneValue = this.dynamicForm.form.get('phone').value;
      let phoneNumber: any;
      phoneNumber = this.dfs.onInputChange(phoneValue ? phoneValue : event.value, false);
      if (this.dynamicForm.form.get('phone').value && this.dynamicForm.form.get('phone').value.length > 0) {
        this.dynamicForm.form.get('phone').setValue(phoneNumber);
      }
    }
    const count = this.dfs.requiredPhoneNumberLength$;

    if (isPrimaryNumber) {
      this.dynamicForm.form.get('mobile').setValidators([Validators.minLength(count), Validators.maxLength(count)]);
      if (this.mobileRequired) {
        this.dynamicForm.form.get('mobile').setValidators([Validators.required, Validators.minLength(count), Validators.maxLength(count)]);
      }
      this.dynamicForm.form.get('mobile').updateValueAndValidity({ emitEvent: false });
      if (this.dynamicForm.form.get('mobile').valid && this.dynamicForm.form.get('mobile').value != "" && this.isAddGuest) {
        contactPreference.isValid = true;
        contactPreference.pageMethod = PageMethod.Sms;
        this.setPagePreference(contactPreference);
      } else {
        contactPreference.isValid = false
        this.setPagePreference(contactPreference);
      }
    }
    else {
      this.dynamicForm.form.get('phone').setValidators([Validators.minLength(count), Validators.maxLength(count)]);
      if (this.mobile2Required) {
        this.dynamicForm.form.get('phone').setValidators([Validators.required, Validators.minLength(count), Validators.maxLength(count)]);
      }
      this.dynamicForm.form.get('phone').updateValueAndValidity({ emitEvent: false });
    }
  }

  ShowPolicyDetails() {
    const componentDetails: ComponentDetails = {
      componentName: DataRetentionPoliciesComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupTitle: this.ts.instant('dataRetentionPolicy')
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '80%',
      width: '50%',
      data: {
        title: this.ts.instant('dataRetentionPolicy'),
        update: 'ok',
        componentDetails,
        from: ComponentTypes.dataRetention,
        back: true,
        standalone: true,
        showAction: true
      },
    });
    this.subscriptions.add(this.popupService.confirmedAction$.subscribe((data) => {
      dialogRef.close();
    }));

  }

  mobileNumberValidation(event?) {
    let contactPreference = {
      isValid: false,
      pageMethod: PageMethod.Sms
    }
    this.setUpCountryCodeForMobileOrPhone(true);
    const mobile = this.dynamicForm.form.get('mobile').value;
    let mobileNumber: any;

    if (mobile || (event && event.value))
      mobileNumber = this.dfs.onInputChange(mobile ? mobile : event.value, false);

    if (this.dynamicForm.form.get('mobile').value && this.dynamicForm.form.get('mobile').value.length > 0) {
      this.dynamicForm.form.get('mobile').setValue(mobileNumber);
    }

    if (this.dfs.requiredPhoneNumberLength$) {
      this.mobileNumberLength = this.dfs.requiredPhoneNumberLength$;
      const count = this.dfs.requiredPhoneNumberLength$;
      if (this._settings.General.ValidateGuestPhoneNumber) {
        this.mobileRequired ? this.dynamicForm.form.get('mobile').setValidators([Validators.required, Validators.minLength(count), Validators.maxLength(count)]) :
          this.dynamicForm.form.get('mobile').setValidators([Validators.minLength(count), Validators.maxLength(count)]);
      } else {
        this.mobileRequired ? this.dynamicForm.form.get('mobile').setValidators([Validators.required]) :
          this.dynamicForm.form.get('mobile').setValidators(null);
      }
      this.dynamicForm.form.get('mobile').updateValueAndValidity({ emitEvent: false });
    } else {
      this.mobileRequired ? this.dynamicForm.form.get('mobile').setValidators([Validators.required]) : this.dynamicForm.form.get('mobile').setValidators(null);
      this.dynamicForm.form.get('mobile').updateValueAndValidity({ emitEvent: false });
    }

    if (event && this.isAddGuest && this.editDefaultCommn) {
      if (this.dynamicForm.form.get('mobile').valid && this.dynamicForm.form.get('mobile').value != "" && this.dynamicForm.form.get('mobile').value != undefined) {
        contactPreference.isValid = true;
        this.setPagePreference(contactPreference);
      } else {
        contactPreference.isValid = this.dynamicForm.form.get('email').value != '' && this.dynamicForm.form.get('email').valid ? true : false;
        contactPreference.pageMethod = this.dynamicForm.form.get('email').value != '' && this.dynamicForm.form.get('email').valid ? PageMethod.Email : PageMethod.Manual;
        this.setPagePreference(contactPreference);
      }
    }
  }

  phoneNumberValidation(event?) {
    let contactPreference = {
      isValid: false,
      pageMethod: PageMethod.Sms2
    }
    this.setUpCountryCodeForMobileOrPhone(false);
    const phoneValue = this.dynamicForm.form.get('phone').value;
    let phoneNumber: any;

    if (phoneValue || (event && event.value))
      phoneNumber = this.dfs.onInputChange(phoneValue ? phoneValue : event.value, false);
    if (this.dynamicForm.form.get('phone').value && this.dynamicForm.form.get('phone').value.length > 0) {
      this.dynamicForm.form.get('phone').setValue(phoneNumber);
    }
    if (this.dfs.requiredPhoneNumberLength$) {
      this.phoneNumberLength = this.dfs.requiredPhoneNumberLength$;
      const count = this.dfs.requiredPhoneNumberLength$;
      if (this._settings.General.ValidateGuestPhoneNumber) {
        this.mobile2Required ? this.dynamicForm.form.get('phone').setValidators([Validators.required, Validators.minLength(count), Validators.maxLength(count)]) :
          this.dynamicForm.form.get('phone').setValidators([Validators.minLength(count), Validators.maxLength(count)]);
      } else {
        this.mobile2Required ? this.dynamicForm.form.get('phone').setValidators([Validators.required]) :
          this.dynamicForm.form.get('phone').setValidators(null);
      }
      this.dynamicForm.form.get('phone').updateValueAndValidity({ emitEvent: false });
    } else {
      this.mobile2Required ? this.dynamicForm.form.get('phone').setValidators([Validators.required]) : this.dynamicForm.form.get('phone').setValidators(null);
      this.dynamicForm.form.get('phone').updateValueAndValidity({ emitEvent: false });
    }
  }

  swapNames() {
    let tempFirstName = this.dynamicForm.value.firstName;
    let tempLastName = this.dynamicForm.value.lastName;
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
    this.dynamicForm.form.patchValue({
      firstName: tempLastName,
      lastName: tempFirstName
    });

    this.guestDetails.FirstName = tempLastName;
    this.guestDetails.LastName = tempFirstName;
  }

  compressFile() {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {

      this.imageCompress.compressFile(image, orientation, 75, 50).then(
        result => {
          this.guestDetails.Photo = result.replace(/^data:image.+;base64,/, '');
          this.guestDetails.ShouldUpdatePhoto = true;
          this.file = result.replace(/^data:image.+;base64,/, '');
          this.partyService.reservationFormGroup.markAsDirty();
          this.partyService.reservationFormGroup.updateValueAndValidity();
          this.dynamicForm.form.patchValue({
            photo: this.file
          });
          this.dynamicForm.form.patchValue({
            shouldUpdatePhoto: true
          });
        }
      );

    });
  }

  deleteImage() {
    this.guestDetails.Photo = '';
    this.guestDetails.ShouldUpdatePhoto = true;
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
    this.dynamicForm.form.patchValue({
      photo: ''
    });
    this.dynamicForm.form.patchValue({
      shouldUpdatePhoto: true
    });
  }

  onVIPClick() {
    this.vipStatus = !this.vipStatus;
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
    this.dynamicForm.form.patchValue({
      vipStatus: this.vipStatus
    });
    this.guestDetails.IsVip = this.vipStatus;
    this.validateFormGroup();
  }

  getGuestTitles(): TitleList[] {
    const communicationList: TitleList[] = [];
    let i = 0;
    for (const enumMember in GuestTitle) {
      if (enumMember) {
        const isValueProperty = parseInt(enumMember) >= 0;
        if (isValueProperty) {
          communicationList.push({ id: i, value: this.ts.instant(GuestTitle[enumMember]) });
        }
        ++i;
      }
    }
    return communicationList;
  }

  setUpCountryCodeForMobileOrPhone(isMobile) {
    const selectedCountryCode = this.countryCodes.filter((country) => {
      return country.id === this.dynamicForm.form.get(isMobile ? 'mobileCode' : 'secondaryMobileCode').value;
    })[0];
    this.dfs.selectedCountryCode = selectedCountryCode.countryCode;
    this.dfs.selectedCountryId = selectedCountryCode.countryId;
  }

  ngOnDestroy() {
    this.dynamicForm.form.reset({}, { emitEvent: false });
    this.guestConfig.forEach(config => {
      config.blurClick && config.blurClick.bind(null);
      config.cellClick && config.cellClick.bind(null);
      config.returnFunction && config.returnFunction.bind(null);
    });

    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.gbs.searchStringForNewGuest = null;
    this.dfs.requiredPhoneNumberLength$ = null;
  }

  addSecondaryGuest() {
    if (this.guestDetails.SecondaryContactsList && this.guestDetails.SecondaryContactsList.length > 0) {
      this.addSecondaryGuestDetails();
    } else {
      this.guestDetails.SecondaryContactsList = [];
      this.addSecondaryGuestDetails();
    }
      this.addSecondaryGuestButton.disbaledproperity = !(this.AllowAddingSecondaryGuest && this.partyService.bookingSize > this.guestDetails.SecondaryContactsList.length + 1);
  }

  addSecondaryGuestDetails() {
    this.guestDetails.SecondaryContactsList.push({
      FirstName: null,
      LastName: null,
      AliasName:null,
      EmailAddress: null,
      PhoneNumber: null,
      PartyId: this.partyId,
      ContactNotes: null,
      Notes: [],
      isNew: true,
      Id: null,
      ContactId: null,
      Birthday: null,
      Anniversary: null,
      ContactCustomFields: null,
      PhoneNumber2: null,
      EmailAddress2: null,
      CountryId2: null,
      tempId: this.guestDetails.SecondaryContactsList.length
    });
    this.secondaryContacts = this.guestDetails.SecondaryContactsList.filter(sc=> (!sc.ContactId) || (sc.ContactId != this.guestDetails.Id));
    this.validateSecondaryGuest();
  }

  deleteSecondaryGuest(secondaryContactDetails: { secondaryContactInfo: SecondaryContactDTO, isFormValid: boolean }) {
    if (secondaryContactDetails.secondaryContactInfo.isNew) {
      this.guestDetails.SecondaryContactsList = this.guestDetails.SecondaryContactsList.filter((secondContact) => secondContact.tempId !== secondaryContactDetails.secondaryContactInfo.tempId);
    } else {
      this.guestDetails.SecondaryContactsList = this.guestDetails.SecondaryContactsList.filter((secondContact) => secondContact.Id !== secondaryContactDetails.secondaryContactInfo.Id);
    }
    this.secondaryContacts = this.guestDetails.SecondaryContactsList.filter(sc=>sc.ContactId != this.guestDetails.Id);
    this.addSecondaryGuestButton.disbaledproperity = !(this.AllowAddingSecondaryGuest && this.partyService.bookingSize > this.guestDetails.SecondaryContactsList.length + 1);
    this.isSecondaryGuestDetailsValid = this.secondaryContacts.filter(secondaryContact => secondaryContact.isFormValid).length == this.secondaryContacts.length;
    this.validateFormGroup();
  }
  changeBookingCount() {
    if (this.guestDetails && this.guestDetails.SecondaryContactsList && this.guestDetails.SecondaryContactsList.length) {
      if (this.guestDetails.SecondaryContactsList.length && (this.partyService.bookingSize || this.partyService.selectedParty$.value?.Size))
        this.guestDetails.SecondaryContactsList = this.secondaryContacts = this.secondaryContacts.slice(0, (this.partyService.bookingSize || this.partyService.selectedParty$.value?.Size) - 1);
      this.validateFormGroup();
    }
  }

}
