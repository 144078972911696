import { Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { AddonBookingDTO, PricingCategory, PricingType, SelectionType, SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { PartyService } from '@app/shared/services/party.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { Subscription } from 'rxjs';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import * as _ from 'lodash';
import { PartyState, ReservationType } from '@app/shared/constants/commonenums';
import { TranslateService } from '@ngx-translate/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { category } from '@app/retail/package/create-package-plan/surcharge/surcharge.model';

@Component({
  selector: 'app-add-on-for-booking',
  templateUrl: './add-on-for-booking.component.html',
  styleUrls: ['./add-on-for-booking.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AddOnForBookingComponent implements OnInit, OnDestroy {
  searchConfig: FieldConfig[];
  subscriptions: Subscription = new Subscription();
  _settings: SettingsDTO;
  addOnList = []
  addOnTempList = [];
  mandatoryAddOns: any = [];
  additionalAddOns: any = [];
  allEligibleAddOns: any = [];
  searchText: any;
  PricingType = PricingType;
  PricingCategory = PricingCategory;
  selectedActivityId: number;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  warningMessages: string[] = [];
  @ViewChild('searchConfigForm', { static: true }) searchConfigForm: DynamicFormComponent;

  activityChangeSubscription: Subscription = new Subscription();
  selectedSlotSubscription: Subscription = new Subscription();

  constructor(public cs: CacheService, public ps: PartyService, private popupService: PopupService, @Inject(COMPONENTINPUT) public data, private _snackBar: MatSnackBar, private ts: TranslateService) {
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
    }));

  }

  ngOnDestroy(): void {
    this.activityChangeSubscription?.unsubscribe();
    this.selectedSlotSubscription?.unsubscribe();
    this.ps.addOnList = [];
  }

  ngOnInit(): void {
    //this.popupService.previousButtonEnabled$.next(true);
    this.addOnList = this.addOnTempList = this._settings.Addons;
    this.searchConfig = [
      {
        type: 'input',
        name: 'searchText',
        label: 'SearchAddonsText',
        class: 'action-bar__search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this)
      }
    ];
    this.updateAddOnsForActivity();
    this.activityChangeSubscription = this.ps.activityChanges$.subscribe(activity => {
      if (activity) {
        if (this.data?.SpecialMealId && (activity?.Id !== this.data?.SpecialMealId) //) {
          && (this.data && (!this.data.ReservedFor && !this.data.addPrivateLessonBooking && !this.data.isCartEdit && !this.data.instructorView))) {
          this.data = null;
        }
      }
      this.updateAddOnsForActivity();
    })
    this.selectedSlotSubscription = this.ps.selectedSlot$.subscribe(slot => {
      if (slot && slot.length)
        this.updateAddOnsForActivity()
    })


    this.subscriptions.add(this.ps.tabChange$.subscribe(value => {
      if (this.ps.reservationType == ReservationType.PrivateLessonBooking && this.ps.isEditData) {
        if (value == 1 || value == 2) {
          this.popupService.previousButtonEnabled$.next(false);
        }
      }
    }));


  }

  getAddOnQuantity(addon): number {
    //changes for future planned - based on selectiontype
    switch (addon.SelectionType) {
      case SelectionType.PerGuest:
        return +this.ps.bookingSize;
      case SelectionType.PerBooking:
        return +addon.MinQuantity
      case SelectionType.PerLesson:
        return +addon.MinQuantity
    }
  }

  updateAddOnsForActivity() {
    let activity;
    if (this.ps.reservationType == ReservationType.OpenBooking) {
      activity = null;
    }
    else {
      activity = this.data && this.data.SpecialMealId ? this.data.SpecialMealId : this.ps.selectedSpecialMealId;
    }
    let getAddOns;
    if((this.ps.reservationType == ReservationType.OpenBooking || this.ps.reservationType == ReservationType.Reservation) &&  this.ps.selectedSlot$?.value?.length){
      getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, activity ,   this.ps.selectedSlot$?.value[0]?.DateTime);
    }else{
      getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, activity);
    }
    this.mandatoryAddOns = [];
    this.additionalAddOns = [];
    this.allEligibleAddOns = [];
    getAddOns.forEach(item => {
      let availableMandatoryAddOn: AddonBookingDTO = this.cs.settings.value.Addons.find(addOnData => addOnData.AddonId == item.AddonId);
      if (availableMandatoryAddOn) {
        availableMandatoryAddOn['IsVisible'] = item.IsVisible;
        if (availableMandatoryAddOn.SelectionType == SelectionType.PerLesson) {
          availableMandatoryAddOn['MaxQuantity'] = 1;
        } else {
          availableMandatoryAddOn['MaxQuantity'] = item.MaxQuantity;
        }
        availableMandatoryAddOn['MinQuantity'] = item.MinQuantity
        availableMandatoryAddOn['Price'] = availableMandatoryAddOn.AddOnDetails?.length ? availableMandatoryAddOn.AddOnDetails[0]?.Price : 0;
        availableMandatoryAddOn['IsCategoryLevelMandatory'] = item.IsCategoryLevelMandatory;
        availableMandatoryAddOn['OverAllQuantity'] = this.ps.selectedSlot$.value?.[0]?.AddOnAvailability?.find(data => data.AddonId == availableMandatoryAddOn.AddonId)?.OverAllQuantity || 0;


        if (this.ps.selectedSlot$.value?.[0]?.isStandBy && availableMandatoryAddOn.SelectionType !== SelectionType.PerLesson) {
          availableMandatoryAddOn.AvailableQuantity = availableMandatoryAddOn.OverAllQuantity;
        } else {
          let quantity = this.getAddOnQuantityForBooking(availableMandatoryAddOn);
          availableMandatoryAddOn.AvailableQuantity = quantity >= 0 ? quantity : 0;
        }
       
        availableMandatoryAddOn['IsMandatory'] = item.IsMandatory;
        availableMandatoryAddOn['IsUnlimited'] = this.ps.selectedSlot$.value?.[0]?.AddOnAvailability?.find(data => data.AddonId == availableMandatoryAddOn.AddonId)?.IsUnlimited || false;
        availableMandatoryAddOn['PricingType'] = availableMandatoryAddOn.AddOnDetails?.length ? availableMandatoryAddOn.AddOnDetails[0]?.PricingType : null;
        availableMandatoryAddOn['PricingCategory'] = availableMandatoryAddOn.AddOnDetails?.length ? availableMandatoryAddOn.AddOnDetails[0]?.PricingCategory : null;
       
        let addOnQuantity = 0;
        if (item.IsMandatory || item.IsCategoryLevelMandatory) {
          addOnQuantity = this.getAddOnQuantity(availableMandatoryAddOn)
        }
        availableMandatoryAddOn.selectedQuantity = (!item.IsMandatory) && addOnQuantity > availableMandatoryAddOn.AvailableQuantity ? availableMandatoryAddOn.AvailableQuantity : addOnQuantity; // && !item.IsCategoryLevelMandatory

        if ((availableMandatoryAddOn['IsMandatory'] || availableMandatoryAddOn['IsCategoryLevelMandatory']) && addOnQuantity > availableMandatoryAddOn.AvailableQuantity) {
          availableMandatoryAddOn.OverBooking = availableMandatoryAddOn.OverBooked = addOnQuantity - availableMandatoryAddOn.AvailableQuantity;
        } else {
          availableMandatoryAddOn.OverBooking = availableMandatoryAddOn.OverBooked = 0;
        }
           
        //Changes for Standby dining reservation 
        if(this.ps.isStandbySlotSelected &&  (this.ps.reservationType == ReservationType.Reservation || this.ps.reservationType == ReservationType.StandbyParties)){
          let quantity = this.getAddOnQuantityForBooking(availableMandatoryAddOn);
          availableMandatoryAddOn.AvailableQuantity = availableMandatoryAddOn.AddOnDetails[0].Quantity;
          availableMandatoryAddOn['OverAllQuantity']  = quantity >= 0 ? quantity : 0
          availableMandatoryAddOn['IsUnlimited'] = availableMandatoryAddOn.AddOnDetails[0].IsUnlimited;
          availableMandatoryAddOn.OverBooking = availableMandatoryAddOn.OverBooked = 0;
        }
        
        if (this.data) {
          if (this.data.BookingContactAddonItems) {
            let bookedAddon = this.data.BookingContactAddonItems.find(addOn => addOn.AddonId == availableMandatoryAddOn.AddonId);
            if (bookedAddon && bookedAddon != undefined && bookedAddon != null) {
              if ((availableMandatoryAddOn.SelectionType == SelectionType.PerGuest && !item.IsMandatory) || (availableMandatoryAddOn.SelectionType != SelectionType.PerGuest)) {
                availableMandatoryAddOn.selectedQuantity = bookedAddon.AddonCount || 0;
              }

            }
          }
          else if (this.data.SelectedAddOns) {
            if ((availableMandatoryAddOn.SelectionType == SelectionType.PerGuest && !item.IsMandatory) || (availableMandatoryAddOn.SelectionType != SelectionType.PerGuest)) {
              availableMandatoryAddOn.selectedQuantity = this.data.SelectedAddOns.find(addOn => addOn.AddonId == availableMandatoryAddOn.AddonId)?.Quantity || 0;
            }

          }
        }
        availableMandatoryAddOn.selectedQuantity = Number(availableMandatoryAddOn.selectedQuantity);
        if (item.IsMandatory || item.IsCategoryLevelMandatory) {
          this.mandatoryAddOns.push(availableMandatoryAddOn);
          this.allEligibleAddOns.push(availableMandatoryAddOn);

        }
        else {
          this.additionalAddOns.push(availableMandatoryAddOn);
          this.allEligibleAddOns.push(availableMandatoryAddOn);

        }

      }
    })
    //this.additionalAddOns = _.difference(this.cs.settings.value.Addons, this.mandatoryAddOns)
    this.mandatoryAddOns = this.getAddOnsGroupedByCategory(this.mandatoryAddOns)
    this.allEligibleAddOns = this.allEligibleAddOns.filter(_a => _a.IsVisible);
    this.allEligibleAddOns = _.orderBy(this.allEligibleAddOns, 'IsMandatory', 'desc');
    //this.allEligibleAddOns = this.getAddOnsGroupedByCategory(this.allEligibleAddOns)
    // this.additionalAddOns.forEach((item: any) => {
    //   item.selectedQuantity = this.data && this.data.SelectedAddOns.find(addOn => addOn.AddonId == item.AddonId) ? this.data.SelectedAddOns.find(addOn => addOn.AddonId == item.AddonId).Quantity : 0;
    //   item['IsVisible'] = item.IsVisible;
    //   item['MaxQuantity'] = item.MaxQuantity;      
    //   item['MinQuantity'] = item.MinQuantity;
    //   item['IsCategoryLevelMandatory'] = item.IsCategoryLevelMandatory;
    // })
    this.additionalAddOns = this.getAddOnsGroupedByCategory(this.additionalAddOns)
    this.ps.addOnList = _.flatten([_.flatten(_.map(this.mandatoryAddOns, 'Addons')), _.flatten(_.map(this.additionalAddOns, 'Addons'))]);
    console.log(this.allEligibleAddOns);
    this.updateFormValidity()
  }

  getAddOnQuantityForBooking(availableMandatoryAddOn) {
    let addOnList = [];
    this.ps.selectedSlot$.value?.forEach(addOnDetail => {
      addOnList.push(addOnDetail.AddOnAvailability?.find(data => data.AddonId == availableMandatoryAddOn.AddonId))
    })
    console.log(_.minBy(addOnList, 'AvailableQuantity'))
    return _.minBy(addOnList, 'AvailableQuantity')?.AvailableQuantity || 0
    // this.ps.selectedSlot$.value[0]?.AddOnAvailability?.find(data => data.AddonId == availableMandatoryAddOn.AddonId)?.AvailableQuantity || 0;
  }

  getAddOnsGroupedByCategory(addOnList) {
    return _.chain(addOnList)
      .groupBy("CategoryId")
      .map((value, key) => {
        return {
          CategoryId: value[0].CategoryId,
          Addons: value
        }
      })
      .value();
  }

  clearSearch(eve) {
    this.searchConfigForm && this.searchConfigForm.form && this.searchConfigForm.form.get('searchText').setValue('', { emitEvent: true });
  }

  customWildSearch(addOnData) {
    let values = [
      addOnData.AddonName,
    ]
    return values.map((itm: string | number) => itm ? itm.toString().toLowerCase() : '');
  }

  decreaseAddOn(addOn) {
    if (addOn.IsMandatory && addOn.selectedQuantity - 1 < addOn.MinQuantity && addOn.SelectionType != SelectionType.PerGuest) {
    }
    else if (addOn.IsMandatory && addOn.SelectionType == SelectionType.PerGuest && (addOn.selectedQuantity - 1 < this.ps.bookingSize)) {

    }
    else {
      if (addOn.selectedQuantity - 1 >= 0)
        addOn.selectedQuantity = addOn.selectedQuantity - 1;
      if (!addOn.IsUnlimited) {
        let overbook = addOn.selectedQuantity - addOn.AvailableQuantity;
        addOn.OverBooking = addOn.OverBooked = overbook > 0 ? overbook : 0;
      }
    }
    //  }
    this.updateFormValidity();
  }

  increaseAddOn(addOn) {
    if ((!addOn.IsUnlimited || (addOn.IsUnlimited && addOn.MaxQuantity)) && ((addOn.selectedQuantity == addOn.MaxQuantity) || ((!this.ps.selectedSlot$.value?.[0]?.isOverbook && addOn.SelectionType !== SelectionType.PerLesson && (addOn.selectedQuantity + 1) > addOn.AvailableQuantity)))) {
    }
    else if (addOn.IsMandatory && addOn.SelectionType == SelectionType.PerGuest && (addOn.selectedQuantity + 1 > this.ps.bookingSize)) {

    }
    else {
      addOn.selectedQuantity = addOn.selectedQuantity + 1;
      if (!addOn.IsUnlimited) {
        let overbook = addOn.selectedQuantity - addOn.AvailableQuantity;
        addOn.OverBooking = addOn.OverBooked = overbook > 0 ? overbook : 0;
      }
    }
    //   }
    this.updateFormValidity();
  }
  addonQuantityValidations(event, addon) {
    if (addon.SelectionType == SelectionType.PerLesson && (!addon.IsUnlimited || (addon.IsUnlimited && addon.MaxQuantity))) {
      addon.selectedQuantity = addon.IsMandatory ? addon.MaxQuantity : (event.target.value >= 1) ? 1 : 0;
    } else if (addon.SelectionType == SelectionType.PerGuest && addon.IsMandatory && (!addon.IsUnlimited || (addon.IsUnlimited && addon.MaxQuantity))) {
      addon.selectedQuantity = this.ps.bookingSize;
    } else if (addon.IsMandatory && (event.target.value == '' || event.target.value < addon.MinQuantity)) {
      addon.selectedQuantity = addon.MinQuantity;
      let overbook = addon.selectedQuantity - addon.AvailableQuantity;
      addon.OverBooked = addon.OverBooking = (this.ps.selectedSlot$.value?.[0]?.isOverbook && overbook) > 0 ? overbook : 0;
    } else if ((event.target.value == '' || event.target.value < addon.MinQuantity)) {
      addon.selectedQuantity = addon.OverBooked = addon.OverBooking = 0;
    } else if ((!addon.IsUnlimited || (addon.IsUnlimited && addon.MaxQuantity)) && (event.target.value > addon.MaxQuantity || event.target.value > addon.AvailableQuantity)) {
      if (this.ps.selectedSlot$.value?.[0]?.isOverbook) {
        addon.selectedQuantity = event.target.value > addon.MaxQuantity ? addon.MaxQuantity : event.target.value;
        let overbook = addon.selectedQuantity - addon.AvailableQuantity;
        addon.OverBooking = addon.OverBooked = overbook > 0 ? overbook : 0;
      } else if (addon.IsUnlimited && addon.MaxQuantity) {
        addon.selectedQuantity = addon.MaxQuantity;
      } else {
        addon.selectedQuantity = addon.AvailableQuantity < addon.MaxQuantity ? addon.AvailableQuantity : addon.MaxQuantity;
      }
    }
    if (Number(event.target.value) !== Number(addon.selectedQuantity)) {
      let message = ''
      const minsize = addon.MinQuantity;
      const maxsize = addon.MaxQuantity;
      this.ts.get('missMatchAddonQuantity', { minsize, maxsize }).subscribe(msgVal => message = msgVal);
      this._snackBar.open(message, this.ts.instant('closeText'), {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 3000,
      });
    }

  }

  updateFormValidity(value?) {
    this.popupService.formValid$.next(
      {
        isFormValid: this.isValidAddonQuantitySelected() && this.checkRequiredAddonsSelected(),
        currentTab: 1
      } as IFormValidDetails
    );

    // Changes made for new tabs component
    if (this.popupService.tabsActionData?.length) {
      this.popupService.tabsActionData[1].gotoNextTab = this.isValidAddonQuantitySelected() && this.checkRequiredAddonsSelected();
    }
    this.popupService.tabsActions$.next(this.popupService.tabsActionData);

  }

  isValidAddonQuantitySelected(): boolean {
    return !this.ps.addOnList.filter(addon => !addon.IsUnlimited && addon.OverBooked && !this.ps.selectedSlot$?.value?.[0]?.isOverbook).length;
  }

  checkRequiredAddonsSelected() {
    let isValid = true;
    let categoryLevel = this.ps.addOnList.filter(addOn => addOn.IsCategoryLevelMandatory);
    categoryLevel.forEach(element => {
      let addOnList = this.allEligibleAddOns.filter(addOn => addOn.CategoryId == element.CategoryId)
      let categoryName = this.cs.settings.value.Categories.find(category => category.Id === addOnList[0].CategoryId)?.Text;
      if (addOnList?.length && _.sumBy(addOnList, 'selectedQuantity') == 0) {
        let message = this.ts.instant('minCategoryAddonMessage' , {name : categoryName});
        if(!this.warningMessages?.includes(message)){
        this.warningMessages.push(message);}
        isValid = false;
      }
      else {
        if(this.warningMessages?.length){
        let index = this.warningMessages?.findIndex(message => message.includes(categoryName));
        if(index != -1) this.warningMessages?.splice(index, 1);
        }
      }
    });
    return isValid;
  }

  ngAfterViewInit() {
    this.searchConfigForm.form.valueChanges.subscribe((val: any) => {
      this.searchText = val.searchText;
    });
    this.subscriptions.add(this.ps.tabChange$.subscribe(value => {
      let getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, this.ps.selectedSpecialMealId);
      if (value == 1 && getAddOns.length > 0 && getAddOns.filter(addOn => addOn.IsVisible == true).length > 0) {

        this.updateFormValidity();
      }


    }));


    this.updateFormValidity();
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  validateAddOnsQtyOnChange(item) {
    setTimeout(() => {
      if (item.selectedQuantity)
        item.selectedQuantity = Math.floor(item.selectedQuantity);
    }, 10);
  }

}

@Pipe({
  name: 'getCategoryData'
})
export class GetCategoryDataPipe implements PipeTransform {
  transform(addOn: any, categories, property): any {

    let data = categories.find(category => category.Id == addOn.CategoryId)
    if (data) {
      return property == 'Color' ? `rgba(${data.Color.R}, ${data.Color.G}, ${data.Color.B}, ${data.Color.A})` : data.Text;
    }
    return '';

  }
}

