import { OnInit, Component, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { LoaderService } from '../services/loader.service';


@Component({
    selector: 'loader-component',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    encapsulation:ViewEncapsulation.None
})

export class LoaderComponent implements OnInit {
    public loading$;
    public showMessage$;
    public loaderMessage;
    public disableLoader$;
    constructor(public dialog: MatDialog, private ls:LoaderService){

    }

    ngOnInit(): void {
        this.loading$ = this.ls.isLoading;
        this.showMessage$ = this.ls.showMessage  
        this.disableLoader$ = this.ls.disableLoader;
        this.ls.loaderMessage.subscribe(text => {
            this.loaderMessage = text;
        }) 
        //throw new Error("Method not implemented.");
    }

}