import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
 name: 'CategoryPipe',
 pure: false
})
export class CategoryPipe implements PipeTransform{

 transform(array: Array<string>): Array<string> {

  if(!array || array === undefined || array.length === 0) return null;

    array.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

}