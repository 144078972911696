import { ActionType, ChangeAction, ChangedEntityType, GetPartyOptions, NotificationMessageType, OperationStatus, OperationType ,PartyType} from '@constants/commonenums';
import { SettingsDTO } from '@models/RestaurantDTO';

export class NotificationModelDTO {
    public OperationType: OperationType;
    public MessageType: NotificationMessageType;
    public RestaurantId: number;
    public UpdatedDataLists: UpdatedDataList[];
    public CacheDTOName: string;
}

export class NotificationMessageDto {
    //public OperationStatus: OperationStatus;
    public ChangedEntityType: ChangedEntityType;
    public RestaurantId: number;
    public SupportedProperties:number[]
    public Revision: number;
    public OperationName: any;
    public MerchantId : number;
    public HostId : number;
    public ConnectionId: string;
    public BroadcastType: string;
}

export class UpdatedDataList {
    public Action: ActionType;
    public JOSNUpdatedData: any;
    public UpdatedData: any;

}

export class UpdatedData {
    public Action: ChangeAction;
    public UpdatedDataJsonString: string;
    public UpdatedDataObject: any;

}

export class ChangeDTO
{
  public Revision : number;
  public Change : Change;
}

export class MessageRequestDto{
    public Message : NotificationMessageDto;
    public CacheService : any;
    public LastRevision : number;
    public worker: boolean;
    public RestaurantSettings : SettingsDTO;
    public CommonParams : CommonParamsDto;
    public Screen: any;
}

export class HeartBeatRequestDto{
    public DeviceId : number;
    public CommonParams : CommonParamsDto;
}

export class CommonParamsDto{
    public RestaurantId : number;
    public BaseUrl : string;
    public RestaurantApiKey : string;
    public MerchantKey : string;
    public ClientUid : string;
    public HostId : string;
    public StartDate?: string;
    public EndDate?: string;
    public Options? : GetPartyOptions;
    public PartyType? :  PartyType;
    public MerchantId? : number;
    public Username?: string;
    public Password?:string
    public LanguageId?: number;

}

export class Change{
    public AdditionalData : any;
    public ObjectChanges : any;
}

export enum AllowedSettingsPublish {
  SetReservationSettings = 53,
  SetGuestDataSettings = 54,
  SetBillingPlan = 55,
  SetDefaultSlot = 56,
  UpdateDefaultSlots = 57,
  SetMPHiddenAdvancedSettings = 58,
  RemoveRatePlan = 66,
  CreateRatePlan = 67,
  UpdateRatePlan = 68,
  RemoveSeatingArea = 73,
  CreateSeatingArea = 74,
  SetSeatingAreas = 75,
  UpdateSeatingArea = 76,
  RemoveSeatingType = 77,
  CreateSeatingType = 78,
  UpdateSeatingType = 79,
  SetServers = 80,
  UpdateServer = 81,
  SetShifts = 82,
  RemoveShift = 83,
  CloneShifts = 84,
  CreateShift = 85,
  UpdateShift = 86,
  SetStatuses = 89,
  SetShiftWebReservable = 99,
  DisableAutoSlots = 100,
  DeleteSpecialMeal = 106,
  SaveSpecialMeal = 107,
  SetContactPredefinedNotes = 108,
  SetPredefinedMessages = 109,
  SetPartyPredefinedNotes = 110,
  UpdatePredefinedPartyNote = 111,
  DeleteSeatingAreaLayoutImage = 112,
  UploadSeatingAreaLayoutImage = 113,
  SynchServers = 114,
  UpdateRestaurant = 123,
  CreateFloorPlan = 124,
  FloorPlanRename = 125,
  RemoveFloorPlan = 126,
  UpdateFloorPlan = 127,
  SetRestaurantNote = 131,
  EnableIgIntegration = 133,
//   ProcessInfoGenesisAction = 134,
  CreateHost = 142,
  UpdateHost = 143,
  RemoveHost = 144,
  PreRemoveFloor = 145,
  //AssignTableServers = 105,
  //BlockAllServerTables = 149,
  //ReassignTableServers = 150,
  //BlockAllTables = 147,
  SaveCustomGuestField = 151,
  DeleteCustomGuestFields = 152,
  DeleteCustomGuestFieldsWithContact = 153,
  CreateContactPredefinedNote = 155,
  UpdateContactPredefinedNote = 156,
  RemoveContactPredefinedNote = 157,
  CreatePredefinedMessage = 158,
  UpdatePredefinedMessage = 159,
  RemovePredefinedMessage = 160,
  SetSMSProxyRelay = 161,
  SetCustomWaitListMessage = 162,
  CreatePredefinedPartyNote = 163,
  RemovePredefinedPartyNote = 164,
  SetPricingOptions = 62,
  SetMinTimeToCancelReservation = 166,
  SetBasicSettings = 167,
  SetCountryForRestaurant = 168,
  SetRestaurantCustomPolicy = 169,
  UpdateRestaurantSettingDate = 170,
  UpdateReleaseNotesReadDate = 171,
  CreateServer = 172,
  RemoveServer = 173,
  DeleteCoverType = 174,
  UpdateVariablePricing = 175,
  SaveCoverType = 176,
  UpdateAutomaticRefunds = 177,
  CreateStatus = 178,
  UpdateStatus = 179,
  RemoveStatus = 180,
  MergeContacts = 181,
  SetReservationConfirmationEmailTemplates = 61,
  SetRateLimits = 64,
  SetDefaultManualTurnTime = 93,
  SetTurnTimesMode = 94,
  CloneManualTurnTime = 95,
  CreateManualTurnTime = 96,
  RemoveManualTurnTime = 97,
  UpdateManualTurnTime = 98,
  
  SetPostVisitSMS = 204,
  //Added from activities
    SaveCategory = 191,
    SaveSubCategory = 192,
    RemoveCategory = 193,
    RemoveSubCategory = 194,
    SaveOTAEmailTemplate = 195,       
    SaveActivityType = 196,
    DeleteActivityType = 197,
    ActivateOrDeActivateQuestion = 202,
    AddFeedbackdetails = 203,
    AddFeedbackQuestions = 204, //need to recode
    DeleteFeedbackdetails = 205,
    EditFeedbackQuestion = 206,
    ModifyFeedbackdetails = 207,
    SetThresholdRateDetails = 208,
    UploadFeedbackLayoutImage = 209,
    CreateAllocationType = 210,
    RemoveAllocationType = 211,
    UpdateAllocationType = 212,
    UpdateAllocationTypeStatus = 213,
    CreateSchedule = 214,
    RemoveSchedule = 215,
    UpdateSchedule = 216,
     CancelSession = 217,
    CancelActivity = 218,
    SetOpenHours = 227,
    CloneOpenHours = 228,
    SaveLesson = 229,
    DeleteLesson = 230,
    SetExpertiseLevels = 234,
    DeleteFeedbackLayoutImage = 238,
    DeleteBookingType = 239,
     SaveBookingType = 240,
  SetShowActivityFlagMerchant = 273
}

export enum ReLoadSettingsRequired{ // Add operation name here to initiate load call When ChangedEntityType is diff from Settings
  CreateHost = 142,
  UpdateHost = 143,
  RemoveHost = 144,
}

export interface ScreenChangeDTO {
  connectionId: string;
  oldscreenId: number;
  newscreenId: number;
  merchantId: number;
  restaurantId: number;
  loginState: number;
  CommonParams : CommonParamsDto;
}

export enum loginState {
  login = 0,
  logout = 1,
  screenChange = 2
}

export interface ChangesByScreenDTO {
  restaurantId: number;
  screenName: string;
  revisionId: number;
  CommonParams : CommonParamsDto;
}

export interface updateLatestRevision {
  restaurantId: number;
  CommonParams : CommonParamsDto;
}
