<div *ngIf="lf.specialEventName" class="specialEvent" [style.top]="lf.specialEventTop" [style.left]="lf.specialEventLeft"  >{{lf.specialEventName}}</div>

<div class="tables-layout">
  <div class="tables-layout__canvas-view" [ngClass]="{'tables-layout__canvas-view-height': isTablesScreen}" #canvasLoader >
    <div *ngIf="!isTablesScreen" >
      <canvas id="{{layoutConfig.from}}-tablesLayout"></canvas>
      <div id="{{layoutConfig.from}}-server-name"></div>
    </div>
    <div *ngIf="isTablesScreen" (pinchin)="pinchIn($event)" (pinchout)="pinchOut($event)"
      (swipeleft)="swipeFn($event)"
      (swiperight)="swipeFn($event)" (swipeup)="swipeFn($event)"
      (swipedown)="swipeFn($event)">
      <canvas id="{{layoutConfig.from}}-tablesLayout"></canvas>
      <div id="{{layoutConfig.from}}-server-name"></div>
    </div>
    <!-- <div class="tables-layout__server-initial" id="{{layoutConfig.from}}-inline-btn"></div> -->
  </div>
  <div class="tables-layout__action" *ngIf="isTablesScreen">
    <div class="tables-layout__zoom-view" *ngIf="isZoomAvailable">
      <activities-app-button [buttontype]="zoomInBtn" (click)="zoomIn()"></activities-app-button>
      <span class="tables-layout__percentage-value">{{percentageValue}}%</span>
      <activities-app-button [buttontype]="zoomOutBtn" (click)="zoomOut()"></activities-app-button>
    </div>
    <div class="tables-layout__fit-view" (click)="viewFullScreen()">
      <span class="icon-expand view-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
    </div>
  </div>
</div>

