import { Product } from '../globalsContant';
import { PaymentMethods } from '../business/shared.modals';

export const ProductPaymentMethods = {
    [Product.GOLF]: [
        PaymentMethods.Cash,
        PaymentMethods.CreditCard,
        PaymentMethods.RoomCharge,
        PaymentMethods.PendingSettlement,
        PaymentMethods.IDTECH,
        PaymentMethods.RainCheck,
        PaymentMethods.CardOnFile,
        PaymentMethods.ARPost,
        PaymentMethods.RedeemPoint,
        PaymentMethods.CreditBook,
        PaymentMethods.CompRedemption,
        PaymentMethods.OfferRedemption,
        PaymentMethods.V1GiftCard,
        PaymentMethods.ExternalGiftCard,
        PaymentMethods.V1GiftCardIdTech,
        PaymentMethods.ExternalGiftCardIdTech,
        PaymentMethods.ARAcctPost,
        PaymentMethods.AgilysysGiftCard,
        PaymentMethods.AgilysysGiftCardIdTech,
        PaymentMethods.CompSlipRedemption,
        PaymentMethods.GroupCharge,
        PaymentMethods.PostToFolio,
        PaymentMethods.Other,
        PaymentMethods.AuthorizePayment,
        PaymentMethods.Wallet,
        PaymentMethods.HotelComp
    ],
    [Product.SPA]: [
        PaymentMethods.Cash,
        PaymentMethods.CreditCard,
        PaymentMethods.RoomCharge,
        PaymentMethods.PendingSettlement,
        PaymentMethods.IDTECH,
        PaymentMethods.CardOnFile,
        PaymentMethods.ARPost,
        PaymentMethods.CreditBook,
        PaymentMethods.CompRedemption,
        PaymentMethods.OfferRedemption,
        PaymentMethods.V1GiftCard,
        PaymentMethods.ExternalGiftCard,
        PaymentMethods.V1GiftCardIdTech,
        PaymentMethods.ExternalGiftCardIdTech,
        PaymentMethods.ARAcctPost,
        PaymentMethods.AgilysysGiftCard,
        PaymentMethods.AgilysysGiftCardIdTech,
        PaymentMethods.CompSlipRedemption,
        PaymentMethods.GroupCharge,
        PaymentMethods.PostToFolio,
        PaymentMethods.Other,
        PaymentMethods.AuthorizePayment,
        PaymentMethods.Wallet,
        PaymentMethods.HotelComp
    ],
    [Product.SNC]: [
        PaymentMethods.Cash,
        PaymentMethods.CreditCard,
        PaymentMethods.RoomCharge,
        PaymentMethods.PendingSettlement,
        PaymentMethods.IDTECH,
        PaymentMethods.CardOnFile,
        PaymentMethods.ARAcctPost,
        PaymentMethods.GroupCharge,
        PaymentMethods.Wallet,
        PaymentMethods.HotelComp
    ],
    [Product.RETAIL]: [
        PaymentMethods.Cash,
        PaymentMethods.CreditCard,
        PaymentMethods.RoomCharge,
        PaymentMethods.PendingSettlement,
        PaymentMethods.IDTECH,
        PaymentMethods.RainCheck,
        PaymentMethods.CardOnFile,
        PaymentMethods.ARPost,        
        PaymentMethods.CreditBook,
        PaymentMethods.CompRedemption,
        PaymentMethods.OfferRedemption,
        PaymentMethods.V1GiftCard,
        PaymentMethods.ExternalGiftCard,
        PaymentMethods.V1GiftCardIdTech,
        PaymentMethods.ExternalGiftCardIdTech,
        PaymentMethods.ARAcctPost,
        PaymentMethods.AgilysysGiftCard,
        PaymentMethods.AgilysysGiftCardIdTech,
        PaymentMethods.CompSlipRedemption,
        PaymentMethods.GroupCharge,
        PaymentMethods.Other,
        PaymentMethods.Wallet,
        PaymentMethods.HotelComp
    ],
    [Product.PMS]: [
        PaymentMethods.Cash,
        PaymentMethods.CreditCard,
        PaymentMethods.RoomCharge,
        PaymentMethods.PendingSettlement,
        PaymentMethods.IDTECH,
        PaymentMethods.RainCheck,
        PaymentMethods.CardOnFile,
        PaymentMethods.ARPost,        
        PaymentMethods.CreditBook,
        PaymentMethods.CompRedemption,
        PaymentMethods.OfferRedemption,
        PaymentMethods.V1GiftCard,
        PaymentMethods.ExternalGiftCard,
        PaymentMethods.V1GiftCardIdTech,
        PaymentMethods.ExternalGiftCardIdTech,
        PaymentMethods.ARAcctPost,
        PaymentMethods.AgilysysGiftCard,
        PaymentMethods.AgilysysGiftCardIdTech,
        PaymentMethods.CompSlipRedemption,
        PaymentMethods.GroupCharge,
        PaymentMethods.Other,
        PaymentMethods.Wallet,
        PaymentMethods.HotelComp,
        PaymentMethods.AuthorizePayment,
    ]
};
