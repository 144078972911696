import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { urlConfig } from '@constants/url-config';
import { LoginDTO, LoginOperationTarget } from '@models/LoginResultDTO';
import { RestaurantDTO } from '@models/RestaurantDTO';
import { HttpService } from '@services/http.service';
import { Utilities } from '@utilities/utilities';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CoreModule } from '../core.module';


@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnDestroy {
  public myData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public restaurantSettings$: Observable<RestaurantDTO>;
  subscriptions: Subscription = new Subscription();
  errorMsg: any;
  public resetPinChange$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public _as: AppService, public _hs: HttpService, private router: Router) {
  }

  authorize() {
    let merchantKey = sessionStorage.getItem(`MerchantKey${Utilities.getSessionStorageType()}`);
    if (!merchantKey) {
      sessionStorage.clear();
      return;
    }
    return this._hs.get(urlConfig.authenticationManagerUrl + '?merchantKey=' + merchantKey);
  }

  authenticate() {
    return this.authorize();
  }

  login(pinCode) {
    if (!pinCode)
      return;
    //pinCode = encodeURIComponent(Utilities.encryptRSA(pinCode));
    let loginDTO = new LoginDTO()
    loginDTO.Pincode = Utilities.encryptRSA(pinCode);
    return this._hs.post(urlConfig.loginUrl + '?restaurantId=' + this._as.restaurantId + '&languageId=' +  sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`),loginDTO);
  }

  loginViaToken(token) {
    if (!token)
      return;
      let loginDTO = new LoginDTO()
      loginDTO.Token = token;
      return this._hs.post(urlConfig.loginViaTokenUrl + '?restaurantId=' + this._as.restaurantId + '&target=' + LoginOperationTarget.Restaurant + '&isSilverlight=' + true ,loginDTO);
  }

  unlockRestaurant(data) {
    if (!data.username || !data.password)
      return;
      let loginDTO = new LoginDTO();
      loginDTO.EmailAddress = data.username;
      loginDTO.Password = Utilities.encryptRSA(data.password);
      //loginDTO.Password = encodeURIComponent(loginDTO.Password);
    return this._hs.post(urlConfig.unlockRestaurantUrl,loginDTO);
  }

  verifyMerchantId(data) {
    if (!data.email) {
      return;
    }
    let loginDTO = new LoginDTO();
    // loginDTO.MerchantId = data.merchantId;
    loginDTO.EmailAddress = data.email;
    return this._hs.post(urlConfig.VerifyUserEmailOrMerchantIdUrl, loginDTO);
  }

  unlockWithAzureADB2CToken(data){
    if (!data.email || !data.token) {
      return;
    }
    let loginDTO = new LoginDTO();
    loginDTO.MerchantId = data.merchantId;
    loginDTO.EmailAddress = data.email;
    loginDTO.Token = data.token;
    return this._hs.post(urlConfig.UnlockWithAzureADB2CTokenUrl, loginDTO);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
