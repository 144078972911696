import { Component, EventEmitter, OnInit, Output, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { CacheService } from "@app/core/services/cache.service";

import { ComponentTypes, buttonTypes } from "@app/shared/constants/commonenums";
import { DynamicFormComponent } from "@app/shared/dynamicform/dynamic-form/dynamic-form.component";
import { ButtonValue, FieldConfig } from "@app/shared/dynamicform/models/field-config.interface";
import { PartyService } from "@app/shared/services/party.service";
import { Utilities } from "@app/shared/utilities/utilities";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";
import { Subscription } from "rxjs";
import { AvailableCartItem } from "../cart-items-display/cart-items-display.component";
import { ComponentDetails } from "@app/popup-module/models/popup.interface";
import { ConfirmationPopupComponent } from "../confirmation-popup/confirmation-popup.component";
import { PopupService } from "@app/popup-module/popup.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: 'select-cart-group',
    templateUrl: './select-cart-group.component.html',
    styleUrls: ['./select-cart-group.component.scss']
  })
  export class SelectCartGroup extends Utilities implements OnInit {
    buttonSelectGroup: ButtonValue;
    buttonAddNew: ButtonValue;
    searchText ='';
    @Output() selectCartGroup = new EventEmitter<any>();
    @ViewChild('searchConfigForm', { static: true }) searchConfigForm: DynamicFormComponent;
    subscriptions: Subscription = new Subscription();
    searchConfig: FieldConfig[];
    public cartGroups: {
        CartGroupName: string,
        ItemCount: number;
        ExpireAt: string;
        AddToGroupEnabled: boolean;
    }[]

    constructor(private partyService: PartyService, public cs: CacheService,dialog:MatDialog,private ts:TranslateService, private availableCartItem: AvailableCartItem,private ps:PopupService){
      super(dialog)  
      this.buttonSelectGroup = {
            type: buttonTypes.actionSecondarySmall,
            label: 'Add to Group',
            customclass: 'next-btn',
            disbaledproperity: false
          }
          this.buttonAddNew = {
            type: buttonTypes.actionPrimarySmall,
            customclass: 'next-btn',
            label: 'Add to New',
            disbaledproperity: false
          }
    }

    ngOnInit(): void {
        this.cartGroups = Object.entries(_.groupBy(this.partyService.TotalCartBookingObj, 'CartGroupName')).map(([key, val]) => {
            let _totalAmt = val.reduce((val, curr) => {
                return val + curr.CartItemDetail.rateSummary ? (curr.CartItemDetail.rateSummary.Amt + 
                            curr.CartItemDetail.rateSummary.SCAmt +
                            curr.CartItemDetail.rateSummary.TaxAmt +
                            curr.CartItemDetail.rateSummary.TaxOnSCAmt) : 0;
            }, 0);
            let {availableCartItemValue} = this.availableCartItem.transform(val);
            return {
                CartGroupName: key,
                ItemCount: val.length,
                ExpireAt: availableCartItemValue?.CartItemDetail?.ExpireAt,
                TotalAmt: _totalAmt,
                AddToGroupEnabled: val.length ? this.cs.propertySettings.value[val[0].PropertyId].settings.PropertySetting[0].TaxType == this.cs.settings.value.PropertySetting[0].TaxType : true,
                Name: (val[0].CartItemDetail.Contact.FirstName || '') + ' ' +(val[0].CartItemDetail.Contact.LastName || '')
            }
        });

        this.searchConfig = [
            {
              type: 'input',
              name: 'searchText',
              label: this.ts.instant('cartGroupSearchText'),
              class: 'search-text',
              showErrorText: true,
              appearance: true,
              icon: 'icon-search',
              icon1: 'icon-Group-591',
              cellClick: this.clearSearch.bind(this)
            }]
    }

    cartGroupSelectHandler(groupData){
      if(groupData && !groupData.AddToGroupEnabled){
        this.openConfirmationDialog();
        return;
      }
        this.selectCartGroup.emit(groupData);
    }
    ngAfterViewInit() {
        this.subscriptions.add(this.searchConfigForm.form.valueChanges.subscribe((formValue) => {
          this.searchText = formValue.searchText;
        }));

  }

  openConfirmationDialog() {
    let msg = this.ts.instant('addToCartValidationMsg');

    const popUpMessage = [{
       confirmationMessage: msg,
       dialogTitle: this.ts.instant('informationText')
    }];
    let okbutton = this.ts.instant('ok');
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
       popUpMessage, popUpMessage[0].dialogTitle);
    let dialogRef = this.openCustomPopup(componentDetails, ComponentTypes.activityCreateConfirmation, '400px', '320px', true, '', okbutton,
       '', true);
    let popupSubscription = this.ps.confirmedAction$.subscribe(val => {
       // popupSubscription.unsubscribe();
    })
    popupSubscription = this.subscriptions.add(dialogRef.afterClosed().subscribe(val => {
       if (val == ComponentTypes.activityCreateConfirmation) {
          popupSubscription.unsubscribe();
       }
    }));
 }
  clearSearch() {
    this.searchConfigForm.form.get('searchText').reset();
  }
}

@Pipe({
  name: 'searchGroup'
})
export class SearchGroupPipe implements PipeTransform {

  transform(array, searchText): any {
    if(searchText){
     return array.filter(data => {
      return(
      (data.CartGroupName ? data.CartGroupName.toLowerCase() : '').includes(searchText) || 
      (data.Name ? data.Name.toLowerCase() :'').includes(searchText)
     )});
    }
    return array;
    }
}

@Pipe({
  name: 'buttonEnable'
})
export class ButtonEnablePipe implements PipeTransform {

  transform(buttonType:ButtonValue, cartGroup): any {
    if(buttonType){
      buttonType.disbaledproperity = !cartGroup.AddToGroupEnabled;
      return {...buttonType};
    }
}
}


