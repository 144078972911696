<div class="retailCustomDataTable retail-CustomDataTable-theme" id="table_{{SelectedSettingId}}" (window:resize)="viewCheckedFlag = false">
  <form class="w-100 h-100" >

      <div class="fixed-table-container" id="fixed-table-container" [ngClass]="{'noSort': bodyArray.length < 1}">            
        <label *ngIf="functionalities[ShowNoOfRecordInGrid]" class="ag_w--100 total-count-label text mr-2">
          {{totalMsg}} </label>   
        <div class="w-100 position-absolute grid-table" id="SPACustomTable">
          <ng-scrollbar  >
            <table aria-describedby="" class="table mb-0 tbl-cellspacing">
              <thead>
                <tr id="SPAHeaderRow">
                  <th *ngFor="let hdrCnt of hdrArray;let hi=index" id="header{{hi}}"  [ngClass]="[hdrCnt.alignType=='right' ? 'text-right' : hdrCnt.alignType=='center' ? 'text-center':'',hdrCnt.sortable != undefined ? (hdrCnt.sortable ? '': 'pointerevents-none') : '', isEditModeSet?'cursor-default':'']"
                  [class.cursordefault]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? false: true) : false"
                  [class.cursor]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? true: false) : true"  (click)="sortingFunc(hdrCnt.jsonkey,hi,'Frmtable', hdrCnt.sortcolumn, hdrCnt.sortcolumndatatype)">
                    <div class="th-inner" [class.font-weight-bold]="selectedDefaultHeader == hdrCnt.jsonkey && (bodyArray | filterPipe: searchText : hdrkeyArray).length > 0 && hdrCnt.sortable != undefined ? hdrCnt.sortable : (selectedDefaultHeader == hdrCnt.jsonkey && bodyArray.length > 0)">
                      {{hdrCnt.title}}
                      <span *ngIf="hdrCnt.isPercentage">{{captions.common.Percentage}}</span>
                      <span class="pl-1" *ngIf="(hdrCnt.jsonkey != '' && hdrCnt.sortable == true) && ((bodyArray | filterPipe: searchText : hdrkeyArray).length != 0)">
                          <i  attr.automationId= 'Icn_{{automationId}}_sort{{hi}}' aria-hidden="true" id="sortArrow{{hi}}" *ngIf="hdrCnt.jsonkey != '' && hdrCnt.sortable != undefined ? hdrCnt.sortable : true && (bodyArray | filterPipe: searchText : hdrkeyArray).length != 0"
                            [ngStyle]="{'opacity':selectedDefaultHeader == hdrCnt.jsonkey && !isEditModeSet ? '1' : '0.3' }"
                            [ngClass]="selectedDefaultHeader == hdrCnt.jsonkey && orderType == 'asc' ? 'icon-filled_up_arrow position-absolute deg0 IC6': selectedDefaultHeader == hdrCnt.jsonkey && orderType =='desc' ?
                        'icon-filled_up_arrow deg180 position-absolute IC6': 'icon-sortArrow sort-icon'">
                          </i>
                        </span>
                    </div>
                  </th>
                  <th  scope='col'  scope='col'  scope='col' class="text-right" *ngIf="!isRoleSetUpReadOnly && EnableActions" [ngClass]="{'column-sticky': sticky, 'minwidth177':DoneCancel, 'minwidth100':!DoneCancel}">
                    <div class="th-inner  ">{{captions.setting.Actions}}</div>
                  </th>
                </tr>
              </thead>
              <tbody class=" h-100" flex >
                <tr [ngClass]="{'highlight' :EnableEdit[bi], 'onEdit':isEdit, 'editRow':editableRow == bodyCnt.id}"
                  id="{{bodyCnt.id}}" *ngFor="let bodyCnt of (bodyArray | filterPipe: searchText : hdrkeyArray | sortPipe : orderType : selectedDefaultHeader : overriddenSortColumn : sortColumnDataType);let bi=index;"
                  >
                  <td *ngFor="let hdrCnt of hdrArray;let hi=index" [ngClass]="[hdrCnt.alignType=='right' ? 'text-right' : 'hello',hdrCnt.alignType=='center' ? 'text-center' : '',EnableEdit[bi]?'pr-0':'']"
                    class="table-data">
                    <span class="content d-inline-block textellipsis" id="content">
                     
                    <span *ngIf="(!hdrCnt.isEdit || !EnableEdit[bi]) && hdrCnt.datatype && hdrCnt.datatype == 'decimal'">
                        {{bodyCnt[hdrCnt.jsonkey] }}%
                      </span>
                      <span  *ngIf="(!hdrCnt.isEdit || !EnableEdit[bi]) && hdrCnt.datatype != 'decimal'" [ngClass]="{'anchor-like': hdrCnt.type == 'clickable'}" (click)="lblclick(bodyCnt, bi , hdrCnt.type)">
                        {{(SetColumnGridValue | setcolumnvalue : bodyCnt : hdrCnt.jsonkey)}}
                      </span>
                      <span  *ngIf="EnableEdit[bi]">
                          <input  attr.automationId= 'Txt_{{automationId}}_text' type="text" *ngIf="hdrCnt.isInput && hdrCnt.type == 'text'"
                              [(value)]="bodyCnt[hdrCnt.jsonkey]"  >
                              <input attr.automationId= 'Txt_{{automationId}}_number' type="text" *ngIf="hdrCnt.isInput && hdrCnt.type == 'number'" id="{{hdrCnt.jsonkey}}{{bi}}"   percentinputtype='onlynumber,decimal,validPercentage,preMaxdecimal' [maxpredecimalValue]="maxLength"
                              [(ngModel)]="bodyCnt[hdrCnt.jsonkey] == 0 ?  LoadDecimalValue(0) : bodyCnt[hdrCnt.jsonkey] " (input)="fieldEdit($event,bodyCnt,hdrCnt.jsonkey,bi)" name="{{hdrCnt.jsonkey}}{{bi}}">
                          </span>
                  </span>
                </td>
                <td *ngIf="!isRoleSetUpReadOnly && EnableActions && !(SelectedSettingId == GridType.password)" [ngClass]="{'column-sticky': sticky,'minwidth177':DoneCancel}" class="noellipsis text-right">
                    <!-- Icon Edit -->
                  <button attr.automationId= 'Txt_{{automationId}}_edit' class="icon-Edit cursor pr-2" [class.cursordefault]="!isEditModeSet" [class.cursordefault]="isEditModeSet" [ngClass]="IsViewOnly || IsRetailCodeSetup ||_disableAction ? 'button_invalid' : ''"
                    *ngIf="editable && !EnableEdit[bi]" title="{{captions.common.Edit}}"
                    (click)="checkValidEdit(bi);" [disabled]="isEditModeSet && edittingIndex !=bi"></button>

                    <!-- Icon Delete -->
                  <button attr.automationId= 'Txt_{{automationId}}_delete' *ngIf="!disableDelete && !EnableEdit[bi]" [class.cursordefault]="!isEditModeSet" [class.cursordefault]="isEditModeSet" class="icon-recurring cursor pr-2"
                    [ngClass]="IsViewOnly|| disableEditButton ? 'button_invalid' : ''" title="{{captions.common.reset}}" (click)="DeleteRecords(bodyCnt)" [disabled]="isEditModeSet && edittingIndex !=bi"></button>

                  <!-- Done -->
                  <span *ngIf="EnableEdit[bi]" class="done" (click)="Done(bodyCnt, bi)">
                    <i  attr.automationId= 'Txt_{{automationId}}_done' aria-hidden="true" class="icon-done"></i>{{captions.common.cDone}}</span>

                  <!-- Cancel -->
                  <span *ngIf="EnableEdit[bi]" class="cancel" (click)="Cancel(bodyCnt, bi)">
                    <i  attr.automationId= 'Txt_{{automationId}}_cancel' aria-hidden="true" class="icon-Cancel"></i>{{captions.common.cCancel}}</span>
                </td>
              </tr>
              <tr *ngIf="(bodyArray | filterPipe: searchText : hdrkeyArray).length == 0 && !isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1" class="text-center">
                  {{captions.common.NoDataFound}} </td>
              </tr>

              <tr *ngIf="isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1" class="text-center">{{captions.common.loadingData}}</td>
              </tr>
            </tbody>
          </table>
        </ng-scrollbar>
      </div>
    </div>
    
  </form>
</div>
