<section class='journal-code-wrapper ag_height--100'>
    <div class="ag_height--100">
        <div>
            <app-dialog-header (closeDialog)="close()"></app-dialog-header>
        </div>
        <div class="wrapper-sect">
            <app-post-type-mapping (DataEmitter)="tableDataemit($event)"
                [intgrationsetupValues]="intgrationsetupValues"></app-post-type-mapping>
        </div>

    </div>

</section>