import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationInput } from '../../interfaces/common';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DialogContainerComponent } from '../dialog-container/dialog-container.component';

@Component({
  selector: 'respos-confirmation-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, DialogContainerComponent],
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationInput) { }


  confirm(): void {
    this.dialogRef.close(this.data?.inputData || true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }


}
