<form [formGroup]="customFormGrp" class="date-ctrl">
    <div [ngSwitch]="type">
        <ng-container *ngSwitchCase="'DEFAULT'">
            <app-ag-date-picker [customPlaceholderLabel]="customPlaceholder" [inputs]="inputConfig" (datePickerChange)="onDateChange($event)">
            </app-ag-date-picker>
  
        </ng-container>
        <ng-container *ngSwitchDefault>
            <app-ag-date-picker [customPlaceholderLabel]="customPlaceholder" [inputs]="inputConfig">
            </app-ag-date-picker>
  
        </ng-container>
    </div>
  </form>