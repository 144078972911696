import { Component,  Input, SecurityContext, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageData } from '../../shared/business/shared.modals';
import * as _ from 'lodash';

@Component({
  selector: 'app-retail-img-thumbnail',
  templateUrl: './img-thumbnail.component.html',
  styleUrls: ['./img-thumbnail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailImgThumbnailComponent  {

  @Input() imageContent: ImageData;
  @Input() thumbnail: boolean;
  @Input() imageType: string;
  url: any;
  constructor(private domSanitizer: DomSanitizer) { }


  ngOnChanges() {

    if (this.imageContent && !_.isEmpty(this.imageContent) && this.imageContent.contentType) {
      let imageUrl = `data:${this.imageContent.contentType};base64,${this.imageContent.thumbnailData}`;
      // this.url = this.domSanitizer.sanitize(SecurityContext.URL,this.domSanitizer.bypassSecurityTrustUrl(imageUrl));
      this.url = imageUrl;
    } else {
      if (this.imageType === 'therapist') {
        this.url = 'assets/images/therapist.png';
      } else if (this.imageType === 'client') {
        this.url = 'assets/images/client.png';
      } else if (this.imageType === 'user') {
        this.url = 'assets/images/user.png';
      } else if (this.imageType === 'retailItem') {
        this.url = 'assets/images/shop/emptyshop.jpg';
      } else if (this.imageType === 'giftcard') {
        this.url = 'assets/images/therapist.png';
      }
    }
  }

}
