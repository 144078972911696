import { Processor } from './processor';
import { ObjectChange } from '@app/shared/models/ChangeTrackingOperationResultDTO';
import { ChangeAction, TableBlockingRuleFrequencyType } from '@app/shared/constants/commonenums';
import { CacheService } from '@app/core/services/cache.service';
import { TablesService } from '@app/shared/services/tables.service';
import { TableBlockingRuleDTO } from '@app/shared/models/TableBlockingDTO';
import { PartyService } from '@app/shared/services/party.service';
import { Utilities } from '@app/shared/utilities/utilities';

export class TableBlockingRuleChangeProcessor implements Processor {
  private static instance: TableBlockingRuleChangeProcessor;
  private constructor(public cs: CacheService, public ts: TablesService, public ps: PartyService) {
  }

  public static Instance(_cs: CacheService, _ts: TablesService, _ps: PartyService): TableBlockingRuleChangeProcessor {
    if (!TableBlockingRuleChangeProcessor.instance) {
      TableBlockingRuleChangeProcessor.instance = new TableBlockingRuleChangeProcessor(_cs, _ts, _ps);
    }
    return TableBlockingRuleChangeProcessor.instance;
  }

  Process(objectChangeRule: ObjectChange, additiondata: object, propertyId: number): void {
    let blockedTable = new TableBlockingRuleDTO();
        let overallTableBlocking: TableBlockingRuleDTO[];
        if (propertyId == Utilities.RestaurantId()) {
            overallTableBlocking = this.ts.ss.blockingdataChange$.value ? this.ts.ss.blockingdataChange$.value
                : [];
        }
        else {
            overallTableBlocking = this.cs.propertySettings.value[propertyId].tableBlockingRules
        }
    blockedTable = new TableBlockingRuleDTO();
    blockedTable.Frequency = { Type: null, Date: null, DayOfWeek: null };
    this.ps.updateTableAvailablity$.next(true);
    if (objectChangeRule.Action === ChangeAction.Created) {
      objectChangeRule.PropertyChanges.forEach(property => {
        if (property.PropertyName === 'StartDate') {
          blockedTable.StartDate = property.Value;
        }
        if (property.PropertyName === 'EndDate') {
          blockedTable.EndDate = property.Value;
        }
        if (property.PropertyName === 'FrequencyTypeInternal') {
          blockedTable.Frequency.Type = JSON.parse(property.Value);
        }
        if (property.PropertyName === 'Id') {
          blockedTable.Id = JSON.parse(property.Value);
        }
if (property.PropertyName === 'StartDate') {
                    blockedTable.StartDate = property.Value;
                }
                if (property.PropertyName === 'BlockBy') {
                    blockedTable.BlockBy = property.Value;
                }
                if (property.PropertyName === 'BlockingReason') {
                    blockedTable.BlockingReason = property.Value;
                }
                if (property.PropertyName === 'AllocationTypeId') {
                    blockedTable.AllocationTypeId = property.Value;
                }
      });
      blockedTable.Shifts = [];
      blockedTable.TableIds = [];
      this.ruleSaveProcess(blockedTable, overallTableBlocking, true,propertyId);
    }
    else if (objectChangeRule.Action === ChangeAction.Removed) {
      objectChangeRule.PropertyChanges.forEach(property => {
        if (property.PropertyName === 'Id') {
          blockedTable.Id = JSON.parse(property.Value);
        }
      });
      this.ruleSaveProcess(blockedTable, overallTableBlocking, false,propertyId);
    }
    else if (objectChangeRule.Action === ChangeAction.Updated) {
      blockedTable.Id = objectChangeRule.ObjectId;
      objectChangeRule.PropertyChanges.forEach(property => {
        if (property.PropertyName === 'StartDate') {
          blockedTable.StartDate = property.Value;
        }
        if (property.PropertyName === 'EndDate') {
          blockedTable.EndDate = property.Value;
        }
        if (property.PropertyName === 'FrequencyTypeInternal') {
          blockedTable.Frequency.Type = JSON.parse(property.Value);
        }
        if (property.PropertyName === 'BlockBy') {
          blockedTable.BlockBy = property.Value;
        }
        if (property.PropertyName === 'BlockingReason') {
          blockedTable.BlockingReason = property.Value;
        }
        if (property.PropertyName === 'AllocationTypeId') {
          blockedTable.AllocationTypeId = property.Value;
        }
      });
      if (overallTableBlocking && overallTableBlocking.length > 0) {
        let objIndex = overallTableBlocking.findIndex((obj => obj.Id == blockedTable.Id));
        if (objIndex != null && objIndex != undefined) {
          if (overallTableBlocking[objIndex]) {
            blockedTable.Shifts = overallTableBlocking[objIndex].Shifts;
            blockedTable.TableIds = overallTableBlocking[objIndex].TableIds;
            if (!blockedTable.StartDate) {
              blockedTable.StartDate = overallTableBlocking[objIndex].StartDate;
            }
            if (!blockedTable.EndDate) {
              blockedTable.EndDate = overallTableBlocking[objIndex].EndDate;
            }
            if (blockedTable.Frequency && blockedTable.Frequency.Type == null) {
              blockedTable.Frequency.Type = overallTableBlocking[objIndex].Frequency.Type;
            }
            if (!blockedTable.BlockBy) {
              blockedTable.BlockBy = overallTableBlocking[objIndex].BlockBy;
            }
            if (!blockedTable.BlockingReason) {
              blockedTable.BlockingReason = overallTableBlocking[objIndex].BlockingReason;
            }
            if (!blockedTable.AllocationTypeId) {
              blockedTable.AllocationTypeId = overallTableBlocking[objIndex].AllocationTypeId;
            }
          }
        }
      }
      this.ruleSaveProcess(blockedTable, overallTableBlocking, true,propertyId);
    }
  }
  ruleSaveProcess(blockedTable, newoverallTableBlocking, isaddupdate,propertyId) {
    if (isaddupdate) {
      if (blockedTable.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay) {
        blockedTable.Frequency.Date = blockedTable.StartDate;
        blockedTable.Frequency.DayOfWeek = null;
      }
      else if (blockedTable.Frequency.Type == TableBlockingRuleFrequencyType.EveryWeekDay) {
        if (blockedTable.Shifts && blockedTable.Shifts.length > 0) {
          let shiftName = this.cs.settings.value.Shifts.filter(val => val.Id == blockedTable.Shifts[0].Id);
          blockedTable.Frequency.Date = null;
          blockedTable.Frequency.DayOfWeek = shiftName[0].DayOfWeek;
        }
      }
      else {
        blockedTable.Frequency.Date = null;
        blockedTable.Frequency.DayOfWeek = null;
      }
    }
    if (newoverallTableBlocking && newoverallTableBlocking.length > 0) {
      newoverallTableBlocking = newoverallTableBlocking.filter(function (obj) {
        return obj.Id !== blockedTable.Id;
      });
    }
    if (isaddupdate) {
      newoverallTableBlocking.push({ ...blockedTable });
    }
    this.ts.overallTableBlockingRules = newoverallTableBlocking;    
    this.cs.propertySettings.value[propertyId].tableBlockingRules = newoverallTableBlocking;
    this.ts.ss.blockingdataChange$.next(this.cs.propertySettings.value[propertyId].tableBlockingRules);
  }

}