import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { Outlet } from '../../retail.modals';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { BaseResponse, popupConfig } from '../../shared/business/shared.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { AlertType } from '../../shared/shared.modal';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';

@Component({
  selector: 'app-retail-vendor-types',
  templateUrl: './retail-vendor-types.component.html',
  styleUrls: ['./retail-vendor-types.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailVendorTypesComponent implements OnInit {

  FormGrp: UntypedFormGroup;
  Outlets: Outlet[] = [];
  selectedPackageComponent: any[] = [];
  tableoptions: any[];
  captions: any;
  currIndex: any;
  deletedIndex: any;
  IsViewOnly: boolean;
  allVendorTypes: any;
  maxListOrder: number;
  @Input() popupConfigs: popupConfig;
  @Input() DragEvent;
  onEditDisableFunction:boolean = true;
  
  constructor(private Form: UntypedFormBuilder, private http: HttpServiceCall, private retailService: RetailSetupService,
     public localization: RetailLocalization, private utils: RetailUtilities) {
  }

  ngOnInit() {
    this.FormGrp = this.Form.group({
      TerminalID: []
    });
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.VendorType).view;
    this.captions = this.localization.captions.retailsetup;
    this.getallVendorTypes();
  }

 checkForMinValidation(vendorType): boolean {
    let valid: boolean = true;
    if ((vendorType.value.controls.setupCodeName.value.trim().length < 3)) {
        let errMsg:string = this.localization.getError(100713);
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
        valid = false;
    }
    return valid;
}

  dragDrop(event: any) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    const dragIndex = event[0];
    const dropIndex = event[1];

    var currentIndex = this.tableoptions[0].TablebodyData[dragIndex - 1];
    var droppedIndex = this.tableoptions[0].TablebodyData[dropIndex - 1];

    const vendorTypeObj = {
      Id : currentIndex.id,
      IsActive: currentIndex.isActive,
      Description: currentIndex.vendorType,
      ListOrder: droppedIndex.listOrder,
    };

    this.http.CallApiWithCallback({
      host: myGlobals.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      callDesc: "UpdateVendorType",
      method: HttpMethod.Put,
      body: vendorTypeObj,
      showError: true,
      extraParams: []
    });
  }

  async Done(event: any) {

    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == event.id);
    
    const vendorTypeObj = {
      Id : this.tableoptions[0].TablebodyData[this.currIndex].id,
      IsActive: this.tableoptions[0].TablebodyData[this.currIndex].isActive,
      Description: this.tableoptions[0].TablebodyData[this.currIndex].vendorType,
      ListOrder: this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
    };
     this.http.CallApiWithCallback({
       host: myGlobals.Host.retailManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "UpdateVendorType",
        uriParams: {id : this.tableoptions[0].TablebodyData[this.currIndex].id},
        method: HttpMethod.Put,
        body: vendorTypeObj,
        showError: true,
        extraParams: []
    });
  }

  addvendorType(data: any) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    let listOrderArray = this.tableoptions && this.tableoptions[0].TablebodyData && this.tableoptions[0].TablebodyData.map(e=> e.listOrder);
    let maxListOrder = listOrderArray && listOrderArray.length > 0 ? Math.max(...listOrderArray) : 0;

    if (data.type.toLowerCase() == this.localization.captions.setting.Add.toLowerCase() && this.checkForMinValidation(data)) {

      const vendorTypeObj = {
        IsActive: true,
        Description: data.value.controls.setupCodeName.value.trim(),
        ListOrder: maxListOrder + 1,
      };

      this.http.CallApiWithCallback({
        host: myGlobals.Host.retailManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "CreateVendorType",
        method: HttpMethod.Post,
        body: vendorTypeObj,
        showError: true,
        extraParams: []
      });
     
    } 
    
    else if (data.type.toLowerCase() == this.localization.captions.setting.update.toLowerCase() && this.checkForMinValidation(data)) {

      const vendorTypeObj = {
        Id:this.tableoptions[0].TablebodyData[this.currIndex].id,
        IsActive: data.value.controls.activetoggle.value,
        Description: data.value.controls.setupCodeName.value.trim(),
        ListOrder: this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
      };

      this.http.CallApiWithCallback({
        host: myGlobals.Host.retailManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "UpdateVendorType",
        method: HttpMethod.Put,
        body: vendorTypeObj,
        showError: true,
        extraParams: []
      });
      
    }

    return true;
  }

  EditRecords(data?: any, type?: any) {
    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == data[0].id);
  }

  DeleteRecords(event: any) {
    let errorMsg = this.localization.getError(75007);
    this.deletedIndex=event[0];
    errorMsg = this.localization.replacePlaceholders(errorMsg, ["VendorType"], [this.deletedIndex.vendorType])
    this.utils.showAlert(errorMsg, AlertType.Warning, myGlobals.ButtonType.YesNo, this.PopupCallback.bind(this), event[0]);
  }

  async PopupCallback(result: string, extraParams?: any) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    if (result.toLowerCase() == "yes") {
        this.http.CallApiWithCallback({
          host: myGlobals.Host.retailManagement,
          success: this.successCallback.bind(this),
          error: this.errorCallback.bind(this),
          callDesc: "DeleteVendorType",
          uriParams: {id : this.deletedIndex.id},
          method: HttpMethod.Delete,
          showError: true,
          extraParams: []
        });
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if(callDesc == "DeleteVendorType" || callDesc == "UpdateVendorType"|| callDesc == "CreateVendorType"){
      this.getallVendorTypes();
    }
    else if (callDesc == "GetVendorTypes") {
      let response = <any>result.result;
      this.allVendorTypes = [];
      response.forEach(element => {
        let vendorType: any = {
          id: element.id,
          vendorType: element.description,
          isActive: element.isActive,
          listOrder: element.listOrder
        }
        this.allVendorTypes.push(vendorType);
      });
      this.BindToGrid();
    }
    this.utils.ToggleLoader(false);
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
  console.error(error.result);
  }

  private getallVendorTypes(){
     this.http.CallApiWithCallback<any>({
       host: myGlobals.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetVendorTypes",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }


  private async BindToGrid() {

    this.tableoptions = [
      {
        TableHdrData: [{ 'title': this.captions.VendorTypes, 'jsonkey': 'vendorType', 'alignType': 'left', 'sortable': true },
        { 'title': this.captions.Active, 'jsonkey': 'isActive', 'alignType': 'left', 'type': 'toggle', 'sortable': true },
        { 'title': this.captions.ListOrder, 'jsonkey': 'listOrder', "alignType": "right", 'sortable': true, "searchable": false }],
        TablebodyData: this.allVendorTypes,
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.Search,
        EnableActions: true,
        SelectRows: true,
        IsCommission: true,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.vendorType,
        Sortable: 'listOrder',
        TableId: myGlobals.GridType.vendorType,
        disableDelete: false,
        customHeader: true,
        pageTitle: 'vendorType',
        ServiceId: 'vendorType',
        IsViewOnly: this.IsViewOnly,
        TableDraggable: true,
        automationId: 'retailVendorTypes'
      }
    ];
  }

}
