<section class="seat-reservation-type">
 <div class="create-reservation" (click)="selectReservationType(ReservationType.Reservation)">
    <i class="icon-open-book-2 show-icon"></i>
   <span class=" reservation-label"  >{{'createreservationbuttontext' | translate}}</span>
 </div>
 <div class="create-waitlist" (click)="selectReservationType(ReservationType.Waitlist)">
    <i class="icon-ic_update_24px show-icon"></i>
  <span class="waitlist-label" >{{'addWaitlistButton' | translate}}</span>
 </div>
</section>

