<div class="submodule-container">
    <app-table-search-header [attr.automationId]="'Srch_taxExemptCategory_search'" [searchHeaderOption]="searchHeaderOption" (searchChange)="searchChange($event)"
        (showInactive)="showInactiveToggler($event)" (createType)="createEvent($event)">
        <app-input-header-action [disabled]="disableInputHeader" [editActionObj]="editObj" [clearInput]='clearInput' [noSpaceAllowed]='noSpaceAllowed'
            [inputPlaceholder]="inputPlaceHolder" [minValue]="3" [codeMaxLength]="6" (save)="saveCancelCodes($event)"
            (formReady)="formReady($event)" (cancel)="cancel($event)"></app-input-header-action>
    </app-table-search-header>
    <div class="table-container">
        <app-cdkvirtual [attr.automationId]="'Tbl_taxExemptCategory_taxExemptCategory'" [IsEditModeEnabled]="IsEditModeEnabledFlag"  [headerOptions]="headerOptions" [tableContent]="tableContent | async" [options]="options"
            [searchOptions]="searchText" (EmittedData)='tableAction($event)'></app-cdkvirtual>
    </div>
</div>
