import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Host, RetailBreakPoint, ButtonType } from '../shared/globalsContant';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { RetailLocalization } from '../common/localization/retail-localization';
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { BaseResponse } from '../retail.modals';
import { AlertAction } from 'src/app/common/shared/shared.modal';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonTypes } from 'src/app/common/Models/common.models';
import { AlertMessagePopupComponent } from '../shared/alert-message-popup/alert-message-popup.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-category-linking',
  templateUrl: './category-linking.component.html',
  styleUrls: ['./category-linking.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategoryLinkingComponent implements OnInit {
  disableSyncUpBtn: boolean;
  subscription: Subscription;
  catergoryArray: Array<any> = [];
  subCategoryOneArray: Array<any> = [];
  primaryCategoryLabel: string;
  secondaryCategoryLabel: string;
  primaryCategoryArray: Array<any> = [];
  secondaryCategoryArray: Array<any> = [];
  subCategoryoptionArray: Array<any> = [];
  selectedDefault: number;
  ifshowSelectCategory: boolean;
  categoryGroupArray: Array<any> = [];
  subCategoryFour: Array<any> = [];
  subCategoryFive: Array<any> = [];
  IsViewOnly: boolean;
  categories: Array<any> = [];
  subCategoryOne: Array<any> = [];
  subCategoryTwo: Array<any> = [];
  subCategoryThree: Array<any> = [];
  categoryLinkingArray: Array<any> = [];
  categoryType: any;
  subcategorySelectedType: number;
  selectCategory: boolean;
  captions: any;
  currentCategory: number;
  commonCaptions: any;
  subCatValueForCategorySelection: number = 1; //Constant value
  floatLabel: string;

  constructor(private dialog: MatDialog, private utils: RetailUtilities, private retailService: RetailSetupService, public locatilization: RetailLocalization, private http: HttpServiceCall) {

    this.categoryGroupArray = [
      {
        category: [],
        subcategory: []
      }
    ]
    this.captions = this.locatilization.captions.retailsetup;
    this.commonCaptions = this.locatilization.captions.common;
    this.floatLabel = this.locatilization.setFloatLabel;
  }

  unSelectAll() {
    this.subCategoryOne.forEach(element => {
      element.isSelected = false;
    });
    this.subCategoryTwo.forEach(element => {
      element.isSelected = false;
    });
    this.subCategoryThree.forEach(element => {
      element.isSelected = false;
    });
    this.subCategoryFour.forEach(element => {
      element.isSelected = false;
    });
    this.subCategoryFive.forEach(element => {
      element.isSelected = false;
    });
    this.selectCategory = false;
  }

  formCategoryLinkingArray() {

    this.categoryLinkingArray = [{
      category: [],
      subCategoryOne: [],
      subCategoryTwo: [],
      subCategoryThree: [],
      subCategoryFour: [],
      subCategoryFive: []
    }]
  }
  /**
   * @method function non returnable
   * @function changeCategory
   * @returns none
   * @param input <obj> category selected
   * @description choose the Retail category or retail subcategory trigger the function if value 1 method call the  primaryCategory function same time "ifshowSelectCategory"
   * ifshowSelectCategory act the drop show and hide method
   */

  changeCategory(e: any): void {
    this.currentCategory = null;
    this.unSelectAll();
    this.categoryType = e.value;
    if (e.value === '1') {
      this.primaryCategory();
      this.ifshowSelectCategory = false;
      this.selectCategory = false;
    } else {
      this.selectedDefault = 1;
      this.secondaryCategory();
      this.ifshowSelectCategory = true;
      this.selectCategory = false;
    }
  }

  /**
   * @method function non returnable
   * @function categorySelect
   * @returns none
   * @param input <obj> category selected
   * @description Indivual category selected function that function trigger to "formCategoryArray"
   */

  categorySelect(e: any) {
    this.unSelectAll();
    this.currentCategory = e.id;
    this.selectCategory = true;
    this.formCategoryLinkingArray();
    this.formCategoryArray(e);
  }

  /**
 * @method function non returnable
 * @function changeSubcategrory
 * @returns none
 * @param input <obj> category selected
 * @description Indivual category selected function that function trigger to "formSubcategoryMaparray"
 */

  changeSubcategrory(e: any) {
    if(((this.categoryType == 2 && this.subcategorySelectedType == 4) || this.categoryType == 1 ) && this.currentCategory > 0){
      this.selectCategory = true;
    }
    this.formSubcategoryMaparray(e)
  }

  /**
  * @method function non returnable
  * @function changeSelection
  * @returns none
  * @param input <obj>
  * @description DropDown Selection Change change the Table array subcategory one .... infinte
  * e.value -----> value of Drop Down
  * e.value + 1 -----> Added one integer value
  *  this.primaryCategoryArray --> Primary Table Array
  * this.secondaryCategoryArray --> Secondary Table Array
  */


  changeSelection(e: any): void {
    this.currentCategory = null;
    this.unSelectAll();
    this.subcategorySelectedType = e.value;
    this.primaryCategoryLabel = this.getCategoryText(e.value);
    const addedValue = e.value + 1;
    this.secondaryCategoryLabel = this.getCategoryText(addedValue);
    this.primaryCategoryArray = this.pimaryCategoryArrayDefined(e.value);
    this.secondaryCategoryArray = this.pimaryCategoryArrayDefined(addedValue);
  }

  getCategoryText(categoryLevel: number) : string
  {
    let categoryText : string = "";
    switch(categoryLevel)
    {
      case 1:
        categoryText = this.captions.Subcategory1;
      break;
      case 2:
        categoryText =  this.captions.Subcategory2;
      break;
      case 3:
        categoryText = this.captions.Subcategory3;
      break;
      case 4:
        categoryText = this.captions.Subcategory4;
      break;
      case 5:
        categoryText = this.captions.Subcategory5;
      break;
    }
    return categoryText;
  }

  /**
 * @method function non returnable
 * @function primaryCategory
 * @returns none
 * @param input None
 * @description Trigger the Function assign the
 * primaryCategoryLabel = 'Category' Primary Table Name.
 * secondaryCategoryLabel = 'SubCategory 1' Primary Table Name
 * primaryCategoryArray ---> Assign the Primary Array
 * secondaryCategoryArray --> Assign the Deafult Secondary Array
 */

  primaryCategory() {
    this.subcategorySelectedType = this.subCatValueForCategorySelection;
    this.primaryCategoryLabel = this.captions.category;
    this.secondaryCategoryLabel = this.captions.subCategory1;
    this.primaryCategoryArray = this.categories;
    this.secondaryCategoryArray = this.subCategoryOne;
  }

  /**
   * @method function non returnable
   * @function secondaryCategory
   * @returns none
   * @param input None
   * @description Trigger the Function assign the
   * primaryCategoryLabel = 'Category' Primary Table Name.
   * secondaryCategoryLabel = 'SubCategory 1' Primary Table Name
   * primaryCategoryArray ---> Assign the Primary Array + 1
   * secondaryCategoryArray --> Assign the Deafult Secondary Array + 1
   * Note + 1 Added the Second Array in Switch Statement
   */

  secondaryCategory() {
    const selectedType = this.subcategorySelectedType + 1;
    this.primaryCategoryLabel =  this.getCategoryText(this.subcategorySelectedType);
    this.secondaryCategoryLabel = this.getCategoryText(selectedType);
    this.primaryCategoryArray = this.pimaryCategoryArrayDefined(this.subcategorySelectedType);
    this.secondaryCategoryArray = this.pimaryCategoryArrayDefined(selectedType);
  }

  /**
   * @method function non returnable
   * @function formCategoryArray
   * @returns none
   * @param input <obj>
   * @description Function Watch the category type if Category Type == 1
   * Push the Selected Category and Check the subcategory default value select the Secondary value
   * e.childSubCategories.length -->check secondary value length
   */

  formCategoryArray(e: any) {
    if (this.categoryType.toString() === "1") {
      this.categoryLinkingArray.forEach(x => { x.category = []; x.category.push({ id: e.id, name: e.name }) });
      if (e.childSubCategories.length > 0) {
        this.subCategoryOne.forEach(element => {
          e.childSubCategories.findIndex(x => {
            if (x.id === element.id) {
              element.isSelected = true;
              this.categoryLinkingArray.forEach(res => { res.subCategoryOne.push({ id: element.id, name: element.name }) })
            }
          })
        });
      }
    this.secondaryCategoryArray = this.subCategoryOne
    } else {
      const subCatType = this.subCategoryType(this.subcategorySelectedType);
      this.categoryLinkingArray.forEach(x => { x[subCatType] = []; x[subCatType].push({ id: e.id, name: e.name }) })
      if (e.childSubCategories && e.childSubCategories.length > 0) {
        const subcatTypeShow = this.subcategorySelectedType + 1;
        const subcategoryDataArray = this.subCategoryType(subcatTypeShow);
        this.pimaryCategoryArrayDefined(subcatTypeShow).forEach(element => {
          e.childSubCategories.findIndex(x => {
            if (x.id === element.id) {
              element.isSelected = true;
              this.categoryLinkingArray.forEach(result => { result[subcategoryDataArray].push({ id: element.id, name: element.name }) })
            }
          })
        });
      }else{
        const subcatTypeShow = this.subcategorySelectedType + 1;
      }
      this.secondaryCategoryArray = this.pimaryCategoryArrayDefined(this.subcategorySelectedType + 1);
    }
  }


  /**
 * @method function non returnable
 * @function formSubcategoryMaparray
 * @returns none
 * @param input <obj>
 * @description Function Watch the category type if Category Type == 1
 * Push the Selected Category and Check the subcategory default value select the Secondary value
 * e.childSubCategories.length -->check secondary value length
 */

  formSubcategoryMaparray(e: any) {
    const addedType = (this.ifshowSelectCategory) ? this.subcategorySelectedType + 1 : this.subcategorySelectedType;
    const subCatType = this.subCategoryType(addedType);
    if (e.isSelected) {
      e.isSelected = false;
      const findSubArray = this.categoryLinkingArray[0][subCatType];
      const arrayIndex = findSubArray.findIndex(x => x.id === e.id);
      this.categoryLinkingArray.forEach(x => x[subCatType].splice(arrayIndex, 1));
    } else {
      e.isSelected = true;
      this.categoryLinkingArray.forEach(x => { x[subCatType].push({ id: e.id, name: e.name }) });
    }
    console.log(this.categoryLinkingArray);
  }


  /**
 * @method function non returnable
 * @function pimaryCategoryArrayDefined
 * @returns none
 * @param input <obj>
 * @description Switch statement Validate the which array show on Primary and Secondary table
 */

  pimaryCategoryArrayDefined(e: any): any[] {
    switch (e) {
      case 1: {
        return this.subCategoryOne;
      }
      case 2: {
        return this.subCategoryTwo;
      }
      case 3: {
        return this.subCategoryThree;
      }
      case 4: {
        return this.subCategoryFour;
      }
      case 5: {
        return this.subCategoryFive;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  /**
 * @method function non returnable
 * @function subCategoryType
 * @returns none
 * @param input <obj>
 * @description Switch statement Validate the which array show on Primary and Secondary table
 */
  subCategoryType(e: any): any {
    switch (e) {
      case 1: {
        return 'subCategoryOne';
      }
      case 2: {
        return 'subCategoryTwo';
      }
      case 3: {
        return 'subCategoryThree';
      }
      case 4: {
        return 'subCategoryFour';
      }
      case 5: {
        return 'subCategoryFive';
      }
      default: {
        //statements;
        break;
      }
    }
  }

  /**
 * @method function non returnable
 * @function categorySave
 * @returns none
 * @param input <obj>
 * @description Finall Array for Category Group
 */
  categorySave() {
    let parentId: number;
    let childIds: number[] = [];
    let level: number;
    let param: string;

    if (this.categoryType == 1) {
      level = 1;
      parentId = this.categoryLinkingArray[0].category[0].id;
      childIds = this.categoryLinkingArray[0].subCategoryOne.map(a => a.id);
    }
    else {
      switch (this.subcategorySelectedType) {
        case 1: {
          level = 2;
          parentId = this.categoryLinkingArray[0].subCategoryOne[0].id;
          childIds = this.categoryLinkingArray[0].subCategoryTwo.map(a => a.id);
          break;
        }
        case 2: {
          level = 3;
          parentId = this.categoryLinkingArray[0].subCategoryTwo[0].id;
          childIds = this.categoryLinkingArray[0].subCategoryThree.map(a => a.id);
          break;
        }
        case 3: {
          level = 4;
          parentId = this.categoryLinkingArray[0].subCategoryThree[0].id;
          childIds = this.categoryLinkingArray[0].subCategoryFour.map(a => a.id);
          break;
        }
        case 4: {
          level = 5;
          parentId = this.categoryLinkingArray[0].subCategoryFour[0].id;
          childIds = this.categoryLinkingArray[0].subCategoryFive.map(a => a.id);
          break;
        }
        default: {
          //statements;
          break;
        }
      }
    }
    if (childIds?.length) {
      this.linkCategoryAndSubacategory(level, parentId, childIds);
    }
    else {
      this.utils.showCommonAlert(this.locatilization.captions.retailsetup.ProceedWithoutSubcatConfirmation, AlertType.Warning, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES)
        {
          this.linkCategoryAndSubacategory(level, parentId, childIds);
        }
      });
    }
  }

  linkCategoryAndSubacategory(level, parentId, childIds) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.http.CallApiWithCallback({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "LinkCategoryAndSubCategory",
      body: childIds,
      uriParams: { level: level, parentId: parentId },
      method: HttpMethod.Put,
      showError: true,
      extraParams: []
    });
  }

  ngOnInit() {
    this.formCategoryLinkingArray()
    this.getAllLinking();
    this.primaryCategoryLabel = this.captions.category; //Primary label Value
    this.secondaryCategoryLabel = this.captions.subCategory1; //Secondary value
    this.selectedDefault = 1; //Default Value
    this.subcategorySelectedType = this.selectedDefault;
    this.subCategoryoptionArray = this.subCategoryOptions();
    this.ifshowSelectCategory = false;
    this.categoryType = 1;
    this.selectCategory = false;
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == RetailBreakPoint.CategoryLinking).view;
    this.retailService.tabLoaderEnable.next(false);
  }

  subCategoryOptions(){

    return [
        {id: 1, name: `${this.captions.Subcategory1}`},
        {id: 2, name: `${this.captions.Subcategory2}`},
        {id: 3, name: `${this.captions.Subcategory3}`},
        {id: 4, name: `${this.captions.Subcategory4}`},
        {id: 5, name: `${this.captions.Subcategory5}`},
      ];
}

  getAllLinking() {
    this.utils.ToggleLoaderWithMessage(true,this.locatilization.captions.lbl_processing)
    this.http.CallApiWithCallback<any>({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetCategoryAndSubCategoryLink",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
      extraParams: []
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetCategoryAndSubCategoryLink") {
      let response = <any>result.result;
      this.categories = response.category ? response.category : [];
      this.subCategoryOne = response.subCategoryOne ? response.subCategoryOne : [];
      this.subCategoryTwo = response.subCategoryTwo ? response.subCategoryTwo : [];
      this.subCategoryThree = response.subCategoryThree ? response.subCategoryThree : [];
      this.subCategoryFour = response.subCategoryFour ? response.subCategoryFour : [];
      this.subCategoryFive = response.subCategoryFive ? response.subCategoryFive : [];
      this.primaryCategoryArray = this.categories;
      this.secondaryCategoryArray = this.subCategoryOne;
    }
    else if (callDesc == "LinkCategoryAndSubCategory") {
      this.selectCategory = false;
      let response = <any>result.result;
      this.categories = response.category ? response.category : [];
      this.subCategoryOne = response.subCategoryOne ? response.subCategoryOne : [];
      this.subCategoryTwo = response.subCategoryTwo ? response.subCategoryTwo : [];
      this.subCategoryThree = response.subCategoryThree ? response.subCategoryThree : [];
      this.subCategoryFour = response.subCategoryFour ? response.subCategoryFour : [];
      this.subCategoryFive = response.subCategoryFive ? response.subCategoryFive : [];
      this.primaryCategoryArray = _.cloneDeep(this.categories);
      this.unSelectAll();
       if(this.categoryType == 2 && this.subcategorySelectedType < 4){
        let prevSelectedSubCat = this.subcategorySelectedType;
        let currSubCat = prevSelectedSubCat + 1;
        this.selectedDefault = currSubCat;
        this.subcategorySelectedType = currSubCat;
        let addedValue = prevSelectedSubCat + 2;
        this.subcategorySelectedType = currSubCat;
        this.primaryCategoryLabel = this.getCategoryText(currSubCat);
        this.secondaryCategoryLabel = this.getCategoryText(addedValue);
        this.primaryCategoryArray = this.pimaryCategoryArrayDefined(currSubCat);
        this.secondaryCategoryArray = this.pimaryCategoryArrayDefined(addedValue);
      }
      else if(this.categoryType == 2 && this.subcategorySelectedType == 4)
      {
        this.primaryCategoryArray = _.cloneDeep(this.pimaryCategoryArrayDefined(this.subcategorySelectedType));
      }
      const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
        width: '305px',
        height: '300px',
        hasBackdrop: true,
        panelClass: 'small-popup',
        data :{ headername: this.commonCaptions.wellDone, headerIcon: 'icon-success-icon', headerMessage: this.commonCaptions.configSaveSuccessFrom , buttonName: this.commonCaptions.okay, type: 'message' },
        disableClose: true
      });
    }
    this.utils.ToggleLoaderWithMessage(false)
  }

  errorCallback() {
    console.error('error');
    this.utils.ToggleLoaderWithMessage(false)
  }

}

