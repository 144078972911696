<section class="SSI" [ngClass]="frompage =='RetailSummary' ? 'height2':'height1'">

    <label class="CaptionLbl LWB16 w-100 mb-0">{{captions.Items}}/{{captions.Services}}</label>
    <div class="clearfix custPad">
        

 <!-- Activities - retail integration changes, ngIf condition is added for activities  -->
 <div *ngIf="!_shopservice.isOperationFromActivities" [ngClass]="{'prevent-action': _retailModuleService.paymentProcessing}"  
        class="split-button btn-group splitbuttons float-right">
        <div #buttonGroup class='buttonGroup'>
            <button mat-flat-button (click)="onDDschange(_shopservice.ReturnMethod,_shopservice.DDselection,frompage)"
                [ngClass]="{'btnDisabled': btnCheck()}" class='text-capital text LW12 body-bgcolor whitecolor'
                color="primary" [attr.automationId]="'Btn_ShopServiceItem_ddSelection'">{{_shopservice.DDselection}}</button>
            <button mat-flat-button class='text-capital trigger' *ngIf="!PropertyInfo.UseRetailInterface" [ngClass]="{'btnDisabled': btnCheck()}" color="primary"
            [attr.automationId]="'Btn_ShopServiceItem_menu'" [matMenuTriggerFor]="menu"><i aria-hidden="true" class='icon-Down-Arrow LW12 whitecolor'>&nbsp;</i></button>
        </div>
        <mat-menu #menu="matMenu" class='golf-split-button-menu'>
            <button mat-menu-item class='split-button-list'
                (click)="onDDschange(2,captions.ReturnWithExchange,frompage)">{{captions.ReturnWithExchange}}</button>
        </mat-menu>
    </div>
<!-- Activities - retail integration changes -->
<div *ngIf="_shopservice.isOperationFromActivities && (_retailSharedService.SelecedSeatProducts.length > 0 || !_shopservice.isCancelOperation)" class="float-right">
    <button mat-raised-button class="h32 text-capital mr-1 LW12"
    [ngClass]="btnCheck() ? 'btnDisabled': 'button--primary'"
    (click)="onDDschange(2,captions.ReturnWithExchange,frompage)">{{captions.ReturnWithExchange}}</button>
</div>
<!-- Activities - retail integration changes -->
<div *ngIf="_shopservice.isCancelOperation && !(_retailSharedService.SelecedSeatProducts.length > 0)" class="float-right">
    <button mat-raised-button class="h32 text-capital mr-1 LW12"
    [ngClass]="btnCheck() ? 'btnDisabled': 'button--primary'"
    (click)="onDDschange(1,captions.ReturnWithoutExchange,frompage)">{{captions.ReturnWithoutExchange}}</button>
</div>

    <div class="float-right" *ngIf="frompage =='RetailSummary'">
        <button [attr.automationId]="'Btn_ShopServiceItem_remove'" mat-raised-button class="h32 text-capital mr-1 LW12" (click)="RemoveFromReturnedList()"
            [ngClass]="{'spa-tertiary-btn-disabled': removeBtnCheck()}">{{captions.REMOVE}}</button>
        <button [attr.automationId]="'Btn_ShopServiceItem_applyDiscount'" *ngIf="!PropertyInfo.UseRetailInterface" class="pull-right text-uppercase h32 mr-2 LW12 apply-btn"
            (click)="OpenApplyDiscountToAll()" 
            [ngClass]="EnableApplyDiscount ? 'button--primary' : 'button--disabled'"
        mat-raised-button >{{ShopCaptions.APPLYDISCOUNT}}</button>
         <!-- Popover starts  -->
         <!-- <popover-content #applyAllPopover id="applyAllPopover" placement="bottom" class="discount-popover left-aligned" >
         <app-apply-discount [ApplyDiscountInput]="applyAllDiscountInput" (discountEmitter)="applyAllDiscount($event)" (closeEmitter)="applyAllPopover.hide();"></app-apply-discount>
         </popover-content> -->
    </div>
    </div>
    <div class="table-view" *ngIf="frompage !='RetailSummary'">
        <ng-scrollbar   >
            <table aria-describedby="" class="w-100" [attr.automationId]="'Tbl_ShopServiceItem_table'">
                <thead class="SSI_head">
                    <tr>
                        <th scope='col' >
                              <!--Activities - retail integration changes -->
                            <div class="checklbl0" [class.disabled]="_sbs.isMultipleMemberTransaction">
                                <mat-checkbox [disabled]="_retailModuleService.paymentProcessing || CheckAllRainCheckIssued() || this._sbs.isMultipleMemberTransaction" (change)="ListChecked($event,GridItems.selectedTransactionRetailProducts,'arr_SI',0)" [checked]="((selectedReturnItems && selectedReturnItems?.length) ==  (GridItems.selectedTransactionRetailProducts && GridItems.selectedTransactionRetailProducts?.length))"></mat-checkbox>
                            </div>
                        </th>
                        <th  scope='col' class="LW14">
                            <div>{{captions.SNo}}</div>
                        </th>
                        <th  scope='col' class="LW14">
                            <div>{{captions.Items}} / {{captions.Services}}</div>
                        </th>
                        <th  scope='col' class="LW14 text-right">
                            <div>{{captions.UnitPrice}} ({{localization.currencySymbol}})</div>
                        </th>
                        <th  scope='col' class="LW14 text-right">
                            <div>{{captions.Quantity}}</div>
                        </th>
                        <th  scope='col' class="LW14 text-right">
                            <div>{{captions.Gratuity}}</div>
                        </th>
                        <th  scope='col' class="LW14 text-right">
                            <div>{{captions.ServiceCharge}}</div>
                        </th>
                        <th  scope='col' class="LW14 text-right">
                            <div>{{captions.Discount}} ({{localization.currencySymbol}})</div>
                        </th>
                        <th  scope='col' class="LW14 text-right">
                            <div>{{captions.Subtotal}} ({{localization.currencySymbol}})</div>
                        </th>
                    </tr>
                </thead>
                <tbody class="SSI_body">   
                            <!-- Activities - retail integration changes -->
                    <tr *ngFor="let ordersummary of GridItems.selectedTransactionRetailProducts index as i;">                        
                            <td>
                                <mat-checkbox [class.disabled]="_sbs.isMultipleMemberTransaction" [disabled]="_retailModuleService.paymentProcessing || ordersummary.rainCheckIssued || this._sbs.isMultipleMemberTransaction || ordersummary.ItemType ==2 || ordersummary.isDisabledActivityItem || ordersummary.isDeleteDisabled || ordersummary.isModificationRestricted"
                                    (change)="ListChecked($event, ordersummary,'arr_SI',0)"
                                    [checked]="itemChecked(ordersummary)" ></mat-checkbox>
                            </td>
                            <td class="LW14">{{i+1}}</td>
                                          
                        
                        <td>
                            <div>
                                <label class="LW16 pr-3">{{ordersummary.ProductName}}</label>                           
                                <label class=" label-icon">                              
                                    <i  aria-hidden="true" *ngIf="ordersummary.Commission && ordersummary.Commission?.length > 0"
                                        class="icon-commission iconborder blckclr LW18"></i>
                                    <i  aria-hidden="true" *ngIf="ordersummary.Gratuity && ordersummary.Gratuity?.length > 0"
                                        class="icon-gratuity iconborder blckclr LW18"></i>
                                    <i  aria-hidden="true" *ngIf="ordersummary.ServiceCharge && ordersummary.ServiceCharge?.length > 0"
                                        class="icon-servicecharge iconborder blckclr LW18"></i>
                                    <i  aria-hidden="true" *ngIf="ordersummary.Discount > 0" class="icon-percent iconborder blckclr LW18"></i>
                                </label>
                                <label class="player-label" *ngIf="ordersummary.playerName">
                                    <i  aria-hidden="true" class="icon-player"></i>
                                    <span>{{ordersummary.playerName}}</span>
                                </label>
                                <label class="player-label" *ngIf="ordersummary.clientName">
                                  <i  aria-hidden="true" class="icon-profile"></i>
                                  <span>{{ordersummary.clientName}}</span>
                              </label>     
                            </div>
                            <div class="pl-3">
                                <mat-checkbox *ngIf="GridItems.returnWithticketItems.length > 0 && ordersummary.Gratuity.length > 0 && !(_shopservice.MultipackproductIds.length > 0 && _shopservice.MultipackproductIds.indexOf(ordersummary.id)!=-1)" 
                                    [(ngModel)]="ordersummary.isGratuityReturn"
                                    [disabled] = "selectedReturnItems.indexOf(ordersummary)!=-1">
                                    {{ShopCaptions.Gratuity}}
                                </mat-checkbox>
                            </div>  
                            <div class="pl-3">
                                <mat-checkbox *ngIf="GridItems.returnWithticketItems.length > 0 && ordersummary.ServiceCharge.length > 0 && !(_shopservice.MultipackproductIds.length > 0 && _shopservice.MultipackproductIds.indexOf(ordersummary.id)!=-1)" 
                                    [(ngModel)]="ordersummary.isServiceChargeReturn"
                                    [disabled] = "(selectedReturnItems.indexOf(ordersummary)!=-1) || (_retailModuleService.paymentProcessing || ordersummary.rainCheckIssued  || ordersummary.isDisabledActivityItem || ordersummary.isDeleteDisabled || ordersummary.isModificationRestricted)">
                                    {{ShopCaptions.ServiceCharge}}
                                </mat-checkbox>                              
                            </div>                           
                        </td>
                        <td class="LW14 text-right">
                            {{localization.localizeCurrency(ordersummary.ProductPrice.toString(), false)}}</td>
                        <td class=" text-right">
                            <label class="LW14 mb-0"
                                *ngIf="(selectedReturnItems.indexOf(ordersummary)==-1) && (!ordersummary.isServiceChargeReturn && !ordersummary.isGratuityReturn) ">{{ordersummary.Noofitems}}</label>
                            <input type="text" inputtype="nonnegative,nodecimal" maxlength="4" autocomplete="off"
                                class="LW12 qty" (blur)="CheckQuantity(ordersummary)" (keyup)="updateQuantityValue()"
                                [(ngModel)]="ordersummary.Noofitems"
                                *ngIf="CheckQuantityDisable(ordersummary)" [disabled] = "(_shopservice.MultipackproductIds.length > 0 && _shopservice.MultipackproductIds.indexOf(ordersummary.id)!=-1)||disableQunatity|| ordersummary.rainCheckIssued  || ordersummary.isDisabledActivityItem || ordersummary.isDeleteDisabled || ordersummary.isModificationRestricted">
                        </td>
                        <td class="LW14 text-right">
                            {{ShowUnitGratuity(ordersummary)}}</td>                            
                            <td class="LW14 text-right">
                                {{ShowUnitServiceCharge(ordersummary)}}</td>
                        <td class="LW14 text-right">
                            {{DisplayDiscount(ordersummary,ordersummary.Discount)}}</td>
                        <td class="LW14 text-right SSI_Subtotal">{{localization.localizeCurrency(ShowItemSubTotal(ordersummary).toString(),
                            false)}}
                        </td>
                    </tr>
                    <tr *ngIf="GridItems.selectedTransactionRetailProducts && GridItems.selectedTransactionRetailProducts?.length<=0">
                        <td colspan="6" class="text-center">{{captions.NoDataFound}}</td>
                    </tr>
                </tbody>
                <tfoot class="SSI_footer" *ngIf="GridItems.selectedTransactionRetailProducts && GridItems.selectedTransactionRetailProducts?.length > 0">
                    <!-- TO DO :: -->
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Gratuity}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">{{ FormatPriceWithLocalisaction(totalGratuityOfReturnWithTicketItems)  }}</td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.ServiceCharge}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">{{ FormatPriceWithLocalisaction(totalServiceChargeOfReturnWithTicketItems) }}</td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Subtotal}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">{{ localization.localizeCurrency(ShowSubTotal(), false) }}</td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Discount}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">{{ShowTotalDiscount()}}</td>
                    </tr>
                    <tr *ngIf="!isVATEnabled && gratuityTax > 0">
                        <td colspan="8" class="LW14 text-right">{{ShopCaptions.GratuityTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">{{localization.localizeCurrency(gratuityTax, false)}}</td>
                    </tr>
                    <tr *ngIf="!isVATEnabled && serviceChargetax >0">
                        <td colspan="8" class="LW14 text-right">{{ShopCaptions.ServiceChargeTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">{{localization.localizeCurrency(serviceChargetax, false)}}</td>
                    </tr>
                    <tr *ngIf="!isVATEnabled">
                        <td colspan="8" class="LW14 text-right">{{captions.Tax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">{{localization.localizeCurrency(tax, false)}}</td>
                    </tr>
                    <tr *ngIf="!isVATEnabled && taxOnTax != 0">
                        <td colspan="8" class="LW14 text-right">{{captions.TaxOnTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">{{localization.localizeCurrency(taxOnTax, false)}}</td>
                    </tr>
                    <tr *ngIf="isVATEnabled">
                        <td colspan="8" class="LW14 text-right">{{this.localization.captions.setting.vat}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">{{localization.localizeCurrency(vat, false)}}</td>
                    </tr>
                    <tr *ngIf="isVATEnabled && serviceChargetax >0">
                        <td colspan="8" class="LW14 text-right">{{localization.captions.setting.serviceChargeVat}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">{{localization.localizeCurrency(serviceChargetax, false)}}</td>
                    </tr>
                    <tr class="h45">
                        <td colspan="8" class="LWB14 text-right">{{captions.Total}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LWB14 text-right">{{localization.localizeCurrency(total, false)}}</td>
                    </tr>
                </tfoot>
            </table>
        </ng-scrollbar>
    </div>


    <div class="table-view" *ngIf="frompage =='RetailSummary'">
        <ng-scrollbar   >
            <table aria-describedby="" class="w-100" [attr.automationId]="'Tbl_ShopServiceItem_retailTable'">
                <thead class="SSI_head">
                    <tr>
                        <th scope='col' >
                            <div></div>
                        </th>
                        <th  scope='col' class="LW14">
                            <div>{{captions.SNo}}</div>
                        </th>
                        <th  scope='col' class="LW14">
                            <div>{{captions.Items}} / {{captions.Services}}</div>
                        </th>
                        <th  scope='col' class="LW14 text-right">
                            <div>{{captions.UnitPrice}} ({{localization.currencySymbol}})</div>
                        </th>
                        <th scope='col'  class="LW14 text-right">
                            <div>{{captions.Quantity}}</div>
                        </th>
                        <th scope='col'  class="LW14 text-right">
                            <div>{{captions.Gratuity}}</div>
                        </th>
                        <th scope='col'  class="LW14 text-right">
                            <div>{{captions.ServiceCharge}}</div>
                        </th>
                        <th scope='col'  class="LW14 text-right">
                            <div>{{captions.Discount}} ({{localization.currencySymbol}})</div>
                        </th>
                        <th  scope='col' class="LW14 text-right">
                            <div>{{captions.Subtotal}} ({{localization.currencySymbol}})</div>
                        </th>
                        <th scope='col' ></th>
                    </tr>
                </thead>
                <tbody class="SSI_body tableBodyBg" *ngIf="_shopservice.selectedExchangeRetailProducts?.length>0">
                
                    <tr class="TablecontentHeading border-0 LW16">
                        <td colspan="10">{{captions.Exchanged}}</td>
                    </tr>
                    <tr
                        *ngFor="let item of _shopservice.selectedExchangeRetailProducts | OrderSummaryPipe; index as i;">
                        <td>
                            <!-- Activities - retail integration changes -->
                            <mat-checkbox [disabled]="_retailModuleService.paymentProcessing || item.rainCheckIssued  || item.isDisabledActivityItem || item.isDeleteDisabled || item.isModificationRestricted"
                                (change)="ListChecked($event, item,'arr_SER',0,i)"
                                [checked]="RS_selectedExchangeItems.indexOf(item)!=-1"></mat-checkbox>
                        </td>
                        <td class="LW14">{{i+1}}</td>
                        <td class="LW14">{{Display(item,"ITEMNAME")}}
                            <label class=" label-icon">
                                <i  aria-hidden="true" *ngFor="let charges of item.charges index as ind;"
                                    [style.display]="(!charges.isApplied || ind== 0)  ? 'none' : ''"
                                    class="icon-{{charges.alias}} iconborder blckclr"    
                                    [ngClass]="{'button_invalid' : this._retailModuleService.paymentProcessing}"                              
                                    (click)="positionPopover($event, item, i,charges)"                                   
                                    (onHidden)="clearIdx()"></i>
                            </label>
                            <label class="player-label" *ngIf="item.playerName">
                                <i  aria-hidden="true" class="icon-player"></i>
                                <span>{{item.playerName}}</span>
                            </label>
                        </td>
                        <td class="LW14 text-right">
                            {{localization.localizeCurrency(Display(item,"ITEMPRICE"), false)}}</td>
                        <td class=" text-right">
                            <label class="LW14 mb-0">{{item.Noofitems}}</label>
                        </td>
                        <td class=" text-right">
                            <label class="LW14 mb-0">{{PropertyInfo.UseRetailInterface ? ShowUnitGratuity(item) : getGratuity(item)}}</label>
                        </td>
                        <td class=" text-right">
                            <label class="LW14 mb-0">{{PropertyInfo.UseRetailInterface ? ShowUnitServiceCharge(item) : getServiceCharge(item)}}</label>
                        </td>
                        <td class="LW14 text-right">
                            {{DisplayDiscount(item,item.Discount,true)}}</td>
                        <td class="LW14 text-right SSI_Subtotal">
                            {{ localization.localizeCurrency(ShowExchangedItemSubTotal(item)?.toString(), false) }}</td>
                        <td>
                            <i  aria-hidden="true" [ngClass]="{'prevent-action': (_retailModuleService.paymentProcessing || item.isGiftCardItem)}"
                                class="icon-More cursor blckclr rotate90" [matMenuTriggerFor]="menu2"
                                matTooltip="{{localization.captions.shop.More}}"></i>
                            <mat-menu #menu2="matMenu" class="matMenu" xPosition="before">
                                <button mat-menu-item *ngFor="let data of item.charges index as j;" class="matmenu-button"
                                    [ngClass]="{'button_invalid': !data.isactive}"
                                    (click)="MenuItemClick(data,item,i)">
                                    <span class="LW14">{{data.name}}</span>
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    <tr *ngIf="GridItems.selectedExchangeRetailProducts?.length<=0">
                        <td colspan="7" class="text-center">{{captions.NoDataFound}}</td>
                    </tr>


                    <!-- Popover starts  -->
                    <!-- <popover-content id="discountPopover" #discountPopover placement="bottom"
                        class="discount-popover" [style.left.px]="xPos"
                        [ngClass]="isRight ? 'right-aligned': 'left-aligned'">
                        <app-apply-discount [ApplyDiscountInput]="applyDiscountInput"
                            (discountEmitter)="applyDiscount($event)" (closeEmitter)="discountPopover.hide();">
                        </app-apply-discount>
                    </popover-content> -->
                </tbody>
                <tbody class="SSI_footer" *ngIf="GridItems.selectedExchangeRetailProducts && GridItems.selectedExchangeRetailProducts?.length && GridItems.selectedExchangeRetailProducts?.length > 0">
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Gratuity}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{FormatPriceWithLocalisaction(Itemtotal.exchangedItemGratuity)}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.ServiceCharge}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{FormatPriceWithLocalisaction(Itemtotal.exchangedItemServiceCharge)}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Subtotal}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{ShowSummarySubtotal(Itemtotal.exchangedItemSubtotal, Itemtotal.exchangedItemDiscount)}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Discount}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{DisplayDiscount(null,Itemtotal.exchangedItemDiscount,true)}}</td>
                        <td></td>
                    </tr>
                    <tr  *ngIf="!isVATEnabled && Itemtotal.exchangedItemGratuityTax > 0">
                        <td colspan="8" class="LW14 text-right">{{ShopCaptions.GratuityTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right" [ngClass]="{'button_invalid': exempttax.value}">
                            {{localization.localizeCurrency(exempttax.value ? 0 : Itemtotal.exchangedItemGratuityTax.toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr  *ngIf="!isVATEnabled && Itemtotal.exchangedItemServiceChargeTax > 0">
                        <td colspan="8" class="LW14 text-right">{{ShopCaptions.ServiceChargeTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right" [ngClass]="{'button_invalid': exempttax.value}">
                            {{localization.localizeCurrency(exempttax.value ? 0 : Itemtotal.exchangedItemServiceChargeTax.toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngIf="!isVATEnabled">
                        <td colspan="6"></td>
                        <td colspan="1" class="LW14">
                            <div *ngIf="!PropertyInfo.UseRetailInterface" class=" d-flex align-items-center">
                                <div class="ml-auto tax-exemption" [class.prevent-action]="_retailModuleService.paymentProcessing">
                                    <label class="LW14 blckclr mb-0">{{localization.captions.shop.TaxExemption}}</label>
                                    <app-retail-toggle-switch [formControl]="exempttax" class="ml-1" [disableToggle]="_shopservice.isOperationFromActivities" 
                                        (click)="taxExemptChange()"></app-retail-toggle-switch>
                                </div>
                            </div>
                        </td>
                        <td colspan="1" class="LW14 text-right">{{captions.Tax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right" [ngClass]="{'button_invalid': exempttax.value}">
                            {{localization.localizeCurrency(exempttax.value ? 0 : Itemtotal.exchangedItemTax.toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngIf="!isVATEnabled && Itemtotal.exchangedItemTaxOnTax != 0">
                        <td colspan="8" class="LW14 text-right">{{captions.TaxOnTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right" [ngClass]="{'button_invalid': exempttax.value}">
                            {{localization.localizeCurrency(exempttax.value ? 0 : Itemtotal.exchangedItemTaxOnTax.toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngIf="isVATEnabled && !PropertyInfo.UseRetailInterface">
                        <td colspan="8" class="LW14 text-right">{{this.localization.captions.setting.vat}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">{{ShowExchagedItemsFinalVATAmountTotal()}}</td>
                    </tr>
                    <tr  *ngIf="isVATEnabled && Itemtotal.exchangedItemServiceChargeTax > 0">
                        <td colspan="8" class="LW14 text-right">{{this.localization.captions.setting.serviceChargeVat}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right" [ngClass]="{'button_invalid': exempttax.value}">
                            {{localization.localizeCurrency(exempttax.value ? 0 : Itemtotal.exchangedItemServiceChargeTax.toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr class="h45">
                        <td colspan="8" class="LWB14 text-right">{{captions.Total}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LWB14 text-right">{{ExchangedSectionTotal}}</td>
                        <td></td>
                    </tr>
                </tbody>

                <tbody class="SSI_body tableBodyBg" *ngIf="GridItems.selectedReturnedRetailProducts?.length>0">
                
                    <tr class="TablecontentHeading border-0 LW16">
                        <td colspan="10">{{captions.Returned}}</td>
                    </tr>
                    <tr *ngFor="let ordersummary of GridItems.selectedReturnedRetailProducts index as i;">
                        <td>
                            <!-- Activities - retail integration change -->
                            <mat-checkbox [class.disabled]="_sbs.isMultipleMemberTransaction" [disabled]="_retailModuleService.paymentProcessing || ordersummary.rainCheckIssued || this._sbs.isMultipleMemberTransaction || ordersummary.isDisabledActivityItem || ordersummary.isDeleteDisabled || ordersummary.isModificationRestricted"
                                (change)="ListChecked($event, ordersummary,'arr_SReR',ordersummary.returnType)"
                                [checked]="RS_selectedReturnItems.indexOf(ordersummary)!=-1"></mat-checkbox>
                        </td>
                        <td class="LW14">{{i+1}}</td>
                        <td class="LW14">{{Display(ordersummary,"ITEMNAME")}}
                            <label class="label-icon">
                                <i  aria-hidden="true" *ngIf="ordersummary.Commission && ordersummary.Commission?.length > 0"
                                    class="icon-commission iconborder blckclr LW18"></i>
                                <i  aria-hidden="true" *ngIf="ordersummary.Gratuity && ordersummary.Gratuity?.length > 0 && (ordersummary.returnType != 1 && ordersummary.returnType != 4 )"
                                    class="icon-gratuity iconborder blckclr LW18"></i>
                                <i  aria-hidden="true" *ngIf="ordersummary.ServiceCharge && ordersummary.ServiceCharge?.length > 0 && (ordersummary.returnType != 2 && ordersummary.returnType != 5 )"
                                    class="icon-servicecharge iconborder blckclr LW18"></i>
                                <i  aria-hidden="true" *ngIf="ordersummary.Discount > 0 && (ordersummary.returnType != 2 && ordersummary.returnType != 1 )" class="icon-percent iconborder blckclr LW18"></i>
                            </label>
                            <label class="player-label" *ngIf="ordersummary.playerName">
                                <i  aria-hidden="true" class="icon-player"></i>
                                <span>{{ordersummary.playerName}}</span>
                            </label>
                            <label class="player-label" *ngIf="ordersummary.clientName">
                              <i  aria-hidden="true" class="icon-profile"></i>
                              <span>{{ordersummary.clientName}}</span>
                          </label>
                            <div class="pl-3">
                                <mat-checkbox *ngIf="(ordersummary.returnType == 0 && ordersummary.Gratuity.length> 0) && !(_shopservice.MultipackproductIds.length > 0 && _shopservice.MultipackproductIds.indexOf(ordersummary.id)!=-1)" 
                                    [(ngModel)]="ordersummary.isGratuityReturn">
                                    {{ShopCaptions.Gratuity}}
                                </mat-checkbox>
                            </div>  
                            <div class="pl-3">
                                <mat-checkbox *ngIf="(ordersummary.returnType == 0 && ordersummary.ServiceCharge.length > 0) && !(_shopservice.MultipackproductIds.length > 0 && _shopservice.MultipackproductIds.indexOf(ordersummary.id)!=-1)" 
                                    [(ngModel)]="ordersummary.isServiceChargeReturn"  [checked]="RS_selectedReturnItems.indexOf(ordersummary)!=-1" [disabled]="_retailModuleService.paymentProcessing || ordersummary.rainCheckIssued  || ordersummary.isDisabledActivityItem || ordersummary.isDeleteDisabled || ordersummary.isModificationRestricted">
                                    {{ShopCaptions.ServiceCharge}}
                                </mat-checkbox>                              
                            </div>   
                        </td>
                        <td class="LW14 text-right">
                            {{localization.localizeCurrency(Display(ordersummary,"ITEMPRICE"), false)}}</td>
                        <td class=" text-right">
                            <label class="LW14 mb-0">{{ordersummary.Noofitems}}</label>
                        </td>
                        <td class="LW14 text-right">
                            {{ShowUnitGratuity(ordersummary)}}</td>
                            <td class="LW14 text-right">
                                {{ShowUnitServiceCharge(ordersummary)}}</td>
                        <td class="LW14 text-right">
                            {{DisplayDiscount(ordersummary,ordersummary.Discount)}}</td>
                        <td class="LW14 text-right SSI_Subtotal">
                            {{localization.localizeCurrency(ShowReturnItemSubTotal(ordersummary).toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngIf="GridItems.selectedReturnedRetailProducts?.length<=0">
                        <td colspan="7" class="text-center">{{captions.NoDataFound}}</td>
                    </tr>
                </tbody>
                <tbody class="SSI_footer" *ngIf="GridItems.selectedReturnedRetailProducts?.length>0">
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Gratuity}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{FormatPriceWithLocalisaction(Itemtotal.returnedItemGratuity)}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.ServiceCharge}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{FormatPriceWithLocalisaction(Itemtotal.returnedItemServiceCharge)}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Subtotal}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{ShowSummarySubtotal(Itemtotal.returnedItemSubtotal, Itemtotal.returnedItemDiscount)}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Discount}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{DisplayDiscount(null,Itemtotal.returnedItemDiscount)}}</td>
                        <td></td>
                    </tr>
                    <tr  *ngIf="!isVATEnabled && Itemtotal.returnedItemGratuityTax > 0">
                        <td colspan="8" class="LW14 text-right">{{ShopCaptions.GratuityTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right" [ngClass]="{'button_invalid': returnTaxExempt.value}">
                            {{localization.localizeCurrency(returnTaxExempt.value ? 0 : Itemtotal.returnedItemGratuityTax.toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngIf="!isVATEnabled && Itemtotal.returnedItemServiceChargeTax > 0">
                        <td colspan="8" class="LW14 text-right">{{ShopCaptions.ServiceChargeTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right" [ngClass]="{'button_invalid': returnTaxExempt.value}">
                            {{localization.localizeCurrency(returnTaxExempt.value ? 0 : Itemtotal.returnedItemServiceChargeTax.toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngIf="!isVATEnabled">
                        <td colspan="6"></td>
                        <td colspan="1" class="LW14">
                            <div *ngIf="!PropertyInfo.UseRetailInterface" class=" d-flex align-items-center">
                                <div class="ml-auto tax-exemption" [class.prevent-action]="_retailModuleService.paymentProcessing">
                                    <label class="LW14 blckclr mb-0">{{localization.captions.shop.TaxExemption}}</label>
                                    <app-retail-toggle-switch [formControl]="returnTaxExempt" class="ml-1"   [disableToggle]="_shopservice.isOperationFromActivities" 
                                        (click)="returnTaxExemptChange()"></app-retail-toggle-switch>
                                </div>
                            </div>
                        </td>
                        <td colspan="1" class="LW14 text-right">{{captions.Tax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right" [ngClass]="{'button_invalid': returnTaxExempt.value}">
                            {{localization.localizeCurrency(returnTaxExempt.value ? 0 : Itemtotal.returnedItemTax.toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngIf="!isVATEnabled && Itemtotal.returnedItemTaxOnTax != 0">
                        <td colspan="8" class="LW14 text-right">{{captions.TaxOnTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right" [ngClass]="{'button_invalid': exempttax.value}">
                            {{localization.localizeCurrency(returnTaxExempt.value ? 0 : Itemtotal.returnedItemTaxOnTax.toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngIf="isVATEnabled && !PropertyInfo.UseRetailInterface">
                        <td colspan="8" class="LW14 text-right">{{this.localization.captions.setting.vat}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">{{ShowReturnedItemsFinalVATAmountTotal(GridItems.selectedReturnedRetailProducts)}}</td>
                    </tr>
                    <tr *ngIf="isVATEnabled && Itemtotal.returnedItemServiceChargeTax > 0">
                        <td colspan="8" class="LW14 text-right">{{localization.captions.setting.serviceChargeVat}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right" [ngClass]="{'button_invalid': returnTaxExempt.value}">
                            {{localization.localizeCurrency(returnTaxExempt.value ? 0 : Itemtotal.returnedItemServiceChargeTax.toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr class="h45">
                        <td colspan="8" class="LWB14 text-right">{{captions.Total}} ({{localization.currencySymbol}})</td>
                        <td class="LWB14 text-right">{{ReturnedSectionTotal}}</td>
                        <td></td>
                    </tr>
                </tbody>

                <tbody class="SSI_body tableBodyBg" *ngIf="GridItems.selectedRetainedRetailProducts?.length > 0">
              
                    <tr class="TablecontentHeading border-0 LW18">
                        <td colspan="10">{{captions.Retained}}</td>
                    </tr>
                    <tr *ngFor="let ordersummary of GridItems.selectedRetainedRetailProducts index as i;">
                        <td>
                            <!-- Activities - retail integration changes -->
                            <mat-checkbox [disabled]="_retailModuleService.paymentProcessing || ordersummary.rainCheckIssued    || ordersummary.isDisabledActivityItem || ordersummary.isDeleteDisabled || ordersummary.isModificationRestricted || DisableRetainForPartialScAndG(ordersummary,'ITEM')"
                                (change)="ListChecked($event, ordersummary,'arr_SRtR',0)"
                                [checked]="(RS_selectedRtainedItems | checkboxchecked : ordersummary : 'ItemId')">
                            </mat-checkbox>
                        </td>
                        <td class="LW14">{{i+1}}</td>
                        <td class="LW14">{{Display(ordersummary,"ITEMNAME")}}
                            <label class="label-icon">
                                <i  aria-hidden="true" *ngIf="ordersummary.Commission && ordersummary.Commission?.length > 0"
                                    class="icon-commission iconborder blckclr LW18"></i>
                                <i  aria-hidden="true" *ngIf="ordersummary.Gratuity && ordersummary.Gratuity?.length > 0 && (ordersummary.returnType != 1 && ordersummary.returnType != 4 && ordersummary.returnType != 6)"
                                    class="icon-gratuity iconborder blckclr LW18"></i>
                                <i  aria-hidden="true" *ngIf="ordersummary.ServiceCharge && ordersummary.ServiceCharge?.length > 0 && (ordersummary.returnType != 2 && ordersummary.returnType != 5 && ordersummary.returnType != 6)"
                                    class="icon-servicecharge iconborder blckclr LW18"></i>
                                <i  aria-hidden="true" *ngIf="ordersummary.Discount > 0" class="icon-percent iconborder blckclr LW18"></i>
                            </label>
                            <label class="player-label" *ngIf="ordersummary.playerName">
                                <i aria-hidden="true"  class="icon-player"></i>
                                <span>{{ordersummary.playerName}}</span>
                            </label>
                            <label class="player-label" *ngIf="ordersummary.clientName">
                              <i  aria-hidden="true" class="icon-profile"></i>
                              <span>{{ordersummary.clientName}}</span>
                          </label>
                          <div class="pl-3">
                            <mat-checkbox *ngIf="((ordersummary.returnType == 0 && ordersummary.Gratuity.length> 0) || ordersummary.returnType == 5) && !(_shopservice.MultipackproductIds.length > 0 && _shopservice.MultipackproductIds.indexOf(ordersummary.id)!=-1)" 
                                (change)="ScAndGCheckBoxChange($event, ordersummary)"
                                [(ngModel)]="ordersummary.isGratuityReturn"
                                [disabled]="DisableRetainForPartialScAndG(ordersummary,'GRATUITY')">
                                {{ShopCaptions.Gratuity}}
                            </mat-checkbox>
                        </div>  
                        <div class="pl-3">
                            <mat-checkbox *ngIf="((ordersummary.returnType == 0 && ordersummary.ServiceCharge.length > 0) || ordersummary.returnType == 4) && !(_shopservice.MultipackproductIds.length > 0 && _shopservice.MultipackproductIds.indexOf(ordersummary.id)!=-1)" 
                                (change)="ScAndGCheckBoxChange($event, ordersummary)"
                                [(ngModel)]="ordersummary.isServiceChargeReturn"
                                [disabled]="DisableRetainForPartialScAndG(ordersummary,'SERVICECHARGE') || (_retailModuleService.paymentProcessing || ordersummary.rainCheckIssued   || ordersummary.isDisabledActivityItem || ordersummary.isDeleteDisabled || ordersummary.isModificationRestricted)">
                                {{ShopCaptions.ServiceCharge}}
                            </mat-checkbox>                              
                        </div>   
                        </td>
                        <td class="LW14 text-right">
                            {{localization.localizeCurrency(Display(ordersummary,"ITEMPRICE"), false)}}</td>
                        <td class=" text-right">
                            <label class="LW14 mb-0">{{ordersummary.Noofitems}}</label>
                        </td>
                        <td class="LW14 text-right">
                            {{ShowUnitGratuity(ordersummary,true)}}</td>
                            <td class="LW14 text-right">
                                {{ShowUnitServiceCharge(ordersummary,true)}}</td>
                        <td class="LW14 text-right">
                            {{DisplayDiscount(ordersummary,ordersummary.Discount,false,true)}}</td>
                        <td class="LW14 text-right SSI_Subtotal">
                            {{localization.localizeCurrency(ShowItemSubTotal(ordersummary).toString(), false) }}
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngIf="GridItems.selectedRetainedRetailProducts?.length<=0">
                        <td colspan="7" class="text-center">{{captions.NoDataFound}}</td>
                    </tr>
                </tbody>
                <tbody class="SSI_footer" *ngIf="GridItems.selectedRetainedRetailProducts?.length > 0">
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Gratuity}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{FormatPriceWithLocalisaction(Itemtotal.retainedItemGratuity)}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.ServiceCharge}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{FormatPriceWithLocalisaction(Itemtotal.retainedItemServiceCharge)}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Subtotal}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{ShowSummarySubtotal(Itemtotal.retainedItemSubTotal, Itemtotal.retainedItemDiscount)}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LW14 text-right">{{captions.Discount}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LW14 text-right">
                            {{DisplayDiscount(null,Itemtotal.retainedItemDiscount)}}</td>
                        <td></td>
                    </tr>
                    <tr *ngIf="!isVATEnabled && Itemtotal.retainedItemGratuityTax > 0">
                        <td colspan="8" class="LW14 text-right">{{ShopCaptions.GratuityTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">
                            {{localization.localizeCurrency(Itemtotal.retainedItemGratuityTax.toString(), false)}}</td>
                        <td></td>
                    </tr>
                    <tr *ngIf="!isVATEnabled && Itemtotal.retainedItemServiceChargeTax > 0">
                        <td colspan="8" class="LW14 text-right">{{ShopCaptions.ServiceChargeTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">
                            {{localization.localizeCurrency(Itemtotal.retainedItemServiceChargeTax.toString(), false)}}</td>
                        <td></td>
                    </tr>
                    <tr *ngIf="!isVATEnabled">
                        <td colspan="8" class="LW14 text-right">{{captions.Tax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">
                            {{localization.localizeCurrency(Itemtotal.retainedItemTax.toString(), false)}}</td>
                        <td></td>
                    </tr>
                    <tr *ngIf="!isVATEnabled && Itemtotal.retainedItemTaxOnTax != 0">
                        <td colspan="8" class="LW14 text-right">{{captions.TaxOnTax}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right" [ngClass]="{'button_invalid': exempttax.value}">
                            {{localization.localizeCurrency(exempttax.value ? 0 : Itemtotal.retainedItemTaxOnTax.toString(), false)}}
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngIf="isVATEnabled && !PropertyInfo.UseRetailInterface">
                        <td colspan="8" class="LW14 text-right">{{this.localization.captions.setting.vat}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">{{ShowRetainedItemsFinalVATAmountTotal(GridItems.selectedRetainedRetailProducts)}}</td>
                    </tr>
                    <tr *ngIf="isVATEnabled && Itemtotal.retainedItemServiceChargeTax > 0">
                        <td colspan="8" class="LW14 text-right">{{localization.captions.setting.serviceChargeVat}} ({{localization.currencySymbol}})</td>
                        <td class="LW14 text-right">
                            {{localization.localizeCurrency(Itemtotal.retainedItemServiceChargeTax.toString(), false)}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="8" class="LWB14 text-right">{{captions.Total}} ({{localization.currencySymbol}})
                        </td>
                        <td class="LWB14 text-right">{{RetainedSectionTotal}}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </ng-scrollbar>
    </div>
</section>
