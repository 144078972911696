import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { UntypedFormGroup, Validators, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailSetupService } from '../retail-setup.service';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { CommonService } from '../../shared/service/common-service.service';

import * as _ from "lodash";
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SPAConfig } from '../../common/config/SPA-config';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { Host, Maxlength, Product, RetailBreakPoint, SourceSystem } from '../../shared/globalsContant';
import { HttpMethod } from '../../shared/service/http-call.service';
import { ViewMoreServiceService } from '../../shared/view-more/view-more-service.service';
import { RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { ViewSettingClientBusiness } from '../../shared/business/view-settings.business';
import { RetailItemType } from 'src/app/common/shared/retail.modals';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GeneralComponent implements OnInit, OnDestroy {
  public selectedOutletMgrArr: any[] = [];
  defaultRadioValue = "Retail item - Retail POS only";
  Category: any = [];

  validateNext: boolean = false;
  itemcatradiogrp: any = [];
  viewTypeSelected: any;
  generalChanges: any;

  captions: any;
  generalFormGroup: UntypedFormGroup;
  otherInfoFormGroup: UntypedFormGroup; 

  selectedOutletSubscription: Subscription;
  cAllowNegative = 'true';
  outlets: any;
  isSyncItem = true;  
  allowCategory = false;
  isItemNumberEditable = true;
  isDuplicateItemNumber = false;
  isDuplicateBarcode = false;
  isDuplicateSecondaryBarcode = false;
  selectedCategory: number = 0;
  selectedSubCategory1: number = 0;
  selectedSubCategory2: number = 0;
  selectedSubCategory3: number = 0;
  selectedSubCategory4: number = 0;
  selectedSubCategory5: number = 0;
  selectedCategoryArray: Array<any> = [];
  retailItemTypes = RetailItemType;
  
  @Input() isEdit;
  IsViewOnly: boolean = false;  
  loginProd: string;
  spacaptions: any;
  url: any;
  searchFilter: []; 
  generalFormSubscription: any;
  noOfLocations: any;
  linkItemCaptions: { label: string; placeholder: string; error: string };
  functionalities: { [key: string]: boolean} = {};
  searchTextChanged = new Subject<string>();
  notifier: Subject<void> = new Subject<void>();
  defaultOutlet: number ;
  duplicateItemNumber: any;
  duplicateBarCode: any;
  duplicateSecondaryBarcode: any;
  floatLabel: string;
  isCustomFeeItem: boolean = false;
  isExternalItem: boolean = false;

  @Input() parentForm;
  @Input() automationId: string = '';

  constructor(private dialogRef: MatDialogRef<RetailPopupComponent>, private Form: UntypedFormBuilder,
              public _utilities: RetailUtilities, public _retailsetupservice: RetailSetupService, public localization: RetailLocalization, public _cs: CommonService, public _ViewMoreServiceService: ViewMoreServiceService,
              private fromBuilder: UntypedFormBuilder, private business: ViewSettingClientBusiness, public spaconfig: SPAConfig, public PropertyInfo: RetailPropertyInformation, public cdr: ChangeDetectorRef,
              public featureFlagInfo: RetailFeatureFlagInformationService, private func:RetailFunctionalityBusiness) {
    this.isSyncItem = PropertyInfo.UseRetailInterface;
    this.defaultOutlet = PropertyInfo.GetDefaultOutlet();
    this.floatLabel = this.localization.setFloatLabel;
  }

  categoryLinkingObjNamelist: string[];
  categoryLinkingObjArray: any;
  ORGcategoryLinkingObjArray: any[];
  percentageLength = Maxlength.PERCENTAGE;
  SNCProduct: number = Product.SNC;
  ngOnInit() {    
    this.captions = this.localization.captions.retailsetup;
    this.spacaptions = this.spaconfig.captions;
    this.linkItemCaptions = {
      label: this.captions.CopyItem,
      placeholder: '',
      error: ''
    };

    this.generalFormGroup = this._retailsetupservice.GeneralFormGrp;
    this.isExternalItem = this.isEdit && this._retailsetupservice.originalItemDetail?.retailItemDetail?.isExternal && this._retailsetupservice.originalItemDetail.retailItemDetail.sourceSystem == SourceSystem.Eatec;
    this.validateNext = this._retailsetupservice.validateNext('general');
    this.setFormValidators();
    this.initializeFormSubscriptions(); 
    this.showErrorMessages();
    this.otherInfoFormGroup = this._retailsetupservice.otherInfoFormGrp;
    this.outlets = this._retailsetupservice.outlets;
    this.selectedOutletMgrArr = [];
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      if (!this.functionalities?.ShowOutletSelectionFieldInCreateRetailItem) {
        this._retailsetupservice.ShowOutletSelectionFieldInCreateRetailItem = false;
        if (this.defaultOutlet === 0) {
          this.func.getDefaultOutlet().then(x => {
            this.defaultOutlet = x ;
            if (this.defaultOutlet > 0 ) {
              this.PropertyInfo.SetDefaultOutlet(this.defaultOutlet);
            }
          });
        }
        const outlet = this._retailsetupservice.outlets.find(x => x.id === this.defaultOutlet);
        this._retailsetupservice.selectedOutlet = [outlet];
        this._retailsetupservice.selectedOutletMgrArr.next([outlet]);
        if (!this.isEdit) {
          this._retailsetupservice.buildTaxArray(this._retailsetupservice.selectedOutletMgrArr.value);
        }
        this.outlets.forEach(element => {
        if (element.id === this.defaultOutlet) {
          let item = this.generalFormGroup.get('taxConfig').value;
          item = item?.filter(x => x.outletId === this.defaultOutlet > 0 ? this.defaultOutlet : this.outlets[0]?.id);
          this.generalFormGroup.get('taxConfig').setValue(item);
        }
        });
      }
      this.setItemTypes();
      if (this.PropertyInfo.IsEatecAsMaster) {
        this.generalFormGroup.controls['inventory'].disable();
        if (this.isExternalItem) {
          this.disableControlsForExternalItem();
        }
      }
      if (!this.functionalities?.ShowRentalItemCategoryInRetail) {
        this.itemcatradiogrp = this.itemcatradiogrp.filter(x => x.id !== RetailItemType.RentalItem);
      }
      if (this.functionalities?.ShowCustomFeeRetailItemType) {
        this._retailsetupservice.GetCustomFeeConfigurationSetting().then(res => {
          this._retailsetupservice.isCustomFeeItem = res.value == "true";
          if (this._retailsetupservice.isCustomFeeItem)
            this.itemcatradiogrp.push({ id: RetailItemType.CustomFee, name: this.captions.customFee });
        });
      }
    });
    this._retailsetupservice.base64textString = '';
    this.setCategoryInfo();
    this.loginProd = this._retailsetupservice.loginProd;
    this._retailsetupservice.isEdit = this.isEdit;

    
    if (!this.isEdit && this.loginProd != 'SPA') {
      this.generalFormGroup.controls.itemcatradiogrp.setValue(1);
    }

    this.selectedOutletSubscription = this._retailsetupservice.selectedOutletMgrArr.subscribe(outlet => {
      this.selectedOutletMgrArr = outlet;
    });

    if (this.isEdit) {      
      let obj = _.cloneDeep(this.selectedOutletMgrArr);
      let hasUnitCost;
      obj.forEach(element => {
        hasUnitCost = element.unitCost ? element.unitCost : 0;
        element.quantity = element.quantity ? element.quantity : 0
        element.mtd = element.mtd ? element.mtd : 0
        element.ytd = element.ytd ? element.ytd : 0
        const tempUnitCost = element.unitCost ? element.unitCost : 0;
        element.unitCost = this.isEdit ? tempUnitCost : this.generalFormGroup.controls.curitemcost.value
      });
      this._retailsetupservice.selectedOutlet = obj;
    }

    this.searchTextChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(async (searchtxt) => this.searchbyInputAfterDebounce(searchtxt));

      if(this.parentForm){
        this.parentForm.addControl('generalFormGroup', this.generalFormGroup)
      }

  }

  initializeFormSubscriptions() {
    this._retailsetupservice.otherInfoFormGrp.valueChanges.subscribe(x => {
      let disableSalesPrice = this._retailsetupservice.otherInfoFormGrp.get('multipack').value;
      if (disableSalesPrice || this.isExternalItem)
        this._retailsetupservice.GeneralFormGrp.get('salesprice').disable();
      else
        this._retailsetupservice.GeneralFormGrp.get('salesprice').enable();
    });
  }  


  disableControlsForExternalItem()
  {
    this.generalFormGroup.controls['itemdescription'].disable();
    this.generalFormGroup.controls['salesprice'].disable();
    this.generalFormGroup.controls['memberprice'].disable();
    this.generalFormGroup.controls['curitemcost'].disable();
    this.generalFormGroup.controls['itemdescription'].disable();
    this.generalFormGroup.controls['barcode'].disable();
    this.generalFormGroup.controls['secbarcode'].disable();
    this.generalFormGroup.controls['marginpercent'].disable();
    if (this._retailsetupservice.ProductId != Product.SPA) {
      this.itemcatradiogrp.forEach(x => {
        x.id == 1 ? x.disable = false : x.disable = true;
      });
    }
  }

  private setItemTypes() {
    switch (this._retailsetupservice.ProductId) {
      case Product.SPA:
        this.itemcatradiogrp = [
          { id: 1, name: this.captions.RetailItemRetailPOSOnly, disable: false},
          { id: 2, name: this.captions.RetailItemAvailableForSpaPackages, disable: false},
          { id: 3, name: this.captions.SpaServices, disable: false },
          { id: 4, name: this.captions.SpaPackage, disable: false },
          { id: 5, name: this.captions.AppointmentAddon, disable: false },
          { id: 7, name: this.captions.RentalItem, disable: false },
          { id: 6, name: this.captions.Deposit, disable: false }
        ];
        break;
      case Product.GOLF:
        this.itemcatradiogrp = [
          { id: 1, name: this.captions.RetailItemRetailPOSOnly, disable: false },
          { id: 6, name: this.captions.Deposit, disable: false }
        ];
        break;
      case Product.SNC:
        this.itemcatradiogrp = [
          { id: 1, name: this.captions.RetailItemRetailPOSOnly, disable: false },
          { id: 6, name: this.captions.Deposit, disable: false },
          { id: 10, name: this.captions.RevenueItem, disable: false }
        ];
        break;     
      case Product.RETAIL:
      this.itemcatradiogrp = [
        { id: 1, name: this.captions.RetailItemRetailPOSOnly, disable: false }
      ];
      break; 
      case Product.PMS:
      this.itemcatradiogrp = [
        { id: 1, name: this.captions.RetailItemRetailPOSOnly, disable: false },
        { id: 7, name: this.captions.RentalItem, disable: false },
      ];
      break;   
      default:
        this.itemcatradiogrp = [
          { id: 1, name: this.captions.RetailItemRetailPOSOnly, disable: false }
        ];
    }

  }

  setCategoryInfo() {

    this.Category = this._retailsetupservice.categories;    
    this.IsViewOnly = this._retailsetupservice.retailSetupBreakPoints.find(rb => rb.breakPointNumber == RetailBreakPoint.ItemSetup).view;
  }

  OnCopyItemChange(data) {
    if (data.value !== undefined) {
      this._retailsetupservice.GetItemToEdit(data.value);
    } else {
      this.resetAll();
      this.showErrorMessages();
    }
  }

  resetAll() {
    this._retailsetupservice.GeneralFormGrp.reset();
    this._retailsetupservice.otherInfoFormGrp.reset();
    this._retailsetupservice.packagedItemForm.reset();
    this._retailsetupservice.multipackFormGrp.reset();
    this._retailsetupservice.seasonalPriceFormGrp.reset();
    this._retailsetupservice.packagedItems = null;
    this._retailsetupservice.isValidOtherInfo = false;
    this._retailsetupservice.GeneralFormGrp.controls.taxConfig = new UntypedFormArray([]);
  }

  async ValidateDuplicateBarcode() {
    const barCode = this.generalFormGroup.controls.barcode.value;
    const secBarCode = this.generalFormGroup.controls.secbarcode.value;

    if (barCode === '') {
      this.isDuplicateBarcode = false;
      this.duplicateBarCode = '';
      this.generalFormGroup.controls.barcode.reset();
    }

    if (barCode) {
      if (secBarCode === barCode || (await this.isBarcodeExist(barCode))) {
        this.isDuplicateBarcode = true;
        this.duplicateBarCode = barCode;
        this.generalFormGroup.controls.barcode.setErrors({ 'invalid': true});
      }

      if (this.isDuplicateSecondaryBarcode && barCode !== secBarCode && !(await this.isBarcodeExist(secBarCode))) {
        this.isDuplicateSecondaryBarcode = false;
        this.duplicateSecondaryBarcode = '';
        this.generalFormGroup.controls.secbarcode.reset();
        this.generalFormGroup.controls.secbarcode.setValue(secBarCode);
      }

    }
  }

  stopdefaultClick(e) {
    if (this.isEdit && this.IsViewOnly) {
      e.preventDefault();
    }
  }

  async ValidateDuplicateSecondayBarcode() {
    const barCode = this.generalFormGroup.controls.secbarcode.value;
    const primBarCode = this.generalFormGroup.controls.barcode.value;

    if (barCode === '') {
      this.isDuplicateSecondaryBarcode = false;
      this.duplicateSecondaryBarcode = ''
      //this.generalFormGroup.controls.secbarcode.reset();
    }

    if (barCode) {
      if (primBarCode === barCode || (await this.isBarcodeExist(barCode))) {
        this.isDuplicateSecondaryBarcode = true;
        this.duplicateSecondaryBarcode = barCode;
        this.generalFormGroup.controls.secbarcode.setErrors({ 'invalid': true});
      }

      if (this.isDuplicateBarcode && barCode !== primBarCode && !(await this.isBarcodeExist(primBarCode))) {
        this.isDuplicateBarcode = false;
        this.duplicateBarCode = '';
        this.generalFormGroup.controls.barcode.reset();
        this.generalFormGroup.controls.barcode.setValue(primBarCode);
      }
    }
  }

  async isBarcodeExist(barcode): Promise<boolean> {
    barcode = encodeURIComponent(barcode);
    try {
      const response = await this._retailsetupservice.cancellableObservalble(
        new Subject<void>(),
        'IsBarcodeExists',
        Host.retailManagement,
        HttpMethod.Get,
        undefined,
        {
          barcode,
          itemId: this.isEdit ? this._retailsetupservice.originalItemDetail.retailItemDetail.id : 0
        }).toPromise();
      return response.result === true;
    } catch {

    }

    return true;

  }


  CategoryLinkAvailable(selectedCategory): boolean {
    return selectedCategory.length > 0 ? true : false;
  }

  LinkedItemEmit(event) {
    this.OnCopyItemChange({ value: event });
  }

  setFormValidators() {
    this.generalFormGroup.controls.itemnumber.setValidators(this.isSyncItem ? [] : [Validators.required]);
    this.generalFormGroup.controls.itemdescription.setValidators(this.isSyncItem ? [] : [Validators.required]);
    this.generalFormGroup.controls.salesprice.setValidators(this.isSyncItem ? [] : [Validators.required]);
    this.generalFormGroup.controls.categoryid.setValidators(this.isSyncItem ? [] : [Validators.required]);
  }

  showErrorMessages() {
    this.generalFormGroup.controls.itemnumber.markAsTouched();
    this.generalFormGroup.controls.itemdescription.markAsTouched();
    this.generalFormGroup.controls.salesprice.markAsTouched();
    this.generalFormGroup.controls.categoryid.markAsTouched();
  }

  ngAfterViewInit() {
    this.formArrayValueChanges();
  }

  formArrayValueChanges() {
    this._retailsetupservice.GeneralFormGrp.controls["taxConfig"].valueChanges.subscribe(c => { this.onFormChange() });
  }

  ngOnDestroy(): void {
    //Ensures all controls are enabled while closing( applicable only if the breakpoint is view only, Since the FormGroups are in service)
    this._utilities.enableControls(this.generalFormGroup);
    this._utilities.enableControls(this.otherInfoFormGroup);
    this._utilities.enableControls(this._retailsetupservice.seasonalPriceFormGrp);
    this._utilities.enableControls(this._retailsetupservice.packagedItemForm);
    this._utilities.enableControls(this._retailsetupservice.multipackFormGrp);
    if (this.generalChanges) {
      this.generalChanges.unsubscribe();
    }
    this.selectedOutletSubscription.unsubscribe();
    this.duplicateBarCode = '';
    this.duplicateItemNumber = '';
    this.duplicateSecondaryBarcode = false;
    this.isDuplicateItemNumber = false;
    this.isDuplicateBarcode = false;
    this.isDuplicateSecondaryBarcode = false;
    this._retailsetupservice.isEdit = false;
    this._retailsetupservice.selectedOutlet = [];
  }

  emitedValue(event) {
    this.toggleButtonClick(event[0], event[2]);
  }
  toggleButtonClick(event, item) {
    let currentItemcost = this._retailsetupservice.GeneralFormGrp.controls.curitemcost.value;
    let obj = this._utilities.getOutletFilterByObject(this._retailsetupservice.outlets, this.selectedOutletMgrArr, item, 'id');
      obj.forEach(element => {
        element.quantity = this.isEdit && element.quantity ? element.quantity : 0
        element.mtd = this.isEdit && element.mtd ? element.mtd : 0
        element.ytd = this.isEdit && element.ytd ? element.ytd : 0
        element.unitCost = this.isEdit && element.unitCost ? element.unitCost : currentItemcost
        element.itemPar = this.isEdit && element.itemPar ? element.itemPar : 0
      });
    this._retailsetupservice.selectedOutlet = obj;
    this._retailsetupservice.selectedOutletMgrArr.next(obj);
    this.onFormChange();
    this._retailsetupservice.buildTaxArray(this._retailsetupservice.selectedOutletMgrArr.value, item);
    
    if (this._ViewMoreServiceService.VM_displayData) {
      this._ViewMoreServiceService.VM_displayData.selecteddisplaydataArr = this.GetSelectedOutletMgrArrId(this.selectedOutletMgrArr);
    }
    this._retailsetupservice.updateUnitCost();
    let taxBind = this.generalFormGroup.controls.taxConfig;
    this.generalFormGroup.setControl('taxConfig', taxBind);
  }
 
  OnSalePriceBlur(event) {
    if (this.localization.currencyToSQLFormat(this.generalFormGroup.controls.memberprice.value) <= 0 && this.localization.currencyToSQLFormat(this.generalFormGroup.controls.salesprice.value) > 0) {
      this.generalFormGroup.controls.memberprice.setValue(this.generalFormGroup.controls.salesprice.value)
    }
  }

  close() {
    this.dialogRef.close();
  }

  taxInfoToggleEvt(event, j, taxInfo, i?) {
    if (this.PropertyInfo.IsVATEnabled) {    
        this.generalFormGroup.controls.taxConfig['controls'][j].setValue({ 'outletId': taxInfo.outletId, 'outletName': taxInfo.outletName, 'id': taxInfo.id, 'taxName': taxInfo.taxName, 'isSelected': event[0] });
      } else if (!this.PropertyInfo.IsVATEnabled) {
      this.generalFormGroup.controls.taxConfig['controls'][i]['controls'][j].setValue({ 'outletId': taxInfo.outletId, 'outletName': taxInfo.outletName, 'id': taxInfo.id, 'taxName': taxInfo.taxName, 'isSelected': event[0] });
    }
  }

  async generateItemNumber(e) {
    if (e[0]) {
      this.isItemNumberEditable = false;
      let result = await this._retailsetupservice.GenerateItemNumber()
      this.generalFormGroup.controls.itemnumber.setValue(result);
      this.generalFormGroup.controls.barcode.setValue(result);
    }
    else {
      this.isItemNumberEditable = true;
      this.isDuplicateItemNumber = false;
      this.generalFormGroup.controls.itemnumber.setValue('');
      this.generalFormGroup.controls.barcode.setValue('');
    }
  }


  private onFormChange(outlet?): void {
    this.generalFormGroup.markAsTouched();

    this.validateNext = this.generalFormGroup.valid && this.selectedOutletMgrArr.length > 0;
    this.generalFormGroup.markAsDirty();
    this._retailsetupservice.ValidateUpdate();
  }

  radioChange(e) {
    if (e.value == 4) {
      this.generalFormGroup.controls.commissionableitem.setValue(false);
      this.generalFormGroup.controls.commissionreq.setValue(false);
    }

    if (e.value == 1 || e.value == 2 || e.value == 4) {
      this.otherInfoFormGroup.controls.gratuity.setValue(false);
      this.otherInfoFormGroup.controls.serviceTax.setValue(false);
      this.otherInfoFormGroup.controls.gratuityValue.setValue('');
      this.otherInfoFormGroup.controls.serviceTaxValue.setValue('');
    }

    if (e.value == RetailItemType.CustomFee) {
      this.generalFormGroup.controls.commissionableitem.setValue(false);
      this.generalFormGroup.controls.commissionreq.setValue(false);
      this.otherInfoFormGroup.controls.gratuity.setValue(false);
      this.otherInfoFormGroup.controls.serviceTax.setValue(false);
      this.otherInfoFormGroup.controls.gratuityValue.setValue('');
      this.otherInfoFormGroup.controls.serviceTaxValue.setValue('');
      this.otherInfoFormGroup.controls.groupKey.setValue(false);
      this.otherInfoFormGroup.controls.scaleItem.setValue(false)
      this.otherInfoFormGroup.controls.inactive.setValue(false)
      this.otherInfoFormGroup.controls.requireComments.setValue(false);
      this.otherInfoFormGroup.controls.scaleItem.setValue(false)
      this.otherInfoFormGroup.controls.openItem.setValue(false);
      this.otherInfoFormGroup.controls.pms.setValue(false)
      this.otherInfoFormGroup.controls.multipack.setValue(false);
      this.otherInfoFormGroup.controls.requestName.setValue(false);
      this.otherInfoFormGroup.controls.unit.setValue(0);
      this.otherInfoFormGroup.controls['unit'].markAsUntouched();
      this.otherInfoFormGroup.controls['unit'].markAsPristine();
      this.otherInfoFormGroup.controls['unit'].updateValueAndValidity();
      this.generalFormGroup.controls.inventory.setValue(false);
    }

    if (this._retailsetupservice.ProductId == Product.SNC) {
      if (e.value == 10) {
        this.generalFormGroup.controls["memberprice"].disable();
        this.generalFormGroup.controls["curitemcost"].disable();
        this.generalFormGroup.controls["marginpercent"].disable();
        this.generalFormGroup.controls["memberprice"].disable();
        this.otherInfoFormGroup.controls.groupKey.setValue(false);
        this.otherInfoFormGroup.controls.scaleItem.setValue(false)
        this.otherInfoFormGroup.controls.inactive.setValue(false)
        this.otherInfoFormGroup.controls.requireComments.setValue(false);
        this.otherInfoFormGroup.controls.scaleItem.setValue(false)
        this.otherInfoFormGroup.controls.openItem.setValue(false);
        this.otherInfoFormGroup.controls.pms.setValue(false)
        this.otherInfoFormGroup.controls.multipack.setValue(false);
        this.otherInfoFormGroup.controls.requestName.setValue(false);
        this.otherInfoFormGroup.controls.hangTicket.setValue(false)
        this.generalFormGroup.controls.commissionableitem.setValue(false);
        this.generalFormGroup.controls.commissionreq.setValue(false)
        this.generalFormGroup.controls.inventory.setValue(false);
      }
      else {
        this.generalFormGroup.controls["memberprice"].enable();
        this.generalFormGroup.controls["curitemcost"].enable();
        this.generalFormGroup.controls["marginpercent"].enable();
        this.generalFormGroup.controls["memberprice"].enable();
      }
    }

  }

  async getItemDetailsByItemNumber(item) {
    if (!this.isSyncItem && this.generalFormGroup.controls.itemnumber.value) {
      let itemInfo = await this._retailsetupservice.GetItemByItemNumber(this.generalFormGroup.controls.itemnumber.value);
      if (itemInfo && itemInfo.retailItem) {
        this.duplicateItemNumber = this.generalFormGroup.controls.itemnumber.value;
        this.generalFormGroup.controls.itemnumber.setErrors({ invalid: true });
      };
    }
    if (this.generalFormGroup.controls.itemnumber.value == '') {
      this.isDuplicateItemNumber = false;
    }

    if (!this.isDuplicateItemNumber) {
      this.generalFormGroup.controls.barcode.setValue(this.generalFormGroup.controls.itemnumber.value);
    }
  }

  GetSelectedOutletMgrArrId(array) {
    if (array && array.length > 0) {
      return array.map(x => x.id);
    }
    return [];
  }

  trackByFn(index) {
    return index;
  }

  bindLocations(event) {
    this.noOfLocations = event;
  }

  searchTextEmit(arg: string) {
    this.searchTextChanged.next(arg);
  }

  async searchbyInputAfterDebounce(searchtxt: string): Promise<void> {

    if (searchtxt === undefined) {
      this.OnCopyItemChange({ value: undefined });
      return;
    }

    const outletId = this._retailsetupservice.selectedOutletMgrArr.value.map(x => x.id);

    if (searchtxt.length < 3 || outletId.length <= 0) {
      return;
    }

    this.cancelOngoingCall();
    this.notifier = new Subject<void>();

    this.searchFilter = [];

    try {
      const response = await this._retailsetupservice.cancellableObservalble(
        this.notifier,
        'SearchRetailItemsByType',
        Host.retailManagement,
        HttpMethod.Put,
        {
          itemType: -1,
          searchKey: searchtxt,
          outletId          
        },
        undefined
      ).toPromise();

      this.searchFilter = response.result.retailItems;
    } catch {

    }
  }

  cancelOngoingCall() {
    this.notifier.next();
    this.notifier.complete();
  }

  commissionableToggleChange(isCommissionable : boolean){
    if(isCommissionable){
      this._retailsetupservice.selectedOutletMgrArr.next(this._retailsetupservice.selectedOutletMgrArr.value);
    }
  }
  itemDescChange(){
    this._retailsetupservice.retailItemDescChangeEvent.next(this._retailsetupservice.GeneralFormGrp.controls.itemdescription.value);
  }
}


