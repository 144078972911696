import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CacheService } from '@app/core/services/cache.service';
import { AdvanceBlockDataShare } from '@app/popup-module/components/services/advance-block.data';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { PricingBy, buttonTypes } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { SpecialMealDTO } from '@app/shared/models/RestaurantDTO';
import { ButtonValue } from '@app/shared/shared-models';
import { Utilities } from '@app/shared/utilities/utilities';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

@Component({
  selector: 'app-activity-advance-block-session',
  templateUrl: './session-selection.component.html',
  styleUrls: ['./session-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityAdvanceBlockSessionComponent extends Utilities implements OnInit, AfterViewInit {
  @ViewChild('addActivityButton') private activitiesList: NgbPopover;
  searchConfigForm: DynamicFormComponent;
  //showPastActivitiesForm:DynamicFormComponent;
  //@ViewChild('showPastActivitiesForm') showPastActivitiesForm: DynamicFormComponent;
  @ViewChild('searchConfigForm') set content(content: DynamicFormComponent) {
    if(content) { // initially setter gets called with undefined
        this.searchConfigForm = content;
    }
 }

//  @ViewChild('showPastActivitiesForm') set data(data: DynamicFormComponent) {
//   if(data) { // initially setter gets called with undefined
//       this.showPastActivitiesForm = data;
//   }
// }

  headerData = [
    { day: 'Mon', dayOfWeek: 1 },
    { day: 'Tue', dayOfWeek: 2 },
    { day: 'Wed', dayOfWeek: 3 },
    { day: 'Thu', dayOfWeek: 4 },
    { day: 'Fri', dayOfWeek: 5 },
    { day: 'Sat', dayOfWeek: 6 },
    { day: 'Sun', dayOfWeek: 0 },
  ]
  showList: boolean = false;
  searchText: string = '';

  allActivities: SpecialMealDTO[];
  addToBlockButton: ButtonValue;
  selectedButton: ButtonValue;
  addActivityButton: ButtonValue;
  config : FieldConfig;
  activityType: 'group' | 'session';
  advBlockData: AdvanceBlockDataShare;
  selectedAll = false;
  showPast = false;
  calenderViewData: {
    Name: string,
    Id: number,
    Days: {
      Day: number,
      Sessions: {
        ActivitySessionId: number;
        StartTime: string;
        EndTime: string;
        Selected: boolean;
      }[]
    }[]
    selectedSessionCount?: number;
  }[] = [];

  constructor(private cacheSevice: CacheService, private ps: PopupService, private ts: TranslateService, @Inject(COMPONENTINPUT) public inputData, private changeDetectorRef: ChangeDetectorRef,dialog:MatDialog) {
    super(dialog)
    this.allActivities = _.cloneDeep(this.cacheSevice.settings.value.SpecialMeals.filter(_fActivity => ( _fActivity.ClassType === PricingBy.Session)));
    this.allActivities = _.orderBy(this.allActivities, "Name")
    this.addToBlockButton = {
      type: buttonTypes.actionPrimarySmall,
      label: inputData.type == 1 ? 'addToBlock' : 'addToUnBlock'
    };
    this.selectedButton = {
      type: buttonTypes.actionSecondaryOrange,
      label: 'Unselect'
    };
    this.addActivityButton = {
      type: buttonTypes.actionSecondary,
      label: inputData.type == 1 ? 'addActivityToBlock' : 'addActivityToUnBlock'
    };
    this.ps.advBlockData = this.advBlockData = new AdvanceBlockDataShare();
    if(inputData?.data){
      this.showSelectedActivity();
    }
  }


  showSelectedActivity(){
    this.addActivity(this.inputData.data.ActivityId);
    this.inputData.data.ActivitySessionIds.forEach(sessionId =>{
      this.sessionClick({ActivitySessionId: sessionId},{Id: this.inputData.data.ActivityId});
    })
   
  }

  selectAllActivities(){
    this.selectedAll = !this.selectedAll;
    if(this.showPast){
      this.allActivities.forEach(_activity => {
        this.selectActivity(_activity);
      });
    }
    else{
      this.allActivities.forEach(_activity => {
        if(new Date(_activity.EndDate) >= new Date(Utilities.getRestaurantDateTime(this.cacheSevice.settings.value.General.DaylightDelta)) && _activity.Name.toLowerCase().includes(this.searchText.toLowerCase()) && !this.advBlockData.selectedActivities[_activity.Id])
          this.selectActivity(_activity);
      });
    }


  }


  selectActivity(_activity){
    if(_activity.Name.toLowerCase().includes(this.searchText.toLowerCase()) && !this.advBlockData.selectedActivities[_activity.Id])
          this.formSlotView(_activity);
  }

  unSelectAllActivities(){
    this.selectedAll = !this.selectedAll;
    this.allActivities.forEach(_activity => {
      if(_activity.Name.toLowerCase().includes(this.searchText.toLowerCase()) && this.advBlockData.selectedActivities[_activity.Id])
        this.removeActivity(_activity.Id);
    });
  }

  removeAllActivities(){
  
    this.allActivities.forEach(_activity => {
      this.removeActivity(_activity.Id);
    });
  }

  addActivity(activityId: number): void {
    let _activity = this.allActivities.find(activity => activity.Id === activityId);

   this.formSlotView(_activity);
  }

  addActivityClick(){
    this.searchText = '';
  }

  getDays(){
    let _days = []
    for (let dayIndex = 1; dayIndex <= 7; dayIndex++) {
      _days[dayIndex] = { Day: dayIndex, Sessions: [] }
    }
    return _days;
  }

formSlotView(_activity){
  let _days = this.getDays();
  _activity.ActivitySessions.forEach(session => {
    
    let column = session.Dayofweek ? session.Dayofweek : 7;
    if (!_days[column]) {
      _days[column] = { Day: column, Sessions: [] }
    }

    let [shr, smin, ssec] = session.StartTime.split(":");
    let [ehr, emin, esec] = session.EndTime.split(":");
    var StartTimeObj = this.getDate(+shr, +smin, +ssec);
    var EndTimeObj = this.getDate(+ehr, +emin, +esec);

    _days[column].Sessions.push({
      ActivitySessionId: session.ActivitySessionId,
      StartTime: StartTimeObj,
      EndTime: EndTimeObj,
      Selected: false,
      Date: session.ParentSessionId ? session.SessionDate : null
    })
    _days[column].Sessions.sort((d1, d2) => new Date(d1.StartTime).getTime() - new Date(d2.EndTime).getTime());
  });
  this.calenderViewData.push({
    Name: _activity.Name,
    Id: _activity.Id,
    Days: _days
  });

  this.showList = false;

  if(this.calenderViewData?.length === 1){
    this.allActivities = this.allActivities.filter(_fActivity => _activity.ClassType === _fActivity.ClassType);
    this.activityType = _activity.ClassType === PricingBy.Class ? 'group' : 'session';
  }

  this.advBlockData.selectedActivities[_activity.Id] = {
    selectedSessionCount: 0,
    Sessions: {}
  }

  this.allActivities = _.orderBy(this.allActivities, "Name");
  this.changeActionState();
}

  removeActivity(activityId: number): void{
    this.selectedAll = false;
    this.calenderViewData = this.calenderViewData.filter(_fActivity => _fActivity.Id !== activityId);
    delete this.advBlockData.selectedActivities[activityId];

    if(!this.calenderViewData?.length){
      this.allActivities = _.cloneDeep(this.cacheSevice.settings.value.SpecialMeals.filter(_fActivity => ( _fActivity.ClassType === PricingBy.Session)));
    }
    this.allActivities = _.orderBy(this.allActivities, "Name");
    this.changeActionState();
  }

  sessionClick(session, activity){

    if(!this.advBlockData.selectedActivities[activity.Id]){
      this.advBlockData.selectedActivities[activity.Id] = {
        selectedSessionCount: 0,
        Sessions: {}
      }
    }
    
    if(this.advBlockData.selectedActivities[activity.Id].Sessions[session.ActivitySessionId]){
      delete this.advBlockData.selectedActivities[activity.Id].Sessions[session.ActivitySessionId];
    }else{
      this.advBlockData.selectedActivities[activity.Id].Sessions[session.ActivitySessionId] = true;
    }

    this.advBlockData.selectedActivities[activity.Id].selectedSessionCount = Object.keys(this.advBlockData.selectedActivities[activity.Id].Sessions).length;
    this.ps.advBlockData.selectedActivities = this.advBlockData.selectedActivities;
    this.changeActionState();
  }

  changeActionState(){
    let _isSessionSelected =  this.calenderViewData.length && (this.calenderViewData.length === Object.values(this.advBlockData.selectedActivities).filter(({selectedSessionCount}) => selectedSessionCount).length);

    this.ps.nextBtnEnabled$.next(!_isSessionSelected);
    this.ps.formValid$.next(
      {
        isFormValid: _isSessionSelected,
        currentTab: 0
      } as IFormValidDetails
    );
  }

  ngAfterViewInit(): void {
    // this.addActivity(1765);
    // this.addActivity(1767);

    if(!this.calenderViewData.length){
      this.showList = true;
    }
   // this.activitiesList.open();
  }
  ngOnInit(): void {
    this.config =
      {
        type: 'switch',
        name: 'showPast',
        label: this.ts.instant('showPastReservations'),
        class: 'show-past-reservations',
        checked: this.showPast,
        value: this.showPast,
      };
    
  }

  showPastReservations(data){
    this.showPast = data.target.checked;
   // this.showPastActivitiesForm.form.get('showPast').setValue(this.showPast);
  }

  searchEvent(event): void {
    this.searchText = event.currentTarget.value || '';
  }
  
  clearSearch(event, ctrl, newCtrl): void {
    this.searchText = '';
  }

  getDate(hr: number, min: number, sec: number): Date {
    let date = new Date();
    date.setHours(hr);
    date.setMinutes(min);
    date.setSeconds(sec);
    return date;
  }

}



@Pipe({
  name: 'showPastFilterPipe'
})
export class ShowPastFilterPipe implements PipeTransform {

  transform(activites, showPast,_settings): any 
  {
    if(!showPast){
     return  activites.filter(data => new Date(data.EndDate) >= new Date(Utilities.getRestaurantDateTime(_settings.General.DaylightDelta)))
    }
    return activites;
  }
}