import { Component, OnInit } from '@angular/core';
import { AppService } from '@app/app.service';
import { TabsModel } from '@app/popup-module/models/popup.interface';
import { AboutComponent } from './about/about.component';
import { TrainingComponent } from './training/training.component';
import { WhatsNewComponent } from './whats-new/whats-new.component';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  helpSectionTabs: TabsModel;

  constructor(private _as: AppService) { }

  ngOnInit(): void {
    this.helpSectionTabs = {
      tabs: [{
        tabComponent: TrainingComponent,
        tabLabel: 'training',
      }//,
      // {
      //   tabComponent: WhatsNewComponent,
      //   tabLabel: 'whatsNew',
      // },
      // {
      //   tabComponent: AboutComponent,
      //   tabLabel: 'about'
      // }
    ],
      default: true
    };
  }

  tabChange() {

  }

  closeHelp() {
    this._as.showHelpScreen = false;
  }

}
