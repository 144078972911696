<virtual-scroller #scroll [items]="_bodyContentData" style="height: calc(100% - 5px)" id="commonSimpleTable"
  *ngIf="_bodyContentData && _bodyContentData.length>0"
  [ngClass]="{'stopscroll': this.checkvaluechanged && this.checkvaluechanged!=''}">
  <table aria-describedby="">
    <thead #header>
      <tr>
        <th scope='col' *ngIf="radiobuttonOption" class="textCenter"> {{commonCaptions.Select}}
        </th>
        <th scope='col' *ngIf="checkBoxOption" class="textCenter"> 
          <mat-checkbox (change)="!this.checkvaluechanged!=''&& emitcheckAlldata($event)" attr.automationId='Chk_{{automationId}}_checkBoxHdr'
            [checked]="checkeddata.length==_bodyContentData.length" *ngIf=" !this.checkvaluechanged!=''"></mat-checkbox>
        </th>
        <th scope='col' *ngFor="let headerdata of  headerOption" [ngClass]="headerdata.alignment"
          (click)="headerdata.sorting && (!checkvaluechanged!='') && sorting( headerdata.key,ordertype)">
          {{headerdata.description}}

          <span
            *ngIf="headerdata.sorting && (!checkvaluechanged!='') && (_bodyContentData  && _bodyContentData.length > 0)">
            <span
              *ngIf="( ([headerdata.key,headerdata.key].indexOf(sortingColoumnKey)) !=-1) && ordertype==sorTypeenum.asc">
              <span class="icon-filled_up_arrow deg0"></span>
            </span>
            <span
              *ngIf="( !(([headerdata.key,headerdata.key].indexOf(sortingColoumnKey)) !=-1) && (ordertype==sorTypeenum.desc || ordertype==sorTypeenum.asc))">
              <span class="icon-sortArrow sort-icon"></span>
            </span>
            <span
              *ngIf="( ([headerdata.key,headerdata.key].indexOf(sortingColoumnKey)) !=-1) && ordertype==sorTypeenum.desc">
              <span class="icon-filled_up_arrow deg180"></span>
            </span>
          </span>
        </th>
        <th scope='col' *ngIf="tableOptions.options" class="textCenter">{{this.captions.actions}}</th>
      </tr>
    </thead>
    <tbody #container cdkDropList class="TBodydataList" (cdkDropListDropped)="drop($event)">
      <tr cdkDrag *ngFor="let _bodyContentData of  scroll.viewPortItems;let i=index" class="cust-row"
        [cdkDragDisabled]="(this.checkvaluechanged && this.checkvaluechanged!=='') || (tableOptions.dragDisabled) ?true:false"
        [ngClass]="((this.checkvaluechanged && this.checkvaluechanged!=='') && (_bodyContentData != this.checkvaluechanged)) ? 'disabledow' : ''">
        <td *ngIf="radiobuttonOption" class="textCenter">
            <mat-radio-group class="radio-group golf__radio" name="radioButtonGrp" attr.automationId='Rdo_{{automationId}}_radioButtonGrp{{i}}'>
              <mat-radio-button class="radio-button golf--form-radio-button suppress-z" 
              (change)="emitcheckdata($event,_bodyContentData,tableOptions.key,'checked')"
              [checked]="_bodyContentData.checked === true" [value]="_bodyContentData.checked" [disabled]="isViewMode">
              </mat-radio-button>
            </mat-radio-group>
        </td>
        <td *ngIf="checkBoxOption" class="textCenter">
           <mat-checkbox (change)="emitcheckdata($event,_bodyContentData,'','')" [disabled]="isViewMode"
            [checked]="checkeddata.length | checkorderExist:_bodyContentData:checkeddata" attr.automationId='Chk_{{automationId}}_checkBoxOption{{i}}'></mat-checkbox> 
        </td>
        <td *ngFor="let headerdata of  headerOption" [ngClass]="headerdata.alignment">
          <span *ngIf="!headerdata.templatecontainer">{{_bodyContentData[headerdata.key]}}</span>
          <span *ngIf="headerdata.templatecontainer" [ngClass]="{'ag_section--disable': isViewMode}">
            <ng-container [ngTemplateOutlet]="this[headerdata.templatecontainer.templateName]"
              [ngTemplateOutletContext]="{rowValue:_bodyContentData,value:_bodyContentData[headerdata.key],headerdata:headerdata,key: headerdata.key,  maxlength:headerdata.maxlength}">
            </ng-container>

          </span>
        </td>
        <td *ngIf="tableOptions.options" class="textCenter">
          <ng-container *ngIf="_bodyContentData != this.checkvaluechanged">
            <ng-container *ngFor="let tabOpt of tableOptions.options" [ngTemplateOutlet]="this[tabOpt]"
              [ngTemplateOutletContext]="{rowValue:_bodyContentData}"></ng-container>
            <span class="icon-drag-drop custtab-pr5 custtab-pointer" cdkDragHandle
              [ngClass]="{'displayNone':(tableOptions.options.indexOf('dragdrop')==-1)}"></span>
          </ng-container>

          <ng-container *ngIf="_bodyContentData == this.checkvaluechanged">
            <ng-container *ngTemplateOutlet="DoneCancel; context: {rowValue:_bodyContentData}"></ng-container>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="((_bodyContentData && _bodyContentData.length<=0) && (originaldata && originaldata.length>0)) "
    class="norecords-alignment fittotable text-center">
    <span>{{ commonCaptions.NoSearchResultFound }}</span>
  </div>
</virtual-scroller>
<div *ngIf="(_bodyContentData && _bodyContentData.length <= 0)" class="norecords-alignment text-center">
  <span>{{ commonCaptions.NoDataFound }}</span>
</div>


<ng-template #inputTemplate let-x="rowValue" let-y="value" let-HdrData="headerdata" let-key="key"
  let-maxlength="maxlength">
  <input matInput (input)="emitOnInput($event,x)" inputtype="notallowspace,nospecailchar" [(ngModel)]="x[key]"
    [maxlength]="maxlength" attr.automationId='Txt_{{automationId}}_{{x[key]}}{{i}}'>
</ng-template>

<ng-template #inputNumberTemplate let-x="rowValue" let-y="value" let-HdrData="headerdata" let-key="key"
  let-maxlength="maxlength">
  <input matInput (input)="emitOnInput($event,x)" numMinMaxlength min='{{HdrData.min}}' max='{{HdrData.max}}'
    inputtype='onlynumber' [(ngModel)]="x[key]" attr.automationId='Txt_{{automationId}}_{{x[key]}}{{i}}'>
</ng-template>

<ng-template #template1 let-x="rowValue" let-y="value" let-HdrData="headerdata">
  <input type="password" [value]="y" attr.automationId='Txt_{{automationId}}_{{x[key]}}{{i}}'>
</ng-template>

<ng-template #toggleTemplate let-x="rowValue" let-y="value" let-HdrData="headerdata">
  <ui-switch [disabled]="HdrData.templatecontainer.disabledFlag" [checkedLabel]="this.captions.yes" class="ui-switch"
    [uncheckedLabel]="this.captions.no" [checked]="y" (change)="valueChanged($event, x,HdrData)" attr.automationId='Tog_{{automationId}}_{{x[key]}}{{i}}'>
  </ui-switch>
</ng-template>

<ng-template #templateIconName let-x="rowValue" let-y="value" let-HdrData="headerdata">
  <i aria-hidden="true" class="icon-Circle" [ngClass]="x.iconname"></i>{{y}}
</ng-template>

<!-- Option Templates-->
<ng-template #edit let-x="rowValue">
  <span class="icon-edit-new custtab-pr5 custtab-pointer" [ngClass]="{'restricted': x.isRestrictEdit}"
    (click)="emitEditrowdata($event,x)" attr.automationId='Icn_{{automationId}}_edit{{i}}'></span>
</ng-template>
<ng-template #delete let-x="rowValue">
  <span class="icon-delete custtab-pr5 custtab-pointer" [ngClass]="{'restricted': x.isRestrictDelete}"
    (click)="emitDeleterowdata($event,x)" attr.automationId='Icn_{{automationId}}_delete{{i}}'></span>
</ng-template>
<ng-template #claim let-x="rowValue">
  <span class="icon-confirmed custtab-pr5 custtab-pointer" (click)="emitClaimrowdata($event,x)"
  attr.automationId='Icn_{{automationId}}_confirmed{{i}}'></span>
</ng-template>
<ng-template #moreoption let-x="rowValue">
  <span class="icon-more custtab-pr5 custtab-pointer" [matMenuTriggerFor]="menuOptions"
    [matMenuTriggerData]="{'menuList':x.menuList,'rowData':x}" attr.automationId='Icn_{{automationId}}_more{{i}}'></span>
</ng-template>
<ng-template #DoneCancel let-x="rowValue">
  <span class='done' (click)='done($event,x)'>
    <i aria-hidden="true" class='icon-done' attr.automationId='Icn_{{automationId}}_done{{i}}'>&nbsp;</i>{{this.captions.done}}
  </span>
  <span class='cancel' (click)='cancel($event,x)'>
    <i aria-hidden="true" class='icon-Cancel' attr.automationId='Icn_{{automationId}}cancel{{i}}'>&nbsp;</i>{{this.captions.cancel}}
  </span>
</ng-template>

<!-- Menu Options-->
<mat-menu #menuOptions="matMenu">
  <ng-template let-menuList="menuList" let-rowData="rowData" matMenuContent>
    <button *ngFor="let x of menuList;let i =index" mat-menu-item (click)="emitmoreoptiondata($event,x,rowData)" 
    attr.automationId='Btn_{{automationId}}_menu{{i}}'>{{x}}</button>
  </ng-template>
</mat-menu>