import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { buttonTypes } from '@constants/commonenums';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { PopupService } from '@popup-module/popup.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationPopupComponent implements OnInit, AfterViewInit {

  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  public confirmationMessage: string;
  public dialogTitle: string;
  hideTitle: boolean;
  @Input() data: any;
  buttonValueOk: ButtonValue;
  isInitial = true;
  showAlert = false;
  forConfirmationEmail = false;
  showConfirmationNumber = true;
  emailConfig: FieldConfig[];
  subscriptions: Subscription = new Subscription();
  listOfItem = null;

  constructor(private ps: PopupService, private cdf: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.buttonValueOk = {
      type: buttonTypes.actionSecondary,
      label: 'ok',
    };
    this.forConfirmationEmail = this.data && this.data[0].sendConfirmationEmail;
    if(this.data && this.data[0].showConfirmationNumber != undefined){
      this.showConfirmationNumber = this.data[0].showConfirmationNumber;
    }
    this.dialogTitle = this.data[0].dialogTitle;
    this.confirmationMessage = this.data[0].confirmationMessage;
    this.showAlert = this.data[0].showAlert ? this.data[0].showAlert : false;
    if (this.dialogTitle == null || this.dialogTitle === '') { this.hideTitle = true; }
    this.listOfItem = this.data[0].ListOfItem;
    this.emailConfig = [
      {
        type: 'input',
        name: 'email',
        inputType: 'text',
        label: 'emailAddress',
        class: 'confirmation-popup__email',
        validation: [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')],
        showErrorText: true
      }
    ];
  }

  ngAfterViewInit() {
    if (this.forConfirmationEmail) {
      this.dynamicForm.form.patchValue({
        email: this.data[0].email
      });
      this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe((data) => {
        this.data[0].email = data.email;
        this.ps.cancelBtnEnable$.next(this.dynamicForm.form.controls['email'].valid && data.email);    
      }));
    }
    this.cdf.detectChanges();
  }
  
  close(eve) {
    this.ps.closeDialog$.next();
  }
  submit() {
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
