export class ManualSlot {
  public Id: number = null;
  public RestaurantId: number = null;
  public PartyId: number = null;
  public DateTime: Date = null;
  public MinPartySize: number = null;
  public MaxPartySize: number = null;
  public IsWebReservable: boolean = false;
  public SeatingAreaId: number = null;
  public IsDeleted: boolean = false;
  public DefaultSlotId: number = null;
  public TiedToParty: boolean = false;
  public CreatedAt: Date = null;
  public UpdatedAt: Date = null;
  public TypeInternal: number = null;
  public LockedAtInternal: Date = null;
  public LockExpiresAtInternal: Date = null;
  public LockNumberOfCoversInternal: number = null;
}

export class ManualSlotIdDTO {
  public DateTime: Date = null;
  public Id: number = null;
  public IsDefault: boolean = false;
}
export class UpdatedManualSlotDTO {
  public Id: ManualSlotIdDTO = null;
  public MinPartySize: number = null;
  public MaxPartySize: number = null;
  public IsWebReservable: boolean = false;
  public SeatingAreaId: number = null;
}
