import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customerRating'
  })
  export class CustomerRatingPipe implements PipeTransform {
    transform(customerRating: number): string[] {
      let ratingCount = [1,2,3,4,5];
      let stars = [];
      ratingCount.forEach(rating => {
        if(!Number.isInteger(customerRating) && (Math.floor(customerRating) + 1 ) == rating){
          stars.push('star-half');
        }else if(rating <= customerRating){
          stars.push('star-full')
        }else{
          stars.push('star-empty')
        }
      })
     return stars || [];
    }
  }
