<div>

  <div class="itinerary_detail_group">

    <!-- Label Section -->
    <div class="itinerary_detail_item" >
      <div class="detail-key">
        <span class="seat-grid-header">{{ 'Name' | translate }} :</span>
      <span >{{ fullName() }}</span></div>
      <div class="detail-key">
        <span class="seat-grid-header">{{ 'PhoneNumber' | translate }} :</span>
      <span >{{ data.PhoneNumber | phoneNumberFormatter: data.CountryId }}</span></div>
      <div class="detail-key">
        <span class="seat-grid-header">{{ 'EmailAddress' | translate }} :</span>
      <span >{{ data.EmailAddress }}</span></div>   
    </div>
  
  
    <!-- Button Section -->
    <div class="itinerary-btn-list" style="width: 50%;">
      <activities-app-button [buttontype]="itineraryUpComingButton"
        (valueChange)='onChangeFiltertab(2)'></activities-app-button>
      <activities-app-button [buttontype]="itineraryUpPastButton"
        (valueChange)='onChangeFiltertab(1)'></activities-app-button>
      <activities-app-button [buttontype]="itineraryUpAllgButton"
        (valueChange)='onChangeFiltertab(0)'></activities-app-button>
    </div>
  
  </div>
  

  <section class="itinerary-table-section example-container mat-elevation-z8" tabindex="0">

    <table mat-table [dataSource]="itineraryGridDataSource" matSort multiTemplateDataRows>

      <!-- StartDateTime Column -->
      <ng-container matColumnDef="StartEndDateTime">
        <td mat-cell *matCellDef="let element;let i = dataIndex" style="width: 100%;">

          <div class="main-table-item">

            <div class="main-table-row">

              <div class="main-table-left-item seat-header-three">

                <span >{{element.startDateTime | localizedDate: cs.settings.value.General.DateFormat}} </span>
                <span >{{'to' |  translate}}</span>
                <span > {{element.endDateTime | localizedDate: cs.settings.value.General.DateFormat}}</span>
                
              </div>

              <div class="main-table-right-item">
                <div *ngFor="let icon of element.iconsGroup;" style="display: flex;">

                  <div *ngIf="icon[1] > 1" matBadge="{{icon[1]}}" matBadgeOverlap="false" class="demo-section">
                    <i style="font-size: 25px;" class="{{icon[0]}} "></i>
                  </div>
                  <i *ngIf="icon[1] <= 1" class="{{icon[0]}}" style="font-size: 25px;"></i>
                </div>
              </div>
            </div>

          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element;let i = dataIndex" (click)="expandItineraryElement($event,element,i)"
          style="width: 0%;">
          <button mat-icon-button aria-label="expand row"
            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let activityElement" [attr.colspan]="columnsToDisplayWithExpand.length">
          <div class="expanded-detail" [@detailExpand]="activityElement == expandedElement ? 'expanded' : 'collapsed'">
            <div class="activity-action-btn-group" *ngIf="activityElement == expandedElement">

              <activities-app-button [buttontype]="itineraryPrintButton"
                (valueChange)='expandMenu()'></activities-app-button>
              <span [matMenuTriggerFor]="menu" #triggerFor></span>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="PrintItineraty(expandedElement,1)">{{'PDF(.pdf)' | translate}}</button>
                <button mat-menu-item (click)="PrintItineraty(expandedElement,2)">{{'Excel(.xlsx)' | translate}}</button>
              </mat-menu>

              <activities-app-button [buttontype]="itinerarysendEmailButton"
                (valueChange)='sendEmail(activityElement)'></activities-app-button>

            </div>
            <div class="activity-table">
              <table mat-table [dataSource]="activityElement.activities" class="mat-elevation-z8 activity-data-table">

                <ng-container matColumnDef="activityName">
                  <th mat-header-cell *matHeaderCellDef>{{'Activity Name' | translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.activityName}} </td>
                </ng-container>

                <ng-container matColumnDef="startDateTime">
                  <th mat-header-cell *matHeaderCellDef> {{'Start Date/Time' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.startDateTime | localizedDate: cs.settings.value.General.DateFormat}} {{element.startDateTime | localizedDate: 'LT'}} </td>
                </ng-container>

                <ng-container matColumnDef="endDateTime">
                  <th mat-header-cell *matHeaderCellDef> {{'End Date/Time' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.endDateTime | localizedDate: cs.settings.value.General.DateFormat}} {{element.endDateTime | localizedDate: 'LT'}}</td>
                </ng-container>

                <ng-container matColumnDef="confirmationId">
                  <th mat-header-cell *matHeaderCellDef> {{'Confirmation' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.confirmationId}} </td>
                </ng-container>

                <ng-container matColumnDef="noOfGuests">
                  <th mat-header-cell *matHeaderCellDef> {{'No of Guests' | translate}} </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center"> {{element.noOfGuests}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> {{'Status' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="activitiesColumns;sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: activitiesColumns;"></tr>
              </table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;let i = dataIndex"
        class="example-element-row" [class.example-expanded-row]="expandedElement === element"
        (click)="expandItineraryMatrowElement($event,element,i)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>

    <div class="table-timeline__body" style="padding: 10px 0;text-align: center;"
      *ngIf="itineraryGridDataSource.data.length == 0">
      {{'noDataFound' | translate}}
    </div>

  </section>

</div>