<div class="float-left">
<div class=" secondSecTDImage position-relative ">
  <div *ngIf="ImageUploaded" class="imageHolder">
    <img [src]="url" class="w-100 h-100" alt="" />
  </div>
  <div *ngIf="!ImageUploaded" class="ImageUpload">
    <span>
      <a class="text-color LW11" [ngClass]="{'button_invalid': readOnly }" (click)="compressFile()">{{emptyImgCaption}}</a>
    </span>
  </div>

</div>
<div  *ngIf="ImageUploaded" class="ImageEdit" [ngClass]="{'button_invalid': readOnly }">
    <button mat-raised-button class= "mt-3 LW12 body-bordercolor change-image" (click)="compressFile()">{{captions.changePicture}}</button>
    <a class="remove pt-3 LW12" (click)="onFileDelete()">{{captions.remove}}</a>
  </div>
</div>
