<section class="transaction-detail-report" [formGroup]="TransactionDetailFormGrp" autocomplete="off">
    <app-retail-multi-select-dropdown *ngIf="!useRetailInterface && paymentMethods" [dropDownFilterData] = "paymentMethods" [dropDownControlname] = "'paymentMethods'" [dropDownName] = "captions.PaymentMethods" (dropDownFrmControl) = "getFormControlValue($event)" (IsAnySelected)="IsSelectionValid($event)"></app-retail-multi-select-dropdown>
    <div *ngIf="showMachineDropDown">
      <app-retail-multi-select-dropdown *ngIf="machineName" [dropDownFilterData] = "machineName" [dropDownControlname] = "'machineName'" [dropDownName] = captions.Machines (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
      </div>
    <mat-error class="user-select" *ngIf="!validSelection"> 
        {{validSelectionErrMsg}}
     </mat-error>
    <div class="mb-3">
        <label class="LW14">{{VoidedTransactionLabelText}}</label>
        <app-retail-toggle-switch class="d-block" [formControl]="TransactionDetailFormGrp.controls['IncludeVoidedTransaction']"></app-retail-toggle-switch>

      </div>
</section>