<div class="package">
    <div class="alert alert-warning w-100 mt-2" role="alert" *ngIf="!isUniqGuest">
        {{'UniqGuestValidationMessage' | translate}}
    </div>
    <ng-scrollbar>
        <div class="list-container">
            <div class="package__title">{{'packages' | translate}}</div>
            <mat-accordion class="" *ngFor="let package of partyService.packages" (click)="selectSpecifyPackage(package)" multi="false">
                <mat-expansion-panel class="package__container"
                    [ngClass]="{'hightlight-package' : package.highlightPackage , 'selected-package': partyService.selectedPackageId == package.Id }"
                    (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="package__name">
                                {{ package?.Name }}
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="mapped-activity">
                        <div class="selection-count"
                            *ngIf="package.SingleDayActivityType == SingleDayActivityTypeValue?.AnyActivity"> {{'guestPick' | translate}} {{'anyText' | translate}}<span class="count">
                                {{package.NumberOfActivities}}</span>{{'activityWith' | translate}} {{package.DiscountPercent}}% {{'asDiscount' | translate}}</div>
                        <div class="selection-count"
                            *ngIf="package.SingleDayActivityType == SingleDayActivityTypeValue?.SpecificActivity">{{'guestPick' | translate}} {{((package.PackageActivities[0]?.NumberOfActivitiesForDay == '-1' ) ?  'allText': 'anyText') | translate }}<span class="count"
                                *ngIf="package.PackageActivities[0]?.NumberOfActivitiesForDay != '-1'">
                                {{package.PackageActivities[0]?.NumberOfActivitiesForDay}}</span> {{'userActivityText' | translate}}</div>
                                <div class="expired">
                                    <small>{{'packageEnds' | translate}} {{package?.EndDate | formatSettingsDate: cs.settings.value.General.DateFormat}}</small>
                                </div>
                        <table class="table"
                            *ngIf="package.SingleDayActivityType == SingleDayActivityTypeValue?.SpecificActivity">
                            <tr *ngFor="let activity of package?.nameMapping">
                                <td>{{activity?.Name}}</td>
                                <td *ngIf="activity.count">{{activity?.count}} {{'sessionText' | translate}}</td>
                                <td *ngIf="activity.duration"><span *ngIf="activity?.duration == '-1'">{{'unlimited' | translate}}</span><span *ngIf="activity?.duration != '-1'">{{activity?.duration}} {{'mins' | translate}} </span><span class="open-booking"
                                        *ngIf="activity.isOpenBooking">({{'OpenBooking' | translate}})</span></td>
                                <td>{{activity?.discountPercent}} %</td>
                            </tr>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="no-data" *ngIf="!partyService.packages || partyService.packages.length == 0">{{'noDataFound' | translate}}</div>
        </div>
    </ng-scrollbar>
    <div class="btn-actions">
        <div class="action" *ngIf="showAction && partyService.selectedPackageId != null && cartDataList?.length > 0">
            <activities-app-button class="pr-3" [buttontype]="applyPackageBtn" (click)='applyPackage()'></activities-app-button>
        </div>
        <div class="action" *ngIf="partyService.selectedPackageId != null && cartDataList?.length > 0">
            <activities-app-button class="pr-3" [buttontype]="cancelBtn" (click)='cancelPackage()'></activities-app-button>
        </div>
    </div>
</div>