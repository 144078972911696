<mat-accordian class="apply-promo-code" *ngIf="availableOffers.length">
    <mat-expansion-panel class="promo-code-panel" [expanded]=true [disabled]="disableToggle">
        <mat-expansion-panel-header class="promo-code-panel-header">
            <mat-panel-title class="seat-grid-header promo-code-panel-title">
                <h4 class="seat-header-three m-0">{{'viewDiscount' | translate}}</h4>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="promo-code-list">
            <section class="promo-code-details">
                <div class="promo-code-data d-flex justify-content-between align-items-center">
                    <dynamic-form [config]="searchConfig" #searchConfigForm class="search-input">
                    </dynamic-form>
                    <activities-app-button [buttontype]="searchButton"
                        (valueChange)='searchPromoCode($event)'></activities-app-button>
                </div>
            </section>
            <div class="promo-view">
                
            <section class="promo-code-description" *ngFor="let offer of (availableOffers| searchPipe: searchText)" [ngStyle]="{height: offer.showHeight ? 'auto':'105px'}">
                <div class="promo-code-name">
                    <div>
                        <span class="offer-name-description d-flex seat-grid-header pb-1"><i class="icon-offer"></i> <span class="pl-1">{{offer.OfferCodeLabel}}</span></span>
                        <span class="seat-grid-content offer-value">{{'Save' | translate}} {{offer.DiscountType == feeType.FlatFee ? this.cs.settings.value.General.OperationCurrency:''}}{{offer.DiscountValue}}{{(offer.DiscountType == feeType.FlatFee ? ' ': '')}}{{(offer.DiscountType == feeType.FlatFee ? 'offText': '%') | translate}} with this code</span>
                    </div>
                    <activities-app-button [buttontype]="applyButton" (valueChange)="applyPromoCode(offer)" class="text-right"></activities-app-button>

                </div>
                <div class="d-flex justify-content-between mb-1 align-items-center promo-code-content">

                    <span class="">{{offer.OfferCode}}</span>

                    <span *ngIf="offer.TermsAndConditions || offer.Description" (click)="offer.showHeight = !offer.showHeight" class=" pointer text-color">{{ (!offer.showHeight ? 'viewDetails' : 'hideDetails') | translate}}</span>
                </div>
                <div [ngStyle]="{'height': offer.showHeight ? 'auto' : '0px'}" class="pt-2 promo-code-conditions">
                    <div class="pb-2" *ngIf="offer.Description">
                        <div class="seat-grid-header text-underline pb-1">{{'Description' | translate }}</div>
                        <span class="seat-grid-content">{{offer.Description}}</span>
                    </div>
                    <div class="pb-2" *ngIf="offer.TermsAndConditions">
                        <div class="seat-grid-header text-underline pb-1">{{'termsAndCondition' | translate }}</div>
                        <div class="seat-grid-content pl-3" [innerHTML]="offer.TermsAndConditions"></div>
                    </div>
                    
                </div>
            </section>
            <section *ngIf="(availableOffers| searchPipe: searchText).length == 0" class="no-data-view p-5 mt-3 mb-3 text-center">
                {{'noDataFound'| translate}}
            </section>
        
        </div>
        </div>
    </mat-expansion-panel>
</mat-accordian>