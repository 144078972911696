import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuestbookComponent } from './guestbook.component';
import { GuestbookRoutingModule } from './guestbook-routing.module';
import { GuestlistComponent } from './guestlist/guestlist.component';
import { GuestdetailsComponent } from './guestdetails/guestdetails.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@app/material-module';
import {NgScrollbarModule } from 'ngx-scrollbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { SharedModule } from '@app/shared/shared.module';
import { OrderDetailsComponent } from './ordercheckdetails/orderdetails.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FeedbackDetailsComponent } from './feedbackdetails/feedbackdetails.component';
import { RatingModule } from 'ng-starrating';

@NgModule({
    declarations: [GuestbookComponent, GuestlistComponent, GuestdetailsComponent, OrderDetailsComponent, FeedbackDetailsComponent],
    imports: [
        CommonModule,
        GuestbookRoutingModule,
        TranslateModule,
        MaterialModule,
        FormsModule,
        NgScrollbarModule,
        InfiniteScrollModule,
        ChartsModule,
        SharedModule,
        PerfectScrollbarModule,
        RatingModule
    ],
    exports: [GuestlistComponent]
})
export class GuestbookModule { }
