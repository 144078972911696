import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Injectable } from "@angular/core";

@Injectable()
export class TimelineDragHandlerService {
    hoveredRowId: number;
    scrollSubscriptionData;
    dragStarted: boolean = false;
    lockAxis : 'x' | 'y' | '' = '';
    dragStartPoints: {left : number, top: number} = {
        left: 0,
        top: 0
    }
    intialScrollOffset: {x : number, y: number} = {
        x: 0,
        y: 0
    }

    mouseLeave(): void {
        let distance = this.scrollSubscriptionData.viewPort.measureScrollOffset(this.scrollSubscriptionData.scrollAxis) - this.intialScrollOffset.x;
        this.dragStartPoints[this.scrollSubscriptionData.scrollAxis] -= distance;
        this.scrollSubscriptionData = null;
    }

    autoScrollHandler(direction: -1 | 1, viewPort: CdkVirtualScrollViewport, scrollAxis: 'left' | 'top'): void {
        this.intialScrollOffset.x = viewPort.measureScrollOffset(scrollAxis) + (25 * direction);
        Promise.resolve({ direction, viewPort }).then(({ direction, viewPort }) => {
            this.scrollSubscriptionData = null;
            this.scrollSubscriptionData = { direction: direction * 25, viewPort, scrollAxis };
            this.handleScroll(100)
        })
    }

    handleScroll(time: number): void {
        if (this.scrollSubscriptionData) {
            setTimeout(() => {
                if (this.scrollSubscriptionData) {
                    let scrollTo = { behavior: 'smooth' };
                    scrollTo[this.scrollSubscriptionData.scrollAxis] = this.scrollSubscriptionData.viewPort.measureScrollOffset(this.scrollSubscriptionData.scrollAxis) - this.scrollSubscriptionData.direction;
                    this.scrollSubscriptionData.viewPort.scrollTo(scrollTo);
                    this.handleScroll(time);
                }
            }, time)
        }
    }
}