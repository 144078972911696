<section [formGroup]="CorrectVoidFormGrp" autocomplete="off">
    <div>
       
          <app-retail-multi-select-dropdown *ngIf="users" [dropDownFilterData] = "users" [dropDownControlname] = "'users'" [dropDownName] = "captions.Users" (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
      </div>
      <div class="mb-3">
            <label class="LW14">{{inActiveText}}</label>
            <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleIncludeInactive($event[0])" [formControl]="CorrectVoidFormGrp.controls['includeInactiveusers']"></app-retail-toggle-switch>

          </div>
  </section>
