import { HttpServiceCall, HttpMethod } from "../../shared/service/http-call.service";
import { Injectable } from "@angular/core";
import { Host } from "../../shared/globalsContant";
import { BaseResponse } from "../../shared/business/shared.modals";
import { RetailCategoryAndDiscountTypeDetails, DiscountTypeDetails, DiscountType, DiscountReason } from "../shop.modals";
import { SystemConfiguration } from "../../retail.modals";
import { RetailRoutes } from 'src/app/retail/retail-route';

@Injectable()
export class ApplyDiscountService {
    CategoryDiscountList: RetailCategoryAndDiscountTypeDetails[] = [];
    AllCategoryDiscountList: RetailCategoryAndDiscountTypeDetails[] = [];
    DiscountTypes: DiscountType[] = [];
    constructor(private http: HttpServiceCall) {

    }

    async getDiscountsForItem(categoryId: number, isAll: boolean = false): Promise<DiscountTypeDetails[]> {

        let result: BaseResponse<RetailCategoryAndDiscountTypeDetails[]>
        if (categoryId === 0 || isAll) {
            result = await this.http.CallApiAsync<RetailCategoryAndDiscountTypeDetails[]>({
                callDesc: "GetDiscountConfiguration",
                host: Host.retailManagement,
                method: HttpMethod.Get
            });
        this.AllCategoryDiscountList = result && result.result ? result.result : [];
        }
        else {
            result = await this.http.CallApiAsync<RetailCategoryAndDiscountTypeDetails[]>({
                callDesc: "GetDiscountConfigurationsByCategoryId",
                host: Host.retailManagement,
                method: HttpMethod.Get,
                uriParams: { categoryId: categoryId }
            });
        }

        this.CategoryDiscountList = result && result.result ? result.result : [];

        var discountList: DiscountTypeDetails[][] = this.CategoryDiscountList.filter(x => x.retailCategoryId == categoryId || isAll)
            .map(x => x.discountTypeDetails);

        return discountList.length > 0 ? discountList.reduce((a, b) => { return a.concat(b) }) : [];
    }

    async getActiveDiscountTypes(): Promise<DiscountType[]> {
        var result = await this.http.CallApiAsync<DiscountType[]>({
            callDesc: RetailRoutes.GetActiveDiscountTypesByRules,
            host: Host.retailManagement,
            method: HttpMethod.Get
        });
        this.DiscountTypes = result.result ? result.result : [];
        return this.DiscountTypes;
    }

    async getActiveDiscountReasons() : Promise<DiscountReason[]>{
        var result = await this.http.CallApiAsync<DiscountReason[]>({
            callDesc: "GetDiscountReason",
            host: Host.retailManagement,
            method: HttpMethod.Get
        });
        return result.result ? result.result : [];
    }

    async getMiscSettings() : Promise<SystemConfiguration[]>{
        var result = await this.http.CallApiAsync<SystemConfiguration[]>({
            callDesc: "GetMiscConfiguration",
            host: Host.retailManagement,
            method: HttpMethod.Get
        });
        return result.result ? result.result : [];
    }
    
}
