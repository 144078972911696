import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { BookingChargeType } from '@app/shared/models/RestaurantDTO';
import { PartyService } from '@app/shared/services/party.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-on-popover',
  templateUrl: './add-on-popover.component.html',
  styleUrls: ['./add-on-popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddOnPopoverComponent implements OnInit {
  @Input() party: any;
  @ViewChild('addOnForBookingPopover') private addOnForBookingPopover: NgbPopover;
  showAddOnDetails: boolean = false;
  addonsForActivitySelected = [];
  addonsForBooking: any = [];
  subscriptions: Subscription = new Subscription();
  constructor(public _ps: PartyService, public cs: CacheService) { }

  ngOnInit() {
    this.updateAddOnDetails(this.party);
  }
  updateAddOnDetails(booking?) {
    this.addonsForActivitySelected = Utilities.getAddons(this.cs.settings.value.Addons, booking?.SpecialMealId || null)
    if (this._ps.isChatPopoverOpened) {
      this.addOnForBookingPopover.close();
      return;
    }
    this.addonsForBooking = [];
    if (booking) {
      this.subscriptions.add(this._ps.getBookedAddonItems({PartyId: booking.Id, BookingChargesType: BookingChargeType.Addon}).subscribe(response => {
        let _addonsForBooking = booking.BookedSessionId ? response.Payload.filter(addOn => addOn.BookedSessionId == booking.BookedSessionId) : response.Payload;
        
        let _addonObj = {};
        _addonsForBooking.forEach(addOn => {
          if(!_addonObj[addOn.Id]){
            _addonObj[addOn.Id] = { AddonId : addOn.Id, TotalAmount : 0, AddonCount  : 0, AddonName: addOn.Name, OverBooked: 0, IsMandatory: this.addonsForActivitySelected?.find(addon => addon.AddonId == addOn.Id)?.IsMandatory || false };
          }
          _addonObj[addOn.Id].AddonCount += ( addOn.Size || 0 );
          _addonObj[addOn.Id].TotalAmount += ( addOn.TotalAmount || 0 );
          _addonObj[addOn.Id].OverBooked += ( addOn.OverBooked || 0 );
        })
        this.addonsForBooking = _.orderBy(Object.values(_addonObj), 'IsMandatory', 'desc');
        this.showAddOnDetails = true;
      }))
    }
  }
}
